import CardFixed from "components/Card/CardFixed";
import LockerSectionList from "./LockerSectionList";

const LockerOverallDetail = ({
  onClickOpenModal,
  selectedBranch,
  floorZoneCombo, //섹션2 콤보 state
  lockerAllInfo, //섹션2 구락리 state
  refreshView, //섹션2 구락리 갱신(branchId,floorId,zoneId)
  getFloorZoneCombo, //섹션2 콤보 갱신
}) => {
  return (
    <div>
      <CardFixed
        topDom={<div>구역별 락커 리스트</div>}
        midDom={
          <LockerSectionList
            onClickOpenModal={onClickOpenModal}
            selectedBranch={selectedBranch}
            floorZoneCombo={floorZoneCombo}
            lockerAllInfo={lockerAllInfo}
            refreshView={refreshView}
            getFloorZoneCombo={getFloorZoneCombo}
          />
        }
        isLoading={false}
        rootStyle={{
          width: "105rem",
          borderRadius: "0.625rem",
          backgroundColor: "#fff",
        }}
      />
    </div>
  );
};
export default LockerOverallDetail;
