import * as ActionTypes from "./action_type";

///MEMBER VIEW
export const loadRegisterView = (data) => {
  return {
    type: ActionTypes.getRegisterViewAction,
    member_id: data.member_id,
    reg_date: data.reg_date,
  };
};

export const loadRegisterViewSuccess = (data) => {
  return {
    type: ActionTypes.getRegisterViewSuccessAction,
    payload: data,
  };
};

export const loadRegisterViewFail = (data) => {
  return {
    type: ActionTypes.getRegisterViewFailAction,
    payload: [],
  };
};

export const loadRegisterViewReset = (data) => {
  return {
    type: ActionTypes.getRegisterViewResetAction,
    payload: data,
  };
};
