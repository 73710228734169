import { simpleAlert, storeEmpty } from "components/CommonLib/CommonLib";
import GridTable from "components/GirdTable/GridTable";
import Label from "components/LabelInput/Label";
import FlatBtn from "components/NewButton/FlatBtn";

import NormalBtnWithSubtitle from "components/NewButton/NormalBtnWithSubtitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadHqViewList, loadHqViewListReset } from "../Module/actions/index";

const HQManagement = ({ onClickOpenModal }) => {
  const storesDispatch = useDispatch();
  const restData = useSelector((state) => state.erp_set_state.hq_view_state);

  useEffect(() => {
    storesDispatch(loadHqViewList());
    return () => {
      storesDispatch(loadHqViewListReset());
    };
  }, [loadHqViewList, storesDispatch]);

  const onERPPackageAddEvent = () => {
    const param = {
      title: "ERP 패키지 추가",
      modalParam: {
        mode: "add",
        refreshStore: viewRefresh,
      },
    };
    onClickOpenModal("본부관리/패키지", param);
  };
  const onERPPackageEditEvent = (id) => {
    const param = {
      title: "ERP 패키지 수정",
      modalParam: {
        mode: "edit",
        package_id: id,
        refreshStore: viewRefresh,
      },
    };
    onClickOpenModal("본부관리/패키지", param);
  };

  const onClickDeletePackage = (packageId) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="패키지를 삭제하시겠습니까?" />
          </>
        ),
        // width: "50rem",
        onBtnYes: () => {
          onDeletePackage(packageId);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const onDeletePackage = async (packageId) => {
    HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/erp_set/package_remove", {
      params: {
        package_id: packageId,
      },
    })
      .then((res) => {
        simpleAlert(storesDispatch, "알림", "패키지가 삭제되었습니다.");
        viewRefresh();
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const onHQAddEvent = () => {
    if (!storeEmpty(restData)) {
      let packNameList = [];
      const erpPackages = restData.stores.data.package_list;
      erpPackages.map((packageInfo) => packNameList.push(packageInfo.package_info.package_name));

      const param = {
        title: "본부 추가",
        modalParam: {
          mode: "add",
          packNameList: packNameList,
          packInfoList: erpPackages,
          refreshStore: viewRefresh,
        },
      };
      onClickOpenModal("본부관리/본부", param);
    }
  };

  const onHqEditEvent = (state) => {
    if (!storeEmpty(restData)) {
      let packNameList = [];
      const erpPackages = restData.stores.data.package_list;
      erpPackages.map((packageInfo) => packNameList.push(packageInfo.package_info.package_name));

      const param = {
        title: "본부 수정",
        modalParam: {
          mode: "edit",
          packNameList: packNameList,
          packInfoList: erpPackages,
          state: state,
          refreshStore: viewRefresh,
        },
      };
      onClickOpenModal("본부관리/본부", param);
    }
  };

  const viewRefresh = () => {
    storesDispatch(loadHqViewList());
  };

  const onClickRegCancel = (hqId) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="등록 취소하시겠습니까?" />
          </>
        ),
        // width: "50rem",
        onBtnYes: () => {
          onDeleteHq(hqId);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const checkDeleteHq = (data) => {
    let errText = "";
    if (data.global_hq_info.branch_cnt > 0) {
      errText = "등록된 지점이 있으면 삭제할 수 없습니다.";
    } else if (data.global_hq_info.staff_cnt > 0) {
      errText = "등록된 직원이 있으면 삭제할 수 없습니다.";
    }

    if (errText !== "") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText={errText} />
            </>
          ),
          // width: "50rem",
          onBtnOk: () => {},
        }),
      );
    } else {
      onClickDelete(data);
    }
  };

  const onClickDelete = (data) => {
    const labelText = "본부 [" + data.hq_info.hq_name + "]를 삭제하시겠습니까?";
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText={labelText} />
          </>
        ),
        // width: "50rem",
        onBtnYes: () => {
          onDeleteHq(data.hq_info.hq_id);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const onDeleteHq = async (hqId) => {
    HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/erp_set/hq_remove", {
      params: {
        hq_id: hqId,
      },
    })
      .then((res) => {
        console.log(res);
        viewRefresh();
      })
      .catch((err) => {
        console.log("err");
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        viewRefresh();
      });
  };

  const erpPackageList = new erpPackageTable(onERPPackageEditEvent, onClickDeletePackage);
  const hqList = new hqTable(onClickRegCancel, checkDeleteHq, onHqEditEvent);

  return (
    <div>
      <NormalBtnWithSubtitle
        title="ERP 패키지 관리"
        name="패키지 등록"
        onClick={onERPPackageAddEvent}
        theme="white"
      />
      <GridTable
        columns_head={erpPackageList.columns_head}
        row_data={restData.stores.data.package_list}
        row_render={erpPackageList.create_table}
      />
      <div style={{ height: "0.7rem" }}></div>

      <NormalBtnWithSubtitle
        title="본부 목록"
        name="본부 등록"
        onClick={onHQAddEvent}
        theme="white"
      />
      <GridTable
        columns_head={hqList.columns_head}
        row_data={restData.stores.data.hq_list}
        row_render={hqList.create_table}
      />
    </div>
  );
};
export default HQManagement;

class erpPackageTable {
  onERPPackageEditEvent = null;
  onClickDeletePackage = null;

  constructor(onERPPackageEditEvent, onClickDeletePackage) {
    this.onERPPackageEditEvent = onERPPackageEditEvent;
    this.onClickDeletePackage = onClickDeletePackage;
  }

  create_table = (data, idx) => {
    return (
      <>
        <div>{idx + 1}</div>
        <div>{data.package_info.package_name}</div>
        <div>{data.reg_info.created_at.split(" ")[0]}</div>
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <FlatBtn
              name="편집"
              onClick={() => this.onERPPackageEditEvent(data.package_info.package_id)}
            />
            <FlatBtn
              name="삭제"
              onClick={() => this.onClickDeletePackage(data.package_info.package_id)}
              disabled={data.remove_lock}
            />
          </div>
        </div>
      </>
    );
  };
  columns_head = [
    { title: "번호", width: "7%" },
    { title: "ERP 패키지명", width: "63%" },
    { title: "등록일", width: "15%" },
    { title: " ", width: "15%" },
  ];
}

class hqTable {
  onClickRegCancel = null;
  onClickDelete = null;
  onClickEditHq = null;

  constructor(onClickRegCancel, onClickDelete, onClickEditHq) {
    this.onClickRegCancel = onClickRegCancel;
    this.onClickDelete = onClickDelete;
    this.onClickEditHq = onClickEditHq;
  }

  create_table = (data, idx) => {
    return (
      <>
        <div>{idx + 1}</div>
        <div>{data.hq_info.hq_name}</div>
        <div>{data.owner_info.owner_name}</div>

        <div>{data.package_info.package_name}</div>
        <div>{data.global_hq_info.branch_cnt}</div>
        <div>{data.global_hq_info.manager_cnt}</div>
        <div>{data.global_hq_info.staff_cnt}</div>
        <div style={data.reg_status === "인증대기" ? { color: "#DE0909" } : { color: "#444444" }}>
          {data.reg_status}
        </div>
        <div>{data.reg_info.created_at.split(" ")[0]}</div>

        <div>
          {data.reg_status === "인증대기" ? (
            <FlatBtn name="등록취소" onClick={() => this.onClickRegCancel(data.hq_info.hq_id)} />
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <FlatBtn name="편집" onClick={() => this.onClickEditHq(data)} />
              <FlatBtn
                name="삭제"
                onClick={() => this.onClickDelete(data)}
                disabled={data.global_hq_info.branch_cnt > 0}
              />
            </div>
          )}
        </div>
      </>
    );
  };

  columns_head = [
    { title: "번호", width: "7%" },
    { title: "본부명", width: "11.5%" },
    { title: "대표자", width: "11.5%" },

    { title: "적용된 ERP 패키지명", width: "25%" },
    { title: "지점 수", width: "5%" },
    { title: "관리자 수", width: "5%" },
    { title: "직원 수", width: "5%" },
    { title: "상태", width: "5%" },
    { title: "등록일", width: "15%" },
    { title: " ", width: "10%" },
  ];
}
