export const newBranchApi = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/hq_set/branch_insert";
};

export const updateBranchApi = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/hq_set/branch_update";
};

export const updatePolicyApi = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/hq_set/policy_update";
};
export const updateHqApi = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/hq_set/hq_update";
};

export const updateBranchPermission = () => {
  return process.env.REACT_APP_STAFF_SERVER + "/hq/staff_permission_update";
};

const post_api = {
  newBranchApi,
  updateBranchApi,
  updateHqApi,
  updatePolicyApi,
  updateBranchPermission,
};

export default post_api;
