import * as ActionTypes from "./action_type";

///급여 메인 리스트
export const loadSalaryInfoView = (salary_report_id, is_edit) => {
  return {
    type: ActionTypes.salaryInfoViewAction,
    salary_report_id: salary_report_id,
    is_edit: is_edit,
  };
};

export const loadSalaryInfoViewSuccess = (data) => {
  return {
    type: ActionTypes.salaryInfoViewSuccessAction,
    payload: data,
  };
};

export const loadSalaryInfoViewFail = () => {
  return {
    type: ActionTypes.salaryInfoViewFailAction,
    payload: [],
  };
};

export const loadSalaryInfoViewReset = (data) => {
  return {
    type: ActionTypes.salaryInfoViewResetAction,
    payload: data,
  };
};

///급여탭 테이블
export const loadSalaryStatusView = (salary_report_id, is_edit) => {
  return {
    type: ActionTypes.salaryStatusViewAction,
    salary_report_id: salary_report_id,
    is_edit: is_edit,
  };
};

export const loadSalaryStatusViewSuccess = (data) => {
  return {
    type: ActionTypes.salaryStatusViewSuccessAction,
    payload: data,
  };
};

export const loadSalaryStatusViewFail = () => {
  return {
    type: ActionTypes.salaryStatusViewFailAction,
    payload: [],
  };
};

export const loadSalaryStatusViewReset = (data) => {
  return {
    type: ActionTypes.salaryStatusViewResetAction,
    payload: data,
  };
};

////기본급탭 테이블
export const loadStaffStandardSalaryView = (salary_report_id, is_edit) => {
  return {
    type: ActionTypes.staffStandardSalaryViewAction,
    salary_report_id: salary_report_id,
    is_edit: is_edit,
  };
};

export const loadStaffStandardSalaryViewSuccess = (data) => {
  return {
    type: ActionTypes.staffStandardSalaryViewSuccessAction,
    payload: data,
  };
};

export const loadStaffStandardSalaryViewFail = () => {
  return {
    type: ActionTypes.staffStandardSalaryViewFailAction,
    payload: [],
  };
};

export const loadStaffStandardSalaryViewReset = (data) => {
  return {
    type: ActionTypes.staffStandardSalaryViewResetAction,
    payload: data,
  };
};

//회원권탭 테이블
export const loadMbshpSalaryView = (salary_report_id, is_edit) => {
  return {
    type: ActionTypes.mbshpSalaryViewAction,
    salary_report_id: salary_report_id,
    is_edit: is_edit,
  };
};

export const loadMbshpSalaryViewSuccess = (data) => {
  return {
    type: ActionTypes.mbshpSalaryViewSuccessAction,
    payload: data,
  };
};

export const loadMbshpSalaryViewFail = () => {
  return {
    type: ActionTypes.mbshpSalaryViewFailAction,
    payload: [],
  };
};

export const loadMbshpSalaryViewReset = (data) => {
  return {
    type: ActionTypes.mbshpSalaryViewResetAction,
    payload: data,
  };
};

//PT탭 테이블
export const loadPtSalaryView = (salary_report_id, is_edit) => {
  return {
    type: ActionTypes.ptSalaryViewAction,
    salary_report_id: salary_report_id,
    is_edit: is_edit,
  };
};

export const loadPtSalaryViewSuccess = (data) => {
  return {
    type: ActionTypes.ptSalaryViewSuccessAction,
    payload: data,
  };
};

export const loadPtSalaryViewFail = () => {
  return {
    type: ActionTypes.ptSalaryViewFailAction,
    payload: [],
  };
};

export const loadPtSalaryViewReset = (data) => {
  return {
    type: ActionTypes.ptSalaryViewResetAction,
    payload: data,
  };
};

//GX탭 테이블
export const loadGxSalaryView = (salary_report_id, is_edit) => {
  return {
    type: ActionTypes.gxSalaryViewAction,
    salary_report_id: salary_report_id,
    is_edit: is_edit,
  };
};

export const loadGxSalaryViewSuccess = (data) => {
  return {
    type: ActionTypes.gxSalaryViewSuccessAction,
    payload: data,
  };
};

export const loadGxSalaryViewFail = () => {
  return {
    type: ActionTypes.gxSalaryViewFailAction,
    payload: [],
  };
};

export const loadGxSalaryViewReset = (data) => {
  return {
    type: ActionTypes.gxSalaryViewResetAction,
    payload: data,
  };
};

//기타탭 테이블
export const loadEtcSalaryView = (salary_report_id, is_edit) => {
  return {
    type: ActionTypes.etcSalaryViewAction,
    salary_report_id: salary_report_id,
    is_edit: is_edit,
  };
};

export const loadEtcSalaryViewSuccess = (data) => {
  return {
    type: ActionTypes.etcSalaryViewSuccessAction,
    payload: data,
  };
};

export const loadEtcSalaryViewFail = () => {
  return {
    type: ActionTypes.etcSalaryViewFailAction,
    payload: [],
  };
};

export const loadEtcSalaryViewReset = (data) => {
  return {
    type: ActionTypes.etcSalaryViewResetAction,
    payload: data,
  };
};

//안쓰는거
export const loadClassSessionView = (class_id, staff_id) => {
  return {
    type: ActionTypes.classSessionViewAction,
    class_id: class_id,
    staff_id: staff_id,
  };
};

export const loadClassSessionViewSuccess = (data) => {
  return {
    type: ActionTypes.classSessionViewSuccessAction,
    payload: data,
  };
};

export const loadClassSessionViewFail = (data) => {
  return {
    type: ActionTypes.classSessionViewFailAction,
    payload: [],
  };
};

export const loadClassSessionViewReset = (data) => {
  return {
    type: ActionTypes.classSessionViewResetAction,
    payload: data,
  };
};
