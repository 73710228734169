import {
  loadRefundView,
  loadRefundViewSuccess,
  loadRefundViewFail,
  loadRefundViewReset,
} from "./view_act";

export const loadRefundViewStore = (member_id) => loadRefundView(member_id);

export const refundViewAction = {
  loadRefundViewSuccess,
  loadRefundViewFail,
  loadRefundViewReset,
};
