import {
  loadCardSearch,
  loadCardSearchSuccess,
  loadCardSearchFail,
  loadCardSearchReset,
  loadCardDetailInfo,
  loadCardDetailInfoSuccess,
  loadCardDetailInfoFail,
  loadCardDetailInfoReset,
  loadCardListAction,
  loadCardListSuccessAction,
  loadCardListFailAction,
  loadCardListResetAction,
} from "./view_act";

export const loadCardSearchStore = (branch_id, card_no) => loadCardSearch(branch_id, card_no);
export const loadCardDetailInfoStore = (card_info_id) => loadCardDetailInfo(card_info_id);
export const loadCardListActionStore = (search_param) => loadCardListAction(search_param);

export const AccessCardAction = {
  loadCardSearch,
  loadCardSearchSuccess,
  loadCardSearchFail,
  loadCardSearchReset,

  loadCardDetailInfo,
  loadCardDetailInfoSuccess,
  loadCardDetailInfoFail,
  loadCardDetailInfoReset,

  loadCardListAction,
  loadCardListSuccessAction,
  loadCardListFailAction,
  loadCardListResetAction,
};
