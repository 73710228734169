import { contentTypeJson, simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import TextArea from "components/LabelInput/TextArea";
import TextField from "components/LabelInput/TextField";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const ManageGxClassModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();

  const [newInfo, setNewInfo] = useState(_.cloneDeep(modalParam.data));

  useEffect(() => {
    setNewInfo((cur) => {
      const obj = { ...cur };
      obj.last_class_info.class_status = "수업완료";
      return obj;
    });
  }, [modalParam]);

  const convertToDayText = (dayIdx) => {
    if (dayIdx === 0) {
      return "일";
    } else if (dayIdx === 1) {
      return "월";
    } else if (dayIdx === 2) {
      return "화";
    } else if (dayIdx === 3) {
      return "수";
    } else if (dayIdx === 4) {
      return "목";
    } else if (dayIdx === 5) {
      return "금";
    } else if (dayIdx === 6) {
      return "토";
    }
  };

  const validationCheck = () => {
    if (!newInfo.last_class_info.class_status) {
      simpleAlert(storesDispatch, "알림", "완료구분을 선택해주세요.");
    } else {
      simpleAlert(
        storesDispatch,
        "알림",
        "수업처리 하시겠습니까?",
        false,
        undefined,
        manageGxClass,
      );
    }
  };

  const manageGxClass = async () => {
    try {
      const formData = new FormData();
      formData.append("new_info", JSON.stringify(newInfo));
      const res = await HttpInstance.patch(
        process.env.REACT_APP_STAFF_SERVER + "/class/change/gx/done",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        //모달 닫고 -> gx상세모달 갱신 + 검결 갱신
        simpleAlert(storesDispatch, "알림", "수업 처리가 완료되었습니다.");
        onModalDone();
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    }
  };
  return (
    <div>
      <TextField
        labelText="수업일"
        labelWidth="5rem"
        textBoxWidth="11rem"
        disabled
        defaultValue={modalParam.data.class_date}
      />
      <TextField
        labelText="수업 요일"
        labelWidth="5rem"
        textBoxWidth="11rem"
        disabled
        marginTop="0.5rem"
        defaultValue={convertToDayText(modalParam.data.schedule_week_idx)}
      />
      <TextField
        labelText="수업 시간"
        labelWidth="5rem"
        textBoxWidth="11rem"
        disabled
        marginTop="0.5rem"
        defaultValue={modalParam.data.class_from_time + "~" + modalParam.data.class_to_time}
      />
      <TextField
        labelText="수업 담당자"
        labelWidth="5rem"
        disabled
        textBoxWidth="11rem"
        marginTop="0.5rem"
        defaultValue={modalParam.data.class_manager_info.staff_name}
      />
      <ComboBox
        labelText="완료 구분"
        labelWidth="5rem"
        data={["수업완료", "휴강", "대강"]}
        defaultValue={"수업완료"}
        comboItemWidth="11rem"
        marginTop="0.5rem"
        onChangeCallback={(e) => {
          setNewInfo((cur) => {
            const obj = { ...cur };
            obj.last_class_info.class_status = e.target.value;
            return obj;
          });
        }}
      />
      <TextArea
        labelText="메모"
        labelWidth="5rem"
        textBoxWidth="11rem"
        marginTop="0.5rem"
        minRows={4}
        maxRows={4}
        onBlur={(e) => {
          setNewInfo((cur) => {
            const obj = { ...cur };
            obj.last_class_info.comment = e.target.value;
            return obj;
          });
        }}
      />
      <div style={{ marginTop: "1.2rem", display: "flex", justifyContent: "center" }}>
        <NormalBtn name="저장" onClick={validationCheck} />
      </div>
    </div>
  );
};

export default ManageGxClassModal;
