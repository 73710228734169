import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Collapse, IconButton, TableCell } from "@mui/material";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, parseMoneyFormat } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";

import DatePicker from "components/LabelInput/DatePicker";

import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import NormalBtn from "components/NewButton/NormalBtn";

import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import PaymentMethod, { paymentMethodTable } from "./PaymentMethod";

const PaymentModal = ({ modalParam, onModalDone, uploadDoneCallback }) => {
  const storesDispatch = useDispatch();
  const [csPayMethodList, setCsPayMethodList] = useState(
    _.cloneDeep(modalParam.csPaidInfo.pay_method),
  );
  const [ptPayMethodList, setPtPayMethodList] = useState(
    _.cloneDeep(modalParam.ptPaidInfo.pay_method),
  );
  const [unPayMethodList, setUnPayMethodList] = useState(
    _.cloneDeep(modalParam.unpaidInfo.pay_method),
  );
  const [remainPoint, setRemainPoint] = useState(modalParam.remainPoint);
  const [usedPoint, setUsedPoint] = useState(modalParam.usedPoint);
  // const [isCashApproveNum, setIsCashApproveNum] = useState(false);
  // const [isTransApproveNum, setIsTransApproveNum] = useState(false);
  // const [uploadedFileName, setUploadedFileName] = useState("");

  const csPaidInfo = useRef({
    total_paid: modalParam.useCs ? modalParam.csPaidInfo.total_paid : 0,
    unpaid_price: modalParam.useCs ? modalParam.csPaidInfo.unpaid_info.unpaid_price : 0,
    promise_date: modalParam.useCs ? modalParam.csPaidInfo.unpaid_info.promise_date : "",
    reason: modalParam.useCs ? modalParam.csPaidInfo.unpaid_info.reason : "",
  });

  const ptPaidInfo = useRef({
    total_paid: modalParam.usePt ? modalParam.ptPaidInfo.total_paid : 0,
    unpaid_price: modalParam.usePt ? modalParam.ptPaidInfo.unpaid_info.unpaid_price : 0,
    promise_date: modalParam.usePt ? modalParam.ptPaidInfo.unpaid_info.promise_date : "",
    reason: modalParam.usePt ? modalParam.ptPaidInfo.unpaid_info.reason : "",
  });

  const unpaidInfo = useRef({
    total_paid: modalParam.useUnpaid ? modalParam.unpaidInfo.total_paid : 0,
    unpaid_price: modalParam.useUnpaid ? modalParam.unpaidInfo.unpaid_info.unpaid_price : 0,
    promise_date: modalParam.useUnpaid ? modalParam.unpaidInfo.regDate : "",
    reason: modalParam.useUnpaid ? modalParam.unpaidInfo.unpaid_info.reason : "",
  });

  // 각 항목 유효성 검사
  const validation = () => {
    let res = "";

    if (
      (csPaidInfo.current.total_paid > 0 || csPaidInfo.current.unpaid_price > 0) &&
      csPayMethodList.length === 0
    ) {
      res += "· [CS] 결제수단\n";
    }

    for (let i = 0; i < csPayMethodList.length; i++) {
      if (!modalParam.isAllCalc && csPaidInfo.current.unpaid_price > 0) {
        if (!csPaidInfo.current.promise_date) res += "· [CS] 완납 예정일\n";
        if (!csPaidInfo.current.reason) res += "· [CS] 미납사유\n";
      }

      if (csPayMethodList[i].pay_method === "카드") {
        if (!csPayMethodList[i].price) {
          res += "· [CS] 결제금액(카드)\n";
        }
        if (csPayMethodList[i].approve_no.length < 8) {
          res += "· [CS] 승인번호\n";
        }
        if (csPayMethodList[i].card_no.length < 8) {
          res += "· [CS] 카드번호 앞 8자리\n";
        }
        if (!csPayMethodList[i].buyer) {
          res += "· [CS] 매입사\n";
        }
      } else if (csPayMethodList[i].pay_method === "현금") {
        if (!csPayMethodList[i].price) {
          res += "· [CS] 결제금액(현금)\n";
        }
      } else if (csPayMethodList[i].pay_method === "이체") {
        if (!csPayMethodList[i].account_name) {
          res += "· [CS] 예금주(이체)\n";
        }
        if (!csPayMethodList[i].price) {
          res += "· [CS] 결제금액(이체)\n";
        }
      } else if (csPayMethodList[i].pay_method === "기타") {
        if (!csPayMethodList[i].account_name) {
          res += "· [CS] 예금주(기타)\n";
        }
        if (!csPayMethodList[i].price) {
          res += "· [CS] 결제금액(기타)\n";
        }
      }
      if (csPayMethodList[i].pay_date.length < 11) {
        res += "· [CS] 승인시각\n";
      }
    }

    if (ptPayMethodList.length === 0) {
      if (ptPaidInfo.current.unpaid_price > 0) {
        if (!ptPaidInfo.current.promise_date) res += "· [PT] 완납 예정일\n";
        if (!ptPaidInfo.current.reason) res += "· [PT] 미납사유\n";
      }
    }

    for (let i = 0; i < ptPayMethodList.length; i++) {
      if (ptPaidInfo.current.unpaid_price > 0) {
        if (!ptPaidInfo.current.promise_date) res += "· [PT] 완납 예정일\n";
        if (!ptPaidInfo.current.reason) res += "· [PT] 미납사유\n";
      }

      if (ptPayMethodList[i].pay_method === "카드") {
        if (!ptPayMethodList[i].price) {
          res += "· [PT] 결제금액(카드)\n";
        }
        if (ptPayMethodList[i].approve_no.length < 8) {
          res += "· [PT] 승인번호\n";
        }
        if (ptPayMethodList[i].card_no.length < 8) {
          res += "· [PT] 카드번호 앞 8자리\n";
        }
        if (!ptPayMethodList[i].buyer) {
          res += "· [PT] 매입사\n";
        }
      } else if (ptPayMethodList[i].pay_method === "현금") {
        if (!ptPayMethodList[i].price) {
          res += "· [PT] 결제금액(현금)\n";
        }
      } else if (ptPayMethodList[i].pay_method === "이체") {
        if (!ptPayMethodList[i].account_name) {
          res += "· [PT] 예금주(이체)\n";
        }
        if (!ptPayMethodList[i].price) {
          res += "· [PT] 결제금액(이체)\n";
        }
      } else if (ptPayMethodList[i].pay_method === "기타") {
        if (!ptPayMethodList[i].account_name) {
          res += "· [PT] 예금주(기타)\n";
        }
        if (!ptPayMethodList[i].price) {
          res += "· [PT] 결제금액(기타)\n";
        }
      }
      if (ptPayMethodList[i].pay_date.length < 11) {
        res += "· [PT] 승인시각\n";
      }
    }

    return res;
  };

  const unpaidValidation = () => {
    let res = "";
    for (let i = 0; i < unPayMethodList.length; i++) {
      if (unpaidInfo.current.unpaid_price > 0) {
        if (!unpaidInfo.current.promise_date) res += "· [미납금] 완납 예정일\n";
        if (!unpaidInfo.current.reason) res += "· [미납금] 미납사유\n";
      }

      if (unPayMethodList[i].pay_method === "카드") {
        if (!unPayMethodList[i].price) {
          res += "· [미납금] 결제금액(카드)\n";
        }
        if (unPayMethodList[i].approve_no.length < 8) {
          res += "· [미납금] 승인번호\n";
        }
        if (unPayMethodList[i].card_no.length < 8) {
          res += "· [미납금] 카드번호 앞 8자리\n";
        }
        if (!unPayMethodList[i].buyer) {
          res += "· [미납금] 매입사\n";
        }
      } else if (unPayMethodList[i].pay_method === "현금") {
        if (!unPayMethodList[i].price) {
          res += "· [미납금] 결제금액(현금)\n";
        }
      } else if (unPayMethodList[i].pay_method === "이체") {
        if (!unPayMethodList[i].account_name) {
          res += "· [미납금] 예금주(이체)\n";
        }
        if (!unPayMethodList[i].price) {
          res += "· [미납금] 결제금액(이체)\n";
        }
      } else if (unPayMethodList[i].pay_method === "기타") {
        if (!unPayMethodList[i].account_name) {
          res += "· [미납금] 예금주(기타)\n";
        }
        if (!unPayMethodList[i].price) {
          res += "· [미납금] 결제금액(기타)\n";
        }
      }
    }
    return res;
  };

  const validationPrice = () => {
    //   return false;
    // }
    return true;
  };

  useEffect(() => {
    setRemainPoint(modalParam.remainPoint);
    // setCsPayMethodList(modalParam.paidInfo.pay_method);
    onChangePrice("cs");
    onChangePrice("pt");
    onChangePrice("unpaid");

    // 기본 1줄 추가
    if (csPayMethodList.length === 0 && modalParam.useCs) {
      onAddMethod("cs");
    }
    if (ptPayMethodList.length === 0 && modalParam.usePt) {
      onAddMethod("pt");
    }
    if (unPayMethodList.length === 0 && modalParam.useUnpaid) {
      onAddMethod("unpaid");
    }
  }, []);

  useEffect(() => {
    onChangePrice("cs");
  }, [csPayMethodList]);
  useEffect(() => {
    onChangePrice("pt");
  }, [ptPayMethodList]);
  useEffect(() => {
    onChangePrice("unpaid");
  }, [unPayMethodList]);

  // 최종 등록 버튼 클릭 시
  const btnApply = () => {
    const validationRes = validation();

    if (ptPaidInfo.current.unpaid_price > 0) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="PT는 미납금을 남길 수 없습니다." />,
          onBtnOk: () => {},
        }),
      );
    } else if (validationRes !== "") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="필수값을 모두 입력해주셔야 합니다." />
              <br />
              <br />
              <Label labelText={validationRes} whiteSpace="pre-wrap" />
            </>
          ),
          // width: "50rem",
          onBtnOk: () => {},
        }),
      );
    } else if (modalParam.isAllCalc && csPaidInfo.current.unpaid_price > 0) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="미납금을 남길 수 없습니다." />,
          onBtnOk: () => {},
        }),
      );
    } else if (csPaidInfo.current.unpaid_price < 0) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="[CS] 총 결제금액은 상품금액보다 클 수 없습니다." />,
          onBtnOk: () => {},
        }),
      );
    } else if (ptPaidInfo.current.unpaid_price < 0) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="[PT] 총 결제금액은 상품금액보다 클 수 없습니다." />,
          onBtnOk: () => {},
        }),
      );
    } else if (validationPrice() === false) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="미납금은 전체 금액의 50%를 초과할 수 없습니다." />,
          onBtnOk: () => {},
        }),
      );
    } else {
      const csPaymentInfo = {
        pay_method: csPayMethodList,
        total_paid: csPaidInfo.current.total_paid,
        unpaid_info: {
          unpaid_price: csPaidInfo.current.unpaid_price,
          reason: csPaidInfo.current.reason,
          promise_date: csPaidInfo.current.promise_date,
        },
      };
      const ptPaymentInfo = {
        pay_method: ptPayMethodList,
        total_paid: ptPaidInfo.current.total_paid,
        unpaid_info: {
          unpaid_price: ptPaidInfo.current.unpaid_price,
          reason: ptPaidInfo.current.reason,
          promise_date: ptPaidInfo.current.promise_date,
        },
      };

      onModalDone("결제", csPaymentInfo, ptPaymentInfo);
    }
  };

  const btnApplyUnpaid = () => {
    const validationRes = unpaidValidation();
    if (validationRes !== "") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="필수값을 모두 입력해주셔야 합니다." />
              <br />
              <br />
              <Label labelText={validationRes} whiteSpace="pre-wrap" />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else if (unpaidInfo.current.unpaid_price < 0) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="[미납금] 총 결제금액은 상품금액보다 클 수 없습니다." />,
          onBtnOk: () => {},
        }),
      );
    } else if (validationPrice() === false) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="미납금은 전체 금액의 50%를 초과할 수 없습니다." />,
          // width: "50rem",
          onBtnOk: () => {},
        }),
      );
    } else {
      const unPaymentInfo = {
        pay_method: unPayMethodList,
        total_paid: unpaidInfo.current.total_paid,
        unpaid_info: {
          unpaid_price: unpaidInfo.current.unpaid_price,
          reason: unpaidInfo.current.reason,
          promise_date: unpaidInfo.current.promise_date,
        },
      };
      onModalDone("결제", unPaymentInfo);
    }
  };

  const onAddMethod = (type) => {
    const methodId = uuidv4();
    const baseMethod = {
      id: methodId,
      pay_method: "카드",
      card_comp: "",
      account_name: "",
      installment: "",
      price: 0,
      pay_date: "",
      approve_no: "",
      card_no: "",
      buyer: "",
      sum: 0,
      //
      bank_user_name: "",
    };

    if (type === "cs") {
      if (csPayMethodList.length === 0) {
        setCsPayMethodList([baseMethod]);
      } else {
        setCsPayMethodList([...csPayMethodList, baseMethod]);
      }
    } else if (type === "pt") {
      if (ptPayMethodList.length === 0) {
        setPtPayMethodList([baseMethod]);
      } else {
        setPtPayMethodList([...ptPayMethodList, baseMethod]);
      }
    } else {
      if (unPayMethodList.length === 0) {
        setUnPayMethodList([baseMethod]);
      } else {
        setUnPayMethodList([...unPayMethodList, baseMethod]);
      }
    }
  };

  const onDeleteMethod = (type, targetId) => {
    if (type === "cs") {
      const array = _.cloneDeep(csPayMethodList);
      const index = array.findIndex((x) => x.id === targetId);
      if (index !== -1) {
        array.splice(index, 1);
        setCsPayMethodList(array);
      }
    } else if (type === "pt") {
      const array = _.cloneDeep(ptPayMethodList);
      const index = array.findIndex((x) => x.id === targetId);
      if (index !== -1) {
        array.splice(index, 1);
        setPtPayMethodList(array);
      }
    } else {
      const array = _.cloneDeep(unPayMethodList);
      const index = array.findIndex((x) => x.id === targetId);
      if (index !== -1) {
        array.splice(index, 1);
        setUnPayMethodList(array);
      }
    }
  };

  // const onClickOpenPayDocModal = (data) => {
  //   storesDispatch(
  //     globalAlertOn({
  //       show: true,
  //       titleText: "결제 영수증",
  //       bodyText: (
  //         <PayDocModal
  //           uploadDoneCallback={uploadDoneCallback}
  //           setUploadedFileName={setUploadedFileName}
  //           uploadedFileName={uploadedFileName}
  //         />
  //       ),
  //       onBtnOk: () => {},
  //     }),
  //   );
  // };

  const onChangePrice = (type, method, val) => {
    // balanceRef.current.setBalance(csPaymentTableData.priceList);
    if (type === "cs") {
      let price = 0;
      csPayMethodList.forEach((it, i) => {
        price += it.price;
      });
      csPaidInfo.current.total_paid = price; // val?
      csPaidInfo.current.unpaid_price = modalParam.csUnpaidPrice - price;
    } else if (type === "pt") {
      let price = 0;
      ptPayMethodList.forEach((it, i) => {
        price += it.price;
      });
      ptPaidInfo.current.total_paid = price;
      ptPaidInfo.current.unpaid_price = modalParam.ptUnpaidPrice - price;
    } else {
      let price = 0;
      unPayMethodList.forEach((it, i) => {
        price += it.price;
      });
      unpaidInfo.current.total_paid = price;
      unpaidInfo.current.unpaid_price = modalParam.unpaidPrice - price;
    }

    if (method === "포인트") {
      setUsedPoint(val);
      setRemainPoint(modalParam.remainPoint - val);
    }

    setPaymentsData(getPaymentsData());
  };

  const onSetCsPayMethodList = (arr, _, __) => {
    setCsPayMethodList(arr);
  };
  const onSetPtPayMethodList = (arr, _, __) => {
    setPtPayMethodList(arr);
  };
  const onSetUnpaidMethodList = (arr, _, __) => {
    setUnPayMethodList(arr);
  };

  const csPaymentTableData = new paymentMethodTable(
    csPayMethodList,
    onChangePrice,
    onSetCsPayMethodList,
    onDeleteMethod,
    // uploadDoneCallback,
    // onClickOpenPayDocModal,
    // isCashApproveNum,
    // setIsCashApproveNum,
    // isTransApproveNum,
    // setIsTransApproveNum,
    modalParam.regDate,
    modalParam.csUnpaidPrice,
    "cs",
    modalParam.remainPoint,
    modalParam.ptPaymentMethod,
  );

  const ptPaymentTableData = new paymentMethodTable(
    ptPayMethodList,
    onChangePrice,
    onSetPtPayMethodList,
    onDeleteMethod,
    // uploadDoneCallback,
    // onClickOpenPayDocModal,
    // isCashApproveNum,
    // setIsCashApproveNum,
    // isTransApproveNum,
    // setIsTransApproveNum,
    modalParam.regDate,
    modalParam.ptUnpaidPrice,
    "pt",
    modalParam.remainPoint,
  );

  const unPaymentTableData = new paymentMethodTable(
    unPayMethodList,
    onChangePrice,
    onSetUnpaidMethodList,
    onDeleteMethod,
    // uploadDoneCallback,
    // onClickOpenPayDocModal,
    // isCashApproveNum,
    // setIsCashApproveNum,
    // isTransApproveNum,
    // setIsTransApproveNum,
    modalParam.unpaidInfo.regDate,
    modalParam.unpaidPrice,
    "unpaid",
    modalParam.remainPoint,
  );

  const [openListState, setOpenListState] = useState([true, false]);

  const selectOpen = (idx, val) => {
    const openList = [...openListState];
    openList[idx] = val;

    if (openList[0] && openList[1]) {
      openList[0] = openList[1] = false;
      openList[idx] = val;
    }

    setOpenListState(openList);
  };

  const getPaymentsData = () => {
    const dataSet = [];

    if (modalParam.useCs) {
      dataSet.push({
        type: "회원권 및 부가상품",
        total_paid: csPaidInfo.current.total_paid,
        unpaid_price: csPaidInfo.current.unpaid_price,
        promise_date: csPaidInfo.current.promise_date,
        reason: csPaidInfo.current.reason,
      });
    }
    if (modalParam.usePt) {
      dataSet.push({
        type: "PT",
        total_paid: ptPaidInfo.current.total_paid,
        unpaid_price: ptPaidInfo.current.unpaid_price,
        promise_date: ptPaidInfo.current.promise_date,
        reason: ptPaidInfo.current.reason,
      });
    }

    if (modalParam.useUnpaid) {
      dataSet.push({
        type: "미납금",
        total_paid: unpaidInfo.current.total_paid,
        unpaid_price: unpaidInfo.current.unpaid_price,
        promise_date: unpaidInfo.current.promise_date,
        reason: unpaidInfo.current.reason,
      });
    }
    return dataSet;
  };

  const [paymentsData, setPaymentsData] = useState(getPaymentsData());

  const totalTableData = new totalTable(
    openListState,
    selectOpen,
    csPayMethodList,
    csPaymentTableData,
    ptPayMethodList,
    ptPaymentTableData,
    unPayMethodList,
    unPaymentTableData,
    onAddMethod,
    csPaidInfo,
    ptPaidInfo,
    unpaidInfo,
    modalParam.mode,
    modalParam.regDate,
    modalParam.isAllCalc,
    modalParam.isPtPayment,
  );

  return (
    <div
      style={{
        width: "84rem",
        display: "block",
      }}
    >
      <div>
        <Label
          labelText="결제 목록"
          justifyContent="left"
          fontWeight="bold"
          marginTop="0.5rem"
          marginBottom="0.5rem"
        />
      </div>

      <div>
        <div>
          <CustomTable
            columns_head={totalTableData.columns_head}
            rest_call={totalTableData.get_data_from_rest}
            row_render={totalTableData.create_table}
            isMargin={false}
            rest_data={paymentsData}
          />
        </div>

        <div style={{ display: "flex", marginTop: "1rem", marginLeft: "0.5rem" }}>
          <div style={{ display: "flex" }}>
            <Label labelText="사용 가능 포인트 : " justifyContent="left" />
            <Label
              labelText={remainPoint.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              justifyContent="left"
            />
          </div>
          <div style={{ display: "flex", marginLeft: "3rem" }}>
            <Label labelText="사용한 포인트 : " justifyContent="left" />
            <Label
              labelText={usedPoint.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              justifyContent="left"
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1.844rem",
          }}
        >
          <NormalBtn
            name="완료"
            onClick={modalParam.mode !== "unpaid" ? btnApply : btnApplyUnpaid}
          />
        </div>
      </div>
    </div>
  );
};
export default PaymentModal;

//

//
class totalTable {
  table_title = "";
  btnStyleClass = btnStyles();
  openListState = [];
  selectOpen = null;
  csPayMethodList = null;
  csPaymentTableData = null;
  ptPayMethodList = null;
  ptPaymentTableData = null;
  unPayMethodList = null;
  unPaymentTableData = null;
  onClickAddMethod = null;
  csPaidInfo = null;
  ptPaidInfo = null;
  unpaidInfo = null;
  regDate = null;
  totalCsPrice = null;
  totalPtPrice = null;
  totalUnpaidPrice = null;
  isAllCalc = null;
  isPtPayment = null;

  constructor(
    openListState,
    selectOpen,
    csPayMethodList,
    csPaymentTableData,
    ptPayMethodList,
    ptPaymentTableData,
    unPayMethodList,
    unPaymentTableData,
    onClickAddMethod,
    csPaidInfo,
    ptPaidInfo,
    unpaidInfo,
    mode,
    regDate,
    isAllCalc,
    isPtPayment,
  ) {
    this.openListState = openListState;
    this.selectOpen = selectOpen;
    this.csPayMethodList = csPayMethodList;
    this.csPaymentTableData = csPaymentTableData;
    this.ptPayMethodList = ptPayMethodList;
    this.ptPaymentTableData = ptPaymentTableData;
    this.unPayMethodList = unPayMethodList;
    this.unPaymentTableData = unPaymentTableData;
    this.onClickAddMethod = onClickAddMethod;
    this.csPaidInfo = csPaidInfo;
    this.ptPaidInfo = ptPaidInfo;
    this.unpaidInfo = unpaidInfo;
    this.mode = mode;
    this.regDate = regDate;
    this.totalCsPrice = csPaidInfo.current.total_paid + csPaidInfo.current.unpaid_price;
    this.totalPtPrice = ptPaidInfo.current.total_paid + ptPaidInfo.current.unpaid_price;
    this.totalUnpaidPrice = unpaidInfo.current.total_paid + unpaidInfo.current.unpaid_price;
    this.isAllCalc = isAllCalc;
    this.isPtPayment = isPtPayment;
  }

  getTableType = (mode, type) => {
    if (mode === "unpaid") {
      return "unpaid";
    } else {
      if (type === "회원권 및 부가상품") {
        return "cs";
      } else {
        return "pt";
      }
    }
  };

  getPayMethodList = (mode, type) => {
    if (mode === "unpaid") {
      return this.unPayMethodList;
    } else {
      if (type === "회원권 및 부가상품") {
        return this.csPayMethodList;
      } else {
        return this.ptPayMethodList;
      }
    }
  };

  getPaymentTableData = (mode, type) => {
    if (mode === "unpaid") {
      return this.unPaymentTableData;
    } else {
      if (type === "회원권 및 부가상품") {
        return this.csPaymentTableData;
      } else {
        return this.ptPaymentTableData;
      }
    }
  };

  getTotalPrice = (mode, type) => {
    if (mode === "unpaid") {
      return this.totalUnpaidPrice;
    } else {
      if (type === "회원권 및 부가상품") {
        return this.totalCsPrice;
      } else {
        return this.totalPtPrice;
      }
    }
  };

  create_table = (data, idx) => {
    return (
      <>
        <CustomRow
          style={{ width: "100%", cursor: "pointer" }}
          key={data.type}
          onClick={(e) => {
            if (e.target.nodeName === "DIV" || e.target.nodeName === "TD") {
              this.selectOpen(idx, !this.openListState[idx]);
            }
          }}
        >
          <CustomTableContents width="17%">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ width: "2rem" }}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    this.selectOpen(idx, !this.openListState[idx]);
                  }}
                >
                  {this.openListState[idx] ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                </IconButton>
              </div>
              <div style={{ width: "100%" }}>{data.type}</div>
            </div>
          </CustomTableContents>
          <CustomTableContents width="15%">
            {parseMoneyFormat(this.getTotalPrice(this.mode, data.type))}
          </CustomTableContents>
          <CustomTableContents width="15%">{parseMoneyFormat(data.total_paid)}</CustomTableContents>
          <CustomTableContents width="15%">
            {parseMoneyFormat(data.unpaid_price)}
          </CustomTableContents>
          <CustomTableContents width="15%">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <DatePicker
                minWidth="9.5rem"
                textMarginRight="0rem"
                defaultValue={data.promise_date}
                minDate={this.regDate}
                onChangeCallback={(e) => {
                  if (data.type === "회원권 및 부가상품") {
                    this.csPaidInfo.current.promise_date = e;
                  } else if (data.type === "PT") {
                    this.ptPaidInfo.current.promise_date = e;
                  } else {
                    this.unpaidInfo.current.promise_date = e;
                  }
                }}
                disabled={data.unpaid_price === 0 || this.isAllCalc}
              />
            </div>
          </CustomTableContents>
          <CustomTableContentsEND align="center" width="20%">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <TextField
                required
                labelMarginRight="0rem"
                textMarginRight="0rem"
                textBoxWidth={"15rem"}
                defaultValue={data.reason}
                onChangeCallback={(e) => {
                  if (data.type === "회원권 및 부가상품") {
                    this.csPaidInfo.current.reason = e;
                  } else if (data.type === "PT") {
                    this.ptPaidInfo.current.reason = e;
                  } else {
                    this.unpaidInfo.current.reason = e;
                  }
                }}
                disabled={data.unpaid_price === 0 || this.isAllCalc}
              />
            </div>
          </CustomTableContentsEND>
        </CustomRow>
        <CustomRow key={idx}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in={this.openListState[idx]} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <PaymentMethod
                  type={this.getTableType(this.mode, data.type)}
                  payMethodList={this.getPayMethodList(this.mode, data.type)}
                  paymentTableData={this.getPaymentTableData(this.mode, data.type)}
                  onClickAddMethod={this.onClickAddMethod}
                  isPtPayment={this.isPtPayment}
                />
              </Box>
            </Collapse>
          </TableCell>
        </CustomRow>
      </>
    );
  };
  columns_head = [
    { title: "상품구분" },
    { title: "총 상품금액" },
    { title: "결제금액" },
    { title: "미납금" },
    { title: "완납 예정일" },
    { title: "미납사유" },
  ];
  get_data_from_rest = (data) => {
    if (dataEmpty(data)) {
      return [];
    }
    return data;
  };
}
