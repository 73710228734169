import { Button } from "@material-ui/core";
import { ReactComponent as AntennaIcon } from "assets/icon/mock_antenna.svg";
import { ReactComponent as BatteryIcon } from "assets/icon/mock_battery.svg";
import { ReactComponent as TimeIcon } from "assets/icon/mock_time.svg";
import { ReactComponent as WifiIcon } from "assets/icon/mock_wifi.svg";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const PhoneComp = ({ messageState, fileGroupId, onSendDone, file }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [testPhoneNo, setTestPhoneNo] = useState("");

  const [image, setImage] = useState();

  const loadImage = async () => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setImage(reader.result || null);
      };
    } else {
      setImage(null);
    }
  };

  useEffect(() => {
    loadImage();
  }, [file]);

  const getMsgHeadline = () => {
    if (!messageState.head_line) {
      return "";
    }

    if (messageState.sms_type === "광고") {
      // return "(광고) 랩스휘트니스 OO점 " + messageState.head_line;
      return messageState.head_line;
    } else {
      // return "랩스휘트니스 OO점 " + messageState.head_line;
      return messageState.head_line;
    }
  };

  const phoneCheck = /\d{3}-\d{4}-\d{4}/;

  const sendTestMsg = async (phoneNo, fileGroupId) => {
    if (phoneCheck.test(phoneNo)) {
      const formData = new FormData();
      formData.append("phone", phoneNo);
      // formData.append("file_group_id", fileGroupId);
      formData.append("new_info", JSON.stringify(messageState));
      formData.append("the_file", file);

      setLoading(true);

      await HttpInstance.post(
        process.env.REACT_APP_USER_SERVER + "/cs/sms/send/test",
        formData,
        contentTypeJson,
      )
        .then((res) => {
          if (res.data.msg) {
            simpleAlert(storesDispatch, "ERROR", res.data.msg);
          } else {
            // console.log(res);
            simpleAlert(storesDispatch, "알림", "테스트 메시지가 발송되었습니다.");
          }
        })
        .catch((err) => {
          console.log(err);
          simpleAlert(
            storesDispatch,
            "ERROR",
            err.response.data ? err.response.data.msg : err.code,
          );
        })
        .finally(() => {
          setLoading(false);
          onSendDone();
        });
    } else {
      simpleAlert(storesDispatch, "알림", "전화번호 형식이 맞지 않습니다.");
    }
  };

  return (
    <div
      style={{
        marginLeft: "1.5rem",
        marginRight: "1.5rem",
        // boxShadow: "2px 2px 30px rgba(0, 0, 0, 0.1)",
      }}
    >
      {loading && <Loading />}

      <div
        style={{
          background: "#F5F5F5",
          border: "1px solid #DEDEDE",
          borderTopLeftRadius: "2rem",
          borderTopRightRadius: "2rem",
          boxShadow: "2px 2px 30px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1rem 1rem",
          }}
        >
          <div>
            <TimeIcon style={{ scale: "0.8" }} />
          </div>
          <div style={{ display: "flex" }}>
            <AntennaIcon style={{ scale: "0.8" }} />
            <WifiIcon style={{ marginLeft: "0.25rem", scale: "0.8" }} />
            <BatteryIcon style={{ marginLeft: "0.25rem", scale: "0.8" }} />
          </div>
        </div>

        <Label
          labelText="메시지 미리보기"
          fontSize="1.1rem"
          fontWeight="bold"
          marginTop="2rem"
          marginBottom="1rem"
        />
      </div>

      {/* Text 영역 */}
      <div
        style={{
          height: "31rem",
          border: "1px solid #DEDEDE",
          boxShadow: "2px 2px 30px rgba(0, 0, 0, 0.1)",
          overflowY: "auto",
        }}
      >
        {/* msgContent */}
        <div
          style={{
            width: "15rem",
            margin: "1.5rem",
            padding: "1rem 1rem",
            backgroundColor: "#F4F6F9",
            borderRadius: "15px",
          }}
        >
          <Label
            labelText={messageState.sms_info.sms_name}
            fontWeight="bold"
            fontSize="1rem"
            justifyContent="flex-start"
            whiteSpace="break-spaces"
            width="14rem"
          />
          <Label labelText="[Web발신]" justifyContent="flex-start" marginTop="0.25rem" />
          <Label
            labelText={getMsgHeadline()}
            fontSize="1rem"
            justifyContent="flex-start"
            whiteSpace="break-spaces"
            width="14rem"
            marginTop="0.25rem"
          />
          <Label
            labelText={messageState.contents}
            justifyContent="flex-start"
            whiteSpace="break-spaces"
            width="14rem"
            marginTop="0.25rem"
          />
          {image ? (
            <div style={{ marginTop: "0.5rem" }}>
              <img src={image} alt="업로드 이미지" style={{ maxWidth: "100%" }} />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      {/* 하단 영역 */}
      <div
        style={{
          background: "#F5F5F5",
          border: "1px solid #DEDEDE",
          borderBottomLeftRadius: "2rem",
          borderBottomRightRadius: "2rem",
          boxShadow: "2px 2px 30px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div style={{ display: "flex", padding: "1.25rem 2rem" }}>
          <TextField
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            defaultValue={testPhoneNo}
            onChangeCallback={(e) => {
              if (e.length === 3 || e.length === 8) {
                e = e + "-";
              }
              setTestPhoneNo(e);
            }}
          />
          <Button
            style={{ marginLeft: "0.5rem" }}
            className={clsx({
              [btnStyleClass.btnRootWhite]: true,
              [btnStyleClass.buttonType9]: true,
            })}
            onClick={() => {
              sendTestMsg(testPhoneNo, fileGroupId);
            }}
          >
            테스트 발송
          </Button>
        </div>
      </div>
    </div>
  );
};
export default PhoneComp;
