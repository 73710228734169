import { Box } from "@mui/material";
import {
  generateObjectZeroId,
  getDefaultValue,
  getPermission,
} from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import _ from "lodash";
import { hq_branch_search_init_state } from "pages/CommonModal/Search/Module/reducers/hq_branch_search_init_state";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useUnsubsListStore } from "./modules/store/unsubsListState";

const SearchCondition = ({ searchParams, setSearchParams, resetFlag, onClickSearch }) => {
  const storesDispatch = useDispatch();
  // 연락처 관련
  const phone = useRef({
    p1: searchParams.p1,
    p2: searchParams.p2,
    p3: searchParams.p3,
  });
  const phoneRef1 = useRef();
  const phoneRef2 = useRef();
  const phoneRef3 = useRef();
  // 페이지 상태 관리
  const { unsubsListHqIdx: hqIdx, setHqIdx } = useUnsubsListStore();
  const [hqBranchData, setHqBranchData] = useState(_.cloneDeep(hq_branch_search_init_state));
  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);

  // 전화번호 입력 처리
  const onInputPhone = () => {
    let res = "";

    if (searchParams.p1 && !searchParams.p2 && searchParams.p3) {
      res = "err";
    } else {
      res = searchParams.p1 + "-" + searchParams.p2 + "-" + searchParams.p3;
    }
    res = res.replace("--", "-");
    const newPhoneNum = res;
    setSearchParams("phone", newPhoneNum);
  };

  // 전화 번호 변경 체크
  useEffect(() => {
    onInputPhone();
  }, [searchParams.p1, searchParams.p2, searchParams.p3]);

  // 본부 및 지점 권한 리스트, 첫번째 검색 조건 세팅
  useEffect(() => {
    getPermission(storesDispatch, "CS_MENU/MESSAGE", true).then((res) => {
      setHqBranchData(res);
      if (res.target_list.length > 0 && searchParams.hq_info.hq_id === generateObjectZeroId()) {
        setSearchParams("hq_info", res.target_list[0].hq_info);
        setSearchParams("branch_info", res.target_list[0].branch_list[0]);
      }
    });
  }, []);

  // 본부 선택 시 지점 정보 첫번째 값 세팅
  useEffect(() => {
    if (!isFirstEnterPage) {
      setSearchParams("branch_info", hqBranchData.target_list[hqIdx].branch_list[0]);
    }
  }, [hqIdx]);

  // 초기화 시 본부 및 지점 첫번째 값 세팅
  useEffect(() => {
    if (!isFirstEnterPage && hqBranchData.target_list.length > 0) {
      setSearchParams("hq_info", hqBranchData.target_list[0].hq_info);
      setSearchParams("branch_info", hqBranchData.target_list[0].branch_list[0]);
    }
  }, [resetFlag]);

  // 첫 페이지 여부 체크
  useEffect(() => {
    setIsFirstEnterPage(false);
  }, []);

  return (
    <Box
      sx={{
        border: "1px solid #dedede",
        borderRadius: "5px",
        backgroundColor: "#f5f5f5",
        height: "8rem",
        marginTop: "0.5rem",
      }}
    >
      <div style={{ margin: "0.75rem 0.75rem" }}>
        <Label labelText="검색 조건" fontWeight="bold" justifyContent="flex-start" />
        <div
          style={{ display: "flex", marginTop: "0.5rem" }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClickSearch(1);
            }
          }}
        >
          <div style={{ width: "25%" }}>
            <ComboBox
              labelText="본부"
              labelWidth="4.5rem"
              labelMarginRight="0rem"
              comboItemWidth="15rem"
              marginRight="4rem"
              data={hqBranchData && hqBranchData.hq_list}
              defaultValue={getDefaultValue("combo", searchParams.hq_info.hq_name)}
              onChangeCallback={(e, idx) => {
                setHqIdx(idx);
                setSearchParams("hq_info", hqBranchData.target_list[idx].hq_info);
              }}
            />
          </div>
          <div style={{ width: "25%" }}>
            <SearchComboBox
              labelText="지점"
              labelWidth="4.5rem"
              labelMarginRight="0rem"
              comboItemWidth="15rem"
              marginRight="4rem"
              data={hqBranchData && hqBranchData.branch_list[hqIdx]}
              defaultValue={getDefaultValue("combo", searchParams.branch_info.branch_name)}
              onChangeCallback={(e, v, idx) => {
                setSearchParams("branch_info", hqBranchData.target_list[hqIdx].branch_list[idx]);
              }}
            />
          </div>
          <div style={{ width: "25%" }}>
            <TextField
              labelText="이름"
              labelWidth="4.5rem"
              labelMarginRight="0rem"
              textMarginRight="0rem"
              textBoxWidth="15rem"
              marginRight="4rem"
              defaultValue={getDefaultValue("field", searchParams.target_name)}
              onChangeCallback={(e) => {
                setSearchParams("target_name", e);
              }}
            />
          </div>
          <div style={{ width: "25%", display: "flex" }}>
            <TextField
              inputRef={phoneRef1}
              labelMarginRight={"0rem"}
              labelWidth={"4.5rem"}
              textBoxWidth={"4.5rem"}
              textMarginRight={"0.25rem"}
              maxLength={3}
              defaultValue={getDefaultValue("field", searchParams.p1)}
              labelText="연락처"
              onChangeCallback={(e) => {
                setSearchParams("p1", e);
                phone.current.p1 = e;
                if (phone.current.p1.length === 3) {
                  phoneRef2.current.select();
                }
              }}
            />
            <TextField
              inputRef={phoneRef2}
              textBoxWidth={"4.5rem"}
              textMarginRight={"0.25rem"}
              labelMarginRight={"0.25rem"}
              maxLength={4}
              labelText="-"
              defaultValue={getDefaultValue("field", searchParams.p2)}
              onChangeCallback={(e) => {
                setSearchParams("p2", e);
                phone.current.p2 = e;
                if (phone.current.p2.length === 4) {
                  phoneRef3.current.select();
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Backspace" && !phone.current.p2) {
                  phoneRef1.current.select();
                }
              }}
            />
            <TextField
              inputRef={phoneRef3}
              textBoxWidth={"4.5rem"}
              labelMarginRight={"0.25rem"}
              maxLength={4}
              labelText="-"
              defaultValue={getDefaultValue("field", searchParams.p3)}
              onChangeCallback={(e) => {
                setSearchParams("p3", e);
                phone.current.p3 = e;
              }}
              onKeyDown={(e) => {
                if (e.key === "Backspace" && !phone.current.p3) {
                  phoneRef2.current.select();
                }
              }}
            />
            {/* <TextField
              labelText="연락처"
              labelWidth="4.5rem"
              labelMarginRight="0rem"
              textMarginRight="0rem"
              textBoxWidth="15rem"
              defaultValue={getDefaultValue("field", searchParams.phone)}
              maxLength={15}
              onChangeCallback={(e) => {
                setSearchParams("phone", e);
              }}
            /> */}
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ width: "25%" }}></div>
          <div style={{ width: "25%" }}></div>
          <div style={{ width: "25%" }}>
            <CheckBox
              labelText="080 수신거부"
              defaultValue={getDefaultValue("combo", searchParams.reject_zero_eight_zero)}
              onChangeCallback={(e) => {
                setSearchParams("reject_zero_eight_zero", e.checked);
              }}
            />
          </div>
          <div style={{ width: "25%" }}>
            <CheckBox
              labelText="마케팅 수신 비동의"
              defaultValue={getDefaultValue("combo", searchParams.agree_marketing)}
              onChangeCallback={(e) => {
                setSearchParams("agree_marketing", e.checked);
              }}
            />
          </div>
        </div>
      </div>
    </Box>
  );
};
export default SearchCondition;
