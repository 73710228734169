import { getPermission } from "components/CommonLib/CommonLib";
import { globalModalOn } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ManageTeamModalIndex from "../Modal/ManageTeamModalIndex";
import ManagePtTeamView from "./ManagePtTeamView";

const ManagePtTeamWrapper = () => {
  const [branchList, setBranchList] = useState({ branch_list: [], branch_name_list: [] });
  const [selectedBranch, setSelectedBranch] = useState({ branch_id: "", branch_name: "" });
  const storesDispatch = useDispatch();
  //콤보 GET
  const getBranchCombo = async () => {
    const result = await getPermission(storesDispatch, "CLASS_MENU/CLASS_MANAGE", true, true);
    const firstBranch = result.branch_list[0];
    setSelectedBranch(firstBranch);
    setBranchList(result);
  };

  //초기 랜더링
  useEffect(() => {
    getBranchCombo();
  }, []);

  const openModal = (select, param, onModalDone) => {
    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,
        Content: (
          <ManageTeamModalIndex modalSelect={select} modalParam={param} onModalDone={onModalDone} />
        ),
      }),
    );
  };

  const onClickOpenModal = (order, param, onModalDone) => {
    if (order !== "") {
      openModal(order, param, onModalDone);
    }
  };

  return (
    <ManagePtTeamView
      branchList={branchList}
      onClickOpenModal={onClickOpenModal}
      selectedBranch={selectedBranch}
      setSelectedBranch={setSelectedBranch}
    />
  );
};
export default ManagePtTeamWrapper;
