import * as ActionTypes from "./action_type";

///MEMBER VIEW
export const loadChangeItemView = (data) => {
  return {
    type: ActionTypes.getChangeItemViewAction,
    member_id: data.member_id,
    reg_date: data.reg_date,
  };
};

export const loadChangeItemViewSuccess = (data) => {
  return {
    type: ActionTypes.getChangeItemViewSuccessAction,
    payload: data,
  };
};

export const loadChangeItemViewFail = (data) => {
  return {
    type: ActionTypes.getChangeItemViewFailAction,
    payload: [],
  };
};

export const loadChangeItemViewReset = (data) => {
  return {
    type: ActionTypes.getChangeViewResetAction,
    payload: data,
  };
};
