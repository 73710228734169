export function getCouponBenefit(data) {
  let resText = "";
  if (data.base_info.benefit_type === "이용혜택") {
    resText = "회원권 ";
    if (data.detail.service_benefit.mbshp.add_month > 0) {
      resText += data.detail.service_benefit.mbshp.add_month + "개월 ";
    }
    if (data.detail.service_benefit.mbshp.add_day > 0) {
      resText += data.detail.service_benefit.mbshp.add_day + "일 ";
    }
    resText += "추가";
    //
  } else if (data.base_info.benefit_type === "금액혜택") {
    if (data.detail.price_benefit.type === "정액할인") {
      if (data.base_info.benefit_product === "회원권") {
        resText += "회원권 " + data.detail.price_benefit.dis_price + "원 할인";
      } else if (data.base_info.benefit_product === "운동복") {
        resText += "운동복 " + data.detail.price_benefit.dis_price + "원 할인";
      } else if (data.base_info.benefit_product === "GX") {
        resText += "GX " + data.detail.price_benefit.dis_price + "원 할인";
      } else if (data.base_info.benefit_product === "락커") {
        resText += "락커 " + data.detail.price_benefit.dis_price + "원 할인";
      }
    } else if (data.detail.price_benefit.type === "비율할인") {
      if (data.base_info.benefit_product === "회원권") {
        resText += "회원권 " + data.detail.price_benefit.dis_rate + "% 할인";
      } else if (data.base_info.benefit_product === "운동복") {
        resText += "운동복 " + data.detail.price_benefit.dis_rate + "% 할인";
      } else if (data.base_info.benefit_product === "GX") {
        resText += "GX " + data.detail.price_benefit.dis_rate + "% 할인";
      } else if (data.base_info.benefit_product === "락커") {
        resText += "락커 " + data.detail.price_benefit.dis_rate + "% 할인";
      }
    }
  }

  return resText;
}

export function getCouponDiscountPrice(data, curItemList) {
  // console.log(curItemList);

  const getPrice = (itemName) => {
    const idx = curItemList.findIndex((x) => x.content_obj.type === itemName);
    if (idx !== -1) {
      return curItemList[idx].content_obj.price;
    } else {
      return 0;
    }
  };

  if (data.detail.price_benefit.type === "정액할인") {
    return data.detail.price_benefit.dis_price;
  } else if (data.detail.price_benefit.type === "비율할인") {
    const prc = getPrice(data.base_info.benefit_product);
    return Math.floor(prc * (data.detail.price_benefit.dis_rate * 0.01));
  }
}
