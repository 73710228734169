import { useState } from "react";
import IncomeListContent from "./IncomeListContent";

const IncomeListWrapper = ({ cashbookId, baseMonth, infoStateData }) => {
  const [searchParam, setSearchParam] = useState({
    create_type: "",
    account: "",
    cashbook_id: cashbookId,
  });

  return (
    <div>
      <IncomeListContent
        searchParam={searchParam}
        setSearchParam={setSearchParam}
        cashbookId={cashbookId}
        baseMonth={baseMonth}
        infoStateData={infoStateData}
      />
    </div>
  );
};
export default IncomeListWrapper;
