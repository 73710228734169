import failIcon from "assets/icon/NO.svg";
import successIcon from "assets/icon/success.svg";
import IconButton from "components/Button/IconButton";
import { getPermission, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import Popup from "components/ModalPopup/Popup";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import RegisterPhoneCheckModal from "pages/ERP/MemberRegister/Register/Modal/RegisterPhoneCheckModal";
import { useEffect, useRef, useState } from "react";
import DaumPostcode from "react-daum-postcode";
import { useDispatch } from "react-redux";

const AssignNewbie = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const labelWidth = "4rem";
  const compWidth = "10rem";

  const [isAddrPopup, setAddrPopup] = useState(false);
  const [hqIdx, setHqIdx] = useState(0);
  const openAddrPopup = (val) => {
    setAddrPopup(val);
  };
  let [phone1, setPhone1] = useState("");
  let [phone2, setPhone2] = useState("");
  let [phone3, setPhone3] = useState("");
  const phoneRef1 = useRef();
  const phoneRef2 = useRef();
  const phoneRef3 = useRef();
  const [phoneCheckStatus, setPhoneCheckStatus] = useState("");
  const [removePhoneMemberId, setRemovePhoneMemberId] = useState("");

  const [hqBranchData, setHqBranchData] = useState({
    branch_list: [[]],
    hq_list: [],
    target_list: [],
  });

  const [memberInfo, setMemberInfo] = useState({
    access: {
      access_method: "",
      card_no: "",
    },
    active_info: {},
    base_info: {
      agree_marketing: false,
      agree_personal: false,
      birth: "",
      gender: "남",
      phone: "",
      address_1: "",
      address_2: "",
      remarks: "",
    },
    branch_info: {
      branch_id: "",
      branch_name: "",
    },
    branch_pt_detail: {},

    hq_info: {
      hq_id: "",
      hq_name: "",
    },
    member_info: {
      member_id: "000000000000000000000000",
      member_name: "",
      member_no: "",
    },
    member_type: "",
  });

  const viewModelRef = useRef();
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });

  const validationCheck = () => {
    if (!memberInfo.member_info.member_name) {
      simpleAlert(storesDispatch, "알림", "이름을 입력해주세요.");
    } else if (!memberInfo.base_info.reg_path) {
      simpleAlert(storesDispatch, "알림", "가입경로를 입력해주세요.");
    } else if (!memberInfo.base_info.birth) {
      simpleAlert(storesDispatch, "알림", "생년월일을 입력해주세요.");
    } else if (!memberInfo.base_info.gender) {
      simpleAlert(storesDispatch, "알림", "성별을 선택해주세요.");
    } else if (!memberInfo.base_info.phone) {
      simpleAlert(storesDispatch, "알림", "연락처를 입력해주세요.");
    } else if (!memberInfo.base_info.address_1) {
      simpleAlert(storesDispatch, "알림", "주소를 입력해주세요.");
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="입력된 신규회원 정보로 양도를 진행합니다." />,
          onBtnYes: () => {
            const data = {
              assigner: modalParam.assigner,
              assignee: memberInfo,
              removePhoneMemberId,
            };
            onModalDone(data);
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const getHqBranchCombo = async () => {
    const result = await getPermission(storesDispatch, "MEMBER_VIEW_MENU/MANAGE", true);
    setMemberInfo((cur) => {
      const hq_info = result.target_list[0].hq_info;
      const branch_info = result.target_list[0].branch_list[0];
      return { ...cur, hq_info, branch_info };
    });
    setHqBranchData(result);
  };

  useEffect(() => {
    getHqBranchCombo();
  }, [modalParam]);

  const handleComplete = (data) => {
    openAddrPopup(false);
    setMemberInfo((cur) => {
      const obj = _.cloneDeep(memberInfo);
      obj.base_info.address_1 = data.address;
      return obj;
    });
  };

  const onCheckPhone = () => {
    if (phone1.length === 3 && phone2.length >= 3 && phone3.length === 4) {
      HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member/check_phone", {
        params: {
          phone: phone1 + "-" + phone2 + "-" + phone3,
        },
      })
        .then((res) => {
          if (!restEmpty(res.data.data)) {
            // console.log(res);
            const isDuplicate = res.data.data;
            // console.log(res.data.data);
            if (isDuplicate === "N") {
              setPhoneCheckStatus("Y");
              // baseInfoState.phone = phone1 + "-" + phone2 + "-" + phone3;
              setMemberInfo((cur) => {
                const obj = _.cloneDeep(memberInfo);
                obj.base_info.phone = phone1 + "-" + phone2 + "-" + phone3;
                return obj;
              });
            } else {
              setPhoneCheckStatus("N");
              // baseInfoState.phone = "";
            }
          }
        })
        .catch((err) => {
          console.log("err");
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        });
    } else {
      memberInfo.base_info.phone = "";
      setPhoneCheckStatus("");
    }

    setPhone1(phone1);
    setPhone2(phone2);
    setPhone3(phone3);
  };

  const checkDuplMember = () => {
    // 권한이 있는 지점의 ID 리스트. 중복자가 권한 없는 지점 회원인 경우 지점변경으로 진입하도록 유도하기 위함
    const availBranchIdList = [];
    for (let i = 0; i < hqBranchData.target_list.length; i++) {
      for (let j = 0; j < hqBranchData.target_list[i].branch_list.length; j++) {
        availBranchIdList.push(hqBranchData.target_list[i].branch_list[j].branch_id);
      }
    }

    const param = {
      phone: phone1 + "-" + phone2 + "-" + phone3,
      branch_info: memberInfo.branch_info,
      is_assign: true,
      avail_branch_list: availBranchIdList,
    };
    setModalInfo({
      show: true,
      title: "연락처 중복 확인",
      Content: <RegisterPhoneCheckModal modalParam={param} onModalDone={onDuplModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const onDuplModalDone = (param1, prevMemberId, phone) => {
    setRemovePhoneMemberId(prevMemberId);
    setPhoneCheckStatus("Y");
    memberInfo.base_info.phone = phone1 + "-" + phone2 + "-" + phone3;
    viewModelRef.current.setClose();
    if (param1 === "cancel") {
      onModalDone(param1);
    }
  };

  return (
    <div style={{ width: "50rem" }}>
      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
      <Popup
        isOpen={isAddrPopup}
        onClose={() => {
          openAddrPopup(false);
        }}
        title="주소 검색"
        Content={
          <DaumPostcode
            onComplete={handleComplete}
            autoClose={false}
            style={{ width: "30rem", height: "35rem" }}
          />
        }
      />

      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="가입 정보" />
      </div>
      <div style={{ display: "flex" }}>
        <ComboBox
          marginLeft="1.5rem"
          labelWidth={labelWidth}
          comboItemWidth={compWidth}
          labelText="본부"
          data={hqBranchData.hq_list}
          defaultValue={hqBranchData.hq_list[hqIdx]}
          onChangeCallback={(e) => {
            const hIdx = hqBranchData.hq_list.findIndex((el) => el === e.target.value);
            if (hIdx !== -1) {
              setHqIdx(hIdx);
            }
            setMemberInfo((cur) => {
              const obj = { ...cur };
              obj.hq_info = hqBranchData.target_list[hIdx].hq_info;
              obj.branch_info = { branch_id: "", branch_name: "" };
              return obj;
            });
          }}
        />
        <SearchComboBox
          marginLeft="1.5rem"
          labelWidth={labelWidth}
          comboItemWidth={compWidth}
          labelText="지점"
          data={hqBranchData.branch_list[hqIdx]}
          defaultValue={hqBranchData.branch_list[hqIdx][0]}
          onChangeCallback={(e, v, idx) => {
            setMemberInfo((cur) => {
              const obj = { ...cur };
              obj.branch_info = hqBranchData.target_list[hqIdx].branch_list[idx];
              return obj;
            });
          }}
        />
        <ComboBox
          required
          marginLeft="1.5rem"
          labelWidth={labelWidth}
          comboItemWidth={compWidth}
          labelText="가입 경로"
          data={["기타", "소개", "간판", "블로그", "인스타", "페이스북", "네이버", "홍보"]}
          onChangeCallback={(e) => {
            memberInfo.base_info.reg_path = e.target.value;
          }}
        />
      </div>

      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="회원 정보" />
      </div>
      <div>
        <div style={{ display: "flex" }}>
          <TextField
            required
            marginLeft="1.5rem"
            labelWidth={labelWidth}
            textBoxWidth={compWidth}
            labelText="이름"
            onChangeCallback={(e) => {
              memberInfo.member_info.member_name = e;
            }}
          />

          <DatePicker
            required
            labelMarginLeft="1.5rem"
            labelText={"생년월일"}
            labelWidth={labelWidth}
            minWidth={compWidth}
            onChangeCallback={(e) => {
              memberInfo.base_info.birth = e;
            }}
          />
          <ComboBox
            required
            marginLeft="1.5rem"
            labelWidth={labelWidth}
            comboItemWidth={compWidth}
            labelText="성별"
            defaultValue={""}
            data={["남", "여"]}
            onChangeCallback={(e) => {
              memberInfo.base_info.gender = e.target.value;
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "0.7rem",
            // justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            required
            inputRef={phoneRef1}
            marginLeft="1.5rem"
            textMarginRight="0rem"
            labelWidth={labelWidth}
            textBoxWidth={"4.5rem"}
            labelText="연락처"
            maxLength={3}
            isStringNumber
            onChangeCallback={(e) => {
              phone1 = e;
              onCheckPhone();
              if (e.length === 3) {
                phoneRef2.current.select();
              }
            }}
          />
          <TextField
            inputRef={phoneRef2}
            required
            textMarginRight="0rem"
            labelWidth="0.1rem"
            labelMarginLeft="0.25rem"
            labelText="-"
            maxLength={4}
            textBoxWidth={"4.5rem"}
            isStringNumber
            onChangeCallback={(e) => {
              phone2 = e;
              onCheckPhone();
              if (e.length === 4) {
                phoneRef3.current.select();
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Backspace" && !phone2) {
                phoneRef1.current.select();
              }
            }}
          />
          <TextField
            inputRef={phoneRef3}
            required
            labelWidth="0.1rem"
            textMarginRight="0.5rem"
            labelMarginLeft="0.25rem"
            labelText="-"
            maxLength={4}
            textBoxWidth={"4.5rem"}
            isStringNumber
            onChangeCallback={(e) => {
              phone3 = e;
              onCheckPhone();
            }}
            onKeyDown={(e) => {
              if (e.key === "Backspace" && !phone3) {
                phoneRef2.current.select();
              }
            }}
            // onBlur={() => onCheckPhone()}
          />
          {phoneCheckStatus === "Y" ? (
            <img
              alt="O"
              src={successIcon}
              style={{
                display: "flex",
                width: "1.4rem",
                height: "1.4rem",
                marginRight: "0.5rem",
              }}
            />
          ) : phoneCheckStatus === "N" ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt="O"
                src={failIcon}
                style={{
                  display: "flex",
                  width: "1.4rem",
                  height: "1.4rem",
                  marginRight: "0.5rem",
                }}
              />
              <NormalBtn
                theme="white"
                width="6.5rem"
                name="중복자 확인"
                onClick={() => {
                  checkDuplMember();
                }}
              />
            </div>
          ) : (
            <></>
          )}
          {/* 개인정보 활용 및 마케팅 수신 동의 체크 */}
          {/* <div style={{ display: "flex", marginLeft: "auto" }}>
            <CheckBox
              marginTop="0rem"
              labelText="개인정보 활용 동의"
              ItemMarginLeft={"1.5rem"}
              onChangeCallback={(e) => {
                setMemberInfo((cur) => {
                  const obj = { ...cur };
                  obj.base_info.agree_personal = e.checked;
                  return obj;
                });
              }}
            />
            <CheckBox
              marginTop="0rem"
              labelText="마케팅 수신 동의"
              onChangeCallback={(e) => {
                setMemberInfo((cur) => {
                  const obj = { ...cur };
                  obj.base_info.agree_marketing = e.checked;
                  return obj;
                });
              }}
            />
          </div> */}
        </div>
        <div style={{ display: "flex", marginTop: "0.7rem" }}>
          <TextField
            marginLeft="1.5rem"
            textMarginRight="0rem"
            labelWidth={labelWidth}
            textBoxWidth={"20rem"}
            labelText="주소"
            defaultValue={memberInfo.base_info.address_1}
            disabled
          />
          <TextField
            marginLeft="0rem"
            textBoxWidth={"20rem"}
            onChangeCallback={(e) => {
              memberInfo.base_info.address_2 = e;
            }}
          />
          <IconButton
            type="search"
            onClick={() => {
              openAddrPopup(true);
            }}
          />
        </div>
        <div style={{ display: "flex", marginTop: "0.7rem" }}>
          <TextField
            marginLeft="1.5rem"
            labelWidth={labelWidth}
            textBoxWidth={"43.5rem"}
            labelText="특이사항"
            onChangeCallback={(e) => {
              memberInfo.base_info.remarks = e;
            }}
          />
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
        <NormalBtn
          name="등록"
          onClick={() => {
            validationCheck();
          }}
        />
      </div>
    </div>
  );
};
export default AssignNewbie;
