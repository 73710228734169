export const getNavigatorPath = (stateData) => {
  // stateData.board_type
  if (stateData.board_type === "notice") {
    return "/notice";
  } else if (stateData.board_type === "refer") {
    return "/reference";
  } else if (stateData.board_type === "deliver") {
    return "/staff_dm";
  } else {
    return "/";
  }
};
