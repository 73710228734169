import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { generateObjectZeroId, getPermission } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
// import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import _ from "lodash";
import { hq_branch_search_init_state } from "pages/CommonModal/Search/Module/reducers/hq_branch_search_init_state";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DraftInfoButtons from "./DraftInfoButtons";

const DraftInfo = ({
  mode,
  permissionAct,
  onClickApproveAction,
  onClickRetrieve,
  baseState,
  onClickSave,
}) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();
  const labelWidth = "4rem";
  const textBoxWidth = "13rem";

  const [hqIdx, setHqIdx] = useState(0);
  const [hqBranchData, setHqBranchData] = useState(_.cloneDeep(hq_branch_search_init_state));

  useEffect(() => {
    const isIncludeHq = true;
    getPermission(storesDispatch, "APPROVAL_MENU/MANAGE", true, false, false, isIncludeHq).then(
      (res) => {
        setHqBranchData(res);
        baseState.writer.hq_info = res.target_list[hqIdx].hq_info;
        baseState.writer.branch_info = res.target_list[hqIdx].branch_list[0];
      },
    );
  }, [baseState]);

  useEffect(() => {
    if (hqBranchData.hq_list.length > 0) {
      onSelectHq(hqBranchData.hq_list[0]);
    }
    if (hqBranchData.branch_list.length > 0) {
      onSelectBranch(hqBranchData.branch_list[0][0]);
    }
  }, [hqBranchData]);

  useEffect(() => {
    if (hqBranchData.hq_list.length > 0) {
      if (baseState.writer.hq_info.hq_name !== "") {
        onSelectHq(baseState.writer.hq_info.hq_name);
      } else {
        onSelectHq(hqBranchData.hq_list[0]);
      }
    }
    if (hqBranchData.branch_list.length > 0) {
      if (baseState.writer.branch_info.branch_name !== "") {
        onSelectBranch(baseState.writer.branch_info.branch_name);
      } else {
        onSelectBranch(hqBranchData.branch_list[0][0]);
      }
    }
  }, [baseState.writer.staff_info]);

  const onSelectHq = (hqName) => {
    const index = hqBranchData.hq_list.findIndex((el) => el === hqName);
    if (index !== -1) {
      setHqIdx(index);
      let tBranchName = hqBranchData.target_list[index].branch_list[0].branch_name;
      let tBranchId = hqBranchData.target_list[index].branch_list[0].branch_id;
      if (tBranchName === "전체") {
        tBranchId = generateObjectZeroId();
      }
      return index;
    }
    return -1;
  };

  const onSelectBranch = (branchName) => {
    const index = hqBranchData.branch_list[hqIdx].findIndex((el) => el === branchName);
    if (index !== -1) {
      let tBranchId = hqBranchData.target_list[hqIdx].branch_list[index].branch_id;
      if (branchName === "전체") {
        tBranchId = generateObjectZeroId();
      }
      return index;
    }
    return -1;
  };

  if (mode === "approver") {
    return (
      <>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <Label labelText="기안 정보" marginLeft="0.5rem" fontWeight="bold" />
        </div>

        <div style={{ width: "100%", marginTop: "0.5rem" }}>
          <div style={{ display: "flex" }}>
            <div style={{ width: "25%" }}>
              <TextField
                labelText="본부"
                labelWidth={labelWidth}
                textBoxWidth={textBoxWidth}
                labelMarginRight="0rem"
                textMarginRight="0rem"
                defaultValue={baseState.writer.hq_info.hq_name}
                disabled
              />
            </div>
            <div style={{ width: "25%" }}>
              <TextField
                labelText="지점"
                labelWidth={labelWidth}
                textBoxWidth={textBoxWidth}
                labelMarginRight="0rem"
                textMarginRight="0rem"
                defaultValue={baseState.writer.branch_info.branch_name}
                disabled
              />
            </div>
            <div style={{ width: "25%", marginLeft: "2rem" }}>
              <TextField
                labelText="기안일시"
                labelWidth={"5rem"}
                textBoxWidth={textBoxWidth}
                labelMarginRight="0rem"
                textMarginRight="0rem"
                defaultValue={baseState.approve_draft_date}
                disabled
              />
            </div>
            <div style={{ width: "25%", marginLeft: "2rem" }}>
              <TextField
                labelText="기안자"
                labelWidth={labelWidth}
                fullWidth
                labelMarginRight="0rem"
                textMarginRight="0rem"
                defaultValue={baseState.writer.staff_info.staff_name}
                disabled
              />
            </div>
          </div>
          <div style={{ display: "flex", marginTop: "0.5rem" }}>
            <TextField
              labelText="제목"
              labelWidth={labelWidth}
              labelMarginRight="0.2rem"
              fullWidth
              textMarginRight="0rem"
              defaultValue={baseState.doc_title}
              disabled
            />
            {permissionAct.im_current_turn ? (
              <div style={{ display: "flex" }}>
                <Button
                  style={{ marginLeft: "0.5rem" }}
                  className={clsx({
                    [btnStyleClass.btnRootWhite]: true,
                    [btnStyleClass.buttonType4]: true,
                  })}
                  onClick={() => {
                    onClickApproveAction("반려");
                  }}
                >
                  반려
                </Button>

                <Button
                  style={{ marginLeft: "0.25rem" }}
                  className={clsx({
                    [btnStyleClass.btnRootRaw]: true,
                    [btnStyleClass.buttonType4]: true,
                  })}
                  onClick={() => {
                    onClickApproveAction("승인");
                  }}
                >
                  승인
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );
    //
  } else {
    //
    return (
      <>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <Label labelText="기안 정보" marginLeft="0.5rem" fontWeight="bold" />
        </div>

        <div style={{ width: "100%", marginTop: "0.5rem" }}>
          {mode === "view" ? (
            <div style={{ display: "flex", marginTop: "0.75rem" }}>
              <div style={{ width: "33.3%" }}>
                <TextField
                  labelText="상태"
                  labelWidth={labelWidth}
                  fullWidth
                  textMarginRight="0rem"
                  defaultValue={baseState.approval_status}
                  disabled
                />
              </div>
              <div style={{ width: "33.3%", marginLeft: "2rem" }}>
                <TextField
                  labelText="본부"
                  labelWidth={labelWidth}
                  labelMarginRight="0.2rem"
                  fullWidth
                  textMarginRight="0rem"
                  defaultValue={baseState.writer.hq_info.hq_name}
                  disabled
                />
              </div>
              <div style={{ width: "33.3%", marginLeft: "2rem" }}>
                <TextField
                  labelText="지점"
                  labelWidth={labelWidth}
                  labelMarginRight="0.2rem"
                  fullWidth
                  textMarginRight="0rem"
                  defaultValue={baseState.writer.branch_info.branch_name}
                  disabled
                />
              </div>
              <div style={{ width: "33.4%", marginLeft: "2rem" }}></div>
            </div>
          ) : (
            // 작성중인 경우 (not view mode)
            <div>
              <ComboBox
                marginTop="0.75rem"
                labelText="본부"
                labelWidth={labelWidth}
                labelMarginRight="0rem"
                comboItemWidth={"17rem"}
                data={hqBranchData.hq_list}
                // defaultValue={baseState.writer.hq_info.hq_name}
                defaultValue={hqBranchData.hq_list[hqIdx]}
                onChangeCallback={(e) => {
                  const idx = onSelectHq(e.target.value);
                  if (idx !== -1) {
                    baseState.writer.hq_info = hqBranchData.target_list[idx].hq_info;
                  }
                }}
              />
              <SearchComboBox
                marginTop="0.5rem"
                labelText="지점"
                labelWidth={labelWidth}
                labelMarginRight="0rem"
                comboItemWidth={"17rem"}
                data={hqBranchData.branch_list[hqIdx]}
                // defaultValue={baseState.writer.branch_info.branch_name}
                defaultValue={hqBranchData.branch_list[hqIdx][0] || ""}
                onChangeCallback={(e, v, idx) => {
                  baseState.writer.branch_info = hqBranchData.target_list[hqIdx].branch_list[idx];
                }}
              />
            </div>
          )}
          <div style={{ display: "flex", marginTop: "0.5rem" }}>
            <TextField
              labelText="제목"
              labelWidth={labelWidth}
              labelMarginRight="0.2rem"
              fullWidth
              textMarginRight="0rem"
              defaultValue={baseState.doc_title}
              onChangeCallback={(e) => {
                baseState.doc_title = e;
              }}
              disabled={mode === "view"}
            />
            {mode === "view" ? (
              <DraftInfoButtons
                onClickRetrieve={onClickRetrieve}
                onClickSave={onClickSave}
                baseState={baseState}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    );
  }
};
export default DraftInfo;
