import { HttpInstance } from "lib/HttpLib";

export const getLockerSetting = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/locker/get_all_set", {
    params: {
      branch_id: param.branch_id === null ? "" : param.branch_id,
      branch_name: param.branch_name === null ? "" : param.branch_name,
    },
  });
};

export const getLockerSettingShow = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/locker/get_all_set_show", {
    params: {
      branch_id: param.branch_id === null ? "" : param.branch_id,
      branch_name: param.branch_name === null ? "" : param.branch_name,
    },
  });
};

export const getLockerAllocateShow = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/locker/get_allocate_show", {
    params: {
      branch_id: param.branch_id === null ? "" : param.branch_id,
      branch_name: param.branch_name === null ? "" : param.branch_name,
      floor_id: param.floor_id === null ? "" : param.floor_id,
      set_info_id: param.set_info_id === null ? "" : param.set_info_id,
    },
  });
};

export const getLockerDetailShow = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/locker/get_detail", {
    params: {
      branch_id: param.branch_id === null ? "" : param.branch_id,
      branch_name: param.branch_name === null ? "" : param.branch_name,
      floor_id: param.floor_id === null ? "" : param.floor_id,
      zone_id: param.zone_id === null ? "" : param.zone_id,
      locker_idx: param.locker_idx === null ? "" : param.locker_idx,
    },
  });
};

const get_api = {
  getLockerSetting,
  getLockerSettingShow,
  getLockerAllocateShow,
  getLockerDetailShow,
};

export default get_api;
