import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import TabletSettingView from "./TabletSettingView";
const TabletSetting = () => {
  return (
    <PageFrame
      menuActive="태블릿 설정"
      parentMenu={{ index: true }}
      children={
        <div>
          <PageTitle titleText="계약서 관리" />
          <TabletSettingView />
        </div>
      }
    />
  );
};
export default TabletSetting;
