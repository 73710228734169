import Box from "@mui/material/Box";
import TextFieldComp from "@mui/material/TextField";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { simpleAlert } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

const FileUpload = (props) => {
  const btnStyleClass = btnStyles();
  const [value, setValue] = useState(props.defaultValue);
  // 컴포넌트 재렌더링 되어도 최초의 라벨값 유지
  const docLabel = useRef(props.labelText);
  const fileInputRef = useRef(null);
  const storesDispatch = useDispatch();

  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);

  const hStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: props.justifyContent,
    marginTop: props.marginTop,
    marginRight: props.marginRight,
    marginLeft: props.marginLeft,
  };

  const labelStyle = {
    width: props.labelWidth,
    height: "2.25rem",
    marginRight: props.labelMarginRight === undefined ? "0.625rem" : props.labelMarginRight,
    marginLeft: props.labelMarginLeft,
    alignItems: "center",
    display: "flex",

    fontFamily: "AppleSDGothicNeo",
    fontSize: props.fontSize ? props.fontSize : "0.938rem",
    fontWeight: props.labelFontWeight ? props.labelFontWeight : "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
  };

  const textBoxStyle = {
    width: props.textBoxWidth,
    height: "2.25rem",
    marginRight: props.textMarginRight === undefined ? "0.625rem" : props.textMarginRight,
    borderRadius: "5px",
    // border: "solid 1px #dedede",
    backgroundColor: "#fff",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.9rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000",
  };

  const textBoxStyleDisabled = {
    width: props.textBoxWidth,
    height: "2.25rem",
    marginRight: props.textMarginRight === undefined ? "0.625rem" : props.textMarginRight,
    borderRadius: "5px",
    // border: "solid 1px #dedede",
    backgroundColor: "#ebebeb",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.9rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
  };

  const [file, setFile] = useState("");
  const [isShowDownload, setShowDownload] = useState(true);
  const [initFileFlag, setInitFileFlag] = useState(false); // initFile이 처음에 있으면 true -> 삭제 아이콘 안보임
  const onChangeFile = (e) => {
    if (e.target.files.length > 0) {
      if (!validation(e.target.files[0])) {
        return;
      }
      setFile(e.target.files[0]);
      setValue(e.target.files[0].name);
      props.onUploadFile(e.target.files[0]);
      setShowDownload(false);
      setInitFileFlag(false);
    }
  };

  useEffect(() => {
    if (props.initFile) {
      setFile(props.initFile);
      setValue(props.initFile.file_name);
      // props.onUploadFile(e.target.files[0]);
      setInitFileFlag(true);
    }
  }, [props.initFile]);

  const downloadFile = async (data) => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const obj = { file_name: data.file_name, url_path: data.url_path };
      const res = await HttpInstance.post(
        process.env.REACT_APP_AUTH_SERVER + "/file/download",
        obj,
        config,
      );
      if (res.data) {
        //성공
        // console.log("다운로드", res.data.data);
        const downloadLink = res.data.data;
        const a = document.createElement("a");
        a.href = downloadLink;
        a.download = data.file_name;
        document.body.appendChild(a);
        a.click();
        setTimeout((_) => {
          window.URL.revokeObjectURL(downloadLink);
        }, 60000);
        a.remove();

        // simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    } catch (err) {
      console.log(err, err.response.data.msg, "???");
      simpleAlert(
        storesDispatch,
        "ERROR",
        "제한된 기능입니다.\n파일이 필요하신 경우, 관리자에 문의해주세요.",
      );
    }
  };

  const onDeleteFile = () => {
    const doDelete = () => {
      fileInputRef.current.value = "";
      setFile("");
      setValue("");
      props.onUploadFile("");
    };

    simpleAlert(storesDispatch, "알림", "파일을 삭제하시겠습니까?", false, undefined, doDelete);
  };

  const validation = (file) => {
    // 업로드 파일 확장자 제한
    if (props.accept) {
      const acceptList = props.accept.split(",").map((type) => type.trim());
      if (!acceptList.includes(file.type)) {
        simpleAlert(storesDispatch, "알림", `${props.accept} 확장자 이미지만 업로드 가능합니다`);
        return false;
      }
    }
    // 업로드 파일 사이즈 제한
    if (props.fileMaxSizeMB) {
      const maxSize = props.fileMaxSizeMB * 1024 * 1024;
      if (file.size > maxSize) {
        simpleAlert(
          storesDispatch,
          "알림",
          `${props.fileMaxSizeMB}MB 이하의 이미지 업로드만 가능합니다`,
        );
        return false;
      }
    }

    return true;
  };

  return (
    <Box style={props.style || hStyle}>
      {/* <Label width={props.labelWidth} textAlign={props.labelAlign} labelText={props.labelText} /> */}
      <div style={labelStyle}>{docLabel.current}</div>
      <TextFieldComp
        inputProps={{
          style: { textAlign: props.isNumber ? "right" : "left" },
          maxLength: props.maxLength,
        }} // only for inputBox text style
        InputProps={{
          style: props.disabled === true ? textBoxStyleDisabled : textBoxStyle,
        }}
        InputLabelProps={{ style: { fontSize: "0.8rem" } }}
        id={props.id}
        disabled
        size="small"
        value={value || ""}
        name={props.name}
        // onChange={(e) => { }}
        error={props.required && (value === undefined || value === "")}
        // label={props.required && (value === undefined || value === "") ? "필수값" : ""}
      />
      {props.hideUploaded ? (
        <></>
      ) : (
        <>
          <IconButton
            type="upload"
            width="2.2rem"
            marginLeft={props.btnMarginLeft ? props.btnMarginLeft : "0.5rem"}
            onClick={() => {
              fileInputRef.current.click();
            }}
          />
          <input
            ref={fileInputRef}
            hidden
            accept={props.accept}
            type="file"
            onChange={onChangeFile}
          />
        </>
      )}
      {/* 다운로드 버튼 숨기기 (파일 x, hideDownload 상태 전달, 새로운 파일 업로드) */}
      {!file || props.hideDownload || !isShowDownload ? (
        <></>
      ) : (
        <IconButton
          type="download"
          width="2.2rem"
          marginLeft={props.btnMarginLeft ? props.btnMarginLeft : "0.5rem"}
          onClick={() => {
            downloadFile(file);
          }}
        />
      )}
      {/* 아이콘 버튼 숨기기 (파일 x, hideDelete 상태 전달, 기존의 파일이 있으면) */}
      {!file || props.hideDelete || initFileFlag ? (
        <></>
      ) : (
        <IconButton
          type="deleteBox"
          width="2.2rem"
          marginLeft={props.btnMarginLeft ? props.btnMarginLeft : "0.5rem"}
          onClick={onDeleteFile}
        />
      )}
    </Box>
  );
};

export default React.memo(FileUpload);
