import {
  dateToStr,
  generateObjectZeroId,
  getCurrentDate,
  getDefaultValue,
  timeToStr,
} from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import TimePicker from "components/LabelInput/TimePicker";
import FriendInfo from "pages/ERP/MemberCommonItems/FriendInfo";
import PriceDiscountInput from "pages/ERP/MemberRegister/Common/Etc/PriceDiscountInput";
import { useEffect, useState } from "react";

const MembershipCardItem = ({
  memberInputState,
  memberInputStateAll,
  membershipIdx,
  hqBranchPolicy,
  payInfoListAction,
  onClickOpenModal,
  selectorInfo,
  isAddPay,
  from_lock,
  updateToDate,
  last_to_date,
  defaultFromDate,
  changeItem,
}) => {
  const compWidthShort = "8.25rem";
  const compWidth = "12rem";
  const labelWidth = "4.5rem";

  const [fromDate, setFromDate] = useState(
    memberInputState.membership_list.mbshp.from_date || getCurrentDate(),
  );
  const [toDate, setToDate] = useState(memberInputState.membership_list.mbshp.to_date);
  const [toDateTime, setToDateTime] = useState(); // 일일권용
  const [otShow, setOtShow] = useState(false);
  const [friendMemberInfo, setFriendMemberInfo] = useState({
    member_name: "",
    member_id: generateObjectZeroId(),
    member_no: "",
  });
  const [disValue, setDisValue] = useState(memberInputState.membership_list.mbshp.dis);
  const [onedayFromDate, setOnedayFromDate] = useState({
    date: "",
    time: "",
  });

  const checkOtShow = () => {
    let showItem = true;

    if (isAddPay && memberInputState.tab_info.is_new === false) {
      showItem = false;
    }
    if (memberInputState.membership_list.mbshp_option === "일일권") {
      showItem = false;
    }
    if (from_lock) {
      showItem = false;
    }
    if (changeItem) {
      showItem = true;
    }

    setOtShow(showItem);
  };

  useEffect(() => {
    calcMbshpToDate(fromDate);
    if (otShow) {
      // onChangeFromDate(memberInputState.membership_list.mbshp.from_date || currentDate());
    }
    setDisValue(memberInputState.membership_list.mbshp.dis);
  }, [selectorInfo.item_name_idx]);

  useEffect(() => {
    checkOtShow();
  }, [memberInputState.membership_list.mbshp_option]);

  useEffect(() => {
    const isItemNotSelected =
      !memberInputState.purchase_item_list.item_info.item_id ||
      memberInputState.purchase_item_list.item_info.item_id === generateObjectZeroId();

    if (isAddPay) {
      if (memberInputState.tab_info.is_new && isItemNotSelected) {
        setFromDate(last_to_date);
      } else {
        setFromDate(memberInputState.membership_list.mbshp.from_date);
      }
    } else if (!isAddPay || memberInputStateAll.membership_all_list.length === 1) {
      if (defaultFromDate) {
        setFromDate(defaultFromDate);
      } else {
        setFromDate(getCurrentDate());
      }
    }

    const fromDateItem = new Date(fromDate);
    calcMbshpToDate(fromDateItem);
  }, [friendMemberInfo, updateToDate, memberInputState, defaultFromDate]);

  useEffect(() => {
    // console.log(memberInputState.membership_list.mbshp.from_date);
    // console.log(fromDate);
    if (
      !memberInputState.purchase_item_list.item_info.item_id ||
      memberInputState.purchase_item_list.item_info.item_id === generateObjectZeroId()
    ) {
      // console.log(memberInputState.purchase_item_list.item_info.item_id);
      // console.log("초기화");
      memberInputState.membership_list.mbshp.from_date = fromDate;
      onChangeFromTime("date", fromDate);
    }
  }, [fromDate]);

  useEffect(() => {
    onChangeFromTime("date", fromDate);
  }, []);

  const onChangeFriend = (memberInfo) => {
    memberInputStateAll.friend_info = memberInfo;
    setFriendMemberInfo(memberInfo);
  };

  function addMonths(numOfMonths, date = new Date()) {
    date.setMonth(date.getMonth() + numOfMonths);
    return date;
  }

  function addDay(numOfDays, date = new Date()) {
    date.setDate(date.getDate() + numOfDays);
    return date;
  }

  //subtract
  function subtDay(date = new Date()) {
    date.setDate(date.getDate() - 1);
    return date;
  }

  const calcMbshpToDate = (fromDate) => {
    if (isAddPay && memberInputState.tab_info.is_new === false) {
      setToDate(memberInputState.membership_list.mbshp.to_date);
      return;
    }

    if (memberInputState.membership_list.mbshp_option === "일일권") {
      if (fromDate !== "") {
        // const _date = new Date(fromDate);
        // _date.setHours(_date.getHours() + 2);
        // setToDate(dateTimeToStr(_date));
        // memberInputState.membership_list.mbshp.to_date = dateTimeToStr(_date);
      }
      return;
    }

    const itemBasePeriod = memberInputState.purchase_item_list.item_info.period_month;
    const itemServiceMonth = memberInputState.purchase_item_list.item_info.service_month;
    const itemServiceDay = memberInputState.purchase_item_list.item_info.service_day;
    const itemEventMonth = memberInputState.membership_list.mbshp.event_month;
    const itemEventDay = memberInputState.membership_list.mbshp.event_day;

    if (fromDate !== "") {
      // BEGIN OF  to_date_raw 셋팅
      let fromDateRaw = new Date(fromDate);
      let dateOrigin = fromDateRaw.getDate();
      let toDateRaw = addMonths(itemBasePeriod, fromDateRaw);
      if (dateOrigin > toDateRaw.getDate()) {
        toDateRaw.setDate(1);
      }
      toDateRaw = subtDay(toDateRaw);
      memberInputState.membership_list.mbshp.to_date_raw = dateToStr(toDateRaw);
      // END OF  to_date_raw 셋팅

      // 월 먼저 추가하고, 하루 뺀 다음에 일수를 추가함.
      let addMonthValue = itemBasePeriod + itemServiceMonth + itemEventMonth;
      let fromDateItem = new Date(fromDate);

      // 지인소개 (월)
      if (friendMemberInfo.member_name !== "") {
        addMonthValue += hqBranchPolicy.branch_setting.membership_policy.recommendee_month;
      }
      // 쿠폰 (월)
      if (memberInputStateAll.coupon_use_list && memberInputStateAll.coupon_use_list.length > 0) {
        if (
          memberInputStateAll.coupon_use_list[0].coupon_info.base_info.benefit_type === "이용혜택"
        ) {
          addMonthValue +=
            memberInputStateAll.coupon_use_list[0].coupon_info.detail.service_benefit.mbshp
              .add_month;
        }
      }

      // 1. 월 추가
      let toDateItem = addMonths(addMonthValue, fromDateItem);
      // console.log(dateToStr(toDateItem));

      // 2. 하루 빼기
      if (dateOrigin > toDateItem.getDate()) {
        toDateItem.setDate(1);
      }

      toDateItem = subtDay(toDateItem);

      // 3. 일수 추가
      toDateItem = addDay(itemServiceDay, toDateItem);
      toDateItem = addDay(itemEventDay, toDateItem);

      // 지인소개 (일)
      if (friendMemberInfo.member_name !== "") {
        toDateItem = addDay(
          hqBranchPolicy.branch_setting.membership_policy.recommendee_day,
          toDateItem,
        );
      }

      // 쿠폰 (일)
      if (memberInputStateAll.coupon_use_list && memberInputStateAll.coupon_use_list.length > 0) {
        if (
          memberInputStateAll.coupon_use_list[0].coupon_info.base_info.benefit_type === "이용혜택"
        ) {
          toDateItem = addDay(
            memberInputStateAll.coupon_use_list[0].coupon_info.detail.service_benefit.mbshp.add_day,
            toDateItem,
          );
        }
      }

      memberInputState.membership_list.mbshp.to_date = dateToStr(toDateItem);
      setToDate(memberInputState.membership_list.mbshp.to_date);

      // console.log(memberInputState);
    }
  };

  // 일일권에 쓰임
  const onChangeFromTime = (type, val) => {
    if (type === "date") {
      onedayFromDate.date = val;
      // setOnedayFromDate({
      //   ...onedayFromDate,
      //   date: val,
      // });
    } else {
      onedayFromDate.time = val;
      // setOnedayFromDate({
      //   ...onedayFromDate,
      //   time: val,
      // });
    }

    if (onedayFromDate.date && onedayFromDate.time) {
      memberInputState.membership_list.mbshp.from_date = onedayFromDate.date;
      memberInputState.membership_list.daily_time.from_time = onedayFromDate.time + ":00";

      const _date = new Date(onedayFromDate.date + " " + onedayFromDate.time);
      const hour = memberInputState.purchase_item_list.item_info.total_period_hour;

      _date.setHours(_date.getHours() + Number(hour));
      setToDate(dateToStr(_date));
      setToDateTime(timeToStr(_date));
      memberInputState.membership_list.mbshp.to_date = dateToStr(_date);
      memberInputState.membership_list.daily_time.end_time = timeToStr(_date) + ":00";
    }
  };

  const getOptionData = (is_lock) => {
    if (
      memberInputState.tab_info.is_add_pay === true &&
      memberInputState.tab_info.is_new === false
    ) {
      // addpaid 뺌
      // if (is_lock) {
      return memberInputState.purchase_item_list.item_info.mbshp_option;
      // } else {
      //   return memberInputState.purchase_item_addpaid.item_info.mbshp_option;
      // }
    } else {
      return memberInputState.purchase_item_list.item_info.mbshp_option;
    }
  };

  const periodFromLock = (is_lock) => {
    if (is_lock === true || from_lock === true) {
      return true;
    }
    return false;
  };

  const friendLock = (is_lock) => {
    if (is_lock === true || from_lock === true) {
      return true;
    }
    // if (selectorInfo.mbshp_option === "일일권") {
    if (memberInputState.membership_list.mbshp_option === "일일권") {
      return true;
    }
    return false;
  };

  const getOtStaffNameList = () => {
    const result = [...hqBranchPolicy.class_team_name_list];
    result.splice(0, 0, "미배정");
    return result;
  };

  const getMaxDate = (lastToDate) => {
    const maxDays = hqBranchPolicy.branch_setting.membership_policy.max_wait_day;
    if (maxDays === 0) {
      return undefined;
    }

    const today = new Date(lastToDate);
    today.setDate(today.getDate() + maxDays);
    return dateToStr(today);
  };

  return (
    <div
      style={{
        // display:"flex"
        marginTop: "0.5rem",
      }}
    >
      {selectorInfo.mbshp_option !== "일반권" ? (
        <div></div>
      ) : !otShow ? (
        <></>
      ) : (
        <div style={{ display: "flex" }}>
          <ComboBox
            marginRight="0.25rem"
            labelWidth={labelWidth}
            comboItemWidth={compWidthShort}
            labelText="행사"
            data={hqBranchPolicy.event_month_str_list}
            defaultValue={getDefaultValue(
              "combo",
              memberInputState.membership_list.mbshp.event_month,
            )}
            onChangeCallback={(e) => {
              let cDate = 0;
              if (!isNaN(e.target.value)) {
                cDate = Number(e.target.value);
              }
              memberInputState.membership_list.mbshp.event_month = cDate;
              calcMbshpToDate(fromDate);
            }}
            disabled={memberInputState.membership_list.mbshp.is_lock}
            includeSelectOption
          />
          <Label labelText="개월" />
          <div style={{ display: "flex", marginLeft: "0.25rem" }}>
            <ComboBox
              marginRight="0.25rem"
              labelMarginRight="0.15rem"
              comboItemWidth={compWidthShort}
              data={hqBranchPolicy.event_day_str_list}
              defaultValue={getDefaultValue(
                "combo",
                memberInputState.membership_list.mbshp.event_day,
              )}
              disabled={memberInputState.membership_list.mbshp.is_lock}
              onChangeCallback={(e) => {
                let cDate = 0;
                if (!isNaN(e.target.value)) {
                  cDate = Number(e.target.value);
                }
                memberInputState.membership_list.mbshp.event_day = cDate;
                calcMbshpToDate(fromDate);
              }}
              includeSelectOption
            />
            <Label labelText="일" />

            <TextField
              marginLeft="2rem"
              labelWidth="2.065rem"
              textBoxWidth="16rem"
              labelText="사유"
              defaultValue={memberInputState.membership_list.mbshp.event_reason}
              disabled={memberInputState.membership_list.mbshp.is_lock}
              onChangeCallback={(e) => {
                memberInputState.membership_list.mbshp.event_reason = e;
              }}
            />
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          marginTop: "0.5rem",
        }}
      >
        {selectorInfo.mbshp_option === "일일권" ? (
          <div style={{ display: "flex" }}>
            <DatePicker
              required
              labelWidth={labelWidth}
              minWidth={"9rem"}
              labelText="유효기간"
              textMarginRight="0rem"
              defaultValue={fromDate}
              maxDate={getMaxDate(last_to_date)}
              disabled={periodFromLock(memberInputState.membership_list.mbshp.is_lock)}
              onChangeCallback={(e) => {
                onChangeFromTime("date", e);
              }}
            />
            <TimePicker
              required
              textBoxWidth="9rem"
              labelMarginRight="0rem"
              textMarginRight="0rem"
              onBlur={(e) => {
                onChangeFromTime("time", e);
              }}
              disabled={periodFromLock(memberInputState.membership_list.mbshp.is_lock)}
            />
          </div>
        ) : (
          <DatePicker
            required
            labelWidth={labelWidth}
            minWidth={compWidthShort}
            labelText="유효기간"
            textMarginRight="0rem"
            defaultValue={fromDate}
            minDate={memberInputState.tab_info.idx > 0 ? last_to_date : undefined}
            maxDate={getMaxDate(last_to_date)}
            disabled={periodFromLock(memberInputState.membership_list.mbshp.is_lock)}
            onChangeCallback={(e) => {
              memberInputState.membership_list.mbshp.from_date = e;
              setFromDate(e);
              calcMbshpToDate(e);
            }}
          />
        )}
        <TextField
          marginLeft="0.95rem"
          labelWidth="0.95rem"
          textBoxWidth={compWidthShort}
          textMarginRight="0rem"
          labelText="~"
          defaultValue={toDate}
          disabled
        />
        {selectorInfo.mbshp_option === "일일권" ? (
          <TimePicker
            labelMarginRight="0rem"
            textBoxWidth="9rem"
            defaultValue={toDateTime}
            disabled
          />
        ) : (
          <></>
        )}

        {selectorInfo.mbshp_option !== "일일권" ? (
          <Label
            marginLeft="0.5rem"
            labelText="* 시작일 이전 출입 시 자동으로 유효기간이 조정됩니다."
            fontSize="0.75rem"
          />
        ) : (
          <></>
        )}

        <PriceDiscountInput
          baseInfo={{
            tabTitle: memberInputState.tab_info.title,
            tabIdx: memberInputState.tab_info.idx,
            orderStartIdx: membershipIdx,
            type: memberInputState.tab_info.is_add_pay
              ? memberInputState.tab_info.title + "회원권"
              : "회원권",
            typeKey: memberInputState.tab_info.is_add_pay
              ? memberInputState.tab_info.title + "회원권"
              : "회원권",
            orderIdx: 0,
            payOrder: 6,
            item_type:
              "회원권/완납형/금액/" +
              getOptionData(memberInputState.membership_list.mbshp.is_lock) +
              "/없음",
          }}
          standardPrice={memberInputState.purchase_item_list.item_info.mbshp_price}
          payInfoListAction={payInfoListAction}
          onChangeCallback={(e) => {
            memberInputState.membership_list.mbshp.dis = e;
            setDisValue(e);
          }}
          paramLock={memberInputState.membership_list.mbshp.is_lock}
          paramUse={memberInputState.membership_list.mbshp.is_use}
          // discountValue={memberInputState.membership_list.mbshp.dis}
          discountValue={disValue}
        />
      </div>

      {/* {isAddPay && !memberInputState.tab_info.is_new ? ( */}
      {selectorInfo.mbshp_option !== "일일권" ? (
        !otShow ? (
          <>
            {/* <TextField
              marginTop="0.5rem"
              labelWidth={labelWidth}
              comboItemWidth={compWidthMid}
              labelText="OT 담당자"
              defaultValue=""
              disabled={memberInputState.membership_list.mbshp.is_lock}
            /> */}
          </>
        ) : (
          <div
            style={{
              display: "flex",
              marginTop: "0.5rem",
            }}
          >
            <ComboBox
              required
              labelWidth={labelWidth}
              comboItemWidth={compWidth}
              labelText="OT 담당자"
              data={getOtStaffNameList()}
              defaultValue={memberInputStateAll.ot_staff.staff_name}
              onChangeCallback={(e) => {
                const index = hqBranchPolicy.class_team_name_list
                  .map((item) => item)
                  .indexOf(e.target.value);
                if (index !== -1) {
                  memberInputStateAll.ot_staff.staff_id =
                    hqBranchPolicy.class_team_list.team[index].staff_id;
                  memberInputStateAll.ot_staff.staff_name =
                    hqBranchPolicy.class_team_list.team[index].staff_name;
                } else {
                  memberInputStateAll.ot_staff.staff_id = generateObjectZeroId();
                  memberInputStateAll.ot_staff.staff_name = "미배정";
                }
              }}
              disabled={memberInputState.membership_list.mbshp.is_lock}
            />
          </div>
        )
      ) : (
        <div></div>
      )}

      {friendLock(isAddPay) ? (
        <></>
      ) : (
        <FriendInfo onClickOpenModal={onClickOpenModal} onChangeFriend={onChangeFriend} />
      )}
    </div>
  );
};

export default MembershipCardItem;
