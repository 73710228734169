export const salaryInfoViewAction = "SALARY/INFO_VIEW";
export const salaryInfoViewSuccessAction = "SALARY/INFO_VIEW/SUCCESS";
export const salaryInfoViewFailAction = "SALARY/INFO_VIEW/FAIL";
export const salaryInfoViewResetAction = "SALARY/INFO_VIEW/RESET";

export const salaryStatusViewAction = "SALARY/STATUS_INFO";
export const salaryStatusViewSuccessAction = "SALARY/STATUS_INFO/SUCCESS";
export const salaryStatusViewFailAction = "SALARY/STATUS_INFO/FAIL";
export const salaryStatusViewResetAction = "SALARY/STATUS_INFO/RESET";

export const staffStandardSalaryViewAction = "SALARY/STANDARD";
export const staffStandardSalaryViewSuccessAction = "SALARY/STANDARD/SUCCESS";
export const staffStandardSalaryViewFailAction = "SALARY/STANDARD/FAIL";
export const staffStandardSalaryViewResetAction = "SALARY/STANDARD/RESET";

export const mbshpSalaryViewAction = "SALARY/MBSHP";
export const mbshpSalaryViewSuccessAction = "SALARY/MBSHP/SUCCESS";
export const mbshpSalaryViewFailAction = "SALARY/MBSHP/FAIL";
export const mbshpSalaryViewResetAction = "SALARY/MBSHP/RESET";

export const ptSalaryViewAction = "SALARY/PT";
export const ptSalaryViewSuccessAction = "SALARY/PT/SUCCESS";
export const ptSalaryViewFailAction = "SALARY/PT/FAIL";
export const ptSalaryViewResetAction = "SALARY/PT/RESET";

export const gxSalaryViewAction = "SALARY/GX";
export const gxSalaryViewSuccessAction = "SALARY/GX/SUCCESS";
export const gxSalaryViewFailAction = "SALARY/GX/FAIL";
export const gxSalaryViewResetAction = "SALARY/GX/RESET";

export const etcSalaryViewAction = "SALARY/ETC";
export const etcSalaryViewSuccessAction = "SALARY/ETC/SUCCESS";
export const etcSalaryViewFailAction = "SALARY/ETC/FAIL";
export const etcSalaryViewResetAction = "SALARY/ETC/RESET";

export const classSessionViewAction = "SALARY/CLASS_SESSION";
export const classSessionViewSuccessAction = "SALARY/CLASS_SESSION/SUCCESS";
export const classSessionViewFailAction = "SALARY/CLASS_SESSION/FAIL";
export const classSessionViewResetAction = "SALARY/CLASS_SESSION/RESET";
