import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import CardFixed from "components/Card/CardFixed";
import { tabBorder, tabStyle } from "components/CommonComp";
import { getPermission } from "components/CommonLib/CommonLib";
import TabPanel from "components/TabControl/TabPanel";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import CatalogList from "../TabletSetting/CatalogList";
import BranchSelectorInfo from "./SubItems/BranchSelectorInfo";
import GxManagement from "./SubItems/GxManagement";
import ItemsManagement from "./SubItems/ItemsManagement";
import PolicyManagement from "./SubItems/PolicyManagement";
import SettingChangeHistory from "./SubItems/SettingChangeHistory";

const BranchSetting = ({
  onClickOpenModal,
  tabIndex,
  setTabIndex,
  branchId,
  selectedBranchInfo,
  onChangeBranch,
}) => {
  const [showPolicyTab, setShowPolicyTab] = useState(false);
  const [showItemTabs, setShowItemTabs] = useState(false);

  const storesDispatch = useDispatch();
  useEffect(() => {
    // 권한에 따른 탭 설정
    getPermission(storesDispatch, "BRANCH_SET_MENU/POLICY_MANAGE").then((res) => {
      setShowPolicyTab(res);
    });
    getPermission(storesDispatch, "BRANCH_SET_MENU/PRODUCT_MANAGE").then((res) => {
      setShowItemTabs(res);
    });
  }, []);

  const onChangeTabIndex = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  let rootWidth = "100%";

  return (
    <div>
      {branchId ? (
        <div>
          <BranchSelectorInfo branchId={branchId} onChangeBranch={onChangeBranch} />
          <CardFixed
            topDom={<div>지점 세부설정</div>}
            midDom={
              <div>
                {/* <Box sx={{ bgcolor: "background.paper", width: 500 }}> */}
                <Box sx={{ bgcolor: "background.paper" }}>
                  {showPolicyTab && showItemTabs ? (
                    <Tabs
                      value={tabIndex}
                      onChange={onChangeTabIndex}
                      textColor="inherit"
                      sx={{ minHeight: "40px" }}
                      TabIndicatorProps={{
                        style: { backgroundColor: "rgba(0, 0, 0, 0)" },
                      }}
                    >
                      <Tab label="정책 관리" style={tabStyle(0, tabIndex)} />
                      <Tab label="상품 관리" style={tabStyle(1, tabIndex)} />
                      <Tab label="GX 관리" style={tabStyle(2, tabIndex)} />
                      <Tab label="자료 관리" style={tabStyle(3, tabIndex)} />
                      <Tab label="변경 이력" style={tabStyle(4, tabIndex)} />
                    </Tabs>
                  ) : showPolicyTab && !showItemTabs ? (
                    <Tabs
                      value={tabIndex}
                      onChange={onChangeTabIndex}
                      textColor="inherit"
                      sx={{ minHeight: "40px" }}
                      TabIndicatorProps={{
                        style: { backgroundColor: "rgba(0, 0, 0, 0)" },
                      }}
                    >
                      <Tab label="정책 관리" style={tabStyle(0, tabIndex)} />
                      <Tab label="변경 이력" style={tabStyle(1, tabIndex)} />
                    </Tabs>
                  ) : !showPolicyTab && showItemTabs ? (
                    <Tabs
                      value={tabIndex}
                      onChange={onChangeTabIndex}
                      textColor="inherit"
                      sx={{ minHeight: "40px" }}
                      TabIndicatorProps={{
                        style: { backgroundColor: "rgba(0, 0, 0, 0)" },
                      }}
                    >
                      <Tab label="상품 관리" style={tabStyle(0, tabIndex)} />
                      <Tab label="GX 관리" style={tabStyle(1, tabIndex)} />
                      <Tab label="자료 관리" style={tabStyle(2, tabIndex)} />
                      <Tab label="변경 이력" style={tabStyle(3, tabIndex)} />
                    </Tabs>
                  ) : (
                    <></>
                  )}
                </Box>

                {showPolicyTab && showItemTabs ? (
                  <SwipeableViews
                    index={tabIndex}
                    onChangeIndex={onChangeTabIndex}
                    style={tabBorder}
                    animateTransitions={false}
                  >
                    <TabPanel value={tabIndex} index={0}>
                      <PolicyManagement branchId={branchId} />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                      <ItemsManagement onClickOpenModal={onClickOpenModal} branchId={branchId} />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={2}>
                      <GxManagement branchId={branchId} onClickOpenModal={onClickOpenModal} />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={3}>
                      <CatalogList selectedBranchInfo={selectedBranchInfo} />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={4}>
                      <SettingChangeHistory branchId={branchId} />
                    </TabPanel>
                  </SwipeableViews>
                ) : showPolicyTab && !showItemTabs ? (
                  <SwipeableViews
                    index={tabIndex}
                    onChangeIndex={onChangeTabIndex}
                    style={tabBorder}
                    animateTransitions={false}
                  >
                    <TabPanel value={tabIndex} index={0}>
                      <PolicyManagement branchId={branchId} />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                      <SettingChangeHistory branchId={branchId} />
                    </TabPanel>
                  </SwipeableViews>
                ) : !showPolicyTab && showItemTabs ? (
                  <SwipeableViews
                    index={tabIndex}
                    onChangeIndex={onChangeTabIndex}
                    style={tabBorder}
                    animateTransitions={false}
                  >
                    <TabPanel value={tabIndex} index={0}>
                      <ItemsManagement onClickOpenModal={onClickOpenModal} branchId={branchId} />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                      <GxManagement branchId={branchId} onClickOpenModal={onClickOpenModal} />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={2}>
                      <CatalogList selectedBranchInfo={selectedBranchInfo} />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={3}>
                      <SettingChangeHistory branchId={branchId} />
                    </TabPanel>
                  </SwipeableViews>
                ) : (
                  <></>
                )}

                {/* <SwipeableViews
                  index={tabIndex}
                  onChangeIndex={onChangeTabIndex}
                  style={tabBorder}
                  animateTransitions={false}
                >
                  <TabPanel value={tabIndex} index={0}>
                    <PolicySetting branchId={branchId} />
                  </TabPanel>
                  <TabPanel value={tabIndex} index={1}>
                    <ProductSetting onClickOpenModal={onClickOpenModal} branchId={branchId} />
                  </TabPanel>
                  <TabPanel value={tabIndex} index={2}>
                    <GxSetting branchId={branchId} onClickOpenModal={onClickOpenModal} />
                  </TabPanel>
                  <TabPanel value={tabIndex} index={3}>
                    <SettingChangeHistory branchId={branchId} />
                  </TabPanel>
                </SwipeableViews> */}
              </div>
            }
            isLoading={false}
            rootStyle={{
              width: rootWidth,
              // height: "100.875rem",
              // height: "100%",
              borderRadius: "10px",
              backgroundColor: "#fff",
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default BranchSetting;
