import { MainBackgroundBox, MainBottomPanel, MainPanel, RootPanel } from "components/Panels/Panels";
import { useEffect, useState } from "react";

const PageFrame = ({ isMain, children, noPadding, isMemberInfo }) => {
  // 모니터 사이즈에 따른 최소 너비 및 높이 세팅
  const [windowSize, setWindowSize] = useState({
    width: window.screen.width - 16,
    height: "100vh",
  });

  useEffect(() => {
    let prevScreenWidth = window.screen.width;

    const handleResize = () => {
      const newScreenWidth = window.screen.width;
      if (newScreenWidth !== prevScreenWidth) {
        setWindowSize({
          width: newScreenWidth - 16,
          height: "100vh",
        });
        prevScreenWidth = newScreenWidth;
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <RootPanel
        isMain={isMain}
        style={{ minWidth: windowSize.width, minHeight: windowSize.height }}
      >
        {/* <Sidebar permissionState={permissionState} /> */}
        <MainPanel $isMemberInfo={isMemberInfo}>
          {/* <MainTopPanel><Navbar permissionState={permissionState} /></MainTopPanel> */}
          {isMain ? (
            <MainBackgroundBox>
              <a
                href="https://www.freepik.com/free-vector/fractal-grid-wave-background_3923425.htm"
                style={{
                  textDecoration: "none",
                  color: "#1E2C68",
                  position: "absolute",
                  top: "7rem",
                  right: "1rem",
                  fontSize: "0.8rem",
                }}
              >
                {/* Freepik */}
              </a>
            </MainBackgroundBox>
          ) : (
            <MainBottomPanel noPadding={noPadding}>{children}</MainBottomPanel>
          )}
        </MainPanel>
      </RootPanel>
    </div>
  );
};

export default PageFrame;
