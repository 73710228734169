import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";

const VaultCashTable = ({ viewData, totalPrice }) => {
  const resultTableInst = new resultTable();

  return (
    <div style={{ marginTop: "0.5rem" }}>
      <CustomTable
        columns_head={resultTableInst.columns_head}
        table_title={resultTableInst.table_title}
        rest_call={resultTableInst.get_data_from_rest}
        row_render={resultTableInst.create_table}
        rest_data={viewData}
        height="18rem"
      />
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem" }}>
        <Label
          marginRight="2rem"
          labelText="※ 시재금을 입금해주시기 바랍니다."
          fontSize="0.8rem"
          color="#777"
          justifyContent="flex-start"
        />
        <TextField
          labelText="잔액"
          labelWidth="2.5rem"
          textBoxWidth="10rem"
          textMarginRight="0rem"
          isNumber
          defaultValue={totalPrice.toLocaleString()}
          endAdornment={"원"}
          disabled
        />
      </div>
    </div>
  );
};
export default VaultCashTable;

class resultTable {
  table_title = "";

  // constructor() {}

  create_table = (data, idx) => {
    return (
      <CustomRow
        style={{}}
        key={idx}
        // onClick={(e) => {
        //   if (e.target.nodeName === "DIV" || e.target.nodeName === "TD") {
        //     this.onClickRow(data);
        //   }
        // }}
        // hover
      >
        <CustomTableContents>{data.reg_date}</CustomTableContents>
        <CustomTableContents>{data.vault_type}</CustomTableContents>
        <CustomTableContents $isNumber>{data.price.toLocaleString()}</CustomTableContents>
        <CustomTableContents>{data.note}</CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "일시", width: "25%" },
    { title: "입출 구분", width: "25%" },
    { title: "금액", width: "25%" },
    { title: "내용", width: "25%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
