export const DUMM_GX = [
  {
    etc_salary: {
      salary_info: {
        deduct_reason: "string",
        final_salary: 0,
        salary: 0,
        salary_date: "string",
        salary_deduct: 0,
      },
    },
    gx_salary: {
      class_list: [
        {
          class_cnt: 5,
          class_hist: [
            {
              from_date: "2023-01-02",
              from_time: "10:00",
            },
          ],
          class_name: "필라테스",
          standard_price: 50000,
          total_salary: 1000000,
        },
        {
          class_cnt: 15,
          class_hist: [
            {
              from_date: "2023-01-03",
              from_time: "11:00",
            },
          ],
          class_name: "요가",
          standard_price: 30000,
          total_salary: 2000000,
        },
      ],
      salary_info: {
        deduct_reason: "string",
        final_salary: 0,
        salary: 0,
        salary_date: "string",
        salary_deduct: 0,
      },
    },
    mbshp_salary: {
      salary_info: {
        deduct_reason: "string",
        final_salary: 0,
        salary: 0,
        salary_date: "string",
        salary_deduct: 0,
      },
      sales_cnt: 0,
      sales_incentive: 0,
      sales_total: 0,
    },
    performance_info: {
      is_staff_check_up: true,
      staff_performance_id: "string",
    },
    personal_info: {
      account_holder: "string",
      account_no: "string",
      address_1: "string",
      address_2: "string",
      bank: "string",
      birthday: "string",
      email: "string",
      gender: "string",
      phone: "string",
      sabun: "string",
      social_number: "string",
      staff_name: "덤1",
      verify: true,
      zip_code: "string",
    },
    pt_salary: {
      class_info: {
        class_allowance: 0,
        class_cnt: 0,
        class_commission: 0,
        class_session_total: 0,
      },
      salary_info: {
        deduct_reason: "string",
        final_salary: 0,
        salary: 0,
        salary_date: "string",
        salary_deduct: 0,
      },
      sales_info: {
        sales_allowance: 0,
        sales_commission: 0,
        sales_total: 0,
      },
    },
    salary_info_total: {
      deduct_reason: "string",
      final_salary: 0,
      salary: 0,
      salary_date: "string",
      salary_deduct: 0,
    },
    salary_summary_all_type: {
      base_salary_total: 0,
      etc_total_salary: 0,
      gx_total_class: 0,
      mbshp_total_salary: 0,
      pt_total_class: 0,
      pt_total_commission: 0,
      salary_total: 0,
    },
    staff_id: "string",
    staff_info: {
      staff_id: "string",
      staff_name: "string",
      staff_pos: "string",
      staff_rank: "string",
    },
    standard_salary: {
      salary_info: {
        deduct_reason: "string",
        final_salary: 0,
        salary: 0,
        salary_date: "string",
        salary_deduct: 0,
      },
      salary_month: 0,
      salary_time: 0,
      total_work_time: 0,
      work_info: {
        wage: 0,
        wage_type: "string",
      },
    },
    summary_info: {
      gx_use_cnt: 0,
      mbshp_incentive_cnt: 0,
      mbshp_total_sales: 0,
      pt_total_sales: 0,
      sales_total: 0,
    },
  },
  {
    etc_salary: {
      salary_info: {
        deduct_reason: "string",
        final_salary: 0,
        salary: 0,
        salary_date: "string",
        salary_deduct: 0,
      },
    },
    gx_salary: {
      class_list: [
        {
          class_cnt: 10,
          class_hist: [
            {
              from_date: "2023-01-05",
              from_time: "10:00",
            },
          ],
          class_name: "에어로빅",
          standard_price: 50000,
          total_salary: 1000000,
        },
        {
          class_cnt: 7,
          class_hist: [
            {
              from_date: "2023-01-05",
              from_time: "13:00",
            },
          ],
          class_name: "플라잉요가",
          standard_price: 70000,
          total_salary: 1000000,
        },
        {
          class_cnt: 5,
          class_hist: [
            {
              from_date: "2023-01-05",
              from_time: "10:00",
            },
          ],
          class_name: "줌바",
          standard_price: 50000,
          total_salary: 1000000,
        },
      ],
      salary_info: {
        deduct_reason: "string",
        final_salary: 0,
        salary: 0,
        salary_date: "string",
        salary_deduct: 0,
      },
    },
    mbshp_salary: {
      salary_info: {
        deduct_reason: "string",
        final_salary: 0,
        salary: 0,
        salary_date: "string",
        salary_deduct: 0,
      },
      sales_cnt: 0,
      sales_incentive: 0,
      sales_total: 0,
    },
    performance_info: {
      is_staff_check_up: true,
      staff_performance_id: "string",
    },
    personal_info: {
      account_holder: "string",
      account_no: "string",
      address_1: "string",
      address_2: "string",
      bank: "string",
      birthday: "string",
      email: "string",
      gender: "string",
      phone: "string",
      sabun: "string",
      social_number: "string",
      staff_name: "덤2",
      verify: true,
      zip_code: "string",
    },
    pt_salary: {
      class_info: {
        class_allowance: 0,
        class_cnt: 0,
        class_commission: 0,
        class_session_total: 0,
      },
      salary_info: {
        deduct_reason: "string",
        final_salary: 0,
        salary: 0,
        salary_date: "string",
        salary_deduct: 0,
      },
      sales_info: {
        sales_allowance: 0,
        sales_commission: 0,
        sales_total: 0,
      },
    },
    salary_info_total: {
      deduct_reason: "string",
      final_salary: 0,
      salary: 0,
      salary_date: "string",
      salary_deduct: 0,
    },
    salary_summary_all_type: {
      base_salary_total: 0,
      etc_total_salary: 0,
      gx_total_class: 0,
      mbshp_total_salary: 0,
      pt_total_class: 0,
      pt_total_commission: 0,
      salary_total: 0,
    },
    staff_id: "string",
    staff_info: {
      staff_id: "string",
      staff_name: "덤3",
      staff_pos: "string",
      staff_rank: "string",
    },
    standard_salary: {
      salary_info: {
        deduct_reason: "string",
        final_salary: 0,
        salary: 0,
        salary_date: "string",
        salary_deduct: 0,
      },
      salary_month: 0,
      salary_time: 0,
      total_work_time: 0,
      work_info: {
        wage: 0,
        wage_type: "string",
      },
    },
    summary_info: {
      gx_use_cnt: 0,
      mbshp_incentive_cnt: 0,
      mbshp_total_sales: 0,
      pt_total_sales: 0,
      sales_total: 0,
    },
  },
  {
    etc_salary: {
      salary_info: {
        deduct_reason: "string",
        final_salary: 0,
        salary: 0,
        salary_date: "string",
        salary_deduct: 0,
      },
    },
    gx_salary: {
      class_list: [
        {
          class_cnt: 10,
          class_hist: [
            {
              from_date: "2023-01-05",
              from_time: "10:00",
            },
          ],
          class_name: "에어로빅",
          standard_price: 50000,
          total_salary: 1000000,
        },
      ],
      salary_info: {
        deduct_reason: "string",
        final_salary: 0,
        salary: 0,
        salary_date: "string",
        salary_deduct: 0,
      },
    },
    mbshp_salary: {
      salary_info: {
        deduct_reason: "string",
        final_salary: 0,
        salary: 0,
        salary_date: "string",
        salary_deduct: 0,
      },
      sales_cnt: 0,
      sales_incentive: 0,
      sales_total: 0,
    },
    performance_info: {
      is_staff_check_up: true,
      staff_performance_id: "string",
    },
    personal_info: {
      account_holder: "string",
      account_no: "string",
      address_1: "string",
      address_2: "string",
      bank: "string",
      birthday: "string",
      email: "string",
      gender: "string",
      phone: "string",
      sabun: "string",
      social_number: "string",
      staff_name: "덤4",
      verify: true,
      zip_code: "string",
    },
    pt_salary: {
      class_info: {
        class_allowance: 0,
        class_cnt: 0,
        class_commission: 0,
        class_session_total: 0,
      },
      salary_info: {
        deduct_reason: "string",
        final_salary: 0,
        salary: 0,
        salary_date: "string",
        salary_deduct: 0,
      },
      sales_info: {
        sales_allowance: 0,
        sales_commission: 0,
        sales_total: 0,
      },
    },
    salary_info_total: {
      deduct_reason: "string",
      final_salary: 0,
      salary: 0,
      salary_date: "string",
      salary_deduct: 0,
    },
    salary_summary_all_type: {
      base_salary_total: 0,
      etc_total_salary: 0,
      gx_total_class: 0,
      mbshp_total_salary: 0,
      pt_total_class: 0,
      pt_total_commission: 0,
      salary_total: 0,
    },
    staff_id: "string",
    staff_info: {
      staff_id: "string",
      staff_name: "덤5",
      staff_pos: "string",
      staff_rank: "string",
    },
    standard_salary: {
      salary_info: {
        deduct_reason: "string",
        final_salary: 0,
        salary: 0,
        salary_date: "string",
        salary_deduct: 0,
      },
      salary_month: 0,
      salary_time: 0,
      total_work_time: 0,
      work_info: {
        wage: 0,
        wage_type: "string",
      },
    },
    summary_info: {
      gx_use_cnt: 0,
      mbshp_incentive_cnt: 0,
      mbshp_total_sales: 0,
      pt_total_sales: 0,
      sales_total: 0,
    },
  },
];
