import * as ActionTypes from "./action_type";

///STAFF
export const loadHqStaffList = (hq_id) => {
  return {
    type: ActionTypes.getHqStaffAction,
    hq_id: hq_id,
    // branch_name: branch_name,
  };
};

export const loadHqStaffListSuccess = (data) => {
  return {
    type: ActionTypes.getHqStaffSuccessAction,
    payload: data,
  };
};

export const loadHqStaffListFail = (data) => {
  return {
    type: ActionTypes.getHqStaffFailAction,
    payload: [],
  };
};

export const loadHqStaffListReset = (data) => {
  return {
    type: ActionTypes.getHqStaffResetAction,
    payload: data,
  };
};

///BRANCH LISTb
export const loadBranchList = (hq_id) => {
  return {
    type: ActionTypes.getHqStaffBranchAction,
    hq_id: hq_id,
    // branch_name: branch_name,
  };
};

export const loadBranchListSuccess = (data) => {
  return {
    type: ActionTypes.getHqStaffBranchSuccessAction,
    payload: data,
  };
};

export const loadBranchListFail = (data) => {
  return {
    type: ActionTypes.getHqStaffBranchFailAction,
    payload: [],
  };
};

export const loadBranchListReset = (data) => {
  return {
    type: ActionTypes.getHqStaffBranchResetAction,
    payload: data,
  };
};

///STAFF PERMISSION
export const loadStaffPermissionList = (permission_param) => {
  return {
    type: ActionTypes.getHqStaffPermissionAction,
    permission_param: permission_param,
  };
};

export const loadStaffPermissionSuccess = (data) => {
  return {
    type: ActionTypes.getHqStaffPermissionSuccessAction,
    payload: data,
  };
};

export const loadStaffPermissionFail = (data) => {
  return {
    type: ActionTypes.getHqStaffPermissionFailAction,
    payload: [],
  };
};

export const loadStaffPermissionReset = (data) => {
  return {
    type: ActionTypes.getHqStaffPermissionResetAction,
    payload: data,
  };
};

export const hqPermissionAction = {
  loadHqStaffListSuccess,
  loadHqStaffListFail,
  loadHqStaffListReset,

  loadBranchListSuccess,
  loadBranchListFail,
  loadBranchListReset,

  loadStaffPermissionSuccess,
  loadStaffPermissionFail,
  loadStaffPermissionReset,
};
