import {
  loadStaffSearch,
  loadStaffSearchSuccess,
  loadStaffSearchFail,
  loadStaffSearchReset,
} from "./view_act";

export const loadStaffSearchStore = (search_param, page_num, is_excel) => loadStaffSearch(search_param, page_num, is_excel);

export const StaffSearchAction = {
  loadStaffSearch,
  loadStaffSearchSuccess,
  loadStaffSearchFail,
  loadStaffSearchReset,
};
