export const refundInitState = {
  member_id: "",
  paymentB: {
    payment_info: [], // paymentInfoState[]
  },
  refund_info_list: [], //refundInfoListState[]
  refund_list: [], // refundListState[]
  refund_sum_mary: {
    deduct_price: 0,
    done_refund_price: 0,
    paid_price: 0,
    penalty: 0,
    repaid_price: 0,
    refund_price: 0,
  },
};

export const paymentInfoState = {
  paid_item_list: [], // paidItemListState[]
  paid_price: 0,
  payment_input: {
    pay_method: [],
    total_paid: 0,
    unpaid_info: {
      promise_date: "",
      reason: "",
      unpaid_price: 0,
    },
  },
  purchase_code: "",
  unpaid_price: 0,
};

export const paidItemListState = {
  MembershipIdx: 0,
  content_obj: {
    dis: 0,
    item_type: "",
    pay_order: 0,
    price: 0,
    tab_idx: 0,
    type: "",
  },
  locker_info: {
    dis: 0,
    is_hide: true,
    is_lock: true,
    is_use: true,
    is_use_exist: true,
    locker_info: {
      family_info: {
        family_info_id: "",
        is_use: true,
        member_id: "",
        member_name: "",
      },
      from_date: "",
      mbshp_locker_id: "",
      locker_no: 0,
      locker_total_idx: 0,
      membership_id: "",
      password: "",
      queue_id: "",
      to_date: "",
      zone_id: "",
      zone_name: "",
    },
    paid_item_idx: 0,
    price: 0,
    subs_options: {
      pre_dis: 0,
      pre_price: 0,
      prorate_dis: 0,
      prorate_price: 0,
      subs_dis: 0,
      subs_price: 0,
    },
  },
  orderIdx: 0,
  type_key: "",
};

export const refundInfoListState = {
  child_idx_list: [],
  is_lock: true,
  is_merged: true,
  purchase_code: "",
  purchase_list: [
    {
      contract_total_price: 0,
      contract_price: 0,
      deduct_price: 0,
      description: "",
      discount_price: 0,
      is_merged: true,
      item_id: "",
      item_price: 0,
      item_type: "",
      mbshp_locker_id: "",
      mbshp_rfnd_day_price: 0,
      membership_id: "",
      paid_price: 0,
      penalty: 0,
      prepay_penalty_rate: 0,
      price: 0,
      pt_detail_id: "",
      purchase_code: "",
      purchase_id: "",
      reason: "",
      refund_price: 0,
      subs_penalty_rate: 0,
      unpaid_price: 0,
      usage_details: "",
    },
  ],
  selected: true,
};

export const refundListState = {
  done_refund_price: 0,
  payment_list: [], // paymentListState[]
  purchase_code: "",
  repaid_price: 0,
  refund_detail: [], // refundDetailState[]
  selected: true,
  total_deduct_price: 0,
  total_dis_return_price: 0,
  total_paid_price: 0,
  total_penalty: 0,
  total_refund_price: 0,
};

export const paymentListState = {
  payment_data: {
    account_holder: "",
    approve_no: "",
    cancel_receipt: "",
    card_buyer: "",
    card_comp: "",
    card_no: "",
    cash_approve_no: "",
    installment: "0",
    is_valid: true,
    pay_date: "",
    pay_method: "",
    pay_receipt: "",
    payment_id: "",
    price: 0,
    purchase_code: "",
    remarks: "",
  },
  payment_list_selector: {
    is_hide: true,
    is_lock: true,
    selected: true,
  },
};

export const refundDetailState = {
  account_holder: "",
  approve_no: "",
  bank_acc: "",
  cancel_date: "",
  cancel_receipt: "",
  cancel_status: "",
  cancel_type: "",
  card_buyer: "",
  card_comp: "",
  card_no: "",
  cash_approve_no: "",
  installment: "0",
  is_done: true,
  is_hide: true,
  is_lock: true,
  is_method_change: "",
  is_valid: true,
  pay_date: "",
  pay_method: "",
  pay_receipt: "",
  payment_id: "",
  price: 0,
  purchase_code: "",
  refund_method: "",
  remarks: "",
  selected: true,
};
