import { combineReducers } from "redux";
import * as ActionTypes from "../actions/action_type";

const hqStaffSetStateinitial = {
  stores: {
    data: [],
  },
};
const hq_staff_set_state = (state = hqStaffSetStateinitial, action) => {
  switch (action.type) {
    case ActionTypes.getHqStaffSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getHqStaffFailAction:
    case ActionTypes.getHqStaffResetAction:
      return { ...state, stores: hqStaffSetStateinitial.stores };
    default:
      return state;
  }
};

const hq_staff_branch_state = (state = hqStaffSetStateinitial, action) => {
  switch (action.type) {
    case ActionTypes.getHqStaffBranchSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getHqStaffBranchFailAction:
    case ActionTypes.getHqStaffBranchResetAction:
      return { ...state, stores: hqStaffSetStateinitial.stores };
    default:
      return state;
  }
};

const allStaffPermissionStateinitial = {
  stores: {
    data: {
      staff_id: "",
      branch_info: { branch_id: "", branch_name: "" },
      hq_info: { hq_id: "", hq_name: "" },
      hq_permission_list: [],
      branch_permission_list: [],
    },
  },
};

const hq_staff_permission_state = (state = allStaffPermissionStateinitial, action) => {
  switch (action.type) {
    case ActionTypes.getHqStaffPermissionSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getHqStaffPermissionFailAction:
    case ActionTypes.getHqStaffPermissionResetAction:
      return { ...state, stores: allStaffPermissionStateinitial.stores };
    default:
      return state;
  }
};

export const hqStaffPermission = combineReducers({
  hq_staff_set_state,
  hq_staff_branch_state,
  hq_staff_permission_state,
});
