import { useEffect, useState } from "react";

import * as lodash from "lodash";
import AddGxClassModal from "./AddGxClassModal";
import ETCSettingContents from "./ETCSettingContents";
import PrePayItemSetting from "./PrePayItemSub/SettingContents";
import PtItemSetting from "./PtItemSub/SettingContents";
import SubPayItemSetting from "./SubPayItemSub/SettingContents";

const HQSetIndex = ({ modalSelect, modalParam, onModalDone }) => {
  const [paramData, setParamData] = useState(modalParam.modalParam);

  useEffect(() => {
    const editData = lodash.cloneDeep(modalParam.modalParam);
    setParamData(editData);
    return () => {};
  }, []);

  if (modalSelect === "상품관리/완납형") {
    return <PrePayItemSetting modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "상품관리/PT") {
    return <PtItemSetting modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "상품관리/구독형") {
    return <SubPayItemSetting modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "상품관리/기타상품") {
    return <ETCSettingContents modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "gx수업추가") {
    return <AddGxClassModal modalParam={paramData} onModalDone={onModalDone} />;
  }
  // else if (modalSelect === "배치설정") {
  //   return <LayOutSetting modalParam={paramData} onModalDone={onModalDone} />;
  // }
  else {
    return <div>current ELSE</div>;
  }
};
export default HQSetIndex;
