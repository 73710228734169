import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, getPermission, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const ERPSetCouponNumbers = ({ modalParam }) => {
  const storesDispatch = useDispatch();
  const [couponlist, setCouponList] = useState([]);
  const couponList = new couponNumbersTable();

  const [canExcel, setCanExcel] = useState(false);

  const checkPermission = async () => {
    const result = await getPermission(storesDispatch, "ACCOUNT_PERMISSION/EXCEL");
    setCanExcel(result);
  };
  useEffect(() => {
    HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/erp_set/coupon/view", {
      params: {
        coupon_id: modalParam.coupon_id,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setCouponList(res.data.data.ticket_list);
        }
      })
      .catch((err) => {
        console.log("err");
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
    checkPermission();
  }, []);

  const onClickExcelDownload = () => {};

  return (
    <div style={{ width: "25rem" }}>
      <div style={{ display: "flex", justifyContent: "right" }}>
        {canExcel && (
          <NormalBtn name="엑셀 다운로드" theme="green" onClick={onClickExcelDownload} />
        )}
      </div>
      <div style={{ marginTop: "0.5rem" }}>
        <CustomTable
          columns_head={couponList.columns_head}
          table_title={couponList.table_title}
          rest_call={couponList.get_data_from_rest}
          row_render={couponList.create_table}
          rest_data={couponlist}
        />
      </div>
      {/* <CustomPageNation curPage={curPage} doSearch={setCurPage} totalCnt={pageMax} /> */}
    </div>
  );
};
export default ERPSetCouponNumbers;

class couponNumbersTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onClickEdit = null;
  onClickShowCoupons = null;

  constructor(onClickEdit, onClickShowCoupons) {
    this.onClickEdit = onClickEdit;
    this.onClickShowCoupons = onClickShowCoupons;
  }

  create_table = (data) => {
    return (
      <CustomRow style={{ width: "100%" }}>
        <CustomTableContents width="70%">{data.ticket_id}</CustomTableContents>
        <CustomTableContents width="30%">{data.is_used === true ? "Y" : "N"}</CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "쿠폰번호", field: "ticket_id" },
    { title: "사용여부", field: "is_used" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
