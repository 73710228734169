export const etcSalaryInitState = {
  stores: {
    data: [
      // {
      //   etc_id: "",
      //   etc_info: {
      //     reason: "",
      //     salary_date: 0,
      //     wage: 0,
      //   },
      //   personal_info: {
      //     account_holder: "",
      //     account_no: "",
      //     address_1: "",
      //     address_2: "",
      //     bank: "",
      //     birthday: "",
      //     email: "",
      //     gender: "",
      //     phone: "",
      //     sabun: "",
      //     social_number: "",
      //     staff_name: "",
      //     verify: false,
      //     zip_code: "",
      //   },
      //   salary_info: {
      //     deduct_reason: "",
      //     final_salary: 0,
      //     salary: 0,
      //     salary_date: "",
      //     salary_deduct: 0,
      //   },
      //   base_info: {
      //     position: {
      //       org_name: "",
      //     },
      //     rank: {
      //       org_name: "",
      //     },
      //   },
      //   staff_id: "",
      // },
    ],
    final_total_salary: 0,
  },
};
