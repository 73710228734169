import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import CardFixed from "components/Card/CardFixed";
import { tabBorder, tabStyle } from "components/CommonComp";
import { getPermission } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import NormalBtn from "components/NewButton/NormalBtn";
import TabPanel from "components/TabControl/TabPanel";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import ContractList from "./ContractList";

const TabletSettingView = () => {
  const navigate = useNavigate();
  // 지점 관리에서 넘어왔을 때 branch 정보 담아옴
  const { state: branch_info } = useLocation();
  const rootWidth = "75rem";
  // 지점 관리 페이지 넘어갈 때 보낼 탭 인덱스 값
  const hqSettingTabIndex = 1;
  const [tabIndex, setTabIndex] = useState(0);

  const storesDispatch = useDispatch();
  const [branchList, setBranchList] = useState({ branch_list: [], branch_name_list: [] });
  const [selectedBranchInfo, setSelectedBranchInfo] = useState({ branch_id: "", branch_name: "" });

  const getBranchCombo = async () => {
    // branch 데이터가 있을때 세팅
    branch_info && setSelectedBranchInfo(branch_info);
    // 접근 권한 리스트 받아오기
    const result = await getPermission(storesDispatch, "ACCESS_MENU/MANAGE", true, true);
    setBranchList(result);
    // 권한에 따라 tablet_setting 접근 시
    if (result.branch_name_list[0] === "없음") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="접근 권한이 없습니다." />
            </>
          ),
          onBtnOk: () => {
            navigate("/");
          },
        }),
      );
    }
  };

  useEffect(() => {
    getBranchCombo();
  }, []);

  const onChangeTabIndex = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <CardFixed
      topDom={
        <div style={{ display: "flex", justifyContent: "right" }}>
          <ComboBox
            labelMarginLeft="2.5rem"
            labelWidth={"2rem"}
            comboItemWidth={"12rem"}
            labelText="지점"
            disabled={branch_info ? true : false}
            defaultValue={branch_info ? branch_info.branch_name : "defaultValue"}
            data={branchList.branch_name_list}
            onChangeCallback={(e) => {
              const branch = branchList.branch_list.find((el) => el.branch_name === e.target.value);
              setSelectedBranchInfo(branch);
            }}
          />
          <NormalBtn
            name={"목록으로"}
            theme={"white"}
            onClick={() => navigate("/hq_setting", { state: hqSettingTabIndex })}
          />
        </div>
      }
      midDom={
        <div>
          <Box sx={{ bgcolor: "background.paper" }}>
            <Tabs
              value={tabIndex}
              onChange={onChangeTabIndex}
              textColor="inherit"
              sx={{ minHeight: "40px" }}
              TabIndicatorProps={{
                style: { backgroundColor: "rgba(0, 0, 0, 0)" },
              }}
            >
              <Tab label="계약서 목록" style={tabStyle(0, tabIndex)} />
            </Tabs>
          </Box>
          <SwipeableViews
            index={tabIndex}
            onChangeIndex={onChangeTabIndex}
            style={tabBorder}
            animateTransitions={false}
          >
            <TabPanel value={tabIndex} index={0}>
              <ContractList selectedBranchInfo={selectedBranchInfo} />
            </TabPanel>
          </SwipeableViews>
        </div>
      }
      isLoading={false}
      rootStyle={{
        width: rootWidth,
        // height: "74.875rem",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};
export default TabletSettingView;
