import * as ActionTypes from "./action_type";

///MEMBER VIEW
export const loadSearchPtClassHist = (search_param) => {
  return {
    type: ActionTypes.searchPtClassHist,
    search_param: search_param,
  };
};

export const loadSearchPtClassHistSuccess = (data) => {
  return {
    type: ActionTypes.searchPtClassHistSuccess,
    payload: data,
  };
};

export const loadSearchPtClassHistFail = (data) => {
  return {
    type: ActionTypes.searchPtClassHistFail,
    payload: [],
  };
};

export const loadSearchPtClassHistReset = (data) => {
  return {
    type: ActionTypes.searchPtClassHistReset,
    payload: data,
  };
};

//////////////Oㅅ

export const loadSearchOtClassHist = (search_param) => {
  return {
    type: ActionTypes.searchOtClassHist,
    search_param: search_param,
  };
};

export const loadSearchOtClassHistSuccess = (data) => {
  return {
    type: ActionTypes.searchOtClassHistSuccess,
    payload: data,
  };
};

export const loadSearchOtClassHistFail = (data) => {
  return {
    type: ActionTypes.searchOtClassHistFail,
    payload: [],
  };
};

export const loadSearchOtClassHistReset = (data) => {
  return {
    type: ActionTypes.searchOtClassHistReset,
    payload: data,
  };
};
