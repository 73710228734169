import { generateObjectDummyId } from "components/CommonLib/CommonLib";

export const cardInfoInitState = {
  stores: {
    data: {
      card_info_id: generateObjectDummyId(),
      card_no: "",
      card_serial_no: "",
      card_status: "",
      branch_info: {
        branch_id: generateObjectDummyId(),
        branch_name: "",
      },
      user_group: "",
      cur_user: {
        user_id: generateObjectDummyId(),
        user_name: "",
        member_no: "",
        sabun: "",
      },
      cur_reg_date: "",
      remarks: "",
      assign_hist: [],
    },
    staff_access_list: [],
  },
};
