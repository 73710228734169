//reg_input_info
export const inputInitState = {
  contract_doc_list: [],
  active_info: {
    active_lockers: [
      {
        family_info: {
          family_info_id: "",
          is_use: true,
          member_id: "",
          member_name: "",
        },
        from_date: "",
        mbshp_locker_id: "",
        locker_no: 0,
        locker_total_idx: 0,
        membership_id: "",
        password: "",
        queue_id: "",
        to_date: "",
        zone_id: "",
        zone_name: "",
      },
    ],
    active_mbshp_status: "",
    active_membership: {
      daily_time: {
        end_time: "",
        from_time: "",
      },
      family_info: {
        family_info_id: "",
        is_use: true,
        member_id: "",
        member_name: "",
      },
      from_date: "",
      is_new: true,
      is_use: true,
      link_info: {
        link_info_total: 0,
        link_list: [
          {
            branch_info: {
              branch_id: "",
              branch_name: "",
            },
            enter_date: "",
          },
        ],
        used_link_cnt: 0,
      },
      mbshp_option: "",
      mbshp_type: "",
      membership_id: "",
      pass_time: {
        end_time: "",
        from_time: "",
      },
      remain_pass_cnt: 0,
      to_date: "",
      use_cloth: true,
      use_gx: true,
    },
    active_ot: {
      ot_detail_id: "",
    },
    active_postpone: {
      approve_status: "",
      doc_info: {
        doc_id: "",
        doc_info: {
          doc_name: "",
          file_name: "",
          reason: "",
          reg_info: {
            created_at: "",
            reg_id: "",
            reg_name: "",
            upd_id: "",
            upd_name: "",
            updated_at: "",
          },
          url_path: "",
        },
        file_due_date: "",
        member_info: {
          member_id: "",
          member_name: "",
          member_no: "",
        },
        purchase_code: "",
      },
      from_date: "",
      mbshp_to_date: "",
      member_id: "",
      membership_id: "",
      postpone_day: 0,
      postpone_id: "",
      postpone_type: "",
      reg_info: {
        created_at: "",
        reg_id: "",
        reg_name: "",
        upd_id: "",
        upd_name: "",
        updated_at: "",
      },
      remarks: "",
      stop_date: "",
      to_date: "",
    },
    active_pt: {
      pt_detail_id: "",
    },
    active_pt_status: "",
    is_mbshp_assign: true,
    is_mbshp_refund: true,
    is_pt_assign: true,
    is_pt_refund: true,
    last_contract_to_date: "",
    last_mbshp_to_date: "",
    mbshp_purchase_cnt: 0,
    member_id: "",
    pt_purchase_cnt: 0,
    regular_pay_method: {
      extra_price: 0,
      pay_date: "",
      payment_method: {
        card_no: "",
        company: "",
        exp_month: 0,
        exp_year: 0,
        id_no: "",
        is_company: "",
        name: "",
        pay_method: "",
        phone: "",
      },
      payment_plan_list: [
        {
          idx: 0,
          pay_date: "",
          price: 0,
        },
      ],
      price_next_month: 0,
      reg_info: {
        created_at: "",
        reg_id: "",
        reg_name: "",
        upd_id: "",
        upd_name: "",
        updated_at: "",
      },
      relation: "",
      subs_period_month: 0,
    },
    remain_day: 0,
    remain_pt_cnt: 0,
  },
  base_info: {
    coupon_list: [
      {
        coupon_info: {
          base_info: {
            benefit_product: "",
            benefit_type: "",
            benefit_use: "",
            can_gift: "",
            condition: true,
            expired_day: 0,
            expired_from_date: "",
            expired_month: 0,
            expired_to_date: "",
            img: "",
            issue_date: "",
            status: "",
            type: "",
            use_target: "",
          },
          coupon_id: "",
          detail: {
            coupon_limit_cnt: 0,
            coupon_name: "",
            coupon_used_cnt: 0,
            first_come_cnt: 0,
            is_coupon_unlimited: true,
            is_use_first_come: true,
            price_benefit: {
              dis_price: 0,
              dis_rate: 0,
              type: "",
            },
            recv_cnt: 0,
            service_benefit: {
              branch_info: {
                branch_id: "",
                branch_name: "",
              },
              mbshp: {
                add_day: 0,
                add_month: 0,
                mbshp_option: "",
                mbshp_type: "",
              },
              pt: {
                add_session: 0,
              },
            },
          },
          reg_info: {
            created_at: "",
            reg_id: "",
            reg_name: "",
            upd_id: "",
            upd_name: "",
            updated_at: "",
          },
          ticket_list: [
            {
              is_issuance: true,
              is_used: true,
              member_info: {
                member_id: "",
                member_name: "",
                member_no: "",
              },
              ticket_id: "",
            },
          ],
          view_info: {
            is_empty: true,
            is_new: true,
            is_used: true,
          },
        },
        membership_info: {
          membership_id: "",
          purchase_item_id: "",
        },
        purchase_code: "",
        ticket_info: {
          is_issuance: true,
          is_used: true,
          member_info: {
            member_id: "",
            member_name: "",
            member_no: "",
          },
          ticket_id: "",
        },
      },
    ],
    family_info_list: [
      {
        branch_info: {
          branch_id: "",
          branch_name: "",
        },
        connected_list: [
          {
            item_name: "",
            membership_id: "",
          },
        ],
        doc_list: [
          {
            doc_name: "",
            file_name: "",
            reason: "",
            reg_info: {
              created_at: "",
              reg_id: "",
              reg_name: "",
              upd_id: "",
              upd_name: "",
              updated_at: "",
            },
            url_path: "",
          },
        ],
        family_info_id: "",
        member_id: "",
        personal_info: {
          address_1: "",
          address_2: "",
          agree_marketing: true,
          agree_personal: true,
          birth: "",
          email: "",
          gender: "",
          member_name: "",
          member_no: "",
          notice: "",
          phone: "",
          reg_info: {
            created_at: "",
            reg_id: "",
            reg_name: "",
            upd_id: "",
            upd_name: "",
            updated_at: "",
          },
          reg_path: "",
          zip_code: "",
        },
        reg_info: {
          created_at: "",
          reg_id: "",
          reg_name: "",
          upd_id: "",
          upd_name: "",
          updated_at: "",
        },
        reg_status: "",
      },
    ],
    member_id: "",
    personal_info: {
      address_1: "",
      address_2: "",
      agree_marketing: true,
      agree_personal: true,
      birth: "",
      email: "",
      gender: "",
      member_name: "",
      member_no: "",
      notice: "",
      phone: "",
      reg_info: {
        created_at: "",
        reg_id: "",
        reg_name: "",
        upd_id: "",
        upd_name: "",
        updated_at: "",
      },
      reg_path: "",
      zip_code: "",
    },
    point: {
      acc_receive_point: 0,
      acc_spend_point: 0,
      remain_point: 0,
    },
    recommend: {
      is_use: true,
      member_info: {
        member_id: "",
        member_name: "",
        member_no: "",
      },
      recommendee_day: 0,
      recommendee_month: 0,
      recommender_day: 0,
      recommender_month: 0,
    },
  },
  cs_staff: {
    staff_id: "",
    staff_name: "",
    staff_pos: "",
    staff_rank: "",
  },
  cs_team_info_list: [
    {
      department: {
        org_id: "",
        org_name: "",
      },
      position: {
        org_id: "",
        org_name: "",
      },
      rank: {
        org_id: "",
        org_name: "",
      },
      show_name: "",
      staff_id: "",
      staff_name: "",
    },
  ],
  cs_team_name_list: [],
  item_list: [
    {
      item_name: "",
      mbshp_option: "",
      mbshp_type: "",
      moonth_price: 0,
      period: "",
      price: 0,
      status: "",
      subs_period: "",
      total_price: 0,
    },
  ],
  member_id: "",
  select_branch_id: "",
  service_price: 0,
  target_branch_list: [
    {
      branch_id: "",
      branch_name: "",
    },
  ],
  target_branch_name_list: [],
};
