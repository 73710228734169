import { Button } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import TimePicker from "components/LabelInput/TimePicker";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useState } from "react";
import { useDispatch } from "react-redux";

const AddGxClassModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();
  const [newInfo, setNewInfo] = useState({
    branch_info: {
      branch_id: "",
      branch_name: "",
    },
    hq_info: {
      hq_id: "",
      hq_name: "",
    },
    gx_info: {
      gx_id: "",
      gx_code: "",
    },
    class_name: "",
    class_from_time: "",
    class_to_time: "",

    color_code: "#F9DC5C",
    schedule_week_idx_list: [],
  });

  const ToggleBtn = styled(ToggleButton)({
    width: "2.85rem",
    "&.Mui-selected": {
      color: "#fff",
      backgroundColor: "#032063",
    },
    "&.Mui-selected:hover": {
      color: "#fff",
      backgroundColor: "#032063",
    },
  });

  const validationCheck = () => {
    if (!newInfo.class_name) {
      simpleAlert(storesDispatch, "알림", "수업명을 입력해주세요.", false);
    } else if (newInfo.schedule_week_idx_list.length === 0) {
      simpleAlert(storesDispatch, "알림", "수업 요일을 선택해주세요.", false);
    } else if (!newInfo.class_from_time || !newInfo.class_to_time) {
      simpleAlert(storesDispatch, "알림", "수업시간을 선택해주세요.", false);
    } else if (!newInfo.color_code) {
      simpleAlert(storesDispatch, "알림", "스케줄 색상을 선택해주세요.", false);
    } else if (newInfo.class_from_time >= newInfo.class_to_time) {
      simpleAlert(storesDispatch, "알림", "수업 시작 시간은 종료 시간보다 클 수 없습니다.", false);
    } else {
      // 통과
      simpleAlert(storesDispatch, "알림", "저장 하시겠습니까?", false, undefined, addGxClass);
    }
  };

  //gx수업 추가 POST
  const addGxClass = async () => {
    try {
      const formData = new FormData();
      newInfo.branch_info.branch_id = modalParam.branchId;

      formData.append("new_info", JSON.stringify(newInfo));

      const res = await HttpInstance.patch(
        process.env.REACT_APP_BRANCH_SERVER + "/branch_set/gx_class/add",
        formData,
        contentTypeJson,
      );

      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: <Label labelText="수업이 추가되었습니다." />,
            onBtnOk: () => {
              onModalDone();
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    }
  };

  return (
    <div>
      <TextField
        labelWidth={"5rem"}
        textBoxWidth={"19.6rem"}
        labelText="수업명"
        onChangeCallback={(e) => {
          setNewInfo((cur) => {
            return { ...cur, class_name: e };
          });
        }}
      />
      <div style={{ marginTop: "0.5rem", display: "flex" }}>
        <Label labelText="수업 요일" labelWidth="5rem" fontSize="0.938rem" />
        <ToggleButtonGroup
          value={newInfo.schedule_week_idx_list}
          onChange={(event, value) => {
            setNewInfo((cur) => {
              return { ...cur, schedule_week_idx_list: value };
            });
          }}
          aria-label="text alignment"
          style={{ marginLeft: "1.9rem" }}
        >
          <ToggleBtn value={0} aria-label="centered">
            일
          </ToggleBtn>
          <ToggleBtn value={1} aria-label="centered">
            월
          </ToggleBtn>
          <ToggleBtn value={2} aria-label="centered">
            화
          </ToggleBtn>
          <ToggleBtn value={3} aria-label="centered">
            수
          </ToggleBtn>
          <ToggleBtn value={4} aria-label="centered">
            목
          </ToggleBtn>
          <ToggleBtn value={5} aria-label="centered">
            금
          </ToggleBtn>
          <ToggleBtn value={6} aria-label="centered">
            토
          </ToggleBtn>
        </ToggleButtonGroup>
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <TimePicker
          labelWidth={"5rem"}
          textBoxWidth={"9rem"}
          labelText="수업 시간"
          textMarginRight="0.5rem"
          onChange={(e) => {
            setNewInfo((cur) => {
              return { ...cur, class_from_time: e };
            });
          }}
        />

        <TimePicker
          labelWidth={"0.5rem"}
          textBoxWidth={"9rem"}
          labelText="~"
          textMarginRight
          onChange={(e) => {
            setNewInfo((cur) => {
              return { ...cur, class_to_time: e };
            });
          }}
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <Label labelText="스케줄 컬러" labelWidth="5rem" fontSize="0.938rem" marginRight="1.1rem" />
        <datalist id="color-options">
          <option>#F9DC5C</option>
          <option>#FFD3DA</option>
          <option>#EE7674</option>
          <option>#E27698</option>
          <option>#DAB9E3</option>
          <option>#BEE9E8</option>
          <option>#6DCDCB</option>
          <option>#01A7C2</option>
          <option>#C0CEA3</option>
          <option>#B1BBDA</option>
          <option>#D0BFA6</option>
          <option>#EFEFEF</option>
        </datalist>
        <input
          type="color"
          onChange={(e) => {
            setNewInfo((cur) => {
              return { ...cur, color_code: e.target.value };
            });
          }}
          value={newInfo.color_code}
          list="color-options"
          style={{ borderRadius: "5px", border: "1px solid #dedede", backgroundColor: "#fff" }}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "center", marginTop: "0.7rem" }}>
        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={validationCheck}
        >
          저장
        </Button>
      </div>
    </div>
  );
};

export default AddGxClassModal;
