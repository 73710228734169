import { contentTypeJson, restEmpty, simpleAlert, SubLine } from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import ModalBtnModeGroup from "components/ModalBtnModeGroup/ModalBtnModeGroup";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import post_api from "../../Module/api/PostApi";
import { branchPolicySetStateinitial } from "../../Module/reducers/policy_state";
import { ptItemInfoInitial } from "../../Module/reducers/pt_state";
import SideItemSetting from "./SideItemSetting";

const ItemInfo = ({ restData, isEditMode }) => {
  const textLabelWidth = "6rem";
  const textBoxWidthLong = "33.25rem";
  const comboItemWidth = "12rem";

  const [salesStatus, setSalesStatus] = useState([]);

  useEffect(() => {
    switch (restData.base_info.sales_status) {
      case "판매대기":
        setSalesStatus(["판매대기", "판매중"]);
        break;
      case "판매중":
        setSalesStatus(["판매중", "판매종료"]);
        break;
      case "판매종료":
        setSalesStatus(["판매중", "판매종료"]);
        break;
      default:
        setSalesStatus([]);
        break;
    }
  }, [restData]);

  return (
    <div>
      <div
        style={{
          display: "flex",
        }}
      >
        <ComboBox
          labelWidth={textLabelWidth}
          comboItemWidth={comboItemWidth}
          labelText="판매 상태"
          data={salesStatus}
          defaultValue={restData.base_info.sales_status}
          onChangeCallback={(e) => {
            restData.base_info.sales_status = e.target.value;
          }}
        />
        <CheckBox
          marginTop="0rem"
          marginRight="0rem"
          labelWidth={textLabelWidth}
          labelText="판매 제한"
          defaultValue={restData.base_info.sales_limit}
          onChangeCallback={(e) => {
            restData.base_info.sales_limit = e.checked;
          }}
        />
        <CheckBox
          marginLeft="0rem"
          marginTop="0rem"
          labelWidth={textLabelWidth}
          labelText="App 노출"
          defaultValue={restData.base_info.show_in_app}
          onChangeCallback={(e) => {
            restData.base_info.show_in_app = e.checked;
          }}
        />
      </div>
      <SubLine />
      <TextField
        required
        labelWidth={textLabelWidth}
        textBoxWidth={textBoxWidthLong}
        labelText="상품명"
        defaultValue={restData.item_name}
        onChangeCallback={(e) => {
          restData.item_name = e;
        }}
        disabled={isEditMode}
      />
    </div>
  );
};

const SettingContents = ({ modalParam, onModalDone }) => {
  const isEditMode = modalParam.mode === "edit";
  const storesDispatch = useDispatch();

  const [ptItemInfo, setPtItemInfo] = useState(_.cloneDeep(ptItemInfoInitial));
  const [branchPolicy, setBranchPolicy] = useState(
    _.cloneDeep(branchPolicySetStateinitial.stores.data),
  );
  const [loading, setLoading] = useState(false);

  // pt 정보 가져오기
  const getPtItemInfo = () => {
    return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/branch_set/pt_view", {
      params: {
        branch_id: modalParam.branchId,
        item_id: modalParam.itemId,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setPtItemInfo(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };
  // 가격 정책 가져오기
  const getBranchPolicy = () => {
    return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/branch_set/policy_view", {
      params: {
        branch_id: modalParam.branchId,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setBranchPolicy(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const validation = () => {
    let res = "";
    if (!isEditMode) {
      if (!ptItemInfo.item_name) {
        res += "· 상품명\n";
      }

      if (!ptItemInfo.session_cnt) {
        res += "· PT 세션\n";
      }
    }
    return res;
  };

  const onClickSave = () => {
    const validationRes = validation();
    if (validationRes !== "") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="필수값을 모두 입력해주셔야 합니다." />
              <br />
              <br />
              <Label labelText={validationRes} whiteSpace="pre-wrap" />
            </>
          ),
          // width: "50rem",
          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="저장하시겠습니까?" />
            </>
          ),
          onBtnYes: () => {
            onSaveEvent();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const onSaveEvent = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("branch_id", modalParam.branchId);
    formData.append("item_info", JSON.stringify(ptItemInfo));
    const postApi = isEditMode ? post_api.updatePtApi() : post_api.newPtApi();
    await HttpInstance.post(postApi, formData, contentTypeJson)
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          onModalDone(modalParam.refreshStore);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
        // onModalDone(modalParam.refreshStore);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // 수정모드일때 pt 정보 가져오기
    if (isEditMode) {
      getPtItemInfo();
    }
    // 가격 정책 가져오기
    getBranchPolicy();
  }, []);

  return (
    <div style={{}}>
      {loading && <Loading />}

      <ModalBtnModeGroup mode={modalParam.mode} onClickEvent={onClickSave} />
      <ItemInfo restData={ptItemInfo} isEditMode={isEditMode} />

      <SideItemSetting
        branchPolicy={branchPolicy.contents}
        ptItemInfo={ptItemInfo}
        isEditMode={isEditMode}
        setPtItemInfo={setPtItemInfo}
      />
    </div>
  );
};
export default SettingContents;
