export const branchItemState = {
  base_info: {
    sales_status: "",//"string / 판매 상태",
    incentive: "0",//"int / 판매 인센티브",
    sales_limit: false,//"bool / 판매 제한 여부",
    order: "0",//"int / 게시 순서",
    tablet_img: "",//"string / 태블릿 표시 이미지 파일명",
    mem_app_img: "",//"string / 회원앱 표시 이미지 파일명",
  },
  item_id: "",//"objectId / 상품 ID",
  item_name: "",//"string / 상품명",
  mbshp_type: "",//"string / 회원권 종류"
  mbshp_option: "",
  pt_per_price: "0",
  pt_price: "0",//"int / PT 금액",
  pt_session: "0",//"int / PT 결합 시 PT 세션",
  is_combine_cloth: false,//"bool / 운동복 결합 여부",
  is_combine_gx: false,//"bool / GX 결합 여부",
  is_combine_locker: false,//"bool / 락커 결합 여부",
  is_combine_pt: false,//"bool / PT 결합 여부",
  postpone_cnt: "0",//"int / 연기 가능 횟수",
  ot_session: "0",
  can_assign: false,
  can_family: false,//"bool / 가족등록 가능 여부",
  can_change: false,//"bool / 상품변경 가능 여부",
  pass_cnt: "0",//"int / (패스권)출입 횟수",
  pass_from_time: "",//"string / (시간권)출입 시작시간",
  pass_end_time: "",//"string / (시간권)출입 종료시간",
  total_price: "0",//"int / 판매가",
  reg_price: "0",//"int / 등록비",
  total_period_month: "0",//"int / 총 유효기간(월)",
  total_period_day: "0",//"int / 총 유효기간(일)",
  total_period_hour: "0",//"int / (일일권) 총 유효기간(시간)",
  period_month: "0",//"int / 기본 유효기간(월)",
  service_month: "0",//"int / 서비스 기간(월)",
  service_day: "0",//"int / 서비스 기간(일)",
  oneday_wait_hour: "0",//"int / (일일권) 사용 대기시간",
  mbshp_price: "0",//"int / 회원권 금액 (구독형의 경우 회원권 판매가 합계)",
  cloth_price: "0",//"int / 운동복 금액 (구독형의 경우 운동복 판매가 합계)",
  gx_price: "0",//"int / GX 금액 (구독형의 경우 GX 판매가 합계)",
  locker_price: "0",//"int / 락커 금액 (구독형의 경우 락커 판매가 합계)",
  standard_price: {
    prepay_reg: "0",//"int /  회원권 등록비 or 등록수수료",
    prepay_mbshp: "0",//"int /  회원권 1개월 정가",
    prepay_cloth: "0",//"int /  운동복 1개월 정가",
    prepay_gx: "0",//"int /  GX 1개월 정가",
    prepay_locker: "0",//"int /  락커 1개월 정가",
    oneday_mbshp: "0",//"int / 회원권 1일(일일권) 정가",
    oneday_cloth: "0",//"int / 운동복 1일 정가",
    oneday_gx: "0",//"int / GX 1일 정가",
    ot: "0",//"int / OT 1회 정가",
    pt: "0",//"int / PT 1회 정가",
  },
  subs_options: {
    subs_reg: "0",
    subs_period_month: "0",//"int / 약정 기간(개월)",
    pre_month: "0",//"int / 선납기간(개월)",
    total_subs_price: "0",//"int / 월 구독료 합계",
    mbshp_subs_price: "0",//"int / 회원권 월 구독료",
    mbshp_pre_price: "0",//"int / 회원권 선납금 판매가",
    cloth_subs_price: "0",//"int / 운동복 월 구독료",
    cloth_pre_price: "0",//"int / 운동복 선납금 판매가",
    gx_subs_price: "0",//"int / GX 월 구독료",
    gx_pre_price: "0",//"int / GX 선납금 판매가",
    locker_subs_price: "0",//"int / 락커 월 구독료",
    locker_pre_price: "0",//"int / 락커 선납금 판매가",
  },
  reg_info: {
    created_at: "",
    updated_at: "",
    reg_name: "",
    reg_id: "",
    upd_id: "",
    upd_name: ""
  },
};
