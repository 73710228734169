import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import SalesRecordWrapper from "./SalesRecordWrapper";

const SalesRecordService = () => {
  return (
    <PageFrame
      children={
        <div>
          <PageTitle titleText="실적관리" />
          <SalesRecordWrapper />
        </div>
      }
    />
  );
};
export default SalesRecordService;
