import * as ActionTypes from "./action_type";

///MEMBER VIEW
export const loadUnPayView = (member_id) => {
  return {
    type: ActionTypes.getUnPayViewAction,
    member_id: member_id,
  };
};

export const loadUnPayViewSuccess = (data) => {
  return {
    type: ActionTypes.getViewSuccessAction,
    payload: data,
  };
};

export const loadUnPayViewFail = (data) => {
  return {
    type: ActionTypes.getUnPayViewFailAction,
    payload: [],
  };
};

export const loadUnPayViewReset = (data) => {
  return {
    type: ActionTypes.getUnPayViewResetAction,
    payload: data,
  };
};
