import IconButton from "components/Button/IconButton";
import { getHqBranchId } from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import SubTitle from "components/PageTitle/SubTitle";
import { useEffect, useState } from "react";
import ButtonActionGroup from "./ButtonAction/ButtonActionGroup";

const WorkInfoBase = ({
  pageMode,
  modalParam,
  hqBranchList,
  affiliation,
  workInfoStore,
  hqIdx,
  onChangeHq,
  branchIdx,
  setBranchIdx,
  setPositionIdx,
  onSaveEvent,
  hqPermissions,
}) => {
  const isEditMode = pageMode === "추가" || pageMode === "수정";
  const labelWidth = "3.5rem";
  const compWidth = "15rem";
  const compMarginLeft = "1.5rem";
  const [hqOnlyState, setHqOnlyState] = useState(false);

  useEffect(() => {
    if (pageMode === "추가") {
      onChangeHq(hqIdx);
    }
  }, []);

  const transLimitDate = workInfoStore.permission.work_trans_limit_date;

  // 본부직원여부 체크박스가 비활성화여야 하는지 확인 (AddHqModal.js에 동일하게 선언함)
  const isDisabledHqOnly = () => {
    if (pageMode !== "추가") {
      return false;
    }

    const curHqId = hqBranchList.target_list[hqIdx].hq_info.hq_id;

    const hqPermIdx = modalParam.hqPermissions.findIndex((x) => curHqId === x.hq_info.hq_id);
    if (hqPermIdx === -1) {
      return true;
    }

    const hqPerm = modalParam.hqPermissions[hqPermIdx].permission_list.find(
      (x) => x.auth_code === "HQ_SET_MENU/BRANCH_MANAGE",
    );
    if (!hqPerm) {
      return true;
    }

    if (!hqPerm.is_read) {
      return true;
    }

    return false;
  };

  const canCheckHqOnly = () => {
    if (isDisabledHqOnly()) {
      return false;
    }

    // affiliation에 현재 본부의 본부직원이 아닌 경우에만 체크 가능해야함. 다른 경우는 모두 배제
    if (pageMode === "추가") {
      // 가능
      const selectedHqId = hqBranchList.target_list[hqIdx].hq_info.hq_id;
      const res = affiliation.findIndex(
        (x) =>
          !x.is_retired &&
          x.branch_info.branch_id === getHqBranchId() &&
          x.hq_info.hq_id === selectedHqId,
      );

      if (res !== -1) {
        // 불가
        return false;
      }

      return true;
    } else {
      // 불가
      return false;
    }
  };
  // 근로정보 필수 입력 값 4가지 기본 값 "-선택-" 세팅
  const changeComboDefaultValue = (value) => {
    if (value === "선택") {
      return "defaultValue";
    } else {
      return value;
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: `${pageMode !== "수정" && "0.4rem"}`,
        }}
      >
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <SubTitle titleText="직원 정보" />
        </div>

        {pageMode === "이력" || modalParam.isApproval ? (
          <></>
        ) : (
          <div>
            <ButtonActionGroup
              workInfoStore={workInfoStore}
              modalParam={modalParam}
              onClickEvent={onSaveEvent}
            />
          </div>
        )}
      </div>

      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        {/* <div style={{ width: "33%" }}> */}
        {modalParam.pageMode === "추가" ? (
          <ComboBox
            labelWidth={labelWidth}
            comboItemWidth={compWidth}
            labelText="본부"
            data={modalParam.hqBranchList.hq_list}
            defaultValue={modalParam.hqBranchList.hq_list[hqIdx]}
            onChangeCallback={(e, idx) => {
              onChangeHq(idx);
              setHqOnlyState(false);
            }}
          />
        ) : (
          <TextField
            labelWidth={labelWidth}
            textBoxWidth={compWidth}
            labelText="본부"
            defaultValue={workInfoStore.contents.work_info_obj.base_info.hq_info.hq_name}
            disabled
          />
        )}
        {/* </div> */}

        {/* <div style={{ width: "33%", display: "flex" }}> */}
        {modalParam.pageMode === "추가" ? (
          <SearchComboBox
            marginLeft={compMarginLeft}
            labelWidth={labelWidth}
            comboItemWidth="9.25rem"
            labelText="지점"
            data={modalParam.hqBranchList.branch_list[hqIdx]}
            defaultValue={modalParam.hqBranchList.branch_list[hqIdx][0]}
            onChangeCallback={(e, v, idx) => {
              setBranchIdx(idx);
            }}
            disabled={hqOnlyState}
          />
        ) : (
          <TextField
            marginLeft={compMarginLeft}
            labelWidth={labelWidth}
            textBoxWidth="9.25rem"
            labelText="지점"
            defaultValue={workInfoStore.contents.work_info_obj.base_info.branch_info.branch_name}
            disabled
          />
        )}

        <CheckBox
          marginTop="0rem"
          marginRight="0.25rem"
          labelText="본부직원"
          defaultValue={workInfoStore.contents.work_info_obj.is_hq_staff}
          onChangeCallback={(e) => {
            workInfoStore.contents.work_info_obj.is_hq_staff = e.checked;
            setHqOnlyState(e.checked);
          }}
          disabled={!isEditMode || !canCheckHqOnly()}
        />
        {/* </div> */}

        {/* <div style={{ width: "33%" }}> */}
        {pageMode === "추가" || workInfoStore.contents.work_info_obj.work_status === "대기" ? (
          <div style={{ marginLeft: "0.75rem" }}>
            <DatePicker
              // inputRef={tmTryTimeRef}
              marginRight="0.625rem"
              labelWidth={labelWidth}
              labelText="입사일"
              minWidth={compWidth}
              textMarginRight="0rem"
              defaultValue={workInfoStore.contents.work_info_obj.base_info.join_date}
              onChangeCallback={(e) => {
                workInfoStore.contents.work_info_obj.base_info.join_date = e;
              }}
              disabled={!isEditMode}
              required
            />
          </div>
        ) : (
          <div style={{ display: "flex", marginLeft: "0.75rem" }}>
            <DatePicker
              // inputRef={tmTryTimeRef}
              marginRight="0.625rem"
              labelWidth={labelWidth}
              labelText="전환일"
              minWidth={compWidth}
              textMarginRight="0rem"
              minDate={transLimitDate}
              defaultValue={workInfoStore.contents.work_info_obj.base_info.work_trans_from_date}
              onChangeCallback={(e) => {
                workInfoStore.contents.work_info_obj.base_info.work_trans_from_date = e;
              }}
              disabled={!isEditMode}
            />
            {isEditMode ? (
              <Label
                labelText={transLimitDate + "일 이후로만 선택 가능"}
                marginLeft="1rem"
                fontSize="0.9rem"
                color="#e45050"
              />
            ) : (
              <></>
            )}
          </div>
        )}
        {/* </div> */}
      </div>

      {/* lane 2 */}
      <div style={{ display: "flex", marginTop: "1rem" }}>
        {pageMode === "보기" ? (
          <div style={{ display: "flex" }}>
            <TextField
              // marginLeft={compMarginLeft}
              labelWidth={labelWidth}
              textBoxWidth={compWidth}
              labelText="부서"
              defaultValue={workInfoStore.contents.work_info_obj.base_info.department.org_name}
              disabled
            />
            <TextField
              marginLeft={compMarginLeft}
              labelWidth={labelWidth}
              textBoxWidth={compWidth}
              labelText="직위"
              defaultValue={workInfoStore.contents.work_info_obj.base_info.rank.org_name}
              disabled
            />
            <TextField
              marginLeft={compMarginLeft}
              labelWidth={labelWidth}
              textBoxWidth={compWidth}
              labelText="직책"
              defaultValue={workInfoStore.contents.work_info_obj.base_info.position.org_name}
              disabled
            />
          </div>
        ) : (
          <div style={{ display: "flex" }}>
            <ComboBox
              // marginLeft={compMarginLeft}
              labelWidth={labelWidth}
              comboItemWidth={compWidth}
              labelText="부서"
              // data={workInfoStore.hq_set_data.dept_list}
              data={workInfoStore.contents.hq_set_data.dept_list.map((it) => {
                return it.org_name;
              })}
              defaultValue={changeComboDefaultValue(
                workInfoStore.contents.work_info_obj.base_info.department.org_name,
              )}
              onChangeCallback={(e) => {
                const index = workInfoStore.contents.hq_set_data.dept_list
                  .map((item) => item.org_name)
                  .indexOf(e.target.value);
                if (index !== -1) {
                  workInfoStore.contents.work_info_obj.base_info.department =
                    workInfoStore.contents.hq_set_data.dept_list[index];
                }
              }}
              disabled={!isEditMode}
              required
            />

            <ComboBox
              marginLeft={compMarginLeft}
              labelWidth={labelWidth}
              comboItemWidth={compWidth}
              labelText="직위"
              // data={workInfoStore.hq_set_data.position_list}
              data={workInfoStore.contents.hq_set_data.rank_list.map((it) => {
                return it.org_name;
              })}
              defaultValue={changeComboDefaultValue(
                workInfoStore.contents.work_info_obj.base_info.rank.org_name,
              )}
              onChangeCallback={(e) => {
                const index = workInfoStore.contents.hq_set_data.rank_list
                  .map((item) => item.org_name)
                  .indexOf(e.target.value);
                if (index !== -1) {
                  workInfoStore.contents.work_info_obj.base_info.rank =
                    workInfoStore.contents.hq_set_data.rank_list[index];
                }
              }}
              disabled={!isEditMode}
              required
            />

            <ComboBox
              marginLeft={compMarginLeft}
              labelWidth={labelWidth}
              comboItemWidth={compWidth}
              labelText="직책"
              // data={workInfoStore.hq_set_data.rank_list}
              data={workInfoStore.contents.hq_set_data.position_list.map((it) => {
                return it.org_name;
              })}
              defaultValue={changeComboDefaultValue(
                workInfoStore.contents.work_info_obj.base_info.position.org_name,
              )}
              onChangeCallback={(e) => {
                const index = workInfoStore.contents.hq_set_data.position_list
                  .map((item) => item.org_name)
                  .indexOf(e.target.value);
                if (index !== -1) {
                  workInfoStore.contents.work_info_obj.base_info.position.org_id =
                    workInfoStore.contents.hq_set_data.position_list[index].org_id;
                  workInfoStore.contents.work_info_obj.base_info.position.org_name =
                    workInfoStore.contents.hq_set_data.position_list[index].org_name;
                  setPositionIdx(index);
                }
              }}
              disabled={!isEditMode}
              required
            />
          </div>
        )}
      </div>

      {/* lane 3 */}
      <div style={{ display: "flex", marginTop: "1rem" }}>
        <div style={{}}>
          <TextField
            labelWidth={labelWidth}
            textBoxWidth="57.5rem"
            labelText="특이사항"
            defaultValue={workInfoStore.contents.work_info_obj.remark}
            onBlur={(e) => {
              workInfoStore.contents.work_info_obj.remark = e.target.value;
            }}
            disabled={!isEditMode}
          />
        </div>
      </div>
    </div>
  );
};
export default WorkInfoBase;
