export const searchOtManageList = "CLASS/MANAGE/SEARCH_OT_MANGE_LIST";
export const searchOtManageListSuccess = "CLASS/MANAGE/SEARCH_OT_MANGE_LIST/SUCCESS";
export const searchOtManageListFail = "CLASS/MANAGE/SEARCH_OT_MANGE_LIST/FAIL";
export const searchOtManageListReset = "CLASS/MANAGE/SEARCH_OT_MANGE_LIST/RESET";

export const searchPtManageList = "CLASS/MANAGE/SEARCH_PT_MANGE_LIST";
export const searchPtManageListSuccess = "CLASS/MANAGE/SEARCH_PT_MANGE_LIST/SUCCESS";
export const searchPtManageListFail = "CLASS/MANAGE/SEARCH_PT_MANGE_LIST/FAIL";
export const searchPtManageListReset = "CLASS/MANAGE/SEARCH_PT_MANGE_LIST/RESET";

export const ptDetailInfo = "CLASS/MANAGE/PT_DETAIL_INFO";
export const ptDetailInfoSuccess = "CLASS/MANAGE/PT_DETAIL_INFO/SUCCESS";
export const ptDetailInfoFail = "CLASS/MANAGE/PT_DETAIL_INFO/FAIL";
export const ptDetailInfoReset = "CLASS/MANAGE/PT_DETAIL_INFO/RESET";

export const classByStaffInfo = "CLASS/MANAGE/CLASS_BY_STAFF_INFO";
export const classByStaffInfoSuccess = "CLASS/MANAGE/CLASS_BY_STAFF_INFO/SUCCESS";
export const classByStaffInfoFail = "CLASS/MANAGE/CLASS_BY_STAFF_INFO/FAIL";
export const classByStaffInfoReset = "CLASS/MANAGE/CLASS_BY_STAFF_INFO/RESET";
