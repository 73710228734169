import { HttpInstance } from "lib/HttpLib";

export const getSalaryInitList = (param) => {
  return HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/salary/manage_list/search_param", {
    params: {},
  });
};

export const getSalarySearchList = (param) => {
  return HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/salary/manage_list", {
    params: {
      search_param: param.search_param,
    },
  });
};

const get_api = {
  getSalaryInitList,
  getSalarySearchList,
};

export default get_api;
