import { useQuery } from "@tanstack/react-query";
import { getConnectedDeviceList, getDeviceList } from "apis/branch/device_manage_api";
import { deviceManageKeys } from "apis/branch/query_keys_branch";
import { simpleAlert } from "components/CommonLib/CommonLib";
import { useDispatch } from "react-redux";

// [GET] 현재 연결된 장치 가져오기
export const useConnectedDeviceList = () => {
  const storesDispatch = useDispatch();

  return useQuery({
    queryKey: deviceManageKeys.connectedDeviceList(),
    queryFn: () => getConnectedDeviceList(),
    retry: false,
    select: (response) => response.data.data,
    onSuccess: (res) => {
      if (res.data?.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });
};

// [GET] 단말기 리스트 검색
export const useDeviceList = (queryData, enabled) => {
  const storesDispatch = useDispatch();
  const { branchId } = queryData;

  return useQuery({
    queryKey: deviceManageKeys.list(queryData),
    queryFn: () => getDeviceList(queryData),
    // 빈 문자일 경우에는 전체로 조회
    enabled: enabled && (!!branchId || branchId === ""),
    retry: false,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });
};
