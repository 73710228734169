import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { getNowServiceState } from "pages/NoticeBoard/model/board_row";
import { useParams } from "react-router-dom";
import ReferenceDetailWrapper from "./ReferenceDetailWrapper";

const ReferenceDetailService = () => {
  const params = useParams();
  const nowService = "자료실";

  return (
    <div>
      <PageFrame
        children={
          <div>
            <PageTitle titleText="자료실" />
            <ReferenceDetailWrapper
              nowService={getNowServiceState(nowService)}
              postId={params.postId}
            />
          </div>
        }
      />
    </div>
  );
};

export default ReferenceDetailService;
