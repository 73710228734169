import { contentTypeJson, dateToStr, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import DocInfo from "components/File/DocInfo";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import { payListActions } from "pages/ERP/MemberCommonItems/Module/actions/index";
import PaymentInfo from "pages/ERP/MemberCommonItems/PaymentInfo";
import PersonalInfo from "pages/ERP/MemberCommonItems/PersonalInfo";
import RegisterPaidDate from "pages/ERP/MemberCommonItems/RegisterPaidDate";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChangeBranchContents from "./ChangeBranchContents";
import ChangeBranchModalIndex from "./Modal/ChangeBranchModalIndex";

const ChangeBranchView = ({ serviceViewState, memberBaseInfoState, memberInfo }) => {
  const navigate = useNavigate();
  const paymentInfoRef = useRef();
  const contentsInfoRef = useRef();
  const storesDispatch = useDispatch();
  const [branchChangePrice, setBranchChangePrice] = useState({
    price: 0,
    dis: 0,
  });
  const [canEnroll, setCanEnroll] = useState(false);
  const [regDate, setRegDate] = useState(dateToStr(new Date()));
  const [selectedBranch, setSelectedBranch] = useState("");
  const [loading, setLoading] = useState(false);

  const [docList, setDocList] = useState([]);

  //파일어레이 갱신
  useEffect(() => {
    if (serviceViewState.contract_doc_list.length !== 0) {
      setDocList(serviceViewState.contract_doc_list);
    }
    // console.log(serviceViewState);
  }, [serviceViewState]);

  useEffect(() => {
    if (memberBaseInfoState.member_id) {
      getChangeFee();

      payInfoListAction(
        "push",
        "지점변경비",
        {
          item_type: "지점변경비/없음/없음/없음/없음",
          type: "지점변경비",
          price: 0,
          dis: 0,
          pay_order: 0,
          tab_idx: 0,
        },
        0,
      );
    }
  }, [memberBaseInfoState]);

  //지점변경금액이 바뀔때마다 결제정보에 넣어줘야함
  useEffect(() => {
    addPayInfoState("지점변경비", branchChangePrice.price, branchChangePrice.dis, 0);
  }, [branchChangePrice]);

  const addPayInfoState = (key, price, dis, idx) => {
    let action = "push";

    if (price < 0) {
      action = "pop";
    }
    payInfoListAction(
      action,
      key,
      {
        item_type: "지점변경비/없음/없음/없음/없음",
        type: key,
        price: price,
        dis: dis,
        pay_order: idx,
        tab_idx: 0,
      },
      idx,
      // mbshpIdx 넣어줘야 함
    );
  };

  // useEffect(() => {
  //   if (branchChangePrice === 0) {
  //     payInfoListAction(
  //       "push",
  //       "지점변경비",
  //       {
  //         type: "지점변경비",
  //         price: 0,
  //         dis: 0,
  //         pay_order: 0,
  //         tab_idx: 0,
  //       },
  //       0,
  //     );
  //   }
  // }, [branchChangePrice]);

  const getChangeFee = () => {
    HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/change/branch_fee", {
      params: {
        member_id: memberBaseInfoState.member_id,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setBranchChangePrice({ ...branchChangePrice, price: res.data.data });
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const onClickOpenModal = (order, param) => {
    if (order !== "") {
      openModal(order, param);
    }
  };

  const openModal = (select, param) => {
    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,
        Content: (
          <ChangeBranchModalIndex
            modalSelect={select}
            modalParam={param}
            onModalDone={onModalDone}
          />
        ),
      }),
    );
  };

  const onModalDone = (title, pay_info) => {
    if (pay_info.unpaid_info.unpaid_price > 0) {
      //미납금 다 결제하셈
    } else {
      setCanEnroll(true);
    }
    paymentInfoRef.current.setCsPaidResult(pay_info);

    storesDispatch(globalModalOff());
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="결제가 완료되었습니다. 등록을 진행해주세요." />
            <Label labelText="결제 완료 후, 금액 변경시 결제금액은 모두 초기화됩니다." />
          </>
        ),
        onBtnOk: () => {},
      }),
    );
  };

  const validationCheck = () => {
    if (!selectedBranch.branch_id) {
      simpleAlert(storesDispatch, "알림", "이동지점을 선택해주세요.");
    } else if (!serviceViewState.cs_staff.staff_name) {
      simpleAlert(storesDispatch, "알림", "상담자를 선택해주세요.");
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="지점변경을 진행하시겠습니까?" />,
          onBtnYes: () => {
            onSaveEvent();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const uploadDoneCallback = (result) => {
    //업로드에서 받아온 파일을 contract_doc_list에 갱신
    const idx = docList.findIndex((el) => el.doc_id === result.doc_id);
    setDocList((cur) => {
      const arr = [...cur];
      arr[idx] = result;
      return arr;
    });
  };

  //최종 등록 버튼 누를때 POST
  const onSaveEvent = async () => {
    try {
      // console.log("get : ", paymentInfoRef.current.getCsPaidResult());
      // console.log("get 2 : ", paymentInfoRef.current.getPaidItemList());

      const newInfo = _.cloneDeep(serviceViewState);
      const paidResult = paymentInfoRef.current.getCsPaidResult();
      const paidItemList = paymentInfoRef.current.getPaidItemList();

      newInfo.reg_date = regDate;
      newInfo.paymentA.payment_info = [
        {
          payment_input: paidResult,
          paid_item_list: paidItemList,
          purchase_code: "",
          paid_price: paidResult.total_paid,
          unpaid_price: paidResult.unpaid_info.unpaid_price,
        },
      ];

      newInfo.contract_doc_list = docList;
      //모두 사용할꺼면
      newInfo.contract_doc_list.forEach((el) => (el.is_use = true));

      const formData = new FormData();
      formData.append("new_info", JSON.stringify(newInfo));

      // console.log(newInfo);
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_USER_SERVER + "/change/branch_update",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: <Label labelText="지점변경이 완료되었습니다." />,
            onBtnOk: () => {
              navigate(`/member_info/${serviceViewState.member_id}`);
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const payInfoListAction = (act, pType, detailInfo, ord) => {
    if (act === "push") {
      storesDispatch(payListActions.paymentInfoPush(pType, detailInfo, ord));
    } else if (act === "pop") {
      storesDispatch(payListActions.paymentInfoPop(pType));
    }
  };

  return (
    <div style={{ display: "flex" }}>
      {loading && <Loading />}

      <div style={{ width: "76.88rem" }}>
        <PersonalInfo
          memberBaseInfoState={memberBaseInfoState.personal_info}
          onClickOpenModal={onClickOpenModal}
          memberInputState={{
            branch_info: memberInfo.branch_info,
            card_param: memberInfo.access,
          }}
          isNeedEncode={true}
        />

        <ChangeBranchContents
          ref={contentsInfoRef}
          payInfoListAction={payInfoListAction}
          serviceViewState={serviceViewState}
          selectedBranch={selectedBranch}
          setSelectedBranch={setSelectedBranch}
          branchChangePrice={branchChangePrice}
          setBranchChangePrice={setBranchChangePrice}
        />
      </div>
      <div style={{ width: "23.13rem", marginLeft: "0.625rem" }}>
        <PaymentInfo
          isAllCalc={true}
          ref={paymentInfoRef}
          onClickOpenModal={onClickOpenModal}
          regDate={regDate}
          setCanEnroll={setCanEnroll}
          noCoupon
          isAddPay={false}
          remainPoint={0}
        />
        <RegisterPaidDate regDate={regDate} setRegDate={setRegDate} />
        {/* 제출 서류*/}

        <DocInfo docList={docList} uploadDoneCallback={uploadDoneCallback} />

        <NormalBtn
          name="등록"
          width="100%"
          onClick={() => validationCheck()}
          disabled={!canEnroll}
        />
      </div>
    </div>
  );
};
export default ChangeBranchView;
