export const workInfoInitState = {
  contents: {
    hq_policy_data: {
      assign_option: {
        except_all: false,
        except_event: false,
        except_service: false,
        include_all: false,
      },
      cms_by_pay_list: [
        {
          branch_info: {
            branch_id: "",
            branch_name: "",
          },
          cms_by_pay_method: {
            card: 0,
            cash: 0,
          },
        },
      ],
      default_salary_date_list: [
        {
          branch_info: {
            branch_id: "",
            branch_name: "",
          },
          default_salary_date: {
            etc_fee: 0,
            gx_fee: 0,
            incentive: 0,
            pt_fee: 0,
            wage: 0,
          },
        },
      ],
      mbshp_rfnd_day_price: 0,
      postpone_month: 0,
      prepay_penalty_rate: 0,
      prepay_postpone_day: 0,
      prepay_postpone_month: 0,
      subs_penalty_rate: 0,
      subs_postpone_month: 0,
    },
    hq_set_data: {
      dept_list: [],
      position_list: [],
      rank_list: [],
    },
    hq_set_name_data: {
      dept_list: [""],
      position_list: [""],
      rank_list: [""],
    },
    work_info_obj: {
      approval_status: "",
      base_info: {
        branch_info: {
          branch_id: "",
          branch_name: "",
        },
        department: {
          org_id: "",
          org_name: "",
        },
        hq_info: {
          hq_id: "",
          hq_name: "",
        },
        join_date: "",
        position: {
          org_id: "",
          org_name: "",
        },
        rank: {
          org_id: "",
          org_name: "",
        },
        resign_date: "",
        work_trans_from_date: "",
        work_trans_to_date: "",
      },
      edit_status: "",
      files: {
        emp_contract_file: "",
        is_submit_emp_file: "",
        is_submit_resign: "",
        resign_file: "",
      },
      gx_class: [],
      is_gx_extra: false,
      is_hq_owner: false,
      is_hq_staff: false,
      is_incentive: false,
      is_pt_extra: false,
      personal_info: {
        email: "",
        phone: "",
        sabun: "",
        staff_name: "",
      },
      reg_info: {
        created_at: "",
        reg_id: "",
        reg_name: "",
        upd_id: "",
        upd_name: "",
        updated_at: "",
      },
      reg_type: "",
      remark: "",
      staff_id: "",
      time_list: [
        {
          idx: 0,
          is_use: false,
          day_of_week: "월",
          from_time: "",
          end_time: "",
        },
        {
          idx: 1,
          is_use: false,
          day_of_week: "화",
          from_time: "",
          end_time: "",
        },
        {
          idx: 2,
          is_use: false,
          day_of_week: "수",
          from_time: "",
          end_time: "",
        },
        {
          idx: 3,
          is_use: false,
          day_of_week: "목",
          from_time: "",
          end_time: "",
        },
        {
          idx: 4,
          is_use: false,
          day_of_week: "금",
          from_time: "",
          end_time: "",
        },
        {
          idx: 5,
          is_use: false,
          day_of_week: "토",
          from_time: "",
          end_time: "",
        },
        {
          idx: 6,
          is_use: false,
          day_of_week: "일",
          from_time: "",
          end_time: "",
        },
        {
          idx: 7,
          is_use: false,
          day_of_week: "공휴일",
          from_time: "",
          end_time: "",
        },
      ],
      wage: 0,
      wage_type: "",
      work_info_id: "",
      work_role: {
        is_cs: false,
        is_ot: false,
        is_pt: false,
        is_pt_cs: false,
      },
      work_status: "",
      work_time_use: false,
    },
  },
  permission: {
    btn_approval: false,
    btn_change: false,
    btn_draft: false,
    btn_edit: false,
    btn_edit_cancel: false,
    btn_re_entry: false,
    btn_recall: false,
    btn_retirement: false,
    btn_trans: false,
    work_trans_limit_date: "",
  },
};
