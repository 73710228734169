import CardFixed from "components/Card/CardFixed";
import { useGetAuthData } from "hooks/apis/access/useAuth";
import { useEffect, useState } from "react";
import DeviceManageListView from "./DeviceManageListView";
import DeviceManageScheduleListView from "./DeviceManageScheduleListView";

const DeviceManageView = ({ onClickOpenModal, hqBranchData, branchList }) => {
  const [isAdmin, setIsAdmin] = useState(false);

  // [GET] 권한 정보 가져오기
  const { data: authData } = useGetAuthData();

  useEffect(() => {
    if (authData) {
      setIsAdmin(authData.data.data.is_admin);
    }
  }, [authData]);

  return (
    <div>
      <CardFixed
        topDom={<div>출입 장치 관리</div>}
        midDom={
          <div>
            <DeviceManageListView
              onClickOpenModal={onClickOpenModal}
              hqBranchData={hqBranchData}
              branchList={branchList}
              isAdmin={isAdmin}
            />
          </div>
        }
        isLoading={false}
        rootStyle={{
          width: "100%",
          marginTop: "0.625rem",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />

      <CardFixed
        topDom={<div>출입 스케줄 관리</div>}
        midDom={
          <div>
            <DeviceManageScheduleListView
              onClickOpenModal={onClickOpenModal}
              hqBranchData={hqBranchData}
              isAdmin={isAdmin}
            />
          </div>
        }
        isLoading={false}
        rootStyle={{
          width: "100%",
          marginTop: "0.625rem",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />
    </div>
  );
};
export default DeviceManageView;
