import { takeLatest, takeEvery } from "redux-saga/effects";

import {
  getLockerSetData,
  getLockerShowData,
  getLockerAllocateShowData,
  getLockerDetailShowData,
} from "./locker_setting_sagas";

export function* lockerSettingSaga() {
  yield takeLatest("LOAD_LOCKER_SETTING", getLockerSetData);
  yield takeLatest("LOAD_LOCKER_SETTING_SHOW", getLockerShowData);
  yield takeLatest("LOAD_LOCKER_ALLOCATE_SHOW", getLockerAllocateShowData);
  yield takeLatest("LOAD_LOCKER_DETAIL_SHOW", getLockerDetailShowData);
}
