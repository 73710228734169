import { getPermission } from "components/CommonLib/CommonLib";
import _ from "lodash";
import {
  branch_search_init_state,
  hq_branch_search_init_state,
} from "pages/CommonModal/Search/Module/reducers/hq_branch_search_init_state";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getPostRowState } from "../../model/board_row";
import { getBoardRowAPi } from "../../restApi/getApi";
import ReferenceCreateView from "./ReferenceCreateView";

const ReferenceCreateWrapper = ({ nowService }) => {
  const [hqBranchData, setHqBranchData] = useState(_.cloneDeep(hq_branch_search_init_state));
  const [branchList, setBranchList] = useState(_.cloneDeep(branch_search_init_state));

  const [postRowState, setPostRowState] = useState(getPostRowState(nowService));
  const storesDispatch = useDispatch();
  useEffect(() => {
    // console.log("use affect.. ");
    getHqBranchCombo();
    getBoardRowAPi(storesDispatch, nowService, "none", setPostRowState);
  }, []);
  //본부지점콤보 GET
  const getHqBranchCombo = async () => {
    const result = await getPermission(storesDispatch, "ACCESS_MENU/MANAGE", true, false, true);
    const branchResult = await getPermission(
      storesDispatch,
      "ACCESS_MENU/MANAGE",
      true,
      true,
      true,
    );
    setHqBranchData(result);
    setBranchList(branchResult);
  };
  // console.log(postRowState);
  return (
    <ReferenceCreateView
      nowService={nowService}
      postRowState={postRowState}
      setPostRowState={setPostRowState}
      hqBranchData={hqBranchData}
      branchList={branchList}
    />
  );
};

export default ReferenceCreateWrapper;
