import { noneSelectType } from "../../Register/Module/reducers/setting_initial_state";

export const structToMembershipType = (listData, isLock) => {
  let dataList = ["완납형", "구독형"];

  if (isLock) {
    dataList = ["완납형"];
  }

  if (listData.length === 0) {
    dataList = ["선택없음"];
  }

  return dataList;
};

export const structToMembershipSettings = (idx, storeData) => {
  if (idx === "완납형") {
    return storeData.prepay_items;
  } else if (idx === "구독형") {
    return storeData.subs_items;
  } else {
    return noneSelectType;
  }
};

export const structToItemName = (listData) => {
  let dataList = [];
  if (listData.length > 0) {
    listData.map((data) => dataList.push(data.item_name));
  }
  return dataList;
};
