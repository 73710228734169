import { HttpInstance } from "lib/HttpLib";

export const getSettingInfoList = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/register/settings_show", {
    params: {
      branch_id: param.branch_id === null ? "" : param.branch_id,
      is_only_prepay: param.is_only_prepay === null ? false : param.is_only_prepay,
      is_only_merged: param.is_only_merged === null ? false : param.is_only_merged,
      selected_item_id: param.selected_item_id === null ? "" : param.selected_item_id,
      remain_pt: param.remain_pt === null ? 0 : param.remain_pt,
    },
  });
};

export const getRegisterViewInfo = (param) => {
  return HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member/register_view", {
    params: {
      member_id: param.member_id === null ? "" : param.member_id,
      reg_date: param.reg_date === null ? "" : param.reg_date,
    },
  });
};

const get_api = {
  getSettingInfoList,
  getRegisterViewInfo,
};

export default get_api;
