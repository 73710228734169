import * as ActionTypes from "./action_type";

///ASSIGN FLOW

export const setAssignParamSrcIdAct = (member_id) => {
  return {
    type: ActionTypes.setAssignParamSrcId,
    member_id: member_id,
  };
};
export const setAssignParamDstIdAct = (member_id) => {
  return {
    type: ActionTypes.setAssignParamDstId,
    member_id: member_id,
  };
};
export const setAssignParamNewbieAct = (newbieInfo) => {
  return {
    type: ActionTypes.setAssignParamNewbie,
    newbie_info: newbieInfo,
  };
};

//CLEAR
export const clearAssignParamSrcIdAct = () => {
  return {
    type: ActionTypes.clearAssignParamSrcId,
  };
};
export const clearAssignParamDstIdAct = () => {
  return {
    type: ActionTypes.clearAssignParamDstId,
  };
};
export const clearAssignParamNewbieAct = () => {
  return {
    type: ActionTypes.clearAssignParamNewbie,
  };
};

export const clearAssignParamAllAct = () => {
  return {
    type: ActionTypes.clearAssignParamAll,
  };
};
