import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroupComp from "@mui/material/RadioGroup";
import { useEffect, useState } from "react";

const RadioGroup2 = (props) => {
  // const RadioGroup2 = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.defaultValue);

  useEffect(() => {
    if (props.defaultValue !== undefined) {
      setValue(props.defaultValue);
    }
  }, [props.defaultValue]);

  // useImperativeHandle(ref, () => ({
  //   resetValue: () => {
  //     setValue(props.value);
  //   },
  //   getValue: () => {
  //     return value;
  //   },
  // }));

  const hStyle = {
    display: "flex",
    alignItems: "center",
    // width: "100%",
    marginTop: props.marginTop ? props.marginTop : "0.625rem",
    marginRight: props.marginRight ? props.marginRight : "0.625rem",
    marginLeft: props.marginLeft ? props.marginLeft : "0rem",
  };

  const radioBoxStyle = {
    margin: "0rem",
    padding: "0rem",
    marginRight: props.radioMarginRight ? props.radioMarginRight : "1.25rem",
  };

  const labelStyle = {
    width: props.labelWidth,
    height: "2.25rem",
    marginRight: props.labelMarginRight ? props.labelMarginRight : "0.625rem",
    marginLeft: props.labelMarginLeft,
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.938rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
    alignItems: "center",
    display: "flex",
  };
  const radioStyle = {
    // width: "2.25rem",
    // height: "2.25rem",
    // transform: "scale(1.2)",
    // marginRight: "0.562rem",
    // marginLeft: props.ItemMarginLeft,
  };

  const radioLabelStyle = (item) => {
    let color = "#000";
    if (props.disabled || (props.disableItem && props.disableItem.indexOf(item) !== -1)) {
      color = "#999";
    }

    return {
      fontFamily: "AppleSDGothicNeo",
      fontSize: "0.938rem",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "left",
      color: color,
      alignItems: "center",
      marginLeft: "0.25rem",
    };
  };

  const onChangeValue = (e, value) => {
    setValue(value);
    if (props.inputRef !== undefined) {
      props.inputRef.current.value = value;
    }
    if (props.onChangeCallback !== undefined) {
      props.onChangeCallback(e, value);
    }
  };

  const Radios = () => {
    if (!props.data) return null;

    return props.data.map((item, idx) => (
      <FormControlLabel
        key={idx}
        value={item}
        style={radioBoxStyle}
        control={
          <Radio
            style={{
              marginTop: props.innerMarginTop ? props.innerMarginTop : "0.25rem",
              marginLeft: props.innerMarginLeft ? props.innerMarginLeft : "0.5rem",
              marginRight: props.innerMarginRight ? props.innerMarginRight : "0.5rem",
              padding: "0.25rem",
            }}
            disabled={
              props.disabled || (props.disableItem && props.disableItem.indexOf(item) !== -1)
            }
          />
        }
        label={props.isMulti === undefined ? "" : <div style={radioLabelStyle(item)}>{item}</div>}
      />
    ));
  };

  return (
    <Box style={hStyle}>
      <RadioGroupComp
        row={props.isRow}
        aria-label="gender"
        name="radioButtonsGroup"
        onChange={(e) => {
          if (props.onChange) {
            props.onChange(e);
          }
          onChangeValue(e, e.target.value);
        }}
        style={radioStyle}
        defaultValue={props.defaultValue}
        value={value}
      >
        <Radios />
      </RadioGroupComp>
      <div style={labelStyle}>{props.labelText}</div>
    </Box>
  );
};

export default RadioGroup2;
