// ErrorBoundaryWithModal.js
import { simpleAlert } from "components/CommonLib/CommonLib";
import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error) {
    console.log("렌더링 에러:", error);
    simpleAlert(
      this.props.storesDispatch,
      "알림",
      "페이지를 표시할 수 없습니다.\n잠시 후 다시 시도해주세요.",
      false,
      () => {
        // 새로고침하면서 홈으로
        window.location.href = "/";
      },
    );
  }

  render() {
    if (this.state.hasError) {
      // 에러 발생 시 화면 표기
      return this.props.fallback || null;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
