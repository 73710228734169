export const onSelectHq = (hqName, hqBranchData, stateSetter, idxSetter) => {
  const idx = hqBranchData.target_list.findIndex((x) => x.hq_info.hq_name === hqName);
  const hInfo = hqBranchData.target_list[idx].hq_info;
  // guestBookState.contact_info.hq_info = hInfo;

  // setPostRowState
  stateSetter((cur) => {
    return {
      ...cur,
      hq_info: hInfo,
    };
  });
  // setHqIdx(idx);
  idxSetter(idx);
};

export const onSelectBranch = (branchName, hqBranchData, hqIdx, stateSetter) => {
  const bInfo = hqBranchData.target_list[hqIdx].branch_list.find(
    (x) => x.branch_name === branchName,
  );
  //setPostRowState
  stateSetter((cur) => {
    return {
      ...cur,
      branch_info: bInfo,
    };
  });
};
