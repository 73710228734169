const { default: styled } = require("styled-components");

// 검색 Styles
export const SearchContainer = styled.div`
  padding: 0.75rem;
  border: 1px solid #dedede;
  border-radius: 5px;
  background-color: #f5f5f5;
  margin-top: 0.5rem;
`;

export const SearchRowBox = styled.div`
  display: flex;
  margin-top: 0.5rem;
`;

export const SearchConditionBox = styled.div`
  width: 26.5%;
  display: flex;
`;
