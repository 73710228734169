import { useQueries } from "@tanstack/react-query";
import { searchMemberKeys } from "apis/member/query_keys_member";
import {
  getBaseSearchParam,
  getMbshpSearchParam,
  getMemberSearchParam,
  getPtSearchParam,
  getSideSearchParam,
} from "apis/member/search_member_api";

// [GET] 회원 조회 검색 기준 베이스
export const useSearchMemberFirstSearchParam = () => {
  const results = useQueries({
    queries: [
      {
        queryKey: searchMemberKeys.baseSearchParam(),
        queryFn: getBaseSearchParam,
        select: (res) => {
          return { baseSearchParam: res.data.data };
        },
      },
      {
        queryKey: searchMemberKeys.memberSearchParam(),
        queryFn: getMemberSearchParam,
        select: (res) => {
          return { memberSearchParam: res.data.data };
        },
      },
      {
        queryKey: searchMemberKeys.mbshpSearchParam(),
        queryFn: getMbshpSearchParam,
        select: (res) => {
          return { mbshpSearchParam: res.data.data };
        },
      },
      {
        queryKey: searchMemberKeys.sideSearchParam(),
        queryFn: getSideSearchParam,
        select: (res) => {
          return { sideSearchParam: res.data.data };
        },
      },
      {
        queryKey: searchMemberKeys.ptSearchParam(),
        queryFn: getPtSearchParam,
        select: (res) => {
          return { ptSearchParam: res.data.data };
        },
      },
    ],
  });

  return {
    // 하나의 객체 형태로 반환
    data: results.reduce((accumulator, result) => ({ ...accumulator, ...result.data }), {}),
    isLoading: results.some((result) => result.isLoading),
  };
};
