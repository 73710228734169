import * as ActionTypes from "./action_type";

///MEMBER VIEW
export const loadChangePayMethodView = (member_id) => {
  return {
    type: ActionTypes.getChangePayMethodViewAction,
    member_id: member_id,
  };
};

export const loadChangePayMethodViewSuccess = (data) => {
  return {
    type: ActionTypes.getChangePayMethodViewSuccessAction,
    payload: data,
  };
};

export const loadChangePayMethodViewFail = (data) => {
  return {
    type: ActionTypes.getChangePayMethodViewFailAction,
    payload: [],
  };
};

export const loadChangePayMethodViewReset = (data) => {
  return {
    type: ActionTypes.getChangePayMethodViewResetAction,
    payload: data,
  };
};

export const loadChangePayMethodRefundPush = (data_row) => {
  return {
    type: ActionTypes.getChangePayMethodRefundPush,
    data_row: data_row,
  };
};

export const loadChangePayMethodRefundPop = (payment_id) => {
  return {
    type: ActionTypes.getChangePayMethodRefundPop,
    payment_id: payment_id,
  };
};
