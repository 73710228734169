import { HttpInstance } from "../../lib/HttpLib";

export const getCSTask = (param) => {
  return HttpInstance.get(process.env.REACT_APP_CS_DASH_SERVER + "/get_cs_task", {
    params: {
      // _id: param._id,
    },
  });
};

export const getCSList = (param) => {
  console.log(param);
  return HttpInstance.get(process.env.REACT_APP_CS_DASH_SERVER + "/get_cs_task_result", {
    params: {
      task_key: param.curCSTarget,
      page_num: param.page_num,
      is_all: param.is_all,
      is_tm_recv_ok: param.is_tm_recv_ok,
      is_tm_recv_no: param.is_tm_recv_no,
    },
  });
};

export const getCSDetailInfo = (param) => {
  console.log(param);
  return HttpInstance.get(process.env.REACT_APP_CS_DASH_SERVER + "/get_cs_target_data", {
    params: {
      task_key: param.curCSTarget,
      member_key: param.curMemberTarget,
    },
  });
};

export const getCSFilterSetting = () => {
  // console.log("getCSFilterSetting");
  // return HttpInstance.get(process.env.REACT_APP_CS_DASH_SERVER + "/get_cs_base_data")
  // .then(response => ({ response }))
  //   .catch(error => ({ error }))
  // .then((res) => {
  //   // console.log("then END -----------", res);
  //   return res;
  // })
  // .catch((err) => {
  //   // console.log("catch END -----------", err);
  //   return err;
  //   // if (err.response) return err.response;
  // });
  // // console.log("getCSFilterSetting END -----------");

  return HttpInstance.get(process.env.REACT_APP_CS_DASH_SERVER + "/get_cs_base_data");
};

const get_api = {
  getCSTask,
  getCSList,
  getCSDetailInfo,
  getCSFilterSetting,
};

export default get_api;
