import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, generateObjectZeroId, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import CheckBox from "components/LabelInput/CheckBox";
import Label from "components/LabelInput/Label";
import { globalAlertOn } from "modules/actions/Alert";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const SrcPtInfo = ({
  ptInfoList,
  dstPtInfoRef,
  setIsSomePtCheck,
  dstMembershipInfoRef,
  checkedMbshpIds,
  srcMbshpList,
}) => {
  const storesDispatch = useDispatch();
  const [, updateState] = useState(); // 강제 렌더링용
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    // 결합 mbshp을 선택한 경우 연결된 pt는 자동 선택 및 해제
    for (let i = 0; i < ptInfoList.length; i++) {
      const mergedMbshpId = ptInfoList[i].pt_info.merged_membership_id;
      if (mergedMbshpId === generateObjectZeroId()) {
        continue;
      }

      if (ptInfoList[i].view_info.selected) {
        if (checkedMbshpIds.findIndex((x) => x === mergedMbshpId) === -1) {
          ptInfoList[i].view_info.selected = false;
        }
      } else {
        if (checkedMbshpIds.findIndex((x) => x === mergedMbshpId) !== -1) {
          ptInfoList[i].view_info.selected = true;
        }
      }
    }
    setSelectedRows();
    forceUpdate();
  }, [checkedMbshpIds]);

  // onChange check!
  const checkMerge = (checked, idx, data) => {
    if (data.pt_info.merged_membership_id !== generateObjectZeroId()) {
      const findIdx = srcMbshpList.findIndex(
        (x) => x.membership_list.membership_id === data.pt_info.merged_membership_id,
      );

      if (findIdx !== -1 && srcMbshpList[findIdx].selected !== checked) {
        simpleAlert(storesDispatch, "알림", "PT 결합상품은 회원권과 별개로 양도가 불가합니다.");
        // data.view_info.selected = false;
        // forceUpdate();
        return false;
      }
    }
    return true;
  };

  const onChangeCheck = (checked, idx, data) => {
    const dstMembershipArr = dstMembershipInfoRef.current.getSelectRow();
    if (dstMembershipArr.length === 0) {
      //경고
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="양수자에게 유효한 회원권이 없습니다." fontWeight="bold" />
              <br />
              <Label labelText="회원권 구매후 양도를 진행하거나," />
              <Label labelText="양도할 회원권 선택 후 PT 상품을 선택하시기 바랍니다." />
            </>
          ),
          onBtnOk: () => {
            data.view_info.selected = false;
            // setIsSomePtCheck(false);
          },
        }),
      );
    } else {
      setSelectedRows();
    }
  };

  const setSelectedRows = () => {
    const selectedRows = [];
    for (let i = 0; i < ptInfoList.length; i++) {
      if (ptInfoList[i].view_info.selected) {
        selectedRows.push(ptInfoList[i]);
      }
    }
    dstPtInfoRef.current.setSelectRow(selectedRows);
    if (selectedRows.length === 0) {
      setIsSomePtCheck(false);
    } else {
      setIsSomePtCheck(true);
    }
  };

  const dataInfo = new dataInfoTable(onChangeCheck, checkMerge);

  return (
    <div>
      <CustomTable
        columns_head={dataInfo.columns_head}
        table_title={dataInfo.table_title}
        rest_call={dataInfo.get_data_from_rest}
        row_render={dataInfo.create_table}
        rest_data={ptInfoList}
      />
    </div>
  );
};
export default SrcPtInfo;

class dataInfoTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onChangeCheck = null;
  checkMerge = null;

  constructor(onChangeCheck, checkMerge) {
    this.onChangeCheck = onChangeCheck;
    this.checkMerge = checkMerge;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>
          <CheckBox
            isTable
            labelText=""
            defaultValue={data.view_info.selected}
            onChangeCallback={(e) => {
              if (this.checkMerge(e.checked, idx, data)) {
                data.view_info.selected = e.checked;
                this.onChangeCheck(e.checked, idx, data);
              } else {
                e.checked = data.view_info.selected;
              }
            }}
          />
        </CustomTableContents>
        <CustomTableContents>{data.pt_info.item_name}</CustomTableContents>
        <CustomTableContents>
          {data.pt_price.paid_price.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents>
          {data.pt_info.service_session_cnt - data.pt_info.pt_used_cnt > 0
            ? data.pt_info.base_session_cnt + " 회"
            : data.pt_info.remain_session_cnt + " 회"}
        </CustomTableContents>
        <CustomTableContents>
          {data.pt_info.service_session_cnt - data.pt_info.pt_used_cnt > 0
            ? data.pt_info.service_session_cnt - data.pt_info.pt_used_cnt + " 회"
            : 0 + " 회"}
        </CustomTableContents>
        <CustomTableContents>
          {this.isMerged(data.pt_info.merged_membership_id)}
        </CustomTableContents>
      </CustomRow>
    );
  };

  isMerged = (id) => {
    if (id === generateObjectZeroId()) {
      return "N";
    } else {
      return "Y";
    }
  };

  columns_head = [
    { title: "선택", width: "5%" },
    { title: "계약 상품명", width: "40%" },
    { title: "결제금액", width: "15%" },
    { title: "잔여 기본 횟수", width: "15%" },
    { title: "잔여 서비스 횟수", width: "15%" },
    { title: "결합상품 여부", width: "10%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
