import { contentTypeJson, getPermission, simpleAlert } from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const CreatePopup = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [branchList, setBranchList] = useState([]);
  const [createParam, setCreateParam] = useState({
    branch_info: { branch_id: "", branch_name: "" },
    reg_type_new: false,
    reg_type_re_reg: false,
    mbshp_option_normal: false,
    mbshp_option_oneday: false,
    reg_date_from: "",
    reg_date_to: "",
  });

  useEffect(() => {
    getBranchList();
  }, []);

  const getBranchList = async () => {
    const branchResult = await getPermission(
      storesDispatch,
      "ACCESS_MENU/MANAGE",
      true,
      true,
      false, // 전체 없어야 함
    );
    setBranchList(branchResult);
  };

  const validation = () => {
    if (!createParam.branch_info.branch_id) {
      simpleAlert(storesDispatch, "알림", "지점을 선택해 주세요.");
      return false;
    } else if (!createParam.reg_type_new && !createParam.reg_type_re_reg) {
      simpleAlert(storesDispatch, "알림", "등록구분을 선택해 주세요.");
      return false;
    } else if (!createParam.mbshp_option_normal && !createParam.mbshp_option_oneday) {
      simpleAlert(storesDispatch, "알림", "회원권 옵션을 선택해 주세요.");
      return false;
    } else if (!createParam.reg_date_from || !createParam.reg_date_to) {
      simpleAlert(storesDispatch, "알림", "등록기간을 선택해 주세요.");
      return false;
    } else if (createParam.reg_date_from > createParam.reg_date_to) {
      simpleAlert(storesDispatch, "알림", "등록기간을 확인해 주세요.");
      return false;
    }

    return true;
  };

  const doCreate = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("stats_create_info", JSON.stringify(createParam));
    await HttpInstance.post(
      process.env.REACT_APP_BRANCH_SERVER + "/statistics/location",
      formData,
      contentTypeJson,
    )
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          simpleAlert(
            storesDispatch,
            "알림",
            "데이터 생성 요청이 완료되었습니다.\n생성이 완료되면 아래 검색결과 테이블에 표시됩니다.",
            true,
            () => {
              onModalDone(res.data.data);
            },
          );
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      }, []);
  };

  const onClickCreate = () => {
    if (!validation()) return;

    simpleAlert(
      storesDispatch,
      "알림",
      "위치 통계 데이터를 생성하시겠습니까?",
      false,
      undefined,
      doCreate,
    );
  };

  // layout var
  const labelWidth = "6rem";

  return (
    <>
      {loading && <Loading />}

      <div style={{}}>
        <ComboBox
          labelText="지점"
          labelWidth={labelWidth}
          labelMarginRight="0rem"
          comboItemWidth="15rem"
          marginRight="4rem"
          data={branchList.branch_name_list}
          defaultValue={createParam.branch_info.branch_name}
          onChangeCallback={(e, idx) => {
            const selectedBranchInfo = {
              branch_id: branchList.branch_list[idx].branch_id,
              branch_name: branchList.branch_list[idx].branch_name,
            };
            setCreateParam({ ...createParam, branch_info: selectedBranchInfo });
            // createParam.branch_info = selectedBranchInfo;
          }}
        />
      </div>

      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <Label width={labelWidth} justifyContent="flex-start" labelText={"등록구분"} />
        <CheckBox
          marginTop="0rem"
          labelText="신규등록"
          defaultValue={createParam.reg_type_new}
          onChange={(e) => {
            setCreateParam({ ...createParam, reg_type_new: e.target.checked });
            // createParam.reg_type_new = e.target.checked;
          }}
        />

        <CheckBox
          marginTop="0rem"
          labelText="재등록"
          ItemMarginLeft={"1rem"}
          defaultValue={createParam.reg_type_re_reg}
          onChange={(e) => {
            setCreateParam({ ...createParam, reg_type_re_reg: e.target.checked });
            // createParam.reg_type_re_reg = e.target.checked;
          }}
        />
      </div>

      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <Label width={labelWidth} justifyContent="flex-start" labelText={"회원권 옵션"} />

        <CheckBox
          marginTop="0rem"
          labelText="일반권"
          defaultValue={createParam.mbshp_option_normal}
          onChange={(e) => {
            setCreateParam({ ...createParam, mbshp_option_normal: e.target.checked });
            // createParam.mbshp_option_normal = e.target.checked;
          }}
        />

        <CheckBox
          marginTop="0rem"
          labelText="일일권"
          ItemMarginLeft={"1.75rem"}
          defaultValue={createParam.mbshp_option_oneday}
          onChange={(e) => {
            setCreateParam({ ...createParam, mbshp_option_oneday: e.target.checked });
            // createParam.mbshp_option_oneday = e.target.checked;
          }}
        />
      </div>

      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <DatePicker
          labelText="등록기간"
          labelWidth={labelWidth}
          textBoxWidth="9rem"
          labelMarginRight="0rem"
          textMarginRight="0rem"
          defaultValue={createParam.reg_date_from}
          onChangeCallback={(e) => {
            setCreateParam({ ...createParam, reg_date_from: e });
            // createParam.reg_date_from = e;
          }}
        />
        <DatePicker
          marginLeft="0.12rem"
          marginRight="4rem"
          labelWidth="0.5rem"
          textBoxWidth="9rem"
          textMarginRight="0rem"
          labelMarginRight="0.12rem"
          labelText="~"
          defaultValue={createParam.reg_date_to}
          onChangeCallback={(e) => {
            setCreateParam({ ...createParam, reg_date_to: e });
            // createParam.reg_date_to = e;
          }}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
        <NormalBtn
          name="생성"
          theme="blue"
          onClick={() => {
            onClickCreate();
          }}
        />
      </div>
    </>
  );
};

export default CreatePopup;
