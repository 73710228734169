import { changeCellStyle, excelExport } from "components/CommonLib/CommonLib";

const getAge = (birth) => {
  const thisYear = new Date().getFullYear();
  const birthYear = new Date(birth).getFullYear();
  let age = thisYear - birthYear + 1;
  return age;
};

export const exportToExcel = (data, fileName, type) => {
  const newData = [];
  if (type === "PT") {
    for (let i = 0; i < data.length; i++) {
      const row = {};
      row.회원번호 = data[i].membership_info.member_no;
      row.이름 = data[i].membership_info.member_name;
      row.성별 = data[i].membership_info.gender;
      row.연락처 = data[i].membership_info.phone;
      row.나이 = getAge(data[i].membership_info.birth);
      row.PT_계약_상품명 = data[i].pt_info.item_name;
      row[""] = null;
      row[" "] = null;
      row.계약일 = data[i].pt_info.purchase_date;
      row["  "] = null;
      row.등록구분 = data[i].pt_info.join_type;
      row.완료구분 = data[i].class_result_type;
      row.진행회차 = `${
        data[i].pt_info.base_session_cnt +
        data[i].pt_info.service_session_cnt -
        data[i].pt_info.remain_session_cnt
      } / ${data[i].pt_info.base_session_cnt + data[i].pt_info.service_session_cnt}`;
      row.수업일 = data[i].class_done_date;
      row.수업_담당자 = data[i].cur_manager.staff_info.staff_name;
      row.코멘트 = data[i].comment;

      newData.push(row);
    }
  } else if (type === "OT") {
    for (let i = 0; i < data.length; i++) {
      const row = {};
      row.회원번호 = data[i].membership_info.member_no;
      row.이름 = data[i].membership_info.member_name;
      row.성별 = data[i].membership_info.gender;
      row.연락처 = data[i].membership_info.phone;
      row.나이 = getAge(data[i].membership_info.birth);
      row.회원권_상품명 = data[i].membership_info.item_name;
      row.회원권_구매일 = data[i].mbshp_paid_date;
      row.회원권_유효기간 = `${data[i].membership_info.from_date} - ${data[i].membership_info.to_date}`;
      row[""] = null;
      row.진행회차 = `${data[i].ot_info.remain_session_cnt} / ${data[i].ot_info.ot_session_cnt}`;
      row.수업일 = data[i].class_done_date;
      row.수업_담당자 = data[i].cur_manager.staff_info.staff_name;
      row.코멘트 = data[i].comment;

      newData.push(row);
    }
  }
  const lastRow = {};
  lastRow.회원번호 = changeCellStyle("총 회원수", "lightOrange", "left");
  lastRow.이름 = changeCellStyle(data.length);

  newData.push(lastRow);
  // 헤더 스타일 정의
  const headerStyle = {
    fill: { fgColor: { theme: 4, tint: 0.8 } },
    alignment: { horizontal: "left" },
  };

  excelExport(newData, fileName, 13, headerStyle);
};
