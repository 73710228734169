import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";

const TMListDetailResultTable = ({
  openModal,
  searchResult,
  curPage,
  totalPage,
  onClickSearch,
  branchInfo,
}) => {
  const btnStyleClass = btnStyles();

  const onClickTmButton = (memberId) => {
    openModal("tm", {
      title: "TM 처리",
      // state: tm_member_detail_state,
      member_id: memberId,
      branch_info: branchInfo,
    });
  };

  const searchResultList = new resultTable(onClickTmButton);

  const doSearch = (page) => {
    onClickSearch(page);
  };

  // console.log(searchResult);
  // console.log(curPage, totalPage);
  return (
    <div>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
        // rest_data={[{ a: "" }]}
        // width="160rem"
      />
      <CustomPagination curPage={curPage} totalCnt={totalPage} doSearch={doSearch} />
    </div>
  );
};
export default TMListDetailResultTable;

class resultTable {
  table_title = "";
  onClickTmButton = null;

  constructor(onClickTmButton) {
    this.onClickTmButton = onClickTmButton;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow style={{}} key={idx}>
        <CustomTableContents>{data.member_type}</CustomTableContents>
        <CustomTableContents>{data.member_name}</CustomTableContents>
        <CustomTableContents>{data.phone}</CustomTableContents>
        <CustomTableContents>
          {data.tm_last_info.cs_date} {data.tm_last_info.cs_time}
        </CustomTableContents>
        <CustomTableContents>{data.tm_last_info.staff_info.staff_name}</CustomTableContents>
        <CustomTableContents>{data.tm_last_info.tm_status}</CustomTableContents>
        <CustomTableContents>{data.tm_last_info.inquiry_memo}</CustomTableContents>

        <CustomTableContentsEND>
          <IconButton
            type="edit"
            scale="1"
            tooltip="TM 처리"
            // onClick={this.onClickTmButton(data.member_id)}
            onClick={() => this.onClickTmButton(data.member_id)}
          />
        </CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "구분", width: "5%" },
    { title: "이름", width: "10%" },
    { title: "연락처", width: "12%" },
    { title: "최근 TM 일시", width: "14%" },
    { title: "최근 TM 담당자", width: "12%" },
    { title: "최근 TM 상태", width: "10%" },
    { title: "최근 TM 내용", width: "29%" },
    { title: "", width: "7%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
