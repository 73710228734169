import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MemberViewContents from "./MemberViewContents";
import { loadMemberViewStore } from "./Module/actions";

const MemberViewWrapper = ({ memberId }) => {
  const storesDispatch = useDispatch();

  //회원 디테일 GET
  const baseInfoState = useSelector((state) => state.member_service.member_show_state);

  //회원정보 불러오기
  useEffect(() => {
    storesDispatch(loadMemberViewStore(memberId));
  }, [memberId]);

  const itemList = baseInfoState.stores.data.membership_data_list.map(
    (el, idx) => `[${idx + 1}] ${el.membership_list.item_name}`,
  );

  return (
    <MemberViewContents
      baseInfoState={baseInfoState.stores.data}
      itemList={itemList}
      memberId={memberId}
    />
  );
};
export default MemberViewWrapper;
