import { Button } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { getAuthData } from "apis/access/get_auth_api";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnSubmitStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import {
  contentTypeJson,
  dateToStr,
  generateObjectZeroId,
  getCurrentDate,
  getDefaultValue,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import DocInfo from "components/File/DocInfo";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import {
  payListActions,
  subsPayListActions,
} from "pages/ERP/MemberCommonItems/Module/actions/index";
import SubscriptionInfo from "pages/ERP/MemberCommonItems/SubscriptionInfo";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PaymentInfo from "../../MemberCommonItems/PaymentInfo";
import RegisterPaidDate from "../../MemberCommonItems/RegisterPaidDate";
import MembershipInfo from "../Common/CardContents/MembershipInfo";
import OthersInfo from "../Common/CardContents/OthersInfo";
import PersonalInfo from "../Common/CardContents/PersonalInfo";
import PtInfo from "../Common/CardContents/PtInfo";
import { registerViewInitState } from "../Common/Module/reducers/view_init";
import RegisterModalIndex from "./Modal/RegisterModalIndex";
import { registerViewAction } from "./Module/actions";
import post_api from "./Module/api/PostApi";
import { access_method_app_init_state } from "./Module/reducers/access_method_init_state";
import { payment_init_state } from "./Module/reducers/payment_init_state";
import { useRegisterStore } from "./modules/store/registerState";

const RegisterView = ({
  baseInfoState,
  branchList,
  branchIdx,
  setBranchIdx,
  memberInputState,
  navigate,
}) => {
  // useBeforeunload((event) => event.preventDefault());
  const paymentInfoRef = useRef();
  const subscriptInfoRef = useRef();
  const ptInfoItemRef = useRef();
  const membershipInfoRef = useRef();

  const [regDate, setRegDate] = useState(dateToStr(new Date()));
  const [cardInfo, setCardInfo] = useState({
    card_no: "",
  });
  const [canEnroll, setCanEnroll] = useState(false);
  const [isSubsNow, setIsSubsNow] = useState(false);
  const [updateToDate, setUpdateToDate] = useState();
  const [loading, setLoading] = useState(false);
  const [removePhoneMemberId, setRemovePhoneMemberId] = useState("");
  const [docList, setDocList] = useState([]);
  const [phoneCheckStatus, setPhoneCheckStatus] = useState("");

  const btnStyleClass = btnSubmitStyles();
  const storesDispatch = useDispatch();

  const mbshpValidation = () => {
    let res = "";
    const curMbshp = memberInputState.membership_all_list[0].membership_list;
    const todayText = getCurrentDate();
    if (curMbshp.mbshp.to_date < todayText)
      res += "유효기간 종료일이 오늘보다 이전일 수 없습니다. 다시 입력해주세요.";

    return res;
  };

  const validation = () => {
    let res = "";
    const curMbshp = memberInputState.membership_all_list[0].membership_list;
    const d = new Date();
    const todayText = getCurrentDate();

    if (!memberInputState.join_way) res += "· 가입경로\n";

    if (!memberInputState.access_way) {
      // memberInputState.access_way = "카드";
      res += "· 출입방식\n";
    }
    if (curMbshp.mbshp_option !== "일일권") {
      if (memberInputState.access_way === "카드" && !cardInfo.card_no) res += "· 카드번호\n";
    }

    if (memberInputState.personal_info.member_name === "") res += "· 회원명\n";
    if (!memberInputState.personal_info.gender) res += "· 성별\n";
    if (curMbshp.mbshp_option !== "일일권") {
      if (
        todayText === memberInputState.personal_info.birth ||
        !memberInputState.personal_info.birth
      )
        res += "· 생년월일\n";
      if (!memberInputState.personal_info.address_1) res += "· 주소\n";
    }
    if (!memberInputState.personal_info.phone) res += "· 연락처\n";

    if (!memberInputState.cs_staff.staff_name) res += "· CS 상담자\n";

    if (curMbshp.mbshp_option === "일일권") {
      // 일일권
      if (!curMbshp.daily_time.from_time) res += "· 유효기간 (시작 시간)\n";
    } else {
      // 일일권 외
      if (curMbshp.mbshp.from_date.length < 10) res += "· 유효기간 시작일\n";
      if (!memberInputState.ot_staff.staff_name) res += "· OT 수업 담당자\n";
    }

    if (memberInputState.pt_info.default_session > 0) {
      if (!memberInputState.pt_info.cs_staff.staff_name) res += "· PT 상담자\n";
      if (!memberInputState.pt_info.pt_staff.staff_name) res += "· PT 수업 담당자\n";
    }

    if (!curMbshp.item_name) res += "· 회원권 상품\n";

    return res;
  };

  //
  const controlDocList = (docName, isValid) => {
    setDocList(
      docList.map((item, idx) => {
        if (item.doc_name === docName) {
          item.is_valid = isValid;
        }
        return item;
      }),
    );
  };

  const enrollConfirmPopupText = () => {
    const paidResult = paymentInfoRef.current.getCsPaidResult();
    const ptPaidResult = paymentInfoRef.current.getPtPaidResult();
    const fromTodate =
      memberInputState.membership_all_list[0].membership_list.mbshp.from_date +
      " ~ " +
      memberInputState.membership_all_list[0].membership_list.mbshp.to_date;

    return (
      <div>
        <Label
          labelText={"· 회원명 : " + memberInputState.personal_info.member_name}
          justifyContent="flex-start"
        />
        <Label
          marginTop="1rem"
          labelText={
            "· 회원권 상품명 : " + memberInputState.membership_all_list[0].membership_list.item_name
          }
          justifyContent="flex-start"
        />
        <Label labelText={"· 유효기간 : " + fromTodate} justifyContent="flex-start" />
        <Label
          labelText={"· CS 결제금액 : " + paidResult.total_paid.toLocaleString() + "원"}
          justifyContent="flex-start"
        />
        <Label
          labelText={"· CS 미납금 : " + paidResult.unpaid_info.unpaid_price.toLocaleString() + "원"}
          justifyContent="flex-start"
        />

        {memberInputState.pt_info.default_session < 1 ? (
          <></>
        ) : (
          <>
            <Label
              marginTop="1rem"
              labelText={"· PT 세션 : " + memberInputState.pt_info.default_session + "회"}
              justifyContent="flex-start"
            />
            <Label
              labelText={"· 서비스 세션 : " + memberInputState.pt_info.service_session + "회"}
              justifyContent="flex-start"
            />
            <Label
              labelText={"· PT 결제금액 : " + ptPaidResult.total_paid.toLocaleString() + "원"}
              justifyContent="flex-start"
            />
            <Label
              labelText={
                "· PT 미납금 : " + ptPaidResult.unpaid_info.unpaid_price.toLocaleString() + "원"
              }
              justifyContent="flex-start"
            />
          </>
        )}

        <Label marginTop="1rem" labelText={"· 등록일 : " + regDate} justifyContent="flex-start" />

        <Label labelText={"위 내용이 맞습니까?"} marginTop="2rem" />
      </div>
    );
  };

  const checkOnedayMbshp = () => {
    if (memberInputState.membership_all_list[0].membership_list.mbshp_option === "일일권") {
      for (let i = 0; i < memberInputState.paymentA.payment_info.length; i++) {
        if (memberInputState.paymentA.payment_info[i].payment_input.unpaid_info.unpaid_price > 0) {
          storesDispatch(
            globalAlertOn({
              show: true,
              titleText: "알림",
              bodyText: <Label labelText="일일권은 결제금액을 완납해야 합니다." />,
              onBtnOk: () => {},
            }),
          );
          return false;
        }
      }
    }
    return true;
  };

  const onChangeMbshpOption = () => {
    if (memberInputState.membership_all_list[0].membership_list.mbshp_option === "일일권") {
      memberInputState.card_param = { ...registerViewInitState.card_param };
      setCardInfo({
        card_no: "",
      });
    }
  };

  const enrollPopup = () => {
    if (!checkOnedayMbshp()) {
      return;
    }

    const mbshpValidationRes = mbshpValidation();
    if (mbshpValidationRes !== "") {
      simpleAlert(storesDispatch, "알림", mbshpValidationRes);
      return;
    }

    const validationRes = validation();

    if (validationRes !== "") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="1. 필수값을 모두 입력해주셔야 합니다." />
              <br />
              <br />
              <Label labelText={validationRes} whiteSpace="pre-wrap" />
            </>
          ),
          // width: "50rem",
          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: enrollConfirmPopupText(),
          width: "25rem",
          onBtnYes: () => onSaveEvent(),
          onBtnNo: () => {},
        }),
      );
    }
  };

  // 지점선택 시 초기화
  useEffect(() => {
    membershipInfoRef.current.onChangeBranch();
    resetPaySubInfoListAction();
    setIsSubsNow(false);
  }, [baseInfoState]);

  useEffect(() => {
    paymentInfoRef.current.resetPaidResult();
  }, [regDate]);

  //파일 초기화
  useEffect(() => {
    if (memberInputState.contract_doc_list.length !== 0) {
      setDocList(memberInputState.contract_doc_list);
    }
  }, [memberInputState.contract_doc_list.length]);

  const onClickOpenModal = (order, param) => {
    if (order !== "") {
      // lockerModelRef.current.setOpen();
      openModal(order, param);
    }
  };

  const openModal = (select, param) => {
    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,
        Content: (
          <RegisterModalIndex
            modalSelect={select}
            modalParam={param}
            onModalDone={onModalDone}
            uploadDoneCallback={uploadDoneCallback}
          />
        ),
      }),
    );
  };

  const onPtItemUpdate = () => {
    ptInfoItemRef.current.init();
  };

  const onPaymentUpdate = (csPayInfo, ptPayInfo) => {
    const paymentInfo = memberInputState.paymentA.payment_info;
    const paidItemList = paymentInfoRef.current.getPaidItemList();
    let existCs = false;
    let existPt = false;

    paidItemList.forEach((item) => {
      if (item.type_key !== "PT") {
        existCs = true;
      } else {
        existPt = true;
      }
    });

    // set CS
    if (existCs /*csPayInfo.pay_method.length > 0*/) {
      let idx = paymentInfo.findIndex((x) => x.temp_item_type === "cs");
      if (idx === -1) {
        idx = paymentInfo.length;
        paymentInfo.push({
          ...payment_init_state,
          temp_item_type: "cs",
        });
      }
      paymentInfo[idx].payment_input = csPayInfo;
      // paid_item 추가
      paymentInfo[idx].paid_item_list = [];
      paidItemList.forEach((item) => {
        if (item.type_key !== "PT") {
          paymentInfo[idx].paid_item_list.push(item);
        }
      });

      paymentInfoRef.current.setCsPaidResult(csPayInfo);
    }

    // set PT
    if (existPt /*ptPayInfo.pay_method.length > 0*/) {
      let idx = paymentInfo.findIndex((x) => x.temp_item_type === "pt");
      if (idx === -1) {
        idx = paymentInfo.length;
        paymentInfo.push({
          ...payment_init_state,
          temp_item_type: "pt",
        });
      }
      paymentInfo[idx].payment_input = ptPayInfo;
      // paid_item 추가
      paymentInfo[idx].paid_item_list = [];
      paidItemList.forEach((item) => {
        if (item.type_key === "PT") {
          paymentInfo[idx].paid_item_list.push(item);
        }
      });

      paymentInfoRef.current.setPtPaidResult(ptPayInfo);
    }
  };

  const onModalDone = (modalType, param1, param2) => {
    //
    if (modalType === "결제") {
      onPaymentUpdate(param1, param2);
      if (param1.pay_method.length > 0 || param2.pay_method.length > 0) {
        setCanEnroll(true);
      }
    } else if (modalType === "쿠폰") {
      const couponUseList = memberInputState.coupon_use_list;
      const newCouponInfo = {
        coupon_info: param1,
        membership_info: {
          membership_id: "",
          purchase_item_id: "",
        },
        ticket_info: {
          is_issuance: true,
          is_used: true,
          ticket_id: param1.ticket_id,
          member_info: {
            member_id: generateObjectZeroId(),
            member_name: "",
            member_no: "",
          },
        },
      };
      // console.log(memberInputState);
      couponUseList.splice(0, couponUseList.length); // clear
      couponUseList.push(newCouponInfo);
      paymentInfoRef.current.setCouponInfo(param1);
      setUpdateToDate(param1.coupon_id); // 이용기간 쿠폰인 경우 기간 업데이트를 위함
      //
    } else if (modalType === "정기결제수단") {
      memberInputState.regular_pay_method = param1;
    } else if (modalType === "연락처중복") {
      setRemovePhoneMemberId(param1);
      if (param2) {
        setPhoneCheckStatus("S");
        memberInputState.personal_info.phone = param2;
      }
    }

    storesDispatch(globalModalOff());
    // paidInfoPush(pay_info.pay_method, pay_info.total_paid, pay_info.unpaid_info);
  };

  // 구독형 회원권이 있으면 sub_payment 데이터 입력
  const setSubsPaymentData = () => {
    const curMbshpList = memberInputState.membership_all_list[0].membership_list;
    const mbshpType = curMbshpList.mbshp_type;
    // const subPayment = memberInputState.sub_payment;
    if (mbshpType === "구독형") {
      const itemList = subscriptInfoRef.current.getPaidItemList();
      const subPayment = {
        paid_item_list: itemList,
        payment_info: memberInputState.regular_pay_method,
        tab_info: {
          MembershipIdx: 0,
          idx: 0,
          title: "신규",
        },
        view_info: {
          is_use: true,
          monthly_total: 0,
          raw_paid: 0,
          total_paid: 0,
        },
      };

      curMbshpList.sub_payment = subPayment;
    }
  };

  const onSaveEvent = async () => {
    // INPUT INIT DATA
    setSubsPaymentData();

    if (memberInputState.membership_all_list[0].membership_list.mbshp_option === "일일권") {
      memberInputState.card_param.card_no = 0;
    } else {
      if (memberInputState.card_param.card_no) {
        memberInputState.card_param.card_no = Number(memberInputState.card_param.card_no);
      }
    }

    memberInputState.branch_info = {
      branch_id: baseInfoState.selected_branch_id,
      branch_name: baseInfoState.selected_branch_name,
    };
    memberInputState.hq_info = baseInfoState.branch_setting.hq_info;

    memberInputState.membership_all_list[0].purchase_item_list.branch_policy =
      baseInfoState.branch_setting;
    memberInputState.membership_all_list[0].purchase_item_list.erp_policy =
      baseInfoState.erp_setting;
    memberInputState.membership_all_list[0].purchase_item_list.hq_policy = baseInfoState.hq_setting;

    memberInputState.reg_date = regDate;
    memberInputState.personal_info.member_name = memberInputState.personal_info.member_name.trim();

    // console.log(JSON.stringify(memberInputState));

    const formData = new FormData();

    //파일 분기처리 필요!! (일단 현재는 다 is_use === true로 해서 전송)
    memberInputState.contract_doc_list = docList;
    memberInputState.contract_doc_list.forEach((el) => (el.is_use = true));
    //세 -> 임시추가.... 요기까지 2줄

    formData.append("new_info", JSON.stringify(memberInputState));
    const postApi = post_api.insertNewUser();

    setLoading(true);

    // // // 전화번호 기존 소유자가 있으면 삭제 API call
    if (removePhoneMemberId) {
      const res = HttpInstance.patch(
        process.env.REACT_APP_USER_SERVER + "/member_info/delete_phone",
        "",
        {
          params: {
            member_id: removePhoneMemberId,
            phone: memberInputState.personal_info.phone,
          },
        },
      );
      if (res.msg) {
        setLoading(false);
        simpleAlert(storesDispatch, "알림", res.msg);
        return;
      }
    }

    await HttpInstance.post(postApi, formData, contentTypeJson)
      .then((res) => {
        const resId = res.data.data;
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          storesDispatch(
            globalAlertOn({
              show: true,
              titleText: "알림",
              bodyText: <Label labelText="등록이 완료되었습니다. 회원정보 페이지로 이동합니다." />,
              reqConfirm: true,
              onBtnOk: () => {
                storesDispatch(registerViewAction.loadRegisterViewReset());
                navigate("/member_info/" + resId);
              },
            }),
          );
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const payInfoListAction = (act, type_key, detailInfo, ord, mbshpIdx) => {
    if (act === "push") {
      storesDispatch(payListActions.paymentInfoPush(type_key, detailInfo, ord, mbshpIdx));
    } else if (act === "pop") {
      storesDispatch(payListActions.paymentInfoPop(type_key));
    } else if (act === "discount") {
      storesDispatch(payListActions.paymentInfoEdit(type_key, detailInfo, ord, mbshpIdx));
    }
  };

  // type_key, type, content_obj, ordIdx, mbshpIdx
  const subsPayInfoListAction = (act, typeKey, contentObj, ordIdx, mbshpIdx) => {
    if (act === "push") {
      storesDispatch(subsPayListActions.subsPayInfoPush(typeKey, contentObj, ordIdx, mbshpIdx));
    } else if (act === "pop") {
      storesDispatch(subsPayListActions.subsPayInfoPop(typeKey));
    } else if (act === "discount") {
      storesDispatch(subsPayListActions.subsPayInfoEdit(typeKey, contentObj, ordIdx, mbshpIdx));
    }
  };

  const resetPaySubInfoListAction = () => {
    storesDispatch(payListActions.paymentInfoClear("clear"));
    storesDispatch(subsPayListActions.subsPayInfoClear("clear"));
    setCanEnroll(false);
  };

  const exportData = () => {
    setSubsPaymentData();

    if (memberInputState.card_param.card_no) {
      memberInputState.card_param.card_no = Number(memberInputState.card_param.card_no);
    }

    memberInputState.branch_info = {
      branch_id: baseInfoState.selected_branch_id,
      branch_name: baseInfoState.selected_branch_name,
    };
    memberInputState.membership_all_list[0].purchase_item_list.branch_policy =
      baseInfoState.branch_setting;
    memberInputState.membership_all_list[0].purchase_item_list.erp_policy =
      baseInfoState.erp_setting;
    memberInputState.membership_all_list[0].purchase_item_list.hq_policy = baseInfoState.hq_setting;

    memberInputState.reg_date = regDate;

    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(memberInputState),
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    // console.log(memberInputState);
    let name = memberInputState.personal_info.member_name;
    link.download = name + "_data.json";

    link.click();
  };

  const uploadDoneCallback = (result) => {
    //업로드에서 받아온 파일을 contract_doc_list에 갱신
    const idx = docList.findIndex((el) => el.file_info.doc_name === result.file_info.doc_name);
    setDocList((cur) => {
      const arr = [...cur];
      arr[idx] = result;
      return arr;
    });
  };

  return (
    <div style={{ display: "flex" }}>
      {loading ? <Loading /> : <></>}

      <div style={{ width: "77rem" }}>
        <div style={{ display: "flex", marginBottom: "1rem" }}>
          <CardFixed
            topDom={<div>가입정보</div>}
            midDom={
              <RegisterInfoCard
                baseInfoState={baseInfoState}
                memberInputState={memberInputState}
                branchList={branchList}
                branchIdx={branchIdx}
                setBranchIdx={setBranchIdx}
                onClickOpenModal={onClickOpenModal}
                cardInfo={cardInfo}
                setCardInfo={setCardInfo}
              />
            }
            isLoading={false}
            rootStyle={{
              width: "21rem",
              height: "15.5rem",
              borderRadius: "10px",
              backgroundColor: "#fff",
            }}
          />
          {/* 회원정보 */}
          <PersonalInfo
            baseInfoState={baseInfoState}
            memberInputState={memberInputState}
            onClickOpenModal={onClickOpenModal}
            navigate={navigate}
            setRemovePhoneMemberId={setRemovePhoneMemberId}
            onModalDone={onModalDone}
            phoneCheckStatus={phoneCheckStatus}
            setPhoneCheckStatus={setPhoneCheckStatus}
          />
        </div>

        <CardFixed
          topDom={<div>회원권</div>}
          midDom={
            <MembershipInfo
              ref={membershipInfoRef}
              regDate={regDate}
              defaultFromDate={baseInfoState.branch_setting.membership_policy.dflt_from_date}
              payInfoListAction={payInfoListAction}
              subsPayInfoListAction={subsPayInfoListAction}
              baseInfoState={baseInfoState}
              onClickOpenModal={onClickOpenModal}
              orderStartIdx={0}
              membershipIdx={0}
              memberInputState={memberInputState.membership_all_list[0]}
              memberInputStateAll={memberInputState}
              onPtItemUpdate={onPtItemUpdate}
              isAddPay={false}
              last_to_date={memberInputState.last_to_date}
              subs_lock={false}
              resetPaySubInfoListAction={resetPaySubInfoListAction}
              setIsSubsNow={setIsSubsNow}
              from_lock={false}
              updateToDate={updateToDate}
              onChangeMbshpOption={onChangeMbshpOption}
            />
          }
          isLoading={false}
          rootStyle={{
            width: "76.88rem",
            borderRadius: "10px",
            backgroundColor: "#fff",
          }}
        />

        <PtInfo
          ref={ptInfoItemRef}
          baseInfoState={baseInfoState}
          payInfoListAction={payInfoListAction}
          memberPtState={memberInputState.pt_info}
          memberInputState={memberInputState.membership_all_list[0]}
          memberInputStateAll={memberInputState}
          isAddPay={false}
        />

        <OthersInfo
          payInfoListAction={payInfoListAction}
          branchId={baseInfoState.branch_setting.branch_info.branch_id}
        />
      </div>

      <div style={{ width: "28.13rem", marginLeft: "0.625rem" }}>
        <PaymentInfo
          ref={paymentInfoRef}
          onPaymentUpdate={onPaymentUpdate}
          onClickOpenModal={onClickOpenModal}
          regDate={regDate}
          baseInfoState={baseInfoState}
          setCanEnroll={setCanEnroll}
          isAddPay={false}
          remainPoint={0}
          controlDocList={controlDocList}
        />

        {isSubsNow ? (
          <SubscriptionInfo
            ref={subscriptInfoRef}
            onClickOpenModal={onClickOpenModal}
            memberInputState={memberInputState}
            isAddPay={false}
            regularPayDate={baseInfoState.erp_setting.regular_pay_date}
          />
        ) : (
          <div></div>
        )}
        <RegisterPaidDate regDate={regDate} setRegDate={setRegDate} />
        {/* <DocumentInfo /> */}
        <DocInfo docList={docList} textBoxWidth={"11rem"} uploadDoneCallback={uploadDoneCallback} />
        <Button
          style={{ marginTop: "1.5rem" }}
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType2]: true,
            [btnStyleClass.disabled]: !canEnroll,
          })}
          onClick={() => enrollPopup()}
          disabled={!canEnroll}
        >
          등록
        </Button>
        {/* 
        <Button
          style={{ marginTop: "1.5rem" }}
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType2]: true,
          })}
          onClick={() => {
            console.log(memberInputState);
            // console.log(docList);
            // console.log(memberInputState.membership_all_list[0].membership_list.mbshp);
            // console.log(paymentInfoRef.current.getPaidItemList());
            // console.log(subscriptInfoRef.current.getPaidItemList());
          }}
        >
          TEST BTN
        </Button> */}
        {/*
        <Button
          style={{ marginTop: "1.5rem" }}
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType2]: true,
          })}
          onClick={() => exportData()}
        >
          DUMP
        </Button> */}
      </div>
    </div>
  );
};
export default RegisterView;

//
const RegisterInfoCard = ({
  baseInfoState,
  memberInputState,
  branchList,
  branchIdx,
  setBranchIdx,
  onClickOpenModal,
  cardInfo,
  setCardInfo,
}) => {
  const comboItemWidth = "12rem";
  const comboItemWidthCardInput = "9.5rem";
  const lineMarginTop = "0.5rem";
  const labelWidth = "4.5rem";

  const storesDispatch = useDispatch();
  // 전역 상태 관리
  const { checkMbshpOption } = useRegisterStore();
  // 페이지 상태 관리
  const [isAdmin, setIsAdmin] = useState(false);
  const [enableCardSelect, setEnableCardSelect] = useState(false);

  // [GET] 권한 정보 가져오기
  const { data: authData } = useQuery({
    queryKey: ["auth"],
    queryFn: () => getAuthData(),
    enabled: true,
    retry: false,

    onError: () => {
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    },
  });

  const onBranchChange = (e, v) => {
    // make a separate copy of the array
    const index = branchList.map((item) => item).indexOf(v);
    if (index !== -1) {
      // 1
      setBranchIdx(index);
      // 2
      changeCardInfo({
        card_no: "",
        card_info_id: "",
        branch_info: {},
      });
      // 3
      memberInputState.cs_staff = {
        staff_id: generateObjectZeroId(),
        staff_name: "",
        staff_pos: "",
        staff_rank: "",
      };
      // 지점 변경 시 "앱"출입 자동 선택
      onChangeAccessMethod({ target: { value: "앱" } });
    }
  };

  const onSearchCard = () => {
    if (memberInputState.membership_all_list[0].membership_list.mbshp_option === "일일권") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="일일권은 카드를 등록할 수 없습니다." />,
          onBtnOk: () => {},
        }),
      );
      return;
    }

    const param = {
      title: "카드검색",
      modalParam: {
        mode: "search",
        searchCallback: onSearchCardCallback,
        branch_id: baseInfoState.selected_branch_id,
      },
    };
    onClickOpenModal("카드검색", param);
  };

  const changeCardInfo = (data) => {
    setCardInfo(data);
    if (memberInputState.access_way === "앱") {
      memberInputState.card_param = {
        access_method: "앱",
        ...memberInputState.card_param,
        ...access_method_app_init_state,
      };
      return;
    }
    memberInputState.card_param.access_method = "카드";
    memberInputState.card_param.branch_info = data.branch_info;
    memberInputState.card_param.card_info_id = data.card_info_id;
    memberInputState.card_param.card_no = data.card_no.toString();
  };

  const onSearchCardCallback = (data) => {
    changeCardInfo(data);
    storesDispatch(globalModalOff());
  };

  // 출입 방식 변경
  const onChangeAccessMethod = (e) => {
    memberInputState.access_way = e.target.value;

    if (e.target.value === "카드") {
      setEnableCardSelect(true);
      return;
    }
    if (e.target.value === "앱") {
      memberInputState.card_param = {
        ...memberInputState.card_param,
        ...access_method_app_init_state,
      };
      setCardInfo({
        card_no: "",
      });
      setEnableCardSelect(false);
      return;
    }
  };

  // 권한 정보 세팅
  useEffect(() => {
    if (authData) {
      setIsAdmin(authData.data.data.is_admin);
    }
  }, [authData]);

  // "일일권" 일 때 카드 선택창 제거 및 "앱 출입" 자동 선택
  useEffect(() => {
    if (checkMbshpOption === "일일권") {
      setEnableCardSelect(false);
      onChangeAccessMethod({ target: { value: "앱" } });
    }
  }, [checkMbshpOption]);

  // 처음에 페이지 진입 시 출입방식 "앱" 세팅
  useEffect(() => {
    if (memberInputState.access_way === "") {
      onChangeAccessMethod({ target: { value: "앱" } });
    }
  }, [memberInputState.access_way]);

  return (
    <div>
      <SearchComboBox
        required
        marginTop={lineMarginTop}
        labelWidth={labelWidth}
        comboItemWidth={comboItemWidth}
        labelText="지점"
        defaultValue={baseInfoState.selected_branch_name}
        data={branchList}
        onChangeCallback={onBranchChange}
      />
      <ComboBox
        required
        marginTop={lineMarginTop}
        labelWidth={labelWidth}
        comboItemWidth={comboItemWidth}
        labelText="가입경로"
        data={
          baseInfoState.erp_setting.reg_path_list === undefined
            ? ["."]
            : baseInfoState.erp_setting.reg_path_list
        }
        onChangeCallback={(e) => {
          // baseInfoState.reg_path = e.target.value;
          memberInputState.join_way = e.target.value;
        }}
      />
      <ComboBox
        required
        marginTop={lineMarginTop}
        labelWidth={labelWidth}
        comboItemWidth={comboItemWidth}
        labelText="출입방식"
        data={
          // 관리자 및 일일권 여부에 따른 출입 방식 combo 선택
          // isAdmin ? (checkMbshpOption === "일일권" ? ["앱"] : ["앱", "카드"]) : ["앱"]
          checkMbshpOption === "일일권" ? ["앱"] : ["앱", "카드"]
        }
        defaultValue={getDefaultValue("combo", memberInputState.access_way)} // temp!
        onChangeCallback={(e) => onChangeAccessMethod(e)}
      />
      <div style={{ display: "flex", marginTop: lineMarginTop }}>
        {enableCardSelect && (
          <>
            <TextField
              required
              labelWidth={labelWidth}
              textMarginRight="0.25rem"
              textBoxWidth={comboItemWidthCardInput}
              labelText=""
              defaultValue={cardInfo.card_no}
              disabled
            />
            <IconButton
              type="search"
              width="2.3rem"
              onClick={onSearchCard}
              disabled={!enableCardSelect}
            />
          </>
        )}
      </div>
    </div>
  );
};
