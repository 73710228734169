import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import ColorLabel from "components/LabelInput/ColorLabel";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const BookmarkApprovalLineModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();
  const label_width = "4rem";
  const box_width = "20rem";
  const boxStyle = {
    marginTop: "0.7rem",
    width: box_width,
    height: "2.8rem",
    borderRadius: "5px",
    border: "1px solid #BBBBBB",
    backgroundColor: "#ebebeb",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.9rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
    boxSizing: "border-box",
    padding: "0.2rem",
    display: "flex",
    gap: "0.2rem",
  };

  const [bookmark, setBookmark] = useState({
    line_info_list: [],
    line_name_list: [],
  });
  const [draftLine, setDraftLine] = useState({
    approverList: [],
    receiverList: [],
    viewerList: [],
  });
  const [loading, setLoading] = useState(false);

  const [idx, setIdx] = useState();

  const getBookmarkApprovalLine = async () => {
    try {
      setLoading(true);
      const res = await HttpInstance.get(
        process.env.REACT_APP_STAFF_SERVER + "/approval/draft/line/list",
        {
          params: {},
        },
      );
      if (!restEmpty(res.data.data)) {
        setBookmark(res.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  const getDetail = async () => {
    if (setIdx !== undefined) {
      try {
        setLoading(true);
        const res = await HttpInstance.get(
          process.env.REACT_APP_STAFF_SERVER + "/approval/draft/line/detail",
          {
            params: {
              approval_line_id: bookmark.line_info_list[idx].approval_line_id,
            },
          },
        );
        if (!restEmpty(res.data.data)) {
          const result = res.data.data;
          const approverList = result.approver.map((el) => el.staff_info.staff_name);
          const receiverList = result.receiver.map((el) => el.staff_info.staff_name);
          const viewerList = result.viewer.map((el) => el.staff_info.staff_name);
          setDraftLine({ approverList, receiverList, viewerList });
        }
      } catch (err) {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getBookmarkApprovalLine();
  }, []);

  useEffect(() => {
    if (idx !== undefined) {
      getDetail();
    }
  }, [idx]);

  const onSaveEvt = () => {
    if (draftLine.approverList.length === 0) {
      simpleAlert(storesDispatch, "알림", "결재자가 없습니다.");
      return;
    }

    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="기안 하시겠습니까?" />
          </>
        ),
        onBtnYes: () => {
          draftApprovalLine();
        },
        onBtnNo: () => {},
      }),
    );
  };

  const draftApprovalLine = async () => {
    try {
      const formData = new FormData();
      formData.append("salary_report_id", modalParam.salaryReportId);
      formData.append("new_info", JSON.stringify());
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_SALARY_SERVER + "/salary/approval/draft",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: (
              <>
                <Label labelText="기안 되었습니다." />
              </>
            ),
            onBtnOk: () => {
              onModalDone(true); //메인갱신 + 모달 닫기
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <Loading />}

      <div>
        <ComboBox
          labelWidth={label_width}
          comboItemWidth={box_width}
          labelText="결재선명"
          data={bookmark.line_name_list ? bookmark.line_name_list : []}
          onChangeCallback={(e) => {
            const index = bookmark.line_name_list.findIndex((el) => el === e.target.value);
            if (index !== -1) {
              setIdx(index);
            }
          }}
        />
        <div style={{ display: "flex" }}>
          <Label labelText="결재" marginRight="2.6rem" />
          <div style={boxStyle}>
            {draftLine.approverList.map((name) => (
              <ColorLabel labelText={name} bgColor="#222222" fontColor="#FFFFFF" />
            ))}
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <Label labelText="열람" marginRight="2.6rem" />
          <div style={boxStyle}>
            {draftLine.viewerList.map((name) => (
              <ColorLabel labelText={name} bgColor="#222222" fontColor="#FFFFFF" />
            ))}
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <Label labelText="수신" marginRight="2.6rem" />
          <div style={boxStyle}>
            {draftLine.receiverList.map((name) => (
              <ColorLabel labelText={name} bgColor="#222222" fontColor="#FFFFFF" />
            ))}
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "0.7rem" }}>
        <Button
          className={clsx({
            [btnStyleClass.btnRootRaw]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={onSaveEvt}
        >
          기안
        </Button>
      </div>
    </div>
  );
};

export default BookmarkApprovalLineModal;
