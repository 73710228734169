import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import {
  generateObjectZeroId,
  getDefaultValue,
  restEmpty,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import ColorCheckbox from "components/LabelInput/ColorCheckbox";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const ScheduleSearchCard = ({
  mode,
  searchParam,
  setSearchParam,
  onClickSearch,
  curPage,
  setCurPage,
}) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();
  const label_width = "3rem";
  const box_width = "15rem";
  // 페이지 상태 관리
  const [hqIdx, setHqIdx] = useState(0);
  const [branchIdx, setBranchIdx] = useState(0);
  const [selectedBranch, setSelectedBranch] = useState({
    branch_id: "",
    branch_name: "",
  });
  const [staffInfo, setStaffInfo] = useState({
    ch_staff_list: [],
    ch_staff_name_list: [],
  });
  const [filterCheck, setFilterCheck] = useState({
    reserv: false,
    class_done: false,
  });
  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);

  //지점이 선택되면 지점별 직원리스트 불러오기 (필수: 서파에 세팅해야함)
  const getStaffInBranch = async () => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_STAFF_SERVER + "/schedule/search_param/ch_staff_list",
        {
          params: {
            branch_id: selectedBranch.branch_id,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        const result = res.data.data;
        setSearchParam((cur) => {
          return { ...cur, ch_staff_list: result.ch_staff_list };
        });
        setStaffInfo(result);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  //지점이 바뀌면 다시 해당 지점의 직원리스트 GET
  useEffect(() => {
    if (selectedBranch.branch_name) {
      getStaffInBranch();
    }
  }, [selectedBranch]);

  //지점 바뀜 -> 직원리스트 바뀜 (서파에) -> 다시 검색요청
  // useEffect(() => {
  //   if (mode === "day") {
  //     onClickSearch(mode, 1);
  //   } else {
  //     onClickSearch(mode);
  //   }
  // }, [searchParam.charger_staff_info]);

  const validationCheck = () => {
    if (hqIdx === undefined || !selectedBranch.branch_name) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="본부와 지점은 필수로 선택되어야 합니다." />
            </>
          ),

          onBtnOk: () => {},
        }),
      );
    } else {
      onClickSearch(mode);
    }
  };

  //검색필터 체크
  useEffect(() => {
    if (
      filterCheck.class_done &&
      (!searchParam.is_ot_class_done || !searchParam.is_pt_class_done)
    ) {
      setFilterCheck((cur) => {
        return { ...cur, class_done: false };
      });
    } else if (
      !filterCheck.class_done &&
      searchParam.is_ot_class_done &&
      searchParam.is_pt_class_done
    ) {
      setFilterCheck((cur) => {
        return { ...cur, class_done: true };
      });
    } else if (
      filterCheck.reserv &&
      (!searchParam.is_reserv_cs || !searchParam.is_reserv_ot || !searchParam.is_reserv_pt)
    ) {
      setFilterCheck((cur) => {
        return { ...cur, reserv: false };
      });
    } else if (
      !filterCheck.reserv &&
      searchParam.is_reserv_cs &&
      searchParam.is_reserv_ot &&
      searchParam.is_reserv_pt
    ) {
      setFilterCheck((cur) => {
        return { ...cur, reserv: true };
      });
    }
  }, [searchParam]);

  useEffect(() => {
    // 첫 본부 및 지점 검색 조건 세팅
    if (searchParam.hq_branch_data.target_list.length > 0) {
      if (isFirstEnterPage && searchParam.hq_info.hq_id === generateObjectZeroId()) {
        const firstHqInfo = searchParam.hq_branch_data.target_list[0].hq_info;
        const firstBranchInfo = searchParam.hq_branch_data.target_list[0].branch_list[0];

        setSelectedBranch(firstBranchInfo);
        setSearchParam((cur) => {
          return {
            ...cur,
            hq_info: firstHqInfo,
            branch_info: firstBranchInfo,
          };
        });
      }
    }
    // 첫 담당자 검색 조건 세팅
    if (isFirstEnterPage && staffInfo.ch_staff_list.length > 0) {
      const firstStaffInfo = staffInfo.ch_staff_list[0];
      setSearchParam((cur) => {
        return {
          ...cur,
          charger_staff_info: firstStaffInfo,
        };
      });
      setIsFirstEnterPage(false);
      onClickSearch(mode);
    }
  }, [searchParam.hq_branch_data, staffInfo]);

  // 본부 선택 시 지점 정보 및 담당자 첫번째 값 세팅
  useEffect(() => {
    if (!isFirstEnterPage) {
      const firstBranchInfo = searchParam.hq_branch_data.target_list[hqIdx].branch_list[0];
      const firstStaffInfo = staffInfo.ch_staff_list[0];
      setSelectedBranch(firstBranchInfo);
      setSearchParam((cur) => {
        return {
          ...cur,
          branch_info: firstBranchInfo,
          charger_staff_info: firstStaffInfo,
        };
      });
    }
  }, [hqIdx]);

  // 지점 변경 시 담당자 초기화 세팅
  useEffect(() => {
    if (!isFirstEnterPage) {
      const firstStaffInfo = staffInfo.ch_staff_list[0];
      setSearchParam((cur) => {
        return {
          ...cur,
          charger_staff_info: firstStaffInfo,
        };
      });
    }
  }, [branchIdx]);

  return (
    <div>
      <CardFixed
        topDom={<div>검색 조건</div>}
        midDom={
          <div style={{ width: "100%" }}>
            <div
              style={{
                marginTop: "1rem",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <ComboBox
                labelWidth={label_width}
                comboItemWidth={box_width}
                labelText="본부"
                data={searchParam.hq_branch_data.hq_list}
                defaultValue={getDefaultValue("combo", searchParam.hq_info.hq_name)}
                onChangeCallback={(e) => {
                  const idx = searchParam.hq_branch_data.hq_list.findIndex(
                    (el) => el === e.target.value,
                  );
                  if (idx !== -1) {
                    setHqIdx(idx);
                    const hq = searchParam.hq_branch_data.target_list[idx].hq_info;
                    setSearchParam((cur) => {
                      return { ...cur, hq_info: hq };
                    });
                  }
                }}
              />
              <SearchComboBox
                labelWidth={label_width}
                comboItemWidth={box_width}
                labelText="지점"
                data={hqIdx !== undefined ? searchParam.hq_branch_data.branch_list[hqIdx] : []}
                defaultValue={getDefaultValue("combo", searchParam.branch_info.branch_name)}
                onChangeCallback={(e, v) => {
                  const branchIdx = searchParam.hq_branch_data.branch_list[hqIdx].findIndex(
                    (el) => el === v,
                  );
                  if (branchIdx !== -1) {
                    const branch =
                      searchParam.hq_branch_data.target_list[hqIdx].branch_list[branchIdx];
                    setBranchIdx(branchIdx);
                    setSelectedBranch(branch);
                    setSearchParam((cur) => {
                      return { ...cur, branch_info: branch };
                    });
                  }
                }}
              />
              <ComboBox
                labelWidth={label_width}
                comboItemWidth={box_width}
                labelText="담당자"
                data={staffInfo.ch_staff_name_list}
                defaultValue={getDefaultValue("combo", searchParam.charger_staff_info.staff_name)}
                onChangeCallback={(e) => {
                  const staffIdx = staffInfo.ch_staff_name_list.findIndex(
                    (el) => el === e.target.value,
                  );

                  const staff = staffInfo.ch_staff_list[staffIdx];
                  setSearchParam((cur) => {
                    return { ...cur, charger_staff_info: staff };
                  });
                }}
              />
            </div>
            {/* <div style={{ marginTop: "0.7rem" }}>
              <Label
                labelText="※ 전체 선택 시 “개인업무” 항목은 확인이 불가합니다."
                fontSize="0.7rem"
                color="#777777"
              />
            </div> */}
            <div style={{ marginTop: "1.5rem" }}>
              <SubTitle titleText="스케줄 구분" fontSize="1.125rem" />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CheckBox
                marginTop="0rem"
                fontSize="0.8rem"
                labelText="예약"
                labelWidth="2.8rem"
                defaultValue={filterCheck.reserv}
                marginRight="0rem"
                onChangeCallback={(e) => {
                  if (e.checked) {
                    //
                    setFilterCheck((cur) => {
                      return { ...cur, reserv: true };
                    });
                    setSearchParam((cur) => {
                      return { ...cur, is_reserv_cs: true, is_reserv_ot: true, is_reserv_pt: true };
                    });
                  } else {
                    setFilterCheck((cur) => {
                      return { ...cur, reserv: false };
                    });
                    setSearchParam((cur) => {
                      return {
                        ...cur,
                        is_reserv_cs: false,
                        is_reserv_ot: false,
                        is_reserv_pt: false,
                      };
                    });
                  }
                }}
              />
              <div style={{ display: "flex", gap: "0.4rem" }}>
                <ColorCheckbox
                  fontSize="0.8rem"
                  labelText="OT"
                  bgColor="#FF6B17"
                  fontColor="#FFFFFF"
                  defaultValue={searchParam.is_reserv_ot}
                  onChangeCallback={(e) => {
                    setSearchParam((cur) => {
                      return { ...cur, is_reserv_ot: e.checked };
                    });
                  }}
                />
                <ColorCheckbox
                  labelText="PT"
                  bgColor="#30C8C8"
                  fontColor="#FFFFFF"
                  fontSize="0.8rem"
                  defaultValue={searchParam.is_reserv_pt}
                  onChangeCallback={(e) => {
                    setSearchParam((cur) => {
                      return { ...cur, is_reserv_pt: e.checked };
                    });
                  }}
                />
                <ColorCheckbox
                  labelText="상담"
                  bgColor="#3022D7"
                  fontColor="#FFFFFF"
                  fontSize="0.8rem"
                  defaultValue={searchParam.is_reserv_cs}
                  onChangeCallback={(e) => {
                    setSearchParam((cur) => {
                      return { ...cur, is_reserv_cs: e.checked };
                    });
                  }}
                />
              </div>
            </div>
            <div style={{ marginTop: "0.7rem", display: "flex", alignItems: "center" }}>
              <CheckBox
                marginTop="0rem"
                labelText="수업완료"
                labelWidth="2.8rem"
                defaultValue={filterCheck.class_done}
                marginRight="0rem"
                fontSize="0.8rem"
                onChangeCallback={(e) => {
                  if (e.checked) {
                    setFilterCheck((cur) => {
                      return { ...cur, class_done: true };
                    });
                    setSearchParam((cur) => {
                      return { ...cur, is_ot_class_done: true, is_pt_class_done: true };
                    });
                  } else {
                    setFilterCheck((cur) => {
                      return { ...cur, class_done: false };
                    });
                    setSearchParam((cur) => {
                      return { ...cur, is_ot_class_done: false, is_pt_class_done: false };
                    });
                  }
                }}
              />
              <div style={{ display: "flex", gap: "0.4rem" }}>
                <ColorCheckbox
                  labelText="OT"
                  bgColor="#6433CE"
                  fontSize="0.8rem"
                  fontColor="#FFFFFF"
                  defaultValue={searchParam.is_ot_class_done}
                  onChangeCallback={(e) => {
                    setSearchParam((cur) => {
                      return { ...cur, is_ot_class_done: e.checked };
                    });
                  }}
                />
                <ColorCheckbox
                  labelText="PT"
                  bgColor="#DC35B7"
                  fontSize="0.8rem"
                  fontColor="#FFFFFF"
                  defaultValue={searchParam.is_pt_class_done}
                  onChangeCallback={(e) => {
                    setSearchParam((cur) => {
                      return { ...cur, is_pt_class_done: e.checked };
                    });
                  }}
                />
              </div>
            </div>
            <div
              style={{
                marginTop: "0.7rem",
                display: "flex",
                gap: "0.4rem",
              }}
            >
              <ColorCheckbox
                fontSize="0.8rem"
                padding="0.4rem 1.2rem 0.4rem 0.5rem"
                width="4.1rem"
                labelWidth="2.8rem"
                labelText="개인업무"
                fontColor="#FFFFFF"
                bgColor="#333232"
                defaultValue={searchParam.is_personal_task}
                onChangeCallback={(e) => {
                  setSearchParam((cur) => {
                    return { ...cur, is_personal_task: e.checked };
                  });
                }}
              />
              <ColorCheckbox
                fontSize="0.8rem"
                labelText="휴무"
                bgColor="#538600"
                fontColor="#FFFFFF"
                defaultValue={searchParam.is_day_off}
                onChangeCallback={(e) => {
                  setSearchParam((cur) => {
                    return { ...cur, is_day_off: e.checked };
                  });
                }}
              />
              <ColorCheckbox
                labelText="전달사항"
                padding="0.4rem 1.2rem 0.4rem 0.5rem"
                width="4.1rem"
                bgColor="#E1243A"
                fontSize="0.8rem"
                labelWidth="2.8rem"
                fontColor="#FFFFFF"
                defaultValue={searchParam.is_notice}
                onChangeCallback={(e) => {
                  setSearchParam((cur) => {
                    return { ...cur, is_notice: e.checked };
                  });
                }}
              />
            </div>
            <div
              style={{
                marginTop: "2.5rem",
                display: "flex",
                gap: "0.5rem",
                justifyContent: "center",
              }}
            >
              <Button
                className={clsx({
                  [btnStyleClass.btnRootRaw]: true,
                  [btnStyleClass.buttonType4]: true,
                })}
                onClick={validationCheck}
              >
                검색
              </Button>
              {/* <Button
                className={clsx({
                  [btnStyleClass.btnRootWhite]: true,
                  [btnStyleClass.buttonType4]: true,
                })}
                onClick={() => {
                  
                }}
              >
                서파
              </Button> */}
            </div>
          </div>
        }
        isLoading={false}
        rootStyle={{
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />
    </div>
  );
};

export default ScheduleSearchCard;
