import { simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import ModalBtnModeGroup from "components/ModalBtnModeGroup/ModalBtnModeGroup";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useState } from "react";
import { useDispatch } from "react-redux";

const HQPolicyPayDayEdit = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const labelWidth = "7.625rem";

  const [loading, setLoading] = useState(false);

  const onClickSave = () => {
    if (
      !modalParam.salaryInfoStore.default_salary_date.wage ||
      !modalParam.salaryInfoStore.default_salary_date.incentive ||
      !modalParam.salaryInfoStore.default_salary_date.pt_fee ||
      !modalParam.salaryInfoStore.default_salary_date.gx_fee ||
      !modalParam.salaryInfoStore.default_salary_date.etc_fee
    ) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="모든 값이 입력되어야 합니다." />
            </>
          ),
          // width: "50rem",
          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="저장하시겠습니까?" />
            </>
          ),
          // width: "50rem",
          onBtnYes: () => {
            onSaveEvent();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const onSaveEvent = async () => {
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    // console.log(JSON.stringify(modalParam.salaryInfoStore));
    const formData = new FormData();
    formData.append("info_data", JSON.stringify(modalParam.salaryInfoStore));
    formData.append("hq_info", JSON.stringify(modalParam.hq_info));

    setLoading(true);

    await HttpInstance.post(
      process.env.REACT_APP_BRANCH_SERVER + "/hq_set/default_salary/update",
      formData,
      config,
    )
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          onModalDone(modalParam.refreshStore);
        }
      })
      .catch((err) => {
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
        // onModalDone(modalParam.refreshStore);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        width: "21rem",
      }}
    >
      {loading && <Loading />}

      <ModalBtnModeGroup mode={"edit"} onClickEvent={onClickSave} />

      <div style={{ marginTop: "1rem" }}>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={"11rem"}
          labelText="지점명"
          defaultValue={modalParam.salaryInfoStore.branch_info.branch_name}
          disabled
        />
      </div>

      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={"5.75rem"}
          labelText="기본급"
          isNumber
          minValue={1}
          maxValue={28}
          endAdornment="일"
          defaultValue={modalParam.salaryInfoStore.default_salary_date.wage}
          onChangeCallback={(e) => {
            modalParam.salaryInfoStore.default_salary_date.wage = e;
          }}
          // disabled
        />
      </div>

      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={"5.75rem"}
          labelText="회원권 인센티브"
          isNumber
          minValue={1}
          maxValue={28}
          endAdornment="일"
          defaultValue={modalParam.salaryInfoStore.default_salary_date.incentive}
          onChangeCallback={(e) => {
            modalParam.salaryInfoStore.default_salary_date.incentive = e;
          }}
          // disabled
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={"5.75rem"}
          labelText="PT 수당"
          isNumber
          minValue={1}
          maxValue={28}
          endAdornment="일"
          defaultValue={modalParam.salaryInfoStore.default_salary_date.pt_fee}
          onChangeCallback={(e) => {
            modalParam.salaryInfoStore.default_salary_date.pt_fee = e;
          }}
          // disabled
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={"5.75rem"}
          labelText="GX 수당"
          isNumber
          minValue={1}
          maxValue={28}
          endAdornment="일"
          defaultValue={modalParam.salaryInfoStore.default_salary_date.gx_fee}
          onChangeCallback={(e) => {
            modalParam.salaryInfoStore.default_salary_date.gx_fee = e;
          }}
          // disabled
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={"5.75rem"}
          labelText="기타 수당"
          isNumber
          minValue={1}
          maxValue={28}
          endAdornment="일"
          defaultValue={modalParam.salaryInfoStore.default_salary_date.etc_fee}
          onChangeCallback={(e) => {
            modalParam.salaryInfoStore.default_salary_date.etc_fee = e;
          }}
          // disabled
        />
      </div>

      <Label
        labelText="※ 급여일은 1일부터 28일까지만 설정 가능합니다."
        color="#e25147"
        fontSize="0.9rem"
        marginTop="1.5rem"
      />
    </div>
  );
};
export default HQPolicyPayDayEdit;
