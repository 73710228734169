import failIcon from "assets/icon/check_fail.png";
import successIcon from "assets/icon/check_success.png";
import { contentTypeJson, simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const CheckEqualView = ({ isEqual }) => {
  if (isEqual) {
    return (
      <img
        alt="O"
        src={successIcon}
        style={{
          display: "flex",
          width: "1.4rem",
          height: "1.4rem",
        }}
      />
    );
  } else {
    return (
      <img
        alt="O"
        src={failIcon}
        style={{
          display: "flex",
          width: "1.4rem",
          height: "1.4rem",
        }}
      />
    );
  }
};
const PasswordChangeModal = ({ modalParam, onModalDone }) => {
  // console.log("비밀번호변경 : ", modalParam);
  const storesDispatch = useDispatch();
  // const [selectNum, setSelectNum] = useState();
  const [loading, setLoading] = useState(false);

  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [isEqual, setIsEqual] = useState(false);

  const labelWidth = "8rem";
  const compWidth = "11rem";

  const checkEqual = () => {
    if (pass1 === "") {
      setIsEqual(false);
      return;
    }
    if (pass1 === pass2) {
      setIsEqual(true);
    } else {
      setIsEqual(false);
    }
  };
  useEffect(() => {
    checkEqual();
  }, [pass1, pass2]);

  const changePassword = async () => {
    try {
      const formData = new FormData();

      formData.append("staff_id", modalParam.staff_id); //기존꺼
      formData.append("new_passwd", pass1);
      formData.append("old_passwd", modalParam.auth_pass); //기존꺼

      const info = {
        staff_id: modalParam.staff_id,
        new_passwd: pass1,
        old_passwd: modalParam.auth_pass,
      };
      formData.append("new_info", JSON.stringify(info));

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_STAFF_SERVER + "/staff/update/passwd",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: <Label labelText="변경이 완료되었습니다." />,
            onBtnOk: () => {
              onModalDone(); //모달 다 닫고 + 회원정보 전체 갱신
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ width: "22rem" }}>
      {loading && <Loading />}

      <div style={{ display: "flex", alignItems: "center" }}>
        <TextField
          required
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          labelText="· 신규 비밀번호"
          onBlur={(e) => {
            // checkAuthId(e.target.value);
          }}
          onChangeCallback={(e) => {
            setPass1(e);
          }}
          password
        />
        <CheckEqualView isEqual={isEqual} />
      </div>
      <div style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
        <TextField
          required
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          labelText="· 신규 비밀번호 확인"
          onBlur={(e) => {
            // checkAuthId(e.target.value);
          }}
          onChangeCallback={(e) => {
            setPass2(e);
          }}
          password
        />
        <CheckEqualView isEqual={isEqual} />
      </div>
      <div
        style={{
          marginTop: "2rem",
          marginBottom: "0.5rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <NormalBtn
          name="확인"
          onClick={() => {
            changePassword();
          }}
        />
      </div>
    </div>
  );
};

export default PasswordChangeModal;
