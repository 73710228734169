import { approvalInfoInitState } from "./approval_info_init_state";

import * as ActionTypes from "../actions/action_type";

export const approval_info = (state = approvalInfoInitState, action) => {
  switch (action.type) {
    case ActionTypes.approvalInfoViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.approvalInfoViewFailAction:
    case ActionTypes.approvalInfoViewResetAction:
      return { ...state, stores: approvalInfoInitState.stores };
    default:
      return state;
  }
};
