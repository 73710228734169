import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, getPermission } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const SelectorTable = ({
  staffSearchResult,
  modalParam,
  onModalDone,
  approverList,
  receiverList,
  viewerList,
  setApproverList,
  setReceiverList,
  setViewerList,
  curPage,
  totalPage,
  onClickSearch,
}) => {
  const btnStyleClass = btnStyles();

  const [selectedState, setSelectedState] = useState([]);
  const [selctedIdx, setSelctedIdx] = useState(-1);
  const [canAccessPersonalInfo, setCanAccessPersonalInfo] = useState(false);
  const storesDispatch = useDispatch();

  const checkPermission = async () => {
    const result = await getPermission(storesDispatch, "ACCOUNT_PERMISSION/SECRET");
    setCanAccessPersonalInfo(result);
  };
  useEffect(() => {
    checkPermission();
  }, []);

  const initSelectState = () => {
    let openList = [];
    for (let i = 0; i < staffSearchResult.length; i++) {
      openList.push(false);
    }
    return openList;
  };

  const onRowSelect = (idx, val) => {
    let openList = initSelectState();
    openList[idx] = val;

    setSelectedState(openList);
    if (val === false) {
      idx = -1;
    }
    setSelctedIdx(idx);
  };

  const searchResultList = new searchResultTable(selectedState, onRowSelect);

  const selectStaffInfo = (type, workInfo) => {
    if (type === "approver") {
      // approver는 필드 2개 추가
      return {
        approve_date: "",
        is_approve: false,
        staff_info: {
          staff_id: workInfo.staff_id,
          staff_name: workInfo.personal_info.staff_name,
          staff_pos: workInfo.base_info.position.org_name,
          staff_rank: workInfo.base_info.rank.org_name,
        },
      };
    }
    return {
      staff_info: {
        staff_id: workInfo.staff_id,
        staff_name: workInfo.personal_info.staff_name,
        staff_pos: workInfo.base_info.position.org_name,
        staff_rank: workInfo.base_info.rank.org_name,
      },
    };
  };

  const dulplicatedAlert = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: <Label labelText="이미 추가되어 있습니다." />,
        onBtnOk: () => {},
      }),
    );
  };

  const onAddApprover = () => {
    if (selctedIdx !== -1) {
      const fIdx = approverList.findIndex(
        (x) => x.staff_info.staff_id === staffSearchResult[selctedIdx].staff_id,
      );

      if (fIdx === -1) {
        setApproverList([
          ...approverList,
          selectStaffInfo("approver", staffSearchResult[selctedIdx]),
        ]);
      } else {
        dulplicatedAlert();
      }
    }
  };

  const onAddViewer = () => {
    if (selctedIdx !== -1) {
      const fIdx = viewerList.findIndex(
        (x) => x.staff_info.staff_id === staffSearchResult[selctedIdx].staff_id,
      );

      if (fIdx === -1) {
        setViewerList([...viewerList, selectStaffInfo("viewer", staffSearchResult[selctedIdx])]);
      } else {
        dulplicatedAlert();
      }
    }
  };

  const onAddReceiver = () => {
    if (selctedIdx !== -1) {
      const fIdx = receiverList.findIndex(
        (x) => x.staff_info.staff_id === staffSearchResult[selctedIdx].staff_id,
      );
      if (fIdx === -1) {
        setReceiverList([
          ...receiverList,
          selectStaffInfo("receiver", staffSearchResult[selctedIdx]),
        ]);
      } else {
        dulplicatedAlert();
      }
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "80%" }}>
        <div style={{ height: "17rem", overflow: "auto", border: "1px solid #ccc" }}>
          <CustomTable
            columns_head={searchResultList.columns_head}
            table_title={searchResultList.table_title}
            rest_call={searchResultList.get_data_from_rest}
            row_render={searchResultList.create_table}
            rest_data={staffSearchResult}
          />
        </div>
        <CustomPagination
          curPage={curPage}
          totalCnt={totalPage}
          doSearch={(e) => {
            onClickSearch(e);
          }}
        />
      </div>

      <div style={{ width: "20%", marginTop: "3rem", marginLeft: "1.25rem" }}>
        <Button
          className={clsx({
            [btnStyleClass.btnRootWhite]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={() => onAddApprover()}
        >
          결재 →
        </Button>

        <Button
          className={clsx({
            [btnStyleClass.btnRootWhite]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={() => onAddViewer()}
          style={{ marginTop: "0.5rem" }}
        >
          열람 →
        </Button>

        <Button
          className={clsx({
            [btnStyleClass.btnRootWhite]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={() => onAddReceiver()}
          style={{ marginTop: "0.5rem" }}
        >
          수신 →
        </Button>
      </div>
    </div>
  );
};
export default SelectorTable;

class searchResultTable {
  table_title = "";
  selectedState = [];
  onRowSelect = null;

  constructor(selectedState, onRowSelect) {
    this.selectedState = selectedState;
    this.onRowSelect = onRowSelect;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow
        style={{
          width: "100%",
        }}
        selected={this.selectedState[idx]}
        key={idx}
        onClick={() => {
          this.onRowSelect(idx, !this.selectedState[idx]);
        }}
      >
        <CustomTableContents>{data.personal_info.staff_name}</CustomTableContents>
        <CustomTableContents>{data.base_info.position.org_name}</CustomTableContents>
        <CustomTableContentsEND align="center">{data.personal_info.phone}</CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "직원명", field: "staff_name", width: "30%" },
    { title: "직책", field: "position", width: "30%" },
    { title: "연락처", field: "phone", width: "40%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
