import CardFixed from "components/Card/CardFixed";
import { forwardRef } from "react";
import HqInfoCard from "./HqInfoCard";

const HqBranchView = forwardRef((props, ref) => {
  return (
    <CardFixed
      topDom={<div>소속정보</div>}
      midDom={
        <div>
          <HqInfoCard
            isViewMode={props.isViewMode}
            staffInfoState={props.staffInfoState}
            onClickOpenModal={props.onClickOpenModal}
            hqPermissions={props.hqPermissions}
          />
          {/* <BranchAccessCard
            staffInfoState={props.staffInfoState}
            onClickOpenModal={props.onClickOpenModal}
          /> */}
        </div>
      }
      isLoading={false}
      rootStyle={{
        // width: "20rem",
        // height: "15.312rem",
        marginTop: "0.625rem",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
});
export default HqBranchView;
