export const firewallState = {
  alias: "",
  ip_addr: "",
  reg_info: {
    created_at: "",
    reg_id: "",
    reg_name: "",
    reg_position: "",
    reg_rank: "",
    reg_sabun: "",
    upd_id: "",
    upd_name: "",
    upd_position: "",
    upd_rank: "",
    upd_sabun: "",
    updated_at: "",
  },
};
