import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { getNowServiceState } from "pages/NoticeBoard/model/board_row";
import ReferenceWrapper from "./ReferenceWrapper";

const ReferenceService = () => {
  const nowService = "자료실";
  return (
    <div>
      <PageFrame
        children={
          <div>
            <PageTitle titleText="자료실" />
            <ReferenceWrapper nowService={getNowServiceState(nowService)} />
          </div>
        }
      />
    </div>
  );
};

export default ReferenceService;
