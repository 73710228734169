// 전체 폰트사이즈 설정
export const setInitialFontSize = () => {
  const calculateFontSize = () => {
    const width = window.screen.width - 16;

    let fontSize;
    if (width <= 768) {
      fontSize = "0.4rem";
    } else if (width <= 992) {
      fontSize = "0.51rem";
    } else if (width <= 1200) {
      fontSize = "0.625rem";
    } else if (width <= 1400) {
      fontSize = "0.7291rem";
    } else if (width <= 1500) {
      fontSize = "0.78125rem";
    } else if (width <= 1600) {
      fontSize = "0.833333rem";
    } else if (width <= 1700) {
      fontSize = "0.89583rem";
    } else if (width <= 1800) {
      fontSize = "0.947916rem";
    } else if (width <= 1920) {
      fontSize = "1rem";
    } else if (width <= 2048) {
      fontSize = "1.066666666666667rem";
    } else if (width <= 2148) {
      fontSize = "1.11875rem";
    } else if (width <= 2248) {
      fontSize = "1.170833333333333rem";
    } else if (width <= 2348) {
      fontSize = "1.222916666666667rem";
    } else if (width <= 2448) {
      fontSize = "1.275rem";
    } else if (width <= 2560) {
      fontSize = "1.33333rem";
    } else if (width <= 2760) {
      fontSize = "1.4375rem";
    } else if (width <= 2960) {
      fontSize = "1.541666666666667rem";
    } else if (width <= 3200) {
      fontSize = "1.66666rem";
    } else if (width <= 3840) {
      fontSize = "2rem";
    } else {
      fontSize = "2rem";
    }

    document.documentElement.style.fontSize = fontSize;
  };

  calculateFontSize();

  // resize 이벤트 리스너 (모니터 화면 변경 시 체크)
  window.addEventListener("resize", calculateFontSize);

  return () => {
    window.removeEventListener("resize", calculateFontSize);
  };
};
