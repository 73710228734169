import IconButton from "components/Button/IconButton";
import { dataEmpty, getPermission, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import Loading from "components/Loading/Loading/Loading";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import { hq_branch_search_init_state } from "pages/CommonModal/Search/Module/reducers/hq_branch_search_init_state";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { schedulePreSetInitState } from "../Module/reducers/init_state";
import ScheduleEditModal from "./ScheduleEditModal";

//스케줄 목록 모달
const ScheduleListModal = ({ onModalDone }) => {
  const storesDispatch = useDispatch();
  const viewModelRef = useRef();
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });

  const [hqIdx, setHqIdx] = useState(0);
  const [selectedBranch, setSelectedBranch] = useState({ branch_id: "", branch_name: "" });

  const [branchScheduleList, setBranchScheduleList] = useState([]);
  const [hqBranchData, setHqBranchData] = useState(_.cloneDeep(hq_branch_search_init_state));
  const [loading, setLoading] = useState(false);

  const getHqBranchCombo = async () => {
    const result = await getPermission(storesDispatch, "ACCESS_MENU/MANAGE", true);
    setHqBranchData(result);
  };

  //지점별 스케줄리스트 GET
  const getBranchScheduleInfo = async (branchId) => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_BRANCH_SERVER + "/access/device_schedule_set_view",
        {
          params: {
            branch_id: branchId,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setBranchScheduleList(res.data.data.schedule_list);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  //스케줄 row삭제 MSG
  const onDeleteRow = (data) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="스케줄을 삭제하시겠습니까?" />
          </>
        ),

        onBtnYes: () => {
          deleteSchedule(data);
        },
        onBtnNo: () => {},
      }),
    );
  };

  //스케줄삭제 POST
  const deleteSchedule = async (data) => {
    try {
      setLoading(true);

      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const formData = new FormData();
      const info = {
        hq_info: hqBranchData.target_list[hqIdx].hq_info,
        branch_info: selectedBranch,
        schedule_info: data,
      };
      formData.append("param_info", JSON.stringify(info));

      const res = await HttpInstance.post(
        process.env.REACT_APP_BRANCH_SERVER + "/access/schedule_preset_remove",
        formData,
        config,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label labelText="삭제 되었습니다." />
              </>
            ),
            onBtnOk: () => {
              //스케줄 리스트 갱신
              getBranchScheduleInfo(selectedBranch.branch_id);
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  //스케줄추가/수정모달 POP (모드:'add' & 'edit')
  const popAddScheduleModal = (mode, data) => {
    const param = {
      mode: mode,
      hq_info: hqBranchData.target_list[hqIdx].hq_info,
      branch_info: selectedBranch,
      itemData: data,
    };

    setModalInfo({
      show: true,
      title: "스케줄 관리",
      Content: <ScheduleEditModal modalParam={param} onModalDone={addScheduleModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  //스케줄추가/수정 모달 DONE
  const addScheduleModalDone = (branchId) => {
    getBranchScheduleInfo(branchId); // 지점 스케줄 리스트 갱신
    onModalDone(); //메인 스케줄테이블 갱신
    viewModelRef.current.setClose(); //추가모달 닫기
  };

  //row클릭 -> 스케줄수정모달 POP
  const onClickRow = (mode, data) => {
    if (!selectedBranch.branch_id) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="지점을 선택해주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else {
      popAddScheduleModal(mode, data);
    }
  };

  //첫 랜더링
  useEffect(() => {
    getHqBranchCombo();
  }, []);

  // 처음 진입, 본부 변경 시 첫번째 지점 선택
  useEffect(() => {
    const branch = hqBranchData.target_list[hqIdx].branch_list[0];
    setSelectedBranch(branch);
    getBranchScheduleInfo(branch.branch_id); //지점바뀌면 스케줄정보 다시 GET
  }, [hqBranchData, hqIdx]);

  const scheduleInfoTableItem = new scheduleInfoTable(onClickRow, onDeleteRow);

  return (
    <div
      style={{
        width: "45rem",
        height: "60rem",
      }}
    >
      {loading && <Loading />}

      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex" }}>
          <ComboBox
            labelWidth={"3.25rem"}
            comboItemWidth={"12rem"}
            labelText="본부"
            defaultValue={hqBranchData.hq_list[0]}
            data={hqBranchData.hq_list}
            onChangeCallback={(e) => {
              const hIdx = hqBranchData.hq_list.findIndex((el) => el === e.target.value);
              if (hIdx !== -1) {
                setHqIdx(hIdx);
                //지점 초기화
                setSelectedBranch({ branch_id: "", branch_name: "" });
                setBranchScheduleList([]);
              }
            }}
          />
          <SearchComboBox
            marginLeft={"2rem"}
            labelWidth={"3.25rem"}
            comboItemWidth={"12rem"}
            labelText="지점"
            defaultValue={selectedBranch.branch_name}
            data={hqBranchData.branch_list[hqIdx]}
            onChangeCallback={(e, v, idx) => {
              const branch = hqBranchData.target_list[hqIdx].branch_list[idx];
              setSelectedBranch(branch);
              getBranchScheduleInfo(branch.branch_id); //지점바뀌면 스케줄정보 다시 GET
            }}
          />
        </div>
        <div
          style={{
            float: "right",
            display: "flex",
            justifyContent: "end",
            width: "100%",
          }}
        >
          <NormalBtn
            name="추가"
            onClick={() => onClickRow("add", _.cloneDeep(schedulePreSetInitState))}
          />
        </div>
      </div>

      <div style={{ marginTop: "0.7rem" }}>
        <CustomTable
          columns_head={scheduleInfoTableItem.columns_head}
          table_title={scheduleInfoTableItem.table_title}
          rest_call={scheduleInfoTableItem.get_data_from_rest}
          row_render={scheduleInfoTableItem.create_table}
          rest_data={branchScheduleList}
        />
      </div>
    </div>
  );
};
export default ScheduleListModal;

class scheduleInfoTable {
  table_title = "";
  onClickRow = null;
  onDeleteRow = null;

  constructor(onClickRow, onDeleteRow) {
    this.onClickRow = onClickRow;
    this.onDeleteRow = onDeleteRow;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.schedule_name}</CustomTableContents>
        <CustomTableContents>{data.descr}</CustomTableContents>
        <CustomTableContents>
          <div style={{ display: "flex", justifyContent: "center", gap: "0.9rem" }}>
            <IconButton type="edit" onClick={() => this.onClickRow("edit", data)} />
            <IconButton
              type="delete"
              onClick={() => {
                this.onDeleteRow(data);
              }}
            />
          </div>
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "스케줄명", width: "35%" },
    { title: "설명", width: "50%" },
    { title: "", width: "15%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
