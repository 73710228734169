import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { tabBorder, tabStyle } from "components/CommonComp";
import TabPanel from "components/TabControl/TabPanel";
import React from "react";
import SwipeableViews from "react-swipeable-views";
import CurrentWorkInfoModal from "./CurrentWorkInfoModal";
import WorkInfoChangeHist from "./WorkInfoChangeHist";

const WorkInfoModal = ({ modalParam, onModalDone }) => {
  const textWorkInfoTab = modalParam.pageMode !== "추가" ? "현 근로정보" : "근로정보";
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          TabIndicatorProps={{
            style: { backgroundColor: "rgba(0, 0, 0, 0)" },
          }}
        >
          <Tab label={textWorkInfoTab} style={tabStyle(0, value)} />
          {modalParam.pageMode !== "추가" ? (
            <Tab label="변경 이력" style={tabStyle(1, value)} />
          ) : (
            <></>
          )}
        </Tabs>
      </Box>
      <SwipeableViews
        index={value}
        onChangeIndex={handleChangeIndex}
        style={tabBorder}
        animateTransitions={false}
      >
        <TabPanel value={value} index={0}>
          <CurrentWorkInfoModal modalParam={modalParam} onModalDone={onModalDone} />
        </TabPanel>
        {modalParam.pageMode !== "추가" ? (
          <TabPanel value={value} index={1}>
            <WorkInfoChangeHist modalParam={modalParam} />
          </TabPanel>
        ) : (
          <></>
        )}
      </SwipeableViews>
    </div>
  );
};
export default WorkInfoModal;
