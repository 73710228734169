import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import StaffListWrapper from "./StaffListWrapper";

const StaffList = () => {
  return (
    <PageFrame
      children={
        <div>
          <PageTitle titleText="직원관리" />
          <StaffListWrapper />
        </div>
      }
    />
  );
};
export default StaffList;
