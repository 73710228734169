import { useQuery } from "@tanstack/react-query";
import {
  getAccessHistList,
  getAccessMonitorSearchParam,
  getDoorHistList,
} from "apis/branch/access_monitor_api";
import { accessMonitorKeys } from "apis/branch/query_keys_branch";
import { simpleAlert } from "components/CommonLib/CommonLib";
import { useDispatch } from "react-redux";

// [GET] 본부, 지점, 도어 Combo 가져오기
export const useAccessMonitorSearchParam = () => {
  const storesDispatch = useDispatch();

  return useQuery({
    queryKey: accessMonitorKeys.searchParam(),
    queryFn: () => getAccessMonitorSearchParam(),
    enabled: true,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });
};

// [GET] 출입 이력 리스트 가져오기
export const useAccessHistList = (queryData, enabled, setEnabled, setLoading) => {
  const storesDispatch = useDispatch();

  return useQuery({
    queryKey: accessMonitorKeys.accessHist(queryData),
    queryFn: () => getAccessHistList(queryData),
    enabled: enabled,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setEnabled(false);
      setLoading(false);
    },
  });
};

// [GET] 문열림 사용 이력 리스트 가져오기
export const useDoorHistList = (queryData, enabled, setEnabled, setLoading) => {
  const storesDispatch = useDispatch();

  return useQuery({
    queryKey: accessMonitorKeys.doorHist(queryData),
    queryFn: () => getDoorHistList(queryData),
    enabled: enabled,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setEnabled(false);
      setLoading(false);
    },
  });
};
