import { contentTypeJson, dataEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import FileUpload from "components/LabelInput/FileUpload";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { utils, writeFile } from "xlsx";

const AddCardByUploadModal = ({ onModalDone }) => {
  const storesDispatch = useDispatch();

  const [cardUploadFiles, setCardUploadFiles] = useState();
  const [inspectResult, setInspectResult] = useState([]);
  const [loading, setLoading] = useState(false);

  const onClickUpload = () => {
    simpleAlert(storesDispatch, "알림", "업로드를 진행하시겠습니까?", false, undefined, () => {
      doUploadFile();
    });
  };

  const doUploadFile = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("card_csv", cardUploadFiles);

      const res = await HttpInstance.post(
        process.env.REACT_APP_BRANCH_SERVER + "/access/card_csv/add",
        formData,
        contentTypeJson,
      );

      const all_list = res.data.data.all_list;
      const overlapped_list = res.data.data.overlapped_list;

      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        if (!overlapped_list) {
          simpleAlert(
            storesDispatch,
            "알림",
            "중복된 번호가 없습니다.\n\n모두 정상적으로 저장 되었습니다.",
            false,
            onModalDone(),
          );
        } else {
          //중복된 결과(overlapped_list)를 테이블에 보여주자
          simpleAlert(
            storesDispatch,
            "알림",
            "카드가 추가되지 않았습니다.\n\n중복된 카드번호가 있습니다.",
          );
          setInspectResult({
            ...inspectResult,
            all_list,
            overlapped_list,
          });
        }
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const onUploadFile = (name, file) => {
    //name은 DocumentItem에서 fileName으로 넘겨준거임(여기서는 card_upload)
    setCardUploadFiles(file);
  };

  //엑셀파일 만들기
  const onClickExcelDown = (data) => {
    const newData = [];
    for (let i = 0; i < data.length; i++) {
      const row = {};
      row.지점 = data[i].branch_name;
      row.카드번호 = data[i].card_no;
      row.시리얼번호 = data[i].card_serial_no;
      row.중복여부 = data[i].overlapped;
      row.비고 = data[i].remarks;
      row.사용자그룹 = data[i].user_group;
      newData.push(row);
    }

    const worksheet = utils.json_to_sheet(newData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, "카드목록.csv");
  };

  const searchResultList = new searchResultTable();

  return (
    <div
      style={{
        width: "30rem",
      }}
    >
      {loading ? <Loading /> : <></>}

      <div
        style={{ display: "flex", justifyContent: "", marginLeft: "1rem", marginBottom: "1.5rem" }}
      >
        <FileUpload
          labelWidth={"8rem"}
          textBoxWidth={"15rem"}
          textMarginRight={"0rem"}
          labelText={"엑셀 파일 업로드"}
          defaultValue={cardUploadFiles && cardUploadFiles.name}
          onUploadFile={(file) => {
            onUploadFile("card_upload", file);
          }}
          hideDownload
        />
      </div>

      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={inspectResult.overlapped_list}
      />

      <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem", gap: "0.3rem" }}>
        {cardUploadFiles && !inspectResult.overlapped_list ? (
          <>
            <NormalBtn name="확인" onClick={() => onClickUpload()} />
            <NormalBtn
              name="취소"
              onClick={() => {
                onModalDone();
              }}
            />
          </>
        ) : (
          <></>
        )}

        {!inspectResult.overlapped_list || inspectResult.overlapped_list.length === 0 ? (
          ""
        ) : (
          <NormalBtn
            name="엑셀 다운로드"
            theme="green"
            onClick={() => {
              onClickExcelDown(inspectResult.all_list);
            }}
          />
        )}
      </div>
    </div>
  );
};
export default AddCardByUploadModal;

class searchResultTable {
  table_title = "";
  onNavigate = null;

  constructor(navigate) {
    this.onNavigate = navigate;
  }

  onClickEvent = (data) => {
    this.onNavigate("/access_card_info/", {
      state: {
        card_info_id: data.card_info_id,
        is_view: true,
      },
    });
  };

  create_table = (data, idx) => {
    return (
      <CustomRow style={{ width: "100%" }} key={idx}>
        <CustomTableContents width="9%">{data.card_no}</CustomTableContents>
        <CustomTableContents width="8%">{data.card_serial_no}</CustomTableContents>
        <CustomTableContents width="8%">{data.overlapped}</CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "카드번호", field: "" },
    { title: "시리얼번호", field: "" },
    { title: "비고", field: "" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
