export const getSearchMemberAction = "SEARCH/GET/MEMBER";
export const getSearchMemberSuccessAction = "SEARCH/GET/MEMBER/SUCCESS";
export const getSearchMemberFailAction = "SEARCH/GET/MEMBER/FAIL";
export const getSearchMemberResetAction = "SEARCH/GET/MEMBER/RESET";

export const getSearchStaffAction = "SEARCH/GET/STAFF";
export const getSearchStaffSuccessAction = "SEARCH/GET/STAFF/SUCCESS";
export const getSearchStaffFailAction = "SEARCH/GET/STAFF/FAIL";
export const getSearchStaffResetAction = "SEARCH/GET/STAFF/RESET";

export const getSearchMemberParamAction = "SEARCH/GET/MEMBER_PARAM";
export const getSearchMemberParamSuccessAction = "SEARCH/GET/MEMBER_PARAM/SUCCESS";
export const getSearchMemberParamFailAction = "SEARCH/GET/MEMBER_PARAM/FAIL";
export const getSearchMemberParamResetAction = "SEARCH/GET/MEMBER_PARAM/RESET";
