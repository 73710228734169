import IconButton from "components/Button/IconButton";
import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import TextArea from "components/LabelInput/TextArea";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const SmsSentHistModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const [smsHistList, setSmsHistList] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSmsHistData(1);
  }, []);

  const getSmsHistData = async (page) => {
    setCurPage(page);
    setLoading(true);

    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_USER_SERVER + "/member/contract/sms/hist",
        {
          params: {
            member_id: modalParam.memberId,
            cur_page: page,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setSmsHistList(res.data.data);
        setTotalPage(res.data.total_page);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  const openMsgModal = (msg) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "발송 메시지 내용",
        bodyText: (
          <>
            <TextArea
              marginTop="0.3rem"
              labelWidth="0rem"
              labelMarginLeft="0rem"
              labelMarginRight="0rem"
              minRows={10}
              maxRows={16}
              textBoxWidth={"30rem"}
              defaultValue={msg}
            />
          </>
        ),
        onBtnOk: () => {},
      }),
    );
  };

  const onClickResend = async (data) => {
    setLoading(true);

    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_USER_SERVER + "/member/contract/sms/resend",
        {
          params: {
            member_id: data.member_id,
            hist_id: data.hist_id,
          },
        },
      );
      if (!res.data.msg) {
        simpleAlert(storesDispatch, "알림", "문자 메시지 재발송이 완료되었습니다.");
      } else {
        simpleAlert(storesDispatch, "알림", "문자 메시지 재발송에 실패했습니다. : ", res.data.msg);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  const smsHistTableInstance = new smsHistTable(openMsgModal, onClickResend);

  return (
    <div style={{ width: "70rem" }}>
      <CustomTable
        columns_head={smsHistTableInstance.columns_head}
        table_title={smsHistTableInstance.table_title}
        rest_call={smsHistTableInstance.get_data_from_rest}
        row_render={smsHistTableInstance.create_table}
        rest_data={smsHistList}
      />
      <CustomPagination curPage={curPage} doSearch={getSmsHistData} totalCnt={totalPage} />
    </div>
  );
};
export default SmsSentHistModal;

// Table
class smsHistTable {
  table_title = "";
  openMsgModal = null;
  onClickResend = null;

  constructor(openMsgModal, onClickResend) {
    this.openMsgModal = openMsgModal;
    this.onClickResend = onClickResend;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.create_date}</CustomTableContents>
        <CustomTableContents>{data.senderNum}</CustomTableContents>
        <CustomTableContents>{data.receiver}</CustomTableContents>
        <CustomTableContents>{data.sms_result}</CustomTableContents>
        <CustomTableContents>
          <IconButton
            type="detail"
            onClick={() => {
              this.openMsgModal(data.msg);
            }}
          />
        </CustomTableContents>
        <CustomTableContents>
          <NormalBtn
            name="재발송"
            onClick={() => {
              this.onClickResend(data);
            }}
          />
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "발송일시", width: "20%" },
    { title: "발신번호", width: "15%" },
    { title: "수신번호", width: "15%" },
    { title: "발송결과", width: "12%" },
    { title: "내용확인", width: "10%" },
    { title: "재발송", width: "15%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
