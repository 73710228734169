import border from "assets/icon/Border.svg";
import CardFixed from "components/Card/CardFixed";
import {
  contentTypeJson,
  dataEmpty,
  getPermission,
  restEmpty,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import SalaryInfoTabView from "./SalaryInfoTabView";

const SalaryInfoView = ({
  navigateToSalaryMain,
  onClickOpenModal,
  salaryReportId,
  is_edit,
  is_approval,
  hasPermission,
}) => {
  const storesDispatch = useDispatch();
  const [summaryData, setSummaryData] = useState();

  useEffect(() => {
    getSalaryInfoData({ salaryReportId, is_edit });
  }, [salaryReportId]);

  const getSalaryInfoData = (param) => {
    HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + "/salary/global_summary", {
      params: {
        salary_report_id: param.salaryReportId,
        is_edit: param.is_edit,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setSummaryData(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  if (summaryData) {
    if (is_approval) {
      return (
        <div style={{ margin: "0 1rem" }}>
          <SalarySummary summaryData={summaryData} is_approval={is_approval} />
          <SalaryInfoTabView
            summaryData={summaryData}
            onClickOpenModal={onClickOpenModal}
            is_edit={is_edit}
            salaryReportId={salaryReportId}
          />
        </div>
      );
      //
    } else {
      // !is_approval
      return (
        <div>
          <CardFixed
            midDom={
              <div>
                <SearchCardRow
                  navigateToSalaryMain={navigateToSalaryMain}
                  salaryReportId={salaryReportId}
                  summaryData={summaryData}
                  onClickOpenModal={onClickOpenModal}
                  is_edit={is_edit}
                  hasPermission={hasPermission}
                />
                <SalarySummary summaryData={summaryData} is_approval={is_approval} />
                <SalaryInfoTabView
                  summaryData={summaryData}
                  onClickOpenModal={onClickOpenModal}
                  is_edit={is_edit}
                  salaryReportId={salaryReportId}
                />
              </div>
            }
            isLoading={false}
            rootStyle={{
              marginTop: "0.625rem",
              borderRadius: "10px",
              backgroundColor: "#fff",
            }}
          />
        </div>
      );
    }
  }
};
export default SalaryInfoView;

//
// 검색 카드
//
const SearchCardRow = ({
  navigateToSalaryMain,
  summaryData,
  onClickOpenModal,
  salaryReportId,
  is_edit,
  hasPermission,
}) => {
  const storesDispatch = useDispatch();
  const labelWidth = "4.5rem";
  const textBoxWidth = "12rem";
  const [loading, setLoading] = useState(false);

  // 승인
  const onClickApprove = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: <Label labelText="승인 하시겠습니까?" />,
        onBtnYes: () => {
          approveDraft();
        },
        onBtnNo: () => {},
      }),
    );
  };

  // 기안
  const onClickDraft = async () => {
    // 전자결재 권한 먼저 체크.
    const approvalPerm = await getPermission(
      storesDispatch,
      "APPROVAL_MENU/MANAGE",
      true,
      false,
      true,
      true,
    );
    if (!approvalPerm || approvalPerm.branch_list.length === 0) {
      simpleAlert(
        storesDispatch,
        "알림",
        "전자결재 권한이 없습니다.\n관리자에게 문의해 주시기 바랍니다.",
      );
      return;
    }

    const param = {
      title: "결재선 지정",
      modalParam: {
        salaryReportId,
        navigateToSalaryMain,
      },
    };
    onClickOpenModal("결재선지정", param);
  };

  // 사용하지 않음
  const onClickRetrieve = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: <Label labelText="회수 하시겠습니까?" />,
        onBtnYes: () => {
          retrieveDraft();
        },
        onBtnNo: () => {},
      }),
    );
  };

  // 사용하지 않음
  const retrieveDraft = async () => {
    try {
      setLoading(true);

      const res = await HttpInstance.get(
        process.env.REACT_APP_SALARY_SERVER + "/salary/approval/recall",
        {
          params: {
            salary_report_id: salaryReportId,
            doc_id: summaryData.approval_permission.doc_info.doc_id,
          },
        },
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: <Label labelText="회수 되었습니다." />,
            onBtnOk: () => {
              navigateToSalaryMain();
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const approveDraft = async () => {
    try {
      const formData = new FormData();
      formData.append("salary_report_id", salaryReportId);
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_SALARY_SERVER + "/salary/approval/draft",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: <Label labelText="승인 되었습니다." />,
            onBtnOk: () => {
              navigateToSalaryMain();
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const showApprovalBtn = () => {
    if (hasPermission) {
      // 승인 권한이 있을때
      switch (summaryData.approval_status) {
        case "대기":
          return <NormalBtn name="승인" onClick={onClickApprove} />;
        case "임시저장":
          return <NormalBtn name="승인" onClick={onClickApprove} />;
        case "회수":
          return <NormalBtn name="승인" onClick={onClickApprove} />;
        case "반려":
          return <NormalBtn name="승인" onClick={onClickApprove} />;
        case "승인대기":
          return <></>;
        case "승인":
          return <></>;
        default:
          return <></>;
      }
    } else {
      // 승인 권한이 없을때
      switch (summaryData.approval_status) {
        case "대기":
          return <NormalBtn name="기안" onClick={onClickDraft} />;
        case "임시저장":
          return <NormalBtn name="기안" onClick={onClickDraft} />;
        case "회수":
          return <NormalBtn name="기안" onClick={onClickDraft} />;
        case "반려":
          return <NormalBtn name="기안" onClick={onClickDraft} />;
        case "승인대기":
          return <NormalBtn name="회수" onClick={onClickRetrieve} />; // 사용하지 않음.
        case "승인":
          return <></>;
        default:
          return <></>;
      }
    }

    // {summaryData.approval_status !== "승인대기" && (
    //   <NormalBtn name={hasPermission ? "승인" : "기안"} onClick={onSelectApprovalLine} />
    // )}
    // {summaryData.approval_status === "승인대기" && !hasPermission && (
    //   <NormalBtn name="회수" theme="white" onClick={onClickEvt} />
    // )}
  };

  // onClickDeleteAndCreate, DoDeleteAndCreate 함수는 개발용 임시!!

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    getAuthData();
  }, []);

  const getAuthData = async () => {
    await HttpInstance.get(process.env.REACT_APP_AUTH_SERVER + "/global/perm_auth", {})
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setIsAdmin(res.data.data.is_admin);
        }
      })
      .catch((err) => {
        console.log("err");
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const navigate = useNavigate();
  const onClickDeleteAndCreate = () => {
    simpleAlert(
      storesDispatch,
      "알림",
      "[" +
        summaryData.branch_info.branch_name +
        "] 지점의 [" +
        summaryData.base_date +
        "] 급여를 삭제 후 재생성 하시겠습니까?",
      false,
      undefined,
      () => {
        DoDeleteAndCreate();
      },
    );
  };

  const DoDeleteAndCreate = async () => {
    try {
      setLoading(true);

      const res = await HttpInstance.delete(
        process.env.REACT_APP_SALARY_SERVER + "/salary/" + salaryReportId,
        contentTypeJson,
      );

      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공

        await HttpInstance.post(
          process.env.REACT_APP_SALARY_SERVER + "/salary/manage_list/create",
          {
            base_date: summaryData.base_date,
            branch_info: summaryData.branch_info,
            hq_info: summaryData.hq_info,
            is_salary_basic: true,
            is_salary_mbshp: true,
            is_salary_pt: true,
            is_salary_gx: true,
            is_salary_etc: true,
          },
          contentTypeJson,
        )
          .then((res) => {
            if (res.data.msg) {
              simpleAlert(storesDispatch, "ERROR", res.data.msg);
            } else {
              simpleAlert(
                storesDispatch,
                "알림",
                "급여 리포트 생성이 완료되었습니다. 메인 화면으로 이동합니다.",
                true,
                () => {
                  navigate("/salary_list/");
                },
              );
            }
          })
          .catch((err) => {
            console.log(err);
            simpleAlert(
              storesDispatch,
              "ERROR",
              err.response.data ? err.response.data.msg : err.code,
            );
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {loading && <Loading />}

      <div style={{ display: "flex" }}>
        <TextField
          labelMarginLeft="0.5rem"
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          labelText="기준연월"
          disabled
          defaultValue={summaryData.base_date}
        />
        <TextField
          marginLeft="2rem"
          labelWidth={"4rem"}
          textBoxWidth={textBoxWidth}
          labelText="지점"
          defaultValue={summaryData.branch_info.branch_name}
          disabled
        />
        <TextField
          marginLeft="2rem"
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          labelText="결재 상태"
          defaultValue={summaryData.approval_status}
          disabled
        />
      </div>

      <div style={{ display: "flex" }}>
        {isAdmin ? (
          <NormalBtn
            name="문서 삭제 후 재생성"
            theme="red"
            onClick={() => onClickDeleteAndCreate()}
            margin={"0 1rem 0 0"}
          />
        ) : (
          <></>
        )}

        {is_edit ? (
          <></>
        ) : (
          <div
            style={{
              display: "flex",
              float: "right",
              marginLeft: "auto",
            }}
          >
            {showApprovalBtn()}
          </div>
        )}
      </div>
    </div>
  );
};

// Summary
const SalarySummary = ({ summaryData, is_approval }) => {
  const branchCurrentTableItem = new branchCurrentTable();
  const salaryCurrentTableItem = new salaryCurrentTable();

  return (
    <div style={{ marginTop: "1rem" }}>
      <div style={{ marginLeft: "0.5rem" }}>
        <SubTitle titleText="지점 요약" fontSize="1.2rem" />
      </div>

      <div
        style={{
          display: is_approval ? "block" : "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: is_approval ? "100%" : "40%" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              alt="border"
              src={border}
              style={{
                width: "1.2rem",
                height: "1.2rem",
              }}
            />
            <SubTitle titleText="지점 현황" />
          </div>

          <div style={{ margin: "0 1px" }}>
            <CustomTable
              columns_head={branchCurrentTableItem.columns_head}
              table_title={branchCurrentTableItem.table_title}
              rest_call={branchCurrentTableItem.get_data_from_rest}
              row_render={branchCurrentTableItem.create_table}
              rest_data={summaryData.branch_summary_info}
            />
          </div>
        </div>
        <div style={{ width: is_approval ? "100%" : "57%" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              alt="border"
              src={border}
              style={{
                width: "1.2rem",
                height: "1.2rem",
              }}
            />
            <SubTitle titleText="급여 현황" />
          </div>

          <div style={{ margin: "0 1px" }}>
            <CustomTable
              columns_head={salaryCurrentTableItem.columns_head}
              table_title={salaryCurrentTableItem.table_title}
              rest_call={salaryCurrentTableItem.get_data_from_rest}
              row_render={salaryCurrentTableItem.create_table}
              rest_data={summaryData.salary_summary_info}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

class salaryCurrentTable {
  table_title = "";

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents $isNumber>
          {data.base_salary_total.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.mbshp_total_salary.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.pt_total_commission.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.pt_total_class.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.gx_total_class.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.etc_total_salary.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContentsEND $isNumber align="center">
          {data.salary_total.toLocaleString() + " 원"}
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "기본급 합계", width: "14%" },
    { title: "회원권 인센티브\n합계", width: "14%" },
    { title: "PT 매출 커미션\n합계", width: "14%" },
    { title: "PT 수업커미션\n합계", width: "14%" },
    { title: "GX 수당 합계", width: "14%" },
    { title: "기타 수당 합계", width: "14%" },
    { title: "급여 합계", width: "14%" },
  ];
  get_data_from_rest = (stateData) => {
    // console.log(stateData);
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return [stateData];
  };
}

class branchCurrentTable {
  table_title = "";

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents $isNumber>
          {data.mbshp_incentive_cnt.toLocaleString() + " 건"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.gx_use_cnt.toLocaleString() + " 명"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.mbshp_total_sales.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.pt_total_sales.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContentsEND $isNumber align="center">
          {/* {data.sales_total.toLocaleString()} */}
          {(data.mbshp_total_sales + data.pt_total_sales).toLocaleString() + " 원"}
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "회원권 인센티브\n지급 건수", width: "21%" },
    { title: "GX 수강 회원 수", width: "20%" },
    { title: "회원권 매출", width: "20%" },
    { title: "PT 매출", width: "20%" },
    { title: "매출 합계", width: "19%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return [stateData];
  };
}
