import { generateObjectZeroId } from "components/CommonLib/CommonLib";
import { create } from "zustand";

// 검색 조건 베이스
const baseSearchParams = {
  base_info: {
    phone: "",
  },
  branch_info: {
    branch_id: generateObjectZeroId(),
    branch_name: "",
  },
  combo_item: {
    branch_info_list: [
      {
        branch_id: generateObjectZeroId(),
        branch_name: "",
      },
    ],
    branch_list: [""],
  },
  member_info: {
    member_id: generateObjectZeroId(),
    member_name: "",
    member_no: "",
  },
  pay_req_date_from: "",
  pay_req_date_to: "",
  payment_state: "",
  tm_from_date: "",
  tm_manager: {
    sabun: "",
    staff_id: generateObjectZeroId(),
    staff_name: "",
    staff_pos: "",
    staff_rank: "",
  },
  tm_status: "",
  tm_to_date: "",
};

export const useSubsManageStore = create((set) => ({
  // ========== State ===========
  // 정기결제 관리 검색 State
  subsManageSearchParams: {
    ...baseSearchParams,
  },
  subsManagePage: 1,

  // ========== Action ==========
  // 정기결제 관리 검색 조건 변경
  setSearchParams: (key, value) => {
    set((state) => ({
      subsManageSearchParams: {
        ...state.subsManageSearchParams,
        [key]: value,
      },
    }));
  },

  // Page 값 변경
  setPage: (value) => {
    set({ subsManagePage: value });
  },

  // 검색 조건 리셋
  resetSearchParams: () => {
    set({
      subsManageSearchParams: {
        ...baseSearchParams,
      },
    });
  },
}));
