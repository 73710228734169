import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import EditBasicCommissionModal from "./EditBasicCommissionModal";
import LoadCommissionModal from "./LoadCommissionModal";

const SelectCommissionModeModal = ({ modalParam }) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();
  const viewModelRef = useRef();

  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });
  // console.log(modalParam);
  const popLoadCommissionSettingModal = (title, param, localModalDone) => {
    setModalInfo({
      show: true,
      title: title,
      Content: <LoadCommissionModal modalParam={param} onModalDone={localModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const popEditBasicCommissionModal = (title, param, localModalDone) => {
    setModalInfo({
      show: true,
      title: title,
      Content: <EditBasicCommissionModal modalParam={param} onModalDone={localModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  return (
    <div>
      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
      <div style={{ display: "flex", justifyContent: "center", gap: "0.7rem" }}>
        <Button
          className={clsx({
            [btnStyleClass.btnRootRaw]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={() => {
            popEditBasicCommissionModal("커미션 수정", {
              ptCommission: modalParam.ptCommission,
              ptFee: modalParam.ptFee,
              pt_preset: modalParam.pt_preset,
              staffId: modalParam.staffId,
              salaryReportId: modalParam.salaryReportId,
              staffSalaryId: modalParam.staffSalaryId,
            });
          }}
        >
          직접 수정
        </Button>
        <Button
          className={clsx({
            [btnStyleClass.btnRootGrayWhite]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={() => {
            popLoadCommissionSettingModal("커미션 설정 불러오기", {
              onClickOpenModal: modalParam.onClickOpenModal,
              staffId: modalParam.staffId,
              salaryReportId: modalParam.salaryReportId,
              branchId: modalParam.branchId,
              staffSalaryId: modalParam.staffSalaryId,
              lastWorkInfoId: modalParam.lastWorkInfoId,
            });
          }}
        >
          불러오기
        </Button>
      </div>
    </div>
  );
};

export default SelectCommissionModeModal;
