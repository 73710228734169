import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CheckBox from "components/LabelInput/CheckBox";
import Label from "components/LabelInput/Label";
import TextArea from "components/LabelInput/TextArea";
import TextField from "components/LabelInput/TextField";
import { globalAlertOn } from "modules/actions/Alert";
import { newDeleteRowAPi } from "pages/NoticeBoard/restApi/deleteApi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const ReferenceDetailContent = ({ postRowState, nowService }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const navigate = useNavigate();

  //게시물삭제 MSG
  const alertDeleteMsg = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="삭제 하시겠습니까?" />
          </>
        ),

        onBtnYes: () => {
          deletePost(postRowState);
        },
        onBtnNo: () => {},
      }),
    );
  };
  //게시물삭제
  const deletePost = async (rowData) => {
    newDeleteRowAPi(navigate, storesDispatch, rowData);
  };
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <TextField
          labelText="본부"
          labelWidth="3.7rem"
          textBoxWidth="11rem"
          defaultValue={postRowState.hq_info.hq_name}
          disabled
        />
        <TextField
          labelText="작성일시"
          labelWidth="4rem"
          textBoxWidth="11rem"
          disabled
          defaultValue={postRowState.reg_info.created_at}
        />
        <div style={{ display: "flex", gap: "0.3rem" }}>
          <TextField
            labelText="작성자"
            labelWidth="4rem"
            textBoxWidth="11rem"
            disabled
            defaultValue={postRowState.post_info.writer.staff_name}
          />
          <IconButton
            type="editBorder"
            onClick={() => {
              navigate(`/reference/update/${postRowState.post_id}`);
            }}
          />
          <IconButton type="deleteBox" onClick={alertDeleteMsg} />
        </div>
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <TextField
          labelText="제목"
          labelWidth="3.7rem"
          textBoxWidth="56rem"
          defaultValue={postRowState.post_info.title}
          disabled
        />
        <CheckBox
          labelWidth="4rem"
          labelText="중요"
          transform="scale(1.1)"
          marginTop="0rem"
          marginRight="0rem"
          defaultValue={postRowState.post_info.is_important}
          disabled
        />
      </div>
      <div style={{ marginTop: "0.5rem" }}>
        <TextArea
          labelText="내용"
          labelWidth="3.96rem"
          fullWidth
          minRows="20"
          maxRows="20"
          defaultValue={postRowState.post_info.contents}
          disabled
        />
      </div>

      <div style={{ display: "flex", justifyContent: "center", marginTop: "0.7rem" }}>
        <Button
          className={clsx({
            [btnStyleClass.btnRootGrayWhite]: true,
            [btnStyleClass.buttonType6]: true,
          })}
          onClick={() => {
            navigate("/reference");
          }}
        >
          목록 보기
        </Button>
      </div>
    </div>
  );
};

export default ReferenceDetailContent;
