import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { board_search_param } from "../model/board_row";
import { newDeleteRowAPi } from "../restApi/deleteApi";
import { searchBoardAPi } from "../restApi/getApi";
// const DUMM = [
//   {
//     no: 0,
//     notice_id: "100",
//     created_at: "2023-01-05",
//     writer: "김철수",
//     title: "설 연휴 운영 공지",
//     opinion: 0,
//     file: 1,
//   },
//   {
//     no: 1,
//     notice_id: "101",
//     created_at: "2023-01-06",
//     writer: "김철수",
//     title: "설 연휴 운영 공지1",
//     opinion: 10,
//     file: 0,
//   },
//   {
//     no: 2,
//     notice_id: "102",
//     created_at: "2023-01-07",
//     writer: "김철수",
//     title: "설 연휴 운영 공지2",
//     opinion: 5,
//     file: 5,
//   },
// ];

const NoticeView = ({ nowService }) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();
  const [curPage, setCurPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [searchParam, setSearchParam] = useState(_.cloneDeep(board_search_param));
  const [searchResult, setSearchResult] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    console.log("use affect.. ");
    searchBoardAPi(storesDispatch, searchParam, nowService, setSearchResult, setTotalPage, curPage);
  }, []);
  //테이블 ROW 클릭 함수
  const onRowClick = (data, mode) => {
    if (mode === "view") {
      navigate(`/notice/${data.post_id}`);
    } else if (mode === "edit") {
      navigate(`/notice/update/${data.post_id}`);
    } else {
      //mode === 'delete'
      alertDeleteMsg(data);
    }
  };

  //게시물삭제 MSG
  const alertDeleteMsg = (data) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="게시물을 삭제하시겠습니까?" />
          </>
        ),
        onBtnYes: () => {
          //삭제요청 API
          deleteNotice(data);
        },
        onBtnNo: () => {},
      }),
    );
  };

  //게시물삭제
  const deleteNotice = async (rowData) => {
    newDeleteRowAPi(navigate, storesDispatch, rowData);
  };

  //검색 POST
  const onClickSearch = async (page) => {
    setCurPage(page);
  };

  //페이지네이션
  useEffect(() => {
    if (curPage) {
      onClickSearch(curPage);
    }
  }, [curPage]);

  const noticeListData = new noticeListTable(onRowClick);
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}>
          <ComboBox labelWidth={"2.5rem"} comboItemWidth={"12rem"} labelText="본부" data={["1"]} />

          <div style={{ display: "flex", marginLeft: "2.5rem" }}>
            <DatePicker labelWidth={"2.5rem"} minWidth={"12rem"} labelText="작성일" />
            <DatePicker labelWidth={"0.5rem"} minWidth={"12rem"} labelText="~" />
          </div>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            labelWidth={"2.5rem"}
            textBoxWidth={"43rem"}
            labelText="제목"
            placeholder="입력"
          />

          <Button
            className={clsx({
              [btnStyleClass.btnRootWhite]: true,
              [btnStyleClass.buttonType4]: true,
            })}
            onClick={() => {
              onClickSearch(1);
            }}
          >
            검색
          </Button>
        </div>
      </div>

      <div style={{ marginTop: "0.7rem" }}>
        <CustomTable
          columns_head={noticeListData.columns_head}
          table_title={noticeListData.table_title}
          rest_call={noticeListData.get_data_from_rest}
          row_render={noticeListData.create_table}
          rest_data={searchResult}
        />
        <CustomPagination curPage={curPage} doSearch={setCurPage} totalCnt={totalPage} />
      </div>
      <div style={{ marginTop: "0.7rem", display: "flex", justifyContent: "flex-end" }}>
        <Button
          className={clsx({
            [btnStyleClass.btnRootRaw]: true,
            [btnStyleClass.buttonType5]: true,
          })}
          onClick={() => {
            navigate("/notice/create");
          }}
        >
          글 쓰기
        </Button>
      </div>
    </div>
  );
};

export default NoticeView;

class noticeListTable {
  table_title = "";
  onRowClick = null;
  constructor(onRowClick) {
    this.onRowClick = onRowClick;
  }

  create_table = (data, key) => {
    // console.log(data);
    return (
      <CustomRow hover key={key}>
        <CustomTableContents
          align="center"
          onClick={() => {
            this.onRowClick(data, "view");
          }}
        >
          {data.post_info.no}
        </CustomTableContents>
        <CustomTableContents
          align="center"
          onClick={() => {
            this.onRowClick(data, "view");
          }}
        >
          {data.reg_info.created_at}
        </CustomTableContents>
        <CustomTableContents
          align="center"
          onClick={() => {
            this.onRowClick(data, "view");
          }}
        >
          {data.post_info.writer.staff_name}
        </CustomTableContents>
        <CustomTableContents
          align="center"
          onClick={() => {
            this.onRowClick(data, "view");
          }}
        >
          {data.post_info.title}
        </CustomTableContents>
        <CustomTableContents
          align="center"
          onClick={() => {
            this.onRowClick(data, "view");
          }}
        >
          {data.post_info.comment_cnt}
        </CustomTableContents>
        <CustomTableContents
          align="center"
          onClick={() => {
            this.onRowClick(data, "view");
          }}
        >
          {data.post_info.file_cnt}
        </CustomTableContents>
        <CustomTableContents>
          <div style={{ display: "flex", justifyContent: "center", gap: "0.7rem" }}>
            <IconButton
              type="edit"
              width="2.2rem"
              onClick={() => {
                this.onRowClick(data, "edit");
              }}
            />
            <IconButton
              type="delete"
              width="2.2rem"
              onClick={() => {
                this.onRowClick(data, "delete");
              }}
            />
          </div>
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "순번", width: "8%" },
    { title: "작성일", width: "10%" },
    { title: "작성자", width: "10%" },
    { title: "제목", width: "46%" },
    { title: "의견수", width: "8%" },
    { title: "첨부", width: "8%" },
    { title: "", width: "10%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
