import { getPermission } from "components/CommonLib/CommonLib";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ClassHistContentsView from "./ClassHistContentsView";

const ClassHistWrapper = () => {
  const storesDispatch = useDispatch();
  const [branchInfo, setBranchInfo] = useState({ branch_list: [], branch_name_list: [] });
  const getBranchCombo = async () => {
    const result = await getPermission(storesDispatch, "CLASS_MENU/CLASS_MANAGE", true, true, true);
    setBranchInfo(result);
  };

  //초기 지점콤보 GET
  useEffect(() => {
    getBranchCombo();
  }, []);

  return <ClassHistContentsView branchInfo={branchInfo} />;
};
export default ClassHistWrapper;
