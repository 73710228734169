import IconButton from "components/Button/IconButton";
import { dataEmpty, getPermission, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import CheckBox from "components/LabelInput/CheckBox";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const CardSummaryInfo = ({ cardStatus }) => {
  const labelWidth = "3rem";
  const textBoxWidth = "10rem";
  const marginLeft = "2.5rem";

  return (
    <div>
      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="카드 현황" />
      </div>

      <div
        style={{
          width: "64%",
          display: "flex",
          border: "1px solid #ccc",
          padding: "1rem 2rem",
        }}
      >
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          labelText="전체"
          endAdornment="개"
          isNumber
          disabled
          defaultValue={cardStatus.all ? cardStatus.all : "0"}
        />
        <TextField
          marginLeft={marginLeft}
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          labelText="사용"
          endAdornment="개"
          isNumber
          disabled
          defaultValue={cardStatus.used ? cardStatus.used : "0"}
        />
        <TextField
          marginLeft={marginLeft}
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          labelText="정지"
          endAdornment="개"
          isNumber
          disabled
          defaultValue={cardStatus.suspended ? cardStatus.suspended : "0"}
        />
        <TextField
          marginLeft={marginLeft}
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          labelText="대기"
          endAdornment="개"
          isNumber
          disabled
          defaultValue={cardStatus.wait ? cardStatus.wait : "0"}
        />
      </div>
    </div>
  );
};

const ActionItemGroup = ({
  onCardAddModal,
  onCardAddOneModal,
  searchResult,
  onCardStatusStopModal,
  selectedCard,
  onClickSearch,
}) => {
  const storesDispatch = useDispatch();
  const [isAllReady, setIsAllReady] = useState(false);
  const [canAddCard, setCanAddCard] = useState(false);
  const [canExcel, setCanExcel] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const allReadyStatus = searchResult.every((el) => el.card_status === "대기");
    if (allReadyStatus) {
      setIsAllReady(true);
    } else {
      setIsAllReady(false);
    }
  }, [searchResult]);

  const deleteSelectedItems = async () => {
    setLoading(true);

    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    const formData = new FormData();
    const cardNumList = selectedCard.map((el) => el.card_no);
    formData.append("card_no_list", JSON.stringify(cardNumList));

    await HttpInstance.post(
      process.env.REACT_APP_BRANCH_SERVER + "/access/card_bulk_delete",
      formData,
      config,
    )
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          storesDispatch(
            globalAlertOn({
              show: true,
              titleText: "알림",
              reqConfirm: true,
              bodyText: (
                <>
                  <Label labelText="삭제 되었습니다." />
                </>
              ),
              onBtnOk: () => {
                onClickSearch(1, false, true); //서파로 결과 재요청
              },
            }),
          );
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onDeleteEvt = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="삭제 하시겠습니까?" />
          </>
        ),
        onBtnYes: () => {
          deleteSelectedItems();
        },
        onBtnNo: () => {},
      }),
    );
  };
  const checkPermission = async () => {
    const result = await getPermission(storesDispatch, "ACCESS_MENU/CARD_MANAGE");
    const resultExcel = await getPermission(storesDispatch, "ACCOUNT_PERMISSION/EXCEL");
    setCanExcel(resultExcel);
    setCanAddCard(result);
  };
  useEffect(() => {
    checkPermission();
  }, []);

  return (
    <div style={{ display: "flex", marginTop: "1rem", marginBottom: "0.25rem" }}>
      {loading ? <Loading /> : <></>}

      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="카드 목록" />
      </div>

      <div style={{ display: "flex", marginLeft: "auto", gap: "0.3rem" }}>
        {canAddCard && (
          <>
            {searchResult.length === 0 || !isAllReady ? (
              ""
            ) : (
              <>
                <NormalBtn name="일괄수정" theme="white" onClick={() => onCardStatusStopModal()} />
                <NormalBtn name="일괄삭제" theme="white" onClick={() => onDeleteEvt()} />
              </>
            )}
            <NormalBtn name="개별추가" onClick={() => onCardAddOneModal()} />
            <NormalBtn name="카드추가" onClick={() => onCardAddModal()} />
          </>
        )}
        {canExcel && (
          <NormalBtn
            name="엑셀 다운로드"
            theme="green"
            onClick={() => {
              onClickSearch(1, true);
            }}
          />
        )}
      </div>
    </div>
  );
};

const CardSearchResult = ({
  navigate,
  searchResult,
  onClickOpenModal,
  cardStatus,
  curPage,
  setCurPage,
  onClickSearch,
  totalPage,
  selectedCard,
}) => {
  const storesDispatch = useDispatch();
  const [branchList, setBranchList] = useState({
    branch_list: [],
    branch_name_list: [],
  });

  //지점콤보 GET
  const getBranchCombo = async () => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_BRANCH_SERVER + "/access/device/branch_selector",
        {
          params: {},
        },
      );
      if (!restEmpty(res.data.data)) {
        //성공
        setBranchList(res.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };
  //첫 랜더링- -> 지점콤보 받아오기(모달 내려주기)
  useEffect(() => {
    getBranchCombo();
  }, []);

  const onCardAddModal = () => {
    const param = {
      title: "카드추가",
      modalParam: {
        branchList: branchList,
      },
    };
    onClickOpenModal("카드추가", param, onClickSearch);
  };

  const onCardStatusStopModal = () => {
    if (selectedCard.length === 0) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="수정할 항목을 1개이상 선택해 주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else {
      const param = {
        title: "일괄수정",
        modalParam: {
          branchList: branchList,
          checkedList: selectedCard,
        },
      };
      onClickOpenModal("일괄수정", param, onClickSearch);
    }
  };

  const onCardAddOneModal = () => {
    const param = {
      title: "개별추가",
      modalParam: {
        branchList: branchList,
      },
    };
    onClickOpenModal("개별추가", param);
  };

  const onCheckRow = (isChecked, data) => {
    const fIdx = searchResult.findIndex((el) => el.card_info_id === data.card_info_id);

    if (isChecked) {
      selectedCard.push(data);
    } else {
      const idx = selectedCard.findIndex((x) => x.card_info_id === data.card_info_id);
      if (idx !== -1) selectedCard.splice(idx, 1);
    }

    if (fIdx !== -1) {
      searchResult[fIdx].is_checked = isChecked;
    }
  };
  const searchResultList = new searchResultTable(navigate, onCheckRow);

  return (
    <div>
      <CardSummaryInfo cardStatus={cardStatus} />
      <ActionItemGroup
        onCardAddModal={onCardAddModal}
        onCardAddOneModal={onCardAddOneModal}
        onCardStatusStopModal={onCardStatusStopModal}
        onClickSearch={onClickSearch}
        searchResult={searchResult}
        selectedCard={selectedCard}
      />

      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
      />
      <CustomPagination curPage={curPage} doSearch={setCurPage} totalCnt={totalPage} />
    </div>
  );
};
export default CardSearchResult;

class searchResultTable {
  table_title = "";
  onNavigate = null;
  onCheckRow = null;

  constructor(navigate, onCheckRow) {
    this.onNavigate = navigate;
    this.onCheckRow = onCheckRow;
  }

  onClickEvent = (data) => {
    this.onNavigate("/access_card_info/", {
      state: {
        card_info_id: data.card_info_id,
        is_view: true,
      },
    });
  };

  create_table = (data, idx) => {
    return (
      <CustomRow
        key={idx}
        onClick={(e) => {
          if (!e.target.type) {
            this.onClickEvent(data);
          }
        }}
        hover
      >
        <CustomTableContents>
          <CheckBox
            isTable
            defaultValue={data.is_checked ? true : false}
            onChangeCallback={(e) => {
              this.onCheckRow(e.checked, data);
            }}
          />
        </CustomTableContents>
        <CustomTableContents>{data.cur_reg_date}</CustomTableContents>
        <CustomTableContents>{data.card_status}</CustomTableContents>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.card_serial_no}</CustomTableContents>
        <CustomTableContents>{data.card_no}</CustomTableContents>
        <CustomTableContents>{data.user_group}</CustomTableContents>
        <CustomTableContents>{data.cur_user.user_name}</CustomTableContents>
        <CustomTableContents>{data.cur_user.member_no}</CustomTableContents>
        <CustomTableContents>{data.cur_user.sabun}</CustomTableContents>
        <CustomTableContentsEND align="center">{data.remarks}</CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "선택", width: "5%" },
    { title: "카드 등록일", width: "12%" },
    { title: "상태", width: "9%" },
    { title: "지점", width: "9%" },
    { title: "시리얼번호", width: "11%" },
    { title: "카드번호", width: "9%" },
    { title: "사용자그룹", width: "9%" },
    { title: "사용자명", width: "9%" },
    { title: "회원번호", width: "9%" },
    { title: "사번", width: "9%" },
    { title: "비고", width: "9%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
