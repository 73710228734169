import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { listEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import PaymentMethod, { paymentMethodTable } from "../PaymentModal/PaymentMethod";
import { pay_method_state } from "./payment_B_init";

const PaymentModalB = ({ modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();

  const [paymentList, setPaymentList] = useState(_.cloneDeep(modalParam.payMethodList));
  const [unpaid, setUnpaid] = useState([]);
  const [openListState, setOpenListState] = useState([]);

  const [, updateState] = useState(); // 강제 렌더링용
  const forceUpdate = useCallback(() => updateState({}), []);

  // console.log(modalParam);

  const initOpenState = () => {
    const openList = [];
    for (let i = 0; i < modalParam.payDataList.length; i++) {
      openList.push(false);
    }
    return openList;
  };

  useEffect(() => {
    const openList = initOpenState();
    if (openList.length > 0) openList[0] = true;
    setOpenListState(openList);

    for (let i = 0; i < paymentList.length; i++) {
      if (paymentList[i].pay_method.length === 0) {
        paymentList[i].unpaid_info.unpaid_price = modalParam.payDataList[i].discount_return
          ? modalParam.payDataList[i].repaid_price + modalParam.payDataList[i].discount_return
          : modalParam.payDataList[i].repaid_price;
        onAddMethod(i);
      }
    }

    calcTotalPaid();
  }, [modalParam.payDataList]);

  const validation = () => {
    // 각 칸마다 결제방법 1개 이상 있어야 함
  };

  const selectOpen = (idx, val) => {
    let openList = initOpenState();
    openList[idx] = val;
    setOpenListState(openList);
  };

  const onAddMethod = (idx) => {
    const methodId = uuidv4();
    const baseMethod = _.cloneDeep(pay_method_state);
    baseMethod.id = methodId;

    modalParam.payMethodList[idx].pay_method.push(baseMethod);
    setPaymentList(modalParam.payMethodList);
    forceUpdate();
  };

  const onDeleteMethod = (__, methodId) => {
    for (let i = 0; i < modalParam.payMethodList.length; i++) {
      const idx = modalParam.payMethodList[i].pay_method.findIndex((x) => x.id === methodId);
      if (idx !== -1) {
        modalParam.payMethodList[i].pay_method.splice(idx, 1);
        calcTotalPaid();
        setPaymentList(modalParam.payMethodList);
        break;
      }
    }
    forceUpdate();
  };

  const calcTotalPaid = () => {
    for (let i = 0; i < modalParam.payMethodList.length; i++) {
      let totalPaid = 0;
      for (let j = 0; j < modalParam.payMethodList[i].pay_method.length; j++) {
        totalPaid += Number(modalParam.payMethodList[i].pay_method[j].price);
      }

      if (modalParam.mode === "change") {
        // 상품변경일 때
        modalParam.payMethodList[i].total_paid = totalPaid;
        modalParam.payMethodList[i].unpaid_info.unpaid_price =
          modalParam.payMethodList[i].total_price - totalPaid;
      } else {
        // 환불일 때
        const totalPriceOrigin = modalParam.payDataList[i].discount_return
          ? modalParam.payDataList[i].repaid_price + modalParam.payDataList[i].discount_return
          : modalParam.payDataList[i].repaid_price;
        modalParam.payMethodList[i].total_paid = totalPaid;
        modalParam.payMethodList[i].unpaid_info.unpaid_price = totalPriceOrigin - totalPaid;
      }
    }
  };

  const onChangePrice = (__, method, val, id) => {
    for (let i = 0; i < modalParam.payMethodList.length; i++) {
      const idx = modalParam.payMethodList[i].pay_method.findIndex((x) => x.id === id);
      if (idx !== -1) {
        // update price
        modalParam.payMethodList[i].pay_method[idx].price = val;

        // update total_paid & unpaid_price
        calcTotalPaid();

        setPaymentList(modalParam.payMethodList);
        break;
      }
    }
    forceUpdate();
  };

  // 결제수단에서 값을 바꿀 때 업데이트 시키는 함수
  const onChangePayment = (__, id, val) => {
    for (let i = 0; i < modalParam.payMethodList.length; i++) {
      const idx = modalParam.payMethodList[i].pay_method.findIndex((x) => x.id === id);
      if (idx !== -1) {
        // update price
        modalParam.payMethodList[i].pay_method[idx].pay_method = val;
        calcTotalPaid();
        setPaymentList(modalParam.payMethodList);
        break;
      }
    }
    forceUpdate();
  };

  const btnApply = () => {
    let validationRes = "";
    for (let i = 0; i < paymentList.length; i++) {
      if (paymentList[i].unpaid_info.unpaid_price < 0) {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: <Label labelText="[CS] 총 결제금액은 상품금액보다 클 수 없습니다." />,
            onBtnOk: () => {},
          }),
        );
        return;
      } else if (paymentList[i].unpaid_info.unpaid_price > 0) {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: <Label labelText="미납금을 모두 결제해야 합니다." />,
            onBtnOk: () => {},
          }),
        );
        return;
      }

      for (let j = 0; j < paymentList[i].pay_method.length; j++) {
        if (paymentList[i].purchase_code === "상품변경 추가금") {
          continue;
        }
        if (paymentList[i].pay_method[j].pay_method === "카드") {
          if (!paymentList[i].pay_method[j].price) {
            validationRes += "· [" + paymentList[i].purchase_code + "] 결제금액(카드)\n";
          }
          if (paymentList[i].pay_method[j].approve_no.length < 8) {
            validationRes += "· [" + paymentList[i].purchase_code + "] 승인번호\n";
          }
          if (paymentList[i].pay_method[j].card_no.length < 8) {
            validationRes += "· [" + paymentList[i].purchase_code + "] 카드번호 앞 8자리\n";
          }
          if (!paymentList[i].pay_method[j].buyer) {
            validationRes += "· [" + paymentList[i].purchase_code + "] 매입사\n";
          }
        } else if (paymentList[i].pay_method[j].pay_method === "현금") {
          if (!paymentList[i].pay_method[j].price) {
            validationRes += "· [" + paymentList[i].purchase_code + "] 결제금액(현금)\n";
          }
        } else if (paymentList[i].pay_method[j].pay_method === "이체") {
          if (!paymentList[i].pay_method[j].account_name) {
            validationRes += "· [" + paymentList[i].purchase_code + "] 예금주(이체)\n";
          }
          if (!paymentList[i].pay_method[j].price) {
            validationRes += "· [" + paymentList[i].purchase_code + "] 결제금액(이체)\n";
          }
        } else if (paymentList[i].pay_method[j].pay_method === "기타") {
          if (!paymentList[i].pay_method[j].account_name) {
            validationRes += "· [" + paymentList[i].purchase_code + "] 예금주(기타)\n";
          }
          if (!paymentList[i].pay_method[j].price) {
            validationRes += "· [" + paymentList[i].purchase_code + "] 결제금액(기타)\n";
          }
        }

        if (paymentList[i].pay_method[j].pay_date.length < 11) {
          validationRes += "· [" + paymentList[i].purchase_code + "] 승인시각\n";
        }
      }
    }

    if (validationRes !== "") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="필수값을 모두 입력해주셔야 합니다." />
              <br />
              <br />
              <Label labelText={validationRes} whiteSpace="pre-wrap" />
            </>
          ),
          // width: "50rem",
          onBtnOk: () => {},
        }),
      );
    } else {
      onModalDone("결제", paymentList);
    }
  };

  // console.log(paymentList);
  // console.log(modalParam.payDataList);

  const paymentTableData = new paymentTable(
    openListState,
    selectOpen,
    onChangePayment,
    modalParam.regDate,
    paymentList,
    onAddMethod,
    onDeleteMethod,
    onChangePrice,
    modalParam.payDataList,
  );

  //
  return (
    <div
      style={{
        width: "84rem",
        display: "block",
      }}
    >
      <SubTitle titleText={"결제 목록"} />

      <div>
        <CustomTable
          columns_head={paymentTableData.columns_head}
          rest_call={paymentTableData.get_data_from_rest}
          row_render={paymentTableData.create_table}
          isMargin={false}
          rest_data={paymentList}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1.844rem",
            gap: "0.3rem",
          }}
        >
          <NormalBtn name="완료" onClick={btnApply} />
          <NormalBtn
            name="취소"
            theme="white"
            onClick={() => {
              storesDispatch(globalModalOff());
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentModalB;

class paymentTable {
  table_title = "";
  btnStyleClass = btnStyles();
  openListState = [];
  selectOpen = null;
  onChangePayment = null;
  regDate = "";
  payMethodList = null;
  tableDataList = [];
  onAddMethod = null;
  onDeleteMethod = null;

  constructor(
    openListState,
    selectOpen,
    onChangePayment,
    regDate,
    payMethodList,
    onAddMethod,
    onDeleteMethod,
    onChangePrice,
    payDataList,
  ) {
    this.openListState = openListState;
    this.selectOpen = selectOpen;
    this.onChangePayment = onChangePayment;
    this.regDate = regDate;
    this.payMethodList = payMethodList;
    this.onAddMethod = onAddMethod;
    this.onDeleteMethod = onDeleteMethod;
    // console.log(payMethodList);
    // console.log(payDataList); // ydh

    for (let i = 0; i < payMethodList.length; i++) {
      const paymentTableData = new paymentMethodTable(
        payMethodList[i].pay_method,
        onChangePrice,
        onChangePayment,
        onDeleteMethod,
        regDate,
        payDataList[i].discount_return
          ? payDataList[i].repaid_price + payDataList[i].discount_return
          : payDataList[i].repaid_price,
        "unpaid",
        0,
      );
      this.tableDataList.push(paymentTableData);
    }
  }

  getPayMethodList = (purchaseCode) => {
    // console.log(this.payMethodList.find((x) => x.purchase_code === purchaseCode));
    return this.payMethodList.find((x) => x.purchase_code === purchaseCode).pay_method;
  };

  getPaymentTableData = (purchaseCode) => {
    const tableIdx = this.payMethodList.findIndex((x) => x.purchase_code === purchaseCode);
    return this.tableDataList[tableIdx];
  };

  onClickAddMethod = (idx) => {
    this.onAddMethod(idx);
  };

  create_table = (data, idx) => {
    return (
      <React.Fragment>
        <CustomRow
          style={{ width: "100%", cursor: "pointer" }}
          onClick={(e) => {
            if (e.target.nodeName === "DIV" || e.target.nodeName === "TD") {
              this.selectOpen(idx, !this.openListState[idx]);
            }
          }}
        >
          <CustomTableContents width="25%">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ width: "2rem" }}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    this.selectOpen(idx, !this.openListState[idx]);
                  }}
                >
                  {this.openListState[idx] ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                </IconButton>
              </div>
              <div style={{ width: "80%" }}>{data.purchase_code}</div>
            </div>
          </CustomTableContents>
          <CustomTableContents width="25%" $isNumber>
            {data.total_price ? data.total_price.toLocaleString() + " 원" : "0"}
          </CustomTableContents>
          <CustomTableContents width="25%" $isNumber>
            {data.total_paid.toLocaleString() + " 원"}
          </CustomTableContents>
          <CustomTableContents width="25%" $isNumber>
            {data.unpaid_info.unpaid_price.toLocaleString() + " 원"}
          </CustomTableContents>
        </CustomRow>
        <CustomRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: "#eee" }}
            colSpan={4}
          >
            <Collapse in={this.openListState[idx]} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <PaymentMethod
                  type={"unpaid"}
                  payMethodList={this.getPayMethodList(data.purchase_code)}
                  paymentTableData={this.getPaymentTableData(data.purchase_code)}
                  onClickAddMethod={() => this.onClickAddMethod(idx)}
                />
              </Box>
            </Collapse>
          </TableCell>
        </CustomRow>
      </React.Fragment>
    );
  };

  columns_head = [
    { title: "구매코드" },
    { title: "총 상품금액" },
    { title: "결제금액" },
    { title: "미납금" },
  ];

  get_data_from_rest = (stateData) => {
    if (listEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
