import { simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { loadCardDetailInfoStore } from "../Module/actions";

const AddAccessibleBrnachModal = ({ modalParam }) => {
  const storesDispatch = useDispatch();
  const [hqIdx, setHqIdx] = useState();
  const [branchIdx, setBranchIdx] = useState();
  const [loading, setLoading] = useState(false);

  //출입가능지점추가 MSG
  const validationCheck = () => {
    if (hqIdx === undefined) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="본부를 선택해주세요." />
            </>
          ),

          onBtnOk: () => {},
        }),
      );
    } else if (branchIdx === undefined) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="지점을 선택해주세요." />
            </>
          ),

          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="출입가능 지점을 추가하시겠습니까?" />
            </>
          ),
          onBtnYes: () => {
            addBranch();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  //출입가능지점추가 POST
  const addBranch = async () => {
    try {
      setLoading(true);

      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const formData = new FormData();
      if (modalParam.staffAccessList.length !== 0) {
        const param = {
          hq_info: modalParam.hqBranchCombo.target_list[hqIdx].hq_info,
          branch_info: modalParam.hqBranchCombo.target_list[hqIdx].branch_list[branchIdx],
          is_access_deny: modalParam.staffAccessList.is_access_deny,
          is_empty_work_info: modalParam.staffAccessList.is_empty_work_info,
          is_hq_only: modalParam.staffAccessList.is_hq_only,
          view_id: modalParam.staffAccessList.view_id,
        };
        formData.append("param_info", JSON.stringify(param));
        formData.append("staff_id", JSON.stringify(modalParam.serviceViewState.cur_user.user_id));
        const res = await HttpInstance.post(
          process.env.REACT_APP_BRANCH_SERVER + "/access/card/branch/add",
          formData,
          config,
        );

        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          //성공
          storesDispatch(
            globalAlertOn({
              show: true,
              titleText: "알림",
              bodyText: <Label labelText="저장 되었습니다." />,
              onBtnOk: () => {
                storesDispatch(loadCardDetailInfoStore(modalParam.card_info_id)); //카드상세 갱신
                storesDispatch(globalModalOff()); //모달닫기
              },
            }),
          );
        }
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? <Loading /> : <></>}

      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <ComboBox
          labelWidth={"3rem"}
          comboItemWidth={"12.75rem"}
          labelText="본부"
          data={modalParam.hqBranchCombo.hq_list}
          onChangeCallback={(e) => {
            const idx = modalParam.hqBranchCombo.hq_list.findIndex((el) => el === e.target.value);
            if (idx !== -1) {
              setHqIdx(idx);
            }
          }}
        />
      </div>

      <div style={{ marginTop: "0.5rem" }}>
        <ComboBox
          labelWidth={"3rem"}
          comboItemWidth={"12.75rem"}
          labelText="지점"
          data={hqIdx !== undefined ? modalParam.hqBranchCombo.branch_list[hqIdx] : []}
          onChangeCallback={(e) => {
            const bIdx = modalParam.hqBranchCombo.branch_list[hqIdx].findIndex(
              (el) => el === e.target.value,
            );
            if (bIdx !== -1) {
              setBranchIdx(bIdx);
            }
          }}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "0.7rem" }}>
        <NormalBtn name="저장" onClick={validationCheck} />
      </div>
    </div>
  );
};
export default AddAccessibleBrnachModal;
