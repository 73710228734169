import * as lodash from "lodash";
import PaymentModal from "pages/ERP/MemberCommonItems/PaymentModal/PaymentModal";
import { useEffect, useState } from "react";

const ChngePayMethodModalIndex = ({ modalSelect, modalParam, onModalDone }) => {
  const [paramData, setParamData] = useState(modalParam.modalParam);

  useEffect(() => {
    const editData = lodash.cloneDeep(modalParam.modalParam);
    setParamData(editData);
    return () => {};
  }, []);

  if (modalSelect === "연락처중복") {
    // return <RegisterPhoneCheckModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "결제") {
    return <PaymentModal modalParam={paramData} onModalDone={onModalDone} />;
  } else {
    return <div>current ELSE</div>;
  }
};
export default ChngePayMethodModalIndex;
