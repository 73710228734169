///PAYMENT
export const newitemInfoPush = (type_key, content_obj, ordIdx) => {
  return {
    type: "CHANGE_ITEM/NEW_ITEM/PUSH",
    payload: {
      type_key: type_key,
      content_obj: content_obj,
      orderIdx: ordIdx,
    },
  };
};

export const newitemInfoPop = (type_key) => {
  return {
    type: "CHANGE_ITEM/NEW_ITEM/POP",
    type_key: type_key,
  };
};
export const newitemInfoClear = (data) => {
  return {
    type: "CHANGE_ITEM/NEW_ITEM/CLEAR",
    payload: data,
  };
};

export const newitemInfoEdit = (type_key, content_obj, ordIdx) => {
  return {
    type: "CHANGE_ITEM/NEW_ITEM/EDIT",
    payload: {
      type_key: type_key,
      content_obj: content_obj,
      orderIdx: ordIdx,
    },
  };
};
