import { Button } from "@mui/material";

const LabelBtn = (props) => {
  //필수 name (버튼명) + theme

  const blueStyle = {
    borderRadius: "100px",
    backgroundColor: "#E3EBF7",
    color: "#578DDE",
    fontWeight: "bold",
    fontSize: "0.8rem",
    fontFamily: "AppleSDGothicNeo",
    margin: props.margin,
    width: props.width,
  };
  const orangeStyle = {
    borderRadius: "100px",
    backgroundColor: "#FFE1D1",
    color: "#FF6B17",
    fontWeight: "bold",
    fontSize: "0.8rem",
    fontFamily: "AppleSDGothicNeo",
    margin: props.margin,
    width: props.width,
  };

  const getColor = () => {
    if (props.theme === "orange") {
      return orangeStyle;
    } else {
      return blueStyle;
    }
  };

  return (
    <Button style={getColor()} onClick={props.onClick} disabled={props.disabled}>
      {props.name}
    </Button>
  );
};

export default LabelBtn;
