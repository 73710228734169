import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import { listEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import CheckBox from "components/LabelInput/CheckBox";

const ItemSelector = ({ serviceViewState, onSelectBaseItem }) => {
  const selectTargetList = new selectTargetTable(
    onSelectBaseItem,
    serviceViewState.membership_data_list,
  );

  return (
    <CardFixed
      topDom={
        <div style={{ display: "flex" }}>
          <div>기존 상품 선택</div>
          <div
            style={{
              marginLeft: "auto",
              marginTop: "0.5rem",
              marginRight: "1rem",
              float: "right",
              fontSize: "0.85rem",
              color: "red",
              fontWeight: "normal",
            }}
          >
            ※ “회원권 + PT” 결합상품은 변경이 불가합니다.
          </div>
        </div>
      }
      midDom={
        <div style={{ marginTop: "1rem", marginLeft: "0.1rem" }}>
          <CustomTable
            columns_head={selectTargetList.columns_head}
            columns_head_2={selectTargetList.columns_head_2}
            table_title={selectTargetList.table_title}
            rest_call={selectTargetList.get_data_from_rest}
            row_render={selectTargetList.create_table}
            rest_data={serviceViewState.refund_item_info.refund_info_list}
            isDouble
          />
        </div>
      }
      isLoading={false}
      rootStyle={{
        width: "76.88rem",
        marginTop: "0.625rem",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};
export default ItemSelector;

class selectTargetTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onSelectBaseItem = null;
  allMembership = [];
  // change_option_list = [];

  constructor(onSelectBaseItem, allMembership) {
    this.onSelectBaseItem = onSelectBaseItem;
    this.allMembership = allMembership;
    // this.change_option_list = change_option_list;
  }

  getMbshpPeriod = (mbshpId) => {
    const idx = this.allMembership.findIndex((x) => x.membership_list.membership_id === mbshpId);
    const from = this.allMembership[idx].membership_list.mbshp.from_date;
    const to = this.allMembership[idx].membership_list.mbshp.to_date;
    return from + " ~ " + to;
  };

  getMbshpStatus = (mbshpId) => {
    const idx = this.allMembership.findIndex((x) => x.membership_list.membership_id === mbshpId);
    return this.allMembership[idx].membership_list.mbshp_status;
  };

  getSubsPeriod = (mbshpId) => {
    const idx = this.allMembership.findIndex((x) => x.membership_list.membership_id === mbshpId);

    if (idx === -1 || this.allMembership[idx].membership_list.mbshp_option !== "구독형") {
      return "";
    }

    const from = this.allMembership[idx].membership_list.mbshp.subs_options.contract_from_date;
    const to = this.allMembership[idx].membership_list.mbshp.subs_options.contract_to_date;

    return from + " ~ " + to;
  };

  getSubsTotalPrice = (mbshpId) => {
    const idx = this.allMembership.findIndex((x) => x.membership_list.membership_id === mbshpId);

    if (idx === -1 || this.allMembership[idx].membership_list.mbshp_option !== "구독형") {
      return "";
    }

    return this.allMembership[idx].membership_list.subs_options.subs_total_price.toLocaleString();
  };

  getSubsMonthlyPrice = (mbshpId) => {
    const idx = this.allMembership.findIndex((x) => x.membership_list.membership_id === mbshpId);

    if (idx === -1 || this.allMembership[idx].membership_list.mbshp_option !== "구독형") {
      return "";
    }

    return "";
  };

  getItemName = (info) => {
    const val = info.item_type.split("/");
    if (val.length > 3 && val[2] === "일할금액") {
      return info.description + " (일할금액)";
    }

    if (info.description === "회원권") {
      return info.description + " (" + info.item_name + ")";
    } else {
      return info.description;
    }
  };

  create_table = (data, idx) => {
    return (
      <>
        <CustomRow key={idx} style={{ width: "100%" }}>
          <CustomTableContents width="4%" rowSpan={data.purchase_list.length + 1}>
            <CheckBox
              isTable
              defaultValue={data.selected}
              onChangeCallback={(e) => {
                data.selected = e.checked;
                this.onSelectBaseItem(idx, e.checked);
              }}
              disabled={data.is_lock}
            />
          </CustomTableContents>
        </CustomRow>
        {data.purchase_list.map((item, idx) => {
          // console.log(item);
          return (
            <CustomRow key={idx}>
              <CustomTableContents>{item.purchase_info.purchase_code}</CustomTableContents>
              <CustomTableContents>{this.getItemName(item.purchase_info)}</CustomTableContents>
              <CustomTableContents>
                {item.usage_period}
                {/* {item.purchase_info.description === "회원권"
                  ? this.getMbshpPeriod(item.purchase_info.membership_id)
                  : ""} */}
              </CustomTableContents>
              <CustomTableContents>
                {item.purchase_info.item_price.toLocaleString()}
              </CustomTableContents>
              <CustomTableContents>
                {item.purchase_info.paid_price.toLocaleString()}
              </CustomTableContents>
              <CustomTableContents>
                {item.purchase_info.description === "회원권"
                  ? this.getMbshpStatus(item.purchase_info.membership_id)
                  : ""}
              </CustomTableContents>
              <CustomTableContents>
                {this.getSubsPeriod(item.purchase_info.membership_id)}
              </CustomTableContents>
              <CustomTableContents>
                {this.getSubsTotalPrice(item.purchase_info.membership_id)}
              </CustomTableContents>
              <CustomTableContents>
                {this.getSubsMonthlyPrice(item.purchase_info.membership_id)}
              </CustomTableContents>
            </CustomRow>
          );
        })}
      </>
    );
  };

  columns_head = [
    { title: "", width: "69%", col_span: 7 },
    { title: "약정 정보", width: "31%", col_span: 3 },
  ];
  columns_head_2 = [
    { title: "선택", width: "4%" },
    { title: "구매코드", width: "10%" },
    { title: "상품명", width: "15%" },
    { title: "유효기간", width: "11%" },
    { title: "상품가액", width: "9%" },
    { title: "결제금액", width: "9%" },
    { title: "상태", width: "8%" },
    { title: "약정기간", width: "11%" },
    { title: "약정총액", width: "9%" },
    { title: "월 결제금액", width: "9%" },
  ];

  get_data_from_rest = (stateData) => {
    if (listEmpty(stateData)) {
      return [];
    }

    // const retList = [];
    // for (let i = 0; i < stateData.length; i++) {
    //   if (!this.change_option_list[i].is_only_pt_hide) {
    //     retList.push(stateData[i]);
    //   }
    // }

    // return retList;
    return stateData;
  };
}
