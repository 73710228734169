import * as lodash from "lodash";
import { useEffect, useState } from "react";
import AddAccessibleBrnachModal from "./AddAccessibleBranchModal";
import CardAddModal from "./CardAddModal";

import AddCardByUploadModal from "./AddCardByUploadModal";
import EditAllCardModal from "./EditAllCardModal";

const CardManageModalIndex = ({ modalSelect, modalParam, onModalDone }) => {
  const [paramData, setParamData] = useState(modalParam.modalParam);

  useEffect(() => {
    const editData = lodash.cloneDeep(modalParam.modalParam);
    setParamData(editData);
    return () => {};
  }, []);

  if (modalSelect === "일괄수정") {
    return <EditAllCardModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "개별추가") {
    return <AddCardByUploadModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "카드추가") {
    return <CardAddModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "출입가능지점추가") {
    return <AddAccessibleBrnachModal modalParam={paramData} onModalDone={onModalDone} />;
  } else {
    return <div>current ELSE</div>;
  }
};
export default CardManageModalIndex;
