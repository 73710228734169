//급여메인 현황 Summary
export const salaryInfoInitState = {
  stores: {
    data: {
      approval_info: {
        approval_comment_list: [
          {
            comment: "",
            staff_info: {
              staff_id: "",
              staff_name: "",
              staff_pos: "",
              staff_rank: "",
            },
          },
        ],
        approval_docs_id: "",
        approval_draft_line: {
          approval_line_id: "",
          approver: [
            {
              approve_date: "",
              is_approve: false,
              staff_info: {
                staff_id: "",
                staff_name: "",
                staff_pos: "",
                staff_rank: "",
              },
            },
          ],
          line_title: "",
          receiver: [
            {
              staff_info: {
                staff_id: "",
                staff_name: "",
                staff_pos: "",
                staff_rank: "",
              },
            },
          ],
          reg_info: {
            created_at: "",
            reg_id: "",
            reg_name: "",
            upd_id: "",
            upd_name: "",
            updated_at: "",
          },
          viewer: [
            {
              staff_info: {
                staff_id: "",
                staff_name: "",
                staff_pos: "",
                staff_rank: "",
              },
            },
          ],
        },
        approval_status: "",
        approval_type: "",
        approve_done_date: "",
        doc_info: {
          doc_id: "",
          doc_name: "",
        },
        doc_title: "",
        files: [
          {
            file_name: "",
          },
        ],
        reg_info: {
          created_at: "",
          reg_id: "",
          reg_name: "",
          upd_id: "",
          upd_name: "",
          updated_at: "",
        },
        writer: {
          branch_info: {
            branch_id: "",
            branch_name: "",
          },
          hq_info: {
            hq_id: "",
            hq_name: "",
          },
          staff_info: {
            staff_id: "",
            staff_name: "",
            staff_pos: "",
            staff_rank: "",
          },
        },
      },
      approval_permission: {
        btn_approval: false,
        btn_draft: false,
        btn_edit: false,
        btn_edit_cancel: false,
        btn_re_entry: false,
        btn_recall: false,
        btn_retirement: false,
        btn_trans: false,
        doc_info: {
          doc_id: "",
          doc_name: "",
        },
        work_trans_limit_date: "",
      },
      approval_status: "",
      base_date: "",
      branch_info: {
        branch_id: "",
        branch_name: "",
      },
      branch_summary_info: {
        gx_use_cnt: 0,
        mbshp_incentive_cnt: 0,
        mbshp_total_sales: 0,
        pt_total_sales: 0,
        sales_total: 0,
      },
      edit_status: "",
      hq_info: {
        hq_id: "",
        hq_name: "",
      },
      salary_summary_info: {
        base_salary_total: 0,
        etc_total_salary: 0,
        gx_total_class: 0,
        mbshp_total_salary: 0,
        pt_total_class: 0,
        pt_total_commission: 0,
        salary_total: 0,
      },

      is_salary_basic: false,
      is_salary_mbshp: false,
      is_salary_pt: false,
      is_salary_gx: false,
      is_salary_etc: false,
    },
  },
};
