import { contentTypeJson } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

// ==================== GET ====================
// [GET] 운영 일지 검색 조건 받아오기 및 권한 데이터 세팅
export async function getDailyReportSearchParam() {
  return await HttpInstance.get(
    process.env.REACT_APP_SALARY_SERVER + "/daily_report/search/param",
    {
      params: {},
    },
  );
}

// [GET] 운영 일지 내역 리스트 검색
export async function getDailyReportList(queryData) {
  const { page, searchParams } = queryData;
  const formData = new FormData();

  formData.append("search_param", JSON.stringify(searchParams));
  formData.append("page_num", page);

  return await HttpInstance.post(
    process.env.REACT_APP_SALARY_SERVER + "/daily_report/search/list",
    formData,
    contentTypeJson,
  );
}

// [GET] 운영일지 상세 데이터
export async function getDailyReportDetail(queryData) {
  const { reportInfoId } = queryData;

  return await HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + "/daily_report/detail", {
    params: {
      report_info_id: reportInfoId,
    },
  });
}

// [GET] 운영일지 월간 매출
export async function getMonthlySales(queryData) {
  const {
    branch_info: { branch_id },
    base_date,
  } = queryData;

  return await HttpInstance.get(
    process.env.REACT_APP_SALARY_SERVER + "/daily_report/monthly/sales",
    {
      params: {
        branch_id,
        base_date,
      },
    },
  );
}

// [GET] 운영일지 미납금 현황
export async function getUnpaidState(queryData) {
  const {
    branch_info: { branch_id },
  } = queryData;

  return await HttpInstance.get(
    process.env.REACT_APP_SALARY_SERVER + "/daily_report/current/unpaid",
    {
      params: { branch_id },
    },
  );
}

// [GET] 운영일지 미납금 제외 처리 목록
export async function getUnpaidExclusion(queryData) {
  const {
    branch_info: { branch_id },
    base_date,
  } = queryData;

  return await HttpInstance.get(
    process.env.REACT_APP_SALARY_SERVER + "/daily_report/current/unpaid/exclusion",
    {
      params: {
        branch_id,
        base_date,
      },
    },
  );
}

// [GET] 운영일지 환불 요청 목록
export async function getTransferReq(queryData) {
  const {
    branch_info: { branch_id },
  } = queryData;

  return await HttpInstance.get(
    process.env.REACT_APP_SALARY_SERVER + "/daily_report/transfer_req",
    {
      params: {
        branch_id,
        is_done: false,
        is_expenditure: false,
        is_admin: false,
      },
    },
  );
}

// [GET] 운영일지 시재금 현황
export async function getVaultCash(queryData) {
  const {
    branch_info: { branch_id },
    base_date,
  } = queryData;

  return await HttpInstance.get(
    process.env.REACT_APP_SALARY_SERVER + "/daily_report/current/vault-cash",
    {
      params: {
        branch_id,
        target_date: base_date,
      },
    },
  );
}

// [GET] 운영일지 시재금 현황 새로고침
export async function refreshVaultCash(queryData) {
  const {
    branch_info: { branch_id },
  } = queryData;

  return await HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + "/vault_cash/refresh", {
    params: { branch_id },
  });
}
