const initialStete = {
  stores: null,
};

export const cs_task_list = (state = initialStete, action) => {
  switch (action.type) {
    case "LOAD_TASK_INFO_SUCCESS": {
      return { ...state, stores: action.payload };
    }
    case "LOAD_TASK_INFO_FAIL":
      return { stores: null };
    default:
      return state;
  }
};
export const cs_list = (state = initialStete, action) => {
  switch (action.type) {
    case "LOAD_CS_LIST_SUCCESS": {
      return { ...state, stores: action.payload };
    }
    case "LOAD_CS_LIST_FAIL":
      return { stores: null };
    default:
      return state;
  }
};

export const cs_info = (state = initialStete, action) => {
  switch (action.type) {
    case "LOAD_CS_INFO_SUCCESS": {
      return { ...state, stores: action.payload };
    }
    case "LOAD_CS_INFO_FAIL":
      return { stores: null };
    default:
      return state;
  }
};

export const cs_filter_info = (state = initialStete, action) => {
  switch (action.type) {
    case "LOAD_CS_FILTER_INFO_SUCCESS": {
      return { ...state, stores: action.payload };
    }
    case "LOAD_CS_FILTER_INFO_FAIL":
      return { stores: null };
    default:
      return state;
  }
};

export const cs_detail_health_info = (state = initialStete, action) => {
  switch (action.type) {
    case "LOAD_CS_DETAIL_HEALTH_INFO_SUCCESS": {
      return { ...state, stores: action.payload };
    }
    case "LOAD_CS_DETAIL_HEALTH_INFO_FAIL":
      return { stores: null };
    default:
      return state;
  }
};
