import axios from "axios";
import { simpleAlert } from "components/CommonLib/CommonLib";
import { setAuthInfoStores } from "../modules/actions/Auth/index";
import { store } from "../store";

export const HttpInstance = axios.create({
  timeout: 60000,
  headers: {
    "X-Product": "erp",
    "Public-Ip": sessionStorage.getItem("Public-Ip"),
  },
});

export const GetPublicIpAddr = async () => {
  try {
    var response = await fetch("https://api.ip.pe.kr/");
    if (response.ok) {
      const ipAddr = await response.text();
      sessionStorage.setItem("Public-Ip", ipAddr);
    }
  } catch (error) {
    var response2 = await fetch("https://icanhazip.com/");
    if (!response2.ok) {
      simpleAlert(store.dispatch, "알림", "IP 주소를 가져오는데 실패했습니다.");
      return;
    } else {
      const ipAddr = await response2.text();
      sessionStorage.setItem("Public-Ip", ipAddr);
    }
  }
};

export const RefreshPublicIpHeader = async () => {
  const publicIp = sessionStorage.getItem("Public-Ip");
  if (!publicIp) {
    await GetPublicIpAddr();
  }
  if (!HttpInstance.defaults.headers["Public-Ip"]) {
    HttpInstance.defaults.headers["Public-Ip"] = sessionStorage.getItem("Public-Ip");
  }
};
// 라이브러리의 timeout 기본 값을 2.5초로 재 정의하여
// 인스턴스의 모든 요청은 2.5초 간만 대기 후 타임아웃 처리합니다.
// httpInstance.defaults.timeout = 3500;
HttpInstance.interceptors.request.use(async (config) => {
  RefreshPublicIpHeader();
  return config;
});

HttpInstance.interceptors.response.use(
  (res) => {
    return res;
  },

  function (error) {
    const originalRequest = error.config;
    // Do something with response error

    if (error.response.status === 401) {
      originalRequest._retry = true;

      const retryOrigReq = new Promise((resolve, reject) => {
        axios.defaults.headers.common["Authorization"] =
          HttpInstance.defaults.headers.common["Authorization"];
        axios.defaults.headers["X-Product"] = "erp";
        axios.defaults.headers["Public-Ip"] = sessionStorage.getItem("Public-Ip");
        // public ip도

        axios
          .post(process.env.REACT_APP_AUTH_SERVER + "/token/renew", {
            refresh_token: HttpInstance.defaults.headers.common["Refresh"],
          })
          .then((res) => {
            if (res.error !== false) {
              HttpInstance.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${res.data.tokens.access}`;
              HttpInstance.defaults.headers.common["Refresh"] = `Bearer ${res.data.tokens.refresh}`;
            }
            originalRequest.headers.Authorization = `Bearer ${res.data.tokens.access}`;
            originalRequest.headers.Refresh = `Bearer ${res.data.tokens.refresh}`;
            store.dispatch(setAuthInfoStores(res.data.tokens));
            resolve(axios(originalRequest));
          })
          .catch((error) => {
            if (error.response.status === 403) {
              simpleAlert(
                store.dispatch,
                "알림",
                "세션이 만료되었습니다.\n다시 로그인 해주시기 바랍니다.",
                true,
                () => {
                  window.location.href = "/login";
                },
              );
            } else {
              console.log("error + " + error);
              const cookies = document.cookie.split("; ");
              const expiration = "Sat, 01 Jan 1972 00:00:00 GMT";
              for (let i = 0; i < cookies.length; i++) {
                document.cookie = cookies[i].split("=")[0] + "=; expires=" + expiration;
              }
            }
          });
      });
      return retryOrigReq;
    } else {
      console.log("요청 실패:", error.response);

      if (error.response && error.response.data) {
        error.response.data.originalMsg = error.response.data.msg; // 원본 메시지 보존
        error.response.data.msg = "요청을 처리할 수 없습니다.\n잠시 후 다시 시도해주세요";
      }
      return Promise.reject(error);
    }
  },
);

export const SetAuthTokens = (authState) => {
  try {
    const current_tokens = authState;

    HttpInstance.defaults.headers.common["Authorization"] = `Bearer ${current_tokens.access}`;
    HttpInstance.defaults.headers.common["Refresh"] = `Bearer ${current_tokens.refresh}`;

    return true;
  } catch (error) {
    return false;
  }
};
