import { generateObjectZeroId } from "components/CommonLib/CommonLib";
import TextField from "components/LabelInput/TextField";
import PriceDiscountInput from "pages/ERP/MemberRegister/Common/Etc/PriceDiscountInput";
import { useEffect, useState } from "react";

const DstPersonalInfo = ({
  assignee,
  assigner,
  addPayInfoState,
  payInfoListAction,
  assignViewState,
  changeBranchFee,
}) => {
  const labelWidth = "3.5rem";
  const compWidth = "7rem";
  const [branchChangeDis, setBranchChangeDis] = useState(0);
  const [showBranchChange, setShowBranchChange] = useState(false);

  useEffect(() => {
    if (
      assigner.branch_info.branch_id !== generateObjectZeroId() &&
      assignee.branch_info.branch_id !== generateObjectZeroId() &&
      assigner.branch_info.branch_id !== assignee.branch_info.branch_id
    ) {
      setShowBranchChange(true);
    }
  }, [assigner]);

  useEffect(() => {
    if (showBranchChange) {
      setBranchChangeDis(0);
      addPayInfoState("지점변경비", changeBranchFee, 2, "지점변경비/없음/없음/없음/없음");
    }
  }, [changeBranchFee]);

  return (
    <div>
      {/* Line 1 */}
      <div style={{ display: "flex" }}>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          marginLeft="1.5rem"
          labelText="지점"
          defaultValue={assignee.branch_info.branch_name}
          disabled
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          marginLeft="1.5rem"
          labelText="구분"
          defaultValue={assignee.personal_info.member_no ? "회원" : "신규회원"}
          disabled
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          marginLeft="1.5rem"
          labelText="출입방식"
          defaultValue={assignee.card_param.access_method}
          disabled
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          marginLeft="1.5rem"
          labelText="카드번호"
          defaultValue={assignee.card_param.card_no}
          disabled
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={"12rem"}
          marginLeft="1.5rem"
          labelText="연락처"
          defaultValue={assignee.personal_info.phone}
          disabled
        />
      </div>

      {/* Line 2 */}
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          marginLeft="1.5rem"
          labelText="이름"
          defaultValue={assignee.personal_info.member_name}
          disabled
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          marginLeft="1.5rem"
          labelText="생년월일"
          defaultValue={assignee.personal_info.birth}
          disabled
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          marginLeft="1.5rem"
          labelText="성별"
          defaultValue={assignee.personal_info.gender}
          disabled
        />

        {showBranchChange ? (
          <div style={{ marginLeft: "5rem" }}>
            <PriceDiscountInput
              baseInfo={{
                label: "지점변경비",
                type: "지점변경비",
                typeKey: "지점변경비",
                orderIdx: 1,
                payOrder: 2,
                item_type: "지점변경비/없음/없음/없음/없음",
              }}
              labelWidth="5.25rem"
              standardPrice={changeBranchFee}
              // standardPrice={assignViewState.assign_price.change_branch_price}
              payInfoListAction={payInfoListAction}
              onChangeCallback={(e) => {
                setBranchChangeDis(e);
              }}
              paramUse={true}
              discountValue={branchChangeDis}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default DstPersonalInfo;
