import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import OrgEditTable from "./OrgEditTable";
// import { registerAction } from "./Module/actions";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import IconButton from "@mui/material/IconButton";
import { dataEmpty, generateObjectZeroId, simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import TableTitleWithButton from "components/TableTitleWithButton/TableTitleWithButton";
import { globalAlertOn } from "modules/actions/Alert";
// import { v4 as uuidv4 } from "uuid";

const bodyStyle = {
  width: "18rem",
  height: "21rem",
  minHeight: "21rem",
};
const midBlock = {
  position: "relative",
  width: "20%",
};
const midStyle = {
  position: "absolute",
  top: "30%",
  left: "35%",
  width: "50%",
};
const upStyle = {
  position: "absolute",
  top: 0,
  left: "35%",
  width: "50%",
};
const bottomStyle = {
  position: "absolute",
  top: "75%",
  left: "35%",
  width: "50%",
};

const OrgEditTableCard = ({
  titleName,
  dataList,
  detailPush,
  detailPop,
  detailChange,
  isPosition,
}) => {
  let itemListTable = new itemTable();
  const storesDispatch = useDispatch();
  const [itemList, setItemList] = useState(dataList);
  const [selectedItem, setSelectedItem] = useState("");

  const [, updateState] = useState(); // 강제 렌더링용
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    setItemList(dataList);

    if (dataList.length > 0 && dataList[dataList.length - 1].org_name === "") {
      document.getElementById(titleName + (dataList.length - 1).toString()).focus();
    }

    return () => {
      setItemList([]);
    };
  }, [dataList, itemList]);

  const onAddItem = () => {
    const defaultValues = isPosition
      ? {
          is_pt_extra: true,
          org_id: generateObjectZeroId(),
          org_name: "",
          pt_commission: [],
          pt_fee: [],
          wage: 0,
          wage_type: "",
        }
      : {
          org_id: generateObjectZeroId(),
          org_name: "",
        };
    let defaultName = "";

    if (dataList.findIndex((x) => x.org_name === defaultName) !== -1) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="먼저 공란 입력 후 추가해 주십시오." />
            </>
          ),
          // width: "50rem",
          onBtnOk: () => {},
        }),
      );
    } else {
      dataList.push(defaultValues);
      if (detailPush !== undefined) {
        detailPush(defaultName);
      }

      setItemList([...dataList]);

      // 불려지지 않음.idx 없는 상태라
      // document.getElementById(titleName + (dataList.length - 1).toString()).focus();
    }
  };

  const onClickDelete = (index, itemName) => {
    simpleAlert(
      storesDispatch,
      "알림",
      "[" + itemName + "] 을(를) 삭제하시겠습니까?",
      false,
      undefined,
      () => {
        onDeleteItem(index);
      },
    );
  };

  const onDeleteItem = (index) => {
    var array = [...itemList];

    if (index !== -1) {
      array.splice(index, 1);
      dataList.splice(index, 1);
      if (detailPop !== undefined) {
        detailPop(index);
      }
      setItemList([...array]);
    }
  };

  const onChangeValue = (idx, val) => {
    dataList[idx].org_name = val;
    if (detailChange !== undefined) {
      detailChange(idx, val);
    }
  };

  const onChangeDone = (idx, val) => {
    dataList[idx].org_name = val;
    setItemList(dataList);

    let duplicateCnt = 0;
    for (let i = 0; i < dataList.length; i++) {
      if (dataList[i].org_name === val) {
        duplicateCnt++;
      }
    }

    if (duplicateCnt > 1) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="중복 값이 있습니다." />
            </>
          ),
          // width: "50rem",
          onBtnOk: () => {},
        }),
      );
      onDeleteItem(idx);
    }

    forceUpdate();
  };

  const onSelectChange = (item) => {
    setSelectedItem(item);
  };

  const onMoveItemUp = () => {
    if (selectedItem) {
      const idx = dataList.findIndex((x) => x.org_name === selectedItem.org_name);
      if (idx > 0) {
        dataList.splice(idx, 1);
        dataList.splice(idx - 1, 0, selectedItem);
        setItemList(dataList);
        forceUpdate();
      }
    }
  };

  const onMoveItemDown = () => {
    if (selectedItem) {
      const idx = dataList.findIndex((x) => x.org_name === selectedItem.org_name);
      if (idx + 1 <= dataList.length - 1) {
        dataList.splice(idx, 1);
        dataList.splice(idx + 1, 0, selectedItem);
        setItemList(dataList);
        forceUpdate();
      }
    }
  };

  const onMoveItemTop = () => {
    if (selectedItem) {
      const idx = dataList.findIndex((x) => x.org_name === selectedItem.org_name);
      if (idx > 0) {
        dataList.splice(idx, 1);
        dataList.splice(0, 0, selectedItem);
        setItemList(dataList);
        forceUpdate();
      }
    }
  };

  const onMoveItemBottom = () => {
    if (selectedItem) {
      const idx = dataList.findIndex((x) => x.org_name === selectedItem.org_name);
      if (idx < dataList.length - 1) {
        dataList.splice(idx, 1);
        dataList.splice(dataList.length, 0, selectedItem);
        setItemList(dataList);
        forceUpdate();
      }
    }
  };

  return (
    <div style={bodyStyle}>
      <TableTitleWithButton titleLabel={titleName} buttonText={"추가"} onClickEvent={onAddItem} />
      <div style={{ display: "flex", height: "100%", maxHeight: "20rem" }}>
        <OrgEditTable
          titleName={titleName}
          rest_call={itemListTable.get_data_from_rest}
          // row_render={itemList.create_table}
          rest_data={itemList}
          onClickDelete={onClickDelete}
          onDeleteItem={onDeleteItem}
          rawChange={onChangeValue}
          onChangeDone={onChangeDone}
          selectChange={onSelectChange}
        />
        <div style={midBlock}>
          <div style={upStyle}>
            <IconButton
              color="primary"
              aria-label="move to top"
              component="span"
              onClick={onMoveItemTop}
            >
              <KeyboardDoubleArrowUpIcon />
            </IconButton>
          </div>
          <div style={midStyle}>
            <IconButton
              color="primary"
              aria-label="move to up"
              component="span"
              onClick={onMoveItemUp}
            >
              <KeyboardArrowUpIcon />
            </IconButton>

            <IconButton
              color="primary"
              aria-label="move to down"
              component="span"
              onClick={onMoveItemDown}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          </div>
          <div style={bottomStyle}>
            <IconButton
              color="primary"
              aria-label="move to bottom"
              component="span"
              onClick={onMoveItemBottom}
            >
              <KeyboardDoubleArrowDownIcon />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgEditTableCard;

class itemTable {
  table_title = "";

  createData(idx, item) {
    return item;
  }

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    let resArr = [];
    for (let i = 0; i < stateData.length; i++) {
      resArr.push(this.createData(i, stateData[i]));
    }

    return resArr;
  };
}
