import { simpleAlert } from "components/CommonLib/CommonLib";
import Loading from "components/Loading/Loading/Loading";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HttpInstance, RefreshPublicIpHeader } from "../../lib/HttpLib";
import { setAuthInfoStores } from "../../modules/actions/Auth/index";
import Login from "./LoginTemplete";

const LoginPage = () => {
  const navigate = useNavigate();
  const storesDispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    RefreshPublicIpHeader();
  }, []);

  const goToMain = () => {
    navigate("/");
  };

  const onClickLogin = (body) => {
    const data = {
      user_id: body.id,
      password: body.password,
    };
    setLoading(true);

    HttpInstance.post(process.env.REACT_APP_AUTH_SERVER + "/user/sign/in", data)
      .then((response) => {
        const apiRes = response.data;
        if (!apiRes.error) {
          const accessToken = apiRes.tokens.access;
          const refreshToken = apiRes.tokens.refresh;

          storesDispatch(setAuthInfoStores(apiRes.tokens));

          HttpInstance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
          HttpInstance.defaults.headers.common["Refresh"] = `Bearer ${refreshToken}`;

          goToMain();
        } else {
          simpleAlert(storesDispatch, "알림", "아이디 또는 패스워드를 확인해주세요.");
        }
      })
      .catch((error) => {
        simpleAlert(storesDispatch, "알림", error.response.data.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Loading />}
      <Login onClickLogin={onClickLogin} />
    </>
  );
};

export default LoginPage;
