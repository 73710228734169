import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { commonFontSmall } from "components/CommonComp";
import { useEffect, useState } from "react";

const ComboBox = (props) => {
  const [value, setValue] = useState("defaultValue");
  const [key, setKey] = useState(props.defaultKey === undefined ? "" : props.defaultKey);

  useEffect(() => {
    if (props.defaultValue) {
      setValue(props.defaultValue);
    }
  }, [props.defaultValue]);

  const handleChange = (event, index) => {
    setValue(event.target.value);
    setKey(event.target.key);

    if (props.inputRef !== undefined) {
      props.inputRef.current.value = event.target.value;
    }

    if (props.onChangeCallback !== undefined) {
      props.onChangeCallback(event, index);
    }
  };

  const handleOpen = () => {
    if (!value || value === "defaultValue") {
      // value가 없거나 "-선택-" 값이면 메뉴 옵션 중 첫번째 값 선택
      const firstMenuItem = props.data.find((item) => item !== "선택" && item !== "선택해주세요");
      const changeEvent = {
        target: {
          value: firstMenuItem,
          name: undefined,
        },
      };
      handleChange(changeEvent, 0);
    }
  };

  const hStyle = {
    display: "flex",
    alignItems: "center",
    width: props.fullWidth ? "100%" : null,
    justifyContent: props.justifyContent,
    marginTop: props.isMargin === undefined ? props.marginTop : "0rem",
    marginLeft: props.isMargin === undefined ? props.marginLeft : "0rem",
    marginRight: props.marginRight
      ? props.marginRight
      : props.isMargin === undefined
      ? "0.625rem"
      : "0rem",
    // width: "100%",
    // border: "1px solid red",
  };

  const labelStyle = {
    width: props.labelWidth,
    // height: "1.188rem",
    // padding: "9.5px 20px 9px 0",
    // margin: "1.188rem 0.625rem 1.125rem 1.875rem",
    marginRight: props.labelMarginRight === undefined ? "0.625rem" : props.labelMarginRight,
    marginLeft: props.labelMarginLeft,
    marginTop: props.labelMarginTop,
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.938rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: props.lineHeight ? props.lineHeight : "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
  };

  const comboItemStyle = {
    width: props.comboItemWidth,
    height: "2.25rem",
    margin: props.comboItemMargin,
    borderRadius: "5px",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "flex-start",

    // padding: "0.563rem 0.955rem 0.5rem 0.875rem",
    // border: "solid 0.063rem #dedede",
  };

  const dropItemStyle = {
    width: props.comboItemWidth,
    padding: "0.563rem 0.955rem 0.5rem 0.875rem",
    borderRadius: "5px",
    display: "grid",
  };

  return (
    <Box style={hStyle}>
      <div style={labelStyle}>{props.labelText}</div>
      <FormControl fullWidth={props.fullWidth}>
        <InputLabel id="label">{props.placeHolder}</InputLabel>
        <Select
          value={value}
          onChange={(event, index) => {
            const selectedIndex = index.props.id;
            handleChange(event, selectedIndex);
          }}
          onOpen={props.onOpen ? props.onOpen : props.required ? handleOpen : null}
          inputRef={props.inputRef}
          labelId="label"
          disabled={props.disabled}
          size="small"
          name={key}
          style={Object.assign(comboItemStyle, commonFontSmall)}
          MenuProps={{
            style: {
              maxHeight: "20rem",
            },
          }}
          error={props.required && value === "defaultValue" ? true : false}
          // name={props.id}
        >
          <MenuItem
            value={"defaultValue"}
            style={{
              ...dropItemStyle,
              ...commonFontSmall,
              display: props.includeSelectOption ? "block" : "none",
            }}
          >
            - 선택 -
          </MenuItem>
          <MenuItem
            value="전체"
            style={{
              ...dropItemStyle,
              ...commonFontSmall,
              display: props.includeAllOption ? "block" : "none", // props.includeAllOption === true면 첫번째 "전체" 옵션 추가
            }}
          >
            전체
          </MenuItem>

          {props.data
            ? props.data.map((item, idx) => {
                if (item === "선택" || item === "선택해주세요") {
                  return null;
                }
                return (
                  <MenuItem
                    key={idx}
                    value={item}
                    name={idx}
                    id={idx}
                    disabled={props.disableItem && props.disableItem.indexOf(item) !== -1}
                    style={Object.assign(dropItemStyle, commonFontSmall)}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#eaedf7",
                      },
                      "&&.Mui-selected": {
                        backgroundColor: "#d3d9f3",
                      },
                    }}
                  >
                    {item}
                  </MenuItem>
                );
              })
            : []}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ComboBox;
