import { useEffect, useState } from "react";

import LockerBrokenModal from "./LockerBrokenModal";
import LockerBrokenUserModal from "./LockerBrokenUserModal";
import LockerDetailModal from "./LockerDetailModal";

import LockerRecoverModal from "./LockerRecoverModal";
import LockerSetEditModal from "./LockerSetEditModal";
import LockerSetModal from "./LockerSetModal";
import LockerUnSignModal from "./LockerUnSignModal";

import * as lodash from "lodash";
import LockerAddFloorModal from "./LockerAddFloorModal";
const LockerModalIndex = ({ modalSelect, modalParam, onModalDone }) => {
  const [paramData, setParamData] = useState(null);

  useEffect(() => {
    const editData = lodash.cloneDeep(modalParam);
    setParamData(editData);
    return () => {};
  }, [modalParam]);

  if (modalSelect === "미배정회원목록") {
    return <LockerUnSignModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "고장락커사용회원") {
    return <LockerBrokenUserModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "고장등록") {
    return <LockerBrokenModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "만료락커검색") {
    return <LockerRecoverModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "락커상세") {
    return <LockerDetailModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "층추가") {
    return <LockerAddFloorModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "구역추가" || modalSelect === "구역수정") {
    return <LockerSetModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "구역명수정") {
    return <LockerSetEditModal modalParam={paramData} onModalDone={onModalDone} />;
  } else {
    return <div>current ELSE</div>;
  }
};
export default LockerModalIndex;
