import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";
import { globalModalOn } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import StaffInfoIndex from "./Modal/StaffInfoIndex";
import { staffInitState } from "./Module/reducers/staff_init_state";
import StaffInfoView from "./StaffInfoView";

const StaffInfoWrapper = ({ getState, staffId }) => {
  const navigate = useNavigate();
  const [staffInfoState, setStaffInfoState] = useState(staffInitState);
  const [hqPermissions, setHqPermissions] = useState([]);
  const storesDispatch = useDispatch();

  const getStaffInfoState = () => {
    HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/staff/get_staff_info", {
      params: {
        staff_id: staffId ? staffId : getState.staff_id,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setStaffInfoState(res.data.data);
        }
      })
      .catch((err) => {
        console.log("err");
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const getHqPermissions = () => {
    HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/hq/hq_permission_by_token", {
      params: {},
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setHqPermissions(res.data.data);
        }
      })
      .catch((err) => {
        console.log("err");
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  useEffect(() => {
    getStaffInfoState();
    getHqPermissions();
  }, []);

  const openModal = (select, param, doneCallback) => {
    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,
        Content: (
          <StaffInfoIndex modalSelect={select} modalParam={param} onModalDone={doneCallback} />
        ),
      }),
    );
  };

  const onClickOpenModal = (order, param, doneCallback) => {
    if (order !== "") {
      if (dataEmpty(doneCallback)) {
        openModal(order, param, onModalDone);
      } else {
        openModal(order, param, doneCallback);
      }
    }
  };

  const onModalDone = (ret_info) => {
    // console.log("ret_info");
    // console.log(ret_info);
  };

  const onRefresh = () => {
    getStaffInfoState();
  };

  return (
    <div>
      <StaffInfoView
        staffInfoState={staffInfoState}
        onClickOpenModal={onClickOpenModal}
        onRefresh={onRefresh}
        hqPermissions={hqPermissions}
      />
    </div>
  );
};
export default StaffInfoWrapper;
