import { create } from "zustand";

// 검색 조건 베이스
const baseSearchParams = {
  approval_status: "",
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  hq_info: {
    hq_id: "",
    hq_name: "",
  },
  edit_status: "",
  is_hq_staff: false,
  is_submit_account_copy: "",
  is_submit_emp_file: "",
  is_submit_id_card: "",
  is_submit_resign: "",
  join_date_from: "",
  join_date_to: "",
  phone: "",
  p1: "",
  p2: "",
  p3: "",
  position: "",
  resign_date_from: "",
  resign_date_to: "",
  staff_name: "",
  work_status: "",
};

export const useStaffListStore = create((set) => ({
  // ========== State ===========
  // ComboBox 첫번째 본부 및 지점 정보 State (조건 초기화 시 선택 위함)
  firstHqBranchInfoData: {
    hq_info: {
      hq_id: "",
      hq_name: "",
    },
    branch_info: {
      branch_id: "",
      branch_name: "",
    },
  },

  // 직원관리 검색 State
  staffListSearchParams: {
    ...baseSearchParams,
  },

  staffListHqIdx: 0,

  staffListPage: 1,

  staffListPhoneNumber: {
    p1: "",
    p2: "",
    p3: "",
  },

  // ========== Action ==========
  // 직원관리 검색 조건 변경
  setSearchParams: (key, value) => {
    set((state) => ({
      staffListSearchParams: {
        ...state.staffListSearchParams,
        [key]: value,
      },
    }));
  },
  // Hq Index 값 변경
  setHqIdx: (value) => {
    set({ staffListHqIdx: value });
  },

  // Page 값 변경
  setPage: (value) => {
    set({ staffListPage: value });
  },

  // ComboBox 첫번째 본부, 지점 정보 변경
  setHqBranchInfo: (key, value) => {
    set((state) => ({
      firstHqBranchInfoData: {
        ...state.firstHqBranchInfoData,
        [key]: value,
      },
    }));
  },
  // 연락처 조건 변경
  setStaffListPhoneNumber: (key, value) => {
    set((state) => ({
      staffListPhoneNumber: {
        ...state.staffListPhoneNumber,
        [key]: value,
      },
    }));
  },

  // 검색 조건 리셋
  resetSearchParams: () => {
    set((state) => ({
      staffListSearchParams: {
        ...baseSearchParams,
        ...state.firstHqBranchInfoData,
      },
    }));
    set({ staffListHqIdx: 0 });
    set({
      staffListPhoneNumber: {
        p1: "",
        p2: "",
        p3: "",
      },
    });
  },
}));
