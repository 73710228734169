import IconButton from "components/Button/IconButton";

import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import TextField from "components/LabelInput/TextField";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import { HttpInstance } from "lib/HttpLib";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import LockerSelectModal from "./LockerSelectModal";

const LockerUnSignModal = ({ modalParam, onModalDone }) => {
  //modalParam: selectedBranch
  const storesDispatch = useDispatch();
  const viewModelRef = useRef();
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });
  const [curPage, setCurPage] = useState();
  const [totalPage, setTotalPage] = useState(1);
  const [unSignedList, setUnSignedList] = useState([]);

  //미배정 회원목록 GET
  const getUnsignList = async (page) => {
    setCurPage(page);
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_BRANCH_SERVER + "/locker/unsign_list",
        {
          params: {
            is_excel: false,
            page_num: curPage,
            branch_id: modalParam.selectedBranch.branch_id,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setUnSignedList(res.data.data);
        setTotalPage(res.data.total_page);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  //1. 락커배정모달(신규) POP
  const popAssignLockerModal = (param) => {
    setModalInfo({
      show: true,
      title: "락커 배정",
      Content: <LockerSelectModal modalParam={param} onModalDone={lockerAssignModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };
  //1-1. 락커배정모달 DONE
  const lockerAssignModalDone = (branchId, floorId, zoneId) => {
    viewModelRef.current.setClose(); //모달닫기
    getUnsignList(1); //미배정리스트 갱신
    onModalDone(branchId, floorId, zoneId); //섹션2 구락리 갱신
  };

  //초기랜더링 리스트 GET
  useEffect(() => {
    if (modalParam && modalParam.selectedBranch.branch_name) {
      getUnsignList(1);
    }
  }, [modalParam]);

  //페이지네이션
  useEffect(() => {
    if (curPage) {
      getUnsignList(curPage);
    }
  }, [curPage]);

  const unSignTableList = new unSignTable(popAssignLockerModal);
  return (
    <div
      style={{
        width: "43rem",
      }}
    >
      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
      <div>
        <TextField
          labelWidth={"3rem"}
          textBoxWidth={"12rem"}
          labelText="지점"
          defaultValue={modalParam && modalParam.selectedBranch.branch_name}
          disabled
        />

        <div style={{ marginTop: "0.5rem" }}>
          <CustomTable
            columns_head={unSignTableList.columns_head}
            table_title={unSignTableList.table_title}
            rest_call={unSignTableList.get_data_from_rest}
            row_render={unSignTableList.create_table}
            rest_data={unSignedList}
          />
          <CustomPagination curPage={curPage} doSearch={setCurPage} totalCnt={totalPage} />
        </div>
      </div>
    </div>
  );
};
export default LockerUnSignModal;

class unSignTable {
  table_title = "";
  popAssignLockerModal = null;

  constructor(popAssignLockerModal) {
    this.popAssignLockerModal = popAssignLockerModal;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.locker_info.member_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.locker_info.member_info.member_name}</CustomTableContents>
        <CustomTableContents>{data.locker_info.member_info.phone}</CustomTableContents>
        <CustomTableContents>{data.locker_info.member_info.gender}</CustomTableContents>
        <CustomTableContents>{data.mbshp_status}</CustomTableContents>
        <CustomTableContentsEND align="center">
          <IconButton
            type="locker"
            onClick={() => {
              this.popAssignLockerModal({
                mode: "add",
                member_info: data.locker_info.member_info,
                branch_id: data.branch_info.branch_id,
                locker_total_idx: data.locker_info.locker_total_idx,
                mbshp_locker_id: data.mbshp_locker_id,
              });
            }}
          />
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "회원번호", width: "15%" },
    { title: "회원명", width: "21%" },
    { title: "연락처", width: "23%" },
    { title: "성별", width: "10%" },
    { title: "회원권 수강상태", width: "15%" },
    { title: "락커 배정", width: "10%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
