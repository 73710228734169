export const getMemberViewAction = "MEMBER/GET/MEMBER_VIEW";
export const getMemberViewSuccessAction = "MEMBER/GET/MEMBER_VIEW/SUCCESS";
export const getMemberViewFailAction = "MEMBER/GET/MEMBER_VIEW/FAIL";
export const getMemberViewResetAction = "MEMBER/GET/MEMBER_VIEW/RESET";

export const getPurchaseDetailModalAction = "MEMBER/MODAL/PURCHASE_DETAIL";
export const getPurchaseDetailModalSuccessAction = "MEMBER/MODAL/PURCHASE_DETAIL/SUCCESS";
export const getPurchaseDetailModalFailAction = "MEMBER/MODAL/PURCHASE_DETAIL/FAIL";
export const getPurchaseDetailModalResetAction = "MEMBER/MODAL/PURCHASE_DETAIL/RESET";

// export const getAddPayViewAction = "MEMBER/GET/ADDPAY_VIEW";
// export const getAddPayViewSuccessAction = "MEMBER/GET/ADDPAY_VIEW/SUCCESS";
// export const getAddPayViewFailAction = "MEMBER/GET/ADDPAY_VIEW/FAIL";
// export const getAddPayViewResetAction = "MEMBER/GET/ADDPAY_VIEW/RESET";

export const getMembershipHistoryViewAction = "MEMBER/HISTORY/MEMBERSHIP";
export const getMembershipHistoryViewSuccessAction = "MEMBER/HISTORY/MEMBERSHIP/SUCCESS";
export const getMembershipHistoryViewFailAction = "MEMBER/HISTORY/MEMBERSHIP/FAIL";
export const getMembershipHistoryViewResetAction = "MEMBER/HISTORY/MEMBERSHIP/RESET";

export const getClassHistoryViewAction = "MEMBER/HISTORY/CLASS";
export const getClassHistoryViewSuccessAction = "MEMBER/HISTORY/CLASS/SUCCESS";
export const getClassHistoryViewFailAction = "MEMBER/HISTORY/CLASS/FAIL";
export const getClassHistoryViewResetAction = "MEMBER/HISTORY/CLASS/RESET";

export const getPurchaseHistoryViewAction = "MEMBER/HISTORY/PURCHASE";
export const getPurchaseHistoryViewSuccessAction = "MEMBER/HISTORY/PURCHASE/SUCCESS";
export const getPurchaseHistoryViewFailAction = "MEMBER/HISTORY/PURCHASE/FAIL";
export const getPurchaseHistoryViewResetAction = "MEMBER/HISTORY/PURCHASE/RESET";

export const getCSHistoryViewAction = "MEMBER/HISTORY/CS";
export const getCSHistoryViewSuccessAction = "MEMBER/HISTORY/CS/SUCCESS";
export const getCSHistoryViewFailAction = "MEMBER/HISTORY/CS/FAIL";
export const getCSHistoryViewResetAction = "MEMBER/HISTORY/CS/RESET";

//가족 & 지인
export const getFamilyViewAction = "MEMBER/FAMILY_LIST";
export const getFamilyViewSuccessAction = "MEMBER/FAMILY_LIST/SUCCESS";
export const getFamilyViewFailAction = "MEMBER/FAMILY_LIST/FAIL";
export const getFamilyViewResetAction = "MEMBER/FAMILY_LIST/RESET";

export const getFriendsViewAction = "MEMBER/FRIEND_INFO";
export const getFriendsViewSuccessAction = "MEMBER/FRIEND_INFO/SUCCESS";
export const getFriendsViewFailAction = "MEMBER/FRIEND_INFO/FAIL";
export const getFriendsViewResetAction = "MEMBER/FRIEND_INFO/RESET";

//ETC
export const getBranchLinkHistViewAction = "MEMBER/BRANCH_LINK_HIST";
export const getBranchLinkHistViewSuccessAction = "MEMBER/BRANCH_LINK_HIST/SUCCESS";
export const getBranchLinkHistViewFailAction = "MEMBER/BRANCH_LINK_HIST/FAIL";
export const getBranchLinkHistViewResetAction = "MEMBER/BRANCH_LINK_HIST/RESET";

export const getDocumentsViewAction = "MEMBER/DOCUMENTS_LIST";
export const getDocumentsViewSuccessAction = "MEMBER/DOCUMENTS_LIST/SUCCESS";
export const getDocumentsViewFailAction = "MEMBER/DOCUMENTS_LIST/FAIL";
export const getDocumentsViewResetAction = "MEMBER/DOCUMENTS_LIST/RESET";
