export const payment_init_state = {
  paid_item_list: [
    {
      MembershipIdx: 0,
      content_obj: {
        dis: 0,
        item_type: "",
        pay_order: 0,
        price: 0,
        tab_idx: 0,
        type: "",
      },
      locker_info: {
        item_info: {
          dis: 0,
          is_hide: false,
          is_lock: false,
          is_use: false,
          is_use_exist: false,
          paid_item_idx: 0,
          price: 0,
          subs_options: {
            pre_dis: 0,
            pre_price: 0,
            prorate_dis: 0,
            prorate_price: 0,
            subs_dis: 0,
            subs_price: 0,
          },
        },

        locker_info: {
          from_date: "",
          locker_total_idx: 0,
          mbshp_locker_id: "",
          locker_no: 0,
          membership_id: "",
          password: "",
          queue_id: "",
          to_date: "",
          zone_id: "",
          zone_name: "",
        },
      },
      orderIdx: 0,
      type_key: "",
    },
  ],
  paid_price: 0,
  payment_input: {
    pay_method: [
      {
        approve_no: "",
        bank_user_name: "",
        buyer: "",
        card_comp: "",
        card_no: "",
        id: "",
        // installment_plan: 0,
        installment: "",
        pay_date: "",
        pay_method: "",
        price: 0,
      },
    ],
    total_paid: 0,
    unpaid_info: {
      promise_date: "",
      reason: "",
      unpaid_price: 0,
    },
  },
  purchase_code: "",
  unpaid_price: 0,
};
