import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import TMHistoryView from "./TMHistoryView";

const TMHistoryService = () => {
  return (
    <PageFrame
      children={
        <div>
          <PageTitle titleText="TM 이력" />
          <TMHistoryView />
        </div>
      }
    />
  );
};
export default TMHistoryService;
