import BaseInfo from "./SubItems/BaseInfo";
import HqDetailInfo from "./SubItems/HqDetailInfo";
import HqSelectorInfo from "./SubItems/HqSelectorInfo";

const HQSetting = ({ onClickOpenModal, tabIndex, setTabIndex, hqBaseData, hqId, onChangeHq }) => {
  const rootWidth = "75rem";

  return (
    <div>
      <HqSelectorInfo hqBaseData={hqBaseData} rootWidth={rootWidth} onChangeHq={onChangeHq} />
      {hqId ? (
        <>
          <BaseInfo hqBaseData={hqBaseData} rootWidth={rootWidth} />
          <HqDetailInfo
            onClickOpenModal={onClickOpenModal}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            hqBaseData={hqBaseData}
            hqId={hqId}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
export default HQSetting;
