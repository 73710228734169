import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import { getDefaultValue, getPermission } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import _ from "lodash";
import { hq_branch_search_init_state } from "pages/CommonModal/Search/Module/reducers/hq_branch_search_init_state";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import MemberConditionArea from "./SearchCondition/MemberConditionArea";
import SearchDetailArea from "./SearchCondition/SearchDetailArea";

const SearchCard = ({
  baseSearchParam,
  detailSearchParam,
  resetSearchParam,
  onClickSearch,
  detailOption,
  setDetailOption,
  openModal,
  setCurPage,
  onChangeDetailOption,
}) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();

  const [openMemberInfo, setOpenMemberInfo] = useState(false);
  const [openSearchDetail, setOpenSearchDetail] = useState(false);
  const [hqIdx, setHqIdx] = useState(0);
  const [baseSearchParamRef, setBaseSearchParamRef] = useState(baseSearchParam);
  const [detailSearchParamRef, setDetailSearchParamRef] = useState(detailSearchParam);
  const [hqBranchData, setHqBranchData] = useState(_.cloneDeep(hq_branch_search_init_state));
  const [resetFlag, setResetFlag] = useState(false);

  // const hqBranchData = useSelector((state) => state.search_modal.search_param_member);

  useEffect(() => {
    getPermission(storesDispatch, "SEARCH_MEMBER_MENU/SEARCH_MANAGE", true).then((res) => {
      setHqBranchData(res);
    });
  }, []);

  useEffect(() => {
    if (baseSearchParam) {
      if (hqBranchData.target_list.length > 0) {
        baseSearchParam.hq_info = hqBranchData.target_list[0].hq_info;
        if (hqBranchData.target_list[0].branch_list.length > 0) {
          baseSearchParam.branch_info = hqBranchData.target_list[0].branch_list[0];
        }
      }
      setBaseSearchParamRef(baseSearchParam);
      setHqIdx(0);
    }
  }, [baseSearchParam]);

  useEffect(() => {
    setDetailSearchParamRef(detailSearchParam);
  }, [detailSearchParam]);

  const onSelectHq = (hqName) => {
    const index = hqBranchData.hq_list.findIndex((el) => el === hqName);
    if (index !== -1) {
      setHqIdx(index);
      baseSearchParam.hq_info = hqBranchData.target_list[index].hq_info;
      setBaseSearchParamRef((prev) => {
        return {
          ...prev,
          hq_info: hqBranchData.target_list[index].hq_info,
          branch_info: hqBranchData.target_list[index].branch_list[0],
        };
      });
      baseSearchParam.branch_info = hqBranchData.target_list[index].branch_list[0];
    }
  };

  const onSelectBranch = (branchName) => {
    const index = hqBranchData.branch_list[hqIdx].findIndex((el) => el === branchName);
    if (index !== -1) {
      setBaseSearchParamRef((prev) => {
        return {
          ...prev,
          branch_info: hqBranchData.target_list[hqIdx].branch_list[index],
        };
      });
      baseSearchParam.branch_info = hqBranchData.target_list[hqIdx].branch_list[index];
    }
  };

  return (
    <>
      <CardFixed
        topDom={<div>검색 조건</div>}
        midDom={
          <div>
            <div style={{ display: "flex", justifyContent: "start", marginTop: "1rem" }}>
              <Button
                className={clsx({
                  [btnStyleClass.btnRootWhite]: true,
                  [btnStyleClass.buttonType10]: true,
                })}
                onClick={() => {
                  openModal("load", {
                    title: "검색조건 불러오기",
                    branch_id: baseSearchParamRef.branch_info.branch_id,
                  });
                }}
              >
                조건 불러오기
              </Button>
              <Button
                style={{ marginLeft: "0.25rem" }}
                className={clsx({
                  [btnStyleClass.btnRootRaw]: true,
                  [btnStyleClass.buttonType10]: true,
                })}
                onClick={() => {
                  openModal("save", { title: "검색조건 저장", condName: "" });
                }}
              >
                조건 저장
              </Button>
            </div>

            <div
              style={{ marginTop: "1rem" }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onClickSearch(false, 1);
                }
              }}
            >
              <ComboBox
                labelWidth={"2.5rem"}
                comboItemWidth="18.1rem"
                marginRight="0rem"
                labelText="본부"
                data={hqBranchData.hq_list}
                defaultValue={getDefaultValue("combo", baseSearchParamRef?.hq_info.hq_name) || ""}
                onChangeCallback={(e) => {
                  onSelectHq(e.target.value);
                }}
              />
              <SearchComboBox
                marginTop="0.5rem"
                labelWidth={"2.5rem"}
                comboItemWidth="18.1rem"
                marginRight="0rem"
                labelText="지점"
                data={hqBranchData.branch_list[hqIdx]}
                defaultValue={
                  getDefaultValue("combo", baseSearchParamRef?.branch_info.branch_name) || ""
                }
                onChangeCallback={(e, v) => {
                  onSelectBranch(v);
                }}
              />
            </div>

            <div>
              {/* 회원 정보 */}
              <div style={{ marginTop: "0.5rem", width: "21.2rem" }}>
                <MemberConditionArea
                  searchParam={baseSearchParamRef}
                  setSearchParam={setBaseSearchParamRef}
                  onClickSearch={onClickSearch}
                  resetFlag={resetFlag}
                  openMemberInfo={openMemberInfo}
                  setOpenMemberInfo={setOpenMemberInfo}
                />
              </div>

              {/* 기준별 검색 조건 */}
              <div style={{ marginTop: "0.5rem", width: "21.2rem" }}>
                <SearchDetailArea
                  searchParam={detailSearchParamRef}
                  detailOption={detailOption}
                  setDetailOption={setDetailOption}
                  openSearchDetail={openSearchDetail}
                  setOpenSearchDetail={setOpenSearchDetail}
                  onChangeDetailOption={onChangeDetailOption}
                />
              </div>
            </div>
          </div>
        }
        isLoading={false}
        rootStyle={{
          width: "22.6rem",
          maxHeight: "48rem",
          borderRadius: "10px",
          padding: "0.75rem 0.2rem 0.75rem 0.5rem",
          backgroundColor: "#fff",
          overflowY: "auto",
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          marginTop: "0.5rem",
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "0.75rem 1rem",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Button
          className={clsx({
            [btnStyleClass.btnRootWhite]: true,
            [btnStyleClass.buttonType10]: true,
          })}
          onClick={() => {
            setResetFlag(!resetFlag);
            resetSearchParam();
          }}
        >
          조건 초기화
        </Button>
        <Button
          style={{ marginLeft: "0.3rem" }}
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType10]: true,
          })}
          onClick={() => {
            // setCurPage(1);
            onClickSearch(false, 1);
          }}
        >
          검색
        </Button>
      </div>
    </>
  );
};
export default SearchCard;
