import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Collapse, IconButton as MuiIconButton } from "@mui/material";
import { restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { stat_info_state } from "pages/DailyReport/Module/reducers/stat_info_state";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import EtcConsultTable from "./EtcArea/EtcConsultTable";
import StatGxArea from "./GxArea/StatGxArea";
import StatMbshpArea from "./MbshpArea/StatMbshpArea";
import StatPtArea from "./PtArea/StatPtArea";

const DailyReportStatistics = ({ reportInfoId }) => {
  const storesDispatch = useDispatch();
  const [openState, setOpenState] = useState({
    mbshp: false,
    pt: false,
    gx: false,
  });

  const [loading, setLoading] = useState(false);
  const [reportType, setReportType] = useState("daily");
  const [reportState, setReportState] = useState(_.cloneDeep(stat_info_state));

  const getStatData = async () => {
    setLoading(true);
    HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + "/daily_report/sales/report", {
      params: {
        report_type: reportType,
        report_info_id: reportInfoId,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setReportState(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getStatData();
  }, [reportType]);

  const onClickOpenState = (name, val) => {
    setOpenState({
      ...openState,
      [name]: !val,
    });
  };

  return (
    <div>
      {loading ? <Loading /> : <></>}
      <div>
        <div>
          <ComboBox
            marginLeft="0.5rem"
            labelWidth={"5rem"}
            comboItemWidth={"15rem"}
            labelText="· 통계 범위"
            data={["당일", "당월"]}
            defaultValue={"당일"}
            onChangeCallback={(e) => {
              const val = e.target.value === "당일" ? "daily" : "monthly";
              setReportType(val);
            }}
          />
        </div>
        <div
          style={{
            marginTop: "1rem",
            display: "flex",
            justifyContent: "space-between",
            height: "3rem",
            backgroundColor: openState.mbshp ? "#4C679D" : "#E8EDF7",
          }}
          onClick={() => {
            onClickOpenState("mbshp", openState.mbshp);
          }}
        >
          <Label
            labelText="회원권"
            marginLeft="1rem"
            fontSize="1.2rem"
            fontWeight="bold"
            color={openState.mbshp ? "#fff" : "#000"}
          />
          <MuiIconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              onClickOpenState("mbshp", openState.mbshp);
            }}
          >
            {openState.mbshp ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </MuiIconButton>
        </div>
        <Collapse
          style={{ border: "1px solid #DEDEDE", padding: "1rem" }}
          in={openState.mbshp}
          timeout="auto"
          unmountOnExit
        >
          <StatMbshpArea reportState={reportState} />
        </Collapse>
      </div>

      <div>
        <div
          style={{
            marginTop: "0.5rem",
            display: "flex",
            justifyContent: "space-between",
            height: "3rem",
            backgroundColor: openState.pt ? "#4C679D" : "#E8EDF7",
          }}
          onClick={() => {
            onClickOpenState("pt", openState.pt);
          }}
        >
          <Label
            labelText="PT"
            marginLeft="1rem"
            fontSize="1.2rem"
            fontWeight="bold"
            color={openState.pt ? "#fff" : "#000"}
          />
          <MuiIconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              onClickOpenState("pt", openState.pt);
            }}
          >
            {openState.pt ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </MuiIconButton>
        </div>
        <Collapse
          style={{ border: "1px solid #DEDEDE", padding: "1rem" }}
          in={openState.pt}
          timeout="auto"
          unmountOnExit
        >
          <StatPtArea reportState={reportState} />
        </Collapse>
      </div>

      <div>
        <div
          style={{
            marginTop: "0.5rem",
            display: "flex",
            justifyContent: "space-between",
            height: "3rem",
            backgroundColor: openState.gx ? "#4C679D" : "#E8EDF7",
          }}
          onClick={() => {
            onClickOpenState("gx", openState.gx);
          }}
        >
          <Label
            labelText="GX"
            marginLeft="1rem"
            fontSize="1.2rem"
            fontWeight="bold"
            color={openState.gx ? "#fff" : "#000"}
          />
          <MuiIconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              onClickOpenState("gx", openState.gx);
            }}
          >
            {openState.gx ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </MuiIconButton>
        </div>
        <Collapse
          style={{ border: "1px solid #DEDEDE", padding: "1rem" }}
          in={openState.gx}
          timeout="auto"
          unmountOnExit
        >
          <StatGxArea reportState={reportState} />
        </Collapse>
      </div>

      {/* 등록 외 상담 테이블 */}
      <div style={{ marginTop: "1.5rem" }}>
        <EtcConsultTable reportState={reportState} />
      </div>
    </div>
  );
};
export default DailyReportStatistics;
