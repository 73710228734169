import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";

import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty } from "components/CommonLib/CommonLib";

export default class EtcItemTable {
  table_title = "";
  btnStyleClass = btnStyles();

  onClickOpenModal = null;
  branchId = "";
  viewRefresh = null;
  onClickDelete = null;

  constructor(branchId, onClickOpenModal, viewRefresh, onClickDelete) {
    this.branchId = branchId;
    this.onClickOpenModal = onClickOpenModal;
    this.viewRefresh = viewRefresh;
    this.onClickDelete = onClickDelete;
  }
  onEdit = (item_id) => {
    const param = {
      title: "기타상품 수정",
      modalParam: {
        mode: "edit",
        branchId: this.branchId,
        itemId: item_id,
        refreshStore: this.viewRefresh,
      },
    };
    this.onClickOpenModal("상품관리/기타상품", param);
  };

  create_table = (data) => {
    return (
      <CustomRow style={{ width: "100%" }}>
        <CustomTableContents>{data.base_info.order}</CustomTableContents>
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.item_name}</CustomTableContents>
        <CustomTableContents $isNumber>{data.price.toLocaleString() + " 원"}</CustomTableContents>
        <CustomTableContents>{data.base_info.sales_status}</CustomTableContents>
        <CustomTableContents>
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <IconButton type="edit" width="2.2rem" onClick={() => this.onEdit(data.item_id)} />

              {/* <Button
                className={clsx({
                  [this.btnStyleClass.btnRootRaw]: true,
                  [this.btnStyleClass.buttonType2]: true,
                })}
                onClick={() => this.onClickDelete(data.item_name, data.item_id)}
              >
                삭제
              </Button> */}
            </div>
          </div>
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "게시\n순서", width: "5%" },
    { title: "등록일시", width: "15%" },
    { title: "상품명", width: "40%" },
    { title: "상품금액", width: "15%" },
    { title: "판매상태", width: "15%" },
    { title: "" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
