import { useQuery } from "@tanstack/react-query";
import { getCashBookAggregate } from "apis/branch/cashbook_api";
import { simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import _ from "lodash";
import { cashbook_aggregate_state } from "pages/CashBook/Module/reducers/cashbook_aggregate_state";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ExpensesDetailTable from "./Tables/Details/ExpensesDetailTable";
import IncomeDetailTable from "./Tables/Details/IncomeDetailTable";
import IncomeExpensesTable from "./Tables/IncomeExpenses/IncomeExpensesTable";
import ShortTermLoanTable from "./Tables/IncomeExpenses/ShortTermLoanTable";

const CashBookAggregate = ({ cashbookId, infoStateData }) => {
  const storesDispatch = useDispatch();
  const [aggreateData, setAggregateData] = useState(_.cloneDeep([cashbook_aggregate_state]));

  // API Query
  // [GET] 수입/지출 내역(월별) 집계 내역
  const { data: cashBookAggregateData } = useQuery({
    queryKey: ["cashBook", cashbookId],
    queryFn: () => getCashBookAggregate(cashbookId),
    enabled: !!cashbookId,
    retry: false,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });

  // API 받아온 데이터 세팅
  useEffect(() => {
    if (cashBookAggregateData) {
      setAggregateData(cashBookAggregateData.data.data);
    }
  }, [cashBookAggregateData]);

  return (
    <div>
      <div>
        <Label
          labelText="수입/지출 현황"
          fontSize="1.2rem"
          fontWeight="bold"
          justifyContent="flex-start"
        />
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%", paddingRight: "1rem" }}>
            <IncomeExpensesTable
              incomeList={aggreateData.income_list}
              expenseList={aggreateData.expense_list}
            />
          </div>
          <div style={{ width: "50%", paddingLeft: "1rem" }}>
            <ShortTermLoanTable
              incomeData={aggreateData.income_list}
              expenseData={aggreateData.expense_list}
            />
          </div>
        </div>
      </div>

      <div>
        <Label
          labelText="내역 상세"
          fontSize="1.2rem"
          fontWeight="bold"
          justifyContent="flex-start"
          marginTop="2.5rem"
        />
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%", paddingRight: "1rem" }}>
            <IncomeDetailTable viewData={aggreateData.income_list} infoStateData={infoStateData} />
          </div>
          <div style={{ width: "50%", paddingLeft: "1rem" }}>
            <ExpensesDetailTable
              viewData={aggreateData.expense_list}
              infoStateData={infoStateData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CashBookAggregate;
