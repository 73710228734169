import { combineReducers } from "redux";

import { staff_search_result_info } from "pages/Staff/StaffList/Module/reducers/view_reducer";

import {
  work_info_list_info,
  staff_info,
  work_info,
  gx_fee_info,
} from "pages/Staff/StaffInfo/Module/reducers/view_reducer";

import { salary_list } from "pages/Staff/SalaryList/Module/reducers/view_reducer";

import {
  salary_info,
  salary_status,
  salary_staff_standard,
  salary_staff_mbshp,
  salary_staff_gx,
  salary_staff_pt,
  etc_salary,
  salary_class_session,
} from "pages/Staff/SalaryInfo/Module/reducers/view_reducer";

import {
  search_pt_class_hist,
  search_ot_class_hist,
} from "pages/Staff/Class/ClassHist/Module/reducers/view_reducer";

import {
  search_ot_manage_list,
  search_pt_manage_list,
  pt_manage_detail,
  class_manage_by_staff_list,
} from "pages/Staff/Class/ClassManage/Module/reducers/view_reducer";

// import { class_stat_info } from "pages/Staff/Class/ClassStat/Module/reducers/view_reducer";

export const staffServiceReducer = combineReducers({
  staff_search_result_info,
  work_info_list_info,
  staff_info,
  work_info,
  gx_fee_info,

  salary_list,

  salary_info,
  salary_status,
  salary_staff_standard,
  salary_staff_mbshp,
  salary_staff_gx,
  salary_staff_pt,
  etc_salary,
  salary_class_session,

  search_pt_class_hist,
  search_ot_class_hist,

  search_ot_manage_list,
  search_pt_manage_list,
  pt_manage_detail,
  class_manage_by_staff_list,

  // class_stat_info,
});
