import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import CloseIcon from "../../assets/icon/pop-close@3x.png";

const Popup = (props) => {
  const { isOpen, onClose, title, Content, ActionButtons, rootStyle, contentStyle, actionStyle } =
    props;

  const titleStyle = {
    height: "3rem",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "1.125rem",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#032063",
  };

  const modalClose = {
    height: "0.875rem",
    width: "0.875rem",
    // margin: "1.7655rem 0",
  };

  const bodyStyle = {
    maxHeight: "44rem",
    backgroundColor: "#f7f7f7",
    paddingTop: "1.125rem",
    paddingLeft: "1.875rem",
    paddingRight: "1.875rem",
  };

  return (
    <div style={rootStyle}>
      <Dialog
        open={isOpen}
        onClose={onClose}
        // aria-labelledby="draggable-dialog-title"
        transitionDuration={{ enter: 100, exit: 100 }}
        maxWidth={false}
      >
        {/* <DialogTitle > */}
        <div style={titleStyle}>
          {title}

          {/* close button */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              float: "right",
              width: "2.875rem",
              height: "3rem",
              cursor: "pointer",
              position: "absolute",
              right: "0",
              top: "0",
            }}
            onClick={() => onClose()}
          >
            <img style={modalClose} src={CloseIcon} alt="iPhone_01" />
          </div>
        </div>

        {/* line */}
        <div
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: "#b8b8b8",
          }}
        ></div>

        <DialogContent style={bodyStyle}>{Content}</DialogContent>
        {ActionButtons ? <DialogActions style={actionStyle}>{ActionButtons}</DialogActions> : <></>}
      </Dialog>
    </div>
  );
};

Popup.defaultProps = {};

export default Popup;
