import { takeLatest, takeEvery } from "redux-saga/effects";

import {
  CardSearchAction,
  CardDetailInfoAction,
  CardListAction,
} from "pages/AccessManage/CardManage/Module/actions/action_type";
import {
  getSearchCardListData,
  getCardInfoData,
  getCardListData,
} from "pages/AccessManage/CardManage/Module/sagas/view_sagas";

import {
  DeviceListAction,
  DeviceScheduleListAction,
  SchedulePreSetInfoAction,
  HolyDayInfoAction,
} from "pages/AccessManage/DeviceManage/Module/actions/action_type";
import {
  getDeviceListData,
  getDeviceScheduleListData,
  getSchedulePreSetInfoData,
  getScheduleHolyDayData,
} from "pages/AccessManage/DeviceManage/Module/sagas/view_sagas";

import {
  AccessLogSearchAction,
  DoorLogSearchAction,
} from "pages/AccessManage/MornitoringMange/Module/actions/action_type";
import {
  getAccessLogSearchData,
  getDoorLogSearchData,
} from "pages/AccessManage/MornitoringMange/Module/sagas/view_sagas";

export function* accessServiceSaga() {
  yield takeLatest(CardSearchAction, getSearchCardListData);
  yield takeLatest(CardDetailInfoAction, getCardInfoData);
  yield takeLatest(CardListAction, getCardListData);

  yield takeLatest(DeviceListAction, getDeviceListData);
  yield takeLatest(DeviceScheduleListAction, getDeviceScheduleListData);
  yield takeLatest(SchedulePreSetInfoAction, getSchedulePreSetInfoData);
  yield takeLatest(HolyDayInfoAction, getScheduleHolyDayData);

  yield takeLatest(AccessLogSearchAction, getAccessLogSearchData);
  yield takeLatest(DoorLogSearchAction, getDoorLogSearchData);
}
