import { HttpInstance } from "lib/HttpLib";

export const getStaffInfoData = (param) => {
  return HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/staff/get_staff_info", {
    params: {
      staff_id: param.staff_id,
    },
  });
};

export const getWorkInfoList = (param) => {
  return HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/staff/work_info_list", {
    params: {
      staff_id: param.staff_id,
    },
  });
};

export const getWorkInfoData = (param) => {
  return HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/staff/show_work_info", {
    params: {
      work_info_id: param.work_info_id,
      // hq_id: param.hq_id,
      // branch_id: param.branch_id,
    },
  });
};

export const getGxFeeInfoData = (param) => {
  return HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/staff/get_gx_fee_info", {
    params: {
      work_info_id: param.work_info_id,
      gx_fee_id: param.gx_fee_id,
    },
  });
};

const get_api = {
  getStaffInfoData,
  getWorkInfoList,
  getWorkInfoData,
  getGxFeeInfoData,
};

export default get_api;
