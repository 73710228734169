import { pay_list_state } from "./pay_list_reducer";
import { subs_pay_list_state } from "./subs_list_reducer";

import { assign_select_param_state } from "pages/ERP/MemberService/AssignChangeBranch/Assign/Module/reducers/select_reducer";
import { combineReducers } from "redux";

export const memberCommonReducer = combineReducers({
  assign_select_param_state,
  pay_list_state,
  subs_pay_list_state,
});
