import { simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";

export const newDeleteRowAPi = async (refreshFunc, storesDispatch, resourceId) => {
  try {
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    const res = await HttpInstance.delete(
      process.env.REACT_APP_BRANCH_SERVER + "/counseling/" + resourceId,
      config,
    );
    if (res.data.msg) {
      simpleAlert(storesDispatch, "ERROR", res.data.msg);
    } else {
      //성공
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          reqConfirm: true,
          bodyText: (
            <>
              <Label labelText="삭제 되었습니다." />
            </>
          ),
          onBtnOk: () => {
            console.log("OK");
            refreshFunc();
          },
        }),
      );
    }
  } catch (err) {
    console.log(err);
    simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
  }
};
