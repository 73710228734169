import { simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useState } from "react";
import { useDispatch } from "react-redux";

const DeviceAllocateModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const labelWidth = "6rem";
  const compWidth = "11rem";

  const [hqIdx, setHqIdx] = useState(0);
  const [branchIdx, setBranchIdx] = useState();

  const [deviceName, setDeviceName] = useState("");
  const [doorOpenTime, setDoorOpenTime] = useState(0);

  const [loading, setLoading] = useState(false);

  //장치할당 POST
  const enrollDevice = async () => {
    try {
      setLoading(true);

      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const info = {
        branch_info: modalParam.hqBranchList.target_list[hqIdx].branch_list[branchIdx],
        device_id: modalParam.device_id.toString(),
        device_name: deviceName,
        door_open_time: String(doorOpenTime),
        hq_info: modalParam.hqBranchList.target_list[hqIdx].hq_info,
      };
      const formData = new FormData();
      formData.append("param_info", JSON.stringify(info));
      const res = await HttpInstance.post(
        process.env.REACT_APP_ACCESS_SERVER + "/access/device_allocate",
        formData,
        config,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: <Label labelText="저장되었습니다." />,
            onBtnOk: () => {
              onModalDone(); //모달 닫기 + 메인 출입리스트 갱신
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  //유효성 검사
  const validationCheck = () => {
    if (branchIdx === undefined) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="지점을 설정해주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else if (!deviceName) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="장치명을 입력해주세요." />,
          onBtnOk: () => {},
        }),
      );
    } else if (doorOpenTime < 1 || isNaN(doorOpenTime)) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="문열림 시간을 입력해주세요." />,
          onBtnOk: () => {},
        }),
      );
    } else {
      //통과
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="저장하시겠습니까?" />
              <br />
              <Label labelText="(저장 후 약 10초의 대기 시간이 소요됩니다)" />
            </>
          ),
          onBtnYes: () => {
            enrollDevice();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  return (
    <div
      style={{
        width: "20rem",
      }}
    >
      {loading && <Loading />}

      <TextField
        marginTop={"0.75rem"}
        labelWidth={labelWidth}
        textBoxWidth={compWidth}
        labelText="장치 ID"
        defaultValue={modalParam.device_id}
        disabled
      />
      <ComboBox
        marginTop={"0.5rem"}
        labelWidth={labelWidth}
        comboItemWidth={compWidth}
        labelText="본부"
        data={modalParam.hqBranchList.hq_list}
        defaultValue={modalParam.hqBranchList.hq_list[hqIdx]}
        onChangeCallback={(e) => {
          const index = modalParam.hqBranchList.hq_list.findIndex((el) => el === e.target.value);
          if (index !== -1) {
            setHqIdx(index);
          }
        }}
      />
      <ComboBox
        marginTop={"0.5rem"}
        labelWidth={labelWidth}
        comboItemWidth={compWidth}
        labelText="지점명"
        data={modalParam.hqBranchList.branch_list[hqIdx]}
        defaultValue={modalParam.hqBranchList.branch_list[hqIdx][0]}
        onChangeCallback={(e) => {
          const index = modalParam.hqBranchList.branch_list[hqIdx].findIndex(
            (el) => el === e.target.value,
          );
          if (index !== -1) {
            setBranchIdx(index);
          }
        }}
      />
      <TextField
        marginTop={"0.5rem"}
        labelWidth={labelWidth}
        textBoxWidth={compWidth}
        labelText="장치명"
        defaultValue={deviceName}
        onChangeCallback={(e) => {
          setDeviceName(e);
        }}
      />
      <TextField
        marginTop={"0.5rem"}
        labelWidth={labelWidth}
        textBoxWidth={compWidth}
        labelText="문열림 시간(초)"
        defaultValue={doorOpenTime}
        onChangeCallback={(e) => {
          setDoorOpenTime(e);
        }}
      />
      <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
        <NormalBtn name="저장" onClick={validationCheck} />
      </div>
    </div>
  );
};
export default DeviceAllocateModal;
