import { contentTypeJson, simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useState } from "react";
import { useDispatch } from "react-redux";

//비밀번호 변경
const LockerPwChangeModal = ({ modalParam, onModalDone }) => {
  //modalParam: zone_id, locker_total_idx, member_info

  const storesDispatch = useDispatch();
  const [pw, setPw] = useState("");
  const [loading, setLoading] = useState(false);

  //비밀번호 변경 POST
  const changePW = async () => {
    try {
      const formData = new FormData();
      formData.append("zone_id", modalParam.zone_id);
      formData.append("new_pass", pw);
      formData.append("locker_total_idx", modalParam.locker_total_idx);
      formData.append("member_info", JSON.stringify(modalParam.member_info));

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_BRANCH_SERVER + "/locker/passwd_update",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        //2. 변경되었습니다 알림팝업 후 모달 닫기
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: (
              <>
                <Label labelText="락커 비밀번호가 변경되었습니다." />
              </>
            ),
            onBtnOk: () => {
              onModalDone(); //모달 닫기 + 락커상세페이지 모두 갱신 + if(락커페이지){섹션2 구락리 갱신}
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const validationCheck = () => {
    if (!pw) {
      //0.비밀번호 입력해주세요 팝업
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="변경하실 비밀번호를 입력해주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else {
      changePW();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1.2rem",
      }}
    >
      {loading && <Loading />}

      <Label labelText="새 비밀번호를 입력해주세요." />
      <TextField
        isStringNumber
        maxLength={4}
        fullWidth
        onBlur={(e) => {
          setPw(e.target.value);
        }}
      />

      <NormalBtn name="변경" onClick={validationCheck} />
    </div>
  );
};

export default LockerPwChangeModal;
