export const getIsOnlyView = (baseState) => {
  if (baseState.approval_status === "임시저장" || baseState.approval_status === "") {
    return false;
  } else {
    return true;
  }
};

export const getIsViewLock = (mode) => {
  if (mode === "view") {
    return true;
  } else if (mode === "approver") {
    return true;
  } else {
    return false;
  }
};

export const getIsViewDelete = (baseState) => {
  if (
    baseState.approval_status === "임시저장" ||
    baseState.approval_status === "반려" ||
    baseState.approval_status === "회수"
  ) {
    return true;
  } else {
    return false;
  }
};
