import { put, call } from "redux-saga/effects";
// import get_api from "../../../../../lib/LockerSettings/GetApi";
import get_api from "../api/GetApi";
import { AccessDeviceAction } from "../actions/index";
import { globalStartLoading, globalFinishLoading } from "modules/actions/Loading/index";

export function* getDeviceListData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.geDeviceList, param);

    if (data.data === null) {
      yield put(AccessDeviceAction.loadDeviceListSuccess(false));
    } else {
      yield put(AccessDeviceAction.loadDeviceListSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(AccessDeviceAction.loadDeviceListFail(error));
    yield put(globalFinishLoading());
  }
}

export function* getDeviceScheduleListData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getDeviceScheduleList, param);

    if (data.data === null) {
      yield put(AccessDeviceAction.loadDeviceScheduleListSuccess(false));
    } else {
      yield put(AccessDeviceAction.loadDeviceScheduleListSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(AccessDeviceAction.loadDeviceScheduleListFail(error));
    yield put(globalFinishLoading());
  }
}

export function* getSchedulePreSetInfoData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getDeviceSchedulePreSetInfo, param);

    if (data.data === null) {
      yield put(AccessDeviceAction.loadSchedulePreSetInfoSuccessAction(false));
    } else {
      yield put(AccessDeviceAction.loadSchedulePreSetInfoSuccessAction(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(AccessDeviceAction.loadSchedulePreSetInfoFailAction(error));
    yield put(globalFinishLoading());
  }
}

export function* getScheduleHolyDayData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getHolyDaySetInfo, param);

    if (data.data === null) {
      yield put(AccessDeviceAction.loadHolyDayInfoSuccessAction(false));
    } else {
      yield put(AccessDeviceAction.loadHolyDayInfoSuccessAction(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(AccessDeviceAction.loadHolyDayInfoFailAction(error));
    yield put(globalFinishLoading());
  }
}
