import { restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { opr_search_param } from "../Module/reducers/opr_search_param";
import DailyReportListView from "./DailyReportListView";

const DailyReportListService = () => {
  const storesDispatch = useDispatch();
  const [searchParam, setSearchParam] = useState(_.cloneDeep(opr_search_param));

  useEffect(() => {
    HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + "/daily_report/search/param", {
      params: {},
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          if (!res.data.data.combo_selector.hq_list) {
            res.data.data.combo_selector.hq_list = [];
          }
          setSearchParam(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  }, []);

  return (
    <div>
      <PageFrame
        menuActive="락커/셋팅"
        parentMenu={{ 락커: true }}
        children={
          <div>
            <PageTitle titleText="운영일지" />
            <DailyReportListView searchParam={searchParam} />
          </div>
        }
      />
    </div>
  );
};
export default DailyReportListService;

// DailyReportList
