import { SubLine } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import NumberTextField from "components/LabelInput/NumberTextField";
import RadioGroup from "components/LabelInput/RadioGroup";
import { useEffect, useState } from "react";

const ETCSetting = ({ restData, mbshpOption, isEditMode, hqPolicy, canSelectFamilyOpt }) => {
  const textLabelWidth = "6rem";
  const textBoxWidth = "10rem";
  const radioLabelWidth = "7.5rem";
  const isSubsMbshp = restData.mbshp_type === "구독형" ? true : false;
  const isOnedayMbshp = mbshpOption === "일일권" ? true : false;
  const isTimeMbshp = mbshpOption === "시간권" ? true : false;
  const isPassMbshp = mbshpOption === "패스권" ? true : false;

  const [postponeMonth, setPostponeMonth] = useState();

  const onCheckBool = (item) => {
    return item.includes("가능");
  };

  const onCheckBoolDefault = (item) => {
    if (item === true) {
      return "가능";
    } else {
      return "불가";
    }
  };

  const getPostponeCnt = () => {
    if (hqPolicy && !isEditMode) {
      const postponeCnt =
        restData.mbshp_type === "완납형"
          ? Math.floor(restData.period_month / hqPolicy.postpone_month)
          : Math.floor(restData.subs_options.subs_period_month / hqPolicy.postpone_month);
      setPostponeMonth(postponeCnt);
      restData.postpone_cnt = postponeCnt;
    }
  };

  useEffect(() => {
    getPostponeCnt();
  }, [restData.period_month, restData.subs_options.subs_period_month]);

  return (
    <div style={{}}>
      <div style={{ display: "flex", marginTop: "1.25rem", marginBottom: "0.625rem" }}>
        <Label labelText={"기타 설정"} />
      </div>
      <div style={{ marginLeft: "2rem" }}>
        <div style={{ display: "flex" }}>
          <NumberTextField
            isNumber
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· 연기 가능 횟수"
            endAdornment="회"
            minValue={0}
            maxValue={100}
            defaultValue={isEditMode ? restData.postpone_cnt : postponeMonth}
            onChangeCallback={(e) => {
              restData.postpone_cnt = e;
            }}
            disabled={isOnedayMbshp || isEditMode}
          />
        </div>
        <div style={{ display: "flex" }}>
          <NumberTextField
            isNumber
            marginTop="0.5rem"
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· OT 제공 횟수"
            endAdornment="회"
            minValue={0}
            maxValue={100}
            defaultValue={restData.ot_session}
            onChangeCallback={(e) => {
              restData.ot_session = e;
            }}
            disabled={isOnedayMbshp || isEditMode}
          />
        </div>

        <RadioGroup
          // inputRef={radioSelectorRef}
          marginTop="0.5rem"
          labelWidth={radioLabelWidth}
          data={["가능", "불가"]}
          isMulti={true}
          labelText="· 양도"
          onChangeCallback={(e, v) => {
            restData.can_assign = onCheckBool(v);
          }}
          // defaultValue={"전체 인원 : " + String(stat_total)}
          defaultValue={onCheckBoolDefault(restData.can_assign)}
          disabled={isOnedayMbshp || isEditMode || isSubsMbshp}
        />

        <RadioGroup
          // inputRef={radioSelectorRef}
          marginTop="0.5rem"
          labelWidth={radioLabelWidth}
          data={["가능", "불가"]}
          isMulti={true}
          labelText="· 가족등록"
          onChangeCallback={(e, v) => {
            // restData.base_info.can_assign = e;

            // let wh = onCheckBool(v);
            // console.log(wh);
            restData.can_family = onCheckBool(v);
          }}
          // defaultValue={"전체 인원 : " + String(stat_total)}
          defaultValue={onCheckBoolDefault(restData.can_family)}
          disabled={
            isOnedayMbshp || isPassMbshp || isTimeMbshp || isEditMode || !canSelectFamilyOpt
          }
        />

        <RadioGroup
          // inputRef={radioSelectorRef}
          marginTop="0.5rem"
          labelWidth={radioLabelWidth}
          data={["가능", "불가"]}
          isMulti={true}
          labelText="· 상품변경"
          onChangeCallback={(e, v) => {
            // restData.base_info.can_assign = e;

            // let wh = onCheckBool(v);
            // console.log(wh);
            restData.can_change = onCheckBool(v);
          }}
          // defaultValue={"전체 인원 : " + String(stat_total)}
          defaultValue={onCheckBoolDefault(restData.can_change)}
          disabled={isOnedayMbshp || isEditMode}
        />
        <div style={{ display: "flex" }}>
          <NumberTextField
            isNumber
            marginTop="0.5rem"
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· 판매 인센티브"
            endAdornment="원"
            minValue={0}
            maxValue={1000000}
            defaultValue={restData.base_info.incentive}
            onChangeCallback={(e) => {
              restData.base_info.incentive = e;
            }}
            disabled={isEditMode}
          />
        </div>
      </div>
      <SubLine />
    </div>
  );
};
export default ETCSetting;
