import * as ActionTypes from "../actions/action_type";
import { memberInitState } from "./member_state";
const initialMemberStete = {
  stores: {
    data: memberInitState,
  },
};
export const member_show_state = (state = initialMemberStete, action) => {
  switch (action.type) {
    case ActionTypes.getMemberViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getMemberViewFailAction:
    case ActionTypes.getMemberViewResetAction:
      return { ...state, stores: initialMemberStete.stores };
    default:
      return state;
  }
};
