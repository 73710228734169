import { contentTypeJson, simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import ApprovalSelectorView from "pages/CommonModal/Approval/ApprovalSelectorView";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import BookmarkApprovalLineModal from "./BookmarkApprovalLineModal";

const ChooseModal = ({ modalParam }) => {
  const viewModelRef = useRef();
  const storesDispatch = useDispatch();

  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });
  const [loading, setLoading] = useState(false);

  const draftModalDone = (data) => {
    const newInfo = {
      approval_draft_line: {
        approver: data.approval_info.approver_list,
        receiver: data.approval_info.receiver_list,
        viewer: data.approval_info.viewer_list,
      },
    };

    if (data.approval_info.approver_list.length === 0) {
      simpleAlert(storesDispatch, "알림", "결재자를 선택해 주세요.");
      return;
    }

    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: <Label labelText="기안 하시겠습니까?" />,
        onBtnYes: () => {
          suggestDraft(newInfo);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const suggestDraft = async (newInfo) => {
    try {
      const formData = new FormData();
      formData.append("salary_report_id", modalParam.salaryReportId);
      formData.append("new_info", JSON.stringify(newInfo));
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_SALARY_SERVER + "/salary/approval/draft",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: (
              <>
                <Label labelText="기안이 완료되었습니다." />
              </>
            ),
            onBtnOk: () => {
              storesDispatch(globalModalOff());
              modalParam.navigateToSalaryMain();
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const popSelectApprovalLine = () => {
    const approval_info = {
      receiver_list: [],
      approver_list: [],
      viewer_list: [],
    };
    const param = {
      approval_info,
      mode: "approve",
    };
    setModalInfo({
      show: true,
      title: "결재선 직접 선택",
      Content: <ApprovalSelectorView modalParam={param} onModalDone={draftModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const popBookmarkApprovalLineModal = () => {
    const param = {
      salaryReportId: modalParam.salaryReportId,
    };
    setModalInfo({
      show: true,
      title: "자주 쓰는 결재선 선택",
      Content: (
        <BookmarkApprovalLineModal
          modalParam={param}
          onModalDone={modalParam.navigateToSalaryMain}
        />
      ),
      callback: null,
      Buttons: undefined,
    });
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
      {loading && <Loading />}
      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />

      <NormalBtn
        name="직접 선택"
        theme="white"
        onClick={() => {
          popSelectApprovalLine();
        }}
      />

      <NormalBtn
        name="즐겨찾기"
        theme="white"
        onClick={() => {
          popBookmarkApprovalLineModal();
        }}
      />
    </div>
  );
};

export default ChooseModal;
