export const noneSelectType = {
  erp_policy: {},
  hq_policy: {},
  branch_policy: {},
  item_info: {
    item_id: "",
    item_name: "string / 상품명",
    mbshp_type: "완납형" /* prepay, subs */,
    sales_status: "string / 판매 상태",
    sales_limit: false,
    mbshp_option: "일반권" /* general, time, pass, oneday */,
    pt_price: 0,
    pt_session: 0,
    pt_per_price: 0,
    is_combine_cloth: false,
    is_combine_gx: false,
    is_combine_locker: false,
    is_combine_pt: false,
    postpone_cnt: 0,
    ot_session: 0,
    can_assign: false,
    can_family: false,
    can_change: false,
    incentive: 0,
    order: 0,
    tablet_image: "string / 태블릿 표시 이미지 파일명",
    mem_app_image: "string / 회원앱 표시 이미지 파일명",
    pass_cnt: 0,
    pass_from_time: "string / (시간권)출입 시작시간",
    pass_end_time: "string / (시간권)출입 종료시간",
    total_price: 0,
    reg_price: 0,
    total_period_month: 0,
    total_period_day: 0,
    total_period_hour: 0,
    period_month: 0,
    service_month: 0,
    service_day: 0,
    oneday_wait_hour: 0,
    mbshp_price: 0,
    cloth_price: 0,
    gx_price: 0,
    locker_price: 0,
    standard_price: {
      prepay_reg: 0,
      prepay_mbshp: 0,
      prepay_cloth: 0,
      prepay_gx: 0,
      prepay_locker: 0,
      oneday_mbshp: 0,
      oneday_cloth: 0,
      oneday_gx: 0,
      ot: 0,
      pt: 0,
    },
    subs_options: {
      subs_period_month: 0,
      pre_month: 0,
      total_subs_price: 0,
      mbshp_subs_price: 0,
      mbshp_pre_price: 0,
      cloth_subs_price: 0,
      cloth_pre_price: 0,
      gx_subs_price: 0,
      gx_pre_price: 0,
      locker_subs_price: 0,
      locker_pre_price: 0,
    },
    reg_info: {
      reg_date: "date / 등록일시",
      reg_user: "string / 등록자",
      upd_date: "date / 수정일시",
      upd_user: "string / 최근수정자",
    },
  },
};

export const initialSettingState = {
  stores: {
    data: {
      selected_branch_name: "선택없음",
      selected_branch_id: "",
      permission_branch_list: [
        {
          branch_id: "000000000000000000000000",
          branch_name: "선택해주세요",
        },
      ],

      cs_team_list: [],
      cs_team_name_list: [], //["선택없음"],
      class_team_list: [],
      class_team_name_list: ["선택없음"],

      event_month_str_list: [], //["선택"],
      event_day_str_list: [], //["선택"],

      branch_setting: {
        branch_name: "선택없음",
        branch_info: {
          branch_id: "",
          branch_name: "",
        },
        transfer_branch_list: [
          {
            branch_id: "",
          },
        ],
        base_policy: {
          prepay_reg: 0,
          prepay_mbshp: 0,
          prepay_cloth: 0,
          prepay_gx: 0,
          prepay_locker: 0,
          subs_reg: 0,
          subs_mbshp: 0,
          subs_cloth: 0,
          subs_gx: 0,
          subs_locker: 0,
          oneday_mbshp: 0,
          oneday_cloth: 0,
          oneday_gx: 0,
          ot: 0,
          pt: 0,
        },
        membership_policy: {
          use_dflt_from_date: false,
          dflt_from_date: "date / 회원권 시작일 기본값",
          use_max_wait_day: false,
          max_wait_day: 0,
          recommender_month: 0,
          recommender_day: 0,
          recommendee_month: 0,
          recommendee_day: 0,
          event_month_list: [0],
          event_day_list: [0],
          change_limit: 0,
          card_remake_fee: 0,
          mbshp_assign_fee: 0,
          pt_assign_fee: 0,
          pass_mbship_count_rate: 0,
        },
      },
      erp_setting: {
        policy: {
          regular_pay_date: "자동이체 결제일",
          branch_link_cnt: 0,
          branch_change_price: 0,
          point_give_rate: 0,
          point_use_rate: 0,
          point_min_use: 0,
          reg_path_list: [] /* 신규등록 가입경로 */,
          re_reg_path_list: [] /* 재등록 가입경로 */,
          purpose_list: [] /* 운동목적 */,
          mbshp_time_branch_list: [
            {
              branch_id: "",
            },
          ],
          mbshp_pass_branch_list: [
            {
              branch_id: "",
            },
          ],
        },
      },
      hq_setting: {
        policy: {
          mbshp_rfnd_day_price: 0,
          prepay_penalty_rate: 0,
          subs_penalty_rate: 0,
          prepay_postpone_month: 0,
          prepay_postpone_day: 0,
          subs_postpone_month: 0,
          postpone_month: 0,
          assign_option: {
            include_all: false,
            except_service: false,
            except_event: false,
            except_all: false,
          },
        },
      },

      prepay_items: {
        normal_items: [noneSelectType],
        pass_items: [noneSelectType],
        time_items: [noneSelectType],
        daily_items: [noneSelectType],
      },
      subs_items: {
        normal_items: [noneSelectType],
        pass_items: [noneSelectType],
        time_items: [noneSelectType],
        daily_items: [noneSelectType],
      },
      etc_items: [],
    },
  },
};
