import { dataEmpty } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import NumberTextField from "components/LabelInput/NumberTextField";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

const PtPriceDiscountInput = forwardRef((props, ref) => {
  const {
    baseInfo,
    standardPrice,
    payInfoListAction,
    onChangeCallback,
    paramLock,
    discountValue,
    paramUse,
    disMode,
    setPtDisPrice,
    setDisMode,
    memberPtState,
    memberPtInputState,
  } = props;
  const textBoxWidth = "10rem";
  const compWidthShort = "8.25rem";

  const checkDisabled = (nowUse) => {
    if (paramLock) {
      return true;
    } else {
      if (nowUse) {
        return false;
      } else {
        return true;
      }
    }
  };
  const [disDisabled, setDisDisabled] = useState(checkDisabled(paramUse));

  const getMaxValue = () => {
    return disMode === "총액" ? standardPrice : memberPtInputState.per_price;
  };

  useEffect(() => {
    setDisDisabled(checkDisabled(paramUse));
  }, [paramUse]);

  useImperativeHandle(ref, () => ({
    setDisabled: (nowUse) => {
      setDisDisabled(checkDisabled(nowUse));
    },
  }));

  const onLocalChangeCallback = (e) => {
    let dis = e;

    let eventId = baseInfo.event;
    if (baseInfo.eventName !== "") {
      eventId = baseInfo.eventName;
    }

    let disMultipler = 1;
    if (disMode === "회당") {
      disMultipler = baseInfo.default_session;
    }
    onChangeCallback(e);
    setPtDisPrice(e);
    payInfoListAction(
      "discount",
      eventId,
      {
        type: baseInfo.event,
        price: standardPrice,
        dis: dis * disMultipler,
        pay_order: baseInfo.payOrder,
        tab_idx: 0,
        item_type: baseInfo.item_type,
      },
      baseInfo.payOrder, // baseInfo.orderStartIdx + baseInfo.orderIdx,
    );
  };

  return (
    <div
      style={{
        display: "flex",
        float: "right",
        marginLeft: "auto",
      }}
    >
      <NumberTextField
        isNumber
        labelWidth={"1.75rem"}
        textMarginRight="0.25rem"
        textBoxWidth={textBoxWidth}
        labelText={dataEmpty(baseInfo.label) ? "금액" : baseInfo.label}
        endAdornment="원"
        defaultValue={standardPrice}
        disabled
      />

      <div style={{ marginLeft: "1.5rem" }}>
        <ComboBox
          comboItemWidth={compWidthShort}
          labelWidth={"1.75rem"}
          labelText="할인"
          data={["총액", "회당"]}
          defaultValue={disMode}
          onChangeCallback={(e) => {
            memberPtInputState.dis = 0;
            onLocalChangeCallback(0);
            setDisMode(e.target.value);
            memberPtInputState.input_type = e.target.value;
          }}
        />
      </div>
      <NumberTextField
        isNumber
        labelMarginRight="0rem"
        textMarginRight="0.25rem"
        textBoxWidth={textBoxWidth}
        maxValue={getMaxValue()}
        endAdornment="원"
        defaultValue={discountValue}
        disabled={disDisabled}
        onChangeCallback={onLocalChangeCallback}
      />
    </div>
  );
});

export default PtPriceDiscountInput;
