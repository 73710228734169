import { Button } from "@material-ui/core";
import border from "assets/icon/Border.svg";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import TextArea from "components/LabelInput/TextArea";
import TextField from "components/LabelInput/TextField";
import TimePicker from "components/LabelInput/TimePicker";
import Loading from "components/Loading/Loading/Loading";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const MakePersonalTaskModal = ({ modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const labelWidth = "4rem";
  const textBoxWidth = "23rem";
  const textBoxWidthShort = "10.6rem";
  const [isEdit, setIsEdit] = useState(modalParam.isEdit);
  const [loading, setLoading] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [info, setInfo] = useState({
    schedule_base: {
      branch_info: modalParam.searchParam.branch_info,
      hq_info: modalParam.searchParam.hq_info,
      charger_staff_info:
        modalParam.mode === "day" && modalParam.isEdit
          ? modalParam.staff
          : modalParam.searchParam.charger_staff_info,
      from_date: modalParam.isEdit ? modalParam.date : "",
      to_date: modalParam.isEdit ? modalParam.date : "",
      from_time: modalParam.isEdit ? modalParam.time : "",
      to_time: modalParam.isEdit ? modalParam.time : "",
      is_all_day: false,
      schedule_info: {
        schedule_id: "",
        schedule_name: "",
        schedule_type: modalParam.scheduleType,
        schedule_sub_type: "",
      },
    },
    sub_info: {
      memo: "",
      title: "",
    },
  });

  useEffect(() => {
    if (modalParam.searchParam.ch_staff_list) {
      const nameArr = modalParam.searchParam.ch_staff_list
        .map((el) => el.staff_name)
        .filter((name) => name !== "");
      setStaffList(nameArr);
    }
  }, [modalParam]);

  const validationCheck = (isNew) => {
    if (
      !info.schedule_base.from_date ||
      !info.schedule_base.to_date ||
      !info.schedule_base.from_time ||
      !info.schedule_base.to_time
    ) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="일시를 입력해주세요." />
            </>
          ),

          onBtnOk: () => {},
        }),
      );
    } else if (!info.schedule_base.charger_staff_info.staff_name) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="직원을 선택해주세요." />
            </>
          ),

          onBtnOk: () => {},
        }),
      );
    } else if (!info.sub_info.title) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="제목을 입력해주세요." />
            </>
          ),

          onBtnOk: () => {},
        }),
      );
    } else if (!info.sub_info.memo) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="내용을 입력해주세요." />
            </>
          ),

          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="등록 하시겠습니까?" />
            </>
          ),

          onBtnYes: () => {
            registerPersonalSchedule(isNew);
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const registerPersonalSchedule = async (isNew) => {
    try {
      const formData = new FormData();
      formData.append("is_new", isNew);
      formData.append("new_info", JSON.stringify(info));
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_STAFF_SERVER + "/schedule/personal/upsert",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공

        onModalDone(); //모달 닫기 + 현재서파로 달력 갱신
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const getScheduleInfo = async () => {
    try {
      setLoading(true);

      const res = await HttpInstance.get(
        process.env.REACT_APP_STAFF_SERVER + "/schedule/personal/view",
        {
          params: {
            schedule_id: modalParam.scheduleId,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        const result = res.data.data;
        setInfo((cur) => {
          const obj = { ...cur };
          obj.schedule_base.branch_info = result.schedule_base.branch_info;
          obj.schedule_base.hq_info = result.schedule_base.hq_info;
          obj.schedule_base.charger_staff_info = result.schedule_base.charger_staff_info;
          obj.schedule_base.from_date = result.schedule_base.from_date;
          obj.schedule_base.from_time = result.schedule_base.from_time;
          obj.schedule_base.to_date = result.schedule_base.to_date;
          obj.schedule_base.to_time = result.schedule_base.to_time;
          obj.schedule_base.schedule_info.schedule_type =
            result.schedule_base.schedule_info.schedule_type;
          obj.schedule_base.schedule_info.schedule_id =
            result.schedule_base.schedule_info.schedule_id;
          obj.schedule_base.schedule_info.schedule_sub_type =
            result.schedule_base.schedule_info.schedule_sub_type;
          obj.sub_info.title = result.sub_info.title;
          obj.sub_info.memo = result.sub_info.memo;
          obj.schedule_base.is_all_day = result.schedule_base.is_all_day;
          return obj;
        });
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  const onDeleteEvt = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="다음 스케줄을 삭제하시겠습니까?" />
            <Label
              labelText={`${modalParam.data.card_title}  - ${modalParam.data.schedule_base.charger_staff_info.staff_name}`}
            />
          </>
        ),

        onBtnYes: () => {
          deleteSchedule();
        },
        onBtnNo: () => {},
      }),
    );
  };

  const deleteSchedule = async () => {
    try {
      setLoading(true);
      const res = await HttpInstance.delete(
        process.env.REACT_APP_STAFF_SERVER + "/schedule/remove",
        {
          params: {
            schedule_id: modalParam.scheduleId,
          },
        },
      );
      if (!res.data.data) {
        onModalDone(); //모달 닫기 + 달력갱신
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!modalParam.isEdit) {
      getScheduleInfo();
    }
  }, [modalParam]);

  return (
    <div style={{ width: "30rem" }}>
      {loading && <Loading />}

      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          alt="border"
          src={border}
          style={{
            width: "1.2rem",
            height: "1.2rem",
          }}
        />

        <SubTitle titleText="일정 정보" />
      </div>

      <div style={{ display: "flex", marginTop: "0.3rem" }}>
        <DatePicker
          labelText="일시"
          labelWidth={labelWidth}
          labelMarginLeft="1rem"
          labelMarginRight="0rem"
          textBoxWidth={textBoxWidthShort}
          defaultValue={info.schedule_base.from_date}
          disabled={!isEdit || (isEdit && modalParam.mode !== "month")}
          onChangeCallback={(e) => {
            setInfo((cur) => {
              const obj = { ...cur };
              obj.schedule_base.from_date = e;
              return obj;
            });
          }}
        />
        <DatePicker
          labelText="~"
          textBoxWidth={textBoxWidthShort}
          disabled={!isEdit || (isEdit && modalParam.mode !== "month")}
          defaultValue={info.schedule_base.to_date}
          onChangeCallback={(e) => {
            setInfo((cur) => {
              const obj = { ...cur };
              obj.schedule_base.to_date = e;
              return obj;
            });
          }}
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.3rem" }}>
        <TimePicker
          labelWidth={labelWidth}
          labelMarginLeft="1rem"
          labelMarginRight="0rem"
          textBoxWidth={textBoxWidthShort}
          disabled={!isEdit || (isEdit && info.schedule_base.is_all_day)}
          defaultValue={info.schedule_base.from_time}
          onChange={(e) => {
            setInfo((cur) => {
              const obj = { ...cur };
              obj.schedule_base.from_time = e;
              return obj;
            });
          }}
        />
        <TimePicker
          labelText="~"
          textBoxWidth={"8.5rem"}
          disabled={!isEdit || (isEdit && info.schedule_base.is_all_day)}
          defaultValue={info.schedule_base.to_time}
          onChange={(e) => {
            setInfo((cur) => {
              const obj = { ...cur };
              obj.schedule_base.to_time = e;
              return obj;
            });
          }}
        />
        <CheckBox
          labelText="종일"
          marginTop="0rem"
          width="0.7rem"
          height="0.7rem"
          trasnform="scale(1)"
          ItemMarginLeft="0.15rem"
          labelMarginLeft="0.15rem"
          fontSize="0.825rem"
          labelWidth="2rem"
          disabled={!isEdit}
          defaultValue={info.schedule_base.is_all_day}
          onChangeCallback={(e) => {
            if (e.checked) {
              setInfo((cur) => {
                const obj = { ...cur };
                obj.schedule_base.from_time = "00:00";
                obj.schedule_base.to_time = "23:59";
                obj.schedule_base.is_all_day = true;
                return obj;
              });
            } else {
              setInfo((cur) => {
                const obj = { ...cur };
                obj.schedule_base.from_time = "";
                obj.schedule_base.to_time = "";
                obj.schedule_base.is_all_day = false;
                return obj;
              });
            }
          }}
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.3rem" }}>
        {modalParam.scheduleType === "휴무" ? (
          <TextField
            labelWidth={labelWidth}
            labelMarginLeft="1rem"
            labelMarginRight="0rem"
            textBoxWidth={textBoxWidth}
            labelText="직원명"
            disabled={!isEdit}
          />
        ) : (
          <ComboBox
            labelWidth={labelWidth}
            labelMarginLeft="1rem"
            labelMarginRight="0rem"
            comboItemWidth={textBoxWidth}
            labelText="직원명"
            disabled={!isEdit || (modalParam.mode === "day" && modalParam.isEdit)}
            data={staffList}
            defaultValue={info.schedule_base.charger_staff_info.staff_name}
            onChangeCallback={(e) => {
              const staff = modalParam.searchParam.ch_staff_list.find(
                (el) => el.staff_name === e.target.value,
              );
              setInfo((cur) => {
                const obj = { ...cur };
                obj.schedule_base.charger_staff_info = staff;
                return obj;
              });
            }}
          />
        )}

        {/* <TextField
          labelWidth="3rem"
          textBoxWidth="8.5rem"
          labelText="연락처"
          labelMarginLeft="1rem"
          disabled
        /> */}
      </div>

      <div style={{ display: "flex", alignItems: "center", marginTop: "0.7rem" }}>
        <img
          alt="border"
          src={border}
          style={{
            width: "1.2rem",
            height: "1.2rem",
          }}
        />

        <SubTitle titleText="개인업무 정보" />
      </div>

      {modalParam.scheduleType !== "휴무" && (
        <TextField
          labelWidth={labelWidth}
          labelText="제목"
          labelMarginLeft="1rem"
          labelMarginRight="0rem"
          textBoxWidth={textBoxWidth}
          disabled={!isEdit}
          defaultValue={info.sub_info.title}
          onBlur={(e) => {
            setInfo((cur) => {
              const obj = { ...cur };
              obj.sub_info.title = e.target.value;
              return obj;
            });
          }}
        />
      )}

      <TextArea
        marginTop="0.3rem"
        labelWidth={labelWidth}
        labelMarginLeft="1rem"
        textBoxWidth={textBoxWidth}
        labelText="내용"
        defaultValue={info.sub_info.memo}
        minRows={5}
        maxRows={5}
        fullWidth
        disabled={!isEdit}
        onBlur={(e) => {
          setInfo((cur) => {
            const obj = { ...cur };
            obj.sub_info.memo = e.target.value;
            return obj;
          });
        }}
      />

      {modalParam.isEdit ? (
        <div style={{ display: "flex", marginTop: "2rem", justifyContent: "center" }}>
          <Button
            className={clsx({
              [btnStyleClass.btnRoot]: true,
              [btnStyleClass.buttonType4]: true,
            })}
            onClick={() => {
              validationCheck(true);
            }}
          >
            등록
          </Button>
          {/* <Button
            className={clsx({
              [btnStyleClass.btnRootWhite]: true,
              [btnStyleClass.buttonType4]: true,
            })}
            onClick={() => {
             
            }}
          >
            보낼
          </Button> */}
        </div>
      ) : modalParam.scheduleType !== "휴무" ? (
        <div
          style={{ display: "flex", marginTop: "2rem", gap: "0.5rem", justifyContent: "center" }}
        >
          {!isEdit && (
            <Button
              className={clsx({
                [btnStyleClass.btnRootWhite]: true,
                [btnStyleClass.buttonType4]: true,
              })}
              onClick={() => {
                onDeleteEvt();
              }}
            >
              삭제
            </Button>
          )}

          {isEdit ? (
            <>
              <Button
                className={clsx({
                  [btnStyleClass.btnRoot]: true,
                  [btnStyleClass.buttonType4]: true,
                })}
                onClick={() => {
                  setIsEdit(false);
                  validationCheck(false);
                }}
              >
                저장
              </Button>
              {/* <Button
                className={clsx({
                  [btnStyleClass.btnRootWhite]: true,
                  [btnStyleClass.buttonType4]: true,
                })}
                onClick={() => {
                  
                }}
              >
                보낼
              </Button> */}
            </>
          ) : (
            <Button
              className={clsx({
                [btnStyleClass.btnRoot]: true,
                [btnStyleClass.buttonType4]: true,
              })}
              onClick={() => {
                setIsEdit(true);
              }}
            >
              수정
            </Button>
          )}
        </div>
      ) : (
        <div style={{ display: "flex", marginTop: "2rem", justifyContent: "center" }}>
          <Button
            className={clsx({
              [btnStyleClass.btnRoot]: true,
              [btnStyleClass.buttonType4]: true,
            })}
            onClick={() => {
              storesDispatch(globalModalOff());
            }}
          >
            확인
          </Button>
        </div>
      )}
    </div>
  );
};
export default MakePersonalTaskModal;
