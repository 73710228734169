import { takeLatest, takeEvery } from "redux-saga/effects";

import { getAssignViewAction } from "pages/ERP/MemberService/AssignChangeBranch/Assign/Module/actions/action_type";
import { getAssignViewInfoData } from "pages/ERP/MemberService/AssignChangeBranch/Assign/Module/sagas/view_sagas";

import { getChangeBranchViewAction } from "pages/ERP/MemberService/AssignChangeBranch/ChangeBranch/Module/actions/action_type";
import { getChangeBranchInfoData } from "pages/ERP/MemberService/AssignChangeBranch/ChangeBranch/Module/sagas/view_sagas";

import { getChangeItemViewAction } from "pages/ERP/MemberService/ChangeItem/Module/actions/action_type";
import { getChangeItemInfoData } from "pages/ERP/MemberService/ChangeItem/Module/sagas/view_sagas";

import { getChangePayMethodViewAction } from "pages/ERP/MemberService/ChangePayMethod/Module/actions/action_type";
import { getChangePayMethodViewInfoData } from "pages/ERP/MemberService/ChangePayMethod/Module/sagas/view_sagas";

import { getPostponeViewAction } from "pages/ERP/MemberService/Postpone/Module/actions/action_type";
import { getPostponeViewInfoData } from "pages/ERP/MemberService/Postpone/Module/sagas/view_sagas";

import { getRefundViewAction } from "pages/ERP/MemberService/Refund/Module/actions/action_type";
import { getRefundViewInfoData } from "pages/ERP/MemberService/Refund/Module/sagas/view_sagas";

import {
  getMemberViewAction,
  getPurchaseDetailModalAction,
} from "pages/ERP/MemberView/Module/actions/action_type";
import {
  getMemberShowViewData,
  getPurchaseDetailData,
} from "pages/ERP/MemberView/Module/sagas/index";

import {
  getRegisterSettings,
  getRegisterView,
} from "pages/ERP/MemberRegister/Register/Module/sagas/register_sagas";
import {
  getRegisterViewAction,
  getRegisterSettingAction,
} from "pages/ERP/MemberRegister/Register/Module/actions/action_type";

import { getUnPayViewAction } from "pages/ERP/MemberService/UnPay/Module/actions/action_type";
import { getUnPayViewInfoData } from "pages/ERP/MemberService/UnPay/Module/sagas/view_sagas";

export function* memberServiceSaga() {
  yield takeLatest(getUnPayViewAction, getUnPayViewInfoData);

  yield takeLatest(getRegisterSettingAction, getRegisterSettings);
  yield takeLatest(getRegisterViewAction, getRegisterView);

  yield takeLatest(getMemberViewAction, getMemberShowViewData);
  yield takeLatest(getPurchaseDetailModalAction, getPurchaseDetailData);

  yield takeLatest(getAssignViewAction, getAssignViewInfoData);
  yield takeLatest(getChangeBranchViewAction, getChangeBranchInfoData);

  yield takeLatest(getChangeItemViewAction, getChangeItemInfoData);
  yield takeLatest(getChangePayMethodViewAction, getChangePayMethodViewInfoData);

  yield takeLatest(getPostponeViewAction, getPostponeViewInfoData);
  yield takeLatest(getRefundViewAction, getRefundViewInfoData);
}
