import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, simpleAlert } from "components/CommonLib/CommonLib";

import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import CheckBox from "components/LabelInput/CheckBox";
import Label from "components/LabelInput/Label";
import { globalAlertOn } from "modules/actions/Alert";
import { useDispatch } from "react-redux";

const SrcMembershipInfo = ({
  dstMembershipInfoRef,
  serviceViewState,
  setIsSomeCheck,
  setIsSomePtCheck,
  assignViewState,
  setAccessMethod,
  dstPtInfoRef,
  assignDateIdx,
  setSumDay,
  checkedMbshpIds,
  setCheckedMbshpIds,
}) => {
  const storesDispatch = useDispatch();
  //체크박스가 바뀔때마다
  const onChangeCheck = (checked, idx, data, day) => {
    //1. 체크된거 selectedRows에 담자
    if (checked) {
      setCheckedMbshpIds([...checkedMbshpIds, data.membership_list.membership_id]);
      setSumDay((cur) => {
        return cur + day;
      });
    } else {
      setCheckedMbshpIds(checkedMbshpIds.filter((x) => x !== data.membership_list.membership_id));
      setSumDay((cur) => {
        return cur - day;
      });
    }

    let selectedRows = [];
    for (let i = 0; i < serviceViewState.length; i++) {
      if (serviceViewState[i].selected) {
        selectedRows.push(serviceViewState[i]);
      }
    }
    dstMembershipInfoRef.current.setSelectRow(selectedRows);
    if (selectedRows.length === 0) {
      setIsSomeCheck(false);
    } else {
      setIsSomeCheck(true);
    }

    const dstMbshpArr = dstMembershipInfoRef.current.getSelectRow();
    const dstPtArr = dstPtInfoRef.current.getSelectRow();
    if (selectedRows.length === 0 && dstMbshpArr.length === 0 && dstPtArr.length !== 0) {
      //경고
      return storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          reqConfirm: true,
          bodyText: (
            <>
              <Label labelText="양수자에게 유효한 회원권이 없습니다." fontWeight="bold" />
              <br />
              <Label labelText="회원권 구매후 양도를 진행하거나," />
              <Label labelText="양도할 회원권 선택 후 PT 상품을 선택하시기 바랍니다." />
            </>
          ),
          onBtnOk: () => {
            //양도인의 모든 회원권과 pt 체크박스 다 초기화
            assignViewState.src_member_info.pt_list.forEach(
              (el) => (el.view_info.selected = false),
            );
            serviceViewState.forEach((el) => (el.selected = false));
            dstPtInfoRef.current.setSelectRow([]);
            setIsSomePtCheck(false);
          },
        }),
      );
    }

    //2.양수자 출입방식이랑 체크박스랑 호환 체크
    const isUseSrcCard = assignViewState.src_member_info.is_assign_card;
    if (isUseSrcCard) {
      //출입방식이 양도자꺼면 지금 내 회원권 체크리스트가 다 체크되있는지 확인해야함

      const filtered = serviceViewState.filter(
        (el) =>
          el.membership_list.mbshp_type === "완납형" &&
          el.membership_list.mbshp_option === "일반권",
      );
      const result = filtered.every((el) => el.selected === true);
      if (!result) {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label
                  labelText={`회원권을 모두 양도 받는 경우에만, 양도자 카드 사용이 가능합니다. `}
                />
                <Label labelText={`양도자 카드를 사용하시려면, 회원권을 모두 선택해주세요.`} />
              </>
            ),
            onBtnOk: () => {
              assignViewState.src_member_info.is_assign_card = false;
              setAccessMethod({ method: "", cardNum: "" });
            },
          }),
        );
      }
    }
  };

  const dataInfo = new dataInfoTable(onChangeCheck, assignDateIdx, storesDispatch);

  return (
    <CustomTable
      columns_head={dataInfo.columns_head}
      table_title={dataInfo.table_title}
      rest_call={dataInfo.get_data_from_rest}
      row_render={dataInfo.create_table}
      rest_data={serviceViewState}
    />
  );
};
export default SrcMembershipInfo;

class dataInfoTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onChangeCheck = null;
  assignDateIdx = null;
  storesDispatch = null;
  constructor(onChangeCheck, assignDateIdx, storesDispatch) {
    this.onChangeCheck = onChangeCheck;
    this.assignDateIdx = assignDateIdx;
    this.storesDispatch = storesDispatch;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>
          <CheckBox
            isTable
            labelText=""
            onChangeCallback={(e) => {
              if (data.membership_list.mbshp_status === "연기") {
                simpleAlert(this.storesDispatch, "알림", "연기중인 회원권은 양도할 수 없습니다.");
                e.checked = false;
                return;
              }
              data.selected = e.checked;
              this.onChangeCheck(
                e.checked,
                idx,
                data,
                data.assign_date_list ? data.assign_date_list[this.assignDateIdx] : "",
              );
            }}
            defaultValue={data.selected}
            disabled={
              data.membership_list.mbshp_type !== "완납형" ||
              data.membership_list.mbshp_option !== "일반권"
            }
          />
        </CustomTableContents>
        <CustomTableContents>{data.membership_list.mbshp_type}</CustomTableContents>
        <CustomTableContents>{data.membership_list.mbshp_option}</CustomTableContents>
        <CustomTableContents>{data.purchase_item_list.item_info.item_name}</CustomTableContents>
        <CustomTableContents>{data.membership_list.mbshp_status}</CustomTableContents>
        <CustomTableContents>
          {data.assign_date_list ? data.assign_date_list[this.assignDateIdx] : ""}일
        </CustomTableContents>
        <CustomTableContents>
          {data.purchase_item_list.item_info.can_family ? "O" : "X"}
        </CustomTableContents>
        <CustomTableContents>
          {data.total_paid_price ? data.total_paid_price.toLocaleString() + " 원" : "0 원"}
        </CustomTableContents>
        <CustomTableContents>{data.ot_info.ot_info.remain_session_cnt + " 회"}</CustomTableContents>
        <CustomTableContents>
          {data.membership_list.cloth.is_use ? "사용" : "비사용"}
        </CustomTableContents>
        <CustomTableContents>
          {data.membership_list.gx.is_use ? "사용" : "비사용"}
        </CustomTableContents>
        <CustomTableContents>
          {data.membership_list.locker_list.length === 0
            ? "비사용"
            : `사용 (${data.membership_list.locker_list.length})`}
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "선택", width: "1%" },
    { title: "종류", width: "8%" },
    { title: "회원권 옵션", width: "8%" },
    { title: "상품명", width: "15%" },
    { title: "상태", width: "8%" },
    { title: "양도일", width: "8%" },
    { title: "가족권 여부", width: "12%" },
    { title: "총 결제금액", width: "12%" },
    { title: "잔여 OT", width: "7%" },
    { title: "운동복", width: "7%" },
    { title: "GX", width: "7%" },
    { title: "락커", width: "7%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
