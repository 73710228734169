import { takeLatest, takeEvery } from "redux-saga/effects";
import { put, call } from "redux-saga/effects";
// import get_api from "../../../../../lib/LockerSettings/GetApi";
import get_api from "../api/GetApi";
import { permissionAction } from "../actions/index";
import { globalStartLoading, globalFinishLoading } from "modules/actions/Loading/index";
import * as ActionTypes from "../actions/action_type";

export function* getAuthPermissionData(param) {
  try {
    // yield put(globalStartLoading());
    const { data } = yield call(get_api.getPermissionAuth, param);

    if (data.data === null) {
      yield put(permissionAction.loadPermissionSuccess(false));
    } else {
      yield put(permissionAction.loadPermissionSuccess(data));
    }
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(permissionAction.loadPermissionFail(error));
  }
  // yield put(globalFinishLoading());
}

export function* authPermissionSaga() {
  yield takeLatest(ActionTypes.getPermissionAction, getAuthPermissionData);
}
