import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";

const GxHistSearchResult = ({ curPage, setCurPage, totalPage, searchResult }) => {
  const searchResultList = new searchResultTable();
  return (
    <div style={{ marginTop: "1rem" }}>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
      />
      <CustomPagination curPage={curPage} doSearch={setCurPage} totalCnt={totalPage} />
    </div>
  );
};
export default GxHistSearchResult;

class searchResultTable {
  table_title = "";

  convertDayText = (idx) => {
    if (idx === 0) {
      return "일";
    } else if (idx === 1) {
      return "월";
    } else if (idx === 2) {
      return "화";
    } else if (idx === 3) {
      return "수";
    } else if (idx === 4) {
      return "목";
    } else if (idx === 5) {
      return "금";
    } else if (idx === 6) {
      return "토";
    }
  };

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.gx_info.gx_code}</CustomTableContents>
        <CustomTableContents>{data.class_name}</CustomTableContents>
        <CustomTableContents>{data.last_class_info.class_done_date}</CustomTableContents>
        <CustomTableContents>{this.convertDayText(data.schedule_week_idx)}</CustomTableContents>
        <CustomTableContents>
          {data.class_from_time} ~ {data.class_to_time}
        </CustomTableContents>
        <CustomTableContents>{data.last_class_info.staff_info.staff_name}</CustomTableContents>
        <CustomTableContents>{data.last_class_info.class_status}</CustomTableContents>
        <CustomTableContents>{data.last_class_info.comment}</CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "수업코드", width: "11%" },
    { title: "수업명", width: "15%" },
    { title: "수업일", width: "11%" },
    { title: "수업요일", width: "7%" },
    { title: "수업시간", width: "12%" },
    { title: "수업 담당자", width: "13%" },
    { title: "완료구분", width: "10%" },
    { title: "메모", width: "20%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
