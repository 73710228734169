import IconButton from "components/Button/IconButton";
import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import FavoriteDraftDetailModal from "./FavoriteDraftDetailModal";

const FavoriteDraftListModal = ({ searchParam, resetSearchParam, navigate }) => {
  const searchResultRef = useRef();
  const storesDispatch = useDispatch();

  const viewModelRef = useRef();

  const [draftLineList, setDraftLineList] = useState([]);
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });

  const getFavoriteList = () => {
    HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/approval/draft/line/list", {
      params: {},
    })
      .then((res) => {
        console.log(res);
        if (!restEmpty(res.data.data)) {
          setDraftLineList(res.data.data.line_info_list);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  useEffect(() => {
    getFavoriteList();
  }, []);

  const onModalDone = () => {
    viewModelRef.current.setClose();
  };

  const onClickRow = (data) => {
    const param = {
      mode: "approval",
      canEdit: false,
      approval_info: {
        title: data.line_title,
        approver_list: data.approver,
        viewer_list: data.viewer,
        receiver_list: data.receiver,
      },
    };

    setModalInfo({
      show: true,
      title: "자주 쓰는 결재선 보기",
      Content: <FavoriteDraftDetailModal modalParam={param} onModalDone={onModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const onClickDelete = (data) => {
    console.log(data);
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="다음 결재선을 삭제하시겠습니까?" />
            <br />
            <Label labelText={data.line_title} fontWeight="bold" marginTop="0.5rem" />
          </>
        ),
        onBtnYes: () => {
          onDelete(data.approval_line_id);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const onDelete = (id) => {
    HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/approval/draft/line/remove", {
      params: {
        approval_line_id: id,
      },
    })
      .then((res) => {
        console.log(res);
        simpleAlert(storesDispatch, "알림", "삭제되었습니다.");
        getFavoriteList();
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const searchResultList = new resultTable(onClickRow, onClickDelete);

  return (
    <>
      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />

      <div style={{ width: "50rem" }}>
        <CustomTable
          columns_head={searchResultList.columns_head}
          table_title={searchResultList.table_title}
          rest_call={searchResultList.get_data_from_rest}
          row_render={searchResultList.create_table}
          rest_data={draftLineList}
        />
      </div>
    </>
  );
};
export default FavoriteDraftListModal;

class resultTable {
  table_title = "";
  onClickRow = null;
  onClickDelete = null;

  constructor(onClickRow, onClickDelete) {
    this.onClickRow = onClickRow;
    this.onClickDelete = onClickDelete;
  }

  getStaffList = (theList) => {
    let viewerStr = "";
    for (let i = 0; i < theList.length; i++) {
      viewerStr += theList[i].staff_info.staff_name + ", ";
    }
    return viewerStr;
  };

  getList = (data) => {
    //승인
    let approvers = "결재 : " + this.getStaffList(data.approver);
    let receivers = "열람 : " + this.getStaffList(data.receiver);
    let viewers = "수신 : " + this.getStaffList(data.viewer);

    return approvers + "\n " + receivers + "\n " + viewers;
  };

  create_table = (data, idx) => {
    return (
      <CustomRow
        style={{}}
        key={idx}
        onClick={(e) => {
          if (e.target.nodeName === "DIV" || e.target.nodeName === "TD") {
            this.onClickRow(data);
          }
        }}
        hover
      >
        <CustomTableContents>{data.line_title}</CustomTableContents>
        <CustomTableContents>{this.getList(data)}</CustomTableContents>
        <CustomTableContentsEND>
          <IconButton type="delete" onClick={() => this.onClickDelete(data)} />
        </CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "결재선명", width: "40%" },
    { title: "결재선 목록", width: "50%" },
    { title: "삭제", width: "10%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
