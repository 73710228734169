import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";

const SearchResultTablePt = ({
  searchResult,
  navigate,
  curPage,
  setCurPage,
  totalPage,
  onClickSearch,
}) => {
  const searchResultList = new resultTable(navigate);

  const doSearch = (page) => {
    onClickSearch(false, page);
  };

  return (
    <div style={{ margin: "0px 1px" }}>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
        width="120rem"
      />
      <CustomPagination
        curPage={curPage}
        // pageSelection={setCurPage}
        totalCnt={totalPage}
        doSearch={doSearch}
      />
    </div>
  );
};
export default SearchResultTablePt;

class resultTable {
  table_title = "";
  onNavigate = null;

  constructor(navigate) {
    this.onNavigate = navigate;
  }

  onClickEvent = (data) => {
    // this.onNavigate("/staff_info/", {
    //   state: {
    //     staff_id: data.staff_id,
    //     is_view: true,
    //   },
    // });
  };

  create_table = (data, idx) => {
    return (
      <CustomRow
        style={{}}
        key={idx}
        // onClick={() => this.onClickEvent(data)}
        // sx={{
        //   "&.MuiTableRow-root:hover": {
        //     backgroundColor: "red",
        //   },
        // }}
        // className={this.styleClasses.MuiTableRow}
        hover
      >
        <CustomTableContents>{data.base_result.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.base_result.member_type}</CustomTableContents>
        <CustomTableContents>{data.base_result.member_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.base_result.member_info.member_name}</CustomTableContents>
        <CustomTableContents>{data.base_result.member_info.phone}</CustomTableContents>
        <CustomTableContents>{data.base_result.member_info.birth}</CustomTableContents>
        <CustomTableContents>{data.base_result.member_info.gender}</CustomTableContents>
        <CustomTableContents>{data.base_result.active_mbshp_status}</CustomTableContents>
        <CustomTableContents>{data.base_result.remain_day}</CustomTableContents>
        <CustomTableContents>{data.base_result.remain_pt_cnt}</CustomTableContents>
        <CustomTableContents>{data.sub_result.item_name}</CustomTableContents>
        <CustomTableContents>{data.sub_result.root_status}</CustomTableContents>
        <CustomTableContents>
          {data.sub_result.cur_manager.staff_info.staff_name}
        </CustomTableContents>
        <CustomTableContents>{data.sub_result.session_cnt}</CustomTableContents>
        <CustomTableContentsEND>{data.sub_result.remain_session_cnt}</CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "지점", width: "7%" },
    { title: "구분", width: "3%" },
    { title: "회원번호", width: "6%" },
    { title: "이름", width: "6%" },
    { title: "연락처", width: "6%" },
    { title: "생년월일", width: "5%" },
    { title: "성별", width: "3%" },
    { title: "회원권 상태", width: "4%" },
    { title: "회원권 전체 잔여일", width: "6%" },
    { title: "총 잔여 세션", width: "5%" },
    { title: "PT 상품명", width: "10%" },
    { title: "PT 상태", width: "4%" },
    { title: "수업 담당자", width: "6%" },
    { title: "등록 횟수", width: "4%" },
    { title: "잔여 횟수", width: "4%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
