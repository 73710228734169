import { put, call } from "redux-saga/effects";
// import get_api from "../../../../../lib/LockerSettings/GetApi";
import get_api from "../api/GetApi";
import { registerSettingAction, registerViewAction } from "../actions/index";
import { globalStartLoading, globalFinishLoading } from "modules/actions/Loading/index";

export function* getRegisterSettings(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getSettingInfoList, param);

    if (data.data === null) {
      yield put(registerSettingAction.registerSettingInfoSuccess(false));
    } else {
      yield put(registerSettingAction.registerSettingInfoSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(registerSettingAction.registerSettingInfoFail(error));
    yield put(globalFinishLoading());
  }
}

export function* getRegisterView(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getRegisterViewInfo, param);

    if (data.data === null) {
      yield put(registerViewAction.loadRegisterViewSuccess(false));
    } else {
      yield put(registerViewAction.loadRegisterViewSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(registerViewAction.loadRegisterViewFail(error));
    yield put(globalFinishLoading());
  }
}
