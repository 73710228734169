import {
  getClassHistoryViewAction,
  getCSHistoryViewAction,
  getMembershipHistoryViewAction,
  getPurchaseHistoryViewAction,
} from "pages/ERP/MemberView/Module/actions/action_type";
import {
  getClassHistoryViewData,
  getCSHistoryViewData,
  getMembershipHistoryViewData,
  getPurhcaseHistoryViewData,
} from "pages/ERP/MemberView/Module/sagas/index";
import { takeLatest } from "redux-saga/effects";

export function* memberHistorySaga() {
  yield takeLatest(getMembershipHistoryViewAction, getMembershipHistoryViewData);
  yield takeLatest(getClassHistoryViewAction, getClassHistoryViewData);
  yield takeLatest(getPurchaseHistoryViewAction, getPurhcaseHistoryViewData);
  yield takeLatest(getCSHistoryViewAction, getCSHistoryViewData);
}
