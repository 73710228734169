import IconButton from "components/Button/IconButton";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import SubTitle from "components/PageTitle/SubTitle";

const PostponeHist = ({ postponeHist, totalPage, setCurPage, curPage }) => {
  const histList = new histTable();

  return (
    <div>
      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText={"연기 이력"} />
      </div>

      <CustomTable
        columns_head={histList.columns_head}
        table_title={histList.table_title}
        rest_call={histList.get_data_from_rest}
        row_render={histList.create_table}
        rest_data={postponeHist}
      />
      <CustomPagination curPage={curPage} doSearch={setCurPage} totalCnt={totalPage} />
    </div>
  );
};

export default PostponeHist;

class histTable {
  table_title = "";

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx} style={{ whiteSpace: "pre-wrap" }}>
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.postpone_type}</CustomTableContents>
        <CustomTableContents>{data.from_date}</CustomTableContents>
        <CustomTableContents>{data.to_date}</CustomTableContents>
        <CustomTableContents>{data.stop_date}</CustomTableContents>
        <CustomTableContents>{data.postpone_day}</CustomTableContents>
        <CustomTableContents>{data.mbshp_to_date}</CustomTableContents>
        <CustomTableContents>{data.reg_info.reg_name}</CustomTableContents>
        <CustomTableContents>{data.approve_status}</CustomTableContents>
        <CustomTableContents>{data.remarks}</CustomTableContents>
        <CustomTableContents>{data.file_name}</CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "신청일", width: "8%" },
    { title: "연기 종류", width: "7%" },
    { title: "연기 시작일", width: "8%" },
    { title: "연기 종료일", width: "8%" },
    { title: "연기 중단일", width: "8%" },
    { title: "연기 일수", width: "8%" },
    { title: "연기 후 회원권\n종료일", width: "10%" },
    { title: "담당자", width: "12%" },
    { title: "장기연기\n승인상태", width: "8%" },
    { title: "비고", width: "15%" },
    { title: "파일첨부", width: "14%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
