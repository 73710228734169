import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { useLocation } from "react-router";
import CashBookInfoView from "./CashBookInfoView";

const CashBookInfoService = () => {
  const { state } = useLocation();

  return (
    <div>
      <PageFrame
        menuActive="락커/셋팅"
        parentMenu={{ 락커: true }}
        children={
          <div>
            <PageTitle titleText="수입/지출 내역" />
            <CashBookInfoView infoState={state} />
          </div>
        }
      />
    </div>
  );
};
export default CashBookInfoService;
