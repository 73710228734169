import TextField from "components/LabelInput/TextField";

const SrcPersonalInfo = ({ assigner }) => {
  const labelWidth = "3.5rem";
  const compWidth = "7.2rem";

  return (
    <div>
      <div style={{ display: "flex" }}>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          marginLeft="1.5rem"
          labelText="지점"
          defaultValue={assigner.branch_info.branch_name}
          disabled
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          marginLeft="1.5rem"
          labelText="구분"
          defaultValue={"회원"}
          disabled
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          marginLeft="1.5rem"
          labelText="출입방식"
          defaultValue={assigner.card_param.access_method}
          disabled
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          marginLeft="1.5rem"
          labelText="카드번호"
          defaultValue={assigner.card_param.card_no}
          disabled
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={"12rem"}
          textMarginRight="0rem"
          marginLeft="1.5rem"
          labelText="연락처"
          defaultValue={assigner.personal_info.phone}
          disabled
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.7rem" }}>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          marginLeft="1.5rem"
          labelText="이름"
          defaultValue={assigner.personal_info.member_name}
          disabled
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          marginLeft="1.5rem"
          labelText="회원번호"
          defaultValue={assigner.personal_info.member_no}
          disabled
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          marginLeft="1.5rem"
          labelText="생년월일"
          defaultValue={assigner.personal_info.birth}
          disabled
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          marginLeft="1.5rem"
          labelText="성별"
          defaultValue={assigner.personal_info.gender}
          disabled
        />
      </div>
    </div>
  );
};
export default SrcPersonalInfo;
