import { useAccessCardManageStore } from "pages/AccessManage/CardManage/modules/store/accessCardManageState";
import { useAccessMonitoringStore } from "pages/AccessManage/MornitoringMange/modules/store/monitoringManageState";
import { useApprovalListStore } from "pages/ApprovalManage/ApprovalList/modules/store/approvalListState";
import { useGuestBookStore } from "pages/CSManage/GuestBook/modules/store/guestBookState";
import { useSendMessageHistStore } from "pages/CSManage/SendHistory/modules/store/sendMessageHistState";
import { useTMHistStore } from "pages/CSManage/TMHistory/modules/store/tmHistState";
import { useTMListStore } from "pages/CSManage/TMList/modules/store/tmListState";
import { useUnsubsListStore } from "pages/CSManage/UnsubscribeList/modules/store/unsubsListState";
import { useCashBookStore } from "pages/CashBook/CashBookList/modules/store/cashBookState";
import { useDailyReportStore } from "pages/DailyReport/modules/store/dailyReportState";
import { useExtendMbshpHistoryStore } from "pages/ERP/MemberService/Search/ExtendHistory/modules/store/extendMbshpHistoryState";
import { useLongPostponeStore } from "pages/ERP/MemberService/Search/LongPostponeList/modules/store/longPostponeState";
import { useSubsManageStore } from "pages/ERP/MemberService/Search/SubsManage/modules/store/subsManageState";
import { useUnpaidManageStore } from "pages/ERP/MemberService/Search/UnpaidManage/modules/store/unpaidManageState";
import { useSalesRecordStore } from "pages/SalesRecord/modules/store/salesRecordState";
import { useClassHistStore } from "pages/Staff/Class/ClassHist/modules/store/classHistState";
import { useSalaryListStore } from "pages/Staff/SalaryList/modules/store/salaryListState";
import { useStaffListStore } from "pages/Staff/StaffList/modules/store/staffListState";
import { useMemberLocationStore } from "pages/Statistics/modules/store/memberLocationState";
import { useLocation } from "react-router-dom";

// Store 저장한 검색 조건 초기화
export const useResetSearchParams = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const { resetSearchParams: resetDailyReportParams } = useDailyReportStore();
  const { resetSearchParams: resetCashBookParams } = useCashBookStore();
  const { resetSearchParams: resetExtendHistParams } = useExtendMbshpHistoryStore();
  const { resetSearchParams: resetSalaryParams } = useSalaryListStore();
  const { resetSearchParams: resetStaffListParams } = useStaffListStore();
  const { resetSearchParams: resetAccessCardParams } = useAccessCardManageStore();
  const { resetSearchParams: resetLongPostponeParams } = useLongPostponeStore();
  const { resetSearchParams: resetSubsManageParams } = useSubsManageStore();
  const { resetSearchParams: resetSendMessageHistParams } = useSendMessageHistStore();
  const { resetSearchParams: resetUnsubsListParams } = useUnsubsListStore();
  const { resetSearchParams: resetGuestBookParams } = useGuestBookStore();
  const { resetSearchParams: resetTMListParams } = useTMListStore();
  const { resetSearchParams: resetTMHistParams } = useTMHistStore();
  const { resetSearchParams: resetMemberLocationParams } = useMemberLocationStore();
  const { resetAllSearchParams: resetAllAccessMonitoringParams } = useAccessMonitoringStore();
  const { resetAllSearchParams: resetAllApprovalSearchParams } = useApprovalListStore();
  const { resetAllSearchParams: resetAllSalesSearchParams } = useSalesRecordStore();
  const { resetAllSearchParams: resetAllUnpaidManageSearchParams } = useUnpaidManageStore();
  const { resetAllSearchParams: resetAllClassHistSearchParams } = useClassHistStore();

  const resetSearchParams = (el) => {
    if (currentPath !== el.menu_url) {
      switch (el.menu_url) {
        case "/daily_list":
          resetDailyReportParams();
          break;
        case "/extend_hist":
          resetExtendHistParams();
          break;
        case "/cash_book":
          resetCashBookParams();
          break;
        case "/salary_list":
          resetSalaryParams();
          break;
        case "/approval_list":
          resetAllApprovalSearchParams();
          break;
        case "/sales_record":
          resetAllSalesSearchParams();
          break;
        case "/staff_list":
          resetStaffListParams();
          break;
        case "/access_card_manage":
          resetAccessCardParams();
          break;
        case "/access_monitor":
          resetAllAccessMonitoringParams();
          break;
        case "/long_postpone_list":
          resetLongPostponeParams();
          break;
        case "/unpaid_manage":
          resetAllUnpaidManageSearchParams();
          break;
        case "/subs_manage":
          resetSubsManageParams();
          break;
        case "/send_message_hist":
          resetSendMessageHistParams();
          break;
        case "/unsubs_list":
          resetUnsubsListParams();
          break;
        case "/guest_book":
          resetGuestBookParams();
          break;
        case "/tm_list":
          resetTMListParams();
          break;
        case "/tm_hist":
          resetTMHistParams();
          break;
        case "/class_hist":
          resetAllClassHistSearchParams();
          break;
        case "/member_location_stats":
          resetMemberLocationParams();
          break;
        default:
          break;
      }
    }
  };

  const initAllSearchParams = () => {
    resetDailyReportParams();
    resetCashBookParams();
    resetExtendHistParams();
    resetSalaryParams();
    resetStaffListParams();
    resetAccessCardParams();
    resetLongPostponeParams();
    resetSubsManageParams();
    resetSendMessageHistParams();
    resetUnsubsListParams();
    resetGuestBookParams();
    resetTMListParams();
    resetTMHistParams();
    resetMemberLocationParams();
    resetAllAccessMonitoringParams();
    resetAllApprovalSearchParams();
    resetAllSalesSearchParams();
    resetAllUnpaidManageSearchParams();
    resetAllClassHistSearchParams();
  };

  return { resetSearchParams, initAllSearchParams };
};
