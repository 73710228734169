import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";

const AccessHistResult = ({ searchResult, curPage, onClickSearch, totalPage }) => {
  const searchResultList = new searchResultTable();

  const doSearch = (page) => {
    onClickSearch(false, page);
  };

  return (
    <div>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
      />
      <CustomPagination curPage={curPage} doSearch={doSearch} totalCnt={totalPage} />
    </div>
  );
};
export default AccessHistResult;

class searchResultTable {
  table_title = "";

  parseText = (text) => {
    return text.substr(0, 10) + "\n" + text.substr(11, 8);
  };

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{this.parseText(data.access_date)}</CustomTableContents>
        <CustomTableContents>{data.hq_info.hq_name}</CustomTableContents>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.door_info.door_name}</CustomTableContents>
        <CustomTableContents>{data.card_no ? data.card_no : ""}</CustomTableContents>
        <CustomTableContents>{data.card_serial_no}</CustomTableContents>
        <CustomTableContents>{data.user_group}</CustomTableContents>
        <CustomTableContents>{data.cur_user.user_name}</CustomTableContents>
        <CustomTableContents>
          {data.user_group !== "회원" ? "-" : data.cur_user.member_no}
        </CustomTableContents>
        <CustomTableContents>
          {data.user_group !== "회원" ? data.cur_user.sabun : "-"}
        </CustomTableContents>
        <CustomTableContents>{data.is_success ? "출입 성공" : "실패"}</CustomTableContents>
        <CustomTableContents>
          {data.qualification === "free_pass" ? "무료패스" : data.remarks}
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "출입일시", width: "7%" },
    { title: "본부명", width: "9%" },
    { title: "지점명", width: "10%" },
    { title: "출입문명", width: "9%" },
    { title: "카드번호", width: "6%" },
    { title: "시리얼번호", width: "8%" },
    { title: "사용자그룹", width: "7%" },
    { title: "사용자명", width: "9%" },
    { title: "회원번호", width: "8%" },
    { title: "사번", width: "6%" },
    { title: "출입여부", width: "6%" },
    { title: "비고", width: "11%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
