import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { simpleAlert } from "components/CommonLib/CommonLib";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useState } from "react";
import { useDispatch } from "react-redux";
import post_api from "../Module/api/PostApi";

const HolidayAddModal = ({ modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const [paramData, setParamData] = useState({
    date_year: modalParam.date_year,
    date: "",
    date_name: "",
  });
  const [loading, setLoading] = useState(false);

  //공휴일 저장 POST
  const addHoliday = async () => {
    try {
      setLoading(true);

      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const formData = new FormData();
      formData.append("param_info", JSON.stringify(paramData));
      let postApi = post_api.holydayAdd();
      const res = await HttpInstance.post(postApi, formData, config);
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //통과
        onModalDone();
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const onSaveEvent = async () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="저장하시겠습니까?" />
          </>
        ),
        onBtnYes: () => {
          addHoliday();
        },
        onBtnNo: () => {},
      }),
    );
  };

  return (
    <div
      style={{
        width: "18rem",
      }}
    >
      {loading && <Loading />}

      <DatePicker
        marginTop="0.75rem"
        labelWidth={"5rem"}
        labelText="날짜 선택"
        minWidth={"11rem"}
        onChangeCallback={(e) => {
          setParamData({
            ...paramData,
            date: e,
            date_year: e.slice(0, 4),
          });
        }}
      />
      <TextField
        marginTop="0.5rem"
        labelWidth={"5rem"}
        textBoxWidth={"11rem"}
        labelText="공휴일 명"
        onChangeCallback={(e) => {
          setParamData({
            ...paramData,
            date_name: e,
          });
        }}
      />
      <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
        <NormalBtn
          name="저장"
          onClick={() => {
            onSaveEvent();
          }}
        />
      </div>
    </div>
  );
};
export default HolidayAddModal;
