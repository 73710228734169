import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import UnsubsListView from "./UnsubsListView";

const UnsubsListService = () => {
  return (
    <div>
      <PageFrame
        children={
          <div>
            <PageTitle titleText="수신거부 목록" />
            <UnsubsListView />
          </div>
        }
      />
    </div>
  );
};
export default UnsubsListService;
