import { Button } from "@material-ui/core";
import failIcon from "assets/icon/check_fail.png";
import successIcon from "assets/icon/check_success.png";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import {
  checkEmail,
  contentTypeJson,
  getPhoneSubstr,
  listEmpty,
  restEmpty,
  setDefault,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import SearchEmployee from "pages/CommonModal/Search/SearchEmployee";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import post_api from "../Module/api/PostApi";
import { hqAddState } from "../Module/reducers/hq_add_state";

const ERPSetHQ = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();
  const viewModelRef = useRef();
  // const hqNameRef = useRef(setDefault(""));
  const packageInfoRef = useRef(setDefault(""));

  const [loading, setLoading] = useState(false);
  const [hqData, setHqData] = useState(modalParam.state || hqAddState);
  const [hqBranchList, setHqBranchList] = useState();
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [phone3, setPhone3] = useState("");
  const [phoneCheckStatus, setPhoneCheckStatus] = useState("");
  const [compDisabled, setCompDisabled] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });
  const [showDuplLabel, setShowDuplLabel] = React.useState("");

  // console.log(modalParam);

  const onChangeHqData = (category, name, val) => {
    if (category === "") {
      setHqData({
        ...hqData,
        [name]: val,
      });
    } else if (category === "hq_info") {
      setHqData({
        ...hqData,
        hq_info: {
          ...hqData.hq_info,
          [name]: val,
        },
      });
    } else if (category === "package_info") {
      let packId = "";
      for (let i = 0; i < modalParam.packInfoList.length; i++) {
        if (modalParam.packInfoList[i].package_info.package_name === val) {
          packId = modalParam.packInfoList[i].package_info.package_id;
          break;
        }
      }
      setHqData({
        ...hqData,
        package_info: {
          package_id: packId,
          package_name: val,
        },
      });
    } else if (category === "owner_info") {
      setHqData({
        ...hqData,
        owner_info: {
          ...hqData.owner_info,
          [name]: val,
        },
      });
    }
  };

  useEffect(() => {
    // 검색용 본부 및 지점 리스트
    HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/staff/search_modal_view", {
      params: { hq_only_add: true },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setHqBranchList(res.data.data);
        }
      })
      .catch((err) => {
        console.log("err");
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });

    // 현 hq 정보 가져오기
    HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/erp_set/hq_modal_view", {
      params: {
        hqData,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          // console.log(res.data.data);
        }
      })
      .catch((err) => {
        console.log("err");
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  }, []);

  const btnStyle = {
    marginLeft: "auto",
    display: "flex",
    justifyContent: "right",
    marginBottom: "0.5rem",
  };

  const validation = () => {
    let res = "";
    if (modalParam.mode === "add") {
      if (!hqData.hq_info.hq_name) {
        res += "· 본부명\n";
      }
      if (!hqData.owner_info.owner_name) {
        res += "· 대표자명\n";
      }
      if (!hqData.owner_info.owner_gender) {
        res += "· 성별\n";
      }
      if (!hqData.owner_info.owner_phone) {
        res += "· 연락처\n";
      }
      if (!hqData.owner_info.owner_email) {
        res += "· 이메일\n";
      }
      if (!hqData.owner_info.owner_birth) {
        res += "· 생년월일\n";
      }
      if (!hqData.found_date) {
        res += "· 등록일\n";
      }
      // if (!hqData.package_info.package_name) { res += "· ERP 패키지\n"; }
    }
    return res;
  };

  const getStaffInfo = (staffId) => {
    return HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/staff/get_staff_info", {
      params: {
        staff_id: staffId,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          console.log(res.data.data);
          return res.data.data;
        }
      })
      .catch((err) => {
        console.log("err");
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        return null;
      });
  };

  const onSearchCallbackEvent = async (recvData) => {
    const staffInfo = await getStaffInfo(recvData.staff_id);
    console.log(staffInfo);

    if (!staffInfo) {
      simpleAlert(storesDispatch, "알림", "직원정보 조회에 실패했습니다.");
      return;
    }

    if (recvData) {
      setHqData({
        ...hqData,
        owner_info: {
          owner_id: recvData.staff_id,
          owner_name: recvData.personal_info.staff_name,
          owner_gender: staffInfo.contents.staff_obj.personal_info.gender || "",
          owner_phone: recvData.personal_info.phone,
          owner_email: recvData.personal_info.email || "",
          owner_birth: staffInfo.contents.staff_obj.personal_info.birthday || "",
        },
        is_new: false,
      });
      setCompDisabled(true);
    }

    viewModelRef.current.setClose();
  };

  const onAddManager = () => {
    setModalInfo({
      show: true,
      title: "직원 검색",
      Content: <SearchEmployee modalParam={hqBranchList} onModalDone={onSearchCallbackEvent} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const onClickSave = async () => {
    if (modalParam.mode === "add") {
      setHqData({
        ...hqData,
        is_new: true,
      });
    }

    const validationRes = validation();
    if (validationRes !== "") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="필수값을 입력해 주시기 바랍니다." />
              <br />
              <br />
              <Label labelText={validationRes} whiteSpace="pre-wrap" />
            </>
          ),
          // width: "50rem",
          onBtnOk: () => {},
        }),
      );
    } else {
      const questionText = modalParam.mode === "add" ? "등록하시겠습니까?" : "저장하시겠습니까?";
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText={questionText} />
            </>
          ),
          onBtnYes: () => {
            onSaveEvent();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const onSaveEvent = async () => {
    hqData.hq_info.hq_name = hqData.hq_info.hq_name.trim();
    hqData.owner_info.owner_name = hqData.owner_info.owner_name.trim();

    const index = modalParam.packInfoList
      .map((item) => item.package_info.package_name)
      .indexOf(packageInfoRef.current.value);

    if (index === -1) {
      return;
    }

    const api = modalParam.mode === "add" ? post_api.newHQ() : post_api.updateHQ();
    const formData = new FormData();
    formData.append("info_data", JSON.stringify(hqData));

    setLoading(true);
    await HttpInstance.post(api, formData, contentTypeJson)
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          simpleAlert(storesDispatch, "알림", "저장되었습니다.");
          onModalDone(modalParam.refreshStore);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
        // onModalDone(modalParam.refreshStore);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onDonePhone = () => {
    if (phone1.length === 3 && phone2.length >= 3 && phone3.length === 4) {
      // hqData.owner_info.owner_phone = phone1 + "-" + phone2 + "-" + phone3;
      HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/hq_set/owner/check_phone", {
        params: {
          phone: phone1 + "-" + phone2 + "-" + phone3,
        },
      })
        .then((res) => {
          if (!restEmpty(res.data.data)) {
            const isDuplicate = res.data.data;
            // console.log(res.data.data);
            if (isDuplicate === "N") {
              onChangeHqData("owner_info", "owner_phone", phone1 + "-" + phone2 + "-" + phone3);
              setPhoneCheckStatus("Y");
              // baseInfoState.phone = phone1 + "-" + phone2 + "-" + phone3;
            } else {
              setPhoneCheckStatus("N");
              onChangeHqData("owner_info", "owner_phone", "");
              // baseInfoState.phone = "";
            }
          }
        })
        .catch((err) => {
          console.log("err");
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        });
    } else {
      setPhoneCheckStatus("");
    }
  };

  const checkHqName = (val) => {
    if (val) {
      onChangeHqData("hq_info", "hq_name", "");

      HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/erp_set/hq/hq_name", {
        params: {
          hq_name: val,
        },
      })
        .then((res) => {
          if (res.data.data === "N") {
            onChangeHqData("hq_info", "hq_name", val);
            setShowDuplLabel("N");
          } else {
            setShowDuplLabel("Y");
          }
        })
        .catch((err) => {
          console.log(err);
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        });
    }
  };

  const parseDate = (val) => {
    return val ? val.substring(0, 10) : "";
  };

  return (
    <div
      style={
        {
          /* width: "37.5rem",*/
        }
      }
    >
      {loading ? <Loading /> : <></>}

      {modalParam.mode === "edit" ? (
        <div style={btnStyle}>
          <Button
            className={clsx({
              [btnStyleClass.btnRoot]: true,
              [btnStyleClass.buttonType2]: true,
            })}
            onClick={onClickSave}
          >
            저장
          </Button>
        </div>
      ) : (
        <>
          <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
          <div style={btnStyle}>
            <Button
              className={clsx({
                [btnStyleClass.btnRoot]: true,
                [btnStyleClass.btnMarginRight]: true,
                [btnStyleClass.buttonType6]: true,
              })}
              onClick={() => onAddManager()}
            >
              임직원 검색
            </Button>
            <Button
              className={clsx({
                [btnStyleClass.btnRoot]: true,
                [btnStyleClass.buttonType2]: true,
              })}
              onClick={onClickSave}
            >
              등록
            </Button>
          </div>
        </>
      )}

      <div style={{ display: "flex", marginTop: "1rem" }}>
        <TextField
          required
          labelWidth={"3.5rem"}
          textBoxWidth={"13.5rem"}
          textMarginRight={"1.625rem"}
          labelText="본부명"
          // inputRef={hqNameRef}
          disabled={modalParam.mode === "edit" ? true : false}
          defaultValue={hqData.hq_info.hq_name || ""}
          // onChangeCallback={(e) => {
          //   // onChangeHqData("hq_info", "hq_id", generateObjectZeroId());
          //   onChangeHqData("hq_info", "hq_name", e);
          // }}
          onBlur={(e) => {
            checkHqName(e.target.value);
          }}
        />
        <ComboBox
          required
          labelWidth={"4.638rem"}
          comboItemWidth="13.5rem"
          inputRef={packageInfoRef}
          labelText="ERP 패키지"
          data={listEmpty(modalParam.packNameList) ? ["선택없음"] : modalParam.packNameList}
          defaultValue={hqData.package_info.package_name || ""}
          onChangeCallback={(e) => {
            onChangeHqData("package_info", "package_name", e.target.value);
          }}
        />
      </div>
      {showDuplLabel === "Y" ? (
        <div>
          <Label
            labelText="중복된 본부명이 있습니다."
            color="#c73f4b"
            fontSize="0.9rem"
            marginLeft="4.5rem"
            justifyContent="left"
          />
        </div>
      ) : (
        <></>
      )}

      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <TextField
          id="email_input"
          required={!compDisabled}
          labelWidth={"3.5rem"}
          textBoxWidth={"13.5rem"}
          labelText="이메일"
          disabled={modalParam.mode === "edit" ? true : false || compDisabled}
          defaultValue={hqData.owner_info.owner_email || ""}
          onChangeCallback={(e) => {
            onChangeHqData("owner_info", "owner_email", e);
          }}
          onBlur={(e) => {
            if (!checkEmail(e.target.value)) {
              storesDispatch(
                globalAlertOn({
                  show: true,
                  titleText: "알림",
                  bodyText: (
                    <>
                      <Label labelText="올바른 이메일 형식이 아닙니다." />
                    </>
                  ),
                  // width: "50rem",
                  onBtnOk: () => {},
                }),
              );
              document.getElementById("email_input").focus();
            } else {
              onChangeHqData("owner_info", "owner_email", e.target.value);
            }
          }}
        />

        <TextField
          isStringNumber
          required={!compDisabled}
          textMarginRight="0.25rem"
          labelWidth="4.638rem"
          textBoxWidth={"3.5rem"}
          labelMarginLeft="1rem"
          labelText="연락처"
          maxLength={3}
          defaultValue={getPhoneSubstr(hqData.owner_info.owner_phone, 0)}
          onChangeCallback={(e) => {
            setPhone1(e);
          }}
          onBlur={onDonePhone}
          disabled={modalParam.mode === "edit" ? true : false || compDisabled}
        />
        <TextField
          isStringNumber
          required={!compDisabled}
          textMarginRight="0.25rem"
          labelMarginRight="0.25rem"
          labelWidth="0.5rem"
          labelText="-"
          maxLength={4}
          textBoxWidth={"4rem"}
          defaultValue={getPhoneSubstr(hqData.owner_info.owner_phone, 1)}
          onChangeCallback={(e) => {
            setPhone2(e);
          }}
          onBlur={onDonePhone}
          disabled={modalParam.mode === "edit" ? true : false || compDisabled}
        />
        <TextField
          isStringNumber
          required={!compDisabled}
          // textMarginRight="0.25rem"
          labelMarginRight="0.25rem"
          labelWidth="0.5rem"
          labelText="-"
          maxLength={4}
          textMarginRight={"0.25rem"}
          textBoxWidth={"4rem"}
          defaultValue={getPhoneSubstr(hqData.owner_info.owner_phone, 2)}
          onChangeCallback={(e) => {
            setPhone3(e);
          }}
          onBlur={onDonePhone}
          disabled={modalParam.mode === "edit" ? true : false || compDisabled}
        />
        {phoneCheckStatus === "Y" ? (
          <img
            alt="O"
            src={successIcon}
            style={{
              display: "flex",
              width: "2.2rem",
              height: "2.2rem",
              marginRight: "0.5rem",
            }}
          />
        ) : phoneCheckStatus === "N" ? (
          <div style={{ display: "flex" }}>
            <img
              alt="O"
              src={failIcon}
              style={{
                display: "flex",
                width: "2.2rem",
                height: "2.2rem",
                marginRight: "0.5rem",
              }}
            />
            <Label
              labelText="중복된 연락처가 있습니다."
              justifyContent="left"
              color="#bb2828"
              fontSize="0.85rem"
            />
          </div>
        ) : (
          <></>
        )}
      </div>

      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <TextField
          required={!compDisabled}
          labelWidth={"3.5rem"}
          textBoxWidth={"13.5rem"}
          textMarginRight={"1.625rem"}
          labelText="대표자명"
          disabled={modalParam.mode === "edit" ? true : false || compDisabled}
          defaultValue={hqData.owner_info.owner_name || ""}
          onChangeCallback={(e) => {
            onChangeHqData("owner_info", "owner_name", e);
          }}
        />
        <ComboBox
          required
          labelWidth={"4.638rem"}
          comboItemWidth={"13.5rem"}
          labelText="성별"
          data={["남", "여"]}
          disabled={modalParam.mode === "edit" ? true : false || compDisabled}
          defaultValue={hqData.owner_info.owner_gender || ""}
          onChangeCallback={(e) => {
            onChangeHqData("owner_info", "owner_gender", e.target.value);
          }}
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <DatePicker
          required
          // inputRef={tmTryTimeRef}
          labelWidth={"3.5rem"}
          minWidth={"13.5rem"}
          labelText="생년월일"
          disabled={modalParam.mode === "edit" ? true : false || compDisabled}
          defaultValue={hqData.owner_info.owner_birth || ""}
          onChangeCallback={(e) => {
            onChangeHqData("owner_info", "owner_birth", e);
          }}
        />

        <DatePicker
          marginLeft="1rem"
          required
          labelWidth={"4.638rem"}
          minWidth={"13.5rem"}
          labelText="등록일"
          disabled={modalParam.mode === "edit" ? true : false}
          defaultValue={parseDate(hqData.reg_info.created_at) || ""}
          onChangeCallback={(e) => {
            onChangeHqData("", "found_date", e);
          }}
        />
      </div>
    </div>
  );
};
export default ERPSetHQ;
