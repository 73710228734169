import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import ClassHistWrapper from "./ClassHistWrapper";

const ClassHist = () => {
  return (
    <PageFrame
      children={
        <div>
          <PageTitle titleText="수업이력" />
          <ClassHistWrapper />
        </div>
      }
    />
  );
};
export default ClassHist;
