import border from "assets/icon/Border.svg";
import CardFixed from "components/Card/CardFixed";
import { listEmpty, restEmpty, simpleAlert, SubLine } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import PriceDiscountInput from "pages/ERP/MemberRegister/Common/Etc/PriceDiscountInput";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch } from "react-redux";

const ChangeBranchContents = forwardRef((props, ref) => {
  const {
    payInfoListAction,
    serviceViewState,
    selectedBranch,
    setSelectedBranch,
    branchChangePrice,
    setBranchChangePrice,
  } = props;

  const storesDispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [csInfo, setCsInfo] = useState({
    cs_team_info_list: [],
    cs_team_name_list: [],
  });

  const myItemsInfo = new myItemsTable();

  useImperativeHandle(ref, () => ({
    searchRun: () => {
      // storesDispatch(loadSearchMemberStore(""));
    },
  }));

  useEffect(() => {
    setCsInfo(() => {
      return { cs_team_info_list: [], cs_team_name_list: [] };
    });
    if (selectedBranch) {
      // 해당 지점에 알맞은 상담직원리스트 GET
      getStaffListOfBranch();
    }
  }, [selectedBranch]);

  //
  const getStaffListOfBranch = async () => {
    try {
      // console.log(selectedBranch);
      const res = await HttpInstance.get(
        process.env.REACT_APP_USER_SERVER + "/change/branch_view/cs_staff",
        {
          params: {
            branch_id: selectedBranch.branch_id,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setCsInfo(res.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  const selectBranch = async (branch) => {
    let resValue = false;
    setLoading(true);

    HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/change/branch_check", {
      params: {
        member_branch_id: serviceViewState.current_branch_info.branch_id,
        branch_id: branch.branch_id,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          if (res.data.data) {
            // 지점변경 가능 지점
            setSelectedBranch(branch);
            serviceViewState.select_branch_info = branch;
            resValue = true;
          } else {
            // 지점변경 불가 지점
            const emptyBranch = {
              branch_id: "",
              branch_name: "",
            };
            setSelectedBranch(emptyBranch);
            serviceViewState.select_branch_info = emptyBranch;

            const alertText =
              "[" +
              serviceViewState.current_branch_info.branch_name +
              "]" +
              "에서 [" +
              branch.branch_name +
              "](으)로의 지점변경은 불가합니다";
            simpleAlert(storesDispatch, "알림", alertText);
            resValue = false;
          }
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      })
      .finally(() => {
        setLoading(false);
      });

    return resValue;
  };

  return (
    <div>
      {loading ? <Loading /> : <></>}

      <CardFixed
        topDom={
          <div style={{ display: "flex", marginTop: "0.25rem" }}>
            <div>지점변경</div>
            <div style={{ marginLeft: "auto" }}>
              <ComboBox
                required
                marginRight="0rem"
                labelWidth={"3.838rem"}
                labelMarginLeft={"1.5rem"}
                comboItemWidth={"9rem"}
                labelText="상담자"
                data={csInfo.cs_team_name_list}
                onOpen={(e) => {
                  if (csInfo.cs_team_info_list && csInfo.cs_team_info_list.length === 0) {
                    simpleAlert(storesDispatch, "알림", "이동 지점을 먼저 선택해 주세요.");
                  }
                }}
                onChangeCallback={(e) => {
                  const found = csInfo.cs_team_info_list.find(
                    (el) => el.staff_name === e.target.value,
                  );
                  const staffInfo = {
                    staff_id: found.staff_id,
                    staff_name: found.staff_name,
                    staff_pos: found.position.org_name,
                    staff_rank: found.rank.org_name,
                  };
                  serviceViewState.cs_staff = staffInfo;
                }}
              />
            </div>
          </div>
        }
        midDom={
          <div style={{ marginTop: "1rem" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  alt="border"
                  src={border}
                  style={{
                    width: "1.2rem",
                    height: "1.2rem",
                  }}
                />

                <SubTitle titleText="이동 상품 목록" />
              </div>

              <Label
                labelText="※ 시간권, 패스권을 사용 중이거나 가족등록이 되어있으면 지점변경이 불가합니다."
                color="red"
                fontSize="0.7rem"
              />
            </div>

            <div>
              <CustomTable
                columns_head={myItemsInfo.columns_head}
                table_title={myItemsInfo.table_title}
                rest_call={myItemsInfo.get_data_from_rest}
                row_render={myItemsInfo.create_table}
                rest_data={serviceViewState.item_list}
              />
            </div>

            <SubLine />

            <div
              style={{
                display: "flex",
                marginTop: "0.625rem",
              }}
            >
              <ComboBox
                required
                labelWidth={"3.813rem"}
                comboItemWidth={"9rem"}
                labelText="이동 지점"
                defaultValue={selectedBranch.branch_name}
                data={serviceViewState.target_branch_name_list}
                disabled={serviceViewState.target_branch_name_list.length === 0}
                onChangeCallback={(e) => {
                  const foundBranch = serviceViewState.target_branch_list.find(
                    (el) => el.branch_name === e.target.value,
                  );

                  selectBranch(foundBranch);
                }}
              />

              <div
                style={{
                  display: "flex",
                  float: "right",
                  marginLeft: "auto",
                  alignItems: "center",
                }}
              >
                <PriceDiscountInput
                  baseInfo={{
                    tabTitle: "",
                    tabIdx: 0,
                    orderStartIdx: 0,
                    type: "지점변경비",
                    typeKey: "지점변경비",
                    orderIdx: 0,
                    payOrder: 6,
                    item_type: "지점변경비/없음/없음/없음/없음",
                  }}
                  standardPrice={branchChangePrice.price}
                  payInfoListAction={payInfoListAction}
                  onChangeCallback={(e) => {
                    setBranchChangePrice({ ...branchChangePrice, dis: e });
                  }}
                  paramLock={false}
                  paramUse={true}
                  discountValue={branchChangePrice.dis}
                />
              </div>
            </div>
          </div>
        }
        isLoading={false}
        rootStyle={{
          width: "76.88rem",
          paddingBottom: "1.4rem",
          // width: "80.437rem",
          // height: "55rem",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />
    </div>
  );
});
export default ChangeBranchContents;

class myItemsTable {
  table_title = "";

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.mbshp_type}</CustomTableContents>
        <CustomTableContents>{data.mbshp_option}</CustomTableContents>
        <CustomTableContents>{data.item_name}</CustomTableContents>
        <CustomTableContents>{data.period}</CustomTableContents>
        <CustomTableContents>{data.price.toLocaleString() + " 원"}</CustomTableContents>
        <CustomTableContents>{data.status}</CustomTableContents>
        <CustomTableContents>
          {/* 구독형만 보여주기 */}
          {data.mbshp_type === "구독형" ? data.period : "-"}
        </CustomTableContents>
        <CustomTableContents>
          {data.mbshp_type === "구독형" ? data.total_price.toLocaleString() + " 원" : "-"}
        </CustomTableContents>
        <CustomTableContents>
          {data.mbshp_type === "구독형" ? data.month_price : "-"}
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "회원권 종류", width: "8%" },
    { title: "회원권 옵션", width: "8%" },
    { title: "상품명", width: "16%" },
    { title: "유효기간", width: "17%" },
    { title: "결제 금액", width: "10%" },
    { title: "상태", width: "6%" },
    { title: "약정기간", width: "17%" },
    { title: "약정총액", width: "8.5%" },
    { title: "월 결제금액", width: "8.5%" },
  ];
  get_data_from_rest = (stateData) => {
    if (listEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
