export const sideMenuItems = {
  board_menu: {
    menu_title: "게시판",
    menu_url: "/test1",
    menu_active: true,
    children: [
      {
        menu_title: "공지사항",
        menu_url: "/notice",
        menu_active: true,
      },
      {
        menu_title: "전달사항",
        menu_url: "/staff_dm",
        menu_active: true,
      },
      {
        menu_title: "자료실",
        menu_url: "/reference",
        menu_active: true,
      },
    ],
  },
  schedule_menu: {
    menu_title: "스케줄",
    menu_url: "/schedule",
    menu_active: true,
  },
  class_menu: {
    menu_title: "수업관리",
    menu_url: "/",
    menu_active: true,
    children: [
      {
        menu_title: "수업 관리",
        menu_url: "/class_manage",
        menu_active: true,
      },
      {
        menu_title: "수업 이력",
        menu_url: "/class_hist",
        menu_active: true,
      },
      {
        menu_title: "PT팀 관리",
        menu_url: "/manage_pt_team",
        menu_active: true,
      },
    ],
  },
  locker_menu: {
    menu_title: "락커관리",
    menu_url: "/locker_service",
    menu_active: true,
  },
  data_menu: {
    menu_title: "조회",
    menu_url: "/",
    menu_active: true,
    children: [
      {
        menu_title: "회원 조회",
        menu_url: "/search_member",
        menu_active: true,
      },
      {
        menu_title: "미납금 관리",
        menu_url: "/unpaid_manage",
        menu_active: true,
      },
      {
        menu_title: "장기연기 리스트",
        menu_url: "/long_postpone_list",
        menu_active: true,
      },
      {
        menu_title: "정기결제 관리",
        menu_url: "/subs_manage",
        menu_active: true,
      },
      {
        menu_title: "기간 추가 현황",
        menu_url: "/extend_hist",
        menu_active: true,
      },
    ],
  },
  branch_menu: {
    menu_title: "지점관리",
    menu_url: "/",
    menu_active: true,
    children: [
      {
        menu_title: "직원관리",
        menu_url: "/staff_list",
        menu_active: true,
      },
      {
        menu_title: "급여 관리",
        menu_url: "/salary_list",
        menu_active: true,
      },
      {
        menu_title: "운영일지",
        menu_url: "/daily_list",
        menu_active: true,
      },
      {
        menu_title: "수입/지출 내역",
        menu_url: "/cash_book",
        menu_active: true,
      },
      {
        menu_title: "미지출 내역",
        menu_url: "/unspent",
        menu_active: true,
      },
      {
        menu_title: "회원 위치 통계",
        menu_url: "/member_location_stats",
        menu_active: true,
      },
    ],
  },
  approval_menu: {
    menu_title: "전자결재",
    menu_url: "/",
    menu_active: false,
  },
  cs_menu: {
    menu_title: "CS관리",
    menu_url: "/cs_manage",
    menu_active: true,
    children: [
      {
        menu_title: "문자 발송",
        menu_url: "/send_message",
        menu_active: true,
      },
      {
        menu_title: "발송 이력",
        menu_url: "/send_message_hist",
        menu_active: true,
      },
      {
        menu_title: "수신거부 목록",
        menu_url: "/unsubs_list",
        menu_active: true,
      },
      {
        menu_title: "방명록",
        menu_url: "/guest_book",
        menu_active: true,
      },
      {
        menu_title: "TM 리스트",
        menu_url: "/tm_list",
        menu_active: true,
      },
      {
        menu_title: "TM 이력",
        menu_url: "/tm_hist",
        menu_active: true,
      },
    ],
  },
  access_menu: {
    menu_title: "출입관리",
    menu_url: "/cs_manage",
    menu_active: true,
    children: [
      {
        menu_title: "단말기 관리",
        menu_url: "/access_device_manage",
        menu_active: true,
      },
      {
        menu_title: "카드 관리",
        menu_url: "/access_card_manage",
        menu_active: true,
      },
      {
        menu_title: "출입문 모니터링",
        menu_url: "/access_monitor",
        menu_active: true,
      },
    ],
  },
};
