import { simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";

import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { useExtendHistory } from "hooks/apis/branch/useExtendMbshp";
import {
  useMonthlySales,
  useRefreshVaultCash,
  useTransferReq,
  useUnpaidExclusion,
  useUnpaidState,
  useVaultCash,
} from "hooks/apis/salary/useDailyReport";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AddVaultCashModal from "./AddVaultCashModal";
import BranchLinkTable from "./Tables/ChangeBranch/BranchLinkTable";
import ChangeBranchTable from "./Tables/ChangeBranch/ChangeBranchTable";
import SmsAuthTable from "./Tables/ChangeBranch/SmsAuthTable";
import ExtendMbshpTable from "./Tables/ExtendMbshp/ExtendMbshpTable";
import RefundListTable from "./Tables/Refund/RefundListTable";
import TransferListTable from "./Tables/Refund/TransferListTable";
import DailyCountTable from "./Tables/Sales/DailyCountTable";
import DailyPriceTable from "./Tables/Sales/DailyPriceTable";
import MonthlyCountTable from "./Tables/Sales/MonthlyCountTable";
import MonthlyPriceTable from "./Tables/Sales/MonthlyPriceTable";
import UnpaidExemptTable from "./Tables/Unpaid/UnpaidExemptTable";
import UnpaidMemberTable from "./Tables/Unpaid/UnpaidMemberTable";
import VaultCashTable from "./Tables/VaultCash/VaultCashTable";

const DailyReportStatus = ({ reportState, isAdmin }) => {
  const storesDispatch = useDispatch();
  const [monthlySalesState, setMonthlySalesState] = useState([]);
  const [unpaidState, setUnpaidState] = useState([]);
  const [unpaidExpState, setUnpaidExpState] = useState([]);
  const [transferListState, setTransferListState] = useState([]);
  const [vaultCashListState, setVaultCashListState] = useState([]);
  const [extendMbshpState, setExtendMbshpState] = useState([]);
  const [cashValutTotalState, setCashValutTotalState] = useState(0);

  // API Query
  // [GET] 운영일지 관련 API 데이터들
  const { data: extendMbshpData } = useExtendHistory(reportState);
  const { data: monthlySalesData } = useMonthlySales(reportState);
  const { data: unpaidStateData } = useUnpaidState(reportState);
  const { data: unpaidExclusionData } = useUnpaidExclusion(reportState);
  const { data: transferReqData } = useTransferReq(reportState);
  const { data: vaultCashData } = useVaultCash(reportState);
  const { mutate: mutateRefreshVaultCash, isLoading: isLoadingRefreshVaultCash } =
    useRefreshVaultCash(reportState);

  // API 받아온 데이터 세팅
  useEffect(() => {
    if (extendMbshpData) setExtendMbshpState(extendMbshpData.data.data);
    if (monthlySalesData) setMonthlySalesState(monthlySalesData.data.data);
    if (unpaidStateData) setUnpaidState(unpaidStateData.data.data);
    if (unpaidExclusionData) setUnpaidExpState(unpaidExclusionData.data.data);
    if (transferReqData) setTransferListState(transferReqData.data.data);
    if (vaultCashData) {
      setVaultCashListState(vaultCashData.data.data);
      setCashValutTotalState(vaultCashData.data.total_amount);
    }
  }, [
    extendMbshpData,
    monthlySalesData,
    unpaidStateData,
    unpaidExclusionData,
    transferReqData,
    vaultCashData,
  ]);

  const onModalDone = () => {
    storesDispatch(globalModalOff());
  };

  const openModal = () => {
    const Modal = <AddVaultCashModal reportState={reportState} onModalDone={onModalDone} />;
    storesDispatch(
      globalModalOn({
        show: true,
        title: "시재금 추가",
        Content: Modal,
      }),
    );
  };

  const onClickAddVaultCash = () => {
    openModal();
  };

  const onClickRefreshVaultCash = () => {
    simpleAlert(storesDispatch, "알림", "시재금을 새로고침 하시겠습니까?", false, undefined, () => {
      mutateRefreshVaultCash();
    });
  };

  return (
    <div>
      {isLoadingRefreshVaultCash && <Loading />}

      <div>
        <Label
          labelText="판매 현황"
          fontSize="1.2rem"
          fontWeight="bold"
          justifyContent="flex-start"
        />
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%", paddingRight: "1rem" }}>
            <DailyPriceTable viewData={reportState.branch_summary_info} />
          </div>
          <div style={{ width: "50%", paddingLeft: "1rem" }}>
            <MonthlyPriceTable viewData={monthlySalesState} />
          </div>
        </div>

        <div style={{ display: "flex", marginTop: "1rem" }}>
          <div style={{ width: "50%", paddingRight: "1rem" }}>
            <DailyCountTable viewData={reportState.branch_summary_info} />
          </div>
          <div style={{ width: "50%", paddingLeft: "1rem" }}>
            <MonthlyCountTable viewData={monthlySalesState} />
          </div>
        </div>
      </div>

      <div style={{ marginTop: "3rem" }}>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%", paddingRight: "1rem" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Label
                labelText="시재금 현황"
                fontSize="1.2rem"
                fontWeight="bold"
                justifyContent="flex-start"
              />
              <div style={{ display: "flex" }}>
                {isAdmin && (
                  <>
                    <NormalBtn name="추가" theme="red" onClick={() => onClickAddVaultCash()} />
                    <NormalBtn
                      name="새로고침"
                      margin="0 0 0 0.5rem"
                      onClick={() => onClickRefreshVaultCash()}
                    />
                    {/* <NormalBtn
                      margin="0 0 0 0.5rem"
                      name="삭제"
                      onClick={() => onClickDeleteVaultCash()}
                    /> */}
                  </>
                )}
              </div>
            </div>

            <VaultCashTable viewData={vaultCashListState} totalPrice={cashValutTotalState} />
          </div>
          <div style={{ width: "50%", paddingLeft: "1rem" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Label
                labelText="회원권 기간 추가 현황"
                fontSize="1.2rem"
                fontWeight="bold"
                justifyContent="flex-start"
              />
              <NormalBtn name="투명" theme="hidden" onClick={() => {}} />
            </div>
            <ExtendMbshpTable viewData={extendMbshpState} />
          </div>
        </div>
      </div>

      <div style={{ marginTop: "3rem" }}>
        <Label
          labelText="미납금 현황"
          fontSize="1.2rem"
          fontWeight="bold"
          justifyContent="flex-start"
        />
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%", paddingRight: "1rem" }}>
            <UnpaidMemberTable viewData={unpaidState} />
          </div>
          <div style={{ width: "50%", paddingLeft: "1rem" }}>
            <UnpaidExemptTable viewData={unpaidExpState} />
          </div>
        </div>
      </div>

      <div style={{ marginTop: "3rem" }}>
        <Label
          labelText="환불 현황"
          fontSize="1.2rem"
          fontWeight="bold"
          justifyContent="flex-start"
        />
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%", paddingRight: "1rem" }}>
            <RefundListTable viewData={reportState.refund_record_list} />
          </div>
          <div style={{ width: "50%", paddingLeft: "1rem" }}>
            <TransferListTable viewData={transferListState} />
          </div>
        </div>
      </div>

      <div style={{ marginTop: "3rem" }}>
        <Label
          labelText="지점 변경/연동 현황"
          fontSize="1.2rem"
          fontWeight="bold"
          justifyContent="flex-start"
        />
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%", paddingRight: "1rem" }}>
            <ChangeBranchTable viewData={reportState.branch_change_list} />
          </div>
          <div style={{ width: "50%", paddingLeft: "1rem" }}>
            <BranchLinkTable viewData={reportState.other_branch_access_list} />
          </div>
        </div>

        <div style={{ display: "flex", marginTop: "2rem" }}>
          <div style={{ width: "50%", paddingRight: "1rem" }}>
            <SmsAuthTable viewData={reportState.auth_hist} />
          </div>
          <div style={{ width: "50%", paddingLeft: "1rem" }}></div>
        </div>
      </div>
    </div>
  );
};
export default DailyReportStatus;
