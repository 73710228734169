import { Button } from "@material-ui/core";
import failIcon from "assets/icon/check_fail.png";
import successIcon from "assets/icon/check_success.png";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import {
  checkEmail,
  checkIdFormat,
  generateObjectZeroId,
  getDefaultValue,
  getPhoneSubstr,
  numberKeyValidation,
  restEmpty,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import FileUpload from "components/LabelInput/FileUpload";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import Popup from "components/ModalPopup/Popup";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import React, { useEffect, useRef, useState } from "react";
import DaumPostcode from "react-daum-postcode";
import { useDispatch } from "react-redux";
import PasswordChangeModal from "./PasswordChangeModal";
import PhotoComponent from "./PhotoComponent";
import { SearchConditionBox, SearchRowBox } from "./style";

const PersonalInfoComp = ({
  mode,
  staffInfoState,
  onSaveEvent,
  onClickOpenModal,
  handleFileUpload,
}) => {
  const btnStyleClass = btnStyles();
  const isViewMode = staffInfoState.contents.staff_obj.staff_id !== generateObjectZeroId();
  const storesDispatch = useDispatch();

  const [canEdit, setCanEdit] = useState(
    staffInfoState.permission.stff_info_edit.value && mode !== "approval",
  );

  useEffect(() => {
    setCanEdit(staffInfoState.permission.stff_info_edit.value && mode !== "approval");
  }, [staffInfoState.permission.stff_info_edit.value]);

  return (
    <CardFixed
      topDom={
        <div style={{ display: "flex" }}>
          기본정보
          {mode === "approval" ? (
            <></>
          ) : (
            <div
              style={{
                display: "flex",
                float: "right",
                marginLeft: "auto",
              }}
            >
              <Button
                className={clsx({
                  [btnStyleClass.btnRoot]: true,
                  [btnStyleClass.buttonType6]: true,
                })}
                onClick={() => onSaveEvent()}
              >
                저장
              </Button>
            </div>
          )}
        </div>
      }
      midDom={
        <div style={{ display: "flex", marginTop: "1.25rem" }}>
          {mode !== "approval" ? (
            <div style={{ width: "15%" }}>
              <PhotoComponent
              // inputRef={personalInfoRefs.photo}
              // staff_id={getData !== null ? getData.id : ""}
              // branch_id={getData !== null ? getData.hr_info.branch_info.branch_id : ""}
              // photo_file={getData !== null ? getData.personal_info.photo_file : ""}
              />
            </div>
          ) : (
            <></>
          )}
          <div style={{ width: "85%", marginLeft: "1rem" }}>
            <SearchCardRow1
              staffInfoState={staffInfoState.contents.staff_obj}
              canEdit={!canEdit}
              isViewMode={isViewMode}
            />
            <SearchCardRow2
              staffInfoState={staffInfoState.contents.staff_obj}
              canEdit={!canEdit}
              isViewMode={isViewMode}
            />
            <SearchCardRow3
              staffInfoState={staffInfoState.contents.staff_obj}
              canEdit={!canEdit}
              isViewMode={isViewMode}
              staffInfoRoot={staffInfoState}
            />
            <SearchCardRow4
              staffInfoState={staffInfoState.contents.staff_obj}
              canEdit={!canEdit}
              isViewMode={isViewMode}
            />
            <SearchCardRow5
              staffInfoState={staffInfoState.contents.staff_obj}
              canEdit={!canEdit}
              isViewMode={isViewMode}
              storesDispatch={storesDispatch}
              onClickOpenModal={onClickOpenModal}
              staffInfoRoot={staffInfoState}
              handleFileUpload={handleFileUpload}
              // setAccCopyFile={setAccCopyFile}
            />
          </div>
        </div>
      }
      isLoading={false}
      rootStyle={{
        // width: "20rem",
        // height: "15.312rem",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};

export default PersonalInfoComp;

//

const SearchCardRow1 = ({ staffInfoState, canEdit, isViewMode }) => {
  const storesDispatch = useDispatch();
  const labelWidth = "4.5rem";
  const labelWidthLong = "5.5rem";
  const textBoxWidth = "14rem";
  const phoneInputBoxWidth = "4.25rem";
  const phoneRef1 = useRef();
  const phoneRef2 = useRef();
  const phoneRef3 = useRef();
  const [phone, setPhone] = React.useState({
    p1: "",
    p2: "",
    p3: "",
  });
  const [phoneCheckStatus, setPhoneCheckStatus] = React.useState(true);

  useEffect(() => {
    setPhone({
      p1: getPhoneSubstr(staffInfoState.personal_info.phone, 0),
      p2: getPhoneSubstr(staffInfoState.personal_info.phone, 1),
      p3: getPhoneSubstr(staffInfoState.personal_info.phone, 2),
    });
  }, [staffInfoState]);

  const onCheckPhone = () => {
    if (phone.p1.length === 3 && phone.p2.length === 4 && phone.p3.length === 4) {
      HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/staff/check_phone", {
        params: {
          phone: phone.p1 + "-" + phone.p2 + "-" + phone.p3,
          staff_id: staffInfoState.staff_id,
        },
      })
        .then((res) => {
          if (!restEmpty(res.data.data)) {
            const isDuplicate = res.data.data;
            if (isDuplicate === "N") {
              setPhoneCheckStatus("Y");
              staffInfoState.personal_info.phone = phone.p1 + "-" + phone.p2 + "-" + phone.p3;
            } else {
              setPhoneCheckStatus("N");
              staffInfoState.personal_info.phone = "";
            }
          }
        })
        .catch((err) => {
          console.log(err);
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        });
    } else {
      setPhoneCheckStatus("");
    }

    setPhone({
      p1: phone.p1,
      p2: phone.p2,
      p3: phone.p3,
    });
  };

  return (
    <SearchRowBox marginTop="0.25rem">
      <SearchConditionBox width="24.5%">
        <TextField
          required
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          labelText="· 이름"
          defaultValue={staffInfoState.personal_info.staff_name}
          onChangeCallback={(e) => {
            staffInfoState.personal_info.staff_name = e;
          }}
          disabled={canEdit || isViewMode}
        />
      </SearchConditionBox>

      <SearchConditionBox width="25.5%">
        <TextField
          inputRef={phoneRef1}
          required={!canEdit}
          textMarginRight="0.1rem"
          labelWidth={labelWidthLong}
          textBoxWidth={phoneInputBoxWidth}
          labelText="· 연락처"
          maxLength={3}
          defaultValue={getPhoneSubstr(staffInfoState.personal_info.phone, 0)}
          onChangeCallback={(e) => {
            phone.p1 = e;
            onCheckPhone();
            if (phone.p1.length === 3) {
              phoneRef2.current.select();
            }
          }}
          // onBlur={onCheckPhone}
          disabled={canEdit}
        />
        <TextField
          inputRef={phoneRef2}
          required={!canEdit}
          textMarginRight="0.1rem"
          labelMarginRight="0.1rem"
          labelText="-"
          maxLength={4}
          textBoxWidth={phoneInputBoxWidth}
          defaultValue={getPhoneSubstr(staffInfoState.personal_info.phone, 1)}
          onChangeCallback={(e) => {
            phone.p2 = e;
            onCheckPhone();
            if (phone.p2.length === 4) {
              phoneRef3.current.select();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Backspace" && !phone.p2) {
              phoneRef1.current.select();
            }
          }}
          disabled={canEdit}
        />
        <TextField
          inputRef={phoneRef3}
          required={!canEdit}
          labelMarginRight="0.1rem"
          textMarginRight="0.25rem"
          labelText="-"
          maxLength={4}
          textBoxWidth={phoneInputBoxWidth}
          defaultValue={getPhoneSubstr(staffInfoState.personal_info.phone, 2)}
          onChangeCallback={(e) => {
            phone.p3 = e;
            onCheckPhone();
          }}
          onKeyDown={(e) => {
            if (e.key === "Backspace" && !phone.p3) {
              phoneRef2.current.select();
            }
          }}
          disabled={canEdit}
        />
        {phoneCheckStatus === "Y" ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              alt="O"
              src={successIcon}
              style={{
                display: "flex",
                width: "1.3rem",
                height: "1.3rem",
              }}
            />
          </div>
        ) : phoneCheckStatus === "N" ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              alt="O"
              src={failIcon}
              style={{
                display: "flex",
                width: "1.3rem",
                height: "1.3rem",
              }}
            />
          </div>
        ) : (
          <div style={{ width: "1.4rem" }}></div>
        )}
      </SearchConditionBox>
      <SearchConditionBox>
        <ComboBox
          required
          labelWidth={labelWidth}
          comboItemWidth={textBoxWidth}
          labelText="· 성별"
          data={["남", "여"]}
          defaultValue={staffInfoState.personal_info.gender}
          onChangeCallback={(e) => {
            // console.log(e);
            staffInfoState.personal_info.gender = e.target.value;
          }}
          disabled={canEdit}
        />
      </SearchConditionBox>
      <SearchConditionBox>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          labelText="· 사번"
          defaultValue={staffInfoState.personal_info.sabun}
          onChangeCallback={(e) => {
            staffInfoState.personal_info.sabun = e;
          }}
          disabled
        />
      </SearchConditionBox>
    </SearchRowBox>
  );
};

const SearchCardRow2 = ({ staffInfoState, canEdit, isViewMode }) => {
  const btnStyleClass = btnStyles();
  const labelWidth = "4.5rem";
  const labelWidthLong = "5.5rem";
  const textBoxWidth = "14rem";

  const [isAddrPopup, setAddrPopup] = React.useState(false);
  const openAddrPopup = (val) => {
    setAddrPopup(val);
  };

  const handleComplete = (data) => {
    openAddrPopup(false);
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    staffInfoState.personal_info.address_1 = fullAddress;
  };

  return (
    <SearchRowBox>
      <Popup
        isOpen={isAddrPopup}
        onClose={() => {
          openAddrPopup(false);
        }}
        title="주소 검색"
        Content={
          <DaumPostcode
            onComplete={handleComplete}
            autoClose={false}
            style={{ width: "30rem", height: "35rem" }}
          />
        }
      />

      <SearchConditionBox width="24.5%">
        <DatePicker
          required
          labelWidth={labelWidth}
          minWidth={textBoxWidth}
          labelText="· 생년월일"
          // defaultValue={"2022-06-21"}
          defaultValue={staffInfoState.personal_info.birthday}
          onChangeCallback={(e) => {
            staffInfoState.personal_info.birthday = e;
          }}
          disabled={canEdit}
        />
      </SearchConditionBox>

      <SearchConditionBox width="47.5%">
        <Label
          labelText="· 주소"
          width={labelWidthLong}
          justifyContent="start"
          marginRight="0.625rem"
        />
        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType2]: true,
          })}
          onClick={() => {
            openAddrPopup(true);
          }}
        >
          검색
        </Button>
        <TextField
          labelMarginRight="0.25rem"
          textMarginRight="0rem"
          flexGrow={1}
          defaultValue={staffInfoState.personal_info.address_1}
          onChangeCallback={(e) => {
            staffInfoState.personal_info.address_1 = e;
          }}
          disabled
        />
      </SearchConditionBox>
      <SearchConditionBox width="25.75%">
        <TextField
          labelMarginRight="0.25rem"
          flexGrow={1}
          defaultValue={staffInfoState.personal_info.address_2}
          onChangeCallback={(e) => {
            staffInfoState.personal_info.address_2 = e;
          }}
          disabled={canEdit}
        />
        {/* <TextField3
          labelWidth={"4.25rem"}
          textBoxWidth={"6.75rem"}
          labelText=""
          textValue="1234567890"
          /> */}
      </SearchConditionBox>
    </SearchRowBox>
  );
};

const SearchCardRow3 = ({ staffInfoState, canEdit, isViewMode, staffInfoRoot }) => {
  const storesDispatch = useDispatch();
  const labelWidth = "4.5rem";
  const labelWidthLong = "5.5rem";
  const textBoxWidth = "14rem";
  const textBoxWidthShort = "9.25rem";
  const btnStyleClass = btnStyles();
  const [idCheckStatus, setIdCheckStatus] = useState(true);
  const [socialNumber, setSocialNumber] = useState(staffInfoState.personal_info.social_number);
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });
  const viewModelRef = useRef();

  useEffect(() => {
    setSocialNumber(staffInfoState.personal_info.social_number);
  }, []);

  const checkAuthId = (val) => {
    const check = checkIdFormat(val);
    if (!check) {
      setIdCheckStatus("N");
      staffInfoRoot.contents.auth_id = "";
      simpleAlert(storesDispatch, "알림", "ID는 알파벳 포함 4자 이상, 공백이 없어야 합니다.");
      return;
    }

    HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/staff/check_id", {
      params: {
        user_id: val,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          const isDuplicate = res.data.data;
          if (isDuplicate === "N") {
            setIdCheckStatus("Y");
            staffInfoRoot.contents.auth_id = val;
          } else {
            setIdCheckStatus("N");
            staffInfoRoot.contents.auth_id = "";
          }
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const onModalChangePass = () => {
    setModalInfo({
      show: true,
      title: "비밀번호 변경",
      Content: (
        <PasswordChangeModal
          modalParam={{
            staff_id: staffInfoState.staff_id,
            auth_id: staffInfoRoot.contents.auth_id,
            auth_pass: staffInfoRoot.contents.auth_pass,
          }}
          onModalDone={() => {
            viewModelRef.current.setClose();
            window.location.reload();
          }}
        />
      ),
      callback: null,
      Buttons: undefined,
    });
  };
  return (
    <SearchRowBox>
      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
      <SearchConditionBox width="24.5%">
        <TextField
          required
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          labelText="· 주민번호"
          maxLength={14}
          defaultValue={staffInfoState.personal_info.social_number}
          // defaultValue={socialNumber}
          onChangeCallback={(e) => {
            if (e.length === 6) {
              e += "-";
            }
            staffInfoState.personal_info.social_number = e;
            setSocialNumber(staffInfoState.personal_info.social_number);
          }}
          onKeyDown={(e) => {
            numberKeyValidation(e);
          }}
          disabled={canEdit}
        />
      </SearchConditionBox>

      <SearchConditionBox width="25.5%">
        <TextField
          required
          id="email_input"
          labelWidth={labelWidthLong}
          textBoxWidth={textBoxWidth}
          labelText="· 이메일"
          defaultValue={staffInfoState.personal_info.email}
          onBlur={(e) => {
            if (e.target.value !== "" && !checkEmail(e.target.value)) {
              storesDispatch(
                globalAlertOn({
                  show: true,
                  titleText: "알림",
                  bodyText: (
                    <>
                      <Label labelText="올바른 이메일 형식이 아닙니다." />
                    </>
                  ),
                  // width: "50rem",
                  onBtnOk: () => {},
                }),
              );
              document.getElementById("email_input").focus();
            } else {
              staffInfoState.personal_info.email = e.target.value;
            }
          }}
          disabled={canEdit}
        />
      </SearchConditionBox>
      <SearchConditionBox>
        <TextField
          required
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          textMarginRight="0.25rem"
          labelText="· 아이디"
          defaultValue={staffInfoRoot.contents.auth_id}
          onBlur={(e) => {
            checkAuthId(e.target.value);
          }}
          disabled={canEdit || isViewMode}
        />
        {idCheckStatus === "Y" ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              alt="O"
              src={successIcon}
              style={{
                display: "flex",
                width: "1.3rem",
                height: "1.3rem",
              }}
            />
          </div>
        ) : idCheckStatus === "N" ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              alt="O"
              src={failIcon}
              style={{
                display: "flex",
                width: "1.3rem",
                height: "1.3rem",
              }}
            />
          </div>
        ) : (
          <div style={{ width: "1.4rem" }}></div>
        )}
      </SearchConditionBox>
      <SearchConditionBox>
        <TextField
          required
          labelWidth={labelWidth}
          textBoxWidth={canEdit || isViewMode ? textBoxWidthShort : textBoxWidth}
          textMarginRight="0.25rem"
          labelText="· 비밀번호"
          defaultValue={staffInfoRoot.contents.auth_pass}
          onChangeCallback={(e) => {
            staffInfoRoot.contents.auth_pass = e;
          }}
          password
          disabled={canEdit || isViewMode}
        />
        {canEdit || isViewMode ? (
          <Button
            className={clsx({
              [btnStyleClass.btnRoot]: true,
              [btnStyleClass.buttonType2]: true,
            })}
            onClick={() => {
              onModalChangePass();
            }}
          >
            변경
          </Button>
        ) : (
          <></>
        )}
      </SearchConditionBox>
    </SearchRowBox>
  );
};

const SearchCardRow4 = ({ staffInfoState, canEdit, isViewMode }) => {
  const labelWidth = "4.5rem";
  const labelWidthLong = "5.5rem";
  const textBoxWidth = "14rem";

  return (
    <SearchRowBox>
      <SearchConditionBox width="24.5%">
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          labelText="· 은행"
          defaultValue={staffInfoState.personal_info.bank}
          onChangeCallback={(e) => {
            staffInfoState.personal_info.bank = e;
          }}
          disabled={canEdit}
        />
      </SearchConditionBox>

      <SearchConditionBox width="25.5%">
        <TextField
          labelWidth={labelWidthLong}
          textBoxWidth={textBoxWidth}
          labelText="· 계좌번호"
          defaultValue={staffInfoState.personal_info.account_no}
          onChangeCallback={(e) => {
            staffInfoState.personal_info.account_no = e;
          }}
          disabled={canEdit}
        />
      </SearchConditionBox>
      <SearchConditionBox>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          labelText="· 예금주"
          defaultValue={staffInfoState.personal_info.account_holder}
          onChangeCallback={(e) => {
            staffInfoState.personal_info.account_holder = e;
          }}
          disabled={canEdit}
        />
      </SearchConditionBox>
      <div style={{ width: "25%" }}></div>
    </SearchRowBox>
  );
};

const SearchCardRow5 = ({
  staffInfoState,
  onClickOpenModal,
  storesDispatch,
  staffInfoRoot,
  handleFileUpload,
  // setAccCopyFile,
}) => {
  const labelWidth = "4.5rem";
  const labelWidthLong = "5.5rem";
  const textBoxWidthShort = "9.125rem";
  const [cardInfo, setCardInfo] = useState(staffInfoRoot.contents.card_info);
  // textBox 길이 조절용 state
  const [idCardFileName, setIdCardFileName] = useState("");
  const [accountFileName, setAccountFileName] = useState("");

  // 처음 출입 방식 "앱 출입" 세팅
  useEffect(() => {
    if (staffInfoRoot.contents.staff_obj.staff_id === generateObjectZeroId()) {
      setCardInfo((cur) => {
        return { ...cur, access_method: "앱" };
      });
      staffInfoRoot.contents.card_info.access_method = "앱";
    } else {
      setCardInfo(staffInfoRoot.contents.card_info);
    }
  }, [staffInfoRoot]);

  const onSearchCard = () => {
    const param = {
      title: "카드검색",
      modalParam: {
        mode: "search",
        is_staff: true,
        searchCallback: onSearchCardCallback,
      },
    };
    onClickOpenModal("카드검색", param);
  };

  const onSearchCardCallback = (data) => {
    setCardInfo((cur) => {
      const newData = { ...cur };
      newData.card_info_id = data.card_info_id;
      newData.card_no = data.card_no;
      newData.branch_info = data.branch_info;
      return newData;
    });
    staffInfoRoot.contents.card_info = {
      access_method: staffInfoRoot.contents.card_info.access_method,
      card_info_id: data.card_info_id,
      card_no: data.card_no,
      branch_info: data.branch_info,
    };
    storesDispatch(globalModalOff());
  };
  // 등본, 통장 파일 등록 체크
  useEffect(() => {
    setIdCardFileName(staffInfoState.files.id_card.file_info?.file_name);
    setAccountFileName(staffInfoState.files.account_copy.file_info?.file_name);
  }, [
    staffInfoState.files.id_card.file_info?.file_name,
    staffInfoState.files.account_copy.file_info?.file_name,
  ]);

  return (
    <SearchRowBox>
      <SearchConditionBox width="24.5%">
        <ComboBox
          marginRight="0rem"
          labelWidth={labelWidth}
          comboItemWidth={"6.25rem"}
          labelText="· 출입방식"
          defaultValue={cardInfo.access_method}
          data={["앱", "카드"]}
          onChangeCallback={(e) => {
            setCardInfo((cur) => {
              const newData = { ...cur };
              newData.access_method = e.target.value;
              return newData;
            });
            staffInfoRoot.contents.card_info.access_method = e.target.value;

            if (e.target.value === "앱") {
              simpleAlert(
                storesDispatch,
                "알림",
                "출입방식을 앱으로 변경 시 기존 출입카드는 사용할 수 없게 됩니다.",
              );
            }
          }}
        />
        {staffInfoRoot.contents.card_info.access_method === "카드" ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              labelMarginRight="0.25rem"
              textMarginRight="0.25rem"
              labelWidth={"0rem"}
              textBoxWidth={"5rem"}
              defaultValue={staffInfoRoot.contents.card_info.card_no}
              disabled
            />
            <IconButton type="search" height="2.2rem" width="2.3rem" onClick={onSearchCard} />
          </div>
        ) : (
          <></>
        )}
      </SearchConditionBox>

      <SearchConditionBox width="25.5%">
        <FileUpload
          labelWidth={labelWidthLong}
          textBoxWidth={restEmpty(idCardFileName) ? "11.55rem" : textBoxWidthShort}
          textMarginRight={"0rem"}
          btnMarginLeft={"0.25rem"}
          labelText={"· 주민등록등본"}
          defaultValue={getDefaultValue(
            "field",
            staffInfoRoot.contents.staff_obj.files.id_card?.file_info?.file_name,
          )}
          initFile={
            staffInfoState.files.id_card?.file_info?.file_name === ""
              ? null
              : staffInfoState.files.id_card.file_info
          }
          onUploadFile={(file) => {
            handleFileUpload(file, "주민등록등본");
            setIdCardFileName(file?.name);
          }}
        />
      </SearchConditionBox>

      <SearchConditionBox>
        <FileUpload
          labelWidth={labelWidth}
          textBoxWidth={restEmpty(accountFileName) ? "11.55rem" : textBoxWidthShort}
          textMarginRight={"0rem"}
          btnMarginLeft={"0.25rem"}
          labelText={"· 통장사본"}
          defaultValue={getDefaultValue(
            "field",
            staffInfoRoot.contents.staff_obj.files.account_copy?.file_info?.file_name,
          )}
          initFile={
            staffInfoState.files.account_copy?.file_info?.file_name === ""
              ? null
              : staffInfoState.files.account_copy.file_info
          }
          onUploadFile={(file) => {
            handleFileUpload(file, "통장사본");
            setAccountFileName(file?.name);
          }}
        />
      </SearchConditionBox>
    </SearchRowBox>
  );
};
