import { HttpInstance } from "lib/HttpLib";

export const getBaseSearchParam = () => {
  return HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member_search/base_search_param", {
    params: {},
  });
};

export const getMemberSearchParam = () => {
  return HttpInstance.get(
    process.env.REACT_APP_USER_SERVER + "/member_search/member_search_param",
    {
      params: {},
    },
  );
};

export const getMbshpSearchParam = () => {
  return HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member_search/mbshp_search_param", {
    params: {},
  });
};

export const getSideSearchParam = () => {
  return HttpInstance.get(
    process.env.REACT_APP_USER_SERVER + "/member_search/others_search_param",
    {
      params: {},
    },
  );
};

export const getPtSearchParam = () => {
  return HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member_search/pt_search_param", {
    params: {},
  });
};

const get_api = {
  getBaseSearchParam,
  getMemberSearchParam,
  getMbshpSearchParam,
  getSideSearchParam,
  getPtSearchParam,
};

export default get_api;
