import { create } from "zustand";

// 검색 조건 베이스
const baseSearchParams = {
  approval_status: "",
  base_from_date: "",
  base_to_date: "",
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  combo_selector: {
    branch_list: [[]],
    hq_list: [],
    target_list: [
      {
        branch_list: [
          {
            branch_id: "",
            branch_name: "",
          },
        ],
        hq_info: {
          hq_id: "",
          hq_name: "",
        },
      },
    ],
  },
  edit_status: "",
  hq_info: {
    hq_id: "",
    hq_name: "",
  },
};

export const useSalaryListStore = create((set) => ({
  // ========== State ===========
  // 운영일지 검색 State
  salaryListSearchParams: {
    ...baseSearchParams,
  },

  salaryListHqIdx: 0,

  salaryListPage: 1,

  // ========== Action ==========
  // 운영일지 검색 조건 변경
  setSearchParams: (key, value) => {
    set((state) => ({
      salaryListSearchParams: {
        ...state.salaryListSearchParams,
        [key]: value,
      },
    }));
  },
  // Hq Index 값 변경
  setHqIdx: (value) => {
    set({ salaryListHqIdx: value });
  },

  // Page 값 변경
  setPage: (value) => {
    set({ salaryListPage: value });
  },
  // 검색 조건 리셋
  resetSearchParams: () => {
    set({
      salaryListSearchParams: {
        ...baseSearchParams,
      },
    });
    set({ salaryListHqIdx: 0 });
  },
}));
