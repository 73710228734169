import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const SettingChangeHistory = ({ branchId }) => {
  const storesDispatch = useDispatch();
  const [changeLog, setChangeLog] = useState([]);

  useEffect(() => {
    HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/branch_set/change_log/list", {
      params: {
        branch_id: branchId,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setChangeLog(res.data.data.contents);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  }, [branchId]);

  const onClickShowDetail = (data) => {
    let resultText = "";
    for (let i = 0; i < data.change_log.length; i++) {
      resultText += (i + 1).toString() + ") ";
      resultText += data.change_log[i].field + " / ";
      resultText += data.change_log[i].before + " / ";
      resultText += data.change_log[i].after;
      resultText += "\n";
    }

    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="변경 세부 리스트" />
            <br />
            <Label labelText={resultText} whiteSpace="pre-wrap" />
          </>
        ),
        // width: "50rem",
        onBtnOk: () => {},
      }),
    );
  };

  const changeHistoryList = new changeHistoryTable(onClickShowDetail);

  return (
    <div>
      <CustomTable
        columns_head={changeHistoryList.columns_head}
        table_title={changeHistoryList.table_title}
        rest_call={changeHistoryList.get_data_from_rest}
        row_render={changeHistoryList.create_table}
        rest_data={changeLog}
      />
    </div>
  );
};
export default SettingChangeHistory;

class changeHistoryTable {
  table_title = "";
  btnStyleClass = btnStyles();

  onClickShowDetail = null;

  constructor(onClickShowDetail) {
    this.onClickShowDetail = onClickShowDetail;
  }

  onEditEvent = (id) => {
    this.onParentEditEvent(id);
  };
  create_table = (data) => {
    return (
      <CustomRow style={{ width: "100%" }}>
        <CustomTableContents width="25%">{data.reg_info.updated_at}</CustomTableContents>
        <CustomTableContents width="15%">{data.reg_info.upd_name}</CustomTableContents>
        <CustomTableContents width="15%">{data.log_info.change_from_sub}</CustomTableContents>
        <CustomTableContents width="15%">{data.log_info.change_action}</CustomTableContents>
        <CustomTableContents width="20%">{/* {data.} */}</CustomTableContents>

        <CustomTableContents width="10%">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              type="detail"
              width="2.4rem"
              onClick={() => {
                this.onClickShowDetail(data);
              }}
            />
          </div>
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "변경일시", field: "" },
    { title: "담당자", field: "" },
    { title: "탭", field: "" },
    { title: "구분", field: "" },
    { title: "항목", field: "" },
    { title: "", field: "action" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    } else {
      return stateData;
    }
  };
}
