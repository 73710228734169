import CardManageInfoService from "pages/AccessManage/CardManage/CardInfo/CardManageInfoService";
import CardManageService from "pages/AccessManage/CardManage/CardManageService";
import DeviceManageService from "pages/AccessManage/DeviceManage/DeviceManageService";
import MonitoringManageService from "pages/AccessManage/MornitoringMange/MonitoringManageService";
// 조회
import ApprovalInfoService from "pages/ApprovalManage/ApprovalInfo/ApprovalInfoService";
///전자결재
import ApprovalListViewService from "pages/ApprovalManage/ApprovalList/ApprovalListViewService";
import ContractView from "pages/Branch/TabletSetting/ContractView";
import TabletSetting from "pages/Branch/TabletSetting/TabletSetting";
// 현금출납장
import CashBookInfoService from "pages/CashBook/CashBookList/CashBookInfoService";
import CashBookListService from "pages/CashBook/CashBookList/CashBookListService";
import UnspentListService from "pages/CashBook/UnspentList/UnspentListService";
// CS
import GuestBookService from "pages/CSManage/GuestBook/GuestBookService";
import SendHistoryService from "pages/CSManage/SendHistory/SendHistoryService";
import SendMessageService from "pages/CSManage/SendMessage/SendMessageService";
import TMHistoryService from "pages/CSManage/TMHistory/TMHistoryService";
import TMListService from "pages/CSManage/TMList/TMListService";
import TMListDetailService from "pages/CSManage/TMListDetail/TMListDetailService";
import UnsubsListService from "pages/CSManage/UnsubscribeList/UnsubsListService";
//운영일지
import DailyReportInfoService from "pages/DailyReport/DailyReportInfo/DailyReportInfoService";
import DailyReportListService from "pages/DailyReport/DailyReportList/DailyReportListService";
/////MEMBER SERVICE
import AssignService from "pages/ERP/MemberService/AssignChangeBranch/Assign/AssignService";
import ChangeBranchService from "pages/ERP/MemberService/AssignChangeBranch/ChangeBranch/ChangeBranchService";
import ChangeItemService from "pages/ERP/MemberService/ChangeItem/ChangeItemService";
import LongPostponeList from "pages/ERP/MemberService/Search/LongPostponeList/LongPostponeList";
import SearchMember from "pages/ERP/MemberService/Search/SearchMember/SearchMember";
import SubsManage from "pages/ERP/MemberService/Search/SubsManage/SubsManage";
import UnpaidManage from "pages/ERP/MemberService/Search/UnpaidManage/UnpaidManage";
//게시판 - 공지사항
import NoticeCreateService from "pages/NoticeBoard/Notice/NoticeCreate/NoticeCreateService";
import NoticeDetailService from "pages/NoticeBoard/Notice/NoticeDetail/NoticeDetailService";
import NoticeService from "pages/NoticeBoard/Notice/NoticeService";
import NoticeUpdateService from "pages/NoticeBoard/Notice/NoticeUpdate/NoticeUpdateService";
//게시판 - 자료실
import ReferenceCreateService from "pages/NoticeBoard/Reference/ReferenceCreate/ReferenceCreateService";
import ReferenceDetailService from "pages/NoticeBoard/Reference/ReferenceDetail/ReferenceDetailService";
import ReferenceService from "pages/NoticeBoard/Reference/ReferenceService";
import ReferenceUpdateService from "pages/NoticeBoard/Reference/ReferenceUpdate/ReferenceUpdateService";
//게시판 - 전달사항
import StaffDMCreateService from "pages/NoticeBoard/StaffDM/StaffDMCreate/StaffDMCreateService";
import StaffDMDetailService from "pages/NoticeBoard/StaffDM/StaffDMDetail/StaffDMDetailService";
import StaffDMService from "pages/NoticeBoard/StaffDM/StaffDMService";
import StaffDMUpdateService from "pages/NoticeBoard/StaffDM/StaffDMUpdate/StaffDMUpdateService";
// 실적관리
import SalesRecordService from "pages/SalesRecord/SalesRecordService";
//수업운영
import ClassHist from "pages/Staff/Class/ClassHist/ClassHist";
import ClassManage from "pages/Staff/Class/ClassManage/ClassManage";
import ManagePtTeam from "pages/Staff/Class/PtTEAM/ManagePtTeam/ManagePtTeamService";
import TeamRecordService from "pages/Staff/Class/PtTEAM/TeamRecord/TeamRecordService";
///급여관리
import SalaryInfo from "pages/Staff/SalaryInfo/SalaryInfo";
import SalaryList from "pages/Staff/SalaryList/SalaryList";
//스케줄
import ScheduleService from "pages/Staff/Schedule/ScheduleService";
//STAFFF
import StaffInfo from "pages/Staff/StaffInfo/StaffInfoService";
import StaffList from "pages/Staff/StaffList/StaffListService";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./components/accessLib/PrivateRoute";
//BRANCH
import BranchSetting from "./pages/Branch/BranchSetting/SettingWrapper";
import ERPSetting from "./pages/Branch/ERPSetting/SettingWrapper";
import HQSetting from "./pages/Branch/HQSetting/SettingWrapper";
import LockerService from "./pages/ERP/LockerService/LockerService";
import AddPayRoot from "./pages/ERP/MemberRegister/AddPay/AddPayRoot";
/////MEMBER
import ExtendMbshpHistory from "pages/ERP/MemberService/Search/ExtendHistory/ExtendMbshpHistory";
import RegisterMemberRoot from "./pages/ERP/MemberRegister/Register/RegisterMemberRoot";
import ChangePayMethodService from "./pages/ERP/MemberService/ChangePayMethod/ChangePayMethodService";
import RefundService from "./pages/ERP/MemberService/Refund/RefundService";
import SubsRefundService from "./pages/ERP/MemberService/SubsRefund/SubsRefundService";
import MemberViewService from "./pages/ERP/MemberView/MemberViewService";
import LoginPage from "./pages/LoginPage/LoginPage";
import MainIndex from "./pages/MainIndex/MainIndex";
///MANAGE
import MemberLocationStatsWrapper from "pages/Statistics/MemberLocationStats/MemberLocationStatsWrapper";
import RenderHTML from "pages/Statistics/MemberLocationStats/RenderHTML";
import PassAuthorize from "pages/ThirdParties/Pass/PassAuthorize";
import PassResult from "pages/ThirdParties/Pass/PassResult";
import NotFound from "./pages/NotFound/NotFound";

const RouteSwitch = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      {/* PASS */}
      <Route path="/auth/pass" element={<PassAuthorize />} />
      <Route path="/auth/pass/res" element={<PassResult />} />

      <Route path="" element={<PrivateRoute />}>
        <Route path="/" element={<MainIndex />} />
        {/* .. MEMBER ..*/}
        <Route path="/register_member" element={<RegisterMemberRoot />} />
        <Route path="/member_info/:targetId" element={<MemberViewService />} />
        <Route path="/change_pay_method" element={<ChangePayMethodService />} />
        <Route path="/change_item" element={<ChangeItemService />} />
        <Route path="/add_pay" element={<AddPayRoot />} />
        <Route path="/refund" element={<RefundService />} />
        <Route path="/subscript_cancel" element={<SubsRefundService />} />
        <Route path="/assign" element={<AssignService />} />
        <Route path="/change_branch" element={<ChangeBranchService />} />
        {/* .. SEARCH ..*/}
        <Route path="/search_member" element={<SearchMember />} />
        <Route path="/long_postpone_list" element={<LongPostponeList />} />
        <Route path="/unpaid_manage" element={<UnpaidManage />} />
        <Route path="/subs_manage" element={<SubsManage />} />
        <Route path="/extend_hist" element={<ExtendMbshpHistory />} />
        {/* .. BRANCH ..*/}
        <Route path="/locker_service" element={<LockerService />} />
        <Route path="/erp_setting" element={<ERPSetting />} />
        <Route path="/hq_setting" element={<HQSetting />} />
        <Route path="/hq_setting/:targetId" element={<HQSetting />} />
        <Route path="/tablet_setting" element={<TabletSetting />} />
        <Route path="/contract/update/:contractId" element={<ContractView />} />
        <Route path="/branch_setting" element={<BranchSetting />} />
        <Route path="/branch_setting/:targetId" element={<BranchSetting />} />
        {/* .. STAFF ..*/}
        <Route path="/staff_list" element={<StaffList />} />
        <Route path="/staff_info" element={<StaffInfo />} />
        <Route path="/staff_info/:targetId" element={<StaffInfo />} />
        <Route path="/manage_pt_team" element={<ManagePtTeam />} />
        <Route path="/pt_team_performance" element={<TeamRecordService />} />
        <Route path="/class_manage" element={<ClassManage />} />
        <Route path="/class_hist" element={<ClassHist />} />
        {/* .. 급여 ..*/}
        <Route path="/salary_list" element={<SalaryList />} />
        <Route path="/salary_info" element={<SalaryInfo />} />
        <Route path="/sales_record" element={<SalesRecordService />} />
        {/* .. 스케줄 ..*/}
        <Route path="/schedule" element={<ScheduleService />} />
        {/* .. 출입 ..*/}
        <Route path="/access_device_manage" element={<DeviceManageService />} />
        <Route path="/access_card_manage" element={<CardManageService />} />
        <Route path="/access_card_info" element={<CardManageInfoService />} />
        <Route path="/access_monitor" element={<MonitoringManageService />} />
        {/* .. 전자결재 ..*/}
        <Route path="/approval_list" element={<ApprovalListViewService />} />
        <Route path="/approval_info" element={<ApprovalInfoService />} />
        {/* .. 운영일지 ..*/}
        <Route path="/daily_list" element={<DailyReportListService />} />
        <Route path="/daily_info" element={<DailyReportInfoService />} />
        {/* .. CS ..*/}
        <Route path="/send_message" element={<SendMessageService />} />
        <Route path="/send_message_hist" element={<SendHistoryService />} />
        <Route path="/unsubs_list" element={<UnsubsListService />} />
        <Route path="/guest_book" element={<GuestBookService />} />
        <Route path="/tm_list" element={<TMListService />} />
        <Route path="/tm_list_detail" element={<TMListDetailService />} />
        <Route path="/tm_hist" element={<TMHistoryService />} />
        <Route path="/cash_book" element={<CashBookListService />} />
        <Route path="/cash_book_detail" element={<CashBookInfoService />} />
        <Route path="/unspent" element={<UnspentListService />} />
        {/* .. 게시판-공지사항 ..*/}
        <Route path="/notice" element={<NoticeService />} />
        <Route path="/notice/create" element={<NoticeCreateService />} />
        <Route path="/notice/update/:postId" element={<NoticeUpdateService />} />
        <Route path="/notice/:postId" element={<NoticeDetailService />} />
        {/* .. 게시판-자료실 ..*/}
        <Route path="/reference" element={<ReferenceService />} />
        <Route path="/reference/create" element={<ReferenceCreateService />} />
        <Route path="/reference/update/:postId" element={<ReferenceUpdateService />} />
        <Route path="/reference/:postId" element={<ReferenceDetailService />} />
        {/* .. 게시판-전달사항 ..*/}
        <Route path="/staff_dm" element={<StaffDMService />} />
        <Route path="/staff_dm/create" element={<StaffDMCreateService />} />
        <Route path="/staff_dm/update/:postId" element={<StaffDMUpdateService />} />
        <Route path="/staff_dm/:postId" element={<StaffDMDetailService />} />
        {/* 통계 */}
        <Route path="/member_location_stats" element={<MemberLocationStatsWrapper />} />
        <Route path="/member_location_map" element={<RenderHTML />} />
      </Route>
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default RouteSwitch;
