import * as ActionTypes from "./action_type";

///Items MANAGE
export const loadItemsView = (branch_id) => {
  return {
    type: ActionTypes.getBranchItemsViewAction,
    branch_id: branch_id,
    // branch_name: branch_name,
  };
};

export const loadItemsViewSuccess = (data) => {
  return {
    type: ActionTypes.getBranchItemsSuccessAction,
    payload: data,
  };
};

export const loadItemsViewFail = (data) => {
  return {
    type: ActionTypes.getBranchItemsFailAction,
    payload: [],
  };
};

export const loadItemsViewReset = (data) => {
  return {
    type: ActionTypes.getBranchItemsResetAction,
    payload: data,
  };
};

///PRE_PAID
export const loadPrePaidItems = (branch_id, item_id) => {
  return {
    type: ActionTypes.getPrePaidInfoAction,
    branch_id: branch_id,
    item_id: item_id,
  };
};

export const loadPrePaidItemsSuccess = (data) => {
  return {
    type: ActionTypes.getPrePaidInfoSuccessAction,
    payload: data,
  };
};

export const loadPrePaidItemsFail = (data) => {
  return {
    type: ActionTypes.getPrePaidInfoFailAction,
    payload: [],
  };
};

export const loadPrePaidItemsReset = (data) => {
  return {
    type: ActionTypes.getPrePaidInfoResetAction,
    payload: data,
  };
};

///SUBSCRIPTION PAID
export const loadSubScriptionItems = (branch_id, item_id) => {
  return {
    type: ActionTypes.getSubPaidInfoAction,
    branch_id: branch_id,
    item_id: item_id,
  };
};

export const loadSubScriptionItemsSuccess = (data) => {
  return {
    type: ActionTypes.getSubPaidInfoSuccessAction,
    payload: data,
  };
};

export const loadSubScriptionItemsFail = (data) => {
  return {
    type: ActionTypes.getSubPaidInfoFailAction,
    payload: [],
  };
};

export const loadSubScriptionItemsReset = (data) => {
  return {
    type: ActionTypes.getSubPaidInfoResetAction,
    payload: data,
  };
};

///ETC
export const loadETCItems = (branch_id, item_id) => {
  return {
    type: ActionTypes.getEtcInfoAction,
    branch_id: branch_id,
    item_id: item_id,
  };
};

export const loadETCItemsSuccess = (data) => {
  return {
    type: ActionTypes.getEtcInfoSuccessAction,
    payload: data,
  };
};

export const loadETCViewFail = (data) => {
  return {
    type: ActionTypes.getEtcInfoFailAction,
    payload: [],
  };
};

export const loadETCItemsReset = (data) => {
  return {
    type: ActionTypes.getEtcInfoResetAction,
    payload: data,
  };
};
