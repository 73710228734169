export const DeviceListAction = "ACCESS/DEVICE/LIST";
export const DeviceListSuccessAction = "ACCESS/DEVICE/LIST/SUCCESS";
export const DeviceListFailAction = "ACCESS/DEVICE/LIST/FAIL";
export const DeviceListResetAction = "ACCESS/DEVICE/LIST/RESET";

export const DeviceScheduleListAction = "ACCESS/DEVICE/SCHEJULE_LIST";
export const DeviceScheduleListSuccessAction = "ACCESS/DEVICE/SCHEJULE_LIST/SUCCESS";
export const DeviceScheduleListFailAction = "ACCESS/DEVICE/SCHEJULE_LIST/FAIL";
export const DeviceScheduleListResetAction = "ACCESS/DEVICE/SCHEJULE_LIST/RESET";

export const SchedulePreSetInfoAction = "ACCESS/DEVICE/SCHEJULE_PRESET";
export const SchedulePreSetInfoSuccessAction = "ACCESS/DEVICE/SCHEJULE_PRESET/SUCCESS";
export const SchedulePreSetInfoFailAction = "ACCESS/DEVICE/SCHEJULE_PRESET/FAIL";
export const SchedulePreSetInfoResetAction = "ACCESS/DEVICE/SCHEJULE_PRESET/RESET";

export const HolyDayInfoAction = "ACCESS/DEVICE/HOLY_DAY";
export const HolyDayInfoSuccessAction = "ACCESS/DEVICE/HOLY_DAY/SUCCESS";
export const HolyDayInfoFailAction = "ACCESS/DEVICE/HOLY_DAY/FAIL";
export const HolyDayInfoResetAction = "ACCESS/DEVICE/HOLY_DAY/RESET";
