import { simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import { useState } from "react";
import { useDispatch } from "react-redux";
import post_api from "../Module/api/PostApi";

const ScheduleAllocModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState();
  const [loading, setLoading] = useState(false);

  //스케줄수정 POST
  const editScheduleData = async () => {
    try {
      setLoading(true);

      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const paramData = {
        branch_info: modalParam.branch_info,
        user_group: modalParam.user_group_info.user_group_name,
        schedule_info: selectedItem,
      };

      const formData = new FormData();
      formData.append("param_info", JSON.stringify(paramData));
      let postApi = post_api.presetAllocate();
      const res = await HttpInstance.post(postApi, formData, config);
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label labelText="저장되었습니다." />
              </>
            ),
            onBtnOk: () => {
              onModalDone(); //모달 닫기 + 스케줄 리스트 갱신
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const validationCheck = async () => {
    if (_.isEmpty(selectedItem)) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="스케줄을 선택해주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="저장하시겠습니까?" />
            </>
          ),
          onBtnYes: () => {
            editScheduleData();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };
  return (
    <div
      style={{
        width: "18rem",
      }}
    >
      {loading && <Loading />}

      <TextField
        marginTop={"0.75rem"}
        labelWidth={"5rem"}
        textBoxWidth={"12rem"}
        labelText="지점명"
        defaultValue={modalParam.branch_info.branch_name}
        disabled
      />
      <TextField
        marginTop={"0.5rem"}
        labelWidth={"5rem"}
        textBoxWidth={"12rem"}
        labelText="사용자 그룹"
        defaultValue={modalParam.user_group_info.user_group_name}
        disabled
      />
      <ComboBox
        marginTop={"0.5rem"}
        labelWidth={"5rem"}
        comboItemWidth={"12rem"}
        labelText="스케줄"
        data={modalParam.schedule_name_list}
        onChangeCallback={(e) => {
          const result = modalParam.schedule_preset_info.find(
            (el) => el.schedule_name === e.target.value,
          );
          setSelectedItem(result);
        }}
      />
      <div style={{ display: "flex", justifyContent: "center", marginTop: "0.7rem" }}>
        <NormalBtn name="저장" onClick={validationCheck} />
      </div>
    </div>
  );
};
export default ScheduleAllocModal;
