import * as ActionTypes from "./action_type";

///SEARCH
export const loadCardSearch = (branch_id, card_no) => {
  return {
    type: ActionTypes.CardSearchAction,
    branch_id: branch_id,
    card_no: card_no,
  };
};

export const loadCardSearchSuccess = (data) => {
  return {
    type: ActionTypes.CardSearchSuccessAction,
    payload: data,
  };
};

export const loadCardSearchFail = (data) => {
  return {
    type: ActionTypes.CardSearchFailAction,
    payload: [],
  };
};

export const loadCardSearchReset = (data) => {
  return {
    type: ActionTypes.CardSearchResetAction,
    payload: data,
  };
};

///Card Info
export const loadCardDetailInfo = (card_info_id) => {
  return {
    type: ActionTypes.CardDetailInfoAction,
    card_info_id: card_info_id,
  };
};

export const loadCardDetailInfoSuccess = (data) => {
  return {
    type: ActionTypes.CardDetailInfoSuccessAction,
    payload: data,
  };
};

export const loadCardDetailInfoFail = (data) => {
  return {
    type: ActionTypes.CardDetailInfoFailAction,
    payload: [],
  };
};

export const loadCardDetailInfoReset = (data) => {
  return {
    type: ActionTypes.CardDetailInfoResetAction,
    payload: data,
  };
};

///CARD LIST
export const loadCardListAction = (search_param) => {
  return {
    type: ActionTypes.CardListAction,
    search_param: search_param,
  };
};

export const loadCardListSuccessAction = (data) => {
  return {
    type: ActionTypes.CardListSuccessAction,
    payload: data,
  };
};

export const loadCardListFailAction = (data) => {
  return {
    type: ActionTypes.CardListFailAction,
    payload: [],
  };
};

export const loadCardListResetAction = (data) => {
  return {
    type: ActionTypes.CardListResetAction,
    payload: data,
  };
};
