import { dataEmpty, getPermission } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const StaffListSearchResult = ({ searchResult, navigate, curPage, totalPage, onClickSearch }) => {
  const storesDispatch = useDispatch();
  const [canAccessPersonalInfo, setCanAccessPersonalInfo] = useState(false);
  const checkPermission = async () => {
    const result = await getPermission(storesDispatch, "ACCOUNT_PERMISSION/SECRET");
    setCanAccessPersonalInfo(result);
  };
  useEffect(() => {
    checkPermission();
  }, []);

  const searchResultList = new searchResultTable(navigate);

  // rowspan용 데이터 수정
  const getSortedResult = () => {
    let curIdx = 0;
    let curStaffId = "";
    for (let i = 0; i < searchResult.length; i++) {
      if (i === searchResult.length - 1) {
        if (searchResult[i].staff_id !== curStaffId) {
          searchResult[curIdx].rowSpan = i - curIdx;
          searchResult[i].rowSpan = 1;
        } else {
          searchResult[curIdx].rowSpan = i - curIdx + 1;
        }
      } else if (searchResult[i].staff_id !== curStaffId) {
        searchResult[curIdx].rowSpan = i - curIdx;
        curIdx = i;
        curStaffId = searchResult[i].staff_id;
      }
    }
    return searchResult;
  };

  const doSearch = (page) => {
    onClickSearch(false, page);
  };

  return (
    <div>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={getSortedResult(searchResult)}
        width="140rem"
      />
      <CustomPagination curPage={curPage} totalCnt={totalPage} doSearch={doSearch} />
    </div>
  );
};
export default StaffListSearchResult;

class searchResultTable {
  table_title = "";
  onNavigate = null;

  constructor(navigate) {
    this.onNavigate = navigate;
  }

  onClickEvent = (data) => {
    this.onNavigate("/staff_info/", {
      state: {
        staff_id: data.staff_id,
        is_view: true,
      },
    });
  };

  create_table = (data, idx) => {
    return (
      <CustomRow style={{}} key={idx} onClick={() => this.onClickEvent(data)} hover>
        {data.rowSpan ? (
          <CustomTableContents width="3.5%" rowSpan={data.rowSpan}>
            {data.personal_info.sabun}
          </CustomTableContents>
        ) : (
          <></>
        )}
        {data.rowSpan ? (
          <CustomTableContents width="6%" rowSpan={data.rowSpan}>
            {data.personal_info.staff_name}
          </CustomTableContents>
        ) : (
          <></>
        )}
        {data.rowSpan ? (
          <CustomTableContents width="6%" rowSpan={data.rowSpan}>
            {data.personal_info.phone}
          </CustomTableContents>
        ) : (
          <></>
        )}
        {data.rowSpan ? (
          <CustomTableContents width="9%" rowSpan={data.rowSpan}>
            {data.personal_info.email}
          </CustomTableContents>
        ) : (
          <></>
        )}
        {data.rowSpan ? (
          <CustomTableContents width="5%" rowSpan={data.rowSpan}>
            {data.personal_info.is_submit_id_card}
          </CustomTableContents>
        ) : (
          <></>
        )}
        {data.rowSpan ? (
          <CustomTableContents width="5%" rowSpan={data.rowSpan}>
            {data.personal_info.is_submit_account_copy}
          </CustomTableContents>
        ) : (
          <></>
        )}

        <CustomTableContents width="4%">{data.approval_status}</CustomTableContents>
        <CustomTableContents width="4%">{data.edit_status}</CustomTableContents>
        <CustomTableContents width="7%">{data.base_info.hq_info.hq_name}</CustomTableContents>
        <CustomTableContents width="6%">
          {data.base_info.branch_info.branch_name}
        </CustomTableContents>
        <CustomTableContents width="4%">{data.work_status}</CustomTableContents>
        <CustomTableContents width="4%">{data.base_info.rank.org_name}</CustomTableContents>
        <CustomTableContents width="5%">{data.base_info.position.org_name}</CustomTableContents>
        <CustomTableContents width="6%">{data.base_info.join_date}</CustomTableContents>
        <CustomTableContents width="6%">{data.base_info.resign_date}</CustomTableContents>
        <CustomTableContents width="4%">{data.files.is_submit_emp_file}</CustomTableContents>
        <CustomTableContents width="4%">{data.files.is_submit_resign}</CustomTableContents>
        <CustomTableContents width="4%">{data.work_role.is_cs ? "Y" : "N"}</CustomTableContents>
        <CustomTableContentsEND align="center" width="4%">
          {data.work_role.is_pt ? "Y" : "N"}
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "사번", field: "sabun" },
    { title: "직원명", field: "staff_name" },
    { title: "연락처", field: "phone" },
    { title: "이메일", field: "email" },
    { title: "주민등록등본", field: "is_submit_id_card" },
    { title: "통장사본", field: "is_submit_account_copy" },
    { title: "결재상태", field: "is_submit_account_copy" },
    { title: "수정상태", field: "approval_status" },
    { title: "본부", field: "hq_name" },
    { title: "지점", field: "branch_name" },
    { title: "재직상태", field: "status" },
    { title: "직위", field: "rank" },
    { title: "직책", field: "position" },
    { title: "입사일", field: "join_date" },
    { title: "퇴사일", field: "resign_date" },
    { title: "근로계약서", field: "is_submit_emp_file" },
    { title: "사직서", field: "is_submit_resign" },
    { title: "CS업무", field: "is_submit_resign" },
    { title: "PT업무", field: "is_submit_resign" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
