import { contentTypeJson, generateObjectZeroId, simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import NumberTextField from "components/LabelInput/NumberTextField";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const AddVaultCashModal = ({ reportState, onModalDone }) => {
  const storesDispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [vaultCashData, setVaultCashData] = useState();
  let selected;

  useEffect(() => {
    setVaultCashData(vault_cash_init_state);
  }, []);

  const onClickSave = () => {
    if (!vaultCashData.vault_type) {
      simpleAlert(storesDispatch, "알림", "입출구분을 선택해 주세요");
      return;
    } else if (!vaultCashData.price) {
      simpleAlert(storesDispatch, "알림", "금액을 입력해 주세요");
      return;
    } else if (!vaultCashData.note) {
      simpleAlert(storesDispatch, "알림", "내용을 입력해 주세요");
      return;
    }

    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: <Label labelText="시재금을 추가하시겠습니까?" />,
        width: "23rem",
        onBtnYes: () => {
          onSaveEvent();
        },
        onBtnNo: () => {},
      }),
    );
  };

  const onSaveEvent = async () => {
    vaultCashData.hq_info = reportState.hq_info;
    vaultCashData.branch_info = reportState.branch_info;
    vaultCashData.reg_date = reportState.base_date;

    const formData = new FormData();
    formData.append("vault_cash_item", JSON.stringify(vaultCashData));

    setLoading(true);

    await HttpInstance.post(
      process.env.REACT_APP_SALARY_SERVER + "/vault_cash/insert",
      formData,
      contentTypeJson,
    )
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          simpleAlert(
            storesDispatch,
            "알림",
            "시재금이 추가되었습니다.\n\n페이지를 새로고침 해주시기 바랍니다.",
          );
          onModalDone();
        }
      })
      .catch((err) => {
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        width: "18rem",
      }}
    >
      {loading && <Loading />}

      <div style={{ marginTop: "0.625rem" }}>
        <TextField
          labelWidth="4.5rem"
          textBoxWidth="12rem"
          labelText="지점"
          labelMarginRight="0rem"
          disabled
          defaultValue={reportState.branch_info.branch_name}
        />
      </div>
      <div style={{ marginTop: "0.625rem" }}>
        <TextField
          labelWidth="4.5rem"
          textBoxWidth="12rem"
          labelText="날짜"
          labelMarginRight="0rem"
          disabled
          defaultValue={reportState.base_date}
        />
      </div>
      <div style={{ marginTop: "0.625rem" }}>
        <ComboBox
          required
          labelText="입출구분"
          labelWidth="4.5rem"
          labelMarginRight="0rem"
          comboItemWidth="12rem"
          data={["수입", "지출"]}
          defaultValue={""}
          onChangeCallback={(e) => {
            vaultCashData.vault_type = e.target.value;
          }}
        />
      </div>
      <div style={{ marginTop: "0.5rem" }}>
        <NumberTextField
          isNumber
          labelWidth="4.5rem"
          textBoxWidth="12rem"
          labelText="금액"
          labelMarginRight="0rem"
          defaultValue={""}
          endAdornment="원"
          onChangeCallback={(e) => {
            vaultCashData.price = e;
          }}
        />
      </div>
      <div style={{ marginTop: "0.5rem" }}>
        <TextField
          labelWidth="4.5rem"
          textBoxWidth="12rem"
          labelText="내용"
          labelMarginRight="0rem"
          defaultValue={""}
          onChangeCallback={(e) => {
            vaultCashData.note = e;
          }}
        />
      </div>

      <div style={{ marginTop: "2rem", display: "flex", justifyContent: "center", gap: "0.3rem" }}>
        <NormalBtn
          name="저장"
          onClick={() => {
            onClickSave();
          }}
        />
        <NormalBtn
          name="취소"
          theme="white"
          onClick={() => {
            storesDispatch(globalModalOff());
          }}
        />
      </div>
    </div>
  );
};
export default AddVaultCashModal;

const vault_cash_init_state = {
  hq_info: {
    hq_id: "",
    hq_name: "",
  },
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  item_id: generateObjectZeroId(),
  vault_type: "",
  reg_date: "",
  is_expenditure: false,
  is_done: false,
  price: 0,
  note: "",
};
