import { contentTypeJson } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

// ==================== GET ====================
// [GET] 미납 현황 리스트 검색
export async function getUnpaidStatusList(queryData) {
  const { page, isExcel, searchParams } = queryData;
  const formData = new FormData();

  // formData.append("is_excel", isExcel ? isExcel : false);
  formData.append("page_num", page);
  formData.append("search_param", JSON.stringify(searchParams));

  return await HttpInstance.post(
    process.env.REACT_APP_USER_SERVER + "/member/search/unpaid?is_unpaid_reject=false",
    formData,
    contentTypeJson,
  );
}
// [GET] 미납금 제외 리스트 검색
export async function getUnpaidExclusionList(queryData) {
  const { page, isExcel, searchParams } = queryData;
  const formData = new FormData();

  // formData.append("is_excel", isExcel ? isExcel : false);
  formData.append("page_num", page);
  formData.append("search_param", JSON.stringify(searchParams));

  return await HttpInstance.post(
    process.env.REACT_APP_USER_SERVER + "/member/search/unpaid?is_unpaid_reject=true",
    formData,
    contentTypeJson,
  );
}

// ==================== PATCH ====================
// [PATCH] 미납금 제외 처리
export async function patchExcludeUnpaid(submitData) {
  return await HttpInstance.patch(
    process.env.REACT_APP_USER_SERVER + "/member/search/unpaid/exclusion",
    submitData,
    contentTypeJson,
  );
}
