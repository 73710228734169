import { useEffect, useState } from "react";

import * as lodash from "lodash";
import PaymentModal from "pages/ERP/MemberCommonItems/PaymentModal/PaymentModal";
import DueDateChangeModal from "./DueDateChangeModal";

const UnPayModalIndex = ({ modalSelect, modalParam, onModalDone }) => {
  const [paramData, setParamData] = useState(modalParam.modalParam);

  useEffect(() => {
    const editData = lodash.cloneDeep(modalParam.modalParam);
    setParamData(editData);
    return () => {};
  }, []);

  if (modalSelect === "납부예정일변경") {
    return <DueDateChangeModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "결제") {
    return <PaymentModal modalParam={paramData} onModalDone={onModalDone} />;
  } else {
    return <div>current ELSE</div>;
  }
};
export default UnPayModalIndex;
