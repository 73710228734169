import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { useLocation } from "react-router";
import SalaryInfoWrapper from "./SalaryInfoWrapper";

const SalaryInfo = () => {
  const { state } = useLocation();

  return (
    <PageFrame
      children={
        <div>
          <PageTitle titleText="급여관리" />
          <SalaryInfoWrapper is_edit={state.is_edit} salaryReportId={state.data.salary_report_id} />
        </div>
      }
    />
  );
};
export default SalaryInfo;
