import { useState } from "react";

import failIcon from "assets/icon/NO.svg";
import successIcon from "assets/icon/success.svg";
import IconButton from "components/Button/IconButton";
import CardFixed from "components/Card/CardFixed";
import {
  checkNumber,
  contentTypeJson,
  getCurrentDate,
  numberKeyValidation,
  restEmpty,
  simpleAlert,
  SubLine,
} from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import Popup from "components/ModalPopup/Popup";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect } from "react";
import DaumPostcode from "react-daum-postcode";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loadMemberViewStore } from "../Module/actions";

const PersonalInfo = ({ baseInfoState, memberId, onClickOpenModal }) => {
  const storesDispatch = useDispatch();
  const navigate = useNavigate();

  const [isModify, setIsModify] = useState(false);
  const [phoneCheckStatus, setPhoneCheckStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [modifiedData, setModifiedData] = useState({
    address_1: "",
    address_2: "",
    agree_marketing: false,
    agree_personal: false,
    birth: "",
    email: "",
    gender: "",
    notice: "",
    phone: "",
    name: "",
  });
  const onCheckPhone = async () => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_USER_SERVER + "/member/check_phone",
        {
          params: {
            phone: modifiedData.phone,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        const isDuplicate = res.data.data;
        if (isDuplicate === "N") {
          setPhoneCheckStatus("Y");
        } else {
          setPhoneCheckStatus("N");
        }
      }
    } catch (err) {
      console.log("err");
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  // 회원 기본정보 set
  const setModifiedInfo = () => {
    const info = {
      address_1: baseInfoState.base_info.personal_info.address_1,
      address_2: baseInfoState.base_info.personal_info.address_2,
      agree_marketing: baseInfoState.base_info.personal_info.agree_marketing,
      agree_personal: baseInfoState.base_info.personal_info.agree_personal,
      birth: baseInfoState.base_info.personal_info.birth,
      gender: baseInfoState.base_info.personal_info.gender,
      notice: baseInfoState.base_info.personal_info.notice,
      phone: baseInfoState.base_info.personal_info.phone,
      name: baseInfoState.base_info.personal_info.member_name,
    };
    setModifiedData(info);
  };

  useEffect(() => {
    setModifiedInfo();

    return () => {
      setIsModify(false);
    };
  }, [baseInfoState, memberId]);

  useEffect(() => {
    //수정모드에서 폰 중복검사
    if (isModify && modifiedData.phone.length === 13) {
      onCheckPhone();
    }
    //꽉 채우지 않으면 폰 체크 Status 초기화
    if (isModify && modifiedData.phone.length < 13) {
      setPhoneCheckStatus("");
    }
  }, [modifiedData.phone]);

  //회원 기본정보 수정 POST
  const modifyMemberInfo = async () => {
    try {
      //복사
      const baseInfo = _.cloneDeep(baseInfoState.base_info);
      baseInfo.personal_info.gender = modifiedData.gender;
      baseInfo.personal_info.address_1 = modifiedData.address_1;
      baseInfo.personal_info.address_2 = modifiedData.address_2;
      baseInfo.personal_info.agree_marketing = modifiedData.agree_marketing;
      baseInfo.personal_info.agree_personal = modifiedData.agree_personal;
      baseInfo.personal_info.birth = modifiedData.birth;
      baseInfo.personal_info.email = modifiedData.birth;
      baseInfo.personal_info.gender = modifiedData.gender;
      baseInfo.personal_info.notice = modifiedData.notice;
      baseInfo.personal_info.phone = modifiedData.phone;
      baseInfo.personal_info.member_name = modifiedData.name.trim();

      const formData = new FormData();
      formData.append("base_info", JSON.stringify(baseInfo));

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_USER_SERVER + "/member_info/edit",
        formData,
        contentTypeJson,
      );

      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //정보 업데이트
        // simpleAlert(storesDispatch, "알림", "변경이 완료되었습니다.");
        storesDispatch(loadMemberViewStore(memberId));
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const validationCheck = () => {
    if (
      !checkNumber(modifiedData.phone.substring(0, 3)) ||
      !checkNumber(modifiedData.phone.substring(4, 8)) ||
      !checkNumber(modifiedData.phone.substring(9, 14)) ||
      modifiedData.phone.length !== 13
    ) {
      simpleAlert(storesDispatch, "알림", "연락처 입력이 잘못되었습니다.");
    } else if (!modifiedData.phone) {
      simpleAlert(storesDispatch, "알림", "연락처를 입력해주세요.");
    } else if (modifiedData.phone && phoneCheckStatus === "N") {
      simpleAlert(storesDispatch, "알림", "중복된 연락처입니다.");
    } else if (!modifiedData.name) {
      simpleAlert(storesDispatch, "알림", "이름을 입력해주세요.");
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="저장하시겠습니까?" />,
          onBtnYes: () => {
            modifyMemberInfo();
            setIsModify(false);
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  //회원전환모달 POP
  const popConvertToMemeber = () => {
    const param = {
      title: "회원 전환",
      modalParam: {
        navigate,
        personal_info: baseInfoState.base_info.personal_info,
        member_name: baseInfoState.base_info.personal_info.member_name,
        member_phone: baseInfoState.base_info.personal_info.phone,
        member_id: baseInfoState.member_id,
        baseInfoState: baseInfoState,
      },
    };

    onClickOpenModal("회원전환", param);
  };

  const onClickCancelChange = () => {
    setModifiedInfo();
    setIsModify(false);
    setPhoneCheckStatus("");
  };

  const TopDom = () => {
    return (
      <div>
        {loading && <Loading />}

        {baseInfoState.member_type === "회원" ? (
          <div style={{ display: "flex", alignContent: "center", justifyContent: "space-between" }}>
            <Label labelText="기본정보" fontSize="1.1rem" fontWeight="bold" />
            {isModify ? (
              <div style={{ display: "flex" }}>
                <NormalBtn
                  name="저장"
                  onClick={() => {
                    validationCheck();
                  }}
                />
                <NormalBtn
                  margin="0 0 0 0.5rem"
                  name="취소"
                  onClick={() => {
                    onClickCancelChange();
                  }}
                />
              </div>
            ) : (
              <NormalBtn
                name=" 기본정보 수정"
                onClick={() => {
                  setIsModify(true);
                }}
              />
            )}
          </div>
        ) : (
          <div style={{ display: "flex", alignContent: "center", justifyContent: "space-between" }}>
            <Label labelText="기본정보" fontSize="1.1rem" fontWeight="bold" />
            <NormalBtn
              name="회원 전환"
              onClick={() => {
                popConvertToMemeber();
              }}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <CardFixed
      topDom={TopDom()}
      midDom={
        <PersonalInfoCardItem
          baseInfoState={baseInfoState}
          isModify={isModify}
          modifiedData={modifiedData}
          setModifiedData={setModifiedData}
          phoneCheckStatus={phoneCheckStatus}
        />
      }
      isLoading={false}
      rootStyle={{
        width: "100%",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};
export default PersonalInfo;

const PersonalInfoCardItem = ({
  baseInfoState,
  isModify,
  setModifiedData,
  modifiedData,
  phoneCheckStatus,
}) => {
  const storesDispatch = useDispatch();
  const leftMarginShort = "1.5rem";
  const textMarginRight = "2rem";
  const labelWidth = "3.5rem";
  const textBoxWidth = "12rem";

  const [isAddrPopup, setAddrPopup] = useState(false);

  //주소검색팝업 띄우는거
  const openAddrPopup = (val) => {
    setAddrPopup(val);
  };

  const handleComplete = (data) => {
    openAddrPopup(false);

    setModifiedData((cur) => {
      return { ...cur, address_1: data.address };
    });
  };

  const checkPhoneValidation = (value) => {
    const count = modifiedData.phone.split("-").length - 1; // "-" 개수 체크

    if (value.length === 0) {
      setModifiedData((cur) => {
        return { ...cur, phone: "" };
      });
    } else if (count === 0 && value.length === 3) {
      setModifiedData((cur) => {
        return { ...cur, phone: value + "-" };
      });
    } else if (count === 1 && value.length === 8) {
      setModifiedData((cur) => {
        return { ...cur, phone: value + "-" };
      });
    } else {
      setModifiedData((cur) => {
        return { ...cur, phone: value };
      });
    }
  };

  const checkHypenValidation = () => {
    const count = modifiedData.phone.split("-").length - 1; // "-" 개수 체크
    const phoneNumberList = modifiedData.phone.split("-"); // "-" 기준 분리한 폰번호 리스트

    // "-" 없을 때 첫번째 "-"삽입
    if (count === 0 && modifiedData.phone.length >= 4 && modifiedData.phone[3] !== "-") {
      setModifiedData((cur) => {
        return {
          ...cur,
          phone: modifiedData.phone.slice(0, 3) + "-" + modifiedData.phone.slice(3),
        };
      });
      return;
    }
    // "-" 한개 있는데 첫번째 "-"가 없으면 삽입
    if (
      count === 1 &&
      phoneNumberList[0].length >= 3 &&
      modifiedData.phone.length >= 4 &&
      modifiedData.phone[3] !== "-"
    ) {
      setModifiedData((cur) => {
        return {
          ...cur,
          phone: modifiedData.phone.slice(0, 3) + "-" + modifiedData.phone.slice(3),
        };
      });
      return;
    } else if (
      // "-" 한개 있는데 두번째 "-"가 없으면 삽입
      count === 1 &&
      modifiedData.phone.length >= 9 &&
      modifiedData.phone[8] !== "-"
    ) {
      setModifiedData((cur) => {
        return {
          ...cur,
          phone: modifiedData.phone.slice(0, 8) + "-" + modifiedData.phone.slice(8),
        };
      });
      return;
    }
    // "-" 2개 있고 각 폰번호 개수 체크
    if (count === 2 && phoneNumberList[1].length === 0) {
      setModifiedData((cur) => {
        return {
          ...cur,
          phone: phoneNumberList[0].slice(0, 3) + "-" + phoneNumberList[2] + "-",
        };
      });
    } else if (count === 2 && phoneNumberList[0].length > 3) {
      simpleAlert(storesDispatch, "알림", "연락처 입력이 잘못되었습니다.");
      setModifiedData((cur) => {
        return {
          ...cur,
          phone:
            phoneNumberList[0].slice(0, 3) + "-" + phoneNumberList[1] + "-" + phoneNumberList[2],
        };
      });
    } else if (count === 2 && phoneNumberList[1].length > 4) {
      simpleAlert(storesDispatch, "알림", "연락처 입력이 잘못되었습니다.");
      setModifiedData((cur) => {
        return {
          ...cur,
          phone:
            phoneNumberList[0] + "-" + phoneNumberList[1].slice(0, 4) + "-" + phoneNumberList[2],
        };
      });
    } else if (count === 2 && phoneNumberList[2].length > 4) {
      simpleAlert(storesDispatch, "알림", "연락처 입력이 잘못되었습니다.");
      setModifiedData((cur) => {
        return {
          ...cur,
          phone:
            phoneNumberList[0] + "-" + phoneNumberList[1] + "-" + phoneNumberList[2].slice(0, 4),
        };
      });
    }
  };

  // 연락처 "-" 유효성 검사
  useEffect(() => {
    checkHypenValidation();
  }, [modifiedData.phone]);

  return (
    <div>
      <Popup
        isOpen={isAddrPopup}
        onClose={() => {
          openAddrPopup(false);
        }}
        title="주소 검색"
        Content={
          <DaumPostcode
            onComplete={handleComplete}
            autoClose={false}
            style={{ width: "30rem", height: "35rem" }}
          />
        }
      />

      <div
        style={{
          display: "flex",
          marginTop: "1.3rem",
        }}
      >
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          textMarginRight={textMarginRight}
          labelText="지점"
          defaultValue={baseInfoState.branch_info.branch_name}
          disabled
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          textMarginRight={textMarginRight}
          labelText="구분"
          defaultValue={baseInfoState.member_type}
          disabled
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          textMarginRight={textMarginRight}
          labelText="출입방식"
          defaultValue={baseInfoState.access.access_method}
          disabled
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          textMarginRight={textMarginRight}
          labelText="카드번호"
          defaultValue={baseInfoState.access.card_no}
          disabled
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          labelText="연락처"
          maxLength={13}
          defaultValue={modifiedData.phone}
          onChangeCallback={(e) => {
            checkPhoneValidation(e);
          }}
          onKeyDown={(event) => {
            numberKeyValidation(event);
          }}
          disabled={!isModify}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          {isModify && phoneCheckStatus === "Y" ? (
            <img
              alt="O"
              src={successIcon}
              style={{
                display: "flex",
                width: "1.4rem",
                height: "1.4rem",
                marginRight: "0.5rem",
              }}
            />
          ) : phoneCheckStatus === "N" ? (
            <img
              alt="O"
              src={failIcon}
              style={{
                display: "flex",
                width: "1.4rem",
                height: "1.4rem",
                marginRight: "0.5rem",
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <SubLine margin="1rem" />

      <div
        style={{
          display: "flex",
        }}
      >
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          textMarginRight={textMarginRight}
          labelText="이름"
          defaultValue={modifiedData.name}
          onChangeCallback={(e) => {
            setModifiedData((cur) => {
              return { ...cur, name: e };
            });
          }}
          disabled={!isModify}
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          textMarginRight={textMarginRight}
          labelText="회원번호"
          defaultValue={baseInfoState.base_info.personal_info.member_no}
          disabled
        />

        <DatePicker
          labelText={"생년월일"}
          labelWidth={labelWidth}
          minWidth={textBoxWidth}
          textMarginRight={textMarginRight}
          disabled={!isModify}
          defaultValue={modifiedData.birth}
          minDate={"1900-01-01"}
          maxDate={getCurrentDate()}
          onChangeCallback={(e) => {
            setModifiedData((cur) => {
              return { ...cur, birth: e };
            });
          }}
        />

        <ComboBox
          labelWidth={labelWidth}
          comboItemWidth={textBoxWidth}
          textMarginRight={textMarginRight}
          labelText="성별"
          data={["남", "여"]}
          defaultValue={modifiedData.gender}
          // onChangeCallback={(e) => {
          //   baseInfoState.base_info.personal_info.gender = e.target.value;
          // }}
          onChangeCallback={(e) => {
            setModifiedData((cur) => {
              return { ...cur, gender: e.target.value };
            });
          }}
          disabled={!isModify}
        />
        <CheckBox
          marginTop="0rem"
          labelText="개인정보 활용 동의"
          ItemMarginLeft={leftMarginShort}
          defaultValue={modifiedData.agree_personal}
          // onChangeCallback={(e) => {
          //   setModifiedData((cur) => {
          //     return { ...cur, agree_personal: e.checked };
          //   });
          // }}
          disabled={true}
        />
        <CheckBox
          marginTop="0rem"
          labelText="마케팅 수신 동의"
          ItemMarginLeft={leftMarginShort}
          defaultValue={modifiedData.agree_marketing}
          // onChangeCallback={(e) => {
          //   setModifiedData((cur) => {
          //     return { ...cur, agree_marketing: e.checked };
          //   });
          // }}
          disabled={true}
        />
      </div>

      <div
        style={{
          display: "flex",
          marginTop: "0.7rem",
        }}
      >
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={"22.6rem"}
          textMarginRight={"0rem"}
          labelText={"주소"}
          // onChangeCallback={(e) => {
          //   setbaseInfoState.base_info.personal_info((cur) => {
          //     return { ...cur, address_2: e.target.value };
          //   });
          // }}
          defaultValue={modifiedData.address_1}
          disabled
        />
        <TextField
          textMarginRight={"0rem"}
          textBoxWidth={isModify ? "22.6rem" : "25rem"}
          onChangeCallback={(e) => {
            setModifiedData((cur) => {
              return { ...cur, address_2: e };
            });
          }}
          defaultValue={modifiedData.address_2}
          disabled={!isModify}
        />
        <div style={isModify ? { marginLeft: "0.2rem", marginRight: "2rem" } : {}}>
          <IconButton
            type="search"
            disabled={!isModify}
            onClick={() => {
              openAddrPopup(true);
            }}
            width={"2.2rem"}
            height={"2.2rem"}
          />
        </div>

        <TextField
          labelWidth={labelWidth}
          labelMarginRight={isModify ? "0.625rem" : "0.45rem"}
          textBoxWidth={"30rem"}
          labelText="특이사항"
          onChangeCallback={(e) => {
            setModifiedData((cur) => {
              return { ...cur, notice: e };
            });
          }}
          defaultValue={modifiedData.notice}
          disabled={!isModify}
        />

        <div style={{ position: "relative", top: "-5px" }}></div>
      </div>
    </div>
  );
};
