import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import CardFixed from "components/Card/CardFixed";
import { tabBorder, tabStyle } from "components/CommonComp";
import Loading from "components/Loading/Loading/Loading";
import A11yProps from "components/TabControl/A11yProps";
import TabPanel from "components/TabControl/TabPanel";
import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import GxHistView from "./ClassHistContents/GxList/GxHistView";
import OtHistView from "./ClassHistContents/OtList/OtHistView";
import PtHistView from "./ClassHistContents/PtList/PtHistView";
import { useClassHistStore } from "./modules/store/classHistState";

const ClassHistContentsView = ({ branchInfo }) => {
  const [tabIndex, setTabIndex] = useState(0);

  // 전역 상태 관리
  const { classHistLoading: loading } = useClassHistStore();

  const onChangeTabIndex = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <>
      {loading && <Loading />}
      <CardFixed
        midDom={
          <div>
            <Box sx={{ bgcolor: "background.paper" }}>
              <Tabs
                value={tabIndex}
                onChange={onChangeTabIndex}
                textColor="inherit"
                TabIndicatorProps={{
                  style: { backgroundColor: "rgba(0, 0, 0, 0)" },
                }}
              >
                <Tab label="OT" {...A11yProps(0)} style={tabStyle(0, tabIndex)} />
                <Tab label="PT" {...A11yProps(1)} style={tabStyle(1, tabIndex)} />
                <Tab label="GX" {...A11yProps(2)} style={tabStyle(2, tabIndex)} />
              </Tabs>
            </Box>
            <SwipeableViews
              index={tabIndex}
              onChangeIndex={onChangeTabIndex}
              style={tabBorder}
              animateTransitions={false}
            >
              <TabPanel value={tabIndex} index={0}>
                <OtHistView branchInfo={branchInfo} />
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <PtHistView branchInfo={branchInfo} />
              </TabPanel>
              <TabPanel value={tabIndex} index={2}>
                <GxHistView branchInfo={branchInfo} />
              </TabPanel>
            </SwipeableViews>
          </div>
        }
        isLoading={false}
        rootStyle={{
          width: "100%",
          marginTop: "0.625rem",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />
    </>
  );
};
export default ClassHistContentsView;
