///PAYMENT
export const subsPayInfoPush = (type_key, content_obj, ordIdx, mbshpIdx) => {
  return {
    type: "COMMON/SUBS_PAYMENT_INFO_LIST/PUSH",
    payload: {
      type_key: type_key,
      content_obj: content_obj,
      orderIdx: ordIdx,
      mbshpIdx: mbshpIdx,
    },
  };
};

export const subsPayInfoPop = (type_key) => {
  return {
    type: "COMMON/SUBS_PAYMENT_INFO_LIST/POP",
    type_key: type_key,
  };
};

export const subsPayInfoClear = (data, idx = 0) => {
  return {
    type: "COMMON/SUBS_PAYMENT_INFO_LIST/CLEAR",
    payload: data,
  };
};

export const subsPayInfoEdit = (type_key, content_obj, ordIdx, mbshpIdx) => {
  return {
    type: "COMMON/SUBS_PAYMENT_INFO_LIST/EDIT",
    payload: {
      type_key: type_key,
      content_obj: content_obj,
      orderIdx: ordIdx,
      mbshpIdx: mbshpIdx,
    },
  };
};

export const subsPayInfoList = (data) => {
  return {
    type: "COMMON/SUBS_PAYMENT_INFO_LIST/LIST",
    payload: data,
  };
};
