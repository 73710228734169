import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { generateObjectZeroId } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import FriendInfo from "pages/ERP/MemberCommonItems/FriendInfo";
import PriceDiscountInput from "pages/ERP/MemberRegister/Common/Etc/PriceDiscountInput";
import { useEffect, useRef, useState } from "react";

const SubscriptCardItem = ({
  memberInputState,
  memberInputStateAll,
  membershipIdx,
  hqBranchPolicy,
  payInfoListAction,
  subsPayInfoListAction,
  onClickOpenModal,
  selectorInfo,
  onChangeSubsFromDate,
  onChangeSubsDis,
  isAddPay,
  from_lock,
  onSubsItemChange,
  last_to_date,
}) => {
  const compWidthShort = "5rem";
  const compWidthMid = "11rem";
  const labelWidth = "4.5rem";

  const btnStyleClass = btnStyles();
  const [toDate, setToDate] = useState("");
  const [proratePrice, setProratePrice] = useState(
    memberInputState.membership_list.mbshp.subs_options.prorate_price,
  );
  const [otShow, setOtShow] = useState(false);
  // const [isPending, startTransition] = useTransition();
  const [mbshpSubsDis, setMbshpSubsDis] = useState(
    memberInputState.membership_list.mbshp.subs_options.subs_dis,
  );
  const [mbshpProrateDis, setMbshpProrateDis] = useState(
    memberInputState.membership_list.mbshp.subs_options.prorate_dis,
  );
  const [mbshpPreDis, setMbshpPreDis] = useState(
    memberInputState.membership_list.mbshp.subs_options.pre_dis,
  );
  const [contractDate, setContractDate] = useState({
    from: memberInputState.membership_list.mbshp.subs_options.contract_from_date,
    to: memberInputState.membership_list.mbshp.subs_options.contract_to_date,
  });

  const itemIdx = useRef();

  useEffect(() => {
    onChangeFromDate(memberInputState.membership_list.mbshp.from_date);
  }, [selectorInfo.item_name_idx]);

  useEffect(() => {
    if (itemIdx.current && memberInputState.membership_list.item_name_idx !== itemIdx.current) {
      setMbshpSubsDis(0);
      setMbshpProrateDis(0);
      setMbshpPreDis(0);
    }
    itemIdx.current = memberInputState.membership_list.item_name_idx;

    setContractDate({
      from: memberInputState.membership_list.mbshp.subs_options.contract_from_date,
      to: memberInputState.membership_list.mbshp.subs_options.contract_to_date,
    });
  }, [
    memberInputState.membership_list.mbshp.from_date,
    memberInputState.membership_list.item_name_idx,
  ]);

  const isOtShow = () => {
    let showItem = true;
    if (isAddPay === true && memberInputState.tab_info.is_new === false) {
      showItem = false;
    }
    // if (selectorInfo.mbshp_option === "일일권") {
    if (memberInputState.membership_list.mbshp_option === "일일권") {
      showItem = true;
    }
    if (from_lock) {
      showItem = false;
    }

    setOtShow(showItem);
  };

  useEffect(() => {
    isOtShow();
  }, [memberInputState.membership_list.mbshp_option]);

  function addMonths(numOfMonths, date = new Date()) {
    date.setMonth(date.getMonth() + numOfMonths);
    return date;
  }
  // function minDay1(date = new Date()) {
  //   date.setDate(date.getDate() - 1);
  //   return date;
  // }

  const onChangeFromDate = (fromDate) => {
    // let itemBasePeriod = membershipItemInfo.period_month;

    if (fromDate !== "") {
      // let fromDateItem = new Date(fromDate);
      // let toDateItem = addMonths(itemBasePeriod, fromDateItem);
      // memberInputState.membership_list.mbshp.to_date = formatDate(toDateItem);
      onChangeSubsFromDate();
      setProratePrice(memberInputState.membership_list.mbshp.subs_options.prorate_price);
      setToDate(memberInputState.membership_list.mbshp.to_date);
    }
  };
  const getOptionData = (is_lock) => {
    if (
      memberInputState.tab_info.is_add_pay === true &&
      memberInputState.tab_info.is_new === false
    ) {
      // addpaid 뺌
      // if (is_lock) {
      return memberInputState.purchase_item_list.item_info.mbshp_option;
      // } else {
      //   return memberInputState.purchase_item_addpaid.item_info.mbshp_option;
      // }
    } else {
      return memberInputState.purchase_item_list.item_info.mbshp_option;
    }
  };

  const periodFromLock = (is_lock) => {
    if (is_lock === true || from_lock === true) {
      return true;
    }
    return false;
  };

  const friendLock = (is_lock) => {
    if (is_lock === true || from_lock === true) {
      return true;
    }
    // if (selectorInfo.mbshp_option === "일일권") {
    if (memberInputState.membership_list.mbshp_option === "일일권") {
      return true;
    }
    return false;
  };

  const getOtStaffNameList = () => {
    const result = [...hqBranchPolicy.class_team_name_list];
    result.splice(0, 0, "미배정");
    return result;
  };

  const onChangeFriend = (memberInfo) => {
    memberInputStateAll.friend_info = memberInfo;
    // setFriendMemberInfo(memberInfo);
  };

  return (
    <div
      style={{
        // display:"flex"
        marginTop: "0.5rem",
      }}
    >
      <div
        style={{
          display: "flex",
          marginTop: "0.5rem",
        }}
      >
        <DatePicker
          // inputRef={tmTryTimeRef}
          labelWidth={labelWidth}
          textMarginRight="0rem"
          minWidth={"9rem"}
          labelText="유효기간"
          defaultValue={memberInputState.membership_list.mbshp.from_date}
          minDate={memberInputState.tab_info.idx > 0 ? last_to_date : undefined}
          onChangeCallback={(e) => {
            memberInputState.membership_list.mbshp.from_date = e;
            onChangeFromDate(e);
            setProratePrice(onChangeSubsDis("회원권"));
            onSubsItemChange();
          }}
          // disabled={!addPayState.is_add_pay_new}
          disabled={periodFromLock(memberInputState.membership_list.mbshp.is_lock)}
        />
        <TextField
          marginLeft="0.5rem"
          labelWidth="0.5rem"
          textBoxWidth="8rem"
          labelText="~"
          defaultValue={toDate}
          disabled
        />

        <PriceDiscountInput
          baseInfo={{
            tabTitle: memberInputState.tab_info.title,
            tabIdx: memberInputState.tab_info.idx,
            orderStartIdx: membershipIdx,
            type: "회원권",
            typeKey: "회원권" + membershipIdx.toString(),
            orderIdx: 0,
            payOrder: 6,
            label: "월 구독료",
            item_type:
              "회원권/구독형/월구독료/" +
              getOptionData(memberInputState.membership_list.mbshp.is_lock) +
              "/없음",
          }}
          standardPrice={
            memberInputState.purchase_item_list.item_info.subs_options.mbshp_subs_price
          }
          payInfoListAction={subsPayInfoListAction}
          onChangeCallback={(e) => {
            memberInputState.membership_list.mbshp.subs_options.subs_dis = e;
            setMbshpSubsDis(e);
            setProratePrice(onChangeSubsDis("회원권"));
          }}
          paramLock={memberInputState.membership_list.mbshp.is_lock}
          paramUse={memberInputState.membership_list.mbshp.is_use}
          discountValue={mbshpSubsDis}
        />
      </div>

      <div
        style={{
          display: "flex",
          marginTop: "0.5rem",
        }}
      >
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={"9rem"}
          labelText="약정기간"
          defaultValue={contractDate.from}
          disabled
        />
        <TextField
          labelWidth={"0.5rem"}
          textBoxWidth={"8rem"}
          labelText="~"
          defaultValue={contractDate.to}
          disabled
        />
        <PriceDiscountInput
          baseInfo={{
            tabTitle: memberInputState.tab_info.title,
            tabIdx: memberInputState.tab_info.idx,
            orderStartIdx: membershipIdx,
            type: memberInputState.tab_info.title + "회원권(일할)",
            typeKey: memberInputState.tab_info.title + "회원권(일할)",
            orderIdx: 0,
            payOrder: 6,
            label: "일할금액",
            item_type:
              "회원권/구독형/일할금액/" +
              getOptionData(memberInputState.membership_list.mbshp.is_lock) +
              "/없음",
          }}
          standardPrice={proratePrice}
          payInfoListAction={payInfoListAction}
          onChangeCallback={(e) => {
            memberInputState.membership_list.mbshp.subs_options.prorate_dis = e;
            setMbshpProrateDis(e);
          }}
          paramUse={memberInputState.membership_list.mbshp.is_use}
          paramLock={memberInputState.membership_list.mbshp.is_lock}
          discountValue={mbshpProrateDis}
        />
      </div>

      <div
        style={{
          display: "flex",
          marginTop: "0.625rem",
        }}
      >
        <TextField
          labelWidth={labelWidth}
          textMarginRight="0.25rem"
          textBoxWidth={compWidthShort}
          labelText={"선납기간"}
          isNumber={true}
          defaultValue={memberInputState.purchase_item_list.item_info.subs_options.pre_month}
          disabled
        />
        <Label labelText="개월" />

        <PriceDiscountInput
          baseInfo={{
            tabTitle: memberInputState.tab_info.title,
            tabIdx: memberInputState.tab_info.idx,
            orderStartIdx: membershipIdx,
            type: memberInputState.tab_info.title + "회원권(선납)",
            typeKey: memberInputState.tab_info.title + "회원권(선납)",
            orderIdx: 0,
            payOrder: 6,
            label: "선납금",
            item_type:
              "회원권/구독형/선납금/" +
              getOptionData(memberInputState.membership_list.mbshp.is_lock) +
              "/없음",
          }}
          standardPrice={memberInputState.purchase_item_list.item_info.subs_options.mbshp_pre_price}
          payInfoListAction={payInfoListAction}
          onChangeCallback={(e) => {
            memberInputState.membership_list.mbshp.subs_options.pre_dis = e;
            setMbshpPreDis(e);
          }}
          paramLock={memberInputState.membership_list.mbshp.is_lock}
          paramUse={memberInputState.membership_list.mbshp.is_use}
          discountValue={mbshpPreDis}
        />
      </div>

      {/* {isAddPay && !memberInputState.tab_info.is_new ? ( */}
      {!otShow ? (
        <></>
      ) : (
        <ComboBox
          required
          marginTop="0.5rem"
          labelWidth={labelWidth}
          comboItemWidth={"11rem"}
          labelText="OT 담당자"
          data={getOtStaffNameList()}
          onChangeCallback={(e) => {
            // console.log(e);
            const index = hqBranchPolicy.class_team_name_list
              .map((item) => item)
              .indexOf(e.target.value);
            if (index !== -1) {
              memberInputStateAll.ot_staff.staff_id =
                hqBranchPolicy.class_team_list.team[index].staff_id;
              memberInputStateAll.ot_staff.staff_name =
                hqBranchPolicy.class_team_list.team[index].staff_name;
            } else {
              memberInputStateAll.ot_staff.staff_id = generateObjectZeroId();
              memberInputStateAll.ot_staff.staff_name = "미배정";
            }
          }}
          disabled={memberInputState.membership_list.mbshp.is_lock}
        />
      )}
      {friendLock(isAddPay) ? (
        <></>
      ) : (
        <FriendInfo onClickOpenModal={onClickOpenModal} onChangeFriend={onChangeFriend} />
      )}
    </div>
  );
};

export default SubscriptCardItem;
