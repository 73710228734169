import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { compareTimeBigger, simpleAlert } from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import Label from "components/LabelInput/Label";
import TimePicker from "components/LabelInput/TimePicker";
import SubTitle from "components/PageTitle/SubTitle";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

const WorkDaySelector = ({ pageMode, dayFlagList, setDayFlagList, workInfoStore }) => {
  const btnStyleClass = btnStyles();

  return (
    <div style={{ display: "flex", marginTop: "0.5rem" }}>
      <div style={{}}>
        <Button
          style={{ borderRadius: "0.3125rem 0 0 0.3125rem" }}
          className={clsx({
            [btnStyleClass.btnGrpActive]: true,
            [btnStyleClass.buttonType7]: true,
            [btnStyleClass.btnGrpDeactive]: !dayFlagList[0],
            [btnStyleClass.btnGrpDisabled]:
              dayFlagList[0] && (pageMode === "보기" || pageMode === "이력"),
          })}
          onClick={() => {
            let arr = [...dayFlagList];
            let setData = !arr[0];
            arr[0] = setData;
            workInfoStore.time_list[0].is_use = setData;
            setDayFlagList([...arr]);
          }}
          disabled={pageMode === "보기" || pageMode === "이력"}
        >
          월
        </Button>
      </div>
      <div style={{}}>
        <Button
          className={clsx({
            [btnStyleClass.btnGrpActive]: true,
            [btnStyleClass.buttonType7]: true,
            [btnStyleClass.btnGrpDeactive]: !dayFlagList[1],
            [btnStyleClass.btnGrpDisabled]:
              dayFlagList[1] && (pageMode === "보기" || pageMode === "이력"),
          })}
          onClick={() => {
            let arr = [...dayFlagList];
            let setData = !arr[1];
            arr[1] = setData;
            workInfoStore.time_list[1].is_use = setData;
            setDayFlagList([...arr]);
          }}
          disabled={pageMode === "보기" || pageMode === "이력"}
        >
          화
        </Button>
      </div>
      <div style={{}}>
        <Button
          className={clsx({
            [btnStyleClass.btnGrpActive]: true,
            [btnStyleClass.buttonType7]: true,
            [btnStyleClass.btnGrpDeactive]: !dayFlagList[2],
            [btnStyleClass.btnGrpDisabled]:
              dayFlagList[2] && (pageMode === "보기" || pageMode === "이력"),
          })}
          onClick={() => {
            let arr = [...dayFlagList];
            let setData = !arr[2];
            arr[2] = setData;
            workInfoStore.time_list[2].is_use = setData;
            setDayFlagList([...arr]);
          }}
          disabled={pageMode === "보기" || pageMode === "이력"}
        >
          수
        </Button>
      </div>
      <div style={{}}>
        <Button
          className={clsx({
            [btnStyleClass.btnGrpActive]: true,
            [btnStyleClass.buttonType7]: true,
            [btnStyleClass.btnGrpDeactive]: !dayFlagList[3],
            [btnStyleClass.btnGrpDisabled]:
              dayFlagList[3] && (pageMode === "보기" || pageMode === "이력"),
          })}
          onClick={() => {
            let arr = [...dayFlagList];
            let setData = !arr[3];
            arr[3] = setData;
            workInfoStore.time_list[3].is_use = setData;
            setDayFlagList([...arr]);
          }}
          disabled={pageMode === "보기" || pageMode === "이력"}
        >
          목
        </Button>
      </div>
      <div style={{}}>
        <Button
          className={clsx({
            [btnStyleClass.btnGrpActive]: true,
            [btnStyleClass.buttonType7]: true,
            [btnStyleClass.btnGrpDeactive]: !dayFlagList[4],
            [btnStyleClass.btnGrpDisabled]:
              dayFlagList[4] && (pageMode === "보기" || pageMode === "이력"),
          })}
          onClick={() => {
            let arr = [...dayFlagList];
            let setData = !arr[4];
            arr[4] = setData;
            workInfoStore.time_list[4].is_use = setData;
            setDayFlagList([...arr]);
          }}
          disabled={pageMode === "보기" || pageMode === "이력"}
        >
          금
        </Button>
      </div>
      <div style={{}}>
        <Button
          className={clsx({
            [btnStyleClass.btnGrpActive]: true,
            [btnStyleClass.buttonType7]: true,
            [btnStyleClass.btnGrpDeactive]: !dayFlagList[5],
            [btnStyleClass.btnGrpDisabled]:
              dayFlagList[5] && (pageMode === "보기" || pageMode === "이력"),
          })}
          onClick={() => {
            let arr = [...dayFlagList];
            let setData = !arr[5];
            arr[5] = setData;
            workInfoStore.time_list[5].is_use = setData;
            setDayFlagList([...arr]);
          }}
          disabled={pageMode === "보기" || pageMode === "이력"}
        >
          토
        </Button>
      </div>
      <div style={{}}>
        <Button
          className={clsx({
            [btnStyleClass.btnGrpActive]: true,
            [btnStyleClass.buttonType7]: true,
            [btnStyleClass.btnGrpDeactive]: !dayFlagList[6],
            [btnStyleClass.btnGrpDisabled]:
              dayFlagList[6] && (pageMode === "보기" || pageMode === "이력"),
          })}
          onClick={() => {
            let arr = [...dayFlagList];
            let setData = !arr[6];
            arr[6] = setData;
            workInfoStore.time_list[6].is_use = setData;
            setDayFlagList([...arr]);
          }}
          disabled={pageMode === "보기" || pageMode === "이력"}
        >
          일
        </Button>
      </div>
      <div style={{}}>
        <Button
          style={{ borderRadius: "0 0.3125rem 0.3125rem 0" }}
          className={clsx({
            [btnStyleClass.btnGrpActive]: true,
            [btnStyleClass.buttonType8]: true,
            [btnStyleClass.btnGrpDeactive]: !dayFlagList[7],
            [btnStyleClass.btnGrpDisabled]:
              dayFlagList[7] && (pageMode === "보기" || pageMode === "이력"),
          })}
          onClick={() => {
            let arr = [...dayFlagList];
            let setData = !arr[7];
            arr[7] = setData;
            workInfoStore.time_list[7].is_use = setData;
            setDayFlagList([...arr]);
          }}
          disabled={pageMode === "보기" || pageMode === "이력"}
        >
          공휴일
        </Button>
      </div>
    </div>
  );
};

const WorkDayTypeSelector = ({
  pageMode,
  workInfoStore,
  weekFlagList,
  setWeekFlagList,
  dayFlagList,
  setDayFlagList,
  setWorkInfoStore,
}) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();

  const [applyAllTimes, setApplyAllTimes] = useState({ from_time: "00:00", end_time: "23:59" });

  return (
    <div>
      <div style={{ display: "flex" }}>
        <Button
          style={{ borderRadius: "0.3125rem 0 0 0.3125rem" }}
          className={clsx({
            [btnStyleClass.btnGrpActive]: true,
            [btnStyleClass.buttonType7]: true,
            [btnStyleClass.btnGrpDeactive]: !weekFlagList[0],
            [btnStyleClass.btnGrpDisabled]:
              weekFlagList[0] && (pageMode === "보기" || pageMode === "이력"),
          })}
          onClick={() => {
            let arr = [...weekFlagList];
            let setData = !arr[0];
            arr[0] = setData;
            setWeekFlagList([...arr]);

            let daysArr = [...dayFlagList];
            daysArr[0] = setData;
            daysArr[1] = setData;
            daysArr[2] = setData;
            daysArr[3] = setData;
            daysArr[4] = setData;
            setDayFlagList([...daysArr]);
            workInfoStore.time_list[0].is_use = setData;
            workInfoStore.time_list[1].is_use = setData;
            workInfoStore.time_list[2].is_use = setData;
            workInfoStore.time_list[3].is_use = setData;
            workInfoStore.time_list[4].is_use = setData;
          }}
          disabled={pageMode === "보기" || pageMode === "이력"}
        >
          평일
        </Button>
        <Button
          style={{ borderRadius: "0 0.3125rem 0.3125rem 0" }}
          className={clsx({
            [btnStyleClass.btnGrpActive]: true,
            [btnStyleClass.buttonType7]: true,
            [btnStyleClass.btnGrpDeactive]: !weekFlagList[1],
            [btnStyleClass.btnGrpDisabled]:
              weekFlagList[1] && (pageMode === "보기" || pageMode === "이력"),
          })}
          onClick={() => {
            let arr = [...weekFlagList];
            let setData = !arr[1];
            arr[1] = setData;
            setWeekFlagList([...arr]);

            let daysArr = [...dayFlagList];
            daysArr[5] = setData;
            daysArr[6] = setData;
            daysArr[7] = setData;
            setDayFlagList([...daysArr]);
            workInfoStore.time_list[5].is_use = setData;
            workInfoStore.time_list[6].is_use = setData;
            workInfoStore.time_list[7].is_use = setData;
          }}
          disabled={pageMode === "보기" || pageMode === "이력"}
        >
          주말
        </Button>

        {/* 임시 Blocked */}
        <div style={{ display: "flex", marginLeft: "auto" }}>
          <TimePicker
            labelWidth={"4rem"}
            labelText="근무 시간"
            textBoxWidth="9.5rem"
            defaultValue={applyAllTimes.from_time}
            onChange={(e) => {
              setApplyAllTimes((prev) => {
                return { ...prev, from_time: e };
              });
            }}
            disabled={pageMode === "보기" || pageMode === "이력"}
          />
          <TimePicker
            labelWidth={"0.5rem"}
            labelText="~"
            textBoxWidth="9.5rem"
            defaultValue={applyAllTimes.end_time}
            onChange={(e) => {
              setApplyAllTimes((prev) => {
                return { ...prev, end_time: e };
              });
            }}
            disabled={pageMode === "보기" || pageMode === "이력"}
          />
          <Button
            className={clsx({
              [btnStyleClass.btnRoot]: true,
              [btnStyleClass.buttonType6]: true,
              [btnStyleClass.disabled]: pageMode === "보기" || pageMode === "이력",
            })}
            onClick={() => {
              let isSelectWorkTime = false;
              // 선택된 요일 시간 모두 적용
              setWorkInfoStore((prev) => {
                const newArray = [];
                workInfoStore.time_list.forEach((time) => {
                  if (time.is_use) {
                    time.from_time = applyAllTimes.from_time;
                    time.end_time = applyAllTimes.end_time;
                    isSelectWorkTime = true;
                  }
                  newArray.push(time);
                });

                return {
                  ...prev,
                  contents: {
                    ...prev.contents,
                    work_info_obj: {
                      ...prev.contents.work_info_obj,
                      time_list: newArray,
                    },
                  },
                };
              });

              simpleAlert(
                storesDispatch,
                "알림",
                `${
                  isSelectWorkTime ? "근무 시간이 모두 적용되었습니다." : "선택된 요일이 없습니다."
                }`,
              );
            }}
            disabled={pageMode === "보기" || pageMode === "이력"}
          >
            모두 적용
          </Button>
        </div>
      </div>
    </div>
  );
};

const WorkDayTime = ({ pageMode, dayFlag, workInfoStore, dayName, idx }) => {
  const storesDispatch = useDispatch();
  const fromTimeRef = useRef();
  const endTimeRef = useRef();
  const textBoxWidth = "9.5rem";

  if (workInfoStore.time_list.length === 0) {
    // console.log(workInfoStore.time_list);
    return <div></div>;
  }

  return (
    <div style={{ display: "flex", marginLeft: "1rem" }}>
      <TimePicker
        inputRef={fromTimeRef}
        labelWidth={"4rem"}
        labelText={dayName}
        textMarginRight="0rem"
        textBoxWidth={textBoxWidth}
        defaultValue={workInfoStore.time_list[idx].from_time}
        onBlur={(e) => {
          if (compareTimeBigger(e, workInfoStore.time_list[idx].end_time)) {
            storesDispatch(
              globalAlertOn({
                show: true,
                titleText: "알림",
                bodyText: <Label labelText="근무 종료시간이 시작시간보다 빠릅니다." />,
                onBtnOk: () => {},
              }),
            );
            fromTimeRef.current.focus();
          } else {
            workInfoStore.time_list[idx].from_time = e;
          }
        }}
        disabled={!dayFlag || pageMode === "보기" || pageMode === "이력"}
      />
      <TimePicker
        inputRef={endTimeRef}
        marginLeft={"0.5rem"}
        labelWidth={"0.5rem"}
        labelText="~"
        textMarginRight="0rem"
        textBoxWidth={textBoxWidth}
        defaultValue={workInfoStore.time_list[idx].end_time}
        onBlur={(e) => {
          if (compareTimeBigger(workInfoStore.time_list[idx].from_time, e)) {
            storesDispatch(
              globalAlertOn({
                show: true,
                titleText: "알림",
                bodyText: <Label labelText="근무 종료시간이 시작시간보다 빠릅니다." />,
                onBtnOk: () => {},
              }),
            );
            endTimeRef.current.focus();
          } else {
            workInfoStore.time_list[idx].end_time = e;
          }
        }}
        disabled={!dayFlag || pageMode === "보기" || pageMode === "이력"}
      />
    </div>
  );
};

const WorkTime = ({ pageMode, workInfoStore, setWorkInfoStore }) => {
  const isOnWeekDay =
    workInfoStore.time_list[0].is_use &&
    workInfoStore.time_list[1].is_use &&
    workInfoStore.time_list[2].is_use &&
    workInfoStore.time_list[3].is_use &&
    workInfoStore.time_list[4].is_use
      ? true
      : false;
  const isOnWeekend =
    workInfoStore.time_list[5].is_use &&
    workInfoStore.time_list[6].is_use &&
    workInfoStore.time_list[7].is_use
      ? true
      : false;

  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);
  const [showWorkTime, setShowWorkTime] = useState(workInfoStore.work_time_use);
  const [weekFlagList, setWeekFlagList] = useState([isOnWeekDay, isOnWeekend]);
  const [dayFlagList, setDayFlagList] = useState([
    workInfoStore.time_list[0].is_use,
    workInfoStore.time_list[1].is_use,
    workInfoStore.time_list[2].is_use,
    workInfoStore.time_list[3].is_use,
    workInfoStore.time_list[4].is_use,
    workInfoStore.time_list[5].is_use,
    workInfoStore.time_list[6].is_use,
    workInfoStore.time_list[7].is_use,
  ]);

  useEffect(() => {
    setShowWorkTime(workInfoStore.work_time_use);
    setWeekFlagList([isOnWeekDay, isOnWeekend]);
    setDayFlagList([
      workInfoStore.time_list[0].is_use,
      workInfoStore.time_list[1].is_use,
      workInfoStore.time_list[2].is_use,
      workInfoStore.time_list[3].is_use,
      workInfoStore.time_list[4].is_use,
      workInfoStore.time_list[5].is_use,
      workInfoStore.time_list[6].is_use,
      workInfoStore.time_list[7].is_use,
    ]);
  }, [workInfoStore]);

  // 첫 페이지 진입 시 근무 끝 시간은 오후 23:59분 세팅
  useEffect(() => {
    if (showWorkTime && isFirstEnterPage) {
      workInfoStore.time_list.forEach((time) => {
        if (time.end_time === "00:00") {
          time.end_time = "23:59";
        }
      });
      setIsFirstEnterPage(false);
    }
  }, [showWorkTime]);

  const gxLabel = "* GX수업시간은 아래 “GX수당”에 입력해주시기 바랍니다.";
  const gxText = "근무 요일 및 시간";

  return (
    <div
      style={{
        marginTop: "1.625rem",
      }}
    >
      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="근무요일 및 시간" />
      </div>

      <div style={{ display: "flex" }}>
        <CheckBox
          //   inputRef={refStatus.pt_required}
          marginTop="0rem"
          labelText={gxText}
          defaultValue={workInfoStore.work_time_use}
          onChange={(e) => {
            workInfoStore.work_time_use = e.target.checked;
            setShowWorkTime(workInfoStore.work_time_use);
          }}
          disabled={pageMode === "보기" || pageMode === "이력"}
        />
        <Label labelText={gxLabel} color="red" fontSize="0.8rem" />
      </div>

      {!showWorkTime ? (
        <></>
      ) : (
        <div
          style={{
            marginLeft: "0.25rem",
            border: "1px solid #bbb",
            padding: "1rem",
            width: "59.25rem",
          }}
        >
          <WorkDayTypeSelector
            pageMode={pageMode}
            workInfoStore={workInfoStore}
            weekFlagList={weekFlagList}
            setWeekFlagList={setWeekFlagList}
            dayFlagList={dayFlagList}
            setDayFlagList={setDayFlagList}
            setWorkInfoStore={setWorkInfoStore}
          />
          <WorkDaySelector
            pageMode={pageMode}
            dayFlagList={dayFlagList}
            setDayFlagList={setDayFlagList}
            workInfoStore={workInfoStore}
          />

          <div style={{ display: "flex", marginTop: "0.5rem" }}>
            <div style={{ border: "1px solid #bbb", padding: "0.75rem", width: "27.5rem" }}>
              <WorkDayTime
                pageMode={pageMode}
                dayFlag={dayFlagList[0]}
                workInfoStore={workInfoStore}
                dayName={"월요일"}
                idx={0}
              />
              <WorkDayTime
                pageMode={pageMode}
                dayFlag={dayFlagList[1]}
                workInfoStore={workInfoStore}
                dayName={"화요일"}
                idx={1}
              />
              <WorkDayTime
                pageMode={pageMode}
                dayFlag={dayFlagList[2]}
                workInfoStore={workInfoStore}
                dayName={"수요일"}
                idx={2}
              />
              <WorkDayTime
                pageMode={pageMode}
                dayFlag={dayFlagList[3]}
                workInfoStore={workInfoStore}
                dayName={"목요일"}
                idx={3}
              />
              <WorkDayTime
                pageMode={pageMode}
                dayFlag={dayFlagList[4]}
                workInfoStore={workInfoStore}
                dayName={"금요일"}
                idx={4}
              />
            </div>

            <div
              style={{
                border: "1px solid #bbb",
                padding: "0.75rem",
                marginLeft: "1rem",
                width: "27.5rem",
              }}
            >
              <WorkDayTime
                pageMode={pageMode}
                dayFlag={dayFlagList[5]}
                workInfoStore={workInfoStore}
                dayName={"토요일"}
                idx={5}
              />
              <WorkDayTime
                pageMode={pageMode}
                dayFlag={dayFlagList[6]}
                workInfoStore={workInfoStore}
                dayName={"일요일"}
                idx={6}
              />
              <WorkDayTime
                pageMode={pageMode}
                dayFlag={dayFlagList[7]}
                workInfoStore={workInfoStore}
                dayName={"공휴일"}
                idx={7}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkTime;
