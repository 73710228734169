import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { tabBorder, tabStyle } from "components/CommonComp";
import TabPanel from "components/TabControl/TabPanel";
import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import PayHistoryTab from "./PayHistoryTab";
import PayManageTab from "./PayManageTab";

const SubsPayManageModal = ({ modalParam, onModalDone }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const onChangeTabIndex = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <div
      style={{
        width: "75.5rem",
      }}
    >
      <Box sx={{ bgcolor: "background.paper" }}>
        <Tabs
          value={tabIndex}
          onChange={onChangeTabIndex}
          textColor="inherit"
          sx={{ minHeight: "40px" }}
          TabIndicatorProps={{
            style: { backgroundColor: "rgba(0, 0, 0, 0)" },
          }}
        >
          <Tab label="정기결제수단" style={tabStyle(0, tabIndex)} />
          <Tab label="정기결제 이력" style={tabStyle(1, tabIndex)} />
        </Tabs>
      </Box>

      <SwipeableViews
        index={tabIndex}
        onChangeIndex={onChangeTabIndex}
        style={tabBorder}
        animateTransitions={false}
      >
        <TabPanel value={tabIndex} index={0}>
          <PayManageTab modalParam={modalParam} onModalDone={onModalDone} />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          {/* <PayHistoryTab modalParam={modalParam} onModalDone={onModalDone} /> */}
          <PayHistoryTab
            modalParam={{
              params: {
                member_id: modalParam.active_info.member_id,
              },
            }}
            onModalDone={onModalDone}
          />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
};
export default SubsPayManageModal;
