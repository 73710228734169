import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import {
  checkFileSize,
  contentTypeJson,
  getDocType,
  getDocTypeKor,
  restEmpty,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import {
  APPROVAL_TYPE_COOPERATION,
  APPROVAL_TYPE_EXP,
  APPROVAL_TYPE_HOLIDAY,
  APPROVAL_TYPE_IMP,
  APPROVAL_TYPE_INSURANCE,
  APPROVAL_TYPE_ORDER,
  APPROVAL_TYPE_REFUND_TRANS,
  APPROVAL_TYPE_VAULT_CASH,
} from "components/CommonLib/Consts";
import { getUploadedFiles, uploadFile } from "components/File/uploadFile";
import Label from "components/LabelInput/Label";
import TextArea from "components/LabelInput/TextArea";
import Loading from "components/Loading/Loading/Loading";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import ApprovalSelectorView from "pages/CommonModal/Approval/ApprovalSelectorView";
import FileUploadBox from "pages/NoticeBoard/CommonItem/FileUploadBox";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getIsViewDelete } from "../ApprovalCommon/checker";
import ApproveActionModal from "../Modal/ApproveActionModal";
import FavoriteDraftDetailModal from "../Modal/FavoriteDraftDetailModal";
import {
  consult_state,
  day_off_state,
  insurance_state,
  order_state,
  spend_proof_state,
  transfer_state,
  vault_cash_state,
  work_assist_state,
} from "../Module/reducers/approval_content_state";
import { approval_act_permission, approval_doc_state } from "../Module/reducers/approval_doc_state";
import ApprovalButtonSet from "./ApprovalButtonSet";
import ApprovalContentWrapper from "./ApprovalContentWrapper";
import { validation } from "./ApprovalInfoValidation";
import CommentForm from "./CommentForm";
import DraftInfo from "./DraftInfo";
import DraftLine from "./DraftLine";

const ApprovalInfo = ({ mode, setMode, docType, approvalId, isCashbook }) => {
  const navigate = useNavigate();
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const viewModelRef = useRef();

  const docId = useRef("");
  const [cardTitle, setCardTitle] = useState("결재 작성하기");
  const [baseState, setBaseState] = useState(_.cloneDeep(approval_doc_state));
  const [permissionAct, setPermissionAct] = useState(_.cloneDeep(approval_act_permission));
  const [contentState, setContentState] = useState();
  const [curContent, setCurContent] = useState(docType || "");
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });
  const [approverList, setApproverList] = useState({
    receiver_list: [],
    approver_list: [],
    viewer_list: [],
  });

  const [fileList, setFileList] = useState([]);
  const [commentList, setCommentList] = useState([]);
  const [favoriteLineList, setFavoriteLineList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getApprovalContent();
    getFavoriteLineList();
  }, []);

  useEffect(() => {
    if (mode === "view") {
      setCardTitle("양식명 : " + getDocTypeKor(docType));
    } else {
      setCardTitle("결재 작성하기");
    }
  }, [mode]);

  const getContentApi = (docType) => {
    let getApi = "";
    switch (docType) {
      case "consult":
        getApi = "/approval/contents/expenditure";
        break;
      case "spendProof":
        getApi = "/approval/contents/implementation";
        break;
      case "transferReq":
        getApi = "/approval/contents/transfer_req";
        break;
      case "insuranceReq":
        getApi = "/approval/contents/insurance";
        break;
      case "dayOffReq":
        getApi = "/approval/contents/holiday";
        break;
      case "workAssistReq":
        getApi = "/approval/contents/cooperation";
        break;
      case "orderReq":
        getApi = "/approval/contents/order";
        break;
      case "personnelWorkReq":
        getApi = "/approval/contents/staff_info";
        setCurContent("personnelWorkReq");
        break;
      case "salaryReport":
        getApi = "/approval/contents/salary_report";
        setCurContent("salaryReport");
        break;
      case "vaultCash":
        getApi = "/approval/contents/vault_cash";
        break;

      default:
        console.log("getContentApi Error!!! - docType : " + docType);
        break;
    }
    return getApi;
  };

  // GET DATA
  const getApprovalContent = () => {
    setLoading(true);

    HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/approval/get_approval_info", {
      params: { approval_docs_id: approvalId },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          if (res.data.data.approval_status === "임시저장") {
            setMode("edit");
          }
          setPermissionAct(res.data.approval_permission);
          setBaseState(res.data.data);

          // 파일 리스트 ("file" 필드는 파일 업로드 시 raw file을 넣는 공간인데, Get했을 때는 null로 초기화)
          let tempFileList = [];
          if (res.data.data.file_list) {
            for (let i = 0; i < res.data.data.file_list.length; i++) {
              tempFileList.push({
                ...res.data.data.file_list[i],
                file: { name: res.data.data.file_list[i].file_info.file_name },
              });
            }
          }
          setFileList(tempFileList);

          if (res.data.data.approval_comment_list) {
            setCommentList(res.data.data.approval_comment_list);
          }
          if (res.data.data.approval_draft_line) {
            setApproverList({
              receiver_list: res.data.data.approval_draft_line.receiver,
              approver_list: res.data.data.approval_draft_line.approver,
              viewer_list: res.data.data.approval_draft_line.viewer,
            });
          }

          docId.current = res.data.data.doc_info.doc_id;

          const curDocTypeEng = getDocType(res.data.data.approval_type);
          const getApi = getContentApi(curDocTypeEng);

          if (getApi !== "") {
            HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + getApi, {
              params: { doc_id: docId.current, approval_docs_id: approvalId },
            })
              .then((res) => {
                if (!restEmpty(res.data.data)) {
                  setContentState(res.data.data);
                }
              })
              .catch((err) => {
                console.log(err);
                simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
              });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getFavoriteLineList = () => {
    setLoading(true);

    HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/approval/draft/line/list", {
      params: {},
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setFavoriteLineList(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onModalDone = (result) => {
    baseState.approval_draft_line.approver = result.approval_info.approver_list;
    baseState.approval_draft_line.receiver = result.approval_info.receiver_list;
    baseState.approval_draft_line.viewer = result.approval_info.viewer_list;
    setApproverList(result.approval_info);
    viewModelRef.current.setClose();
  };

  const onSaveFavorite = (data) => {
    storesDispatch(globalModalOff());
  };

  const onOpenFavorite = (approval_info) => {
    if (
      approval_info.approver_list.length === 0 &&
      approval_info.receiver_list.length === 0 &&
      approval_info.viewer_list.length === 0
    ) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="선택된 결재/열람/수신자가 없습니다." />,
          onBtnOk: () => {},
        }),
      );
      return;
    }

    const param = {
      mode: "approval",
      canEdit: true,
      approval_info: approval_info,
    };

    storesDispatch(
      globalModalOn({
        show: true,
        title: "자주 쓰는 결재선 저장",
        Content: <FavoriteDraftDetailModal modalParam={param} onModalDone={onSaveFavorite} />,
      }),
    );
  };

  const onClickSelectDraftLine = () => {
    const param = {
      mode: "approval",
      approval_info: _.cloneDeep(approverList),
      onClickSaveFavorite: onOpenFavorite,
    };

    setModalInfo({
      show: true,
      title: "결재선 직접 선택",
      Content: <ApprovalSelectorView modalParam={param} onModalDone={onModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  //
  // 화면 상단 액션
  //
  const onClickApproveAction = (action) => {
    const param = {
      action: action,
      docId: docId.current,
      approvalDocsId: baseState.approval_docs_id,
    };

    setModalInfo({
      show: true,
      title: action,
      Content: (
        <ApproveActionModal
          modalParam={param}
          onModalDone={() => {
            viewModelRef.current.setClose();
            navigate("/approval_list");
          }}
        />
      ),
      callback: null,
      Buttons: undefined,
    });
  };

  const onClickRetrieve = () => {
    simpleAlert(storesDispatch, "알림", "회수 처리하시겠습니까?", false, undefined, doRetrieve);
  };

  const doRetrieve = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("approval_docs_id", baseState.approval_docs_id);
    await HttpInstance.post(
      process.env.REACT_APP_STAFF_SERVER + "/approval/execute/retrieve/",
      formData,
      contentTypeJson,
    )
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          storesDispatch(
            globalAlertOn({
              show: true,
              titleText: "알림",
              bodyText: (
                <>
                  <Label labelText="회수 처리가 완료되었습니다." />
                  <Label labelText="전자결재 목록으로 이동합니다." />
                </>
              ),
              reqConfirm: true,
              onBtnOk: () => {
                navigate("/approval_list");
              },
            }),
          );
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickDelete = () => {
    simpleAlert(storesDispatch, "알림", "삭제하시겠습니까?", false, undefined, () => {
      doDelete(storesDispatch, globalAlertOn, navigate, setLoading, baseState);
    });
  };

  const doDelete = async (storesDispatch, globalAlertOn, navigate, setLoading, baseState) => {
    setLoading(true);
    await HttpInstance.delete(
      process.env.REACT_APP_STAFF_SERVER + "/approval/" + baseState.approval_docs_id,
      contentTypeJson,
    )
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          simpleAlert(
            storesDispatch,
            "알림",
            "삭제가 완료되었습니다.\n전자결재 목록으로 이동합니다.",
            false,
            () => {
              navigate("/approval_list");
            },
          );
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //
  // 화면 하단 액션
  //
  const onClickSave = (doAction) => {
    const validationRes = validation(curContent, baseState, contentState);
    let doDraft = false;
    if (doAction === "기안") {
      doDraft = true;
    }

    if (validationRes) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText={validationRes} />,
          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText={doAction + "하시겠습니까?"} />,
          onBtnYes: () => {
            onSave(doDraft, doAction);
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const onSave = async (doDraft, doAction) => {
    // doDraft - true:기안 / false:임시저장

    let postApi = "";
    switch (curContent) {
      case "consult":
        postApi = "/approval/insert/expenditure";
        break;
      case "spendProof":
        postApi = "/approval/insert/implementation";
        break;
      case "transferReq":
        postApi = "/approval/insert/refund_transfer";
        break;
      case "insuranceReq":
        postApi = "/approval/insert/insurance";
        break;
      case "dayOffReq":
        postApi = "/approval/insert/holiday";
        break;
      case "workAssistReq":
        postApi = "/approval/insert/cooperation";
        break;
      case "orderReq":
        postApi = "/approval/insert/order";
        break;
      case "vaultCash":
        postApi = "/approval/insert/vault_cash";
        break;
      // case "personnelWorkReq":
      //   postApi = "/approval/insert/";
      //   break;
      // case "salaryReport":
      //   postApi = "/approval/insert/";
      //   break;
      default:
        break;
    }
    if (!postApi) return;

    // file upload
    await uploadFiles();

    contentState.doc_info.doc_name = baseState.doc_title;

    setLoading(true);

    const completeText = doAction + "이 완료되었습니다.";

    const formData = new FormData();
    formData.append("is_new", mode === "new" ? true : false);
    formData.append("do_draft", doDraft);
    formData.append("approval_info", JSON.stringify(baseState));
    formData.append("doc_info", JSON.stringify(contentState));

    await HttpInstance.post(process.env.REACT_APP_STAFF_SERVER + postApi, formData, contentTypeJson)
      .then((res) => {
        if (res.data.msg) {
          const isNew = mode === "new" ? "true" : "false";
          storesDispatch(
            globalAlertOn({
              show: true,
              titleText: "ERROR !",
              bodyText: (
                <>
                  <Label labelText={"msg : " + res.data.msg} />
                  <Label labelText={"API : " + postApi} />
                  <Label labelText={"do_draft : " + doDraft} />
                  <Label labelText={"is_new : " + isNew} />
                </>
              ),
              onBtnOk: () => {},
            }),
          );
        } else {
          storesDispatch(
            globalAlertOn({
              show: true,
              titleText: "알림",
              bodyText: <Label labelText={completeText} />,
              reqConfirm: true,
              onBtnOk: () => {
                navigate("/approval_list");
              },
            }),
          );
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickCancel = () => {
    navigate("/approval_list");
  };
  const onClickPrev = () => {};
  const onClickNext = () => {};
  const onClickMoveToList = () => {
    navigate("/approval_list");
  };

  const leftPanelWidth = isCashbook ? "100%" : "73%";
  const rightPanelWidth = isCashbook ? "0%" : "27%";

  //
  // file upload
  //
  const uploadFiles = async () => {
    const uploadInfo = {
      file_info: {
        belong_type: "approve",
        doc_name: baseState.approval_type,
        user_id: baseState.approval_docs_id,
        file_group_id: baseState.approval_docs_id,
        reason: "전자결재",
      },
    };

    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].file && !checkFileSize(fileList[i].file, 20)) {
        simpleAlert(storesDispatch, "알림", "파일 사이즈는 20MB 이하로 등록 가능합니다.");
        break;
      }
    }

    baseState.file_list = [];

    setLoading(true);

    for (let i = 0; i < fileList.length; i++) {
      // 파일 업로드는 추가된 파일에 대해서만 실행 (불러온 파일은 file.size가 없음)
      if (fileList[i].file.size) {
        await uploadFile(uploadInfo, fileList[i].file, undefined);
      }
    }

    const getDocFiles = (fileInfos) => {
      for (let i = 0; i < fileInfos.length; i++) {
        // 현재 사용자가 업로드한 파일만 approval doc의 file_list에 추가
        // 만약 수정 상태에서 화면에서 삭제했다면 해당 파일은 doc에 포함하지 않음

        let isExist = false;
        for (let j = 0; j < fileList.length; j++) {
          const decodedCurFileName = decodeURIComponent(fileList[j].file.name);
          const decodedDocFileName = decodeURIComponent(fileInfos[i].file_info.file_name);
          if (decodedCurFileName === decodedDocFileName) {
            isExist = true;
            break;
          }
        }
        if (isExist) {
          // 중복 검사
          if (
            baseState.file_list.findIndex(
              (it) =>
                decodeURIComponent(it.file_info.file_name) ===
                decodeURIComponent(fileInfos[i].file_info.file_name),
            ) === -1
          ) {
            baseState.file_list.push(fileInfos[i]);
          }
        }
      }
    };
    await getUploadedFiles(storesDispatch, baseState.approval_docs_id, getDocFiles);
  };

  return (
    <div>
      <div style={{ display: "flex" }}>
        {loading && <Loading />}
        <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
        {/* LEFT PANEL */}
        <div style={{ width: leftPanelWidth }}>
          <CardFixed
            topDom={
              <div>
                {cardTitle}
                {getIsViewDelete(baseState) ? (
                  <Button
                    style={{
                      // marginLeft: "0.5rem",
                      float: "right",
                    }}
                    className={clsx({
                      [btnStyleClass.btnRootRaw]: true,
                      [btnStyleClass.buttonType4]: true,
                    })}
                    onClick={onClickDelete}
                  >
                    삭제
                  </Button>
                ) : (
                  ""
                )}
              </div>
            }
            midDom={
              <div style={{ marginTop: "1.5rem" }}>
                <DraftInfo
                  mode={mode}
                  permissionAct={permissionAct}
                  baseState={baseState}
                  setBaseState={setBaseState}
                  onClickApproveAction={onClickApproveAction}
                  onClickRetrieve={onClickRetrieve}
                  onClickSave={onClickSave}
                />
                <DraftLine
                  mode={mode}
                  baseState={baseState}
                  onClickSelectDraftLine={onClickSelectDraftLine}
                  approverList={approverList}
                  favoriteLineList={favoriteLineList}
                  setApproverList={setApproverList}
                />
                <DraftContent
                  mode={mode}
                  curContent={curContent}
                  baseState={baseState}
                  contentState={contentState}
                  setContentState={setContentState}
                />
                <DraftRemarks
                  mode={mode}
                  baseState={baseState}
                  fileList={fileList}
                  setFileList={setFileList}
                />

                {!isCashbook ? (
                  <ApprovalButtonSet
                    mode={mode}
                    onClickSave={onClickSave}
                    onClickCancel={onClickCancel}
                    // onClickPrev={onClickPrev}
                    // onClickNext={onClickNext}
                    onClickMoveToList={onClickMoveToList}
                  />
                ) : (
                  <></>
                )}
              </div>
            }
            isLoading={false}
            rootStyle={{
              borderRadius: "10px",
              backgroundColor: "#fff",
            }}
          />
        </div>
        {/* RIGHT PANEL */}
        <div style={{ width: rightPanelWidth, marginLeft: "1rem" }}>
          {mode === "new" ? (
            <CardFixed
              topDom={<div>결재 양식 목록</div>}
              midDom={
                <div style={{ marginTop: "1.5rem" }}>
                  <ApprovalForm
                    activeName="consult"
                    formName={APPROVAL_TYPE_EXP}
                    curContent={curContent}
                    onClickEvent={() => {
                      setCurContent("consult");
                      setContentState(_.cloneDeep(consult_state));
                    }}
                  />
                  <ApprovalForm
                    activeName="spendProof"
                    formName={APPROVAL_TYPE_IMP}
                    curContent={curContent}
                    onClickEvent={() => {
                      setCurContent("spendProof");
                      setContentState(_.cloneDeep(spend_proof_state));
                    }}
                  />
                  <ApprovalForm
                    activeName="transferReq"
                    formName={APPROVAL_TYPE_REFUND_TRANS}
                    curContent={curContent}
                    onClickEvent={() => {
                      setCurContent("transferReq");
                      setContentState(_.cloneDeep(transfer_state));
                    }}
                  />
                  <ApprovalForm
                    activeName="vaultCash"
                    formName={APPROVAL_TYPE_VAULT_CASH}
                    curContent={curContent}
                    onClickEvent={() => {
                      setCurContent("vaultCash");
                      setContentState(_.cloneDeep(vault_cash_state));
                    }}
                  />
                  <ApprovalForm
                    activeName="insuranceReq"
                    formName={APPROVAL_TYPE_INSURANCE}
                    curContent={curContent}
                    onClickEvent={() => {
                      setCurContent("insuranceReq");
                      setContentState(_.cloneDeep(insurance_state));
                    }}
                  />
                  <ApprovalForm
                    activeName="dayOffReq"
                    formName={APPROVAL_TYPE_HOLIDAY}
                    curContent={curContent}
                    onClickEvent={() => {
                      setCurContent("dayOffReq");
                      setContentState(_.cloneDeep(day_off_state));
                    }}
                  />
                  <ApprovalForm
                    activeName="workAssistReq"
                    formName={APPROVAL_TYPE_COOPERATION}
                    curContent={curContent}
                    onClickEvent={() => {
                      setCurContent("workAssistReq");
                      setContentState(_.cloneDeep(work_assist_state));
                    }}
                  />
                  <ApprovalForm
                    activeName="orderReq"
                    formName={APPROVAL_TYPE_ORDER}
                    curContent={curContent}
                    onClickEvent={() => {
                      setCurContent("orderReq");
                      setContentState(_.cloneDeep(order_state));
                    }}
                  />
                </div>
              }
              isLoading={false}
              rootStyle={{
                // width: "20rem",
                // height: "15.312rem",
                borderRadius: "10px",
                backgroundColor: "#fff",
              }}
            />
          ) : !isCashbook ? (
            <CardFixed
              topDom={<div>의견</div>}
              midDom={
                <div style={{ marginTop: "1.5rem" }}>
                  {baseState.comment_list.map((item, idx) => (
                    <>
                      <CommentForm commentItem={item} />
                      {idx === commentList.length - 1 ? (
                        <></>
                      ) : (
                        <div style={{ height: "1.75rem" }}></div>
                      )}
                    </>
                  ))}
                </div>
              }
              isLoading={false}
              rootStyle={{
                borderRadius: "10px",
                backgroundColor: "#fff",
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      {/* 현금출납장 서류보기 전용 의견 컴포넌트 */}
      <div style={{ marginTop: "1rem" }}>
        {isCashbook ? (
          <CardFixed
            topDom={<div>의견</div>}
            midDom={
              <div style={{ marginTop: "1.5rem" }}>
                {baseState.comment_list.map((item, idx) => (
                  <>
                    <CommentForm commentItem={item} />
                    {idx === commentList.length - 1 ? (
                      <></>
                    ) : (
                      <div style={{ height: "1.75rem" }}></div>
                    )}
                  </>
                ))}
              </div>
            }
            isLoading={false}
            rootStyle={{
              borderRadius: "10px",
              backgroundColor: "#fff",
            }}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default ApprovalInfo;

//
// 기안 문서 세부내용
//
const DraftContent = ({ mode, curContent, baseState, contentState, setContentState }) => {
  return (
    <>
      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <IconButton type="border" />
        <Label labelText="내용" marginLeft="0.5rem" fontWeight="bold" />
      </div>
      <ApprovalContentWrapper
        mode={mode}
        curContent={curContent}
        baseState={baseState}
        contentState={contentState}
        setContentState={setContentState}
      />
    </>
  );
};

const DraftRemarks = ({ mode, baseState, fileList, setFileList }) => {
  const labelWidth = "5rem";
  const fullWidth = "69rem";

  return (
    <>
      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <IconButton type="border" />
        <Label labelText="비고" marginLeft="0.5rem" fontWeight="bold" />
      </div>

      <div>
        <div style={{ display: "flex", marginTop: "0.5rem" }}>
          <Label width="3.8rem" labelText="첨부파일" marginRight="1.2rem" />
          {/* <div
            style={{
              width: "100%",
              height: "8rem",
              border: "1px solid #BBBBBB",
              borderRadius: "5px",
            }}
          ></div> */}
          <FileUploadBox
            fileGroupId={baseState.approval_docs_id}
            nowService={"전자결재"}
            mode={mode}
            fileList={fileList}
            setFileList={setFileList}
          />
        </div>
        <TextArea
          labelWidth={labelWidth}
          labelText={"메모"}
          labelMarginRight="0rem"
          fullWidth
          marginTop={"0.8rem"}
          minRows={4}
          maxRows={4}
          defaultValue={baseState.approval_memo}
          onChangeCallback={(e) => {
            baseState.approval_memo = e.target.value;
          }}
          disabled={mode === "view" || mode === "approver"}
        />
      </div>
    </>
  );
};

const ApprovalForm = ({ activeName, formName, curContent, onClickEvent }) => {
  return (
    <div
      style={{
        marginTop: "0.75rem",
        display: "flex",
        alignItems: "center",
        padding: "10px 15px",
        border: "1px solid #DEDEDE",
        boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "5px",
        width: "23rem",
        height: "2.5rem",
        cursor: "pointer",
        backgroundColor: activeName === curContent ? "#dfdffc" : "#FFF",
      }}
      onClick={onClickEvent}
    >
      <IconButton type="doc_form" />
      <Label labelText={formName} marginLeft="0.5rem" cursor="pointer" />
    </div>
  );
};
