import { salaryStatusInitState } from "./salary_status_state";
import { classSessionInitState } from "./class_session_state";
import { staffStandardSalaryState } from "./staff_standardSalary_state";
import { salaryInfoInitState } from "./salary_info_init_state";
import { etcSalaryInitState } from "./etc_salary_state";
import { gxSalaryInitState } from "./gx_salary_state";
import { mbshpSalaryInitState } from "./mbshp_salary_state";
import { ptSalaryInitState } from "./pt_salary_status";

import * as ActionTypes from "../actions/action_type";

//급여 메인
export const salary_info = (state = salaryInfoInitState, action) => {
  switch (action.type) {
    case ActionTypes.salaryInfoViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.salaryInfoViewFailAction:
    case ActionTypes.salaryInfoViewResetAction:
      return { ...state, stores: salaryInfoInitState.stores };
    default:
      return state;
  }
};

//급여현황(1번탭)
export const salary_status = (state = salaryStatusInitState, action) => {
  switch (action.type) {
    case ActionTypes.salaryStatusViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.salaryStatusViewFailAction:
    case ActionTypes.salaryStatusViewResetAction:
      return { ...state, stores: salaryStatusInitState.stores };
    default:
      return state;
  }
};

//기본급(2번탭)
export const salary_staff_standard = (state = staffStandardSalaryState, action) => {
  switch (action.type) {
    case ActionTypes.staffStandardSalaryViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.staffStandardSalaryViewFailAction:
    case ActionTypes.staffStandardSalaryViewResetAction:
      return { ...state, stores: staffStandardSalaryState.stores };
    default:
      return state;
  }
};

//회원인센티브(3번탭)
export const salary_staff_mbshp = (state = mbshpSalaryInitState, action) => {
  switch (action.type) {
    case ActionTypes.mbshpSalaryViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.mbshpSalaryViewFailAction:
    case ActionTypes.mbshpSalaryViewResetAction:
      return { ...state, stores: mbshpSalaryInitState.stores };
    default:
      return state;
  }
};

//pt (4번탭)
export const salary_staff_pt = (state = ptSalaryInitState, action) => {
  switch (action.type) {
    case ActionTypes.ptSalaryViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.ptSalaryViewFailAction:
    case ActionTypes.ptSalaryViewResetAction:
      return { ...state, stores: ptSalaryInitState.stores };
    default:
      return state;
  }
};

//gx(5번탭)
export const salary_staff_gx = (state = gxSalaryInitState, action) => {
  switch (action.type) {
    case ActionTypes.gxSalaryViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.gxSalaryViewFailAction:
    case ActionTypes.gxSalaryViewResetAction:
      return { ...state, stores: gxSalaryInitState.stores };
    default:
      return state;
  }
};

//기타(6번탭)
export const etc_salary = (state = etcSalaryInitState, action) => {
  switch (action.type) {
    case ActionTypes.etcSalaryViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.etcSalaryViewFailAction:
    case ActionTypes.etcSalaryViewResetAction:
      return { ...state, stores: etcSalaryInitState.stores };
    default:
      return state;
  }
};

//안쓰는거
export const salary_class_session = (state = classSessionInitState, action) => {
  switch (action.type) {
    case ActionTypes.classSessionViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.classSessionViewFailAction:
    case ActionTypes.classSessionViewResetAction:
      return { ...state, stores: classSessionInitState.stores };
    default:
      return state;
  }
};
