import { contentTypeJson, simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useState } from "react";
import { useDispatch } from "react-redux";

const ExistLockerSelectModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const [selectNum, setSelectNum] = useState();
  const [loading, setLoading] = useState(false);

  const saveLockerSelect = async () => {
    const locker = modalParam.lockerArr.find((el) => el.locker_no === selectNum);

    try {
      const formData = new FormData();

      formData.append("zone_id", locker.zone_id); //기존꺼
      formData.append("mbshp_locker_id", modalParam.mbshp_locker_id); //지금 배정하려는 회원권
      formData.append("locker_total_idx", locker.locker_total_idx); //기존꺼
      formData.append("member_info", JSON.stringify(modalParam.member_info));

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_BRANCH_SERVER + "/locker/locker_allocate",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: <Label labelText="락커가 배정되었습니다." />,
            onBtnOk: () => {
              onModalDone(); //모달 다 닫고 + 회원정보 전체 갱신
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ width: "12rem" }}>
      {loading && <Loading />}

      <Label labelText="기존 락커를 선택해주세요." fontSize="0.85rem" />
      <ComboBox
        marginTop="0.7rem"
        fullWidth
        data={modalParam.lockerNumArr}
        onChangeCallback={(e) => {
          setSelectNum(e.target.value);
        }}
      />

      <div
        style={{
          marginTop: "2rem",
          marginBottom: "0.5rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <NormalBtn
          name="확인"
          onClick={() => {
            saveLockerSelect();
          }}
          disabled={!selectNum}
        />
      </div>
    </div>
  );
};

export default ExistLockerSelectModal;
