// import { registerAction } from "./Module/actions";
import NumberTextField from "components/LabelInput/NumberTextField";
import SubTitle from "components/PageTitle/SubTitle";

const ServicePolicy = ({ restData }) => {
  const textBoxWidth = "10rem";
  const textLabelWidth = "6rem";
  const labelMarginLeft = "2.625rem";
  return (
    <div>
      <SubTitle titleText={"서비스 이용료"} />
      <div
        style={{
          display: "flex",
          marginLeft: "2rem",
        }}
      >
        <NumberTextField
          isNumber
          labelWidth={textLabelWidth}
          textBoxWidth={textBoxWidth}
          labelText="· 카드재발급"
          endAdornment="원"
          minValue={0}
          maxValue={1000000}
          defaultValue={restData.membership_policy.card_remake_fee}
          onChangeCallback={(e) => {
            restData.membership_policy.card_remake_fee = e;
          }}
        />

        <NumberTextField
          isNumber
          labelWidth={"6.5rem"}
          textBoxWidth={textBoxWidth}
          labelMarginLeft={labelMarginLeft}
          labelText="· 회원권 양도비"
          endAdornment="원"
          minValue={0}
          maxValue={1000000}
          defaultValue={restData.membership_policy.mbshp_assign_fee}
          onChangeCallback={(e) => {
            restData.membership_policy.mbshp_assign_fee = e;
          }}
        />

        <NumberTextField
          isNumber
          labelWidth={textLabelWidth}
          textBoxWidth={textBoxWidth}
          labelMarginLeft={labelMarginLeft}
          labelText="· PT 양도비"
          endAdornment="원"
          minValue={0}
          maxValue={1000000}
          defaultValue={restData.membership_policy.pt_assign_fee}
          onChangeCallback={(e) => {
            restData.membership_policy.pt_assign_fee = e;
          }}
        />
      </div>
    </div>
  );
};
export default ServicePolicy;
