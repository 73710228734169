import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import { HttpInstance } from "lib/HttpLib";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const LinkBranchHistModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const tableMaxHeight = "15rem";
  const [enterList, setEnterList] = useState([]);

  //회원의 타지점 출입 이력 GET
  const getAttendenceOtherHistList = async () => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_USER_SERVER + "/member/other_access/list",
        {
          params: {
            member_id: modalParam.memberId,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setEnterList(res.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  useEffect(() => {
    getAttendenceOtherHistList();
  }, [modalParam]);

  const dataInfoTableItem = new dataInfoTable();

  return (
    <div
      style={{
        width: "23rem",
      }}
    >
      <CustomTable
        columns_head={dataInfoTableItem.columns_head}
        table_title={dataInfoTableItem.table_title}
        rest_call={dataInfoTableItem.get_data_from_rest}
        row_render={dataInfoTableItem.create_table}
        rest_data={enterList}
        maxHeight={tableMaxHeight}
      />
    </div>
  );
};
export default LinkBranchHistModal;

class dataInfoTable {
  table_title = "";
  btnStyleClass = btnStyles();

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.enter_date}</CustomTableContents>
        <CustomTableContentsEND align="center">
          {data.branch_info.branch_name}
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "출입일시", width: "50%" },
    { title: "지점명", width: "50%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
