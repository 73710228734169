import { contentTypeJson } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

// ==================== GET ====================
// [GET] 운영 일지 검색 조건 받아오기 및 권한 데이터 세팅
export async function getDailyReportSearchParam() {
  return await HttpInstance.get(
    process.env.REACT_APP_SALARY_SERVER + "/daily_report/search/param",
    {
      params: {},
    },
  );
}

// [GET] 운영 일지 내역 리스트 검색
export async function getDailyReportList(queryData) {
  const { page, searchParams } = queryData;
  const formData = new FormData();

  formData.append("search_param", JSON.stringify(searchParams));
  formData.append("page_num", page);

  return await HttpInstance.post(
    process.env.REACT_APP_SALARY_SERVER + "/daily_report/search/list",
    formData,
    contentTypeJson,
  );
}
