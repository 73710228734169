import { useQuery } from "@tanstack/react-query";
import { getUnspentSearchParam } from "apis/branch/unspent_api";
import { restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import UnspentListView from "./UnspentListView";

const UnspentListService = () => {
  const storesDispatch = useDispatch();
  // 본부 및 지점 Combo
  const [hqBranchCombo, setHqBranchCombo] = useState();

  // [GET] 미지출 내역 검색 조건 및 권한 가져오기
  const { data: firstSearchParamData } = useQuery({
    queryKey: ["unspent", "firstSearchParam"],
    queryFn: () => getUnspentSearchParam(),
    enabled: true,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });

  // API 받아온 데이터 세팅
  useEffect(() => {
    // 데이터가 있으면
    if (firstSearchParamData && !restEmpty(firstSearchParamData.data.data)) {
      setHqBranchCombo(firstSearchParamData.data.data.combo_selector);
    }
  }, [firstSearchParamData]);

  return (
    <div>
      <PageFrame
        menuActive="락커/셋팅"
        parentMenu={{ 락커: true }}
        children={
          <div>
            <PageTitle titleText="미지출 내역" />
            <UnspentListView hqBranchCombo={hqBranchCombo} />
          </div>
        }
      />
    </div>
  );
};
export default UnspentListService;
