import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import TableTitleWithButton from "components/TableTitleWithButton/TableTitleWithButton";
import { HttpInstance } from "lib/HttpLib";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { erpCouponState } from "../Module/reducers/coupon_state";

const CouponManagement = ({ onClickOpenModal }) => {
  const storesDispatch = useDispatch();
  const [couponDataList, setCouponDataList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [branchNameList, setBranchNameList] = useState([]);

  // for pagination
  const [curPage, setCurPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const viewRefresh = () => {
    getCouponList();
  };

  const getBranchSelector = () => {
    HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/branch_set/branch_selector", {
      params: {},
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setBranchList(res.data.data.branch_list);
          setBranchNameList(res.data.data.branch_name_list);
        }
      })
      .catch((err) => {
        console.log("err");
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const getCouponList = () => {
    HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/erp_set/coupon/list", {
      params: {
        page_num: curPage,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setCouponDataList(res.data.data);
          setTotalPage(res.data.total_page);
        }
      })
      .catch((err) => {
        console.log("err");
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  useEffect(() => {
    getBranchSelector();
    getCouponList();
  }, [curPage]);

  const onClickAdd = () => {
    const param = {
      title: "쿠폰 발급",
      modalParam: {
        mode: "add",
        data: erpCouponState,
        branch_list: branchList,
        branch_name_list: branchNameList,
        refreshStore: viewRefresh,
      },
    };
    onClickOpenModal("쿠폰관리/쿠폰", param);
  };

  const onClickEdit = (data) => {
    const param = {
      title: "쿠폰 수정",
      modalParam: {
        mode: "edit",
        data: data,
        branch_list: branchList,
        branch_name_list: branchNameList,
        refreshStore: viewRefresh,
      },
    };
    onClickOpenModal("쿠폰관리/쿠폰", param);
  };

  const onClickShowCoupons = (couponId) => {
    const param = {
      title: "쿠폰 수정",
      modalParam: {
        coupon_id: couponId,
      },
    };
    onClickOpenModal("쿠폰관리/쿠폰번호보기", param);
  };

  const couponList = new couponTable(onClickEdit, onClickShowCoupons);

  return (
    <div>
      <TableTitleWithButton
        titleLabel={"쿠폰 관리"}
        buttonText={"추가"}
        onClickEvent={onClickAdd}
      />
      <CustomTable
        columns_head={couponList.columns_head}
        table_title={couponList.table_title}
        rest_call={couponList.get_data_from_rest}
        row_render={couponList.create_table}
        rest_data={couponDataList}
      />
      <CustomPagination curPage={curPage} doSearch={setCurPage} totalCnt={totalPage} />
    </div>
  );
};
export default CouponManagement;

class couponTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onClickEdit = null;
  onClickShowCoupons = null;

  constructor(onClickEdit, onClickShowCoupons) {
    this.onClickEdit = onClickEdit;
    this.onClickShowCoupons = onClickShowCoupons;
  }

  create_table = (data) => {
    return (
      <CustomRow style={{ width: "100%" }}>
        <CustomTableContents width="12%">{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents width="20%">{data && data.detail.coupon_name}</CustomTableContents>
        <CustomTableContents width="8%">{data && data.base_info.benefit_type}</CustomTableContents>
        <CustomTableContents width="20%">
          {data && data.base_info.expired_from_date} ~ {data && data.base_info.expired_to_date}
        </CustomTableContents>
        <CustomTableContents width="20%">
          {data && data.detail.service_benefit.branch_info.branch_name}
        </CustomTableContents>
        <CustomTableContents width="7%">
          {data && (data.detail.coupon_limit_cnt || "제한없음")}
        </CustomTableContents>
        <CustomTableContents width="7%">{data.detail.coupon_used_cnt}</CustomTableContents>
        <CustomTableContents width="8%">{data.base_info.status}</CustomTableContents>

        <CustomTableContents width="7%">
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                className={clsx({
                  [this.btnStyleClass.btnRoot]: true,
                  [this.btnStyleClass.buttonType5]: true,
                  [this.btnStyleClass.btnMarginRight]: true,
                })}
                onClick={() => this.onClickEdit(data)}
              >
                상세보기
              </Button>
              <Button
                className={clsx({
                  [this.btnStyleClass.btnRoot]: true,
                  [this.btnStyleClass.buttonType7]: true,
                })}
                onClick={() => this.onClickShowCoupons(data.coupon_id)}
              >
                쿠폰번호 보기
              </Button>
            </div>
          </div>
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "등록일시", field: "reg_date" },
    { title: "쿠폰명", field: "coupon_name" },
    { title: "쿠폰종류", field: "coupon_type" },
    { title: "유효기간", field: "coupon_range" },
    { title: "적용지점", field: "coupon_branches" },
    { title: "발행갯수", field: "pub_cnt" },
    { title: "사용갯수", field: "used_cnt" },
    { title: "상태", field: "status" },
    { title: " ", field: "action" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
