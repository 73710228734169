import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { useDispatch } from "react-redux";
import GuestBookView from "./GuestBookView";

const GuestBookService = () => {
  const storesDispatch = useDispatch();

  return (
    <PageFrame
      children={
        <div>
          <PageTitle titleText="방명록" />
          <GuestBookView />
        </div>
      }
    />
  );
};
export default GuestBookService;
