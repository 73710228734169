import { HttpInstance } from "lib/HttpLib";
import { permissionParaminitial } from "../reducers/perm_param_init";

export const getBaseInfoApi = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/hq_set/get_hq_info", {
    params: {
      hq_id: param.hq_id === null ? "" : param.hq_id,
      // branch_name: param.branch_name === null ? "" : param.branch_name,
    },
  });
};

export const getHqViewApi = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/hq_set/get_hq_setting", {
    params: {
      hq_id: param.hq_id === null ? "" : param.hq_id,
      // branch_name: param.branch_name === null ? "" : param.branch_name,
    },
  });
};

export const getBranchViewApi = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/hq_set/get_branch_setting", {
    params: {
      hq_id: param.hq_id === null ? "" : param.hq_id,
      // branch_name: param.branch_name === null ? "" : param.branch_name,
    },
  });
};

export const getPolicyViewApi = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/hq_set/get_policy_setting", {
    params: {
      hq_id: param.hq_id === null ? "" : param.hq_id,
      // branch_name: param.branch_name === null ? "" : param.branch_name,
    },
  });
};

export const getBranchInfoApi = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/hq_set/get_branch_info", {
    params: {
      branch_id: param.branch_id === null ? "" : param.branch_id,
      // branch_name: param.branch_name === null ? "" : param.branch_name,
    },
  });
};

////PERMISSION...
export const geHqStaffListApi = (param) => {
  return HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/hq/staff_list", {
    params: {
      hq_id: param.hq_id === null ? "" : param.hq_id,
      // branch_name: param.branch_name === null ? "" : param.branch_name,
    },
  });
};

export const geHqStaffBranchListApi = (param) => {
  return HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/hq/staff_tree", {
    params: {
      hq_id: param.hq_id === null ? "" : param.hq_id,
      // branch_name: param.branch_name === null ? "" : param.branch_name,
    },
  });
};
export const geHqStaffPermissionApi = (param) => {
  return HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/hq/staff_permission", {
    params: {
      // hq_id: param.hq_id === null ? "" : param.hq_id,
      // branch_id: param.branch_id === null ? "" : param.branch_id,
      // staff_id: param.staff_id === null ? "" : param.staff_id,
      permission_param:
        param.permission_param === null ? permissionParaminitial : param.permission_param,
    },
  });
};

const get_api = {
  getBaseInfoApi,
  getHqViewApi,
  getBranchViewApi,
  getPolicyViewApi,
  getBranchInfoApi,
  geHqStaffListApi,
  geHqStaffBranchListApi,
  geHqStaffPermissionApi,
};

export default get_api;
