import { contentTypeJson } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

// ==================== GET ====================
// [GET] 회원 검색 (모달)
export async function getModalSearchMember(queryData) {
  const { page, searchParam } = queryData;

  const formData = new FormData();
  formData.append("page_num", page);
  formData.append("search_param", JSON.stringify(searchParam));

  return await HttpInstance.post(
    process.env.REACT_APP_USER_SERVER + "/member/search_modal",
    formData,
    contentTypeJson,
  );
}
