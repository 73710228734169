import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnSubmitStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, simpleAlert } from "components/CommonLib/CommonLib";
import DocInfo from "components/File/DocInfo";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import { payListActions } from "pages/ERP/MemberCommonItems/Module/actions/index";
import PaymentInfo from "pages/ERP/MemberCommonItems/PaymentInfo";
import PersonalInfo from "pages/ERP/MemberCommonItems/PersonalInfo";
import RegisterPaidDate from "pages/ERP/MemberCommonItems/RegisterPaidDate";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChangePayMethodContents from "./ChangePayMethodContents";
import ChngePayMethodModalIndex from "./Modal/ChngePayMethodModalIndex";

const ChangePayMethodView = ({ serviceViewState, memberBaseInfoState, initRegDate }) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnSubmitStyles();
  const paymentInfoRef = useRef();
  const contentsInfoRef = useRef();
  const navigate = useNavigate();

  const [regDate, setRegDate] = useState(initRegDate);
  const [canEnroll, setCanEnroll] = useState(false);
  const [docList, setDocList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isPtPayment, setIsPtPayment] = useState(false);
  const [ptPaymentMethod, setPtPaymentMethod] = useState("");

  useEffect(() => {
    setRegDate(initRegDate);
  }, [initRegDate]);

  //파일 초기화 -> 주석 살려야함
  // useEffect(() => {
  //   if (serviceViewState.contract_doc_list) {
  //     setDocList(serviceViewState.contract_doc_list);
  //   }
  // }, [serviceViewState.contract_doc_list.length]);

  const onClickOpenModal = (order, param) => {
    if (order !== "") {
      openModal(order, param);
    }
  };

  const openModal = (select, param) => {
    // isPtPayment 끼워팔기
    param.modalParam = {
      ...param.modalParam,
      isPtPayment,
      ptPaymentMethod,
    };

    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,
        Content: (
          <ChngePayMethodModalIndex
            modalSelect={select}
            modalParam={param}
            onModalDone={onModalDone}
          />
        ),
      }),
    );
  };

  //결제창 모달 닫힐때
  const onModalDone = (title, pay_info) => {
    if (pay_info.unpaid_info.unpaid_price > 0) {
      //미납금 다 결제하셈
    } else {
      setCanEnroll(true);
    }
    paymentInfoRef.current.setCsPaidResult(pay_info);
    storesDispatch(globalModalOff());
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="결제가 완료되었습니다. 등록을 진행해주세요." />
            <Label labelText="결제 완료 후, 금액 변경시 결제금액은 모두 초기화됩니다." />
          </>
        ),
        onBtnOk: () => {},
      }),
    );
  };

  const validationCheck = () => {
    //1. 미납금 없는지 => paymentModal 체크

    const refundInfoArr = contentsInfoRef.current.getRefundItem();

    const isRefundType = refundInfoArr.every(
      (el) =>
        el.cancel_type === "환불완료" ||
        (el.cancel_type === "환불요청" && el.account_name && el.bank && el.bank_acc),
    );

    const isRefundTime = refundInfoArr.every((el) => el.pay_date);

    if (!serviceViewState.cs_staff.staff_name) {
      //2. 상담자 선택 했는지
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="상담자를 선택해주세요." />,
          onBtnOk: () => {},
        }),
      );
    } else if (!isRefundTime) {
      //3. 취소일시 선택했는지
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="환불금 지급 상세내역의 취소일시를 필수로 입력해주세요." />,
          onBtnOk: () => {},
        }),
      );
    } else if (!isRefundType) {
      //3. 환불구분 선택했는지
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="환불금 지급 상세내역 중 입력되지 않은 항목이 있습니다." />,
          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="결제수단을 변경하시겠습니까?" />,
          onBtnYes: () => {
            onSaveEvent();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const CopyCancelData = (newInfo) => {
    for (let i = 0; i < newInfo.payment_list.length; i++) {
      if (newInfo.payment_list[i].payment_list_selector.selected) {
        const pId = newInfo.payment_list[i].payment_data.payment_id;
        const rfndPaymentIdx = newInfo.refund_list.findIndex((x) => x.payment_id === pId);
        const dat = newInfo.refund_list[rfndPaymentIdx];
        if (rfndPaymentIdx !== -1) {
          // 무조건 진입할 것임
          newInfo.payment_list[i].payment_data.cancel_approve_no = dat.cancel_approve_no;
          newInfo.payment_list[i].payment_data.cancel_date = dat.cancel_date;
          newInfo.payment_list[i].payment_data.account_name = dat.account_name;
          newInfo.payment_list[i].payment_data.bank_acc = dat.bank_acc;
          newInfo.payment_list[i].payment_data.bank = dat.bank;
        }
      }
    }
  };

  //최종 등록 버튼 누를때 POST
  const onSaveEvent = async () => {
    try {
      const newInfo = _.cloneDeep(serviceViewState);
      newInfo.reg_date = regDate;
      newInfo.paymentA = paymentInfoRef.current.getCsPaidResult();
      newInfo.contract_doc_list = docList;
      //모두 사용할꺼면
      newInfo.contract_doc_list.forEach((el) => (el.is_use = true));

      CopyCancelData(newInfo); // 취소승인번호 등 '환불금 지급'에서 입력된 데이터 copy
      // console.log("newInfo: ", newInfo);

      const formData = new FormData();
      formData.append("new_info", JSON.stringify(newInfo));

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_USER_SERVER + "/change/pay_method_update",
        formData,
        contentTypeJson,
      );
      if (res.error) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: <Label labelText="결제수단 변경이 완료되었습니다." />,
            onBtnOk: () => {
              navigate(`/member_info/${serviceViewState.member_id}`);
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const payInfoListAction = (act, pType, detailInfo, ord) => {
    if (act === "push") {
      storesDispatch(payListActions.paymentInfoPush(pType, detailInfo, ord));
    } else if (act === "pop") {
      storesDispatch(payListActions.paymentInfoPop(pType));
    }
  };

  const uploadDoneCallback = (result) => {
    //업로드에서 받아온 파일을 contract_doc_list에 갱신
    const idx = docList.findIndex((el) => el.doc_id === result.doc_id);
    setDocList((cur) => {
      const arr = [...cur];
      arr[idx] = result;
      return arr;
    });
  };

  return (
    <div style={{ display: "flex" }}>
      {loading && <Loading />}

      <div style={{ width: "76.88rem" }}>
        {/* <Button
          onClick={() => {
            console.log("pA", serviceViewState);
          }}
        >
          페이먼트a
        </Button> */}
        <PersonalInfo
          memberBaseInfoState={memberBaseInfoState.base_info.personal_info}
          onClickOpenModal={onClickOpenModal}
          memberInputState={{
            branch_info: memberBaseInfoState.branch_info,
            card_param: memberBaseInfoState.access,
          }}
        />
        <ChangePayMethodContents
          ref={contentsInfoRef}
          payInfoListAction={payInfoListAction}
          serviceViewState={serviceViewState}
          setIsPtPayment={setIsPtPayment}
          setPtPaymentMethod={setPtPaymentMethod}
          regDate={regDate}
        />

        {/* <PointInfo /> */}
      </div>

      {/* 우측 화면 */}
      <div style={{ width: "23.313rem", marginLeft: "0.625rem" }}>
        <PaymentInfo
          isAllCalc={true}
          ref={paymentInfoRef}
          onClickOpenModal={onClickOpenModal}
          regDate={regDate}
          setCanEnroll={setCanEnroll}
          isAddPay={false}
          noCoupon
          remainPoint={0}
        />
        <RegisterPaidDate regDate={regDate} setRegDate={setRegDate} isDisabled />
        {/* <ChangePayMethodDoc setDocInfo={setDocInfo} /> */}
        <DocInfo docList={docList} uploadDoneCallback={uploadDoneCallback} />

        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType2]: true,
            [btnStyleClass.disabled]: !canEnroll,
          })}
          onClick={() => validationCheck()}
          disabled={!canEnroll}
        >
          등록
        </Button>
      </div>
    </div>
  );
};
export default ChangePayMethodView;
