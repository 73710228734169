import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import { simpleAlert } from "components/CommonLib/CommonLib";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import { HttpInstance } from "lib/HttpLib";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const BaseInfoCardItem = ({ branchId, onChangeBranch }) => {
  const storesDispatch = useDispatch();
  const [branchList, setBranchList] = useState([]);
  const [curBranchName, setCurBranchName] = useState("");

  useEffect(() => {
    HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/branch_set/branch_selector", {
      params: {},
    })
      .then((res) => {
        setBranchList(res.data.data.branch_list);
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  }, []);

  useEffect(() => {
    if (branchList.length > 0) {
      const idx = branchList.findIndex((x) => x.branch_id === branchId);
      setCurBranchName(branchList[idx].branch_name);
    }
  }, [branchList]);

  return (
    <div style={{}}>
      <SearchComboBox
        labelWidth={"3.25rem"}
        comboItemWidth={"11.75rem"}
        labelText="지점명"
        data={branchList.map((item) => {
          return item.branch_name;
        })}
        defaultValue={curBranchName}
        onChangeCallback={(e, v, idx) => {
          // 지점 선택에 따른 브랜치 정보 보내줌
          const branchInfo = { branch_id: branchList[idx].branch_id, branch_name: v };
          onChangeBranch(branchList[idx].branch_id, branchInfo);
        }}
      />
    </div>
  );
};

const BranchSelectorInfo = ({ branchId, onChangeBranch }) => {
  const classes = btnStyles();

  return (
    <CardFixed
      topDom={<div>지점 선택</div>}
      midDom={<BaseInfoCardItem branchId={branchId} onChangeBranch={onChangeBranch} />}
      isLoading={false}
      rootStyle={{
        // width: "59.812rem",
        width: "100%",
        // height: "10.312rem",
        marginBottom: "0.625rem",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};
export default BranchSelectorInfo;
