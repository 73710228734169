export const settingInitState = {
  prepay_items: {
    normal_items: [
      {
        item_id: "000000000000000000000000",
        item_name: "",
        mbshp_type: "",
        mbshp_option: "",
        pt_per_price: 0,
        pt_price: 0,
        pt_session: 0,
        is_combine_cloth: false,
        is_combine_gx: false,
        is_combine_locker: false,
        is_combine_pt: false,
        postpone_cnt: 0,
        ot_session: 0,
        can_assign: false,
        can_family: false,
        can_change: false,
        pass_cnt: 0,
        pass_from_time: "",
        pass_end_time: "",
        total_price: 0,
        reg_price: 0,
        total_period_month: 0,
        total_period_day: 0,
        total_period_hour: 0,
        period_month: 0,
        service_month: 0,
        service_day: 0,
        oneday_wait_hour: 0,
        mbshp_price: 0,
        cloth_price: 0,
        gx_price: 0,
        locker_price: 0,
        standard_price: {
          prepay_reg: 0,
          prepay_mbshp: 0,
          prepay_cloth: 0,
          prepay_gx: 0,
          prepay_locker: 0,
          oneday_mbshp: 0,
          oneday_cloth: 0,
          oneday_gx: 0,
          ot: 0,
          pt: 0,
        },
        subs_options: {
          subs_reg: 0,
          subs_period_month: 0,
          pre_month: 0,
          total_subs_price: 0,
          mbshp_subs_price: 0,
          mbshp_pre_price: 0,
          cloth_subs_price: 0,
          cloth_pre_price: 0,
          gx_subs_price: 0,
          gx_pre_price: 0,
          locker_subs_price: 0,
          locker_pre_price: 0,
        },
        base_info: {
          sales_status: "",
          sales_limit: false,
          incentive: 0,
          order: 0,
          tablet_img: "",
          mem_app_img: "",
        },
        reg_info: {
          created_at: "",
          updated_at: "",
          reg_name: "",
          reg_id: "000000000000000000000000",
          upd_id: "000000000000000000000000",
          upd_name: "",
        },
      },
    ],
    pass_items: [
      {
        item_id: "000000000000000000000000",
        item_name: "",
        mbshp_type: "",
        mbshp_option: "",
        pt_per_price: 0,
        pt_price: 0,
        pt_session: 0,
        is_combine_cloth: false,
        is_combine_gx: false,
        is_combine_locker: false,
        is_combine_pt: false,
        postpone_cnt: 0,
        ot_session: 0,
        can_assign: false,
        can_family: false,
        can_change: false,
        pass_cnt: 0,
        pass_from_time: "",
        pass_end_time: "",
        total_price: 0,
        reg_price: 0,
        total_period_month: 0,
        total_period_day: 0,
        total_period_hour: 0,
        period_month: 0,
        service_month: 0,
        service_day: 0,
        oneday_wait_hour: 0,
        mbshp_price: 0,
        cloth_price: 0,
        gx_price: 0,
        locker_price: 0,
        standard_price: {
          prepay_reg: 0,
          prepay_mbshp: 0,
          prepay_cloth: 0,
          prepay_gx: 0,
          prepay_locker: 0,
          oneday_mbshp: 0,
          oneday_cloth: 0,
          oneday_gx: 0,
          ot: 0,
          pt: 0,
        },
        subs_options: {
          subs_reg: 0,
          subs_period_month: 0,
          pre_month: 0,
          total_subs_price: 0,
          mbshp_subs_price: 0,
          mbshp_pre_price: 0,
          cloth_subs_price: 0,
          cloth_pre_price: 0,
          gx_subs_price: 0,
          gx_pre_price: 0,
          locker_subs_price: 0,
          locker_pre_price: 0,
        },
        base_info: {
          sales_status: "",
          sales_limit: false,
          incentive: 0,
          order: 0,
          tablet_img: "",
          mem_app_img: "",
        },
        reg_info: {
          created_at: "",
          updated_at: "",
          reg_name: "",
          reg_id: "000000000000000000000000",
          upd_id: "000000000000000000000000",
          upd_name: "",
        },
      },
    ],
    time_items: [
      {
        item_id: "000000000000000000000000",
        item_name: "",
        mbshp_type: "",
        mbshp_option: "",
        pt_per_price: 0,
        pt_price: 0,
        pt_session: 0,
        is_combine_cloth: false,
        is_combine_gx: false,
        is_combine_locker: false,
        is_combine_pt: false,
        postpone_cnt: 0,
        ot_session: 0,
        can_assign: false,
        can_family: false,
        can_change: false,
        pass_cnt: 0,
        pass_from_time: "",
        pass_end_time: "",
        total_price: 0,
        reg_price: 0,
        total_period_month: 0,
        total_period_day: 0,
        total_period_hour: 0,
        period_month: 0,
        service_month: 0,
        service_day: 0,
        oneday_wait_hour: 0,
        mbshp_price: 0,
        cloth_price: 0,
        gx_price: 0,
        locker_price: 0,
        standard_price: {
          prepay_reg: 0,
          prepay_mbshp: 0,
          prepay_cloth: 0,
          prepay_gx: 0,
          prepay_locker: 0,
          oneday_mbshp: 0,
          oneday_cloth: 0,
          oneday_gx: 0,
          ot: 0,
          pt: 0,
        },
        subs_options: {
          subs_reg: 0,
          subs_period_month: 0,
          pre_month: 0,
          total_subs_price: 0,
          mbshp_subs_price: 0,
          mbshp_pre_price: 0,
          cloth_subs_price: 0,
          cloth_pre_price: 0,
          gx_subs_price: 0,
          gx_pre_price: 0,
          locker_subs_price: 0,
          locker_pre_price: 0,
        },
        base_info: {
          sales_status: "",
          sales_limit: false,
          incentive: 0,
          order: 0,
          tablet_img: "",
          mem_app_img: "",
        },
        reg_info: {
          created_at: "",
          updated_at: "",
          reg_name: "",
          reg_id: "000000000000000000000000",
          upd_id: "000000000000000000000000",
          upd_name: "",
        },
      },
    ],
    daily_items: [
      {
        item_id: "000000000000000000000000",
        item_name: "",
        mbshp_type: "",
        mbshp_option: "",
        pt_per_price: 0,
        pt_price: 0,
        pt_session: 0,
        is_combine_cloth: false,
        is_combine_gx: false,
        is_combine_locker: false,
        is_combine_pt: false,
        postpone_cnt: 0,
        ot_session: 0,
        can_assign: false,
        can_family: false,
        can_change: false,
        pass_cnt: 0,
        pass_from_time: "",
        pass_end_time: "",
        total_price: 0,
        reg_price: 0,
        total_period_month: 0,
        total_period_day: 0,
        total_period_hour: 0,
        period_month: 0,
        service_month: 0,
        service_day: 0,
        oneday_wait_hour: 0,
        mbshp_price: 0,
        cloth_price: 0,
        gx_price: 0,
        locker_price: 0,
        standard_price: {
          prepay_reg: 0,
          prepay_mbshp: 0,
          prepay_cloth: 0,
          prepay_gx: 0,
          prepay_locker: 0,
          oneday_mbshp: 0,
          oneday_cloth: 0,
          oneday_gx: 0,
          ot: 0,
          pt: 0,
        },
        subs_options: {
          subs_reg: 0,
          subs_period_month: 0,
          pre_month: 0,
          total_subs_price: 0,
          mbshp_subs_price: 0,
          mbshp_pre_price: 0,
          cloth_subs_price: 0,
          cloth_pre_price: 0,
          gx_subs_price: 0,
          gx_pre_price: 0,
          locker_subs_price: 0,
          locker_pre_price: 0,
        },
        base_info: {
          sales_status: "",
          sales_limit: false,
          incentive: 0,
          order: 0,
          tablet_img: "",
          mem_app_img: "",
        },
        reg_info: {
          created_at: "",
          updated_at: "",
          reg_name: "",
          reg_id: "000000000000000000000000",
          upd_id: "000000000000000000000000",
          upd_name: "",
        },
      },
    ],
  },
  subs_items: {
    normal_items: [
      {
        item_id: "000000000000000000000000",
        item_name: "",
        mbshp_type: "",
        mbshp_option: "",
        pt_per_price: 0,
        pt_price: 0,
        pt_session: 0,
        is_combine_cloth: false,
        is_combine_gx: false,
        is_combine_locker: false,
        is_combine_pt: false,
        postpone_cnt: 0,
        ot_session: 0,
        can_assign: false,
        can_family: false,
        can_change: false,
        pass_cnt: 0,
        pass_from_time: "",
        pass_end_time: "",
        total_price: 0,
        reg_price: 0,
        total_period_month: 0,
        total_period_day: 0,
        total_period_hour: 0,
        period_month: 0,
        service_month: 0,
        service_day: 0,
        oneday_wait_hour: 0,
        mbshp_price: 0,
        cloth_price: 0,
        gx_price: 0,
        locker_price: 0,
        standard_price: {
          prepay_reg: 0,
          prepay_mbshp: 0,
          prepay_cloth: 0,
          prepay_gx: 0,
          prepay_locker: 0,
          oneday_mbshp: 0,
          oneday_cloth: 0,
          oneday_gx: 0,
          ot: 0,
          pt: 0,
        },
        subs_options: {
          subs_reg: 0,
          subs_period_month: 0,
          pre_month: 0,
          total_subs_price: 0,
          mbshp_subs_price: 0,
          mbshp_pre_price: 0,
          cloth_subs_price: 0,
          cloth_pre_price: 0,
          gx_subs_price: 0,
          gx_pre_price: 0,
          locker_subs_price: 0,
          locker_pre_price: 0,
        },
        base_info: {
          sales_status: "",
          sales_limit: false,
          incentive: 0,
          order: 0,
          tablet_img: "",
          mem_app_img: "",
        },
        reg_info: {
          created_at: "",
          updated_at: "",
          reg_name: "",
          reg_id: "000000000000000000000000",
          upd_id: "000000000000000000000000",
          upd_name: "",
        },
      },
    ],
    pass_items: [
      {
        item_id: "000000000000000000000000",
        item_name: "",
        mbshp_type: "",
        mbshp_option: "",
        pt_per_price: 0,
        pt_price: 0,
        pt_session: 0,
        is_combine_cloth: false,
        is_combine_gx: false,
        is_combine_locker: false,
        is_combine_pt: false,
        postpone_cnt: 0,
        ot_session: 0,
        can_assign: false,
        can_family: false,
        can_change: false,
        pass_cnt: 0,
        pass_from_time: "",
        pass_end_time: "",
        total_price: 0,
        reg_price: 0,
        total_period_month: 0,
        total_period_day: 0,
        total_period_hour: 0,
        period_month: 0,
        service_month: 0,
        service_day: 0,
        oneday_wait_hour: 0,
        mbshp_price: 0,
        cloth_price: 0,
        gx_price: 0,
        locker_price: 0,
        standard_price: {
          prepay_reg: 0,
          prepay_mbshp: 0,
          prepay_cloth: 0,
          prepay_gx: 0,
          prepay_locker: 0,
          oneday_mbshp: 0,
          oneday_cloth: 0,
          oneday_gx: 0,
          ot: 0,
          pt: 0,
        },
        subs_options: {
          subs_reg: 0,
          subs_period_month: 0,
          pre_month: 0,
          total_subs_price: 0,
          mbshp_subs_price: 0,
          mbshp_pre_price: 0,
          cloth_subs_price: 0,
          cloth_pre_price: 0,
          gx_subs_price: 0,
          gx_pre_price: 0,
          locker_subs_price: 0,
          locker_pre_price: 0,
        },
        base_info: {
          sales_status: "",
          sales_limit: false,
          incentive: 0,
          order: 0,
          tablet_img: "",
          mem_app_img: "",
        },
        reg_info: {
          created_at: "",
          updated_at: "",
          reg_name: "",
          reg_id: "000000000000000000000000",
          upd_id: "000000000000000000000000",
          upd_name: "",
        },
      },
    ],
    time_items: [
      {
        item_id: "000000000000000000000000",
        item_name: "",
        mbshp_type: "",
        mbshp_option: "",
        pt_per_price: 0,
        pt_price: 0,
        pt_session: 0,
        is_combine_cloth: false,
        is_combine_gx: false,
        is_combine_locker: false,
        is_combine_pt: false,
        postpone_cnt: 0,
        ot_session: 0,
        can_assign: false,
        can_family: false,
        can_change: false,
        pass_cnt: 0,
        pass_from_time: "",
        pass_end_time: "",
        total_price: 0,
        reg_price: 0,
        total_period_month: 0,
        total_period_day: 0,
        total_period_hour: 0,
        period_month: 0,
        service_month: 0,
        service_day: 0,
        oneday_wait_hour: 0,
        mbshp_price: 0,
        cloth_price: 0,
        gx_price: 0,
        locker_price: 0,
        standard_price: {
          prepay_reg: 0,
          prepay_mbshp: 0,
          prepay_cloth: 0,
          prepay_gx: 0,
          prepay_locker: 0,
          oneday_mbshp: 0,
          oneday_cloth: 0,
          oneday_gx: 0,
          ot: 0,
          pt: 0,
        },
        subs_options: {
          subs_reg: 0,
          subs_period_month: 0,
          pre_month: 0,
          total_subs_price: 0,
          mbshp_subs_price: 0,
          mbshp_pre_price: 0,
          cloth_subs_price: 0,
          cloth_pre_price: 0,
          gx_subs_price: 0,
          gx_pre_price: 0,
          locker_subs_price: 0,
          locker_pre_price: 0,
        },
        base_info: {
          sales_status: "",
          sales_limit: false,
          incentive: 0,
          order: 0,
          tablet_img: "",
          mem_app_img: "",
        },
        reg_info: {
          created_at: "",
          updated_at: "",
          reg_name: "",
          reg_id: "000000000000000000000000",
          upd_id: "000000000000000000000000",
          upd_name: "",
        },
      },
    ],
    daily_items: [
      {
        item_id: "000000000000000000000000",
        item_name: "",
        mbshp_type: "",
        mbshp_option: "",
        pt_per_price: 0,
        pt_price: 0,
        pt_session: 0,
        is_combine_cloth: false,
        is_combine_gx: false,
        is_combine_locker: false,
        is_combine_pt: false,
        postpone_cnt: 0,
        ot_session: 0,
        can_assign: false,
        can_family: false,
        can_change: false,
        pass_cnt: 0,
        pass_from_time: "",
        pass_end_time: "",
        total_price: 0,
        reg_price: 0,
        total_period_month: 0,
        total_period_day: 0,
        total_period_hour: 0,
        period_month: 0,
        service_month: 0,
        service_day: 0,
        oneday_wait_hour: 0,
        mbshp_price: 0,
        cloth_price: 0,
        gx_price: 0,
        locker_price: 0,
        standard_price: {
          prepay_reg: 0,
          prepay_mbshp: 0,
          prepay_cloth: 0,
          prepay_gx: 0,
          prepay_locker: 0,
          oneday_mbshp: 0,
          oneday_cloth: 0,
          oneday_gx: 0,
          ot: 0,
          pt: 0,
        },
        subs_options: {
          subs_reg: 0,
          subs_period_month: 0,
          pre_month: 0,
          total_subs_price: 0,
          mbshp_subs_price: 0,
          mbshp_pre_price: 0,
          cloth_subs_price: 0,
          cloth_pre_price: 0,
          gx_subs_price: 0,
          gx_pre_price: 0,
          locker_subs_price: 0,
          locker_pre_price: 0,
        },
        base_info: {
          sales_status: "",
          sales_limit: false,
          incentive: 0,
          order: 0,
          tablet_img: "",
          mem_app_img: "",
        },
        reg_info: {
          created_at: "",
          updated_at: "",
          reg_name: "",
          reg_id: "000000000000000000000000",
          upd_id: "000000000000000000000000",
          upd_name: "",
        },
      },
    ],
  },
  etc_items: null,
  erp_setting: {
    _id: "633283be1f1de4e24ffa057e",
    identify: "admin",
    regular_pay_date: 3,
    branch_link_cnt: 10,
    branch_change_price: 0,
    point_give_rate: 10,
    point_use_rate: 10,
    point_min_use: 1000,
    reg_path_list: [],
    re_reg_path_list: [],
    purpose_list: [],
    mbshp_time_branch_list: [],
    mbshp_time_branch_reject_list: [],
    mbshp_pass_branch_list: [],
    mbshp_pass_branch_reject_list: [],
  },
  hq_setting: {
    hq_info: {
      hq_id: "000000000000000000000000",
      hq_name: "",
    },
    package_info: {
      package_id: "000000000000000000000000",
      package_name: "",
    },
    auth_code: "",
    owner_info: {
      owner_id: "000000000000000000000000",
      owner_name: "",
      owner_gender: "",
      owner_phone: "",
      owner_email: "",
      owner_birth: "",
    },
    remove_lock: false,
    branch_list: [],
    reg_status: "",
    reg_info: {
      created_at: "",
      updated_at: "",
      reg_name: "",
      reg_id: "000000000000000000000000",
      upd_id: "000000000000000000000000",
      upd_name: "",
    },
    hq_set: {
      dept_list: [],
      position_list: [],
      rank_list: [],
    },
    policy_set: {
      mbshp_rfnd_day_price: 0,
      prepay_penalty_rate: 0,
      subs_penalty_rate: 0,
      prepay_postpone_month: 0,
      prepay_postpone_day: 0,
      subs_postpone_month: 0,
      postpone_month: 0,
      assign_option: {
        include_all: false,
        except_service: false,
        except_event: false,
        except_all: false,
      },
      cms_by_pay_list: [],
      default_salary_date_list: [],
    },
    found_date: "",
  },
  branch_setting: {
    erp_identify: "",
    branch_info: {
      branch_id: "000000000000000000000000",
      branch_name: "",
    },
    hq_info: {
      hq_id: "000000000000000000000000",
      hq_name: "",
    },
    found_date: "",
    branch_no: 0,
    remove_lock: false,
    manager_list: [],
    base_policy: {
      prepay_reg: 0,
      prepay_mbshp: 0,
      prepay_cloth: 0,
      prepay_gx: 0,
      prepay_locker: 0,
      subs_reg: 0,
      subs_mbshp: 0,
      subs_cloth: 0,
      subs_gx: 0,
      subs_locker: 0,
      oneday_mbshp: 0,
      oneday_cloth: 0,
      oneday_gx: 0,
      ot: 0,
      pt: 0,
    },
    membership_policy: {
      use_dflt_from_date: false,
      dflt_from_date: "",
      use_max_wait_day: false,
      max_wait_day: 0,
      recommender_month: 0,
      recommender_day: 0,
      recommendee_month: 0,
      recommendee_day: 0,
      event_month_list: [],
      event_day_list: [],
      pass_mbship_count_rate: 0,
      change_limit: 0,
      pass_change_limit: 0,
      card_remake_fee: 0,
      mbshp_assign_fee: 0,
      pt_assign_fee: 0,
    },
    contract: {
      register_prepay: {
        key_name: "",
        path: "",
      },
      register_subs: {
        key_name: "",
        path: "",
      },
      assign: {
        key_name: "",
        path: "",
      },
      change: {
        key_name: "",
        path: "",
      },
      transfer: {
        key_name: "",
        path: "",
      },
      refund: {
        key_name: "",
        path: "",
      },
      service: {
        key_name: "",
        path: "",
      },
      register_pt: {
        key_name: "",
        path: "",
      },
      cancel_subs: {
        key_name: "",
        path: "",
      },
      cancel_regular: {
        key_name: "",
        path: "",
      },
      agree_personal: {
        key_name: "",
        path: "",
      },
      agree_marketing: {
        key_name: "",
        path: "",
      },
    },
    cms_by_pay_method: {
      cash: 0,
      card: 0,
    },
    default_salary_date: {
      wage: 0,
      incentive: 0,
      pt_fee: 0,
      gx_fee: 0,
      etc_fee: 0,
    },
  },
  permission_branch_list: [],
  cs_team_list: {
    key: "",
    branch_id: "000000000000000000000000",
    branch_name: "",
    team: [
      {
        staff_id: "000000000000000000000000",
        staff_name: "",
        department: {
          org_id: "000000000000000000000000",
          org_name: "",
        },
        rank: {
          org_id: "000000000000000000000000",
          org_name: "",
        },
        position: {
          org_id: "000000000000000000000000",
          org_name: "",
        },
        show_name: "",
      },
    ],
  },
  class_team_list: {
    key: "",
    branch_id: "000000000000000000000000",
    branch_name: "",
    team: [
      {
        staff_id: "000000000000000000000000",
        staff_name: "",
        department: {
          org_id: "000000000000000000000000",
          org_name: "",
        },
        rank: {
          org_id: "000000000000000000000000",
          org_name: "",
        },
        position: {
          org_id: "000000000000000000000000",
          org_name: "",
        },
        show_name: "",
      },
    ],
  },
  cs_team_name_list: [],
  class_team_name_list: [],
  selected_branch_name: "",
  selected_branch_id: "000000000000000000000000",
  event_day_str_list: [],
  event_month_str_list: [],
};
