//양수자
export const memberInit = {
  access: { access_method: "", card_no: 0 },
  active_info: {
    active_membership: { is_use: false, from_date: "", to_date: "" },
    total_unpaid: 0,
  },
  base_info: {
    agree_personal: false,
    agree_marketing: false,
    birth: "",
    gender: "",
    phone: "",
  },
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  hq_info: {
    hq_id: "",
    hq_name: "",
  },
  branch_pt_detail: {
    pt_status: "",
    remain_session_cnt: 0,
  },
  member_info: {
    member_id: "",
    member_name: "",
    member_no: "",
  },
  member_type: "",
  view_info: { selected: false },
};

//reg_input_info
export const inputInitState = {
  contract_doc_list: [],
  src_member_info: {
    branch_info: {
      branch_id: "",
      branch_name: "",
    },
    member_id: "",
    personal_info: {
      member_name: "",
      member_no: "",
      phone: "",
      agree_personal: false,
      agree_marketing: false,
      gender: "",
      birth: "",
      email: "",
      zip_code: "",
      address_1: "",
      address_2: "",
      notice: "",
      reg_path: "",
      reg_info: {
        created_at: "",
        updated_at: "",
        reg_name: "",
        reg_id: "000000000000000000000000",
        upd_id: "000000000000000000000000",
        upd_name: "",
      },
    },
    membership_data_list: [
      {
        assign_date_list: [],
        purchase_item_list: {
          erp_policy: {
            regular_pay_date: 0,
            branch_link_cnt: 0,
            branch_change_price: 0,
          },
          hq_policy: {
            mbshp_rfnd_day_price: 0,
            prepay_penalty_rate: 0,
            subs_penalty_rate: 0,
          },
          branch_policy: {
            cms_by_pay_method: {
              cash: 0,
              card: 0,
            },
            membership_policy: {
              use_dflt_from_date: false,
              dflt_from_date: "",
              use_max_wait_day: false,
              max_wait_day: 0,
              recommender_month: 0,
              recommender_day: 0,
              recommendee_month: 0,
              recommendee_day: 0,
              event_month_list: [],
              event_day_list: [],
              pass_mbship_count_rate: 0,
              change_limit: 0,
              pass_change_limit: 0,
              card_remake_fee: 0,
              mbshp_assign_fee: 0,
              pt_assign_fee: 0,
            },
          },
          item_info: {
            purchase_item_id: "",
            item_id: "",
            item_name: "",
            base_info: {
              incentive: 0,
            },
            mbshp_type: "",
            mbshp_option: "",
            pt_per_price: 0,
            pt_price: 0,
            pt_session: 0,
            is_combine_cloth: false,
            is_combine_gx: false,
            is_combine_locker: false,
            is_combine_pt: false,
            postpone_cnt: 0,
            ot_session: 0,
            can_assign: false,
            can_family: false,
            can_change: false,
            pass_cnt: 0,
            pass_from_time: "",
            pass_end_time: "",
            total_price: 0,
            reg_price: 0,
            total_period_month: 0,
            total_period_day: 0,
            total_period_hour: 0,
            period_month: 0,
            service_month: 0,
            service_day: 0,
            oneday_wait_hour: 0,
            mbshp_price: 0,
            cloth_price: 0,
            gx_price: 0,
            locker_price: 0,
            description: "",
            standard_price: {
              prepay_reg: 0,
              prepay_mbshp: 0,
              prepay_cloth: 0,
              prepay_gx: 0,
              prepay_locker: 0,
              oneday_mbshp: 0,
              oneday_cloth: 0,
              oneday_gx: 0,
              ot: 0,
              pt: 0,
            },
            subs_options: {
              subs_reg: 0,
              subs_period_month: 0,
              pre_month: 0,
              total_subs_price: 0,
              mbshp_subs_price: 0,
              mbshp_pre_price: 0,
              cloth_subs_price: 0,
              cloth_pre_price: 0,
              gx_subs_price: 0,
              gx_pre_price: 0,
              locker_subs_price: 0,
              locker_pre_price: 0,
            },
            reg_info: {
              created_at: "",
              updated_at: "",
              reg_name: "",
              reg_id: "",
              upd_id: "",
              upd_name: "",
            },
          },
        },
        membership_list: {
          item_name: "",
          item_type_idx: 0,
          item_option_idx: 0,
          item_name_idx: 0,
          option_lock: false,
          membership_id: "",
          purchase_item_id: "",
          parent_purchase_code: "",
          mbshp_type: "",
          mbshp_option: "",
          mbshp_status: "",
          service_status: "",
          is_new: true,
          is_postpone_lock: false,
          paid_done_date: "",
          re_reg_path: "",
          subs_options: {
            contract_from_date: "",
            contract_to_date: "",
            auto_payment_cancel: false,
            subs_paid_cnt: 0,
            subs_total_price: 0,
            payment_done_list: [],
          },
          pass: {
            total_cnt: 0,
          },
          pass_time: {
            from_time: "",
            end_time: "",
          },
          daily_time: {
            from_time: "",
            end_time: "",
          },
          reg: {
            is_use: false,
            from_date: "",
            to_date: "",
            price: 0,
            dis: 0,
            raw_price: 0,
            is_hide: false,
            is_lock: false,
            subs_options: {
              subs_price: 0,
              pre_price: 0,
              prorate_price: 0,
              subs_dis: 0,
              pre_dis: 0,
              prorate_dis: 0,
            },
            coupon_info: {
              coupon_id: "000000000000000000000000",
              base_info: {
                benefit_type: "",
                benefit_product: "",
              },
              detail: {
                coupon_name: "",
                service_benefit: {
                  mbshp: {
                    mbshp_type: "",
                    mbshp_option: "",
                    add_month: 0,
                    add_day: 0,
                  },
                },
                price_benefit: {
                  type: "",
                  dis_price: 0,
                  dis_rate: 0,
                },
              },
              ticket_id: "",
              final_dis: 0,
            },
          },
          mbshp: {
            event_month: 0,
            event_day: 0,
            event_reason: "",
            is_use: false,
            from_date: "",
            to_date_raw: "",
            to_date: "",
            price: 0,
            dis: 0,
            raw_price: 0,
            is_hide: false,
            is_lock: false,
            subs_options: {
              subs_price: 0,
              pre_price: 0,
              prorate_price: 0,
              subs_dis: 0,
              pre_dis: 0,
              prorate_dis: 0,
            },
            coupon_info: {
              coupon_id: "000000000000000000000000",
              base_info: {
                benefit_type: "",
                benefit_product: "",
              },
              detail: {
                coupon_name: "",
                service_benefit: {
                  mbshp: {
                    mbshp_type: "",
                    mbshp_option: "",
                    add_month: 0,
                    add_day: 0,
                  },
                },
                price_benefit: {
                  type: "",
                  dis_price: 0,
                  dis_rate: 0,
                },
              },
              ticket_id: "",
              final_dis: 0,
            },
            is_add_pay: false,
          },
          cloth: {
            is_use: false,
            from_date: "",
            to_date: "",
            price: 0,
            dis: 0,
            raw_price: 0,
            is_hide: false,
            is_lock: false,
            subs_options: {
              subs_price: 0,
              pre_price: 0,
              prorate_price: 0,
              subs_dis: 0,
              pre_dis: 0,
              prorate_dis: 0,
            },
            coupon_info: {
              coupon_id: "000000000000000000000000",
              base_info: {
                benefit_type: "",
                benefit_product: "",
              },
              detail: {
                coupon_name: "",
                service_benefit: {
                  mbshp: {
                    mbshp_type: "",
                    mbshp_option: "",
                    add_month: 0,
                    add_day: 0,
                  },
                },
                price_benefit: {
                  type: "",
                  dis_price: 0,
                  dis_rate: 0,
                },
              },
              ticket_id: "",
              final_dis: 0,
            },
          },
          gx: {
            is_use: false,
            from_date: "",
            to_date: "",
            price: 0,
            dis: 0,
            raw_price: 0,
            is_hide: false,
            is_lock: false,
            subs_options: {
              subs_price: 0,
              pre_price: 0,
              prorate_price: 0,
              subs_dis: 0,
              pre_dis: 0,
              prorate_dis: 0,
            },
            coupon_info: {
              coupon_id: "000000000000000000000000",
              base_info: {
                benefit_type: "",
                benefit_product: "",
              },
              detail: {
                coupon_name: "",
                service_benefit: {
                  mbshp: {
                    mbshp_type: "",
                    mbshp_option: "",
                    add_month: 0,
                    add_day: 0,
                  },
                },
                price_benefit: {
                  type: "",
                  dis_price: 0,
                  dis_rate: 0,
                },
              },
              ticket_id: "",
              final_dis: 0,
            },
          },
          locker: {
            is_use: false,
            is_hide: false,
            is_lock: false,
            subs_options: {
              subs_price: 0,
              pre_price: 0,
              prorate_price: 0,
              subs_dis: 0,
              pre_dis: 0,
              prorate_dis: 0,
              contract_from_date: "",
              contract_to_date: "",
            },
          },
          locker_list: [],
          family_info: {
            is_use: false,
            family_info_id: "000000000000000000000000",
            member_info: {
              member_id: "000000000000000000000000",
              member_name: "",
              member_no: "",
            },
            personal_info: {
              member_name: "",
              member_no: "",
              phone: "",
              agree_personal: false,
              agree_marketing: false,
              gender: "",
              birth: "",
              email: "",
              zip_code: "",
              address_1: "",
              address_2: "",
              notice: "",
              reg_path: "",
              reg_info: {
                created_at: "",
                updated_at: "",
                reg_name: "",
                reg_id: "000000000000000000000000",
                upd_id: "000000000000000000000000",
                upd_name: "",
              },
            },
          },
          family_cloth: {
            is_use: false,
            from_date: "",
            to_date: "",
            price: 0,
            dis: 0,
            raw_price: 0,
            is_hide: false,
            is_lock: false,
            subs_options: {
              subs_price: 0,
              pre_price: 0,
              prorate_price: 0,
              subs_dis: 0,
              pre_dis: 0,
              prorate_dis: 0,
            },
            coupon_info: {
              coupon_id: "000000000000000000000000",
              base_info: {
                benefit_type: "",
                benefit_product: "",
              },
              detail: {
                coupon_name: "",
                service_benefit: {
                  mbshp: {
                    mbshp_type: "",
                    mbshp_option: "",
                    add_month: 0,
                    add_day: 0,
                  },
                },
                price_benefit: {
                  type: "",
                  dis_price: 0,
                  dis_rate: 0,
                },
              },
              ticket_id: "",
              final_dis: 0,
            },
          },
          family_gx: {
            is_use: false,
            from_date: "",
            to_date: "",
            price: 0,
            dis: 0,
            raw_price: 0,
            is_hide: false,
            is_lock: false,
            subs_options: {
              subs_price: 0,
              pre_price: 0,
              prorate_price: 0,
              subs_dis: 0,
              pre_dis: 0,
              prorate_dis: 0,
            },
            coupon_info: {
              coupon_id: "000000000000000000000000",
              base_info: {
                benefit_type: "",
                benefit_product: "",
              },
              detail: {
                coupon_name: "",
                service_benefit: {
                  mbshp: {
                    mbshp_type: "",
                    mbshp_option: "",
                    add_month: 0,
                    add_day: 0,
                  },
                },
                price_benefit: {
                  type: "",
                  dis_price: 0,
                  dis_rate: 0,
                },
              },
              ticket_id: "",
              final_dis: 0,
            },
          },
          family_locker: {
            is_use: false,
            is_hide: false,
            is_lock: false,
          },
          family_locker_list: [],
          usage: {
            link_info_total: 0,
            total_postpone_cnt: 3,
            used_postpone_cnt: 0,
            acc_postpone_day: 0,
            ot_session: 1,
            family_ot_session: 1,
          },
          mbshp_postpone_list: [],
          ot_detail_info: {
            ot_detail_id: "000000000000000000000000",
          },
          family_ot_detail_info: {
            ot_detail_id: "000000000000000000000000",
          },
          ot_staff: {
            staff_id: "000000000000000000000000",
            staff_name: "",
            staff_pos: "",
            staff_rank: "",
          },
          reg_info: {
            created_at: "",
            updated_at: "",
            reg_name: "",
            reg_id: "",
            upd_id: "",
            upd_name: "",
          },
        },
        ot_info: {
          branch_info: {
            branch_id: "000000000000000000000000",
            branch_name: "",
          },
          ot_detail_id: "000000000000000000000000",
          is_use: false,
          alloc_date: "",
          remarks: "",
          ot_info: {
            proceed_class_lock: false,
            is_suggest_pt: false,
            ot_session: 0,
            mbshp_status: "",
            class_status: "",
            remain_session: 0,
          },
          membership_info: {
            item_name: "",
            membership_id: "000000000000000000000000",
            member_id: "000000000000000000000000",
            member_name: "",
            member_no: "",
            phone: "",
            from_date: "",
            to_date: "",
            gender: "",
            birth: "",
            is_new: false,
            mbshp_type: "",
            mbshp_option: "",
          },
          ot_info_change: {
            latest_contact: "",
            notice: "",
          },
          cur_manager: {
            is_assigned: false,
            staff_info: {
              staff_id: "000000000000000000000000",
              staff_name: "",
              staff_pos: "",
              staff_rank: "",
            },
            is_resigned: false,
            assign_date: "",
          },
          ot_class: null,
          service_session_hist: [],
          reg_info: {
            created_at: "",
            updated_at: "",
            reg_name: "",
            reg_id: "000000000000000000000000",
            upd_id: "000000000000000000000000",
            upd_name: "",
          },
        },
        is_use: false,
        is_new: false,
        is_add_pay: false,
        state_initialized: false,
        reg_input_info: {
          tab_title: "",
          tab_idx: 0,
          item_type: "",
          item_type_idx: 0,
          item_type_lock: false,
          item_name: "",
          item_name_idx: 0,
          item_lock: false,
          is_start: false,
          membership_price: 0,
          membership_dis: 0,
          membership_lock: false,
          event_month: 0,
          event_day: 0,
          from_date: "",
          to_date: "",
          ot_manager: "",
          friend: "",
          use_join: false,
          join_price: 0,
          join_dis: 0,
          join_lock: false,
          join_view: false,
          use_cloth: false,
          cloth_price: 0,
          cloth_dis: 0,
          cloth_lock: false,
          use_gx: false,
          gx_price: 0,
          gx_dis: 0,
          gx_lock: false,
        },
        selected: false,
      },
    ],
    pt_list: [],
    pt_selector: [],
    purchase_list: [],
    payment_list: [],
    card_param: {
      is_use: false,
      card_no: "",
      price: 0,
      dis: 0,
      access_method: "",
      card_info_id: "",
      branch_info: {
        branch_id: "000000000000000000000000",
        branch_name: "",
      },
    },
    is_assign_card: false,
  },
  dst_member_info: {
    branch_info: {
      branch_id: "",
      branch_name: "",
    },
    member_id: "",
    personal_info: {
      member_name: "",
      member_no: "",
      phone: "",
      agree_personal: false,
      agree_marketing: false,
      gender: "",
      birth: "",
      email: "",
      zip_code: "",
      address_1: "",
      address_2: "",
      notice: "",
      reg_path: "",
      reg_info: {
        created_at: "",
        updated_at: "",
        reg_name: "",
        reg_id: "000000000000000000000000",
        upd_id: "000000000000000000000000",
        upd_name: "",
      },
    },
    membership_data_list: [
      {
        purchase_item_list: {
          erp_policy: {
            regular_pay_date: 0,
            branch_link_cnt: 0,
            branch_change_price: 0,
          },
          hq_policy: {
            mbshp_rfnd_day_price: 0,
            prepay_penalty_rate: 0,
            subs_penalty_rate: 0,
          },
          branch_policy: {
            cms_by_pay_method: {
              cash: 0,
              card: 0,
            },
            membership_policy: {
              use_dflt_from_date: false,
              dflt_from_date: "",
              use_max_wait_day: false,
              max_wait_day: 0,
              recommender_month: 0,
              recommender_day: 0,
              recommendee_month: 0,
              recommendee_day: 0,
              event_month_list: [],
              event_day_list: [],
              pass_mbship_count_rate: 0,
              change_limit: 0,
              pass_change_limit: 0,
              card_remake_fee: 0,
              mbshp_assign_fee: 0,
              pt_assign_fee: 0,
            },
          },
          item_info: {
            purchase_item_id: "",
            item_id: "",
            item_name: "",
            base_info: {
              incentive: 0,
            },
            mbshp_type: "",
            mbshp_option: "",
            pt_per_price: 0,
            pt_price: 0,
            pt_session: 0,
            is_combine_cloth: false,
            is_combine_gx: false,
            is_combine_locker: false,
            is_combine_pt: false,
            postpone_cnt: 0,
            ot_session: 0,
            can_assign: false,
            can_family: false,
            can_change: false,
            pass_cnt: 0,
            pass_from_time: "",
            pass_end_time: "",
            total_price: 0,
            reg_price: 0,
            total_period_month: 0,
            total_period_day: 0,
            total_period_hour: 0,
            period_month: 0,
            service_month: 0,
            service_day: 0,
            oneday_wait_hour: 0,
            mbshp_price: 0,
            cloth_price: 0,
            gx_price: 0,
            locker_price: 0,
            description: "",
            standard_price: {
              prepay_reg: 0,
              prepay_mbshp: 0,
              prepay_cloth: 0,
              prepay_gx: 0,
              prepay_locker: 0,
              oneday_mbshp: 0,
              oneday_cloth: 0,
              oneday_gx: 0,
              ot: 0,
              pt: 0,
            },
            subs_options: {
              subs_reg: 0,
              subs_period_month: 0,
              pre_month: 0,
              total_subs_price: 0,
              mbshp_subs_price: 0,
              mbshp_pre_price: 0,
              cloth_subs_price: 0,
              cloth_pre_price: 0,
              gx_subs_price: 0,
              gx_pre_price: 0,
              locker_subs_price: 0,
              locker_pre_price: 0,
            },
            reg_info: {
              created_at: "",
              updated_at: "",
              reg_name: "",
              reg_id: "",
              upd_id: "",
              upd_name: "",
            },
          },
        },
        membership_list: {
          item_name: "",
          item_type_idx: 0,
          item_option_idx: 0,
          item_name_idx: 0,
          option_lock: false,
          membership_id: "",
          purchase_item_id: "",
          parent_purchase_code: "",
          mbshp_type: "",
          mbshp_option: "",
          mbshp_status: "",
          service_status: "",
          is_new: true,
          is_postpone_lock: false,
          paid_done_date: "",
          re_reg_path: "",
          subs_options: {
            contract_from_date: "",
            contract_to_date: "",
            auto_payment_cancel: false,
            subs_paid_cnt: 0,
            subs_total_price: 0,
            payment_done_list: [],
          },
          pass: {
            total_cnt: 0,
          },
          pass_time: {
            from_time: "",
            end_time: "",
          },
          daily_time: {
            from_time: "",
            end_time: "",
          },
          reg: {
            is_use: false,
            from_date: "",
            to_date: "",
            price: 0,
            dis: 0,
            raw_price: 0,
            is_hide: false,
            is_lock: false,
            subs_options: {
              subs_price: 0,
              pre_price: 0,
              prorate_price: 0,
              subs_dis: 0,
              pre_dis: 0,
              prorate_dis: 0,
            },
            coupon_info: {
              coupon_id: "000000000000000000000000",
              base_info: {
                benefit_type: "",
                benefit_product: "",
              },
              detail: {
                coupon_name: "",
                service_benefit: {
                  mbshp: {
                    mbshp_type: "",
                    mbshp_option: "",
                    add_month: 0,
                    add_day: 0,
                  },
                },
                price_benefit: {
                  type: "",
                  dis_price: 0,
                  dis_rate: 0,
                },
              },
              ticket_id: "",
              final_dis: 0,
            },
          },
          mbshp: {
            event_month: 0,
            event_day: 0,
            event_reason: "",
            is_use: false,
            from_date: "",
            to_date_raw: "",
            to_date: "",
            price: 0,
            dis: 0,
            raw_price: 0,
            is_hide: false,
            is_lock: false,
            subs_options: {
              subs_price: 0,
              pre_price: 0,
              prorate_price: 0,
              subs_dis: 0,
              pre_dis: 0,
              prorate_dis: 0,
            },
            coupon_info: {
              coupon_id: "000000000000000000000000",
              base_info: {
                benefit_type: "",
                benefit_product: "",
              },
              detail: {
                coupon_name: "",
                service_benefit: {
                  mbshp: {
                    mbshp_type: "",
                    mbshp_option: "",
                    add_month: 0,
                    add_day: 0,
                  },
                },
                price_benefit: {
                  type: "",
                  dis_price: 0,
                  dis_rate: 0,
                },
              },
              ticket_id: "",
              final_dis: 0,
            },
            is_add_pay: false,
          },
          cloth: {
            is_use: false,
            from_date: "",
            to_date: "",
            price: 0,
            dis: 0,
            raw_price: 0,
            is_hide: false,
            is_lock: false,
            subs_options: {
              subs_price: 0,
              pre_price: 0,
              prorate_price: 0,
              subs_dis: 0,
              pre_dis: 0,
              prorate_dis: 0,
            },
            coupon_info: {
              coupon_id: "000000000000000000000000",
              base_info: {
                benefit_type: "",
                benefit_product: "",
              },
              detail: {
                coupon_name: "",
                service_benefit: {
                  mbshp: {
                    mbshp_type: "",
                    mbshp_option: "",
                    add_month: 0,
                    add_day: 0,
                  },
                },
                price_benefit: {
                  type: "",
                  dis_price: 0,
                  dis_rate: 0,
                },
              },
              ticket_id: "",
              final_dis: 0,
            },
          },
          gx: {
            is_use: false,
            from_date: "",
            to_date: "",
            price: 0,
            dis: 0,
            raw_price: 0,
            is_hide: false,
            is_lock: false,
            subs_options: {
              subs_price: 0,
              pre_price: 0,
              prorate_price: 0,
              subs_dis: 0,
              pre_dis: 0,
              prorate_dis: 0,
            },
            coupon_info: {
              coupon_id: "000000000000000000000000",
              base_info: {
                benefit_type: "",
                benefit_product: "",
              },
              detail: {
                coupon_name: "",
                service_benefit: {
                  mbshp: {
                    mbshp_type: "",
                    mbshp_option: "",
                    add_month: 0,
                    add_day: 0,
                  },
                },
                price_benefit: {
                  type: "",
                  dis_price: 0,
                  dis_rate: 0,
                },
              },
              ticket_id: "",
              final_dis: 0,
            },
          },
          locker: {
            is_use: false,
            is_hide: false,
            is_lock: false,
          },
          locker_list: [],
          family_info: {
            is_use: false,
            family_info_id: "000000000000000000000000",
            member_info: {
              member_id: "000000000000000000000000",
              member_name: "",
              member_no: "",
            },
            personal_info: {
              member_name: "",
              member_no: "",
              phone: "",
              agree_personal: false,
              agree_marketing: false,
              gender: "",
              birth: "",
              email: "",
              zip_code: "",
              address_1: "",
              address_2: "",
              notice: "",
              reg_path: "",
              reg_info: {
                created_at: "",
                updated_at: "",
                reg_name: "",
                reg_id: "000000000000000000000000",
                upd_id: "000000000000000000000000",
                upd_name: "",
              },
            },
          },
          family_cloth: {
            is_use: false,
            from_date: "",
            to_date: "",
            price: 0,
            dis: 0,
            raw_price: 0,
            is_hide: false,
            is_lock: false,
            subs_options: {
              subs_price: 0,
              pre_price: 0,
              prorate_price: 0,
              subs_dis: 0,
              pre_dis: 0,
              prorate_dis: 0,
            },
            coupon_info: {
              coupon_id: "000000000000000000000000",
              base_info: {
                benefit_type: "",
                benefit_product: "",
              },
              detail: {
                coupon_name: "",
                service_benefit: {
                  mbshp: {
                    mbshp_type: "",
                    mbshp_option: "",
                    add_month: 0,
                    add_day: 0,
                  },
                },
                price_benefit: {
                  type: "",
                  dis_price: 0,
                  dis_rate: 0,
                },
              },
              ticket_id: "",
              final_dis: 0,
            },
          },
          family_gx: {
            is_use: false,
            from_date: "",
            to_date: "",
            price: 0,
            dis: 0,
            raw_price: 0,
            is_hide: false,
            is_lock: false,
            subs_options: {
              subs_price: 0,
              pre_price: 0,
              prorate_price: 0,
              subs_dis: 0,
              pre_dis: 0,
              prorate_dis: 0,
            },
            coupon_info: {
              coupon_id: "000000000000000000000000",
              base_info: {
                benefit_type: "",
                benefit_product: "",
              },
              detail: {
                coupon_name: "",
                service_benefit: {
                  mbshp: {
                    mbshp_type: "",
                    mbshp_option: "",
                    add_month: 0,
                    add_day: 0,
                  },
                },
                price_benefit: {
                  type: "",
                  dis_price: 0,
                  dis_rate: 0,
                },
              },
              ticket_id: "",
              final_dis: 0,
            },
          },
          family_locker: {
            is_use: false,
            is_hide: false,
            is_lock: false,
          },
          family_locker_list: [],
          usage: {
            link_info_total: 0,
            total_postpone_cnt: 3,
            used_postpone_cnt: 0,
            acc_postpone_day: 0,
            ot_session: 1,
            family_ot_session: 1,
          },
          mbshp_postpone_list: [],
          ot_detail_info: {
            ot_detail_id: "000000000000000000000000",
          },
          family_ot_detail_info: {
            ot_detail_id: "000000000000000000000000",
          },
          ot_staff: {
            staff_id: "000000000000000000000000",
            staff_name: "",
            staff_pos: "",
            staff_rank: "",
          },
          reg_info: {
            created_at: "",
            updated_at: "",
            reg_name: "",
            reg_id: "",
            upd_id: "",
            upd_name: "",
          },
        },
        ot_info: {
          branch_info: {
            branch_id: "000000000000000000000000",
            branch_name: "",
          },
          ot_detail_id: "000000000000000000000000",
          is_use: false,
          alloc_date: "",
          remarks: "",
          ot_info: {
            proceed_class_lock: false,
            is_suggest_pt: false,
            ot_session: 0,
            mbshp_status: "",
            class_status: "",
            remain_session: 0,
          },
          membership_info: {
            item_name: "",
            membership_id: "000000000000000000000000",
            member_id: "000000000000000000000000",
            member_name: "",
            member_no: "",
            phone: "",
            from_date: "",
            to_date: "",
            gender: "",
            birth: "",
            is_new: false,
            mbshp_type: "",
            mbshp_option: "",
            latest_contact: "",
          },

          cur_manager: {
            is_assigned: false,
            staff_info: {
              staff_id: "000000000000000000000000",
              staff_name: "",
              staff_pos: "",
              staff_rank: "",
            },
            is_resigned: false,
            assign_date: "",
          },
          ot_class: null,
          service_session_hist: [],
          reg_info: {
            created_at: "",
            updated_at: "",
            reg_name: "",
            reg_id: "000000000000000000000000",
            upd_id: "000000000000000000000000",
            upd_name: "",
          },
        },
        is_use: false,
        is_new: false,
        is_add_pay: false,
        state_initialized: false,
        reg_input_info: {
          tab_title: "",
          tab_idx: 0,
          item_type: "",
          item_type_idx: 0,
          item_type_lock: false,
          item_name: "",
          item_name_idx: 0,
          item_lock: false,
          is_start: false,
          membership_price: 0,
          membership_dis: 0,
          membership_lock: false,
          event_month: 0,
          event_day: 0,
          from_date: "",
          to_date: "",
          ot_manager: "",
          friend: "",
          use_join: false,
          join_price: 0,
          join_dis: 0,
          join_lock: false,
          join_view: false,
          use_cloth: false,
          cloth_price: 0,
          cloth_dis: 0,
          cloth_lock: false,
          use_gx: false,
          gx_price: 0,
          gx_dis: 0,
          gx_lock: false,
        },
        selected: false,
      },
    ],
    pt_list: [],
    pt_selector: [],
    purchase_list: [],
    payment_list: [],
    card_param: {
      is_use: false,
      card_no: "",
      price: 0,
      dis: 0,
      access_method: "",
      card_info_id: "",
      branch_info: {
        branch_id: "000000000000000000000000",
        branch_name: "",
      },
    },
    is_assign_card: false,
  },
  src_option: {
    mbshp_event_include_list: [
      "모든 행사기간 제외",
      "행사 추가기간 제외",
      "기본 행사기간 제외",
      "모든 행사기간 포함",
    ],
    mbshp_event_include: "모든 행사기간 포함",
    pt_include_service_session: false,
  },
  assign_price: {
    change_branch_price: 0,
    assign_mbsh_price: 33000,
    assign_mbsh_dis: 0,
    assign_pt_price: 33000,
    assign_pt_dis: 0,
  },
  dst_option: {
    is_new: false,
    is_new_card: false,
    new_card_param: {
      is_use: false,
      card_no: "",
      price: 0,
      dis: 0,
      access_method: "",
      card_info_id: "000000000000000000000000",
      branch_info: {
        branch_id: "000000000000000000000000",
        branch_name: "",
      },
    },
    is_lock_branch_change_price: true,
  },
  update_by_server: true,
  paymentA: {
    payment_info: [
      {
        paid_item_list: [
          {
            MembershipIdx: 0,
            content_obj: {
              dis: 0,
              item_type: "",
              pay_order: 0,
              price: 0,
              tab_idx: 0,
              type: "",
            },
            orderIdx: 0,
            type_key: "",
          },
        ],
        paid_price: 0,
        payment_input: {
          pay_method: [
            {
              approve_no: "",
              bank_user_name: "",
              buyer: "",
              card_comp: "",
              card_no: "",
              id: "",
              // installment_plan: 0,
              installment: "",
              pay_date: "",
              pay_method: "",
              price: 0,
            },
          ],
          total_paid: 0,
          unpaid_info: {
            promise_date: "",
            reason: "",
            unpaid_price: 0,
          },
        },
        purchase_code: "",
        unpaid_price: 0,
      },
    ],
  },
  cs_staff: {
    staff_id: "000000000000000000000000",
    staff_name: "",
    staff_pos: "",
    staff_rank: "",
  },
  cs_team_info_list: [],
  cs_team_name_list: [],
};
