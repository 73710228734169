import { HttpInstance } from "lib/HttpLib";

export const getPermissionAll = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/erp_set/package_permission", {
    params: {
      package_id: param.package_id === null ? "" : param.package_id,
      // branch_name: param.branch_name === null ? "" : param.branch_name,
    },
  });
};

export const getHqViewApi = () => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/erp_set/hq_view", {
    params: {
      // branch_id: param.branch_id === null ? "" : param.branch_id,
      // branch_name: param.branch_name === null ? "" : param.branch_name,
    },
  });
};

export const getIpAllowViewApi = () => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/erp_set/ip_allow_view", {
    params: {
      // branch_id: param.branch_id === null ? "" : param.branch_id,
      // branch_name: param.branch_name === null ? "" : param.branch_name,
    },
  });
};

export const getPolicyViewApi = () => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/erp_set/policy_view", {
    params: {
      // branch_id: param.branch_id === null ? "" : param.branch_id,
      // branch_name: param.branch_name === null ? "" : param.branch_name,
    },
  });
};

export const getCouponViewApi = () => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/erp_set/coupon/list", {
    params: {
      // branch_id: param.branch_id === null ? "" : param.branch_id,
      // branch_name: param.branch_name === null ? "" : param.branch_name,
    },
  });
};

const get_api = {
  getPermissionAll,
  getHqViewApi,
  getPolicyViewApi,
  getIpAllowViewApi,
  getCouponViewApi,
};

export default get_api;
