import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import CheckBox from "components/LabelInput/CheckBox";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import { globalAlertOn } from "modules/actions/Alert";
import FileUploadBox from "pages/NoticeBoard/CommonItem/FileUploadBox";
import TextEditor from "pages/NoticeBoard/CommonItem/TextEditor";
import { onSelectBranch, onSelectHq } from "pages/NoticeBoard/model/state_setter";
import { newPostRowAPi } from "pages/NoticeBoard/restApi/postApi";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const ReferenceCreateView = ({
  nowService,
  postRowState,
  setPostRowState,
  hqBranchData,
  branchList,
}) => {
  const btnStyleClass = btnStyles();
  const navigate = useNavigate();
  const storesDispatch = useDispatch();
  const [hqIdx, setHqIdx] = useState(0);

  const alertCancelMsg = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="작성중인 내용은 저장되지 않습니다." />
            <Label labelText="작성을 취소하시겠습니까?" />
          </>
        ),
        onBtnYes: () => {
          navigate("/reference");
        },
        onBtnNo: () => {},
      }),
    );
  };

  //저장 validation
  const validationCheck = () => {
    //본사,지점,제목 확인하고
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="저장 하시겠습니까?" />
          </>
        ),
        onBtnYes: () => {
          addPost();
        },
        onBtnNo: () => {},
      }),
    );
  };

  //게시물작성 POST
  const addPost = async () => {
    newPostRowAPi(navigate, storesDispatch, postRowState);
  };
  return (
    <CardFixed
      topDom={"게시글 작성"}
      midDom={
        <div>
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ComboBox
                labelText="본부"
                labelWidth="2rem"
                comboItemWidth="14rem"
                // data={[]}
                data={hqBranchData.hq_list}
                defaultValue={hqBranchData.hq_list[0]}
                //  disabled={modalParam.mode === "show"}
                onChangeCallback={(e) => {
                  onSelectHq(e.target.value, hqBranchData, setPostRowState, setHqIdx);
                  // onSelectHq(e.target.value);
                  console.log(e);
                }}
              />
              <ComboBox
                labelText="지점"
                labelWidth="2rem"
                labelMarginLeft="1.2rem"
                comboItemWidth="14rem"
                // data={[]}
                data={hqIdx !== undefined ? hqBranchData.branch_list[hqIdx] : []}
                defaultValue={hqBranchData.branch_list[hqIdx][0]}
                onChangeCallback={(e) => {
                  onSelectBranch(e.target.value, hqBranchData, hqIdx, setPostRowState);
                }}
              />
              <CheckBox
                labelText="지점 내 공지"
                transform="scale(1)"
                marginTop="0rem"
                marginRight="0rem"
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", marginTop: "0.5rem" }}>
              <TextField
                labelText="제목"
                fullWidth
                labelWidth="2rem"
                defaultValue={postRowState.post_info.title}
                onChangeCallback={(e) => {
                  setPostRowState((cur) => {
                    return {
                      ...cur,
                      post_info: {
                        ...cur.post_info,
                        title: e,
                      },
                    };
                  });
                }}
              />
              <CheckBox
                labelText="중요"
                labelWidth="2rem"
                transform="scale(1)"
                marginTop="0rem"
                marginRight="0rem"
                // defaultValue={postRowState.post_info.title}
                onChangeCallback={(e) => {
                  setPostRowState((cur) => {
                    return {
                      ...cur,
                      post_info: {
                        ...cur.post_info,
                        is_important: e.checked,
                      },
                    };
                  });
                }}
              />
            </div>
          </div>

          <TextEditor
            defaultValue={postRowState.post_info.contents}
            onChangeCallback={(e) => {
              setPostRowState((cur) => {
                return {
                  ...cur,
                  post_info: {
                    ...cur.post_info,
                    contents: e,
                  },
                };
              });
            }}
          />
          <FileUploadBox postRootState={postRowState} nowService={nowService} />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "0.5rem",
              marginTop: "1.2rem",
            }}
          >
            <Button
              className={clsx({
                [btnStyleClass.btnRootGrayWhite]: true,
                [btnStyleClass.buttonType4]: true,
              })}
              onClick={alertCancelMsg}
            >
              취소
            </Button>
            <Button
              className={clsx({
                [btnStyleClass.btnRootRaw]: true,
                [btnStyleClass.buttonType4]: true,
              })}
              onClick={validationCheck}
            >
              저장
            </Button>
          </div>
        </div>
      }
      isLoading={false}
      rootStyle={{
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};
export default ReferenceCreateView;
