import styled from "styled-components";

export const DailySalesModalBody = styled.div`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const DailySalesModalFooter = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1.5rem;
`;
