import { contentTypeJson } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

// ==================== GET ====================
// [GET] 급여 관리 검색 조건 받아오기 및 권한 데이터 세팅
export async function getSalarySearchParam() {
  const formData = new FormData();
  formData.append("", JSON.stringify());

  return await HttpInstance.post(
    process.env.REACT_APP_SALARY_SERVER + "/salary/manage_list/search_param",
    formData,
    contentTypeJson,
  );
}

// [GET] 급여 관리 내역 리스트 검색
export async function getSalaryList(queryData) {
  const { page, searchParams } = queryData;
  const formData = new FormData();

  formData.append("search_param", JSON.stringify(searchParams));
  formData.append("page_num", page);

  return await HttpInstance.post(
    process.env.REACT_APP_SALARY_SERVER + "/salary/manage_list",
    formData,
    contentTypeJson,
  );
}
// [DELETE] 급여 관리 내역 Row 삭제
export async function deleteSalary(salaryReportId) {
  return await HttpInstance.delete(
    process.env.REACT_APP_SALARY_SERVER + "/salary/" + salaryReportId,
    contentTypeJson,
  );
}
