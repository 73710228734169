import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import border from "assets/icon/Border.svg";
import IconButton from "components/Button/IconButton";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableCell, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import SubTitle from "components/PageTitle/SubTitle";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useMemo, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch } from "react-redux";

const TotalInfoTable = ({ searchList }) => {
  const result = useMemo(() => {
    let cur_sales = 0;
    let goal_sales = 0;
    let rate = 0;
    let ot_reserv = 0;
    let ot_done = 0;
    let pt_reserv = 0;
    let pt_done = 0;

    for (let i = 0; i < searchList.length; i++) {
      cur_sales += searchList[i].weekly_summary.task_objective_info.current_sales;
      goal_sales += searchList[i].weekly_summary.task_objective_info.goal_sales;
      rate += searchList[i].weekly_summary.task_objective_info.goal_rate;
      ot_reserv += searchList[i].weekly_summary.task_activity_info.ot_reserv;
      ot_done += searchList[i].weekly_summary.task_activity_info.ot_class;
      pt_reserv += searchList[i].weekly_summary.task_activity_info.pt_reserv;
      pt_done += searchList[i].weekly_summary.task_activity_info.pt_class;
    }

    return {
      cur_sales,
      goal_sales,
      rate: Math.floor(rate / 3),
      ot_reserv,
      ot_done,
      pt_reserv,
      pt_done,
    };
  }, [searchList]);

  return (
    <div style={{ position: "relative", top: "-1px", marginLeft: "0.5px", marginRight: "1px" }}>
      <TableContainer
        style={{
          border: "1px solid #CCC",
        }}
      >
        <Table>
          <TableHead>
            <CustomRow style={{ width: "100%" }}>
              <CustomTableCell style={{ backgroundColor: "#dfebf3" }} align="center" width="10%">
                총계
              </CustomTableCell>
              <CustomTableCell width="15%" $isNumber>
                {result.cur_sales.toLocaleString() + " 원"}
              </CustomTableCell>
              <CustomTableCell width="15%" $isNumber>
                {result.goal_sales.toLocaleString() + " 원"}
              </CustomTableCell>
              <CustomTableCell width="10%" $isNumber>
                {result.rate + "%"}
              </CustomTableCell>
              <CustomTableCell width="10%" $bgColor="#F0EBFB">
                {result.ot_reserv}
              </CustomTableCell>
              <CustomTableCell width="10%" $bgColor="#F0EBFB">
                {result.ot_done}
              </CustomTableCell>
              <CustomTableCell width="10%" $bgColor="#E4F4E1">
                {result.pt_reserv}
              </CustomTableCell>
              <CustomTableCell width="10%" $bgColor="#E4F4E1">
                {result.pt_done}
              </CustomTableCell>
              <CustomTableCell width="9.8%"></CustomTableCell>
            </CustomRow>
          </TableHead>
        </Table>
      </TableContainer>
    </div>
  );
};

const WeekCard = ({ data, summaryInfo }) => {
  const storesDispatch = useDispatch();
  const viewModelRef = useRef();
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });

  const [staffNameList, setStaffNameList] = useState([]);
  const [graphList, setGraphList] = useState([]);

  // 들어온 데이터로 주차 별 StaffNameList 만들어 줌
  const createStaffNameList = (data) => {
    const staffList = [];
    if (data.search_result_total.length > 0) {
      data.search_result_total.forEach((el) => {
        staffList.push(el.staff_info.staff_name);
      });
    }
    setStaffNameList(staffList);
  };

  useEffect(() => {
    const graphResult = data.search_result_total.map(
      (el) => el.weekly_summary.task_objective_info.current_sales,
    );
    setGraphList(graphResult);
    createStaffNameList(data);
  }, [data]);

  const popSalesDetailModal = (title, param, localModalDone) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="서비스 준비중입니다." />
          </>
        ),
        onBtnOk: () => {},
      }),
    );

    // setModalInfo({
    //   show: true,
    //   title: title,
    //   Content: <SalesPlanDetailModal modalParam={param} onModalDone={localModalDone} />,
    //   callback: null,
    //   Buttons: undefined,
    // });
  };

  const salesData = new salesTable();
  const otData = new classOtTable();
  const ptData = new classPtTable();
  const detailData = new detailTable(popSalesDetailModal);
  const staffDetailData = new staffDetailTable();

  const obj = {
    series: [
      {
        name: "현재 매출",
        data: graphList,
      },
    ],
    options: {
      chart: {
        height: 100,
        type: "bar",
        toolbar: false,
      },
      legend: {
        show: false,
      },
      tooltip: {
        style: {
          fontFamily: "AppleSDGothicNeo",
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "70%",
          borderRadius: 3,
          dataLabels: {
            // position: "center", // top, center, bottom
          },
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
        formatter: function (val) {
          return val.toLocaleString() + " 원";
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          fontFamily: "AppleSDGothicNeo",
          colors: ["#304758"],
        },
      },

      xaxis: {
        categories: staffNameList.length > 0 ? staffNameList : summaryInfo.nameList,
        tickPlacement: "on",
        position: "bottom",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: false,
        },
        labels: {
          style: {
            fontFamily: "AppleSDGothicNeo",
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val.toLocaleString() + "원";
          },
          style: {
            fontFamily: "AppleSDGothicNeo",
          },
        },
      },
    },
  };

  return (
    <div>
      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <Label
          marginLeft="0.3rem"
          labelText={`조회 결과: ${data.week_info.week_ord_name}`}
          fontWeight={"bold"}
          fontSize="1.1rem"
        />
      </div>
      <div>
        <div style={{ display: "flex", alignItems: "center", marginTop: "0.5rem" }}>
          <img
            alt="border"
            src={border}
            style={{
              width: "1.2rem",
              height: "1.2rem",
            }}
          />

          <SubTitle titleText="총계" />
        </div>

        <div>
          <ReactApexChart options={obj.options} series={obj.series} type="bar" height={350} />
        </div>
        <div style={{ marginTop: "0.7rem", width: "100%", display: "flex" }}>
          <div style={{ width: "50%" }}>
            <CustomTable
              columns_head={salesData.columns_head}
              table_title={salesData.table_title}
              rest_call={salesData.get_data_from_rest}
              row_render={salesData.create_table}
              rest_data={data.search_result_total}
              marginRight="0rem"
            />
          </div>
          <div style={{ width: "20%" }}>
            <CustomTable
              bgColor={"#F0EBFB"}
              columns_head={otData.columns_head}
              columns_head_2={otData.columns_head_2}
              table_title={otData.table_title}
              rest_call={otData.get_data_from_rest}
              row_render={otData.create_table}
              rest_data={data.search_result_total}
              isDouble
              marginRight="0rem"
              marginLeft="0.5px"
            />
          </div>
          <div style={{ width: "20%" }}>
            <CustomTable
              bgColor={"#E4F4E1"}
              columns_head={ptData.columns_head}
              columns_head_2={ptData.columns_head_2}
              table_title={ptData.table_title}
              rest_call={ptData.get_data_from_rest}
              row_render={ptData.create_table}
              rest_data={data.search_result_total}
              isDouble
              marginRight="0rem"
              marginLeft="0.5px"
            />
          </div>
          <div style={{ width: "10%" }}>
            <CustomTable
              columns_head={detailData.columns_head}
              table_title={detailData.table_title}
              rest_call={detailData.get_data_from_rest}
              row_render={detailData.create_table}
              rest_data={data.search_result_total}
              marginRight="0rem"
            />
          </div>
        </div>
        <TotalInfoTable searchList={data.search_result_total} />
      </div>
      <div style={{ display: "flex", marginTop: "1rem" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="내역" />
      </div>
      <CustomTable
        columns_head={staffDetailData.columns_head}
        table_title={staffDetailData.table_title}
        rest_call={staffDetailData.get_data_from_rest}
        row_render={staffDetailData.create_table}
        rest_data={data.staff_sales_all_list}
        maxHeight={"15rem"}
      />
    </div>
  );
};

export default WeekCard;

//현재매출,목표매출, 달성률
class salesTable {
  table_title = "";

  create_table = (data, key) => {
    return (
      <CustomRow key={key}>
        <CustomTableContents>{data.staff_info.staff_name}</CustomTableContents>
        <CustomTableContents $isNumber>
          {data.weekly_summary.task_objective_info.current_sales.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.weekly_summary.task_objective_info.goal_sales.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.weekly_summary.task_objective_info.goal_rate + "%"}
        </CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "직원명", width: "20%", height: "3.4rem" },
    { title: "현재 매출", width: "30%", height: "3.4rem" },
    { title: "목표 매출", width: "30%", height: "3.4rem" },
    { title: "달성률", width: "20%", height: "3.4rem" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}

//OT
class classOtTable {
  table_title = "";

  create_table = (data, key) => {
    return (
      <CustomRow key={key}>
        <CustomTableContents>
          {data.weekly_summary.task_activity_info.ot_reserv}
        </CustomTableContents>
        <CustomTableContents>{data.weekly_summary.task_activity_info.ot_class}</CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "O", width: "", align: "right", combineRight: true },
    { title: "T", width: "", align: "left", combineLeft: true },
  ];
  columns_head_2 = [
    { title: "예약", width: "50%" },
    { title: "완료", width: "50%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}

class classPtTable {
  table_title = "";

  create_table = (data, key) => {
    return (
      <CustomRow key={key}>
        <CustomTableContents>
          {data.weekly_summary.task_activity_info.pt_reserv}
        </CustomTableContents>
        <CustomTableContents>{data.weekly_summary.task_activity_info.pt_class}</CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "P", width: "", align: "right", combineRight: true },
    { title: "T", width: "", align: "left", combineLeft: true },
  ];
  columns_head_2 = [
    { title: "예약", width: "50%" },
    { title: "완료", width: "50%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}

class detailTable {
  table_title = "";
  popSalesDetailModal = null;

  constructor(popSalesDetailModal) {
    this.popSalesDetailModal = popSalesDetailModal;
  }

  create_table = (data, key) => {
    return (
      <CustomRow key={key}>
        <CustomTableContents>
          <IconButton
            type="detail"
            onClick={() => {
              this.popSalesDetailModal("매출 계획 상세보기", {});
            }}
          />
        </CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [{ title: "매출계획 상세보기", height: "3.4rem" }];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}

class staffDetailTable {
  table_title = "";

  create_table = (data, key) => {
    return (
      <CustomRow key={key}>
        <CustomTableContents>
          {data.staff_info.staff_name + " " + data.staff_info.staff_pos}
        </CustomTableContents>
        <CustomTableContents>{data.sales_info.member_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.sales_info.member_info.member_name}</CustomTableContents>
        <CustomTableContents>{data.sales_info.member_info.phone}</CustomTableContents>
        <CustomTableContents $isNumber>
          {data.sales_info.pt_price.paid_price_tax.toLocaleString() + " 원"}
        </CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "직원명", width: "20%" },
    { title: "회원번호", width: "20%" },
    { title: "이름", width: "20%" },
    { title: "연락처", width: "20%" },
    { title: "결제금액", width: "20%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
