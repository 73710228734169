import { contentTypeJson } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

// ==================== GET ====================
// [GET] OT 수업 관리 리스트 검색
export async function getOtManageList(queryData) {
  const { page, searchParam } = queryData;
  const formData = new FormData();
  formData.append("page_num", page);
  formData.append("search_param", JSON.stringify(searchParam));

  return await HttpInstance.post(
    process.env.REACT_APP_STAFF_SERVER + "/class/search_ot_manage_list",
    formData,
    contentTypeJson,
  );
}
// [GET] PT 수업 관리 리스트 검색
export async function getPtManageList(queryData) {
  const { page, searchParam, isExcel } = queryData;
  const formData = new FormData();

  formData.append("search_param", JSON.stringify(searchParam));
  formData.append("page_num", page);
  formData.append("is_excel", isExcel);

  return await HttpInstance.post(
    process.env.REACT_APP_STAFF_SERVER + "/class/search_pt_manage_list",
    formData,
    contentTypeJson,
  );
}
// [GET] GX 수업 관리 리스트 검색
export async function getGxManageList(queryData) {
  const { page, searchParam } = queryData;
  const formData = new FormData();

  formData.append("search_param", JSON.stringify(searchParam));
  formData.append("page_num", page);

  return await HttpInstance.post(
    process.env.REACT_APP_STAFF_SERVER + "/class/search_gx_manage_list",
    formData,
    contentTypeJson,
  );
}
