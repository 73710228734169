import NormalBtn from "components/NewButton/NormalBtn";

//MODE
///add
///view
///edit
const ModalBtnModeGroup = ({ mode, onClickEvent, onCancelEvent }) => {
  const btnStyle = {
    marginLeft: "auto",
    display: "table",
  };
  if (mode === "add") {
    return (
      <div style={btnStyle}>
        <NormalBtn name="저장" onClick={() => onClickEvent()} />
      </div>
    );
  } else if (mode === "view") {
    return (
      <div style={btnStyle}>
        <NormalBtn name="수정" onClick={() => onClickEvent()} />
      </div>
    );
  } else if (mode === "edit") {
    return (
      <div style={btnStyle}>
        <NormalBtn name="저장" onClick={() => onClickEvent()} />
      </div>
    );
  } else {
    return <div></div>;
  }
};
export default ModalBtnModeGroup;
