import ComboBox from "components/LabelInput/ComboBox";

import Label from "components/LabelInput/Label";
import NormalBtn from "components/NewButton/NormalBtn";

const SubsPayCancelModal = ({ modalParam, onModalDone }) => {
  const popupType = 3;

  return (
    <div>
      {popupType === 1 ? ( // 약정 종료일 1개월 이내인 구독 없음
        <>
          <Label labelText="정기결제 해지 예약을 할 수 없습니다." fontSize="1rem" />
          <Label
            labelText="사유 : 약정 종료일이 1개월 이내인 회원권이 없습니다."
            fontSize="1rem"
            marginTop="0.5rem"
          />
        </>
      ) : popupType === 2 ? ( // 대기중인 구독권 있음
        <>
          <Label labelText="정기결제 해지 예약을 할 수 없습니다." fontSize="1rem" />
          <Label
            labelText="사유 : 대기 중인 구독 회원권이 있습니다."
            fontSize="1rem"
            marginTop="0.5rem"
          />
        </>
      ) : (
        // 해지예약 진행
        <div>
          <Label labelText="현재 수강중인 회원권의 정기결제를 해지하시겠습니까?" fontSize="1rem" />
          <Label
            labelText="회원권 종료일 이후 더 이상 자동결제가 진행되지 않습니다."
            fontSize="1rem"
            marginTop="0.5rem"
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "1rem",
            }}
          >
            <ComboBox
              labelWidth={"3.25rem"}
              comboItemWidth={"12rem"}
              labelText="담당자"
              data={["test", "test2"]}
            />
          </div>
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "1.5rem",
        }}
      >
        <NormalBtn name="확인" />
      </div>
    </div>
  );
};
export default SubsPayCancelModal;
