export const cashbook_aggregate_state = {
  hq_info: {
    hq_id: "",
    hq_name: "",
  },
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  base_date: "",
  cashbook_id: "",

  income_list: [],
  expense_list: [],
};
