import IconButton from "components/Button/IconButton";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";

const AggregatePaymentMethodModal = ({ modalParam, onModalDone }) => {
  const paymentTableInst = new paymentResultTable();
  const refundTableInst = new refundResultTable();

  return (
    <div style={{ textAlign: "center", width: "50rem" }}>
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <SubTitle titleText="결제내역" />
        </div>
        <CustomTable
          columns_head={paymentTableInst.columns_head}
          table_title={paymentTableInst.table_title}
          rest_call={paymentTableInst.get_data_from_rest}
          row_render={paymentTableInst.create_table}
          rest_data={modalParam.paymentData}
        />
      </div>
      <div>
        <div style={{ display: "flex", alignItems: "center", marginTop: "0.75rem" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <SubTitle titleText="환불내역" />
        </div>
        <CustomTable
          columns_head={refundTableInst.columns_head}
          table_title={refundTableInst.table_title}
          rest_call={refundTableInst.get_data_from_rest}
          row_render={refundTableInst.create_table}
          rest_data={modalParam.refundData}
        />
      </div>

      <NormalBtn
        name="닫기"
        margin="1.5rem 0 0 0"
        onClick={() => {
          onModalDone();
        }}
      />
    </div>
  );
};
export default AggregatePaymentMethodModal;

// Table
class paymentResultTable {
  table_title = "";

  // 합계 계산 함수
  getTotalPrice = (data) => {
    let total = 0;
    total += data.card;
    total += data.cash;
    total += data.transfer;
    total += data.etc;

    return total;
  };

  create_table = (data, idx) => {
    return (
      <>
        <CustomRow style={{}} key={idx}>
          <CustomTableContents>{data.type}</CustomTableContents>
          <CustomTableContents>{data.card.toLocaleString()}</CustomTableContents>
          <CustomTableContents>{data.cash.toLocaleString()}</CustomTableContents>
          <CustomTableContents>{data.transfer.toLocaleString()}</CustomTableContents>
          <CustomTableContents>{data.etc.toLocaleString()}</CustomTableContents>
          <CustomTableContents>{this.getTotalPrice(data).toLocaleString()}</CustomTableContents>
        </CustomRow>
      </>
    );
  };

  columns_head = [
    { title: "구분", width: "8%" },
    { title: "카드", width: "9%" },
    { title: "현금", width: "9%" },
    { title: "이체", width: "9%" },
    { title: "기타", width: "9%" },
    { title: "합계", width: "9%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}

class refundResultTable {
  table_title = "";

  // 합계 계산 함수
  getTotalPrice = (data) => {
    let total = 0;
    total += data.card;
    total += data.cash;
    total += data.transfer;
    total += data.etc;

    return total;
  };

  create_table = (data, idx) => {
    return (
      <>
        <CustomRow style={{}} key={idx}>
          <CustomTableContents>{data.type}</CustomTableContents>
          <CustomTableContents>{data.card.toLocaleString()}</CustomTableContents>
          <CustomTableContents>{data.cash.toLocaleString()}</CustomTableContents>
          <CustomTableContents>{data.transfer.toLocaleString()}</CustomTableContents>
          <CustomTableContents>{data.etc.toLocaleString()}</CustomTableContents>
          <CustomTableContents>{this.getTotalPrice(data).toLocaleString()}</CustomTableContents>
        </CustomRow>
      </>
    );
  };

  columns_head = [
    { title: "구분", width: "8%" },
    { title: "카드", width: "9%" },
    { title: "현금", width: "9%" },
    { title: "이체", width: "9%" },
    { title: "기타", width: "9%" },
    { title: "합계", width: "9%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
