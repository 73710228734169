import { contentTypeJson, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

export const getCouncelingDetailAPi = async (storesDispatch, fileObject, stateSetter) => {
  try {
    if (fileObject.url_path !== "") {
      // console.log(process.env.REACT_APP_BRANCH_SERVER + "/board/" + postId + "/type/" + boardType);
      // contract/detail
      // let fileData = {

      // }
      const res = await HttpInstance.post(
        process.env.REACT_APP_AUTH_SERVER + "/file/download",
        fileObject,
        contentTypeJson,
      );
      // console.log(res.data);
      if (!restEmpty(res.data.data)) {
        stateSetter(res.data.data);
      }
    }
  } catch (err) {
    console.log(err);
    simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
  }
};

export const getContractDetailAPi = async (storesDispatch, contractId, stateSetter) => {
  try {
    if (contractId !== "") {
      // console.log(process.env.REACT_APP_BRANCH_SERVER + "/board/" + postId + "/type/" + boardType);
      // contract/detail
      const res = await HttpInstance.get(
        process.env.REACT_APP_BRANCH_SERVER + "/contract/detail/" + contractId,
      );
      // console.log(res.data);
      if (!restEmpty(res.data.data)) {
        stateSetter(res.data.data);
      }
    }
  } catch (err) {
    console.log(err);
    simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
  }
};

// Counseling이 아니라 catalog list임. 나중에 명칭 모두 변경 필요
export const getCounselingTableAPi = async (storesDispatch, branchId, stateSetter) => {
  try {
    if (branchId !== "") {
      // console.log(process.env.REACT_APP_BRANCH_SERVER + "/board/" + postId + "/type/" + boardType);
      const res = await HttpInstance.get(
        process.env.REACT_APP_BRANCH_SERVER + "/" + branchId + "/counseling",
      );
      // console.log(res.data);
      if (!restEmpty(res.data.data)) {
        stateSetter(res.data.data);
      }
    }
  } catch (err) {
    console.log(err);
    simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
  }
};

export const getContractTableAPi = async (storesDispatch, branchId, stateSetter) => {
  try {
    if (branchId !== "") {
      // console.log(process.env.REACT_APP_BRANCH_SERVER + "/board/" + postId + "/type/" + boardType);
      const res = await HttpInstance.get(
        process.env.REACT_APP_BRANCH_SERVER + "/" + branchId + "/contract",
      );
      // console.log(res.data);
      if (!restEmpty(res.data.data)) {
        stateSetter(res.data.data);
      }
    }
  } catch (err) {
    console.log(err);
    simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
  }
};
