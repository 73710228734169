import {
  contentTypeJson,
  generateObjectZeroId,
  restEmpty,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import ModalBtnModeGroup from "components/ModalBtnModeGroup/ModalBtnModeGroup";
import TreeChecker from "components/TreeChecker/TreeChecker";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import SetPermissionStaff from "pages/CommonModal/Permission/SetPermissionStaff";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

const HQSetPermission = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const permissionInfoRef = useRef();
  const [branchTreeList, setBranchTreeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [srcPermission, setSrcPermission] = useState({ branch_id: "" });

  const [selectedBranch, setSelectedBranch] = useState({
    branch_id: generateObjectZeroId(),
    branch_name: "",
  });

  useEffect(() => {
    HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/hq/staff_tree", {
      params: { hq_id: modalParam.hq_id },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setBranchTreeList(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  }, []);

  // 로그인 유저의 권한을 가져옴
  const getPermission = async (branchId, branchName) => {
    await HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/hq/staff_permission_by_token", {
      params: {
        hq_id: modalParam.hq_id,
        // hq_name: modalParam.hq_name,
        branch_id: branchId,
        // branch_name: branchName,
      },
    })
      .then((res) => {
        if (res.data.is_admin) {
          permissionInfoRef.current.setSrcPermission({ is_admin: true });
        }
        if (!restEmpty(res.data.data)) {
          permissionInfoRef.current.setSrcPermission(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const onTreeSelect = async (nodeId) => {
    if (selectedBranch.branch_id !== branchTreeList[nodeId].branch_id) {
      setLoading(true);
    }

    await getPermission(branchTreeList[nodeId].branch_id, branchTreeList[nodeId].branch_name);
    setSelectedBranch(branchTreeList[nodeId]);
  };

  const onClickSave = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: <Label labelText="저장하시겠습니까?" />,
        onBtnYes: () => {
          setLoading(true);
          onSaveEvent();
        },
        onBtnNo: () => {},
      }),
    );
  };

  const onSaveEvent = async () => {
    const setPermissionData = permissionInfoRef.current.getPermission();
    setPermissionData.branch_info = selectedBranch;
    setPermissionData.hq_info.hq_id = modalParam.hq_id;
    setPermissionData.hq_info.hq_name = modalParam.hqBaseData.hq_info.hq_name;
    setPermissionData.staff_id = modalParam.staff_id;

    const formData = new FormData();

    formData.append("staff_permission", JSON.stringify(setPermissionData));

    await HttpInstance.post(
      process.env.REACT_APP_STAFF_SERVER + "/hq/staff_permission_update",
      formData,
      contentTypeJson,
    )
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          storesDispatch(
            globalAlertOn({
              show: true,
              titleText: "알림",
              bodyText: <Label labelText="저장되었습니다." />,
              onBtnOk: () => {},
            }),
          );
          setLoading(false);
          // refreshStore();
          // onModalDone(modalParam.refreshStore);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
        // onModalDone(modalParam.refreshStore);
      });
  };

  return (
    <div
      style={{
        width: "60rem",
      }}
    >
      {loading ? <Loading /> : <></>}

      <div style={{ display: "flex" }}>
        <TextField
          labelWidth={"5.438rem"}
          textBoxWidth={"13rem"}
          labelText="임직원명"
          defaultValue={modalParam.staff_name}
          disabled
        />
        <ModalBtnModeGroup mode={"add"} onClickEvent={onClickSave} />
      </div>

      <div style={{ display: "flex", marginTop: "1rem" }}>
        <div style={{ width: "35%" }}>
          <TreeChecker
            baseData={modalParam}
            treeData={branchTreeList}
            onTreeSelect={onTreeSelect}
          />
        </div>

        <div style={{ width: "65%" }}>
          <SetPermissionStaff
            ref={permissionInfoRef}
            setLoading={setLoading}
            modalParam={{
              srcPermission: srcPermission,
              targetParams: {
                branch_info: selectedBranch,
                staff_id: modalParam.staff_id,
                hq_info: {
                  hq_id: modalParam.hq_id,
                  hq_name: modalParam.hqBaseData.hq_info.hq_name,
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default HQSetPermission;
