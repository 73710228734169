///SETTING
export const accountBranchInfo = () => {
  return {
    type: "REGISTER/BASE_LIST_INFO",
  };
};

export const accountBranchInfoSuccess = (data) => {
  return {
    type: "REGISTER/BASE_LIST_INFO/SUCCESS",
    payload: data,
  };
};

export const accountBranchInfoFail = (data) => {
  return {
    type: "REGISTER/BASE_LIST_INFO/FAIL",
    payload: data,
  };
};

///SHOW
export const registerSettingInfo = (
  branch_id,
  is_only_prepay,
  is_only_merged,
  selected_item_id,
  remain_pt,
) => {
  return {
    type: "REGISTER/SETTINGS",
    branch_id: branch_id,
    // branch_name: branch_name,
    is_only_prepay: is_only_prepay,
    is_only_merged: is_only_merged,
    selected_item_id: selected_item_id,
    remain_pt: remain_pt,
  };
};

export const registerSettingInfoSuccess = (data) => {
  return {
    type: "REGISTER/SETTINGS/SUCCESS",
    payload: data,
  };
};

export const registerSettingInfoFail = (data) => {
  return {
    type: "REGISTER/SETTINGS/FAIL",
    payload: data,
  };
};
export const registerSettingInfoClear = (data) => {
  return {
    type: "REGISTER/SETTINGS/CLEAR",
    payload: null,
  };
};
