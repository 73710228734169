import { globalModalOff } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Popup from "./Popup";
// const ModalPopup = forwardRef((props, ref) => {
const ModalPopup = ({ params }) => {
  const initialState = {
    show: false,
    title: "",
    Content: "",
    callback: null,
    onModalDone: undefined,
  };
  const [paramState, setParamState] = useState(initialState);

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(globalModalOff());
  };

  useEffect(() => {
    if (params !== undefined) {
      setParamState(params);
    }
    return () => {
      setParamState(initialState);
    };
  }, [params]);

  return (
    <>
      {/* {params.Button} */}
      <Popup
        isOpen={paramState.show}
        onClose={() => {
          handleClose();
        }}
        // title={paramState.title}
        // Content={paramState.Content}
        // Buttons={paramState.Buttons === undefined ? undefined : onBtnOkCallback}
        {...paramState}
      />
    </>
  );
};

export default ModalPopup;
