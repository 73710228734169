import React from "react";
import AgesSalesCntTable from "./AgesSalesCntTable";
import GenderSalesCntTable from "./GenderSalesCntTable";
import PeriodSalesCntTable from "./PeriodSalesCntTable";
import TimeSalesCntTable from "./TimeSalesCntTable";

const StatGxArea = ({ reportState }) => {
  // console.log(reportState);
  return (
    <div style={{}}>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <div style={{ width: "50%", paddingRight: "1rem" }}>
          <PeriodSalesCntTable reportState={reportState} />
        </div>
        <div style={{ width: "50%", paddingLeft: "1rem" }}>
          <TimeSalesCntTable reportState={reportState} />
        </div>
      </div>

      <div style={{ display: "flex", marginTop: "1rem" }}>
        <div style={{ width: "50%", paddingRight: "1rem" }}>
          <AgesSalesCntTable reportState={reportState} />
        </div>
        <div style={{ width: "50%", paddingLeft: "1rem" }}>
          <GenderSalesCntTable reportState={reportState} />
        </div>
      </div>
    </div>
  );
};
export default StatGxArea;
