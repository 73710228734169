import { simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import LockerSelectModal from "pages/ERP/LockerService/Modal/LockerSelectModal";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import ExistLockerSelectModal from "./ExistLockerSelectModal";
const LockerSelectOptionModal = ({ modalParam, onModalDone }) => {
  //onModalDone : 모달 다 닫고  + 회원정보 전체 갱신

  const storesDispatch = useDispatch();
  const viewModelRef = useRef();
  // const lockerNumRef = useRef([]);
  // const lockerInfoRef = useRef([]);

  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });
  const [existLockerList, setExistLockerList] = useState([]);

  useEffect(() => {
    if (modalParam) {
      // const lockerArr = modalParam.firstMbshpLockerList.filter(
      //   (el) => el.locker_info.locker_no !== 0,
      // );
      // const lockerNumArr = lockerArr.map((locker) => locker.locker_info.locker_no);

      // lockerInfoRef.current = lockerArr;
      // lockerNumRef.current = lockerNumArr;

      if (modalParam.member_info.member_id !== "000000000000000000000000") {
        HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member/register/existing_locker", {
          params: {
            member_id: modalParam.member_info.member_id,
            mbshp_id: modalParam.member_info.membership_id,
          },
        })
          .then((res) => {
            if (res.data.data.length > 0) {
              setExistLockerList(res.data.data);
            }
          })
          .catch((err) => {
            console.log(err);
            simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
          });
      }
    }
  }, [modalParam]);

  const popExistLockerSelectModal = () => {
    const lockerArr = existLockerList.filter((el) => el.locker_no !== 0);
    const lockerNumArr = lockerArr.map((locker) => locker.locker_no);

    const param = {
      // lockerNumArr: lockerNumRef.current,
      // lockerArr: lockerInfoRef.current,
      lockerNumArr: lockerNumArr,
      lockerArr: lockerArr,
      member_info: modalParam.member_info,
      mbshp_locker_id: modalParam.mbshp_locker_id,
    };
    setModalInfo({
      show: true,
      title: "기존 락커선택",
      Content: <ExistLockerSelectModal modalParam={param} onModalDone={onModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const validationCheckExistSelect = () => {
    // if (lockerNumRef.current.length === 0) {
    if (existLockerList.length === 0) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="기존 배정된 락커가 없습니다." />,
          onBtnOk: () => {},
        }),
      );
    } else {
      //기존선택 팝업
      popExistLockerSelectModal();
    }
  };

  const popNewSelectLockerModal = () => {
    const param = {
      mode: "add",
      member_info: modalParam.member_info,
      branch_id: modalParam.branch_id,
      locker_total_idx: modalParam.locker_total_idx,
      mbshp_locker_id: modalParam.mbshp_locker_id,
    };
    setModalInfo({
      show: true,
      title: "락커 배정",
      Content: <LockerSelectModal modalParam={param} onModalDone={onModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };
  return (
    <div>
      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
      <div style={{ display: "flex", gap: "0.3rem" }}>
        <NormalBtn theme="white" name="기존선택" onClick={validationCheckExistSelect} />
        <NormalBtn theme="white" name="새로배정" onClick={popNewSelectLockerModal} />
      </div>
    </div>
  );
};

export default LockerSelectOptionModal;
