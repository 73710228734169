export const newPackage = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/erp_set/package_insert";
};

export const updatePackage = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/erp_set/package_update";
};

export const newHQ = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/erp_set/hq_insert";
};

export const updateHQ = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/erp_set/hq_update";
};

export const updatePolicy = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/erp_set/policy_update";
};

export const addCoupon = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/erp_set/coupon/add";
};

export const updateCoupon = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/erp_set/coupon/update";
};

export const deleteCoupon = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/erp_set/coupon/delete";
};

export const addFirewall = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/erp_set/ip_whitelist";
};

export const updateFirewall = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/erp_set/ip_whitelist";
};

const post_api = {
  newPackage,
  updatePackage,
  newHQ,
  updateHQ,
  updatePolicy,
  addCoupon,
  updateCoupon,
  deleteCoupon,
  addFirewall,
  updateFirewall,
};

export default post_api;
