import { HttpInstance } from "lib/HttpLib";

export const getViewInfo = (param) => {
  return HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member/assign_view", {
    params: {
      src_member_id: param.src_member_id === null ? "" : param.src_member_id,
      dst_member_id: param.dst_member_id === null ? "" : param.dst_member_id,
    },
  });
};

const get_api = {
  getViewInfo,
};

export default get_api;
