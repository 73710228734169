import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import FileUpload from "components/LabelInput/FileUpload";
import Label from "components/LabelInput/Label";
import { globalAlertOn } from "modules/actions/Alert";
import { useState } from "react";
import { useDispatch } from "react-redux";

const JoinContractPopup = ({ params, onModalDone }) => {
  const btnStyleClass = btnStyles();
  const [file, setFile] = useState();
  const storesDispatch = useDispatch();

  const onButtonClickYes = () => {
    if (params.privileged && !file) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="근로계약서를 첨부해 주십시오." />,
          onBtnOk: () => {},
        }),
      );
    } else {
      if (params.mode === "전환") {
        onModalDone("전환", file);
      } else if (params.mode === "기안") {
        onModalDone("기안", file);
      } else if (params.mode === "재입사") {
        onModalDone("재입사", file);
      }
    }
  };

  const onButtonClickNo = () => {
    onModalDone("취소");
  };

  const getLabelText = () => {
    if (params.mode === "전환") {
      return params.privileged
        ? "근로정보 전환 처리하시겠습니까?"
        : "근로정보 전환을 기안하시겠습니까?";
    } else if (params.mode === "기안") {
      return params.privileged ? "직원을 등록하시겠습니까?" : "직원 등록을 기안하시겠습니까?";
    } else if (params.mode === "재입사") {
      return params.privileged ? "재입사 처리하시겠습니까?" : "재입사 기안하시겠습니까?";
    }
  };

  return (
    <div style={{ width: "21rem" }}>
      <Label labelText={getLabelText()} justifyContent="center" />
      {params.privileged ? (
        <div
          style={{
            display: "flex",
            marginTop: "1.5rem",
          }}
        >
          <FileUpload
            labelWidth={"5rem"}
            textBoxWidth={"11rem"}
            textMarginRight={"0rem"}
            labelText="근로계약서"
            onUploadFile={(file) => {
              setFile(file);
              params.workInfoStore.files.emp_contract_file.file_name = file.name;
              params.workInfoStore.files.emp_contract_file.file_type = file.type;
            }}
            hideDownload
          />
        </div>
      ) : (
        <></>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1.5rem",
        }}
      >
        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={() => onButtonClickYes()}
          style={{ marginLeft: "0.5rem" }}
        >
          예
        </Button>
        <Button
          className={clsx({
            [btnStyleClass.btnRootWhite]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={() => onButtonClickNo()}
          style={{ marginLeft: "0.5rem" }}
        >
          아니오
        </Button>
      </div>
    </div>
  );
};
export default JoinContractPopup;
