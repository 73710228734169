import { Button, Collapse, Drawer, List, ListItem } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
// import { ExpandLess, ExpandMore } from "@material-ui/icons";
// import menuItems from "./sideBarItems";
// import CustomRouterLink from "components/CommonLib/CustomRouterLink";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import menuBarStyles from "./menuBarStyles";
import { useSideBarStore } from "./modules/store/sideBarState";
import { useResetSearchParams } from "./useResetSearchParams";

const Sidebar = ({ permissionState }) => {
  const location = useLocation();
  // 전역 상태 관리
  const { resetSearchParams } = useResetSearchParams();
  const {
    sideMenuStatus,
    setSideMenuStatus,
    sideMenuDoubleDepthOpen: doubleDepthOpen,
    setSideMenuDoubleDepthOpen: setDoubleDepthOpen,
    sideMultiMenuOpen,
    setSideMultiMenuOpen,
  } = useSideBarStore();

  const classes = menuBarStyles();

  const [permissionMenu, setPermissionMenu] = useState(permissionState.side_menu);

  useEffect(() => {
    setPermissionMenu(permissionState.side_menu);
  }, [permissionState]);

  // url 변경에 따른 메뉴 상태 변경
  useEffect(() => {
    setSideMenuStatus(location.pathname);
  }, [location.pathname]);

  const SidebarSingleMenu = ({ menuState, level }) => {
    const classes = menuBarStyles({
      isActive: sideMenuStatus[menuState.menu_title],
    });

    if (menuState && menuState.menu_active) {
      return (
        <div>
          <List component="div" disablePadding key={menuState.menu_title}>
            <ListItem
              className={classes.item}
              disableGutters
              style={{ padding: "0px" }}
              key={menuState.menu_title}
            >
              <Button
                className={clsx({
                  [classes.btnRoot]: level === 2 ? false : true,
                  [classes.button]: level === 2 ? false : true,
                  [classes.subMenu]: level,
                  [classes.buttonChild]: level === 2 ? true : false,
                })}
                component={RouterLink}
                onClick={() => {
                  resetSearchParams(menuState);
                }}
                to={menuState.menu_url}
                style={{ justifyContent: "left", width: "100%" }}
              >
                {menuState.menu_title}
              </Button>
            </ListItem>
          </List>
        </div>
      );
    } else {
      return "";
    }
  };

  const SidebarMultiMenu = ({ menuState }) => {
    const classes = menuBarStyles({
      isActive: sideMenuStatus[menuState.menu_title],
      isSelected: sideMultiMenuOpen[menuState.menu_title],
    });

    if (menuState && menuState.menu_active) {
      return (
        <div>
          <ListItem className={classes.item} disableGutters key={menuState.menu_title}>
            <Button
              className={clsx({
                [classes.btnRoot]: true,
                [classes.button]: true,
                [classes.subMenu]: 0,
                [classes.buttonActive]: menuState.menu_active === true ? true : false,
              })}
              onClick={() => {
                setSideMultiMenuOpen(menuState.menu_title);
              }}
            >
              {menuState.menu_title}
            </Button>
          </ListItem>
          <div style={{ backgroundColor: "#f5f5f5" }}>
            <Collapse in={sideMultiMenuOpen[menuState.menu_title]} timeout="auto" unmountOnExit>
              {menuState.children.map((item, idx) => {
                if (item.children && item.children.length !== 0) {
                  return <SidebarDoubleDepth key={idx} menuState={item} />;
                } else {
                  return <SidebarSingleMenu key={idx} menuState={item} level={2} />;
                }
              })}
            </Collapse>
          </div>
        </div>
      );
    } else {
      return "";
    }
  };

  const SidebarDoubleDepth = ({ menuState, level }) => {
    const classes = menuBarStyles({
      isSelected: doubleDepthOpen[menuState.menu_title],
    });

    if (menuState && menuState.menu_active) {
      return (
        <div>
          <List component="div" disablePadding key={menuState.menu_title}>
            <ListItem
              className={classes.item}
              disableGutters
              style={{ padding: "0px" }}
              key={menuState.menu_title}
            >
              <Button
                className={clsx({
                  [classes.btnRoot]: false,
                  [classes.button]: false,
                  [classes.subMenu]: 2,
                  [classes.buttonChild]: true,
                  [classes.buttonDoubleDepth]: true,
                })}
                onClick={() => setDoubleDepthOpen(menuState.menu_title)}
              >
                {/* {"- "} */}
                {menuState.menu_title}
              </Button>
            </ListItem>
            <div>
              <Collapse in={doubleDepthOpen[menuState.menu_title]} timeout="auto" unmountOnExit>
                {menuState.children.map((el, idx) => {
                  const classes = menuBarStyles({
                    isActive: sideMenuStatus[el.menu_title],
                  });
                  return (
                    <div
                      key={idx}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <List component="div" disablePadding key={el.menu_title}>
                        <ListItem
                          className={classes.item}
                          disableGutters
                          style={{ padding: "0px", width: "11.25rem" }}
                          key={el.menu_title}
                        >
                          <Button
                            component={RouterLink}
                            onClick={() => {
                              resetSearchParams(el);
                            }}
                            to={el.menu_url}
                            className={clsx({
                              [classes.buttonDoubleDepthChild]: true,
                            })}
                          >
                            {`- ${el.menu_title}`}
                          </Button>
                        </ListItem>
                      </List>
                    </div>
                  );
                })}
              </Collapse>
            </div>
          </List>
        </div>
      );
    } else {
      return "";
    }
  };

  const handleMenu = () => {
    return (
      <div>
        <SidebarMultiMenu menuState={permissionMenu.board_menu} />
        <SidebarSingleMenu menuState={permissionMenu.schedule_menu} />
        <SidebarMultiMenu menuState={permissionMenu.class_menu} />
        <SidebarSingleMenu menuState={permissionMenu.locker_menu} />
        <SidebarMultiMenu menuState={permissionMenu.data_menu} />
        <SidebarMultiMenu menuState={permissionMenu.branch_menu} />
        <SidebarSingleMenu menuState={permissionMenu.approval_menu} />
        <SidebarMultiMenu menuState={permissionMenu.cs_menu} />
        <SidebarMultiMenu menuState={permissionMenu.access_menu} />
      </div>
    );
  };
  return (
    <React.Fragment>
      <Drawer anchor="left" classes={{ paper: classes.drawer }} open={true} variant="persistent">
        <List className={clsx(classes.root)}>{handleMenu()}</List>
      </Drawer>
    </React.Fragment>
  );
};

export default Sidebar;
