export const insertNewUser = () => {
  return process.env.REACT_APP_USER_SERVER + "/member/new";
};

export const insertAddPayInfo = () => {
  return process.env.REACT_APP_USER_SERVER + "/member/addpay";
};

const post_api = {
  insertNewUser,
  insertAddPayInfo,
};

export default post_api;
