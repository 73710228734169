import TextArea from "components/LabelInput/TextArea";
import TextField from "components/LabelInput/TextField";

const MsgPreviewModal = ({ modalParam, onModalDone }) => {
  const labelWidth = "5rem";

  return (
    <div style={{ width: "25rem" }}>
      <div style={{ marginTop: "0.5rem" }}>
        <TextField
          labelText="제목"
          labelWidth={labelWidth}
          labelMarginRight="0rem"
          textMarginRight="0rem"
          fullWidth
          disabled
          defaultValue={
            // 문자 발송과 발송이력의 데이터 구조 속성이 달라서 우선 분기처리
            modalParam.data && modalParam.data.template_info
              ? modalParam.data.template_info.sms_info.sms_name
              : modalParam.data.sms_info.sms_name
          }
        />
      </div>

      {/* Lane 3 */}
      <div style={{ marginTop: "0.5rem" }}>
        <TextField
          labelText="머릿말"
          labelWidth={labelWidth}
          labelMarginRight="0rem"
          textMarginRight="0rem"
          fullWidth
          disabled
          defaultValue={
            modalParam.data && modalParam.data.template_info
              ? modalParam.data.template_info.head_line
              : modalParam.data.head_line
          }
        />
      </div>

      {/* Lane 4 */}
      <div style={{ marginTop: "0.5rem" }}>
        <TextArea
          labelText="내용"
          labelWidth={labelWidth}
          marginTop={"0.5rem"}
          labelMarginRight="0rem"
          fullWidth
          minRows={16}
          maxRows={16}
          disabled
          defaultValue={
            modalParam.data && modalParam.data.template_info
              ? modalParam.data.template_info.contents
              : modalParam.data.contents
          }
        />
      </div>
    </div>
  );
};
export default MsgPreviewModal;
