import { Button } from "@mui/material";

const FlatBtn = (props) => {
  const flatStyle = {
    fontfamily: "Pretendard",
    fontWeight: "600",
    fontsize: "0.82rem",
  };

  return (
    <Button
      disableRipple
      style={flatStyle}
      sx={{
        color: props.disabled ? "#DEDEDE" : props.color ? props.color : "#999999",
        "&:hover": { backgroundColor: "transparent", color: "#0C38A8" },
      }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.name}
    </Button>
  );
};

export default FlatBtn;
