import { generateObjectZeroId } from "components/CommonLib/CommonLib";

export const searchMemberParamInit = {
  member_type: "",
  member_info: {
    member_id: generateObjectZeroId(),
    member_name: "",
    member_no: "",
  },
  branch_info: {
    branch_id: generateObjectZeroId(),
    branch_name: "",
  },
  hq_info: {
    hq_id: generateObjectZeroId(),
    hq_name: "",
  },
  base_info: {
    phone: "",
    gender: "",
    agree_personal: false,
    agree_marketing: false,
    birth: "",
  },
  active_info: {
    active_membership: {
      is_use: false,
      from_date: "",
      to_date: "",
    },
  },
  branch_pt_detail: {
    remain_session_cnt: 0,
    pt_status: "",
  },
  access: {
    access_method: "",
    card_no: "",
  },
};
