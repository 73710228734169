import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { getNowServiceState } from "pages/NoticeBoard/model/board_row";
import ReferenceCreateWrapper from "./ReferenceCreateWrapper";

const ReferenceCreateService = () => {
  const nowService = "자료실";
  return (
    <div>
      <PageFrame
        children={
          <div>
            <PageTitle titleText={nowService} />
            <ReferenceCreateWrapper nowService={getNowServiceState(nowService)} />
          </div>
        }
      />
    </div>
  );
};

export default ReferenceCreateService;
