import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import MonitoringManage from "./MonitoringManageWrapper";

const MonitoringManageService = () => {
  return (
    <PageFrame
      children={
        <div>
          <PageTitle titleText="출입문 모니터링" />
          <MonitoringManage />
        </div>
      }
    />
  );
};
export default MonitoringManageService;
