import { useState } from "react";

import { btnStyles } from "components/ButtonStyle/ButtonStyle";

import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import {
  contentTypeJson,
  dataEmpty,
  makeNextDay,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useDispatch } from "react-redux";

const ChangePaperDueModal = ({ modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const [newDueDate, setNewDueDate] = useState("");
  const [loading, setLoading] = useState(false);

  const changePaperDueDate = async () => {
    try {
      const formData = new FormData();
      const info = {
        before_due_date: modalParam.file_due_date,
        cur_due_date: newDueDate,
        member_id: modalParam.member_id,
        membership_id: modalParam.membership_id,
      };
      formData.append("new_info", JSON.stringify(info));

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_USER_SERVER + "/change/long_postpone/due_date",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label labelText="변경 되었습니다." />
              </>
            ),
            onBtnOk: () => {
              modalParam.getPaperDueHist();
              onModalDone(); //refreshStore+ 모달 닫힘
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const onSaveEvt = () => {
    if (!newDueDate) {
      simpleAlert(storesDispatch, "알림", "변경 제출 기한을 선택해주세요.");
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="서류 제출기한을 변경 하시겠습니까?" />
            </>
          ),
          // width: "50rem",
          onBtnYes: () => {
            //변경 요청
            changePaperDueDate();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const dueHistTableList = new dueHistTable();

  return (
    <div
      style={{
        width: "42rem",
      }}
    >
      {loading && <Loading />}

      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="기한 변경 이력" />
      </div>
      <div style={{ margin: "0.3rem 0 1rem 0 " }}>
        <CustomTable
          columns_head={dueHistTableList.columns_head}
          table_title={dueHistTableList.table_title}
          rest_call={dueHistTableList.get_data_from_rest}
          row_render={dueHistTableList.create_table}
          rest_data={modalParam.paperDueList}
        />
      </div>

      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="기한 변경" />
      </div>
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <DatePicker
          labelWidth={"6rem"}
          minWidth={"12.5rem"}
          labelText="현재 제출 기한"
          disabled
          defaultValue={modalParam.file_due_date}
        />
        <DatePicker
          labelWidth={"6rem"}
          minWidth={"12.5rem"}
          textMarginRight="0rem"
          labelText="변경 제출 기한"
          minDate={modalParam.file_due_date && makeNextDay(modalParam.file_due_date)}
          onBlur={(e) => {
            setNewDueDate(e);
          }}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem", gap: "0.3rem" }}>
        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={onSaveEvt}
        >
          변경
        </Button>
      </div>
    </div>
  );
};
export default ChangePaperDueModal;

class dueHistTable {
  table_title = "";

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.before_due_date}</CustomTableContents>
        <CustomTableContents>{data.cur_due_date}</CustomTableContents>
        <CustomTableContentsEND align="center">{data.reg_info.reg_name}</CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "등록 일시", width: "25%" },
    { title: "기존 제출 기한", width: "25%" },
    { title: "변경 제출 기한", width: "25%" },
    { title: "담당자", width: "25%" },
  ];
  get_data_from_rest = (stateData) => {
    // console.log(stateData);
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
