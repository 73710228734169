import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { tabBorder, tabStyle } from "components/CommonComp";
import TabPanel from "components/TabControl/TabPanel";
import React from "react";
import SwipeableViews from "react-swipeable-views";
import CurrentWorkInfoView from "./CurrentWorkInfoView";
import PersonalInfoView from "./PersonalInfoView";

const PersonnelWorkReqForm = ({ mode, contentState }) => {
  const [tabIndex, setTabIndex] = React.useState(0);

  const readOnly = mode === "approver" || mode === "unspent" || mode === "view";

  const onChangeTabIndex = (e, index) => {
    setTabIndex(index);
  };

  console.log(contentState);

  const histParams = {
    pageMode: "보기",
    staff_info_state: contentState.staff_object, // temp
    branch_id: contentState.work_info_obj.base_info.branch_info.branch_id,
  };

  return (
    <div style={{ marginTop: "1rem", marginBottom: "2rem", width: "100%" }}>
      <Box sx={{ bgcolor: "background.paper" }}>
        <Tabs
          value={tabIndex}
          onChange={onChangeTabIndex}
          sx={{ minHeight: "40px" }}
          textColor="inherit"
          TabIndicatorProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0)" } }}
        >
          <Tab
            sx={{ minHeight: "40px" }}
            label="직원 기본정보"
            style={tabStyle(0, tabIndex, "9rem")}
          />
          <Tab sx={{ minHeight: "40px" }} label="근로정보" style={tabStyle(1, tabIndex)} />
          {/* <Tab sx={{ minHeight: "40px" }} label="변경 이력" style={tabStyle(2, tabIndex)} /> */}
        </Tabs>
      </Box>
      <SwipeableViews
        index={tabIndex}
        onChangeIndex={onChangeTabIndex}
        style={tabBorder}
        animateTransitions={false}
      >
        <TabPanel value={tabIndex} index={0}>
          <div style={{ width: "100%", display: "flex" }}>
            <PersonalInfoView
              // mode="approval"
              staffInfoState={contentState.staff_object}
              // onModalDone={() => {}}
            />
          </div>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <CurrentWorkInfoView
            workInfoObj={contentState.work_info_obj}
            logType={contentState.doc_info.doc_name}
          />
        </TabPanel>
        {/* <TabPanel value={tabIndex} index={2}>
          <WorkInfoChangeHist modalParam={histParams} />
        </TabPanel> */}
      </SwipeableViews>
    </div>
  );
};
export default PersonnelWorkReqForm;
