import { create } from "zustand";

// 검색 조건 베이스
const baseSearchParams = {
  base_from_date: "",
  base_to_date: "",
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  combo_selector: {
    branch_list: [[""]],
    hq_list: [""],
    target_list: [
      {
        branch_list: [
          {
            branch_id: "",
            branch_name: "",
          },
        ],
        hq_info: {
          hq_id: "",
          hq_name: "",
        },
      },
    ],
  },
  hq_info: {
    hq_id: "",
    hq_name: "",
  },
};

export const useCashBookStore = create((set) => ({
  // ========== State ===========
  // 수입/지출 내역(월별) 검색 내역 State
  cashBookSearchParams: {
    ...baseSearchParams,
  },

  cashBookHqIdx: 0,

  cashBookPage: 1,

  // 집계 내역, 수입 내역 관리, 지출 내역 관리 Loading State
  cashBookLoading: false,

  // ========== Action ==========
  // 수입/지출 내역(월별) 검색 조건 변경
  setSearchParams: (key, value) => {
    set((state) => ({
      cashBookSearchParams: {
        ...state.cashBookSearchParams,
        [key]: value,
      },
    }));
  },
  // Hq Index 값 변경
  setHqIdx: (value) => {
    set({ cashBookHqIdx: value });
  },

  // Page 값 변경
  setPage: (value) => {
    set({ cashBookPage: value });
  },

  // 검색 조건 리셋
  resetSearchParams: () => {
    set({
      cashBookSearchParams: {
        ...baseSearchParams,
      },
    });
    set({ cashBookHqIdx: 0 });
  },

  // Loading state 값 변경
  setCashBookLoading: (bool) => {
    set({ cashBookLoading: bool });
  },
}));
