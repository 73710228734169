import { create } from "zustand";

// 검색 조건 베이스
const baseSearchParams = {
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  extend_days_from: 0,
  extend_days_to: 0,
  extend_type: "",
  hq_info: {
    hq_id: "",
    hq_name: "",
  },
  member_name: "",
  member_no: "",
  reg_date_from: "",
  reg_date_to: "",
  staff_name: "",
};

export const useExtendMbshpHistoryStore = create((set) => ({
  // ========== State ===========
  // 기간 추가 회원 검색 State
  extendHistorySearchParams: {
    ...baseSearchParams,
  },

  extendHistoryHqIdx: 0,

  extendHistoryPage: 1,

  // ========== Action ==========
  // "회원권 기간 추가" 회원 검색 조건 변경
  setSearchParams: (key, value) => {
    set((state) => ({
      extendHistorySearchParams: {
        ...state.extendHistorySearchParams,
        [key]: value,
      },
    }));
  },
  // Hq Index 값 변경
  setHqIdx: (value) => {
    set({ extendHistoryHqIdx: value });
  },

  // Page 값 변경
  setPage: (value) => {
    set({ extendHistoryPage: value });
  },

  // 검색 조건 리셋
  resetSearchParams: () => {
    set({
      extendHistorySearchParams: {
        ...baseSearchParams,
      },
    });
    set({ extendHistoryHqIdx: 0 });
  },
}));
