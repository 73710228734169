import { Button, Collapse } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import Label from "components/LabelInput/Label";
import { useResetSearchParams } from "hooks/common/useResetSearchParams";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import SearchAssignMember from "pages/CommonModal/Search/Assign/SearchAssignMember";
import SearchMember from "pages/CommonModal/Search/SearchMember";
import SearchSubject from "pages/ERP/MemberService/AssignChangeBranch/Modals/SearchSubject";
import { forwardRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink as RouterLink, useLocation, useNavigate } from "react-router-dom";
import mainIcon from "../../../src/assets/main_icon.png";
import "./Navbar.css";
import { parseMenuIndex } from "./NavbarFuncs";
import menuBarStyles from "./menuBarStyles";

const navStyle = {
  height: "3.75rem",
  backgroundColor: "#10134F",
  display: "inline-block",
  width: "100%",
  zIndex: 20,
  position: "fixed",
  left: 0,
  top: 0,
};

const Navbar = ({ menuActive, permissionState }) => {
  const storesDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const menuActiveIndex = parseMenuIndex(menuActive);
  const classes = menuBarStyles();
  const topBtnWidth = "8rem";
  // 전역 상태 관리
  const { initAllSearchParams } = useResetSearchParams();

  const [permissionMenu, setPermissionMenu] = useState(permissionState.nav_menu);
  const [welcomeText, setWelcomeText] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [location]);

  useEffect(() => {
    if (permissionState.staff_name) {
      setWelcomeText(
        permissionState.staff_name + "(" + permissionState.auth_id + ") 님 환영합니다.",
      );
    } else {
      setWelcomeText("환영합니다.");
    }
    setPermissionMenu(permissionState.nav_menu);
  }, [permissionState]);

  const goToMemberView = (retData) => {
    storesDispatch(globalModalOff());
    navigate("/member_info/" + retData.member_info.member_id);
  };

  const openSearchMemberModal = () => {
    const param = {
      goToMemberView: goToMemberView,
      mode: "search",
    };
    storesDispatch(
      globalModalOn({
        show: true,
        title: "회원검색",

        Content: <SearchMember modalParam={param} onModalDone={() => {}} />,
      }),
    );
  };

  const popAssignBranchChangeModal = () => {
    const param = {};
    storesDispatch(
      globalModalOn({
        show: true,
        title: "양도/지점변경",
        Content: <SearchAssignMember modalParam={param} onModalDone={onAssignModalDone} />,
      }),
    );
  };

  //1. 양도/지변 검색 모달 DONE
  const onAssignModalDone = (mode, data) => {
    if (mode === "change_branch") {
      //지점변경
      storesDispatch(globalModalOff());
      navigate("/change_branch", { state: data });
    } else {
      //양도
      const param = {
        assigner: data,
      };
      storesDispatch(globalModalOff());
      storesDispatch(
        globalModalOn({
          show: true,
          title: "양수자 검색",
          Content: <SearchSubject modalParam={param} onModalDone={onAssigneeModalDone} />,
        }),
      );
    }
  };
  //2. 양수검색 모달 DONE
  const onAssigneeModalDone = (data) => {
    //다음 버튼 -> 양도 페이지로 이동
    //양수자 신규등록 -> 등록 -> 양도페이지 이동
    storesDispatch(globalModalOff());
    navigate("/assign", { state: data });
  };

  const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
      <RouterLink {...props} />
    </div>
  ));

  const onClickSettings = () => {
    setOpen(!open);
  };

  //로그아웃 - 쿠키제거
  const remove_cookie = () => {
    const cookies = document.cookie.split("; ");
    const expiration = "Sat, 01 Jan 1972 00:00:00 GMT";
    for (let i = 0; i < cookies.length; i++) {
      document.cookie = cookies[i].split("=")[0] + "=; expires=" + expiration;
    }
    // 모든 저장된 검색 조건 초기화
    initAllSearchParams();
    navigate("/login");
  };

  const onClickLogout = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: <Label labelText="로그아웃 하시겠습니까?" />,
        // width: "50rem",
        onBtnYes: () => {
          remove_cookie();
        },
        onBtnNo: () => {},
      }),
    );
  };

  return (
    <nav style={navStyle}>
      <div className="nav-contents">
        <div>
          <Button
            className={clsx({
              [classes.noPadding]: true,
            })}
            onClick={() => {
              navigate("/");
            }}
          >
            <img className="nav-logo" alt="iPhone_01" src={mainIcon} style={{}} />
          </Button>
        </div>
        {/* 로고 */}

        <div className="nav-button-group">
          {permissionMenu.assign_menu.menu_active ? (
            <Button
              className={clsx({
                [classes.btnRoot]: true,
                [classes.topNavButton]: true,
                [classes.buttonActive]: false,
              })}
              onClick={() => popAssignBranchChangeModal()}
              style={{ width: topBtnWidth }}
            >
              {/* 양도/지점변경 */}
              {permissionMenu.assign_menu.menu_title}
            </Button>
          ) : (
            ""
          )}
          {permissionMenu.register_menu.menu_active ? (
            <Button
              className={clsx({
                [classes.btnRoot]: true,
                [classes.topNavButton]: true,
                [classes.buttonActive]: menuActiveIndex.register_active,
              })}
              component={CustomRouterLink}
              to={permissionMenu.register_menu.menu_url}
              style={{ width: topBtnWidth }}
            >
              {/* 신규등록 */}
              {permissionMenu.register_menu.menu_title}
            </Button>
          ) : (
            ""
          )}
          {permissionMenu.search_menu.menu_active ? (
            <Button
              className={clsx({
                [classes.btnRoot]: true,
                [classes.topNavButton]: true,
                [classes.buttonActive]: false,
              })}
              onClick={() => openSearchMemberModal()}
              style={{ width: topBtnWidth }}
            >
              {/* 고객/회원검색 */}
              {permissionMenu.search_menu.menu_title}
            </Button>
          ) : (
            ""
          )}

          {permissionMenu.setting_menu.menu_active ? (
            <div key={permissionMenu.setting_menu.menu_title}>
              {/* 설정 메뉴 */}
              <Button
                className={clsx({
                  [classes.btnRoot]: true,
                  [classes.topNavButton]: true,
                  [classes.buttonActive]: false,
                })}
                onClick={onClickSettings}
                style={{ width: topBtnWidth }}
              >
                {permissionMenu.setting_menu.menu_title}
                {open ? (
                  <ArrowDropUp sx={{ paddingLeft: "0.25rem" }} />
                ) : (
                  <ArrowDropDown sx={{ paddingLeft: "0.25rem" }} />
                )}
              </Button>
              <Collapse in={open} timeout={100} unmountOnExit>
                {permissionMenu.setting_menu.children.map((item, idx) => (
                  <div key={idx}>
                    {item.menu_active ? (
                      <Button
                        className={clsx({
                          [classes.btnRoot]: true,
                          [classes.button]: true,
                          [classes.topButtonList]: true,
                          [classes.buttonActive]: false,
                        })}
                        component={CustomRouterLink}
                        to={item.menu_url}
                        style={{ borderRadius: "0" }}
                      >
                        {item.menu_title}
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
              </Collapse>
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          style={{ display: "flex", alignItems: "center", marginLeft: "auto", marginRight: "2rem" }}
        >
          <Label
            labelText={welcomeText}
            color="white"
            marginRight="1rem"
            minWidth="fit-content"
            whiteSpace="nowrap"
          />
          <IconButton type="logout" onClick={onClickLogout} />
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
