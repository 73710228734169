import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import SearchStaffInBranch from "pages/CommonModal/Search/SearchStaffInBranch";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import ChangeTeamModal from "./ChangeTeamModal";

const EditTeamModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();
  const [checkState, setCheckState] = useState([]);
  const viewModelRef = useRef();

  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });
  const [loading, setLoading] = useState(false);
  const [teamMemberList, setTeamMemberList] = useState([]);

  const getTeamMemberList = async () => {
    try {
      setLoading(true);
      const res = await HttpInstance.get(
        process.env.REACT_APP_STAFF_SERVER + "/pt_team/detail/info",
        {
          params: {
            team_id: modalParam.data.team_info.team_id,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        const result = res.data.data;
        const teamList = result.team_member_list.map((el) => el.staff_info);
        setTeamMemberList(teamList);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTeamMemberList();
  }, [modalParam]);

  //팀원리스트 갱신 + 메인팀리스트 갱신
  const refreshView = () => {
    getTeamMemberList();
    onModalDone(modalParam.branch_id);
  };

  //모달 닫기 + 팀원리스트 갱신 + 메인팀리스트 갱신
  const allRefreshViewModalDone = () => {
    getTeamMemberList(); //팀원 목록 다시 GET
    onModalDone(modalParam.branch_id); //메인팀리스트 갱신
    viewModelRef.current.setClose();
  };

  const popChangeTeamModal = (title, param, onModalDone) => {
    setModalInfo({
      show: true,
      title: title,
      Content: <ChangeTeamModal modalParam={param} onModalDone={onModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const popAddTeamMemberModal = (title, param, onModalDone) => {
    setModalInfo({
      show: true,
      title: title,
      Content: <SearchStaffInBranch modalParam={param} onModalDone={onModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const onAddTeamMemberDone = (data) => {
    const staffInfo = {
      staff_id: data.staff_id,
      staff_name: data.personal_info.staff_name,
      staff_pos: data.base_info.position.org_name,
      staff_rank: data.base_info.rank.org_name,
    };

    addMember(staffInfo);
  };

  const addMember = async (staffInfo) => {
    try {
      const formData = new FormData();
      formData.append("team_id", modalParam.data.team_info.team_id);
      formData.append("new_info", JSON.stringify(staffInfo));
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_STAFF_SERVER + "/pt_team/member/add",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        allRefreshViewModalDone();
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const deleteMemeber = async (data) => {
    try {
      setLoading(true);
      const res = await HttpInstance.get(
        process.env.REACT_APP_STAFF_SERVER + "/pt_team/member/remove",
        {
          params: {
            team_id: modalParam.data.team_info.team_id,
            staff_id: data.staff_id,
          },
        },
      );
      if (!res.data.data) {
        refreshView();
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  const deleteRow = (data) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText={`${data.staff_name} 를 삭제하시겠습니까?`} />
          </>
        ),

        onBtnYes: () => {
          deleteMemeber(data);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const teamListData = new teamListTable(
    checkState,
    popChangeTeamModal,
    deleteRow,

    allRefreshViewModalDone,
    modalParam.data,
  );

  return (
    <div style={{ width: "25rem" }}>
      {loading && <Loading />}
      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />

      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="팀원 목록" />
      </div>

      <CustomTable
        columns_head={teamListData.columns_head}
        table_title={teamListData.table_title}
        rest_call={teamListData.get_data_from_rest}
        row_render={teamListData.create_table}
        rest_data={teamMemberList}
      />

      <div style={{ display: "flex", justifyContent: "center", marginTop: "1.3rem" }}>
        <Button
          className={clsx({
            [btnStyleClass.btnRootWhite]: true,
            [btnStyleClass.buttonType5]: true,
          })}
          onClick={() => {
            popAddTeamMemberModal(
              "팀원 추가",
              {
                branch_info: modalParam.data.branch_info,
                mode: "multi-one",
              },
              onAddTeamMemberDone,
            );
          }}
        >
          팀원 추가
        </Button>
      </div>
    </div>
  );
};

export default EditTeamModal;

class teamListTable {
  table_title = "";
  checkState = null;
  popChangeTeamModal = null;
  deleteRow = null;

  allRefreshViewModalDone = null;
  teamData = null;

  constructor(
    checkState,
    popChangeTeamModal,
    deleteRow,

    allRefreshViewModalDone,
    teamData,
  ) {
    this.checkState = checkState;
    this.popChangeTeamModal = popChangeTeamModal;
    this.deleteRow = deleteRow;

    this.allRefreshViewModalDone = allRefreshViewModalDone;
    this.teamData = teamData;
  }

  create_table = (data, idx) => {
    // radio check
    if (this.checkState[idx] === undefined) {
      this.checkState[idx] = false;
    } else {
      data.is_boss = this.checkState[idx];
    }

    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.staff_pos}</CustomTableContents>
        <CustomTableContents>{data.staff_name}</CustomTableContents>
        <CustomTableContentsEND align="center">
          <div style={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
            <IconButton
              type="change"
              onClick={() => {
                this.popChangeTeamModal(
                  "팀 변경",
                  { data, teamInfo: this.teamData },
                  this.allRefreshViewModalDone,
                );
              }}
            />
            <IconButton
              type="delete"
              onClick={() => {
                this.deleteRow(data);
              }}
            />
          </div>
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    // { title: "팀장", width: "25%" },
    { title: "직책", width: "30%" },
    { title: "직원명", width: "30%" },
    { title: "변경/삭제", width: "40%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
