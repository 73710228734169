import { contentTypeJson } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

// ==================== GET ====================
// [GET] 출입 카드관리 리스트 검색
export async function getAccessCardList(queryData) {
  const { page, isExcel, searchParams } = queryData;
  const formData = new FormData();

  formData.append("is_excel", isExcel ? isExcel : false);
  formData.append("page_num", page);
  formData.append("search_param", JSON.stringify(searchParams));

  return await HttpInstance.post(
    process.env.REACT_APP_BRANCH_SERVER + "/access/card_manage_list",
    formData,
    contentTypeJson,
  );
}
// [GET] 출입 카드현황 가져오기
export async function getAccessCardStatus(queryData) {
  const { searchParams } = queryData;
  const formData = new FormData();

  formData.append("search_param", JSON.stringify(searchParams));

  return await HttpInstance.post(
    process.env.REACT_APP_BRANCH_SERVER + "/access/card_manage_list/summary",
    formData,
    contentTypeJson,
  );
}
