import { dataEmpty, getPermission } from "components/CommonLib/CommonLib";
import _ from "lodash";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import {
  branch_search_init_state,
  hq_branch_search_init_state,
} from "pages/CommonModal/Search/Module/reducers/hq_branch_search_init_state";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DeviceManageView from "./DeviceManageView";
import DeviceMangeModalIndex from "./Modal/DeviceMangeModalIndex";

const DeviceManageWrapper = () => {
  const storesDispatch = useDispatch();

  const [hqBranchData, setHqBranchData] = useState(_.cloneDeep(hq_branch_search_init_state));
  const [branchList, setBranchList] = useState(_.cloneDeep(branch_search_init_state));

  const getHqBranchCombo = async () => {
    const result = await getPermission(storesDispatch, "ACCESS_MENU/MANAGE", true, false, true);
    const branchResult = await getPermission(
      storesDispatch,
      "ACCESS_MENU/MANAGE",
      true,
      true,
      true,
    );
    setHqBranchData(result);
    setBranchList(branchResult);
  };

  //첫 랜더링
  useEffect(() => {
    getHqBranchCombo();
  }, []);

  const openModal = (select, param, doneCallback) => {
    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,
        Content: (
          <DeviceMangeModalIndex
            modalSelect={select}
            modalParam={param}
            onModalDone={doneCallback}
          />
        ),
      }),
    );
  };

  //버튼 클릭후 모달이 팝업될때
  const onClickOpenModal = (order, param, doneCallback) => {
    if (order !== "") {
      if (dataEmpty(doneCallback)) {
        openModal(order, param, storesDispatch(globalModalOff()));
      } else {
        openModal(order, param, doneCallback);
      }
    }
  };

  return (
    <DeviceManageView
      onClickOpenModal={onClickOpenModal}
      hqBranchData={hqBranchData}
      branchList={branchList}
    />
  );
};
export default DeviceManageWrapper;
