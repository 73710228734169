import { generateObjectDummyId } from "components/CommonLib/CommonLib";

export const ptDetailInit = {
  stores: {
    data: {
      member_id: "string / 회원 ID",
      pt_detail_id: "string / PT Detail ID",
      pt_info: {
        pt_status: "상태",
        base_session_cnt: 0,
        service_session_cnt: 0,
        remain_session_cnt: 0,
        purchase_date: "date / 계약일 (구매일)",
        total_price: 0,
        unpaid: 0,
        per_price: 0,

        join_type: "워크인",
        pt_id: "string / PT 계약상품 ID",
        item_name: "계약상품명",
        latest_class_date: "최근 수업일",
        notice: "메모",
        remarks: "비고",
        pt_used_cnt: 0,
        pt_postpone_cnt: 0,
        pt_cancel_cnt: 0,
      },
      cur_manager: {
        id_assigned: false,
        manager_id: "string / 수업담당자 ID",
        manager_name: "string / 수업담당자 이름",
        manager_pos: "string / 수업담당자 직책",
        is_resigned: false,
      },
      cs_staff: {
        cs_staff_id: "string / 상담 직원 ID",
        cs_staff_name: "string / 상담 직원명",
        cs_staff_pos: "string / 상담 직원 직책",
      },
      reg_info: {
        reg_date: "date / 등록일시",
        reg_user: "string / 등록자",
        upd_date: "date / 수정일시",
        upd_user: "string / 최근수정자",
      },
      pt_class: [
        {
          no: "PT 회차",
          session_type: "서비스",
          session_price: 0,
          manager_id: "string / 수업담당자 ID",
          manager_name: "string / 수업담당자 이름",
          manager_pos: "string / 수업담당자 직책",
          payout_price: 0,
          fix_payout_rate: false,

          proceed_class: {
            comment: "string / 수업 코멘트",
            done_type: "완료",
            class_done_date: "date / 수업 완료일시",
          },
        },
      ],
    },
  },
};
