import * as ActionTypes from "./action_type";

///PERMISSION
export const loadPermission = () => {
  return {
    type: ActionTypes.getPermissionAction,
    // package_id: package_id,
    // branch_name: branch_name,
  };
};

export const loadPermissionSuccess = (data) => {
  return {
    type: ActionTypes.getPermissionSuccessAction,
    payload: data,
  };
};

export const loadPermissionFail = (data) => {
  return {
    type: ActionTypes.getPermissionFailAction,
    payload: [],
  };
};

export const permissionAction = {
  loadPermissionSuccess,
  loadPermissionFail,
};
