//reg_input_info
export const inputInitState = {
  bill_file: {},
  contract_doc_list: [],
  member_id: "",
  pay_promise: {
    purchase_code: "",
    manager: "",
    reason: "",
    date: "",
  },
  paymentA: {},
  reg_date: "",
  unpaid: {
    total_unpaid: "",
    unpaid_list: [],
  },
  unpaid_list: [],
};
