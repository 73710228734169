import { gxFeeInitState } from "./gx_fee_init_state";
import { staffInitState } from "./staff_init_state";
import { workInfoInitState } from "./work_info_init_state";

import * as ActionTypes from "../actions/action_type";

const workInfoListInitialState = {
  stores: {
    data: [],
  },
};
export const work_info_list_info = (state = workInfoListInitialState, action) => {
  switch (action.type) {
    case ActionTypes.staffWorkListViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.staffWorkListViewFailAction:
    case ActionTypes.staffWorkListViewResetAction:
      return { ...state, stores: workInfoListInitialState.stores };
    default:
      return state;
  }
};

export const staff_info = (state = staffInitState, action) => {
  switch (action.type) {
    case ActionTypes.staffInfoViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.staffInfoViewFailAction:
    case ActionTypes.staffInfoViewResetAction:
      return { ...state, stores: staffInitState.stores };
    default:
      return state;
  }
};

export const work_info = (state = workInfoInitState, action) => {
  switch (action.type) {
    case ActionTypes.staffWorkInfoViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.staffWorkInfoViewFailAction:
    case ActionTypes.staffWorkInfoViewResetAction:
      return { ...state, stores: workInfoInitState.stores };
    default:
      return state;
  }
};

export const gx_fee_info = (state = gxFeeInitState, action) => {
  switch (action.type) {
    case ActionTypes.staffGxFeeInfoViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.staffGxFeeInfoViewFailAction:
    case ActionTypes.staffGxFeeInfoViewResetAction:
      return { ...state, stores: gxFeeInitState.stores };
    default:
      return state;
  }
};
