import { generateObjectZeroId } from "components/CommonLib/CommonLib";
import { create } from "zustand";

// 미납 현황 검색 조건 베이스
const baseSearchParams = {
  base_info: {
    phone: "",
  },
  p1: "",
  p2: "",
  p3: "",
  branch_info: {
    branch_id: generateObjectZeroId(),
    branch_name: "",
  },
  combo_item: {
    branch_info_list: [
      {
        branch_id: generateObjectZeroId(),
        branch_name: "",
      },
    ],
    branch_list: [""],
  },
  is_unpaid_reject: false,
  member_info: {
    member_id: generateObjectZeroId(),
    member_name: "",
    member_no: "",
  },
  paid_item_type: "",
  tm_from_date: "",
  tm_manager: {
    sabun: "",
    staff_id: generateObjectZeroId(),
    staff_name: "",
    staff_pos: "",
    staff_rank: "",
  },
  tm_status: "",
  tm_to_date: "",
  unpaid_due_date_from: "",
  unpaid_due_date_to: "",
  unpaid_reg_date_from: "",
  unpaid_reg_date_to: "",
  unpaid_reject_staff: {
    staff_id: generateObjectZeroId(),
    staff_name: "",
    staff_pos: "",
    staff_rank: "",
  },
};

export const useUnpaidManageStore = create((set, get) => ({
  returnStoreByType: (type) => {
    const {
      unpaidManageLoading,
      setUnpaidManageLoading,
      // 미납 현황
      unpaidStatusSearchParams,
      setUnpaidStatusSearchParams,
      resetUnpaidStatusSearchParams,
      unpaidStatusPage,
      setUnpaidStatusPage,
      // 미납금 제외 이력 사용 이력
      unpaidExclusionSearchParams,
      setUnpaidExclusionSearchParams,
      resetUnpaidExclusionSearchParams,
      unpaidExclusionPage,
      setUnpaidExclusionPage,
    } = get();

    const unpaidStatusLib = {
      loading: unpaidManageLoading,
      setLoading: setUnpaidManageLoading,
      searchParams: unpaidStatusSearchParams,
      setSearchParams: setUnpaidStatusSearchParams,
      resetSearchParams: resetUnpaidStatusSearchParams,
      page: unpaidStatusPage,
      setPage: setUnpaidStatusPage,
    };
    const unpaidExclusionLib = {
      loading: unpaidManageLoading,
      setLoading: setUnpaidManageLoading,
      searchParams: unpaidExclusionSearchParams,
      setSearchParams: setUnpaidExclusionSearchParams,
      resetSearchParams: resetUnpaidExclusionSearchParams,
      page: unpaidExclusionPage,
      setPage: setUnpaidExclusionPage,
    };

    switch (type) {
      case "unpaidStatus":
        return unpaidStatusLib;
      case "unpaidExclusion":
        return unpaidExclusionLib;
      default:
        break;
    }
  },

  // ========== State ===========
  // 미납 현황, 미납금 제외 이력 Loading State
  unpaidManageLoading: false,

  // 미납 현황 검색 State
  unpaidStatusSearchParams: {
    ...baseSearchParams,
  },

  // 미납금 제외 이력 검색 State
  unpaidExclusionSearchParams: {
    ...baseSearchParams,
  },

  // 선택 페이지
  unpaidStatusPage: 1,
  unpaidExclusionPage: 1,

  // ========== Action ==========
  // Loading state 값 변경
  setUnpaidManageLoading: (bool) => {
    set({ unpaidManageLoading: bool });
  },

  // 미납 현황 검색 조건 변경
  setUnpaidStatusSearchParams: (key, value) => {
    set((state) => ({
      unpaidStatusSearchParams: {
        ...state.unpaidStatusSearchParams,
        [key]: value,
      },
    }));
  },
  // 미납금 제외 이력 사용 이력 검색 조건 변경
  setUnpaidExclusionSearchParams: (key, value) => {
    set((state) => ({
      unpaidExclusionSearchParams: {
        ...state.unpaidExclusionSearchParams,
        [key]: value,
      },
    }));
  },

  // Page 값 변경
  setUnpaidStatusPage: (value) => {
    set({ unpaidStatusPage: value });
  },
  setUnpaidExclusionPage: (value) => {
    set({ unpaidExclusionPage: value });
  },

  // 검색 조건 리셋
  resetUnpaidStatusSearchParams: () => {
    set((state) => ({
      unpaidStatusSearchParams: {
        ...baseSearchParams,
        ...state.firstHqBranchInfoData,
      },
    }));
  },

  resetUnpaidExclusionSearchParams: () => {
    set((state) => ({
      unpaidExclusionSearchParams: {
        ...baseSearchParams,
        ...state.firstHqBranchInfoData,
      },
    }));
  },

  // 모든 검색 조건 리셋
  resetAllSearchParams: () => {
    const { resetUnpaidStatusSearchParams, resetUnpaidExclusionSearchParams } = get();

    resetUnpaidStatusSearchParams();
    resetUnpaidExclusionSearchParams();
  },
}));
