import { contentTypeJson, simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

//구역수정 모달
const LockerSetEditModal = ({ modalParam, onModalDone }) => {
  //modalParam: name,floor_id,zone_id,branch_id,zoneList

  const storesDispatch = useDispatch();
  const [zoneName, setZoneName] = useState("");
  const [loading, setLoading] = useState(false);

  //초기랜더링 zoneName세팅
  useEffect(() => {
    if (modalParam) {
      setZoneName(modalParam.name);
    }
  }, [modalParam]);

  const editZoneName = async () => {
    try {
      const formData = new FormData();
      formData.append("branch_id", modalParam.branch_id);
      formData.append("floor_id", modalParam.floor_id);
      formData.append("zone_id", modalParam.zone_id);
      formData.append("zone_name", zoneName);

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_BRANCH_SERVER + "/locker/zone/update",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label labelText="변경되었습니다." />
              </>
            ),
            onBtnOk: () => {
              //모달 닫기 + 섹션3 갱신 + 섹션2 콤보갱신
              onModalDone();
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const validationCheck = () => {
    const idx = modalParam.zoneList.findIndex((el) => el === zoneName);
    if (!zoneName) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="구역명을 입력해주세요." />
            </>
          ),

          onBtnOk: () => {},
        }),
      );
    } else if (idx !== -1) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="지점 내 이미 존재하는 구역명입니다." />
            </>
          ),

          onBtnOk: () => {},
        }),
      );
    } else {
      //수정 POST요청
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="변경 하시겠습니까?" />
            </>
          ),

          onBtnYes: () => {
            editZoneName();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  return (
    <div>
      {loading && <Loading />}

      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <TextField
          labelWidth={"3.3rem"}
          labelText="구역명"
          textBoxWidth="12rem"
          defaultValue={zoneName}
          onBlur={(e) => {
            setZoneName(e.target.value);
          }}
        />
        <div style={{ display: "flex", marginTop: "1rem" }}>
          <Label labelText="※구역수정은" fontSize="0.75rem" />
          <Label labelText="구역명" color="red" fontSize="0.75rem" />
          <Label labelText="만 변경 가능합니다." fontSize="0.75rem" />
        </div>
        <div style={{ height: "1rem" }}></div>

        <NormalBtn name="변경" onClick={validationCheck} />
      </div>
    </div>
  );
};
export default LockerSetEditModal;
