import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { getByte, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import ColorLabel from "components/LabelInput/ColorLabel";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import TextArea from "components/LabelInput/TextArea";
import TextField from "components/LabelInput/TextField";
import TimePicker from "components/LabelInput/TimePicker";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalModalOff } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getNoticeInit } from "../Module/reducer/searchInitState";

const ViewNoticeModal = ({ modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const [info, setInfo] = useState(getNoticeInit);
  const [staffName, setStaffName] = useState("");
  const labelWidth = "4rem";

  const getNoticeInfo = async () => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_STAFF_SERVER + "/schedule/notice/view",
        {
          params: {
            schedule_id: modalParam.scheduleId,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        const result = res.data.data;
        // const nameArr = result.sub_info.target_staff_list.map((el) => el.staff_name);
        // const nameString = nameArr.join(", ");
        // setStaffName(nameString);
        if (!result.sub_info.dm_staff_list) {
          result.sub_info.dm_staff_list = [];
        }
        setInfo(result);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  useEffect(() => {
    getNoticeInfo();
  }, [modalParam]);

  const getStaffNamePos = (data) => {
    return data.staff_name + " " + data.staff_pos;
  };

  // const navigate = useNavigate();
  const onClickMoveToList = () => {
    if (info.sub_info.dm_path !== "") {
      onModalDone(info.sub_info.dm_path);
    }
  };
  return (
    <div>
      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="일정 정보" />
      </div>
      <div style={{ display: "flex", marginTop: "0.3rem" }}>
        <DatePicker
          labelText="일시"
          labelMarginRight="0rem"
          labelWidth="4rem"
          labelMarginLeft="1rem"
          textBoxWidth="9rem"
          disabled
          defaultValue={info.schedule_base.from_date}
        />
        <DatePicker
          labelText="~"
          textBoxWidth="9rem"
          disabled
          defaultValue={info.schedule_base.to_date}
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.3rem" }}>
        <TimePicker
          labelMarginRight="0rem"
          labelWidth="4rem"
          labelMarginLeft="1rem"
          textBoxWidth="9rem"
          disabled
          defaultValue={info.schedule_base.from_time}
        />
        <TimePicker
          labelText="~"
          textBoxWidth="9rem"
          disabled
          defaultValue={info.schedule_base.to_time}
        />
        <CheckBox
          labelText="종일"
          marginTop="0rem"
          width="0.7rem"
          height="0.7rem"
          trasnform="scale(1)"
          ItemMarginLeft="0.2rem"
          labelMarginLeft="0.2rem"
          fontSize="0.8rem"
          labelWidth="2rem"
          disabled
          defaultValue={
            info.schedule_base.from_time === "00:00" && info.schedule_base.to_time === "23:59"
              ? true
              : false
          }
        />
      </div>
      {/* <div style={{ marginTop: "0.3rem" }}>
        <TextField
          labelWidth="4rem"
          labelText="대상직원"
          labelMarginLeft="1rem"
          // textBoxWidth="18.7rem"
          fullWidth
          disabled
          defaultValue={staffName}
        />
      </div> */}
      <div style={{ display: "flex", marginTop: "0.3rem" }}>
        <div style={{ width: labelWidth, display: "flex" }}>
          <Label width={labelWidth} labelText="대상직원" justifyContent="left" />
        </div>
        <div style={boxStyle}>
          {info.sub_info.dm_staff_list.map((el) => (
            <ColorLabel
              width={((getByte(getStaffNamePos(el)) * 0.9) / 2 + 0.5).toString() + "rem"}
              labelText={getStaffNamePos(el)}
              bgColor="#222222"
              fontColor="#FFFFFF"
            />
          ))}
        </div>
      </div>
      <div style={{ display: "flex", marginTop: "0.7rem" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="전달 내용" />
      </div>
      <div style={{ marginTop: "0.3rem" }}>
        <TextField
          labelWidth="4rem"
          labelText="담당자"
          labelMarginLeft="1rem"
          fullWidth
          disabled
          defaultValue={info.schedule_base.charger_staff_info.staff_name}
        />
        <TextField
          marginTop="0.3rem"
          labelWidth="4rem"
          labelText="제목"
          labelMarginLeft="1rem"
          fullWidth
          disabled
          defaultValue={info.sub_info.title}
        />

        <TextArea
          marginTop="0.3rem"
          labelWidth="4rem"
          labelMarginLeft="1rem"
          labelText="내용"
          minRows={5}
          maxRows={5}
          fullWidth
          disabled
          defaultValue={info.sub_info.memo}
        />
      </div>
      <div style={{ display: "flex", marginTop: "2rem", justifyContent: "center", gap: "0.5rem" }}>
        <Button
          className={clsx({
            [btnStyleClass.btnRootWhite]: true,
            [btnStyleClass.buttonType6]: true,
          })}
          onClick={() => onClickMoveToList()}
        >
          게시글 이동
        </Button>
        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={() => {
            storesDispatch(globalModalOff());
          }}
        >
          확인
        </Button>
      </div>
    </div>
  );
};

export default ViewNoticeModal;

const boxStyle = {
  marginTop: "0.5rem",
  width: "100%",
  minHeight: "2.5rem",
  borderRadius: "5px",
  border: "1px solid #BBBBBB",
  backgroundColor: "#ebebeb",
  fontFamily: "AppleSDGothicNeo",
  fontSize: "0.9rem",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#000",
  boxSizing: "border-box",
  padding: "0.2rem",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: "0.2rem",
};
