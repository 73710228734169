import * as ActionTypes from "./action_type";

///BASE
export const loadHqBase = (hq_id) => {
  return {
    type: ActionTypes.getHqBaseAction,
    hq_id: hq_id,
    // branch_name: branch_name,
  };
};

export const loadHqBaseSuccess = (data) => {
  return {
    type: ActionTypes.getHqBaseSuccessAction,
    payload: data,
  };
};

export const loadHqBaseFail = (data) => {
  return {
    type: ActionTypes.getHqBaseFailAction,
    payload: [],
  };
};

export const loadHqBaseReset = (data) => {
  return {
    type: ActionTypes.getHqBaseResetAction,
    payload: data,
  };
};

///HQ MANAGE
export const loadHqView = (hq_id) => {
  return {
    type: ActionTypes.getHqManageViewAction,
    hq_id: hq_id,
    // branch_name: branch_name,
  };
};

export const loadHqViewSuccess = (data) => {
  return {
    type: ActionTypes.getHqManageViewSuccessAction,
    payload: data,
  };
};

export const loadHqViewFail = (data) => {
  return {
    type: ActionTypes.getHqManageViewFailAction,
    payload: [],
  };
};

export const loadHqViewReset = (data) => {
  return {
    type: ActionTypes.getHqManageViewResetAction,
    payload: data,
  };
};

///BRANCH MANAGE
export const loadBranchView = (hq_id) => {
  return {
    type: ActionTypes.getBranchManageViewAction,
    hq_id: hq_id,
    // branch_name: branch_name,
  };
};

export const loadBranchViewSuccess = (data) => {
  return {
    type: ActionTypes.getBranchManageSuccessAction,
    payload: data,
  };
};

export const loadBranchViewFail = (data) => {
  return {
    type: ActionTypes.getBranchManageFailAction,
    payload: [],
  };
};

export const loadBranchViewReset = (data) => {
  return {
    type: ActionTypes.getBranchManageResetAction,
    payload: data,
  };
};

///POLICY MANAGE
export const loadPolicyView = () => {
  return {
    type: ActionTypes.getHqManageViewAction,
    // branch_id: branch_id,
    // branch_name: branch_name,
  };
};

export const loadPolicyViewSuccess = (data) => {
  return {
    type: ActionTypes.getHqManageViewSuccessAction,
    payload: data,
  };
};

export const loadPolicyViewFail = (data) => {
  return {
    type: ActionTypes.getHqManageViewFailAction,
    payload: [],
  };
};

export const loadPolicyViewReset = (data) => {
  return {
    type: ActionTypes.getHqManageViewResetAction,
    payload: data,
  };
};

///BRANCH INFO
export const loadBranchInfo = (branch_id) => {
  return {
    type: ActionTypes.getBranchInfoAction,
    branch_id: branch_id,
    // branch_name: branch_name,
  };
};

export const loadBranchInfoSuccess = (data) => {
  return {
    type: ActionTypes.getBranchInfoSuccessAction,
    payload: data,
  };
};

export const loadBranchInfoFail = (data) => {
  return {
    type: ActionTypes.getBranchInfoFailAction,
    payload: [],
  };
};

export const loadBranchInfoReset = (data) => {
  return {
    type: ActionTypes.getBranchInfoResetAction,
    payload: data,
  };
};

export const hqSettingAction = {
  loadHqBaseSuccess,
  loadHqBaseFail,
  loadHqBaseReset,
  loadHqViewSuccess,
  loadHqViewFail,
  loadHqViewReset,
  loadBranchViewSuccess,
  loadBranchViewFail,
  loadBranchViewReset,
  loadPolicyViewSuccess,
  loadPolicyViewFail,
  loadPolicyViewReset,
  loadBranchInfoSuccess,
  loadBranchInfoFail,
  loadBranchInfoReset,
};
