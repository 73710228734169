import LoadingContainer from "./LoadingContainer";
import Spinner from "./Spinner";

import styled from "styled-components";

const Title = styled.h1`
  font-size: 2.5rem;

  font-weight: 500;
  font-family: "AppleSDGothicNeo";
  text-align: center;
  color: #ffffff;

  margin: 0;
  margin-top: 2rem;
`;
const SubText = styled.div`
  font-size: 1rem;
  font-weight: 500;
  font-family: "AppleSDGothicNeo";
  text-align: center;
  color: #ffffff;
  margin-top: 0.3rem;
`;

const Loading = () => {
  // const getRandom = (min, max) => parseInt(Math.random() * (max - min)) + min;
  // const loadingText = [
  //   "잠시만 기다려주세요",
  //   // "Please Wait ....",
  //   // "Loading ..",
  //   // "로딩중 ...",
  //   // "불러오는 중 ...",
  // ];

  return (
    <LoadingContainer offSetY={window.pageYOffset}>
      <Spinner />
      {/* <Title>{loadingText[getRandom(0, 1)]}</Title> */}
      <Title>로딩 중입니다.</Title>
      <SubText>잠시만 기다려주세요.</SubText>
    </LoadingContainer>
  );
};

export default Loading;
