import { dateToStr } from "components/CommonLib/CommonLib";
import { useEffect, useState } from "react";
import WeekSchedule from "./WeekSchedule";
import {
  boxStyle,
  normalTimeStyle,
  selectStyle,
  timeLineBoxStyle,
  today_style_week,
} from "./calendarStyle";

const WeekCalendar = ({
  searchParam,
  searchResult,
  setSearchResult,
  onClickOpenModal,
  refreshCalendar,
}) => {
  const [colDayArr, setColDayArr] = useState([]);

  //이번주 시작(일요일) 기준으로 나머지 요일들의 날짜 구하기
  const getDay = (dayIdx) => {
    const start = searchParam.search_week_from_date;
    if (dayIdx === 0) {
      //일요일
      return start;
    } else if (dayIdx === 1) {
      //월요일
      return dateToStr(new Date(new Date(start).setDate(new Date(start).getDate() + 1)));
    } else if (dayIdx === 2) {
      //화요일
      return dateToStr(new Date(new Date(start).setDate(new Date(start).getDate() + 2)));
    } else if (dayIdx === 3) {
      //수요일
      return dateToStr(new Date(new Date(start).setDate(new Date(start).getDate() + 3)));
    } else if (dayIdx === 4) {
      //목요일
      return dateToStr(new Date(new Date(start).setDate(new Date(start).getDate() + 4)));
    } else if (dayIdx === 5) {
      //금요일
      return dateToStr(new Date(new Date(start).setDate(new Date(start).getDate() + 5)));
    } else if (dayIdx === 6) {
      //토요일
      return dateToStr(new Date(new Date(start).setDate(new Date(start).getDate() + 6)));
    }
  };

  //주간 날짜 배열
  const getDayArr = () => {
    let arr = [];
    const start = searchParam.search_week_from_date;
    for (let i = 0; i < 7; i++) {
      arr.push(dateToStr(new Date(new Date(start).setDate(new Date(start).getDate() + i))));
    }
    setColDayArr(arr);
  };

  useEffect(() => {
    getDayArr();
  }, [searchParam.search_week_from_date]);

  const getToday = (day) => {
    if (day === dateToStr(new Date())) {
      return today_style_week;
    } else {
      return normalTimeStyle;
    }
  };

  const selectPopup = (date, time, scheduleType, isEdit, scheduleId, data) => {
    if (scheduleType === "예약" || scheduleType === "수업완료") {
      popReservModal(date, time, scheduleType, isEdit, scheduleId, data);
    } else if (scheduleType === "개인업무" || scheduleType === "휴무") {
      popPersonalTaskModal(date, time, scheduleType, isEdit, scheduleId, data);
    } else if (scheduleType === "전달사항") {
      popNoticeModal(date, time, scheduleType, isEdit, scheduleId, data);
    }
  };

  const popReservModal = (date, time, scheduleType, isEdit, scheduleId, data) => {
    const param = {
      title:
        scheduleType === "예약" && isEdit
          ? "예약 등록"
          : scheduleType === "예약" && !isEdit
          ? "예약"
          : "수업완료",
      modalParam: {
        mode: "week",
        scheduleType,
        isEdit,
        searchParam,
        scheduleId,
        data,
        date,
        time,
      },
    };
    onClickOpenModal("예약등록", param, refreshCalendar);
  };

  const popPersonalTaskModal = (date, time, scheduleType, isEdit, scheduleId, data) => {
    const param = {
      title:
        scheduleType === "개인업무" && !isEdit
          ? "개인업무"
          : scheduleType === "개인업무" && isEdit
          ? "개인업무 등록"
          : "휴무",

      modalParam: {
        mode: "week",
        scheduleType,
        isEdit,
        searchParam,
        scheduleId,
        data,
        date,
        time,
      },
    };
    onClickOpenModal("개인업무등록", param, refreshCalendar);
  };

  const popNoticeModal = (date, time, scheduleType, isEdit, scheduleId, data) => {
    const param = {
      title: "전달사항",
      modalParam: {
        mode: "week",
        scheduleType,
        isEdit,
        searchParam,
        scheduleId,
        data,
        date,
        time,
      },
    };
    onClickOpenModal("전달사항", param, refreshCalendar);
  };

  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "0.5fr repeat(7,1fr)",
          textAlign: "center",
          marginTop: "2rem",
          marginBottom: "0.7rem",
          fontWeight: "bold",
          color: "#222222",
        }}
      >
        <div></div>
        <div style={getToday(getDay(0))}>일 ({getDay(0).slice(5).replace("-", "/")})</div>
        <div style={getToday(getDay(1))}>월 ({getDay(1).slice(5).replace("-", "/")})</div>
        <div style={getToday(getDay(2))}>화 ({getDay(2).slice(5).replace("-", "/")})</div>
        <div style={getToday(getDay(3))}>수 ({getDay(3).slice(5).replace("-", "/")})</div>
        <div style={getToday(getDay(4))}>목 ({getDay(4).slice(5).replace("-", "/")})</div>
        <div style={getToday(getDay(5))}>금 ({getDay(5).slice(5).replace("-", "/")})</div>
        <div style={getToday(getDay(6))}>토 ({getDay(6).slice(5).replace("-", "/")})</div>
      </div>
      <div style={{ height: "40rem", overflowY: "scroll" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "0.5fr repeat(7,1fr)",
            borderBottom: "4px solid #EBEBEB",
            gridAutoRows: "minmax(2.5rem,auto)",
            boxSizing: "border-box",
          }}
        >
          <div style={timeLineBoxStyle}>종일</div>
          {searchResult.head_line_item.map((day, idx) => (
            <div key={`headline-${idx}`} style={boxStyle}>
              {day.schedule_list.map((el) => (
                <div
                  key={el.schedule_base.schedule_info.schedule_id}
                  style={selectStyle(
                    el.schedule_base.schedule_info.schedule_type,
                    el.schedule_base.schedule_info.schedule_sub_type,
                  )}
                  onClick={() => {
                    selectPopup(
                      day.date,
                      "",
                      el.schedule_base.schedule_info.schedule_type,
                      false,
                      el.schedule_base.schedule_info.schedule_id,
                      el,
                    );
                  }}
                >
                  {el.card_title} <br />
                  {`${el.schedule_base.charger_staff_info.staff_name}`}
                </div>
              ))}
            </div>
          ))}
        </div>
        <WeekSchedule
          searchParam={searchParam}
          setSearchResult={setSearchResult}
          gridRowItem={searchResult.grid_item}
          selectPopup={selectPopup}
          colDayArr={colDayArr}
        />
      </div>
    </div>
  );
};
export default WeekCalendar;
