import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, getCardCompList } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import TextField from "components/LabelInput/TextField";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { getCMSMemberInfoAPi, searchCMSMemberInfoHistAPi } from "./subsPayApi/getApi";
import { newPostPayMethodAPi } from "./subsPayApi/postApi";
import { subs_method_state } from "./subsPayApi/subs_mthod";

const PayManageTab = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();

  const regPayMethod = { ...modalParam.regular_pay_method };
  const subsInfoList = new subsInfoTable(modalParam.regular_pay_date);
  const subsPayList = new subsPayTable();
  const subsMethodHistList = new subsMethodHistTable();

  const [memberId, setMemberId] = useState("");

  const [subsMethodState, setSubsMethodState] = useState(subs_method_state);

  const [subsMethodHist, setSubsMethodHist] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const viewModelRef = useRef();
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });

  useEffect(() => {
    if (modalParam.active_info) {
      setMemberId(modalParam.active_info.member_id);
      getCMSMemberInfoAPi(storesDispatch, modalParam.active_info.member_id, setSubsMethodState);
      searchCMSMemberInfoHistAPi(
        storesDispatch,
        modalParam.active_info.member_id,
        setSubsMethodHist,
        setTotalPage,
        curPage,
      );
    }

    return () => {
      setMemberId("");
      setCurPage(0);
      setTotalPage(0);
      setSubsMethodHist([]);
    };
  }, []);

  useEffect(() => {
    if (curPage) {
      searchCMSMemberInfoHistAPi(
        storesDispatch,
        modalParam.active_info.member_id,
        setSubsMethodHist,
        setTotalPage,
        curPage,
      );
    }
  }, [curPage]);

  // const validation = () => {
  //   let res = "";
  //   // if (!regPayMethod.relation) res += "· 결제자와의 관계\n";
  //   // if (!regPayMethod.payment_method.pay_method) res += "· 납부방법\n";
  //   if (!regPayMethod.payment_method.company) res += "· 결제사명\n";
  //   // if (!regPayMethod.payment_method.exp_month) res += "· 유효기간 (월)\n";
  //   // if (!regPayMethod.payment_method.exp_year) res += "· 유효기간 (연도)\n";
  //   if (!regPayMethod.payment_method.name) res += "· 결제자명\n";
  //   if (!regPayMethod.payment_method.card_no) res += "· 결제번호\n";
  //   if (!regPayMethod.payment_method.phone) res += "· 결제자 전화번호\n";
  //   if (!regPayMethod.payment_method.is_company) res += "· 사업자 여부\n";
  //   // if (!regPayMethod.payment_method.id_no) res += "· 주민번호/사업자번호\n";

  //   return res;
  // };

  const onOpenSendModal = () => {
    setModalInfo({
      show: true,
      title: "정기결제수단 등록 링크 발송",
      Content: <SendLinkModal modalParam={""} onModalDone={onSendModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const onSendModalDone = (phone) => {
    viewModelRef.current.setClose();
    onClickSave(phone);
  };

  const onClickSave = async (phone) => {
    // console.log("mo ", memberPhone);
    // console.log("mo2 ", memberId);

    // const validationRes = validation();

    // if (validationRes !== "") {
    //   storesDispatch(
    //     globalAlertOn({
    //       show: true,
    //       titleText: "알림",
    //       bodyText: (
    //         <>
    //           <Label labelText="필수값을 모두 입력해주셔야 합니다." />
    //           <br />
    //           <br />
    //           <Label labelText={validationRes} whiteSpace="pre-wrap" />
    //         </>
    //       ),
    //       onBtnOk: () => {},
    //     }),
    //   );
    // } else {
    //   storesDispatch(
    //     globalAlertOn({
    //       show: true,
    //       titleText: "알림",
    //       bodyText: <Label labelText="저장되었습니다." />,
    //       reqConfirm: true,
    //       onBtnOk: () => {
    //         onModalDone("정기결제수단", regPayMethod);
    //       },
    //     }),
    //   );
    // }

    newPostPayMethodAPi(storesDispatch, {
      params: {
        member_id: modalParam.active_info.member_id,
        phone: phone,
        is_send_sms: true,
      },
    });
  };

  const onClickModify = () => {};

  const onClickRefresh = () => {};

  const borderStyle = {
    border: "1px solid #888",
    paddingTop: "0.75rem",
    paddingBottom: "1rem",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    marginBottom: "1.5rem",
    // width: borderWidth,
  };

  const btnStyle = {
    marginLeft: "auto",
    display: "table",
  };

  return (
    <div>
      <div style={borderStyle}>
        <ModalPopupLocal ref={viewModelRef} params={modalInfo} />

        <div style={{ marginBottom: "0.25rem", display: "flex" }}>
          <div style={btnStyle}>
            <NormalBtn
              name="링크발송"
              margin="0 0.5rem 0 0"
              onClick={() => {
                onOpenSendModal();
              }}
            />
            <NormalBtn name="새로고침" onClick={() => onClickRefresh()} />
          </div>
        </div>

        <SubTitle titleText={"신청정보"} />
        <CustomTable
          columns_head={subsInfoList.columns_head}
          table_title={subsInfoList.table_title}
          rest_call={subsInfoList.get_data_from_rest}
          row_render={subsInfoList.create_table}
          rest_data={regPayMethod}
        />

        <div style={{ marginTop: "1rem" }}>
          <SubTitle titleText={"납부 정보"} />
          <CustomTable
            columns_head={subsPayList.columns_head}
            table_title={subsPayList.table_title}
            rest_call={subsPayList.get_data_from_rest}
            row_render={subsPayList.create_table}
            // rest_data={regPayMethod.payment_method}
            rest_data={subsMethodState}
          />
        </div>
      </div>

      <div style={{ marginTop: "1rem" }}>
        <SubTitle titleText={"정기결제 수단 등록 이력"} />
        <CustomTable
          columns_head={subsMethodHistList.columns_head}
          table_title={subsMethodHistList.table_title}
          rest_call={subsMethodHistList.get_data_from_rest}
          row_render={subsMethodHistList.create_table}
          // rest_data={regPayMethod}
          rest_data={subsMethodHist}
          height="12rem"
        />
        <CustomPagination curPage={curPage} doSearch={setCurPage} totalCnt={totalPage} />
      </div>
    </div>
  );
};
export default PayManageTab;

class subsInfoTable {
  table_title = "";
  btnStyleClass = btnStyles();
  payDate = 0;

  constructor(payDate) {
    this.payDate = payDate;
  }

  create_table = (data) => {
    return (
      <CustomRow style={{ width: "100%" }}>
        {/* <CustomTableContents width="33%">
          <ComboBox
            justifyContent={"center"}
            labelWidth={"0rem"}
            comboItemWidth={"12rem"}
            labelText=""
            data={["본인", "가족", "지인"]}
            defaultValue={data.relation}
            onChangeCallback={(e) => {
              data.relation = e.target.value;
            }}
          />
        </CustomTableContents> */}
        <CustomTableContents width="34%">{data.price_next_month}</CustomTableContents>
        <CustomTableContentsEND align="center" width="33%">
          매월 {this.payDate}일
        </CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    // { title: "결제자와의 관계", field: "relation" },
    { title: "익월 납부금액", field: "price_next_month" },
    { title: "납부일", field: "pay_date" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return [stateData];
  };
}

//
class subsPayTable {
  table_title = "";
  btnStyleClass = btnStyles();
  cardCompList = getCardCompList();

  create_table = (data) => {
    console.log(data);
    return (
      <CustomRow style={{ width: "100%" }}>
        <CustomTableContents>{data.paymentCompany}</CustomTableContents>
        <CustomTableContents>{data.payerName}</CustomTableContents>
        <CustomTableContents>{data.paymentNumber}</CustomTableContents>

        {/* <CustomTableContentsEND>{"개인"}</CustomTableContentsEND> */}
      </CustomRow>
    );
  };

  columns_head = [
    // { title: "납부방법", width: "10%" },
    { title: "결제사명", width: "25%" },
    // { title: "유효기간\n(MM/YY)", width: "12%" },
    { title: "결제자명", width: "25%" },
    { title: "결제번호", width: "25%" },
    // { title: "결제자 전화번호", width: "10%" },
    // { title: "사업자 여부", width: "25%" },
    // { title: "주민등록번호 7자리\n/ 사업자번호", width: "10%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return [stateData];
  };
}

class subsMethodHistTable {
  table_title = "";
  btnStyleClass = btnStyles();

  create_table = (data) => {
    return (
      <CustomRow style={{ width: "100%" }}>
        <CustomTableContents>{data.created_at}</CustomTableContents>
        <CustomTableContents>{}</CustomTableContents>
        <CustomTableContents>{}</CustomTableContents>
        <CustomTableContents>{}</CustomTableContents>
        <CustomTableContents>{}</CustomTableContents>
        <CustomTableContentsEND>{}</CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "등록일시" },
    { title: "담당자" },
    { title: "납부방법" },
    { title: "결제사명" },
    { title: "결제자명" },
    { title: "결제번호" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}

const SendLinkModal = ({ modalParam, onModalDone }) => {
  let phone = "";
  return (
    <>
      <div
        style={{
          width: "18rem",
          display: "block",
          textAlign: "center",
        }}
      >
        <TextField
          labelText="결제자 전화번호"
          labelWidth="11rem"
          textBoxWidth="10rem"
          justifyContent={"center"}
          labelMarginRight={"0rem"}
          textMarginRight={"0rem"}
          fullWidth
          isStringNumber
          defaultValue={phone}
          onChangeCallback={(e) => {
            phone = e;
          }}
          // disabled
        />
        <div style={{ marginTop: "2rem" }} />
        <NormalBtn
          name="발송"
          margin="0 0.5rem 0 0"
          onClick={() => {
            onModalDone(phone);
          }}
        />
      </div>
    </>
  );
};
