import {
  contentTypeJson,
  dateTimeToStr,
  dateToStr,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import TextArea from "components/LabelInput/TextArea";
import TimePicker from "components/LabelInput/TimePicker";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useState } from "react";
import { useDispatch } from "react-redux";

const OtReservationModal = ({ modalParam, onModalDone }) => {
  const labelWidth = "4.5rem";
  const textBoxWidth = "21.8rem";
  const textBoxWidthShort = "10rem";
  const storesDispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState({
    schedule_base: {
      branch_info: modalParam.data.branch_info,
      hq_info: modalParam.data.hq_info,
      charger_staff_info: modalParam.data.cur_manager.staff_info,
      is_all_day: false,
      from_date: dateToStr(new Date()),
      to_date: dateToStr(new Date()),
      from_time: dateTimeToStr(new Date()).split(" ")[1].slice(0, 5),
      to_time: dateTimeToStr(new Date()).split(" ")[1].slice(0, 5),
      schedule_info: {
        schedule_id: "",
        schedule_name: "",
        schedule_type: "",
        schedule_sub_type: modalParam.mode,
      },
    },
    sub_info: {
      memo: "",
      proceed_class: {
        class_status: "",
      },
      reserv_member: {
        member_id: modalParam.data.membership_info.member_id,
        membership_id: modalParam.data.membership_info.membership_id,
        member_name: modalParam.data.membership_info.member_name,
        phone: modalParam.data.membership_info.phone,
        member_no: modalParam.data.membership_info.member_no,
      },
    },
  });

  const validationCheck = () => {
    if (
      !info.schedule_base.from_date ||
      !info.schedule_base.to_date ||
      !info.schedule_base.from_time ||
      !info.schedule_base.to_time
    ) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="일시를 입력해주세요." />
            </>
          ),

          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="등록 하시겠습니까?" />
            </>
          ),

          onBtnYes: () => {
            registerReserv();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const registerReserv = async () => {
    try {
      const formData = new FormData();
      formData.append("is_new", true);
      formData.append("new_info", JSON.stringify(info));
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_STAFF_SERVER + "/schedule/reserv/upsert",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공

        onModalDone(); //..?
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ width: "26.5rem" }}>
      {loading && <Loading />}

      <div style={{ display: "flex", marginTop: "0.3rem" }}>
        <DatePicker
          labelText="예약 일시"
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidthShort}
          labelMarginRight="0rem"
          defaultValue={info.schedule_base.from_date}
          onChangeCallback={(e) => {
            setInfo((cur) => {
              const obj = { ...cur };
              obj.schedule_base.from_date = e;
              obj.schedule_base.to_date = e;
              return obj;
            });
          }}
        />
        <DatePicker
          labelText="~"
          textBoxWidth={textBoxWidthShort}
          disabled
          defaultValue={info.schedule_base.from_date}
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.3rem" }}>
        <TimePicker
          labelMarginRight="0rem"
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidthShort}
          defaultValue={info.schedule_base.from_time}
          onChange={(e) => {
            setInfo((cur) => {
              const obj = { ...cur };
              obj.schedule_base.from_time = e;
              return obj;
            });
          }}
        />
        <TimePicker
          labelText="~"
          textBoxWidth={textBoxWidthShort}
          defaultValue={info.schedule_base.to_time}
          onChange={(e) => {
            setInfo((cur) => {
              const obj = { ...cur };
              obj.schedule_base.to_time = e;
              return obj;
            });
          }}
        />
      </div>
      <TextArea
        marginTop="0.3rem"
        labelWidth={labelWidth}
        labelMarginRight={"0rem"}
        labelText="메모"
        minRows={5}
        maxRows={5}
        textBoxWidth={textBoxWidth}
        defaultValue={info.sub_info.memo}
        onBlur={(e) => {
          setInfo((cur) => {
            const obj = { ...cur };
            obj.sub_info.memo = e.target.value;
            return obj;
          });
        }}
      />

      <div
        style={{
          marginTop: "1.5rem",
          display: "flex",
          justifyContent: "center",
          gap: "0.5rem",
        }}
      >
        <NormalBtn name="등록" onClick={validationCheck} />
        <NormalBtn
          theme="white"
          name="취소"
          onClick={() => {
            onModalDone(false);
          }}
        />
      </div>
    </div>
  );
};

export default OtReservationModal;
