import * as ActionTypes from "./action_type";

///MEMBER VIEW
export const loadPurchaseDetailModal = (member_id, purchase_code) => {
  return {
    type: ActionTypes.getPurchaseDetailModalAction,
    member_id: member_id,
    purchase_code: purchase_code,
  };
};

export const loadPurchaseDetailModalSuccess = (data) => {
  return {
    type: ActionTypes.getPurchaseDetailModalSuccessAction,
    payload: data,
  };
};

export const loadPurchaseDetailModalFail = (data) => {
  return {
    type: ActionTypes.getPurchaseDetailModalFailAction,
    payload: [],
  };
};

export const loadPurchaseDetailModalReset = (data) => {
  return {
    type: ActionTypes.getPurchaseDetailModalResetAction,
    payload: data,
  };
};
