import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import { listEmpty, parseMoneyFormat } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import React, { useEffect } from "react";
import RefundListItem from "./RefundListItem";

const RefundList = ({
  serviceViewState,
  refundListState,
  payInfoListAction,
  onRefundPaymentItem,
  addPaymentInfo,
  removePaymentInfo,
  updatePaymentInfo,
  payMethodList,
  setPayMethodList,
  regDate,
}) => {
  const [openListState, setOpenListState] = React.useState([]);
  // const [refundTargetList, setRefundTargetList] = React.useState([]);

  const initOpenState = () => {
    let openList = [];
    for (let i = 0; i < refundListState.length; i++) {
      openList.push(false);
    }
    return openList;
  };

  useEffect(() => {
    let openList = initOpenState();
    setOpenListState(openList);

    // setRefundTargetList(refundListState);
  }, [refundListState]);

  const selectOpen = (idx, val) => {
    let openList = initOpenState();
    openList[idx] = val;
    setOpenListState(openList);
  };

  const selectPayMethod = (totalPaid, idx) => {
    refundListState[idx].done_refund_price = totalPaid;
    refundListState[idx].repaid_price = totalPaid - refundListState[idx].total_refund_price;

    const openList = initOpenState();
    openList[idx] = true;
    setOpenListState(openList);

    const purchaseCode = refundListState[idx].purchase_code;
    if (refundListState[idx].repaid_price > 0) {
      addPayInfoState("재결제 (" + purchaseCode + ")", refundListState[idx].repaid_price, 0);

      // 환불데이터 내 재결제금액 set
      const refundList = serviceViewState.refund_item_info.refund_list;
      const _idx = refundList.findIndex((x) => x.purchase_code === purchaseCode);
      refundList[_idx].repaid_price = refundListState[idx].repaid_price;

      const pIdx = payMethodList.findIndex((x) => x.purchase_code === purchaseCode);
      if (pIdx !== -1) {
        payMethodList[pIdx].unpaid_info.unpaid_price = refundListState[idx].repaid_price;
        updatePaymentInfo(purchaseCode, refundListState[idx].repaid_price);
      } else {
        addPaymentInfo(purchaseCode, refundListState[idx].repaid_price);
      }
    } else {
      refundListState[idx].repaid_price = 0;
      addPayInfoState("재결제 (" + purchaseCode + ")", refundListState[idx].repaid_price, 0);
      removePaymentInfo(purchaseCode);
    }
  };

  const addPayInfoState = (key, price, idx) => {
    let action = "push";

    if (price === 0) {
      action = "pop";
    }
    payInfoListAction(
      action,
      key,
      {
        type: key,
        price: price,
        dis: 0,
        pay_order: idx,
        tab_idx: 0,
        item_type: "재결제/없음/없음/없음/없음",
      },
      idx,
      //mbshpIdx 이거 넣어야 함
    );
  };

  const refundList = new refundTable(
    openListState,
    selectOpen,
    selectPayMethod,
    onRefundPaymentItem,
    regDate,
  );

  // console.log(refundListState);

  return (
    <div>
      <CardFixed
        topDom={<div>환불 신청 내역</div>}
        midDom={
          <div style={{ margin: "0 0.1rem" }}>
            <CustomTable
              columns_head={refundList.columns_head}
              table_title={refundList.table_title}
              rest_call={refundList.get_data_from_rest}
              row_render={refundList.create_table}
              rest_data={refundListState}
            />
          </div>
        }
        isLoading={false}
        rootStyle={{
          width: "76.88rem",
          marginTop: "0.625rem",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />
    </div>
  );
};

export default RefundList;

class refundTable {
  table_title = "";
  btnStyleClass = btnStyles();
  openListState = [];
  selectOpen = null;
  selectPayMethod = null;
  onRefundPaymentItem = null;
  regDate = null;

  constructor(openListState, selectOpen, selectPayMethod, onRefundPaymentItem, regDate) {
    this.openListState = openListState;
    this.selectOpen = selectOpen;
    this.selectPayMethod = selectPayMethod;
    this.onRefundPaymentItem = onRefundPaymentItem;
    this.regDate = regDate;
  }

  create_table = (data, idx) => {
    return (
      <>
        <CustomRow
          style={{ width: "100%", cursor: "pointer" }}
          onClick={() => {
            this.selectOpen(idx, !this.openListState[idx]);
          }}
        >
          <CustomTableContents width="20%">
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  this.selectOpen(idx, !this.openListState[idx]);
                }}
              >
                {this.openListState[idx] ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
              <div style={{ width: "70%" }}>{data.purchase_code}</div>
            </div>
          </CustomTableContents>
          <CustomTableContents width="20%">
            {parseMoneyFormat(data.total_paid_price)}
          </CustomTableContents>
          <CustomTableContents width="20%">
            {parseMoneyFormat(data.total_refund_price)}
          </CustomTableContents>
          <CustomTableContents width="20%">
            {data.done_refund_price.toLocaleString()}
          </CustomTableContents>
          <CustomTableContents width="20%">
            {data.repaid_price.toLocaleString()}
          </CustomTableContents>
        </CustomRow>
        <CustomRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
            <Collapse in={this.openListState[idx]} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <RefundListItem
                  refundItem={data}
                  index={idx}
                  selectPayMethod={this.selectPayMethod}
                  onRefundPaymentItem={this.onRefundPaymentItem}
                  regDate={this.regDate}
                />
              </Box>
            </Collapse>
          </TableCell>
        </CustomRow>
      </>
    );
  };

  columns_head = [
    { title: "구매코드", field: "purchase_code" },
    { title: "결제금액 합계", field: "total_paid_price" },
    { title: "환불금액", field: "total_refund_price" },
    { title: "환불 완료 금액", field: "done_refund_price" },
    { title: "재결제 금액", field: "repaid_price" },
  ];

  get_data_from_rest = (stateData) => {
    if (listEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
