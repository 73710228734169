import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnSubmitStyles } from "components/ButtonStyle/ButtonStyle";
import {
  contentTypeJson,
  generateObjectZeroId,
  getCurrentDate,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import DocInfo from "components/File/DocInfo";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import {
  payListActions,
  subsPayListActions,
} from "pages/ERP/MemberCommonItems/Module/actions/index";
import PersonalInfo from "pages/ERP/MemberCommonItems/PersonalInfo";
import SubscriptionInfo from "pages/ERP/MemberCommonItems/SubscriptionInfo";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PaymentInfo from "../../MemberCommonItems/PaymentInfo";
import RegisterPaidDate from "../../MemberCommonItems/RegisterPaidDate";
import CardReissueInfo from "../Common/CardContents/CardReissueInfo";
import OthersInfo from "../Common/CardContents/OthersInfo";
import PtInfo from "../Common/CardContents/PtInfo";
import { payment_init_state } from "../Register/Module/reducers/payment_init_state";
import AddPayMembershipContents from "./AddPayMembershipContents";
import RegisterModalIndex from "./Modal/RegisterModalIndex";
import post_api from "./Module/api/PostApi";

const AddPayView = ({
  baseInfoState,
  memberInputState,
  memberBaseInfoState,
  navigate,
  initRegDate,
}) => {
  //
  const mbshpOptionChecker = () => {
    if (
      memberInputState.membership_all_list.findIndex(
        (x) => x.membership_list.mbshp_type === "구독형",
      ) !== -1
    ) {
      return true;
    } else {
      return false;
    }
  };

  const [loading, setLoading] = useState(false);
  const [regDate, setRegDate] = useState(initRegDate);
  const paymentInfoRef = useRef();
  const subscriptInfoRef = useRef();
  const ptInfoItemRef = useRef();
  const btnStyleClass = btnSubmitStyles();
  const storesDispatch = useDispatch();

  const [canEnroll, setCanEnroll] = useState(false);
  const [docList, setDocList] = useState([]);
  const [isIncludeSubs, setIsIncludeSubs] = useState(mbshpOptionChecker());
  const [isNewMbshp, setIsNewMbshp] = useState(
    memberInputState.membership_all_list[0].tab_info.is_new,
  );
  const [mbshpIndex, setMbshpIndex] = useState(0);
  const [cardInfo, setCardInfo] = useState({
    is_use: false,
    card_no: "",
    price: baseInfoState.branch_setting.membership_policy.card_remake_fee,
    dis: 0,
    access_method: "카드",
    card_info_id: generateObjectZeroId(),
    branch_info: "",
  });
  const [updateToDate, setUpdateToDate] = useState();

  const onPtItemUpdate = () => {
    ptInfoItemRef.current.init();
  };

  const initSubsItemList = () => {
    for (let i = 0; i < memberInputState.membership_all_list.length; i++) {
      if (memberInputState.membership_all_list[i].sub_payment) {
        for (
          let j = 0;
          j < memberInputState.membership_all_list[i].sub_payment.paid_item_list.length;
          j++
        ) {
          // const subsPayInfoListAction = (act, typeKey, contentObj, ordIdx, mbshpIdx) => {

          const subsItem = memberInputState.membership_all_list[i].sub_payment.paid_item_list[j];
          subsPayInfoListAction(
            "push",
            subsItem.type_key,
            subsItem.content_obj,
            subsItem.orderIdx,
            i,
          );
        }
      }
    }
  };

  useEffect(() => {
    setIsIncludeSubs(mbshpOptionChecker()); // 구독형 회원권이 포함돼 있는지 확인
    onPtItemUpdate();
    setCardInfo({
      card_no: "",
      is_use: false,
      price: baseInfoState.branch_setting.membership_policy.card_remake_fee,
      dis: 0,
    });
  }, [baseInfoState.selected_branch_id]);

  useEffect(() => {
    initSubsItemList();
    setRegDate(initRegDate);
  }, [memberInputState]);

  //파일 초기화
  useEffect(() => {
    if (memberInputState.contract_doc_list.length !== 0) {
      setDocList(memberInputState.contract_doc_list);
    }
  }, [memberInputState.contract_doc_list.length]);

  useEffect(() => {
    paymentInfoRef.current.resetPaidResult();
  }, [regDate]);

  const onClickOpenModal = (order, param) => {
    if (order !== "") {
      // lockerModelRef.current.setOpen();

      openModal(order, param);
    }
  };

  const openModal = (select, param) => {
    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,
        Content: (
          <RegisterModalIndex modalSelect={select} modalParam={param} onModalDone={onModalDone} />
        ),
      }),
    );
  };

  const updateSubsInfo = () => {
    subscriptInfoRef.current.setPaidItemList();
  };

  const onPaymentUpdate = (csPayInfo, ptPayInfo) => {
    if (memberInputState.paymentA.payment_info === null) {
      memberInputState.paymentA.payment_info = [];
    }
    const paymentInfo = memberInputState.paymentA.payment_info;
    const paidItemList = paymentInfoRef.current.getPaidItemList();
    let existCs = false;
    let existPt = false;

    paidItemList.forEach((item) => {
      if (item.type_key !== "PT") {
        existCs = true;
      } else {
        existPt = true;
      }
    });

    // set CS
    if (existCs /*csPayInfo.pay_method.length > 0*/) {
      let idx = paymentInfo.findIndex((x) => x.temp_item_type === "cs");
      if (idx === -1) {
        idx = paymentInfo.length;
        paymentInfo.push({
          ...payment_init_state,
          temp_item_type: "cs",
        });
      }
      paymentInfo[idx].payment_input = csPayInfo;
      // paid_item 추가
      paymentInfo[idx].paid_item_list = [];
      paidItemList.forEach((item) => {
        if (item.type_key !== "PT") {
          paymentInfo[idx].paid_item_list.push(item);
        }
      });

      paymentInfoRef.current.setCsPaidResult(csPayInfo);
    }

    // set PT
    if (existPt /*ptPayInfo.pay_method.length > 0*/) {
      let idx = paymentInfo.findIndex((x) => x.temp_item_type === "pt");
      if (idx === -1) {
        idx = paymentInfo.length;
        paymentInfo.push({
          ...payment_init_state,
          temp_item_type: "pt",
        });
      }
      paymentInfo[idx].payment_input = ptPayInfo;
      // paid_item 추가
      paymentInfo[idx].paid_item_list = [];
      paidItemList.forEach((item) => {
        if (item.type_key === "PT") {
          paymentInfo[idx].paid_item_list.push(item);
        }
      });

      paymentInfoRef.current.setPtPaidResult(ptPayInfo);
    }

    // 포인트 정보 갱신
    const getUsedPoint = (payMethodList) => {
      let used = 0;
      for (let i = 0; i < payMethodList.pay_method.length; i++) {
        if (payMethodList.pay_method[i].pay_method === "포인트") {
          used += payMethodList.pay_method[i].price;
        }
      }
      return used;
    };
    let usedPoint = 0;
    usedPoint += getUsedPoint(csPayInfo);
    usedPoint += getUsedPoint(ptPayInfo);
    paymentInfoRef.current.setPointInfo(memberInputState.point.remain_point - usedPoint, usedPoint);
  };

  const onModalDone = (modalType, param1, param2) => {
    //
    if (modalType === "결제") {
      onPaymentUpdate(param1, param2);
      // 등록 버튼 활성화
      if (param1.pay_method.length > 0 || param2.pay_method.length > 0) {
        setCanEnroll(true);
      }
    } else if (modalType === "쿠폰") {
      // console.log(param1);
      const couponUseList = memberInputState.coupon_use_list;
      const newCouponInfo = {
        coupon_info: param1,
        membership_info: {
          membership_id: "",
          purchase_item_id: "",
        },
        ticket_info: {
          is_issuance: true,
          is_used: true,
          ticket_id: param1.ticket_id,
          member_info: {
            member_id: generateObjectZeroId(),
            member_name: "",
            member_no: "",
          },
        },
      };

      couponUseList.splice(0, couponUseList.length); // clear
      couponUseList.push(newCouponInfo);
      paymentInfoRef.current.setCouponInfo(param1);
      setUpdateToDate(param1.coupon_id); // 이용기간 쿠폰인 경우 기간 업데이트를 위함
      //
    } else if (modalType === "정기결제수단") {
      memberInputState.regular_pay_method = param1;
    }

    storesDispatch(globalModalOff());
    // paidInfoPush(pay_info.pay_method, pay_info.total_paid, pay_info.unpaid_info);
  };

  // 새로 추가한 구독형 회원권이 있으면 sub_payment 데이터 추가
  const setSubsPaymentData = () => {
    // const lastMbshpIdx = memberInputState.membership_all_list.length - 1;
    // const isNewLastMbshp = memberInputState.membership_all_list[lastMbshpIdx].tab_info.is_new
    //   ? true
    //   : false;
    // const mbshpType = memberInputState.membership_all_list[lastMbshpIdx].membership_list.mbshp_type;

    for (let i = 0; i < memberInputState.membership_all_list.length; i++) {
      const mbshpType = memberInputState.membership_all_list[i].membership_list.mbshp_type;

      if (mbshpType === "구독형") {
        const itemList = subscriptInfoRef.current.getPaidItemList(i);
        const isNew = memberInputState.membership_all_list[i].tab_info.is_new;
        const subPayment = memberInputState.membership_all_list[i].sub_payment;
        // console.log(subPayment);

        if (isNew) {
          subPayment.paid_item_list = itemList;
          subPayment.tab_info = {
            MembershipIdx: i,
            idx: i,
            title: memberInputState.membership_all_list[i].tab_info.title,
          };
          subPayment.view_info = {
            is_use: true,
            monthly_total: 0,
            raw_paid: 0,
            total_paid: 0,
          };
        } else {
          subPayment.paid_item_list = itemList;
        }

        memberInputState.membership_all_list[i].sub_payment = subPayment;
      }
    }
  };

  const redirectNotMemberError = (err) => {
    if (err.response.data.msg.includes("회원 전환")) {
      simpleAlert(
        storesDispatch,
        "알림",
        err.response.data.msg,
        true,
        navigate("/member_info/" + memberBaseInfoState.member_id),
      );
      return true;
    }
    return false;
  };

  const onSaveEvent = async () => {
    setLoading(true);

    // INPUT INIT DATA
    setSubsPaymentData();

    memberInputState.branch_info = {
      branch_id: baseInfoState.selected_branch_id,
      branch_name: baseInfoState.selected_branch_name,
    };

    memberInputState.reg_date = regDate;

    const lastIdx = memberInputState.membership_all_list.length - 1;

    if (memberInputState.membership_all_list[lastIdx].tab_info.is_new) {
      memberInputState.membership_all_list[lastIdx].purchase_item_list.branch_policy =
        baseInfoState.branch_setting;
      memberInputState.membership_all_list[lastIdx].purchase_item_list.erp_policy =
        baseInfoState.erp_setting;
      memberInputState.membership_all_list[lastIdx].purchase_item_list.hq_policy =
        baseInfoState.hq_setting;

      // if (memberInputState.membership_all_list[lastIdx].membership_list.mbshp_type === "구독형") {
      //   memberInputState.sub_payment[0].payment_info = memberInputState.regular_pay_method;
      // }
    }

    // 포인트 (check & update remainPoint)
    const pointInfo = paymentInfoRef.current.getPointInfo();
    if (memberInputState.point.remain_point !== pointInfo.remain + pointInfo.used) {
      console.log("POINT CHECK ERROR");
      return;
    }
    memberInputState.point.remain_point = pointInfo.remain;
    memberInputState.point.acc_spend_point += pointInfo.used; // 이거 클라에서 해야 하나?

    // 카드재발급
    if (cardInfo.is_use) {
      memberInputState.card_param = cardInfo;
      if (memberInputState.card_param.card_no) {
        memberInputState.card_param.card_no = Number(memberInputState.card_param.card_no);
      }
    }

    // console.log(JSON.stringify(memberInputState));

    //파일 분기처리 필요!! (일단 현재는 다 is_use === true로 해서 전송)
    memberInputState.contract_doc_list = docList;
    memberInputState.contract_doc_list.forEach((el) => (el.is_use = true));
    //세 -> 임시추가.... 요기까지 2줄

    const formData = new FormData();
    formData.append("member_id", JSON.stringify(memberInputState.member_id));
    formData.append("new_info", JSON.stringify(memberInputState));
    const postApi = post_api.insertAddPayInfo();

    await HttpInstance.post(postApi, formData, contentTypeJson)
      .then((res) => {
        // console.log(res);
        const resId = res.data.data;
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          simpleAlert(
            storesDispatch,
            "알림",
            "등록이 완료되었습니다. 회원정보 페이지로 이동합니다.",
            true,
            navigate("/member_info/" + resId),
          );
        }
      })
      .catch((err) => {
        if (!redirectNotMemberError(err)) {
          console.log(err);
          simpleAlert(
            storesDispatch,
            "ERROR",
            err.response.data ? err.response.data.msg : err.code,
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const payInfoListAction = (act, pType, detailInfo, ord, mbshpIdx) => {
    if (act === "push") {
      storesDispatch(payListActions.paymentInfoPush(pType, detailInfo, ord, mbshpIdx));
    } else if (act === "pop") {
      storesDispatch(payListActions.paymentInfoPop(pType));
    } else if (act === "discount") {
      storesDispatch(payListActions.paymentInfoEdit(pType, detailInfo, ord, mbshpIdx));
    }
  };

  const subsPayInfoListAction = (act, typeKey, contentObj, ordIdx, mbshpIdx) => {
    // console.log("subsPayInfoListAction ..  ", act, typeKey, contentObj, ordIdx, mbshpIdx);
    if (act === "push") {
      storesDispatch(subsPayListActions.subsPayInfoPush(typeKey, contentObj, ordIdx, mbshpIdx));
    } else if (act === "pop") {
      storesDispatch(subsPayListActions.subsPayInfoPop(typeKey));
    } else if (act === "discount") {
      storesDispatch(subsPayListActions.subsPayInfoEdit(typeKey, contentObj, ordIdx, mbshpIdx));
    }
  };

  const resetPaySubInfoListAction = (tabIdx) => {
    // console.log("clear~!");
    storesDispatch(payListActions.paymentInfoClear("add_clear"));
    storesDispatch(subsPayListActions.subsPayInfoClear({ type: "add_clear", idx: tabIdx }));
  };

  const mbshpValidation = () => {
    let res = "";

    for (let i = 0; i < memberInputState.membership_all_list.length; i++) {
      if (memberInputState.membership_all_list[i].tab_info.is_use) {
        if (memberInputState.membership_all_list[i].tab_info.is_new) {
          if (
            memberInputState.membership_all_list[i].membership_list.mbshp.to_date < getCurrentDate()
          ) {
            res += "유효기간 종료일이 오늘보다 이전일 수 없습니다. 다시 입력해주세요";
          }
        }
      }
    }

    return res;
  };

  const validation = () => {
    let res = "";

    for (let i = 0; i < memberInputState.membership_all_list.length; i++) {
      if (memberInputState.membership_all_list[i].tab_info.is_use) {
        if (memberInputState.membership_all_list[i].tab_info.is_new) {
          // 새로운 회원권 가입경로 check
          if (!memberInputState.membership_all_list[i].membership_list.re_reg_path) {
            res += "· 가입경로\n";
          }
          if (
            memberInputState.membership_all_list[i].membership_list.mbshp_option !== "일일권" &&
            !memberInputState.ot_staff.staff_name
          ) {
            res += "· OT 담당자\n";
          }
          // 수강이 없는 경우 새로운 회원권의 시작일 check
          if (!memberInputState.membership_all_list[i].membership_list.mbshp.from_date) {
            res += "· 회원권 시작일\n";
          }
          // 일일권 시간
          if (
            memberInputState.membership_all_list[i].membership_list.mbshp_option === "일일권" &&
            !memberInputState.membership_all_list[i].membership_list.daily_time.from_time
          ) {
            res += "· 일일권 시작시간\n";
          }

          break;
        }
      }
    }

    // if (mbshpOptionChecker() === true && !memberInputState.regular_pay_method.relation) {
    //   res += "· 정기결제수단\n";
    // }

    const paidItemList = paymentInfoRef.current.getPaidItemList();
    let isUseCs = false;
    let isUsePt = false;
    paidItemList.forEach((item) => {
      if (item.type_key === "PT") {
        isUsePt = true;
      } else {
        isUseCs = true;
      }
    });

    if (isUseCs) {
      if (!memberInputState.cs_staff.staff_name) res += "· CS 상담자\n";
      if (cardInfo.is_use && !cardInfo.card_no) res += "· 재발급 카드번호\n";
    }
    if (isUsePt) {
      if (memberInputState.pt_info.default_session > 0) {
        if (!memberInputState.pt_info.cs_staff.staff_name) res += "· PT 상담자\n";
        if (!memberInputState.pt_info.pt_staff.staff_name) res += "· PT 수업 담당자\n";
      }
    }

    return res;
  };

  const enrollPopup = () => {
    const mbshpValidationRes = mbshpValidation();
    if (mbshpValidationRes !== "") {
      simpleAlert(storesDispatch, "알림", mbshpValidationRes);
      return;
    }

    const validationRes = validation();
    if (validationRes !== "") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="필수값을 모두 입력해주셔야 합니다." />
              <br />
              <br />
              <Label labelText={validationRes} whiteSpace="pre-wrap" />
            </>
          ),
          // width: "50rem",
          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="등록하시겠습니까?" />, //enrollConfirmPopupText(),
          // width: "50rem",
          onBtnYes: () => onSaveEvent(),
          onBtnNo: () => {},
        }),
      );
    }
  };

  const getTabNameList = () => {
    //
    // filter()로 바꾸기
    //
    return memberInputState.membership_all_list.map((item, idx) => {
      if (item.tab_info.is_use) {
        return item.tab_info.title;
      }
    });
  };

  const exportData = () => {
    setSubsPaymentData();

    memberInputState.branch_info = {
      branch_id: baseInfoState.selected_branch_id,
      branch_name: baseInfoState.selected_branch_name,
    };

    memberInputState.reg_date = regDate;

    const lastIdx = memberInputState.membership_all_list.length - 1;

    if (memberInputState.membership_all_list[lastIdx].tab_info.is_new) {
      memberInputState.membership_all_list[lastIdx].purchase_item_list.branch_policy =
        baseInfoState.branch_setting;
      memberInputState.membership_all_list[lastIdx].purchase_item_list.erp_policy =
        baseInfoState.erp_setting;
      memberInputState.membership_all_list[lastIdx].purchase_item_list.hq_policy =
        baseInfoState.hq_setting;

      // if (memberInputState.membership_all_list[lastIdx].membership_list.mbshp_type === "구독형") {
      //   memberInputState.sub_payment[0].payment_info = memberInputState.regular_pay_method;
      // }
    }

    // 포인트 (check & update remainPoint)
    const pointInfo = paymentInfoRef.current.getPointInfo();
    if (memberInputState.point.remain_point !== pointInfo.remain + pointInfo.used) {
      console.log("POINT CHECK ERROR");
      return;
    }
    memberInputState.point.remain_point = pointInfo.remain;
    memberInputState.point.acc_spend_point += pointInfo.used; // 이거 클라에서 해야 하나?

    // 카드재발급
    if (cardInfo.is_use) {
      memberInputState.card_param = cardInfo;
      if (memberInputState.card_param.card_no) {
        memberInputState.card_param.card_no = Number(memberInputState.card_param.card_no);
      }
    }

    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(memberInputState),
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    // console.log(memberInputState);
    let name = memberInputState.personal_info.member_name;
    link.download = name + "_data.json";

    link.click();
  };

  // console.log("baseInfoState", baseInfoState);
  // console.log(memberBaseInfoState);
  // console.log("memberInputState");
  // console.log("memberInputState", memberInputState);

  // console.log(JSON.stringify(memberInputState));
  const uploadDoneCallback = (result) => {
    //업로드에서 받아온 파일을 contract_doc_list에 갱신
    const idx = docList.findIndex((el) => el.doc_id === result.doc_id);
    setDocList((cur) => {
      const arr = [...cur];
      arr[idx] = result;
      return arr;
    });
  };

  return (
    <div style={{ display: "flex" }}>
      {loading ? <Loading /> : <></>}

      <div style={{ width: "77rem" }}>
        <div style={{ display: "flex", marginBottom: "0.625rem" }}>
          <PersonalInfo
            memberBaseInfoState={memberBaseInfoState.base_info.personal_info}
            onClickOpenModal={onClickOpenModal}
            memberInputState={{
              branch_info: memberBaseInfoState.branch_info,
              card_param: memberBaseInfoState.access,
            }}
          />
        </div>

        <AddPayMembershipContents
          regDate={regDate}
          payInfoListAction={payInfoListAction}
          subsPayInfoListAction={subsPayInfoListAction}
          baseInfoState={baseInfoState}
          onClickOpenModal={onClickOpenModal}
          memberInputState={memberInputState}
          onPtItemUpdate={onPtItemUpdate}
          resetPaySubInfoListAction={resetPaySubInfoListAction}
          setIsIncludeSubs={setIsIncludeSubs}
          setIsNewMbshp={setIsNewMbshp}
          setMbshpIndex={setMbshpIndex}
          updateToDate={updateToDate}
        />

        <PtInfo
          ref={ptInfoItemRef}
          baseInfoState={baseInfoState}
          payInfoListAction={payInfoListAction}
          memberPtState={memberInputState.pt_info}
          memberInputState={memberInputState.membership_all_list[mbshpIndex]}
          memberInputStateAll={memberInputState}
          isAddPay={true}
        />

        <CardReissueInfo
          payInfoListAction={payInfoListAction}
          onClickOpenModal={onClickOpenModal}
          baseInfoState={baseInfoState}
          cardInfo={cardInfo}
          setCardInfo={setCardInfo}
        />

        <OthersInfo
          payInfoListAction={payInfoListAction}
          branchId={baseInfoState.branch_setting.branch_info.branch_id}
        />
      </div>

      <div style={{ width: "28.13rem", marginLeft: "0.625rem" }}>
        <PaymentInfo
          ref={paymentInfoRef}
          onPaymentUpdate={onPaymentUpdate}
          onClickOpenModal={onClickOpenModal}
          regDate={regDate}
          baseInfoState={baseInfoState}
          setCanEnroll={setCanEnroll}
          tabNameList={getTabNameList()}
          isAddPay={true}
          remainPoint={memberInputState.point.remain_point}
          mbshpCnt={memberInputState.membership_all_list.length - 1}
        />

        {mbshpOptionChecker() === true ? (
          <SubscriptionInfo
            ref={subscriptInfoRef}
            onClickOpenModal={onClickOpenModal}
            memberInputState={memberInputState}
            isAddPay={true}
            regularPayDate={baseInfoState.erp_setting.regular_pay_date}
          />
        ) : (
          <div></div>
        )}
        <RegisterPaidDate regDate={regDate} setRegDate={setRegDate} isDisabled />

        <DocInfo docList={docList} uploadDoneCallback={uploadDoneCallback} textBoxWidth={"11rem"} />
        {/* <DocumentInfo /> */}
        <Button
          style={{ marginTop: "1.5rem" }}
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType2]: true,
            [btnStyleClass.disabled]: !canEnroll,
          })}
          onClick={() => enrollPopup()}
          disabled={!canEnroll}
        >
          등록
        </Button>

        {/* <Button
          style={{ marginTop: "1.5rem" }}
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType2]: true,
          })}
          onClick={() => {
            console.log(memberInputState);
            console.log(baseInfoState);
            // console.log(paymentInfoRef.current.getPaidItemList());
            // console.log(paymentInfoRef.current.getPaidItemList());
          }}
        >
          TEST BTN
        </Button>

        <Button
          style={{ marginTop: "1.5rem" }}
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType2]: true,
          })}
          onClick={() => exportData()}
        >
          DUMP
        </Button> */}
      </div>
    </div>
  );
};
export default AddPayView;
