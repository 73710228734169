import _ from "lodash";
import { locker_info_state } from "pages/ERP/MemberCommonItems/Module/reducers/locker_info";
import { v4 as uuidv4 } from "uuid";

export class subsItemInitializer {
  regDate = null;
  memberInputState = null;
  payInfoListAction = null;
  subsPayInfoListAction = null;
  membershipIdx = 0;

  month_last_day = 0;
  prorate_day = 0;

  preMbshpPerDay = 0;
  preClothPerDay = 0;
  preGxPerDay = 0;
  preLockerPerDay = [0, 0];
  preFamilyClothPerDay = 0;
  preFamilyGxPerDay = 0;
  preFamilyLockerPerDay = [0, 0];

  isNew = false;

  constructor(regDate, memberInputState, payInfoListAction, subsPayInfoListAction, membershipIdx) {
    this.regDate = regDate;
    this.memberInputState = memberInputState;
    this.payInfoListAction = payInfoListAction;
    this.subsPayInfoListAction = subsPayInfoListAction;
    this.membershipIdx = membershipIdx;
    this.isNew = this.memberInputState.tab_info.is_new;
  }

  dateFormat(date) {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    // let hour = date.getHours();
    // let minute = date.getMinutes();
    // let second = date.getSeconds();

    month = month >= 10 ? month : "0" + month;
    day = day >= 10 ? day : "0" + day;
    // hour = hour >= 10 ? hour : '0' + hour;
    // minute = minute >= 10 ? minute : '0' + minute;
    // second = second >= 10 ? second : '0' + second;

    // return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
    return date.getFullYear() + "-" + month + "-" + day;
  }

  makePrePrice = () => {
    const fromDate = this.isNew
      ? new Date(this.memberInputState.membership_list.mbshp.from_date)
      : new Date(this.regDate); // 유효기간 시작일 or 등록일
    const nextFirstDate = new Date(this.memberInputState.membership_list.mbshp.from_date); // 구독 시작일(다음달 1일)
    nextFirstDate.setDate(1);
    nextFirstDate.setMonth(fromDate.getMonth() + 1);

    if (this.isNew) {
      this.memberInputState.membership_list.mbshp.subs_options.contract_from_date = this
        .memberInputState.membership_list.mbshp.from_date
        ? this.dateFormat(nextFirstDate)
        : "";
    }

    const untilEndDate = new Date(
      this.memberInputState.membership_list.mbshp.subs_options.contract_from_date,
    ); // 구독 종료일
    untilEndDate.setMonth(
      nextFirstDate.getMonth() +
        this.memberInputState.purchase_item_list.item_info.subs_options.subs_period_month,
    );
    untilEndDate.setDate(0); // 하루 전
    this.memberInputState.membership_list.mbshp.subs_options.contract_to_date = this
      .memberInputState.membership_list.mbshp.from_date
      ? this.dateFormat(untilEndDate)
      : "";
    this.memberInputState.membership_list.mbshp.to_date = this.dateFormat(untilEndDate);

    //
    const btMs = nextFirstDate.getTime() - fromDate.getTime();
    this.prorate_day = btMs / (1000 * 60 * 60 * 24);

    const lastDate = new Date(fromDate.getFullYear(), fromDate.getMonth() + 1, 0);
    this.month_last_day = lastDate.getDate(); // 이달 몇일까지?

    // 월 구독료
    this.memberInputState.membership_list.mbshp.subs_options.subs_price =
      this.memberInputState.purchase_item_list.item_info.subs_options.mbshp_subs_price;
    this.memberInputState.membership_list.cloth.subs_options.subs_price =
      this.memberInputState.purchase_item_list.item_info.subs_options.cloth_subs_price;
    this.memberInputState.membership_list.gx.subs_options.subs_price =
      this.memberInputState.purchase_item_list.item_info.subs_options.gx_subs_price;

    // 일할금액 계산을 위한 일 금액
    this.preMbshpPerDay = Math.floor(
      (this.memberInputState.membership_list.mbshp.subs_options.subs_price -
        this.memberInputState.membership_list.mbshp.subs_options.subs_dis) /
        this.month_last_day,
    );
    this.preClothPerDay = Math.floor(
      (this.memberInputState.membership_list.cloth.subs_options.subs_price -
        this.memberInputState.membership_list.cloth.subs_options.subs_dis) /
        this.month_last_day,
    );
    this.preGxPerDay = Math.floor(
      (this.memberInputState.membership_list.gx.subs_options.subs_price -
        this.memberInputState.membership_list.gx.subs_options.subs_dis) /
        this.month_last_day,
    );

    //// 일할금액 입력
    if (this.isNew) {
      if (fromDate.getDate() === 1) {
        this.memberInputState.membership_list.mbshp.subs_options.prorate_price =
          this.memberInputState.membership_list.mbshp.subs_options.subs_price;
        this.memberInputState.membership_list.cloth.subs_options.prorate_price =
          this.memberInputState.membership_list.cloth.subs_options.subs_price;
        this.memberInputState.membership_list.gx.subs_options.prorate_price =
          this.memberInputState.membership_list.gx.subs_options.subs_price;
      } else {
        this.memberInputState.membership_list.mbshp.subs_options.prorate_price =
          this.preMbshpPerDay * this.prorate_day;
        this.memberInputState.membership_list.cloth.subs_options.prorate_price =
          this.preClothPerDay * this.prorate_day;
        this.memberInputState.membership_list.gx.subs_options.prorate_price =
          this.preGxPerDay * this.prorate_day;
      }
    }

    for (let i = 0; i < this.memberInputState.membership_list.locker_list.length; i++) {
      this.setLockerProrate(i);

      if (
        isNaN(
          this.memberInputState.membership_list.locker_list[i].item_info.subs_options.prorate_price,
        )
      ) {
        this.memberInputState.membership_list.locker_list[
          i
        ].item_info.subs_options.prorate_price = 0;
      }
    }

    if (isNaN(this.memberInputState.membership_list.mbshp.subs_options.prorate_price)) {
      this.memberInputState.membership_list.mbshp.subs_options.prorate_price = 0;
    }
    if (isNaN(this.memberInputState.membership_list.cloth.subs_options.prorate_price)) {
      this.memberInputState.membership_list.cloth.subs_options.prorate_price = 0;
    }
    if (isNaN(this.memberInputState.membership_list.gx.subs_options.prorate_price)) {
      this.memberInputState.membership_list.gx.subs_options.prorate_price = 0;
    }
    ////

    ///선납금...
    if (this.isNew) {
      this.memberInputState.membership_list.mbshp.subs_options.pre_price =
        this.memberInputState.purchase_item_list.item_info.subs_options.mbshp_pre_price;
      this.memberInputState.membership_list.cloth.subs_options.pre_price =
        this.memberInputState.purchase_item_list.item_info.subs_options.cloth_pre_price;
      this.memberInputState.membership_list.gx.subs_options.pre_price =
        this.memberInputState.purchase_item_list.item_info.subs_options.gx_pre_price;
    }
  };

  setLockerProrate = (lockerIdx) => {
    const fromDate = this.isNew
      ? new Date(this.memberInputState.membership_list.mbshp.from_date)
      : new Date(this.regDate); // 유효기간 시작일 or 등록일

    const lastDate = new Date(fromDate.getFullYear(), fromDate.getMonth() + 1, 0);
    this.month_last_day = lastDate.getDate();

    this.preLockerPerDay[lockerIdx] = Math.floor(
      (this.memberInputState.membership_list.locker_list[lockerIdx].item_info.subs_options
        .subs_price -
        this.memberInputState.membership_list.locker_list[lockerIdx].item_info.subs_options
          .subs_dis) /
        this.month_last_day,
    );

    const nextFirstDate = new Date(fromDate); // 구독 시작일(다음달 1일)
    nextFirstDate.setDate(1);
    nextFirstDate.setMonth(fromDate.getMonth() + 1);
    const btMs = nextFirstDate.getTime() - fromDate.getTime();
    this.prorate_day = btMs / (1000 * 60 * 60 * 24);

    // 일할금액 입력
    if (fromDate.getDate() === 1) {
      this.memberInputState.membership_list.locker_list[
        lockerIdx
      ].item_info.subs_options.prorate_price =
        this.memberInputState.membership_list.locker_list[
          lockerIdx
        ].item_info.subs_options.subs_price;
    } else {
      this.memberInputState.membership_list.locker_list[
        lockerIdx
      ].item_info.subs_options.prorate_price = this.preLockerPerDay[lockerIdx] * this.prorate_day;
    }
  };

  onChangeCheckToPayInfo = (event, orderIdx, isRequred, stdPrice, disPrice, payOrder, isLocker) => {
    let act = isRequred ? "push" : "pop";
    if (event === "등록비") {
      act = "discount"; //edit
    }
    const tabIdx = this.memberInputState.tab_info.idx;
    const startIdx = this.membershipIdx * 10;
    const mOpt = this.memberInputState.purchase_item_list.item_info.mbshp_option;

    let eventName = this.memberInputState.tab_info.title + event;
    // if (isLocker) {
    //   eventName = this.memberInputState.tab_info.is_add_pay
    //     ? this.memberInputState.tab_info.title + "락커 (" + (tabIdx + 1) + ")"
    //     : "락커 (" + (tabIdx + 1) + ")";
    // } else {
    //   eventName = this.memberInputState.tab_info.is_add_pay
    //     ? this.memberInputState.tab_info.title + event
    //     : event;
    // }

    this.payInfoListAction(
      act,
      event,
      {
        type: event,
        price: stdPrice,
        dis: disPrice,
        pay_order: startIdx + payOrder,
        tab_idx: tabIdx,

        item_type: event + "/구독형/선납금/" + mOpt + "/없음",
      },
      startIdx + payOrder,
    );
  };

  remappingState = () => {
    this.makePrePrice();
    // this.newRegistInit();
    // this.newMembershipInit();
    if (this.memberInputState.membership_list.item_name_idx > 0) {
      // console.log(this.memberInputState.membership_list.item_name_idx);
      this.newMembershipInit();
      if (!this.memberInputState.membership_list.reg.is_hide) {
        this.newRegistInit();
      }
    }
    this.newOthersInit();
  };

  checkUse = (item) => {
    if (item.is_lock) {
      return false;
    }
    if (!item.is_use) {
      return false;
    }

    return true;
  };

  newRegistInit = () => {
    // if (this.memberInputState.membership_list.reg.is_lock) {
    //   return;
    // }
    let membershipItem = this.memberInputState.purchase_item_list.item_info;
    // itemIncludeRef.join.current.setValue(true);
    this.onChangeCheckToPayInfo(
      "등록비",
      1,
      true,
      membershipItem.subs_options.subs_reg,
      this.memberInputState.membership_list.reg.dis,
      2,
    );
    this.memberInputState.membership_list.reg.is_use = true;
  };

  newMembershipInit = () => {
    // if (this.checkUse(this.memberInputState.membership_list.mbshp)) {
    //   console.log(this.memberInputState.membership_list.mbshp);

    let eventName = this.memberInputState.tab_info.title + "회원권";
    let tabIdx = this.memberInputState.tab_info.idx;
    let startIdx = this.membershipIdx * 10;
    let mOpt = this.memberInputState.purchase_item_list.item_info.mbshp_option;

    this.subsPayInfoListAction(
      "push",
      "회원권" + this.membershipIdx.toString(),
      {
        type: "회원권",
        price: this.memberInputState.membership_list.mbshp.subs_options.subs_price,
        dis: this.memberInputState.membership_list.mbshp.subs_options.subs_dis,
        pay_order: startIdx + 1,
        tab_idx: tabIdx,
        item_type: "회원권/구독형/월구독료/" + mOpt + "/없음",
      },
      startIdx + 1,
      this.membershipIdx,
    );

    this.editMbshpProratePrice("push", "회원권");

    this.payInfoListAction(
      "push",
      eventName + "(선납)",
      {
        type: eventName + "(선납)",
        price: this.memberInputState.membership_list.mbshp.subs_options.pre_price,
        dis: this.memberInputState.membership_list.mbshp.subs_options.pre_dis,
        pay_order: startIdx + 1,
        tab_idx: tabIdx,
        item_type: "회원권/구독형/선납금/" + mOpt + "/없음",
      },
      startIdx + 1,
      this.membershipIdx,
    );
    // }
  };

  newOthersInit = () => {
    const membershipItem = this.memberInputState.purchase_item_list.item_info;

    this.memberInputState.membership_list.cloth.is_use = membershipItem.is_combine_cloth;
    // itemIncludeRef.cloth.current.setValue(membershipItem.is_combine_cloth);
    const tabIdx = this.memberInputState.tab_info.idx;
    const startIdx = this.membershipIdx * 10;
    const mOpt = this.memberInputState.purchase_item_list.item_info.mbshp_option;

    if (this.checkUse(this.memberInputState.membership_list.cloth)) {
      this.editClothProratePrice("push", "운동복");
      const eventName = this.memberInputState.tab_info.title + "운동복";

      this.subsPayInfoListAction(
        "push",
        eventName + this.membershipIdx.toString(),
        {
          type: eventName,
          price: this.memberInputState.membership_list.cloth.subs_options.subs_price,
          dis: this.memberInputState.membership_list.cloth.subs_options.subs_dis,
          pay_order: startIdx + 2,
          tab_idx: tabIdx,
          item_type: "운동복/구독형/월구독료/" + mOpt + "/없음",
        },
        startIdx + 2,
        this.membershipIdx,
      );

      this.payInfoListAction(
        "push",
        eventName + "(선납)",
        {
          type: eventName + "(선납)",
          price: this.memberInputState.membership_list.cloth.subs_options.pre_price,
          dis: this.memberInputState.membership_list.cloth.subs_options.pre_dis,
          pay_order: startIdx + 2,
          tab_idx: tabIdx,
          item_type: "운동복/구독형/선납금/" + mOpt + "/없음",
        },
        startIdx + 2,
        this.membershipIdx,
      );
    }

    this.memberInputState.membership_list.gx.is_use = membershipItem.is_combine_gx;
    if (this.checkUse(this.memberInputState.membership_list.gx)) {
      let eventName = this.memberInputState.tab_info.title + "GX";
      this.subsPayInfoListAction(
        "push",
        eventName + this.membershipIdx.toString(),
        {
          type: eventName,
          price: this.memberInputState.membership_list.cloth.subs_options.subs_price,
          dis: this.memberInputState.membership_list.cloth.subs_options.subs_dis,
          pay_order: startIdx + 3,
          tab_idx: tabIdx,
          item_type: "GX/구독형/월구독료/" + mOpt + "/없음",
        },
        startIdx + 3,
        this.membershipIdx,
      );

      this.editGxProratePrice("push", "GX");
      this.payInfoListAction(
        "push",
        eventName + "(선납)",
        {
          type: eventName + "(선납)",
          price: this.memberInputState.membership_list.gx.subs_options.pre_price,
          dis: this.memberInputState.membership_list.gx.subs_options.pre_dis,
          pay_order: startIdx + 3,
          tab_idx: tabIdx,
          item_type: "GX/구독형/선납금/" + mOpt + "/없음",
        },
        startIdx + 3,
        this.membershipIdx,
      );
    }

    // 락커
    this.memberInputState.membership_list.locker.is_use = membershipItem.is_combine_locker;
    if (this.checkUse(this.memberInputState.membership_list.locker)) {
      const lockerViewId = uuidv4();
      const baseLocker = _.cloneDeep(locker_info_state);
      baseLocker.view_id = lockerViewId;
      baseLocker.is_combine = true;
      baseLocker.price = membershipItem.locker_price;
      baseLocker.subs_options.pre_price = membershipItem.subs_options.locker_pre_price;
      baseLocker.subs_options.subs_price = membershipItem.subs_options.locker_subs_price;
      // baseLocker.locker_info.locker_idx = this.memberInputState.membership_list.locker_list.length;
      this.memberInputState.membership_list.locker_list.push(baseLocker);

      this.makePrePrice();

      let eventName = this.memberInputState.tab_info.title + "락커";
      this.subsPayInfoListAction(
        "push",
        lockerViewId,
        {
          type: "락커 (1)",
          price: this.memberInputState.membership_list.locker_list[0].subs_options.subs_price,
          dis: this.memberInputState.membership_list.locker_list[0].dis,
          pay_order: startIdx + 4,
          tab_idx: tabIdx,
          item_type: "락커/구독형/월구독료/" + mOpt + "/없음",
        },
        startIdx + 4,
        this.membershipIdx,
      );

      // this.editLockerProratePrice("push", lockerViewId);
      this.payInfoListAction(
        "push",
        lockerViewId + "(일할)",
        {
          type: this.memberInputState.tab_info.title + "락커(일할) (1)",
          price: this.memberInputState.membership_list.locker_list[0].subs_options.prorate_price,
          dis: this.memberInputState.membership_list.locker_list[0].subs_options.prorate_dis,
          pay_order: startIdx + 4,
          tab_idx: tabIdx,
          item_type: "락커/구독형/일할금액/" + mOpt + "/없음",
        },
        startIdx + 4,
        this.membershipIdx,
      );
      this.payInfoListAction(
        "push",
        lockerViewId + "(선납)",
        {
          type: this.memberInputState.tab_info.title + "락커(선납) (1)",
          price: this.memberInputState.membership_list.locker_list[0].subs_options.pre_price,
          dis: this.memberInputState.membership_list.locker_list[0].subs_options.pre_dis,
          pay_order: startIdx + 4,
          tab_idx: tabIdx,
          item_type: "락커/구독형/선납금/" + mOpt + "/없음",
        },
        startIdx + 4,
        this.membershipIdx,
      );
    }
  };

  editDis = (item) => {
    switch (item) {
      case "회원권":
        this.preMbshpPerDay =
          (this.memberInputState.membership_list.mbshp.subs_options.subs_price -
            this.memberInputState.membership_list.mbshp.subs_options.subs_dis) /
          this.month_last_day;
        this.memberInputState.membership_list.mbshp.subs_options.prorate_price = Math.floor(
          this.preMbshpPerDay * this.prorate_day,
        );

        this.editMbshpProratePrice("discount", "회원권");
        return this.memberInputState.membership_list.mbshp.subs_options.prorate_price;
      case "운동복":
        this.preClothPerDay =
          (this.memberInputState.membership_list.cloth.subs_options.subs_price -
            this.memberInputState.membership_list.cloth.subs_options.subs_dis) /
          this.month_last_day;
        this.memberInputState.membership_list.cloth.subs_options.prorate_price = Math.floor(
          this.preClothPerDay * this.prorate_day,
        );

        this.editClothProratePrice("discount", "운동복");
        return this.memberInputState.membership_list.cloth.subs_options.prorate_price;
      case "GX":
        this.preGxPerDay =
          (this.memberInputState.membership_list.gx.subs_options.subs_price -
            this.memberInputState.membership_list.gx.subs_options.subs_dis) /
          this.month_last_day;
        this.memberInputState.membership_list.gx.subs_options.prorate_price = Math.floor(
          this.preGxPerDay * this.prorate_day,
        );

        this.editGxProratePrice("discount", "GX"); //ydh
        return this.memberInputState.membership_list.gx.subs_options.prorate_price;
      default:
        return 0;
    }
  };

  editMbshpProratePrice = (act, event) => {
    const eventName = this.memberInputState.tab_info.title + event;
    const tabIdx = this.memberInputState.tab_info.idx;
    const startIdx = this.membershipIdx * 10;
    const mOpt = this.memberInputState.purchase_item_list.item_info.mbshp_option;

    // console.log(eventName);
    // console.log(tabIdx);
    // console.log(startIdx);
    // console.log(act);

    // 여기서 처음 추가됨
    this.payInfoListAction(
      act,
      eventName + "(일할)",
      {
        type: eventName + "(일할)",
        price: this.memberInputState.membership_list.mbshp.subs_options.prorate_price,
        dis: this.memberInputState.membership_list.mbshp.subs_options.prorate_dis,
        pay_order: startIdx + 1,
        tab_idx: tabIdx,
        item_type: "회원권/구독형/일할금액/" + mOpt + "/없음",
      },
      startIdx + 1,
      this.membershipIdx,
    );
  };

  editClothProratePrice = (act, event) => {
    const mOpt = this.memberInputState.purchase_item_list.item_info.mbshp_option;
    const eventName = this.memberInputState.tab_info.title + event;
    const tabIdx = this.memberInputState.tab_info.idx;
    const startIdx = this.membershipIdx * 10;

    this.payInfoListAction(
      act,
      eventName + "(일할)",
      {
        type: eventName + "(일할)",
        price: this.memberInputState.membership_list.cloth.subs_options.prorate_price,
        dis: this.memberInputState.membership_list.cloth.subs_options.prorate_dis,
        pay_order: startIdx + 2,
        tab_idx: tabIdx,
        item_type: "운동복/구독형/일할금액/" + mOpt + "/없음",
      },
      startIdx + 2,
      this.membershipIdx,
    );
  };

  editGxProratePrice = (act, event) => {
    const eventName = this.memberInputState.tab_info.title + event;
    const tabIdx = this.memberInputState.tab_info.idx;
    const startIdx = this.membershipIdx * 10;
    const mOpt = this.memberInputState.purchase_item_list.item_info.mbshp_option;

    this.payInfoListAction(
      act,
      eventName + "(일할)",
      {
        type: eventName + "(일할)",
        price: this.memberInputState.membership_list.gx.subs_options.prorate_price,
        dis: this.memberInputState.membership_list.gx.subs_options.prorate_dis,
        pay_order: startIdx + 3,
        tab_idx: tabIdx,
        item_type: "GX/구독형/일할금액/" + mOpt + "/없음",
      },
      startIdx + 3,
      this.membershipIdx,
    );
  };

  // 수정필요!!
  editLockerProratePrice = (act, lockerViewId) => {
    const eventName = this.memberInputState.tab_info.title + lockerViewId;
    const tabIdx = this.memberInputState.tab_info.idx;
    const startIdx = this.membershipIdx * 10;
    const mOpt = this.memberInputState.purchase_item_list.item_info.mbshp_option;

    this.payInfoListAction(
      act,
      lockerViewId + "(일할)",
      {
        type:
          this.memberInputState.tab_info.title + "락커(일할) (" + (this.membershipIdx + 1) + ")",
        price: this.memberInputState.membership_list.gx.subs_options.prorate_price,
        dis: this.memberInputState.membership_list.gx.subs_options.prorate_dis,
        pay_order: startIdx + 4,
        tab_idx: tabIdx,
        item_type: "락커/구독형/일할금액/" + mOpt + "/없음",
      },
      startIdx + 4,
      this.membershipIdx,
    );
  };
}
