import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LockerView from "./LockerView";
import LockerModalIndex from "./Modal/LockerModalIndex";
import { lockerAllInfoInit } from "./Module/reducers/init_state";
import LockerOverallStatus from "./Section1/LockerOverallStatus";

const LockerWrapper = () => {
  const storesDispatch = useDispatch();
  const [branchList, setBranchList] = useState({ branch_list: [], branch_name_list: [] });
  const [selectedBranch, setSelectedBranch] = useState({ branch_id: "", branch_name: "" });
  const [lockerStatus, setLockerStatus] = useState({
    almost: 0,
    broken: 0,
    expired: 0,
    ready: 0,
    total: 0,
    un_passwd: 0,
    used: 0,
  });

  //섹션2 구락리
  const [lockerAllInfo, setLockerAllInfo] = useState(_.cloneDeep(lockerAllInfoInit));

  //지점리스트 GET (섹션1)
  const getBranchData = async () => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_BRANCH_SERVER + "/locker/branch_selector",
        {
          params: {},
        },
      );
      if (!restEmpty(res.data.data)) {
        const result = res.data.data;
        const firstBranch = result.branch_list[0];
        setBranchList(res.data.data);
        setSelectedBranch(firstBranch);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  //지점락커현황 GET (섹션1)
  const getBranchStatusInfo = async (branchId) => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_BRANCH_SERVER + "/locker/status/summary",
        {
          params: {
            branch_id: branchId,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setLockerStatus(res.data.data.count_info);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  //구락리 GET (섹션2)
  const getFloorZoneAllList = async (branchId, floorId, zoneId) => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_BRANCH_SERVER + "/locker/zone/list",
        {
          params: {
            branch_id: branchId,
            floor_id: floorId,
            zone_id: zoneId,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setLockerAllInfo(res.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  //구락리 갱신
  const refreshView = (branchId, floodId, zoneId) => {
    getFloorZoneAllList(branchId, floodId, zoneId);
  };

  //첫 랜더링때, 지점리스트 GET
  useEffect(() => {
    getBranchData();
  }, []);

  //지점바뀌면 화면 모두갱신(섹션1) *섹션2,3은 각각 파일에서 갱신
  useEffect(() => {
    if (selectedBranch.branch_name) {
      getBranchStatusInfo(selectedBranch.branch_id); //섹션1 갱신
      setLockerAllInfo(_.cloneDeep(lockerAllInfoInit)); //섹션2 구락리 초기화
    }
  }, [selectedBranch.branch_id]);

  //1.글로벌 모달 열기
  const openModal = (select, param, doneCallback) => {
    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,
        Content: (
          <LockerModalIndex modalSelect={select} modalParam={param} onModalDone={doneCallback} />
        ),
      }),
    );
  };
  //1-1.글로벌 모달 열기
  const onClickOpenModal = (order, param, onModalDone) => {
    if (order !== "") {
      if (dataEmpty(onModalDone)) {
        openModal(order, param, storesDispatch(globalModalOff()));
      } else {
        openModal(order, param, onModalDone);
      }
    }
  };

  return (
    <>
      {/* 락커 섹션1 */}
      <LockerOverallStatus
        onClickOpenModal={onClickOpenModal}
        branchList={branchList}
        selectedBranch={selectedBranch}
        setSelectedBranch={setSelectedBranch}
        lockerStatus={lockerStatus}
        refreshView={refreshView} //섹션2 구락리 갱신
      />

      {/* 락커 섹션2,3 */}
      <LockerView
        onClickOpenModal={onClickOpenModal}
        selectedBranch={selectedBranch}
        lockerAllInfo={lockerAllInfo}
        getBranchStatusInfo={getBranchStatusInfo} //섹션1 갱신
        refreshView={refreshView} //섹션2 구락리 갱신
      />
    </>
  );
};

export default LockerWrapper;
