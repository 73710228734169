import { simpleAlert } from "components/CommonLib/CommonLib";
import FileUploadIcon from "components/LabelInput/FileUploadIcon";
import Label from "components/LabelInput/Label";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useDispatch } from "react-redux";

export const IconPostponeDocInfo = (props) => {
  //docList : 서버에서 GET 받아온 contract_doc_list
  //uploadDoneCallback: 파일 업로드 이후 실행할 함수
  //onlyOne: 한개파일만 업로드 할때 (UI 분기처리용)

  // console.log(props);
  const storesDispatch = useDispatch();
  return (
    <div>
      <div>
        {/* {props.docList.map((el, idx) => ( */}
        <FileUploadIcon
          // key={idx}
          // marginTop="0.5rem"
          labelWidth={"5rem"}
          textBoxWidth={props.textBoxWidth}
          textMarginRight={"0rem"}
          // labelText={el.doc_name}
          labelText={"문서 첨부"}
          onUploadFile={(file) => {
            // console.log("file", file);
            updateContractAPi(storesDispatch, props.memberId, file);
          }}
          hideDownload
        />
        {/* ))} */}
      </div>
    </div>
  );
};

export const updateContractAPi = async (storesDispatch, memberId, file) => {
  try {
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    if (memberId === "") {
      return;
    }

    const formData = new FormData();
    formData.append("member_id", memberId);
    formData.append("the_file", file);

    const res = await HttpInstance.post(
      process.env.REACT_APP_USER_SERVER + "/change/long_postpone/document",
      formData,
      config,
    );
    if (res.data.msg) {
      simpleAlert(storesDispatch, "ERROR", res.data.msg);
    } else {
      //성공
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          reqConfirm: true,
          bodyText: (
            <>
              <Label labelText="완료 되었습니다." />
            </>
          ),
          onBtnOk: () => {
            // navigate(getNavigatorPath(stateData));
            console.log("??");
            // navigate("/tablet_setting");
          },
        }),
      );
    }
  } catch (err) {
    console.log(err);
    simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
  }
};
