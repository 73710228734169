import IconButton from "components/Button/IconButton";
import { contentTypeJson, restEmpty, simpleAlert, SubLine } from "components/CommonLib/CommonLib";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOff, globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PostponeContents from "./PostponeContents";
import PostponeHist from "./PostponeHist";

const PostponeView = ({
  viewState,
  popLongPostponeModal,
  popChangePaperDueModal,
  refreshStore,
  postponeHist,
  branchId,
  mbshpType,
  curPage,
  setCurPage,
  totalPage,
  getPostponeHist,
  isAdmin,
}) => {
  const labelWidthLong = "7.5rem";
  const labelWidth = "5.5rem";
  const textBoxWidth = "12rem";
  const storesDispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  //연기신청 POST
  const registerPostpone = async () => {
    try {
      const formData = new FormData();
      formData.append("new_info", JSON.stringify(viewState));

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_USER_SERVER + "/change/postpone_update",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        refreshStore(); //연기화면 갱신
        storesDispatch(globalAlertOff());
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  //연기신청 팝업 종료일 GET
  const getPostponeToDate = async () => {
    try {
      setLoading(true);

      const res = await HttpInstance.get(
        process.env.REACT_APP_USER_SERVER + "/change/postpone_view/to_date",
        {
          params: {
            mbshp_type: mbshpType,
            branch_id: branchId,
            from_date: viewState.contents.active_postpone.from_date,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        //성공 => 받은 종료일을 label에 넣어서 보여주기
        const result = res.data.data;
        const stringArr = result.split("\n");
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "연기 신청",
            bodyText: (
              <div style={{ display: "flex", flexDirection: "column", gap: "0.3rem" }}>
                <Label labelText={stringArr[0]} />
                <Label labelText={stringArr[1]} />
              </div>
            ),
            onBtnYes: () => {
              registerPostpone();
            },
            onBtnNo: () => {},
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  const requestPostponeNormal = (mode) => {
    if (!viewState.contents.active_postpone.from_date) {
      simpleAlert(storesDispatch, "알림", "연기 시작일을 설정해주세요.");
    } else {
      if (mode === "normal") {
        //그냥 연기신청
        getPostponeToDate();
      } else {
        //장기연기신청모달 팝
        popLongPostponeModal(viewState.contents.active_postpone.from_date);
      }
    }
  };

  // 연기 시작일 Default값을 limit_from_date(최저 시작 가능일)로 세팅
  useEffect(() => {
    if (restEmpty(viewState.contents.active_postpone.from_date)) {
      viewState.contents.active_postpone.from_date = viewState.permission.limit_from_date;
    }
  }, [viewState.permission.limit_from_date, viewState.contents.active_postpone.from_date]);

  const getMinDate = (limitFromDate) => {
    if (isAdmin) {
      return undefined;
    }
    return limitFromDate;
  };

  return (
    <div>
      {loading && <Loading />}

      <div style={{ display: "flex" }}>
        <div style={{ width: "80.437rem" }}>
          <div style={{ display: "flex" }}>
            <IconButton type="border" />
            <div style={{ width: "0.5rem" }}></div>
            <SubTitle titleText={"회원권 연기 정보 "} />
          </div>
          <div style={{ marginLeft: "2rem" }}>
            <div
              style={{
                display: "flex",
              }}
            >
              <TextField
                labelWidth={labelWidthLong}
                textBoxWidth={textBoxWidth}
                textMarginRight={"0.25rem"}
                labelText="연기 잔여 횟수"
                endAdornment="회"
                isNumber={true}
                defaultValue={
                  viewState.contents.usage.total_postpone_cnt -
                  viewState.contents.usage.used_postpone_cnt
                }
                disabled
              />
              <TextField
                marginLeft={"1.15rem"}
                labelWidth={labelWidth}
                textBoxWidth={textBoxWidth}
                textMarginRight={"0.25rem"}
                labelText="누적 연기 일수"
                endAdornment="일"
                isNumber={true}
                defaultValue={viewState.contents.usage.acc_postpone_day}
                disabled
              />
            </div>
          </div>
          <SubLine />

          {viewState.permission.is_postpone_ing || viewState.permission.is_long_postpone_ing ? (
            <PostponeContents
              viewState={viewState}
              popChangePaperDueModal={popChangePaperDueModal}
              refreshStore={refreshStore}
              getPostponeHist={getPostponeHist}
            />
          ) : (
            <Label labelText="진행중인 연기가 없습니다." marginTop={"2rem"} />
          )}

          <div style={{ display: "flex", marginTop: "1rem" }}>
            <IconButton type="border" />
            <div style={{ width: "0.5rem" }}></div>
            <SubTitle titleText={"연기 신청"} />
          </div>
          <div style={{ marginLeft: "2rem" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <DatePicker
                labelWidth={"7.5rem"}
                minWidth={"17.75rem"}
                labelText="연기 시작일"
                textMarginRight="0.25rem"
                disabled={!viewState.permission.can_postpone}
                defaultValue={viewState.contents.active_postpone.from_date}
                onChangeCallback={(e) => {
                  viewState.contents.active_postpone.from_date = e;
                }}
                minDate={getMinDate(viewState.permission.limit_from_date)}
                maxDate={viewState.permission.limit_to_date}
              />
              <div style={{ display: "flex", gap: "0.3rem" }}>
                <NormalBtn
                  name="연기 신청"
                  width="5.75rem"
                  onClick={() => requestPostponeNormal("normal")}
                  disabled={!viewState.permission.can_postpone}
                />
                <NormalBtn
                  name="장기연기 신청"
                  width="7.35rem"
                  onClick={() => requestPostponeNormal("long")}
                  disabled={!viewState.permission.can_postpone}
                />
              </div>

              <Label
                labelText="※ 타 회원권이 연기 중인 경우, 신청이 불가능합니다."
                marginLeft="2rem"
                fontSize="0.825rem"
                color="#de0909"
              />
            </div>
          </div>

          <SubLine />
        </div>
      </div>
      {/* 연기이력 테이블*/}
      <PostponeHist
        curPage={curPage}
        postponeHist={postponeHist}
        setCurPage={setCurPage}
        totalPage={totalPage}
      />
    </div>
  );
};
export default PostponeView;
