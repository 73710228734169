import { useQuery } from "@tanstack/react-query";
import { getDoorHistList } from "apis/branch/access_monitor_api";
import { simpleAlert } from "components/CommonLib/CommonLib";
import NormalBtn from "components/NewButton/NormalBtn";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { utils, writeFile } from "xlsx";
import { useAccessMonitoringStore } from "../modules/store/monitoringManageState";
import DoorHistSearchCard from "./DoorHistSearchCard";
import DoorHistSearchResult from "./DoorHistSearchResult";

const DoorHistView = ({ hqBranchDoorCombo }) => {
  const navigate = useNavigate();
  const storesDispatch = useDispatch();
  // 전역 상태 관리
  const { setLoading, searchParams, setPage, page, resetSearchParams } =
    useAccessMonitoringStore().returnStoreByType("doorHist");

  // 페이지 상태 관리
  const [searchResult, setSearchResult] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [isExcel, setIsExcel] = useState(false);

  // API 상태 관리
  const [isGetDoorHistList, setIsGetDoorHistList] = useState(false);
  const queryData = { page: page, searchParams: searchParams, isExcel: isExcel };

  // [GET] 문열림 사용 이력 리스트 가져오기
  const { isFetching, data: doorHist } = useQuery({
    queryKey: ["doorHist", queryData],
    queryFn: () => getDoorHistList(queryData),
    enabled: isGetDoorHistList,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setIsGetDoorHistList(false);
      setLoading(false);
    },
  });

  /** isExcel: 엑셀 다운로드 여부, page: 페이지네이션 */
  const onClickSearch = async (isExcel, page) => {
    setPage(page);
    setIsExcel(isExcel);
    // 문열림 사용 기능 리스트 받아오기
    setIsGetDoorHistList(true);
  };

  //검색결과를 엑셀파일로 다운로드 받는 함수
  const exportToExcel = (data) => {
    //data는 검색POST 요청 결과 res.data.data
    const newData = [];
    for (let i = 0; i < data.length; i++) {
      const row = {};
      row.일시 = data[i].access_date;
      row.본부명 = data[i].hq_info.hq_name;
      row.지점명 = data[i].branch_info.branch_name;
      row.출입문명 = data[i].door_info.door_name;
      row.직원명 = data[i].card_no;
      row.사번 = data[i].cur_user.sabun;
      newData.push(row);
    }

    const worksheet = utils.json_to_sheet(newData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, "문열림사용이력.xlsx");
  };

  // API 받아온 데이터 세팅
  useEffect(() => {
    // 문열림 사용 이력 데이터
    if (doorHist) {
      if (isExcel) {
        exportToExcel(doorHist.data.data);
      } else {
        setSearchResult(doorHist.data.data);
        setTotalPage(doorHist.data.total_page);
      }
    }
  }, [doorHist, isExcel]);

  // 로딩 상태
  useEffect(() => {
    if (isFetching) {
      setLoading(isFetching);
    }
  }, [isFetching]);

  // 첫 페이지 진입 시 검색 결과를 가져오기
  useEffect(() => {
    setIsGetDoorHistList(true);
  }, []);

  return (
    <div>
      <SearchCard
        searchParams={searchParams}
        onClickSearch={onClickSearch}
        resetSearchParams={resetSearchParams}
        hqBranchDoorCombo={hqBranchDoorCombo}
      />

      <SearchResultCard
        searchResult={searchResult}
        navigate={navigate}
        onClickSearch={onClickSearch}
        curPage={page}
        totalPage={totalPage}
      />
    </div>
  );
};
export default DoorHistView;

// SearchCard
const SearchCard = ({ resetSearchParams, onClickSearch, hqBranchDoorCombo }) => {
  return (
    <div>
      <DoorHistSearchCard onClickSearch={onClickSearch} hqBranchDoorCombo={hqBranchDoorCombo} />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "0.3rem",
        }}
      >
        <NormalBtn
          name="초기화"
          theme="white"
          width="6.25rem"
          onClick={() => resetSearchParams()}
        />
        <NormalBtn
          name="검색"
          width="6.25rem"
          onClick={() => {
            onClickSearch(false, 1);
          }}
        />
      </div>
    </div>
  );
};

const SearchResultCard = ({ searchResult, navigate, onClickSearch, curPage, totalPage }) => {
  return (
    <div style={{ marginTop: "1.2rem" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "0.5rem",
        }}
      >
        <NormalBtn
          name="엑셀 다운로드"
          theme="green"
          onClick={() => {
            onClickSearch(true, 1);
          }}
        />
      </div>
      <DoorHistSearchResult
        onClickSearch={onClickSearch}
        searchResult={searchResult}
        curPage={curPage}
        totalPage={totalPage}
      />
    </div>
  );
};
