import { restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { registerViewInitState } from "../Common/Module/reducers/view_init";
import AddPayOptionWrapper from "./AddPayOptionWrapper";

const AddPayRoot = () => {
  const { state } = useLocation();
  const storesDispatch = useDispatch();

  const [memberInputState, setMemberInputState] = useState(_.cloneDeep(registerViewInitState));

  useEffect(() => {
    HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member/register_view", {
      params: {
        member_id: state.member_id,
        reg_date: state.reg_date,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          // console.log(_.cloneDeep(res.data.data)); // test
          setMemberInputState(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });

    if (state.memberBaseInfoState.member_type === "고객") {
      HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member/get_member_info", {
        params: {
          member_id: state.member_id,
        },
      })
        .then((res) => {
          if (!restEmpty(res.data.data)) {
            state.memberBaseInfoState = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        });
    }
  }, [state.member_id]);

  return (
    <PageFrame
      menuActive="설정"
      parentMenu={{ index: true }}
      children={
        <div>
          <PageTitle titleText="추가결제" />
          <AddPayOptionWrapper
            memberInputState={memberInputState}
            branch_id={state.branch_id}
            memberBaseInfoState={state.memberBaseInfoState}
            initRegDate={state.reg_date}
          />
        </div>
      }
    />
  );
};
export default AddPayRoot;
