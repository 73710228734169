import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const RegisterPhoneCheckModal = ({ modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [resultData, setResultData] = useState([
    {
      base_info: {
        phone: "",
      },
      branch_info: {
        branch_id: "",
        branch_name: "",
      },
      member_info: {
        member_id: "",
        member_name: "",
        phone: modalParam.phone,
      },
      member_type: "",
    },
  ]);

  const findMemberList = new findMemberInfoTable();

  useEffect(() => {
    setLoading(true);
    HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member/search_by_phone", {
      params: { phone: modalParam.phone },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          const arr = [];
          arr.push(res.data.data);
          setResultData(arr);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onClickYesGuestBook = () => {
    resultData[0].is_remove = false;
    onModalDone(resultData[0]);
  };

  const onClickNoGuestBook = () => {
    simpleAlert(
      storesDispatch,
      "알림",
      "방명록 등록 완료 시 해당 회원의 연락처는 삭제됩니다.",
      false,
      undefined,
      () => {
        resultData[0].is_remove = true;
        onModalDone(resultData[0]);
      },
    );
  };

  // 양도 신규등록 시
  const onClickYesAssign = async () => {
    if (resultData[0].member_type === "고객") {
      simpleAlert(
        storesDispatch,
        "알림",
        "고객의 경우 회원 전환을 먼저 진행해주시기 바랍니다.\n회원정보 페이지로 이동하시겠습니까?",
        false,
        undefined,
        () => {
          modalParam.navigate("/member_info/" + resultData[0].member_info.member_id);
        },
      );
    } else if (resultData[0].member_type === "회원") {
      const findIdx = modalParam.avail_branch_list.findIndex(
        (x) => x === resultData[0].branch_info.branch_id,
      );
      if (findIdx === -1) {
        const prevMemberInfo = await getPrevMemberInfo();

        simpleAlert(
          storesDispatch,
          "알림",
          "타지점 회원의 경우 지점변경을 먼저 진행해주시기 바랍니다.\n지점변경 페이지로 이동하시겠습니까?",
          false,
          undefined,
          () => {
            modalParam.navigate("/change_branch", { state: prevMemberInfo });
          },
        );
      } else {
        simpleAlert(
          storesDispatch,
          "알림",
          "이미 등록되어 있는 회원의 경우 양수자 검색 후 선택하여 주십시오.",
        );
      }
    }

    onModalDone("cancel");
  };

  const onClickYes = async () => {
    if (resultData[0].member_type === "고객") {
      simpleAlert(
        storesDispatch,
        "알림",
        "고객의 경우 회원 전환을 먼저 진행해주시기 바랍니다.\n회원정보 페이지로 이동하시겠습니까?",
        false,
        undefined,
        () => {
          modalParam.navigate("/member_info/" + resultData[0].member_info.member_id);
        },
      );
    } else if (modalParam.branch_info.branch_name === resultData[0].branch_info.branch_name) {
      simpleAlert(
        storesDispatch,
        "알림",
        "기존 회원은 추가결제를 진행해 주시기 바랍니다.\n해당 회원의 회원정보 페이지로 이동하시겠습니까?",
        false,
        undefined,
        () => {
          modalParam.navigate("/member_info/" + resultData[0].member_info.member_id);
        },
      );
    } else {
      const prevMemberInfo = await getPrevMemberInfo();

      simpleAlert(
        storesDispatch,
        "알림",
        "타지점 회원의 경우 지점변경을 먼저 진행해주시기 바랍니다.\n지점변경 페이지로 이동하시겠습니까?",
        false,
        undefined,
        () => {
          modalParam.navigate("/change_branch", { state: prevMemberInfo });
        },
      );
    }
    onModalDone("연락처중복", "");
  };

  const onClickNo = () => {
    simpleAlert(
      storesDispatch,
      "알림",
      "등록 완료 시 해당 회원의 연락처는 삭제됩니다.",
      false,
      undefined,
      () => {
        onModalDone("연락처중복", resultData[0].member_info.member_id, modalParam.phone);
      },
    );
  };

  const getPrevMemberInfo = async () => {
    let prevMemberInfo;
    setLoading(true);
    await HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member/get_member_info", {
      params: {
        member_id: resultData[0].member_info.member_id,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          prevMemberInfo = res.data.data;
          prevMemberInfo.member_info = {
            member_id: prevMemberInfo.member_id,
            member_name: prevMemberInfo.base_info.personal_info.member_name,
            member_no: prevMemberInfo.base_info.personal_info.member_no,
          };
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      })
      .finally(() => {
        setLoading(false);
      });

    return prevMemberInfo;
  };

  const descText_1 = modalParam.is_guestbook
    ? "방명록 등록 회원(고객) 정보가 아래와 동일합니까?"
    : "가입하려는 회원 정보가 아래와 동일합니까?";

  return (
    <div
      style={{
        width: "43.719rem",
      }}
    >
      {loading && <Loading />}
      <div
        style={{
          textAlign: "center",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <Label labelText={descText_1} justifyContent="center" />
        <Label
          labelText="만약 동일인이 아닌 경우('아니오' 선택 시) 아래 회원(또는 고객)의 연락처 정보는 삭제됩니다."
          justifyContent="center"
        />
      </div>

      <div style={{ marginTop: "1rem" }}>
        <CustomTable
          columns_head={findMemberList.columns_head}
          table_title={findMemberList.table_title}
          rest_call={findMemberList.get_data_from_rest}
          row_render={findMemberList.create_table}
          rest_data={resultData}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "1.5rem",
        }}
      >
        <Button
          className={clsx({
            [btnStyleClass.btnRootRaw]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={() => {
            if (modalParam.is_guestbook) {
              onClickYesGuestBook();
            } else if (modalParam.is_assign) {
              onClickYesAssign();
            } else {
              onClickYes();
            }
          }}
        >
          예
        </Button>

        <Button
          className={clsx({
            [btnStyleClass.btnRootRaw]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          style={{ marginLeft: "1rem" }}
          onClick={() => {
            if (modalParam.is_guestbook) {
              onClickNoGuestBook();
            } else {
              onClickNo();
            }
          }}
        >
          아니오
        </Button>
      </div>
    </div>
  );
};
export default RegisterPhoneCheckModal;

class findMemberInfoTable {
  table_title = "";
  btnStyleClass = btnStyles();

  create_table = (data) => {
    return (
      <CustomRow style={{ width: "100%" }}>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_name}</CustomTableContents>
        <CustomTableContents>{data.member_type}</CustomTableContents>
        <CustomTableContentsEND>{data.base_info.phone}</CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "지점명", width: "30%" },
    { title: "회원명", width: "30%" },
    { title: "회원구분", width: "15%" },
    { title: "연락처", width: "25%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
