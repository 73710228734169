import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import { useDispatch } from "react-redux";

const ClassRecordResultTable = ({ openModal, searchResult, curPage, totalPage, onClickSearch }) => {
  const storesDispatch = useDispatch();

  const onClickOpenModal = (data) => {
    openModal(data);
  };

  const searchResultList = new resultTable(onClickOpenModal);

  const doSearch = (page) => {
    onClickSearch(page);
  };

  return (
    <div>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
      />
      <CustomPagination curPage={curPage} totalCnt={totalPage} doSearch={doSearch} />
    </div>
  );
};
export default ClassRecordResultTable;

class resultTable {
  table_title = "";
  onClickOpenModal = null;

  constructor(onClickOpenModal) {
    this.onClickOpenModal = onClickOpenModal;
  }

  getSessionType = (str) => {
    if (str === "기본 세션") {
      return "PT (기본 세션)";
    } else if (str === "서비스 세션") {
      return "PT (서비스 세션)";
    } else {
      return str;
    }
  };
  getIncentive = (data) => {
    if (data.is_paid_done) {
      return data.incentive.incentive;
    } else {
      return 0;
    }
  };
  create_table = (data, idx) => {
    return (
      <CustomRow>
        <CustomTableContents>
          {data.class_info.proceed_class.class_done_date +
            " " +
            data.class_info.proceed_class.class_done_time}
        </CustomTableContents>
        <CustomTableContents>
          {this.getSessionType(data.class_info.session_type)}
        </CustomTableContents>
        <CustomTableContents>{data.member_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_name}</CustomTableContents>
        <CustomTableContents $isNumber>
          {data.class_info.session_price.toLocaleString() + " 원"}
        </CustomTableContents>
        {/* <CustomTableContents>
          {data.class_info.pt_pay_out.payout_price.toLocaleString()}
        </CustomTableContents> */}
        {/* <CustomTableContents>{data.incentive.incentive.toLocaleString()}</CustomTableContents> */}
        {/* <CustomTableContents>{this.getIncentive(data).toLocaleString()}</CustomTableContents> */}
        <CustomTableContents>{data.class_info.staff_info.staff_name}</CustomTableContents>
        <CustomTableContents>{data.is_salaried_done ? "Y" : "N"}</CustomTableContents>
        {/* <CustomTableContents>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <NormalBtn
              name="상담자 변경"
              onClick={() => {
                this.onClickOpenModal();
              }}
            />
          </div>
        </CustomTableContents> */}
      </CustomRow>
    );
  };

  columns_head = [
    { title: "수업일시", width: "14%" },
    { title: "수업구분", width: "9%" },
    { title: "회원번호", width: "9%" },
    { title: "회원명", width: "10%" },
    { title: "회당 단가", width: "10%" },
    // { title: "실적금액", width: "10%" },
    { title: "실적 직원", width: "12%" },
    { title: "급여 승인여부", width: "7%" },
    // { title: "", width: "8%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
