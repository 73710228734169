export const memberInitState = {
  access: {
    access_method: "",
    card_info_id: "",
    card_no: 0,
  },
  active_info: {
    access_link_info: {
      last_access_date: "",
      link_info_total: 0,
      used_link_cnt: 0,
    },
    active_lockers: [
      {
        expiration_info: {
          date: "",
          reason: "",
        },
        family_info: {
          family_info_id: "",
          is_use: false,
          member_id: "",
          member_name: "",
        },
        from_date: "",
        locker_no: 0,
        locker_total_idx: 0,
        mbshp_locker_id: "",
        mbshp_status: "",
        membership_id: "",
        password: "",
        queue_id: "",
        to_date: "",
        zone_id: "",
        zone_name: "",
      },
    ],
    active_mbshp_status: "",
    active_membership: {
      daily_time: {
        end_time: "",
        from_time: "",
      },
      expiration_info: {
        date: "",
        reason: "",
      },
      family_info: {
        family_info_id: "",
        is_use: false,
        member_id: "",
        member_name: "",
      },
      from_date: "",
      is_new: false,
      is_use: false,
      item_name: "",
      mbshp_option: "",
      mbshp_type: "",
      membership_id: "",
      pass_time: {
        end_time: "",
        from_time: "",
      },
      remain_pass_cnt: 0,
      to_date: "",
      use_cloth: false,
      use_gx: false,
    },
    active_ot: {
      ot_detail_id: "",
    },
    active_postpone: {
      approve_status: "",
      doc_info: {
        belong_type: "",
        doc_group_id: "",
        doc_id: "",
        doc_name: "",
        file_due_date: "",
        file_name: "",
        is_use: false,
        is_valid: false,
        member_info: {
          member_id: "",
          member_name: "",
          member_no: "",
        },
        purchase_code: "",
        reason: "",
        reg_info: {
          created_at: "",
          reg_id: "",
          reg_name: "",
          upd_id: "",
          upd_name: "",
          updated_at: "",
        },
        staff_info: {
          staff_id: "",
          staff_name: "",
          staff_pos: "",
          staff_rank: "",
        },
        url_path: "",
      },
      doc_status: "",
      from_date: "",
      mbshp_to_date: "",
      member_id: "",
      membership_id: "",
      postpone_day: 0,
      postpone_id: "",
      postpone_type: "",
      reg_info: {
        created_at: "",
        reg_id: "",
        reg_name: "",
        upd_id: "",
        upd_name: "",
        updated_at: "",
      },
      remarks: "",
      stop_date: "",
      to_date: "",
    },
    active_pt: {
      pt_detail_id: "",
    },
    active_pt_status: "",
    last_contract_to_date: "",
    last_mbshp_to_date: "",
    last_subs_to_date: "",
    member_id: "",
    member_type: "",
    regular_pay_method: {
      active_subs_pay_info: {
        purchase_code: "",
        purchase_id: "",
      },
      contract_from_date: "",
      contract_to_date: "",
      current_num: 0,
      extra_price: 0,
      last_paid_info: {
        pay_date: "",
        reg_info: {
          created_at: "",
          reg_id: "",
          reg_name: "",
          upd_id: "",
          upd_name: "",
          updated_at: "",
        },
        remark: "",
        seq_info: "",
        status: "",
      },
      pay_date: "",
      payment_method: {
        card_no: "",
        company: "",
        exp_month: 0,
        exp_year: 0,
        id_no: "",
        is_company: "",
        name: "",
        pay_method: "",
        phone: "",
      },
      payment_plan_list: [
        {
          num: 0,
          pay_date: "",
          price: 0,
        },
      ],
      price_next_month: 0,
      reg_info: {
        created_at: "",
        reg_id: "",
        reg_name: "",
        upd_id: "",
        upd_name: "",
        updated_at: "",
      },
      relation: "",
      subs_period_month: 0,
    },
    remain_day: 0,
    remain_ot_cnt: 0,
    remain_pt_cnt: 0,
    search_param: {
      access: {
        access_method: "",
        card_no: 0,
      },
      base_info: {
        agree_marketing: false,
        agree_personal: false,
        birth: "",
        birth_info: {
          day: 0,
          month: 0,
          year: 0,
        },
        gender: "",
        phone: "",
      },
      branch_info: {
        branch_id: "",
        branch_name: "",
      },
      hq_info: {
        hq_id: "",
        hq_name: "",
      },
      mbshp_search_param: {
        is_mbshp_assign: false,
        is_mbshp_refund: false,
        mbshp_purchase_cnt: 0,
      },
      member_info: {
        member_id: "",
        member_name: "",
        member_no: "",
      },
      membership_list: [
        {
          cloth: {
            contract_from_date: "",
            contract_to_date: "",
            from_date: "",
            is_use: false,
            to_date: "",
          },
          expiration_info: {
            date: "",
            reason: "",
          },
          gx: {
            contract_from_date: "",
            contract_to_date: "",
            from_date: "",
            is_use: false,
            to_date: "",
          },
          is_new: false,
          item_name: "",
          locker: {
            contract_from_date: "",
            contract_to_date: "",
            from_date: "",
            is_use: false,
            to_date: "",
          },
          locker_list: [
            {
              contract_from_date: "",
              contract_to_date: "",
              from_date: "",
              is_use: false,
              to_date: "",
            },
          ],
          mbshp: {
            contract_from_date: "",
            contract_to_date: "",
            from_date: "",
            is_use: false,
            to_date: "",
          },
          mbshp_option: "",
          mbshp_status: "",
          mbshp_type: "",
          membership_id: "",
          paid_done_date: "",
          service_status: "",
          subs_options: {
            auto_payment_cancel: false,
            contract_from_date: "",
            contract_to_date: "",
            payment_done_list: [
              {
                num: 0,
                pay_date: "",
                price: 0,
              },
            ],
            subs_paid_num: 0,
            subs_paid_total_price: 0,
            subs_total_price: 0,
          },
        },
      ],
      membership_list_data: {
        cloth: {
          contract_from_date: "",
          contract_to_date: "",
          from_date: "",
          is_use: false,
          to_date: "",
        },
        expiration_info: {
          date: "",
          reason: "",
        },
        gx: {
          contract_from_date: "",
          contract_to_date: "",
          from_date: "",
          is_use: false,
          to_date: "",
        },
        is_new: false,
        item_name: "",
        locker: {
          contract_from_date: "",
          contract_to_date: "",
          from_date: "",
          is_use: false,
          to_date: "",
        },
        locker_list: [
          {
            contract_from_date: "",
            contract_to_date: "",
            from_date: "",
            is_use: false,
            to_date: "",
          },
        ],
        mbshp: {
          contract_from_date: "",
          contract_to_date: "",
          from_date: "",
          is_use: false,
          to_date: "",
        },
        mbshp_option: "",
        mbshp_status: "",
        mbshp_type: "",
        membership_id: "",
        paid_done_date: "",
        service_status: "",
        subs_options: {
          auto_payment_cancel: false,
          contract_from_date: "",
          contract_to_date: "",
          payment_done_list: [
            {
              num: 0,
              pay_date: "",
              price: 0,
            },
          ],
          subs_paid_num: 0,
          subs_paid_total_price: 0,
          subs_total_price: 0,
        },
      },
      ot_list: [
        {
          cur_manager: {
            assign_date: "",
            is_assigned: false,
            is_resigned: false,
            staff_info: {
              staff_id: "",
              staff_name: "",
              staff_pos: "",
              staff_rank: "",
            },
          },
          ot_detail_id: "",
          ot_info: {
            class_status: "",
            comment: "",
            is_refunded: false,
            is_suggest_pt: false,
            latest_contact: "",
            latest_reserv_date: "",
            mbshp_paid_date: "",
            ot_session_cnt: 0,
            proceed_class_lock: false,
            remain_session_cnt: 0,
            root_status: "",
            service_status: "",
            suggest_date: "",
          },
        },
      ],
      ot_search_param: {
        is_ot_assign: false,
        is_ot_refund: false,
        ot_info_sum: {
          base_session_cnt: 0,
          class_status: "",
          comment: "",
          is_refunded: false,
          item_name: "",
          join_type: "",
          latest_class_date: "",
          latest_contact: "",
          latest_reserv_date: "",
          proceed_class_lock: false,
          pt_cancel_cnt: 0,
          pt_id: "",
          pt_postpone_cnt: 0,
          pt_used_cnt: 0,
          purchase_date: "",
          remain_session_cnt: 0,
          remarks: "",
          root_status: "",
          service_session_cnt: 0,
          service_status: "",
          session_cnt: 0,
        },
      },
      pt_list: [
        {
          cur_manager: {
            assign_date: "",
            is_assigned: false,
            is_resigned: false,
            staff_info: {
              staff_id: "",
              staff_name: "",
              staff_pos: "",
              staff_rank: "",
            },
          },
          pt_detail_id: "",
          pt_info: {
            base_session_cnt: 0,
            class_status: "",
            comment: "",
            is_refunded: false,
            item_name: "",
            join_type: "",
            latest_class_date: "",
            latest_contact: "",
            latest_reserv_date: "",
            proceed_class_lock: false,
            pt_cancel_cnt: 0,
            pt_id: "",
            pt_postpone_cnt: 0,
            pt_used_cnt: 0,
            purchase_date: "",
            remain_session_cnt: 0,
            remarks: "",
            root_status: "",
            service_session_cnt: 0,
            service_status: "",
            session_cnt: 0,
          },
        },
      ],
      pt_list_data: {
        cur_manager: {
          assign_date: "",
          is_assigned: false,
          is_resigned: false,
          staff_info: {
            staff_id: "",
            staff_name: "",
            staff_pos: "",
            staff_rank: "",
          },
        },
        pt_detail_id: "",
        pt_info: {
          base_session_cnt: 0,
          class_status: "",
          comment: "",
          is_refunded: false,
          item_name: "",
          join_type: "",
          latest_class_date: "",
          latest_contact: "",
          latest_reserv_date: "",
          proceed_class_lock: false,
          pt_cancel_cnt: 0,
          pt_id: "",
          pt_postpone_cnt: 0,
          pt_used_cnt: 0,
          purchase_date: "",
          remain_session_cnt: 0,
          remarks: "",
          root_status: "",
          service_session_cnt: 0,
          service_status: "",
          session_cnt: 0,
        },
      },
      pt_search_param: {
        is_pt_assign: false,
        is_pt_refund: false,
        pt_info_sum: {
          base_session_cnt: 0,
          class_status: "",
          comment: "",
          is_refunded: false,
          item_name: "",
          join_type: "",
          latest_class_date: "",
          latest_contact: "",
          latest_reserv_date: "",
          proceed_class_lock: false,
          pt_cancel_cnt: 0,
          pt_id: "",
          pt_postpone_cnt: 0,
          pt_used_cnt: 0,
          purchase_date: "",
          remain_session_cnt: 0,
          remarks: "",
          root_status: "",
          service_session_cnt: 0,
          service_status: "",
          session_cnt: 0,
        },
        pt_purchase_cnt: 0,
      },
      view_info: {
        selected: false,
      },
    },
    tm_last_info: {
      cs_date: "",
      cs_time: "",
      cs_type: "",
      inquiry_memo: "",
      inquiry_title: "",
      is_refuse: false,
      refuse_way: "",
      reg_info: {
        created_at: "",
        reg_id: "",
        reg_name: "",
        upd_id: "",
        upd_name: "",
        updated_at: "",
      },
      staff_info: {
        staff_id: "",
        staff_name: "",
        staff_pos: "",
        staff_rank: "",
      },
      tm_status: "",
    },
    total_unpaid: 0,
  },
  base_info: {
    coupon_list: [
      {
        coupon_info: {
          base_info: {
            benefit_product: "",
            benefit_type: "",
            benefit_use: "",
            can_gift: "",
            condition: false,
            expired_day: 0,
            expired_from_date: "",
            expired_month: 0,
            expired_to_date: "",
            img: "",
            issue_date: "",
            status: "",
            type: "",
            use_target: "",
          },
          coupon_id: "",
          detail: {
            coupon_limit_cnt: 0,
            coupon_name: "",
            coupon_used_cnt: 0,
            first_come_cnt: 0,
            is_coupon_unlimited: false,
            is_use_first_come: false,
            price_benefit: {
              dis_price: 0,
              dis_rate: 0,
              type: "",
            },
            recv_cnt: 0,
            service_benefit: {
              branch_info: {
                branch_id: "",
                branch_name: "",
              },
              mbshp: {
                add_day: 0,
                add_month: 0,
                mbshp_option: "",
                mbshp_type: "",
              },
              pt: {
                add_session: 0,
              },
            },
          },
          reg_info: {
            created_at: "",
            reg_id: "",
            reg_name: "",
            upd_id: "",
            upd_name: "",
            updated_at: "",
          },
          ticket_list: [
            {
              is_issuance: false,
              is_used: false,
              member_info: {
                member_id: "",
                member_name: "",
                member_no: "",
              },
              ticket_id: "",
            },
          ],
          view_info: {
            is_empty: false,
            is_new: false,
            is_used: false,
          },
        },
        membership_info: {
          membership_id: "",
          purchase_item_id: "",
        },
        purchase_code: "",
        ticket_info: {
          is_issuance: false,
          is_used: false,
          member_info: {
            member_id: "",
            member_name: "",
            member_no: "",
          },
          ticket_id: "",
        },
      },
    ],
    family_info_list: [
      {
        branch_info: {
          branch_id: "",
          branch_name: "",
        },
        connected_list: [
          {
            item_name: "",
            membership_id: "",
          },
        ],
        doc_list: [
          {
            belong_type: "",
            doc_group_id: "",
            doc_id: "",
            doc_name: "",
            file_due_date: "",
            file_name: "",
            is_use: false,
            is_valid: false,
            member_info: {
              member_id: "",
              member_name: "",
              member_no: "",
            },
            purchase_code: "",
            reason: "",
            reg_info: {
              created_at: "",
              reg_id: "",
              reg_name: "",
              upd_id: "",
              upd_name: "",
              updated_at: "",
            },
            staff_info: {
              staff_id: "",
              staff_name: "",
              staff_pos: "",
              staff_rank: "",
            },
            url_path: "",
          },
        ],
        family_info_id: "",
        member_id: "",
        personal_info: {
          address_1: "",
          address_2: "",
          agree_marketing: false,
          agree_personal: false,
          birth: "",
          email: "",
          gender: "",
          member_name: "",
          member_no: "",
          notice: "",
          phone: "",
          phone_backup: "",
          reg_info: {
            created_at: "",
            reg_id: "",
            reg_name: "",
            upd_id: "",
            upd_name: "",
            updated_at: "",
          },
          reg_path: "",
          zip_code: "",
        },
        reg_info: {
          created_at: "",
          reg_id: "",
          reg_name: "",
          upd_id: "",
          upd_name: "",
          updated_at: "",
        },
        reg_status: "",
      },
    ],
    member_id: "",
    personal_info: {
      address_1: "",
      address_2: "",
      agree_marketing: false,
      agree_personal: false,
      birth: "",
      email: "",
      gender: "",
      member_name: "",
      member_no: "",
      notice: "",
      phone: "",
      phone_backup: "",
      reg_info: {
        created_at: "",
        reg_id: "",
        reg_name: "",
        upd_id: "",
        upd_name: "",
        updated_at: "",
      },
      reg_path: "",
      zip_code: "",
    },
    point: {
      acc_receive_point: 0,
      acc_spend_point: 0,
      remain_point: 0,
    },
    recommend: {
      is_use: false,
      member_info: {
        member_id: "",
        member_name: "",
        member_no: "",
      },
      recommendee_day: 0,
      recommendee_month: 0,
      recommender_day: 0,
      recommender_month: 0,
    },
  },
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  can_other_access: false,
  expired_mbshp_info: {
    member_id: "",
    membership_list: [
      {
        cloth: {
          coupon_info: {
            base_info: {
              benefit_product: "",
              benefit_type: "",
            },
            coupon_id: "",
            detail: {
              coupon_name: "",
              price_benefit: {
                dis_price: 0,
                dis_rate: 0,
                type: "",
              },
              service_benefit: {
                mbshp: {
                  add_day: 0,
                  add_month: 0,
                  mbshp_option: "",
                  mbshp_type: "",
                },
              },
            },
            final_dis: 0,
            ticket_id: "",
          },
          dis: 0,
          expiration_info: {
            date: "",
            reason: "",
          },
          from_date: "",
          is_hide: false,
          is_lock: false,
          is_use: false,
          mbshp_usage_info: {
            normal_cnt: 0,
            pre_cnt: 0,
            prorate_cnt: 0,
            subs_cnt: 0,
          },
          price: 0,
          raw_price: 0,
          subs_options: {
            contract_from_date: "",
            contract_to_date: "",
            pre_dis: 0,
            pre_price: 0,
            prorate_dis: 0,
            prorate_price: 0,
            subs_dis: 0,
            subs_price: 0,
          },
          to_date: "",
        },
        daily_time: {
          end_time: "",
          from_time: "",
        },
        family_cloth: {
          coupon_info: {
            base_info: {
              benefit_product: "",
              benefit_type: "",
            },
            coupon_id: "",
            detail: {
              coupon_name: "",
              price_benefit: {
                dis_price: 0,
                dis_rate: 0,
                type: "",
              },
              service_benefit: {
                mbshp: {
                  add_day: 0,
                  add_month: 0,
                  mbshp_option: "",
                  mbshp_type: "",
                },
              },
            },
            final_dis: 0,
            ticket_id: "",
          },
          dis: 0,
          expiration_info: {
            date: "",
            reason: "",
          },
          from_date: "",
          is_hide: false,
          is_lock: false,
          is_use: false,
          mbshp_usage_info: {
            normal_cnt: 0,
            pre_cnt: 0,
            prorate_cnt: 0,
            subs_cnt: 0,
          },
          price: 0,
          raw_price: 0,
          subs_options: {
            contract_from_date: "",
            contract_to_date: "",
            pre_dis: 0,
            pre_price: 0,
            prorate_dis: 0,
            prorate_price: 0,
            subs_dis: 0,
            subs_price: 0,
          },
          to_date: "",
        },
        family_gx: {
          coupon_info: {
            base_info: {
              benefit_product: "",
              benefit_type: "",
            },
            coupon_id: "",
            detail: {
              coupon_name: "",
              price_benefit: {
                dis_price: 0,
                dis_rate: 0,
                type: "",
              },
              service_benefit: {
                mbshp: {
                  add_day: 0,
                  add_month: 0,
                  mbshp_option: "",
                  mbshp_type: "",
                },
              },
            },
            final_dis: 0,
            ticket_id: "",
          },
          dis: 0,
          expiration_info: {
            date: "",
            reason: "",
          },
          from_date: "",
          is_hide: false,
          is_lock: false,
          is_use: false,
          mbshp_usage_info: {
            normal_cnt: 0,
            pre_cnt: 0,
            prorate_cnt: 0,
            subs_cnt: 0,
          },
          price: 0,
          raw_price: 0,
          subs_options: {
            contract_from_date: "",
            contract_to_date: "",
            pre_dis: 0,
            pre_price: 0,
            prorate_dis: 0,
            prorate_price: 0,
            subs_dis: 0,
            subs_price: 0,
          },
          to_date: "",
        },
        family_info: {
          family_info_id: "",
          is_use: false,
          member_info: {
            member_id: "",
            member_name: "",
            member_no: "",
          },
          personal_info: {
            address_1: "",
            address_2: "",
            agree_marketing: false,
            agree_personal: false,
            birth: "",
            email: "",
            gender: "",
            member_name: "",
            member_no: "",
            notice: "",
            phone: "",
            phone_backup: "",
            reg_info: {
              created_at: "",
              reg_id: "",
              reg_name: "",
              upd_id: "",
              upd_name: "",
              updated_at: "",
            },
            reg_path: "",
            zip_code: "",
          },
        },
        family_locker: {
          is_hide: false,
          is_lock: false,
          is_use: false,
        },
        family_locker_list: [
          {
            item_info: {
              coupon_info: {
                base_info: {
                  benefit_product: "",
                  benefit_type: "",
                },
                coupon_id: "",
                detail: {
                  coupon_name: "",
                  price_benefit: {
                    dis_price: 0,
                    dis_rate: 0,
                    type: "",
                  },
                  service_benefit: {
                    mbshp: {
                      add_day: 0,
                      add_month: 0,
                      mbshp_option: "",
                      mbshp_type: "",
                    },
                  },
                },
                final_dis: 0,
                ticket_id: "",
              },
              dis: 0,
              is_hide: false,
              is_lock: false,
              is_use: false,
              is_use_exist: false,
              price: 0,
              raw_price: 0,
              subs_options: {
                contract_from_date: "",
                contract_to_date: "",
                pre_dis: 0,
                pre_price: 0,
                prorate_dis: 0,
                prorate_price: 0,
                subs_dis: 0,
                subs_price: 0,
              },
              usage_info: {
                normal_cnt: 0,
                pre_cnt: 0,
                prorate_cnt: 0,
                subs_cnt: 0,
              },
            },
            locker_info: {
              expiration_info: {
                date: "",
                reason: "",
              },
              family_info: {
                family_info_id: "",
                is_use: false,
                member_id: "",
                member_name: "",
              },
              from_date: "",
              locker_no: 0,
              locker_total_idx: 0,
              mbshp_locker_id: "",
              mbshp_status: "",
              membership_id: "",
              password: "",
              queue_id: "",
              to_date: "",
              zone_id: "",
              zone_name: "",
            },
            paid_item_idx: 0,
          },
        ],
        family_ot_detail_info: {
          ot_detail_id: "",
        },
        gx: {
          coupon_info: {
            base_info: {
              benefit_product: "",
              benefit_type: "",
            },
            coupon_id: "",
            detail: {
              coupon_name: "",
              price_benefit: {
                dis_price: 0,
                dis_rate: 0,
                type: "",
              },
              service_benefit: {
                mbshp: {
                  add_day: 0,
                  add_month: 0,
                  mbshp_option: "",
                  mbshp_type: "",
                },
              },
            },
            final_dis: 0,
            ticket_id: "",
          },
          dis: 0,
          expiration_info: {
            date: "",
            reason: "",
          },
          from_date: "",
          is_hide: false,
          is_lock: false,
          is_use: false,
          mbshp_usage_info: {
            normal_cnt: 0,
            pre_cnt: 0,
            prorate_cnt: 0,
            subs_cnt: 0,
          },
          price: 0,
          raw_price: 0,
          subs_options: {
            contract_from_date: "",
            contract_to_date: "",
            pre_dis: 0,
            pre_price: 0,
            prorate_dis: 0,
            prorate_price: 0,
            subs_dis: 0,
            subs_price: 0,
          },
          to_date: "",
        },
        is_all_service_lock: false,
        is_new: false,
        is_postpone_lock: false,
        item_name: "",
        item_name_idx: 0,
        item_option_idx: 0,
        item_type_idx: 0,
        locker: {
          is_hide: false,
          is_lock: false,
          is_use: false,
          subs_options: {
            subs_price: 0,
            pre_price: 0,
            prorate_price: 0,
            subs_dis: 0,
            pre_dis: 0,
            prorate_dis: 0,
            contract_from_date: "",
            contract_to_date: "",
          },
        },
        locker_list: [
          {
            item_info: {
              coupon_info: {
                base_info: {
                  benefit_product: "",
                  benefit_type: "",
                },
                coupon_id: "",
                detail: {
                  coupon_name: "",
                  price_benefit: {
                    dis_price: 0,
                    dis_rate: 0,
                    type: "",
                  },
                  service_benefit: {
                    mbshp: {
                      add_day: 0,
                      add_month: 0,
                      mbshp_option: "",
                      mbshp_type: "",
                    },
                  },
                },
                final_dis: 0,
                ticket_id: "",
              },
              dis: 0,
              is_hide: false,
              is_lock: false,
              is_use: false,
              is_use_exist: false,
              price: 0,
              raw_price: 0,
              subs_options: {
                contract_from_date: "",
                contract_to_date: "",
                pre_dis: 0,
                pre_price: 0,
                prorate_dis: 0,
                prorate_price: 0,
                subs_dis: 0,
                subs_price: 0,
              },
              usage_info: {
                normal_cnt: 0,
                pre_cnt: 0,
                prorate_cnt: 0,
                subs_cnt: 0,
              },
            },
            locker_info: {
              expiration_info: {
                date: "",
                reason: "",
              },
              family_info: {
                family_info_id: "",
                is_use: false,
                member_id: "",
                member_name: "",
              },
              from_date: "",
              locker_no: 0,
              locker_total_idx: 0,
              mbshp_locker_id: "",
              mbshp_status: "",
              membership_id: "",
              password: "",
              queue_id: "",
              to_date: "",
              zone_id: "",
              zone_name: "",
            },
            paid_item_idx: 0,
          },
        ],
        mbshp: {
          coupon_info: {
            base_info: {
              benefit_product: "",
              benefit_type: "",
            },
            coupon_id: "",
            detail: {
              coupon_name: "",
              price_benefit: {
                dis_price: 0,
                dis_rate: 0,
                type: "",
              },
              service_benefit: {
                mbshp: {
                  add_day: 0,
                  add_month: 0,
                  mbshp_option: "",
                  mbshp_type: "",
                },
              },
            },
            final_dis: 0,
            ticket_id: "",
          },
          dis: 0,
          event_day: 0,
          event_month: 0,
          event_reason: "",
          expiration_info: {
            date: "",
            reason: "",
          },
          from_date: "",
          is_add_pay: false,
          is_hide: false,
          is_lock: false,
          is_use: false,
          price: 0,
          raw_price: 0,
          subs_options: {
            contract_from_date: "",
            contract_to_date: "",
            pre_dis: 0,
            pre_price: 0,
            prorate_dis: 0,
            prorate_price: 0,
            subs_dis: 0,
            subs_price: 0,
          },
          to_date: "",
          to_date_raw: "",
          usage_info: {
            normal_cnt: 0,
            pre_cnt: 0,
            prorate_cnt: 0,
            subs_cnt: 0,
          },
        },
        mbshp_option: "",
        mbshp_postpone: {
          final_to_date: "",
          from_date: "",
          postpone_day: 0,
          postpone_id: "",
          postpone_type: "",
          stop_date: "",
          to_date: "",
        },
        mbshp_status: "",
        mbshp_type: "",
        membership_id: "",
        option_lock: false,
        ot_detail_info: {
          ot_detail_id: "",
        },
        ot_staff: {
          staff_id: "",
          staff_name: "",
          staff_pos: "",
          staff_rank: "",
        },
        paid_done_date: "",
        parent_purchase_code: "",
        pass: {
          total_cnt: 0,
          used_cnt: 0,
        },
        pass_time: {
          end_time: "",
          from_time: "",
        },
        purchase_item_id: "",
        re_reg_path: "",
        reg: {
          coupon_info: {
            base_info: {
              benefit_product: "",
              benefit_type: "",
            },
            coupon_id: "",
            detail: {
              coupon_name: "",
              price_benefit: {
                dis_price: 0,
                dis_rate: 0,
                type: "",
              },
              service_benefit: {
                mbshp: {
                  add_day: 0,
                  add_month: 0,
                  mbshp_option: "",
                  mbshp_type: "",
                },
              },
            },
            final_dis: 0,
            ticket_id: "",
          },
          dis: 0,
          expiration_info: {
            date: "",
            reason: "",
          },
          from_date: "",
          is_hide: false,
          is_lock: false,
          is_use: false,
          mbshp_usage_info: {
            normal_cnt: 0,
            pre_cnt: 0,
            prorate_cnt: 0,
            subs_cnt: 0,
          },
          price: 0,
          raw_price: 0,
          subs_options: {
            contract_from_date: "",
            contract_to_date: "",
            pre_dis: 0,
            pre_price: 0,
            prorate_dis: 0,
            prorate_price: 0,
            subs_dis: 0,
            subs_price: 0,
          },
          to_date: "",
        },
        reg_info: {
          created_at: "",
          reg_id: "",
          reg_name: "",
          upd_id: "",
          upd_name: "",
          updated_at: "",
        },
        service_status: "",
        subs_options: {
          auto_payment_cancel: false,
          contract_from_date: "",
          contract_to_date: "",
          payment_done_list: [
            {
              num: 0,
              pay_date: "",
              price: 0,
            },
          ],
          subs_paid_num: 0,
          subs_paid_total_price: 0,
          subs_total_price: 0,
        },
        usage: {
          acc_postpone_day: 0,
          family_ot_session: 0,
          link_info_total: 0,
          ot_session: 0,
          total_postpone_cnt: 0,
          used_postpone_cnt: 0,
        },
      },
    ],
    purchase_item_list: [
      {
        branch_policy: {
          cms_by_pay_method: {
            card: 0,
            card_denominator: 0,
            card_numerator: 0,
            cash: 0,
            cash_denominator: 0,
            cash_numerator: 0,
          },
          membership_policy: {
            card_remake_fee: 0,
            change_limit: 0,
            dflt_from_date: "",
            event_day_list: [0],
            event_month_list: [0],
            max_wait_day: 0,
            mbshp_assign_fee: 0,
            pass_change_limit: 0,
            pass_mbship_count_rate: 0,
            pt_assign_fee: 0,
            recommendee_day: 0,
            recommendee_month: 0,
            recommender_day: 0,
            recommender_month: 0,
            use_dflt_from_date: false,
            use_max_wait_day: false,
          },
        },
        erp_policy: {
          branch_link_cnt: 0,
          branch_change_price: 0,
          regular_pay_date: 0,
        },
        hq_policy: {
          mbshp_rfnd_day_price: 0,
          prepay_penalty_rate: 0,
          subs_penalty_rate: 0,
        },
        item_info: {
          base_info: {
            incentive: 0,
          },
          can_assign: false,
          can_change: false,
          can_family: false,
          cloth_price: 0,
          description: "",
          gx_price: 0,
          is_combine_cloth: false,
          is_combine_gx: false,
          is_combine_locker: false,
          is_combine_pt: false,
          item_id: "",
          item_name: "",
          locker_price: 0,
          mbshp_option: "",
          mbshp_price: 0,
          mbshp_type: "",
          oneday_wait_hour: 0,
          ot_session: 0,
          pass_cnt: 0,
          pass_end_time: "",
          pass_from_time: "",
          per_price_info: {
            ot: 0,
            prepay_cloth: 0,
            prepay_gx: 0,
            prepay_locker: 0,
            prepay_mbshp: 0,
            prepay_reg: 0,
            pt: 0,
            std_cloth: 0,
            std_gx: 0,
            std_locker: 0,
            std_mbshp: 0,
          },
          period_month: 0,
          postpone_cnt: 0,
          pt_per_price: 0,
          pt_price: 0,
          pt_session: 0,
          purchase_item_id: "",
          reg_info: {
            created_at: "",
            reg_id: "",
            reg_name: "",
            upd_id: "",
            upd_name: "",
            updated_at: "",
          },
          reg_price: 0,
          service_day: 0,
          service_month: 0,
          standard_price: {
            oneday_cloth: 0,
            oneday_gx: 0,
            oneday_mbshp: 0,
            ot: 0,
            prepay_cloth: 0,
            prepay_gx: 0,
            prepay_locker: 0,
            prepay_mbshp: 0,
            prepay_reg: 0,
            pt: 0,
          },
          subs_options: {
            cloth_pre_price: 0,
            cloth_subs_price: 0,
            gx_pre_price: 0,
            gx_subs_price: 0,
            locker_pre_price: 0,
            locker_subs_price: 0,
            mbshp_pre_price: 0,
            mbshp_subs_price: 0,
            pre_month: 0,
            subs_period_month: 0,
            subs_reg: 0,
            total_subs_price: 0,
          },
          total_period_day: 0,
          total_period_hour: 0,
          total_period_month: 0,
          total_price: 0,
        },
      },
    ],
  },
  filter_option: {
    is_refund_or_assign: false,
  },
  hq_info: {
    hq_id: "",
    hq_name: "",
  },
  is_no_entry: false,
  member_id: "",
  member_type: "",
  membership_data_list: [
    {
      assign_date_list: [""],
      is_add_pay: false,
      is_new: false,
      is_use: false,
      membership_list: {
        cloth: {
          coupon_info: {
            base_info: {
              benefit_product: "",
              benefit_type: "",
            },
            coupon_id: "",
            detail: {
              coupon_name: "",
              price_benefit: {
                dis_price: 0,
                dis_rate: 0,
                type: "",
              },
              service_benefit: {
                mbshp: {
                  add_day: 0,
                  add_month: 0,
                  mbshp_option: "",
                  mbshp_type: "",
                },
              },
            },
            final_dis: 0,
            ticket_id: "",
          },
          dis: 0,
          expiration_info: {
            date: "",
            reason: "",
          },
          from_date: "",
          is_hide: false,
          is_lock: false,
          is_use: false,
          mbshp_usage_info: {
            normal_cnt: 0,
            pre_cnt: 0,
            prorate_cnt: 0,
            subs_cnt: 0,
          },
          price: 0,
          raw_price: 0,
          subs_options: {
            contract_from_date: "",
            contract_to_date: "",
            pre_dis: 0,
            pre_price: 0,
            prorate_dis: 0,
            prorate_price: 0,
            subs_dis: 0,
            subs_price: 0,
          },
          to_date: "",
        },
        daily_time: {
          end_time: "",
          from_time: "",
        },
        family_cloth: {
          coupon_info: {
            base_info: {
              benefit_product: "",
              benefit_type: "",
            },
            coupon_id: "",
            detail: {
              coupon_name: "",
              price_benefit: {
                dis_price: 0,
                dis_rate: 0,
                type: "",
              },
              service_benefit: {
                mbshp: {
                  add_day: 0,
                  add_month: 0,
                  mbshp_option: "",
                  mbshp_type: "",
                },
              },
            },
            final_dis: 0,
            ticket_id: "",
          },
          dis: 0,
          expiration_info: {
            date: "",
            reason: "",
          },
          from_date: "",
          is_hide: false,
          is_lock: false,
          is_use: false,
          mbshp_usage_info: {
            normal_cnt: 0,
            pre_cnt: 0,
            prorate_cnt: 0,
            subs_cnt: 0,
          },
          price: 0,
          raw_price: 0,
          subs_options: {
            contract_from_date: "",
            contract_to_date: "",
            pre_dis: 0,
            pre_price: 0,
            prorate_dis: 0,
            prorate_price: 0,
            subs_dis: 0,
            subs_price: 0,
          },
          to_date: "",
        },
        family_gx: {
          coupon_info: {
            base_info: {
              benefit_product: "",
              benefit_type: "",
            },
            coupon_id: "",
            detail: {
              coupon_name: "",
              price_benefit: {
                dis_price: 0,
                dis_rate: 0,
                type: "",
              },
              service_benefit: {
                mbshp: {
                  add_day: 0,
                  add_month: 0,
                  mbshp_option: "",
                  mbshp_type: "",
                },
              },
            },
            final_dis: 0,
            ticket_id: "",
          },
          dis: 0,
          expiration_info: {
            date: "",
            reason: "",
          },
          from_date: "",
          is_hide: false,
          is_lock: false,
          is_use: false,
          mbshp_usage_info: {
            normal_cnt: 0,
            pre_cnt: 0,
            prorate_cnt: 0,
            subs_cnt: 0,
          },
          price: 0,
          raw_price: 0,
          subs_options: {
            contract_from_date: "",
            contract_to_date: "",
            pre_dis: 0,
            pre_price: 0,
            prorate_dis: 0,
            prorate_price: 0,
            subs_dis: 0,
            subs_price: 0,
          },
          to_date: "",
        },
        family_info: {
          family_info_id: "",
          is_use: false,
          member_info: {
            member_id: "",
            member_name: "",
            member_no: "",
          },
          personal_info: {
            address_1: "",
            address_2: "",
            agree_marketing: false,
            agree_personal: false,
            birth: "",
            email: "",
            gender: "",
            member_name: "",
            member_no: "",
            notice: "",
            phone: "",
            phone_backup: "",
            reg_info: {
              created_at: "",
              reg_id: "",
              reg_name: "",
              upd_id: "",
              upd_name: "",
              updated_at: "",
            },
            reg_path: "",
            zip_code: "",
          },
        },
        family_locker: {
          is_hide: false,
          is_lock: false,
          is_use: false,
        },
        family_locker_list: [
          {
            item_info: {
              coupon_info: {
                base_info: {
                  benefit_product: "",
                  benefit_type: "",
                },
                coupon_id: "",
                detail: {
                  coupon_name: "",
                  price_benefit: {
                    dis_price: 0,
                    dis_rate: 0,
                    type: "",
                  },
                  service_benefit: {
                    mbshp: {
                      add_day: 0,
                      add_month: 0,
                      mbshp_option: "",
                      mbshp_type: "",
                    },
                  },
                },
                final_dis: 0,
                ticket_id: "",
              },
              dis: 0,
              is_hide: false,
              is_lock: false,
              is_use: false,
              is_use_exist: false,
              price: 0,
              raw_price: 0,
              subs_options: {
                contract_from_date: "",
                contract_to_date: "",
                pre_dis: 0,
                pre_price: 0,
                prorate_dis: 0,
                prorate_price: 0,
                subs_dis: 0,
                subs_price: 0,
              },
              usage_info: {
                normal_cnt: 0,
                pre_cnt: 0,
                prorate_cnt: 0,
                subs_cnt: 0,
              },
            },
            locker_info: {
              expiration_info: {
                date: "",
                reason: "",
              },
              family_info: {
                family_info_id: "",
                is_use: false,
                member_id: "",
                member_name: "",
              },
              from_date: "",
              locker_no: 0,
              locker_total_idx: 0,
              mbshp_locker_id: "",
              mbshp_status: "",
              membership_id: "",
              password: "",
              queue_id: "",
              to_date: "",
              zone_id: "",
              zone_name: "",
            },
            paid_item_idx: 0,
          },
        ],
        family_ot_detail_info: {
          ot_detail_id: "",
        },
        gx: {
          coupon_info: {
            base_info: {
              benefit_product: "",
              benefit_type: "",
            },
            coupon_id: "",
            detail: {
              coupon_name: "",
              price_benefit: {
                dis_price: 0,
                dis_rate: 0,
                type: "",
              },
              service_benefit: {
                mbshp: {
                  add_day: 0,
                  add_month: 0,
                  mbshp_option: "",
                  mbshp_type: "",
                },
              },
            },
            final_dis: 0,
            ticket_id: "",
          },
          dis: 0,
          expiration_info: {
            date: "",
            reason: "",
          },
          from_date: "",
          is_hide: false,
          is_lock: false,
          is_use: false,
          mbshp_usage_info: {
            normal_cnt: 0,
            pre_cnt: 0,
            prorate_cnt: 0,
            subs_cnt: 0,
          },
          price: 0,
          raw_price: 0,
          subs_options: {
            contract_from_date: "",
            contract_to_date: "",
            pre_dis: 0,
            pre_price: 0,
            prorate_dis: 0,
            prorate_price: 0,
            subs_dis: 0,
            subs_price: 0,
          },
          to_date: "",
        },
        is_all_service_lock: false,
        is_new: false,
        is_postpone_lock: false,
        item_name: "",
        item_name_idx: 0,
        item_option_idx: 0,
        item_type_idx: 0,
        locker: {
          is_hide: false,
          is_lock: false,
          is_use: false,
          subs_options: {
            subs_price: 0,
            pre_price: 0,
            prorate_price: 0,
            subs_dis: 0,
            pre_dis: 0,
            prorate_dis: 0,
            contract_from_date: "",
            contract_to_date: "",
          },
        },
        locker_list: [
          {
            item_info: {
              coupon_info: {
                base_info: {
                  benefit_product: "",
                  benefit_type: "",
                },
                coupon_id: "",
                detail: {
                  coupon_name: "",
                  price_benefit: {
                    dis_price: 0,
                    dis_rate: 0,
                    type: "",
                  },
                  service_benefit: {
                    mbshp: {
                      add_day: 0,
                      add_month: 0,
                      mbshp_option: "",
                      mbshp_type: "",
                    },
                  },
                },
                final_dis: 0,
                ticket_id: "",
              },
              dis: 0,
              is_hide: false,
              is_lock: false,
              is_use: false,
              is_use_exist: false,
              price: 0,
              raw_price: 0,
              subs_options: {
                contract_from_date: "",
                contract_to_date: "",
                pre_dis: 0,
                pre_price: 0,
                prorate_dis: 0,
                prorate_price: 0,
                subs_dis: 0,
                subs_price: 0,
              },
              usage_info: {
                normal_cnt: 0,
                pre_cnt: 0,
                prorate_cnt: 0,
                subs_cnt: 0,
              },
            },
            locker_info: {
              expiration_info: {
                date: "",
                reason: "",
              },
              family_info: {
                family_info_id: "",
                is_use: false,
                member_id: "",
                member_name: "",
              },
              from_date: "",
              locker_no: 0,
              locker_total_idx: 0,
              mbshp_locker_id: "",
              mbshp_status: "",
              membership_id: "",
              password: "",
              queue_id: "",
              to_date: "",
              zone_id: "",
              zone_name: "",
            },
            paid_item_idx: 0,
          },
        ],
        mbshp: {
          coupon_info: {
            base_info: {
              benefit_product: "",
              benefit_type: "",
            },
            coupon_id: "",
            detail: {
              coupon_name: "",
              price_benefit: {
                dis_price: 0,
                dis_rate: 0,
                type: "",
              },
              service_benefit: {
                mbshp: {
                  add_day: 0,
                  add_month: 0,
                  mbshp_option: "",
                  mbshp_type: "",
                },
              },
            },
            final_dis: 0,
            ticket_id: "",
          },
          dis: 0,
          event_day: 0,
          event_month: 0,
          event_reason: "",
          expiration_info: {
            date: "",
            reason: "",
          },
          from_date: "",
          is_add_pay: false,
          is_hide: false,
          is_lock: false,
          is_use: false,
          price: 0,
          raw_price: 0,
          subs_options: {
            contract_from_date: "",
            contract_to_date: "",
            pre_dis: 0,
            pre_price: 0,
            prorate_dis: 0,
            prorate_price: 0,
            subs_dis: 0,
            subs_price: 0,
          },
          to_date: "",
          to_date_raw: "",
          usage_info: {
            normal_cnt: 0,
            pre_cnt: 0,
            prorate_cnt: 0,
            subs_cnt: 0,
          },
        },
        mbshp_option: "",
        mbshp_postpone: {
          final_to_date: "",
          from_date: "",
          postpone_day: 0,
          postpone_id: "",
          postpone_type: "",
          stop_date: "",
          to_date: "",
        },
        mbshp_status: "",
        mbshp_type: "",
        membership_id: "",
        option_lock: false,
        ot_detail_info: {
          ot_detail_id: "",
        },
        ot_staff: {
          staff_id: "",
          staff_name: "",
          staff_pos: "",
          staff_rank: "",
        },
        paid_done_date: "",
        parent_purchase_code: "",
        pass: {
          total_cnt: 0,
          used_cnt: 0,
        },
        pass_time: {
          end_time: "",
          from_time: "",
        },
        purchase_item_id: "",
        re_reg_path: "",
        reg: {
          coupon_info: {
            base_info: {
              benefit_product: "",
              benefit_type: "",
            },
            coupon_id: "",
            detail: {
              coupon_name: "",
              price_benefit: {
                dis_price: 0,
                dis_rate: 0,
                type: "",
              },
              service_benefit: {
                mbshp: {
                  add_day: 0,
                  add_month: 0,
                  mbshp_option: "",
                  mbshp_type: "",
                },
              },
            },
            final_dis: 0,
            ticket_id: "",
          },
          dis: 0,
          expiration_info: {
            date: "",
            reason: "",
          },
          from_date: "",
          is_hide: false,
          is_lock: false,
          is_use: false,
          mbshp_usage_info: {
            normal_cnt: 0,
            pre_cnt: 0,
            prorate_cnt: 0,
            subs_cnt: 0,
          },
          price: 0,
          raw_price: 0,
          subs_options: {
            contract_from_date: "",
            contract_to_date: "",
            pre_dis: 0,
            pre_price: 0,
            prorate_dis: 0,
            prorate_price: 0,
            subs_dis: 0,
            subs_price: 0,
          },
          to_date: "",
        },
        reg_info: {
          created_at: "",
          reg_id: "",
          reg_name: "",
          upd_id: "",
          upd_name: "",
          updated_at: "",
        },
        service_status: "",
        subs_options: {
          auto_payment_cancel: false,
          contract_from_date: "",
          contract_to_date: "",
          payment_done_list: [
            {
              num: 0,
              pay_date: "",
              price: 0,
            },
          ],
          subs_paid_num: 0,
          subs_paid_total_price: 0,
          subs_total_price: 0,
        },
        usage: {
          acc_postpone_day: 0,
          family_ot_session: 0,
          link_info_total: 0,
          ot_session: 0,
          total_postpone_cnt: 0,
          used_postpone_cnt: 0,
        },
      },
      ot_info: {
        alloc_date: "",
        branch_info: {
          branch_id: "",
          branch_name: "",
        },
        cur_manager: {
          assign_date: "",
          is_assigned: false,
          is_resigned: false,
          staff_info: {
            staff_id: "",
            staff_name: "",
            staff_pos: "",
            staff_rank: "",
          },
        },
        hq_info: {
          hq_id: "",
          hq_name: "",
        },
        is_use: false,
        membership_info: {
          birth: "",
          birth_info: {
            day: 0,
            month: 0,
            year: 0,
          },
          from_date: "",
          gender: "",
          is_new: false,
          item_name: "",
          mbshp_option: "",
          mbshp_type: "",
          member_id: "",
          member_name: "",
          member_no: "",
          membership_id: "",
          phone: "",
          to_date: "",
        },
        ot_class: [
          {
            class_done_date: "",
            class_done_time: "",
            class_status: "",
            comment: "",
            is_suggest_pt: false,
            membership_id: "",
            memo: "",
            no: 0,
            staff_info: {
              staff_id: "",
              staff_name: "",
              staff_pos: "",
              staff_rank: "",
            },
          },
        ],
        ot_detail_id: "",
        ot_info: {
          class_status: "",
          comment: "",
          is_refunded: false,
          is_suggest_pt: false,
          latest_contact: "",
          latest_reserv_date: "",
          mbshp_paid_date: "",
          ot_session_cnt: 0,
          proceed_class_lock: false,
          remain_session_cnt: 0,
          root_status: "",
          service_status: "",
          suggest_date: "",
        },
        reg_info: {
          created_at: "",
          reg_id: "",
          reg_name: "",
          upd_id: "",
          upd_name: "",
          updated_at: "",
        },
        remarks: "",
        service_session_hist: [
          {
            add_session: 0,
            comment: "",
            reg_info: {
              created_at: "",
              reg_id: "",
              reg_name: "",
              upd_id: "",
              upd_name: "",
              updated_at: "",
            },
          },
        ],
      },
      purchase_item_list: {
        branch_policy: {
          cms_by_pay_method: {
            card: 0,
            card_denominator: 0,
            card_numerator: 0,
            cash: 0,
            cash_denominator: 0,
            cash_numerator: 0,
          },
          membership_policy: {
            card_remake_fee: 0,
            change_limit: 0,
            dflt_from_date: "",
            event_day_list: [0],
            event_month_list: [0],
            max_wait_day: 0,
            mbshp_assign_fee: 0,
            pass_change_limit: 0,
            pass_mbship_count_rate: 0,
            pt_assign_fee: 0,
            recommendee_day: 0,
            recommendee_month: 0,
            recommender_day: 0,
            recommender_month: 0,
            use_dflt_from_date: false,
            use_max_wait_day: false,
          },
        },
        erp_policy: {
          branch_link_cnt: 0,
          branch_change_price: 0,
          regular_pay_date: 0,
        },
        hq_policy: {
          mbshp_rfnd_day_price: 0,
          prepay_penalty_rate: 0,
          subs_penalty_rate: 0,
        },
        item_info: {
          base_info: {
            incentive: 0,
          },
          can_assign: false,
          can_change: false,
          can_family: false,
          cloth_price: 0,
          description: "",
          gx_price: 0,
          is_combine_cloth: false,
          is_combine_gx: false,
          is_combine_locker: false,
          is_combine_pt: false,
          item_id: "",
          item_name: "",
          locker_price: 0,
          mbshp_option: "",
          mbshp_price: 0,
          mbshp_type: "",
          oneday_wait_hour: 0,
          ot_session: 0,
          pass_cnt: 0,
          pass_end_time: "",
          pass_from_time: "",
          per_price_info: {
            ot: 0,
            prepay_cloth: 0,
            prepay_gx: 0,
            prepay_locker: 0,
            prepay_mbshp: 0,
            prepay_reg: 0,
            pt: 0,
            std_cloth: 0,
            std_gx: 0,
            std_locker: 0,
            std_mbshp: 0,
          },
          period_month: 0,
          postpone_cnt: 0,
          pt_per_price: 0,
          pt_price: 0,
          pt_session: 0,
          purchase_item_id: "",
          reg_info: {
            created_at: "",
            reg_id: "",
            reg_name: "",
            upd_id: "",
            upd_name: "",
            updated_at: "",
          },
          reg_price: 0,
          service_day: 0,
          service_month: 0,
          standard_price: {
            oneday_cloth: 0,
            oneday_gx: 0,
            oneday_mbshp: 0,
            ot: 0,
            prepay_cloth: 0,
            prepay_gx: 0,
            prepay_locker: 0,
            prepay_mbshp: 0,
            prepay_reg: 0,
            pt: 0,
          },
          subs_options: {
            cloth_pre_price: 0,
            cloth_subs_price: 0,
            gx_pre_price: 0,
            gx_subs_price: 0,
            locker_pre_price: 0,
            locker_subs_price: 0,
            mbshp_pre_price: 0,
            mbshp_subs_price: 0,
            pre_month: 0,
            subs_period_month: 0,
            subs_reg: 0,
            total_subs_price: 0,
          },
          total_period_day: 0,
          total_period_hour: 0,
          total_period_month: 0,
          total_price: 0,
        },
      },
      reg_input_info: {
        cloth_dis: 0,
        cloth_lock: false,
        cloth_price: 0,
        event_day: 0,
        event_month: 0,
        friend: "",
        from_date: "",
        gx_dis: 0,
        gx_lock: false,
        gx_price: 0,
        is_start: false,
        item_lock: false,
        item_name: "",
        item_name_idx: 0,
        item_type: "",
        item_type_idx: 0,
        item_type_lock: false,
        join_dis: 0,
        join_lock: false,
        join_price: 0,
        join_view: false,
        membership_dis: 0,
        membership_lock: false,
        membership_price: 0,
        ot_manager: "",
        tab_idx: 0,
        tab_title: "",
        to_date: "",
        use_cloth: false,
        use_gx: false,
        use_join: false,
      },
      selected: false,
      state_initialized: false,
      total_paid_price: 0,
    },
  ],
  membership_info: {
    member_id: "",
    membership_list: [
      {
        cloth: {
          coupon_info: {
            base_info: {
              benefit_product: "",
              benefit_type: "",
            },
            coupon_id: "",
            detail: {
              coupon_name: "",
              price_benefit: {
                dis_price: 0,
                dis_rate: 0,
                type: "",
              },
              service_benefit: {
                mbshp: {
                  add_day: 0,
                  add_month: 0,
                  mbshp_option: "",
                  mbshp_type: "",
                },
              },
            },
            final_dis: 0,
            ticket_id: "",
          },
          dis: 0,
          expiration_info: {
            date: "",
            reason: "",
          },
          from_date: "",
          is_hide: false,
          is_lock: false,
          is_use: false,
          mbshp_usage_info: {
            normal_cnt: 0,
            pre_cnt: 0,
            prorate_cnt: 0,
            subs_cnt: 0,
          },
          price: 0,
          raw_price: 0,
          subs_options: {
            contract_from_date: "",
            contract_to_date: "",
            pre_dis: 0,
            pre_price: 0,
            prorate_dis: 0,
            prorate_price: 0,
            subs_dis: 0,
            subs_price: 0,
          },
          to_date: "",
        },
        daily_time: {
          end_time: "",
          from_time: "",
        },
        family_cloth: {
          coupon_info: {
            base_info: {
              benefit_product: "",
              benefit_type: "",
            },
            coupon_id: "",
            detail: {
              coupon_name: "",
              price_benefit: {
                dis_price: 0,
                dis_rate: 0,
                type: "",
              },
              service_benefit: {
                mbshp: {
                  add_day: 0,
                  add_month: 0,
                  mbshp_option: "",
                  mbshp_type: "",
                },
              },
            },
            final_dis: 0,
            ticket_id: "",
          },
          dis: 0,
          expiration_info: {
            date: "",
            reason: "",
          },
          from_date: "",
          is_hide: false,
          is_lock: false,
          is_use: false,
          mbshp_usage_info: {
            normal_cnt: 0,
            pre_cnt: 0,
            prorate_cnt: 0,
            subs_cnt: 0,
          },
          price: 0,
          raw_price: 0,
          subs_options: {
            contract_from_date: "",
            contract_to_date: "",
            pre_dis: 0,
            pre_price: 0,
            prorate_dis: 0,
            prorate_price: 0,
            subs_dis: 0,
            subs_price: 0,
          },
          to_date: "",
        },
        family_gx: {
          coupon_info: {
            base_info: {
              benefit_product: "",
              benefit_type: "",
            },
            coupon_id: "",
            detail: {
              coupon_name: "",
              price_benefit: {
                dis_price: 0,
                dis_rate: 0,
                type: "",
              },
              service_benefit: {
                mbshp: {
                  add_day: 0,
                  add_month: 0,
                  mbshp_option: "",
                  mbshp_type: "",
                },
              },
            },
            final_dis: 0,
            ticket_id: "",
          },
          dis: 0,
          expiration_info: {
            date: "",
            reason: "",
          },
          from_date: "",
          is_hide: false,
          is_lock: false,
          is_use: false,
          mbshp_usage_info: {
            normal_cnt: 0,
            pre_cnt: 0,
            prorate_cnt: 0,
            subs_cnt: 0,
          },
          price: 0,
          raw_price: 0,
          subs_options: {
            contract_from_date: "",
            contract_to_date: "",
            pre_dis: 0,
            pre_price: 0,
            prorate_dis: 0,
            prorate_price: 0,
            subs_dis: 0,
            subs_price: 0,
          },
          to_date: "",
        },
        family_info: {
          family_info_id: "",
          is_use: false,
          member_info: {
            member_id: "",
            member_name: "",
            member_no: "",
          },
          personal_info: {
            address_1: "",
            address_2: "",
            agree_marketing: false,
            agree_personal: false,
            birth: "",
            email: "",
            gender: "",
            member_name: "",
            member_no: "",
            notice: "",
            phone: "",
            phone_backup: "",
            reg_info: {
              created_at: "",
              reg_id: "",
              reg_name: "",
              upd_id: "",
              upd_name: "",
              updated_at: "",
            },
            reg_path: "",
            zip_code: "",
          },
        },
        family_locker: {
          is_hide: false,
          is_lock: false,
          is_use: false,
        },
        family_locker_list: [
          {
            item_info: {
              coupon_info: {
                base_info: {
                  benefit_product: "",
                  benefit_type: "",
                },
                coupon_id: "",
                detail: {
                  coupon_name: "",
                  price_benefit: {
                    dis_price: 0,
                    dis_rate: 0,
                    type: "",
                  },
                  service_benefit: {
                    mbshp: {
                      add_day: 0,
                      add_month: 0,
                      mbshp_option: "",
                      mbshp_type: "",
                    },
                  },
                },
                final_dis: 0,
                ticket_id: "",
              },
              dis: 0,
              is_hide: false,
              is_lock: false,
              is_use: false,
              is_use_exist: false,
              price: 0,
              raw_price: 0,
              subs_options: {
                contract_from_date: "",
                contract_to_date: "",
                pre_dis: 0,
                pre_price: 0,
                prorate_dis: 0,
                prorate_price: 0,
                subs_dis: 0,
                subs_price: 0,
              },
              usage_info: {
                normal_cnt: 0,
                pre_cnt: 0,
                prorate_cnt: 0,
                subs_cnt: 0,
              },
            },
            locker_info: {
              expiration_info: {
                date: "",
                reason: "",
              },
              family_info: {
                family_info_id: "",
                is_use: false,
                member_id: "",
                member_name: "",
              },
              from_date: "",
              locker_no: 0,
              locker_total_idx: 0,
              mbshp_locker_id: "",
              mbshp_status: "",
              membership_id: "",
              password: "",
              queue_id: "",
              to_date: "",
              zone_id: "",
              zone_name: "",
            },
            paid_item_idx: 0,
          },
        ],
        family_ot_detail_info: {
          ot_detail_id: "",
        },
        gx: {
          coupon_info: {
            base_info: {
              benefit_product: "",
              benefit_type: "",
            },
            coupon_id: "",
            detail: {
              coupon_name: "",
              price_benefit: {
                dis_price: 0,
                dis_rate: 0,
                type: "",
              },
              service_benefit: {
                mbshp: {
                  add_day: 0,
                  add_month: 0,
                  mbshp_option: "",
                  mbshp_type: "",
                },
              },
            },
            final_dis: 0,
            ticket_id: "",
          },
          dis: 0,
          expiration_info: {
            date: "",
            reason: "",
          },
          from_date: "",
          is_hide: false,
          is_lock: false,
          is_use: false,
          mbshp_usage_info: {
            normal_cnt: 0,
            pre_cnt: 0,
            prorate_cnt: 0,
            subs_cnt: 0,
          },
          price: 0,
          raw_price: 0,
          subs_options: {
            contract_from_date: "",
            contract_to_date: "",
            pre_dis: 0,
            pre_price: 0,
            prorate_dis: 0,
            prorate_price: 0,
            subs_dis: 0,
            subs_price: 0,
          },
          to_date: "",
        },
        is_all_service_lock: false,
        is_new: false,
        is_postpone_lock: false,
        item_name: "",
        item_name_idx: 0,
        item_option_idx: 0,
        item_type_idx: 0,
        locker: {
          is_hide: false,
          is_lock: false,
          is_use: false,
          subs_options: {
            subs_price: 0,
            pre_price: 0,
            prorate_price: 0,
            subs_dis: 0,
            pre_dis: 0,
            prorate_dis: 0,
            contract_from_date: "",
            contract_to_date: "",
          },
        },
        locker_list: [
          {
            item_info: {
              coupon_info: {
                base_info: {
                  benefit_product: "",
                  benefit_type: "",
                },
                coupon_id: "",
                detail: {
                  coupon_name: "",
                  price_benefit: {
                    dis_price: 0,
                    dis_rate: 0,
                    type: "",
                  },
                  service_benefit: {
                    mbshp: {
                      add_day: 0,
                      add_month: 0,
                      mbshp_option: "",
                      mbshp_type: "",
                    },
                  },
                },
                final_dis: 0,
                ticket_id: "",
              },
              dis: 0,
              is_hide: false,
              is_lock: false,
              is_use: false,
              is_use_exist: false,
              price: 0,
              raw_price: 0,
              subs_options: {
                contract_from_date: "",
                contract_to_date: "",
                pre_dis: 0,
                pre_price: 0,
                prorate_dis: 0,
                prorate_price: 0,
                subs_dis: 0,
                subs_price: 0,
              },
              usage_info: {
                normal_cnt: 0,
                pre_cnt: 0,
                prorate_cnt: 0,
                subs_cnt: 0,
              },
            },
            locker_info: {
              expiration_info: {
                date: "",
                reason: "",
              },
              family_info: {
                family_info_id: "",
                is_use: false,
                member_id: "",
                member_name: "",
              },
              from_date: "",
              locker_no: 0,
              locker_total_idx: 0,
              mbshp_locker_id: "",
              mbshp_status: "",
              membership_id: "",
              password: "",
              queue_id: "",
              to_date: "",
              zone_id: "",
              zone_name: "",
            },
            paid_item_idx: 0,
          },
        ],
        mbshp: {
          coupon_info: {
            base_info: {
              benefit_product: "",
              benefit_type: "",
            },
            coupon_id: "",
            detail: {
              coupon_name: "",
              price_benefit: {
                dis_price: 0,
                dis_rate: 0,
                type: "",
              },
              service_benefit: {
                mbshp: {
                  add_day: 0,
                  add_month: 0,
                  mbshp_option: "",
                  mbshp_type: "",
                },
              },
            },
            final_dis: 0,
            ticket_id: "",
          },
          dis: 0,
          event_day: 0,
          event_month: 0,
          event_reason: "",
          expiration_info: {
            date: "",
            reason: "",
          },
          from_date: "",
          is_add_pay: false,
          is_hide: false,
          is_lock: false,
          is_use: false,
          price: 0,
          raw_price: 0,
          subs_options: {
            contract_from_date: "",
            contract_to_date: "",
            pre_dis: 0,
            pre_price: 0,
            prorate_dis: 0,
            prorate_price: 0,
            subs_dis: 0,
            subs_price: 0,
          },
          to_date: "",
          to_date_raw: "",
          usage_info: {
            normal_cnt: 0,
            pre_cnt: 0,
            prorate_cnt: 0,
            subs_cnt: 0,
          },
        },
        mbshp_option: "",
        mbshp_postpone: {
          final_to_date: "",
          from_date: "",
          postpone_day: 0,
          postpone_id: "",
          postpone_type: "",
          stop_date: "",
          to_date: "",
        },
        mbshp_status: "",
        mbshp_type: "",
        membership_id: "",
        option_lock: false,
        ot_detail_info: {
          ot_detail_id: "",
        },
        ot_staff: {
          staff_id: "",
          staff_name: "",
          staff_pos: "",
          staff_rank: "",
        },
        paid_done_date: "",
        parent_purchase_code: "",
        pass: {
          total_cnt: 0,
          used_cnt: 0,
        },
        pass_time: {
          end_time: "",
          from_time: "",
        },
        purchase_item_id: "",
        re_reg_path: "",
        reg: {
          coupon_info: {
            base_info: {
              benefit_product: "",
              benefit_type: "",
            },
            coupon_id: "",
            detail: {
              coupon_name: "",
              price_benefit: {
                dis_price: 0,
                dis_rate: 0,
                type: "",
              },
              service_benefit: {
                mbshp: {
                  add_day: 0,
                  add_month: 0,
                  mbshp_option: "",
                  mbshp_type: "",
                },
              },
            },
            final_dis: 0,
            ticket_id: "",
          },
          dis: 0,
          expiration_info: {
            date: "",
            reason: "",
          },
          from_date: "",
          is_hide: false,
          is_lock: false,
          is_use: false,
          mbshp_usage_info: {
            normal_cnt: 0,
            pre_cnt: 0,
            prorate_cnt: 0,
            subs_cnt: 0,
          },
          price: 0,
          raw_price: 0,
          subs_options: {
            contract_from_date: "",
            contract_to_date: "",
            pre_dis: 0,
            pre_price: 0,
            prorate_dis: 0,
            prorate_price: 0,
            subs_dis: 0,
            subs_price: 0,
          },
          to_date: "",
        },
        reg_info: {
          created_at: "",
          reg_id: "",
          reg_name: "",
          upd_id: "",
          upd_name: "",
          updated_at: "",
        },
        service_status: "",
        subs_options: {
          auto_payment_cancel: false,
          contract_from_date: "",
          contract_to_date: "",
          payment_done_list: [
            {
              num: 0,
              pay_date: "",
              price: 0,
            },
          ],
          subs_paid_num: 0,
          subs_paid_total_price: 0,
          subs_total_price: 0,
        },
        usage: {
          acc_postpone_day: 0,
          family_ot_session: 0,
          link_info_total: 0,
          ot_session: 0,
          total_postpone_cnt: 0,
          used_postpone_cnt: 0,
        },
      },
    ],
    purchase_item_list: [
      {
        branch_policy: {
          cms_by_pay_method: {
            card: 0,
            card_denominator: 0,
            card_numerator: 0,
            cash: 0,
            cash_denominator: 0,
            cash_numerator: 0,
          },
          membership_policy: {
            card_remake_fee: 0,
            change_limit: 0,
            dflt_from_date: "",
            event_day_list: [0],
            event_month_list: [0],
            max_wait_day: 0,
            mbshp_assign_fee: 0,
            pass_change_limit: 0,
            pass_mbship_count_rate: 0,
            pt_assign_fee: 0,
            recommendee_day: 0,
            recommendee_month: 0,
            recommender_day: 0,
            recommender_month: 0,
            use_dflt_from_date: false,
            use_max_wait_day: false,
          },
        },
        erp_policy: {
          branch_link_cnt: 0,
          branch_change_price: 0,
          regular_pay_date: 0,
        },
        hq_policy: {
          mbshp_rfnd_day_price: 0,
          prepay_penalty_rate: 0,
          subs_penalty_rate: 0,
        },
        item_info: {
          base_info: {
            incentive: 0,
          },
          can_assign: false,
          can_change: false,
          can_family: false,
          cloth_price: 0,
          description: "",
          gx_price: 0,
          is_combine_cloth: false,
          is_combine_gx: false,
          is_combine_locker: false,
          is_combine_pt: false,
          item_id: "",
          item_name: "",
          locker_price: 0,
          mbshp_option: "",
          mbshp_price: 0,
          mbshp_type: "",
          oneday_wait_hour: 0,
          ot_session: 0,
          pass_cnt: 0,
          pass_end_time: "",
          pass_from_time: "",
          per_price_info: {
            ot: 0,
            prepay_cloth: 0,
            prepay_gx: 0,
            prepay_locker: 0,
            prepay_mbshp: 0,
            prepay_reg: 0,
            pt: 0,
            std_cloth: 0,
            std_gx: 0,
            std_locker: 0,
            std_mbshp: 0,
          },
          period_month: 0,
          postpone_cnt: 0,
          pt_per_price: 0,
          pt_price: 0,
          pt_session: 0,
          purchase_item_id: "",
          reg_info: {
            created_at: "",
            reg_id: "",
            reg_name: "",
            upd_id: "",
            upd_name: "",
            updated_at: "",
          },
          reg_price: 0,
          service_day: 0,
          service_month: 0,
          standard_price: {
            oneday_cloth: 0,
            oneday_gx: 0,
            oneday_mbshp: 0,
            ot: 0,
            prepay_cloth: 0,
            prepay_gx: 0,
            prepay_locker: 0,
            prepay_mbshp: 0,
            prepay_reg: 0,
            pt: 0,
          },
          subs_options: {
            cloth_pre_price: 0,
            cloth_subs_price: 0,
            gx_pre_price: 0,
            gx_subs_price: 0,
            locker_pre_price: 0,
            locker_subs_price: 0,
            mbshp_pre_price: 0,
            mbshp_subs_price: 0,
            pre_month: 0,
            subs_period_month: 0,
            subs_reg: 0,
            total_subs_price: 0,
          },
          total_period_day: 0,
          total_period_hour: 0,
          total_period_month: 0,
          total_price: 0,
        },
      },
    ],
  },
  ot_list: [
    {
      alloc_date: "",
      branch_info: {
        branch_id: "",
        branch_name: "",
      },
      cur_manager: {
        assign_date: "",
        is_assigned: false,
        is_resigned: false,
        staff_info: {
          staff_id: "",
          staff_name: "",
          staff_pos: "",
          staff_rank: "",
        },
      },
      hq_info: {
        hq_id: "",
        hq_name: "",
      },
      is_use: false,
      membership_info: {
        birth: "",
        birth_info: {
          day: 0,
          month: 0,
          year: 0,
        },
        from_date: "",
        gender: "",
        is_new: false,
        item_name: "",
        mbshp_option: "",
        mbshp_type: "",
        member_id: "",
        member_name: "",
        member_no: "",
        membership_id: "",
        phone: "",
        to_date: "",
      },
      ot_class: [
        {
          class_done_date: "",
          class_done_time: "",
          class_status: "",
          comment: "",
          is_suggest_pt: false,
          membership_id: "",
          memo: "",
          no: 0,
          staff_info: {
            staff_id: "",
            staff_name: "",
            staff_pos: "",
            staff_rank: "",
          },
        },
      ],
      ot_detail_id: "",
      ot_info: {
        class_status: "",
        comment: "",
        is_refunded: false,
        is_suggest_pt: false,
        latest_contact: "",
        latest_reserv_date: "",
        mbshp_paid_date: "",
        ot_session_cnt: 0,
        proceed_class_lock: false,
        remain_session_cnt: 0,
        root_status: "",
        service_status: "",
        suggest_date: "",
      },
      reg_info: {
        created_at: "",
        reg_id: "",
        reg_name: "",
        upd_id: "",
        upd_name: "",
        updated_at: "",
      },
      remarks: "",
      service_session_hist: [
        {
          add_session: 0,
          comment: "",
          reg_info: {
            created_at: "",
            reg_id: "",
            reg_name: "",
            upd_id: "",
            upd_name: "",
            updated_at: "",
          },
        },
      ],
    },
  ],
  pass_time: {
    end_time: "",
    from_time: "",
  },
  pt_list: [
    {
      alloc_date: "",
      branch_info: {
        branch_id: "",
        branch_name: "",
      },
      coupon_info: {
        base_info: {
          benefit_product: "",
          benefit_type: "",
        },
        coupon_id: "",
        detail: {
          coupon_name: "",
          price_benefit: {
            dis_price: 0,
            dis_rate: 0,
            type: "",
          },
          service_benefit: {
            pt: {
              add_session: 0,
            },
          },
        },
        final_dis: 0,
        ticket_id: "",
      },
      cs_staff: {
        staff_id: "",
        staff_name: "",
        staff_pos: "",
        staff_rank: "",
      },
      cur_manager: {
        assign_date: "",
        is_assigned: false,
        is_resigned: false,
        staff_info: {
          staff_id: "",
          staff_name: "",
          staff_pos: "",
          staff_rank: "",
        },
      },
      hq_info: {
        hq_id: "",
        hq_name: "",
      },
      membership_info: {
        birth: "",
        birth_info: {
          day: 0,
          month: 0,
          year: 0,
        },
        from_date: "",
        gender: "",
        is_new: false,
        item_name: "",
        mbshp_option: "",
        mbshp_type: "",
        member_id: "",
        member_name: "",
        member_no: "",
        membership_id: "",
        phone: "",
        to_date: "",
      },
      pt_class: [
        {
          class_id: "",
          fix_payout_rate: false,
          memo: "",
          no: 0,
          payout_price: 0,
          proceed_class: {
            class_done_date: "",
            class_done_time: "",
            class_status: "",
            comment: "",
          },
          session_price: 0,
          session_type: "",
          staff_info: {
            staff_id: "",
            staff_name: "",
            staff_pos: "",
            staff_rank: "",
          },
          view_info: {
            is_fix_payout_rate_lock: false,
          },
        },
      ],
      pt_detail_id: "",
      pt_info: {
        base_session_cnt: 0,
        class_status: "",
        comment: "",
        is_refunded: false,
        item_name: "",
        join_type: "",
        latest_class_date: "",
        latest_contact: "",
        latest_reserv_date: "",
        proceed_class_lock: false,
        pt_cancel_cnt: 0,
        pt_id: "",
        pt_postpone_cnt: 0,
        pt_used_cnt: 0,
        purchase_date: "",
        remain_session_cnt: 0,
        remarks: "",
        root_status: "",
        service_session_cnt: 0,
        service_status: "",
        session_cnt: 0,
      },
      pt_price: {
        paid_price: 0,
        paid_price_tax: 0,
        per_price: 0,
        per_price_tax: 0,
        refund_per_price: 0,
        total_price: 0,
        total_price_tax: 0,
      },
      purchase_item_info: {
        branch_policy: {
          cms_by_pay_method: {
            card: 0,
            card_denominator: 0,
            card_numerator: 0,
            cash: 0,
            cash_denominator: 0,
            cash_numerator: 0,
          },
          membership_policy: {
            card_remake_fee: 0,
            change_limit: 0,
            dflt_from_date: "",
            event_day_list: [0],
            event_month_list: [0],
            max_wait_day: 0,
            mbshp_assign_fee: 0,
            pass_change_limit: 0,
            pass_mbship_count_rate: 0,
            pt_assign_fee: 0,
            recommendee_day: 0,
            recommendee_month: 0,
            recommender_day: 0,
            recommender_month: 0,
            use_dflt_from_date: false,
            use_max_wait_day: false,
          },
        },
        erp_policy: {
          branch_link_cnt: 0,
          branch_change_price: 0,
          regular_pay_date: 0,
        },
        hq_policy: {
          mbshp_rfnd_day_price: 0,
          prepay_penalty_rate: 0,
          subs_penalty_rate: 0,
        },
        item_info: {
          base_info: {
            incentive: 0,
          },
          can_assign: false,
          can_change: false,
          can_family: false,
          cloth_price: 0,
          description: "",
          gx_price: 0,
          is_combine_cloth: false,
          is_combine_gx: false,
          is_combine_locker: false,
          is_combine_pt: false,
          item_id: "",
          item_name: "",
          locker_price: 0,
          mbshp_option: "",
          mbshp_price: 0,
          mbshp_type: "",
          oneday_wait_hour: 0,
          ot_session: 0,
          pass_cnt: 0,
          pass_end_time: "",
          pass_from_time: "",
          per_price_info: {
            ot: 0,
            prepay_cloth: 0,
            prepay_gx: 0,
            prepay_locker: 0,
            prepay_mbshp: 0,
            prepay_reg: 0,
            pt: 0,
            std_cloth: 0,
            std_gx: 0,
            std_locker: 0,
            std_mbshp: 0,
          },
          period_month: 0,
          postpone_cnt: 0,
          pt_per_price: 0,
          pt_price: 0,
          pt_session: 0,
          purchase_item_id: "",
          reg_info: {
            created_at: "",
            reg_id: "",
            reg_name: "",
            upd_id: "",
            upd_name: "",
            updated_at: "",
          },
          reg_price: 0,
          service_day: 0,
          service_month: 0,
          standard_price: {
            oneday_cloth: 0,
            oneday_gx: 0,
            oneday_mbshp: 0,
            ot: 0,
            prepay_cloth: 0,
            prepay_gx: 0,
            prepay_locker: 0,
            prepay_mbshp: 0,
            prepay_reg: 0,
            pt: 0,
          },
          subs_options: {
            cloth_pre_price: 0,
            cloth_subs_price: 0,
            gx_pre_price: 0,
            gx_subs_price: 0,
            locker_pre_price: 0,
            locker_subs_price: 0,
            mbshp_pre_price: 0,
            mbshp_subs_price: 0,
            pre_month: 0,
            subs_period_month: 0,
            subs_reg: 0,
            total_subs_price: 0,
          },
          total_period_day: 0,
          total_period_hour: 0,
          total_period_month: 0,
          total_price: 0,
        },
      },
      raw_price: 0,
      reg_info: {
        created_at: "",
        reg_id: "",
        reg_name: "",
        upd_id: "",
        upd_name: "",
        updated_at: "",
      },
      service_session_hist: [
        {
          add_session: 0,
          comment: "",
          reg_info: {
            created_at: "",
            reg_id: "",
            reg_name: "",
            upd_id: "",
            upd_name: "",
            updated_at: "",
          },
        },
      ],
      view_info: {
        selected: false,
      },
    },
  ],
  purchase_info: {
    member_id: "",
    payment_list: [
      {
        account_name: "",
        approve_no: "",
        bank_acc: "",
        buyer: "",
        cancel_receipt: "",
        card_comp: "",
        card_no: "",
        // installment_plan: 0,
        installment: "",
        is_valid: false,
        pay_date: "",
        pay_method: "",
        pay_receipt: "",
        payment_id: "",
        price: 0,
        purchase_code: "",
        remarks: "",
      },
    ],
    purchase_list: [
      {
        branch_info: {
          branch_id: "",
          branch_name: "",
        },
        change_exp_date: "",
        contract_docs: [
          {
            file_name: "",
            file_type: "",
            reason: "",
            reg_info: {
              created_at: "",
              reg_id: "",
              reg_name: "",
              upd_id: "",
              upd_name: "",
              updated_at: "",
            },
          },
        ],
        contract_price: 0,
        coupon_info: {
          coupon_id: "",
          detail: {
            coupon_name: "",
            price_benefit: {
              dis_price: 0,
              dis_rate: 0,
              type: "",
            },
          },
          final_dis: 0,
          ticket_id: "",
        },
        cs_staff: {
          staff_id: "",
          staff_name: "",
          staff_pos: "",
          staff_rank: "",
        },
        description: "",
        enroll_reg_date: "",
        etc_info: {
          count: 0,
          dis: 0,
          price: 0,
        },
        hq_policy: {
          mbshp_rfnd_day_price: 0,
          prepay_penalty_rate: 0,
          subs_penalty_rate: 0,
        },
        is_merged: false,
        is_subs: false,
        is_valid: false,
        item_id: "",
        item_name: "",
        item_per_price: 0,
        item_price: 0,
        item_type: "",
        mbshp_locker_id: "",
        membership_id: "",
        paid_price: 0,
        parent_purchase_code: "",
        point_info: {
          used_point: 0,
        },
        price: 0,
        pt_detail_id: "",
        purchase_code: "",
        purchase_id: "",
        purchase_item_id: "",
        reason: "",
        reg_info: {
          created_at: "",
          reg_id: "",
          reg_name: "",
          upd_id: "",
          upd_name: "",
          updated_at: "",
        },
        sort_ord: 0,
        staff_performance: {
          staff_info: {
            staff_id: "",
            staff_name: "",
            staff_pos: "",
            staff_rank: "",
          },
          staff_performance_id: "",
          tmp_performance_id: "",
        },
        subs_purchase_info: {
          current_num: 0,
          item_price_per_day: 0,
          month_price: 0,
          subs_pay_list: [
            {
              num: 0,
              paid_price: 0,
              payment_id: "",
              reg_info: {
                created_at: "",
                reg_id: "",
                reg_name: "",
                upd_id: "",
                upd_name: "",
                updated_at: "",
              },
              total_price: 0,
              unpaid_price: 0,
            },
          ],
          total_day: 0,
          total_unpaid: 0,
        },
        unpaid_price: 0,
      },
    ],
    unpaid: {
      total_unpaid: 0,
      unpaid_list: [
        {
          due_date: "",
          paid_price: 0,
          payment_type: "",
          purchase_code: "",
          reason: "",
          reg_date: "",
          reg_info: {
            created_at: "",
            reg_id: "",
            reg_name: "",
            upd_id: "",
            upd_name: "",
            updated_at: "",
          },
          staff_performance: {
            staff_info: {
              staff_id: "",
              staff_name: "",
              staff_pos: "",
              staff_rank: "",
            },
            staff_performance_id: "",
            tmp_performance_id: "",
          },
          total_price: 0,
          unpaid_price: 0,
          unpaid_reject_info: {
            is_unpaid_reject: false,
            staff_info: {
              staff_id: "",
              staff_name: "",
              staff_pos: "",
              staff_rank: "",
            },
          },
        },
      ],
    },
  },
};
