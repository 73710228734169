import { put, call } from "redux-saga/effects";
// import get_api from "../../../../../lib/LockerSettings/GetApi";
import get_api from "../api/GetApi";
import { lockerSettingAction } from "../actions/index";
import { globalStartLoading, globalFinishLoading } from "modules/actions/Loading/index";

export function* getLockerSetData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getLockerSetting, param);

    if (data.data === null) {
      yield put(lockerSettingAction.loadLockerSettingSuccess(false));
    } else {
      yield put(lockerSettingAction.loadLockerSettingSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(lockerSettingAction.loadLockerSettingFail(error));
    yield put(globalFinishLoading());
  }
}

export function* getLockerShowData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getLockerSettingShow, param);

    if (data.data === null) {
      yield put(lockerSettingAction.loadLockerSettingShowSuccess(false));
    } else {
      yield put(lockerSettingAction.loadLockerSettingShowSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(lockerSettingAction.loadLockerSettingShowFail(error));
    yield put(globalFinishLoading());
  }
}

export function* getLockerAllocateShowData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getLockerAllocateShow, param);

    if (data.data === null) {
      yield put(lockerSettingAction.loadLockerAllocateShowSuccess(false));
    } else {
      yield put(lockerSettingAction.loadLockerAllocateShowSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(lockerSettingAction.loadLockerAllocateShowFail(error));
    yield put(globalFinishLoading());
  }
}

export function* getLockerDetailShowData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getLockerDetailShow, param);

    if (data.data === null) {
      yield put(lockerSettingAction.loadLockerDetailShowSuccess(false));
    } else {
      yield put(lockerSettingAction.loadLockerDetailShowSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(lockerSettingAction.loadLockerDetailShowShowFail(error));
    yield put(globalFinishLoading());
  }
}
