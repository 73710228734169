const ColorLabel = (props) => {
  const { labelText, width, textAlign, justifyContent, marginTop } = props;

  if (labelText) {
    return (
      <label
        onClick={props.onClick}
        style={{
          padding: props.padding ? props.padding : "0rem",
          cursor: props.cursor,
          width: props.width ? props.width : "4.3rem",
          height: props.height ? props.height : "1.9rem",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          backgroundColor: props.bgColor,
          color: props.fontColor,
          borderRadius: "100px",
          fontFamily: "AppleSDGothicNeo",
          fontSize: props.fontSize ? props.fontSize : "0.8rem",
          fontWeight: props.fontWeight ? props.fontWeight : "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "normal",
          letterSpacing: "normal",
          justifyContent: "center",
          marginTop: marginTop ? marginTop : "0rem",
          marginLeft: props.marginLeft ? props.marginLeft : "0rem",
          marginRight: props.marginRight ? props.marginRight : "0rem",
          marginBottom: props.marginBottom ? props.marginBottom : "0rem",
          whiteSpace: props.whiteSpace,
        }}
      >
        {labelText}&nbsp;
      </label>
    );
  }
  return null;
};

ColorLabel.defaultProps = {
  textAlign: "left",
};

export default ColorLabel;
