import { generateObjectDummyId, generateObjectZeroId } from "components/CommonLib/CommonLib";

export const searchStaffParamInit = {
  //결제 수단
  // is_unset: true,
  hq_info: {
    hq_id: generateObjectZeroId(),
    hq_name: "",
  },
  branch_info: {
    branch_id: generateObjectZeroId(),
    branch_name: "",
  },
  staff_name: "",
  phone: "",
  is_hq_staff: false,//"bool / 본부 직원 여부"
};
