import { getBoardRowAPi } from "pages/NoticeBoard/restApi/getApi";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getPostRowState } from "../../model/board_row";
import NoticeDetailView from "./NoticeDetailView";
const NoticeDetailWrapper = ({ nowService, postId }) => {
  // console.log(postId);
  const storesDispatch = useDispatch();
  const [postRowState, setPostRowState] = useState(getPostRowState(nowService));
  useEffect(() => {
    getBoardRowAPi(storesDispatch, nowService, postId, setPostRowState);
  }, []);

  // console.log(postRowState);
  //게시글 상세 GET
  const getNoticeDetail = async () => {};
  return <NoticeDetailView postRowState={postRowState} nowService={nowService} />;
};

export default NoticeDetailWrapper;
