import { contentTypeJson } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

// ==================== GET ====================
// [GET] 운영일지 회원권 기간 추가 이력
export async function getExtendHistory(reportState) {
  const formData = new FormData();
  const data = {
    branch_info: {
      branch_id: reportState.branch_info.branch_id,
      branch_name: reportState.branch_info.branch_name,
    },
    extend_days_from: 0,
    extend_days_to: 0,
    extend_type: "",
    hq_info: {
      hq_id: reportState.hq_info.hq_id,
      hq_name: reportState.hq_info.hq_name,
    },
    member_name: "",
    member_no: "",
    reg_date_from: reportState.base_date,
    reg_date_to: reportState.base_date,
    staff_name: "",
  };
  const page = 1;

  formData.append("search_param", JSON.stringify(data));
  formData.append("page_num", page);

  return await HttpInstance.post(
    process.env.REACT_APP_BRANCH_SERVER + "/history/membership/extension",
    formData,
    contentTypeJson,
  );
}

// [GET] 회원권 기간 추가 현황 리스트 검색
export async function getSearchExtendHistory(queryData) {
  const { page, searchParams } = queryData;
  const formData = new FormData();

  formData.append("search_param", JSON.stringify(searchParams));
  formData.append("page_num", page);

  return await HttpInstance.post(
    process.env.REACT_APP_BRANCH_SERVER + "/history/membership/extension",
    formData,
    contentTypeJson,
  );
}

// ==================== PATCH ====================
// [PATCH] 회원권 기간 연장
export async function patchExtendMbshp(submitData) {
  return await HttpInstance.patch(process.env.REACT_APP_USER_SERVER + "/mbshp/to_date/update", "", {
    params: submitData,
  });
}
