import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";

const SearchResultTableLocker = ({
  searchResult,
  navigate,
  curPage,
  setCurPage,
  totalPage,
  onClickSearch,
}) => {
  const searchResultList = new resultTable(navigate);

  const doSearch = (page) => {
    onClickSearch(false, page);
  };

  return (
    <div style={{ margin: "0px 1px" }}>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
        width="120rem"
      />
      <CustomPagination
        curPage={curPage}
        // pageSelection={setCurPage}
        totalCnt={totalPage}
        doSearch={doSearch}
      />
    </div>
  );
};
export default SearchResultTableLocker;

class resultTable {
  table_title = "";
  onNavigate = null;

  constructor(navigate) {
    this.onNavigate = navigate;
  }

  onClickEvent = (data) => {
    // this.onNavigate("/staff_info/", {
    //   state: {
    //     staff_id: data.staff_id,
    //     is_view: true,
    //   },
    // });
  };

  create_table = (data, idx) => {
    const lockerListLength =
      data.sub_result.active_mbshp.locker_list && data.sub_result.active_mbshp.locker_list.length;

    return (
      <>
        <CustomRow style={{}} key={idx} onClick={() => this.onClickEvent(data)} hover>
          <CustomTableContents rowSpan={lockerListLength || 1}>
            {data.base_result.branch_info.branch_name}
          </CustomTableContents>
          <CustomTableContents rowSpan={lockerListLength || 1}>
            {data.base_result.member_type}
          </CustomTableContents>
          <CustomTableContents rowSpan={lockerListLength || 1}>
            {data.base_result.member_info.member_no}
          </CustomTableContents>
          <CustomTableContents rowSpan={lockerListLength || 1}>
            {data.base_result.member_info.member_name}
          </CustomTableContents>
          <CustomTableContents rowSpan={lockerListLength || 1}>
            {data.base_result.member_info.phone}
          </CustomTableContents>
          <CustomTableContents rowSpan={lockerListLength || 1}>
            {data.base_result.member_info.birth}
          </CustomTableContents>
          <CustomTableContents rowSpan={lockerListLength || 1}>
            {data.base_result.member_info.gender}
          </CustomTableContents>
          <CustomTableContents rowSpan={lockerListLength || 1}>
            {data.base_result.active_mbshp_status}
          </CustomTableContents>
          <CustomTableContents rowSpan={lockerListLength || 1}>
            {data.base_result.last_mbshp_to_date}
          </CustomTableContents>

          {lockerListLength > 0 ? (
            <>
              <CustomTableContents>
                {data.sub_result.active_mbshp.is_use_locker === "사용" ? "사용" : "미사용"}
              </CustomTableContents>
              <CustomTableContents>
                {data.sub_result.active_mbshp.locker_list[0].from_date}
              </CustomTableContents>
              <CustomTableContents>
                {data.sub_result.active_mbshp.locker_list[0].to_date}
              </CustomTableContents>
              <CustomTableContents>
                {data.sub_result.active_mbshp.locker_list[0].locker_no}
              </CustomTableContents>
            </>
          ) : (
            <>
              <CustomTableContents>미사용</CustomTableContents>
              <CustomTableContents>-</CustomTableContents>
              <CustomTableContents>-</CustomTableContents>
              <CustomTableContents>-</CustomTableContents>
            </>
          )}
          <CustomTableContentsEND>
            {data.base_result.agree_marketing ? "동의" : "미동의"}
          </CustomTableContentsEND>
        </CustomRow>
        {lockerListLength > 1 &&
          data.sub_result.active_mbshp.locker_list.slice(1).map((locker, index) => (
            <CustomRow key={`${idx}-${index + 1}`}>
              <CustomTableContents>
                {data.sub_result.active_mbshp.is_use_locker === "사용" ? "사용" : "미사용"}
              </CustomTableContents>
              <CustomTableContents>{locker.from_date}</CustomTableContents>
              <CustomTableContents>{locker.to_date}</CustomTableContents>
              <CustomTableContents>{locker.locker_no}</CustomTableContents>
            </CustomRow>
          ))}
      </>
    );
  };

  columns_head = [
    { title: "지점", width: "8%" },
    { title: "구분", width: "4%" },
    { title: "회원번호", width: "7%" },
    { title: "이름", width: "7%" },
    { title: "연락처", width: "7%" },
    { title: "생년월일", width: "7%" },
    { title: "성별", width: "4%" },
    { title: "회원권 상태", width: "5%" },
    { title: "최종 회원권 종료일", width: "7%" },

    { title: "부가상품 상태", width: "5%" },
    { title: "시작일", width: "7%" },
    { title: "종료일", width: "7%" },
    { title: "사용 번호", width: "5%" },
    { title: "마케팅 수신 동의", width: "5%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
