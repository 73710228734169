import { globalFinishLoading, globalStartLoading } from "modules/actions/Loading/index";
import { call, put } from "redux-saga/effects";
import { memberViewActionDepth2 } from "../actions/index_depth_2";
import get_api from "../api/GetApi";

export function* getDocumentsViewData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getDocumentsInfo, param);

    if (data.data === null) {
      yield put(memberViewActionDepth2.loadDocumentsModalSuccess(false));
    } else {
      yield put(memberViewActionDepth2.loadDocumentsModalSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(memberViewActionDepth2.loadDocumentsModalFail(error));
    yield put(globalFinishLoading());
  }
}

export function* getBranchLinkDetailData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getBranchLinkInfo, param);

    if (data.data === null) {
      yield put(memberViewActionDepth2.loadBranchLinkHistModalSuccess(false));
    } else {
      yield put(memberViewActionDepth2.loadBranchLinkHistModalSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(memberViewActionDepth2.loadBranchLinkHistModalFail(error));
    yield put(globalFinishLoading());
  }
}

///////

export function* getFriendsViewData(param) {
  try {
    // yield put(globalStartLoading());
    const { data } = yield call(get_api.getFriendsInfo, param);

    if (data.data === null) {
      yield put(memberViewActionDepth2.loadFriendModalSuccess(false));
    } else {
      yield put(memberViewActionDepth2.loadFriendModalSuccess(data));
    }
    // yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(memberViewActionDepth2.loadFriendModalFail(error));
    // yield put(globalFinishLoading());
  }
}

export function* getFamilyViewData(param) {
  try {
    // yield put(globalStartLoading());
    const { data } = yield call(get_api.getFamilyInfo, param);

    if (data.data === null) {
      yield put(memberViewActionDepth2.loadFamilyModalSuccess(false));
    } else {
      yield put(memberViewActionDepth2.loadFamilyModalSuccess(data));
    }
    // yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(memberViewActionDepth2.loadFamilyModalFail(error));
    // yield put(globalFinishLoading());
  }
}
