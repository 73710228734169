import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { getNowServiceState } from "pages/NoticeBoard/model/board_row";
import { useParams } from "react-router-dom";
import NoticeUpdateWrapper from "./NoticeUpdateWrapper";
const NoticeUpdateService = () => {
  const params = useParams();
  const nowService = "공지사항";
  return (
    <div>
      <PageFrame
        children={
          <div>
            <PageTitle titleText="공지사항" />
            <NoticeUpdateWrapper
              nowService={getNowServiceState(nowService)}
              postId={params.postId}
            />
          </div>
        }
      />
    </div>
  );
};

export default NoticeUpdateService;
