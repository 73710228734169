import { takeLatest, takeEvery } from "redux-saga/effects";
import { put, call } from "redux-saga/effects";
// import get_api from "../../../../../lib/LockerSettings/GetApi";
import get_api from "../api/GetApi";
import { erpSettingAction } from "../actions/index";
import { globalStartLoading, globalFinishLoading } from "modules/actions/Loading/index";
import * as ActionTypes from "../actions/action_type";

export function* getAllPermissionData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getPermissionAll, param);

    if (data.data === null) {
      yield put(erpSettingAction.loadPermissionSuccess(false));
    } else {
      yield put(erpSettingAction.loadPermissionSuccess(data));
    }
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(erpSettingAction.loadPermissionFail(error));
  }
  yield put(globalFinishLoading());
}

export function* getHqViewData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getHqViewApi, param);

    if (data.data === null) {
      yield put(erpSettingAction.loadHqViewListSuccess(false));
    } else {
      yield put(erpSettingAction.loadHqViewListSuccess(data));
    }
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(erpSettingAction.loadHqViewListFail(error));
  }
  yield put(globalFinishLoading());
}

export function* getIpAllowViewData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getIpAllowViewApi, param);

    if (data.data === null) {
      yield put(erpSettingAction.loadIpAllowListSuccess(false));
    } else {
      yield put(erpSettingAction.loadIpAllowListSuccess(data));
    }
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(erpSettingAction.loadIpAllowListFail(error));
  }
  yield put(globalFinishLoading());
}

export function* getPolicyViewData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getPolicyViewApi, param);

    if (data.data === null) {
      yield put(erpSettingAction.loadPolicyViewSuccess(false));
    } else {
      yield put(erpSettingAction.loadPolicyViewSuccess(data));
    }
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(erpSettingAction.loadPolicyViewFail(error));
  }
  yield put(globalFinishLoading());
}


export function* erpSettingSaga() {
  yield takeLatest(ActionTypes.getPermissionAction, getAllPermissionData);
  yield takeLatest(ActionTypes.getHqViewAction, getHqViewData);

  yield takeLatest(ActionTypes.getIpAllowViewAction, getIpAllowViewData);
  yield takeLatest(ActionTypes.getPolicyViewAction, getPolicyViewData);
}
