import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ko } from "date-fns/locale";
import { useEffect, useState } from "react";

const TimePicker = (props) => {
  const [value, setValue] = useState(props.defaultValue);

  const hStyle = {
    display: "flex",
    alignItems: "center",
    width: props.fullWidth ? "100%" : null,
    justifyContent: props.justifyContent,
    marginTop: props.marginTop,
    marginRight: props.marginRight,
    marginLeft: props.marginLeft,
  };

  const labelStyle = {
    width: props.labelWidth,
    height: "2.25rem",
    marginRight: props.labelMarginRight === undefined ? "0.625rem" : props.labelMarginRight,
    marginLeft: props.labelMarginLeft,
    alignItems: "center",
    display: "flex",

    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.938rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
  };

  const textBoxStyle = {
    width: props.textBoxWidth,
    height: "2.25rem",
    marginRight: props.textMarginRight === undefined ? "0.625rem" : props.textMarginRight,
    borderRadius: "5px",
    // border: "solid 1px #dedede",
    backgroundColor: "#fff",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.9rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000",
  };

  const textBoxStyleDisabled = {
    width: props.textBoxWidth || props.minWidth,
    height: "2.25rem",
    marginRight: props.textMarginRight === undefined ? "0.625rem" : props.textMarginRight,
    borderRadius: "5px",
    backgroundColor: "#ebebeb",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.9rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000",
  };

  useEffect(() => {
    if (props.defaultValue !== undefined) {
      setValue(props.defaultValue);
    }
  }, [props.defaultValue]);

  return (
    <LocalizationProvider adapterLocale={ko} dateAdapter={AdapterDateFns}>
      <Box style={hStyle}>
        {/* <Label width={props.labelWidth} textAlign={props.labelAlign} labelText={props.labelText} /> */}
        <div style={labelStyle}>{props.labelText}</div>
        <TextField
          id={props.id}
          size="small"
          type="time"
          error={props.required && (value === undefined || value === "")}
          // defaultValue="03:30"
          value={value}
          inputRef={props.inputRef}
          InputProps={{
            readOnly: props.readonly,
            endAdornment: props.endAdornment,
            style: props.disabled === true ? textBoxStyleDisabled : textBoxStyle,
          }}
          onChange={(e) => {
            setValue(e.target.value);
            if (props.onChange) {
              props.onChange(e.target.value);
            }
          }}
          onBlur={(e) => {
            if (props.onBlur) {
              setValue(e.target.value);
              props.onBlur(e.target.value);
            }
          }}
          disabled={props.disabled}
        />
        {/* <TimePickerComp
          InputProps={{
            readOnly: props.readonly,
            endAdornment: props.endAdornment,
            style: textBoxStyle,
          }}
          inputRef={props.inputRef}
          value={value}
          // onChange={(newValue) => { setValue(newValue); }}
          onChange={(e) => {
            setValue(e);
            props.onChange(timeToStr(e));
          }}
          inputFormat="HH:mm"
          ampm={false}
          renderInput={(params) => <TextField {...params} size="small" />}
          disabled={props.disabled}
        /> */}
      </Box>
    </LocalizationProvider>
  );
};

export default TimePicker;
