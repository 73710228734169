import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import SubTitle from "components/PageTitle/SubTitle";
import { useState } from "react";
import MonthlyClassRecordList from "./MonthlyClassRecordList";
import MonthlySalesRecordList from "./MonthlySalesRecordList";

const StaffPtSalesDetail = ({ selected, ptDetail, ptSummary, popChangeSessionPriceModal }) => {
  const btnStyleClass = btnStyles();
  const dataTableItem = new dataTable();
  const [openList, setOpenList] = useState([true, true]);

  return (
    <div>
      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText={`직원별 PT 수당 상세: ${selected.staffName} ${selected.staffPos} `} />
      </div>
      <div>
        <CustomTable
          columns_head={dataTableItem.columns_head}
          table_title={dataTableItem.table_title}
          rest_call={dataTableItem.get_data_from_rest}
          row_render={dataTableItem.create_table}
          rest_data={ptSummary.pt_summary_info}
        />
      </div>
      <div style={{ marginTop: "1rem" }}>
        <MonthlySalesRecordList
          openList={openList}
          setOpenList={setOpenList}
          salesList={ptDetail.sales_info.sales_record_list}
        />
        <div style={{ height: "1.3rem" }}></div>
        <MonthlyClassRecordList
          openList={openList}
          setOpenList={setOpenList}
          classRecordList={ptDetail.class_info.class_record_list}
          popChangeSessionPriceModal={popChangeSessionPriceModal}
        />
      </div>
    </div>
  );
};
export default StaffPtSalesDetail;

class dataTable {
  table_title = "";

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents $isNumber>
          {data.pt_work_in_sales.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.pt_after_ot_sales.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.pt_re_reg_sales.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.pt_total_refund.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.pt_total_sales.toLocaleString() + " 원"}
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "워크인 매출", width: "20%" },
    { title: "OT 후 PT 매출", width: "20%" },
    { title: "PT 재등록 매출", width: "20%" },
    { title: "총 환불", width: "20%" },
    { title: "총 매출", width: "20%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return [stateData];
  };
}
