import { dateToStr, getDefaultValue } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import NormalBtn from "components/NewButton/NormalBtn";
import { globalModalOff } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DailySalesModalBody, DailySalesModalFooter } from "./style";

const DailySalesModal = ({ hqBranchListData, setHqIdx, onClickSearch, setSearchParams }) => {
  const storesDispatch = useDispatch();
  // 페이지 구성 변수
  const labelWidth = "4.5rem";
  const comboWidth = "13rem";
  const { hq_list, target_list } = hqBranchListData;
  // 페이지 상태 관리
  const [baseFromDate, setBaseFromDate] = useState("");

  // 최초의 엑셀용 검색조건 세팅
  const initSearchParams = () => {
    const today = new Date();
    const oneDayMS = 24 * 60 * 60 * 1000;
    const yesterday = new Date(today.getTime() - oneDayMS);
    setBaseFromDate(dateToStr(yesterday));
    setSearchParams("base_from_date", dateToStr(yesterday));
    setSearchParams("base_to_date", dateToStr(yesterday));
    setSearchParams("hq_info", target_list[0].hq_info);
    setSearchParams("branch_info", target_list[0].branch_list[0]);
  };

  useEffect(() => {
    initSearchParams();

    return () => {
      setSearchParams("hq_info", hqBranchListData.target_list[0].hq_info);
      setSearchParams("branch_info", hqBranchListData.target_list[0].branch_list[0]);
      setSearchParams("base_from_date", "");
      setSearchParams("base_to_date", "");
      setHqIdx(0);
    };
  }, []);

  return (
    <>
      <DailySalesModalBody>
        <ComboBox
          labelWidth={labelWidth}
          comboItemWidth={comboWidth}
          labelText="본부"
          labelMarginRight="0rem"
          data={hq_list}
          defaultValue={getDefaultValue("combo", hq_list[0])}
          onChangeCallback={(e, idx) => {
            const hqParams = hqBranchListData.target_list[idx].hq_info;
            setSearchParams("hq_info", hqParams);
          }}
          required
        />
        <DatePicker
          labelText="날짜"
          labelWidth={labelWidth}
          textBoxWidth={comboWidth}
          labelMarginRight="0rem"
          textMarginRight="0rem"
          defaultValue={getDefaultValue("field", baseFromDate)}
          onChangeCallback={(e) => {
            setSearchParams("base_from_date", e);
            setSearchParams("base_to_date", e);
            setBaseFromDate(e);
          }}
        />
      </DailySalesModalBody>
      <DailySalesModalFooter>
        <NormalBtn
          theme="green"
          name={"다운로드"}
          onClick={() => {
            onClickSearch(1, true);
            setTimeout(() => {
              storesDispatch(globalModalOff());
            }, 300);
          }}
        />
        <NormalBtn
          name="취소"
          theme="white"
          onClick={() => {
            storesDispatch(globalModalOff());
          }}
        />
      </DailySalesModalFooter>
    </>
  );
};

export default DailySalesModal;
