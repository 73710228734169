import { restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { useDispatch } from "react-redux";
import { exportToSalesReportExcel } from "./MakeExcel";
import AssignListTable from "./Tables/AssignListTable";
import PaymentListTable from "./Tables/PaymentListTable";
import RefundListTable from "./Tables/RefundListTable";
import SalesListTable from "./Tables/SalesListTable";

const DailyReportSales = ({ reportState }) => {
  const storesDispatch = useDispatch();

  const getDailyReportExcelData = () => {
    HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + "/daily_report/detail/excel", {
      params: {
        branch_id: reportState.branch_info.branch_id,
        year_month: reportState.base_date.substr(0, 7),
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          exportToSalesReportExcel(
            res.data.data,
            reportState.branch_info.branch_name +
              "_" +
              reportState.base_date.substr(0, 7) +
              "_" +
              "매출환불리스트",
          );
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  return (
    <div style={{}}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <NormalBtn
          name="당월 자료 다운로드"
          theme="green"
          onClick={() => {
            getDailyReportExcelData();
          }}
        />
      </div>
      <div>
        <SalesListTable reportState={reportState} />
        <RefundListTable reportState={reportState} />
        <AssignListTable reportState={reportState} />
        <PaymentListTable reportState={reportState} />
      </div>
    </div>
  );
};
export default DailyReportSales;
