import { globalFinishLoading, globalStartLoading } from "modules/actions/Loading/index";
import { call, put } from "redux-saga/effects";
import { memberViewAction } from "../actions/index";
import get_api from "../api/GetApi";

export function* getMemberShowViewData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getMemberViewInfo, param);

    if (data.data === null) {
      yield put(memberViewAction.loadMemberViewSuccess(false));
    } else {
      yield put(memberViewAction.loadMemberViewSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(memberViewAction.loadMemberViewFail(error));
    yield put(globalFinishLoading());
  }
}

export function* getPurchaseDetailData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getPurchaseDetailInfo, param);

    if (data.data === null) {
      yield put(memberViewAction.loadPurchaseDetailModalSuccess(false));
    } else {
      yield put(memberViewAction.loadPurchaseDetailModalSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(memberViewAction.loadPurchaseDetailModalFail(error));
    yield put(globalFinishLoading());
  }
}

///////

export function* getMembershipHistoryViewData(param) {
  try {
    // yield put(globalStartLoading());
    const { data } = yield call(get_api.getMembershipHistoryInfo, param);

    if (data.data === null) {
      yield put(memberViewAction.loadMembershipHistorySuccess(false));
    } else {
      yield put(memberViewAction.loadMembershipHistorySuccess(data));
    }
    // yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(memberViewAction.loadMembershipHistoryViewFail(error));
    // yield put(globalFinishLoading());
  }
}

export function* getClassHistoryViewData(param) {
  try {
    // yield put(globalStartLoading());
    const { data } = yield call(get_api.getClasseHistoryInfo, param);

    if (data.data === null) {
      yield put(memberViewAction.loadClassHistorySuccess(false));
    } else {
      yield put(memberViewAction.loadClassHistorySuccess(data));
    }
    // yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(memberViewAction.loadClassHistoryViewFail(error));
    // yield put(globalFinishLoading());
  }
}

export function* getPurhcaseHistoryViewData(param) {
  try {
    // yield put(globalStartLoading());
    const { data } = yield call(get_api.getPurchaseHistoryInfo, param);

    if (data.data === null) {
      yield put(memberViewAction.loadPurchaseHistorySuccess(false));
    } else {
      yield put(memberViewAction.loadPurchaseHistorySuccess(data));
    }
    // yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(memberViewAction.loadPurchaseHistoryViewFail(error));
    // yield put(globalFinishLoading());
  }
}

export function* getCSHistoryViewData(param) {
  try {
    // yield put(globalStartLoading());
    const { data } = yield call(get_api.getCSHistoryInfo, param);

    if (data.data === null) {
      yield put(memberViewAction.loadCSHistorySuccess(false));
    } else {
      yield put(memberViewAction.loadCSHistorySuccess(data));
    }
    // yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(memberViewAction.loadCSHistoryViewFail(error));
    // yield put(globalFinishLoading());
  }
}
