export const newPrePayApi = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/branch_set/prepay_insert";
};

export const newSubScriptApi = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/branch_set/subscript_insert";
};

export const newETCApi = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/branch_set/etc_insert";
};

export const updatePrePayApi = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/branch_set/prepay_update";
};

export const updateSubScriptApi = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/branch_set/subscript_update";
};

export const updateETCApi = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/branch_set/etc_update";
};

export const updatePolicyApi = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/branch_set/policy_update";
};

export const newPtApi = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/branch_set/pt_insert";
};

export const updatePtApi = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/branch_set/pt_update";
};

const post_api = {
  updatePolicyApi,
  newPrePayApi,
  newSubScriptApi,
  newETCApi,
  newPtApi,
  updatePtApi,
  updatePrePayApi,
  updateSubScriptApi,
  updateETCApi,
};

export default post_api;
