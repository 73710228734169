import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import CardFixed from "components/Card/CardFixed";
import { tabBorder, tabStyle } from "components/CommonComp";
import { restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import TextField from "components/LabelInput/TextField";
import TabPanel from "components/TabControl/TabPanel";
import { HttpInstance } from "lib/HttpLib";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import DailyReportSales from "./DailyReportTabs/SalesTab/DailyReportSales";
import DailyReportStatistics from "./DailyReportTabs/StatisticsTab/DailyReportStatistics";
import DailyReportStatus from "./DailyReportTabs/StatusTab/DailyReportStatus";

const DailyReportInfoView = ({ reportState, isAdmin }) => {
  const storesDispatch = useDispatch();

  const [tabIndex, setTabIndex] = useState(0);

  const labelWidth = "3.5rem";
  const compWidth = "12rem";

  const onChangeTabIndex = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  useEffect(() => {
    if (reportState.branch_info.branch_id) {
      getScheduleSearchParam();
    }
  }, [reportState]);

  const getScheduleSearchParam = () => {
    HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/schedule/search_param", {
      params: {},
    })
      .then((res1) => {
        if (!restEmpty(res1.data.data)) {
          HttpInstance.get(
            process.env.REACT_APP_STAFF_SERVER + "/schedule/search_param/ch_staff_list",
            {
              params: {
                branch_id: reportState.branch_info.branch_id,
              },
            },
          )
            .then((res2) => {
              if (!restEmpty(res2.data.data)) {
                // setSchedSearchParam({
                //   ...res1.data.data,
                //   hq_info: reportState.hq_info,
                //   branch_info: reportState.branch_info,
                //   search_day_date: reportState.base_date,
                //   ch_staff_list: res2.data.data.ch_staff_list,
                // });
              }
            })
            .catch((err) => {
              console.log(err);
              simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
            });
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  return (
    <div>
      <CardFixed
        midDom={
          <div>
            <div style={{ display: "flex" }}>
              <TextField
                labelText="본부"
                labelWidth={labelWidth}
                textBoxWidth={compWidth}
                textMarginRight="4rem"
                defaultValue={reportState.hq_info.hq_name}
                disabled
              />
              <TextField
                labelText="지점"
                labelWidth={labelWidth}
                textBoxWidth={compWidth}
                textMarginRight="4rem"
                defaultValue={reportState.branch_info.branch_name}
                disabled
              />
              <TextField
                labelText="날짜"
                labelWidth={labelWidth}
                textBoxWidth={compWidth}
                defaultValue={reportState.base_date}
                disabled
              />
            </div>

            <Box sx={{ bgcolor: "background.paper", marginTop: "1.5rem" }}>
              <Tabs
                value={tabIndex}
                onChange={onChangeTabIndex}
                textColor="inherit"
                sx={{ minHeight: "40px" }}
                TabIndicatorProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0)" } }}
              >
                <Tab sx={{ minHeight: "40px" }} label="현황" style={tabStyle(0, tabIndex)} />
                <Tab
                  sx={{ minHeight: "40px" }}
                  label="매출/환불 목록"
                  style={tabStyle(1, tabIndex, "9rem")}
                />
                <Tab sx={{ minHeight: "40px" }} label="통계" style={tabStyle(2, tabIndex)} />
                {/* <Tab sx={{ minHeight: "40px" }} label="업무" style={tabStyle(3, tabIndex)} /> */}
                {/* <Tab sx={{ minHeight: "40px" }} label="스케줄" style={tabStyle(4, tabIndex)} /> */}
              </Tabs>
            </Box>
            <SwipeableViews
              index={tabIndex}
              onChangeIndex={onChangeTabIndex}
              style={tabBorder}
              animateTransitions={false}
            >
              {/* <div index={value} onChangeIndex={handleChangeIndex}> */}
              <TabPanel value={tabIndex} index={0}>
                <DailyReportStatus reportState={reportState} isAdmin={isAdmin} />
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <DailyReportSales reportState={reportState} />
              </TabPanel>
              <TabPanel value={tabIndex} index={2}>
                <DailyReportStatistics reportInfoId={reportState.report_info_id} />
              </TabPanel>
              {/* <TabPanel value={tabIndex} index={3}>
                <DailyReportWorks reportState={reportState} />
              </TabPanel> */}
              {/* <TabPanel value={tabIndex} index={4}>
                <DailyReportSchedule schedSearchParam={schedSearchParam} /> */}
              {/* </TabPanel> */}
            </SwipeableViews>
          </div>
        }
        isLoading={false}
        rootStyle={{
          width: "100%",
          minHeight: "29rem",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />
    </div>
  );
};
export default DailyReportInfoView;
