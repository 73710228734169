import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import DeviceManageWrapper from "./DeviceManageWrapper";

const DeviceManageService = () => {
  return (
    <PageFrame
      children={
        <div>
          <PageTitle titleText="단말기 관리" />
          <DeviceManageWrapper />
        </div>
      }
    />
  );
};
export default DeviceManageService;
