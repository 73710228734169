import { HttpInstance } from "lib/HttpLib";

export const getApprovalInfoData = (param) => {
  if (param.approval_id) {
    return HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/approval/get_approval_info", {
      params: {
        approval_id: param.approval_id,
      },
    });
  }
};

const get_api = {
  getApprovalInfoData,
};

export default get_api;
