import { compareTimeBigger, SubLine } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import NumberTextField from "components/LabelInput/NumberTextField";
import TimePicker from "components/LabelInput/TimePicker";
import { globalAlertOn } from "modules/actions/Alert";
import { useDispatch } from "react-redux";

const ExpiredSetting = ({ restData, mbshpOption, setPeriod, setPreMonth, isEditMode }) => {
  const textLabelWidth = "6rem";
  const textBoxWidthShort = "5rem";
  const textBoxWidth = "7rem";
  const labelMarginLeft = "2.625rem";

  const storesDispatch = useDispatch();

  return (
    <div style={{}}>
      <div style={{ display: "flex", marginTop: "1.25rem", marginBottom: "0.625rem" }}>
        <Label textAlign={"left"} labelText={"유효기간"} />
      </div>
      <div style={{ marginLeft: "2rem" }}>
        <div>
          <div style={{ display: "flex" }}>
            <NumberTextField
              required
              id="contr_comp_01"
              labelWidth={textLabelWidth}
              textBoxWidth={textBoxWidth}
              labelText="· 약정기간"
              endAdornment="개월"
              maxValue={120}
              defaultValue={restData.subs_options.subs_period_month}
              onBlur={(e) => {
                if (e < Number(restData.subs_options.pre_month)) {
                  storesDispatch(
                    globalAlertOn({
                      show: true,
                      titleText: "알림",
                      bodyText: (
                        <>
                          <Label labelText="약정기간은 선납기간보다 클 수 없습니다." />
                        </>
                      ),
                      // width: "50rem",
                      onBtnOk: () => {},
                    }),
                  );
                  document.getElementById("contr_comp_01").focus();
                } else {
                  restData.subs_options.subs_period_month = Number(e);
                  setPeriod(e);
                }
              }}
              disabled={isEditMode}
            />
          </div>

          <div style={{ display: "flex", marginTop: "0.5rem" }}>
            <NumberTextField
              required
              id="pre_comp_02"
              labelWidth={textLabelWidth}
              textBoxWidth={textBoxWidth}
              labelText="· 선납기간"
              endAdornment="개월"
              defaultValue={restData.subs_options.pre_month}
              onBlur={(e) => {
                if (Number(e) > Number(restData.subs_options.subs_period_month)) {
                  storesDispatch(
                    globalAlertOn({
                      show: true,
                      titleText: "알림",
                      bodyText: (
                        <>
                          <Label labelText="선납기간은 약정기간보다 클 수 없습니다." />
                        </>
                      ),
                      // width: "50rem",
                      onBtnOk: () => {},
                    }),
                  );
                  document.getElementById("pre_comp_02").focus();
                } else {
                  restData.subs_options.pre_month = Number(e);
                  setPreMonth(e);
                }
              }}
              disabled={isEditMode}
            />
          </div>

          {mbshpOption === "패스권" ? (
            <div style={{ display: "flex", marginTop: "0.5rem" }}>
              <NumberTextField
                labelWidth={textLabelWidth}
                textBoxWidth={textBoxWidth}
                labelText="· 월 출입 횟수"
                endAdornment="회"
                maxValue={28}
                defaultValue={restData.pass_cnt}
                onChangeCallback={(e) => {
                  restData.pass_cnt = e;
                }}
                disabled={isEditMode}
              />
            </div>
          ) : (
            ""
          )}

          {mbshpOption === "시간권" ? (
            <div style={{ display: "flex", marginTop: "0.5rem" }}>
              <TimePicker
                id="picker_from_time"
                labelWidth={textLabelWidth}
                labelText="· 출입 가능 시간"
                textBoxWidth="8.5rem"
                defaultValue={restData.pass_from_time}
                onBlur={(e) => {
                  if (restData.pass_end_time && compareTimeBigger(e, restData.pass_end_time)) {
                    storesDispatch(
                      globalAlertOn({
                        show: true,
                        titleText: "알림",
                        bodyText: (
                          <>
                            <Label labelText="출입 종료 시간이 시작 시간보다 빠릅니다." />
                          </>
                        ),
                        // width: "50rem",
                        onBtnOk: () => {},
                      }),
                    );
                    document.getElementById("picker_from_time").focus();
                  } else {
                    restData.pass_from_time = e;
                  }
                }}
                disabled={isEditMode}
              />
              <TimePicker
                id="picker_end_time"
                labelText="~"
                textBoxWidth="8.5rem"
                defaultValue={restData.pass_end_time}
                onBlur={(e) => {
                  if (restData.pass_from_time && compareTimeBigger(restData.pass_from_time, e)) {
                    storesDispatch(
                      globalAlertOn({
                        show: true,
                        titleText: "알림",
                        bodyText: (
                          <>
                            <Label labelText="출입 종료 시간이 시작 시간보다 빠릅니다." />
                          </>
                        ),
                        // width: "50rem",
                        onBtnOk: () => {},
                      }),
                    );
                    document.getElementById("picker_end_time").focus();
                  } else {
                    restData.pass_end_time = e;
                  }
                }}
                disabled={isEditMode}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <SubLine />
    </div>
  );
};
export default ExpiredSetting;
