import { useRef, useState } from "react";

import TextField from "components/LabelInput/TextField";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";

import IconButton from "components/Button/IconButton";
import { dataEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import post_api from "../Module/api/PostApi";
import ScheduleTimeEditModal from "./ScheduleTimeEditModal";

const ScheduleEditModal = ({ modalParam, onModalDone }) => {
  //modalParam : mode(add, edit), hq_info, branch_info, itemData

  const viewModelRef = useRef();
  const storesDispatch = useDispatch();
  //저장 POST 보낼때 schedule_info에 들어갈 데이터
  const [itemInfoList, setItemInfoList] = useState(_.cloneDeep(modalParam.itemData));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      //종료될때,
      setItemInfoList(_.cloneDeep(modalParam.itemData));
    };
  }, []);

  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });

  //스케줄타임수정 모달 POP (row 클릭)
  const popEditTimeModal = (data, idx) => {
    //idx === 0 (월) ~
    const param = {
      idx: idx, //요일인덱스
      timeArr: data._ti,
    };
    setModalInfo({
      show: true,
      title: "스케줄 시간 수정",
      Content: <ScheduleTimeEditModal modalParam={param} onModalDone={editTimeModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  //스케줄타임수정 모달 DONE
  const editTimeModalDone = (timeList, dayIdx) => {
    setItemInfoList((cur) => {
      const obj = { ...cur };
      obj.sched_day_list[dayIdx]._ti = timeList;
      return obj;
    });

    viewModelRef.current.setClose();
  };

  // 스케줄 추가/수정 POST
  const saveScheduleList = async () => {
    try {
      setLoading(true);

      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const paramData = {
        hq_info: modalParam.hq_info,
        branch_info: modalParam.branch_info,
        schedule_info: itemInfoList,
      };

      const formData = new FormData();
      formData.append("param_info", JSON.stringify(paramData));
      let postApi = "";

      if (modalParam.mode === "add") {
        postApi = post_api.presetAdd();
      } else {
        postApi = post_api.presetUpdate();
      }
      const res = await HttpInstance.post(postApi, formData, config);
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label labelText="저장되었습니다." />
              </>
            ),
            onBtnOk: () => {
              //모달 닫기 + 메인리스트 갱신 + 지점리스트 갱신
              onModalDone(modalParam.branch_info.branch_id);
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const validationCheck = () => {
    if (!itemInfoList.schedule_name) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="스케줄명을 입력해주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="저장하시겠습니까?" />
            </>
          ),
          onBtnYes: () => {
            saveScheduleList();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const dataListTableItem = new dataListTable(popEditTimeModal);

  return (
    <div
      style={{
        width: "37.5rem",
      }}
    >
      {loading && <Loading />}

      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />

      <div style={{ display: "flex" }}>
        <TextField
          labelText="스케줄명"
          fullWidth
          labelWidth={"6rem"}
          defaultValue={modalParam.itemData.schedule_name}
          onBlur={(e) => {
            setItemInfoList((cur) => {
              const obj = { ...cur };
              obj.schedule_name = e.target.value;
              return obj;
            });
          }}
        />
      </div>

      <TextField
        marginTop={"0.5rem"}
        labelText="설명"
        fullWidth
        labelWidth={"6rem"}
        defaultValue={modalParam.itemData.descr}
        onBlur={(e) => {
          setItemInfoList((cur) => {
            const obj = { ...cur };
            obj.descr = e.target.value;
            return obj;
          });
        }}
      />
      <div style={{ marginTop: "0.5rem" }}>
        <CustomTable
          columns_head={dataListTableItem.columns_head}
          table_title={dataListTableItem.table_title}
          rest_call={dataListTableItem.get_data_from_rest}
          row_render={dataListTableItem.create_table}
          rest_data={itemInfoList.sched_day_list}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "0.7rem",
        }}
      >
        <NormalBtn name="저장" onClick={validationCheck} />
      </div>
    </div>
  );
};
export default ScheduleEditModal;

class dataListTable {
  table_title = "";
  popEditTimeModal = null;

  constructor(popEditTimeModal) {
    this.popEditTimeModal = popEditTimeModal;
  }

  parse_time = (dataList) => {
    let timeStr = "";
    if (dataList) {
      for (let i = 0; i < dataList.length; i++) {
        timeStr += dataList[i].from_time + "~" + dataList[i].to_time;
        if (i !== dataList.length - 1) {
          timeStr += ", ";
        }
      }
    }

    return timeStr;
  };

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.day_name}</CustomTableContents>
        <CustomTableContents>{this.parse_time(data._ti)}</CustomTableContents>
        <CustomTableContents>
          <IconButton type="edit" onClick={() => this.popEditTimeModal(data, idx)} />
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "요일", width: "15%" },
    { title: "출입 시간", width: "70%" },
    { title: "", width: "15%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
