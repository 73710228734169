import { Box } from "@mui/material";
import {
  changeComboValueToEmpty,
  checkDateOrder,
  generateObjectZeroId,
  getDefaultValue,
  getPermission,
} from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import _ from "lodash";
import { branch_search_init_state } from "pages/CommonModal/Search/Module/reducers/hq_branch_search_init_state";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const SearchCondition = ({ searchParams, setSearchParams, resetFlag, onClickSearch }) => {
  const storesDispatch = useDispatch();

  const [branchList, setBranchList] = useState(_.cloneDeep(branch_search_init_state));
  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);

  // 지점 권한 및 리스트, 첫번째 값 세팅
  useEffect(() => {
    getPermission(storesDispatch, "CS_MENU/MESSAGE", true, true).then((res) => {
      setBranchList(res);
      if (
        res.branch_list.length > 0 &&
        searchParams.branch_info.branch_id === generateObjectZeroId()
      ) {
        setSearchParams("branch_info", res.branch_list[0]);
      }
    });
  }, []);

  // 초기화 시 지점 첫번째 값 세팅
  useEffect(() => {
    if (!isFirstEnterPage && branchList.branch_list[0]) {
      setSearchParams("branch_info", branchList.branch_list[0]);
    }
  }, [resetFlag]);

  useEffect(() => {
    setIsFirstEnterPage(false);
  }, []);

  return (
    <Box
      sx={{
        border: "1px solid #dedede",
        borderRadius: "5px",
        backgroundColor: "#f5f5f5",
        height: "6rem",
        marginTop: "0.5rem",
      }}
    >
      <div style={{ margin: "0.75rem 0.75rem" }}>
        <Label labelText="검색 조건" fontWeight="bold" justifyContent="flex-start" />
        <div
          style={{ display: "flex", marginTop: "0.5rem" }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClickSearch(1);
            }
          }}
        >
          <SearchComboBox
            labelText="발신 지점"
            labelWidth="4.5rem"
            labelMarginRight="0rem"
            comboItemWidth="15rem"
            marginRight="4rem"
            data={branchList.branch_name_list}
            defaultValue={getDefaultValue("combo", searchParams.branch_info.branch_name)}
            onChangeCallback={(e, v, idx) => {
              setSearchParams("branch_info", branchList.branch_list[idx]);
            }}
          />

          <div style={{ display: "flex" }}>
            <DatePicker
              // id="mbshp_date_from"
              labelText="전송 일시"
              labelWidth="4.5rem"
              textBoxWidth="9rem"
              labelMarginRight="0rem"
              textMarginRight="0rem"
              defaultValue={getDefaultValue("field", searchParams.send_from_date)}
              onChangeCallback={(e) => {
                setSearchParams("send_from_date", e);
              }}
              onBlur={(e) => {
                if (!checkDateOrder(e, searchParams.send_to_date)) {
                  setSearchParams("send_from_date", "");
                }
              }}
            />
            <DatePicker
              marginLeft="0.12rem"
              marginRight="4rem"
              labelWidth="0.5rem"
              textBoxWidth="9rem"
              textMarginRight="0rem"
              labelMarginRight="0.12rem"
              labelText="~"
              defaultValue={getDefaultValue("field", searchParams.send_to_date)}
              onChangeCallback={(e) => {
                setSearchParams("send_to_date", e);
              }}
              onBlur={(e) => {
                if (!checkDateOrder(searchParams.send_from_date, e)) {
                  setSearchParams("send_to_date", "");
                }
              }}
            />
          </div>

          <ComboBox
            labelText="발송 구분"
            labelWidth="4.5rem"
            labelMarginRight="0rem"
            comboItemWidth="15rem"
            marginRight="4rem"
            data={["전체", "문자"]}
            defaultValue={getDefaultValue("combo", searchParams.send_method)}
            onChangeCallback={(e) => {
              changeComboValueToEmpty(setSearchParams, "send_method", e);
            }}
          />

          <ComboBox
            labelText="발송 정보"
            labelWidth="4.5rem"
            labelMarginRight="0rem"
            comboItemWidth="15rem"
            marginRight="4rem"
            data={["전체", "공지", "광고"]}
            defaultValue={getDefaultValue("combo", searchParams.sms_type)}
            onChangeCallback={(e) => {
              changeComboValueToEmpty(setSearchParams, "sms_type", e);
            }}
          />
        </div>
      </div>
    </Box>
  );
};
export default SearchCondition;
