import CardFixed from "components/Card/CardFixed";
import ComboBox from "components/LabelInput/ComboBox";
import { useState } from "react";
// import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import IconButton from "components/Button/IconButton";
import { tabBorder, tabStyle } from "components/CommonComp";
import { generateObjectZeroId } from "components/CommonLib/CommonLib";
import TabPanel from "components/TabControl/TabPanel";
import _ from "lodash";
import MembershipInfo from "pages/ERP/MemberRegister/Common/CardContents/MembershipInfo";
import SwipeableViews from "react-swipeable-views";

const SelectConsultant = ({ baseInfoState, memberInputState }) => {
  const getCsStaffNameList = () => {
    const result = [...baseInfoState.cs_team_name_list];
    result.splice(0, 0, "추후 선택");
    return result;
  };

  return (
    <div
      style={{
        display: "flex",
        float: "right",
        marginLeft: "auto",
      }}
    >
      <ComboBox
        required
        labelWidth={"4.5rem"}
        comboItemWidth={"10rem"}
        labelText="CS 상담자"
        data={getCsStaffNameList()}
        onChangeCallback={(e) => {
          const index = baseInfoState.cs_team_name_list.map((item) => item).indexOf(e.target.value);
          if (index !== -1) {
            memberInputState.cs_staff.staff_id = baseInfoState.cs_team_list.team[index].staff_id;
            memberInputState.cs_staff.staff_name =
              baseInfoState.cs_team_list.team[index].staff_name;
          } else {
            memberInputState.cs_staff.staff_id = generateObjectZeroId();
            memberInputState.cs_staff.staff_name = "추후 선택";
          }
        }}
      />
    </div>
  );
};

const TabMemberShip = ({
  regDate,
  tabIndex,
  onChangeTabIndex,
  payInfoListAction,
  subsPayInfoListAction,
  baseInfoState,
  onClickOpenModal,
  memberInputState,
  onAddMembership,
  isAddNew,
  onPtItemUpdate,
  last_to_date,
  subs_lock,
  resetPaySubInfoListAction,
  setIsSubsNow,
  memberInputStateAll,
  selected_locker_list,
  updateToDate,
}) => {
  //
  const filterBaseSetting = (item) => {
    if (item.tab_info.is_new) {
      return baseInfoState;
    } else {
      const settingCopy = _.cloneDeep(baseInfoState);
      settingCopy.prepay_items = [];
      settingCopy.subs_items = [];
      settingCopy.etc_items = [];

      if (item.purchase_item_list.item_info.mbshp_type.includes("완납")) {
        settingCopy.prepay_items.push(item.purchase_item_list.item_info);
      } else if (item.purchase_item_list.item_info.mbshp_type.includes("구독")) {
        settingCopy.subs_items.push(item.purchase_item_list.item_info);
      }

      return settingCopy;
    }
  };

  return (
    <div style={{}}>
      <div>
        <Tabs
          value={tabIndex}
          onChange={onChangeTabIndex}
          textColor="inherit"
          sx={{ minHeight: "40px", alignItems: "center" }}
          TabIndicatorProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0)" } }}
        >
          {memberInputState.map((item, idx) => {
            if (item.tab_info.is_use) {
              return (
                <Tab
                  key={`tab-${idx}`}
                  sx={{ minHeight: "40px" }}
                  label={item.tab_info.title}
                  style={tabStyle(idx, tabIndex, "10rem")}
                />
              );
            } else {
              return <div key={`tab-${idx}`}></div>;
            }
          })}

          {!isAddNew ? (
            <div
              style={{
                display: "flex",
                height: "2.75rem",
                marginLeft: "1.25rem",
                alignItems: "center",
              }}
            >
              <IconButton
                type="plus"
                scale="1.5"
                onClick={() => onAddMembership()}
                tooltip="회원권을 추가합니다"
              />
            </div>
          ) : (
            <div></div>
          )}
        </Tabs>
      </div>

      <SwipeableViews
        index={tabIndex}
        onChangeIndex={onChangeTabIndex}
        style={tabBorder}
        animateTransitions={false}
      >
        {memberInputState.map((item, idx) => {
          return item.tab_info.is_use ? (
            <TabPanel key={`panel-${idx}`} value={tabIndex} index={idx} style={{ width: "100%" }}>
              <MembershipInfo
                regDate={regDate}
                payInfoListAction={payInfoListAction}
                subsPayInfoListAction={subsPayInfoListAction}
                baseInfoState={filterBaseSetting(item)}
                onClickOpenModal={onClickOpenModal}
                orderStartIdx={idx * tabIndex}
                membershipIdx={idx}
                memberInputState={item}
                memberInputStateAll={memberInputStateAll}
                onPtItemUpdate={onPtItemUpdate}
                isAddPay={true}
                last_to_date={last_to_date}
                selected_locker_list={selected_locker_list}
                subs_lock={subs_lock}
                resetPaySubInfoListAction={resetPaySubInfoListAction}
                setIsSubsNow={setIsSubsNow}
                from_lock={false}
                updateToDate={updateToDate}
              />
            </TabPanel>
          ) : (
            <div key={`panel-${idx}`}></div>
          );
        })}
        {/* </Tabs> */}
      </SwipeableViews>
    </div>
  );
};

const AddPayMembershipContents = ({
  regDate,
  payInfoListAction,
  subsPayInfoListAction,
  baseInfoState,
  onClickOpenModal,
  memberInputState,
  onPtItemUpdate,
  resetPaySubInfoListAction,
  setIsNewMbshp,
  setIsIncludeSubs,
  setMbshpIndex,
  updateToDate,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [isAddNew, setIsAddNew] = useState(false);

  const onChangeTabIndex = (event, newIndex) => {
    setTabIndex(newIndex);
    setMbshpIndex(newIndex);

    if (memberInputState.membership_all_list[newIndex].tab_info.is_new) {
      setIsNewMbshp(true);
    } else {
      setIsNewMbshp(false);
    }
    if (memberInputState.membership_all_list[newIndex].membership_list.mbshp_type === "구독형") {
      setIsIncludeSubs(true);
    } else {
      if (
        memberInputState.membership_all_list.findIndex(
          (x) => x.membership_list.mbshp_type === "구독형",
        ) === -1
      ) {
        setIsIncludeSubs(false);
      }
    }
  };

  const onAddMembership = (e) => {
    const lastIdx = memberInputState.membership_all_list.length - 1;

    memberInputState.membership_all_list[lastIdx].tab_info.is_use = true;
    // membershipDataList[lastIdx].tab_info.is_use = true;
    // console.log("onAddMembership -->     ", lastIdx);
    // setTabIndex(lastIdx);
    setIsAddNew(true);
    onChangeTabIndex(e, lastIdx);
    // 구독 정보도 index 변경
  };
  // console.log("AddPayMembershipContents    ->   ", memberInputState);

  return (
    <CardFixed
      topDom={<div>회원권</div>}
      midDom={
        <div>
          <SelectConsultant baseInfoState={baseInfoState} memberInputState={memberInputState} />
          <TabMemberShip
            regDate={regDate}
            tabIndex={tabIndex}
            onChangeTabIndex={onChangeTabIndex}
            payInfoListAction={payInfoListAction}
            subsPayInfoListAction={subsPayInfoListAction}
            baseInfoState={baseInfoState}
            onClickOpenModal={onClickOpenModal}
            memberInputState={memberInputState.membership_all_list}
            onAddMembership={onAddMembership}
            isAddNew={isAddNew}
            onPtItemUpdate={onPtItemUpdate}
            memberInputStateAll={memberInputState}
            last_to_date={memberInputState.last_to_date}
            subs_lock={memberInputState.subs_lock}
            resetPaySubInfoListAction={resetPaySubInfoListAction}
            setIsSubsNow={setIsIncludeSubs}
            updateToDate={updateToDate}
          />
        </div>
      }
      isLoading={false}
      rootStyle={{
        width: "76.88rem",
        // height: "74.875rem",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};
export default AddPayMembershipContents;
