import * as ActionTypes from "./action_type";

///STAFF VIEW
export const loadStaffInfoView = (staff_id) => {
  return {
    type: ActionTypes.staffInfoViewAction,
    staff_id: staff_id,
  };
};

export const loadStaffInfoViewSuccess = (data) => {
  return {
    type: ActionTypes.staffInfoViewSuccessAction,
    payload: data,
  };
};

export const loadStaffInfoViewFail = (data) => {
  return {
    type: ActionTypes.staffInfoViewFailAction,
    payload: [],
  };
};

export const loadStaffInfoViewReset = (data) => {
  return {
    type: ActionTypes.staffInfoViewResetAction,
    payload: data,
  };
};

///WORK INFO LIST
export const loadStaffWorkListView = (staff_id) => {
  return {
    type: ActionTypes.staffWorkListViewAction,
    staff_id: staff_id,
  };
};

export const loadStaffWorkListViewSuccess = (data) => {
  return {
    type: ActionTypes.staffWorkListViewSuccessAction,
    payload: data,
  };
};

export const loadStaffWorkListViewFail = (data) => {
  return {
    type: ActionTypes.staffWorkListViewFailAction,
    payload: [],
  };
};

export const loadStaffWorkListViewReset = (data) => {
  return {
    type: ActionTypes.staffWorkListViewResetAction,
    payload: data,
  };
};

////WORK INFO. DETAIL
export const loadStaffWorkDetailView = (work_info_id) => {
  return {
    type: ActionTypes.staffWorkInfoViewAction,
    work_info_id: work_info_id,
    // hq_id: hq_id,
    // branch_id: branch_id,
  };
};

export const loadStaffWorkDetailViewSuccess = (data) => {
  return {
    type: ActionTypes.staffWorkInfoViewSuccessAction,
    payload: data,
  };
};

export const loadStaffWorkDetailViewFail = (data) => {
  return {
    type: ActionTypes.staffWorkInfoViewFailAction,
    payload: [],
  };
};

export const loadStaffWorkDetailViewReset = (data) => {
  return {
    type: ActionTypes.staffWorkInfoViewResetAction,
    payload: data,
  };
};

/////GX FEEEEE
////WORK INFO. DETAIL
export const loadGxFeeView = (work_info_id, gx_fee_id) => {
  return {
    type: ActionTypes.staffGxFeeInfoViewAction,
    work_info_id: work_info_id,
    gx_fee_id: gx_fee_id,
  };
};

export const loadGxFeeViewSuccess = (data) => {
  return {
    type: ActionTypes.staffGxFeeInfoViewSuccessAction,
    payload: data,
  };
};

export const loadGxFeeViewFail = (data) => {
  return {
    type: ActionTypes.staffGxFeeInfoViewFailAction,
    payload: [],
  };
};

export const loadGxFeeViewReset = (data) => {
  return {
    type: ActionTypes.staffGxFeeInfoViewResetAction,
    payload: data,
  };
};
