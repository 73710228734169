import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import CardFixed from "components/Card/CardFixed";
import { tabBorder, tabStyle } from "components/CommonComp";
import { restEmpty } from "components/CommonLib/CommonLib";
import Loading from "components/Loading/Loading/Loading";
import A11yProps from "components/TabControl/A11yProps";
import TabPanel from "components/TabControl/TabPanel";
import { useAccessMonitorSearchParam } from "hooks/apis/branch/useAccessMonitor";
import { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import AccessHistView from "./AccessHistView/AccessHistView";
import DoorHistView from "./DoorHistView/DoorHistView";
import { useAccessMonitoringStore } from "./modules/store/monitoringManageState";

const MonitoringManageView = () => {
  // 전역 상태 관리
  const { loading, setHqBranchInfo } = useAccessMonitoringStore().returnStoreByType("accessHist");

  // 페이지 상태 관리
  const [tabIndex, setTabIndex] = useState(0);
  const [hqBranchDoorCombo, setHqBranchDoorCombo] = useState();

  const onChangeTabIndex = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  // 본부, 지점, 도어 Combo 가져오기
  const { data: firstSearchParamData } = useAccessMonitorSearchParam();

  // API 받아온 데이터 세팅
  useEffect(() => {
    // 데이터가 있으면
    if (firstSearchParamData && !restEmpty(firstSearchParamData.data.data)) {
      const result = firstSearchParamData.data.data;
      if (!result.hq_list) {
        result.hq_list = [];
      }

      setHqBranchDoorCombo(result);
      // ComboBox 첫번째 본부 및 지점 및 출입문 정보
      setHqBranchInfo("hq_info", result.target_list[0].hq_info);
      setHqBranchInfo("branch_info", result.target_list[0].branch_list[0]);
      setHqBranchInfo("door_info", result.target_door_list[0].branch_list[0].door_list[0]);
    }
  }, [firstSearchParamData]);

  return (
    <>
      {loading && <Loading />}
      <CardFixed
        midDom={
          <div>
            <Box sx={{ bgcolor: "background.paper" }}>
              <Tabs
                value={tabIndex}
                onChange={onChangeTabIndex}
                textColor="inherit"
                sx={{ minHeight: "40px" }}
                TabIndicatorProps={{
                  style: { backgroundColor: "rgba(0, 0, 0, 0)" },
                }}
              >
                <Tab label="출입 이력" {...A11yProps(0)} style={tabStyle(0, tabIndex)} />
                <Tab
                  label={"문열림 기능\n사용 이력"}
                  {...A11yProps(1)}
                  style={tabStyle(1, tabIndex)}
                />
              </Tabs>
            </Box>

            <SwipeableViews
              index={tabIndex}
              onChangeIndex={onChangeTabIndex}
              style={tabBorder}
              animateTransitions={false}
            >
              <TabPanel value={tabIndex} index={0}>
                {hqBranchDoorCombo ? (
                  <AccessHistView hqBranchDoorCombo={hqBranchDoorCombo} />
                ) : (
                  <></>
                )}
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                {hqBranchDoorCombo ? <DoorHistView hqBranchDoorCombo={hqBranchDoorCombo} /> : <></>}
              </TabPanel>
            </SwipeableViews>
          </div>
        }
        isLoading={false}
        rootStyle={{
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />
    </>
  );
};
export default MonitoringManageView;
