import { put, call } from "redux-saga/effects";
// import get_api from "../../../../../lib/LockerSettings/GetApi";
import get_api from "../api/GetApi";
import { AccessLogSearchAction } from "../actions/index";
import { globalStartLoading, globalFinishLoading } from "modules/actions/Loading/index";

export function* getAccessLogSearchData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getAccessLogSearch, param);

    if (data.data === null) {
      yield put(AccessLogSearchAction.loadAccessLogSuccess(false));
    } else {
      yield put(AccessLogSearchAction.loadAccessLogSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(AccessLogSearchAction.loadAccessLogFail(error));
    yield put(globalFinishLoading());
  }
}

export function* getDoorLogSearchData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getDoorLogSearch, param);

    if (data.data === null) {
      yield put(AccessLogSearchAction.loadDoorLogSearchSuccess(false));
    } else {
      yield put(AccessLogSearchAction.loadDoorLogSearchSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(AccessLogSearchAction.loadDoorLogSearchFail(error));
    yield put(globalFinishLoading());
  }
}
