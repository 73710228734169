import { simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
// import { getNavigatorPath } from "./commonUtil";

export const newPostPayMethodAPi = async (
  // navigate,
  storesDispatch,
  stateData,
) => {
  try {
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    // const paramData = {
    //   member_id:
    // }
    // const formData = new FormData();
    // formData.append("new_data", JSON.stringify(stateData));
    // console.log(stateData);
    const res = await HttpInstance.get(
      process.env.REACT_APP_SUBS_PAY_SERVER + "/subscription-regist/new",
      stateData,
      config,
    );
    if (res.data.msg) {
      simpleAlert(storesDispatch, "ERROR", res.data.msg);
    } else {
      //성공
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          reqConfirm: true,
          bodyText: (
            <>
              <Label labelText="추가 되었습니다." />
            </>
          ),
          onBtnOk: () => {
            // navigate(getNavigatorPath(stateData));
          },
        }),
      );
    }
  } catch (err) {
    console.log(err);
    simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
  }
};
