import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";

import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import HQSetting from "./HQSetting";

import { dataEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import HQSetIndex from "./Modals/HQSetIndex";
import { loadHqBase, loadHqBaseReset } from "./Module/actions/index";

const SettingWrapper = () => {
  const { state } = useLocation();
  const params = useParams();
  const [isParamReady, setIsParamReady] = useState(false);
  const [hqId, setHqId] = useState("");
  // 계약서 관리 => 지점 관리페이지 바로 이동위한 state 값 세팅
  const [tabIndex, setTabIndex] = useState(state ? state : 0);
  const storesDispatch = useDispatch();

  const restData = useSelector((state) => state.hq_set_state.hq_base_state);

  // console.log(restData);

  useEffect(() => {
    if (!params.targetId) {
      HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/hq_set/hq_selector", { params: {} })
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.data.hq_list.length > 0) {
            params.targetId = res.data.data.hq_list[0].hq_id;
            setIsParamReady(true);
          }
        })
        .catch((err) => {
          console.log(err);
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        });
    } else {
      setIsParamReady(true);
    }
  }, []);

  useEffect(() => {
    if (isParamReady) {
      if (params.targetId !== "") {
        storesDispatch(loadHqBase(params.targetId));
        setHqId(params.targetId);
      }
    }
    return () => {
      storesDispatch(loadHqBaseReset());
    };
  }, [loadHqBase, storesDispatch, isParamReady]);

  const openModal = (select, param) => {
    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,

        Content: <HQSetIndex modalSelect={select} modalParam={param} onModalDone={onModalDone} />,
      }),
    );
  };

  const onModalDone = (refreshStore) => {
    storesDispatch(globalModalOff());
    if (!dataEmpty(refreshStore)) {
      refreshStore();
    }
  };

  const onClickOpenModal = (order, param) => {
    if (order !== "") {
      // lockerModelRef.current.setOpen();
      openModal(order, param);
    }
  };

  const onChangeHq = (hqId) => {
    storesDispatch(loadHqBase(hqId));
    setHqId(hqId);
  };

  return (
    <PageFrame
      menuActive="본부 설정"
      parentMenu={{ index: true }}
      children={
        <div>
          <PageTitle titleText="본부 설정" />
          <HQSetting
            onClickOpenModal={onClickOpenModal}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            hqBaseData={restData.stores.data}
            hqId={hqId || params.targetId}
            onChangeHq={onChangeHq}
          />
        </div>
      }
    />
  );
};
export default SettingWrapper;
