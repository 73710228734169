import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import MemberViewModalIndex from "pages/ERP/MemberView/Modal/MemberViewModalIndex";
import { useDispatch } from "react-redux";

const LongPostponeResultTable = ({ searchResult, curPage, totalPage, onClickSearch }) => {
  const btnStyleClass = btnStyles();

  const storesDispatch = useDispatch();

  const doSearch = (page) => {
    onClickSearch(false, page);
  };

  const openModal = (select, param, doneCallback) => {
    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,
        Content: (
          <MemberViewModalIndex
            modalSelect={select}
            modalParam={param}
            onModalDone={doneCallback}
          />
        ),
      }),
    );
  };

  const onClickOpenModal = (order, param, doneCallback) => {
    if (order !== "") {
      if (dataEmpty(doneCallback)) {
        openModal(order, param, onModalDone);
      } else {
        openModal(order, param, doneCallback);
      }
    }
  };
  const onModalDone = () => {
    storesDispatch(globalModalOff());
  };

  const popPostponeModal = (data) => {
    const idx = data.search_param.membership_list.findIndex(
      (x) => x.membership_id === data.active_postpone.membership_id,
    );
    if (idx !== -1) {
      const param = {
        title: "회원권 연기",
        modalParam: {
          memberId: data.member_info.member_id,
          branchId: data.branch_info.branch_id,
          membershipId: data.active_postpone.membership_id,
          mbshp_type: data.search_param.membership_list[idx].mbshp_type,
          mbshp_status: data.search_param.membership_list[idx].mbshp_status,
        },
      };
      onClickOpenModal("연기", param);
    }
  };

  const searchResultList = new resultTable(popPostponeModal);

  return (
    <div>
      <div
        style={{
          display: "flex",
          float: "right",
          marginLeft: "auto",
          marginBottom: "0.625rem",
        }}
      >
        <Button
          className={clsx({
            [btnStyleClass.btnRootWhite]: true,
            [btnStyleClass.buttonType6]: true,
          })}
          onClick={() => {}}
          style={{ marginLeft: "0.5rem" }}
        >
          목록 저장
        </Button>
      </div>

      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
        // width="160rem"
      />
      <CustomPagination curPage={curPage} totalCnt={totalPage} doSearch={doSearch} />
    </div>
  );
};
export default LongPostponeResultTable;

class resultTable {
  table_title = "";
  // onNavigate = null;
  onPostponePopUp = null;

  constructor(onPopup) {
    this.onPostponePopUp = onPopup;
  }

  getDateDiff = (d2) => {
    var today = new Date();
    let d1 = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

    const date1 = new Date(d1);
    const date2 = new Date(d2);

    const diffDate = date1.getTime() - date2.getTime();
    let diffDay = Math.abs(diffDate / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일

    if (diffDay > 0) {
      return diffDay;
    } else {
      return 0;
    }
  };

  create_table = (data, idx) => {
    return (
      <CustomRow
        style={{}}
        key={idx}
        onClick={(e) => {
          if (e.target.nodeName === "DIV" || e.target.nodeName === "TD") {
            // this.onClickRow(idx);
          }
        }}
        hover
      >
        <CustomTableContents>{data.active_postpone.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_name}</CustomTableContents>
        <CustomTableContents>{data.search_param.base_info.phone}</CustomTableContents>
        <CustomTableContents>{data.active_postpone.reg_info.reg_name}</CustomTableContents>
        <CustomTableContents>{data.active_postpone.from_date}</CustomTableContents>
        <CustomTableContents>
          {data.active_postpone.stop_date || data.active_postpone.to_date}
        </CustomTableContents>
        <CustomTableContents>{data.active_postpone.remarks}</CustomTableContents>
        <CustomTableContents>
          {this.getDateDiff(data.active_postpone.file_due_date)} 일
        </CustomTableContents>
        <CustomTableContents>{data.active_postpone.file_due_date}</CustomTableContents>
        <CustomTableContents>{data.active_postpone.doc_status}</CustomTableContents>
        <CustomTableContents>{data.active_postpone.approve_status}</CustomTableContents>
        <CustomTableContentsEND>
          <IconButton type="detail" onClick={() => this.onPostponePopUp(data)} />
        </CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "연기 신청일", width: "7.1%" },
    { title: "지점", width: "7.1%" },
    { title: "회원번호", width: "7.1%" },
    { title: "이름", width: "7.1%" },
    { title: "연락처", width: "7.1%" },
    { title: "담당자", width: "7.1%" },
    { title: "연기 시작일", width: "7.1%" },
    { title: "연기 종료일", width: "7.1%" },
    { title: "사유", width: "7.1%" },
    { title: "미등록 경과일", width: "7.1%" },
    { title: "서류 제출 기한", width: "7.1%" },
    { title: "서류 제출 상태", width: "7.1%" },
    { title: "승인 상태", width: "7.1%" },
    { title: "", width: "7.1%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
