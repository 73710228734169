import Cookies from "cookies-js";
import { accessServiceReducer } from "pages/AccessManage/ServiceRedux/reducers";
import { reduxReducerDepth2 } from "pages/ActionGroupRedux/reducers/index depth2";
import { approvalServiceReducer } from "pages/ApprovalManage/ServiceRedux/reducers";
import { branchSettingReducer } from "pages/Branch/BranchSetting/Module/reducers";
import { erpSettingReducer } from "pages/Branch/ERPSetting/Module/reducers";
import { hqSettingReducer } from "pages/Branch/HQSetting/Module/reducers";
import { hqStaffPermission } from "pages/Branch/HQSetting/Module/reducers/staff_list";
import { searchModalReducer } from "pages/CommonModal/Search/Module/reducers";
import { lockerSettingReducer } from "pages/ERP/LockerService/Module/reducers";
import { memberCommonReducer } from "pages/ERP/MemberCommonItems/Module/reducers";
import { memberServiceReducer } from "pages/ERP/MemberServiceRedux/reducers";
import { memberHistoryReducer } from "pages/ERP/MemberView/MemberViewHistoryRedux/reducers";
import { staffServiceReducer } from "pages/Staff/StaffServiceRedux/reducers";
import { authPermissiongReducer } from "permissions//Module/reducers";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { CookieStorage } from "redux-persist-cookie-storage";
import { auth_info, state_auth_info } from "./authReducer";

import { alert_state } from "./alert_reducer";
import {
  cs_detail_health_info,
  cs_filter_info,
  cs_info,
  cs_list,
  cs_task_list,
} from "./cs_reducer";
import { global_loading } from "./loading_reducer";
import { test_state } from "./test_reducer";

import { modal_state } from "./modal_reducer";

const persistConfig = {
  key: "root",
  // localStorage에 저장합니다.
  storage: new CookieStorage(Cookies),
  // storage: sessionStorage,
  // auth, board, studio 3개의 reducer 중에 auth reducer만 localstorage에 저장합니다.
  // whitelist: ["auth_info", "state_auth_info", "global_auth"],
  whitelist: ["auth_info", "state_auth_info"],
  // blacklist -> 그것만 제외합니다
};

const rootReducer = combineReducers({
  auth_info,
  state_auth_info,
  global_loading,
  test_state,
  alert_state,
  modal_state,
  locker_state: lockerSettingReducer,
  member_common_state: memberCommonReducer,
  erp_set_state: erpSettingReducer,
  hq_set_state: hqSettingReducer,
  hq_staff_state: hqStaffPermission,
  branch_set_state: branchSettingReducer,
  global_auth: authPermissiongReducer,
  member_service: memberServiceReducer,
  member_history: memberHistoryReducer,
  state_depth2: reduxReducerDepth2,
  search_modal: searchModalReducer,
  staff_service: staffServiceReducer,
  approval_service: approvalServiceReducer,
  access_service: accessServiceReducer,

  cs_task_list,
  cs_list,
  cs_info,
  cs_filter_info,
  cs_detail_health_info,
});

// export default rootReducer;
export default persistReducer(persistConfig, rootReducer);
