import { makeStyles } from "@material-ui/styles";

const menuBarStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "left",
  },
  drawer: {
    // paddingTop : "20px",
    width: "11.25rem",
    marginTop: "3.75rem",
    zIndex: 1,
  },
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: (props) => ({
    position: "relative",
    width: "11.25rem",
    height: "3rem",
    justifyContent: "flex-start",
    textTransform: "none",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "1rem",
    fontWeight: props.isActive || props.isSelected ? "normal" : "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    borderRadius: "0px",
    backgroundColor: props.isActive ? "#032063" : props.isSelected ? "#2B4B8B" : "#FFF",
    color: props.isActive || props.isSelected ? "#FFF" : "#222",
    "&:hover": {
      backgroundColor: props.isActive || props.isSelected ? "#4C679D" : "#E8EDF7",
    },
  }),
  buttonChild: (props) => ({
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.975rem",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.54",
    letterSpacing: "-0.13px",
    textAlign: "left",
    color: props.isActive || props.isSelected ? "#032063" : "#222",
    fontWeight: props.isActive || props.isSelected ? "bold" : "normal",
    borderLeft: props.isActive || props.isSelected ? "4px solid #032063" : "",
    borderRadius: "0px",
    "&:hover": {
      backgroundColor: props.isActive || props.isSelected ? "#4C679D" : "#E8EDF7",
      fontWeight: props.isActive || props.isSelected ? "normal" : "bold",
      color: props.isActive || props.isSelected ? "#FFF" : "#032063",
      borderLeft: "4px solid #032063",
    },
  }),
  buttonDoubleDepth: {
    width: "100%",
    justifyContent: "start",
  },
  buttonDoubleDepthChild: (props) => ({
    width: "100%",
    fontFamily: "AppleSDGothicNeo",
    fontWeight: "400",
    display: "flex",
    justifyContent: "start",
    fontSize: "0.9rem",
    paddingLeft: "2.55rem",
    backgroundColor: props.isActive ? "#032063" : "rgba(3, 32, 99, 0.05)",
    color: props.isActive ? "#FFF" : "#032063",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#4C679D",
      color: "#FFF",
    },
  }),

  buttonActive: {
    borderRadius: "0px",
  },
  btnRoot: {
    paddingLeft: "1.5rem",
    justifyContent: "left !important",
  },
  subMenu: {
    // paddingLeft : "50px !important",
    // marginLeft: "1.875rem !important",
    paddingLeft: "2.25rem",

    "&:hover": {
      color: "#032063",
    },
  },
}));
export default menuBarStyles;
