import { dataEmpty } from "components/CommonLib/CommonLib";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import { useDispatch } from "react-redux";
import CardManageView from "./CardManageView";
import CardManageModalIndex from "./Modal/CardManageModalIndex";

const CardManageWrapper = () => {
  const storesDispatch = useDispatch();

  const openModal = (select, param, doneCallback) => {
    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,
        Content: (
          <CardManageModalIndex
            modalSelect={select}
            modalParam={param}
            onModalDone={doneCallback}
          />
        ),
      }),
    );
  };

  const onClickOpenModal = (order, param, doneCallback) => {
    if (order !== "") {
      if (dataEmpty(doneCallback)) {
        openModal(order, param, onModalDone);
      } else {
        openModal(order, param, doneCallback);
      }
    }
  };
  const onModalDone = () => {
    storesDispatch(globalModalOff());
  };

  return <CardManageView onClickOpenModal={onClickOpenModal} />;
};
export default CardManageWrapper;
