import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { FormControl } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { DatePicker as DatePickerComp, LocalizationProvider } from "@mui/x-date-pickers";
import { ko } from "date-fns/locale";
import { useEffect, useState } from "react";
// import TextFieldDatePicker from "./TextFieldDatePicker";
import { dateToYearMonth } from "components/CommonLib/CommonLib";

const MonthPicker = (props) => {
  const [value, setValue] = useState(null);
  const [open, setOpen] = useState(false);

  const hStyle = {
    justifyContent: props.justifyContent,
    display: "flex",
    alignItems: "center",
    marginTop: props.marginTop, // was "0.625rem"
    marginRight: props.marginRight ? props.marginRight : "0.625rem", // was "0.625rem"
    marginLeft: props.marginLeft,
    fontFamily: "AppleSDGothicNeo",
  };

  const labelStyle = {
    width: props.labelWidth,
    height: "2.25rem",
    marginRight: props.labelMarginRight === undefined ? "0.625rem" : props.labelMarginRight,
    marginLeft: props.labelMarginLeft,
    alignItems: "center",
    display: "flex",

    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.938rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
  };

  const textBoxStyle = {
    width: props.textBoxWidth || props.minWidth,
    height: "2.25rem",
    marginRight: props.textMarginRight === undefined ? "0.625rem" : props.textMarginRight,
    borderRadius: "5px",
    backgroundColor: "#fff",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.9rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000",
  };

  useEffect(() => {
    if (props.defaultValue !== undefined) {
      setValue(new Date(props.defaultValue));
    } else {
      setValue("");
    }
  }, [props.defaultValue]);
  const datePickerStyle = {
    "& .MuiPickersMonth-root button": {
      fontFamily: "AppleSDGothicNeo",
    },
  };

  return (
    <LocalizationProvider locale={ko} dateAdapter={AdapterDateFns}>
      <Box style={hStyle}>
        <div style={labelStyle}>{props.labelText}</div>

        <FormControl
          sx={{
            width: props.minWidth || "100%",
          }}
        >
          <DatePickerComp
            open={open}
            onClose={() => setOpen(false)}
            inputRef={props.inputRef}
            inputFormat={"yyyy-mm"}
            openTo={"month"}
            views={["year", "month"]}
            value={value}
            onChange={(newValue) => {
              setValue(newValue);
              if (props.onChangeCallback) {
                props.onChangeCallback(dateToYearMonth(newValue));
              }
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  error={false}
                  size="small"
                  onClick={(e) => setOpen(!props.disabled && true)}
                  autoComplete={props.autoComplete ? props.autoComplete : "off"}
                />
              );
            }}
            disabled={props.disabled}
            inputProps={{
              readOnly: props.readonly,
              value: dateToYearMonth(value),
            }}
            components={{
              Popover: {
                style: datePickerStyle,
              },
            }}
            InputProps={{ style: textBoxStyle }}
            minDate={props.minDate ? new Date(props.minDate) : new Date("2022-01-01")}
            maxDate={props.maxDate ? new Date(props.maxDate) : new Date("2099-12-31")}
          />
        </FormControl>
      </Box>
    </LocalizationProvider>
  );
};

export default MonthPicker;
