import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { loadAddPayViewStore } from "pages/ERP/MemberRegister/Register/Module/actions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import ChangeItemWrapper from "./ChangeItemWrapper";

const ChangeItemService = () => {
  const { state } = useLocation();
  // const branchSettingInfoState = useSelector((state) => state.member_service.register_settings);
  const memberInputState = useSelector((state) => state.member_service.register_view_state);
  // console.log(state);
  const storesDispatch = useDispatch();

  useEffect(() => {
    // storesDispatch(registerSettingAction.registerSettingInfo());
    storesDispatch(loadAddPayViewStore({ member_id: "", reg_date: state.reg_date }));
  }, [state.member_id]);

  // console.log(branchSettingInfoState);

  return (
    <PageFrame
      menuActive="설정"
      parentMenu={{ index: true }}
      children={
        <div>
          <PageTitle titleText="상품변경" />
          <ChangeItemWrapper
            memberInputState={memberInputState.stores.data}
            memberId={state.member_id}
            branchId={state.branch_id}
            memberBaseInfoState={state.memberBaseInfoState}
            initRegDate={state.reg_date}
          />
        </div>
      }
    />
  );
};
export default ChangeItemService;
