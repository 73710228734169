import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { btnStyles } from "components/ButtonStyle/ButtonStyle";

import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import { loadClassHistoryStore } from "../../Module/actions";

const ClassHistoryTable = ({ memberId }) => {
  const storesDispatch = useDispatch();
  const [curPage, setCurPage] = useState(1);

  const historyInfoState = useSelector((state) => state.member_history.class_history_state);

  useEffect(() => {
    storesDispatch(loadClassHistoryStore(memberId));
  }, [memberId]);

  //페이지네이션
  useEffect(() => {
    if (curPage) {
      storesDispatch(loadClassHistoryStore(memberId, curPage));
    }
  }, [curPage]);

  const classHistoryTable = new classHistoryClass();
  return (
    <div>
      <CustomTable
        columns_head={classHistoryTable.columns_head}
        table_title={classHistoryTable.table_title}
        rest_call={classHistoryTable.get_data_from_rest}
        row_render={classHistoryTable.create_table}
        rest_data={historyInfoState.stores.data}
      />
      <CustomPagination
        curPage={curPage}
        doSearch={setCurPage}
        totalCnt={historyInfoState.stores.total_page ? historyInfoState.stores.total_page : 1}
      />
    </div>
  );
};
export default ClassHistoryTable;

class classHistoryClass {
  table_title = "";
  btnStyleClass = btnStyles();

  create_table = (data, idx) => {
    // const noteArr = data.note.split("\n");
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.reg_date}</CustomTableContents>
        <CustomTableContents>{data.type}</CustomTableContents>

        <CustomTableContents>{data.description}</CustomTableContents>
        <CustomTableContents>{data.change_cnt}</CustomTableContents>
        <CustomTableContents>{data.class_manager.staff_name}</CustomTableContents>

        <CustomTableContents>{data.remain_cnt}</CustomTableContents>
        <CustomTableContents>{data.reg_info.reg_name}</CustomTableContents>
        <CustomTableContents>
          {data.note}
          {/* {noteArr[0]} {noteArr.length >= 1 && <br />}
          {noteArr[1]} {noteArr.length >= 2 && <br />}
          {noteArr[2]} {noteArr.length >= 3 && <br />} */}
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "등록일시", width: "12%" },
    { title: "지점", width: "8%" },
    { title: "반영일", width: "7%" },
    { title: "구분", width: "6%" },
    { title: "계약상품", width: "15%" },
    { title: "변경 횟수", width: "6%" },
    { title: "수업 담당자", width: "7%" },
    { title: "잔여 횟수", width: "7%" },
    { title: "담당자", width: "7%" },
    { title: "비고", width: "25%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
