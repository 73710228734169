import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import NumberTextField from "components/LabelInput/NumberTextField";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import _ from "lodash";
import { getIsViewLock } from "pages/ApprovalManage/ApprovalCommon/checker";
import { implementation_info } from "pages/ApprovalManage/Module/reducers/approval_content_state";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

const SpendProofForm = ({ mode, contentState, setContentState, onClickLedger, accountList }) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();

  const readOnly =
    mode === "approver" || mode === "unspent" || mode === "view" || mode === "ledger";

  const [, updateState] = useState(); // 강제 렌더링용
  const forceUpdate = useCallback(() => updateState({}), []);

  const onClickAddRow = () => {
    const clone = _.cloneDeep(contentState.item_list);
    clone.push(_.cloneDeep(implementation_info));
    clone.item_id = uuidv4();
    setContentState({
      ...contentState,
      item_list: clone,
    });
    // forceUpdate();
  };

  const onClickDelete = (id) => {
    const delIdx = contentState.item_list.findIndex((x) => x.item_id === id);
    contentState.item_list.splice(delIdx, 1);
    // const clone = _.cloneDeep(contentState.item_list);
    // clone.splice(idx, 1);

    // setContentState({
    //   ...contentState,
    //   item_list: clone,
    // });
    forceUpdate();
  };

  const getBoxSize = () => {
    let boxSize = {
      width: "88rem",
      height: "35rem",
    };
    if (mode === "unspent" || mode === "ledger") {
      return {
        width: "100%",
        height: "100%",
      };
    } else {
      return boxSize;
    }
  };

  const contentList = new contentTable(mode, readOnly, onClickDelete, onClickLedger, accountList);

  if (mode === "unspent") {
    return (
      <div style={{ margin: "0.5rem 0.1rem" }}>
        <CustomTable
          columns_head={contentList.columns_head_unspent}
          table_title={contentList.table_title}
          rest_call={contentList.get_data_from_rest}
          row_render={contentList.create_table}
          rest_data={contentState && contentState.item_list}
        />
      </div>
    );
  } else {
    return (
      <div>
        <div style={{ margin: "0.5rem 0.1rem" }}>
          <CustomTable
            columns_head={contentList.columns_head}
            table_title={contentList.table_title}
            rest_call={contentList.get_data_from_rest}
            row_render={contentList.create_table}
            rest_data={contentState && contentState.item_list}
            width={getBoxSize().width}
            height={getBoxSize().height}
          />
        </div>
        {mode === "ledger" ? (
          <div></div>
        ) : (
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.25rem" }}>
            <Button
              className={clsx({
                [btnStyleClass.btnRootWhite]: true,
                [btnStyleClass.buttonType7]: true,
              })}
              onClick={() => {
                onClickAddRow();
              }}
              disabled={getIsViewLock(mode)}
            >
              행 추가
            </Button>
          </div>
        )}
      </div>
    );
  }
};
export default SpendProofForm;

//

class contentTable {
  table_title = "";
  onClickDelete = null;
  readOnly = null;
  mode = null;
  onClickLedger = null;
  accountList = null;

  constructor(mode, readOnly, onClickDelete, onClickLedger, accountList) {
    this.mode = mode;
    this.onClickDelete = onClickDelete;
    this.readOnly = readOnly;
    this.onClickLedger = onClickLedger;
    this.accountList = accountList;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow
        style={{}}
        key={idx}
        // onClick={(e) => {
        //   if (e.target.nodeName === "DIV" || e.target.nodeName === "TD") {
        //     this.onClickRow(idx);
        //   }
        // }}
        // hover
      >
        <CustomTableContents>
          <DatePicker
            fullWidth
            labelMarginRight="0rem"
            textMarginRight="0rem"
            defaultValue={data.pay_date}
            onChangeCallback={(e) => {
              data.pay_date = e;
            }}
            disabled={this.readOnly}
          />
        </CustomTableContents>
        <CustomTableContents>
          <TextField
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            defaultValue={data.breakdown}
            onChangeCallback={(e) => {
              data.breakdown = e;
            }}
            disabled={this.readOnly}
          />
        </CustomTableContents>
        <CustomTableContents>
          <NumberTextField
            required
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            defaultValue={data.price}
            onChangeCallback={(e) => {
              data.price = e;
            }}
            disabled={this.readOnly}
            isNumber
            endAdornment="원"
          />
        </CustomTableContents>
        <CustomTableContents>
          <NumberTextField
            required
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            defaultValue={data.tax_price}
            onChangeCallback={(e) => {
              data.tax_price = e;
            }}
            disabled={this.readOnly}
            isNumber
            endAdornment="원"
          />
        </CustomTableContents>
        <CustomTableContents>
          <NumberTextField
            required
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            defaultValue={data.total_price}
            onChangeCallback={(e) => {
              data.total_price = e;
            }}
            disabled={this.readOnly}
            isNumber
            endAdornment="원"
          />
        </CustomTableContents>
        <CustomTableContents>
          <ComboBox
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            data={["카드", "현금", "이체", "기타"]}
            defaultValue={data.pay_method}
            onChangeCallback={(e) => {
              data.pay_method = e.target.value;
            }}
            disabled={this.readOnly}
          />
        </CustomTableContents>
        <CustomTableContents>
          <SearchComboBox
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            data={this.accountList}
            defaultValue={data.account}
            onChangeCallback={(e, v) => {
              data.account = v;
            }}
            disabled={this.readOnly}
          />
        </CustomTableContents>
        <CustomTableContents>
          <ComboBox
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            data={["카드", "간이영수증", "현금영수증"]}
            defaultValue={data.tax_invoice}
            onChangeCallback={(e) => {
              data.tax_invoice = e.target.value;
            }}
            disabled={this.readOnly}
          />
        </CustomTableContents>

        {this.mode === "unspent" ? (
          <>
            <CustomTableContents>
              <DatePicker
                fullWidth
                labelMarginRight="0rem"
                textMarginRight="0rem"
                defaultValue={data.ledger_date}
                onChangeCallback={(e) => {
                  data.ledger_date = e;
                }}
                disabled={data.is_expenditure}
              />
            </CustomTableContents>
            <CustomTableContentsEND>
              {data.is_expenditure ? (
                <></>
              ) : (
                <IconButton
                  type="attendance"
                  onClick={() => this.onClickLedger(data)}
                  tooltip="지출 내역을 확정합니다."
                />
              )}
            </CustomTableContentsEND>
          </>
        ) : (
          <CustomTableContentsEND>
            {!this.readOnly ? (
              <IconButton type="delete" onClick={() => this.onClickDelete(idx)} />
            ) : (
              <></>
            )}
          </CustomTableContentsEND>
        )}
      </CustomRow>
    );
  };

  columns_head = [
    { title: "결제일", width: "10%" },
    { title: "내역", width: "19%" },
    { title: "공급가액", width: "10%" },
    { title: "부가세액", width: "10%" },
    { title: "총계", width: "10%" },
    { title: "결제수단", width: "7%" },
    { title: "계정", width: "13%" },
    // { title: "세금계산서 발행1", width: "10%" },
    { title: "지출 증빙 발행", width: "10%" },
    { title: "", width: "5%" },
  ];

  columns_head_unspent = [
    { title: "결제일", width: "10%" },
    { title: "내역", width: "15%" },
    { title: "공급가액", width: "10%" },
    { title: "부가세액", width: "10%" },
    { title: "총계", width: "10%" },
    { title: "결제수단", width: "7%" },
    { title: "계정", width: "13%" },
    { title: "세금계산서 발행", width: "10%" },
    { title: "지출일", width: "7%" },
    { title: "", width: "5%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
