import { restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SalaryEmployee from "./SalaryEmployee";

const INIT = {
  reason: "",
  salary_date: "",
  salary_deduct: 0,
  salary_report_id: "",
  salary_type: "",
  salary_type_list: [],
  staff_id: "",
  staff_info_list: [],
  staff_name_list: [],
};
const SalaryCurrentInfoView = ({
  onClickOpenModal,
  salaryReportId,
  is_edit,
  branchName,
  baseDate,
}) => {
  const storesDispatch = useDispatch();
  const [staffListInfo, setStaffListInfo] = useState(INIT);
  const [salaryOverallData, setSalaryOverallData] = useState();

  useEffect(() => {
    getSalaryOverallData();
    getSalaryComboData();
  }, [salaryReportId]);

  const getSalaryOverallData = async () => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_SALARY_SERVER + "/salary/overall_list",
        {
          params: {
            salary_report_id: salaryReportId,
            is_edit: is_edit,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setSalaryOverallData(res.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  const getSalaryComboData = async () => {
    try {
      const res = await HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + "/salary/modal", {
        params: {
          salary_report_id: salaryReportId,
          is_edit: is_edit,
        },
      });
      if (!restEmpty(res.data.data)) {
        setStaffListInfo(res.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  return (
    <>
      {salaryOverallData ? (
        <SalaryEmployee
          staffListInfo={staffListInfo}
          salaryOverallData={salaryOverallData.data}
          onClickOpenModal={onClickOpenModal}
          finalTotalSalary={salaryOverallData.final_total_salary}
          salaryReportId={salaryReportId}
          is_edit={is_edit}
          branchName={branchName}
          baseDate={baseDate}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default SalaryCurrentInfoView;
