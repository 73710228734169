import { forwardRef, useImperativeHandle, useRef } from "react";
import MembershipCardItem from "./NormalType/MembershipCardItem";
import MembershipFamilyItem from "./NormalType/MembershipFamilyItem";
import MembershipSideItem from "./NormalType/MembershipSideItem";
import SubscriptCardItem from "./SubscriptType/SubscriptCardItem";
import SubscriptFamilyItem from "./SubscriptType/SubscriptFamilyItem";
import SubscriptSideItem from "./SubscriptType/SubscriptSideItem";

const MembershipDetail = forwardRef((props, ref) => {
  const {
    regDate,
    payInfoListAction,
    subsPayInfoListAction,
    onClickOpenModal,
    membershipIdx,
    itemIncludeRef,
    memberInputState,
    memberInputStateAll,
    isAddPay,
    baseInfoState,
    from_lock,
    selectorInfo,
    last_to_date,
    updateToDate,
    preInitCls,
    subsInitCls,
    defaultFromDate,
    onSubsItemChange,
    noReg,
    changeItem,
    onPtItemUpdate,
  } = props;

  const sideRef = useRef();
  const changeSubsFromDate = () => {
    onChangeSubsFromDate();
    sideRef.current.updateProrate();
  };

  useImperativeHandle(ref, () => ({
    setEffectItemSelect: (itemIdx) => {
      return onChangeItemSelect(itemIdx);
    },
  }));

  // 초기화???
  const onChangeItemSelect = (itemIdx) => {
    // if (isAddPay === false) {
    //   setSubsInitCls(
    //     new subsItemInitializer(
    //       memberInputState,
    //       payInfoListAction,
    //       subsPayInfoListAction,
    //       membershipIdx,
    //     ),
    //   );
    // }

    if (memberInputState.membership_list.item_type_idx === 1) {
      subsInitCls.remappingState();
    } else {
      preInitCls.remappingState();
    }
  };

  const onChangeSubsFromDate = () => {
    subsInitCls.makePrePrice();
  };

  const onChangeSubsDis = (event) => {
    return subsInitCls.editDis(event);
  };

  const setLockerProratePrice = (lockerIdx) => {
    subsInitCls.setLockerProrate(lockerIdx);
  };

  if (selectorInfo.mbshp_type.includes("구독")) {
    return (
      <div>
        <SubscriptCardItem
          selectorInfo={selectorInfo}
          subsPayInfoListAction={subsPayInfoListAction}
          memberInputState={memberInputState}
          memberInputStateAll={memberInputStateAll}
          membershipIdx={membershipIdx}
          payInfoListAction={payInfoListAction}
          onClickOpenModal={onClickOpenModal}
          itemIncludeRef={itemIncludeRef}
          onChangeSubsFromDate={changeSubsFromDate}
          onChangeSubsDis={onChangeSubsDis}
          hqBranchPolicy={baseInfoState}
          isAddPay={isAddPay}
          from_lock={from_lock}
          last_to_date={last_to_date}
          onSubsItemChange={onSubsItemChange}
        />
        <SubscriptSideItem
          ref={sideRef}
          regDate={regDate}
          subsPayInfoListAction={subsPayInfoListAction}
          payInfoListAction={payInfoListAction}
          membershipIdx={membershipIdx}
          itemIncludeRef={itemIncludeRef}
          memberInputState={memberInputState}
          onChangeSubsDis={onChangeSubsDis}
          hqBranchPolicy={baseInfoState}
          selectorInfo={selectorInfo}
          memberId={memberInputStateAll.member_id}
          setLockerProratePrice={setLockerProratePrice}
          personalInfo={memberInputStateAll.personal_info}
        />
        {memberInputState.membership_list.family_info.is_use ? (
          <SubscriptFamilyItem
            subsPayInfoListAction={subsPayInfoListAction}
            payInfoListAction={payInfoListAction}
            membershipIdx={membershipIdx}
            itemIncludeRef={itemIncludeRef}
            memberInputState={memberInputState}
            onChangeSubsDis={onChangeSubsDis}
            hqBranchPolicy={baseInfoState}
            personalInfo={memberInputStateAll.personal_info}
          />
        ) : (
          ""
        )}
      </div>
    );
  } else {
    return (
      <div>
        <MembershipCardItem
          selectorInfo={selectorInfo}
          memberInputState={memberInputState}
          memberInputStateAll={memberInputStateAll}
          membershipIdx={membershipIdx}
          defaultFromDate={defaultFromDate}
          payInfoListAction={payInfoListAction}
          onClickOpenModal={onClickOpenModal}
          hqBranchPolicy={baseInfoState}
          isAddPay={isAddPay}
          from_lock={from_lock}
          last_to_date={last_to_date}
          updateToDate={updateToDate}
          changeItem={changeItem}
        />
        <MembershipSideItem
          payInfoListAction={payInfoListAction}
          membershipIdx={membershipIdx}
          itemIncludeRef={itemIncludeRef}
          memberInputState={memberInputState}
          hqBranchPolicy={baseInfoState}
          isAddPay={isAddPay}
          selectorInfo={selectorInfo}
          memberId={memberInputStateAll.member_id}
          personalInfo={memberInputStateAll.personal_info}
          noReg={noReg}
        />
        {memberInputState.membership_list.family_info.is_use ? (
          <MembershipFamilyItem
            payInfoListAction={payInfoListAction}
            membershipIdx={membershipIdx}
            itemIncludeRef={itemIncludeRef}
            memberInputState={memberInputState}
            hqBranchPolicy={baseInfoState}
            personalInfo={memberInputStateAll.personal_info}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
});
export default MembershipDetail;
