import * as ActionTypes from "../actions/action_type";

const initialStete = {
  stores: {
    data: [],
  },
};
export const search_pt_class_hist = (state = initialStete, action) => {
  switch (action.type) {
    case ActionTypes.searchPtClassHistSuccess: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.searchPtClassHistFail:
    case ActionTypes.searchPtClassHistReset:
      return { ...state, stores: initialStete.stores };
    default:
      return state;
  }
};

export const search_ot_class_hist = (state = initialStete, action) => {
  switch (action.type) {
    case ActionTypes.searchOtClassHistSuccess: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.searchOtClassHistFail:
    case ActionTypes.searchOtClassHistReset:
      return { ...state, stores: initialStete.stores };
    default:
      return state;
  }
};
