import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContentsEND } from "components/CustomTable/tableStyle";

const SearchCardTable = (props) => {
  const { modalParam, onModalDone, curPage, searchList, totalPage, onClickSearchEvent } = props;

  const doSearch = (page) => {
    onClickSearchEvent(page);
  };

  const searchResultList = new searchResultTable(modalParam.searchCallback);

  return (
    <div>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchList}
        minHeight="27.5rem"
      />
      <CustomPagination curPage={curPage} totalCnt={totalPage} doSearch={doSearch} />
    </div>
  );
};
export default SearchCardTable;

class searchResultTable {
  table_title = "";
  onParentClickEvent = null;

  constructor(searchCallback) {
    this.onParentClickEvent = searchCallback;
  }

  onClickEvent = (data) => {
    this.onParentClickEvent(data);
  };

  create_table = (data, idx) => {
    return (
      <CustomRow style={{ width: "10rem" }} key={idx} onClick={() => this.onClickEvent(data)} hover>
        <CustomTableContentsEND align="center">{data.card_no}</CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [{ title: "사용 가능 카드 리스트", field: "branch" }];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
