import IconButton from "components/Button/IconButton";
import {
  contentTypeJson,
  getDefaultValue,
  restEmpty,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { searchStaffParamInit } from "../Search/Module/reducers/staff_search_params";
import SelectorTable from "./SelectorTable";

const SearchStaff = ({
  hqBranchList,
  modalParam,
  onModalDone,
  approverList,
  receiverList,
  viewerList,
  setApproverList,
  setReceiverList,
  setViewerList,
  permissionKey,
}) => {
  const storesDispatch = useDispatch();

  const [hqIdx, setHqIdx] = useState(0);
  const [curPage, setCurPage] = useState();
  const [totalPage, setTotalPage] = useState(1);
  const [searchParam, setSearchParam] = useState(_.cloneDeep(searchStaffParamInit));
  const [staffSearchResult, setStaffSearchResult] = useState([]);
  const [loading, setLoading] = useState(false);

  const [phone, setPhone] = useState({
    p1: "",
    p2: "",
    p3: "",
  });
  const phoneRef1 = useRef();
  const phoneRef2 = useRef();
  const phoneRef3 = useRef();

  // 처음 페이지 접속 시 "전체" 선택
  useEffect(() => {
    if (hqBranchList) {
      searchParam.is_hq_staff = false;
      searchParam.hq_info.hq_id = hqBranchList.target_list[hqIdx].hq_info.hq_id;
      searchParam.branch_info.branch_id = hqBranchList.target_list[hqIdx].branch_list[0].branch_id;
    }
  }, [hqBranchList]);

  const onClickSearch = async (page) => {
    setCurPage(page);
    if (permissionKey) {
      searchParam.permission_key = permissionKey;
    }

    const formData = new FormData();
    formData.append("page_num", page);
    formData.append("is_pagination", true);
    formData.append("search_param", JSON.stringify(searchParam));

    setLoading(true);

    await HttpInstance.post(
      process.env.REACT_APP_STAFF_SERVER + "/staff/search_modal",
      formData,
      contentTypeJson,
    )
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          if (!restEmpty(res.data.data)) {
            setTotalPage(res.data.total_page);
            setStaffSearchResult(res.data.data);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // 전화번호 입력 이벤트 (모든 칸)
  const onInputPhone = () => {
    let res = "";

    if (phone.p1 && !phone.p2 && phone.p3) {
      res = "err";
    } else {
      res = phone.p1 + "-" + phone.p2 + "-" + phone.p3;
    }
    res = res.replace("--", "-");

    searchParam.phone = res;
  };

  return (
    <div
      style={{
        width: "28.5rem",
        height: "33rem",
        marginTop: "1rem",
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onClickSearch(1);
        }
      }}
    >
      {loading && <Loading />}

      <ComboBox
        labelWidth={"3.5rem"}
        comboItemWidth={"14.5rem"}
        labelText="본부"
        data={hqBranchList ? hqBranchList.hq_list : [""]}
        defaultValue={hqBranchList && hqBranchList.hq_list[hqIdx]}
        onChangeCallback={(e) => {
          const index = hqBranchList.hq_list.map((item) => item).indexOf(e.target.value);
          if (index !== -1) {
            setHqIdx(index);
            setSearchParam((prev) => {
              return {
                ...prev,
                hq_info: hqBranchList.target_list[index].hq_info,
                branch_info: hqBranchList.target_list[hqIdx].branch_list[0],
                is_hq_staff: false,
              };
            });
          }
        }}
      />

      <SearchComboBox
        marginTop="0.5rem"
        labelWidth={"3.5rem"}
        comboItemWidth={"14.5rem"}
        labelText="지점"
        data={hqBranchList ? hqBranchList.branch_list[hqIdx] : [""]}
        defaultValue={getDefaultValue("combo", searchParam.branch_info.branch_name)}
        onChangeCallback={(e, v, idx) => {
          if (idx !== -1) {
            if (v === "본부") {
              setSearchParam((prev) => {
                return { ...prev, is_hq_staff: true };
              });
            } else {
              setSearchParam((prev) => {
                return { ...prev, is_hq_staff: false };
              });
            }
            setSearchParam((prev) => {
              return { ...prev, branch_info: hqBranchList.target_list[hqIdx].branch_list[idx] };
            });
          }
        }}
      />

      <div style={{ marginTop: "0.5rem" }}>
        <TextField
          labelWidth={"3.5rem"}
          textBoxWidth={"14.5rem"}
          labelText="직원명"
          onChangeCallback={(e) => {
            searchParam.staff_name = e;
          }}
        />
      </div>

      <div
        style={{ display: "flex", marginTop: "0.25rem" }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onClickSearch(1);
          }
        }}
      >
        <TextField
          inputRef={phoneRef1}
          labelWidth={"3.5rem"}
          textBoxWidth={"4.2rem"}
          textMarginRight="0.25rem"
          labelText="연락처"
          maxLength={3}
          onChangeCallback={(e) => {
            phone.p1 = e;
            onInputPhone();
            if (phone.p1.length === 3) {
              phoneRef2.current.select();
            }
          }}
        />
        <TextField
          inputRef={phoneRef2}
          required={phone.p1 && !phone.p2 && phone.p3 ? true : false}
          textBoxWidth={"4.2rem"}
          labelText="-"
          labelMarginRight="0.25rem"
          textMarginRight="0.25rem"
          maxLength={4}
          onChangeCallback={(e) => {
            phone.p2 = e;
            onInputPhone();
            if (phone.p2.length === 4) {
              phoneRef3.current.select();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Backspace" && !phone.p2) {
              phoneRef1.current.select();
            }
          }}
        />
        <TextField
          inputRef={phoneRef3}
          textBoxWidth={"4.2rem"}
          labelText="-"
          labelMarginRight="0.25rem"
          maxLength={4}
          onChangeCallback={(e) => {
            phone.p3 = e;
            onInputPhone();
          }}
          onKeyDown={(e) => {
            if (e.key === "Backspace" && !phone.p3) {
              phoneRef2.current.select();
            }
          }}
        />
        <IconButton width="2.2rem" type="search" onClick={() => onClickSearch(1)} />
      </div>
      <div style={{ marginTop: "0.5rem" }}>
        <SelectorTable
          staffSearchResult={staffSearchResult}
          modalParam={modalParam}
          onModalDone={onModalDone}
          approverList={approverList}
          receiverList={receiverList}
          viewerList={viewerList}
          setApproverList={setApproverList}
          setReceiverList={setReceiverList}
          setViewerList={setViewerList}
          curPage={curPage}
          totalPage={totalPage}
          onClickSearch={onClickSearch}
        />
      </div>
    </div>
  );
};
export default SearchStaff;
