import { useEffect, useState } from "react";

import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useDispatch } from "react-redux";

const CouponCurrentState = ({ memberId, branchInfo }) => {
  const storesDispatch = useDispatch();
  const [couponList, setCouponList] = useState([]);
  const [curPage, setCurPage] = useState();
  const [totalPage, setTotalPage] = useState(0);
  const [couponNum, setCouponNum] = useState("");
  const [loading, setLoading] = useState(false);

  const getCouponList = async (page) => {
    setCurPage(page);
    setLoading(true);
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_USER_SERVER + "/member/coupon/list",
        {
          params: {
            page_num: curPage,
            branch_id: branchInfo.branch_id,
            member_id: memberId,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setCouponList(res.data.data);
        setTotalPage(res.data.total_page);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  const addCoupon = async () => {
    try {
      const formData = new FormData();
      const info = {
        branch_info: branchInfo,
        is_coupon_manage: true,
        is_register: true,
        member_id: memberId,
        ticket_id: couponNum,
      };
      formData.append("new_info", JSON.stringify(info));

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_USER_SERVER + "/member/coupon/push",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        getCouponList(curPage);
      }
      setCouponNum("");
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const validationCheck = () => {
    if (!couponNum) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="쿠폰번호를 입력해주세요." />,
          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="쿠폰을 등록하시겠습니까?" />,
          onBtnYes: () => {
            addCoupon();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  useEffect(() => {
    getCouponList(1);
  }, [memberId]);

  const couponListTable = new couponTable();

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "1rem",
        }}
      >
        {loading && <Loading />}

        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <SubTitle titleText="쿠폰 현황" />
        </div>

        <div style={{ display: "flex" }}>
          <TextField
            labelMarginLeft="1rem"
            labelWidth={"4rem"}
            textBoxWidth={"11rem"}
            labelText="쿠폰번호"
            defaultValue={couponNum}
            onChangeCallback={(e) => {
              setCouponNum(e);
            }}
          />

          <NormalBtn name="등록" theme="white" onClick={validationCheck} />
        </div>
      </div>

      <CustomTable
        columns_head={couponListTable.columns_head}
        table_title={couponListTable.table_title}
        rest_call={couponListTable.get_data_from_rest}
        row_render={couponListTable.create_table}
        rest_data={couponList}
      />
      <CustomPagination curPage={curPage} doSearch={setCurPage} totalCnt={totalPage} />
    </>
  );
};

export default CouponCurrentState;

class couponTable {
  table_title = "";
  btnStyleClass = btnStyles();

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.base_info.issue_date}</CustomTableContents>
        <CustomTableContents>{data.reg_info.reg_name}</CustomTableContents>
        <CustomTableContents>{data.base_info.type}</CustomTableContents>
        <CustomTableContents>{data.detail.coupon_name}</CustomTableContents>
        <CustomTableContents>{data.base_info.benefit_type}</CustomTableContents>
        <CustomTableContents>{data.base_info.status}</CustomTableContents>
        <CustomTableContents>
          {data.base_info.expired_from_date} ~ {data.base_info.expired_to_date}
        </CustomTableContents>
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.purchase_code}</CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "등록일시", width: "11%" },
    { title: "등록자", width: "11%" },
    { title: "쿠폰종류", width: "11%" },
    { title: "쿠폰명", width: "11%" },
    { title: "혜택", width: "11%" },
    { title: "상태", width: "11%" },
    { title: "유효기간", width: "11%" },
    { title: "사용일", width: "11%" },
    { title: "구매코드", width: "12%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
