import { generateObjectZeroId } from "components/CommonLib/CommonLib";
import { create } from "zustand";

// 검색 조건 베이스
const baseSearchParams = {
  agree_marketing: true,
  agree_personal: true,
  branch_info: {
    branch_id: generateObjectZeroId(),
    branch_name: "",
  },
  combo_item: {
    branch_hq_info_list: [
      {
        branch_list: [
          {
            branch_id: "",
            branch_name: "",
          },
        ],
        hq_info: {
          hq_id: "",
          hq_name: "",
        },
      },
    ],
    branch_list: [[""]],
    hq_list: [""],
  },
  hq_info: {
    hq_id: generateObjectZeroId(),
    hq_name: "",
  },
  phone: "",
  target_name: "",
};

export const useGuestBookStore = create((set) => ({
  // ========== State ===========
  // 방명록 리스트 검색 State
  guestBookSearchParams: {
    ...baseSearchParams,
  },
  guestBookPage: 1,

  guestBookHqIdx: 0,

  // ========== Action ==========
  // 방명록 리스트 검색 조건 변경
  setSearchParams: (key, value) => {
    set((state) => ({
      guestBookSearchParams: {
        ...state.guestBookSearchParams,
        [key]: value,
      },
    }));
  },

  // Page 값 변경
  setPage: (value) => {
    set({ guestBookPage: value });
  },

  // Hq Index 값 변경
  setHqIdx: (value) => {
    set({ guestBookHqIdx: value });
  },

  // 검색 조건 리셋
  resetSearchParams: () => {
    set({
      guestBookSearchParams: {
        ...baseSearchParams,
      },
    });
    set({ guestBookHqIdx: 0 });
  },
}));
