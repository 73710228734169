export const searchInitState = {
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  ch_staff_list: [
    {
      staff_id: "",
      staff_name: "",
      staff_pos: "",
      staff_rank: "",
    },
  ],
  charger_staff_info: {
    staff_id: "",
    staff_name: "",
    staff_pos: "",
    staff_rank: "",
  },
  hq_branch_data: {
    branch_list: [[]],
    hq_list: [],
    target_list: [
      {
        branch_list: [
          {
            branch_id: "",
            branch_name: "",
          },
        ],
        hq_info: {
          hq_id: "",
          hq_name: "",
        },
      },
    ],
  },
  hq_info: {
    hq_id: "",
    hq_name: "",
  },
  is_day_off: true,
  is_notice: true,
  is_ot_class_done: true,
  is_personal_task: true,
  is_pt_class_done: true,
  is_reserv_cs: true,
  is_reserv_ot: true,
  is_reserv_pt: true,
  search_day_date: "",
  search_month: "",
  search_week_from_date: "",
  search_week_to_date: "",
};

export const searchResultInit = {
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  grid_item: [],
  head_line_item: [
    {
      date: 0,
      is_today: true,
      schedule_list: [
        {
          card_title: "",
          schedule_base: {
            branch_info: {
              branch_id: "",
              branch_name: "",
            },
            charger_staff_info: {
              staff_id: "",
              staff_name: "",
              staff_pos: "",
              staff_rank: "",
            },
            from_date: "",
            from_time: "",
            hq_info: {
              hq_id: "",
              hq_name: "",
            },
            is_all_day: true,
            is_day_off: true,
            is_notice: true,
            is_ot_class_done: true,
            is_personal_task: true,
            is_pt_class_done: true,
            is_reserv_cs: true,
            is_reserv_ot: true,
            is_reserv_pt: true,
            reg_info: {
              created_at: "",
              reg_id: "",
              reg_name: "",
              upd_id: "",
              upd_name: "",
              updated_at: "",
            },
            schedule_info: {
              schedule_id: "",
              schedule_name: "",
              schedule_type: "",
            },
            to_date: "",
            to_time: "",
          },
        },
      ],
    },
  ],
  hq_info: {
    hq_id: "",
    hq_name: "",
  },
  search_day_date: "",
  search_month: "",
  search_week_from_date: "",
  search_week_to_date: "",
};

export const getNoticeInit = {
  schedule_base: {
    branch_info: {
      branch_id: "",
      branch_name: "",
    },
    charger_staff_info: {
      staff_id: "",
      staff_name: "",
      staff_pos: "",
      staff_rank: "",
    },
    from_date: "",
    from_time: "",
    hq_info: {
      hq_id: "",
      hq_name: "",
    },
    is_all_day: true,
    is_day_off: true,
    is_notice: true,
    is_ot_class_done: true,
    is_personal_task: true,
    is_pt_class_done: true,
    is_reserv_cs: true,
    is_reserv_ot: true,
    is_reserv_pt: true,
    reg_info: {
      created_at: "",
      reg_id: "",
      reg_name: "",
      upd_id: "",
      upd_name: "",
      updated_at: "",
    },
    schedule_info: {
      schedule_id: "",
      schedule_name: "",
      schedule_type: "",
    },
    to_date: "",
    to_time: "",
  },
  sub_info: {
    memo: "",
    dm_path: "",
    dm_staff_list: [
      {
        staff_id: "",
        staff_name: "",
        staff_pos: "",
        staff_rank: "",
      },
    ],
    title: "",
  },
};
