import * as ActionTypes from "../actions/action_type";

const friendinitialStete = {
  stores: {
    data: {},
  },
};
export const friend_info_state = (state = friendinitialStete, action) => {
  switch (action.type) {
    case ActionTypes.getFriendsViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getFriendsViewFailAction:
    case ActionTypes.getFriendsViewResetAction:
      return { ...state, stores: friendinitialStete.stores };
    default:
      return state;
  }
};

const familyinitialStete = {
  stores: {
    data: {},
  },
};
export const family_info_state = (state = familyinitialStete, action) => {
  switch (action.type) {
    case ActionTypes.getFamilyViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getFamilyViewFailAction:
    case ActionTypes.getFamilyViewResetAction:
      return { ...state, stores: familyinitialStete.stores };
    default:
      return state;
  }
};
