//reg_input_info
export const refundInitState = {
  selected: false,
  payment_id: " / 결제 ID",
  purchase_code: " / 연결된 구매코드",
  is_valid: "bool / 유효 결제 여부",
  pay_date: "date / 결제일시",
  pay_method: " / 결제수단",
  price: "int / 결제금액",
  card_no: " / 카드번호 앞 6자리",
  approve_no: " / 카드 승인번호",
  cash_approve_no: " / 현금영수증 승인번호",
  pay_receipt: " / 결제 영수증 파일명",
  cancel_receipt: " / 결제 취소 영수증 파일명",
  remarks: " / 비고",
};

export const inputInitState = {
  cs_staff: {
    staff_id: "",
    staff_name: "",
    staff_pos: "",
    staff_rank: "",
  },
  cs_team_info_list: [],
  cs_team_name_list: [],
  member_id: "",
  paymentA: {
    pay_method: [],
    total_paid: 0,
    unpaid_info: {
      promise_date: "",
      reason: "",
      unpaid_price: 0,
    },
  },
  payment_list: [],
  purchase_list: [],
  refund_list: [],
  reg_date: "",
  unpaid: {
    total_unpaid: 0,
    unpaid_list: [],
  },
  contract_doc_list: [],
};
