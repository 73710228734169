import * as ActionTypes from "../actions/action_type";

const newbieInitialState = {
  member_name: "",
  phone: "",
  agree_personal: false,
  agree_marketing: false,
  gender: "",
  birth: "",
  email: "",
  zip_code: "",
  address_1: "",
  address_2: "",
  notice: "",
};

const initialState = {
  src_member_id: "",
  dst_member_id: "",
  newbie_info: newbieInitialState,
};

// 리듀서 작성
export const assign_select_param_state = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.setAssignParamSrcId: {
      state.src_member_id = action.member_id;
      return state;
    }
    case ActionTypes.setAssignParamDstId: {
      state.dst_member_id = action.member_id;
      return state;
    }
    case ActionTypes.setAssignParamNewbie: {
      state.newbie_info = action.newbie_info;
      return state;
    }
    case ActionTypes.clearAssignParamSrcId: {
      state.src_member_id = "";
      return state;
    }
    case ActionTypes.clearAssignParamDstId: {
      state.dst_member_id = "";
      return state;
    }
    case ActionTypes.clearAssignParamNewbie: {
      state.newbie_info = newbieInitialState;
      return state;
    }
    case ActionTypes.clearAssignParamAll: {
      // state.src_member_id = "";
      // state.dst_member_id = "";
      // state.newbie_info = newbieInitialState;
      return initialState;
    }
    default:
      return state;
  }
};
