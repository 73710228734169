export const loadTest = () => {
  return {
    type: "loading/TEST",
    payload: true,
  };
};

export const loadTestSuccess = (data) => {
  return {
    type: "loading/TEST/OK",
    payload: data,
  };
};

export const loadTestInfoFail = (data) => {
  return {
    type: "loading/TEST/FAIL",
    payload: data,
  };
};

export const getTestStore = () => loadTest();
