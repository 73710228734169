import { takeLatest, takeEvery } from "redux-saga/effects";
import { put, call } from "redux-saga/effects";
// import get_api from "../../../../../lib/LockerSettings/GetApi";
import get_api from "../api/GetApi";
import { hqSettingAction } from "../actions/index";
import { globalStartLoading, globalFinishLoading } from "modules/actions/Loading/index";
import * as ActionTypes from "../actions/action_type";

export function* getHqBaseData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getBaseInfoApi, param);

    if (data.data === null) {
      yield put(hqSettingAction.loadHqBaseSuccess(false));
    } else {
      yield put(hqSettingAction.loadHqBaseSuccess(data));
    }
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(hqSettingAction.loadHqBaseFail(error));
  }
  yield put(globalFinishLoading());
}

export function* getHqHqViewData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getHqViewApi, param);

    if (data.data === null) {
      yield put(hqSettingAction.loadHqViewSuccess(false));
    } else {
      yield put(hqSettingAction.loadHqViewSuccess(data));
    }
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(hqSettingAction.loadHqViewFail(error));
  }
  yield put(globalFinishLoading());
}

export function* getHqBranchViewData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getBranchViewApi, param);

    if (data.data === null) {
      yield put(hqSettingAction.loadBranchViewSuccess(false));
    } else {
      yield put(hqSettingAction.loadBranchViewSuccess(data));
    }
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(hqSettingAction.loadBranchViewFail(error));
  }
  yield put(globalFinishLoading());
}

export function* getHqPolicyViewData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getPolicyViewApi, param);

    if (data.data === null) {
      yield put(hqSettingAction.loadPolicyViewSuccess(false));
    } else {
      yield put(hqSettingAction.loadPolicyViewSuccess(data));
    }
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(hqSettingAction.loadPolicyViewFail(error));
  }
  yield put(globalFinishLoading());
}

export function* getHqBranchInfoData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getBranchInfoApi, param);

    if (data.data === null) {
      yield put(hqSettingAction.loadBranchInfoSuccess(false));
    } else {
      yield put(hqSettingAction.loadBranchInfoSuccess(data));
    }
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(hqSettingAction.loadBranchInfoFail(error));
  }
  yield put(globalFinishLoading());
}

export function* hqSettingSaga() {
  yield takeLatest(ActionTypes.getHqBaseAction, getHqBaseData);
  yield takeLatest(ActionTypes.getHqManageViewAction, getHqHqViewData);
  yield takeLatest(ActionTypes.getBranchManageViewAction, getHqBranchViewData);
  yield takeLatest(ActionTypes.getPolicyManageViewAction, getHqPolicyViewData);
  yield takeLatest(ActionTypes.getBranchInfoAction, getHqBranchInfoData);
}
