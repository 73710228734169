import React, { useState, useRef, useEffect } from "react";
import PageFrame from "components/Panels/PageFrame";
import PageTitle from "components/PageTitle/PageTitle";
import SubsManageContent from "./SubsManageContent";
import { search_subs_manage_state } from "../Module/reducers/search_subs_manage_state";
import _ from "lodash";

const SubsManage = () => {
  const [searchParam, setSearchParam] = useState(_.cloneDeep(search_subs_manage_state));

  return (
    <div>
      <PageFrame
        menuActive="락커/셋팅"
        parentMenu={{ 락커: true }}
        children={
          <div>
            <PageTitle titleText="정기결제 관리" />
            <SubsManageContent searchParam={searchParam} setSearchParam={setSearchParam} />
          </div>
        }
      />
    </div>
  );
};
export default SubsManage;
