// 초기화
// const initialState = {
//   titleText: "",
//   bodyText: "",
//   price: 0,
// };
const initialState = [];

// 리듀서 작성
export const subs_pay_list_state = (state = initialState, action) => {
  switch (action.type) {
    case "COMMON/SUBS_PAYMENT_INFO_LIST/PUSH": {
      let array = [...state];
      const index = array.map((item) => item.type_key).indexOf(action.payload.type_key);
      if (index !== -1) {
        array[index] = action.payload;
      } else {
        array.push(action.payload);
        array.sort((a, b) => {
          if (a.orderIdx < b.orderIdx) return -1;
          if (a.orderIdx > b.orderIdx) return 1;

          return 0;
        });
      }

      return [...array];
    }
    case "COMMON/SUBS_PAYMENT_INFO_LIST/POP": {
      let array = [...state];
      const index = array.map((item) => item.type_key).indexOf(action.type_key);

      if (index !== -1) {
        array.splice(index, 1);
      }

      return [...array];
    }
    case "COMMON/SUBS_PAYMENT_INFO_LIST/EDIT": {
      let array = [...state];
      const index = array.map((item) => item.type_key).indexOf(action.payload.type_key);

      if (index !== -1) {
        const mbshpIdx = array[index].MembershipIdx;
        array[index] = action.payload;
        array[index].MembershipIdx = mbshpIdx;
      } else {
        array.push(action.payload);
        array.sort((a, b) => {
          if (a.orderIdx < b.orderIdx) return -1;
          if (a.orderIdx > b.orderIdx) return 1;

          return 0;
        });
      }

      return [...array];
    }
    case "COMMON/SUBS_PAYMENT_INFO_LIST/CLEAR":
      if (action.payload === "clear") {
        return [];
      } else if (action.payload.type === "add_clear") {
        let array = [];
        // console.log(state);
        // console.log(action.payload);
        for (let i = 0; i < state.length; i++) {
          if (state[i].mbshpIdx !== action.payload.idx) {
            array.push(state[i]);
          }
        }
        return [...array];
      } else {
        return [];
      }
    case "COMMON/SUBS_PAYMENT_INFO_LIST/LIST":
      return state;

    default:
      return state;
  }
};
