import TextField from "components/LabelInput/TextField";

const SubsMembershipStatus = ({ selectedItem, subState }) => {
  const labelWidth = "4.3rem";
  const textBoxWidth = "13.3rem";

  const contractPeriodText = selectedItem.mbshp_option
    ? `${selectedItem.subs_contract_from}  ~  ${selectedItem.subs_contract_to}`
    : "";

  const passCntText =
    selectedItem.mbshp_option === "패스권"
      ? `월 ${selectedItem.pass_total_cnt} 회 (당월잔여 ${selectedItem.pass_remain_cnt}회)`
      : "";
  const passTimeText =
    selectedItem.mbshp_option === "시간권"
      ? `${selectedItem.pass_from_time} ~ ${selectedItem.pass_end_time}`
      : "";
  const postponeText = selectedItem.mbshp_option
    ? selectedItem.postpone_total_cnt + "회 중 " + selectedItem.postpone_used_cnt + "회 사용"
    : "";

  return (
    <div>
      <TextField
        labelWidth={labelWidth}
        textBoxWidth={textBoxWidth}
        labelText="약정기간"
        defaultValue={contractPeriodText}
        disabled
      />
      <TextField
        marginTop="0.5rem"
        labelWidth={labelWidth}
        textBoxWidth={textBoxWidth}
        labelText="연기"
        defaultValue={postponeText}
        disabled
      />
      <TextField
        marginTop="0.5rem"
        labelWidth={labelWidth}
        textBoxWidth={textBoxWidth}
        labelText="정기결제액"
        defaultValue={`${subState.pay_per_month.toLocaleString()} 원`}
        disabled
      />
      {selectedItem.mbshp_option === "패스권" ? (
        <TextField
          marginTop="0.5rem"
          labelMarginLeft="0.5rem"
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          labelText="출입횟수"
          defaultValue={passCntText}
          disabled
        />
      ) : (
        ""
      )}
      {selectedItem.mbshp_option === "시간권" ? (
        <TextField
          marginTop="0.5rem"
          labelMarginLeft="0.5rem"
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          labelText="출입시간"
          defaultValue={passTimeText}
          disabled
        />
      ) : (
        ""
      )}
      <TextField
        marginTop="0.5rem"
        labelWidth={labelWidth}
        textBoxWidth={textBoxWidth}
        labelText="진행 회차"
        defaultValue={`${subState.subs_paid_num} 회`}
        disabled
      />
    </div>
  );
};
export default SubsMembershipStatus;
