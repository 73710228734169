import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AccessDeviceAction, loadHolyDayInfoActionStore } from "../Module/actions/index";
import HolidayAddModal from "./HolidayAddModal";

const HolidayListView = () => {
  const storesDispatch = useDispatch();
  const viewModelRef = useRef();
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });
  const [currentYear, setCurrentYear] = useState("2023");
  const [loading, setLoading] = useState(false);

  //공휴일 리스트
  const restData = useSelector((state) => state.access_service.access_holyday_info);

  //해당년도의 공휴일 리스트 GET
  useEffect(() => {
    storesDispatch(loadHolyDayInfoActionStore(currentYear));
    return () => {
      storesDispatch(AccessDeviceAction.loadHolyDayInfoResetAction());
    };
  }, [currentYear]);

  const localModalDone = () => {
    storesDispatch(loadHolyDayInfoActionStore(currentYear));
    viewModelRef.current.setClose();
  };

  //공휴일 추가 모달 POP
  const onModalOpen = (data) => {
    const params = {
      date_year: currentYear,
    };
    setModalInfo({
      show: true,
      title: "공휴일 추가",
      Content: <HolidayAddModal modalParam={params} onModalDone={localModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  //공휴일 삭제 POST

  const deleteDay = async (data) => {
    try {
      setLoading(true);

      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const formData = new FormData();
      formData.append("param_info", JSON.stringify(data));
      const res = await HttpInstance.post(
        process.env.REACT_APP_BRANCH_SERVER + "/access/remove_holy_day",
        formData,
        config,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //공휴일 리스트 갱신
        storesDispatch(loadHolyDayInfoActionStore(currentYear)); //
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const onRemoveDay = async (data) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        reqConfirm: true,
        bodyText: (
          <>
            <Label labelText={`공휴일을 삭제하시겠습니까?`} fontWeight={"bold"} />
            <Label labelText={`공휴일 명:${data.date_name}`} />
            <Label labelText={`날짜:${data.date} `} />
          </>
        ),

        onBtnYes: () => {
          deleteDay(data);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const holidayInfoTableItem = new holidayInfoTable(onRemoveDay);

  return (
    <div
      style={{
        width: "37.5rem",
      }}
    >
      {loading && <Loading />}

      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
      <div style={{ display: "flex" }}>
        <ComboBox
          labelWidth={"4.5rem"}
          comboItemWidth={"8.75rem"}
          labelText="연도 선택"
          data={["2022", "2023", "2024"]}
          defaultValue={"2023"}
          onChangeCallback={(e) => {
            setCurrentYear(e.target.value);
          }}
        />
        <div
          style={{
            float: "right",
            display: "flex",
            justifyContent: "end",
            width: "100%",
          }}
        >
          <NormalBtn name="추가" onClick={() => onModalOpen()} />
        </div>
      </div>
      <div style={{ marginTop: "0.5rem" }}>
        <CustomTable
          columns_head={holidayInfoTableItem.columns_head}
          table_title={holidayInfoTableItem.table_title}
          rest_call={holidayInfoTableItem.get_data_from_rest}
          row_render={holidayInfoTableItem.create_table}
          rest_data={restData.stores.data.holiday_list}
        />
      </div>
    </div>
  );
};
export default HolidayListView;

class holidayInfoTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onRemoveDay = null;

  constructor(onRemoveDay) {
    this.onRemoveDay = onRemoveDay;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.date}</CustomTableContents>
        <CustomTableContents>{data.date_name}</CustomTableContents>
        <CustomTableContentsEND align="center">
          <IconButton type="delete" onClick={() => this.onRemoveDay(data)} />
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "날짜", width: "33%" },
    { title: "공휴일 명", width: "33%" },
    { title: "삭제", width: "33%" },
  ];
  get_data_from_rest = (stateData) => {
    // console.log(stateData);
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
