import SubTitle from "components/PageTitle/SubTitle";
import PageFrame from "components/Panels/PageFrame";

const MainIndex = () => {

  return (
    <div>
      <PageFrame
        isMain
        menuActive="락커/셋팅"
        parentMenu={{ 락커: true }}
        children={
          <div style={{ border: "2px solid red" }}>
            <SubTitle titleText="The page is under maintenance.." fontSize="3rem" />
          </div>
        }
      />
    </div>
  );
};
export default MainIndex;
