import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import { useNavigate } from "react-router-dom";
import { useTMListDetailStore } from "../TMListDetail/modules/store/tmListDetailState";
const TMListResultTable = ({ searchResult, curPage, totalPage, onClickSearch }) => {
  const navigate = useNavigate();
  const { resetSearchParams } = useTMListDetailStore();

  const onClickRow = (data) => {
    resetSearchParams();
    navigate("/tm_list_detail/", {
      state: {
        row_data: data,
      },
    });
  };

  const searchResultList = new resultTable(onClickRow);

  const doSearch = (page) => {
    onClickSearch(page);
  };

  return (
    <div>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
        emptyHeight="20rem"
      />
      <CustomPagination curPage={curPage} totalCnt={totalPage} doSearch={doSearch} />
    </div>
  );
};
export default TMListResultTable;

class resultTable {
  table_title = "";
  onClickRow = null;

  constructor(onClickRow) {
    this.onClickRow = onClickRow;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow
        key={idx}
        onClick={(e) => {
          if (e.target.nodeName === "DIV" || e.target.nodeName === "TD") {
            this.onClickRow(data);
          }
        }}
        hover
      >
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.reg_info.reg_name}</CustomTableContents>
        <CustomTableContentsEND>{data.preset_name}</CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "지점", width: "20%" },
    { title: "등록일", width: "20%" },
    { title: "등록자", width: "20%" },
    { title: "조건명", width: "40%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
