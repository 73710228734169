import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import CheckBox from "components/LabelInput/CheckBox";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import TableTitleWithButton from "components/TableTitleWithButton/TableTitleWithButton";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { comm_set_init_state } from "../Module/reducers/comm_set_init_state";
import EditCommissionPreset from "./EditCommissionPreset";

// "기본 커미션 설정" 팝업
const ChangeCommissionSet = ({ modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const [checkState, setCheckState] = useState([]);
  const [loading, setLoading] = useState(false);

  let [commissionSet, setCommissionSet] = useState(comm_set_init_state);

  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });

  useEffect(() => {
    getCommSetList();
  }, []);

  const getCommSetList = () => {
    setLoading(true);
    HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/staff/work_info_setting/data", {
      params: { staff_id: modalParam.staff_id, branch_id: modalParam.branch_id },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          // console.log(res.data.data);
          setCommissionSet(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickAddEdit = (mode, presetId) => {
    const param = {
      staff_id: commissionSet.contents.staff_id,
      branch_id: commissionSet.contents.base_info.branch_info.branch_id,
      preset_id: presetId,
      // refreshStore: viewRefresh,
    };

    setModalInfo({
      show: true,
      title: mode === "추가" ? "커미션 추가" : "커미션 수정",
      Content: (
        <EditCommissionPreset
          mode={mode}
          commissionSet={commissionSet}
          modalParam={param}
          onModalDone={onLocalModalDone}
        />
      ),
      callback: null,
      Buttons: undefined,
    });
  };

  const viewModalRef = useRef();

  const onLocalModalDone = () => {
    viewModalRef.current.setClose();
    getCommSetList();
  };

  const onClickSave = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: <Label labelText="저장하시겠습니까?" />,
        // width: "50rem",
        onBtnYes: () => {
          onSave();
        },
        onBtnNo: () => {},
      }),
    );
  };

  const onSave = async () => {
    // console.log(commissionSet);

    const formData = new FormData();
    formData.append("new_info", JSON.stringify(commissionSet));
    setLoading(true);

    await HttpInstance.post(
      process.env.REACT_APP_STAFF_SERVER + "/staff/work_info_setting/update",
      formData,
      contentTypeJson,
    )
      .then((res) => {
        console.log(res);
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          onModalDone();
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // radio check
  const onChangeCheckState = (idx) => {
    setCheckState(checkState.map((item, i) => i === idx));
  };

  const itemList = new itemInfoTable(onClickAddEdit, checkState, onChangeCheckState);

  return (
    <div style={{ width: "50rem" }}>
      {loading && <Loading />}
      <ModalPopupLocal ref={viewModalRef} params={modalInfo} />

      <div style={{ display: "flex" }}>
        <TextField
          required
          labelWidth={"4rem"}
          textBoxWidth={"14rem"}
          labelText="지점명"
          defaultValue={commissionSet.contents.base_info.branch_info.branch_name}
          disabled
        />
        <div style={{ float: "right", marginLeft: "auto" }}>
          <Button
            className={clsx({
              [btnStyleClass.btnRoot]: true,
              [btnStyleClass.buttonType4]: true,
            })}
            onClick={onClickSave}
          >
            저장
          </Button>
        </div>
      </div>

      <div style={{ marginTop: "2rem" }}>
        <TableTitleWithButton
          titleLabel="기본 커미션 선택"
          buttonText={"추가"}
          onClickEvent={() => {
            onClickAddEdit("추가", "");
          }}
          btnWidth="5rem"
          marginBottom="0.15rem"
        />
        <CustomTable
          columns_head={itemList.columns_head}
          table_title={itemList.table_title}
          rest_call={itemList.get_data_from_rest}
          row_render={itemList.create_table}
          rest_data={commissionSet.contents.pt_pre_set}
        />
      </div>

      <div style={{ marginTop: "2rem" }}>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <Label labelText="담당 업무" fontWeight="bold" justifyContent="left" />
        </div>
        <div style={{ marginLeft: "1rem", display: "flex" }}>
          <CheckBox
            labelText="CS"
            defaultValue={commissionSet.contents && commissionSet.contents.work_role.is_cs}
            onChangeCallback={(e) => {
              setCommissionSet({
                contents: {
                  ...commissionSet.contents,
                  work_role: {
                    ...commissionSet.contents.work_role,
                    is_cs: e.checked,
                  },
                },
              });
            }}
          />
          <CheckBox
            labelText="PT"
            defaultValue={commissionSet.contents && commissionSet.contents.work_role.is_pt}
            onChangeCallback={(e) => {
              setCommissionSet({
                contents: {
                  ...commissionSet.contents,
                  work_role: {
                    ...commissionSet.contents.work_role,
                    is_pt: e.checked,
                  },
                },
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default ChangeCommissionSet;

class itemInfoTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onClickChange = null;
  checkState = null;
  onChangeCheckState = null;

  constructor(onClickChange, checkState, onChangeCheckState) {
    this.onClickChange = onClickChange;
    this.checkState = checkState;
    this.onChangeCheckState = onChangeCheckState;
  }

  create_table = (data, idx) => {
    // radio check
    if (this.checkState[idx] === undefined) {
      this.checkState[idx] = data.is_default;
    } else {
      data.is_default = this.checkState[idx];
    }

    return (
      <CustomRow key={idx} style={{ width: "100%" }}>
        <CustomTableContents width="10%">
          <CheckBox
            isTable
            defaultValue={this.checkState[idx]}
            onChangeCallback={(e) => {
              if (!e.checked) {
                e.checked = true;
              } else {
                data.is_default = e.checked;
                this.onChangeCheckState(idx);
              }
            }}
          />
        </CustomTableContents>
        <CustomTableContents width="25%">{data.reg_info.updated_at}</CustomTableContents>
        <CustomTableContents width="15%">{data.reg_info.upd_name}</CustomTableContents>
        <CustomTableContents width="25%">{data.preset_name}</CustomTableContents>
        <CustomTableContents width="10%" nopadding="true">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              className={clsx({
                [this.btnStyleClass.btnRootRaw]: true,
                [this.btnStyleClass.buttonType4]: true,
              })}
              onClick={() => this.onClickChange("수정", data.preset_id)}
            >
              변경
            </Button>
          </div>
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "기본값", field: "select" },
    { title: "최근 수정일시", field: "approval_status" },
    { title: "수정자", field: "edit_status" },
    { title: "커미션명", field: "card_no" },
    { title: "", field: "edit" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
