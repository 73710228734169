////////////////// 시행품의서
export const consult_state = {
  doc_info: {
    doc_id: "",
    doc_name: "",
  },
  item_list: [],
};

export const expenditure_info = {
  account: "",
  bank: "",
  bank_acc: "",
  breakdown: "",
  item_id: "",
  pay_date: "",
  pay_method: "",
  price: 0,
  tax_invoice: "",
  tax_price: 0,
  total_price: 0,
};

// 업무협조 요청서
export const work_assist_state = {
  cooperation: "",
  doc_info: {
    doc_id: "",
    doc_name: "",
  },
};

////////////////// 휴무
export const day_off_state = {
  doc_info: {
    doc_id: "",
    doc_name: "",
  },
  staff_list: [
    // {
    // branch_info: {
    //   branch_id: "",
    //   branch_name: "",
    // },
    //   before_day_off: "",
    //   join_date: "",
    //   off_type: "",
    //   req_day_off: "",
    //   req_day_off_from_date: "",
    //   req_day_off_to_date: "",
    //   staff_info: {
    //     staff_id: "",
    //     staff_name: "",
    //     staff_pos: "",
    //     staff_rank: "",
    //   },
    //   work_month: "",
    //   year_use: 0,
    // },
  ],
};

export const day_off_staff_state = {
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  before_day_off: "",
  join_date: "",
  off_type: "",
  req_day_off: 0,
  req_day_off_from_date: "",
  req_day_off_to_date: "",
  staff_info: {
    staff_id: "",
    staff_name: "",
    staff_pos: "",
    staff_rank: "",
  },
  work_month: "",
  year_use: 0,
};

////////////////// 지출증빙
export const spend_proof_state = {
  doc_info: {
    doc_id: "",
    doc_name: "",
  },
  item_list: [],
};

export const implementation_info = {
  account: "",
  bank: "",
  bank_acc: "",
  breakdown: "",
  item_id: "",
  pay_date: "",
  pay_method: "",
  price: 0,
  tax_invoice: "",
  tax_price: 0,
  total_price: 0,
};

//////////////// 보험
export const insurance_state = {
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  circumstances: "",
  doc_info: {
    doc_id: "",
    doc_name: "",
  },
  member_info: {
    birth: "",
    birth_info: {
      day: 0,
      month: 0,
      year: 0,
    },
    from_date: "",
    gender: "",
    is_new: true,
    item_name: "",
    mbshp_option: "",
    mbshp_type: "",
    member_id: "",
    member_name: "",
    member_no: "",
    membership_id: "",
    phone: "",
    to_date: "",
  },
};

//////////////// 발주
export const order_state = {
  doc_info: {
    doc_id: "",
    doc_name: "",
  },
  item_list: [
    // {
    //   addr: "",
    //   email: "",
    //   order_cnt: 0,
    //   order_id: "",
    //   order_name: "",
    // },
  ],
};

export const order_detail_state = {
  addr_1: "",
  addr_2: "",
  email: "",
  order_cnt: 0,
  item_id: "",
  order_name: "",
};

//////////////// 환불이체
export const transfer_state = {
  doc_info: {
    doc_id: "",
    doc_name: "",
  },
  item_list: [],
};

//////////////// 시재금 입금 확인서
export const vault_cash_state = {
  doc_info: {
    doc_id: "",
    doc_name: "",
  },
  item_list: [],
};
