import { HttpInstance } from "lib/HttpLib";

export const getMemberViewInfo = (param) => {
  return HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member/get_member_info", {
    params: {
      member_id: param.member_id === null ? "" : param.member_id,
    },
  });
};

//회원권 이력
export const getMembershipHistoryInfo = (param) => {
  return HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/history/membership", {
    params: {
      member_id: param.member_id === null ? "" : param.member_id,
      page_num: param.page_num ? param.page_num : 1,
    },
  });
};

//구매 이력
export const getPurchaseHistoryInfo = (param) => {
  return HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/history/purchase", {
    params: {
      member_id: param.member_id === null ? "" : param.member_id,
      page_num: param.page_num ? param.page_num : 1,
    },
  });
};

///수업 이력
export const getClasseHistoryInfo = (param) => {
  return HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/history/class", {
    params: {
      member_id: param.member_id === null ? "" : param.member_id,
      page_num: param.page_num ? param.page_num : 1,
    },
  });
};

///상담 이력
export const getCSHistoryInfo = (param) => {
  return HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/history/counseling", {
    params: {
      member_id: param.member_id === null ? "" : param.member_id,
      page_num: param.page_num ? param.page_num : 1,
    },
  });
};

////friend family
export const getFriendsInfo = (param) => {
  return HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/friend/detail", {
    params: {
      member_id: param.member_id === null ? "" : param.member_id,
    },
  });
};
export const getFamilyInfo = (param) => {
  return HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/family/detail", {
    params: {
      member_id: param.member_id === null ? "" : param.member_id,
    },
  });
};

////타지점 출입, 계야ㅐㄱ서
export const getBranchLinkInfo = (param) => {
  return HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/branch_link/hist", {
    params: {
      member_id: param.member_id === null ? "" : param.member_id,
    },
  });
};
export const getDocumentsInfo = (param) => {
  return HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/documents/detail", {
    params: {
      member_id: param.member_id === null ? "" : param.member_id,
    },
  });
};

const get_api = {
  getMemberViewInfo,
  getMembershipHistoryInfo,
  getPurchaseHistoryInfo,
  getClasseHistoryInfo,

  getFriendsInfo,
  getFamilyInfo,
  getBranchLinkInfo,
  getDocumentsInfo,
  getCSHistoryInfo,
};

export default get_api;
