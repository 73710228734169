import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { getDocType, restEmpty, simpleAlert, SubLine } from "components/CommonLib/CommonLib";
import TextField from "components/LabelInput/TextField";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import { exportToCashBookExcel } from "pages/DailyReport/DailyReportInfo/DailyReportTabs/SalesTab/MakeExcel";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import IncomeExpenseAddModal from "../Modal/IncomeExpenseAddModal";
import { useCashBookStore } from "../modules/store/cashBookState";
import ExpenseResultTable from "./ExpenseResultTable";
import SearchCondition from "./SearchCondition";
import ShowApproveDoc from "./ShowApproveDoc";

const ExpenseListContent = ({
  searchParam,
  setSearchParam,
  cashbookId,
  baseMonth,
  infoStateData,
}) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();

  // 페이지 상태 관리
  const [searchParamRef, setSearchParamRef] = useState(searchParam); // 조건초기화 용도
  const [searchResult, setSearchResult] = useState([]);
  const [curPage, setCurPage] = useState(1); // pagination
  const [totalPage, setTotalPage] = useState(1);
  const [typeList, setTypeList] = useState(["전체"]);
  const [totalPrice, setTotalPrice] = useState(0);

  // 전역 상태 관리
  const { setCashBookLoading: setLoading } = useCashBookStore();

  useEffect(() => {
    setSearchParamRef(searchParam);
  }, [searchParam]);

  useEffect(() => {
    getIncomeAccountList();
  }, []);

  const getIncomeAccountList = async () => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_SALARY_SERVER + "/cashbook/account/list",
        {
          params: {
            account_type: "expense",
          },
        },
      );

      if (!restEmpty(res.data.data)) {
        setTypeList(res.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  const resetSearchParam = () => {
    setSearchParam({
      create_type: "",
      account: "",
      cashbook_id: cashbookId,
    });
  };

  const onClickSearch = async (isExcel, page) => {
    const formData = new FormData();
    formData.append("search_param", JSON.stringify(searchParamRef));

    setCurPage(page);
    setLoading(true);

    HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + "/cashbook/expense/list", {
      params: {
        cashbook_id: cashbookId,
        create_type: searchParamRef.create_type,
        account: searchParamRef.account,
        cur_page: page,
        is_excel: isExcel,
      },
    })
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          if (isExcel) {
            exportToCashBookExcel(
              "지출상세",
              res.data.data,
              `${infoStateData.branch_info.branch_name}_${infoStateData.base_month}_지출상세내역`,
            );
          } else {
            setTotalPrice(res.data.total_price);
            setTotalPage(res.data.total_page);
            setSearchResult(res.data.data);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onModalDone = (res) => {
    storesDispatch(globalModalOff());
    onClickSearch(false, curPage);
  };

  const onClickAdd = () => {
    const param = {
      cashbookId: cashbookId,
      mode: "add",
      type: "expense",
      typeList,
      baseMonth,
    };

    storesDispatch(
      globalModalOn({
        show: true,
        title: "지출 내역 추가",
        Content: <IncomeExpenseAddModal modalParam={param} onModalDone={onModalDone} />,
      }),
    );
  };

  const onClickEdit = (data) => {
    data.is_old = true; // 삭제버튼 표시 안하기 위함

    const param = {
      cashbookId: cashbookId,
      mode: "edit",
      type: "expense",
      data: _.cloneDeep(data),
      typeList,
      baseMonth,
    };

    storesDispatch(
      globalModalOn({
        show: true,
        title: "지출 내역 수정",
        Content: <IncomeExpenseAddModal modalParam={param} onModalDone={onModalDone} />,
      }),
    );
  };

  const onClickDelete = (data) => {
    simpleAlert(storesDispatch, "알림", "삭제하시겠습니까?", false, undefined, () => {
      onDelete(data.item_id, data.approval_info.doc_info.doc_id);
    });
  };

  const onDelete = async (itemId, docId) => {
    HttpInstance.delete(process.env.REACT_APP_SALARY_SERVER + "/cashbook/expense/delete", {
      params: {
        cashbook_id: cashbookId,
        item_id: itemId,
        // doc_id: docId, // 추후 추가 해야되나? 전자결재 doc까지 삭제...
      },
    })
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          simpleAlert(storesDispatch, "알림", "삭제되었습니다.", true, () => {
            onClickSearch(false, curPage);
          });
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const onClickShowDocument = (data) => {
    const docType = getDocType(data.approval_info.doc_info.doc_type);
    const approvalId = data.approval_info.approval_docs_id;

    storesDispatch(
      globalModalOn({
        show: true,
        title: "서류보기",
        Content: <ShowApproveDoc docType={docType} approvalId={approvalId} />,
      }),
    );
  };

  const getTotalPrice = () => {
    if (searchResult) {
      return searchResult.reduce((sum, cur) => {
        return sum + cur.price;
      }, 0);
    }
    return 0;
  };

  return (
    <div>
      <div>
        <SearchCondition searchParam={searchParamRef} typeList={typeList} />

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "0.75rem",
          }}
        >
          <Button
            className={clsx({
              [btnStyleClass.btnRootWhite]: true,
              [btnStyleClass.buttonType6]: true,
            })}
            onClick={() => {
              resetSearchParam();
            }}
          >
            초기화
          </Button>
          <Button
            className={clsx({
              [btnStyleClass.btnRoot]: true,
              [btnStyleClass.buttonType6]: true,
            })}
            onClick={() => {
              onClickSearch(false, 1);
            }}
            style={{ marginLeft: "0.3rem" }}
          >
            검색
          </Button>
        </div>
      </div>

      <SubLine />

      <div style={{ marginTop: "1rem" }}>
        <ExpenseResultTable
          searchResult={searchResult}
          onClickAdd={onClickAdd}
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
          onClickShowDocument={onClickShowDocument}
          onClickSearch={onClickSearch}
          curPage={curPage}
          totalPage={totalPage}
          infoStateData={infoStateData}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0rem" }}>
        <TextField
          labelText="지출 금액 합계"
          labelWidth="6rem"
          textBoxWidth="10rem"
          textMarginRight="0rem"
          endAdornment="원"
          isNumber
          disabled
          defaultValue={totalPrice.toLocaleString()}
        />
      </div>
    </div>
  );
};
export default ExpenseListContent;
