export const comm_set_init_state = {
  contents: {
    staff_id: "",
    work_role: {
      is_cs: false,
      is_ot: false,
      is_pt: false,
      is_pt_cs: false,
    },
    base_info: {
      branch_info: {
        branch_id: "",
        branch_name: "",
      },
      hq_info: {
        hq_id: "",
        hq_name: "",
      },
    },
    pt_pre_set: [],
    reg_info: {
      created_at: "",
      updated_at: "",
      reg_name: "",
      reg_id: "",
      upd_id: "",
      upd_name: "",
    },
  },
};
