import * as ActionTypes from "./action_type";

///MEMBER VIEW
export const loadStaffSearch = (search_param, page_num, is_excel) => {
  return {
    type: ActionTypes.staffSearchAction,
    search_param: search_param,
    page_num: page_num,
    is_excel: is_excel
  };
};

export const loadStaffSearchSuccess = (data) => {
  return {
    type: ActionTypes.staffSearchSuccessAction,
    payload: data,
  };
};

export const loadStaffSearchFail = (data) => {
  return {
    type: ActionTypes.staffSearchFailAction,
    payload: [],
  };
};

export const loadStaffSearchReset = (data) => {
  return {
    type: ActionTypes.staffSearchResetAction,
    payload: data,
  };
};
