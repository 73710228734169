import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { simpleAlert } from "components/CommonLib/CommonLib";
import TextField from "components/LabelInput/TextField";
import { useDispatch } from "react-redux";

const SaveConditionModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();

  const onSave = (canUseTm) => {
    simpleAlert(
      storesDispatch,
      "알림",
      canUseTm ? "저장하시겠습니까? (TM리스트 + 검색조건)" : "저장하시겠습니까? (검색조건)",
      false,
      undefined,
      () => {
        onModalDone("save", modalParam.condName, canUseTm ? canUseTm : false);
      },
    );
  };

  return (
    <div>
      <TextField
        marginTop="1rem"
        labelWidth="3rem"
        labelText="조건명"
        textBoxWidth="20rem"
        onChangeCallback={(e) => {
          modalParam.condName = e;
        }}
      />

      <div style={{ display: "flex", marginTop: "2.5rem", justifyContent: "center" }}>
        <Button
          className={clsx({
            [btnStyleClass.btnRootWhite]: true,
            [btnStyleClass.buttonType10]: true,
          })}
          onClick={() => {
            onSave(true);
          }}
        >
          TM리스트 + 조건 저장
        </Button>
        <Button
          style={{ marginLeft: "0.5rem" }}
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType7]: true,
          })}
          onClick={() => {
            onSave();
          }}
        >
          조건만 저장
        </Button>
      </div>
    </div>
  );
};
export default SaveConditionModal;
