import { useMutation } from "@tanstack/react-query";
import { patchExtendMbshp } from "apis/member/extend_mbshp_api";
import { simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import NumberTextField from "components/LabelInput/NumberTextField";
import TextField from "components/LabelInput/TextField";
import NormalBtn from "components/NewButton/NormalBtn";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { loadMembershipHistoryStore, loadMemberViewStore } from "../../Module/actions";

const PeriodModalBody = styled.div`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
const PeriodModalFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
`;

const ExtendMembershipModal = ({ modalParam }) => {
  const navigate = useNavigate();
  const storesDispatch = useDispatch();
  const { membership_data_list, active_info, base_info } = modalParam;
  // 페이지 구성 변수
  const labelWidth = "12rem";
  const comboWidth = "12rem";
  const textDetailWidth = "20rem";
  const extendReasonComboList = [
    "이벤트 참여",
    "연기 누락",
    "지인 등록",
    "시작일 변경",
    "동반 가입",
    "PT 소진",
    "기타",
  ];
  // 페이지 상태 관리
  const [beforeExpireDate, setBeforeExpireDate] = useState("");
  const [afterExpireDate, setAfterExpireDate] = useState("");
  const [extendPeriod, setExtendPeriod] = useState(0);
  const [submitData, setSubmitData] = useState({});

  // API Query
  // [PATCH] 회원권 기간 추가
  const { mutate: mutateExtendMbshp } = useMutation({
    mutationFn: patchExtendMbshp,
    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
        return;
      }
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText={`기간이 연장 되었습니다`} />,
          onBtnOk: () => {
            storesDispatch(globalModalOff());
            storesDispatch(loadMemberViewStore(active_info.member_id)); // 회원 정보 가져오기
            storesDispatch(loadMembershipHistoryStore(active_info.member_id)); // 회원권 이력 가져오기
          },
        }),
      );
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });

  // 회원권 없으면 띄우는 errorAlert
  const showErrorAlert = () => {
    storesDispatch(globalModalOff());
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: <Label labelText="변경 가능한 회원권이 없습니다" />,
        onBtnOk: () => {},
      }),
    );
  };

  // 회원권 소유 여부 체크 및 회원권 종료일 세팅
  useEffect(() => {
    if (membership_data_list.length === 0) {
      showErrorAlert();
    }
    // 현재 최종 회원권 종료일 세팅
    setBeforeExpireDate(active_info.last_mbshp_to_date);
  }, []);

  useEffect(() => {
    calcPeriodDate();
  }, [extendPeriod]);

  // 기간 연장 후 최종 종료일 계산
  const calcPeriodDate = () => {
    const beforeDate = new Date(active_info.last_mbshp_to_date);
    const calcDate = new Date(beforeDate);

    calcDate.setDate(beforeDate.getDate() + extendPeriod);
    let newYear = calcDate.getFullYear();
    let newMonth = calcDate.getMonth() + 1;
    let newDay = calcDate.getDate();

    if (newMonth < 10) newMonth = "0" + newMonth;
    if (newDay < 10) newDay = "0" + newDay;

    const afterDate = `${newYear}-${newMonth}-${newDay}`;

    setAfterExpireDate(afterDate);
    setSubmitData({
      ...submitData,
      new_to_date: afterDate,
    });
  };

  // 제출 데이터 세팅
  const onChangeSubmitData = (name, value) => {
    const memberId = active_info.member_id;
    const membershipId =
      membership_data_list[membership_data_list.length - 1].membership_list.membership_id;

    setSubmitData({
      ...submitData,
      member_id: memberId,
      membership_id: membershipId,
      [name]: value,
    });
  };

  // 제출 데이터 유효성 검사 후 query 실행
  const validation = () => {
    let alertMessage;
    if (!("extend_type" in submitData)) {
      alertMessage = "추가 구분을 선택해주세요";
    } else if (!("extend_reason" in submitData) || submitData["extend_reason"] === "") {
      alertMessage = "상세 사유를 입력해주세요";
    } else if (!("extend_days" in submitData) || submitData["extend_days"] === 0) {
      alertMessage = "추가 일수를 정확히 입력해주세요";
    }

    if (alertMessage) {
      return simpleAlert(storesDispatch, "알림", alertMessage);
    }
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText={`"${base_info.personal_info.member_name}"님의 회원권 기간을`} />
            <Label labelText={`${submitData["extend_days"]}일 추가 하시겠습니까?`} />
            <Label
              color={"red"}
              fontSize={"0.875rem"}
              marginTop={"0.5rem"}
              labelText={`최종 회원권 종료일 ${submitData["new_to_date"]}`}
            />
          </>
        ),
        onBtnOk: () => {
          mutateExtendMbshp(submitData);
        },
        onBtnNo: () => {},
      }),
    );
  };

  return (
    <>
      <PeriodModalBody>
        <ComboBox
          labelWidth={labelWidth}
          comboItemWidth={comboWidth}
          labelText="추가 구분"
          data={extendReasonComboList}
          onChangeCallback={(e) => {
            onChangeSubmitData("extend_type", e.target.value);
          }}
          required
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={textDetailWidth}
          labelText="추가 상세 사유"
          maxLength={40}
          onChangeCallback={(e) => {
            onChangeSubmitData("extend_reason", e);
          }}
          required
        />
        <NumberTextField
          labelWidth={labelWidth}
          textBoxWidth={comboWidth}
          textAlign={"left"}
          labelText="추가 일수"
          defaultValue=""
          maxLength={3}
          onChangeCallback={(e) => {
            onChangeSubmitData("extend_days", e);
            setExtendPeriod(e);
          }}
          required
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={comboWidth}
          defaultValue={beforeExpireDate}
          disabled
          labelText="현재 최종 회원권 종료일"
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={comboWidth}
          defaultValue={afterExpireDate}
          disabled
          labelText="추가 후 최종 회원권 종료일"
        />
        {/* <TextField
          
          labelWidth={labelWidth}
          textBoxWidth={comboWidth}
          defaultValue={staffName}
          disabled
          labelText="담당 직원"
        /> */}
      </PeriodModalBody>
      <PeriodModalFooter>
        <NormalBtn
          name={"저장"}
          onClick={() => {
            validation();
          }}
        />
      </PeriodModalFooter>
    </>
  );
};

export default ExtendMembershipModal;
