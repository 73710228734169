import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import ExtendMbshpHistoryWrapper from "./ExtendMbshpHistoryWrapper";

const ExtendMbshpHistory = () => {
  return (
    <PageFrame
      children={
        <div>
          <PageTitle titleText="기간 추가 현황" />
          <ExtendMbshpHistoryWrapper />
        </div>
      }
    />
  );
};
export default ExtendMbshpHistory;
