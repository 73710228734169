import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import DatePicker from "components/LabelInput/DatePicker";
import NumberTextField from "components/LabelInput/NumberTextField";
import _ from "lodash";
import { vault_cash_state } from "pages/ApprovalManage/Module/reducers/approval_content_state";
import { useCallback, useEffect, useState } from "react";

const VaultCashForm = ({ mode, baseState, contentState, setContentState }) => {
  const readOnly = mode === "approver" || mode === "unspent" || mode === "view";
  const [, updateState] = useState(); // 강제 렌더링용
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    if (contentState.item_list.length === 0) {
      addRow();
    }
  }, [contentState]);

  const addRow = () => {
    contentState.item_list.push(_.cloneDeep(vault_cash_state));
    forceUpdate();
  };

  const contentList = new contentTable(readOnly);

  return (
    <div>
      <div style={{ margin: "0.5rem 0.1rem" }}>
        <CustomTable
          columns_head={contentList.columns_head}
          table_title={contentList.table_title}
          rest_call={contentList.get_data_from_rest}
          row_render={contentList.create_table}
          rest_data={contentState.item_list}
          height="12rem"
        />
      </div>
    </div>
  );
};
export default VaultCashForm;

//

class contentTable {
  readOnly = null;

  constructor(readOnly) {
    this.readOnly = readOnly;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>
          <DatePicker
            fullWidth
            labelMarginRight="0rem"
            textMarginRight="0rem"
            defaultValue={data.reg_date}
            onChangeCallback={(e) => {
              data.reg_date = e;
            }}
            disabled={this.readOnly}
          />
        </CustomTableContents>
        <CustomTableContents>
          <NumberTextField
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            defaultValue={data.price}
            onChangeCallback={(e) => {
              data.price = e;
            }}
            isNumber
            endAdornment="원"
            disabled={this.readOnly}
          />
        </CustomTableContents>
        <CustomTableContentsEND></CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "입금일", width: "20%" },
    { title: "입금액", width: "20%" },
    { title: "", width: "60%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
