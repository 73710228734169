import * as ActionTypes from "./action_type";

///BASE
export const loadBranchBase = (branch_id) => {
  return {
    type: ActionTypes.getBranchBaseAction,
    branch_id: branch_id,
    // branch_name: branch_name,
  };
};

export const loadBranchBaseSuccess = (data) => {
  return {
    type: ActionTypes.getBranchBaseSuccessAction,
    payload: data,
  };
};

export const loadBranchBaseFail = (data) => {
  return {
    type: ActionTypes.getBranchBaseFailAction,
    payload: [],
  };
};

export const loadBranchBaseReset = (data) => {
  return {
    type: ActionTypes.getBranchBaseResetAction,
    payload: data,
  };
};

///POLICY MANAGE
export const loadPolicyView = (branch_id) => {
  return {
    type: ActionTypes.getPolicyManageViewAction,
    branch_id: branch_id,
    // branch_name: branch_name,
  };
};

export const loadPolicyViewSuccess = (data) => {
  return {
    type: ActionTypes.getPolicyManageViewSuccessAction,
    payload: data,
  };
};

export const loadPolicyViewFail = (data) => {
  return {
    type: ActionTypes.getPolicyManageViewFailAction,
    payload: [],
  };
};

export const loadPolicyViewReset = (data) => {
  return {
    type: ActionTypes.getPolicyManageViewResetAction,
    payload: data,
  };
};
