export const insertStaffInfo = () => {
  return process.env.REACT_APP_STAFF_SERVER + "/staff/new";
};
export const updateStaffInfo = () => {
  return process.env.REACT_APP_STAFF_SERVER + "/staff/update";
};
export const insertWorkInfo = () => {
  return process.env.REACT_APP_STAFF_SERVER + "/staff/new_work_info";
};
export const editWorkInfo = () => {
  return process.env.REACT_APP_STAFF_SERVER + "/staff/edit_work_info";
};
export const editCancelWorkInfo = () => {
  return process.env.REACT_APP_STAFF_SERVER + "/staff/edit_cancel_work_info";
};
export const approveWorkInfo = () => {
  return process.env.REACT_APP_STAFF_SERVER + "/staff/work_info/privileged_approval";
};
export const draftWorkInfo = () => {
  return process.env.REACT_APP_STAFF_SERVER + "/staff/work_info_draft";
};
export const transWorkInfo = () => {
  return process.env.REACT_APP_STAFF_SERVER + "/staff/work_info_new_trans";
};
export const reEntryWorkInfo = () => {
  // rejoin
  return process.env.REACT_APP_STAFF_SERVER + "/staff/work_info_re_entry";
};
export const recallWorkInfo = () => {
  return process.env.REACT_APP_STAFF_SERVER + "/staff/retrieve_work_info";
};
export const retireWorkInfo = () => {
  return process.env.REACT_APP_STAFF_SERVER + "/staff/work_info_retirement";
};

export const insertGxFeeInfo = () => {
  return process.env.REACT_APP_STAFF_SERVER + "/staff/new_gx_fee";
};

const post_api = {
  insertStaffInfo,
  updateStaffInfo,
  insertWorkInfo,
  editWorkInfo,
  editCancelWorkInfo,
  retireWorkInfo,
  recallWorkInfo,
  reEntryWorkInfo,
  transWorkInfo,
  draftWorkInfo,
  approveWorkInfo,
  insertGxFeeInfo,
};

export default post_api;
