import styled from "styled-components";

const MenuTitle = styled.span`
  width: 83px;
  height: 100%;

  font-family: AppleSDGothicNeo;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "0.938rem")};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: normal;
  text-align: left;
  color: #000;

  margin-right: ${(props) => props.marginRight};
`;

const TitleDiv = styled.div`
  text-align: left;
  // margin-bottom: 10px;
`;

const SubTitle = (props) => {
  return (
    <TitleDiv>
      <MenuTitle fontSize={props.fontSize} marginRight={props.marginRight}>
        {props.titleText}
      </MenuTitle>
    </TitleDiv>
  );
};
export default SubTitle;
