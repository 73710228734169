import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import { SubLine } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import { globalAlertOn } from "modules/actions/Alert";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { payListActions } from "./Module/actions";
import { getCouponBenefit, getCouponDiscountPrice } from "./PaymentModal/CommonFunc";

const PaymentInfo = forwardRef((props, ref) => {
  const storesDispatch = useDispatch();
  const itemListState = useSelector((state) => state.member_common_state.pay_list_state);

  const [csUnpaidPrice, setCsUnpaidPrice] = useState(0);
  const [ptUnpaidPrice, setPtUnpaidPrice] = useState(0);
  const [remainPoint, setRemainPoint] = useState(props.remainPoint);
  const [usedPoint, setUsedPoint] = useState(0);
  const [discountCoupon, setDiscountCoupon] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);
  const [couponBenefitText, setCouponBenefitText] = useState();

  const [csPaidInfo, setCsPaidInfo] = useState({
    pay_method: [],
    total_paid: 0,
    unpaid_info: {
      unpaid_price: 0,
      reason: "",
      promise_date: "",
    },
  });
  const [ptPaidInfo, setPtPaidInfo] = useState({
    pay_method: [],
    total_paid: 0,
    unpaid_info: {
      unpaid_price: 0,
      reason: "",
      promise_date: "",
    },
  });
  const [refundPaidInfo, setRefundPaidInfo] = useState([]);

  useEffect(() => {
    storesDispatch(payListActions.paymentInfoClear("clear"));
    return () => {
      storesDispatch(payListActions.paymentInfoClear("clear"));
    };
  }, []);

  useEffect(() => {
    calcUnpaid();
    setDocsValid();
  }, [itemListState]);

  useEffect(() => {
    setRemainPoint(props.remainPoint);
  }, [props.remainPoint]);

  useEffect(() => {
    setDiscountTotal(usedPoint + discountCoupon);
  }, [usedPoint, discountCoupon]);

  // 결제정보에 추가된 아이템들 기준으로 제출서류 is_valid값 셋팅
  const setDocsValid = () => {
    // const keyValuePairs = [];
    // keyValuePairs.push({ key: "PT", value: "PT 가입신청서" });
    // // keyValuePairs.push({ key: "", value: "" });
    // for (let i = 0; i < keyValuePairs.length; i++) {
    //   if (itemListState.findIndex((x) => x.type_key === keyValuePairs[i].key) === -1) {
    //     props.controlDocList(keyValuePairs[i].value, false);
    //   } else {
    //     props.controlDocList(keyValuePairs[i].value, true);
    //   }
    // }
  };

  const getPayMethodCnt = () => {
    let cnt = 0;
    for (let i = 0; i < csPaidInfo.pay_method.length; i++) cnt++;
    for (let i = 0; i < ptPaidInfo.pay_method.length; i++) cnt++;

    if (refundPaidInfo.length > 0) {
      for (let i = 0; i < refundPaidInfo.length; i++) {
        for (let j = 0; j < refundPaidInfo[i].pay_method.length; j++) cnt++;
      }
    }
    return cnt;
  };

  const updatePointUsage = (type) => {
    let usedPoint = 0;
    const idx = csPaidInfo.pay_method.findIndex((x) => x.pay_method === "포인트");
    if (idx !== -1) {
      usedPoint += csPaidInfo.pay_method[idx].price;
    }
    const ptIdx = ptPaidInfo.pay_method.findIndex((x) => x.pay_method === "포인트");
    if (ptIdx !== -1) {
      usedPoint += csPaidInfo.pay_method[ptIdx].price;
    }
    setUsedPoint(usedPoint);
  };

  useImperativeHandle(ref, () => ({
    getCsPaidResult: () => {
      return csPaidInfo;
    },
    setCsPaidResult: (pay_info) => {
      setCsPaidInfo(pay_info);
      updatePointUsage();
    },
    setRefundPaidResult: (pay_info) => {
      setRefundPaidInfo(pay_info);
      // setCsPaidInfo(pay_info);
      updatePointUsage();
    },
    getPtPaidResult: () => {
      return ptPaidInfo;
    },
    setPtPaidResult: (pay_info) => {
      setPtPaidInfo(pay_info);
      updatePointUsage();
    },
    getPaidItemList: () => {
      return itemListState;
    },
    resetPaidResult: () => {
      if (props.isRefund) {
        for (let i = 0; i < refundPaidInfo.length; i++) {
          refundPaidInfo[i].pay_method = [];
          refundPaidInfo[i].total_paid = 0;
          refundPaidInfo[i].unpaid_info.unpaid_price = refundPaidInfo[i].total_price;
        }
      }
      calcUnpaid();
    },
    setCouponInfo: (couponInfo) => {
      // console.log(couponInfo);
      // setDiscountCoupon()
      if (couponInfo.base_info.benefit_type === "금액혜택") {
        const discPrice = getCouponDiscountPrice(couponInfo, itemListState);
        setDiscountCoupon(discPrice);
      } else if (couponInfo.base_info.benefit_type === "이용혜택") {
        setDiscountCoupon(0);
      } else {
        console.log("error");
      }
      setCouponBenefitText(getCouponBenefit(couponInfo));
    },
    setPointInfo: (remainPoint, usedPoint) => {
      setRemainPoint(remainPoint);
      setUsedPoint(usedPoint);
    },
    getPointInfo: () => {
      return {
        remain: remainPoint,
        used: usedPoint,
      };
    },
  }));

  const resetPaid = () => {
    setCsPaidInfo({
      pay_method: [],
      total_paid: 0,
      unpaid_info: {
        unpaid_price: 0,
        reason: "",
        promise_date: "",
      },
    });
    setPtPaidInfo({
      pay_method: [],
      total_paid: 0,
      unpaid_info: {
        unpaid_price: 0,
        reason: "",
        promise_date: "",
      },
    });
    setRefundPaidInfo([]);
  };

  const calcUnpaid = () => {
    let csTotalPrice = 0;
    let csTotalDiscount = 0;
    let ptTotalPrice = 0;
    let ptTotalDiscount = 0;
    for (let i = 0; i < itemListState.length; i++) {
      if (itemListState[i].type_key !== "PT") {
        csTotalPrice += itemListState[i].content_obj.price;
        csTotalDiscount += itemListState[i].content_obj.dis;
      }
      if (itemListState[i].type_key === "PT") {
        ptTotalPrice += itemListState[i].content_obj.price;
        ptTotalDiscount += itemListState[i].content_obj.dis;
      }
    }

    // setUnPaidPrice(totalPrice - paidInfoState.stores.data.total_paid);
    setCsUnpaidPrice(csTotalPrice - csTotalDiscount);
    setPtUnpaidPrice(ptTotalPrice - ptTotalDiscount);
    setCouponBenefitText("");
    setUsedPoint(0);
    setDiscountCoupon(0);

    if (csPaidInfo.total_paid > 0 || ptPaidInfo.total_paid > 0) {
      resetPaid();
    }

    if (props.isRefund && itemListState.length === 0) {
      //
    } else {
      props.setCanEnroll(false);
    }
  };

  // 결제 팝업 띄움
  const onOpenPayment = () => {
    let useCs = false;
    let usePt = false;
    let isMbshpInclude = false;
    if (itemListState.findIndex((x) => x.type_key !== "PT") !== -1) {
      useCs = true;
    }
    if (itemListState.findIndex((x) => x.type_key === "PT") !== -1) {
      usePt = true;
    }
    if (itemListState.findIndex((x) => x.type_key.includes("회원권")) !== -1) {
      isMbshpInclude = true;
    }

    let totalPayPrice = 0;
    for (let i = 0; i < itemListState.length; i++) {
      totalPayPrice += itemListState[i].content_obj.price - itemListState[i].content_obj.dis;
    }

    const checkPtOnly = () => {
      if (props.isAddPay === false && isMbshpInclude === false && usePt === true) {
        return true;
      } else if (
        props.isAddPay === true &&
        props.mbshpCnt === 0 &&
        isMbshpInclude === false &&
        usePt === true
      ) {
        return true;
      }
      return false;
    };

    const param = {
      title: "결제",
      modalParam: {
        // unPaidPrice: unPaidPrice + paidInfoState.stores.data.total_paid,
        csUnpaidPrice: csUnpaidPrice + 0,
        ptUnpaidPrice: ptUnpaidPrice + 0,
        useCs: useCs,
        usePt: usePt,
        csPaidInfo: csPaidInfo,
        ptPaidInfo: ptPaidInfo,
        unpaidInfo: {
          pay_method: [],
        },
        remainPoint: remainPoint,
        usedPoint: usedPoint,
        regDate: props.regDate,
        isAllCalc: props.isAllCalc && props.isAllCalc,
      },
    };

    // console.log(param);
    if (itemListState.findIndex((x) => x.content_obj.type === "기타상품(미선택)") !== -1) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="선택되지 않은 기타상품이 있습니다." />,
          onBtnOk: () => {},
        }),
      );
    } else if (!useCs && !usePt) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="결제할 수 있는 항목이 없습니다." />,
          onBtnOk: () => {},
        }),
      );
    } else if (checkPtOnly() === true) {
      // 회원권 없이 PT만 결제하려는 경우
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="PT는 회원권 없이 등록할 수 없습니다." />,
          onBtnOk: () => {},
        }),
      );
    } else if (props.isChangePtDownGradeCheck) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="PT 횟수 차감시 결제수단을 모두 선택해야합니다." />,
          onBtnOk: () => {},
        }),
      );
    } else if (
      (refundPaidInfo.length === 1 && refundPaidInfo[0].total_price === 0) ||
      (itemListState.length > 0 && totalPayPrice === 0)
    ) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="결제할 수 있는 항목이 없습니다." />
              <br />
              <Label labelText="등록을 진행해 주시기 바랍니다." whiteSpace="pre-wrap" />
            </>
          ),
          onBtnOk: () => {
            props.setCanEnroll(true);
          },
        }),
      );

      const paymentInfo = {
        pay_method: [],
        total_paid: 0,
        unpaid_info: {
          unpaid_price: 0,
          reason: "",
          promise_date: "",
        },
      };
      if (props.onPaymentUpdate) {
        props.onPaymentUpdate(paymentInfo, paymentInfo);
      }
    } else if (getPayMethodCnt() < 1) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText={"결제일이 [" + props.regDate + "] 가 맞습니까?"} />,
          onBtnYes: () => {
            props.onClickOpenModal("결제", param);
          },
          onBtnNo: () => {},
        }),
      );
    } else {
      props.onClickOpenModal("결제", param);
    }
  };

  const onOpenCoupon = () => {
    const param = {
      title: "쿠폰 적용",
      modalParam: {
        coupon_list: [],
        branch_id: props.baseInfoState.branch_setting.branch_info.branch_id,
        itemListState: itemListState,
        tabNameList: props.tabNameList,
      },
    };

    props.onClickOpenModal("쿠폰적용", param);
  };

  const getRefundPaidPrice = () => {
    let paid = 0;
    refundPaidInfo.forEach((item, idx) => {
      paid += item.total_paid;
    });
    return paid;
  };

  const getRefundUnpaidPrice = () => {
    let unpaid = 0;
    refundPaidInfo.forEach((item, idx) => {
      unpaid += item.unpaid_info.unpaid_price;
    });
    return unpaid;
  };

  const couponTextArea = [
    {
      type_key: "point",
      content_obj: {
        type: "포인트 사용",
        price: usedPoint,
      },
    },
    {
      type_key: "coupon",
      content_obj: {
        type: "쿠폰 할인",
        price: discountCoupon,
      },
    },
    {
      type_key: "coupon_detail",
      content_obj: {
        type: "적용된 혜택",
        price: couponBenefitText,
      },
    },
    {
      type_key: "coupon_point_total",
      content_obj: {
        type: "할인 총액",
        price: discountTotal,
      },
    },
  ];

  const payResultTextArea = [
    {
      type_key: "total_paid",
      content_obj: {
        type: "결제 금액",
        price: props.isRefund
          ? getRefundPaidPrice()
          : Number(csPaidInfo.total_paid) + Number(ptPaidInfo.total_paid) + discountTotal,
      },
    },
    {
      type_key: "total_unpaid",
      content_obj: {
        type: "미납 금액",
        // price:
        //   Number(csUnpaidPrice) +
        //   Number(ptUnpaidPrice) -
        //   Number(csPaidInfo.total_paid) -
        //   Number(ptPaidInfo.total_paid) -
        //   discountTotal,
        price: props.isRefund
          ? getRefundUnpaidPrice()
          : Number(csUnpaidPrice) +
            Number(ptUnpaidPrice) -
            Number(csPaidInfo.total_paid) -
            Number(ptPaidInfo.total_paid) -
            discountTotal,
      },
    },
  ];

  return (
    <div>
      <CardFixed
        topDom={<div>결제정보</div>}
        midDom={
          <PaymentResult
            itemList={itemListState}
            couponTextArea={couponTextArea}
            payResultTextArea={payResultTextArea}
            onOpenCoupon={onOpenCoupon}
            onOpenPayment={onOpenPayment}
            isRefund={props.isRefund}
            noCoupon={props.noCoupon}
          />
        }
        isLoading={false}
        rootStyle={{
          // width: "25.63rem",
          // height: "435px",
          // marginLeft: "10px",
          borderRadius: "10px",
          border: "solid 2px #032063",
          backgroundColor: "#fff",
        }}
      />
    </div>
  );
});
export default PaymentInfo;

//
const PaymentResultItem = (props) => {
  const baseStyle = {
    height: "1.188rem",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.938rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
    marginBottom: props.marginBottom !== undefined ? props.marginBottom : "0.5rem",
  };

  return (
    <div style={baseStyle}>
      <div
        style={{
          display: "flex",
          float: "left",
          marginRight: "auto",
          fontWeight: props.isBold === undefined ? "normal" : "bold",
          fontSize: props.isBigFont ? "1.125rem" : props.isSmallFont ? "0.8rem" : "0.938rem",
          color: props.color,
          alignItems: "center",
        }}
      >
        {props.icon ? props.icon : ""}
        {props.data.content_obj.type}
        {/* {data.type_key} */}
      </div>

      <div
        style={{
          display: "flex",
          float: "right",
          marginLeft: "auto",
          fontWeight: props.isBold === undefined ? "normal" : "bold",
          fontSize: props.isBigFont ? "1.125rem" : props.isSmallFont ? "0.8rem" : "0.938rem",
          color: props.color,
        }}
      >
        {props.data.type_key === "coupon_detail"
          ? ""
          : props.isAddDis !== undefined
          ? (props.data.content_obj.price - props.data.content_obj.dis).toLocaleString() + " 원"
          : props.data.content_obj.price.toLocaleString() + " 원"}
      </div>
    </div>
  );
};

//
const PaymentResult = ({
  itemList,
  couponTextArea,
  payResultTextArea,
  onOpenCoupon,
  onOpenPayment,
  noCoupon,
  isRefund,
}) => {
  const btnStyleClass = btnStyles();

  return (
    <div
      style={{
        marginTop: "1.25rem",
      }}
    >
      {itemList.map((item, idx) => (
        <PaymentResultItem key={idx} data={item} isAddDis={true} />
      ))}

      <SubLine />

      {isRefund ? (
        <></>
      ) : (
        <>
          <PaymentResultItem data={couponTextArea[0]} />
          {noCoupon ? (
            <></>
          ) : (
            <>
              <PaymentResultItem data={couponTextArea[1]} />
              <PaymentResultItem data={couponTextArea[2]} isSmallFont color="#af4f4f" />
            </>
          )}
          <PaymentResultItem
            data={couponTextArea[3]}
            isBold={true}
            isBigFont
            // icon={<PlusIcon style={{ marginRight: "0.25rem" }} />}
          />
          <SubLine />
        </>
      )}

      <PaymentResultItem data={payResultTextArea[0]} isBold={true} isBigFont marginBottom="1rem" />
      <PaymentResultItem data={payResultTextArea[1]} isBold={true} isBigFont color="#ff3e3e" />
      <SubLine />

      <div style={{ display: "flex", justifyContent: "center" }}>
        {isRefund || noCoupon ? (
          <></>
        ) : (
          <Button
            className={clsx({
              [btnStyleClass.btnRootWhite]: true,
              [btnStyleClass.buttonType99]: true,
            })}
            onClick={() => onOpenCoupon()}
          >
            쿠폰 적용
          </Button>
        )}
        <Button
          style={{ marginLeft: "0.5rem" }}
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType99]: true,
          })}
          onClick={() => onOpenPayment()}
        >
          결제
        </Button>
      </div>
    </div>
  );
};
