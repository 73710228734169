export const selectStyle = (type, subType) => {
  let boxStyle = {
    cursor: "pointer",
    marginTop: "0.2rem",
    borderRadius: "5px",
    textAlign: "left",
    padding: "0.25rem 0.5rem",
    fontSize: "0.8rem",
    backgroundColor: "",
    fontFamily: "AppleSDGothicNeo",
    color: "#fff",
  };
  if (type === "예약") {
    if (subType === "OT") {
      boxStyle.backgroundColor = "#FF6B17";
    } else if (subType === "PT") {
      boxStyle.backgroundColor = "#30C8C8";
    } else {
      boxStyle.backgroundColor = "#3022D7";
    }
  } else if (type === "수업완료") {
    if (subType === "OT") {
      boxStyle.backgroundColor = "#6433CE";
    } else {
      boxStyle.backgroundColor = "#DC35B7";
    }
  } else if (type === "개인업무") {
    boxStyle.backgroundColor = "#333232";
  } else if (type === "휴무") {
    boxStyle.backgroundColor = "#538600";
  } else if (type === "전달사항") {
    boxStyle.backgroundColor = "#E1243A";
  }
  return boxStyle;
};

export const normalTimeStyle = {
  width: "100%",
  padding: "0.2rem 0.1rem",
  margin: "0 auto",
  boxSizing: "border-box",
  fontFamily: "AppleSDGothicNeo",
};

export const timeLineBoxStyle = {
  borderTop: "1px solid #EBEBEB",
  borderLeft: "1px solid #EBEBEB",
  borderRight: "1px solid #EBEBEB",
  fontFamily: "AppleSDGothicNeo",
  padding: "0.3rem 0.6rem",
  minHeight: "3rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // overflowY: "auto",
};
export const boxStyle = {
  borderTop: "1px solid #EBEBEB",
  borderLeft: "1px solid #EBEBEB",
  borderRight: "1px solid #EBEBEB",
  padding: "0.3rem 0.6rem",
  minHeight: "3rem",

  // overflowY: "auto",
};

export const today_style_month = {
  backgroundColor: "#003CCB",
  color: "#ffffff",
  width: "1.2rem",
  minWidth: "1.2rem",
  height: "1.2rem",
  textAlign: "center",
  boxSizing: "border-box",
  paddingTop: "0.25rem",
  fontSize: "0.72rem",
  borderRadius: "50%",
  fontFamily: "AppleSDGothicNeo",
};

export const normalStyle = {
  border: "1px solid #EBEBEB",
  padding: "0.3rem 0.6rem",
  maxHeight: "7rem",
  overflowY: "auto",
};

export const today_style_week = {
  backgroundColor: "#003CCB",
  color: "#fff",
  borderRadius: "100px",
  width: "60%",
  padding: "0.2rem",
  margin: "0 auto",
  boxSizing: "border-box",
  fontFamily: "AppleSDGothicNeo",
};
