// import { memberViewAction, loadMemberViewStore } from "./Module/actions";
import IconButton from "components/Button/IconButton";
import { SubLine } from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import FileUpload from "components/LabelInput/FileUpload";
import NumberTextField from "components/LabelInput/NumberTextField";
import RadioGroup2 from "components/LabelInput/RadioGroup2";
import SubTitle from "components/PageTitle/SubTitle";
import { useEffect, useState } from "react";
import GxSalaryCard from "./SalaryCard/GxSalaryCard";
import PtSalaryCard from "./SalaryCard/PtSalaryCard";

const ItemBlock1 = ({ pageMode, workInfo, positionDetail }) => {
  const isEditMode = pageMode === "추가" || pageMode === "수정";
  const [wageType, setWageType] = useState(workInfo.wage_type);
  const [wage, setWage] = useState(workInfo.wage);

  useEffect(() => {
    setWageType(workInfo.wage_type);
    setWage(workInfo.wage);
  }, [workInfo.wage_type]);

  useEffect(() => {
    if (pageMode === "보기") {
      setWageType(workInfo.wage_type);
    } else {
      if (positionDetail.wage_type && positionDetail.wage) {
        workInfo.wage_type = positionDetail.wage_type;
        workInfo.wage = positionDetail.wage;
      }
      setWageType(positionDetail.wage_type);
      setWage(positionDetail.wage);
    }
  }, [positionDetail]);

  return (
    <div>
      <SubLine />
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="기본급" />
      </div>

      <div style={{ display: "flex" }}>
        {/* <Label labelText="· 기본급" marginRight="1.5rem" /> */}
        <RadioGroup2
          isRow
          marginTop="0rem"
          innerMarginTop="0rem"
          innerMarginLeft="0rem"
          innerMarginRight="0.25rem"
          labelMarginRight="1rem"
          data={["월급제", "시급제"]}
          labelMarginLeft={"0.625rem"}
          isMulti={true}
          defaultValue={wageType}
          onChangeCallback={(e, v) => {
            setWageType(v);
            workInfo.wage_type = v;
            // setPositionDetail({
            //   ...positionDetail,
            //   wage_type: v,
            // });
          }}
          disabled={!isEditMode}
        />

        <NumberTextField
          labelWidth={"0rem"}
          textBoxWidth={"9.5rem"}
          textMarginRight={"0.25rem"}
          labelMarginLeft={"0rem"}
          labelMarginRight={"0rem"}
          endAdornment="원"
          isNumber={true}
          defaultValue={wage}
          onChangeCallback={(e) => {
            setWage(e);
            workInfo.wage = e;
          }}
          disabled={!isEditMode}
        />
      </div>
      <SubLine />

      <CheckBox
        marginTop="0rem"
        labelText="회원권 판매 인센티브"
        defaultValue={workInfo.is_incentive}
        onChange={(e) => {
          workInfo.is_incentive = e.target.checked;
        }}
        disabled={!isEditMode}
      />
      <SubLine />
    </div>
  );
};

const ItemBlock4 = ({ pageMode, workInfo }) => {
  const isEditMode = pageMode === "추가" || pageMode === "수정";

  return (
    <div style={{}}>
      <div style={{ display: "flex" }}>
        <FileUpload
          labelWidth={"5rem"}
          textBoxWidth={"15rem"}
          textMarginRight={"0rem"}
          labelText={"근로계약서"}
          initFile={
            workInfo.files.is_submit_emp_file === "등록완료"
              ? workInfo.files.emp_contract_file.file_info
              : null
          }
          onUploadFile={(file) => {
            // onUploadFile("id_card", file)
            // setIdCardFile(file);
            // staffInfoRoot.staff_obj.files.id_card.file_info.file_name = file.name;
            // staffInfoRoot.staff_obj.files.id_card.file_info.file_type = file.type;
          }}
          disabled={!isEditMode}
        />
      </div>

      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <FileUpload
          labelWidth={"5rem"}
          textBoxWidth={"15rem"}
          textMarginRight={"0rem"}
          labelText={"사직서"}
          initFile={
            workInfo.files.is_submit_resign === "등록완료"
              ? workInfo.files.resign_file.file_info
              : null
          }
          onUploadFile={(file) => {
            // onUploadFile("id_card", file)
          }}
          disabled={!isEditMode}
        />
      </div>
    </div>
  );
};

const WorkInfoSalary = ({
  pageMode,
  positionDetail,
  workInfoStore,
  setPositionDetail,
  staffId,
  staffName,
  staffRank,
  staffPos,
}) => {
  const [workInfo, setWorkInfo] = useState(workInfoStore);
  useEffect(() => {
    setWorkInfo(workInfoStore);
  }, [workInfoStore]);

  return (
    <div
      style={{
        marginTop: "0.5rem",
      }}
    >
      <ItemBlock1
        pageMode={pageMode}
        workInfo={workInfo}
        positionDetail={positionDetail}
        setPositionDetail={setPositionDetail}
      />
      <PtSalaryCard
        pageMode={pageMode}
        workInfo={workInfo}
        positionDetail={positionDetail}
        setPositionDetail={setPositionDetail}
      />
      <GxSalaryCard
        pageMode={pageMode}
        workInfo={workInfo}
        staffId={staffId}
        staffName={staffName}
        staffPos={staffPos}
        staffRank={staffRank}
      />
      {pageMode === "보기?" || pageMode === "이력" ? (
        <>
          <SubLine />
          <ItemBlock4 pageMode={pageMode} workInfo={workInfo} />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
export default WorkInfoSalary;
