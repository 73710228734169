import styled from "styled-components";

export const StyledButton = styled.button`
  position: relative;
  width: 11.25rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: none;
  font-family: AppleSDGothicNeo;
  font-size: 1rem;
  font-weight: ${(props) => (props.$isActive || props.$isSelected ? "normal" : "bold")};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  border: none;
  border-radius: 0px;
  background-color: ${(props) =>
    props.$isActive ? "#032063" : props.$isSelected ? "#2B4B8B" : "#FFF"};
  color: ${(props) => (props.$isActive || props.$isSelected ? "#FFF" : "#222")};
  cursor: pointer;
  padding-left: ${(props) => (props.$isRoot ? "1.5rem" : props.$isSubMenu ? "2.25rem" : "0")};
  text-decoration: none;

  &:hover {
    background-color: ${(props) => (props.$isActive || props.$isSelected ? "#4C679D" : "#E8EDF7")};
    text-decoration: none;
  }
`;

export const StyledChildButton = styled(StyledButton)`
  font-size: 0.975rem;
  height: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  line-height: 1.54;
  letter-spacing: -0.13px;
  padding-left: 2.25rem;
  color: ${(props) => (props.$isActive || props.$isSelected ? "#032063" : "#222")};
  font-weight: ${(props) => (props.$isActive || props.$isSelected ? "bold" : "normal")};
  background-color: #f4f6f9;
  border-left: ${(props) => (props.$isActive || props.$isSelected ? "4px solid #032063" : "")};

  &:hover {
    background-color: ${(props) => (props.$isActive || props.$isSelected ? "#4C679D" : "#E8EDF7")};
    font-weight: ${(props) => (props.$isActive || props.$isSelected ? "normal" : "bold")};
    color: ${(props) => (props.$isActive || props.$isSelected ? "#FFF" : "#032063")};
    border-left: 4px solid #032063;
    transition: all 0.3s ease;
  }
`;

export const StyledDoubleDepthChildButton = styled(StyledButton)`
  height: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 400;
  display: flex;
  justify-content: start;
  font-size: 0.9rem;
  padding-left: 2.55rem;
  background-color: ${(props) => (props.$isActive ? "#032063" : "rgba(3, 32, 99, 0.05)")};
  color: ${(props) => (props.$isActive ? "#FFF" : "#032063")};
  text-decoration: none;

  &:hover {
    background-color: #4c679d;
    color: #fff;
    text-decoration: none;
    transition: all 0.3s ease;
  }
`;
