import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, isArrayEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import { useDispatch } from "react-redux";

const UnpaidStatusResultTable = ({
  searchResult,
  curPage,
  totalPage,
  onClickSearch,
  onClickDetail,
  onClickExemption,
}) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();
  const searchResultList = new resultTable(onClickDetail, onClickExemption);

  const doSearch = (page) => {
    onClickSearch(false, page);
  };

  const onClickSaveList = () => {
    simpleAlert(storesDispatch, "알림", "서비스 준비중입니다.");
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          float: "right",
          marginLeft: "auto",
          marginBottom: "0.625rem",
        }}
      >
        <Button
          className={clsx({
            [btnStyleClass.btnRootWhite]: true,
            [btnStyleClass.buttonType6]: true,
          })}
          onClick={() => {
            onClickSaveList();
          }}
          style={{ marginLeft: "0.5rem" }}
        >
          목록 저장
        </Button>
      </div>

      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
        // width="160rem"
      />
      <CustomPagination
        curPage={curPage}
        // pageSelection={setCurPage}
        totalCnt={totalPage}
        doSearch={doSearch}
      />
    </div>
  );
};
export default UnpaidStatusResultTable;

class resultTable {
  table_title = "";
  onClickDetail = null;
  onClickExemption = null;

  constructor(onClickDetail, onClickExemption) {
    this.onClickDetail = onClickDetail;
    this.onClickExemption = onClickExemption;
  }

  getMergedItem = (data, idx, itemLen, isCash) => {
    if (idx === 0) {
      return (
        <CustomTableContents key={data} rowSpan={itemLen} $isNumber={isCash}>
          {isCash ? data + " 원" : data}
        </CustomTableContents>
      );
    } else {
      return (
        <div
          style={{
            width: "0px",
            display: "none",
          }}
        ></div>
      );
    }
  };
  create_table = (data, idx) => {
    // console.log(data.purchase.unpaid.total_unpaid, data.member_info.member_name);
    // console.log(data.purchase.unpaid.unpaid_list.length);
    // console.log(data);

    if (isArrayEmpty(data.purchase.unpaid.unpaid_list)) {
      return;
    }
    let itemLen = data.purchase.unpaid.unpaid_list.length;
    const isCash = true;
    return data.purchase.unpaid.unpaid_list.map((unpaidRow, index) => {
      // console.log(unpaidRow, data, "언페이드로우");
      return (
        <CustomRow
          style={{}}
          key={`unpaid-row-${index}`}
          onClick={(e) => {
            if (e.target.nodeName === "DIV" || e.target.nodeName === "TD") {
              this.onClickDetail(data);
            }
          }}
          hover
        >
          {this.getMergedItem(data.branch_info.branch_name, index, itemLen)}
          {this.getMergedItem(data.member_info.member_no, index, itemLen)}
          {this.getMergedItem(data.member_info.member_name, index, itemLen)}
          {this.getMergedItem(data.search_param.base_info.phone, index, itemLen)}
          {this.getMergedItem(data.active_mbshp_info, index, itemLen)}
          {this.getMergedItem(
            data.purchase.unpaid.total_unpaid.toLocaleString(),
            index,
            itemLen,
            isCash,
          )}

          <CustomTableContents>{unpaidRow.purchase_code}</CustomTableContents>
          <CustomTableContents>{unpaidRow.payment_type}</CustomTableContents>
          <CustomTableContents $isNumber>
            {unpaidRow.unpaid_price.toLocaleString() + " 원"}
          </CustomTableContents>
          <CustomTableContents>{unpaidRow.reg_date}</CustomTableContents>
          <CustomTableContents>{unpaidRow.due_date}</CustomTableContents>
          <CustomTableContentsEND>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <IconButton
                type="cancel"
                onClick={() => this.onClickExemption(data, unpaidRow)}
                tooltip="미납금 제외 처리"
              />
            </div>
          </CustomTableContentsEND>
        </CustomRow>
      );
    });
  };

  columns_head = [
    { title: "지점", width: "8.3%" },
    { title: "회원번호", width: "7%" },
    { title: "이름", width: "8.3%" },
    { title: "연락처", width: "8.3%" },
    { title: "회원권 수강상태", width: "7%" },
    { title: "미납금 합계", width: "7.5%" },
    { title: "구매코드", width: "8%" },
    { title: "결제 구분", width: "7%" },
    { title: "미납금", width: "7.5%" },
    { title: "미납일(등록일)", width: "8.3%" },
    { title: "납부 예정일", width: "8.3%" },
    { title: "미납금 제외 처리", width: "7%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
