import { HttpInstance } from "lib/HttpLib";

export const getSearchMemberResultInfo = (param) => {
  return HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member/search_modal", {
    params: { search_param: param.search_param },
  });
};

export const getSearchStaffResultInfo = (param) => {
  return HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/staff/search_modal", {
    params: { search_param: param.search_param },
  });
};

export const getLoadSearchMemberParamInfo = (param) => {
  return HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member/search_member_view", {
    params: { search_param: param.search_param },
  });
};

const get_api = {
  getSearchMemberResultInfo,
  getSearchStaffResultInfo,
  getLoadSearchMemberParamInfo,
};

export default get_api;
