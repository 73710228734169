import { dateToStr } from "components/CommonLib/CommonLib";
import ColorLabel from "components/LabelInput/ColorLabel";
import { normalStyle, selectStyle, today_style_month } from "./calendarStyle";

const MonthSchedule = ({
  searchParam,
  gridItem,
  setSearchResult,
  onClickOpenModal,
  refreshCalendar,
  month,
}) => {
  // console.log(gridItem);
  //오늘날짜 찾기
  const getToday = (fullMonth, day) => {
    if (searchParam.search_month && day > 0) {
      //달력에서 날짜
      const selectedDay = fullMonth + (day < 10 ? "-0" : "-") + day;
      //오늘날짜
      const today = dateToStr(new Date());
      if (today === selectedDay) {
        return true;
      } else {
        return false;
      }
    } else if (!searchParam.search_month && day > 0) {
      //달력에서 날짜
      const selectedDay =
        dateToStr(new Date()).slice(0, 7) + (day > 0 && day < 10 ? "-0" : "-") + day;
      //오늘날짜
      const today = dateToStr(new Date());
      if (today === selectedDay) {
        return true;
      } else {
        return false;
      }
    } else {
      return;
    }
  };

  const onMouseEvt = (e, weekIdx, dayIdx) => {
    if (searchParam.branch_info.branch_name) {
      setSearchResult((cur) => {
        const obj = { ...cur };
        obj.grid_item[weekIdx][dayIdx].is_selected = true;
        return obj;
      });
    }
  };

  const onMouseLeaveEvt = (e, weekIdx, dayIdx) => {
    if (searchParam.branch_info.branch_name) {
      setSearchResult((cur) => {
        const obj = { ...cur };
        obj.grid_item[weekIdx][dayIdx].is_selected = false;
        return obj;
      });
    }
  };

  const selectPopup = (date, scheduleType, isEdit, scheduleId, data) => {
    if (scheduleType === "예약" || scheduleType === "수업완료") {
      popReservModal(date, scheduleType, isEdit, scheduleId, data);
    } else if (scheduleType === "개인업무" || scheduleType === "휴무") {
      popPersonalTaskModal(date, scheduleType, isEdit, scheduleId, data);
    } else if (scheduleType === "전달사항") {
      popNoticeModal(date, scheduleType, isEdit, scheduleId, data);
    }
  };

  const popReservModal = (date, scheduleType, isEdit, scheduleId, data) => {
    const param = {
      title:
        scheduleType === "예약" && isEdit
          ? "예약 등록"
          : scheduleType === "예약" && !isEdit
          ? "예약"
          : "수업완료",
      modalParam: {
        mode: "month",
        scheduleType,
        isEdit,
        searchParam,
        scheduleId,
        data,
        date: date > 0 && date < 10 ? month + "-0" + date : date >= 10 ? month + "-" + date : "",
        time: new Date().getHours() + ":00",
      },
    };
    onClickOpenModal("예약등록", param, refreshCalendar);
  };

  const popPersonalTaskModal = (date, scheduleType, isEdit, scheduleId, data) => {
    const param = {
      title:
        scheduleType === "개인업무" && !isEdit
          ? "개인업무"
          : scheduleType === "개인업무" && isEdit
          ? "개인업무 등록"
          : "휴무",

      modalParam: {
        mode: "month",
        scheduleType,
        isEdit,
        searchParam,
        scheduleId,
        data,
        time: new Date().getHours() + ":00",
        date: date > 0 && date < 10 ? month + "-0" + date : date >= 10 ? month + "-" + date : "",
      },
    };
    onClickOpenModal("개인업무등록", param, refreshCalendar);
  };

  const popNoticeModal = (date, scheduleType, isEdit, scheduleId, data) => {
    const param = {
      title: "전달사항",
      modalParam: {
        mode: "month",
        scheduleType,
        isEdit,
        searchParam,
        scheduleId,
        data,
        time: new Date().getHours() + ":00",
        date: date > 0 && date < 10 ? month + "-0" + date : date >= 10 ? month + "-" + date : "",
      },
    };
    onClickOpenModal("전달사항", param, refreshCalendar);
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(7,1fr)",
        gridAutoRows: "minmax(7rem,auto)",
        gridAutoColumns: "1fr",
        boxSizing: "border-box",
      }}
    >
      {gridItem.map((week, weekIdx) =>
        week.map((day, dayIdx) => (
          <div
            key={dayIdx}
            style={normalStyle}
            onMouseOver={(e) => {
              onMouseEvt(e, weekIdx, dayIdx);
            }}
            onMouseLeave={(e) => {
              onMouseLeaveEvt(e, weekIdx, dayIdx);
            }}
          >
            {getToday(searchParam.search_month, day.date) ? (
              <div
                style={{
                  display: "flex",
                  gap: "0.5rem",
                }}
              >
                <div style={today_style_month}>{day.date}</div>
                {day.is_selected ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "0.2rem",
                    }}
                  >
                    <ColorLabel
                      width="3.5rem"
                      height="1.2rem"
                      labelText="+ 예약"
                      bgColor="#003CCB"
                      fontColor="#fff"
                      fontSize="0.8rem"
                      cursor="pointer"
                      onClick={() => {
                        selectPopup(day.date, "예약", true);
                      }}
                    />
                    <ColorLabel
                      width="4.5rem"
                      height="1.2rem"
                      labelText="+ 개인업무"
                      bgColor="#003CCB"
                      fontColor="#fff"
                      fontSize="0.8rem"
                      cursor="pointer"
                      onClick={() => {
                        selectPopup(day.date, "개인업무", true);
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : day.date > 0 ? (
              <div
                style={{
                  display: "flex",
                  gap: "0.5rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    fontFamily: "AppleSDGothicNeo",
                  }}
                >
                  {day.date}
                </div>
                {day.is_selected ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "0.2rem",
                      cursor: "pointer",
                    }}
                  >
                    <ColorLabel
                      width="3.5rem"
                      height="1.2rem"
                      labelText="+ 예약"
                      bgColor="#003CCB"
                      fontColor="#fff"
                      fontSize="0.8rem"
                      cursor="pointer"
                      onClick={() => {
                        selectPopup(day.date, "예약", true);
                      }}
                    />
                    <ColorLabel
                      width="4.5rem"
                      height="1.2rem"
                      labelText="+ 개인업무"
                      bgColor="#003CCB"
                      fontColor="#fff"
                      fontSize="0.8rem"
                      cursor="pointer"
                      onClick={() => {
                        selectPopup(day.date, "개인업무", true);
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {day.schedule_list &&
              // 시간 기준으로 정렬
              day.schedule_list
                .sort((a, b) => {
                  const timeA = a.schedule_base.from_time;
                  const timeB = b.schedule_base.from_time;
                  return timeA.localeCompare(timeB);
                })
                .map((el) => (
                  <div
                    key={el.schedule_base.schedule_info.schedule_id}
                    style={selectStyle(
                      el.schedule_base.schedule_info.schedule_type,
                      el.schedule_base.schedule_info.schedule_sub_type,
                    )}
                    onClick={() => {
                      selectPopup(
                        day.date,
                        el.schedule_base.schedule_info.schedule_type,
                        false,
                        el.schedule_base.schedule_info.schedule_id,
                        el,
                      );
                    }}
                  >
                    {el.card_title}
                    {/* <br />
                  {`${el.schedule_base.charger_staff_info.staff_name}`} */}
                    {/* {el.schedule_base.schedule_info.schedule_type === "수업완료" ||
                  el.schedule_base.schedule_info.schedule_type === "예약"
                    ? ` / ${el.schedule_base.schedule_info.schedule_sub_type}`
                    : ""} */}
                  </div>
                ))}
          </div>
        )),
      )}
    </div>
  );
};

export default MonthSchedule;
