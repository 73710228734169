import IconButton from "components/Button/IconButton";
import { contentTypeJson, simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { loadMemberViewStore } from "../../Module/actions";

const FamilyInfo = ({ popConnectFamilyModal, selectedItem, memberId }) => {
  const storesDispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const disconnectFamily = async () => {
    try {
      const formData = new FormData();
      const info = {
        family_info: selectedItem.family_info,
        member_id: memberId,
        membership_io: {
          item_name: selectedItem.item_name,
          membership_id: selectedItem.membership_id,
        },
      };
      formData.append("new_info", JSON.stringify(info));

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_USER_SERVER + "/member/family/close",
        formData,
        contentTypeJson,
      );

      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        //화면에 반영해야
        storesDispatch(loadMemberViewStore(memberId));
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const onCancelEvt = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="가족 연결을 취소하시겠습니까?" />
          </>
        ),
        onBtnYes: () => {
          disconnectFamily();
          //모달 닫고 => selectedItem업데이트 해줘야함
        },
        onBtnNo: () => {},
      }),
    );
  };

  return (
    <div style={{ marginTop: "1rem" }}>
      {loading && <Loading />}

      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="가족 회원" />
      </div>

      {selectedItem.can_family ? (
        <div style={{ display: "flex" }}>
          <div>
            <TextField
              labelWidth={"7rem"}
              textBoxWidth={"9rem"}
              labelText="가족회원"
              defaultValue={
                selectedItem.connectedFamilyName ? selectedItem.connectedFamilyName : "없음"
              }
              disabled
            />
            <div style={{ height: "0.5rem" }}></div>
            <TextField
              labelWidth={"7rem"}
              textBoxWidth={"9rem"}
              labelText="가족 등록 상태"
              defaultValue={selectedItem.connectedFamilyName ? "등록" : "미등록"}
              disabled
            />
          </div>
          <div>
            {selectedItem.connectedFamilyName ? (
              <NormalBtn name="가족해제" theme="white" onClick={onCancelEvt} />
            ) : (
              <NormalBtn
                name="가족연결"
                theme="white"
                onClick={() => {
                  popConnectFamilyModal();
                }}
              />
            )}
          </div>
        </div>
      ) : (
        <>
          <Label labelText="가족회원 등록이 불가한 상품입니다." fontSize="1rem" marginTop="2rem" />
        </>
      )}
    </div>
  );
};
export default FamilyInfo;
