const initialStete = {
  stores: null,
};
export const auth_info = (state = initialStete, action) => {
  switch (action.type) {
    case "LOAD_AUTH_INFO_DATA": {
      return { ...state, stores: action.payload };
    }
    case "LOAD_AUTH_INFO_FAIL":
    default:
      return state;
  }
};

// export const state_auth_info = (state = initialStete, action) => {
//   return { ...state, stores: action.payload };
// };

export const state_auth_info = (state = initialStete, action) => {
  switch (action.type) {
    case "LOAD_AUTH_BASE": {
      return { ...state, stores: action.payload };
    }
    case "LOAD_AUTH_BASE_FAIL":
    default:
      return state;
  }
};

// const persistConfig = {
//   key: "root",
//   // localStorage에 저장합니다.
//   storage,
//   // auth, board, studio 3개의 reducer 중에 auth reducer만 localstorage에 저장합니다.
//   whitelist: ["auth_info", "state_auth_info"],
//   // blacklist -> 그것만 제외합니다
// };

// const authReducer = combineReducers({
//   auth_info,
//   state_auth_info,
// });

// export default persistReducer(persistConfig, authReducer);
