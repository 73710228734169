import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";

const DoorHistSearchResult = ({ onClickSearch, searchResult, curPage, totalPage }) => {
  const searchResultList = new searchResultTable();

  const doSearch = (page) => {
    onClickSearch(false, page);
  };

  return (
    <div>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
      />
      <CustomPagination curPage={curPage} doSearch={doSearch} totalCnt={totalPage} />
    </div>
  );
};
export default DoorHistSearchResult;

class searchResultTable {
  table_title = "";

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.access_date}</CustomTableContents>
        <CustomTableContents>{data.hq_info.hq_name}</CustomTableContents>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.door_info.door_name}</CustomTableContents>
        <CustomTableContents>{data.personal_info.staff_name}</CustomTableContents>
        <CustomTableContents>{data.personal_info.sabun}</CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "일시", width: "16%" },
    { title: "본부명", width: "16%" },
    { title: "지점명", width: "16%" },
    { title: "출입문명", width: "16%" },

    { title: "직원명", width: "16%" },
    { title: "사번", width: "16%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
