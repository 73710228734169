import border from "assets/icon/Border.svg";
import { contentTypeJson, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { DrawLockerGrid } from "../Common/LockerGridForEdit";
let lockerDisList = [];

//락커 구역추가 모달
const LockerSetModal = ({ modalParam, onModalDone }) => {
  const labelWidth = "3.5rem";
  const textBoxWidth = "10rem";
  const textBoxWidthShort = "7.43rem";
  const textBoxWidthLong = "20.6rem";

  const endNumRef = useRef(0);

  const [autoCalValue, setAutoCalValue] = useState({
    locker_total_num: 0,
    end_no: 0,
  });
  const zoneInitState = {
    floor_info: {
      floor_id: "",
      floor_name: "",
      zone_list: [],
    },
    name: "",
    gender: "공용",
    locker_total_num: 0,
    height: 0,
    width: 0,
    start_level: 0,
    start_no: 0,
    end_no: 0,
  };

  const storesDispatch = useDispatch();

  const [lockerZoneInfo, setLockerZoneInfo] = useState(zoneInitState);
  const [gridAllocateList, setGridAllocateList] = useState([]);
  const [gridRawList, setGridRawList] = useState([]);

  const [numDirectionCombo, setNumDirectionCombo] = useState(["오른쪽으로", "아래로"]);
  const [orderSelection, setOrderSelection] = useState({
    order_1: "좌측 상단부터",
    order_2: "오른쪽으로",
  });
  const [floorList, setFloorList] = useState([]);
  const [floorNameList, setFloorNameList] = useState([]);
  const [loading, setLoading] = useState(false);

  //층 GET
  const getBranchFloorInfo = async () => {
    try {
      setLoading(true);

      const res = await HttpInstance.get(
        process.env.REACT_APP_BRANCH_SERVER + "/locker/floor/list",
        {
          params: {
            branch_id: modalParam.selectedBranch.branch_id,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        const result = res.data.data;

        const floorArr = result.map((el) => {
          return {
            floor_id: el.floor_id,
            floor_name: el.floor_name,
          };
        });
        const floorNameArr = result.map((el) => el.floor_name);
        setFloorNameList(floorNameArr);
        setFloorList(floorArr);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  //초기랜더링
  useEffect(() => {
    if (modalParam) {
      getBranchFloorInfo();
    }
  }, [modalParam]);

  const subSelection = (order_1) => {
    let subSelect = "";

    if (order_1 === "좌측 상단부터") {
      subSelect = "오른쪽으로";
      setNumDirectionCombo(["오른쪽으로", "아래로"]);
    } else if (order_1 === "좌측 하단부터") {
      subSelect = "위로";
      setNumDirectionCombo(["위로", "오른쪽으로"]);
    } else if (order_1 === "우측 상단부터") {
      subSelect = "아래로";
      setNumDirectionCombo(["아래로", "왼쪽으로"]);
    } else {
      subSelect = "왼쪽으로";
      setNumDirectionCombo(["왼쪽으로", "위로"]);
    }

    return subSelect;
  };

  const validationCheck = (mode) => {
    if (!lockerZoneInfo.name) {
      simpleAlert(storesDispatch, "알림", "구역명을 입력해주세요.");
    } else if (!lockerZoneInfo.floor_info.floor_name) {
      simpleAlert(storesDispatch, "알림", "층을 선택해주세요.");
    } else if (!orderSelection.order_1 || !orderSelection.order_2) {
      simpleAlert(storesDispatch, "알림", "번호 시작 방향을 선택해주세요.");
    } else if (!lockerZoneInfo.height) {
      simpleAlert(storesDispatch, "알림", "단수를 입력해주세요.");
    } else if (!lockerZoneInfo.width) {
      simpleAlert(storesDispatch, "알림", "열을 입력해주세요.");
    } else if (!lockerZoneInfo.start_level) {
      simpleAlert(storesDispatch, "알림", "시작 단수를 입력해주세요.");
    } else if (!lockerZoneInfo.start_no) {
      simpleAlert(storesDispatch, "알림", "시작 번호를 입력해주세요.");
    } else {
      //유효성 검사 먼저!
      if (mode === "apply") {
        applyGridRow();
      } else {
        saveBtn();
      }
    }
  };

  //구역추가 MSG
  const saveBtn = async () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText={`구역을 추가 하시겠습니까?`} />
          </>
        ),
        onBtnYes: () => {
          addLockerZone();
        },
        onBtnNo: () => {},
      }),
    );
  };

  //구역추가 POST
  const addLockerZone = async () => {
    try {
      const formData = new FormData();
      const floorInfo = {
        floor_id: lockerZoneInfo.floor_info.floor_id,
        floor_name: lockerZoneInfo.floor_info.floor_name,
      };
      const baseInfo = {
        name: lockerZoneInfo.name,
        order_1: orderSelection.order_1,
        order_2: orderSelection.order_2,
        gender: lockerZoneInfo.gender,
        height: lockerZoneInfo.height,
        width: lockerZoneInfo.width,
        start_level: lockerZoneInfo.start_level,
        start_no: lockerZoneInfo.start_no,
        end_no: endNumRef.current,
      };

      formData.append("branch_info", JSON.stringify(modalParam.selectedBranch));
      formData.append("floor_info", JSON.stringify(floorInfo));
      formData.append("base_info", JSON.stringify(baseInfo));
      formData.append("grid_allocate_list", JSON.stringify(gridAllocateList));

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_BRANCH_SERVER + "/locker/zone/insert",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label labelText="저장되었습니다." />
              </>
            ),
            onBtnOk: () => {
              //모달 닫기 + 섹션1, 섹션2 콤보, 섹션3 갱신
              onModalDone();
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  //좌우 변경
  const rebuildOrder = (array, order_1, order_2) => {
    if (order_1 === "좌측 상단부터") {
      if (order_2 === "오른쪽으로") {
        //case 좌측 상단부터, 오른쪽으로
        ///nothing..
      } else {
        //case 좌측 상단부터, 아래로
        array = rotateMatrix(array, 3);
        array = yReverseRebuild(array);
      }
    } else if (order_1 === "좌측 하단부터") {
      if (order_2 === "오른쪽으로") {
        //case 좌측 하단부터, 오른쪽으로
        array = yReverseRebuild(array);
      } else {
        //case 좌측 하단부터, 위로
        array = rotateMatrix(array, 3);
      }
    } else if (order_1 === "우측 상단부터") {
      if (order_2 === "왼쪽으로") {
        //case 우측 상단부터, 왼쪽으로
        array = rotateMatrix(array, 2);
        array = yReverseRebuild(array);
      } else {
        //case 우측 상단부터, 아래로
        array = rotateMatrix(array, 1);
      }
    } else {
      if (order_2 === "왼쪽으로") {
        array = rotateMatrix(array, 2);
      } else {
        //case 우측 하단부터, 위쪽으로
        array = rotateMatrix(array, 1);
        array = yReverseRebuild(array);
      }
    }
    return array;
  };

  //gridRow
  const getRotateShape = () => {
    const order_1 = orderSelection.order_1;
    const order_2 = orderSelection.order_2;

    let gridShape = {
      width: lockerZoneInfo.width,
      height: lockerZoneInfo.height,
    };

    if (order_1 === "좌측 상단부터") {
      if (order_2 === "오른쪽으로") {
        //case 좌측 상단부터, 오른쪽으로
        ///nothing..
        return gridShape;
      } else {
        //case 좌측 상단부터, 아래로
        gridShape = {
          height: lockerZoneInfo.width,
          width: lockerZoneInfo.height,
        };
        return gridShape;
      }
    } else if (order_1 === "좌측 하단부터") {
      if (order_2 === "오른쪽으로") {
        //case 좌측 하단부터, 오른쪽으로
        return gridShape;
      } else {
        //case 좌측 하단부터, 위로
        gridShape = {
          height: lockerZoneInfo.width,
          width: lockerZoneInfo.height,
        };
        return gridShape;
      }
    } else if (order_1 === "우측 상단부터") {
      if (order_2 === "왼쪽으로") {
        //case 우측 상단부터, 왼쪽으로
        return gridShape;
      } else {
        //case 우측 상단부터, 아래로
        gridShape = {
          height: lockerZoneInfo.width,
          width: lockerZoneInfo.height,
        };
        return gridShape;
      }
    } else {
      if (order_2 === "왼쪽으로") {
        return gridShape;
      } else {
        //case 우측 하단부터, 위쪽으로
        gridShape = {
          height: lockerZoneInfo.width,
          width: lockerZoneInfo.height,
        };
        return gridShape;
      }
    }
  };

  const yReverseRebuild = (array) => {
    if (array.length === 0) {
      return array;
    }
    if (array[0].length === 0) {
      return array;
    }

    let allocate_list = [];
    // let wCnt = lockerZoneInfo.width;
    let wCnt = array[0].length;
    let hCnt = array.length;
    // let hCnt = lockerZoneInfo.height;

    let newY = 0;
    for (let y = hCnt - 1; y >= 0; y--) {
      let newX = 0;
      let allocate_rows = [];
      for (let x = 0; x < wCnt; x++) {
        array[y][x].level = newY + 1;
        allocate_rows.push(array[y][x]);
        newX++;
      }
      allocate_list.push(allocate_rows);
      newY++;
    }
    return allocate_list;
  };

  const numRebuild = (array, startY, startX, num) => {
    // let array = [...gridAllocateList];
    let wCnt = lockerZoneInfo.width;
    let hCnt = lockerZoneInfo.height;
    let gridShape = getRotateShape();
    wCnt = gridShape.width;
    hCnt = gridShape.height;

    for (let y = startY; y < hCnt; y++) {
      for (let x = startX; x < wCnt; x++) {
        array[y][x].num += num;
      }
      startX = 0;
    }
  };

  //gridRow 좌표ver
  const getMakeAreaData = (wCnt, hCnt, select1, select2, startN) => {
    let allocate_list = [];
    let a = 0;
    let gridShape = getRotateShape();
    wCnt = gridShape.width;
    hCnt = gridShape.height;

    for (let y = 0; y < hCnt; y++) {
      let allocate_rows = [];

      for (let x = 0; x < wCnt; x++) {
        let c = startN + x + y * wCnt;

        allocate_rows.push({
          num: c,
          level: y + 1,
          active: 0,
          idxX: x,
          idxY: y,
        });
      }
      allocate_list.push(allocate_rows);
    }

    return allocate_list;
  };

  //gridRow 아이템 눌렀을때
  const setActiveState = (targetItem) => {
    let array = [...gridRawList];

    if (array[targetItem.idxY][targetItem.idxX].active === 1) {
      array[targetItem.idxY][targetItem.idxX].active = 0;
      const index = lockerDisList.findIndex(
        (el) => el.x === targetItem.idxX && el.y === targetItem.idxY,
      );
      if (index !== -1) {
        lockerDisList.splice(index, 1);
      }
      numRebuild(array, targetItem.idxY, targetItem.idxX, 1);
    } else {
      array[targetItem.idxY][targetItem.idxX].active = 1;
      lockerDisList.push({
        y: targetItem.idxY,
        x: targetItem.idxX,
      });
      numRebuild(array, targetItem.idxY, targetItem.idxX, -1);
    }
    setGridRawList(array);

    const order_1 = orderSelection.order_1;
    const order_2 = orderSelection.order_2;
    let rebuildArr = rebuildOrder(array, order_1, order_2);
    setGridAllocateList(rebuildArr);

    const endNoArr = rebuildArr.map((el) => {
      const nums = el.map((ele) => ele.num);
      const rowMax = Math.max.apply(null, nums);
      return rowMax;
    });
    setAutoCalValue((cur) => {
      return {
        ...cur,
        end_no: Math.max(...endNoArr),
        locker_total_num: Math.max(...endNoArr) - lockerZoneInfo.start_no + 1,
      };
    });
  };

  const rotateMatrix = function (matrix, rotateNum = 1) {
    let N = matrix.length;
    let M = matrix[0] && matrix[0].length;

    rotateNum = rotateNum % 4;
    if (rotateNum === 0) {
      return matrix;
    }

    let result = [];
    let rotateCount = rotateNum % 2 === 1 ? [M, N] : [N, M];

    for (let row = 0; row < rotateCount[0]; row++) {
      result[row] = [];
      for (let col = 0; col < rotateCount[1]; col++) {
        if (rotateNum === 1) {
          let cData = matrix[N - col - 1][row];
          cData.level = row + 1;
          result[row][col] = cData;
        } else if (rotateNum === 2) {
          let cData = matrix[N - row - 1][M - col - 1];
          cData.level = row + 1;
          result[row][col] = cData;
        } else {
          let cData = matrix[col][M - row - 1];
          cData.level = row + 1;
          result[row][col] = cData;
        }
      }
    }
    return result;
  };

  //초기화버튼 함수
  const initData = () => {
    setLockerZoneInfo(zoneInitState);
    setOrderSelection({
      order_1: "",
      order_2: "",
    });
    setAutoCalValue({ locker_total_num: 0, end_no: 0 });
    setGridAllocateList([]);
  };

  //락커번호 중복 검사
  const inspectDoubleNum = async (array) => {
    try {
      const formData = new FormData();
      formData.append("branch_info", JSON.stringify(modalParam.selectedBranch));
      formData.append("grid_allocate_list", JSON.stringify(array));

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_BRANCH_SERVER + "/locker/zone/insert/check",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: (
              <div
                style={{
                  width: "10rem",
                  fontFamily: "AppleSDGothicNeo",
                  // height: "10rem",
                  overflowY: "auto",
                  margin: "0 auto",
                  padding: "0 0.5rem",
                  textAlign: "center",
                }}
              >
                <Label
                  labelText={res.data.msg.split(".")[0]}
                  fontWeight="bold"
                  fontSize="0.9rem"
                  marginBottom="0.3rem"
                />
                {/* <div style={{border:'1px solid red', display:'flex'}}> </div> */}
                {res.data.msg.split(".")[1]}
              </div>
            ),

            onBtnOk: () => {},
          }),
        );
      } else {
        //4. 통과하면 그림 보여주기
        setGridAllocateList(array);
        //5. 끝번호와 락커 총 개수 보여주기
        const x = lockerZoneInfo.width;
        const y = lockerZoneInfo.height;
        const total = Number(x) * Number(y);
        const startN = lockerZoneInfo.start_no;
        const endN = startN + total - 1;
        setAutoCalValue((cur) => {
          return { ...cur, locker_total_num: total, end_no: endN };
        });
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  //적용버튼 함수
  const applyGridRow = () => {
    //1. 입력값을 바탕으로 grid를 좌표기준으로 그림(초안)
    const result = getMakeAreaData(
      lockerZoneInfo.width,
      lockerZoneInfo.height,
      orderSelection.order_1,
      orderSelection.order_2,
      lockerZoneInfo.start_no,
    );
    setGridRawList(result);

    //2. 번호방향에 맞게 다시 세팅
    let array = rebuildOrder(result, orderSelection.order_1, orderSelection.order_2);

    //3. 시작번호 중복검사
    inspectDoubleNum(array);
  };

  useEffect(() => {
    return () => {
      setLockerZoneInfo(zoneInitState);
      setGridAllocateList([]);
      setGridRawList([]);
    };
  }, []);

  return (
    <div>
      {loading && <Loading />}

      <div
        style={{
          width: "58rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            alt="border"
            src={border}
            style={{
              width: "1.2rem",
              height: "1.2rem",
            }}
          />

          <SubTitle titleText="기본 설정" />
        </div>
        <div
          style={{
            marginBottom: "1rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", width: "100%" }}>
            <TextField
              labelMarginLeft="1rem"
              labelWidth={labelWidth}
              textBoxWidth={textBoxWidthLong}
              // marginRight={"2rem"}
              labelText="구역명"
              defaultValue={lockerZoneInfo.name}
              onChangeCallback={(e) => {
                setLockerZoneInfo((cur) => {
                  return { ...cur, name: e };
                });
              }}
            />
            <ComboBox
              labelWidth={labelWidth}
              comboItemWidth={textBoxWidth}
              labelMarginLeft="1rem"
              // marginRight={"2rem"}
              labelText="층수"
              defaultValue={lockerZoneInfo.floor_info.floor_name}
              data={floorNameList}
              onChangeCallback={(e) => {
                const floor = floorList.find((el) => el.floor_name === e.target.value);
                setLockerZoneInfo((cur) => {
                  return { ...cur, floor_info: floor };
                });
              }}
            />
            <ComboBox
              labelWidth={labelWidth}
              comboItemWidth={textBoxWidth}
              labelMarginLeft="1rem"
              labelText="성별"
              defaultValue={lockerZoneInfo.gender}
              data={["공용", "남", "여"]}
              onChangeCallback={(e) => {
                setLockerZoneInfo((cur) => {
                  return { ...cur, gender: e.target.value };
                });
              }}
            />
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            alt="border"
            src={border}
            style={{
              width: "1.2rem",
              height: "1.2rem",
            }}
          />

          <SubTitle titleText="상세 설정" />
        </div>
        <div
          style={{
            marginBottom: "1.625rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginBottom: "1rem",
              display: "flex",
              width: "100%",
            }}
          >
            <TextField
              labelMarginLeft="1rem"
              labelWidth={labelWidth}
              textBoxWidth={textBoxWidthShort}
              labelText="단수 ↑"
              defaultValue={lockerZoneInfo.height}
              onChangeCallback={(e) => {
                setLockerZoneInfo((cur) => {
                  return { ...cur, height: Number(e) };
                });
              }}
            />

            <TextField
              labelMarginLeft="1rem"
              labelWidth={labelWidth}
              textBoxWidth={textBoxWidthShort}
              defaultValue={lockerZoneInfo.width}
              labelText="열수 →"
              onChangeCallback={(e) => {
                setLockerZoneInfo((cur) => {
                  return { ...cur, width: Number(e) };
                });
              }}
            />
            <TextField
              labelMarginLeft="1rem"
              labelWidth={labelWidth}
              textBoxWidth={textBoxWidth}
              labelText="시작 단"
              defaultValue={lockerZoneInfo.start_level}
              onChangeCallback={(e) => {
                setLockerZoneInfo((cur) => {
                  return { ...cur, start_level: Number(e) };
                });
              }}
            />
          </div>
          <div
            style={{
              marginBottom: "2.5rem",
              display: "flex",
              width: "100%",
            }}
          >
            <ComboBox
              labelMarginLeft="1rem"
              labelWidth={labelWidth}
              comboItemWidth={textBoxWidth}
              marginRight={"0rem"}
              labelText="번호 배치"
              defaultValue={orderSelection.order_1}
              data={["좌측 상단부터", "좌측 하단부터", "우측 상단부터", "우측 하단부터"]}
              onChangeCallback={(e) => {
                setOrderSelection((cur) => {
                  subSelection(e.target.value);
                  return { ...cur, order_1: e.target.value };
                });
              }}
            />
            <ComboBox
              comboItemWidth={textBoxWidth}
              labelWidth="0rem"
              labelText=""
              defaultValue={orderSelection.order_2}
              data={numDirectionCombo}
              onChangeCallback={(e) => {
                setOrderSelection((cur) => {
                  return { ...cur, order_2: e.target.value };
                });
              }}
            />
            <TextField
              labelMarginLeft="1rem"
              labelWidth={labelWidth}
              textBoxWidth={textBoxWidth}
              labelText="시작번호"
              defaultValue={lockerZoneInfo.start_no}
              onChangeCallback={(e) => {
                setLockerZoneInfo((cur) => {
                  const obj = { ...cur, start_no: Number(e) };
                  return obj;
                });
              }}
            />

            <TextField
              labelMarginLeft="1rem"
              labelWidth={labelWidth}
              textBoxWidth={textBoxWidth}
              labelText="끝번호"
              defaultValue={autoCalValue.end_no}
              disabled
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center", gap: "0.3rem" }}>
            <NormalBtn
              name="초기화"
              theme="white"
              onClick={() => {
                initData();
              }}
            />

            <NormalBtn
              name="적용"
              theme="blue"
              onClick={() => {
                validationCheck("apply");
              }}
            />
          </div>
        </div>
        <div>
          <DrawLockerGrid
            currentData={{
              order_selection_1: lockerZoneInfo.order_1,
              order_selection_2: lockerZoneInfo.order_2,
              allocate_list: gridAllocateList,
            }}
            gridWidth={"67rem"}
            startLevel={lockerZoneInfo.start_level}
            mode={"edit"}
            onItemClick={setActiveState}
            areaHeight={lockerZoneInfo.height}
          />
        </div>
        {gridAllocateList.length === 0 ? (
          ""
        ) : (
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "1rem" }}>
            <TextField
              labelWidth={"6rem"}
              textBoxWidth={textBoxWidth}
              // marginRight={"4.6rem"}
              labelText="락커 총 개수"
              disabled
              defaultValue={autoCalValue.locker_total_num}
            />
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // marginTop: "1.844rem",
        }}
      >
        {gridAllocateList.length === 0 ? (
          ""
        ) : (
          <NormalBtn
            name="저장"
            onClick={() => {
              validationCheck("save");
            }}
          />
        )}
      </div>
    </div>
  );
};

export default LockerSetModal;
