import { contentTypeJson, dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loadPtSalaryViewStore, loadSalaryInfoViewStore } from "../Module/actions";

const LoadCommissionModal = ({ modalParam }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCommissionList = async () => {
    try {
      setLoading(true);
      const res = await HttpInstance.get(
        process.env.REACT_APP_SALARY_SERVER + "/salary/class/commission/list",
        {
          params: {
            staff_id: modalParam.staffId,
            branch_id: modalParam.branchId,
            work_info_id: modalParam.lastWorkInfoId,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setList(res.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCommissionList();
  }, [modalParam]);

  const storesDispatch = useDispatch();
  // const popSettingBasicCommissionModal = () => {
  //   const param = {
  //     title: "당월 커미션 수정",
  //     modalParam: {
  //       onClickOpenModal: modalParam.onClickOpenModal,
  //     },
  //   };
  //   modalParam.onClickOpenModal("당월커미션수정", param);
  // };

  const onClickEvt = (name, data) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText={`${name}으로 설정하시겠습니까?`} />
          </>
        ),
        onBtnYes: () => {
          editCommission(data);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const editCommission = async (data) => {
    try {
      const formData = new FormData();
      formData.append("new_info", JSON.stringify(data));
      setLoading(true);

      let getQuery = "?salary_report_id=" + modalParam.salaryReportId;
      getQuery += "&staff_id=" + modalParam.staffId;
      getQuery += "&staff_salary_id=" + modalParam.staffSalaryId;
      const res = await HttpInstance.post(
        process.env.REACT_APP_SALARY_SERVER + "/salary/class/commission/update" + getQuery,
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(loadSalaryInfoViewStore(modalParam.salaryReportId, true)); //메인 갱신
        storesDispatch(loadPtSalaryViewStore(modalParam.salaryReportId, true)); //PT탭 갱신
        // ptSalaryDetail(pId); //pt디테일 갱신 ..?
        storesDispatch(globalModalOff()); //모달닫기
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const settingData = new getSettingTable(onClickEvt);

  return (
    <div style={{ width: "30rem" }}>
      {loading && <Loading />}

      <CustomTable
        columns_head={settingData.columns_head}
        table_title={settingData.table_title}
        rest_call={settingData.get_data_from_rest}
        row_render={settingData.create_table}
        rest_data={list}
        maxHeight={"15rem"}
      />
    </div>
  );
};
export default LoadCommissionModal;

class getSettingTable {
  table_title = "";
  onClickEvt = null;

  constructor(onClickEvt) {
    this.onClickEvt = onClickEvt;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow
        key={idx}
        onClick={() => {
          this.onClickEvt(data.preset_name, data);
        }}
        hover
      >
        <CustomTableContents>{data.reg_info.reg_name}</CustomTableContents>
        <CustomTableContentsEND align="center">{data.preset_name}</CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "담당자", width: "40%" },
    { title: "커미션명", width: "60%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
