import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { useLocation, useParams } from "react-router";
import StaffInfoWrapper from "./StaffInfoWrapper";

const StaffInfo = () => {
  const { state } = useLocation();
  let titleText = "";
  if (state && state.staff_id === "") {
    titleText = "직원등록";
  } else {
    titleText = "직원정보";
  }
  const params = useParams();

  return (
    <div>
      <PageFrame
        menuActive="락커/셋팅"
        parentMenu={{ 락커: true }}
        children={
          <div>
            <PageTitle titleText={titleText} />
            <StaffInfoWrapper getState={state} staffId={params.targetId} />
          </div>
        }
      />
    </div>
  );
};
export default StaffInfo;
