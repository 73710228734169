import Loading from "components/Loading/Loading/Loading";
import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { useSearchMemberFirstSearchParam } from "hooks/apis/member/useSearchMember";
import SearchMemberContent from "./SearchMemberContent";

const SearchMember = () => {
  const { data, isLoading: isBaseSearchLoading } = useSearchMemberFirstSearchParam();

  return (
    <div>
      <PageFrame
        children={
          <div>
            <PageTitle titleText="회원 조회" />
            {isBaseSearchLoading ? (
              <Loading />
            ) : (
              <SearchMemberContent
                baseSearchState={data.baseSearchParam}
                memberSearchState={data.memberSearchParam}
                mbshpSearchState={data.mbshpSearchParam}
                sideSearchState={data.sideSearchParam}
                ptSearchState={data.ptSearchParam}
              />
            )}
          </div>
        }
      />
    </div>
  );
};
export default SearchMember;
