import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import ApprovalInfo from "./ApprovalInfo";

const ApprovalInfoService = () => {
  const { state } = useLocation();

  const [mode, setMode] = useState("view");
  useEffect(() => {
    setMode(state.mode);
  }, [state]);

  return (
    <div>
      <PageFrame
        children={
          <div>
            <PageTitle titleText="전자결재" />
            <ApprovalInfo
              mode={mode}
              setMode={setMode}
              docType={state.docType}
              approvalId={state.approval_id}
            />
          </div>
        }
      />
    </div>
  );
};
export default ApprovalInfoService;
