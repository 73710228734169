import { compareTimeBigger, SubLine } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import NumberTextField from "components/LabelInput/NumberTextField";
import TimePicker from "components/LabelInput/TimePicker";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const ExpiredSetting = ({ branchPolicy, restData, mbshpOption, setPeriod, isEditMode }) => {
  const storesDispatch = useDispatch();
  const textLabelWidth = "6rem";
  const textBoxWidth = "10rem";
  const labelMarginLeft = "2.625rem";

  const [totalMonths, setTotalMonths] = useState(0);
  const [totalDays, setTotalDays] = useState(0);

  useEffect(() => {
    setTotalMonths(restData.period_month + restData.service_month);
    setTotalDays(restData.service_day);
  }, [restData]);

  return (
    <div>
      <div style={{ display: "flex", marginTop: "1.25rem", marginBottom: "0.625rem" }}>
        <Label textAlign={"left"} labelText={"유효기간"} />
      </div>
      <div style={{ marginLeft: "2rem" }}>
        {mbshpOption === "일일권" ? (
          <div
            style={{
              display: "flex",
            }}
          >
            <NumberTextField
              required
              isNumber
              labelWidth={textLabelWidth}
              textBoxWidth={textBoxWidth}
              labelText="· 유효 기간"
              endAdornment="시간"
              maxValue={24}
              defaultValue={restData.total_period_hour}
              onChangeCallback={(e) => {
                restData.total_period_hour = e;
              }}
              disabled={isEditMode}
            />

            <NumberTextField
              required
              isNumber
              labelWidth={textLabelWidth}
              textBoxWidth={textBoxWidth}
              labelMarginLeft={labelMarginLeft}
              labelText="· 사용 대기시간"
              endAdornment="시간"
              maxValue={24}
              defaultValue={restData.oneday_wait_hour}
              onChangeCallback={(e) => {
                restData.oneday_wait_hour = e;
              }}
              disabled={isEditMode}
            />
          </div>
        ) : (
          <div>
            <div style={{ display: "flex" }}>
              <NumberTextField
                isNumber
                required
                labelWidth={textLabelWidth}
                textBoxWidth={textBoxWidth}
                labelText="· 기본기간"
                endAdornment="개월"
                minValue={0}
                maxValue={120}
                defaultValue={restData.period_month}
                onChangeCallback={(e) => {
                  restData.period_month = e;
                  setTotalMonths(restData.period_month + restData.service_month);
                  setPeriod(e);
                }}
                disabled={isEditMode}
              />

              <Label
                color="#c55d5d"
                fontWeight="bold"
                fontSize="0.85rem"
                labelText={"※ 총 기간 : "}
                marginLeft="11rem"
              />
              <Label color="#c55d5d" fontWeight="bold" fontSize="0.85rem" labelText={totalMonths} />
              <Label color="#c55d5d" fontWeight="bold" fontSize="0.85rem" labelText={"개월 "} />
              <Label color="#c55d5d" fontWeight="bold" fontSize="0.85rem" labelText={totalDays} />
              <Label color="#c55d5d" fontWeight="bold" fontSize="0.85rem" labelText={"일"} />
            </div>

            <div style={{ display: "flex", marginTop: "0.5rem" }}>
              <NumberTextField
                isNumber
                labelWidth={textLabelWidth}
                textBoxWidth={textBoxWidth}
                labelText="· 서비스 기간"
                endAdornment="개월"
                minValue={0}
                maxValue={120}
                defaultValue={restData.service_month}
                onChangeCallback={(e) => {
                  restData.service_month = e;
                  setTotalMonths(restData.period_month + restData.service_month);
                }}
                disabled={isEditMode}
              />

              <NumberTextField
                isNumber
                textBoxWidth={textBoxWidth}
                endAdornment="일"
                minValue={0}
                maxValue={30}
                defaultValue={restData.service_day}
                onChangeCallback={(e) => {
                  restData.service_day = e;
                  setTotalDays(restData.service_day);
                }}
                disabled={isEditMode}
              />
            </div>
            {mbshpOption === "패스권" ? (
              <div style={{ display: "flex", marginTop: "0.5rem" }}>
                <NumberTextField
                  isNumber
                  labelWidth={textLabelWidth}
                  textBoxWidth={textBoxWidth}
                  labelText="· 출입 횟수"
                  endAdornment="회"
                  defaultValue={restData.pass_cnt}
                  onChangeCallback={(e) => {
                    restData.pass_cnt = e;
                  }}
                  disabled={isEditMode}
                />
              </div>
            ) : (
              ""
            )}

            {mbshpOption === "시간권" ? (
              <div style={{ display: "flex", marginTop: "0.5rem" }}>
                <TimePicker
                  id="picker_from_time"
                  labelWidth={textLabelWidth}
                  labelText="· 출입 가능 시간"
                  textBoxWidth="8.5rem"
                  defaultValue={restData.pass_from_time}
                  onBlur={(e) => {
                    if (restData.pass_end_time && compareTimeBigger(e, restData.pass_end_time)) {
                      storesDispatch(
                        globalAlertOn({
                          show: true,
                          titleText: "알림",
                          bodyText: (
                            <>
                              <Label labelText="출입 종료 시간이 시작 시간보다 빠릅니다." />
                            </>
                          ),
                          // width: "50rem",
                          onBtnOk: () => {},
                        }),
                      );
                      document.getElementById("picker_from_time").focus();
                    } else {
                      restData.pass_from_time = e;
                    }
                  }}
                  disabled={isEditMode}
                />
                <TimePicker
                  id="picker_end_time"
                  labelText="~"
                  textBoxWidth="8.5rem"
                  defaultValue={restData.pass_end_time}
                  onBlur={(e) => {
                    if (restData.pass_from_time && compareTimeBigger(restData.pass_from_time, e)) {
                      storesDispatch(
                        globalAlertOn({
                          show: true,
                          titleText: "알림",
                          bodyText: (
                            <>
                              <Label labelText="출입 종료 시간이 시작 시간보다 빠릅니다." />
                            </>
                          ),
                          // width: "50rem",
                          onBtnOk: () => {},
                        }),
                      );
                      document.getElementById("picker_end_time").focus();
                    } else {
                      restData.pass_end_time = e;
                    }
                  }}
                  disabled={isEditMode}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
      <SubLine />
    </div>
  );
};
export default ExpiredSetting;
