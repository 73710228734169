import { generateObjectZeroId } from "components/CommonLib/CommonLib";
import { create } from "zustand";

// 검색 조건 베이스
const baseSearchParams = {
  hq_info: {
    hq_id: generateObjectZeroId(),
    hq_name: "",
  },
  branch_info: {
    branch_id: generateObjectZeroId(),
    branch_name: "",
  },
  reg_date_from: "",
  reg_date_to: "",
  sales_staff_name: "",
  sales_status: "",
  class_type: "",
  class_date_from: "",
  class_date_to: "",
  is_salaried_done: "",
  combo_selector: { branch_list: [[]], hq_list: [], target_list: [] },
};

export const useSalesRecordStore = create((set, get) => ({
  returnStoreByType: (type) => {
    const {
      setSalesRecordLoading,
      hqBranchBaseData,
      setHqBranchInfo,
      // 회원권 실적
      mbshpSalesSearchParams,
      setMbshpSalesSearchParams,
      resetMbshpSalesSearchParams,
      mbshpSalesHqIdx,
      setMbshpSalesHqIdx,
      mbshpSalesPage,
      setMbshpSalesPage,
      // PT 실적
      ptSalesSearchParams,
      setPtSalesSearchParams,
      resetPtSalesSearchParams,
      ptSalesHqIdx,
      setPtSalesHqIdx,
      ptSalesPage,
      setPtSalesPage,
      // 수업 실적
      classSalesSearchParams,
      setClassSalesSearchParams,
      resetClassSalesSearchParams,
      classSalesHqIdx,
      setClassSalesHqIdx,
      classSalesPage,
      setClassSalesPage,
    } = get();

    const mbshpSalesLib = {
      hqBranchBaseData,
      setHqBranchInfo,
      setLoading: setSalesRecordLoading,
      searchParams: mbshpSalesSearchParams,
      setSearchParams: setMbshpSalesSearchParams,
      resetSearchParams: resetMbshpSalesSearchParams,
      hqIdx: mbshpSalesHqIdx,
      setHqIdx: setMbshpSalesHqIdx,
      page: mbshpSalesPage,
      setPage: setMbshpSalesPage,
    };
    const ptSalesLib = {
      hqBranchBaseData,
      setHqBranchInfo,
      setLoading: setSalesRecordLoading,
      searchParams: ptSalesSearchParams,
      setSearchParams: setPtSalesSearchParams,
      resetSearchParams: resetPtSalesSearchParams,
      hqIdx: ptSalesHqIdx,
      setHqIdx: setPtSalesHqIdx,
      page: ptSalesPage,
      setPage: setPtSalesPage,
    };
    const classSalesLib = {
      hqBranchBaseData,
      setHqBranchInfo,
      setLoading: setSalesRecordLoading,
      searchParams: classSalesSearchParams,
      setSearchParams: setClassSalesSearchParams,
      resetSearchParams: resetClassSalesSearchParams,
      hqIdx: classSalesHqIdx,
      setHqIdx: setClassSalesHqIdx,
      page: classSalesPage,
      setPage: setClassSalesPage,
    };

    switch (type) {
      case "mbshp":
        return mbshpSalesLib;
      case "pt":
        return ptSalesLib;
      case "class":
        return classSalesLib;
      default:
        break;
    }
  },

  // ========== State ===========
  // 본부, 지점 베이스 State
  hqBranchBaseData: {
    branch_list: [[""]],
    hq_list: [""],
    target_list: [
      {
        branch_list: [
          {
            branch_id: "",
            branch_name: "",
          },
        ],
        hq_info: {
          hq_id: "",
          hq_name: "",
        },
      },
    ],
  },

  // ComboBox 첫번째 본부 및 지점 정보 State (조건 초기화 시 선택 위함)
  firstHqBranchInfoData: {
    hq_info: {
      hq_id: generateObjectZeroId(),
      hq_name: "",
    },
    branch_info: {
      branch_id: generateObjectZeroId(),
      branch_name: "",
    },
  },

  // 회원권, PT, 수업 실적 Loading State
  salesRecordLoading: false,

  // 회원권 실적 검색 State
  mbshpSalesSearchParams: {
    ...baseSearchParams,
  },
  // PT 실적 검색 State
  ptSalesSearchParams: {
    ...baseSearchParams,
  },
  // 수업 실적 검색 State
  classSalesSearchParams: {
    ...baseSearchParams,
  },

  // 본부 선택 인덱스
  mbshpSalesHqIdx: 0,
  ptSalesHqIdx: 0,
  classSalesHqIdx: 0,

  // 선택 페이지
  mbshpSalesPage: 1,
  ptSalesPage: 1,
  classSalesPage: 1,

  // ========== Action ==========
  // Loading state 값 변경
  setSalesRecordLoading: (bool) => {
    set({ salesRecordLoading: bool });
  },

  // 회원권 실적 검색 조건 변경
  setMbshpSalesSearchParams: (key, value) => {
    set((state) => ({
      mbshpSalesSearchParams: {
        ...state.mbshpSalesSearchParams,
        [key]: value,
      },
    }));
  },
  // PT 실적 검색 조건 변경
  setPtSalesSearchParams: (key, value) => {
    set((state) => ({
      ptSalesSearchParams: {
        ...state.ptSalesSearchParams,
        [key]: value,
      },
    }));
  },
  // 수업 실적 검색 조건 변경
  setClassSalesSearchParams: (key, value) => {
    set((state) => ({
      classSalesSearchParams: {
        ...state.classSalesSearchParams,
        [key]: value,
      },
    }));
  },

  // Hq Index 값 변경
  setMbshpSalesHqIdx: (value) => {
    set({ mbshpSalesHqIdx: value });
  },
  setPtSalesHqIdx: (value) => {
    set({ ptSalesHqIdx: value });
  },
  setClassSalesHqIdx: (value) => {
    set({ classSalesHqIdx: value });
  },

  // Page 값 변경
  setMbshpSalesPage: (value) => {
    set({ mbshpSalesPage: value });
  },
  setPtSalesPage: (value) => {
    set({ ptSalesPage: value });
  },
  setClassSalesPage: (value) => {
    set({ classSalesPage: value });
  },

  // ComboBox 첫번째 본부, 지점 정보 변경
  setHqBranchInfo: (key, value) => {
    set((state) => ({
      firstHqBranchInfoData: {
        ...state.firstHqBranchInfoData,
        [key]: value,
      },
    }));
  },

  // 검색 조건 리셋
  resetMbshpSalesSearchParams: () => {
    set((state) => ({
      mbshpSalesSearchParams: {
        ...baseSearchParams,
        ...state.firstHqBranchInfoData,
      },
    }));
    set({ mbshpSalesHqIdx: 0 });
  },

  resetPtSalesSearchParams: () => {
    set((state) => ({
      ptSalesSearchParams: {
        ...baseSearchParams,
        ...state.firstHqBranchInfoData,
      },
    }));
    set({ ptSalesHqIdx: 0 });
  },
  resetClassSalesSearchParams: () => {
    set((state) => ({
      classSalesSearchParams: {
        ...baseSearchParams,
        ...state.firstHqBranchInfoData,
      },
    }));
    set({ classSalesHqIdx: 0 });
  },

  // 모든 검색 조건 리셋
  resetAllSearchParams: () => {
    const { resetMbshpSalesSearchParams, resetPtSalesSearchParams, resetClassSalesSearchParams } =
      get();

    resetMbshpSalesSearchParams();
    resetPtSalesSearchParams();
    resetClassSalesSearchParams();
  },
}));
