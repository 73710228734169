import IconButton from "components/Button/IconButton";
import CheckBox from "components/LabelInput/CheckBox";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import { useEffect, useState } from "react";

const SearchDetailMbshpContents = ({ searchParam }) => {
  const textBoxWidth = "20rem";
  const textBoxWidthShort = "9.5rem";
  const checkBoxMarginRight = "1.5rem";
  const [statusValid, setStatusValid] = useState({
    valid: false,
    ing: false,
    postpone: false,
    wait: false,
  });

  useEffect(() => {
    if (searchParam.mbshp_param && searchParam.mbshp_param.mbshp_status_selector) {
      setStatusValid({
        valid: searchParam.mbshp_param.mbshp_status_selector.is_valid,
        ing: searchParam.mbshp_param.mbshp_status_selector.is_ing,
        postpone: searchParam.mbshp_param.mbshp_status_selector.is_postpone,
        wait: searchParam.mbshp_param.mbshp_status_selector.is_wait,
      });
    }
  }, [searchParam]);

  const onUpdateStatus = (type, val) => {
    if (type === "valid") {
      setStatusValid({
        valid: val,
        ing: val,
        postpone: val,
        wait: val,
      });
    } else {
      if (type === "ing") statusValid.ing = val;
      if (type === "postpone") statusValid.postpone = val;
      if (type === "wait") statusValid.wait = val;

      if (statusValid.ing && statusValid.postpone && statusValid.wait) {
        setStatusValid({
          ...statusValid,
          valid: true,
          [type]: val,
        });
      } else {
        setStatusValid({
          ...statusValid,
          valid: false,
          [type]: val,
        });
      }
    }
  };

  const LabelTop = ({ text }) => {
    return <Label labelText={text} color="#777" justifyContent="flex-start" marginTop="0.5rem" />;
  };

  if (searchParam.pending) {
    return <div style={{ height: "50rem" }}></div>;
  } else {
    return (
      <>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <Label labelText="회원권" marginLeft="0.5rem" fontWeight="bold" />
        </div>
        <div>
          <LabelTop text={"회원권 종류"} />
          <ComboBox
            labelMarginRight="0rem"
            comboItemWidth={textBoxWidth}
            data={["전체", "완납형"]}
            defaultValue={searchParam.mbshp_param.mbshp_type || "전체"}
            onChangeCallback={(e) => {
              searchParam.mbshp_param.mbshp_type = e.target.value === "전체" ? "" : e.target.value;
            }}
          />

          <LabelTop text={"회원권 옵션"} />
          <ComboBox
            labelMarginRight="0rem"
            comboItemWidth={textBoxWidth}
            data={["전체", "일반권", "일일권"]}
            defaultValue={searchParam.mbshp_param.mbshp_option || "전체"}
            onChangeCallback={(e) => {
              searchParam.mbshp_param.mbshp_option =
                e.target.value === "전체" ? "" : e.target.value;
            }}
          />

          <LabelTop text={"회원권 상태"} />
          <div style={{ display: "flex", marginLeft: "1.25rem" }}>
            <CheckBox
              isTable
              labelMarginRight={"1.5rem"}
              labelText="유효"
              defaultValue={statusValid.valid}
              onChangeCallback={(e) => {
                searchParam.mbshp_param.mbshp_status_selector.is_valid = e.checked;
                onUpdateStatus("valid", e.checked);

                searchParam.mbshp_param.mbshp_status_selector.is_ing = e.checked;
                searchParam.mbshp_param.mbshp_status_selector.is_postpone = e.checked;
                searchParam.mbshp_param.mbshp_status_selector.is_wait = e.checked;
              }}
            />
            <Label labelText="(" marginRight="0.5rem" />
            <CheckBox
              isTable
              labelMarginRight={checkBoxMarginRight}
              labelText="수강"
              defaultValue={statusValid.ing}
              onChangeCallback={(e) => {
                searchParam.mbshp_param.mbshp_status_selector.is_ing = e.checked;
                onUpdateStatus("ing", e.checked);

                if (e.checked === false) {
                  searchParam.mbshp_param.mbshp_status_selector.is_valid = false;
                } else {
                  if (
                    searchParam.mbshp_param.mbshp_status_selector.is_ing &&
                    searchParam.mbshp_param.mbshp_status_selector.is_postpone &&
                    searchParam.mbshp_param.mbshp_status_selector.is_wait
                  ) {
                    searchParam.mbshp_param.mbshp_status_selector.is_valid = true;
                  }
                }
              }}
            />
            <CheckBox
              isTable
              labelMarginRight={checkBoxMarginRight}
              labelText="연기"
              defaultValue={statusValid.postpone}
              onChangeCallback={(e) => {
                searchParam.mbshp_param.mbshp_status_selector.is_postpone = e.checked;
                onUpdateStatus("postpone", e.checked);

                if (e.checked === false) {
                  searchParam.mbshp_param.mbshp_status_selector.is_valid = false;
                } else {
                  if (
                    searchParam.mbshp_param.mbshp_status_selector.is_ing &&
                    searchParam.mbshp_param.mbshp_status_selector.is_postpone &&
                    searchParam.mbshp_param.mbshp_status_selector.is_wait
                  ) {
                    searchParam.mbshp_param.mbshp_status_selector.is_valid = true;
                  }
                }
              }}
            />
            <CheckBox
              isTable
              labelMarginRight="0rem"
              labelText="대기 )"
              defaultValue={statusValid.wait}
              onChangeCallback={(e) => {
                searchParam.mbshp_param.mbshp_status_selector.is_wait = e.checked;
                onUpdateStatus("wait", e.checked);

                if (e.checked === false) {
                  searchParam.mbshp_param.mbshp_status_selector.is_valid = false;
                } else {
                  if (
                    searchParam.mbshp_param.mbshp_status_selector.is_ing &&
                    searchParam.mbshp_param.mbshp_status_selector.is_postpone &&
                    searchParam.mbshp_param.mbshp_status_selector.is_wait
                  ) {
                    searchParam.mbshp_param.mbshp_status_selector.is_valid = true;
                  }
                }
              }}
            />
          </div>
          <div style={{ display: "flex", marginLeft: "1.25rem" }}>
            <CheckBox
              isTable
              labelMarginRight="0rem"
              labelText="만료"
              defaultValue={searchParam.mbshp_param.mbshp_status_selector.is_expire}
              onChangeCallback={(e) => {
                searchParam.mbshp_param.mbshp_status_selector.is_expire = e.checked;
              }}
            />
          </div>

          <LabelTop text={"종료일"} />
          <ComboBox
            labelMarginRight="0rem"
            comboItemWidth={textBoxWidth}
            data={["유효기간", "최종 회원권"]}
            defaultValue={searchParam.mbshp_param.to_date_type || ""}
            onChangeCallback={(e) => {
              searchParam.mbshp_param.to_date_type = e.target.value;
            }}
          />
          <div style={{ display: "flex", marginTop: "0.25rem" }}>
            <DatePicker
              // id="mbshp_date_from"
              textBoxWidth={textBoxWidthShort}
              labelMarginRight="0rem"
              textMarginRight="0.25rem"
              defaultValue={searchParam.mbshp_param.to_date_from}
              onChangeCallback={(e) => {
                searchParam.mbshp_param.to_date_from = e;
              }}
            />
            <DatePicker
              // id="mbshp_date_to"
              labelWidth="0.5rem"
              textBoxWidth={textBoxWidthShort}
              labelMarginRight="0.25rem"
              labelText="~"
              defaultValue={searchParam.mbshp_param.to_date_to}
              onChangeCallback={(e) => {
                searchParam.mbshp_param.to_date_to = e;
              }}
            />
          </div>
        </div>

        <div style={{ display: "flex", marginTop: "1.5rem" }}>
          <IconButton type="border" />
          <Label labelText="부가상품 사용 여부" marginLeft="0.5rem" fontWeight="bold" />
        </div>
        <div>
          <LabelTop text={"운동복"} />
          <ComboBox
            labelMarginRight="0rem"
            comboItemWidth={textBoxWidth}
            data={["전체", "사용", "미사용"]}
            defaultValue={searchParam.other_param.cloth_use || "전체"}
            onChangeCallback={(e) => {
              searchParam.other_param.cloth_use = e.target.value === "전체" ? "" : e.target.value;
            }}
          />
          <LabelTop text={"GX"} />
          <ComboBox
            labelMarginRight="0rem"
            comboItemWidth={textBoxWidth}
            data={["전체", "사용", "미사용"]}
            defaultValue={searchParam.other_param.gx_use || "전체"}
            onChangeCallback={(e) => {
              searchParam.other_param.gx_use = e.target.value === "전체" ? "" : e.target.value;
            }}
          />
          <LabelTop text={"락커"} />
          <ComboBox
            labelMarginRight="0rem"
            comboItemWidth={textBoxWidth}
            data={["전체", "사용", "미사용"]}
            defaultValue={searchParam.other_param.locker_use || "전체"}
            onChangeCallback={(e) => {
              searchParam.other_param.locker_use = e.target.value === "전체" ? "" : e.target.value;
            }}
          />
        </div>

        <div style={{ display: "flex", marginTop: "1.5rem" }}>
          <IconButton type="border" />
          <Label labelText="수업" marginLeft="0.5rem" fontWeight="bold" />
        </div>
        <div>
          <LabelTop text={"OT 배정 구분"} />
          <ComboBox
            labelMarginRight="0rem"
            comboItemWidth={textBoxWidth}
            data={["전체", "배정", "미배정"]}
            defaultValue={searchParam.class_param.ot_use_state || "전체"}
            onChangeCallback={(e) => {
              searchParam.class_param.ot_use_state =
                e.target.value === "전체" ? "" : e.target.value;
            }}
          />
          <LabelTop text={"PT 상태"} />
          <ComboBox
            labelMarginRight="0rem"
            comboItemWidth={textBoxWidth}
            data={["전체", "수강", "대기", "만료"]}
            defaultValue={searchParam.class_param.pt_use_state || "전체"}
            onChangeCallback={(e) => {
              searchParam.class_param.pt_use_state =
                e.target.value === "전체" ? "" : e.target.value;
            }}
          />
        </div>
      </>
    );
  }
};
export default SearchDetailMbshpContents;
