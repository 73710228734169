import { useState } from "react";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { tabBorder, tabStyle } from "components/CommonComp";
import A11yProps from "components/TabControl/A11yProps";
import TabPanel from "components/TabControl/TabPanel";
import SwipeableViews from "react-swipeable-views";
import CouponCurrentState from "./CouponCurrentState";
import PointCurrentState from "./PointCurrentState";

const CouponPointModal = ({ modalParam, onModalDone }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const onChangeTabIndex = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <div
      style={{
        width: "60rem",
      }}
    >
      <div style={{ marginTop: "1.5rem" }}>
        <Box>
          <Tabs
            value={tabIndex}
            onChange={onChangeTabIndex}
            textColor="inherit"
            TabIndicatorProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0)" } }}
          >
            <Tab label="쿠폰" {...A11yProps(0)} style={tabStyle(0, tabIndex)} />
            <Tab label="포인트" {...A11yProps(1)} style={tabStyle(1, tabIndex)} />
          </Tabs>
        </Box>

        <SwipeableViews
          index={tabIndex}
          onChangeIndex={onChangeTabIndex}
          style={tabBorder}
          animateTransitions={false}
        >
          <TabPanel value={tabIndex} index={0} bgwhite="true">
            <CouponCurrentState
              memberId={modalParam.memberId}
              branchInfo={modalParam.baseInfoState.branch_info}
            />
          </TabPanel>
          <TabPanel value={tabIndex} index={1} bgwhite="true">
            <PointCurrentState
              memberId={modalParam.memberId}
              baseInfoState={modalParam.baseInfoState}
            />
          </TabPanel>
        </SwipeableViews>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "1rem 0 ",
        }}
      ></div>
    </div>
  );
};

export default CouponPointModal;
