import { Box } from "@mui/material";
import {
  changeComboValueToEmpty,
  checkDateOrder,
  getDefaultValue,
} from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import { useEffect, useRef, useState } from "react";
import { useTMHistStore } from "./modules/store/tmHistState";

const SearchCondition = ({ resetFlag, branchList, onClickSearch }) => {
  // 전역 상태 관리
  const { tmHistSearchParams: searchParams, setSearchParams } = useTMHistStore();
  // 페이지 상태 관리
  const labelWidth = "4.5rem";
  const textBoxWidth = "15rem";
  const textBoxWidthShort = "8.5rem";
  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);
  const phone = useRef({
    p1: searchParams.p1,
    p2: searchParams.p2,
    p3: searchParams.p3,
  });
  const phoneRef1 = useRef();
  const phoneRef2 = useRef();
  const phoneRef3 = useRef();

  // 전화번호 입력 체크
  const onInputPhone = () => {
    let res = "";

    if (searchParams.p1 && !searchParams.p2 && searchParams.p3) {
      res = "err";
    } else {
      res = searchParams.p1 + "-" + searchParams.p2 + "-" + searchParams.p3;
    }
    res = res.replace("--", "-");

    setSearchParams("phone", res);
  };

  // 초기화 시 지점 첫번째 값 세팅
  useEffect(() => {
    if (!isFirstEnterPage && branchList.branch_list.length > 0) {
      setSearchParams("branch_info", branchList.branch_list[0]);
    }
  }, [resetFlag]);

  // 첫 페이지 여부 체크
  useEffect(() => {
    setIsFirstEnterPage(false);
  }, []);

  // 전화 번호 변경 체크
  useEffect(() => {
    onInputPhone();
  }, [searchParams.p1, searchParams.p2, searchParams.p3]);

  return (
    <Box
      sx={{
        border: "1px solid #dedede",
        borderRadius: "5px",
        backgroundColor: "#f5f5f5",
        height: "8rem",
        marginTop: "0.5rem",
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") onClickSearch(1);
      }}
    >
      <div style={{ margin: "0.75rem 0.75rem" }}>
        <Label labelText="검색 조건" fontWeight="bold" justifyContent="flex-start" />
        <div style={{ display: "flex", marginTop: "0.5rem" }}>
          <div style={{ width: "26.5%" }}>
            <SearchComboBox
              labelText="지점"
              labelWidth={labelWidth}
              comboItemWidth={"17.75rem"}
              data={branchList.branch_name_list}
              defaultValue={getDefaultValue("combo", searchParams.branch_info.branch_name)}
              onChangeCallback={(e, v, idx) => {
                setSearchParams("branch_info", branchList.branch_list[idx]);
              }}
            />
          </div>
          <div style={{ width: "25%" }}>
            <ComboBox
              labelText="구분"
              labelWidth={labelWidth}
              comboItemWidth={textBoxWidth}
              data={["전체", "회원", "고객"]}
              defaultValue={getDefaultValue("combo", searchParams.member_type)}
              onChangeCallback={(e) => {
                changeComboValueToEmpty(setSearchParams, "member_type", e);
              }}
            />
          </div>
          <div style={{ width: "25%" }}>
            <TextField
              labelText="이름"
              labelWidth={labelWidth}
              textMarginRight="0rem"
              textBoxWidth={textBoxWidth}
              defaultValue={getDefaultValue("field", searchParams.member_name)}
              onChangeCallback={(e) => {
                setSearchParams("member_name", e);
              }}
            />
          </div>
          <div style={{ width: "25%", display: "flex" }}>
            <TextField
              inputRef={phoneRef1}
              labelMarginRight={"0rem"}
              labelWidth={labelWidth}
              textBoxWidth={"4.5rem"}
              textMarginRight={"0.25rem"}
              maxLength={3}
              defaultValue={getDefaultValue("field", searchParams.p1)}
              labelText="연락처"
              onChangeCallback={(e) => {
                setSearchParams("p1", e);
                phone.current.p1 = e;
                if (phone.current.p1.length === 3) {
                  phoneRef2.current.select();
                }
              }}
            />
            <TextField
              inputRef={phoneRef2}
              textBoxWidth={"4.5rem"}
              textMarginRight={"0.25rem"}
              labelMarginRight={"0.25rem"}
              maxLength={4}
              labelText="-"
              defaultValue={getDefaultValue("field", searchParams.p2)}
              onChangeCallback={(e) => {
                setSearchParams("p2", e);
                phone.current.p2 = e;
                if (phone.current.p2.length === 4) {
                  phoneRef3.current.select();
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Backspace" && !phone.current.p2) {
                  phoneRef1.current.select();
                }
              }}
            />
            <TextField
              inputRef={phoneRef3}
              textBoxWidth={"4.5rem"}
              labelMarginRight={"0.25rem"}
              maxLength={4}
              labelText="-"
              defaultValue={getDefaultValue("field", searchParams.p3)}
              onChangeCallback={(e) => {
                setSearchParams("p3", e);
                phone.current.p3 = e;
              }}
              onKeyDown={(e) => {
                if (e.key === "Backspace" && !phone.current.p3) {
                  phoneRef2.current.select();
                }
              }}
            />
          </div>
        </div>

        <div style={{ display: "flex", marginTop: "0.5rem" }}>
          <div style={{ display: "flex", width: "26.5%" }}>
            <DatePicker
              labelText="TM 일시"
              labelWidth={labelWidth}
              textBoxWidth={textBoxWidthShort}
              textMarginRight="0rem"
              defaultValue={getDefaultValue("field", searchParams.tm_from_date)}
              onChangeCallback={(e) => {
                setSearchParams("tm_from_date", e);
              }}
              onBlur={(e) => {
                if (!checkDateOrder(e, searchParams.tm_to_date)) {
                  setSearchParams("tm_from_date", "");
                }
              }}
            />
            <DatePicker
              marginLeft="0.12rem"
              labelWidth="0.5rem"
              textBoxWidth={textBoxWidthShort}
              labelMarginRight="0.12rem"
              labelText="~"
              defaultValue={getDefaultValue("field", searchParams.tm_to_date)}
              onChangeCallback={(e) => {
                setSearchParams("tm_to_date", e);
              }}
              onBlur={(e) => {
                if (!checkDateOrder(searchParams.tm_from_date, e)) {
                  setSearchParams("tm_to_date", "");
                }
              }}
            />
          </div>
          <div style={{ width: "25%" }}>
            <TextField
              labelText="TM 담당자"
              labelWidth={labelWidth}
              textMarginRight="0rem"
              textBoxWidth={textBoxWidth}
              defaultValue={getDefaultValue("field", searchParams.tm_manager.staff_name)}
              onChangeCallback={(e) => {
                const newStaffName = { ...searchParams.tm_manager, staff_name: e };
                setSearchParams("tm_manager", newStaffName);
              }}
            />
          </div>
          <div style={{ width: "25%" }}>
            <ComboBox
              labelText="TM 상태"
              labelWidth={labelWidth}
              comboItemWidth={textBoxWidth}
              data={["전체", "없음", "TM 완료", "부재"]}
              defaultValue={getDefaultValue("combo", searchParams.tm_status)}
              onChangeCallback={(e) => {
                changeComboValueToEmpty(setSearchParams, "tm_status", e);
              }}
            />
          </div>
          <div style={{ width: "25%" }}> </div>
        </div>
      </div>
    </Box>
  );
};
export default SearchCondition;
