import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, simpleAlert } from "components/CommonLib/CommonLib";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useState } from "react";
import { useDispatch } from "react-redux";

const SuggestPtModal = ({ modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);

  const validationCheck = () => {
    if (!date) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="날짜를 입력해주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="저장하시겠습니까?" />
            </>
          ),
          onBtnYes: () => {
            //API 호출
            suggestPt();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const suggestPt = async () => {
    try {
      const formData = new FormData();
      const info = {
        is_suggest_pt: true,
        membership_id: modalParam.data.membership_info.membership_id,
        ot_detail_id: modalParam.data.ot_detail_id,
        staff_info: modalParam.data.cur_manager.staff_info,
        suggest_date: date,
      };
      formData.append("new_info", JSON.stringify(info));
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_STAFF_SERVER + "/class/suggest/pt",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        onModalDone();
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <Loading />}

      <div
        style={{
          marginTop: "0.7rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <DatePicker
          labelWidth={"4.5rem"}
          minWidth={"12rem"}
          labelText="PT 권유일"
          onChangeCallback={(e) => {
            setDate(e);
          }}
        />
        <Label labelText="특이사항은 별도 입력해주시기 바랍니다" fontSize="0.8rem" />
        <NormalBtn name="저장" onClick={validationCheck} />
      </div>
    </div>
  );
};

export default SuggestPtModal;
