import IconButton from "components/Button/IconButton";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";

const SearchDetailPtContents = ({ searchParam }) => {
  const textBoxWidth = "20rem";
  const textBoxWidthShort = "9.5rem";
  const LabelTop = ({ text }) => {
    return <Label labelText={text} color="#777" justifyContent="flex-start" marginTop="0.5rem" />;
  };

  if (searchParam.pending) {
    return <div style={{ height: "50rem" }}></div>;
  } else {
    return (
      <>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <Label labelText="PT" marginLeft="0.5rem" fontWeight="bold" />
        </div>
        <div>
          <LabelTop text={"상태"} />
          <ComboBox
            labelMarginRight="0rem"
            comboItemWidth={textBoxWidth}
            data={["전체", "수강", "대기", "만료"]}
            defaultValue={searchParam.pt_param.pt_use_state || "전체"}
            onChangeCallback={(e) => {
              searchParam.pt_param.pt_use_state = e.target.value === "전체" ? "" : e.target.value;
            }}
          />
        </div>

        <LabelTop text={"계약 세션"} />
        <div style={{ display: "flex" }}>
          <TextField
            labelMarginRight="0rem"
            textMarginRight="0.25rem"
            textBoxWidth={textBoxWidthShort}
            maxValue={999}
            maxLength={3}
            isNumber
            endAdornment="회"
            defaultValue={searchParam.pt_param.session_cnt_min}
            onChangeCallback={(e) => {
              searchParam.pt_param.session_cnt_min = e;
            }}
          />
          <TextField
            labelWidth="0.75rem"
            labelText="~"
            labelMarginRight="0rem"
            textMarginRight="0.25rem"
            textBoxWidth={textBoxWidthShort}
            maxValue={999}
            maxLength={3}
            isNumber
            endAdornment="회"
            defaultValue={searchParam.pt_param.session_cnt_max}
            onChangeCallback={(e) => {
              searchParam.pt_param.session_cnt_max = e;
            }}
          />
        </div>

        <LabelTop text={"잔여 세션"} />
        <div style={{ display: "flex" }}>
          <TextField
            labelMarginRight="0rem"
            textMarginRight="0.25rem"
            textBoxWidth={textBoxWidthShort}
            maxValue={999}
            maxLength={3}
            isNumber
            endAdornment="회"
            defaultValue={searchParam.pt_param.remain_session_cnt_min}
            onChangeCallback={(e) => {
              searchParam.pt_param.remain_session_cnt_min = e;
            }}
          />
          <TextField
            labelWidth="0.75rem"
            labelText="~"
            labelMarginRight="0rem"
            textMarginRight="0.25rem"
            textBoxWidth={textBoxWidthShort}
            maxValue={999}
            maxLength={3}
            isNumber
            endAdornment="회"
            defaultValue={searchParam.pt_param.remain_session_cnt_max}
            onChangeCallback={(e) => {
              searchParam.pt_param.remain_session_cnt_max = e;
            }}
          />
        </div>

        <LabelTop text={"총 잔여 세션"} />
        <div style={{ display: "flex" }}>
          <TextField
            labelMarginRight="0rem"
            textMarginRight="0.25rem"
            textBoxWidth={textBoxWidthShort}
            maxValue={999}
            maxLength={3}
            isNumber
            endAdornment="회"
            defaultValue={searchParam.pt_param.total_remain_session_cnt_min}
            onChangeCallback={(e) => {
              searchParam.pt_param.total_remain_session_cnt_min = e;
            }}
          />
          <TextField
            labelWidth="0.75rem"
            labelText="~"
            labelMarginRight="0rem"
            textMarginRight="0.25rem"
            textBoxWidth={textBoxWidthShort}
            maxValue={999}
            maxLength={3}
            isNumber
            endAdornment="회"
            defaultValue={searchParam.pt_param.total_remain_session_cnt_max}
            onChangeCallback={(e) => {
              searchParam.pt_param.total_remain_session_cnt_max = e;
            }}
          />
        </div>

        <div style={{ display: "flex", marginTop: "1.5rem" }}>
          <IconButton type="border" />
          <Label labelText="회원권" marginLeft="0.5rem" fontWeight="bold" />
        </div>
        <div>
          <LabelTop text={"상태"} />
          <ComboBox
            labelMarginRight="0rem"
            comboItemWidth={textBoxWidth}
            data={["전체", "유효", "만료"]}
            defaultValue={searchParam.mbshp_param.mbshp_status || "전체"}
            onChangeCallback={(e) => {
              searchParam.mbshp_param.mbshp_status =
                e.target.value === "전체" ? "" : e.target.value;
            }}
          />

          <LabelTop text={"전체 회원권 잔여일"} />
          <div style={{ display: "flex" }}>
            <TextField
              labelMarginRight="0rem"
              textMarginRight="0.25rem"
              textBoxWidth={textBoxWidth}
              maxValue={9999}
              maxLength={4}
              isNumber
              endAdornment="일 이하"
              defaultValue={searchParam.mbshp_param.remain_day}
              onChangeCallback={(e) => {
                searchParam.mbshp_param.remain_day = e;
              }}
            />
          </div>
        </div>
      </>
    );
  }
};
export default SearchDetailPtContents;
