import { contentTypeJson } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

// ==================== GET ====================
// [GET] 실적 관리 검색 조건 받아오기 및 권한 데이터 세팅
export async function getSalesSearchParam() {
  return HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/record/search_param", {
    params: {},
  });
}

// [GET] 회원권 실적 내역 리스트 검색
export async function getMbshpSalesList(queryData) {
  const { page, searchParams } = queryData;
  const formData = new FormData();

  formData.append("search_param", JSON.stringify(searchParams));
  formData.append("page_num", page);

  return await HttpInstance.post(
    process.env.REACT_APP_STAFF_SERVER + "/record/search/mbshp",
    formData,
    contentTypeJson,
  );
}
// [GET] PT 실적 내역 리스트 검색
export async function getPtSalesList(queryData) {
  const { page, searchParams } = queryData;
  const formData = new FormData();

  formData.append("search_param", JSON.stringify(searchParams));
  formData.append("page_num", page);

  return await HttpInstance.post(
    process.env.REACT_APP_STAFF_SERVER + "/record/search/pt",
    formData,
    contentTypeJson,
  );
}
// [GET] 수업 실적 내역 리스트 검색
export async function getClassSalesList(queryData) {
  const { page, searchParams } = queryData;
  const formData = new FormData();

  formData.append("search_param", JSON.stringify(searchParams));
  formData.append("page_num", page);

  return await HttpInstance.post(
    process.env.REACT_APP_STAFF_SERVER + "/record/search/class",
    formData,
    contentTypeJson,
  );
}

// ==================== PATCH ====================
// [PATCH] 실적금액 변경
export async function patchSalesPrice(submitData) {
  return await HttpInstance.patch(process.env.REACT_APP_STAFF_SERVER + "/record/price", "", {
    params: submitData,
  });
}
