import { dateToStr } from "components/CommonLib/CommonLib";

//오늘을 기준으로 이번주 시작날짜(일요일) 구하기
export const calWeekStartDay = () => {
  const weekNum = new Date().getDay();
  if (weekNum === 0) {
    return dateToStr(new Date());
  } else if (weekNum === 1) {
    return dateToStr(new Date(new Date().setDate(new Date().getDate() - 1)));
  } else if (weekNum === 2) {
    return dateToStr(new Date(new Date().setDate(new Date().getDate() - 2)));
  } else if (weekNum === 3) {
    return dateToStr(new Date(new Date().setDate(new Date().getDate() - 3)));
  } else if (weekNum === 4) {
    return dateToStr(new Date(new Date().setDate(new Date().getDate() - 4)));
  } else if (weekNum === 5) {
    return dateToStr(new Date(new Date().setDate(new Date().getDate() - 5)));
  } else if (weekNum === 6) {
    return dateToStr(new Date(new Date().setDate(new Date().getDate() - 6)));
  }
};

export const showTimeText = (idx) => {
  if (idx === 0) {
    return "00:00";
  } else if (idx === 1) {
    return "01:00";
  } else if (idx === 2) {
    return "02:00";
  } else if (idx === 3) {
    return "03:00";
  } else if (idx === 4) {
    return "04:00";
  } else if (idx === 5) {
    return "05:00";
  } else if (idx === 6) {
    return "06:00";
  } else if (idx === 7) {
    return "07:00";
  } else if (idx === 8) {
    return "08:00";
  } else if (idx === 9) {
    return "09:00";
  } else if (idx === 10) {
    return "10:00";
  } else if (idx === 11) {
    return "11:00";
  } else if (idx === 12) {
    return "12:00";
  } else if (idx === 13) {
    return "13:00";
  } else if (idx === 14) {
    return "14:00";
  } else if (idx === 15) {
    return "15:00";
  } else if (idx === 16) {
    return "16:00";
  } else if (idx === 17) {
    return "17:00";
  } else if (idx === 18) {
    return "18:00";
  } else if (idx === 19) {
    return "19:00";
  } else if (idx === 20) {
    return "20:00";
  } else if (idx === 21) {
    return "21:00";
  } else if (idx === 22) {
    return "22:00";
  } else {
    return "23:00";
  }
};

export const TIME_ARR = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];
