import { contentTypeJson, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ConsultForm from "../ApprovalForm/ConsultForm/ConsultForm";
import DayOffReqForm from "../ApprovalForm/DayOffReq/DayOffReqForm";
import InsuranceReqForm from "../ApprovalForm/InsuranceReq/InsuranceReqForm";
import OrderReqForm from "../ApprovalForm/OrderReq/OrderReqForm";
import PersonnelWorkReqForm from "../ApprovalForm/PersonnelWorkReq/PersonnelWorkReqForm";
import SalaryReportForm from "../ApprovalForm/SalaryReport/SalaryReportForm";
import SpendProofForm from "../ApprovalForm/SpendProof/SpendProofForm";
import TransferReqForm from "../ApprovalForm/TransferReq/TransferReqForm";
import VaultCashForm from "../ApprovalForm/VaultCash/VaultCashForm";
import WorkAssistReqForm from "../ApprovalForm/WorkAssistReq/WorkAssistReqForm";

const ApprovalContentWrapper = ({ mode, curContent, baseState, contentState, setContentState }) => {
  const storesDispatch = useDispatch();
  const [accountList, setAccountList] = useState([]);

  useEffect(() => {
    getIncomeAccountList();
  }, []);

  const onClickLedger = (data) => {
    if (!data.ledger_date) {
      simpleAlert(storesDispatch, "알림", "지출일이 입력되지 않았습니다.");
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="지출 완료 처리 하시겠습니까?" />
              <Label labelText="진행한 후엔 되돌릴 수 없습니다." />
            </>
          ),
          onBtnYes: () => {
            onConfirm(data);
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const getIncomeAccountList = async () => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_SALARY_SERVER + "/cashbook/account/list",
        {
          params: {
            account_type: "expense",
          },
        },
      );

      if (!restEmpty(res.data.data)) {
        setAccountList(res.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  const onConfirm = async (data) => {
    const formData = new FormData();
    formData.append("doc_id", contentState.doc_info.doc_id);
    formData.append("item_id", data.item_id);
    formData.append("ledger_date", data.ledger_date);
    // formData.append("ledger_memo", "");
    await HttpInstance.patch(
      process.env.REACT_APP_SALARY_SERVER + "/cashbook/unspent/update",
      formData,
      contentTypeJson,
    )
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          simpleAlert(storesDispatch, "알림", "지출 완료 처리되었습니다.", true, () => {
            // onClickSearch();
          });
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "PATCH DATA ERROR");
      });
  };

  if (contentState) {
    switch (curContent) {
      case "consult":
        return (
          <ConsultForm
            mode={mode}
            contentState={contentState}
            setContentState={setContentState}
            onClickLedger={onClickLedger}
            accountList={accountList}
          />
        );
      case "spendProof":
        return (
          <SpendProofForm
            mode={mode}
            contentState={contentState}
            setContentState={setContentState}
            onClickLedger={onClickLedger}
            accountList={accountList}
          />
        );
      case "transferReq":
        return (
          <TransferReqForm
            mode={mode}
            baseState={baseState}
            contentState={contentState}
            setContentState={setContentState}
            onClickLedger={onClickLedger}
          />
        );
      case "personnelWorkReq":
        return (
          <PersonnelWorkReqForm
            mode={mode}
            contentState={contentState}
            setContentState={setContentState}
          />
        );
      case "salaryReport":
        // if (mode === "unspent") {
        //   return (
        //     <SalarySpendProofForm
        //       mode={mode}
        //       contentState={contentState}
        //       setContentState={setContentState}
        //       onClickLedger={onClickLedger}
        //     />
        //   );
        // } else {
        return (
          <SalaryReportForm
            mode={mode}
            contentState={contentState}
            setContentState={setContentState}
            salaryReportId={baseState.approval_docs_id}
          />
        );
      // }

      case "insuranceReq":
        return (
          <InsuranceReqForm
            baseState={baseState}
            mode={mode}
            contentState={contentState}
            setContentState={setContentState}
          />
        );
      case "dayOffReq":
        return (
          <DayOffReqForm
            baseState={baseState}
            mode={mode}
            contentState={contentState}
            setContentState={setContentState}
          />
        );
      case "workAssistReq":
        return (
          <WorkAssistReqForm
            mode={mode}
            contentState={contentState}
            setContentState={setContentState}
          />
        );
      case "orderReq":
        return (
          <OrderReqForm mode={mode} contentState={contentState} setContentState={setContentState} />
        );
      case "vaultCash":
        return (
          <VaultCashForm
            mode={mode}
            baseState={baseState}
            contentState={contentState}
            setContentState={setContentState}
          />
        );
      default:
        return (
          <div
            style={{
              marginTop: "0.5rem",
              height: "35rem",
              backgroundColor: "#EBEBEB",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Label labelText="결재 양식을 선택해 주세요" />
          </div>
        );
    }
  } else {
    return <div></div>;
  }
};
export default ApprovalContentWrapper;
