import { getPermission, simpleAlert, SubLine } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import Label from "components/LabelInput/Label";
import TableTitleWithButtonWithFilter from "components/TableTitleWithButton/TableTitleWithButtonWithFilter";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { branchSettingAction, loadItemsViewStore } from "../Module/actions";
import EtcItemTable from "./ItemsTable/EtcItemTable";
import PrepayItemTable from "./ItemsTable/PrepayItemTable";
import SubsItemTable from "./ItemsTable/SubsItemTable";

const ItemsManagement = ({ onClickOpenModal, branchId }) => {
  const storesDispatch = useDispatch();

  const restData = useSelector((state) => state.branch_set_state.branch_items_list_state);

  const [showMode, setShowMode] = useState({
    prepay: true,
    subs: true,
    etc: true,
  }); // true == 판매중
  const [prepayItems, setPrepayItems] = useState([]);
  const [subsItems, setSubsItems] = useState([]);
  const [etcItems, setEtcItems] = useState([]);

  const [canSelectFamilyOpt, setCanSelectFamilyOpt] = useState(false);
  const [canSelectPassOpt, setCanSelectPassOpt] = useState(false);

  const tableMaxHeight = "30rem";

  // console.log(branchId);
  // console.log(restData);

  const getPerm = (key, setter) => {
    getPermission(storesDispatch, key).then((res) => {
      setter(res);
    });
  };

  useEffect(() => {
    if (branchId !== "") {
      storesDispatch(loadItemsViewStore(branchId));
      getPerm("BRANCH_SET_MENU/FAMILY", setCanSelectFamilyOpt);
      getPerm("BRANCH_SET_MENU/TIME_PASS", setCanSelectPassOpt);
      // setPrepayItems(restData.stores.data.contents.prepay_items);
    }
    return () => {
      storesDispatch(branchSettingAction.loadPolicyViewReset());
    };
  }, [loadItemsViewStore, storesDispatch, branchId]);

  useEffect(() => {
    const filterStatus = "판매종료";
    if (showMode.prepay) {
      setPrepayItems(
        restData.stores.data.contents.prepay_items.filter(
          (x) => !x.base_info.sales_status.includes(filterStatus),
        ),
      );
    } else {
      setPrepayItems(
        restData.stores.data.contents.prepay_items.filter((x) =>
          x.base_info.sales_status.includes(filterStatus),
        ),
      );
    }

    if (showMode.subs) {
      setSubsItems(
        restData.stores.data.contents.subs_items.filter(
          (x) => !x.base_info.sales_status.includes(filterStatus),
        ),
      );
    } else {
      setSubsItems(
        restData.stores.data.contents.subs_items.filter((x) =>
          x.base_info.sales_status.includes(filterStatus),
        ),
      );
    }

    if (showMode.etc) {
      setEtcItems(
        restData.stores.data.contents.etc_items.filter(
          (x) => !x.base_info.sales_status.includes(filterStatus),
        ),
      );
    } else {
      setEtcItems(
        restData.stores.data.contents.etc_items.filter((x) =>
          x.base_info.sales_status.includes(filterStatus),
        ),
      );
    }
  }, [showMode, restData]);

  const viewRefresh = () => {
    storesDispatch(loadItemsViewStore(branchId));
  };

  const onAddPrePayMembership = () => {
    const param = {
      title: "완납형 상품 추가",
      modalParam: {
        mode: "add",
        branchId: branchId,
        itemId: "",
        canSelectFamilyOpt: canSelectFamilyOpt,
        canSelectPassOpt: canSelectPassOpt,
        refreshStore: viewRefresh,
      },
    };
    onClickOpenModal("상품관리/완납형", param);
  };

  const onAddSubMembership = () => {
    const param = {
      title: "구독형 상품 추가",
      modalParam: {
        mode: "add",
        branchId: branchId,
        itemId: "",
        canSelectFamilyOpt: canSelectFamilyOpt,
        canSelectPassOpt: canSelectPassOpt,
        refreshStore: viewRefresh,
      },
    };
    onClickOpenModal("상품관리/구독형", param);
  };

  const onAddEtc = () => {
    const param = {
      title: "기타상품 추가",
      modalParam: {
        mode: "add",
        branchId: branchId,
        itemId: "",
        refreshStore: viewRefresh,
      },
    };
    onClickOpenModal("상품관리/기타상품", param);
  };

  const onClickDeleteMbshp = (itemName, itemId, mbshpType) => {
    const popupText = "상품 [" + itemName + "] 를 삭제하시겠습니까?";
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText={popupText} />
          </>
        ),
        // width: "50rem",
        onBtnYes: () => {
          onDeleteMbshp(itemId, mbshpType);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const onDeleteMbshp = (itemId, mbshpType) => {
    HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/branch_set/item_remove", {
      params: {
        branch_id: branchId,
        item_id: itemId,
        mbshp_type: mbshpType,
      },
    })
      .then((res) => {
        console.log(res);
        if (!res.data.error) {
          viewRefresh();
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const onChangeShowMode = (name, val) => {
    setShowMode({
      ...showMode,
      [name]: val,
    });
  };

  const prePayList = new PrepayItemTable(
    branchId,
    onClickOpenModal,
    viewRefresh,
    onClickDeleteMbshp,
  );
  const subScriptionsList = new SubsItemTable(
    branchId,
    onClickOpenModal,
    viewRefresh,
    onClickDeleteMbshp,
  );
  const etcList = new EtcItemTable(branchId, onClickOpenModal, viewRefresh);

  return (
    <div>
      <div style={{}}>
        <TableTitleWithButtonWithFilter
          titleLabel={"완납형 상품"}
          buttonText={"추가"}
          onClickEvent={onAddPrePayMembership}
          compName="prepay"
          onChangeShowMode={onChangeShowMode}
        />
        <CustomTable
          columns_head={prePayList.columns_head}
          table_title={prePayList.table_title}
          rest_call={prePayList.get_data_from_rest}
          row_render={prePayList.create_table}
          rest_data={prepayItems}
          maxHeight={tableMaxHeight}
        />
      </div>
      <SubLine />

      <div style={{}}>
        <TableTitleWithButtonWithFilter
          titleLabel={"구독형 상품"}
          buttonText={"추가"}
          onClickEvent={onAddSubMembership}
          compName="subs"
          onChangeShowMode={onChangeShowMode}
          isDisabledBtn={true}
        />
        <CustomTable
          columns_head={subScriptionsList.columns_head}
          table_title={subScriptionsList.table_title}
          rest_call={subScriptionsList.get_data_from_rest}
          row_render={subScriptionsList.create_table}
          rest_data={subsItems}
          maxHeight={tableMaxHeight}
        />
      </div>
      <SubLine />

      <div style={{}}>
        <TableTitleWithButtonWithFilter
          titleLabel={"기타 상품"}
          buttonText={"추가"}
          onClickEvent={onAddEtc}
          compName="etc"
          onChangeShowMode={onChangeShowMode}
        />
        <CustomTable
          columns_head={etcList.columns_head}
          table_title={etcList.table_title}
          rest_call={etcList.get_data_from_rest}
          row_render={etcList.create_table}
          rest_data={etcItems}
          maxHeight={tableMaxHeight}
        />
      </div>
    </div>
  );
};
export default ItemsManagement;
