import { globalFinishLoading, globalStartLoading } from "modules/actions/Loading/index";
import { call, put } from "redux-saga/effects";
import { HttpInstance } from "../../lib/HttpLib";
import { loadTestInfoFail, loadTestSuccess } from "../actions/Test/index";

export const testAPI = (param) => {
  return HttpInstance.get(process.env.REACT_APP_AUTH_SERVER + "/test_delay", {
    params: {
      // _id: param._id,
    },
  });
};

export function* getTestSaga(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(testAPI, param);
    yield put(loadTestSuccess(true));
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from getCSTask saga  -> ");
    console.log(error);
    yield put(loadTestInfoFail(error));
    yield put(globalFinishLoading());
  }
}
