import RefundView from "./RefundView";

const RefundWrapper = ({ refundBaseState, memberBaseInfoState, settingsState, initRegDate }) => {
  return (
    <div>
      <RefundView
        refundBaseState={refundBaseState}
        memberBaseInfoState={memberBaseInfoState}
        settingsState={settingsState}
        initRegDate={initRegDate}
      />
    </div>
  );
};
export default RefundWrapper;
