import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import CheckBox from "components/LabelInput/CheckBox";
import { HttpInstance } from "lib/HttpLib";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const TransferReqListModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();

  const [resultData, setResultData] = useState([]);
  const checkedList = [];

  const preProcessResult = (data) => {
    const res = [];

    for (let i = 0; i < data.length; i++) {
      if (modalParam.current_list.findIndex((x) => x.item_id === data[i].item_id) === -1) {
        res.push(data[i]);
      }
    }

    setResultData(res);
  };

  useEffect(() => {
    HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + "/daily_report/transfer_req", {
      params: {
        branch_id: modalParam.branch_id,
        is_done: false,
        is_expenditure: false,
        is_admin: false,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data) && res.data.data.length !== 0) {
          preProcessResult(res.data.data);
        } else {
          simpleAlert(storesDispatch, "알림", "데이터가 없습니다.");
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  }, []);

  const onCheckData = (data) => {
    console.log(data);
  };

  const transferList = new transferListTable(checkedList);

  return (
    <div style={{ width: "80rem" }}>
      <div style={{ margin: "0.5rem 0.1rem" }}>
        <CustomTable
          columns_head={transferList.columns_head}
          table_title={transferList.table_title}
          rest_call={transferList.get_data_from_rest}
          row_render={transferList.create_table}
          rest_data={resultData}
          height="20rem"
        />
      </div>

      <div style={{ display: "flex", justifyContent: "center", marginTop: "0.75rem" }}>
        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType7]: true,
          })}
          onClick={() => {
            const result = [];
            for (let i = 0; i < checkedList.length; i++) {
              result.push(resultData[checkedList[i]]);
            }
            onModalDone({ selectedIdx: checkedList, resultList: result });
          }}
        >
          완료
        </Button>
      </div>
    </div>
  );
};
export default TransferReqListModal;

//

class transferListTable {
  table_title = "";
  checkedList = null;

  constructor(checkedList) {
    this.checkedList = checkedList;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow style={{}} key={idx}>
        <CustomTableContents>
          <CheckBox
            isTable
            onChangeCallback={(e) => {
              if (e.checked) {
                this.checkedList.push(idx);
              } else {
                const delIdx = this.checkedList.findIndex((x) => x === idx);
                this.checkedList.splice(delIdx, 1);
              }
            }}
          />
        </CustomTableContents>
        <CustomTableContents>{data.type}</CustomTableContents>
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.hq_info.hq_name}</CustomTableContents>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_name}</CustomTableContents>
        <CustomTableContents>{data.payment_info.pay_method}</CustomTableContents>
        <CustomTableContents $isNumber>
          {data.payment_info.price.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContentsEND $isNumber>
          {data.payment_info.refund_transfer_price &&
            data.payment_info.refund_transfer_price.toLocaleString() + " 원"}
        </CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "선택", width: "6%" },
    { title: "구분", width: "9%" },
    { title: "일시", width: "14%" },
    { title: "본부", width: "13%" },
    { title: "지점", width: "13%" },
    { title: "회원번호", width: "9%" },
    { title: "회원명", width: "9%" },
    { title: "결제수단", width: "7%" },
    { title: "결제금액", width: "10%" },
    { title: "이체요청금액", width: "10%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
