import { initialSettingState } from "./setting_initial_state";
// import { registerViewInitState } from "./register_view_state";

import { registerViewInitState } from "pages/ERP/MemberRegister/Common/Module/reducers/view_init";

import * as ActionTypes from "../actions/action_type";

export const register_settings = (state = initialSettingState, action) => {
  switch (action.type) {
    case ActionTypes.getRegisterSettingSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getRegisterSettingFailAction:
    case ActionTypes.getRegisterSettingResetAction:
      return { ...state, stores: initialSettingState.stores };
    default:
      return state;
  }
};

const initialMemberState = {
  stores: {
    data: {
      ...registerViewInitState,
    },
  },
};
export const register_view_state = (state = initialMemberState, action) => {
  switch (action.type) {
    case ActionTypes.getRegisterViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getRegisterViewFailAction:
    case ActionTypes.getRegisterViewResetAction:
      return { stores: initialMemberState.stores };
    default:
      return state;
  }
};
