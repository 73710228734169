import { SubLine } from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import Label from "components/LabelInput/Label";
import LockerListInfo from "pages/ERP/MemberCommonItems/LockerRegister/LockerListInfo";
import PriceDiscountInput from "pages/ERP/MemberRegister/Common/Etc/PriceDiscountInput";
import { useRegisterStore } from "pages/ERP/MemberRegister/Register/modules/store/registerState";
import { useEffect, useRef, useState } from "react";

const MembershipSideItem = ({
  selectorInfo,
  payInfoListAction,
  membershipIdx,
  itemIncludeRef,
  memberInputState,
  hqBranchPolicy,
  isAddPay,
  memberId,
  personalInfo,
  noReg,
}) => {
  const [regDis, setRegDis] = useState(memberInputState.membership_list.reg.dis);
  const [clothDis, setClothDis] = useState(memberInputState.membership_list.cloth.dis);
  const [gxDis, setGxDis] = useState(memberInputState.membership_list.gx.dis);

  const [clothPriceOrigin, setClothPriceOrigin] = useState(0);
  const [gxPriceOrigin, setGxPriceOrigin] = useState(0);
  const [curLockerPrice, setCurLockerPrice] = useState(0);
  const [lockerPriceOrigin, setLockerPriceOrigin] = useState(0);
  // 전역 상태 관리
  const { onChangeSubmitDocState } = useRegisterStore();

  const itemIdx = useRef();

  const setLockerPrice = (val) => {
    setCurLockerPrice(val);
  };

  useEffect(() => {
    setClothPriceOrigin(getItemData(memberInputState.membership_list.cloth.is_lock).cloth_price);
    setGxPriceOrigin(getItemData(memberInputState.membership_list.cloth.is_lock).gx_price);
    setLockerPriceOrigin(getItemData(memberInputState.membership_list.cloth.is_lock).locker_price);
    setCurLockerPrice(getItemData(memberInputState.membership_list.cloth.is_lock).locker_price);
  }, [memberInputState.purchase_item_list.item_info]);

  useEffect(() => {
    if (itemIdx.current && selectorInfo.item_name_idx !== itemIdx.current) {
      setRegDis(0);
      setClothDis(0);
      setGxDis(0);
    }
    itemIdx.current = selectorInfo.item_name_idx;
  }, [selectorInfo.item_name_idx]);

  const disItemRefs = {
    reg: useRef(),
    cloth: useRef(),
    gx: useRef(),
    locker: [useRef(), useRef()],
  };

  const inputPayInfoList = (event, orderIdx, contentObj, stdPrice, payOrder, dis) => {
    let act = event.checked ? "push" : "pop";
    let eventId = memberInputState.tab_info.title + event.id;
    let tabIdx = memberInputState.tab_info.idx;
    let startIdx = membershipIdx * 10;

    payInfoListAction(
      act,
      eventId,
      {
        type: eventId,
        price: stdPrice,
        dis: dis,
        pay_order: startIdx + payOrder,
        tab_idx: tabIdx,
        item_type: event.id + "/" + getTypeData(false) + "/금액/" + getOptionData(false) + "/없음",
      },
      startIdx + payOrder,
      membershipIdx,
    );
  };

  const getItemData = (is_lock) => {
    if (
      memberInputState.tab_info.is_add_pay === true &&
      memberInputState.tab_info.is_new === false
    ) {
      return memberInputState.purchase_item_list.item_info;
    } else {
      return memberInputState.purchase_item_list.item_info;
    }
  };

  const getTypeData = (is_lock) => {
    if (
      memberInputState.tab_info.is_add_pay === true &&
      memberInputState.tab_info.is_new === false
    ) {
      return memberInputState.purchase_item_list.item_info.mbshp_type;
    } else {
      return memberInputState.purchase_item_list.item_info.mbshp_type;
    }
  };

  const getOptionData = (is_lock) => {
    if (
      memberInputState.tab_info.is_add_pay === true &&
      memberInputState.tab_info.is_new === false
    ) {
      return memberInputState.purchase_item_list.item_info.mbshp_option;
    } else {
      return memberInputState.purchase_item_list.item_info.mbshp_option;
    }
  };

  return (
    <div style={{}}>
      <SubLine />
      {/* <SubTitle titleText={"기준회원 부가상품"} /> */}
      <Label labelText="부가상품" justifyContent="left" />
      {selectorInfo.mbshp_option === "일일권" || memberInputState.tab_info.is_add_pay || noReg ? (
        <></>
      ) : (
        <div
          style={{
            display: memberInputState.membership_list.reg.is_hide ? "none" : "flex",
            marginTop: "0.75rem",
            marginLeft: "1rem",
          }}
        >
          <CheckBox
            marginTop="0rem"
            ref={itemIncludeRef.join}
            labelText="등록비"
            onChangeCallbackOrd={(event, orderIdx, contentObj) => {
              memberInputState.membership_list.reg.is_use = event.checked;
              inputPayInfoList(
                event,
                orderIdx,
                contentObj,
                memberInputState.purchase_item_list.item_info.reg_price,
                0,
              );
              disItemRefs.reg.current.setDisabled(event.checked);
            }}
            orderIdx={1}
            // disabled={memberInputState.membership_list.reg.is_lock}
            defaultValue={true}
            readonly
          />
          <PriceDiscountInput
            ref={disItemRefs.reg}
            baseInfo={{
              tabTitle: memberInputState.tab_info.title,
              tabIdx: memberInputState.tab_info.idx,
              orderStartIdx: membershipIdx,
              // type: memberInputState.tab_info.is_add_pay
              //   ? memberInputState.tab_info.title + "등록비"
              //   : "등록비",
              type: "등록비",
              typeKey: "등록비",
              orderIdx: 1,
              payOrder: 0,
              item_type:
                "등록비/" +
                getTypeData(memberInputState.membership_list.reg.is_lock) +
                "/금액/" +
                getOptionData(memberInputState.membership_list.reg.is_lock) +
                "/없음",
            }}
            standardPrice={memberInputState.purchase_item_list.item_info.reg_price}
            payInfoListAction={payInfoListAction}
            onChangeCallback={(e) => {
              memberInputState.membership_list.reg.dis = e;
              setRegDis(e);
            }}
            paramLock={memberInputState.membership_list.reg.is_lock}
            paramUse={true}
            discountValue={regDis}
          />
        </div>
      )}

      <div
        style={{
          display: "flex",
          marginTop: "0.5rem",
          marginLeft: "1rem",
        }}
      >
        <CheckBox
          marginTop="0rem"
          ref={itemIncludeRef.cloth}
          labelText="운동복"
          onChangeCallbackOrd={(event, orderIdx, contentObj) => {
            // 신규 등록이 아닐 때만 서비스 신청서 받음
            !memberInputState.tab_info.is_new &&
              onChangeSubmitDocState("서비스 신청서", event.checked);

            memberInputState.membership_list.cloth.is_use = event.checked;
            inputPayInfoList(
              event,
              orderIdx,
              contentObj,
              memberInputState.purchase_item_list.item_info.cloth_price,
              2,
              memberInputState.membership_list.cloth.dis,
            );
            disItemRefs.cloth.current.setDisabled(event.checked);
          }}
          orderIdx={2}
          disabled={
            memberInputState.membership_list.cloth.is_lock ||
            memberInputState.purchase_item_list.item_info.is_combine_cloth
          }
          defaultValue={memberInputState.membership_list.cloth.is_use}
        />
        <PriceDiscountInput
          ref={disItemRefs.cloth}
          baseInfo={{
            tabTitle: memberInputState.tab_info.title,
            tabIdx: memberInputState.tab_info.idx,
            orderStartIdx: membershipIdx,
            type: memberInputState.tab_info.is_add_pay
              ? memberInputState.tab_info.title + "운동복"
              : "운동복",
            typeKey: memberInputState.tab_info.is_add_pay
              ? memberInputState.tab_info.title + "운동복"
              : "운동복",
            orderIdx: 2,
            payOrder: 2,
            item_type:
              "운동복/" +
              getTypeData(memberInputState.membership_list.cloth.is_lock) +
              "/금액/" +
              getOptionData(memberInputState.membership_list.cloth.is_lock) +
              "/없음",
          }}
          standardPrice={getItemData(memberInputState.membership_list.cloth.is_lock).cloth_price}
          setStandardPrice={(e) => {
            getItemData(memberInputState.membership_list.cloth.is_lock).cloth_price = e;
            setClothPriceOrigin(e);
          }}
          payInfoListAction={payInfoListAction}
          onChangeCallback={(e) => {
            memberInputState.membership_list.cloth.dis = e;
            setClothDis(e);
          }}
          paramLock={memberInputState.membership_list.cloth.is_lock}
          paramUse={memberInputState.membership_list.cloth.is_use}
          discountValue={clothDis}
          canChangePrice
          isAddPay={memberInputState.tab_info.is_add_pay}
          originPrice={clothPriceOrigin}
        />
      </div>

      <div
        style={{
          display: "flex",
          marginTop: "0.5rem",
          marginLeft: "1rem",
        }}
      >
        <CheckBox
          marginTop="0rem"
          ref={itemIncludeRef.gx}
          labelText="GX"
          onChangeCallbackOrd={(event, orderIdx, contentObj) => {
            !memberInputState.tab_info.is_new &&
              onChangeSubmitDocState("서비스 신청서", event.checked);

            memberInputState.membership_list.gx.is_use = event.checked;
            inputPayInfoList(
              event,
              orderIdx,
              contentObj,
              memberInputState.purchase_item_list.item_info.gx_price,
              3,
              memberInputState.membership_list.gx.dis,
            );
            disItemRefs.gx.current.setDisabled(event.checked);
          }}
          orderIdx={3}
          disabled={
            memberInputState.membership_list.gx.is_lock ||
            memberInputState.purchase_item_list.item_info.is_combine_gx
          }
          defaultValue={memberInputState.membership_list.gx.is_use}
        />
        <PriceDiscountInput
          ref={disItemRefs.gx}
          baseInfo={{
            tabTitle: memberInputState.tab_info.title,
            tabIdx: memberInputState.tab_info.idx,
            orderStartIdx: membershipIdx,
            type: memberInputState.tab_info.is_add_pay
              ? memberInputState.tab_info.title + "GX"
              : "GX",
            typeKey: memberInputState.tab_info.is_add_pay
              ? memberInputState.tab_info.title + "GX"
              : "GX",
            orderIdx: 3,
            payOrder: 3,
            item_type:
              "GX/" +
              getTypeData(memberInputState.membership_list.gx.is_lock) +
              "/금액/" +
              getOptionData(memberInputState.membership_list.gx.is_lock) +
              "/없음",
          }}
          // standardPrice={memberInputState.purchase_item_list.item_info.gx_price}
          standardPrice={getItemData(memberInputState.membership_list.gx.is_lock).gx_price}
          setStandardPrice={(e) => {
            getItemData(memberInputState.membership_list.gx.is_lock).gx_price = e;
            setGxPriceOrigin(e);
          }}
          payInfoListAction={payInfoListAction}
          onChangeCallback={(e) => {
            memberInputState.membership_list.gx.dis = e;
            setGxDis(e);
          }}
          paramLock={memberInputState.membership_list.gx.is_lock}
          paramUse={memberInputState.membership_list.gx.is_use}
          discountValue={gxDis}
          canChangePrice
          isAddPay={memberInputState.tab_info.is_add_pay}
          originPrice={gxPriceOrigin}
        />
      </div>

      {selectorInfo.mbshp_option !== "일일권" ? (
        <div style={{ marginTop: "0.5rem", marginLeft: "1rem" }}>
          <LockerListInfo
            hqBranchPolicy={hqBranchPolicy}
            memberInputState={memberInputState}
            payInfoListAction={payInfoListAction}
            memberId={memberId}
            orderStartIdx={membershipIdx}
            isSubs={false}
            personalInfo={personalInfo}
            isAddPay={memberInputState.tab_info.is_add_pay}
            originPrice={curLockerPrice} // 변동되는 금액
            lockerPriceOrigin={lockerPriceOrigin} // 원래 락커 금액
            setLockerPrice={setLockerPrice}
          />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default MembershipSideItem;
