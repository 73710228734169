import {
  loadStaffInfoView,
  loadStaffInfoViewSuccess,
  loadStaffInfoViewFail,
  loadStaffInfoViewReset,
  loadStaffWorkListView,
  loadStaffWorkListViewSuccess,
  loadStaffWorkListViewFail,
  loadStaffWorkListViewReset,
  loadStaffWorkDetailView,
  loadStaffWorkDetailViewSuccess,
  loadStaffWorkDetailViewFail,
  loadStaffWorkDetailViewReset,
  loadGxFeeView,
  loadGxFeeViewSuccess,
  loadGxFeeViewFail,
  loadGxFeeViewReset,
} from "./view_act";

export const loadStaffInfoViewStore = (staff_id) => loadStaffInfoView(staff_id);
export const loadStaffWorkListViewStore = (staff_id) => loadStaffWorkListView(staff_id);
export const loadStaffWorkDetailViewStore = (work_info_id) => loadStaffWorkDetailView(work_info_id);
export const loadGxFeeViewStore = (work_info_id, gx_fee_id) =>
  loadGxFeeView(work_info_id, gx_fee_id);

export const staffViewAction = {
  loadStaffInfoView,
  loadStaffInfoViewSuccess,
  loadStaffInfoViewFail,
  loadStaffInfoViewReset,

  loadStaffWorkListView,
  loadStaffWorkListViewSuccess,
  loadStaffWorkListViewFail,
  loadStaffWorkListViewReset,

  loadStaffWorkDetailView,
  loadStaffWorkDetailViewSuccess,
  loadStaffWorkDetailViewFail,
  loadStaffWorkDetailViewReset,

  loadGxFeeView,
  loadGxFeeViewSuccess,
  loadGxFeeViewFail,
  loadGxFeeViewReset,
};
