export const erpCouponState = {

  coupon_id: "",//"string / 쿠폰 ID",
  base_info: {
    use_target: "",//"string / 발급대상 / 회원 - 직원",
    type: "",//"string / 발급 종류",
    condition: false,//"bool / 조건부 자동 발급",

    benefit_type: "",//"string / 혜택 종류",
    benefit_product: "",//"string / 혜택 상품",
    benefit_use: "",//"string / 혜택 사용방법 - 구매 시, 이용 중, 즉시",

    can_gift: "",//"string / 가능 - 불가",
    img: "",//"string / 경로",

    issue_date: "",//"string / 발급일",
    expired_month: 0,//"int / 유효 기간 월",
    expired_day: 0,//"int / 유효 기간 일",
    expired_from_date: "",//"string / 유효 기간",
    expired_to_date: "",//"string / 유효 기간"
  },

  detail: {
    coupon_name: "",//"string / 쿠폰명",
    recv_cnt: 0,//"int / 수신자 인원",
    is_use_first_come: false,//"bool / 선착순",
    first_come_cnt: 0,//"int / 선착순 인원",
    is_coupon_unlimited: false,//"bool / 발행 갯수 제한없음",
    coupon_limit_cnt: 0,//"int / 발행 수량",

    service_benefit: {
      branch_info: {
        branch_id: "",//"string / 지점 ID",
        branch_name: "",//"string / 지점명"
      },
      mbshp: {
        target_branch: {
          branch_id: "",//"string / 지점 ID",
          branch_name: "",//"string / 지점명"
        },
        mbshp_type: "",//"string / 회원권 종류",
        mbshp_option: "",//"string / 회원권 옵션",
        add_month: 0,//"int / 추가 기간 월",
        add_day: 0,//"int / 추가 기간 일"
      },
      pt: {
        add_session: 0,
      }
    },
    price_benefit: {
      type: "",//"string / 할인 방식",
      dis_price: 0,//"int / 할인 금액 원",
      dis_rate: 0,//"int / 할인 금액 %"
    }

  },
  reg_info: {
    created_at: "",//"date / 등록일시",
    reg_id: "",//"string / 등록자 ID",
    reg_name: "",//"date / 등록자",
    updated_at: "",//"date / 수정일시",
    upd_id: "",//"string / 수정자 ID",
    upd_name: "",//"date / 수정자",
  }

}