import * as ActionTypes from "../actions/action_type";

const initialStete = {
  stores: {
    data: [],
  },
};
export const staff_search_result_info = (state = initialStete, action) => {
  switch (action.type) {
    case ActionTypes.staffSearchSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.staffSearchFailAction:
    case ActionTypes.staffSearchResetAction:
      return { ...state, stores: initialStete.stores };
    default:
      return state;
  }
};
