import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, SubLine } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import SubTitle from "components/PageTitle/SubTitle";

const PositionDefaultSetting = ({ restData, onClickOpenModal, viewRefresh }) => {
  const onEditDone = (data, done) => {};
  const onEditRow = (data, idx) => {
    // console.log(data);
    const param = {
      title: "직책별 기본값 설정",
      modalParam: {
        // mode: "add",
        itemIdx: idx,
        workInfoStore: data,
        hqInfo: restData.hq_info,
        onEditDone: onEditDone,
        refreshStore: viewRefresh,
      },
    };
    onClickOpenModal("직책별기본값설정", param);
  };
  const positionDefaultTableItem = new positionDefaultTable(onEditRow);

  return (
    <div>
      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText={"직책별 기본값 설정"} />
      </div>

      <CustomTable
        columns_head={positionDefaultTableItem.columns_head}
        table_title={positionDefaultTableItem.table_title}
        rest_call={positionDefaultTableItem.get_data_from_rest}
        row_render={positionDefaultTableItem.create_table}
        rest_data={restData.hq_set.position_list}
        maxHeight="18rem"
      />
    </div>
  );
};

const PtSalarySetting = ({ restData, onClickOpenModal, viewRefresh }) => {
  const onEditRow = (data, idx) => {
    const param = {
      title: "인정 매출 비율 설정",
      modalParam: {
        // mode: "add",
        hq_info: restData.hq_info,
        salesInfoStore: data,
        index: idx,
        refreshStore: viewRefresh,
      },
    };
    onClickOpenModal("인정매출비율설정", param);
  };
  let paymentPtSalaryTableItem = new paymentPtSalaryTable(onEditRow);

  return (
    <div>
      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText={"결제수단별 PT 매출 비율"} />
      </div>

      <CustomTable
        columns_head={paymentPtSalaryTableItem.columns_head}
        table_title={paymentPtSalaryTableItem.table_title}
        rest_call={paymentPtSalaryTableItem.get_data_from_rest}
        row_render={paymentPtSalaryTableItem.create_table}
        rest_data={restData.policy_set.cms_by_pay_list}
        maxHeight="18rem"
      />
    </div>
  );
};

const PayDayDefaultSetting = ({ restData, onClickOpenModal, viewRefresh }) => {
  const onEditRow = (data, idx) => {
    const param = {
      title: "급여일 설정",
      modalParam: {
        // mode: "add",
        hq_info: restData.hq_info,
        salaryInfoStore: data,
        index: idx,
        refreshStore: viewRefresh,
      },
    };
    onClickOpenModal("급여일설정", param);
  };
  let payDayDefaultTableItem = new payDayDefaultTable(onEditRow);

  return (
    <div>
      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText={"급여일 기본값"} />
      </div>

      <CustomTable
        columns_head={payDayDefaultTableItem.columns_head}
        table_title={payDayDefaultTableItem.table_title}
        rest_call={payDayDefaultTableItem.get_data_from_rest}
        row_render={payDayDefaultTableItem.create_table}
        rest_data={restData.policy_set.default_salary_date_list}
        maxHeight="18rem"
      />
    </div>
  );
};

const PolicyStaffSetting = ({ onClickOpenModal, restData, viewRefresh }) => {
  return (
    <div>
      <PositionDefaultSetting
        restData={restData}
        onClickOpenModal={onClickOpenModal}
        viewRefresh={viewRefresh}
      />
      <SubLine />
      <PtSalarySetting
        restData={restData}
        onClickOpenModal={onClickOpenModal}
        viewRefresh={viewRefresh}
      />
      <SubLine />
      <PayDayDefaultSetting
        restData={restData}
        onClickOpenModal={onClickOpenModal}
        viewRefresh={viewRefresh}
      />
      {/* <SubLine />
      <ETCSetting /> */}
    </div>
  );
};
export default PolicyStaffSetting;

class positionDefaultTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onEditRow = null;

  constructor(onEditRow) {
    this.onEditRow = onEditRow;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.org_name}</CustomTableContents>
        <CustomTableContents>{data.wage_type}</CustomTableContents>
        <CustomTableContents $isNumber>{data.wage.toLocaleString() + " 원"}</CustomTableContents>
        <CustomTableContents>{data.is_pt_extra === true ? "Y" : "N"}</CustomTableContents>
        <CustomTableContents>
          <IconButton type="edit" width="2.2rem" onClick={() => this.onEditRow(data, idx)} />
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "직책명", width: "22.5%" },
    { title: "급여 종류", width: "22.5%" },
    { title: "급여액", width: "22.5%" },
    { title: "PT수당 여부", width: "22.5%" },
    { title: " ", width: "10%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}

class paymentPtSalaryTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onParentClickEvent = null;

  constructor(onEditRow) {
    this.onParentClickEvent = onEditRow;
  }

  onClickEvent = (data, idx) => {
    if (this.onParentClickEvent !== null) {
      this.onParentClickEvent(data, idx);
    }
  };

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents $isNumber>{data.cms_by_pay_method.cash * 100} %</CustomTableContents>
        <CustomTableContents $isNumber>{data.cms_by_pay_method.card * 100} %</CustomTableContents>

        <CustomTableContents>
          <IconButton type="edit" width="2.2rem" onClick={() => this.onClickEvent(data, idx)} />
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "지점명", width: "30%" },
    { title: "현금매출(%)", width: "30%" },
    { title: "카드매출(%)", width: "30%" },
    { title: " ", width: "10%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}

class payDayDefaultTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onParentClickEvent = null;

  constructor(onEditRow) {
    this.onParentClickEvent = onEditRow;
  }

  onClickEvent = (data, idx) => {
    if (this.onParentClickEvent !== null) {
      this.onParentClickEvent(data, idx);
    }
  };

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.default_salary_date.wage} 일</CustomTableContents>
        <CustomTableContents>{data.default_salary_date.incentive} 일</CustomTableContents>
        <CustomTableContents>{data.default_salary_date.pt_fee} 일</CustomTableContents>
        <CustomTableContents>{data.default_salary_date.gx_fee} 일</CustomTableContents>
        <CustomTableContents>{data.default_salary_date.etc_fee} 일</CustomTableContents>

        <CustomTableContents>
          <IconButton type="edit" width="2.2rem" onClick={() => this.onClickEvent(data, idx)} />
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "지점명", width: "20%" },
    { title: "기본급", width: "14%" },
    { title: "회원권 판매 인센티브", width: "14%" },
    { title: "PT수당", width: "14%" },
    { title: "GX수당", width: "14%" },
    { title: "기타수당", width: "14%" },
    { title: " ", width: "10%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
