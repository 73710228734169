import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty } from "components/CommonLib/CommonLib";

import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";

const DstPtInfo = forwardRef((props, ref) => {
  const { ptInfoList, isIncludeServicePt } = props;
  const [dstPtList, setDstPtList] = useState(ptInfoList);

  const dataInfo = new dataInfoTable(isIncludeServicePt);

  useEffect(() => {
    setDstPtList(ptInfoList);
  }, [ptInfoList]);

  useImperativeHandle(ref, () => ({
    setSelectRow: (rows) => {
      const newRows = rows.map((el) => {
        return { ...el, isGiven: true };
      });
      // console.log("new", newRows);
      setDstPtList([...ptInfoList, ...newRows]);
    },
    getSelectRow: () => {
      return dstPtList;
    },
  }));

  return (
    <div>
      <CustomTable
        columns_head={dataInfo.columns_head}
        table_title={dataInfo.table_title}
        rest_call={dataInfo.get_data_from_rest}
        row_render={dataInfo.create_table}
        rest_data={dstPtList}
      />
    </div>
  );
});

export default DstPtInfo;

class dataInfoTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onParentClickEvent = null;
  isPt = null;
  constructor(isPt) {
    this.isPt = isPt;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.isGiven ? "양수" : "기존"}</CustomTableContents>
        <CustomTableContents>{data.pt_info.item_name}</CustomTableContents>
        <CustomTableContents>
          {this.isPt
            ? data.pt_info.base_session_cnt
            : data.pt_info.base_session_cnt + data.pt_info.service_session_cnt}
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "구분", width: "10%" },
    { title: "계약 상품", width: "70%" },
    { title: "잔여횟수", width: "20%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
