import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import TextField from "components/LabelInput/TextField";
import PriceDiscountInput from "pages/ERP/MemberRegister/Common/Etc/PriceDiscountInput";
import { useRegisterStore } from "pages/ERP/MemberRegister/Register/modules/store/registerState";
import { useEffect, useRef, useState } from "react";

const LockerListItem = ({
  ord,
  lockerItemInfo,
  btnLockerPop,
  onClickOpenLockerModal,
  payInfoListAction,
  tabInfoParam,
  isSubs,
  subsPayInfoListAction,
  memberInputState,
  familyKey,
  onClickExistLocker,
  existLockerList,
  lockerLen,
  isAddPay,
  originPrice,
  lockerPriceOrigin,
  setLockerPrice,
}) => {
  const btnStyleClass = btnStyles();
  const [lockerSubsDis, setLockerSubsDis] = useState(
    lockerItemInfo.item_info.subs_options.subs_dis,
  );
  const [lockerProrateDis, setLockerProrateDis] = useState(
    lockerItemInfo.item_info.subs_options.prorate_dis,
  );
  const [lockerPreDis, setLockerPreDis] = useState(lockerItemInfo.item_info.subs_options.pre_dis);
  const [lockerInputPrice, setLocketInputPrice] = useState(
    memberInputState.purchase_item_list.item_info.locker_price,
  );
  // 전역 상태 관리
  const { onChangeSubmitDocState } = useRegisterStore();

  const itemIdx = useRef();

  useEffect(() => {
    if (itemIdx.current && memberInputState.membership_list.item_name_idx !== itemIdx.current) {
      setLockerSubsDis(0);
      setLockerProrateDis(0);
      setLockerPreDis(0);
    }
    itemIdx.current = memberInputState.membership_list.item_name_idx;
  }, [
    memberInputState.membership_list.mbshp.from_date,
    memberInputState.membership_list.item_name_idx,
  ]);

  const canSelectExistLocker = (is_lock) => {
    if (!memberInputState.tab_info.is_add_pay) {
      return false;
    }
    if (existLockerList.length === 0) {
      return false;
    }
    return true;
  };

  const getItemData = (is_lock) => {
    if (
      memberInputState.tab_info.is_add_pay === true &&
      memberInputState.tab_info.is_new === false
    ) {
      // addpaid 뺌
      // if (is_lock) {
      return memberInputState.purchase_item_list.item_info;
      // } else {
      //   return memberInputState.purchase_item_addpaid.item_info;
      // }
    } else {
      return memberInputState.purchase_item_list.item_info;
    }
  };

  const getOptionData = () => {
    if (
      memberInputState.tab_info.is_add_pay === true &&
      memberInputState.tab_info.is_new === false
    ) {
      return memberInputState.purchase_item_list.item_info.mbshp_option;
    } else {
      return memberInputState.purchase_item_list.item_info.mbshp_option;
    }
  };

  const canRemove = () => {
    if (lockerLen - 1 === ord) {
      return false;
    }
    return true;
  };

  return (
    <div style={{}}>
      <div
        style={{
          display: "flex",
          marginTop: "1rem",
        }}
      >
        <TextField
          textMarginRight="0.25rem"
          labelMarginLeft="3rem"
          labelWidth="2.5rem"
          textBoxWidth="6rem"
          labelText="· 번호"
          defaultValue={lockerItemInfo.locker_info.locker_no}
          disabled
        />
        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType2]: true,
            [btnStyleClass.disabled]: lockerItemInfo.item_info.is_lock,
          })}
          onClick={(e) =>
            onClickOpenLockerModal("락커선택", "락커 배정", ord, lockerItemInfo.view_id)
          }
          id={lockerItemInfo.view_id}
          disabled={lockerItemInfo.item_info.is_lock}
        >
          선택
        </Button>

        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType5]: true,
            [btnStyleClass.disabled]: lockerItemInfo.item_info.is_lock || !canSelectExistLocker(),
          })}
          onClick={(e) => onClickExistLocker(lockerItemInfo.view_id)}
          id={lockerItemInfo.view_id}
          disabled={lockerItemInfo.item_info.is_lock || !canSelectExistLocker()}
        >
          기존 선택
        </Button>

        <TextField
          textMarginRight="0.25rem"
          labelMarginLeft="2rem"
          labelWidth="3.5rem"
          textBoxWidth="6rem"
          labelText="비밀번호"
          defaultValue={lockerItemInfo.locker_info.password}
          maxLength={4}
          onChangeCallback={(e) => {
            lockerItemInfo.locker_info.password = e;
          }}
          disabled={lockerItemInfo.item_info.is_lock}
        />
        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType2]: true,
            [btnStyleClass.disabled]: lockerItemInfo.item_info.is_lock || canRemove,
          })}
          onClick={(e) => {
            !memberInputState.tab_info.is_new && onChangeSubmitDocState("서비스 신청서", false);
            btnLockerPop(lockerItemInfo.view_id);
          }}
          id={lockerItemInfo.view_id}
          disabled={
            lockerItemInfo.item_info.is_lock || canRemove() || lockerItemInfo.item_info.is_combine
          }
        >
          삭제
        </Button>
        {isSubs ? (
          <PriceDiscountInput
            baseInfo={{
              tabTitle: tabInfoParam.title,
              tabIdx: tabInfoParam.idx,
              orderStartIdx: tabInfoParam.idx,
              type: "락커 (" + (ord + 1) + ")",
              typeKey: lockerItemInfo.view_id,
              orderIdx: ord + 4,
              payOrder: ord + 5,
              label: "월 구독료",
              item_type: "락커/구독형/월구독료/" + getOptionData() + "/" + familyKey,
            }}
            standardPrice={lockerItemInfo.item_info.subs_options.subs_price}
            payInfoListAction={subsPayInfoListAction}
            onChangeCallback={(e) => {
              lockerItemInfo.item_info.subs_options.subs_dis = e;
              setLockerSubsDis(e);
              // setGxProrate(onChangeSubsDis("GX"));
            }}
            paramUse={true}
            paramLock={lockerItemInfo.item_info.is_lock}
            discountValue={lockerSubsDis}
          />
        ) : (
          <PriceDiscountInput
            baseInfo={{
              tabTitle: tabInfoParam.title,
              tabIdx: tabInfoParam.idx,
              orderStartIdx: tabInfoParam.idx * 10,
              typeKey: lockerItemInfo.view_id,
              type: memberInputState.tab_info.title + "락커 (" + (ord + 1) + ")",
              orderIdx: ord + 4,
              payOrder: ord + 4,
              item_type:
                "락커/완납형/금액/" +
                getOptionData(lockerItemInfo.item_info.is_lock) +
                "/" +
                familyKey,
            }}
            standardPrice={lockerInputPrice}
            setStandardPrice={(e) => {
              getItemData(lockerItemInfo.item_info.is_lock).locker_price = e;
              setLockerPrice(e);
              setLocketInputPrice(e);
            }}
            payInfoListAction={payInfoListAction}
            onChangeCallback={(e) => {
              lockerItemInfo.item_info.dis = e;
            }}
            paramLock={lockerItemInfo.item_info.is_lock}
            paramUse={true}
            discountValue={lockerItemInfo.item_info.dis}
            canChangePrice
            isAddPay={isAddPay}
            originPrice={lockerPriceOrigin}
          />
        )}
      </div>

      {isSubs ? (
        <div>
          <div style={{ display: "flex", marginTop: "0.5rem" }}>
            <PriceDiscountInput
              baseInfo={{
                tabTitle: tabInfoParam.title,
                tabIdx: tabInfoParam.idx,
                type: memberInputState.tab_info.title + "락커(일할) (" + (ord + 1) + ")",
                typeKey: lockerItemInfo.view_id + "(일할)",
                orderIdx: ord + 4,
                payOrder: ord + 5,
                label: "일할금액",
                item_type:
                  "락커/일할금액/금액/" +
                  getOptionData(lockerItemInfo.item_info.is_lock) +
                  "/" +
                  familyKey,
              }}
              standardPrice={lockerItemInfo.item_info.subs_options.prorate_price}
              payInfoListAction={payInfoListAction}
              onChangeCallback={(e) => {
                lockerItemInfo.item_info.subs_options.prorate_dis = e;
                setLockerProrateDis(e);
              }}
              paramUse={true}
              paramLock={lockerItemInfo.item_info.is_lock}
              discountValue={lockerProrateDis}
            />
          </div>
          <div style={{ display: "flex", marginTop: "0.5rem" }}>
            <PriceDiscountInput
              baseInfo={{
                tabTitle: tabInfoParam.title,
                tabIdx: tabInfoParam.idx,
                // orderStartIdx: membershipIdx,
                type: memberInputState.tab_info.title + "락커(선납) (" + (ord + 1) + ")",
                typeKey: lockerItemInfo.view_id + "(선납)",
                orderIdx: ord + 4,
                payOrder: ord + 5,
                label: "선납금",
                item_type:
                  "락커/선납금/금액/" +
                  getOptionData(lockerItemInfo.item_info.is_lock) +
                  "/" +
                  familyKey,
              }}
              standardPrice={lockerItemInfo.item_info.subs_options.pre_price}
              payInfoListAction={payInfoListAction}
              onChangeCallback={(e) => {
                lockerItemInfo.item_info.subs_options.pre_dis = e;
                setLockerPreDis(e);
              }}
              paramUse={true}
              paramLock={lockerItemInfo.item_info.is_lock}
              discountValue={lockerPreDis}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default LockerListItem;
