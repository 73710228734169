import { simpleAlert, SubLine } from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import ModalBtnModeGroup from "components/ModalBtnModeGroup/ModalBtnModeGroup";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  branchSettingAction,
  loadPolicyStore,
  loadSubScriptionItemsStore,
} from "../../Module/actions";
import post_api from "../../Module/api/PostApi";
import BoardingSetting from "../CommonSub/BoardingSetting";
import ETCSetting from "../CommonSub/ExtraSetting";
import ExpiredSetting from "./ExpiredSetting";
import MembershipSetting from "./MembershipSetting";
import SideItemSetting from "./SideItemSetting";

const ItemInfo = ({ restData, itemOptions, onOptionChange, isEditMode }) => {
  const textLabelWidth = "6rem";

  const [salesStatus, setSalesStatus] = useState([]);

  useEffect(() => {
    switch (restData.base_info.sales_status) {
      case "판매대기":
        setSalesStatus(["판매대기", "판매중"]);
        break;
      case "판매중":
        setSalesStatus(["판매중", "판매종료"]);
        break;
      case "판매종료":
        setSalesStatus(["판매중", "판매종료"]);
        break;
      default:
        setSalesStatus([]);
        break;
    }
  }, [restData]);

  return (
    <div>
      <div
        style={{
          display: "flex",
        }}
      >
        <ComboBox
          labelWidth={textLabelWidth}
          comboItemWidth={"8.75rem"}
          // inputRef={packageInfoRef}
          labelText="판매 상태"
          data={salesStatus}
          defaultValue={restData.base_info.sales_status}
          onChangeCallback={(e) => {
            restData.base_info.sales_status = e.target.value;
          }}
        />
        <CheckBox
          marginTop="0rem"
          labelWidth={textLabelWidth}
          labelText="판매 제한"
          defaultValue={restData.base_info.sales_limit}
          onChangeCallback={(e) => {
            restData.base_info.sales_limit = e.checked;
          }}
        />
      </div>
      <SubLine />
      <TextField
        required
        labelWidth={textLabelWidth}
        textBoxWidth={"30rem"}
        labelText="상품명"
        defaultValue={restData.item_name}
        onChangeCallback={(e) => {
          restData.item_name = e;
        }}
        disabled={isEditMode}
      />
      <ComboBox
        marginTop="0.5rem"
        labelWidth={textLabelWidth}
        comboItemWidth={"10.75rem"}
        // inputRef={packageInfoRef}
        labelText="옵션"
        data={itemOptions}
        defaultValue={restData.mbshp_option}
        onChangeCallback={(e) => {
          restData.mbshp_option = e.target.value;
          onOptionChange(e.target.value);
        }}
        disabled={isEditMode}
      />
    </div>
  );
};

const SettingContents = ({ modalParam, onModalDone }) => {
  const isEditMode = modalParam.mode === "edit";
  const canSelectFamilyOpt = modalParam.canSelectFamilyOpt;
  const canSelectPassOpt = modalParam.canSelectPassOpt;
  const storesDispatch = useDispatch();
  // const itemOptions = ["일반권", "시간권", "패스권"];
  const [itemOptions, setItemOptions] = useState([""]);

  const restData = useSelector((state) => state.branch_set_state.branch_sub_paid_info_state);
  const branchPolicy = useSelector((state) => state.branch_set_state.branch_policy_set_state);

  const [loading, setLoading] = useState(false);
  const [mbshpOption, setMbshpOption] = useState(restData.mbshp_option);
  const [period, setPeriod] = useState(0);
  const [preMonth, setPreMonth] = useState(0);
  const [totalPrice, setTotalPrice] = useState({
    // Total 금액
    mbshp: 0,
    cloth: 0,
    gx: 0,
    locker: 0,
  });
  const [totalSubsPrice, setTotalSubsPrice] = useState({
    // Total 월 구독료
    mbshp: 0,
    cloth: 0,
    gx: 0,
    locker: 0,
  });
  const [combine, setCombine] = useState({
    // 상품 결합
    mbshp: false,
    cloth: false,
    gx: false,
    locker: false,
  });

  useEffect(() => {
    if (modalParam.branchId !== "") {
      storesDispatch(loadSubScriptionItemsStore(modalParam.branchId, modalParam.itemId));
      storesDispatch(loadPolicyStore(modalParam.branchId));
    }

    return () => {
      storesDispatch(branchSettingAction.loadSubScriptionItemsReset());
    };
  }, [loadSubScriptionItemsStore, storesDispatch]);

  useEffect(() => {
    setPeriod(restData.stores.data.subs_items[0].subs_options.subs_period_month);
    setPreMonth(restData.stores.data.subs_items[0].subs_options.pre_month);
    setMbshpOption(restData.stores.data.subs_items[0].mbshp_option);
    updateItemOptions(restData.stores.erp_setting);
  }, [restData]);

  const updateItemOptions = (erpSetInfo) => {
    const _itemOptions = ["일반권"];
    if (erpSetInfo) {
      // erpSetInfo 값이 정상일 때
      if (erpSetInfo.mbshp_pass_branch_list.find((x) => x.branch_id === modalParam.branchId)) {
        _itemOptions.push("패스권");
      }
      if (erpSetInfo.mbshp_time_branch_list.find((x) => x.branch_id === modalParam.branchId)) {
        _itemOptions.push("시간권");
      }
    }
    setItemOptions(_itemOptions);
  };

  const validation = () => {
    let res = "";

    if (!isEditMode) {
      if (!restData.stores.data.subs_items[0].item_name) {
        res += "· 상품명\n";
      }
      if (!restData.stores.data.subs_items[0].subs_options.subs_period_month) {
        res += "· 약정기간\n";
      }
      // if (!restData.stores.data.subs_items[0].subs_options.pre_month) {
      //   res += "· 선납기간\n";
      // }
      if (!restData.stores.data.subs_items[0].subs_options.mbshp_subs_price) {
        res += "· 회원권 월 구독료\n";
      }
      if (!restData.stores.data.subs_items[0].subs_options.cloth_subs_price) {
        res += "· 운동복 월 구독료\n";
      }
      if (!restData.stores.data.subs_items[0].subs_options.gx_subs_price) {
        res += "· GX 월 구독료\n";
      }
      if (!restData.stores.data.subs_items[0].subs_options.locker_subs_price) {
        res += "· 락커 월 구독료\n";
      }

      if (
        restData.stores.data.subs_items[0].is_combine_pt &&
        !restData.stores.data.subs_items[0].pt_session
      ) {
        res += "· PT 세션\n";
      }
      if (
        restData.stores.data.subs_items[0].is_combine_pt &&
        !restData.stores.data.subs_items[0].pt_per_price
      ) {
        res += "· PT 회당 가격\n";
      }

      if (restData.stores.data.subs_items[0].mbshp_option === "시간권") {
        if (!restData.stores.data.subs_items[0].pass_from_time) {
          res += "· 출입 가능 시간 - 시작시간\n";
        }
        if (!restData.stores.data.subs_items[0].pass_end_time) {
          res += "· 출입 가능 시간 - 종료시간\n";
        }
      } else if (restData.stores.data.subs_items[0].mbshp_option === "패스권") {
        if (!restData.stores.data.subs_items[0].pass_cnt) {
          res += "· 월 출입 횟수\n";
        }
      }
    }

    return res;
  };

  const onClickSave = () => {
    const validationRes = validation();
    if (validationRes !== "") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="필수값을 모두 입력해주셔야 합니다." />
              <br />
              <br />
              <Label labelText={validationRes} whiteSpace="pre-wrap" />
            </>
          ),
          // width: "50rem",
          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="저장하시겠습니까?" />
            </>
          ),
          // width: "50rem",
          onBtnYes: () => {
            onSaveEvent();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const onSaveEvent = async () => {
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    restData.stores.data.branch_id = modalParam.branchId;
    console.log(restData.stores.data);
    const isDebug = false;
    if (isDebug) {
      const formData = new FormData();
      formData.append("item_info", JSON.stringify(restData.stores.data));
      const postApi = isEditMode ? post_api.updateSubScriptApi() : post_api.newSubScriptApi();

      await HttpInstance.post(postApi, formData, config)
        .then((res) => {
          console.log(res);
          if (res.data.msg) {
            simpleAlert(storesDispatch, "ERROR", res.data.msg);
          } else {
            onModalDone(modalParam.refreshStore);
          }
        })
        .catch((err) => {
          console.log(err);
          simpleAlert(
            storesDispatch,
            "ERROR",
            err.response.data ? err.response.data.msg : err.code,
          );
          // onModalDone(modalParam.refreshStore);
        });
    }
  };

  const onOptionChange = (e) => {
    setMbshpOption(e);
  };

  return (
    <div style={{}}>
      {loading && <Loading />}

      <ModalBtnModeGroup mode={modalParam.mode} onClickEvent={onClickSave} />
      <ItemInfo
        restData={restData.stores.data.subs_items[0]}
        itemOptions={itemOptions}
        onOptionChange={onOptionChange}
        isEditMode={isEditMode}
      />
      <ExpiredSetting
        branchPolicy={branchPolicy.stores.data.contents}
        restData={restData.stores.data.subs_items[0]}
        mbshpOption={mbshpOption}
        setPeriod={setPeriod}
        setPreMonth={setPreMonth}
        isEditMode={isEditMode}
      />
      <MembershipSetting
        branchPolicy={branchPolicy.stores.data.contents}
        restData={restData.stores.data.subs_items[0]}
        mbshpOption={mbshpOption}
        period={period}
        preMonth={preMonth}
        isEditMode={isEditMode}
        totalPrice={totalPrice}
        setTotalPrice={setTotalPrice}
        totalSubsPrice={totalSubsPrice}
        setTotalSubsPrice={setTotalSubsPrice}
        combine={combine}
      />
      <SideItemSetting
        branchPolicy={branchPolicy.stores.data.contents}
        restData={restData.stores.data.subs_items[0]}
        mbshpOption={mbshpOption}
        period={period}
        preMonth={preMonth}
        isEditMode={isEditMode}
        totalPrice={totalPrice}
        setTotalPrice={setTotalPrice}
        totalSubsPrice={totalSubsPrice}
        setTotalSubsPrice={setTotalSubsPrice}
        combine={combine}
        setCombine={setCombine}
      />
      <ETCSetting
        restData={restData.stores.data.subs_items[0]}
        hqPolicy={restData.stores.data.hq_policy}
        mbshpOption={mbshpOption}
        isEditMode={isEditMode}
        canSelectFamilyOpt={canSelectFamilyOpt}
      />
      <BoardingSetting
        branchPolicy={branchPolicy.stores.data.contents}
        restData={restData.stores.data.subs_items[0]}
      />
    </div>
  );
};
export default SettingContents;
