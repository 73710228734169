import IconButton from "components/Button/IconButton";
import CardFixed from "components/Card/CardFixed";
import { restEmpty, simpleAlert, SubLine } from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import PriceDiscountInput from "pages/ERP/MemberRegister/Common/Etc/PriceDiscountInput";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import SrcMembershipInfo from "./PageTables/SrcMembershipInfo";
import SrcPersonalInfo from "./PageTables/SrcPersonalInfo";
import SrcPtInfo from "./PageTables/SrcPtInfo";

const AssignSrcContents = ({
  payInfoListAction,
  addPayInfoState,
  assignViewState,
  assigner,
  assignee,
  dstMembershipInfoRef,
  dstPtInfoRef,
  setAccessMethod,
  setIsIncludeServicePt,
  setSumDay,
}) => {
  const storesDispatch = useDispatch();

  const [isSomePtCheck, setIsSomePtCheck] = useState(false);
  const [assignDateIdx, setAssignDateIdx] = useState(0);
  const [isSomeCheck, setIsSomeCheck] = useState(false);
  const [csTeam, setCsTeam] = useState({ cs_team_info_list: [], cs_team_name_list: [] });
  const [checkedMbshpIds, setCheckedMbshpIds] = useState([]);

  useEffect(() => {
    if (isSomeCheck) {
      addPayInfoState(
        "회원권 양도비",
        assignViewState.assign_price.assign_mbsh_price,
        0,
        "회원권 양도비/없음/없음/없음/없음",
      );
    } else {
      addPayInfoState("회원권 양도비", 0, 0, "회원권 양도비/없음/없음/없음/없음");
    }
  }, [isSomeCheck]);

  useEffect(() => {
    if (isSomePtCheck) {
      addPayInfoState(
        "PT 양도비",
        assignViewState.assign_price.assign_pt_price,
        1,
        "PT 양도비/없음/없음/없음/없음",
      );
    } else {
      addPayInfoState("PT 양도비", 0, 1, "PT 양도비/없음/없음/없음/없음");
    }
  }, [isSomePtCheck]);

  //양도 상담자 콤보 GET (양수자 지점 기준)
  const getStaffListOfBranch = async () => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_USER_SERVER + "/change/branch_view/cs_staff",
        {
          params: {
            branch_id: assignee.branch_info.branch_id,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setCsTeam(res.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  //초기에 한번만 GET 필요
  useEffect(() => {
    getStaffListOfBranch();
  }, [assignee.branch_info.branch_id]);

  return (
    <CardFixed
      midDom={
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Label labelText="양도사항" fontSize="1.125rem" fontWeight="bold" />
            <ComboBox
              required
              labelWidth={"3.5rem"}
              comboItemWidth={"12rem"}
              marginRight="0rem"
              labelText="상담자"
              data={csTeam.cs_team_name_list}
              defaultValue={assignViewState.cs_staff.staff_name}
              onChangeCallback={(e) => {
                const found = csTeam.cs_team_info_list.find(
                  (el) => el.staff_name === e.target.value,
                );
                const staffInfo = {
                  staff_id: found.staff_id,
                  staff_name: found.staff_name,
                  staff_pos: found.position.org_name,
                  staff_rank: found.rank.org_name,
                };
                assignViewState.cs_staff = staffInfo;
              }}
            />
          </div>
          <div style={{ display: "flex", marginTop: "1rem" }}>
            <IconButton type="border" />
            <div style={{ width: "0.5rem" }}></div>
            <SubTitle titleText="양도자 회원정보" />
          </div>
          <SrcPersonalInfo assigner={assigner} />
          <SubLine />
          <div
            style={{ display: "flex", justifyContent: "space-between", margin: "1rem 0 1rem 0 " }}
          >
            <div style={{ display: "flex" }}>
              <IconButton type="border" />
              <div style={{ width: "0.5rem" }}></div>
              <SubTitle titleText="회원권 목록" />
              <div style={{ width: "1rem" }}></div>
              <Label
                labelText={
                  "※ 구독형 회원권, 시간권 또는 패스권 옵션의 회원권은 양도가 불가능합니다. "
                }
                color={"red"}
                fontSize={"0.75rem"}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.3rem",
              }}
            >
              <IconButton id="desc" type="tooltip" width="1.1rem" />
              <ReactTooltip
                anchorId="desc"
                place="top"
                variant="dark"
                style={{ borderRadius: "8px" }}
                content={
                  <div style={{ textAlign: "left", fontSize: "0.75rem" }}>
                    <div>
                      <span style={{ fontWeight: "bold" }}>* 모든 추가기간 포함 : </span>
                      <span style={{ color: "#BBBBBB", fontSize: "0.7rem" }}>
                        모든 행사 기간을 포함하여 양도합니다.
                      </span>
                    </div>
                    <div>
                      <span style={{ fontWeight: "bold" }}>* 기본 서비스 기간 제외 : </span>
                      <span style={{ color: "#BBBBBB", fontSize: "0.7rem" }}>
                        상품 설정에서 추가된 서비스 기간을 제외하고 양도합니다.
                      </span>
                    </div>
                    <div>
                      <span style={{ fontWeight: "bold" }}>* 행사 추가기간 제외 : </span>
                      <span style={{ color: "#BBBBBB", fontSize: "0.7rem" }}>
                        회원권 구매 시 추가한 행사 기간을 제외하고 양도합니다.
                      </span>
                    </div>
                    <div>
                      <span style={{ fontWeight: "bold" }}>* 모든 추가기간 제외 : </span>
                      <span style={{ color: "#BBBBBB", fontSize: "0.7rem" }}>
                        상품 설정 및 회원권 구매 시 추가된 모든 행사기간을 제외하고 양도합니다.
                      </span>
                    </div>
                  </div>
                }
              />

              <ComboBox
                labelMarginTop="0.15rem"
                labelWidth={"8.5rem"}
                comboItemWidth={"12rem"}
                labelMarginRight="0rem"
                marginRight="0rem"
                labelText="회원권 추가기간 제외"
                defaultValue={assignViewState.src_option.mbshp_event_include_list[0]}
                data={assignViewState.src_option.mbshp_event_include_list}
                onChangeCallback={(e) => {
                  //회원권에 체크된거 모두 해제
                  const dataList = assignViewState.src_member_info.membership_data_list;
                  for (let i = 0; i < dataList.length; i++) {
                    dataList[i].selected = false;
                  }
                  //양수리스트에도 반영해야.. -> 초기화
                  dstMembershipInfoRef.current.setSelectRow([]);

                  setSumDay(0);

                  const idx = assignViewState.src_option.mbshp_event_include_list.findIndex(
                    (el) => el === e.target.value,
                  );
                  setAssignDateIdx(idx);
                  assignViewState.src_option.mbshp_event_include = e.target.value;
                  assignViewState.src_member_info.is_assign_card = false;
                  setAccessMethod({
                    method: "",
                    cardNum: "",
                  });
                  payInfoListAction("pop", "회원권 양도비");
                }}
              />
            </div>
          </div>

          {/*회원권목록 테이블*/}
          <SrcMembershipInfo
            dstMembershipInfoRef={dstMembershipInfoRef}
            dstPtInfoRef={dstPtInfoRef}
            serviceViewState={assigner.membership_data_list}
            setIsSomeCheck={setIsSomeCheck}
            setIsSomePtCheck={setIsSomePtCheck}
            assignViewState={assignViewState}
            setAccessMethod={setAccessMethod}
            assignDateIdx={assignDateIdx}
            setSumDay={setSumDay}
            checkedMbshpIds={checkedMbshpIds}
            setCheckedMbshpIds={setCheckedMbshpIds}
          />
          {isSomeCheck ? (
            <div style={{ display: "flex", alignItems: "center", marginTop: "0.3rem" }}>
              <CheckBox
                labelText="회원권 양도비"
                defaultValue={true}
                disabled
                marginTop="0rem"
                labelWidth="6rem"
                transform="scale(1.2)"
                labelMarginLeft="0.3rem"
                marginRight="0rem"
              />
              <div style={{ marginLeft: "auto" }}>
                <PriceDiscountInput
                  baseInfo={{
                    label: "금액",
                    typeKey: "회원권 양도비",
                    type: "회원권 양도비",
                    item_type: "회원권 양도비/없음/없음/없음/없음",
                  }}
                  standardPrice={assignViewState.assign_price.assign_mbsh_price}
                  payInfoListAction={payInfoListAction}
                  onChangeCallback={(e) => {}}
                  paramUse={true}
                  assignWhat={"mbshp"}
                  assignViewState={assignViewState}
                />
              </div>
            </div>
          ) : (
            ""
          )}

          {assigner.pt_list.length !== 0 ? (
            <>
              <SubLine />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IconButton type="border" />
                  <div style={{ width: "0.5rem" }}></div>
                  <SubTitle titleText="PT 목록" />
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "0.3rem" }}>
                  <CheckBox
                    labelText="서비스 세션 제외"
                    transform="scale(1.0)"
                    marginTop="0rem"
                    marginRight={"0rem"}
                    labelMarginRight={"0rem"}
                    onChangeCallback={(e) => {
                      setIsIncludeServicePt(e.checked);
                      assignViewState.src_option.pt_include_service_session = e.checked;
                    }}
                  />
                  <IconButton id="desc-1" type="tooltip" width="1.2rem" height="1.2rem" />
                  <ReactTooltip
                    anchorId="desc-1"
                    place="top"
                    variant="dark"
                    style={{ borderRadius: "8px" }}
                    content={
                      <div style={{ fontSize: "0.75rem" }}>
                        <div>PT 등록 시 서비스 세션으로 입력된</div>
                        <div>횟수를 제외하고 양도합니다.</div>
                      </div>
                    }
                  />
                </div>
              </div>
              <SrcPtInfo
                dstMembershipInfoRef={dstMembershipInfoRef}
                ptInfoList={assigner.pt_list}
                dstPtInfoRef={dstPtInfoRef}
                setIsSomePtCheck={setIsSomePtCheck}
                checkedMbshpIds={checkedMbshpIds}
                srcMbshpList={assignViewState.src_member_info.membership_data_list}
              />
              {isSomePtCheck ? (
                <div style={{ display: "flex", alignItems: "center", marginTop: "0.3rem" }}>
                  <CheckBox
                    labelText="PT 양도비"
                    defaultValue={true}
                    disabled
                    marginTop="0rem"
                    labelWidth="6rem"
                    transform="scale(1.2)"
                    labelMarginLeft="0.3rem"
                    marginRight="0rem"
                  />
                  <div style={{ marginLeft: "auto" }}>
                    <PriceDiscountInput
                      baseInfo={{
                        label: "금액",
                        typeKey: "PT 양도비",
                        type: "PT 양도비",
                        item_type: "PT 양도비/없음/없음/없음/없음",
                      }}
                      standardPrice={assignViewState.assign_price.assign_pt_price}
                      payInfoListAction={payInfoListAction}
                      onChangeCallback={(e) => {}}
                      paramUse={true}
                      assignWhat={"pt"}
                      assignViewState={assignViewState}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </div>
      }
      isLoading={false}
      rootStyle={{
        margin: "0 1rem 1rem 1rem",
        borderRadius: "10px",
        backgroundColor: "#fff",
        border: "1px solid #e8ebf2",
        background:
          "transparent linear-gradient(117deg, #e8ebf2 0%, #f2f3f7 100%) 0% 0% no-repeat padding-box",
      }}
    />
  );
};
export default AssignSrcContents;
