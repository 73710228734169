import { useEffect, useState } from "react";

import DeviceAllocateModal from "./DeviceAllocateModal";

import ReadyDeviceModal from "./ReadyDeviceModal";

import ScheduleAllocModal from "./ScheduleAllocModal";

import * as lodash from "lodash";
import HolidayAddModal from "./HolidayAddModal";
import HolidayListView from "./HolidayListView";
import ScheduleListModal from "./ScheduleListModal";
import ScheduleTimeEditModal from "./ScheduleTimeEditModal";

const DeviceMangeModalIndex = ({ modalSelect, modalParam, onModalDone }) => {
  const [paramData, setParamData] = useState(modalParam.modalParam);

  useEffect(() => {
    const editData = lodash.cloneDeep(modalParam.modalParam);
    setParamData(editData);
    return () => {};
  }, []);
  if (modalSelect === "공휴일목록") {
    return <HolidayListView modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "공휴일추가") {
    return <HolidayAddModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "스케줄목록") {
    return <ScheduleListModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "스케줄수정") {
    return <ScheduleAllocModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "대기중장치보기") {
    return <ReadyDeviceModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "장치할당") {
    return <DeviceAllocateModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "스케줄시간수정") {
    return <ScheduleTimeEditModal modalParam={paramData} onModalDone={onModalDone} />;
  } else {
    return <div>current ELSE</div>;
  }
};
export default DeviceMangeModalIndex;
