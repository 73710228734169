import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import CardFixed from "components/Card/CardFixed";
import { tabBorder, tabStyle } from "components/CommonComp";
import Loading from "components/Loading/Loading/Loading";
import TabPanel from "components/TabControl/TabPanel";
import { globalModalOff } from "modules/actions/Modal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import UnpaidExemptTab from "./UnpaidExemptTab";
import UnpaidStatusTab from "./UnpaidStatusTab";
import { useUnpaidManageStore } from "./modules/store/unpaidManageState";

const UnpaidManageContent = () => {
  const storesDispatch = useDispatch();
  // 전역 상태 관리
  const { unpaidManageLoading: loading } = useUnpaidManageStore();

  const [tabIndex, setTabIndex] = useState(0);

  const onModalDone = (res) => {
    storesDispatch(globalModalOff());
  };

  const openModal = (order, param) => {
    // let Modal;
    // if (order === "save") {
    //   Modal = <SaveConditionModal modalParam={param} onModalDone={onModalDone} />;
    // } else if (order === "load") {
    //   Modal = <LoadConditionModal modalParam={param} onModalDone={onModalDone} />;
    // }
    // storesDispatch(
    //   globalModalOn({
    //     show: true,
    //     title: param.title,
    //     Content: Modal,
    //   }),
    // );
  };

  const onChangeTabIndex = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <>
      {loading && <Loading />}
      <CardFixed
        midDom={
          <div>
            {/* <Box sx={{ bgcolor: "background.paper" }}> */}
            <Tabs
              value={tabIndex}
              onChange={onChangeTabIndex}
              textColor="inherit"
              sx={{ minHeight: "40px" }}
              TabIndicatorProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0)" } }}
            >
              <Tab sx={{ minHeight: "40px" }} label="미납 현황" style={tabStyle(0, tabIndex)} />
              <Tab
                sx={{ minHeight: "40px" }}
                label="미납금 제외 이력"
                style={tabStyle(1, tabIndex, "10rem")}
              />
            </Tabs>
            {/* </Box> */}

            <SwipeableViews
              index={tabIndex}
              onChangeIndex={onChangeTabIndex}
              style={tabBorder}
              animateTransitions={false}
            >
              <TabPanel value={tabIndex} index={0}>
                <UnpaidStatusTab />
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <UnpaidExemptTab />
              </TabPanel>
            </SwipeableViews>
          </div>
        }
        isLoading={false}
        rootStyle={
          {
            // width: "80.437rem",
            // height: "74.875rem",
          }
        }
      />
    </>
  );
};
export default UnpaidManageContent;
