import { generateObjectZeroId, SubLine } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import NormalBtn from "components/NewButton/NormalBtn";
import { useAccessHistList } from "hooks/apis/branch/useAccessMonitor";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { utils, writeFile } from "xlsx";
import { useAccessMonitoringStore } from "../modules/store/monitoringManageState";
import AccessHistResult from "./AccessHistResult";
import AccessSearchCard from "./AccessSearchCard";

const AccessHistView = ({ hqBranchDoorCombo }) => {
  const storesDispatch = useDispatch();
  // 전역 상태 관리
  const { setLoading, searchParams, setPage, page, resetSearchParams } =
    useAccessMonitoringStore().returnStoreByType("accessHist");

  // 페이지 상태 관리
  const [searchResult, setSearchResult] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [isExcel, setIsExcel] = useState(false);

  // API 상태 관리
  const [isGetAccessHistList, setIsGetAccessHistList] = useState(false);
  const queryData = { page: page, searchParams: searchParams, isExcel: isExcel };

  // [GET] 출입 이력 가져오기
  const { isFetching, data: accessHist } = useAccessHistList(
    queryData,
    isGetAccessHistList,
    setIsGetAccessHistList,
    setLoading,
  );

  /** isExcel: 엑셀 다운로드 여부, page: 페이지네이션 */
  const onClickSearch = async (isExcel, page) => {
    setPage(page);
    setIsExcel(isExcel);
    // 출입 이력 리스트 받아오기
    setIsGetAccessHistList(true);
  };

  //검색결과를 엑셀파일로 다운로드 받는 함수
  const exportToExcel = (data) => {
    //data는 검색POST 요청 결과 res.data.data
    const newData = [];
    for (let i = 0; i < data.length; i++) {
      const row = {};
      row.출입일시 = data[i].access_date;
      row.본부명 = data[i].hq_info.hq_name;
      row.지점명 = data[i].branch_info.branch_name;
      row.출입문명 = data[i].door_info.door_name;
      row.카드번호 = data[i].card_no;
      row.시리얼번호 = data[i].card_serial_no;
      row.사용자그룹 = data[i].user_group;
      row.사용자명 = data[i].cur_user.user_name;
      row.회원번호 = data[i].cur_user.member_no;
      row.사번 = data[i].cur_user.sabun;
      row.출입여부 = data[i].is_success ? "출입 성공" : "실패";
      row.비고 = data[i].remarks;
      newData.push(row);
    }

    const worksheet = utils.json_to_sheet(newData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, "출입이력.xlsx");
  };

  // API 받아온 데이터 세팅
  useEffect(() => {
    // 출입 이력 리스트
    if (accessHist) {
      if (isExcel) {
        exportToExcel(accessHist.data.data);
      } else {
        setSearchResult(accessHist.data.data);
        setTotalPage(accessHist.data.total_page);
      }
    }
  }, [accessHist, isExcel]);

  // 로딩 상태
  useEffect(() => {
    if (isFetching) {
      setLoading(isFetching);
    }
  }, [isFetching]);

  // 첫 페이지 진입 시 검색 결과를 가져오기
  useEffect(() => {
    setIsGetAccessHistList(true);
  }, []);

  return (
    <div>
      <SearchCard
        searchParams={searchParams}
        resetSearchParams={resetSearchParams}
        onClickSearch={onClickSearch}
        hqBranchDoorCombo={hqBranchDoorCombo}
      />

      <SearchResultCard
        searchResult={searchResult}
        onClickSearch={onClickSearch}
        curPage={page}
        totalPage={totalPage}
      />
    </div>
  );
};
export default AccessHistView;

const SearchCard = ({ searchParams, resetSearchParams, onClickSearch, hqBranchDoorCombo }) => {
  const storesDispatch = useDispatch();

  const validation = () => {
    if (
      searchParams.hq_info.hq_id === generateObjectZeroId() ||
      searchParams.branch_info.branch_id === generateObjectZeroId()
    ) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="본부와 지점은 필수 검색조건입니다." />,
          onBtnOk: () => {},
        }),
      );
    } else {
      onClickSearch(false, 1);
    }
  };

  return (
    <div>
      <AccessSearchCard onClickSearch={onClickSearch} hqBranchDoorCombo={hqBranchDoorCombo} />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "0.5rem",
          gap: "0.3rem",
        }}
      >
        <NormalBtn
          name="초기화"
          theme="white"
          width="6.25rem"
          onClick={() => resetSearchParams()}
        />
        <NormalBtn
          name="검색"
          width="6.25rem"
          onClick={() => {
            //TO-DO 템프 삭제하고 주석 해제해야
            // onClickSearch(false, 1);
            validation();
          }}
        />
      </div>
      <SubLine />
    </div>
  );
};

const SearchResultCard = ({ searchResult, onClickSearch, curPage, totalPage }) => {
  return (
    <div style={{ marginTop: "1.2rem" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "0.5rem",
        }}
      >
        <NormalBtn
          name="엑셀 다운로드"
          theme="green"
          onClick={() => {
            onClickSearch(true, 1);
          }}
        />
      </div>
      <AccessHistResult
        searchResult={searchResult}
        curPage={curPage}
        onClickSearch={onClickSearch}
        totalPage={totalPage}
      />
    </div>
  );
};
