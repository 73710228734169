import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

// import { RootPanel, MainPanel, MainTopPanel, MainBottomPanel } from "components/Panels/Panels";
// import Sidebar from "components/Sidebar/Sidebar";
// import Navbar2 from "components/NavMenu/Navbar2";
import { dataEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { HttpInstance } from "lib/HttpLib";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import BranchSetting from "./BranchSetting";
import BranchSetIndex from "./Modals/BranchSetIndex";

const SettingWrapper = () => {
  const params = useParams();
  const [tabIndex, setTabIndex] = useState(0);
  const [branchId, setBranchId] = useState("");
  const [branchInfo, setBranchInfo] = useState(null);
  const storesDispatch = useDispatch();

  useEffect(() => {
    if (!params.targetId) {
      HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/branch_set/branch_selector", {
        params: {},
      })
        .then((res) => {
          if (res.data.data.branch_list.length > 0) {
            const branchData = res.data.data.branch_list[0];
            params.targetId = branchData.branch_id;
            setBranchId(branchData.branch_id);
            setBranchInfo({ branch_id: branchData.branch_id, branch_name: branchData.branch_name });
          }
        })
        .catch((err) => {
          console.log(err);
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        });
    }
  }, []);

  // useEffect(() => {
  //   setBranchId(params.targetId);

  //     HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/branch_set/policy_view", { params: {
  //       branch_id: params.targetId
  //     }, })
  //   .then((res) => {
  //   if (!restEmpty(res.data.data)) {

  //   }
  //   })
  //   .catch((err) => {
  //   console.log("err");
  // simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR" );
  //   });
  // }, []);

  // const restData = useSelector((state) => state.branch_set_state.branch_policy_set_state);
  // console.log("wrapper restData");
  // console.log(restData);

  // useEffect(() => {
  //   console.log("!!!!!" + params.targetId);
  //   setBranchId(params.targetId);
  // }, [params.targetId]);

  const openModal = (select, param, onLocalDone) => {
    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,
        Content: (
          <BranchSetIndex
            modalSelect={select}
            modalParam={param}
            onModalDone={onLocalDone ? onLocalDone : onModalDone}
          />
        ),
      }),
    );
  };

  const onModalDone = (refreshStore) => {
    storesDispatch(globalModalOff());
    if (!dataEmpty(refreshStore)) {
      refreshStore();
    }
  };

  const onClickOpenModal = (order, param, onLocalDone) => {
    if (order !== "") {
      openModal(order, param, onLocalDone);
    }
  };

  const onChangeBranch = (branchId, branchInfo) => {
    // storesDispatch(loadHqBase(hqId));  // 갱신 로직 필요
    setBranchId(branchId);
    setBranchInfo(branchInfo);
  };

  return (
    <PageFrame
      menuActive="지점 설정"
      parentMenu={{ index: true }}
      children={
        <div>
          <PageTitle titleText="지점 설정" />
          <BranchSetting
            onClickOpenModal={onClickOpenModal}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            branchId={branchId || params.targetId}
            selectedBranchInfo={branchInfo}
            onChangeBranch={onChangeBranch}
          />
        </div>
      }
    />
  );
};
export default SettingWrapper;
