import * as ActionTypes from "./action_type";

///MEMBER VIEW
export const loadSalaryList = (search_param) => {
  return {
    type: ActionTypes.salaryListAction,
    search_param: search_param,
  };
};

export const loadSalaryListSuccess = (data) => {
  return {
    type: ActionTypes.salaryListSuccessAction,
    payload: data,
  };
};

export const loadSalaryListFail = (data) => {
  return {
    type: ActionTypes.salaryListFailAction,
    payload: [],
  };
};

export const loadSalaryListReset = (data) => {
  return {
    type: ActionTypes.salaryListResetAction,
    payload: data,
  };
};
