import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";

const ApprovalButtonSet = ({
  mode,
  onClickSave,
  onClickCancel,
  onClickPrev,
  onClickNext,
  onClickMoveToList,
}) => {
  const btnStyleClass = btnStyles();

  if (mode === "new" || mode === "edit") {
    return (
      <div style={{ marginTop: "2rem" }}>
        <Button
          className={clsx({
            [btnStyleClass.btnRootWhite]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={onClickCancel}
        >
          취소
        </Button>
        <Button
          style={{ marginLeft: "0.5rem" }}
          className={clsx({
            [btnStyleClass.btnRootWhite]: true,
            [btnStyleClass.buttonType6]: true,
          })}
          onClick={() => {
            // onClickSave(false);
            onClickSave("임시저장");
          }}
        >
          임시저장
        </Button>
        <Button
          style={{ marginLeft: "0.5rem" }}
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={() => {
            // onClickSave(true);
            onClickSave("기안");
          }}
        >
          기안
        </Button>
      </div>
    );
  } else {
    return (
      <div style={{ marginTop: "2rem" }}>
        {/* <Button
          className={clsx({
            [btnStyleClass.btnRootWhite]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={onClickPrev}
        >
          이전
        </Button> */}
        <Button
          style={{ marginLeft: "0.5rem" }}
          className={clsx({
            [btnStyleClass.btnRootWhite]: true,
            [btnStyleClass.buttonType7]: true,
          })}
          onClick={onClickMoveToList}
        >
          목록으로
        </Button>
        {/* <Button
          style={{ marginLeft: "0.5rem" }}
          className={clsx({
            [btnStyleClass.btnRootWhite]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={onClickNext}
        >
          다음
        </Button> */}
      </div>
    );
  }
};
export default ApprovalButtonSet;
