import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { generateObjectZeroId, simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import TextArea from "components/LabelInput/TextArea";
import TextField from "components/LabelInput/TextField";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import SearchMember from "pages/CommonModal/Search/SearchMember";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const InsuranceReqForm = ({ baseState, mode, contentState, setContentState }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();

  const readOnly = mode === "approver" || mode === "unspent" || mode === "view";

  const [selectedMemberInfo, setSelectedMemberInfo] = useState(
    contentState && contentState.member_info,
  );

  const [, updateState] = useState(); // 강제 렌더링용
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    if (contentState.member_info) {
      setContentState({
        ...contentState,
      });
    } else {
      setContentState({
        ...contentState,
        branch_info: {
          branch_id: generateObjectZeroId(),
          branch_name: "",
        },
        member_info: {
          member_id: generateObjectZeroId(),
          member_no: "",
          member_name: "",
          phone: "",
        },
      });
    }
  }, [baseState.writer.hq_info.hq_name, baseState.writer.branch_info.branch_name]);

  const onModalDone = (info) => {
    // console.log(info);
    contentState.member_info.birth = info.base_info.birth;
    contentState.member_info.gender = info.base_info.gender;
    contentState.member_info.member_id = info.member_info.member_id;
    contentState.member_info.member_name = info.member_info.member_name;
    contentState.member_info.member_no = info.member_info.member_no;
    contentState.member_info.phone = info.base_info.phone;
    contentState.branch_info = info.branch_info;
    // setSelectedMemberInfo(memberInfo);
    forceUpdate();
    storesDispatch(globalModalOff());
  };

  const onClickSearch = () => {
    if (
      !baseState.writer.branch_info.branch_name ||
      !baseState.writer.hq_info.hq_name ||
      baseState.writer.branch_info.branch_name === "전체"
    ) {
      simpleAlert(storesDispatch, "알림", "지점을 선택해주세요.");
    } else {
      const param = {
        mode: "search",
        goToMemberView: onModalDone,
        // isFixedBranch: true,
        // hqBranchData: {
        //   branch_list: [[baseState.writer.branch_info.branch_name]],
        //   hq_list: [baseState.writer.hq_info.hq_name],
        //   target_list: [
        //     { hq_info: baseState.writer.hq_info, branch_list: [baseState.writer.branch_info] },
        //   ],
        // },
      };

      storesDispatch(
        globalModalOn({
          show: true,
          title: "회원 검색",
          Content: <SearchMember modalParam={param} />,
        }),
      );
    }
  };

  return (
    <div>
      <div style={{ marginTop: "1.5rem" }}>
        <Label labelText="대상 회원정보" fontSize="1rem" fontWeight="bold" justifyContent="left" />

        <div style={{ display: "flex", width: "100%", marginTop: "0.75rem" }}>
          <div style={{ width: "30%" }}>
            <TextField
              labelWidth="4rem"
              labelText="지점"
              fullWidth
              textMarginRight="0rem"
              defaultValue={contentState && contentState.branch_info.branch_name}
              disabled
            />
          </div>
          <div style={{ width: "30%", marginLeft: "3rem" }}>
            <TextField
              labelWidth="4rem"
              labelText="회원명"
              fullWidth
              textMarginRight="0rem"
              defaultValue={contentState && contentState.member_info.member_name}
              disabled
            />
          </div>
          <div style={{ display: "flex", width: "40%", marginLeft: "3rem" }}>
            <TextField
              labelWidth="4rem"
              labelText="연락처"
              fullWidth
              textMarginRight="0rem"
              defaultValue={contentState && contentState.member_info.phone}
              disabled
            />
            {mode === "new" || mode === "edit" ? (
              <Button
                style={{ marginLeft: "0.5rem" }}
                className={clsx({
                  [btnStyleClass.btnRootWhite]: true,
                  [btnStyleClass.buttonType6]: true,
                  [btnStyleClass.disabled]: readOnly,
                })}
                disabled={readOnly}
                onClick={() => {
                  onClickSearch();
                }}
              >
                검색
              </Button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <div style={{ marginTop: "1.5rem", marginBottom: "3.5rem" }}>
        <Label
          labelText="사고 경위"
          fontSize="1rem"
          fontWeight="bold"
          justifyContent="left"
          marginBottom="0.5rem"
        />
        <TextArea
          marginTop={"0.5rem"}
          labelMarginRight="0rem"
          fullWidth
          minRows={12}
          maxRows={12}
          defaultValue={contentState && contentState.circumstances}
          onChangeCallback={(e) => {
            contentState.circumstances = e.target.value;
          }}
          disabled={readOnly}
          required
        />
      </div>
    </div>
  );
};
export default InsuranceReqForm;
