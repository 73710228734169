import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import { forwardRef } from "react";

const ApprovalTable = forwardRef((props, ref) => {
  const { dataList, setDataList, modalParam } = props;

  const btnItemPop = (targetId) => {
    var array = [...dataList];
    const index = array.map((item) => item.staff_info.staff_id).indexOf(targetId);

    if (index !== -1) {
      array.splice(index, 1);
      modalParam.approval_info.approver_list.splice(index, 1);
      setDataList(array);
    }
  };

  const searchResultList = new searchResultTable(btnItemPop);

  return (
    <div style={{}}>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={dataList}
      />
    </div>
  );
});
export default ApprovalTable;

class searchResultTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onParentClickEvent = null;

  constructor(onItemDelete) {
    this.onParentClickEvent = onItemDelete;
  }

  onClickEvent = (data) => {
    this.onParentClickEvent(data);
  };
  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.staff_info.staff_pos}</CustomTableContents>
        <CustomTableContents>{data.staff_info.staff_name}</CustomTableContents>
        <CustomTableContentsEND align="center">
          <IconButton type="delete" onClick={() => this.onClickEvent(data.staff_info.staff_id)} />
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "직책", width: "40%" },
    { title: "직원명", width: "40%" },
    { title: "", width: "20%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
