export const search_subs_manage_state = {
  base_info: {
    phone: "",
  },
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  combo_item: {
    branch_hq_info_list: [
      // {
      //   branch_list: [
      //     {
      //       branch_id: "",
      //       branch_name: "",
      //     },
      //   ],
      //   hq_info: {
      //     hq_id: "",
      //     hq_name: "",
      //   },
      // },
    ],
    branch_list: [
      // [""]
    ],
    hq_list: [
      // ""
    ],
  },
  member_info: {
    member_id: "",
    member_name: "",
    member_no: "",
  },
  pay_req_date_from: "",
  pay_req_date_to: "",
  payment_state: "",
};
