export const stat_info_state = {
  cs_summary_info: {
    call_cnt: 0,
    tm_cnt: 0,
    visit_cnt: 0,
  },
  gx_age_data: [
    {
      age_type_1: 0,
      age_type_2: 0,
      age_type_3: 0,
      age_type_4: 0,
      age_type_5: 0,
    },
  ],
  gx_gender_data: [
    {
      man: 0,
      woman: 0,
    },
  ],
  gx_sales_count: [
    {
      ot_pt: 0,
      pt_repay: 0,
      work_in: 0,
    },
  ],
  gx_sales_type: [
    {
      ot_pt: 0,
      pt_repay: 0,
      work_in: 0,
    },
  ],
  mbshp_age_data: [
    {
      age_type_1: 0,
      age_type_2: 0,
      age_type_3: 0,
      age_type_4: 0,
      age_type_5: 0,
    },
  ],
  mbshp_gender_data: [
    {
      man: 0,
      woman: 0,
    },
  ],
  mbshp_join_way: [
    {
      cnt: 0,
      join_type: "",
    },
  ],
  mbshp_period_month: [
    {
      month_type_1: 0,
      month_type_2: 0,
      month_type_3: 0,
      month_type_4: 0,
      month_type_5: 0,
    },
  ],
  mbshp_period_time: [
    {
      am: 0,
      holiday: 0,
      pm: 0,
    },
  ],
  mbshp_re_join_way: [
    {
      cnt: 0,
      join_type: "",
    },
  ],
  mbshp_regtype_data: [
    {
      type_1: 0,
      type_2: 0,
      type_3: 0,
      type_4: 0,
    },
  ],
  pt_age_data: [
    {
      age_type_1: 0,
      age_type_2: 0,
      age_type_3: 0,
      age_type_4: 0,
      age_type_5: 0,
    },
  ],
  pt_gender_data: [
    {
      man: 0,
      woman: 0,
    },
  ],
  pt_sales_count: [
    {
      ot_pt: 0,
      pt_repay: 0,
      work_in: 0,
    },
  ],
  pt_sales_type: [
    {
      ot_pt: 0,
      pt_repay: 0,
      work_in: 0,
    },
  ],
  report_info_id: "",
};
