import { combineReducers } from "redux";
import * as ActionTypes from "../actions/action_type";

const listStateinitial = {
  stores: {
    data: [],
  },
};
const permission_state = (state = listStateinitial, action) => {
  switch (action.type) {
    case ActionTypes.getPermissionSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getPermissionFailAction:
    case ActionTypes.getPermissionResetAction:
      return { ...state, stores: listStateinitial.stores };
    default:
      return state;
  }
};

const hqViewStateinitial = {
  stores: {
    data: {
      package_list: [],
      hq_list: [],
    },
  },
};

const hq_view_state = (state = hqViewStateinitial, action) => {
  switch (action.type) {
    case ActionTypes.getHqViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getHqViewFailAction:
    case ActionTypes.getHqViewResetAction:
      return { ...state, stores: hqViewStateinitial.stores };
    default:
      return state;
  }
};

const hq_ip_allow_state = (state = listStateinitial, action) => {
  switch (action.type) {
    case ActionTypes.getIpAllowViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getIpAllowViewFailAction:
    case ActionTypes.getIpAllowViewResetAction:
      return { ...state, stores: listStateinitial.stores };
    default:
      return state;
  }
};

const defaultStateinitial = {
  stores: {
    data: {
      regular_pay_date: 0,
      branch_link_cnt: 0,
      branch_change_price: 0,
      point_give_rate: 0,
      point_use_rate: 0,
      point_min_use: 0,
      reg_path_list: [] /* 신규등록 가입경로 */,
      re_reg_path_list: [] /* 재등록 가입경로 */,
      purpose_list: [] /* 운동목적 */,
      mbshp_time_branch_list: [],
      mbshp_pass_branch_list: [],
      mbshp_time_branch_reject_list: [],
      mbshp_pass_branch_reject_list: [],
    },
  },
};
const policy_view_state = (state = defaultStateinitial, action) => {
  switch (action.type) {
    case ActionTypes.getPolicyViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getPolicyViewFailAction:
    case ActionTypes.getPolicyViewResetAction:
      return { ...state, stores: defaultStateinitial.stores };
    default:
      return state;
  }
};

export const erpSettingReducer = combineReducers({
  permission_state,
  hq_view_state,
  hq_ip_allow_state,
  policy_view_state,
});
