import { generateObjectZeroId } from "components/CommonLib/CommonLib";
import { create } from "zustand";

// 검색 조건 베이스
const baseSearchParams = {
  apply_doc: "",
  approve_status: "",
  base_info: {
    phone: "",
  },
  p1: "",
  p2: "",
  p3: "",
  branch_info: {
    branch_id: generateObjectZeroId(),
    branch_name: "",
  },
  combo_item: {
    branch_hq_info_list: [],
    branch_list: [],
  },
  doc_due_date_from: "",
  doc_due_date_to: "",
  elapsed_date_from: 0,
  elapsed_date_to: 0,
  member_info: {
    member_id: generateObjectZeroId(),
    member_name: "",
    member_no: "",
  },
  postpone_reg_date_from: "",
  postpone_reg_date_to: "",
  tm_from_date: "",
  tm_manager: {
    sabun: "",
    staff_id: generateObjectZeroId(),
    staff_name: "",
    staff_pos: "",
    staff_rank: "",
  },
  tm_status: "",
  tm_to_date: "",
};

export const useLongPostponeStore = create((set) => ({
  // ========== State ===========
  // 장기연기 리스트 검색 State
  longPostponeSearchParams: {
    ...baseSearchParams,
  },
  longPostponePage: 1,

  // ========== Action ==========
  // 장기연기 리스트 검색 조건 변경
  setSearchParams: (key, value) => {
    set((state) => ({
      longPostponeSearchParams: {
        ...state.longPostponeSearchParams,
        [key]: value,
      },
    }));
  },

  // Page 값 변경
  setPage: (value) => {
    set({ longPostponePage: value });
  },

  // 검색 조건 리셋
  resetSearchParams: () => {
    set({
      longPostponeSearchParams: {
        ...baseSearchParams,
      },
    });
  },
}));
