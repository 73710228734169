import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import { globalAlertOn } from "modules/actions/Alert";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadSearchMemberStore, searchModalAction } from "../Module/actions";

const FamilyTable = forwardRef((props, ref) => {
  const {
    modalParam,
    onModalDone,
    searchParam,
    totalPage,
    curPage,
    popUpAddFamilyModal,
    searchResult,
    onClickSearch,
  } = props;
  // const btnStyleClass = btnStyles();
  const restData = useSelector((state) => state.search_modal.search_result_member);
  const storesDispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    searchRun: () => {
      storesDispatch(loadSearchMemberStore(searchParam));
    },
  }));
  // console.log(restData);
  useEffect(() => {
    return () => {
      storesDispatch(searchModalAction.loadSearchMemberReset());
    };
  }, []);

  const rowClickEvt = (data) => {
    if (modalParam.memberId === data.member_info.member_id) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="회원 본인은 선택 불가합니다." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else {
      if (modalParam.mode === "family") {
        //가족추가 모달
        popUpAddFamilyModal(data);
      } else {
        //지인등록
        // from. 회원페이지 -> 지인등록 popup + 지인등록 POST
        onModalDone(data);
      }
    }
  };

  const searchResultList = new searchResultTable(modalParam.goToMemberView, rowClickEvt);

  return (
    <div>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
      />
      <CustomPagination curPage={curPage} doSearch={onClickSearch} totalCnt={totalPage} />
    </div>
  );
});
export default FamilyTable;

class searchResultTable {
  table_title = "";
  onParentClickEvent = null;
  rowClickEvt = null;

  constructor(goToMemberView, rowClickEvt) {
    this.onParentClickEvent = goToMemberView;
    this.rowClickEvt = rowClickEvt;
  }

  onClickEvent = (data) => {
    this.onParentClickEvent(data);
  };

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx} onClick={() => this.rowClickEvt(data)} hover>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.member_type}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.access.card_no ? data.access.card_no : ""}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_name}</CustomTableContents>
        <CustomTableContentsEND align="center">{data.base_info.phone}</CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "지점", width: "15%" },
    { title: "구분", width: "10%" },
    { title: "회원번호", width: "10%" },
    { title: "카드번호", width: "10%" },
    { title: "회원명", width: "15%" },
    { title: "연락처", width: "15%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
