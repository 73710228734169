import { create } from "zustand";

// 검색 조건 베이스
const baseSearchParams = {
  approval_status: "",
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  doc_name: "",
  doc_title: "",
  draft_from_date: "",
  draft_to_date: "",
  drafter_list: [
    {
      staff_id: "",
      staff_name: "",
      staff_pos: "",
      staff_rank: "",
    },
  ],
  hq_branch_data: {
    branch_list: [[""]],
    hq_list: [""],
    target_list: [
      {
        branch_list: [
          {
            branch_id: "",
            branch_name: "",
          },
        ],
        hq_info: {
          hq_id: "",
          hq_name: "",
        },
      },
    ],
  },
  hq_info: {
    hq_id: "",
    hq_name: "",
  },
  is_view_my_draft: true,
  recv_from_date: "",
  recv_to_date: "",
  search_drafter_info: {
    staff_id: "",
    staff_name: "",
    staff_pos: "",
    staff_rank: "",
  },
  view_type: "",
};

export const useApprovalListStore = create((set, get) => ({
  returnStoreByType: (type) => {
    const {
      hqBranchBaseData,
      setHqBranchInfo,
      tabIndex,
      setTabIndex,
      // my_draft
      myDraftSearchParams,
      setMyDraftSearchParams,
      resetMyDraftSearchParams,
      myDraftHqIdx,
      setMyDraftHqIdx,
      myDraftPage,
      setMyDraftPage,
      // my_approval
      myApprovalSearchParams,
      setMyApprovalSearchParams,
      resetMyApprovalSearchParams,
      myApprovalHqIdx,
      setMyApprovalHqIdx,
      myApprovalPage,
      setMyApprovalPage,
      // completed
      completedSearchParams,
      setCompletedSearchParams,
      resetCompletedSearchParams,
      completedHqIdx,
      setCompletedHqIdx,
      completedPage,
      setCompletedPage,
      // received
      receivedSearchParams,
      setReceivedSearchParams,
      resetReceivedSearchParams,
      receivedHqIdx,
      setReceivedHqIdx,
      receivedPage,
      setReceivedPage,
    } = get();

    const myDraftLib = {
      hqBranchBaseData,
      setHqBranchInfo,
      tabIndex,
      setTabIndex,
      searchParams: myDraftSearchParams,
      setSearchParams: setMyDraftSearchParams,
      resetSearchParams: resetMyDraftSearchParams,
      hqIdx: myDraftHqIdx,
      setHqIdx: setMyDraftHqIdx,
      page: myDraftPage,
      setPage: setMyDraftPage,
    };
    const myApprovalLib = {
      hqBranchBaseData,
      setHqBranchInfo,
      tabIndex,
      setTabIndex,
      searchParams: myApprovalSearchParams,
      setSearchParams: setMyApprovalSearchParams,
      resetSearchParams: resetMyApprovalSearchParams,
      hqIdx: myApprovalHqIdx,
      setHqIdx: setMyApprovalHqIdx,
      page: myApprovalPage,
      setPage: setMyApprovalPage,
    };
    const completedLib = {
      hqBranchBaseData,
      setHqBranchInfo,
      tabIndex,
      setTabIndex,
      searchParams: completedSearchParams,
      setSearchParams: setCompletedSearchParams,
      resetSearchParams: resetCompletedSearchParams,
      hqIdx: completedHqIdx,
      setHqIdx: setCompletedHqIdx,
      page: completedPage,
      setPage: setCompletedPage,
    };
    const receivedLib = {
      hqBranchBaseData,
      setHqBranchInfo,
      tabIndex,
      setTabIndex,
      searchParams: receivedSearchParams,
      setSearchParams: setReceivedSearchParams,
      resetSearchParams: resetReceivedSearchParams,
      hqIdx: receivedHqIdx,
      setHqIdx: setReceivedHqIdx,
      page: receivedPage,
      setPage: setReceivedPage,
    };

    switch (type) {
      case "my_draft":
        return myDraftLib;
      case "my_approval":
        return myApprovalLib;
      case "completed":
        return completedLib;
      case "received":
        return receivedLib;
      default:
        break;
    }
  },
  // ========== State ===========
  // 본부, 지점 베이스 State
  hqBranchBaseData: {
    branch_list: [[""]],
    hq_list: [""],
    target_list: [
      {
        branch_list: [
          {
            branch_id: "",
            branch_name: "",
          },
        ],
        hq_info: {
          hq_id: "",
          hq_name: "",
        },
      },
    ],
  },

  // ComboBox 첫번째 본부 및 지점 정보 State (조건 초기화 시 선택 위함)
  firstHqBranchInfoData: {
    hq_info: {
      hq_id: "",
      hq_name: "",
    },
    branch_info: {
      branch_id: "",
      branch_name: "",
    },
  },

  // 내 기안 문서 검색 State
  myDraftSearchParams: {
    ...baseSearchParams,
  },
  // 결재할 문서 검색 State
  myApprovalSearchParams: {
    ...baseSearchParams,
  },
  // 완료된 문서 검색 State
  completedSearchParams: {
    ...baseSearchParams,
  },
  // 수신함 검색 State
  receivedSearchParams: {
    ...baseSearchParams,
  },

  // 본부 선택 인덱스
  myDraftHqIdx: 0,
  myApprovalHqIdx: 0,
  completedHqIdx: 0,
  receivedHqIdx: 0,

  // 선택 페이지
  myDraftPage: 1,
  myApprovalPage: 1,
  completedPage: 1,
  receivedPage: 1,

  // 탭 인덱스
  tabIndex: 0,

  // ========== Action ==========
  // 내 기안 문서 검색 조건 변경
  setMyDraftSearchParams: (key, value) => {
    set((state) => ({
      myDraftSearchParams: {
        ...state.myDraftSearchParams,
        [key]: value,
      },
    }));
  },
  // 결재할 문서 검색 조건 변경
  setMyApprovalSearchParams: (key, value) => {
    set((state) => ({
      myApprovalSearchParams: {
        ...state.myApprovalSearchParams,
        [key]: value,
      },
    }));
  },
  // 완료된 문서 검색 조건 변경
  setCompletedSearchParams: (key, value) => {
    set((state) => ({
      completedSearchParams: {
        ...state.completedSearchParams,
        [key]: value,
      },
    }));
  },
  // 수신함 검색 조건 변경
  setReceivedSearchParams: (key, value) => {
    set((state) => ({
      receivedSearchParams: {
        ...state.receivedSearchParams,
        [key]: value,
      },
    }));
  },
  // Hq Index 값 변경
  setMyDraftHqIdx: (value) => {
    set({ myDraftHqIdx: value });
  },
  setMyApprovalHqIdx: (value) => {
    set({ myApprovalHqIdx: value });
  },
  setCompletedHqIdx: (value) => {
    set({ completedHqIdx: value });
  },
  setReceivedHqIdx: (value) => {
    set({ receivedHqIdx: value });
  },

  // Page 값 변경
  setMyDraftPage: (value) => {
    set({ myDraftPage: value });
  },
  setMyApprovalPage: (value) => {
    set({ myApprovalPage: value });
  },
  setCompletedPage: (value) => {
    set({ completedPage: value });
  },
  setReceivedPage: (value) => {
    set({ receivedPage: value });
  },

  // TabIndex값 변경
  setTabIndex: (value) => {
    set({ tabIndex: value });
  },

  // ComboBox 첫번째 본부, 지점 정보 변경
  setHqBranchInfo: (key, value) => {
    set((state) => ({
      firstHqBranchInfoData: {
        ...state.firstHqBranchInfoData,
        [key]: value,
      },
    }));
  },

  // 검색 조건 리셋
  resetMyDraftSearchParams: () => {
    set((state) => ({
      myDraftSearchParams: {
        ...baseSearchParams,
        ...state.firstHqBranchInfoData,
      },
    }));
    set({ myDraftHqIdx: 0 });
  },

  resetMyApprovalSearchParams: () => {
    set((state) => ({
      myApprovalSearchParams: {
        ...baseSearchParams,
        ...state.firstHqBranchInfoData,
      },
    }));
    set({ myApprovalHqIdx: 0 });
  },
  resetCompletedSearchParams: () => {
    set((state) => ({
      completedSearchParams: {
        ...baseSearchParams,
        ...state.firstHqBranchInfoData,
      },
    }));
    set({ completedHqIdx: 0 });
  },
  resetReceivedSearchParams: () => {
    set((state) => ({
      receivedSearchParams: {
        ...baseSearchParams,
        ...state.firstHqBranchInfoData,
      },
    }));
    set({ receivedHqIdx: 0 });
  },
  // 모든 검색 조건 리셋
  resetAllSearchParams: () => {
    const {
      resetMyDraftSearchParams,
      resetMyApprovalSearchParams,
      resetCompletedSearchParams,
      resetReceivedSearchParams,
    } = get();

    resetMyDraftSearchParams();
    resetMyApprovalSearchParams();
    resetCompletedSearchParams();
    resetReceivedSearchParams();

    set({ tabIndex: 0 });
  },
}));
