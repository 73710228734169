import { restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { registerViewInitState } from "../Common/Module/reducers/view_init";
import RegisterOptionWrapper from "./RegisterOptionWrapper";

const RegisterMemberRoot = () => {
  const storesDispatch = useDispatch();
  const [memberInputState, setMemberInputState] = useState(_.cloneDeep(registerViewInitState));

  useEffect(() => {
    HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member/register_view", { params: {} })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setMemberInputState(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  }, []);

  return (
    <PageFrame
      menuActive="설정"
      parentMenu={{ index: true }}
      children={
        <div>
          <PageTitle titleText="신규등록" />
          <RegisterOptionWrapper memberInputState={memberInputState} />
        </div>
      }
    />
  );
};
export default RegisterMemberRoot;
