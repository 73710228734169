import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import ComboBox from "components/LabelInput/ComboBox";
import TextField from "components/LabelInput/TextField";
import { DrawLockerGrid } from "../Common/LockerGridForEdit";

import { contentTypeJson, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import { lockerAllInfoInit } from "../Module/reducers/init_state";

//1. 락커신규배정 (mode:add)
//2. 락커이동 (mode:edit)
//3. 고장나서 다시배정 (mode:edit)
const LockerSelectModal = ({ modalParam, onModalDone }) => {
  //modalParam
  //(필수) mode, member_info, branch_id, locker_total_idx,
  //if(mode === add) => mbshp_locker_id
  //if(mode === edit) => zone_id, locker_no
  //isReg, selectedLockerList, view_id
  const storesDispatch = useDispatch();
  const labelWidth = "4.5rem";
  const labelWidthShort = "2rem";
  const textBoxWidth = "12rem";
  const [floorZoneCombo, setFloorZoneCombo] = useState({
    floor_name_list: [],
    floor_zone_list: [],
    zone_name_list: [[]],
    gender_name_list: [[]],
  });
  const [lockerAllInfo, setLockerAllInfo] = useState(_.cloneDeep(lockerAllInfoInit));
  const [floorIdx, setFloorIdx] = useState(0);
  const [zoneIdx, setZoneIdx] = useState(0);
  const [loading, setLoading] = useState(false);

  //층구역 콤보 GET
  const getFloorZoneCombo = async () => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_BRANCH_SERVER + "/locker/floor_zone/combo",
        {
          params: {
            branch_id: modalParam.branch_id,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        const result = res.data.data;
        if (result.floor_zone_list.length !== 0) {
          const firstFId = result.floor_zone_list[0].floor_id;
          const firstZId = result.floor_zone_list[0].zone_list[0].zone_id;
          getFloorZoneAllList(modalParam.branch_id, firstFId, firstZId);
          setFloorZoneCombo(res.data.data);
        }
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  //구역gird GET
  const getFloorZoneAllList = async (branchId, floorId, zoneId) => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_BRANCH_SERVER + "/locker/zone/list",
        {
          params: {
            branch_id: branchId,
            floor_id: floorId,
            zone_id: zoneId,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        if (modalParam.isReg && modalParam.selectedLockerList.length > 0) {
          for (let i = 0; i < modalParam.selectedLockerList.length; i++) {
            const curLockerNo = modalParam.selectedLockerList[i].locker_info.locker_no;
            if (curLockerNo < 1) {
              continue;
            }
            if (res.data.data.zone_id !== modalParam.selectedLockerList[i].locker_info.zone_id) {
              continue;
            }

            for (let j = 0; j < res.data.data.allocate_list.length; j++) {
              const selectedIdx = res.data.data.allocate_list[j].findIndex(
                // (x) => x.locker_no === curLockerNo,
                (x) => x.num === curLockerNo, // 이거 잘못된 것 같음. 왜 locker_no를 안쓰는지?
              );

              if (selectedIdx !== -1) {
                res.data.data.allocate_list[j][selectedIdx].status = "selected";
              }
            }
          }
        }
        setLockerAllInfo(res.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  //락커배정 POST
  const saveLockerSelect = async (data) => {
    if (modalParam.mode === "add") {
      try {
        const formData = new FormData();

        formData.append(
          "zone_id",
          floorZoneCombo.floor_zone_list[floorIdx].zone_list[zoneIdx].zone_id,
        );
        formData.append("mbshp_locker_id", modalParam.mbshp_locker_id);
        formData.append("locker_total_idx", data.locker_total_idx);
        formData.append("member_info", JSON.stringify(modalParam.member_info));

        setLoading(true);

        const res = await HttpInstance.post(
          process.env.REACT_APP_BRANCH_SERVER + "/locker/locker_allocate",
          formData,
          contentTypeJson,
        );
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          storesDispatch(
            globalAlertOn({
              show: true,
              titleText: "알림",
              reqConfirm: true,
              bodyText: <Label labelText="락커가 배정되었습니다." />,
              onBtnOk: () => {
                //from. 락커 미배정 -> 모달닫기 + 미배정리스트 갱신 + 섹션2 구락리 갱신
                //from. 회원 미배정 -> 모달닫기 + 회원정보갱신 (파라미터 x)
                onModalDone(
                  modalParam.branch_id,
                  floorZoneCombo.floor_zone_list[floorIdx].floor_id,
                  floorZoneCombo.floor_zone_list[floorIdx].zone_list[zoneIdx].zone_id,
                );
              },
            }),
          );
        }
      } catch (err) {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      } finally {
        setLoading(false);
      }
    } else {
      //락커이동(mode ==='edit')
      try {
        const formData = new FormData();
        const old_locker = {
          locker_total_idx: modalParam.locker_total_idx,
          zone_id: modalParam.zone_id,
          locker_no: modalParam.locker_no,
        };
        const new_locker = {
          locker_total_idx: data.locker_total_idx,
          zone_id: lockerAllInfo.zone_id,
          locker_no: data.num,
        };
        const new_locker_info = {
          branch_id: modalParam.branch_id,
          floor_id: floorZoneCombo.floor_zone_list[floorIdx].floor_id,
          zone_id: lockerAllInfo.zone_id,
          locker_total_idx: data.locker_total_idx,
          locker_no: data.num,
        };
        formData.append("cur_locker_info", JSON.stringify(old_locker));
        formData.append("mov_locker_info", JSON.stringify(new_locker));
        formData.append("member_info", JSON.stringify(modalParam.member_info));

        setLoading(true);

        const res = await HttpInstance.post(
          process.env.REACT_APP_BRANCH_SERVER + "/locker/locker_move",
          formData,
          contentTypeJson,
        );

        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          storesDispatch(
            globalAlertOn({
              show: true,
              titleText: "알림",
              reqConfirm: true,
              bodyText: <Label labelText="락커가 이동되었습니다." />,
              onBtnOk: () => {
                //1. 회원에서 왔을때랑, 락커에서 왔을때 각각 화면 갱신
                //2. 락커 상세 전체갱신
                //3. 모달 닫기
                onModalDone(new_locker_info);
              },
            }),
          );
        }
      } catch (err) {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      } finally {
        setLoading(false);
      }
    }
  };

  const onShowLockerDetail = (data) => {
    //lockerAllInfo.allocate_list에서 해당 아이템을 data에 있는 정보로 찾아
    //data의 좌표로 찾으면 될듯
    //선택된 아이템의 색깔을 바꿔주야함
    const zoneGender = floorZoneCombo.gender_name_list[floorIdx][zoneIdx];
    if (zoneGender !== "공용" && modalParam.member_info.gender !== zoneGender) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="구역 성별을 확인해주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else {
      //통과
      if (data.status === "selected") return;
      if (data.status !== "대기") {
        return storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: <Label labelText={`사용할 수 없는 락커입니다.`} />,
            onBtnOk: () => {},
          }),
        );
      }

      if (data.active === 0 && data.status === "대기") {
        //배정가능
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: (
              <>
                {modalParam.mode === "add" ? (
                  <div>
                    <Label
                      fontWeight="600"
                      labelText={`${floorZoneCombo.floor_zone_list[floorIdx].floor_name} ${floorZoneCombo.floor_zone_list[floorIdx].zone_list[zoneIdx].zone_name} ${data.num}번`}
                    />
                    <Label labelText="락커를 배정 하시겠습니까?" />
                  </div>
                ) : (
                  <div>
                    <Label
                      fontWeight="600"
                      labelText={`${floorZoneCombo.floor_zone_list[floorIdx].floor_name} ${floorZoneCombo.floor_zone_list[floorIdx].zone_list[zoneIdx].zone_name} ${data.num}번`}
                    />
                    <Label labelText="락커로 이동 하시겠습니까?" />
                  </div>
                )}
              </>
            ),
            onBtnYes: () => {
              if (modalParam.isReg) {
                // 신규등록 or 추가결제에서는 배정할 락커 정보만 넘김
                onModalDone(
                  data,
                  floorZoneCombo.floor_zone_list[floorIdx].zone_list[zoneIdx],
                  modalParam.view_id,
                );
              } else {
                saveLockerSelect(data);
              }
            },
            onBtnNo: () => {},
          }),
        );
      }
    }
  };

  //초기 랜더링 -> 콤보GET
  useEffect(() => {
    if (modalParam) {
      getFloorZoneCombo();
    }
  }, [modalParam]);

  return (
    <div>
      {loading && <Loading />}

      <div
        style={{
          display: "flex",
          marginTop: "1.5rem",
          justifyContent: "start",
          gap: "2.5rem",
        }}
      >
        <ComboBox
          labelMarginLeft="0.6rem"
          labelWidth={labelWidthShort}
          comboItemWidth={textBoxWidth}
          labelText="층"
          data={floorZoneCombo.floor_name_list}
          defaultValue={floorZoneCombo.floor_name_list[floorIdx]}
          onChangeCallback={(e) => {
            const fIdx = floorZoneCombo.floor_name_list.findIndex((el) => el === e.target.value);
            if (fIdx !== -1) {
              setFloorIdx(fIdx);
              //층 바뀌면 구역 초기화
              setZoneIdx(0);
              getFloorZoneAllList(
                modalParam.branch_id,
                floorZoneCombo.floor_zone_list[fIdx].floor_id,
                floorZoneCombo.floor_zone_list[fIdx].zone_list[0].zone_id,
              );
            }
          }}
        />
        <ComboBox
          labelWidth={labelWidthShort}
          comboItemWidth={textBoxWidth}
          labelText="구역"
          data={floorZoneCombo.zone_name_list[floorIdx]}
          defaultValue={floorZoneCombo.zone_name_list[floorIdx][zoneIdx]}
          onChangeCallback={(e) => {
            const zIdx = floorZoneCombo.zone_name_list[floorIdx].findIndex(
              (el) => el === e.target.value,
            );
            const zId = floorZoneCombo.floor_zone_list[floorIdx].zone_list[zIdx].zone_id;
            if (zIdx !== -1) {
              setZoneIdx(zIdx);
              //구역 바뀌면 구역gird GET
              getFloorZoneAllList(
                modalParam.branch_id,
                floorZoneCombo.floor_zone_list[floorIdx].floor_id,
                zId,
              );
            }
          }}
        />
        <TextField
          labelWidth={labelWidth}
          comboItemWidth={textBoxWidth}
          labelText="구역 성별"
          disabled
          defaultValue={
            floorIdx !== undefined &&
            zoneIdx !== undefined &&
            floorZoneCombo.gender_name_list[floorIdx][zoneIdx]
          }
        />
      </div>

      <div style={{ width: "100%" }}>
        <div style={{ height: "1.5rem" }}></div>
        <DrawLockerGrid
          currentData={lockerAllInfo}
          mode={"assign"}
          onItemClick={onShowLockerDetail}
          statusFilter={"전체"}
          areaHeight={lockerAllInfo.height}
        />
      </div>
    </div>
  );
};
export default LockerSelectModal;
