import { useQuery } from "@tanstack/react-query";
import { getAccessCardList, getAccessCardStatus } from "apis/branch/access_card_manage_api";
import { accessCardKeys } from "apis/branch/query_keys_branch";
import { simpleAlert } from "components/CommonLib/CommonLib";
import { useDispatch } from "react-redux";

// [GET] 출입 카드관리 리스트 검색
export const useAccessCardList = (queryData, enabled, setEnabled) => {
  const storesDispatch = useDispatch();

  return useQuery({
    queryKey: accessCardKeys.list(queryData),
    queryFn: () => getAccessCardList(queryData),
    enabled: enabled,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setEnabled(false);
    },
  });
};

// [GET] 출입 카드 현황 가져오기
export const useAccessCardStatus = (queryData, enabled) => {
  const storesDispatch = useDispatch();

  return useQuery({
    queryKey: accessCardKeys.status(queryData),
    queryFn: () => getAccessCardStatus(queryData),
    enabled: enabled,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });
};
