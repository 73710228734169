import {
  contentTypeJson,
  dateToStr,
  parseMoneyFormat,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import DocInfo from "components/File/DocInfo";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import UnPayModalIndex from "./Modal/UnPayModalIndex";
import post_api from "./Module/api/PostApi";
import UnPaidProcessView from "./UnPaidProcessView";

const UnPaidProcessWrapper = ({ viewState, refreshStore, branchId }) => {
  const storesDispatch = useDispatch();
  const viewModelRef = useRef();
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });
  const [loading, setLoading] = useState(false);

  const [payInfo, setPayInfo] = useState({
    selectedPay: { unpaid_data: {}, unpaid_list_selector: {} },
    doc_receipt: "",
    reg_date: dateToStr(Date.now()),
  });
  const [docList, setDocList] = useState([]);

  const onModalCallbackEvent = (mode) => {
    viewModelRef.current.setClose();
  };

  useEffect(() => {
    if (viewState.contract_doc_list.length !== 0) {
      setDocList(viewState.contract_doc_list);
    }
  }, [viewState.contract_doc_list.length]);

  //결제창 닫히면서...
  const onPayModalCallbackEvent = (mode, paymentInfo) => {
    let confirmText = "";
    confirmText += "-현 결제금액 : " + parseMoneyFormat(paymentInfo.total_paid) + "원\n";
    confirmText += "-미납금 : " + parseMoneyFormat(paymentInfo.unpaid_info.unpaid_price) + "원";

    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText={confirmText} whiteSpace="pre-wrap" />
            <br />
            <br />
            <Label labelText="결제 금액이 위와 맞습니까?" />
          </>
        ),
        onBtnYes: () => {
          onSave(paymentInfo);
        },
        onBtnNo: () => {},
      }),
    );
  };

  //최종 결제 POST
  const onSave = async (paymentInfo) => {
    try {
      const newInfo = _.cloneDeep(viewState);
      newInfo.paymentA = paymentInfo;
      newInfo.contract_doc_list = docList;
      //모두 사용할꺼면
      newInfo.contract_doc_list.forEach((el) => (el.is_use = true));
      newInfo.reg_date = payInfo.reg_date;
      const formData = new FormData();
      formData.append("new_info", JSON.stringify(newInfo));

      const postApi = post_api.unpay_process();
      setLoading(true);

      const res = await HttpInstance.post(postApi, formData, contentTypeJson);
      if (res.error) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: <Label labelText={`${res.data.msg}`} />,
            reqConfirm: true,
            onBtnOk: () => {
              refreshStore(); //결제 테이블 갱신 + 회원상세 갱신 + 모달 닫기
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const onChangeDueDate = (purchase_code) => {
    const param = {
      title: "납부예정일변경",
      modalParam: {
        member_id: viewState.member_id,
        purchase_code: purchase_code,
        branch_id: branchId,
      },
    };
    setModalInfo({
      show: true,
      title: "납부 예정일 변경",
      Content: (
        <UnPayModalIndex
          modalSelect={param.title}
          modalParam={param}
          onModalDone={onModalCallbackEvent}
        />
      ),
      callback: null,
      Buttons: undefined,
    });
  };

  const onPopPay = () => {
    const param = {
      title: "결제",
      modalParam: {
        unpaidPrice: payInfo.selectedPay.unpaid_data.unpaid_price,
        csPaidInfo: {
          pay_method: [],
        },
        ptPaidInfo: {
          pay_method: [],
        },
        unpaidInfo: {
          pay_method: [],
          total_paid: payInfo.selectedPay.unpaid_data.total_price,
          unpaid_info: {
            unpaid_price: payInfo.selectedPay.unpaid_data.unpaid_price,
            reason: payInfo.selectedPay.unpaid_data.reason,
            promise_date: payInfo.selectedPay.unpaid_data.due_date,
          },
          regDate: payInfo.reg_date,
        },
        remainPoint: 0,
        useUnpaid: true,
        usedPoint: 0,
        mode: "unpaid",
        regDate: payInfo.reg_date,
      },
    };

    setModalInfo({
      show: true,
      title: "결제",
      Content: (
        <UnPayModalIndex
          modalSelect={param.title}
          modalParam={param}
          onModalDone={onPayModalCallbackEvent}
        />
      ),
      callback: null,
      Buttons: undefined,
    });
  };

  const onPayEvt = () => {
    if (_.isEmpty(payInfo.selectedPay.unpaid_data)) {
      return storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="결제하실 미납금을 선택해주세요." />,
          onBtnOk: () => {},
        }),
      );
    } else if (!payInfo.reg_date) {
      return storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="등록일을 입력해주세요." />,
          onBtnOk: () => {},
        }),
      );
    }
    //결제모달 띄우기
    onPopPay();
  };

  const uploadDoneCallback = (result) => {
    const arr = [];
    arr.push(result);
    setDocList(arr);
  };

  return (
    <div
      style={{
        width: "50rem",
      }}
    >
      {loading && <Loading />}

      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
      <UnPaidProcessView
        viewState={viewState}
        onChangeDueDate={onChangeDueDate}
        setPayInfo={setPayInfo}
        payInfo={payInfo}
      />
      {/* 
      <div style={{ display: "flex", marginTop: "1rem" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="결제" />
      </div> */}

      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "1rem",
          }}
        >
          <DocInfo onlyOne docList={docList} uploadDoneCallback={uploadDoneCallback} />
        </div>

        <div>
          <DatePicker
            marginTop="0.5rem"
            textMarginRight="0rem"
            labelWidth={"3rem"}
            labelText="등록일"
            minWidth={"10rem"}
            defaultValue={payInfo.reg_date}
            onChangeCallback={(e) => {
              setPayInfo((cur) => {
                return { ...cur, reg_date: e };
              });
            }}
          />
        </div>
      </div>

      <div
        style={{
          marginTop: "1.5rem",
          display: "flex",
          justifyContent: "center",
          gap: "0.5rem",
        }}
      >
        <NormalBtn
          name="결제"
          onClick={() => {
            onPayEvt();
          }}
        />
        <NormalBtn
          name="취소"
          theme="white"
          onClick={() => {
            storesDispatch(globalModalOff());
          }}
        />
      </div>
    </div>
  );
};
export default UnPaidProcessWrapper;
