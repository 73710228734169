export const tm_member_detail_state = {
  member_info: {
    member_id: "",
    member_name: "empty",
    member_no: "",
  },
  base_info: {
    phone: "",
  },
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  guest_book_hist: [
    // {
    //   branch_info: {
    //     branch_id: "",
    //     branch_name: "",
    //   },
    //   hq_info: {
    //     hq_id: "",
    //     hq_name: "",
    //   },
    //   inquiry_memo: "",
    //   inquiry_title: "",
    //   reg_info: {
    //     created_at: "",
    //     reg_id: "",
    //     reg_name: "",
    //     upd_id: "",
    //     upd_name: "",
    //     updated_at: "",
    //   },
    //   cs_date: "",
    //   cs_time: "",
    // },
  ],

  service_status_info: [
    {
      active_pt_status: "",
      last_mbshp_paid_date: "",
      last_mbshp_to_date: "",
      last_pt_paid_date: "",
      mbshp_status: "",
      total_pt_remain_cnt: 0,
    },
  ],
  tm_record_list: [
    // {
    //   hist_id: "",
    //   member_id: "",
    //   member_name: "",
    //   member_type: "",
    //   phone: "",
    //   reg_info: {
    //     created_at: "",
    //     reg_id: "",
    //     reg_name: "",
    //     upd_id: "",
    //     upd_name: "",
    //     updated_at: "",
    //   },
    //   staff_info: {
    //     staff_id: "",
    //     staff_name: "",
    //     staff_pos: "",
    //     staff_rank: "",
    //   },
    //   tm_date: "",
    //   tm_id: "",
    //   tm_memo: "",
    //   tm_status: "",
    //   tm_time: "",
    //   cs_date: "",
    //   cs_time: "",
    // },
  ],

  cs_team_name_list: [],
  cs_team_list: {
    key: "",
    branch_id: "000000000000000000000000",
    branch_name: "",
    team: [
      {
        staff_id: "000000000000000000000000",
        staff_name: "",
        department: {
          org_id: "000000000000000000000000",
          org_name: "",
        },
        rank: {
          org_id: "000000000000000000000000",
          org_name: "",
        },
        position: {
          org_id: "000000000000000000000000",
          org_name: "",
        },
        show_name: "",
      },
    ],
  },
};
