import React, { useMemo } from "react";

const GxClassCalendar = ({ gridItem }) => {
  const normalStyle = useMemo(() => {
    return {
      border: "1px solid #EBEBEB",
      padding: "0.3rem 0.6rem",
    };
  }, []);
  const boxStyle = {
    marginTop: "0.2rem",
    borderRadius: "5px",
    textAlign: "center",
    padding: "0.25rem 0.5rem",
    fontSize: "0.8rem",
    backgroundColor: "pink",
    color: "#fff",
  };
  const showTimeText = (idx) => {
    if (idx === 0) {
      return "00:00";
    } else if (idx === 1) {
      return "01:00";
    } else if (idx === 2) {
      return "02:00";
    } else if (idx === 3) {
      return "03:00";
    } else if (idx === 4) {
      return "04:00";
    } else if (idx === 5) {
      return "05:00";
    } else if (idx === 6) {
      return "06:00";
    } else if (idx === 7) {
      return "07:00";
    } else if (idx === 8) {
      return "08:00";
    } else if (idx === 9) {
      return "09:00";
    } else if (idx === 10) {
      return "10:00";
    } else if (idx === 11) {
      return "11:00";
    } else if (idx === 12) {
      return "12:00";
    } else if (idx === 13) {
      return "13:00";
    } else if (idx === 14) {
      return "14:00";
    } else if (idx === 15) {
      return "15:00";
    } else if (idx === 16) {
      return "16:00";
    } else if (idx === 17) {
      return "17:00";
    } else if (idx === 18) {
      return "18:00";
    } else if (idx === 19) {
      return "19:00";
    } else if (idx === 20) {
      return "20:00";
    } else if (idx === 21) {
      return "21:00";
    } else if (idx === 22) {
      return "22:00";
    } else {
      return "23:00";
    }
  };

  const selectStyle = (color_code) => {
    return {
      marginTop: "0.2rem",
      borderRadius: "5px",
      textAlign: "center",
      padding: "0.25rem 0.5rem",
      fontSize: "0.8rem",
      backgroundColor: color_code,
      // color: "#fff",
    };
  };

  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "0.5fr repeat(7,1fr)",
          textAlign: "center",
          marginBottom: "0.7rem",
          fontWeight: "bold",
          color: "#222222",
        }}
      >
        <div></div>
        <div style={{ color: "red" }}>일 </div>
        <div>월 </div>
        <div>화 </div>
        <div>수</div>
        <div>목 </div>
        <div>금 </div>
        <div>토 </div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "0.5fr repeat(7,1fr)",
          gridAutoRows: "minmax(2.5rem,auto)",
          // maxHeight: "30rem",
          gridAutoColumns: "1fr",
          boxSizing: "border-box",
          // overflowY: "auto",
        }}
      >
        {gridItem.length !== 0 &&
          gridItem.map((time, timeIdx) => {
            return (
              <React.Fragment key={timeIdx}>
                <div style={normalStyle}>{showTimeText(timeIdx)}</div>
                {time.map((day, dayIdx) => (
                  <div key={dayIdx} style={normalStyle}>
                    {day.schedule_list &&
                      day.schedule_list.map((el, idx) => (
                        <div key={idx} style={selectStyle(el.view_info.color_code)}>
                          <div style={{ color: "#222222", fontSize: "0.9rem" }}>
                            {el.card_title.split("\n")[0]}
                          </div>
                          <div style={{ color: "rgba(34, 34, 34, 0.6)" }}>
                            {el.card_title.split("\n")[1]}
                          </div>
                        </div>
                      ))}
                  </div>
                ))}
              </React.Fragment>
            );
          })}
      </div>
    </>
  );
};

export default GxClassCalendar;
