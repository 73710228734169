import * as _ from "lodash";

export const HqBranchFilter = (hqBranchListRaw, affiliationList, workInfoList) => {
  const hqBranchList = _.cloneDeep(hqBranchListRaw);

  // affiliation에서 퇴직처리된 게 있으면 마킹
  // console.log(workInfoList);
  for (let i = 0; i < affiliationList.length; i++) {
    const workInfoItem = workInfoList.find(
      (x) =>
        x.base_info.hq_info.hq_id === affiliationList[i].hq_info.hq_id &&
        x.base_info.branch_info.branch_id === affiliationList[i].branch_info.branch_id,
    );

    if (workInfoItem && workInfoItem.reg_type === "퇴직") {
      affiliationList[i].is_retired = true;
    }
  }

  const newList = {
    branch_list: [],
    hq_list: [],
    target_list: [],
  };

  for (let i = 0; i < hqBranchList.target_list.length; i++) {
    const curTarget = hqBranchList.target_list[i];

    // 현재 i target 본부가 재직 중인 본부의 직원인지 여부
    // const sameHqOnly = affiliationList.find(
    //   (x) =>
    //     !x.is_retired &&
    //     x.hq_info.hq_id === curTarget.hq_info.hq_id &&
    //     x.branch_info.branch_id === getHqBranchId(),
    // );

    // if (!sameHqOnly) {
    const newBranchList = [];
    for (let j = 0; j < curTarget.branch_list.length; j++) {
      // 퇴직이나 재직 상관없이 affil~에 있으면 리스트에 추가안함
      const sameBranch = affiliationList.find(
        (x) =>
          x.hq_info.hq_id === curTarget.hq_info.hq_id &&
          x.branch_info.branch_id === curTarget.branch_list[j].branch_id,
      );
      if (!sameBranch) {
        newBranchList.push(curTarget.branch_list[j]);
      }
    }
    curTarget.branch_list = newBranchList;
    newList.target_list.push(curTarget);
    // hq_list, branch_list 채우기
    newList.hq_list.push(curTarget.hq_info.hq_name);
    newList.branch_list.push(
      curTarget.branch_list.map((it, idx) => {
        return it.branch_name;
      }),
    );
    // }
  }

  return newList;
};
