import { simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import NumberTextField from "components/LabelInput/NumberTextField";
import RadioGroup from "components/LabelInput/RadioGroup";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import ModalBtnModeGroup from "components/ModalBtnModeGroup/ModalBtnModeGroup";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import PtSalaryCard from "pages/Staff/StaffInfo/Modal/WorkInfoItem/SalaryCard/PtSalaryCard";
import { useState } from "react";
import { useDispatch } from "react-redux";

const ItemBlock1 = ({ workInfoStore }) => {
  const textBoxWidth = "12rem";

  return (
    <div>
      <div style={{}}>
        <TextField
          labelWidth={"4rem"}
          textBoxWidth={textBoxWidth}
          labelText="직책명"
          defaultValue={workInfoStore.org_name}
          // onChangeCallback={(e) => { workInfoStore.position_name = e }}
          disabled
        />
      </div>

      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <div>
          <RadioGroup
            labelWidth={"6.5rem"}
            labelText="기본급"
            data={["월급제", "시급제"]}
            isMulti={true}
            defaultValue={workInfoStore.wage_type}
            onChangeCallback={(e, v) => {
              workInfoStore.wage_type = v;
            }}
          />
        </div>

        <div style={{ display: "flex", marginLeft: "1rem" }}>
          <NumberTextField
            isNumber
            textBoxWidth={textBoxWidth}
            defaultValue={workInfoStore.wage}
            endAdornment="원"
            onChangeCallback={(e) => {
              workInfoStore.wage = e;
            }}
          />
        </div>
      </div>
    </div>
  );
};

const HQPolicyStaffSalaryByPosition = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onClickSave = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="저장하시겠습니까?" />
          </>
        ),
        // width: "50rem",
        onBtnYes: () => {
          onSaveEvent();
        },
        onBtnNo: () => {},
      }),
    );
  };

  const onSaveEvent = async () => {
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    const paramSet = {
      hq_info: modalParam.hqInfo,
      position_list: modalParam.workInfoStore,
    };

    console.log(JSON.stringify(paramSet));

    const formData = new FormData();
    formData.append("info_data", JSON.stringify(paramSet));

    setLoading(true);

    await HttpInstance.post(
      process.env.REACT_APP_BRANCH_SERVER + "/hq_set/position_detail/update",
      formData,
      config,
    )
      .then((res) => {
        console.log(res);
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          onModalDone(modalParam.refreshStore);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
        // onModalDone(modalParam.refreshStore);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div
      style={{
        width: "64rem",
      }}
    >
      {loading && <Loading />}

      <ModalBtnModeGroup mode={"edit"} onClickEvent={onClickSave} />

      <ItemBlock1 workInfoStore={modalParam.workInfoStore} />
      <PtSalaryCard workInfo={modalParam.workInfoStore} isHqMenu={true} />
    </div>
  );
};
export default HQPolicyStaffSalaryByPosition;
