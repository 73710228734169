import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AssignView from "./AssignView";
import { assignAction, loadAssignViewStore } from "./Module/actions";

const AssignWrapper = ({ assigner, assignee, removePhoneMemberId }) => {
  const storesDispatch = useDispatch();

  //양도화면 GET
  const assignViewState = useSelector((state) => state.member_service.assign_view_info);

  //신규 양수자 일땐, member_id가 000000000000000000000000
  useEffect(() => {
    storesDispatch(
      loadAssignViewStore(assigner.member_info.member_id, assignee.member_info.member_id),
    );

    return () => {
      storesDispatch(assignAction.loadAssignViewReset());
    };
  }, [assigner, assignee]);

  return (
    <AssignView
      assignViewState={assignViewState.stores.data}
      assignee={assignee}
      removePhoneMemberId={removePhoneMemberId}
    />
  );
};
export default AssignWrapper;
