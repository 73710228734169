import TextArea from "components/LabelInput/TextArea";
import TextField from "components/LabelInput/TextField";

const CommentForm = ({ commentItem }) => {
  const labelWidth = "5rem";

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div style={{ width: "43%" }}>
          <TextField
            labelText="결재"
            labelWidth={labelWidth}
            labelMarginRight="0.5rem"
            fullWidth
            defaultValue={commentItem.approval_status}
            disabled
          />
        </div>
        <div style={{ width: "57%", marginLeft: "1rem" }}>
          <TextField
            labelText="결재일시"
            labelWidth={"6rem"}
            fullWidth
            labelMarginLeft="1rem"
            defaultValue={commentItem.reg_info.created_at}
            disabled
          />
        </div>
      </div>

      <TextField
        marginTop="0.5rem"
        labelText="직원명"
        labelWidth={labelWidth}
        labelMarginRight="0rem"
        fullWidth
        defaultValue={commentItem.reg_info.reg_name}
        disabled
      />

      <TextArea
        marginTop="0.5rem"
        labelWidth={labelWidth}
        labelText={"의견"}
        labelMarginRight="0rem"
        defaultValue={commentItem.comment}
        fullWidth
        minRows={4}
        maxRows={4}
        disabled
      />
    </div>
  );
};
export default CommentForm;
