import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";

const SalesListTable = ({ reportState }) => {
  const resultTableInst = new resultTable();

  const getTotalPrice = () => {
    let total = 0;
    for (let i = 0; i < reportState.sales_record_list.length; i++) {
      const rowTotal = reportState.sales_record_list[i].product_list.reduce((sum, cur) => {
        // return sum + cur.dis + cur.paid_price;
        return sum + cur.paid_price;
      }, 0);

      total += rowTotal;
    }

    return total;
  };

  return (
    <>
      <Label
        labelText="판매 내역"
        fontWeight="bold"
        fontSize="1.2rem"
        justifyContent="flex-start"
        marginBottom="0.25rem"
      />
      <CustomTable
        columns_head={resultTableInst.columns_head}
        table_title={resultTableInst.table_title}
        rest_call={resultTableInst.get_data_from_rest}
        row_render={resultTableInst.create_table}
        rest_data={reportState.sales_record_list}
      />
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem" }}>
        <TextField
          labelText="합계"
          labelWidth="3rem"
          textBoxWidth="10rem"
          textMarginRight="0rem"
          isNumber
          defaultValue={getTotalPrice().toLocaleString()}
          endAdornment={"원"}
          disabled
        />
      </div>
    </>
  );
};
export default SalesListTable;

class resultTable {
  table_title = "";

  getItemPrice = (itemList, type) => {
    const result = itemList.reduce((sum, cur) => {
      if (cur.product === type) {
        return sum + cur.paid_price;
      }
      return sum;
    }, 0);

    if (result) {
      return result.toLocaleString();
    } else {
      return "";
    }
  };

  getTotalDisPrice = (itemList) => {
    const result = itemList.reduce((sum, cur) => {
      return sum + cur.dis;
    }, 0);

    if (result) {
      return result.toLocaleString();
    } else {
      return "";
    }
  };

  getTotalPrice = (itemList) => {
    const result = itemList.reduce((sum, cur) => {
      return sum + cur.paid_price;
    }, 0);

    return result.toLocaleString();
  };

  getPaidItems = (data) => {
    let result = "";

    for (let i = 0; i < data.length; i++) {
      result += data[i].description;
      if (i < data.length - 1 && data[i].description !== "") {
        result += " ";
      }
    }
    return result;
  };

  // 회원권, 운동복 이런 항목들을 배열로 주기로 함. 값 들어오면 필드 채우기
  create_table = (data, idx) => {
    return (
      <CustomRow style={{}} key={idx}>
        <CustomTableContents>{data.reason}</CustomTableContents>
        <CustomTableContents>{data.reg_info.reg_name}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_name}</CustomTableContents>
        <CustomTableContents>{this.getPaidItems(data.product_list)}</CustomTableContents>
        <CustomTableContents $isNumber>
          {this.getItemPrice(data.product_list, "등록비")}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {this.getItemPrice(data.product_list, "회원권")}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {this.getItemPrice(data.product_list, "운동복")}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {this.getItemPrice(data.product_list, "GX")}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {this.getItemPrice(data.product_list, "락커")}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {this.getItemPrice(data.product_list, "PT")}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {this.getItemPrice(data.product_list, "카드재발급")}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {this.getItemPrice(data.product_list, "기타상품")}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {this.getTotalDisPrice(data.product_list)}
        </CustomTableContents>
        <CustomTableContents $isNumber fontWeight="bold">
          {this.getTotalPrice(data.product_list)}
        </CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "구분", width: "5.5%" },
    { title: "담당자", width: "5%" },
    { title: "회원번호", width: "6.25%" },
    { title: "이름", width: "6.25%" },
    { title: "구매항목", width: "9%" },
    { title: "가입비", width: "5.25%" },
    { title: "회원권", width: "5.25%" },
    { title: "운동복", width: "5.25%" },
    { title: "GX", width: "5.25%" },
    { title: "락커", width: "5.25%" },
    { title: "PT", width: "5.25%" },
    { title: "카드재발급", width: "5.25%" },
    { title: "기타상품", width: "5.25%" },
    { title: "총 할인금액", width: "5.25%" },
    { title: "합계", width: "6.25%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
