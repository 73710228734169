export const approval_doc_state = {
  comment_list: [
    // {
    //   "comment": "",
    //   "staff_info": {
    //     "staff_id": "",
    //     "staff_name": "",
    //     "staff_pos": "",
    //     "staff_rank": ""
    //   }
    // }
  ],
  approval_docs_id: "",
  approval_draft_line: {
    approval_line_id: "",
    approver: [
      // {
      //   approve_date: "",
      //   is_approve: true,
      //   staff_info: {
      //     staff_id: "",
      //     staff_name: "",
      //     staff_pos: "",
      //     staff_rank: "",
      //   },
      // },
    ],
    line_title: "",
    receiver: [
      // {
      //   staff_info: {
      //     staff_id: "",
      //     staff_name: "",
      //     staff_pos: "",
      //     staff_rank: "",
      //   },
      // },
    ],
    reg_info: {
      created_at: "",
      reg_id: "",
      reg_name: "",
      upd_id: "",
      upd_name: "",
      updated_at: "",
    },
    viewer: [
      // {
      //   staff_info: {
      //     staff_id: "",
      //     staff_name: "",
      //     staff_pos: "",
      //     staff_rank: "",
      //   },
      // },
    ],
  },
  approval_status: "",
  approval_type: "",
  approve_done_date: "",
  doc_info: {
    doc_id: "",
    doc_name: "",
  },
  doc_title: "",
  file_list: [
    // {
    //   file_name: "",
    // },
  ],
  reg_info: {
    created_at: "",
    reg_id: "",
    reg_name: "",
    upd_id: "",
    upd_name: "",
    updated_at: "",
  },
  writer: {
    branch_info: {
      branch_id: "",
      branch_name: "",
    },
    hq_info: {
      hq_id: "",
      hq_name: "",
    },
    staff_info: {
      staff_id: "",
      staff_name: "",
      staff_pos: "",
      staff_rank: "",
    },
  },
};

export const approval_act_permission = {
  // btn_draft: false,
  // btn_approval: false,
  // btn_recall: false,
  im_current_turn: false,
};
