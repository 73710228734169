import CardFixed from "components/Card/CardFixed";
import OpinionView from "pages/NoticeBoard/CommonItem/OpinionView";
import ReferenceDetailContent from "./ReferenceDetailContent";

const ReferenceDetailView = ({ postRowState, nowService }) => {
  return (
    <div style={{ display: "flex", gap: "1rem" }}>
      <CardFixed
        topDom={"게시글 보기"}
        midDom={<ReferenceDetailContent postRowState={postRowState} />}
        isLoading={false}
        rootStyle={{
          width: "65%",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />

      <CardFixed
        topDom={"의견 작성"}
        midDom={<OpinionView postRootState={postRowState} nowService={nowService} />}
        isLoading={false}
        rootStyle={{
          width: "35%",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />
    </div>
  );
};

export default ReferenceDetailView;
