import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
// import { memberViewAction, loadMemberViewStore } from "./Module/actions";
import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, generateObjectDummyId } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import DatePicker from "components/LabelInput/DatePicker";
import TextField from "components/LabelInput/TextField";
import TableTitleWithButton from "components/TableTitleWithButton/TableTitleWithButton";

const CertCard = forwardRef((props, ref) => {
  const [itemInfoList, setItemInfoList] = useState(props.staffInfoState.qualifications);

  useEffect(() => {
    setItemInfoList(props.staffInfoState.qualifications);
  }, [props.staffInfoState.qualifications]);

  useImperativeHandle(ref, () => ({
    getItemList: () => {
      return itemInfoList;
    },
  }));

  const btnItemPush = () => {
    const idx = itemInfoList.length;
    const baseItemInfo = {
      id: generateObjectDummyId(),
      idx: idx,
      qual_name: "",
      qual_date: "",
      qual_inst: "",
    };

    if (itemInfoList === []) {
      setItemInfoList([baseItemInfo]);
    } else {
      setItemInfoList([...itemInfoList, baseItemInfo]);
    }
    props.staffInfoState.qualifications.push(baseItemInfo);
  };

  const btnItemPop = (targetId) => {
    var array = [...itemInfoList]; // make a separate copy of the array
    const index = array.map((item) => item.id).indexOf(targetId);

    if (index !== -1) {
      array.splice(index, 1);
      props.staffInfoState.qualifications.splice(index, 1);
      setItemInfoList(array);
    }
  };

  const itemList = new itemInfoTable(btnItemPop);

  return (
    <div style={{ padding: "0.625rem 1.25rem", border: "1px solid #bbb", marginTop: "1rem" }}>
      <div style={{}}>
        <TableTitleWithButton
          titleLabel={"자격 사항"}
          buttonText={"추가"}
          onClickEvent={btnItemPush}
        />
      </div>
      <div>
        <CustomTable
          columns_head={itemList.columns_head}
          table_title={itemList.table_title}
          rest_call={itemList.get_data_from_rest}
          row_render={itemList.create_table}
          rest_data={itemInfoList}
        />
      </div>
    </div>
  );
});
export default CertCard;

class itemInfoTable {
  table_title = "";
  btnStyleClass = btnStyles();
  btnPop = null;

  constructor(btnPop) {
    this.btnPop = btnPop;
  }

  create_table = (data, key) => {
    return (
      <CustomRow key={key} style={{ width: "100%" }}>
        <CustomTableContents width="10%">
          <TextField
            justifyContent="center"
            textBoxWidth={"24rem"}
            labelMarginLeft={"0rem"}
            textMarginRight={"0rem"}
            defaultValue={data.qual_name}
            onChangeCallback={(e) => {
              data.qual_name = e;
            }}
          />
        </CustomTableContents>

        <CustomTableContents width="10%">
          <DatePicker
            justifyContent="center"
            minWidth={"12rem"}
            defaultValue={data.qual_date}
            onChangeCallback={(e) => {
              data.qual_date = e;
            }}
          />
        </CustomTableContents>
        <CustomTableContents width="10%">
          <TextField
            justifyContent="center"
            textBoxWidth={"24rem"}
            labelMarginLeft={"0rem"}
            textMarginRight={"0rem"}
            defaultValue={data.qual_inst}
            onChangeCallback={(e) => {
              data.qual_inst = e;
            }}
          />
        </CustomTableContents>
        <CustomTableContents width="5%">
          {/* {data.remove} */}
          {data.is_lock ? (
            <Button
              className={clsx({
                [this.btnStyleClass.btnRootRaw]: true,
                [this.btnStyleClass.buttonType2]: true,
              })}
              onClick={() => this.btnPop(data.id)}
            >
              삭제
            </Button>
          ) : (
            ""
          )}
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "자격증명", field: "select" },
    { title: "발급일", field: "pay_method" },
    { title: "발급처", field: "pay_method" },

    { title: "", field: "remove" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
