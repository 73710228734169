import {
  contentTypeJson,
  dataEmpty,
  generateObjectZeroId,
  restEmpty,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loadDeviceListStore } from "./Module/actions/index";
import post_api from "./Module/api/PostApi";

const DeviceManageListView = ({ onClickOpenModal, hqBranchData, branchList, isAdmin }) => {
  const storesDispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [curPage, setCurPage] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState({ branch_id: "", branch_name: "" });
  const [deviceList, setDeviceList] = useState([
    // { hq_info: "", branch_info: "", device_info: "", door_info: "" },
  ]);
  let deviceIdList = [];

  //출입장치리스트 GET (지점 바뀔때마다)
  useEffect(() => {
    if (branchList.branch_list.length > 1) {
      getDeviceList(1);
    }
  }, [selectedBranch.branch_id, branchList]);

  const getDeviceList = async (page) => {
    setCurPage(page);

    // 추후 이 API 호출은 삭제하기. 아래 것만 사용
    await HttpInstance.get(process.env.REACT_APP_ACCESS_SERVER + "/sdk/device_list", {})
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          deviceIdList = res.data.data;
        }
      })
      .catch((err) => {
        console.log("err");
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });

    await HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/access/device_list", {
      params: {
        branch_id: selectedBranch.branch_id,
        page_num: page,
      },
    })
      .then((res) => {
        const filteredList = [];
        if (!restEmpty(res.data.data)) {
          const list = res.data.data;
          if (list.length > 0) {
            for (let i = 0; i < list.length; i++) {
              if (
                deviceIdList.findIndex((x) => x.toString() === list[i].device_info.device_id) !== -1
              ) {
                list[i].device_info.status = "정상";
              } else {
                list[i].device_info.status = "연결 해제";
              }
            }

            // 권한이 있는 branch와 매칭이 되지 않는 결과물은 필터.
            for (let i = 0; i < branchList.branch_list.length; i++) {
              if (branchList.branch_list[i].branch_id === generateObjectZeroId()) continue;

              for (let j = 0; j < list.length; j++) {
                if (list[j].branch_info.branch_id === branchList.branch_list[i].branch_id) {
                  filteredList.push(list[j]);
                }
              }
            }
          }
          setTotalPage(res.data.total_page);
        }
        setDeviceList(filteredList);
      })
      .catch((err) => {
        console.log("err");
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const refreshStore = () => {
    getDeviceList();
  };

  const popWaitDeviceModal = () => {
    const param = {
      title: "대기중 장치 보기",
      modalParam: {
        hqBranchList: hqBranchData,
      },
    };
    onClickOpenModal("대기중장치보기", param, refreshStore);
  };

  const onClickReallocation = async () => {
    const clickYes = () => {
      setLoading(true);
      HttpInstance.get(process.env.REACT_APP_ACCESS_SERVER + "/access/device_reallocate", {})
        .then((res) => {
          if (!restEmpty(res.data.data) && res.data.data.length > 0) {
            let msg = "";
            for (let i = 0; i < res.data.data.length; i++) {
              msg += res.data.data[i] + "\n";
            }
            simpleAlert(storesDispatch, "알림", msg);
          }
        })
        .catch((err) => {
          console.log("err");
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        })
        .finally((e) => {
          setLoading(false);
        });
    };

    simpleAlert(
      storesDispatch,
      "모든 장치 재할당",
      "모든 장치를 재할당하시겠습니까?",
      false,
      undefined,
      clickYes,
      undefined,
    );
  };

  //문열림 버튼 POST
  const onDoorOpen = async (data) => {
    setLoading(true);
    try {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const formData = new FormData();
      formData.append("door_id", data.door_info.door_id);
      formData.append("device_id", data.device_info.device_id);
      const res = await HttpInstance.post(
        process.env.REACT_APP_ACCESS_SERVER + "/sdk/open_door",
        formData,
        config,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", "문이 열렸습니다.");
      } else {
        //성공

        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label labelText="문이 열렸습니다." />
              </>
            ),
            onBtnOk: () => {
              storesDispatch(loadDeviceListStore(selectedBranch.branch_id)); //장치리스트 갱신
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  //장치 초기화 POST
  const onResetDeviceEvent = async (mode, data) => {
    setLoading(true);
    try {
      const formData = new FormData();
      if (mode === "reset") {
        formData.append("param_info", JSON.stringify(data));
      } else {
        formData.append("device_id", data.device_info.device_id);
      }

      const resultText =
        mode === "reset" ? "장치 초기화가 완료되었습니다." : "장치 새로고침이 완료되었습니다.";
      const postApi = mode === "reset" ? post_api.setDeviceReset() : post_api.setDeviceRefresh();

      const res = await HttpInstance.post(postApi, formData, contentTypeJson);
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: <Label labelText={resultText} />,
            onBtnOk: () => {
              refreshStore();
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  //장치 초기화안내
  const onResetDevice = (data) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "장치 초기화",
        bodyText: (
          <>
            <Label labelText={`본부: ${data.hq_info.hq_name}`} />
            <Label labelText={`지점: ${data.branch_info.branch_name}`} />
            <Label labelText={`장치ID: ${data.device_info.device_id}`} />
            <Label labelText={`장치명: ${data.device_info.device_name}`} />
            <br />
            <Label labelText="장치를 초기화하시겠습니까?" />
            <Label labelText="초기화는 약 10초 정도 소요됩니다." />
          </>
        ),
        width: "20rem",
        onBtnYes: () => {
          onResetDeviceEvent("reset", data);
        },
        onBtnNo: () => {},
      }),
    );
  };

  // 장치 새로고침
  const onRefreshDevice = (data) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "장치 새로고침",
        bodyText: (
          <>
            <Label labelText={`본부: ${data.hq_info.hq_name}`} />
            <Label labelText={`지점: ${data.branch_info.branch_name}`} />
            <Label labelText={`장치ID: ${data.device_info.device_id}`} />
            <Label labelText={`장치명: ${data.device_info.device_name}`} />
            <br />
            <Label labelText="장치 새로고침을 하시겠습니까? 초기화는 되지 않습니다." />
            <Label labelText="새로고침 약 10초 정도 소요됩니다." />
          </>
        ),
        width: "20rem",
        onBtnYes: () => {
          onResetDeviceEvent("refresh", data);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const deviceInfoTableItem = new deviceInfoTable(
    onDoorOpen,
    onResetDevice,
    onRefreshDevice,
    isAdmin,
  );

  return (
    <div>
      {loading && <Loading />}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}>
          <SearchComboBox
            labelWidth={"3.25rem"}
            comboItemWidth={"11rem"}
            labelText="지점"
            defaultValue={branchList.branch_name_list[0] || ""}
            data={branchList.branch_name_list}
            onChangeCallback={(e, v) => {
              const branch = branchList.branch_list.find((el) => el.branch_name === v);
              if (branch.branch_id) {
                setSelectedBranch(branch);
              }
            }}
          />
        </div>
        {isAdmin ? (
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "0.5rem" }}>
              <NormalBtn
                name="모든 장치 재할당"
                onClick={() => {
                  onClickReallocation();
                }}
                theme="red"
              />
            </div>
            <NormalBtn
              name="대기장치 보기"
              onClick={() => {
                popWaitDeviceModal();
              }}
            />
          </div>
        ) : (
          <></>
        )}
      </div>

      <div style={{ marginTop: "1.2rem" }}>
        <CustomTable
          columns_head={deviceInfoTableItem.columns_head}
          table_title={deviceInfoTableItem.table_title}
          rest_call={deviceInfoTableItem.get_data_from_rest}
          row_render={deviceInfoTableItem.create_table}
          rest_data={deviceList}
        />
        <CustomPagination curPage={curPage} doSearch={getDeviceList} totalCnt={totalPage} />
      </div>
    </div>
  );
};
export default DeviceManageListView;

class deviceInfoTable {
  table_title = "";
  onDoorOpen = null;
  onResetDevice = null;
  onRefreshDevice = null;
  showResetDevice = null;

  constructor(onDoorOpen, onResetDevice, onRefreshDevice, showResetDevice) {
    this.onDoorOpen = onDoorOpen;
    this.onResetDevice = onResetDevice;
    this.onRefreshDevice = onRefreshDevice;
    this.showResetDevice = showResetDevice;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.hq_info.hq_name}</CustomTableContents>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.device_info.device_id}</CustomTableContents>
        <CustomTableContents>{data.device_info.device_name}</CustomTableContents>
        <CustomTableContents>{data.device_info.status}</CustomTableContents>
        <CustomTableContents $isNumber>{data.door_info.door_open_time + " 초"}</CustomTableContents>
        <CustomTableContentsEND align="center">
          <div style={{ display: "flex", justifyContent: "center", gap: "0.25rem" }}>
            <NormalBtn
              name="문열림"
              onClick={() => {
                this.onDoorOpen(data);
              }}
            />
            {this.showResetDevice ? (
              <>
                <div style={{ marginRight: "0.75rem" }}>
                  <NormalBtn
                    name="새로고침"
                    onClick={() => {
                      this.onRefreshDevice(data);
                    }}
                  />
                </div>
                <NormalBtn
                  name="초기화"
                  onClick={() => {
                    this.onResetDevice(data);
                  }}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "본부", width: "14%" },
    { title: "지점명", width: "14%" },
    { title: "장치 ID", width: "14%" },
    { title: "장치명", width: "14%" },
    { title: "연결 상태", width: "14%" },
    { title: "문열림 시간", width: "9%" },
    { title: "", width: "22%" },
  ];
  get_data_from_rest = (stateData) => {
    // console.log(stateData);
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
