import * as Actions from "./cs_action";

export const getCSTaskStores = (id) => Actions.loadTaskInfo(id);
export const getCSListStores = (curCSTarget, page_num, is_all, is_tm_recv_ok, is_tm_recv_no) =>
  Actions.loadCSList(curCSTarget, page_num, is_all, is_tm_recv_ok, is_tm_recv_no);
export const getCSInfoStores = (curCSTarget, curMemberTarget) =>
  Actions.loadCSInfo(curCSTarget, curMemberTarget);
export const getCSFilterInfoStores = () => Actions.loadCSFilterInfo();

export const getCSDetailHealthStore = (memberOid) => Actions.loadCSDetailHealthInfo(memberOid);

export const CSAction = {
  Actions,
};
