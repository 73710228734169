import { checkDateOrder, getDefaultValue, restEmpty } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import { useEffect, useState } from "react";
import { SearchConditionBox, SearchContainer, SearchRowBox } from "./style";

const SearchCondition = ({
  searchParams,
  setSearchParams,
  branchList,
  onClickSearch,
  resetFlag,
}) => {
  const labelWidth = "4.5rem";
  const textBoxWidth = "15rem";
  const textBoxWidthShort = "8.5rem";
  const labelWidthShort = "4rem";
  const [branchNameList, setBranchNameList] = useState([]);

  useEffect(() => {
    if (!restEmpty(branchList.branch_name_list) && branchList.branch_name_list.length > 0) {
      setBranchNameList(branchList.branch_name_list);
    }
  }, [branchList]);

  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);

  // 초기화 시 지점 첫번째 값 세팅
  useEffect(() => {
    if (!isFirstEnterPage && branchList.branch_list[0]) {
      setSearchParams("branch_info", branchList.branch_list[0]);
    }
  }, [resetFlag]);

  useEffect(() => {
    setIsFirstEnterPage(false);
  }, []);

  return (
    <SearchContainer>
      <Label labelText="검색 조건" fontWeight="bold" justifyContent="flex-start" />
      <SearchRowBox
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onClickSearch(1);
          }
        }}
      >
        <SearchConditionBox>
          <SearchComboBox
            labelText="지점"
            labelWidth={labelWidth}
            comboItemWidth={textBoxWidth}
            data={branchNameList}
            defaultValue={getDefaultValue("combo", searchParams.branch_info.branch_name)}
            onChangeCallback={(e, v, idx) => {
              const selectedBranchInfo = branchList.branch_list[idx];
              setSearchParams("branch_info", selectedBranchInfo);
            }}
          />
        </SearchConditionBox>

        <SearchConditionBox>
          <DatePicker
            labelText="생성일시"
            labelWidth={labelWidthShort}
            textBoxWidth={textBoxWidthShort}
            textMarginRight="0rem"
            defaultValue={getDefaultValue("field", searchParams.created_at_from)}
            onChangeCallback={(e) => {
              setSearchParams("created_at_from", e);
            }}
            onBlur={(e) => {
              if (!checkDateOrder(e, searchParams.created_at_to)) {
                setSearchParams("created_at_from", "");
              }
            }}
          />
          <DatePicker
            marginLeft="0.12rem"
            labelWidth="0.5rem"
            textBoxWidth={textBoxWidthShort}
            labelMarginRight="0.12rem"
            labelText="~"
            defaultValue={getDefaultValue("field", searchParams.created_at_to)}
            onChangeCallback={(e) => {
              setSearchParams("created_at_to", e);
            }}
            onBlur={(e) => {
              if (!checkDateOrder(searchParams.created_at_from, e)) {
                setSearchParams("created_at_to", "");
              }
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox>
          <ComboBox
            labelText="등록구분"
            labelWidth={labelWidth}
            comboItemWidth={textBoxWidth}
            data={["신규등록", "재등록"]}
            defaultValue={getDefaultValue("combo", searchParams.reg_type)}
            onChangeCallback={(e) => {
              setSearchParams("reg_type", e.target.value);
            }}
            includeAllOption
          />
        </SearchConditionBox>

        <SearchConditionBox>
          <ComboBox
            labelText="회원권 옵션"
            labelWidth={labelWidth}
            comboItemWidth={textBoxWidth}
            data={["일반권", "일일권"]}
            defaultValue={getDefaultValue("combo", searchParams.mbshp_option)}
            onChangeCallback={(e) => {
              setSearchParams("mbshp_option", e.target.value);
            }}
            includeAllOption
          />
        </SearchConditionBox>
      </SearchRowBox>
    </SearchContainer>
  );
};
export default SearchCondition;
