import styled from "styled-components";
import Main from "../../assets/main.png";

// 가장 최상위 패널
export const RootPanel = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => (props.isMain ? "#121246" : "#f4f6f9")};
`;

// 좌측 Navi 패널
export const NavPanel = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 4.2vw;
`;

// Navi를 제외한 우측 패널
export const MainPanel = styled.div`
  width: ${(props) => (props.$isMemberInfo ? "" : "100%")};
  height: 100%;
`;

// 우측 패널 중 상단 메뉴 패널
export const MainTopPanel = styled.div`
  width: 100%;
`;

// 우측 패널 중 하단의 메인 페이지 패널
export const MainBottomPanel = styled.div`
  height: 100%;
  margin-left: 11.25rem;
  margin-top: 3.75rem;
  padding: ${(props) => (props.noPadding ? "0" : "1.25rem")};
`;

export const MainBackgroundBox = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  background-image: url(${Main});
  background-size: cover;
`;
