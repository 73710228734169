const permissionParaminitial = {
  staff_id: "",
  hq_info: {
    hq_id: "",
    hq_name: "",
  },
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
};
