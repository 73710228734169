import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import {
  changeComboValueToEmpty,
  checkDateOrder,
  getDefaultValue,
  getPermission,
} from "components/CommonLib/CommonLib";
import {
  APPROVAL_TYPE_COOPERATION,
  APPROVAL_TYPE_EXP,
  APPROVAL_TYPE_HOLIDAY,
  APPROVAL_TYPE_IMP,
  APPROVAL_TYPE_INSURANCE,
  APPROVAL_TYPE_ORDER,
  APPROVAL_TYPE_REFUND_TRANS,
  APPROVAL_TYPE_SALARY,
  APPROVAL_TYPE_VAULT_CASH,
  APPROVAL_TYPE_WORK_INFO,
} from "components/CommonLib/Consts";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useApprovalListStore } from "../modules/store/approvalListState";

const SearchArea = ({ tabType, onClickSearch }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  // 페이지 구성 변수
  const leftMargin = "3rem";
  const compWidth = "12rem";
  const labelWidth = "3.5rem";
  // 페이지 상태 관리
  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);
  // 전역 상태 관리
  const {
    hqBranchBaseData,
    setHqBranchInfo,
    searchParams,
    setSearchParams,
    setHqIdx,
    hqIdx,
    resetSearchParams,
  } = useApprovalListStore().returnStoreByType(tabType);

  const [hqBranchListData, setHqBranchListData] = useState(hqBranchBaseData);
  const [branchList, setBranchList] = useState([]);

  // 권한에 따른 본부 및 지점 세팅
  useEffect(() => {
    const isIncludeHq = true;
    getPermission(storesDispatch, "APPROVAL_MENU/MANAGE", true, false, true, isIncludeHq).then(
      (res) => {
        setHqBranchListData(res);
        if (res.target_list.length > 0) {
          if (searchParams.hq_info.hq_id === "") {
            setSearchParams("hq_info", res.target_list[0].hq_info);
            setSearchParams("branch_info", res.target_list[0].branch_list[0]);
          }
          setBranchList(res.branch_list[hqIdx]);
          // ComboBox 첫번째 본부 및 지점 정보
          setHqBranchInfo("hq_info", res.target_list[0].hq_info);
          setHqBranchInfo("branch_info", res.target_list[0].branch_list[0]);
        }
      },
    );
  }, []);

  // 본부 선택에 따른 지점 리스트 다시 세팅
  useEffect(() => {
    if (hqBranchListData.branch_list) setBranchList(hqBranchListData.branch_list[hqIdx]);
    //  첫 페이지 진입이 아니고 본부 변경 시 지점 초기화 세팅
    if (!isFirstEnterPage) {
      const branchParams = {
        branch_id: hqBranchListData.target_list[hqIdx].branch_list[0].branch_id,
        branch_name: hqBranchListData.target_list[hqIdx].branch_list[0].branch_name,
      };
      setSearchParams("branch_info", branchParams);
    }
  }, [hqIdx]);

  // 첫 페이지 여부
  useEffect(() => {
    setIsFirstEnterPage(false);
  }, []);

  const getDocsType = () => {
    return [
      "전체",
      APPROVAL_TYPE_WORK_INFO,
      APPROVAL_TYPE_HOLIDAY,
      APPROVAL_TYPE_EXP,
      APPROVAL_TYPE_IMP,
      APPROVAL_TYPE_REFUND_TRANS,
      APPROVAL_TYPE_SALARY,
      APPROVAL_TYPE_INSURANCE,
      APPROVAL_TYPE_COOPERATION,
      APPROVAL_TYPE_ORDER,
      APPROVAL_TYPE_VAULT_CASH,
    ];
  };

  return (
    <>
      <div
        style={{ display: "flex" }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onClickSearch(1);
          }
        }}
      >
        <ComboBox
          labelText="본부"
          labelWidth={labelWidth}
          labelMarginRight="0rem"
          comboItemWidth={compWidth}
          data={hqBranchListData && hqBranchListData.hq_list}
          defaultValue={getDefaultValue("combo", searchParams.hq_info.hq_name)}
          onChangeCallback={(e, idx) => {
            setHqIdx(idx);
            const hqParams = {
              hq_id: hqBranchListData.target_list[idx].hq_info.hq_id,
              hq_name: e.target.value,
            };
            setSearchParams("hq_info", hqParams);
          }}
        />

        <SearchComboBox
          marginLeft={leftMargin}
          labelText="지점"
          labelWidth={labelWidth}
          labelMarginRight="0rem"
          comboItemWidth={compWidth}
          data={branchList}
          defaultValue={getDefaultValue("combo", searchParams.branch_info.branch_name)}
          onChangeCallback={(e, v, idx) => {
            const branchParams = hqBranchListData.target_list[hqIdx].branch_list[idx];
            setSearchParams("branch_info", branchParams);
          }}
        />

        <div style={{ display: "flex", marginLeft: leftMargin }}>
          <DatePicker
            labelText="기안일"
            labelWidth={labelWidth}
            textBoxWidth="11rem"
            labelMarginRight="0rem"
            textMarginRight="0rem"
            defaultValue={getDefaultValue("field", searchParams.draft_from_date)}
            onChangeCallback={(e) => {
              setSearchParams("draft_from_date", e);
            }}
            onBlur={(e) => {
              if (!checkDateOrder(e, searchParams.draft_to_date)) {
                setSearchParams("draft_from_date", "");
              }
            }}
          />
          <DatePicker
            marginLeft="0.25rem"
            labelWidth="0.5rem"
            textBoxWidth="11rem"
            labelMarginRight="0.25rem"
            labelText="~"
            defaultValue={getDefaultValue("field", searchParams.draft_to_date)}
            onChangeCallback={(e) => {
              setSearchParams("draft_to_date", e);
            }}
            onBlur={(e) => {
              if (!checkDateOrder(searchParams.draft_from_date, e)) {
                setSearchParams("draft_to_date", "");
              }
            }}
          />
        </div>
      </div>

      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <ComboBox
          labelText="서류명"
          labelWidth={labelWidth}
          labelMarginRight="0rem"
          comboItemWidth={compWidth}
          data={getDocsType()}
          defaultValue={getDefaultValue("combo", searchParams.doc_name)}
          onChangeCallback={(e) => changeComboValueToEmpty(setSearchParams, "doc_name", e)}
        />
        {tabType === "my_draft" ? (
          <ComboBox
            marginLeft={leftMargin}
            labelText="상태"
            labelWidth={labelWidth}
            labelMarginRight="0rem"
            comboItemWidth={compWidth}
            data={["전체", "임시저장", "승인대기", "반려", "회수"]}
            defaultValue={getDefaultValue("combo", searchParams.approval_status)}
            onChangeCallback={(e) => changeComboValueToEmpty(setSearchParams, "approval_status", e)}
          />
        ) : tabType === "my_approval" ? (
          <ComboBox
            marginLeft={leftMargin}
            labelText="보기"
            labelWidth={labelWidth}
            labelMarginRight="0rem"
            comboItemWidth={compWidth}
            data={["전체", "결재해야 할 문서 보기", "결재자에 포함된 문서 보기", "열람 문서 보기"]} // data 리스트 수정 시 Back-end도 수정 必!!
            defaultValue={getDefaultValue("combo", searchParams.view_type)}
            onChangeCallback={(e) => changeComboValueToEmpty(setSearchParams, "view_type", e)}
          />
        ) : (
          <TextField
            marginLeft={leftMargin}
            labelText="기안자"
            labelWidth={labelWidth}
            labelMarginRight="0rem"
            textBoxWidth={compWidth}
            defaultValue={getDefaultValue("field", searchParams.search_drafter_info.staff_name)}
            onChangeCallback={(e) => {
              const drafterParams = {
                staff_id: "",
                staff_name: e,
                staff_pos: "",
                staff_rank: "",
              };
              setSearchParams("search_drafter_info", drafterParams);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onClickSearch(1);
              }
            }}
          />
        )}

        {tabType === "received" ? (
          <div style={{ display: "flex", marginLeft: leftMargin }}>
            <DatePicker
              labelText="수신일"
              labelWidth={labelWidth}
              textBoxWidth="11rem"
              labelMarginRight="0rem"
              textMarginRight="0rem"
              defaultValue={searchParams.recv_from_date}
              onChangeCallback={(e) => {
                searchParams.recv_from_date = e;
              }}
            />
            <DatePicker
              marginLeft="0.25rem"
              labelWidth="0.5rem"
              textBoxWidth="11rem"
              labelMarginRight="0.25rem"
              labelText="~"
              defaultValue={searchParams.recv_to_date}
              onChangeCallback={(e) => {
                searchParams.recv_to_date = e;
              }}
            />
          </div>
        ) : (
          <TextField
            marginLeft={leftMargin}
            labelText="제목"
            labelWidth={labelWidth}
            labelMarginRight="0rem"
            textMarginRight="3rem"
            textBoxWidth="23rem"
            defaultValue={searchParams.doc_title}
            onChangeCallback={(e) => {
              searchParams.doc_title = e;
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onClickSearch(1);
              }
            }}
          />
        )}
      </div>

      {tabType === "received" ? (
        <div style={{ display: "flex", marginTop: "0.5rem" }}>
          <TextField
            labelText="제목"
            labelWidth={labelWidth}
            labelMarginRight="0rem"
            textBoxWidth="31rem"
            defaultValue={searchParams.doc_title}
            onChangeCallback={(e) => {
              searchParams.doc_title = e;
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onClickSearch(1);
              }
            }}
          />
        </div>
      ) : (
        <></>
      )}

      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <Button
            className={clsx({
              [btnStyleClass.btnRootWhite]: true,
              [btnStyleClass.buttonType6]: true,
            })}
            onClick={() => {
              resetSearchParams();
            }}
          >
            초기화
          </Button>
          <Button
            className={clsx({
              [btnStyleClass.btnRoot]: true,
              [btnStyleClass.buttonType6]: true,
            })}
            onClick={() => {
              onClickSearch(1);
            }}
            style={{ marginLeft: "0.3rem" }}
          >
            검색
          </Button>
        </div>
      </div>
    </>
  );
};
export default SearchArea;
