import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { listEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import CheckBox from "components/LabelInput/CheckBox";
import SubTitle from "components/PageTitle/SubTitle";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useDispatch } from "react-redux";
import RefundSelector from "../../MemberCommonItems/RefundSelector";

const SubsRefundListItem = forwardRef((props, ref) => {
  const { refundItem, index, selectPayMethod, regDate } = props;

  const storesDispatch = useDispatch();
  // const [selectPaymentList, setSelectPaymentList] = React.useState([]);
  const [refundTotalPrice, setRefundTotalPrice] = useState(0);
  const [addedRefundTotalPrice, setAddedRefundTotalPrice] = useState(0);
  const [refundPayList, setRefundPayList] = useState(refundItem.refund_detail);

  useImperativeHandle(ref, () => ({
    clearList: () => {
      console.log("clear");
      setRefundPayList([]);
    },
  }));
  // useEffect(() => {}, [selectPayMethod]);

  const onAddTotalUpdate = (totalPrice) => {
    const totalRefund = refundTotalPrice + totalPrice;
    selectPayMethod(totalRefund, index);
    setAddedRefundTotalPrice(totalPrice);
  };

  const updateRefundPayList = (data) => {
    setRefundPayList(data);
    refundItem.refund_detail = data;
  };

  // 취소할 결제 수단 선택!
  const onSelectPayment = () => {
    const curPaymentList = [];
    let totalUnpaid = 0;
    let totalPaid = 0;

    for (let i = 0; i < refundItem.refund_detail.length; i++) {
      refundItem.refund_detail[i].is_view = false;
      refundItem.refund_detail[i].selected = false;
    }

    for (let i = 0; i < refundItem.payment_list.length; i++) {
      if (refundItem.payment_list[i].payment_list_selector.selected) {
        let index = refundItem.refund_detail
          .map((item) => item.payment_id)
          .indexOf(refundItem.payment_list[i].payment_data.payment_id);
        if (index !== -1) {
          curPaymentList.push(refundItem.payment_list[i]);
          refundItem.refund_detail[index].is_view = true;
          refundItem.refund_detail[index].selected = true;
          // totalUnpaid += serviceViewState.unpaid.unpaid_list[index].unpaid_price;
        }
        totalPaid += refundItem.payment_list[i].payment_data.price;
      }
    }

    selectPayMethod(totalPaid, index);

    makeRefundTotal();
  };

  const makeRefundTotal = () => {
    var total = 0;
    for (let i = 0; i < refundItem.refund_detail.length; i++) {
      if (refundItem.refund_detail[i].selected) {
        total += refundItem.refund_detail[i].price;
      }
    }
    setRefundTotalPrice(total);
  };

  const tableWidth = "69.25rem";

  return (
    <div style={{ width: tableWidth, marginTop: "1rem", marginBottom: "1rem" }}>
      <RefundPayMethodList
        payMethodList={refundItem.payment_list}
        onSelectPayment={onSelectPayment}
      />

      <RefundSelector
        // ref={refundInfoRef}
        refundTotalPrice={refundTotalPrice}
        addedRefundTotalPrice={addedRefundTotalPrice}
        setAddedRefundTotalPrice={onAddTotalUpdate}
        refundPayList={refundPayList}
        setRefundPayList={updateRefundPayList}
        parentMenu="refund"
        regDate={regDate}
        tableWidth="75rem"
      />
    </div>
  );
});
export default SubsRefundListItem;

/////

class refundPayMethodTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onSelectPayment = null;

  constructor(onSelectPayment) {
    this.onSelectPayment = onSelectPayment;
  }

  create_table = (data) => {
    return (
      <CustomRow style={{ width: "100%" }}>
        <CustomTableContents width="15%">
          <CheckBox
            isTable
            defaultValue={data.payment_list_selector.selected}
            onChangeCallback={(e) => {
              data.payment_list_selector.selected = e.checked;
              this.onSelectPayment();
            }}
          />
        </CustomTableContents>
        <CustomTableContents width="55%">
          {data.payment_data.pay_method} ({data.payment_data.card_no})
        </CustomTableContents>
        <CustomTableContents width="30%">
          {data.payment_data.price.toLocaleString()}
        </CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "선택", field: "select" },
    { title: "항목", field: "pay_method" },
    { title: "결제금액", field: "price" },
  ];

  get_data_from_rest = (stateData) => {
    if (listEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}

//
const RefundPayMethodList = ({ payMethodList, onSelectPayment }) => {
  const refundPayMethodList = new refundPayMethodTable(onSelectPayment);

  return (
    <div style={{ width: "45rem" }}>
      <SubTitle titleText={"취소할 결제 수단"} />
      <CustomTable
        columns_head={refundPayMethodList.columns_head}
        table_title={refundPayMethodList.table_title}
        rest_call={refundPayMethodList.get_data_from_rest}
        row_render={refundPayMethodList.create_table}
        rest_data={payMethodList}
      />
    </div>
  );
};
