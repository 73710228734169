import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";

const TMHistoryResultTable = ({ openModal, searchResult, curPage, totalPage, onClickSearch }) => {
  const onClickDetail = () => {
    // openModal("show", { title: "문자내용 보기" });
  };

  const searchResultList = new resultTable(onClickDetail);

  const doSearch = (page) => {
    onClickSearch(page);
  };

  return (
    <div>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
        // width="160rem"
      />
      <CustomPagination curPage={curPage} totalCnt={totalPage} doSearch={doSearch} />
    </div>
  );
};
export default TMHistoryResultTable;

class resultTable {
  table_title = "";
  onClickDetail = null;

  constructor(onClickDetail) {
    this.onClickDetail = onClickDetail;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.member_type}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_name}</CustomTableContents>
        <CustomTableContents>{data.phone}</CustomTableContents>
        <CustomTableContents>
          {data.tm_info.cs_date + " " + data.tm_info.cs_time}
        </CustomTableContents>
        <CustomTableContents> {data.tm_info.staff_info.staff_name} </CustomTableContents>
        <CustomTableContents>{data.tm_info.tm_status}</CustomTableContents>
        <CustomTableContents>{data.tm_info.inquiry_memo}</CustomTableContents>
        <CustomTableContents>
          {data.visit_plan.visit_date + " " + data.visit_plan.visit_time}
        </CustomTableContents>
        {/* <CustomTableContentsEND>
          <IconButton type="detail" scale="1" tooltip="" onClick={this.onClickDetail} />
        </CustomTableContentsEND> */}
      </CustomRow>
    );
  };

  columns_head = [
    { title: "지점", width: "9%" },
    { title: "구분", width: "6%" },
    { title: "이름", width: "9%" },
    { title: "연락처", width: "10%" },
    { title: "TM 일시", width: "11%" },
    { title: "TM 담당자", width: "10%" },
    { title: "TM 상태", width: "7%" },
    { title: "TM 내용", width: "27%" },
    { title: "방문예약 일시", width: "11%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
