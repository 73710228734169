import * as ActionTypes from "../actions/action_type";

const initialStete = {
  stores: {
    data: [],
  },
};
export const access_log_search = (state = initialStete, action) => {
  switch (action.type) {
    case ActionTypes.AccessLogSearchSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.AccessLogSearchFailAction:
    case ActionTypes.AccessLogSearchResetAction:
      return { ...state, stores: initialStete.stores };
    default:
      return state;
  }
};

export const door_log_search = (state = initialStete, action) => {
  switch (action.type) {
    case ActionTypes.DoorLogSearchSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.DoorLogSearchFailAction:
    case ActionTypes.DoorLogSearchResetAction:
      return { ...state, stores: initialStete.stores };
    default:
      return state;
  }
};
