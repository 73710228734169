export const getHqBaseAction = "HQ/GET/HQ_BASE";
export const getHqBaseSuccessAction = "HQ/GET/HQ_BASE/SUCCESS";
export const getHqBaseFailAction = "HQ/GET/HQ_BASE/FAIL";
export const getHqBaseResetAction = "HQ/GET/HQ_BASE/RESET";

export const getHqManageViewAction = "HQ/GET/HQ_MANAGE";
export const getHqManageViewSuccessAction = "HQ/GET/HQ_MANAGE/SUCCESS";
export const getHqManageViewFailAction = "HQ/GET/HQ_MANAGE/FAIL";
export const getHqManageViewResetAction = "HQ/GET/HQ_MANAGE/RESET";

export const getBranchManageViewAction = "HQ/GET/BRANCH_MANAGE";
export const getBranchManageSuccessAction = "HQ/GET/BRANCH_MANAGE/SUCCESS";
export const getBranchManageFailAction = "HQ/GET/BRANCH_MANAGE/FAIL";
export const getBranchManageResetAction = "HQ/GET/BRANCH_MANAGE/RESET";

export const getPolicyManageViewAction = "HQ/GET/POLICY_MANAGE";
export const getPolicyManageViewSuccessAction = "HQ/GET/POLICY_MANAGE/SUCCESS";
export const getPolicyManageViewFailAction = "HQ/GET/POLICY_MANAGE/FAIL";
export const getPolicyManageViewResetAction = "HQ/GET/POLICY_MANAGE/RESET";

export const getBranchInfoAction = "HQ/GET/BRANCH_INFO";
export const getBranchInfoSuccessAction = "HQ/GET/BRANCH_INFO/SUCCESS";
export const getBranchInfoFailAction = "HQ/GET/BRANCH_INFO/FAIL";
export const getBranchInfoResetAction = "HQ/GET/BRANCH_INFO/RESET";

////staff... permission ...
export const getHqStaffAction = "HQ/GET/STAFF_LIST";
export const getHqStaffSuccessAction = "HQ/GET/STAFF_LIST/SUCCESS";
export const getHqStaffFailAction = "HQ/GET/STAFF_LIST/FAIL";
export const getHqStaffResetAction = "HQ/GET/STAFF_LIST/RESET";

export const getHqStaffBranchAction = "HQ/GET/STAFF_BRANCH_LIST";
export const getHqStaffBranchSuccessAction = "HQ/GET/STAFF_BRANCH_LIST/SUCCESS";
export const getHqStaffBranchFailAction = "HQ/GET/STAFF_BRANCH_LIST/FAIL";
export const getHqStaffBranchResetAction = "HQ/GET/STAFF_BRANCH_LIST/RESET";

export const getHqStaffPermissionAction = "HQ/GET/STAFF_PERMISSION";
export const getHqStaffPermissionSuccessAction = "HQ/GET/STAFF_PERMISSION/SUCCESS";
export const getHqStaffPermissionFailAction = "HQ/GET/STAFF_PERMISSION/FAIL";
export const getHqStaffPermissionResetAction = "HQ/GET/STAFF_PERMISSION/RESET";
