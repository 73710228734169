import IconButton from "components/Button/IconButton";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";

const ChangeBranchTable = ({ viewData }) => {
  const resultTableInst = new resultTable();

  const getTotalCnt = () => {
    if (dataEmpty(viewData)) {
      return 0;
    }
    // const countedId = [];
    // let cnt = 0;

    // for (let i = 0; i < viewData.length; i++) {
    //   if (countedId.findIndex((x) => x === viewData[i].member_info.member_no) === -1) {
    //     countedId.push(viewData[i].member_info.member_no);
    //     cnt++;
    //   }
    // }
    // return cnt;

    return viewData.length;
  };

  const getTotalPrice = () => {
    if (dataEmpty(viewData)) {
      return 0;
    }
    return viewData.reduce((sum, cur) => {
      return sum + cur.branch_transfer_price;
    }, 0);
  };

  return (
    <div style={{ marginTop: "0.5rem" }}>
      <div style={{ display: "flex", marginBottom: "0.5rem" }}>
        <IconButton type="border" />
        <Label labelText="지점 변경 인수 목록" marginLeft="0.5rem" fontWeight="bold" />
      </div>
      <CustomTable
        columns_head={resultTableInst.columns_head}
        table_title={resultTableInst.table_title}
        rest_call={resultTableInst.get_data_from_rest}
        row_render={resultTableInst.create_table}
        rest_data={viewData}
        height="18rem"
      />
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem" }}>
        <TextField
          labelText="총 인원"
          labelWidth="4.5rem"
          textBoxWidth="10rem"
          isNumber
          defaultValue={getTotalCnt().toLocaleString()}
          endAdornment={"명"}
          disabled
        />
        <TextField
          marginLeft="4rem"
          labelText="총 금액"
          labelWidth="4.5rem"
          textBoxWidth="10rem"
          textMarginRight="0rem"
          isNumber
          defaultValue={getTotalPrice().toLocaleString()}
          endAdornment={"원"}
          disabled
        />
      </div>
    </div>
  );
};
export default ChangeBranchTable;

class resultTable {
  table_title = "";

  create_table = (data, idx) => {
    return (
      <CustomRow style={{}} key={idx}>
        <CustomTableContents>{data.member_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_name}</CustomTableContents>
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.src_branch_info.branch_name}</CustomTableContents>
        <CustomTableContents $isNumber>
          {data.branch_transfer_price.toLocaleString() + "원"}
        </CustomTableContents>
        <CustomTableContents>{data.reg_info.reg_name}</CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "회원번호", width: "16.6%" },
    { title: "이름", width: "16.6%" },
    { title: "지점변경일", width: "16.6%" },
    { title: "기존지점", width: "16.6%" },
    { title: "지점변경비", width: "16.6%" },
    { title: "담당자", width: "16.6%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
