import GxCurrentSalaryList from "./GxCurrentSalaryList";

const GxSalaryInfoView = ({ salaryReportId, is_edit }) => {
  return (
    <div>
      <GxCurrentSalaryList salaryReportId={salaryReportId} is_edit={is_edit} />
    </div>
  );
};
export default GxSalaryInfoView;
