import {
  loadChangeBranchView,
  loadChangeBranchViewSuccess,
  loadChangeBranchViewFail,
  loadChangeBranchViewReset,
} from "./view_act";

export const loadChangeBranchViewStore = (member_id) => loadChangeBranchView(member_id);

export const changeBranchViewAction = {
  loadChangeBranchViewSuccess,
  loadChangeBranchViewFail,
  loadChangeBranchViewReset,
};
