import { Button } from "@material-ui/core";
import border from "assets/icon/Border.svg";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import RadioGroup from "components/LabelInput/RadioGroup";
import SubTitle from "components/PageTitle/SubTitle";

const btnStyle = {
  width: "80%",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
};

const TableTitleWithButtonWithFilter = ({
  titleLabel,
  buttonText,
  onClickEvent,
  compName,
  onChangeShowMode,
  isDisabledBtn,
}) => {
  const btnStyleClass = btnStyles();

  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "20%", display: "flex", alignItems: "center" }}>
        <img
          alt="border"
          src={border}
          style={{
            width: "1.2rem",
            height: "1.2rem",
          }}
        />

        <SubTitle titleText={titleLabel} />
      </div>

      <div style={btnStyle}>
        <RadioGroup
          // inputRef={radioSelectorRef}
          labelWidth={"10rem"}
          data={["판매중", "판매종료"]}
          isMulti={true}
          labelText="판매 상태별 보기"
          onChangeCallback={(e, v) => {
            onChangeShowMode(compName, v === "판매중" ? true : false);
          }}
          defaultValue={"판매중"}
          // defaultValue={onCheckBoolDefault(restData.can_assign)}
        />

        <Button
          className={clsx({
            [btnStyleClass.btnRootRaw]: true,
            [btnStyleClass.buttonType2]: true,
            [btnStyleClass.disabled]: isDisabledBtn,
          })}
          onClick={() => onClickEvent()}
          disabled={isDisabledBtn}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
export default TableTitleWithButtonWithFilter;
