import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import { simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import { HttpInstance } from "lib/HttpLib";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const BaseInfoCardItem = ({ curHqName, onChangeHq }) => {
  const storesDispatch = useDispatch();
  const [hqList, setHqList] = useState([]);

  useEffect(() => {
    HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/hq_set/hq_selector", { params: {} })
      .then((res) => {
        setHqList(res.data.data.hq_list);
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  }, []);

  return (
    <div style={{}}>
      <ComboBox
        labelWidth={"3.25rem"}
        comboItemWidth={"11.75rem"}
        labelText="본부명"
        data={hqList.map((item) => {
          return item.hq_name;
        })}
        defaultValue={curHqName}
        onChangeCallback={(e) => {
          const idx = hqList.findIndex((x) => x.hq_name === e.target.value);
          onChangeHq(hqList[idx].hq_id);
        }}
      />
    </div>
  );
};

const HqSelectorInfo = ({ hqBaseData, rootWidth, onChangeHq }) => {
  // console.log(hqBaseData);
  const classes = btnStyles();

  return (
    <CardFixed
      topDom={<div>본부 선택</div>}
      midDom={
        <BaseInfoCardItem
          curHqName={hqBaseData ? hqBaseData.hq_info.hq_name : ""}
          onChangeHq={onChangeHq}
        />
      }
      isLoading={false}
      rootStyle={{
        // width: "59.812rem",
        // height: "10.312rem",
        width: rootWidth,
        marginBottom: "0.625rem",
        // borderRadius: "10px",
        // backgroundColor: "#fff",
      }}
    />
  );
};
export default HqSelectorInfo;
