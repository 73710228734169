import { takeLatest, takeEvery } from "redux-saga/effects";

import {
  getFamilyViewAction,
  getFriendsViewAction,
  getBranchLinkHistViewAction,
  getDocumentsViewAction,
} from "pages/ERP/MemberView/Module/actions/action_type";
import {
  getFamilyViewData,
  getFriendsViewData,
  getBranchLinkDetailData,
  getDocumentsViewData,
} from "pages/ERP/MemberView/Module/sagas/index depth_2";

export function* reduxSagaDepth2() {
  yield takeLatest(getFamilyViewAction, getFamilyViewData);
  yield takeLatest(getFriendsViewAction, getFriendsViewData);
  yield takeLatest(getBranchLinkHistViewAction, getBranchLinkDetailData);
  yield takeLatest(getDocumentsViewAction, getDocumentsViewData);
}
