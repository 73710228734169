export const income_item_state = {
  approval_info: {
    approval_docs_id: "000000000000000000000000",
    comment_list: [],
    doc_data: "",
    doc_info: {
      approval_docs_id: "000000000000000000000000",
      doc_id: "000000000000000000000000",
      doc_name: "",
      doc_type: "",
      is_ledger_done: false,
    },
  },
  account: "",
  can_edit: true,
  create_type: "수기입력",
  item_id: "000000000000000000000000",
  memo: "",
  price: 0,
  reason: "",
  reg_date: "",
  reg_info: {
    created_at: "",
    reg_id: "000000000000000000000000",
    reg_name: "",
    reg_position: "",
    reg_rank: "",
    reg_sabun: "",
    upd_id: "000000000000000000000000",
    upd_name: "",
    upd_position: "",
    upd_rank: "",
    upd_sabun: "",
    updated_at: "",
  },
};
