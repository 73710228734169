import { refundInitState } from "./refund_state";
import * as ActionTypes from "../actions/action_type";

const initialStete = {
  stores: {
    data: refundInitState,
  },
};
export const refund_view_info = (state = initialStete, action) => {
  switch (action.type) {
    case ActionTypes.getRefundViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getRefundViewFailAction:
    case ActionTypes.getRefundViewResetAction:
      return { ...state, stores: initialStete.stores };
    default:
      return state;
  }
};
