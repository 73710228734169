import { useQuery } from "@tanstack/react-query";
import { getExtendHistory } from "apis/branch/extend_mbshp_api";
import { dailyReportKeys } from "apis/salary/query_keys_salary";

import { simpleAlert } from "components/CommonLib/CommonLib";
import { useDispatch } from "react-redux";

// [GET] 회원권 기간 추가 리스트 (운영 일지)
export const useExtendHistory = (queryData) => {
  const storesDispatch = useDispatch();

  return useQuery({
    queryKey: dailyReportKeys.extend(queryData),
    queryFn: () => getExtendHistory(queryData),
    enabled: !!queryData.hq_info.hq_id,
    retry: false,
    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });
};
