import { makeStyles } from "@material-ui/styles";

const menuBarStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "left",
  },
  drawer: {
    // paddingTop : "20px",
    width: "11.25rem",
    marginTop: "3.75rem",
  },
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  noPadding: {
    padding: 0,
  },
  button: {
    margin: "0px 1.563rem 0px",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "1.125rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#fff",
  },
  buttonActive: {
    fontWeight: "bold",
    // borderRadius: "5px",
    // backgroundColor: "#ebf1ff",
  },
  btnRoot: {
    height: "100%",
    // marginLeft : "20px",
    // marginTop: "8px",
    // justifyContent : "left !important"
  },
  subMenu: {
    // paddingLeft : "50px !important",
    // marginLeft : "30px !important",
  },
  topNavButton: {
    fontFamily: "AppleSDGothicNeo",
    fontSize: "1.125rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
  },
  topButtonList: {
    backgroundColor: "#032063",
    zIndex: 1,
    width: "8rem",
    height: "3rem",
    fontSize: "0.975rem",
    margin: 0,
    "&:hover": {
      backgroundColor: "#4C679D",
    },
  },
  btnEtc: {
    margin: "0rem",
    padding: "0rem",
    borderRadius: "5px",
    color: "#ffffff",
    fontSize: "0.95rem",
    fontFamily: "AppleSDGothicNeo",
    fontWeight: "bold",
    textDecoration: "underline",
    zIndex: 1,
    width: "5rem",
    height: "2rem",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));
export default menuBarStyles;
