import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import CardFixed from "components/Card/CardFixed";
import { tabBorder, tabStyle } from "components/CommonComp";
import TabPanel from "components/TabControl/TabPanel";
import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import GxListView from "./GxList/GxListView";

import Loading from "components/Loading/Loading/Loading";
import OtListView from "./OtList/OtListView";
import PtListView from "./PtList/PtListView";
import { useClassManageStore } from "./modules/store/classManageState";

const ClassManageView = ({ onClickOpenModal, branchInfo }) => {
  // 전역 상태 관리
  const { classManageLoading: loading } = useClassManageStore();

  const [tabIndex, setTabIndex] = useState(0);

  const onChangeTabIndex = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <>
      {loading && <Loading />}
      <CardFixed
        midDom={
          <div>
            <Box sx={{ bgcolor: "background.paper" }}>
              <Tabs
                value={tabIndex}
                onChange={onChangeTabIndex}
                textColor="inherit"
                TabIndicatorProps={{
                  style: { backgroundColor: "rgba(0, 0, 0, 0)" },
                }}
              >
                <Tab label="OT" style={tabStyle(0, tabIndex)} />
                <Tab label="PT" style={tabStyle(1, tabIndex)} />
                <Tab label="GX" style={tabStyle(2, tabIndex)} />
              </Tabs>
            </Box>

            <SwipeableViews
              index={tabIndex}
              onChangeIndex={onChangeTabIndex}
              style={tabBorder}
              animateTransitions={false}
            >
              <TabPanel value={tabIndex} index={0}>
                <OtListView onClickOpenModal={onClickOpenModal} branchInfo={branchInfo} />
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <PtListView onClickOpenModal={onClickOpenModal} branchInfo={branchInfo} />
              </TabPanel>
              <TabPanel value={tabIndex} index={2}>
                <GxListView onClickOpenModal={onClickOpenModal} branchInfo={branchInfo} />
              </TabPanel>
            </SwipeableViews>
          </div>
        }
        isLoading={false}
        rootStyle={{
          width: "100%",
          marginTop: "0.625rem",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />
    </>
  );
};

export default ClassManageView;
