import IconButton from "components/Button/IconButton";
import { contentTypeJson, dataEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import CheckBox from "components/LabelInput/CheckBox";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import SearchStaffInBranch from "pages/CommonModal/Search/SearchStaffInBranch";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";

const AddTeamModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();

  const [checkState, setCheckState] = useState([]);
  const [teamMemberList, setTeamMemberList] = useState([]);
  const viewModelRef = useRef();
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });

  const [teamName, setTeamName] = useState("");
  const [loading, setLoading] = useState(false);

  // radio check
  const onChangeCheckState = (tableIdx) => {
    setCheckState(checkState.map((item, idx) => idx === tableIdx));
  };

  const searchStaffModalDone = (checkedArr) => {
    setTeamMemberList(checkedArr);
    viewModelRef.current.setClose();
  };

  const popSearchStaffModal = (title, param, onModalDone) => {
    setModalInfo({
      show: true,
      title: title,
      Content: <SearchStaffInBranch modalParam={param} onModalDone={onModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const addTeam = async () => {
    try {
      const formData = new FormData();
      const memberInfoArr = teamMemberList.map((el, idx) => {
        const obj = {
          staff_info: {
            staff_name: el.personal_info.staff_name,
            staff_id: el.staff_id,
            staff_pos: el.base_info.position.org_name,
            staff_rank: el.base_info.rank.org_name,
          },
          is_boss: checkState[idx],
        };
        return obj;
      });

      const info = {
        branch_info: modalParam.branch_info,
        team_info: { team_name: teamName, team_id: "" },
        team_member_list: memberInfoArr,
      };

      formData.append("new_info", JSON.stringify(info));
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_STAFF_SERVER + "/pt_team/new",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        //팀 목록 테이블 갱신 + 모달닫기
        onModalDone();
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const validationCheck = () => {
    //1. 팀명 있는지 확인
    if (!teamName) {
      simpleAlert(storesDispatch, "알림", "팀명을 입력해주세요.");
    } else if (teamMemberList.length === 0) {
      simpleAlert(storesDispatch, "알림", "팀원은 최소 1명 이상으로 설정해주세요.");
    } else if (checkState.every((el) => el === false)) {
      simpleAlert(storesDispatch, "알림", "팀장을 선택해주세요.");
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="저장 하시겠습니까?" />
            </>
          ),
          onBtnYes: () => {
            //POST
            addTeam();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const deleteRow = async (data) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText={` ${data.personal_info.staff_name}을 삭제 하시겠습니까?`} />
          </>
        ),
        onBtnYes: () => {
          const newArr = teamMemberList.filter((el) => el.staff_id !== data.staff_id);
          setTeamMemberList(newArr);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const teamListData = new teamListTable(checkState, onChangeCheckState, deleteRow);

  return (
    <div style={{ width: "25rem" }}>
      {loading && <Loading />}

      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />

      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="팀명" />
      </div>

      <TextField
        fullWidth
        labelText=""
        onBlur={(e) => {
          setTeamName(e.target.value);
        }}
      />

      <div style={{ display: "flex", marginTop: "0.7rem" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="팀원 목록" />
      </div>

      <CustomTable
        columns_head={teamListData.columns_head}
        table_title={teamListData.table_title}
        rest_call={teamListData.get_data_from_rest}
        row_render={teamListData.create_table}
        rest_data={teamMemberList}
      />

      <div
        style={{ display: "flex", justifyContent: "center", gap: "0.5rem", marginTop: "1.3rem" }}
      >
        <NormalBtn
          theme="white"
          name="팀원추가"
          onClick={() => {
            const idArr = teamMemberList.map((el) => el.staff_id);
            popSearchStaffModal(
              "팀원 추가",
              {
                branch_info: modalParam.branch_info,
                mode: "multi",
                teamMemberIdList: idArr,
                teamMemberList,
              },
              searchStaffModalDone,
            );
          }}
        />
        <NormalBtn name="저장" onClick={validationCheck} />
      </div>
    </div>
  );
};
export default AddTeamModal;

class teamListTable {
  table_title = "";
  checkState = null;
  onChangeCheckState = null;
  deleteRow = null;

  constructor(checkState, onChangeCheckState, deleteRow) {
    this.checkState = checkState;
    this.onChangeCheckState = onChangeCheckState;
    this.deleteRow = deleteRow;
  }

  create_table = (data, idx) => {
    // radio check
    if (this.checkState[idx] === undefined) {
      this.checkState[idx] = false;
    } else {
      data.is_default = this.checkState[idx];
    }

    return (
      <CustomRow key={idx}>
        <CustomTableContents>
          <CheckBox
            defaultValue={this.checkState[idx]}
            isTable
            onChangeCallback={(e) => {
              if (!e.checked) {
                e.checked = true;
              } else {
                data.is_default = e.checked;
                this.onChangeCheckState(idx);
              }
            }}
          />
        </CustomTableContents>
        <CustomTableContents>{data.base_info.position.org_name}</CustomTableContents>

        <CustomTableContents>{data.personal_info.staff_name}</CustomTableContents>
        <CustomTableContentsEND align="center">
          <IconButton
            type="delete"
            onClick={() => {
              this.deleteRow(data);
            }}
          />
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "팀장", width: "25%" },
    { title: "직책", width: "25%" },
    { title: "직원명", width: "25%" },
    { title: "삭제", width: "25%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
