import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useQuery } from "@tanstack/react-query";
import { getApprovalList } from "apis/staff/approval_api";
import CardFixed from "components/Card/CardFixed";
import { tabBorder, tabStyle } from "components/CommonComp";
import { simpleAlert } from "components/CommonLib/CommonLib";
import Loading from "components/Loading/Loading/Loading";
import TabPanel from "components/TabControl/TabPanel";
import { globalModalOn } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import FavoriteDraftListModal from "../Modal/FavoriteDraftListModal";
import CompletedDocsTab from "./ApprovalTabs/CompletedDocsTab";
import MyApprovalDocsTab from "./ApprovalTabs/MyApprovalDocsTab";
import MyDraftDocsTab from "./ApprovalTabs/MyDraftDocsTab";
import ReceivedDocsTab from "./ApprovalTabs/ReceivedDocsTab";
import { useApprovalListStore } from "./modules/store/approvalListState";

const ApprovalListView = () => {
  const navigate = useNavigate();
  const storesDispatch = useDispatch();

  // 페이지 상태 관리
  const [tabType, setTabType] = useState("my_draft");
  const [searchResult, setSearchResult] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [resultSummary, setResultSummary] = useState({
    approve: 0,
    recall: 0,
    reject: 0,
    wait: 0,
  });
  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);
  // 전역 상태 관리
  const { searchParams, page, setPage, tabIndex, setTabIndex } =
    useApprovalListStore().returnStoreByType(tabType);
  // API 상태 관리
  const [isGetApprovalList, setIsGetApprovalList] = useState(false);
  const queryData = { type: tabType, page: page, searchParams: searchParams };

  // [GET] 전자결재 문서 내역 리스트 검색
  const { isFetching, data: approvalListData } = useQuery({
    queryKey: ["approval", queryData],
    queryFn: () => getApprovalList(queryData),
    enabled: isGetApprovalList,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setIsGetApprovalList(false);
    },
  });

  // API 받아온 데이터 세팅
  useEffect(() => {
    if (approvalListData) {
      setSearchResult(approvalListData.data.data);
      setTotalPage(approvalListData.data.total_page);
      if (approvalListData.data.summary_data) {
        setResultSummary(approvalListData.data.summary_data);
      }
    }
  }, [approvalListData]);
  // 페이지 진입 시 결과 리스트
  useEffect(() => {
    if (isFirstEnterPage && searchParams.hq_info.hq_id !== "") {
      setIsGetApprovalList(true);
      setIsFirstEnterPage(false);
    }
  }, [searchParams.hq_info.hq_id]);
  // 페이지 진입 시 TabIndex 세팅
  useEffect(() => {
    const event = "event";
    onChangeTabIndex(event, tabIndex);
  }, []);

  // 검색 클릭 시 리스트 받아오기
  const onClickSearch = async (page) => {
    if (searchParams.hq_info.hq_id === "" || searchParams.branch_info.branch_id === "") {
      simpleAlert(storesDispatch, "알림", "본부 및 지점을 선택해주세요.");
      return;
    }
    setIsGetApprovalList(true);
    setPage(page);
  };

  const onChangeTabIndex = (event, newIndex) => {
    setTabIndex(newIndex);
    setIsGetApprovalList(true);
    switch (newIndex) {
      case 0:
        setTabType("my_draft");
        break;
      case 1:
        setTabType("my_approval");
        break;
      case 2:
        setTabType("completed");
        break;
      case 3:
        setTabType("received");
        break;
      default:
        break;
    }
  };

  const onSelectFavorite = () => {};

  const onClickFavoriteBtn = () => {
    const param = {};

    storesDispatch(
      globalModalOn({
        show: true,
        title: "자주 쓰는 결재선 목록",
        Content: <FavoriteDraftListModal modalParam={param} onModalDone={onSelectFavorite} />,
      }),
    );
  };

  const onClickNewDraft = () => {
    navigate("/approval_info", {
      state: {
        mode: "new",
      },
    });
  };

  return (
    <CardFixed
      midDom={
        <div>
          {isFetching && <Loading />}

          <Tabs
            value={tabIndex}
            onChange={onChangeTabIndex}
            textColor="inherit"
            sx={{ minHeight: "40px" }}
            TabIndicatorProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0)" } }}
          >
            <Tab sx={{ minHeight: "40px" }} label="내 기안 문서" style={tabStyle(0, tabIndex)} />
            <Tab sx={{ minHeight: "40px" }} label="결재할 문서" style={tabStyle(1, tabIndex)} />
            <Tab sx={{ minHeight: "40px" }} label="완료된 문서" style={tabStyle(2, tabIndex)} />
            <Tab sx={{ minHeight: "40px" }} label="수신함" style={tabStyle(3, tabIndex)} />
          </Tabs>

          <SwipeableViews
            index={tabIndex}
            onChangeIndex={onChangeTabIndex}
            style={tabBorder}
            animateTransitions={false}
          >
            <TabPanel value={tabIndex} index={0}>
              <MyDraftDocsTab
                onClickFavorite={onClickFavoriteBtn}
                onClickNewDraft={onClickNewDraft}
                searchResult={searchResult}
                totalPage={totalPage}
                onClickSearch={onClickSearch}
                resultSummary={resultSummary}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <MyApprovalDocsTab
                onClickFavorite={onClickFavoriteBtn}
                onClickNewDraft={onClickNewDraft}
                searchResult={searchResult}
                totalPage={totalPage}
                onClickSearch={onClickSearch}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              <CompletedDocsTab
                onClickFavorite={onClickFavoriteBtn}
                onClickNewDraft={onClickNewDraft}
                searchResult={searchResult}
                totalPage={totalPage}
                onClickSearch={onClickSearch}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
              <ReceivedDocsTab
                onClickFavorite={onClickFavoriteBtn}
                onClickNewDraft={onClickNewDraft}
                searchResult={searchResult}
                totalPage={totalPage}
                onClickSearch={onClickSearch}
              />
            </TabPanel>
          </SwipeableViews>
        </div>
      }
      isLoading={false}
      rootStyle={{
        // width: "20rem",
        // height: "45rem",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};
export default ApprovalListView;
