import { contentTypeJson, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

export const uploadFile = async (newInfo, file, uploadDoneCallback) => {
  //newInfo : contract_doc_list에서 하나의 원소
  //file: 업로드한 파일형식
  //uploadDoneCallback : 업로드 끝난뒤 실행할 함수

  if (!validateFileName(file.name)) {
    const result = {
      error: "파일명에 특수문자가 포함되어 있습니다.",
    };
    if (uploadDoneCallback) {
      uploadDoneCallback(result);
    }
    return false;
  } else {
    try {
      const formData = new FormData();
      formData.append("new_info", JSON.stringify(newInfo.file_info));
      formData.append("the_file", file);
      formData.append("is_full_doc", newInfo.is_full_doc);
      if (newInfo.payment_id) {
        formData.append("payment_id", newInfo.payment_id);
      }
      if (newInfo.purchase_code) {
        formData.append("purchase_code", newInfo.purchase_code);
      }

      const res = await HttpInstance.post(
        process.env.REACT_APP_AUTH_SERVER + "/file/upload",
        formData,
        contentTypeJson,
      );
      const result = res.data.data;
      result.is_use = true; // 사용여부를 true로 바꿔주기 ??? 왜???
      if (uploadDoneCallback) {
        uploadDoneCallback(result);
      }
      return true;
      //
    } catch (err) {
      //
      console.log("upload err:", err);
      const result = {
        error: err,
      };
      if (uploadDoneCallback) {
        uploadDoneCallback(result);
      }
      return false;
    }
  }
};

const validateFileName = (fileName) => {
  const invalidChar = ["/", "\\", ":", "*", "?", '"', "<", ">", "|"];
  for (let i = 0; i < invalidChar.length; i++) {
    if (fileName?.includes(invalidChar[i])) {
      return false;
    }
  }
  return true;
};

// 서버에 업로드된 파일 리스트 가져오기
export const getUploadedFiles = async (storesDispatch, fileGroupID, setFileList) => {
  try {
    if (fileGroupID !== "") {
      const res = await HttpInstance.get(
        process.env.REACT_APP_AUTH_SERVER + "/file/uploaded/" + fileGroupID,
      );
      if (!restEmpty(res.data.data)) {
        setFileList(res.data.data);
      }
    }
  } catch (err) {
    console.log(err);
    simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
  }
};
