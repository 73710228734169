// 운영일지
export const dailyReportKeys = {
  all: ["dailyReport"],

  // ---- 운영일지 리스트 ----
  // [GET] 운영일지 검색 조건 받아오기 및 권한 데이터 세팅
  firstSearchParam: () => [...dailyReportKeys.all, "firstSearchParam"],
  // [GET] 운영일지 내역 리스트 검색
  list: (queryData) => [...dailyReportKeys.all, "list", queryData],
  // [GET] 운영일지 상세 데이터
  detail: (queryData) => [...dailyReportKeys.all, "detail", queryData],

  // ---- 운영일지 현황 탭 ----
  // [GET] 회원권 기간 추가 리스트 (운영 일지)
  extend: (queryData) => [...dailyReportKeys.all, "extend", queryData],
  // [GET] 운영일지 월간 매출
  monthlySales: (queryData) => [...dailyReportKeys.all, "monthlySales", queryData],
  // [GET] 운영일지 미납금 현황
  unpaidStatus: (queryData) => [...dailyReportKeys.all, "unpaidStatus", queryData],
  // [GET] 운영일지 미납금 제외 처리 목록
  unpaidExclusion: (queryData) => [...dailyReportKeys.all, "unpaidExclusion", queryData],
  // [GET] 운영일지 환불 요청
  transferReq: (queryData) => [...dailyReportKeys.all, "transferReq", queryData],
  // [GET] 운영일지 시재금 현황
  vaultCash: (queryData) => [...dailyReportKeys.all, "vaultCash", queryData],
};
