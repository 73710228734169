import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import TeamRecordWrapper from "./TeamRecordWrapper";
const TeamRecordService = () => {
  return (
    <PageFrame
      children={
        <div>
          <PageTitle titleText="팀 실적" />
          <TeamRecordWrapper />
        </div>
      }
    />
  );
};

export default TeamRecordService;
