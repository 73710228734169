import { useState } from "react";
import ScheduleCalendar from "./ScheduleCalendar";
import ScheduleSearchCard from "./ScheduleSearchCard";

const ScheduleView = ({
  searchParam,
  setSearchParam,
  onClickSearch,
  searchResult,
  setSearchResult,
  onClickOpenModal,
  setCurPage,
  totalPage,
  curPage,
  staffList,
}) => {
  const [mode, setMode] = useState("month");

  return (
    <div>
      <div
        style={{ display: "flex", width: "100%", gap: "0.5rem", justifyContent: "space-between" }}
      >
        <div style={{ width: "77%" }}>
          <ScheduleCalendar
            onClickOpenModal={onClickOpenModal}
            searchParam={searchParam}
            setSearchParam={setSearchParam}
            onClickSearch={onClickSearch}
            searchResult={searchResult}
            setSearchResult={setSearchResult}
            mode={mode}
            setMode={setMode}
            setCurPage={setCurPage}
            totalPage={totalPage}
            curPage={curPage}
            staffList={staffList}
          />
        </div>
        <div style={{ width: "23%" }}>
          <ScheduleSearchCard
            mode={mode}
            searchParam={searchParam}
            setSearchParam={setSearchParam}
            onClickSearch={onClickSearch}
            curPage={curPage}
            setCurPage={setCurPage}
          />
        </div>
      </div>
    </div>
  );
};
export default ScheduleView;
