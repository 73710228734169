import { Button } from "@mui/material";

const NormalBtn = (props) => {
  //필수 name (버튼명) + 테마 선택(기본:blueStyle)

  const btnTextStyle = {
    fontWeight: "400",
    fontsize: "0.875rem",
    borderRadius: "5px",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.938rem",
    lineHeight: "normal",
    letterSpacing: "normal",
    padding: props.padding ? props.padding : "0.6rem 1rem 0.5rem 1rem",
    minWidth: props.width ? props.width : "5.375rem",
    margin: props.margin,
    height: props.height ? props.height : "2.25rem",
  };

  const whiteStyle = {
    ...btnTextStyle,
    border: props.disabled ? "1px solid #777777" : "1px solid #032063",
    backgroundColor: props.disabled ? "#C6C6C6" : "#FFFFFF",
    color: props.disabled ? "#777777" : "#032063",
  };
  const grayStyle = {
    ...btnTextStyle,
    border: props.disabled ? "1px solid #777777" : "1px solid #BBBBBB",
    backgroundColor: props.disabled ? "#C6C6C6" : "#FFFFFF",
    color: props.disabled ? "#777777" : " #222222",
  };
  const blueStyle = {
    ...btnTextStyle,
    margin: props.margin,
    backgroundColor: props.disabled ? "#C6C6C6" : "#032063",
    color: props.disabled ? "#777777" : " #FFFFFF",
  };
  const greenStyle = {
    ...btnTextStyle,
    backgroundColor: props.disabled ? "#C6C6C6" : "#217346",
    color: props.disabled ? "#777777" : " #FFFFFF",
  };
  const redStyle = {
    ...btnTextStyle,
    backgroundColor: props.disabled ? "#C6C6C6" : "#8a2929",
    color: props.disabled ? "#777777" : " #FFFFFF",
  };
  const redOutlinedStyle = {
    ...btnTextStyle,
    border: props.disabled ? "1px solid #777777" : "1px solid #8a2929",
    backgroundColor: props.disabled ? "#C6C6C6" : "#FFFFFF",
    color: props.disabled ? "#777777" : "#8a2929",
  };
  const hiddenStyle = {
    ...btnTextStyle,
    visibility: "hidden",
  };

  const btnColorTheme = () => {
    if (props.theme === "blue") {
      return blueStyle;
    } else if (props.theme === "gray") {
      return grayStyle;
    } else if (props.theme === "white") {
      return whiteStyle;
    } else if (props.theme === "green") {
      return greenStyle;
    } else if (props.theme === "red") {
      return redStyle;
    } else if (props.theme === "redOutlined") {
      return redOutlinedStyle;
    } else if (props.theme === "hidden") {
      return hiddenStyle;
    } else {
      return blueStyle;
    }
  };

  return (
    <Button style={btnColorTheme()} onClick={props.onClick} disabled={props.disabled}>
      {props.name}
    </Button>
  );
};

export default NormalBtn;
