import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnSubmitStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, generateObjectZeroId, simpleAlert } from "components/CommonLib/CommonLib";
import DocInfo from "components/File/DocInfo";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import {
  payListActions,
  subsPayListActions,
} from "pages/ERP/MemberCommonItems/Module/actions/index";
import PaymentInfo from "pages/ERP/MemberCommonItems/PaymentInfo";
import PersonalInfo from "pages/ERP/MemberCommonItems/PersonalInfo";
import RegisterPaidDate from "pages/ERP/MemberCommonItems/RegisterPaidDate";
import SubscriptionInfo from "pages/ERP/MemberCommonItems/SubscriptionInfo";
import { registerSettingAction } from "pages/ERP/MemberRegister/Register/Module/actions";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChangeItemContents from "./ChangeItemContents";
import ChangeItemModalIndex from "./Modal/ChangeItemModalIndex";
import post_api from "./Module/api/PostApi";

const ChangeItemView = ({
  serviceViewState,
  memberInputState,
  branchId,
  memberBaseInfoState,
  navigate,
  initRegDate,
}) => {
  const [regDate, setRegDate] = useState(initRegDate);
  const [isSubsNow, setIsSubsNow] = useState(false);
  const [canEnroll, setCanEnroll] = useState(false);
  const [docList, setDocList] = useState([]);
  const [payMethodList, setPayMethodList] = useState([]);
  const [curSettingOption, setCurSettingOption] = useState({
    is_only_prepay: false,
    is_only_merged: false,
    selected_item_id: "",
    is_only_pt: false, // PT 상품 선택 시
    is_pt_merged: false, // 변경상품에서 회원권+PT 결합상품 선택 시 true
    remain_pt: 0,
    isSelectedItem: false,
  });
  const [loading, setLoading] = useState(false);

  const baseInfoState = useSelector((state) => state.member_service.register_settings);
  const btnStyleClass = btnSubmitStyles();
  const paymentInfoRef = useRef();
  const contentsInfoRef = useRef();
  const subscriptInfoRef = useRef();
  const storesDispatch = useDispatch();

  // console.log("memberInputState");
  // console.log(memberInputState);
  // console.log("serviceViewState");
  // console.log(serviceViewState);
  // console.log(regDate);
  // console.log("---------------------- INIT DONE --------------------");
  // console.log("");
  // console.log("");

  useEffect(() => {
    setRegDate(initRegDate);
    storesDispatch(
      registerSettingAction.registerSettingInfo(
        branchId,
        curSettingOption.is_only_prepay,
        curSettingOption.is_only_merged,
        curSettingOption.selected_item_id,
        curSettingOption.remain_pt,
      ),
    );
    return () => {};
  }, []);

  useEffect(() => {
    paymentInfoRef.current.setRefundPaidResult(payMethodList);
  }, [payMethodList]);

  useEffect(() => {
    // 결제정보 초기화
    payMethodList.forEach((it) => (it.pay_method = []));
    paymentInfoRef.current.resetPaidResult();
  }, [regDate]);

  //파일 초기화
  useEffect(() => {
    if (memberInputState.contract_doc_list.length !== 0) {
      setDocList(memberInputState.contract_doc_list);
    }
  }, [memberInputState.contract_doc_list.length]);

  const validationFlow = () => {
    const refundList = serviceViewState.refund_item_info.refund_list;
    for (let i = 0; i < refundList.length; i++) {
      if (refundList[i].selected) {
        if (refundList[i].done_refund_price < refundList[i].total_refund_price) {
          return "[" + refundList[i].purchase_code + "]의 환불 처리가 완료되지 않았습니다.";
        }
        for (let j = 0; j < refundList[i].refund_detail.length; j++) {
          if (refundList[i].refund_detail[j].selected) {
            if (
              !refundList[i].refund_detail[j].cancel_type ||
              refundList[i].refund_detail[j].pay_date.length < 16
            ) {
              return (
                "[" +
                refundList[i].purchase_code +
                "]의 환불 취소일시 또는 구분이 선택되지 않았습니다."
              );
            }
          }
        }
      }
    }

    if (curSettingOption.is_only_pt) {
      if (memberInputState.pt_info.default_session < 1) {
        return "PT 기본 세션이 입력되지 않았습니다.";
      }
    } else {
      if (
        memberInputState.membership_all_list[0].purchase_item_list.item_info.item_id ===
        generateObjectZeroId()
      ) {
        return "회원권이 선택되지 않았습니다.";
      }
    }

    return "";
  };

  const validationContent = () => {
    // 1. 변경할 상품을 선택했으면, 변경상품을 선택해야 함
    // 2. 환불신청내역에서 환불금액 있으면 환불완료를 시켜야 함.
    // 3. 차액이 있으면 결제를 완료해야 함
    let res = "";

    const itemList = contentsInfoRef.current.getItemList();

    if (
      itemList.findIndex((x) => x.pType === "회원권") !== -1 &&
      !memberInputState.cs_staff.staff_name
    )
      res += "· CS 상담자\n";
    if (
      memberInputState.pt_info.default_session > 0 &&
      !memberInputState.pt_info.cs_staff.staff_name
    )
      res += "· PT 상담자\n";
    // if (
    //   memberInputState.pt_info.default_session > 0 &&
    //   !memberInputState.pt_info.pt_staff.staff_name
    // )
    //   res += "· PT 수업 담당자\n";

    if (
      memberInputState.membership_all_list[0].membership_list.mbshp_type === "구독형" &&
      !memberInputState.regular_pay_method.relation
    ) {
      res += "· 정기결제수단\n";
    }

    return res;
  };

  const onClickOpenModal = (order, param) => {
    if (order !== "") {
      // lockerModelRef.current.setOpen();

      openModal(order, param);
    }
  };

  const onClickOpenPayModal = () => {
    // console.log(contentsInfoRef.current.getPtDownGradeCheck());

    // if (contentsInfoRef.current.getPtDownGradeCheck()) {
    //   // simpleAlert(storesDispatch, "ERROR", "PT 횟수 차감시 결제수단을 모두 선택해야합니다.");
    //   return
    // } el
    const payDataList = [];
    for (let i = 0; i < payMethodList.length; i++) {
      payDataList.push({
        purchase_code: payMethodList[i].purchase_code,
        repaid_price: payMethodList[i].total_price,
      });
    }

    const param = {
      title: "결제",
      modalParam: {
        mode: "change",
        payMethodList: _.cloneDeep(payMethodList),
        payDataList: payDataList,
        regDate: regDate,
      },
    };
    openModal("결제", param);
    // }
  };

  const openModal = (select, param) => {
    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,
        Content: (
          <ChangeItemModalIndex modalSelect={select} modalParam={param} onModalDone={onModalDone} />
        ),
      }),
    );
  };

  const onModalDone = (modalType, param1) => {
    if (modalType === "결제") {
      setPayMethodList(param1);
      paymentInfoRef.current.setRefundPaidResult(param1);

      for (let i = 0; i < param1.length; i++) {
        const idx = serviceViewState.paymentA.payment_info.findIndex(
          (x) => x.purchase_code === param1[i].purchase_code,
        );
        if (idx !== -1) {
          serviceViewState.paymentA.payment_info[idx].paid_item_list.push({
            MembershipIdx: 0,
            type_key: "재결제",
            orderIdx: 0,
            content_obj: {
              dis: 0,
              item_type: "재결제/없음/없음/없음/없음",
              pay_order: 0,
              price: param1[i].total_paid,
              tab_idx: 0,
              type: "재결제",
            },
          });
          serviceViewState.paymentA.payment_info[idx].payment_input = param1[i];
          serviceViewState.paymentA.payment_info[idx].paid_price = param1[i].total_paid;
          serviceViewState.paymentA.payment_info[idx].unpaid_price =
            param1[i].unpaid_info.unpaid_price;
        }
      }

      let flag = true;
      for (let i = 0; i < param1.length; i++) {
        if (param1[i].pay_method.length === 0) {
          flag = false;
        }
      }

      setCanEnroll(flag);
    } else if (modalType === "정기결제수단") {
      memberInputState.regular_pay_method = param1;
    }

    storesDispatch(globalModalOff());
  };

  // 구독형 회원권이 있으면 sub_payment 데이터 입력
  const setSubsPaymentData = () => {
    const curMbshpList = memberInputState.membership_all_list[0].membership_list;
    const mbshpType = curMbshpList.mbshp_type;

    if (mbshpType === "구독형") {
      const itemList = subscriptInfoRef.current.getPaidItemList();
      const subPayment = {
        paid_item_list: itemList,
        payment_info: memberInputState.regular_pay_method,
        tab_info: {
          MembershipIdx: 0,
          idx: 0,
          title: "신규",
        },
        view_info: {
          is_use: true,
          monthly_total: 0,
          raw_paid: 0,
          total_paid: 0,
        },
      };

      curMbshpList.sub_payment = subPayment;
    }
  };

  const onClickSave = () => {
    const valiFlowText = validationFlow();
    if (valiFlowText !== "") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText={valiFlowText} />,
          onBtnOk: () => {},
        }),
      );
    } else {
      const validationRes = validationContent();

      if (validationRes !== "") {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: (
              <>
                <Label labelText="필수값을 모두 입력해주셔야 합니다." />
                <br />
                <br />
                <Label labelText={validationRes} whiteSpace="pre-wrap" />
              </>
            ),
            onBtnOk: () => {},
          }),
        );
      } else {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: <Label labelText="상품변경 처리하시겠습니까?" />,
            onBtnYes: () => {
              onSaveEvent();
            },
            onBtnNo: () => {},
          }),
        );
      }
    }
  };

  const postProcessPayment = () => {
    // COPY paid_item_list
    const prevPaidItemList = contentsInfoRef.current.getItemList();
    // console.log("prev", prevPaidItemList);
    const newPaidItemList = [];
    for (let i = 0; i < prevPaidItemList.length; i++) {
      newPaidItemList.push({
        MembershipIdx: prevPaidItemList[i].detailInfo.tab_idx,
        type_key: prevPaidItemList[i].pType,
        content_obj: prevPaidItemList[i].detailInfo,
        orderIdx: prevPaidItemList[i].ord,
      });
    }

    const basePurchaseCode = contentsInfoRef.current.getBasePurchaseCode();
    const paymentInfo = serviceViewState.paymentA.payment_info;
    const replaceIdx = paymentInfo.findIndex((x) => x.purchase_code === "상품변경 추가금");

    if (replaceIdx === -1) {
      // INPUT paid_item_list
      const baseIdx = paymentInfo.findIndex((x) => x.purchase_code === basePurchaseCode);
      paymentInfo[baseIdx].paid_item_list = newPaidItemList;
    } else {
      // REPLACE PURCHASE CODE
      if (paymentInfo[replaceIdx].purchase_code === "상품변경 추가금") {
        paymentInfo[replaceIdx].purchase_code = basePurchaseCode;
        paymentInfo[replaceIdx].payment_input.purchase_code = basePurchaseCode;
        paymentInfo[replaceIdx].paid_item_list = newPaidItemList;
      }
    }
  };

  const onSaveEvent = async () => {
    if (isChangePtDownGradeCheck()) {
      simpleAlert(storesDispatch, "ERROR", "PT 횟수 차감시 결제수단을 모두 선택해야합니다...");
    }

    // SET DATA
    setSubsPaymentData();
    serviceViewState.new_membership_info = memberInputState.membership_all_list[0];
    serviceViewState.pt_info = memberInputState.pt_info;
    serviceViewState.reg_date = regDate;
    serviceViewState.cs_staff = memberInputState.cs_staff;
    postProcessPayment();

    const formData = new FormData();

    //파일 분기처리 필요!! (일단 현재는 다 is_use === true로 해서 전송)
    serviceViewState.contract_doc_list = docList;
    serviceViewState.contract_doc_list.forEach((el) => (el.is_use = true));
    //세 -> 임시추가.... 요기까지 2줄

    formData.append("new_info", JSON.stringify(serviceViewState));
    const postApi = post_api.changeItem();

    // console.log(JSON.stringify(serviceViewState));

    setLoading(true);

    await HttpInstance.post(postApi, formData, contentTypeJson)
      .then((res) => {
        // console.log(res);
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          storesDispatch(
            globalAlertOn({
              show: true,
              titleText: "알림",
              bodyText: (
                <Label labelText="상품변경이 완료되었습니다. 회원정보 페이지로 이동합니다." />
              ),
              reqConfirm: true,
              onBtnOk: () => {
                navigate("/member_info/" + memberBaseInfoState.member_id);
              },
            }),
          );
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const payInfoListAction = (act, pType, detailInfo, ord) => {
    if (detailInfo === undefined) {
      return;
    }
    if (act === "push") {
      storesDispatch(payListActions.paymentInfoPush(pType, detailInfo, ord));
    } else if (act === "pop") {
      storesDispatch(payListActions.paymentInfoPop(pType));
    } else if (act === "discount") {
      storesDispatch(payListActions.paymentInfoEdit(pType, detailInfo, ord));
    }
  };

  const payInfoListDummy = (act, pType, detailInfo, ord) => {
    if (act === "push") {
      contentsInfoRef.current.pushItem(act, pType, detailInfo, ord);
    } else if (act === "pop") {
      contentsInfoRef.current.popItem(pType);
    } else if (act === "discount") {
      contentsInfoRef.current.disItem(pType, detailInfo, ord);
    }
  };

  const subsPayInfoListAction = (act, typeKey, contentObj, ordIdx, mbshpIdx) => {
    if (act === "push") {
      storesDispatch(subsPayListActions.subsPayInfoPush(typeKey, contentObj, ordIdx, mbshpIdx));
    } else if (act === "pop") {
      storesDispatch(subsPayListActions.subsPayInfoPop(typeKey));
    } else if (act === "discount") {
      storesDispatch(subsPayListActions.subsPayInfoEdit(typeKey, contentObj, ordIdx, mbshpIdx));
    }
  };

  const resetPaySubInfoListAction = () => {
    storesDispatch(payListActions.paymentInfoClear("clear"));
    storesDispatch(subsPayListActions.subsPayInfoClear("clear"));
  };

  const uploadDoneCallback = (result) => {
    //업로드에서 받아온 파일을 contract_doc_list에 갱신
    const idx = docList.findIndex((el) => el.doc_id === result.doc_id);
    setDocList((cur) => {
      const arr = [...cur];
      arr[idx] = result;
      return arr;
    });
  };
  const isChangePtDownGradeCheck = () => {
    // console.log(contentsInfoRef.current);

    if (contentsInfoRef.current) {
      if (contentsInfoRef.current.getIsPtChange()) {
        // console.log(contentsInfoRef.current.getIsPtChange());
        return contentsInfoRef.current.getPtDownGradeCheck();
      }
    }
    return false;
  };

  return (
    <div style={{ display: "flex" }}>
      {loading ? <Loading /> : <></>}

      <div style={{ width: "76.88rem" }}>
        <PersonalInfo
          memberBaseInfoState={memberBaseInfoState.base_info.personal_info}
          onClickOpenModal={onClickOpenModal}
          memberInputState={{
            branch_info: memberBaseInfoState.branch_info,
            card_param: memberBaseInfoState.access,
          }}
        />

        <ChangeItemContents
          ref={contentsInfoRef}
          payInfoListAction={payInfoListAction}
          payInfoListDummy={payInfoListDummy}
          serviceViewState={serviceViewState}
          baseInfoState={baseInfoState.stores.data}
          onClickOpenModal={onClickOpenModal}
          subsPayInfoListAction={subsPayInfoListAction}
          memberInputState={memberInputState}
          curSettingOption={curSettingOption}
          setCurSettingOption={setCurSettingOption}
          resetPaySubInfoListAction={resetPaySubInfoListAction}
          setIsSubsNow={setIsSubsNow}
          payMethodList={payMethodList}
          setPayMethodList={setPayMethodList}
          regDate={regDate}
          setCanEnroll={setCanEnroll}
        />
      </div>

      <div style={{ width: "28.13rem", marginLeft: "0.625rem" }}>
        <PaymentInfo
          ref={paymentInfoRef}
          onClickOpenModal={onClickOpenPayModal}
          regDate={regDate}
          baseInfoState={{
            branch_setting: {
              branch_info: memberBaseInfoState.branch_info,
            },
          }}
          setCanEnroll={setCanEnroll}
          isAddPay={false}
          isRefund={true}
          isChangePtDownGradeCheck={isChangePtDownGradeCheck()}
        />

        {isSubsNow ? (
          <SubscriptionInfo
            ref={subscriptInfoRef}
            onClickOpenModal={onClickOpenModal}
            memberInputState={memberInputState}
            isAddPay={false}
            regularPayDate={baseInfoState.stores.data.erp_setting.regular_pay_date}
          />
        ) : (
          <div></div>
        )}

        <RegisterPaidDate regDate={regDate} setRegDate={setRegDate} isDisabled />
        {/* <DocumentInfo /> */}
        <DocInfo docList={docList} uploadDoneCallback={uploadDoneCallback} textBoxWidth="11rem" />
        <Button
          style={{ marginTop: "1.5rem" }}
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType2]: true,
            [btnStyleClass.disabled]: !canEnroll,
          })}
          onClick={() => onClickSave()}
          disabled={!canEnroll}
        >
          등록
        </Button>

        {/* <Button
          style={{ marginTop: "1.5rem" }}
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType2]: true,
          })}
          onClick={() => {
            // console.log("serviceViewState");
            // console.log(serviceViewState);
            // console.log("memberInputState");
            // console.log(memberInputState);
            console.log("-------TEST------");
            console.log(payMethodList);
            console.log(serviceViewState);
            console.log(memberInputState);
            console.log("-----------------");
            // console.log(serviceViewState.refund_item_info.refund_list[0]);

            // console.log(payMethodList);
            // console.log(contentsInfoRef.current.getItemList());
          }}
        >
          TEST BTN
        </Button> */}
      </div>
    </div>
  );
};
export default ChangeItemView;
