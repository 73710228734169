import {
  loadPrePaidItems,
  loadPrePaidItemsSuccess,
  loadPrePaidItemsFail,
  loadPrePaidItemsReset,
  loadSubScriptionItems,
  loadSubScriptionItemsSuccess,
  loadSubScriptionItemsFail,
  loadSubScriptionItemsReset,
  loadETCItems,
  loadETCItemsSuccess,
  loadETCViewFail,
  loadETCItemsReset,
  loadItemsView,
  loadItemsViewSuccess,
  loadItemsViewFail,
  loadItemsViewReset,
} from "./items_act";

import {
  loadBranchBase,
  loadBranchBaseSuccess,
  loadBranchBaseFail,
  loadBranchBaseReset,
  loadPolicyView,
  loadPolicyViewSuccess,
  loadPolicyViewFail,
  loadPolicyViewReset,
} from "./policy_act";

export const loadBranchBaseStore = (branch_id) => loadBranchBase(branch_id);
export const loadPolicyStore = (branch_id) => loadPolicyView(branch_id);
export const loadItemsViewStore = (branch_id) => loadItemsView(branch_id);

export const loadPrePaidItemsStore = (branch_id, item_id) => loadPrePaidItems(branch_id, item_id);
export const loadSubScriptionItemsStore = (branch_id, item_id) =>
  loadSubScriptionItems(branch_id, item_id);
export const loadETCItemsStore = (branch_id, item_id) => loadETCItems(branch_id, item_id);

export const branchSettingAction = {
  loadBranchBaseSuccess,
  loadBranchBaseFail,
  loadBranchBaseReset,

  loadPolicyViewSuccess,
  loadPolicyViewFail,
  loadPolicyViewReset,

  loadItemsViewSuccess,
  loadItemsViewFail,
  loadItemsViewReset,

  loadPrePaidItemsSuccess,
  loadPrePaidItemsFail,
  loadPrePaidItemsReset,

  loadSubScriptionItemsSuccess,
  loadSubScriptionItemsFail,
  loadSubScriptionItemsReset,

  loadETCItemsSuccess,
  loadETCViewFail,
  loadETCItemsReset,
};
