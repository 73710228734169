// 출입 카드 관리
export const accessCardKeys = {
  all: ["accessCard"],
  // [GET] 출입 카드관리 리스트 검색
  list: (queryData) => [...accessCardKeys.all, queryData],
  // [GET] 출입 카드현황 가져오기
  status: (queryData) => [...accessCardKeys.all, "status", queryData],
};

// 출입문 모니터링
export const accessMonitorKeys = {
  all: ["accessMonitor"],
  // [GET] 출입문 모니터링 검색 조건 받아오기 및 권한 데이터 세팅
  searchParam: () => [...accessMonitorKeys.all, "searchParam"],
  // [GET] 출입 이력 리스트 검색
  accessHist: (queryData) => [...accessMonitorKeys.all, "accessHist", queryData],
  // [GET] 문열림 사용 이력 리스트 가져오기
  doorHist: (queryData) => [...accessMonitorKeys.all, "doorHist", queryData],
};

// 단말기 관리
export const deviceManageKeys = {
  all: ["deviceManage"],
  // [GET] 현재 연결된 장치 가져오기
  connectedDeviceList: () => [...deviceManageKeys.all, "connectedDeviceList"],
  // [GET] 장치 리스트 가져오기
  list: (queryData) => [...deviceManageKeys.all, "list", queryData],
  // [GET] 장치 스케줄 리스트 가져오기
  scheduleList: (queryData) => [...deviceManageKeys.all, "scheduleList", queryData],
};
