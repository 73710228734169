import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import CardFixed from "components/Card/CardFixed";
import { tabBorder, tabStyle } from "components/CommonComp";
import A11yProps from "components/TabControl/A11yProps";
import TabPanel from "components/TabControl/TabPanel";
import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import MemberViewActionsWithButton from "../SubItems/MemberViewActionsWithButton";
import AccessMethodHistTable from "./HistoryInfoTables/AccessMethodHistTable";
import ClassHistoryTable from "./HistoryInfoTables/ClassHistoryTable";
import CounselingHistoryTable from "./HistoryInfoTables/CounselingHistoryTable";
import MembershipHistoryTable from "./HistoryInfoTables/MembershipHistoryTable";
import PaymentHistoryTable from "./HistoryInfoTables/PaymentHistoryTable";

const HistoryInfo = ({ memberId, onClickOpenModal, baseInfoState }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const onChangeTabIndex = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <CardFixed
      topDom={
        <MemberViewActionsWithButton
          titleLabel={"회원 이력"}
          memberId={memberId}
          baseInfoState={baseInfoState}
          onClickOpenModal={onClickOpenModal}
        />
      }
      midDom={
        <div style={{ marginTop: "1.5rem" }}>
          <Box sx={{ bgcolor: "background.paper" }}>
            <Tabs
              value={tabIndex}
              onChange={onChangeTabIndex}
              textColor="inherit"
              sx={{ minHeight: "40px" }}
              TabIndicatorProps={{
                style: { backgroundColor: "rgba(0, 0, 0, 0)" },
              }}
            >
              <Tab label="회원권" {...A11yProps(0)} style={tabStyle(0, tabIndex)} />
              <Tab label="수업" {...A11yProps(1)} style={tabStyle(1, tabIndex)} />

              <Tab
                label="결제/환불"
                {...A11yProps(2)}
                style={tabStyle(2, tabIndex)}
                disabled={baseInfoState.member_type === "고객"}
              />

              <Tab label="상담" {...A11yProps(3)} style={tabStyle(3, tabIndex)} />
              <Tab label="출입방식" {...A11yProps(4)} style={tabStyle(4, tabIndex)} />
              {/* <Tab label="인바디" {...A11yProps(5)} style={tabStyle(5, tabIndex)} /> */}
              {/* <Tab label="통계" {...A11yProps(6)} style={tabBorder} /> */}
            </Tabs>
          </Box>

          <SwipeableViews
            index={tabIndex}
            onChangeIndex={onChangeTabIndex}
            style={tabBorder}
            animateTransitions={false}
          >
            <TabPanel value={tabIndex} index={0}>
              <MembershipHistoryTable memberId={memberId} />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <ClassHistoryTable memberId={memberId} />
            </TabPanel>

            <TabPanel value={tabIndex} index={2}>
              <PaymentHistoryTable memberId={memberId} onClickOpenModal={onClickOpenModal} />
            </TabPanel>

            <TabPanel value={tabIndex} index={3}>
              <CounselingHistoryTable memberId={memberId} />
            </TabPanel>
            <TabPanel value={tabIndex} index={4}>
              <AccessMethodHistTable memberId={memberId} />
            </TabPanel>
            {/* <TabPanel value={tabIndex} index={5}>
              <InbodyHistoryTable memberId={memberId} />
            </TabPanel> */}
            {/* <TabPanel value={value} index={6}>
              <StatisticsHistoryTable memberId={memberId} />
            </TabPanel> */}
          </SwipeableViews>
        </div>
      }
      isLoading={false}
      rootStyle={{
        width: "100%",
        height: "29rem",
        marginTop: "0.625rem",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};
export default HistoryInfo;
