import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import { getBoardRowAPi } from "pages/NoticeBoard/restApi/getApi";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCommentState, getPostRowState } from "../model/board_row";
import { newPostCommentAPi } from "../restApi/postApi";
// const DUMM = [
//   {
//     name: "김철수",
//     pos: "슈퍼바이저",
//     created_at: "2023-01-12",
//     content: "확인했습니다.",
//   },
//   {
//     name: "박철수",
//     pos: "슈퍼바이저",
//     created_at: "2023-01-13",
//     content: "확인했슈.",
//   },
// ];

const OpinionView = ({ postRootState, nowService }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const [commentState, setCommentState] = useState(getCommentState());

  const [postRowState, setPostRowState] = useState(getPostRowState(nowService));
  useEffect(() => {
    getBoardRowAPi(storesDispatch, nowService, postRootState.post_id, setPostRowState);
  }, [postRootState.post_id]);

  //댓글작성 POST
  const addComment = async () => {
    if (await newPostCommentAPi(storesDispatch, postRowState, commentState)) {
      setCommentState(getCommentState());
      getBoardRowAPi(storesDispatch, nowService, postRootState.post_id, setPostRowState);
    }
  };
  return (
    <div>
      <div style={{ display: "flex" }}>
        <TextField
          fullWidth
          labelMarginRight="0rem"
          onChangeCallback={(e) => {
            setCommentState((cur) => {
              return { ...cur, comment: e };
            });
          }}
        />
        <Button
          style={{ marginLeft: "0.5rem" }}
          className={clsx({
            [btnStyleClass.btnRootRaw]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={() => addComment()}
        >
          등록
        </Button>
      </div>
      <div style={{ marginTop: "0.7rem", backgroundColor: "#F5F5F5" }}>
        {postRowState.comment_list.map((el, idx) => (
          <div
            key={idx}
            style={{
              display: "flex",
              padding: "0.5rem 0.3rem",
              borderBottom: idx < postRowState.comment_list.length - 1 ? "1px dashed #BBBBBB" : "",
            }}
          >
            <Label
              labelText={el.reg_info.reg_name}
              color="#003CCB"
              marginRight="1.2rem"
              marginLeft="1.2rem"
            />
            <Label labelText={el.reg_info.reg_position} marginRight="1.2rem" />
            <Label labelText={el.reg_info.created_at} color="#777777" marginRight="1.2rem" />
            <Label labelText={el.comment} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OpinionView;
