import { HttpInstance } from "lib/HttpLib";

export const geDeviceList = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/access/device_list", {
    params: {
      branch_id: param.branch_id,
    },
  });
};

export const getDeviceScheduleList = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/access/manage_list", {
    params: {
      branch_id: param.branch_id,
    },
  });
};

export const getDeviceSchedulePreSetInfo = (param) => {
  return HttpInstance.get(
    process.env.REACT_APP_BRANCH_SERVER + "/access/device_schedule_set_view",
    {
      params: {
        branch_id: param.branch_id,
      },
    },
  );
};

export const getHolyDaySetInfo = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/access/holy_day_list", {
    params: {
      date_year: param.date_year,
    },
  });
};

const get_api = {
  geDeviceList,
  getDeviceScheduleList,
  getDeviceSchedulePreSetInfo,
  getHolyDaySetInfo,
};

export default get_api;
