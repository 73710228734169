import { create } from "zustand";

export const useSalaryInfoStore = create((set) => ({
  // ========== State ===========
  // Loading State
  salaryInfoLoading: false,

  // ========== Action ==========
  // Loading state 값 변경
  setSalaryInfoLoading: (bool) => {
    set({ salaryInfoLoading: bool });
  },
}));
