import { getDefaultValue } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import { useEffect, useRef } from "react";
import { SearchConditionBox, SearchContainer, SearchRowBox } from "../style";

const MemberInfoCondition = ({ searchParams, setSearchParams, onClickSearch }) => {
  const labelWidth = "5.5rem";
  const textBoxWidth = "17.75rem";
  const phoneInputBoxWidth = "5.5rem";
  const phone = useRef({
    p1: searchParams.p1,
    p2: searchParams.p2,
    p3: searchParams.p3,
  });
  const phoneRef1 = useRef();
  const phoneRef2 = useRef();
  const phoneRef3 = useRef();

  // 전화번호 입력 처리
  const onInputPhone = () => {
    let res = "";

    if (searchParams.p1 && !searchParams.p2 && searchParams.p3) {
      res = "err";
    } else {
      res = searchParams.p1 + "-" + searchParams.p2 + "-" + searchParams.p3;
    }
    res = res.replace("--", "-");
    const newPhoneNum = { phone: res };
    setSearchParams("base_info", newPhoneNum);
  };

  // 전화 번호 변경 체크
  useEffect(() => {
    onInputPhone();
  }, [searchParams.p1, searchParams.p2, searchParams.p3]);

  return (
    <SearchContainer>
      <Label labelText="검색 조건 : 회원 정보" fontWeight="bold" justifyContent="flex-start" />
      <SearchRowBox
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onClickSearch(false, 1);
          }
        }}
      >
        <SearchConditionBox width="26.5%">
          <TextField
            labelText="회원번호"
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth}
            defaultValue={getDefaultValue("field", searchParams.member_info.member_no)}
            onChangeCallback={(e) => {
              const newMemberInfo = { ...searchParams.member_info, member_no: e };
              setSearchParams("member_info", newMemberInfo);
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox width="26.5%">
          <TextField
            labelText="이름"
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth}
            defaultValue={getDefaultValue("field", searchParams.member_info.member_name)}
            onChangeCallback={(e) => {
              const newMemberInfo = { ...searchParams.member_info, member_name: e };
              setSearchParams("member_info", newMemberInfo);
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox width="26.5%">
          <TextField
            inputRef={phoneRef1}
            labelWidth={labelWidth}
            textBoxWidth={phoneInputBoxWidth}
            textMarginRight="0.1rem"
            maxLength={3}
            defaultValue={getDefaultValue("field", searchParams.p1)}
            labelText="연락처"
            onChangeCallback={(e) => {
              setSearchParams("p1", e);
              phone.current.p1 = e;
              if (phone.current.p1.length === 3) {
                phoneRef2.current.select();
              }
            }}
          />

          <TextField
            inputRef={phoneRef2}
            textBoxWidth={phoneInputBoxWidth}
            textMarginRight="0.1rem"
            labelMarginRight="0.1rem"
            maxLength={4}
            labelText="-"
            defaultValue={getDefaultValue("field", searchParams.p2)}
            onChangeCallback={(e) => {
              setSearchParams("p2", e);
              phone.current.p2 = e;
              if (phone.current.p2.length === 4) {
                phoneRef3.current.select();
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Backspace" && !phone.current.p2) {
                phoneRef1.current.select();
              }
            }}
          />
          <TextField
            inputRef={phoneRef3}
            textBoxWidth={phoneInputBoxWidth}
            textMarginRight="0.1rem"
            labelMarginRight="0.1rem"
            maxLength={4}
            labelText="-"
            defaultValue={getDefaultValue("field", searchParams.p3)}
            onChangeCallback={(e) => {
              setSearchParams("p3", e);
              phone.current.p3 = e;
            }}
            onKeyDown={(e) => {
              if (e.key === "Backspace" && !phone.current.p3) {
                phoneRef2.current.select();
              }
            }}
          />
        </SearchConditionBox>
      </SearchRowBox>
    </SearchContainer>
  );
};
export default MemberInfoCondition;
