import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, dataEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadEtcSalaryViewStore, loadSalaryInfoViewStore } from "../Module/actions";

const EtcSalaryInfoView = ({ baseDate, onClickOpenModal, salaryReportId, is_edit }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const restData = useSelector((state) => state.staff_service.etc_salary);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    storesDispatch(loadEtcSalaryViewStore(salaryReportId, is_edit));
  }, [salaryReportId]);

  const refreshAllView = (isDelete) => {
    storesDispatch(loadEtcSalaryViewStore(salaryReportId, is_edit)); //기타탭 갱신
    storesDispatch(loadSalaryInfoViewStore(salaryReportId, is_edit)); //메인 갱신
    storesDispatch(globalModalOff());
  };

  const popEtcSalaryAddModal = () => {
    const param = {
      title: "기타 수당 내역 추가",
      modalParam: {
        salaryReportId: salaryReportId,
        baseDate: baseDate,
      },
    };
    onClickOpenModal("기타수당내역추가", param, refreshAllView);
  };

  const deleteEtcSalary = async (etcId) => {
    try {
      const formData = new FormData();
      formData.append("salary_report_id", salaryReportId);
      formData.append("etc_id", etcId);
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_SALARY_SERVER + "/salary/etc/delete",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        simpleAlert(storesDispatch, "알림", "삭제되었습니다.\n\n새로고침 해주시기 바랍니다.");
        refreshAllView(true);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const onDeleteRow = (data) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: <Label labelText="삭제하시겠습니까?" />,
        onBtnYes: () => {
          deleteEtcSalary(data.etc_id);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const dataTableItem = new dataTable(onDeleteRow, is_edit);

  return (
    <div style={{ marginTop: "0.5rem" }}>
      {loading && <Loading />}

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <SubTitle titleText="기타 수당 현황" />
        </div>
        {is_edit ? (
          <Button
            className={clsx({
              [btnStyleClass.btnRootWhite]: true,
              [btnStyleClass.buttonType8]: true,
            })}
            onClick={() => {
              popEtcSalaryAddModal();
            }}
          >
            기타수당 내역 추가
          </Button>
        ) : (
          ""
        )}
      </div>
      <div style={{ marginTop: "0.7rem" }}>
        <CustomTable
          columns_head={dataTableItem.columns_head}
          table_title={dataTableItem.table_title}
          rest_call={dataTableItem.get_data_from_rest}
          row_render={dataTableItem.create_table}
          rest_data={restData.stores.data}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem" }}>
        <TextField
          labelWidth={"7rem"}
          textBoxWidth={"9rem"}
          textMarginRight="0rem"
          labelText="최종 지급액 합계"
          endAdornment={"원"}
          defaultValue={restData.stores.final_total_salary.toLocaleString()}
          isNumber
          disabled
        />
      </div>
    </div>
  );
};
export default EtcSalaryInfoView;

class dataTable {
  table_title = "";
  onDeleteRow = null;
  isEdit = null;

  constructor(onDeleteRow, isEdit) {
    this.onDeleteRow = onDeleteRow;
    this.isEdit = isEdit;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.personal_info.sabun}</CustomTableContents>
        <CustomTableContents>{data.etc_info.salary_date}</CustomTableContents>
        <CustomTableContents>{data.personal_info.staff_name}</CustomTableContents>
        <CustomTableContents>{data.base_info.position.org_name}</CustomTableContents>
        <CustomTableContents>{data.etc_info.is_owner_staff}</CustomTableContents>
        <CustomTableContents>{data.personal_info.social_number}</CustomTableContents>
        <CustomTableContents>{data.personal_info.bank}</CustomTableContents>
        <CustomTableContents>{data.personal_info.account_no}</CustomTableContents>
        <CustomTableContents>{data.personal_info.account_holder}</CustomTableContents>
        <CustomTableContents>{data.etc_info.reason}</CustomTableContents>
        <CustomTableContents $isNumber>
          {data.etc_info.wage.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContentsEND align="center">
          {this.isEdit ? (
            <IconButton
              type="delete"
              onClick={() => {
                this.onDeleteRow(data);
              }}
            />
          ) : (
            ""
          )}
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "사번", width: "5%" },
    { title: "급여일", width: "7.5%" },
    { title: "직원명", width: "9%" },
    { title: "직책", width: "9%" },
    { title: "임직원 여부", width: "6%" },
    { title: "주민번호", width: "9%" },
    { title: "은행", width: "9%" },
    { title: "계좌번호", width: "9%" },
    { title: "예금주", width: "9%" },
    { title: "사유", width: "15%" },
    { title: "최종 지급액", width: "9%" },
    { title: "", width: "4%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
