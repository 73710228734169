import { contentTypeJson, dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import ComboBox from "components/LabelInput/ComboBox";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import ModalBtnModeGroup from "components/ModalBtnModeGroup/ModalBtnModeGroup";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalModalOff } from "modules/actions/Modal";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import post_api from "../Module/api/PostApi";

const CardAddModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [cardList, setCardList] = useState([]);

  const [paramInfo, setParamInfo] = useState({
    branch_info: { branch_id: "", branch_name: "" },
    user_group: "",
    startCardNo: "",
  });
  const cardRef = useRef([]);
  const remarksRef = useRef([]);

  useEffect(() => {
    //카드리스트가 바뀌면 다음애한테 포커스를 주자
    const focusedItemIdx = cardList.findIndex((el) => el.focused === true);
    if (focusedItemIdx !== -1 && cardList.length > 10) {
      cardInfoTableItem.cardRef.current[focusedItemIdx].focus();
    }
  }, [cardList]);

  //초기 10개 GET
  const makeInitList = async () => {
    setLoading(true);
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_BRANCH_SERVER + "/access/card/make_card_list",
        {
          params: {
            card_start_no: paramInfo.startCardNo,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        return res.data.data;
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  //리스트생성 BTN
  const doMakeInitList = async () => {
    if (!paramInfo.branch_info.branch_id) {
      simpleAlert(storesDispatch, "알림", "지점을 선택해주세요.");
    } else if (!paramInfo.user_group) {
      simpleAlert(storesDispatch, "알림", "사용자그룹을 선택해주세요.");
    } else if (paramInfo.startCardNo === "" || isNaN(paramInfo.startCardNo)) {
      simpleAlert(storesDispatch, "알림", "올바른 카드 시작번호를 입력해주세요.");
    } else {
      //서버에서 기본 10개를 받아와서 메카리에 세팅
      const tenArr = await makeInitList();
      tenArr[0].focused = true;
      setCardList([...tenArr]);
    }
  };

  //시리얼넘버 디비랑 중복검사 GET
  const checkWithServerNum = async (serial_no, idx) => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_BRANCH_SERVER + "/access/card/check_serial_num",
        {
          params: {
            card_serial_no: serial_no,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        if (res.data.data === "Y") {
          simpleAlert(storesDispatch, "알림", "시리얼 번호가 중복입니다.", false, () => {
            cardRef.current[idx].focus();
          });
          return true;
        } else if (res.data.data === "N") {
          return false;
        }
      }
    } catch (err) {
      console.error(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  //엔터이후 새로운 row 생성 GET
  const getNewRowAfterEnter = async (card_num, idx) => {
    setLoading(true);
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_BRANCH_SERVER + "/access/card/next_card_num",
        {
          params: {
            card_no: card_num,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setCardList((cur) => {
          const arr = [...cur];
          arr.forEach((el) => (el.focused = false));
          arr.push(res.data.data);
          return arr;
        });
      }
    } catch (err) {
      console.error(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  const popupAlert = (idx, msg) => {
    simpleAlert(storesDispatch, "알림", msg, false, () => {
      cardRef.current[idx].focus();
    });
  };

  //카드저장 MSG
  const onSaveEvent = () => {
    const isAllEmpty = cardList.every((el) => !el.card_serial_no);
    if (isAllEmpty) {
      //아무것도 입력한게 없음
      simpleAlert(storesDispatch, "알림", "저장하실 카드의 시리얼 번호를 1개이상 입력해주세요.");
    } else {
      simpleAlert(storesDispatch, "알림", "저장하시겠습니까?", false, undefined, () => {
        enrollCard();
      });
    }
  };

  //카드저장 POST
  const enrollCard = async () => {
    try {
      setLoading(true);

      let resArr = [];
      for (let i = 0; i < cardList.length; i++) {
        resArr.push({
          card_no: cardList[i].card_no,
          card_serial_no: cardList[i].card_serial_no,
          remarks: cardList[i].remarks,
        });
      }
      const paramData = {
        branch_info: paramInfo.branch_info,
        user_group: paramInfo.user_group,
        card_list: resArr,
      };

      const formData = new FormData();
      formData.append("param_info", JSON.stringify(paramData));
      console.log(paramData);

      const postApi = post_api.cardAdd();
      const res = await HttpInstance.post(postApi, formData, contentTypeJson);

      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        simpleAlert(storesDispatch, "알림", "저장되었습니다.", false, () => {
          onModalDone(1, false, true);
          storesDispatch(globalModalOff());
        });
      }
    } catch (err) {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };
  // 처음 지점 세팅
  useEffect(() => {
    const branch = modalParam.branchList.branch_list[0];
    setParamInfo((cur) => {
      return { ...cur, branch_info: branch };
    });
  }, []);

  //테이블 생성자
  const cardInfoTableItem = new cardInfoTable(
    cardList,
    setCardList,
    cardRef,
    remarksRef,
    checkWithServerNum,
    getNewRowAfterEnter,
    popupAlert,
  );

  return (
    <div
      style={{
        width: "34rem",
      }}
    >
      {loading ? <Loading /> : <></>}

      {cardList.length !== 0 && <ModalBtnModeGroup mode={"add"} onClickEvent={onSaveEvent} />}

      <SearchComboBox
        labelWidth={"6rem"}
        comboItemWidth={"13rem"}
        labelText="지점"
        data={modalParam.branchList.branch_name_list}
        defaultValue={paramInfo.branch_info.branch_name || ""}
        onChangeCallback={(e, v) => {
          const branch = modalParam.branchList.branch_list.find((el) => el.branch_name === v);
          setParamInfo((cur) => {
            return { ...cur, branch_info: branch };
          });
        }}
      />
      <ComboBox
        marginTop="0.5rem"
        labelWidth={"6rem"}
        comboItemWidth={"13rem"}
        labelText="사용자 그룹"
        data={["회원", "직원", "지점"]}
        defaultValue={paramInfo.user_group}
        onChangeCallback={(e) => {
          setParamInfo((cur) => {
            return { ...cur, user_group: e.target.value };
          });
        }}
      />
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <TextField
          labelWidth={"6rem"}
          labelText="카드 시작번호"
          textBoxWidth="13rem"
          isStringNumber
          maxValue={999999}
          minValue={0}
          onChangeCallback={(e) => {
            setParamInfo((cur) => {
              return { ...cur, startCardNo: e };
            });
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              doMakeInitList();
            }
          }}
        />

        <div
          style={{
            display: "flex",
            float: "right",
            marginLeft: "auto",
          }}
        >
          <NormalBtn
            name="리스트 생성"
            onClick={() => {
              doMakeInitList();
            }}
          />
        </div>
      </div>

      <div style={{ marginTop: "0.5rem" }}>
        <CustomTable
          columns_head={cardInfoTableItem.columns_head}
          table_title={cardInfoTableItem.table_title}
          rest_call={cardInfoTableItem.get_data_from_rest}
          row_render={cardInfoTableItem.create_table}
          rest_data={cardList}
        />
      </div>
    </div>
  );
};
export default CardAddModal;

class cardInfoTable {
  table_title = "";
  cardList = [];
  setCardList = null;
  cardRef = null;
  remarksRef = null;
  checkWithServerNum = null;
  isSerialDouble = true;
  getNewRowAfterEnter = null;
  popupAlert = null;

  constructor(
    cardList,
    setCardList,
    cardRef,
    remarksRef,
    checkWithServerNum,
    getNewRowAfterEnter,
    popupAlert,
  ) {
    this.cardList = cardList;
    this.setCardList = setCardList;
    this.cardRef = cardRef;
    this.remarksRef = remarksRef;
    this.checkWithServerNum = checkWithServerNum;
    this.getNewRowAfterEnter = getNewRowAfterEnter;
    this.popupAlert = popupAlert;
  }

  //LATEST
  //무조건 엔터칠때 검사 및 state 세팅!
  //1. 현재리스트랑 중복검사
  //2. 통과-> 서버랑 중복검사
  //3. 통과-> 다음칸 있나 없나에 따라 있으면 다음칸 포커스, 없으면 다음칸 추가 후 포커스
  onKeyDownCallback = async (idx, data) => {
    const curSerialNo = this.cardRef.current[idx].value;
    if (!curSerialNo) {
      this.popupAlert(idx, "시리얼 번호를 차례대로 입력하세요.");
    } else {
      //1. 현재리스트랑 검사
      const checkIdx = this.cardList.findIndex(
        (el, index) => index !== idx && el.card_serial_no === curSerialNo,
      );
      if (checkIdx !== -1) {
        //중복
        this.popupAlert(idx, "시리얼 번호가 중복입니다.");
      } else {
        //1. 통과
        //2. 서버랑 검사
        const result = await this.checkWithServerNum(curSerialNo, idx);
        if (!result) {
          //2.통과
          this.setCardList((cur) => {
            const arr = [...cur];
            arr[idx].card_serial_no = curSerialNo;
            arr[idx].remarks = this.remarksRef.current[idx].value;
            arr.forEach((el, index) => {
              index === idx ? (el.focused = true) : (el.focused = false);
            });
            return arr;
          });
          //3. 다음칸 여부에 따라 분기처리
          if (!this.cardRef.current[idx + 1]) {
            //다음칸 없음 -> 새 행 추가
            this.getNewRowAfterEnter(data.card_no, idx);
          } else {
            //다음칸 있음 -> 다음칸 포커스
            this.cardRef.current[idx + 1].focus();
          }
        }
      }
    }
  };

  // remarkKeyDown = async (idx, targetValue, data) => {
  //   this.cardList[idx].remarks = targetValue;
  //   this.setCardList([...this.cardList]);
  //   if (!this.remarksRef.current[idx + 1]) {
  //     //행추가
  //     this.getNewRowAfterEnter(data.card_no, idx);
  //   } else {
  //     this.remarksRef.current[idx + 1].focus();
  //   }
  // };

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>
          <TextField isNumber defaultValue={data.card_no} disabled />
        </CustomTableContents>
        <CustomTableContents>
          <TextField
            inputRef={(el) => (this.cardRef.current[idx] = el)}
            defaultValue={data.card_serial_no}
            isStringNumber
            maxValue={4294967295}
            minValue={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.onKeyDownCallback(idx, data);
              }
            }}
          />
        </CustomTableContents>
        <CustomTableContentsEND>
          <TextField
            textBoxWidth="15rem"
            defaultValue={data.remarks}
            inputRef={(el) => (this.remarksRef.current[idx] = el)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                // this.remarkKeyDown(idx, e.target.value, data);
                this.onKeyDownCallback(idx, data);
              }
            }}
          />
        </CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "카드번호", width: "23%" },
    { title: "시리얼번호", width: "27%" },
    { title: "비고", width: "50%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
