import CustomPagination from "components/CustomPagination/CustomPagination";
import Label from "components/LabelInput/Label";
import { boxStyle, selectStyle, timeLineBoxStyle } from "./calendarStyle";
import DaySchedule from "./DaySchedule";

const DayCalendar = ({
  searchParam,
  searchResult,
  setSearchResult,
  onClickOpenModal,
  refreshCalendar,
  setCurPage,
  totalPage,
  curPage,
  staffList,
  readonly,
}) => {
  //1. scheduleType에 따른 팝업 분기처리
  //date: 달력에서 선택된 날짜
  //time: 현재 선택된 칸의 시간
  //staff:{staff_name, staff_id, staff_rank,staff_pos} 형태
  //필요없는 파라미터는 "" 처리

  const selectPopup = (date, time, scheduleType, isEdit, scheduleId, data, staff) => {
    if (scheduleType === "예약" || scheduleType === "수업완료") {
      popReservModal(date, time, scheduleType, isEdit, scheduleId, data, staff);
    } else if (scheduleType === "개인업무" || scheduleType === "휴무") {
      popPersonalTaskModal(date, time, scheduleType, isEdit, scheduleId, data, staff);
    }
  };

  //1-1. 예약 & 수업완료 팝업
  const popReservModal = (date, time, scheduleType, isEdit, scheduleId, data, staff) => {
    const param = {
      title:
        scheduleType === "예약" && isEdit
          ? "예약 등록"
          : scheduleType === "예약" && !isEdit
          ? "예약"
          : "수업완료",
      modalParam: {
        mode: "day",
        scheduleType,
        isEdit,
        searchParam,
        scheduleId,
        data,
        date,
        time,
        staff,
      },
    };
    onClickOpenModal("예약등록", param, refreshCalendar);
  };

  //1-2. 개인업무 & 휴무 팝업
  const popPersonalTaskModal = (date, time, scheduleType, isEdit, scheduleId, data, staff) => {
    const param = {
      title:
        scheduleType === "개인업무" && !isEdit
          ? "개인업무"
          : scheduleType === "개인업무" && isEdit
          ? "개인업무 등록"
          : "휴무",

      modalParam: {
        mode: "day",
        scheduleType,
        isEdit,
        searchParam,
        scheduleId,
        data,
        date,
        time,
        staff,
      },
    };
    onClickOpenModal("개인업무등록", param, refreshCalendar);
  };

  //전달사항 팝업
  const popNoticeModal = () => {
    const param = {
      title: "전달사항",
      modalParam: {},
    };
    onClickOpenModal("전달사항", param);
  };

  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: `0.5fr repeat(${staffList.length},1fr)`,
          textAlign: "center",
          marginTop: "2rem",
          marginBottom: "0.7rem",
          fontWeight: "bold",
          color: "#222222",
          overflowX: "auto",
        }}
      >
        {/* 직원 이름 리스트 */}
        <Label labelText=" " />
        {staffList.map((el, idx) => (
          <Label labelText={el.staff_name} key={`staff-${idx}`} />
        ))}
      </div>

      {/* 종일 - 헤드라인 */}
      <div style={{ height: "40rem", overflowY: "scroll" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: `0.5fr repeat(${staffList.length},1fr)`,
            borderBottom: "4px solid #EBEBEB",
            gridAutoRows: "minmax(2.5rem,auto)",
            boxSizing: "border-box",
            overflowX: "auto",
          }}
        >
          <div style={timeLineBoxStyle}>종일</div>
          {searchResult.head_line_item.map((day, idx) => (
            <div key={`headline-${idx}`} style={boxStyle}>
              {day.schedule_list.map((el) => (
                <div
                  key={el.schedule_base.schedule_info.schedule_id}
                  style={selectStyle(
                    el.schedule_base.schedule_info.schedule_type,
                    el.schedule_base.schedule_info.schedule_sub_type,
                  )}
                  onClick={() => {
                    selectPopup(
                      day.date,
                      "",
                      el.schedule_base.schedule_info.schedule_type,
                      false,
                      el.schedule_base.schedule_info.schedule_id,
                      el,
                    );
                  }}
                >
                  {el.card_title}
                  <br />
                  {`${el.schedule_base.charger_staff_info.staff_name}`}
                </div>
              ))}
            </div>
          ))}
        </div>

        {/*스케줄 리스트 */}
        <DaySchedule
          searchParam={searchParam}
          setSearchResult={setSearchResult}
          selectPopup={selectPopup}
          gridRowItem={searchResult.grid_item}
          staffList={staffList}
          readonly={readonly}
        />

        <CustomPagination
          curPage={curPage ? curPage : 1}
          doSearch={setCurPage}
          totalCnt={totalPage}
        />
      </div>
    </div>
  );
};
export default DayCalendar;
