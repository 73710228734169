import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import {
  contentTypeJson,
  dataEmpty,
  generateObjectZeroId,
  getDefaultValue,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { searchStaffParamInit } from "pages/CommonModal/Search/Module/reducers/staff_search_params";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

const SearchStaffModal = ({ modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const labelWidth = "4.5rem";
  const textBoxWidth = "14rem";
  const phoneInputBoxWidth = "4.25rem";

  const [searchParam, setSearchParam] = useState(searchStaffParamInit);
  const [staffSearchResult, setStaffSearchResult] = useState([]);
  const [hqIdx, setHqIdx] = useState(0);
  const [curPage, setCurPage] = useState();
  const [totalPage, setTotalPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const phone = useRef({
    p1: "",
    p2: "",
    p3: "",
  });
  const phone1Ref = useRef("");
  const phone2Ref = useRef("");
  const phone3Ref = useRef("");

  // 전화번호 입력 처리
  const onInputPhone = () => {
    let res = "";

    if (phone.current.p1 && !phone.current.p2 && phone.current.p3) {
      res = "err";
    } else {
      res = phone.current.p1 + "-" + phone.current.p2 + "-" + phone.current.p3;
    }
    res = res.replace("--", "-");

    setSearchParam((cur) => {
      return { ...cur, phone: res };
    });
  };

  const onClickSearch = async (page) => {
    setCurPage(page);
    try {
      const formData = new FormData();
      formData.append("page_num", curPage);
      formData.append("search_param", JSON.stringify(searchParam));
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_STAFF_SERVER + "/staff/search_modal",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        setStaffSearchResult(res.data.data);
        setTotalPage(res.data.total_page);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const onSelectStaff = (data, name) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText={`${name}을 선택하시겠습니까?`} />
          </>
        ),

        onBtnYes: () => {
          onModalDone(data);
        },
        onBtnNo: () => {},
      }),
    );
  };

  //페이지네이션
  useEffect(() => {
    if (modalParam.hq_list && modalParam.hq_list.length > 0) {
      setSearchParam((cur) => {
        return { ...cur, hq_info: modalParam.target_list[0].hq_info };
      });
    }

    if (curPage) {
      onClickSearch(curPage);
    }
  }, [curPage]);

  // 최초 지점 본부직원 세팅
  useEffect(() => {
    if (searchParam.branch_info.branch_id === generateObjectZeroId()) {
      setSearchParam((cur) => {
        const branchInfo = modalParam.target_list[0].branch_list[0];
        return { ...cur, branch_info: branchInfo };
      });
    }
  }, []);

  const searchResultData = new searchResultTable(onSelectStaff);

  return (
    <div style={{ width: "42rem" }}>
      {loading && <Loading />}

      <div>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <SubTitle titleText="직원 검색" />
        </div>
        <div>
          <div
            style={{ display: "flex", gap: "1.5rem" }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onClickSearch(1);
              }
            }}
          >
            <ComboBox
              labelWidth={labelWidth}
              comboItemWidth={textBoxWidth}
              labelText="본부"
              data={modalParam.hq_list}
              defaultValue={modalParam.hq_list.length !== 0 && modalParam.hq_list[hqIdx]}
              onChangeCallback={(e) => {
                const idx = modalParam.hq_list.findIndex((el) => el === e.target.value);
                if (idx !== -1) {
                  setHqIdx(idx);
                }
                const hqInfo = modalParam.target_list[idx].hq_info;
                setSearchParam((cur) => {
                  return { ...cur, hq_info: hqInfo };
                });
              }}
            />
            <SearchComboBox
              marginLeft="1.7rem"
              labelWidth={labelWidth}
              comboItemWidth={textBoxWidth}
              labelText="지점"
              data={modalParam.branch_list[hqIdx]}
              defaultValue={getDefaultValue("combo", searchParam.branch_info.branch_name)}
              onChangeCallback={(e, v) => {
                const idx = modalParam.branch_list[hqIdx].findIndex((el) => el === v);
                if (idx !== -1) {
                  const branchInfo = modalParam.target_list[hqIdx].branch_list[idx];
                  setSearchParam((cur) => {
                    return { ...cur, branch_info: branchInfo };
                  });
                }
              }}
            />
          </div>
          <div
            style={{ display: "flex", marginTop: "0.5rem", gap: "1.5rem" }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onClickSearch(1);
              }
            }}
          >
            <TextField
              labelWidth={labelWidth}
              textBoxWidth={textBoxWidth}
              labelText="직원명"
              onChangeCallback={(e) => {
                setSearchParam((cur) => {
                  return { ...cur, staff_name: e };
                });
              }}
            />
            <div style={{ display: "flex", marginLeft: " 1.7rem" }}>
              <TextField
                inputRef={phone1Ref}
                labelWidth={labelWidth}
                textBoxWidth={phoneInputBoxWidth}
                textMarginRight="0.1rem"
                labelText="연락처"
                defaultValue={getDefaultValue("field", phone.current.p1)}
                maxLength={3}
                onChangeCallback={(e) => {
                  phone.current.p1 = e;
                  onInputPhone();
                  if (phone.current.p1.length === 3) {
                    phone2Ref.current.select();
                  }
                }}
              />
              <TextField
                inputRef={phone2Ref}
                textBoxWidth={phoneInputBoxWidth}
                textMarginRight="0.1rem"
                labelMarginRight="0.1rem"
                labelText="-"
                maxLength={4}
                defaultValue={getDefaultValue("field", phone.current.p2)}
                onChangeCallback={(e) => {
                  phone.current.p2 = e;
                  onInputPhone();
                  if (phone.current.p2.length === 4) {
                    phone3Ref.current.select();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Backspace" && !phone.current.p2) {
                    phone1Ref.current.select();
                  }
                }}
              />
              <TextField
                inputRef={phone3Ref}
                textBoxWidth={phoneInputBoxWidth}
                labelMarginRight="0.1rem"
                labelText="-"
                maxLength={4}
                defaultValue={getDefaultValue("field", phone.current.p3)}
                onChangeCallback={(e) => {
                  phone.current.p3 = e;
                  onInputPhone();
                }}
                onKeyDown={(e) => {
                  if (e.key === "Backspace" && !phone.current.p3) {
                    phone2Ref.current.select();
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem" }}>
          <Button
            className={clsx({
              [btnStyleClass.btnRoot]: true,
              [btnStyleClass.buttonType4]: true,
            })}
            onClick={() => {
              onClickSearch(1);
            }}
          >
            검색
          </Button>
        </div>
      </div>
      <div style={{ marginTop: "1rem" }}>
        <CustomTable
          columns_head={searchResultData.columns_head}
          table_title={searchResultData.table_title}
          rest_call={searchResultData.get_data_from_rest}
          row_render={searchResultData.create_table}
          rest_data={staffSearchResult}
        />
        <CustomPagination curPage={curPage} doSearch={setCurPage} totalCnt={totalPage} />
      </div>
    </div>
  );
};

export default SearchStaffModal;

class searchResultTable {
  table_title = "";
  onSelectStaff = null;

  constructor(onSelectStaff) {
    this.onSelectStaff = onSelectStaff;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow
        key={idx}
        onClick={() => {
          this.onSelectStaff(data, data.personal_info.staff_name);
        }}
        hover
      >
        <CustomTableContents>{data.base_info.hq_info.hq_name}</CustomTableContents>
        <CustomTableContents>{data.base_info.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.personal_info.staff_name}</CustomTableContents>
        <CustomTableContents>{data.base_info.position.org_name}</CustomTableContents>
        <CustomTableContentsEND align="center">{data.personal_info.phone}</CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "본부", width: "20%" },
    { title: "지점", width: "20%" },
    { title: "직원명", width: "20%" },
    { title: "직책", width: "20%" },
    { title: "연락처", width: "20%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
