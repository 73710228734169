import styled from "styled-components";

const getPageCnt = (numPages) => {
  if (numPages >= 10) {
    return 10;
  } else {
    if (numPages < 0) {
      return 0;
    }
    return numPages;
  }
};

const isMaxPage = (numPages, pageMax) => {
  if (numPages === pageMax) {
    return true;
  } else {
    return false;
  }
};

const isEndMaxPage = (curPage, totalCnt, numPageCnt) => {
  if (numPageCnt > 1) {
    if (curPage === totalCnt) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

function CustomPagination({ totalCnt, curPage, doSearch }) {
  if (!curPage) {
    return;
  }

  const numPages = parseInt((curPage - 1) / 10) * 10;
  const numPageDiff = totalCnt - numPages;

  const numPageCnt = getPageCnt(numPageDiff);

  return (
    <>
      <Nav>
        {curPage === 1 ? (
          ""
        ) : (
          <Button onClick={() => doSearch(1)} disabled={curPage === 1}>
            &#171;
          </Button>
        )}

        {curPage < 11 ? (
          ""
        ) : (
          <Button onClick={() => doSearch(numPages - 9)} disabled={curPage === 1}>
            &lt;
          </Button>
        )}

        {Array(numPageCnt)
          .fill()
          .map((_, i) => (
            <Button
              key={i + 1 + numPages}
              onClick={() => doSearch(i + 1 + numPages)}
              aria-current={curPage === i + 1 + numPages ? "page" : null}
            >
              {i + 1 + numPages}
            </Button>
          ))}
        {isMaxPage(numPageCnt + numPages, totalCnt) ? (
          ""
        ) : (
          <Button
            onClick={() => doSearch(numPages + 11)}
            disabled={isMaxPage(numPageCnt + numPages, totalCnt)}
          >
            &gt;
          </Button>
        )}

        {isEndMaxPage(curPage, totalCnt, numPageCnt) ? (
          ""
        ) : (
          <Button onClick={() => doSearch(totalCnt)} disabled={curPage === totalCnt}>
            &#187;
          </Button>
        )}
      </Nav>
    </>
  );
}

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  //   gap: 2px;
  margin-top: 16px;
`;

const Button = styled.button`
  border: none;
  border-radius: 8px;
  padding: 8px;
  margin: 0;
  background: rgba(0, 0, 0, 0);
  color: #999999;
  font-family: AppleSDGothicNeo;
  font-size: 0.938rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  font-size: 1rem;

  &:hover {
    // background: tomato;
    cursor: pointer;
    transform: translateY(-2px);
  }

  &[disabled] {
    cursor: revert;
    transform: revert;
  }

  &[aria-current] {
    // background: #032063;
    font-family: AppleSDGothicNeo;
    font-size: 1.238rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #11104b;
    font-weight: bold;
    cursor: revert;
    transform: revert;
  }
`;

export default CustomPagination;
