import Box from "@mui/material/Box";
import CardComp from "@mui/material/Card";
import React from "react";
import styled from "styled-components";

const CardTitle = styled.div`
  // width: 62px;
  height: ${(props) => (props.titleHeight === undefined ? "1.313rem;" : props.titleHeight)};
  margin: 0 0.625rem 0.625rem 0;
  font-family: AppleSDGothicNeo;
  font-size: 1.125rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
`;

const CardFixed = (props) => {
  // const [loading, setLoading] = useState(props.isLoading);

  const cardStyle = {
    alignContent: "space-between",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "100%",
  };

  const cardRootStyle = {
    borderRadius: "10px",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
  };

  return (
    <div style={Object.assign(cardRootStyle, props.rootStyle)}>
      <Box
        sx={{
          padding: props.rootStyle.padding ? props.rootStyle.padding : "1.25rem 1.5rem",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      >
        <div>
          <CardComp style={cardStyle} variant="none">
            {props.topDom === undefined ? "" : <CardTitle>{props.topDom}</CardTitle>}
            {props.midDom}
            {/* {props.bottomDom} */}
          </CardComp>
        </div>
      </Box>
    </div>
  );
};

export default React.memo(CardFixed);
