import { generateObjectZeroId } from "components/CommonLib/CommonLib";
import { create } from "zustand";

// 검색 조건 베이스
const baseSearchParams = {
  branch_info: {
    branch_id: generateObjectZeroId(),
    branch_name: "",
  },
  combo_selector: {
    branch_list: [[""]],
    hq_list: [""],
    target_list: [
      {
        branch_list: [
          {
            branch_id: "",
            branch_name: "",
          },
        ],
        hq_info: {
          hq_id: "",
          hq_name: "",
        },
      },
    ],
  },
  draft_from_date: "",
  draft_to_date: "",
  hq_info: {
    hq_id: generateObjectZeroId(),
    hq_name: "",
  },
  spend_from_date: "",
  spend_to_date: "",
};

export const useUnspentStore = create((set) => ({
  // ========== State ===========
  // 카드관리 검색 State
  unspentSearchParams: {
    ...baseSearchParams,
  },
  unspentPage: 1,

  unspentHqIdx: 0,

  // ========== Action ==========
  // 카드관리 검색 조건 변경
  setSearchParams: (key, value) => {
    set((state) => ({
      unspentSearchParams: {
        ...state.unspentSearchParams,
        [key]: value,
      },
    }));
  },

  // Hq Index 값 변경
  setHqIdx: (value) => {
    set({ unspentHqIdx: value });
  },

  // Page 값 변경
  setPage: (value) => {
    set({ unspentPage: value });
  },
}));
