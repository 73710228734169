import { getDefaultValue } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import MonthPicker from "components/LabelInput/MonthPicker";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import { useSalaryListStore } from "./modules/store/salaryListState";
import { SearchConditionBox, SearchContainer, SearchRowBox } from "./style";

const SalaryListSearchCard = ({ hqBranchListData, branchList, onClickSearch }) => {
  return (
    <div style={{ width: "100%" }}>
      <SearchCardRow1
        hqBranchListData={hqBranchListData}
        branchList={branchList}
        onClickSearch={onClickSearch}
      />
    </div>
  );
};
export default SalaryListSearchCard;

//
const SearchCardRow1 = ({ hqBranchListData, branchList, onClickSearch }) => {
  const labelWidth = "5.5rem";
  const labelWidthShort = "4.5rem";
  const textBoxWidth = "15rem";
  const textBoxWidthShort = "8.5rem";

  // 전역 상태 관리
  const {
    salaryListSearchParams: searchParams,
    setSearchParams,
    salaryListHqIdx: hqIdx,
    setHqIdx,
    setPage,
  } = useSalaryListStore();

  return (
    <SearchContainer>
      <Label labelText="검색 조건" fontWeight="bold" justifyContent="flex-start" />
      <SearchRowBox
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setPage(1);
            onClickSearch(true);
          }
        }}
      >
        <SearchConditionBox width="24%">
          <ComboBox
            labelWidth={labelWidthShort}
            comboItemWidth={textBoxWidth}
            labelText="본부"
            data={hqBranchListData && hqBranchListData.hq_list}
            defaultValue={getDefaultValue("combo", searchParams.hq_info.hq_name)}
            onChangeCallback={(e, idx) => {
              setHqIdx(idx);
              const hqParams = {
                hq_id: hqBranchListData.target_list[idx].hq_info.hq_id,
                hq_name: e.target.value,
              };
              setSearchParams("hq_info", hqParams);
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox width="24%">
          <SearchComboBox
            labelWidth={labelWidthShort}
            comboItemWidth={textBoxWidth}
            labelText="지점"
            data={branchList}
            defaultValue={getDefaultValue("combo", searchParams.branch_info.branch_name)}
            onChangeCallback={(e, v, idx) => {
              const branchParams = hqBranchListData.target_list[hqIdx].branch_list[idx];
              setSearchParams("branch_info", branchParams);
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox width="26.5%">
          <MonthPicker
            labelWidth={labelWidthShort}
            minWidth={textBoxWidthShort}
            marginRight="0.1rem"
            labelText="기준 연월"
            defaultValue={getDefaultValue("field", searchParams.base_from_date)}
            onChangeCallback={(e) => setSearchParams("base_from_date", e)}
          />
          <MonthPicker
            labelWidth={"0.1rem"}
            labelText="~"
            minWidth={textBoxWidthShort}
            defaultValue={getDefaultValue("field", searchParams.base_to_date)}
            onChangeCallback={(e) => setSearchParams("base_to_date", e)}
          />
        </SearchConditionBox>
        <SearchConditionBox>
          <ComboBox
            labelWidth={labelWidth}
            comboItemWidth={textBoxWidth}
            labelText="결재 상태"
            defaultValue={getDefaultValue("combo", searchParams.approval_status)}
            data={["전체", "임시저장", "승인", "승인대기", "대기", "반려", "회수"]}
            onChangeCallback={(e) => {
              const res = e.target.value === "전체" ? "" : e.target.value;
              setSearchParams("approval_status", e.target.value);
            }}
          />
        </SearchConditionBox>
      </SearchRowBox>
    </SearchContainer>
  );
};
