import failIcon from "assets/icon/check_fail.png";
import successIcon from "assets/icon/check_success.png";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import { getCurrentDate, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Popup from "components/ModalPopup/Popup";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { useRef, useState } from "react";
import DaumPostcode from "react-daum-postcode";
import { useDispatch } from "react-redux";

const PersonalInfo = ({
  onClickOpenModal,
  memberInputState,
  baseInfoState,
  navigate,
  setRemovePhoneMemberId,
  onModalDone,
  phoneCheckStatus,
  setPhoneCheckStatus,
}) => {
  const onOpenCheckPhonePopup = (phone) => {
    // console.log(baseInfoState);
    const param = {
      title: "연락처 중복 확인",
      modalParam: {
        phone: phone,
        branch_info: baseInfoState.branch_setting.branch_info,
        navigate: navigate,
      },
      // onModalDone: (e, param1, param2) => {
      //   console.log("1");
      //   if (param1 !== "") {
      //     console.log("2");
      //     setPhoneCheckStatus("Y");
      //   }
      //   console.log("3");
      //   // onModalDone(e, param1, param2);
      // },
    };
    onClickOpenModal("연락처중복", param);
  };

  return (
    <CardFixed
      topDom={<div>회원정보</div>}
      midDom={
        <PersonalInfoCardItem
          onOpenCheckPhonePopup={onOpenCheckPhonePopup}
          memberInputState={memberInputState.personal_info}
          setRemovePhoneMemberId={setRemovePhoneMemberId}
          phoneCheckStatus={phoneCheckStatus}
          setPhoneCheckStatus={setPhoneCheckStatus}
        />
      }
      isLoading={false}
      rootStyle={{
        width: "54.88rem",
        height: "15.5rem",
        marginLeft: "1rem",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};
export default PersonalInfo;

//

const PersonalInfoCardItem = ({
  onOpenCheckPhonePopup,
  memberInputState,
  setRemovePhoneMemberId,
  phoneCheckStatus,
  setPhoneCheckStatus,
}) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const textBoxWidth_1 = "12rem";
  const lineMarginTop = "0.5rem";
  const labelWidth = "4.5rem";

  let [phone1, setPhone1] = useState("");
  let [phone2, setPhone2] = useState("");
  let [phone3, setPhone3] = useState("");
  const phoneRef1 = useRef();
  const phoneRef2 = useRef();
  const phoneRef3 = useRef();

  const [isAddrPopup, setAddrPopup] = useState(false);
  const openAddrPopup = (val) => {
    setAddrPopup(val);
  };

  const handleComplete = (data) => {
    openAddrPopup(false);
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    memberInputState.address_1 = fullAddress;
  };

  const onCheckPhone = () => {
    if (phone1.length === 3 && phone2.length === 4 && phone3.length === 4) {
      HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member/check_phone", {
        params: {
          phone: phone1 + "-" + phone2 + "-" + phone3,
        },
      })
        .then((res) => {
          if (!restEmpty(res.data.data)) {
            const isDuplicate = res.data.data;
            if (isDuplicate === "N") {
              setPhoneCheckStatus("Y");
              memberInputState.phone = phone1 + "-" + phone2 + "-" + phone3;
              setRemovePhoneMemberId("");
            } else {
              setPhoneCheckStatus("N");
              memberInputState.phone = "";
            }
          }
        })
        .catch((err) => {
          console.log("err");
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        });
    } else {
      setRemovePhoneMemberId("");
      setPhoneCheckStatus("");
      memberInputState.phone = "";
    }

    setPhone1(phone1);
    setPhone2(phone2);
    setPhone3(phone3);
  };

  const checkDuplMember = () => {
    onOpenCheckPhonePopup(phone1 + "-" + phone2 + "-" + phone3);
  };

  return (
    <div style={{}}>
      <Popup
        isOpen={isAddrPopup}
        onClose={() => {
          openAddrPopup(false);
        }}
        title="주소 검색"
        Content={
          <DaumPostcode
            onComplete={handleComplete}
            autoClose={false}
            style={{ width: "30rem", height: "35rem" }}
          />
        }
      />

      <div style={{}}>
        <div style={{ display: "flex" }}>
          <TextField
            required
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth_1}
            labelText="회원명"
            onChangeCallback={(e) => {
              memberInputState.member_name = e;
            }}
          />

          <DatePicker
            marginLeft="2rem"
            required
            labelWidth={labelWidth}
            minWidth={textBoxWidth_1}
            labelText="생년월일"
            minDate={"1900-01-01"}
            maxDate={getCurrentDate()}
            defaultValue={""}
            onChangeCallback={(e) => {
              memberInputState.birth = e;
            }}
          />
        </div>

        <div style={{ display: "flex", marginTop: lineMarginTop }}>
          <ComboBox
            required
            labelWidth={labelWidth}
            comboItemWidth={textBoxWidth_1}
            labelText="성별"
            // defaultValue={baseInfoState.selected_branch_name}
            data={["남", "여"]}
            onChangeCallback={(e) => {
              memberInputState.gender = e.target.value;
            }}
          />

          <TextField
            inputRef={phoneRef1}
            marginLeft="2rem"
            required
            textMarginRight="0.25rem"
            labelWidth={labelWidth}
            textBoxWidth={"5.5rem"}
            labelText="연락처"
            maxLength={3}
            isStringNumber
            // defaultValue={phone1}
            onChangeCallback={(e) => {
              phone1 = e;
              if (phone1.length === 3) {
                phoneRef2.current.select();
              }
              onCheckPhone();
            }}
          />
          <TextField
            inputRef={phoneRef2}
            required
            textMarginRight="0.25rem"
            labelMarginRight="0.25rem"
            labelWidth="0.5rem"
            labelText="-"
            maxLength={4}
            isStringNumber
            textBoxWidth={"5.5rem"}
            onChangeCallback={(e) => {
              phone2 = e;
              onCheckPhone();
              if (phone2.length === 4) {
                phoneRef3.current.select();
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Backspace" && !phone2) {
                phoneRef1.current.select();
              }
            }}
          />
          <TextField
            inputRef={phoneRef3}
            required
            textMarginRight="0.35rem"
            labelMarginRight="0.25rem"
            labelWidth="0.5rem"
            labelText="-"
            maxLength={4}
            isStringNumber
            textBoxWidth={"5.5rem"}
            onChangeCallback={(e) => {
              phone3 = e;
              onCheckPhone();
            }}
            onKeyDown={(e) => {
              if (e.key === "Backspace" && !phone3) {
                phoneRef2.current.select();
              }
            }}
          />
          {phoneCheckStatus === "Y" ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img
                alt="O"
                src={successIcon}
                style={{
                  display: "flex",
                  width: "1.4rem",
                  height: "1.4rem",
                  marginRight: "0.35rem",
                }}
              />
            </div>
          ) : phoneCheckStatus === "N" ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img
                alt="O"
                src={failIcon}
                style={{
                  display: "flex",
                  width: "1.4rem",
                  height: "1.4rem",
                  marginRight: "0.35rem",
                }}
              />
              <NormalBtn
                theme="red"
                name="중복자 확인"
                padding="0 0.895rem"
                onClick={() => checkDuplMember()}
              />
            </div>
          ) : phoneCheckStatus === "S" ? (
            <>
              <Label labelText="등록 시 중복 연락처를 삭제합니다." fontSize="0.85rem" color="red" />
            </>
          ) : (
            <></>
          )}
        </div>

        <div style={{ display: "flex", marginTop: "0.5rem" }}>
          <Label labelText="주소" marginRight="3.25rem" />
          <IconButton
            type="search"
            width="2.2rem"
            onClick={() => {
              openAddrPopup(true);
            }}
          />
          <TextField
            required
            textBoxWidth={"22.75rem"}
            textMarginRight="0rem"
            labelMarginRight="0.25rem"
            disabled
            defaultValue={memberInputState.address_1}
          />
          <TextField
            textBoxWidth={"21rem"}
            textMarginRight="0rem"
            labelMarginRight="0.25rem"
            onChange={(e) => {
              memberInputState.address_2 = e.target.value;
            }}
          />
        </div>
      </div>

      <div
        style={{
          width: "52.812rem",
          height: "0.063rem",
          margin: "0.4rem 0",
          backgroundColor: "#ebebeb",
        }}
      ></div>
      <div style={{ display: "flex" }}>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={"46.5rem"}
          labelText="특이사항"
          onChangeCallback={(e) => {
            memberInputState.notice = e;
          }}
        />
      </div>
    </div>
  );
};
