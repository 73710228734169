//
// TASK INFO
//
export const loadTaskInfo = (id) => {
  return {
    type: "LOAD_TASK_INFO",
    _id: id,
  };
};

export const loadTaskInfoSuccess = (data) => {
  return {
    type: "LOAD_TASK_INFO_SUCCESS",
    payload: data,
  };
};

export const loadTaskInfoFail = (data) => {
  return {
    type: "LOAD_TASK_INFO_FAIL",
    payload: data,
  };
};

//
// REQUEST RESULT LIST
//
export const loadCSList = (curCSTarget, page_num, is_all, is_tm_recv_ok, is_tm_recv_no) => {
  return {
    type: "LOAD_CS_LIST",
    // data,
    curCSTarget: curCSTarget,
    page_num: page_num,
    is_all: is_all,
    is_tm_recv_ok: is_tm_recv_ok,
    is_tm_recv_no: is_tm_recv_no,
  };
};

export const loadCSListSuccess = (data) => {
  return {
    type: "LOAD_CS_LIST_SUCCESS",
    payload: data,
  };
};

export const loadCSListFail = (data) => {
  return {
    type: "LOAD_CS_LIST_FAIL",
    payload: data,
  };
};

//
// CS INFO
//
export const loadCSInfo = (curCSTarget, curMemberTarget) => {
  console.log(curCSTarget, curMemberTarget);
  return {
    type: "LOAD_CS_INFO",
    curCSTarget: curCSTarget,
    curMemberTarget: curMemberTarget,
    // data,
  };
};

export const loadCSInfoSuccess = (data) => {
  return {
    type: "LOAD_CS_INFO_SUCCESS",
    payload: data,
  };
};

export const loadCSInfoFail = (data) => {
  return {
    type: "LOAD_CS_INFO_FAIL",
    payload: data,
  };
};

//
// CS INFO
//
export const loadCSFilterInfo = (data) => {
  return {
    type: "LOAD_CS_FILTER_INFO",
    data,
  };
};

export const loadCSFilterInfoSuccess = (data) => {
  return {
    type: "LOAD_CS_FILTER_INFO_SUCCESS",
    payload: data,
  };
};

export const loadCSFilterInfoFail = (data) => {
  return {
    type: "LOAD_CS_FILTER_INFO_FAIL",
    payload: data,
  };
};

//
// CS INFO
//
export const loadCSDetailHealthInfo = (memberOid) => {
  return {
    type: "LOAD_CS_DETAIL_HEALTH_INFO",
    memberOid: memberOid,
  };
};

export const loadCSDetailHealthInfoSuccess = (data) => {
  return {
    type: "LOAD_CS_DETAIL_HEALTH_INFO_SUCCESS",
    payload: data,
  };
};

export const loadCSDetailHealthInfoFail = (data) => {
  return {
    type: "LOAD_CS_DETAIL_HEALTH_INFO_FAIL",
    payload: data,
  };
};
