import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PtClassCard from "../Modal/WorkInfoItem/SalaryCard/PtClassCard";
import PtCommissionCard from "../Modal/WorkInfoItem/SalaryCard/PtCommissionCard";
import { pt_comm_preset_init_state } from "../Module/reducers/pt_comm_preset_init_state";

// 커미션 아이템 수정 팝업
const EditCommissionPreset = ({ mode, commissionSet, modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  let [preset, setPreset] = useState(pt_comm_preset_init_state);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCommPreset();
  }, []);

  const getCommPreset = () => {
    HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/staff/pt_commission_preset/data", {
      params: {
        staff_id: modalParam.staff_id,
        branch_id: modalParam.branch_id,
        preset_id: mode === "추가" ? "" : modalParam.preset_id,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          res.data.data.contents.base_info = commissionSet.contents.base_info;
          res.data.data.contents.staff_id = commissionSet.contents.staff_id;
          setPreset(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const onClickSave = () => {
    if (mode === "추가" && !preset.contents.pt_pre_set.preset_name) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="커미션명을 입력해 주시기 바랍니다." />,
          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="저장하시겠습니까?" />,
          // width: "50rem",
          onBtnYes: () => {
            onSave();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const onSave = async () => {
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    const postApi =
      mode === "추가" ? "/staff/pt_commission_preset/add" : "/staff/pt_commission_preset/update";
    const formData = new FormData();
    formData.append("new_info", JSON.stringify(preset));
    setLoading(true);

    await HttpInstance.post(process.env.REACT_APP_STAFF_SERVER + postApi, formData, config)
      .then((res) => {
        console.log(res);
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          onModalDone();
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ width: "68rem" }}>
      {loading && <Loading />}

      <div style={{ display: "flex" }}>
        <TextField
          required
          labelWidth={"4rem"}
          textBoxWidth={"14rem"}
          labelText="커미션명"
          defaultValue={preset.contents.pt_pre_set.preset_name}
          disabled={mode !== "추가"}
          onBlur={(e) => {
            setPreset({
              contents: {
                ...preset.contents,
                pt_pre_set: {
                  ...preset.contents.pt_pre_set,
                  preset_name: e.target.value,
                },
              },
            });
          }}
        />
        <div style={{ float: "right", marginLeft: "auto" }}>
          <Button
            className={clsx({
              [btnStyleClass.btnRoot]: true,
              [btnStyleClass.buttonType4]: true,
            })}
            onClick={onClickSave}
          >
            저장
          </Button>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          marginTop: "1.5rem",
          padding: "1rem",
          border: "1px solid #aaa",
          width: "66rem",
        }}
      >
        <div style={{ width: "32rem" }}>
          <PtCommissionCard workInfoStore={preset.contents.pt_pre_set} />
        </div>
        <div style={{ width: "32rem", marginLeft: "2rem" }}>
          <PtClassCard workInfoStore={preset.contents.pt_pre_set} />
        </div>
      </div>
    </div>
  );
};
export default EditCommissionPreset;
