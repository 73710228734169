import { branchItemState } from "./item_state";

export const branchPrePayStateinitial = {
  stores: {
    data: {
      branch_id: "string / 지점 ID",
      prepay_items: [branchItemState],
      subs_items: [],
      etc_items: [],
    },
  },
};
