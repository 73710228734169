import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TextField from "components/LabelInput/TextField";
import * as React from "react";
// import IconButton from "@mui/material/IconButton";
import IconButton from "components/Button/IconButton";
import { CustomRow } from "components/CustomTable/tableStyle";

// function createData(id, name) {
//   return {
//     name,
//   };
// }

// const rows = [createData(0, "Cupcake")];

export default function EditTable(props) {
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);

  const rows = props.rest_call(props.rest_data);

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = rows.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };
  const onChangeValue = (idx, val) => {
    props.rawChange(idx, val);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);

    let newSelected = "";
    if (selectedIndex === -1) {
      newSelected = name;
    } else if (selectedIndex === 0) {
      newSelected = "";
    }

    // let newSelected = [];

    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, name);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selected.slice(0, selectedIndex),
    //     selected.slice(selectedIndex + 1),
    //   );
    // }

    props.selectChange(newSelected);
    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: "100%", height: "90%", overflow: "auto" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer style={{ overflow: "hidden" }}>
          <Table
            // sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {rows
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // console.log(props.titleName + index.toString());
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <CustomRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox" style={{ width: "0px", padding: "0px" }}>
                        <Checkbox
                          style={{
                            width: "0px",
                            maxWidth: "0px", // percentage also works
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      {/* <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.name}
                      
                      </TableCell> */}
                      <TableCell align="center" style={{ width: "100%", padding: "0px" }}>
                        <TextField
                          id={props.titleName + index.toString()}
                          labelMarginRight="0rem"
                          fullWidth
                          textMarginRight={"0rem"}
                          defaultValue={row.name}
                          onChangeCallback={(e) => {
                            // onChangeValue(index, e);
                          }}
                          onBlur={(e) => {
                            if (e.target.value === "") {
                              props.popAction(index);
                            } else {
                              props.onChangeDone(index, e.target.value);
                            }
                          }}
                        />
                      </TableCell>

                      <TableCell align="right">
                        <IconButton
                          type="delete"
                          onClick={() => {
                            // console.log("edit..", index);
                            props.popAction(index);
                          }}
                        />
                        {/* <IconButton color="primary" aria-label="upload picture" component="span">
                            <DeleteOutlineIcon
                              onClick={() => {
                                // console.log("edit..", index);
                                props.popAction(index);
                              }}
                            />
                          </IconButton> */}
                      </TableCell>
                    </CustomRow>
                  );
                })}
              {emptyRows > 0 && (
                <CustomRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={3} />
                </CustomRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
