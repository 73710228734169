import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import { getByte, simpleAlert } from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import ColorLabel from "components/LabelInput/ColorLabel";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import TimePicker from "components/LabelInput/TimePicker";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import SearchEmployee from "pages/CommonModal/Search/SearchEmployee";
import FileUploadBox from "pages/NoticeBoard/CommonItem/FileUploadBox";
import TextEditor from "pages/NoticeBoard/CommonItem/TextEditor";
import { onSelectBranch, onSelectHq } from "pages/NoticeBoard/model/state_setter";
import { newPutRowAPi } from "pages/NoticeBoard/restApi/putApi";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const StaffDMUpdateView = ({
  nowService,
  postRowState,
  setPostRowState,
  hqBranchData,
  branchList,
}) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const navigate = useNavigate();
  const [hqIdx, setHqIdx] = useState(0);
  const [, updateState] = useState(); // 강제 렌더링용
  const forceUpdate = useCallback(() => updateState({}), []);
  // console.log(postRowState);
  const labelWidth = "4rem";
  //저장 validation
  const validationCheck = () => {
    //본사,지점,제목 확인하고
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="저장 하시겠습니까?" />
          </>
        ),
        onBtnYes: () => {
          editNotice();
        },
        onBtnNo: () => {},
      }),
    );
  };

  const alertCancelMsg = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="작성중인 내용은 저장되지 않습니다." />
            <Label labelText="작성을 취소하시겠습니까?" />
          </>
        ),
        onBtnYes: () => {
          navigate(`/staff_dm/${postRowState.post_id}`);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const editNotice = async () => {
    newPutRowAPi(navigate, storesDispatch, postRowState);
  };

  const onClickAddRow = () => {
    // contentState.staff_list.push(_.cloneDeep(day_off_staff_state));
    // clone.implementation_id = uuidv4();

    // console.log(hqBranchList);
    let test = false;
    if (
      !postRowState.branch_info.branch_name ||
      !postRowState.hq_info.hq_name ||
      postRowState.branch_info.branch_name === "전체"
    ) {
      simpleAlert(storesDispatch, "알림", "지점을 선택해주세요.");
    } else {
      const param = {
        branch_list: [[postRowState.branch_info.branch_name]],
        hq_list: [postRowState.hq_info.hq_name],
        target_list: [{ hq_info: postRowState.hq_info, branch_list: [postRowState.branch_info] }],
      };

      storesDispatch(
        globalModalOn({
          show: true,
          title: "직원 검색",
          Content: <SearchEmployee modalParam={param} onModalDone={onModalDone} />,
        }),
      );

      forceUpdate();
    }
  };

  const onModalDone = (staffInfo) => {
    // console.log(staffInfo);
    const fIdx = postRowState.target_staff_list.findIndex(
      (x) => x.staff_info.staff_id === staffInfo.staff_id,
    );
    if (fIdx !== -1) {
      simpleAlert(storesDispatch, "알림", "이미 추가된 직원입니다.");
    } else {
      const newInfo = {
        staff_info: {
          staff_id: staffInfo.staff_id,
          staff_name: staffInfo.personal_info.staff_name,
          staff_pos: staffInfo.base_info.position.org_name,
          staff_rank: staffInfo.base_info.rank.org_name,
          sabun: staffInfo.personal_info.sabun,
        },
      };
      postRowState.target_staff_list.push(newInfo);
    }
    storesDispatch(globalModalOff());
  };

  const getStaffNamePos = (data) => {
    return data.staff_info.staff_name + " " + data.staff_info.staff_pos;
  };

  return (
    <CardFixed
      topDom={"게시글 수정"}
      midDom={
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ComboBox
              labelText="본부"
              labelWidth="2rem"
              comboItemWidth="14rem"
              // data={[]}
              data={hqBranchData.hq_list}
              defaultValue={hqBranchData.hq_list[0]}
              //  disabled={modalParam.mode === "show"}
              onChangeCallback={(e) => {
                onSelectHq(e.target.value, hqBranchData, setPostRowState, setHqIdx);
                // onSelectHq(e.target.value);
                console.log(e);
              }}
            />
            <ComboBox
              labelText="지점"
              labelWidth="2rem"
              labelMarginLeft="1.2rem"
              comboItemWidth="14rem"
              // data={[]}
              data={hqIdx !== undefined ? hqBranchData.branch_list[hqIdx] : []}
              defaultValue={hqBranchData.branch_list[hqIdx][0]}
              onChangeCallback={(e) => {
                onSelectBranch(e.target.value, hqBranchData, hqIdx, setPostRowState);
              }}
            />
            {/* <CheckBox
                labelText="지점 내 공지"
                transform="scale(1)"
                marginTop="0rem"
                marginRight="0rem"
              /> */}
          </div>
          <TextField
            labelText="제목"
            fullWidth
            labelWidth="2rem"
            defaultValue={postRowState.post_info.title}
            onChangeCallback={(e) => {
              setPostRowState((cur) => {
                return {
                  ...cur,
                  post_info: {
                    ...cur.post_info,
                    title: e,
                  },
                };
              });
            }}
          />
          {/* <div
            style={{ display: "flex", alignItems: "center", marginTop: "0.5rem", gap: "0.5rem" }}
          >
            <TextField labelText="대상직원" fullWidth labelWidth="4rem" disabled />
            <Button
              className={clsx({
                [btnStyleClass.btnRootWhite]: true,
                [btnStyleClass.buttonType4]: true,
              })}
            >
              선택
            </Button>
          </div> */}
          <div style={{ display: "flex" }}>
            <div style={{ width: labelWidth, display: "flex" }}>
              <Label width={labelWidth} labelText="대상직원" justifyContent="left" />
            </div>
            <div style={boxStyle}>
              {postRowState.target_staff_list.map((el) => (
                <ColorLabel
                  width={((getByte(getStaffNamePos(el)) * 0.9) / 2 + 0.5).toString() + "rem"}
                  labelText={getStaffNamePos(el)}
                  bgColor="#222222"
                  fontColor="#FFFFFF"
                />
              ))}
            </div>
            {/* </div> */}
            <Button
              className={clsx({
                [btnStyleClass.btnRootWhite]: true,
                [btnStyleClass.buttonType4]: true,
              })}
              onClick={() => onClickAddRow()}
            >
              선택
            </Button>
          </div>
          <div style={{ display: "flex", marginTop: "0.5rem" }}>
            <DatePicker
              labelText="스케줄"
              labelWidth="3.8rem"
              textMarginRight="0rem"
              textBoxWidth="11rem"
              defaultValue={
                postRowState.schedule_date_period.from_date
                  ? postRowState.schedule_date_period.from_date
                  : ""
              }
              onChangeCallback={(e) => {
                if (
                  postRowState.schedule_date_period.to_date &&
                  new Date(e) > new Date(postRowState.schedule_date_period.to_date)
                ) {
                  storesDispatch(
                    globalAlertOn({
                      show: true,
                      titleText: "알림",
                      bodyText: (
                        <>
                          <Label labelText="시작일은 종료일보다 클 수 없습니다." />
                        </>
                      ),
                      onBtnOk: () => {},
                    }),
                  );
                  e = "";
                } else {
                  setPostRowState((cur) => {
                    return {
                      ...cur,
                      schedule_date_period: {
                        ...cur.schedule_date_period,
                        from_date: e,
                      },
                    };
                  });
                }
              }}
            />

            <TimePicker
              labelMarginRight="0.3rem"
              textBoxWidth="11rem"
              defaultValue={
                postRowState.schedule_date_period.from_time
                  ? postRowState.schedule_date_period.from_time
                  : ""
              }
              onChange={(e) => {
                setPostRowState((cur) => {
                  return {
                    ...cur,
                    schedule_date_period: {
                      ...cur.schedule_date_period,
                      from_time: e,
                    },
                  };
                });
              }}
            />
            <DatePicker
              labelText="~"
              labelWidth="0.5rem"
              textMarginRight="0rem"
              textBoxWidth="11rem"
              defaultValue={
                postRowState.schedule_date_period.to_date
                  ? postRowState.schedule_date_period.to_date
                  : ""
              }
              onChangeCallback={(e) => {
                if (
                  postRowState.schedule_date_period.from_date &&
                  new Date(postRowState.schedule_date_period.from_date) > new Date(e)
                ) {
                  storesDispatch(
                    globalAlertOn({
                      show: true,
                      titleText: "알림",
                      bodyText: (
                        <>
                          <Label labelText="시작일은 종료일보다 클 수 없습니다." />
                        </>
                      ),
                      onBtnOk: () => {},
                    }),
                  );
                  e = "";
                } else {
                  setPostRowState((cur) => {
                    return {
                      ...cur,
                      schedule_date_period: {
                        ...cur.schedule_date_period,
                        to_date: e,
                      },
                    };
                  });
                }
              }}
            />
            <TimePicker
              labelMarginRight="0.3rem"
              textBoxWidth="11rem"
              defaultValue={
                postRowState.schedule_date_period.to_time
                  ? postRowState.schedule_date_period.to_time
                  : ""
              }
              onChange={(e) => {
                setPostRowState((cur) => {
                  return {
                    ...cur,
                    schedule_date_period: {
                      ...cur.schedule_date_period,
                      to_time: e,
                    },
                  };
                });
              }}
            />
            <CheckBox
              transform="scale(1.1)"
              marginTop="0rem"
              labelText="등록"
              labelWidth="2rem"
              marginRight="0rem"
            />
          </div>
          <TextEditor
            defaultValue={postRowState.post_info.contents}
            onChangeCallback={(e) => {
              setPostRowState((cur) => {
                return {
                  ...cur,
                  post_info: {
                    ...cur.post_info,
                    contents: e,
                  },
                };
              });
            }}
          />

          <FileUploadBox postRootState={postRowState} nowService={nowService} />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "0.5rem",
              marginTop: "1.2rem",
            }}
          >
            <Button
              className={clsx({
                [btnStyleClass.btnRootGrayWhite]: true,
                [btnStyleClass.buttonType4]: true,
              })}
              onClick={alertCancelMsg}
            >
              취소
            </Button>
            <Button
              className={clsx({
                [btnStyleClass.btnRootRaw]: true,
                [btnStyleClass.buttonType4]: true,
              })}
              onClick={validationCheck}
            >
              저장
            </Button>
          </div>
        </div>
      }
      isLoading={false}
      rootStyle={{
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};
export default StaffDMUpdateView;

const boxStyle = {
  marginTop: "0.5rem",
  width: "100%",
  minHeight: "2.5rem",
  borderRadius: "5px",
  border: "1px solid #BBBBBB",
  backgroundColor: "#ebebeb",
  fontFamily: "AppleSDGothicNeo",
  fontSize: "0.9rem",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#000",
  boxSizing: "border-box",
  padding: "0.2rem",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: "0.2rem",
};
