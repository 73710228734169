import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Collapse, IconButton as MuiIconButton } from "@mui/material";
import Label from "components/LabelInput/Label";
import MemberContidionContents from "./MemberConditionContents";

const MemberConditionArea = (props) => {
  const {
    searchParam,
    setSearchParam,
    openMemberInfo,
    setOpenMemberInfo,
    onClickSearch,
    resetFlag,
  } = props;

  return (
    <>
      <Box
        sx={{
          border: "1px solid #dedede",
          borderRadius: "5px",
          backgroundColor: "#f5f5f5",
        }}
      >
        <div
          style={{ display: "flex" }}
          onClick={() => {
            setOpenMemberInfo(!openMemberInfo);
          }}
        >
          <MuiIconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpenMemberInfo(!openMemberInfo);
            }}
          >
            {openMemberInfo ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </MuiIconButton>
          <Label labelText="회원 정보" />
        </div>

        <Collapse in={openMemberInfo} timeout="auto" unmountOnExit>
          <div style={{ margin: "0.75rem 0.5rem" }}>
            <MemberContidionContents
              searchParam={searchParam}
              setSearchParam={setSearchParam}
              onClickSearch={onClickSearch}
              resetFlag={resetFlag}
            />
          </div>
        </Collapse>
      </Box>
    </>
  );
};
export default MemberConditionArea;
