import { Button } from "@material-ui/core";
import border from "assets/icon/Border.svg";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import { useGetAuthData } from "hooks/apis/access/useAuth";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import SearchStaffInBranch from "pages/CommonModal/Search/SearchStaffInBranch";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ptDetailInit } from "../Module/reducers/pt_manage_search_param";
import AddOtServiceSessionModal from "./AddOtServiceSessionModal";
import ClassProcessModal from "./ClassProcessModal";
import OtReservationModal from "./OtReservationModal";

const PtClassDetailModal = ({ modalParam, ptClassOnclickSearch }) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();
  const labelWidth = "5rem";
  const textBoxWidth = "12rem";
  const textBoxWidthShort = "9.5rem";
  const textBoxWidthLong = "51.4rem";

  const viewModelRef = useRef();
  const [isAdmin, setIsAdmin] = useState(false);

  // [GET] 권한 정보 가져오기
  const { data: authData } = useGetAuthData();

  useEffect(() => {
    if (authData) {
      setIsAdmin(authData.data.data.is_admin);
    }
  }, [authData]);

  const [loading, setLoading] = useState(false);
  const [viewInfo, setViewInfo] = useState(_.cloneDeep(ptDetailInit));
  const [isModify, setIsModify] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });

  const num = useRef(1);
  const possibleRate = useRef(0);

  //화면 상세정보 GET
  useEffect(() => {
    getDetailInfo();
  }, [modalParam]);

  //수업차수
  useEffect(() => {
    const firstOne = viewInfo.pt_class.find((el) => !el.proceed_class.class_done_date);
    if (firstOne) {
      const presentNo = firstOne.no;
      num.current = presentNo;
    }
    const priceRate = viewInfo.pt_price.paid_price / viewInfo.pt_price.total_price;
    const classRate = Math.floor(
      (viewInfo.pt_info.base_session_cnt + viewInfo.pt_info.service_session_cnt) * priceRate,
    );
    possibleRate.current = classRate;
  }, [viewInfo]);

  const checkIsPossibleManage = () => {
    // console.log("비율", possibleRate.current, num.current);

    if (
      !viewInfo.cur_manager.staff_info.staff_name ||
      viewInfo.cur_manager.staff_info.staff_name === "미배정"
    ) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="수업담당자를 먼저 배정해주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else if (viewInfo.pt_info.root_status !== "수강") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <div>
              <Label
                labelText="PT상태가 수강이 아닌 경우, 수업처리가 불가합니다. 
              "
              />
              <Label labelText="수강중인 PT상품의 수업처리를 완료해주시기 바랍니다" />
            </div>
          ),
          onBtnOk: () => {},
        }),
      );
    }
    // else if (possibleRate.current < num.current) {
    //   storesDispatch(
    //     globalAlertOn({
    //       show: true,
    //       titleText: "알림",
    //       bodyText: (
    //         <>
    //           <Label labelText="수업처리가 불가합니다. 미납금을 결제해주세요." />
    //         </>
    //       ),
    //       onBtnOk: () => {},
    //     }),
    //   );
    // }
    else {
      popPtManageModal(
        "PT 수업 처리",
        {
          mode: "pt",
          data: modalParam.data,
          no: num.current,
        },
        refreshView,
      );
    }
  };

  const getDetailInfo = async () => {
    try {
      setLoading(true);
      const res = await HttpInstance.get(
        process.env.REACT_APP_STAFF_SERVER + "/class/pt_detail_info",
        {
          params: {
            pt_detail_id: modalParam.data.pt_detail_id,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setViewInfo(res.data.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const doRevokeClass = async (ptDetailId, classNo) => {
    try {
      setLoading(true);
      const res = await HttpInstance.get(
        process.env.REACT_APP_USER_SERVER + "/admin/class/revoke",
        {
          params: {
            pt_detail_id: ptDetailId,
            class_no: classNo,
          },
        },
      );

      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        simpleAlert(storesDispatch, "알림", "수업 취소가 완료되었습니다.");
        getDetailInfo();
      }
    } catch (err) {
      simpleAlert(storesDispatch, "ERROR", err.response.data.msg);
    } finally {
      setLoading(false);
    }
  };

  const changePtManager = async (url, staffInfo) => {
    try {
      const formData = new FormData();
      const info = {
        pt_detail_id: modalParam.data.pt_detail_id,
        staff_info: staffInfo,
      };
      formData.append("new_info", JSON.stringify(info));
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_STAFF_SERVER + url,
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: <Label labelText={`${staffInfo.staff_name} 으로 배정되었습니다.`} />,
            onBtnOk: () => {
              getDetailInfo(); //상세화면 다시 GET
              modalParam.refreshTable(); //검결 테이블 다시 갱신
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const validationCheck = () => {
    if (isModify) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="저장 하시겠습니까?" />
            </>
          ),

          onBtnYes: () => {
            editComment();
          },
          onBtnNo: () => {},
        }),
      );
    } else {
      setIsModify(true);
    }
  };

  const editComment = async () => {
    try {
      const formData = new FormData();
      const info = {
        comment: viewInfo.pt_info.comment,
        pt_detail_id: modalParam.data.pt_detail_id,
        staff_info: modalParam.data.cur_manager.staff_info,
      };
      formData.append("new_info", JSON.stringify(info));
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_STAFF_SERVER + "/class/change/pt/comment",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        setIsModify(false);
        getDetailInfo(); //상세화면 다시 GET
        modalParam.refreshTable(); //검결 테이블 다시 갱신
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  //직원검색 모달 DONE
  const searchStaffModalDone = (data) => {
    const staffInfo = {
      staff_name: data.personal_info.staff_name,
      staff_id: data.staff_id,
      staff_pos: data.base_info.position.org_name,
      staff_rank: data.base_info.rank.org_name,
    };
    if (
      viewInfo.cur_manager.staff_info.staff_name === "미배정" ||
      !viewInfo.cur_manager.staff_info.staff_name
    ) {
      //신규등록 POST
      changePtManager("/class/assign_pt", staffInfo);
    } else {
      //변경 POST
      changePtManager("/class/change_pt_manager", staffInfo);
    }
    viewModelRef.current.setClose();
  };

  const refreshView = (isCancel) => {
    if (isCancel) {
      viewModelRef.current.setClose();
    } else {
      getDetailInfo(); //상세정보 화면 갱신
      modalParam.refreshTable(); //검결 테이블 다시 갱신
      viewModelRef.current.setClose();
    }
  };

  const popAddOtServiceSessionModal = (title, param, localModalDone) => {
    if (
      !viewInfo.cur_manager.staff_info.staff_name ||
      viewInfo.cur_manager.staff_info.staff_name === "미배정"
    ) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="수업담당자를 먼저 배정해주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else {
      setModalInfo({
        show: true,
        title: title,
        Content: <AddOtServiceSessionModal modalParam={param} onModalDone={localModalDone} />,
        callback: null,
        Buttons: undefined,
      });
    }
  };

  const popSearchStaffModal = (title, param, onModalDone) => {
    setModalInfo({
      show: true,
      title: title,
      Content: <SearchStaffInBranch modalParam={param} onModalDone={onModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const popOtReservationModal = (title, param, localModalDone) => {
    if (
      !viewInfo.cur_manager.staff_info.staff_name ||
      viewInfo.cur_manager.staff_info.staff_name === "미배정"
    ) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="수업담당자를 먼저 배정해주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else {
      setModalInfo({
        show: true,
        title: title,
        Content: <OtReservationModal modalParam={param} onModalDone={localModalDone} />,
        callback: null,
        Buttons: undefined,
      });
    }
  };

  const popPtManageModal = (title, param, localModalDone) => {
    setModalInfo({
      show: true,
      title: title,
      Content: <ClassProcessModal modalParam={param} onModalDone={localModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const onClickRevoke = (ptDetailId, classNo) => {
    simpleAlert(
      storesDispatch,
      "알림",
      classNo + "회차 수업을 취소하시겠습니까?",
      false,
      null,
      () => {
        doRevokeClass(ptDetailId, classNo);
      },
    );
  };

  const sessionList = new sessionListTable(modalParam.data.pt_detail_id, isAdmin, onClickRevoke);

  //
  // TEMP!!!!!!!
  const onClickBtnTemp = async (mode) => {
    const curStatus = modalParam.data.pt_info.root_status;

    await HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/temp/update/class", {
      params: {
        member_no: modalParam.data.membership_info.member_no,
        item_name: modalParam.data.pt_info.item_name,
        mode: mode,
      },
    })
      .then((res) => {
        if (res.data.msg) {
          console.log("err");
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          let msgStr = "처리 완료되었습니다.";
          if (curStatus === "만료" && (mode === "1" || mode === "2")) {
            msgStr =
              "처리 완료 되었습니다.\n\n만료에서 상태 변경 시 remainCnt를 조정해야 합니다.\n 반드시 메모해 두시기 바랍니다.";
            getDetailInfo(); //상세화면 다시 GET
            modalParam.refreshTable(); //검결 테이블 다시 갱신
          }
          simpleAlert(storesDispatch, "알림", msgStr);
          ptClassOnclickSearch(1);
        }
      })
      .catch((err) => {
        console.log("err");
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  // 수업 담당자 찾기
  const onClickSearchStaffIcon = () => {
    if (viewInfo.pt_info.remain_session_cnt <= 0) {
      simpleAlert(storesDispatch, "알림", "남은 횟수가 없는 수업은 담당자를 배정할 수 없습니다.");
      return;
    }
    popSearchStaffModal(
      "직원 검색",
      {
        branch_info: modalParam.data.branch_info,
      },
      searchStaffModalDone,
    );
  };

  return (
    <div style={{ width: "58rem" }}>
      {loading && <Loading />}
      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />

      <div>
        <div>
          {isAdmin ? (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <SubTitle titleText="관리자용 임시 버튼" />
              <Button
                style={{ marginLeft: "1rem" }}
                className={clsx({
                  [btnStyleClass.btnRootWhite]: true,
                  [btnStyleClass.buttonType6]: true,
                })}
                onClick={() => {
                  onClickBtnTemp("1");
                }}
              >
                수강 처리
              </Button>
              <Button
                style={{ marginLeft: "0.5rem" }}
                className={clsx({
                  [btnStyleClass.btnRootWhite]: true,
                  [btnStyleClass.buttonType6]: true,
                })}
                onClick={() => {
                  onClickBtnTemp("2");
                }}
              >
                대기 처리
              </Button>
              <Button
                style={{ marginLeft: "1rem" }}
                className={clsx({
                  [btnStyleClass.btnRoot]: true,
                  [btnStyleClass.buttonType6]: true,
                })}
                onClick={() => {
                  onClickBtnTemp("3");
                }}
              >
                만료 처리
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            alt="border"
            src={border}
            style={{
              width: "1.2rem",
              height: "1.2rem",
            }}
          />

          <SubTitle titleText="회원 정보" />
        </div>
        <div>
          <div style={{ display: "flex" }}>
            <TextField
              labelMarginLeft="1rem"
              labelWidth={labelWidth}
              textBoxWidth={textBoxWidth}
              labelText="회원번호"
              defaultValue={viewInfo.membership_info.member_no}
              disabled
            />
            <TextField
              labelWidth={labelWidth}
              labelMarginLeft="1.5rem"
              textBoxWidth={textBoxWidth}
              labelText="이름"
              defaultValue={viewInfo.membership_info.member_name}
              disabled
            />
            <TextField
              labelMarginLeft="1.5rem"
              labelWidth={labelWidth}
              textBoxWidth={textBoxWidth}
              labelText="상담자"
              defaultValue={viewInfo.cs_staff.staff_name}
              disabled
            />
          </div>
          <div style={{ display: "flex", marginTop: "0.5rem" }}>
            <TextField
              labelMarginLeft="1rem"
              labelWidth={labelWidth}
              textMarginRight="0.3rem"
              textBoxWidth={"49rem"}
              labelText="특이사항"
              defaultValue={viewInfo.pt_info.comment}
              disabled={!isModify}
              onChangeCallback={(e) => {
                setViewInfo((cur) => {
                  const obj = { ...cur };
                  obj.pt_info.comment = e;
                  return obj;
                });
              }}
            />
            {isModify ? (
              <IconButton type="save_border" width={"2.3rem"} onClick={validationCheck} />
            ) : (
              <IconButton width={"2.3rem"} type="editBorder" onClick={validationCheck} />
            )}
          </div>
          <div style={{ marginTop: "1.5rem" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt="border"
                src={border}
                style={{
                  width: "1.2rem",
                  height: "1.2rem",
                }}
              />

              <SubTitle titleText="수업 정보" />
            </div>
            <div>
              <TextField
                labelMarginLeft="1rem"
                labelMarginRight="0.725rem"
                labelWidth={labelWidth}
                labelLetterSpacing="-1.2px"
                textBoxWidth={textBoxWidthLong}
                labelText={`PT 계약 상품명`}
                defaultValue={viewInfo.pt_info.item_name}
                disabled
              />
            </div>
            <div style={{ display: "flex", marginTop: "0.5rem" }}>
              <div style={{ display: "flex", marginRight: "0.5rem" }}>
                <TextField
                  labelMarginLeft="1rem"
                  labelWidth={labelWidth}
                  textMarginRight="0.3rem"
                  textBoxWidth={textBoxWidthShort}
                  labelText="수업 담당자"
                  defaultValue={
                    viewInfo.cur_manager.staff_info.staff_name
                      ? viewInfo.cur_manager.staff_info.staff_name
                      : "미배정"
                  }
                  disabled
                />

                <IconButton
                  width={"2.3rem"}
                  type="profile"
                  // disabled={viewInfo.pt_info.remain_session_cnt <= 0}
                  onClick={onClickSearchStaffIcon}
                />
              </div>
              <TextField
                labelWidth={labelWidth}
                labelMarginLeft="1.5rem"
                textBoxWidth={textBoxWidth}
                labelText="수업 상태"
                defaultValue={viewInfo.pt_info.class_status}
                disabled
              />
              <TextField
                labelMarginLeft="1.5rem"
                labelWidth={labelWidth}
                textBoxWidth={textBoxWidth}
                labelText="최근 예약일"
                defaultValue={viewInfo.pt_info.latest_reserv_date}
                disabled
              />
            </div>
            <div style={{ display: "flex", marginTop: "0.5rem" }}>
              <TextField
                labelMarginLeft="1rem"
                labelWidth={labelWidth}
                textBoxWidth={textBoxWidth}
                labelText="총 상품금액"
                endAdornment={"원"}
                defaultValue={viewInfo.pt_price.total_price.toLocaleString()}
                isNumber
                disabled
              />
              {/* <TextField
                labelWidth={label_width}
                labelMarginLeft="1.5rem"
                textBoxWidth={box_width}
                labelText="미납금"
                endAdornment={"원"}
                defaultValue={(
                  viewInfo.pt_price.total_price - viewInfo.pt_price.paid_price
                ).toLocaleString()}
                disabled
              />
              <TextField
                labelMarginLeft="1.5rem"
                labelWidth={long_label_width}
                textBoxWidth={box_width}
                endAdornment={"원"}
                labelText="계약 세션가"
                defaultValue={viewInfo.pt_price.per_price.toLocaleString()}
                disabled
              /> */}
            </div>
          </div>

          <div style={{ display: "flex", marginTop: "0.5rem" }}>
            <TextField
              labelMarginLeft="1rem"
              labelWidth={labelWidth}
              textBoxWidth={textBoxWidth}
              endAdornment={"회"}
              labelText="기본 세션"
              defaultValue={viewInfo.pt_info.base_session_cnt}
              isNumber
              disabled
            />
            <TextField
              labelWidth={labelWidth}
              labelMarginLeft="1.5rem"
              textBoxWidth={textBoxWidth}
              labelText="서비스 세션"
              endAdornment={"회"}
              defaultValue={
                viewInfo.pt_info.service_session_cnt !== 0
                  ? viewInfo.pt_info.service_session_cnt
                  : "0"
              }
              isNumber
              disabled
            />
          </div>
        </div>
      </div>
      <div style={{ marginTop: "0.7rem" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            alt="border"
            src={border}
            style={{
              width: "1.2rem",
              height: "1.2rem",
            }}
          />

          <SubTitle titleText="세션 목록" />
        </div>

        <CustomTable
          columns_head={isAdmin ? sessionList.columns_head_admin : sessionList.columns_head}
          table_title={sessionList.table_title}
          rest_call={sessionList.get_data_from_rest}
          row_render={sessionList.create_table}
          rest_data={viewInfo.pt_class}
        />
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "1.5rem", gap: "0.5rem" }}
      >
        {modalParam.canAddServiceSession ? (
          <NormalBtn
            name="서비스 세션 추가"
            theme="white"
            onClick={() => {
              popAddOtServiceSessionModal(
                "PT 서비스 세션 추가",
                {
                  mode: "pt",
                  data: modalParam.data,
                },
                refreshView,
              );
            }}
          />
        ) : (
          ""
        )}

        <NormalBtn
          name="수업예약"
          theme="white"
          onClick={() => {
            popOtReservationModal(
              "PT 수업 예약",
              { mode: "PT", data: modalParam.data },
              refreshView,
            );
          }}
          disabled={viewInfo.pt_info.remain_session_cnt <= 0}
        />
        <NormalBtn
          name="수업처리"
          onClick={checkIsPossibleManage}
          disabled={viewInfo.pt_info.remain_session_cnt <= 0}
        />
      </div>
    </div>
  );
};

export default PtClassDetailModal;

class sessionListTable {
  table_title = "";
  ptDetailId = "";
  isAdmin = false;
  onClickRevoke = null;

  constructor(ptDetailId, isAdmin, onClickRevoke) {
    this.ptDetailId = ptDetailId;
    this.isAdmin = isAdmin;
    this.onClickRevoke = onClickRevoke;
  }

  revokeClass = (data) => {
    this.onClickRevoke(this.ptDetailId, data.no);
  };

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.session_type}</CustomTableContents>
        <CustomTableContents>{data.no}</CustomTableContents>
        <CustomTableContents>{data.proceed_class.class_done_date}</CustomTableContents>
        <CustomTableContents>{data.staff_info.staff_name}</CustomTableContents>
        <CustomTableContents>{data.proceed_class.comment}</CustomTableContents>
        <CustomTableContents>{data.memo}</CustomTableContents>

        {this.isAdmin ? (
          <>
            {data.proceed_class.class_done_date ? (
              <CustomTableContents>
                <NormalBtn
                  width="4rem"
                  theme="red"
                  name="취소"
                  onClick={() => {
                    this.revokeClass(data);
                  }}
                />
              </CustomTableContents>
            ) : (
              <CustomTableContents></CustomTableContents>
            )}
          </>
        ) : (
          <></>
        )}
      </CustomRow>
    );
  };

  columns_head = [
    { title: "세션구분", width: "15%" },
    { title: "수업차수", width: "15%" },
    { title: "수업일", width: "15%" },
    { title: "수업 담당자", width: "15%" },
    { title: "메모", width: "20%" },
    { title: "비고", width: "20%" },
  ];
  columns_head_admin = [
    { title: "세션구분", width: "13%" },
    { title: "수업차수", width: "10%" },
    { title: "수업일", width: "13%" },
    { title: "수업 담당자", width: "12%" },
    { title: "메모", width: "18%" },
    { title: "비고", width: "18%" },
    { title: "수업취소", width: "12%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
