// import { registerAction } from "./Module/actions";
import NumberTextField from "components/LabelInput/NumberTextField";
import SubTitle from "components/PageTitle/SubTitle";

const DailyItem = ({ restData }) => {
  const textBoxWidth = "10rem";
  const textLabelWidth = "6rem";
  const labelMarginLeft = "2.625rem";
  return (
    <div style={{}}>
      <SubTitle titleText={"일일권 정가"} />
      <div style={{ marginLeft: "2rem" }}>
        <div
          style={{
            display: "flex",
            marginTop: "0.625rem",
          }}
        >
          <NumberTextField
            isNumber
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· 회원권 1일"
            endAdornment="원"
            minValue={0}
            maxValue={99999999}
            defaultValue={restData.base_policy.oneday_mbshp}
            onChangeCallback={(e) => {
              restData.base_policy.oneday_mbshp = e;
            }}
          />

          <NumberTextField
            isNumber
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelMarginLeft={labelMarginLeft}
            labelText="· 운동복 1일"
            endAdornment="원"
            minValue={0}
            maxValue={99999999}
            defaultValue={restData.base_policy.oneday_cloth}
            onChangeCallback={(e) => {
              restData.base_policy.oneday_cloth = e;
            }}
          />

          <NumberTextField
            isNumber
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelMarginLeft={labelMarginLeft}
            labelText="· GX 1일"
            endAdornment="원"
            minValue={0}
            maxValue={99999999}
            defaultValue={restData.base_policy.oneday_gx}
            onChangeCallback={(e) => {
              restData.base_policy.oneday_gx = e;
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default DailyItem;
