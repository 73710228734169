import CardFixed from "components/Card/CardFixed";
import { getCurrentDate } from "components/CommonLib/CommonLib";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";

const RegisterPaidDate = ({ regDate, setRegDate, isDisabled }) => {
  const curDate = getCurrentDate();

  return (
    <CardFixed
      midDom={
        <div style={{ display: "flex" }}>
          <Label labelText="등록일" fontSize="1.125rem" fontWeight="bold" />
          <DatePicker
            marginLeft="3rem"
            minWidth={"11rem"}
            defaultValue={regDate}
            maxDate={curDate}
            onChangeCallback={(e) => {
              setRegDate(e);
            }}
            disabled={isDisabled}
          />
        </div>
      }
      isLoading={false}
      rootStyle={{
        marginTop: "0.625rem",
        borderRadius: "5px",
        backgroundColor: "#fff",
        border: "solid 1px #dedede",
      }}
    />
  );
};
export default RegisterPaidDate;
