import * as ActionTypes from "./action_type";

///MEMBER SHIP
export const loadMembershipHistoryView = (member_id, page_num) => {
  return {
    type: ActionTypes.getMembershipHistoryViewAction,
    member_id: member_id,
    page_num: page_num,
  };
};

export const loadMembershipHistorySuccess = (data) => {
  return {
    type: ActionTypes.getMembershipHistoryViewSuccessAction,
    payload: data,
  };
};

export const loadMembershipHistoryViewFail = () => {
  return {
    type: ActionTypes.getMembershipHistoryViewFailAction,
    payload: [],
  };
};

export const loadMembershipHistoryViewReset = (data) => {
  return {
    type: ActionTypes.getMembershipHistoryViewResetAction,
    payload: data,
  };
};

///CLASS
export const loadClassHistoryView = (member_id, page_num) => {
  return {
    type: ActionTypes.getClassHistoryViewAction,
    member_id: member_id,
    page_num: page_num,
  };
};

export const loadClassHistorySuccess = (data) => {
  return {
    type: ActionTypes.getClassHistoryViewSuccessAction,
    payload: data,
  };
};

export const loadClassHistoryViewFail = () => {
  return {
    type: ActionTypes.getClassHistoryViewFailAction,
    payload: [],
  };
};

export const loadClassHistoryViewReset = (data) => {
  return {
    type: ActionTypes.getClassHistoryViewResetAction,
    payload: data,
  };
};

///PURCHASE
export const loadPurchaseHistoryView = (member_id, page_num) => {
  return {
    type: ActionTypes.getPurchaseHistoryViewAction,
    member_id: member_id,
    page_num: page_num,
  };
};

export const loadPurchaseHistorySuccess = (data) => {
  return {
    type: ActionTypes.getPurchaseHistoryViewSuccessAction,
    payload: data,
  };
};

export const loadPurchaseHistoryViewFail = (data) => {
  return {
    type: ActionTypes.getPurchaseHistoryViewFailAction,
    payload: [],
  };
};

export const loadPurchaseHistoryViewReset = (data) => {
  return {
    type: ActionTypes.getPurchaseHistoryViewResetAction,
    payload: data,
  };
};

///CS
export const loadCSHistoryView = (member_id, page_num) => {
  return {
    type: ActionTypes.getCSHistoryViewAction,
    member_id: member_id,
    page_num: page_num,
  };
};

export const loadCSHistorySuccess = (data) => {
  return {
    type: ActionTypes.getCSHistoryViewSuccessAction,
    payload: data,
  };
};

export const loadCSHistoryViewFail = (data) => {
  return {
    type: ActionTypes.getCSHistoryViewFailAction,
    payload: [],
  };
};

export const loadCSHistoryViewReset = (data) => {
  return {
    type: ActionTypes.getCSHistoryViewResetAction,
    payload: data,
  };
};
