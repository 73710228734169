import { getPermission, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LockerOverallDetail from "./Section2/LockerOverallDetail";
import LockerSetting from "./Section3/LockerSetting";

const LockerView = ({
  onClickOpenModal,
  selectedBranch,
  lockerAllInfo, //섹션2 구락리 state
  getBranchStatusInfo, //섹션1 갱신
  refreshView, //섹션2 구락리 갱신
}) => {
  const storesDispatch = useDispatch();
  const [showLockerSetting, setShowLockerSetting] = useState(false);

  //섹션2 콤보
  const [floorZoneCombo, setFloorZoneCombo] = useState({
    floor_name_list: [],
    floor_zone_list: [],
    zone_name_list: [[]],
    gender_name_list: [[]],
  });

  useEffect(() => {
    getPermission(storesDispatch, "LOCKER_MENU/LOCKER_SET").then((res) => {
      setShowLockerSetting(res);
    });
  }, []);

  //층구역 콤보 GET (섹션2)
  const getFloorZoneCombo = async (branchId, isInit) => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_BRANCH_SERVER + "/locker/floor_zone/combo",
        {
          params: {
            branch_id: branchId,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        const result = res.data.data;
        setFloorZoneCombo(res.data.data);
        if (isInit) {
          //구락리 갱신필요
          if (result.floor_zone_list.length !== 0) {
            const fId = result.floor_zone_list[0].floor_id;
            const zId = result.floor_zone_list[0].zone_list[0].zone_id;
            refreshView(branchId, fId, zId);
          }
        }
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  return (
    <div>
      {/* 락커 섹션2 */}
      <div style={{ display: "flex", margin: "0.625rem 0" }}>
        <LockerOverallDetail
          onClickOpenModal={onClickOpenModal}
          selectedBranch={selectedBranch}
          floorZoneCombo={floorZoneCombo}
          lockerAllInfo={lockerAllInfo}
          refreshView={refreshView}
          getFloorZoneCombo={getFloorZoneCombo}
        />
      </div>
      {/* 락커 섹션3 */}
      {showLockerSetting ? (
        <LockerSetting
          onClickOpenModal={onClickOpenModal}
          selectedBranch={selectedBranch}
          getBranchStatusInfo={getBranchStatusInfo}
          getFloorZoneCombo={getFloorZoneCombo}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default LockerView;
