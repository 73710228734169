// import { registerAction } from "./Module/actions";
import NumberTextField from "components/LabelInput/NumberTextField";
import SubTitle from "components/PageTitle/SubTitle";

const ClassProduct = ({ restData }) => {
  const textBoxWidth = "10rem";
  const textLabelWidth = "6rem";
  const labelMarginLeft = "2.625rem";
  return (
    <div style={{}}>
      <SubTitle titleText={"수업 상품 1회 정가 "} />
      <div style={{ marginLeft: "2rem" }}>
        <div
          style={{
            display: "flex",
            marginTop: "0.625rem",
          }}
        >
          <NumberTextField
            isNumber
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· OT"
            endAdornment="원"
            minValue={0}
            maxValue={99999999}
            defaultValue={restData.base_policy.ot}
            onChangeCallback={(e) => {
              restData.base_policy.ot = e;
            }}
          />

          <NumberTextField
            isNumber
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelMarginLeft={labelMarginLeft}
            labelText="· PT"
            endAdornment="원"
            minValue={0}
            maxValue={99999999}
            defaultValue={restData.base_policy.pt}
            onChangeCallback={(e) => {
              restData.base_policy.pt = e;
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default ClassProduct;
