import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { getNowServiceState } from "pages/NoticeBoard/model/board_row";
import StaffDMWrapper from "./StaffDMWrapper";

const StaffDMService = () => {
  const nowService = "전달사항";
  return (
    <div>
      <PageFrame
        children={
          <div>
            <PageTitle titleText="전달사항" />
            <StaffDMWrapper nowService={getNowServiceState(nowService)} />
          </div>
        }
      />
    </div>
  );
};
export default StaffDMService;
