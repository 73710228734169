import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import CardFixed from "components/Card/CardFixed";
import { tabBorder, tabStyle } from "components/CommonComp";
import { getPermission } from "components/CommonLib/CommonLib";
import TabPanel from "components/TabControl/TabPanel";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import BranchManagement from "./BranchManagement";
import HQManagement from "./HQManagement";
import PolicyManagement from "./PolicyManagement";
import SettingChangeHistory from "./SettingChangeHistory";

const HqDetailInfo = ({ onClickOpenModal, tabIndex, setTabIndex, hqBaseData, hqId }) => {
  const rootWidth = "75rem";
  const [showMainTabs, setShowMainTabs] = useState(false);
  const [showBranchTabs, setShowBranchTabs] = useState(false);

  const storesDispatch = useDispatch();
  useEffect(() => {
    getPermission(storesDispatch, "HQ_SET_MENU/HQ_MANAGE").then((res) => {
      setShowMainTabs(res);
    });
    getPermission(storesDispatch, "HQ_SET_MENU/BRANCH_MANAGE").then((res) => {
      setShowBranchTabs(res);
    });
  }, []);

  const onChangeTabIndex = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <CardFixed
      topDom={<div>본부 세부설정</div>}
      midDom={
        <div>
          <Box sx={{ bgcolor: "background.paper" }}>
            {showMainTabs && showBranchTabs ? (
              <Tabs
                value={tabIndex}
                onChange={onChangeTabIndex}
                textColor="inherit"
                sx={{ minHeight: "40px" }}
                TabIndicatorProps={{
                  style: { backgroundColor: "rgba(0, 0, 0, 0)" },
                }}
              >
                <Tab label="본부 관리" style={tabStyle(0, tabIndex)} />
                <Tab label="지점 관리" style={tabStyle(1, tabIndex)} />
                <Tab label="정책 관리" style={tabStyle(2, tabIndex)} />
                <Tab label="변경 이력" style={tabStyle(3, tabIndex)} />
              </Tabs>
            ) : showMainTabs && !showBranchTabs ? (
              <Tabs
                value={tabIndex}
                onChange={onChangeTabIndex}
                textColor="inherit"
                sx={{ minHeight: "40px" }}
                TabIndicatorProps={{
                  style: { backgroundColor: "rgba(0, 0, 0, 0)" },
                }}
              >
                <Tab label="본부 관리" style={tabStyle(0, tabIndex)} />
                <Tab label="정책 관리" style={tabStyle(1, tabIndex)} />
                <Tab label="변경 이력" style={tabStyle(2, tabIndex)} />
              </Tabs>
            ) : !showMainTabs && showBranchTabs ? (
              <Tabs
                value={tabIndex}
                onChange={onChangeTabIndex}
                textColor="inherit"
                sx={{ minHeight: "40px" }}
                TabIndicatorProps={{
                  style: { backgroundColor: "rgba(0, 0, 0, 0)" },
                }}
              >
                <Tab label="지점 관리" style={tabStyle(0, tabIndex)} />
                <Tab label="변경 이력" style={tabStyle(1, tabIndex)} />
              </Tabs>
            ) : (
              <></>
            )}
          </Box>

          {showMainTabs && showBranchTabs ? (
            <SwipeableViews
              index={tabIndex}
              onChangeIndex={onChangeTabIndex}
              style={tabBorder}
              animateTransitions={false}
            >
              <TabPanel value={tabIndex} index={0}>
                <HQManagement
                  onClickOpenModal={onClickOpenModal}
                  hqId={hqId}
                  hqBaseData={hqBaseData}
                />
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <BranchManagement onClickOpenModal={onClickOpenModal} hqBaseData={hqBaseData} />
              </TabPanel>
              <TabPanel value={tabIndex} index={2}>
                <PolicyManagement onClickOpenModal={onClickOpenModal} hqId={hqId} />
              </TabPanel>
              <TabPanel value={tabIndex} index={3}>
                <SettingChangeHistory hqId={hqId} />
              </TabPanel>
            </SwipeableViews>
          ) : showMainTabs && !showBranchTabs ? (
            <SwipeableViews
              index={tabIndex}
              onChangeIndex={onChangeTabIndex}
              style={tabBorder}
              animateTransitions={false}
            >
              <TabPanel value={tabIndex} index={0}>
                <HQManagement
                  onClickOpenModal={onClickOpenModal}
                  hqId={hqId}
                  hqBaseData={hqBaseData}
                />
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <PolicyManagement onClickOpenModal={onClickOpenModal} hqId={hqId} />
              </TabPanel>
              <TabPanel value={tabIndex} index={2}>
                <SettingChangeHistory hqId={hqId} />
              </TabPanel>
            </SwipeableViews>
          ) : !showMainTabs && showBranchTabs ? (
            <SwipeableViews
              index={tabIndex}
              onChangeIndex={onChangeTabIndex}
              style={tabBorder}
              animateTransitions={false}
            >
              <TabPanel value={tabIndex} index={0}>
                <BranchManagement onClickOpenModal={onClickOpenModal} hqBaseData={hqBaseData} />
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <SettingChangeHistory hqId={hqId} />
              </TabPanel>
            </SwipeableViews>
          ) : (
            <></>
          )}
          {/* </SwipeableViews> */}
        </div>
      }
      isLoading={false}
      rootStyle={{
        width: rootWidth,
        // height: "74.875rem",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};
export default HqDetailInfo;
