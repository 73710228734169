import { HttpInstance } from "lib/HttpLib";

export const getPermissionAuth = (param) => {
  return HttpInstance.get(process.env.REACT_APP_AUTH_SERVER + "/global/perm_auth", {
    // params: {
    //   // package_id: param.package_id === null ? "" : param.package_id,
    //   // branch_name: param.branch_name === null ? "" : param.branch_name,
    // },
  });
};

const get_api = {
  getPermissionAuth,
};

export default get_api;
