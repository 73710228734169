import { HttpInstance } from "lib/HttpLib";

export const searchPtClassHist = (param) => {
  return HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/class/pt_class_hist", {
    params: {
      search_param: param.search_param,
    },
  });
};
export const searchOtClassHist = (param) => {
  return HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/class/ot_class_hist", {
    params: {
      search_param: param.search_param,
    },
  });
};
const get_api = {
  searchPtClassHist,
  searchOtClassHist,
};

export default get_api;
