export const validation = (curContent, baseState, contentState) => {
  let res = "";
  if (!curContent) res = "선택된 양식이 없습니다.";
  else if (
    baseState.approval_draft_line.approver.length === 0 &&
    baseState.approval_draft_line.viewer.length === 0 &&
    baseState.approval_draft_line.receiver.length === 0
  )
    res = "결재선이 비어있습니다.";
  else if (!baseState.doc_title) res = "제목을 입력해 주세요.";
  else if (!baseState.writer.hq_info.hq_name) res = "본부를 선택해 주세요.";
  else if (!baseState.writer.branch_info.branch_name) res = "지점을 선택해 주세요.";

  if (curContent === "consult") {
    // 시행품의서
    if (contentState.item_list.length === 0) {
      res = "내용이 비어있습니다.";
    }
    for (let i = 0; i < contentState.item_list.length; i++) {
      if (
        !contentState.item_list[i].account ||
        !contentState.item_list[i].bank ||
        !contentState.item_list[i].bank_acc ||
        !contentState.item_list[i].bank_acc_name ||
        !contentState.item_list[i].breakdown ||
        !contentState.item_list[i].tax_invoice ||
        !contentState.item_list[i].price ||
        !contentState.item_list[i].total_price
      ) {
        res = "내용 중 입력되지 않은 항목이 있습니다.";
        break;
      }
    }
  } else if (curContent === "spendProof") {
    // 지출증빙서
    if (contentState.item_list.length === 0) {
      res = "내용이 비어있습니다.";
    }
    for (let i = 0; i < contentState.item_list.length; i++) {
      if (
        !contentState.item_list[i].account ||
        !contentState.item_list[i].breakdown ||
        !contentState.item_list[i].pay_date ||
        !contentState.item_list[i].pay_method ||
        !contentState.item_list[i].tax_invoice ||
        !contentState.item_list[i].price ||
        !contentState.item_list[i].total_price
      ) {
        res = "내용 중 입력되지 않은 항목이 있습니다.";
        break;
      }
    }
  } else if (curContent === "transferReq") {
    // 이체 요청
    if (contentState.item_list.length === 0) {
      res = "이체 요청 목록이 비어있습니다.";
    }
    for (let i = 0; i < contentState.item_list.length; i++) {
      if (
        !contentState.item_list[i].transfer_req_info.price ||
        !contentState.item_list[i].transfer_req_info.bank ||
        !contentState.item_list[i].transfer_req_info.bank_account ||
        !contentState.item_list[i].transfer_req_info.account_name
      ) {
        res = "이체 요청 목록 중 입력되지 않은 항목이 있습니다.";
        break;
      }
    }
  } else if (curContent === "dayOffReq") {
    // 휴무 신청
    if (contentState.staff_list.length === 0) {
      res = "휴무 신청 목록이 비어있습니다.";
    }
    for (let i = 0; i < contentState.staff_list.length; i++) {
      if (
        !contentState.staff_list[i].req_day_off ||
        !contentState.staff_list[i].req_day_off_from_date ||
        !contentState.staff_list[i].off_type
      ) {
        res = "휴무 신청 내용 중 입력되지 않은 항목이 있습니다.";
        break;
      }
    }
  } else if (curContent === "vaultCash") {
    // 시재금 입금
    if (contentState.item_list.length === 0) {
      res = "내용이 비어있습니다. 관리자에게 문의해 주시기 바랍니다.";
    }
    for (let i = 0; i < contentState.item_list.length; i++) {
      if (!contentState.item_list[i].price || !contentState.item_list[i].reg_date) {
        res = "시재금 입금 확인서 내용 중 입력되지 않은 항목이 있습니다.";
        break;
      }
    }
  }

  return res;
};
