import { put, call } from "redux-saga/effects";
// import get_api from "../../../../../lib/LockerSettings/GetApi";
import get_api from "../api/GetApi";
import { AccessCardAction } from "../actions/index";
import { globalStartLoading, globalFinishLoading } from "modules/actions/Loading/index";

export function* getSearchCardListData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getSearchCardList, param);

    if (data.data === null) {
      yield put(AccessCardAction.loadCardSearchSuccess(false));
    } else {
      yield put(AccessCardAction.loadCardSearchSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(AccessCardAction.loadCardSearchFail(error));
    yield put(globalFinishLoading());
  }
}

export function* getCardInfoData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getCardInfo, param);

    if (data.data === null) {
      yield put(AccessCardAction.loadCardDetailInfoSuccess(false));
    } else {
      yield put(AccessCardAction.loadCardDetailInfoSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(AccessCardAction.loadCardDetailInfoFail(error));
    yield put(globalFinishLoading());
  }
}

export function* getCardListData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getCardList, param);

    if (data.data === null) {
      yield put(AccessCardAction.loadCardListSuccessAction(false));
    } else {
      yield put(AccessCardAction.loadCardListSuccessAction(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(AccessCardAction.loadCardListFailAction(error));
    yield put(globalFinishLoading());
  }
}
