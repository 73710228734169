import { useQuery } from "@tanstack/react-query";
import { getPtManageList } from "apis/staff/class_manage_api";
import { generateObjectZeroId, simpleAlert } from "components/CommonLib/CommonLib";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ptSearchParamInit } from "../Module/reducers/pt_manage_search_param";
import { useClassManageStore } from "../modules/store/classManageState";
import { exportToExcel } from "./MakeExcel";
import PtSearchCard from "./PtSearchCard";
import PtSearchResult from "./PtSearchResult";

const PtListView = ({ onClickOpenModal, branchInfo }) => {
  const storesDispatch = useDispatch();
  const [searchParam, setSearchParam] = useState(_.cloneDeep(ptSearchParamInit));
  const [statusType, setStatusType] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [curPage, setCurPage] = useState();
  const [totalPage, setTotalPage] = useState(0);
  const [isMakeExcel, setIsMakeExcel] = useState(false);
  const [phone, setPhone] = useState({
    p1: "",
    p2: "",
    p3: "",
  });
  const STATUS_DATA = ["수강", "대기", "만료"];
  // 전역 상태 관리
  const { setClassManageLoading: setLoading } = useClassManageStore();
  // API 상태 관리
  const [isGetPtManageList, setIsGetPtManageList] = useState(false);
  const queryData = { page: curPage, searchParam: searchParam, isExcel: isMakeExcel };

  // [GET] PT 수업 관리 리스트
  const { data: ptManageList } = useQuery({
    queryKey: ["ptClassManage", queryData],
    queryFn: () => getPtManageList(queryData),
    enabled: isGetPtManageList,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setIsGetPtManageList(false);
      setLoading(false);
    },
  });

  // 페이지 진입 시 검색
  useEffect(() => {
    onClickSearch(1);
  }, []);

  const resetSearchParam = () => {
    setSearchParam(_.cloneDeep(ptSearchParamInit));
    setPhone({ p1: "", p2: "", p3: "" });
  };

  const searchValidation = () => {
    // 전화번호 중간 자리 미입력 시 alert 띄움
    if (searchParam.member_base.phone === "err") {
      simpleAlert(storesDispatch, "알림", "전화번호 검색 조건이 잘못되었습니다.");
      return false;
    }

    if (searchParam.staff_text_name || searchParam.pt_info.class_status) {
      if (searchParam.pt_info.is_assigned !== "배정") {
        simpleAlert(storesDispatch, "알림", "배정 상태를 선택해주세요.");
        return false;
      } else if (
        !searchParam.branch_info.branch_id ||
        searchParam.branch_info.branch_id === generateObjectZeroId()
      ) {
        simpleAlert(storesDispatch, "알림", "지점을 선택해주세요.");
        return false;
      }
    }
    // OT 상태 옵션 선택 하나도 안했으면 자동으로 전체 복구
    if (searchParam.pt_info.root_status.length === 0) {
      searchParam.pt_info.root_status = [...STATUS_DATA];
    }

    return true;
  };

  //검색 POST
  const onClickSearch = async (page, isExcelDownLoad) => {
    // validation
    if (!searchValidation()) return;

    setCurPage(page);
    setStatusType(searchParam.pt_info.is_assigned);
    setLoading(true);

    if (isExcelDownLoad) {
      setIsGetPtManageList(true);
      setIsMakeExcel(true);
      return;
    }
    setIsGetPtManageList(true);
  };

  //테이블 다시 갱신
  const refreshTable = () => {
    onClickSearch(1, false);
  };

  // API 받아온 데이터 세팅
  useEffect(() => {
    // PT 수업관리 데이터
    if (ptManageList) {
      if (isMakeExcel) {
        exportToExcel(ptManageList.data.data, ptManageList.data.try_at + "PT_목록");
      } else {
        setSearchResult(ptManageList.data.data);
        setTotalPage(ptManageList.data.total_page);
      }
    }
  }, [ptManageList]);

  //페이지네이션
  useEffect(() => {
    if (curPage) {
      onClickSearch(curPage, false);
    }
  }, [curPage]);

  return (
    <div>
      <PtSearchCard
        searchParam={searchParam}
        setSearchParam={setSearchParam}
        resetSearchParam={resetSearchParam}
        ptBranch={branchInfo}
        phone={phone}
        onClickSearch={onClickSearch}
        statusData={STATUS_DATA}
      />

      <SearchResultCard
        searchResult={searchResult}
        curPage={curPage}
        setCurPage={setCurPage}
        totalPage={totalPage}
        statusType={statusType}
        onClickOpenModal={onClickOpenModal}
        onClickSearch={onClickSearch}
        refreshTable={refreshTable}
      />
    </div>
  );
};
export default PtListView;

//
const SearchResultCard = ({
  searchResult,
  statusType,
  curPage,
  setCurPage,
  totalPage,
  onClickOpenModal,
  onClickSearch,
  refreshTable,
}) => {
  return (
    <PtSearchResult
      searchResult={searchResult}
      curPage={curPage}
      setCurPage={setCurPage}
      totalPage={totalPage}
      statusType={statusType}
      onClickOpenModal={onClickOpenModal}
      onClickSearch={onClickSearch}
      refreshTable={refreshTable}
    />
  );
};
