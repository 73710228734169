import {
  loadChangeItemView,
  loadChangeItemViewSuccess,
  loadChangeItemViewFail,
  loadChangeItemViewReset,
} from "./view_act";

import {
  newitemInfoPush,
  newitemInfoPop,
  newitemInfoClear,
  newitemInfoEdit,
} from "./newItem_action";

export const loadChangeItemViewStore = (member_id) => loadChangeItemView(member_id);

export const changeItemViewAction = {
  loadChangeItemViewSuccess,
  loadChangeItemViewFail,
  loadChangeItemViewReset,
  newitemInfoPush,
  newitemInfoPop,
  newitemInfoClear,
  newitemInfoEdit,
};
