import IconButton from "components/Button/IconButton";
import { contentTypeJson, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

//층 추가 모달
const LockerAddFloorModal = ({ modalParam, onModalDone }) => {
  //modalParam : selectedBranch
  const storesDispatch = useDispatch();

  const [floorList, setFloorList] = useState([]);
  const [floorName, setFloorName] = useState("");
  const [loading, setLoading] = useState(false);

  //층 GET
  const getBranchFloorInfo = async () => {
    try {
      setLoading(true);

      const res = await HttpInstance.get(
        process.env.REACT_APP_BRANCH_SERVER + "/locker/floor/list",
        {
          params: {
            branch_id: modalParam.selectedBranch.branch_id,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        const result = res.data.data;

        const floorArr = result.map((el) => {
          return {
            floor_id: el.floor_id,
            floor_name: el.floor_name,
          };
        });

        setFloorList(floorArr);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  //초기랜더링
  useEffect(() => {
    if (modalParam) {
      getBranchFloorInfo();
    }
  }, [modalParam]);

  //층추가 MSG
  const onAdddEvt = () => {
    if (!floorName) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="층 이름을 입력해주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText={`${floorName}을 추가하시겠습니까?`} />
            </>
          ),
          onBtnYes: () => {
            onClickToAddFloor();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  //층 추가POST
  const onClickToAddFloor = async () => {
    try {
      const formData = new FormData();
      formData.append("branch_info", JSON.stringify(modalParam.selectedBranch));
      formData.append("floor_name", JSON.stringify(floorName));

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_BRANCH_SERVER + "/locker/floor_set",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label labelText="추가되었습니다." />
              </>
            ),
            onBtnOk: () => {
              setFloorName(""); //텍필 비워주기
              getBranchFloorInfo(); //층리스트 갱신
              onModalDone(); //섹션2 콤보갱신
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  //층삭제 MSG
  const onDeleteEvt = (floor, floorId) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText={` ${floor}을 삭제하시겠습니까?`} />
          </>
        ),
        onBtnYes: () => {
          onRemoveFloor(floorId);
        },
        onBtnNo: () => {},
      }),
    );
  };

  //층 삭제 POST
  const onRemoveFloor = async (floor_id) => {
    try {
      const formData = new FormData();
      formData.append("branch_info", JSON.stringify(modalParam.selectedBranch));
      formData.append("floor_id", JSON.stringify(floor_id));

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_BRANCH_SERVER + "/locker/remove_floor",
        formData,
        contentTypeJson,
      );

      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label labelText="삭제되었습니다." />
              </>
            ),
            onBtnOk: () => {
              getBranchFloorInfo(); //층리스트 갱신
              onModalDone(); //섹션2 콤보갱신
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const floorTableData = new FloorSetTable(onDeleteEvt);

  return (
    <div>
      {loading && <Loading />}

      <div style={{ display: "flex" }}>
        <TextField
          labelWidth={"3.3rem"}
          labelText="층 이름"
          defaultValue={floorName}
          onBlur={(e) => {
            setFloorName(e.target.value);
          }}
        />

        <NormalBtn name="추가" theme="white" onClick={() => onAdddEvt()} />
      </div>

      <div style={{ marginTop: "0.5rem" }}>
        <CustomTable
          columns_head={floorTableData.columns_head}
          rest_call={floorTableData.get_data_from_rest}
          row_render={floorTableData.create_table}
          rest_data={floorList}
        />
      </div>
    </div>
  );
};
export default LockerAddFloorModal;

class FloorSetTable {
  table_title = "";
  onDeleteEvt = null;

  constructor(onDeleteEvt) {
    this.onDeleteEvt = onDeleteEvt;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.floor_name}</CustomTableContents>
        <CustomTableContents>
          <IconButton
            type="delete"
            onClick={() => {
              this.onDeleteEvt(data.floor_name, data.floor_id);
            }}
          />
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "층이름", width: "50%" },
    { title: "삭제", width: "50%" },
  ];

  get_data_from_rest = (pushed_data) => {
    return pushed_data;
  };
}
