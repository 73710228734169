import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { simpleAlert } from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import LockerSelectModal from "pages/ERP/LockerService/Modal/LockerSelectModal";
import { useRegisterStore } from "pages/ERP/MemberRegister/Register/modules/store/registerState";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { locker_info_state } from "../Module/reducers/locker_info";
import LockerListItem from "./LockerListItem";

const LockerListInfo = ({
  hqBranchPolicy,
  memberInputState,
  payInfoListAction,
  orderStartIdx,
  subsPayInfoListAction,
  isSubs,
  memberId,
  setLockerProratePrice,
  personalInfo,
  isAddPay,
  originPrice,
  lockerPriceOrigin,
  setLockerPrice,
}) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();
  const viewModelRef = useRef();
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });

  const [existLockerList, setExistLockerList] = useState([]);
  // 전역 상태 관리
  const { onChangeSubmitDocState } = useRegisterStore();

  useEffect(() => {
    // console.log(memberInputState);
    if (memberId !== "000000000000000000000000") {
      HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member/register/existing_locker", {
        params: {
          member_id: memberId,
          mbshp_id: memberInputState.membership_list.membership_id,
        },
      })
        .then((res) => {
          if (res.data.data.length > 0) {
            setExistLockerList(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        });
    }
  }, [memberId]);

  const onClickOpenLockerModal = (event, titleText, order, view_id) => {
    const modalInfo = {
      title: titleText,
      select: event,
      modalParam: {
        selectedLockerList: memberInputState.membership_list.locker_list,
        mode: "add",
        isReg: true,
        branch_id: hqBranchPolicy.selected_branch_id,
        member_info: personalInfo,
        view_id: view_id,
      },
    };

    onClickOpenLocker(event, modalInfo, onLockerSelectDone);
  };

  const onClickExistLocker = (viewId) => {
    // console.log(existLockerList);
    // console.log(memberInputState.membership_list.locker_list);

    setModalInfo({
      show: true,
      title: "기존락커 배정",
      Content: (
        <ExistLockerSelectModal
          modalParam={existLockerList}
          onModalDone={(num) => {
            const selectedIdx = existLockerList.findIndex((x) => x.locker_no);
            const idx = memberInputState.membership_list.locker_list.findIndex(
              (x) => x.view_id === viewId,
            );
            const locker = memberInputState.membership_list.locker_list[idx].locker_info;

            // locker.locker_idx = existLockerList[selectedIdx].locker_idx;
            locker.locker_no = existLockerList[selectedIdx].locker_no;
            locker.locker_total_idx = existLockerList[selectedIdx].locker_total_idx;
            locker.password = existLockerList[selectedIdx].password;
            locker.queue_id = existLockerList[selectedIdx].queue_id;
            locker.zone_id = existLockerList[selectedIdx].zone_id;
            locker.zone_name = existLockerList[selectedIdx].zone_name;

            forceUpdate();
            viewModelRef.current.setClose();
          }}
        />
      ),
      callback: null,
      Buttons: undefined,
    });
  };

  const onClickOpenLocker = (order, modalInfo, onModalDone) => {
    setModalInfo({
      show: true,
      title: modalInfo.title,
      Content: <LockerSelectModal modalParam={modalInfo.modalParam} onModalDone={onModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const onLockerSelectDone = (lockerInfo, zoneInfo, viewId) => {
    const idx = memberInputState.membership_list.locker_list.findIndex((x) => x.view_id === viewId);

    if (idx !== -1) {
      const curLocker = memberInputState.membership_list.locker_list[idx];
      curLocker.locker_info.locker_total_idx = lockerInfo.locker_total_idx;
      curLocker.locker_info.zone_id = zoneInfo.zone_id;
      curLocker.locker_info.zone_name = zoneInfo.zone_name;
      curLocker.locker_info.locker_no = lockerInfo.num;
      curLocker.locker_info.queue_id = lockerInfo.queue_id;
      // console.log(curLocker);
    } else {
      console.log("locker allocation error!");
      // const newLocker = { ...locker_info_state };
      // newLocker.locker_info.locker_idx = memberInputState.membership_list.locker_list.length;
      // newLocker.locker_info.locker_no = lockerInfo.num;
      // newLocker.locker_info.queue_id = lockerInfo.queue_id;
      // newLocker.locker_info.zone_id = zoneInfo.zone_id;
      // newLocker.locker_info.zone_name = zoneInfo.zone_name;
      // memberInputState.membership_list.locker_list.push(newLocker);
    }

    viewModelRef.current.setClose();
  };

  const getOptionData = (is_lock) => {
    if (
      memberInputState.tab_info.is_add_pay === true &&
      memberInputState.tab_info.is_new === false
    ) {
      // purchase_item_addpaid 뺌
      // if (is_lock) {
      return memberInputState.purchase_item_list.item_info.mbshp_option;
      // } else {
      //   return memberInputState.purchase_item_addpaid.item_info.mbshp_option;
      // }
    } else {
      return memberInputState.purchase_item_list.item_info.mbshp_option;
    }
  };

  const [, updateState] = useState(); // 강제 렌더링용
  const forceUpdate = useCallback(() => updateState({}), []);

  const btnLockerPush = () => {
    // getLockerTotalPrice();

    const lockerId = uuidv4();
    const currentItem = memberInputState.purchase_item_list.item_info;
    const baseLocker = _.cloneDeep(locker_info_state);
    baseLocker.view_id = lockerId;
    baseLocker.item_info.price = memberInputState.purchase_item_list.item_info.locker_price;
    baseLocker.item_info.subs_options.pre_price =
      memberInputState.purchase_item_list.item_info.subs_options.locker_pre_price;
    baseLocker.item_info.subs_options.subs_price =
      memberInputState.purchase_item_list.item_info.subs_options.locker_subs_price;

    // baseLocker.locker_info.locker_idx = memberInputState.membership_list.locker_list.length;

    // 구독형 금액 안맞으면 여기서...

    memberInputState.membership_list.locker.is_use = true;
    memberInputState.membership_list.locker_list.push(baseLocker);

    const idx = memberInputState.membership_list.locker_list.length - 1;
    const eventId = memberInputState.tab_info.title;
    const orderIdx = orderStartIdx * 10 + 4;

    if (setLockerProratePrice !== undefined) {
      setLockerProratePrice(idx);
    }

    // if (!dataEmpty(subsPayInfoListAction)) {
    if (isSubs) {
      const curLocker = memberInputState.membership_list.locker_list[idx];
      // 구독형 삼형제
      payInfoListAction(
        "push",
        lockerId + "(일할)", // eventId + "락커(일할) [" + idx + "]",
        {
          type: eventId + "락커(일할) (" + (idx + 1) + ")",
          // price: baseLocker.item_info.subs_options.prorate_price,
          price: curLocker.item_info.subs_options.prorate_price,
          dis: 0,
          pay_order: orderIdx + idx,
          tab_idx: memberInputState.tab_info.idx,
          item_type: "락커/구독형/일할금액/" + getOptionData(false) + "/없음",
        },
        orderIdx + idx,
        memberInputState.tab_info.idx,
      );

      payInfoListAction(
        "push",
        lockerId + "(선납)", // eventId + "락커(선납) [" + idx + "]",
        {
          type: eventId + "락커(선납) (" + (idx + 1) + ")",
          // price: baseLocker.item_info.subs_options.pre_price,
          price: curLocker.item_info.subs_options.pre_price,
          dis: 0,
          pay_order: orderIdx + idx,
          tab_idx: memberInputState.tab_info.idx,
          item_type: "락커/구독형/선납금/" + getOptionData(false) + "/없음",
        },
        orderIdx + idx,
        memberInputState.tab_info.idx,
      );

      subsPayInfoListAction(
        "push",
        lockerId,
        {
          type: "락커 (" + (idx + 1) + ")",
          // price: baseLocker.item_info.subs_options.subs_price,
          price: curLocker.item_info.subs_options.subs_price,
          dis: 0,
          pay_order: orderIdx + idx,
          tab_idx: memberInputState.tab_info.idx,
          item_type: "락커/구독형/월구독료/" + getOptionData(false) + "/없음",
        },
        orderIdx + idx,
        memberInputState.tab_info.idx,
      );
      //
    } else {
      // 완납형
      payInfoListAction(
        "push",
        lockerId, // eventId + "락커 [" + idx + "]",
        {
          type: eventId + "락커 (" + (idx + 1) + ")",
          price: baseLocker.item_info.price,
          dis: 0,
          pay_order: orderIdx + idx,
          tab_idx: memberInputState.tab_info.idx,
          item_type: "락커/완납형/금액/" + getOptionData(false) + "/없음",
        },
        orderIdx + idx,
        memberInputState.tab_info.idx,
      );
    }

    forceUpdate();
  };
  //

  const btnLockerPop = (viewId) => {
    const array = [...memberInputState.membership_list.locker_list];
    const idx = array.findIndex((x) => x.view_id === viewId);

    if (idx !== -1) {
      const curItem = array[idx];

      memberInputState.membership_list.locker_list.splice(idx, 1);

      if (isSubs) {
        payInfoListAction("pop", curItem.view_id + "(일할)");
        payInfoListAction("pop", curItem.view_id + "(선납)");
        subsPayInfoListAction("pop", curItem.view_id);
      } else {
        payInfoListAction("pop", curItem.view_id);
      }
    }

    if (memberInputState.membership_list.locker_list.length === 0) {
      memberInputState.membership_list.locker.is_use = false;
    }

    forceUpdate();
  };

  const checkerLock = () => {
    if (
      memberInputState.membership_list.locker_list.length > 1 ||
      memberInputState.membership_list.locker.is_lock
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
      <div style={{ display: "flex" }}>
        <CheckBox
          marginTop="0rem"
          //   inputRef={refStatus.pt_required}
          labelText="락커"
          defaultValue={memberInputState.membership_list.locker_list.length > 0 ? true : false}
          //   defaultValue={initData.pt_required}
          // disabled={checkerLock()}
          readonly
        />

        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType2]: true,
            [btnStyleClass.disabled]: checkerLock(),
          })}
          onClick={() => {
            !memberInputState.tab_info.is_new && onChangeSubmitDocState("서비스 신청서", true);
            btnLockerPush();
          }}
          disabled={checkerLock()}
        >
          추가
        </Button>
      </div>

      {memberInputState.membership_list.locker_list.map((item, idx) => (
        <LockerListItem
          key={idx + 1}
          ord={idx}
          lockerItemInfo={item}
          // onDisPriceChange={onDisPriceChange}
          btnLockerPop={btnLockerPop}
          onClickOpenLockerModal={onClickOpenLockerModal}
          onClickExistLocker={onClickExistLocker}
          payInfoListAction={payInfoListAction}
          tabInfoParam={memberInputState.tab_info}
          subsPayInfoListAction={subsPayInfoListAction}
          isSubs={isSubs}
          memberInputState={memberInputState}
          familyKey={"없음"}
          existLockerList={existLockerList}
          lockerLen={memberInputState.membership_list.locker_list.length}
          isAddPay={isAddPay}
          originPrice={originPrice}
          lockerPriceOrigin={lockerPriceOrigin}
          setLockerPrice={setLockerPrice}
        />
      ))}
    </div>
  );
};
export default LockerListInfo;

const ExistLockerSelectModal = ({ modalParam, onModalDone }) => {
  const [selectNum, setSelectNum] = useState();
  const lockerNumbers = modalParam.map((it, idx) => {
    return it.locker_no;
  });

  return (
    <div style={{ width: "17rem" }}>
      <Label labelText="배정할 기존 락커를 선택해주세요." marginTop="1rem" />
      <ComboBox
        marginTop="1.5rem"
        labelWidth="5.5rem"
        comboItemWidth="9rem"
        labelText="기존 락커번호"
        data={lockerNumbers}
        onChangeCallback={(e) => {
          setSelectNum(e.target.value);
        }}
        justifyContent="center"
      />

      <div
        style={{
          marginTop: "2rem",
          marginBottom: "0.5rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <NormalBtn name="확인" onClick={() => onModalDone(selectNum)} disabled={!selectNum} />
      </div>
    </div>
  );
};
