import _ from "lodash";
import { locker_info_state } from "pages/ERP/MemberCommonItems/Module/reducers/locker_info";
import { v4 as uuidv4 } from "uuid";

export class preItemInitializer {
  memberInputState = null;
  payInfoListAction = null;
  membershipIdx = 0;
  noReg = false;

  constructor(memberInputState, payInfoListAction, membershipIdx, noReg) {
    this.memberInputState = memberInputState;
    this.payInfoListAction = payInfoListAction;
    this.membershipIdx = membershipIdx;
    this.noReg = noReg;
  }

  // orderIdx 일단 빼놓기. (orderIdx == payOrder)
  onChangeCheckToPayInfo = (event, orderIdx, isRequred, stdPrice, disPrice, payOrder, isLocker) => {
    let act = isRequred ? "push" : "pop";
    // if (event === "등록비") {
    //   act = "discount";
    // }

    const tabIdx = this.memberInputState.tab_info.idx;
    const startIdx = event === "등록비" ? 0 : this.membershipIdx * 10;
    const mOpt = this.memberInputState.purchase_item_list.item_info.mbshp_option;

    let eventName = "";
    if (isLocker) {
      const lockerIdx = this.memberInputState.membership_list.locker_list.length - 1;
      eventName = this.memberInputState.tab_info.is_add_pay
        ? this.memberInputState.tab_info.title + "락커 (" + (lockerIdx + 1) + ")"
        : "락커 (" + (lockerIdx + 1) + ")";
    } else {
      eventName = this.memberInputState.tab_info.is_add_pay
        ? this.memberInputState.tab_info.title + event
        : event;
    }

    const itemType = isLocker
      ? "락커/완납형/금액/" + mOpt + "/없음"
      : event + "/완납형/금액/" + mOpt + "/없음";

    this.payInfoListAction(
      act,
      isLocker ? event : eventName,
      {
        type: eventName,
        price: stdPrice,
        dis: disPrice,
        pay_order: startIdx + payOrder,
        tab_idx: tabIdx,
        item_type: itemType,
      },
      startIdx + payOrder,
      this.membershipIdx,
    );
  };

  remappingState = () => {
    if (this.memberInputState.membership_list.item_name_idx > 0) {
      this.newMembershipInit();
      if (
        !this.memberInputState.membership_list.reg.is_hide &&
        !this.memberInputState.tab_info.is_add_pay &&
        !this.noReg
      ) {
        this.newRegistInit();
      }
    }

    this.newOthersInit();
  };

  newRegistInit = () => {
    const membershipItem = this.memberInputState.purchase_item_list.item_info;
    // itemIncludeRef.join.current.setValue(true);
    if (membershipItem.mbshp_option !== "일일권") {
      this.onChangeCheckToPayInfo(
        "등록비",
        1,
        true,
        membershipItem.reg_price,
        this.memberInputState.membership_list.reg.dis,
        0,
        false,
      );
      this.memberInputState.membership_list.reg.is_use = true;
    }
  };

  newMembershipInit = () => {
    let eventName = this.memberInputState.tab_info.is_add_pay
      ? this.memberInputState.tab_info.title + "회원권"
      : "회원권";
    let tabIdx = this.memberInputState.tab_info.idx;
    let startIdx = this.membershipIdx * 10;
    let mOpt = this.memberInputState.purchase_item_list.item_info.mbshp_option;

    this.payInfoListAction(
      "push",
      eventName,
      {
        type: eventName,
        price: this.memberInputState.purchase_item_list.item_info.mbshp_price,
        dis: this.memberInputState.membership_list.mbshp.dis,
        pay_order: startIdx + 1,
        tab_idx: tabIdx,
        item_type: "회원권/완납형/금액/" + mOpt + "/없음",
      },
      startIdx + 1,
      this.membershipIdx,
    );
  };

  newOthersInit = () => {
    const membershipItem = this.memberInputState.purchase_item_list.item_info;

    this.memberInputState.membership_list.cloth.is_use = membershipItem.is_combine_cloth;
    this.onChangeCheckToPayInfo(
      "운동복",
      2,
      membershipItem.is_combine_cloth,
      membershipItem.cloth_price,
      this.memberInputState.membership_list.cloth.dis,
      2,
      false,
    );

    this.memberInputState.membership_list.gx.is_use = membershipItem.is_combine_gx;
    this.onChangeCheckToPayInfo(
      "GX",
      3,
      membershipItem.is_combine_gx,
      membershipItem.gx_price,
      this.memberInputState.membership_list.gx.dis,
      3,
      false,
    );

    // 락커
    this.memberInputState.membership_list.locker.is_use = membershipItem.is_combine_locker;
    let disPrice = 0;
    const lockerId = uuidv4();
    if (membershipItem.is_combine_locker) {
      const baseLocker = _.cloneDeep(locker_info_state);
      baseLocker.view_id = lockerId;
      baseLocker.item_info.is_combine = true;
      baseLocker.item_info.price = membershipItem.locker_price;
      // console.log(membershipItem.subs_options);
      baseLocker.item_info.subs_options.pre_price = membershipItem.subs_options.locker_pre_price;
      baseLocker.item_info.subs_options.subs_price = membershipItem.subs_options.locker_subs_price;
      // baseLocker.locker_info.locker_idx = this.memberInputState.membership_list.locker_list.length;
      this.memberInputState.membership_list.locker_list.push(baseLocker);
      disPrice = this.memberInputState.membership_list.locker_list[0].item_info.dis;
    }

    this.onChangeCheckToPayInfo(
      lockerId,
      4,
      membershipItem.is_combine_locker,
      membershipItem.locker_price,
      disPrice,
      4,
      true,
    );
  };
}
