import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import ChangeBranchWrapper from "./ChangeBranchWrapper";
import { loadChangeBranchViewStore } from "./Module/actions";

const ChangeBranchService = () => {
  const storesDispatch = useDispatch();
  const { state } = useLocation();

  const viewState = useSelector((state) => state.member_service.change_branch_view_info);

  useEffect(() => {
    storesDispatch(loadChangeBranchViewStore(state.member_info.member_id));
  }, [state.dataState]);

  return (
    <PageFrame
      children={
        <div>
          <PageTitle titleText="지점변경" />
          <ChangeBranchWrapper
            memberBaseInfoState={viewState.stores.data.base_info}
            serviceViewState={viewState.stores.data}
            memberInfo={state}
          />
        </div>
      }
    />
  );
};
export default ChangeBranchService;
