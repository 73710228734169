import ComboBox from "components/LabelInput/ComboBox";
import ModalBtnModeGroup from "components/ModalBtnModeGroup/ModalBtnModeGroup";
const AddAccessModal = ({ modalParam, onModalDone }) => {
  return (
    <div>
      <ModalBtnModeGroup mode={"add"} />

      <ComboBox
        labelWidth={"4.638rem"}
        comboItemWidth={"12.75rem"}
        // inputRef={packageInfoRef}
        labelText="본부"
        data={["S본부"]}
        // defaultValue={restData.base_info.sales_status}
        // onChangeCallback={(e) => {
        //   restData.base_info.sales_status = e.target.value;
        // }}
      />

      <ComboBox
        labelWidth={"4.638rem"}
        comboItemWidth={"12.75rem"}
        // inputRef={packageInfoRef}
        labelText="지점"
        data={["S본부"]}
        // defaultValue={restData.base_info.sales_status}
        // onChangeCallback={(e) => {
        //   restData.base_info.sales_status = e.target.value;
        // }}
      />
    </div>
  );
};
export default AddAccessModal;
