import CardFixed from "components/Card/CardFixed";
import {
  generateObjectZeroId,
  getPermission,
  restEmpty,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import Loading from "components/Loading/Loading/Loading";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TeamRecordSearchCard from "./SearchCard/TeamRecordSearchCard";
import TeamRecordSearchResultCard from "./SearchCard/TeamRecordSearchResultCard";
import TeamSummary from "./SearchCard/TeamSummary";

import { useQuery } from "@tanstack/react-query";
import { getPtTeamSales } from "apis/staff/pt_team_performance_api";
import { useTeamRecordStore } from "./modules/store/teamRecordState";

const TeamRecordView = () => {
  const storesDispatch = useDispatch();

  // 전역 상태 관리
  const {
    teamRecordSearchParams: searchParams,
    setSearchParams,
    resetSearchParams,
  } = useTeamRecordStore();

  // 페이지 상태 관리
  const [branchList, setBranchList] = useState({ branch_list: [], branch_name_list: [] });
  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);
  const [searchResult, setSearchResult] = useState({
    search_result_total: [],
    week_result: [],
  });
  const [summaryInfo, setSummaryInfo] = useState({
    nameList: [],
    curGoalList: [],
    curSum: 0,
    curGoal: 0,
    curRate: 0,
  });

  // API 상태 관리
  const [isGetPtTeamSalesList, setIsGetPtTeamSalesList] = useState(false);
  const queryData = { searchParams: searchParams };

  //콤보 GET
  const getBranchCombo = async () => {
    const result = await getPermission(storesDispatch, "CLASS_MENU/CLASS_MANAGE", true, true);
    if (!restEmpty(result)) {
      setBranchList(result);
      // 첫 검색 조건
      if (searchParams.branch_info.branch_id === generateObjectZeroId()) {
        setSearchParams("branch_info", result.branch_list[0]);
      }
    }
  };

  // [GET] 팀 실적 가져오기
  const { isFetching, data: ptTeamSales } = useQuery({
    queryKey: ["ptTeamPerformance", queryData],
    queryFn: () => getPtTeamSales(queryData),
    enabled: isGetPtTeamSalesList,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setIsGetPtTeamSalesList(false);
    },
  });

  // 팀 실적 내역 가져오기
  const onClickSearch = async () => {
    setIsGetPtTeamSalesList(true);
  };

  // API 받아온 데이터 세팅
  useEffect(() => {
    // 팀 실적 내역
    if (ptTeamSales) {
      setSearchResult(ptTeamSales.data.data);

      const result = ptTeamSales.data.data.search_result_total;

      if (result.length !== 0) {
        const nameArr = result.map((el) => el.staff_info.staff_name);
        const curGoalArr = result.map((el) => el.weekly_summary.task_objective_info.current_sales);
        let curSum = 0;
        let curGoal = 0;
        let curRate = 0;

        for (let i = 0; i++; i < result.length) {
          curSum += result.weekly_summary.task_objective_info.current_sales;
          curGoal += result.weekly_summary.task_objective_info.goal_sales;
          curRate += result.weekly_summary.task_objective_info.goal_rate;
        }

        setSummaryInfo((cur) => {
          const obj = { ...cur };
          obj.curSum = curSum;
          obj.curGoal = curGoal;
          obj.nameList = nameArr;
          obj.curGoalList = curGoalArr;
          obj.curRate = Math.floor(curRate / result.length);
          return obj;
        });
      }
    }
  }, [ptTeamSales]);

  //초기 랜더링
  useEffect(() => {
    getBranchCombo();
  }, []);

  // 첫 페이지 진입 시 결과 띄우기
  useEffect(() => {
    if (isFirstEnterPage && searchParams.team_info.team_id !== generateObjectZeroId()) {
      onClickSearch();
      setIsFirstEnterPage(false);
    }
  }, [searchParams.team_info.team_id]);

  return (
    <div>
      <CardFixed
        midDom={
          <div>
            {isFetching && <Loading />}

            <TeamRecordSearchCard
              branchList={branchList}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              resetSearchParams={resetSearchParams}
              onClickSearch={onClickSearch}
            />
            {searchResult.week_result.length !== 0 &&
            searchResult.search_result_total.length !== 0 ? (
              <TeamSummary
                totalResultList={searchResult.search_result_total}
                summaryInfo={summaryInfo}
              />
            ) : (
              ""
            )}
          </div>
        }
        isLoading={false}
        rootStyle={{
          marginTop: "0.5rem",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />

      {searchResult.week_result.length !== 0 && searchResult.search_result_total.length !== 0 ? (
        <TeamRecordSearchResultCard
          weekResult={searchResult.week_result}
          summaryInfo={summaryInfo}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default TeamRecordView;
