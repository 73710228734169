import { SubLine } from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import Label from "components/LabelInput/Label";
import LockerListInfo from "pages/ERP/MemberCommonItems/LockerRegister/LockerListInfo";
import PriceDiscountInput from "pages/ERP/MemberRegister/Common/Etc/PriceDiscountInput";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

const SubscriptSideItem = forwardRef((props, ref) => {
  const {
    regDate,
    payInfoListAction,
    subsPayInfoListAction,
    membershipIdx,
    itemIncludeRef,
    memberInputState,
    onChangeSubsDis,
    hqBranchPolicy,
    memberId,
    setLockerProratePrice,
    personalInfo,
  } = props;

  const disItemRef = {
    reg: useRef(),
    cloth: useRef(),
    gx: useRef(),
    locker: [useRef(), useRef()],
  };

  useImperativeHandle(ref, () => ({
    updateProrate: () => {
      setClothProrate(memberInputState.membership_list.cloth.subs_options.prorate_price);
      setGxProrate(memberInputState.membership_list.gx.subs_options.prorate_price);
    },
  }));

  const [clothProrate, setClothProrate] = useState(
    memberInputState.membership_list.cloth.subs_options.prorate_price,
  );
  const [gxProrate, setGxProrate] = useState(
    memberInputState.membership_list.gx.subs_options.prorate_price,
  );

  const itemIdx = useRef();

  useEffect(() => {
    if (itemIdx.current && memberInputState.membership_list.item_name_idx !== itemIdx.current) {
      setRegDis(0);
      setClothSubsDis(0);
      setClothProrateDis(0);
      setClothPreDis(0);
      setGxSubsDis(0);
      setGxProrateDis(0);
      setGxPreDis(0);
    }
    itemIdx.current = memberInputState.membership_list.item_name_idx;
  }, [
    memberInputState.membership_list.mbshp.from_date,
    memberInputState.membership_list.item_name_idx,
  ]);

  const [regDis, setRegDis] = useState(memberInputState.membership_list.reg.dis);
  const [clothSubsDis, setClothSubsDis] = useState(
    memberInputState.membership_list.cloth.subs_options.subs_dis,
  );
  const [clothProrateDis, setClothProrateDis] = useState(
    memberInputState.membership_list.cloth.subs_options.prorate_dis,
  );
  const [clothPreDis, setClothPreDis] = useState(
    memberInputState.membership_list.cloth.subs_options.pre_dis,
  );
  const [gxSubsDis, setGxSubsDis] = useState(
    memberInputState.membership_list.gx.subs_options.subs_dis,
  );
  const [gxProrateDis, setGxProrateDis] = useState(
    memberInputState.membership_list.gx.subs_options.prorate_dis,
  );
  const [gxPreDis, setGxPreDis] = useState(
    memberInputState.membership_list.gx.subs_options.pre_dis,
  );

  const [, updateState] = useState(); // 강제 렌더링용
  const forceUpdate = useCallback(() => updateState({}), []);

  const getItemData = (is_lock) => {
    if (
      memberInputState.tab_info.is_add_pay === true &&
      memberInputState.tab_info.is_new === false
    ) {
      // addpaid 뺌
      // if (is_lock) {
      return memberInputState.purchase_item_list.item_info;
      // } else {
      //   return memberInputState.purchase_item_addpaid.item_info;
      // }
    } else {
      return memberInputState.purchase_item_list.item_info;
    }
  };

  const getOptionData = (is_lock) => {
    if (
      memberInputState.tab_info.is_add_pay === true &&
      memberInputState.tab_info.is_new === false
    ) {
      // addpaid 뺌
      // if (is_lock) {
      return memberInputState.purchase_item_list.item_info.mbshp_option;
      // } else {
      //   return memberInputState.purchase_item_addpaid.item_info.mbshp_option;
      // }
    } else {
      return memberInputState.purchase_item_list.item_info.mbshp_option;
    }
  };

  const inputPayInfoList = (
    event,
    orderIdx,
    contentObj,
    stdPrice,
    proratePrice,
    prePrice,
    payOrder,
    subs_dis,
    prorate_dis,
    pre_dis,
  ) => {
    const act = event.checked ? "push" : "pop";
    const eventId = memberInputState.tab_info.title + event.id;
    const tabIdx = memberInputState.tab_info.idx;
    const startIdx = membershipIdx * 10;

    // 아이템 넣을 때 초기화 부분
    payInfoListAction(
      act,
      eventId + "(일할)",
      {
        type: eventId + "(일할)",
        price: proratePrice,
        dis: prorate_dis,
        pay_order: startIdx + payOrder,
        tab_idx: tabIdx,
        // item_type: event.id,
        item_type: event.id + "/구독형/일할금액/" + getOptionData(false) + "/없음",
      },
      startIdx + payOrder,
      membershipIdx,
    );
    payInfoListAction(
      act,
      eventId + "(선납)",
      {
        type: eventId + "(선납)",
        price: prePrice,
        dis: pre_dis,
        pay_order: startIdx + payOrder,
        tab_idx: tabIdx,
        item_type: event.id + "/구독형/선납금/" + getOptionData(false) + "/없음",
      },
      startIdx + payOrder,
      membershipIdx,
    );
    subsPayInfoListAction(
      act,
      event.id + membershipIdx.toString(),
      {
        type: event.id,
        price: stdPrice,
        dis: subs_dis,
        pay_order: startIdx + payOrder,
        tab_idx: tabIdx,
        item_type: event.id + "/구독형/월구독료/" + getOptionData(false) + "/없음",
      },
      startIdx + payOrder,
      membershipIdx,
    );
  };

  const getPrePrice = (itemType, lockerIdx) => {
    let prePrice = 0;
    switch (itemType) {
      case "cloth":
        prePrice = memberInputState.membership_list.cloth.subs_options.pre_price;
        break;
      case "gx":
        prePrice = memberInputState.membership_list.gx.subs_options.pre_price;
        break;
      case "locker":
        prePrice = memberInputState.membership_list.locker_list[lockerIdx].subs_options.pre_price;
        break;
      default:
        break;
    }

    if (!memberInputState.tab_info.is_new) {
      const preFromDate = new Date(
        memberInputState.membership_list.mbshp.subs_options.contract_from_date,
      );
      const preToDate = new Date(
        memberInputState.membership_list.mbshp.subs_options.contract_from_date,
      );
      preToDate.setDate(1);
      preToDate.setMonth(
        preToDate.getMonth() + memberInputState.purchase_item_list.item_info.subs_options.pre_month,
      );
      preToDate.setDate(0);
      console.log(preFromDate);
      console.log(preToDate);

      const diff1 = preToDate.getTime() - preFromDate.getTime();
      const dayCntAll = diff1 / (1000 * 60 * 60 * 24);
      const pricePerDay = Math.floor(prePrice / dayCntAll);

      const curDate = new Date(regDate);

      if (curDate < preFromDate) {
        // 등록일이 선납기간 '이전'인 경우
      } else if (curDate > preFromDate && curDate <= preToDate) {
        // 등록일이 선납기간 '도중'인 경우
        const diff2 = curDate.getTime() - preFromDate.getTime();
        const remainDays = diff2 / (1000 * 60 * 60 * 24);
        console.log(memberInputState.membership_list.mbshp.subs_options.contract_from_date);
        console.log(preFromDate);
        console.log(curDate);

        console.log(remainDays);
        prePrice = remainDays * pricePerDay;
        console.log(prePrice);
      } else if (curDate > preToDate) {
        // 등록일이 선납기간 '이후'인 경우
        prePrice = 0;
        return 0;
      }

      // prePrice = prePrice / prorateDay;

      // if(){}
      // regDate
    }
    // memberInputState.membership_list.gx.is_lock
  };

  // getPrePrice("gx");

  return (
    <div style={{}}>
      <SubLine margin="1rem" />
      <Label labelText="부가상품" justifyContent="left" />
      <div
        style={{
          display: "flex",
          marginTop: "0.5rem",
          marginLeft: "1rem",
          // display: memberInputState.membership_list.reg.is_hide ? "none" : "flex",
        }}
      >
        <CheckBox
          marginTop="0rem"
          ref={itemIncludeRef.join}
          labelText="등록비"
          onChangeCallbackOrd={(event, orderIdx, contentObj) => {
            memberInputState.membership_list.reg.is_use = event.checked;
            inputPayInfoList(
              event,
              orderIdx,
              contentObj,
              memberInputState.purchase_item_list.item_info.subs_options.subs_reg,
              0,
              0,
              0,
            );
            disItemRef.reg.current.setDisabled(event.checked);
          }}
          orderIdx={1}
          defaultValue={true}
          readonly
        />
        <PriceDiscountInput
          temp
          ref={disItemRef.reg}
          baseInfo={{
            tabTitle: memberInputState.tab_info.title,
            tabIdx: memberInputState.tab_info.idx,
            orderStartIdx: membershipIdx,
            type: "등록비",
            typeKey: "등록비",
            orderIdx: 1,
            payOrder: 0,
            item_type:
              "등록비/구독형/선납금/" +
              getOptionData(memberInputState.membership_list.reg.is_lock) +
              "/없음",
          }}
          standardPrice={memberInputState.purchase_item_list.item_info.subs_options.subs_reg}
          payInfoListAction={payInfoListAction}
          onChangeCallback={(e) => {
            memberInputState.membership_list.reg.dis = e;
            setRegDis(e);
          }}
          paramUse={memberInputState.membership_list.reg.is_use}
          paramLock={memberInputState.membership_list.reg.is_lock}
          discountValue={regDis}
        />
      </div>

      <SubLine margin="0.5rem" marginLeft="2.5rem" marginRight="2.5rem" />
      <div
        style={{
          display: "flex",
          marginTop: "0.5rem",
          marginLeft: "1rem",
        }}
      >
        <CheckBox
          marginTop="0rem"
          ref={itemIncludeRef.cloth}
          labelText="운동복"
          onChangeCallbackOrd={(event, orderIdx, contentObj) => {
            memberInputState.membership_list.cloth.is_use = event.checked;
            inputPayInfoList(
              event,
              orderIdx,
              contentObj,
              memberInputState.membership_list.cloth.subs_options.subs_price,
              memberInputState.membership_list.cloth.subs_options.prorate_price,
              memberInputState.membership_list.cloth.subs_options.pre_price,
              2,
              memberInputState.membership_list.cloth.subs_options.subs_dis, // 구독료 할인
              memberInputState.membership_list.cloth.subs_options.prorate_dis, // 일할 할인
              memberInputState.membership_list.cloth.subs_options.pre_dis, // 선납 할인
            );
            disItemRef.cloth.current.setDisabled(event.checked);
            forceUpdate();
          }}
          orderIdx={2}
          disabled={
            memberInputState.membership_list.cloth.is_lock ||
            memberInputState.purchase_item_list.item_info.is_combine_cloth
          }
          defaultValue={memberInputState.membership_list.cloth.is_use}
        />
        <PriceDiscountInput
          ref={disItemRef.cloth}
          baseInfo={{
            tabTitle: memberInputState.tab_info.title,
            tabIdx: memberInputState.tab_info.idx,
            orderStartIdx: membershipIdx,
            type: "운동복",
            typeKey: "운동복" + membershipIdx.toString(),
            orderIdx: 2,
            payOrder: membershipIdx * 10 + 2,
            label: "월 구독료",
            item_type:
              "운동복/구독형/월구독료/" +
              getOptionData(memberInputState.membership_list.cloth.is_lock) +
              "/없음",
          }}
          standardPrice={
            memberInputState.purchase_item_list.item_info.subs_options.cloth_subs_price
          }
          payInfoListAction={subsPayInfoListAction}
          onChangeCallback={(e) => {
            memberInputState.membership_list.cloth.subs_options.subs_dis = e;
            setClothSubsDis(e);
            setClothProrate(onChangeSubsDis("운동복"));
          }}
          paramUse={memberInputState.membership_list.cloth.is_use}
          paramLock={memberInputState.membership_list.cloth.is_lock}
          discountValue={clothSubsDis}
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <PriceDiscountInput
          baseInfo={{
            tabTitle: memberInputState.tab_info.title,
            tabIdx: memberInputState.tab_info.idx,
            orderStartIdx: membershipIdx,
            type: memberInputState.tab_info.title + "운동복(일할)",
            typeKey: memberInputState.tab_info.title + "운동복(일할)",
            orderIdx: 2,
            payOrder: membershipIdx * 10 + 2,
            label: "일할금액",
            item_type:
              "운동복/구독형/일할금액/" +
              getOptionData(memberInputState.membership_list.cloth.is_lock) +
              "/없음",
          }}
          standardPrice={clothProrate}
          payInfoListAction={payInfoListAction}
          onChangeCallback={(e) => {
            memberInputState.membership_list.cloth.subs_options.prorate_dis = e;
            setClothProrateDis(e);
          }}
          paramUse={memberInputState.membership_list.cloth.is_use}
          paramLock={memberInputState.membership_list.cloth.is_lock}
          discountValue={clothProrateDis}
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <PriceDiscountInput
          baseInfo={{
            tabTitle: memberInputState.tab_info.title,
            tabIdx: memberInputState.tab_info.idx,
            orderStartIdx: membershipIdx,
            type: memberInputState.tab_info.title + "운동복(선납)",
            typeKey: memberInputState.tab_info.title + "운동복(선납)",
            orderIdx: 2,
            payOrder: membershipIdx * 10 + 2,
            label: "선납금",
            item_type:
              "운동복/구독형/선납금/" +
              getOptionData(memberInputState.membership_list.cloth.is_lock) +
              "/없음",
          }}
          // standardPrice={getItemData(memberInputState.membership_list.cloth.is_lock).subs_options.pre_price}
          standardPrice={memberInputState.membership_list.cloth.subs_options.pre_price}
          payInfoListAction={payInfoListAction}
          onChangeCallback={(e) => {
            memberInputState.membership_list.cloth.subs_options.pre_dis = e;
            setClothPreDis(e);
          }}
          paramUse={memberInputState.membership_list.cloth.is_use}
          paramLock={memberInputState.membership_list.cloth.is_lock}
          discountValue={clothPreDis}
        />
      </div>

      <SubLine margin="0.5rem" marginLeft="2.5rem" marginRight="2.5rem" />
      <div
        style={{
          display: "flex",
          marginTop: "0.5rem",
          marginLeft: "1rem",
        }}
      >
        <CheckBox
          marginTop="0rem"
          ref={itemIncludeRef.gx}
          labelText="GX"
          onChangeCallbackOrd={(event, orderIdx, contentObj) => {
            memberInputState.membership_list.gx.is_use = event.checked;
            inputPayInfoList(
              event,
              orderIdx,
              contentObj,
              memberInputState.membership_list.gx.subs_options.subs_price,
              memberInputState.membership_list.gx.subs_options.prorate_price,
              memberInputState.membership_list.gx.subs_options.pre_price,
              3,
              memberInputState.membership_list.gx.subs_options.subs_dis, // 구독료 할인
              memberInputState.membership_list.gx.subs_options.prorate_dis, // 일할 할인
              memberInputState.membership_list.gx.subs_options.pre_dis, // 선납 할인
            );
            disItemRef.gx.current.setDisabled(event.checked);
            forceUpdate();
          }}
          orderIdx={3}
          disabled={
            memberInputState.membership_list.gx.is_lock ||
            memberInputState.purchase_item_list.item_info.is_combine_gx
          }
          defaultValue={memberInputState.membership_list.gx.is_use}
        />
        <PriceDiscountInput
          ref={disItemRef.gx}
          baseInfo={{
            tabTitle: memberInputState.tab_info.title,
            tabIdx: memberInputState.tab_info.idx,
            orderStartIdx: membershipIdx,
            type: "GX",
            typeKey: "GX" + membershipIdx.toString(),
            orderIdx: 3,
            payOrder: membershipIdx * 10 + 3,
            label: "월 구독료",
            item_type:
              "GX/구독형/월구독료/" +
              getOptionData(memberInputState.membership_list.gx.is_lock) +
              "/없음",
          }}
          standardPrice={memberInputState.purchase_item_list.item_info.subs_options.gx_subs_price}
          payInfoListAction={subsPayInfoListAction}
          onChangeCallback={(e) => {
            memberInputState.membership_list.gx.subs_options.subs_dis = e;
            setGxSubsDis(e);
            setGxProrate(onChangeSubsDis("GX"));
          }}
          paramUse={memberInputState.membership_list.gx.is_use}
          paramLock={memberInputState.membership_list.gx.is_lock}
          discountValue={gxSubsDis}
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <PriceDiscountInput
          baseInfo={{
            tabTitle: memberInputState.tab_info.title,
            tabIdx: memberInputState.tab_info.idx,
            orderStartIdx: membershipIdx,
            type: memberInputState.tab_info.title + "GX(일할)",
            typeKey: memberInputState.tab_info.title + "GX(일할)",
            orderIdx: 3,
            payOrder: membershipIdx * 10 + 3,
            label: "일할금액",
            item_type:
              "GX/구독형/일할금액/" +
              getOptionData(memberInputState.membership_list.gx.is_lock) +
              "/없음",
          }}
          standardPrice={gxProrate}
          payInfoListAction={payInfoListAction}
          onChangeCallback={(e) => {
            memberInputState.membership_list.gx.subs_options.prorate_dis = e;
            setGxProrateDis(e);
          }}
          paramUse={memberInputState.membership_list.gx.is_use}
          paramLock={memberInputState.membership_list.gx.is_lock}
          discountValue={gxProrateDis}
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <PriceDiscountInput
          baseInfo={{
            tabTitle: memberInputState.tab_info.title,
            tabIdx: memberInputState.tab_info.idx,
            orderStartIdx: membershipIdx,
            type: memberInputState.tab_info.title + "GX(선납)",
            typeKey: memberInputState.tab_info.title + "GX(선납)",
            orderIdx: 3,
            payOrder: membershipIdx * 10 + 3,
            label: "선납금",
            item_type:
              "GX/구독형/선납금/" +
              getOptionData(memberInputState.membership_list.gx.is_lock) +
              "/없음",
          }}
          standardPrice={memberInputState.membership_list.gx.subs_options.pre_price}
          payInfoListAction={payInfoListAction}
          onChangeCallback={(e) => {
            memberInputState.membership_list.gx.subs_options.pre_dis = e;
            setGxPreDis(e);
          }}
          paramUse={memberInputState.membership_list.gx.is_use}
          paramLock={memberInputState.membership_list.gx.is_lock}
          discountValue={gxPreDis}
        />
      </div>
      <SubLine margin="0.5rem" marginLeft="2.5rem" marginRight="2.5rem" />

      <div style={{ marginLeft: "1rem" }}>
        <LockerListInfo
          hqBranchPolicy={hqBranchPolicy}
          memberInputState={memberInputState}
          payInfoListAction={payInfoListAction}
          subsPayInfoListAction={subsPayInfoListAction}
          memberId={memberId}
          orderStartIdx={membershipIdx}
          isSubs={true}
          setLockerProratePrice={setLockerProratePrice}
          personalInfo={personalInfo}
        />
      </div>
    </div>
  );
});
export default SubscriptSideItem;
