import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import ConverToMemberModal from "pages/ERP/MemberView/Modal/ConverToMemberModal";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";

const AssigneeTable = ({
  onModalDone,
  onClickSearch,
  searchResult,
  assigner,
  curPage,
  setCurPage,
  totalPage,
}) => {
  const storesDispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [selectedMember, setSelectedMember] = useState({
    data: {},
    idx: "",
  });
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });
  const viewModelRef = useRef();

  const onRowSelect = (data, idx) => {
    setSelectedMember((cur) => {
      return { ...cur, data, idx };
    });
  };

  const searchResultList = new searchResultTable(onRowSelect, selectedMember);

  const popRegisterNewMemberModal = async () => {
    let memberPersonalInfo = null;
    setLoading(true);
    await HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member/get_member_info", {
      params: {
        member_id: selectedMember.data.member_info.member_id,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          memberPersonalInfo = res.data.data.base_info.personal_info;
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      })
      .finally(() => {
        setLoading(false);
      });

    const param = {
      isAssign: true,
      // assigner,
      member_id: selectedMember.data.member_info.member_id,
      member_name: memberPersonalInfo.member_name,
      personal_info: memberPersonalInfo,
    };

    setModalInfo({
      show: true,
      title: "양수자 회원 전환",
      Content: (
        <ConverToMemberModal
          modalParam={param}
          onModalDone={() => {
            onConverModalDone();
          }}
        />
      ),
      callback: null,
      Buttons: undefined,
    });
  };

  const onConverModalDone = () => {
    onClickSearch(curPage);
    viewModelRef.current.setClose();
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="회원 전환이 완료되었습니다." />
            <Label labelText="해당 회원을 선택하여 양도를 진행해주세요." />
          </>
        ),
        onBtnOk: () => {},
      }),
    );

    setSelectedMember({
      data: {},
      idx: "",
    });
  };

  const checkAssignAvailable = async () => {
    if (selectedMember.data.member_type === "고객") {
      return true;
    }
    // 선택된 멤버가 없을 때 false 반환
    if (!selectedMember.data.branch_info) {
      return false;
    }

    let resValue = false;
    setLoading(true);

    await HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member/check_assign_branch", {
      params: {
        src_branch_id: assigner.branch_info.branch_id,
        dst_branch_id: selectedMember.data.branch_info.branch_id,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          if (res.data.data) {
            // 양도/지점변경 가능 지점
            resValue = true;
          } else {
            // 양도/지점변경 불가 지점
            resValue = false;
          }
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      })
      .finally(() => {
        setLoading(false);
      });

    return resValue;
  };

  const onAssign = async () => {
    const canAssign = await checkAssignAvailable();
    if (_.isEmpty(selectedMember.data)) {
      simpleAlert(storesDispatch, "알림", "양수 회원을 선택해주세요.");
    } else if (!canAssign) {
      simpleAlert(storesDispatch, "알림", "양도가 불가한 지점입니다.");
    } else if (assigner.member_info.member_id === selectedMember.data.member_info.member_id) {
      simpleAlert(storesDispatch, "알림", "회원 본인은 선택할 수 없습니다.");
    } else if (selectedMember.data.member_type === "고객") {
      popRegisterNewMemberModal();
    } else {
      const data = {
        assigner,
        assignee: selectedMember.data,
      };
      onModalDone(data);
    }
  };

  return (
    <div>
      {loading && <Loading />}
      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />

      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
      />
      <CustomPagination curPage={curPage} doSearch={onClickSearch} totalCnt={totalPage} />

      <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
        <NormalBtn name="다음" onClick={() => onAssign()} />
      </div>
    </div>
  );
};
export default AssigneeTable;

class searchResultTable {
  table_title = "";
  onRowSelect = null;
  selectedMember = null;

  constructor(onRowSelect, selectedMember) {
    this.onRowSelect = onRowSelect;
    this.selectedMember = selectedMember;
  }

  onClickEvent = (data, idx) => {
    this.onRowSelect(data, idx);
  };

  create_table = (data, idx) => {
    return (
      <CustomRow
        style={{
          backgroundColor: this.selectedMember.idx === idx ? "#ebf1ff" : "",
        }}
        key={idx}
        onClick={() => {
          this.onClickEvent(data, idx);
        }}
      >
        <CustomTableContents
          style={{ backgroundColor: this.selectedMember.idx === idx ? "#ebf1ff" : "" }}
        >
          {data.member_type}
        </CustomTableContents>
        <CustomTableContents
          style={{ backgroundColor: this.selectedMember.idx === idx ? "#ebf1ff" : "" }}
        >
          {data.branch_info.branch_name}
        </CustomTableContents>
        <CustomTableContents
          style={{ backgroundColor: this.selectedMember.idx === idx ? "#ebf1ff" : "" }}
        >
          {data.member_info.member_no}
        </CustomTableContents>

        <CustomTableContents
          style={{ backgroundColor: this.selectedMember.idx === idx ? "#ebf1ff" : "" }}
        >
          {data.member_info.member_name}
        </CustomTableContents>
        <CustomTableContents
          style={{ backgroundColor: this.selectedMember.idx === idx ? "#ebf1ff" : "" }}
        >
          {data.base_info.phone}
        </CustomTableContents>
        <CustomTableContentsEND
          style={{ backgroundColor: this.selectedMember.idx === idx ? "#ebf1ff" : "" }}
        >
          {data.access.card_no ? data.access.card_no : ""}
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "회원구분", width: "10%" },
    { title: "지점", width: "20%" },
    { title: "회원번호", width: "16%" },
    { title: "회원명", width: "18%" },
    { title: "연락처", width: "20%" },
    { title: "카드번호", width: "15%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
