import * as ActionTypes from "./action_type";

///STAFF VIEW
export const loadApprovalInfoView = (approval_id) => {
  return {
    type: ActionTypes.approvalInfoViewAction,
    approval_id: approval_id,
  };
};

export const loadApprovalInfoViewSuccess = (data) => {
  return {
    type: ActionTypes.approvalInfoViewSuccessAction,
    payload: data,
  };
};

export const loadApprovalInfoViewFail = (data) => {
  return {
    type: ActionTypes.approvalInfoViewFailAction,
    payload: [],
  };
};

export const loadApprovalInfoViewReset = (data) => {
  return {
    type: ActionTypes.approvalInfoViewResetAction,
    payload: data,
  };
};
