import { useEffect, useRef, useState } from "react";

import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalModalOff } from "modules/actions/Modal";
import { useDispatch } from "react-redux";
import { loadMemberViewStore } from "../Module/actions";
import ConnectFamilyAlertModal from "./ConnectFamilyAlertModal";

const FamilyConnectModal = ({ modalParam, onModalDone }) => {
  const [familyList, setFamiyList] = useState([]);
  const storesDispatch = useDispatch();
  const viewModelRef = useRef();
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });

  const getFamilyList = async () => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_USER_SERVER + "/member/family/list",
        {
          params: {
            member_id: modalParam.memberId,
            is_approved: true,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setFamiyList(res.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  //초기 랜더링
  useEffect(() => {
    getFamilyList();
  }, [modalParam]);

  const onAlertConnectFamilyModal = (title, param, localModalDone) => {
    setModalInfo({
      show: true,
      title: title,
      Content: <ConnectFamilyAlertModal modalParam={param} onModalDone={localModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };
  const onConnectModalDone = () => {
    storesDispatch(loadMemberViewStore(modalParam.memberId)); //회원페이지 갱신
    storesDispatch(globalModalOff()); //모달닫기
  };

  const noBtnEvt = () => {
    viewModelRef.current.setClose();
  };

  const rowClickEvt = (name, data) => {
    //회원권 상태에 따라

    onAlertConnectFamilyModal(
      "가족 연결",
      {
        type: "아직없음",
        name,
        noBtnEvt,
        data,
        memberId: modalParam.memberId,
        membershipId: modalParam.membershipId,
        itemName: modalParam.itemName,
      },
      onConnectModalDone,
    );
  };
  const familyDocumentList = new familyDocumentTable(rowClickEvt);
  return (
    <div
      style={{
        width: "37.5rem",
      }}
    >
      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
      <SubTitle titleText={"가족 연결"} />
      <CustomTable
        columns_head={familyDocumentList.columns_head}
        table_title={familyDocumentList.table_title}
        rest_call={familyDocumentList.get_data_from_rest}
        row_render={familyDocumentList.create_table}
        rest_data={familyList}
      />
    </div>
  );
};
export default FamilyConnectModal;

class familyDocumentTable {
  table_title = "";
  rowClickEvt = null;

  constructor(rowClickEvt) {
    this.rowClickEvt = rowClickEvt;
  }

  create_table = (data) => {
    return (
      <CustomRow
        hover
        onClick={() => {
          this.rowClickEvt(data.personal_info.member_name, data);
        }}
      >
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>없음</CustomTableContents>
        <CustomTableContents>{data.personal_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.personal_info.member_name}</CustomTableContents>
        <CustomTableContentsEND align="center">없음</CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "등록일", width: "20%" },
    { title: "구분", width: "15%" },
    { title: "회원번호", width: "25%" },
    { title: "이름", width: "25%" },
    { title: "회원권 상태", width: "25%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
