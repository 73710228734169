import { useQuery } from "@tanstack/react-query";
import { getTMHist } from "apis/member/tm_hist_api";
import CardFixed from "components/Card/CardFixed";
import {
  checkDateOrder,
  generateObjectZeroId,
  getPermission,
  restEmpty,
  simpleAlert,
  SubLine,
} from "components/CommonLib/CommonLib";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import _ from "lodash";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import { branch_search_init_state } from "pages/CommonModal/Search/Module/reducers/hq_branch_search_init_state";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import MsgPreviewModal from "../Modal/MsgPreviewModal";
import { useTMHistStore } from "./modules/store/tmHistState";
import SearchCondition from "./SearchCondition";
import TMHistoryResultTable from "./TMHistoryResultTable";

const TMHistoryView = () => {
  const storesDispatch = useDispatch();

  // 전역 상태 관리
  const {
    tmHistSearchParams: searchParams,
    setSearchParams,
    tmHistPage: page,
    setPage,
    resetSearchParams,
  } = useTMHistStore();

  // 페이지 상태 관리
  const [branchList, setBranchList] = useState(_.cloneDeep(branch_search_init_state));
  const [searchResult, setSearchResult] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [resetFlag, setResetFlag] = useState(false);

  // API 상태 관리
  const [isGetTMHist, setIsGetTMHist] = useState(false);
  const queryData = { page: page, searchParams: searchParams };

  // [GET] TM 이력 목록 가져오기
  const { isFetching, data: tmHistData } = useQuery({
    queryKey: ["tmHist", queryData],
    queryFn: () => getTMHist(queryData),
    enabled: isGetTMHist,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setIsGetTMHist(false);
    },
  });

  // TM 이력 지점 권한 Combo 가져오기
  const getBranchCombo = async () => {
    const result = await getPermission(storesDispatch, "CS_MENU/CONSULT", true, true);
    setBranchList(result);
    if (result.branch_list.length > 0) {
      if (searchParams.branch_info.branch_id === generateObjectZeroId()) {
        setSearchParams("branch_info", result.branch_list[0]);
      }
    }
  };

  // TM 이력 목록 검색
  const onClickSearch = async (page) => {
    if (searchParams.phone === "err") {
      simpleAlert(storesDispatch, "알림", "전화번호 검색 조건이 잘못되었습니다.");
      return;
    }
    // 날짜 시작일과 종료일 체크
    if (checkDateOrder(searchParams.tm_from_date, searchParams.tm_to_date)) {
      setPage(page);
      setIsGetTMHist(true);
    } else {
      setSearchParams("tm_from_date", "");
      setSearchParams("tm_to_date", "");
    }
  };

  const onModalDone = (res) => {
    console.log(res);
    storesDispatch(globalModalOff());
  };

  const openModal = (order, param) => {
    let Modal;
    if (order === "show") {
      Modal = <MsgPreviewModal modalParam={param} onModalDone={onModalDone} />;
    }
    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,
        Content: Modal,
      }),
    );
  };

  // API 받아온 데이터 세팅
  useEffect(() => {
    // TM 이력 리스트
    if (tmHistData && !restEmpty(tmHistData.data.data)) {
      setSearchResult(tmHistData.data.data);
      setTotalPage(tmHistData.data.total_page);
    }
  }, [tmHistData]);

  // 첫 페이지 진입 시 검색 결과를 가져오기 및 지점 권한 GET
  useEffect(() => {
    setIsGetTMHist(true);
    getBranchCombo();
  }, []);

  return (
    <CardFixed
      midDom={
        <div>
          {isFetching && <Loading />}

          <div>
            <SearchCondition
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              branchList={branchList}
              onClickSearch={onClickSearch}
              resetFlag={resetFlag}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "0.75rem",
                gap: "0.3rem",
              }}
            >
              <NormalBtn
                name="초기화"
                theme="white"
                width="6.25rem"
                onClick={() => {
                  resetSearchParams();
                  setResetFlag(!resetFlag);
                }}
              />
              <NormalBtn
                name="검색"
                width="6.25rem"
                onClick={() => {
                  onClickSearch(1);
                }}
              />
            </div>
          </div>
          <SubLine />

          <div style={{ marginTop: "1rem" }}>
            <TMHistoryResultTable
              openModal={openModal}
              searchResult={searchResult}
              curPage={page}
              totalPage={totalPage}
              onClickSearch={onClickSearch}
            />
          </div>
        </div>
      }
      isLoading={false}
      rootStyle={{}}
    />
  );
};
export default TMHistoryView;
