import * as ActionTypes from "./action_type";

///MEMBER VIEW
export const loadRefundView = (member_id) => {
  return {
    type: ActionTypes.getRefundViewAction,
    member_id: member_id,
  };
};

export const loadRefundViewSuccess = (data) => {
  return {
    type: ActionTypes.getRefundViewSuccessAction,
    payload: data,
  };
};

export const loadRefundViewFail = (data) => {
  return {
    type: ActionTypes.getRefundViewFailAction,
    payload: [],
  };
};

export const loadRefundViewReset = (data) => {
  return {
    type: ActionTypes.getRefundViewResetAction,
    payload: data,
  };
};
