import {
  loadAccessLog,
  loadAccessLogSuccess,
  loadAccessLogFail,
  loadAccessLogReset,
  loadDoorLogSearch,
  loadDoorLogSearchSuccess,
  loadDoorLogSearchFail,
  loadDoorLogSearchReset,
} from "./view_act";

export const loadAccessLogStore = (search_param) => loadAccessLog(search_param);
export const loadDoorLogSearchStore = (search_param) => loadDoorLogSearch(search_param);

export const AccessLogSearchAction = {
  loadAccessLog,
  loadAccessLogSuccess,
  loadAccessLogFail,
  loadAccessLogReset,

  loadDoorLogSearch,
  loadDoorLogSearchSuccess,
  loadDoorLogSearchFail,
  loadDoorLogSearchReset,
};
