import {
  loadPurchaseDetailModal,
  loadPurchaseDetailModalSuccess,
  loadPurchaseDetailModalFail,
  loadPurchaseDetailModalReset,
} from "./purchase_act";

import {
  loadFamilyModal,
  loadFamilyModalSuccess,
  loadFamilyModalFail,
  loadFamilyModalReset,
  loadFriendModal,
  loadFriendModalSuccess,
  loadFriendModalFail,
  loadFriendModalReset,
} from "./family_friend_act";

import {
  loadBranchLinkHistModal,
  loadBranchLinkHistModalSuccess,
  loadBranchLinkHistModalFail,
  loadBranchLinkHistModalReset,
  loadDocumentsModal,
  loadDocumentsModalSuccess,
  loadDocumentsModalFail,
  loadDocumentsModalReset,
} from "./etc_act";

export const loadDocumentsModalStore = (member_id) => loadDocumentsModal(member_id);
export const loadBranchLinkHistModalStore = (member_id) => loadBranchLinkHistModal(member_id);

export const loadFamilyModalStore = (member_id) => loadFamilyModal(member_id);
export const loadFriendModalStore = (member_id) => loadFriendModal(member_id);

export const loadPurchaseDetailStore = (member_id, purchase_code) =>
  loadPurchaseDetailModal(member_id, purchase_code);

export const memberViewActionDepth2 = {
  loadPurchaseDetailModalSuccess,
  loadPurchaseDetailModalFail,
  loadPurchaseDetailModalReset,

  loadDocumentsModalSuccess,
  loadDocumentsModalFail,
  loadDocumentsModalReset,

  loadBranchLinkHistModalSuccess,
  loadBranchLinkHistModalFail,
  loadBranchLinkHistModalReset,

  loadFriendModalSuccess,
  loadFriendModalFail,
  loadFriendModalReset,

  loadFamilyModalSuccess,
  loadFamilyModalFail,
  loadFamilyModalReset,
};
