import { contentTypeJson } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

// ==================== GET ====================
// [GET] 장기연기 리스트 검색
export async function getLongPostponeList(queryData) {
  const { page, isExcel, searchParams } = queryData;
  const formData = new FormData();

  // formData.append("is_excel", isExcel ? isExcel : false);
  formData.append("page_num", page);
  formData.append("search_param", JSON.stringify(searchParams));

  return await HttpInstance.post(
    process.env.REACT_APP_USER_SERVER + "/member/search/postpone",
    formData,
    contentTypeJson,
  );
}
