import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextFieldComp from "@mui/material/TextField";

const TextArea = (props) => {
  const [value, setValue] = useState(props.defaultValue);

  useEffect(() => {
    if (props.defaultValue !== undefined) {
      setValue(props.defaultValue);
    }
  }, [props.defaultValue]);

  // useEffect(() => {
  // }, [value]);

  const cOnChange = (e) => {
    setValue(e.target.value);
    if (props.onChangeCallback !== undefined) {
      props.onChangeCallback(e);
    }
  };

  const hStyle = {
    display: "flex",
    alignItems: "center",
    width: props.fullWidth ? "100%" : null,
    justifyContent: props.justifyContent,
    marginTop: props.marginTop,
    marginRight: props.marginRight,
    marginLeft: props.marginLeft,
  };

  const labelStyle = {
    width: props.labelWidth,
    height: "2.25rem",
    marginRight: props.labelMarginRight === undefined ? "0.625rem" : props.labelMarginRight,
    marginLeft: props.labelMarginLeft,
    alignItems: "center",
    display: "flex",

    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.938rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
  };

  const textBoxStyle = {
    width: props.textBoxWidth,
    // height: "6rem",
    marginRight: props.textMarginRight === undefined ? "0.625rem" : props.textMarginRight,
    borderRadius: "5px",
    backgroundColor: "#fff",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.9rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000",
  };

  const textBoxStyleDisabled = {
    width: props.textBoxWidth,
    // height: "2.25rem",
    marginRight: props.textMarginRight === undefined ? "0.625rem" : props.textMarginRight,
    borderRadius: "5px",
    backgroundColor: "#ebebeb",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.9rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
  };

  return (
    <Box style={props.style || hStyle}>
      <div style={labelStyle}>
        <div>{props.labelText}</div>
      </div>
      <TextFieldComp
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#555",
          },
        }}
        // id="outlined-multiline-flexible"
        multiline
        minRows={props.minRows}
        maxRows={props.maxRows}
        fullWidth={props.fullWidth}
        // style={props.disabled === true ? textBoxStyleDisabled : textBoxStyle}
        InputProps={{
          readOnly: props.readonly,
          endAdornment: props.endAdornment,
          style: props.disabled === true ? textBoxStyleDisabled : textBoxStyle,
        }}
        InputLabelProps={{ style: { fontSize: "0.8rem" } }}
        disabled={props.disabled}
        size="small"
        inputRef={props.inputRef}
        onKeyPress={props.onKeyPress}
        value={value || ""}
        name={props.name}
        onChange={(e) => {
          cOnChange(e);
          if (props.onChange) {
            props.onChange(e);
          }
        }}
        onKeyDown={props.onKeyDown}
        error={props.required && (value === undefined || value === "")}
        onBlur={props.onBlur}
        autoFocus={props.autoFocus}
        helperText={props.helperText}
        label={props.label}
        autoComplete="off"
      />
    </Box>
  );
};

export default React.memo(TextArea);
