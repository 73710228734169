import {
  contentTypeJson,
  dateToStr,
  generateObjectZeroId,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import DocInfo from "components/File/DocInfo";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import { payListActions } from "pages/ERP/MemberCommonItems/Module/actions/index";
import PaymentInfo from "pages/ERP/MemberCommonItems/PaymentInfo";
import RegisterPaidDate from "pages/ERP/MemberCommonItems/RegisterPaidDate";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AssignDstAccessInfo from "./AssignDstAccessInfo";
import AssignDstContents from "./AssignDstContents";
import AssignSrcContents from "./AssignSrcContents";
import AssignModalIndex from "./Modal/AssignModalIndex";
import post_api from "./Module/api/PostApi";

const AssignView = ({ assignViewState, assignee, removePhoneMemberId }) => {
  const storesDispatch = useDispatch();
  const navigate = useNavigate();
  const paymentInfoRef = useRef();

  const dstMembershipInfoRef = useRef();
  const dstPtInfoRef = useRef();

  const [regDate, setRegDate] = useState(dateToStr(new Date()));
  const [canEnroll, setCanEnroll] = useState(false);
  const [docList, setDocList] = useState([]);
  const [isIncludeServicePt, setIsIncludeServicePt] = useState(
    assignViewState.src_option.pt_include_service_session,
  );
  const [loading, setLoading] = useState(false);

  const [accessMethod, setAccessMethod] = useState({ method: "", cardNum: "" });
  const [sumDay, setSumDay] = useState(0);

  const onClickOpenModal = (order, param) => {
    if (order !== "") {
      openModal(order, param);
    }
  };

  const openModal = (select, param) => {
    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,
        Content: (
          <AssignModalIndex modalSelect={select} modalParam={param} onModalDone={onModalDone} />
        ),
      }),
    );
  };

  //파일 초기화
  useEffect(() => {
    if (assignViewState.contract_doc_list.length !== 0) {
      setDocList(assignViewState.contract_doc_list);
    }
  }, [assignViewState.contract_doc_list.length]);

  //처음 페이지 진입할때, 출입방식 초기화
  useEffect(() => {
    setAccessMethod({ method: "", cardNum: "" });

    if (assignViewState.dst_member_info.member_id === generateObjectZeroId()) {
      //신규회원
      const personalInfo = {
        ...assignViewState.dst_member_info.personal_info,
        ...assignee.base_info,
        member_name: assignee.member_info.member_name,
        reg_path: assignee.base_info.reg_path,
      };

      assignViewState.dst_member_info.personal_info = personalInfo;
      assignViewState.dst_member_info.branch_info = assignee.branch_info;
      assignViewState.dst_option.is_new = true;
    }

    // 회원권 추가기간 제외 초기화
    assignViewState.src_option.mbshp_event_include =
      assignViewState.src_option.mbshp_event_include_list[0];
  }, [assignViewState.src_member_info.member_id]);

  const checkSelectionValidation = () => {
    const dstMembershipList = assignViewState.dst_member_info.membership_data_list;
    const srcMembershipList = assignViewState.src_member_info.membership_data_list;
    const srcPtList = assignViewState.src_member_info.pt_list;
    const isSomeSelected = srcMembershipList.some((el) => el.selected === true);
    const isSomePtSelected = srcPtList.some((el) => el.view_info.selected === true);
    if (dstMembershipList.length === 0) {
      //양도자꺼 회원권 적어도 1개 선택해야
      return isSomeSelected;
    } else {
      if (srcPtList.length === 0) {
        //양도자 PT 없음
        //양도자꺼 회원권 적어도 1개 선택해야
        //양도자꺼 회원권 적어도 1개 선택해야
        return isSomeSelected;
      } else {
        //양도자 PT 있음
        if (isSomeSelected || isSomePtSelected) {
          //회원권이든 pt든 뭐하나 선택해야
          return true;
        } else {
          return false;
        }
        //회원권이든 pt든 뭐하나 선택해야
      }
    }
  };

  const validationCheck = () => {
    if (!checkSelectionValidation()) {
      simpleAlert(storesDispatch, "알림", "회원권을 1개 이상 선택해주세요.");
    } else if (!assignViewState.cs_staff.staff_name) {
      simpleAlert(storesDispatch, "알림", "상담자를 선택해주세요.");
    } else if (
      !assignViewState.dst_option.is_new_card &&
      !assignViewState.dst_member_info.is_use_my_card &&
      !assignViewState.src_member_info.is_assign_card &&
      !assignViewState.dst_option.is_use_app
    ) {
      simpleAlert(storesDispatch, "알림", "양수자 출입방식을 선택해주세요.");
    } else if (
      assignViewState.dst_option.is_new_card &&
      !assignViewState.dst_option.new_card_param.card_no
    ) {
      //신규발급인데 -> 카드번호 선택안했을때,
      simpleAlert(storesDispatch, "알림", "카드 번호를 선택해주세요.");
    } else if (
      assignViewState.src_member_info.branch_info.branch_id !== assignee.branch_info.branch_id &&
      assignViewState.assign_price.change_branch_price < 0
    ) {
      simpleAlert(storesDispatch, "알림", "지점변경비를 입력해주세요.");
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="양도 하시겠습니까?" />,
          onBtnYes: () => {
            enrollAssign();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const onModalDone = (title, pay_info) => {
    if (pay_info.unpaid_info.unpaid_price > 0) {
      simpleAlert(storesDispatch, "알림", "양도 시 미납금이 발생될 수 없습니다.");
    } else {
      paymentInfoRef.current.setCsPaidResult(pay_info);
      storesDispatch(globalModalOff());
      setCanEnroll(true);
    }
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="결제가 완료되었습니다. 등록을 진행해주세요." />
            <Label labelText="결제 완료 후, 양도정보 변경시 결제금액은 모두 초기화됩니다." />
          </>
        ),
        onBtnOk: () => {},
      }),
    );
  };

  //최종 양도 POST
  const enrollAssign = async () => {
    try {
      assignViewState.paymentA.payment_info = [{ payment_input: {}, paid_item_list: [] }];
      assignViewState.paymentA.payment_info[0].payment_input =
        paymentInfoRef.current.getCsPaidResult();
      assignViewState.paymentA.payment_info[0].paid_item_list =
        paymentInfoRef.current.getPaidItemList();
      assignViewState.contract_doc_list = docList;
      assignViewState.reg_date = regDate;

      // 양도, 양수자가 출입방식이 "카드"면 card_param에 branch 정보 담아 보내 줌
      if (assignViewState.dst_member_info.card_param.access_method === "카드") {
        assignViewState.dst_member_info.card_param.branch_info =
          assignViewState.dst_member_info.branch_info;
      }
      if (assignViewState.src_member_info.card_param.access_method === "카드") {
        assignViewState.src_member_info.card_param.branch_info =
          assignViewState.src_member_info.branch_info;
      }

      //pt를 양도 받는지 아닌지에 따라 is_use 분기처리
      if (assignViewState.src_member_info.pt_list.length !== 0) {
        const isAssignPt = assignViewState.src_member_info.pt_list.some(
          (el) => el.view_info.selected === true,
        );
        if (isAssignPt) {
          //4개다 is_use
          assignViewState.contract_doc_list.forEach((el) => (el.is_use = true));
        } else {
          //pt양도 없음
          //pt빼고 3개만 is_use
          assignViewState.contract_doc_list[0].is_use = true;
          assignViewState.contract_doc_list[2].is_use = true;
          assignViewState.contract_doc_list[3].is_use = true;
        }
      } else {
        //pt양도 없음
        //pt빼고 3개만 is_use
        assignViewState.contract_doc_list[0].is_use = true;
        assignViewState.contract_doc_list[2].is_use = true;
        assignViewState.contract_doc_list[3].is_use = true;
      }

      assignViewState.src_option.pt_include_service_session =
        !assignViewState.src_option.pt_include_service_session;

      const formData = new FormData();
      formData.append("new_info", JSON.stringify(assignViewState));

      const postApi = post_api.assignUser();

      setLoading(true);

      // 전화번호 기존 소유자가 있으면 삭제 API call
      if (removePhoneMemberId) {
        const res = HttpInstance.patch(
          process.env.REACT_APP_USER_SERVER + "/member_info/delete_phone",
          "",
          {
            params: {
              member_id: removePhoneMemberId,
              phone: assignee.base_info.phone,
            },
          },
        );
        if (res.msg) {
          setLoading(false);
          simpleAlert(storesDispatch, "알림", res.msg);
          return;
        }
      }

      const res = await HttpInstance.post(postApi, formData, contentTypeJson);

      if (res.error) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label labelText={`${res.data.msg}`} />
              </>
            ),
            onBtnOk: () => {
              navigate("/");
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  //결제정보 디스패치
  const payInfoListAction = (act, pType, detailInfo, ord) => {
    if (act === "push") {
      storesDispatch(payListActions.paymentInfoPush(pType, detailInfo, ord));
    } else if (act === "pop") {
      storesDispatch(payListActions.paymentInfoPop(pType));
    } else if (act === "discount") {
      storesDispatch(payListActions.paymentInfoEdit(pType, detailInfo, ord));
    }
    //  else if (act === "clear") {
    //   storesDispatch(payListActions.paymentInfoClear("add_clear"));
    // }
  };

  const addPayInfoState = (key, price, idx, item_type) => {
    let action = "push";

    if (price === 0) {
      action = "pop";
    }
    payInfoListAction(
      action,
      key,
      {
        type: key,
        price: price,
        dis: 0,
        pay_order: idx,
        tab_idx: 0,
        item_type: item_type,
      },
      idx,
    );
  };

  //파일 업로드하고 CALLBACK
  const uploadDoneCallback = (result) => {
    //업로드에서 받아온 파일을 contract_doc_list에 갱신
    const idx = docList.findIndex((el) => el.doc_id === result.doc_id);
    setDocList((cur) => {
      const arr = [...cur];
      arr[idx] = result;
      return arr;
    });
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {loading && <Loading />}

      <div style={{ width: "76.5%" }}>
        {/* 양도자*/}
        <AssignSrcContents
          payInfoListAction={payInfoListAction}
          addPayInfoState={addPayInfoState}
          assignViewState={assignViewState}
          assigner={assignViewState.src_member_info}
          assignee={assignViewState.dst_member_info}
          dstMembershipInfoRef={dstMembershipInfoRef}
          dstPtInfoRef={dstPtInfoRef}
          setAccessMethod={setAccessMethod}
          setIsIncludeServicePt={setIsIncludeServicePt}
          setSumDay={setSumDay}
        />
        {/* 양수자 출입방식*/}
        <AssignDstAccessInfo
          addPayInfoState={addPayInfoState}
          assignViewState={assignViewState}
          assigner={assignViewState.src_member_info}
          assignee={assignViewState.dst_member_info}
          serviceViewState={assignViewState.src_member_info}
          dstMembershipInfoRef={dstMembershipInfoRef}
          accessMethod={accessMethod}
          setAccessMethod={setAccessMethod}
        />

        {/* 양수자*/}
        <AssignDstContents
          payInfoListAction={payInfoListAction}
          addPayInfoState={addPayInfoState}
          regDate={regDate}
          assignViewState={assignViewState}
          assigner={assignViewState.src_member_info}
          assignee={assignViewState.dst_member_info}
          dstMembershipInfoRef={dstMembershipInfoRef}
          dstPtInfoRef={dstPtInfoRef}
          isIncludeServicePt={isIncludeServicePt}
          sumDay={sumDay}
        />
      </div>

      {/* 우측 화면 */}
      <div style={{ width: "23.5%" }}>
        <PaymentInfo
          ref={paymentInfoRef}
          onClickOpenModal={onClickOpenModal}
          regDate={regDate}
          setCanEnroll={setCanEnroll}
          isAddPay={false}
          noCoupon
          remainPoint={0}
          isAllCalc={true}
        />
        <RegisterPaidDate regDate={regDate} setRegDate={setRegDate} />
        <DocInfo docList={docList} uploadDoneCallback={uploadDoneCallback} />

        <NormalBtn
          name="등록"
          width="100%"
          height="2.5rem"
          theme="white"
          disabled={!canEnroll}
          onClick={validationCheck}
        />

        {/* <NormalBtn
          margin="1rem 0 0 0"
          name="TEST"
          width="100%"
          height="2.5rem"
          theme="white"
          onClick={() => {
            console.log("assignViewState : ", assignViewState);
          }}
        /> */}
      </div>
    </div>
  );
};
export default AssignView;
