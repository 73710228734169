import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import ClassManageWrapper from "./ClassManageWrapper";

const ClassManage = () => {
  return (
    <PageFrame
      children={
        <div>
          <PageTitle titleText="수업관리" />
          <ClassManageWrapper />
        </div>
      }
    />
  );
};
export default ClassManage;
