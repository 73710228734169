import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Popup from "./Popup";

const ModalPopupLocal = forwardRef((props, ref) => {
  // const ModalPopupLocal = ({ params }) => {
  const initialState = {
    show: false,
    title: "",
    Content: "",
    callback: null,
    onModalDone: undefined,
  };
  const [paramState, setParamState] = useState(initialState);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (props.params !== undefined) {
      setParamState(props.params);
      setIsOpen(props.params.show);
    }
    return () => {
      setParamState(initialState);
      setIsOpen(false);
    };
  }, [props.params]);

  useImperativeHandle(ref, () => ({
    // setOpen: () => {
    //   setIsOpen(true);
    // },
    setClose: () => {
      setIsOpen(false);
    },
  }));

  const onBtnOkCallback = () => {
    // paramState.onBtnOk();
    // handleClose();
    setIsOpen(false);
  };

  return (
    <>
      {/* {params.Button} */}
      <Popup
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        // title={paramState.title}
        // Content={paramState.Content}
        // Buttons={paramState.Buttons === undefined ? undefined : onBtnOkCallback}
        {...paramState}
      />
    </>
  );
});

export default ModalPopupLocal;
