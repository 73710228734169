import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import PtClassCard from "pages/Staff/StaffInfo/Modal/WorkInfoItem/SalaryCard/PtClassCard";
import PtCommissionCard from "pages/Staff/StaffInfo/Modal/WorkInfoItem/SalaryCard/PtCommissionCard";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { loadPtSalaryViewStore, loadSalaryInfoViewStore } from "../Module/actions";

//당월 커미션 수정 모달
const EditBasicCommissionModal = ({ modalParam }) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();
  const [loading, setLoading] = useState(false);

  const onClickEvt = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="저장 하시겠습니까?" />
          </>
        ),

        onBtnYes: () => {
          saveCommission();
        },
        onBtnNo: () => {},
      }),
    );
  };
  // console.log(modalParam);
  const saveCommission = async () => {
    try {
      const formData = new FormData();
      // formData.append("salary_report_id", modalParam.salaryReportId);
      // formData.append("staff_id", modalParam.staffId);
      formData.append("new_info", JSON.stringify(modalParam.pt_preset));
      setLoading(true);

      let getQuery = "?salary_report_id=" + modalParam.salaryReportId;
      getQuery += "&staff_id=" + modalParam.staffId;
      getQuery += "&staff_salary_id=" + modalParam.staffSalaryId;

      const res = await HttpInstance.post(
        process.env.REACT_APP_SALARY_SERVER + "/salary/class/commission/update" + getQuery,
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(loadSalaryInfoViewStore(modalParam.salaryReportId, true)); //메인 갱신
        storesDispatch(loadPtSalaryViewStore(modalParam.salaryReportId, true)); //PT탭 갱신
        // ptSalaryDetail(pId); //pt디테일 갱신 ..?
        storesDispatch(globalModalOff()); //모달닫기
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ width: "32rem" }}>
      {loading && <Loading />}

      <div>
        <div>
          <PtCommissionCard workInfoStore={modalParam.pt_preset} />
        </div>

        <div style={{ marginTop: "1.2rem" }}>
          <PtClassCard workInfoStore={modalParam.pt_preset} />
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: "1.3rem", gap: "0.7rem" }}
        >
          <Button
            className={clsx({
              [btnStyleClass.btnRootRaw]: true,
              [btnStyleClass.buttonType5]: true,
            })}
            onClick={onClickEvt}
          >
            저장
          </Button>
        </div>
      </div>
    </div>
  );
};
export default EditBasicCommissionModal;
