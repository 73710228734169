import { useEffect, useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const TextEditor = (props) => {
  // const [value, setValue] = useState("");
  const [value, setValue] = useState(props.defaultValue);

  const quillRef = useRef();

  useEffect(() => {
    if (props.defaultValue !== undefined) {
      setValue(props.defaultValue);
    } else {
      setValue("haha");
    }
  }, [props.defaultValue]);

  useEffect(() => {
    if (props.value !== undefined) {
      setValue(props.value);
    }
  }, [props.value]);

  //저장 POST
  const submitNotice = async () => {};

  const imageHandler = async () => {
    //1. 이미지를 업로드할 input Element를 만들자.
    const input = document.createElement("input");

    //2. input의 속성들을 설정하자
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.setAttribute("class", "hidden");

    //3. body에 input을 추가하자
    document.body.appendChild(input);

    //4. 툴에서 이미지가 클릭되면 input을 클릭하자
    input.click();

    //5. 파일이 선택되면 사진을 서버로 보내자
    input.addEventListener("change", async () => {
      const file = input.files[0];
      const formData = new FormData();
      await formData.append("src", file);

      try {
        //6. 사진을 서버로 보내면 서버가 URL을 줌 (POST API 필요)
        // const res = await api.post("/admin/notice/img", formData);
        // const url = res.data.location;
        // //7. 텍스트 에디터에 커서위치에 사진을 넣자
        // if (quillRef?.current) {
        //   const editor = quillRef.current.getEditor();
        //   // editor.root.innerHTML += `<img src=${url} /><br/>`
        //   const range = editor.getSelection();
        //   editor.insertEmbed(range.index, "image", url);
        // }
      } catch (error) {
        console.error(error);
      }
    });
  };

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, false] }],
          ["bold", "italic", "underline", "blockquote"],
          [{ color: [] }, { background: [] }, { align: [] }], // dropdown with defaults from theme
          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
          ["link", "image"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    };
  }, []);

  const formats = useMemo(() => {
    return [
      "header",
      "bold",
      "color",
      "background",
      "align",
      "italic",
      "underline",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
      "image",
    ];
  }, []);

  return (
    <div style={{ marginTop: "0.5rem ", height: "20rem" }}>
      <ReactQuill
        forwardRef={quillRef}
        style={{ height: "100%", borderRadius: "10px" }}
        theme="snow"
        formats={formats}
        modules={modules}
        // value={value}
        value={value || ""}
        onChange={(e) => {
          //html형태 string
          // console.log(e);
          setValue(e);
          if (props.onChangeCallback) {
            props.onChangeCallback(e);
          }
        }}
      />
    </div>
  );
};
export default TextEditor;
