import { HttpInstance } from "lib/HttpLib";

// [GET] 현재 연결된 장치 가져오기
export const getConnectedDeviceList = () => {
  return HttpInstance.get(process.env.REACT_APP_ACCESS_SERVER + "/sdk/device_list", {});
};

// [GET] 장치 리스트 가져오기
export const getDeviceList = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/access/device_list", {
    params: {
      branch_id: param.branchId,
      page_num: param.page,
    },
  });
};

// [GET] 장치 스케줄 리스트 가져오기
export const getDeviceScheduleList = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/access/manage_list", {
    params: {
      branch_id: param.branch_id,
    },
  });
};

// [GET] 장치 스케줄 설정 정보 가져오기
export const getDeviceSchedulePreSetInfo = (param) => {
  return HttpInstance.get(
    process.env.REACT_APP_BRANCH_SERVER + "/access/device_schedule_set_view",

    {
      params: {
        branch_id: param.branch_id,
      },
    },
  );
};

// [GET] 휴일 정보 가져오기
export const getHolyDaySetInfo = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/access/holy_day_list", {
    params: {
      date_year: param.date_year,
    },
  });
};
