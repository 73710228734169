import { btnStyles, btnSubmitStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import { generateObjectZeroId, getPermission, listEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import CheckBox from "components/LabelInput/CheckBox";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import NumberTextField from "components/LabelInput/NumberTextField";
import SubTitle from "components/PageTitle/SubTitle";
import { globalAlertOn } from "modules/actions/Alert";
import { payListActions } from "pages/ERP/MemberCommonItems/Module/actions";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch } from "react-redux";
import RefundList from "./RefundList";

const RefundContents = forwardRef((props, ref) => {
  const {
    refundState,
    setRefundState,
    payInfoListAction,
    settingsState,
    regDate,
    payMethodList,
    setPayMethodList,
    onSelectPayMethod,
  } = props;
  const btnStyleClass = btnSubmitStyles();
  const storesDispatch = useDispatch();
  const [refundListState, setRefundListState] = useState([]);
  const [canEditPenalty, setCanEditPenalty] = useState(false);

  useEffect(() => {
    getPermission(storesDispatch, "MEMBER_VIEW_MENU/EXT_MANAGE").then((res) => {
      setCanEditPenalty(res);
    });
  }, []);

  useImperativeHandle(ref, () => ({
    getSelectedItem: () => {
      return refundListState;
    },
  }));

  const updateRefundList = () => {
    const curRefundTargetList = [];
    const refundInfoList = refundState.refund_info_list;

    for (let i = 0; i < refundInfoList.length; i++) {
      refundInfoList[i].is_lock = false;
    }
    for (let i = 0; i < refundState.refund_list.length; i++) {
      refundState.refund_list[i].selected = false;
      refundState.refund_list[i].total_paid_price = 0;
      refundState.refund_list[i].total_deduct_price = 0;
      refundState.refund_list[i].total_penalty = 0;
      refundState.refund_list[i].total_refund_price = 0;

      refundState.refund_list[i].repaid_price = 0;
      refundState.refund_list[i].done_refund_price = 0;

      for (let j = 0; j < refundState.refund_list[i].refund_detail.length; j++) {
        refundState.refund_list[i].refund_detail[j].is_view = false;
        refundState.refund_list[i].refund_detail[j].selected = false;
      }
    }

    for (let i = 0; i < refundInfoList.length; i++) {
      let totalPaid = 0;
      let totalDeduct = 0;
      let totalPenalty = 0;
      let totalRefund = 0;

      if (refundInfoList[i].selected) {
        const index = refundState.refund_list
          .map((item, idx) => item.purchase_code)
          .indexOf(refundInfoList[i].purchase_code);
        if (index !== -1) {
          refundState.refund_list[index].selected = true;

          const curTarget = refundInfoList[i];
          for (let j = 0; j < curTarget.purchase_list.length; j++) {
            totalPaid += curTarget.purchase_list[j].purchase_info.paid_price;
            totalDeduct += curTarget.purchase_list[j].deduct_price;
            totalPenalty += curTarget.purchase_list[j].penalty;
            totalRefund += curTarget.purchase_list[j].refund_price;
          }
          refundState.refund_list[index].total_paid_price += totalPaid;
          refundState.refund_list[index].total_deduct_price += totalDeduct;
          refundState.refund_list[index].total_penalty += totalPenalty;
          refundState.refund_list[index].total_refund_price += totalRefund;

          // 연결된 부가상품 강제 select
          for (let j = 0; j < curTarget.child_idx_list.length; j++) {
            const childIdx = curTarget.child_idx_list[j];
            refundInfoList[childIdx].selected = true;
            refundInfoList[childIdx].is_lock = true;
          }
        }
      } else {
        // 연결된 부가상품 중 PT만 강제 unselect
        const curTarget = refundInfoList[i];
        for (let j = 0; j < curTarget.child_idx_list.length; j++) {
          const childIdx = curTarget.child_idx_list[j];
          if (
            refundInfoList[childIdx].purchase_list.length > 0 &&
            refundInfoList[childIdx].purchase_list[0].purchase_info.item_type.includes("PT") &&
            refundInfoList[childIdx].purchase_list[0].purchase_info.membership_id !==
              generateObjectZeroId()
          ) {
            refundInfoList[childIdx].selected = false;
            refundInfoList[childIdx].is_lock = false;
          }
        }
      }
    }

    for (let i = 0; i < refundState.refund_list.length; i++) {
      if (refundState.refund_list[i].selected) {
        curRefundTargetList.push(refundState.refund_list[i]);
      }
    }

    setRefundListState(curRefundTargetList);

    // 환불신청내역 테이블 내 값들 초기화
    // setRefundTotalPrice(0);
    // setAddedRefundTotalPrice(0);
    // setRefundPayList(refundItem.refund_detail);

    for (let i = 0; i < refundListState.length; i++) {
      if (refundListState[i].payment_list) {
        for (let j = 0; j < refundListState[i].payment_list.length; j++) {
          refundListState[i].payment_list[j].payment_list_selector.selected = false;
        }
      }
    }

    storesDispatch(payListActions.paymentInfoClear("clear"));
    setPayMethodList([]);
  };

  const onChangeRefundState = (idx1, idx2, type, val) => {
    if (type === "deduct") {
      refundState.refund_info_list[idx1].purchase_list[idx2].deduct_price = val;
    } else if (type === "penalty") {
      refundState.refund_info_list[idx1].purchase_list[idx2].penalty = val;
    }

    refundState.refund_info_list[idx1].purchase_list[idx2].refund_price =
      refundState.refund_info_list[idx1].purchase_list[idx2].purchase_info.paid_price -
      refundState.refund_info_list[idx1].purchase_list[idx2].deduct_price -
      refundState.refund_info_list[idx1].purchase_list[idx2].penalty;

    updateRefundList();
  };

  const onSelectCsStaff = (staffInfo) => {
    refundState.cs_staff.staff_id = staffInfo.staff_id;
    refundState.cs_staff.staff_name = staffInfo.staff_name;
    refundState.cs_staff.staff_pos = staffInfo.position.org_name;
    refundState.cs_staff.staff_rank = staffInfo.rank.org_name;
  };

  return (
    <CardFixed
      topDom={
        <div style={{ display: "flex" }}>
          <div>환불 정보</div>
          <div style={{ display: "flex", float: "right", marginLeft: "auto" }}>
            <SelectConsultant
              csInfoList={refundState.cs_team_info_list}
              csNameList={refundState.cs_team_name_list}
              onSelectCsStaff={onSelectCsStaff}
            />
          </div>
        </div>
      }
      midDom={
        <div>
          {/* 환불사항 선택 */}
          <RefundTargetList
            refundState={refundState}
            onChangeRefundState={onChangeRefundState}
            updateRefundList={updateRefundList}
            canEditPenalty={canEditPenalty}
          />

          {/* 환불 신청 내역 */}
          <RefundList
            refundState={refundState}
            refundListState={refundListState}
            payInfoListAction={payInfoListAction}
            regDate={regDate}
            payMethodList={payMethodList}
            setPayMethodList={setPayMethodList}
            onSelectPayMethod={onSelectPayMethod}
          />
        </div>
      }
      isLoading={false}
      rootStyle={{
        width: "76.88rem",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
});
export default RefundContents;

//
const SelectConsultant = ({ csInfoList, csNameList, onSelectCsStaff }) => {
  return (
    <div>
      <ComboBox
        required
        marginRight="0rem"
        labelWidth={"2.625rem"}
        comboItemWidth={"12rem"}
        labelText="상담자"
        data={csNameList || []}
        onChangeCallback={(e) => {
          const idx = csInfoList.findIndex((x) => x.staff_name === e.target.value);
          onSelectCsStaff(csInfoList[idx]);
        }}
      />
    </div>
  );
};

//
const RefundTargetList = ({
  refundState,
  updateRefundList,
  onChangeRefundState,
  canEditPenalty,
}) => {
  const refundTargetList = new refundTargetTable(
    updateRefundList,
    onChangeRefundState,
    canEditPenalty,
  );

  return (
    <div
      style={{
        marginTop: "1.25rem",
      }}
    >
      <SubTitle titleText={"환불사항 선택"} />
      <div style={{ margin: "0 0.1rem" }}>
        <CustomTable
          columns_head={refundTargetList.columns_head}
          table_title={refundTargetList.table_title}
          rest_call={refundTargetList.get_data_from_rest}
          row_render={refundTargetList.create_table}
          rest_data={refundState.refund_info_list}
        />
      </div>
    </div>
  );
};

//
class refundTargetTable {
  table_title = "";
  btnStyleClass = btnStyles();
  storesDispatch = useDispatch();
  updateRefundList = null;
  setRefundState = null;
  canEditPenalty = null;

  constructor(updateRefundList, setRefundState, canEditPenalty) {
    this.updateRefundList = updateRefundList;
    this.setRefundState = setRefundState;
    this.canEditPenalty = canEditPenalty;
  }

  getMaxValue = (data, idx1, idx2, value) => {
    // refundState.refund_info_list[idx1].purchase_list[idx2].refund_price =
    //   refundState.refund_info_list[idx1].purchase_list[idx2].purchase_info.paid_price -
    //   refundState.refund_info_list[idx1].purchase_list[idx2].deduct_price -
    //   refundState.refund_info_list[idx1].purchase_list[idx2].penalty;
  };

  checkMaxValue = (type, data, idx, value) => {
    if (type === "deduct") {
      if (
        data.purchase_list[idx].purchase_info.paid_price <
        value + data.purchase_list[idx].penalty
      ) {
        return false;
      }
    } else {
      if (
        data.purchase_list[idx].purchase_info.paid_price <
        value + data.purchase_list[idx].deduct_price
      ) {
        return false;
      }
    }
    return true;
  };

  checkMergedPt = (e, data) => {
    if (e.checked /*&& data.is_merged === true*/) {
      if (
        data.purchase_list.length > 0 &&
        data.purchase_list[0].purchase_info.item_type.includes("PT") &&
        data.purchase_list[0].purchase_info.membership_id !== generateObjectZeroId()
      ) {
        e.checked = false;
        this.storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: <Label labelText="결합상품의 PT는 별도 환불이 불가합니다." />,
            onBtnOk: () => {},
          }),
        );
        return true;
      }
    }
    return false;
  };

  create_table = (data, idx1) => {
    return (
      <>
        <CustomRow style={{ width: "100%" }} key={idx1}>
          <CustomTableContents width="4%" rowSpan={data.purchase_list.length + 1}>
            <CheckBox
              isTable
              defaultValue={data.selected}
              onChangeCallback={(e) => {
                if (this.checkMergedPt(e, data) === false) {
                  data.selected = e.checked;
                  this.updateRefundList();
                }
              }}
              disabled={data.is_lock}
            />
          </CustomTableContents>
          <CustomTableContents width="10%" rowSpan={data.purchase_list.length + 1}>
            {""}
          </CustomTableContents>
          <CustomTableContents width="10%" rowSpan={data.purchase_list.length + 1}>
            {data.purchase_code}
          </CustomTableContents>
        </CustomRow>
        {data.purchase_list.map((item, idx2) => {
          return (
            <CustomRow key={idx2}>
              <CustomTableContents width="20%">
                {item.purchase_info.description === "회원권"
                  ? item.purchase_info.description + " (" + item.purchase_info.item_name + ")"
                  : item.purchase_info.description}
              </CustomTableContents>
              <CustomTableContents width="8%">
                {item.purchase_info.item_price.toLocaleString()}
              </CustomTableContents>
              <CustomTableContents width="8%">
                {item.purchase_info.paid_price.toLocaleString()}
              </CustomTableContents>
              <CustomTableContents width="8%">{item.usage_details}</CustomTableContents>
              <CustomTableContents width="8%">
                {/* {item.deduct_price} */}
                <NumberTextField
                  justifyContent="center"
                  textBoxWidth="6rem"
                  defaultValue={item.deduct_price}
                  labelMarginRight="0rem"
                  textMarginRight="0rem"
                  isNumber
                  minValue={0}
                  maxValue={this.getMaxValue()}
                  onChangeCallback={(e) => {
                    // if (this.checkMaxValue("deduct", data, idx2, e) === false) {
                    //   e = 0;
                    //   this.storesDispatch(
                    //     globalAlertOn({
                    //       show: true,
                    //       titleText: "알림",
                    //       bodyText: <Label labelText="환불금액이 결제금액보다 큽니다." />,
                    //       onBtnOk: () => {},
                    //     }),
                    //   );
                    // }
                    this.setRefundState(idx1, idx2, "deduct", e);
                  }}
                  disabled={!this.canEditPenalty}
                />
              </CustomTableContents>
              <CustomTableContents width="8%">
                {/* {item.penalty} */}
                <NumberTextField
                  justifyContent="center"
                  textBoxWidth="6rem"
                  defaultValue={item.penalty}
                  labelMarginRight="0rem"
                  textMarginRight="0rem"
                  isNumber
                  minValue={0}
                  onChangeCallback={(e) => {
                    // if (this.checkMaxValue("penalty", data, idx2, e) === false) {
                    //   e = 0;
                    //   this.storesDispatch(
                    //     globalAlertOn({
                    //       show: true,
                    //       titleText: "알림",
                    //       bodyText: <Label labelText="환불금액이 결제금액보다 큽니다." />,
                    //       onBtnOk: () => {},
                    //     }),
                    //   );
                    // }
                    this.setRefundState(idx1, idx2, "penalty", e);
                  }}
                  disabled={!this.canEditPenalty}
                />
              </CustomTableContents>
              <CustomTableContents width="8%">
                {item.refund_price.toLocaleString()}
              </CustomTableContents>
            </CustomRow>
          );
        })}
      </>
    );
  };

  columns_head = [
    { title: "선택", width: "3%" },
    { title: "결제지점", width: "6%" },
    { title: "구매코드", width: "11%" },
    { title: "항목", width: "15%" },
    { title: "상품가액", width: "9%" },
    { title: "결제금액", width: "9%", tooltip: "실제 회원이 결제한 상품의 금액입니다." },
    { title: "사용내역", width: "8%" },
    { title: "차감금액", width: "9%", tooltip: "사용단가 * 사용내역을 계산한 금액입니다." },
    { title: "위약금", width: "9%", tooltip: "설정된 위약금의 비율대로 계산된 금액입니다." },
    { title: "환불금액", width: "9%" },
  ];

  get_data_from_rest = (stateData) => {
    if (listEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
