import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import ManagePtTeamWrapper from "./ManagePtTeamWrapper";

const ManagePtTeam = () => {
  return (
    <PageFrame
      children={
        <div>
          <PageTitle titleText="PT팀 관리" />
          <ManagePtTeamWrapper />
        </div>
      }
    />
  );
};
export default ManagePtTeam;
