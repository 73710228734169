import { generateObjectDummyId } from "components/CommonLib/CommonLib";

export const searchParamInit = {
  //결제 수단
  is_unset: true,
  hq_id: generateObjectDummyId(),
};

export const hqBranchListEmpty = {
  branch_list: [
    {
      branch_id: "000000000000000000000000",
      branch_name: "전체",
    },
  ],
  branch_name_list: ["전체"],
  hq_branch_list: {
    branch_list: [["전체"]],
    hq_list: ["전체"],
    target_list: [
      {
        branch_list: {
          branch_id: "000000000000000000000000",
          branch_name: "전체",
        },
        hq_info: {
          hq_id: "000000000000000000000000",
          hq_name: "전체",
        },
      },
    ],
  },
};

export const hqBranchListEmptyState = {
  //결제 수단
  view_list: hqBranchListEmpty,
  edit_list: hqBranchListEmpty,
};

export const schedulePreSetInitState = {
  schedule_name: "",
  user_group: "",
  descr: "",
  schedule_id: "",
  sched_day_list: [
    {
      day_name: "월요일",
      _ti: [],
    },
    {
      day_name: "화요일",
      _ti: [],
    },
    {
      day_name: "수요일",
      _ti: [],
    },
    {
      day_name: "목요일",
      _ti: [],
    },
    {
      day_name: "금요일",
      _ti: [],
    },
    {
      day_name: "토요일",
      _ti: [],
    },
    {
      day_name: "일요일",
      _ti: [],
    },
    {
      day_name: "공휴일",
      _ti: [],
    },
  ],
};
