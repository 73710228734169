import { generateObjectZeroId, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { settingInitState } from "../Register/Module/reducers/setting_init_state";
import AddPayView from "./AddPayView";

const AddPayOptionWrapper = ({ memberInputState, branch_id, memberBaseInfoState, initRegDate }) => {
  const navigate = useNavigate();
  const storesDispatch = useDispatch();

  // const [branchIdx, setBranchIdx] = useState(0);
  const [baseInfoState, setBaseInfoState] = useState(_.cloneDeep(settingInitState));
  // const [branchList, setBranchList] = useState([]); // structToBranches(baseInfoState);

  const getSettingsData = (branchId) => {
    HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/register/settings_show", {
      params: { branch_id: branchId },
    })

      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setBaseInfoState(res.data.data);
          // setBranchList(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  useEffect(() => {
    if (memberInputState.branch_info.branch_id !== generateObjectZeroId()) {
      getSettingsData(memberInputState.branch_info.branch_id);
    }
  }, [memberInputState]);

  return (
    <div>
      <AddPayView
        baseInfoState={baseInfoState}
        memberInputState={memberInputState}
        // branchList={branchList}
        // branchIdx={branchIdx}
        // setBranchIdx={setBranchIdx}
        memberBaseInfoState={memberBaseInfoState}
        navigate={navigate}
        initRegDate={initRegDate}
      />
    </div>
  );
};
export default AddPayOptionWrapper;
