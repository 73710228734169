import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Loading from "components/Loading/Loading/Loading";
import ModalPopup from "components/ModalPopup/ModalPopup";
import AlertPopupGlobal from "components/Popup/AlertPopupGlobal";
import { setInitialFontSize } from "fonts/setInitialFontSize";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import RouteSwitch from "./RouteSwitch";

function App() {
  // const queryClient = new QueryClient();
  const [queryClient] = useState(() => new QueryClient());
  const loadingStores = useSelector((state) => state.global_loading);
  const alertModal = useSelector((state) => state.alert_state);
  const modalModal = useSelector((state) => state.modal_state);

  useEffect(() => {
    setInitialFontSize();
  }, []);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          {loadingStores.stores === false ? "" : <Loading />}
          <AlertPopupGlobal params={alertModal.stores} />
          <ModalPopup params={modalModal.stores} />
          <RouteSwitch></RouteSwitch>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;
