import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCouncelingDetailAPi } from "../RestApi/getApi";

const PreViewPopup = ({ params, onModalDone }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const [imgUrl, setImgUrl] = useState("");

  useEffect(() => {
    getCouncelingDetailAPi(storesDispatch, params.file_info, setImgUrl);
  }, [params.resource_id]);

  return (
    <div style={{ width: "440px", height: "622px" }}>
      {imgUrl === "" ? <div></div> : <img src={imgUrl} style={{ width: "100%", height: "100%" }} />}
    </div>
  );
};
export default PreViewPopup;
