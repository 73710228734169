import {
  loadChangePayMethodView,
  loadChangePayMethodViewSuccess,
  loadChangePayMethodViewFail,
  loadChangePayMethodViewReset,
  loadChangePayMethodRefundPush,
  loadChangePayMethodRefundPop,
} from "./view_act";

export const loadChangePayMethodViewStore = (member_id) => loadChangePayMethodView(member_id);

export const changePayMethodViewAction = {
  loadChangePayMethodViewSuccess,
  loadChangePayMethodViewFail,
  loadChangePayMethodViewReset,
  loadChangePayMethodRefundPush,
  loadChangePayMethodRefundPop,
};
