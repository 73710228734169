import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { useEffect, useState } from "react";
import {
  getBaseSearchParam,
  getMbshpSearchParam,
  getMemberSearchParam,
  getPtSearchParam,
  getSideSearchParam,
} from "../Module/api/GetApi";
import SearchMemberContent from "./SearchMemberContent";

const SearchMember = () => {
  const [baseSearchState, setBaseSearchState] = useState();
  const [memberSearchState, setMemberSearchState] = useState();
  const [mbshpSearchState, setMbshpSearchState] = useState();
  const [sideSearchState, setSideSearchState] = useState();
  const [ptSearchState, setPtSearchState] = useState();

  useEffect(() => {
    getBaseSearchParam().then((res) => {
      setBaseSearchState(res.data.data);
    });
    getMemberSearchParam().then((res) => {
      setMemberSearchState(res.data.data);
    });
    getMbshpSearchParam().then((res) => {
      setMbshpSearchState(res.data.data);
    });
    getSideSearchParam().then((res) => {
      setSideSearchState(res.data.data);
    });
    getPtSearchParam().then((res) => {
      setPtSearchState(res.data.data);
    });
  }, []);

  return (
    <div>
      <PageFrame
        children={
          <div>
            <PageTitle titleText="회원 조회" />
            <SearchMemberContent
              baseSearchState={baseSearchState}
              memberSearchState={memberSearchState}
              mbshpSearchState={mbshpSearchState}
              sideSearchState={sideSearchState}
              ptSearchState={ptSearchState}
            />
          </div>
        }
      />
    </div>
  );
};
export default SearchMember;
