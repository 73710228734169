import { contentTypeJson, dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalModalOn } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ScheduleModalIndex from "./Modal/ScheduleModalIndex";
import { searchInitState, searchResultInit } from "./Module/reducer/searchInitState";
import ScheduleView from "./ScheduleView";

const ScheduleWrapper = () => {
  const storesDispatch = useDispatch();
  const [searchParam, setSearchParam] = useState(_.cloneDeep(searchInitState));
  const [searchResult, setSearchResult] = useState(_.cloneDeep(searchResultInit));
  const [staffList, setStaffList] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const openModal = (select, param, doneCallback) => {
    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,
        Content: (
          <ScheduleModalIndex modalSelect={select} modalParam={param} onModalDone={doneCallback} />
        ),
      }),
    );
  };

  const onClickOpenModal = (order, param, doneCallback) => {
    if (order !== "") {
      if (dataEmpty(doneCallback)) {
        openModal(order, param);
      } else {
        openModal(order, param, doneCallback);
      }
    }
  };

  //초기 서파GET
  const getSearchInit = async () => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_STAFF_SERVER + "/schedule/search_param",
        {
          params: {},
        },
      );
      if (!restEmpty(res.data.data)) {
        const result = res.data.data;
        if (!result.hq_branch_data.hq_list) {
          result.hq_branch_data.hq_list = [];
        }

        setSearchParam(result);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  //검색 POST
  const onClickSearch = async (mode, page) => {
    //일간 일때만 페이지네이션
    if (mode === "day" && page) {
      setCurPage(page);
    } else {
      setCurPage(1);
    }

    try {
      const formData = new FormData();
      if (mode === "day") {
        formData.append("page_num", page);
      }
      formData.append("search_param", JSON.stringify(searchParam));

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_STAFF_SERVER + `/schedule/search/${mode}`,
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        //일간 -> 직원목록, total_page 넘겨주기
        if (mode === "day") {
          const staffArr = searchParam.ch_staff_list.slice(1);
          if (page) {
            const newArr = staffArr.filter(
              (el, idx) => idx >= 7 * (page - 1) && idx <= 7 * page - 1,
            );
            setStaffList(newArr);
          } else {
            const newArr = staffArr.filter((el, idx) => idx < 7);
            setStaffList(newArr);
          }
          setTotalPage(res.data.total_page);
        }

        setSearchResult(res.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  //초기 랜더링 -> 서파 받아오기 및 기본 레이아웃 불러오기
  useEffect(() => {
    getSearchInit();
    // onClickSearch("month");
  }, []);

  //페이지네이션(일간)
  useEffect(() => {
    if (curPage) {
      onClickSearch("day", curPage);
    }
  }, [curPage]);

  return (
    <div>
      {loading ? <Loading /> : <></>}

      <ScheduleView
        onClickOpenModal={onClickOpenModal}
        searchParam={searchParam}
        setSearchParam={setSearchParam}
        onClickSearch={onClickSearch}
        searchResult={searchResult}
        setSearchResult={setSearchResult}
        setCurPage={setCurPage}
        totalPage={totalPage}
        curPage={curPage}
        staffList={staffList}
      />
    </div>
  );
};
export default ScheduleWrapper;
