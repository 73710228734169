import { Table, TableContainer, TableHead } from "@mui/material";
import border from "assets/icon/Border.svg";
import { SubLine, dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableCell,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import SubTitle from "components/PageTitle/SubTitle";
import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";

const TotalInfoTable = ({ sumInfo }) => {
  return (
    <div style={{ position: "relative", top: "-1px", marginLeft: "0.6px", marginRight: "1px" }}>
      <TableContainer
        style={{
          border: "1px solid #CCC",
        }}
      >
        <Table>
          <TableHead>
            <CustomRow style={{ width: "100%" }}>
              <CustomTableCell style={{ backgroundColor: "#dfebf3" }} align="center" width="20%">
                총계
              </CustomTableCell>
              <CustomTableCell width="20%" $isNumber>
                {sumInfo.curSum.toLocaleString() + " 원"}
              </CustomTableCell>
              <CustomTableCell width="20%" $isNumber>
                {sumInfo.goalSum.toLocaleString() + " 원"}
              </CustomTableCell>
              <CustomTableCell width="20%" $isNumber>
                {sumInfo.rate + " %"}
              </CustomTableCell>
              <CustomTableCell width="20%" $isNumber>
                {sumInfo.classSum + " 회"}
              </CustomTableCell>
            </CustomRow>
          </TableHead>
        </Table>
      </TableContainer>
    </div>
  );
};

const TeamSummary = ({ totalResultList, summaryInfo }) => {
  const teamListData = new teamListTable();

  const sumInfo = useMemo(() => {
    let curSum = 0;
    let goalSum = 0;
    let rateSum = 0;
    let classSum = 0;

    for (let i = 0; i < totalResultList.length; i++) {
      curSum += totalResultList[i].weekly_summary.task_objective_info.current_sales;
      goalSum += totalResultList[i].weekly_summary.task_objective_info.goal_sales;
      rateSum += totalResultList[i].weekly_summary.task_objective_info.goal_rate;
      classSum += totalResultList[i].weekly_summary.task_activity_info.pt_class;
    }

    return { curSum, goalSum, rate: Math.floor(rateSum / 3), classSum };
  }, [totalResultList.length]);

  return (
    <div>
      <SubLine />

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ width: "70%" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              alt="border"
              src={border}
              style={{
                width: "1.2rem",
                height: "1.2rem",
              }}
            />

            <SubTitle titleText="조회 결과 총계" />
          </div>
          <CustomTable
            columns_head={teamListData.columns_head}
            table_title={teamListData.table_title}
            rest_call={teamListData.get_data_from_rest}
            row_render={teamListData.create_table}
            rest_data={totalResultList}
          />

          <TotalInfoTable sumInfo={sumInfo} />
        </div>
        <div style={{ width: "25%" }}>
          <ReactApexChart
            options={{
              chart: {
                width: 300,
                type: "pie",
              },

              tooltip: {
                style: {
                  fontFamily: "AppleSDGothicNeo",
                },
              },
              labels: summaryInfo.nameList,
            }}
            series={summaryInfo.curGoalList}
            type="pie"
            width={380}
          />
        </div>
      </div>
    </div>
  );
};
export default TeamSummary;

class teamListTable {
  table_title = "";

  create_table = (data, key) => {
    return (
      <CustomRow key={key}>
        <CustomTableContents>{data.staff_info.staff_name}</CustomTableContents>
        <CustomTableContents $isNumber>
          {data.weekly_summary.task_objective_info.current_sales.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.weekly_summary.task_objective_info.goal_sales.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.weekly_summary.task_objective_info.goal_rate + " %"}
        </CustomTableContents>
        <CustomTableContentsEND $isNumber>
          {data.weekly_summary.task_activity_info.pt_class + " 회"}
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "팀원", width: "20%" },
    { title: "현재 매출", width: "20%" },
    { title: "목표 매출", width: "20%" },
    { title: "달성률", width: "20%" },
    { title: "PT 수업 진행 횟수", width: "20%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
