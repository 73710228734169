import { generateObjectDummyId, generateObjectZeroId } from "components/CommonLib/CommonLib";

export const ptInitState = {
  per_price: 0,
  pt_total_cnt: 0,
  total_price: 0,
  price: 0,
  dis: 0,
  input_type: "총액",
  default_session: 0,
  service_session: 0,
  is_lock: false,

  pt_staff: {
    staff_id: generateObjectZeroId(),
    staff_name: "",
    staff_pos: "",
    staff_rank: "",
  },
  cs_staff: {
    staff_id: generateObjectZeroId(),
    staff_name: "",
    staff_pos: "",
    staff_rank: "",
  },
  // reg_info: {
  //   reg_date: "date / 등록일시",
  //   reg_user: "string / 등록자",
  //   upd_date: "date / 수정일시",
  //   upd_user: "string / 최근수정자",
  // },
};
