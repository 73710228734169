export const ptItemInfoInitial = {
  item_id: "",
  item_name: "",
  item_price: 0,
  per_price: 0,
  session_cnt: 0,
  service_session_cnt: 0,
  base_info: {
    sales_status: "판매대기",
    sales_limit: false,
    show_in_app: false,
    incentive: 0,
    order: 0,
    tablet_img: "",
    mem_app_img: "",
  },
};
