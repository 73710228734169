import React, { useEffect, useRef, useState } from "react";
import { TIME_ARR, showTimeText } from "../calendarDateUtil";
import { selectStyle } from "./calendarStyle";
const WeekSchedule = ({ searchParam, gridRowItem, setSearchResult, selectPopup, colDayArr }) => {
  // 오전 9시 row component
  const rowFocusRef = useRef(null);
  const [gridItem, setGridIem] = useState([]);

  useEffect(() => {
    if (gridRowItem.length === 24) {
      setGridIem(gridRowItem);
    }
  }, [gridRowItem]);

  // 페이지 진입 시 9시 띄우기
  useEffect(() => {
    if (rowFocusRef.current !== null) {
      rowFocusRef.current.scrollIntoView();
    }
  }, [gridItem]);

  const onMouseEvt = (e, timeIdx, dayIdx) => {
    if (searchParam.branch_info.branch_name) {
      setSearchResult((cur) => {
        const obj = { ...cur };
        obj.grid_item[timeIdx][dayIdx].is_selected = true;
        return obj;
      });
    }
  };

  const onMouseLeaveEvt = (e, timeIdx, dayIdx) => {
    if (searchParam.branch_info.branch_name) {
      setSearchResult((cur) => {
        const obj = { ...cur };
        obj.grid_item[timeIdx][dayIdx].is_selected = false;
        return obj;
      });
    }
  };

  const normalStyle = {
    border: "1px solid #EBEBEB",
    padding: "0.3rem 0.6rem",
    fontFamily: "AppleSDGothicNeo",
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "0.5fr repeat(7,1fr)",
        gridAutoRows: "minmax(5rem,auto)",
        gridAutoColumns: "1fr",
        boxSizing: "border-box",
      }}
    >
      {gridItem.length !== 0 &&
        gridItem.map((time, timeIdx) => {
          return (
            <React.Fragment key={timeIdx}>
              <div style={normalStyle} ref={timeIdx === 9 ? rowFocusRef : null}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  {showTimeText(timeIdx)}
                </div>
              </div>
              {time.map((day, dayIdx) => (
                <div
                  key={dayIdx}
                  style={normalStyle}
                  onMouseOver={(e) => {
                    onMouseEvt(e, timeIdx, dayIdx);
                  }}
                  onMouseLeave={(e) => {
                    onMouseLeaveEvt(e, timeIdx, dayIdx);
                  }}
                >
                  {day.schedule_list &&
                    day.schedule_list.map((el) => (
                      <div
                        key={el.schedule_base.schedule_info.schedule_id}
                        style={selectStyle(
                          el.schedule_base.schedule_info.schedule_type,
                          el.schedule_base.schedule_info.schedule_sub_type,
                        )}
                        onClick={() => {
                          selectPopup(
                            colDayArr[dayIdx],
                            TIME_ARR[timeIdx],
                            el.schedule_base.schedule_info.schedule_type,
                            false,
                            el.schedule_base.schedule_info.schedule_id,
                            el,
                          );
                        }}
                      >
                        {el.card_title}
                        {/* <br />
                        {`${el.schedule_base.charger_staff_info.staff_name}`} */}
                        {/* {el.schedule_base.schedule_info.schedule_type === "수업완료" ||
                        el.schedule_base.schedule_info.schedule_type === "예약"
                          ? ` / ${el.schedule_base.schedule_info.schedule_sub_type}`
                          : ""} */}
                      </div>
                    ))}
                  <div
                    style={{
                      marginTop: "0.2rem",
                      display: "flex",
                      gap: "0.2rem",
                      visibility: day.is_selected ? "visible" : "hidden",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#003CCB",
                        color: "#fff",
                        fontSize: "0.8rem",
                        cursor: "pointer",
                        borderRadius: "5px",
                        width: "50%",
                        height: "1.5rem",
                        lineHeight: "1.5rem",
                      }}
                      onClick={() => {
                        selectPopup(colDayArr[dayIdx], TIME_ARR[timeIdx], "예약", true);
                      }}
                    >
                      + 예약
                    </div>
                    <div
                      style={{
                        backgroundColor: "#003CCB",
                        color: "#fff",
                        fontSize: "0.8rem",
                        cursor: "pointer",
                        borderRadius: "5px",
                        width: "50%",
                        height: "1.5rem",
                        lineHeight: "1.5rem",
                      }}
                      onClick={() => {
                        selectPopup(colDayArr[dayIdx], TIME_ARR[timeIdx], "개인업무", true);
                      }}
                    >
                      + 개인업무
                    </div>
                  </div>
                </div>
              ))}
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default WeekSchedule;
