import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import NormalBtn from "components/NewButton/NormalBtn";

const PtRecordResultTable = ({
  openModal,
  searchResult,
  curPage,
  totalPage,
  onClickSearch,
  isAdmin,
}) => {
  const onClickOpenModal = (data, type) => {
    openModal(data, type);
  };

  const searchResultList = new resultTable(onClickOpenModal, isAdmin);

  const doSearch = (page) => {
    onClickSearch(page);
  };

  return (
    <div>
      <CustomTable
        columns_head={isAdmin ? searchResultList.columns_head_admin : searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
      />
      <CustomPagination curPage={curPage} totalCnt={totalPage} doSearch={doSearch} />
    </div>
  );
};
export default PtRecordResultTable;

//

class resultTable {
  table_title = "";
  onClickOpenModal = null;
  isAdmin = false;

  constructor(onClickOpenModal, isAdmin) {
    this.onClickOpenModal = onClickOpenModal;
    this.isAdmin = isAdmin;
  }

  // 판매금액 가져오기
  getSalesPrice = (data) => {
    // 환불이 아닌 경우는 카드+현금 금액. / 환불인 경우는 토탈 환불금액
    if (data.from_service === "환불" && data.sales_info.purchase_refund_total > 0) {
      return data.sales_info.purchase_refund_total * -1;
    } else {
      return data.sales_info.paid_card_price + data.sales_info.paid_cash_price;
    }
  };

  create_table = (data, idx) => {
    return (
      <CustomRow>
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.reg_info.reg_name}</CustomTableContents>
        <CustomTableContents>{data.from_service}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_name}</CustomTableContents>
        <CustomTableContents>{data.pt_info.item_name}</CustomTableContents>
        <CustomTableContents $isNumber>
          {this.getSalesPrice(data).toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.incentive.incentive.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents>{data.staff_info.staff_name}</CustomTableContents>
        <CustomTableContents>{data.is_salaried_done ? "Y" : "N"}</CustomTableContents>
        <CustomTableContents>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <NormalBtn
              name="상담자 변경"
              onClick={() => {
                this.onClickOpenModal(data, "staff");
              }}
            />
          </div>
        </CustomTableContents>
        {this.isAdmin ? (
          <CustomTableContents>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <NormalBtn
                name="금액 변경"
                onClick={() => {
                  this.onClickOpenModal(data, "sales");
                }}
              />
            </div>
          </CustomTableContents>
        ) : (
          <></>
        )}
      </CustomRow>
    );
  };

  columns_head = [
    { title: "등록일시", width: "11%" },
    { title: "등록계정", width: "6%" },
    { title: "사유", width: "6%" },
    { title: "회원번호", width: "6%" },
    { title: "회원명", width: "6%" },
    { title: "상품명", width: "13%" },
    { title: "판매금액", width: "6%" },
    { title: "실적금액", width: "6%" },
    { title: "실적 직원", width: "6%" },
    { title: "급여\n승인여부", width: "5%" },
    { title: "", width: "6%" },
  ];
  columns_head_admin = [
    { title: "등록일시", width: "11%" },
    { title: "등록계정", width: "6%" },
    { title: "사유", width: "6%" },
    { title: "회원번호", width: "6%" },
    { title: "회원명", width: "6%" },
    { title: "상품명", width: "13%" },
    { title: "판매금액", width: "6%" },
    { title: "실적금액", width: "6%" },
    { title: "실적 직원", width: "6%" },
    { title: "급여\n승인여부", width: "5%" },
    { title: "", width: "6%" },
    { title: "", width: "5%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
