import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import TableTitleWithButton from "components/TableTitleWithButton/TableTitleWithButton";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loadBranchView, loadBranchViewReset } from "../Module/actions";

const BranchManagement = ({ onClickOpenModal, hqBaseData }) => {
  const navigate = useNavigate();
  const restBranchList = useSelector((state) => state.hq_set_state.hq_branch_set_state);

  const storesDispatch = useDispatch();

  useEffect(() => {
    storesDispatch(loadBranchView(hqBaseData.hq_info.hq_id));
    return () => {
      storesDispatch(loadBranchViewReset());
    };
  }, [loadBranchView, storesDispatch, hqBaseData]);

  // 지점 별 계약서 관리 페이지 이동
  const onShowContract = (branch_info) => {
    navigate("/tablet_setting", { state: branch_info });
  };

  const onBranchAddEvent = () => {
    const param = {
      title: "지점 추가",
      modalParam: {
        mode: "add",
        hq_info: hqBaseData.hq_info,
        branch_info: { branch_id: "", branch_name: "" },
        refreshStore: viewRefresh,
      },
    };
    onClickOpenModal("지점관리/등록/수정", param);
  };

  const onBranchEditEvent = (branch_info) => {
    const param = {
      title: "지점 정보 수정",
      modalParam: {
        mode: "edit",
        hq_info: hqBaseData.hq_info,
        branch_info: branch_info,
        refreshStore: viewRefresh,
      },
    };
    onClickOpenModal("지점관리/등록/수정", param);
  };

  const viewRefresh = () => {
    storesDispatch(loadBranchView(hqBaseData.hq_info.hq_id));
  };

  const onClickDelete = (branch_id) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="삭제하시겠습니까?" />
          </>
        ),
        // width: "50rem",
        onBtnYes: () => {
          onDeleteBranch(branch_id);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const onDeleteBranch = (branch_id) => {
    HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/hq_set/branch_remove", {
      params: {
        branch_id: branch_id,
      },
    })
      .then((res) => {
        simpleAlert(storesDispatch, "알림", "삭제되었습니다.");
        storesDispatch(loadBranchView(hqBaseData.hq_info.hq_id));
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const onClickMoveToHq = (branch_id) => {
    navigate("/branch_setting/" + branch_id);
  };

  const branchInfoList = new branchInfoTable(
    onShowContract,
    onBranchEditEvent,
    onClickDelete,
    onClickMoveToHq,
  );

  return (
    <div>
      <TableTitleWithButton
        titleLabel={"본부 내 지점 목록"}
        buttonText={"추가"}
        onClickEvent={onBranchAddEvent}
      />
      <CustomTable
        columns_head={branchInfoList.columns_head}
        table_title={branchInfoList.table_title}
        rest_call={branchInfoList.get_data_from_rest}
        row_render={branchInfoList.create_table}
        rest_data={restBranchList.stores.data.contents}
      />
    </div>
  );
};
export default BranchManagement;

class branchInfoTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onParentEditEvent = null;
  onParentRemoveEvent = null;
  onClickDelete = null;
  onClickMoveToHq = null;
  onShowContract = null;
  moveUrl = "/branch_setting/";

  constructor(onShowContract, onBranchEditEvent, onClickDelete, onClickMoveToHq) {
    this.onShowContract = onShowContract;
    this.onParentEditEvent = onBranchEditEvent;
    this.onClickDelete = onClickDelete;
    this.onClickMoveToHq = onClickMoveToHq;
  }

  onEditEvent = (id) => {
    this.onParentEditEvent(id);
  };
  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.hq_info.hq_name}</CustomTableContents>
        <CustomTableContents>{data.branch_no}</CustomTableContents>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.found_date}</CustomTableContents>
        <CustomTableContents $isNumber>{data.manager_cnt + " 명"}</CustomTableContents>
        <CustomTableContents $isNumber>{data.staff_cnt + " 명"}</CustomTableContents>
        <CustomTableContents $isNumber>{data.member_cnt + " 명"}</CustomTableContents>

        <CustomTableContents>
          <IconButton
            type="detail"
            width="2.2rem"
            onClick={() => this.onShowContract(data.branch_info)}
            tooltip="계약서 관리"
          />
        </CustomTableContents>
        <CustomTableContents>
          <IconButton
            type="edit"
            width="2.2rem"
            onClick={() => this.onEditEvent(data.branch_info)}
          />
        </CustomTableContents>
        <CustomTableContents>
          <IconButton
            type="delete"
            width="2.2rem"
            onClick={() => this.onClickDelete(data.branch_info.branch_id)}
          />
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "본부명", width: "16%" },
    { title: "지점번호", width: "8%" },
    { title: "지점명", width: "14%" },
    { title: "오픈일", width: "12%" },
    { title: "관리자 수", width: "9%" },
    { title: "직원 수", width: "9%" },
    { title: "회원 수", width: "9%" },
    { title: "계약서\n관리", width: "7%" },
    { title: "지점정보\n수정", width: "7%" },
    { title: " ", width: "6%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    } else {
      return stateData;
    }
  };
}
