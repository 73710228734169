import CardFixed from "components/Card/CardFixed";
import { simpleAlert, SubLine } from "components/CommonLib/CommonLib";
import EditTableCard from "components/EdiTable/EditTableCard";
import ExchangeElementTable from "components/ExchangeElementTable/ExchangeElementTable";
import Label from "components/LabelInput/Label";
import NumberTextField from "components/LabelInput/NumberTextField";
import Loading from "components/Loading/Loading/Loading";
import ModalBtnModeGroup from "components/ModalBtnModeGroup/ModalBtnModeGroup";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadPolicyView, loadPolicyViewListReset } from "../Module/actions/index";
import post_api from "../Module/api/PostApi";

const PolicyManagement = () => {
  const storesDispatch = useDispatch();

  const restStoreData = useSelector((state) => state.erp_set_state.policy_view_state);
  // console.log(restStoreData);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    storesDispatch(loadPolicyView());
    return () => {
      storesDispatch(loadPolicyViewListReset());
    };
  }, [loadPolicyView, storesDispatch]);

  const onClickSave = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="저장하시겠습니까?" />
          </>
        ),
        // width: "50rem",
        onBtnYes: () => {
          onSaveEvent();
        },
        onBtnNo: () => {},
      }),
    );
  };

  const onSaveEvent = async () => {
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    // console.log(restStoreData.stores.data);

    const formData = new FormData();

    formData.append("setting_info", JSON.stringify(restStoreData.stores.data));

    setLoading(true);

    await HttpInstance.post(post_api.updatePolicy(), formData, config)
      .then((res) => {
        // console.log(res);
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          storesDispatch(loadPolicyView());
          storesDispatch(
            globalAlertOn({
              show: true,
              titleText: "알림",
              bodyText: (
                <>
                  <Label labelText="저장되었습니다." />
                </>
              ),
              // width: "50rem",
              onBtnOk: () => {},
            }),
          );
        }
      })
      .catch((err) => {
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
        storesDispatch(loadPolicyView());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      {loading && <Loading />}

      <ModalBtnModeGroup mode={"edit"} onClickEvent={onClickSave} />
      <div
        style={{
          display: "flex",
        }}
      >
        <SubTitle titleText={"구독형 정기결제일"} marginRight="4rem" />

        <NumberTextField
          isMarginTop="false"
          labelMarginRight={"0.512rem"}
          labelWidth={"1.813rem"}
          textMarginRight="0.25rem"
          textBoxWidth={"5.5rem"}
          labelText="매월"
          minValue={1}
          maxValue={28}
          endAdornment="일"
          defaultValue={restStoreData.stores.data.regular_pay_date}
          onChangeCallback={(e) => {
            restStoreData.stores.data.regular_pay_date = e;
          }}
        />
      </div>
      <SubLine />

      <div
        style={{
          display: "flex",
        }}
      >
        <SubTitle titleText={"지점연동 횟수"} marginRight="5.725rem" />

        <NumberTextField
          isMarginTop="false"
          labelMarginRight={"0.512rem"}
          labelWidth={"1.813rem"}
          textMarginRight="0.25rem"
          textBoxWidth={"5.5rem"}
          labelText="월"
          isNumber={true}
          minValue={1}
          maxValue={28}
          endAdornment="회"
          defaultValue={restStoreData.stores.data.branch_link_cnt}
          onChangeCallback={(e) => {
            restStoreData.stores.data.branch_link_cnt = e;
          }}
        />
      </div>
      <SubLine />

      <div
        style={{
          display: "flex",
        }}
      >
        <SubTitle titleText={"지점변경 금액 "} marginRight="5.725rem" />

        <NumberTextField
          isMarginTop="false"
          labelMarginRight={"0.512rem"}
          labelWidth={"7.813rem"}
          textMarginRight="0.25rem"
          textBoxWidth={"5.5rem"}
          labelText="잔여개월 1개월당 "
          isNumber={true}
          // minValue={1}
          // maxValue={28}
          endAdornment="원"
          defaultValue={restStoreData.stores.data.branch_change_price}
          onChangeCallback={(e) => {
            restStoreData.stores.data.branch_change_price = e;
          }}
        />
      </div>
      <SubLine />

      <div
        style={{
          display: "flex",
        }}
      >
        <SubTitle titleText={"포인트"} marginRight="8.325rem" />
        <div style={{ display: "flex" }}>
          <NumberTextField
            isMarginTop="false"
            isMarginRight="false"
            labelMarginRight={"0.512rem"}
            labelWidth={"8.5rem"}
            textBoxWidth={"5rem"}
            labelText="· 지급 : 총 결제금액의"
            minValue={0}
            maxValue={100}
            endAdornment="%"
            defaultValue={restStoreData.stores.data.point_give_rate}
            onChangeCallback={(e) => {
              restStoreData.stores.data.point_give_rate = e;
            }}
          />
          <Label textAlign={"left"} labelText={"지급"} />

          <NumberTextField
            isMarginTop="false"
            isMarginRight="false"
            labelMarginRight={"0.512rem"}
            labelMarginLeft={"3rem"}
            labelWidth={"9.813rem"}
            textBoxWidth={"5rem"}
            labelText="· 사용한도 : 총 결제금액의"
            minValue={0}
            maxValue={100}
            endAdornment="%"
            defaultValue={restStoreData.stores.data.point_use_rate}
            onChangeCallback={(e) => {
              restStoreData.stores.data.point_use_rate = e;
            }}
          />
          <Label textAlign={"left"} labelText={"이하 사용 가능"} />

          <NumberTextField
            isMarginTop="false"
            isMarginRight="false"
            labelMarginRight={"0.512rem"}
            labelMarginLeft={"3rem"}
            labelWidth={"4.5rem"}
            textBoxWidth={"6rem"}
            labelText="· 사용제한  : "
            minValue={0}
            defaultValue={restStoreData.stores.data.point_min_use}
            onChangeCallback={(e) => {
              restStoreData.stores.data.point_min_use = e;
            }}
          />
          <Label textAlign={"left"} labelText={"포인트 이상일 때 사용 가능"} />
        </div>
      </div>
      <SubLine />

      <SubTitle titleText={"설문 문항 설정"} marginRight="8.325rem" />
      <div style={{ display: "flex" }}>
        <CardFixed
          midDom={
            <EditTableCard
              titleName={"신규등록 가입경로"}
              dataList={restStoreData.stores.data.reg_path_list}
            />
          }
          isLoading={false}
          rootStyle={{
            marginTop: "0.3rem",
            marginRight: "1rem",
            border: "1px solid #e8ebf2",
            // background:
            //   "transparent linear-gradient(117deg, #e8ebf2 0%, #f2f3f7 100%) 0% 0% no-repeat padding-box",
            // boxShadow: "20px 20px 30px #0f296b1f",
          }}
        />

        <CardFixed
          midDom={
            <EditTableCard
              titleName={"재등록 가입경로"}
              dataList={restStoreData.stores.data.re_reg_path_list}
            />
          }
          isLoading={false}
          rootStyle={{
            // width: "80.437rem",
            // height: "74.875rem",
            marginTop: "0.3rem",
            marginRight: "1rem",
            border: "1px solid #e8ebf2",
            // background:
            //   "transparent linear-gradient(117deg, #e8ebf2 0%, #f2f3f7 100%) 0% 0% no-repeat padding-box",
            // boxShadow: "20px 20px 30px #0f296b1f",
          }}
        />

        <CardFixed
          midDom={
            <EditTableCard
              titleName={"운동목적"}
              dataList={restStoreData.stores.data.purpose_list}
            />
          }
          isLoading={false}
          rootStyle={{
            // width: "80.437rem",
            // height: "74.875rem",
            marginTop: "0.3rem",
            border: "1px solid #e8ebf2",
            // background:
            //   "transparent linear-gradient(117deg, #e8ebf2 0%, #f2f3f7 100%) 0% 0% no-repeat padding-box",
            // boxShadow: "20px 20px 30px #0f296b1f",
          }}
        />
      </div>
      <SubLine />

      <SubTitle titleText={"판매 제한 설정"} />
      <div style={{ display: "flex" }}>
        <CardFixed
          midDom={
            <ExchangeElementTable
              titleName={"시간권 옵션 판매 제한"}
              dataListLeft={restStoreData.stores.data.mbshp_time_branch_reject_list}
              dataListRight={restStoreData.stores.data.mbshp_time_branch_list}
              leftHead={"제한"}
              rightHead={"비제한"}
            />
          }
          isLoading={false}
          rootStyle={{
            width: "50%",
            marginTop: "0.3rem",
            marginRight: "1rem",
            borderRadius: "10px",
            backgroundColor: "#fff",
            border: "1px solid #e8ebf2",
            // background:
            // "transparent linear-gradient(117deg, #e8ebf2 0%, #f2f3f7 100%) 0% 0% no-repeat padding-box",
            // boxShadow: "20px 20px 30px #0f296b1f",
          }}
        />
        <CardFixed
          midDom={
            <ExchangeElementTable
              titleName={"패스권 옵션 판매 제한"}
              dataListLeft={restStoreData.stores.data.mbshp_pass_branch_reject_list}
              dataListRight={restStoreData.stores.data.mbshp_pass_branch_list}
              leftHead={"제한"}
              rightHead={"비제한"}
            />
          }
          isLoading={false}
          rootStyle={{
            width: "50%",
            marginTop: "0.3rem",
            marginRight: "1rem",
            borderRadius: "10px",
            backgroundColor: "#fff",
            border: "1px solid #e8ebf2",
            // background:
            //   "transparent linear-gradient(117deg, #e8ebf2 0%, #f2f3f7 100%) 0% 0% no-repeat padding-box",
            // boxShadow: "20px 20px 30px #0f296b1f",
          }}
        />
      </div>
    </div>
  );
};
export default PolicyManagement;
