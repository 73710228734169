import { useDispatch } from "react-redux";
import HistoryInfo from "./CardContents/HistoryInfo";
import MembershipInfo from "./CardContents/MembershipInfo";
import PersonalInfo from "./CardContents/PersonalInfo";
import PtInfo from "./CardContents/PtInfo";
import UnPaidInfo from "./CardContents/UnPaidInfo";

import { globalModalOn } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import AttendenceOther from "./CardContents/AttendenceOther";
import MemberViewModalIndex from "./Modal/MemberViewModalIndex";

const MemberViewContents = ({ baseInfoState, itemList, memberId }) => {
  const storesDispatch = useDispatch();
  const [hasSubsPayment, setHasSubsPayment] = useState(true);

  useEffect(() => {
    setHasSubsPayment(checkHasSubsPayment());
  }, [baseInfoState]);

  // 구독형 회원권이 있고, 정기결제수단이 없으면 false
  const checkHasSubsPayment = () => {
    if (baseInfoState.membership_data_list.length > 0) {
      const subsIdx = baseInfoState.membership_data_list.findIndex(
        (x) => x.membership_list.mbshp_type === "구독형",
      );
      if (subsIdx >= 0) {
        if (baseInfoState.purchase_info.subs_member_info.memberId === "") {
          return false;
        }
      }
    }
    return true;
  };

  const onClickOpenModal = (order, param, onModalDone) => {
    if (order !== "") {
      openModal(order, param, onModalDone);
    }
  };

  const openModal = (select, param, onModalDone) => {
    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,
        Content: (
          <MemberViewModalIndex modalSelect={select} modalParam={param} onModalDone={onModalDone} />
        ),
      }),
    );
  };

  return (
    <div>
      <PersonalInfo
        baseInfoState={baseInfoState}
        memberId={memberId}
        onClickOpenModal={onClickOpenModal}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          marginTop: "0.625rem",
        }}
      >
        <MembershipInfo
          onClickOpenModal={onClickOpenModal}
          itemList={itemList} //회원의 멤버쉽 이름리스트
          baseInfoState={baseInfoState}
          membershipDataList={baseInfoState.membership_data_list}
          memberId={memberId}
          hasSubsPayment={hasSubsPayment}
        />

        <div
          style={{
            display: "flex",
            width: "40%",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <PtInfo baseInfoState={baseInfoState} />
          <UnPaidInfo
            baseInfoState={baseInfoState}
            onClickOpenModal={onClickOpenModal}
            memberId={memberId}
          />
          <AttendenceOther
            baseInfoState={baseInfoState}
            onClickOpenModal={onClickOpenModal}
            memberId={memberId}
          />
        </div>
      </div>
      <HistoryInfo
        baseInfoState={baseInfoState}
        memberId={memberId}
        onClickOpenModal={onClickOpenModal}
      />
    </div>
  );
};
export default MemberViewContents;
