import { put, call } from "redux-saga/effects";
// import get_api from "../../../../../lib/LockerSettings/GetApi";
import get_api from "../api/GetApi";
import { classManageAction } from "../actions/index";
import { globalStartLoading, globalFinishLoading } from "modules/actions/Loading/index";

export function* getSearchOtManageList(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.searchOtManageList, param);

    if (data.data === null) {
      yield put(classManageAction.loadSearchOtManageListSuccess(false));
    } else {
      yield put(classManageAction.loadSearchOtManageListSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(classManageAction.loadSearchOtManageListFail(error));
    yield put(globalFinishLoading());
  }
}

export function* getSearchPtManageList(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.searchPtManageList, param);

    if (data.data === null) {
      yield put(classManageAction.loadSearchPtManageListSuccess(false));
    } else {
      yield put(classManageAction.loadSearchPtManageListSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(classManageAction.loadSearchPtManageListFail(error));
    yield put(globalFinishLoading());
  }
}

export function* getPtDetailInfoData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getPtDetailInfo, param);

    if (data.data === null) {
      yield put(classManageAction.loadPtDetailInfoSuccess(false));
    } else {
      yield put(classManageAction.loadPtDetailInfoSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(classManageAction.loadPtDetailInfoFail(error));
    yield put(globalFinishLoading());
  }
}

export function* getClassByStaffInfoData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getClassByStaffInfo, param);

    if (data.data === null) {
      yield put(classManageAction.loadClassByStaffSuccess(false));
    } else {
      yield put(classManageAction.loadClassByStaffSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(classManageAction.loadClassByStaffFail(error));
    yield put(globalFinishLoading());
  }
}
