import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import { loadMemberViewStore } from "pages/ERP/MemberView/Module/actions";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { lockerDetailInit } from "../Module/reducers/init_state";
import LockerBrokenModal from "./LockerBrokenModal";
import LockerPwChangeModal from "./LockerPwChangeModal";
import LockerSelectModal from "./LockerSelectModal";

const LockerDetailModal = ({ modalParam, onModalDone }) => {
  //modalParam : branch_id, floor_id, zone_id, floor_name, zone_name, locker_total_idx, locker_no
  // navigate, isFromMemPage,
  // refreshView(isFromMemPage: false일때만 필요)
  // memberId(isFromMemPage: true일때만 필요)

  const storesDispatch = useDispatch();
  const viewModelRef = useRef();
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });
  const [lockerDetailInfo, setLockerDetailInfo] = useState(_.cloneDeep(lockerDetailInit));
  const [lockerHistList, setLockerHistList] = useState([]);
  const [curPage, setCurPage] = useState();
  const [totalPage, setTotalPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const zone_gender = useRef("");

  //락커상세정보 GET
  const getLockerDetailInfo = async (zoneId, lockerTotalIdx) => {
    try {
      setLoading(true);

      const res = await HttpInstance.get(
        process.env.REACT_APP_BRANCH_SERVER + "/locker/get_detail",
        {
          params: {
            zone_id: zoneId,
            locker_total_idx: lockerTotalIdx,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        zone_gender.current = res.data.zone_gender;
        setLockerDetailInfo(res.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  //락커사용내역 GET
  const getLockerHistory = async (page, zoneId, totalIdx) => {
    setCurPage(page);
    try {
      setLoading(true);

      const res = await HttpInstance.get(
        process.env.REACT_APP_BRANCH_SERVER + "/locker/hist_list",
        {
          params: {
            page_num: curPage,
            zone_id: zoneId !== undefined ? zoneId : modalParam.zone_id,
            locker_total_idx: totalIdx !== undefined ? totalIdx : modalParam.locker_total_idx,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setLockerHistList(res.data.data);
        setTotalPage(res.data.total_page);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  //대기상태변경 POST
  const changeLockerStatus = async () => {
    try {
      const formData = new FormData();
      formData.append("zone_id", modalParam.zone_id);
      formData.append("locker_total_idx", modalParam.locker_total_idx);
      formData.append("zone_name", modalParam.zone_name);
      formData.append("locker_no", modalParam.locker_no);
      formData.append("member_info", JSON.stringify(lockerDetailInfo.member_info));

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_BRANCH_SERVER + "/locker/do_wait_status",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: <Label labelText="대기상태로 변경되었습니다." />,
            onBtnOk: () => {
              if (!modalParam.isFromMemPage) {
                //락커페이지에서 왔을땐, 섹션2 구락리 갱신
                modalParam.refreshView(
                  modalParam.branch_id,
                  modalParam.floor_id,
                  modalParam.zone_id,
                );
              } else {
                // 회원에서 왔을땐, 회원정보 갱신
                storesDispatch(loadMemberViewStore(modalParam.memberId));
                // 락커상세전체 갱신
                getLockerDetailInfo(modalParam.zone_id, modalParam.locker_total_idx);
                getLockerHistory(1);
              }
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  //수리완료 POST
  const completeRepairLocker = async () => {
    try {
      const formData = new FormData();
      formData.append("zone_id", modalParam.zone_id);
      formData.append("member_info", JSON.stringify(lockerDetailInfo.member_info));
      formData.append("locker_total_idx", modalParam.locker_total_idx);

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_BRANCH_SERVER + "/locker/repair_done",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label labelText="정상적으로 처리되었습니다." />
              </>
            ),
            onBtnOk: () => {
              getLockerDetailInfo(modalParam.zone_id, modalParam.locker_total_idx); //락커상세 갱신신
              getLockerHistory(1); //상세이력 갱신

              //락커에서 들어왔을때만(ONLY)
              if (!modalParam.isFromMemPage) {
                modalParam.refreshView(
                  modalParam.branch_id,
                  modalParam.floor_id,
                  modalParam.zone_id,
                ); //섹션2 구락리 갱신
              }
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  //1. 비번변경모달 POP
  const popChangePwModal = (title, param, localModalDone) => {
    setModalInfo({
      show: true,
      title: title,
      Content: <LockerPwChangeModal modalParam={param} onModalDone={localModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  //2. 고장락커등록모달 POP
  const popRegisterBrokenModal = (title, param, localModalDone) => {
    setModalInfo({
      show: true,
      title: title,
      Content: <LockerBrokenModal modalParam={param} onModalDone={localModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  //1-1. 비번변경모달 DONE
  //2-1. 고장락커등록모달 DONE
  const refreshDetailViewAll = () => {
    if (!modalParam.isFromMemPage) {
      //락커페이지(ONLY) -> 섹션2 구락리 갱신
      modalParam.refreshView(modalParam.branch_id, modalParam.floor_id, modalParam.zone_id);
    }
    getLockerDetailInfo(modalParam.zone_id, modalParam.locker_total_idx); //락커상세 갱신
    getLockerHistory(1); //락커상세 이력 갱신
    viewModelRef.current.setClose(); //모달닫기
  };

  //3. 고장수리완료 MSG
  const doneRepairAlert = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: <Label labelText="수리 완료 처리 하시겠습니까?" />,
        onBtnYes: () => {
          completeRepairLocker();
        },
        onBtnNo: () => {},
      }),
    );
  };

  //4. 대기상태변경 MSG
  const changeStatusToWaitAlert = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="대기상태로 변경하시겠습니까?" />
            <Label labelText="대기 상태로 변경 시 회원의 배정 정보는 삭제됩니다." />
            <Label labelText="(해당 락커가 배정되어 있는 모든 회원권에서 배정취소 됩니다.)" />
          </>
        ),
        onBtnYes: () => {
          changeLockerStatus();
        },
        onBtnNo: () => {},
      }),
    );
  };

  //5.락커이동모달 POP
  const popAssignLockerModal = (title, param, onModalDone) => {
    setModalInfo({
      show: true,
      title: title,
      Content: <LockerSelectModal modalParam={param} onModalDone={onModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  //5-1. 락커이동모달 DONE
  const lockerMoveModalDone = (new_locker) => {
    if (!modalParam.isFromMemPage) {
      //락커에서 들어왔을때 구락리 갱신
      modalParam.refreshView(modalParam.branch_id, modalParam.floor_id, modalParam.zone_id);
    } else {
      //회원메인 갱신
      storesDispatch(loadMemberViewStore(modalParam.isFromMemPage && modalParam.memberId));
    }
    //락커상세 전체갱신
    getLockerDetailInfo(new_locker.zone_id, new_locker.locker_total_idx);
    getLockerHistory(1, new_locker.zone_id, new_locker.locker_total_idx);
    viewModelRef.current.setClose();
  };

  useEffect(() => {
    if (modalParam) {
      getLockerDetailInfo(modalParam.zone_id, modalParam.locker_total_idx);
      getLockerHistory(1);
    }
  }, [modalParam]);

  useEffect(() => {
    if (curPage) {
      getLockerHistory(curPage);
    }
  }, [curPage]);

  const historyInfoView = new useHistoryTable();

  return (
    <>
      {loading && <Loading />}

      {modalParam && (
        <div>
          <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
          {/* 파트1 */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex" }}>
              <IconButton type="border" />
              <div style={{ width: "0.5rem" }}></div>
              <SubTitle titleText="락커 정보" />
            </div>

            <div>
              <div style={{ display: "flex", marginBottom: "0.5rem" }}>
                <TextField
                  labelMarginLeft="1rem"
                  labelWidth={"3.5rem"}
                  textBoxWidth={"9rem"}
                  labelText="번호"
                  disabled
                  defaultValue={lockerDetailInfo.locker_no}
                />
                <TextField
                  labelMarginLeft="1.2rem"
                  labelWidth={"3.5rem"}
                  textBoxWidth={"9rem"}
                  labelText="구역명"
                  disabled
                  defaultValue={modalParam.zone_name}
                />
                <TextField
                  labelMarginLeft="1.2rem"
                  labelWidth={"3.5rem"}
                  textBoxWidth={"9rem"}
                  labelText="성별"
                  disabled
                  defaultValue={zone_gender.current}
                />
                <div style={{ display: "flex", width: "25%" }}>
                  <TextField
                    labelMarginLeft="1.2rem"
                    labelWidth={"3.5rem"}
                    textBoxWidth={"4.5rem"}
                    labelText="비밀번호"
                    textMarginRight="0.4rem"
                    disabled
                    defaultValue={lockerDetailInfo.password}
                  />
                  {lockerDetailInfo.status === "만료" ? (
                    ""
                  ) : (
                    <NormalBtn
                      name="변경"
                      theme="white"
                      disabled={!lockerDetailInfo.member_info.member_name}
                      onClick={() => {
                        popChangePwModal(
                          "비밀번호 변경",
                          {
                            zone_id: modalParam.zone_id,
                            locker_total_idx: modalParam.locker_total_idx,
                            member_info: lockerDetailInfo.member_info,
                          },
                          refreshDetailViewAll,
                        );
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div>
              <div style={{ display: "flex" }}>
                <TextField
                  labelMarginLeft="1rem"
                  labelWidth={"3.5rem"}
                  textBoxWidth={"9rem"}
                  labelText="상태"
                  disabled
                  defaultValue={lockerDetailInfo.status}
                />

                {lockerDetailInfo.status === "고장" ? (
                  <>
                    <TextField
                      labelMarginLeft="1.2rem"
                      labelWidth={"3.5rem"}
                      textBoxWidth={"9rem"}
                      labelText="고장일"
                      disabled
                      defaultValue={lockerDetailInfo.broken_date}
                    />
                    <TextField
                      labelMarginLeft="1.2rem"
                      labelWidth={"3.5rem"}
                      textBoxWidth={"9rem"}
                      labelText="고장사유"
                      disabled
                      defaultValue={lockerDetailInfo.broken_desc}
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          {/* 파트2 */}
          <div style={{ marginTop: "1.5rem" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex" }}>
                <IconButton type="border" />
                <div style={{ width: "0.5rem" }}></div>
                <SubTitle titleText="현재 사용 회원" />
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}>
                  <TextField
                    labelMarginLeft="1rem"
                    labelWidth={"3.5rem"}
                    textBoxWidth={"9rem"}
                    labelText="회원번호"
                    disabled
                    defaultValue={lockerDetailInfo.member_info.member_no}
                  />
                  <TextField
                    labelMarginLeft="1.2rem"
                    labelWidth={"3.5rem"}
                    textBoxWidth={"9rem"}
                    labelText="회원명"
                    disabled
                    defaultValue={lockerDetailInfo.member_info.member_name}
                  />
                  <TextField
                    labelMarginLeft="1.2rem"
                    labelWidth={"3.5rem"}
                    textBoxWidth={"9rem"}
                    labelText="연락처"
                    disabled
                    defaultValue={lockerDetailInfo.member_info.phone}
                  />
                </div>
                <div style={{ display: "flex", gap: "0.3rem" }}>
                  {modalParam.isFromMemPage ||
                  lockerDetailInfo.status === "대기" ||
                  !lockerDetailInfo.member_info.member_name ? (
                    ""
                  ) : (
                    <NormalBtn
                      name="회원정보 보기"
                      theme="blue"
                      onClick={() => {
                        //회원정보로 이동
                        modalParam.navigate(
                          "/member_info/" + lockerDetailInfo.member_info.member_id,
                        );
                        storesDispatch(globalModalOff());
                      }}
                    />
                  )}
                  {lockerDetailInfo.status === "대기" ||
                  lockerDetailInfo.status === "고장" ||
                  lockerDetailInfo.status === "만료" ? (
                    ""
                  ) : (
                    <NormalBtn
                      name="락커 이동"
                      theme="white"
                      onClick={() => {
                        popAssignLockerModal(
                          "락커 이동",
                          {
                            mode: "edit",
                            branch_id: modalParam.branch_id,
                            member_info: lockerDetailInfo.member_info,
                            floor_id: modalParam.floor_id,
                            zone_id: modalParam.zone_id,
                            floor_name: modalParam.floor_name,
                            zone_name: modalParam.zone_name,
                            locker_total_idx: lockerDetailInfo.locker_total_idx,
                            locker_no: modalParam.locker_no,
                          },
                          lockerMoveModalDone,
                        );
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* 파트3 */}
          <div style={{ marginTop: "1.5rem" }}>
            <div style={{ display: "flex" }}>
              <IconButton type="border" />
              <div style={{ width: "0.5rem" }}></div>
              <SubTitle titleText="사용 내역" />
            </div>
            <CustomTable
              columns_head={historyInfoView.columns_head}
              table_title={historyInfoView.table_title}
              rest_call={historyInfoView.get_data_from_rest}
              row_render={historyInfoView.create_table}
              rest_data={lockerHistList}
            />
            <CustomPagination curPage={curPage} doSearch={setCurPage} totalCnt={totalPage} />
          </div>

          {/* 파트4 */}
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: "2rem", gap: "0.3rem" }}
          >
            {lockerDetailInfo.status === "대기" || lockerDetailInfo.status === "고장" ? (
              ""
            ) : (
              <NormalBtn
                name="대기상태 변경"
                theme="gray"
                onClick={() => {
                  changeStatusToWaitAlert();
                }}
              />
            )}
            {lockerDetailInfo.status === "만료" ? (
              ""
            ) : (
              <NormalBtn
                name={lockerDetailInfo.status === "고장" ? "수리 완료" : "고장 등록"}
                theme="white"
                // disabled={isExpired()}
                onClick={() => {
                  if (lockerDetailInfo.status === "고장") {
                    doneRepairAlert();
                  } else {
                    popRegisterBrokenModal(
                      "고장 등록",
                      {
                        zone_id: modalParam.zone_id,
                        locker_total_idx: modalParam.locker_total_idx,
                        member_info: lockerDetailInfo.member_info,
                      },
                      refreshDetailViewAll,
                    );
                  }
                }}
              />
            )}
            <NormalBtn
              name="확인"
              onClick={() => {
                onModalDone(); //모달닫기
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default LockerDetailModal;

class useHistoryTable {
  table_title = "";
  btnStyleClass = btnStyles();

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.member_info.from_date}</CustomTableContents>
        <CustomTableContents>{data.member_info.to_date}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_no}</CustomTableContents>
        <CustomTableContents>
          {data.member_info.member_name.length < 6
            ? data.member_info.member_name
            : data.member_info.member_name.slice(0, 6) + "..."}
        </CustomTableContents>
        <CustomTableContents>{data.reg_info.updated_at}</CustomTableContents>
        <CustomTableContents>{data.reg_info.upd_name}</CustomTableContents>
        <CustomTableContentsEND align="center">{data.remark}</CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "사용 시작일", width: "14%" },
    { title: "사용 종료일", width: "14%" },
    { title: "회원번호", width: "14%" },
    { title: "회원명", width: "14%" },
    { title: "변경일", width: "14%" },
    { title: "담당자", width: "14%" },
    { title: "비고", width: "16%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
