import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import { uploadFile } from "components/File/uploadFile";
import FileUploadIcon from "components/LabelInput/FileUploadIcon";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import SmsSentHistModal from "./SubModal/SmsSentHistModal";

const ContractDocumentModal = ({ modalParam }) => {
  const storesDispatch = useDispatch();
  const viewModelRef = useRef();
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });

  const [curPage, setCurPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [docList, setDocList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (modalParam.memberId) {
      getDocumentList(curPage);
    }
  }, [modalParam.memberId]);

  //계약서리스트 GET
  const getDocumentList = async (page) => {
    setCurPage(page);
    setLoading(true);

    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_USER_SERVER + "/member/document/list",
        {
          params: {
            member_id: modalParam.memberId,
            page_num: page,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setDocList(res.data.data);
        setTotalPage(res.data.total_page);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  //파일 다운로드 POST
  const downloadFile = async (data) => {
    try {
      const obj = {
        file_name: data.file_info.file_name,
        url_path: data.file_info.url_path,
      };

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_AUTH_SERVER + "/file/download",
        obj,
        contentTypeJson,
      );
      if (res.data) {
        //성공

        const downloadLink = res.data.data;
        const a = document.createElement("a");
        a.href = downloadLink;
        a.download = data.file_name;
        document.body.appendChild(a);
        a.click();
        setTimeout((_) => {
          window.URL.revokeObjectURL(downloadLink);
        }, 60000);
        a.remove();
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const onClickDelete = (fileId) => {
    simpleAlert(storesDispatch, "알림", "삭제하시겠습니까?", false, undefined, () => {
      deleteFile(fileId);
    });
  };

  const deleteFile = async (fileId) => {
    try {
      setLoading(true);
      const res = await HttpInstance.delete(process.env.REACT_APP_AUTH_SERVER + "/file/delete", {
        params: {
          file_id: fileId,
        },
      });
      if (!res.data.msg) {
        simpleAlert(storesDispatch, "알림", "삭제되었습니다.");
        getDocumentList(curPage);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  const uploadDoneCallback = (response) => {
    if (response.error) {
      simpleAlert(storesDispatch, "ERROR", response.error);
    } else {
      simpleAlert(storesDispatch, "알림", "파일 업로드가 완료되었습니다.");
      getDocumentList(curPage);
    }
  };

  //파일업로드 POST MSG
  const onUploadFile = (data, file) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: <Label labelText={`[${data.file_info.doc_name}] 을(를) 업로드 하시겠습니까?`} />,
        onBtnYes: () => {
          uploadFile(data, file, uploadDoneCallback);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const onOpenSmsListModal = (title, param, localModalDone) => {
    setModalInfo({
      show: true,
      title: title,
      Content: <SmsSentHistModal modalParam={param} onModalDone={localModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const localModalDone = () => {
    viewModelRef.current.setClose();
  };

  const findMemberList = new findMemberInfoTable(
    downloadFile,
    onUploadFile,
    onClickDelete,
    modalParam.isAdmin,
  );

  const onClickShowSmsList = () => {
    onOpenSmsListModal("문자발송 내역", modalParam, localModalDone);
  };

  return (
    <div style={{ width: "60rem" }}>
      {loading && <Loading />}

      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />

      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "1rem" }}>
        {modalParam.isAdmin && (
          <NormalBtn
            theme="blue"
            name="발송 문자 보기"
            onClick={() => {
              onClickShowSmsList();
            }}
          />
        )}
      </div>

      <CustomTable
        columns_head={findMemberList.columns_head}
        table_title={findMemberList.table_title}
        rest_call={findMemberList.get_data_from_rest}
        row_render={findMemberList.create_table}
        rest_data={docList}
      />
      <CustomPagination curPage={curPage} doSearch={getDocumentList} totalCnt={totalPage} />
    </div>
  );
};
export default ContractDocumentModal;

class findMemberInfoTable {
  table_title = "";
  btnStyleClass = btnStyles();
  downloadFile = null;
  onUploadFile = null;
  deleteFile = null;
  isAdmin = false;

  constructor(downloadFile, onUploadFile, deleteFile, isAdmin) {
    this.downloadFile = downloadFile;
    this.onUploadFile = onUploadFile;
    this.deleteFile = deleteFile;
    this.isAdmin = isAdmin;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.purchase_code}</CustomTableContents>
        <CustomTableContents>{data.file_info.reason}</CustomTableContents>
        <CustomTableContents>{data.file_info.doc_name}</CustomTableContents>
        <CustomTableContents>{data.reg_info.reg_name}</CustomTableContents>
        <CustomTableContents>
          {data.file_info.url_path ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <IconButton
                type="download_small"
                onClick={() => {
                  this.downloadFile(data);
                }}
              />
              {this.isAdmin ? (
                <IconButton
                  type="delete"
                  marginLeft="1rem"
                  onClick={() => {
                    this.deleteFile(data.file_info.file_id);
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          ) : (
            <FileUploadIcon
              idx={idx}
              onlyIcon
              small
              onUploadFile={(file) => {
                this.onUploadFile(data, file);
              }}
              hideDownload
            />
          )}
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "등록일시", width: "18%" },
    { title: "구매코드", width: "18%" },
    { title: "사유", width: "18%" },
    { title: "계약서 종류", width: "18%" },
    { title: "담당자", width: "18%" },
    { title: "", width: "10%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
