import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Label from "components/LabelInput/Label";

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
  },
});

const LoginPassword = ({
  password,
  passwordChangeHandler,
  passwordValid,
  onClickLogin,
  idValid,
  id,
}) => {
  const textBoxStyle = {
    width: "100%",
    height: "36px",
    marginRight: "0rem",
    borderRadius: "5px",
    backgroundColor: "transparent",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#fff",
    border: "1px solid rgba(255, 255, 255, 0.6)",
  };

  const hStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "250px",
    marginTop: "10px",
  };

  return (
    <Box style={hStyle}>
      <Label labelText="PW" fontSize="14px" color="#fff" />
      <StyledTextField
        fullWidth
        InputProps={{
          style: textBoxStyle,
        }}
        inputProps={{
          style: {
            padding: "5px 4px",
          },
        }}
        type="password"
        autoComplete="current-password"
        onChange={passwordChangeHandler}
        onBlur={passwordValid}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            if (idValid() && passwordValid()) {
              onClickLogin({ id: id.text, password: password.text });
            }
          }
        }}
      />
    </Box>
  );
};

export default LoginPassword;
