import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import TextField from "components/LabelInput/TextField";
import SubTitle from "components/PageTitle/SubTitle";

const IncentiveByStaffList = ({ mbshpDetailArr, selected }) => {
  const staffListTableItem = new staffListTable();

  const getTotalIncentive = (data) => {
    let totalIncentive = 0;
    data.forEach((element) => {
      totalIncentive += element.incentive.incentive;
    });
    return totalIncentive;
  };

  return (
    <div style={{ marginTop: "1rem" }}>
      <div>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <SubTitle
            titleText={`직원별 회원권 판매 인센티브 상세: ${selected.staffName} ${selected.staffPos}`}
          />
        </div>
      </div>
      <div style={{ marginTop: "0.7rem" }}>
        <div>
          <CustomTable
            columns_head={staffListTableItem.columns_head}
            table_title={staffListTableItem.table_title}
            rest_call={staffListTableItem.get_data_from_rest}
            row_render={staffListTableItem.create_table}
            rest_data={mbshpDetailArr}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.7rem" }}>
          <TextField
            labelWidth={"6rem"}
            textBoxWidth={"9rem"}
            textMarginRight="0rem"
            labelText="인센티브 합계"
            endAdornment={"원"}
            defaultValue={getTotalIncentive(mbshpDetailArr).toLocaleString()}
            isNumber
            disabled
          />
        </div>
      </div>
    </div>
  );
};
export default IncentiveByStaffList;

class staffListTable {
  table_title = "";
  btnStyleClass = btnStyles();

  getMbshpSales = (data) => {
    if (data.sales_info.purchase_refund_total > 0) {
      return -data.sales_info.purchase_refund_total;
    } else {
      return data.sales_info.paid_card_price + data.sales_info.paid_cash_price;
    }
  };

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.sales_info.enroll_reg_date}</CustomTableContents>
        <CustomTableContents>{data.mbshp_info.item_name}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_name}</CustomTableContents>
        <CustomTableContents $isNumber>
          {this.getMbshpSales(data).toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.incentive.incentive.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContentsEND>{data.note}</CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "등록일", width: "16%" },
    { title: "회원권 상품명", width: "16%" },
    { title: "회원번호", width: "16%" },
    { title: "회원명", width: "16%" },
    { title: "회원권 매출", width: "16%" },
    { title: "인센티브", width: "16%" },
    { title: "비고", width: "16%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
