import TextField from "components/LabelInput/TextField";

const PreMembershipStatus = ({ selectedItem }) => {
  const labelWidth = "4.3rem";
  const textBoxWidth = "13.5rem";

  const d = new Date();
  const todayText =
    d.getFullYear() +
    "-" +
    (d.getMonth() + 1) +
    "-" +
    (d.getDate().toString().length < 2 ? "0" + d.getDate().toString() : d.getDate().toString());
  const today = new Date(todayText);
  const mbshp_from = new Date(selectedItem.from_date);
  const mbshp_to = new Date(selectedItem.to_date);

  //잔여일
  //회원권 상태: 수강 -> 오늘 ~ 회원권 종료일
  //회원권 상태: 대기 -> 회원권 시작일 ~ 회원권 종료일
  const remainDays =
    selectedItem.mbshp_status === "수강"
      ? Math.floor(Math.abs((mbshp_to.getTime() - today.getTime()) / (1000 * 60 * 60 * 24))) + 1
      : Math.floor(Math.abs((mbshp_to.getTime() - mbshp_from.getTime()) / (1000 * 60 * 60 * 24))) +
        1;

  const passCntText =
    selectedItem.mbshp_option === "패스권"
      ? `잔여 ${selectedItem.pass_remain_cnt}회 (총 ${selectedItem.pass_total_cnt}회)`
      : "";
  const passTimeText =
    selectedItem.mbshp_option === "시간권"
      ? `${selectedItem.pass_from_time} ~ ${selectedItem.pass_end_time}`
      : "";
  const postponeText = selectedItem.mbshp_option
    ? // ? selectedItem.postpone_total_cnt + "회 중 " + selectedItem.postpone_used_cnt + "회 사용"
      `잔여 ${selectedItem.postpone_total_cnt - selectedItem.postpone_used_cnt}회 (총 ${
        selectedItem.postpone_total_cnt
      }회)`
    : "";

  return (
    <div>
      <TextField
        labelMarginLeft="0.5rem"
        labelWidth={labelWidth}
        textBoxWidth={textBoxWidth}
        labelText="잔여일"
        defaultValue={remainDays}
        disabled
      />
      {selectedItem.mbshp_option === "패스권" ? (
        <TextField
          marginTop="0.5rem"
          labelMarginLeft="0.5rem"
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          labelText="출입횟수"
          defaultValue={passCntText} //"총 60회 (잔여 : 37회)"
          disabled
        />
      ) : (
        ""
      )}
      {selectedItem.mbshp_option === "시간권" ? (
        <TextField
          marginTop="0.5rem"
          labelMarginLeft="0.5rem"
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          labelText="출입시간"
          defaultValue={passTimeText}
          disabled
        />
      ) : (
        ""
      )}
      {selectedItem.mbshp_option === "일일권" ? (
        ""
      ) : (
        <TextField
          labelMarginLeft="0.5rem"
          marginTop="0.5rem"
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          labelText="연기"
          defaultValue={postponeText}
          disabled
        />
      )}
    </div>
  );
};
export default PreMembershipStatus;
