import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, generateObjectDummyId } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import DatePicker from "components/LabelInput/DatePicker";
import TextField from "components/LabelInput/TextField";
import TableTitleWithButton from "components/TableTitleWithButton/TableTitleWithButton";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

const AwardCard = forwardRef((props, ref) => {
  const [itemInfoList, setItemInfoList] = useState(props.staffInfoState.award);

  useEffect(() => {
    setItemInfoList(props.staffInfoState.award);
  }, [props.staffInfoState.award]);

  useImperativeHandle(ref, () => ({
    getItemList: () => {
      return itemInfoList;
    },
  }));

  const btnItemPush = () => {
    // const methodId = uuidv4();
    let lenList = itemInfoList.length;
    const baseItemInfo = {
      id: generateObjectDummyId(),
      idx: lenList,
      award_name: "",
      award_date: "",
      award_inst: "",
    };

    if (itemInfoList === []) {
      setItemInfoList([baseItemInfo]);
    } else {
      setItemInfoList([...itemInfoList, baseItemInfo]);
    }

    props.staffInfoState.award.push(baseItemInfo);
  };

  const btnItemPop = (targetId) => {
    var array = [...itemInfoList]; // make a separate copy of the array
    const index = array.map((item) => item.id).indexOf(targetId);

    if (index !== -1) {
      array.splice(index, 1);
      props.staffInfoState.award.splice(index, 1);
      setItemInfoList(array);
    }
  };

  const itemList = new itemInfoTable(btnItemPop);

  return (
    <div style={{ padding: "0.625rem 1.25rem", border: "1px solid #bbb", marginTop: "1rem" }}>
      <div style={{}}>
        <TableTitleWithButton
          titleLabel={"수상 이력"}
          buttonText={"추가"}
          onClickEvent={btnItemPush}
        />
      </div>
      <div>
        <CustomTable
          columns_head={itemList.columns_head}
          table_title={itemList.table_title}
          rest_call={itemList.get_data_from_rest}
          row_render={itemList.create_table}
          rest_data={itemInfoList}
        />
      </div>
    </div>
  );
});
export default AwardCard;

class itemInfoTable {
  table_title = "";
  btnStyleClass = btnStyles();
  // refundInfoList = [];
  btnPop = null;

  constructor(btnPop) {
    this.btnPop = btnPop;
  }

  create_table = (data, key) => {
    return (
      <CustomRow key={key} style={{ width: "100%" }}>
        <CustomTableContents width="10%">
          <TextField
            justifyContent="center"
            textBoxWidth={"24rem"}
            labelMarginLeft={"0rem"}
            textMarginRight={"0rem"}
            defaultValue={data.award_name}
            onChangeCallback={(e) => {
              data.award_name = e;
            }}
          />
        </CustomTableContents>

        <CustomTableContents width="10%">
          <DatePicker
            justifyContent="center"
            minWidth={"12rem"}
            labelText=""
            defaultValue={data.award_date}
            onChangeCallback={(e) => {
              data.award_date = e;
            }}
          />
        </CustomTableContents>
        <CustomTableContents width="10%">
          <TextField
            justifyContent="center"
            textBoxWidth={"24rem"}
            labelMarginLeft={"0rem"}
            textMarginRight={"0rem"}
            defaultValue={data.award_inst}
            onChangeCallback={(e) => {
              data.award_inst = e;
            }}
          />
        </CustomTableContents>
        <CustomTableContents width="5%">
          {/* {data.remove} */}
          {data.is_lock ? (
            <Button
              className={clsx({
                [this.btnStyleClass.btnRootRaw]: true,
                [this.btnStyleClass.buttonType2]: true,
              })}
              onClick={() => this.btnPop(data.id)}
            >
              삭제
            </Button>
          ) : (
            ""
          )}
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "수상명", field: "select" },
    { title: "수상일", field: "pay_method" },
    { title: "수상처", field: "pay_method" },
    { title: "", field: "remove" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
