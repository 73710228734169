import styled from "styled-components";

const TitleDiv = styled.div`
  text-align: left;
  margin-bottom: 20px;
`;

const MenuTitle = styled.span`
  width: 83px;
  height: 29px;
  font-family: AppleSDGothicNeo;
  font-size: 1.625rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #000;
`;

const PageTitle = ({ titleText }) => {
  return (
    <TitleDiv>
      <MenuTitle>{titleText}</MenuTitle>
    </TitleDiv>
  );
};
export default PageTitle;
