import * as ActionTypes from "../actions/action_type";
import { cardInfoInitState } from "./card_info_init_state";
const initialStete = {
  stores: {
    data: [],
  },
};
export const card_search_result = (state = initialStete, action) => {
  switch (action.type) {
    case ActionTypes.CardSearchSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.CardSearchFailAction:
    case ActionTypes.CardSearchResetAction:
      return { ...state, stores: initialStete.stores };
    default:
      return state;
  }
};

export const card_detail_info = (state = cardInfoInitState, action) => {
  switch (action.type) {
    case ActionTypes.CardDetailInfoSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.CardDetailInfoFailAction:
    case ActionTypes.CardDetailInfoResetAction:
      return { ...state, stores: cardInfoInitState.stores };
    default:
      return state;
  }
};

export const card_list_info = (state = initialStete, action) => {
  switch (action.type) {
    case ActionTypes.CardListSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.CardListFailAction:
    case ActionTypes.CardListResetAction:
      return { ...state, stores: initialStete.stores };
    default:
      return state;
  }
};
