import CardFixed from "components/Card/CardFixed";
import { restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DeviceManageListView from "./DeviceManageListView";
import DeviceManageScheduleListView from "./DeviceManageScheduleListView";

const DeviceManageView = ({ onClickOpenModal, hqBranchData, branchList }) => {
  const storesDispatch = useDispatch();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    getAuthData();
  }, []);

  const getAuthData = async () => {
    await HttpInstance.get(process.env.REACT_APP_AUTH_SERVER + "/global/perm_auth", {})
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setIsAdmin(res.data.data.is_admin);
        }
      })
      .catch((err) => {
        console.log("err");
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  return (
    <div>
      <CardFixed
        topDom={<div>출입 장치 관리</div>}
        midDom={
          <div>
            <DeviceManageListView
              onClickOpenModal={onClickOpenModal}
              hqBranchData={hqBranchData}
              branchList={branchList}
              isAdmin={isAdmin}
            />
          </div>
        }
        isLoading={false}
        rootStyle={{
          width: "100%",
          marginTop: "0.625rem",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />

      <CardFixed
        topDom={<div>출입 스케줄 관리</div>}
        midDom={
          <div>
            <DeviceManageScheduleListView
              onClickOpenModal={onClickOpenModal}
              hqBranchData={hqBranchData}
              isAdmin={isAdmin}
            />
          </div>
        }
        isLoading={false}
        rootStyle={{
          width: "100%",
          marginTop: "0.625rem",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />
    </div>
  );
};
export default DeviceManageView;
