import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getContractTableAPi } from "./RestApi/getApi";

const ContractList = ({ selectedBranchInfo: branch_info }) => {
  const storesDispatch = useDispatch();
  const [dataList, setDataList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getContractTableAPi(storesDispatch, branch_info.branch_id, setDataList);
  }, [branch_info]);

  // 계약서 수정 디테일 페이지 이동
  const goToDetail = (contractId) => {
    navigate(`/contract/update/${contractId}`, { state: branch_info });
  };

  const searchResultList = new searchResultTable(goToDetail);

  return (
    <div>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={dataList}
      />
    </div>
  );
};
export default ContractList;

class searchResultTable {
  table_title = "";
  onParentClickEvent = null;
  constructor(goToDetailView) {
    this.onParentClickEvent = goToDetailView;
  }
  parseText = (text) => {
    return text.substr(0, 10) + "\n" + text.substr(11, 8);
  };
  onClickEvent = (data) => {
    this.onParentClickEvent(data.contract_id);
  };

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx} onClick={() => this.onClickEvent(data)} hover>
        <CustomTableContents>{data.reason}</CustomTableContents>
        <CustomTableContents>{data.contract_name}</CustomTableContents>
        <CustomTableContents>{data.reg_info.updated_at}</CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "연결 위치", width: "7%" },
    { title: "계약서", width: "9%" },
    { title: "변경일", width: "10%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
