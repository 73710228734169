// 표준 값
export const hq_branch_search_init_state = {
  branch_list: [[]],
  hq_list: [],
  target_list: [
    {
      branch_list: [
        {
          branch_id: "",
          branch_name: "",
        },
      ],
      hq_info: {
        hq_id: "",
        hq_name: "",
      },
    },
  ],
};

export const branch_search_init_state = {
  branch_list: [{ branch_id: "", branch_name: "" }],
  branch_name_list: [""],
};
