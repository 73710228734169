import { restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

export const searchCMSMemberInfoHistAPi = async (
  storesDispatch,
  // searchParam,
  memberId,
  stateSetter,
  pageSetter,
  page_num,
) => {
  // console.log(searchParam);
  try {
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    const res = await HttpInstance.get(
      process.env.REACT_APP_SUBS_PAY_SERVER + "/cms/method/hist/" + page_num,
      // searchParam,
      {
        params: {
          member_id: memberId,
        },

        // search_param: searchParam,
        // searchParam,
      },
      config,
    );
    // console.log(memberId);
    // console.log(res.data);
    if (!restEmpty(res.data.data)) {
      stateSetter(res.data.data);
    }
    if (!restEmpty(res.data.total_page)) {
      pageSetter(res.data.total_page);
    }
  } catch (err) {
    console.log(err);
    simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
  }
};

export const getCMSMemberInfoAPi = async (storesDispatch, memberId, stateSetter) => {
  try {
    if (memberId !== "") {
      // console.log(process.env.REACT_APP_BRANCH_SERVER + "/board/" + postId + "/type/" + boardType);
      const res = await HttpInstance.get(
        process.env.REACT_APP_SUBS_PAY_SERVER + "/cms/member/" + memberId,
      );
      console.log(res.data);
      console.log(res.data);
      if (!restEmpty(res.data.data)) {
        stateSetter(res.data.data);
      }
    }
  } catch (err) {
    console.log(err);
    simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
  }
};

export const searchCMSPaidHistAPi = async (
  storesDispatch,
  searchParam,
  // memberId,
  stateSetter,
  pageSetter,
  page_num,
) => {
  // console.log(searchParam);
  try {
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    const res = await HttpInstance.get(
      process.env.REACT_APP_SUBS_PAY_SERVER + "/cms/payment/hist/" + page_num,
      searchParam,
      // {
      //   params: {
      //     member_id: memberId,
      //   },
      //   // search_param: searchParam,
      //   // searchParam,
      // },
      config,
    );
    // console.log(res.data);
    if (!restEmpty(res.data.data)) {
      stateSetter(res.data.data);
    }
    if (!restEmpty(res.data.total_page)) {
      pageSetter(res.data.total_page);
    }
  } catch (err) {
    console.log(err);
    simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
  }
};



export const getCMSPayPlanStateAPi = async (
  storesDispatch,
  searchParam,
  // memberId,
  stateSetter,
) => {
  // console.log(searchParam);
  try {
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    const res = await HttpInstance.get(
      process.env.REACT_APP_SUBS_PAY_SERVER + "/cms/payment/state",
      searchParam,
      // {
      //   params: {
      //     member_id: memberId,
      //   },
      //   // search_param: searchParam,
      //   // searchParam,
      // },
      config,
    );
    // console.log(res.data);
    if (!restEmpty(res.data.data)) {
      stateSetter(res.data.data);
    }
    
  } catch (err) {
    console.log(err);
    simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
  }
};
