import { forwardRef, useEffect, useState } from "react";
// import { memberViewAction, loadMemberViewStore } from "./Module/actions";
import { generateObjectDummyId, SubLine } from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import PtClassCard from "./PtClassCard";
import PtCommissionCard from "./PtCommissionCard";

const PtSalaryCard = forwardRef((props, ref) => {
  const { workInfo, positionDetail } = props;
  const [showPtCards, setShowPtCards] = useState(workInfo.is_pt_extra);
  const [isPtExtra, setIsPtExtra] = useState(workInfo.is_pt_extra);
  const ptLabel = "PT 수당";

  useEffect(() => {
    // 근로정보에서만 해당하는 기능
    if (positionDetail) {
      setIsPtExtra(positionDetail.is_pt_extra);
      workInfo.is_pt_extra = positionDetail.is_pt_extra;
      // console.log(positionDetail.is_pt_extra);
    }
  }, [positionDetail]);

  useEffect(() => {
    setIsPtExtra(workInfo.is_pt_extra);
    setShowPtCards(workInfo.is_pt_extra);
  }, [workInfo.is_pt_extra]);

  const getWorkInfoStore = () => {
    for (let i = 0; i < workInfo.pt_commission.length; i++) {
      workInfo.pt_commission[i].id = generateObjectDummyId();
    }
    for (let i = 0; i < workInfo.pt_fee.length; i++) {
      workInfo.pt_fee[i].id = generateObjectDummyId();
    }

    return workInfo;
  };

  return (
    <div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <CheckBox
          marginTop="0rem"
          labelText="PT 수당"
          defaultValue={isPtExtra}
          onChangeCallback={(e) => {
            workInfo.is_pt_extra = e.checked;
            setShowPtCards(e.checked);
            setIsPtExtra(e.checked);
          }}
          disabled={props.pageMode === "보기" || props.pageMode === "이력"}
        />
      </div>

      {/* 이 부분 새로운 탭으로 이동함 */}
      {!showPtCards ? (
        <></>
      ) : props.isHqMenu ? (
        <div
          style={{
            display: "flex",
            marginTop: "0.5rem",
            padding: "1rem",
            border: "1px solid #aaa",
            width: "62rem",
          }}
        >
          <div style={{ width: "30rem" }}>
            <PtCommissionCard workInfoStore={getWorkInfoStore()} />
          </div>
          <div style={{ width: "30rem", marginLeft: "2rem" }}>
            <PtClassCard workInfoStore={getWorkInfoStore()} />
          </div>
        </div>
      ) : (
        <></>
      )}

      <SubLine />
    </div>
  );
});
export default PtSalaryCard;
