import { restEmpty } from "components/CommonLib/CommonLib";
import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { useEffect } from "react";
import { useLocation } from "react-router";
import TMListDetailView from "./TMListDetailView";
import { useTMListDetailStore } from "./modules/store/tmListDetailState";

const TMListDetailService = () => {
  const { state } = useLocation();
  const { tmListDetailSearchParams: searchParams, setSearchParams } = useTMListDetailStore();

  const getDetailData = (presetId) => {
    // HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/cs/tm/hist/search_param", {
    //   params: {},
    // })
    //   .then((res) => {
    //     if (!restEmpty(res.data.data)) {
    //       setSearchParam(res.data.data);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    //   });
  };

  useEffect(() => {
    if (state.row_data && !restEmpty(state.row_data)) {
      const paramsDataList = [
        state.row_data.branch_info,
        state.row_data.reg_info.created_at,
        state.row_data.preset_name,
        state.row_data.preset_id,
      ];
      setSearchParams("useLocationState", paramsDataList);
    }
  }, [state.row_data]);

  return (
    <PageFrame
      children={
        <div>
          <PageTitle titleText="TM 리스트 - 상세보기" />
          <TMListDetailView searchParams={searchParams} setSearchParams={setSearchParams} />
        </div>
      }
    />
  );
};
export default TMListDetailService;
