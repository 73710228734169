// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/AppleSDGothicNeoM.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App {\n  text-align: center;\n  font-size: \"80%\";\n}\n\n@font-face {\n  font-family: \"AppleSDGothicNeo\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n::-webkit-scrollbar {\n  width: 9px;\n  height: 9px;\n}\n\n::-webkit-scrollbar-thumb:horizontal {\n  border-radius: 10px;\n  background: #919191;\n}\n::-webkit-scrollbar-track:horizontal {\n  border-radius: 10px;\n  background: #dadada;\n}\n::-webkit-scrollbar-thumb:vertical {\n  border-radius: 10px;\n  background: #919191;\n}\n::-webkit-scrollbar-track:vertical {\n  background: #dadada;\n}\n", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,+BAA+B;EAC/B,4CAAyC;AAC3C;;AAEA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".App {\n  text-align: center;\n  font-size: \"80%\";\n}\n\n@font-face {\n  font-family: \"AppleSDGothicNeo\";\n  src: url(\"./fonts/AppleSDGothicNeoM.ttf\");\n}\n\n::-webkit-scrollbar {\n  width: 9px;\n  height: 9px;\n}\n\n::-webkit-scrollbar-thumb:horizontal {\n  border-radius: 10px;\n  background: #919191;\n}\n::-webkit-scrollbar-track:horizontal {\n  border-radius: 10px;\n  background: #dadada;\n}\n::-webkit-scrollbar-thumb:vertical {\n  border-radius: 10px;\n  background: #919191;\n}\n::-webkit-scrollbar-track:vertical {\n  background: #dadada;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
