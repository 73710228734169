import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import ComboBox from "components/LabelInput/ComboBox";
import TextArea from "components/LabelInput/TextArea";
import TextField from "components/LabelInput/TextField";
// import { uploadFile } from "./uploadFile";/
import { getStringBytes, simpleAlert } from "components/CommonLib/CommonLib";
import FileUpload from "components/LabelInput/FileUpload";
import Label from "components/LabelInput/Label";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PhoneComp from "./PhoneComp";

const SendMessageComp = ({
  openModal,
  onClickSend,
  onClickReset,
  messageState,
  setMessageState,
  onClickSaveContent,
  onSendDone,
  msgType,
  setMsgType,
  file,
  setFile,
}) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  // const [fileGroupId, setFileGroupId] = useState("");
  const [textSize, setTextSize] = useState(0);
  // const [fileUploaded, setFileUploaded] = useState(false);

  const labelWidth = "5rem";

  // const uploadDoneCallback = (responseData) => {};

  useEffect(() => {
    if (file && file.size > 0) {
      setMsgType("MMS");
    }
  }, [file]);

  return (
    <div>
      <CardFixed
        topDom={<div>메시지 미리보기</div>}
        midDom={
          <div style={{ display: "flex" }}>
            <div style={{ width: "60%", paddingRight: "0.5rem" }}>
              {/* Lane 1 */}
              <div style={{ display: "flex", marginTop: "1rem" }}>
                <ComboBox
                  labelText="발송정보"
                  fullWidth
                  labelWidth={"5.3rem"}
                  labelMarginRight="0rem"
                  marginRight="0.25rem"
                  data={["공지", "광고"]}
                  defaultValue={messageState.sms_type || "공지"}
                  onChangeCallback={(e) => {
                    // messageState.sms_type = e.target.value;
                    setMessageState({
                      ...messageState,
                      sms_type: e.target.value,
                    });
                  }}
                />
                <Button
                  className={clsx({
                    [btnStyleClass.btnRootWhite]: true,
                    [btnStyleClass.buttonType9]: true,
                  })}
                  onClick={() => {
                    openModal("loadContent", { title: "내용 불러오기" });
                  }}
                >
                  내용 불러오기
                </Button>
                <Button
                  style={{ marginLeft: "0.25rem" }}
                  className={clsx({
                    [btnStyleClass.btnRoot]: true,
                    [btnStyleClass.buttonType8]: true,
                  })}
                  onClick={() => {
                    onClickSaveContent();
                  }}
                >
                  내용 저장
                </Button>
              </div>
              {/* Lane 2 */}
              <div style={{ marginTop: "0.5rem" }}>
                <TextField
                  labelText="제목"
                  labelWidth={labelWidth}
                  labelMarginRight="0rem"
                  textMarginRight="0rem"
                  fullWidth
                  defaultValue={messageState.sms_info.sms_name}
                  onChangeCallback={(e) => {
                    // messageState.sms_info.sms_name = e;
                    setMessageState({
                      ...messageState,
                      sms_info: {
                        ...messageState.sms_info,
                        sms_name: e,
                      },
                    });
                  }}
                />
              </div>

              {/* Lane 3 */}
              <div style={{ marginTop: "0.5rem" }}>
                <TextField
                  labelText="머릿말"
                  labelWidth={labelWidth}
                  labelMarginRight="0rem"
                  textMarginRight="0rem"
                  fullWidth
                  defaultValue={messageState.head_line}
                  onChangeCallback={(e) => {
                    // messageState.head_line = e;
                    setMessageState({
                      ...messageState,
                      head_line: e,
                    });
                  }}
                />
              </div>

              {/* Lane 4 */}
              <div style={{ marginTop: "0.5rem" }}>
                <TextArea
                  labelText="내용"
                  labelWidth={labelWidth}
                  marginTop={"0.5rem"}
                  labelMarginRight="0rem"
                  fullWidth
                  minRows={21.8}
                  maxRows={21.8}
                  defaultValue={messageState.contents}
                  onChangeCallback={(e) => {
                    // messageState.contents = e.target.value;
                    setMessageState({
                      ...messageState,
                      contents: e.target.value,
                    });

                    const size = getStringBytes(e.target.value);
                    setTextSize(size);
                    if (size <= 90 && !file) {
                      setMsgType("SMS");
                    } else if (size > 90 && !file) {
                      setMsgType("LMS");
                    } else {
                      setMsgType("MMS");
                    }

                    if (size > 2000) {
                      simpleAlert(
                        storesDispatch,
                        "알림",
                        "메시지는 최대 2,000Byte 까지만 전송이 가능합니다.",
                      );
                    }
                  }}
                />
              </div>

              <div style={{ display: "flex", justifyContent: "right", margin: "0.25rem" }}>
                <Label labelText={textSize.toLocaleString() + " Byte"} />
                <Label labelText={"(" + msgType + ")"} />
              </div>

              {/* Lane 5 */}
              <div style={{ display: "flex", marginBottom: "2rem", marginTop: "1.25rem" }}>
                <FileUpload
                  labelWidth={"3.75rem"}
                  textBoxWidth={"14.5rem"}
                  textMarginRight={"0rem"}
                  defaultValue={file ? file.name : ""}
                  labelText="이미지"
                  onUploadFile={(file) => {
                    const alertSize = 204800;
                    if (file && file.size > alertSize) {
                      simpleAlert(
                        storesDispatch,
                        "알림",
                        "큰 이미지 전송 시 화질이 저하될 수 있습니다.",
                      );
                    }
                    setFile(file);
                    //   params.workInfoStore.files.resign_file.file_name = file.name;
                  }}
                  accept=".gif, .jpg, .jpeg, .png"
                  hideDownload
                />
              </div>

              <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
                <Button
                  className={clsx({
                    [btnStyleClass.btnRootWhite]: true,
                    [btnStyleClass.buttonType6]: true,
                  })}
                  onClick={() => {
                    onClickReset();
                  }}
                >
                  초기화
                </Button>
                <Button
                  style={{ marginLeft: "0.5rem" }}
                  className={clsx({
                    [btnStyleClass.btnRoot]: true,
                    [btnStyleClass.buttonType6]: true,
                  })}
                  onClick={() => {
                    onClickSend();
                  }}
                >
                  발송
                </Button>
              </div>
            </div>

            {/* Phone */}
            <div
              style={{
                width: "40%",
                paddingLeft: "0.5rem",
              }}
            >
              <PhoneComp messageState={messageState} onSendDone={onSendDone} file={file} />
            </div>
          </div>
        }
        isLoading={false}
        rootStyle={{
          // width: "22rem",
          // height: "49rem",
          maxHeight: "51rem",
          borderRadius: "10px",
          padding: "0.5rem 0.5rem",
          backgroundColor: "#fff",
          overflowY: "auto",
        }}
      />
    </div>
  );
};
export default SendMessageComp;
