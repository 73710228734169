import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import TextField from "components/LabelInput/TextField";

import IconButton from "components/Button/IconButton";
import { contentTypeJson, simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useState } from "react";
import { useDispatch } from "react-redux";

const ConnectFamilyAlertModal = ({ modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const connectingFamily = async () => {
    try {
      const formData = new FormData();
      const obj = {
        family_member_info: modalParam.data,
        member_id: modalParam.memberId, //기준회원 아이디
        membership_io: { item_name: modalParam.itemName, membership_id: modalParam.membershipId },
      };

      setLoading(true);

      formData.append("new_info", JSON.stringify(obj));
      const res = await HttpInstance.post(
        process.env.REACT_APP_USER_SERVER + "/member/family/connect",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label labelText="정상적으로 처리되었습니다." />
              </>
            ),
            onBtnOk: () => {
              //모달 닫기 + 회원정보 화면 갱신
              onModalDone();
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        width: "20rem",
      }}
    >
      {loading && <Loading />}

      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="선택된 가족" />
      </div>
      <div style={{ marginLeft: "1rem" }}>
        <TextField
          labelWidth={"2.5rem"}
          textBoxWidth={"13rem"}
          labelText="구분"
          disabled
          defaultValue={modalParam.type}
        />
        <div style={{ height: "0.4rem" }}></div>
        <TextField
          labelWidth={"2.5rem"}
          textBoxWidth={"13rem"}
          labelText="이름"
          disabled
          defaultValue={modalParam.name}
        />
      </div>
      <div style={{ fontSize: "0.8rem" }}>
        <p>
          선택된 가족의 <span style={{ color: "red" }}>출입방식은 앱으로 자동 변경</span>됩니다.
          <br />
          지점도 기준회원의 지점으로 변경됩니다.
        </p>
        <p>
          선택된 가족이 고객인 경우, <span style={{ color: "red" }}>앱에서 추가정보 입력</span>을
          <br />
          완료하여야 가족권 사용이 가능해집니다.
        </p>
      </div>
      <Label labelText="위 회원을 가족으로 연결하시겠습니까?" />

      <div style={{ display: "flex", gap: "0.3rem", justifyContent: "center", marginTop: "1rem" }}>
        <NormalBtn
          name="아니오"
          theme="white"
          onClick={() => {
            modalParam.noBtnEvt();
          }}
        />
        <NormalBtn
          name="예"
          onClick={() => {
            connectingFamily();
          }}
        />
      </div>
    </div>
  );
};
export default ConnectFamilyAlertModal;
