import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import ColorLabel from "components/LabelInput/ColorLabel";
import { useSalaryListStore } from "./modules/store/salaryListState";

const SalaryListSearchResult = ({ navigate, searchResult, totalPage, deleteRow }) => {
  // 전역 상태 관리
  const { setPage, salaryListPage: page } = useSalaryListStore();

  const searchResultList = new searchResultTable(navigate, deleteRow);
  return (
    <div>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
      />
      <CustomPagination curPage={page} doSearch={setPage} totalCnt={totalPage} />
    </div>
  );
};
export default SalaryListSearchResult;

class searchResultTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onNavigate = null;
  onRowDeleteFunc = null;

  constructor(navigate, doDeleteFunc) {
    this.onNavigate = navigate;
    this.onRowDeleteFunc = doDeleteFunc;
  }

  onClickEvent = (data, isEdit) => {
    this.onNavigate("/salary_info/", {
      state: {
        is_edit: isEdit,
        data,
      },
    });
  };
  onRowDelete = (data) => {
    this.onRowDeleteFunc(data);
  };

  deleteDisable = (data) => {
    // console.log(data.approval_status);
    if (
      // data.approval_status !== "" ||
      data.approval_status === "승인대기" ||
      data.approval_status === "승인"
    ) {
      return true;
    } else {
      return false;
    }
  };

  setColorLabel = (value) => {
    let bgColor = "";
    let fontColor = "";
    if (value === "없음") {
      bgColor = "#FFF4D7";
      fontColor = "#FFC737";
    } else if (value === "대기") {
      bgColor = "#FFF4D7";
      fontColor = "#FFC737";
    } else if (value === "승인대기") {
      bgColor = "#FFE1D1";
      fontColor = "#FF6B17";
    } else if (value === "승인") {
      bgColor = "#E4F4E1";
      fontColor = "#109B58";
    } else if (value === "반려") {
      bgColor = "#F8D8D8";
      fontColor = "#DE0909";
    } else if (value === "회수") {
      bgColor = "#E3EBF7";
      fontColor = "#578DDE";
    }
    return <ColorLabel labelText={`${value}`} bgColor={bgColor} fontColor={fontColor} />;
  };

  create_table = (data, idx) => {
    return (
      <CustomRow
        key={idx}
        onClick={(e) => {
          // e.target.tagName : svg = IconButton, TD = table cell
          if (!e.target.type && e.target.tagName === "TD") {
            this.onClickEvent(data);
          }
        }}
        hover
      >
        <CustomTableContents>{data.base_date}</CustomTableContents>
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.approval_info.approve_draft_date}</CustomTableContents>
        <CustomTableContents>{data.hq_info.hq_name}</CustomTableContents>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {this.setColorLabel(data.approval_status)}
          </div>
        </CustomTableContents>
        <CustomTableContents>{data.approval_info.writer.staff_info.staff_name}</CustomTableContents>
        <CustomTableContents>
          {data.approval_info.approval_draft_line.approver &&
            data.approval_info.approval_draft_line.approver.map((item, idx) => {
              if (idx === 0) {
                return item.staff_info.staff_name;
              } else {
                return ", " + item.staff_info.staff_name;
              }
            })}
        </CustomTableContents>
        <CustomTableContents
          $isNumber
          onClick={(e) => {
            this.onClickEvent(data, false);
          }}
        >
          {data.salary_summary_info.salary_total.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContentsEND>
          <div style={{ display: "flex", justifyContent: "center", gap: "0.7rem" }}>
            <IconButton
              type="edit"
              width="2.2rem"
              onClick={() => {
                this.onClickEvent(data, true);
              }}
              tooltip="수정"
              disabled={this.deleteDisable(data)}
            />
            <IconButton
              type="delete"
              width="2.2rem"
              onClick={() => {
                this.onRowDelete(data);
              }}
              tooltip="삭제"
              disabled={this.deleteDisable(data)}
            />
          </div>
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "기준 연월", width: "7%" },
    { title: "생성일시", width: "11%" },
    { title: "최근 기안일", width: "11%" },
    { title: "본부", width: "10%" },
    { title: "지점", width: "10%" },
    { title: "결재 상태", width: "8%" },
    { title: "기안자", width: "10%" },
    { title: "승인자", width: "10%" },
    { title: "급여 합계", width: "10%" },
    { title: "", width: "5%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
