import ChangeBranchView from "./ChangeBranchView";

const ChangeBranchWrapper = ({ serviceViewState, memberBaseInfoState, memberInfo }) => {
  return (
    <ChangeBranchView
      serviceViewState={serviceViewState}
      memberBaseInfoState={memberBaseInfoState}
      memberInfo={memberInfo}
    />
  );
};
export default ChangeBranchWrapper;
