import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import NormalBtn from "components/NewButton/NormalBtn";
import { useNavigate } from "react-router-dom";
import { useExtendMbshpHistoryStore } from "../modules/store/extendMbshpHistoryState";

const ExtendMbshpHistRecordResultTable = ({
  searchResult,
  curPage,
  totalPage,
  setIsGetExtendMbshp,
}) => {
  const navigate = useNavigate();
  const { setPage } = useExtendMbshpHistoryStore();

  // 회원 결과 객체 생성
  const searchResultList = new resultTable(navigate);

  const doSearch = (page) => {
    setPage(page);
    setIsGetExtendMbshp(true);
  };

  return (
    <div>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
      />
      <CustomPagination curPage={curPage} totalCnt={totalPage} doSearch={doSearch} />
    </div>
  );
};
export default ExtendMbshpHistRecordResultTable;

class resultTable {
  table_title = "";

  constructor(navigate) {
    this.navigate = navigate;
  }

  create_table = (data) => {
    return (
      <CustomRow>
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.reg_info.reg_name}</CustomTableContents>
        <CustomTableContents>{data.member_no}</CustomTableContents>
        <CustomTableContents>{data.member_name}</CustomTableContents>
        <CustomTableContents>{data.extend_days}</CustomTableContents>
        <CustomTableContents>{data.extend_type}</CustomTableContents>
        <CustomTableContents>{data.extend_reason}</CustomTableContents>
        <CustomTableContents>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <NormalBtn
              name="회원정보"
              onClick={() => {
                this.navigate(`/member_info/${data.member_id}`);
              }}
            />
          </div>
        </CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "등록일", width: "10%" },
    { title: "지점", width: "6%" },
    { title: "담당자", width: "6%" },
    { title: "회원번호", width: "6%" },
    { title: "회원명", width: "8%" },
    { title: "추가 일수", width: "4%" },
    { title: "추가 구분", width: "6%" },
    { title: "추가 상세 사유", width: "26%" },
    { title: "", width: "0%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
