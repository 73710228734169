import { HttpInstance } from "lib/HttpLib";

export const getSearchCardList = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/access/search_card_list", {
    params: {
      branch_id: param.branch_id,
      card_no: param.card_no,
    },
  });
};

export const getCardInfo = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/access/card_info", {
    params: {
      card_info_id: param.card_info_id,
    },
  });
};
export const getCardList = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/access/card_manage_list", {
    params: {
      search_param: param.search_param,
    },
  });
};

const get_api = {
  getSearchCardList,
  getCardInfo,
  getCardList,
};

export default get_api;
