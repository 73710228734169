import { useState } from "react";

import {
  dataEmpty,
  parseMoneyFormat,
  restEmpty,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import { HttpInstance } from "lib/HttpLib";
import PurchaseDetailModal from "pages/ERP/MemberView/Modal/PurchaseDetail/PurchaseDetailModal";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

const UnPaidHistModal = ({ modalParam, onModalDone }) => {
  const viewModelRef = useRef();
  const storesDispatch = useDispatch();
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });
  const [curPage, setCurPage] = useState();
  const [totalPage, setTotalPage] = useState(0);
  const [unpaidList, setUnpaidList] = useState(modalParam.unpaidList);

  const purchaseDetailModalDone = () => {
    viewModelRef.current.setClose();
  };

  const popPurchaseDetailModal = (param) => {
    setModalInfo({
      show: true,
      title: "구매 상세내역",
      Content: <PurchaseDetailModal modalParam={param} onModalDone={purchaseDetailModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  //미납금 이력 GET

  const getUnpaidList = async (page) => {
    setCurPage(page);

    try {
      const res = await HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/change/unpay_hist", {
        params: {
          member_id: modalParam.memberId,
          page_num: curPage,
        },
      });
      if (!restEmpty(res.data.data)) {
        setUnpaidList(res.data.data);
        setTotalPage(res.data.total_page);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  useEffect(() => {
    getUnpaidList(1);
  }, [modalParam]);

  //페이지네이션
  useEffect(() => {
    if (curPage) {
      getUnpaidList(curPage);
    }
  }, [curPage]);

  const unpayHistList = new unpayHistTable(popPurchaseDetailModal);

  return (
    <div
      style={{
        width: "65rem",
      }}
    >
      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
      <CustomTable
        columns_head={unpayHistList.columns_head}
        table_title={unpayHistList.table_title}
        rest_call={unpayHistList.get_data_from_rest}
        row_render={unpayHistList.create_table}
        rest_data={unpaidList}
      />
      <CustomPagination curPage={curPage} doSearch={setCurPage} totalCnt={totalPage} />
    </div>
  );
};
export default UnPaidHistModal;

class unpayHistTable {
  popPurchaseDetailModal = null;

  constructor(popPurchaseDetailModal) {
    this.popPurchaseDetailModal = popPurchaseDetailModal;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents
          align="center"
          onClick={() => {
            this.popPurchaseDetailModal({
              memberId: data.member_info.member_id,
              purchase_code: data.purchase_code,
            });
          }}
        >
          <span style={{ borderBottom: "1px solid #003CCB", color: "#003CCB", cursor: "pointer" }}>
            {data.purchase_code}
          </span>
        </CustomTableContents>
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.type}</CustomTableContents>
        <CustomTableContents>{data.reg_date}</CustomTableContents>
        <CustomTableContents $isNumber>
          {parseMoneyFormat(data.total_price) + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {parseMoneyFormat(data.paid_price) + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {parseMoneyFormat(data.unpaid_price) + " 원"}
        </CustomTableContents>
        <CustomTableContentsEND align="center">{data.remarks}</CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "구매코드", width: "12%" },
    { title: "등록일", width: "12%" },
    { title: "구분", width: "12%" },
    { title: "반영일", width: "11%" },
    { title: "상품 총액", width: "11%" },
    { title: "납부 완료", width: "11%" },
    { title: "잔여 미납금", width: "11%" },
    { title: "비고", width: "20%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
