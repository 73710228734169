import {
  contentTypeJson,
  getCurrentDate,
  getPermission,
  restEmpty,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import { useGetAuthData } from "hooks/apis/access/useAuth";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loadMemberViewStore, loadMembershipHistoryStore } from "../Module/actions";

const MemberViewActionsWithButton = ({ titleLabel, baseInfoState, memberId, onClickOpenModal }) => {
  const navigate = useNavigate();

  const [canSelectMasterChange, setCanSelectMasterChange] = useState(false);
  const [canSelectAddPeriod, setCanSelectAddPeriod] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const storesDispatch = useDispatch();

  // [GET] 권한 정보 가져오기
  const { data: authData } = useGetAuthData();

  useEffect(() => {
    if (authData) {
      setIsAdmin(authData.data.data.is_admin);
    }
  }, [authData]);

  useEffect(() => {
    getPermission(storesDispatch, "MEMBER_VIEW_MENU/MASTER_CHANGE").then((res) => {
      setCanSelectMasterChange(res);
    });
    getPermission(storesDispatch, "MEMBER_VIEW_MENU/ADD_PERIOD").then((res) => {
      setCanSelectAddPeriod(res);
    });
  }, [memberId]);

  //1. 가족목록 버튼
  const popFamilyListModal = () => {
    const param = {
      title: "가족 목록",
      modalParam: {
        memberId: memberId,
      },
    };
    onClickOpenModal("가족목록", param);
  };

  //2. 지인등록 버튼
  const popAddFriendModal = () => {
    const param = {
      title: "회원 검색",
      modalParam: {
        mode: "friend",
        memberId: memberId,
      },
    };
    onClickOpenModal("회원검색", param, addFriendModalDone);
  };

  const addFriendModalDone = (data) => {
    const info = `${data.branch_info.branch_name} | ${data.member_type} | ${data.member_info.member_name} | ${data.base_info.phone} `;
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText={`다음 회원을 소개자로 등록하시겠습니까?`} />
            <Label labelText={`${info}`} />
          </>
        ),
        onBtnYes: () => {
          //지인등록 POST
          addFriendEvt(data);
        },
        onBtnNo: () => {},
      }),
    );
  };

  //지인등록 POST
  const addFriendEvt = async (data) => {
    try {
      const formData = new FormData();
      formData.append("branch_id", JSON.stringify(data.branch_info.branch_id));
      formData.append("recommender_member_id", JSON.stringify(memberId)); //추천해준사람
      formData.append("member_id", JSON.stringify(data.member_info.member_id)); //추천받은사람

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_USER_SERVER + "/mbshp/recommand/update",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //지인등록 성공
        //추천해준사람 페이지니까 추천해준사람꺼 갱신
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: <Label labelText="지인등록이 완료되었습니다." />,
            onBtnOk: () => {
              storesDispatch(loadMembershipHistoryStore(memberId)); //회원권탭 갱신
              storesDispatch(loadMemberViewStore(memberId)); //회원화면 갱신
              storesDispatch(globalModalOff());
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  //3. 추가결제 버튼
  const popAddPayModal = () => {
    if (
      baseInfoState.purchase_info.unpaid.total_unpaid > 0 &&
      baseInfoState.membership_data_list.length === 0
    ) {
      simpleAlert(storesDispatch, "알림", "미납금 결제 후 추가결제가 가능합니다.");
      return;
    }

    let regDate = getCurrentDate();
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "추가결제",
        bodyText: (
          <DatePicker
            labelWidth="4rem"
            minWidth="12rem"
            labelText="등록일"
            minDate={"1900-01-01"}
            maxDate={getCurrentDate()}
            defaultValue={getCurrentDate()}
            onChangeCallback={(e) => {
              regDate = e;
            }}
          />
        ),
        onBtnOk: () => {
          navigate("/add_pay", {
            state: {
              member_id: memberId,
              branch_id: baseInfoState.branch_info.branch_id,
              branch_name: baseInfoState.branch_info.branch_name,
              memberBaseInfoState: baseInfoState,
              reg_date: regDate !== undefined ? regDate : "",
            },
          });
        },
      }),
    );
  };

  //4. 변경환불 셀렉션 모달
  const popChangeRefundSelectionModal = () => {
    const param = {
      title: "변경/환불",
      modalParam: {
        membership_data_list: baseInfoState.membership_data_list,
        active_info: baseInfoState.active_info,
        base_info: baseInfoState.base_info,
        canSelectMasterChange: canSelectMasterChange,
        canSelectAddPeriod: canSelectAddPeriod,
      },
    };
    onClickOpenModal("변경/환불", param, selectionModalDone);
  };

  const selectionModalDone = (actionInfo) => {
    storesDispatch(globalModalOff());
    if (actionInfo.mode === "href") {
      navigate(actionInfo.action, {
        state: {
          member_id: memberId,
          branch_id: baseInfoState.branch_info.branch_id,
          branch_name: baseInfoState.branch_info.branch_name,
          memberBaseInfoState: baseInfoState,
          reg_date: actionInfo.reg_date !== undefined ? actionInfo.reg_date : "",
        },
      });
    } else {
      //mode === 'modal'
      const param = {
        title: actionInfo.title,
      };
      if (actionInfo.action === "수강정보변경") {
        validationCheckMasterChange(actionInfo);
      } else {
        onClickOpenModal(actionInfo.action, param);
      }
    }
  };

  const validationCheckMasterChange = (actionInfo) => {
    const param = {
      title: actionInfo.title,
    };

    onClickOpenModal(actionInfo.action, param);
  };

  //5. 쿠폰포인트 모달
  const popCouponModal = () => {
    //수정필요
    const param = {
      title: "쿠폰/포인트",
      modalParam: {
        mode: "edit",
        branch_id: baseInfoState.branch_info.branch_id,
        memberId: memberId,
        baseInfoState: baseInfoState,
      },
    };
    onClickOpenModal("쿠폰포인트", param);
  };

  //6. 계약서관리 팝업
  const popContractViewModal = () => {
    const param = {
      title: "계약서관리",
      modalParam: {
        memberId: memberId,
        isAdmin,
      },
    };
    onClickOpenModal("계약서관리", param);
  };

  const doBatch = () => {
    HttpInstance.get(process.env.REACT_APP_BATCH_SERVER + "/daily/mbshp/test", {
      params: {
        member_id: memberId,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          simpleAlert(storesDispatch, "알림", "회원권 배치 처리가 완료되었습니다.");
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  return (
    <div
      style={{
        display: "flex",
        marginBottom: "5rem",
        justifyContent: "space-between",
      }}
    >
      {loading && <Loading />}

      <SubTitle titleText={titleLabel} />
      <div style={{ display: "flex", gap: "0.3rem" }}>
        {/* 0. 회원정보 갱신(배치) */}
        {isAdmin && baseInfoState.member_type === "회원" ? (
          <NormalBtn
            theme="blue"
            name="회원정보 갱신"
            onClick={() => {
              doBatch();
            }}
          />
        ) : (
          ""
        )}

        {/* 1. 가족등록 BTN */}
        {baseInfoState.member_type === "회원" ? (
          <NormalBtn name="가족목록" theme="white" onClick={() => popFamilyListModal()} />
        ) : (
          ""
        )}

        {/* 2. 지인등록 BTN */}
        {baseInfoState.member_type === "회원" && !baseInfoState.base_info.recommend.is_use ? (
          <NormalBtn name="지인등록" theme="white" onClick={() => popAddFriendModal()} />
        ) : (
          ""
        )}
        {/* 3. 추가결제 BTN */}
        {baseInfoState.member_type === "회원" ? (
          <NormalBtn name="추가결제" theme="white" onClick={() => popAddPayModal()} />
        ) : (
          ""
        )}

        {/* 4. 변경환불 BTN */}
        {baseInfoState.member_type === "회원" ? (
          <NormalBtn
            name="변경/환불"
            theme="white"
            onClick={() => popChangeRefundSelectionModal()}
          />
        ) : (
          ""
        )}

        {/* 5. 쿠폰포인트 BTN */}
        <NormalBtn name="쿠폰/포인트" theme="white" onClick={() => popCouponModal()} />

        {/* 6. 계약서관리 BTN */}
        <NormalBtn name="계약서 관리" theme="white" onClick={() => popContractViewModal()} />
      </div>
    </div>
  );
};
export default MemberViewActionsWithButton;
