const { default: styled } = require("styled-components");

// 검색 Styles
export const SearchContainer = styled.div`
  margin: 0.75rem;
`;

export const SearchConditionBox = styled.div`
  width: 25%;
  display: flex;
`;

export const SearchDatePickerBox = styled.div`
  width: 50%;
  display: flex;
`;

export const SearchRowBox = styled.div`
  display: flex;
  margin-top: 0.5rem;
`;
