import { call, put, takeLatest } from "redux-saga/effects";
import { globalStartLoading } from "../actions/Loading/index";

const START_LOADING = "loading/START_LOADING";
const FINISH_LOADING = "loading/FINISH_LOADING";
const GET_STORES = "loading/GET_STORES";
const GET_STORES_SUCCESS = "loading/GET_STORES_SUCCESS";
const GET_STORES_FAILURE = "loading/GET_STORES_FAILURE";

export function* getTestStoresSaga() {
  yield put(globalStartLoading(GET_STORES));
  try {
    yield put({
      type: GET_STORES_SUCCESS,
      payload: "OK",
    });
  } catch (e) {
    yield put({
      type: GET_STORES_FAILURE,
      payload: e,
      error: true,
    });
  }
}
