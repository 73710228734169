import * as ActionTypes from "./action_type";

///searchOtManageList
export const loadSearchOtManageList = (search_param) => {
  return {
    type: ActionTypes.searchOtManageList,
    search_param: search_param,
  };
};

export const loadSearchOtManageListSuccess = (data) => {
  return {
    type: ActionTypes.searchOtManageListSuccess,
    payload: data,
  };
};

export const loadSearchOtManageListFail = (data) => {
  return {
    type: ActionTypes.searchOtManageListFail,
    payload: [],
  };
};

export const loadSearchOtManageListReset = (data) => {
  return {
    type: ActionTypes.searchOtManageListReset,
    payload: data,
  };
};

///searchPtManageList
export const loadSearchPtManageList = (search_param) => {
  return {
    type: ActionTypes.searchPtManageList,
    search_param: search_param,
  };
};

export const loadSearchPtManageListSuccess = (data) => {
  return {
    type: ActionTypes.searchPtManageListSuccess,
    payload: data,
  };
};

export const loadSearchPtManageListFail = (data) => {
  return {
    type: ActionTypes.searchPtManageListFail,
    payload: [],
  };
};

export const loadSearchPtManageListReset = (data) => {
  return {
    type: ActionTypes.searchPtManageListReset,
    payload: data,
  };
};

///ptDetailInfo
export const loadPtDetailInfo = (pt_detail_id) => {
  return {
    type: ActionTypes.ptDetailInfo,
    pt_detail_id: pt_detail_id,
  };
};

export const loadPtDetailInfoSuccess = (data) => {
  return {
    type: ActionTypes.ptDetailInfoSuccess,
    payload: data,
  };
};

export const loadPtDetailInfoFail = (data) => {
  return {
    type: ActionTypes.ptDetailInfoFail,
    payload: [],
  };
};

export const loadPtDetailInfoReset = (data) => {
  return {
    type: ActionTypes.ptDetailInfoReset,
    payload: data,
  };
};

///ptDetailInfo
export const loadClassByStaff = (staff_id, branch_id) => {
  return {
    type: ActionTypes.classByStaffInfo,
    staff_id: staff_id,
    branch_id: branch_id,
  };
};

export const loadClassByStaffSuccess = (data) => {
  return {
    type: ActionTypes.classByStaffInfoSuccess,
    payload: data,
  };
};

export const loadClassByStaffFail = (data) => {
  return {
    type: ActionTypes.classByStaffInfoFail,
    payload: [],
  };
};

export const loadClassByStaffReset = (data) => {
  return {
    type: ActionTypes.classByStaffInfoReset,
    payload: data,
  };
};
