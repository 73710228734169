import IconButton from "components/Button/IconButton";
import TextField from "components/LabelInput/TextField";
import SubTitle from "components/PageTitle/SubTitle";
import PointPayTable from "./PointPayTable";
import PointUseTable from "./PointUseTable";

const PointCurrentState = ({ memberId, baseInfoState }) => {
  return (
    <div>
      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="포인트 현황" />
      </div>

      <div style={{ display: "flex", marginBottom: "1rem" }}>
        <TextField
          labelMarginLeft="1rem"
          labelWidth={"5rem"}
          textBoxWidth={"9rem"}
          marginRight={"1rem"}
          labelText="잔여 포인트"
          disabled
          defaultValue={baseInfoState.base_info.point.remain_point.toString()}
        />
        <TextField
          labelWidth={"7rem"}
          textBoxWidth={"9rem"}
          marginRight={"1rem"}
          labelText="누적 지급 포인트"
          disabled
          defaultValue={baseInfoState.base_info.point.acc_receive_point.toString()}
        />
        <TextField
          labelWidth={"7rem"}
          textBoxWidth={"9rem"}
          labelText="누적 사용 포인트"
          disabled
          defaultValue={baseInfoState.base_info.point.acc_spend_point.toString()}
        />
      </div>
      <div style={{ border: "1px solid #BBBBBB", display: "flex" }}>
        <div style={{ padding: "1rem", width: "50%" }}>
          <PointPayTable memberId={memberId} />
        </div>
        <div style={{ padding: "1rem", width: "50%" }}>
          <PointUseTable memberId={memberId} />
        </div>
      </div>
    </div>
  );
};

export default PointCurrentState;
