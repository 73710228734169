import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ko } from "date-fns/locale";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Label from "./Label";

const DatePicker = (props) => {
  const storesDispatch = useDispatch();
  const [value, setValue] = useState(props.defaultValue);

  const hStyle = {
    display: "flex",
    alignItems: "center",
    width: props.fullWidth ? "100%" : null,
    justifyContent: props.justifyContent,
    marginTop: props.marginTop,
    marginRight: props.marginRight,
    marginLeft: props.marginLeft,
  };

  const labelStyle = {
    width: props.labelWidth,
    height: "2.25rem",
    marginRight: props.labelMarginRight === undefined ? "0.625rem" : props.labelMarginRight,
    marginLeft: props.labelMarginLeft,
    alignItems: "center",
    display: "flex",

    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.938rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
  };

  const textBoxStyle = {
    width: props.textBoxWidth || props.minWidth,
    height: "2.25rem",
    marginRight: props.textMarginRight === undefined ? "0.625rem" : props.textMarginRight,
    borderRadius: "5px",
    backgroundColor: "#fff",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.9rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000",
  };

  const textBoxStyleDisabled = {
    width: props.textBoxWidth || props.minWidth,
    height: "2.25rem",
    marginRight: props.textMarginRight === undefined ? "0.625rem" : props.textMarginRight,
    borderRadius: "5px",
    backgroundColor: "#ebebeb",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.9rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000",
  };

  useEffect(() => {
    if (props.defaultValue !== undefined) {
      setValue(props.defaultValue);
    }
  }, [props.defaultValue]);

  // min or max 벗어나면 무조건 초기화
  const checkMinMaxValue = (val) => {
    if (
      !val ||
      (props.minDate && new Date(val) < new Date(props.minDate)) ||
      (props.maxDate && new Date(val) > new Date(props.maxDate))
    ) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="날짜 입력이 잘못되었습니다." />,
          onBtnOk: () => {},
        }),
      );

      setValue("");

      if (props.onChange) {
        props.onChange("");
      } else if (props.onChangeCallback) {
        props.onChangeCallback("");
      }

      return false;
    }
    return true;
  };
  const customPtBRLocaleText = {
    okButtonLabel: "확인",
    cancelButtonLabel: "취소",
  };

  return (
    <LocalizationProvider
      adapterLocale={ko}
      dateAdapter={AdapterDateFns}
      localeText={customPtBRLocaleText}
    >
      <Box style={hStyle}>
        <div style={labelStyle}>{props.labelText}</div>
        <TextField
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#555",
            },
            "& .MuiOutlinedInput-input": {
              padding: "10px 10.5px 8.5px 11.5px",
            },
          }}
          error={props.required && (value === undefined || value === "")}
          fullWidth={props.fullWidth}
          id={props.id}
          size="small"
          type="date"
          value={value}
          inputRef={props.inputRef}
          InputProps={{
            readOnly: props.readonly,
            endAdornment: props.endAdornment,
            style: props.disabled === true ? textBoxStyleDisabled : textBoxStyle,
          }}
          inputProps={{
            min: props.minDate ? props.minDate : "1900-01-01",
            max: props.maxDate ? props.maxDate : "2099-12-31",
          }}
          onChange={(e) => {
            setValue(e.target.value);
            if (props.onChange) {
              props.onChange(e.target.value);
            } else if (props.onChangeCallback) {
              props.onChangeCallback(e.target.value);
            }
          }}
          onBlur={(e) => {
            if (checkMinMaxValue(e.target.value) === true) {
              if (props.onBlur) {
                setValue(e.target.value);
                props.onBlur(e.target.value);
              }
            }
          }}
          disabled={props.disabled}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default DatePicker;
