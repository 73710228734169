import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty } from "components/CommonLib/CommonLib";

import { dateToStr } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import TextField from "components/LabelInput/TextField";

const DstMembershipInfo = forwardRef((props, ref) => {
  const { serviceViewState, sumDay, regDate, lastDate, setLastDate } = props;
  const [dstMembershipList, setDstMembershipList] = useState(serviceViewState);

  const dataInfo = new dataInfoTable();

  useEffect(() => {
    if (serviceViewState) {
      //null이 아닐때,
      if (serviceViewState.length === 0) {
        //회원권이 없으면 등록일 부터(수정필요)
        if (sumDay === 0) {
          setLastDate("");
        } else {
          const start = new Date(regDate);
          start.setDate(start.getDate() + sumDay - 1);
          setLastDate(dateToStr(start));
        }
      } else {
        //첫번쨰 회원권 종료일 기준
        const lastIdx = serviceViewState.length - 1;
        if (serviceViewState[lastIdx].membership_list.mbshp.to_date) {
          const start = new Date(serviceViewState[lastIdx].membership_list.mbshp.to_date);
          start.setDate(start.getDate() + sumDay);
          //로부터 sumDay일 만큼 후 날짜 구하기
          // const end = dateToStr(start + sumDay);
          setLastDate(dateToStr(start));
        }
      }
    }
  }, [sumDay]);

  //첫 랜더링
  useEffect(() => {
    setDstMembershipList(serviceViewState);
  }, [serviceViewState]);

  useImperativeHandle(ref, () => ({
    setSelectRow: (rows) => {
      const newRows = rows.map((el) => {
        return { ...el, isGiven: true };
      });

      if (serviceViewState === null) {
        setDstMembershipList([...newRows]);
      } else {
        setDstMembershipList([...serviceViewState, ...newRows]);
      }
    },
    getSelectRow: () => {
      return dstMembershipList;
    },
  }));

  return (
    <div>
      <CustomTable
        columns_head={dataInfo.columns_head}
        table_title={dataInfo.table_title}
        rest_call={dataInfo.get_data_from_rest}
        row_render={dataInfo.create_table}
        rest_data={dstMembershipList}
      />
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.7rem" }}>
        <TextField
          labelText="최종 회원권 종료일"
          labelWidth="7rem"
          textBoxWidth="11rem"
          disabled
          textMarginRight="0rem"
          defaultValue={lastDate ? lastDate : ""}
        />
      </div>
    </div>
  );
});
export default DstMembershipInfo;

class dataInfoTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onParentClickEvent = null;

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.isGiven ? "양수" : "기존"}</CustomTableContents>
        <CustomTableContents>{data.membership_list.mbshp_type}</CustomTableContents>
        <CustomTableContents>{data.membership_list.mbshp_option}</CustomTableContents>
        <CustomTableContents>{data.purchase_item_list.item_info.item_name}</CustomTableContents>
        {/* <CustomTableContents>
          {data.membership_list.mbshp_status}
        </CustomTableContents>
        <CustomTableContents>
          {data.membership_list.mbshp.from_date} ~ {data.membership_list.mbshp.to_date}
        </CustomTableContents> */}
        <CustomTableContents>{data.ot_info.ot_info.remain_session_cnt + " 회"}</CustomTableContents>
        <CustomTableContents>
          {data.membership_list.cloth.is_use ? "사용" : "비사용"}
        </CustomTableContents>
        <CustomTableContents>
          {data.membership_list.gx.is_use ? "사용" : "비사용"}
        </CustomTableContents>
        <CustomTableContents>
          {data.membership_list.locker_list.length === 0
            ? "비사용"
            : `사용 (${data.membership_list.locker_list.length})`}
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "구분", width: "10%" },
    { title: "종류", width: "10%" },
    { title: "회원권 옵션", width: "10%" },
    { title: "상품명", width: "23%" },
    // { title: "상태", width: "5%" },
    // { title: "유효기간", width: "20%" },
    { title: "잔여 OT", width: "10%" },
    { title: "운동복", width: "10%" },
    { title: "GX", width: "10%" },
    { title: "락커", width: "10%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
