import { MainBackgroundBox, MainBottomPanel, MainPanel, RootPanel } from "components/Panels/Panels";

const PageFrame = ({ isMain, children, noPadding }) => {
  return (
    <div>
      <RootPanel isMain={isMain}>
        {/* <Sidebar permissionState={permissionState} /> */}
        <MainPanel>
          {/* <MainTopPanel><Navbar permissionState={permissionState} /></MainTopPanel> */}
          {isMain ? (
            <MainBackgroundBox>
              <a
                href="https://www.freepik.com/free-vector/fractal-grid-wave-background_3923425.htm"
                style={{
                  textDecoration: "none",
                  color: "#1E2C68",
                  position: "absolute",
                  top: "7rem",
                  right: "1rem",
                  fontSize: "0.8rem",
                }}
              >
                {/* Freepik */}
              </a>
            </MainBackgroundBox>
          ) : (
            <MainBottomPanel noPadding={noPadding}>{children}</MainBottomPanel>
          )}
        </MainPanel>
      </RootPanel>
    </div>
  );
};

export default PageFrame;
