import { Button } from "@material-ui/core";
import clsx from "clsx";
import SwitchButton from "components/Button/SwitchButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, generateObjectDummyId } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import SubTitle from "components/PageTitle/SubTitle";
import { globalAlertOn } from "modules/actions/Alert";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import GxInfoModal from "../../GxInfoModal";

import IconButton from "components/Button/IconButton";
import { useDispatch } from "react-redux";
import AddGxSalaryModal from "../../AddGxSalaryModal";

const GxSalaryCard = forwardRef((props, ref) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();
  const [showGxTable, setShowGxTable] = useState(props.workInfo.is_gx_extra);
  const [itemInfoList, setItemInfoList] = useState(props.workInfo.gx_class);
  const viewModelRef = useRef();
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });

  const [gxList, setGxList] = useState([]);

  //초기세팅
  useEffect(() => {
    setGxList(props.workInfo.gx_class);
  }, [props.workInfo]);

  useImperativeHandle(ref, () => ({
    getItemList: () => {
      return itemInfoList;
    },
  }));

  useEffect(() => {
    setShowGxTable(props.workInfo.is_gx_extra);
    setItemInfoList(props.workInfo.gx_fee);
  }, [props.workInfo.is_gx_extra]);

  const initWorkTime = (idx, dWeek) => {
    return {
      idx: idx,
      is_use: false,
      day_of_week: dWeek, //"string / 요일" /* 일,월,화,수,목,금,토,공휴일 */,
      from_time: "00:00",
      end_time: "00:00",
    };
  };

  const getEmptyGx = () => {
    const modalParam = {
      gx_fee_id: generateObjectDummyId(),
      class_name: "",
      class_fee: 0,
      time_list: [
        initWorkTime(0, "월"),
        initWorkTime(1, "화"),
        initWorkTime(2, "수"),
        initWorkTime(3, "목"),
        initWorkTime(4, "금"),
        initWorkTime(5, "토"),
        initWorkTime(6, "일"),
        initWorkTime(7, "공휴일"),
      ],
    };
    return modalParam;
  };

  const onAddItem = (modalParam) => {
    if (modalParam === undefined) {
      modalParam = getEmptyGx();
    }

    setModalInfo({
      show: true,
      title: "GX 수당",
      Content: <GxInfoModal modalParam={modalParam} onModalDone={btnItemPush} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const onEditItem = (data) => {
    const idx = itemInfoList.findIndex((x) => x.gx_fee_id === data.gx_fee_id);

    setModalInfo({
      show: true,
      title: "GX 수당",
      Content: <GxInfoModal modalParam={itemInfoList[idx]} onModalDone={btnItemPush} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const popAddGxSalaryModal = (title, param, onModalDone) => {
    setModalInfo({
      show: true,
      title: title,
      Content: <AddGxSalaryModal modalParam={param} onModalDone={onModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const btnItemPush = (itemInfo) => {
    if (itemInfoList.length === 0) {
      setItemInfoList([itemInfo]);
      props.workInfo.gx_fee.push(itemInfo);
    } else {
      const array = [...itemInfoList];
      const index = array.map((item) => item.gx_fee_id).indexOf(itemInfo.gx_fee_id);
      if (index !== -1) {
        array[index] = itemInfo;
        setItemInfoList(array);
      } else {
        setItemInfoList([...itemInfoList, itemInfo]);
        props.workInfo.gx_fee.push(itemInfo);
      }
    }

    // console.log(props.workInfo);
    viewModelRef.current.setClose();
  };

  const btnItemPop = (targetId) => {
    const array = [...itemInfoList]; // make a separate copy of the array
    const index = array.map((item) => item.item_id).indexOf(targetId);

    if (index !== -1) {
      array.splice(index, 1);
      props.workInfo.gx_fee.splice(index, 1);
      setItemInfoList(array);
    }
  };

  const itemList = new itemInfoTable(onEditItem, btnItemPop, props.pageMode);

  //GX추가 모달 닫히면서...
  const addModalDone = (classList) => {
    //1. 모달 닫기
    viewModelRef.current.setClose();
    //2. 받아온 classList를 임시저장 보내는데 넣어주기
    props.workInfo.gx_class = classList;

    //3. 현재 리스트에 넣어주기
    setGxList(classList);
  };

  const onClickHandler = () => {
    if (!props.staffRank || props.staffRank === "선택") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="상단의 직원정보를 먼저 모두 입력해주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else if (!props.staffPos || props.staffPos === "선택") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="상단의 직원정보를 먼저 모두 입력해주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else {
      popAddGxSalaryModal(
        "GX 수당 추가",
        {
          branchId: props.workInfo.base_info.branch_info.branch_id,
          staffInfo: {
            staff_name: props.staffName,
            staff_id: props.staffId,
            staff_rank: props.staffRank,
            staff_pos: props.staffPos,
          },
          staffId: props.staffId,
        },
        addModalDone,
      );
    }
  };

  return (
    <div style={{ width: "62rem" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <SubTitle titleText="GX 수당" />
        </div>
        <div>
          <SwitchButton
            labelText={props.workInfo.is_gx_extra ? "사용" : "비사용"}
            defaultValue={props.workInfo.is_gx_extra}
            disabled={props.pageMode === "보기" || props.pageMode === "이력"}
            onChangeCallback={(e) => {
              setShowGxTable(e.target.checked);
              props.workInfo.is_gx_extra = e.target.checked;
              if (!e.target.checked) {
                props.workInfo.gx_class = [];
                setGxList([]);
              }
            }}
          />
        </div>
      </div>

      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />

      {showGxTable ? (
        <>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Button
              className={clsx({
                [btnStyleClass.btnRootWhite]: true,
                [btnStyleClass.buttonType7]: true,
                [btnStyleClass.disabled]: true,
              })}
              // onClick={() => onAddItem()}
              onClick={onClickHandler}
              disabled={props.pageMode === "보기" || props.pageMode === "이력"}
            >
              GX 수당 추가
            </Button>
          </div>

          <div style={{ marginTop: "0.5rem" }}>
            <CustomTable
              columns_head={itemList.columns_head}
              table_title={itemList.table_title}
              rest_call={itemList.get_data_from_rest}
              row_render={itemList.create_table}
              rest_data={gxList}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
});
export default GxSalaryCard;

class itemInfoTable {
  table_title = "";
  btnStyleClass = btnStyles();
  btnEdit = null;
  btnPayMethodPop = null;
  pageMode = null;

  constructor(btnEdit, btnPayMethodPop, pageMode) {
    this.btnEdit = btnEdit;
    this.btnPayMethodPop = btnPayMethodPop;
    this.pageMode = pageMode;
  }

  getTimeList = (timeList) => {
    let res = "";
    for (let i = 0; i < timeList.length; i++) {
      if (timeList[i].is_use) {
        if (res !== "") {
          res += " / ";
        }
        res += timeList[i].day_of_week + ": " + timeList[i].from_time + "~" + timeList[i].end_time;
      }
    }

    return res;
  };

  convertDayText = (array) => {
    let temp = [];
    array.forEach((el) => {
      if (el === 0) {
        temp.push("일");
      } else if (el === 1) {
        temp.push("월");
      } else if (el === 2) {
        temp.push("화");
      } else if (el === 3) {
        temp.push("수");
      } else if (el === 4) {
        temp.push("목");
      } else if (el === 5) {
        temp.push("금");
      } else if (el === 6) {
        temp.push("토");
      }
    });
    const text = temp.join(", ");
    return text;
  };

  create_table = (data, key) => {
    return (
      <CustomRow key={key}>
        <CustomTableContents>{data.gx_info.gx_code}</CustomTableContents>
        <CustomTableContents>{data.class_name}</CustomTableContents>
        <CustomTableContents>
          {this.convertDayText(data.schedule_week_idx_list)}
        </CustomTableContents>
        <CustomTableContents>
          {/* <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              className={clsx({
                [this.btnStyleClass.btnRootRaw]: true,
                [this.btnStyleClass.buttonType2]: true,
                [this.btnStyleClass.disabled]: true,
              })}
              onClick={() => this.btnEdit(data)}
              disabled={this.pageMode === "보기" || this.pageMode === "이력"}
            >
              변경
            </Button>
            <Button
              className={clsx({
                [this.btnStyleClass.btnRootRaw]: true,
                [this.btnStyleClass.buttonType2]: true,
                [this.btnStyleClass.disabled]: true,
              })}
              onClick={() => this.btnPayMethodPop(data.item_id)}
              style={{ marginLeft: "0.25rem" }}
              disabled={this.pageMode === "보기" || this.pageMode === "이력"}
            >
              삭제
            </Button>
          </div> */}
          {data.class_from_time} ~ {data.class_to_time}
        </CustomTableContents>
        <CustomTableContentsEND>
          {data.session_price.toLocaleString() + " 원"}
        </CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "수업코드", width: "20%" },
    { title: "수업명", width: "20%" },
    { title: "수업 요일", width: "20%" },
    { title: "수업 시간", width: "20%" },
    { title: "회당금액", width: "20%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
