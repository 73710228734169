import StaffDMView from "./StaffDMView";
const StaffDMWrapper = ({ nowService }) => {
  return (
    <div>
      <StaffDMView nowService={nowService} />
    </div>
  );
};

export default StaffDMWrapper;
