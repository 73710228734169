import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import LongPostponeListContent from "./LongPostponeListContent";

const LongPostponeList = () => {
  return (
    <div>
      <PageFrame
        menuActive="락커/셋팅"
        parentMenu={{ 락커: true }}
        children={
          <div>
            <PageTitle titleText="장기연기 리스트" />
            <LongPostponeListContent />
          </div>
        }
      />
    </div>
  );
};
export default LongPostponeList;
