import CardFixed from "components/Card/CardFixed";
import ExtendMbshpHistRecordView from "./ExtendMbshpHistoryRecord/ExtendMbshpHistRecordView";

const ExtendMbshpHistoryWrapper = () => {
  return (
    <CardFixed
      topDom={<div>회원 검색</div>}
      midDom={<ExtendMbshpHistRecordView />}
      isLoading={false}
      rootStyle={{
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};
export default ExtendMbshpHistoryWrapper;
