import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import TableTitleWithButton from "components/TableTitleWithButton/TableTitleWithButton";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { firewallState } from "../Module/reducers/firewall_state";

const FireWallManagement = ({ onClickOpenModal }) => {
  const storesDispatch = useDispatch();
  const [firewallData, setFirewallData] = React.useState([]);

  const [curPage, setCurPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  useEffect(() => {
    getFirewallData();
  }, [curPage]);

  // Get IP list
  const getFirewallData = async () => {
    HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/erp_set/ip_whitelist", {
      params: {
        page_num: curPage,
      },
    })
      .then((res) => {
        setTotalPage(res.data.total_page);
        setFirewallData(res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.msg, "err");
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const viewRefresh = () => {
    getFirewallData();
  };

  const onIpAddEvent = () => {
    const param = {
      title: "접근 허용 IP 추가",
      modalParam: {
        mode: "add",
        state: firewallState,
        refreshStore: viewRefresh,
      },
    };
    onClickOpenModal("접근관리/IP", param);
  };

  const onClickEdit = (data) => {
    const param = {
      title: "접근 허용 IP 수정",
      modalParam: {
        mode: "edit",
        state: data,
        refreshStore: viewRefresh,
      },
    };
    onClickOpenModal("접근관리/IP", param);
  };

  const onClickDelete = (data) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="삭제하시겠습니까?" />
          </>
        ),
        // width: "50rem",
        onBtnYes: () => {
          onDeleteItem(data);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const onDeleteItem = async (data) => {
    await HttpInstance.delete(process.env.REACT_APP_BRANCH_SERVER + "/erp_set/ip_whitelist", {
      params: {
        ip_addr: data.ip_addr,
      },
    })
      .then((res) => {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: (
              <>
                <Label labelText="삭제되었습니다." />
              </>
            ),
            // width: "50rem",
            onBtnOk: () => {
              viewRefresh();
            },
          }),
        );
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  let firewallList = new firewallTable(onClickEdit, onClickDelete);

  return (
    <div>
      <TableTitleWithButton
        titleLabel={"ERP 접속 허용 IP 목록"}
        buttonText={"추가"}
        onClickEvent={onIpAddEvent}
      />
      <CustomTable
        columns_head={firewallList.columns_head}
        table_title={firewallList.table_title}
        rest_call={firewallList.get_data_from_rest}
        row_render={firewallList.create_table}
        rest_data={firewallData}
      />
      <CustomPagination curPage={curPage} doSearch={setCurPage} totalCnt={totalPage} />
    </div>
  );
};
export default FireWallManagement;

class firewallTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onClickEdit = null;
  onClickDelete = null;

  constructor(onClickEdit, onClickDelete) {
    this.onClickEdit = onClickEdit;
    this.onClickDelete = onClickDelete;
  }

  // parseIpAddr = (data) => {
  //   if (
  //     data.firewall_info &&
  //     data.firewall_info.ip_addr_1 &&
  //     data.firewall_info.ip_addr_2 &&
  //     data.firewall_info.ip_addr_3 &&
  //     data.firewall_info.ip_addr_4
  //   ) {
  //     return (
  //       data.firewall_info.ip_addr_1 +
  //       "." +
  //       data.firewall_info.ip_addr_2 +
  //       "." +
  //       data.firewall_info.ip_addr_3 +
  //       "." +
  //       data.firewall_info.ip_addr_4
  //     );
  //   }
  //   return "";
  // };

  create_table = (data, idx) => {
    return (
      <CustomRow style={{ width: "100%" }} key={idx}>
        <CustomTableContents width="20%">{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents width="20%">{data.ip_addr}</CustomTableContents>
        <CustomTableContents width="30%">{data.alias}</CustomTableContents>
        <CustomTableContents width="5%">
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                className={clsx({
                  [this.btnStyleClass.btnRoot]: true,
                  [this.btnStyleClass.btnMarginRight]: true,
                  [this.btnStyleClass.buttonType2]: true,
                })}
                onClick={() => this.onClickEdit(data)}
              >
                수정
              </Button>
              <Button
                className={clsx({
                  [this.btnStyleClass.btnRoot]: true,
                  [this.btnStyleClass.buttonType2]: true,
                })}
                onClick={() => this.onClickDelete(data)}
              >
                삭제
              </Button>
            </div>
          </div>
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "등록일시", field: "" },
    { title: "IP 주소", field: "" },
    { title: "별칭", field: "" },
    { title: " ", field: "action" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
