import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import {
  checkDateOrder,
  getDefaultValue,
  restEmpty,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import { HttpInstance } from "lib/HttpLib";
import { useStaffListStore } from "./modules/store/staffListState";
import { SearchConditionBox, SearchConditionDateBox, SearchContainer, SearchRowBox } from "./style";

const SearchCardRow1 = ({
  searchParams,
  hqBranchListData,
  branchList,
  setSearchParams,
  onClickSearch,
}) => {
  // 전역 상태 관리
  const {
    staffListHqIdx: hqIdx,
    setHqIdx,
    // staffListPhoneNumber: phone,
    // setStaffListPhoneNumber: setPhone,
  } = useStaffListStore();
  // 페이지 상태 관리
  const labelWidth = "5.5rem";
  const labelWidthShort = "4.5rem";

  const textBoxWidth = "15rem";
  const phoneInputBoxWidth = "4.58rem";

  const phone = useRef({
    p1: searchParams.p1,
    p2: searchParams.p2,
    p3: searchParams.p3,
  });
  const phoneRef1 = useRef();
  const phoneRef2 = useRef();
  const phoneRef3 = useRef();

  // 전화번호 입력 처리
  const onInputPhone = () => {
    let res = "";

    if (searchParams.p1 && !searchParams.p2 && searchParams.p3) {
      res = "err";
    } else {
      res = searchParams.p1 + "-" + searchParams.p2 + "-" + searchParams.p3;
    }
    res = res.replace("--", "-");
    const newPhoneNum = res;
    setSearchParams("phone", newPhoneNum);
  };

  // 전화 번호 변경 체크
  useEffect(() => {
    onInputPhone();
  }, [searchParams.p1, searchParams.p2, searchParams.p3]);

  return (
    <SearchRowBox
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onClickSearch(false, 1);
        }
      }}
    >
      <SearchConditionBox>
        <ComboBox
          labelWidth={labelWidth}
          comboItemWidth={textBoxWidth}
          labelText="본부"
          data={hqBranchListData && hqBranchListData.hq_list}
          defaultValue={getDefaultValue("combo", searchParams.hq_info.hq_name)}
          onChangeCallback={(e, idx) => {
            setHqIdx(idx);
            const hqParams = hqBranchListData.target_list[idx].hq_info;
            setSearchParams("hq_info", hqParams);
          }}
        />
      </SearchConditionBox>
      <SearchConditionBox>
        <SearchComboBox
          labelWidth={labelWidthShort}
          comboItemWidth={textBoxWidth}
          labelText="지점"
          data={branchList}
          defaultValue={getDefaultValue("combo", searchParams.branch_info.branch_name)}
          onChangeCallback={(e, v, idx) => {
            const branchParams = hqBranchListData.target_list[hqIdx].branch_list[idx];
            setSearchParams("branch_info", branchParams);
          }}
        />
      </SearchConditionBox>
      <SearchConditionBox>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          labelText="직원명"
          defaultValue={getDefaultValue("field", searchParams.staff_name)}
          onChange={(e) => {
            setSearchParams("staff_name", e.target.value);
          }}
        />
      </SearchConditionBox>
      <SearchConditionBox>
        <TextField
          inputRef={phoneRef1}
          labelWidth={labelWidth}
          textBoxWidth={phoneInputBoxWidth}
          textMarginRight="0.1rem"
          maxLength={3}
          defaultValue={getDefaultValue("field", searchParams.p1)}
          labelText="연락처"
          onChangeCallback={(e) => {
            setSearchParams("p1", e);
            phone.current.p1 = e;
            if (phone.current.p1.length === 3) {
              phoneRef2.current.select();
            }
          }}
        />
        <TextField
          inputRef={phoneRef2}
          textBoxWidth={phoneInputBoxWidth}
          textMarginRight="0.1rem"
          labelMarginRight="0.1rem"
          maxLength={4}
          labelText="-"
          defaultValue={getDefaultValue("field", searchParams.p2)}
          onChangeCallback={(e) => {
            setSearchParams("p2", e);
            phone.current.p2 = e;
            if (phone.current.p2.length === 4) {
              phoneRef3.current.select();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Backspace" && !phone.current.p2) {
              phoneRef1.current.select();
            }
          }}
        />
        <TextField
          inputRef={phoneRef3}
          textBoxWidth={phoneInputBoxWidth}
          labelMarginRight="0.1rem"
          maxLength={4}
          labelText="-"
          defaultValue={getDefaultValue("field", searchParams.p3)}
          onChangeCallback={(e) => {
            setSearchParams("p3", e);
            phone.current.p3 = e;
          }}
          onKeyDown={(e) => {
            if (e.key === "Backspace" && !phone.current.p3) {
              phoneRef2.current.select();
            }
          }}
        />
      </SearchConditionBox>
    </SearchRowBox>
  );
};

const SearchCardRow2 = ({ searchParams, setSearchParams, hqId }) => {
  const storesDispatch = useDispatch();
  const labelWidth = "5.5rem";
  const labelWidthShort = "4.5rem";
  const textBoxWidth = "15rem";
  const workStatusData = ["대기", "재직", "퇴직"];
  const documentStatusData = ["등록완료", "미등록"];

  const [posNameList, setPosNameList] = useState([]);

  useEffect(() => {
    HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/staff/search_hq_detail", {
      params: {
        hq_id: hqId,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setPosNameList(res.data.data.position_name_list);
        }
      })
      .catch((err) => {
        console.log("err");
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  }, [hqId]);

  return (
    <SearchRowBox>
      <SearchConditionBox>
        <ComboBox
          labelWidth={labelWidth}
          comboItemWidth={textBoxWidth}
          labelText="재직상태"
          defaultValue={getDefaultValue("combo", searchParams.work_status)}
          data={workStatusData}
          onChangeCallback={(e) => {
            setSearchParams("work_status", e.target.value);
          }}
          includeAllOption
        />
      </SearchConditionBox>
      <SearchConditionBox>
        <ComboBox
          labelWidth={labelWidthShort}
          comboItemWidth={textBoxWidth}
          labelText="직책"
          defaultValue={getDefaultValue("combo", searchParams.position)}
          data={posNameList}
          onChangeCallback={(e) => {
            setSearchParams("position", e.target.value);
          }}
        />
      </SearchConditionBox>
      <SearchConditionBox>
        <ComboBox
          labelWidth={labelWidth}
          comboItemWidth={textBoxWidth}
          labelText="주민등록등본"
          defaultValue={getDefaultValue("combo", searchParams.is_submit_id_card)}
          data={documentStatusData}
          onChangeCallback={(e) => {
            setSearchParams("is_submit_id_card", e.target.value);
          }}
          includeAllOption
        />
      </SearchConditionBox>
      <SearchConditionBox>
        <ComboBox
          labelWidth={labelWidth}
          comboItemWidth={textBoxWidth}
          labelText="통장사본"
          defaultValue={getDefaultValue("combo", searchParams.is_submit_account_copy)}
          data={documentStatusData}
          onChangeCallback={(e) => {
            setSearchParams("is_submit_account_copy", e.target.value);
          }}
          includeAllOption
        />
      </SearchConditionBox>
    </SearchRowBox>
  );
};
const SearchCardRow3 = ({ searchParams, setSearchParams }) => {
  const labelWidth = "5.5rem";
  const labelWidthShort = "4.5rem";
  const textBoxWidth = "15rem";
  const approvalStatusData = ["없음", "승인대기", "승인", "반려", "회수"]; // 결재상태
  const editStatusData = ["없음", "임시저장"]; // 수정상태
  const documentStatusData = ["등록완료", "미등록"];

  return (
    <SearchRowBox>
      <SearchConditionBox>
        <ComboBox
          labelWidth={labelWidth}
          comboItemWidth={textBoxWidth}
          labelText="결재상태"
          defaultValue={getDefaultValue("combo", searchParams.approval_status)}
          data={approvalStatusData}
          onChangeCallback={(e) => {
            setSearchParams("approval_status", e.target.value);
          }}
          includeAllOption
        />
      </SearchConditionBox>
      <SearchConditionBox>
        <ComboBox
          labelWidth={labelWidthShort}
          comboItemWidth={textBoxWidth}
          labelText="수정상태"
          defaultValue={getDefaultValue("combo", searchParams.edit_status)}
          data={editStatusData}
          onChangeCallback={(e) => {
            setSearchParams("edit_status", e.target.value);
          }}
          includeAllOption
        />
      </SearchConditionBox>
      <SearchConditionBox>
        <ComboBox
          labelWidth={labelWidth}
          comboItemWidth={textBoxWidth}
          labelText="근로계약서"
          defaultValue={getDefaultValue("combo", searchParams.is_submit_emp_file)}
          data={documentStatusData}
          onChangeCallback={(e) => {
            setSearchParams("is_submit_emp_file", e.target.value);
          }}
          includeAllOption
        />
      </SearchConditionBox>
      <SearchConditionBox>
        <ComboBox
          labelWidth={labelWidth}
          comboItemWidth={textBoxWidth}
          labelText="사직서"
          defaultValue={getDefaultValue("combo", searchParams.is_submit_resign)}
          data={documentStatusData}
          onChangeCallback={(e) => {
            setSearchParams("is_submit_resign", e.target.value);
          }}
          includeAllOption
        />
      </SearchConditionBox>
    </SearchRowBox>
  );
};

const SearchCardRow4 = ({ searchParams, setSearchParams }) => {
  const labelWidth = "5.5rem";
  const textBoxWidth = "15rem";

  return (
    <SearchRowBox>
      <SearchConditionDateBox>
        <DatePicker
          id="join_date_from"
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          textMarginRight="0rem"
          labelText="입사일"
          defaultValue={getDefaultValue("field", searchParams.join_date_from)}
          onChangeCallback={(e) => {
            setSearchParams("join_date_from", e);
          }}
          onBlur={(e) => {
            if (!checkDateOrder(e, searchParams.join_date_to)) {
              setSearchParams("join_date_from", "");
            }
          }}
        />
        <DatePicker
          id="join_date_to"
          marginLeft="0.5rem"
          labelWidth="0.5rem"
          textBoxWidth={textBoxWidth}
          labelText="~"
          defaultValue={getDefaultValue("field", searchParams.join_date_to)}
          onChangeCallback={(e) => {
            setSearchParams("join_date_to", e);
          }}
          onBlur={(e) => {
            if (!checkDateOrder(searchParams.join_date_from, e)) {
              setSearchParams("join_date_to", "");
            }
          }}
        />
      </SearchConditionDateBox>
      <SearchConditionDateBox>
        <DatePicker
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          textMarginRight="0rem"
          labelText="퇴사일"
          defaultValue={getDefaultValue("field", searchParams.resign_date_from)}
          onChangeCallback={(e) => {
            setSearchParams("resign_date_from", e);
          }}
          onBlur={(e) => {
            if (!checkDateOrder(e, searchParams.resign_date_to)) {
              setSearchParams("resign_date_from", "");
            }
          }}
        />
        <DatePicker
          marginLeft="0.5rem"
          labelWidth="0.5rem"
          textBoxWidth={textBoxWidth}
          labelText="~"
          defaultValue={getDefaultValue("field", searchParams.resign_date_to)}
          onChangeCallback={(e) => {
            setSearchParams("resign_date_to", e);
          }}
          onBlur={(e) => {
            if (!checkDateOrder(searchParams.resign_date_from, e)) {
              setSearchParams("resign_date_to", "");
            }
          }}
        />
      </SearchConditionDateBox>
    </SearchRowBox>
  );
};

const SearchCardRow5 = () => {
  return (
    <SearchRowBox>
      <SearchConditionBox>
        <Label labelText="가입상태" />
        <CheckBox
          labelText="승인대기"
          marginLeft="3rem"
          marginTop="0rem"
          marginRight="0rem"
          onChangeCallback={(e) => {
            // console.log("");
          }} // ??????
          disabled
        />
      </SearchConditionBox>
    </SearchRowBox>
  );
};

const StaffListSearchCard = ({ hqBranchListData, branchList, onClickSearch }) => {
  // 전역 상태 관리
  const { staffListSearchParams: searchParams, setSearchParams } = useStaffListStore();
  const [hqId, setHqId] = useState("");

  // const onChangeHqId = (hq_id) => { setHqId(hq_id); console.log(hq_id); };

  return (
    <SearchContainer>
      <Label labelText="검색 조건" fontWeight="bold" justifyContent="flex-start" />
      <SearchCardRow1
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        hqBranchListData={hqBranchListData}
        branchList={branchList}
        onClickSearch={onClickSearch}
      />
      <SearchCardRow2 searchParams={searchParams} setSearchParams={setSearchParams} hqId={hqId} />
      <SearchCardRow3 searchParams={searchParams} setSearchParams={setSearchParams} />
      <SearchCardRow4 searchParams={searchParams} setSearchParams={setSearchParams} />
      <SearchCardRow5 />
    </SearchContainer>
  );
};
export default StaffListSearchCard;
