const DrawLockerBlockView = ({ itemInfo, onItemClick, statusFilter, isFilterPasswd }) => {
  const selectColor = (status) => {
    if (status === "대기") {
      return "#E4F4E1";
    } else if (status === "사용") {
      return "#E3EBF7";
    } else if (status === "예약") {
      return "#FBF0DB";
    } else if (status === "만료") {
      return "#F8D8D8";
    } else if (status === "고장") {
      return "#F5E0F7";
    } else {
      return "#DADADA";
    }
  };

  const blockStyle = {
    display: "flex",
    visibility:
      statusFilter === "전체" ? "visible" : itemInfo.status === statusFilter ? "visible" : "hidden",
    justifyContent: "center",
    alignItems: "center",
    width: "2.5rem",
    height: "2.25rem",
    margin: "0 0.25rem",
    borderRadius: "5px",
    backgroundColor: selectColor(itemInfo.status),
    border: "1px solid #DEDEDE",
    color: !isFilterPasswd ? "black" : itemInfo.password ? "blue" : "red",
    cursor: "pointer",
  };

  return (
    <div style={blockStyle} onClick={() => onItemClick(itemInfo)}>
      {itemInfo.active === 0 ? itemInfo.num : ""}
    </div>
  );
};

const DrawLockerBlockEdit = ({ itemInfo, onItemClick }) => {
  const blockStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "2.5rem",
    height: "2.25rem",
    margin: "0 0.25rem",
    borderRadius: "5px",
    backgroundColor: itemInfo.active === 0 ? "#FFFFFF" : "#C6C6C6",
    border: "2px solid #DEDEDE ",
    color: itemInfo.active === 0 ? "#222222" : "",
    cursor: "pointer",
  };

  return (
    <div style={blockStyle} onClick={() => onItemClick(itemInfo)}>
      {itemInfo.active === 0 ? itemInfo.num : ""}
    </div>
  );
};

const DrawLockerBlockAssign = ({ itemInfo, onItemClick }) => {
  const blockStyle = {
    display: "flex",
    justifyContent: "center",
    fontFamily: "AppleSDGothicNeo",
    alignItems: "center",
    width: "2.5rem",
    height: "2.25rem",
    margin: "0 0.25rem",
    borderRadius: "5px",
    border: "1px solid #DEDEDE",
    color: itemInfo.status !== "대기" ? "#032063" : "#222222",
    cursor: "pointer",
    backgroundColor: !itemInfo.status || itemInfo.status !== "대기" ? "#032063" : "#FFFFFF",
  };

  return (
    <div style={blockStyle} onClick={() => onItemClick(itemInfo)}>
      {itemInfo.active === 1 ? "" : itemInfo.num}
    </div>
  );
};

const DrawGridRow = ({
  currentData,
  onItemClick,
  startLevel,
  mode, //3종류(view, edit, assign)
  statusFilter,
  areaHeight,
  isFilterPasswd,
}) => {
  const startL = isNaN(startLevel) ? 0 : parseInt(startLevel) - 1;

  return (
    <div>
      <div style={{ display: "flex" }}>
        {currentData.map((item, idx) => {
          return (
            <div style={{ display: "flex" }} key={idx}>
              {idx === 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "3.063rem",
                    height: "2.25rem",
                    fontFamily: "AppleSDGothicNeo",
                  }}
                >
                  {areaHeight - item.level + startL + 1} 단
                </div>
              ) : (
                ""
              )}

              <div style={{ display: "block" }} key={idx}>
                {mode === "edit" ? ( //구역추가
                  <DrawLockerBlockEdit itemInfo={item} onItemClick={onItemClick} />
                ) : mode === "assign" ? (
                  //락커이동
                  <DrawLockerBlockAssign itemInfo={item} onItemClick={onItemClick} />
                ) : (
                  //mode === 'view' (락커메인 섹션2)
                  <DrawLockerBlockView
                    itemInfo={item}
                    onItemClick={onItemClick}
                    statusFilter={statusFilter}
                    isFilterPasswd={isFilterPasswd}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const DrawLockerGrid = ({
  currentData,
  gridWidth,
  onItemClick,
  mode,
  statusFilter,
  areaHeight,
  isFilterPasswd,
  startLevel,
}) => {
  if (currentData.allocate_list === []) {
    return <div></div>;
  } else {
    return (
      <div style={{ overflow: "auto" }}>
        {currentData.allocate_list.map((row, idx) => {
          return (
            <div key={idx}>
              <DrawGridRow
                currentData={row}
                onItemClick={onItemClick}
                startLevel={startLevel}
                mode={mode}
                statusFilter={statusFilter}
                areaHeight={areaHeight}
                isFilterPasswd={isFilterPasswd}
              />
              <div
                style={{
                  width: gridWidth,
                  height: "0.063rem",
                  margin: "0.344rem 0rem",
                  backgroundColor: "#ebebeb",
                }}
              />
            </div>
          );
        })}
      </div>
    );
  }
};
