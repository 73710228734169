import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import CheckBox from "components/LabelInput/CheckBox";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import SubTitle from "components/PageTitle/SubTitle";
import TableTitleWithButton from "components/TableTitleWithButton/TableTitleWithButton";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HqBranchFilter } from "../CommonLib/SelectFilter";
import { loadStaffWorkListViewStore } from "../Module/actions";

const BranchWorkInfoCard = forwardRef((props, ref) => {
  const { onClickOpenModal, staffInfoState, onRefresh, hqPermissions } = props;
  const [itemInfoList, setItemInfoList] = useState([]);
  const [loading, setLoading] = useState(false);
  const workInfoListStore = useSelector((state) => state.staff_service.work_info_list_info);
  const storesDispatch = useDispatch();

  useEffect(() => {
    storesDispatch(loadStaffWorkListViewStore(staffInfoState.contents.staff_obj.staff_id));
  }, [staffInfoState]);

  const viewRefresh = () => {
    storesDispatch(loadStaffWorkListViewStore(staffInfoState.contents.staff_obj.staff_id));
    props.onRefresh();
  };

  const onOpenModal = (pageMode, workInfoId, hqId, branchId) => {
    const affiliation = staffInfoState.contents.staff_obj.affiliation;

    const filteredHqBranchInfo = HqBranchFilter(
      staffInfoState.selector,
      affiliation,
      workInfoListStore.stores.data,
    );

    if (filteredHqBranchInfo.target_list.length === 0 && pageMode === "추가") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="더 이상 추가 가능한 지점이 없습니다." />,
          onBtnOk: () => {},
        }),
      );
    } else {
      const title =
        pageMode === "추가" ? "근로정보 추가" : pageMode === "수정" ? "근로정보 수정" : "근로정보";

      const param = {
        title: title,
        modalParam: {
          pageMode: pageMode,
          work_info_id: workInfoId,
          hq_id: hqId,
          branch_id: branchId,
          hqBranchList:
            pageMode === "추가"
              ? filteredHqBranchInfo
              : staffInfoState.contents.staff_obj.affiliation,
          affiliation: staffInfoState.contents.staff_obj.affiliation,
          staff_info_state: staffInfoState.contents.staff_obj,
          refreshStore: viewRefresh,
          hqPermissions: hqPermissions,
        },
      };

      onClickOpenModal("근로정보", param, onDone);
    }
  };

  const onCancelReg = (workInfoId, staffId, branchId) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: <Label labelText="직원 등록을 취소하시겠습니까?" />,
        // width: "50rem",
        onBtnYes: () => {
          HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/staff/work_info_cancel", {
            params: {
              work_info_id: workInfoId,
              staff_id: staffId,
              branch_id: branchId,
            },
          })
            .then((res) => {
              if (!res.data.error) {
                viewRefresh();
              }
            })
            .catch((err) => {
              console.log(err);
              simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
            });
        },
        onBtnNo: () => {},
      }),
    );

    // no api
  };

  // 근로정보 저장 완료
  const onDone = () => {
    storesDispatch(loadStaffWorkListViewStore(staffInfoState.contents.staff_obj.staff_id));
    storesDispatch(globalModalOff());
    onRefresh();
  };

  useImperativeHandle(ref, () => ({
    getItemList: () => {
      return itemInfoList;
    },
  }));

  const updatePrimaryWorkInfo = async (workInfoId) => {
    setLoading(true);

    await HttpInstance.patch(process.env.REACT_APP_STAFF_SERVER + "/staff/primary_work_info", "", {
      params: {
        work_info_id: workInfoId,
      },
    })
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          simpleAlert(storesDispatch, "알림", "대표지점 설정이 완료되었습니다.");
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [checkState, setCheckState] = useState([]);

  // 최초 primary 지점 체크박스 설정
  useEffect(() => {
    if (workInfoListStore.stores.data) {
      for (let i = 0; i < workInfoListStore.stores.data.length; i++) {
        if (workInfoListStore.stores.data[i].is_primary) {
          setCheckState(checkState.map((item, idx) => idx === i));
          break;
        }
      }
    }
  }, [workInfoListStore]);

  // radio check
  const onChangeCheckState = (idx, data) => {
    const branchName = data.base_info.branch_info.branch_name;
    const curIdx = checkState.findIndex((x) => x === true);

    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <Label labelText={"[" + branchName + "] 지점을 대표지점으로 지정하시겠습니까?"} />
        ),
        onBtnYes: () => {
          updatePrimaryWorkInfo(data.work_info_id);
          setCheckState(checkState.map((item, i) => i === idx));
          return true;
        },
        onBtnNo: () => {
          setCheckState(checkState.map((item, i) => i === curIdx));
          return false;
        },
      }),
    );
  };

  // console.log(workInfoListStore);

  const itemList = new itemInfoTable(
    onOpenModal,
    onCancelReg,
    workInfoListStore.stores ? workInfoListStore.stores.data.action_type_list : [],
    checkState,
    onChangeCheckState,
  );

  return (
    <div>
      <div>
        {loading && <Loading />}
        {/* permission */}
        {props.staffInfoState.permission.work_info_tab_add.value ? (
          <TableTitleWithButton
            titleLabel={"근로 내역"}
            buttonText={"추가"}
            onClickEvent={() => onOpenModal("추가", "")}
          />
        ) : (
          <SubTitle titleText="근로 내역" />
        )}
      </div>
      <div style={{ marginTop: "0.25rem" }}>
        <CustomTable
          columns_head={itemList.columns_head}
          table_title={itemList.table_title}
          rest_call={itemList.get_data_from_rest}
          row_render={itemList.create_table}
          rest_data={workInfoListStore.stores.data}
        />
      </div>
    </div>
  );
});
export default BranchWorkInfoCard;

class itemInfoTable {
  table_title = "";
  btnStyleClass = btnStyles();
  actionTypeList = [];
  btnOpenModal = null;
  btnCancel = null;
  checkState = null;
  onChangeCheckState = null;

  constructor(btnOpenModal, btnCancel, actionList, checkState, onChangeCheckState) {
    this.btnOpenModal = btnOpenModal;
    this.btnCancel = btnCancel;
    this.actionTypeList = actionList;
    this.checkState = checkState;
    this.onChangeCheckState = onChangeCheckState;
  }

  showCancel = (approvalStatus, editStatus) => {
    if (approvalStatus === "" && editStatus === "임시저장") return true;
    return false;
  };

  // onClickEvent = (data) => {
  //   this.btnOpenModal(
  //     "보기",
  //     data.work_info_id,
  //     data.base_info.hq_info.hq_id,
  //     data.base_info.branch_info.branch_id,
  //   );
  // };

  create_table = (data, idx) => {
    // radio check
    if (this.checkState[idx] === undefined) {
      this.checkState[idx] = data.is_primary;
    } else {
      data.is_primary = this.checkState[idx];
    }

    return (
      <CustomRow
        key={idx}
        style={{ width: "100%" }}
        // onClick={() => this.onClickEvent(data)}
        // hover
      >
        <CustomTableContents width="5%">
          <CheckBox
            isTable
            defaultValue={this.checkState[idx]}
            onChangeCallback={(e) => {
              if (!e.checked) {
                e.checked = true;
              } else {
                // data.is_primary = e.checked;
                // this.onChangeCheckState(idx, data);

                if (this.onChangeCheckState(idx, data)) {
                  data.is_primary = e.checked;
                } else {
                  e.checked = false;
                }
              }
            }}
          />
        </CustomTableContents>
        <CustomTableContents width="6%">{/* {data.join_status} */}</CustomTableContents>
        <CustomTableContents width="6%">{data.approval_status}</CustomTableContents>
        <CustomTableContents width="6%">{data.edit_status}</CustomTableContents>
        <CustomTableContents width="6%">{data.work_status}</CustomTableContents>
        <CustomTableContents width="12%">{data.base_info.hq_info.hq_name}</CustomTableContents>
        <CustomTableContents width="14%">
          {data.base_info.branch_info.branch_name}
        </CustomTableContents>
        <CustomTableContents width="7%">{data.base_info.rank.org_name}</CustomTableContents>
        <CustomTableContents width="10%">{data.base_info.position.org_name}</CustomTableContents>
        <CustomTableContents width="7%">{data.base_info.join_date}</CustomTableContents>
        <CustomTableContents width="7%">{data.base_info.resign_date}</CustomTableContents>
        <CustomTableContents width="7%">{data.files.is_submit_emp_file}</CustomTableContents>
        <CustomTableContents width="5%">{data.files.is_submit_resign}</CustomTableContents>
        <CustomTableContents width="12%">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              className={clsx({
                [this.btnStyleClass.btnRootRaw]: true,
                [this.btnStyleClass.buttonType2]: true,
              })}
              onClick={() => {
                this.btnOpenModal(
                  "보기",
                  data.work_info_id,
                  data.base_info.hq_info.hq_id,
                  data.base_info.branch_info.branch_id,
                );
              }}
            >
              보기
            </Button>
            <Button
              className={clsx({
                [this.btnStyleClass.btnRootRaw]: true,
                [this.btnStyleClass.buttonType2]: true,
              })}
              onClick={() =>
                this.btnOpenModal(
                  "수정",
                  data.work_info_id,
                  data.base_info.hq_info.hq_id,
                  data.base_info.branch_info.branch_id,
                )
              }
              style={{ marginLeft: "0.25rem" }}
            >
              수정
            </Button>
            {this.showCancel(data.approval_status, data.edit_status) ? (
              <Button
                className={clsx({
                  [this.btnStyleClass.btnRootRaw]: true,
                  [this.btnStyleClass.buttonType4]: true,
                })}
                onClick={() =>
                  this.btnCancel(
                    data.work_info_id,
                    data.staff_id,
                    data.base_info.branch_info.branch_id,
                  )
                }
                style={{ marginLeft: "0.25rem" }}
              >
                등록취소
              </Button>
            ) : (
              <></>
            )}
            {/* {this.actionTypeList[key] === "수정가능" ? (
              <Button
                className={clsx({
                  [this.btnStyleClass.btnRootRaw]: true,
                  [this.btnStyleClass.buttonType2]: true,
                })}
                onClick={() => this.btnPop(true, data.work_info_id)}
                style={{ marginLeft: "0.25rem" }}
              >
                수정
              </Button>
            ) : (
              ""
            )} */}
          </div>
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "대표지점", field: "select" },
    { title: "가입상태", field: "select" },
    { title: "결재상태", field: "approval_status" },
    { title: "수정상태", field: "edit_status" },
    { title: "재직상태", field: "status" },
    { title: "본부", field: "card_no" },
    { title: "지점", field: "card_no" },
    { title: "직위", field: "rank" },
    { title: "직책", field: "position" },
    { title: "입사일", field: "join_date" },
    { title: "퇴사일", field: "resign_date" },
    { title: "근로계약서", field: "card_no" },
    { title: "사직서", field: "card_no" },
    { title: "", field: "remove" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
