import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import CardFixed from "components/Card/CardFixed";
import SwipeableViews from "react-swipeable-views";
import CouponManagement from "./SubItems/CouponManagement";
import FireWallManagement from "./SubItems/FireWallManagement";
import HQManagement from "./SubItems/HQManagement";
import PolicyManagement from "./SubItems/PolicyManagement";

import { Box } from "@mui/material";
import { tabStyleWhite } from "components/CommonComp";
import A11yProps from "components/TabControl/A11yProps";
import TabPanel from "components/TabControl/TabPanel";

const ERPSetting = ({ onClickOpenModal, tabIndex, setTabIndex }) => {
  // console.log("ERPSetting render..");
  const onChangeTabIndex = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <CardFixed
      midDom={
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                bgcolor: "#F5F5F5",
                display: "flex",
                justifyContent: "center",
                borderRadius: "5px",
                boxShadow: "inset 0px 0px 10px rgba(0, 0, 0, 0.04)",
                padding: "0.25rem",
                width: "26.43rem",
                height: "3rem",
                boxSizing: "border-box",
                marginBottom: "1.5rem",
              }}
            >
              <Tabs
                value={tabIndex}
                onChange={onChangeTabIndex}
                textColor="inherit"
                sx={{ minHeight: "40px" }}
                TabIndicatorProps={{
                  style: { backgroundColor: "rgba(0, 0, 0, 0)" },
                }}
              >
                <Tab
                  label="본부 관리"
                  {...A11yProps(0)}
                  style={tabStyleWhite(0, tabIndex)}
                  sx={{ minHeight: "2.5rem", width: "6.25rem" }}
                />
                <Tab
                  label="쿠폰 관리"
                  {...A11yProps(1)}
                  style={tabStyleWhite(1, tabIndex)}
                  sx={{ minHeight: "2.5rem", width: "6.25rem" }}
                />
                <Tab
                  label="정책 관리"
                  {...A11yProps(2)}
                  style={tabStyleWhite(2, tabIndex)}
                  sx={{ minHeight: "2.5rem", width: "6.25rem" }}
                />
                <Tab
                  label="접근 관리"
                  {...A11yProps(3)}
                  style={tabStyleWhite(3, tabIndex)}
                  sx={{ minHeight: "2.5rem", width: "6.25rem" }}
                />
              </Tabs>
            </Box>
          </div>

          <SwipeableViews
            index={tabIndex}
            onChangeIndex={onChangeTabIndex}
            animateTransitions={false}
          >
            <TabPanel value={tabIndex} index={0} noPadding>
              <HQManagement onClickOpenModal={onClickOpenModal} />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <CouponManagement onClickOpenModal={onClickOpenModal} />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              <PolicyManagement />
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
              <FireWallManagement onClickOpenModal={onClickOpenModal} />
            </TabPanel>
            {/* <TabPanel value={tabIndex} index={4}>
              <SettingChangeHistory />
            </TabPanel> */}
          </SwipeableViews>
        </div>
      }
      isLoading={false}
      rootStyle={{}}
    />
  );
};

export default ERPSetting;
