import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { listEmpty, parseMoneyFormat } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import SubTitle from "components/PageTitle/SubTitle";

const UnpaidList = ({ unPaidListState }) => {
  const unPaidList = new unpaidTable();
  return (
    <div
      style={{
        paddingTop: "3rem",
      }}
    >
      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText={"미납금 목록"} />
      </div>

      <CustomTable
        columns_head={unPaidList.columns_head}
        table_title={unPaidList.table_title}
        rest_call={unPaidList.get_data_from_rest}
        row_render={unPaidList.create_table}
        rest_data={unPaidListState}
      />
    </div>
  );
};
export default UnpaidList;

class unpaidTable {
  table_title = "";
  btnStyleClass = btnStyles();

  create_table = (data, idx) => {
    // console.log("dl", data);
    return (
      <CustomRow style={{ width: "100%" }} key={idx}>
        <CustomTableContents width="30%">{data.purchase_code}</CustomTableContents>
        <CustomTableContents width="25%">{data.reg_date}</CustomTableContents>
        <CustomTableContents width="25%" $isNumber>
          {parseMoneyFormat(data.unpaid_price) + " 원"}
        </CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "구매코드", field: "purchase_code" },
    { title: "등록일", field: "due_date" },
    { title: "미납금액", field: "paid_price" },
  ];

  get_data_from_rest = (stateData) => {
    if (listEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
