import * as ActionTypes from "./action_type";

///DeviceList
export const loadDeviceList = (branch_id) => {
  return {
    type: ActionTypes.DeviceListAction,
    branch_id: branch_id,
  };
};

export const loadDeviceListSuccess = (data) => {
  return {
    type: ActionTypes.DeviceListSuccessAction,
    payload: data,
  };
};

export const loadDeviceListFail = (data) => {
  return {
    type: ActionTypes.DeviceListFailAction,
    payload: [],
  };
};

export const loadDeviceListReset = (data) => {
  return {
    type: ActionTypes.DeviceListResetAction,
    payload: data,
  };
};

///DeviceScheduleList
export const loadDeviceScheduleList = (branch_id) => {
  return {
    type: ActionTypes.DeviceScheduleListAction,
    branch_id: branch_id,
  };
};

export const loadDeviceScheduleListSuccess = (data) => {
  return {
    type: ActionTypes.DeviceScheduleListSuccessAction,
    payload: data,
  };
};

export const loadDeviceScheduleListFail = (data) => {
  return {
    type: ActionTypes.DeviceScheduleListFailAction,
    payload: [],
  };
};

export const loadDeviceScheduleListReset = (data) => {
  return {
    type: ActionTypes.DeviceScheduleListResetAction,
    payload: data,
  };
};

///Device PRESET
export const loadSchedulePreSetInfoAction = (branch_id) => {
  return {
    type: ActionTypes.SchedulePreSetInfoAction,
    branch_id: branch_id,
  };
};

export const loadSchedulePreSetInfoSuccessAction = (data) => {
  return {
    type: ActionTypes.SchedulePreSetInfoSuccessAction,
    payload: data,
  };
};

export const loadSchedulePreSetInfoFailAction = (data) => {
  return {
    type: ActionTypes.SchedulePreSetInfoFailAction,
    payload: [],
  };
};

export const loadSchedulePreSetInfoResetAction = (data) => {
  return {
    type: ActionTypes.SchedulePreSetInfoResetAction,
    payload: data,
  };
};

///Holyday
export const loadHolyDayInfoAction = (date_year) => {
  return {
    type: ActionTypes.HolyDayInfoAction,
    date_year: date_year,
  };
};

export const loadHolyDayInfoSuccessAction = (data) => {
  return {
    type: ActionTypes.HolyDayInfoSuccessAction,
    payload: data,
  };
};

export const loadHolyDayInfoFailAction = (data) => {
  return {
    type: ActionTypes.HolyDayInfoFailAction,
    payload: [],
  };
};

export const loadHolyDayInfoResetAction = (data) => {
  return {
    type: ActionTypes.HolyDayInfoResetAction,
    payload: data,
  };
};
