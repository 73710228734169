import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import { globalModalOn } from "modules/actions/Modal";
import { useDispatch } from "react-redux";
import SmsAuthListModal from "./SmsAuthListModal";

const SearchResultTableTotal = ({
  searchResult,
  navigate,
  curPage,
  setCurPage,
  totalPage,
  onClickSearch,
}) => {
  const storesDispatch = useDispatch();

  const openModal = (data) => {
    storesDispatch(
      globalModalOn({
        show: true,
        title: "문자 인증 내역",
        Content: <SmsAuthListModal listData={data} />,
      }),
    );
  };

  const searchResultList = new resultTable(navigate, openModal);

  const doSearch = (page) => {
    onClickSearch(false, page);
  };

  return (
    <div style={{ margin: "0px 1px" }}>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
        // width="160rem"
      />
      <CustomPagination
        curPage={curPage}
        // pageSelection={setCurPage}
        totalCnt={totalPage}
        doSearch={doSearch}
      />
    </div>
  );
};
export default SearchResultTableTotal;

class resultTable {
  table_title = "";
  onNavigate = null;
  openModal = null;

  constructor(navigate, openModal) {
    this.onNavigate = navigate;
    this.openModal = openModal;
  }

  onClickEvent = (data) => {
    this.openModal(data.base_result.auth_hist);
  };

  getLastAuthDate = (auth_hist) => {
    if (!auth_hist || auth_hist.length === 0) return "";
    return auth_hist[auth_hist.length - 1].auth_at;
  };

  getLastUUID = (auth_hist) => {
    if (!auth_hist || auth_hist.length === 0) return "";
    const fullUUID = auth_hist[auth_hist.length - 1].uuid;
    return fullUUID.slice(-12); // 마지막 12자리만 반환
  };

  getAuthCount = (auth_hist) => {
    if (!auth_hist) return 0;
    return auth_hist.length;
  };

  create_table = (data, idx) => {
    return (
      <CustomRow
        style={{}}
        key={idx}
        onClick={() => this.onClickEvent(data)}
        // sx={{
        //   "&.MuiTableRow-root:hover": {
        //     backgroundColor: "red",
        //   },
        // }}
        // className={this.styleClasses.MuiTableRow}
        hover
      >
        <CustomTableContents>{data.base_result.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.base_result.member_type}</CustomTableContents>
        <CustomTableContents>{data.base_result.member_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.base_result.member_info.member_name}</CustomTableContents>
        <CustomTableContents>{data.base_result.member_info.phone}</CustomTableContents>
        <CustomTableContents>{data.base_result.member_info.birth}</CustomTableContents>
        <CustomTableContents>{data.base_result.member_info.gender}</CustomTableContents>
        <CustomTableContents>
          {this.getLastAuthDate(data.base_result.auth_hist)}
        </CustomTableContents>
        <CustomTableContents>{this.getAuthCount(data.base_result.auth_hist)}</CustomTableContents>
        <CustomTableContentsEND>
          {this.getLastUUID(data.base_result.auth_hist)}
        </CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "지점", width: "10%" },
    { title: "구분", width: "6%" },
    { title: "회원번호", width: "9%" },
    { title: "이름", width: "9%" },
    { title: "연락처", width: "10%" },
    { title: "생년월일", width: "9%" },
    { title: "성별", width: "6%" },
    { title: "최근 문자인증 일시", width: "14%" },
    { title: "기간 내 인증 수", width: "9%" },
    { title: "UUID (끝 12자리)", width: "12%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
