export const payment_input_state = {
  total_paid: 0,
  pay_method: [],
  purchase_code: "",
  unpaid_info: {
    promise_date: "",
    reason: "",
    unpaid_price: 0,
  },
};

export const payment_info_state = {
  paid_price: 0,
  purchase_code: "",
  unpaid_price: 0,
  paid_item_list: [],
  payment_input: payment_input_state,
};

export const paid_item_list_state = {
  MembershipIdx: 0,
  orderIdx: 0,
  type_key: "",
  content_obj: {
    count: 0,
    dis: 0,
    item_id: "",
    item_type: "",
    pay_order: 0,
    price: 0,
    tab_idx: 0,
    type: "",
  },
};

export const pay_method_state = {
  id: "",
  pay_method: "카드",
  card_comp: "",
  account_name: "",
  // installment_plan: 0,
  installment: "",
  price: 0,
  pay_date: "",
  approve_no: "",
  card_no: "",
  buyer: "",
  sum: 0,
  bank_user_name: "",
};
