import { useQuery } from "@tanstack/react-query";
import { getStaffList } from "apis/staff/staff_list_api";
import CardFixed from "components/Card/CardFixed";
import { excelExport, getPermission, simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import StaffListSearchCard from "./StaffListSearchCard";
import StaffListSearchResult from "./StaffListSearchResult";
import { useStaffListStore } from "./modules/store/staffListState";

const StaffListView = () => {
  const storesDispatch = useDispatch();
  // 전역 상태 관리
  const {
    staffListSearchParams: searchParams,
    setSearchParams,
    staffListHqIdx: hqIdx,
    setPage,
    staffListPage: page,
    setHqBranchInfo,
    resetSearchParams,
  } = useStaffListStore();

  // 페이지 상태 관리
  const [hqBranchListData, setHqBranchListData] = useState({});
  const [branchList, setBranchList] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);
  const [isExcel, setIsExcel] = useState(false);
  // API 상태 관리
  const [isGetStaffList, setIsGetStaffList] = useState(false);
  const queryData = { isExcel: isExcel, page: page, searchParams: searchParams };

  // API Query
  // [GET] 직원 리스트 검색
  const { isFetching, data: staffListData } = useQuery({
    queryKey: ["staffList", queryData],
    queryFn: () => getStaffList(queryData),
    enabled: isGetStaffList,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setIsGetStaffList(false);
    },
  });

  // API 받아온 데이터 세팅
  useEffect(() => {
    // 검색 결과 데이터 세팅
    if (staffListData) {
      if (isExcel) {
        exportToExcel(staffListData.data.data, "직원정보");
      } else {
        setSearchResult(staffListData.data.data);
        setTotalPage(staffListData.data.total_page);
      }
    }
  }, [staffListData]);

  // 권한에 따른 본부와 지점 리스트 최초 세팅
  useEffect(() => {
    getPermission(storesDispatch, "STAFF_MENU/STAFF_SERVICE", true, false, true).then((res) => {
      if (res.hq_list.length > 0 && res.branch_list.length > 0) {
        // 첫 검색 조건
        if (searchParams.hq_info.hq_id === "") {
          setSearchParams("hq_info", res.target_list[0].hq_info);
          setSearchParams("branch_info", res.target_list[0].branch_list[0]);
          setSearchParams("combo_selector", res);
        }
        // 본부 및 지점 정보
        setHqBranchListData(res);
        setBranchList(res.branch_list[hqIdx]);
        // ComboBox 첫번째 본부 및 지점 정보
        setHqBranchInfo("hq_info", res.target_list[0].hq_info);
        setHqBranchInfo("branch_info", res.target_list[0].branch_list[0]);
      }
    });
  }, []);

  // 본부 선택에 따른 지점 리스트 다시 세팅
  useEffect(() => {
    if (hqBranchListData.branch_list) setBranchList(hqBranchListData.branch_list[hqIdx]);
    //  첫 페이지 진입이 아니고 본부 변경 시 지점 초기화 세팅
    if (!isFirstEnterPage) {
      const branchParams = {
        branch_id: hqBranchListData.target_list[hqIdx].branch_list[0].branch_id,
        branch_name: hqBranchListData.target_list[hqIdx].branch_list[0].branch_name,
      };
      setSearchParams("branch_info", branchParams);
    }
  }, [hqIdx]);

  // 컴포넌트 들어오면 검색 실행
  useEffect(() => {
    onClickSearch(false, page);
    // 첫 페이지 여부
    setIsFirstEnterPage(false);
  }, []);

  const exportToExcel = (data, fileName) => {
    const newData = [];
    for (let i = 0; i < data.length; i++) {
      const row = {};
      row.사번 = data[i].personal_info.sabun;
      row.직원명 = data[i].personal_info.staff_name;
      row.연락처 = data[i].personal_info.phone;
      row.이메일 = data[i].personal_info.email;
      row.주민등록등본 = data[i].files.is_submit_id_card;
      row.통장사본 = data[i].files.is_submit_account_copy;
      row.결재상태 = data[i].approval_status;
      row.수정상태 = data[i].edit_status;
      row.본부 = data[i].base_info.hq_info.hq_name;
      row.지점 = data[i].base_info.branch_info.branch_name;
      row.재직상태 = data[i].work_status;
      row.직위 = data[i].base_info.rank.org_name;
      row.직책 = data[i].base_info.position.org_name;
      row.입사일 = data[i].base_info.join_date;
      row.퇴사일 = data[i].base_info.resign_date;
      row.근로계약서 = data[i].files.is_submit_emp_file;
      row.사직서 = data[i].files.is_submit_resign;
      row.CS업무 = data[i].work_role.is_cs ? "Y" : "N";
      row.PT업무 = data[i].work_role.is_pt ? "Y" : "N";
      newData.push(row);
    }

    excelExport(newData, fileName);
  };

  const onClickExcelDown = () => {
    onClickSearch(true);
  };

  const onClickSearch = async (isExcel, page) => {
    setPage(page);
    setIsExcel(isExcel);
    setIsGetStaffList(true);
  };

  return (
    <div>
      {isFetching && <Loading />}

      <SearchCard
        searchParams={searchParams}
        hqBranchListData={hqBranchListData}
        branchList={branchList}
        onClickSearch={onClickSearch}
        resetSearchParams={resetSearchParams}
      />

      <SearchResultCard
        searchResult={searchResult}
        onClickExcelDown={onClickExcelDown}
        page={page}
        totalPage={totalPage}
        onClickSearch={onClickSearch}
      />
    </div>
  );
};
export default StaffListView;

//

const SearchCard = ({
  searchParams,
  hqBranchListData,
  branchList,
  onClickSearch,
  resetSearchParams,
}) => {
  const storesDispatch = useDispatch();

  //나중에 지울(죽전전용)
  const tempValidation = () => {
    if (!searchParams.hq_info.hq_id || !searchParams.branch_info.branch_id) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="본부와 지점은 필수 검색조건입니다." />,
          onBtnOk: () => {},
        }),
      );
    } else {
      onClickSearch(false, 1);
    }
  };

  return (
    <CardFixed
      midDom={
        <div>
          <StaffListSearchCard
            hqBranchListData={hqBranchListData}
            branchList={branchList}
            onClickSearch={onClickSearch}
          />

          <div
            style={{
              display: "flex",
              float: "right",
              marginLeft: "auto",
              marginTop: "0.625rem",
              gap: "0.3rem",
            }}
          >
            <NormalBtn
              name="초기화"
              theme="white"
              width="6.25rem"
              onClick={() => {
                resetSearchParams();
              }}
            />
            <NormalBtn
              name="검색"
              width="6.25rem"
              onClick={() => {
                tempValidation();
                //나중에 밑에꺼로 살려야함
                // onClickSearch(false, 1);
              }}
            />
          </div>
        </div>
      }
      isLoading={false}
      rootStyle={{
        // width: "20rem",
        // height: "15.312rem",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};

const SearchResultCard = ({ searchResult, onClickExcelDown, page, totalPage, onClickSearch }) => {
  const navigate = useNavigate();
  const storesDispatch = useDispatch();
  const [canExcel, setCanExcel] = useState(false);

  const checkPermission = async () => {
    const result = await getPermission(storesDispatch, "ACCOUNT_PERMISSION/EXCEL");
    setCanExcel(result);
  };
  useEffect(() => {
    checkPermission();
  }, []);

  return (
    <CardFixed
      midDom={
        <div>
          <div
            style={{
              display: "flex",
              float: "right",
              marginLeft: "auto",
              marginBottom: "0.625rem",
              gap: "0.3rem",
            }}
          >
            {canExcel && (
              <NormalBtn name="엑셀 다운로드" theme="green" onClick={onClickExcelDown} />
            )}

            <NormalBtn
              name="직원 등록"
              width="6.25rem"
              onClick={() => {
                navigate("/staff_info/", {
                  state: {
                    staff_id: "",
                    is_view: false,
                  },
                });
              }}
            />
          </div>

          <StaffListSearchResult
            searchResult={searchResult}
            navigate={navigate}
            curPage={page}
            totalPage={totalPage}
            onClickSearch={onClickSearch}
          />
        </div>
      }
      isLoading={false}
      rootStyle={{
        // width: "20rem",
        // height: "15.312rem",
        marginTop: "0.625rem",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};
