import IconButton from "components/Button/IconButton";
import { contentTypeJson, simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const EditTeamNameModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const [isModify, setIsModify] = useState(false);
  const [isModifyBoss, setIsModifyBoss] = useState(false);
  const [nameList, setNameList] = useState([]);
  const [staffIdx, setStaffIdx] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const staffNameList = modalParam.data.team_member_list.map((el) => el.staff_info.staff_name);
    setNameList(staffNameList);
  }, [modalParam]);

  const editTeamName = async () => {
    try {
      const formData = new FormData();

      formData.append("new_info", JSON.stringify(modalParam.data.team_info));
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_STAFF_SERVER + "/pt_team/update/name",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공 -> 메인 리스트 갱신
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label labelText="저장되었습니다." />
              </>
            ),
            onBtnOk: () => {
              onModalDone(modalParam.branch_id);
              setIsModify(false);
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const editTeamBoss = async () => {
    try {
      setLoading(true);
      const res = await HttpInstance.get(
        process.env.REACT_APP_STAFF_SERVER + "/pt_team/update/boss",
        {
          params: {
            team_id: modalParam.data.team_info.team_id,
            staff_id:
              staffIdx !== undefined &&
              modalParam.data.team_member_list[staffIdx].staff_info.staff_id,
          },
        },
      );
      if (!res.data.data) {
        //성공 -> 메인 리스트 갱신
        setIsModifyBoss(false);
        onModalDone(modalParam.branch_id);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  const editName = () => {
    if (!isModify) {
      setIsModify(true);
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="저장 하시겠습니까?" />
            </>
          ),
          onBtnYes: () => {
            //저장 POST
            editTeamName();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const editBoss = () => {
    if (!isModifyBoss) {
      setIsModifyBoss(true);
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="저장 하시겠습니까?" />
            </>
          ),
          onBtnYes: () => {
            //저장 POST
            editTeamBoss();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  return (
    <div style={{ width: "18rem" }}>
      {loading && <Loading />}

      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="팀명" />
      </div>
      <div style={{ display: "flex" }}>
        <TextField
          textBoxWidth={"15rem"}
          defaultValue={modalParam.data.team_info.team_name}
          textMarginRight={"0.2rem"}
          disabled={!isModify}
          onBlur={(e) => {
            modalParam.data.team_info.team_name = e.target.value;
          }}
        />
        <IconButton
          type="editBorder"
          onClick={() => {
            editName();
          }}
        />
      </div>

      <div style={{ display: "flex", marginTop: "0.7rem" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="팀장" />
      </div>
      <div style={{ display: "flex" }}>
        <ComboBox
          comboItemWidth={"15rem"}
          data={nameList}
          marginRight={"0.2rem"}
          defaultValue={modalParam.data.team_view_info.boss_name}
          disabled={!isModifyBoss}
          onChangeCallback={(e) => {
            const idx = modalParam.data.team_member_list.findIndex(
              (el) => el.staff_info.staff_name === e.target.value,
            );
            if (idx !== -1) {
              setStaffIdx(idx);
            }
          }}
        />
        <IconButton
          type="editBorder"
          onClick={() => {
            editBoss();
          }}
        />
      </div>
    </div>
  );
};
export default EditTeamNameModal;
