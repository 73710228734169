import CardFixed from "components/Card/CardFixed";
import { listEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";

const ChangeSummaryInfo = ({ totalSummary }) => {
  const summaryList = new summaryTable();
  // console.log("ChangeSummaryInfo  ", totalSummary);
  return (
    <CardFixed
      topDom={<div>상품 변경 금액</div>}
      midDom={
        <div>
          <CustomTable
            columns_head={summaryList.columns_head}
            table_title={summaryList.table_title}
            rest_call={summaryList.get_data_from_rest}
            row_render={summaryList.create_table}
            rest_data={[totalSummary]}
          />
        </div>
      }
      isLoading={false}
      rootStyle={{
        width: "76.88rem",
        marginTop: "0.625rem",
        // height: "229px",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};
export default ChangeSummaryInfo;

class summaryTable {
  table_title = "";

  getItemPrice = (items) => {
    let res = 0;
    for (let i = 0; i < items.length; i++) {
      // res += items[i].paid_price;
      res += items[i];
    }
    return res.toLocaleString();
  };

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx} style={{ width: "100%" }}>
        <CustomTableContents>{this.getItemPrice(data.mbshpItems)}</CustomTableContents>
        <CustomTableContents>{this.getItemPrice(data.otherItems)}</CustomTableContents>
        <CustomTableContents>{data.changeTotal.toLocaleString()}</CustomTableContents>
        <CustomTableContents>{data.diffTotal.toLocaleString()}</CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "기존상품 총액 (A)", width: "25%" },
    { title: "환불처리 금액 (B)", width: "25%" },
    { title: "변경상품 총액 (C)", width: "25%" },
    { title: "차액 (C-(A-B))", width: "25%" },
  ];

  get_data_from_rest = (stateData) => {
    if (listEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
