import {
  loadClassHistorySuccess,
  loadClassHistoryView,
  loadClassHistoryViewFail,
  loadClassHistoryViewReset,
  loadCSHistorySuccess,
  loadCSHistoryView,
  loadCSHistoryViewFail,
  loadCSHistoryViewReset,
  loadMembershipHistorySuccess,
  loadMembershipHistoryView,
  loadMembershipHistoryViewFail,
  loadMembershipHistoryViewReset,
  loadPurchaseHistorySuccess,
  loadPurchaseHistoryView,
  loadPurchaseHistoryViewFail,
  loadPurchaseHistoryViewReset,
} from "./history_act";
import {
  loadPurchaseDetailModal,
  loadPurchaseDetailModalFail,
  loadPurchaseDetailModalReset,
  loadPurchaseDetailModalSuccess,
} from "./purchase_act";
import {
  loadMemberView,
  loadMemberViewFail,
  loadMemberViewReset,
  loadMemberViewSuccess,
} from "./root_act";

export const loadMemberViewStore = (member_id) => loadMemberView(member_id);

export const loadMembershipHistoryStore = (member_id, page_num) =>
  loadMembershipHistoryView(member_id, page_num);
export const loadClassHistoryStore = (member_id, page_num) =>
  loadClassHistoryView(member_id, page_num);
export const loadPurchaseHistoryStore = (member_id, page_num) =>
  loadPurchaseHistoryView(member_id, page_num);

export const loadCSHistoryStore = (member_id, page_num) => loadCSHistoryView(member_id, page_num);

export const loadPurchaseDetailStore = (member_id, purchase_code) =>
  loadPurchaseDetailModal(member_id, purchase_code);

export const memberViewAction = {
  loadMemberViewSuccess,
  loadMemberViewFail,
  loadMemberViewReset,

  loadMembershipHistorySuccess,
  loadMembershipHistoryViewFail,
  loadMembershipHistoryViewReset,

  loadClassHistorySuccess,
  loadClassHistoryViewFail,
  loadClassHistoryViewReset,

  loadPurchaseHistorySuccess,
  loadPurchaseHistoryViewFail,
  loadPurchaseHistoryViewReset,

  loadPurchaseDetailModalSuccess,
  loadPurchaseDetailModalFail,
  loadPurchaseDetailModalReset,

  loadCSHistorySuccess,
  loadCSHistoryViewFail,
  loadCSHistoryViewReset,
};
