import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import CardFixed from "components/Card/CardFixed";
import { tabBorder, tabStyle } from "components/CommonComp";
import { simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import TabPanel from "components/TabControl/TabPanel";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import MembershipDataListView from "./MembershipDataListView";

const MasterChangeView = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [expiredMbshps, setExpiredMbshps] = useState([]);
  const [selectedExpiredMbshp, setSelectedExpiredMbshp] = useState();
  const [expiredMbshpNames, setExpiredMbshpNames] = useState([]);
  const [expMbshpPeriod, setExpMbshpPeriod] = useState({ from_date: "", to_date: "" });

  useEffect(() => {
    if (modalParam) {
      GetExpiredMbshps();
    }
  }, [modalParam]);

  useEffect(() => {
    setExpMbshpPeriod({
      from_date: selectedExpiredMbshp ? selectedExpiredMbshp.mbshp.from_date : "",
      to_date: selectedExpiredMbshp ? selectedExpiredMbshp.mbshp.to_date : "",
    });
  }, [selectedExpiredMbshp]);

  const GetExpiredMbshps = async () => {
    await HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/change/force/exp_mbshp", {
      params: {
        member_id: modalParam.active_info.member_id,
      },
    })
      .then((res) => {
        if (!res.data.msg) {
          setExpiredMbshps(res.data.data);
          for (let i = 0; i < res.data.data.membership_list.length; i++) {
            const idx = (i + 1).toString();
            const txt =
              "[" +
              idx +
              "] " +
              res.data.data.membership_list[i].item_name +
              " (" +
              res.data.data.membership_list[i].service_status +
              ")";
            setExpiredMbshpNames((prev) => [...prev, { item_name: txt, idx: i }]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const onClickChangeExpMbshp = () => {
    if (!expMbshpPeriod.from_date || !expMbshpPeriod.to_date) {
      simpleAlert(storesDispatch, "알림", "유효기간을 선택해주세요.");
    } else if (!selectedExpiredMbshp) {
      simpleAlert(storesDispatch, "알림", "복구할 회원권을 선택해주세요.");
    } else {
      simpleAlert(storesDispatch, "알림", "만료 회원권을 복구하시겠습니까?", false, false, () => {
        changeExpMbshp();
      });
    }
  };

  const changeExpMbshp = async () => {
    await HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/change/force/revive_mbshp", {
      params: {
        member_id: modalParam.active_info.member_id,
        membership_id: selectedExpiredMbshp.membership_id,
        from_date: expMbshpPeriod.from_date,
        to_date: expMbshpPeriod.to_date,
      },
    })
      .then((res) => {
        if (!res.data.msg) {
          simpleAlert(storesDispatch, "알림", "복구되었습니다.");
          storesDispatch(globalModalOff()); //모달닫기
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data.msg);
      });
  };

  const onChangeTabIndex = (event, newIndex) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: <Label labelText="변경사항이 저장되지 않습니다. 이동하시겠습니까?" />,
        onBtnYes: () => {
          setTabIndex(newIndex);
        },
        onBtnNo: () => {},
      }),
    );
  };

  return (
    <>
      {loading && <Loading />}
      <CardFixed
        midDom={
          <div style={{ width: "42rem" }}>
            <Box sx={{ bgcolor: "background.paper" }}>
              <Tabs
                value={tabIndex}
                onChange={onChangeTabIndex}
                textColor="inherit"
                TabIndicatorProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0)" } }}
              >
                {modalParam &&
                  modalParam.membership_data_list.map((el, idx) => (
                    <Tab
                      style={tabStyle(idx, tabIndex)}
                      key={idx}
                      label={el.membership_list.mbshp_status}
                    />
                  ))}
              </Tabs>
            </Box>
            <SwipeableViews
              index={tabIndex}
              onChangeIndex={onChangeTabIndex}
              style={tabBorder}
              animateTransitions={false}
            >
              {modalParam &&
                modalParam.membership_data_list.map((el, idx) => (
                  <TabPanel value={tabIndex} index={idx} key={idx}>
                    <MembershipDataListView
                      mbshp={el}
                      no={idx}
                      activeInfo={modalParam.active_info}
                      onModalDone={onModalDone}
                    />
                  </TabPanel>
                ))}
            </SwipeableViews>
            <div style={{ display: "flex", marginLeft: "1rem", marginTop: "1rem" }}>
              <ComboBox
                labelWidth={"5rem"}
                comboItemWidth={"20rem"}
                labelText="만료 회원권"
                data={expiredMbshpNames.map((el) => el.item_name)}
                onChangeCallback={(e) => {
                  const idx = expiredMbshpNames.findIndex((el) => el.item_name === e.target.value);
                  setSelectedExpiredMbshp(expiredMbshps.membership_list[idx]);
                }}
              />
              <NormalBtn
                name="복구"
                onClick={() => {
                  onClickChangeExpMbshp();
                }}
              />
            </div>
            <div style={{ display: "flex", marginLeft: "1rem", marginTop: "0.5rem" }}>
              <DatePicker
                labelWidth={"5rem"}
                minWidth={"10rem"}
                labelText="유효기간"
                disabled={!selectedExpiredMbshp}
                defaultValue={expMbshpPeriod.from_date}
                onBlur={(e) => {
                  if (
                    setExpMbshpPeriod.to_date &&
                    new Date(e) >= new Date(setExpMbshpPeriod.to_date)
                  ) {
                    simpleAlert(
                      storesDispatch,
                      "알림",
                      "시작일은 종료일보다 클 수 없습니다.",
                      true,
                    );
                  } else {
                    setExpMbshpPeriod({ ...expMbshpPeriod, from_date: e });
                  }
                }}
              />
              <DatePicker
                labelWidth={"0.5rem"}
                minWidth={"10rem"}
                labelText="~"
                disabled={!selectedExpiredMbshp}
                defaultValue={expMbshpPeriod.to_date}
                onBlur={(e) => {
                  if (
                    setExpMbshpPeriod.from_date &&
                    new Date(e) <= new Date(setExpMbshpPeriod.from_date)
                  ) {
                    simpleAlert(
                      storesDispatch,
                      "알림",
                      "시작일은 종료일보다 클 수 없습니다.",
                      true,
                    );
                  } else {
                    setExpMbshpPeriod({ ...expMbshpPeriod, to_date: e });
                  }
                }}
              />
            </div>
          </div>
        }
        isLoading={false}
        rootStyle={{
          marginLeft: "0.625rem",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />
    </>
  );
};
export default MasterChangeView;
