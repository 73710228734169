import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";

const UnsubsListResultTable = ({
  openModal,
  searchResult,
  curPage,
  setCurPage,
  totalPage,
  onClickSearch,
}) => {
  const btnStyleClass = btnStyles();

  const searchResultList = new resultTable();

  const doSearch = (page) => {
    onClickSearch(page);
  };

  return (
    <div>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
        // width="160rem"
      />
      <CustomPagination curPage={curPage} totalCnt={totalPage} doSearch={doSearch} />
    </div>
  );
};
export default UnsubsListResultTable;

class resultTable {
  table_title = "";

  // constructor() {}

  create_table = (data, idx) => {
    return (
      <CustomRow style={{}} key={idx}>
        <CustomTableContents>{}</CustomTableContents>
        <CustomTableContents>{/*data.branch_info.branch_name*/}</CustomTableContents>
        <CustomTableContents>{}</CustomTableContents>
        <CustomTableContents>{}</CustomTableContents>
        <CustomTableContents>{}</CustomTableContents>
        <CustomTableContents>{}</CustomTableContents>
        <CustomTableContents>{}</CustomTableContents>
        <CustomTableContentsEND></CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "거부일시", width: "12.5%" },
    { title: "지점", width: "12.5%" },
    { title: "구분", width: "12.5%" },
    { title: "회원번호", width: "12.5%" },
    { title: "이름", width: "12.5%" },
    { title: "연락처", width: "12.5%" },
    { title: "수강상태", width: "12.5%" },
    { title: "거부방식", width: "12.5%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
