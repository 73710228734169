import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import { useEffect, useRef, useState } from "react";

const MemberContidionContents = ({ searchParam, setSearchParam, onClickSearch, resetFlag }) => {
  const textBoxWidth = "20rem";
  const textBoxWidthShort = "9.15rem";
  const phoneBoxWidth = "6rem";
  const [phone, setPhone] = useState({
    p1: "",
    p2: "",
    p3: "",
  });
  const phoneRef1 = useRef();
  const phoneRef2 = useRef();
  const phoneRef3 = useRef();

  // 전화번호 입력 이벤트 (모든 칸)
  const onInputPhone = () => {
    let res = "";

    if (phone.p1 && !phone.p2 && phone.p3) {
      res = "err";
    } else {
      res = phone.p1 + "-" + phone.p2 + "-" + phone.p3;
    }
    res = res.replace("--", "-");

    setSearchParam((cur) => {
      const obj = { ...cur };
      obj.base_info.phone = res;
      return obj;
    });
  };

  const LabelTop = ({ text }) => {
    return <Label labelText={text} color="#777" justifyContent="flex-start" marginTop="0.5rem" />;
  };

  // 연락처 입력 초기화
  useEffect(() => {
    setPhone({ p1: "", p2: "", p3: "" });
  }, [resetFlag]);

  // console.log(searchParam);
  return (
    <div
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onClickSearch(false, 1);
        }
      }}
    >
      <LabelTop text={"구분"} />
      <ComboBox
        labelMarginRight="0rem"
        comboItemWidth={textBoxWidth}
        data={["전체", "회원", "고객"]}
        defaultValue={searchParam.member_type || "전체"}
        onChangeCallback={(e) => {
          searchParam.member_type = e.target.value === "전체" ? "" : e.target.value;
        }}
      />

      <LabelTop text={"성별"} />
      <ComboBox
        labelMarginRight="0rem"
        comboItemWidth={textBoxWidth}
        data={["전체", "남", "여"]}
        defaultValue={searchParam.base_info.gender || "전체"}
        onChangeCallback={(e) => {
          searchParam.base_info.gender = e.target.value === "전체" ? "" : e.target.value;
        }}
      />

      <LabelTop text={"회원번호"} />
      <TextField
        labelMarginRight="0rem"
        textBoxWidth={textBoxWidth}
        defaultValue={searchParam.member_info.member_no}
        onChangeCallback={(e) => {
          searchParam.member_info.member_no = e;
        }}
      />

      <LabelTop text={"출입방식"} />
      <ComboBox
        labelMarginRight="0rem"
        comboItemWidth={textBoxWidth}
        data={["전체", "카드", "앱"]}
        defaultValue={searchParam.access.access_method || "전체"}
        onChangeCallback={(e) => {
          searchParam.access.access_method = e.target.value === "전체" ? "" : e.target.value;
        }}
      />

      <LabelTop text={"이름"} />
      <TextField
        labelMarginRight="0rem"
        textBoxWidth={textBoxWidth}
        defaultValue={searchParam.member_info.member_name}
        onChangeCallback={(e) => {
          searchParam.member_info.member_name = e;
        }}
      />

      <LabelTop text={"연락처"} />
      <div style={{ display: "flex", justifyContent: "start" }}>
        <TextField
          inputRef={phoneRef1}
          labelMarginRight={"0rem"}
          textMarginRight={"0.25rem"}
          labelWidth={"0rem"}
          textBoxWidth={phoneBoxWidth}
          defaultValue={phone.p1}
          maxLength={3}
          onChangeCallback={(e) => {
            phone.p1 = e;
            onInputPhone();
            if (phone.p1.length === 3) {
              phoneRef2.current.select();
            }
          }}
        />
        <TextField
          inputRef={phoneRef2}
          textBoxWidth={phoneBoxWidth}
          textMarginRight="0.25rem"
          labelMarginRight="0.25rem"
          labelWidth="0.5rem"
          labelText="-"
          maxLength={4}
          defaultValue={phone.p2}
          onChangeCallback={(e) => {
            phone.p2 = e;
            onInputPhone();
            if (phone.p2.length === 4) {
              phoneRef3.current.select();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Backspace" && !phone.p2) {
              phoneRef1.current.select();
            }
          }}
        />
        <TextField
          inputRef={phoneRef3}
          textBoxWidth={phoneBoxWidth}
          textMarginRight="0.25rem"
          labelMarginRight="0.25rem"
          labelWidth="0.5rem"
          labelText="-"
          maxLength={4}
          defaultValue={phone.p3}
          onChangeCallback={(e) => {
            phone.p3 = e;
            onInputPhone();
          }}
          onKeyDown={(e) => {
            if (e.key === "Backspace" && !phone.p3) {
              phoneRef2.current.select();
            }
          }}
        />
      </div>
      <LabelTop text={"개인정보 수신 동의"} />
      <ComboBox
        labelMarginRight="0rem"
        comboItemWidth={textBoxWidth}
        data={["전체", "동의", "비동의"]}
        defaultValue={searchParam.base_info.agree_personal || "전체"}
        onChangeCallback={(e) => {
          searchParam.base_info.agree_personal = e.target.value === "전체" ? "" : e.target.value;
        }}
      />

      <LabelTop text={"마케팅 수신 동의"} />
      <ComboBox
        labelMarginRight="0rem"
        comboItemWidth={textBoxWidth}
        data={["전체", "동의", "비동의"]}
        defaultValue={searchParam.base_info.agree_marketing || "전체"}
        onChangeCallback={(e) => {
          searchParam.base_info.agree_marketing = e.target.value === "전체" ? "" : e.target.value;
        }}
      />

      <LabelTop text={"생년월일"} />
      <div style={{ display: "flex" }}>
        <TextField
          labelMarginRight="0rem"
          textMarginRight="0.5rem"
          textBoxWidth={textBoxWidthShort}
          maxValue={2099}
          maxLength={4}
          isStringNumber
          endAdornment="년"
          defaultValue={searchParam.base_info.birth_year_from}
          onChangeCallback={(e) => {
            searchParam.base_info.birth_year_from = e;
          }}
        />

        <TextField
          textMarginRight="0.25rem"
          textBoxWidth={textBoxWidthShort}
          labelText="~"
          maxValue={2099}
          maxLength={4}
          isStringNumber
          endAdornment="년"
          defaultValue={searchParam.base_info.birth_year_to}
          onChangeCallback={(e) => {
            searchParam.base_info.birth_year_to = e;
          }}
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <TextField
          labelMarginRight="0rem"
          textMarginRight="0.5rem"
          textBoxWidth={textBoxWidthShort}
          maxValue={12}
          maxLength={2}
          isStringNumber
          endAdornment="월"
          defaultValue={searchParam.base_info.birth_month_from}
          onChangeCallback={(e) => {
            searchParam.base_info.birth_month_from = e;
          }}
        />
        <TextField
          textMarginRight="0.25rem"
          textBoxWidth={textBoxWidthShort}
          labelText="~"
          maxValue={12}
          maxLength={2}
          isStringNumber
          endAdornment="월"
          defaultValue={searchParam.base_info.birth_month_to}
          onChangeCallback={(e) => {
            searchParam.base_info.birth_month_to = e;
          }}
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <TextField
          labelMarginRight="0rem"
          textMarginRight="0.5rem"
          textBoxWidth={textBoxWidthShort}
          maxValue={31}
          maxLength={2}
          isStringNumber
          endAdornment="일"
          defaultValue={searchParam.base_info.birth_day_from}
          onChangeCallback={(e) => {
            searchParam.base_info.birth_day_from = e;
          }}
        />
        <TextField
          textMarginRight="0.25rem"
          textBoxWidth={textBoxWidthShort}
          labelText="~"
          maxValue={31}
          maxLength={2}
          isStringNumber
          endAdornment="일"
          defaultValue={searchParam.base_info.birth_day_to}
          onChangeCallback={(e) => {
            searchParam.base_info.birth_day_to = e;
          }}
        />
      </div>

      <LabelTop text={"문자 인증 날짜 범위"} />
      <div style={{ display: "flex" }}>
        <DatePicker
          textBoxWidth={textBoxWidthShort}
          labelMarginRight="0rem"
          textMarginRight="0.25rem"
          defaultValue={searchParam.sms_auth.sms_auth_at_from}
          onChangeCallback={(e) => {
            searchParam.sms_auth.sms_auth_at_from = e;
          }}
        />
        <DatePicker
          labelWidth="0.5rem"
          labelMarginRight="0.25rem"
          textBoxWidth={textBoxWidthShort}
          labelText="~"
          defaultValue={searchParam.sms_auth.sms_auth_at_to}
          onChangeCallback={(e) => {
            searchParam.sms_auth.sms_auth_at_to = e;
          }}
        />
      </div>

      <LabelTop text={"문자 인증 횟수"} />
      <div style={{ display: "flex" }}>
        <TextField
          labelMarginRight="0rem"
          textMarginRight="0.25rem"
          textBoxWidth={textBoxWidthShort}
          maxValue={999}
          maxLength={3}
          isNumber
          endAdornment="회"
          defaultValue={searchParam.sms_auth.sms_auth_cnt_from}
          onChangeCallback={(e) => {
            searchParam.sms_auth.sms_auth_cnt_from = e;
          }}
        />
        <TextField
          labelWidth="0.75rem"
          labelText="~"
          labelMarginRight="0rem"
          textMarginRight="0.25rem"
          textBoxWidth={textBoxWidthShort}
          maxValue={999}
          maxLength={3}
          isNumber
          endAdornment="회"
          defaultValue={searchParam.sms_auth.sms_auth_cnt_to}
          onChangeCallback={(e) => {
            searchParam.sms_auth.sms_auth_cnt_to = e;
          }}
        />
      </div>
    </div>
  );
};
export default MemberContidionContents;
