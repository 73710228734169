import { excelExport } from "components/CommonLib/CommonLib";

export const exportToExcel = (data, fileName) => {
  const newData = [];

  for (let i = 0; i < data.length; i++) {
    const row = {};
    row.날짜 = data[i].base_date;
    row.지점 = data[i].branch_name;
    row.매출총계 = data[i].sales_total;
    newData.push(row);
  }

  excelExport(newData, fileName, 13);
};
