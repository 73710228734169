import { Button } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import {
  getSendMessageHist,
  getSendMessageHistSearchParams,
} from "apis/member/send_message_hist_api";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import { SubLine, simpleAlert } from "components/CommonLib/CommonLib";
import Loading from "components/Loading/Loading/Loading";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import MsgPreviewModal from "../Modal/MsgPreviewModal";
import SearchCondition from "./SearchCondition";
import SendHistResultTable from "./SendHistResultTable";
import { useSendMessageHistStore } from "./modules/store/sendMessageHistState";

const SendHistoryView = () => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  // 전역 상태 관리
  const {
    sendMessageHistSearchParams: searchParams,
    setSearchParams,
    sendMessageHistPage: page,
    setPage,
    resetSearchParams,
  } = useSendMessageHistStore();

  // 페이지 상태 관리
  const [searchResult, setSearchResult] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [resetFlag, setResetFlag] = useState(false);

  // API 상태 관리
  const [isGetSendMessageHistList, setIsGetSendMessageHistList] = useState(false);
  const queryData = { page: page, searchParams: searchParams };

  // [GET] 문자 발송 이력 검색 조건 및 권한 가져오기 << 현재 안 쓰는 중
  const { data: firstSearchParamData } = useQuery({
    queryKey: ["sendMessage", "firstSearchParam"],
    queryFn: () => getSendMessageHistSearchParams(),
    enabled: true,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      // 현재 msg가 같이 와서 Error처럼 표시되는 중 추후 확인 해야 함!
      // if (res.data.msg) {
      //   simpleAlert(storesDispatch, "알림", res.data.msg);
      // }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });

  // [GET] 문자 발송 이력 리스트 가져오기
  const { isFetching, data: sendMessageHistList } = useQuery({
    queryKey: ["sendMessageHist", queryData],
    queryFn: () => getSendMessageHist(queryData),
    enabled: isGetSendMessageHistList,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setIsGetSendMessageHistList(false);
    },
  });

  // 문자 발송 이력 리스트 검색
  const onClickSearch = async (page) => {
    setPage(page);
    setIsGetSendMessageHistList(true);
  };

  const onModalDone = (res) => {
    // console.log(res);
    storesDispatch(globalModalOff());
  };

  const openModal = (order, param) => {
    let Modal;
    if (order === "show") {
      Modal = <MsgPreviewModal modalParam={param} onModalDone={onModalDone} />;
    }
    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,
        Content: Modal,
      }),
    );
  };

  // API 받아온 데이터 세팅
  useEffect(() => {
    // 문자 발송 이력 리스트
    if (sendMessageHistList) {
      setSearchResult(sendMessageHistList.data.data);
      setTotalPage(sendMessageHistList.data.total_page);
    }
  }, [sendMessageHistList]);

  // 첫 페이지 진입 시 검색 결과를 가져오기
  useEffect(() => {
    setIsGetSendMessageHistList(true);
  }, []);

  return (
    <CardFixed
      midDom={
        <div>
          {isFetching && <Loading />}

          <div>
            <SearchCondition
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              resetFlag={resetFlag}
              onClickSearch={onClickSearch}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "0.75rem",
              }}
            >
              <Button
                className={clsx({
                  [btnStyleClass.btnRootWhite]: true,
                  [btnStyleClass.buttonType6]: true,
                })}
                onClick={() => {
                  resetSearchParams();
                  setResetFlag(!resetFlag);
                }}
              >
                초기화
              </Button>
              <Button
                className={clsx({
                  [btnStyleClass.btnRoot]: true,
                  [btnStyleClass.buttonType6]: true,
                })}
                onClick={() => {
                  onClickSearch(1);
                }}
                style={{ marginLeft: "0.3rem" }}
              >
                검색
              </Button>
            </div>
          </div>

          <SubLine />

          <div style={{ marginTop: "1rem" }}>
            <SendHistResultTable
              openModal={openModal}
              searchResult={searchResult}
              curPage={page}
              setCurPage={setPage}
              totalPage={totalPage}
              onClickSearch={onClickSearch}
            />
          </div>
        </div>
      }
      isLoading={false}
      rootStyle={
        {
          // width: "80.437rem",
          // height: "74.875rem",
        }
      }
    />
  );
};
export default SendHistoryView;
