import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { simpleAlert, SubLineVertical } from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import RadioGroup from "components/LabelInput/RadioGroup";
import RadioGroup2 from "components/LabelInput/RadioGroup2";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import post_api from "../Module/api/PostApi";

const ERPSetCoupon = ({ modalParam, onModalDone }) => {
  const isEditMode = modalParam.mode === "edit";
  const compMarginTop = "1.25rem";
  const storesDispatch = useDispatch();
  const viewModelRef = useRef();
  const btnStyleClass = btnStyles();

  const [couponData, setCouponData] = useState(modalParam.data);
  const [noCntLimit, setNoCntLimit] = useState(false);
  const [benefitType, setBenefitType] = useState(modalParam.data.base_info.benefit_type);
  const [discntMethod, setDiscntMethod] = useState(modalParam.data.detail.price_benefit.type);
  const [benefitProdList, setBenefitProdList] = useState([""]);
  const [mbshpOptionList, setMbshpOptionList] = useState([""]);
  const [benefitUseDisableList, setBenefitUseDisableList] = useState([""]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    changeBenefitProdList();
    changeBenefitUseDisable();
    changeMbshpOptionList();
  }, [benefitType, couponData]);

  const validation = () => {
    let res = "";
    if (!couponData.base_info.benefit_type) {
      res += "· 혜택 종류\n";
    }
    if (!couponData.base_info.benefit_product) {
      res += "· 혜택 상품\n";
    }
    if (!couponData.base_info.issue_date) {
      res += "· 발급일\n";
    }
    if (!couponData.base_info.expired_month && !couponData.base_info.expired_day) {
      res += "· 유효기간 발급월/일\n";
    }
    if (!couponData.detail.coupon_name) {
      res += "· 쿠폰명\n";
    }
    if (!couponData.detail.service_benefit.branch_info.branch_name) {
      res += "· 지점\n";
    }
    if (couponData.base_info.benefit_type === "이용혜택") {
      if (!couponData.detail.service_benefit.mbshp.mbshp_type) {
        res += "· 회원권 종류\n";
      }
      if (!couponData.detail.service_benefit.mbshp.mbshp_option) {
        res += "· 회원권 옵션\n";
      }
      if (
        !couponData.detail.service_benefit.mbshp.add_month &&
        !couponData.detail.service_benefit.mbshp.add_day
      ) {
        res += "· 기간 추가 (개월)\n";
      }
    } else if (couponData.base_info.benefit_type === "금액혜택") {
      if (!couponData.detail.price_benefit.type) {
        res += "· 할인 방식\n";
      }
      if (couponData.detail.price_benefit.type === "정액할인") {
        if (!couponData.detail.price_benefit.dis_price) {
          res += "· 할인금액\n";
        }
      } else {
        if (!couponData.detail.price_benefit.dis_rate) {
          res += "· 할인비율\n";
        }
      }
      if (!couponData.detail.price_benefit.type) {
        res += "· 할인 방식\n";
      }
    }
    if (!couponData.detail.is_coupon_unlimited && !couponData.detail.coupon_limit_cnt) {
      res += "· 발행수량\n";
    }

    return res;
  };

  const onChangeCouponData = (category, name, val) => {
    if (category === "base_info") {
      setCouponData({
        ...couponData,
        base_info: {
          ...couponData.base_info,
          [name]: val,
        },
      });
    } else if (category === "detail") {
      setCouponData({
        ...couponData,
        detail: {
          ...couponData.detail,
          [name]: val,
        },
      });
    } else if (category === "branch_info") {
      const idx = modalParam.branch_name_list.indexOf(val);
      const branch_id = modalParam.branch_list[idx].branch_id;

      setCouponData({
        ...couponData,
        detail: {
          ...couponData.detail,
          service_benefit: {
            ...couponData.detail.service_benefit,
            branch_info: {
              [name]: val,
              branch_id: branch_id,
            },
          },
        },
      });
    } else if (category === "mbshp") {
      setCouponData({
        ...couponData,
        detail: {
          ...couponData.detail,
          service_benefit: {
            ...couponData.detail.service_benefit,
            mbshp: {
              ...couponData.detail.service_benefit.mbshp,
              [name]: val,
            },
          },
        },
      });
    } else if (category === "price_benefit") {
      setCouponData({
        ...couponData,
        detail: {
          ...couponData.detail,
          price_benefit: {
            ...couponData.detail.price_benefit,
            [name]: val,
          },
        },
      });
    }
  };

  // for edit
  const onClickSave = (mode) => {
    const validationRes = validation();

    if (mode === "add") {
      if (validationRes !== "") {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: (
              <>
                <Label labelText="필수값을 입력해 주시기 바랍니다." />
                <br />
                <br />
                <Label labelText={validationRes} whiteSpace="pre-wrap" />
              </>
            ),
            // width: "50rem",
            onBtnOk: () => {},
          }),
        );
      } else {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: (
              <>
                <Label labelText="저장하시겠습니까?" />
              </>
            ),
            // width: "50rem",
            onBtnYes: () => {
              onSaveEvent(mode);
            },
            onBtnNo: () => {},
          }),
        );
      }
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="사용중단 처리하시겠습니까?" />
            </>
          ),
          // width: "50rem",
          onBtnYes: () => {
            onSaveEvent(mode);
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const onSaveEvent = async (mode) => {
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    if (mode === "edit") {
      // onChangeCouponData("base_info", "status", "사용중단"); // 말 안들음
      couponData.base_info.status = "사용중단";
    }

    const formData = new FormData();
    formData.append("info_data", JSON.stringify(couponData));

    const api = mode === "add" ? post_api.addCoupon : post_api.updateCoupon;

    setLoading(true);

    await HttpInstance.post(api(), formData, config)
      .then((res) => {
        // console.log(res);
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          onModalDone(modalParam.refreshStore);
        }
      })
      .catch((err) => {
        // console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
        // onModalDone(modalParam.refreshStore);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changeBenefitProdList = () => {
    switch (benefitType) {
      case "이용혜택":
        setBenefitProdList(["회원권"]);
        break;
      case "금액혜택":
        setBenefitProdList(["회원권", "운동복", "GX", "락커"]);
        break;
      case "구매가능":
        setBenefitProdList(["회원권"]);
        break;
      default:
        setBenefitProdList([""]);
        break;
    }
  };

  const changeBenefitUseDisable = () => {
    switch (benefitType) {
      case "이용혜택":
        setBenefitUseDisableList([""]);
        break;
      case "금액혜택":
        setBenefitUseDisableList(["구매 시점 또는 즉시 적용", "사용 시 즉시 적용"]);
        break;
      case "구매가능":
        setBenefitUseDisableList(["구매 시점 또는 즉시 적용", "사용 시 즉시 적용"]);
        break;
      default:
        setBenefitUseDisableList([""]);
        break;
    }

    // setBenefitUse("구매 시에만 사용 가능");
  };

  const changeMbshpOptionList = () => {
    switch (benefitType) {
      case "이용혜택":
        setMbshpOptionList(["일반권"]);
        break;
      case "금액혜택":
        setMbshpOptionList(["일반권"]);
        break;
      case "구매가능":
        setMbshpOptionList(["일반권", "시간권", "패스권"]);
        break;
      default:
        setMbshpOptionList([""]);
        break;
    }
  };

  return (
    <div style={{ width: "70rem" }}>
      {loading && <Loading />}

      <div style={{ textAlign: "right" }}>
        {modalParam.mode === "edit" ? (
          <Button
            className={clsx({
              [btnStyleClass.btnRoot]: true,
              [btnStyleClass.buttonType5]: true,
            })}
            onClick={() => onClickSave("edit")}
          >
            사용중단
          </Button>
        ) : (
          <Button
            className={clsx({
              [btnStyleClass.btnRoot]: true,
              [btnStyleClass.buttonType2]: true,
            })}
            onClick={() => onClickSave("add")}
          >
            등록
          </Button>
        )}
      </div>

      <div style={{}}>
        {/* 1. 기본정보 */}
        <SubTitle titleText="쿠폰 기본정보" />
        <div style={{ display: "flex", padding: "1rem", border: "1px solid #ccc" }}>
          {/* 쿠폰 기본정보 좌측 영역 */}
          <div style={{ width: "49%" }}>
            <RadioGroup
              labelWidth={"7rem"}
              data={["회원(고객포함)", "직원"]}
              isMulti={true}
              labelText="· 발급 대상"
              onChangeCallback={(e, v) => {
                onChangeCouponData("base_info", "use_target", v);
              }}
              defaultValue={couponData.base_info.use_target || "회원(고객포함)"}
              disableItem={["직원"]}
              disabled={isEditMode}
            />

            <div style={{ marginTop: compMarginTop }}>
              <div style={{ display: "flex" }}>
                <Label labelText="· 혜택" justifyContent="left" marginRight="5.5rem" />
                <ComboBox
                  required
                  labelWidth="3.5rem"
                  comboItemWidth="12rem"
                  labelText="종류"
                  data={["이용혜택", "금액혜택", "구매가능"]}
                  defaultValue={couponData.base_info.benefit_type || ""}
                  onChangeCallback={(e) => {
                    setBenefitType(e.target.value);
                    onChangeCouponData("base_info", "benefit_type", e.target.value);
                  }}
                  disabled={isEditMode}
                />
              </div>
              <ComboBox
                required
                marginTop="0.5rem"
                marginLeft="7.85rem"
                labelWidth="3.5rem"
                comboItemWidth="12rem"
                labelText="상품"
                data={benefitProdList}
                defaultValue={couponData.base_info.benefit_product || ""}
                onChangeCallback={(e) => {
                  onChangeCouponData("base_info", "benefit_product", e.target.value);
                }}
                disabled={isEditMode}
              />
            </div>

            <RadioGroup
              marginTop={compMarginTop}
              labelWidth={"7rem"}
              data={["선물가능", "선물불가"]}
              isMulti={true}
              labelText="· 선물 가능 여부"
              onChangeCallback={(e, v) => {
                onChangeCouponData("base_info", "can_gift", v);
              }}
              defaultValue={couponData.base_info.can_gift || "선물가능"}
              // disabled={isEditMode}
              disabled
            />

            <div style={{ display: "flex", marginTop: compMarginTop }}>
              <TextField
                required
                labelMarginRight="0rem"
                labelWidth="7.5rem"
                textBoxWidth={"12rem"}
                textMarginRight={"0rem"}
                labelText="· 쿠폰 이미지"
                onChangeCallback={(e) => {
                  onChangeCouponData("base_info", "img", e);
                }}
                defaultValue={couponData.base_info.img || ""}
                // disabled={isEditMode}
                disabled
              />
              <Button
                className={clsx({
                  [btnStyleClass.btnRoot]: true,
                  [btnStyleClass.buttonType2]: true,
                  [btnStyleClass.disabled]: true,
                })}
                // disabled={isEditMode}
                disabled
                // onClick={}
              >
                첨부
              </Button>
            </div>
          </div>

          <div style={{ width: "2%", marginLeft: "2rem" }}>
            <SubLineVertical />
          </div>

          {/* 쿠폰 기본정보 우측 영역 */}
          <div style={{ width: "49%" }}>
            <div style={{ display: "flex" }}>
              <ComboBox
                required
                labelWidth="7rem"
                comboItemWidth="7rem"
                labelText="· 발급 종류"
                data={["앱", "ㅁㅁㅁㅁㅁ"]}
                defaultValue={couponData.base_info.type || ""}
                onChangeCallback={(e) => {
                  onChangeCouponData("base_info", "type", e.target.value);
                }}
                // disabled={isEditMode}
                disabled
              />
              <CheckBox
                marginTop="0rem"
                labelWidth="8rem"
                labelText="조건부 자동 발급"
                // disabled={isEditMode}
                disabled
                defaultValue={couponData.base_info.type}
                onChangeCallback={(e) => {
                  couponData.base_info.type = e.checked;
                }}
              />
            </div>

            <div style={{ display: "flex", marginTop: compMarginTop }}>
              <Label labelText="· 사용 방식" alignItems="flex-start" justifyContent="left" />
              <RadioGroup2 // 표준값!
                marginLeft="3.25rem"
                marginTop="0rem"
                innerMarginTop="0rem"
                innerMarginLeft="0rem"
                innerMarginRight="0.25rem"
                labelMarginRight="1rem"
                data={["구매 시에만 사용 가능", "구매 시점 또는 즉시 적용", "사용 시 즉시 적용"]}
                isMulti={true}
                onChangeCallback={(e, v) => {
                  onChangeCouponData("base_info", "benefit_use", v);
                }}
                defaultValue={couponData.base_info.benefit_use || "구매 시에만 사용 가능"}
                // defaultValue={benefitUse}
                disableItem={benefitUseDisableList}
                disabled={isEditMode}
              />
            </div>

            <div style={{}}>
              <div style={{ display: "flex", marginTop: compMarginTop }}>
                <Label labelText="· 유효기간 정보" justifyContent="left" marginRight="2rem" />
                <DatePicker
                  required
                  labelWidth={"4rem"}
                  minWidth={"10rem"}
                  labelText="발급일"
                  defaultValue={couponData.base_info.issue_date}
                  onChangeCallback={(e) => {
                    couponData.base_info.issue_date = e;
                  }}
                  disabled={isEditMode}
                />
              </div>

              <div style={{ display: "flex", marginTop: "0.5rem", marginLeft: "7.75rem" }}>
                <Label labelText="유효기간" marginRight="1.25rem" />
                <TextField
                  required
                  isNumber
                  labelMarginRight="0rem"
                  labelWidth="5.75rem"
                  textBoxWidth={"3rem"}
                  textMarginRight={"0.25rem"}
                  labelText="발급일로부터"
                  minValue={0}
                  maxValue={60}
                  defaultValue={couponData.base_info.expired_month || 0}
                  onChangeCallback={(e) => {
                    onChangeCouponData("base_info", "expired_month", e);
                  }}
                  disabled={isEditMode}
                />
                <Label labelText="개월" />

                <TextField
                  marginLeft="0.25rem"
                  required
                  isNumber
                  labelMarginRight="0rem"
                  textBoxWidth={"3rem"}
                  textMarginRight={"0.25rem"}
                  minValue={0}
                  maxValue={31}
                  defaultValue={couponData.base_info.expired_day || 0}
                  onChangeCallback={(e) => {
                    onChangeCouponData("base_info", "expired_day", e);
                  }}
                  disabled={isEditMode}
                />
                <Label labelText="일" />
              </div>
            </div>
          </div>
        </div>

        {/* 2. 상세정보 */}
        <div style={{ marginTop: "1rem" }}>
          <SubTitle titleText="쿠폰 상세정보" />
          <div style={{ display: "flex", padding: "1rem", border: "1px solid #ccc" }}>
            {/* 좌측 영역 */}
            <div style={{ width: "49%" }}>
              <TextField
                required
                labelMarginRight="0rem"
                labelWidth="7.5rem"
                textBoxWidth={"22rem"}
                textMarginRight={"0rem"}
                labelText="· 쿠폰명"
                defaultValue={couponData.detail.coupon_name || ""}
                onBlur={(e) => {
                  onChangeCouponData("detail", "coupon_name", e.target.value);
                }}
                disabled={isEditMode}
              />

              <div style={{ marginTop: compMarginTop }}>
                <div style={{ display: "flex" }}>
                  <Label labelText="· 상세혜택" justifyContent="left" marginRight="4rem" />
                  <ComboBox
                    required
                    labelWidth="4.5rem"
                    comboItemWidth="10rem"
                    labelText="지점"
                    data={modalParam.branch_name_list}
                    defaultValue={couponData.detail.service_benefit.branch_info.branch_name || ""}
                    onChangeCallback={(e) => {
                      onChangeCouponData("branch_info", "branch_name", e.target.value);
                    }}
                    disabled={isEditMode}
                  />
                </div>
                <div style={{ marginLeft: "8rem" }}>
                  {benefitType === "금액혜택" ? (
                    <div>
                      <ComboBox
                        required
                        marginTop="0.5rem"
                        labelWidth="4.5rem"
                        comboItemWidth="10rem"
                        labelText="할인방식"
                        data={["정액할인", "비율할인"]}
                        defaultValue={couponData.detail.price_benefit.type || ""}
                        onChangeCallback={(e) => {
                          setDiscntMethod(e.target.value);
                          onChangeCouponData("price_benefit", "type", e.target.value);
                        }}
                        disabled={isEditMode}
                      />
                      {discntMethod === "정액할인" ? (
                        <div style={{ display: "flex", marginTop: "0.5rem" }}>
                          <TextField
                            required
                            isNumber
                            labelWidth="4.5rem"
                            textBoxWidth={"7rem"}
                            textMarginRight={"0.25rem"}
                            labelText="할인금액"
                            defaultValue={couponData.detail.price_benefit.dis_price || ""}
                            onChangeCallback={(e) => {
                              onChangeCouponData("price_benefit", "dis_price", e);
                            }}
                            disabled={isEditMode}
                          />
                          <Label labelText="원" />
                        </div>
                      ) : discntMethod === "비율할인" ? (
                        <div style={{ display: "flex", marginTop: "0.5rem" }}>
                          <TextField
                            required
                            isNumber
                            labelWidth="4.5rem"
                            textBoxWidth={"7rem"}
                            textMarginRight={"0.25rem"}
                            labelText="할인비율"
                            minValue={0}
                            maxValue={99}
                            defaultValue={couponData.detail.price_benefit.dis_rate || ""}
                            onChangeCallback={(e) => {
                              onChangeCouponData("price_benefit", "dis_rate", e);
                            }}
                            disabled={isEditMode}
                          />
                          <Label labelText="%" />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <div>
                      <ComboBox
                        required
                        marginTop="0.5rem"
                        labelWidth="4.5rem"
                        comboItemWidth="10rem"
                        labelText="회원권 종류"
                        data={["완납형", "구독형"]}
                        disableItem={["구독형"]}
                        defaultValue={couponData.detail.service_benefit.mbshp.mbshp_type || ""}
                        onChangeCallback={(e) => {
                          onChangeCouponData("mbshp", "mbshp_type", e.target.value);
                        }}
                        disabled={isEditMode}
                      />
                      <ComboBox
                        required
                        marginTop="0.5rem"
                        labelWidth="4.5rem"
                        comboItemWidth="10rem"
                        labelText="회원권 옵션"
                        data={mbshpOptionList}
                        defaultValue={couponData.detail.service_benefit.mbshp.mbshp_option || ""}
                        onChangeCallback={(e) => {
                          onChangeCouponData("mbshp", "mbshp_option", e.target.value);
                        }}
                        disabled={isEditMode}
                      />

                      {benefitType !== "구매가능" ? (
                        <div style={{ display: "flex", marginTop: "0.5rem" }}>
                          <Label labelText="혜택 내용" marginRight="1.25rem" />
                          <TextField
                            required
                            isNumber
                            labelMarginRight="0rem"
                            labelWidth="4.5rem"
                            textBoxWidth={"3rem"}
                            textMarginRight={"0.25rem"}
                            labelText="기간 추가"
                            minValue={0}
                            maxValue={12}
                            defaultValue={couponData.detail.service_benefit.mbshp.add_month || 0}
                            onChangeCallback={(e) => {
                              onChangeCouponData("mbshp", "add_month", e);
                            }}
                            disabled={isEditMode}
                          />
                          <Label labelText="개월" />

                          <TextField
                            marginLeft="0.25rem"
                            required
                            isNumber
                            labelMarginRight="0rem"
                            textBoxWidth={"3rem"}
                            textMarginRight={"0.25rem"}
                            minValue={0}
                            maxValue={31}
                            defaultValue={couponData.detail.service_benefit.mbshp.add_day || 0}
                            onChangeCallback={(e) => {
                              onChangeCouponData("mbshp", "add_day", e);
                            }}
                            disabled={isEditMode}
                          />
                          <Label labelText="일" />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div style={{ width: "2%", marginLeft: "2rem" }}>
              <SubLineVertical />
            </div>

            {/* 우측 영역 */}
            <div style={{ width: "49%" }}>
              <div style={{ display: "flex" }}>
                <Label labelText="· 수신자 선택" marginRight="2.5rem" />
                <Button
                  className={clsx({
                    [btnStyleClass.btnRoot]: true,
                    [btnStyleClass.buttonType2]: true,
                    [btnStyleClass.disabled]: true,
                  })}
                  // onClick={}
                  // disabled={isEditMode}
                  disabled
                >
                  선택
                </Button>
                <TextField
                  isNumber
                  // disabled={isEditMode}
                  disabled
                  labelMarginRight="0.25rem"
                  textBoxWidth={"4rem"}
                  textMarginRight={"0.25rem"}
                  // 나중에 추가
                />
                <Label labelText="명" marginRight="0.5rem" disabled />

                <CheckBox
                  marginTop="0rem"
                  marginLeft="1.25rem"
                  marginRight="0rem"
                  labelMarginRight="0rem"
                  labelWidth="3rem"
                  labelText="선착순"
                  // disabled={isEditMode}
                  disabled
                  // defaultValue={restData.base_info.sales_limit}
                  // onChangeCallback={(e) => {
                  //   restData.base_info.sales_limit = e.checked;
                  // }}
                />
                <TextField
                  isNumber
                  // disabled={isEditMode}
                  disabled
                  labelMarginRight="0rem"
                  textBoxWidth={"4rem"}
                  textMarginRight={"0.25rem"}
                />
                <Label labelText="명" marginRight="0.5rem" disabled />
              </div>

              <div style={{ display: "flex", marginTop: compMarginTop }}>
                <TextField
                  required
                  isNumber
                  labelMarginRight="0rem"
                  labelWidth="7.5rem"
                  textBoxWidth={"6rem"}
                  textMarginRight={"0.25rem"}
                  labelText="· 발행수량"
                  maxValue={1000}
                  disabled={noCntLimit || isEditMode}
                  defaultValue={couponData.detail.coupon_limit_cnt || ""}
                  onChangeCallback={(e) => {
                    onChangeCouponData("detail", "coupon_limit_cnt", e);
                  }}
                />
                <Label labelText="개" marginRight="0.5rem" />
                <CheckBox
                  marginTop="0rem"
                  marginLeft="1.25rem"
                  marginRight="0rem"
                  labelMarginRight="0rem"
                  labelWidth="6rem"
                  labelText="갯수제한 없음"
                  defaultValue={couponData.detail.is_coupon_unlimited}
                  onChangeCallback={(e) => {
                    setNoCntLimit(e.checked);
                    if (e.checked) {
                      onChangeCouponData("detail", "coupon_limit_cnt", 0);
                    }
                    onChangeCouponData("detail", "is_coupon_unlimited", e.checked);
                  }}
                  disabled={isEditMode}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ERPSetCoupon;
