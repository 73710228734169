import TextField from "components/LabelInput/TextField";
import { forwardRef } from "react";

const SelectOption = ({ memberInputState }) => {
  return (
    <TextField
      marginTop="0.5rem"
      labelWidth={"4.5rem"}
      textBoxWidth={"12rem"}
      labelText={"옵션"}
      defaultValue={memberInputState.membership_list.mbshp_option}
      disabled
    />
  );
};

const SelectItemCombo = ({ memberInputState, selectorInfo }) => {
  return (
    <TextField
      marginTop="0.5rem"
      labelWidth={"4.5rem"}
      textBoxWidth={selectorInfo.mbshp_option === "일일권" ? "18rem" : "19rem"}
      labelText={"상품명"}
      defaultValue={memberInputState.purchase_item_list.item_info.item_name}
      disabled
    />
  );
};

const MembershipItemSelected = forwardRef((props, ref) => {
  const { memberInputState, selectorInfo } = props;

  // console.log(baseInfoState);
  // console.log(memberInputState);
  // console.log(rawMemberState);

  return (
    <div>
      <SelectOption memberInputState={memberInputState} />
      <SelectItemCombo memberInputState={memberInputState} selectorInfo={selectorInfo} />
    </div>
  );
});
export default MembershipItemSelected;
