import IconButton from "components/Button/IconButton";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";

const FamilyDocViewModal = ({ modalParam }) => {
  const docListData = new docTable();
  return (
    <div style={{ width: "15rem" }}>
      <CustomTable
        columns_head={docListData.columns_head}
        table_title={docListData.table_title}
        rest_call={docListData.get_data_from_rest}
        row_render={docListData.create_table}
        rest_data={[""]}
      />
    </div>
  );
};

export default FamilyDocViewModal;

class docTable {
  table_title = "";

  create_table = (data, key) => {
    return (
      <CustomRow key={key}>
        <CustomTableContents>서류이름</CustomTableContents>
        <CustomTableContents>
          <IconButton type="download_small" />
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "서류 ", width: "70%" },
    { title: "", width: "30%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
