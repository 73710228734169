import { dataEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import NumberTextField from "components/LabelInput/NumberTextField";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch } from "react-redux";

const PriceDiscountInput = forwardRef((props, ref) => {
  const [, updateState] = useState(); // 강제 렌더링용
  const forceUpdate = useCallback(() => updateState({}), []);
  const storesDispatch = useDispatch();
  const labelWidth = "1.75rem";
  const labelWidthLong = props.labelWidth ? props.labelWidth : "3.75rem";
  const textBoxWidth = "10rem";
  const {
    baseInfo,
    standardPrice,
    payInfoListAction,
    onChangeCallback,
    paramLock,
    discountValue,
    paramUse,
    assignWhat,
    assignViewState,
  } = props;

  const [lock, setLock] = useState(true);

  useEffect(() => {
    if (!paramUse) {
      setLock(true);
    } else {
      setLock(paramLock);
    }
  }, [paramUse, paramLock]);

  useImperativeHandle(ref, () => ({
    setDisabled: (isDisabled) => {
      setLock(!isDisabled);
    },
  }));

  const onLocalChangeCallback = (e) => {
    const dis = e;

    payInfoListAction(
      "discount",
      baseInfo.typeKey, // 구분자
      {
        type: baseInfo.type, // 화면에 출력, 저장될 이름
        price: standardPrice,
        dis: dis,
        pay_order: baseInfo.type === "등록비" ? 0 : baseInfo.orderStartIdx + baseInfo.payOrder,
        tab_idx: baseInfo.tabIdx,
        item_type: baseInfo.item_type,
      },
      baseInfo.orderStartIdx + baseInfo.payOrder,
      baseInfo.tabIdx,
    );

    if (onChangeCallback) onChangeCallback(e);

    if (assignWhat === "mbshp") {
      assignViewState.assign_price.assign_mbsh_dis = e;
    } else if (assignWhat === "pt") {
      assignViewState.assign_price.assign_pt_dis = e;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        float: "right",
        marginLeft: "auto",
      }}
    >
      <NumberTextField
        isNumber
        labelWidth={dataEmpty(baseInfo.label) ? labelWidth : labelWidthLong}
        textBoxWidth={textBoxWidth}
        textMarginRight="0.25rem"
        labelText={dataEmpty(baseInfo.label) ? "금액" : baseInfo.label}
        endAdornment="원"
        defaultValue={standardPrice}
        onBlur={(e) => {
          if (e < props.originPrice) {
            simpleAlert(storesDispatch, "알림", "기존 금액보다 적은 금액은 입력할 수 없습니다.");
            props.setStandardPrice(props.originPrice);
          } else {
            props.setStandardPrice(e);
            onLocalChangeCallback(discountValue);
          }
          // forceUpdate();
        }}
        onChangeCallback={(e) => {
          props.setStandardPrice(e);
        }}
        disabled={!props.isAddPay || !props.canChangePrice || lock}
      />

      <NumberTextField
        isNumber
        marginLeft="1.25rem"
        labelWidth={labelWidth}
        textBoxWidth={textBoxWidth}
        textMarginRight="0.25rem"
        labelText="할인"
        maxValue={standardPrice}
        endAdornment="원"
        defaultValue={discountValue}
        disabled={lock}
        onChangeCallback={onLocalChangeCallback}
      />
    </div>
  );
});

export default PriceDiscountInput;
