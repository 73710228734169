const TitleLabel = (props) => {
  const { labelText, width, textAlign, justifyContent, marginTop } = props;

  if (labelText) {
    return (
      <label
        style={{
          width: props.width,
          textAlign,
          display: "flex",
          padding: props.padding ? props.padding : "0rem",
          alignItems: "flex-start",
          // backgroundColor: "#fff",
          fontFamily: "Pretendard",
          fontSize: props.fontSize ? props.fontSize : "0.938rem",
          fontWeight: "600",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "100%",
          letterSpacing: "normal",
          color: "#A3A8B4",
          justifyContent: justifyContent || "center",
          marginTop: marginTop ? marginTop : "0rem",
          marginLeft: props.marginLeft ? props.marginLeft : "0rem",
          marginRight: props.marginRight ? props.marginRight : "0rem",
          marginBottom: props.marginBottom ? props.marginBottom : "0rem",
          whiteSpace: props.whiteSpace,
          transform: props.transform,
          zIndex: props.zIndex,
          cursor: props.cursor,
          flexDirection: "column",
          flex: "1 0 0",
        }}
      >
        {labelText}&nbsp;
      </label>
    );
  }
  return null;
};

TitleLabel.defaultProps = {
  textAlign: "left",
};

export default TitleLabel;
