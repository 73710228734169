export const msg_state = {
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  contents: "",
  head_line: "",
  reg_info: {
    created_at: "",
    reg_id: "",
    reg_name: "",
    upd_id: "",
    upd_name: "",
    updated_at: "",
  },
  sms_info: {
    sms_id: "",
    sms_name: "",
  },
  sms_type: "공지",
};
