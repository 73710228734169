import CardFixed from "components/Card/CardFixed";
import Label from "components/LabelInput/Label";
import NormalBtn from "components/NewButton/NormalBtn";

const AccessCardItem = ({ baseInfoState, onClickOpenModal, memberId }) => {
  const popOtherBranchAccessHist = () => {
    const param = {
      title: "당월 타지점 출입이력",
      modalParam: {
        memberId: memberId,
      },
    };
    onClickOpenModal("당월타지점출입이력", param);
  };

  const totalLinkCnt = baseInfoState.active_info.access_link_info.link_info_total;
  const usedLinkCnt = baseInfoState.active_info.access_link_info.used_link_cnt;

  return (
    <div>
      {baseInfoState.membership_data_list.length !== 0 ? (
        <div
          style={{
            display: "flex",
            marginTop: "1.25rem",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", gap: "1rem" }}>
            <Label labelText="잔여 횟수" />
            <div style={{ display: "flex" }}>
              <Label labelText={`${totalLinkCnt}회 중`} color="#777777" />
              <Label labelText={`${usedLinkCnt}회`} color="#DE0909" />
              <Label labelText="사용" color="#777777" />
            </div>
          </div>
          <NormalBtn name="출입이력" theme="white" onClick={() => popOtherBranchAccessHist()} />
        </div>
      ) : (
        <Label labelText="출입 가능한 지점이 없습니다." fontSize="1rem" marginTop="2rem" />
      )}
    </div>
  );
};

const AttendenceOther = ({ baseInfoState, onClickOpenModal, memberId }) => {
  return (
    <CardFixed
      topDom={<div>당월 타지점 출입</div>}
      midDom={
        <AccessCardItem
          memberId={memberId}
          baseInfoState={baseInfoState}
          onClickOpenModal={onClickOpenModal}
        />
      }
      isLoading={false}
      rootStyle={{
        // width: "40rem",
        width: "98%",
        height: "9rem",
        marginTop: "0.625rem",
        marginLeft: "0.625rem",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};
export default AttendenceOther;
