import { Button } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { getCashBookMonthlyList, getCashBookMonthlySearchParam } from "apis/branch/cashbook_api";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import { dataEmpty, getDefaultValue, simpleAlert, SubLine } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import MonthPicker from "components/LabelInput/MonthPicker";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import Loading from "components/Loading/Loading/Loading";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCashBookStore } from "./modules/store/cashBookState";
import { SearchConditionBox, SearchContainer, SearchRowBox } from "./style";

const CashBookListView = () => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const navigate = useNavigate();

  // 페이지 구성 변수
  const textBoxWidth = "15rem";
  const labelWidth = "4.5rem";
  // 전역 상태 관리
  const {
    cashBookSearchParams: searchParams,
    setSearchParams,
    cashBookHqIdx: hqIdx,
    setHqIdx,
    setPage,
    cashBookPage: page,
  } = useCashBookStore();
  // 페이지 상태 관리
  const [hqBranchListData, setHqBranchListData] = useState({});
  const [branchList, setBranchList] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);
  // API 상태 관리
  const [isGetMonthlyList, setIsGetMonthlyList] = useState(false);
  const queryData = { page: page, searchParams: searchParams };

  // API Query
  // [GET] 수입/지출 내역(월별) 검색 조건 및 권한 리스트 세팅
  const { data: firstSearchParamData } = useQuery({
    queryKey: ["cashBook", "firstSearchParam"],
    queryFn: () => getCashBookMonthlySearchParam(),
    enabled: isFirstEnterPage,
    retry: false,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });
  // [GET] 수입/지출 내역(월별) 리스트 검색
  const { isFetching, data: cashBookListData } = useQuery({
    queryKey: ["cashBook", queryData],
    queryFn: () => getCashBookMonthlyList(queryData),
    enabled: isGetMonthlyList,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setIsGetMonthlyList(false);
    },
  });

  // API 받아온 데이터 세팅
  useEffect(() => {
    if (firstSearchParamData) {
      const { combo_selector } = firstSearchParamData.data.data;
      // 첫 검색 조건
      if (searchParams.hq_info.hq_id === "") {
        setSearchParams("hq_info", combo_selector.target_list[0].hq_info);
        setSearchParams("branch_info", combo_selector.target_list[0].branch_list[0]);
        setSearchParams("combo_selector", combo_selector);
      }
      // 본부 및 지점 정보
      setHqBranchListData(combo_selector);
      setBranchList(combo_selector.branch_list[hqIdx]);
    }

    // 검색 결과 데이터 세팅
    if (cashBookListData) {
      setSearchResult(cashBookListData.data.data);
      setTotalPage(cashBookListData.data.total_page);
    }
  }, [firstSearchParamData, cashBookListData]);

  // 임시 생성 버튼
  // const onClickCreate = () => {
  //   if (searchParams.base_from_date === "") {
  //     simpleAlert(storesDispatch, "알림", "기준 연월을 선택해주세요.");
  //     return;
  //   }

  //   const doCreate = () => {
  //     HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + "/maker/cashbook", {
  //       params: {
  //         base_date: searchParams.base_from_date,
  //       },
  //     })
  //       .then((res) => {
  //         if (restEmpty(res.data.msg)) {
  //           const createdCnt = res.data.data;
  //           simpleAlert(
  //             storesDispatch,
  //             "알림",
  //             "이번달 현금출납장 생성이 완료되었습니다.\n생성 수 : " + createdCnt,
  //           );
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
  //       });
  //   };

  //   simpleAlert(
  //     storesDispatch,
  //     "알림",
  //     "[" + searchParams.base_from_date + "] 월 데이터를 일괄 생성하시겠습니까?",
  //     false,
  //     undefined,
  //     () => {
  //       doCreate();
  //     },
  //   );
  // };

  // 본부 선택에 따른 지점 리스트 다시 세팅
  useEffect(() => {
    if (hqBranchListData.branch_list) setBranchList(hqBranchListData.branch_list[hqIdx]);
    //  첫 페이지 진입이 아니고 본부 변경 시 지점 초기화 세팅
    if (!isFirstEnterPage) {
      const branchParams = {
        branch_id: hqBranchListData.target_list[hqIdx].branch_list[0].branch_id,
        branch_name: hqBranchListData.target_list[hqIdx].branch_list[0].branch_name,
      };
      setSearchParams("branch_info", branchParams);
    }
  }, [hqIdx]);

  // 페이지 진입 시 결과 리스트
  useEffect(() => {
    if (isFirstEnterPage && searchParams.hq_info.hq_id !== "") {
      setIsGetMonthlyList(true);
      setIsFirstEnterPage(false);
    }
  }, [searchParams.hq_info.hq_id]);

  // 페이지네이션 클릭 시
  const onClickSearch = (page) => {
    setPage(page);
    setIsGetMonthlyList(true);
  };

  const searchResultList = new resultTable(navigate);

  return (
    <div>
      <CardFixed
        midDom={
          <div>
            {isFetching && <Loading />}

            <SearchContainer>
              <Label labelText="검색 조건" fontWeight="bold" justifyContent="flex-start" />
              <SearchRowBox
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onClickSearch(1);
                  }
                }}
              >
                <SearchConditionBox>
                  <ComboBox
                    labelText="본부"
                    labelWidth={labelWidth}
                    comboItemWidth={textBoxWidth}
                    data={hqBranchListData && hqBranchListData.hq_list}
                    defaultValue={getDefaultValue("combo", searchParams.hq_info.hq_name)}
                    onChangeCallback={(e, idx) => {
                      setHqIdx(idx);
                      const hqParams = hqBranchListData.target_list[idx].hq_info;
                      setSearchParams("hq_info", hqParams);
                    }}
                  />
                </SearchConditionBox>
                <SearchConditionBox>
                  <SearchComboBox
                    labelText="지점"
                    labelWidth={labelWidth}
                    comboItemWidth={textBoxWidth}
                    data={branchList}
                    defaultValue={getDefaultValue("combo", searchParams.branch_info.branch_name)}
                    onChangeCallback={(e, v, idx) => {
                      const branchParams = hqBranchListData.target_list[hqIdx].branch_list[idx];
                      setSearchParams("branch_info", branchParams);
                    }}
                  />
                </SearchConditionBox>
                <SearchConditionBox>
                  <MonthPicker
                    labelWidth={labelWidth}
                    minWidth={textBoxWidth}
                    labelText="기준 연월"
                    defaultValue={getDefaultValue("field", searchParams.base_from_date)}
                    onChangeCallback={(e) => {
                      setSearchParams("base_from_date", e);
                      setSearchParams("base_to_date", e);
                    }}
                  />
                </SearchConditionBox>
              </SearchRowBox>
            </SearchContainer>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "0.5rem",
              }}
            >
              <Button
                className={clsx({
                  [btnStyleClass.btnRoot]: true,
                  [btnStyleClass.buttonType6]: true,
                })}
                onClick={() => {
                  setPage(1);
                  setIsGetMonthlyList(true);
                }}
              >
                검색
              </Button>
            </div>
            <SubLine />
            <div style={{ marginTop: "1.5rem" }}>
              <CustomTable
                columns_head={searchResultList.columns_head}
                table_title={searchResultList.table_title}
                rest_call={searchResultList.get_data_from_rest}
                row_render={searchResultList.create_table}
                rest_data={searchResult}
              />
              <CustomPagination curPage={page} totalCnt={totalPage} doSearch={onClickSearch} />
            </div>
          </div>
        }
        isLoading={false}
        rootStyle={{
          width: "100%",
          height: "29rem",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />
    </div>
  );
};
export default CashBookListView;

//

class resultTable {
  table_title = "";
  onNavigate = null;

  constructor(navigate) {
    this.onNavigate = navigate;
  }

  onClickRow = (data) => {
    this.onNavigate("/cash_book_detail/", {
      state: {
        hq_info: data.hq_info,
        branch_info: data.branch_info,
        base_month: data.base_month,
        cashbook_id: data.cashbook_id,
      },
    });
  };

  getProfit = (data) => {
    let income = 0;
    let expenses = 0;

    data.income_list.forEach((data) => {
      if (!data.account.includes("환불")) {
        income += data.price;
      } else {
        expenses += data.price; // incomeList에서 환불만 지출로 처리
      }
    });

    data.expense_list.forEach((data) => {
      expenses += data.price;
    });

    return income - expenses;
  };

  getShortLoan = (data) => {
    const ret = data.income_list.find((item) => item.account === "단기대여금");

    if (ret) {
      return ret.price;
    }
    return 0;
  };

  create_table = (data, idx) => {
    return (
      <CustomRow
        style={{}}
        key={idx}
        onClick={(e) => {
          if (e.target.nodeName === "DIV" || e.target.nodeName === "TD") {
            this.onClickRow(data);
          }
        }}
        hover
      >
        <CustomTableContents>{idx + 1}</CustomTableContents>
        <CustomTableContents>{data.base_month}</CustomTableContents>
        <CustomTableContents>{data.hq_info.hq_name}</CustomTableContents>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents $isNumber>
          {this.getProfit(data).toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {this.getShortLoan(data).toLocaleString() + " 원"}
        </CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "순번", width: "16%" },
    { title: "기준연월", width: "16%" },
    { title: "본부", width: "16%" },
    { title: "지점", width: "16%" },
    { title: "수입-지출", width: "16%" },
    { title: "당월 단기대여금", width: "16%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
