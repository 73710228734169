export const inputInitState = {
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  class_team_info_list: [
    {
      department: {
        org_id: "",
        org_name: "",
      },
      position: {
        org_id: "",
        org_name: "",
      },
      rank: {
        org_id: "",
        org_name: "",
      },
      show_name: "",
      staff_id: "",
      staff_name: "",
    },
  ],
  class_team_name_list: [""],
  contractDocumentList: [
    {
      doc_id: "",
      doc_info: {
        doc_name: "",
        file_name: "",
        reason: "",
        reg_info: {
          created_at: "",
          reg_id: "",
          reg_name: "",
          upd_id: "",
          upd_name: "",
          updated_at: "",
        },
        url_path: "",
      },
      file_due_date: "",
      member_info: {
        member_id: "",
        member_name: "",
        member_no: "",
      },
      purchase_code: "",
    },
  ],
  cs_team_info_list: [
    {
      department: {
        org_id: "",
        org_name: "",
      },
      position: {
        org_id: "",
        org_name: "",
      },
      rank: {
        org_id: "",
        org_name: "",
      },
      show_name: "",
      staff_id: "",
      staff_name: "",
    },
  ],
  cs_team_name_list: [""],
  member_id: "",
  membership_data_list: [
    {
      assign_date_list: [""],
      is_add_pay: true,
      is_new: true,
      is_use: true,
      membership_list: {
        cloth: {
          coupon_info: {
            base_info: {
              benefit_product: "",
              benefit_type: "",
            },
            coupon_id: "",
            detail: {
              coupon_name: "",
              price_benefit: {
                dis_price: 0,
                dis_rate: 0,
                type: "",
              },
              service_benefit: {
                mbshp: {
                  add_day: 0,
                  add_month: 0,
                  mbshp_option: "",
                  mbshp_type: "",
                },
              },
            },
            final_dis: 0,
            ticket_id: "",
          },
          dis: 0,
          from_date: "",
          is_hide: true,
          is_lock: true,
          is_use: true,
          price: 0,
          raw_price: 0,
          subs_options: {
            pre_dis: 0,
            pre_price: 0,
            prorate_dis: 0,
            prorate_price: 0,
            subs_dis: 0,
            subs_price: 0,
          },
          to_date: "",
        },
        daily_time: {
          end_time: "",
          from_time: "",
        },
        family_cloth: {
          coupon_info: {
            base_info: {
              benefit_product: "",
              benefit_type: "",
            },
            coupon_id: "",
            detail: {
              coupon_name: "",
              price_benefit: {
                dis_price: 0,
                dis_rate: 0,
                type: "",
              },
              service_benefit: {
                mbshp: {
                  add_day: 0,
                  add_month: 0,
                  mbshp_option: "",
                  mbshp_type: "",
                },
              },
            },
            final_dis: 0,
            ticket_id: "",
          },
          dis: 0,
          from_date: "",
          is_hide: true,
          is_lock: true,
          is_use: true,
          price: 0,
          raw_price: 0,
          subs_options: {
            pre_dis: 0,
            pre_price: 0,
            prorate_dis: 0,
            prorate_price: 0,
            subs_dis: 0,
            subs_price: 0,
          },
          to_date: "",
        },
        family_gx: {
          coupon_info: {
            base_info: {
              benefit_product: "",
              benefit_type: "",
            },
            coupon_id: "",
            detail: {
              coupon_name: "",
              price_benefit: {
                dis_price: 0,
                dis_rate: 0,
                type: "",
              },
              service_benefit: {
                mbshp: {
                  add_day: 0,
                  add_month: 0,
                  mbshp_option: "",
                  mbshp_type: "",
                },
              },
            },
            final_dis: 0,
            ticket_id: "",
          },
          dis: 0,
          from_date: "",
          is_hide: true,
          is_lock: true,
          is_use: true,
          price: 0,
          raw_price: 0,
          subs_options: {
            pre_dis: 0,
            pre_price: 0,
            prorate_dis: 0,
            prorate_price: 0,
            subs_dis: 0,
            subs_price: 0,
          },
          to_date: "",
        },
        family_info: {
          family_info_id: "",
          is_use: true,
          member_info: {
            member_id: "",
            member_name: "",
            member_no: "",
          },
          personal_info: {
            address_1: "",
            address_2: "",
            agree_marketing: true,
            agree_personal: true,
            birth: "",
            email: "",
            gender: "",
            member_name: "",
            member_no: "",
            notice: "",
            phone: "",
            reg_info: {
              created_at: "",
              reg_id: "",
              reg_name: "",
              upd_id: "",
              upd_name: "",
              updated_at: "",
            },
            reg_path: "",
            zip_code: "",
          },
        },
        family_locker: {
          is_hide: true,
          is_lock: true,
          is_use: true,
        },
        family_locker_list: [
          {
            paid_item_idx: 0,
            item_info: {
              coupon_info: {
                base_info: {
                  benefit_product: "",
                  benefit_type: "",
                },
                coupon_id: "",
                detail: {
                  coupon_name: "",
                  price_benefit: {
                    dis_price: 0,
                    dis_rate: 0,
                    type: "",
                  },
                  service_benefit: {
                    mbshp: {
                      add_day: 0,
                      add_month: 0,
                      mbshp_option: "",
                      mbshp_type: "",
                    },
                  },
                },
                final_dis: 0,
                ticket_id: "",
              },
              dis: 0,
              is_hide: true,
              is_lock: true,
              is_use: true,
              is_use_exist: true,

              price: 0,
              raw_price: 0,
              subs_options: {
                pre_dis: 0,
                pre_price: 0,
                prorate_dis: 0,
                prorate_price: 0,
                subs_dis: 0,
                subs_price: 0,
              },
            },

            locker_info: {
              family_info: {
                family_info_id: "",
                is_use: true,
                member_id: "",
                member_name: "",
              },
              from_date: "",
              mbshp_locker_id: "",
              locker_no: 0,
              locker_total_idx: 0,
              membership_id: "",
              password: "",
              queue_id: "",
              to_date: "",
              zone_id: "",
              zone_name: "",
            },
          },
        ],
        family_ot_detail_info: {
          ot_detail_id: "",
        },
        gx: {
          coupon_info: {
            base_info: {
              benefit_product: "",
              benefit_type: "",
            },
            coupon_id: "",
            detail: {
              coupon_name: "",
              price_benefit: {
                dis_price: 0,
                dis_rate: 0,
                type: "",
              },
              service_benefit: {
                mbshp: {
                  add_day: 0,
                  add_month: 0,
                  mbshp_option: "",
                  mbshp_type: "",
                },
              },
            },
            final_dis: 0,
            ticket_id: "",
          },
          dis: 0,
          from_date: "",
          is_hide: true,
          is_lock: true,
          is_use: true,
          price: 0,
          raw_price: 0,
          subs_options: {
            pre_dis: 0,
            pre_price: 0,
            prorate_dis: 0,
            prorate_price: 0,
            subs_dis: 0,
            subs_price: 0,
          },
          to_date: "",
        },
        is_new: true,
        is_postpone_lock: true,
        item_name: "",
        item_name_idx: 0,
        item_option_idx: 0,
        item_type_idx: 0,
        locker: {
          is_hide: true,
          is_lock: true,
          is_use: true,
          subs_options: {
            subs_price: 0,
            pre_price: 0,
            prorate_price: 0,
            subs_dis: 0,
            pre_dis: 0,
            prorate_dis: 0,
            contract_from_date: "",
            contract_to_date: "",
          },
        },
        locker_list: [
          {
            paid_item_idx: 0,
            item_info: {
              coupon_info: {
                base_info: {
                  benefit_product: "",
                  benefit_type: "",
                },
                coupon_id: "",
                detail: {
                  coupon_name: "",
                  price_benefit: {
                    dis_price: 0,
                    dis_rate: 0,
                    type: "",
                  },
                  service_benefit: {
                    mbshp: {
                      add_day: 0,
                      add_month: 0,
                      mbshp_option: "",
                      mbshp_type: "",
                    },
                  },
                },
                final_dis: 0,
                ticket_id: "",
              },
              dis: 0,
              is_hide: true,
              is_lock: true,
              is_use: true,
              is_use_exist: true,

              price: 0,
              raw_price: 0,
              subs_options: {
                pre_dis: 0,
                pre_price: 0,
                prorate_dis: 0,
                prorate_price: 0,
                subs_dis: 0,
                subs_price: 0,
              },
            },
            locker_info: {
              family_info: {
                family_info_id: "",
                is_use: true,
                member_id: "",
                member_name: "",
              },
              from_date: "",
              mbshp_locker_id: "",
              locker_no: 0,
              locker_total_idx: 0,
              membership_id: "",
              password: "",
              queue_id: "",
              to_date: "",
              zone_id: "",
              zone_name: "",
            },
          },
        ],
        mbshp: {
          coupon_info: {
            base_info: {
              benefit_product: "",
              benefit_type: "",
            },
            coupon_id: "",
            detail: {
              coupon_name: "",
              price_benefit: {
                dis_price: 0,
                dis_rate: 0,
                type: "",
              },
              service_benefit: {
                mbshp: {
                  add_day: 0,
                  add_month: 0,
                  mbshp_option: "",
                  mbshp_type: "",
                },
              },
            },
            final_dis: 0,
            ticket_id: "",
          },
          dis: 0,
          event_day: 0,
          event_month: 0,
          event_reason: "",
          from_date: "",
          is_add_pay: true,
          is_hide: true,
          is_lock: true,
          is_use: true,
          price: 0,
          raw_price: 0,
          subs_options: {
            pre_dis: 0,
            pre_price: 0,
            prorate_dis: 0,
            prorate_price: 0,
            subs_dis: 0,
            subs_price: 0,
          },
          to_date: "",
          to_date_raw: "",
        },
        mbshp_option: "",
        mbshp_postpone_list: [
          {
            from_date: "",
            postpone_day: 0,
            postpone_id: "",
            postpone_type: "",
            stop_date: "",
            to_date: "",
          },
        ],
        mbshp_status: "",
        mbshp_type: "",
        membership_id: "",
        option_lock: true,
        ot_detail_info: {
          ot_detail_id: "",
        },
        ot_staff: {
          staff_id: "",
          staff_name: "",
          staff_pos: "",
          staff_rank: "",
        },
        paid_done_date: "",
        parent_purchase_code: "",
        pass: {
          total_cnt: 0,
        },
        pass_time: {
          end_time: "",
          from_time: "",
        },
        purchase_item_id: "",
        re_reg_path: "",
        reg: {
          coupon_info: {
            base_info: {
              benefit_product: "",
              benefit_type: "",
            },
            coupon_id: "",
            detail: {
              coupon_name: "",
              price_benefit: {
                dis_price: 0,
                dis_rate: 0,
                type: "",
              },
              service_benefit: {
                mbshp: {
                  add_day: 0,
                  add_month: 0,
                  mbshp_option: "",
                  mbshp_type: "",
                },
              },
            },
            final_dis: 0,
            ticket_id: "",
          },
          dis: 0,
          from_date: "",
          is_hide: true,
          is_lock: true,
          is_use: true,
          price: 0,
          raw_price: 0,
          subs_options: {
            pre_dis: 0,
            pre_price: 0,
            prorate_dis: 0,
            prorate_price: 0,
            subs_dis: 0,
            subs_price: 0,
          },
          to_date: "",
        },
        reg_info: {
          created_at: "",
          reg_id: "",
          reg_name: "",
          upd_id: "",
          upd_name: "",
          updated_at: "",
        },
        service_status: "",
        subs_options: {
          auto_payment_cancel: true,
          contract_from_date: "",
          contract_to_date: "",
          payment_done_list: [
            {
              idx: 0,
              pay_date: "",
              price: 0,
            },
          ],
          subs_paid_num: 0,
          subs_total_price: 0,
        },
        usage: {
          acc_postpone_day: 0,
          family_ot_session: 0,
          link_info_total: 0,
          ot_session: 0,
          total_postpone_cnt: 0,
          used_postpone_cnt: 0,
        },
      },
      ot_info: {
        alloc_date: "",
        branch_info: {
          branch_id: "",
          branch_name: "",
        },
        cur_manager: {
          assign_date: "",
          is_assigned: true,
          is_resigned: true,
          staff_info: {
            staff_id: "",
            staff_name: "",
            staff_pos: "",
            staff_rank: "",
          },
        },
        is_use: true,
        membership_info: {
          birth: "",
          from_date: "",
          gender: "",
          is_new: true,
          item_name: "",
          mbshp_option: "",
          mbshp_type: "",
          member_id: "",
          member_name: "",
          member_no: "",
          membership_id: "",
          phone: "",
          to_date: "",
        },
        ot_class: [
          {
            class_done_date: "",
            comment: "",
            is_suggest_pt: true,
            membership_id: "",
            no: 0,
            staff_info: {
              staff_id: "",
              staff_name: "",
              staff_pos: "",
              staff_rank: "",
            },
          },
        ],
        ot_detail_id: "",
        ot_info: {
          class_status: "",
          is_suggest_pt: true,
          mbshp_status: "",
          ot_session: 0,
          proceed_class_lock: true,
          remain_session: 0,
          latest_contact: "",
        },

        reg_info: {
          created_at: "",
          reg_id: "",
          reg_name: "",
          upd_id: "",
          upd_name: "",
          updated_at: "",
        },
        remarks: "",
        service_session_hist: [
          {
            add_session: 0,
            reg_info: {
              created_at: "",
              reg_id: "",
              reg_name: "",
              upd_id: "",
              upd_name: "",
              updated_at: "",
            },
          },
        ],
      },
      purchase_item_list: {
        branch_policy: {
          cms_by_pay_method: {
            card: 0,
            cash: 0,
          },
          membership_policy: {
            card_remake_fee: 0,
            change_limit: 0,
            dflt_from_date: "",
            event_day_list: [0],
            event_month_list: [0],
            max_wait_day: 0,
            mbshp_assign_fee: 0,
            pass_change_limit: 0,
            pass_mbship_count_rate: 0,
            pt_assign_fee: 0,
            recommendee_day: 0,
            recommendee_month: 0,
            recommender_day: 0,
            recommender_month: 0,
            use_dflt_from_date: true,
            use_max_wait_day: true,
          },
        },
        erp_policy: {
          branch_link_cnt: 0,
          branch_change_price: 0,
          regular_pay_date: 0,
        },
        hq_policy: {
          mbshp_rfnd_day_price: 0,
          prepay_penalty_rate: 0,
          subs_penalty_rate: 0,
        },
        item_info: {
          base_info: {
            incentive: 0,
          },
          can_assign: true,
          can_change: true,
          can_family: true,
          cloth_price: 0,
          description: "",
          gx_price: 0,
          is_combine_cloth: true,
          is_combine_gx: true,
          is_combine_locker: true,
          is_combine_pt: true,
          item_id: "",
          item_name: "",
          locker_price: 0,
          mbshp_option: "",
          mbshp_price: 0,
          mbshp_type: "",
          oneday_wait_hour: 0,
          ot_session: 0,
          pass_cnt: 0,
          pass_end_time: "",
          pass_from_time: "",
          period_month: 0,
          postpone_cnt: 0,
          pt_per_price: 0,
          pt_price: 0,
          pt_session: 0,
          purchase_item_id: "",
          reg_info: {
            created_at: "",
            reg_id: "",
            reg_name: "",
            upd_id: "",
            upd_name: "",
            updated_at: "",
          },
          reg_price: 0,
          service_day: 0,
          service_month: 0,
          standard_price: {
            oneday_cloth: 0,
            oneday_gx: 0,
            oneday_mbshp: 0,
            ot: 0,
            prepay_cloth: 0,
            prepay_gx: 0,
            prepay_locker: 0,
            prepay_mbshp: 0,
            prepay_reg: 0,
            pt: 0,
          },
          subs_options: {
            cloth_pre_price: 0,
            cloth_subs_price: 0,
            gx_pre_price: 0,
            gx_subs_price: 0,
            locker_pre_price: 0,
            locker_subs_price: 0,
            mbshp_pre_price: 0,
            mbshp_subs_price: 0,
            pre_month: 0,
            subs_period_month: 0,
            subs_reg: 0,
            total_subs_price: 0,
          },
          total_period_day: 0,
          total_period_hour: 0,
          total_period_month: 0,
          total_price: 0,
        },
      },
      reg_input_info: {
        cloth_dis: 0,
        cloth_lock: true,
        cloth_price: 0,
        event_day: 0,
        event_month: 0,
        friend: "",
        from_date: "",
        gx_dis: 0,
        gx_lock: true,
        gx_price: 0,
        is_start: true,
        item_lock: true,
        item_name: "",
        item_name_idx: 0,
        item_type: "",
        item_type_idx: 0,
        item_type_lock: true,
        join_dis: 0,
        join_lock: true,
        join_price: 0,
        join_view: true,
        membership_dis: 0,
        membership_lock: true,
        membership_price: 0,
        ot_manager: "",
        tab_idx: 0,
        tab_title: "",
        to_date: "",
        use_cloth: true,
        use_gx: true,
        use_join: true,
      },
      selected: true,
      state_initialized: true,
    },
  ],
  new_membersip_info: {
    membership_list: {
      cloth: {
        coupon_info: {
          base_info: {
            benefit_product: "",
            benefit_type: "",
          },
          coupon_id: "",
          detail: {
            coupon_name: "",
            price_benefit: {
              dis_price: 0,
              dis_rate: 0,
              type: "",
            },
            service_benefit: {
              mbshp: {
                add_day: 0,
                add_month: 0,
                mbshp_option: "",
                mbshp_type: "",
              },
            },
          },
          final_dis: 0,
          ticket_id: "",
        },
        dis: 0,
        from_date: "",
        is_hide: true,
        is_lock: true,
        is_use: true,
        price: 0,
        raw_price: 0,
        subs_options: {
          pre_dis: 0,
          pre_price: 0,
          prorate_dis: 0,
          prorate_price: 0,
          subs_dis: 0,
          subs_price: 0,
        },
        to_date: "",
      },
      daily_time: {
        end_time: "",
        from_time: "",
      },
      family_cloth: {
        coupon_info: {
          base_info: {
            benefit_product: "",
            benefit_type: "",
          },
          coupon_id: "",
          detail: {
            coupon_name: "",
            price_benefit: {
              dis_price: 0,
              dis_rate: 0,
              type: "",
            },
            service_benefit: {
              mbshp: {
                add_day: 0,
                add_month: 0,
                mbshp_option: "",
                mbshp_type: "",
              },
            },
          },
          final_dis: 0,
          ticket_id: "",
        },
        dis: 0,
        from_date: "",
        is_hide: true,
        is_lock: true,
        is_use: true,
        price: 0,
        raw_price: 0,
        subs_options: {
          pre_dis: 0,
          pre_price: 0,
          prorate_dis: 0,
          prorate_price: 0,
          subs_dis: 0,
          subs_price: 0,
        },
        to_date: "",
      },
      family_gx: {
        coupon_info: {
          base_info: {
            benefit_product: "",
            benefit_type: "",
          },
          coupon_id: "",
          detail: {
            coupon_name: "",
            price_benefit: {
              dis_price: 0,
              dis_rate: 0,
              type: "",
            },
            service_benefit: {
              mbshp: {
                add_day: 0,
                add_month: 0,
                mbshp_option: "",
                mbshp_type: "",
              },
            },
          },
          final_dis: 0,
          ticket_id: "",
        },
        dis: 0,
        from_date: "",
        is_hide: true,
        is_lock: true,
        is_use: true,
        price: 0,
        raw_price: 0,
        subs_options: {
          pre_dis: 0,
          pre_price: 0,
          prorate_dis: 0,
          prorate_price: 0,
          subs_dis: 0,
          subs_price: 0,
        },
        to_date: "",
      },
      family_info: {
        family_info_id: "",
        is_use: true,
        member_info: {
          member_id: "",
          member_name: "",
          member_no: "",
        },
        personal_info: {
          address_1: "",
          address_2: "",
          agree_marketing: true,
          agree_personal: true,
          birth: "",
          email: "",
          gender: "",
          member_name: "",
          member_no: "",
          notice: "",
          phone: "",
          reg_info: {
            created_at: "",
            reg_id: "",
            reg_name: "",
            upd_id: "",
            upd_name: "",
            updated_at: "",
          },
          reg_path: "",
          zip_code: "",
        },
      },
      family_locker: {
        is_hide: true,
        is_lock: true,
        is_use: true,
      },
      family_locker_list: [
        {
          paid_item_idx: 0,
          item_info: {
            coupon_info: {
              base_info: {
                benefit_product: "",
                benefit_type: "",
              },
              coupon_id: "",
              detail: {
                coupon_name: "",
                price_benefit: {
                  dis_price: 0,
                  dis_rate: 0,
                  type: "",
                },
                service_benefit: {
                  mbshp: {
                    add_day: 0,
                    add_month: 0,
                    mbshp_option: "",
                    mbshp_type: "",
                  },
                },
              },
              final_dis: 0,
              ticket_id: "",
            },
            dis: 0,
            is_hide: true,
            is_lock: true,
            is_use: true,
            is_use_exist: true,

            price: 0,
            raw_price: 0,
            subs_options: {
              pre_dis: 0,
              pre_price: 0,
              prorate_dis: 0,
              prorate_price: 0,
              subs_dis: 0,
              subs_price: 0,
            },
          },
          locker_info: {
            family_info: {
              family_info_id: "",
              is_use: true,
              member_id: "",
              member_name: "",
            },
            from_date: "",
            mbshp_locker_id: "",
            locker_no: 0,
            locker_total_idx: 0,
            membership_id: "",
            password: "",
            queue_id: "",
            to_date: "",
            zone_id: "",
            zone_name: "",
          },
        },
      ],
      family_ot_detail_info: {
        ot_detail_id: "",
      },
      gx: {
        coupon_info: {
          base_info: {
            benefit_product: "",
            benefit_type: "",
          },
          coupon_id: "",
          detail: {
            coupon_name: "",
            price_benefit: {
              dis_price: 0,
              dis_rate: 0,
              type: "",
            },
            service_benefit: {
              mbshp: {
                add_day: 0,
                add_month: 0,
                mbshp_option: "",
                mbshp_type: "",
              },
            },
          },
          final_dis: 0,
          ticket_id: "",
        },
        dis: 0,
        from_date: "",
        is_hide: true,
        is_lock: true,
        is_use: true,
        price: 0,
        raw_price: 0,
        subs_options: {
          pre_dis: 0,
          pre_price: 0,
          prorate_dis: 0,
          prorate_price: 0,
          subs_dis: 0,
          subs_price: 0,
        },
        to_date: "",
      },
      is_new: true,
      is_postpone_lock: true,
      item_name: "",
      item_name_idx: 0,
      item_option_idx: 0,
      item_type_idx: 0,
      locker: {
        is_hide: true,
        is_lock: true,
        is_use: true,
        subs_options: {
          subs_price: 0,
          pre_price: 0,
          prorate_price: 0,
          subs_dis: 0,
          pre_dis: 0,
          prorate_dis: 0,
          contract_from_date: "",
          contract_to_date: "",
        },
      },
      locker_list: [
        {
          paid_item_idx: 0,
          item_info: {
            coupon_info: {
              base_info: {
                benefit_product: "",
                benefit_type: "",
              },
              coupon_id: "",
              detail: {
                coupon_name: "",
                price_benefit: {
                  dis_price: 0,
                  dis_rate: 0,
                  type: "",
                },
                service_benefit: {
                  mbshp: {
                    add_day: 0,
                    add_month: 0,
                    mbshp_option: "",
                    mbshp_type: "",
                  },
                },
              },
              final_dis: 0,
              ticket_id: "",
            },
            dis: 0,
            is_hide: true,
            is_lock: true,
            is_use: true,
            is_use_exist: true,

            price: 0,
            raw_price: 0,
            subs_options: {
              pre_dis: 0,
              pre_price: 0,
              prorate_dis: 0,
              prorate_price: 0,
              subs_dis: 0,
              subs_price: 0,
            },
          },
          locker_info: {
            family_info: {
              family_info_id: "",
              is_use: true,
              member_id: "",
              member_name: "",
            },
            from_date: "",
            mbshp_locker_id: "",
            locker_no: 0,
            locker_total_idx: 0,
            membership_id: "",
            password: "",
            queue_id: "",
            to_date: "",
            zone_id: "",
            zone_name: "",
          },
        },
      ],
      mbshp: {
        coupon_info: {
          base_info: {
            benefit_product: "",
            benefit_type: "",
          },
          coupon_id: "",
          detail: {
            coupon_name: "",
            price_benefit: {
              dis_price: 0,
              dis_rate: 0,
              type: "",
            },
            service_benefit: {
              mbshp: {
                add_day: 0,
                add_month: 0,
                mbshp_option: "",
                mbshp_type: "",
              },
            },
          },
          final_dis: 0,
          ticket_id: "",
        },
        dis: 0,
        event_day: 0,
        event_month: 0,
        event_reason: "",
        from_date: "",
        is_add_pay: true,
        is_hide: true,
        is_lock: true,
        is_use: true,
        price: 0,
        raw_price: 0,
        subs_options: {
          pre_dis: 0,
          pre_price: 0,
          prorate_dis: 0,
          prorate_price: 0,
          subs_dis: 0,
          subs_price: 0,
        },
        to_date: "",
        to_date_raw: "",
      },
      mbshp_option: "",
      mbshp_postpone_list: [
        {
          from_date: "",
          postpone_day: 0,
          postpone_id: "",
          postpone_type: "",
          stop_date: "",
          to_date: "",
        },
      ],
      mbshp_status: "",
      mbshp_type: "",
      membership_id: "",
      option_lock: true,
      ot_detail_info: {
        ot_detail_id: "",
      },
      ot_staff: {
        staff_id: "",
        staff_name: "",
        staff_pos: "",
        staff_rank: "",
      },
      paid_done_date: "",
      parent_purchase_code: "",
      pass: {
        total_cnt: 0,
      },
      pass_time: {
        end_time: "",
        from_time: "",
      },
      purchase_item_id: "",
      re_reg_path: "",
      reg: {
        coupon_info: {
          base_info: {
            benefit_product: "",
            benefit_type: "",
          },
          coupon_id: "",
          detail: {
            coupon_name: "",
            price_benefit: {
              dis_price: 0,
              dis_rate: 0,
              type: "",
            },
            service_benefit: {
              mbshp: {
                add_day: 0,
                add_month: 0,
                mbshp_option: "",
                mbshp_type: "",
              },
            },
          },
          final_dis: 0,
          ticket_id: "",
        },
        dis: 0,
        from_date: "",
        is_hide: true,
        is_lock: true,
        is_use: true,
        price: 0,
        raw_price: 0,
        subs_options: {
          pre_dis: 0,
          pre_price: 0,
          prorate_dis: 0,
          prorate_price: 0,
          subs_dis: 0,
          subs_price: 0,
        },
        to_date: "",
      },
      reg_info: {
        created_at: "",
        reg_id: "",
        reg_name: "",
        upd_id: "",
        upd_name: "",
        updated_at: "",
      },
      service_status: "",
      subs_options: {
        auto_payment_cancel: true,
        contract_from_date: "",
        contract_to_date: "",
        payment_done_list: [
          {
            idx: 0,
            pay_date: "",
            price: 0,
          },
        ],
        subs_paid_num: 0,
        subs_total_price: 0,
      },
      usage: {
        acc_postpone_day: 0,
        family_ot_session: 0,
        link_info_total: 0,
        ot_session: 0,
        total_postpone_cnt: 0,
        used_postpone_cnt: 0,
      },
    },
    purchase_item_list: {
      branch_policy: {
        cms_by_pay_method: {
          card: 0,
          cash: 0,
        },
        membership_policy: {
          card_remake_fee: 0,
          change_limit: 0,
          dflt_from_date: "",
          event_day_list: [0],
          event_month_list: [0],
          max_wait_day: 0,
          mbshp_assign_fee: 0,
          pass_change_limit: 0,
          pass_mbship_count_rate: 0,
          pt_assign_fee: 0,
          recommendee_day: 0,
          recommendee_month: 0,
          recommender_day: 0,
          recommender_month: 0,
          use_dflt_from_date: true,
          use_max_wait_day: true,
        },
      },
      erp_policy: {
        branch_link_cnt: 0,
        branch_change_price: 0,
        regular_pay_date: 0,
      },
      hq_policy: {
        mbshp_rfnd_day_price: 0,
        prepay_penalty_rate: 0,
        subs_penalty_rate: 0,
      },
      item_info: {
        base_info: {
          incentive: 0,
        },
        can_assign: true,
        can_change: true,
        can_family: true,
        cloth_price: 0,
        description: "",
        gx_price: 0,
        is_combine_cloth: true,
        is_combine_gx: true,
        is_combine_locker: true,
        is_combine_pt: true,
        item_id: "",
        item_name: "",
        locker_price: 0,
        mbshp_option: "",
        mbshp_price: 0,
        mbshp_type: "",
        oneday_wait_hour: 0,
        ot_session: 0,
        pass_cnt: 0,
        pass_end_time: "",
        pass_from_time: "",
        period_month: 0,
        postpone_cnt: 0,
        pt_per_price: 0,
        pt_price: 0,
        pt_session: 0,
        purchase_item_id: "",
        reg_info: {
          created_at: "",
          reg_id: "",
          reg_name: "",
          upd_id: "",
          upd_name: "",
          updated_at: "",
        },
        reg_price: 0,
        service_day: 0,
        service_month: 0,
        standard_price: {
          oneday_cloth: 0,
          oneday_gx: 0,
          oneday_mbshp: 0,
          ot: 0,
          prepay_cloth: 0,
          prepay_gx: 0,
          prepay_locker: 0,
          prepay_mbshp: 0,
          prepay_reg: 0,
          pt: 0,
        },
        subs_options: {
          cloth_pre_price: 0,
          cloth_subs_price: 0,
          gx_pre_price: 0,
          gx_subs_price: 0,
          locker_pre_price: 0,
          locker_subs_price: 0,
          mbshp_pre_price: 0,
          mbshp_subs_price: 0,
          pre_month: 0,
          subs_period_month: 0,
          subs_reg: 0,
          total_subs_price: 0,
        },
        total_period_day: 0,
        total_period_hour: 0,
        total_period_month: 0,
        total_price: 0,
      },
    },
    sub_payment: {
      paid_item_list: [
        {
          content_obj: {
            dis: 0,
            pay_order: 0,
            price: 0,
            tab_idx: 0,
            type: "",
          },
          orderIdx: 0,
          type_key: "",
        },
      ],
      tab_info: {
        idx: 0,
        title: "",
      },
      view_info: {
        is_use: true,
        monthly_total: 0,
        raw_paid: 0,
        total_paid: 0,
      },
    },
    tab_info: {
      idx: 0,
      is_add_pay: true,
      is_new: true,
      is_use: true,
      selected: true,
      state_initialized: true,
      title: "",
    },
  },
  paymentA: {
    payment_info: [
      {
        paid_item_list: [
          {
            MembershipIdx: 0,
            content_obj: {
              count: 0,
              dis: 0,
              item_id: "",
              item_type: "",
              pay_order: 0,
              price: 0,
              tab_idx: 0,
              type: "",
            },
            locker_info: {
              paid_item_idx: 0,
              item_info: {
                coupon_info: {
                  base_info: {
                    benefit_product: "",
                    benefit_type: "",
                  },
                  coupon_id: "",
                  detail: {
                    coupon_name: "",
                    price_benefit: {
                      dis_price: 0,
                      dis_rate: 0,
                      type: "",
                    },
                    service_benefit: {
                      mbshp: {
                        add_day: 0,
                        add_month: 0,
                        mbshp_option: "",
                        mbshp_type: "",
                      },
                    },
                  },
                  final_dis: 0,
                  ticket_id: "",
                },
                dis: 0,
                is_hide: true,
                is_lock: true,
                is_use: true,
                is_use_exist: true,

                price: 0,
                raw_price: 0,
                subs_options: {
                  pre_dis: 0,
                  pre_price: 0,
                  prorate_dis: 0,
                  prorate_price: 0,
                  subs_dis: 0,
                  subs_price: 0,
                },
              },
              locker_info: {
                family_info: {
                  family_info_id: "",
                  is_use: true,
                  member_id: "",
                  member_name: "",
                },
                from_date: "",
                mbshp_locker_id: "",
                locker_no: 0,
                locker_total_idx: 0,
                membership_id: "",
                password: "",
                queue_id: "",
                to_date: "",
                zone_id: "",
                zone_name: "",
              },
            },
            orderIdx: 0,
            type_key: "",
          },
        ],
        paid_price: 0,
        payment_input: {
          pay_method: [
            {
              approve_no: "",
              bank_user_name: "",
              buyer: "",
              card_comp: "",
              card_no: "",
              id: "",
              // installment_plan: 0,
              installment: "",
              pay_date: "",
              pay_method: "",
              price: 0,
            },
          ],
          total_paid: 0,
          unpaid_info: {
            promise_date: "",
            reason: "",
            unpaid_price: 0,
          },
        },
        purchase_code: "",
        unpaid_price: 0,
      },
    ],
  },
  personal_info: {
    address_1: "",
    address_2: "",
    agree_marketing: true,
    agree_personal: true,
    birth: "",
    email: "",
    gender: "",
    member_name: "",
    member_no: "",
    notice: "",
    phone: "",
    reg_info: {
      created_at: "",
      reg_id: "",
      reg_name: "",
      upd_id: "",
      upd_name: "",
      updated_at: "",
    },
    reg_path: "",
    zip_code: "",
  },
  pt_info: {
    cs_staff: {
      staff_id: "",
      staff_name: "",
      staff_pos: "",
      staff_rank: "",
    },
    default_session: 0,
    dis: 0,
    input_type: "",
    per_price: 0,
    price: 0,
    pt_staff: {
      staff_id: "",
      staff_name: "",
      staff_pos: "",
      staff_rank: "",
    },
    pt_total_cnt: 0,
    service_session: 0,
    total_price: 0,
  },
  refund_item_info: {
    cs_staff: {
      staff_id: "",
      staff_name: "",
      staff_pos: "",
      staff_rank: "",
    },
    cs_team_info_list: [
      {
        department: {
          org_id: "",
          org_name: "",
        },
        position: {
          org_id: "",
          org_name: "",
        },
        rank: {
          org_id: "",
          org_name: "",
        },
        show_name: "",
        staff_id: "",
        staff_name: "",
      },
    ],
    cs_team_name_list: [""],
    member_id: "",
    paymentB: {
      payment_info: [
        {
          paid_item_list: [
            {
              MembershipIdx: 0,
              content_obj: {
                count: 0,
                dis: 0,
                item_id: "",
                item_type: "",
                pay_order: 0,
                price: 0,
                tab_idx: 0,
                type: "",
              },
              locker_info: {
                paid_item_idx: 0,
                item_info: {
                  coupon_info: {
                    base_info: {
                      benefit_product: "",
                      benefit_type: "",
                    },
                    coupon_id: "",
                    detail: {
                      coupon_name: "",
                      price_benefit: {
                        dis_price: 0,
                        dis_rate: 0,
                        type: "",
                      },
                      service_benefit: {
                        mbshp: {
                          add_day: 0,
                          add_month: 0,
                          mbshp_option: "",
                          mbshp_type: "",
                        },
                      },
                    },
                    final_dis: 0,
                    ticket_id: "",
                  },
                  dis: 0,
                  is_hide: true,
                  is_lock: true,
                  is_use: true,
                  is_use_exist: true,
                  price: 0,
                  raw_price: 0,
                  subs_options: {
                    pre_dis: 0,
                    pre_price: 0,
                    prorate_dis: 0,
                    prorate_price: 0,
                    subs_dis: 0,
                    subs_price: 0,
                  },
                },
                locker_info: {
                  family_info: {
                    family_info_id: "",
                    is_use: true,
                    member_id: "",
                    member_name: "",
                  },
                  from_date: "",
                  mbshp_locker_id: "",
                  locker_no: 0,
                  locker_total_idx: 0,
                  membership_id: "",
                  password: "",
                  queue_id: "",
                  to_date: "",
                  zone_id: "",
                  zone_name: "",
                },
              },
              orderIdx: 0,
              type_key: "",
            },
          ],
          paid_price: 0,
          payment_input: {
            pay_method: [
              {
                approve_no: "",
                bank_user_name: "",
                buyer: "",
                card_comp: "",
                card_no: "",
                id: "",
                // installment_plan: 0,
                installment: "",
                pay_date: "",
                pay_method: "",
                price: 0,
              },
            ],
            total_paid: 0,
            unpaid_info: {
              promise_date: "",
              reason: "",
              unpaid_price: 0,
            },
          },
          purchase_code: "",
          unpaid_price: 0,
        },
      ],
    },
    refund_info_list: [
      {
        child_idx_list: [0],
        is_lock: true,
        is_merged: true,
        purchase_code: "",
        purchase_list: [
          {
            contract_total_price: 0,
            deduct_price: 0,
            discount_price: 0,
            mbshp_rfnd_day_price: 0,
            penalty: 0,
            prepay_penalty_rate: 0,
            purchase_info: {
              branch_info: {
                branch_id: "",
                branch_name: "",
              },
              change_exp_date: "",
              contract_docs: [
                {
                  file_name: "",
                  file_type: "",
                  reason: "",
                  reg_info: {
                    created_at: "",
                    reg_id: "",
                    reg_name: "",
                    upd_id: "",
                    upd_name: "",
                    updated_at: "",
                  },
                },
              ],
              contract_price: 0,
              coupon_info: {
                coupon_id: "",
                detail: {
                  coupon_name: "",
                  price_benefit: {
                    dis_price: 0,
                    dis_rate: 0,
                    type: "",
                  },
                },
                final_dis: 0,
                ticket_id: "",
              },
              cs_staff: {
                staff_id: "",
                staff_name: "",
                staff_pos: "",
                staff_rank: "",
              },
              description: "",
              enroll_reg_date: "",
              etc_info: {
                count: 0,
                dis: 0,
                price: 0,
              },
              hq_policy: {
                mbshp_rfnd_day_price: 0,
                prepay_penalty_rate: 0,
                subs_penalty_rate: 0,
              },
              is_merged: true,
              is_subs: true,
              is_valid: true,
              item_id: "",
              item_name: "",
              item_price: 0,
              item_type: "",
              mbshp_locker_id: "",
              membership_id: "",
              paid_price: 0,
              parent_purchase_code: "",
              per_day_price: 0,
              point_info: {
                used_point: 0,
              },
              price: 0,
              pt_detail_id: "",
              purchase_code: "",
              purchase_id: "",
              purchase_item_id: "",
              reason: "",
              reg_info: {
                created_at: "",
                reg_id: "",
                reg_name: "",
                upd_id: "",
                upd_name: "",
                updated_at: "",
              },
              staff_performance: {
                staff_info: {
                  staff_id: "",
                  staff_name: "",
                  staff_pos: "",
                  staff_rank: "",
                },
                staff_performance_id: "",
                tmp_performance_id: "",
              },
              unpaid_price: 0,
            },
            refund_price: 0,
            subs_penalty_rate: 0,
            usage_details: "",
          },
        ],
        selected: true,
      },
    ],
    refund_list: [
      {
        done_refund_price: 0,
        payment_list: [
          {
            payment_data: {
              account_holder: "",
              approve_no: "",
              cancel_receipt: "",
              card_buyer: "",
              card_comp: "",
              card_no: "",
              cash_approve_no: "",
              installment: "0",
              is_valid: true,
              pay_date: "",
              pay_method: "",
              pay_receipt: "",
              payment_id: "",
              price: 0,
              purchase_code: "",
              remarks: "",
            },
            payment_list_selector: {
              is_hide: true,
              is_lock: true,
              selected: true,
            },
          },
        ],
        purchase_code: "",
        refund_detail: [
          {
            account_holder: "",
            approve_no: "",
            bank_acc: "",
            cancel_date: "",
            cancel_receipt: "",
            cancel_status: "",
            cancel_type: "",
            card_buyer: "",
            card_comp: "",
            card_no: "",
            cash_approve_no: "",
            installment: "0",
            is_done: true,
            is_hide: true,
            is_lock: true,
            is_method_change: "",
            is_valid: true,
            pay_date: "",
            pay_method: "",
            pay_receipt: "",
            payment_id: "",
            price: 0,
            purchase_code: "",
            refund_method: "",
            remarks: "",
            selected: true,
          },
        ],
        repaid_price: 0,
        selected: true,
        total_deduct_price: 0,
        total_dis_return_price: 0,
        total_paid_price: 0,
        total_penalty: 0,
        total_refund_price: 0,
      },
    ],
    refund_sum_mary: {
      deduct_price: 0,
      done_refund_price: 0,
      paid_price: 0,
      penalty: 0,
      refund_price: 0,
      repaid_price: 0,
    },
  },
};
