import { dataEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const CreateContractPopup = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [fileObjects, setFileObjects] = useState([]);
  let selectedFileGroupId = "";

  useEffect(() => {
    getFileGroupIds();
  }, []);

  const getFileGroupIds = async () => {
    await HttpInstance.get(
      process.env.REACT_APP_USER_SERVER + "/member/contract/sign/file_group_ids",
      {
        params: {
          member_id: modalParam.member_id,
        },
      },
    )
      .then((res) => {
        setFileObjects(res.data.data);
      })
      .catch((err) => {
        console.log("err");
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const onClickCreateContract = async () => {
    //
    setLoading(true);

    await HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member/contract/create/purchase", {
      params: {
        member_id: modalParam.member_id,
        purchase_code: modalParam.purchase_code,
        file_group_id: selectedFileGroupId,
        is_overwrite: true,
      },
    })
      .then((res) => {
        // setFileGroupIds(res.data.data);
      })
      .catch((err) => {
        console.log("err");
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      })
      .finally(() => {
        simpleAlert(storesDispatch, "알림", "계약서 생성이 완료되었습니다.");
        setLoading(false);
      });
  };

  const onClickOk = () => {
    let text = "";
    if (!selectedFileGroupId) {
      text = "서명이 없는 채로 계약서 및 영수증을 새로 생성하시겠습니까?";
    } else {
      text = "선택한 서명으로 계약서 및 영수증을 생성하시겠습니까?";
    }

    simpleAlert(storesDispatch, "알림", text, false, undefined, onClickCreateContract);
  };

  const onClickCancel = () => {
    onModalDone();
  };

  const onSelectRow = (data) => {
    selectedFileGroupId = data.file_info.file_group_id;
    onClickOk();
  };

  const fileObjectTableInst = new fileObjectTable(onSelectRow);

  return (
    <div style={{ width: "60rem" }}>
      {loading && <Loading />}
      <div>
        {/* <ComboBox
          labelText="서명이 있는 File Group ID"
          labelWidth="13rem"
          labelMarginRight="0rem"
          comboItemWidth="17rem"
          data={fileGroupIds}
          onChangeCallback={(e, idx) => {
            setSelectedFileGroupId(e.target.value);
          }}
        /> */}
        <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "0.75rem" }}>
          <NormalBtn
            margin="0 0.5rem"
            name="선택 없이 생성"
            onClick={() => {
              selectedFileGroupId = "";
              onClickOk();
            }}
          />
        </div>
        <CustomTable
          columns_head={fileObjectTableInst.columns_head}
          table_title={fileObjectTableInst.table_title}
          rest_call={fileObjectTableInst.get_data_from_rest}
          row_render={fileObjectTableInst.create_table}
          rest_data={fileObjects && fileObjects}
        />
      </div>
    </div>
  );
};
export default CreateContractPopup;

//
//
class fileObjectTable {
  table_title = "";
  onSelectRow = null;

  constructor(onSelectRow) {
    this.onSelectRow = onSelectRow;
  }

  create_table = (data, idx) => {
    if (!data.file_info) {
      return <></>;
    }

    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.file_info.file_group_id}</CustomTableContents>
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.file_info.doc_name}</CustomTableContents>
        <CustomTableContents>{data.file_info.reason}</CustomTableContents>
        <CustomTableContentsEND>
          <NormalBtn
            name="선택"
            onClick={() => {
              this.onSelectRow(data);
            }}
          />
        </CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "FileGroupId", width: "23%" },
    { title: "등록일시", width: "23%" },
    { title: "파일구분", width: "23%" },
    { title: "생성구분", width: "15%" },
    { title: "", width: "10%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
