import { HttpInstance } from "lib/HttpLib";

// ==================== GET ====================
// [GET] 회원 출입 수단 변경 내역 리스트
export async function getMemberAccessMethodHist(queryData) {
  const { page, memberId } = queryData;

  return await HttpInstance.get(
    process.env.REACT_APP_USER_SERVER + `/member/${memberId}/access_method/history`,
    {
      params: {
        member_id: memberId,
        page_num: page,
      },
    },
  );
}
