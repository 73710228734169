import { combineReducers } from "redux";
import * as ActionTypes from "../actions/action_type";
import { branchPolicySetStateinitial } from "./policy_state";

import { branchPrePayStateinitial } from "./prepay_state";
import { branchSubScriptStateinitial } from "./subscription_state";
import { branchETCStateinitial } from "./etc_state";

const branch_base_state = (state = branchPolicySetStateinitial, action) => {
  switch (action.type) {
    case ActionTypes.getBranchBaseSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getBranchBaseFailAction:
    case ActionTypes.getBranchBaseResetAction:
      return { ...state, stores: branchPolicySetStateinitial.stores };
    default:
      return state;
  }
};

const branch_policy_set_state = (state = branchPolicySetStateinitial, action) => {
  switch (action.type) {
    case ActionTypes.getPolicyManageViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getPolicyManageViewFailAction:
    case ActionTypes.getPolicyManageViewResetAction:
      return { ...state, stores: branchPolicySetStateinitial.stores };
    default:
      return state;
  }
};

const branchItemsSetStateinitial = {
  stores: {
    data: {
      contents: {
        prepay_items: [],
        subs_items: [],
        etc_items: [],
      },
    },
  },
};
const branch_items_list_state = (state = branchItemsSetStateinitial, action) => {
  switch (action.type) {
    case ActionTypes.getBranchItemsSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getBranchItemsFailAction:
    case ActionTypes.getBranchItemsResetAction:
      return { ...state, stores: branchItemsSetStateinitial.stores };
    default:
      return state;
  }
};

const branch_pre_paid_info_state = (state = branchPrePayStateinitial, action) => {
  switch (action.type) {
    case ActionTypes.getPrePaidInfoSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getPrePaidInfoFailAction:
    case ActionTypes.getPrePaidInfoResetAction:
      return { ...state, stores: branchPrePayStateinitial.stores };
    default:
      return state;
  }
};

const branch_sub_paid_info_state = (state = branchSubScriptStateinitial, action) => {
  switch (action.type) {
    case ActionTypes.getSubPaidInfoSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getSubPaidInfoFailAction:
    case ActionTypes.getSubPaidInfoResetAction:
      return { ...state, stores: branchSubScriptStateinitial.stores };
    default:
      return state;
  }
};

const branch_etc_product_state = (state = branchETCStateinitial, action) => {
  switch (action.type) {
    case ActionTypes.getEtcInfoSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getEtcInfoFailAction:
    case ActionTypes.getEtcInfoResetAction:
      return { ...state, stores: branchETCStateinitial.stores };
    default:
      return state;
  }
};

export const branchSettingReducer = combineReducers({
  branch_base_state,
  branch_policy_set_state,
  branch_items_list_state,
  branch_pre_paid_info_state,
  branch_sub_paid_info_state,
  branch_etc_product_state,
});
