import { useEffect } from "react";

const PassResult = () => {
  useEffect(() => {
    // 인증결과 확인
    const script = document.createElement("script");
    const resultData = document.createTextNode(
      "redirectData = new URLSearchParams(location.search).get('data');" +
        "try {" +
        "redirectData = JSON.parse(redirectData);" +
        "document.querySelector('#result').value = JSON.stringify(redirectData, null, 4);" +
        "} catch (error) {" +
        "document.querySelector('#result').value = redirectData;" +
        "}",
    );

    const url = new URL(window.location.href);

    const redirectData = url.searchParams.get("data");

    redirectDataData = redirectData;

    script.appendChild(resultData);
    document.body.appendChild(script);

    // sendHandler(redirectData);
    window.addEventListener("flutterInAppWebViewPlatformReady", function (event) {
      // 웹에서 앱으로 데이터 보내기
      window.flutter_inappwebview.callHandler("FlutterHandler", redirectData);
    });
  }, []);

  let redirectDataData = "?";

  const mokSend = () => {
    window.flutter_inappwebview.callHandler("FlutterHandler", redirectDataData);
  };

  return (
    <main>
      <div>
        <div>
          <h1 id="result">본인인증이 완료 되었습니다</h1>
        </div>
        <div>
          <button onClick={mokSend}>닫기</button>
        </div>
      </div>
    </main>
  );
};
export default PassResult;
