import { put, call } from "redux-saga/effects";
import { takeLatest, takeEvery } from "redux-saga/effects";

import get_api from "../api/GetApi";
import { searchModalAction } from "../actions/index";
import { globalStartLoading, globalFinishLoading } from "modules/actions/Loading/index";
import * as ActionTypes from "../actions/action_type";

export function* getSearchMemberData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getSearchMemberResultInfo, param);

    if (data.data === null) {
      yield put(searchModalAction.loadSearchMemberSuccess(false));
    } else {
      yield put(searchModalAction.loadSearchMemberSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(searchModalAction.loadSearchMemberFail(error));
    yield put(globalFinishLoading());
  }
}

export function* getSearchStaffData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getSearchStaffResultInfo, param);

    if (data.data === null) {
      yield put(searchModalAction.loadSearchStaffSuccess(false));
    } else {
      yield put(searchModalAction.loadSearchStaffSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(searchModalAction.loadSearchStaffFail(error));
    yield put(globalFinishLoading());
  }
}

export function* getSearchMemberParamData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getLoadSearchMemberParamInfo, param);

    if (data.data === null) {
      yield put(searchModalAction.loadSearchMemberParamSuccess(false));
    } else {
      yield put(searchModalAction.loadSearchMemberParamSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(searchModalAction.loadSearchMemberParamFail(error));
    yield put(globalFinishLoading());
  }
}

export function* searchModalSaga() {
  yield takeLatest(ActionTypes.getSearchMemberAction, getSearchMemberData);
  yield takeLatest(ActionTypes.getSearchStaffAction, getSearchStaffData);

  yield takeLatest(ActionTypes.getSearchMemberParamAction, getSearchMemberParamData);
}
