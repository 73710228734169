import IconButton from "components/Button/IconButton";
import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableCellChild,
  CustomTableCellFit,
  CustomTableContents,
} from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { loadGxSalaryViewStore, loadSalaryInfoViewStore } from "../Module/actions";

const GxStaffBySalaryList = ({
  getGxSalaryDetailByRow,
  selected,
  gxDetailArr,
  salaryReportId,
  is_edit,
}) => {
  const storesDispatch = useDispatch();
  const [modalCombo, setModalCombo] = useState([]);

  const refreshAllView = () => {
    storesDispatch(loadGxSalaryViewStore(salaryReportId, is_edit)); //GX탭 갱신
    storesDispatch(loadSalaryInfoViewStore(salaryReportId, is_edit)); //메인 갱신
    getGxSalaryDetailByRow(selected.staffId); //상세테이블 갱신
    storesDispatch(globalModalOff());
  };

  const getGxAddModalCombo = async () => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_SALARY_SERVER + "/salary/gx/add/view",
        {
          params: {
            staff_performance_id: selected.pId,
            salary_report_id: salaryReportId,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setModalCombo(res.data.data.gx_class_name_list);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  const deleteGxClassRow = async (classHistId, data) => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_SALARY_SERVER + "/salary/gx/remove",
        {
          params: {
            salary_report_id: salaryReportId,
            staff_id: selected.staffId,
            gx_class_hist_id: classHistId,
            class_name: data.class_name,
          },
        },
      );
      if (!res.data.data) {
        //성공
        storesDispatch(loadGxSalaryViewStore(salaryReportId, is_edit)); //GX탭 갱신
        storesDispatch(loadSalaryInfoViewStore(salaryReportId, is_edit)); //메인 갱신
        getGxSalaryDetailByRow(selected.staffId); //상세테이블 갱신
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  const onDeleteRow = (classHistId, data) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: <Label labelText="삭제 하시겠습니까?" />,
        onBtnYes: () => {
          deleteGxClassRow(classHistId, data);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const dataTableItem = new dataTable(onDeleteRow, is_edit);

  return (
    <div style={{ marginTop: "1.5rem" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <SubTitle titleText={`직원별 GX 수당 상세: ${selected.staffName} GX 강사`} />
        </div>
      </div>
      <div style={{ marginTop: "0.7rem" }}>
        <CustomTable
          columns_head={dataTableItem.columns_head}
          table_title={dataTableItem.table_title}
          rest_call={dataTableItem.get_data_from_rest}
          row_render={dataTableItem.create_table}
          rest_data={gxDetailArr}
          maxHeight={"15rem"}
        />
      </div>
    </div>
  );
};
export default GxStaffBySalaryList;

class dataTable {
  table_title = "";
  onDeleteRow = null;
  isEdit = null;

  constructor(onDeleteRow, isEdit) {
    this.onDeleteRow = onDeleteRow;
    this.isEdit = isEdit;
  }

  getChildClassDoneDate = (data) => {
    return (
      <CustomTableCellFit align="center" max_cnt={1}>
        {data.class_record_list.map((el, idx) => (
          <CustomTableCellChild
            key={idx}
            isLast={data.class_record_list.length === 1 ? true : false}
          >
            {el.class_info.proceed_class.class_done_date}
          </CustomTableCellChild>
        ))}
      </CustomTableCellFit>
    );
  };
  getChildClassDoneTime = (data) => {
    console.log(data);
    return (
      <CustomTableCellFit align="center" max_cnt={1}>
        {data.class_record_list.map((el, idx) => (
          <CustomTableCellChild
            key={idx}
            isLast={data.class_record_list.length === 1 ? true : false}
          >
            {/* {el.class_info.proceed_class.class_done_time} */}
            {el.reg_time}
          </CustomTableCellChild>
        ))}
      </CustomTableCellFit>
    );
  };
  getChildClassStatus = (data) => {
    return (
      <CustomTableCellFit align="center" max_cnt={1}>
        {data.class_record_list.map((el, idx) => (
          <CustomTableCellChild
            key={idx}
            isLast={data.class_record_list.length === 1 ? true : false}
          >
            {el.class_info.proceed_class.class_status}
          </CustomTableCellChild>
        ))}
      </CustomTableCellFit>
    );
  };
  getChildClassMemo = (data) => {
    return (
      <CustomTableCellFit align="center" max_cnt={1}>
        {data.class_record_list.map((el, idx) => (
          <CustomTableCellChild
            key={idx}
            isLast={data.class_record_list.length === 1 ? true : false}
          >
            {el.class_info.proceed_class.comment}
          </CustomTableCellChild>
        ))}
      </CustomTableCellFit>
    );
  };

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.class_name}</CustomTableContents>
        <CustomTableContents>{data.standard_price.toLocaleString() + " 원"}</CustomTableContents>
        {this.getChildClassDoneDate(data)}
        {this.getChildClassDoneTime(data)}
        {this.getChildClassStatus(data)}
        {this.getChildClassMemo(data)}
      </CustomRow>
    );
  };
  columns_head = [
    { title: "수업명", width: "20%" },
    { title: "1회 수업료", width: "10%" },
    { title: "수업일", width: "15%" },
    { title: "수업시간", width: "15%" },
    { title: "완료구분", width: "15%" },
    { title: "메모", width: "25%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
