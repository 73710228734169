import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import RadioGroup from "components/LabelInput/RadioGroup";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const ChangeTeamModal = ({ modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();

  const labelText = "5rem";
  const [selectRecordMonth, setSelectRecordMonth] = useState("전월");
  const [selectedTeam, setSelectedTeam] = useState({ team_id: "", team_name: "" });
  const [teamList, setTeamList] = useState([]);

  //팀콤보리스트
  const getOtherTeamList = async () => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_STAFF_SERVER + "/pt_team/name/list",
        {
          params: {
            branch_id: modalParam.teamInfo.branch_info.branch_id,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setTeamList(res.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };
  useEffect(() => {
    if (modalParam) {
      getOtherTeamList();
    }
  }, [modalParam]);

  //팀변경PATCH
  const changeTeam = async () => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const formData = new FormData();

      formData.append("move_opt", selectRecordMonth === "전월" ? true : false);
      formData.append("src_team_id", modalParam.teamInfo.team_info.team_id);
      formData.append("dst_team_id", selectedTeam.team_id);
      formData.append("staff_id", modalParam.data.staff_id);
      const res = await HttpInstance.patch(
        process.env.REACT_APP_STAFF_SERVER + "/pt_team/member/move",
        formData,
        config,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label labelText="변경되었습니다." />
              </>
            ),
            onBtnOk: () => {
              //뭘 갱신할지
              onModalDone();
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    }
  };

  const validationCheck = () => {
    if (!selectedTeam.team_id) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="이동할 팀을 선택해주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText={`변경하시겠습니까?`} />
            </>
          ),

          onBtnYes: () => {
            changeTeam();
          },
          onBtnNo: () => {},
        }),
      );
    }
    //팀 선택했는지, 라디오 선택했는지 물어보고 -> 저장 POST ->  onModalDone(모달닫기 + 팀원리스트 갱신+ 메인팀리스트 갱신 -> 이미되어있음^^)
  };
  return (
    <div style={{ width: "20rem" }}>
      <div>
        <Label labelText={`${modalParam.data.staff_name} 의 팀 소속을 변경합니다.`} />
        <div
          style={{
            marginTop: "0.7rem",
            display: "flex",
            flexDirection: "column",

            gap: "0.5rem",
          }}
        >
          <ComboBox
            labelText="이동할 팀"
            labelWidth={labelText}
            comboItemWidth={"11rem"}
            data={teamList.length !== 0 ? teamList.team_name_list : []}
            defaultValue={selectedTeam.team_name}
            onChangeCallback={(e) => {
              if (e.target.value === modalParam.teamInfo.team_info.team_name) {
                storesDispatch(
                  globalAlertOn({
                    show: true,
                    titleText: "알림",
                    reqConfirm: true,
                    bodyText: (
                      <>
                        <Label labelText="현재 소속팀입니다. 변경하실 팀을 선택해주세요." />
                      </>
                    ),
                    onBtnOk: () => {
                      setSelectedTeam({ team_id: "", team_name: "" });
                    },
                  }),
                );
              } else {
                //
                const team = teamList.team_info_list.find((el) => el.team_name === e.target.value);
                if (team.team_name) {
                  setSelectedTeam(team);
                }
              }
            }}
          />
          <RadioGroup
            labelWidth={labelText}
            radioMarginLeft={"1.3rem"}
            data={["전월", "당월"]}
            defaultValue={"전월"}
            isMulti={true}
            labelText="실적 이동"
            onChangeCallback={(e, value) => {
              setSelectRecordMonth(value);
            }}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
          <Button
            className={clsx({
              [btnStyleClass.btnRootRaw]: true,
              [btnStyleClass.buttonType4]: true,
            })}
            onClick={validationCheck}
          >
            변경
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChangeTeamModal;
