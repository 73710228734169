import { Button } from "@material-ui/core";
// import get_api from "../../../lib/Staff/GetApi";
import axios from "axios";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import Card from "components/Card/Card";
import TextField from "components/LabelInput/TextField";
import { useEffect, useState } from "react";
// import { Button } from "@mui/material";
// import { btnStyle, flexRowStyle } from "../CommonComponents";
import styled from "styled-components";

const Input = styled("input")({
  display: "none",
});
const flexRowStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
};

const getPhoto = async (staff_id, branch_id, photo_file, setFileImage) => {
  if (staff_id && branch_id && photo_file) {
    await axios({
      method: "GET",
      url: process.env.REACT_APP_STAFF_SERVER + "/staff_photo",
      responseType: "blob",
      params: {
        staff_id,
        branch_id,
        photo_file,
      },
    })
      .then((res) => {
        if (res.data.size) setFileImage(URL.createObjectURL(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

const PhotoComponent = (props) => {
  //파일 미리볼 url을 저장해줄 state
  const [fileImage, setFileImage] = useState("");
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    getPhoto(props.staff_id, props.branch_id, props.photo_file, setFileImage);
  }, []);

  // 파일 저장
  const onSelectImage = (e) => {
    if (e.target.files[0]) {
      setFileImage(URL.createObjectURL(e.target.files[0]));
      setFileName(e.target.files[0].name);
      props.inputRef.current = e.target.files[0];
    }
  };

  // 파일 삭제
  const onDeleteImage = () => {
    console.log(fileName);
    URL.revokeObjectURL(fileImage);
    setFileImage("");
    setFileName("");
  };

  const FileController = () => {
    const btnStyleClass = btnStyles();
    if (fileImage) {
      return (
        <Button
          className={clsx({
            [btnStyleClass.btnRootRaw]: true,
            [btnStyleClass.buttonType2]: true,
          })}
          onClick={() => onDeleteImage()}
        >
          삭제
        </Button>
      );
    } else {
      if (props.hideUploaded) {
        return <div></div>;
      } else {
        return (
          <div style={flexRowStyle}>
            <TextField
              defaultValue={fileName}
              textBoxWidth={"8.25rem"}
              readonly
              labelMarginRight="0rem"
              textMarginRight="0rem"
            />

            <label htmlFor="attach-file-photo">
              <Input
                accept="image/*"
                id="attach-file-photo"
                multiple
                type="file"
                onChange={onSelectImage}
              />

              <Button
                className={clsx({
                  [btnStyleClass.btnRootRaw]: true,
                  [btnStyleClass.buttonType2]: true,
                })}
                // onClick={() => onDeleteImage()}
              >
                첨부
              </Button>
            </label>
          </div>
        );
      }
    }
  };

  return (
    <>
      <div>
        <Card
          isLoading={false}
          type="blank"
          backgroundColor="#FFFFFF"
          midDom={
            fileImage && (
              <img alt="sample" src={fileImage} style={{ margin: "auto", width: "100%" }} />
            )
          }
          width="12rem"
          height="12.5rem"
        />

        <FileController />
      </div>
    </>
  );
};

export default PhotoComponent;
