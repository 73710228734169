import { contentTypeJson } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

// ==================== GET ====================
// [GET] 회원 검색 (모달)
export async function getModalSearchMember(queryData) {
  const { page, searchParam } = queryData;

  const formData = new FormData();
  formData.append("page_num", page);
  formData.append("search_param", JSON.stringify(searchParam));

  return await HttpInstance.post(
    process.env.REACT_APP_USER_SERVER + "/member/search_modal",
    formData,
    contentTypeJson,
  );
}

// [GET] 회원 검색 (페이지) 베이스 조건 가져오기
export const getBaseSearchParam = () => {
  return HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member_search/base_search_param", {
    params: {},
  });
};

export const getMemberSearchParam = () => {
  return HttpInstance.get(
    process.env.REACT_APP_USER_SERVER + "/member_search/member_search_param",
    {
      params: {},
    },
  );
};

export const getMbshpSearchParam = () => {
  return HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member_search/mbshp_search_param", {
    params: {},
  });
};

export const getSideSearchParam = () => {
  return HttpInstance.get(
    process.env.REACT_APP_USER_SERVER + "/member_search/others_search_param",
    {
      params: {},
    },
  );
};

export const getPtSearchParam = () => {
  return HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member_search/pt_search_param", {
    params: {},
  });
};
