import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, getByte, simpleAlert } from "components/CommonLib/CommonLib";
import ColorLabel from "components/LabelInput/ColorLabel";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";

const FavoriteDraftDetailModal = ({ modalParam, onModalDone }) => {
  const mode = modalParam.mode;
  const searchResultRef = useRef();
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();

  const labelWidth = "4.5rem";
  const boxWidth = "30rem";

  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);

  const onClickSave = async () => {
    if (!title) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="결재선명을 입력해주세요." />,
          onBtnOk: () => {},
        }),
      );
      return;
    }

    const favoriteLineItem = {
      approval_line_id: "",
      line_title: title,
      approver: modalParam.approval_info.approver_list,
      receiver: modalParam.approval_info.receiver_list,
      viewer: modalParam.approval_info.viewer_list,
      reg_info: {
        created_at: "",
        reg_id: "",
        reg_name: "",
        upd_id: "",
        upd_name: "",
        updated_at: "",
      },
    };

    setLoading(true);

    console.log("FavoriteDraftDetailModal");
    const formData = new FormData();
    formData.append("new_info", JSON.stringify(favoriteLineItem));

    await HttpInstance.post(
      process.env.REACT_APP_STAFF_SERVER + "/approval/draft/line/add",
      formData,
      contentTypeJson,
    )
      .then((res) => {
        console.log(res);
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          storesDispatch(
            globalAlertOn({
              show: true,
              titleText: "알림",
              bodyText: <Label labelText="저장되었습니다." />,
              reqConfirm: true,
              onBtnOk: () => {
                onModalDone();
              },
            }),
          );
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getStaffNamePos = (data) => {
    return data.staff_info.staff_name + " " + data.staff_info.staff_pos;
  };

  return (
    <div style={{ width: "35rem" }}>
      {loading && <Loading />}

      <div>
        {mode === "approval" ? (
          <TextField
            labelWidth={labelWidth}
            fullWidth
            defaultValue={modalParam.approval_info.title || title}
            labelText="결재선명"
            onChangeCallback={(e) => {
              setTitle(e);
            }}
            disabled={!modalParam.canEdit}
          />
        ) : (
          <ComboBox
            labelWidth={labelWidth}
            comboItemWidth={boxWidth}
            labelText="결재선명"
            data={["1"]}
          />
        )}
        <div style={{ display: "flex" }}>
          <div style={{ width: labelWidth, display: "flex" }}>
            <Label width={labelWidth} labelText="결재" justifyContent="left" />
          </div>
          <div style={boxStyle}>
            {modalParam.approval_info.approver_list.map((el) => (
              <ColorLabel
                width={((getByte(getStaffNamePos(el)) * 0.9) / 2 + 0.5).toString() + "rem"}
                labelText={getStaffNamePos(el)}
                bgColor="#222222"
                fontColor="#FFFFFF"
              />
            ))}
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: labelWidth, display: "flex" }}>
            <Label width={labelWidth} labelText="열람" justifyContent="left" />
          </div>
          <div style={boxStyle}>
            {modalParam.approval_info.viewer_list.map((el) => (
              <ColorLabel
                width={((getByte(getStaffNamePos(el)) * 0.9) / 2 + 0.5).toString() + "rem"}
                labelText={getStaffNamePos(el)}
                bgColor="#222222"
                fontColor="#FFFFFF"
              />
            ))}
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: labelWidth, display: "flex" }}>
            <Label width={labelWidth} labelText="수신" justifyContent="left" />
          </div>
          <div style={boxStyle}>
            {modalParam.approval_info.receiver_list.map((el) => (
              <ColorLabel
                width={((getByte(getStaffNamePos(el)) * 0.9) / 2 + 0.5).toString() + "rem"}
                labelText={getStaffNamePos(el)}
                bgColor="#222222"
                fontColor="#FFFFFF"
              />
            ))}
          </div>
        </div>
      </div>
      {modalParam.canEdit ? (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "1.5rem" }}>
          <Button
            className={clsx({
              [btnStyleClass.btnRootRaw]: true,
              [btnStyleClass.buttonType4]: true,
            })}
            onClick={onClickSave}
          >
            완료
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default FavoriteDraftDetailModal;

const boxStyle = {
  marginTop: "0.5rem",
  width: "100%",
  minHeight: "2.5rem",
  borderRadius: "5px",
  border: "1px solid #BBBBBB",
  backgroundColor: "#ebebeb",
  fontFamily: "AppleSDGothicNeo",
  fontSize: "0.9rem",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#000",
  boxSizing: "border-box",
  padding: "0.2rem",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: "0.2rem",
};
