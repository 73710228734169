import {
  loadSearchOtManageList,
  loadSearchOtManageListSuccess,
  loadSearchOtManageListFail,
  loadSearchOtManageListReset,
  loadSearchPtManageList,
  loadSearchPtManageListSuccess,
  loadSearchPtManageListFail,
  loadSearchPtManageListReset,
  loadPtDetailInfo,
  loadPtDetailInfoSuccess,
  loadPtDetailInfoFail,
  loadPtDetailInfoReset,
  loadClassByStaff,
  loadClassByStaffSuccess,
  loadClassByStaffFail,
  loadClassByStaffReset,
} from "./view_act";

export const loadSearchOtManageListStore = (search_param) => loadSearchOtManageList(search_param);
export const loadSearchPtManageListStore = (search_param) => loadSearchPtManageList(search_param);
export const loadPtDetailInfoStore = (pt_detail_id) => loadPtDetailInfo(pt_detail_id);

export const loadClassByStaffStore = (staff_id, branch_id) => loadClassByStaff(staff_id, branch_id);

export const classManageAction = {
  loadSearchOtManageList,
  loadSearchOtManageListSuccess,
  loadSearchOtManageListFail,
  loadSearchOtManageListReset,

  loadSearchPtManageList,
  loadSearchPtManageListSuccess,
  loadSearchPtManageListFail,
  loadSearchPtManageListReset,

  loadPtDetailInfo,
  loadPtDetailInfoSuccess,
  loadPtDetailInfoFail,
  loadPtDetailInfoReset,

  loadClassByStaff,
  loadClassByStaffSuccess,
  loadClassByStaffFail,
  loadClassByStaffReset,
};
