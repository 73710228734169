import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import RadioGroup2 from "components/LabelInput/RadioGroup2";
import TextField from "components/LabelInput/TextField";
import SubTitle from "components/PageTitle/SubTitle";
import { useState } from "react";

const MilitaryCardItem = ({ staffInfoState }) => {
  const textBoxWidth = "12rem";
  const labelWidth = "5rem";
  const [isEtc, setIsEtc] = useState(false);

  return (
    <div style={{}}>
      <div style={{ display: "flex" }}>
        <Label labelText="· 군필여부" marginRight="1.5rem" />
        <RadioGroup2 // 표준값!
          isRow
          marginTop="0rem"
          innerMarginTop="0rem"
          innerMarginLeft="0rem"
          innerMarginRight="0.1rem"
          labelMarginRight="1rem"
          marginRight="0.25rem"
          data={["면제", "필", "미필", "특례"]}
          isMulti={true}
          defaultValue={staffInfoState.military.is_serviced || "면제"}
          onChange={(e) => {
            e.target.value === "특례" ? setIsEtc(true) : setIsEtc(false);
            staffInfoState.military.is_serviced = e.target.value;
          }}
        />

        <Label labelText={"(사유 :"} />
        <TextField // 표준값!
          labelWidth={"0rem"}
          textBoxWidth={textBoxWidth}
          textMarginRight={"0.25rem"}
          labelMarginLeft={"0rem"}
          labelMarginRight={"0rem"}
          labelText=""
          defaultValue={staffInfoState.military.exception_reason}
          onChangeCallback={(e) => {
            staffInfoState.military.exception_reason = e;
          }}
          disabled={!isEtc}
        />
        <Label labelText={")"} />

        <div style={{ display: "flex", marginLeft: "3.25rem" }}>
          <DatePicker
            labelWidth={labelWidth}
            minWidth={textBoxWidth}
            textMarginRight="0rem"
            labelText="· 복무기간"
            defaultValue={staffInfoState.military.service_from_date}
            onChangeCallback={(e) => {
              staffInfoState.military.service_from_date = e;
            }}
          />
          <DatePicker
            minWidth={textBoxWidth}
            marginLeft="0.1rem"
            labelWidth="0.1rem"
            labelText="~"
            defaultValue={staffInfoState.military.service_end_date}
            onChangeCallback={(e) => {
              staffInfoState.military.service_end_date = e;
            }}
          />
        </div>
      </div>

      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <div style={{}}>
          <TextField
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· 최종계급"
            defaultValue={staffInfoState.military.rank}
            onChangeCallback={(e) => {
              staffInfoState.military.rank = e;
            }}
          />
        </div>

        <div style={{ marginLeft: "5.6rem" }}>
          <ComboBox
            labelWidth={labelWidth}
            comboItemWidth={textBoxWidth}
            labelText="· 군별"
            data={["육군", "해군", "공군", "해병", "기타"]}
            defaultValue={staffInfoState.military.category}
            onChangeCallback={(e) => {
              staffInfoState.military.category = e.target.value;
            }}
          />
        </div>

        <div style={{ marginLeft: "3.4rem" }}>
          <TextField
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· 병과"
            defaultValue={staffInfoState.military.specialty}
            onChangeCallback={(e) => {
              staffInfoState.military.specialty = e;
            }}
          />
        </div>
      </div>
    </div>
  );
};

const MilitaryCard = ({ staffInfoState }) => {
  return (
    <div style={{ padding: "0.625rem 1.25rem", border: "1px solid #bbb" }}>
      <div style={{}}>
        <SubTitle titleText="병역사항" />
      </div>
      <div>
        <MilitaryCardItem staffInfoState={staffInfoState} />
      </div>
    </div>
  );
};
export default MilitaryCard;
