import IconButton from "components/Button/IconButton";
import {
  contentTypeJson,
  getPermission,
  simpleAlert,
  SubLine,
} from "components/CommonLib/CommonLib";
import { downloadFile } from "components/File/downloadFile";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IconPostponeDocInfo } from "./postApi";
const PostponeContents = ({ viewState, popChangePaperDueModal, getPostponeHist, refreshStore }) => {
  const storesDispatch = useDispatch();
  const [hasPermission, setHasPermission] = useState(true);
  const [loading, setLoading] = useState(false);
  const labelWidthLong = "6rem";
  const textBoxWidthShort = "8.5rem";
  const textBoxWidth = "12rem";

  const checkPermission = async () => {
    const result = await getPermission(storesDispatch, "MEMBER_VIEW_MENU/EXT_MANAGE");
    setHasPermission(result);
  };

  //연기중단 POST
  const stopPostpone = async () => {
    try {
      const formData = new FormData();
      formData.append("new_info", JSON.stringify(viewState));

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_USER_SERVER + "/change/postpone_cancel",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        getPostponeHist(1);
        refreshStore();
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const onStopPostponeEvt = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: <Label labelText="연기를 중단하시겠습니까?" />,
        onBtnYes: () => {
          stopPostpone();
        },
        onBtnNo: () => {},
      }),
    );
  };

  //파일 업로드 POST
  const uploadFile = async (data, file) => {
    try {
      const formData = new FormData();
      formData.append("attachment", file);

      setLoading(true);

      const res = await HttpInstance.patch(
        process.env.REACT_APP_AUTH_SERVER +
          `/member/${viewState.contents.member_id}/document/${data.doc_id}`,
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
        //성공
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const alertUploadMsg = (data, file, fileName) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText={`${fileName} 을 업로드 하시겠습니까?`} />
          </>
        ),
        onBtnYes: () => {
          uploadFile(data, file);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const changeLongPostponeStatus = async (mode) => {
    try {
      const formData = new FormData();
      formData.append("new_info", JSON.stringify(viewState));

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_USER_SERVER + `/change/long_postpone/${mode}`,
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label labelText="정상적으로 처리 되었습니다." />
              </>
            ),
            onBtnOk: () => {
              getPostponeHist(1);
              refreshStore(); //연기페이지 업데이트
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const onClickBtn = (mode) => {
    if (mode === "allow") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="장기연기를 승인하시겠습니까?" />
            </>
          ),

          onBtnYes: () => {
            changeLongPostponeStatus("allow");
          },
          onBtnNo: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="장기연기 승인을 거부하시겠습니까?" />
            </>
          ),

          onBtnYes: () => {
            changeLongPostponeStatus("deny");
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const uploadDoneCallback = (result) => {
    console.log("result", result);
  };

  useEffect(() => {
    checkPermission();
  }, []);

  return (
    <div>
      {loading && <Loading />}

      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText={"연기 현황"} />
      </div>

      <div style={{ display: "flex" }}>
        <div style={{ marginLeft: "2rem", width: "50%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <DatePicker
              labelWidth={"7.5rem"}
              minWidth={
                viewState.permission.is_long_postpone_ing ? textBoxWidthShort : textBoxWidth
              }
              textMarginRight={viewState.permission.is_long_postpone_ing ? "0rem" : "0.35rem"}
              labelText="연기 기간"
              defaultValue={viewState.contents.active_postpone.from_date}
              disabled
            />
            <DatePicker
              labelWidth={viewState.permission.is_long_postpone_ing ? "0.5rem" : "0.85rem"}
              marginLeft="0.12rem"
              labelMarginRight="0.12rem"
              minWidth={
                viewState.permission.is_long_postpone_ing ? textBoxWidthShort : textBoxWidth
              }
              textMarginRight={"0.25rem"}
              labelText="~"
              defaultValue={viewState.contents.active_postpone.to_date}
              disabled
            />

            <NormalBtn name="연기 중단" theme="white" width="5.75rem" onClick={onStopPostponeEvt} />
          </div>
        </div>
        {viewState.permission.is_long_postpone_ing ? (
          <div style={{ display: "flex" }}>
            {viewState.contract_doc_list[0] && viewState.contract_doc_list[0].file_info.url_path ? (
              <>
                <TextField
                  labelWidth={labelWidthLong}
                  textBoxWidth={textBoxWidth}
                  textMarginRight={"0.25rem"}
                  labelText="첨부서류"
                  defaultValue={viewState.contract_doc_list[0].file_info.file_name}
                  disabled
                />
                <IconButton
                  type="download"
                  width="2.2rem"
                  onClick={() => {
                    downloadFile(storesDispatch, viewState.contract_doc_list[0]);
                  }}
                />
              </>
            ) : (
              <IconPostponeDocInfo
                docList={viewState.contract_doc_list ? viewState.contract_doc_list : []}
                memberId={viewState.contents.member_id}
                uploadDoneCallback={uploadDoneCallback}
                textBoxWidth={textBoxWidth}
              />
            )}
          </div>
        ) : (
          ""
        )}
      </div>

      {viewState.permission.is_long_postpone_ing ? (
        <div style={{ display: "flex", marginTop: "0.4rem" }}>
          <div style={{ marginLeft: "2rem", width: "50%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                labelWidth={"7.5rem"}
                textBoxWidth={hasPermission ? "17.75rem" : "21.3rem"}
                textMarginRight={"0.25rem"}
                labelText="장기연기 승인 상태"
                defaultValue={viewState.contents.active_postpone.approve_status}
                disabled
              />
              {hasPermission && (
                <div style={{ display: "flex", gap: "0.3rem" }}>
                  <NormalBtn
                    theme="white"
                    width="5.75rem"
                    name="거부"
                    disabled={!viewState.permission.can_long_postpone_deny}
                    onClick={() => {
                      onClickBtn("deny");
                    }}
                  />
                  <NormalBtn
                    width="5.75rem"
                    name="승인"
                    disabled={!viewState.permission.can_long_postpone_allow}
                    onClick={() => {
                      onClickBtn("allow");
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <TextField
              labelWidth={labelWidthLong}
              textBoxWidth={textBoxWidth}
              textMarginRight={"0.25rem"}
              labelText="서류 제출 기한"
              defaultValue={viewState.contents.active_postpone.file_due_date}
              disabled
            />
            {!viewState.permission.can_long_postpone_allow ? (
              ""
            ) : (
              <IconButton
                width="2.2rem"
                type="editBorder"
                onClick={() => {
                  popChangePaperDueModal(viewState.contents.active_postpone.file_due_date);
                }}
              />
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      <div style={{ marginTop: "0.5rem", marginLeft: "2rem" }}>
        <div
          style={{
            display: "flex",
          }}
        ></div>
      </div>

      <SubLine />
    </div>
  );
};
export default PostponeContents;
