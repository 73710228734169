import { forwardRef } from "react";

import AwardCard from "./AwardCard";
import CareerCard from "./CareerCard";
import CertCard from "./CertCard";
import EduCard from "./EduCard";
import FamilyCard from "./FamilyCard";
import MilitaryCard from "./MilitaryCard";

const ContentCard = forwardRef((props, ref) => {
  // console.log(props);
  return (
    <div>
      <MilitaryCard staffInfoState={props.staffInfoState} />
      <EduCard staffInfoState={props.staffInfoState} />
      <CareerCard staffInfoState={props.staffInfoState} />
      <FamilyCard staffInfoState={props.staffInfoState} />
      <AwardCard staffInfoState={props.staffInfoState} />
      <CertCard staffInfoState={props.staffInfoState} />
    </div>
  );
});
export default ContentCard;
