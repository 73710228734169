import { Button } from "@material-ui/core";
import border from "assets/icon/Border.svg";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import RadioGroup from "components/LabelInput/RadioGroup";
import TextArea from "components/LabelInput/TextArea";
import TextField from "components/LabelInput/TextField";
import TimePicker from "components/LabelInput/TimePicker";
import Loading from "components/Loading/Loading/Loading";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import SearchMemberInBranch from "pages/CommonModal/Search/SearchMemberInBranch";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

const MakeReservModal = ({ modalParam, onModalDone }) => {
  const viewModelRef = useRef();
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();
  const labelWidth = "4rem";
  const textBoxWidthShort = "10rem";
  const [isEdit, setIsEdit] = useState(modalParam.isEdit);
  // const [allDayOption, setAlldayOption] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });
  const [loading, setLoading] = useState(false);

  const [info, setInfo] = useState({
    schedule_base: {
      branch_info: modalParam.searchParam.branch_info,
      hq_info: modalParam.searchParam.hq_info,
      charger_staff_info:
        modalParam.mode === "day" && modalParam.isEdit
          ? modalParam.staff
          : modalParam.searchParam.charger_staff_info,
      from_date: modalParam.isEdit ? modalParam.date : "",
      to_date: modalParam.isEdit ? modalParam.date : "",
      is_all_day: false,
      from_time: modalParam.isEdit ? modalParam.time : "",
      to_time: "",
      schedule_info: {
        schedule_id: "",
        schedule_name: "",
        schedule_type: modalParam.scheduleType,
        schedule_sub_type: "OT",
      },
    },
    sub_info: {
      memo: "",
      proceed_class: {
        class_status: "",
      },
      reserv_member: {
        member_id: "",
        member_name: "",
        phone: "",
        member_no: "",
      },
    },
  });

  const validationCheck = (isNew) => {
    if (
      !info.schedule_base.from_date ||
      !info.schedule_base.to_date ||
      !info.schedule_base.from_time ||
      !info.schedule_base.to_time
    ) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="일시를 입력해주세요." />
            </>
          ),

          onBtnOk: () => {},
        }),
      );
    } else if (!info.sub_info.reserv_member.member_name) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="회원을 선택해주세요." />
            </>
          ),

          onBtnOk: () => {},
        }),
      );
    } else if (
      !info.sub_info.reserv_member.phone ||
      info.sub_info.reserv_member.phone.length !== 13
    ) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="연락처를 확인해주세요." />
              <Label labelText="예시) 010-1111-2222 (O)" />
            </>
          ),

          onBtnOk: () => {},
        }),
      );
    } else if (!info.schedule_base.charger_staff_info.staff_name) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="직원을 선택해주세요." />
            </>
          ),

          onBtnOk: () => {},
        }),
      );
    } else if (!info.sub_info.memo) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="내용을 입력해주세요." />
            </>
          ),

          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="등록 하시겠습니까?" />
            </>
          ),

          onBtnYes: () => {
            setIsEdit(false);
            registerReserv(isNew);
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const registerReserv = async (isNew) => {
    try {
      const formData = new FormData();
      formData.append("is_new", isNew);
      formData.append("new_info", JSON.stringify(info));
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_STAFF_SERVER + "/schedule/reserv/upsert",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공

        onModalDone(); //모달 닫기 + 현재서파로 달력 갱신
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (modalParam.searchParam.ch_staff_list) {
      // filter로 이름 "" 는 제외
      const nameArr = modalParam.searchParam.ch_staff_list
        .map((el) => el.staff_name)
        .filter((name) => name !== "");
      setStaffList(nameArr);
    }
  }, [modalParam]);

  const popSearchMember = (title, param, onModalDone) => {
    setModalInfo({
      show: true,
      title: title,
      Content: <SearchMemberInBranch modalParam={param} onModalDone={onModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  //회원검색 모달 DONE
  const getMemberInfo = (data) => {
    viewModelRef.current.setClose();
    setInfo((cur) => {
      const obj = { ...cur };
      obj.sub_info.reserv_member.member_id = data.member_info.member_id;
      obj.sub_info.reserv_member.member_name = data.member_info.member_name;
      obj.sub_info.reserv_member.phone = data.base_info.phone;
      obj.sub_info.reserv_member.member_no = data.member_info.member_no;
      return obj;
    });
  };

  const getScheduleInfo = async () => {
    try {
      setLoading(true);

      const res = await HttpInstance.get(
        process.env.REACT_APP_STAFF_SERVER + "/schedule/reserv/view",
        {
          params: {
            schedule_id: modalParam.scheduleId,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        const result = res.data.data;
        setInfo((cur) => {
          const obj = { ...cur };
          obj.schedule_base.branch_info = result.schedule_base.branch_info;
          obj.schedule_base.hq_info = result.schedule_base.hq_info;
          obj.schedule_base.charger_staff_info = result.schedule_base.charger_staff_info;
          obj.schedule_base.from_date = result.schedule_base.from_date;
          obj.schedule_base.from_time = result.schedule_base.from_time;
          obj.schedule_base.to_date = result.schedule_base.to_date;
          obj.schedule_base.to_time = result.schedule_base.to_time;
          obj.schedule_base.schedule_info.schedule_type =
            result.schedule_base.schedule_info.schedule_type;
          obj.schedule_base.schedule_info.schedule_id =
            result.schedule_base.schedule_info.schedule_id;
          obj.schedule_base.is_all_day = result.schedule_base.is_all_day;
          obj.schedule_base.schedule_info.schedule_sub_type =
            result.schedule_base.schedule_info.schedule_sub_type;
          obj.sub_info.proceed_class.class_status = result.sub_info.proceed_class.class_status;
          obj.sub_info.memo = result.sub_info.memo;
          obj.sub_info.reserv_member.member_id = result.sub_info.reserv_member.member_id;
          obj.sub_info.reserv_member.member_name = result.sub_info.reserv_member.member_name;
          obj.sub_info.reserv_member.phone = result.sub_info.reserv_member.phone;
          obj.sub_info.reserv_member.member_no = result.sub_info.reserv_member.member_no;
          return obj;
        });
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  const onDeleteEvt = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="다음 스케줄을 삭제하시겠습니까?" />
            <Label
              labelText={`${modalParam.data.card_title}  - ${modalParam.data.schedule_base.charger_staff_info.staff_name}`}
            />
          </>
        ),

        onBtnYes: () => {
          deleteSchedule();
        },
        onBtnNo: () => {},
      }),
    );
  };

  const deleteSchedule = async () => {
    try {
      setLoading(true);

      const res = await HttpInstance.delete(
        process.env.REACT_APP_STAFF_SERVER + "/schedule/remove",
        {
          params: {
            schedule_id: modalParam.scheduleId,
          },
        },
      );
      if (!res.data.data) {
        onModalDone(); //모달 닫기 + 달력갱신
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!modalParam.isEdit) {
      getScheduleInfo();
    }
  }, [modalParam]);

  return (
    <div style={{ width: "28rem" }}>
      {loading && <Loading />}

      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          alt="border"
          src={border}
          style={{
            width: "1.2rem",
            height: "1.2rem",
          }}
        />

        <SubTitle titleText="일정 정보" />
      </div>
      {modalParam.scheduleType === "수업완료" ? (
        <div style={{ display: "flex" }}>
          <TextField
            labelWidth={labelWidth}
            labelText="구분"
            labelMarginRight="0rem"
            labelMarginLeft="1rem"
            textBoxWidth="8.5rem"
            disabled
            defaultValue={info.schedule_base.schedule_info.schedule_sub_type}
          />
          <TextField
            labelWidth={labelWidth}
            labelText="완료구분"
            labelMarginLeft="1rem"
            textBoxWidth="8.5rem"
            disabled
            defaultValue={info.sub_info.proceed_class.class_status}
          />
        </div>
      ) : (
        <RadioGroup
          data={["OT", "PT", "상담"]}
          isMulti={true}
          labelWidth={labelWidth}
          labelText="예약구분"
          labelMarginLeft="1rem"
          radioMarginLeft="0.5rem"
          radioMarginRight="0.5rem"
          disabled={!isEdit}
          defaultValue={
            info.schedule_base.schedule_info.schedule_sub_type !== "OT"
              ? info.schedule_base.schedule_info.schedule_sub_type
              : "OT"
          }
          onChangeCallback={(e, v) => {
            setInfo((cur) => {
              const obj = { ...cur };
              obj.schedule_base.schedule_info.schedule_sub_type = v;
              return obj;
            });
          }}
        />
      )}

      <div style={{ display: "flex", marginTop: "0.3rem" }}>
        <DatePicker
          labelText="일시"
          labelWidth={labelWidth}
          labelMarginLeft="1rem"
          textBoxWidth={modalParam.scheduleType === "수업완료" ? "8.5rem" : "22.95rem"}
          labelMarginRight="0rem"
          defaultValue={info.schedule_base.from_date}
          disabled={!isEdit || (isEdit && modalParam.mode !== "month")}
          onChangeCallback={(e) => {
            setInfo((cur) => {
              const obj = { ...cur };
              obj.schedule_base.from_date = e;
              obj.schedule_base.to_date = e;
              return obj;
            });
          }}
        />
        {modalParam.scheduleType === "수업완료" && (
          <TimePicker
            labelText="수업시간"
            labelWidth={labelWidth}
            labelMarginLeft="1rem"
            textBoxWidth="8.5rem"
            disabled={!isEdit || (isEdit && info.schedule_base.is_all_day)}
            defaultValue={info.schedule_base.from_time}
            onChange={(e) => {
              setInfo((cur) => {
                const obj = { ...cur };
                obj.schedule_base.from_time = e;
                return obj;
              });
            }}
          />
        )}
      </div>

      {modalParam.scheduleType === "예약" && (
        <div style={{ display: "flex", marginTop: "0.3rem" }}>
          <TimePicker
            labelMarginRight="0rem"
            labelWidth={labelWidth}
            labelMarginLeft="1rem"
            textMarginRight="1.15rem"
            textBoxWidth={textBoxWidthShort}
            disabled={!isEdit || (isEdit && info.schedule_base.is_all_day)}
            defaultValue={info.schedule_base.from_time}
            onChange={(e) => {
              setInfo((cur) => {
                const obj = { ...cur };
                obj.schedule_base.from_time = e;
                return obj;
              });
            }}
          />
          <TimePicker
            labelText="~"
            labelWidth="1.15rem"
            textBoxWidth={textBoxWidthShort}
            disabled={!isEdit || (isEdit && info.schedule_base.is_all_day)}
            defaultValue={info.schedule_base.to_time}
            onChange={(e) => {
              setInfo((cur) => {
                const obj = { ...cur };
                obj.schedule_base.to_time = e;
                obj.schedule_base.to_date = obj.schedule_base.from_date;
                return obj;
              });
            }}
          />
        </div>
      )}

      <div style={{ display: "flex", alignItems: "center", marginTop: "0.7rem" }}>
        <img
          alt="border"
          src={border}
          style={{
            width: "1.2rem",
            height: "1.2rem",
          }}
        />

        <SubTitle titleText="회원 정보" />
      </div>
      {modalParam.isEdit ? (
        <>
          <div style={{ display: "flex" }}>
            <TextField
              labelWidth={labelWidth}
              labelText="예약자명"
              labelMarginRight="0rem"
              labelMarginLeft="1rem"
              textMarginRight="0.3rem"
              textBoxWidth="18rem"
              defaultValue={info.sub_info.reserv_member.member_name}
              onBlur={(e) => {
                setInfo((cur) => {
                  const obj = { ...cur };
                  obj.sub_info.reserv_member.member_name = e.target.value;
                  return obj;
                });
              }}
            />
            <Button
              className={clsx({
                [btnStyleClass.btnRootWhite]: true,
                [btnStyleClass.buttonType4]: true,
              })}
              onClick={() => {
                popSearchMember(
                  "회원 검색",
                  { branch_info: info.schedule_base.branch_info },
                  getMemberInfo,
                );
              }}
            >
              검색
            </Button>
          </div>
          <div style={{ display: "flex", marginTop: "0.3rem" }}>
            <TextField
              maxLength={13}
              labelWidth={labelWidth}
              labelText="연락처"
              labelMarginLeft="1rem"
              fullWidth
              // placeholder={"010-1111-2222"}
              defaultValue={info.sub_info.reserv_member.phone}
              onBlur={(e) => {
                setInfo((cur) => {
                  const obj = { ...cur };
                  obj.sub_info.reserv_member.phone = e.target.value;
                  return obj;
                });
              }}
            />
          </div>
        </>
      ) : (
        <div style={{ display: "flex" }}>
          <TextField
            labelWidth={labelWidth}
            labelText="회원명"
            labelMarginRight="0rem"
            labelMarginLeft="1rem"
            textBoxWidth="9rem"
            disabled={!isEdit}
            defaultValue={info.sub_info.reserv_member.member_name}
            onBlur={(e) => {
              setInfo((cur) => {
                const obj = { ...cur };
                obj.sub_info.reserv_member.member_name = e.target.value;
                return obj;
              });
            }}
          />
          <TextField
            maxLength={13}
            labelWidth="2.5rem"
            labelText="연락처"
            labelMarginLeft="1rem"
            textBoxWidth="9rem"
            disabled={!isEdit}
            // placeholder={modalParam.isEdit && "010-1111-2222"}
            defaultValue={info.sub_info.reserv_member.phone}
            onBlur={(e) => {
              setInfo((cur) => {
                const obj = { ...cur };
                obj.sub_info.reserv_member.phone = e.target.value;
                return obj;
              });
            }}
          />
        </div>
      )}

      <div style={{ display: "flex", alignItems: "center", marginTop: "0.7rem" }}>
        <img
          alt="border"
          src={border}
          style={{
            width: "1.2rem",
            height: "1.2rem",
          }}
        />

        <SubTitle titleText="담당자 정보" />
      </div>
      <ComboBox
        fullWidth
        labelWidth={labelWidth}
        labelMarginLeft="1rem"
        labelText="담당자"
        disabled={!isEdit || (modalParam.mode === "day" && modalParam.isEdit)}
        data={staffList}
        defaultValue={info.schedule_base.charger_staff_info.staff_name}
        onChangeCallback={(e) => {
          const staff = modalParam.searchParam.ch_staff_list.find(
            (el) => el.staff_name === e.target.value,
          );
          setInfo((cur) => {
            const obj = { ...cur };
            obj.schedule_base.charger_staff_info = staff;
            return obj;
          });
        }}
      />
      <TextArea
        marginTop="0.3rem"
        labelWidth={labelWidth}
        labelMarginLeft="1rem"
        labelText="메모"
        minRows={4}
        maxRows={4}
        fullWidth
        defaultValue={info.sub_info.memo}
        disabled={!isEdit}
        onBlur={(e) => {
          setInfo((cur) => {
            const obj = { ...cur };
            obj.sub_info.memo = e.target.value;
            return obj;
          });
        }}
      />
      {modalParam.isEdit ? (
        <div
          style={{ display: "flex", marginTop: "2rem", justifyContent: "center", gap: "0.5rem" }}
        >
          <Button
            className={clsx({
              [btnStyleClass.btnRoot]: true,
              [btnStyleClass.buttonType4]: true,
            })}
            onClick={() => {
              validationCheck(true);
            }}
          >
            등록
          </Button>
          <Button
            className={clsx({
              [btnStyleClass.btnRootWhite]: true,
              [btnStyleClass.buttonType4]: true,
            })}
            onClick={() => {
              onModalDone();
            }}
          >
            취소
          </Button>
        </div>
      ) : modalParam.scheduleType !== "수업완료" ? (
        <div
          style={{ display: "flex", marginTop: "2rem", gap: "0.5rem", justifyContent: "center" }}
        >
          {isEdit ? (
            <>
              <Button
                className={clsx({
                  [btnStyleClass.btnRoot]: true,
                  [btnStyleClass.buttonType4]: true,
                })}
                onClick={() => {
                  validationCheck(false);
                }}
              >
                저장
              </Button>
              <Button
                className={clsx({
                  [btnStyleClass.btnRootWhite]: true,
                  [btnStyleClass.buttonType4]: true,
                })}
                onClick={() => {
                  setIsEdit(false);
                }}
              >
                취소
              </Button>
            </>
          ) : (
            <Button
              className={clsx({
                [btnStyleClass.btnRoot]: true,
                [btnStyleClass.buttonType4]: true,
              })}
              onClick={() => {
                setIsEdit(true);
              }}
            >
              수정
            </Button>
          )}
          {!isEdit && (
            <Button
              className={clsx({
                [btnStyleClass.btnRootWhite]: true,
                [btnStyleClass.buttonType4]: true,
              })}
              onClick={() => {
                onDeleteEvt();
              }}
            >
              삭제
            </Button>
          )}
        </div>
      ) : (
        <div style={{ display: "flex", marginTop: "2rem", justifyContent: "center" }}>
          <Button
            className={clsx({
              [btnStyleClass.btnRoot]: true,
              [btnStyleClass.buttonType4]: true,
            })}
            onClick={() => {
              storesDispatch(globalModalOff());
            }}
          >
            확인
          </Button>
        </div>
      )}
    </div>
  );
};
export default MakeReservModal;
