import { SubLine } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import DocsStatusCount from "./DocsStatusCount";
import SearchArea from "./SearchArea";
import SearchResultArea from "./SearchResultArea";

const CommonTabComp = ({
  tabType,
  onClickSearch,
  searchResult,
  totalPage,
  onClickFavorite,
  onClickNewDraft,
  resultSummary,
}) => {
  return (
    <div>
      {tabType === "my_draft" ? (
        <Label
          labelText="※ 내가 기안한 문서의 전체 목록을 확인할 수 있습니다."
          color="#777"
          justifyContent="flex-start"
          fontSize="0.9rem"
        />
      ) : (
        <div></div>
      )}

      <div style={{ display: "flex", marginTop: "1rem" }}>
        <div style={{ maxWidth: "65%" }}>
          <SearchArea onClickSearch={onClickSearch} tabType={tabType} />
        </div>
        <div style={{ maxWidth: "35%", marginLeft: "3rem" }}>
          {tabType === "my_draft" ? <DocsStatusCount resultSummary={resultSummary} /> : <></>}
        </div>
      </div>
      <SubLine />

      <SearchResultArea
        tabType={tabType}
        onClickFavorite={onClickFavorite}
        onClickNewDraft={onClickNewDraft}
        searchResult={searchResult}
        onClickSearch={onClickSearch}
        totalPage={totalPage}
      />
    </div>
  );
};
export default CommonTabComp;
