import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, generateObjectDummyId } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import DatePicker from "components/LabelInput/DatePicker";
import TextField from "components/LabelInput/TextField";
import TableTitleWithButton from "components/TableTitleWithButton/TableTitleWithButton";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

const CareerCard = forwardRef((props, ref) => {
  const [itemInfoList, setItemInfoList] = useState(props.staffInfoState.career);

  useEffect(() => {
    setItemInfoList(props.staffInfoState.career);
  }, [props.staffInfoState.career]);

  useImperativeHandle(ref, () => ({
    getItemList: () => {
      return itemInfoList;
    },
  }));

  const btnItemPush = () => {
    const idx = itemInfoList.length;
    const baseItemInfo = {
      id: generateObjectDummyId(),
      idx: idx,
      comp_name: "",
      work_from_date: "",
      work_end_date: "",
      position: "",
    };

    if (itemInfoList === []) {
      setItemInfoList([baseItemInfo]);
    } else {
      setItemInfoList([...itemInfoList, baseItemInfo]);
    }
    props.staffInfoState.career.push(baseItemInfo);
  };

  const btnItemPop = (targetId) => {
    var array = [...itemInfoList]; // make a separate copy of the array
    const index = array.map((item) => item.id).indexOf(targetId);

    if (index !== -1) {
      array.splice(index, 1);
      props.staffInfoState.career.splice(index, 1);
      setItemInfoList(array);
    }
  };

  const periodRef = useRef();

  const itemList = new itemInfoTable(btnItemPop, periodRef);

  return (
    <div style={{ padding: "0.625rem 1.25rem", border: "1px solid #bbb", marginTop: "1rem" }}>
      <div style={{}}>
        <TableTitleWithButton
          titleLabel={"경력 사항"}
          buttonText={"추가"}
          onClickEvent={btnItemPush}
        />
      </div>
      <div>
        <CustomTable
          columns_head={itemList.columns_head}
          table_title={itemList.table_title}
          rest_call={itemList.get_data_from_rest}
          row_render={itemList.create_table}
          rest_data={itemInfoList}
        />
      </div>
    </div>
  );
});
export default CareerCard;

class itemInfoTable {
  table_title = "";
  btnStyleClass = btnStyles();
  // refundInfoList = [];
  btnPop = null;
  periodRef = null;

  constructor(btnPop, periodRef) {
    this.btnPop = btnPop;
    this.periodRef = periodRef;
  }

  calcServicePeriod = (from, end) => {
    const _from = from.split("-");
    const _end = end.split("-");
    const _from1 = new Date(_from[0], _from[1], _from[2]);
    const _end1 = new Date(_end[0], _end[1], _end[2]);

    let diff = _end1 - _from1;

    const cDay = 24 * 60 * 60 * 1000;
    const cMonth = cDay * 30;
    const cYear = cMonth * 12;

    const year = parseInt(diff / cYear);
    diff -= year * cYear;
    const month = parseInt(diff / cMonth);
    if (!isNaN(year)) {
      return year + "년 " + month + "개월";
    } else {
      return "";
    }
  };

  create_table = (data, key) => {
    return (
      <CustomRow key={key} style={{ width: "100%" }}>
        <CustomTableContents width="10%">
          {/* {data.pay_method} */}
          <DatePicker
            justifyContent="center"
            minWidth={"12rem"}
            labelText=""
            defaultValue={data.work_from_date}
            onChangeCallback={(e) => {
              data.work_from_date = e;
              this.periodRef.current.value = this.calcServicePeriod(
                data.work_from_date,
                data.work_end_date,
              );
            }}
          />
        </CustomTableContents>

        <CustomTableContents width="10%">
          <DatePicker
            justifyContent="center"
            minWidth={"12rem"}
            defaultValue={data.work_end_date}
            onChangeCallback={(e) => {
              data.work_end_date = e;
              this.periodRef.current.value = this.calcServicePeriod(
                data.work_from_date,
                data.work_end_date,
              );
            }}
          />
        </CustomTableContents>

        <CustomTableContents width="10%">
          <TextField
            justifyContent="center"
            textBoxWidth={"14rem"}
            labelMarginLeft={"0rem"}
            textMarginRight={"0rem"}
            defaultValue={data.comp_name}
            onChangeCallback={(e) => {
              data.comp_name = e;
            }}
          />
        </CustomTableContents>
        <CustomTableContents width="10%">
          <TextField
            justifyContent="center"
            textBoxWidth={"14rem"}
            labelMarginLeft={"0rem"}
            textMarginRight={"0rem"}
            defaultValue={data.position}
            onChangeCallback={(e) => {
              data.position = e;
            }}
          />
        </CustomTableContents>
        <CustomTableContents width="10%">
          <TextField
            inputRef={this.periodRef}
            justifyContent="center"
            textBoxWidth={"14rem"}
            labelMarginLeft={"0rem"}
            textMarginRight={"0rem"}
            defaultValue={this.calcServicePeriod(data.work_from_date, data.work_end_date)}
            disabled
          />
        </CustomTableContents>
        <CustomTableContents width="5%">
          {/* {data.remove} */}
          {data.is_lock ? (
            <Button
              className={clsx({
                [this.btnStyleClass.btnRootRaw]: true,
                [this.btnStyleClass.buttonType2]: true,
              })}
              onClick={() => this.btnPop(data.id)}
            >
              삭제
            </Button>
          ) : (
            ""
          )}
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "재직 시작일", field: "select" },
    { title: "재직 종료일", field: "pay_method" },
    { title: "직장명", field: "pay_method" },
    { title: "직위", field: "card_no" },
    { title: "근무기간", field: "card_no" },
    { title: "", field: "remove" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
