import CardFixed from "components/Card/CardFixed";
import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import MemberLocationStatsView from "./MemberLocationStatsView";

const MemberLocationStatsWrapper = () => {
  return (
    <>
      <PageFrame>
        <div>
          <PageTitle titleText="회원 위치 통계" />

          <div>
            <CardFixed
              midDom={
                <div>
                  <MemberLocationStatsView />
                </div>
              }
              isLoading={false}
              rootStyle={{
                borderRadius: "10px",
                backgroundColor: "#fff",
              }}
            />
          </div>
        </div>
      </PageFrame>
    </>
  );
};
export default MemberLocationStatsWrapper;
