import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import SetPermissionStaff from "./SetPermissionStaff";

const SetPermissionModal = ({ modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const permissionInfoRef = useRef();

  const onClickSave = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: <Label labelText="저장하시겠습니까?" />,
        onBtnYes: () => {
          setLoading(true);
          onSaveEvent();
        },
        onBtnNo: () => {},
      }),
    );
  };

  const onSaveEvent = async () => {
    setLoading(true);

    const setPermissionData = permissionInfoRef.current.getPermission();
    setPermissionData.branch_info = modalParam.targetParams.branch_info;
    setPermissionData.hq_info = modalParam.targetParams.hq_info;
    setPermissionData.staff_id = modalParam.targetParams.staff_id;

    const formData = new FormData();
    formData.append("staff_permission", JSON.stringify(setPermissionData));

    await HttpInstance.post(
      process.env.REACT_APP_STAFF_SERVER + "/hq/staff_permission_update",
      formData,
      contentTypeJson,
    )
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          storesDispatch(
            globalAlertOn({
              show: true,
              titleText: "알림",
              bodyText: (
                <>
                  <Label labelText="저장되었습니다." />
                  <Label labelText={"(권한은 즉시 반영됩니다)"} />
                </>
              ),
              onBtnOk: () => {},
            }),
          );
          onModalDone();
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
        // onModalDone(modalParam.refreshStore);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ width: "40rem" }}>
      {loading && <Loading />}

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={onClickSave}
        >
          저장
        </Button>
      </div>

      <SetPermissionStaff
        ref={permissionInfoRef}
        setLoading={setLoading}
        modalParam={{
          srcPermission: modalParam.srcPermission,
          targetParams: {
            branch_info: modalParam.targetParams.branch_info,
            staff_id: modalParam.targetParams.staff_id,
            hq_info: modalParam.targetParams.hq_info,
          },
        }}
      />
    </div>
  );
};
export default SetPermissionModal;
