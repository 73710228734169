import IconButton from "components/Button/IconButton";
import { dataEmpty, getByte, getPermission } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomScrollDiv,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import ColorLabel from "components/LabelInput/ColorLabel";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const OtSearchResult = ({
  searchResult,
  statusType,
  curPage,
  onClickSearch,
  totalPage,
  onClickOpenModal,
  refreshTable,
}) => {
  const storesDispatch = useDispatch();
  const [canAddServiceSession, setCanAddServiceSession] = useState(false);

  useEffect(() => {
    getPermission(storesDispatch, "CLASS_MENU/CLASS_EXT_MANAGE").then((res) => {
      setCanAddServiceSession(res);
    });
  }, []);

  const popOtDetailModal = (data) => {
    const param = {
      title: "OT 수업상세보기",
      modalParam: {
        data,
        refreshTable,
        canAddServiceSession,
      },
    };
    onClickOpenModal("OT수업상세", param);
  };

  const unSignedResultList = new unassignedResultTable();
  const assignedResultList = new assignedResultTable();
  const fixedResultList = new fixedTable(statusType, popOtDetailModal);

  return (
    <div>
      <div style={{ display: "flex", width: "100%", marginTop: "1rem" }}>
        <div style={{ width: "85%", borderRight: "1px solid #BBB" }}>
          {statusType !== "미배정" ? (
            <CustomTable
              width={"120rem"}
              columns_head={assignedResultList.columns_head}
              table_title={assignedResultList.table_title}
              rest_call={assignedResultList.get_data_from_rest}
              row_render={assignedResultList.create_table}
              rest_data={searchResult}
            />
          ) : (
            <CustomTable
              columns_head={unSignedResultList.columns_head}
              table_title={unSignedResultList.table_title}
              rest_call={unSignedResultList.get_data_from_rest}
              row_render={unSignedResultList.create_table}
              rest_data={searchResult}
            />
          )}
        </div>
        <div style={{ width: "15%" }}>
          <CustomTable
            columns_head={fixedResultList.columns_head}
            table_title={fixedResultList.table_title}
            rest_call={fixedResultList.get_data_from_rest}
            row_render={fixedResultList.create_table}
            rest_data={searchResult}
          />
        </div>
      </div>
      <CustomPagination curPage={curPage} doSearch={onClickSearch} totalCnt={totalPage} />
    </div>
  );
};
export default OtSearchResult;

class unassignedResultTable {
  table_title = "";

  getAge = (birth) => {
    const thisYear = new Date().getFullYear();
    const birthYear = new Date(birth).getFullYear();
    let age = thisYear - birthYear + 1;
    return age;
  };

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents height={"2.375rem"}>
          {data.membership_info.member_no}
        </CustomTableContents>
        <CustomTableContents>
          <CustomScrollDiv leng={getByte(data.membership_info.member_name)} set={12}>
            {data.membership_info.member_name}
          </CustomScrollDiv>
        </CustomTableContents>

        <CustomTableContents>{data.membership_info.gender}</CustomTableContents>
        <CustomTableContents>{data.membership_info.phone}</CustomTableContents>
        <CustomTableContents>{this.getAge(data.membership_info.birth)}</CustomTableContents>
        <CustomTableContents>{data.membership_info.is_new ? "신규" : "재등록"}</CustomTableContents>
        <CustomTableContents>{data.membership_info.from_date}</CustomTableContents>

        <CustomTableContents>
          {data.ot_info.remain_session_cnt + " / " + data.ot_info.ot_session_cnt}
        </CustomTableContents>
        <CustomTableContents>{data.ot_info.latest_contact}</CustomTableContents>
        <CustomTableContents>
          <CustomScrollDiv leng={getByte(data.ot_info.comment)} set={12}>
            {data.ot_info.comment}
          </CustomScrollDiv>
        </CustomTableContents>
        <CustomTableContentsEND align="center">
          <CustomScrollDiv leng={getByte(data.remarks)} set={12}>
            {data.remarks}
          </CustomScrollDiv>
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "회원번호", width: "8%" },
    { title: "이름", width: "7.5%" },
    { title: "성별", width: "4%" },
    { title: "연락처", width: "10%" },
    { title: "나이", width: "5%" },
    { title: "회원권 구분", width: "7.5%" },
    { title: "회원권 시작일", width: "8.5%" },
    { title: "잔여 세션", width: "7.5%" },
    { title: "최근 연락일", width: "10%" },
    { title: "특이사항", width: "16%" },
    { title: "비고", width: "16%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}

class assignedResultTable {
  table_title = "";

  getAge = (birth) => {
    const thisYear = new Date().getFullYear();
    const birthYear = new Date(birth).getFullYear();
    let age = thisYear - birthYear + 1;
    return age;
  };

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents height={"2.375rem"}>
          {data.ot_info.mbshp_paid_date}
        </CustomTableContents>
        <CustomTableContents>{data.membership_info.member_no}</CustomTableContents>
        <CustomTableContents>
          <CustomScrollDiv leng={getByte(data.membership_info.member_name)} set={10}>
            {data.membership_info.member_name}
          </CustomScrollDiv>
        </CustomTableContents>
        <CustomTableContents>{data.membership_info.gender}</CustomTableContents>
        <CustomTableContents>{data.membership_info.phone}</CustomTableContents>
        <CustomTableContents>{this.getAge(data.membership_info.birth)}</CustomTableContents>

        <CustomTableContents>{data.membership_info.is_new ? "신규" : "재등록"}</CustomTableContents>
        <CustomTableContents>{data.membership_info.from_date}</CustomTableContents>
        <CustomTableContents>
          {data.ot_info.remain_session_cnt + " / " + data.ot_info.ot_session_cnt}
        </CustomTableContents>
        <CustomTableContents>{data.alloc_date.slice(0, 10)}</CustomTableContents>
        <CustomTableContents>
          <CustomScrollDiv leng={getByte(data.cur_manager.staff_info.staff_name)} set={12}>
            {data.cur_manager.staff_info.staff_name}
          </CustomScrollDiv>
        </CustomTableContents>
        <CustomTableContents>
          {data.ot_class.length === 0 ? "" : data.ot_class[0].class_done_date}
        </CustomTableContents>
        <CustomTableContents>
          {data.ot_class.length !== 2 ? "" : data.ot_class[1].class_done_date}
        </CustomTableContents>

        <CustomTableContents>{data.ot_info.latest_contact}</CustomTableContents>
        <CustomTableContents>
          {data.ot_info.suggest_date ? data.ot_info.suggest_date : "안함"}
        </CustomTableContents>
        <CustomTableContents>
          <CustomScrollDiv leng={getByte(data.ot_info.comment)} set={14}>
            {data.ot_info.comment}
          </CustomScrollDiv>
        </CustomTableContents>
        <CustomTableContentsEND align="center">
          <CustomScrollDiv leng={getByte(data.remarks)} set={14}>
            {data.remarks}
          </CustomScrollDiv>
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "등록일", width: "6%" },
    { title: "회원번호", width: "6%" },
    { title: "이름", width: "6.5%" },
    { title: "성별", width: "3.25%" },
    { title: "연락처", width: "7.5%" },
    { title: "나이", width: "3.25%" },

    { title: "회원권 구분", width: "6%" },
    { title: "회원권 시작일", width: "6.5%" },
    { title: "잔여 세션", width: "5%" },
    { title: "배정일", width: "6%" },

    { title: "수업 담당자", width: "6.25%" },
    { title: "1회차 진행일", width: "6.5%" },
    { title: "2회차 진행일", width: "6.5%" },

    { title: "최근 연락일", width: "6.5%" },
    { title: "PT 권유", width: "6.25%" },
    { title: "특이사항", width: "6.25%" },
    { title: "비고", width: "8.25%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}

class fixedTable {
  table_title = "";
  statusType;
  popOtDetailModal = null;

  constructor(statusType, popOtDetailModal) {
    this.statusType = statusType;
    this.popOtDetailModal = popOtDetailModal;
  }

  getColor_1 = (value) => {
    let bgColor = "";
    let fontColor = "";
    if (value === "수강") {
      bgColor = "#E4F4E1";
      fontColor = "#109B58";
    } else if (value === "대기") {
      bgColor = "#FFF4D7";
      fontColor = "#FFC737";
    } else if (value === "연기") {
      bgColor = "#FFE1D1";
      fontColor = "#FF6B17";
    } else if (value === "만료" || value === "소진완료") {
      bgColor = "#F8D8D8";
      fontColor = "#DE0909";
    }

    return { bgColor, fontColor };
  };

  getColor_2 = (value) => {
    let bgColor = "";
    let fontColor = "";
    if (value === "예약") {
      bgColor = "#E3EBF7";
      fontColor = "#578DDE";
    } else if (value === "대기") {
      bgColor = "#FFF4D7";
      fontColor = "#FFC737";
    } else if (value === "수업완료") {
      bgColor = "#D6D3F7";
      fontColor = "#3022D7";
    } else {
      bgColor = "#F8D8D8";
      fontColor = "#DE0909";
    }

    return { bgColor, fontColor };
  };

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents height={"2.375rem"}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ColorLabel
              width="4.1rem"
              labelText={data.ot_info.root_status}
              bgColor={this.getColor_1(data.ot_info.root_status).bgColor}
              fontColor={this.getColor_1(data.ot_info.root_status).fontColor}
            />
          </div>
        </CustomTableContents>
        <CustomTableContents>
          {this.statusType !== "미배정" ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ColorLabel
                width="4.1rem"
                labelText={data.ot_info.class_status}
                bgColor={this.getColor_2(data.ot_info.class_status).bgColor}
                fontColor={this.getColor_2(data.ot_info.class_status).fontColor}
              />
            </div>
          ) : (
            "-"
          )}
        </CustomTableContents>
        <CustomTableContentsEND align="center">
          <IconButton
            height="1rem"
            type="detail"
            onClick={() => {
              this.popOtDetailModal(data);
            }}
          />
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "OT상태", width: "40%" },
    { title: "OT 수업상태", width: "40%" },
    { title: "", width: "20%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
