import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChangeItemView from "./ChangeItemView";
import { loadChangeItemViewStore } from "./Module/actions";

const ChangeItemWrapper = ({
  memberInputState,
  memberId,
  branchId,
  memberBaseInfoState,
  initRegDate,
}) => {
  const serviceViewState = useSelector((state) => state.member_service.change_item_view_info);
  const navigate = useNavigate();
  const storesDispatch = useDispatch();

  useEffect(() => {
    storesDispatch(loadChangeItemViewStore({ member_id: memberId, reg_date: initRegDate }));
  }, [loadChangeItemViewStore, storesDispatch]);

  // child에 따른 순서 정렬
  // temp_check : 하나씩 추가되는걸 체크하기 위한 값. child는 이미 true여서 건너뛰도록 함
  // temp_row_cnt : 기존 상품 선택 테이블에서 선택 체크박스 row_span을 위한 값. 값만큼 merge함
  // useEffect(() => {
  //   const list = serviceViewState.stores.data.refund_item_info.refund_info_list;
  //   const sortedList = [];
  //   for (let i = 0; i < list.length; i++) {
  //     if (list[i].temp_check) continue;

  //     list[i].temp_check = true;
  //     list[i].temp_row_cnt = list[i].purchase_list.length + 1;

  //     sortedList.push(list[i]);

  //     const parentIdx = sortedList.length - 1;
  //     for (let j = 0; j < list[i].child_idx_list.length; j++) {
  //       const childIdx = list[i].child_idx_list[j];
  //       list[childIdx].temp_check = true;
  //       list[childIdx].temp_row_cnt = 0;
  //       list[i].temp_row_cnt += list[childIdx].purchase_list.length + 1;
  //       sortedList.push(list[childIdx]);
  //       sortedList[parentIdx].child_idx_list[j] = sortedList.length - 1;
  //     }
  //   }
  //   serviceViewState.stores.data.refund_item_info.refund_info_list = sortedList;
  // }, [serviceViewState.stores.data]);

  return (
    <div>
      <ChangeItemView
        memberInputState={memberInputState}
        serviceViewState={serviceViewState.stores.data}
        branchId={branchId}
        memberBaseInfoState={memberBaseInfoState}
        navigate={navigate}
        initRegDate={initRegDate}
      />
    </div>
  );
};
export default ChangeItemWrapper;
