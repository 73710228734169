import { contentTypeJson } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

// ==================== GET ====================
// [GET] 방명록 검색 조건 가져오기
export async function getGuestBookSearchParams() {
  return await HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/cs/guest_book/search_param", {
    params: {},
  });
}

// [GET] 방명록 검색
export async function getGuestBook(queryData) {
  const { page, searchParams } = queryData;
  const formData = new FormData();

  formData.append("page_num", page);
  formData.append("search_param", JSON.stringify(searchParams));

  return await HttpInstance.post(
    process.env.REACT_APP_USER_SERVER + "/cs/guest_book/search",
    formData,
    contentTypeJson,
  );
}
