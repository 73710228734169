import { restEmpty, simpleAlert, structToBranches } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { settingInitState } from "./Module/reducers/setting_init_state";
import RegisterView from "./RegisterView";

const RegisterOptionWrapper = ({ memberInputState }) => {
  const [baseInfoState, setBaseInfoState] = useState(_.cloneDeep(settingInitState));
  const navigate = useNavigate();
  const storesDispatch = useDispatch();

  const getSettingsData = (branchId) => {
    HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/register/settings_show", {
      params: { branch_id: branchId },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setBaseInfoState(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  useEffect(() => {
    getSettingsData("");
  }, [memberInputState]);

  const [branchIdx, setBranchIdx] = useState(0);
  const branchList = structToBranches(baseInfoState);

  const onBranchChangeAction = (idx) => {
    const tBranchList = baseInfoState.permission_branch_list;
    setBranchIdx(idx);
    getSettingsData(tBranchList[idx].branch_id);
  };

  return (
    <div>
      <RegisterView
        memberInputState={memberInputState}
        baseInfoState={baseInfoState}
        branchList={branchList}
        branchIdx={branchIdx}
        setBranchIdx={onBranchChangeAction}
        navigate={navigate}
      />
    </div>
  );
};
export default RegisterOptionWrapper;
