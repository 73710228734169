import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
// import { CustomRow } from "components/CustomTable/tableStyle";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadCSHistoryStore } from "../../Module/actions";

const CounselingHistoryTable = ({ memberId }) => {
  // const [counselingClass, setCounselingClass] = useState([]);

  // const [curPage, setCurPage] = useState(1);
  // const [totalPage, setTotalPage] = useState(0);

  const historyInfoState = useSelector((state) => state.member_history.cs_history_state);
  const storesDispatch = useDispatch();
  const [curPage, setCurPage] = useState(1);

  useEffect(() => {
    storesDispatch(loadCSHistoryStore(memberId));
  }, [memberId]);

  //페이지네이션
  useEffect(() => {
    if (curPage) {
      storesDispatch(loadCSHistoryStore(memberId, curPage));
    }
  }, [curPage]);

  const counselingHistoryTable = new counselingHistoryClass();

  return (
    <div>
      <CustomTable
        columns_head={counselingHistoryTable.columns_head}
        table_title={counselingHistoryTable.table_title}
        rest_call={counselingHistoryTable.get_data_from_rest}
        row_render={counselingHistoryTable.create_table}
        // rest_data={counselingClass}
        rest_data={historyInfoState.stores.data}
      />
      <CustomPagination
        curPage={curPage}
        doSearch={setCurPage}
        // totalCnt={totalPage}
        totalCnt={historyInfoState.stores.total_page ? historyInfoState.stores.total_page : 1}
      />
    </div>
  );
};
export default CounselingHistoryTable;

class counselingHistoryClass {
  table_title = "";
  btnStyleClass = btnStyles();

  create_table = (data, idx) => {
    // console.log(data);
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.cs_date + "  " + data.cs_time}</CustomTableContents>
        <CustomTableContents>{data.reg_info.reg_name}</CustomTableContents>
        <CustomTableContents>{data.cs_type}</CustomTableContents>
        <CustomTableContents>{data.inquiry_title}</CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "등록일시", width: "20%" },
    { title: "지점", width: "13%" },
    { title: "상담일시", width: "13%" },
    { title: "담당자", width: "16%" },
    { title: "구분", width: "16%" },
    { title: "주요 문의 내용", width: "22%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
