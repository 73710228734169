import { dataEmpty, getPermission } from "components/CommonLib/CommonLib";
import { globalModalOn } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SalaryInfoModalIndex from "./Modal/SalaryInfoModalIndex";
import SalaryInfoView from "./SalaryInfoView";

const SalaryInfoWrapper = ({ salaryReportId, is_edit }) => {
  const storesDispatch = useDispatch();
  const navigate = useNavigate();
  
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    checkPermission();
  }, [salaryReportId]);

  const checkPermission = async () => {
    const result = await getPermission(storesDispatch, "SALARY_MENU/SALARY_APPROV");
    setHasPermission(result);
  };

  const openModal = (select, param, doneCallback) => {
    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,
        Content: (
          <SalaryInfoModalIndex
            modalSelect={select}
            modalParam={param}
            onModalDone={doneCallback}
          />
        ),
      }),
    );
  };

  const onClickOpenModal = (order, param, doneCallback) => {
    if (order !== "") {
      if (dataEmpty(doneCallback)) {
        openModal(order, param, onModalDone);
      } else {
        openModal(order, param, doneCallback);
      }
    }
  };

  const onModalDone = (ret_info) => {
    console.log(ret_info);
  };

  const navigateToSalaryMain = () => {
    navigate("/salary_list");
  };

  return (
    <SalaryInfoView
      onClickOpenModal={onClickOpenModal}
      salaryReportId={salaryReportId}
      is_edit={is_edit}
      navigateToSalaryMain={navigateToSalaryMain}
      hasPermission={hasPermission}
    />
  );
};
export default SalaryInfoWrapper;
