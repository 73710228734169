import { useState } from "react";
import ExpenseListContent from "./ExpenseListContent";

const ExpenseListWrapper = ({ cashbookId, baseMonth, infoStateData }) => {
  const [searchParam, setSearchParam] = useState({
    create_type: "",
    account: "",
    cashbook_id: cashbookId,
  });

  return (
    <div>
      <ExpenseListContent
        searchParam={searchParam}
        setSearchParam={setSearchParam}
        cashbookId={cashbookId}
        baseMonth={baseMonth}
        infoStateData={infoStateData}
      />
    </div>
  );
};
export default ExpenseListWrapper;
