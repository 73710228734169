import {
  loadAssignView,
  loadAssignViewSuccess,
  loadAssignViewFail,
  loadAssignViewReset,
} from "./view_act";

import {
  setAssignParamSrcIdAct,
  setAssignParamDstIdAct,
  setAssignParamNewbieAct,
  clearAssignParamSrcIdAct,
  clearAssignParamDstIdAct,
  clearAssignParamNewbieAct,
  clearAssignParamAllAct,
} from "./select_act";

export const loadAssignViewStore = (src_member_id, dst_member_id) =>
  loadAssignView(src_member_id, dst_member_id);

export const assignAction = {
  loadAssignViewSuccess,
  loadAssignViewFail,
  loadAssignViewReset,
};

export const assignSelectAction = {
  setAssignParamSrcIdAct,
  setAssignParamDstIdAct,
  setAssignParamNewbieAct,
  clearAssignParamSrcIdAct,
  clearAssignParamDstIdAct,
  clearAssignParamNewbieAct,
  clearAssignParamAllAct,
};
