import {
  loadLockerSetting,
  loadLockerSettingSuccess,
  loadLockerSettingFail,
  loadLockerSettingShow,
  loadLockerSettingShowSuccess,
  loadLockerSettingShowFail,
  loadLockerAllocateShow,
  loadLockerAllocateShowSuccess,
  loadLockerAllocateShowFail,
  loadLockerAllocateShowReset,
  loadLockerDetailShow,
  loadLockerDetailShowSuccess,
  loadLockerDetailShowShowFail,
  loadLockerDetailShowReset,
} from "./locker_setting";

export const getLockerSettingStores = (branch_id, branch_name) =>
  loadLockerSetting(branch_id, branch_name);

export const getLockerSettingShowStores = (branch_id, branch_name) =>
  loadLockerSettingShow(branch_id, branch_name);

export const getLockerAllocateShowStores = (branch_id, branch_name, floor_id, set_info_id) =>
  loadLockerAllocateShow(branch_id, branch_name, floor_id, set_info_id);

export const getLockerDetailShowStores = (branch_id, branch_name, floor_id, zone_id, locker_idx) =>
  loadLockerDetailShow(branch_id, branch_name, floor_id, zone_id, locker_idx);

export const lockerSettingAction = {
  loadLockerSettingSuccess,
  loadLockerSettingFail,
  loadLockerSettingShowSuccess,
  loadLockerSettingShowFail,
  loadLockerAllocateShowSuccess,
  loadLockerAllocateShowFail,
  loadLockerAllocateShowReset,
  loadLockerDetailShowSuccess,
  loadLockerDetailShowShowFail,
  loadLockerDetailShowReset,
};
