import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, getCardCompList } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCMSPayPlanStateAPi } from "./subsPayApi/getApi";
import { subs_plan_state } from "./subsPayApi/subs_mthod";

const PayPlanState = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const subsPayList = new subsPayTable();

  const [subsState, setSubsState] = useState(subs_plan_state);
  // const [curPage, setCurPage] = useState(1);
  // const [totalPage, setTotalPage] = useState(1);
  console.log(subsState);
  const doSearch = async (page) => {
    // setCurPage(page);

    getCMSPayPlanStateAPi(
      storesDispatch,
      // modalParam.active_info.member_id,
      modalParam,
      setSubsState,
      // setTotalPage,
      // curPage,
    );
  };

  useEffect(() => {
    doSearch(1);
    return () => {
      // setMemberId("");
      // setCurPage(1);
      // setTotalPage(0);
      setSubsState(subs_plan_state);
    };
  }, [modalParam.params.member_id]);

  return (
    <>
      <div style={{ marginTop: "1rem", width: "60rem" }}>
        {/* <SubTitle titleText={"납부 정보"} /> */}
        <CustomTable
          columns_head={subsPayList.columns_head}
          table_title={subsPayList.table_title}
          rest_call={subsPayList.get_data_from_rest}
          row_render={subsPayList.create_table}
          rest_data={subsState.payment_plan_list}
          // rest_data={[{ aa: "aaa" }]}
        />
        {/* <CustomPagination curPage={curPage} doSearch={doSearch} totalCnt={totalPage} /> */}
      </div>
    </>
  );
};
export default PayPlanState;

class subsPayTable {
  table_title = "";
  btnStyleClass = btnStyles();
  cardCompList = getCardCompList();

  create_table = (data) => {
    console.log(data);
    return (
      <CustomRow style={{ width: "100%" }}>
        <CustomTableContents>{data.num}</CustomTableContents>
        <CustomTableContents>{data.membership_item_name}</CustomTableContents>
        <CustomTableContents>{data.membership_info}</CustomTableContents>
        <CustomTableContents>{data.paymentCompany}</CustomTableContents>
        <CustomTableContents>{data.payerName}</CustomTableContents>
        <CustomTableContents>{data.paymentNumber}</CustomTableContents>

        <CustomTableContents>{data.price}</CustomTableContents>
        <CustomTableContents>{data.pay_date}</CustomTableContents>

        <CustomTableContents>{data.paid_info.paymentDate}</CustomTableContents>

        <CustomTableContents>{data.paid_info.status}</CustomTableContents>
        <CustomTableContents>{data.callAmount}</CustomTableContents>
        <CustomTableContents>{data.status}</CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "회차", width: "5%" },
    { title: "회원권", width: "6%" },
    { title: "운동복", width: "6%" },
    { title: "GX", width: "6%" },
    { title: "락커1", width: "6%" },
    { title: "락커2", width: "6%" },
    { title: "합계", width: "6%" },
    { title: "결제 예정일", width: "12%" },
    { title: "최근 결제 신청 일시", width: "12%" },

    { title: "결제 상태", width: "8%" },
    { title: "비고", width: "8%" },
    { title: "결제 신청", width: "8%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
