///PAYMENT
export const paymentInfoPush = (type_key, content_obj, ordIdx, mbshpIdx) => {
  return {
    type: "COMMON/PAYMENT_INFO_LIST/PUSH",
    payload: {
      MembershipIdx: mbshpIdx,
      type_key: type_key,
      content_obj: content_obj,
      orderIdx: ordIdx,
    },
  };
};

export const paymentInfoPop = (type_key) => {
  return {
    type: "COMMON/PAYMENT_INFO_LIST/POP",
    type_key: type_key,
  };
};

export const paymentInfoAddedPaidClear = (data) => {
  return {
    type: "COMMON/PAYMENT_INFO_LIST/CLEAR_ADDPAID_ITEM",
    payload: data,
  };
};

export const paymentInfoClear = (data) => {
  return {
    type: "COMMON/PAYMENT_INFO_LIST/CLEAR",
    payload: data,
  };
};

export const paymentInfoEdit = (type_key, content_obj, ordIdx, mbshpIdx) => {
  return {
    type: "COMMON/PAYMENT_INFO_LIST/EDIT",
    payload: {
      MembershipIdx: mbshpIdx,
      type_key: type_key,
      content_obj: content_obj,
      orderIdx: ordIdx,
    },
  };
};
