import { generateObjectDummyId } from "components/CommonLib/CommonLib";


export const subs_method_state = {
  memberName: "",
  paymentKind: "",
  paymentCompany: "",
  paymentNumber: "",
  payerName: "",
};


export const subs_plan_state = {
  branch_info: {
    branch_id: generateObjectDummyId(),
    branch_name: "",
  },
  hq_info: {
    hq_id: generateObjectDummyId(),
    hq_name: "",
  },
  membership_info: {
    birth: "",
    birth_info: {
      day: 0,
      month: 0,
      year: 0,
    },
    from_date: "",
    gender: "",
    is_new: false,
    item_name: "",
    mbshp_option: "",
    mbshp_type: "",
    member_id: "",
    member_name: "",
    member_no: "",
    membership_id: "",
    phone: "",
    to_date: "",
  },

  contract_from_date: "",
  contract_to_date: "",
  auto_payment_cancel: false,
  subs_paid_num: 0,

  subs_paid_total_price: 0,
  pay_per_month: 0,
  next_pay_date: "",

  contract_month: 0,
  remain_month: 0,


  pay_try_date: "",
  remark: "",
  is_expired: false,





  purchase_list: [],
  payment_plan_list: [],
  reg_info: {
    created_at: "",
    reg_id: "",
    reg_name: "",
    upd_id: "",
    upd_name: "",
    updated_at: "",
  },
};
