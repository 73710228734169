import { takeLatest, takeEvery, all } from "redux-saga/effects";
import { setAuthInfo, authSagaInfo, AUTH_PRINT_STATE } from "./auth_sagas";
import { fork, call, put } from "redux-saga/effects";

import { lockerSettingSaga } from "pages/ERP/LockerService/Module/sagas";
import { erpSettingSaga } from "pages/Branch/ERPSetting/Module/sagas";
import { hqSettingSaga } from "pages/Branch/HQSetting/Module/sagas";
import { hqStaffPermissionSaga } from "pages/Branch/HQSetting/Module/sagas/staff_list";
import { branchSettingSaga } from "pages/Branch/BranchSetting/Module/sagas";
import { authPermissionSaga } from "permissions/Module/sagas";
import { memberServiceSaga } from "pages/ERP/MemberServiceRedux/sagas";
import { memberHistorySaga } from "pages/ERP/MemberView/MemberViewHistoryRedux/sagas";
import { searchModalSaga } from "pages/CommonModal/Search/Module/sagas";
import { staffServiceSaga } from "pages/Staff/StaffServiceRedux/sagas";
import { reduxSagaDepth2 } from "pages/ActionGroupRedux/sagas/index depth2";
import { approvalServiceSaga } from "pages/ApprovalManage/ServiceRedux/sagas/index";
import { accessServiceSaga } from "pages/AccessManage/ServiceRedux/sagas/index";

import {
  getCSTask,
  getCSList,
  getCSInfo,
  getCSFilterInfo,
  getCSDetailHealthInfo,
} from "./cs_sagas";

import { getTestStoresSaga } from "./loading_sagas";
import { getTestSaga } from "./test_sagas";

function* sagaOriginal() {
  yield takeLatest("LOAD_AUTH_INFO", setAuthInfo);
  yield takeLatest(AUTH_PRINT_STATE, authSagaInfo);

  //CS
  yield takeLatest("LOAD_TASK_INFO", getCSTask);
  yield takeLatest("LOAD_CS_LIST", getCSList);
  yield takeLatest("LOAD_CS_INFO", getCSInfo);
  yield takeLatest("LOAD_CS_FILTER_INFO", getCSFilterInfo);
  yield takeLatest("LOAD_CS_DETAIL_HEALTH_INFO", getCSDetailHealthInfo);

  //LOADING
  yield takeLatest("loading/GET_STORES", getTestStoresSaga);
  yield takeLatest("loading/TEST", getTestSaga);
}

function* rootSaga() {
  yield all([
    fork(sagaOriginal), // saga1 can also yield [ fork(actionOne), fork(actionTwo) ]
    fork(lockerSettingSaga),

    fork(erpSettingSaga),
    fork(hqSettingSaga),
    fork(branchSettingSaga),
    fork(authPermissionSaga),

    fork(memberServiceSaga),
    fork(searchModalSaga),
    fork(staffServiceSaga),
    fork(memberHistorySaga),

    fork(reduxSagaDepth2),

    fork(hqStaffPermissionSaga),

    fork(approvalServiceSaga),
    fork(accessServiceSaga),
  ]);
}

export default rootSaga;
