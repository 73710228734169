import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SearchCardTable from "./SearchCardTable/SearchCardTable";

const SearchCard = ({ modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [curPage, setCurPage] = useState(1);
  const [searchList, setSearchList] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [targetCardNo, setTargetCardNo] = useState("");

  const onClickSearch = (curPage) => {
    setLoading(true);
    HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/access/search_card_list", {
      params: {
        page_num: curPage,
        branch_id: modalParam.branch_id,
        card_no: Number(targetCardNo),
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setSearchList(res.data.data);
          setTotalPage(res.data.total_page);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickSearchStaff = (curPage) => {
    setLoading(true);
    HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/staff/search_card_list", {
      params: {
        page_num: curPage,
        card_no: targetCardNo,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setSearchList(res.data.data);
          setTotalPage(res.data.total_page);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickSearchEvent = (page) => {
    setCurPage(page);

    if (modalParam.is_staff === undefined) {
      onClickSearch(page);
    } else {
      onClickSearchStaff(page);
    }
  };

  // 페이지 진입 시 카드 검색
  useEffect(() => {
    onClickSearchEvent(curPage);
  }, []);

  return (
    <div
      style={{
        width: "25rem",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {loading && <Loading />}
      <div
        style={{
          width: "20rem",
        }}
      >
        <div style={{ display: "flex" }}>
          <TextField
            labelWidth={"3.75rem"}
            textBoxWidth={"10.5rem"}
            labelText="카드번호"
            isNumber={true}
            onChangeCallback={(e) => {
              setTargetCardNo(e);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onClickSearchEvent(curPage);
              }
            }}
          />
          <Button
            className={clsx({
              [btnStyleClass.btnRoot]: true,
              [btnStyleClass.buttonType2]: true,
            })}
            onClick={() => onClickSearchEvent(curPage)}
          >
            검색
          </Button>
        </div>

        <div style={{ marginTop: "1rem" }}>
          <SearchCardTable
            modalParam={modalParam}
            onModalDone={onModalDone}
            searchList={searchList}
            curPage={curPage}
            totalPage={totalPage}
            onClickSearchEvent={onClickSearchEvent}
          />
        </div>
      </div>
    </div>
  );
};
export default SearchCard;
