import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import {
  checkNumber,
  contentTypeJson,
  dataEmpty,
  restEmpty,
  setDefault,
  simpleAlert,
  SubLine,
} from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import ExchangeElementTable from "components/ExchangeElementTable/ExchangeElementTable";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import ModalBtnModeGroup from "components/ModalBtnModeGroup/ModalBtnModeGroup";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import TableTitleWithButton from "components/TableTitleWithButton/TableTitleWithButton";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import SetPermissionModal from "pages/CommonModal/Permission/SetPermissionModal";
import SearchEmployee from "pages/CommonModal/Search/SearchEmployee";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hqSettingAction, loadBranchInfo } from "../Module/actions";
import post_api from "../Module/api/PostApi";

const HQSetBranch = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const restBranchInfo = useSelector((state) => state.hq_set_state.hq_branchInfo_state);

  const btnStyleClass = btnStyles();
  const viewModelRef = useRef();
  let [branchName, setBranchName] = useState("");
  const branchOpenRef = useRef(setDefault(""));
  const branchPhoneRef = useRef(setDefault(""));

  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });

  const [managerList, setManagerList] = useState(restBranchInfo.stores.data.manager_list);
  const [showDuplLabel, setShowDuplLabel] = useState("");
  const [hqBranchList, setHqBranchList] = useState();
  const [srcPermission, setSrcPermission] = useState();
  const [loading, setLoading] = useState(false);

  const filterHqBranchList = (data) => {
    const filteredData = {
      branch_list: [[modalParam.branch_info.branch_name]],
      hq_list: [modalParam.hq_info.hq_name],
      target_list: [{ hq_info: modalParam.hq_info, branch_list: [modalParam.branch_info] }],
    };

    setHqBranchList(filteredData);
  };

  //useEffect 1
  useEffect(() => {
    // 검색용 본부 및 지점 리스트
    HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/staff/search_view", {
      params: { hq_only_add: true },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          filterHqBranchList(res.data.data);
        }
      })
      .catch((err) => {
        console.log("err");
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });

    getPermission();
  }, []);

  //useEffect 2
  useEffect(() => {
    if (modalParam.mode === "add") {
      storesDispatch(loadBranchInfo());
    } else if (modalParam.mode === "edit") {
      storesDispatch(loadBranchInfo(modalParam.branch_info.branch_id));
    }
    return () => {
      storesDispatch(hqSettingAction.loadBranchInfoReset());
    };
  }, [loadBranchInfo, storesDispatch]);

  //useEffect 3
  useEffect(() => {
    setManagerList([...restBranchInfo.stores.data.manager_list]);
    return () => {
      setManagerList([]);
    };
  }, [restBranchInfo.stores.data.manager_list]);

  // 로그인 유저의 권한을 가져옴
  const getPermission = () => {
    HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/hq/staff_permission_by_token", {
      params: {
        hq_id: modalParam.hq_info.hq_id,
        // hq_name: modalParam.hq_info.hq_name,
        branch_id: modalParam.branch_info.branch_id,
        // branch_name: modalParam.branch_info.branch_name,
      },
    })
      .then((res) => {
        if (res.data.is_admin) {
          setSrcPermission({ is_admin: true });
        }
        if (!restEmpty(res.data.data)) {
          setSrcPermission(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const checkBranchName = (val) => {
    branchName = "";

    if (val[val.length - 1] === "점") {
      simpleAlert(storesDispatch, "알림", "지점명에 '점'이 포함되었습니다.");
      return;
    }

    const trimStr = val.trim();
    if (trimStr !== val) {
      simpleAlert(storesDispatch, "알림", "지점명 처음과 끝에는 공백을 포함할 수 없습니다.");
      return;
    }

    HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/hq_set/branch/check_name", {
      params: {
        branch_name: val,
      },
    })
      .then((res) => {
        if (res.data.data === "N") {
          setShowDuplLabel("N");
          setBranchName(val);
        } else {
          setShowDuplLabel("Y");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validation = () => {
    let res = "";

    if (!branchName) {
      res += "· 지점명\n";
    }
    if (
      !branchPhoneRef.current.value ||
      branchPhoneRef.current.value.length < 9 ||
      !checkNumber(branchPhoneRef.current.value)
    ) {
      res += "· 전화번호\n";
    }
    if (!branchOpenRef.current.value) {
      res += "· 오픈일\n";
    }

    return res;
  };

  const onClickSave = () => {
    const validationRes = modalParam.mode === "add" ? validation() : "";
    if (validationRes !== "") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="필수값을 입력해 주시기 바랍니다." />
              <br />
              <br />
              <Label labelText={validationRes} whiteSpace="pre-wrap" />
            </>
          ),
          // width: "50rem",
          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="저장하시겠습니까?" />
            </>
          ),
          // width: "50rem",
          onBtnYes: () => {
            onSaveEvent();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const onClickAddManager = () => {
    setModalInfo({
      show: true,
      title: "직원 검색",
      Content: <SearchEmployee modalParam={hqBranchList} onModalDone={onAddManager} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const onAddManager = (recvData) => {
    if (-1 === managerList.findIndex((x) => x.staff_id === recvData.staff_id)) {
      // restBranchInfo.stores.data.manager_list.push(recvData);
      // setManagerList([...restBranchInfo.stores.data.manager_list]);
      setManagerList([
        ...managerList,
        {
          staff_id: recvData.staff_id,
          branch_name: recvData.base_info.branch_info.branch_name,
          name: recvData.personal_info.staff_name,
          position: recvData.base_info.position.org_name,
        },
      ]);
      viewModelRef.current.setClose();
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="이미 추가된 직원입니다." />,
          // width: "50rem",
          onBtnOk: () => {},
        }),
      );
    }
  };

  const onDeleteManager = (staffId) => {
    const idx = managerList.findIndex((x) => x.staff_id === staffId);
    if (-1 !== idx) {
      setManagerList(
        managerList.filter((x) => {
          return x.staff_id !== staffId;
        }),
      );
    }
  };

  const onClickPermission = (staff_id) => {
    const params = {
      hq_info: modalParam.hq_info,
      branch_info: modalParam.branch_info,
      staff_id: staff_id,
    };

    setModalInfo({
      show: true,
      title: "접근 권한 설정",
      Content: (
        <SetPermissionModal
          modalParam={{
            targetParams: params,
            srcPermission: srcPermission,
          }}
          onModalDone={() => {
            viewModelRef.current.setClose();
          }}
        />
      ),
      callback: null,
      Buttons: undefined,
    });
  };

  const onSaveEvent = async () => {
    const param = {
      assign_branch_list: restBranchInfo.stores.data.global_info.assign_branch_list,
      assign_branch_reject_list: restBranchInfo.stores.data.global_info.assign_branch_reject_list,
      hq_info: {
        hq_id: modalParam.hq_info.hq_id,
        hq_name: modalParam.hq_info.hq_name,
      },
      branch_info: {
        branch_id: modalParam.branch_info.branch_id,
        branch_name: branchName || modalParam.branch_info.branch_name,
      },
      branch_no: restBranchInfo.stores.data.branch_no,
      found_date: branchOpenRef.current.value || restBranchInfo.stores.data.found_date,
      call_number: branchPhoneRef.current.value || restBranchInfo.stores.data.call_number,
      manager_list: managerList,
    };

    // console.log(param);
    const formData = new FormData();
    formData.append("branch_info", JSON.stringify(param));
    const postApi =
      modalParam.mode === "add" ? post_api.newBranchApi() : post_api.updateBranchApi();

    setLoading(true);

    await HttpInstance.post(postApi, formData, contentTypeJson)
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          onModalDone(modalParam.refreshStore);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
        // onModalDone(modalParam.refreshStore);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const branchManagerList = new branchManagerTable(onClickPermission, onDeleteManager);
  const labelWidth = "4rem";

  return (
    <div>
      {loading && <Loading />}

      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
      <div
        style={{
          width: "50rem",
        }}
      >
        <div style={{ display: "flex" }}>
          <ModalBtnModeGroup mode={modalParam.mode} onClickEvent={onClickSave} />
        </div>
        <div style={{ display: "flex", marginTop: "0.5rem" }}>
          <TextField
            labelWidth={labelWidth}
            textBoxWidth={"12rem"}
            labelText="본부명"
            defaultValue={modalParam.hq_info.hq_name}
            disabled
          />
          <TextField
            required
            labelWidth={labelWidth}
            textBoxWidth={"12rem"}
            labelMarginLeft={"1.8rem"}
            labelText="지점명"
            defaultValue={modalParam.branch_info.branch_name}
            disabled={modalParam.mode === "edit"}
            onBlur={(e) => {
              checkBranchName(e.target.value);
            }}
          />
          <TextField
            labelWidth={labelWidth}
            textBoxWidth={"5rem"}
            labelMarginLeft={"1.8rem"}
            labelText="지점번호"
            disabled
            defaultValue={restBranchInfo.stores.data.branch_no}
            // onChange={
            //   (e) => { restBranchInfo.stores.data.branch_no = e.target.value; }
            // }
          />
        </div>
        {showDuplLabel === "Y" ? (
          <div>
            <Label
              labelText="중복된 지점명이 있습니다."
              color="#c73f4b"
              fontSize="0.9rem"
              marginLeft="22rem"
              justifyContent="left"
            />
          </div>
        ) : (
          <></>
        )}

        <div style={{ display: "flex", marginTop: "0.5rem" }}>
          <DatePicker
            required
            labelWidth={labelWidth}
            minWidth={"12rem"}
            labelText="오픈일"
            inputRef={branchOpenRef}
            defaultValue={restBranchInfo.stores.data.found_date || ""}
            // onChangeCallback={(e) => {
            //   restBranchInfo.stores.data.found_date = e;
            // }}
          />

          <TextField
            required
            isStringNumber
            labelWidth={labelWidth}
            textBoxWidth={"12rem"}
            labelMarginLeft={"1.8rem"}
            labelText="전화번호"
            inputRef={branchPhoneRef}
            defaultValue={restBranchInfo.stores.data.call_number || ""}
            onChangeCallback={() => {}}
            // disabled={modalParam.mode === "edit"}
          />
        </div>

        {modalParam.mode === "add" ? (
          <></>
        ) : (
          <div>
            <SubLine />
            <TableTitleWithButton
              titleLabel={"지점 관리자 목록"}
              buttonText={"추가"}
              onClickEvent={onClickAddManager}
            />

            <div style={{ marginTop: "0.5rem" }}>
              <CustomTable
                columns_head={branchManagerList.columns_head}
                table_title={branchManagerList.table_title}
                rest_call={branchManagerList.get_data_from_rest}
                row_render={branchManagerList.create_table}
                rest_data={managerList}
              />
            </div>
          </div>
        )}
        <SubLine />

        <div style={{}}>
          <ExchangeElementTable
            titleName={"지점변경 및 양도 가능 지점"}
            dataListLeft={restBranchInfo.stores.data.global_info.assign_branch_list}
            dataListRight={restBranchInfo.stores.data.global_info.assign_branch_reject_list}
            leftHead={"가능 지점"}
            rightHead={"불가 지점"}
          />
        </div>
      </div>
    </div>
  );
};
export default HQSetBranch;

class branchManagerTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onClickPermission = null;
  onClickDelete = null;

  constructor(onClickPermission, onClickDelete) {
    this.onClickPermission = onClickPermission;
    this.onClickDelete = onClickDelete;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow style={{ width: "100%" }} key={idx}>
        <CustomTableContents width="30%">{data.branch_name}</CustomTableContents>
        <CustomTableContents width="30%">{data.name}</CustomTableContents>
        <CustomTableContents width="20%">{data.position}</CustomTableContents>
        <CustomTableContentsEND align="center" width="20%">
          <div
            style={{
              display: "flex",
            }}
          >
            <Button
              className={clsx({
                [this.btnStyleClass.btnRoot]: true,
                [this.btnStyleClass.buttonType5]: true,
              })}
              onClick={() => this.onClickPermission(data.staff_id)}
            >
              권한 보기
            </Button>
            <Button
              style={{ marginLeft: "0.25rem" }}
              className={clsx({
                [this.btnStyleClass.btnRoot]: true,
                [this.btnStyleClass.buttonType2]: true,
              })}
              onClick={() => this.onClickDelete(data.staff_id)}
            >
              삭제
            </Button>
          </div>
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "소속", field: "depart" },
    { title: "직원명", field: "name" },
    { title: "직책", field: "position" },
    { title: "", field: "action" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
