import {
  changeComboValueToEmpty,
  checkDateOrder,
  generateObjectZeroId,
  getDefaultValue,
  getPermission,
  restEmpty,
} from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAccessCardManageStore } from "./modules/store/accessCardManageState";

const SearchCardRow1 = ({ searchParams, setSearchParams, resetFlag, onClickSearch }) => {
  const storesDispatch = useDispatch();
  // 페이지 상태 관리
  const [branchList, setBranchList] = useState({ branch_list: [], branch_name_list: [] });

  // 지점 리스트 가져오기
  const getBranchCombo = async () => {
    const result = await getPermission(storesDispatch, "ACCESS_MENU/MANAGE", true, true);
    if (!restEmpty(result)) {
      setBranchList(result);
      // 첫 검색 조건
      if (searchParams.branch_info.branch_id === generateObjectZeroId()) {
        setSearchParams("branch_info", result.branch_list[0]);
      }
    }
  };

  useEffect(() => {
    getBranchCombo();
  }, []);

  // 초기화 시 지점 첫번째 값 세팅
  useEffect(() => {
    if (branchList.branch_list[0]) {
      setSearchParams("branch_info", branchList.branch_list[0]);
    }
  }, [resetFlag]);

  return (
    <div
      style={{ display: "flex" }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onClickSearch(1);
        }
      }}
    >
      <DatePicker
        minWidth={"9.5rem"}
        labelText="카드 등록일"
        labelWidth={"6rem"}
        textMarginRight={"0.2rem"}
        defaultValue={getDefaultValue("field", searchParams.cur_reg_from_date)}
        onChangeCallback={(e) => {
          setSearchParams("cur_reg_from_date", e);
        }}
        onBlur={(e) => {
          if (!checkDateOrder(e, searchParams.cur_reg_to_date)) {
            setSearchParams("cur_reg_from_date", "");
          }
        }}
      />
      <DatePicker
        marginLeft={"0.5rem"}
        minWidth={"9.5rem"}
        labelText="~"
        labelWidth={"0.5rem"}
        defaultValue={getDefaultValue("field", searchParams.cur_reg_to_date)}
        onChangeCallback={(e) => {
          setSearchParams("cur_reg_to_date", e);
        }}
        onBlur={(e) => {
          if (!checkDateOrder(searchParams.cur_reg_from_date, e)) {
            setSearchParams("cur_reg_to_date", "");
          }
        }}
      />

      <ComboBox
        labelMarginLeft="2.5rem"
        labelWidth={"6rem"}
        comboItemWidth={"12rem"}
        labelText="카드 상태"
        data={["전체", "사용", "대기", "정지"]}
        defaultValue={getDefaultValue("combo", searchParams.card_status)}
        onChangeCallback={(e) => {
          changeComboValueToEmpty(setSearchParams, "card_status", e);
        }}
      />

      <SearchComboBox
        labelMarginLeft="2.5rem"
        labelWidth={"6rem"}
        comboItemWidth={"12rem"}
        labelText="지점"
        data={branchList.branch_name_list}
        defaultValue={getDefaultValue("combo", searchParams.branch_info.branch_name)}
        onChangeCallback={(e, v, idx) => {
          const branch = branchList.branch_list.find((el) => el.branch_name === v);
          setSearchParams("branch_info", branch);
        }}
      />
    </div>
  );
};

const SearchCardRow2 = ({ searchParams, setSearchParams, onClickSearch }) => {
  const labelWidth = "6rem";

  return (
    <div
      style={{ display: "flex", marginTop: "1rem" }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onClickSearch(1);
        }
      }}
    >
      <TextField
        labelWidth={labelWidth}
        textBoxWidth={"20.8rem"}
        labelText="시리얼번호"
        defaultValue={getDefaultValue("field", searchParams.card_serial_no)}
        onChangeCallback={(e) => {
          setSearchParams("card_serial_no", e);
        }}
      />

      <div style={{ display: "flex", marginLeft: "2.5rem" }}>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={"5.2rem"}
          textMarginRight={"0rem"}
          labelText="카드번호"
          isStringNumber
          defaultValue={getDefaultValue("field", searchParams.card_no_from)}
          onChangeCallback={(e) => {
            setSearchParams("card_no_from", e);
          }}
        />
        <TextField
          marginLeft={"0.5rem"}
          labelWidth={"0.5rem"}
          textBoxWidth={"5.2rem"}
          labelText="~"
          isStringNumber
          defaultValue={getDefaultValue("field", searchParams.card_no_to)}
          onChangeCallback={(e) => {
            setSearchParams("card_no_to", e);
          }}
        />
      </div>

      <ComboBox
        labelMarginLeft="2.5rem"
        labelWidth={labelWidth}
        comboItemWidth={"12rem"}
        labelText="사용자 그룹"
        data={["전체", "회원", "직원", "지점"]}
        defaultValue={getDefaultValue("combo", searchParams.user_group)}
        onChangeCallback={(e) => {
          changeComboValueToEmpty(setSearchParams, "user_group", e);
        }}
      />
    </div>
  );
};

const SearchCardRow3 = ({ searchParams, setSearchParams, onClickSearch }) => {
  const labelWidth = "6rem";

  return (
    <div style={{ display: "flex", marginTop: "1rem" }}>
      <div style={{ display: "flex" }}>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={"20.8rem"}
          labelText="사용자명"
          defaultValue={getDefaultValue("field", searchParams.cur_user.user_name)}
          onChangeCallback={(e) => {
            const curUser = {
              ...searchParams.cur_user,
              user_name: e,
            };
            setSearchParams("cur_user", curUser);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClickSearch(1);
            }
          }}
        />
      </div>
    </div>
  );
};

const CardSearchView = ({ onClickSearch }) => {
  // 전역 상태 관리
  const {
    accessCardManageSearchParams: searchParams,
    setSearchParams,
    accessCardManageResetFlag: resetFlag,
  } = useAccessCardManageStore();

  return (
    <div>
      <SearchCardRow1
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        resetFlag={resetFlag}
        onClickSearch={onClickSearch}
      />
      <SearchCardRow2
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        onClickSearch={onClickSearch}
      />
      <SearchCardRow3
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        onClickSearch={onClickSearch}
      />
    </div>
  );
};
export default CardSearchView;
