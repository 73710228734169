import { generateObjectZeroId } from "components/CommonLib/CommonLib";
import { create } from "zustand";

// 출입 이력 검색 조건 베이스
const baseAccessHistSearchParams = {
  access_from_date: "",
  access_from_time: "",
  access_to_date: "",
  access_to_time: "",
  branch_info: {
    branch_id: generateObjectZeroId(),
    branch_name: "",
  },
  card_no: "",
  card_serial_no: "",
  cur_user: {
    member_no: "",
    sabun: "",
    user_id: "",
    user_name: "",
  },
  door_info: {
    door_id: "",
    door_name: "",
  },
  hq_info: {
    hq_id: generateObjectZeroId(),
    hq_name: "",
  },
  user_group: "",
};

// 출입 이력 검색 조건 베이스
const baseDoorHistSearchParams = {
  access_from_date: "",
  access_from_time: "",
  access_to_date: "",
  access_to_time: "",
  branch_info: {
    branch_id: generateObjectZeroId(),
    branch_name: "",
  },
  cur_user: {
    member_no: "",
    sabun: "",
    user_id: "",
    user_name: "",
  },
  door_info: {
    auto_lock_timeout: 0,
    door_id: "",
    door_name: "",
    door_open_time: "",
  },
  hq_info: {
    hq_id: generateObjectZeroId(),
    hq_name: "",
  },
};

export const useAccessMonitoringStore = create((set, get) => ({
  returnStoreByType: (type) => {
    const {
      accessMonitoringLoading,
      setAccessMonitoringLoading,
      setHqBranchInfo,
      // 출입 이력
      accessHistSearchParams,
      setAccessHistSearchParams,
      resetAccessHistSearchParams,
      accessHistHqIdx,
      setAccessHistHqIdx,
      accessHistBranchIdx,
      setAccessHistBranchIdx,
      accessHistPage,
      setAccessHistPage,
      // 문열림 기능 사용 이력
      doorHistSearchParams,
      setDoorHistSearchParams,
      resetDoorHistSearchParams,
      doorHistHqIdx,
      setDoorHistHqIdx,
      doorHistBranchIdx,
      setDoorHistBranchIdx,
      doorHistPage,
      setDoorHistPage,
    } = get();

    const accessHistLib = {
      setHqBranchInfo,
      loading: accessMonitoringLoading,
      setLoading: setAccessMonitoringLoading,
      searchParams: accessHistSearchParams,
      setSearchParams: setAccessHistSearchParams,
      resetSearchParams: resetAccessHistSearchParams,
      hqIdx: accessHistHqIdx,
      setHqIdx: setAccessHistHqIdx,
      branchIdx: accessHistBranchIdx,
      setBranchIdx: setAccessHistBranchIdx,
      page: accessHistPage,
      setPage: setAccessHistPage,
    };
    const doorHistLib = {
      setHqBranchInfo,
      loading: accessMonitoringLoading,
      setLoading: setAccessMonitoringLoading,
      searchParams: doorHistSearchParams,
      setSearchParams: setDoorHistSearchParams,
      resetSearchParams: resetDoorHistSearchParams,
      hqIdx: doorHistHqIdx,
      setHqIdx: setDoorHistHqIdx,
      branchIdx: doorHistBranchIdx,
      setBranchIdx: setDoorHistBranchIdx,
      page: doorHistPage,
      setPage: setDoorHistPage,
    };

    switch (type) {
      case "accessHist":
        return accessHistLib;
      case "doorHist":
        return doorHistLib;
      default:
        break;
    }
  },

  // ========== State ===========
  // ComboBox 첫번째 본부 및 지점 및 출입문 정보 State (조건 초기화 시 선택 위함)
  firstHqBranchInfoData: {
    hq_info: {
      hq_id: generateObjectZeroId(),
      hq_name: "",
    },
    branch_info: {
      branch_id: generateObjectZeroId(),
      branch_name: "",
    },
    door_info: {
      auto_lock_timeout: 0,
      door_id: "",
      door_name: "",
      door_open_time: "",
    },
  },

  // 출입 이력, 문열림 기능 Loading State
  accessMonitoringLoading: false,

  // 출입 이력 검색 State
  accessHistSearchParams: {
    ...baseAccessHistSearchParams,
  },
  // 문열림 기능 사용 이력 검색 State
  doorHistSearchParams: {
    ...baseDoorHistSearchParams,
  },

  // 본부 선택 인덱스
  accessHistHqIdx: 0,
  doorHistHqIdx: 0,

  // 지점 선택 인덱스
  accessHistBranchIdx: 0,
  doorHistBranchIdx: 0,

  // 선택 페이지
  accessHistPage: 1,
  doorHistPage: 1,

  // ========== Action ==========
  // Loading state 값 변경
  setAccessMonitoringLoading: (bool) => {
    set({ accessMonitoringLoading: bool });
  },

  // 출입 이력 검색 조건 변경
  setAccessHistSearchParams: (key, value) => {
    set((state) => ({
      accessHistSearchParams: {
        ...state.accessHistSearchParams,
        [key]: value,
      },
    }));
  },
  // 문열림 기능 사용 이력 검색 조건 변경
  setDoorHistSearchParams: (key, value) => {
    set((state) => ({
      doorHistSearchParams: {
        ...state.doorHistSearchParams,
        [key]: value,
      },
    }));
  },

  // Hq Index 값 변경
  setAccessHistHqIdx: (value) => {
    set({ accessHistHqIdx: value });
  },
  setDoorHistHqIdx: (value) => {
    set({ doorHistHqIdx: value });
  },
  // Branch Index 값 변경
  setAccessHistBranchIdx: (value) => {
    set({ accessHistBranchIdx: value });
  },
  setDoorHistBranchIdx: (value) => {
    set({ doorHistBranchIdx: value });
  },

  // Page 값 변경
  setAccessHistPage: (value) => {
    set({ accessHistPage: value });
  },
  setDoorHistPage: (value) => {
    set({ doorHistPage: value });
  },

  // ComboBox 첫번째 본부, 지점 정보 변경
  setHqBranchInfo: (key, value) => {
    set((state) => ({
      firstHqBranchInfoData: {
        ...state.firstHqBranchInfoData,
        [key]: value,
      },
    }));
  },

  // 검색 조건 리셋
  resetAccessHistSearchParams: () => {
    set((state) => ({
      accessHistSearchParams: {
        ...baseAccessHistSearchParams,
        ...state.firstHqBranchInfoData,
      },
    }));
    set({ accessHistHqIdx: 0 });
    set({ accessHistBranchIdx: 0 });
  },

  resetDoorHistSearchParams: () => {
    set((state) => ({
      doorHistSearchParams: {
        ...baseDoorHistSearchParams,
        ...state.firstHqBranchInfoData,
      },
    }));
    set({ doorHistHqIdx: 0 });
    set({ doorHistBranchIdx: 0 });
  },

  // 모든 검색 조건 리셋
  resetAllSearchParams: () => {
    const { resetAccessHistSearchParams, resetDoorHistSearchParams } = get();

    resetAccessHistSearchParams();
    resetDoorHistSearchParams();
  },
}));
