import { simpleAlert } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";
import { utils, writeFile } from "xlsx";

export const onClickExcel = async (storesDispatch, setLoading, salaryReportId, fileName) => {
  try {
    setLoading(true);

    HttpInstance.defaults.timeout = 0;
    const res = await HttpInstance.get(
      process.env.REACT_APP_SALARY_SERVER + "/salary/overall_list",
      {
        params: {
          salary_report_id: salaryReportId,
          is_excel: true,
          is_edit: false,
        },
      },
    );
    if (res.data.msg) {
      simpleAlert(storesDispatch, "ERROR", res.data.msg);
    } else {
      //성공
      console.log("res", res.data.data);
      exportToExcel(res.data.data, fileName);
    }
  } catch (err) {
    console.log(err);
    setLoading(false);
    simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
  } finally {
    setLoading(false);
  }
};

//엑셀파일 만들기
const exportToExcel = (data, fileName) => {
  const newData = [];
  for (let i = 0; i < data.length; i++) {
    // console.log(data[i]);
    for (let j = 0; j < data[i].salary_data_list.length; j++) {
      const row = {};
      row.사번 = data[i].personal_info.sabun;
      row.직원명 = data[i].personal_info.staff_name;
      row.직책 = data[i].staff_info.staff_pos;

      row.주민번호 = data[i].personal_info.social_number;
      row.은행 = data[i].personal_info.bank;
      row.계좌번호 = data[i].personal_info.account_no;
      row.예금주 = data[i].personal_info.account_holder;
      row.확인여부 = data[i].performance_info.is_staff_check_up;

      row.급여종류 = data[i].salary_data_list[j].salary_type;
      row.급여일 = data[i].salary_data_list[j].salary_info_data.salary_date;
      row.지급액 = data[i].salary_data_list[j].salary_info_data.salary;
      row.차감금액 = data[i].salary_data_list[j].salary_info_data.salary_deduct;
      row.차감사유 = data[i].salary_data_list[j].salary_info_data.deduct_reason;
      row.최종지급액 = data[i].salary_data_list[j].salary_info_data.final_salary;

      // 3.3% 세후 금액
      const afterTax = data[i].salary_data_list[j].salary_info_data.final_salary * 0.033;
      row.세후금액 = data[i].salary_data_list[j].salary_info_data.final_salary - afterTax;

      newData.push(row);
    }
  }

  const worksheet = utils.json_to_sheet(newData);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, "Sheet1");
  writeFile(workbook, fileName + "급여 현황.xlsx");
};
