import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import CashBookListView from "./CashBookListView";

const CashBookListService = () => {
  return (
    <div>
      <PageFrame
        menuActive="수입/지출 내역"
        parentMenu={{ 락커: true }}
        children={
          <div>
            <PageTitle titleText="수입/지출 내역" />
            <CashBookListView />
          </div>
        }
      />
    </div>
  );
};
export default CashBookListService;
