import { useState } from "react";

import TextField from "components/LabelInput/TextField";

import IconButton from "components/Button/IconButton";
import { contentTypeJson, simpleAlert, SubLine } from "components/CommonLib/CommonLib";
import FileUpload from "components/LabelInput/FileUpload";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import { useDispatch } from "react-redux";

const AddFamilyModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const [docList, setDocList] = useState([]);
  const [loading, setLoading] = useState(false);

  const labelWidth = "3.5rem";
  const compWidth = "8.5rem";

  const validationCheck = () => {
    if (docList) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="가족관계 증명서는 필수첨부 입니다." />
            </>
          ),

          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="저장 하시겠습니까?" />
            </>
          ),

          onBtnYes: () => {
            addFamily();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const addFamily = async () => {
    try {
      const newInfo = {
        //기준회원
        member_id: modalParam.memberId,
        branch_info: modalParam.data.branch_info,
        //등록될 가족회원정보
        family_member_info: { ...modalParam.data },
      };

      const formData = new FormData();
      formData.append("new_info", JSON.stringify(newInfo));
      //가족관계 증명서는 필수

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_USER_SERVER + "/member/family/add",
        formData,
        contentTypeJson,
      );

      if (res.error) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공 => 가족 리스트 갱신필요
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label labelText="저장되었습니다." />
              </>
            ),

            onBtnOk: () => {
              //성공
              storesDispatch(globalModalOff()); //모달 다 닫기
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        width: "43rem",
      }}
    >
      {loading && <Loading />}

      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="가족 회원 정보" />
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          labelText="지점"
          disabled
          defaultValue={modalParam.data.branch_info.branch_name}
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          marginLeft="1.5rem"
          labelText="회원구분"
          defaultValue={modalParam.data.member_type}
          disabled
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          marginLeft="1.5rem"
          labelText="카드번호"
          defaultValue={modalParam.data.access.card_no}
          disabled
        />
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "0.5rem",
        }}
      >
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          labelText="이름"
          disabled
          defaultValue={modalParam.data.member_info.member_name}
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          marginLeft="1.5rem"
          labelText="연락처"
          defaultValue={modalParam.data.base_info.phone}
          disabled
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          marginLeft="1.5rem"
          labelText="회원번호"
          defaultValue={modalParam.data.member_info.member_no}
          disabled
        />
      </div>

      <SubLine />
      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="증명 서류 첨부" />
      </div>
      <div style={{ display: "flex" }}>
        <FileUpload
          labelWidth={"9rem"}
          textBoxWidth={"11rem"}
          textMarginRight={"0rem"}
          labelText={"가족관계 증명서"}
          onUploadFile={(file) => {
            // setDocInfo((cur) => {
            //   return { ...cur, doc_family: file };
            // });
          }}
          hideDownload
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <FileUpload
          labelWidth={"9rem"}
          textBoxWidth={"11rem"}
          textMarginRight={"0rem"}
          labelText={"기타 증명서1"}
          onUploadFile={(file) => {
            // setDocInfo((cur) => {
            //   return { ...cur, doc_etc1: file };
            // });
          }}
          hideDownload
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <FileUpload
          labelWidth={"9rem"}
          textBoxWidth={"11rem"}
          textMarginRight={"0rem"}
          labelText={"기타 증명서2"}
          onUploadFile={(file) => {
            // setDocInfo((cur) => {
            //   return { ...cur, doc_etc2: file };
            // });
          }}
          hideDownload
        />
      </div>

      <div
        style={{ marginTop: "1.75rem", display: "flex", justifyContent: "center", gap: "0.3rem" }}
      >
        <NormalBtn
          name="이전"
          theme="gray"
          onClick={() => {
            onModalDone();
          }}
        />
        <NormalBtn name="저장" onClick={validationCheck} />
      </div>
    </div>
  );
};
export default AddFamilyModal;
