import { dataEmpty } from "components/CommonLib/CommonLib";
import { globalModalOn } from "modules/actions/Modal";
import SalaryInfoModalIndex from "pages/Staff/SalaryInfo/Modal/SalaryInfoModalIndex";
import SalaryInfoView from "pages/Staff/SalaryInfo/SalaryInfoView";
import { useDispatch } from "react-redux";

const SalaryReportForm = ({ mode, salaryReportId }) => {
  const storesDispatch = useDispatch();

  const openModal = (select, param, doneCallback) => {
    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,
        Content: (
          <SalaryInfoModalIndex
            modalSelect={select}
            modalParam={param}
            onModalDone={doneCallback}
          />
        ),
      }),
    );
  };

  const onClickOpenModal = (order, param, doneCallback) => {
    if (order !== "") {
      if (dataEmpty(doneCallback)) {
        openModal(order, param, onModalDone);
      } else {
        openModal(order, param, doneCallback);
      }
    }
  };

  const onModalDone = (ret_info) => {
    console.log(ret_info);
  };

  return (
    <div>
      <SalaryInfoView
        onClickOpenModal={onClickOpenModal}
        salaryReportId={salaryReportId}
        is_approval={true}
      />
    </div>
  );
};
export default SalaryReportForm;
