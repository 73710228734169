import { SubLine } from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import Label from "components/LabelInput/Label";
import NumberTextField from "components/LabelInput/NumberTextField";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const SideItemSetting = ({
  branchPolicy,
  restData,
  mbshpOption,
  period,
  preMonth,
  isEditMode,
  totalPrice,
  setTotalPrice,
  totalSubsPrice,
  setTotalSubsPrice,
  combine,
  setCombine,
}) => {
  const textLabelWidth = "5rem";
  const textLabelWidthShort = "4rem";
  const textBoxWidth = "9rem";
  const labelMarginLeft = "4rem";

  const storesDispatch = useDispatch();

  const [ptPrice, setPtPrice] = useState(restData.pt_price);
  const [ptCombine, setPtCombine] = useState(restData.is_combine_pt);

  const calcPtPrice = () => {
    const pt_total = restData.pt_session * restData.pt_per_price;
    restData.pt_price = pt_total;
    setPtPrice(pt_total);
  };

  const calcTotalPrice = (name) => {
    const isPreMonth = preMonth > 0 ? 1 : 0;
    let val;
    switch (name) {
      case "cloth":
        val =
          restData.subs_options.cloth_subs_price * (period - preMonth) +
          restData.subs_options.cloth_pre_price * isPreMonth;
        restData.cloth_price = val;
        break;
      case "gx":
        val =
          restData.subs_options.gx_subs_price * (period - preMonth) +
          restData.subs_options.gx_pre_price * isPreMonth;
        restData.gx_price = val;
        break;
      case "locker":
        val =
          restData.subs_options.locker_subs_price * (period - preMonth) +
          restData.subs_options.locker_pre_price * isPreMonth;
        restData.locker_price = val;
        break;
      default:
        break;
    }

    setTotalPrice({
      ...totalPrice,
      [name]: val,
    });
  };

  useEffect(() => {
    if (!isEditMode) {
      // 추가모드일 때만 기본값 로딩
      restData.subs_options.reg_subs_price = branchPolicy.base_policy.subs_reg;
      restData.subs_options.cloth_subs_price = branchPolicy.base_policy.subs_cloth;
      restData.subs_options.gx_subs_price = branchPolicy.base_policy.subs_gx;
      restData.subs_options.locker_subs_price = branchPolicy.base_policy.subs_locker;

      restData.subs_options.subs_reg = branchPolicy.base_policy.subs_reg * preMonth;
      restData.subs_options.cloth_pre_price = branchPolicy.base_policy.subs_cloth * preMonth;
      restData.subs_options.gx_pre_price = branchPolicy.base_policy.subs_gx * preMonth;
      restData.subs_options.locker_pre_price = branchPolicy.base_policy.subs_locker * preMonth;
      restData.pt_per_price = branchPolicy.base_policy.pt;
    }
    calcTotalPrice("cloth");
    calcTotalPrice("gx");
    calcTotalPrice("locker");
  }, [period, preMonth]);

  return (
    <div style={{}}>
      <div style={{ display: "flex", marginTop: "1.25rem", marginBottom: "0.625rem" }}>
        <Label labelText={"부가상품"} />
      </div>
      <div style={{ marginLeft: "2rem" }}>
        <div
          style={{
            display: "flex",
          }}
        >
          <Label labelText="· 운동복" width="6rem" justifyContent="left" />
          <NumberTextField
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· 1개월 정가"
            endAdornment="원"
            defaultValue={branchPolicy.base_policy.subs_cloth}
            disabled
          />

          <NumberTextField
            required
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· 월 구독료"
            endAdornment="원"
            defaultValue={
              restData.subs_options.cloth_subs_price || branchPolicy.base_policy.subs_cloth
            }
            onChangeCallback={(e) => {
              restData.subs_options.cloth_subs_price = e;
            }}
            onBlur={(e) => {
              calcTotalPrice("cloth");
              setTotalSubsPrice({
                ...totalSubsPrice,
                cloth: Number(e.target.value),
              });
            }}
            disabled={isEditMode}
          />
        </div>
        <div
          style={{
            display: "flex",
            marginLeft: "6rem",
            marginTop: "0.5rem",
          }}
        >
          <NumberTextField
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· 선납금 정가"
            endAdornment="원"
            defaultValue={branchPolicy.base_policy.subs_cloth * preMonth}
            disabled
          />

          <NumberTextField
            required
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· 판매가"
            endAdornment="원"
            defaultValue={
              restData.subs_options.cloth_pre_price ||
              branchPolicy.base_policy.subs_cloth * preMonth
            }
            onChangeCallback={(e) => {
              restData.subs_options.cloth_pre_price = e;
            }}
            onBlur={() => calcTotalPrice("cloth")}
            disabled={isEditMode}
          />

          <NumberTextField
            labelWidth={"7.5rem"}
            textBoxWidth={textBoxWidth}
            labelText="· 운동복 판매가 합계"
            endAdornment="원"
            defaultValue={restData.cloth_price}
            disabled
          />
        </div>

        <div
          style={{
            display: "flex",
            marginTop: "0.5rem",
          }}
        >
          <Label labelText="· GX" width="6rem" justifyContent="left" />
          <NumberTextField
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· 1개월 정가"
            defaultValue={branchPolicy.base_policy.subs_gx}
            endAdornment="원"
            disabled
          />

          <NumberTextField
            required
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· 월 구독료"
            endAdornment="원"
            defaultValue={restData.subs_options.gx_subs_price || branchPolicy.base_policy.subs_gx}
            onChangeCallback={(e) => {
              restData.subs_options.gx_subs_price = e;
            }}
            onBlur={(e) => {
              calcTotalPrice("gx");
              setTotalSubsPrice({
                ...totalSubsPrice,
                gx: Number(e.target.value),
              });
            }}
            disabled={isEditMode}
          />
        </div>
        <div
          style={{
            display: "flex",
            marginLeft: "6rem",
            marginTop: "0.5rem",
          }}
        >
          <NumberTextField
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· 선납금 정가"
            endAdornment="원"
            defaultValue={branchPolicy.base_policy.subs_gx * preMonth}
            disabled
          />

          <NumberTextField
            required
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· 판매가"
            endAdornment="원"
            defaultValue={
              restData.subs_options.gx_pre_price || branchPolicy.base_policy.subs_gx * preMonth
            }
            onChangeCallback={(e) => {
              restData.subs_options.gx_pre_price = e;
            }}
            onBlur={() => calcTotalPrice("gx")}
            disabled={isEditMode}
          />

          <NumberTextField
            labelWidth={"7.5rem"}
            textBoxWidth={textBoxWidth}
            labelText="· GX 판매가 합계"
            endAdornment="원"
            defaultValue={restData.gx_price}
            // onChangeCallback={(e) => {
            //   restData.subs_options.mbshp_pre_price = e;
            // }}
            disabled
          />
        </div>

        <div
          style={{
            display: "flex",
            marginTop: "0.5rem",
          }}
        >
          <Label labelText="· 락커" width="6rem" justifyContent="left" />
          <NumberTextField
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· 1개월 정가"
            endAdornment="원"
            defaultValue={branchPolicy.base_policy.subs_locker}
            disabled
          />

          <NumberTextField
            required
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· 월 구독료"
            endAdornment="원"
            defaultValue={
              restData.subs_options.locker_subs_price || branchPolicy.base_policy.subs_locker
            }
            onChangeCallback={(e) => {
              restData.subs_options.locker_subs_price = e;
            }}
            onBlur={(e) => {
              calcTotalPrice("locker");
              setTotalSubsPrice({
                ...totalSubsPrice,
                locker: Number(e.target.value),
              });
            }}
            disabled={isEditMode}
          />
        </div>
        <div
          style={{
            display: "flex",
            marginLeft: "6rem",
            marginTop: "0.5rem",
          }}
        >
          <NumberTextField
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· 선납금 정가"
            endAdornment="원"
            defaultValue={branchPolicy.base_policy.subs_locker * preMonth}
            disabled
          />

          <NumberTextField
            required
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· 판매가"
            endAdornment="원"
            defaultValue={
              restData.subs_options.locker_pre_price ||
              branchPolicy.base_policy.subs_locker * preMonth
            }
            onChangeCallback={(e) => {
              restData.subs_options.locker_pre_price = e;
            }}
            onBlur={() => calcTotalPrice("locker")}
            disabled={isEditMode}
          />

          <NumberTextField
            labelWidth={"7.5rem"}
            textBoxWidth={textBoxWidth}
            labelText="· 락커 판매가 합계"
            endAdornment="원"
            defaultValue={restData.locker_price}
            // onChangeCallback={(e) => {
            //   restData.subs_options.mbshp_pre_price = e;
            // }}
            disabled
          />
        </div>
      </div>
      <SubLine />

      <div style={{ display: "flex", marginTop: "1.25rem" }}>
        <Label labelText={"결합상품 선택"} />
      </div>
      <div style={{ marginLeft: "2rem" }}>
        <div
          style={{
            display: "flex",
          }}
        >
          <CheckBox
            //   inputRef={refStatus.pt_required}
            labelText="운동복"
            defaultValue={restData.is_combine_cloth}
            onChangeCallback={(e) => {
              if (!restData.cloth_price) {
                storesDispatch(
                  globalAlertOn({
                    show: true,
                    titleText: "알림",
                    bodyText: (
                      <>
                        <Label labelText="운동복 금액을 먼저 입력해야 합니다." />
                      </>
                    ),
                    // width: "50rem",
                    onBtnOk: () => {},
                  }),
                );
                e.checked = false;
              } else {
                restData.is_combine_cloth = e.checked;
                setCombine({
                  ...combine,
                  cloth: e.checked,
                });
              }
            }}
            disabled={isEditMode}
          />
          <CheckBox
            //   inputRef={refStatus.pt_required}
            labelText="GX"
            defaultValue={restData.is_combine_gx}
            onChangeCallback={(e) => {
              if (!restData.gx_price) {
                storesDispatch(
                  globalAlertOn({
                    show: true,
                    titleText: "알림",
                    bodyText: (
                      <>
                        <Label labelText="GX 금액을 먼저 입력해야 합니다." />
                      </>
                    ),
                    // width: "50rem",
                    onBtnOk: () => {},
                  }),
                );
                e.checked = false;
              } else {
                restData.is_combine_gx = e.checked;
                setCombine({
                  ...combine,
                  gx: e.checked,
                });
              }
            }}
            disabled={isEditMode}
          />
          <CheckBox
            //   inputRef={refStatus.pt_required}
            labelText="락커"
            defaultValue={restData.is_combine_locker}
            onChangeCallback={(e) => {
              if (!restData.locker_price) {
                storesDispatch(
                  globalAlertOn({
                    show: true,
                    titleText: "알림",
                    bodyText: (
                      <>
                        <Label labelText="락커 금액을 먼저 입력해야 합니다." />
                      </>
                    ),
                    // width: "50rem",
                    onBtnOk: () => {},
                  }),
                );
                e.checked = false;
              } else {
                restData.is_combine_locker = e.checked;
                setCombine({
                  ...combine,
                  locker: e.checked,
                });
              }
            }}
            disabled={isEditMode}
          />
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          <CheckBox
            //   inputRef={refStatus.pt_required}
            labelText="PT"
            defaultValue={restData.is_combine_pt}
            onChangeCallback={(e) => {
              restData.is_combine_pt = e.checked;
              setPtCombine(e.checked);
            }}
            disabled={isEditMode}
          />

          <div style={{ display: "flex", marginTop: "0.625rem" }}>
            <NumberTextField
              required
              labelWidth="4rem"
              textBoxWidth="6rem"
              labelText="· 세션"
              endAdornment="회"
              defaultValue={restData.pt_session}
              onChangeCallback={(e) => {
                restData.pt_session = e;
                calcPtPrice();
              }}
              disabled={!ptCombine || isEditMode}
            />
          </div>
        </div>
        <div>
          {/* pt 금액 line */}
          <div style={{ display: "flex", marginTop: "0.625rem", marginLeft: "6.125rem" }}>
            <Label labelText={"· PT 금액"} marginRight={"2rem"} />
            <NumberTextField
              labelWidth={"2.5rem"}
              textBoxWidth={"8rem"}
              labelText="정가"
              endAdornment="원"
              defaultValue={branchPolicy.base_policy.pt}
              disabled
            />

            <NumberTextField
              required
              labelWidth={"2.5rem"}
              textBoxWidth={"8rem"}
              labelMarginLeft={"2rem"}
              labelText="회당"
              endAdornment="원"
              defaultValue={isEditMode ? restData.pt_per_price : branchPolicy.base_policy.pt}
              onChangeCallback={(e) => {
                restData.pt_per_price = e;
                calcPtPrice();
              }}
              disabled={!ptCombine || isEditMode}
            />

            <NumberTextField
              labelWidth={"3.5rem"}
              textBoxWidth={"8rem"}
              labelMarginLeft={"2rem"}
              labelText="판매가"
              endAdornment="원"
              defaultValue={ptPrice}
              disabled
            />
          </div>
        </div>
      </div>
      <SubLine />
    </div>
  );
};
export default SideItemSetting;
