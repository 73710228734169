import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { useLocation } from "react-router";
import AssignWrapper from "./AssignWrapper";

const AssignService = () => {
  const { state } = useLocation();

  return (
    <PageFrame
      menuActive="설정"
      parentMenu={{ index: true }}
      children={
        <div>
          <PageTitle titleText="양도" />
          <AssignWrapper
            assigner={state.assigner}
            assignee={state.assignee}
            removePhoneMemberId={state.removePhoneMemberId}
          />
        </div>
      }
    />
  );
};
export default AssignService;
