import Label from "components/LabelInput/Label";
import MonthSchedule from "./MonthSchedule";

const MonthCalendar = ({
  searchParam,
  searchResult,
  setSearchResult,
  onClickOpenModal,
  refreshCalendar,
  month,
}) => {
  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(7,1fr)",
          textAlign: "center",
          marginTop: "2rem",
          marginBottom: "0.7rem",
          fontWeight: "bold",
          color: "#222222",
        }}
      >
        <Label labelText="일" color="red" />
        <Label labelText="월" />
        <Label labelText="화" />
        <Label labelText="수" />
        <Label labelText="목" />
        <Label labelText="금" />
        <Label labelText="토" />
      </div>
      <MonthSchedule
        searchParam={searchParam}
        gridItem={searchResult.grid_item}
        setSearchResult={setSearchResult}
        onClickOpenModal={onClickOpenModal}
        refreshCalendar={refreshCalendar}
        month={month}
      />
    </>
  );
};
export default MonthCalendar;
