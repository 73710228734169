import * as ActionTypes from "../actions/action_type";

const initialStete = {
  stores: {
    data: [],
  },
};
export const access_device_list = (state = initialStete, action) => {
  switch (action.type) {
    case ActionTypes.DeviceListSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.DeviceListFailAction:
    case ActionTypes.DeviceListResetAction:
      return { ...state, stores: initialStete.stores };
    default:
      return state;
  }
};

export const access_device_schedule_list = (state = initialStete, action) => {
  switch (action.type) {
    case ActionTypes.DeviceScheduleListSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.DeviceScheduleListFailAction:
    case ActionTypes.DeviceScheduleListResetAction:
      return { ...state, stores: initialStete.stores };
    default:
      return state;
  }
};

export const access_schedule_preset_info = (state = initialStete, action) => {
  switch (action.type) {
    case ActionTypes.SchedulePreSetInfoSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.SchedulePreSetInfoFailAction:
    case ActionTypes.SchedulePreSetInfoResetAction:
      return { ...state, stores: initialStete.stores };
    default:
      return state;
  }
};

export const access_holyday_info = (state = initialStete, action) => {
  switch (action.type) {
    case ActionTypes.HolyDayInfoSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.HolyDayInfoFailAction:
    case ActionTypes.HolyDayInfoResetAction:
      return { ...state, stores: initialStete.stores };
    default:
      return state;
  }
};
