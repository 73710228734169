import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import NumberTextField from "components/LabelInput/NumberTextField";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import _ from "lodash";
import { getIsViewLock } from "pages/ApprovalManage/ApprovalCommon/checker";
import { expenditure_info } from "pages/ApprovalManage/Module/reducers/approval_content_state";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

const ConsultForm = ({ mode, contentState, setContentState, onClickLedger, accountList }) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();

  const readOnly =
    mode === "approver" || mode === "unspent" || mode === "view" || mode === "ledger";

  const [, updateState] = useState(); // 강제 렌더링용
  const forceUpdate = useCallback(() => updateState({}), []);

  const onClickAddRow = () => {
    const clone = _.cloneDeep(contentState.item_list);
    clone.push(_.cloneDeep(expenditure_info));
    setContentState({
      ...contentState,
      item_list: clone,
    });
    // forceUpdate();
  };

  const onClickDelete = (idx) => {
    const clone = _.cloneDeep(contentState.item_list);
    clone.splice(idx, 1);

    setContentState({
      ...contentState,
      item_list: clone,
    });
    forceUpdate();
  };

  const getBoxSize = () => {
    let boxSize = {
      width: "100rem",
      height: "35rem",
    };
    if (mode === "unspent" || mode === "ledger") {
      return {
        width: "100%",
        height: "100%",
      };
    } else {
      return boxSize;
    }
  };

  const contentList = new contentTable(mode, readOnly, onClickDelete, onClickLedger, accountList);

  if (mode === "unspent") {
    return (
      <div style={{ margin: "0.5rem 0.1rem" }}>
        <CustomTable
          columns_head={contentList.columns_head_unspent}
          table_title={contentList.table_title}
          rest_call={contentList.get_data_from_rest}
          row_render={contentList.create_table}
          rest_data={contentState && contentState.item_list}
          width="90rem"
        />
      </div>
    );
  } else {
    return (
      <div>
        <div style={{ margin: "0.5rem 0.1rem" }}>
          <CustomTable
            columns_head={contentList.columns_head}
            table_title={contentList.table_title}
            rest_call={contentList.get_data_from_rest}
            row_render={contentList.create_table}
            rest_data={contentState && contentState.item_list}
            // width="100rem"
            // height="35rem"
            width={getBoxSize().width}
            height={getBoxSize().height}
          />
        </div>
        {mode === "ledger" ? (
          <div></div>
        ) : (
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.25rem" }}>
            <Button
              className={clsx({
                [btnStyleClass.btnRootWhite]: true,
                [btnStyleClass.buttonType7]: true,
              })}
              onClick={() => {
                onClickAddRow();
              }}
              disabled={getIsViewLock(mode)}
            >
              행 추가
            </Button>
          </div>
        )}
      </div>
    );
  }
};
export default ConsultForm;

//

class contentTable {
  table_title = "";
  onClickDelete = null;
  readOnly = null;
  mode = null;
  onClickLedger = null;
  accountList = null;

  constructor(mode, readOnly, onClickDelete, onClickLedger, accountList) {
    this.mode = mode;
    this.onClickDelete = onClickDelete;
    this.readOnly = readOnly;
    this.onClickLedger = onClickLedger;
    this.accountList = accountList;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow
        style={{}}
        key={idx}
        // onClick={(e) => {
        //   if (e.target.nodeName === "DIV" || e.target.nodeName === "TD") {
        //     this.onClickRow(idx);
        //   }
        // }}
        // hover
      >
        <CustomTableContents>
          <TextField
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            defaultValue={data.breakdown}
            onChangeCallback={(e) => {
              data.breakdown = e;
            }}
            disabled={this.readOnly}
          />
        </CustomTableContents>
        <CustomTableContents>
          <NumberTextField
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            defaultValue={data.price}
            onChangeCallback={(e) => {
              data.price = e;
            }}
            disabled={this.readOnly}
            isNumber
            endAdornment="원"
          />
        </CustomTableContents>
        <CustomTableContents>
          <NumberTextField
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            defaultValue={data.tax_price}
            onChangeCallback={(e) => {
              data.tax_price = e;
            }}
            disabled={this.readOnly}
            isNumber
            endAdornment="원"
          />
        </CustomTableContents>
        <CustomTableContents>
          <NumberTextField
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            defaultValue={data.total_price}
            onChangeCallback={(e) => {
              data.total_price = e;
            }}
            disabled={this.readOnly}
            isNumber
            endAdornment="원"
          />
        </CustomTableContents>
        <CustomTableContents>
          <TextField
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            defaultValue={data.bank}
            onChangeCallback={(e) => {
              data.bank = e;
            }}
            disabled={this.readOnly}
          />
        </CustomTableContents>
        <CustomTableContents>
          <TextField
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            defaultValue={data.bank_acc}
            onChangeCallback={(e) => {
              data.bank_acc = e;
            }}
            disabled={this.readOnly}
          />
        </CustomTableContents>
        <CustomTableContents>
          <TextField
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            defaultValue={data.bank_acc_name} // account_name 으로 바꿔야 하는거 아닌지?
            onChangeCallback={(e) => {
              data.bank_acc_name = e;
            }}
            disabled={this.readOnly}
          />
        </CustomTableContents>
        <CustomTableContents>
          <SearchComboBox
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            data={this.accountList}
            defaultValue={data.account}
            onChangeCallback={(e, v) => {
              data.account = v;
            }}
            disabled={this.readOnly}
          />
        </CustomTableContents>
        <CustomTableContents>
          <ComboBox
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            data={["전자", "서면", "현금영수증"]}
            defaultValue={data.tax_invoice}
            onChangeCallback={(e) => {
              data.tax_invoice = e.target.value;
            }}
            disabled={this.readOnly}
          />
        </CustomTableContents>
        {this.mode === "unspent" ? (
          <>
            <CustomTableContents>
              <DatePicker
                fullWidth
                labelMarginRight="0rem"
                textMarginRight="0rem"
                defaultValue={data.ledger_date}
                onChangeCallback={(e) => {
                  data.ledger_date = e;
                }}
                disabled={data.is_expenditure}
              />
            </CustomTableContents>
            <CustomTableContentsEND>
              {data.is_expenditure ? (
                <></>
              ) : (
                <IconButton
                  type="attendance"
                  onClick={() => this.onClickLedger(data)}
                  tooltip="지출 내역을 확정합니다."
                />
              )}
            </CustomTableContentsEND>
          </>
        ) : (
          <CustomTableContentsEND>
            {!this.readOnly ? (
              <IconButton type="delete" onClick={() => this.onClickDelete(idx)} />
            ) : (
              <></>
            )}
          </CustomTableContentsEND>
        )}
      </CustomRow>
    );
  };

  columns_head = [
    { title: "내역", width: "18%" },
    { title: "공급가액", width: "8%" },
    { title: "부가세액", width: "8%" },
    { title: "총계", width: "8%" },
    { title: "은행", width: "7.5%" },
    { title: "계좌번호", width: "12%" },
    { title: "입금자명", width: "7.5%" },
    { title: "계정", width: "12%" },
    { title: "세금계산서 발행", width: "9%" },
    { title: "", width: "4%" },
  ];

  columns_head_unspent = [
    { title: "내역", width: "15%" },
    { title: "공급가액", width: "8%" },
    { title: "부가세액", width: "8%" },
    { title: "총계", width: "8%" },
    { title: "은행", width: "7.5%" },
    { title: "계좌번호", width: "12%" },
    { title: "입금자명", width: "7.5%" },
    { title: "계정", width: "12%" },
    { title: "세금계산서 발행", width: "9%" },
    { title: "지출일", width: "7%" },
    { title: "", width: "4%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
