import { useEffect } from "react";
import "./style.css";

const PassAuthorize = () => {
  useEffect(() => {
    // 인증결과 콜백함수 정의
    const script = document.createElement("script");
    const callBackFunc = document.createTextNode(
      "function result(result) {" +
        "try {" +
        "result = JSON.parse(result);" +
        "document.querySelector('#result').value = JSON.stringify(result, null, 4);" +
        "} catch (error) {" +
        "document.querySelector('#result').value = result;" +
        "}" +
        "}",
    );

    script.appendChild(callBackFunc);
    document.body.appendChild(script);

    // 본인확인 시작
    mokMove();
  }, []);

  const mokMove = () => {
    let setTimeOutCount = 0;
    const interval = setInterval(() => {
      if (window.MOBILEOK !== null && window.MOBILEOK?.process !== undefined) {
        window.MOBILEOK.process("http://59.11.2.212:12000/mok/mok_std_request", "MB", ""); // 개발
        // window.MOBILEOK.process("https://erp.raps365.com/mok/mok_std_request", "MB", ""); // 운영
        // window.MOBILEOK.process("http://localhost:12000/mok/mok_std_request", "MB", ""); // 로컬
        clearInterval(interval);
      } else {
        setTimeOutCount += 1;
        if (setTimeOutCount > 10) {
          clearInterval(interval);
        }
      }
    }, 1000); // 2초마다 fetchData 함수를 호출합니다.
  };

  return (
    <>
      <div class="wrapper">
        <div>
          <div class="loader"></div>
          <div class="spacer"></div>
          <button onClick={mokMove}>본인확인 시작(팝업)</button>
        </div>
      </div>
    </>
  );
};
export default PassAuthorize;
