import * as ActionTypes from "./action_type";

///MEMBER VIEW
export const loadPostponeView = (member_id, branch_id, membership_id) => {
  return {
    type: ActionTypes.getPostponeViewAction,
    member_id: member_id,
    branch_id: branch_id,
    membership_id: membership_id,
  };
};

export const loadPostponeViewSuccess = (data) => {
  return {
    type: ActionTypes.getPostponeViewSuccessAction,
    payload: data,
  };
};

export const loadPostponeViewFail = (data) => {
  return {
    type: ActionTypes.getPostponeViewFailAction,
    payload: [],
  };
};

export const loadPostponeViewReset = (data) => {
  return {
    type: ActionTypes.getPostponeViewResetAction,
    payload: data,
  };
};
