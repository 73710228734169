import IconButton from "components/Button/IconButton";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import { useEffect, useState } from "react";

const IncomeExpensesTable = ({ incomeList, expenseList }) => {
  const resultTableInst = new resultTable();
  const [resData, setResData] = useState([]);

  useEffect(() => {
    if (incomeList) {
      calcIncomeExpenses();
    }
  }, [incomeList]);

  const calcIncomeExpenses = () => {
    let income = 0;
    let expenses = 0;

    incomeList.forEach((data) => {
      if (!data.account.includes("환불")) {
        income += data.price;
      } else {
        income -= data.price; // incomeList에서 환불은 수입 감소로 처리
      }
    });

    expenseList.forEach((data) => {
      expenses += data.price;
    });

    const res = [];
    res.push({ key: "순이익", value: income - expenses });
    res.push({ key: "수입", value: income });
    res.push({ key: "지출", value: expenses });

    setResData(res);
  };

  return (
    <div style={{ marginTop: "0.5rem" }}>
      <div style={{ display: "flex", marginBottom: "0.5rem" }}>
        <IconButton type="border" />
        <Label labelText="수입/지출" marginLeft="0.5rem" fontWeight="bold" />
      </div>
      <CustomTable
        columns_head={resultTableInst.columns_head}
        table_title={resultTableInst.table_title}
        rest_call={resultTableInst.get_data_from_rest}
        row_render={resultTableInst.create_table}
        rest_data={resData}
      />
    </div>
  );
};
export default IncomeExpensesTable;

class resultTable {
  table_title = "";

  // constructor() {}

  create_table = (data, idx) => {
    return (
      <CustomRow style={{}} key={idx}>
        <CustomTableContents $bgColor="#dfebf3">{data.key}</CustomTableContents>
        <CustomTableContents $isNumber>{data.value.toLocaleString() + " 원"}</CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "구분", width: "50%" },
    { title: "합계", width: "50%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
