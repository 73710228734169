import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import SubTitle from "components/PageTitle/SubTitle";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadHqStaffList, loadHqStaffListReset } from "../Module/actions/permision_act";

const HQStaffList = ({ onClickOpenModal, hqId, hqBaseData }) => {
  const storesDispatch = useDispatch();

  const restData = useSelector((state) => state.hq_staff_state.hq_staff_set_state);
  // console.log(hqId);
  // console.log(restData);

  useEffect(() => {
    // console.log("HQHQSetting rerender...");
    if (hqId !== "") {
      storesDispatch(loadHqStaffList(hqId));
    }

    return () => {
      storesDispatch(loadHqStaffListReset());
    };
    // }, [loadHqStaffList, storesDispatch]);
  }, [hqId]);

  const onClickPermission = (staffData) => {
    const param = {
      title: "본부 직원 권한 설정",
      modalParam: {
        staff_id: staffData.staff_id,
        staff_name: staffData.personal_info.staff_name,
        hq_id: hqId,
        hqBaseData: hqBaseData,
      },
    };
    onClickOpenModal("본부관리/직원권한", param);
  };

  const hqEmployeeList = new hqEmployeeTable(onClickPermission);

  return (
    <div>
      <SubTitle titleText={"본부 직원 관리"} />
      <CustomTable
        columns_head={hqEmployeeList.columns_head}
        table_title={hqEmployeeList.table_title}
        rest_call={hqEmployeeList.get_data_from_rest}
        row_render={hqEmployeeList.create_table}
        rest_data={restData.stores.data}
      />
    </div>
  );
};
export default HQStaffList;

class hqEmployeeTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onClickPermission = null;

  constructor(onClickPermission) {
    this.onClickPermission = onClickPermission;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow style={{ width: "100%" }} key={idx}>
        <CustomTableContents width="17%">{data.base_info.department.org_name}</CustomTableContents>
        <CustomTableContents width="15%">{data.personal_info.sabun}</CustomTableContents>
        <CustomTableContents width="17%">{data.base_info.rank.org_name}</CustomTableContents>
        <CustomTableContents width="17%">{data.base_info.position.org_name}</CustomTableContents>
        <CustomTableContents width="17%">{data.personal_info.staff_name}</CustomTableContents>
        <CustomTableContents width="10%">
          <IconButton type="setting" width="2.2rem" onClick={() => this.onClickPermission(data)} />
          {/* <Button
            className={clsx({
              [this.btnStyleClass.btnRoot]: true,
              [this.btnStyleClass.buttonType5]: true,
            })}
            onClick={() => this.onClickPermission(data)}
          >
            권한설정
          </Button> */}
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "부서명", field: "department" },
    { title: "사번", field: "sabun" },
    { title: "직위명", field: "position" },
    { title: "직책명", field: "duty" },
    { title: "임직원명", field: "executive" },
    { title: " ", field: "action" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}

function createData(department, position, duty, executive, emp_id) {
  return {
    department,
    position,
    duty,
    executive,
    emp_id,
  };
}
