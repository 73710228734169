import { Button } from "@material-ui/core";
import Switch from "@mui/material/Switch";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import CheckBox from "components/LabelInput/CheckBox";
import Label from "components/LabelInput/Label";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import GxClassCalendar from "./GxClassCalendar";

const GxManagement = ({ branchId, onClickOpenModal }) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();
  const [classList, setClassList] = useState([]);
  const [gridItem, setGridItem] = useState([[]]);
  const [isAllSee, setIsAllSee] = useState(false);
  const rawClassList = useRef([]);
  const [isActiveGx, setIsActiveGx] = useState(false);

  //GX운영여부 GET
  const getGxManageOption = async () => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_BRANCH_SERVER + "/branch_set/policy_view",
        {
          params: {
            branch_id: branchId,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        //성공
        setIsActiveGx(res.data.data.contents.is_active_gx);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  //지점바뀔때마다 gx운영여부 다시 GET
  useEffect(() => {
    if (branchId) {
      getGxManageOption();
      getGxInfo();
    }
  }, [branchId]);

  //지울거
  useEffect(() => {
    if (isActiveGx) {
      getGxInfo();
    }
  }, [isActiveGx]);

  //GX운영여부 토글버튼
  const onToggleEvt = (isUse) => {
    if (isUse) {
      //운영 -> 미운영
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "GX 운영 중지",
          bodyText: (
            <>
              <Label labelText="운영을 중지할 경우, GX 상품을 판매할 수 없으며" />
              <Label labelText="기존에 등록된 정보는 사라지지 않습니다." />
            </>
          ),
          onBtnYes: () => {
            toggleGx(false);
          },
          onBtnNo: () => {},
        }),
      );
    } else {
      //미운영 -> 운영
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "GX 운영",
          bodyText: (
            <>
              <Label labelText="GX 수업을 운영하시겠습니까?" />
            </>
          ),
          onBtnYes: () => {
            toggleGx(true);
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  //GX 운영 토글 PATCH
  const toggleGx = async (mode) => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const formData = new FormData();
      formData.append("on_off", mode);
      formData.append("branch_id", branchId);
      const res = await HttpInstance.patch(
        process.env.REACT_APP_BRANCH_SERVER + "/branch_set/gx_set_on_off",
        formData,
        config,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        getGxManageOption(); //gx운영 여부 갱신
        getGxInfo(); //gx상세 받아오기
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    }
  };

  //GX정보 GET
  const getGxInfo = async () => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_BRANCH_SERVER + "/branch_set/gx_view",
        {
          params: {
            branch_id: branchId,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        //성공

        setGridItem(res.data.data.grid_item);
        const allClassList = res.data.data.all_class;
        if (allClassList) {
          const openClassList = allClassList.filter((el) => el.is_open);
          rawClassList.current = allClassList;
          setClassList(openClassList);
        } else {
          setClassList([]);
        }
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  //수업 개강폐강 토글
  const toggleOnClass = async (mode, gxId) => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const formData = new FormData();
      formData.append("on_off", mode);
      formData.append("branch_id", branchId);
      formData.append("gx_id", gxId);
      const res = await HttpInstance.patch(
        process.env.REACT_APP_BRANCH_SERVER + "/branch_set/gx_set_on_off/class",
        formData,
        config,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공

        if (isAllSee) {
          setIsAllSee(false);
        }
        getGxInfo(); //gx상세 받아오기
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    }
  };

  const popCloseClass = (data) => {
    if (data.is_open) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="다음 수업을 폐강하시겠습니까?" />
              <Label labelText={`${data.gx_info.gx_id} | ${data.class_name}`} fontWeight="600" />
            </>
          ),

          onBtnYes: () => {
            toggleOnClass(!data.is_open, data.gx_info.gx_id);
          },
          onBtnNo: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="다음 수업을 개강하시겠습니까?" />
              <Label labelText={`${data.gx_info.gx_code} | ${data.class_name}`} fontWeight="600" />
            </>
          ),

          onBtnYes: () => {
            toggleOnClass(!data.is_open, data.gx_info.gx_id);
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  //수업 추가 모달 닫히고 -> 화면 갱신()
  const refreshView = () => {
    storesDispatch(globalModalOff());
    getGxInfo();
  };

  const popAddGxClassModal = () => {
    const param = {
      title: "수업 추가",
      modalParam: {
        branchId,
      },
    };
    onClickOpenModal("gx수업추가", param, refreshView);
  };

  //체크여부 바뀔때마다
  //모두보기 -> 초기 raw데이터 받아뒀던거로 보여주고
  //아니면 -> 초기 raw데이터에서 open만 다시 필터링 해서 set해줌
  useEffect(() => {
    if (isAllSee) {
      const rawList = rawClassList.current;
      setClassList(rawList);
    } else {
      const filtered = rawClassList.current.filter((el) => el.is_open);
      setClassList(filtered);
    }
  }, [isAllSee]);

  const gxListData = new gxListTable(popCloseClass);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {isActiveGx ? (
          <IconButton
            type="gx_toggle_on"
            onClick={() => {
              onToggleEvt(true);
            }}
          />
        ) : (
          <IconButton
            type="gx_toggle_off"
            onClick={() => {
              onToggleEvt(false);
            }}
          />
        )}
      </div>
      {isActiveGx ? (
        <div style={{ width: "100%", display: "flex", marginTop: "1.2rem" }}>
          <div style={{ width: "80%" }}>
            <GxClassCalendar gridItem={gridItem} />
          </div>
          <div style={{ width: "1.5%" }}></div>
          <div style={{ width: "18.5%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <CheckBox
                labelText="모든 수업 보기"
                marginTop="0rem"
                labelMarginRight="0.1rem"
                marginRight="0.2rem"
                marginLeft="0rem"
                width="1.2rem"
                height="1.2rem"
                transform="scale(0.9)"
                labelTextColor="#777777"
                defaultValue={isAllSee}
                fontSize="0.825rem"
                onChangeCallback={(e) => {
                  setIsAllSee(e.checked);
                }}
              />
            </div>
            <div>
              <CustomTable
                columns_head={gxListData.columns_head}
                table_title={gxListData.table_title}
                rest_call={gxListData.get_data_from_rest}
                row_render={gxListData.create_table}
                rest_data={classList}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
              <Button
                className={clsx({
                  [btnStyleClass.btnRoot]: true,
                  [btnStyleClass.buttonType6]: true,
                })}
                onClick={() => {
                  popAddGxClassModal();
                }}
              >
                수업 추가
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <Label labelText="운영중인 GX가 없습니다. " marginTop="3rem" color="#999999" />
      )}
    </div>
  );
};

export default GxManagement;

class gxListTable {
  table_title = "";
  popCloseClass = null;

  constructor(popCloseClass) {
    this.popCloseClass = popCloseClass;
  }

  create_table = (data, key) => {
    return (
      <CustomRow key={key} hover>
        <CustomTableContents>{data.gx_info.gx_code}</CustomTableContents>
        <CustomTableContents>{data.class_name}</CustomTableContents>
        <CustomTableContentsEND align="center">
          <Switch
            checked={data.is_open}
            onChange={(e) => {
              this.popCloseClass(data);
            }}
          />
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "수업코드", width: "40%" },
    { title: "수업명", width: "40%" },
    { title: "", width: "20%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
