import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { getNowServiceState } from "pages/NoticeBoard/model/board_row";
import NoticeCreateWrapper from "./NoticeCreateWrapper";
const NoticeCreateService = () => {
  const nowService = "공지사항";
  return (
    <div>
      <PageFrame
        children={
          <div>
            <PageTitle titleText={nowService} />
            <NoticeCreateWrapper nowService={getNowServiceState(nowService)} />
          </div>
        }
      />
    </div>
  );
};

export default NoticeCreateService;
