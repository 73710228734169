import { Button } from "@material-ui/core";
import border from "assets/icon/Border.svg";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import FileUpload from "components/LabelInput/FileUpload";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOff, globalAlertOn } from "modules/actions/Alert";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PreViewPopup from "./Modal/PreViewPopup";
import UploadPopup from "./Modal/UploadPopup";
import { newDeleteRowAPi } from "./RestApi/deleteApi";
import { getCounselingTableAPi } from "./RestApi/getApi";

const CatalogList = ({ selectedBranchInfo }) => {
  const storesDispatch = useDispatch();

  const monitorServerHost = process.env.REACT_APP_MONITOR_SERVER;
  const btnStyleClass = btnStyles();
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newMonitorImage, setNewMonitorImage] = useState("");
  const [monitorServerUrl, setMonitorServerUrl] = useState();
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });
  const viewModelRef = useRef();

  useEffect(() => {
    getCounselingTableAPi(storesDispatch, selectedBranchInfo.branch_id, setDataList);
    setMonitorServerUrl(monitorServerHost + "login/" + selectedBranchInfo.branch_id);
  }, [selectedBranchInfo]);

  const goToDetail = (fileObject) => {
    setModalInfo({
      show: true,
      title: "미리 보기",
      Content: <PreViewPopup params={fileObject} onModalDone={onFileUpload} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const editModal = (data) => {
    setModalInfo({
      show: true,
      title: "파일 수정",
      Content: <UploadPopup params={data} onModalDone={refreshData} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const refreshData = () => {
    storesDispatch(globalAlertOff());
    getCounselingTableAPi(storesDispatch, selectedBranchInfo.branch_id, setDataList);
    setLoading(false);
    setModalInfo({
      show: false,
      title: "",
      Content: "",
      callback: null,
      Buttons: undefined,
    });
  };

  const doDelete = (data) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: <Label labelText={"파일을 삭제 하시겠습니까?"} />,
        // width: "50rem",
        onBtnYes: () => {
          setLoading(true);
          newDeleteRowAPi(refreshData, storesDispatch, data.resource_id);
          // onModalDone("추가", file);
        },
        onBtnNo: () => {},
      }),
    );
  };
  const searchResultList = new searchResultTable(goToDetail, editModal, doDelete);

  const onFileUpload = () => {};

  // 출입모니터 이미지 저장
  const onSaveAccessMonitorImage = async () => {
    try {
      const formData = new FormData();
      formData.append("branch_id", selectedBranchInfo.branch_id);
      formData.append("image", newMonitorImage);

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_BRANCH_SERVER + "/branch/monitor-image",
        formData,
        contentTypeJson,
      );
      if (res.error) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        simpleAlert(storesDispatch, "알림", "저장되었습니다.");
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <Loading />}
      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "0.2rem" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            alt="border"
            src={border}
            style={{
              width: "1.2rem",
              height: "1.2rem",
            }}
          />
          <SubTitle titleText={"상담자료 관리"} />
        </div>
        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType6]: true,
          })}
          onClick={() => {
            const popupParam = {
              resource_id: "",
              branch_info: selectedBranchInfo,
            };
            setModalInfo({
              show: true,
              title: "파일 추가",
              Content: <UploadPopup params={popupParam} onModalDone={refreshData} />,
              callback: null,
              Buttons: undefined,
            });
          }}
        >
          파일추가
        </Button>
      </div>

      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={dataList}
        height={"30rem"}
      />
      <div style={{ marginTop: "1.5rem" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            alt="border"
            src={border}
            style={{
              width: "1.2rem",
              height: "1.2rem",
            }}
          />
          <SubTitle titleText={"출입모니터 이미지 업로드"} />
        </div>
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <FileUpload
            marginTop="0.5rem"
            labelMarginLeft="0.5rem"
            labelWidth={"3rem"}
            labelFontWeight="bold"
            textBoxWidth={"15rem"}
            textMarginRight={"0rem"}
            labelText={`파일명`}
            fileMaxSizeMB={10}
            accept="image/png, image/gif"
            onUploadFile={(file) => {
              setNewMonitorImage(file);
            }}
            showDelete
          />
          <NormalBtn
            name="저장"
            margin="0 0 0 1rem"
            onClick={() => {
              if (restEmpty(newMonitorImage)) {
                simpleAlert(storesDispatch, "알림", "저장할 파일을 첨부해 주시기 바랍니다.");
              } else {
                simpleAlert(
                  storesDispatch,
                  "알림",
                  `${selectedBranchInfo.branch_name}점 출입모니터 이미지를 저장하시겠습니까?`,
                  false,
                  undefined,
                  () => {
                    onSaveAccessMonitorImage();
                  },
                );
              }
            }}
          />
          <NormalBtn
            name="출입모니터 페이지 열기"
            margin="0 0 0 1rem"
            theme="white"
            onClick={() => {
              window.open(monitorServerUrl, "_blank");
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default CatalogList;

class searchResultTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onParentClickEvent = null;
  onEditClickEvent = null;
  onDeleteRowAPi = null;

  constructor(goToDetailView, editModal, deleteDo) {
    this.onParentClickEvent = goToDetailView;
    this.onEditClickEvent = editModal;
    this.onDeleteRowAPi = deleteDo;
  }
  parseText = (text) => {
    return text.substr(0, 10) + "\n" + text.substr(11, 8);
  };
  onClickEvent = (data) => {
    // console.log(data.resource_id);
    this.onParentClickEvent(data.file_object);
  };
  onClickEditEvent = (data) => {
    this.onEditClickEvent(data);
  };
  newDeleteRowAPi = (data) => {
    this.onDeleteRowAPi(data);
  };

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx} hover>
        <CustomTableContents
          onClick={() => {
            this.onClickEvent(data);
          }}
        >
          {idx + 1}
        </CustomTableContents>
        <CustomTableContents
          onClick={() => {
            this.onClickEvent(data);
          }}
        >
          {data.file_object.file_info.file_name}
        </CustomTableContents>
        <CustomTableContents
          onClick={() => {
            this.onClickEvent(data);
          }}
        >
          {data.file_object.reg_info.created_at}
        </CustomTableContents>

        <CustomTableContents>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <NormalBtn
                name="편집"
                theme="white"
                width="4rem"
                onClick={() => this.onClickEditEvent(data)}
              />
              <NormalBtn
                margin="0 0 0 0.5rem"
                name="삭제"
                theme="white"
                width="4rem"
                onClick={() => this.onDeleteRowAPi(data)}
              />
            </div>
          </div>
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "순서", width: "5%" },
    { title: "파일명", width: "30%" },
    { title: "등록일시", width: "10%" },
    { title: "", width: "10%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
