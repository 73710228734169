///Login
export const globalStartLoading = () => {
  return {
    type: "loading/GLOBAL_STATE/ON",
    payload: true,
  };
};

export const globalFinishLoading = () => ({
  type: "loading/GLOBAL_STATE/OFF",
  payload: false,
});
