import React, { useState, useRef, useEffect } from "react";
import RefundSelector from "pages/ERP/MemberCommonItems/RefundSelector";
import { RefundPayMethodList } from "../../Refund/RefundPayMethodList";

const RefundListItem = ({ refundItem, index, selectPayMethod, onRefundPaymentItem, regDate }) => {
  const refundInfoRef = useRef();
  const [selectPaymentList, setSelectPaymentList] = React.useState([]);
  const [refundTotalPrice, setRefundTotalPrice] = useState(0);
  const [addedRefundTotalPrice, setAddedRefundTotalPrice] = useState(0);

  const [refundPayList, setRefundPayList] = useState(refundItem.refund_detail);

  const onAddTotalUpdate = (total) => {
    let totalRefund = refundTotalPrice + total;
    selectPayMethod(totalRefund, index);
    setAddedRefundTotalPrice(total);
  };

  const updateRefundPayList = (data) => {
    setRefundPayList(data);
    refundItem.refund_detail = data;
  };

  const onSelectPayment = () => {
    const curPaymentList = [];
    let totalUnpaid = 0;
    let totalPaid = 0;

    refundItem.done_refund_price = 0;

    for (let i = 0; i < refundItem.refund_detail.length; i++) {
      refundItem.refund_detail[i].is_view = false;
      refundItem.refund_detail[i].selected = false;
    }

    for (let i = 0; i < refundItem.payment_list.length; i++) {
      if (refundItem.payment_list[i].payment_list_selector.selected) {
        let index = refundItem.refund_detail
          .map((item) => item.payment_id)
          .indexOf(refundItem.payment_list[i].payment_data.payment_id);
        if (index !== -1) {
          curPaymentList.push(refundItem.payment_list[i]);
          refundItem.refund_detail[index].is_view = true;
          refundItem.refund_detail[index].selected = true;
          refundItem.done_refund_price += refundItem.payment_list[i].payment_data.price;
          // totalUnpaid += serviceViewState.unpaid.unpaid_list[index].unpaid_price;
        }
        totalPaid += refundItem.payment_list[i].payment_data.price;
      }
    }

    selectPayMethod(totalPaid, index);
    // onRefundPaymentItem(serviceViewState.purchase_code, totalPaid);
    makeRefundTotal();
  };

  const makeRefundTotal = () => {
    var total = 0;
    for (let i = 0; i < refundItem.refund_detail.length; i++) {
      if (refundItem.refund_detail[i].selected) {
        total += refundItem.refund_detail[i].price;
      }
    }
    setRefundTotalPrice(total);
  };

  const tableWidth = "69.25rem";

  return (
    <div style={{ width: tableWidth }}>
      <RefundPayMethodList
        payMethodList={refundItem.payment_list}
        onSelectPayment={onSelectPayment}
      />

      <RefundSelector
        // ref={refundInfoRef}
        refundTotalPrice={refundTotalPrice}
        addedRefundTotalPrice={addedRefundTotalPrice}
        setAddedRefundTotalPrice={onAddTotalUpdate}
        refundPayList={refundPayList}
        setRefundPayList={updateRefundPayList}
        regDate={regDate}
        tableWidth="75rem"
      />
    </div>
  );
};
export default RefundListItem;
