import IconButton from "components/Button/IconButton";
import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import TextField from "components/LabelInput/TextField";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import IncentiveByStaffList from "./IncentiveByStaffList";

const MembershipSalesIncentiveList = ({ salaryReportId, is_edit, baseDate }) => {
  const storesDispatch = useDispatch();
  const [selected, setSelected] = useState({
    salaryReportId: salaryReportId,
    staffId: "",
    staffName: "",
    staffPos: "",
    staffSalaryId: "",
  });
  const [mbshpSalaryList, setMbshpSalaryList] = useState();
  const [mbshpDetails, setMbshpDetails] = useState([]);

  useEffect(() => {
    getMbshpSalaryList();
  }, [salaryReportId]);

  useEffect(() => {
    if (selected.staffId) {
      getMbshpSalaryDetailByRow(selected);
    }
  }, [selected.staffId]);

  const getMbshpSalaryList = async () => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_SALARY_SERVER + "/salary/mbshp_incentive/list",
        {
          params: {
            salary_report_id: salaryReportId,
            is_edit: is_edit,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setMbshpSalaryList(res.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  //Row클릭 상세 GET
  const getMbshpSalaryDetailByRow = async (selectedData) => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_SALARY_SERVER + "/salary/mbshp_incentive/detail",
        {
          params: {
            staff_salary_id: selectedData.staffSalaryId,
            staff_id: selectedData.staffId,
            base_date: baseDate,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setMbshpDetails(res.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  const onClickRow = (staffId, staffName, staffPos, staffSalaryId) => {
    setSelected((cur) => {
      return { ...cur, staffId, staffName, staffPos, staffSalaryId };
    });
  };

  const staffListTableItem = new staffListTable(onClickRow);

  return (
    <div>
      <div>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <SubTitle titleText="회원권 판매 인센티브 현황" />
        </div>
      </div>
      <div style={{ marginTop: "0.7rem" }}>
        <div>
          <CustomTable
            columns_head={staffListTableItem.columns_head}
            table_title={staffListTableItem.table_title}
            rest_call={staffListTableItem.get_data_from_rest}
            row_render={staffListTableItem.create_table}
            rest_data={mbshpSalaryList && mbshpSalaryList.data}
            maxHeight={"30rem"}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.7rem" }}>
          <TextField
            labelWidth={"6rem"}
            textBoxWidth={"9rem"}
            textMarginRight="0rem"
            labelText="인센티브 합계"
            endAdornment={"원"}
            defaultValue={mbshpSalaryList && mbshpSalaryList.total_incentive.toLocaleString()}
            isNumber
            disabled
          />
        </div>
      </div>

      {/*위에 테이블 눌러서 row별로 바뀌는 테이블 */}
      {selected.staffId && (
        <IncentiveByStaffList mbshpDetailArr={mbshpDetails} selected={selected} />
      )}
    </div>
  );
};
export default MembershipSalesIncentiveList;

class staffListTable {
  table_title = "";
  onClickRow = null;

  constructor(onClickRow) {
    this.onClickRow = onClickRow;
  }

  getMbshpSalesTotal = (data) => {
    let totalSales = 0;
    if (data.mbshp_salary.sales_record_list) {
      data.mbshp_salary.sales_record_list.forEach((curSales) => {
        if (curSales.sales_info.purchase_refund_total > 0) {
          totalSales -= curSales.sales_info.purchase_refund_total;
        } else {
          totalSales += curSales.sales_info.paid_card_price + curSales.sales_info.paid_cash_price;
        }
      });
    }
    return totalSales;
  };

  create_table = (data, idx) => {
    return (
      <CustomRow
        key={idx}
        hover
        onClick={() => {
          this.onClickRow(
            data.staff_info.staff_id,
            data.personal_info.staff_name,
            data.staff_info.staff_pos,
            data.staff_salary_id,
          );
        }}
      >
        <CustomTableContents>{data.personal_info.sabun}</CustomTableContents>
        <CustomTableContents>{data.personal_info.staff_name}</CustomTableContents>
        <CustomTableContents>{data.staff_info.staff_pos}</CustomTableContents>
        <CustomTableContents $isNumber>{data.mbshp_salary.sales_cnt + " 건"}</CustomTableContents>
        <CustomTableContents $isNumber>
          {this.getMbshpSalesTotal(data).toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.mbshp_salary.sales_incentive.toLocaleString() + " 원"}
        </CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "사번", width: "16%" },
    { title: "직원명", width: "16%" },
    { title: "직책", width: "16%" },
    { title: "지급 건수", width: "16%" },
    { title: "회원권 매출", width: "16%" },
    { title: "인센티브 합계", width: "16%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
