import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import { getPermission } from "components/CommonLib/CommonLib";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ApprovalTable from "./ApprovalTable";
import SearchStaff from "./SearchStaff";

const ApprovalSelectorView = ({ modalParam, onModalDone }) => {
  const permissionKey = "APPROVAL_MENU/MANAGE";

  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const [receiverList, setReceiverList] = useState(modalParam.approval_info.receiver_list);
  const [approverList, setApproverList] = useState(modalParam.approval_info.approver_list);
  const [viewerList, setViewerList] = useState(modalParam.approval_info.viewer_list);
  const [hqBranchList, setHqBranchList] = React.useState();

  useEffect(() => {
    getPermission(storesDispatch, permissionKey, true, false, true, true).then((res) => {
      setHqBranchList(res);
    });
  }, []);

  const onCallbackEvent = () => {
    modalParam.approval_info.approver_list = approverList;
    modalParam.approval_info.receiver_list = receiverList;
    modalParam.approval_info.viewer_list = viewerList;

    onModalDone(modalParam);
  };

  return (
    <div
      style={{
        width: "100rem",
      }}
    >
      <div style={{ display: "flex" }}>
        <CardFixed
          topDom={
            <div style={{ display: "flex" }}>
              <IconButton type="border" />
              <div style={{ width: "0.5rem" }}></div>
              <SubTitle titleText="직원 검색" />
            </div>
          }
          midDom={
            <div>
              <SearchStaff
                hqBranchList={hqBranchList}
                modalParam={modalParam}
                onModalDone={onModalDone}
                approverList={approverList}
                receiverList={receiverList}
                viewerList={viewerList}
                setApproverList={setApproverList}
                setReceiverList={setReceiverList}
                setViewerList={setViewerList}
                permissionKey={permissionKey}
                isAddHqOnly={true}
              />
            </div>
          }
          isLoading={false}
          rootStyle={{
            marginTop: "0.625rem",
            borderRadius: "10px",
            backgroundColor: "#fff",
          }}
        />

        <CardFixed
          topDom={
            <div style={{ display: "flex" }}>
              <IconButton type="border" />
              <div style={{ width: "0.5rem" }}></div>
              <SubTitle titleText="결재 정보" />
            </div>
          }
          midDom={
            <div style={{ display: "flex" }}>
              <div style={{ width: "33%" }}>
                <SubTitle titleText="결재" />
                <ApprovalTable
                  modalParam={modalParam}
                  dataList={approverList}
                  setDataList={setApproverList}
                />
              </div>
              <div style={{ width: "33%", marginLeft: "2rem" }}>
                <SubTitle titleText="열람" />
                <ApprovalTable
                  modalParam={modalParam}
                  dataList={viewerList}
                  setDataList={setViewerList}
                />
              </div>
              <div style={{ width: "33%", marginLeft: "2rem" }}>
                <SubTitle titleText="수신" />
                <ApprovalTable
                  modalParam={modalParam}
                  dataList={receiverList}
                  setDataList={setReceiverList}
                />
              </div>
            </div>
          }
          isLoading={false}
          rootStyle={{
            width: "70rem",
            marginTop: "0.625rem",
            marginLeft: "0.5rem",
            borderRadius: "10px",
            backgroundColor: "#fff",
          }}
        />
      </div>

      <div
        style={{
          textAlign: "center",
          marginTop: "1.5rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {modalParam.mode === "approval" ? (
          <NormalBtn
            style={{ marginLeft: "0.5rem" }}
            name="결재선 저장"
            theme="white"
            onClick={() => {
              let approval_info = {
                approver_list: approverList,
                receiver_list: receiverList,
                viewer_list: viewerList,
              };
              modalParam.onClickSaveFavorite(approval_info);
            }}
          />
        ) : (
          <></>
        )}
        <div style={{ marginLeft: "0.5rem" }}></div>
        <NormalBtn style={{ marginLeft: "0.5rem" }} name="완료" onClick={() => onCallbackEvent()} />
      </div>
    </div>
  );
};
export default ApprovalSelectorView;
