import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import {
  contentTypeJson,
  dataEmpty,
  generateObjectZeroId,
  restEmpty,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import ComboBox from "components/LabelInput/ComboBox";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { searchStaffParamInit } from "./Module/reducers/staff_search_params";

const SearchEmployee = ({ modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();

  const storesDispatch = useDispatch();
  const [searchParam, setSearchParam] = useState(_.cloneDeep(searchStaffParamInit));
  const [staffSearchResult, setStaffSearchResult] = useState([]);
  const [hqIdx, setHqIdx] = useState(0);
  const [curPage, setCurPage] = useState();
  const [totalPage, setTotalPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const [phone, setPhone] = useState({
    p1: "",
    p2: "",
    p3: "",
  });
  const phoneRef1 = useRef();
  const phoneRef2 = useRef();
  const phoneRef3 = useRef();

  useEffect(() => {
    if (modalParam.target_list.length > 0) {
      if (modalParam.target_list[0].branch_list.length > 0) {
        searchParam.branch_info.branch_id = modalParam.target_list[0].branch_list[0].branch_id;
        searchParam.hq_info.hq_id = modalParam.target_list[0].hq_info.hq_id;
      } else {
        searchParam.hq_info.hq_id = modalParam.target_list[0].hq_info.hq_id;
      }
    }
  }, []);

  // 전화번호 입력 이벤트 (모든 칸)
  const onInputPhone = () => {
    let res = "";

    if (phone.p1 && !phone.p2 && phone.p3) {
      res = "err";
    } else {
      res = phone.p1 + "-" + phone.p2 + "-" + phone.p3;
    }
    res = res.replace("--", "-");

    searchParam.phone = res;
  };

  const onClickRowEvent = (data) => {
    onModalDone(data);
  };

  const onClickSearch = async (page) => {
    setCurPage(page);

    const formData = new FormData();
    formData.append("page_num", page);
    formData.append("search_param", JSON.stringify(searchParam));

    setLoading(true);

    await HttpInstance.post(
      process.env.REACT_APP_STAFF_SERVER + "/staff/search_modal",
      formData,
      contentTypeJson,
    )
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          if (!restEmpty(res.data.data)) {
            setStaffSearchResult(res.data.data);
            setTotalPage(res.data.total_page);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const permissionList = new searchResultTable(onClickRowEvent);

  //
  return (
    <div
      style={{
        width: "42rem",
      }}
    >
      {loading && <Loading />}

      <div>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <SubTitle titleText="직원 검색" />
        </div>
        <div>
          <div style={{ display: "flex", gap: "1.5rem" }}>
            <ComboBox
              labelWidth={"3.25rem"}
              comboItemWidth={"13.75rem"}
              labelText="본부"
              data={modalParam.hq_list}
              defaultValue={modalParam.hq_list[hqIdx]}
              onChangeCallback={(e) => {
                const index = modalParam.hq_list.map((item) => item).indexOf(e.target.value);
                if (index !== -1) {
                  setHqIdx(index);

                  let tBranchName = modalParam.target_list[index].branch_list[0].branch_name;
                  let tBranchId = modalParam.target_list[index].branch_list[0].branch_id;
                  if (tBranchName === "전체") {
                    tBranchId = generateObjectZeroId();
                  }

                  searchParam.hq_info = modalParam.target_list[index].hq_info;
                }
              }}
            />
            <ComboBox
              marginLeft="1rem"
              labelWidth={"3.25rem"}
              comboItemWidth={"15rem"}
              labelText="지점"
              data={modalParam.branch_list[hqIdx]}
              defaultValue={modalParam.branch_list[hqIdx][0]}
              onChangeCallback={(e) => {
                const index = modalParam.branch_list[hqIdx]
                  .map((item) => item)
                  .indexOf(e.target.value);
                if (index !== -1) {
                  let tBranchId = modalParam.target_list[hqIdx].branch_list[index].branch_id;
                  if (e === "전체") {
                    tBranchId = generateObjectZeroId();
                  }

                  searchParam.branch_info.branch_id = tBranchId;
                }
              }}
            />
          </div>
          <div style={{ display: "flex", marginTop: "0.5rem", gap: "1.5rem" }}>
            <TextField
              labelWidth={"3.25rem"}
              textBoxWidth={"13.75rem"}
              labelText="직원명"
              onChangeCallback={(e) => {
                searchParam.staff_name = e;
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onClickSearch(1);
                }
              }}
            />
            <div style={{ display: "flex", marginLeft: "1rem" }}>
              <TextField
                inputRef={phoneRef1}
                labelWidth={"3.25rem"}
                textBoxWidth={"4rem"}
                textMarginRight="0.25rem"
                labelText="연락처"
                maxLength={3}
                isStringNumber
                onChangeCallback={(e) => {
                  phone.p1 = e;
                  onInputPhone();
                  if (phone.p1.length === 3) {
                    phoneRef2.current.focus();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") onClickSearch(1);
                }}
              />
              <TextField
                inputRef={phoneRef2}
                textMarginRight="0.25rem"
                labelMarginRight="0.25rem"
                labelWidth="0.5rem"
                labelText="-"
                maxLength={4}
                textBoxWidth={"4rem"}
                isStringNumber
                onChangeCallback={(e) => {
                  phone.p2 = e;
                  onInputPhone();
                  if (phone.p2.length === 4) {
                    phoneRef3.current.focus();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onClickSearch(1);
                  } else if (e.key === "Backspace" && !phone.p2) {
                    phoneRef1.current.focus();
                  }
                }}
              />
              <TextField
                inputRef={phoneRef3}
                labelMarginRight="0.25rem"
                labelWidth="0.5rem"
                labelText="-"
                maxLength={4}
                textBoxWidth={"4rem"}
                isStringNumber
                onChangeCallback={(e) => {
                  phone.p3 = e;
                  onInputPhone();
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onClickSearch(1);
                  } else if (e.key === "Backspace" && !phone.p3) {
                    phoneRef2.current.focus();
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem" }}>
          <Button
            className={clsx({
              [btnStyleClass.btnRoot]: true,
              [btnStyleClass.buttonType2]: true,
            })}
            onClick={() => {
              onClickSearch(1);
            }}
            style={{ marginTop: "0.625rem" }}
          >
            검색
          </Button>
        </div>
      </div>

      <div style={{ marginTop: "1rem" }}>
        <CustomTable
          columns_head={permissionList.columns_head}
          table_title={permissionList.table_title}
          rest_call={permissionList.get_data_from_rest}
          row_render={permissionList.create_table}
          rest_data={staffSearchResult}
        />
        <CustomPagination curPage={curPage} doSearch={onClickSearch} totalCnt={totalPage} />
      </div>
    </div>
  );
};
export default SearchEmployee;

class searchResultTable {
  table_title = "";

  onClickRowEvent = null;

  constructor(onClickRowEvent) {
    this.onClickRowEvent = onClickRowEvent;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow
        key={idx}
        onClick={() => this.onClickRowEvent(data)}
        sx={{
          "&.MuiTableRow-root:hover": {
            backgroundColor: "#bfd8f0",
          },
        }}
        hover
      >
        <CustomTableContents>{data.base_info.hq_info.hq_name}</CustomTableContents>
        <CustomTableContents>{data.base_info.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.personal_info.staff_name}</CustomTableContents>
        <CustomTableContents>{data.base_info.position.org_name}</CustomTableContents>
        <CustomTableContentsEND>{data.personal_info.phone}</CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "본부", width: "20%" },
    { title: "지점", width: "20%" },
    { title: "직원명", width: "20%" },
    { title: "직책", width: "20%" },
    { title: "연락처", width: "20%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
