import * as lodash from "lodash";
import { useEffect, useState } from "react";
import AddTeamModal from "./AddTeamModal";
import ChangeTeamModal from "./ChangeTeamModal";
import ChooseEditModeModal from "./ChooseEditModeModal";
import EditTeamModal from "./EditTeamModal";
import EditTeamNameModal from "./EditTeamNameModal";

const ManageTeamModalIndex = ({ modalSelect, modalParam, onModalDone }) => {
  const [paramData, setParamData] = useState(modalParam.modalParam);

  useEffect(() => {
    const editData = lodash.cloneDeep(modalParam.modalParam);
    setParamData(editData);
    return () => {};
  }, []);

  if (modalSelect === "팀추가") {
    return <AddTeamModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "팀변경") {
    return <ChangeTeamModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "팀수정") {
    return <ChooseEditModeModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "팀명수정") {
    return <EditTeamNameModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "팀원수정") {
    return <EditTeamModal modalParam={paramData} onModalDone={onModalDone} />;
  } else {
    return <div>current ELSE</div>;
  }
};

export default ManageTeamModalIndex;
