import { call, put } from "redux-saga/effects";
// import get_api from "../../../../../lib/LockerSettings/GetApi";
import { globalFinishLoading, globalStartLoading } from "modules/actions/Loading/index";
import { staffViewAction } from "../actions/index";
import get_api from "../api/GetApi";

export function* getStaffInfoViewData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getStaffInfoData, param);

    if (data.data === null) {
      yield put(staffViewAction.loadStaffInfoViewSuccess(false));
    } else {
      yield put(staffViewAction.loadStaffInfoViewSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(staffViewAction.loadStaffInfoViewFail(error));
    yield put(globalFinishLoading());
  }
}

export function* getWorkInfoListData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getWorkInfoList, param);

    if (data.data === null) {
      yield put(staffViewAction.loadStaffWorkListViewSuccess(false));
    } else {
      yield put(staffViewAction.loadStaffWorkListViewSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(staffViewAction.loadStaffWorkListViewFail(error));
    yield put(globalFinishLoading());
  }
}

export function* getWorkInfoDetailData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getWorkInfoData, param);

    if (data.data === null) {
      yield put(staffViewAction.loadStaffWorkDetailViewSuccess(false));
    } else {
      yield put(staffViewAction.loadStaffWorkDetailViewSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(staffViewAction.loadStaffWorkDetailViewFail(error));
    yield put(globalFinishLoading());
  }
}

export function* getGxFeeData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getGxFeeInfoData, param);

    if (data.data === null) {
      yield put(staffViewAction.loadGxFeeViewSuccess(false));
    } else {
      yield put(staffViewAction.loadGxFeeViewSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(staffViewAction.loadGxFeeViewFail(error));
    yield put(globalFinishLoading());
  }
}
