export const getPermissionAction = "ERP/SET/PERMISSION";
export const getPermissionSuccessAction = "ERP/SET/PERMISSION/SUCCESS";
export const getPermissionFailAction = "ERP/SET/PERMISSION/FAIL";
export const getPermissionResetAction = "ERP/SET/PERMISSION/RESET";

export const getHqViewAction = "ERP/GET/HQ_VIEW";
export const getHqViewSuccessAction = "ERP/GET/HQ_VIEW/SUCCESS";
export const getHqViewFailAction = "ERP/GET/HQ_VIEW/FAIL";
export const getHqViewResetAction = "ERP/GET/HQ_VIEW/RESET";

export const getIpAllowViewAction = "ERP/GET/IP_ALLOW_VIEW";
export const getIpAllowViewSuccessAction = "ERP/GET/IP_ALLOW_VIEW/SUCCESS";
export const getIpAllowViewFailAction = "ERP/GET/IP_ALLOW_VIEW/FAIL";
export const getIpAllowViewResetAction = "ERP/GET/IP_ALLOW_VIEW/RESET";

export const getPolicyViewAction = "ERP/GET/POLICY_VIEW";
export const getPolicyViewSuccessAction = "ERP/GET/POLICY_VIEW/SUCCESS";
export const getPolicyViewFailAction = "ERP/GET/POLICY_VIEW/FAIL";
export const getPolicyViewResetAction = "ERP/GET/POLICY_VIEW/RESET";
