import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";

const ExtendMbshpTable = ({ viewData }) => {
  const resultTableInst = new resultTable();

  return (
    <div style={{ marginTop: "0.5rem" }}>
      <CustomTable
        columns_head={resultTableInst.columns_head}
        table_title={resultTableInst.table_title}
        rest_call={resultTableInst.get_data_from_rest}
        row_render={resultTableInst.create_table}
        rest_data={viewData}
        height="18rem"
      />
    </div>
  );
};
export default ExtendMbshpTable;

class resultTable {
  table_title = "";

  create_table = (data, idx) => {
    return (
      <CustomRow style={{}} key={idx}>
        <CustomTableContents>{data.reg_info.reg_name}</CustomTableContents>
        <CustomTableContents>{data.member_no}</CustomTableContents>
        <CustomTableContents>{data.member_name}</CustomTableContents>
        <CustomTableContents>{data.extend_days}</CustomTableContents>
        <CustomTableContents>{data.extend_reason}</CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "담당자", width: "15%" },
    { title: "회원번호", width: "15%" },
    { title: "이름", width: "20%" },
    { title: "추가일수", width: "10%" },
    { title: "추가사유", width: "40%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
