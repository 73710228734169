import { useState } from "react";
import CommonTabComp from "./CommonTabComp";

const MyDraftDocsTab = ({
  searchResult,
  totalPage,
  onClickSearch,
  onClickFavorite,
  onClickNewDraft,
  resultSummary,
}) => {
  const [statusCount, setStatusCount] = useState({
    done: 0,
    wait: 0,
    reject: 0,
    retrieve: 0,
  });

  return (
    <div style={{ minHeight: "40rem" }}>
      <CommonTabComp
        tabType="my_draft"
        onClickSearch={onClickSearch}
        onClickFavorite={onClickFavorite}
        onClickNewDraft={onClickNewDraft}
        totalPage={totalPage}
        searchResult={searchResult}
        statusCount={statusCount}
        resultSummary={resultSummary}
      />
    </div>
  );
};
export default MyDraftDocsTab;
