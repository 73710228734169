import { Box } from "@mui/material";
import { getDefaultValue } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import { useEffect, useState } from "react";
import { useGuestBookStore } from "./modules/store/guestBookState";

const SearchCondition = ({
  searchParams,
  setSearchParams,
  resetFlag,
  hqBranchData,
  onClickSearch,
}) => {
  // 페이지 상태 관리
  const { guestBookHqIdx: hqIdx, setHqIdx } = useGuestBookStore();
  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);

  // 본부 선택 시 지점 정보 첫번째 값 세팅
  useEffect(() => {
    if (!isFirstEnterPage) {
      setSearchParams("branch_info", hqBranchData.target_list[hqIdx].branch_list[0]);
    }
  }, [hqIdx]);

  // 초기화 시 본부 및 지점 첫번째 값 세팅
  useEffect(() => {
    if (!isFirstEnterPage && hqBranchData.target_list.length > 0) {
      setSearchParams("hq_info", hqBranchData.target_list[0].hq_info);
      setSearchParams("branch_info", hqBranchData.target_list[0].branch_list[0]);
    }
  }, [resetFlag]);

  // 첫 페이지 여부 체크
  useEffect(() => {
    setIsFirstEnterPage(false);
  }, []);

  return (
    <Box
      sx={{
        border: "1px solid #dedede",
        borderRadius: "5px",
        backgroundColor: "#f5f5f5",
        height: "6rem",
        marginTop: "0.5rem",
      }}
    >
      <div style={{ margin: "0.75rem 0.75rem" }}>
        <Label labelText="검색 조건" fontWeight="bold" justifyContent="flex-start" />
        <div
          style={{ display: "flex", marginTop: "0.5rem" }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClickSearch(1);
            }
          }}
        >
          <div style={{ width: "25%" }}>
            <ComboBox
              labelText="본부"
              labelWidth="4.5rem"
              labelMarginRight="0rem"
              comboItemWidth="15rem"
              data={hqBranchData && hqBranchData.hq_list}
              defaultValue={getDefaultValue("combo", searchParams.hq_info.hq_name)}
              onChangeCallback={(e, idx) => {
                setHqIdx(idx);
                setSearchParams("hq_info", hqBranchData.target_list[idx].hq_info);
              }}
            />
          </div>
          <div style={{ width: "25%" }}>
            <SearchComboBox
              labelText="지점"
              labelWidth="4.5rem"
              labelMarginRight="0rem"
              comboItemWidth="15rem"
              marginRight="4rem"
              data={hqBranchData && hqBranchData.branch_list[hqIdx]}
              defaultValue={getDefaultValue("combo", searchParams.branch_info.branch_name)}
              onChangeCallback={(e, v, idx) => {
                setSearchParams("branch_info", hqBranchData.target_list[hqIdx].branch_list[idx]);
              }}
            />
          </div>
          <div style={{ width: "25%" }}></div>
          <div style={{ width: "25%" }}></div>
        </div>
      </div>
    </Box>
  );
};
export default SearchCondition;
