export const pt_comm_preset_init_state = {
  contents: {
    staff_id: "000000000000000000000000",
    base_info: {
      branch_info: {
        branch_id: "000000000000000000000000",
        branch_name: "",
      },
      hq_info: {
        hq_id: "000000000000000000000000",
        hq_name: "",
      },
    },
    pt_pre_set: {
      preset_id: "000000000000000000000000",
      preset_name: "",
      is_default: false,
      pt_commission: [],
      pt_fee: [],
      reg_info: {
        created_at: "",
        updated_at: "",
        reg_name: "",
        reg_id: "000000000000000000000000",
        upd_id: "000000000000000000000000",
        upd_name: "",
      },
    },
  },
};
