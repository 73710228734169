import { restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

export const subscriptionInfo = {
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  hq_info: {
    hq_id: "",
    hq_name: "",
  },
  membership_info: {
    item_name: "",
    membership_id: "",
    member_id: "",
    member_name: "",
    member_no: "",
    phone: "",
    from_date: "",
    to_date: "",
    gender: "",
    birth: "",
    birth_info: {
      year: 0,
      month: 0,
      day: 0,
    },
    is_new: true,
    mbshp_type: "",
    mbshp_option: "",
  },
  contract_from_date: "2023-08-01",
  contract_to_date: "2024-05-31",
  auto_payment_cancel: false,
  subs_paid_num: 0,
  subs_paid_total_price: 0,
  pay_per_month: 0,
  next_pay_date: "2023-10-01",
  contract_month: 8,
  remain_month: 8,
  pay_try_date: "",
  remark: "",
  is_expired: false,
  purchase_list: [],
  payment_plan_list: [],
};

export const getSubscriptionPurchaseAPi = async (
  storesDispatch,
  memberId,
  mbshpId,
  stateSetter,
) => {
  if (memberId === "" || mbshpId === "") {
    return;
  }
  HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/membership/subscription/purchase", {
    params: {
      member_id: memberId,
      membership_id: mbshpId,
    },
  })
    .then((res) => {
      if (!restEmpty(res.data.data)) {
        // console.log(res.data.data);
        stateSetter(res.data.data);
      }
    })
    .catch((err) => {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    });
};
