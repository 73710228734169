import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import MsgPreviewModal from "./MsgPreviewModal";

const LoadMsgContentModal = ({ modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();

  const [contentList, setContentList] = useState([]);
  const [curPage, setCurPage] = useState();
  const [totalPage, setTotalPage] = useState(1);

  const getContentList = (pageNum = 1) => {
    setCurPage(pageNum);

    HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/cs/sms/template/list", {
      params: {
        page_num: pageNum,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          // console.log(res.data.data);
          setContentList(res.data.data);
          setTotalPage(res.data.total_page);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  useEffect(() => {
    getContentList();
  }, []);

  const onClickRow = (id) => {
    HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/cs/sms/template/show", {
      params: {
        sms_id: id,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          storesDispatch(
            globalAlertOn({
              show: true,
              titleText: "내용 미리보기",
              bodyText: (
                <>
                  <MsgPreviewModal modalParam={res.data} />
                  <Label labelText="위의 문자를 불러오시겠습니까?" marginTop="2rem" />
                </>
              ),
              onBtnYes: () => {
                onModalDone("loadContent", res.data.data);
              },
              onBtnNo: () => {},
            }),
          );
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const onClickDelete = (data) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="다음의 저장된 메시지를 삭제하시겠습니까?" />
            <br />
            <Label
              labelText={"[" + data.sms_info.sms_name + "]"}
              fontWeight="bold"
              marginTop="0.5rem"
            />
          </>
        ),
        onBtnYes: () => {
          onDelete(data.sms_info.sms_id);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const onDelete = (id) => {
    HttpInstance.delete(process.env.REACT_APP_USER_SERVER + "/cs/sms/template/remove", {
      params: {
        sms_id: id,
      },
    })
      .then((res) => {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: <Label labelText="삭제되었습니다." />,
            onBtnOk: () => {},
          }),
        );
        getContentList();
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const contentListTable = new resultTable(onClickRow, onClickDelete);

  return (
    <div style={{ width: "50rem" }}>
      <CustomTable
        columns_head={contentListTable.columns_head}
        table_title={contentListTable.table_title}
        rest_call={contentListTable.get_data_from_rest}
        row_render={contentListTable.create_table}
        rest_data={contentList}
        // width="160rem"
      />
      <CustomPagination curPage={curPage} totalCnt={totalPage} doSearch={getContentList} />
    </div>
  );
};
export default LoadMsgContentModal;

//
class resultTable {
  table_title = "";
  onClickDelete = null;
  onClickRow = null;

  constructor(onClickRow, onClickDelete) {
    this.onClickRow = onClickRow;
    this.onClickDelete = onClickDelete;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow
        onClick={(e) => {
          if (e.target.nodeName === "DIV" || e.target.nodeName === "TD") {
            this.onClickRow(data.sms_info.sms_id);
          }
        }}
        key={idx}
      >
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.reg_info.reg_name}</CustomTableContents>
        <CustomTableContents>{data.sms_type}</CustomTableContents>
        <CustomTableContents>{data.sms_info.sms_name}</CustomTableContents>
        <CustomTableContentsEND>
          <IconButton type="delete" onClick={() => this.onClickDelete(data)} />
        </CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "등록일", width: "20%" },
    { title: "담당자", width: "15%" },
    { title: "발송정보", width: "15%" },
    { title: "제목", width: "40%" },
    { title: "", width: "10%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
