import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, generateObjectDummyId, getCardBuyer } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import NumberTextField from "components/LabelInput/NumberTextField";
import TextField from "components/LabelInput/TextField";
import TimePicker from "components/LabelInput/TimePicker";
import SubTitle from "components/PageTitle/SubTitle";
import { forwardRef, useCallback, useImperativeHandle, useState, useTransition } from "react";

const RefundSelector = forwardRef((props, ref) => {
  const {
    refundPayList, // 기본으로 가져올 환불지급수단
    setRefundPayList, // setter
    refundTotalPrice, // 지급 가능한 총 금액
    addedRefundTotalPrice,
    setAddedRefundTotalPrice,
    regDate,
    tableWidth,
  } = props;
  // const [refundAddedList, setRefundAddedList] = useState([]);
  const btnStyleClass = btnStyles();
  const [isPending, startTransition] = useTransition();
  const [remainTotalPrice, setRemainTotalPrice] = useState(refundTotalPrice); // 추가 입력 가능한 TotalPrice

  const [, updateState] = useState(); // 강제 렌더링용
  const forceUpdate = useCallback(() => updateState({}), []);

  useImperativeHandle(ref, () => ({
    // getRefundList: () => {
    //   return refundAddedList;
    // },
    clearList: () => {
      console.log("clear");
    },
  }));

  const btnPayMethodPush = () => {
    const baseInfo = {
      // view_id: uuidv4(),
      payment_id: generateObjectDummyId(),
      account_holder: "",
      approve_no: "",
      bank_acc: "",
      cancel_date: "",
      cancel_receipt: "",
      cancel_status: "",
      cancel_type: "",
      card_buyer: "",
      card_comp: "",
      card_no: "",
      cash_approve_no: "",
      installment: "0",
      is_done: false,
      is_hide: false,
      is_lock: false,
      is_method_change: "",
      is_valid: false,
      is_view: true,
      pay_date: "2022-01-01 00:00:00",
      pay_method: "",
      pay_receipt: "",
      price: 0,
      purchase_code: "",
      refund_method: "",
      remarks: "",
      selected: true,
    };

    const arrCopy = [...refundPayList];
    arrCopy.push(baseInfo);
    setRefundPayList(arrCopy);

    // if (refundAddedList === []) {
    //   setRefundAddedList([baseRefundInfo]);
    //   makeAddedRefundTotal(baseRefundInfo);
    // } else {
    //   setRefundAddedList([...refundAddedList, baseRefundInfo]);
    //   makeAddedRefundTotal([...refundAddedList, baseRefundInfo]);
    // }
  };

  const btnPayMethodPop = (targetId) => {
    var arrCopy = [...refundPayList];
    const index = arrCopy.map((item) => item.payment_id).indexOf(targetId);

    if (index !== -1) {
      arrCopy.splice(index, 1);
      setRefundPayList(arrCopy);

      // refundListState.splice(index, 1);
      // setRefundAddedList(array);
      // makeAddedRefundTotal(array);
    }
  };

  const getRefundFilter = () => {
    var array = [];

    if (refundPayList) {
      for (let i = 0; i < refundPayList.length; i++) {
        if (refundPayList[i].is_view) {
          array.push(refundPayList[i]);
        }
      }
    }
    return array;
  };

  // ??
  const makeAddedRefundTotal = (arrayList) => {
    let totalPrice = 0;
    if (arrayList === undefined) {
      arrayList = [...refundPayList];
    }

    for (let i = 0; i < arrayList.length; i++) {
      if (arrayList[i].selected) {
        totalPrice += Number(arrayList[i].price);
      }
    }
    setRemainTotalPrice(Number(refundTotalPrice) - Number(totalPrice));

    startTransition(() => {
      setAddedRefundTotalPrice(totalPrice);
    });
  };

  // console.log(refundListState);
  const refundList = new refundTable(
    btnPayMethodPop,
    makeAddedRefundTotal,
    regDate,
    remainTotalPrice,
    forceUpdate,
  );

  return (
    <div
      style={{
        marginTop: "1.5rem",
      }}
    >
      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText={"환불금 지급"} />
      </div>

      <CustomTable
        columns_head={refundList.columns_head}
        table_title={refundList.table_title}
        rest_call={refundList.get_data_from_rest}
        row_render={refundList.create_table}
        rest_data={getRefundFilter(refundPayList)}
        // rest_data_sub={refundAddedList}
        width={tableWidth}
      />

      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "1rem" }}>
        {/* <Label labelText="* 결제 금액 합계보다 적어야 합니다." fontSize="0.8rem" color="red" /> */}
        <TextField
          endAdornment={"원"}
          marginLeft="2rem"
          labelWidth={"6.5rem"}
          textBoxWidth={"8.563rem"}
          labelText="환불금액 합계"
          isNumber
          defaultValue={(refundTotalPrice + addedRefundTotalPrice).toLocaleString()}
          disabled
        />
      </div>
    </div>
  );
});
export default RefundSelector;

class refundTable {
  table_title = "";
  btnStyleClass = btnStyles();
  btnPayMethodPop = null;
  makeAddedRefundTotal = null;
  regDate = null;
  remainTotalPrice = 0;
  forceUpdate = null;
  cancel_type = ["환불완료", "환불요청"];
  method_item = ["카드", "현금", "이체", "기타"];
  method_item_cash = ["현금", "이체"];
  card_comp = ["테스트"];
  buyer = getCardBuyer();

  constructor(btnPayMethodPop, makeAddedRefundTotal, regDate, remainTotalPrice, forceUpdate) {
    this.btnPayMethodPop = btnPayMethodPop;
    this.makeAddedRefundTotal = makeAddedRefundTotal;
    this.regDate = regDate;
    this.remainTotalPrice = remainTotalPrice; // 선택된 환불금액 total
    this.forceUpdate = forceUpdate;
  }

  getPayDate = (type, data) => {
    if (type === "date") {
      return data.substr(0, 10);
    } else {
      return data.substr(11, 5);
    }
  };

  dateChangeMethod = (data, val) => {
    if (data.pay_date.length < 11) {
      data.pay_date = val + " 00:00:00";
    } else {
      data.pay_date = val + " " + data.pay_date.substr(11, 5) + ":00";
    }
  };

  timeChangeMethod = (data, val) => {
    data.cancel_date = this.regDate + " " + val;
  };

  create_table = (data, key) => {
    if (data.is_view === false) {
      return "";
    }
    // console.log(data.pay_method);

    return (
      <CustomRow key={key} style={{ width: "100%" }}>
        <CustomTableContents nopadding="true">
          <ComboBox
            labelMarginRight="0rem"
            marginRight="0rem"
            comboItemWidth={"6rem"}
            defaultValue={data.pay_method}
            data={this.method_item}
            disabled
            onChangeCallback={(e) => {
              data.pay_method = e.target.value;
            }}
          />
        </CustomTableContents>

        <CustomTableContents nopadding="true">
          <TextField
            justifyContent={"center"}
            labelMarginRight={"0rem"}
            textMarginRight={"0rem"}
            textBoxWidth={"6.5rem"}
            defaultValue={data.card_no}
            onChangeCallback={(e) => {
              data.card_no = e;
            }}
            isNumber={true}
            disabled
          />
        </CustomTableContents>

        <CustomTableContents nopadding="true">
          <NumberTextField
            endAdornment={"원"}
            justifyContent={"center"}
            labelMarginRight={"0rem"}
            textMarginRight={"0rem"}
            textBoxWidth={"7rem"}
            defaultValue={data.price}
            minValue={0}
            onBlur={(e) => {
              data.price = e.target.value;
              this.makeAddedRefundTotal();
            }}
            disabled
          />
        </CustomTableContents>

        <CustomTableContents nopadding="true">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <DatePicker
              textMarginRight={"0rem"}
              labelMarginRight={"0rem"}
              minWidth={"8.5rem"}
              defaultValue={this.regDate}
              onChangeCallback={(e) => {}}
              disabled
            />
            <TimePicker
              required
              labelMarginRight="0rem"
              textMarginRight={"0rem"}
              textBoxWidth="8.75rem"
              defaultValue={this.getPayDate("time", "")}
              onChange={(e) => {
                this.timeChangeMethod(data, e);
              }}
            />
          </div>
        </CustomTableContents>
        <CustomTableContents nopadding="true">
          <ComboBox
            required
            labelMarginRight="0rem"
            marginRight="0rem"
            comboItemWidth={"7rem"}
            data={this.cancel_type}
            onChangeCallback={(e) => {
              data.cancel_type = e.target.value;
              if (e.target.value !== "환불완료") {
                data.cancel_approve_no = "";
              }
              this.forceUpdate();
            }}
          />
        </CustomTableContents>
        <CustomTableContents nopadding="true">
          <TextField
            justifyContent={"center"}
            labelMarginRight={"0rem"}
            textMarginRight={"0rem"}
            textBoxWidth={"8rem"}
            maxLength={10}
            defaultValue={data.cancel_approve_no}
            onChangeCallback={(e) => {
              data.cancel_approve_no = e;
            }}
            isStringNumber
            disabled={data.cancel_type !== "환불완료"}
          />
        </CustomTableContents>

        <CustomTableContents nopadding="true">
          <TextField
            justifyContent={"center"}
            labelMarginRight={"0rem"}
            textMarginRight={"0rem"}
            textBoxWidth={"6rem"}
            defaultValue={data.account_name}
            onChangeCallback={(e) => {
              data.account_name = e;
            }}
            disabled={data.cancel_type !== "환불요청"}
          />
        </CustomTableContents>

        <CustomTableContents nopadding="true">
          <TextField
            justifyContent={"center"}
            labelMarginRight={"0rem"}
            textMarginRight={"0rem"}
            textBoxWidth={"8rem"}
            defaultValue={data.bank}
            onChangeCallback={(e) => {
              data.bank = e;
            }}
            disabled={data.cancel_type !== "환불요청"}
          />
        </CustomTableContents>

        <CustomTableContents nopadding="true">
          <TextField
            justifyContent={"center"}
            labelMarginRight={"0rem"}
            textMarginRight={"0rem"}
            textBoxWidth={"11rem"}
            defaultValue={data.bank_acc}
            onChangeCallback={(e) => {
              data.bank_acc = e;
            }}
            disabled={data.cancel_type !== "환불요청"}
          />
        </CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "환불 수단", width: "6%" },
    { title: "카드번호\n(앞 8자리)", width: "7%" },
    { title: "환불금액", width: "3%" },
    { title: "취소일시", width: "8%" },
    { title: "환불구분", width: "3%" },
    { title: "취소승인번호", width: "6%" },
    { title: "예금주", width: "5%" },
    { title: "은행", width: "10%" },
    { title: "계좌번호", width: "12%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
