import React from "react";

import Box from "@mui/material/Box";
import CardComp from "@mui/material/Card";

const Card = (props) => {
  const cardStyle = {
    alignContent: "space-between",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "100%",
  };

  const productStyle = {
    alignContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "100%",

    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  };

  const hStyle = {
    width: props.width,
    height: props.height,
    backgroundColor: props.backgroundColor,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #272D3B33",
    padding: "2%",
    margin: "1%",
  };

  const statusStyle = {
    background:
      "transparent linear-gradient(117deg, #e8ebf2 0%, #f2f3f7 100%) 0% 0% no-repeat padding-box",
    boxShadow: "20px 20px 30px #0f296b1f",
    border: "1px solid #ffffff80",
    borderRadius: "20px",
    opacity: "1",
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
    width: props.width,
    height: props.height,
  };

  const eventStyle = {
    background: props.backgroundColor,
    boxShadow: "0px 3px 6px #272D3B33",
    border: "1px solid #ffffff80",
    borderRadius: "20px",
    opacity: "1",
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
    width: props.width,
    height: props.height,
  };

  const NormalCard = () => {
    switch (props.type) {
      case "blank":
        return (
          <CardComp style={hStyle} variant="outlined">
            <div style={cardStyle}>
              {props.topDom}
              {props.midDom}
              {props.bottomDom}
            </div>
          </CardComp>
        );
      case "status":
        return (
          <CardComp style={statusStyle} variant="outlined">
            <div style={productStyle}>
              {props.topDom}
              {props.midDom}
              {props.bottomDom}
            </div>
          </CardComp>
        );
      case "event":
        return (
          <CardComp style={eventStyle} variant="outlined">
            <div style={cardStyle}>
              {props.topDom}
              {props.midDom}
              {props.bottomDom}
            </div>
          </CardComp>
        );
      default:
        return null;
    }
  };

  return (
    <div style={props.rootStyle}>
      <Box sx={{ minWidth: "100%" }}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <NormalCard />
        </div>
      </Box>
    </div>
  );
};

export default React.memo(Card);
