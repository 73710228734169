import { combineReducers } from "redux";

import {
  access_log_search,
  door_log_search,
} from "pages/AccessManage/MornitoringMange/Module/reducers/view_reducer";

import {
  access_device_list,
  access_device_schedule_list,
  access_schedule_preset_info,
  access_holyday_info,
} from "pages/AccessManage/DeviceManage/Module/reducers/view_reducer";

import {
  card_search_result,
  card_detail_info,
  card_list_info,
} from "pages/AccessManage/CardManage/Module/reducers/view_reducer";

export const accessServiceReducer = combineReducers({
  access_device_list,
  access_device_schedule_list,
  access_schedule_preset_info,
  access_holyday_info,

  card_search_result,
  card_detail_info,
  card_list_info,

  access_log_search,
  door_log_search,
});
