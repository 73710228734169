import IconButton from "components/Button/IconButton";
import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import TextField from "components/LabelInput/TextField";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import { HttpInstance } from "lib/HttpLib";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import LockerSelectModal from "./LockerSelectModal";

const LockerBrokenUserModal = ({ modalParam, onModalDone }) => {
  //modalParam: selectedBranch
  const storesDispatch = useDispatch();
  const viewModelRef = useRef();
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });
  const [curPage, setCurPage] = useState();
  const [totalPage, setTotalPage] = useState(1);
  const [brokenList, setBrokenList] = useState([]);

  const getBrokenLockerList = async (page) => {
    setCurPage(page);
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_BRANCH_SERVER + "/locker/broken_list",
        {
          params: {
            page_num: curPage,
            branch_id: modalParam.selectedBranch.branch_id,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setBrokenList(res.data.data);
        setTotalPage(res.data.total_page);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  //1. 락커배정모달 POP
  const popAssignLockerModal = (param) => {
    setModalInfo({
      show: true,
      title: "락커 배정",
      Content: <LockerSelectModal modalParam={param} onModalDone={lockerAssignModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  //1-1. 락커배정모달 DONE
  const lockerAssignModalDone = (newLockerInfo) => {
    //newLockerInfo : branch_id, floor_id,zone_id, locker_total_idx, locker_no
    getBrokenLockerList(1); //고장락커리스트 갱신
    onModalDone(newLockerInfo.branch_id, newLockerInfo.floor_id, newLockerInfo.zone_id); //섹션2 구락리 갱신
    viewModelRef.current.setClose(); //모달닫기
  };

  //초기 랜더링 리스트 GET
  useEffect(() => {
    if (modalParam && modalParam.selectedBranch.branch_id) {
      getBrokenLockerList(1);
    }
  }, [modalParam]);

  //페이지네이션
  useEffect(() => {
    if (curPage) {
      getBrokenLockerList(curPage);
    }
  }, [curPage]);

  const brokenLockerUserList = new brokenLockerUserTable(popAssignLockerModal);
  return (
    <div
      style={{
        width: "68rem",
      }}
    >
      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
      <div>
        <TextField
          labelWidth={"3rem"}
          textBoxWidth={"12rem"}
          labelText="지점"
          defaultValue={modalParam && modalParam.selectedBranch.branch_name}
          disabled
        />

        <div style={{ marginTop: "0.5rem" }}>
          <CustomTable
            columns_head={brokenLockerUserList.columns_head}
            table_title={brokenLockerUserList.table_title}
            rest_call={brokenLockerUserList.get_data_from_rest}
            row_render={brokenLockerUserList.create_table}
            rest_data={brokenList}
          />
          <CustomPagination curPage={curPage} doSearch={setCurPage} totalCnt={totalPage} />
        </div>
      </div>
    </div>
  );
};
export default LockerBrokenUserModal;

class brokenLockerUserTable {
  table_title = "";

  popAssignLockerModal = null;

  constructor(popAssignLockerModal) {
    this.popAssignLockerModal = popAssignLockerModal;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.floor_name}</CustomTableContents>
        <CustomTableContents>{data.zone_name}</CustomTableContents>
        <CustomTableContents>{data.locker_no}</CustomTableContents>
        <CustomTableContents>{data.locker_info.member_info.member_no}</CustomTableContents>
        <CustomTableContents>
          {data.locker_info.member_info.member_name.length < 6
            ? data.locker_info.member_info.member_name
            : data.locker_info.member_info.member_name.slice(0, 6) + "..."}
        </CustomTableContents>
        <CustomTableContents>{data.locker_info.member_info.phone}</CustomTableContents>
        <CustomTableContents>{data.mbshp_status}</CustomTableContents>
        <CustomTableContents>{data.mbshp_to_date}</CustomTableContents>
        <CustomTableContentsEND align="center">
          <IconButton
            type="locker"
            onClick={() => {
              this.popAssignLockerModal({
                mode: "edit",
                member_info: data.locker_info.member_info,
                branch_id: data.branch_info.branch_id,
                zone_id: data.zone_id,
                locker_total_idx: data.locker_info.locker_total_idx,
                locker_no: data.locker_no,
              });
            }}
          />
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "층", width: "9%" },
    { title: "구역", width: "12%" },
    { title: "락커번호", width: "12%" },
    { title: "회원번호", width: "12%" },
    { title: "회원명", width: "12%" },
    { title: "연락처", width: "12%" },
    { title: "회원권 수강 상태", width: "12%" },
    { title: "유효기간 종료일", width: "12%" },
    { title: "락커변경", width: "7%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
