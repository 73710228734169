import { takeLatest, takeEvery } from "redux-saga/effects";

import { staffSearchAction } from "pages/Staff/StaffList/Module/actions/action_type";
import { getStaffSearchResultData } from "pages/Staff/StaffList/Module/sagas/view_sagas";

import * as staffViewAction from "pages/Staff/StaffInfo/Module/actions/action_type";
import {
  getStaffInfoViewData,
  getWorkInfoListData,
  getWorkInfoDetailData,
  getGxFeeData,
} from "pages/Staff/StaffInfo/Module/sagas/view_sagas";

import { salaryListAction } from "pages/Staff/SalaryList/Module/actions/action_type";
import { getSalaryListData } from "pages/Staff/SalaryList/Module/sagas/view_sagas";

import {
  salaryInfoViewAction,
  salaryStatusViewAction,
  staffStandardSalaryViewAction,
  mbshpSalaryViewAction,
  gxSalaryViewAction,
  ptSalaryViewAction,
  etcSalaryViewAction,
  classSessionViewAction,
} from "pages/Staff/SalaryInfo/Module/actions/action_type";
import {
  getSalaryInfoViewData,
  getSalaryStatusData,
  getStaffStandardSalaryStatusData,
  getMbshpSalaryData,
  getGxSalaryData,
  getPtSalaryData,
  getEtcSalaryData,
  getClassSessionData,
} from "pages/Staff/SalaryInfo/Module/sagas/view_sagas";

// import { classGlobalStat } from "pages/Staff/Class/ClassStat/Module/actions/stat_act_type";
// import { getClassStatResultData } from "pages/Staff/Class/ClassStat/Module/sagas/view_sagas";

import {
  searchPtClassHist,
  searchOtClassHist,
} from "pages/Staff/Class/ClassHist/Module/actions/action_type";
import {
  getSearchPtClassHist,
  getSearchOtClassHist,
} from "pages/Staff/Class/ClassHist/Module/sagas/view_sagas";

import {
  searchOtManageList,
  searchPtManageList,
  ptDetailInfo,
  classByStaffInfo,
} from "pages/Staff/Class/ClassManage/Module/actions/action_type";
import {
  getSearchOtManageList,
  getSearchPtManageList,
  getPtDetailInfoData,
  getClassByStaffInfoData,
} from "pages/Staff/Class/ClassManage/Module/sagas/view_sagas";

export function* staffServiceSaga() {
  yield takeLatest(staffSearchAction, getStaffSearchResultData);

  yield takeLatest(staffViewAction.staffInfoViewAction, getStaffInfoViewData);
  yield takeLatest(staffViewAction.staffWorkListViewAction, getWorkInfoListData);
  yield takeLatest(staffViewAction.staffWorkInfoViewAction, getWorkInfoDetailData);
  yield takeLatest(staffViewAction.staffGxFeeInfoViewAction, getGxFeeData);

  yield takeLatest(salaryListAction, getSalaryListData);

  yield takeLatest(salaryInfoViewAction, getSalaryInfoViewData);
  yield takeLatest(salaryStatusViewAction, getSalaryStatusData);
  yield takeLatest(staffStandardSalaryViewAction, getStaffStandardSalaryStatusData);
  yield takeLatest(mbshpSalaryViewAction, getMbshpSalaryData);
  yield takeLatest(gxSalaryViewAction, getGxSalaryData);
  yield takeLatest(ptSalaryViewAction, getPtSalaryData);

  yield takeLatest(etcSalaryViewAction, getEtcSalaryData);
  yield takeLatest(classSessionViewAction, getClassSessionData);

  yield takeLatest(searchPtClassHist, getSearchPtClassHist);
  yield takeLatest(searchOtClassHist, getSearchOtClassHist);

  yield takeLatest(searchOtManageList, getSearchOtManageList);
  yield takeLatest(searchPtManageList, getSearchPtManageList);
  yield takeLatest(ptDetailInfo, getPtDetailInfoData);
  yield takeLatest(classByStaffInfo, getClassByStaffInfoData);
}
