import {
  changeComboValueToEmpty,
  checkDateOrder,
  getDefaultValue,
  simpleAlert,
  SubLine,
} from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import NormalBtn from "components/NewButton/NormalBtn";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { SearchConditionBox, SearchContainer, SearchRowBox } from "../style";

const SearchCardRow1 = ({ searchParams, setSearchParams, onClickSearch }) => {
  const labelWidth = "5.5rem";
  const textBoxWidth = "14rem";
  const labelWidthShort = "4rem";
  const phoneInputBoxWidth = "5.5rem";
  const phone = useRef({
    p1: searchParams.p1,
    p2: searchParams.p2,
    p3: searchParams.p3,
  });
  const phoneRef1 = useRef();
  const phoneRef2 = useRef();
  const phoneRef3 = useRef();

  // 전화번호 입력 처리
  const onInputPhone = () => {
    let res = "";

    if (searchParams.p1 && !searchParams.p2 && searchParams.p3) {
      res = "err";
    } else {
      res = searchParams.p1 + "-" + searchParams.p2 + "-" + searchParams.p3;
    }
    res = res.replace("--", "-");

    const newMemberBase = { ...searchParams.member_base, phone: res };
    setSearchParams("member_base", newMemberBase);
  };

  // 전화 번호 변경 체크
  useEffect(() => {
    onInputPhone();
  }, [searchParams.p1, searchParams.p2, searchParams.p3]);

  return (
    <SearchContainer>
      <Label labelText="검색 조건: 회원 정보" fontWeight="bold" justifyContent="flex-start" />

      <SearchRowBox
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onClickSearch(1);
          }
        }}
      >
        <SearchConditionBox>
          <TextField
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth}
            labelText="회원번호"
            defaultValue={getDefaultValue("field", searchParams.member_base.member_no)}
            onChangeCallback={(e) => {
              const newMemberBase = { ...searchParams.member_base, member_no: e };
              setSearchParams("member_base", newMemberBase);
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox>
          <TextField
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth}
            labelText="이름"
            defaultValue={getDefaultValue("field", searchParams.member_base.member_name)}
            onChangeCallback={(e) => {
              const newMemberBase = { ...searchParams.member_base, member_name: e };
              setSearchParams("member_base", newMemberBase);
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox>
          <TextField
            inputRef={phoneRef1}
            labelWidth={labelWidthShort}
            textBoxWidth={phoneInputBoxWidth}
            textMarginRight="0.1rem"
            maxLength={3}
            defaultValue={getDefaultValue("field", searchParams.p1)}
            labelText="연락처"
            onChangeCallback={(e) => {
              setSearchParams("p1", e);
              phone.current.p1 = e;
              if (phone.current.p1.length === 3) {
                phoneRef2.current.select();
              }
            }}
          />
          <TextField
            inputRef={phoneRef2}
            textBoxWidth={phoneInputBoxWidth}
            textMarginRight="0.1rem"
            labelMarginRight="0.1rem"
            maxLength={4}
            labelText="-"
            defaultValue={getDefaultValue("field", searchParams.p2)}
            onChangeCallback={(e) => {
              setSearchParams("p2", e);
              phone.current.p2 = e;
              if (phone.current.p2.length === 4) {
                phoneRef3.current.select();
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Backspace" && !phone.current.p2) {
                phoneRef1.current.select();
              }
            }}
          />
          <TextField
            inputRef={phoneRef3}
            textBoxWidth={phoneInputBoxWidth}
            textMarginRight="0.1rem"
            labelMarginRight="0.1rem"
            maxLength={4}
            labelText="-"
            defaultValue={getDefaultValue("field", searchParams.p3)}
            onChangeCallback={(e) => {
              setSearchParams("p3", e);
              phone.current.p3 = e;
            }}
            onKeyDown={(e) => {
              if (e.key === "Backspace" && !phone.current.p3) {
                phoneRef2.current.select();
              }
            }}
          />
        </SearchConditionBox>
      </SearchRowBox>
    </SearchContainer>
  );
};

const SearchCardRow2 = ({ searchParams, setSearchParams, branchInfo, onClickSearch }) => {
  const labelWidth = "5.5rem";
  const textBoxWidth = "14rem";
  const labelWidthShort = "4rem";
  const textBoxWidthShort = "8.5rem";

  return (
    <SearchContainer>
      <Label labelText="검색 조건: PT 상세" fontWeight="bold" justifyContent="flex-start" />
      <SearchRowBox
        onKeyDown={(e) => {
          if (e.key === "Enter") onClickSearch(1);
        }}
      >
        <SearchConditionBox>
          <SearchComboBox
            labelWidth={labelWidth}
            comboItemWidth={textBoxWidth}
            data={branchInfo.branch_name_list}
            labelText="지점"
            defaultValue={getDefaultValue("combo", searchParams.branch_info.branch_name)}
            onChangeCallback={(e, v, idx) => {
              const branchParams = branchInfo.branch_list[idx];
              setSearchParams("branch_info", branchParams);
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox>
          <TextField
            labelText="수업 담당자"
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth}
            defaultValue={getDefaultValue("field", searchParams.staff_text_name)}
            onChangeCallback={(e) => {
              setSearchParams("staff_text_name", e);
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox>
          <DatePicker
            labelWidth={labelWidthShort}
            textBoxWidth={textBoxWidthShort}
            textMarginRight="0rem"
            labelText="수업일"
            defaultValue={getDefaultValue("field", searchParams.class_from_date)}
            onChangeCallback={(e) => {
              setSearchParams("class_from_date", e);
            }}
            onBlur={(e) => {
              if (!checkDateOrder(e, searchParams.class_to_date)) {
                setSearchParams("class_from_date", "");
              }
            }}
          />
          <DatePicker
            marginLeft="0.1rem"
            labelWidth="0.1rem"
            textBoxWidth={textBoxWidthShort}
            labelText="~"
            defaultValue={getDefaultValue("field", searchParams.class_to_date)}
            onChangeCallback={(e) => {
              setSearchParams("class_to_date", e);
            }}
            onBlur={(e) => {
              if (!checkDateOrder(searchParams.class_from_date, e)) {
                setSearchParams("class_to_date", "");
              }
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox>
          <ComboBox
            marginLeft="2rem"
            labelWidth={labelWidth}
            comboItemWidth={textBoxWidth}
            defaultValue={getDefaultValue("combo", searchParams.class_result_type)}
            data={["전체", "수업완료", "노쇼"]}
            labelText="완료 구분"
            onChangeCallback={(e) => {
              changeComboValueToEmpty(setSearchParams, "class_result_type", e);
            }}
          />
        </SearchConditionBox>
      </SearchRowBox>
    </SearchContainer>
  );
};

const PtHistSearchCard = ({
  searchParams,
  setSearchParams,
  resetSearchParams,
  onClickSearch,
  branchInfo,
}) => {
  const storesDispatch = useDispatch();

  // 두 날짜 사이의 차이 계산
  const calcPeriodDays = (fromDate, toDate) => {
    const date1 = new Date(fromDate);
    const date2 = new Date(toDate);

    const periodMS = Math.abs(date2 - date1);

    const periodDays = Math.floor(periodMS / (24 * 60 * 60 * 1000));
    return periodDays;
  };

  const validateMakeExcel = () => {
    if (searchParams.class_from_date === "" || searchParams.class_to_date === "") {
      simpleAlert(storesDispatch, "알림", "시작일과 종료일을 입력해주세요.");
      return;
    }
    if (calcPeriodDays(searchParams.class_from_date, searchParams.class_to_date) > 30) {
      simpleAlert(storesDispatch, "알림", "조회 기간은 31일 이내로 선택해주세요.");
      return;
    }

    simpleAlert(
      storesDispatch,
      "알림",
      `"${searchParams.branch_info.branch_name}_${searchParams.class_from_date}_${searchParams.class_to_date}_PT 수업 이력"\n 엑셀을 다운로드 하시겠습니까?`,
      false,
      undefined,
      () => {
        onClickSearch(1, true);
      },
      () => {},
    );
  };
  return (
    <div>
      <SearchCardRow1
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        onClickSearch={onClickSearch}
      />
      <SearchCardRow2
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        onClickSearch={onClickSearch}
        branchInfo={branchInfo}
      />
      <div
        style={{
          marginTop: "0.7rem",
          display: "flex",
          justifyContent: "flex-end",
          gap: "0.3rem",
        }}
      >
        <NormalBtn
          name="초기화"
          theme="white"
          width="6.25rem"
          onClick={() => {
            resetSearchParams();
          }}
        />
        <NormalBtn
          name="검색"
          width="6.25rem"
          onClick={() => {
            onClickSearch(1);
          }}
        />
      </div>
      <SubLine />
      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <NormalBtn
          name="엑셀 다운로드"
          theme="green"
          onClick={() => {
            validateMakeExcel();
          }}
        />
      </div>
    </div>
  );
};
export default PtHistSearchCard;
