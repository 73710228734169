import { dataEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { useState } from "react";
import { useDispatch } from "react-redux";

const AssignTable = ({ searchResult, onModalDone, onClickSearch, curPage, totalPage }) => {
  const storesDispatch = useDispatch();

  const [selectedMember, setSelectedMember] = useState({
    data: {},
    idx: "",
  });

  //row를 클릭하면 => 해당 row 색깔 selected하고 상태로 잡아주기
  const onRowSelect = (data, idx) => {
    setSelectedMember((cur) => {
      return { ...cur, data, idx };
    });
  };

  const searchResultList = new searchResultTable(selectedMember, onRowSelect);

  const checkUnpaidInfo = async (memberBranchId, memberId, mode) => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_USER_SERVER + "/change_assign/check_unpaid",
        {
          params: {
            member_id: memberId,
          },
        },
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
        return;
      }

      // if (mode === "change_branch") {
      //   const res2 = await HttpInstance.get(
      //     process.env.REACT_APP_USER_SERVER + "/change/branch_check",
      //     {
      //       params: {
      //         member_branch_id: memberBranchId,
      //         branch_id: memberId,
      //       },
      //     },
      //   );
      //   if (res2.data.msg) {
      //     simpleAlert(storesDispatch, "알림", res2.data.msg);
      //     return;
      //   }
      // }

      onModalDone(mode, selectedMember.data);
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  const onAssign = () => {
    if (_.isEmpty(selectedMember.data)) {
      simpleAlert(storesDispatch, "알림", "양도 회원을 선택해주세요.");
    } else {
      checkUnpaidInfo(
        selectedMember.data.branch_info.branch_id,
        selectedMember.data.member_info.member_id,
        "assign",
      );
    }
  };

  //지점변경
  const onChangeBranch = () => {
    console.log(selectedMember);
    if (_.isEmpty(selectedMember.data)) {
      simpleAlert(storesDispatch, "알림", "지점을 변경하실 회원을 선택해주세요.");
    } else {
      checkUnpaidInfo(
        selectedMember.data.branch_info.branch_id,
        selectedMember.data.member_info.member_id,
        "change_branch",
      );
    }
  };

  return (
    <div>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
      />
      <CustomPagination curPage={curPage} doSearch={onClickSearch} totalCnt={totalPage} />

      <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem", gap: "0.7rem" }}>
        <NormalBtn name="양도" theme="white" onClick={() => onAssign()} />
        <NormalBtn name="지점변경" theme="white" onClick={() => onChangeBranch()} />
      </div>
    </div>
  );
};
export default AssignTable;

class searchResultTable {
  table_title = "";
  selectedMember = null;
  onRowSelect = null;

  constructor(selectedMember, onRowSelect) {
    this.selectedMember = selectedMember;
    this.onRowSelect = onRowSelect;
  }

  onClickEvent = (data, idx) => {
    this.onRowSelect(data, idx);
  };

  create_table = (data, idx) => {
    return (
      <CustomRow
        style={{
          backgroundColor: this.selectedMember.idx === idx ? "#ebf1ff" : "",
        }}
        key={idx}
        onClick={() => {
          this.onClickEvent(data, idx);
        }}
      >
        <CustomTableContents
          align="center"
          style={{ backgroundColor: this.selectedMember.idx === idx ? "#ebf1ff" : "" }}
        >
          {data.branch_info.branch_name}
        </CustomTableContents>
        <CustomTableContents
          align="center"
          style={{ backgroundColor: this.selectedMember.idx === idx ? "#ebf1ff" : "" }}
        >
          {data.member_info.member_no}
        </CustomTableContents>
        <CustomTableContents
          align="center"
          style={{ backgroundColor: this.selectedMember.idx === idx ? "#ebf1ff" : "" }}
        >
          {data.member_info.member_name}
        </CustomTableContents>
        <CustomTableContents
          align="center"
          style={{ backgroundColor: this.selectedMember.idx === idx ? "#ebf1ff" : "" }}
        >
          {data.base_info.phone}
        </CustomTableContents>
        <CustomTableContentsEND
          align="center"
          style={{ backgroundColor: this.selectedMember.idx === idx ? "#ebf1ff" : "" }}
        >
          {data.access.card_no ? data.access.card_no : ""}
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "지점", width: "20%" },
    { title: "회원번호", width: "20%" },
    { title: "회원명", width: "20%" },
    { title: "연락처", width: "20%" },
    { title: "카드번호", width: "20%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
