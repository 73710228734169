export const staffStandardSalaryState = {
  stores: {
    data: [
      {
        etc_salary: {
          salary_info: {
            deduct_reason: "",
            final_salary: 0,
            salary: 0,
            salary_date: "",
            salary_deduct: 0,
          },
        },
        gx_salary: {
          class_list: [
            {
              class_cnt: 0,
              class_hist: [
                {
                  from_date: "",
                  from_time: "",
                },
              ],
              class_name: "",
              standard_price: 0,
              total_salary: 0,
            },
          ],
          salary_info: {
            deduct_reason: "",
            final_salary: 0,
            salary: 0,
            salary_date: "",
            salary_deduct: 0,
          },
        },
        mbshp_salary: {
          salary_info: {
            deduct_reason: "",
            final_salary: 0,
            salary: 0,
            salary_date: "",
            salary_deduct: 0,
          },
          sales_cnt: 0,
          sales_incentive: 0,
          sales_total: 0,
        },
        performance_info: {
          is_staff_check_up: false,
          staff_performance_id: "",
        },
        personal_info: {
          account_holder: "",
          account_no: "",
          address_1: "",
          address_2: "",
          bank: "",
          birthday: "",
          email: "",
          gender: "",
          phone: "",
          sabun: "",
          social_number: "",
          staff_name: "",
          verify: false,
          zip_code: "",
        },
        pt_salary: {
          class_info: {
            class_allowance: 0,
            class_cnt: 0,
            class_commission: 0,
            class_session_total: 0,
          },
          salary_info: {
            deduct_reason: "",
            final_salary: 0,
            salary: 0,
            salary_date: "",
            salary_deduct: 0,
          },
          sales_info: {
            sales_allowance: 0,
            sales_commission: 0,
            sales_total: 0,
          },
        },
        salary_info_total: {
          deduct_reason: "",
          final_salary: 0,
          salary: 0,
          salary_date: "",
          salary_deduct: 0,
        },
        salary_summary_all_type: {
          base_salary_total: 0,
          etc_total_salary: 0,
          gx_total_class: 0,
          mbshp_total_salary: 0,
          pt_total_class: 0,
          pt_total_commission: 0,
          salary_total: 0,
        },
        staff_id: "",
        staff_info: {
          staff_id: "",
          staff_name: "",
          staff_pos: "",
          staff_rank: "",
        },
        standard_salary: {
          salary_info: {
            deduct_reason: "",
            final_salary: 0,
            salary: 0,
            salary_date: "",
            salary_deduct: 0,
          },
          salary_month: 0,
          salary_time: 0,
          total_work_time: 0,
          work_info: {
            wage: 0,
            wage_type: "",
          },
        },
        summary_info: {
          gx_use_cnt: 0,
          mbshp_incentive_cnt: 0,
          mbshp_total_sales: 0,
          pt_total_sales: 0,
          sales_total: 0,
        },
      },
    ],
    final_total_salary: 0,
  },
};
