import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";

const PtHistSearchResult = ({ curPage, setCurPage, totalPage, searchResult }) => {
  const searchResultList = new searchResultTable();
  return (
    <div style={{ marginTop: "0.5rem" }}>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
      />
      <CustomPagination curPage={curPage} doSearch={setCurPage} totalCnt={totalPage} />
    </div>
  );
};
export default PtHistSearchResult;

class searchResultTable {
  table_title = "";
  getAge = (birth) => {
    const thisYear = new Date().getFullYear();
    const birthYear = new Date(birth).getFullYear();
    let age = thisYear - birthYear + 1;
    return age;
  };

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.membership_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.membership_info.member_name}</CustomTableContents>
        <CustomTableContents>{data.membership_info.gender}</CustomTableContents>

        <CustomTableContents>{data.membership_info.phone}</CustomTableContents>
        <CustomTableContents>{this.getAge(data.membership_info.birth)}</CustomTableContents>
        <CustomTableContents>{data.pt_info.item_name}</CustomTableContents>

        <CustomTableContents>{data.pt_info.purchase_date}</CustomTableContents>
        <CustomTableContents>{data.pt_info.join_type}</CustomTableContents>
        <CustomTableContents>{data.class_result_type}</CustomTableContents>

        <CustomTableContents>
          {`${
            data.pt_info.base_session_cnt +
            data.pt_info.service_session_cnt -
            data.pt_info.remain_session_cnt
          } / ${data.pt_info.base_session_cnt + data.pt_info.service_session_cnt}`}
        </CustomTableContents>
        <CustomTableContents>{data.class_done_date}</CustomTableContents>
        <CustomTableContents>{data.cur_manager.staff_info.staff_name}</CustomTableContents>

        <CustomTableContentsEND align="center">{data.comment}</CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "회원번호", width: "7.6%" },
    { title: "이름", width: "7.6%" },
    { title: "성별", width: "4.6%" },
    { title: "연락처", width: "8.6%" },
    { title: "나이", width: "4.6%" },
    { title: "PT 계약상품명", width: "12.2%" },
    { title: "계약일", width: "8.6%" },
    { title: "등록구분", width: "8.6%" },
    { title: "완료구분", width: "7.6%" },
    { title: "진행회차", width: "7.6%" },
    { title: "수업일", width: "6.6%" },
    { title: "수업 담당자", width: "7.6%" },
    { title: "코멘트", width: "7%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
