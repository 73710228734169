import { contentTypeJson, simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import ModalBtnModeGroup from "components/ModalBtnModeGroup/ModalBtnModeGroup";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useState } from "react";
import { useDispatch } from "react-redux";

const HQPolicySalaryPerEdit = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  // console.log(modalParam);
  const [percent, setPercent] = useState({
    card: modalParam.salesInfoStore.cms_by_pay_method.card,
    cash: modalParam.salesInfoStore.cms_by_pay_method.cash,
  });
  const [loading, setLoading] = useState(false);

  const onClickSave = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="저장하시겠습니까?" />
          </>
        ),
        // width: "50rem",
        onBtnYes: () => {
          onSaveEvent();
        },
        onBtnNo: () => {},
      }),
    );
  };

  const calcPercent = (type) => {
    if (type === "cash") {
      const res = Number(
        (
          modalParam.salesInfoStore.cms_by_pay_method.cash_numerator /
          modalParam.salesInfoStore.cms_by_pay_method.cash_denominator
        ).toFixed(10),
      );
      setPercent({ ...percent, cash: res });
      modalParam.salesInfoStore.cms_by_pay_method.cash = res;
    } else {
      const res = Number(
        (
          modalParam.salesInfoStore.cms_by_pay_method.card_numerator /
          modalParam.salesInfoStore.cms_by_pay_method.card_denominator
        ).toFixed(10),
      );
      setPercent({ ...percent, card: res });
      modalParam.salesInfoStore.cms_by_pay_method.card = res;
    }
  };

  const onSaveEvent = async () => {
    console.log(modalParam.salesInfoStore);
    console.log(modalParam.hq_info);

    const formData = new FormData();
    formData.append("info_data", JSON.stringify(modalParam.salesInfoStore));
    formData.append("hq_info", JSON.stringify(modalParam.hq_info));

    setLoading(true);

    await HttpInstance.post(
      process.env.REACT_APP_BRANCH_SERVER + "/hq_set/cms_pay/update",
      formData,
      contentTypeJson,
    )
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          onModalDone(modalParam.refreshStore);
        }
      })
      .catch((err) => {
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
        // onModalDone(modalParam.refreshStore);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        width: "23rem",
      }}
    >
      {loading && <Loading />}

      <ModalBtnModeGroup mode={"edit"} onClickEvent={onClickSave} />

      <div style={{ marginTop: "0.625rem" }}>
        <TextField
          labelWidth={"5rem"}
          textBoxWidth={"17rem"}
          labelText="지점명"
          defaultValue={modalParam.salesInfoStore.branch_info.branch_name}
          disabled
        />
      </div>

      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <TextField
          labelWidth={"5rem"}
          textBoxWidth={"4rem"}
          labelMarginLeft="0rem"
          textMarginRight="0.25rem"
          labelText="현금 매출"
          maxLength={3}
          isNumber
          defaultValue={modalParam.salesInfoStore.cms_by_pay_method.cash_numerator}
          onChangeCallback={(e) => {
            modalParam.salesInfoStore.cms_by_pay_method.cash_numerator = e;
            // modalParam.salesInfoStore.cms_by_pay_method.cash = e;
            calcPercent("cash");
          }}
          // disabled
        />
        <Label textAlign={"left"} labelText="/" />
        <TextField
          textBoxWidth={"4rem"}
          labelMarginLeft="0rem"
          labelMarginRight="0rem"
          maxLength={3}
          isNumber
          defaultValue={modalParam.salesInfoStore.cms_by_pay_method.cash_denominator}
          onChangeCallback={(e) => {
            modalParam.salesInfoStore.cms_by_pay_method.cash_denominator = e;
            // modalParam.salesInfoStore.cms_by_pay_method.cash = e;
            // setPercent({
            //   ...percent,
            //   cash: 0
            // });
            calcPercent("cash");
          }}
          // disabled
        />
        <Label textAlign={"left"} labelText={"(" + percent.cash * 100 + "%)"} />
      </div>

      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <TextField
          labelWidth={"5rem"}
          textBoxWidth={"4rem"}
          labelMarginLeft="0rem"
          textMarginRight="0.25rem"
          labelText="카드 매출"
          maxLength={3}
          isNumber
          defaultValue={modalParam.salesInfoStore.cms_by_pay_method.card_numerator}
          onChangeCallback={(e) => {
            modalParam.salesInfoStore.cms_by_pay_method.card_numerator = e;
            calcPercent("card");
          }}
          // disabled
        />
        <Label textAlign={"left"} labelText="/" />
        <TextField
          textBoxWidth={"4rem"}
          labelMarginLeft="0rem"
          labelMarginRight="0rem"
          maxLength={3}
          isNumber
          defaultValue={modalParam.salesInfoStore.cms_by_pay_method.card_denominator}
          onChangeCallback={(e) => {
            modalParam.salesInfoStore.cms_by_pay_method.card_denominator = e;
            // modalParam.salesInfoStore.cms_by_pay_method.cash = e;
            // setPercent({
            //   ...percent,
            //   cash: 0
            // });
            calcPercent("card");
          }}
          // disabled
        />
        <Label textAlign={"left"} labelText={"(" + percent.card * 100 + "%)"} />
      </div>
    </div>
  );
};
export default HQPolicySalaryPerEdit;
