import {
  generateObjectZeroId,
  getPermission,
  restEmpty,
  simpleAlert,
  SubLine,
} from "components/CommonLib/CommonLib";
import Loading from "components/Loading/Loading/Loading";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useMemberLocationStore } from "../modules/store/memberLocationState";
import CreatePopup from "./CreatePopup";
import MemberLocationStatsResultTable from "./MemberLocationStatsResultTable";
import SearchCondition from "./SearchCondition";

const MemberLocationStatsView = ({ isAdmin }) => {
  const storesDispatch = useDispatch();
  // 전역 상태 관리
  const {
    memberLocationSearchParams: searchParams,
    setSearchParams,
    memberLocationPage: page,
    setPage,
    resetSearchParams,
  } = useMemberLocationStore();

  // 페이지 상태 관리
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [resetFlag, setResetFlag] = useState(false);

  const [branchList, setBranchList] = useState([]);

  useEffect(() => {
    getBranchList();
  }, []);

  // 지점 권한 받아오기
  const getBranchList = async () => {
    const branchResult = await getPermission(
      storesDispatch,
      "ACCESS_MENU/MANAGE",
      true,
      true,
      true,
    );
    setBranchList(branchResult);
    if (
      branchResult.branch_list.length > 0 &&
      searchParams.branch_info.branch_id === generateObjectZeroId()
    ) {
      setSearchParams("branch_info", branchResult.branch_list[0]);
    }
  };

  // modal 변수
  const viewModelRef = useRef();
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });

  // 회원 위치 통계 리스트 가져오기
  const onClickSearch = async (page) => {
    if (searchParams.branch_info.branch_id === "") {
      simpleAlert(storesDispatch, "알림", "지점을 선택해주세요.");
      return;
    }

    setLoading(true);

    HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/statistics/location", {
      params: {
        branch_id: searchParams.branch_info.branch_id,
        created_at_from: searchParams.created_at_from,
        created_at_to: searchParams.created_at_to,
        reg_type: searchParams.reg_type,
        mbshp_option: searchParams.mbshp_option,
      },
    })
      .then((res) => {
        if (restEmpty(res.data.msg)) {
          setSearchResult(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  ////////

  const onModalDone = (data) => {
    viewModelRef.current.setClose();

    console.log("done:", data);
  };
  ////////////

  const openCreatePopup = () => {
    const param = {
      getBranchList,
    };

    setModalInfo({
      show: true,
      title: "위치 통계 데이터 생성",
      Content: <CreatePopup modalParam={param} onModalDone={onModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  // 페이지 진입 시 결과 받아오기
  useEffect(() => {
    onClickSearch(1);
  }, []);

  return (
    <div>
      {loading ? <Loading /> : <></>}

      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />

      <div>
        <SearchCondition
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          branchList={branchList}
          onClickSearch={onClickSearch}
          resetFlag={resetFlag}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "0.75rem",
            gap: "0.3rem",
          }}
        >
          <NormalBtn
            name="초기화"
            theme="white"
            width="6.25rem"
            onClick={() => {
              resetSearchParams();
              setResetFlag(!resetFlag);
            }}
          />
          <NormalBtn
            name="검색"
            width="6.25rem"
            onClick={() => {
              onClickSearch(1);
            }}
          />
        </div>
      </div>

      <SubLine />
      <div style={{ display: "flex", justifyContent: "end", marginBottom: "0rem" }}>
        <NormalBtn
          name="생성"
          width="6.25rem"
          onClick={() => {
            openCreatePopup();
          }}
        />
      </div>

      <div style={{ marginTop: "0.5rem" }}>
        <MemberLocationStatsResultTable
          searchResult={searchResult}
          curPage={page}
          totalPage={totalPage}
          onClickSearch={onClickSearch}
          isAdmin={isAdmin}
        />
      </div>
    </div>
  );
};
export default MemberLocationStatsView;
