import { useState } from "react";
import { useDispatch } from "react-redux";
import ClassManageView from "./ClassManageView";

import { dataEmpty, getPermission } from "components/CommonLib/CommonLib";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import { useEffect } from "react";
import ClassManageModalIndex from "./Modal/ClassManageModalIndex";

const ClassManageWrapper = () => {
  const storesDispatch = useDispatch();
  const [branchInfo, setBranchInfo] = useState({
    branch_list: [],
    branch_name_list: [],
  });

  const openModal = (select, param, ptClassOnclickSearch) => {
    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,

        Content: (
          <ClassManageModalIndex
            modalSelect={select}
            modalParam={param}
            onModalDone={onModalDone}
            ptClassOnclickSearch={ptClassOnclickSearch}
          />
        ),
      }),
    );
  };

  const onModalDone = (refreshStore) => {
    storesDispatch(globalModalOff());
    if (!dataEmpty(refreshStore)) {
      refreshStore();
    }
  };

  const onClickOpenModal = (order, param, ptClassOnclickSearch) => {
    if (order !== "") {
      openModal(order, param, ptClassOnclickSearch);
    }
  };

  const getBranchCombo = async () => {
    const result = await getPermission(storesDispatch, "CLASS_MENU/CLASS_MANAGE", true, true, true);
    setBranchInfo(result);
  };

  //초기 지점콤보 GET
  useEffect(() => {
    getBranchCombo();
  }, []);

  return <ClassManageView onClickOpenModal={onClickOpenModal} branchInfo={branchInfo} />;
};
export default ClassManageWrapper;
