import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useEffect } from "react";

// MUI 커스텀
const theme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
            padding: "5px",
          },
          "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
            minWidth: "0px",
          },
          "& .MuiAutocomplete-tag": {
            maxHeight: "100%",
            maxWidth: "25%",
          },
          "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root": {
            display: "flex",
            flexWrap: "nowrap",
          },
        },
        option: {
          fontFamily: "AppleSDGothicNeo",
          fontSize: "0.9rem",
        },
        noOptions: {
          fontSize: "0.9rem",
        },
        tag: {
          margin: "0rem 0.1rem 0rem 0.1rem",
        },
        listbox: {
          "& .MuiAutocomplete-option.Mui-focused": {
            backgroundColor: "#d3d9f3",
            borderRadius: "5px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: "AppleSDGothicNeo",
          fontSize: "0.9rem",
        },
        sizeSmall: {
          height: "2.25rem",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontFamily: "AppleSDGothicNeo",
          fontSize: "0.855rem",
        },
      },
    },
  },
});

const MultiComboBox = (props) => {
  const handleChange = (event, value, reason) => {
    if (props.onChangeCallback !== undefined) {
      props.onChangeCallback(event, value, reason);
    }
  };

  useEffect(() => {}, [props.defaultValue]);

  const hStyle = {
    display: "flex",
    alignItems: "center",
    width: "100%",
  };

  const labelStyle = {
    width: props.labelWidth,
    marginRight: props.labelMarginRight === undefined ? "0.625rem" : props.labelMarginRight,
    marginLeft: props.labelMarginLeft,
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.938rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
  };

  return (
    <div style={hStyle}>
      <div style={labelStyle}>{props.labelText}</div>
      <ThemeProvider theme={theme}>
        <Stack
          sx={{
            width: props.comboItemWidth,
            backgroundColor: "#fff",
            color: "red",
          }}
        >
          <Autocomplete
            multiple
            size="small"
            value={props.defaultValue}
            options={props.option}
            // defaultValue={props.defaultValue ? props.defaultValue : []}
            getOptionLabel={(option) => option}
            onChange={handleChange}
            filterSelectedOptions
            renderInput={(params) => {
              return <TextField {...params} placeholder={props.placeHolder} size="small" />;
            }}
            disabled={props.disabled}
          />
        </Stack>
      </ThemeProvider>
    </div>
  );
};

export default MultiComboBox;
