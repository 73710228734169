import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles, btnSubmitStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, simpleAlert } from "components/CommonLib/CommonLib";
import DocInfo from "components/File/DocInfo";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import { payListActions } from "pages/ERP/MemberCommonItems/Module/actions/index";
import PaymentInfo from "pages/ERP/MemberCommonItems/PaymentInfo";
import PersonalInfo from "pages/ERP/MemberCommonItems/PersonalInfo";
import RegisterPaidDate from "pages/ERP/MemberCommonItems/RegisterPaidDate";
import { payment_init_state } from "pages/ERP/MemberRegister/Register/Module/reducers/payment_init_state";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SubsRefundModalIndex from "./Modal/SubsRefundModalIndex";
import post_api from "./Module/api/PostApi";
import SubsRefundContents from "./SubsRefundContents";

const SubsRefundView = ({ refundBaseState, memberBaseInfoState, settingsState, initRegDate }) => {
  const navigate = useNavigate();
  const storesDispatch = useDispatch();
  const [regDate, setRegDate] = useState(initRegDate);
  const [canEnroll, setCanEnroll] = useState(false);
  const [refundState, setRefundState] = useState();
  const [payMethodList, setPayMethodList] = useState([]);
  const [docList, setDocList] = useState([]);
  const [loading, setLoading] = useState(false);
  const btnStyleClass = btnSubmitStyles();
  const btnStyle = btnStyles();
  const paymentInfoRef = useRef();
  const contentsInfoRef = useRef();

  useEffect(() => {
    setRegDate(initRegDate);
    setRefundState(refundBaseState);
    // console.log(refundBaseState);
  }, [refundBaseState]);

  //파일 초기화
  useEffect(() => {
    if (refundBaseState.contract_doc_list.length !== 0) {
      setDocList(refundBaseState.contract_doc_list);
    }
  }, [refundBaseState.contract_doc_list.length]);

  useEffect(() => {
    if (paymentInfoRef.current) {
      paymentInfoRef.current.setRefundPaidResult(payMethodList);
    }
  }, [payMethodList]);

  // console.log("memberBaseInfoState");
  // console.log(memberBaseInfoState);
  // console.log("refundState");
  // console.log(refundState);

  const validation = () => {
    let res = "";

    // console.log(refundState);
    if (!refundState.cs_staff.staff_name) {
      res += "· 상담자\n";
    }

    return res;
  };

  const onSelectPayMethod = () => {
    const selectedItems = contentsInfoRef.current.getSelectedItem();
    let flag = true;
    for (let i = 0; i < selectedItems.length; i++) {
      if (
        selectedItems[i].repaid_price !== 0 ||
        selectedItems[i].done_refund_price !== selectedItems[i].total_refund_price
      ) {
        flag = false;
        break;
      }
    }
    setCanEnroll(flag);
  };

  const onClickOpenModal = (order, param) => {
    if (order !== "") {
      // lockerModelRef.current.setOpen();
      openModal(order, param);
    }
  };

  const onClickOpenPayModal = () => {
    const payData = [];
    let stopFlag = false;

    for (let i = 0; i < refundState.refund_list.length; i++) {
      if (refundState.refund_list[i].selected) {
        if (
          refundState.refund_list[i].total_refund_price >
          refundState.refund_list[i].done_refund_price
        ) {
          stopFlag = true;
          break;
        }

        if (
          refundState.refund_list[i].repaid_price > 0 ||
          refundState.refund_list[i].discount_return > 0
        ) {
          payData.push(refundState.refund_list[i]);
        }
      }
    }

    if (stopFlag) {
      // globalAlert이 안된다 ㅜㅜ
      storesDispatch(
        globalModalOn({
          show: true,
          title: "알림",
          Content: (
            <div style={{ width: "17rem" }}>
              <Label labelText="환불 처리가 완료되지 않은 항목이 있습니다." marginTop="0.5rem" />
              <div style={{ marginTop: "2.5rem", display: "flex", justifyContent: "center" }}>
                <Button
                  className={clsx({
                    [btnStyle.btnRoot]: true,
                    [btnStyle.buttonType2]: true,
                  })}
                  onClick={() => storesDispatch(globalModalOff())}
                >
                  확인
                </Button>
              </div>
            </div>
          ),
        }),
      );
    } else {
      const param = {
        title: "환불금액 재결제",
        modalParam: {
          payMethodList: payMethodList,
          payDataList: payData,
          regDate: regDate,
        },
      };
      openModal("결제", param);
    }
  };

  const openModal = (name, param) => {
    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,
        Content: (
          <SubsRefundModalIndex modalSelect={name} modalParam={param} onModalDone={onModalDone} />
        ),
      }),
    );
  };

  const onModalDone = (modalType, payMethodList) => {
    setPayMethodList(payMethodList);
    paymentInfoRef.current.setRefundPaidResult(payMethodList);

    refundState.paymentB.payment_info = [];
    const paidItemList = paymentInfoRef.current.getPaidItemList();

    for (let i = 0; i < payMethodList.length; i++) {
      const paymentResult = _.cloneDeep(payment_init_state);
      paymentResult.paid_item_list = [paidItemList[i]];
      paymentResult.payment_input = payMethodList[i];
      paymentResult.purchase_code = payMethodList[i].purchase_code;
      paymentResult.paid_price = payMethodList[i].total_paid;
      paymentResult.unpaid_price = payMethodList[i].unpaid_info.unpaid_price;

      refundState.paymentB.payment_info.push(paymentResult);
    }

    storesDispatch(globalModalOff());

    let flag = true;
    for (let i = 0; i < payMethodList.length; i++) {
      if (payMethodList[i].pay_method.length === 0) {
        flag = false;
      }
    }
    setCanEnroll(flag);
  };

  const onClickSave = () => {
    const selectedItems = contentsInfoRef.current.getSelectedItem();
    let flag = false;
    for (let i = 0; i < selectedItems.length; i++) {
      if (selectedItems[i].done_refund_price < selectedItems[i].total_refund_price) {
        flag = true;
        break;
      }
    }

    let flag_2 = false;
    for (let i = 0; i < refundState.refund_list.length; i++) {
      for (let j = 0; j < refundState.refund_list[i].refund_detail.length; j++) {
        // 환불구분 선택 안한 경우
        console.log(refundState.refund_list[i].refund_detail[j].selected);
        console.log(refundState.refund_list[i].refund_detail[j].cancel_type);
        if (
          refundState.refund_list[i].refund_detail[j].selected &&
          refundState.refund_list[i].refund_detail[j].cancel_type === ""
        ) {
          flag_2 = true;
          break;
        }
      }

      if (flag_2) break;
    }

    const validationRes = validation();
    if (validationRes !== "") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="필수값을 모두 입력해주셔야 합니다." />
              <br />
              <br />
              <Label labelText={validationRes} whiteSpace="pre-wrap" />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else if (flag) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="환불 처리가 완료되지 않은 항목이 있습니다." />,
          onBtnOk: () => {},
        }),
      );
    } else if (flag_2) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="환불금 지급 상세내역 중 입력되지 않은 항목이 있습니다." />,
          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="선택된 상품의 약정 또는 정기결제를 해지 처리하시겠습니까?" />
            </>
          ),
          onBtnYes: () => {
            onSaveEvent();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const onSaveEvent = async () => {
    refundState.reg_date = regDate;

    console.log(JSON.stringify(refundState));
    //파일 첨부
    refundState.contract_doc_list = docList;
    //모두 사용할꺼면
    refundState.contract_doc_list.forEach((el) => (el.is_use = true));

    const formData = new FormData();
    formData.append("new_info", JSON.stringify(refundState));
    const postApi = post_api.refundUpdate();

    setLoading(true);

    await HttpInstance.post(postApi, formData, contentTypeJson)
      .then((res) => {
        console.log(res);
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          storesDispatch(
            globalAlertOn({
              show: true,
              titleText: "알림",
              bodyText: (
                <Label labelText="약정해지 처리가 완료되었습니다. 회원정보 페이지로 이동합니다." />
              ),
              reqConfirm: true,
              onBtnOk: () => {
                navigate("/member_info/" + memberBaseInfoState.member_id);
              },
            }),
          );
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const payInfoListAction = (act, pType, detailInfo, ord, mbshpIdx) => {
    if (act === "push") {
      storesDispatch(payListActions.paymentInfoPush(pType, detailInfo, ord, mbshpIdx));
    } else if (act === "pop") {
      storesDispatch(payListActions.paymentInfoPop(pType));
    } else if (act === "discount") {
      storesDispatch(payListActions.paymentInfoEdit(pType, detailInfo, ord, mbshpIdx));
    }
  };

  const exportData = () => {
    refundState.reg_date = regDate;

    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(refundState),
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;

    const id = memberBaseInfoState.member_id;
    link.download = id + "_data.json";
    link.click();
  };

  const uploadDoneCallback = (result) => {
    //업로드에서 받아온 파일을 contract_doc_list에 갱신
    const idx = docList.findIndex((el) => el.doc_id === result.doc_id);
    setDocList((cur) => {
      const arr = [...cur];
      arr[idx] = result;
      return arr;
    });
  };
  if (!refundState) {
    return <></>;
  } else {
    return (
      <div style={{ display: "flex" }}>
        {loading && <Loading />}

        <div style={{ width: "76.88rem" }}>
          <PersonalInfo
            memberBaseInfoState={memberBaseInfoState.base_info.personal_info}
            onClickOpenModal={onClickOpenModal}
            memberInputState={{
              branch_info: memberBaseInfoState.branch_info,
              card_param: memberBaseInfoState.access,
            }}
          />

          <div style={{ marginTop: "0.625rem" }}>
            <SubsRefundContents
              ref={contentsInfoRef}
              payInfoListAction={payInfoListAction}
              payMethodList={payMethodList}
              setPayMethodList={setPayMethodList}
              refundState={refundState}
              setRefundState={setRefundState}
              settingsState={settingsState}
              regDate={regDate}
              onSelectPayMethod={onSelectPayMethod}
            />
          </div>
        </div>

        <div style={{ width: "28.13rem", marginLeft: "0.625rem" }}>
          <PaymentInfo
            ref={paymentInfoRef}
            onClickOpenModal={onClickOpenPayModal}
            regDate={regDate}
            baseInfoState={{
              branch_setting: {
                branch_info: memberBaseInfoState.branch_info,
              },
            }}
            setCanEnroll={setCanEnroll}
            isAddPay={false}
            isRefund={true}
          />

          <RegisterPaidDate regDate={regDate} setRegDate={setRegDate} isDisabled />

          {/* <DocumentInfo /> */}
          <DocInfo docList={docList} uploadDoneCallback={uploadDoneCallback} textBoxWidth="11rem" />
          <Button
            style={{ marginTop: "1.5rem" }}
            className={clsx({
              [btnStyleClass.btnRoot]: true,
              [btnStyleClass.buttonType2]: true,
              [btnStyleClass.disabled]: !canEnroll,
            })}
            onClick={() => onClickSave()}
            disabled={!canEnroll}
          >
            등록
          </Button>

          {/* <Button
            style={{ marginTop: "1.5rem" }}
            className={clsx({
              [btnStyleClass.btnRoot]: true,
              [btnStyleClass.buttonType2]: true,
            })}
            onClick={() => {
              console.log(refundState);
              // console.log(payMethodList);
            }}
          >
            TEST BTN
          </Button>

          <Button
            style={{ marginTop: "1.5rem" }}
            className={clsx({
              [btnStyleClass.btnRoot]: true,
              [btnStyleClass.buttonType2]: true,
            })}
            onClick={() => exportData()}
          >
            DUMP
          </Button> */}
        </div>
      </div>
    );
  }
};
export default SubsRefundView;
