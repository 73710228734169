import {
  contentTypeJson,
  generateObjectZeroId,
  getPermission,
  restEmpty,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import ApprovalSelectorView from "pages/CommonModal/Approval/ApprovalSelectorView";
import WorkTime from "pages/Staff/StaffInfo/Modal/CommonItem/WorkTime";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import post_api from "../Module/api/PostApi";
import { workInfoInitState } from "../Module/reducers/work_info_init_state";
import JoinContractPopup from "./WorkInfoActionPopup/JoinContractPopup";
import ResignPopup from "./WorkInfoActionPopup/ResignPopup";
import WorkInfoBase from "./WorkInfoItem/WorkInfoBase";
import WorkInfoSalary from "./WorkInfoItem/WorkInfoSalary";

const CurrentWorkInfoModal = ({ modalParam, onModalDone }) => {
  const pageMode = modalParam.pageMode;
  const [loading, setLoading] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [hqIdx, setHqIdx] = useState(0);
  const [branchIdx, setBranchIdx] = useState(0);
  const [hqId, setHqId] = useState(modalParam.hq_id);
  const [branchId, setBranchId] = useState(modalParam.branch_id);
  const [positionIdx, setPositionIdx] = useState(0);
  const [workInfoStore, setWorkInfoStore] = useState(_.cloneDeep(workInfoInitState));
  const [positionDetail, setPositionDetail] = useState({
    is_pt_extra: false,
    org_id: "",
    org_name: "",
    pt_commission: [],
    pt_fee: [],
    wage_type: "",
    wage: 0,
  });
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });
  const viewModelRef = useRef();

  const storesDispatch = useDispatch();

  useEffect(() => {
    getPermission(storesDispatch, "STAFF_MENU/WORK_INFO_APPROV").then((res) => {
      setHasPermission(res);
    });
  }, []);

  useEffect(() => {
    if (pageMode === "보기") {
      HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/staff/show_work_info", {
        params: {
          work_info_id: modalParam.work_info_id,
        },
      })
        .then((res) => {
          if (!restEmpty(res.data.data)) {
            // 임시저장이나 승인대기 상태면 work_info_edited_obj를 가져와서 사용
            if (
              res.data.data.contents.work_info_cur_obj.edit_status === "임시저장" ||
              res.data.data.contents.work_info_cur_obj.approval_status === "승인대기"
            ) {
              res.data.data.contents.work_info_obj = res.data.data.contents.work_info_edited_obj;
            } else {
              res.data.data.contents.work_info_obj = res.data.data.contents.work_info_cur_obj;
            }
            setWorkInfoStore(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        });
    } else if (pageMode === "이력") {
      HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/staff/work_info/change_detail", {
        params: {
          log_id: modalParam.log_id,
        },
      })
        .then((res) => {
          if (!restEmpty(res.data.data)) {
            setWorkInfoStore({
              ...workInfoStore,
              contents: {
                ...workInfoStore.contents,
                work_info_obj: res.data.data.work_info_data,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        });
    } else {
      HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/staff/add_edit_work_info", {
        params: {
          hq_id: hqId,
          branch_id: branchId,
          work_info_id: modalParam.work_info_id,
        },
      })
        .then((res) => {
          if (!restEmpty(res.data.data)) {
            setWorkInfoStore(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        });
    }
  }, [hqId, branchId]);

  const validation = () => {
    let res = "";
    const workInfo = workInfoStore.contents.work_info_obj;

    if (
      !workInfo.is_hq_staff &&
      workInfo.base_info.branch_info.branch_id === generateObjectZeroId()
    )
      res += "· 지점\n";
    if (workInfo.base_info.department.org_id === generateObjectZeroId()) res += "· 부서\n";
    if (workInfo.base_info.rank.org_id === generateObjectZeroId()) res += "· 직위\n";
    if (workInfo.base_info.position.org_id === generateObjectZeroId()) res += "· 직책\n";
    if (pageMode === "추가" || workInfo.work_status === "대기") {
      if (!workInfo.base_info.join_date) res += "· 입사일\n";
    } else {
      if (!workInfo.base_info.work_trans_from_date) res += "· 전환일\n";
    }
    if (!workInfo.wage_type) res += "· 기본급\n";

    if (workInfo.wage_type === "시급제") {
      if (!workInfo.wage) res += "· 급여액\n";
    }

    for (let i = 0; i < workInfo.time_list.length; i++) {
      if (
        workInfo.time_list[i].is_use &&
        workInfo.time_list[i].from_time === workInfo.time_list[i].end_time
      ) {
        res += "· 근무 시간(시작, 종료시간이 동일함)\n";
        break;
      }
    }

    return res;
  };

  const onChangeHq = (idx) => {
    setHqIdx(idx);
    setHqId(modalParam.hqBranchList.target_list[idx].hq_info.hq_id);
    setBranchId(modalParam.hqBranchList.target_list[idx].branch_list[0].branch_id);
    setBranchIdx(0);
  };

  const onChangeBranch = (idx) => {
    setBranchIdx(idx);
    setBranchId(modalParam.hqBranchList.target_list[hqIdx].branch_list[idx].branch_id);
  };

  const onPopupDone = (params) => {
    // workInfoStore.permission.btn_approval = false; // 테스트용
    const popupParam = {
      workInfoStore: workInfoStore.contents.work_info_obj,
      workInfoPermission: workInfoStore.permission,
      privileged: hasPermission,
    };
    viewModelRef.current.setClose();

    switch (params.type) {
      case "퇴직":
        setModalInfo({
          show: true,
          title: "퇴직",
          Content: <ResignPopup params={popupParam} onModalDone={onSave} />,
          callback: null,
          Buttons: undefined,
        });
        break;
      case "승인":
        popupParam.mode = "승인";
        setModalInfo({
          show: true,
          title: "알림",
          Content: <JoinContractPopup params={popupParam} onModalDone={onSave} />,
          callback: null,
          Buttons: undefined,
        });
        break;
      case "기안":
        popupParam.mode = "기안";
        setModalInfo({
          show: true,
          title: "알림",
          Content: <JoinContractPopup params={popupParam} onModalDone={onSave} />,
          callback: null,
          Buttons: undefined,
        });
        break;
      case "전환":
        if (hasPermission) {
          popupParam.mode = "전환";
          setModalInfo({
            show: true,
            title: "알림",
            Content: <JoinContractPopup params={popupParam} onModalDone={onSave} />,
            callback: null,
            Buttons: undefined,
          });
        } else {
          storesDispatch(
            globalAlertOn({
              show: true,
              titleText: "알림",
              bodyText: <Label labelText={"근로정보 전환을 기안하시겠습니까?"} />,
              onBtnYes: () => {
                onSave(params.type);
              },
              onBtnNo: () => {},
            }),
          );
        }
        break;
      case "재입사":
        if (hasPermission) {
          popupParam.mode = "재입사";
          setModalInfo({
            show: true,
            title: "알림",
            Content: <JoinContractPopup params={popupParam} onModalDone={onSave} />,
            callback: null,
            Buttons: undefined,
          });
        } else {
          storesDispatch(
            globalAlertOn({
              show: true,
              titleText: "알림",
              bodyText: <Label labelText={"재입사 기안하시겠습니까?"} />,
              onBtnYes: () => {
                onSave(params.type);
              },
              onBtnNo: () => {},
            }),
          );
        }
        // storesDispatch(
        //   globalAlertOn({
        //     show: true,
        //     titleText: "알림",
        //     bodyText: (
        //       <Label
        //         labelText={
        //           workInfoStore.permission.btn_approval
        //             ? "재입사 처리 하시겠습니까?"
        //             : "재입사 기안하시겠습니까?"
        //         }
        //       />
        //     ),
        //     onBtnYes: () => {
        //       onSave(params.type);
        //     },
        //     onBtnNo: () => {},
        //   }),
        // );
        break;
      default:
        break;
    }
  };

  const onSaveEvent = (type) => {
    const popupParam = {
      type: type,
      nextAction: "",
      workInfoStore: workInfoStore.contents.work_info_obj,
      privileged: hasPermission,
      approval_info: workInfoStore.contents.approval_info,
    };

    // workInfoStore.permission.btn_approval = false; // 테스트용
    if (type === "임시저장") {
      const validationRes = validation();
      if (validationRes !== "") {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: (
              <>
                <Label labelText="필수값을 모두 입력해주셔야 합니다." />
                <br />
                <br />
                <Label labelText={validationRes} whiteSpace="pre-wrap" />
              </>
            ),
            // width: "50rem",
            onBtnOk: () => {},
          }),
        );
      } else {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: <Label labelText="저장하시겠습니까?" />,
            onBtnYes: () => {
              onSave(type);
            },
            onBtnNo: () => {},
          }),
        );
      }
    } else if (type === "변경취소") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="수정사항을 취소하고 기존 정보로 되돌리시겠습니까?" />,
          onBtnYes: () => {
            onSave(type);
          },
          onBtnNo: () => {},
        }),
      );
    } else if (type === "전환") {
      if (hasPermission) {
        onPopupDone({ type: type });
      } else {
        setModalInfo({
          show: true,
          title: "결재선 지정",
          Content: <ApprovalSelectorView modalParam={popupParam} onModalDone={onPopupDone} />,
          callback: null,
          Buttons: undefined,
        });
      }
    } else if (type === "재입사") {
      if (
        modalParam.affiliation.findIndex(
          (x) =>
            x.hq_info.hq_id === workInfoStore.contents.work_info_cur_obj.base_info.hq_info.hq_id &&
            x.branch_info.branch_id === "000000000000001039934570" &&
            !x.is_retired,
        ) !== -1
      ) {
        simpleAlert(
          storesDispatch,
          "알림",
          "소속된 본부에 재직 중입니다. 재입사 처리할 수 없습니다.",
        );
      } else {
        if (hasPermission) {
          onPopupDone({ type: type });
        } else {
          setModalInfo({
            show: true,
            title: "결재선 지정",
            Content: <ApprovalSelectorView modalParam={popupParam} onModalDone={onPopupDone} />,
            callback: null,
            Buttons: undefined,
          });
        }
      }
    } else if (type === "기안") {
      const validationRes = validation();
      if (validationRes !== "") {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: (
              <>
                <Label labelText="입력되지 않은 값이 있습니다." />
                <Label labelText="수정 후 등록해 주시기 바랍니다." />
                <br />
                <Label labelText={validationRes} whiteSpace="pre-wrap" />
              </>
            ),
            onBtnOk: () => {},
          }),
        );
      } else {
        if (hasPermission) {
          onPopupDone({ type: type });
        } else {
          setModalInfo({
            show: true,
            title: "결재선 지정",
            Content: <ApprovalSelectorView modalParam={popupParam} onModalDone={onPopupDone} />,
            callback: null,
            Buttons: undefined,
          });
        }
      }
    } else if (type === "퇴직") {
      if (hasPermission) {
        onPopupDone({ type: type });
      } else {
        setModalInfo({
          show: true,
          title: "결재선 지정",
          Content: <ApprovalSelectorView modalParam={popupParam} onModalDone={onPopupDone} />,
          callback: null,
          Buttons: undefined,
        });
      }
    } else if (type === "회수") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="기안된 정보를 회수 처리하시겠습니까?" />,
          onBtnYes: () => {
            onSave(type);
          },
          onBtnNo: () => {},
        }),
      );
    } else {
      console.log("else..........");
    }
  };

  const onSave = async (type, file) => {
    viewModelRef.current.setClose();

    workInfoStore.contents.work_info_obj.staff_id = modalParam.staff_info_state.staff_id;
    workInfoStore.contents.work_info_obj.personal_info.email =
      modalParam.staff_info_state.personal_info.email;
    workInfoStore.contents.work_info_obj.personal_info.sabun =
      modalParam.staff_info_state.personal_info.sabun;
    workInfoStore.contents.work_info_obj.personal_info.phone =
      modalParam.staff_info_state.personal_info.phone;
    workInfoStore.contents.work_info_obj.personal_info.staff_name =
      modalParam.staff_info_state.personal_info.staff_name;

    const hqOnlyBranchInfo = {
      branch_id: "000000000000001039934570", // 본부직원은 무조건 이 branch id 사용
      branch_name: "본부직원",
    };

    if (workInfoStore.contents.work_info_obj.is_hq_staff) {
      workInfoStore.contents.work_info_obj.base_info.branch_info = hqOnlyBranchInfo;
    }

    const formData = new FormData();
    formData.append("new_info", JSON.stringify(workInfoStore));

    let postApi;

    switch (type) {
      case "취소":
        return false;
      case "임시저장":
        if (modalParam.pageMode === "수정") {
          postApi = post_api.editWorkInfo();
        } else {
          postApi = post_api.insertWorkInfo();
        }
        break;
      case "변경취소":
        postApi = post_api.editCancelWorkInfo();
        break;
      case "기안":
        postApi = hasPermission
          ? post_api.approveWorkInfo() // 권한 있는 걸로 바꾸기
          : post_api.draftWorkInfo();
        formData.append("emp_contract", file);
        break;
      case "회수":
        postApi = post_api.recallWorkInfo();
        break;
      case "퇴직":
        postApi = hasPermission
          ? post_api.retireWorkInfo() // 권한 있는 걸로 바꾸기
          : post_api.retireWorkInfo();
        formData.append("resign", file);
        break;
      case "재입사":
        postApi = hasPermission
          ? post_api.reEntryWorkInfo() // 권한 있는 걸로 바꾸기
          : post_api.reEntryWorkInfo();
        break;
      case "전환":
        postApi = hasPermission
          ? post_api.transWorkInfo() // 권한 있는 걸로 바꾸기
          : post_api.transWorkInfo();
        if (hasPermission) {
          formData.append("emp_contract", file);
        }
        break;
      default:
        break;
    }

    let saveAlertText;
    if (hasPermission) {
      if (type === "기안") {
        saveAlertText = "승인 처리되었습니다.";
      } else {
        saveAlertText = type + " 처리되었습니다.";
      }
    } else {
      if (type === "회수" || type === "임시저장" || type === "변경취소" || type === "기안") {
        saveAlertText = type + " 처리되었습니다.";
      } else {
        saveAlertText = type + "에 대한 결재 상신이 완료되었습니다.";
      }
    }

    setLoading(true);

    await HttpInstance.post(postApi, formData, contentTypeJson)
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          simpleAlert(storesDispatch, "알림", saveAlertText);
          onModalDone(modalParam.refreshStore);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onPositionChange = (idx) => {
    const curPosition = workInfoStore.contents.hq_set_data.position_list[idx];
    setPositionDetail(curPosition);
    setPositionIdx(idx);
  };

  const viewCheck = () => {
    if (workInfoStore.contents.work_info_cur_obj) {
      let objd = workInfoStore.contents.work_info_cur_obj;
      if (
        objd.approval_status === "승인" &&
        objd.work_status === "퇴직" &&
        objd.edit_status === ""
      ) {
        return true;
      }
    }
    return false;
  };

  if (viewCheck()) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          // height: getNoDataHeight(),
        }}
      >
        <Label labelText="데이터가 없습니다." />
      </div>
    );
  } else {
    return (
      <div
        style={{
          width: "100%",
        }}
      >
        {loading && <Loading />}
        <ModalPopupLocal ref={viewModelRef} params={modalInfo} />

        <div style={{ display: "inline-block", width: "100%", marginTop: "0.5rem" }}>
          <WorkInfoBase
            pageMode={pageMode}
            modalParam={modalParam}
            hqBranchList={modalParam.hqBranchList}
            affiliation={modalParam.affiliation}
            hqIdx={hqIdx}
            onChangeHq={onChangeHq}
            branchIdx={branchIdx}
            setBranchIdx={onChangeBranch}
            setPositionIdx={onPositionChange}
            workInfoStore={workInfoStore}
            onSaveEvent={onSaveEvent}
            hqPermissions={modalParam.hqPermissions}
          />
          <WorkTime
            pageMode={pageMode}
            workInfoStore={workInfoStore.contents.work_info_obj}
            setWorkInfoStore={setWorkInfoStore}
          />
          <WorkInfoSalary
            pageMode={pageMode}
            positionDetail={positionDetail}
            setPositionDetail={setPositionDetail}
            workInfoStore={workInfoStore.contents.work_info_obj}
            staffId={modalParam.staff_info_state.staff_id}
            staffName={modalParam.staff_info_state.personal_info.staff_name}
            staffPos={workInfoStore.contents.work_info_obj.base_info.position.org_name}
            staffRank={workInfoStore.contents.work_info_obj.base_info.rank.org_name}
          />
        </div>
      </div>
    );
  }
};
export default CurrentWorkInfoModal;
