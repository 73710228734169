import { restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { settingInitState } from "pages/ERP/MemberRegister/Register/Module/reducers/setting_init_state";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { refundInitStateAll } from "./Module/reducers/refund_state_all";
import RefundWrapper from "./RefundWrapper";

// class TestRoot extends Component {
const RefundService = () => {
  const { state } = useLocation();
  const storesDispatch = useDispatch();
  const [refundBaseState, setRefundBaseState] = useState(_.cloneDeep(refundInitStateAll));
  const [settingsState, setSettingsState] = useState(_.cloneDeep(settingInitState));

  const getSettingsData = (branchId) => {
    HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/register/settings_show", {
      params: { branch_id: branchId },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setSettingsState(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const getViewData = () => {
    HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/change/refund_view", {
      params: {
        member_id: state.member_id,
        reg_date: state.reg_date,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setRefundBaseState(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  useEffect(() => {
    getViewData();
    getSettingsData();
  }, []);

  return (
    <div>
      <PageFrame
        children={
          <div>
            <PageTitle titleText="환불" />
            <RefundWrapper
              refundBaseState={refundBaseState}
              memberBaseInfoState={state.memberBaseInfoState}
              settingsState={settingsState}
              initRegDate={state.reg_date}
            />
          </div>
        }
      />
    </div>
  );
};
export default RefundService;
