import { useQuery } from "@tanstack/react-query";
import { getAuthData } from "apis/access/get_auth_api";
import { authKeys } from "apis/access/query_keys_access";
import { simpleAlert } from "components/CommonLib/CommonLib";
import { useDispatch } from "react-redux";

// [GET] 출입 카드 현황 가져오기
export const useGetAuthData = () => {
  const storesDispatch = useDispatch();

  return useQuery({
    queryKey: authKeys.authData(),
    queryFn: () => getAuthData(),
    enabled: true,
    retry: false,

    onError: () => {
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    },
  });
};
