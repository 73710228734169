import * as ActionTypes from "./action_type";

///MEMBER VIEW
export const loadMemberView = (member_id) => {
  return {
    type: ActionTypes.getMemberViewAction,
    member_id: member_id,
  };
};

export const loadMemberViewSuccess = (data) => {
  return {
    type: ActionTypes.getMemberViewSuccessAction,
    payload: data,
  };
};

export const loadMemberViewFail = (data) => {
  return {
    type: ActionTypes.getMemberViewFailAction,
    payload: [],
  };
};

export const loadMemberViewReset = (data) => {
  return {
    type: ActionTypes.getMemberViewResetAction,
    payload: data,
  };
};
