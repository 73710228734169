import {
  changeComboValueToEmpty,
  checkDateOrder,
  getDefaultValue,
} from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import { SearchConditionBox, SearchContainer, SearchRowBox } from "./style";

const SearchCondition = ({ searchParams, setSearchParams, onClickSearch }) => {
  const labelWidth = "6rem";
  const textBoxWidth = "15rem";
  const textBoxWidthShort = "8.5rem";

  return (
    <SearchContainer>
      <Label labelText="검색 조건 : 상세" fontWeight="bold" justifyContent="flex-start" />
      <SearchRowBox>
        <SearchConditionBox width="26.5%">
          <DatePicker
            labelText="최근 TM 일시"
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidthShort}
            textMarginRight="0rem"
            defaultValue={getDefaultValue("field", searchParams.tm_from_date)}
            onChangeCallback={(e) => {
              setSearchParams("tm_from_date", e);
            }}
            onBlur={(e) => {
              if (!checkDateOrder(e, searchParams.tm_to_date)) {
                setSearchParams("tm_from_date", "");
              }
            }}
          />
          <DatePicker
            marginLeft="0.12rem"
            marginRight="4rem"
            labelWidth="0.5rem"
            textBoxWidth={textBoxWidthShort}
            textMarginRight="0rem"
            labelMarginRight="0.12rem"
            labelText="~"
            defaultValue={getDefaultValue("field", searchParams.tm_to_date)}
            onChangeCallback={(e) => {
              setSearchParams("tm_to_date", e);
            }}
            onBlur={(e) => {
              if (!checkDateOrder(searchParams.tm_from_date, e)) {
                setSearchParams("tm_to_date", "");
              }
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox>
          <TextField
            labelText="최근 TM 담당자"
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth}
            marginRight="4rem"
            defaultValue={getDefaultValue("field", searchParams.tm_manager_name)}
            onChangeCallback={(e) => {
              setSearchParams("tm_manager_name", e);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onClickSearch(1);
              }
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox>
          <ComboBox
            labelText="최근 TM 상태"
            labelWidth={labelWidth}
            labelMarginRight="0rem"
            comboItemWidth={textBoxWidth}
            marginRight="4rem"
            data={["전체", "없음", "TM 완료", "부재"]}
            defaultValue={getDefaultValue("combo", searchParams.tm_status)}
            onChangeCallback={(e) => {
              changeComboValueToEmpty(setSearchParams, "tm_status", e);
            }}
          />
        </SearchConditionBox>
      </SearchRowBox>
    </SearchContainer>
  );
};
export default SearchCondition;
