import failIcon from "assets/icon/check_fail.png";
import successIcon from "assets/icon/check_success.png";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import { checkEmail, getPhoneSubstr, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import FileUpload from "components/LabelInput/FileUpload";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import PhotoComponent from "pages/Staff/StaffInfo/PersonalCard/PhotoComponent";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

const SearchCardRow1 = ({ staffInfoState, canEdit, isViewMode }) => {
  const storesDispatch = useDispatch();
  const compMarginLeft = "4rem";
  const labelWidth = "4rem";
  const compWidth = "11rem";
  const phoneRef2 = useRef();
  const phoneRef3 = useRef();
  const [phone, setPhone] = React.useState({
    p1: "",
    p2: "",
    p3: "",
  });
  // const [phoneCheckStatus, setPhoneCheckStatus] = React.useState(true);

  useEffect(() => {
    setPhone({
      p1: getPhoneSubstr(staffInfoState.personal_info.phone, 0),
      p2: getPhoneSubstr(staffInfoState.personal_info.phone, 1),
      p3: getPhoneSubstr(staffInfoState.personal_info.phone, 2),
    });
  }, [staffInfoState]);

  const onCheckPhone = () => {};

  return (
    <div style={{ display: "flex" }}>
      <div style={{}}>
        <TextField
          required
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          labelText="· 이름"
          defaultValue={staffInfoState.personal_info.staff_name}
          // onChangeCallback={(e) => {
          //   staffInfoState.personal_info.staff_name = e;
          // }}
          disabled={canEdit || isViewMode}
        />
      </div>

      <div style={{ display: "flex", marginLeft: compMarginLeft }}>
        <TextField
          required={!canEdit}
          textMarginRight="0.25rem"
          labelWidth={labelWidth}
          textBoxWidth={"3.5rem"}
          labelText="· 연락처"
          maxLength={3}
          defaultValue={getPhoneSubstr(staffInfoState.personal_info.phone, 0)}
          onChangeCallback={(e) => {
            phone.p1 = e;
            onCheckPhone();
            if (e.length === 3) {
              phoneRef2.current.focus();
            }
          }}
          // onBlur={onCheckPhone}
          disabled={!canEdit}
        />
        <TextField
          inputRef={phoneRef2}
          required={!canEdit}
          textMarginRight="0.25rem"
          labelMarginRight="0.25rem"
          labelWidth="0.5rem"
          labelText="-"
          maxLength={4}
          textBoxWidth={"4rem"}
          defaultValue={getPhoneSubstr(staffInfoState.personal_info.phone, 1)}
          onChangeCallback={(e) => {
            phone.p2 = e;
            onCheckPhone();
            if (e.length === 4) {
              phoneRef3.current.focus();
            }
          }}
          // onBlur={onCheckPhone}
          disabled={!canEdit}
        />
        <TextField
          inputRef={phoneRef3}
          required={!canEdit}
          labelMarginRight="0.25rem"
          labelWidth="0.5rem"
          labelText="-"
          maxLength={4}
          textMarginRight={"0.25rem"}
          textBoxWidth={"4rem"}
          defaultValue={getPhoneSubstr(staffInfoState.personal_info.phone, 2)}
          onChangeCallback={(e) => {
            phone.p3 = e;
            onCheckPhone();
          }}
          // onBlur={onCheckPhone}
          disabled={!canEdit}
        />
      </div>
      <img
        alt="O"
        src={successIcon}
        style={{
          display: "flex",
          width: "2.2rem",
          height: "2.2rem",
        }}
      />
      {/* {phoneCheckStatus === "Y" ? (
        <img
          alt="O"
          src={successIcon}
          style={{
            display: "flex",
            width: "2.2rem",
            height: "2.2rem",
          }}
        />
      ) : phoneCheckStatus === "N" ? (
        <img
          alt="O"
          src={failIcon}
          style={{
            display: "flex",
            width: "2.2rem",
            height: "2.2rem",
          }}
        />
      ) : (
        <div style={{ width: "2.2rem" }}></div>
      )} */}

      <div style={{ marginLeft: "1rem" }}>
        <ComboBox
          required
          labelWidth={labelWidth}
          comboItemWidth={compWidth}
          labelText="· 성별"
          data={["남", "여"]}
          defaultValue={staffInfoState.personal_info.gender}
          onChangeCallback={(e) => {
            // console.log(e);
            staffInfoState.personal_info.gender = e.target.value;
          }}
          disabled={!canEdit}
        />
      </div>
      <div style={{ marginLeft: compMarginLeft }}>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          labelText="· 사번"
          defaultValue={staffInfoState.personal_info.sabun}
          onChangeCallback={(e) => {
            staffInfoState.personal_info.sabun = e;
          }}
          disabled
        />
      </div>
    </div>
  );
};

const SearchCardRow2 = ({ staffInfoState, canEdit, isViewMode }) => {
  const btnStyleClass = btnStyles();
  const compMarginLeft = "4rem";
  const labelWidth = "4rem";
  const compWidth = "11rem";

  const [isAddrPopup, setAddrPopup] = React.useState(false);
  const openAddrPopup = (val) => {
    setAddrPopup(val);
  };

  const handleComplete = (data) => {
    openAddrPopup(false);
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    staffInfoState.personal_info.address_1 = fullAddress;
  };

  return (
    <div style={{ display: "flex", marginTop: "1rem" }}>
      {/* <Popup
        isOpen={isAddrPopup}
        onClose={() => {
          openAddrPopup(false);
        }}
        title="주소 검색"
        Content={
          <DaumPostcode
            onComplete={handleComplete}
            autoClose={false}
            style={{ width: "30rem", height: "35rem" }}
          />
        }
      /> */}

      <div style={{}}>
        <DatePicker
          required
          labelWidth={labelWidth}
          minWidth={compWidth}
          labelText="· 생년월일"
          // defaultValue={"2022-06-21"}
          defaultValue={staffInfoState.personal_info.birthday}
          onChangeCallback={(e) => {
            staffInfoState.personal_info.birthday = e;
          }}
          disabled={!canEdit}
        />
      </div>

      <div style={{ display: "flex", marginLeft: compMarginLeft }}>
        <Label labelText="· 주소" marginRight="2.25rem" />
        {/* <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType2]: true,
          })}
          onClick={() => {
            openAddrPopup(true);
          }}
        >
          검색
        </Button> */}
        <TextField
          labelMarginRight="0.25rem"
          textMarginRight="0rem"
          textBoxWidth={"25.5rem"}
          defaultValue={staffInfoState.personal_info.address_1}
          onChangeCallback={(e) => {
            staffInfoState.personal_info.address_1 = e;
          }}
          disabled
        />
      </div>

      <div style={{}}>
        <TextField
          labelMarginRight="0.25rem"
          textBoxWidth={"22.5rem"}
          defaultValue={staffInfoState.personal_info.address_2}
          onChangeCallback={(e) => {
            staffInfoState.personal_info.address_2 = e;
          }}
          disabled={!canEdit}
        />
        {/* <TextField3
          labelWidth={"4.25rem"}
          textBoxWidth={"6.75rem"}
          labelText=""
          textValue="1234567890"
        /> */}
      </div>
    </div>
  );
};

const SearchCardRow3 = ({ staffInfoState, canEdit, isViewMode, staffInfoRoot }) => {
  const storesDispatch = useDispatch();
  const compMarginLeft = "4rem";
  const labelWidth = "4rem";
  const compWidth = "11rem";
  const [idCheckStatus, setIdCheckStatus] = useState(true);
  const [socialNumber, setSocialNumber] = useState(staffInfoState.personal_info.social_number);

  useEffect(() => {
    setSocialNumber(staffInfoState.personal_info.social_number);
  }, []);

  const checkAuthId = (val) => {
    HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/staff/check_id", {
      params: {
        user_id: val,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          const isDuplicate = res.data.data;
          if (isDuplicate === "N") {
            setIdCheckStatus("Y");
            staffInfoRoot.auth_info.user_id = val;
          } else {
            setIdCheckStatus("N");
            staffInfoRoot.auth_info.user_id = "";
          }
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  return (
    <div style={{ display: "flex", marginTop: "1rem" }}>
      <div style={{}}>
        <TextField
          required
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          labelText="· 주민번호"
          maxLength={14}
          defaultValue={staffInfoState.personal_info.social_number}
          // defaultValue={socialNumber}
          onChangeCallback={(e) => {
            if (e.length === 6) {
              e += "-";
            }
            staffInfoState.personal_info.social_number = e;
            setSocialNumber(staffInfoState.personal_info.social_number);
          }}
          disabled={!canEdit}
        />
      </div>

      <div style={{ marginLeft: compMarginLeft }}>
        <TextField
          required
          id="email_input"
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          labelText="· 이메일"
          defaultValue={staffInfoState.personal_info.email}
          onBlur={(e) => {
            if (e.target.value !== "" && !checkEmail(e.target.value)) {
              storesDispatch(
                globalAlertOn({
                  show: true,
                  titleText: "알림",
                  bodyText: (
                    <>
                      <Label labelText="올바른 이메일 형식이 아닙니다." />
                    </>
                  ),
                  // width: "50rem",
                  onBtnOk: () => {},
                }),
              );
              document.getElementById("email_input").focus();
            } else {
              staffInfoState.personal_info.email = e.target.value;
            }
          }}
          disabled={!canEdit}
        />
      </div>
      <div style={{ display: "flex", marginLeft: "5.2rem" }}>
        <TextField
          required
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          labelText="· 아이디"
          defaultValue={staffInfoRoot.auth_info.user_id}
          onBlur={(e) => {
            checkAuthId(e.target.value);
          }}
          disabled={canEdit || isViewMode}
        />
        {idCheckStatus === "Y" ? (
          <img
            alt="O"
            src={successIcon}
            style={{
              display: "flex",
              width: "2.2rem",
              height: "2.2rem",
            }}
          />
        ) : idCheckStatus === "N" ? (
          <img
            alt="O"
            src={failIcon}
            style={{
              display: "flex",
              width: "2.2rem",
              height: "2.2rem",
            }}
          />
        ) : (
          <div style={{ width: "2.2rem" }}></div>
        )}
      </div>
      <div style={{ marginLeft: "1.8rem" }}>
        <TextField
          required
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          labelText="· 비밀번호"
          defaultValue={staffInfoRoot.auth_info.password_hash}
          password
          disabled
        />
      </div>
    </div>
  );
};

const SearchCardRow4 = ({ staffInfoState, canEdit, isViewMode }) => {
  const compMarginLeft = "4rem";
  const labelWidth = "4rem";
  const compWidth = "11rem";

  return (
    <div style={{ display: "flex", marginTop: "1rem" }}>
      <div style={{}}>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          labelText="· 은행"
          defaultValue={staffInfoState.personal_info.bank}
          onChangeCallback={(e) => {
            staffInfoState.personal_info.bank = e;
          }}
          disabled={!canEdit}
        />
      </div>

      <div style={{ marginLeft: compMarginLeft }}>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          labelText="· 계좌번호"
          defaultValue={staffInfoState.personal_info.account_no}
          onChangeCallback={(e) => {
            staffInfoState.personal_info.account_no = e;
          }}
          disabled={!canEdit}
        />
      </div>
      <div style={{ marginLeft: "5.2rem" }}>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          labelText="· 예금주"
          defaultValue={staffInfoState.personal_info.account_holder}
          onChangeCallback={(e) => {
            staffInfoState.personal_info.account_holder = e;
          }}
          disabled={!canEdit}
        />
      </div>
      <div style={{ width: "25%" }}></div>
    </div>
  );
};

const SearchCardRow5 = ({
  staffInfoState,
  canEdit,
  isViewMode,
  onClickOpenModal,
  storesDispatch,
  staffInfoRoot,
  setIdCardFile,
  setAccCopyFile,
}) => {
  const compMarginLeft = "4rem";
  const labelWidth = "4rem";
  const compWidth = "11rem";
  const btnStyleClass = btnStyles();
  // const [cardInfo, setCardInfo] = useState(staffInfoRoot.access_info.card_info);

  const uploadDoneCallback = (result) => {};
  // console.log(staffInfoState.files.id_card.file_info);
  // console.log(staffInfoState.files.is_submit_id_card === "등록");
  return (
    <div style={{ display: "flex", marginTop: "2rem" }}>
      <div style={{ display: "flex" }}>
        <ComboBox
          marginRight="0rem"
          labelWidth={labelWidth}
          comboItemWidth={"10.5rem"}
          labelText="· 출입방식"
          defaultValue={staffInfoRoot.access_info.access_method}
          data={["카드"]}
          disabled={!canEdit}
        />
        <TextField
          labelMarginRight="0.25rem"
          textMarginRight="0.25rem"
          labelWidth={"0rem"}
          textBoxWidth={"6rem"}
          defaultValue={staffInfoRoot.access_info.card_no}
          disabled
        />
        {/* <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType2]: true,
          })}
          // onClick={() => onSearchCard()}
        >
          변경
        </Button> */}
      </div>

      <div style={{ display: "flex", marginLeft: "3rem" }}>
        <FileUpload
          labelWidth={"6rem"}
          textBoxWidth={"11rem"}
          textMarginRight={"0rem"}
          labelText={"· 주민등록등본"}
          initFile={
            staffInfoState.staff_info.files.is_submit_id_card === "등록완료"
              ? staffInfoState.staff_info.files.id_card.file_info
              : null
          }
          hideUploaded={true}
          disabled
          // onUploadFile={(file) => {
          //   console.log(file);
          //   staffInfoState.staff_info.files.id_card.file_info.file_group_id =
          //     staffInfoState.staff_info.files.temp_group_id;
          //   uploadFile(staffInfoState.staff_info.files.id_card, file, uploadDoneCallback);
          //   // onUploadFile("id_card", file)
          //   // setIdCardFile(file);
          //   // staffInfoRoot.staff_info.files.id_card.file_info.file_name = file.name;
          //   // staffInfoRoot.staff_info.files.id_card.file_info.file_type = file.type;
          // }}
        />
      </div>

      <div style={{ display: "flex", marginLeft: "2.5rem" }}>
        <FileUpload
          labelWidth={"4rem"}
          textBoxWidth={"11rem"}
          textMarginRight={"0rem"}
          labelText={"· 통장사본"}
          initFile={
            staffInfoState.staff_info.files.is_submit_account_copy === "등록완료"
              ? staffInfoState.staff_info.files.account_copy.file_info
              : null
          }
          hideUploaded={true}
          disabled
          // onUploadFile={(file) => {
          //   // onUploadFile("id_card", file)
          //   setAccCopyFile(file);
          //   staffInfoRoot.staff_info.files.account_copy.file_info.file_name = file.name;
          //   staffInfoRoot.staff_info.files.account_copy.file_info.file_type = file.type;
          // }}
        />
      </div>
    </div>
  );
};

const PersonalInfoView = ({
  // mode,
  staffInfoState,
  // onSaveEvent,
  // onClickOpenModal,
  // setIdCardFile,
  // setAccCopyFile,
}) => {
  const labelWidth = "30%";
  const gridSpacing = 3;
  const btnStyleClass = btnStyles();

  const storesDispatch = useDispatch();

  return (
    <CardFixed
      topDom={
        <div style={{ display: "flex" }}>
          기본정보
          {/* {mode === "approval" ? (
            <></>
          ) : (
            <div style={{ display: "flex", float: "right", marginLeft: "auto" }}>
              <Button
                className={clsx({
                  [btnStyleClass.btnRoot]: true,
                  [btnStyleClass.buttonType6]: true,
                })}
                // onClick={() => onSaveEvent()}
              >
                저장
              </Button>
            </div>
          )} */}
        </div>
      }
      midDom={
        <div style={{ display: "flex", marginTop: "1.25rem" }}>
          <div style={{ width: "15%" }}>
            <PhotoComponent
              // inputRef={personalInfoRefs.photo}
              // staff_id={getData !== null ? getData.id : ""}
              // branch_id={getData !== null ? getData.hr_info.branch_info.branch_id : ""}
              // photo_file={getData !== null ? getData.personal_info.photo_file : ""}
              hideUploaded={true}
            />
          </div>

          <div style={{ width: "85%", marginLeft: "1rem" }}>
            <SearchCardRow1
              staffInfoState={staffInfoState.staff_info}
              canEdit={false}
              isViewMode={true}
            />
            <SearchCardRow2
              staffInfoState={staffInfoState.staff_info}
              canEdit={false}
              isViewMode={true}
            />
            <SearchCardRow3
              staffInfoState={staffInfoState.staff_info}
              canEdit={false}
              isViewMode={true}
              staffInfoRoot={staffInfoState}
            />
            <SearchCardRow4
              staffInfoState={staffInfoState.staff_info}
              canEdit={false}
              isViewMode={true}
            />
            <SearchCardRow5
              staffInfoState={staffInfoState}
              canEdit={false}
              isViewMode={true}
              storesDispatch={storesDispatch}
              // onClickOpenModal={onClickOpenModal}
              staffInfoRoot={staffInfoState}
              // setIdCardFile={setIdCardFile}
              // setAccCopyFile={setAccCopyFile}
            />
          </div>
        </div>
      }
      isLoading={false}
      rootStyle={{
        // width: "20rem",
        // height: "15.312rem",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};

export default PersonalInfoView;
