import { useDispatch, useSelector } from "react-redux";

import IconButton from "components/Button/IconButton";
import CardFixed from "components/Card/CardFixed";
import { dataEmpty, getPermission, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loadCardDetailInfoStore } from "../Module/actions";

const CardInfoView = ({ onClickOpenModal, cardId, hqBranchData }) => {
  const navigate = useNavigate();
  const storesDispatch = useDispatch();

  //카드상세 detail Selector
  const cardInfoState = useSelector((state) => state.access_service.card_detail_info);
  const [canDeleteCard, setCanDeleteCard] = useState(false);
  const [loading, setLoading] = useState(false);

  //카드디테일정보

  //출입가능지점추가 모달 POP
  const popAddBranchModal = () => {
    const param = {
      title: "출입 가능 지점 추가",
      modalParam: {
        hqBranchCombo: hqBranchData,
        serviceViewState: cardInfoState.stores.data,
        card_info_id: cardId,
        staffAccessList: cardInfoState.stores.staff_access_list,
      },
    };
    onClickOpenModal("출입가능지점추가", param, addBranchModalDone);
  };

  //출입가능지점추가 모달 DONE
  const addBranchModalDone = () => {
    storesDispatch(loadCardDetailInfoStore(cardId)); //카드상세 화면 갱신
    storesDispatch(globalModalOff()); //모달닫기
  };

  //카드삭제 POST
  const deleteCard = async () => {
    try {
      setLoading(true);

      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const formData = new FormData();
      formData.append("card_no", JSON.stringify(cardInfoState.stores.data.card_no));

      const res = await HttpInstance.post(
        process.env.REACT_APP_BRANCH_SERVER + "/access/card_delete",
        formData,
        config,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label labelText="삭제 되었습니다." />
              </>
            ),
            onBtnOk: () => {
              navigate("/access_card_manage");
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  //카드삭제 MSG
  const alertDeleteCardMsg = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="삭제 하시겠습니까?" />
          </>
        ),
        onBtnYes: () => {
          deleteCard();
        },
        onBtnNo: () => {},
      }),
    );
  };

  //카드정지 POST
  const stopCard = async () => {
    try {
      setLoading(true);

      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const formData = new FormData();

      formData.append("card_info_id", JSON.stringify(cardId));
      const res = await HttpInstance.post(
        process.env.REACT_APP_BRANCH_SERVER + "/access/card_update/suspend",
        formData,
        config,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label labelText="정상적으로 처리되었습니다." />
              </>
            ),
            onBtnOk: () => {
              storesDispatch(loadCardDetailInfoStore(cardId)); //카드상세 갱신
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  //카드정지 MSG
  const alertStopCardMsg = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "카드정지",
        bodyText: (
          <>
            <Label labelText="카드를 정지하시겠습니까?" />
          </>
        ),
        onBtnYes: () => {
          stopCard();
        },
        onBtnNo: () => {},
      }),
    );
  };

  //출입가능지점 삭제POST(재직중이 아닌 지점만 삭제가능)
  const deleteAccessibleBranchForStaff = async (data) => {
    try {
      setLoading(true);

      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const formData = new FormData();
      formData.append("param_info", JSON.stringify(data));
      formData.append("staff_id", JSON.stringify(cardInfoState.stores.cur_user.user_id));
      const res = await HttpInstance.post(
        process.env.REACT_APP_BRANCH_SERVER + "/access/card/branch/remove",
        formData,
        config,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      }
      storesDispatch(loadCardDetailInfoStore(cardId)); //결과를 상세정보 페이지에 새로 반영해야지
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const alertRowDeleteMsg = (data) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText={`${data.branch_info.branch_name}을 삭제하시겠습니까?`} />
          </>
        ),

        onBtnYes: () => {
          deleteAccessibleBranchForStaff(data);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const checkPermission = async () => {
    const result = await getPermission(storesDispatch, "ACCESS_MENU/CARD_MANAGE");
    setCanDeleteCard(result);
  };
  useEffect(() => {
    checkPermission();
  }, []);

  const cardHistTableItem = new cardHistTable();
  const accessibleBranchTableItem = new accessibleBranchTable(alertRowDeleteMsg);

  return (
    <div>
      {loading ? <Loading /> : <></>}

      {/* 카드  정보 */}
      <CardFixed
        midDom={
          <div>
            {canDeleteCard && (
              <>
                <div
                  style={{
                    display: "flex",
                    float: "right",
                  }}
                >
                  {cardInfoState.stores.data.card_status === "대기" ? (
                    <div style={{ display: "flex", gap: "0.3rem" }}>
                      <NormalBtn name="카드 정지" onClick={() => alertStopCardMsg()} />
                      <NormalBtn
                        name="카드 삭제"
                        onClick={() => {
                          alertDeleteCardMsg();
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}

            <SearchCardRow1 serviceViewState={cardInfoState.stores.data} />
            <SearchCardRow2 serviceViewState={cardInfoState.stores.data} />
          </div>
        }
        isLoading={false}
        rootStyle={{
          marginTop: "0.625rem",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />

      {/* 카드 사용자 정보 */}
      <CardFixed
        midDom={<SearchCardRow3 serviceViewState={cardInfoState.stores.data} />}
        isLoading={false}
        rootStyle={{
          marginTop: "0.625rem",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />

      {/* 직원출입 정보 */}
      {cardInfoState.stores.data.user_group === "직원" ? (
        <CardFixed
          midDom={
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <div style={{ display: "flex" }}>
                  <IconButton type="border" />
                  <div style={{ width: "0.5rem" }}></div>
                  <SubTitle titleText="출입 가능 지점" />
                </div>
                {canDeleteCard && (
                  <NormalBtn
                    name="추가"
                    disabled={cardInfoState.stores.data.card_status === "정지"}
                    onClick={() => {
                      popAddBranchModal();
                    }}
                  />
                )}
              </div>

              <CustomTable
                columns_head={accessibleBranchTableItem.columns_head}
                table_title={accessibleBranchTableItem.table_title}
                rest_call={accessibleBranchTableItem.get_data_from_rest}
                row_render={accessibleBranchTableItem.create_table}
                rest_data={cardInfoState.stores.staff_access_list}
              />
            </div>
          }
          isLoading={false}
          rootStyle={{
            marginTop: "0.625rem",
            borderRadius: "10px",
            backgroundColor: "#fff",
          }}
        />
      ) : (
        ""
      )}

      {/* 카드 이력 */}
      <CardFixed
        midDom={
          <div>
            <div style={{ display: "flex" }}>
              <IconButton type="border" />
              <div style={{ width: "0.5rem" }}></div>
              <SubTitle titleText="카드 이력" />
            </div>
            <CustomTable
              columns_head={cardHistTableItem.columns_head}
              table_title={cardHistTableItem.table_title}
              rest_call={cardHistTableItem.get_data_from_rest}
              row_render={cardHistTableItem.create_table}
              rest_data={cardInfoState.stores.data.assign_hist}
            />
          </div>
        }
        isLoading={false}
        rootStyle={{
          marginTop: "0.625rem",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />
    </div>
  );
};
export default CardInfoView;

const SearchCardRow1 = ({ serviceViewState }) => {
  const labelWidth = "5.5rem";
  const compWidth = "15rem";

  return (
    <div>
      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="카드 정보" />
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <TextField
          marginLeft="0.7rem"
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          labelText="카드 상태"
          defaultValue={serviceViewState.card_status}
          disabled
        />
        <TextField
          marginLeft="4rem"
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          labelText="시리얼번호"
          defaultValue={serviceViewState.card_serial_no}
          disabled
        />
        <TextField
          marginLeft="4rem"
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          labelText="카드번호"
          defaultValue={serviceViewState.card_no}
          disabled
        />
      </div>
    </div>
  );
};

const SearchCardRow2 = ({ serviceViewState }) => {
  const labelWidth = "5.5rem";
  const compWidth = "15rem";

  return (
    <div style={{ display: "flex", marginTop: "1rem" }}>
      <TextField
        marginLeft="0.7rem"
        labelWidth={labelWidth}
        textBoxWidth={compWidth}
        labelText="사용자 그룹"
        defaultValue={serviceViewState.user_group}
        disabled
      />
      <TextField
        marginLeft="4rem"
        labelWidth={labelWidth}
        textBoxWidth={compWidth}
        labelText="지점"
        defaultValue={serviceViewState.branch_info.branch_name}
        disabled
      />
      <TextField
        marginLeft="4rem"
        labelWidth={labelWidth}
        textBoxWidth={compWidth}
        labelText="비고"
        defaultValue={serviceViewState.remarks}
        disabled
      />
    </div>
  );
};

const SearchCardRow3 = ({ serviceViewState }) => {
  const labelWidth = "5.5rem";
  const compWidth = "15rem";

  return (
    <div>
      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="사용자 정보" />
      </div>

      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <TextField
          marginLeft="0.7rem"
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          labelText="사용자명"
          defaultValue={serviceViewState.cur_user.user_name}
          disabled
        />

        {serviceViewState.user_group === "회원" ? (
          <TextField
            marginLeft="4rem"
            labelWidth={labelWidth}
            textBoxWidth={compWidth}
            labelText="회원번호"
            defaultValue={serviceViewState.cur_user.member_no}
            disabled
          />
        ) : (
          <TextField
            marginLeft="4rem"
            labelWidth={labelWidth}
            textBoxWidth={compWidth}
            labelText="사번"
            defaultValue={serviceViewState.cur_user.sabun}
            disabled
          />
        )}
      </div>
    </div>
  );
};

class cardHistTable {
  table_title = "";

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.reg_date}</CustomTableContents>
        <CustomTableContents>{data.card_status}</CustomTableContents>
        <CustomTableContents>{data.user_group}</CustomTableContents>

        <CustomTableContents>{data.reason}</CustomTableContents>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.user_name}</CustomTableContents>
        <CustomTableContents>{data.member_no}</CustomTableContents>
        <CustomTableContents>{data.sabun}</CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "등록일시", width: "13%" },
    { title: "카드상태", width: "7%" },
    { title: "사용자 그룹", width: "7%" },
    { title: "사유", width: "19%" },
    { title: "지점", width: "18%" },
    { title: "사용자명", width: "15%" },
    { title: "회원번호", width: "10%" },
    { title: "사번", width: "10%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}

class accessibleBranchTable {
  table_title = "";
  alertRowDeleteMsg = null;
  constructor(alertRowDeleteMsg) {
    this.alertRowDeleteMsg = alertRowDeleteMsg;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.hq_info.hq_name}</CustomTableContents>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.is_empty_work_info ? "" : "재직"}</CustomTableContents>
        <CustomTableContents>
          {data.is_empty_work_info ? (
            <IconButton
              type="delete"
              onClick={() => {
                this.alertRowDeleteMsg(data);
              }}
            />
          ) : (
            ""
          )}
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "등록일", width: "25%" },
    { title: "본부명", width: "25%" },
    { title: "지점", width: "20%" },
    { title: "재직 여부", width: "20%" },
    { title: "", width: "10%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
