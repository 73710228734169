import IconButton from "components/Button/IconButton";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import { useEffect, useState } from "react";

const RegTypeSalesTable = ({ reportState }) => {
  const resultTableInst = new resultTable();
  const [totalCnt, setTotalCnt] = useState(0);

  useEffect(() => {
    const sum =
      reportState.pt_sales_type.length > 0
        ? reportState.pt_sales_type[0].ot_pt +
          reportState.pt_sales_type[0].pt_repay +
          reportState.pt_sales_type[0].work_in
        : 0;
    setTotalCnt(sum);
  }, [reportState]);

  return (
    <div>
      <div style={{ display: "flex", marginBottom: "0.5rem" }}>
        <IconButton type="border" />
        <Label labelText="등록 구분별 매출" marginLeft="0.5rem" fontWeight="bold" />
      </div>
      <CustomTable
        columns_head={resultTableInst.columns_head}
        table_title={resultTableInst.table_title}
        rest_call={resultTableInst.get_data_from_rest}
        row_render={resultTableInst.create_table}
        rest_data={reportState.pt_sales_type}
      />
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem" }}>
        <TextField
          labelText="합계"
          labelWidth="3rem"
          textBoxWidth="10rem"
          textMarginRight="0rem"
          isNumber
          defaultValue={totalCnt.toLocaleString()}
          endAdornment={"원"}
          disabled
        />
      </div>
    </div>
  );
};
export default RegTypeSalesTable;

class resultTable {
  table_title = "";

  // constructor() {}

  create_table = (data, idx) => {
    return (
      <CustomRow style={{}} key={idx}>
        <CustomTableContents $bgColor="#dfebf3">매출</CustomTableContents>
        <CustomTableContents $isNumber>{data.work_in.toLocaleString()}</CustomTableContents>
        <CustomTableContents $isNumber>{data.ot_pt.toLocaleString()}</CustomTableContents>
        <CustomTableContents $isNumber>{data.pt_repay.toLocaleString()}</CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "구분", width: "25%" },
    { title: "워크인", width: "25%" },
    { title: "OT 후 PT", width: "25%" },
    { title: "PT 재등록", width: "25%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
