import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { getCurrentDate } from "components/CommonLib/CommonLib";
import DatePicker from "components/LabelInput/DatePicker";
import FileUpload from "components/LabelInput/FileUpload";
import Label from "components/LabelInput/Label";
import { globalAlertOn } from "modules/actions/Alert";
import { useState } from "react";
import { useDispatch } from "react-redux";

const ResignPopup = ({ params, onModalDone }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const [file, setFile] = useState();

  const onClickSave = () => {
    if (!params.workInfoStore.base_info.resign_date) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="퇴직 날짜를 선택해주세요." />,
          onBtnOk: () => {},
        }),
      );
    } else if (!params.privileged && !file) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="사직서를 첨부해 주시기 바랍니다." />,
          onBtnOk: () => {},
        }),
      );
    } else {
      const labelText = params.privileged
        ? "퇴직 처리 하시겠습니까?"
        : "퇴직 기안을 상신하시겠습니까?";
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText={labelText} />,
          onBtnYes: () => {
            onModalDone("퇴직", file);
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  return (
    <div>
      <DatePicker
        labelWidth={"4rem"}
        minWidth={"9.5rem"}
        labelText="퇴사일"
        maxDate={getCurrentDate()}
        onChangeCallback={(e) => {
          params.workInfoStore.base_info.resign_date = e;
        }}
      />
      {/* {params.privileged ? ( */}
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <FileUpload
          labelWidth={"4rem"}
          textBoxWidth={"11rem"}
          textMarginRight={"0rem"}
          labelText="사직서"
          onUploadFile={(file) => {
            setFile(file);
            params.workInfoStore.files.resign_file.file_name = file.name;
            params.workInfoStore.files.resign_file.file_type = file.type;
          }}
          hideDownload
        />
      </div>
      {/* // ) : (
      //   <></>
      // )} */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1.5rem",
          // marginBottom: "5rem",
        }}
      >
        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={onClickSave}
        >
          저장
        </Button>
      </div>
    </div>
  );
};
export default ResignPopup;
