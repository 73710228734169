import { contentTypeJson, simpleAlert, SubLine } from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import Label from "components/LabelInput/Label";
import NumberTextField from "components/LabelInput/NumberTextField";
import Loading from "components/Loading/Loading/Loading";
import ModalBtnModeGroup from "components/ModalBtnModeGroup/ModalBtnModeGroup";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadHqView, loadHqViewReset } from "../Module/actions/index";
import post_api from "../Module/api/PostApi";
import PolicyStaffSetting from "./PolicyStaffSetting";

const AssignSetting = ({ restData }) => {
  const labelWidth = "13rem";
  return (
    <div>
      <SubTitle titleText={"양도"} />

      <div
        style={{
          display: "flex",
        }}
      >
        <Label
          labelText="· 양도 시 행사기간 제외 선택 옵션"
          marginLeft="1rem"
          justifyContent="start"
          width={labelWidth}
          fontSize="0.9rem"
          fontWeight="bold"
        />
        <CheckBox
          marginTop="0rem"
          marginLeft="6rem"
          labelText="모든 행사기간 포함"
          defaultValue={restData.policy_set.assign_option.include_all}
          onChangeCallback={(e) => {
            restData.policy_set.assign_option.include_all = e.checked;
          }}
        />
        <CheckBox
          marginTop="0rem"
          labelText="기본 행사기간 제외"
          defaultValue={restData.policy_set.assign_option.except_service}
          onChangeCallback={(e) => {
            restData.policy_set.assign_option.except_service = e.checked;
          }}
        />
        <CheckBox
          marginTop="0rem"
          labelText="행사 추가기간 제외"
          defaultValue={restData.policy_set.assign_option.except_event}
          onChangeCallback={(e) => {
            restData.policy_set.assign_option.except_event = e.checked;
          }}
        />
        <CheckBox
          marginTop="0rem"
          labelText="모든 행사기간 제외"
          defaultValue={restData.policy_set.assign_option.except_all}
          onChangeCallback={(e) => {
            restData.policy_set.assign_option.except_all = e.checked;
          }}
        />
      </div>
    </div>
  );
};
const PostponeSetting = ({ restData }) => {
  const itemLabelWidth = "5rem";
  const labelWidth = "13rem";
  const textBoxWidth = "12rem";

  return (
    <div>
      <SubTitle titleText={"연기"} />

      <div
        style={{
          display: "flex",
        }}
      >
        <Label
          labelText="· 1회 연기기간"
          marginLeft="1rem"
          justifyContent="start"
          width={labelWidth}
          fontSize="0.9rem"
          fontWeight="bold"
        />
        <NumberTextField
          isNumber
          labelWidth={itemLabelWidth}
          textBoxWidth={textBoxWidth}
          labelText="완납형 : "
          endAdornment="개월"
          maxValue={6}
          defaultValue={restData.policy_set.prepay_postpone_month}
          onChangeCallback={(e) => {
            restData.policy_set.prepay_postpone_month = e;
          }}
        />

        <NumberTextField
          isNumber
          labelMarginRight={"0.512rem"}
          labelWidth={"1rem"}
          textBoxWidth={textBoxWidth}
          // minValue={0}
          maxValue={30}
          endAdornment="일"
          defaultValue={restData.policy_set.prepay_postpone_day}
          onChangeCallback={(e) => {
            restData.policy_set.prepay_postpone_day = e;
          }}
        />
      </div>

      <div
        style={{
          display: "flex",
          marginTop: "0.5rem",
        }}
      >
        <Label labelText=" " marginLeft="1rem" justifyContent="start" width={labelWidth} />
        <NumberTextField
          required
          isNumber
          labelWidth={itemLabelWidth}
          textBoxWidth={textBoxWidth}
          labelText="구독형 : "
          minValue={1}
          maxValue={6}
          endAdornment="개월"
          defaultValue={restData.policy_set.subs_postpone_month}
          onChangeCallback={(e) => {
            restData.policy_set.subs_postpone_month = e;
          }}
        />
      </div>

      <div
        style={{
          display: "flex",
          marginTop: "0.5rem",
        }}
      >
        <Label
          labelText="· 기본 연기 횟수"
          marginLeft="1rem"
          justifyContent="start"
          width={labelWidth}
          fontSize="0.9rem"
          fontWeight="bold"
        />

        <NumberTextField
          required
          isNumber
          labelWidth={itemLabelWidth}
          textBoxWidth={textBoxWidth}
          labelText="기본 횟수 :"
          maxValue={12}
          endAdornment="개월당 1회"
          defaultValue={restData.policy_set.postpone_month}
          onChangeCallback={(e) => {
            restData.policy_set.postpone_month = e;
          }}
        />
        {/* <Label textAlign={"left"} labelText={"개월당 1회"} /> */}
      </div>
    </div>
  );
};

const RefundSetting = ({ restData }) => {
  const textBoxWidth = "12rem";
  const itemLabelWidth = "5rem";
  const labelWidth = "13rem";

  return (
    <div>
      <div
        style={{
          display: "flex",
        }}
      >
        <Label
          labelText="· 회원권 환불 시 이용금액 계산"
          marginLeft="1rem"
          justifyContent="start"
          width={labelWidth}
          fontSize="0.9rem"
          fontWeight="bold"
        />
        <NumberTextField
          required
          isNumber
          labelWidth={itemLabelWidth}
          textBoxWidth={textBoxWidth}
          labelText="1일당"
          maxValue={50000}
          endAdornment="원"
          defaultValue={restData.policy_set.mbshp_rfnd_day_price}
          onChangeCallback={(e) => {
            restData.policy_set.mbshp_rfnd_day_price = e;
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "0.5rem",
        }}
      >
        <Label
          labelText="· 완납형 상품 환불 위약금"
          marginLeft="1rem"
          justifyContent="start"
          width={labelWidth}
          fontSize="0.9rem"
          fontWeight="bold"
        />

        <NumberTextField
          required
          isNumber
          labelMarginRight={"0.55rem"}
          labelWidth={itemLabelWidth}
          textBoxWidth={textBoxWidth}
          labelText="상품금액의"
          minValue={0}
          maxValue={99}
          endAdornment="%"
          defaultValue={restData.policy_set.prepay_penalty_rate}
          onChangeCallback={(e) => {
            restData.policy_set.prepay_penalty_rate = e;
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "0.5rem",
        }}
      >
        <Label
          labelText="· 구독형 상품 약정 해지 위약금"
          marginLeft="1rem"
          justifyContent="start"
          width={labelWidth}
          fontSize="0.9rem"
          fontWeight="bold"
        />
        <NumberTextField
          required
          isNumber
          labelMarginRight={"0.55rem"}
          labelWidth={itemLabelWidth}
          textBoxWidth={textBoxWidth}
          labelText="약정총액의"
          minValue={0}
          maxValue={99}
          endAdornment="%"
          defaultValue={restData.policy_set.subs_penalty_rate}
          onChangeCallback={(e) => {
            restData.policy_set.subs_penalty_rate = e;
          }}
        />
      </div>
    </div>
  );
};

const PolicyManagement = ({ onClickOpenModal, hqId }) => {
  const storesDispatch = useDispatch();

  const restData = useSelector((state) => state.hq_set_state.hq_hq_set_state);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (hqId !== "") {
      storesDispatch(loadHqView(hqId));
    }

    return () => {
      storesDispatch(loadHqViewReset());
    };
  }, [loadHqView, storesDispatch, hqId]);

  const validation = () => {
    if (
      restData.stores.data.contents.policy_set.mbshp_rfnd_day_price > 1 &&
      restData.stores.data.contents.policy_set.prepay_penalty_rate > 1 &&
      restData.stores.data.contents.policy_set.subs_penalty_rate > 1

      // restData.policy_set.prepay_postpone_month &&
      // restData.policy_set.prepay_postpone_day &&
      // restData.policy_set.subs_postpone_month &&
      // restData.policy_set.postpone_month
    ) {
      return true;
    }
    return false;
  };

  const savePopup = () => {
    if (validation() === false) {
      simpleAlert(storesDispatch, "알림", "필수값을 모두 입력해주셔야 합니다.");
    } else {
      simpleAlert(storesDispatch, "알림", "저장하시겠습니까?", false, undefined, onSaveEvent);
    }
  };

  const onSaveEvent = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("setting_info", JSON.stringify(restData.stores.data.contents));
    await HttpInstance.post(post_api.updatePolicyApi(), formData, contentTypeJson)
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          simpleAlert(storesDispatch, "알림", "저장되었습니다.", false, () => {
            storesDispatch(loadHqView(hqId));
          });
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const viewRefresh = () => {
    storesDispatch(loadHqView(hqId));
  };

  const borderStyle = {
    border: "1px solid #888",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    marginBottom: "1rem",
    // width: borderWidth,
  };

  return (
    <div>
      {loading && <Loading />}

      <SubTitle titleText={"< 회원권 정책 >"} fontSize={"1.1rem"} />
      <div style={borderStyle}>
        <div style={{ display: "flex" }}>
          <SubTitle titleText={"환불"} />
          <ModalBtnModeGroup mode={"edit"} onClickEvent={savePopup} />
        </div>
        <RefundSetting restData={restData.stores.data.contents} />
        <SubLine />
        <PostponeSetting restData={restData.stores.data.contents} />
        <SubLine />
        <AssignSetting restData={restData.stores.data.contents} />
      </div>
      <SubTitle titleText={"< 급여 정책 >"} fontSize={"1.1rem"} />
      <div style={borderStyle}>
        <PolicyStaffSetting
          restData={restData.stores.data.contents}
          onClickOpenModal={onClickOpenModal}
          viewRefresh={viewRefresh}
        />
      </div>
    </div>
  );
};
export default PolicyManagement;
