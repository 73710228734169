import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";

const SearchResultTableMbshp = ({
  searchResult,
  navigate,
  curPage,
  setCurPage,
  totalPage,
  onClickSearch,
}) => {
  const searchResultList = new resultTable(navigate);

  const doSearch = (page) => {
    onClickSearch(false, page);
  };

  return (
    <div style={{ margin: "0px 1px" }}>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
        width="220rem"
      />
      <CustomPagination
        curPage={curPage}
        // pageSelection={setCurPage}
        totalCnt={totalPage}
        doSearch={doSearch}
      />
    </div>
  );
};
export default SearchResultTableMbshp;

class resultTable {
  table_title = "";
  onNavigate = null;

  constructor(navigate) {
    this.onNavigate = navigate;
  }

  onClickEvent = (data) => {
    // this.onNavigate("/staff_info/", {
    //   state: {
    //     staff_id: data.staff_id,
    //     is_view: true,
    //   },
    // });
  };

  getCurManagerName = (data) => {
    if (!data.sub_result.active_mbshp.ot_info.cur_manager.staff_info.staff_name) {
      return "미배정";
    } else {
      return data.sub_result.active_mbshp.ot_info.cur_manager.staff_info.staff_name;
    }
  };

  create_table = (data, idx) => {
    return (
      <CustomRow
        style={{}}
        key={idx}
        // onClick={() => this.onClickEvent(data)}
        // sx={{
        //   "&.MuiTableRow-root:hover": {
        //     backgroundColor: "red",
        //   },
        // }}
        // className={this.styleClasses.MuiTableRow}
        hover
      >
        <CustomTableContents>{data.base_result.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.base_result.member_type}</CustomTableContents>
        <CustomTableContents>{data.base_result.member_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.base_result.member_info.member_name}</CustomTableContents>
        <CustomTableContents>{data.base_result.member_info.phone}</CustomTableContents>
        <CustomTableContents>{data.base_result.member_info.birth}</CustomTableContents>
        <CustomTableContents>{data.base_result.member_info.gender}</CustomTableContents>
        <CustomTableContents>{data.base_result.active_pt_status}</CustomTableContents>
        <CustomTableContents>{data.base_result.remain_pt_cnt}</CustomTableContents>
        <CustomTableContents>{data.base_result.last_mbshp_to_date}</CustomTableContents>
        <CustomTableContents>{data.sub_result.active_mbshp.mbshp_type}</CustomTableContents>
        <CustomTableContents>{data.sub_result.active_mbshp.mbshp_option}</CustomTableContents>
        <CustomTableContents>{data.sub_result.active_mbshp.item_name}</CustomTableContents>
        <CustomTableContents>{data.sub_result.active_mbshp.mbshp_stauts}</CustomTableContents>
        <CustomTableContents>{data.sub_result.active_mbshp.from_date}</CustomTableContents>
        <CustomTableContents>{data.sub_result.active_mbshp.to_date}</CustomTableContents>
        <CustomTableContents>
          {data.sub_result.active_mbshp.expiration_info.reason}
        </CustomTableContents>
        <CustomTableContents>{data.sub_result.active_mbshp.is_use_cloth}</CustomTableContents>
        <CustomTableContents>{data.sub_result.active_mbshp.is_use_gx}</CustomTableContents>
        <CustomTableContents>{data.sub_result.active_mbshp.is_use_locker}</CustomTableContents>
        <CustomTableContents>
          {data.sub_result.active_mbshp.mbshp_price.paid_price}
        </CustomTableContents>
        <CustomTableContents>
          {data.sub_result.active_mbshp.cloth_price.paid_price}
        </CustomTableContents>
        <CustomTableContents>
          {data.sub_result.active_mbshp.gx_price.paid_price}
        </CustomTableContents>
        <CustomTableContents>
          {data.sub_result.active_mbshp.locker_price.paid_price}
        </CustomTableContents>
        <CustomTableContents>{this.getCurManagerName(data)}</CustomTableContents>
        <CustomTableContentsEND>
          {data.sub_result.active_mbshp.ot_info.ot_info.remain_session_cnt}
        </CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "지점", width: "3%" },
    { title: "구분", width: "3%" },
    { title: "회원번호", width: "3%" },
    { title: "이름", width: "3%" },
    { title: "연락처", width: "3%" },
    { title: "생년월일", width: "3%" },
    { title: "성별", width: "3%" },
    { title: "PT 수강 상태", width: "3%" },
    { title: "PT 총 잔여 횟수", width: "3%" },
    { title: "최종 회원권 종료일", width: "3%" },
    { title: "회원권 종류", width: "3%" },
    { title: "회원권 옵션", width: "3%" },
    { title: "회원권 상품명", width: "3%" },
    { title: "회원권 상태", width: "3%" },
    { title: "유효기간 시작일", width: "3%" },
    { title: "유효기간 종료일", width: "3%" },
    { title: "만료 사유", width: "3%" },
    { title: "운동복", width: "3%" },
    { title: "GX", width: "3%" },
    { title: "락커", width: "3%" },
    { title: "회원권 결제금액", width: "3%" },
    { title: "운동복 결제금액", width: "3%" },
    { title: "GX 결제금액", width: "3%" },
    { title: "락커 결제금액", width: "3%" },
    { title: "OT 수업 담당자", width: "3%" },
    { title: "OT 잔여 횟수", width: "3%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
