import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import TimePicker from "components/LabelInput/TimePicker";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useState } from "react";
import { useDispatch } from "react-redux";

const GxDetailAddModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();
  const box_width = "18.5rem";
  const label_width = "4rem";
  const [addInfo, setAddInfo] = useState({
    class_name: "",
    from_date: "",
    from_time: "",
    staff_id: modalParam.staffId,
  });
  const [loading, setLoading] = useState(false);

  const validationCheck = () => {
    if (!addInfo.class_name) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="수업명을 입력해주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else if (!addInfo.from_date || !addInfo.from_time) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="수업일시를 선택해주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="저장 하시겠습니까?" />
            </>
          ),
          onBtnYes: () => {
            addGxSalary();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const addGxSalary = async () => {
    try {
      const formData = new FormData();
      formData.append("salary_report_id", modalParam.salaryReportId);
      formData.append("new_info", JSON.stringify(addInfo));
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_SALARY_SERVER + "/salary/gx/add",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label labelText="저장 되었습니다." />
              </>
            ),
            onBtnOk: () => {
              //GX탭 + 메인 테이블 갱신 + 모달 닫기
              onModalDone();
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      {loading && <Loading />}

      <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        <TextField
          labelWidth={label_width}
          textBoxWidth={box_width}
          labelText="사번"
          disabled
          defaultValue={modalParam.sabun}
        />
        <TextField
          labelWidth={label_width}
          textBoxWidth={box_width}
          labelText="직원명"
          disabled
          defaultValue={modalParam.staffName}
        />
        <ComboBox
          labelWidth={label_width}
          comboItemWidth={box_width}
          labelText="수업명"
          data={modalParam.modalCombo}
          onChangeCallback={(e) => {
            setAddInfo((cur) => {
              return { ...cur, class_name: e.target.value };
            });
          }}
        />
        <div style={{ display: "flex" }}>
          <DatePicker
            labelText="수업 일시"
            textMarginRight={"0rem"}
            labelWidth={label_width}
            textBoxWidth={"9rem"}
            onChangeCallback={(e) => {
              setAddInfo((cur) => {
                return { ...cur, from_date: e };
              });
            }}
          />
          <TimePicker
            textBoxWidth={"9rem"}
            onChange={(e) => {
              setAddInfo((cur) => {
                return { ...cur, from_time: e };
              });
            }}
          />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType5]: true,
          })}
          onClick={validationCheck}
        >
          추가
        </Button>
      </div>
    </div>
  );
};
export default GxDetailAddModal;
