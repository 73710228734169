export const classSessionInitState = {
  stores: {
    data: {
      staff_id: "string / ID",
      join_status: "string / 가입 상태",
      can_login: "string / 로그인 허용",
      temp_branch_name: "string / 임시 지점명",

      personal_info: {
        sabun: "string / 사번",
        staff_name: "string / 이름",
        phone: "string / 연락처",
        gender: "string / 성별",
        birthday: "date / 생년월일",
        verify: false,
        zip_code: "string / 우편번호",
        address_1: "string / 주소",
        address_2: "string / 세부주소",
        social_number: "string / 주민번호",
        email: "string / 이메일",
        bank: "string / 은행명",
        account_no: "string / 계좌번호",
        account_holder: "string / 예금주",
      },

      affiliation: [],
      accessible: [],

      military: {
        idx: 0,
        is_serviced: false,
        exception_reason: "string / 병역특례 사유",
        service_from_date: "date / 복무 시작일",
        service_end_date: "date / 복무 종료일",
        rank: "string / 최종 계급",
        category: "string / 군별",
        specialty: "string / 병과",
      },

      education: [
        // {
        //   idx: 0,
        //   degree: "string / 학위 구분",
        //   org_name: "string / 학교명",
        //   major: "string / 전공명",
        //   grad_type: "string / 졸업 여부",
        //   grad_date: "date / 졸업일",
        // },
      ],

      career: [
        // {
        //   idx: 0,
        //   comp_name: "string / 회사명",
        //   work_from_date: "date / 재직 시작일",
        //   work_end_date: "date / 재직 종료일",
        //   position: "string / 직책",
        // },
      ],

      family: [
        // {
        //   idx: 0,
        //   relation: "string / 관계",
        //   name: "string / 이름",
        //   birthday: "date / 생년월일",
        //   job: "string / 직업",
        //   is_cohabit: false,
        // },
      ],

      award: [
        // {
        //   idx: 0,
        //   award_name: "string / 수상명",
        //   award_date: "date / 수상일",
        //   award_inst: "string / 수상처",
        // },
      ],

      qualifications: [
        // {
        //   idx: 0,
        //   qual_name: "string / 자격증명",
        //   qual_date: "date / 발급일",
        //   qual_inst: "string / 발급처",
        // },
      ],

      files: {
        id_card: "string / 주민등록등본 파일명",
        is_submit_id_card: "미등록",
        account_copy: "string / 통장사본 파일명",
        is_submit_account_copy: "미등록",
      },
    },
  },
};
