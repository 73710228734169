import { inputInitState } from "./init_state";
import * as ActionTypes from "../actions/action_type";

const initialStete = {
  stores: {
    data: inputInitState,
  },
};
export const change_branch_view_info = (state = initialStete, action) => {
  switch (action.type) {
    case ActionTypes.getChangeBranchSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getChangeBranchFailAction:
    case ActionTypes.getChangeBranchResetAction:
      return { ...state, stores: initialStete.stores };
    default:
      return state;
  }
};
