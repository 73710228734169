import { getCurrentDate } from "components/CommonLib/CommonLib";

export const add_member_tm_state = {
  hist_id: "",
  member_info: {
    member_id: "",
    member_name: "",
    member_no: "",
  },
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  member_type: "",
  phone: "",

  tm_info: {
    cs_date: getCurrentDate(),
    cs_time: "", //getCurrentTime(true),
    cs_type: "TM 처리",

    inquiry_title: "TM 처리",
    inquiry_memo: "",

    tm_status: "TM 완료",

    reg_info: {
      created_at: "",
      reg_id: "",
      reg_name: "",
      upd_id: "",
      upd_name: "",
      updated_at: "",
    },
    staff_info: {
      staff_id: "",
      staff_name: "",
      staff_pos: "",
      staff_rank: "",
    },
  },

  visit_plan: {
    visit_date: getCurrentDate(),
    visit_time: "",
  },
};
