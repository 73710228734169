import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { getNowServiceState } from "pages/NoticeBoard/model/board_row";
import { useParams } from "react-router-dom";
import NoticeDetailWrapper from "./NoticeDetailWrapper";
const NoticeDetailService = () => {
  const params = useParams();
  const nowService = "공지사항";
  // console.log(params.postId);
  return (
    <div>
      <PageFrame
        children={
          <div>
            <PageTitle titleText="공지사항" />
            <NoticeDetailWrapper
              nowService={getNowServiceState(nowService)}
              postId={params.postId}
            />
          </div>
        }
      />
    </div>
  );
};

export default NoticeDetailService;
