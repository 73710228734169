import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { CustomRow, CustomTableCell } from "components/CustomTable/tableStyle";
import { useEffect, useState } from "react";

const TotalInfoTable = ({ refundState }) => {
  // console.log(props);
  const [totalInfo, setTotalInfo] = useState(refundState.refund_sum_mary);

  useEffect(() => {
    setTotalInfo(refundState.refund_sum_mary);
  }, [refundState.refund_sum_mary]);

  return (
    <div style={{ position: "relative", top: "-2px", marginLeft: "0.5px", marginRight: "1px" }}>
      <TableContainer
        style={{
          border: "1px solid #CCC",
        }}
      >
        <Table>
          <TableHead>
            <CustomRow style={{ width: "100%" }}>
              <CustomTableCell style={{ backgroundColor: "#dfebf3" }} align="center" width="16%">
                합계
              </CustomTableCell>
              <CustomTableCell width="14%" $isNumber>
                {totalInfo.paid_price.toLocaleString() + " 원"}
              </CustomTableCell>
              <CustomTableCell width="14%" $isNumber>
                {(totalInfo.deduct_price + totalInfo.penalty).toLocaleString() + " 원"}
              </CustomTableCell>
              <CustomTableCell width="14%" $isNumber>
                {totalInfo.refund_price.toLocaleString() + " 원"}
              </CustomTableCell>
              <CustomTableCell width="14%" $isNumber>
                {totalInfo.done_refund_price.toLocaleString() + " 원"}
              </CustomTableCell>
              <CustomTableCell width="14%" $isNumber>
                {totalInfo.repaid_price.toLocaleString() + " 원"}
              </CustomTableCell>
            </CustomRow>
          </TableHead>
        </Table>
      </TableContainer>
    </div>
  );
};
export default TotalInfoTable;
