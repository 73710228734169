import { useQuery } from "@tanstack/react-query";
import { getGxManageList } from "apis/staff/class_manage_api";
import { simpleAlert } from "components/CommonLib/CommonLib";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { gxSearchInit } from "../Module/reducers/gx_manage_search_param";
import { useClassManageStore } from "../modules/store/classManageState";
import GxSearchCard from "./GxSearchCard";
import GxSearchResult from "./GxSearchResult";

const SearchCard = ({ searchParam, setSearchParam, resetSearchParam, onClickSearch, gxBranch }) => {
  return (
    <GxSearchCard
      searchParam={searchParam}
      setSearchParam={setSearchParam}
      resetSearchParam={resetSearchParam}
      onClickSearch={onClickSearch}
      gxBranch={gxBranch}
    />
  );
};

const SearchResultCard = ({
  searchResult,
  curPage,
  setCurPage,
  totalPage,
  onClickOpenModal,
  refreshTable,
}) => {
  return (
    <GxSearchResult
      searchResult={searchResult}
      curPage={curPage}
      setCurPage={setCurPage}
      totalPage={totalPage}
      onClickOpenModal={onClickOpenModal}
      refreshTable={refreshTable}
    />
  );
};

const GxListView = ({ onClickOpenModal, branchInfo }) => {
  const storesDispatch = useDispatch();

  const [searchParam, setSearchParam] = useState(_.cloneDeep(gxSearchInit));
  const [searchResult, setSearchResult] = useState([]);
  const [curPage, setCurPage] = useState();
  const [totalPage, setTotalPage] = useState(0);

  // 전역 상태 관리
  const { setClassManageLoading: setLoading } = useClassManageStore();
  // API 상태 관리
  const [isGetGxManageList, setIsGetGxManageList] = useState(false);
  const queryData = { page: curPage, searchParam: searchParam };

  // [GET] GX 수업 관리 리스트
  const { data: gxManageList } = useQuery({
    queryKey: ["gxClassManage", queryData],
    queryFn: () => getGxManageList(queryData),
    enabled: isGetGxManageList,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setIsGetGxManageList(false);
      setLoading(false);
    },
  });

  //검색 POST
  const onClickSearch = async (page) => {
    setCurPage(page);
    setLoading(true);
    setIsGetGxManageList(true);
  };

  const refreshTable = () => {
    onClickSearch(1);
  };

  const resetSearchParam = () => {
    setSearchParam(_.cloneDeep(gxSearchInit));
  };

  // API 받아온 데이터 세팅
  useEffect(() => {
    // OT 수업관리 데이터
    if (gxManageList) {
      setSearchResult(gxManageList.data.data);
      setTotalPage(gxManageList.data.total_page);
    }
  }, [gxManageList]);

  // 페이지 진입 시 검색
  useEffect(() => {
    onClickSearch(1);
  }, []);

  useEffect(() => {
    if (curPage) {
      onClickSearch(curPage);
    }
  }, [curPage]);

  return (
    <div>
      <SearchCard
        searchParam={searchParam}
        setSearchParam={setSearchParam}
        resetSearchParam={resetSearchParam}
        onClickSearch={onClickSearch}
        gxBranch={branchInfo}
      />
      <SearchResultCard
        searchResult={searchResult}
        curPage={curPage}
        onClickOpenModal={onClickOpenModal}
        setCurPage={setCurPage}
        totalPage={totalPage}
        refreshTable={refreshTable}
      />
    </div>
  );
};

export default GxListView;
