import * as ActionTypes from "./action_type";

///MEMBER VIEW
export const loadChangeBranchView = (member_id) => {
  return {
    type: ActionTypes.getChangeBranchViewAction,
    member_id: member_id,
  };
};

export const loadChangeBranchViewSuccess = (data) => {
  return {
    type: ActionTypes.getChangeBranchSuccessAction,
    payload: data,
  };
};

export const loadChangeBranchViewFail = (data) => {
  return {
    type: ActionTypes.getChangeBranchFailAction,
    payload: [],
  };
};

export const loadChangeBranchViewReset = (data) => {
  return {
    type: ActionTypes.getChangeBranchResetAction,
    payload: data,
  };
};
