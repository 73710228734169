import * as ActionTypes from "./action_type";

///연동 이력
export const loadBranchLinkHistModal = (member_id) => {
  return {
    type: ActionTypes.getBranchLinkHistViewAction,
    member_id: member_id,
  };
};

export const loadBranchLinkHistModalSuccess = (data) => {
  return {
    type: ActionTypes.getBranchLinkHistViewSuccessAction,
    payload: data,
  };
};

export const loadBranchLinkHistModalFail = (data) => {
  return {
    type: ActionTypes.getBranchLinkHistViewFailAction,
    payload: [],
  };
};

export const loadBranchLinkHistModalReset = (data) => {
  return {
    type: ActionTypes.getBranchLinkHistViewResetAction,
    payload: data,
  };
};

///Documents
export const loadDocumentsModal = (member_id) => {
  return {
    type: ActionTypes.getDocumentsViewAction,
    member_id: member_id,
  };
};

export const loadDocumentsModalSuccess = (data) => {
  return {
    type: ActionTypes.getDocumentsViewSuccessAction,
    payload: data,
  };
};

export const loadDocumentsModalFail = (data) => {
  return {
    type: ActionTypes.getDocumentsViewFailAction,
    payload: [],
  };
};

export const loadDocumentsModalReset = (data) => {
  return {
    type: ActionTypes.getDocumentsViewResetAction,
    payload: data,
  };
};
