import { useEffect, useState } from "react";

import * as lodash from "lodash";
import GxClassDetailModal from "./GxClassDetailModal";
import OtClassDetailModal from "./OtClassDetailModal";
import PtClassDetailModal from "./PtClassDetailModal";

const ClassManageModalIndex = ({ modalSelect, modalParam, onModalDone, ptClassOnclickSearch }) => {
  const [paramData, setParamData] = useState(modalParam.modalParam);

  useEffect(() => {
    const editData = lodash.cloneDeep(modalParam.modalParam);
    setParamData(editData);
    return () => {};
  }, []);

  if (modalSelect === "OT수업상세") {
    return <OtClassDetailModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "PT수업상세") {
    return (
      <PtClassDetailModal
        modalParam={paramData}
        onModalDone={onModalDone}
        ptClassOnclickSearch={ptClassOnclickSearch}
      />
    );
  } else if (modalSelect === "GX수업상세") {
    return <GxClassDetailModal modalParam={paramData} onModalDone={onModalDone} />;
  } else {
    return <div>current ELSE</div>;
  }
};
export default ClassManageModalIndex;
