import { useEffect, useState } from "react";

import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useDispatch } from "react-redux";
import post_api from "../Module/api/PostApi";

const ERPSetFirewall = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();
  const [firewallData, setFirewallData] = useState(modalParam.state);
  const [compDisabled, setCompDisabled] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFirewallData({
      ...firewallData,
      new_ip_addr: firewallData.ip_addr.toString(),
    });
  }, []);

  // 모드에 따른 키 추가
  const checkIpAddr = (mode, ipAddr) => {
    if (mode === "add") {
      setFirewallData({
        ...firewallData,
        ip_addr: ipAddr.toString(),
      });
    }
    if (mode === "edit") {
      setFirewallData({
        ...firewallData,
        new_ip_addr: ipAddr.toString(),
      });
    }
  };

  const onClickSave = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: <Label labelText="저장하시겠습니까?" />,
        onBtnYes: () => {
          onSaveEvent();
        },
        onBtnNo: () => {},
      }),
    );
  };

  const onSaveEvent = async () => {
    const { alias, ip_addr, new_ip_addr } = firewallData;
    let api = "";
    setLoading(true);

    // ERP 접속 허용 IP 수정
    if (modalParam.mode === "edit") {
      api = post_api.updateFirewall();

      await HttpInstance.patch(api, "", {
        params: {
          ip_addr: ip_addr,
          alias: alias,
          new_ip_addr: new_ip_addr,
        },
      })
        .then((res) => {
          if (res.data.msg) {
            simpleAlert(storesDispatch, "ERROR", res.data.msg);
          } else {
            simpleAlert(
              storesDispatch,
              "알림",
              "수정되었습니다.",
              false,
              onModalDone(modalParam.refreshStore),
            );
          }
        })
        .catch((err) => {
          console.log(err, "err");
          simpleAlert(
            storesDispatch,
            "ERROR",
            err.response.data ? err.response.data.msg : err.code,
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // ERP 접속 허용 IP 추가
    if (modalParam.mode === "add") {
      api = post_api.addFirewall();

      const formData = new FormData();
      formData.append("alias", alias);
      formData.append("ip_addr", ip_addr);

      await HttpInstance.post(api, formData, contentTypeJson)
        .then((res) => {
          if (res.data.msg) {
            simpleAlert(storesDispatch, "ERROR", res.data.msg);
          } else {
            simpleAlert(
              storesDispatch,
              "알림",
              "추가되었습니다.",
              false,
              onModalDone(modalParam.refreshStore),
            );
          }
        })
        .catch((err) => {
          console.log(err, "err");
          simpleAlert(
            storesDispatch,
            "ERROR",
            err.response.data ? err.response.data.msg : err.code,
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div>
      {loading && <Loading />}

      <div
        style={{
          width: "39rem",
          marginBottom: "0.5rem",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <TextField
          required
          labelWidth={"3rem"}
          textBoxWidth={"12rem"}
          labelText="별칭"
          disabled={compDisabled}
          defaultValue={firewallData.alias || ""}
          onChangeCallback={(e) => {
            setFirewallData({
              ...firewallData,
              alias: e,
            });
          }}
        />

        <TextField
          required
          marginLeft="1rem"
          labelWidth={"3rem"}
          textBoxWidth={"12rem"}
          textMarginRight={"1rem"}
          labelText="IP 주소"
          maxLength={15}
          minValue={0}
          disabled={compDisabled}
          defaultValue={firewallData.ip_addr}
          onChangeCallback={(e) => checkIpAddr(modalParam.mode, e)}
        />
        <div>
          {modalParam.mode === "edit" ? (
            <Button
              className={clsx({
                [btnStyleClass.btnRoot]: true,
                [btnStyleClass.buttonType2]: true,
              })}
              onClick={onClickSave}
            >
              저장
            </Button>
          ) : (
            <Button
              className={clsx({
                [btnStyleClass.btnRoot]: true,
                [btnStyleClass.buttonType2]: true,
              })}
              onClick={onClickSave}
            >
              등록
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
export default ERPSetFirewall;
