import ApprovalInfo from "pages/ApprovalManage/ApprovalInfo/ApprovalInfo";

const ShowApproveDoc = ({ docType, approvalId }) => {
  return (
    <div>
      <ApprovalInfo
        mode="view"
        setMode={() => {}}
        docType={docType}
        approvalId={approvalId}
        isCashbook={true}
      />
    </div>
  );
};
export default ShowApproveDoc;
