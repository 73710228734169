import { generateObjectZeroId } from "components/CommonLib/CommonLib";
import { create } from "zustand";

// 검색 조건 베이스
const baseSearchParams = {
  branch_info: {
    branch_id: generateObjectZeroId(),
    branch_name: "",
  },
  combo_item: {
    branch_info_list: [
      {
        branch_id: generateObjectZeroId(),
        branch_name: "",
      },
    ],
    branch_list: [""],
  },
  member_name: "",
  member_type: "",
  p1: "",
  p2: "",
  p3: "",
  phone: "",
  tm_from_date: "",
  tm_manager: {
    sabun: "",
    staff_id: generateObjectZeroId(),
    staff_name: "",
    staff_pos: "",
    staff_rank: "",
  },
  tm_status: "",
  tm_to_date: "",
};

export const useTMHistStore = create((set) => ({
  // ========== State ===========
  // TM History 검색 State
  tmHistSearchParams: {
    ...baseSearchParams,
  },
  tmHistPage: 1,

  // ========== Action ==========
  // TM History 검색 조건 변경
  setSearchParams: (key, value) => {
    set((state) => ({
      tmHistSearchParams: {
        ...state.tmHistSearchParams,
        [key]: value,
      },
    }));
  },

  // Page 값 변경
  setPage: (value) => {
    set({ tmHistPage: value });
  },

  // 검색 조건 리셋
  resetSearchParams: () => {
    set({
      tmHistSearchParams: {
        ...baseSearchParams,
      },
    });
  },
}));
