export const gxFeeInitState = {
  stores: {
    data: {
      gx_fee_id: "string / id",
      class_name: "string / 수업명",
      class_fee: 0,
      time_list: [
        {
          idx: 0,
          is_use: false,
          day_of_week: "string / 요일",
          from_time: "time / 수업 시작 시간",
          end_time: "time / 수업 종료 시간",
        },
      ],
    },
  },
};
