import CardFixed from "components/Card/CardFixed";
import {
  generateObjectZeroId,
  parseMoneyFormat,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import NumberTextField from "components/LabelInput/NumberTextField";
import TextField from "components/LabelInput/TextField";
import PtPriceDiscountInput from "pages/ERP/MemberRegister/Common/Etc/PtPriceDiscountInput";
import { useRegisterStore } from "pages/ERP/MemberRegister/Register/modules/store/registerState";
import { forwardRef, useEffect, useImperativeHandle, useState, useTransition } from "react";
import { useDispatch } from "react-redux";
import { ptInitState } from "../Module/reducers/pt_init";

const PtInfo = forwardRef((props, ref) => {
  const {
    baseInfoState,
    payInfoListAction,
    memberInputState,
    memberInputStateAll,
    memberPtState,
    isAddPay,
    isOnlyPt,
    isPtMerged,
  } = props;
  const [ptState, setPtState] = useState(memberPtState);
  const [ptPerPrice, setPtPerPrice] = useState(0);
  const [ptPerDis, setPtPerDis] = useState(0);
  const [disMode, setDisMode] = useState("총액");
  const [ptTotalPrice, setPtTotalPrice] = useState(0);
  const [ptDisPrice, setPtDisPrice] = useState(0);

  useImperativeHandle(ref, () => ({
    init: () => {
      newPtInit();
      //   startTransition(() => {
      //     newPtInit();
      //   });
    },
    getState: () => {
      return ptState;
    },
    getState2: () => {
      return memberPtState;
    },
    getPtPrice: () => {
      return ptTotalPrice;
    },
  }));

  const onChangeDisMode = (val) => {
    let membershipItem = memberInputState.purchase_item_list.item_info;
    if (membershipItem.is_combine_pt) {
      setPtPerPrice(membershipItem.pt_price);
    } else {
      ptInitState.per_price = baseInfoState.branch_setting.base_policy.pt;
      setPtPerPrice(ptInitState.per_price);
    }

    memberPtState.dis = 0;
    setPtDisPrice(0);
    setDisMode(val);
  };

  const isCombine = () => {
    let membershipItem = memberInputState.purchase_item_list.item_info;
    let showItem = false;

    if (memberInputState.tab_info.is_new === false) {
      showItem = false;
    } else if (membershipItem.is_combine_pt) {
      showItem = true;
    }
    // if (from_lock) {
    //   showItem = false;
    // }

    return showItem;
  };

  const newPtInit = () => {
    const membershipItem = memberInputState.purchase_item_list.item_info;
    const ptData = { ...ptInitState };

    if (isCombine()) {
      ptData.per_price = membershipItem.pt_per_price;
      ptData.pt_total_cnt = membershipItem.pt_session;
      ptData.default_session = membershipItem.pt_session;
      ptData.service_session = 0;
      ptData.total_price = membershipItem.pt_per_price * membershipItem.pt_session;
      ptData.price = membershipItem.pt_per_price * membershipItem.pt_session;
      ptData.dis = 0;
      ptData.is_lock = true;
      setPtPerPrice(membershipItem.pt_per_price);
      setPtTotalPrice(membershipItem.pt_per_price * membershipItem.pt_session);
    } else {
      const pt_raw = baseInfoState.branch_setting.base_policy.pt;
      ptData.per_price = pt_raw;
      ptData.pt_total_cnt = 0;
      ptData.default_session = 0;
      ptData.service_session = 0;
      ptData.total_price = 0;
      ptData.price = 0;
      ptData.dis = 0;
      ptData.is_lock = false;
      setPtPerPrice(pt_raw);
      setPtTotalPrice(0);
    }

    const event = "PT";
    const act = ptData.default_session !== 0 ? "push" : "pop";
    const eventName = memberInputState.tab_info.title + event;
    const tabIdx = memberInputState.tab_info.idx;

    setPtDisPrice(0);
    setDisMode("총액");
    payInfoListAction(
      act,
      event, //viewId?
      {
        type: event,
        price: ptData.price,
        dis: ptData.dis,
        pay_order: 80,
        tab_idx: tabIdx,
        item_type: "PT/없음/없음/없음/없음",
      },
      80,
      0,
    );

    setPtState({ ...ptData });
    memberInputStateAll.pt_info = ptData;
    // setPtState(ptData);
  };

  // const ptPerPrice = "5,000,000";
  const PtTitle = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>PT</div>
        <div style={smallFont}>(회당 {parseMoneyFormat(Math.floor(ptPerPrice - ptPerDis))}원)</div>
      </div>
    );
  };

  if (isOnlyPt) {
    return (
      <div>
        <div
          style={{
            marginBottom: "1rem",
            fontFamily: "AppleSDGothicNeo",
            display: "flex",
            alignItems: "center",
          }}
        >
          {PtTitle()}
        </div>

        <div style={{ marginLeft: "1rem" }}>
          <PtEdit
            payInfoListAction={payInfoListAction}
            memberInputState={memberInputState}
            memberPtState={ptState}
            memberPtInputState={memberInputStateAll.pt_info}
            setPtState={setPtState}
            baseInfoState={baseInfoState}
            ptTotalPrice={ptTotalPrice}
            disMode={disMode}
            setDisMode={onChangeDisMode}
            setPtPerPrice={setPtPerPrice}
            setPtTotalPrice={setPtTotalPrice}
            ptPerPrice={ptPerPrice}
            ptDisPrice={ptDisPrice}
            setPtDisPrice={setPtDisPrice}
            setPtPerDis={setPtPerDis}
            isOnlyPt={isOnlyPt}
            isPtMerged={isPtMerged}
            curPtManager={props.curPtManager}
            // classDoneCnt={props.classDoneCnt}
            baseClassDoneCnt={props.baseClassDoneCnt}
            svcClassDoneCnt={props.svcClassDoneCnt}
          />
        </div>
      </div>
    );
  } else {
    return (
      <CardFixed
        topDom={PtTitle()}
        midDom={
          <PtEdit
            payInfoListAction={payInfoListAction}
            memberInputState={memberInputState}
            memberPtState={ptState}
            memberPtInputState={memberInputStateAll.pt_info}
            setPtState={setPtState}
            baseInfoState={baseInfoState}
            ptTotalPrice={ptTotalPrice}
            disMode={disMode}
            setDisMode={onChangeDisMode}
            setPtPerPrice={setPtPerPrice}
            setPtTotalPrice={setPtTotalPrice}
            ptPerPrice={ptPerPrice}
            ptDisPrice={ptDisPrice}
            setPtDisPrice={setPtDisPrice}
            setPtPerDis={setPtPerDis}
          />
        }
        isLoading={false}
        rootStyle={{
          width: "76.88rem",
          marginTop: "1rem",
          // height: "229px",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />
    );
  }
});
export default PtInfo;

//

const PtEdit = ({
  payInfoListAction,
  memberInputState,
  memberPtState,
  memberPtInputState,
  setPtState,
  baseInfoState,
  ptTotalPrice,
  disMode,
  setDisMode,
  setPtPerPrice,
  setPtTotalPrice,
  ptPerPrice,
  ptDisPrice,
  setPtDisPrice,
  setPtPerDis,
  isOnlyPt,
  isPtMerged,
  curPtManager,
  baseClassDoneCnt,
  svcClassDoneCnt,
}) => {
  const storesDispatch = useDispatch();
  const compWidthShort = "10rem";
  const compWidth = "10rem";
  const labelWidth = "4.5rem";

  const [isUse, setIsUse] = useState(false);
  const [isPending, startTransition] = useTransition();
  const [defaultSession, setDefaultSession] = useState(memberPtInputState.default_session);
  const [serviceSession, setServiceSession] = useState(memberPtInputState.service_session);
  // 전역 상태 관리
  const { onChangeSubmitDocState } = useRegisterStore();

  useEffect(() => {
    if (memberPtState.default_session > 0) {
      setIsUse(true);
    } else {
      setIsUse(false);
    }
  }, [memberPtState.default_session]);

  useEffect(() => {
    setDefaultSession(memberPtInputState.default_session);
    setServiceSession(memberPtInputState.service_session);
  }, [memberPtInputState.default_session, memberPtInputState.service_session]);

  const onChangePtPrice = (e) => {
    let dis = memberPtState.dis;
    const event = "PT";

    let act = e !== 0 ? "push" : "pop";
    let eventName = memberInputState.tab_info.title + event;
    let tabIdx = memberInputState.tab_info.idx;
    if (memberPtState.input_type === "회당") {
      dis = memberPtState.dis * e;
    }

    memberPtState.price = e * memberPtState.per_price;
    setPtPerPrice(memberPtState.per_price);
    setPtTotalPrice(memberPtState.price);

    memberPtInputState.per_price = memberPtState.per_price;
    memberPtInputState.price = memberPtState.price;
    memberPtInputState.total_price = memberPtState.price;
    // memberPtInputState.input_type = disMode;

    payInfoListAction(
      act,
      event,
      {
        type: event,
        price: memberPtState.price,
        dis: dis,
        pay_order: 80,
        tab_idx: tabIdx,
        item_type: "PT/없음/없음/없음/없음",
      },
      80,
      0,
    );

    if (e > 0) {
      setIsUse(true);
    } else {
      setIsUse(false);
    }

    setPtState({ ...memberPtState });
  };
  // pt 횟수 변경 시 실행 함수
  const onChangePtCount = (ptType, e) => {
    switch (ptType) {
      case "default":
        memberPtInputState.default_session = e;
        memberPtInputState.pt_total_cnt =
          memberPtInputState.default_session + memberPtInputState.service_session;
        break;
      case "service":
        memberPtInputState.service_session = e;
        memberPtInputState.pt_total_cnt =
          memberPtInputState.default_session + memberPtInputState.service_session;
        break;
      case "defaultOnlyPt":
        memberPtInputState.default_session = baseClassDoneCnt + 1;
        memberPtInputState.pt_total_cnt =
          memberPtInputState.default_session + memberPtInputState.service_session;
        break;
      case "serviceOnlyPt":
        memberPtInputState.service_session = svcClassDoneCnt + 1;
        memberPtInputState.pt_total_cnt =
          memberPtInputState.default_session + memberPtInputState.service_session;
        break;
      default:
        console.log("pt 횟수 변경쪽 에러 발생");
        break;
    }
    // pt 횟수에 따른 제출 서류 보이기
    if (memberPtInputState.pt_total_cnt > 0) {
      onChangeSubmitDocState("PT가입 신청서", true);
    } else {
      onChangeSubmitDocState("PT가입 신청서", false);
    }
  };

  const getPtClassStaffNameList = () => {
    const result = [...baseInfoState.class_team_name_list];
    result.splice(0, 0, "미배정");
    return result;
  };

  const getPtStaffNameList = () => {
    const result = [...baseInfoState.class_team_name_list];
    result.splice(0, 0, "추후 선택");
    return result;
  };

  if (!memberInputState) {
    return <></>;
  } else {
    return (
      <div style={{}}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {isOnlyPt ? (
            <TextField
              labelWidth={labelWidth}
              textBoxWidth={compWidth}
              labelText="수업 담당자"
              defaultValue={curPtManager.staff_name}
              disabled
            />
          ) : (
            <ComboBox
              required={memberPtInputState.default_session > 0}
              labelWidth={labelWidth}
              comboItemWidth={compWidth}
              labelText="수업 담당자"
              data={getPtClassStaffNameList()}
              onChangeCallback={(e) => {
                const index = baseInfoState.class_team_name_list
                  .map((item) => item)
                  .indexOf(e.target.value);
                if (index !== -1) {
                  memberPtInputState.pt_staff.staff_id =
                    baseInfoState.class_team_list.team[index].staff_id;
                  memberPtInputState.pt_staff.staff_name =
                    baseInfoState.class_team_list.team[index].staff_name;
                } else {
                  memberPtInputState.pt_staff.staff_id = generateObjectZeroId();
                  memberPtInputState.pt_staff.staff_name = "미배정";
                }
              }}
            />
          )}

          <div>
            <ComboBox
              required={memberPtInputState.default_session > 0}
              labelWidth={labelWidth}
              comboItemWidth={compWidth}
              marginRight="0.25rem"
              labelText="PT 상담자"
              data={getPtStaffNameList()}
              onChangeCallback={(e) => {
                const index = baseInfoState.class_team_name_list
                  .map((item) => item)
                  .indexOf(e.target.value);
                if (index !== -1) {
                  memberPtInputState.cs_staff.staff_id =
                    baseInfoState.class_team_list.team[index].staff_id;
                  memberPtInputState.cs_staff.staff_name =
                    baseInfoState.class_team_list.team[index].staff_name;
                } else {
                  memberPtInputState.cs_staff.staff_id = generateObjectZeroId();
                  memberPtInputState.cs_staff.staff_name = "추후 선택";
                }
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            marginTop: "0.75rem",
          }}
        >
          <NumberTextField
            labelWidth={labelWidth}
            textMarginRight="0.25rem"
            textBoxWidth={compWidthShort}
            labelText="세션"
            endAdornment="회"
            defaultValue={defaultSession} // memberPtState.default_session
            disabled={memberPtState.is_lock}
            maxValue={500}
            isNumber
            onChangeCallback={(e) => {
              onChangePtCount("default", e);
              onChangePtPrice(e);
            }}
            onBlur={(e) => {
              if (isOnlyPt && e < baseClassDoneCnt) {
                simpleAlert(
                  storesDispatch,
                  "알림",
                  "이미 진행한 기본 세션 수보다 큰 값이어야 합니다.",
                );
                onChangePtCount("defaultOnlyPt", e);
                onChangePtPrice(baseClassDoneCnt + 1);
              }
            }}
          />

          <NumberTextField
            marginLeft="1.5rem"
            labelWidth="4.75rem"
            textMarginRight="0.25rem"
            textBoxWidth={compWidthShort}
            labelText="서비스 세션"
            maxValue={500}
            defaultValue={serviceSession} // memberPtState.service_session
            endAdornment="회"
            isNumber
            onChangeCallback={(e) => {
              onChangePtCount("service", e);
            }}
            onBlur={(e) => {
              if (isOnlyPt && e < svcClassDoneCnt) {
                simpleAlert(
                  storesDispatch,
                  "알림",
                  "이미 진행한 서비스 세션 수보다 큰 값이어야 합니다.",
                );
                onChangePtCount("serviceOnlyPt", e);
              }
            }}
          />

          <PtPriceDiscountInput
            baseInfo={{
              tabIdx: 0,
              orderStartIdx: 0,
              event: "PT",
              orderIdx: 10,
              payOrder: 80,
              eventName: "PT",
              item_type: "PT/없음/없음/없음/없음",
              default_session: memberPtInputState.default_session,
            }}
            disMode={disMode}
            setDisMode={setDisMode}
            payInfoListAction={payInfoListAction}
            setPtDisPrice={setPtDisPrice}
            memberPtState={memberPtState}
            memberPtInputState={memberPtInputState}
            standardPrice={ptTotalPrice}
            onChangeCallback={(e) => {
              memberPtState.dis = e;
              memberPtInputState.dis = e;
              if (disMode === "총액") {
                startTransition(() => {
                  const disP = (ptTotalPrice - e) / memberPtInputState.default_session;
                  setPtPerDis(ptPerPrice - disP);
                });
              } else {
                startTransition(() => {
                  setPtPerDis(e);
                });
              }
            }}
            paramLock={false}
            paramUse={isUse}
            discountValue={ptDisPrice}
          />
        </div>
      </div>
    );
  }
};

const smallFont = {
  fontSize: "0.8rem",
  marginLeft: "0.8rem",
};
