import {
  contentTypeJson,
  dateToStr,
  getCurrentDate,
  simpleAlert,
  timeToStr,
} from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import TextArea from "components/LabelInput/TextArea";
import TextField from "components/LabelInput/TextField";
import TimePicker from "components/LabelInput/TimePicker";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { useState } from "react";
import { useDispatch } from "react-redux";

const ClassProcessModal = ({ modalParam, onModalDone }) => {
  const labelWidth = "4.5rem";
  const textBoxWidth = "21.5rem";
  const textBoxWidthShort = "10.45rem";

  const storesDispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const getClassStatusCombo = () => {
    if (modalParam.mode === "ot") {
      if (modalParam.data.ot_info.root_status === "대기") {
        return ["거절", "부재", "보류(연기)", "PT 등록"];
      } else {
        return ["수업완료", "거절", "부재", "보류(연기)", "PT 등록"];
      }
    } else {
      return ["수업완료", "노쇼"];
    }
  };

  const [info, setInfo] = useState({
    class_done_date: dateToStr(new Date()),
    class_done_time: timeToStr(new Date()),
    class_status: getClassStatusCombo()[0],
    comment: "",
    latest_contact: "",
    membership_id: modalParam.data.membership_info.membership_id,
    no: modalParam.no,
    ot_detail_id: modalParam.data.ot_detail_id,
    staff_info: modalParam.data.cur_manager.staff_info,
  });

  const [ptInfo, setPtInfo] = useState({
    // fix_payout_rate: true,
    latest_contact: "",
    no: modalParam.no,
    payout_price: 0,
    proceed_class: {
      class_done_date: dateToStr(new Date()),
      class_done_time: timeToStr(new Date()),
      class_status: getClassStatusCombo()[0],
      comment: "",
    },
    pt_detail_id: modalParam.data.pt_detail_id,
    session_price: 0,
    session_type: "",
    staff_info: modalParam.data.cur_manager.staff_info,
  });

  const otValidationCheck = () => {
    if (!info.class_status) {
      simpleAlert(storesDispatch, "알림", "수업 상태를 선택해주세요.");
    } else if (
      info.class_status === "수업완료" &&
      (!info.class_done_date || !info.class_done_time)
    ) {
      simpleAlert(storesDispatch, "알림", "일시를 선택해주세요.");
    } else if (info.class_status !== "수업완료" && !info.latest_contact) {
      simpleAlert(storesDispatch, "알림", "일시를 선택해주세요.");
    } else {
      const bodyText =
        "선택된 수업일시는 [" +
        info.class_done_date +
        " " +
        info.class_done_time +
        "] 입니다.\n\n저장하시겠습니까?";
      simpleAlert(storesDispatch, "알림", bodyText, false, undefined, DoSaveOtClass);
    }
  };

  const ptValidationCheck = () => {
    if (!ptInfo.proceed_class.class_status) {
      simpleAlert(storesDispatch, "알림", "수업 상태를 선택해주세요.");
    } else if (!ptInfo.proceed_class.class_done_date || !ptInfo.proceed_class.class_done_time) {
      simpleAlert(storesDispatch, "알림", "일시를 선택해주세요.");
    } else {
      const bodyText =
        "선택된 수업일시는 [" +
        ptInfo.proceed_class.class_done_date +
        " " +
        ptInfo.proceed_class.class_done_time +
        "] 입니다.\n\n저장하시겠습니까?";
      simpleAlert(storesDispatch, "알림", bodyText, false, undefined, DoSavePtClass);
    }
  };

  const DoSaveOtClass = async () => {
    try {
      const formData = new FormData();

      formData.append("new_info", JSON.stringify(info));
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_STAFF_SERVER + "/class/ot_class_done",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        onModalDone();
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const DoSavePtClass = async () => {
    try {
      const formData = new FormData();

      formData.append("new_info", JSON.stringify(ptInfo));
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_STAFF_SERVER + "/class/pt_class_done",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        onModalDone();
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ width: "26.5rem" }}>
      {loading && <Loading />}

      <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        <ComboBox
          labelWidth={labelWidth}
          comboItemWidth={textBoxWidth}
          labelMarginRight="0.725rem"
          labelText={modalParam.mode === "ot" ? "수업 상태" : "완료 구분"}
          data={getClassStatusCombo(modalParam.mode)}
          defaultValue={getClassStatusCombo()[0]}
          onChangeCallback={(e) => {
            if (modalParam.mode === "ot") {
              setInfo((cur) => {
                return { ...cur, class_status: e.target.value };
              });
            } else {
              setPtInfo((cur) => {
                const obj = { ...cur };
                obj.proceed_class.class_status = e.target.value;
                return obj;
              });
            }
          }}
        />

        <div style={{ display: "flex" }}>
          {(modalParam.mode === "ot" && info.class_status === "수업완료") ||
          modalParam.mode === "pt" ? (
            <div>
              <TextField
                labelWidth={labelWidth}
                textBoxWidth={textBoxWidth}
                labelText="회차"
                defaultValue={modalParam.mode === "ot" ? info.no : ptInfo.no}
                disabled
              />
              <div style={{ display: "flex", marginTop: "0.5rem" }}>
                <DatePicker
                  labelWidth={labelWidth}
                  textMarginRight={"0rem"}
                  minWidth={textBoxWidthShort}
                  labelText={"일시"}
                  maxDate={getCurrentDate()}
                  defaultValue={
                    modalParam.mode === "ot"
                      ? info.class_done_date
                      : ptInfo.proceed_class.class_done_date
                  }
                  onChangeCallback={(e) => {
                    if (modalParam.mode === "ot") {
                      setInfo((cur) => {
                        return { ...cur, class_done_date: e };
                      });
                    } else {
                      setPtInfo((cur) => {
                        const obj = { ...cur };
                        obj.proceed_class.class_done_date = e;
                        return obj;
                      });
                    }
                  }}
                />
                <TimePicker
                  textBoxWidth={textBoxWidthShort}
                  defaultValue={
                    modalParam.mode === "ot"
                      ? info.class_done_time
                      : ptInfo.proceed_class.class_done_time
                  }
                  onChange={(e) => {
                    if (modalParam.mode === "ot") {
                      setInfo((cur) => {
                        return { ...cur, class_done_time: e };
                      });
                    } else {
                      setPtInfo((cur) => {
                        const obj = { ...cur };
                        obj.proceed_class.class_done_time = e;
                        return obj;
                      });
                    }
                  }}
                />
              </div>

              <TextArea
                labelWidth={labelWidth}
                labelText={"메모"}
                textBoxWidth={textBoxWidth}
                marginTop={"0.5rem"}
                minRows={5}
                maxRows={5}
                onBlur={(e) => {
                  if (modalParam.mode === "ot") {
                    setInfo((cur) => {
                      return { ...cur, comment: e.target.value };
                    });
                  } else {
                    setPtInfo((cur) => {
                      const obj = { ...cur };
                      obj.proceed_class.comment = e.target.value;
                      return obj;
                    });
                  }
                }}
              />
            </div>
          ) : (
            <DatePicker
              labelWidth={labelWidth}
              textMarginRight={"0rem"}
              minWidth={textBoxWidth}
              labelText={"최근 연락일"}
              onChangeCallback={(e) => {
                if (modalParam.mode === "ot") {
                  setInfo((cur) => {
                    return { ...cur, latest_contact: e };
                  });
                }
              }}
            />
          )}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "start", marginTop: "0.5rem" }}>
        <Label
          labelText="※ 특이사항은 별도 입력해주시기 바랍니다."
          fontSize="0.725rem"
          color="red"
          marginLeft="5.25rem"
        />
      </div>
      <div
        style={{
          marginTop: "1rem",
          display: "flex",
          justifyContent: "center",
          gap: "0.5rem",
        }}
      >
        <NormalBtn
          name="저장"
          onClick={modalParam.mode === "ot" ? otValidationCheck : ptValidationCheck}
        />
        <NormalBtn
          theme="white"
          name="취소"
          onClick={() => {
            onModalDone(false);
          }}
        />
      </div>
    </div>
  );
};

export default ClassProcessModal;
