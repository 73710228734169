export const branchETCStateinitial = {
  stores: {
    data: {
      branch_id: "",//"string / 지점 ID",

      prepay_items: [],
      subs_items: [],
      etc_items: [
        {
          item_id: "",//"objectId / 상품 ID",
          item_name: "",//"string / 상품명",
          item_detail: "",//"string / 상품 설명",
          price: 0,//"int / 판매가",
          base_info: {
            sales_status: "",//"string / 판매 상태",
            incentive: 0,//"int / 판매 인센티브",
            order: 0,//"int / 게시 순서",
            tablet_image: "",//"string / 태블릿 표시 이미지 파일명",
            mem_app_image: "",//"string / 회원앱 표시 이미지 파일명",
          },
          reg_info: {
            reg_date: "",//"date / 등록일시",
            reg_user: "",//"string / 등록자",
            upd_date: "",//"date / 수정일시",
            upd_user: "",//"string / 최근수정자",
          },
        },
      ],
    },
  },
};
