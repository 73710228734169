import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import ChangePayMethodWrapper from "./ChangePayMethodWrapper";
import { loadChangePayMethodViewStore } from "./Module/actions";

const ChangePayMethodService = () => {
  const storesDispatch = useDispatch();
  const { state } = useLocation();
  const viewState = useSelector((state) => state.member_service.change_paymethod_view_info);

  //결제수단변경 화면 GET
  useEffect(() => {
    storesDispatch(loadChangePayMethodViewStore(state.member_id));
  }, [state.member_id]);

  return (
    <div>
      <PageFrame
        children={
          <div>
            <PageTitle titleText="결제수단 변경" />
            <ChangePayMethodWrapper
              memberBaseInfoState={state.memberBaseInfoState}
              serviceViewState={viewState.stores.data}
              initRegDate={state.reg_date}
            />
          </div>
        }
      />
    </div>
  );
};
export default ChangePayMethodService;
