import { simpleAlert } from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import { useState } from "react";
import { useDispatch } from "react-redux";

const EditAllCardModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const [editData, setEditData] = useState({
    user_group: "",
    branch_info: { branch_id: "", branch_name: "" },
    card_status: false,
    card_no_list: [],
  });
  const [loading, setLoading] = useState(false);

  //일괄수정POST
  const saveEditData = async () => {
    try {
      setLoading(true);

      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const formData = new FormData();

      const cardNumList = modalParam.checkedList.map((el) => el.card_no);
      editData.card_no_list = cardNumList;
      formData.append("new_info", JSON.stringify(editData));

      const res = await HttpInstance.post(
        process.env.REACT_APP_BRANCH_SERVER + "/access/card_bulk_update",
        formData,
        config,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label labelText="저장되었습니다." />
              </>
            ),
            onBtnOk: () => {
              onModalDone(1, false, true); //서파로 결과 재요청
              storesDispatch(globalModalOff());
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const saveEvt = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="저장 하시겠습니까?" />
          </>
        ),
        onBtnYes: () => {
          saveEditData();
        },
        onBtnNo: () => {},
      }),
    );
  };

  return (
    <div
      style={{
        width: "23rem",
      }}
    >
      {loading ? <Loading /> : <></>}

      <div style={{ display: "flex" }}>
        <ComboBox
          marginTop="0.5rem"
          labelWidth={"6rem"}
          comboItemWidth={"12rem"}
          labelText="사용자 그룹"
          data={["회원", "직원", "지점"]}
          onChangeCallback={(e) => {
            setEditData((cur) => {
              return { ...cur, user_group: e.target.value };
            });
          }}
        />
      </div>
      <div style={{ display: "flex" }}>
        <ComboBox
          marginTop="0.5rem"
          labelWidth={"6rem"}
          comboItemWidth={"12rem"}
          labelText="지점"
          data={modalParam.branchList.branch_name_list}
          onChangeCallback={(e) => {
            const branch = modalParam.branchList.branch_list.find(
              (el) => el.branch_name === e.target.value,
            );
            setEditData((cur) => {
              return { ...cur, branch_info: branch };
            });
          }}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <CheckBox
          labelText="카드정지"
          onChangeCallback={(e) => {
            setEditData((cur) => {
              return { ...cur, card_status: e.checked };
            });
          }}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "0.7rem" }}>
        <NormalBtn name="저장" onClick={() => saveEvt()} />
      </div>
    </div>
  );
};
export default EditAllCardModal;
