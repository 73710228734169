import { SubLine } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import NumberTextField from "components/LabelInput/NumberTextField";
import { useEffect } from "react";

const MembershipSetting = ({
  branchPolicy,
  restData,
  mbshpOption,
  period,
  preMonth,
  isEditMode,
  totalPrice,
  setTotalPrice,
  totalSubsPrice,
  setTotalSubsPrice,
  combine,
}) => {
  const textLabelWidth = "5rem";
  const textLabelWidthShort = "4rem";
  const textBoxWidth = "9rem";
  const labelMarginLeft = "3rem";

  useEffect(() => {
    if (!isEditMode) {
      restData.subs_options.mbshp_subs_price = branchPolicy.base_policy.subs_mbshp;
      restData.subs_options.mbshp_pre_price = branchPolicy.base_policy.subs_mbshp * preMonth;
    }
    calcMbshpTotalPrice();
  }, [period, preMonth, totalSubsPrice, combine]);

  // 회원권 판매가 합계 계산
  const calcMbshpTotalPrice = () => {
    const isPreMonth = preMonth > 0 ? 1 : 0;
    const val =
      restData.subs_options.mbshp_subs_price * (period - preMonth) +
      restData.subs_options.mbshp_pre_price * isPreMonth;
    setTotalPrice({
      ...totalPrice,
      mbshp: val,
    });
    restData.mbshp_price = val;
    restData.total_price = getTotalPrice();
    restData.subs_options.total_subs_price = getTotalSubsPrice();
  };

  const getTotalPrice = () => {
    const clothPrice = combine.cloth ? totalPrice.cloth : 0;
    const gxPrice = combine.gx ? totalPrice.gx : 0;
    const lockerPrice = combine.locker ? totalPrice.locker : 0;

    return totalPrice.mbshp + clothPrice + gxPrice + lockerPrice;
  };
  const getTotalSubsPrice = () => {
    const clothPrice = combine.cloth ? totalSubsPrice.cloth : 0;
    const gxPrice = combine.gx ? totalSubsPrice.gx : 0;
    const lockerPrice = combine.locker ? totalSubsPrice.locker : 0;

    return totalSubsPrice.mbshp + clothPrice + gxPrice + lockerPrice;
  };

  return (
    <div style={{}}>
      <div style={{ display: "flex", marginTop: "1.25rem", marginBottom: "0.625rem" }}>
        <Label labelText={"회원권 금액"} />
        {isEditMode ? (
          <></>
        ) : (
          <Label
            color="#c55d5d"
            fontWeight="bold"
            fontSize="0.85rem"
            labelText={
              "※ 판매가 합계 : " +
              getTotalPrice() +
              "원 / 월 구독료 합계 : " +
              getTotalSubsPrice() +
              "원"
            }
            marginLeft="32rem"
          />
        )}
      </div>
      <div style={{ marginLeft: "2rem" }}>
        <div style={{ display: "flex" }}>
          <NumberTextField
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· 1개월 정가"
            endAdornment="원"
            defaultValue={branchPolicy.base_policy.subs_mbshp}
            disabled
          />

          <NumberTextField
            required
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelMarginLeft={labelMarginLeft}
            labelText="· 월 구독료"
            endAdornment="원"
            defaultValue={
              restData.subs_options.mbshp_subs_price || branchPolicy.base_policy.subs_mbshp
            }
            onChangeCallback={(e) => {
              restData.subs_options.mbshp_subs_price = e;
            }}
            // endAdornment="원"
            onBlur={(e) => {
              calcMbshpTotalPrice();
              setTotalSubsPrice({
                ...totalSubsPrice,
                mbshp: Number(e.target.value),
              });
            }}
            disabled={isEditMode}
          />
        </div>
        <div style={{ display: "flex", marginTop: "0.5rem" }}>
          <NumberTextField
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· 선납금 정가"
            endAdornment="원"
            defaultValue={branchPolicy.base_policy.subs_mbshp * preMonth}
            disabled
          />

          <NumberTextField
            required
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelMarginLeft={labelMarginLeft}
            labelText="· 판매가"
            endAdornment="원"
            defaultValue={
              restData.subs_options.mbshp_pre_price ||
              branchPolicy.base_policy.subs_mbshp * preMonth
            }
            onChangeCallback={(e) => {
              restData.subs_options.mbshp_pre_price = e;
            }}
            onBlur={calcMbshpTotalPrice}
            disabled={isEditMode}
          />

          <NumberTextField
            labelWidth={"8rem"}
            textBoxWidth={textBoxWidth}
            labelMarginLeft={labelMarginLeft}
            labelText="· 회원권 판매가 합계"
            endAdornment="원"
            defaultValue={restData.mbshp_price}
            disabled
          />
        </div>
      </div>
      <SubLine />

      <div>
        <div style={{ display: "flex", marginTop: "1.25rem", marginBottom: "0.625rem" }}>
          <Label labelText={"등록 수수료"} />
        </div>
        <div style={{ display: "flex", marginLeft: "2rem", marginTop: "0.5rem" }}>
          <NumberTextField
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· 정가"
            endAdornment="원"
            defaultValue={branchPolicy.base_policy.subs_reg}
            disabled
          />

          <NumberTextField
            required
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelMarginLeft={labelMarginLeft}
            labelText="· 판매가"
            endAdornment="원"
            defaultValue={restData.subs_options.subs_reg || branchPolicy.base_policy.subs_reg}
            onChangeCallback={(e) => {
              restData.subs_options.subs_reg = e;
            }}
            disabled={isEditMode}
          />
        </div>
        <SubLine />
      </div>
    </div>
  );
};
export default MembershipSetting;
