import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import CheckBox from "components/LabelInput/CheckBox";
import Label from "components/LabelInput/Label";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch } from "react-redux";

const SetPermissionStaff = forwardRef((props, ref) => {
  const { modalParam, onModalDone } = props;
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();
  const [isAdmin, setIsAdmin] = useState(false);
  const [srcPermission, setSrcPermission] = useState(modalParam.srcPermission);
  const [permissionSet, setPermissionSet] = useState({
    data: {
      data: {
        account_permission: [],
        branch_id: "string",
        branch_info: {
          branch_id: "string",
          branch_name: "string",
        },
        branch_permission_list: [],
        hq_id: "string",
        hq_info: {
          hq_id: "string",
          hq_name: "string",
        },
        hq_permission_list: [],
        staff_id: "string",
      },
    },
  });

  // 관리자 정보 세팅
  useEffect(() => {
    if (srcPermission.is_admin) {
      setIsAdmin(srcPermission.is_admin);
    }
  }, [srcPermission.is_admin]);

  useEffect(() => {
    getTargetPermission();
  }, [modalParam.targetParams.branch_info.branch_id]);

  // console.log(modalParam);
  useImperativeHandle(ref, () => ({
    getPermission: () => {
      return permissionSet;
    },
    setSrcPermission: (data) => {
      setSrcPermission(data);
    },
  }));

  const filterPermissionSet = (dataSet) => {
    for (let i = 0; i < dataSet.hq_permission_list.length; i++) {
      const myPerm = srcPermission.hq_permission_list.find(
        (x) => x.auth_code === dataSet.hq_permission_list[i].auth_code,
      );
      if (myPerm) {
        dataSet.hq_permission_list[i].is_read_lock = myPerm.is_read_lock;
        dataSet.hq_permission_list[i].is_write_lock = myPerm.is_write_lock;
      } else {
        dataSet.hq_permission_list[i].is_read_lock = true;
        dataSet.hq_permission_list[i].is_write_lock = true;
      }
    }

    for (let i = 0; i < dataSet.branch_permission_list.length; i++) {
      const myPerm = srcPermission.branch_permission_list.find(
        (x) => x.auth_code === dataSet.branch_permission_list[i].auth_code,
      );
      if (myPerm) {
        dataSet.branch_permission_list[i].is_read_lock = myPerm.is_read_lock;
        dataSet.branch_permission_list[i].is_write_lock = myPerm.is_write_lock;
      } else {
        dataSet.branch_permission_list[i].is_read_lock = true;
        dataSet.branch_permission_list[i].is_write_lock = true;
      }
    }
  };

  const getTargetPermission = async () => {
    props.setLoading(true);
    if (!srcPermission.branch_id && !srcPermission.is_admin) {
      props.setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("permission_param", JSON.stringify(modalParam.targetParams));
    await HttpInstance.post(
      process.env.REACT_APP_STAFF_SERVER + "/hq/staff_permission",
      formData,
      contentTypeJson,
    )
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          let isAdmin = false;
          if (srcPermission.is_admin) {
            isAdmin = true;
          }
          if (!restEmpty(res.data.data)) {
            if (!isAdmin) {
              filterPermissionSet(res.data.data);
            }
            setPermissionSet(res.data.data);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        props.setLoading(false);
      });
  };

  const onChangeReadEvent = (idx, checked, mode) => {
    var array = [];
    if (mode === "hq") {
      array = [...permissionSet.hq_permission_list];
    } else if (mode === "branch") {
      array = [...permissionSet.branch_permission_list];
    }
    // else if (mode === "account") {
    //   array = [...restData.stores.data.permission];
    // }

    const index = array.map((item) => item.id).indexOf(idx);

    if (index !== -1) {
      if (mode === "hq") {
        permissionSet.hq_permission_list[index].is_read = checked;
      } else if (mode === "branch") {
        permissionSet.branch_permission_list[index].is_read = checked;
      }
      // else if (mode === "account") {
      //   restData.stores.data.permission[index].is_read = checked;
      // }
    }
  };
  const onChangeWriteEvent = (idx, checked, mode) => {
    var array = [];
    if (mode === "hq") {
      array = [...permissionSet.hq_permission_list];
    } else if (mode === "branch") {
      array = [...permissionSet.branch_permission_list];
    } else if (mode === "account") {
      array = [...permissionSet.permission];
    }

    const index = array.map((item) => item.id).indexOf(idx);

    if (index !== -1) {
      if (mode === "hq") {
        permissionSet.hq_permission_list[index].is_write = checked;
      } else if (mode === "branch") {
        permissionSet.branch_permission_list[index].is_write = checked;
      } else if (mode === "account") {
        permissionSet.permission[index].is_write = checked;
      }
    }
  };
  // 관리자용 Lock 체크 함수
  const onChangeLockEvent = (idx, checked, columnHead, mode) => {
    var array = [];
    if (mode === "hq") {
      array = [...permissionSet.hq_permission_list];
    } else if (mode === "branch") {
      array = [...permissionSet.branch_permission_list];
    }

    const index = array.map((item) => item.id).indexOf(idx);

    if (index !== -1) {
      if (mode === "hq") {
        columnHead === "읽기"
          ? (permissionSet.hq_permission_list[index].is_read_lock = checked)
          : (permissionSet.hq_permission_list[index].is_write_lock = checked);
      } else if (mode === "branch") {
        columnHead === "읽기"
          ? (permissionSet.branch_permission_list[index].is_read_lock = checked)
          : (permissionSet.branch_permission_list[index].is_write_lock = checked);
      }
    }
  };

  const accountPermissionList = new permissionTable(
    onChangeReadEvent,
    onChangeWriteEvent,
    "account",
  );
  const hqPermissionList = new permissionTable(
    onChangeReadEvent,
    onChangeWriteEvent,
    onChangeLockEvent,
    "hq",
    isAdmin,
  );
  const branchPermissionList = new permissionTable(
    onChangeReadEvent,
    onChangeWriteEvent,
    onChangeLockEvent,
    "branch",
    isAdmin,
  );

  return (
    <div>
      <SubTitle titleText={"본부 접근 권한"} />
      <CustomTable
        columns_head={isAdmin ? hqPermissionList.columns_head_admin : hqPermissionList.columns_head}
        table_title={hqPermissionList.table_title}
        rest_call={hqPermissionList.get_data_from_rest}
        row_render={hqPermissionList.create_table}
        rest_data={permissionSet.hq_permission_list}
      />
      {/* 
      <CustomTable
        columns_head={accountPermissionList.columns_head}
        table_title={accountPermissionList.table_title}
        rest_call={accountPermissionList.get_data_from_rest}
        row_render={accountPermissionList.create_table}
        rest_data={restData.permission}
      /> */}

      <div style={{ marginTop: "2rem" }} />
      <SubTitle titleText={"지점 접근 권한"} />
      <CustomTable
        columns_head={
          isAdmin ? branchPermissionList.columns_head_admin : branchPermissionList.columns_head
        }
        table_title={branchPermissionList.table_title}
        rest_call={branchPermissionList.get_data_from_rest}
        row_render={branchPermissionList.create_table}
        rest_data={permissionSet.branch_permission_list}
      />
    </div>
  );
});
export default SetPermissionStaff;

class permissionTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onChangeReadEvent = null;
  onChangeWriteEvent = null;
  onChangeLockEvent = null;
  mode = "";
  isAdmin = false;

  constructor(onChangeReadEvent, onChangeWriteEvent, onChangeLockEvent, mode, isAdmin) {
    this.onChangeReadEvent = onChangeReadEvent;
    this.onChangeWriteEvent = onChangeWriteEvent;
    this.onChangeLockEvent = onChangeLockEvent;
    this.mode = mode;
    if (mode === "account") {
      this.columns_head = [];
    }
    this.isAdmin = isAdmin;
  }
  tWriteEvent = (idx, e) => {
    this.onChangeWriteEvent(idx, e.checked, this.mode);
  };

  tReadEvent = (idx, e) => {
    this.onChangeReadEvent(idx, e.checked, this.mode);
  };

  tLockEvent = (idx, columnHead, e) => {
    this.onChangeLockEvent(idx, e.checked, columnHead, this.mode);
  };

  create_table = (data) => {
    // let modeDisable = false;
    // if (this.mode === "account") {
    //   modeDisable = true;
    // }

    return (
      <CustomRow style={{ width: "100%" }}>
        <CustomTableContents width="5.3%">{data.id}</CustomTableContents>
        <CustomTableContents width="15.3%">{data.menu_name}</CustomTableContents>
        <CustomTableContents width="23.5%">{data.func_name}</CustomTableContents>

        <CustomTableContents width={this.isAdmin ? "7%" : "6%"}>
          <CheckBox
            isTable
            labelText={""}
            defaultValue={data.is_read}
            onChangeCallback={(e) => this.tReadEvent(data.id, e)}
            disabled={this.isAdmin ? false : data.is_read_lock}
          />
        </CustomTableContents>
        <CustomTableContents width={this.isAdmin ? "7%" : "6%"}>
          <CheckBox
            isTable
            labelText={""}
            defaultValue={data.is_write}
            onChangeCallback={(e) => this.tWriteEvent(data.id, e)}
            disabled={this.isAdmin ? false : data.is_write_lock}
          />
        </CustomTableContents>
        {this.isAdmin ? (
          <>
            <CustomTableContents width="7%">
              <CheckBox
                isTable
                labelText={""}
                defaultValue={data.is_read_lock}
                onChangeCallback={(e) => this.tLockEvent(data.id, "읽기", e)}
              />
            </CustomTableContents>
            <CustomTableContentsEND width="7%">
              <CheckBox
                isTable
                labelText={""}
                defaultValue={data.is_write_lock}
                onChangeCallback={(e) => this.tLockEvent(data.id, "쓰기", e)}
              />
            </CustomTableContentsEND>
          </>
        ) : (
          <></>
        )}
      </CustomRow>
    );
  };
  columns_head = [
    { title: "번호", field: "id" },
    { title: "메뉴 분류", field: "menu_name" },
    { title: "기능 분류", field: "func_name" },
    { title: "읽기", field: "is_read" },
    { title: "쓰기", field: "is_write" },
  ];
  columns_head_admin = [
    { title: "번호", field: "id" },
    { title: "메뉴 분류", field: "menu_name" },
    { title: "기능 분류", field: "func_name" },
    { title: "읽기", field: "is_read" },
    { title: "쓰기", field: "is_write" },
    // { title: "읽기\n(Lock)", field: "is_read_lock" },
    {
      title: (
        <>
          <Label labelText="읽기" />
          <Label fontSize="0.675rem" fontWeight="bold" labelText="(Lock)" />
        </>
      ),
      field: "is_read_lock",
    },
    {
      title: (
        <>
          <Label labelText="쓰기" />
          <Label fontSize="0.675rem" fontWeight="bold" labelText="(Lock)" />
        </>
      ),
      field: "is_write_lock",
    },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
