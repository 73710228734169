import {
  loadApprovalInfoView,
  loadApprovalInfoViewSuccess,
  loadApprovalInfoViewFail,
  loadApprovalInfoViewReset,
} from "./view_act";

export const loadApprovalInfoViewStore = (approval_id) => loadApprovalInfoView(approval_id);

export const approvalViewAction = {
  loadApprovalInfoView,
  loadApprovalInfoViewSuccess,
  loadApprovalInfoViewFail,
  loadApprovalInfoViewReset,
};
