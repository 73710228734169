import IconButton from "components/Button/IconButton";
import { dataEmpty, getPermission, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import ComboBox from "components/LabelInput/ComboBox";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalModalOff } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const DeviceManageScheduleListView = ({ onClickOpenModal, hqBranchData, isAdmin }) => {
  const storesDispatch = useDispatch();

  const [hqIdx, setHqIdx] = useState(0);
  const [selectedBranch, setSelectedBranch] = useState({ branch_id: "", branch_name: "" });
  const [curPage, setCurPage] = useState();
  const [totalPage, setTotalPage] = useState(1);
  const [scheduleList, setScheduleList] = useState([]);
  const [hasPermission, setHasPermission] = useState(false);

  //스케줄정보 GET
  const getScheduleList = async (page) => {
    setCurPage(page);
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_BRANCH_SERVER + "/access/manage_list",
        {
          params: {
            page_num: curPage,
            hq_id: hqBranchData.target_list[hqIdx].hq_info.hq_id,
            branch_id: selectedBranch.branch_id,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setTotalPage(res.data.total_page);
        setScheduleList(res.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  const checkPermission = async () => {
    const result = await getPermission(storesDispatch, "ACCESS_MENU/CARD_MANAGE");
    setHasPermission(result);
  };

  //초기 랜더링
  useEffect(() => {
    if (hqBranchData.target_list.length !== 0) {
      const firstBranch = hqBranchData.target_list[0].branch_list[0];
      setSelectedBranch(firstBranch);
    }
    checkPermission();
  }, [hqBranchData]);

  //지점이 바뀌면 지점스케줄 요청
  useEffect(() => {
    getScheduleList(1);
  }, [selectedBranch.branch_id]);

  //페이지네이션
  useEffect(() => {
    if (curPage) {
      getScheduleList(curPage);
    }
  }, [curPage]);

  //스케줄모달 POP
  const popScheduleListModal = () => {
    if (isAdmin) {
      const param = {
        title: "스케줄 목록",
        modalParam: {},
      };
      onClickOpenModal("스케줄목록", param, scheduleListModalDone);
    } else {
      simpleAlert(storesDispatch, "알림", "서비스 준비중입니다.");
    }
  };

  //스케줄모달 DONE
  const scheduleListModalDone = () => {
    getScheduleList(1); //스케줄리스트 갱신
  };

  //공휴일 모달 POP
  const popHolidayModal = () => {
    const param = {
      title: "공휴일 목록",
      modalParam: {},
    };
    onClickOpenModal("공휴일목록", param);
  };

  //스케줄 수정모달 DONE
  const editModalDone = () => {
    storesDispatch(globalModalOff()); //모달닫기
    getScheduleList(1); //스케줄 리스트 갱신
  };

  //스케줄 수정모달 POP
  const onEditRow = (data) => {
    if (isAdmin) {
      const param = {
        title: "스케줄 수정",
        modalParam: {
          branch_info: data.branch_info,
          user_group_info: data.user_group_info,
          schedule_name_list: data.schedule_name_list,
          schedule_preset_info: data.schedule_preset_info.schedule_list,
        },
      };
      onClickOpenModal("스케줄수정", param, editModalDone);
    } else {
      simpleAlert(storesDispatch, "알림", "서비스 준비중입니다.");
    }
  };

  const deviceInfoTableItem = new deviceInfoTable(onEditRow);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}>
          <ComboBox
            labelWidth={"3.25rem"}
            comboItemWidth={"11rem"}
            labelText="본부"
            defaultValue={hqBranchData.hq_list[0]}
            data={hqBranchData.hq_list}
            onChangeCallback={(e) => {
              const hIdx = hqBranchData.hq_list.findIndex((el) => el === e.target.value);
              if (hIdx !== -1) {
                setHqIdx(hIdx);
                setSelectedBranch({ branch_id: "", branch_name: "" });
              }
            }}
          />
          <SearchComboBox
            marginLeft={"2rem"}
            labelWidth={"3.25rem"}
            comboItemWidth={"11rem"}
            labelText="지점"
            defaultValue={hqBranchData.branch_list[hqIdx][0] || ""}
            data={hqBranchData.branch_list[hqIdx]}
            onChangeCallback={(e, v) => {
              const branch = hqBranchData.target_list[hqIdx].branch_list.find(
                (el) => el.branch_name === v,
              );
              if (branch.branch_name) {
                setSelectedBranch(branch);
              }
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            gap: "0.3rem",
          }}
        >
          {hasPermission && (
            <NormalBtn name=" 공휴일 목록" theme="white" onClick={() => popHolidayModal()} />
          )}

          <NormalBtn name="스케줄 목록" onClick={() => popScheduleListModal()} />
        </div>
      </div>

      <div style={{ marginTop: "1.2rem" }}>
        <CustomTable
          columns_head={deviceInfoTableItem.columns_head}
          table_title={deviceInfoTableItem.table_title}
          rest_call={deviceInfoTableItem.get_data_from_rest}
          row_render={deviceInfoTableItem.create_table}
          rest_data={scheduleList}
        />
        <CustomPagination curPage={curPage} doSearch={setCurPage} totalCnt={totalPage} />
      </div>
    </div>
  );
};
export default DeviceManageScheduleListView;

class deviceInfoTable {
  table_title = "";
  onEditRow = null;

  constructor(onEditRow) {
    this.onEditRow = onEditRow;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.reg_info.updated_at}</CustomTableContents>
        <CustomTableContents>{data.reg_info.upd_name}</CustomTableContents>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.user_group_info.user_group_name}</CustomTableContents>
        <CustomTableContents>{data.schedule_info.schedule_name}</CustomTableContents>
        <CustomTableContentsEND align="center">
          <IconButton type="edit" onClick={() => this.onEditRow(data)} />
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "최근 수정일", width: "18%" },
    { title: "담당자", width: "16%" },
    { title: "지점명", width: "16%" },
    { title: "사용자 그룹", width: "12%" },
    { title: "스케줄명", width: "28%" },
    { title: "", width: "10%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
