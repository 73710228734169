import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { getByte } from "components/CommonLib/CommonLib";
import ColorLabel from "components/LabelInput/ColorLabel";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import TextArea from "components/LabelInput/TextArea";
import TextField from "components/LabelInput/TextField";
import TimePicker from "components/LabelInput/TimePicker";
import { globalAlertOn } from "modules/actions/Alert";
import { newDeleteRowAPi } from "pages/NoticeBoard/restApi/deleteApi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const StaffDMDetailContent = ({ postRowState, nowService }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const navigate = useNavigate();
  const labelWidth = "4rem";
  //게시물삭제 MSG
  const alertDeleteMsg = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="삭제 하시겠습니까?" />
          </>
        ),

        onBtnYes: () => {
          deletePost(postRowState);
        },
        onBtnNo: () => {},
      }),
    );
  };
  //게시물삭제
  const deletePost = async (rowData) => {
    newDeleteRowAPi(navigate, storesDispatch, rowData);
  };

  const getStaffNamePos = (data) => {
    return data.staff_info.staff_name + " " + data.staff_info.staff_pos;
  };
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", gap: "0.3rem" }}>
          <TextField
            labelText="본부"
            labelWidth="3.5rem"
            textBoxWidth="10rem"
            defaultValue={postRowState.hq_info.hq_name}
            disabled
          />

          <TextField
            labelText="지점"
            labelWidth="3.5rem"
            textBoxWidth="10rem"
            defaultValue={postRowState.branch_info.branch_name}
            disabled
          />
          <TextField
            labelText="작성일시"
            labelWidth="3.5rem"
            textBoxWidth="10rem"
            disabled
            defaultValue={postRowState.reg_info.created_at}
          />
          <TextField
            labelText="작성자"
            labelWidth="3.5rem"
            textBoxWidth="10rem"
            disabled
            defaultValue={postRowState.post_info.writer.staff_name}
          />
        </div>

        <div style={{ display: "flex", gap: "0.3rem" }}>
          <IconButton
            type="editBorder"
            onClick={() => {
              navigate(`/staff_dm/update/${postRowState.post_id}`);
            }}
          />
          <IconButton type="deleteBox" onClick={alertDeleteMsg} />
        </div>
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <TextField
          labelText="제목"
          labelWidth="3.7rem"
          textBoxWidth="56rem"
          defaultValue={postRowState.post_info.title}
          disabled
        />
      </div>
      {/* <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <TextField labelText="대상직원" labelWidth="3.7rem" textBoxWidth="56rem" disabled />
      </div> */}
      <div style={{ display: "flex" }}>
        <div style={{ width: labelWidth, display: "flex" }}>
          <Label width={labelWidth} labelText="대상직원" justifyContent="left" />
        </div>
        <div style={boxStyle}>
          {postRowState.target_staff_list.map((el) => (
            <ColorLabel
              width={((getByte(getStaffNamePos(el)) * 0.9) / 2 + 0.5).toString() + "rem"}
              labelText={getStaffNamePos(el)}
              bgColor="#222222"
              fontColor="#FFFFFF"
            />
          ))}
        </div>
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <DatePicker
          labelText="스케줄"
          defaultValue={
            postRowState.schedule_date_period.from_date
              ? postRowState.schedule_date_period.from_date
              : ""
          }
        />
        <TimePicker
          defaultValue={
            postRowState.schedule_date_period.from_time
              ? postRowState.schedule_date_period.from_time
              : ""
          }
        />
        <DatePicker
          labelText="~"
          defaultValue={
            postRowState.schedule_date_period.to_date
              ? postRowState.schedule_date_period.to_date
              : ""
          }
        />
        <TimePicker
          defaultValue={
            postRowState.schedule_date_period.to_time
              ? postRowState.schedule_date_period.to_time
              : ""
          }
        />
      </div>
      <div style={{ marginTop: "0.5rem" }}>
        <TextArea
          labelText="내용"
          labelWidth="3.96rem"
          fullWidth
          minRows="20"
          maxRows="20"
          defaultValue={postRowState.post_info.contents}
          disabled
        />
      </div>

      <div style={{ display: "flex", justifyContent: "center", marginTop: "0.7rem" }}>
        <Button
          className={clsx({
            [btnStyleClass.btnRootGrayWhite]: true,
            [btnStyleClass.buttonType6]: true,
          })}
          onClick={() => {
            navigate("/staff_dm");
          }}
        >
          목록 보기
        </Button>
      </div>
    </div>
  );
};

export default StaffDMDetailContent;

const boxStyle = {
  marginTop: "0.5rem",
  width: "100%",
  minHeight: "2.5rem",
  borderRadius: "5px",
  border: "1px solid #BBBBBB",
  backgroundColor: "#ebebeb",
  fontFamily: "AppleSDGothicNeo",
  fontSize: "0.9rem",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#000",
  boxSizing: "border-box",
  padding: "0.2rem",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: "0.2rem",
};
