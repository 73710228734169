import { Button } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CloseIcon from "../../assets/icon/pop-close@3x.png";

const PopupAlert = (props) => {
  const {
    isOpen,
    onClose,
    title,
    Content,
    width,
    ActionButtons,
    rootStyle,
    contentStyle,
    actionStyle,
    onBtnOk,
    onBtnYes,
    onBtnNo,
    onBtnCancel,
    reqConfirm,
  } = props;

  const titleStyle = {
    height: "3rem",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "1.125rem",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#032063",
  };

  const modalClose = {
    height: "0.875rem",
    width: "0.875rem",
  };

  const bodyStyle = {
    maxHeight: "44rem",
    backgroundColor: "#f7f7f7",
  };

  const contentsStyle = {
    fontFamily: "AppleSDGothicNeo",
    fontSize: props.fontSize ? props.fontSize : "0.938rem",
    fontWeight: props.fontWeight ? props.fontWeight : "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: props.color ? props.color : "#000",
  };

  const btnStyleClass = btnStyles();

  return (
    <div style={rootStyle}>
      <Dialog
        open={isOpen}
        onClose={reqConfirm ? () => {} : onClose}
        transitionDuration={{ enter: 100, exit: 100 }}
        maxWidth={false}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            if (props.onBtnYesCallback) props.onBtnYesCallback();
            else if (props.onBtnOkCallback) props.onBtnOkCallback();
          }
        }}
      >
        {/* <DialogTitle > */}
        <div style={titleStyle}>
          {title}
          {reqConfirm ? (
            <></>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                float: "right",
                width: "2.875rem",
                height: "3rem",
                cursor: "pointer",
                position: "absolute",
                right: "0",
                top: "0",
              }}
              onClick={() => onClose()}
            >
              <img style={modalClose} src={CloseIcon} alt="" />
            </div>
          )}
        </div>
        {/* </DialogTitle> */}
        <div
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: "#b8b8b8",
          }}
        ></div>
        <DialogContent style={contentsStyle}>{Content}</DialogContent>
        {/* <DialogActions style={actionStyle}>{ActionButtons}</DialogActions> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1rem",
            marginBottom: "1rem",
            minWidth: "20rem",
            width: width ? width : "",
          }}
        >
          {props.onBtnOkCallback !== undefined ? (
            <Button
              onClick={props.onBtnOkCallback}
              className={clsx({
                [btnStyleClass.btnRootRaw]: true,
                [btnStyleClass.buttonType3]: true,
              })}
            >
              확인
            </Button>
          ) : (
            ""
          )}

          {props.onBtnYesCallback !== undefined ? (
            <Button
              onClick={props.onBtnYesCallback}
              className={clsx({
                [btnStyleClass.btnRootRaw]: true,
                [btnStyleClass.buttonType3]: true,
              })}
            >
              예
            </Button>
          ) : (
            ""
          )}

          {props.onBtnNoCallback !== undefined ? (
            <div
              style={{
                marginLeft: "0.75rem",
              }}
            >
              <Button
                onClick={props.onBtnNoCallback}
                className={clsx({
                  [btnStyleClass.btnRootWhite]: true,
                  [btnStyleClass.buttonType3]: true,
                })}
              >
                아니오
              </Button>
            </div>
          ) : (
            ""
          )}

          {props.onBtnCancelCallback !== undefined ? (
            <div
              style={{
                marginLeft: "0.75rem",
              }}
            >
              <Button
                onClick={props.onBtnCancelCallback}
                className={clsx({
                  [btnStyleClass.btnRootRaw]: true,
                  [btnStyleClass.buttonType3]: true,
                })}
              >
                취소
              </Button>
            </div>
          ) : (
            ""
          )}

          {props.onBtnCloseCallback !== undefined ? (
            <div
              style={{
                marginLeft: "0.75rem",
              }}
            >
              <Button
                onClick={props.onBtnCloseCallback}
                className={clsx({
                  [btnStyleClass.btnRootGrayWhite]: true,
                  [btnStyleClass.buttonType3]: true,
                })}
              >
                닫기
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
      </Dialog>
    </div>
  );
};

PopupAlert.defaultProps = {};

export default PopupAlert;
