import IconButton from "components/Button/IconButton";
import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableCellChild,
  CustomTableCellFit,
  CustomTableContents,
} from "components/CustomTable/tableStyle";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const PayListTable = ({ memberId, pCode }) => {
  const storesDispatch = useDispatch();
  const [curPage, setCurPage] = useState();
  const [totalPage, setTotalPage] = useState(0);
  const [payList, setPayList] = useState([]);

  // const historyInfoState = useSelector((state) => state.member_history.purchase_history_state);

  useEffect(() => {
    getPurchaseDetail(1);
    // storesDispatch(loadPurchaseHistoryStore(memberId));
  }, [memberId]);

  //결제환불 GET
  const getPurchaseDetail = async (page) => {
    setCurPage(page);
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_USER_SERVER + "/purchase/detail/hist",
        {
          params: {
            member_id: memberId,
            purchase_code: pCode,
            page_num: curPage,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setPayList(res.data.data);
        setTotalPage(res.data.total_page);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  //페이지네이션
  // useEffect(() => {
  //   if (curPage) {
  //     getPurchaseDetail(curPage);
  //   }
  // }, [curPage]);

  const purchaseListItem = new purchaseListClass();

  return (
    <>
      <div style={{ marginTop: "1rem" }}>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <SubTitle titleText="결제 / 환불 이력" />
        </div>

        <CustomTable
          columns_head={purchaseListItem.columns_head}
          table_title={purchaseListItem.table_title}
          rest_call={purchaseListItem.get_data_from_rest}
          row_render={purchaseListItem.create_table}
          rest_data={payList}
        />
        <CustomPagination curPage={curPage} doSearch={getPurchaseDetail} totalCnt={totalPage} />
      </div>
    </>
  );
};

export default PayListTable;

class purchaseListClass {
  table_title = "";

  getChildPaidProduct = (data) => {
    return (
      <CustomTableCellFit align="center" max_cnt={2}>
        {data.paid_detail &&
          data.paid_detail.map((el, idx) => (
            <CustomTableCellChild
              key={idx}
              rowRate={1}
              isLast={data.paid_detail.length === 1 ? true : false}
            >
              {el.description}
            </CustomTableCellChild>
          ))}
      </CustomTableCellFit>
    );
  };
  getChildPaidPrice = (data) => {
    return (
      <CustomTableCellFit align="center" max_cnt={2}>
        {data.paid_detail &&
          data.paid_detail.map((el, idx) => (
            <CustomTableCellChild
              textAlign="right"
              key={idx}
              rowRate={1}
              isLast={data.paid_detail.length === 1 ? true : false}
            >
              {el.paid_price.toLocaleString() + " 원"}
            </CustomTableCellChild>
          ))}
      </CustomTableCellFit>
    );
  };
  getChildUnPaidPrice = (data) => {
    return (
      <CustomTableCellFit align="center" max_cnt={2}>
        {data.paid_detail &&
          data.paid_detail.map((el, idx) => (
            <CustomTableCellChild
              textAlign="right"
              key={idx}
              rowRate={1}
              isLast={data.paid_detail.length === 1 ? true : false}
            >
              {el.unpaid_price.toLocaleString() + " 원"}
            </CustomTableCellChild>
          ))}
      </CustomTableCellFit>
    );
  };
  getChildRefundPrice = (data) => {
    return (
      <CustomTableCellFit align="center" max_cnt={2}>
        {data.paid_detail &&
          data.paid_detail.map((el, idx) => (
            <CustomTableCellChild
              textAlign="right"
              key={idx}
              rowRate={1}
              isLast={data.paid_detail.length === 1 ? true : false}
            >
              {el.refund_price.toLocaleString() + " 원"}
            </CustomTableCellChild>
          ))}
      </CustomTableCellFit>
    );
  };
  onClickEvent = (data) => {
    // console.log(data);
    this.onParentClickEvent(data);
  };

  create_table = (data, idx) => {
    // console.log(data);
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.reg_date}</CustomTableContents>
        <CustomTableContents>{data.reg_info.reg_name}</CustomTableContents>
        <CustomTableContents>{data.reason}</CustomTableContents>
        <CustomTableContents $isNumber>
          {data.total_price.toLocaleString() + " 원"}
        </CustomTableContents>
        {this.getChildPaidProduct(data)}
        {this.getChildPaidPrice(data)}
        {this.getChildUnPaidPrice(data)}
        {this.getChildRefundPrice(data)}
      </CustomRow>
    );
  };
  columns_head = [
    { title: "등록일시", width: "8%" },
    { title: "지점", width: "8%" },
    { title: "반영일", width: "8%" },
    { title: "담당자", width: "7%" },
    { title: "사유", width: "8%" },
    { title: "합계 금액", width: "8%" },
    { title: "상품 내역", width: "9%" },
    { title: "결제 금액", width: "8%" },
    { title: "미납금", width: "8%" },
    { title: "환불 금액", width: "8%" },
  ];
  get_data_from_rest = (stateData) => {
    // console.log(stateData);
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
