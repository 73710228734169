import {
  branch_link_state,
  document_info_state,
} from "pages/ERP/MemberView/Module/reducers/etc_reducer";

import {
  friend_info_state,
  family_info_state,
} from "pages/ERP/MemberView/Module/reducers/family_friend_reducer copy";

import { combineReducers } from "redux";

export const reduxReducerDepth2 = combineReducers({
  friend_info_state,
  family_info_state,
  branch_link_state,
  document_info_state,
});
