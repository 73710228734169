import IconButton from "components/Button/IconButton";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";

const UnpaidExemptTable = ({ viewData }) => {
  const resultTableInst = new resultTable();
  const getTotalCnt = () => {
    const countedId = [];
    let cnt = 0;
    for (let i = 0; i < viewData.length; i++) {
      if (countedId.findIndex((x) => x === viewData[i].member_info.member_no) === -1) {
        countedId.push(viewData[i].member_info.member_no);
        cnt++;
      }
    }
    return cnt;
  };

  const getTotalPrice = () => {
    return viewData.reduce((sum, cur) => {
      return sum + cur.unpaid_info.unpaid_price;
    }, 0);
  };
  return (
    <div style={{ marginTop: "0.5rem" }}>
      <div style={{ display: "flex", marginBottom: "0.5rem" }}>
        <IconButton type="border" />
        <Label labelText="미납 제외 처리 목록" marginLeft="0.5rem" fontWeight="bold" />
      </div>
      <CustomTable
        columns_head={resultTableInst.columns_head}
        table_title={resultTableInst.table_title}
        rest_call={resultTableInst.get_data_from_rest}
        row_render={resultTableInst.create_table}
        rest_data={viewData}
        height="18rem"
      />
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem" }}>
        <TextField
          labelText="총 인원"
          labelWidth="4.5rem"
          textBoxWidth="10rem"
          isNumber
          endAdornment={"명"}
          defaultValue={getTotalCnt().toLocaleString()}
          disabled
        />
        <TextField
          marginLeft="4rem"
          labelText="총 금액"
          labelWidth="4.5rem"
          textBoxWidth="10rem"
          textMarginRight="0rem"
          isNumber
          defaultValue={getTotalPrice().toLocaleString()}
          endAdornment={"원"}
          disabled
        />
      </div>
    </div>
  );
};
export default UnpaidExemptTable;

class resultTable {
  table_title = "";

  // constructor() {}

  create_table = (data, idx) => {
    console.log(data);
    return (
      <CustomRow style={{}} key={idx}>
        <CustomTableContents>{data.unpaid_info.purchase_code}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_name}</CustomTableContents>
        <CustomTableContents>{data.unpaid_info.payment_type}</CustomTableContents>
        <CustomTableContents $isNumber>
          {data.unpaid_info.total_price.toLocaleString()}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.unpaid_info.unpaid_price.toLocaleString()}
        </CustomTableContents>
        <CustomTableContents>{data.unpaid_info.reg_info.reg_name}</CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "구매코드", width: "13.5%" },
    { title: "회원번호", width: "12.5%" },
    { title: "이름", width: "12.5%" },
    { title: "결제종류", width: "11%" },
    { title: "상품총액", width: "12.5%" },
    { title: "미납금액", width: "12.5%" },
    { title: "담당자", width: "12.5%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
