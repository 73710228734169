import { generateObjectZeroId } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import TextField from "components/LabelInput/TextField";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState, useTransition } from "react";
import MembershipDetail from "./MembershipItems/MembershipDetail";
import MembershipItemSelected from "./MembershipItems/MembershipItemSelected";
import MembershipItemSet from "./MembershipItems/MembershipItemSet";
import { preItemInitializer } from "./MembershipItems/preItemChange";
import { subsItemInitializer } from "./MembershipItems/subsItemChange";
import { structToMembershipType } from "./parseSettings";

const MembershipInfo = forwardRef((props, ref) => {
  const {
    regDate,
    payInfoListAction,
    subsPayInfoListAction,
    baseInfoState,
    onClickOpenModal,
    membershipIdx,
    memberInputState,
    memberInputStateAll,
    onPtItemUpdate,
    isAddPay,
    subs_lock,
    last_to_date,
    resetPaySubInfoListAction,
    setIsSubsNow,
    from_lock,
    updateToDate,
    defaultFromDate,
    onChangeMbshpSelect,
    onChangeMbshpOption,
    noReg,
    changeItem,
  } = props;

  const itemIncludeRef = {
    join: useRef(),
    cloth: useRef(),
    gx: useRef(),
    locker: useRef(),
    family_cloth: useRef(),
    family_gx: useRef(),
    family_locker: useRef(),
  };
  const labelWidth = "4.5rem";
  const comboItemWidth = "12rem";
  const [isPending, startTransition] = useTransition();
  const [rawMemberState, setRawMemberState] = useState(memberInputState);

  const membershipTypeList = structToMembershipType(baseInfoState, subs_lock);

  const [selectorInfo, setSelectorInfo] = useState({
    mbshp_type: memberInputState.membership_list.mbshp_type,
    item_type_idx: memberInputState.membership_list.item_type_idx,
    mbshp_option: memberInputState.membership_list.mbshp_option,
    item_option_idx: memberInputState.membership_list.item_option_idx,
    // item_name: memberInputState.membership_list.mbshp_option,
    // item_name_idx: memberInputState.membership_list.item_name_idx,
  });

  ///종류
  const [selectedItemInfo, setSelectedItemInfo] = useState(memberInputState.purchase_item_list);

  const membershipInfoItemRef = useRef();
  const membershipItemSelectRef = useRef();

  useEffect(() => {
    setSelectorInfo({
      mbshp_type: memberInputState.membership_list.mbshp_type,
      item_type_idx: memberInputState.membership_list.item_type_idx,
      mbshp_option: memberInputState.membership_list.mbshp_option,
      item_option_idx: memberInputState.membership_list.item_option_idx,
    });
  }, [memberInputState]);

  useEffect(() => {
    setSelectedItemInfo(memberInputState.purchase_item_list);
    if (membershipItemSelectRef.current !== undefined) {
      // if (memberInputState.membership_list.item_name_idx === 0) {
      membershipItemSelectRef.current.setItemIdx(memberInputState.membership_list.item_name_idx);
      // }
    }
  }, [memberInputState.tab_info.idx]);

  useImperativeHandle(ref, () => ({
    getSelectedItem: () => {
      return membershipItemSelectRef.current.getSelectedItem();
    },
    onChangeBranch: () => {
      return onBranchReset();
    },
  }));

  //종류 부터 초기화
  const onBranchReset = () => {
    // console.log("onBranchReset");
    startTransition(() => {
      memberInputState.membership_list.mbshp_type = "완납형";
      memberInputState.membership_list.item_type_idx = 0;
      memberInputState.membership_list.mbshp_option = "일반권";
      memberInputState.membership_list.item_option_idx = 0;
      // memberInputState.membership_list.item_name = "선택해주세요";
      // memberInputState.membership_list.item_name_idx = 0;

      setSelectorInfo({
        mbshp_type: "완납형",
        item_type_idx: 0,
        mbshp_option: "일반권",
        item_option_idx: 0,
        // item_name: "선택해주세요",
        // item_name_idx: 0,
      });
      if (membershipItemSelectRef.current !== undefined) {
        membershipItemSelectRef.current.resetBranch();
      }
    });
  };

  //종류 부터 초기화
  const onTypeReset = () => {
    // console.log("onTypeReset");
    startTransition(() => {
      memberInputState.membership_list.mbshp_option = "일반권";
      memberInputState.membership_list.item_option_idx = 0;
      // memberInputState.membership_list.item_name = "선택해주세요";
      // memberInputState.membership_list.item_name_idx = 0;
      setSelectorInfo({
        mbshp_type: memberInputState.membership_list.mbshp_type,
        item_type_idx: memberInputState.membership_list.item_type_idx,
        mbshp_option: "일반권",
        item_option_idx: 0,
        // item_name: "선택해주세요",
        // item_name_idx: 0,
      });
    });
  };

  const onTypeChange = (val) => {
    // console.log("onTypeChange");
    const index = membershipTypeList.map((item) => item).indexOf(val);
    if (index !== -1) {
      memberInputState.membership_list.item_type_idx = index;
      memberInputState.membership_list.mbshp_type = membershipTypeList[index];
      onTypeReset();

      if (membershipItemSelectRef.current !== undefined) {
        membershipItemSelectRef.current.resetType(val);
      }
    }
  };

  const onChangeItemSelect = (itemIdx) => {
    if (onChangeMbshpSelect) {
      onChangeMbshpSelect(); // 상품변경 전용
    }
    membershipInfoItemRef.current.setEffectItemSelect(itemIdx);
  };

  const preInitCls = new preItemInitializer(
    memberInputState,
    payInfoListAction,
    membershipIdx,
    noReg,
  );
  const subsInitCls = new subsItemInitializer(
    regDate,
    memberInputState,
    payInfoListAction,
    subsPayInfoListAction,
    membershipIdx,
  );

  const onSubsPeriodChange = () => {
    // MembershipItemSet에 있는 코드와 거의 유사함. 코드 합치기 전까진 같이 업데이트 해야함
    const mbshpList = memberInputState.membership_list;
    const getSubsOptions = (option) => {
      return {
        ...option,
        pre_dis: 0,
        pre_price: option.pre_price,
        prorate_dis: 0,
        prorate_price: option.prorate_price,
        subs_dis: 0,
        subs_price: option.subs_price,
      };
    };
    mbshpList.reg.dis = 0;
    mbshpList.reg.subs_options = getSubsOptions(mbshpList.reg.subs_options);
    mbshpList.gx.subs_options = getSubsOptions(mbshpList.gx.subs_options);
    mbshpList.mbshp.subs_options = getSubsOptions(mbshpList.mbshp.subs_options);
    mbshpList.cloth.subs_options = getSubsOptions(mbshpList.cloth.subs_options);
    for (let i = 0; i < mbshpList.locker_list.length; i++) {
      mbshpList.locker_list[i].subs_options = getSubsOptions(mbshpList.locker.subs_options);
    }
    // 가족것도 추가해야됨.

    // membershipItemSelectRef.current.setItemIdx(memberInputState.membership_list.item_name_idx);
    // membershipInfoItemRef.current.setEffectItemSelect();
  };

  const getCsStaffNameList = () => {
    const result = [...baseInfoState.cs_team_name_list];
    result.splice(0, 0, "추후 선택");
    return result;
  };

  return (
    <div>
      {isAddPay ? (
        <></>
      ) : (
        <div
          style={{
            display: "flex",
            float: "right",
            marginLeft: "auto",
          }}
        >
          <ComboBox
            required
            labelWidth={labelWidth}
            comboItemWidth={comboItemWidth}
            marginRight="0.25rem"
            labelText="CS 상담자"
            data={getCsStaffNameList()}
            onChangeCallback={(e) => {
              const index = baseInfoState.cs_team_name_list
                .map((item) => item)
                .indexOf(e.target.value);
              if (index !== -1) {
                memberInputStateAll.cs_staff.staff_id =
                  baseInfoState.cs_team_list.team[index].staff_id;
                memberInputStateAll.cs_staff.staff_name =
                  baseInfoState.cs_team_list.team[index].staff_name;
              } else {
                memberInputStateAll.cs_staff.staff_id = generateObjectZeroId();
                memberInputStateAll.cs_staff.staff_name = "추후 선택";
              }
            }}
          />
        </div>
      )}
      <SelectTypeItem
        selectorInfo={selectorInfo}
        baseInfoState={baseInfoState}
        membershipTypeList={membershipTypeList}
        onTypeChange={onTypeChange}
        memberInputState={memberInputState}
        setIsSubsNow={setIsSubsNow}
      />

      <div
        style={{
          backgroundColor: "#ebebeb",
        }}
      />

      {memberInputState.tab_info.is_add_pay === true &&
      memberInputState.tab_info.is_new === false ? (
        <MembershipItemSelected memberInputState={memberInputState} selectorInfo={selectorInfo} />
      ) : (
        <MembershipItemSet
          ref={membershipItemSelectRef}
          baseInfoState={baseInfoState}
          setSelectedItemInfo={setSelectedItemInfo}
          memberInputStateAll={memberInputStateAll}
          memberInputState={memberInputState}
          rawMemberState={rawMemberState}
          onChangeItemSelect={onChangeItemSelect}
          resetPaySubInfoListAction={resetPaySubInfoListAction}
          onPtItemUpdate={onPtItemUpdate}
          selectorInfo={selectorInfo}
          setSelectorInfo={setSelectorInfo}
          onChangeMbshpOption={onChangeMbshpOption}
        />
      )}

      <MembershipDetail
        ref={membershipInfoItemRef}
        regDate={regDate}
        defaultFromDate={defaultFromDate}
        payInfoListAction={payInfoListAction}
        subsPayInfoListAction={subsPayInfoListAction}
        baseInfoState={baseInfoState}
        onClickOpenModal={onClickOpenModal}
        membershipIdx={membershipIdx}
        itemIncludeRef={itemIncludeRef}
        memberInputState={memberInputState}
        memberInputStateAll={memberInputStateAll}
        rawMemberState={rawMemberState}
        onPtItemUpdate={onPtItemUpdate}
        isAddPay={isAddPay}
        from_lock={from_lock}
        selectorInfo={selectorInfo}
        last_to_date={last_to_date}
        updateToDate={updateToDate}
        preInitCls={preInitCls}
        subsInitCls={subsInitCls}
        onSubsItemChange={onSubsPeriodChange}
        noReg={noReg}
        changeItem={changeItem}
      />
    </div>
  );
});
export default MembershipInfo;

//
const SelectTypeItem = ({
  selectorInfo,
  baseInfoState,
  membershipTypeList,
  onTypeChange,
  memberInputState,
  setIsSubsNow,
}) => {
  const labelWidth = "4.5rem";
  const compWidth = "12rem";

  if (memberInputState.tab_info.is_add_pay === true && memberInputState.tab_info.is_new === false) {
    return (
      <TextField
        labelWidth={labelWidth}
        textBoxWidth={compWidth}
        labelText={"종류"}
        defaultValue={memberInputState.purchase_item_list.item_info.mbshp_type}
        disabled
      />
    );
  } else {
    return (
      <div>
        {memberInputState.tab_info.is_add_pay && memberInputState.tab_info.is_new ? (
          <ComboBox
            required
            labelWidth={labelWidth}
            comboItemWidth={compWidth}
            labelText="가입경로"
            defaultValue={memberInputState.membership_list.re_reg_path}
            data={baseInfoState.erp_setting.re_reg_path_list}
            onChangeCallback={(e) => {
              memberInputState.membership_list.re_reg_path = e.target.value;
            }}
          />
        ) : (
          <></>
        )}
        <ComboBox
          marginTop="0.5rem"
          labelWidth={labelWidth}
          comboItemWidth={compWidth}
          labelText="종류"
          defaultValue={selectorInfo.mbshp_type}
          data={membershipTypeList}
          onChangeCallback={(e) => {
            onTypeChange(e.target.value);
            if (e.target.value === "구독형") {
              setIsSubsNow(true);
            } else {
              setIsSubsNow(false);
            }
          }}
          disabled={memberInputState.membership_list.option_lock}
        />
      </div>
    );
  }
};
