import IconButton from "components/Button/IconButton";
import { restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import TextField from "components/LabelInput/TextField";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import CreateContractPopup from "./CreateContractPopup";
import PayListTable from "./PayListTable";
import PaymethodDetailTable from "./PaymethodDetailTable";

const PurchaseDetailModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    getAuthData();
  }, []);

  const getAuthData = async () => {
    await HttpInstance.get(process.env.REACT_APP_AUTH_SERVER + "/global/perm_auth", {})
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setIsAdmin(res.data.data.is_admin);
        }
      })
      .catch((err) => {
        console.log("err");
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const viewModelRef = useRef();
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });

  const localModalDone = () => {
    viewModelRef.current.setClose();
  };

  const openCreateContractPopup = () => {
    const param = {
      member_id: modalParam.memberId,
      purchase_code: modalParam.purchase_code,
    };
    setModalInfo({
      show: true,
      title: "기존 서명 선택",
      Content: <CreateContractPopup modalParam={param} onModalDone={localModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  return (
    <div
      style={{
        width: "76rem",
      }}
    >
      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />

      <div>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <SubTitle titleText="구매 정보" />
        </div>

        <div style={{ marginLeft: "1rem", display: "flex", justifyContent: "space-between" }}>
          <TextField
            labelWidth={"4rem"}
            textBoxWidth={"100%"}
            textMarginRight={"0.625rem"}
            labelText="구매코드"
            defaultValue={modalParam.purchase_code}
            disabled
          />
          {isAdmin && (
            <NormalBtn
              name="영수증 재생성"
              onClick={() => {
                openCreateContractPopup();
              }}
            />
          )}
        </div>
      </div>
      {/* 결제환불 테이블*/}
      <PayListTable memberId={modalParam.memberId} pCode={modalParam.purchase_code} />

      {/* 결제수단 테이블*/}
      <PaymethodDetailTable memberId={modalParam.memberId} pCode={modalParam.purchase_code} />
    </div>
  );
};
export default PurchaseDetailModal;
