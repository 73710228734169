import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty } from "components/CommonLib/CommonLib";
import SubTitle from "components/PageTitle/SubTitle";
import { useEffect, useRef, useState } from "react";
import EditTable from "./EditTable";

// import { makeStyles } from "@material-ui/core/styles";

// const useStyles = makeStyles((theme) => ({
//   table: {
//     // "& tbody>.MuiTableRow-root:hover": {
//     //   background: "blue",
//     // },
//     "& tbody>.MuiTableRow-root>.Mui-selected:selected": {
//       backgroundColor: "green",
//     },
//   },
// }));

// const theme = createMuiTheme({
//   overrides: {
//     MuiTableRow: {
//       root: {
//         "&$selected": {
//           backgroundColor: "red",
//         },
//       },
//     },
//   },
// });

const bodyStyle = {
  width: "23rem",
  // height: "20rem",
};
const midBlock = {
  width: "5rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};
const midStyle = {
  position: "absolute",
  // left: "35%",
  top: "25%",
  width: "50%",
};

const ExchangeElementTable = ({ titleName, dataListLeft, dataListRight, leftHead, rightHead }) => {
  // const classes = useStyles();
  const btnStyleClass = btnStyles();
  const leftTableRef = useRef();
  const rightTableRef = useRef();

  const [leftItemList, setLeftItemList] = useState(dataListLeft);
  const [rightItemList, setRightItemList] = useState(dataListRight);

  let itemList = new itemTable();

  useEffect(() => {
    if (dataListLeft && dataListLeft.length > 0) {
      setLeftItemList([...dataListLeft]);
    }

    return () => {
      setLeftItemList([]);
    };
  }, [dataListLeft]);

  useEffect(() => {
    if (dataListRight && dataListRight.length > 0) {
      setRightItemList([...dataListRight]);
    }

    return () => {
      setRightItemList([]);
    };
  }, [dataListRight]);

  const onMoveLeft = (e) => {
    if (dataListRight && dataListRight.length === 0) {
      return;
    }

    let selectedIdxList = rightTableRef.current.getSelectedStat();
    selectedIdxList.sort((x, y) => {
      return y - x;
    }); // 내림차순 아니면 에러남
    for (let i = 0; i < selectedIdxList.length; i++) {
      dataListLeft.push(rightItemList[selectedIdxList[i]]);
      dataListRight.splice(selectedIdxList[i], 1);
    }
    setLeftItemList([...dataListLeft]);
    setRightItemList([...dataListRight]);
  };

  const onMoveRight = (e) => {
    if (dataListLeft && dataListLeft.length === 0) {
      return;
    }

    let selectedIdxList = leftTableRef.current.getSelectedStat();
    selectedIdxList.sort((x, y) => {
      return y - x;
    });
    for (let i = 0; i < selectedIdxList.length; i++) {
      dataListRight.push(leftItemList[selectedIdxList[i]]);
      dataListLeft.splice(selectedIdxList[i], 1);
    }
    setLeftItemList([...dataListLeft]);
    setRightItemList([...dataListRight]);
  };

  return (
    // <div className={classes.table}>
    <div>
      {/* <MuiThemeProvider theme={theme}> */}
      <SubTitle titleText={titleName} />
      <div style={{ display: "flex" }}>
        <div style={bodyStyle}>
          <EditTable
            ref={leftTableRef}
            rest_call={itemList.get_data_from_rest}
            // row_render={itemList.create_table}
            rest_data={leftItemList}
            isLeft={true}
            headTitle={leftHead}
          />
        </div>
        <div style={midBlock}>
          <IconButton
            color="primary"
            aria-label="move to left"
            component="span"
            onClick={(e) => onMoveLeft(e)}
          >
            <ChevronLeftIcon />
          </IconButton>

          <IconButton
            color="primary"
            aria-label="move to right"
            component="span"
            onClick={(e) => onMoveRight(e)}
          >
            <ChevronRightIcon />
          </IconButton>
        </div>
        <div style={bodyStyle}>
          <EditTable
            ref={rightTableRef}
            rest_call={itemList.get_data_from_rest}
            // row_render={itemList.create_table}
            rest_data={rightItemList}
            isLeft={false}
            headTitle={rightHead}
          />
        </div>
      </div>
    </div>
  );
};
export default ExchangeElementTable;

class itemTable {
  table_title = "";

  // createData(id, name) {
  //   return {
  //     name,
  //   };
  // }

  // columns_head = [{ title: "123", field: "select" }];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    let resArr = [];
    for (let i = 0; i < stateData.length; i++) {
      // resArr.push(this.createData(i, stateData[i]));
      resArr.push(stateData[i]);
    }

    return resArr;
  };
}
