import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import ScheduleWrapper from "./ScheduleWrapper";

const ScheduleService = () => {
  return (
    <div>
      <PageFrame
        children={
          <div>
            <PageTitle titleText="스케줄" />
            <ScheduleWrapper />
          </div>
        }
      />
    </div>
  );
};
export default ScheduleService;
