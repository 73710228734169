const { generateObjectZeroId } = require("components/CommonLib/CommonLib");

export const locker_info_state = {
  view_id: "",

  item_info: {
    dis: 0,
    is_hide: false,
    is_lock: false,
    is_use: true,
    is_use_exist: false,
    paid_item_idx: 0,
    price: 0,
    subs_options: {
      pre_dis: 0,
      pre_price: 0,
      prorate_dis: 0,
      prorate_price: 0,
      subs_dis: 0,
      subs_price: 0,
    },
  },
  locker_info: {
    family_info: {
      family_info_id: "",
      is_use: false,
      member_id: "",
      member_name: "",
    },
    from_date: "",
    locker_total_idx: 0,
    mbshp_locker_id: "",
    locker_no: 0,
    membership_id: generateObjectZeroId(),
    password: "",
    queue_id: "",
    to_date: "",
    zone_id: "",
    zone_name: "",
  },
};
