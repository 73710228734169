import { Button } from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Collapse, IconButton as MuiIconButton } from "@mui/material";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import Label from "components/LabelInput/Label";
import { useEffect, useState } from "react";
import SearchDetailMbshpContents from "./SearchDetailMbshpContents";
import SearchDetailMemberContents from "./SearchDetailMemberContents";
import SearchDetailPtContents from "./SearchDetailPtContents";
import SearchDetailSideContents from "./SearchDetailSideContents";

const SearchDetailArea = (props) => {
  const {
    searchParam,
    setSearchParam,
    detailOption,
    setDetailOption,
    openSearchDetail,
    setOpenSearchDetail,
    onChangeDetailOption,
  } = props;
  const btnStyleClass = btnStyles();

  const [searchDetailTitle, setSearchDetailTitle] = useState("기준별 검색 조건");

  useEffect(() => {
    onSelectDetailOpt(detailOption);
  }, [detailOption]);

  const onSelectDetailOpt = (option) => {
    switch (option) {
      case "member":
        setSearchDetailTitle("기준별 검색 조건 : 회원");
        break;
      case "mbshp":
        setSearchDetailTitle("기준별 검색 조건 : 회원권");
        break;
      case "pt":
        setSearchDetailTitle("기준별 검색 조건 : PT");
        break;
      case "side":
        setSearchDetailTitle("기준별 검색 조건 : 부가상품");
        break;
      case "locker":
        setSearchDetailTitle("기준별 검색 조건 : 부가상품");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Box
        sx={{
          border: "1px solid #dedede",
          borderRadius: "5px",
          backgroundColor: "#f5f5f5",
        }}
      >
        <div
          style={{ display: "flex" }}
          onClick={() => {
            setOpenSearchDetail(!openSearchDetail);
          }}
        >
          <MuiIconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpenSearchDetail(!openSearchDetail);
            }}
          >
            {openSearchDetail ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </MuiIconButton>
          <Label labelText={searchDetailTitle} />
        </div>

        <Collapse in={openSearchDetail} timeout="auto" unmountOnExit>
          <div style={{ margin: "0.75rem 0.5rem" }}>
            <div style={{ display: "flex" }}>
              <Button
                style={{ borderRadius: "0.3125rem 0 0 0.3125rem" }}
                className={clsx({
                  [btnStyleClass.btnGrpActive]: true,
                  [btnStyleClass.buttonType4]: true,
                  [btnStyleClass.btnGrpDeactive]: detailOption !== "member",
                })}
                onClick={(e) => {
                  setSearchParam((prev) => {
                    return {
                      ...prev,
                      pending: true,
                    };
                  });
                  onChangeDetailOption("member");
                  setDetailOption("member");
                }}
              >
                회원
              </Button>
              <Button
                className={clsx({
                  [btnStyleClass.btnGrpActive]: true,
                  [btnStyleClass.buttonType4]: true,
                  [btnStyleClass.btnGrpDeactive]: detailOption !== "mbshp",
                })}
                onClick={(e) => {
                  setSearchParam((prev) => {
                    return {
                      ...prev,
                      pending: true,
                    };
                  });
                  onChangeDetailOption("mbshp");
                  setDetailOption("mbshp");
                }}
              >
                회원권
              </Button>
              <Button
                className={clsx({
                  [btnStyleClass.btnGrpActive]: true,
                  [btnStyleClass.buttonType4]: true,
                  [btnStyleClass.btnGrpDeactive]: detailOption !== "pt",
                })}
                onClick={(e) => {
                  setSearchParam((prev) => {
                    return {
                      ...prev,
                      pending: true,
                    };
                  });
                  onChangeDetailOption("pt");
                  setDetailOption("pt");
                }}
              >
                PT
              </Button>
              <Button
                style={{ borderRadius: "0 0.3125rem 0.3125rem 0" }}
                className={clsx({
                  [btnStyleClass.btnGrpActive]: true,
                  [btnStyleClass.buttonType5]: true,
                  [btnStyleClass.btnGrpDeactive]:
                    detailOption !== "side" && detailOption !== "locker",
                })}
                onClick={(e) => {
                  setSearchParam((prev) => {
                    return {
                      ...prev,
                      pending: true,
                    };
                  });
                  onChangeDetailOption("side");
                  setDetailOption("side");
                }}
              >
                부가상품
              </Button>
            </div>

            <div style={{ marginTop: "1.25rem" }}>
              {detailOption === "member" ? (
                <SearchDetailMemberContents searchParam={searchParam} />
              ) : detailOption === "mbshp" ? (
                <SearchDetailMbshpContents searchParam={searchParam} />
              ) : detailOption === "pt" ? (
                <SearchDetailPtContents searchParam={searchParam} />
              ) : detailOption === "side" || detailOption === "locker" ? (
                <SearchDetailSideContents
                  searchParam={searchParam}
                  setDetailOption={setDetailOption}
                />
              ) : (
                <div>
                  <Label
                    labelText="상세 검색 기준을 선택해주세요"
                    color="#777"
                    marginTop="5rem"
                    marginBottom="5rem"
                  />
                </div>
              )}
            </div>
          </div>
        </Collapse>
      </Box>
    </>
  );
};
export default SearchDetailArea;
