import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import CheckBox from "components/LabelInput/CheckBox";
import { forwardRef, useImperativeHandle } from "react";
import { useDispatch } from "react-redux";

const MultipleTable = forwardRef((props, ref) => {
  const {
    modalParam,
    onModalDone,
    searchParam,
    totalPage,
    curPage,
    searchResult,
    selectedMember,
    setSelectedMember,
    onClickSearch,
  } = props;

  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();

  useImperativeHandle(ref, () => ({
    resetList: () => {
      setSelectedMember([]);
    },
  }));

  const onCheckRow = (isChecked, data) => {
    const fIdx = searchResult.findIndex(
      (x) => x.member_info.member_id === data.member_info.member_id,
    );

    if (isChecked) {
      selectedMember.push(data);
    } else {
      const idx = selectedMember.findIndex(
        (x) => x.member_info.member_id === data.member_info.member_id,
      );
      if (idx !== -1) selectedMember.splice(idx, 1);
    }

    if (fIdx !== -1) {
      searchResult[fIdx].is_checked = isChecked;
    }
  };

  const searchResultList = new searchResultTable(onCheckRow);

  return (
    <div>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
        minHeight="25rem"
      />
      <CustomPagination curPage={curPage} doSearch={onClickSearch} totalCnt={totalPage} />
      <div style={{ display: "flex", justifyContent: "center", marginTop: "1.25rem" }}>
        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={() => {
            onModalDone(selectedMember);
          }}
        >
          확인
        </Button>
      </div>
    </div>
  );
});
export default MultipleTable;

export class searchResultTable {
  table_title = "";
  onCheckRow = null;

  constructor(onCheckRow) {
    this.onCheckRow = onCheckRow;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow style={{ width: "100%" }} key={idx}>
        <CustomTableContents>
          <CheckBox
            isTable
            defaultValue={data.is_checked ? true : false}
            onChangeCallback={(e) => {
              this.onCheckRow(e.checked, data);
            }}
          />
        </CustomTableContents>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.member_type}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.access.card_no}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_name}</CustomTableContents>
        <CustomTableContentsEND align="center">{data.base_info.phone}</CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "선택", width: "7%" },
    { title: "지점", width: "20%" },
    { title: "구분", width: "6%" },
    { title: "회원번호", width: "10%" },
    { title: "카드번호", width: "8%" },
    { title: "회원명", width: "18%" },
    { title: "연락처", width: "15%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
