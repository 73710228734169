export const refundInitStateAll = {
  member_id: "",
  contract_doc_list: [],
  paymentB: {
    payment_info: [
      {
        paid_item_list: [
          {
            MembershipIdx: 0,
            content_obj: {
              dis: 0,
              item_type: "",
              pay_order: 0,
              price: 0,
              tab_idx: 0,
              type: "",
            },
            locker_info: {
              item_info: {
                dis: 0,
                is_hide: true,
                is_lock: true,
                is_use: true,
                is_use_exist: true,
                price: 0,
                subs_options: {
                  pre_dis: 0,
                  pre_price: 0,
                  prorate_dis: 0,
                  prorate_price: 0,
                  subs_dis: 0,
                  subs_price: 0,
                },
              },
              locker_info: {
                family_info: {
                  family_info_id: "",
                  is_use: true,
                  member_id: "",
                  member_name: "",
                },
                from_date: "",
                mbshp_locker_id: "",
                locker_no: 0,
                locker_total_idx: 0,
                membership_id: "",
                password: "",
                queue_id: "",
                to_date: "",
                zone_id: "",
                zone_name: "",
              },
              paid_item_idx: 0,
            },
            orderIdx: 0,
            type_key: "",
          },
        ],
        paid_price: 0,
        payment_input: {
          pay_method: [
            {
              approve_no: "",
              bank_user_name: "",
              buyer: "",
              card_comp: "",
              card_no: "",
              id: "",
              // installment_plan: 0,
              installment: "",
              pay_date: "",
              pay_method: "",
              price: 0,
            },
          ],
          total_paid: 0,
          unpaid_info: {
            promise_date: "",
            reason: "",
            unpaid_price: 0,
          },
        },
        purchase_code: "",
        unpaid_price: 0,
      },
    ],
  },

  // refund_info_list: [
  //   {
  //     child_idx_list: [0],
  //     is_lock: true,
  //     is_merged: true,
  //     purchase_code: "",
  //     purchase_list: [
  //       {
  //         contract_price: 0,
  //         deduct_price: 0,
  //         description: "",
  //         discount_price: 0,
  //         is_merged: true,
  //         item_id: "",
  //         item_price: 0,
  //         item_type: "",
  //         mbshp_locker_id: "",
  //         mbshp_rfnd_day_price: 0,
  //         membership_id: "",
  //         paid_price: 0,
  //         penalty: 0,
  //         prepay_penalty_rate: 0,
  //         price: 0,
  //         pt_detail_id: "",
  //         purchase_code: "",
  //         purchase_id: "",
  //         reason: "",
  //         refund_price: 0,
  //         subs_penalty_rate: 0,
  //         unpaid_price: 0,
  //         usage_details: "",
  //       },
  //     ],
  //     selected: true,
  //   },
  // ],
  refund_info_list: [
    {
      child_idx_list: [0],
      is_lock: true,
      is_merged: true,
      purchase_code: "",
      purchase_list: [
        {
          contract_total_price: 0,
          deduct_price: 0,
          discount_price: 0,
          mbshp_rfnd_day_price: 0,
          penalty: 0,
          prepay_penalty_rate: 0,
          purchase_info: {
            branch_info: {
              branch_id: "",
              branch_name: "",
            },
            change_exp_date: "",
            contract_docs: [
              {
                file_name: "",
                file_type: "",
                reason: "",
                reg_info: {
                  created_at: "",
                  reg_id: "",
                  reg_name: "",
                  upd_id: "",
                  upd_name: "",
                  updated_at: "",
                },
              },
            ],
            contract_price: 0,
            coupon_info: {
              coupon_id: "",
              detail: {
                coupon_name: "",
                price_benefit: {
                  dis_price: 0,
                  dis_rate: 0,
                  type: "",
                },
              },
              final_dis: 0,
              ticket_id: "",
            },
            cs_staff: {
              staff_id: "",
              staff_name: "",
              staff_pos: "",
              staff_rank: "",
            },
            description: "",
            enroll_reg_date: "",
            etc_info: {
              count: 0,
              dis: 0,
              price: 0,
            },
            hq_policy: {
              mbshp_rfnd_day_price: 0,
              prepay_penalty_rate: 0,
              subs_penalty_rate: 0,
            },
            is_merged: false,
            is_valid: true,
            item_id: "",
            item_name: "",
            item_price: 0,
            item_type: "",
            mbshp_locker_id: "",
            membership_id: "",
            paid_price: 0,
            parent_purchase_code: "",
            point_info: {
              used_point: 0,
            },
            price: 0,
            pt_detail_id: "",
            purchase_code: "",
            purchase_id: "",
            purchase_item_id: "",
            reason: "",
            reg_info: {
              created_at: "",
              reg_id: "",
              reg_name: "",
              upd_id: "",
              upd_name: "",
              updated_at: "",
            },
            staff_performance: {
              staff_info: {
                staff_id: "",
                staff_name: "",
                staff_pos: "",
                staff_rank: "",
              },
              staff_performance_id: "",
              tmp_performance_id: "",
            },
            unpaid_price: 0,
          },
          refund_price: 0,
          subs_penalty_rate: 0,
          usage_details: "",
        },
      ],
      selected: true,
    },
  ],

  refund_list: [
    {
      done_refund_price: 0,
      payment_list: [
        {
          payment_data: {
            account_holder: "",
            approve_no: "",
            cancel_receipt: "",
            card_buyer: "",
            card_comp: "",
            card_no: "",
            cash_approve_no: "",
            installment: "0",
            is_valid: true,
            pay_date: "",
            pay_method: "",
            pay_receipt: "",
            payment_id: "",
            price: 0,
            purchase_code: "",
            remarks: "",
          },
          payment_list_selector: {
            is_hide: true,
            is_lock: true,
            selected: true,
          },
        },
      ],
      purchase_code: "",
      repaid_price: 0,
      refund_detail: [
        {
          account_holder: "",
          approve_no: "",
          bank_acc: "",
          cancel_date: "",
          cancel_receipt: "",
          cancel_status: "",
          cancel_type: "",
          card_buyer: "",
          card_comp: "",
          card_no: "",
          cash_approve_no: "",
          installment: "0",
          is_done: true,
          is_hide: true,
          is_lock: true,
          is_method_change: "",
          is_valid: true,
          pay_date: "",
          pay_method: "",
          pay_receipt: "",
          payment_id: "",
          price: 0,
          purchase_code: "",
          refund_method: "",
          remarks: "",
          selected: true,
        },
      ],
      selected: true,
      total_deduct_price: 0,
      total_dis_return_price: 0,
      total_paid_price: 0,
      total_penalty: 0,
      total_refund_price: 0,
    },
  ],
  refund_sum_mary: {
    deduct_price: 0,
    done_refund_price: 0,
    paid_price: 0,
    penalty: 0,
    repaid_price: 0,
    refund_price: 0,
  },
};
