import { useEffect, useState } from "react";

import { btnStyles } from "components/ButtonStyle/ButtonStyle";

import HQPolicyPayDayEdit from "./HQPolicyPayDayEdit";
import HQPolicySalaryPerEdit from "./HQPolicySalaryPerEdit";
import HQPolicyStaffSalaryByPosition from "./HQPolicyStaffSalaryByPosition";
import HQSetBranch from "./HQSetBranch";
import HQSetPermission from "./HQSetPermission";

import * as lodash from "lodash";
const HQSetIndex = ({ modalSelect, modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();

  const [paramData, setParamData] = useState(modalParam.modalParam);

  useEffect(() => {
    const editData = lodash.cloneDeep(modalParam.modalParam);
    setParamData(editData);
    return () => {};
  }, []);

  if (modalSelect === "본부관리/직원권한") {
    return <HQSetPermission modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "지점관리/등록/수정") {
    return <HQSetBranch modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "인정매출비율설정") {
    return <HQPolicySalaryPerEdit modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "급여일설정") {
    return <HQPolicyPayDayEdit modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "직책별기본값설정") {
    return <HQPolicyStaffSalaryByPosition modalParam={paramData} onModalDone={onModalDone} />;
  } else {
    return <div>current ELSE</div>;
  }
};
export default HQSetIndex;
