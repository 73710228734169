import { useEffect, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { getMemberAccessMethodHist } from "apis/member/member_info_api";
import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import { useDispatch } from "react-redux";

const AccessMethodHistTable = ({ memberId }) => {
  const storesDispatch = useDispatch();
  let accessMethidHistTable = new accessMethodHistClass();

  // 페이지 상태 관리
  const [searchResult, setSearchResult] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  // API 상태 관리
  const queryData = { page: page, memberId: memberId };

  // [GET] 출입 수단 변경 이력가져오기
  const { data: accessMethodHist } = useQuery({
    queryKey: ["accessMethodHist", queryData],
    queryFn: () => getMemberAccessMethodHist(queryData),
    enabled: !!memberId,
    // enabled: false,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });

  // API 받아온 데이터 세팅
  useEffect(() => {
    // 출입 수단 변경 이력 데이터
    if (accessMethodHist && !restEmpty(accessMethodHist)) {
      setSearchResult(accessMethodHist.data.data);
      setTotalPage(accessMethodHist.data.total_page);
    }
  }, [accessMethodHist]);

  return (
    <div>
      <CustomTable
        columns_head={accessMethidHistTable.columns_head}
        table_title={accessMethidHistTable.table_title}
        rest_call={accessMethidHistTable.get_data_from_rest}
        row_render={accessMethidHistTable.create_table}
        rest_data={searchResult}
      />
      <CustomPagination curPage={page} doSearch={setPage} totalCnt={totalPage} />
    </div>
  );
};
export default AccessMethodHistTable;

class accessMethodHistClass {
  // Date 표기법 YYYY-MM-DD HH:MM:SS
  formatDate = (data) => {
    const date = new Date(data);

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "Asia/Seoul",
    };
    let formattedDate = date.toLocaleString("ko-KR", options);

    formattedDate = formattedDate.replace(". ", "-").replace(". ", "-").replace(".", "").trim();

    return `${formattedDate}`;
  };
  // 상태 따라서 Row 생성
  formatStatus = (data) => {
    switch (data) {
      case "active":
        return true;
      case "expired":
        return false;
      default:
        return true;
    }
  };
  // 이벤트 체크
  formatEventTrigger = (data) => {
    switch (data) {
      case "change_access_method":
        return "출입 방식 변경";
      case "reissue_access_card":
        return "카드 재발급";
      case "new_member_registration":
        return "신규 등록";
      case "assign_membership":
        return "양도";
      case "receive_membership":
        return "양수";
      case "change_branch":
        return "지점 변경";
      default:
        return data;
    }
  };

  create_table = (data, idx) => {
    return (
      <>
        {this.formatStatus(data.status) ? (
          <CustomRow key={idx}>
            <CustomTableContents>{this.formatDate(data.triggered_at)}</CustomTableContents>
            <CustomTableContents>{data.access_method}</CustomTableContents>
            <CustomTableContents>{this.formatEventTrigger(data.event_trigger)}</CustomTableContents>
            <CustomTableContentsEND>{data.identification_number}</CustomTableContentsEND>
          </CustomRow>
        ) : (
          <></>
        )}
      </>
    );
  };
  columns_head = [
    { title: "변경일", width: "25%" },
    { title: "출입 방식", width: "25%" },
    { title: "사유", width: "25%" },
    { title: "비고", width: "25%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
