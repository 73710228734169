import { Collapse, Drawer, List, ListItem } from "@mui/material";
import { useResetSearchParams } from "hooks/common/useResetSearchParams";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { StyledButton, StyledChildButton, StyledDoubleDepthChildButton } from "./menuBarStyles";
import { useSideBarStore } from "./modules/store/sideBarState";

const Sidebar = ({ permissionState }) => {
  const location = useLocation();
  // 전역 상태 관리
  const { resetSearchParams } = useResetSearchParams();
  const {
    sideMenuStatus,
    setSideMenuStatus,
    sideMenuDoubleDepthOpen: doubleDepthOpen,
    setSideMenuDoubleDepthOpen: setDoubleDepthOpen,
    sideMultiMenuOpen,
    setSideMultiMenuOpen,
  } = useSideBarStore();

  const [permissionMenu, setPermissionMenu] = useState(permissionState.side_menu);

  useEffect(() => {
    setPermissionMenu(permissionState.side_menu);
  }, [permissionState]);

  // url 변경에 따른 메뉴 상태 변경
  useEffect(() => {
    setSideMenuStatus(location.pathname);
  }, [location.pathname]);

  const SidebarSingleMenu = ({ menuState, level }) => {
    if (!menuState?.menu_active) return null;

    return (
      <List component="div" disablePadding>
        <ListItem disableGutters sx={{ padding: 0 }}>
          {level === 2 ? (
            <StyledChildButton
              as={Link}
              to={menuState.menu_url}
              $isActive={sideMenuStatus[menuState.menu_title]}
              onClick={() => resetSearchParams(menuState)}
            >
              {menuState.menu_title}
            </StyledChildButton>
          ) : (
            <StyledButton
              as={Link}
              to={menuState.menu_url}
              $isActive={sideMenuStatus[menuState.menu_title]}
              $isRoot
              onClick={() => resetSearchParams(menuState)}
            >
              {menuState.menu_title}
            </StyledButton>
          )}
        </ListItem>
      </List>
    );
  };
  const SidebarMultiMenu = ({ menuState }) => {
    if (menuState && menuState.menu_active) {
      return (
        <div>
          <ListItem sx={{ padding: 0 }} disableGutters key={menuState.menu_title}>
            <StyledButton
              $isRoot
              $isActive={sideMenuStatus[menuState.menu_title]}
              $isSelected={sideMultiMenuOpen[menuState.menu_title]}
              style={{ width: "100%" }}
              onClick={() => setSideMultiMenuOpen(menuState.menu_title)}
            >
              {menuState.menu_title}
            </StyledButton>
          </ListItem>
          <div style={{ backgroundColor: "#f5f5f5" }}>
            <Collapse in={sideMultiMenuOpen[menuState.menu_title]} timeout="auto" unmountOnExit>
              {menuState.children.map((item, idx) => {
                if (item.children && item.children.length !== 0) {
                  return <SidebarDoubleDepth key={idx} menuState={item} />;
                } else {
                  return <SidebarSingleMenu key={idx} menuState={item} level={2} />;
                }
              })}
            </Collapse>
          </div>
        </div>
      );
    }
    return "";
  };

  const SidebarDoubleDepth = ({ menuState }) => {
    if (menuState && menuState.menu_active) {
      return (
        <div>
          <List component="div" disablePadding key={menuState.menu_title}>
            <ListItem disableGutters sx={{ padding: 0 }} key={menuState.menu_title}>
              <StyledChildButton
                $isSelected={doubleDepthOpen[menuState.menu_title]}
                onClick={() => setDoubleDepthOpen(menuState.menu_title)}
              >
                {menuState.menu_title}
              </StyledChildButton>
            </ListItem>
            <div>
              <Collapse in={doubleDepthOpen[menuState.menu_title]} timeout="auto" unmountOnExit>
                {menuState.children.map((el, idx) => (
                  <div
                    key={idx}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <List component="div" disablePadding key={el.menu_title}>
                      <ListItem
                        disableGutters
                        sx={{ padding: 0, width: "11.25rem" }}
                        key={el.menu_title}
                      >
                        <StyledDoubleDepthChildButton
                          as={Link}
                          $isActive={sideMenuStatus[el.menu_title]}
                          to={el.menu_url}
                          onClick={() => resetSearchParams(el)}
                        >
                          {`- ${el.menu_title}`}
                        </StyledDoubleDepthChildButton>
                      </ListItem>
                    </List>
                  </div>
                ))}
              </Collapse>
            </div>
          </List>
        </div>
      );
    }
    return "";
  };

  const handleMenu = () => {
    return (
      <div>
        <SidebarMultiMenu menuState={permissionMenu.board_menu} />
        <SidebarSingleMenu menuState={permissionMenu.schedule_menu} />
        <SidebarMultiMenu menuState={permissionMenu.class_menu} />
        <SidebarSingleMenu menuState={permissionMenu.locker_menu} />
        <SidebarMultiMenu menuState={permissionMenu.data_menu} />
        <SidebarMultiMenu menuState={permissionMenu.branch_menu} />
        <SidebarSingleMenu menuState={permissionMenu.approval_menu} />
        <SidebarMultiMenu menuState={permissionMenu.cs_menu} />
        <SidebarMultiMenu menuState={permissionMenu.access_menu} />
      </div>
    );
  };
  return (
    <React.Fragment>
      <Drawer
        anchor="left"
        sx={{
          width: "11.25rem",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "11.25rem",
            marginTop: "3.75rem",
            zIndex: 12,
          },
        }}
        open={true}
        variant="persistent"
      >
        <List sx={{ justifyContent: "left" }}>{handleMenu()}</List>
      </Drawer>
    </React.Fragment>
  );
};

export default Sidebar;
