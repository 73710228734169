export const APPROVAL_TYPE_WORK_INFO = "인사 처리 요청서";
export const APPROVAL_TYPE_HOLIDAY = "휴무 신청서";
export const APPROVAL_TYPE_EXP = "시행 품의서";
export const APPROVAL_TYPE_IMP = "지출 증빙서";
export const APPROVAL_TYPE_REFUND_TRANS = "환불이체 신청서";
export const APPROVAL_TYPE_SALARY = "급여 보고서";
export const APPROVAL_TYPE_INSURANCE = "보험 처리 요청서";
export const APPROVAL_TYPE_COOPERATION = "업무 협조 요청서";
export const APPROVAL_TYPE_ORDER = "발주 신청서";
export const APPROVAL_TYPE_VAULT_CASH = "시재금 입금 확인서";
