export const navMenuItems = {
  assign_menu: {
    menu_title: "양도/지점변경",
    menu_url: "/",
    menu_active: false,
    children: [],
  },
  register_menu: {
    menu_title: "신규등록",
    menu_url: "/register_member",
    menu_active: false,
    children: [],
  },
  search_menu: {
    menu_title: "고객/회원검색",
    menu_url: "/",
    menu_active: false,
    children: [],
  },
  setting_menu: {
    menu_title: "설정",
    menu_active: false,
    menu_url: "/",
    children: [
      {
        menu_title: "본부 설정",
        menu_url: "/hq_setting",
        menu_active: false,
      },
      {
        menu_title: "지점 설정",
        menu_url: "/branch_setting",
        menu_active: false,
      },
    ],
  },
};
// export default navMenuItems;
