import CardFixed from "components/Card/CardFixed";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import NormalBtn from "components/NewButton/NormalBtn";

const UnPaidInfoCardItem = ({ purchaseInfo, onClickOpenModal, memberId, branchId }) => {
  const popUnpayHistModal = () => {
    const param = {
      title: "미납금 내역",
      modalParam: {
        memberId: memberId,
      },
    };
    onClickOpenModal("미납금이력", param);
  };
  const popUnpayProcessModal = () => {
    const param = {
      title: "미납금 잔액 결제",
      modalParam: {
        member_id: memberId,
        unpaid: purchaseInfo.unpaid,
        branch_id: branchId,
      },
    };
    onClickOpenModal("미납금처리", param);
  };

  return (
    <div>
      {purchaseInfo.unpaid.total_unpaid <= 0 ? (
        <div
          style={{
            display: "flex",
            padding: "1rem 0",
            justifyContent: "center",
            alignItems: "center",
            // border: "1px solid red",
          }}
        >
          <Label labelText={"미납금이 없습니다."} fontSize="1rem" />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            marginTop: "1.25rem",
            justifyContent: "space-between",
            gap: "0.5rem",
          }}
        >
          <TextField
            labelWidth="6rem"
            textMarginRight="0.25rem"
            fullWidth
            labelText="미납 잔액"
            defaultValue={purchaseInfo.unpaid.total_unpaid.toLocaleString()}
            isNumber
            disabled
          />
          <div style={{ display: "flex", gap: "0.3rem" }}>
            <NormalBtn name="내역" theme="white" onClick={() => popUnpayHistModal()} />
            <NormalBtn name="잔액 결제" width="6.5rem" onClick={() => popUnpayProcessModal()} />
          </div>
        </div>
      )}
    </div>
  );
};

const UnPaidInfo = ({ baseInfoState, onClickOpenModal, memberId }) => {
  return (
    <CardFixed
      topDom={<div>미납금</div>}
      midDom={
        <UnPaidInfoCardItem
          purchaseInfo={baseInfoState.purchase_info}
          branchId={baseInfoState.branch_info.branch_id}
          onClickOpenModal={onClickOpenModal}
          memberId={memberId}
        />
      }
      isLoading={false}
      rootStyle={{
        // width: "40rem",
        width: "98%",
        height: "9rem",
        marginTop: "0.625rem",
        marginLeft: "0.625rem",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};
export default UnPaidInfo;
