export const approvalInfoInitState = {
  stores: {
    data: {
      work_info_id: "",
      staff_id: "",
      approval_status: "임시저장",
      status: "대기",

      base_info: {
        branch_info: {
          branch_id: "",
          branch_name: "",
        },
        hq_info: {
          hq_id: "",
          hq_name: "",
        },
        department: "선택",
        rank: "선택",
        position: "선택",
        join_date: "date / 입사일",
        resign_date: "date / 퇴사일",
        apply_month: "date / 변경사항 반영월",
      },
      work_role: {
        is_cs: false,
        is_ot: false,
        is_pt: false,
        is_pt_cs: false,
      },
      work_time_use: false,
      time_list: [
        {
          idx: 0,
          is_use: false,
          day_of_week: "string / 요일",
          from_time: "time / 근무 시작 시간",
          end_time: "time / 근무 종료 시간",
        },
      ],
      wage_type: "월급제",
      wage: 0,
      is_incentive: false,
      is_pt_extra: false,

      pt_commission: [
        {
          idx: 0,
          from_section: 0,
          to_section: 0,
          rate: 0,
        },
      ],

      pt_fee: [
        {
          idx: 0,
          from_section: 0,
          to_section: 0,
          rate: 0,
        },
      ],
      is_gx_extra: false,
      gx_class: [],
      files: {
        emp_contract_file: "string / 근로계약서 파일명",
        is_submit_emp_file: "미첨부",
        resign_file: "string / 사직서 파일명",
        is_submit_resign: "미첨부",
      },
    },
  },
};
