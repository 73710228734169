export const guest_book_state = {
  agree_marketing: false,
  agree_personal: false,
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  hq_info: {
    hq_id: "",
    hq_name: "",
  },
  inquiry_memo: "",
  inquiry_title: "",
  reg_info: {
    created_at: "",
    reg_id: "",
    reg_name: "",
    upd_id: "",
    upd_name: "",
    updated_at: "",
  },
  cs_date: "",
  cs_time: "",
  cs_type: "방명록",
  is_trans_member: false,
  member_id: "",
  member_name: "",
  phone: "",
  visit_path: "",
  gender: "",
  ages: "",
  cs_staff_info: {
    staff_id: "",
    sabun: "",
    staff_name: "",
    staff_pos: "",
    staff_rank: "",
  },
};

export const guest_book_staff_state = {
  cs_team_name_list: [],
  cs_team_list: {
    key: "",
    branch_id: "000000000000000000000000",
    branch_name: "",
    team: [
      {
        staff_id: "000000000000000000000000",
        staff_name: "",
        sabun: "",
        department: {
          org_id: "000000000000000000000000",
          org_name: "",
        },
        rank: {
          org_id: "000000000000000000000000",
          org_name: "",
        },
        position: {
          org_id: "000000000000000000000000",
          org_name: "",
        },
        show_name: "",
      },
    ],
  },
};
