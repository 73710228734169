import { restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

export const searchBoardAPi = async (
  storesDispatch,
  searchParam,
  boardType,
  stateSetter,
  pageSetter,
  page_num,
) => {
  console.log(searchParam);
  try {
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    const res = await HttpInstance.post(
      process.env.REACT_APP_BRANCH_SERVER + "/board/type/" + boardType + "/search/" + page_num,
      searchParam,
      // {
      //   search_param: searchParam,
      //   // searchParam,
      // },
      config,
    );
    // console.log(res.data);
    if (!restEmpty(res.data.data)) {
      stateSetter(res.data.data);
    }
    if (!restEmpty(res.data.total_page)) {
      pageSetter(res.data.total_page);
    }
  } catch (err) {
    console.log(err);
    simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
  }
};

export const getBoardRowAPi = async (storesDispatch, boardType, postId, stateSetter) => {
  try {
    if (postId !== "") {
      // console.log(process.env.REACT_APP_BRANCH_SERVER + "/board/" + postId + "/type/" + boardType);
      const res = await HttpInstance.get(
        process.env.REACT_APP_BRANCH_SERVER + "/board/" + postId + "/type/" + boardType,
      );
      // console.log(res.data);
      if (!restEmpty(res.data.data)) {
        stateSetter(res.data.data);
      }
    }
  } catch (err) {
    console.log(err);
    simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
  }
};

///전자결재

export const getApproveRowAPi = async (storesDispatch, approvalId, stateSetter) => {
  HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/approval/get_approval_info", {
    params: { approval_docs_id: approvalId },
  })
    .then((res) => {
      if (!restEmpty(res.data.data)) {
        console.log(res.data.data);
        stateSetter(res.data.data);
      }
    })
    .catch((err) => {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    });
};
