import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from "react";
import PriceDiscountInput from "pages/ERP/MemberRegister/Common/Etc/PriceDiscountInput";
import CheckBox from "components/LabelInput/CheckBox";
import SubTitle from "components/PageTitle/SubTitle";
import FamilyLockerListInfo from "pages/ERP/MemberCommonItems/LockerRegister/FamilyLockerListInfo";
import { SubLine } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";

const MembershipFamilyItem = ({
  payInfoListAction,
  membershipIdx,
  itemIncludeRef,
  memberInputState,
  hqBranchPolicy,
  personalInfo,
}) => {
  const disItemRef = {
    reg: useRef(),
    cloth: useRef(),
    gx: useRef(),
    locker: [useRef(), useRef()],
  };

  const getItemData = (is_lock) => {
    if (
      memberInputState.tab_info.is_add_pay === true &&
      memberInputState.tab_info.is_new === false
    ) {
      // addpaid 뺌
      // if (is_lock) {
      return memberInputState.purchase_item_list.item_info;
      // } else {
      //   return memberInputState.purchase_item_addpaid.item_info;
      // }
    } else {
      return memberInputState.purchase_item_list.item_info;
    }
  };

  const getOptionData = (is_lock) => {
    if (
      memberInputState.tab_info.is_add_pay === true &&
      memberInputState.tab_info.is_new === false
    ) {
      // addpaid 뺌
      // if (is_lock) {
      return memberInputState.purchase_item_list.item_info.mbshp_option;
      // } else {
      //   return memberInputState.purchase_item_addpaid.item_info.mbshp_option;
      // }
    } else {
      return memberInputState.purchase_item_list.item_info.mbshp_option;
    }
  };

  // const [lockerList, setLockerList] = useState([]);
  const onChangeCheck = (event, orderIdx, contentObj, stdPrice, payOrder) => {
    let act = event.checked ? "push" : "pop";
    let eventId = memberInputState.tab_info.title + event.id;
    let tabIdx = memberInputState.tab_info.idx;
    // console.log(event, orderIdx, contentObj, stdPrice);
    let startIdx = membershipIdx * 10;
    let mOpt = memberInputState.purchase_item_list.item_info.mbshp_option;

    payInfoListAction(
      act,
      eventId + "(가족회원)",
      {
        type: eventId + "(가족회원)",
        price: stdPrice,
        dis: 0,
        pay_order: startIdx + payOrder,
        tab_idx: tabIdx,
        // item_type: event.id + "(가족)",
        item_type: event.id + "/완납형/금액/" + mOpt + "/가족",
      },
      startIdx + orderIdx,
      membershipIdx,
    );
  };

  return (
    <div style={{}}>
      <SubLine margin="1rem" />
      <div style={{ display: "flex", marginBottom: "0.625rem" }}>
        <Label labelText={"부가상품"} />
      </div>

      <div style={{ display: "flex", marginTop: "0.5rem", marginLeft: "1rem" }}>
        <CheckBox
          marginTop="0rem"
          ref={itemIncludeRef.family_cloth}
          labelText="운동복"
          onChangeCallbackOrd={(event, orderIdx, contentObj) => {
            memberInputState.membership_list.family_cloth.is_use = event.checked;
            onChangeCheck(
              event,
              orderIdx,
              contentObj,
              memberInputState.purchase_item_list.item_info.cloth_price,
              3,
            );
            disItemRef.cloth.current.setDisabled(event.checked);
          }}
          orderIdx={2}
          disabled={memberInputState.membership_list.family_cloth.is_lock}
          defaultValue={memberInputState.membership_list.family_cloth.is_use}
        />
        <PriceDiscountInput
          ref={disItemRef.cloth}
          baseInfo={{
            tabTitle: memberInputState.tab_info.title,
            tabIdx: memberInputState.tab_info.idx,
            orderStartIdx: membershipIdx,
            typeKey: "운동복(가족회원)",
            type: "운동복(가족회원)",
            orderIdx: 2,
            payOrder: 3,
            item_type:
              "운동복/완납형/금액/" +
              getOptionData(memberInputState.membership_list.cloth.is_lock) +
              "/가족",
          }}
          // standardPrice={memberInputState.purchase_item_list.item_info.cloth_price}
          standardPrice={getItemData(memberInputState.membership_list.cloth.is_lock).cloth_price}
          payInfoListAction={payInfoListAction}
          onChangeCallback={(e) => {
            memberInputState.membership_list.family_cloth.dis = e;
          }}
          paramLock={memberInputState.membership_list.family_cloth.is_lock}
          paramUse={memberInputState.membership_list.family_cloth.is_use}
          discountValue={memberInputState.membership_list.family_cloth.dis}
        />
      </div>

      <div style={{ display: "flex", marginTop: "0.5rem", marginLeft: "1rem" }}>
        <CheckBox
          marginTop="0rem"
          ref={itemIncludeRef.family_gx}
          labelText="GX"
          onChangeCallbackOrd={(event, orderIdx, contentObj) => {
            memberInputState.membership_list.family_gx.is_use = event.checked;
            onChangeCheck(
              event,
              orderIdx,
              contentObj,
              memberInputState.purchase_item_list.item_info.gx_price,
              4,
            );
            disItemRef.gx.current.setDisabled(event.checked);
          }}
          orderIdx={3}
          disabled={memberInputState.membership_list.family_gx.is_lock}
          defaultValue={memberInputState.membership_list.family_gx.is_use}
        />
        <PriceDiscountInput
          ref={disItemRef.gx}
          baseInfo={{
            tabTitle: memberInputState.tab_info.title,
            tabIdx: memberInputState.tab_info.idx,
            orderStartIdx: membershipIdx,
            type: "GX(가족회원)",
            typeKey: "GX(가족회원)",
            orderIdx: 3,
            payOrder: 4,
            item_type:
              "GX/완납형/금액/" +
              getOptionData(memberInputState.membership_list.gx.is_lock) +
              "/가족",
          }}
          // standardPrice={memberInputState.purchase_item_list.item_info.gx_price}
          standardPrice={getItemData(memberInputState.membership_list.gx.is_lock).gx_price}
          payInfoListAction={payInfoListAction}
          onChangeCallback={(e) => {
            memberInputState.membership_list.family_gx.dis = e;
          }}
          paramLock={memberInputState.membership_list.family_gx.is_lock}
          paramUse={memberInputState.membership_list.family_gx.is_use}
          discountValue={memberInputState.membership_list.family_gx.dis}
        />
      </div>

      <div style={{ marginTop: "0.5rem", marginLeft: "1rem" }}>
        <FamilyLockerListInfo
          //   refStatus={props.refStatus}
          //   ref={props.lockerTableRef}

          //   settingData={props.settingData}
          //   periodIdx={periodIdx}
          //   membershipIdx={membershipIdx}
          hqBranchPolicy={hqBranchPolicy}
          memberInputState={memberInputState}
          // lockerList={lockerList}
          // setLockerList={setLockerList}
          isSubs={false}
          payInfoListAction={payInfoListAction}
          personalInfo={personalInfo}
        />
      </div>
    </div>
  );
};

export default MembershipFamilyItem;
