import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import TextField from "components/LabelInput/TextField";

// 본부
const BaseInfoCardItem = ({ hqBaseData }) => {
  const btnStyleClass = btnStyles();

  return (
    <div style={{}}>
      {!hqBaseData ? (
        <></>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              marginTop: "0.5rem",
            }}
          >
            <TextField
              labelWidth={"4rem"}
              textBoxWidth={"13rem"}
              textMarginRight={"1.625rem"}
              labelText="본부명"
              defaultValue={hqBaseData.hq_info.hq_name}
              disabled
            />

            <TextField
              marginLeft="3rem"
              labelWidth={"6rem"}
              textBoxWidth={"13rem"}
              textMarginRight={"1.625rem"}
              labelText="ERP 패키지명"
              defaultValue={hqBaseData.package_info.package_name}
              disabled
            />

            <TextField
              marginLeft="3rem"
              labelWidth={"3.25rem"}
              textBoxWidth={"13rem"}
              textMarginRight={"1.625rem"}
              labelText="등록일"
              defaultValue={hqBaseData.reg_info.created_at.substring(0, 10)}
              disabled
            />
          </div>

          <div
            style={{
              display: "flex",
              marginTop: "0.5rem",
            }}
          >
            <TextField
              labelWidth={"4rem"}
              textBoxWidth={"13rem"}
              textMarginRight={"1.625rem"}
              labelText="대표자명"
              defaultValue={hqBaseData.owner_info.owner_name}
              disabled
            />
            <TextField
              marginLeft="3rem"
              labelWidth={"6rem"}
              textBoxWidth={"13rem"}
              textMarginRight={"1.625rem"}
              labelText="연락처"
              defaultValue={hqBaseData.owner_info.owner_phone}
              disabled
            />
            <TextField
              marginLeft="3rem"
              labelWidth={"3.25rem"}
              textBoxWidth={"13rem"}
              textMarginRight={"1.625rem"}
              labelText="이메일"
              defaultValue={hqBaseData.owner_info.owner_email}
              disabled
            />
          </div>
        </>
      )}
    </div>
  );
};

const BaseInfo = ({ hqBaseData, rootWidth }) => {
  const btnStyleClass = btnStyles();

  return (
    <CardFixed
      topDom={
        <div style={{ display: "flex" }}>
          <div>본부 기본정보</div>
          <div style={{ marginLeft: "auto" }}>
            {/* <Button
              className={clsx({
                [btnStyleClass.btnRoot]: true,
                [btnStyleClass.buttonType2]: true,
                [btnStyleClass.disabled]: true,
              })}
              disabled
            //   onClick={() => onFiltersReset()}
            >
              수정
            </Button> */}
          </div>
        </div>
      }
      midDom={<BaseInfoCardItem hqBaseData={hqBaseData} />}
      isLoading={false}
      rootStyle={{
        // width: parseWidth(957),
        // width: "59.812rem",
        width: rootWidth,
        // height: "10.312rem",
        // height: "245px",
        marginBottom: "0.625rem",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};
export default BaseInfo;
