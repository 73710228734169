//엑셀파일 만들기
import { getCurrentDateTimeForFileName } from "components/CommonLib/CommonLib";
import { utils, writeFile } from "xlsx";

export const exportToExcel = (option, data) => {
  const newData = [];
  if (!option) {
    for (let i = 0; i < data.length; i++) {
      const row = {};
      row.지점 = data[i].base_result.branch_info.branch_name;
      row.구분 = data[i].base_result.member_type;
      row.회원번호 = data[i].base_result.member_info.member_no;
      row.이름 = data[i].base_result.member_info.member_name;
      row.연락처 = data[i].base_result.member_info.phone;
      row.생년월일 = data[i].base_result.member_info.birth;
      row.성별 = data[i].base_result.member_info.gender;
      row.최근_문자인증_일시 = getLastAuthDate(data[i].base_result.auth_hist);
      row.기간_내_인증_수 = getAuthCount(data[i].base_result.auth_hist);
      row.UUID = getLastUUID(data[i].base_result.auth_hist);
      row.마케팅_수신_동의 = data[i].base_result.agree_marketing ? "동의" : "미동의";
      newData.push(row);
    }
  } else if (option === "member") {
    //
    for (let i = 0; i < data.length; i++) {
      const row = {};
      row.지점 = data[i].base_result.branch_info.branch_name;
      row.구분 = data[i].base_result.member_type;
      row.회원번호 = data[i].base_result.member_info.member_no;
      row.이름 = data[i].base_result.member_info.member_name;
      row.연락처 = data[i].base_result.member_info.phone;
      row.생년월일 = data[i].base_result.member_info.birth;
      row.성별 = data[i].base_result.member_info.gender;
      row.회원권_상태 = data[i].base_result.active_mbshp_status;
      row.최종_회원권_종료일 = data[i].base_result.last_mbshp_to_date;
      row.PT_수강_상태 = data[i].base_result.active_pt_status;
      row.PT_총_잔여횟수 = data[i].base_result.remain_pt_cnt;
      row.마케팅_수신_동의 = data[i].base_result.agree_marketing ? "동의" : "미동의";
      newData.push(row);
    }
  } else if (option === "mbshp") {
    for (let i = 0; i < data.length; i++) {
      const row = {};
      row.지점 = data[i].base_result.branch_info.branch_name;
      row.구분 = data[i].base_result.member_type;
      row.회원번호 = data[i].base_result.member_info.member_no;
      row.이름 = data[i].base_result.member_info.member_name;
      row.연락처 = data[i].base_result.member_info.phone;
      row.생년월일 = data[i].base_result.member_info.birth;
      row.성별 = data[i].base_result.member_info.gender;
      row.PT_수강상태 = data[i].base_result.active_pt_status;
      row.PT_총_잔여횟수 = data[i].base_result.remain_pt_cnt;
      row.최종_회원권_종료일 = data[i].base_result.last_mbshp_to_date;
      row.회원권_종류 = data[i].sub_result.active_mbshp.mbshp_type;
      row.회원권_옵션 = data[i].sub_result.active_mbshp.mbshp_option;
      row.회원권_상품명 = data[i].sub_result.active_mbshp.item_name;
      row.회원권_상태 = data[i].sub_result.active_mbshp.mbshp_stauts; // 서버에서의 오타
      row.회원권_구매일 = data[i].sub_result.active_mbshp.ot_info.ot_info.mbshp_paid_date;
      row.유효기간_시작일 = data[i].sub_result.active_mbshp.from_date;
      row.유효기간_종료일 = data[i].sub_result.active_mbshp.to_date;
      row.만료사유 = data[i].sub_result.active_mbshp.expiration_info.reason;
      row.운동복 = data[i].sub_result.active_mbshp.is_use_cloth;
      row.GX = data[i].sub_result.active_mbshp.is_use_gx;
      row.락커 = data[i].sub_result.active_mbshp.is_use_locker;
      row.회원권_결제금액 = data[i].sub_result.active_mbshp.mbshp_price.paid_price;
      row.운동복_결제금액 = data[i].sub_result.active_mbshp.cloth_price.paid_price;
      row.GX_결제금액 = data[i].sub_result.active_mbshp.gx_price.paid_price;
      row.락커_결제금액 = data[i].sub_result.active_mbshp.locker_price.paid_price;
      row.OT_수업_담당자 = getCurManagerName(data[i]);
      row.OT_잔여_횟수 = data[i].sub_result.active_mbshp.ot_info.ot_info.remain_session_cnt;
      row.마케팅_수신_동의 = data[i].base_result.agree_marketing ? "동의" : "미동의";
      newData.push(row);
    }
  } else if (option === "pt") {
    for (let i = 0; i < data.length; i++) {
      const row = {};
      row.지점 = data[i].base_result.branch_info.branch_name;
      row.구분 = data[i].base_result.member_type;
      row.회원번호 = data[i].base_result.member_info.member_no;
      row.이름 = data[i].base_result.member_info.member_name;
      row.연락처 = data[i].base_result.member_info.phone;
      row.생년월일 = data[i].base_result.member_info.birth;
      row.성별 = data[i].base_result.member_info.gender;

      row.회원권_상태 = data[i].base_result.active_mbshp_status;
      row.회원권_전체_잔여일 = "";
      row.총_잔여_세션 = data[i].base_result.remain_pt_cnt;
      row.PT_상품명 = data[i].sub_result.item_name;
      row.PT_상태 = data[i].sub_result.root_status;
      row.수업_담당자 = data[i].sub_result.cur_manager.staff_info.staff_name;
      row.등록_횟수 = data[i].sub_result.session_cnt;
      row.잔여_횟수 = data[i].sub_result.remain_session_cnt;
      row.마케팅_수신_동의 = data[i].base_result.agree_marketing ? "동의" : "미동의";
      newData.push(row);
    }
  } else if (option === "side" || option === "locker") {
    for (let i = 0; i < data.length; i++) {
      const row = {};
      row.지점 = data[i].base_result.branch_info.branch_name;
      row.구분 = data[i].base_result.member_type;
      row.회원번호 = data[i].base_result.member_info.member_no;
      row.이름 = data[i].base_result.member_info.member_name;
      row.연락처 = data[i].base_result.member_info.phone;
      row.생년월일 = data[i].base_result.member_info.birth;
      row.성별 = data[i].base_result.member_info.gender;
      row.최종_회원권_종료일 = data[i].base_result.last_mbshp_to_date;
      row.회원권_구매코드 = data[i].sub_result.parent_purchase_code;
      row.부가상품_상태 = "";
      row.시작일 = data[i].sub_result.from_date;
      row.종료일 = data[i].sub_result.to_date;
      row.마케팅_수신_동의 = data[i].base_result.agree_marketing ? "동의" : "미동의";
      newData.push(row);
    }
  }

  const worksheet = utils.json_to_sheet(newData);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, "Sheet1");

  const fileName = getCurrentDateTimeForFileName() + "_회원조회.xlsx";
  writeFile(workbook, fileName);
};

const getCurManagerName = (data) => {
  if (!data.sub_result.active_mbshp.ot_info.cur_manager.staff_info.staff_name) {
    return "미배정";
  } else {
    return data.sub_result.active_mbshp.ot_info.cur_manager.staff_info.staff_name;
  }
};

const getLastAuthDate = (auth_hist) => {
  if (!auth_hist || auth_hist.length === 0) return "";
  return auth_hist[auth_hist.length - 1].auth_at;
};

const getLastUUID = (auth_hist) => {
  if (!auth_hist || auth_hist.length === 0) return "";
  const fullUUID = auth_hist[auth_hist.length - 1].uuid;
  return fullUUID.slice(-12); // 마지막 12자리만 반환
};

const getAuthCount = (auth_hist) => {
  if (!auth_hist) return 0;
  return auth_hist.length;
};
