import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadPostponeViewStore } from "./Module/actions";
import PostponeWrapper from "./PostponeWrapper";

const PostponeService = ({ modalParam }) => {
  const storesDispatch = useDispatch();
  const viewState = useSelector((state) => state.member_service.postpone_view_info);

  //연기화면 전체적으로 GET
  useEffect(() => {
    storesDispatch(
      loadPostponeViewStore(modalParam.memberId, modalParam.branchId, modalParam.membershipId),
    );
  }, [modalParam.memberId, modalParam.branchId, modalParam.membershipId]);

  return (
    <PostponeWrapper
      viewState={viewState.stores.data}
      memberId={modalParam.memberId}
      membershipId={modalParam.membershipId}
      branchId={modalParam.branchId}
      mbshpType={modalParam.mbshp_type}
    />
  );
};
export default PostponeService;
