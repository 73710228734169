import { checkDateOrder, getDefaultValue } from "components/CommonLib/CommonLib";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import { useEffect, useState } from "react";
import { SearchConditionBox, SearchContainer, SearchRowBox } from "./style";

const SearchCondition = ({
  searchParams,
  setSearchParams,
  resetFlag,
  branchList,
  onClickSearch,
}) => {
  // 페이지 상태 관리
  const labelWidth = "4.5rem";
  const textBoxWidth = "15rem";
  const textBoxWidthShort = "8.5rem";
  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);

  // 초기화 시 지점 첫번째 값 세팅
  useEffect(() => {
    if (!isFirstEnterPage && branchList.branch_list.length > 0) {
      setSearchParams("branch_info", branchList.branch_list[0]);
    }
  }, [resetFlag]);

  // 첫 페이지 여부 체크
  useEffect(() => {
    setIsFirstEnterPage(false);
  }, []);

  return (
    <SearchContainer>
      <Label labelText="검색 조건" fontWeight="bold" justifyContent="flex-start" />
      <SearchRowBox
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onClickSearch(1);
          }
        }}
      >
        <SearchConditionBox width="23.5%">
          <SearchComboBox
            labelText="지점"
            labelWidth={labelWidth}
            labelMarginRight="0rem"
            comboItemWidth={textBoxWidth}
            data={branchList.branch_name_list}
            defaultValue={getDefaultValue("combo", searchParams.branch_info.branch_name)}
            onChangeCallback={(e, v, idx) => {
              setSearchParams("branch_info", branchList.branch_list[idx]);
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox width="26.5%">
          <DatePicker
            labelText="등록일"
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidthShort}
            labelMarginRight="0rem"
            textMarginRight="0rem"
            defaultValue={getDefaultValue("field", searchParams.reg_from_date)}
            onChangeCallback={(e) => {
              setSearchParams("reg_from_date", e);
            }}
            onBlur={(e) => {
              if (!checkDateOrder(e, searchParams.reg_to_date)) {
                setSearchParams("reg_from_date", "");
              }
            }}
          />
          <DatePicker
            marginLeft="0.12rem"
            labelWidth="0.5rem"
            textBoxWidth={textBoxWidthShort}
            textMarginRight="0rem"
            labelMarginRight="0.12rem"
            labelText="~"
            defaultValue={getDefaultValue("field", searchParams.reg_to_date)}
            onChangeCallback={(e) => {
              setSearchParams("reg_to_date", e);
            }}
            onBlur={(e) => {
              if (!checkDateOrder(searchParams.reg_from_date, e)) {
                setSearchParams("reg_to_date", "");
              }
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox width="48%">
          <TextField
            labelText="조건명"
            labelWidth={labelWidth}
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            defaultValue={getDefaultValue("field", searchParams.title)}
            onChangeCallback={(e) => {
              setSearchParams("title", e);
            }}
          />
        </SearchConditionBox>
      </SearchRowBox>
    </SearchContainer>
  );
};
export default SearchCondition;
