import { HttpInstance } from "lib/HttpLib";

export const getAccessLogSearch = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/access/search_access_history", {
    params: {
      search_param: param.search_param,
    },
  });
};

export const getDoorLogSearch = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/access/search_door_history", {
    params: {
      search_param: param.search_param,
    },
  });
};

const get_api = {
  getDoorLogSearch,
  getAccessLogSearch,
};

export default get_api;
