// 초기화
// const initialState = {
//   titleText: "",
//   bodyText: "",
//   price: 0,
// };
const initialState = [];

// 리듀서 작성
export const change_new_item_state = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_ITEM/NEW_ITEM/PUSH": {
      let array = [...state];
      const index = array.map((item) => item.type_key).indexOf(action.payload.type_key);
      if (index !== -1) {
        array[index] = action.payload;
      } else {
        array.push(action.payload);
        array.sort((a, b) => {
          if (a.orderIdx < b.orderIdx) return -1;
          if (a.orderIdx > b.orderIdx) return 1;

          return 0;
        });
      }

      return [...array];
    }
    case "CHANGE_ITEM/NEW_ITEM/POP": {
      let array = [...state];
      const index = array.map((item) => item.type_key).indexOf(action.type_key);

      if (index !== -1) {
        array.splice(index, 1);
      }

      return [...array];
    }
    case "CHANGE_ITEM/NEW_ITEM/EDIT": {
      let array = [...state];
      const index = array.map((item) => item.type_key).indexOf(action.payload.type_key);
      // console.log(array);
      // console.log(action);
      // console.log(index);
      if (index !== -1) {
        // console.log(array[index]);
        // console.log(action.payload);
        array[index] = action.payload;
      } else {
        array.push(action.payload);
        array.sort((a, b) => {
          if (a.orderIdx < b.orderIdx) return -1;
          if (a.orderIdx > b.orderIdx) return 1;

          return 0;
        });
      }

      return [...array];
    }

    case "CHANGE_ITEM/NEW_ITEM/CLEAR":
      return [];
    default:
      return state;
  }
};
