import { contentTypeJson, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import SearchMember from "pages/CommonModal/Search/SearchMember";
import LoadConditionModal from "pages/ERP/MemberService/Search/SearchMember/Modal/LoadConditionModal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LoadMsgContentModal from "../Modal/LoadMsgContentModal";
import { msg_state } from "../Module/reducers/msg_state";
import ReceiverList from "./ReceiverList";
import SendMessageComp from "./SendMessageComp";

const SendMessageView = () => {
  const storesDispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [messageState, setMessageState] = useState(_.cloneDeep(msg_state));
  const [msgType, setMsgType] = useState("SMS");
  const [file, setFile] = useState();
  // const [curPage, setCurPage] = useState(); // pagination
  // const [totalPage, setTotalPage] = useState(1);

  const [availSendMsg, setAvailSendMsg] = useState({ SMS_CNT: 0, LMS_CNT: 0, MMS_CNT: 0 });

  useEffect(() => {
    getSmsStatus();
  }, []);

  const getSmsStatus = () => {
    HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/cs/sms/current/status", {
      params: {
        sms_id: "",
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setAvailSendMsg(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        // simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR : sms_id가 없습니다");
      });
  };

  const onModalDone = (type, data) => {
    if (type === "load") {
      loadCondition(1, data);
    } else if (type === "loadContent") {
      setMessageState(data);
    }
    storesDispatch(globalModalOff());
  };

  const onSelectedMember = (selectedList) => {
    let duplicatedCnt = 0;

    for (let i = 0; i < selectedList.length; i++) {
      if (
        searchResult.findIndex(
          (x) => x.member_info.member_id === selectedList[i].member_info.member_id,
        ) === -1
      ) {
        searchResult.push(selectedList[i]);
      } else {
        duplicatedCnt++;
      }
    }
    setSearchResult([...searchResult]);

    if (duplicatedCnt > 0) {
      simpleAlert(
        storesDispatch,
        "알림",
        duplicatedCnt.toString() + "건의 중복 데이터를 제외했습니다.",
        true,
        () => {
          storesDispatch(globalModalOff());
        },
      );
    } else {
      storesDispatch(globalModalOff());
    }
  };

  const openModal = (order, param) => {
    let Modal;
    if (order === "load") {
      Modal = <LoadConditionModal modalParam={param} onModalDone={onModalDone} />;
    } else if (order === "loadContent") {
      Modal = <LoadMsgContentModal modalParam={param} onModalDone={onModalDone} />;
    } else if (order === "member") {
      Modal = <SearchMember modalParam={param} onModalDone={onSelectedMember} />;
    }

    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,
        Content: Modal,
      }),
    );
  };

  const onUpdateSearchResult = (data) => {
    const list = [];
    for (let i = 0; i < data.length; i++) {
      list.push({
        branch_info: data[i].base_result.branch_info,
        member_info: {
          member_id: data[i].base_result.member_info.member_id,
          member_name: data[i].base_result.member_info.member_name,
          member_no: data[i].base_result.member_info.member_no,
        },
        member_type: data[i].base_result.member_type,
        phone: data[i].base_result.member_info.phone,
      });
    }

    setSearchResult(list);
  };

  const loadCondition = (page, presetId) => {
    // setCurPage(page);

    // 여기선 preset id로 불러와야 함

    HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member/search/preset/data", {
      params: {
        preset_id: presetId,
      },
    })
      .then((res) => {
        // console.log(res);

        let detailParam = {};
        if (res.data.data.condition_type === "member") {
          detailParam = res.data.data.search_param.member_param;
        } else if (res.data.data.condition_type === "mbshp") {
          detailParam = res.data.data.search_param.mbshp_param;
        } else if (res.data.data.condition_type === "pt") {
          detailParam = res.data.data.search_param.pt_param;
        } else if (res.data.data.condition_type === "side") {
          detailParam = res.data.data.search_param.other_param;
        } else {
          detailParam = res.data.data.search_param.member_param;
        }

        searchByCondition(
          res.data.data.condition_type,
          res.data.data.search_param.base_param,
          detailParam,
        );
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const onSendDone = () => {
    getSmsStatus();
  };

  const searchByCondition = async (type, baseParam, detailParam) => {
    const formData = new FormData();

    setLoading(true);

    const searchParam = {
      base_search: baseParam,
      sub_search: detailParam,
    };

    // console.log(searchParam);

    formData.append("is_all", true);
    formData.append("page_num", 1);
    formData.append("search_param", JSON.stringify(searchParam));

    if (type === "member") {
      formData.append("is_member_detail", true);
    }

    const postApi =
      type === "member"
        ? process.env.REACT_APP_USER_SERVER + "/member/search/member"
        : type === "mbshp"
        ? process.env.REACT_APP_USER_SERVER + "/member/search/mbshp"
        : type === "pt"
        ? process.env.REACT_APP_USER_SERVER + "/member/search/pt"
        : type === "side" || type === "locker"
        ? process.env.REACT_APP_USER_SERVER + "/member/search/other"
        : process.env.REACT_APP_USER_SERVER + "/member/search/member";

    await HttpInstance.post(postApi, formData, contentTypeJson)
      .then((res) => {
        // console.log(res);
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          // setTotalPage(res.data.total_page);
          console.log(res.data.data);
          onUpdateSearchResult(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data && err.response.data.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickSend = () => {
    if (!checkSendAvailable()) {
      simpleAlert(storesDispatch, "알림", "발송 가능량이 부족하여 발송할 수 없습니다.");
    } else if (!messageState.sms_info.sms_name) {
      simpleAlert(storesDispatch, "알림", "메시지 제목은 필수입니다.");
    } else if (!messageState.contents) {
      simpleAlert(storesDispatch, "알림", "메시지 내용이 없습니다.");
    } else if (searchResult.length === 0) {
      simpleAlert(storesDispatch, "알림", "메시지 수신자가 없습니다.");
    } else {
      simpleAlert(storesDispatch, "알림", "메시지를 발송하시겠습니까?", false, undefined, () => {
        onSendMsg();
      });
    }
  };

  const checkSendAvailable = () => {
    if (msgType === "SMS") {
      if (searchResult.length > availSendMsg.SMS_CNT) {
        return false;
      }
    } else if (msgType === "LMS") {
      if (searchResult.length > availSendMsg.LMS_CNT) {
        return false;
      }
    } else if (msgType === "MMS") {
      if (searchResult.length > availSendMsg.MMS_CNT) {
        return false;
      }
    }

    return true;
  };

  const onSendMsg = async () => {
    const formData = new FormData();

    formData.append("member_list", JSON.stringify(searchResult));
    formData.append("new_info", JSON.stringify(messageState));
    formData.append("the_file", file);

    // console.log(searchResult);
    // console.log(messageState);
    setLoading(true);

    await HttpInstance.post(
      process.env.REACT_APP_USER_SERVER + "/cs/sms/send/bulk",
      formData,
      contentTypeJson,
    )
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          console.log(res);
          simpleAlert(storesDispatch, "알림", "발송이 완료되었습니다.");
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data && err.response.data.msg);
      })
      .finally(() => {
        setLoading(false);
        onSendDone();
      });
  };

  const onClickReset = () => {
    setMessageState(_.cloneDeep(msg_state));
  };

  const onClickSaveContent = () => {
    simpleAlert(storesDispatch, "알림", "메시지 내용을 저장하시겠습니까?", false, undefined, () => {
      onSaveContent();
    });
  };

  const onSaveContent = async () => {
    const formData = new FormData();
    formData.append("new_info", JSON.stringify(messageState));

    setLoading(true);

    await HttpInstance.post(
      process.env.REACT_APP_USER_SERVER + "/cs/sms/template/add",
      formData,
      contentTypeJson,
    )
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          // console.log(res.data.data);
          simpleAlert(storesDispatch, "알림", "저장되었습니다.");
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data && err.response.data.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ display: "flex" }}>
      {loading ? <Loading /> : <></>}
      <div style={{ width: "35%", paddingRight: "0.5rem" }}>
        <ReceiverList
          openModal={openModal}
          searchResult={searchResult}
          setSearchResult={setSearchResult}
          availSendMsg={availSendMsg}
          messageState={messageState}
          setMessageState={setMessageState}
          // curPage={curPage}
          // totalPage={totalPage}
          // loadCondition={loadCondition}
        />
      </div>
      <div style={{ width: "65%", paddingLeft: "0.5rem" }}>
        <SendMessageComp
          openModal={openModal}
          onClickSend={onClickSend}
          onClickReset={onClickReset}
          messageState={messageState}
          setMessageState={setMessageState}
          onClickSaveContent={onClickSaveContent}
          onSendDone={onSendDone}
          msgType={msgType}
          setMsgType={setMsgType}
          file={file}
          setFile={setFile}
        />
      </div>
    </div>
  );
};
export default SendMessageView;
