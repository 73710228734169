import {
  class_history_state,
  cs_history_state,
  membership_history_state,
  purchase_history_state,
} from "pages/ERP/MemberView/Module/reducers/history_reducer";
import { combineReducers } from "redux";

export const memberHistoryReducer = combineReducers({
  membership_history_state,
  class_history_state,
  purchase_history_state,
  cs_history_state,
});
