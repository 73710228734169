import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { globalModalOff } from "modules/actions/Modal";
import { loadMemberViewStore, loadPurchaseHistoryStore } from "pages/ERP/MemberView/Module/actions";
import { loadUnPayViewStore } from "./Module/actions";
import UnPaidProcessWrapper from "./UnPaidProcessWrapper";

const UnPaidProcessModal = ({ modalParam }) => {
  const storesDispatch = useDispatch();

  const viewState = useSelector((state) => state.member_service.unpay_view_info);

  useEffect(() => {
    storesDispatch(loadUnPayViewStore(modalParam.member_id));
  }, [modalParam.member_id]);

  const refreshStore = () => {
    storesDispatch(loadPurchaseHistoryStore(modalParam.member_id)); //결제탭 갱신
    storesDispatch(loadMemberViewStore(modalParam.member_id)); //회원정보 갱신
    storesDispatch(globalModalOff());
  };

  return (
    <UnPaidProcessWrapper
      viewState={viewState.stores.data}
      refreshStore={refreshStore}
      branchId={modalParam.branch_id}
    />
  );
};
export default UnPaidProcessModal;
