export const ptSearchParamInit = {
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  manager_info: {
    is_resigned: false,
    staff_info: {
      staff_id: "",
      staff_name: "",
      staff_pos: "",
      staff_rank: "",
    },
  },
  member_base: {
    member_name: "",
    member_no: "",
    phone: "",
  },
  pt_info: {
    class_status: "",
    is_assigned: "",
    join_type: "",
    remain_cnt_filter: false,
    root_status: ["수강", "대기", "만료"],
  },
  staff_text_name: "",
  view_info: {
    branch_list: [
      {
        branch_id: "",
        branch_name: "",
      },
    ],
    branch_name_list: [],
  },
};

export const ptDetailInit = {
  alloc_date: "",
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  coupon_info: {
    base_info: {
      benefit_product: "",
      benefit_type: "",
    },
    coupon_id: "",
    detail: {
      coupon_name: "",
      price_benefit: {
        dis_price: 0,
        dis_rate: 0,
        type: "",
      },
      service_benefit: {
        pt: {
          add_session: 0,
        },
      },
    },
    final_dis: 0,
    ticket_id: "",
  },
  cs_staff: {
    staff_id: "",
    staff_name: "",
    staff_pos: "",
    staff_rank: "",
  },
  cur_manager: {
    assign_date: "",
    is_assigned: false,
    is_resigned: false,
    staff_info: {
      staff_id: "",
      staff_name: "",
      staff_pos: "",
      staff_rank: "",
    },
  },
  hq_info: {
    hq_id: "",
    hq_name: "",
  },
  membership_info: {
    birth: "",
    birth_info: {
      day: 0,
      month: 0,
      year: 0,
    },
    from_date: "",
    gender: "",
    is_new: false,
    item_name: "",
    mbshp_option: "",
    mbshp_type: "",
    member_id: "",
    member_name: "",
    member_no: "",
    membership_id: "",
    phone: "",
    to_date: "",
  },
  pt_class: [
    {
      class_id: "",
      fix_payout_rate: false,
      memo: "",
      no: 0,
      payout_price: 0,
      proceed_class: {
        class_done_date: "",
        class_done_time: "",
        class_status: "",
        comment: "",
      },
      session_price: 0,
      session_type: "",
      staff_info: {
        staff_id: "",
        staff_name: "",
        staff_pos: "",
        staff_rank: "",
      },
      view_info: {
        is_fix_payout_rate_lock: false,
      },
    },
  ],
  pt_detail_id: "",
  pt_info: {
    base_session_cnt: 0,
    class_status: "",
    comment: "",
    item_name: "",
    join_type: "",
    latest_class_date: "",
    latest_contact: "",
    latest_reserv_date: "",
    proceed_class_lock: false,
    pt_cancel_cnt: 0,
    pt_id: "",
    pt_postpone_cnt: 0,
    pt_used_cnt: 0,
    purchase_date: "",
    remain_session_cnt: 0,
    remarks: "",
    root_status: "",
    service_session_cnt: 0,
    service_status: "",
    session_cnt: 0,
  },
  pt_price: {
    paid_price: 0,
    paid_price_tax: 0,
    per_price: 0,
    per_price_tax: 0,
    refund_per_price: 0,
    total_price: 0,
    total_price_tax: 0,
  },
  raw_price: 0,
  reg_info: {
    created_at: "",
    reg_id: "",
    reg_name: "",
    upd_id: "",
    upd_name: "",
    updated_at: "",
  },
  service_session_hist: [
    {
      add_session: 0,
      comment: "",
      reg_info: {
        created_at: "",
        reg_id: "",
        reg_name: "",
        upd_id: "",
        upd_name: "",
        updated_at: "",
      },
    },
  ],
  view_info: {
    selected: false,
  },
};
