import {
  paymentInfoPush,
  paymentInfoPop,
  paymentInfoClear,
  paymentInfoAddedPaidClear,
  paymentInfoEdit,
} from "./payment_action";

import {
  subsPayInfoPush,
  subsPayInfoPop,
  subsPayInfoClear,
  subsPayInfoEdit,
  subsPayInfoList,
} from "./subs_payment_action";

export const payListActions = {
  paymentInfoPush,
  paymentInfoPop,
  paymentInfoClear,
  paymentInfoAddedPaidClear,
  paymentInfoEdit,
};

export const subsPayListActions = {
  subsPayInfoPush,
  subsPayInfoPop,
  subsPayInfoClear,
  subsPayInfoEdit,
  subsPayInfoList,
};
