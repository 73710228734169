import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { listEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import CheckBox from "components/LabelInput/CheckBox";
import Label from "components/LabelInput/Label";
import SubTitle from "components/PageTitle/SubTitle";

const ActivatePayList = ({ serviceViewState, onSelectPayment, selectedPCode }) => {
  const activePayList = new activePayTable(
    onSelectPayment,
    selectedPCode,
    serviceViewState.branch_info && serviceViewState.branch_info.branch_id,
  );

  return (
    <div
      style={{
        marginTop: "1.5rem",
      }}
    >
      <div style={{ display: "flex", gap: "1rem" }}>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <SubTitle titleText={"유효 결제 목록"} />
        </div>
        <Label
          labelText="※ 동일한 구매코드 내에서만 결제수단 선택이 가능합니다."
          color="red"
          fontSize="0.7rem"
        />
      </div>

      <CustomTable
        columns_head={activePayList.columns_head}
        table_title={activePayList.table_title}
        rest_call={activePayList.get_data_from_rest}
        row_render={activePayList.create_table}
        rest_data={serviceViewState.payment_list}
      />
    </div>
  );
};
export default ActivatePayList;

class activePayTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onSelectPayment = null;
  selectedPCode = null;
  curBranchId = null;

  constructor(onSelectPayment, selectedPCode, curBranchId) {
    this.onSelectPayment = onSelectPayment;
    this.selectedPCode = selectedPCode;
    this.curBranchId = curBranchId;
  }

  checkUnavailable = (data) => {
    if (this.selectedPCode && data.payment_data.purchase_code !== this.selectedPCode) {
      return true;
    }

    if (
      data.payment_data.branch_info &&
      data.payment_data.branch_info.branch_id !== this.curBranchId
    ) {
      return true;
    }

    return false;
  };

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>
          <CheckBox
            isTable
            labelText=""
            defaultValue={data.payment_list_selector.selected}
            onChangeCallback={(e) => {
              data.payment_list_selector.selected = e.checked;
              this.onSelectPayment(data);
            }}
            disabled={this.checkUnavailable(data)}
          />
        </CustomTableContents>
        <CustomTableContents>{data.payment_data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.payment_data.purchase_code}</CustomTableContents>
        <CustomTableContents>{data.payment_data.pay_date}</CustomTableContents>
        <CustomTableContents>{data.payment_data.pay_method}</CustomTableContents>
        <CustomTableContents>{data.payment_data.approve_no}</CustomTableContents>
        <CustomTableContents>
          <div style={{ fontWeight: data.payment_data.paid_by === "tablet" ? "bold" : "" }}>
            {data.payment_data.price.toLocaleString() + "원"}
          </div>
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "선택", width: "8%" },
    { title: "결제 지점", width: "16%" },
    { title: "구매코드", width: "16%" },
    { title: "결제 일시", width: "16%" },
    { title: "결제 수단", width: "13%" },
    { title: "승인번호", width: "16%" },
    { title: "결제 금액", width: "16%", tooltip: "Tablet에서 결제한 경우 Bold체로 표시됩니다" },
  ];
  get_data_from_rest = (stateData) => {
    if (listEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
