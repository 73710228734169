import { contentTypeJson, simpleAlert } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

export const downloadFile = async (storesDispatch, data) => {
  try {
    const obj = { file_name: data.file_info.file_name, url_path: data.file_info.url_path };
    const res = await HttpInstance.post(
      process.env.REACT_APP_AUTH_SERVER + "/file/download",
      obj,
      contentTypeJson,
    );
    if (res.data) {
      //성공
      const downloadLink = res.data.data;
      const a = document.createElement("a");
      a.href = downloadLink;
      a.download = data.file_name;
      document.body.appendChild(a);
      a.click();
      setTimeout((_) => {
        window.URL.revokeObjectURL(downloadLink);
      }, 60000);
      a.remove();
    }
  } catch (err) {
    console.log(err);
    simpleAlert(storesDispatch, "알림", "파일을 다운로드할 수 없습니다.");
  }
};
