import { create } from "zustand";

// 검색 조건 베이스
const baseSearchParams = {
  base_from_date: "",
  base_to_date: "",
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  combo_selector: {
    branch_list: [[""]],
    hq_list: [""],
    target_list: [
      {
        branch_list: [
          {
            branch_id: "",
            branch_name: "",
          },
        ],
        hq_info: {
          hq_id: "",
          hq_name: "",
        },
      },
    ],
  },
  hq_info: {
    hq_id: "",
    hq_name: "",
  },
};

export const useDailyReportStore = create((set) => ({
  // ========== State ===========
  // 운영일지 검색 State
  dailyReportSearchParams: {
    ...baseSearchParams,
  },

  dailyReportHqIdx: 0,

  dailyReportPage: 1,

  // ========== Action ==========
  // 운영일지 검색 조건 변경
  setSearchParams: (key, value) => {
    set((state) => ({
      dailyReportSearchParams: {
        ...state.dailyReportSearchParams,
        [key]: value,
      },
    }));
  },
  // Hq Index 값 변경
  setHqIdx: (value) => {
    set({ dailyReportHqIdx: value });
  },

  // Page 값 변경
  setPage: (value) => {
    set({ dailyReportPage: value });
  },
  // 검색 조건 리셋
  resetSearchParams: () => {
    set({
      dailyReportSearchParams: {
        ...baseSearchParams,
      },
    });
    set({ dailyReportHqIdx: 0 });
  },
}));
