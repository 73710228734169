import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
// import { getLockerSettingStores } from "../../../modules/actions/LockerSetting/index";
import IconButton from "components/Button/IconButton";
import CardFixed from "components/Card/CardFixed";
import { contentTypeJson, dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import { HttpInstance } from "../../../../lib/HttpLib";

const LockerSetting = ({
  selectedBranch,
  getBranchStatusInfo,
  getFloorZoneCombo,
  onClickOpenModal,
}) => {
  const storesDispatch = useDispatch();

  const [floorZoneList, setFloorZoneList] = useState([]);
  const [loading, setLoading] = useState(false);
  const zoneList = useRef([]);

  //섹션3 층/구역 정보 GET
  const getFloorZoneData = async () => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_BRANCH_SERVER + "/locker/zone/setting_list",
        {
          params: {
            branch_id: selectedBranch.branch_id,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        const result = res.data.data;
        const zoneArr = result.map((el) => el.base_info.name);
        zoneList.current = zoneArr;
        setFloorZoneList(result);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  //테이블ROW 삭제 POST
  const deleteFloorZoneRow = async (data) => {
    try {
      const formData = new FormData();
      formData.append("branch_info", JSON.stringify(selectedBranch));
      formData.append("floor_id", JSON.stringify(data.floor_id));
      formData.append("zone_id", JSON.stringify(data.base_info.zone_id));
      formData.append("view_id", JSON.stringify(data.view_id));

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_BRANCH_SERVER + "/locker/remove/zone",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label labelText="삭제되었습니다." />
              </>
            ),
            onBtnOk: () => {
              getFloorZoneData(); //섹션3 갱신
              getBranchStatusInfo(selectedBranch.branch_id); //섹션1 현황갱신
              getFloorZoneCombo(selectedBranch.branch_id); //섹션2 콤보갱신
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  //테이블 row삭제 알림
  const onRemoveSector = async (data) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText={`다음 구역을 삭제하시겠습니까?`} />
            <Label labelText={`구역명 : ${data.base_info.name}`} />
          </>
        ),

        onBtnNo: () => {},
        onBtnYes: () => {
          deleteFloorZoneRow(data);
        },
      }),
    );
  };

  //지점 바뀌면 섹션3 갱신
  useEffect(() => {
    if (selectedBranch.branch_name) {
      getFloorZoneData();
    }
  }, [selectedBranch.branch_id]);

  //1.구역추가 모달 POP
  const popSetLockerModal = () => {
    if (!selectedBranch.branch_name) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="지점을 설정해주세요." />
            </>
          ),

          onBtnOk: () => {},
        }),
      );
    } else {
      const param = {
        title: "구역 추가",
        selectedBranch,
      };
      onClickOpenModal("구역추가", param, lockerSetModalDone);
    }
  };
  //1-1.구역추가 모달 DONE
  const lockerSetModalDone = () => {
    getBranchStatusInfo(selectedBranch.branch_id); //섹션1 갱신
    getFloorZoneCombo(selectedBranch.branch_id); //섹션2 콤보갱신
    getFloorZoneData(); //섹션3 갱신
    storesDispatch(globalModalOff()); //모달닫기
  };

  //2.층추가 모달 POP
  const popAddFloorModal = () => {
    if (!selectedBranch.branch_name) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="지점을 설정해주세요." />
            </>
          ),

          onBtnOk: () => {},
        }),
      );
    } else {
      const param = {
        title: "층 추가",
        selectedBranch,
      };
      onClickOpenModal("층추가", param, addFloorModalDone);
    }
  };

  //2-1.층추가 모달 DONE
  const addFloorModalDone = () => {
    getFloorZoneCombo(selectedBranch.branch_id); //섹션2 콤보갱신
  };

  //3.구역명수정 모달 POP
  const popEditZoneModal = (data) => {
    const param = {
      title: "구역수정",
      name: data.base_info.name,
      floor_id: data.floor_id,
      zone_id: data.base_info.zone_id,
      branch_id: selectedBranch.branch_id,
      zoneList: zoneList.current,
    };
    onClickOpenModal("구역명수정", param, editZoneModalDone);
  };

  //3-1. 구역명수정 모달 DONE
  const editZoneModalDone = () => {
    storesDispatch(globalModalOff()); //모달 닫기
    getFloorZoneData(); //섹션3 갱신
    getFloorZoneCombo(selectedBranch.branch_id); //섹션2 콤보갱신
  };

  const currentLockerSettingTable = new lockerSectorTable(popEditZoneModal, onRemoveSector);

  return (
    <div>
      <CardFixed
        topDom={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {loading && <Loading />}

            <div>락커구역 설정</div>

            <div
              style={{
                display: "flex",

                gap: "0.3rem",
              }}
            >
              <NormalBtn
                name="층 추가"
                theme="white"
                onClick={() => {
                  popAddFloorModal();
                }}
              />
              <NormalBtn
                name="     구역 추가"
                theme="white"
                onClick={() => {
                  popSetLockerModal();
                }}
              />
            </div>
          </div>
        }
        midDom={
          <div style={{ marginTop: "1.5rem" }}>
            <CustomTable
              columns_head={currentLockerSettingTable.columns_head}
              table_title={currentLockerSettingTable.table_title}
              rest_call={currentLockerSettingTable.get_data_from_rest}
              row_render={currentLockerSettingTable.create_table}
              rest_data={floorZoneList}
            />
          </div>
        }
        isLoading={false}
        rootStyle={{
          width: "105rem",
          borderRadius: "0.625rem",
          backgroundColor: "#fff",
        }}
        titleHeight={"3rem"}
      />
    </div>
  );
};
export default LockerSetting;

class lockerSectorTable {
  table_title = "";
  popEditZoneModal = null;
  onRemoveSector = null;

  constructor(popEditZoneModal, onRemoveSector) {
    this.popEditZoneModal = popEditZoneModal;
    this.onRemoveSector = onRemoveSector;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.floor_name}</CustomTableContents>
        <CustomTableContents>{data.base_info.name}</CustomTableContents>
        <CustomTableContents>{data.base_info.gender}</CustomTableContents>
        <CustomTableContents>{data.base_info.total}</CustomTableContents>
        <CustomTableContents>{data.base_info.height}</CustomTableContents>
        <CustomTableContents>{data.base_info.width}</CustomTableContents>
        <CustomTableContents>{data.base_info.start_no}</CustomTableContents>
        <CustomTableContents>{data.base_info.end_no}</CustomTableContents>
        <CustomTableContents>
          <div style={{ display: "flex", gap: "1rem", justifyContent: "center" }}>
            <IconButton type="edit" onClick={() => this.popEditZoneModal(data)} />
            <IconButton type="delete" onClick={() => this.onRemoveSector(data, idx)} />
          </div>
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "층", width: "15%" },
    { title: "구역명", width: "15%" },
    { title: "구역 성별", width: "9%" },
    { title: "락커 갯수", width: "11%" },
    { title: "단수", width: "11%" },
    { title: "열 →", width: "11%" },
    { title: "시작번호", width: "11%" },
    { title: "끝번호", width: "11%" },
    { title: "", width: "10%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
