export const getBranchBaseAction = "BRANCH/GET/BRANCH_BASE";
export const getBranchBaseSuccessAction = "BRANCH/GET/BRANCH_BASE/SUCCESS";
export const getBranchBaseFailAction = "BRANCH/GET/BRANCH_BASE/FAIL";
export const getBranchBaseResetAction = "BRANCH/GET/BRANCH_BASE/RESET";

export const getPolicyManageViewAction = "BRANCH/GET/POLICY_MANAGE";
export const getPolicyManageViewSuccessAction = "BRANCH/GET/POLICY_MANAGE/SUCCESS";
export const getPolicyManageViewFailAction = "BRANCH/GET/POLICY_MANAGE/FAIL";
export const getPolicyManageViewResetAction = "BRANCH/GET/POLICY_MANAGE/RESET";

export const getBranchItemsViewAction = "BRANCH/GET/ITEMS_MANAGE";
export const getBranchItemsSuccessAction = "BRANCH/GET/ITEMS_MANAGE/SUCCESS";
export const getBranchItemsFailAction = "BRANCH/GET/ITEMS_MANAGE/FAIL";
export const getBranchItemsResetAction = "BRANCH/GET/ITEMS_MANAGE/RESET";

////PrePay
export const getPrePaidInfoAction = "BRANCH/GET/PRE_PAID_INFO";
export const getPrePaidInfoSuccessAction = "BRANCH/GET/PRE_PAID_INFO/SUCCESS";
export const getPrePaidInfoFailAction = "BRANCH/GET/PRE_PAID_INFO/FAIL";
export const getPrePaidInfoResetAction = "BRANCH/GET/PRE_PAID_INFO/RESET";

export const getSubPaidInfoAction = "BRANCH/GET/SUB_PAID_INFO";
export const getSubPaidInfoSuccessAction = "BRANCH/GET/SUB_PAID_INFO/SUCCESS";
export const getSubPaidInfoFailAction = "BRANCH/GET/SUB_PAID_INFO/FAIL";
export const getSubPaidInfoResetAction = "BRANCH/GET/SUB_PAID_INFO/RESET";

export const getEtcInfoAction = "BRANCH/GET/ETC_INFO";
export const getEtcInfoSuccessAction = "BRANCH/GET/ETC_INFO/SUCCESS";
export const getEtcInfoFailAction = "BRANCH/GET/ETC_INFO/FAIL";
export const getEtcInfoResetAction = "BRANCH/GET/ETC_INFO/RESET";
