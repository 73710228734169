import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";

const ColorCheckbox = (props) => {
  const [value, setValue] = useState(props.defaultValue);
  const boxStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: props.width ? props.width : "2.8rem",
    height: props.height ? props.height : "1.7rem",
    backgroundColor: props.bgColor,
    borderRadius: "100px",
    padding: props.padding ? props.padding : "0.4rem 0.8rem ",
  };
  const checkerStyle = {
    width: props.width ? props.width : "2.25rem",
    height: props.height ? props.height : "2.25rem",
    transform: props.transform ? props.transform : "scale(0.9)",
    marginRight: props.marginRight ? props.marginRight : "0rem",
    marginLeft: props.ItemMarginLeft,
  };

  const labelStyle = {
    width: props.labelWidth ? props.labelWidth : "2.3rem",
    height: "2.25rem",
    marginRight: props.labelMarginRight ? props.labelMarginRight : "0rem",
    marginLeft: props.labelMarginLeft,
    fontFamily: "AppleSDGothicNeo",
    fontSize: props.fontSize ? props.fontSize : "0.938rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: props.labelTextColor ? props.labelTextColor : "#fff",
    alignItems: "center",
    display: "flex",
  };

  const onChangeValue = (event) => {
    if (props.onChangeCallback !== undefined) {
      props.onChangeCallback(event.target);
    }

    setValue(event.target.checked);
  };

  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);

  if (props.labelText) {
    return (
      <div style={boxStyle}>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: "#fff",
                  borderRadius: "50%",
                  "&.Mui-checked": {
                    color: "#fff",
                  },
                }}
                style={checkerStyle}
                onChange={(e) => {
                  onChangeValue(e);
                  if (props.onChange) {
                    props.onChange(e);
                  }
                }}
                inputRef={props.inputRef}
                checked={value}
                disabled={props.disabled || props.readonly}
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleOutlineIcon />}
              />
            }
            label={props.labelText ? <div style={labelStyle}>{props.labelText}</div> : undefined}
            // style={!props.labelText ? labelMarginStyle : {}}
            sx={{ marginRight: "0rem" }}
          />
        </FormControl>
      </div>
    );
  }
  return null;
};

ColorCheckbox.defaultProps = {
  textAlign: "left",
};

export default ColorCheckbox;
