export const report_info_state = {
  base_date: "",
  report_info_id: "",
  hq_info: {
    hq_id: "",
    hq_name: "",
  },
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  assign_change_history: [
    // {
    //   branch_transfer_price: 0,
    //   health_refund_price: 0,
    //   item_change_price: 0,
    //   member_info: {
    //     member_id: "",
    //     member_name: "",
    //     member_no: "",
    //   },
    //   pt_refund_price: 0,
    //   purchase_code: "",
    //   reason: "",
    //   reg_date: "",
    //   reg_info: {
    //     created_at: "",
    //     reg_id: "",
    //     reg_name: "",
    //     upd_id: "",
    //     upd_name: "",
    //     updated_at: "",
    //   },
    //   total_price: 0,
    // },
  ],
  branch_summary_info: [
    {
      mbshp_total_cnt: 0,
      mbshp_total_sales: 0,
      other_total_cnt: 0,
      other_total_sales: 0,
      pt_total_cnt: 0,
      pt_total_sales: 0,
      sales_total: 0,
      sales_type: "",
    },
  ],
  cs_summary_info: {
    call_cnt: 0,
    tm_cnt: 0,
    visit_cnt: 0,
  },
  other_branch_access_list: [
    // {
    //   branch_id: "",
    //   branch_name: "",
    //   enter_date: "",
    //   is_success: true,
    //   member_id: "",
    //   member_name: "",
    //   member_no: "",
    //   remarks: "",
    // },
  ],
  payment_history: [],
  refund_record_list: [
    // {
    //   member_info: {
    //     member_id: "",
    //     member_name: "",
    //     member_no: "",
    //   },
    //   product_list: [
    //     {
    //       dis: 0,
    //       paid_price: 0,
    //       price: 0,
    //       product: "",
    //       unpaid_price: 0,
    //     },
    //   ],
    //   purchase_code: "",
    //   reason: "",
    //   reg_date: "",
    //   reg_info: {
    //     created_at: "",
    //     reg_id: "",
    //     reg_name: "",
    //     upd_id: "",
    //     upd_name: "",
    //     updated_at: "",
    //   },
    //   total_discnt: 0,
    //   total_price: 0,
    // },
  ],
  sales_record_list: [
    // {
    //   member_info: {
    //     member_id: "",
    //     member_name: "",
    //     member_no: "",
    //   },
    //   product_list: [
    //     {
    //       dis: 0,
    //       paid_price: 0,
    //       price: 0,
    //       product: "",
    //       unpaid_price: 0,
    //     },
    //   ],
    //   purchase_code: "",
    //   reason: "",
    //   reg_date: "",
    //   reg_info: {
    //     created_at: "",
    //     reg_id: "",
    //     reg_name: "",
    //     upd_id: "",
    //     upd_name: "",
    //     updated_at: "",
    //   },
    //   total_discnt: 0,
    //   total_price: 0,
    // },
  ],
  total_vault_cash_price: 0,
  // vault_cash_list: [
  //   {
  //     note: "",
  //     price: 0,
  //     reg_info: {
  //       created_at: "",
  //       reg_id: "",
  //       reg_name: "",
  //       upd_id: "",
  //       upd_name: "",
  //       updated_at: "",
  //     },
  //     vault_type: "",
  //   },
  // ],
  work_note: {
    cs_note: "",
    facility_note: "",
    marketing_note: "",
    pt_note: "",
  },
};
