import CardFixed from "components/Card/CardFixed";
import { SubLine, simpleAlert } from "components/CommonLib/CommonLib";
import OrgEditTableCard from "components/EdiTable/OrgEditTableCard";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import ModalBtnModeGroup from "components/ModalBtnModeGroup/ModalBtnModeGroup";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadHqView, loadHqViewReset } from "../Module/actions/index";
import post_api from "../Module/api/PostApi";
import HQStaffList from "./HQStaffList";

const HQManagement = ({ onClickOpenModal, hqId, hqBaseData }) => {
  const storesDispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const restData = useSelector((state) => state.hq_set_state.hq_hq_set_state);

  useEffect(() => {
    if (hqId !== "") {
      storesDispatch(loadHqView(hqId));
    }
    return () => {
      storesDispatch(loadHqViewReset());
    };
  }, [loadHqView, storesDispatch, hqId]);

  // console.log("restData");
  // console.log(restData);

  const onClickSave = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="저장하시겠습니까?" />
          </>
        ),
        // width: "50rem",
        onBtnYes: () => {
          onSaveEvent();
        },
        onBtnNo: () => {},
      }),
    );
  };

  const onSaveEvent = async () => {
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    // console.log(JSON.stringify(restData.stores.data.contents));
    const formData = new FormData();
    formData.append("setting_info", JSON.stringify(restData.stores.data.contents));

    setLoading(true);

    await HttpInstance.post(post_api.updateHqApi(), formData, config)
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          storesDispatch(
            globalAlertOn({
              show: true,
              titleText: "알림",
              bodyText: (
                <>
                  <Label labelText="저장되었습니다." />
                </>
              ),
              // width: "50rem",
              onBtnOk: () => {
                storesDispatch(loadHqView(hqId));
              },
            }),
          );
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      {loading && <Loading />}

      <ModalBtnModeGroup mode={"edit"} onClickEvent={onClickSave} style={{ marginLeft: "auto" }} />
      <SubTitle titleText={"본부 조직 관리"} marginRight="8.325rem" />

      <div style={{ display: "flex" }}>
        <CardFixed
          midDom={
            <OrgEditTableCard
              titleName={"부서명"}
              dataList={restData.stores.data.contents.hq_set.dept_list}
            />
          }
          isLoading={false}
          rootStyle={{
            marginTop: "0.3rem",
            marginRight: "1rem",
            borderRadius: "10px",
            backgroundColor: "#fff",
            border: "1px solid #e8ebf2",
            background:
              "transparent linear-gradient(117deg, #e8ebf2 0%, #f2f3f7 100%) 0% 0% no-repeat padding-box",
          }}
        />

        <CardFixed
          midDom={
            <OrgEditTableCard
              titleName={"직위 목록"}
              dataList={restData.stores.data.contents.hq_set.rank_list}
            />
          }
          isLoading={false}
          rootStyle={{
            marginTop: "0.3rem",
            marginRight: "1rem",
            border: "1px solid #e8ebf2",
            background:
              "transparent linear-gradient(117deg, #e8ebf2 0%, #f2f3f7 100%) 0% 0% no-repeat padding-box",
          }}
        />

        <CardFixed
          midDom={
            <OrgEditTableCard
              titleName={"직책 목록"}
              dataList={restData.stores.data.contents.hq_set.position_list}
              isPosition
            />
          }
          isLoading={false}
          rootStyle={{
            marginTop: "0.3rem",
            border: "1px solid #e8ebf2",
            background:
              "transparent linear-gradient(117deg, #e8ebf2 0%, #f2f3f7 100%) 0% 0% no-repeat padding-box",
          }}
        />
      </div>
      <SubLine />

      <HQStaffList onClickOpenModal={onClickOpenModal} hqId={hqId} hqBaseData={hqBaseData} />
    </div>
  );
};
export default HQManagement;
