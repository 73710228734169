import { useEffect, useRef, useState } from "react";

import IconButton from "components/Button/IconButton";
import { contentTypeJson, dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import SearchMember from "pages/CommonModal/Search/SearchMember";
import { useDispatch } from "react-redux";
import FamilyDocViewModal from "./FamilyDocViewModal";

const FamilyListModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const viewModelRef = useRef();
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });
  const [familyList, setFamilyList] = useState([]);
  const [loading, setLoading] = useState(false);

  const searchMemberModal = (title, param, localModalDone) => {
    setModalInfo({
      show: true,
      title: title,
      Content: <SearchMember modalParam={param} onModalDone={localModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const viewDocModal = (title, param, localModalDone) => {
    setModalInfo({
      show: true,
      title: title,
      Content: <FamilyDocViewModal modalParam={param} onModalDone={localModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const localModalDone = () => {
    getFamilyList();
    viewModelRef.current.setClose();
  };

  const getFamilyList = async () => {
    try {
      setLoading(true);
      const res = await HttpInstance.get(
        process.env.REACT_APP_USER_SERVER + "/member/family/list",
        {
          params: {
            member_id: modalParam.memberId,
            is_approved: false,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setFamilyList(res.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFamilyList();
  }, [modalParam]);

  const onApproveEvt = (name, data) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText={`${name}님 가족등록을 승인하시겠습니까?`} />
          </>
        ),
        // width: "50rem",
        onBtnYes: () => {
          approveFamily(data);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const approveFamily = async (data) => {
    try {
      const formData = new FormData();
      formData.append("member_id", modalParam.memberId);
      formData.append("new_info", JSON.stringify(data));

      setLoading(true);
      const res = await HttpInstance.post(
        process.env.REACT_APP_USER_SERVER + "/member/family/approve",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label labelText="정상적으로 승인되었습니다." />
              </>
            ),

            onBtnOk: () => {
              getFamilyList(); //가족목록 리스트 갱신
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const onRowDelete = (name, familyId) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText={`${name}님을 가족목록에서 삭제하시겠습니까?`} />
            {/* <Label labelText={'연결된 가족을 삭제하는 경우, 연결이 자동으로 해제됩니다.'} /> */}
          </>
        ),
        onBtnYes: () => {
          deleteFamily(familyId);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const deleteFamily = async (familyId) => {
    try {
      setLoading(true);

      const res = await HttpInstance.delete(
        process.env.REACT_APP_USER_SERVER + "/member/family/remove",
        {
          params: {
            member_id: modalParam.memberId, //기준회원 id
            family_info_id: familyId,
          },
        },
      );
      if (!res.data.data) {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label labelText="정상 처리되었습니다." />
              </>
            ),
            onBtnOk: () => {
              //삭제성공
              getFamilyList(); //가족목록 다시 갱신
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  const familyListTableData = new familyListTable(onApproveEvt, viewDocModal, onRowDelete);
  return (
    <div
      style={{
        width: "40rem",
      }}
    >
      {loading && <Loading />}

      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
      <CustomTable
        columns_head={familyListTableData.columns_head}
        table_title={familyListTableData.table_title}
        rest_call={familyListTableData.get_data_from_rest}
        row_render={familyListTableData.create_table}
        rest_data={familyList}
      />
      <div style={{ display: "flex", justifyContent: "center", margin: "1rem 0 0 0" }}>
        <NormalBtn
          name="가족 추가"
          onClick={() => {
            searchMemberModal(
              "회원 검색",
              {
                mode: "family",
                memberId: modalParam.memberId,
              },
              localModalDone,
            );
          }}
        />
      </div>
    </div>
  );
};
export default FamilyListModal;

class familyListTable {
  table_title = "";

  onApproveEvt = null;
  viewDocModal = null;
  onRowDelete = null;
  constructor(onApproveEvt, viewDocModal, onRowDelete) {
    this.onApproveEvt = onApproveEvt;
    this.viewDocModal = viewDocModal;
    this.onRowDelete = onRowDelete;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.personal_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.personal_info.member_name}</CustomTableContents>
        <CustomTableContents>{data.reg_status}</CustomTableContents>
        <CustomTableContents>
          {data.reg_status === "대기" && (
            <IconButton
              type="register_done"
              width={"3rem"}
              height={"3rem"}
              onClick={() => {
                this.onApproveEvt(data.personal_info.member_name, data);
              }}
            />
          )}

          <IconButton
            type="detail"
            width={"3rem"}
            height={"3rem"}
            onClick={() => {
              this.viewDocModal("가족등록 서류 확인", {
                data: data.doc_list,
              });
            }}
          />
          <IconButton
            type="delete"
            width={"3rem"}
            height={"3rem"}
            onClick={() => {
              this.onRowDelete(data.personal_info.member_name, data.family_info_id);
            }}
          />
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "등록일", width: "18%" },
    { title: "회원번호", width: "18%" },
    { title: "이름", width: "18%" },
    { title: "등록상태", width: "18%" },
    { title: "연결/증명서", width: "28%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
