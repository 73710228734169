import _ from "lodash";
import {
  payListActions,
  subsPayListActions,
} from "pages/ERP/MemberCommonItems/Module/actions/index";
import {
  payment_info_state,
  payment_input_state,
} from "pages/ERP/MemberCommonItems/PaymentModalB/payment_B_init";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChangeSummaryInfo from "./ChangeSummaryInfo";
import RefundList from "./RefundList";

const ResultWrapper = forwardRef((props, ref) => {
  const {
    serviceViewState,
    changeItemTotalPrice,
    payInfoListAction,
    basePurchaseCode,
    payMethodList,
    setPayMethodList,
    // isPtChange,
    // isPtUpgrade,
    regDate,
  } = props;
  const storesDispatch = useDispatch();
  const resultViewState = useSelector((state) => state.member_common_state.pay_list_state);

  const [refundListState, setRefundListState] = useState([]);
  const [totalSummary, setTotalSummary] = useState({
    mbshpItems: [],
    otherItems: [],
    beforeTotal: 0,
    beforeRefund: 0,
    afterRefundTotal: 0,
    diffTotal: 0, // 차액
    changeTotal: 0, // 변경상품 총액
    remainedRefund: 0, // 남은 환불금액 (환불신청내역에서 환불금액을 처리해야하는 곳에서 사용)
  });

  useImperativeHandle(ref, () => ({
    getRefundItemList: () => {
      return refundListState;
    },
    resetItem: (curRefundTargetList, totalSummaryParams) => {
      // initAndReset();
      setRefundListState(curRefundTargetList);
      setTotalSummary(totalSummaryParams);
      storesDispatch(payListActions.paymentInfoClear("clear"));
      storesDispatch(subsPayListActions.subsPayInfoClear("clear"));
      // calcChangeTotal();
    },
    membershipItemEdit: (act, pType, detailInfo, ord, mbshpIdx) => {
      console.log("!?!?!?!? editItem : ");
      console.log(act);
      console.log(pType);
      console.log(detailInfo);
      payInfoListAction(act, pType, detailInfo, ord, mbshpIdx);
    },
  }));

  const addPaymentInfo = (purchaseCode, price, items) => {
    const newPayInfo = _.cloneDeep(payment_info_state);
    newPayInfo.purchase_code = purchaseCode;
    newPayInfo.unpaid_price = price;
    newPayInfo.payment_input.purchase_code = purchaseCode;
    newPayInfo.payment_input.unpaid_info.unpaid_price = price;
    newPayInfo.payment_input.total_price = price;
    newPayInfo.paid_item_list = items || [];
    serviceViewState.paymentA.payment_info.push(newPayInfo);

    setPayMethodList([...payMethodList, newPayInfo.payment_input]);
  };

  const removePaymentInfo = (purchaseCode) => {
    const idx = serviceViewState.paymentA.payment_info.findIndex(
      (x) => x.purchase_code === purchaseCode,
    );
    if (idx !== -1) {
      serviceViewState.paymentA.payment_info.splice(idx, 1);
      setPayMethodList(payMethodList.filter((it) => it.purchase_code !== purchaseCode));
    }
  };

  const updatePaymentInfo = (purchaseCode, price, items) => {
    const paymentInfo = serviceViewState.paymentA.payment_info;
    const idx = paymentInfo.findIndex((x) => x.purchase_code === purchaseCode);

    if (idx !== -1) {
      paymentInfo[idx].unpaid_price = price;
      paymentInfo[idx].payment_input.unpaid_info.unpaid_price = price;
      if (items) paymentInfo[idx].paid_item_list = items;

      setPayMethodList(
        payMethodList.map((it, idx) => {
          if (it.purchase_code === purchaseCode) {
            it = _.cloneDeep(payment_input_state);
            it.purchase_code = purchaseCode;
            it.unpaid_info.unpaid_price = price;
            it.total_price = price;
          }
          return it;
        }),
      );
    }
  };

  useEffect(() => {
    const typeKey = "상품변경 추가금";
    let diffPrice = 0;
    let action = "";

    // if (totalSummary.diffTotal < 0) {
    //   action = "pop";
    //   removePaymentInfo(typeKey);
    // } else {
    if (!basePurchaseCode) {
      return;
    }

    if (totalSummary.diffTotal < 0) {
      diffPrice = 0;
    } else {
      diffPrice = totalSummary.diffTotal;
    }

    if (resultViewState.findIndex((x) => x.type_key === typeKey) === -1) {
      action = "push";
      addPaymentInfo(typeKey, Math.abs(diffPrice));
    } else {
      action = "discount";
      updatePaymentInfo(typeKey, Math.abs(diffPrice));
    }
    // }

    payInfoListAction(
      action,
      typeKey,
      {
        dis: 0,
        item_type: "상품변경/없음/없음/없음/없음",
        pay_order: 0,
        price: diffPrice,
        tab_idx: 0,
        type: typeKey,
      },
      0,
    );
  }, [totalSummary.diffTotal]);

  useEffect(() => {
    calcChangeTotal();

    // console.log(refundListState);
  }, [changeItemTotalPrice, refundListState]);

  const calcChangeTotal = () => {
    const mbshps = totalSummary.mbshpItems.reduce((acc, cur, i) => {
      return acc + cur;
    }, 0);
    const others = totalSummary.otherItems.reduce((acc, cur, i) => {
      return acc + cur;
    }, 0);

    // console.log("changeItemTotalPrice  ", changeItemTotalPrice);
    // console.log("mbshps  ", mbshps);
    // console.log("others  ", others);
    const diffT = changeItemTotalPrice - (mbshps - others);
    // let remainedRefund = 0;

    // 환불금액 셋팅!
    const baseIdx = serviceViewState.refund_item_info.refund_list.findIndex(
      (x) => x.purchase_code === basePurchaseCode,
    );
    if (baseIdx !== -1) {
      if (diffT < 0) {
        serviceViewState.refund_item_info.refund_list[baseIdx].total_refund_price = Math.abs(diffT);
      } else {
        serviceViewState.refund_item_info.refund_list[baseIdx].total_refund_price = 0;
      }
    }

    // console.log("calcChangeTotal  ", isPtChange, isPtUpgrade);

    // console.log("calcChangeTotal  ", changeItemTotalPrice);
    // console.log("calcChangeTotal  ", diffT);
    setTotalSummary({
      ...totalSummary,
      changeTotal: changeItemTotalPrice,
      diffTotal: diffT,
    });
  };

  // useEffect(() => {
  //   storesDispatch(loadChangeItemViewStore(memberId));
  // }, []);

  // 선택 시 초기화

  // ** 이거 아직 실행 안함
  const onRefundPaymentItem = (pCode, totalPaid) => {
    console.log("onRefundPaymentItem~~~!!");
    // console.log(onRefundPaymentItem);
    let rIndex = serviceViewState.refund_item_info.refund_list
      .map((item) => item.purchase_code)
      .indexOf(pCode);

    if (rIndex !== -1) {
      serviceViewState.refund_item_info.refund_list[rIndex].total_refund_price = totalPaid;
    }
  };

  return (
    <div>
      {/* 상품 변경 금액 */}
      <ChangeSummaryInfo totalSummary={totalSummary} />

      {/* 환불 신청 내역 */}
      <RefundList
        serviceViewState={serviceViewState}
        refundListState={refundListState}
        payInfoListAction={payInfoListAction}
        onRefundPaymentItem={onRefundPaymentItem}
        addPaymentInfo={addPaymentInfo}
        removePaymentInfo={removePaymentInfo}
        updatePaymentInfo={updatePaymentInfo}
        payMethodList={payMethodList}
        setPayMethodList={setPayMethodList}
        regDate={regDate}
      />
    </div>
  );
});
export default ResultWrapper;
