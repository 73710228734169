import IconButton from "components/Button/IconButton";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";

const MonthlyPriceTable = ({ viewData }) => {
  const resultTableInst = new resultTable();

  return (
    <div style={{ marginTop: "0.5rem" }}>
      <div style={{ display: "flex", marginBottom: "0.5rem" }}>
        <IconButton type="border" />
        <Label labelText="당월 판매 금액" marginLeft="0.5rem" fontWeight="bold" />
      </div>
      <CustomTable
        columns_head={resultTableInst.columns_head}
        table_title={resultTableInst.table_title}
        rest_call={resultTableInst.get_data_from_rest}
        row_render={resultTableInst.create_table}
        rest_data={viewData}
      />
    </div>
  );
};
export default MonthlyPriceTable;

class resultTable {
  table_title = "";

  getTextColor = (salesType) => {
    if (salesType === "환불") {
      return "#b51c1c";
    } else if (salesType === "매출") {
      return "#2724d8";
    }
    return "#000000";
  };

  getFontWeight = (salesType) => {
    if (salesType === "총계") {
      return "bold";
    }
    return "normal";
  };

  create_table = (data, idx) => {
    return (
      <CustomRow style={{}} key={idx}>
        <CustomTableContents $bgColor="#dfebf3">{data.sales_type}</CustomTableContents>
        <CustomTableContents
          color={this.getTextColor(data.sales_type)}
          fontWeight={this.getFontWeight(data.sales_type)}
          $isNumber
        >
          {data.mbshp_total_sales.toLocaleString()}
        </CustomTableContents>
        <CustomTableContents
          color={this.getTextColor(data.sales_type)}
          fontWeight={this.getFontWeight(data.sales_type)}
          $isNumber
        >
          {data.pt_total_sales.toLocaleString()}
        </CustomTableContents>
        <CustomTableContents
          color={this.getTextColor(data.sales_type)}
          fontWeight={this.getFontWeight(data.sales_type)}
          $isNumber
        >
          {data.other_total_sales.toLocaleString()}
        </CustomTableContents>
        <CustomTableContents
          color={this.getTextColor(data.sales_type)}
          fontWeight={this.getFontWeight(data.sales_type)}
          $isNumber
        >
          {data.sales_total.toLocaleString()}
        </CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "", width: "20%" },
    { title: "회원권", width: "20%" },
    { title: "PT", width: "20%" },
    { title: "회원권, PT 외", width: "20%" },
    { title: "총계", width: "20%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
