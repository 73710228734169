//reg_input_info
export const inputInitState = {
  contents: {
    active_postpone: {
      approve_status: "",
      doc_info: {
        doc_id: "",
        doc_info: {
          doc_name: "",
          file_name: "",
          reason: "",
          reg_info: {
            created_at: "",
            reg_id: "",
            reg_name: "",
            upd_id: "",
            upd_name: "",
            updated_at: "",
          },
          url_path: "",
        },
        file_due_date: "",
        member_info: {
          member_id: "",
          member_name: "",
          member_no: "",
        },
        purchase_code: "",
      },
      from_date: "",
      mbshp_to_date: "",
      member_id: "",
      membership_id: "",
      postpone_day: 0,
      postpone_id: "",
      postpone_type: "",
      reg_info: {
        created_at: "",
        reg_id: "",
        reg_name: "",
        upd_id: "",
        upd_name: "",
        updated_at: "",
      },
      remarks: "",
      stop_date: "",
      to_date: "",
    },
    mbshp_type: "",
    member_id: "",
    membership_id: "",
    usage: {
      acc_postpone_day: 0,
      family_ot_session: 0,
      link_info_total: 0,
      ot_session: 0,
      total_postpone_cnt: 0,
      used_postpone_cnt: 0,
    },
  },
  permission: {
    branch_id: "",
    can_long_postpone: true,
    can_long_postpone_allow: true,
    can_long_postpone_deny: true,
    can_postpone: true,
    is_long_postpone_ing: false,
    is_postpone_ing: false,
    limit_from_date: "",
    limit_to_date: "",
  },
  contract_doc_list: [],
};
