import React, { useEffect, useRef, useState } from "react";
import { TIME_ARR, showTimeText } from "../calendarDateUtil";
import { selectStyle } from "./calendarStyle";
const DaySchedule = ({
  searchParam,
  setSearchResult,
  selectPopup,
  gridRowItem,
  staffList,
  readonly,
}) => {
  // 오전 9시 row component
  const rowFocusRef = useRef(null);
  const [gridItem, setGridItem] = useState([]);

  //초기값 세팅
  useEffect(() => {
    if (gridRowItem) {
      setGridItem(gridRowItem);
    }
  }, [gridRowItem]);

  // 페이지 진입 시 9시 띄우기
  useEffect(() => {
    if (rowFocusRef.current !== null) {
      rowFocusRef.current.scrollIntoView();
    }
  }, [gridItem]);

  //달력 한칸 호버시, 버튼 생기도록
  const onMouseEvt = (e, timeIdx, staffIdx) => {
    if (searchParam.branch_info.branch_name) {
      setSearchResult((cur) => {
        const obj = { ...cur };
        if (obj.grid_item[timeIdx][staffIdx].is_selected) {
          obj.grid_item[timeIdx][staffIdx].is_selected = true;
        }
        return obj;
      });
    }
  };

  const onMouseLeaveEvt = (e, timeIdx, staffIdx) => {
    if (searchParam.branch_info.branch_name) {
      setSearchResult((cur) => {
        const obj = { ...cur };
        obj.grid_item[timeIdx][staffIdx].is_selected = false;
        return obj;
      });
    }
  };

  const getStaffIdx = (staffIdx) => {
    const staffLen = staffList.length;

    let curIdx = staffIdx + 1;
    // console.log(curIdx, staffLen);
    if (curIdx >= staffLen) {
      return 0;
    } else {
      return curIdx;
    }
  };

  const normalStyle = {
    border: "1px solid #EBEBEB",
    padding: "0.3rem 0.6rem",
    minHeight: "2.5rem",
    fontFamily: "AppleSDGothicNeo",
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `0.5fr repeat(${staffList.length},1fr)`,
        gridAutoRows: "minmax(2.5rem,auto)",
        gridAutoColumns: "1fr",
        boxSizing: "border-box",
      }}
    >
      {gridItem.map((time, timeIdx) => (
        <React.Fragment key={timeIdx}>
          <div style={normalStyle} ref={timeIdx === 9 ? rowFocusRef : null}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              {showTimeText(timeIdx)}
            </div>
          </div>
          {time.map((staff, staffIdx) => (
            <div
              key={staffIdx}
              style={normalStyle}
              onMouseOver={(e) => {
                if (!readonly) onMouseEvt(e, timeIdx, staffIdx);
              }}
              onMouseLeave={(e) => {
                onMouseLeaveEvt(e, timeIdx, staffIdx);
              }}
            >
              {staff.schedule_list &&
                staff.schedule_list.map((el) => (
                  <div
                    key={el.schedule_base.schedule_info.schedule_id}
                    style={selectStyle(
                      el.schedule_base.schedule_info.schedule_type,
                      el.schedule_base.schedule_info.schedule_sub_type,
                    )}
                    onClick={() => {
                      selectPopup(
                        searchParam.search_day_date,
                        "",
                        el.schedule_base.schedule_info.schedule_type,
                        false,
                        el.schedule_base.schedule_info.schedule_id,
                        el,
                      );
                    }}
                  >
                    {el.card_title}
                    {/* <br /> */}
                    {/* {`${el.schedule_base.charger_staff_info.staff_name}`} */}
                    {/* {el.schedule_base.schedule_info.schedule_type === "수업완료" ||
                    el.schedule_base.schedule_info.schedule_type === "예약"
                      ? ` / ${el.schedule_base.schedule_info.schedule_sub_type}`
                      : ""} */}
                  </div>
                ))}
              <div
                style={{
                  marginTop: "0.2rem",
                  display: "flex",
                  gap: "0.2rem",
                  visibility: staff.is_selected ? "visible" : "hidden",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#003CCB",
                    color: "#fff",
                    fontSize: "0.8rem",
                    cursor: "pointer",
                    borderRadius: "5px",
                    width: "50%",
                    height: "1.5rem",
                    lineHeight: "1.5rem",
                  }}
                  onClick={() => {
                    selectPopup(
                      searchParam.search_day_date,
                      TIME_ARR[timeIdx],
                      "예약",
                      true,
                      "",
                      "",
                      staffList[getStaffIdx(staffIdx)],
                    );
                  }}
                >
                  + 예약
                </div>
                <div
                  style={{
                    backgroundColor: "#003CCB",
                    color: "#fff",
                    fontSize: "0.8rem",
                    cursor: "pointer",
                    borderRadius: "5px",
                    width: "50%",
                    height: "1.5rem",
                    lineHeight: "1.5rem",
                  }}
                  onClick={() => {
                    selectPopup(
                      searchParam.search_day_date,
                      TIME_ARR[timeIdx],
                      "개인업무",
                      true,
                      "",
                      "",
                      staffList[getStaffIdx(staffIdx)],
                    );
                  }}
                >
                  + 개인업무
                </div>
              </div>
            </div>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};

export default DaySchedule;
