import { SubLine } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import NumberTextField from "components/LabelInput/NumberTextField";
import { useEffect } from "react";

const SideItemSetting = ({ branchPolicy, ptItemInfo, isEditMode, setPtItemInfo }) => {
  const textLabelWidth = "6rem";
  const textBoxWidth = "12rem";

  const calcPtPrice = () => {
    const pt_total = ptItemInfo.session_cnt * ptItemInfo.per_price;
    setPtItemInfo((prev) => {
      return { ...prev, item_price: pt_total };
    });
  };
  // 가격 정책에 따른 pt 가격 초기화
  const resetPrice = () => {
    if (!isEditMode) {
      setPtItemInfo((prev) => {
        return { ...prev, per_price: branchPolicy.base_policy.pt };
      });
    }
  };

  useEffect(() => {
    resetPrice();
  }, [branchPolicy]);

  return (
    <div style={{}}>
      <SubLine />
      <div style={{ display: "flex", marginTop: "1.25rem" }}>
        <Label labelText={"세부 설정"} />
      </div>
      <div style={{ marginLeft: "0rem" }}>
        <div style={{ display: "flex", marginTop: "0.625rem" }}>
          <NumberTextField
            isNumber
            required
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· 세션"
            endAdornment="회"
            minValue={0}
            maxValue={200}
            defaultValue={ptItemInfo.session_cnt}
            onChangeCallback={(e) => {
              ptItemInfo.session_cnt = e;
              calcPtPrice();
            }}
            disabled={isEditMode}
          />
          <NumberTextField
            isNumber
            labelMarginLeft={"2rem"}
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· 서비스 세션"
            endAdornment="회"
            minValue={0}
            maxValue={200}
            defaultValue={ptItemInfo.service_session_cnt}
            onChangeCallback={(e) => {
              ptItemInfo.service_session_cnt = e;
            }}
            disabled={isEditMode}
          />
        </div>

        {/* pt 금액 line */}

        <div style={{ display: "flex", marginTop: "0.625rem" }}>
          <Label labelText={"· PT 금액 정가"} marginRight={"0.575rem"} />
          <NumberTextField
            isNumber
            labelWidth={"0rem"}
            textBoxWidth={textBoxWidth}
            labelText=""
            defaultValue={branchPolicy.base_policy.pt}
            endAdornment="원"
            disabled
          />

          <NumberTextField
            isNumber
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelMarginLeft={"2rem"}
            labelText="· 회당"
            endAdornment="원"
            minValue={0}
            maxValue={1000000}
            defaultValue={isEditMode ? ptItemInfo.per_price : branchPolicy.base_policy.pt}
            onChangeCallback={(e) => {
              ptItemInfo.per_price = e;
              calcPtPrice();
            }}
            disabled={isEditMode}
          />

          <NumberTextField
            isNumber
            labelWidth={"3.5rem"}
            textBoxWidth={textBoxWidth}
            labelMarginLeft={"2rem"}
            labelText="· 판매가"
            endAdornment="원"
            defaultValue={ptItemInfo.item_price}
            disabled
          />
        </div>
      </div>
      <SubLine />
    </div>
  );
};
export default SideItemSetting;
