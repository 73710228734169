import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { btnSubmitStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import { tabBorder, tabStyle } from "components/CommonComp";
import TabPanel from "components/TabControl/TabPanel";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import { subsPayListActions } from "./Module/actions";

// const PaymentInfo = ({ onClickOpenModal }) => {
const SubscriptionInfo = forwardRef((props, ref) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnSubmitStyles();
  const subsItemListState = useSelector((state) => state.member_common_state.subs_pay_list_state);
  const paymentItemList = useSelector((state) => state.member_common_state.pay_list_state);

  const [tabIndex, setTabIndex] = useState(
    props.memberInputState.membership_all_list.findLastIndex(
      (x) => x.membership_list.mbshp_type === "구독형",
    ),
  );
  const [subsItemList, setSubsItemList] = useState([]);

  // console.log(subsItemListState); // 현재 추가 중인 월 구독 정보들
  // console.log(paymentItemList);
  // console.log(props.memberInputState.sub_payment); // 기존 월 구독 정보들

  const makeItemList = (isInit) => {
    const arr = [];
    for (let i = 0; i < props.memberInputState.membership_all_list.length; i++) {
      arr.push([]);
    }

    for (let i = 0; i < subsItemListState.length; i++) {
      const idx = subsItemListState[i].mbshpIdx;
      arr[idx].push(subsItemListState[i]);
    }

    setSubsItemList(arr);
  };

  useImperativeHandle(ref, () => ({
    getPaidItemList: (idx) => {
      return subsItemList[idx];
    },
    setPaidItemList: () => {
      console.log("ㅋㅋㅋㅋㅋㅋㅋㅋ");
      storesDispatch(subsPayListActions.subsPayInfoList());
      makeItemList();
    },
  }));

  useEffect(() => {
    storesDispatch(subsPayListActions.subsPayInfoList());
    makeItemList(true);
    return () => {
      storesDispatch(subsPayListActions.subsPayInfoClear("clear"));
    };
  }, []);

  useEffect(() => {
    makeItemList();
    setTabIndex(
      props.memberInputState.membership_all_list.findLastIndex(
        (x) => x.membership_list.mbshp_type === "구독형",
      ),
    );
  }, [subsItemListState]);

  const onOpenPayment = (mode) => {
    const param = {
      title: "정기결제수단 등록",
      modalParam: {
        regular_pay_method: props.memberInputState.regular_pay_method,
        regular_pay_date: props.regularPayDate,
        mode: mode,
      },
    };
    props.onClickOpenModal("정기결제수단등록", param);
  };

  const getPaymentItemList = (mbshpIdx) => {
    // filter()로 바꾸기
    return paymentItemList.map((item, idx) => {
      if (item.content_obj.tab_idx === mbshpIdx) return item;
    });
  };

  const getPreDis = (mbshp, type) => {
    if (!mbshp) return 0;
    try {
      if (type === "mbshp") {
        return mbshp.mbshp.subs_options.pre_dis;
      } else if (type === "cloth") {
        return mbshp.cloth.subs_options.pre_dis;
      } else if (type === "gx") {
        return mbshp.gx.subs_options.pre_dis;
      } else if (type === "locker1") {
        if (mbshp.locker_list.length > 0) {
          return mbshp.locker_list[0].subs_options.pre_dis;
        } else {
          return 0;
        }
      } else if (type === "locker2") {
        if (mbshp.locker_list.length > 1) {
          return mbshp.locker_list[1].subs_options.pre_dis;
        } else {
          return 0;
        }
      }
    } catch (error) {
      return 0;
    }
  };

  return (
    <div>
      <CardFixed
        topDom={<div>구독 정보</div>}
        midDom={
          <div>
            <Box sx={{ bgcolor: "background.paper" }}>
              <Tabs
                value={tabIndex}
                onChange={(e, v) => {
                  setTabIndex(v);
                }}
                textColor="inherit"
                sx={{ minHeight: "40px" }}
                TabIndicatorProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0)" } }}
              >
                {subsItemList.map((item, idx) => {
                  if (
                    props.memberInputState.membership_all_list[idx].membership_list.mbshp_type ===
                    "구독형"
                  ) {
                    return (
                      <Tab
                        sx={{ minHeight: "40px" }}
                        key={idx}
                        label={
                          props.isAddPay
                            ? props.memberInputState.membership_all_list[idx].tab_info.title
                            : "신규"
                        }
                        style={tabStyle(idx, tabIndex)}
                      />
                    );
                  } else {
                    return <div></div>;
                  }
                })}
              </Tabs>
            </Box>

            <SwipeableViews
              index={tabIndex}
              onChangeIndex={setTabIndex}
              style={tabBorder}
              animateTransitions={false}
            >
              {subsItemList.map((item, idx) => {
                if (
                  props.memberInputState.membership_all_list[idx].membership_list.mbshp_type ===
                  "구독형"
                ) {
                  return (
                    <TabPanel value={tabIndex} index={tabIndex}>
                      <PaymentResult
                        mbshpIdx={idx}
                        itemList={subsItemList[idx]}
                        onOpenPayment={onOpenPayment}
                        memberInputState={props.memberInputState}
                        paymentItemList={getPaymentItemList(idx)}
                        mbshpPreDis={getPreDis(
                          props.memberInputState.membership_all_list[idx].membership_list,
                          "mbshp",
                        )}
                        clothPreDis={getPreDis(
                          props.memberInputState.membership_all_list[idx].membership_list,
                          "cloth",
                        )}
                        gxPreDis={getPreDis(
                          props.memberInputState.membership_all_list[idx].membership_list,
                          "gx",
                        )}
                        locker_1_preDis={getPreDis(
                          props.memberInputState.membership_all_list[idx].membership_list,
                          "locker1",
                        )}
                        locker_2_preDis={getPreDis(
                          props.memberInputState.membership_all_list[idx].membership_list,
                          "locker2",
                        )}
                      />
                    </TabPanel>
                  );
                } else {
                  return <div></div>;
                }
              })}
            </SwipeableViews>
            {/* 
            {props.memberInputState.regular_pay_method.relation ? (
              <Button
                className={clsx({
                  // [btnStyleClass.btnRoot] : true,
                  [btnStyleClass.buttonType1]: true,
                })}
                onClick={() => {
                  onOpenPayment("edit");
                }}
              >
                정기결제수단 수정
              </Button>
            ) : (
              <Button
                className={clsx({
                  // [btnStyleClass.btnRoot] : true,
                  [btnStyleClass.buttonType1]: true,
                })}
                onClick={() => {
                  onOpenPayment("add");
                }}
              >
                정기결제수단 등록
              </Button>
            )} */}
          </div>
        }
        isLoading={false}
        rootStyle={{
          marginTop: "0.625rem",
          borderRadius: "10px",
          border: "solid 2px #032063",
          backgroundColor: "#fff",
        }}
      />
    </div>
  );
});
export default SubscriptionInfo;

const PaymentResult = ({
  mbshpIdx,
  itemList,
  onOpenPayment,
  memberInputState,
  paymentItemList,
  mbshpPreDis,
  clothPreDis,
  gxPreDis,
  locker_1_preDis,
  locker_2_preDis,
}) => {
  const [contractTotal, setContractTotal] = useState(0);
  const [totalRegularPrice, setTotalRegularPrice] = useState(0);

  const contractTotalPriceBlock = [
    {
      type_key: "약정 총액",
      content_obj: {
        price: contractTotal,
      },
    },
  ];

  const regularItemsBlock = [
    {
      type_key: "월 정기결제 항목",
      content_obj: {
        price: "",
      },
    },
  ];

  const totalRegularPriceBlock = [
    {
      type_key: "월 정기결제액",
      content_obj: {
        price: totalRegularPrice,
      },
    },
  ];

  useEffect(() => {
    calcPrices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemList, mbshpPreDis, clothPreDis, gxPreDis, locker_1_preDis, locker_2_preDis]);

  const calcPrices = () => {
    let totalPrice = 0;
    let totalDiscount = 0;
    for (let i = 0; i < itemList.length; i++) {
      totalPrice += itemList[i].content_obj.price;
      totalDiscount += itemList[i].content_obj.dis;
    }
    setTotalRegularPrice(totalPrice - totalDiscount);

    // if (paidInfo.total_paid > 0) {
    //   resetPaid();
    // }

    // (모든 상품 월 구독료 * (약정기간 - 선납기간) + 모든 상품 선납금 - 선납금 할인액)
    // console.log(itemList);
    // console.log(itemListAll);

    const curItem = memberInputState.membership_all_list[mbshpIdx].purchase_item_list.item_info;
    const month = curItem.subs_options.subs_period_month - curItem.subs_options.pre_month;
    let ctrctTotal = 0;

    itemList.forEach((it, idx) => {
      ctrctTotal += (it.content_obj.price - it.content_obj.dis) * month;
    });

    for (let i = 0; i < paymentItemList.length; i++) {
      if (paymentItemList[i] && paymentItemList[i].type_key.includes("선납")) {
        ctrctTotal += paymentItemList[i].content_obj.price - paymentItemList[i].content_obj.dis;
      }
    }

    setContractTotal(ctrctTotal);
  };

  return (
    <div
      style={{
        marginTop: "1.25rem",
      }}
    >
      <PaymentResultItem data={contractTotalPriceBlock[0]} isBold={true} />
      {/* <PaymentResultItem data={contractTotalPrice[1]} /> */}

      <div
        style={{
          width: "20.25rem",
          height: "0.063rem",
          margin: "1.125rem 0rem",
          backgroundColor: "#dedede",
        }}
      />

      <PaymentResultItem data={regularItemsBlock[0]} isBold={true} marginBottom="1rem" noPrice />
      {itemList.map((item, idx) => (
        <PaymentResultItem key={idx} data={item} isAddDis={true} isItems={true} />
      ))}
      <div
        style={{
          width: "20.25rem",
          height: "0.063rem",
          margin: "1.125rem 0rem",
          backgroundColor: "#dedede",
        }}
      />

      {/* <PaymentResultItem data={testPayList[0]} /> */}
      <PaymentResultItem data={totalRegularPriceBlock[0]} isBold={true} />
    </div>
  );
};

const PaymentResultItem = (props) => {
  const baseStyle = {
    height: "1.188rem",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.938rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
    marginBottom: props.marginBottom ? props.marginBottom : "0.375rem",
  };

  return (
    <div style={baseStyle}>
      <div
        style={{
          display: "flex",
          float: "left",
          marginRight: "auto",
          fontWeight: props.isBold === undefined ? "normal" : "bold",
          fontSize: props.isHighLight === undefined ? "0.938rem" : "1.125rem",
          color: props.isHighLight === undefined ? "#000" : "#ff3e3e",
        }}
      >
        {props.isItems ? props.data.content_obj.type : props.data.type_key}
      </div>

      <div
        style={{
          display: "flex",
          float: "right",
          marginLeft: "auto",
          fontWeight: props.isBold === undefined ? "normal" : "bold",
          fontSize: props.isHighLight === undefined ? "0.938rem" : "1.125rem",
          color: props.isHighLight === undefined ? "#000" : "#ff3e3e",
        }}
      >
        {props.noPrice
          ? ""
          : props.isAddDis !== undefined
          ? (props.data.content_obj.price - props.data.content_obj.dis).toLocaleString() + "원"
          : props.data.content_obj.price.toLocaleString() + "원"}
      </div>
    </div>
  );
};
