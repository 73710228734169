import { inputInitState } from "./init_state";
import * as ActionTypes from "../actions/action_type";

const initialStete = {
  stores: {
    data: inputInitState,
  },
};
export const change_paymethod_view_info = (state = initialStete, action) => {
  switch (action.type) {
    case ActionTypes.getChangePayMethodViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getChangePayMethodRefundPush: {
      state.stores.data.refund_list.push(action.data_row);
      return state;
    }
    case ActionTypes.getChangePayMethodRefundPop: {
      var array = [...state.stores.data.refund_list];
      const index = array.map((item) => item.payment_id).indexOf(action.payment_id);

      if (index !== -1) {
        array.splice(index, 1);
      }
      state.stores.data.refund_list = array;
      return state;
    }
    case ActionTypes.getChangePayMethodViewFailAction:
    case ActionTypes.getChangePayMethodViewResetAction:
      return { ...state, stores: initialStete.stores };

    default:
      return state;
  }
};
