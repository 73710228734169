import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { generateObjectRandomDummyId } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import RadioGroup from "components/LabelInput/RadioGroup";
import TextField from "components/LabelInput/TextField";
import TitleLabel from "components/LabelInput/TitleLabel";
import SubTitle from "components/PageTitle/SubTitle";
import { globalAlertOn } from "modules/actions/Alert";

import { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getContractDetailAPi } from "./RestApi/getApi";
// import { newPostTesterAPi } from "./RestApi/tempPostApi";
import Loading from "components/Loading/Loading/Loading";
// import TextEditor from "pages/NoticeBoard/CommonItem/TextEditor";
import { updateContractAPi } from "./RestApi/postApi";

const TextEditor = lazy(() => import("pages/NoticeBoard/CommonItem/TextEditor"));

const CardTitle = styled.div`
  // width: 62px;
  height: ${(props) => (props.titleHeight === undefined ? "1.313rem;" : props.titleHeight)};
  margin: 0 0.625rem 0.625rem 0;
  font-family: AppleSDGothicNeo;
  font-size: 1.125rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
`;

const ContractUpdateView = () => {
  const params = useParams();
  const { state: selectedBranchInfo } = useLocation();

  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const navigate = useNavigate();
  const [contractObject, setContractObject] = useState({
    contract_id: params.contractId,
    contract_list: [],
    contract_name: "",
    reason: "",
  });

  useEffect(() => {
    getContractDetailAPi(storesDispatch, params.contractId, setContractObject);
  }, [params.contractId]);

  const newContractData = () => {
    let contractInfo = {
      case_id: generateObjectRandomDummyId(),
      case_name: "",

      case_content: "",
      case_requre: false,
      case_check: false,
    };

    contractObject.contract_list.push(contractInfo);

    setContractObject((cur) => {
      return { ...cur, contract_list: contractObject.contract_list };
    });
  };
  const deleteContractData = (idx) => {
    // if (contractId) {
    //   const idx = contractObject.contract_list.findIndex((x) => x.case_id === contractId);

    if (idx !== -1) {
      console.log(idx);
      contractObject.contract_list.splice(idx, 1);
      setContractObject((cur) => {
        return { ...cur, contract_list: contractObject.contract_list };
      });
    }
    // }
  };

  //저장 validation
  const validationCheck = () => {
    //본사,지점,제목 확인하고
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="저장 하시겠습니까?" />
          </>
        ),
        onBtnYes: () => {
          editContract();
        },
        onBtnNo: () => {},
      }),
    );
  };

  const editContract = async () => {
    const debug = false;
    if (!debug) {
      updateContractAPi(
        navigate,
        selectedBranchInfo,
        storesDispatch,
        params.contractId,
        contractObject.contract_list,
      );
    }
  };

  const cancelBtn = () => {
    navigate("/tablet_setting", { state: selectedBranchInfo });
  };

  const getRadioDefault1 = (checked) => {
    if (checked) {
      return "필수";
    } else {
      return "선택";
    }
  };
  const getRadioDefault2 = (checked) => {
    if (checked) {
      return "체크 있음";
    } else {
      return "체크 없음";
    }
  };

  return (
    <Suspense fallback={<Loading />}>
      <div>
        <CardTitle>{contractObject.contract_name}</CardTitle>
        {contractObject.contract_list.map((el, idx) => (
          <div key={`contract-${idx}`}>
            <div style={{ display: "flex", marginBottom: "1rem" }}>
              <IconButton type="border" />
              <div style={{ width: "0.5rem" }}></div>
              <SubTitle titleText={"항목 " + (idx + 1)} />
              <div
                style={{
                  marginLeft: "auto",
                  order: "2",
                }}
              >
                <Button
                  className={clsx({
                    [btnStyleClass.btnRootGrayWhite]: true,
                    [btnStyleClass.buttonType4]: true,
                  })}
                  onClick={() => deleteContractData(idx)}
                  disabled={contractObject.contract_list.length === 1 ? true : false}
                >
                  항목 삭제
                </Button>
              </div>
            </div>
            <TitleLabel marginLeft="0.625rem" marginBottom="0.625rem" labelText="항목명" />
            <TextField
              labelText=""
              fullWidth
              labelWidth="0rem"
              defaultValue={contractObject.contract_list[idx].case_name}
              onChangeCallback={(e) => {
                contractObject.contract_list[idx].case_name = e;

                setContractObject((cur) => {
                  return { ...cur, contract_list: contractObject.contract_list };
                });
              }}
            />
            <TitleLabel
              marginLeft="0.625rem"
              marginBottom="0.625rem"
              marginTop="1.625rem"
              labelText="내용"
            />
            <div
              style={{
                marginLeft: "0.625rem",
                marginBottom: "0.625rem",
                display: "inline-block",
                width: "99%",
              }}
            >
              <TextEditor
                defaultValue={contractObject.contract_list[idx].case_content}
                onChangeCallback={(e) => {
                  contractObject.contract_list[idx].case_content = e;
                  setContractObject((cur) => {
                    return { ...cur, contract_list: contractObject.contract_list };
                  });
                }}
              />
            </div>
            <div>
              <TitleLabel
                marginLeft="0.625rem"
                marginBottom="0.625rem"
                marginTop="1.625rem"
                labelText="항목 구분"
              />
              <RadioGroup
                // inputRef={radioSelectorRef}
                labelWidth={"0rem"}
                data={["필수", "선택"]}
                isMulti={true}
                labelText=""
                onChangeCallback={(e, v) => {
                  let curChecked = v === "필수" ? true : false;
                  contractObject.contract_list[idx].case_require = curChecked;
                  setContractObject((cur) => {
                    return { ...cur, contract_list: contractObject.contract_list };
                  });
                }}
                defaultValue={getRadioDefault1(contractObject.contract_list[idx].case_require)}
                // defaultValue={onCheckBoolDefault(restData.can_assign)}
              />
            </div>
            <div>
              <TitleLabel
                marginLeft="0.625rem"
                marginBottom="0.625rem"
                marginTop="1.625rem"
                labelText="항목 동의 체크 여부"
              />
              <RadioGroup
                // inputRef={radioSelectorRef}
                labelWidth={"0rem"}
                data={["체크 있음", "체크 없음"]}
                isMulti={true}
                labelText=""
                onChangeCallback={(e, v) => {
                  let curChecked = v === "체크 있음" ? true : false;
                  contractObject.contract_list[idx].case_check = curChecked;
                  setContractObject((cur) => {
                    return { ...cur, contract_list: contractObject.contract_list };
                  });
                }}
                defaultValue={getRadioDefault2(contractObject.contract_list[idx].case_check)}
                // defaultValue={""}
                // defaultValue={onCheckBoolDefault(restData.can_assign)}
              />
            </div>
          </div>
        ))}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "0.5rem",
            marginTop: "0.7rem",
          }}
        >
          <Button
            className={clsx({
              [btnStyleClass.btnRootRaw]: true,
              [btnStyleClass.buttonType4]: true,
            })}
            onClick={validationCheck}
          >
            저장
          </Button>
          <Button
            className={clsx({
              [btnStyleClass.btnRootRaw]: true,
              [btnStyleClass.buttonType4]: true,
            })}
            onClick={newContractData}
          >
            항목추가
          </Button>
          <Button
            className={clsx({
              [btnStyleClass.btnRootGrayWhite]: true,
              [btnStyleClass.buttonType4]: true,
            })}
            onClick={cancelBtn}
          >
            취소
          </Button>
        </div>
      </div>
    </Suspense>
  );
};
export default ContractUpdateView;
