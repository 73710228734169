import {
  contentTypeJson,
  dataEmpty,
  getPermission,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import CheckBox from "components/LabelInput/CheckBox";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { searchStaffParamInit } from "pages/CommonModal/Search/Module/reducers/staff_search_params";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import IconButton from "components/Button/IconButton";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";

const SearchStaffInBranch = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const [searchParam, setSearchParam] = useState(searchStaffParamInit);
  const [staffSearchResult, setStaffSearchResult] = useState([]);

  const [curPage, setCurPage] = useState();
  const [totalPage, setTotalPage] = useState(0);

  const [phone, setPhone] = useState({
    p1: "",
    p2: "",
    p3: "",
  });
  const phoneRef1 = useRef();
  const phoneRef2 = useRef();
  const phoneRef3 = useRef();
  const [checkedArr, setCheckedArr] = useState([]);
  const [canAccessPersonalInfo, setCanAccessPersonalInfo] = useState(false);
  const [loading, setLoading] = useState(false);

  // 전화번호 입력 이벤트 (모든 칸)
  const onInputPhone = () => {
    let res = "";

    if (phone.p1 && !phone.p2 && phone.p3) {
      res = "err";
    } else {
      res = phone.p1 + "-" + phone.p2 + "-" + phone.p3;
    }
    res = res.replace("--", "-");

    setSearchParam({
      ...searchParam,
      phone: res,
    });
  };

  const onClickSearch = async (page) => {
    setCurPage(page);
    try {
      if (searchParam.phone === "err") {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: <Label labelText="전화번호 검색 조건이 잘못되었습니다." />,
            onBtnOk: () => {},
          }),
        );
        return;
      }

      searchParam.branch_info.branch_id = modalParam.branch_info.branch_id;
      const formData = new FormData();
      formData.append("page_num", curPage);
      if (modalParam.mode === "multi" || modalParam.mode === "multi-one") {
        formData.append("is_pagination", false);
      }

      formData.append("search_param", JSON.stringify(searchParam));
      formData.append("menu_name", "CLASS_MENU");

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_STAFF_SERVER + "/staff/search_modal",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        const result = res.data.data;
        if (modalParam.mode === "multi" && modalParam.teamMemberIdList.length !== 0) {
          setCheckedArr(modalParam.teamMemberList);
        }

        if (modalParam.mode === "multi" || modalParam.mode === "multi-one") {
          const team_list = res.data.team_list;
          let idxArr = [];
          for (let i = 0; i < team_list.length; i++) {
            if (!team_list[i].team_name) {
              idxArr.push(i);
            }
          }

          const hasNoTeamMemList = result.filter((el, idx) => idxArr.includes(idx));
          setStaffSearchResult(hasNoTeamMemList);
        } else {
          setStaffSearchResult(result);
          setTotalPage(res.data.total_page);
        }
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const onSelectStaff = (data, name) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText={`[${name}] 를 선택하시겠습니까? `} />
          </>
        ),

        onBtnYes: () => {
          onModalDone(data);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const onSelectMultiStaff = (data, idx, isChecked) => {
    if (isChecked) {
      //추가
      setCheckedArr((cur) => {
        return [...cur, data];
      });
    } else {
      //빼기
      setCheckedArr((cur) => {
        const arr = [...cur];
        const newArr = arr.filter((el) => el.staff_id !== data.staff_id);
        return newArr;
      });
    }
  };

  const addMultiStaff = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="저장 하시겠습니까?" />
          </>
        ),

        onBtnYes: () => {
          onModalDone(checkedArr);
        },
        onBtnNo: () => {},
      }),
    );
  };

  useEffect(() => {
    if (modalParam.mode === "multi" || modalParam.mode === "multi-one") {
      onClickSearch(1);
    }
  }, [modalParam]);

  //페이지네이션
  useEffect(() => {
    if (curPage) {
      onClickSearch(curPage);
    }
  }, [curPage]);

  const checkPermission = async () => {
    const result = await getPermission(storesDispatch, "ACCOUNT_PERMISSION/SECRET");
    setCanAccessPersonalInfo(result);
  };
  useEffect(() => {
    checkPermission();
  }, []);

  const searchResultData = new searchResultTable(onSelectStaff);
  const searchResultMultiData = new searchResultMultiTable(onSelectMultiStaff, checkedArr);

  return (
    <div style={{ width: "46rem" }}>
      {loading && <Loading />}

      <div>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <SubTitle titleText="직원 검색" />
        </div>
        <div>
          {modalParam.mode === "multi" || modalParam.mode === "multi-one" ? (
            <div>
              <TextField
                labelMarginLeft={"1rem"}
                labelWidth={"3rem"}
                textBoxWidth={"9rem"}
                disabled
                labelText="지점"
                defaultValue={modalParam.branch_info.branch_name}
              />
            </div>
          ) : (
            <div>
              <div style={{ display: "flex" }}>
                <TextField
                  labelWidth={"2.5rem"}
                  textBoxWidth={"9rem"}
                  disabled
                  labelText="지점"
                  defaultValue={modalParam.branch_info.branch_name}
                />
                <TextField
                  labelWidth={"3rem"}
                  labelMarginLeft="1.25rem"
                  textBoxWidth={"8rem"}
                  labelText="직원명"
                  onChangeCallback={(e) => {
                    setSearchParam({ ...searchParam, staff_name: e });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      onClickSearch(1);
                    }
                  }}
                />
                <div style={{ display: "flex" }}>
                  <TextField
                    inputRef={phoneRef1}
                    labelWidth={"3rem"}
                    labelMarginLeft="1.25rem"
                    textMarginRight="0.25rem"
                    textBoxWidth="4.25rem"
                    labelText="연락처"
                    maxLength={3}
                    onChangeCallback={(e) => {
                      phone.p1 = e;
                      onInputPhone();
                      if (phone.p1.length === 3) {
                        phoneRef2.current.focus();
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") onClickSearch(1);
                    }}
                  />
                  <TextField
                    inputRef={phoneRef2}
                    labelMarginLeft="0rem"
                    labelMarginRight="0.25rem"
                    textMarginRight="0.25rem"
                    textBoxWidth="4.25rem"
                    labelText="-"
                    maxLength={4}
                    onChangeCallback={(e) => {
                      phone.p2 = e;
                      onInputPhone();
                      if (phone.p2.length === 4) {
                        phoneRef3.current.focus();
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onClickSearch(1);
                      } else if (e.key === "Backspace" && !phone.p2) {
                        phoneRef1.current.focus();
                      }
                    }}
                  />
                  <TextField
                    inputRef={phoneRef3}
                    labelMarginLeft="0rem"
                    labelMarginRight="0.25rem"
                    textBoxWidth="4.25rem"
                    labelText="-"
                    maxLength={4}
                    onChangeCallback={(e) => {
                      phone.p3 = e;
                      onInputPhone();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onClickSearch(1);
                      } else if (e.key === "Backspace" && !phone.p3) {
                        phoneRef2.current.focus();
                      }
                    }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "1.3rem" }}>
                <NormalBtn
                  name="검색"
                  theme="white"
                  onClick={() => {
                    onClickSearch(1);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {modalParam.mode === "multi" ? (
        <div style={{ marginTop: "1rem" }}>
          <CustomTable
            columns_head={searchResultMultiData.columns_head}
            table_title={searchResultMultiData.table_title}
            rest_call={searchResultMultiData.get_data_from_rest}
            row_render={searchResultMultiData.create_table}
            rest_data={staffSearchResult}
            maxHeight={"30rem"}
          />
          <div style={{ display: "flex", marginTop: "0.5rem", justifyContent: "center" }}>
            <NormalBtn name="저장" onClick={addMultiStaff} />
          </div>
        </div>
      ) : modalParam.mode === "multi-one" ? (
        <div style={{ marginTop: "1rem" }}>
          <CustomTable
            columns_head={searchResultData.columns_head}
            table_title={searchResultData.table_title}
            rest_call={searchResultData.get_data_from_rest}
            row_render={searchResultData.create_table}
            rest_data={staffSearchResult}
            maxHeight={"30rem"}
          />
        </div>
      ) : (
        <div style={{ marginTop: "1rem" }}>
          <CustomTable
            columns_head={searchResultData.columns_head}
            table_title={searchResultData.table_title}
            rest_call={searchResultData.get_data_from_rest}
            row_render={searchResultData.create_table}
            rest_data={staffSearchResult}
          />
          <CustomPagination curPage={curPage} doSearch={setCurPage} totalCnt={totalPage} />
        </div>
      )}
    </div>
  );
};

export default SearchStaffInBranch;

class searchResultTable {
  table_title = "";
  onSelectStaff = null;

  constructor(onSelectStaff) {
    this.onSelectStaff = onSelectStaff;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow
        key={idx}
        onClick={() => {
          this.onSelectStaff(data, data.personal_info.staff_name);
        }}
        hover
      >
        <CustomTableContents>{data.base_info.hq_info.hq_name}</CustomTableContents>
        <CustomTableContents>{data.base_info.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.personal_info.staff_name}</CustomTableContents>
        <CustomTableContents>{data.base_info.position.org_name}</CustomTableContents>
        <CustomTableContentsEND align="center">{data.personal_info.phone}</CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "본부", width: "20%" },
    { title: "지점", width: "20%" },
    { title: "직원명", width: "20%" },
    { title: "직책", width: "20%" },
    { title: "연락처", width: "20%" },
  ];
  get_data_from_rest = (stateData) => {
    // console.log(stateData);
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}

class searchResultMultiTable {
  table_title = "";
  onSelectMultiStaff = null;
  checkedArr = null;

  constructor(onSelectMultiStaff, checkedArr) {
    this.onSelectMultiStaff = onSelectMultiStaff;
    this.checkedArr = checkedArr;
  }

  getChecked = (data) => {
    //staff_id랑 체크리스트에 들어있는 staff_id랑 가
    const foundIdx = this.checkedArr.findIndex((el) => el.staff_id === data.staff_id);
    if (foundIdx !== -1) {
      return true;
    } else {
      return false;
    }
  };

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>
          <CheckBox
            defaultValue={this.getChecked(data)}
            isTable
            onChangeCallback={(e) => {
              this.onSelectMultiStaff(data, idx, e.checked);
            }}
          />
        </CustomTableContents>
        <CustomTableContents>{data.personal_info.staff_name}</CustomTableContents>
        <CustomTableContents>{data.base_info.position.org_name}</CustomTableContents>
        <CustomTableContentsEND align="center">{data.personal_info.phone}</CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "선택", width: "10%" },
    { title: "직원명", width: "30%" },
    { title: "직책", width: "30%" },
    { title: "연락처", width: "30%" },
  ];
  get_data_from_rest = (stateData) => {
    // console.log(stateData);
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
