import { useQuery } from "@tanstack/react-query";
import { getSearchExtendHistory } from "apis/branch/extend_mbshp_api";
import { SubLine, getPermission, simpleAlert } from "components/CommonLib/CommonLib";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useExtendMbshpHistoryStore } from "../modules/store/extendMbshpHistoryState";
import ExtendMbshpHistRecordResultTable from "./ExtendMbshpHistRecordResultTable";
import SearchCondition from "./SearchCondition";

const ExtendMbshpHistRecordView = () => {
  const storesDispatch = useDispatch();
  // 전역 상태 관리
  const {
    extendHistorySearchParams: searchParams,
    resetSearchParams,
    setSearchParams,
    extendHistoryPage: page,
    setPage,
  } = useExtendMbshpHistoryStore();
  // 페이지 상태 관리
  const [searchResult, setSearchResult] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);
  const [resetFlag, setResetFlag] = useState(false);
  // API 상태 관리
  const [isGetExtendMbshp, setIsGetExtendMbshp] = useState(false);
  const queryData = { page: page, searchParams: searchParams };

  // API Query
  // [GET] 회원권 기간 추가 리스트 (검색 조건에 따라)
  const { isFetching, data: extendMbshpData } = useQuery({
    queryKey: ["extend", queryData],
    queryFn: () => searchValid(),
    enabled: isGetExtendMbshp,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setIsGetExtendMbshp(false);
    },
  });

  // 검색 유효성 검사 및 query 실행
  const searchValid = () => {
    if (searchParams.hq_info.hq_id === "" || searchParams.branch_info.branch_id === "") {
      simpleAlert(storesDispatch, "알림", "본부와 지점을 선택해주세요.");
      setIsGetExtendMbshp(false);
      return "";
    }
    return getSearchExtendHistory(queryData);
  };

  // API 받아온 데이터 세팅
  useEffect(() => {
    if (extendMbshpData) {
      setSearchResult(extendMbshpData.data.data);
      setTotalPage(extendMbshpData.data.total_page);
    }
  }, [extendMbshpData]);

  // 권한에 따른 본부와 지점 최초값 세팅
  useEffect(() => {
    if (searchParams.hq_info.hq_id === "") {
      getPermission(storesDispatch, "STAFF_MENU/STAFF_SERVICE", true, false, true)
        .then((res) => {
          if (res.target_list.length > 0) {
            setSearchParams("hq_info", res.target_list[0].hq_info);
            setSearchParams("branch_info", res.target_list[0].branch_list[0]);
          }
        })
        .catch((err) => {
          console.log(err);
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        });
    }
  }, []);

  // 페이지 진입 시 결과 리스트
  useEffect(() => {
    if (isFirstEnterPage && searchParams.hq_info.hq_id !== "") {
      setIsGetExtendMbshp(true);
      setIsFirstEnterPage(false);
    }
  }, [searchParams.hq_info.hq_id]);

  return (
    <div>
      {isFetching && <Loading />}

      <div>
        <SearchCondition setIsGetExtendMbshp={setIsGetExtendMbshp} resetFlag={resetFlag} />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "0.75rem",
            gap: "0.3rem",
          }}
        >
          <NormalBtn
            name="초기화"
            theme="white"
            width="6.25rem"
            onClick={() => {
              setPage(1);
              resetSearchParams();
              setResetFlag(!resetFlag);
            }}
          />
          <NormalBtn
            name="검색"
            width="6.25rem"
            onClick={() => {
              setPage(1);
              setIsGetExtendMbshp(true);
            }}
          />
        </div>
      </div>

      <SubLine />

      <div style={{ marginTop: "1rem" }}>
        <ExtendMbshpHistRecordResultTable
          searchResult={searchResult}
          curPage={page}
          totalPage={totalPage}
          setIsGetExtendMbshp={setIsGetExtendMbshp}
        />
      </div>
    </div>
  );
};
export default ExtendMbshpHistRecordView;
