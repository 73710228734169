import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";

const SmsAuthListModal = ({ listData }) => {
  const smsAuthList = new smsAuthListTable();

  return (
    <div style={{ width: "36rem" }}>
      <CustomTable
        columns_head={smsAuthList.columns_head}
        table_title={smsAuthList.table_title}
        rest_call={smsAuthList.get_data_from_rest}
        row_render={smsAuthList.create_table}
        rest_data={listData}
      />
    </div>
  );
};

export default SmsAuthListModal;

class smsAuthListTable {
  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{idx + 1}</CustomTableContents>
        <CustomTableContents>{data.auth_at.slice(0, 10)}</CustomTableContents>
        <CustomTableContents>{data.uuid.slice(-12)}</CustomTableContents>
        <CustomTableContentsEND>{data.auth_at.slice(11, 19)}</CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "No", width: "15%" },
    { title: "날짜", width: "25%" },
    { title: "UUID (끝 12자리)", width: "25%" },
    { title: "문자 인증 시간", width: "25%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
