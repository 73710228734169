import { contentTypeJson } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

// ==================== GET ====================
// [GET] 문자 발송 이력 검색 조건 가져오기
export async function getSendMessageHistSearchParams() {
  return await HttpInstance.get(
    process.env.REACT_APP_USER_SERVER + "/cs/sms/history/search_param",
    {
      params: {},
    },
  );
}

// [GET] 문자 발송 이력 검색
export async function getSendMessageHist(queryData) {
  const { page, searchParams } = queryData;
  const formData = new FormData();

  formData.append("page_num", page);
  formData.append("search_param", JSON.stringify(searchParams));

  return await HttpInstance.post(
    process.env.REACT_APP_USER_SERVER + "/cs/sms/history/search",
    formData,
    contentTypeJson,
  );
}
