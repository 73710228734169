import CardFixed from "components/Card/CardFixed";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { useAccessCardList, useAccessCardStatus } from "hooks/apis/branch/useAccessCard";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { utils, writeFile } from "xlsx";
import CardSearchResult from "./CardSearchResult";
import CardSearchView from "./CardSearchView";
import { useAccessCardManageStore } from "./modules/store/accessCardManageState";

const SearchCard = ({ resetSearchParams, onClickSearch }) => {
  return (
    <CardFixed
      midDom={
        <div>
          <CardSearchView onClickSearch={onClickSearch} />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "0.75rem",
              gap: "0.3rem",
            }}
          >
            <NormalBtn
              name="초기화"
              theme="white"
              width="6.25rem"
              onClick={() => resetSearchParams()}
            />
            <NormalBtn
              name="검색"
              width="6.25rem"
              onClick={() => {
                onClickSearch(1, false, true);
              }}
            />
          </div>
        </div>
      }
      isLoading={false}
      rootStyle={{
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};

const SearchResultCard = ({
  searchResult,
  navigate,
  onClickOpenModal,
  cardStatus,
  curPage,
  setCurPage,
  onClickSearch,
  totalPage,
  selectedCard,
}) => {
  return (
    <CardFixed
      midDom={
        <div>
          <CardSearchResult
            cardStatus={cardStatus}
            navigate={navigate}
            searchResult={searchResult}
            onClickOpenModal={onClickOpenModal}
            onClickSearch={onClickSearch}
            curPage={curPage}
            setCurPage={setCurPage}
            totalPage={totalPage}
            selectedCard={selectedCard}
          />
        </div>
      }
      isLoading={false}
      rootStyle={{
        marginTop: "0.625rem",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};

const CardManageView = ({ onClickOpenModal }) => {
  const navigate = useNavigate();

  // 전역 상태 관리
  const {
    accessCardManageSearchParams: searchParams,
    accessCardManagePage: page,
    setPage,
    resetSearchParams,
  } = useAccessCardManageStore();

  // 페이지 상태 관리
  const [searchResult, setSearchResult] = useState([]);
  const [selectedCard, setSelectedCard] = useState([]);
  const [cardStatus, setCardStatus] = useState({
    all: 0,
    used: 0,
    suspended: 0,
    wait: 0,
  });
  const [totalPage, setTotalPage] = useState(1);
  const [isExcel, setIsExcel] = useState(false);
  const [isInit, setIsInit] = useState(false);

  // API 상태 관리
  const [isGetAccessCardList, setIsGetAccessCardList] = useState(false);
  const queryData = { page: page, searchParams: searchParams, isExcel: isExcel };

  // [GET] 출입 카드관리 리스트 검색
  const { isFetching: accessCardFetching, data: accessCardList } = useAccessCardList(
    queryData,
    isGetAccessCardList,
    setIsGetAccessCardList,
  );
  // [GET] 출입 카드 현황 가져오기
  const { data: accessCardStatus } = useAccessCardStatus(queryData, isGetAccessCardList);

  //엑셀파일 만들기
  const exportToExcel = (data) => {
    const newData = [];
    for (let i = 0; i < data.length; i++) {
      const row = {};
      row.카드등록일 = data[i].cur_reg_date;
      row.상태 = data[i].card_status;
      row.지점 = data[i].branch_info.branch_name;
      row.시리얼번호 = data[i].card_serial_no;
      row.카드번호 = data[i].card_no;
      row.사용자그룹 = data[i].user_group;
      row.사용자명 = data[i].cur_user.user_name;
      row.회원번호 = data[i].cur_user.member_no;
      row.사번 = data[i].cur_user.sabun;
      row.비고 = data[i].remarks;
      newData.push(row);
    }

    const worksheet = utils.json_to_sheet(newData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, "카드목록.xlsx");
  };
  const preProcessResult = (data) => {
    for (let i = 0; i < data.length; i++) {
      if (selectedCard.findIndex((el) => el.card_info_id === data[i].card_info_id) !== -1) {
        data[i].is_checked = true;
      } else {
        data[i].is_checked = false;
      }
    }
  };

  //검색 POST
  /** 파라미터 page, isExcel, isInit(페네말고 걍 검색버튼) */
  const onClickSearch = async (page, isExcel, isInit) => {
    setPage(page);
    setIsExcel(isExcel);
    setIsInit(isInit);
    if (isInit) {
      setSelectedCard([]);
    }
    // 카드 목록 리스트 받아오기
    setIsGetAccessCardList(true);
  };

  // API 받아온 데이터 세팅
  useEffect(() => {
    // 카드 목록 리스트
    if (accessCardList) {
      if (isExcel) {
        exportToExcel(accessCardList.data.data);
      } else {
        if (!isInit) {
          preProcessResult(accessCardList.data.data);
        }
        setSearchResult(accessCardList.data.data);
        setTotalPage(accessCardList.data.total_page);
      }
    }
    // 카드 현황
    if (accessCardStatus) {
      setCardStatus(accessCardStatus.data.data);
    }
  }, [accessCardList, accessCardStatus, isExcel, isInit]);

  //페이지네이션 및 처음 진입시 결과 띄우기
  useEffect(() => {
    if (page) {
      onClickSearch(page, false);
    }
  }, [page]);

  return (
    <div>
      {accessCardFetching ? <Loading /> : <></>}

      <SearchCard resetSearchParams={resetSearchParams} onClickSearch={onClickSearch} />

      <SearchResultCard
        navigate={navigate}
        onClickOpenModal={onClickOpenModal}
        searchResult={searchResult}
        cardStatus={cardStatus}
        onClickSearch={onClickSearch}
        curPage={page}
        setCurPage={setPage}
        totalPage={totalPage}
        selectedCard={selectedCard}
      />
    </div>
  );
};
export default CardManageView;
