import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from "react";
import PriceDiscountInput from "pages/ERP/MemberRegister/Common/Etc/PriceDiscountInput";
import CheckBox from "components/LabelInput/CheckBox";
import SubTitle from "components/PageTitle/SubTitle";
import FamilyLockerListInfo from "pages/ERP/MemberCommonItems/LockerRegister/FamilyLockerListInfo";
import { SubLine } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";

const SubscriptFamilyItem = ({
  payInfoListAction,
  subsPayInfoListAction,
  // membershipItemInfo,
  membershipIdx,
  itemIncludeRef,
  memberInputState,
  onChangeSubsDis,
  hqBranchPolicy,
  selected_locker_list,
  personalInfo,
}) => {
  const disItemRef = {
    reg: useRef(),
    cloth: useRef(),
    gx: useRef(),
    locker: [useRef(), useRef()],
  };

  // console.log(membershipItemInfo);
  const [clothProrate, setClothProrate] = useState(
    memberInputState.membership_list.cloth.subs_options.prorate_price,
  );
  const [gxProrate, setGxProrate] = useState(
    memberInputState.membership_list.gx.subs_options.prorate_price,
  );

  // const [lockerList, setLockerList] = useState([]);

  useEffect(() => {
    setClothProrate(memberInputState.membership_list.cloth.subs_options.prorate_price);
  }, [memberInputState.membership_list.cloth.subs_options.prorate_price]);

  useEffect(() => {
    setGxProrate(memberInputState.membership_list.gx.subs_options.prorate_price);
  }, [memberInputState.membership_list.gx.subs_options.prorate_price]);

  const onChangeCheck = (event, orderIdx, contentObj, stdPrice, payOrder) => {
    let act = event.checked ? "push" : "pop";
    let eventId = memberInputState.tab_info.title + event.id;
    let tabIdx = memberInputState.tab_info.idx;
    // console.log(event, orderIdx, contentObj, stdPrice);
    let startIdx = membershipIdx * 10;
    payInfoListAction(
      act,
      eventId + "(가족회원)",
      {
        type: eventId + "(가족회원)",
        price: stdPrice,
        dis: 0,
        pay_order: startIdx + payOrder,
        tab_idx: tabIdx,
        // item_type: event.id + "(가족)",
        item_type: event.id + "/완납형/금액/" + getOptionData(false) + "/가족",
      },
      startIdx + orderIdx,
      tabIdx,
    );
  };
  const getOptionData = (is_lock) => {
    if (
      memberInputState.tab_info.is_add_pay === true &&
      memberInputState.tab_info.is_new === false
    ) {
      // addpaid 뺌
      // if (is_lock) {
      return memberInputState.purchase_item_list.item_info.mbshp_option;
      // } else {
      //   return memberInputState.purchase_item_addpaid.item_info.mbshp_option;
      // }
    } else {
      return memberInputState.purchase_item_list.item_info.mbshp_option;
    }
  };
  return (
    <div style={{}}>
      <SubLine margin="1rem" />
      <div style={{ display: "flex", marginBottom: "0.625rem" }}>
        <Label labelText={"가족회원 부가상품"} />
      </div>

      <div
        style={{
          display: "flex",
        }}
      >
        <CheckBox
          marginTop="0rem"
          ref={itemIncludeRef.family_cloth}
          labelText="운동복"
          onChangeCallbackOrd={(event, orderIdx, contentObj) => {
            memberInputState.membership_list.family_cloth.is_use = event.checked;
            onChangeCheck(
              event,
              orderIdx,
              contentObj,

              memberInputState.membership_list.cloth.subs_options.subs_price,
              3,
            );
            disItemRef.cloth.current.setDisabled(event.checked);
          }}
          orderIdx={2}
          disabled={memberInputState.membership_list.family_cloth.is_lock}
          defaultValue={memberInputState.membership_list.family_cloth.is_use}
        />
        <PriceDiscountInput
          ref={disItemRef.cloth}
          baseInfo={{
            tabTitle: memberInputState.tab_info.title,
            tabIdx: memberInputState.tab_info.idx,
            orderStartIdx: membershipIdx,
            type: "운동복 월 구독(가족회원)",
            typeKey: "운동복 월 구독(가족회원)" + membershipIdx.toString(),
            orderIdx: 2,
            payOrder: 3,
            label: "월 구독료",
            item_type:
              "운동복/구독형/월구독료/" +
              getOptionData(memberInputState.membership_list.family_cloth.is_lock) +
              "/가족",
          }}
          standardPrice={
            memberInputState.purchase_item_list.item_info.subs_options.cloth_subs_price
          }
          payInfoListAction={subsPayInfoListAction}
          onChangeCallback={(e) => {
            memberInputState.membership_list.family_cloth.subs_options.subs_dis = e;
            setClothProrate(onChangeSubsDis("운동복"));
          }}
          paramUse={memberInputState.membership_list.family_cloth.is_use}
          paramLock={memberInputState.membership_list.family_cloth.is_lock}
          discountValue={memberInputState.membership_list.family_cloth.subs_options.subs_dis}
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <PriceDiscountInput
          baseInfo={{
            tabTitle: memberInputState.tab_info.title,
            tabIdx: memberInputState.tab_info.idx,
            orderStartIdx: membershipIdx,
            type: "운동복 일할(가족회원)",
            typeKey: "운동복 일할(가족회원)",
            orderIdx: 3,
            payOrder: 4,
            label: "일할금액",
            item_type:
              "운동복/구독형/일할금액/" +
              getOptionData(memberInputState.membership_list.family_cloth.is_lock) +
              "/가족",
          }}
          // standardPrice={memberInputState.purchase_item_list.item_info.gx_price}
          standardPrice={clothProrate}
          payInfoListAction={payInfoListAction}
          // onChangeCallback={(e) => {
          //   memberInputState.membership_list.family_cloth.dis = e;
          // }}
          paramUse={memberInputState.membership_list.family_cloth.is_use}
          paramLock={memberInputState.membership_list.family_cloth.is_lock}
          discountValue={memberInputState.membership_list.family_cloth.prorate_dis}
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <PriceDiscountInput
          baseInfo={{
            tabTitle: memberInputState.tab_info.title,
            tabIdx: memberInputState.tab_info.idx,
            orderStartIdx: membershipIdx,
            type: "운동복 선납(가족회원)",
            typeKey: "운동복 선납(가족회원)",
            orderIdx: 3,
            payOrder: 4,
            label: "선납금",
            item_type:
              "운동복/구독형/선납금/" +
              getOptionData(memberInputState.membership_list.family_cloth.is_lock) +
              "/가족",
          }}
          standardPrice={memberInputState.membership_list.cloth.subs_options.pre_price}
          payInfoListAction={payInfoListAction}
          onChangeCallback={(e) => {
            memberInputState.membership_list.family_cloth.pre_dis = e;
          }}
          paramUse={memberInputState.membership_list.family_cloth.is_use}
          paramLock={memberInputState.membership_list.family_cloth.is_lock}
          discountValue={memberInputState.membership_list.family_cloth.pre_dis}
        />
      </div>
      <SubLine margin="0.5rem" marginLeft="2rem" marginRight="2rem" />

      <div
        style={{
          display: "flex",
        }}
      >
        <CheckBox
          marginTop="0rem"
          ref={itemIncludeRef.family_gx}
          labelText="GX"
          onChangeCallbackOrd={(event, orderIdx, contentObj) => {
            memberInputState.membership_list.family_gx.is_use = event.checked;
            onChangeCheck(
              event,
              orderIdx,
              contentObj,
              // memberInputState.purchase_item_list.item_info.gx_price,
              memberInputState.membership_list.family_gx.subs_options.subs_price,
              4,
            );
            disItemRef.gx.current.setDisabled(event.checked);
          }}
          orderIdx={3}
          disabled={memberInputState.membership_list.family_gx.is_lock}
          defaultValue={memberInputState.membership_list.family_gx.is_use}
        />
        <PriceDiscountInput
          ref={disItemRef.gx}
          baseInfo={{
            tabTitle: memberInputState.tab_info.title,
            tabIdx: memberInputState.tab_info.idx,
            orderStartIdx: membershipIdx,
            type: "GX 월 구독(가족회원)",
            typeKey: "GX 월 구독(가족회원)" + membershipIdx.toString(),
            orderIdx: 2,
            payOrder: 3,
            label: "월 구독료",
            item_type:
              "GX/구독형/월구독료/" +
              getOptionData(memberInputState.membership_list.family_gx.is_lock) +
              "/가족",
          }}
          standardPrice={memberInputState.purchase_item_list.item_info.subs_options.gx_subs_price}
          payInfoListAction={subsPayInfoListAction}
          onChangeCallback={(e) => {
            memberInputState.membership_list.family_gx.subs_options.subs_dis = e;
            setGxProrate(onChangeSubsDis("GX"));
          }}
          paramUse={memberInputState.membership_list.family_gx.is_use}
          paramLock={memberInputState.membership_list.family_gx.is_lock}
          discountValue={memberInputState.membership_list.family_gx.subs_options.subs_dis}
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <PriceDiscountInput
          baseInfo={{
            tabTitle: memberInputState.tab_info.title,
            tabIdx: memberInputState.tab_info.idx,
            orderStartIdx: membershipIdx,
            type: "GX 일할(가족회원)",
            typeKey: "GX 일할(가족회원)",
            orderIdx: 3,
            payOrder: 4,
            label: "일할금액",
            item_type:
              "GX/구독형/일할금액/" +
              getOptionData(memberInputState.membership_list.family_gx.is_lock) +
              "/가족",
          }}
          standardPrice={gxProrate}
          payInfoListAction={payInfoListAction}
          // onChangeCallback={(e) => {
          //   memberInputState.membership_list.gx.dis = e;
          // }}
          paramUse={memberInputState.membership_list.family_gx.is_use}
          paramLock={memberInputState.membership_list.family_gx.is_lock}
          discountValue={memberInputState.membership_list.family_gx.subs_options.prorate_dis}
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <PriceDiscountInput
          baseInfo={{
            tabTitle: memberInputState.tab_info.title,
            tabIdx: memberInputState.tab_info.idx,
            orderStartIdx: membershipIdx,
            type: "GX 선납(가족회원)",
            typeKey: "GX 선납(가족회원)",
            orderIdx: 3,
            payOrder: 4,
            label: "선납금",
            item_type:
              "GX/구독형/선납금/" +
              getOptionData(memberInputState.membership_list.family_gx.is_lock) +
              "/가족",
          }}
          standardPrice={memberInputState.membership_list.family_gx.subs_options.pre_price}
          payInfoListAction={payInfoListAction}
          onChangeCallback={(e) => {
            memberInputState.membership_list.family_gx.subs_options.pre_dis = e;
          }}
          paramUse={memberInputState.membership_list.family_gx.is_use}
          paramLock={memberInputState.membership_list.family_gx.is_lock}
          discountValue={memberInputState.membership_list.family_gx.subs_options.pre_dis}
        />
      </div>
      <SubLine margin="0.5rem" marginLeft="2rem" marginRight="2rem" />

      <FamilyLockerListInfo
        //   refStatus={props.refStatus}
        //   ref={props.lockerTableRef}

        //   settingData={props.settingData}
        //   periodIdx={periodIdx}
        //   membershipIdx={membershipIdx}
        hqBranchPolicy={hqBranchPolicy}
        memberInputState={memberInputState}
        subsPayInfoListAction={subsPayInfoListAction}
        isSubs={true}
        payInfoListAction={payInfoListAction}
        personalInfo={personalInfo}
      />
    </div>
  );
};

export default SubscriptFamilyItem;
