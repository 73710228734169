import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { generateObjectZeroId } from "components/CommonLib/CommonLib";
import TextField from "components/LabelInput/TextField";
import { globalModalOff } from "modules/actions/Modal";
import { useState } from "react";
import { useDispatch } from "react-redux";

const FriendInfo = ({ onClickOpenModal, onChangeFriend }) => {
  const compWidth = "12rem";
  const labelWidth = "4.5rem";

  const classes = btnStyles();
  const btnStyleClass = btnStyles();
  const [friendName, setFriendName] = useState("");
  const storesDispatch = useDispatch();

  const onSearchFriend = () => {
    const param = {
      title: "지인소개",
      modalParam: {
        mode: "search",
        goToMemberView: onSearchFriendCallback,
      },
    };
    onClickOpenModal("지인소개", param);
  };

  const onSearchFriendCallback = (data) => {
    setFriendName(data.member_info.member_name);
    onChangeFriend(data.member_info);
    storesDispatch(globalModalOff());
  };

  const onFriendRemove = () => {
    setFriendName("");
    onChangeFriend({
      member_name: "",
      member_id: generateObjectZeroId(),
      member_no: "",
    });
  };

  return (
    <div
      style={{
        display: "flex",
        marginTop: "0.5rem",
      }}
    >
      <TextField
        labelWidth={labelWidth}
        textBoxWidth={compWidth}
        textMarginRight="0.25rem"
        labelText="지인소개"
        defaultValue={friendName}
        disabled
      />
      <IconButton type="search" width="2.3rem" onClick={() => onSearchFriend()} />
      <IconButton
        type="deleteBox"
        width="2.3rem"
        marginLeft="0.25rem"
        onClick={() => onFriendRemove()}
      />
    </div>
  );
};
export default FriendInfo;
