///Login
export const globalModalOn = (param) => {
  return {
    type: "modal/GLOBAL_STATE/ON",
    payload: param,
  };
};

export const globalModalOff = (param) => ({
  type: "modal/GLOBAL_STATE/OFF",
  payload: param,
});
