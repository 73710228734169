import ChangePayMethodView from "./ChangePayMethodView";

const ChangePayMethodWrapper = ({ serviceViewState, memberBaseInfoState, initRegDate }) => {
  return (
    <ChangePayMethodView
      serviceViewState={serviceViewState}
      memberBaseInfoState={memberBaseInfoState}
      initRegDate={initRegDate}
    />
  );
};
export default ChangePayMethodWrapper;
