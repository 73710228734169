import IconButton from "components/Button/IconButton";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";

const GxSearchResult = ({
  searchResult,
  curPage,
  setCurPage,
  totalPage,
  onClickOpenModal,
  refreshTable,
}) => {
  const popGxDetailModal = (data) => {
    const param = {
      title: "GX 수업상세보기",
      modalParam: {
        data,
        refreshTable,
      },
    };
    onClickOpenModal("GX수업상세", param);
  };

  const searchResultList = new searchResultTable(popGxDetailModal);

  return (
    <div style={{ marginTop: "1rem" }}>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
      />
      <CustomPagination curPage={curPage} doSearch={setCurPage} totalCnt={totalPage} />
    </div>
  );
};
export default GxSearchResult;

class searchResultTable {
  table_title = "";
  popGxDetailModal = null;
  constructor(popGxDetailModal) {
    this.popGxDetailModal = popGxDetailModal;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents height={"2.375rem"}>{data.gx_info.gx_code}</CustomTableContents>
        <CustomTableContents>{data.class_name}</CustomTableContents>
        <CustomTableContents>{data.view_info.class_info_str}</CustomTableContents>
        <CustomTableContents>{data.last_class_info.class_done_date}</CustomTableContents>
        {/* <CustomTableContents>?? 요일 없어요</CustomTableContents> */}
        <CustomTableContents>{data.last_class_info.class_done_time}</CustomTableContents>
        <CustomTableContents>{data.last_class_info.staff_info.staff_name}</CustomTableContents>
        <CustomTableContents>{data.last_class_info.class_status}</CustomTableContents>

        <CustomTableContentsEND align="center">
          <IconButton
            type="detail"
            onClick={() => {
              this.popGxDetailModal(data);
            }}
          />
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "수업코드", width: "13.75%" },
    { title: "수업명", width: "13.75%" },
    { title: "수업스케줄", width: "13.75%" },
    { title: "최근 수업일", width: "13.75%" },
    // { title: "최근 수업요일", width: "13.75%" },
    { title: "최근 수업시간", width: "13.75%" },
    { title: "최근 수업 담당자", width: "13.75%" },
    { title: "완료구분", width: "13.75%" },
    { title: "", width: "3.75%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
