import IconButton from "components/Button/IconButton";
import CardFixed from "components/Card/CardFixed";
import { SubLine } from "components/CommonLib/CommonLib";
import SubTitle from "components/PageTitle/SubTitle";
import { useEffect, useState } from "react";
import DstMembershipInfo from "./PageTables/DstMembershipInfo";
import DstPersonalInfo from "./PageTables/DstPersonalInfo";
import DstPtInfo from "./PageTables/DstPtInfo";

const AssignDstContents = ({
  payInfoListAction,
  addPayInfoState,
  regDate,
  assignViewState,
  assigner,
  assignee,
  dstPtInfoRef,
  dstMembershipInfoRef,
  isIncludeServicePt,
  sumDay,
}) => {
  const [lastDate, setLastDate] = useState("");
  const [changeBranchFee, setChangeBranchFee] = useState(0);

  useEffect(() => {
    if (lastDate) {
      const fromTime = Date.now();
      const toTime = new Date(lastDate);
      const diffDays = Math.ceil(Math.abs(toTime - fromTime) / (1000 * 3600 * 24));
      const diffMonth = Math.ceil(diffDays / 30);
      setChangeBranchFee(diffMonth * 11000);
    }
  }, [lastDate]);

  return (
    <CardFixed
      topDom={<div>양수사항</div>}
      midDom={
        <div>
          <div style={{ display: "flex", marginTop: "1rem" }}>
            <IconButton type="border" />
            <div style={{ width: "0.5rem" }}></div>
            <SubTitle titleText="양수자 회원정보" />
          </div>
          <DstPersonalInfo
            assignee={assignee}
            assigner={assigner}
            addPayInfoState={addPayInfoState}
            assignViewState={assignViewState}
            payInfoListAction={payInfoListAction}
            changeBranchFee={changeBranchFee}
          />
          <SubLine />

          <div style={{ display: "flex", marginTop: "1rem" }}>
            <IconButton type="border" />
            <div style={{ width: "0.5rem" }}></div>
            <SubTitle titleText="회원권 목록 (기존 보유상품 포함)" />
          </div>
          <DstMembershipInfo
            ref={dstMembershipInfoRef}
            serviceViewState={assignee.membership_data_list}
            sumDay={sumDay}
            regDate={regDate}
            lastDate={lastDate}
            setLastDate={setLastDate}
          />
          <SubLine />

          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton type="border" />
            <div style={{ width: "0.5rem" }}></div>
            <SubTitle titleText="PT 목록 (기존 보유상품 포함) " />
          </div>
          <DstPtInfo
            ptInfoList={assignee.pt_list}
            ref={dstPtInfoRef}
            isIncludeServicePt={isIncludeServicePt}
          />
        </div>
      }
      isLoading={false}
      rootStyle={{
        margin: "1rem",
        borderRadius: "10px",
        backgroundColor: "#fff",
        border: "1px solid #e8ebf2",
        background:
          "transparent linear-gradient(117deg, #e8ebf2 0%, #f2f3f7 100%) 0% 0% no-repeat padding-box",
      }}
    />
  );
};
export default AssignDstContents;
