import IconButton from "components/Button/IconButton";
import CheckBox from "components/LabelInput/CheckBox";
import DatePicker from "components/LabelInput/DatePicker";
import TextField from "components/LabelInput/TextField";
import SubTitle from "components/PageTitle/SubTitle";

const WorkInfoBaseView = ({ workInfoStore, logType }) => {
  const labelWidth = "3.5rem";
  const compWidth = "15rem";
  const compMarginLeft = "1.5rem";

  const getDateInfo = () => {
    if (logType === "입사") {
      return workInfoStore.base_info.join_date;
    } else if (logType === "퇴사") {
      return workInfoStore.base_info.resign_date;
    } else if (logType === "전환") {
      return workInfoStore.base_info.work_trans_from_date;
    } else {
      return "";
    }
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <SubTitle titleText="직원 정보" />
        </div>
      </div>

      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          labelText="본부"
          defaultValue={workInfoStore.base_info.hq_info.hq_name}
          disabled
        />

        <TextField
          marginLeft={compMarginLeft}
          labelWidth={labelWidth}
          textBoxWidth="9.25rem"
          labelText="지점"
          defaultValue={workInfoStore.base_info.branch_info.branch_name}
          disabled
        />

        <CheckBox
          marginTop="0rem"
          marginRight="0.25rem"
          labelText="본부직원"
          defaultValue={workInfoStore.is_hq_staff}
          disabled={true}
        />
        {/* </div> */}

        <div style={{ display: "flex", marginLeft: "0.75rem" }}>
          <DatePicker
            marginRight="0.625rem"
            labelWidth={labelWidth}
            labelText={logType + "일"}
            minWidth={compWidth}
            textMarginRight="0rem"
            defaultValue={getDateInfo()}
            disabled={true}
          />
        </div>
      </div>

      {/* lane 2 */}
      <div style={{ display: "flex", marginTop: "1rem" }}>
        <div style={{ display: "flex" }}>
          <TextField
            labelWidth={labelWidth}
            textBoxWidth={compWidth}
            labelText="부서"
            defaultValue={workInfoStore.base_info.department.org_name}
            disabled
          />
          <TextField
            marginLeft={compMarginLeft}
            labelWidth={labelWidth}
            textBoxWidth={compWidth}
            labelText="직위"
            defaultValue={workInfoStore.base_info.rank.org_name}
            disabled
          />
          <TextField
            marginLeft={compMarginLeft}
            labelWidth={labelWidth}
            textBoxWidth={compWidth}
            labelText="직책"
            defaultValue={workInfoStore.base_info.position.org_name}
            disabled
          />
        </div>
      </div>

      {/* lane 3 */}
      <div style={{ display: "flex", marginTop: "1rem" }}>
        <div style={{}}>
          <TextField
            labelWidth={labelWidth}
            textBoxWidth="57.5rem"
            labelText="특이사항"
            defaultValue={workInfoStore.remark}
            onBlur={(e) => {
              workInfoStore.remark = e.target.value;
            }}
            disabled={true}
          />
        </div>
      </div>
    </div>
  );
};
export default WorkInfoBaseView;
