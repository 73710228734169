import { contentTypeJson, dateToStr, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import DatePicker from "components/LabelInput/DatePicker";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { loadUnPayViewStore } from "../Module/actions";
import SelectStaffModal from "./SelectStaffModal";

const DueDateChangeModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const viewModelRef = useRef();
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });

  const [changeInfo, setChangeInfo] = useState({
    staff: "",
    date: "",
    reason: "",
  });
  const [staffList, setStaffList] = useState([]);
  const [loading, setLoading] = useState(false);

  const onLocalModalDone = () => {
    viewModelRef.current.setClose();
  };

  const getDueDateSelector = async () => {
    try {
      setLoading(true);

      const res = await HttpInstance.get(
        process.env.REACT_APP_USER_SERVER + "/change/due_date/selector",
        {
          params: {
            branch_id: modalParam.branch_id,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setStaffList(res.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDueDateSelector();
  }, [modalParam]);

  const popSelectStaffModal = () => {
    const param = {
      title: "담당자 선택",
      data: {
        branch_id: modalParam.branch_id,
        staffList: staffList,
        setChangeInfo: setChangeInfo,
      },
    };
    setModalInfo({
      show: true,
      title: "담당자 선택",
      Content: (
        <SelectStaffModal
          modalSelect={param.title}
          modalParam={param}
          onModalDone={onLocalModalDone}
        />
      ),
      callback: null,
      Buttons: undefined,
    });
  };

  const changePaymentDay = async () => {
    try {
      const formData = new FormData();
      const newInfo = {
        due_date: changeInfo.date,
        member_id: modalParam.member_id,
        purchase_code: modalParam.purchase_code,
        reason: changeInfo.reason,
      };

      if (!changeInfo.date) {
        //알림 => 날짜를 입력해주세영
        simpleAlert(storesDispatch, "알림", "날짜를 입력해주세요.");
      } else {
        formData.append("new_info", JSON.stringify(newInfo));
        setLoading(true);

        const res = await HttpInstance.post(
          process.env.REACT_APP_USER_SERVER + "/change/due_date/update",
          formData,
          contentTypeJson,
        );

        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          //변경되었습니다 팝업 => 미납현황리스트에 업데이트

          onModalDone();
          storesDispatch(loadUnPayViewStore(modalParam.member_id));
        }
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <Loading />}

      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
      <div style={{ display: "flex", gap: "0.3rem" }}>
        <TextField
          labelWidth={"3.2rem"}
          labelText="담당자"
          fullWidth
          disabled
          defaultValue={changeInfo.staff ? changeInfo.staff.personal_info.staff_name : ""}
        />
        <NormalBtn
          name="검색"
          onClick={() => {
            popSelectStaffModal();
          }}
        />
      </div>

      <DatePicker
        marginTop="0.5rem"
        labelWidth={"3.05rem"}
        labelText="날짜"
        fullWidth
        minDate={dateToStr(moment(Date.now()))}
        onChangeCallback={(e) => {
          setChangeInfo((cur) => {
            return { ...cur, date: e };
          });
        }}
      />
      <TextField
        marginTop="0.5rem"
        labelWidth={"3.05rem"}
        labelText="사유"
        fullWidth
        onChangeCallback={(e) => {
          setChangeInfo((cur) => {
            return { ...cur, reason: e };
          });
        }}
      />

      <div style={{ marginTop: "1.5rem", textAlign: "center" }}>
        <NormalBtn name="변경" onClick={() => changePaymentDay()} />
      </div>
    </div>
  );
};
export default DueDateChangeModal;
