import { useEffect, useState } from "react";

import { btnStyles } from "components/ButtonStyle/ButtonStyle";

import * as lodash from "lodash";
import PaymentModal from "pages/ERP/MemberCommonItems/PaymentModal/PaymentModal";

const AssignModalIndex = ({ modalSelect, modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();

  const [paramData, setParamData] = useState(modalParam.modalParam);

  useEffect(() => {
    const editData = lodash.cloneDeep(modalParam.modalParam);
    setParamData(editData);
    return () => {};
  }, []);

  if (modalSelect === "결제") {
    return <PaymentModal modalParam={paramData} onModalDone={onModalDone} />;
  } else {
    return <div>current ELSE</div>;
  }
};
export default AssignModalIndex;
