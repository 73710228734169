import { contentTypeJson, getPermission, simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import { searchMemberParamInit } from "pages/CommonModal/Search/Module/reducers/member_search_param";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import AssignNewbie from "./AssignNewbie";
import AssigneeTable from "./SearchMemberTable/AssigneeTable";

// 양수자 검색
const SearchSubject = ({ modalParam, onModalDone }) => {
  const [searchParam, setSearchParam] = useState(_.cloneDeep(searchMemberParamInit));
  const [hqIdx, setHqIdx] = useState(0);
  const [curPage, setCurPage] = useState();
  const [totalPage, setTotalPage] = useState(1);
  const [searchResult, setSearchResult] = useState([]);

  const storesDispatch = useDispatch();
  const labelWidth = "4.5rem";
  const compWidth = "12rem";
  const [hqBranchData, setHqBranchData] = useState({
    branch_list: [[]],
    hq_list: [],
    target_list: [],
  });
  const [phone, setPhone] = useState({
    p1: "",
    p2: "",
    p3: "",
  });
  const phoneRef1 = useRef();
  const phoneRef2 = useRef();
  const phoneRef3 = useRef();

  const viewModelRef = useRef();

  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getHqBranchCombo();
  }, [modalParam]);

  // 전화번호 입력 이벤트 (모든 칸)
  const onInputPhone = () => {
    let res = "";

    if (phone.p1 && !phone.p2 && phone.p3) {
      res = "err";
    } else {
      res = phone.p1 + "-" + phone.p2 + "-" + phone.p3;
    }
    res = res.replace("--", "-");

    setSearchParam({
      ...searchParam,
      base_info: {
        phone: res,
      },
    });
  };

  // useEffect(() => {
  //   if (curPage) {
  //     console.log("useEffect 검색");
  //     onClickSearch(curPage);
  //   }
  // }, [curPage]);

  // useEffect(() => {
  //   storesDispatch(loadSearchMemberParamStore());
  //   return () => {
  //     storesDispatch(searchModalAction.loadSearchMemberParamReset());
  //   };
  // }, [loadSearchMemberParamStore, storesDispatch]);

  // useEffect(() => {
  //   storesDispatch(assignSelectAction.clearAssignParamAllAct());
  // }, []);

  const getHqBranchCombo = async () => {
    const result = await getPermission(
      storesDispatch,
      "MEMBER_VIEW_MENU/MANAGE",
      true,
      false,
      true,
    );
    setHqBranchData(result);

    if (result.target_list.length > 0) {
      searchParam.hq_info = result.target_list[0].hq_info;
    }
  };

  //검색버튼 누르면 searchParam으로 검색요청
  const onClickSearch = async (page) => {
    if (searchParam.base_info.phone === "err") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="전화번호 검색 조건이 잘못되었습니다." />,
          onBtnOk: () => {},
        }),
      );
      return;
    }

    if (!searchParam.hq_info.hq_name) {
      //기본값 세팅
      searchParam.hq_info = hqBranchData.target_list[0].hq_info;
      searchParam.branch_info = hqBranchData.target_list[0].branch_list[0];
    }

    try {
      // searchParam.member_type = "회원";
      setCurPage(page);

      const formData = new FormData();
      formData.append("page_num", page);
      formData.append("search_param", JSON.stringify(searchParam));

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_USER_SERVER + "/member/search_modal",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        setSearchResult(res.data.data);
        setTotalPage(res.data.total_page);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const newbieModalDone = (data) => {
    if (data === "cancel") {
      viewModelRef.current.setClose();
    } else {
      onModalDone(data);
    }
  };

  //양수자 신규등록 모달 POP
  const popRegisterNewMemberModal = () => {
    const param = {
      assigner: modalParam.assigner,
    };

    setModalInfo({
      show: true,
      title: "양수자 신규등록",
      Content: <AssignNewbie modalParam={param} onModalDone={newbieModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  return (
    <div
      style={{
        width: "55.5rem",
      }}
    >
      {loading && <Loading />}

      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
      <div
        style={{ display: "flex" }}
        onKeyDown={(e) => {
          if (e.key === "Enter") onClickSearch(1);
        }}
      >
        <ComboBox
          labelWidth={labelWidth}
          comboItemWidth={compWidth}
          labelText="본부"
          data={hqBranchData.hq_list}
          defaultValue={hqBranchData.hq_list[hqIdx]}
          onChangeCallback={(e) => {
            const hIdx = hqBranchData.hq_list.findIndex((el) => el === e.target.value);
            if (hIdx !== -1) {
              setHqIdx(hIdx);
              setSearchParam({
                ...searchParam,
                hq_info: hqBranchData.target_list[hIdx].hq_info,
                branch_info: { branch_id: "", branch_name: "" },
              });
              // setSearchParam((cur) => {
              //   const hq_info = hqBranchData.target_list[hIdx].hq_info;
              //   const branch_info = { branch_id: "", branch_name: "" };
              //   return { ...cur, hq_info, branch_info };
              // });
            }
          }}
        />
        <ComboBox
          marginLeft="1.5rem"
          labelWidth={labelWidth}
          comboItemWidth={compWidth}
          labelText="지점"
          data={hqBranchData.branch_list[hqIdx]}
          defaultValue={hqBranchData.branch_list[hqIdx][0]}
          onChangeCallback={(e) => {
            const bIdx = hqBranchData.branch_list[hqIdx].findIndex((el) => el === e.target.value);

            if (bIdx !== -1) {
              searchParam.branch_info = hqBranchData.target_list[hqIdx].branch_list[bIdx];
              // setSearchParam({
              //   ...searchParam,
              //   branch_info: hqBranchData.target_list[hqIdx].branch_list[bIdx],
              // });
              // setSearchParam((cur) => {
              //   const branch_info = hqBranchData.target_list[hqIdx].branch_list[bIdx];
              //   return { ...cur, branch_info };
              // });
            }
          }}
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          marginLeft="1.5rem"
          labelText="회원번호"
          onChangeCallback={(e) => {
            const trimmedNo = e.trim();
            setSearchParam({
              ...searchParam,
              member_info: {
                member_name: searchParam.member_info.member_name,
                member_no: trimmedNo,
              },
            });
          }}
        />
      </div>
      <div style={{ display: "flex", marginTop: "1rem", justifyContent: "space-between" }}>
        <div
          style={{ display: "flex" }}
          onKeyDown={(e) => {
            if (e.key === "Enter") onClickSearch(1);
          }}
        >
          <TextField
            labelWidth={labelWidth}
            textBoxWidth={compWidth}
            labelText="이름"
            onChangeCallback={(e) => {
              setSearchParam({
                ...searchParam,
                member_info: {
                  member_name: e,
                  member_no: searchParam.member_info.member_no,
                },
              });
            }}
          />
          <TextField
            inputRef={phoneRef1}
            labelWidth={labelWidth}
            textBoxWidth={"5.5rem"}
            textMarginRight="0.25rem"
            marginLeft="1.5rem"
            labelText="연락처"
            maxLength={3}
            onChangeCallback={(e) => {
              phone.p1 = e;
              onInputPhone();
              if (phone.p1.length === 3) {
                phoneRef2.current.select();
              }
            }}
          />
          <TextField
            inputRef={phoneRef2}
            textMarginRight="0.25rem"
            labelMarginRight="0.25rem"
            labelWidth="0.5rem"
            labelText="-"
            maxLength={4}
            textBoxWidth={"5.5rem"}
            onChangeCallback={(e) => {
              phone.p2 = e;
              onInputPhone();
              if (phone.p2.length === 4) {
                phoneRef3.current.select();
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Backspace" && !phone.p2) {
                phoneRef1.current.select();
              }
            }}
          />
          <TextField
            inputRef={phoneRef3}
            labelMarginRight="0.25rem"
            labelWidth="0.5rem"
            labelText="-"
            maxLength={4}
            textBoxWidth={"5.5rem"}
            onChangeCallback={(e) => {
              phone.p3 = e;
              onInputPhone();
            }}
            onKeyDown={(e) => {
              if (e.key === "Backspace" && !phone.p3) {
                phoneRef2.current.select();
              }
            }}
          />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end", gap: "0.5rem" }}>
        <NormalBtn name="검색" onClick={() => onClickSearch(1)} />
        <NormalBtn
          name="신규등록"
          theme="white"
          onClick={() => {
            popRegisterNewMemberModal();
          }}
        />
      </div>
      <div style={{ marginTop: "1rem" }}>
        <AssigneeTable
          onModalDone={onModalDone}
          searchParam={searchParam}
          onClickSearch={onClickSearch}
          searchResult={searchResult}
          totalPage={totalPage}
          curPage={curPage}
          setCurPage={setCurPage}
          assigner={modalParam.assigner}
        />
      </div>
    </div>
  );
};
export default SearchSubject;
