import {
  loadUnPayView,
  loadUnPayViewSuccess,
  loadUnPayViewFail,
  loadUnPayViewReset,
} from "./view_act";

export const loadUnPayViewStore = (member_id) => loadUnPayView(member_id);

export const unPayViewAction = {
  loadUnPayViewSuccess,
  loadUnPayViewFail,
  loadUnPayViewReset,
};
