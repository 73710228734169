import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, getDocType } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import { useNavigate } from "react-router-dom";
import { useApprovalListStore } from "../modules/store/approvalListState";

const SearchResultArea = ({
  searchResult,
  totalPage,
  onClickSearch,
  onClickFavorite,
  onClickNewDraft,
  tabType,
}) => {
  const navigate = useNavigate();
  const btnStyleClass = btnStyles();
  // 전역 상태 관리
  const { page } = useApprovalListStore().returnStoreByType(tabType);

  const searchResultList = new resultTable(navigate, tabType);

  const doSearch = (page) => {
    onClickSearch(page);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          float: "right",
          marginLeft: "auto",
          marginBottom: "0.625rem",
        }}
      >
        <Button
          className={clsx({
            [btnStyleClass.btnRootWhite]: true,
            [btnStyleClass.buttonType9]: true,
          })}
          onClick={() => {
            onClickFavorite();
          }}
          style={{ marginLeft: "0.5rem" }}
        >
          자주 쓰는 결재선
        </Button>
      </div>

      <CustomTable
        columns_head={searchResultList.getTabHeader(tabType)}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
      />

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div style={{ width: "100%" }}>
          <CustomPagination curPage={page} totalCnt={totalPage} doSearch={doSearch} />
        </div>

        <div style={{ position: "absolute", marginTop: "1rem" }}>
          <Button
            className={clsx({
              [btnStyleClass.btnRoot]: true,
              [btnStyleClass.buttonType7]: true,
            })}
            onClick={() => {
              onClickNewDraft();
            }}
          >
            신규 기안
          </Button>
        </div>
      </div>
    </div>
  );
};
export default SearchResultArea;

//

class resultTable {
  table_title = "";
  onNavigate = null;
  tabType = null;

  constructor(navigate, tabType) {
    this.onNavigate = navigate;
    this.tabType = tabType;
  }

  onClickRow = (data) => {
    const mode = this.tabType === "my_approval" ? "approver" : "view";
    const docType = getDocType(data.doc_info.doc_name);

    this.onNavigate("/approval_info/", {
      state: {
        approval_id: data.approval_docs_id,
        branch_info: data.writer.branch_info,
        mode: mode,
        docType: docType,
      },
    });
  };

  onClickDelete = (data) => {};

  getCurApprover = (approverList) => {
    if (approverList) {
      for (let i = 0; i < approverList.length; i++) {
        if (!approverList[i].is_approve) {
          return approverList[i].staff_info.staff_name;
        }
      }
    }
    return "";
  };

  getViewers = (viewerList) => {
    let viewerStr = "";
    if (viewerList) {
      for (let i = 0; i < viewerList.length; i++) {
        viewerStr += viewerList[i].staff_info.staff_name;
        if (i < viewerList.length - 1) {
          viewerStr += ", ";
        }
      }
    }

    return viewerStr;
  };

  create_table = (data, idx) => {
    return (
      <CustomRow
        style={{}}
        key={idx}
        onClick={(e) => {
          if (e.target.nodeName === "DIV" || e.target.nodeName === "TD") {
            this.onClickRow(data);
          }
        }}
        hover
      >
        {this.tabType === "received" ? (
          <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        ) : (
          <></>
        )}
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.approval_status}</CustomTableContents>
        <CustomTableContents>{data.doc_info.doc_type}</CustomTableContents>
        <CustomTableContents>{data.doc_title}</CustomTableContents>
        <CustomTableContents>{data.writer.hq_info.hq_name}</CustomTableContents>
        <CustomTableContents>{data.writer.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.writer.staff_info.staff_name}</CustomTableContents>
        <CustomTableContents>
          {this.getCurApprover(data.approval_draft_line.approver)}
        </CustomTableContents>
        <CustomTableContents>
          {this.getViewers(data.approval_draft_line.viewer)}
        </CustomTableContents>
      </CustomRow>
    );
  };

  getTabHeader = (tabType) => {
    if (tabType === "received") {
      return [
        { title: "수신일시", width: "7%" },
        { title: "기안일시", width: "8%" },
        { title: "상태", width: "6%" },
        { title: "서류명", width: "10%" },
        { title: "제목", width: "23%" },
        { title: "본부", width: "10%" },
        { title: "지점", width: "10%" },
        { title: "기안자", width: "8%" },
        { title: "현재 결재자", width: "8%" },
        { title: "열람자", width: "10%" },
      ];
    } else {
      return [
        { title: "기안일시", width: "7%" },
        { title: "상태", width: "6%" },
        { title: "서류명", width: "10%" },
        { title: "제목", width: "23%" },
        { title: "본부", width: "10%" },
        { title: "지점", width: "10%" },
        { title: "기안자", width: "8%" },
        { title: "현재 결재자", width: "8%" },
        { title: "열람자", width: "10%" },
      ];
    }
  };

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
