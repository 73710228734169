import { generateObjectZeroId } from "components/CommonLib/CommonLib";

export const access_method_app_init_state = {
  access_method: "앱",
  branch_info: {
    branch_id: generateObjectZeroId(),
    branch_name: "",
  },
  card_info_id: generateObjectZeroId(),
  card_no: 0,
};
