import { contentTypeJson } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

// ==================== GET ====================
// [GET] 수입/지출 내역(월별) 검색 조건 받아오기 및 권한 데이터 세팅
export async function getCashBookMonthlySearchParam() {
  return await HttpInstance.get(
    process.env.REACT_APP_SALARY_SERVER + "/ledger/monthly/search_param",
    {
      params: {},
    },
  );
}

// [GET] 수입/지출 내역(월별) 리스트 검색
export async function getCashBookMonthlyList(queryData) {
  const { page, searchParams } = queryData;
  const formData = new FormData();

  formData.append("search_param", JSON.stringify(searchParams));
  formData.append("page_num", page);

  return await HttpInstance.post(
    process.env.REACT_APP_SALARY_SERVER + "/cashbook/search/main",
    formData,
    contentTypeJson,
  );
}

// [GET] 수입/지출 내역(월별) 집계 내역
export async function getCashBookAggregate(queryData) {
  const reportInfoId = queryData;

  return await HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + "/cashbook/aggregate", {
    params: {
      cashbook_id: reportInfoId,
    },
  });
}

// ==================== PATCH ====================
