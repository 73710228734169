import { contentTypeJson, simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useState } from "react";
import { useDispatch } from "react-redux";

const AddOtServiceSessionModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const labelWidth = "8rem";

  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const validationCheck = () => {
    if (count <= 0) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="세션을 입력해주세요." />
            </>
          ),

          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="저장 하시겠습니까?" />
            </>
          ),

          onBtnYes: () => {
            if (modalParam.mode === "ot") {
              addSession("/ot/session/service/update");
            } else {
              addSession("/pt/session/service/update");
            }
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const addSession = async (url) => {
    try {
      const formData = new FormData();
      const info = {
        add_session: count,
        class_detail_id:
          modalParam.mode === "ot" ? modalParam.data.ot_detail_id : modalParam.data.pt_detail_id,
        item_name:
          modalParam.mode === "ot"
            ? modalParam.data.membership_info.item_name
            : modalParam.data.pt_info.item_name,
        staff_info: modalParam.data.cur_manager.staff_info,
      };
      formData.append("new_info", JSON.stringify(info));
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_STAFF_SERVER + url,
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        onModalDone();
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div style={{ width: "26.5rem" }}>
      <div>
        {loading && <Loading />}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "0.5rem",
          }}
        >
          <TextField
            labelWidth={labelWidth}
            fullWidth
            labelText={modalParam.mode === "ot" ? "회원권 상품명" : "PT 계약 상품명"}
            defaultValue={
              modalParam.mode === "ot"
                ? modalParam.data.membership_info.item_name
                : modalParam.data.pt_info.item_name
            }
            disabled
          />
          <Label
            labelText="※ 수강 상태인 회원권에만 추가됩니다."
            fontSize="0.725rem"
            color="red"
            marginTop="0.5rem"
            marginLeft="-1.25rem"
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <TextField
            labelWidth={labelWidth}
            fullWidth
            labelText="추가 세션"
            endAdornment={"회"}
            isNumber
            onChangeCallback={(e) => {
              setCount(Number(e));
            }}
          />
        </div>

        <div
          style={{
            marginTop: "0.5rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextField
            labelWidth={labelWidth}
            fullWidth
            labelText="수업 담당자"
            defaultValue={modalParam.data.cur_manager.staff_info.staff_name}
            disabled
          />
          <Label
            labelText="※ 담당자 변경은 수업운영 메뉴에서 가능합니다."
            fontSize="0.725rem"
            color="red"
            marginTop="0.5rem"
            marginLeft="1.25rem"
          />
        </div>
      </div>
      <div
        style={{
          marginTop: "1.5rem",
          display: "flex",
          justifyContent: "center",
          gap: "0.5rem",
        }}
      >
        <NormalBtn name="저장" onClick={validationCheck} />
        <NormalBtn
          theme="white"
          name="취소"
          onClick={() => {
            onModalDone(false);
          }}
        />
      </div>
    </div>
  );
};

export default AddOtServiceSessionModal;
