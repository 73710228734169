import { takeLatest } from "redux-saga/effects";

import {
  CardDetailInfoAction,
  CardListAction,
  CardSearchAction,
} from "pages/AccessManage/CardManage/Module/actions/action_type";
import {
  getCardInfoData,
  getCardListData,
  getSearchCardListData,
} from "pages/AccessManage/CardManage/Module/sagas/view_sagas";

import {
  DeviceListAction,
  DeviceScheduleListAction,
  HolyDayInfoAction,
  SchedulePreSetInfoAction,
} from "pages/AccessManage/DeviceManage/Module/actions/action_type";
import {
  getDeviceListData,
  getDeviceScheduleListData,
  getScheduleHolyDayData,
  getSchedulePreSetInfoData,
} from "pages/AccessManage/DeviceManage/Module/sagas/view_sagas";

export function* accessServiceSaga() {
  yield takeLatest(CardSearchAction, getSearchCardListData);
  yield takeLatest(CardDetailInfoAction, getCardInfoData);
  yield takeLatest(CardListAction, getCardListData);

  yield takeLatest(DeviceListAction, getDeviceListData);
  yield takeLatest(DeviceScheduleListAction, getDeviceScheduleListData);
  yield takeLatest(SchedulePreSetInfoAction, getSchedulePreSetInfoData);
  yield takeLatest(HolyDayInfoAction, getScheduleHolyDayData);
}
