import { useQuery } from "@tanstack/react-query";
import { getModalSearchMember } from "apis/member/search_member_api";
import {
  generateObjectZeroId,
  getDefaultValue,
  getPermission,
  restEmpty,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import AddFamilyModal from "pages/ERP/MemberView/Modal/AddFamilyModal";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { loadSearchMemberParamStore } from "./Module/actions";
import { searchMemberParamInit } from "./Module/reducers/member_search_param";
import AssignTable from "./SearchMemberTable/AssignTable";
import FamilyTable from "./SearchMemberTable/FamilyTable";
import MultipleTable from "./SearchMemberTable/MultipleTable";
import NormalTable from "./SearchMemberTable/NormalTable";

const SearchMember = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const viewModelRef = useRef();
  const tableRef = useRef();
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });
  const [searchParam, setSearchParam] = useState(_.cloneDeep(searchMemberParamInit));
  const [curPage, setCurPage] = useState();
  const [totalPage, setTotalPage] = useState(1);
  const [hqIdx, setHqIdx] = useState(0);
  const [searchResult, setSearchResult] = useState([]);
  const [phone, setPhone] = useState({
    p1: "",
    p2: "",
    p3: "",
  });

  const [selectedMember, setSelectedMember] = useState([]);
  const [hqBranchData, setHqBranchData] = useState({
    branch_list: [[]],
    hq_list: [],
    target_list: [],
  });
  const phoneRef1 = useRef();
  const phoneRef2 = useRef();
  const phoneRef3 = useRef();

  // API 상태 관리
  const [isGetMemberDataList, setIsGetMemberDataList] = useState(false);
  const queryData = { page: curPage, searchParam: searchParam };

  // [GET] 회원 검색 결과
  const { isFetching, data: memberDataList } = useQuery({
    queryKey: ["searchMemberModal", queryData],
    queryFn: () => getModalSearchMember(queryData),
    enabled: isGetMemberDataList,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setIsGetMemberDataList(false);
    },
  });

  const preProcessResult = (data) => {
    if (modalParam.mode !== "multiple") return;

    for (let i = 0; i < data.length; i++) {
      if (
        selectedMember.findIndex(
          (x) => x.member_info.member_id === data[i].member_info.member_id,
        ) !== -1
      ) {
        data[i].is_checked = true;
      } else {
        data[i].is_checked = false;
      }
    }
  };

  const validationFriendSearch = () => {
    if (
      !searchParam.member_info.member_name &&
      !searchParam.member_info.member_no &&
      !searchParam.base_info.phone &&
      !searchParam.access.card_no
    ) {
      return false;
    }
    return true;
  };

  //검색버튼 누르면 searchParam으로 검색요청
  // isInit : 페이지네이션이 아니라 검색버튼으로 검색됐을 때
  const onClickSearch = async (page, isInit) => {
    if (modalParam.mode === "friend" && !validationFriendSearch()) {
      simpleAlert(storesDispatch, "알림", "최소 하나 이상의 검색 조건을 입력해 주세요");
      return;
    }

    if (isInit && modalParam.mode === "multiple") {
      tableRef.current.resetList();
    }
    if (modalParam.mode === "friend") {
      searchParam.member_type = "회원";
    }
    // 전화번호 중간 자리 미입력 시 alert 띄움
    if (searchParam.base_info.phone === "err") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="전화번호 검색 조건이 잘못되었습니다." />,
          onBtnOk: () => {},
        }),
      );
      return;
    }

    setCurPage(page);
    // 회원 검색
    setIsGetMemberDataList(true);
  };

  const getAllHqBranchCombo = async () => {
    const result = await getPermission(storesDispatch, "HQ_BRANCH/ALL", true, false, false);
    setHqBranchData(result);
    if (result.target_list.length > 0) {
      searchParam.hq_info = result.target_list[0].hq_info;
      if (result.target_list[0].branch_list.length > 0) {
        searchParam.branch_info = result.target_list[0].branch_list[0];
      }
    }
  };

  // API 받아온 데이터 세팅
  useEffect(() => {
    // 회원 검색 데이터
    if (memberDataList) {
      preProcessResult(memberDataList.data.data);
      setSearchResult(memberDataList.data.data);
      setTotalPage(memberDataList.data.total_page);
    }
  }, [memberDataList]);

  useEffect(() => {
    if (modalParam.mode === "friend") {
      getAllHqBranchCombo();
    } else {
      if (modalParam.isFixedBranch) {
        // 본부, 지점 고정이 필요한 경우 미리 modalParam에 넘겨줘야 함
        searchParam.hq_info = modalParam.hqBranchData.hq_info;
        searchParam.branch_info = modalParam.hqBranchData.branch_info;
        searchParam.member_type = "회원";
        setHqBranchData({
          branch_list: [[searchParam.branch_info.branch_name]],
          hq_list: [searchParam.hq_info.hq_name],
        });
      } else {
        HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member/search_member_view", {
          params: {},
        })
          .then((res) => {
            if (!restEmpty(res.data.data)) {
              const result = res.data.data;
              if (!result.hq_list) {
                result.hq_list = [];
                setHqBranchData(result);
              } else {
                setHqBranchData(result);
                searchParam.hq_info = result.target_list[hqIdx].hq_info;
                searchParam.member_type = "회원";
              }
            }
          })
          .catch((err) => {
            console.log(err);
            simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
          });
      }
    }
  }, [loadSearchMemberParamStore, storesDispatch]);

  const labelWidth = "4.5rem";
  const compWidth = "12rem";

  const localModalDone = () => {
    viewModelRef.current.setClose();
  };

  const addFamilyModal = (title, param, localModalDone) => {
    setModalInfo({
      show: true,
      title: title,
      Content: <AddFamilyModal modalParam={param} onModalDone={localModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const popUpAddFamilyModal = (data) => {
    addFamilyModal(
      "가족 추가",
      {
        data,
        memberId: modalParam.memberId,
      },
      localModalDone,
    );
  };

  // 전화번호 입력 이벤트 (모든 칸)
  const onInputPhone = () => {
    let res = "";

    if (phone.p1 && !phone.p2 && phone.p3) {
      res = "err";
    } else {
      res = phone.p1 + "-" + phone.p2 + "-" + phone.p3;
    }
    res = res.replace("--", "-");

    setSearchParam({
      ...searchParam,
      base_info: {
        phone: res,
      },
    });
  };

  return (
    <div
      style={{
        width: "55.5rem",
      }}
    >
      {isFetching && <Loading />}

      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
      <div
        style={{ display: "flex" }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onClickSearch(1, true);
          }
        }}
      >
        <ComboBox
          labelWidth={labelWidth}
          comboItemWidth={compWidth}
          labelText="본부"
          data={hqBranchData.hq_list}
          defaultValue={hqBranchData.hq_list.length !== 0 && hqBranchData.hq_list[hqIdx]}
          onChangeCallback={(e) => {
            const index = hqBranchData.hq_list.findIndex((el) => el === e.target.value);
            if (index !== -1) {
              setHqIdx(index);
              let tBranchName = hqBranchData.target_list[index].branch_list[0].branch_name;
              let tBranchId = hqBranchData.target_list[index].branch_list[0].branch_id;
              if (tBranchName === "전체") {
                tBranchId = generateObjectZeroId();
              }
              setSearchParam({
                ...searchParam,
                hq_info: hqBranchData.target_list[index].hq_info,
                branch_info: hqBranchData.target_list[index].branch_list[0],
              });
            }
          }}
        />
        <SearchComboBox
          marginLeft="1.5rem"
          labelWidth={labelWidth}
          comboItemWidth={compWidth}
          labelText="지점"
          data={hqBranchData.branch_list[hqIdx]}
          defaultValue={getDefaultValue("combo", searchParam.branch_info.branch_name)}
          onChangeCallback={(e, v) => {
            const index = hqBranchData.branch_list[hqIdx].findIndex((el) => el === v);

            if (index !== -1) {
              let tBranchId = hqBranchData.target_list[hqIdx].branch_list[index].branch_id;
              let tBranchName = hqBranchData.target_list[hqIdx].branch_list[index].branch_name;
              if (v === "전체") {
                tBranchId = generateObjectZeroId();
              }
              setSearchParam({
                ...searchParam,
                branch_info: {
                  branch_id: tBranchId,
                  branch_name: tBranchName,
                },
              });
            }
          }}
        />

        {modalParam.mode === "friend" ? (
          <TextField
            marginLeft="1.5rem"
            labelWidth={labelWidth}
            comboItemWidth={compWidth}
            labelText="회원구분"
            disabled
            defaultValue="회원"
          />
        ) : modalParam.mode !== "assign" ? (
          <ComboBox
            marginLeft="1.5rem"
            labelWidth={labelWidth}
            comboItemWidth={compWidth}
            labelText="회원구분"
            data={["회원", "고객"]}
            defaultValue="회원"
            onChangeCallback={(e) => {
              setSearchParam({
                ...searchParam,
                member_type: e.target.value,
              });
            }}
          />
        ) : (
          ""
        )}
      </div>
      <div style={{ display: "flex", marginTop: "0.7rem" }}>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          labelText="이름"
          onChangeCallback={(e) => {
            setSearchParam({
              ...searchParam,
              member_info: {
                member_name: e,
                member_no: searchParam.member_info.member_no,
              },
            });
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") onClickSearch(1, true);
          }}
        />

        <TextField
          inputRef={phoneRef1}
          labelWidth={labelWidth}
          textBoxWidth={"5.5rem"}
          textMarginRight="0.25rem"
          marginLeft="1.5rem"
          labelText="연락처"
          maxLength={3}
          isStringNumber
          onChangeCallback={(e) => {
            phone.p1 = e;
            onInputPhone();
            if (phone.p1.length === 3) {
              phoneRef2.current.select();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") onClickSearch(1, true);
          }}
        />
        <TextField
          inputRef={phoneRef2}
          textMarginRight="0.25rem"
          labelMarginRight="0.25rem"
          labelWidth="0.5rem"
          labelText="-"
          maxLength={4}
          textBoxWidth={"5.5rem"}
          isStringNumber
          onChangeCallback={(e) => {
            phone.p2 = e;
            onInputPhone();
            if (phone.p2.length === 4) {
              phoneRef3.current.select();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClickSearch(1, true);
            } else if (e.key === "Backspace" && !phone.p2) {
              phoneRef1.current.select();
            }
          }}
        />
        <TextField
          inputRef={phoneRef3}
          labelMarginRight="0.25rem"
          labelWidth="0.5rem"
          labelText="-"
          maxLength={4}
          textBoxWidth={"5.5rem"}
          isStringNumber
          onChangeCallback={(e) => {
            phone.p3 = e;
            onInputPhone();
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClickSearch(1, true);
            } else if (e.key === "Backspace" && !phone.p3) {
              phoneRef2.current.select();
            }
          }}
        />
      </div>

      <div style={{ display: "flex", marginTop: "0.7rem", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}>
          <TextField
            labelWidth={labelWidth}
            textBoxWidth={compWidth}
            labelText="회원번호"
            onChangeCallback={(e) => {
              const trimmedNo = e.trim();
              setSearchParam({
                ...searchParam,
                member_info: {
                  member_name: searchParam.member_info.member_name,
                  member_no: trimmedNo,
                },
              });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") onClickSearch(1, true);
            }}
          />
          <TextField
            labelWidth={labelWidth}
            textBoxWidth={compWidth}
            marginLeft="1.5rem"
            labelText="카드번호"
            isStringNumber
            onChangeCallback={(e) => {
              setSearchParam({
                ...searchParam,
                access: {
                  card_no: e,
                },
              });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") onClickSearch(1, true);
            }}
          />
        </div>

        <NormalBtn name="검색" onClick={() => onClickSearch(1, true)} />
      </div>

      <div style={{ marginTop: "1rem" }}>
        {modalParam.mode === "search" ? (
          <NormalTable
            onClickSearch={onClickSearch}
            modalParam={modalParam}
            onModalDone={onModalDone}
            searchParam={searchParam}
            totalPage={totalPage}
            curPage={curPage}
            setCurPage={setCurPage}
            searchResult={searchResult}
          />
        ) : modalParam.mode === "family" || modalParam.mode === "friend" ? (
          <FamilyTable
            onClickSearch={onClickSearch}
            modalParam={modalParam}
            onModalDone={onModalDone}
            searchParam={searchParam}
            totalPage={totalPage}
            curPage={curPage}
            searchResult={searchResult}
            popUpAddFamilyModal={popUpAddFamilyModal}
          />
        ) : modalParam.mode === "multiple" ? (
          <MultipleTable
            ref={tableRef}
            onClickSearch={onClickSearch}
            modalParam={modalParam}
            onModalDone={onModalDone}
            searchParam={searchParam}
            totalPage={totalPage}
            curPage={curPage}
            searchResult={searchResult}
            selectedMember={selectedMember}
            setSelectedMember={setSelectedMember}
          />
        ) : (
          <AssignTable
            onClickSearch={onClickSearch}
            onModalDone={onModalDone}
            searchParam={searchParam}
            totalPage={totalPage}
            curPage={curPage}
            setCurPage={setCurPage}
            searchResult={searchResult}
            navigate={modalParam.navigate}
          />
        )}
      </div>
    </div>
  );
};
export default SearchMember;
