import { Box, Button } from "@mui/material";
import TextFieldComp from "@mui/material/TextField";
import { ReactComponent as UploadIcon } from "assets/icon/Upload.svg";
import { ReactComponent as UploadSmallIcon } from "assets/icon/upload_small.svg";
import { useEffect, useState } from "react";

const FileUploadIcon = (props) => {
  const [value, setValue] = useState(props.defaultValue);

  useEffect(() => {
    if (props.defaultValue) {
      setValue(props.defaultValue);
    }
  }, [props.defaultValue]);

  const onChangeFile = (e) => {
    if (e.target.files.length > 0) {
      props.onUploadFile(e.target.files[0]);
    }
  };

  const hStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: props.justifyContent,
    marginTop: props.marginTop,
    marginRight: props.marginRight,
    marginLeft: props.marginLeft,
  };

  const labelStyle = {
    width: props.labelWidth,
    height: "2.25rem",
    marginRight: props.labelMarginRight === undefined ? "0.625rem" : props.labelMarginRight,
    marginLeft: props.labelMarginLeft,
    alignItems: "center",
    display: "flex",

    fontFamily: "AppleSDGothicNeo",
    fontSize: props.fontSize ? props.fontSize : "0.938rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
  };

  const textBoxStyleDisabled = {
    width: props.textBoxWidth,
    height: "2.25rem",
    marginRight: props.textMarginRight === undefined ? "0.25rem" : props.textMarginRight,
    borderRadius: "5px",
    backgroundColor: "#ebebeb",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.9rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
  };

  return (
    <>
      {props.onlyIcon ? (
        <Button
          sx={{
            "&:hover": { backgroundColor: "transparent" },
            padding: 0,
          }}
          onClick={() => {
            document.getElementById("fileInput" + props.idx).click();
          }}
        >
          {props.small ? <UploadSmallIcon width="2.2rem" /> : <UploadIcon width="2.2rem" />}

          <input id={"fileInput" + props.idx} hidden type="file" onChange={onChangeFile} />
        </Button>
      ) : (
        <Box style={props.style || hStyle}>
          <div style={labelStyle}>{props.labelText}</div>
          <TextFieldComp
            inputProps={{
              style: { textAlign: props.isNumber ? "right" : "left" },
              maxLength: props.maxLength,
            }}
            InputProps={{
              style: textBoxStyleDisabled,
            }}
            InputLabelProps={{ style: { fontSize: "0.8rem" } }}
            id={props.idx}
            disabled
            size="small"
            value={value || ""}
            name={props.name}
            error={props.required && (value === undefined || value === "")}
          />
          <Button
            sx={{
              "&:hover": { backgroundColor: "transparent" },
              padding: 0,
              transform: "translateX(-0.55rem)",
            }}
            onClick={() => {
              document.getElementById("fileInput" + props.idx).click();
            }}
          >
            {props.small ? <UploadSmallIcon width="2.2rem" /> : <UploadIcon width="2.2rem" />}

            <input id={"fileInput" + props.idx} hidden type="file" onChange={onChangeFile} />
          </Button>
        </Box>
      )}
    </>
  );
};

export default FileUploadIcon;
