import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import * as lodash from "lodash";
import SearchCard from "pages/CommonModal/Search/SearchCard";
import { useEffect, useState } from "react";
import ChangeCommissionSet from "../CommissionCard/ChangeCommissionSet";
import AddAccessModal from "./AddAccessModal";
import AddHqModal from "./AddHqModal";
import GxInfoModal from "./GxInfoModal";
import WorkInfoModal from "./WorkInfoModal";

const StaffInfoIndex = ({ modalSelect, modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();

  const [paramData, setParamData] = useState(modalParam.modalParam);

  useEffect(() => {
    const editData = lodash.cloneDeep(modalParam.modalParam);
    setParamData(editData);
    return () => {};
  }, []);

  if (modalSelect === "근로정보") {
    return <WorkInfoModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "GX정보") {
    return <GxInfoModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "소속정보") {
    return <AddHqModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "출입가능지점") {
    return <AddAccessModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "기본커미션설정") {
    return <ChangeCommissionSet modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "카드검색") {
    return <SearchCard modalParam={paramData} onModalDone={onModalDone} />;
  } else {
    return <div>current ELSE</div>;
  }
};
export default StaffInfoIndex;
