import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { getByte } from "components/CommonLib/CommonLib";
import ColorLabel from "components/LabelInput/ColorLabel";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";

const DraftLine = ({
  mode,
  onClickSelectDraftLine,
  approverList,
  baseState,
  favoriteLineList,
  setApproverList,
}) => {
  const btnStyleClass = btnStyles();
  const labelWidth = "4rem";
  const fullWidth = "69rem";

  const getStaffNamePos = (data) => {
    return data.staff_info.staff_name + " " + data.staff_info.staff_pos;
  };

  return (
    <>
      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <IconButton type="border" />
        <Label labelText="결재선" marginLeft="0.5rem" fontWeight="bold" />
      </div>

      <div style={{ display: "flex", marginTop: "0.5rem", width: "100%" }}>
        <div style={{ width: labelWidth, display: "flex" }}>
          <Label width={labelWidth} labelText="결재" justifyContent="left" />
        </div>
        <div style={boxStyle}>
          {approverList.approver_list.map((el) => (
            <ColorLabel
              width={((getByte(getStaffNamePos(el)) * 0.9) / 2 + 0.5).toString() + "rem"}
              labelText={getStaffNamePos(el)}
              bgColor="#222222"
              fontColor="#FFFFFF"
            />
          ))}
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <div style={{ width: labelWidth, display: "flex" }}>
          <Label width={labelWidth} labelText="열람" justifyContent="left" />
        </div>
        <div style={boxStyle}>
          {approverList.viewer_list.map((el) => (
            <ColorLabel
              width={((getByte(getStaffNamePos(el)) * 0.9) / 2 + 0.5).toString() + "rem"}
              labelText={getStaffNamePos(el)}
              bgColor="#222222"
              fontColor="#FFFFFF"
            />
          ))}
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <div style={{ width: labelWidth, display: "flex" }}>
          <Label width={labelWidth} labelText="수신" justifyContent="left" />
        </div>
        <div style={boxStyle}>
          {approverList.receiver_list.map((el) => (
            <ColorLabel
              width={((getByte(getStaffNamePos(el)) * 0.9) / 2 + 0.5).toString() + "rem"}
              labelText={getStaffNamePos(el)}
              bgColor="#222222"
              fontColor="#FFFFFF"
            />
          ))}
        </div>
      </div>

      {mode === "edit" || mode === "new" ? (
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem" }}>
          <ComboBox
            labelText="자주 쓰는 결재선"
            labelWidth={"7rem"}
            labelMarginRight="0rem"
            comboItemWidth={"12rem"}
            data={favoriteLineList.line_name_list || []}
            defaultValue={""}
            onChangeCallback={(e) => {
              const idx = favoriteLineList.line_info_list.findIndex(
                (x) => x.line_title === e.target.value,
              );
              if (idx !== -1) {
                baseState.approval_draft_line.approver =
                  favoriteLineList.line_info_list[idx].approver;
                baseState.approval_draft_line.receiver =
                  favoriteLineList.line_info_list[idx].receiver;
                baseState.approval_draft_line.viewer = favoriteLineList.line_info_list[idx].viewer;

                setApproverList({
                  receiver_list: favoriteLineList.line_info_list[idx].receiver,
                  approver_list: favoriteLineList.line_info_list[idx].approver,
                  viewer_list: favoriteLineList.line_info_list[idx].viewer,
                });
              }
            }}
          />

          <Button
            // marginRight
            style={{ marginLeft: "0.5rem" }}
            className={clsx({
              [btnStyleClass.btnRootRaw]: true,
              [btnStyleClass.buttonType6]: true,
            })}
            onClick={() => {
              setApproverList((cur) => {
                return { ...cur, receiver_list: [], approver_list: [], viewer_list: [] };
              });
              onClickSelectDraftLine();
            }}
          >
            직접 선택
          </Button>
          <Button
            // marginRight
            style={{ marginLeft: "0.5rem" }}
            className={clsx({
              [btnStyleClass.btnRootWhite]: true,
              [btnStyleClass.buttonType5]: true,
            })}
            onClick={() => {
              setApproverList((cur) => {
                return { ...cur, receiver_list: [], approver_list: [], viewer_list: [] };
              });
            }}
          >
            초기화
          </Button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default DraftLine;

const boxStyle = {
  marginTop: "0.5rem",
  width: "100%",
  minHeight: "2.5rem",
  borderRadius: "5px",
  border: "1px solid #BBBBBB",
  backgroundColor: "#ebebeb",
  fontFamily: "AppleSDGothicNeo",
  fontSize: "0.9rem",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#000",
  boxSizing: "border-box",
  padding: "0.2rem",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: "0.2rem",
};
