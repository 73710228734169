import {
  loadSearchMember,
  loadSearchMemberSuccess,
  loadSearchMemberFail,
  loadSearchMemberReset,
  loadSearchStaffItems,
  loadSearchStaffSuccess,
  loadSearchStaffFail,
  loadSearchStaffReset,
  loadSearchMemberParam,
  loadSearchMemberParamSuccess,
  loadSearchMemberParamFail,
  loadSearchMemberParamReset,
} from "./search_actions";

export const loadSearchMemberStore = (search_param) => loadSearchMember(search_param);
export const loadSearchStaffStore = (search_param) => loadSearchStaffItems(search_param);

export const loadSearchMemberParamStore = () => loadSearchMemberParam();

export const searchModalAction = {
  loadSearchMemberSuccess,
  loadSearchMemberFail,
  loadSearchMemberReset,

  loadSearchStaffSuccess,
  loadSearchStaffFail,
  loadSearchStaffReset,

  loadSearchMemberParamSuccess,
  loadSearchMemberParamFail,
  loadSearchMemberParamReset,
};
