import {
  loadPostponeView,
  loadPostponeViewSuccess,
  loadPostponeViewFail,
  loadPostponeViewReset,
} from "./view_act";

export const loadPostponeViewStore = (member_id, branch_id, membership_id) =>
  loadPostponeView(member_id, branch_id, membership_id);

export const postponeViewAction = {
  loadPostponeViewSuccess,
  loadPostponeViewFail,
  loadPostponeViewReset,
};
