import { Table, TableBody, TableHead, Tooltip } from "@mui/material";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Label from "components/LabelInput/Label";
import React from "react";
import { CustomRow, CustomTableCell } from "./tableStyle";

function CustomTableTypeA(props) {
  const header_list = props.columns_head.map((header, idx) => (
    <Tooltip
      key={`tooltip-${idx}`}
      title={header.tooltip ? header.tooltip : ""}
      arrow
      placement="top"
      enterDelay={600}
      disableHoverListener={header.tooltip ? false : true}
    >
      <CustomTableCell
        key={`header-${idx}`}
        rowSpan={header.row_span ? header.row_span : 0}
        colSpan={header.col_span ? header.col_span : 0}
        align="center"
        style={{
          backgroundColor: props.bgColor
            ? props.bgColor
            : header.bgColor
            ? header.bgColor
            : "#dfebf3",
          color: "#000",
          width: header.width,
          height: header.height ? header.height : "1.2rem",
          textAlign: header.align,
          paddingLeft: header.combineLeft ? 0 : "",
          paddingRight: header.combineRight ? 0 : "",
          borderRight: header.combineRight ? 0 : "",
          zIndex: 11,
        }}
      >
        {header.title}
      </CustomTableCell>
    </Tooltip>
  ));

  const header_list_2 =
    props.isDouble &&
    props.columns_head_2.map((header, idx) => (
      <CustomTableCell
        key={`header2-${idx}`}
        rowSpan={header.row_span ? header.row_span : 0}
        colSpan={header.col_span ? header.col_span : 0}
        align="center"
        style={{
          backgroundColor: props.bgColor ? props.bgColor : "#dfebf3",
          color: "#000",
          width: header.width,
          height: header.height ? header.height : "1.2rem",
          zIndex: 11,
        }}
      >
        {header.title}
      </CustomTableCell>
    ));

  const rest_get_data = props.rest_call(props.rest_data);

  const getNoDataHeight = () => {
    // if (props.height !== undefined) {
    //   return props.height;
    // }
    // if (props.minHeight !== undefined) {
    //   return props.minHeight;
    // }
    if (props.emptyHeight !== undefined) {
      return props.emptyHeight;
    }
    return "4rem";
  };

  return (
    <div
      style={{
        marginLeft: props.marginLeft ? props.marginLeft : "1px",
        marginRight: props.marginRight ? props.marginRight : "1px",
      }}
    >
      <TableContainer
        component={Paper}
        style={{
          minHeight: props.minHeight,
          maxHeight: props.maxHeight,
          overflow: props.overflow ? props.overflow : "auto",
          height: props.height,
          backgroundColor: "#f5f5f5",
          border: "1px solid #CCC",
          borderRadius: "0",
          boxShadow: "none",
        }}
      >
        <Table stickyHeader style={{ width: props.width }}>
          <TableHead>
            <CustomRow style={{ whiteSpace: "pre-wrap" }}>{header_list}</CustomRow>
          </TableHead>
          {props.isDouble ? (
            <TableHead>
              <CustomRow style={{ whiteSpace: "pre-wrap" }}>{header_list_2}</CustomRow>
            </TableHead>
          ) : (
            ""
          )}

          <TableBody>
            {rest_get_data.map((row, index) => (
              <React.Fragment key={`data-${index}`}>{props.row_render(row, index)}</React.Fragment>
            ))}
          </TableBody>
        </Table>
        {rest_get_data.length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: getNoDataHeight(),
            }}
          >
            <Label labelText="데이터가 없습니다." />
          </div>
        ) : (
          <></>
        )}
      </TableContainer>
    </div>
  );
}

export default CustomTableTypeA;
