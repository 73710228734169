// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/login/BG.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login_container {\n  width: 100vw;\n  height: 100vh;\n  margin: 0 auto;\n  box-sizing: border-box;\n}\n\n.login_bg {\n  width: 100%;\n  height: 100%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  position: relative;\n}\n\n.login_title {\n  position: absolute;\n  top: 267.2px;\n  left: 360px;\n  /* border: 2px solid pink; */\n}\n\n.login_form {\n  position: absolute;\n  margin-top: 29px;\n  top: 506px;\n  left: 360px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/LoginPage/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yDAAkD;EAClD,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,4BAA4B;AAC9B;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,UAAU;EACV,WAAW;AACb","sourcesContent":[".login_container {\n  width: 100vw;\n  height: 100vh;\n  margin: 0 auto;\n  box-sizing: border-box;\n}\n\n.login_bg {\n  width: 100%;\n  height: 100%;\n  background-image: url(\"../../assets/login/BG.png\");\n  background-size: cover;\n  position: relative;\n}\n\n.login_title {\n  position: absolute;\n  top: 267.2px;\n  left: 360px;\n  /* border: 2px solid pink; */\n}\n\n.login_form {\n  position: absolute;\n  margin-top: 29px;\n  top: 506px;\n  left: 360px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
