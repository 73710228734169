import { put, call } from "redux-saga/effects";
// import get_api from "../../../../../lib/LockerSettings/GetApi";
import get_api from "../api/GetApi";
import { classHistAction } from "../actions/index";
import { globalStartLoading, globalFinishLoading } from "modules/actions/Loading/index";

export function* getSearchPtClassHist(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.searchPtClassHist, param);

    if (data.data === null) {
      yield put(classHistAction.loadSearchPtClassHistSuccess(false));
    } else {
      yield put(classHistAction.loadSearchPtClassHistSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(classHistAction.loadSearchPtClassHistFail(error));
    yield put(globalFinishLoading());
  }
}

export function* getSearchOtClassHist(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.searchOtClassHist, param);

    if (data.data === null) {
      yield put(classHistAction.loadSearchOtClassHistSuccess(false));
    } else {
      yield put(classHistAction.loadSearchOtClassHistSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(classHistAction.loadSearchOtClassHistFail(error));
    yield put(globalFinishLoading());
  }
}
