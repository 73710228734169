import Label from "components/LabelInput/Label";

const GridTable = (props) => {
  const ratio = props.columns_head.map((el) => el.width).join(" ");

  const headerStyle = {
    display: "grid",
    gridTemplateColumns: ratio,
  };

  const textStyle = {
    fontFamily: "Pretendard",
    fontWeight: "600",
    fontSize: "0.9rem",
    lineHeight: "100%",
    color: "#A3A8B4",
    padding: " 0 0 0.4rem 0",
  };

  const rowItemStyle = {
    fontFamily: "Pretendard",
    fontWeight: "400",
    fontSize: "0.9rem",
    lineHeight: "100%",
    color: "#444444",
    boxSizing: "border-box",
  };

  return (
    <div style={{ margin: "0.2rem 0 0.5rem 0" }}>
      <div style={headerStyle}>
        {props.columns_head.map((el, idx) => (
          <div style={textStyle} key={idx}>
            {el.title}
          </div>
        ))}
      </div>
      {props.row_data.length !== 0 ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {props.row_data.map((row, index) => (
            <div style={rowItemStyle} key={index}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: ratio,
                  // padding: "0.5rem 0",
                  height: "2.5rem",
                  borderTop: "1px solid rgba(0, 0, 0, 0.15)",
                  borderRight: "1px solid rgba(0, 0, 0, 0.15)",
                  borderLeft: "1px solid rgba(0, 0, 0, 0.15)",
                  borderBottom:
                    index === props.row_data.length - 1 && "1px solid rgba(0, 0, 0, 0.15)",
                  borderTopLeftRadius: index === 0 && "10px",
                  borderTopRightRadius: index === 0 && "10px",
                  borderBottomLeftRadius: index === props.row_data.length - 1 && "10px",
                  borderBottomRightRadius: index === props.row_data.length - 1 && "10px",
                  alignItems: "center",
                }}
              >
                {props.row_render(row, index)}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "7rem",
            backgroundColor: "#dedede",
            borderRadius: "5px",
            margin: "1rem 0",
          }}
        >
          <Label labelText="데이터가 없습니다." />
        </div>
      )}
    </div>
  );
};

export default GridTable;
