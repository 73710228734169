import { useQuery } from "@tanstack/react-query";
import { getModalSearchMember } from "apis/member/search_member_api";
import { getDefaultValue, getPermission, simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { searchMemberParamInit } from "../Module/reducers/member_search_param";
import AssignTable from "../SearchMemberTable/AssignTable";

const SearchAssignMember = ({ onModalDone }) => {
  const storesDispatch = useDispatch();

  const [searchParam, setSearchParam] = useState(_.cloneDeep(searchMemberParamInit));
  const [curPage, setCurPage] = useState();
  const [totalPage, setTotalPage] = useState(1);
  const [hqIdx, setHqIdx] = useState(0);
  const [searchResult, setSearchResult] = useState([]);
  const [phone, setPhone] = useState({
    p1: "",
    p2: "",
    p3: "",
  });
  const phoneRef1 = useRef();
  const phoneRef2 = useRef();
  const phoneRef3 = useRef();
  const [hqBranchData, setHqBranchData] = useState({
    branch_list: [[]],
    hq_list: [],
    target_list: [],
  });
  // API 상태 관리
  const [isGetMemberDataList, setIsGetMemberDataList] = useState(false);
  const queryData = { page: curPage, searchParam: searchParam };

  // [GET] 회원 검색 결과
  const { isFetching, data: memberDataList } = useQuery({
    queryKey: ["searchMemberModal", queryData],
    queryFn: () => getModalSearchMember(queryData),
    enabled: isGetMemberDataList,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setIsGetMemberDataList(false);
    },
  });

  const onClickSearch = async (page) => {
    // 전화번호 중간 자리 미입력 시 alert 띄움
    if (searchParam.base_info.phone === "err") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="전화번호 검색 조건이 잘못되었습니다." />,
          onBtnOk: () => {},
        }),
      );
      return;
    }

    if (!searchParam.hq_info.hq_name) {
      //기본값 세팅
      searchParam.hq_info = hqBranchData.target_list[0].hq_info;
    }
    searchParam.member_type = "회원";
    searchParam.is_change_branch = true;

    setCurPage(page);
    // 회원 검색
    setIsGetMemberDataList(true);
  };

  const getHqBranchCombo = async () => {
    const result = await getPermission(storesDispatch, "HQ_BRANCH/ALL", true, false, true);
    setHqBranchData(result);
  };

  useEffect(() => {
    getHqBranchCombo();
  }, []);

  const labelWidth = "4.5rem";
  const compWidth = "12rem";

  // 전화번호 입력 이벤트 (모든 칸)
  const onInputPhone = () => {
    let res = "";

    if (phone.p1 && !phone.p2 && phone.p3) {
      res = "err";
    } else {
      res = phone.p1 + "-" + phone.p2 + "-" + phone.p3;
    }
    res = res.replace("--", "-");

    setSearchParam({
      ...searchParam,
      base_info: {
        phone: res,
      },
    });
  };

  // API 받아온 데이터 세팅
  useEffect(() => {
    // 회원 검색 데이터
    if (memberDataList) {
      setSearchResult(memberDataList.data.data);
      setTotalPage(memberDataList.data.total_page);
    }
  }, [memberDataList]);

  return (
    <div
      style={{
        width: "55.5rem",
      }}
    >
      {isFetching && <Loading />}

      <div
        style={{ display: "flex" }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onClickSearch(1, true);
          }
        }}
      >
        <ComboBox
          labelWidth={labelWidth}
          comboItemWidth={compWidth}
          labelText="본부"
          data={hqBranchData.hq_list}
          defaultValue={hqBranchData.hq_list[hqIdx]}
          onChangeCallback={(e) => {
            const hIdx = hqBranchData.hq_list.findIndex((el) => el === e.target.value);
            if (hIdx !== -1) {
              setHqIdx(hIdx);
              setSearchParam((cur) => {
                const hq_info = {
                  hq_id: hqBranchData.target_list[hIdx].hq_info.hq_id,
                  hq_name: hqBranchData.target_list[hIdx].hq_info.hq_name,
                };
                const branch_info = { branch_id: "", branch_name: "" };
                return { ...cur, hq_info, branch_info };
              });
            }
          }}
        />
        <SearchComboBox
          marginLeft="1.5rem"
          labelWidth={labelWidth}
          comboItemWidth={compWidth}
          labelText="지점"
          data={hqBranchData.branch_list[hqIdx]}
          defaultValue={getDefaultValue("combo", searchParam.branch_info.branch_name)}
          onChangeCallback={(e, v, idx) => {
            const branch_info = hqBranchData.target_list[hqIdx].branch_list[idx];
            setSearchParam((cur) => {
              return { ...cur, branch_info };
            });
          }}
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          labelText="이름"
          onChangeCallback={(e) => {
            setSearchParam({
              ...searchParam,
              member_info: {
                member_name: e,
                member_no: searchParam.member_info.member_no,
              },
            });
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") onClickSearch(1, true);
          }}
        />

        <TextField
          inputRef={phoneRef1}
          labelWidth={labelWidth}
          textBoxWidth={"5.5rem"}
          textMarginRight="0.25rem"
          marginLeft="1.5rem"
          labelText="연락처"
          maxLength={3}
          onChangeCallback={(e) => {
            phone.p1 = e;
            onInputPhone();
            if (phone.p1.length === 3) {
              phoneRef2.current.select();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") onClickSearch(1, true);
          }}
        />
        <TextField
          inputRef={phoneRef2}
          textMarginRight="0.25rem"
          labelMarginRight="0.25rem"
          labelWidth="0.5rem"
          labelText="-"
          maxLength={4}
          textBoxWidth={"5.5rem"}
          onChangeCallback={(e) => {
            phone.p2 = e;
            onInputPhone();
            if (phone.p2.length === 4) {
              phoneRef3.current.select();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClickSearch(1, true);
            } else if (e.key === "Backspace" && !phone.p2) {
              phoneRef1.current.select();
            }
          }}
        />
        <TextField
          inputRef={phoneRef3}
          labelMarginRight="0.25rem"
          labelWidth="0.5rem"
          labelText="-"
          maxLength={4}
          textBoxWidth={"5.5rem"}
          onChangeCallback={(e) => {
            phone.p3 = e;
            onInputPhone();
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClickSearch(1, true);
            } else if (e.key === "Backspace" && !phone.p3) {
              phoneRef2.current.select();
            }
          }}
        />
      </div>

      <div style={{ display: "flex", marginTop: "0.5rem", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}>
          <TextField
            labelWidth={labelWidth}
            textBoxWidth={compWidth}
            labelText="회원번호"
            onChangeCallback={(e) => {
              const trimmedNo = e.trim();
              setSearchParam({
                ...searchParam,
                member_info: {
                  member_name: searchParam.member_info.member_name,
                  member_no: trimmedNo,
                },
              });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") onClickSearch(1, true);
            }}
          />
          <TextField
            labelWidth={labelWidth}
            textBoxWidth={compWidth}
            marginLeft="1.5rem"
            labelText="카드번호"
            onChangeCallback={(e) => {
              setSearchParam({
                ...searchParam,
                access: {
                  card_no: e,
                },
              });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") onClickSearch(1, true);
            }}
          />
        </div>

        <NormalBtn name="검색" onClick={() => onClickSearch(1, true)} />
      </div>

      <div style={{ marginTop: "1rem" }}>
        <AssignTable
          onClickSearch={onClickSearch}
          onModalDone={onModalDone}
          searchParam={searchParam}
          totalPage={totalPage}
          curPage={curPage}
          setCurPage={setCurPage}
          searchResult={searchResult}
        />
      </div>
    </div>
  );
};
export default SearchAssignMember;
