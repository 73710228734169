import CardFixed from "components/Card/CardFixed";
import ComboBox from "components/LabelInput/ComboBox";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import RefundSelector from "../../MemberCommonItems/RefundSelector";
import ActivatePayList from "./ActivePayList";
import UnpaidList from "./UnpaidList";

const ChangePayMethodContents = forwardRef((props, ref) => {
  const { serviceViewState, payInfoListAction } = props;
  const [unPaidListState, setUnPaidListState] = useState([]);

  const [selectedPCode, setSelectedPCode] = useState("");
  const refundInfoRef = useRef();

  const [refundPayList, setRefundPayList] = useState([]);
  const refundTotalPrice = useRef(0);

  useImperativeHandle(ref, () => ({
    getUnPaidItem: () => {
      return unPaidListState;
    },
    getRefundItem: () => {
      return refundPayList;
    },
  }));

  //row가 클릭 되었을때 => 뭐 결제할지 골랐을때
  const onSelectPayment = (data) => {
    let totalPaid = 0;
    if (data.payment_list_selector.selected) {
      setSelectedPCode(data.payment_data.purchase_code);
    } else {
      const found = serviceViewState.payment_list.some(
        (el) => el.payment_list_selector.selected === true,
      );
      if (found) {
        //뭐라도 아직 남음
        // console.log("aaaaaaaaaaaaaaaaaaaa");
      } else {
        setSelectedPCode("");
      }
    }

    let arr = [];
    let isPt = false;
    for (let i = 0; i < serviceViewState.payment_list.length; i++) {
      // serviceViewState.payment_list[i]와 같은 serviceViewState.refund_list[?]를 찾는다
      const rIdx = serviceViewState.refund_list.findIndex(
        (x) => x.payment_id === serviceViewState.payment_list[i].payment_data.payment_id,
      );

      //selected가 true인 인덱스를 찾아서 개네는 환불 리스트에 넣자
      if (serviceViewState.payment_list[i].payment_list_selector.selected === true) {
        if (rIdx === -1) {
          console.log("serviceViewState.refund_list에 해당하는 payment 데이터가 없음");
          break;
        }

        //이 인덱스 번째를 환불쪽에 보이도록!
        serviceViewState.refund_list[rIdx].is_view = true;
        arr.push(serviceViewState.refund_list[rIdx]);
        totalPaid += serviceViewState.payment_list[i].payment_data.price;

        // 선택된 payment 중 PT가 포함되었는 지 체크 & 해당 결제수단 저장
        if (!isPt) {
          isPt = serviceViewState.payment_list[i].is_pt;
          if (isPt) {
            props.setPtPaymentMethod(serviceViewState.payment_list[i].payment_data.pay_method);
          }
        }
      } else {
        serviceViewState.refund_list[rIdx].is_view = false;
      }
    }

    props.setIsPtPayment(isPt);

    setRefundPayList(arr);
    refundTotalPrice.current = totalPaid;
    addPayInfoState("재결제(결제변경 금액)", totalPaid, 1);
  };

  useEffect(() => {
    //선택된 구매코드가 바뀔때마다, 활성화 해줄 애들을 세팅
    let totalUnpaid = 0;
    let totalPaid = 0;
    let curUnpaidList = [];
    if (!selectedPCode) {
      serviceViewState.payment_list &&
        serviceViewState.payment_list.forEach((el) => (el.payment_list_selector.is_lock = false));
    } else {
      //1. 해당 구매코드랑 다른애는 is_lock을 true로 설정, 같은애는 false
      serviceViewState.payment_list.forEach((el) => {
        if (el.payment_data.purchase_code === selectedPCode) {
          el.payment_list_selector.is_lock = false;
        } else {
          el.payment_list_selector.is_lock = true;
        }
      });
      //2. 선택된 구매코드에 대해서 미납이력 보여주기
      const index = serviceViewState.unpaid.unpaid_list.findIndex(
        (el) => el.purchase_code === selectedPCode,
      );

      if (index !== -1) {
        //그 해당 구매코드에 미납금이 있으니까 표시해주자
        curUnpaidList.push(serviceViewState.unpaid.unpaid_list[index]);
        totalUnpaid = serviceViewState.unpaid.unpaid_list[index].unpaid_price;
      }
    }

    addPayInfoState("미납금", totalUnpaid, 0);
    setUnPaidListState(mergerUnPaidList(curUnpaidList));
  }, [selectedPCode]);

  // purchase code filter
  const getFilteredViewState = () => {
    if (selectedPCode !== "") {
      for (let i = 0; i < serviceViewState.payment_list.length; i++) {
        if (serviceViewState.payment_list[i].payment_data.purchase_code === selectedPCode) {
          serviceViewState.payment_list[i].payment_list_selector.is_lock = false;
        } else {
          serviceViewState.payment_list[i].payment_list_selector.is_lock = true;
        }
      }
    }
    return serviceViewState;
  };

  const mergerUnPaidList = (curUnpaidList) => {
    let finalUnpaidList = [];
    for (let i = 0; i < curUnpaidList.length; i++) {
      if (finalUnpaidList.length === 0) {
        finalUnpaidList.push(curUnpaidList[i]);
      } else {
        let index = finalUnpaidList
          .map((item) => item.purchase_code)
          .indexOf(curUnpaidList[i].purchase_code);
        if (index === -1) {
          finalUnpaidList.push(curUnpaidList[i]);
        } else {
          finalUnpaidList[index].unpaid_price += curUnpaidList[i].unpaid_price;
        }
      }
    }
    return finalUnpaidList;
  };

  const addPayInfoState = (key, price, idx) => {
    let action = "push";

    if (price === 0) {
      action = "pop";
    }
    payInfoListAction(
      action,
      key,
      {
        type: key,
        price: price,
        dis: 0,
        pay_order: idx,
        tab_idx: 0,
      },
      idx,
      // mbshpIdx 넣어줘야 함
    );
  };

  return (
    <CardFixed
      topDom={
        <div style={{ display: "flex" }}>
          <div>결제 수단 변경</div>
          <SelectConsultant serviceViewState={serviceViewState} />
        </div>
      }
      midDom={
        <div>
          {/* 유효 결제 목록 */}
          <ActivatePayList
            serviceViewState={getFilteredViewState()}
            onSelectPayment={onSelectPayment}
            selectedPCode={selectedPCode}
          />

          {unPaidListState.length !== 0 ? <UnpaidList unPaidListState={unPaidListState} /> : ""}

          {/* 환불금 지급 */}
          <RefundSelector
            ref={refundInfoRef}
            refundPayList={refundPayList}
            setRefundPayList={setRefundPayList}
            paymentListState={serviceViewState.payment_list}
            refundTotalPrice={refundTotalPrice.current}
            addedRefundTotalPrice={0}
            regDate={props.regDate}
          />
        </div>
      }
      isLoading={false}
      rootStyle={{
        // width: "80.437rem",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
});
export default ChangePayMethodContents;

//

const SelectConsultant = ({ serviceViewState }) => {
  return (
    <div
      style={{
        display: "flex",
        float: "right",
        marginLeft: "auto",
      }}
    >
      <ComboBox
        required
        labelWidth={"2.625rem"}
        comboItemWidth={"12rem"}
        labelText="상담자"
        data={serviceViewState.cs_team_name_list}
        onChangeCallback={(e) => {
          const idx = serviceViewState.cs_team_info_list.findIndex(
            (el) => el.staff_name === e.target.value,
          );
          const staff_info = {
            staff_id: serviceViewState.cs_team_info_list[idx].staff_id,
            staff_name: serviceViewState.cs_team_info_list[idx].staff_name,
            staff_pos: serviceViewState.cs_team_info_list[idx].position.org_name,
            staff_rank: serviceViewState.cs_team_info_list[idx].rank.org_name,
          };
          serviceViewState.cs_staff = staff_info;
        }}
      />
    </div>
  );
};
