import * as ActionTypes from "./action_type";

///SEARCH MEMBER
export const loadSearchMember = (search_param) => {
  return {
    type: ActionTypes.getSearchMemberAction,
    search_param: search_param,
    // branch_name: branch_name,
  };
};

export const loadSearchMemberSuccess = (data) => {
  return {
    type: ActionTypes.getSearchMemberSuccessAction,
    payload: data,
  };
};

export const loadSearchMemberFail = (data) => {
  return {
    type: ActionTypes.getSearchMemberFailAction,
    payload: [],
  };
};

export const loadSearchMemberReset = (data) => {
  return {
    type: ActionTypes.getSearchMemberResetAction,
    payload: data,
  };
};

///SEARCH STAFF
export const loadSearchStaffItems = (search_param) => {
  return {
    type: ActionTypes.getSearchStaffAction,
    search_param: search_param,
  };
};

export const loadSearchStaffSuccess = (data) => {
  return {
    type: ActionTypes.getSearchStaffSuccessAction,
    payload: data,
  };
};

export const loadSearchStaffFail = (data) => {
  return {
    type: ActionTypes.getSearchStaffFailAction,
    payload: [],
  };
};

export const loadSearchStaffReset = (data) => {
  return {
    type: ActionTypes.getSearchStaffResetAction,
    payload: data,
  };
};

///SEARCH MEMBER PARAM
export const loadSearchMemberParam = () => {
  return {
    type: ActionTypes.getSearchMemberParamAction,
  };
};

export const loadSearchMemberParamSuccess = (data) => {
  return {
    type: ActionTypes.getSearchMemberParamSuccessAction,
    payload: data,
  };
};

export const loadSearchMemberParamFail = (data) => {
  return {
    type: ActionTypes.getSearchMemberParamFailAction,
    payload: [],
  };
};

export const loadSearchMemberParamReset = (data) => {
  return {
    type: ActionTypes.getSearchMemberParamResetAction,
    payload: data,
  };
};
