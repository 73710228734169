import * as ActionTypes from "./action_type";

///MEMBER VIEW
export const loadAccessLog = (search_param) => {
  return {
    type: ActionTypes.AccessLogSearchAction,
    search_param: search_param,
  };
};

export const loadAccessLogSuccess = (data) => {
  return {
    type: ActionTypes.AccessLogSearchSuccessAction,
    payload: data,
  };
};

export const loadAccessLogFail = (data) => {
  return {
    type: ActionTypes.AccessLogSearchFailAction,
    payload: [],
  };
};

export const loadAccessLogReset = (data) => {
  return {
    type: ActionTypes.AccessLogSearchResetAction,
    payload: data,
  };
};

///MEMBER VIEW
export const loadDoorLogSearch = (search_param) => {
  return {
    type: ActionTypes.AccessLogSearchAction,
    search_param: search_param,
  };
};

export const loadDoorLogSearchSuccess = (data) => {
  return {
    type: ActionTypes.DoorLogSearchSuccessAction,
    payload: data,
  };
};

export const loadDoorLogSearchFail = (data) => {
  return {
    type: ActionTypes.DoorLogSearchFailAction,
    payload: [],
  };
};

export const loadDoorLogSearchReset = (data) => {
  return {
    type: ActionTypes.DoorLogSearchResetAction,
    payload: data,
  };
};
