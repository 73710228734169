import {
  loadClassSessionView,
  loadClassSessionViewFail,
  loadClassSessionViewReset,
  loadClassSessionViewSuccess,
  loadEtcSalaryView,
  loadEtcSalaryViewFail,
  loadEtcSalaryViewReset,
  loadEtcSalaryViewSuccess,
  loadGxSalaryView,
  loadGxSalaryViewFail,
  loadGxSalaryViewReset,
  loadGxSalaryViewSuccess,
  loadMbshpSalaryView,
  loadMbshpSalaryViewFail,
  loadMbshpSalaryViewReset,
  loadMbshpSalaryViewSuccess,
  loadPtSalaryView,
  loadPtSalaryViewFail,
  loadPtSalaryViewReset,
  loadPtSalaryViewSuccess,
  loadSalaryInfoView,
  loadSalaryInfoViewFail,
  loadSalaryInfoViewReset,
  loadSalaryInfoViewSuccess,
  loadSalaryStatusView,
  loadSalaryStatusViewFail,
  loadSalaryStatusViewReset,
  loadSalaryStatusViewSuccess,
  loadStaffStandardSalaryView,
  loadStaffStandardSalaryViewFail,
  loadStaffStandardSalaryViewReset,
  loadStaffStandardSalaryViewSuccess,
} from "./view_act";

//급여메인
export const loadSalaryInfoViewStore = (salary_report_id, is_edit) =>
  loadSalaryInfoView(salary_report_id, is_edit);

//탭(1-6번)
export const loadSalaryStatusViewStore = (salary_report_id, is_edit) =>
  loadSalaryStatusView(salary_report_id, is_edit);
export const loadStaffStandardSalaryViewStore = (salary_report_id, is_edit) =>
  loadStaffStandardSalaryView(salary_report_id, is_edit);
export const loadMbshpSalaryViewStore = (salary_report_id, is_edit) =>
  loadMbshpSalaryView(salary_report_id, is_edit);
export const loadPtSalaryViewStore = (salary_report_id, is_edit) =>
  loadPtSalaryView(salary_report_id, is_edit);
export const loadGxSalaryViewStore = (salary_report_id, is_edit) =>
  loadGxSalaryView(salary_report_id, is_edit);
export const loadEtcSalaryViewStore = (salary_report_id, is_edit) =>
  loadEtcSalaryView(salary_report_id, is_edit);

//안쓰는거
export const loadClassSessionViewStore = (class_id, staff_id) =>
  loadClassSessionView(class_id, staff_id);

export const SalaryInfoAction = {
  loadSalaryInfoView,
  loadSalaryInfoViewSuccess,
  loadSalaryInfoViewFail,
  loadSalaryInfoViewReset,
  loadSalaryStatusView,
  loadSalaryStatusViewSuccess,
  loadSalaryStatusViewFail,
  loadSalaryStatusViewReset,
  loadStaffStandardSalaryView,
  loadStaffStandardSalaryViewSuccess,
  loadStaffStandardSalaryViewFail,
  loadStaffStandardSalaryViewReset,
  loadClassSessionView,
  loadClassSessionViewSuccess,
  loadClassSessionViewFail,
  loadClassSessionViewReset,
  loadEtcSalaryView,
  loadEtcSalaryViewSuccess,
  loadEtcSalaryViewFail,
  loadEtcSalaryViewReset,
  loadMbshpSalaryView,
  loadMbshpSalaryViewSuccess,
  loadMbshpSalaryViewFail,
  loadMbshpSalaryViewReset,
  loadGxSalaryView,
  loadGxSalaryViewSuccess,
  loadGxSalaryViewFail,
  loadGxSalaryViewReset,
  loadPtSalaryView,
  loadPtSalaryViewSuccess,
  loadPtSalaryViewFail,
  loadPtSalaryViewReset,
};
