export const salaryReportPost = {
  base_date: "",
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  combo_selector: {
    branch_list: [[]],
    hq_list: [],
    target_list: [
      {
        branch_list: [
          {
            branch_id: "",
            branch_name: "",
          },
        ],
        hq_info: {
          hq_id: "",
          hq_name: "",
        },
      },
    ],
  },
  hq_info: {
    hq_id: "",
    hq_name: "",
  },
  is_salary_basic: true,
  is_salary_mbshp: true,
  is_salary_pt: true,
  is_salary_gx: true,
  is_salary_etc: true,
};
