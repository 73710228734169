export const hqAddState = {
  hq_info: {
    hq_id: "",//"objectId",
    hq_name: "",//"string / 본부명"
  },
  package_info: {
    package_id: "",//"object id / 패키지 ID",
    package_name: "",//"string / 패키지명"
  },
  owner_info: {
    owner_id: "",//"objectId / 대표자 ID",
    owner_name: "",//"string / 대표자 이름",
    owner_gender: "",//"string / 대표자 성별",
    owner_phone: "",//"string / 대표자 연락처",
    owner_email: "",//"string / 대표자 이메일",
    owner_birth: "",//"string / 대표자 생년월일"
  },
  found_date: "",//"date / 등록일",
  is_new: true,//"bool / 새로운 직원 정보 ????????????? (추가됨) 임직원 검색이면 FALSE, 직접 입력이면 TRUE",
  reg_info: {
    reg_date: "",//"date / 등록일시",
    reg_user: "",//"string / 등록자",
    upd_date: "",//"date / 수정일시",
    upd_user: "",//"string / 최근수정자"
  }
}