export const gxSearchInit = {
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  class_from_date: "",
  class_name: "",
  class_result_type: "",
  class_to_date: "",
  gx_code: "",
  manager_info: {
    is_resigned: false,
    staff_info: {
      staff_id: "",
      staff_name: "",
      staff_pos: "",
      staff_rank: "",
    },
  },
  view_info: {
    branch_list: [
      {
        branch_id: "",
        branch_name: "",
      },
    ],
    branch_name_list: [],
  },
};
