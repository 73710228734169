import { useEffect, useState } from "react";

import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { useDispatch, useSelector } from "react-redux";

import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import { loadMembershipHistoryStore } from "../../Module/actions";

const MembershipHistoryTable = ({ memberId }) => {
  const historyInfoState = useSelector((state) => state.member_history.membership_history_state);
  const storesDispatch = useDispatch();
  const [curPage, setCurPage] = useState(1);

  useEffect(() => {
    storesDispatch(loadMembershipHistoryStore(memberId));
  }, [memberId]);

  //페이지네이션
  useEffect(() => {
    if (curPage) {
      storesDispatch(loadMembershipHistoryStore(memberId, curPage));
    }
  }, [curPage]);

  const membershipHistoryTable = new membershipHistoryClass();

  return (
    <div>
      <CustomTable
        columns_head={membershipHistoryTable.columns_head}
        table_title={membershipHistoryTable.table_title}
        rest_call={membershipHistoryTable.get_data_from_rest}
        row_render={membershipHistoryTable.create_table}
        rest_data={historyInfoState.stores.data}
      />
      <CustomPagination
        curPage={curPage}
        doSearch={setCurPage}
        totalCnt={historyInfoState.stores.total_page ? historyInfoState.stores.total_page : 1}
      />
    </div>
  );
};
export default MembershipHistoryTable;

class membershipHistoryClass {
  table_title = "";
  btnStyleClass = btnStyles();

  create_table = (data, idx) => {
    // const noteArr = data.note.split("\n");

    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.reg_date}</CustomTableContents>
        <CustomTableContents>{data.description}</CustomTableContents>
        <CustomTableContents>{data.change_day} 일</CustomTableContents>
        <CustomTableContents>{data.reason}</CustomTableContents>
        <CustomTableContents>{data.last_membership_date}</CustomTableContents>
        <CustomTableContents>{data.reg_info.reg_name}</CustomTableContents>
        <CustomTableContents>
          {data.note}
          {/* {noteArr[0]} {noteArr.length >= 1 && <br />}
          {noteArr[1]} {noteArr.length >= 2 && <br />}
          {noteArr[2]} {noteArr.length >= 3 && <br />}
          {noteArr[3]} */}
        </CustomTableContents>
      </CustomRow>
    );
  };

  // splitNote = () => {};

  columns_head = [
    { title: "등록일시", width: "12%" },
    { title: "지점", width: "8%" },
    { title: "반영일", width: "7%", tooltip: "매출이 반영된 날짜입니다" },
    { title: "회원권명", width: "15%" },
    { title: "변경일수", width: "6%" },
    { title: "사유", width: "8%" },
    { title: "최종 회원권 종료일", width: "10%" },
    { title: "담당자", width: "7%" },
    { title: "비고", width: "27%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
