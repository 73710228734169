// import { memberViewAction, loadMemberViewStore } from "./Module/actions";
import IconButton from "components/Button/IconButton";
import { dataEmpty, getByte, getPermission } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomScrollDiv,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import ColorLabel from "components/LabelInput/ColorLabel";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const PtSearchResult = ({
  searchResult,
  statusType,
  curPage,
  setCurPage,
  totalPage,
  onClickOpenModal,
  onClickSearch,
  refreshTable,
}) => {
  const storesDispatch = useDispatch();
  const [canAddServiceSession, setCanAddServiceSession] = useState(false);

  useEffect(() => {
    getPermission(storesDispatch, "CLASS_MENU/CLASS_EXT_MANAGE").then((res) => {
      setCanAddServiceSession(res);
    });
  }, []);

  const popPtDetailModal = (data) => {
    const param = {
      title: "PT 수업상세보기",
      modalParam: {
        data,
        refreshTable,
        canAddServiceSession,
      },
    };
    onClickOpenModal("PT수업상세", param, onClickSearch);
  };

  const unSignedResultList = new unassignedResultTable();
  const assignedResultList = new assignedResultTable();
  const fixedResultList = new fixedTable(statusType, popPtDetailModal);

  return (
    <div>
      <div style={{ display: "flex", width: "100%", marginTop: "0.5rem" }}>
        <div style={{ width: "85%", borderRight: "1px solid #BBB" }}>
          {statusType !== "미배정" ? (
            <CustomTable
              width={"90rem"}
              columns_head={assignedResultList.columns_head}
              table_title={assignedResultList.table_title}
              rest_call={assignedResultList.get_data_from_rest}
              row_render={assignedResultList.create_table}
              rest_data={searchResult}
            />
          ) : (
            <CustomTable
              columns_head={unSignedResultList.columns_head}
              table_title={unSignedResultList.table_title}
              rest_call={unSignedResultList.get_data_from_rest}
              row_render={unSignedResultList.create_table}
              rest_data={searchResult}
            />
          )}
        </div>
        <div style={{ width: "15%" }}>
          <CustomTable
            columns_head={fixedResultList.columns_head}
            table_title={fixedResultList.table_title}
            rest_call={fixedResultList.get_data_from_rest}
            row_render={fixedResultList.create_table}
            rest_data={searchResult}
          />
        </div>
      </div>
      <CustomPagination curPage={curPage} doSearch={setCurPage} totalCnt={totalPage} />
    </div>
  );
};
export default PtSearchResult;

class unassignedResultTable {
  table_title = "";

  getAge = (birth) => {
    const thisYear = new Date().getFullYear();
    const birthYear = new Date(birth).getFullYear();
    let age = thisYear - birthYear + 1;
    return age;
  };

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents height={"2.375rem"}>
          {data.membership_info.member_no}
        </CustomTableContents>
        <CustomTableContents>
          <CustomScrollDiv leng={getByte(data.membership_info.member_name)} set={16}>
            {data.membership_info.member_name}
          </CustomScrollDiv>
        </CustomTableContents>
        <CustomTableContents>{data.membership_info.gender}</CustomTableContents>
        <CustomTableContents>{data.membership_info.phone}</CustomTableContents>
        <CustomTableContents>{this.getAge(data.membership_info.birth)}</CustomTableContents>
        <CustomTableContents>{data.pt_info.item_name}</CustomTableContents>
        <CustomTableContents>{data.active_info.last_mbshp_to_date}</CustomTableContents>
        <CustomTableContents>
          <CustomScrollDiv leng={getByte(data.pt_info.comment)} set={20}>
            {data.pt_info.comment}
          </CustomScrollDiv>
        </CustomTableContents>
        <CustomTableContentsEND align="center">
          <CustomScrollDiv leng={getByte(data.pt_info.remarks)} set={20}>
            {data.pt_info.remarks}
          </CustomScrollDiv>
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "회원번호", width: "10%" },
    { title: "이름", width: "10%" },
    { title: "성별", width: "7%" },
    { title: "연락처", width: "10%" },
    { title: "나이", width: "7%" },
    { title: "PT 계약 상품명", width: "13%" },
    { title: "최종 회원권 종료일", width: "13%" },
    { title: "특이사항", width: "15%" },
    { title: "비고", width: "15%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}

class assignedResultTable {
  table_title = "";

  getAge = (birth) => {
    const thisYear = new Date().getFullYear();
    const birthYear = new Date(birth).getFullYear();
    let age = thisYear - birthYear + 1;
    return age;
  };

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents height={"2.375rem"}>
          {data.membership_info.member_no}
        </CustomTableContents>
        <CustomTableContents>
          <CustomScrollDiv leng={getByte(data.membership_info.member_name)} set={16}>
            {data.membership_info.member_name}
          </CustomScrollDiv>
        </CustomTableContents>
        <CustomTableContents>{data.membership_info.gender}</CustomTableContents>
        <CustomTableContents>{data.membership_info.phone}</CustomTableContents>
        <CustomTableContents>{this.getAge(data.membership_info.birth)}</CustomTableContents>

        <CustomTableContents>{data.pt_info.item_name}</CustomTableContents>
        <CustomTableContents>{data.active_info.last_mbshp_to_date}</CustomTableContents>

        <CustomTableContents>
          {`${data.pt_info.remain_session_cnt} / ${
            data.pt_info.base_session_cnt + data.pt_info.service_session_cnt
          }`}
        </CustomTableContents>
        <CustomTableContents>{data.cur_manager.staff_info.staff_name}</CustomTableContents>
        <CustomTableContents>{data.pt_info.latest_class_date}</CustomTableContents>

        <CustomTableContents>
          <CustomScrollDiv leng={getByte(data.pt_info.comment)} set={16}>
            {data.pt_info.comment}
          </CustomScrollDiv>
        </CustomTableContents>
        <CustomTableContentsEND align="center">
          <CustomScrollDiv leng={getByte(data.pt_info.remarks)} set={16}>
            {data.pt_info.remarks}
          </CustomScrollDiv>
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "회원번호", width: "8.3%" },
    { title: "이름", width: "8.3%" },
    { title: "성별", width: "6.3%" },
    { title: "연락처", width: "8.3%" },
    { title: "나이", width: "6.3%" },

    { title: "PT 계약 상품명", width: "10.3%" },
    { title: "최종 회원권 종료일", width: "10.3%" },

    { title: "잔여 세션", width: "8.3%" },
    { title: "수업 담당자", width: "8.3%" },
    { title: "최근 수업일", width: "8.3%" },

    { title: "특이사항", width: "8.3%" },
    { title: "비고", width: "8.3%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}

class fixedTable {
  table_title = "";
  statusType = null;
  popPtDetailModal = null;

  constructor(statusType, popPtDetailModal) {
    this.statusType = statusType;
    this.popPtDetailModal = popPtDetailModal;
  }

  getColor_1 = (value) => {
    let bgColor = "";
    let fontColor = "";
    if (value === "수강") {
      bgColor = "#E4F4E1";
      fontColor = "#109B58";
    } else if (value === "대기") {
      bgColor = "#FFF4D7";
      fontColor = "#FFC737";
    } else {
      bgColor = "#F8D8D8";
      fontColor = "#DE0909";
    }

    return { bgColor, fontColor };
  };

  getColor_2 = (value) => {
    let bgColor = "";
    let fontColor = "";
    if (value === "예약") {
      bgColor = "#E3EBF7";
      fontColor = "#578DDE";
    } else if (value === "대기") {
      bgColor = "#FFF4D7";
      fontColor = "#FFC737";
    } else if (value === "수업완료") {
      bgColor = "#D6D3F7";
      fontColor = "#3022D7";
    } else {
      bgColor = "#F8D8D8";
      fontColor = "#DE0909";
    }

    return { bgColor, fontColor };
  };

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents height="2.375rem">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ColorLabel
              labelText={data.pt_info.root_status}
              bgColor={this.getColor_1(data.pt_info.root_status).bgColor}
              fontColor={this.getColor_1(data.pt_info.root_status).fontColor}
            />
          </div>
        </CustomTableContents>
        <CustomTableContents>
          {this.statusType !== "미배정" ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ColorLabel
                labelText={data.pt_info.class_status}
                bgColor={this.getColor_2(data.pt_info.class_status).bgColor}
                fontColor={this.getColor_2(data.pt_info.class_status).fontColor}
              />
            </div>
          ) : (
            "-"
          )}
        </CustomTableContents>
        <CustomTableContentsEND align="center">
          <IconButton
            height="1rem"
            type="detail"
            onClick={() => {
              this.popPtDetailModal(data);
            }}
          />
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "PT상태", width: "40%" },
    { title: "PT 수업상태", width: "40%" },
    { title: "", width: "20%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
