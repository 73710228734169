import Loading from "components/Loading/Loading/Loading";
import WorkTime from "pages/Staff/StaffInfo/Modal/CommonItem/WorkTime";
import { useState } from "react";
import WorkInfoBaseView from "./WorkInfoItem/WorkInfoBase";
import WorkInfoSalaryView from "./WorkInfoItem/WorkInfoSalary";

const CurrentWorkInfoView = ({ workInfoObj, logType }) => {
  const [loading, setLoading] = useState(false);

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {loading && <Loading />}

      <div style={{ display: "inline-block", width: "100%", marginTop: "0.5rem" }}>
        <WorkInfoBaseView pageMode={"보기"} workInfoStore={workInfoObj} logType={logType} />
        <WorkTime pageMode={"보기"} workInfoStore={workInfoObj} />
        <WorkInfoSalaryView pageMode={"보기"} workInfoStore={workInfoObj} />
      </div>
    </div>
  );
};
export default CurrentWorkInfoView;
