import * as lodash from "lodash";
import { useEffect, useState } from "react";
import MakePersonalTaskModal from "./MakePersonalTaskModal";
import MakeReservModal from "./MakeReservModal";
import ViewNoticeModal from "./ViewNoticeModal";
import ViewWorkOffModal from "./ViewWorkOffModal";

const ScheduleModalIndex = ({ modalSelect, modalParam, onModalDone }) => {
  const [paramData, setParamData] = useState(modalParam.modalParam);

  useEffect(() => {
    const editData = lodash.cloneDeep(modalParam.modalParam);
    setParamData(editData);
    return () => {};
  }, []);

  if (modalSelect === "예약등록") {
    return <MakeReservModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "개인업무등록") {
    return <MakePersonalTaskModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "휴무") {
    return <ViewWorkOffModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "전달사항") {
    return <ViewNoticeModal modalParam={paramData} onModalDone={onModalDone} />;
  } else {
    return <div>current ELSE</div>;
  }
};
export default ScheduleModalIndex;
