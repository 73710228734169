import TextArea from "components/LabelInput/TextArea";

const WorkAssistReqForm = ({ mode, contentState, setContentState }) => {
  const readOnly = mode === "approver" || mode === "unspent" || mode === "view";

  return (
    <div style={{ marginTop: "0.5rem" }}>
      <TextArea
        labelMarginRight="0rem"
        fullWidth
        minRows={16}
        maxRows={16}
        defaultValue={contentState && contentState.cooperation}
        onChangeCallback={(e) => {
          contentState.cooperation = e.target.value;
        }}
        // disabled={this.readOnly}
        disabled={readOnly}
      />
    </div>
  );
};
export default WorkAssistReqForm;
