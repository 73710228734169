import { contentTypeJson } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

// ==================== GET ====================
// [GET] 전자결재 검색 조건 받아오기 및 권한 데이터 세팅
export async function getApprovalSearchParam() {
  return await HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/approval/search_param", {
    params: {},
  });
}

// [GET] 전자결재 문서 내역 리스트 검색
export async function getApprovalList(queryData) {
  const { type, page, searchParams } = queryData;
  const formData = new FormData();
  formData.append("page_num", page);
  formData.append("search_param", JSON.stringify(searchParams));

  const postApi =
    type === "my_draft"
      ? process.env.REACT_APP_STAFF_SERVER + "/approval/search/my_draft"
      : type === "my_approval"
      ? process.env.REACT_APP_STAFF_SERVER + "/approval/search/my_approval"
      : type === "completed"
      ? process.env.REACT_APP_STAFF_SERVER + "/approval/search/my_done"
      : process.env.REACT_APP_STAFF_SERVER + "/approval/search/my_recv";

  return await HttpInstance.post(postApi, formData, contentTypeJson);
}

// ==================== PATCH ====================
