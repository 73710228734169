import * as ActionTypes from "./action_type";

///MEMBER VIEW
export const loadAssignView = (src_member_id, dst_member_id) => {
  return {
    type: ActionTypes.getAssignViewAction,
    src_member_id: src_member_id,
    dst_member_id: dst_member_id,
  };
};

export const loadAssignViewSuccess = (data) => {
  return {
    type: ActionTypes.getAssignViewSuccessAction,
    payload: data,
  };
};

export const loadAssignViewFail = (data) => {
  return {
    type: ActionTypes.getAssignViewFailAction,
    payload: null,
  };
};

export const loadAssignViewReset = (data) => {
  return {
    type: ActionTypes.getAssignViewResetAction,
    payload: data,
  };
};
