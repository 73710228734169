import {
  loadSalaryList,
  loadSalaryListSuccess,
  loadSalaryListFail,
  loadSalaryListReset,
} from "./view_act";

export const loadSalaryListStore = (search_param) => loadSalaryList(search_param);

export const SalaryListAction = {
  loadSalaryList,
  loadSalaryListSuccess,
  loadSalaryListFail,
  loadSalaryListReset,
};
