import { generateObjectZeroId } from "components/CommonLib/CommonLib";
import { create } from "zustand";

// OT 검색 조건 베이스
const otBaseSearchParams = {
  branch_info: {
    branch_id: generateObjectZeroId(),
    branch_name: "",
  },
  class_from_date: "",
  class_to_date: "",
  is_use_date: true,
  manager_info: {
    is_resigned: true,
    staff_info: {
      sabun: "",
      staff_id: generateObjectZeroId(),
      staff_name: "",
      staff_pos: "",
      staff_rank: "",
    },
  },
  p1: "",
  p2: "",
  p3: "",
  member_base: {
    member_name: "",
    member_no: "",
    phone: "",
  },
  staff_text_name: "",
  view_info: {
    branch_list: [
      {
        branch_id: generateObjectZeroId(),
        branch_name: "",
      },
    ],
    branch_name_list: [""],
  },
};
// PT 검색 조건 베이스
const ptBaseSearchParams = {
  branch_info: {
    branch_id: generateObjectZeroId(),
    branch_name: "전체",
  },
  class_from_date: "",
  class_result_type: "",
  class_to_date: "",
  is_use_date: true,
  manager_info: {
    is_resigned: true,
    staff_info: {
      sabun: "",
      staff_id: generateObjectZeroId(),
      staff_name: "",
      staff_pos: "",
      staff_rank: "",
    },
  },
  p1: "",
  p2: "",
  p3: "",
  member_base: {
    member_name: "",
    member_no: "",
    phone: "",
  },
  staff_text_name: "",
  view_info: {
    branch_list: [
      {
        branch_id: generateObjectZeroId(),
        branch_name: "",
      },
    ],
    branch_name_list: [""],
  },
};
// GX 검색 조건 베이스
const gxBaseSearchParams = {
  branch_info: {
    branch_id: generateObjectZeroId(),
    branch_name: "",
  },
  class_from_date: "",
  class_name: "",
  class_result_type: "",
  class_to_date: "",
  gx_code: "",
  manager_info: {
    is_resigned: true,
    staff_info: {
      sabun: "",
      staff_id: generateObjectZeroId(),
      staff_name: "",
      staff_pos: "",
      staff_rank: "",
    },
  },
  staff_text_name: "",
  view_info: {
    branch_list: [
      {
        branch_id: generateObjectZeroId(),
        branch_name: "",
      },
    ],
    branch_name_list: [""],
  },
};

export const useClassHistStore = create((set, get) => ({
  returnStoreByType: (type) => {
    const {
      setClassHistLoading,
      // OT 수업 이력
      otHistSearchParams,
      setOtHistSearchParams,
      resetOtHistSearchParams,
      otHistPage,
      setOtHistPage,
      // PT 수업 이력
      ptHistSearchParams,
      setPtHistSearchParams,
      resetPtHistSearchParams,
      ptHistPage,
      setPtHistPage,
      // GX 수업 이력
      gxHistSearchParams,
      setGxHistSearchParams,
      resetGxHistSearchParams,
      gxHistPage,
      setGxHistPage,
    } = get();

    const otHistLib = {
      setLoading: setClassHistLoading,
      searchParams: otHistSearchParams,
      setSearchParams: setOtHistSearchParams,
      resetSearchParams: resetOtHistSearchParams,
      page: otHistPage,
      setPage: setOtHistPage,
    };
    const ptHistLib = {
      setLoading: setClassHistLoading,
      searchParams: ptHistSearchParams,
      setSearchParams: setPtHistSearchParams,
      resetSearchParams: resetPtHistSearchParams,
      page: ptHistPage,
      setPage: setPtHistPage,
    };
    const gxHistLib = {
      setLoading: setClassHistLoading,
      searchParams: gxHistSearchParams,
      setSearchParams: setGxHistSearchParams,
      resetSearchParams: resetGxHistSearchParams,
      page: gxHistPage,
      setPage: setGxHistPage,
    };

    switch (type) {
      case "ot":
        return otHistLib;
      case "pt":
        return ptHistLib;
      case "gx":
        return gxHistLib;
      default:
        break;
    }
  },
  // ========== State ===========

  // OT, PT, GX Loading State
  classHistLoading: false,

  // OT 이력 검색 State
  otHistSearchParams: {
    ...otBaseSearchParams,
  },
  // PT 이력 검색 State
  ptHistSearchParams: {
    ...ptBaseSearchParams,
  },
  // GX 이력 검색 State
  gxHistSearchParams: {
    ...gxBaseSearchParams,
  },

  // 선택 페이지
  otHistPage: 1,
  ptHistPage: 1,
  gxHistPage: 1,

  // ========== Action ==========
  // Loading state 값 변경
  setClassHistLoading: (bool) => {
    set({ classHistLoading: bool });
  },

  // OT 이력 검색 조건 변경
  setOtHistSearchParams: (key, value) => {
    set((state) => ({
      otHistSearchParams: {
        ...state.otHistSearchParams,
        [key]: value,
      },
    }));
  },
  // PT 이력 검색 조건 변경
  setPtHistSearchParams: (key, value) => {
    set((state) => ({
      ptHistSearchParams: {
        ...state.ptHistSearchParams,
        [key]: value,
      },
    }));
  },
  // GX 이력 검색 조건 변경
  setGxHistSearchParams: (key, value) => {
    set((state) => ({
      gxHistSearchParams: {
        ...state.gxHistSearchParams,
        [key]: value,
      },
    }));
  },

  // Page 값 변경
  setOtHistPage: (value) => {
    set({ otHistPage: value });
  },
  setPtHistPage: (value) => {
    set({ ptHistPage: value });
  },
  setGxHistPage: (value) => {
    set({ gxHistPage: value });
  },

  // 검색 조건 리셋
  resetOtHistSearchParams: () => {
    set({
      otHistSearchParams: {
        ...otBaseSearchParams,
      },
    });
  },

  resetPtHistSearchParams: () => {
    set({
      ptHistSearchParams: {
        ...ptBaseSearchParams,
      },
    });
  },
  resetGxHistSearchParams: () => {
    set({
      gxHistSearchParams: {
        ...gxBaseSearchParams,
      },
    });
  },

  // 모든 검색 조건 리셋
  resetAllSearchParams: () => {
    const { resetOtHistSearchParams, resetPtHistSearchParams, resetGxHistSearchParams } = get();
    resetOtHistSearchParams();
    resetPtHistSearchParams();
    resetGxHistSearchParams();
  },
}));
