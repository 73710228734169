import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadSearchMemberStore, searchModalAction } from "../Module/actions";

const NormalTable = forwardRef((props, ref) => {
  const {
    modalParam,
    onModalDone,
    searchParam,
    totalPage,
    curPage,
    setCurPage,
    searchResult,
    onClickSearch,
  } = props;
  const restData = useSelector((state) => state.search_modal.search_result_member);
  const storesDispatch = useDispatch();
  const rowMax = restData.stores === null ? 1 : 2;

  useImperativeHandle(ref, () => ({
    searchRun: () => {
      storesDispatch(loadSearchMemberStore(searchParam));
    },
  }));

  useEffect(() => {
    return () => {
      storesDispatch(searchModalAction.loadSearchMemberReset());
    };
  }, []);

  const searchResultList = new searchResultTable(modalParam.goToMemberView);

  return (
    <div>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
      />
      <CustomPagination curPage={curPage} doSearch={onClickSearch} totalCnt={totalPage} />
    </div>
  );
});
export default NormalTable;

class searchResultTable {
  table_title = "";
  onParentClickEvent = null;

  constructor(goToMemberView) {
    this.onParentClickEvent = goToMemberView;
  }

  onClickEvent = (data) => {
    this.onParentClickEvent(data);
  };

  create_table = (data, idx) => {
    return (
      <CustomRow
        style={{ width: "100%" }}
        key={idx}
        onClick={() => this.onClickEvent(data)}
        // sx={{
        //   "&.MuiTableRow-root:hover": {
        //     backgroundColor: "red",
        //   },
        // }}
        // className={this.styleClasses.MuiTableRow}
        hover
      >
        <CustomTableContents width="20%">{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents width="6%">{data.member_type}</CustomTableContents>
        <CustomTableContents width="10%">{data.member_info.member_no}</CustomTableContents>
        <CustomTableContents width="8%">
          {data.access.card_no ? data.access.card_no : ""}
        </CustomTableContents>
        <CustomTableContents width="18%">{data.member_info.member_name}</CustomTableContents>
        <CustomTableContentsEND align="center" width="15%">
          {data.base_info.phone}
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "지점", field: "branch" },
    { title: "구분", field: "branch" },
    { title: "회원번호", field: "name" },
    { title: "카드번호", field: "position" },
    { title: "회원명", field: "member_name" },
    { title: "연락처", field: "phone" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
