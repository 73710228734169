import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, setDefault, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import CheckBox from "components/LabelInput/CheckBox";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import ModalBtnModeGroup from "components/ModalBtnModeGroup/ModalBtnModeGroup";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadPermission, loadPermissionReset } from "../Module/actions/index";
import post_api from "../Module/api/PostApi";

const ERPSetPackage = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const packageNameRef = useRef(setDefault(""));
  const restStoreData = useSelector((state) => state.erp_set_state.permission_state);
  const [showDuplLabel, setShowDuplLabel] = useState("");
  const [packageNameOld, setPackageNameOld] = useState("");
  const [loading, setLoading] = React.useState(false);

  const [, updateState] = useState(); // 강제 렌더링용
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    if (modalParam.mode === "add") {
      storesDispatch(loadPermission());
    } else if (modalParam.mode === "edit") {
      storesDispatch(loadPermission(modalParam.package_id));
    }
    return () => {
      storesDispatch(loadPermissionReset());
    };
  }, [loadPermission, storesDispatch]);

  useEffect(() => {
    if (modalParam.mode === "edit") {
      if (restStoreData.stores.data.package_info) {
        setPackageNameOld(restStoreData.stores.data.package_info.package_name);
        packageNameRef.current.value = restStoreData.stores.data.package_info.package_name;
      }
    }
  }, [restStoreData]);

  const onReadChangeEvent = (idx, checked) => {
    var array = [...restStoreData.stores.data.permission_list]; // make a separate copy of the array
    const index = array.map((item) => item.id).indexOf(idx);

    if (index !== -1) {
      restStoreData.stores.data.permission_list[index].is_read = checked;
    }

    if (checked === false && restStoreData.stores.data.permission_list[index].is_write === true) {
      restStoreData.stores.data.permission_list[index].is_write = false;
    }
  };

  const onWriteChangeEvent = (idx, checked) => {
    var array = [...restStoreData.stores.data.permission_list]; // make a separate copy of the array
    const index = array.map((item) => item.id).indexOf(idx);

    if (index !== -1) {
      restStoreData.stores.data.permission_list[index].is_write = checked;
    }
    if (checked === true && restStoreData.stores.data.permission_list[index].is_read === false) {
      restStoreData.stores.data.permission_list[index].is_read = true;
    }
  };

  const onForceUpdate = () => {
    forceUpdate();
  };

  const onIsCheckedAll = (fromIdx, toIdx) => {
    for (let i = fromIdx; i < toIdx + 1; i++) {
      if (
        restStoreData.stores.data.permission_list[i].is_write &&
        restStoreData.stores.data.permission_list[i].is_read
      ) {
        continue;
      } else {
        return false;
      }
    }
    return true;
  };

  const permissionList = new permissionTable(
    onReadChangeEvent,
    onWriteChangeEvent,
    onForceUpdate,
    onIsCheckedAll,
    modalParam.mode,
  );

  const validation = () => {
    if (!packageNameRef.current.value) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="패키지명을 입력해주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="저장하시겠습니까?" />
            </>
          ),
          // width: "50rem",
          onBtnYes: () => {
            onSaveEvent();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const onSaveEvent = async () => {
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    let curAPI = post_api.newPackage();
    const permissionData = {
      permission_list: restStoreData.stores.data.permission_list,
    };

    let formData = new FormData();

    formData.append("package_name", JSON.stringify(packageNameRef.current.value));
    formData.append("package_permission", JSON.stringify(permissionData));

    if (modalParam.mode === "edit") {
      curAPI = post_api.updatePackage();

      formData = new FormData();
      formData.append("package_info", JSON.stringify(restStoreData.stores.data));
    }

    setLoading(true);

    await HttpInstance.post(curAPI, formData, config)
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          simpleAlert(storesDispatch, "알림", "저장되었습니다.");
          onModalDone(modalParam.refreshStore);
        }
      })
      .catch((err) => {
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
        // onModalDone(modalParam.refreshStore);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (restStoreData.stores.data.permission_list) {
    let start = 0,
      end = 0,
      curItem = "";
    restStoreData.stores.data.permission_list.forEach((item, i) => {
      if (i === 0) {
        curItem = item.menu_name;
        return; // == continue
      }

      if (item.menu_name !== curItem) {
        if (i - start === 1) {
          restStoreData.stores.data.permission_list[start].range_end = start;
          end = i - 1;
        } else {
          restStoreData.stores.data.permission_list[start].range_end = end;
        }
        start = i;
        curItem = item.menu_name;
      } else {
        end = i;
      }

      if (i === restStoreData.stores.data.permission_list.length - 1) {
        restStoreData.stores.data.permission_list[start].range_end = i;
      }
    });
  }

  const checkPackageName = (val) => {
    packageNameRef.current.value = "";

    // console.log(packageNameOld);
    if (
      modalParam.mode === "edit" &&
      packageNameOld === restStoreData.stores.data.package_info.package_name
    ) {
      packageNameRef.current.value = val;
    } else {
      HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/erp_set/package/check_name", {
        params: {
          package_name: val,
        },
      })
        .then((res) => {
          if (res.data.data === "N") {
            packageNameRef.current.value = val;
            setShowDuplLabel("N");
          } else {
            setShowDuplLabel("Y");
          }
        })
        .catch((err) => {
          console.log(err);
          // simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        });
    }
  };

  return (
    <div
      style={{
        width: "40rem",
      }}
    >
      {loading ? <Loading /> : <></>}

      <div style={{ display: "flex", marginBottom: "0.5rem", gap: "0.5rem" }}>
        <TextField
          required
          labelWidth={"6.5em"}
          fullWidth
          labelText="ERP 패키지명"
          defaultValue={
            restStoreData.stores.data.package_info &&
            restStoreData.stores.data.package_info.package_name
          }
          onBlur={(e) => {
            checkPackageName(e.target.value);
          }}
        />

        <ModalBtnModeGroup mode={modalParam.mode} onClickEvent={validation} />
      </div>
      {showDuplLabel === "Y" ? (
        <div>
          <Label
            labelText="중복된 패키지명이 있습니다."
            color="#c73f4b"
            fontSize="0.9rem"
            marginLeft="7rem"
            justifyContent="left"
          />
        </div>
      ) : (
        <></>
      )}

      {/* <SubTitle titleText="권한 설정" /> */}
      <CustomTable
        columns_head={permissionList.columns_head}
        table_title={permissionList.table_title}
        rest_call={permissionList.get_data_from_rest}
        row_render={permissionList.ret_table}
        rest_data={restStoreData.stores.data.permission_list}
      />
    </div>
  );
};
export default ERPSetPackage;

class permissionTable {
  table_title = "";
  btnStyleClass = btnStyles();
  mReadEvent = null;
  mWriteEvent = null;
  mForceUpdate = null;
  mIsCheckedAll = null;
  mode = "add";

  constructor(onReadChangeEvent, onWriteChangeEvent, onForceUpdate, onIsCheckedAll, mode) {
    this.mReadEvent = onReadChangeEvent;
    this.mWriteEvent = onWriteChangeEvent;
    this.mForceUpdate = onForceUpdate;
    this.mIsCheckedAll = onIsCheckedAll;
    this.mode = mode;
  }

  tWriteChangeEvent = (idx, e) => {
    // console.log(idx, e.checked);
    this.mWriteEvent(idx, e.checked);
  };

  tReadChangeEvent = (idx, e) => {
    // console.log(idx, e.checked);
    this.mReadEvent(idx, e.checked);
  };

  tForceUpdate = () => {
    this.mForceUpdate();
  };

  tIsCheckedAll = (from, end) => {
    return this.mIsCheckedAll(from, end);
  };

  ret_table = (data) => {
    // if (data.id === 2 && data.func_name === "지점관리 탭") {
    //   return <></>;
    // } else {
    return (
      <CustomRow style={{ width: "100%" }}>
        {/* {data.range_end ? (
            <CustomTableContents width="13%" rowSpan={data.range_end - data.id + 1}>
              <CheckBox
                // defaultValue={data.is_read}
                isTable={true}
                defaultValue={this.tIsCheckedAll(data.id, data.range_end)}
                onChangeCallback={(e) => {
                  for (let i = data.id; i < data.range_end + 1; i++) {
                    this.tReadChangeEvent(i, e);
                    this.tWriteChangeEvent(i, e);
                    this.tForceUpdate();
                  }
                }}
              />
            </CustomTableContents>
          ) : (
            <></>
          )} */}
        <CustomTableContents width="8%">{data.id}</CustomTableContents>
        <CustomTableContents width="18%">{data.menu_name}</CustomTableContents>
        <CustomTableContents width="55%">{data.func_name}</CustomTableContents>

        <CustomTableContents width="4%">
          <CheckBox
            labelText={""}
            defaultValue={data.is_read}
            isTable={true}
            onChangeCallback={(e) => {
              this.tReadChangeEvent(data.id, e);
              this.tForceUpdate();
            }}
          />
        </CustomTableContents>
        <CustomTableContentsEND align="center" width="4%">
          <CheckBox
            labelText={""}
            defaultValue={data.is_write}
            isTable={true}
            onChangeCallback={(e) => {
              this.tWriteChangeEvent(data.id, e);
              this.tForceUpdate();
            }}
          />
        </CustomTableContentsEND>
      </CustomRow>
    );
    // }
  };
  columns_head = [
    // { title: "일괄변경", field: "check_all" },
    { title: "번호", field: "id" },
    { title: "메뉴 분류", field: "menu_name" },
    { title: "기능 분류", field: "func_name" },
    { title: "읽기", field: "is_read" },
    { title: "쓰기", field: "is_write" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    } else {
      if (this.mode === "edit") {
        return stateData;
        // return [];
      } else {
        return stateData;
      }
    }
  };
}
// function createData(id, menu_name, func_name, is_read, is_write) {
//   return {
//     id,
//     menu_name,
//     func_name,
//     is_read,
//     is_write,
//   };
// }

// const rest_data = [createData(0, "Cupcake", "Cupcake", false, false)];
