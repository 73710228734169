import IconButton from "components/Button/IconButton";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";

const SmsAuthTable = ({ viewData }) => {
  const resultTableInst = new resultTable();

  return (
    <div style={{ marginTop: "0.5rem" }}>
      <div style={{ display: "flex", marginBottom: "0.5rem" }}>
        <IconButton type="border" />
        <Label labelText="문자 인증 목록" marginLeft="0.5rem" fontWeight="bold" />
      </div>

      <CustomTable
        columns_head={resultTableInst.columns_head}
        table_title={resultTableInst.table_title}
        rest_call={resultTableInst.get_data_from_rest}
        row_render={resultTableInst.create_table}
        rest_data={viewData}
        height="18rem"
      />
    </div>
  );
};
export default SmsAuthTable;

class resultTable {
  table_title = "";

  create_table = (data, idx) => {
    return (
      <CustomRow style={{}} key={idx}>
        <CustomTableContents>{data.member_name}</CustomTableContents>
        <CustomTableContents>{data.auth_at.slice(0, 10)}</CustomTableContents>
        <CustomTableContents>{data.uuid.slice(-12)}</CustomTableContents>
        <CustomTableContents>{data.auth_at.slice(11, 19)}</CustomTableContents>
        <CustomTableContents>{data.accumulated_cnt}</CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "이름", width: "16.6%" },
    { title: "날짜", width: "16.6%" },
    { title: "UUID (끝 12자리)", width: "16.6%" },
    { title: "문자 인증 시간", width: "16.6%" },
    { title: "누적 인증 횟수", width: "16.6%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
