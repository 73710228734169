import { checkDateOrder, getDefaultValue } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import { useEffect, useState } from "react";
import { SearchConditionBox, SearchContainer, SearchRowBox } from "../style";

const DetailCondition = ({
  searchParams,
  setSearchParams,
  branchList,
  resetFlag,
  onClickSearch,
}) => {
  const labelWidth = "5.5rem";
  const textBoxWidth = "17.75rem";
  const textBoxWidthShort = "8.5rem";
  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);

  // 초기화 시 지점 첫번째 값 세팅
  useEffect(() => {
    if (!isFirstEnterPage && branchList.branch_list[0]) {
      setSearchParams("branch_info", branchList.branch_list[0]);
    }
  }, [resetFlag]);

  useEffect(() => {
    setIsFirstEnterPage(false);
  }, []);

  return (
    <SearchContainer>
      <Label labelText="검색 조건 : 상세" fontWeight="bold" justifyContent="flex-start" />
      <SearchRowBox
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onClickSearch(false, 1);
          }
        }}
      >
        <SearchConditionBox width="26.5%">
          <SearchComboBox
            labelText="지점"
            labelWidth={labelWidth}
            comboItemWidth={textBoxWidth}
            data={branchList.branch_name_list}
            defaultValue={getDefaultValue("combo", searchParams.branch_info.branch_name)}
            onChangeCallback={(e, v, idx) => {
              setSearchParams("branch_info", branchList.branch_list[idx]);
            }}
          />
        </SearchConditionBox>

        <SearchConditionBox width="26.5%">
          <DatePicker
            labelText="결제 신청일"
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidthShort}
            textMarginRight="0rem"
            defaultValue={getDefaultValue("field", searchParams.pay_req_date_from)}
            onChangeCallback={(e) => {
              setSearchParams("pay_req_date_from", e);
            }}
            onBlur={(e) => {
              if (!checkDateOrder(e, searchParams.pay_req_date_to)) {
                setSearchParams("pay_req_date_from", "");
              }
            }}
          />
          <DatePicker
            marginLeft="0.12rem"
            marginRight="4rem"
            labelWidth="0.5rem"
            textBoxWidth={textBoxWidthShort}
            textMarginRight="0rem"
            labelMarginRight="0.12rem"
            labelText="~"
            defaultValue={getDefaultValue("field", searchParams.pay_req_date_to)}
            onChangeCallback={(e) => {
              setSearchParams("pay_req_date_to", e);
            }}
            onBlur={(e) => {
              if (!checkDateOrder(searchParams.pay_req_date_from, e)) {
                setSearchParams("pay_req_date_to", "");
              }
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox width="26.5%">
          <ComboBox
            labelText="결제 상태"
            labelWidth={labelWidth}
            comboItemWidth={textBoxWidth}
            data={["전체", "성공", "실패"]}
            defaultValue={getDefaultValue("combo", searchParams.payment_state)}
            onChangeCallback={(e) => {
              setSearchParams("payment_state", e.target.value);
            }}
          />
        </SearchConditionBox>
      </SearchRowBox>
    </SearchContainer>
  );
};
export default DetailCondition;
