const initialStete = {
  stores: {
    data: {
      branch_id: "",
      branch_name: "",
      count_info: { almost: 0, broken: 0, expired: 0, ready: 0, total: 0, un_passwd: 0, used: 0 },
      floor_info: [],
      id: "",
      permission_branch_list: [
        {
          branch_id: "000000000000000000000000",
          branch_name: "선택해주세요",
        },
      ],
      allocate_list: [],
    },
  },
};
export const locker_setting = (state = initialStete, action) => {
  switch (action.type) {
    case "LOAD_LOCKER_SETTING_SUCCESS": {
      return { ...state, stores: action.payload };
    }
    case "LOAD_LOCKER_SETTING_FAIL":
    default:
      return state;
  }
};

export const locker_setting_show = (state = initialStete, action) => {
  switch (action.type) {
    case "LOAD_LOCKER_SETTING_SHOW_SUCCESS": {
      return { ...state, stores: action.payload };
    }
    case "LOAD_LOCKER_SETTING_SHOW_FAIL":
    default:
      return state;
  }
};

export const locker_allocate_show = (state = initialStete, action) => {
  switch (action.type) {
    case "LOAD_LOCKER_ALLOCATE_SHOW_SUCCESS": {
      return { ...state, stores: action.payload };
    }
    case "LOAD_LOCKER_ALLOCATE_SHOW_FAIL":
    case "LOAD_LOCKER_ALLOCATE_SHOW_RESET":
      return { ...state, stores: undefined };
    default:
      return state;
  }
};

const initialDetailState = {
  stores: {
    data: {
      active: 0,
      broken_date: "init",
      broken_desc: "",
      idx: 0,
      level: 0,
      num: 0,
      password: "",
      status: "",
      member_info: {
        member_id: "",
        name: "",
        phone: "",
        from_date: "",
        to_date: "",
      },
    },
  },
};
export const locker_detail_show = (state = initialDetailState, action) => {
  switch (action.type) {
    case "LOAD_LOCKER_DETAIL_SHOW_SUCCESS": {
      return { ...state, stores: action.payload };
    }
    case "LOAD_LOCKER_DETAIL_SHOW_FAIL":
    case "LOAD_LOCKER_DETAIL_SHOW_RESET":
      return { ...state, stores: initialDetailState.stores };
    default:
      return state;
  }
};
