import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import _ from "lodash";
import PaymentModalB from "pages/ERP/MemberCommonItems/PaymentModalB/PaymentModalB";
import { useEffect, useState } from "react";

const SubsRefundModalIndex = ({ modalSelect, modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();
  const [paramData, setParamData] = useState(modalParam.modalParam);

  useEffect(() => {
    const editData = _.cloneDeep(modalParam.modalParam);
    setParamData(editData);
  }, []);

  if (modalSelect === "결제") {
    return <PaymentModalB modalParam={paramData} onModalDone={onModalDone} />;
  } else {
    return <div>current ELSE</div>;
  }
};
export default SubsRefundModalIndex;
