import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CardFixed from "components/Card/CardFixed";
import Label from "components/LabelInput/Label";
import React, { useEffect } from "react";

// MUI 커스텀
const theme = createTheme({
  components: {
    MuiTreeItem: {
      styleOverrides: {
        label: {
          fontFamily: "AppleSDGothicNeo",
          fontSize: "0.938rem",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "normal",
          letterSpacing: "normal",
          textAlign: "left",
          color: "#000",
          height: "2.1rem",
          display: "flex",
          alignItems: "center",
        },
      },
    },
  },
});

const TreeChecker = ({ baseData, treeData, onTreeSelect }) => {
  const [rootTree, setRootTree] = React.useState("");
  const [childTree, setChildTree] = React.useState([]);
  const [expanded, setExpanded] = React.useState([]);
  const [selected, setSelected] = React.useState([]);

  useEffect(() => {
    setRootTree("본부 : " + baseData.hqBaseData.hq_info.hq_name);
    setExpanded(["1"]);
  }, [baseData.hqBaseData]);

  useEffect(() => {
    setChildTree([...treeData]);
  }, [treeData]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    if (event.target.nodeName === "svg") {
      return;
    }

    setSelected(nodeIds);
    if (nodeIds !== "1") {
      onTreeSelect(Number(nodeIds) - 2);
    }
  };

  return (
    <div>
      <Label
        labelText="지점 선택"
        justifyContent="flex-start"
        fontWeight="bold"
        marginTop="0.75rem"
      />
      <CardFixed
        midDom={
          <ThemeProvider theme={theme}>
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              selected={selected}
              expanded={expanded}
              onNodeSelect={handleSelect}
              onNodeToggle={handleToggle}
            >
              <TreeItem nodeId="1" label={rootTree}>
                {childTree.map((data, idx) => {
                  return (
                    <TreeItem
                      key={data.branch_id}
                      nodeId={(idx + 2).toString()}
                      label={data.branch_name}
                    />
                  );
                })}
              </TreeItem>
            </TreeView>
          </ThemeProvider>
        }
        isLoading={false}
        rootStyle={{
          overflowY: "auto",
          width: "90%",
          height: "30rem",
          marginTop: "0.5rem",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />
    </div>
  );
};
export default TreeChecker;
