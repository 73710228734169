import * as ActionTypes from "../actions/action_type";

const paramInitialStete = {
  stores: {
    data: {
      hq_list: ["선택없음"],
      branch_list: [["선택없음"]],
      target_list: [],
    },
  },
};
const initialStete = {
  stores: {
    data: [],
  },
};

export const search_result_member = (state = initialStete, action) => {
  switch (action.type) {
    case ActionTypes.getSearchMemberSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getSearchMemberFailAction:
    case ActionTypes.getSearchMemberResetAction:
      return { ...state, stores: initialStete.stores };
    default:
      return state;
  }
};

export const search_result_staff = (state = initialStete, action) => {
  switch (action.type) {
    case ActionTypes.getSearchStaffSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getSearchStaffFailAction:
    case ActionTypes.getSearchStaffResetAction:
      return { ...state, stores: initialStete.stores };
    default:
      return state;
  }
};

///permission..

export const search_param_member = (state = paramInitialStete, action) => {
  switch (action.type) {
    case ActionTypes.getSearchMemberParamSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getSearchMemberParamFailAction:
    case ActionTypes.getSearchMemberParamResetAction:
      return { ...state, stores: paramInitialStete.stores };
    default:
      return state;
  }
};
