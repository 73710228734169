import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, generateObjectDummyId } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import NumberTextField from "components/LabelInput/NumberTextField";
import TableTitleWithButton from "components/TableTitleWithButton/TableTitleWithButton";
import { globalAlertOn } from "modules/actions/Alert";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch } from "react-redux";
const PtCommissionCard = forwardRef((props, ref) => {
  const { workInfoStore /*, positionDetail, setPositionDetail*/ } = props;
  const [itemInfoList, setItemInfoList] = useState(workInfoStore.pt_commission);
  const [, updateState] = useState(); // 강제 렌더링용
  const forceUpdate = useCallback(() => updateState({}), []);
  const storesDispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    getItemList: () => {
      return itemInfoList;
    },
  }));

  useEffect(() => {
    setItemInfoList(workInfoStore.pt_commission);
  }, [workInfoStore.pt_commission]);

  const btnItemPush = () => {
    const baseItemInfo = {
      id: generateObjectDummyId(),
      idx: 0,
      from_section:
        workInfoStore.pt_commission.length === 0
          ? 0
          : workInfoStore.pt_commission[workInfoStore.pt_commission.length - 1].to_section + 1,
      to_section:
        workInfoStore.pt_commission.length === 0
          ? 1
          : workInfoStore.pt_commission[workInfoStore.pt_commission.length - 1].to_section + 2,
      rate: 0.0,
    };

    if (itemInfoList.length === 0) {
      setItemInfoList([baseItemInfo]);
    } else {
      setItemInfoList([...itemInfoList, baseItemInfo]);
    }
    workInfoStore.pt_commission.push(baseItemInfo);
    // setPositionDetail({
    //   ...positionDetail,
    //   pt_commission: workInfoStore.pt_commission,
    // });
  };

  const btnItemPop = (targetFromSection) => {
    var array = [...itemInfoList]; // make a separate copy of the array
    const index = array.map((item) => item.from_section).indexOf(targetFromSection);
    if (index !== -1) {
      array.splice(index, 1);

      const lastIdx = array.length - 1;
      for (let i = 0; i < lastIdx; i++) {
        if (i !== lastIdx) {
          const curItem = array[i];
          const nextItem = array[i + 1];

          nextItem.from_section = curItem.to_section + 1;
          if (nextItem.to_section < nextItem.from_section) {
            nextItem.to_section = nextItem.from_section + 1;
          }
        }
      }
      workInfoStore.pt_commission = array;
      setItemInfoList(array);
    }
  };

  const onChangeToSection = (idx, val) => {
    if (val <= workInfoStore.pt_commission[idx].from_section) {
      // setItemInfoList(
      //   itemInfoList.map((item) =>
      //     item.idx === idx ? { ...item, to_section: item.from_section + 1 } : item
      //   )
      // );

      // workInfoStore.pt_commission[idx].to_section = workInfoStore.pt_commission[idx].from_section + 1;
      document.getElementById("commission" + idx).focus();
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="종료금액은 시작금액보다 커야 합니다." />
            </>
          ),
          // width: "50rem",
          onBtnOk: () => {},
        }),
      );
    } else {
      const lastIdx = workInfoStore.pt_commission.length - 1;
      for (let i = idx; i < lastIdx; i++) {
        if (i !== lastIdx) {
          const curItem = workInfoStore.pt_commission[i];
          const nextItem = workInfoStore.pt_commission[i + 1];

          nextItem.from_section = curItem.to_section + 1;
          // setItemInfoList(
          //   itemInfoList.map((item) =>
          //     item.idx === idx + 1 ? { ...item, from_section: Number(val) + 1 } : item
          //   )
          // );

          if (nextItem.to_section < nextItem.from_section) {
            nextItem.to_section = nextItem.from_section + 1;
          }
        }
      }
    }

    setItemInfoList(workInfoStore.pt_commission);
    forceUpdate();
  };

  const itemList = new itemInfoTable(btnItemPop, onChangeToSection);

  return (
    <div>
      <TableTitleWithButton
        titleLabel={"PT 매출 커미션"}
        buttonText={"추가"}
        onClickEvent={btnItemPush}
      />

      <CustomTable
        columns_head={itemList.columns_head}
        table_title={itemList.table_title}
        rest_call={itemList.get_data_from_rest}
        row_render={itemList.create_table}
        rest_data={itemInfoList}
      />
    </div>
  );
});
export default PtCommissionCard;

class itemInfoTable {
  table_title = "commission";
  btnStyleClass = btnStyles();
  btnMethodPop = null;
  onChangeToSection = null;

  constructor(btnMethodPop, onChangeToSection) {
    this.btnMethodPop = btnMethodPop;
    this.onChangeToSection = onChangeToSection;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx} style={{ width: "100%" }}>
        <CustomTableContents width="35%">
          <div style={{ display: "flex" }}>
            <NumberTextField
              isNumber
              endAdornment={"원"}
              labelMarginRight="0rem"
              textMarginRight="0.25rem"
              defaultValue={data.from_section || "0"}
              onChangeCallback={(e) => {
                data.from_section = e;
              }}
              disabled
            />
          </div>
        </CustomTableContents>

        <CustomTableContents width="35%">
          <div style={{ display: "flex" }}>
            <NumberTextField
              isNumber
              id={this.table_title + idx}
              endAdornment={"원"}
              labelMarginRight="0rem"
              textMarginRight="0.25rem"
              defaultValue={data.to_section}
              onChangeCallback={(e) => {
                data.to_section = e;
              }}
              onBlur={(e) => {
                this.onChangeToSection(idx, e);
              }}
            />
          </div>
        </CustomTableContents>

        <CustomTableContents width="20%">
          <div style={{ display: "flex" }}>
            <NumberTextField
              isNumber
              endAdornment={"%"}
              labelMarginRight="0rem"
              textMarginRight="0.25rem"
              maxValue={100}
              defaultValue={data.rate}
              onChangeCallback={(e) => {
                data.rate = e;
              }}
            />
          </div>
        </CustomTableContents>

        <CustomTableContents width="5%">
          {idx === 0 ? (
            <div style={{ width: "4.5rem" }}></div>
          ) : (
            <IconButton
              type="delete"
              onClick={() => {
                this.btnMethodPop(data.from_section);
              }}
            />
          )}
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "매출구간 시작금액", field: "select" },
    { title: "매출구간 종료금액", field: "pay_method" },
    { title: "커미션율", field: "pay_method" },
    { title: "삭제", field: "remove" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
