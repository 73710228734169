import { dataEmpty, getPermission } from "components/CommonLib/CommonLib";
import _ from "lodash";
import { globalModalOn } from "modules/actions/Modal";
import { hq_branch_search_init_state } from "pages/CommonModal/Search/Module/reducers/hq_branch_search_init_state";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CardManageModalIndex from "../Modal/CardManageModalIndex";
import { AccessCardAction, loadCardDetailInfoStore } from "../Module/actions";
import CardInfoView from "./CardInfoView";

const CardManageInfoWrapper = ({ getState }) => {
  const storesDispatch = useDispatch();

  const [hqBranchData, setHqBranchData] = useState(_.cloneDeep(hq_branch_search_init_state));

  useEffect(() => {
    storesDispatch(loadCardDetailInfoStore(getState.card_info_id)); //카드상세 GET
    getHqBranchCombo();
    return () => {
      storesDispatch(AccessCardAction.loadCardDetailInfoReset());
    };
  }, []);

  const getHqBranchCombo = async () => {
    const result = await getPermission(storesDispatch, "ACCESS_MENU/MANAGE", true);
    setHqBranchData(result);
  };

  const openModal = (select, param, doneCallback) => {
    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,
        Content: (
          <CardManageModalIndex
            modalSelect={select}
            modalParam={param}
            onModalDone={doneCallback}
          />
        ),
      }),
    );
  };

  const onClickOpenModal = (order, param, doneCallback) => {
    if (order !== "") {
      if (dataEmpty(doneCallback)) {
        openModal(order, param, console.log("error"));
      } else {
        openModal(order, param, doneCallback);
      }
    }
  };

  return (
    <CardInfoView
      onClickOpenModal={onClickOpenModal}
      hqBranchData={hqBranchData}
      cardId={getState.card_info_id}
    />
  );
};
export default CardManageInfoWrapper;
