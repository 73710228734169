import { contentTypeJson, simpleAlert } from "components/CommonLib/CommonLib";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useState } from "react";
import { useDispatch } from "react-redux";

const LockerBrokenModal = ({ modalParam, onModalDone }) => {
  //modalParam: zone_id, locker_total_idx, member_info
  const storesDispatch = useDispatch();

  const [brokenInfo, setBrokenInfo] = useState({
    broken_date: "",
    broken_desc: "",
  });
  const [loading, setLoading] = useState(false);

  //고장등록 POST
  const registerBrokenLocker = async () => {
    try {
      const formData = new FormData();
      formData.append("zone_id", modalParam.zone_id);
      formData.append("locker_total_idx", modalParam.locker_total_idx);
      formData.append("member_info", JSON.stringify(modalParam.member_info));
      formData.append("broken_io", JSON.stringify(brokenInfo));

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_BRANCH_SERVER + "/locker/do_broken_status",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: (
              <>
                <Label labelText="고장 락커로 등록되었습니다." />
              </>
            ),
            onBtnOk: () => {
              //1. 회원정보에서 넘어오면 -> 락커상세 갱신, 상세이력 갱신
              //2. 락커에서 넘어오면 -> 락커상세 전체 갱신, 구락리 갱신, 모달 닫기
              onModalDone();
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const validationCheck = () => {
    if (!brokenInfo.broken_date || !brokenInfo.broken_desc) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="고장일과 고장사유를 입력해주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else {
      registerBrokenLocker();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      {loading && <Loading />}

      <DatePicker
        labelWidth={"5rem"}
        fullWidth
        labelText="고장일"
        onChangeCallback={(e) => {
          setBrokenInfo((cur) => {
            return { ...cur, broken_date: e };
          });
        }}
      />
      <TextField
        labelWidth={"5rem"}
        labelText={"고장사유"}
        fullWidth
        onBlur={(e) => {
          setBrokenInfo((cur) => {
            return { ...cur, broken_desc: e.target.value };
          });
        }}
      />

      <NormalBtn name="등록" onClick={validationCheck} />
    </div>
  );
};

export default LockerBrokenModal;
