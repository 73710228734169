import {
  changeComboValueToEmpty,
  checkDateOrder,
  generateObjectZeroId,
  getDefaultValue,
} from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import TimePicker from "components/LabelInput/TimePicker";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAccessMonitoringStore } from "../modules/store/monitoringManageState";
import { SearchContainer, SearchRowBox } from "../style";

const AccessSearchCard = ({ onClickSearch, hqBranchDoorCombo }) => {
  // 전역 상태 관리
  const { searchParams, setSearchParams, hqIdx, setHqIdx, branchIdx, setBranchIdx } =
    useAccessMonitoringStore().returnStoreByType("accessHist");

  return (
    <SearchContainer>
      <Label labelText="검색 조건" fontWeight="bold" justifyContent="flex-start" />
      <SearchCardRow1
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        hqIdx={hqIdx}
        setHqIdx={setHqIdx}
        branchIdx={branchIdx}
        setBranchIdx={setBranchIdx}
        hqBranchDoorCombo={hqBranchDoorCombo}
        onClickSearch={onClickSearch}
      />
      <SearchCardRow2
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        onClickSearch={onClickSearch}
      />
      <SearchCardRow3
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        onClickSearch={onClickSearch}
      />
    </SearchContainer>
  );
};
export default AccessSearchCard;

//
const SearchCardRow1 = ({
  searchParams,
  setSearchParams,
  hqIdx,
  setHqIdx,
  branchIdx,
  setBranchIdx,
  hqBranchDoorCombo,
  onClickSearch,
}) => {
  const labelWidth = "4.5rem";
  const textBoxWidth = "15rem";

  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);

  useEffect(() => {
    if (hqBranchDoorCombo.target_list.length > 0) {
      // 첫 검색 조건
      if (searchParams.hq_info.hq_id === generateObjectZeroId()) {
        setSearchParams("hq_info", hqBranchDoorCombo.target_list[0].hq_info);
        setSearchParams("branch_info", hqBranchDoorCombo.target_list[0].branch_list[0]);
        setSearchParams(
          "door_info",
          hqBranchDoorCombo.target_door_list[hqIdx].branch_list[0].door_list[0],
        );
      }
    }
  }, [hqBranchDoorCombo]);

  // 본부 선택 시 지점 정보 및 출입문 첫번째 값 세팅
  useEffect(() => {
    if (!isFirstEnterPage) {
      setBranchIdx(0);
      setSearchParams("branch_info", hqBranchDoorCombo.target_list[hqIdx].branch_list[0]);
      setSearchParams(
        "door_info",
        hqBranchDoorCombo.target_door_list[hqIdx].branch_list[0].door_list[0],
      );
    }
  }, [hqIdx]);

  // 지점 선택 시 출입문 세팅
  useEffect(() => {
    if (!isFirstEnterPage) {
      setSearchParams(
        "door_info",
        hqBranchDoorCombo.target_door_list[hqIdx].branch_list[branchIdx].door_list[0],
      );
    }
  }, [branchIdx]);

  // 첫 페이지 여부 체크
  useEffect(() => {
    setIsFirstEnterPage(false);
  }, []);

  return (
    <SearchRowBox
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onClickSearch(false, 1);
        }
      }}
    >
      <ComboBox
        labelWidth={labelWidth}
        comboItemWidth={textBoxWidth}
        labelText="본부"
        defaultValue={getDefaultValue("combo", searchParams.hq_info.hq_name)}
        data={hqBranchDoorCombo && hqBranchDoorCombo.hq_list}
        onChangeCallback={(e, idx) => {
          setHqIdx(idx);
          setSearchParams("hq_info", hqBranchDoorCombo.target_list[idx].hq_info);
        }}
      />
      <SearchComboBox
        marginLeft="3.5rem"
        labelWidth={labelWidth}
        comboItemWidth={textBoxWidth}
        labelText="지점"
        defaultValue={getDefaultValue("combo", searchParams.branch_info.branch_name)}
        data={hqBranchDoorCombo.branch_list[hqIdx]}
        onChangeCallback={(e, v, idx) => {
          setBranchIdx(idx);
          setSearchParams("branch_info", hqBranchDoorCombo.target_list[hqIdx].branch_list[idx]);
        }}
      />

      <ComboBox
        marginLeft="3.5rem"
        labelWidth={labelWidth}
        comboItemWidth={"17.65rem"}
        labelText="출입문"
        defaultValue={getDefaultValue("combo", searchParams.door_info?.door_name)}
        disabled={branchIdx === undefined}
        data={hqBranchDoorCombo.door_list[hqIdx][branchIdx]}
        onChangeCallback={(e, idx) => {
          const door =
            hqBranchDoorCombo.target_door_list[hqIdx].branch_list[branchIdx].door_list[idx];
          setSearchParams("door_info", door);
        }}
      />
    </SearchRowBox>
  );
};

const SearchCardRow2 = ({ searchParams, setSearchParams, onClickSearch }) => {
  const storesDispatch = useDispatch();
  const labelWidth = "4.5rem";
  const textBoxWidth = "15rem";
  const textBoxWidthShort = "8.5rem";

  return (
    <SearchRowBox>
      <TextField
        labelWidth={labelWidth}
        textBoxWidth={textBoxWidth}
        labelText="카드번호"
        isStringNumber
        defaultValue={getDefaultValue("field", searchParams.card_no)}
        onChangeCallback={(e) => {
          setSearchParams("card_no", e);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onClickSearch(false, 1);
          }
        }}
      />
      <TextField
        marginLeft="3.5rem"
        labelWidth={labelWidth}
        textBoxWidth={textBoxWidth}
        labelText="시리얼번호"
        defaultValue={getDefaultValue("field", searchParams.card_serial_no)}
        onChangeCallback={(e) => {
          setSearchParams("card_serial_no", e);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onClickSearch(false, 1);
          }
        }}
      />

      <div style={{ display: "flex", alignItems: "center" }}>
        <DatePicker
          marginLeft="3.5rem"
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidthShort}
          textMarginRight="0rem"
          labelText="일시"
          defaultValue={getDefaultValue("field", searchParams.access_from_date)}
          onChangeCallback={(e) => {
            setSearchParams("access_from_date", e);
          }}
          onBlur={(e) => {
            if (!checkDateOrder(e, searchParams.access_to_date)) {
              setSearchParams("access_from_date", "");
            }
          }}
        />
        <TimePicker
          textBoxWidth={textBoxWidthShort}
          defaultValue={getDefaultValue("field", searchParams.access_from_time)}
          onChange={(e) => {
            setSearchParams("access_from_time", e);
          }}
        />
        <DatePicker
          textBoxWidth={textBoxWidthShort}
          textMarginRight="0rem"
          labelText="~"
          defaultValue={getDefaultValue("field", searchParams.access_to_date)}
          onChangeCallback={(e) => {
            setSearchParams("access_to_date", e);
          }}
          onBlur={(e) => {
            if (!checkDateOrder(searchParams.access_from_date, e)) {
              setSearchParams("access_to_date", "");
            }
          }}
        />
        <TimePicker
          textBoxWidth={textBoxWidthShort}
          defaultValue={getDefaultValue("field", searchParams.access_to_time)}
          onChange={(e) => {
            setSearchParams("access_to_time", e);
          }}
        />
      </div>
    </SearchRowBox>
  );
};

const SearchCardRow3 = ({ searchParams, setSearchParams, onClickSearch }) => {
  const labelWidth = "4.5rem";
  const textBoxWidth = "15rem";

  return (
    <SearchRowBox>
      <ComboBox
        labelWidth={labelWidth}
        comboItemWidth={textBoxWidth}
        labelText="사용자 그룹"
        data={["전체", "회원", "직원", "지점"]}
        defaultValue={getDefaultValue("combo", searchParams.user_group)}
        onChangeCallback={(e) => {
          changeComboValueToEmpty(setSearchParams, "user_group", e);
        }}
      />

      <TextField
        marginLeft="3.5rem"
        labelWidth={labelWidth}
        textBoxWidth={textBoxWidth}
        labelText="사용자명"
        defaultValue={getDefaultValue("field", searchParams.cur_user.user_name)}
        onChangeCallback={(e) => {
          const curUser = {
            ...searchParams.cur_user,
            user_name: e,
          };
          setSearchParams("cur_user", curUser);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onClickSearch(false, 1);
          }
        }}
      />

      {searchParams.user_group === "회원" || !searchParams.user_group ? (
        <TextField
          marginLeft="3.5rem"
          labelWidth={labelWidth}
          textBoxWidth={"17.65rem"}
          labelText="회원번호"
          defaultValue={getDefaultValue("field", searchParams.cur_user.member_no)}
          onChangeCallback={(e) => {
            const curUser = {
              ...searchParams.cur_user,
              member_no: e,
            };
            setSearchParams("cur_user", curUser);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClickSearch(false, 1);
            }
          }}
        />
      ) : (
        <TextField
          marginLeft="3.5rem"
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth}
          labelText="사번"
          defaultValue={getDefaultValue("field", searchParams.cur_user.sabun)}
          onChangeCallback={(e) => {
            const curUser = {
              ...searchParams.cur_user,
              sabun: e,
            };
            setSearchParams("cur_user", curUser);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClickSearch(false, 1);
            }
          }}
        />
      )}
    </SearchRowBox>
  );
};
