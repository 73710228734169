import { Button } from "@material-ui/core";
import { useMutation, useQuery } from "@tanstack/react-query";
import { deleteSalary, getSalaryList, getSalarySearchParam } from "apis/branch/salary_list_api";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import { simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CreateSalaryReport from "./Modal/CreateSalaryReport";
import SalaryListSearchCard from "./SalaryListSearchCard";
import SalaryListSearchResult from "./SalaryListSearchResult";
import { useSalaryListStore } from "./modules/store/salaryListState";

const SalaryListView = ({ navigate }) => {
  const storesDispatch = useDispatch();

  // 전역 상태 관리
  const {
    salaryListSearchParams: searchParams,
    setSearchParams,
    salaryListHqIdx: hqIdx,
    setPage,
    salaryListPage: page,
  } = useSalaryListStore();

  // 페이지 상태 관리
  const [hqBranchListData, setHqBranchListData] = useState({});
  const [branchList, setBranchList] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);
  // API 상태 관리
  const [isGetSalaryList, setIsGetSalaryList] = useState(false);
  const queryData = { page: page, searchParams: searchParams };

  // API Query
  // [GET] 급여 관리 검색 조건 받아오기 및 권한 데이터 세팅
  const { data: firstSearchParamData } = useQuery({
    queryKey: ["salaryList", "firstSearchParam"],
    queryFn: () => getSalarySearchParam(),
    enabled: isFirstEnterPage,
    retry: false,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });

  // [GET] 급여 관리 내역 리스트 검색
  const { isFetching, data: salaryListData } = useQuery({
    queryKey: ["salaryList", queryData],
    queryFn: () => getSalaryList(queryData),
    enabled: isGetSalaryList,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setIsGetSalaryList(false);
    },
  });

  // [DELETE] 급여 관리 내역 삭제
  const { mutate: mutateDeleteSalary } = useMutation({
    mutationFn: deleteSalary,
    onSuccess: () => {
      simpleAlert(storesDispatch, "알림", "삭제 되었습니다.", true, () => {
        setIsGetSalaryList(true);
      });
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });

  // API 받아온 데이터 세팅
  useEffect(() => {
    if (firstSearchParamData) {
      const { combo_selector } = firstSearchParamData.data.data;
      // 첫 검색 조건
      if (searchParams.hq_info.hq_id === "") {
        setSearchParams("hq_info", combo_selector.target_list[0].hq_info);
        setSearchParams("branch_info", combo_selector.target_list[0].branch_list[0]);
        setSearchParams("combo_selector", combo_selector);
      }
      // 본부 및 지점 정보
      setHqBranchListData(combo_selector);
      setBranchList(combo_selector.branch_list[hqIdx]);
    }

    // 검색 결과 데이터 세팅
    if (salaryListData) {
      setSearchResult(salaryListData.data.data);
      setTotalPage(salaryListData.data.total_page);
    }
  }, [firstSearchParamData, salaryListData]);

  // 본부 선택에 따른 지점 리스트 다시 세팅
  useEffect(() => {
    if (hqBranchListData.branch_list) setBranchList(hqBranchListData.branch_list[hqIdx]);
    //  첫 페이지 진입이 아니고 본부 변경 시 지점 초기화 세팅
    if (!isFirstEnterPage) {
      const branchParams = {
        branch_id: hqBranchListData.target_list[hqIdx].branch_list[0].branch_id,
        branch_name: hqBranchListData.target_list[hqIdx].branch_list[0].branch_name,
      };
      setSearchParams("branch_info", branchParams);
    }
  }, [hqIdx]);

  // 페이지 진입 시 결과 리스트
  useEffect(() => {
    if (isFirstEnterPage && searchParams.hq_info.hq_id !== "") {
      setIsGetSalaryList(true);
      setIsFirstEnterPage(false);
    }
  }, [searchParams.hq_info.hq_id]);

  // 페이지네이션
  useEffect(() => {
    if (page) {
      setIsGetSalaryList(true);
    }
  }, [page]);

  const openCreateModal = () => {
    const param = {};

    storesDispatch(
      globalModalOn({
        show: true,
        title: "급여 리포트 생성",
        Content: <CreateSalaryReport modalParam={param} onModalDone={onCreateModalDone} />,
      }),
    );
  };

  const onCreateModalDone = (isRefresh) => {
    storesDispatch(globalModalOff());
    // 급여 리스트 다시 받아오기
    if (isRefresh) {
      setIsGetSalaryList(true);
    }
  };

  const deleteRow = async (rowData) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: <Label labelText="삭제 하시겠습니까?" />,
        onBtnYes: () => {
          mutateDeleteSalary(rowData.salary_report_id);
        },
        onBtnNo: () => {},
      }),
    );
  };

  return (
    <div>
      {isFetching && <Loading />}

      <SearchCard
        searchParams={searchParams}
        hqBranchListData={hqBranchListData}
        branchList={branchList}
        setPage={setPage}
        onClickSearch={setIsGetSalaryList}
      />
      <SearchResultCard
        navigate={navigate}
        searchResult={searchResult}
        totalPage={totalPage}
        deleteRow={deleteRow}
        openCreateModal={openCreateModal}
      />
    </div>
  );
};
export default SalaryListView;

//
const SearchCard = ({ hqBranchListData, branchList, setPage, onClickSearch }) => {
  const btnStyleClass = btnStyles();

  return (
    <CardFixed
      midDom={
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <SalaryListSearchCard
              hqBranchListData={hqBranchListData}
              branchList={branchList}
              onClickSearch={onClickSearch}
            />
          </div>
          <div
            style={{
              display: "flex",
              float: "right",
              marginLeft: "auto",
              marginTop: "0.5rem",
            }}
          >
            <Button
              className={clsx({
                [btnStyleClass.btnRoot]: true,
                [btnStyleClass.buttonType6]: true,
              })}
              onClick={() => {
                setPage(1);
                onClickSearch(true);
              }}
            >
              검색
            </Button>
          </div>
        </div>
      }
      isLoading={false}
      rootStyle={{
        borderRadius: "10px",
        backgroundColor: "#fff",
        padding: "0.5rem 0.75rem 0.25rem 0.75rem",
      }}
    />
  );
};

const SearchResultCard = ({ navigate, searchResult, totalPage, deleteRow, openCreateModal }) => {
  return (
    <CardFixed
      midDom={
        <>
          <div style={{ display: "flex", justifyContent: "end", marginBottom: "0.5rem" }}>
            <NormalBtn name="급여 생성" width="6.25rem" onClick={() => openCreateModal()} />
          </div>
          <SalaryListSearchResult
            navigate={navigate}
            searchResult={searchResult}
            totalPage={totalPage}
            deleteRow={deleteRow}
          />
        </>
      }
      isLoading={false}
      rootStyle={{
        marginTop: "0.625rem",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};
