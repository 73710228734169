import { generateObjectZeroId } from "components/CommonLib/CommonLib";
import { create } from "zustand";

// 검색 조건 베이스
const baseSearchParams = {
  branch_info: {
    branch_id: generateObjectZeroId(),
    branch_name: "",
  },
  combo_item: {
    branch_info_list: [
      {
        branch_id: generateObjectZeroId(),
        branch_name: "",
      },
    ],
    branch_list: [""],
  },
  reg_from_date: "",
  reg_to_date: "",
  title: "",
  tm_from_date: "",
  tm_to_date: "",
  tm_manager_name: "",
  tm_status: "",
};

export const useTMListDetailStore = create((set) => ({
  // ========== State ===========
  // TM List Detail 검색 State
  tmListDetailSearchParams: {
    ...baseSearchParams,
  },
  tmListDetailPage: 1,

  // ========== Action ==========
  // TM List Detail 검색 조건 변경
  setSearchParams: (key, value) => {
    if (key === "useLocationState") {
      const [branchInfo, regDate, title, presetId] = value;

      set((state) => ({
        tmListDetailSearchParams: {
          ...state.tmListDetailSearchParams,
          branch_info: branchInfo,
          reg_date: regDate,
          title: title,
          preset_id: presetId,
        },
      }));
    } else {
      set((state) => ({
        tmListDetailSearchParams: {
          ...state.tmListDetailSearchParams,
          [key]: value,
        },
      }));
    }
  },

  // Page 값 변경
  setPage: (value) => {
    set({ tmListDetailPage: value });
  },

  // 검색 조건 리셋
  resetSearchParams: () => {
    set((state) => ({
      tmListDetailSearchParams: {
        ...state.tmListDetailSearchParams,
        tm_from_date: "",
        tm_to_date: "",
        tm_manager_name: "",
        tm_status: "전체",
      },
    }));
  },
}));
