import IconButton from "components/Button/IconButton";
import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import TextField from "components/LabelInput/TextField";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { exportToAttendanceExcel } from "../MakeExcel";
import { loadSalaryInfoViewStore, loadStaffStandardSalaryViewStore } from "../Module/actions";

const SalaryBasicInfoView = ({
  onClickOpenModal,
  salaryReportId,
  is_edit,
  baseDate,
  branchName,
}) => {
  const storesDispatch = useDispatch();
  const restData = useSelector((state) => state.staff_service.salary_staff_standard);

  useEffect(() => {
    storesDispatch(loadStaffStandardSalaryViewStore(salaryReportId, is_edit));
  }, [salaryReportId]);

  const getAttendanceExcelData = async (salaryReportId) => {
    try {
      if (salaryReportId === undefined) {
        return;
      }
      const res = await HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + "/salary/excel", {
        params: {
          salary_report_id: salaryReportId,
        },
      });
      if (!restEmpty(res.data.data)) {
        exportToAttendanceExcel(res.data, `${branchName}_${baseDate}_근무_현황`, baseDate);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  const getAttendanceDetail = async (data) => {
    try {
      if (data.staff_salary_id === undefined) {
        return;
      }
      const res = await HttpInstance.get(
        process.env.REACT_APP_SALARY_SERVER + "/salary/staff/attendance",
        {
          params: {
            salary_report_id: data.salary_report_id,
            staff_id: data.staff_id,
            staff_salary_id: data.staff_salary_id,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        const param = {
          title: "당월 근태 상세 보기",
          modalParam: {
            data: res.data.data,
            is_edit,
            salaryReportId,
          },
        };
        onClickOpenModal("근태상세보기", param, refreshAllView);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  const refreshAllView = (data) => {
    getAttendanceDetail(data);
    storesDispatch(loadStaffStandardSalaryViewStore(salaryReportId, is_edit)); //기본급탭 갱신
    storesDispatch(loadSalaryInfoViewStore(salaryReportId, is_edit)); //메인 갱신
  };

  //row클릭 -> row 근태상세 GET
  const onRowClickGetDetail = (data) => {
    getAttendanceDetail(data);
  };

  // 엑셀 다운로드 여부 체크 모달
  const onClickOpenExcelDownloadModal = (salaryReportId) => {
    simpleAlert(
      storesDispatch,
      "알림",
      `"${branchName}_${baseDate}_근무_현황"\n 엑셀 파일을 다운로드 하시겠습니까?`,
      false,
      undefined,
      () => {
        getAttendanceExcelData(salaryReportId);
      },
    );
  };

  const staffSalaryTableItem = new staffSalaryStatusTable(onRowClickGetDetail);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <SubTitle titleText="급여 현황" />
        </div>
        <div>
          <NormalBtn
            name="엑셀 다운로드"
            theme="green"
            onClick={() => {
              onClickOpenExcelDownloadModal(salaryReportId);
            }}
          />
        </div>
      </div>
      <div style={{ marginTop: "0.5rem" }}>
        <CustomTable
          columns_head={staffSalaryTableItem.columns_head}
          table_title={staffSalaryTableItem.table_title}
          rest_call={staffSalaryTableItem.get_data_from_rest}
          row_render={staffSalaryTableItem.create_table}
          rest_data={restData.stores.data}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem" }}>
        <TextField
          labelWidth={"7rem"}
          textBoxWidth={"9rem"}
          textMarginRight="0rem"
          labelText="최종 지급액 합계"
          endAdornment={" 원"}
          defaultValue={restData.stores.final_total_salary.toLocaleString()}
          isNumber
          disabled
        />
      </div>
    </div>
  );
};
export default SalaryBasicInfoView;

class staffSalaryStatusTable {
  table_title = "";
  onRowClickGetDetail = null;

  constructor(onRowClickGetDetail) {
    this.onRowClickGetDetail = onRowClickGetDetail;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.personal_info.sabun}</CustomTableContents>
        <CustomTableContents>{data.personal_info.staff_name}</CustomTableContents>
        <CustomTableContents>{data.staff_info.staff_pos}</CustomTableContents>
        <CustomTableContents>{data.standard_salary.work_info.wage_type}</CustomTableContents>
        <CustomTableContents $isNumber>
          {Math.floor(data.standard_salary.total_work_time * 10) / 10}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.standard_salary.work_info.wage_type === "시급제"
            ? data.standard_salary.work_info.wage.toLocaleString() + " 원"
            : ""}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.standard_salary.work_info.wage_type === "월급제"
            ? data.standard_salary.salary_info.salary.toLocaleString() + " 원"
            : ""}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.standard_salary.salary_info.final_salary.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContentsEND>
          <IconButton
            type="attendance"
            tooltip="근태 상세보기"
            onClick={() => {
              this.onRowClickGetDetail(data);
            }}
          />
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "사번", width: "10%" },
    { title: "직원명", width: "10%" },
    { title: "직책", width: "10%" },
    { title: "급여 체계", width: "10%" },
    { title: "총 근무시간", width: "10%" },
    { title: "시급액", width: "13%" },
    { title: "기본급", width: "13%" },
    { title: "최종 지급액", width: "13%" },
    { title: "근태 상세보기", width: "7%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
