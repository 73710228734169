import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { listEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import CheckBox from "components/LabelInput/CheckBox";
import SubTitle from "components/PageTitle/SubTitle";

//
export const RefundPayMethodList = ({ payMethodList, onSelectPayment }) => {
  const refundPayMethodList = new refundPayMethodTable(onSelectPayment);

  return (
    <div
      style={{
        width: "45rem",
      }}
    >
      <SubTitle titleText={"취소할 결제 수단"} />
      <CustomTable
        columns_head={refundPayMethodList.columns_head}
        table_title={refundPayMethodList.table_title}
        rest_call={refundPayMethodList.get_data_from_rest}
        row_render={refundPayMethodList.create_table}
        rest_data={payMethodList}
      />
    </div>
  );
};

export class refundPayMethodTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onSelectPayment = null;

  constructor(onSelectPayment) {
    this.onSelectPayment = onSelectPayment;
  }

  create_table = (data) => {
    return (
      <CustomRow style={{ width: "100%" }}>
        <CustomTableContents width="15%">
          <CheckBox
            isTable
            defaultValue={data.payment_list_selector.selected}
            onChangeCallback={(e) => {
              data.payment_list_selector.selected = e.checked;
              this.onSelectPayment();
            }}
          />
        </CustomTableContents>
        <CustomTableContents width="55%">
          {data.payment_data.pay_method} ({data.payment_data.card_no})
        </CustomTableContents>
        <CustomTableContents width="30%">
          <div style={{ fontWeight: data.payment_data.paid_by === "tablet" ? "bold" : "" }}>
            {data.payment_data.price.toLocaleString()}
          </div>
        </CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "선택" },
    { title: "항목" },
    { title: "결제금액", tooltip: "Tablet에서 결제한 경우 Bold체로 표시됩니다" },
  ];

  get_data_from_rest = (stateData) => {
    if (listEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
