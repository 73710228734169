import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty } from "components/CommonLib/CommonLib";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";

export default class PtItemTable {
  table_title = "";
  btnStyleClass = btnStyles();

  onClickOpenModal = null;
  branchId = "";
  viewRefresh = null;
  onClickDelete = null;

  constructor(branchId, onClickOpenModal, viewRefresh, onClickDelete) {
    this.branchId = branchId;
    this.onClickOpenModal = onClickOpenModal;
    this.viewRefresh = viewRefresh;
    this.onClickDelete = onClickDelete;
  }

  onEdit = (item_id) => {
    const param = {
      title: "PT 상품 수정",
      modalParam: {
        mode: "edit",
        branchId: this.branchId,
        itemId: item_id,
        refreshStore: this.viewRefresh,
      },
    };
    this.onClickOpenModal("상품관리/PT", param);
  };

  create_table = (data) => {
    return (
      <CustomRow style={{ width: "100%" }}>
        <CustomTableContents width="5%">{data.base_info.order}</CustomTableContents>
        <CustomTableContents width="14%">{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents width="28%">{data.item_name}</CustomTableContents>
        <CustomTableContents width="10%" $isNumber>
          {data.item_price.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents width="10%" $isNumber>
          {data.session_cnt.toLocaleString() + " 회"}
        </CustomTableContents>
        <CustomTableContents width="10%" $isNumber>
          {data.service_session_cnt.toLocaleString() + " 회"}
        </CustomTableContents>
        <CustomTableContents width="6%">{data.base_info.sales_status}</CustomTableContents>
        <CustomTableContents width="6%">
          {data.base_info.sales_limit === true ? "Y" : "N"}
        </CustomTableContents>
        <CustomTableContents width="6%">
          {data.base_info.show_in_app === true ? "Y" : "N"}
        </CustomTableContents>
        <CustomTableContents width="5%">
          <div style={{ display: "flex" }}>
            <IconButton type="edit" width="2.2rem" onClick={() => this.onEdit(data.item_id)} />
            <IconButton
              type="delete"
              width="2.2rem"
              onClick={() => this.onClickDelete(data.item_name, data.item_id, "PT")}
              disabled={data.base_info.sales_status !== "판매대기"}
            />
          </div>
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "게시\n순서" },
    { title: "등록일시" },
    { title: "상품명" },
    { title: "판매가" },
    { title: "세션 수" },
    { title: "서비스 세션 수" },
    { title: "판매상태" },
    { title: "판매제한" },
    { title: "App 노출" },
    { title: "", field: "action" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
