import {
  loadSearchPtClassHist,
  loadSearchPtClassHistSuccess,
  loadSearchPtClassHistFail,
  loadSearchPtClassHistReset,
  loadSearchOtClassHist,
  loadSearchOtClassHistSuccess,
  loadSearchOtClassHistFail,
  loadSearchOtClassHistReset,
} from "./view_act";

export const loadSearchPtClassHistStore = (search_param) => loadSearchPtClassHist(search_param);
export const loadSearchOtClassHistStore = (search_param) => loadSearchOtClassHist(search_param);

export const classHistAction = {
  loadSearchPtClassHist,
  loadSearchPtClassHistSuccess,
  loadSearchPtClassHistFail,
  loadSearchPtClassHistReset,

  loadSearchOtClassHist,
  loadSearchOtClassHistSuccess,
  loadSearchOtClassHistFail,
  loadSearchOtClassHistReset,
};
