import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import { useEffect, useState } from "react";

const PtInfoCardItem = ({ ptList }) => {
  const [ptNameList, setPtNameList] = useState([]);
  const [ptIdx, setPtIdx] = useState(0);

  useEffect(() => {
    const nameList = ptList.map((el) => el.pt_info.item_name);
    setPtNameList(nameList);
  }, [ptList]);

  const currentPtTable = new ptStatusTable();

  return (
    <div style={{ marginBottom: "0.5rem", marginTop: "1.625rem" }}>
      <ComboBox
        labelWidth={"6rem"}
        fullWidth
        labelText="계약 상품"
        defaultValue={ptNameList[0]}
        data={ptNameList}
        onChangeCallback={(e) => {
          const index = ptNameList.findIndex((el) => el === e.target.value);
          if (index !== -1) {
            setPtIdx(index);
          }
        }}
      />
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <TextField
          labelWidth={"6rem"}
          fullWidth
          labelText="수업 담당자"
          defaultValue={
            ptList[ptIdx].cur_manager.staff_info.staff_name
              ? ptList[ptIdx].cur_manager.staff_info.staff_name
              : "담당자 없음"
          }
          disabled
        />
      </div>
      <div style={{ marginTop: "1rem" }}>
        <CustomTable
          columns_head={currentPtTable.columns_head}
          table_title={currentPtTable.table_title}
          rest_call={currentPtTable.get_data_from_rest}
          row_render={currentPtTable.create_table}
          rest_data={ptList[ptIdx].pt_info}
        />
      </div>
    </div>
  );
};

const PtInfo = ({ baseInfoState }) => {
  const CardTitle = () => {
    const remainPtCnt = baseInfoState.active_info.remain_pt_cnt;
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>PT</div>
        <div style={{ marginLeft: "0.5rem", fontSize: "0.7rem", color: "#777777" }}>
          {baseInfoState.pt_list.length !== 0 ? `※ 잔여횟수: ${remainPtCnt}회` : ""}
        </div>
      </div>
    );
  };

  return (
    <CardFixed
      topDom={<CardTitle />}
      midDom={
        baseInfoState.pt_list.length === 0 ? (
          <div
            style={{
              display: "flex",
              padding: "1rem 0",
              justifyContent: "center",
              alignItems: "center",
              height: "9rem",
            }}
          >
            <Label labelText="등록된 PT 상품이 없습니다." fontSize="1rem" />
          </div>
        ) : (
          <PtInfoCardItem ptList={baseInfoState.pt_list} />
        )
      }
      isLoading={false}
      rootStyle={{
        width: "98%",
        height: "17rem",
        marginLeft: "0.625rem",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};
export default PtInfo;

class ptStatusTable {
  table_title = "";
  btnStyleClass = btnStyles();

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents $isNumber>
          {data.base_session_cnt ? data.base_session_cnt + data.service_session_cnt + " 회" : ""}
        </CustomTableContents>
        <CustomTableContents $isNumber>{data.remain_session_cnt + " 회"}</CustomTableContents>
        <CustomTableContents $isNumber>
          {data.pt_used_cnt - data.pt_cancel_cnt + " 회"}
        </CustomTableContents>
        <CustomTableContents $isNumber>{data.pt_cancel_cnt + " 회"}</CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "전체", width: "16.5%" },
    { title: "잔여", width: "16.5%" },
    { title: "완료", width: "16.5%" },
    { title: "취소", width: "16.5%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return [stateData];
  };
}
