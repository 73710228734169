import { contentTypeJson } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

// ==================== GET ====================
// [GET] OT 수업 이력 리스트 검색
export async function getOtHistList(queryData) {
  const { page, searchParams, isExcel } = queryData;
  const formData = new FormData();

  formData.append("search_param", JSON.stringify(searchParams));
  formData.append("page_num", page);
  formData.append("is_excel", isExcel);

  return await HttpInstance.post(
    process.env.REACT_APP_STAFF_SERVER + "/class/ot_class_hist",
    formData,
    contentTypeJson,
  );
}
// [GET] PT 수업 이력 리스트 검색
export async function getPtHistList(queryData) {
  const { page, searchParams, isExcel } = queryData;
  const formData = new FormData();

  formData.append("search_param", JSON.stringify(searchParams));
  formData.append("page_num", page);
  formData.append("is_excel", isExcel);

  return await HttpInstance.post(
    process.env.REACT_APP_STAFF_SERVER + "/class/pt_class_hist",
    formData,
    contentTypeJson,
  );
}
// [GET] GX 수업 이력 리스트 검색
export async function getGxHistList(queryData) {
  const { page, searchParams } = queryData;
  const formData = new FormData();

  formData.append("search_param", JSON.stringify(searchParams));
  formData.append("page_num", page);

  return await HttpInstance.post(
    process.env.REACT_APP_STAFF_SERVER + "/class/gx_class_hist",
    formData,
    contentTypeJson,
  );
}
