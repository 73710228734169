import IconButton from "components/Button/IconButton";
import CardFixed from "components/Card/CardFixed";
import { dateToStr, makeNextDay, makePrevDay } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import { setMonth } from "date-fns";
import { globalModalOff } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DayCalendar from "./Calendar/DayCalendar";
import MonthCalendar from "./Calendar/MonthCalendar";
import WeekCalendar from "./Calendar/WeekCalendar";
import { calWeekStartDay } from "./calendarDateUtil";

const ScheduleCalendar = ({
  mode,
  setMode,
  searchParam,
  setSearchParam,
  onClickSearch,
  searchResult,
  setSearchResult,
  onClickOpenModal,
  setCurPage,
  totalPage,
  curPage,
  staffList,
}) => {
  const storesDispatch = useDispatch();
  const [isClickBtn, setIsClickBtn] = useState();
  const navigate = useNavigate();
  //모달DONE
  const refreshCalendar = (redirectPath) => {
    if (redirectPath) {
      storesDispatch(globalModalOff());
      navigate(redirectPath);
    } else {
      //1. 현재 서파로 mode에 따라 다시 검색
      onClickSearch(mode);
      //2. 모달 닫기
      storesDispatch(globalModalOff());
    }
  };

  //콤보 모드 바꾸기
  const filterMode = (category) => {
    if (category === "월간") {
      setSearchParam((cur) => {
        return {
          ...cur,
          search_month: dateToStr(new Date()).slice(0, 7),
          search_week_from_date: "",
          search_week_to_date: "",
          search_day_date: "",
        };
      });
      setMode("month");
    } else if (category === "주간") {
      setSearchParam((cur) => {
        //오늘 기준 -> 이번주초 일요일 날짜 계산
        const start = calWeekStartDay();
        //오늘 기준 -> 이번주 토요일 날짜 계산
        const end = dateToStr(new Date(new Date().setDate(new Date(start).getDate() + 6)));
        return {
          ...cur,
          search_month: "",
          search_week_from_date: start,
          search_week_to_date: end,
          search_day_date: "",
        };
      });
      setMode("week");
    } else {
      setSearchParam((cur) => {
        return {
          ...cur,
          search_month: "",
          search_week_from_date: "",
          search_week_to_date: "",
          search_day_date: dateToStr(new Date()),
        };
      });
      setMode("day");
    }
  };

  //PREV BTN
  const prevSearch = () => {
    if (mode === "month") {
      const thisMonth = searchParam.search_month ? new Date(searchParam.search_month) : new Date();
      const prevMonth = dateToStr(setMonth(thisMonth, thisMonth.getMonth() - 1)).slice(0, 7);
      setSearchParam((cur) => {
        return { ...cur, search_month: prevMonth };
      });
      setIsClickBtn((cur) => !cur);
    } else if (mode === "week") {
      //
      const thisWeekFrom = searchParam.search_week_from_date;
      const thisWeekTo = searchParam.search_week_to_date;

      const prevWeekFrom = dateToStr(
        new Date(new Date(thisWeekFrom).setDate(new Date(thisWeekFrom).getDate() - 7)),
      );
      const prevWeekTo = dateToStr(
        new Date(new Date(thisWeekTo).setDate(new Date(thisWeekTo).getDate() - 7)),
      );

      setSearchParam((cur) => {
        return { ...cur, search_week_from_date: prevWeekFrom, search_week_to_date: prevWeekTo };
      });
      setIsClickBtn((cur) => !cur);
    } else {
      const prevDay = makePrevDay(searchParam.search_day_date);
      setSearchParam((cur) => {
        return { ...cur, search_day_date: prevDay };
      });
      setIsClickBtn((cur) => !cur);
    }
  };

  //NEXT BTN
  const nextSearch = () => {
    if (mode === "month") {
      const thisMonth = searchParam.search_month ? new Date(searchParam.search_month) : new Date();
      const nextMonth = dateToStr(setMonth(thisMonth, thisMonth.getMonth() + 1)).slice(0, 7);
      setSearchParam((cur) => {
        return { ...cur, search_month: nextMonth };
      });
      setIsClickBtn((cur) => !cur);
    } else if (mode === "week") {
      const thisWeekFrom = searchParam.search_week_from_date;
      const thisWeekTo = searchParam.search_week_to_date;

      const nextWeekFrom = dateToStr(
        new Date(new Date(thisWeekFrom).setDate(new Date(thisWeekFrom).getDate() + 7)),
      );
      const nextWeekTo = dateToStr(
        new Date(new Date(thisWeekTo).setDate(new Date(thisWeekTo).getDate() + 7)),
      );

      setSearchParam((cur) => {
        return { ...cur, search_week_from_date: nextWeekFrom, search_week_to_date: nextWeekTo };
      });
      setIsClickBtn((cur) => !cur);
    } else {
      //
      const nextDay = makeNextDay(searchParam.search_day_date);
      setSearchParam((cur) => {
        return { ...cur, search_day_date: nextDay };
      });
      setIsClickBtn((cur) => !cur);
    }
  };

  //오늘 버튼 클릭 -> 현재 mode에서 오늘날짜 기준의 달력 검색
  const moveToToday = () => {
    if (mode === "month") {
      //오늘 기준 -> 이번달 ex) 2023-02
      setSearchParam((cur) => {
        return { ...cur, search_month: dateToStr(new Date()).slice(0, 7) };
      });
      setIsClickBtn((cur) => !cur);
    } else if (mode === "week") {
      //오늘 기준 -> 이번주초 일요일 날짜 계산
      const start = calWeekStartDay();
      //오늘 기준 -> 이번주 토요일 날짜 계산
      const end = dateToStr(new Date(new Date().setDate(new Date(start).getDate() + 6)));
      setSearchParam((cur) => {
        return { ...cur, search_week_from_date: start, search_week_to_date: end };
      });
      setIsClickBtn((cur) => !cur);
    } else {
      setSearchParam((cur) => {
        return { ...cur, search_day_date: dateToStr(new Date()) };
      });
      setIsClickBtn((cur) => !cur);
    }
  };

  //이전,다음,오늘 버튼 누르면 바뀐 서파로 검색
  useEffect(() => {
    if (isClickBtn !== undefined) {
      if (mode === "day") {
        if (curPage) {
          onClickSearch("day", curPage);
        } else {
          onClickSearch("day", 1);
        }
      } else {
        onClickSearch(mode);
      }
    }
  }, [isClickBtn]);

  //콤보 바뀌면 바뀐 서파로 검색
  useEffect(() => {
    onClickSearch(mode);
  }, [mode]);

  return (
    <div>
      <CardFixed
        midDom={
          <div>
            <div>
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <Label
                  labelText={`${
                    mode === "month" ? "월간" : mode === "week" ? "주간" : "일간"
                  } 보기`}
                  fontWeight="500"
                  fontSize="1rem"
                />
              </div>
              <div
                style={{ display: "flex", justifyContent: "space-between", marginTop: "0.7rem" }}
              >
                <IconButton
                  type="today"
                  onClick={(e) => {
                    moveToToday();
                  }}
                />

                <div style={{ display: "flex", gap: "0.7rem" }}>
                  <IconButton
                    type="preBtn"
                    onClick={() => {
                      prevSearch();
                    }}
                  />
                  <Label
                    labelText={
                      !searchParam.search_month && mode === "month"
                        ? dateToStr(new Date()).slice(0, 7)
                        : searchParam.search_month && mode === "month"
                        ? searchParam.search_month
                        : mode === "day"
                        ? searchParam.search_day_date
                        : searchParam.search_week_from_date +
                          " - " +
                          searchParam.search_week_to_date
                    }
                    fontWeight="bold"
                    fontSize="1.2rem"
                  />
                  <IconButton
                    type="nextBtn"
                    onClick={() => {
                      nextSearch();
                    }}
                  />
                </div>
                <div>
                  <ComboBox
                    labelWidth={"0rem"}
                    comboItemWidth={"9rem"}
                    data={["월간", "주간", "일간"]}
                    defaultValue={"월간"}
                    onChangeCallback={(e) => {
                      filterMode(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div>
                {mode === "month" ? (
                  <MonthCalendar
                    searchParam={searchParam}
                    searchResult={searchResult}
                    setSearchResult={setSearchResult}
                    onClickOpenModal={onClickOpenModal}
                    refreshCalendar={refreshCalendar}
                    month={searchResult.search_month}
                  />
                ) : mode === "week" ? (
                  <WeekCalendar
                    searchParam={searchParam}
                    searchResult={searchResult}
                    setSearchResult={setSearchResult}
                    onClickOpenModal={onClickOpenModal}
                    refreshCalendar={refreshCalendar}
                  />
                ) : (
                  <DayCalendar
                    searchParam={searchParam}
                    searchResult={searchResult}
                    setSearchResult={setSearchResult}
                    onClickOpenModal={onClickOpenModal}
                    refreshCalendar={refreshCalendar}
                    setCurPage={setCurPage}
                    totalPage={totalPage}
                    curPage={curPage}
                    staffList={staffList}
                  />
                )}
              </div>
            </div>
          </div>
        }
        isLoading={false}
        rootStyle={{
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />
    </div>
  );
};
export default ScheduleCalendar;
