import IconButton from "components/Button/IconButton";
import {
  contentTypeJson,
  getCurrentDate,
  restEmpty,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import {
  APPROVAL_TYPE_EXP,
  APPROVAL_TYPE_IMP,
  APPROVAL_TYPE_REFUND_TRANS,
} from "components/CommonLib/Consts";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { approval_doc_state } from "pages/ApprovalManage/Module/reducers/approval_doc_state";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import UnspentCommentForm from "./UnspentCommentForm";
import UnspentDetailDataTable from "./UnspentDetailDataTable";

const UnspentDetailModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [baseState, setBaseState] = useState(_.cloneDeep(approval_doc_state));
  const [contentState, setContentState] = useState();
  const [commentList, setCommentList] = useState([]);
  const [docName, setDocName] = useState("");
  const [enrollDate, setEnrollDate] = useState(getCurrentDate);
  const [isDisabled, setIsDisabled] = useState(false);

  const getApprovalContent = (docType, approvalId, docId) => {
    let getApi = "";
    switch (docType) {
      case APPROVAL_TYPE_EXP:
        getApi = "/approval/contents/expenditure";
        break;
      case APPROVAL_TYPE_IMP:
        getApi = "/approval/contents/implementation";
        break;
      case APPROVAL_TYPE_REFUND_TRANS:
        getApi = "/approval/contents/transfer_req";
        break;
      default:
        console.log("ERROR: docType is not defined");
        break;
    }

    if (approvalId) {
      setLoading(true);

      // 1) 전자결재 문서를 가져온다
      HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/approval/get_approval_info", {
        params: { approval_docs_id: approvalId },
      })
        .then((res) => {
          if (!restEmpty(res.data.data)) {
            setBaseState(res.data.data);

            // 승인 상태인 경우에만 지출 처리 가능
            setIsDisabled(res.data.data.approval_status !== "승인");

            // 결재의견 Set
            if (res.data.data.approval_comment_list) {
              setCommentList(res.data.data.approval_comment_list);
            }

            // 2) 전자결재 문서의 내용을 가져온다
            if (getApi !== "") {
              HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + getApi, {
                params: { doc_id: docId },
              })
                .then((res) => {
                  if (!restEmpty(res.data.data)) {
                    setContentState(res.data.data);
                    setDocName(res.data.data.doc_info.doc_name);
                  }
                })
                .catch((err) => {
                  console.log(err);
                  simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
                });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        })
        .finally((e) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getApprovalContent(modalParam.docType, modalParam.approvalDocsId, modalParam.docId);
  }, [modalParam]);

  const onClickSave = async () => {
    const formData = new FormData();
    formData.append("approval_docs_id", modalParam.approvalDocsId);
    formData.append("doc_id", modalParam.docId);
    formData.append("enroll_date", enrollDate);

    const doSave = async () => {
      setLoading(true);
      await HttpInstance.post(
        process.env.REACT_APP_SALARY_SERVER + "/cashbook/unspent/done",
        formData,
        contentTypeJson,
      )
        .then((res) => {
          if (res.data.msg) {
            simpleAlert(storesDispatch, "ERROR", res.data.msg);
          } else {
            simpleAlert(storesDispatch, "알림", "지출 처리가 완료되었습니다.", true, () => {
              onModalDone();
            });
          }
        })
        .catch((err) => {
          console.log(err);
          simpleAlert(
            storesDispatch,
            "ERROR",
            err.response.data ? err.response.data.msg : err.code,
          );
        })
        .finally((e) => {
          setLoading(false);
        });
    };

    simpleAlert(
      storesDispatch,
      "알림",
      "선택한 지출 완료일은 [" +
        enrollDate +
        "] 이며, 완료 후에는 되돌릴 수 없습니다.\n\n지출 처리하시겠습니까?",
      false,
      undefined,
      doSave,
    );
  };

  const getComment = () => {
    const emptyComment = {
      approval_status: "",
      reg_info: {
        created_at: "",
        reg_name: "",
      },
      comment: "",
    };

    if (baseState.comment_list) {
      if (baseState.comment_list.length === 0) {
        return emptyComment;
      } else {
        return baseState.comment_list[baseState.comment_list.length - 1];
      }
    }
  };

  return (
    <div style={{ width: "90rem" }}>
      {loading ? <Loading /> : <></>}
      <div style={{ display: "flex", marginBottom: "0.5rem" }}>
        <IconButton type="border" />
        <Label labelText="의견" marginLeft="0.5rem" fontWeight="bold" />
      </div>
      <div style={{ marginTop: "1.5rem" }}>
        <UnspentCommentForm commentItem={getComment()} />
      </div>

      <div style={{ margin: "0.5rem 0.1rem" }}>
        <div style={{ display: "flex", marginTop: "1rem", marginBottom: "0.5rem" }}>
          <IconButton type="border" />
          <Label labelText={docName} marginLeft="0.5rem" fontWeight="bold" />
        </div>
        {/* 전자결재에서의 Form */}
        {/* <ApprovalContentWrapper
          mode={"unspent"}
          curContent={getDocType(modalParam.docType)}
          baseState={baseState}
          contentState={contentState}
          //  setContentState={setContentState}
        /> */}
        <UnspentDetailDataTable contentState={contentState} />
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "2rem" }}>
        {isDisabled ? (
          <TextField
            labelText="지출 완료일"
            labelWidth="5rem"
            textBoxWidth="9rem"
            textMarginRight="0rem"
            disabled
          />
        ) : (
          <DatePicker
            labelText="지출 완료일"
            labelMarginRight="1rem"
            textMarginRight="0rem"
            defaultValue={getCurrentDate}
            minWidth="9rem"
            onChangeCallback={(e) => {
              setEnrollDate(e);
            }}
            disabled={isDisabled}
          />
        )}
        <NormalBtn
          margin="0 0 0 1rem"
          name="지출 처리"
          onClick={onClickSave}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};
export default UnspentDetailModal;
