import React, { useState, useEffect } from "react";
import PageFrame from "components/Panels/PageFrame";
import PageTitle from "components/PageTitle/PageTitle";
import { useLocation } from "react-router";
import { HttpInstance } from "lib/HttpLib";
import { restEmpty } from "components/CommonLib/CommonLib";
import SendMessageView from "./SendMessageView";
import _ from "lodash";

const SendMessageService = () => {
  const [baseState, setBaseState] = useState();

  // const getSettingsData = (branchId) => {
  //   HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/register/settings_show", {
  //     params: { branch_id: branchId },
  //   })
  //     .then((res) => {
  //       if (!restEmpty(res.data.data)) {
  //         setSettingsState(res.data.data);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  // simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
  //     });
  // };

  useEffect(() => {
    // getSettingsData();
  }, []);

  return (
    <div>
      <PageFrame
        menuActive="락커/셋팅"
        parentMenu={{ 락커: true }}
        children={
          <div>
            <PageTitle titleText="문자 발송" />
            <SendMessageView baseState={baseState} />
          </div>
        }
      />
    </div>
  );
};
export default SendMessageService;
