import { getPostRowState } from "pages/NoticeBoard/model/board_row";
import { getBoardRowAPi } from "pages/NoticeBoard/restApi/getApi";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReferenceDetailView from "./ReferenceDetailView";

const ReferenceDetailWrapper = ({ nowService, postId }) => {
  const storesDispatch = useDispatch();
  const [postRowState, setPostRowState] = useState(getPostRowState(nowService));
  useEffect(() => {
    getBoardRowAPi(storesDispatch, nowService, postId, setPostRowState);
  }, []);

  //게시글 상세 GET
  const getNoticeDetail = async () => {};
  return <ReferenceDetailView postRowState={postRowState} nowService={nowService} />;
};

export default ReferenceDetailWrapper;
