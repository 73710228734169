import TextArea from "components/LabelInput/TextArea";
import TextField from "components/LabelInput/TextField";

const UnspentCommentForm = ({ commentItem }) => {
  const labelWidth = "5rem";

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div style={{ width: "33%" }}>
          <TextField
            labelText="결재"
            labelWidth={labelWidth}
            labelMarginRight="0rem"
            textBoxWidth="20rem"
            defaultValue={commentItem.approval_status}
            disabled
          />
        </div>
        <div style={{ width: "33%", marginLeft: "1rem" }}>
          <TextField
            labelText="결재일시"
            labelWidth={labelWidth}
            labelMarginRight="0rem"
            textBoxWidth="20rem"
            defaultValue={commentItem.reg_info.created_at}
            disabled
          />
        </div>
        <div style={{ width: "33%", marginLeft: "1rem" }}>
          <TextField
            labelText="직원명"
            labelWidth={labelWidth}
            labelMarginRight="0rem"
            textBoxWidth="20rem"
            defaultValue={commentItem.reg_info.reg_name}
            disabled
          />
        </div>
      </div>

      <TextArea
        marginTop="0.5rem"
        labelWidth={labelWidth}
        labelText={"내용"}
        labelMarginRight="0rem"
        defaultValue={commentItem.comment}
        fullWidth
        minRows={3}
        maxRows={3}
        disabled
      />
    </div>
  );
};
export default UnspentCommentForm;
