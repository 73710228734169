import { HttpInstance } from "lib/HttpLib";

export const getSearchStaff = (param) => {
  // console.log(param);
  const formData = new FormData();
  formData.append("search_param", JSON.stringify(param.search_param));
  formData.append("page_num", JSON.stringify(param.page_num));
  formData.append("is_excel", JSON.stringify(param.is_excel));

  // return HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/staff/search_list", {
  //   params: {
  //     search_param: param.search_param,
  //     page_num: param.page_num,
  //     is_excel: param.is_excel,
  //   },
  // });
  return HttpInstance.post(process.env.REACT_APP_STAFF_SERVER + "/staff/search_list", formData);
};

const get_api = {
  getSearchStaff,
};

export default get_api;
