import { getCurrentDate, simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import NormalBtn from "components/NewButton/NormalBtn";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const OPTIONS = {
  CHANGE_PAY_METHOD: "결제수단 변경",
  REGULAR_PAY_METHOD_MGMT: "정기결제수단 관리",
  CHANGE_ITEM: "상품변경",
  REFUND: "환불",
  ADD_MEMBERSHIP_PERIOD: "회원권 기간 추가",
  SUBS_REFUND: "약정(정기결제) 해지",
  SUBS_PAY_CANCEL: "정기결제 해지 예약",
  MASTER_CHANGE: "수강 정보 변경",
};

const ChangeRefundModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const [option, setOption] = useState("");
  const [regDate, setRegDate] = useState(getCurrentDate());
  const [optionList, setOptionList] = useState([OPTIONS.CHANGE_PAY_METHOD, OPTIONS.REFUND]);

  useEffect(() => {
    const additionalAuth = [...optionList];
    if (modalParam.canSelectMasterChange && !additionalAuth.includes(OPTIONS.MASTER_CHANGE)) {
      additionalAuth.push(OPTIONS.MASTER_CHANGE);
    }
    if (modalParam.canSelectAddPeriod && !additionalAuth.includes(OPTIONS.ADD_MEMBERSHIP_PERIOD)) {
      additionalAuth.push(OPTIONS.ADD_MEMBERSHIP_PERIOD);
    }
    setOptionList(additionalAuth);
  }, []);

  //정기결제수단 관리, 정기결제해지예약, 수강정보변경, 회원권 기간 추가 only modal
  const onMoveMethod = () => {
    let actionInfo = {
      mode: "none",
      action: "",
    };

    switch (option) {
      case OPTIONS.CHANGE_PAY_METHOD:
        actionInfo = {
          mode: "href",
          action: "/change_pay_method",
          reg_date: regDate,
        };
        break;
      case OPTIONS.CHANGE_ITEM:
        actionInfo = {
          mode: "href",
          action: "/change_item",
          reg_date: regDate,
        };

        break;
      case OPTIONS.REFUND:
        actionInfo = {
          mode: "href",
          action: "/refund",
          reg_date: regDate,
        };
        break;
      case OPTIONS.ADD_MEMBERSHIP_PERIOD:
        actionInfo = {
          mode: "modal",
          action: "회원권기간추가",
          title: "회원권 기간 추가",
        };
        break;
      case OPTIONS.SUBS_REFUND:
        actionInfo = {
          mode: "href",
          action: "/subscript_cancel",
          reg_date: regDate,
        };
        break;
      case OPTIONS.REGULAR_PAY_METHOD_MGMT:
        actionInfo = {
          mode: "modal",
          action: "정기결제수단관리",
          title: "정기결제수단 관리",
        };
        break;
      case OPTIONS.SUBS_PAY_CANCEL:
        actionInfo = {
          mode: "modal",
          action: "정기결제해지예약",
          title: "정기결제 해지예약",
        };
        break;
      case OPTIONS.MASTER_CHANGE:
        if (modalParam.canSelectMasterChange) {
          actionInfo = {
            mode: "modal",
            action: "수강정보변경",
            title: "수강정보 변경",
          };
        } else {
          simpleAlert(storesDispatch, "알림", "권한이 없습니다.");
        }
        break;
      default:
        break;
    }
    onModalDone(actionInfo);
  };

  const showRegDate = () => {
    if (
      option === "결제수단 변경" ||
      option === "상품변경" ||
      option === "환불" ||
      option === "약정(정기결제) 해지"
    ) {
      return true;
    }
    return false;
  };

  return (
    <div
      style={{
        width: "20rem",
        display: "grid",
        placeContent: "center",
      }}
    >
      <div
        style={{
          marginTop: "0.8rem",
          marginBottom: "1.75em",
        }}
      >
        <Label labelText={"아래의 변경사항을 선택한 후 [확인] 버튼을 클릭하면"} />
        <Label labelText={"해당 변경/환불 페이지로 이동합니다."} />
      </div>

      <ComboBox
        labelWidth={"3.813rem"}
        comboItemWidth={"13rem"}
        labelText="변경사항"
        data={optionList}
        defaultValue={""}
        onChangeCallback={(e) => {
          setOption(e.target.value);
        }}
      />
      {showRegDate() === true ? (
        <DatePicker
          marginTop={"0.5rem"}
          labelWidth={"3.813rem"}
          minWidth="13rem"
          labelText="등록일"
          minDate={"1900-01-01"}
          maxDate={getCurrentDate()}
          defaultValue={getCurrentDate()}
          onChangeCallback={(e) => {
            setRegDate(e);
          }}
        />
      ) : (
        <></>
      )}
      <div style={{ display: "flex", justifyContent: "center", marginTop: "1.5rem" }}>
        <NormalBtn name="확인" disabled={!option} onClick={() => onMoveMethod()} />
      </div>
    </div>
  );
};
export default ChangeRefundModal;
