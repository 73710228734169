import { useQuery } from "@tanstack/react-query";
import { restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import { HttpInstance } from "lib/HttpLib";

import { getExtendHistory } from "apis/member/extend_mbshp_api";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AddVaultCashModal from "./AddVaultCashModal";
import BranchLinkTable from "./Tables/ChangeBranch/BranchLinkTable";
import ChangeBranchTable from "./Tables/ChangeBranch/ChangeBranchTable";
import SmsAuthTable from "./Tables/ChangeBranch/SmsAuthTable";
import ExtendMbshpTable from "./Tables/ExtendMbshp/ExtendMbshpTable";
import RefundListTable from "./Tables/Refund/RefundListTable";
import TransferListTable from "./Tables/Refund/TransferListTable";
import DailyCountTable from "./Tables/Sales/DailyCountTable";
import DailyPriceTable from "./Tables/Sales/DailyPriceTable";
import MonthlyCountTable from "./Tables/Sales/MonthlyCountTable";
import MonthlyPriceTable from "./Tables/Sales/MonthlyPriceTable";
import UnpaidExemptTable from "./Tables/Unpaid/UnpaidExemptTable";
import UnpaidMemberTable from "./Tables/Unpaid/UnpaidMemberTable";
import VaultCashTable from "./Tables/VaultCash/VaultCashTable";

const DailyReportStatus = ({ reportState, isAdmin }) => {
  const storesDispatch = useDispatch();
  const [monthlySalesState, setMonthlySalesState] = useState([]);
  const [unpaidState, setUnpaidState] = useState([]);
  const [unpaidExpState, setUnpaidExpState] = useState([]);
  const [transferListState, setTransferListState] = useState([]);
  const [vaultCashListState, setVaultCashListState] = useState([]);
  const [extendMbshpState, setExtendMbshpState] = useState([]);
  const [cashValutTotalState, setCashValutTotalState] = useState(0);
  const [loading, setLoading] = useState(false);

  // API Query
  // [GET] 회원권 기간 추가 리스트 (운영 일지)
  const { data: extendMbshpData } = useQuery({
    queryKey: ["extend", reportState],
    queryFn: () => getExtendHistory(reportState),
    enabled: !!reportState.hq_info.hq_id,
    retry: false,
    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });

  // API 받아온 데이터 세팅
  useEffect(() => {
    if (extendMbshpData) {
      setExtendMbshpState(extendMbshpData.data.data);
    }
  }, [extendMbshpData]);

  useEffect(() => {
    if (reportState.branch_info.branch_id) {
      setLoading(true);

      HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + "/daily_report/monthly/sales", {
        params: {
          branch_id: reportState.branch_info.branch_id,
          base_date: reportState.base_date,
        },
      })
        .then((res) => {
          if (!restEmpty(res.data.data)) {
            setMonthlySalesState(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        });

      HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + "/daily_report/current/unpaid", {
        params: {
          branch_id: reportState.branch_info.branch_id,
        },
      })
        .then((res) => {
          if (!restEmpty(res.data.data)) {
            setUnpaidState(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        });

      HttpInstance.get(
        process.env.REACT_APP_SALARY_SERVER + "/daily_report/current/unpaid/exclusion",
        {
          params: {
            branch_id: reportState.branch_info.branch_id,
            base_date: reportState.base_date,
          },
        },
      )
        .then((res) => {
          if (!restEmpty(res.data.data)) {
            setUnpaidExpState(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        });

      HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + "/daily_report/transfer_req", {
        params: {
          branch_id: reportState.branch_info.branch_id,
          is_done: false,
          is_expenditure: false,
          is_admin: false,
        },
      })
        .then((res) => {
          if (!restEmpty(res.data.data)) {
            setTransferListState(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        });

      HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + "/daily_report/current/vault-cash", {
        params: {
          branch_id: reportState.branch_info.branch_id,
          target_date: reportState.base_date,
        },
      })
        .then((res) => {
          if (!restEmpty(res.data.data)) {
            setVaultCashListState(res.data.data);
            setCashValutTotalState(res.data.total_amount);
          }
        })
        .catch((err) => {
          console.log(err);
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        });

      setLoading(false);
    }
  }, [reportState]);

  const onModalDone = () => {
    storesDispatch(globalModalOff());
  };

  const openModal = () => {
    const Modal = <AddVaultCashModal reportState={reportState} onModalDone={onModalDone} />;
    storesDispatch(
      globalModalOn({
        show: true,
        title: "시재금 추가",
        Content: Modal,
      }),
    );
  };

  const doRefreshVaultCash = () => {
    setLoading(true);
    HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + "/vault_cash/refresh", {
      params: {
        branch_id: reportState.branch_info.branch_id,
      },
    })
      .then((res) => {
        if (!res.data.msg) {
          simpleAlert(storesDispatch, "알림", "새로고침이 완료되었습니다.");
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickAddVaultCash = () => {
    openModal();
  };

  const onClickRefreshVaultCash = () => {
    simpleAlert(storesDispatch, "알림", "시재금을 새로고침 하시겠습니까?", false, undefined, () => {
      doRefreshVaultCash();
    });
  };

  const onClickDeleteVaultCash = () => {
    console.log("onClickDeleteVaultCash");
  };

  // 회원권 기간 추가 현황 데이터
  // const getExtendHistory = async () => {
  //   const formData = new FormData();
  //   const data = {
  //     branch_info: {
  //       branch_id: reportState.branch_info.branch_id,
  //       branch_name: reportState.branch_info.branch_name,
  //     },
  //     extend_days_from: 0,
  //     extend_days_to: 0,
  //     extend_type: "",
  //     hq_info: {
  //       hq_id: reportState.hq_info.hq_id,
  //       hq_name: reportState.hq_info.hq_name,
  //     },
  //     member_name: "",
  //     member_no: "",
  //     reg_date_from: reportState.base_date,
  //     reg_date_to: reportState.base_date,
  //     staff_name: "",
  //   };
  //   const page = 1;

  //   formData.append("search_param", JSON.stringify(data));
  //   formData.append("page_num", page);

  //   await HttpInstance.post(
  //     process.env.REACT_APP_BRANCH_SERVER + "/history/membership/extension",
  //     formData,
  //     contentTypePost,
  //   )
  //     .then((res) => {
  //       if (res.data.msg) {
  //         simpleAlert(storesDispatch, "ERROR", res.data.msg);
  //       } else {
  //         setExtendMbshpState(res.data.data);
  //       }
  //     })
  //     .catch((err) => {
  //       simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
  //     });
  // };

  return (
    <div>
      {loading && <Loading />}

      <div>
        <Label
          labelText="판매 현황"
          fontSize="1.2rem"
          fontWeight="bold"
          justifyContent="flex-start"
        />
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%", paddingRight: "1rem" }}>
            <DailyPriceTable viewData={reportState.branch_summary_info} />
          </div>
          <div style={{ width: "50%", paddingLeft: "1rem" }}>
            <MonthlyPriceTable viewData={monthlySalesState} />
          </div>
        </div>

        <div style={{ display: "flex", marginTop: "1rem" }}>
          <div style={{ width: "50%", paddingRight: "1rem" }}>
            <DailyCountTable viewData={reportState.branch_summary_info} />
          </div>
          <div style={{ width: "50%", paddingLeft: "1rem" }}>
            <MonthlyCountTable viewData={monthlySalesState} />
          </div>
        </div>
      </div>

      <div style={{ marginTop: "3rem" }}>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%", paddingRight: "1rem" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Label
                labelText="시재금 현황"
                fontSize="1.2rem"
                fontWeight="bold"
                justifyContent="flex-start"
              />
              <div style={{ display: "flex" }}>
                {isAdmin && (
                  <>
                    <NormalBtn name="추가" theme="red" onClick={() => onClickAddVaultCash()} />
                    <NormalBtn
                      name="새로고침"
                      margin="0 0 0 0.5rem"
                      onClick={() => onClickRefreshVaultCash()}
                    />
                    {/* <NormalBtn
                      margin="0 0 0 0.5rem"
                      name="삭제"
                      onClick={() => onClickDeleteVaultCash()}
                    /> */}
                  </>
                )}
              </div>
            </div>

            <VaultCashTable viewData={vaultCashListState} totalPrice={cashValutTotalState} />
          </div>
          <div style={{ width: "50%", paddingLeft: "1rem" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Label
                labelText="회원권 기간 추가 현황"
                fontSize="1.2rem"
                fontWeight="bold"
                justifyContent="flex-start"
              />
              <NormalBtn name="투명" theme="hidden" onClick={() => {}} />
            </div>
            <ExtendMbshpTable viewData={extendMbshpState} />
          </div>
        </div>
      </div>

      <div style={{ marginTop: "3rem" }}>
        <Label
          labelText="미납금 현황"
          fontSize="1.2rem"
          fontWeight="bold"
          justifyContent="flex-start"
        />
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%", paddingRight: "1rem" }}>
            <UnpaidMemberTable viewData={unpaidState} />
          </div>
          <div style={{ width: "50%", paddingLeft: "1rem" }}>
            <UnpaidExemptTable viewData={unpaidExpState} />
          </div>
        </div>
      </div>

      <div style={{ marginTop: "3rem" }}>
        <Label
          labelText="환불 현황"
          fontSize="1.2rem"
          fontWeight="bold"
          justifyContent="flex-start"
        />
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%", paddingRight: "1rem" }}>
            <RefundListTable viewData={reportState.refund_record_list} />
          </div>
          <div style={{ width: "50%", paddingLeft: "1rem" }}>
            <TransferListTable viewData={transferListState} />
          </div>
        </div>
      </div>

      <div style={{ marginTop: "3rem" }}>
        <Label
          labelText="지점 변경/연동 현황"
          fontSize="1.2rem"
          fontWeight="bold"
          justifyContent="flex-start"
        />
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%", paddingRight: "1rem" }}>
            <ChangeBranchTable viewData={reportState.branch_change_list} />
          </div>
          <div style={{ width: "50%", paddingLeft: "1rem" }}>
            <BranchLinkTable viewData={reportState.other_branch_access_list} />
          </div>
        </div>

        <div style={{ display: "flex", marginTop: "2rem" }}>
          <div style={{ width: "50%", paddingRight: "1rem" }}>
            <SmsAuthTable viewData={reportState.auth_hist} />
          </div>
          <div style={{ width: "50%", paddingLeft: "1rem" }}></div>
        </div>
      </div>
    </div>
  );
};
export default DailyReportStatus;
