import { useCallback, useState } from "react";
// import { registerAction } from "./Module/actions";
import CardFixed from "components/Card/CardFixed";
import { parseAllToString, SubLine } from "components/CommonLib/CommonLib";
import EditTableCard from "components/EdiTable/EditTableCard";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import NumberTextField from "components/LabelInput/NumberTextField";
import RadioGroup from "components/LabelInput/RadioGroup";
import SubTitle from "components/PageTitle/SubTitle";
import ServicePolicy from "./ServicePolicy";

const FriendSetting = ({ restData }) => {
  const textBoxWidth = "10rem";
  const textLabelWidth = "6rem";
  const labelMarginLeft = "2.625rem";

  return (
    <div style={{}}>
      <SubTitle titleText={"지인소개 추가일수"} />
      <div style={{ marginLeft: "2rem" }}>
        <div
          style={{
            display: "flex",
          }}
        >
          <NumberTextField
            isNumber
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· 소개자"
            endAdornment="개월"
            minValue={0}
            maxValue={12}
            defaultValue={restData.membership_policy.recommender_month}
            onChangeCallback={(e) => {
              restData.membership_policy.recommender_month = e;
            }}
          />

          <NumberTextField
            isNumber
            textBoxWidth={textBoxWidth}
            minValue={0}
            maxValue={30}
            endAdornment="일"
            defaultValue={restData.membership_policy.recommender_day}
            onChangeCallback={(e) => {
              restData.membership_policy.recommender_day = e;
            }}
          />

          <div style={{ marginLeft: "4rem" }}></div>

          <NumberTextField
            isNumber
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· 피소개자"
            endAdornment="개월"
            minValue={0}
            maxValue={12}
            defaultValue={restData.membership_policy.recommendee_month}
            onChangeCallback={(e) => {
              restData.membership_policy.recommendee_month = e;
            }}
          />

          <NumberTextField
            isNumber
            textBoxWidth={textBoxWidth}
            endAdornment="일"
            maxValue={30}
            defaultValue={restData.membership_policy.recommendee_day}
            onChangeCallback={(e) => {
              restData.membership_policy.recommendee_day = e;
            }}
          />
        </div>
      </div>
    </div>
  );
};

const StartDateSetting = ({ restData }) => {
  const [, updateState] = useState(); // 강제 렌더링용
  const forceUpdate = useCallback(() => updateState({}), []);

  const textLabelWidth = "13rem";
  const textBoxWidth = "11rem";

  const onCheckBool = (item) => {
    return !item.includes("안함");
  };
  const onCheckString = (item) => {
    if (item) {
      return "지정";
    }
    return "지정안함";
  };

  return (
    <div style={{}}>
      <SubTitle titleText={"회원권 시작일"} />
      <div style={{ marginLeft: "2rem" }}>
        <div
          style={{
            display: "flex",
            marginTop: "0.625rem",
          }}
        >
          <RadioGroup
            // inputRef={radioSelectorRef}
            labelWidth={textLabelWidth}
            data={["지정안함", "지정"]}
            isMulti={true}
            labelText="· 시작일 기본값"
            defaultValue={onCheckString(restData.membership_policy.use_dflt_from_date)}
            onChangeCallback={(e, v) => {
              // let wh = onCheckBool(v);
              // wh ? setDisabledInputDefault(false) : setDisabledInputDefault(true);
              restData.membership_policy.use_dflt_from_date = onCheckBool(v);
              forceUpdate();
            }}
          />
          <Label width={"2rem"} labelText={" "} />
          <DatePicker
            // inputRef={promiseDateRef}
            minWidth={textBoxWidth}
            labelText=""
            defaultValue={restData.membership_policy.dflt_from_date}
            onChangeCallback={(e) => {
              // console.log(e);
              restData.membership_policy.dflt_from_date = e;
            }}
            disabled={restData.membership_policy.use_dflt_from_date === false}
          />
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "0.625rem",
          }}
        >
          <RadioGroup
            // inputRef={radioSelectorRef}
            labelWidth={textLabelWidth}
            data={["지정안함", "지정"]}
            isMulti={true}
            labelText="· 시작일 대기일"
            onChangeCallback={(e, v) => {
              // let wh = onCheckBool(v);
              // wh ? setDisabledInputWait(false) : setDisabledInputWait(true);
              restData.membership_policy.use_max_wait_day = onCheckBool(v);
              forceUpdate();
            }}
            defaultValue={onCheckString(restData.membership_policy.use_max_wait_day)}
          />
          <NumberTextField
            isNumber
            labelWidth={"2rem"}
            textBoxWidth={textBoxWidth}
            labelText="최대"
            endAdornment="일"
            minValue={0}
            maxValue={30}
            defaultValue={restData.membership_policy.max_wait_day}
            onChangeCallback={(e) => {
              restData.membership_policy.max_wait_day = e;
            }}
            disabled={restData.membership_policy.use_max_wait_day === false}
          />
        </div>
      </div>
    </div>
  );
};

const EventSetting = ({ restData }) => {
  return (
    <div>
      <SubTitle titleText={"행사 설정"} />
      <div>
        <div
          style={{
            display: "flex",
          }}
        >
          <CardFixed
            midDom={
              <EditTableCard
                titleName={"개월"}
                dataList={parseAllToString(restData.membership_policy.event_month_list)}
                isNumber
              />
            }
            isLoading={false}
            rootStyle={{
              marginTop: "0.3rem",
              marginRight: "1rem",
              borderRadius: "10px",
              backgroundColor: "#fff",
              border: "1px solid #e8ebf2",
              background:
                "transparent linear-gradient(117deg, #e8ebf2 0%, #f2f3f7 100%) 0% 0% no-repeat padding-box",
              // boxShadow: "20px 20px 30px #0f296b1f",
            }}
          />

          <CardFixed
            midDom={
              <EditTableCard
                titleName={"일"}
                dataList={parseAllToString(restData.membership_policy.event_day_list)}
                isNumber
              />
            }
            isLoading={false}
            rootStyle={{
              marginTop: "0.3rem",
              borderRadius: "10px",
              backgroundColor: "#fff",
              border: "1px solid #e8ebf2",
              background:
                "transparent linear-gradient(117deg, #e8ebf2 0%, #f2f3f7 100%) 0% 0% no-repeat padding-box",
              // boxShadow: "20px 20px 30px #0f296b1f",
            }}
          />
        </div>
      </div>
      <SubLine />
      <SubTitle titleText={"상품변경 제한 일수"} />
      <div style={{ display: "flex", marginLeft: "2rem" }}>
        <NumberTextField
          isNumber
          labelWidth={"6rem"}
          textBoxWidth={"10rem"}
          labelText="· 등록 후"
          minValue={0}
          maxValue={365}
          endAdornment="일"
          defaultValue={restData.membership_policy.change_limit}
          onChangeCallback={(e) => {
            restData.membership_policy.change_limit = e;
          }}
        />
        <Label textAlign={"left"} labelText={"이내에만 가능"} />
      </div>
    </div>
  );
};

const MembershipPolicy = ({ restData }) => {
  return (
    <div style={{ marginBottom: "1.5rem" }}>
      <StartDateSetting restData={restData} />
      <SubLine />
      <FriendSetting restData={restData} />
      <SubLine />
      <EventSetting restData={restData} />
      <SubLine />
      <ServicePolicy restData={restData} />
    </div>
  );
};
export default MembershipPolicy;
