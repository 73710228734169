// import React from "react";
import { useEffect, useState } from "react";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import Box from "@mui/material/Box";

const CheckBox2 = (props) => {
  const [value, setValue] = useState(props.defaultValue);

  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);

  const hStyle = {
    display: "flex",
    alignItems: "center",
    marginTop: props.marginTop ? props.marginTop : "0.625rem",
    marginLeft: props.marginLeft ? props.marginLeft : "0.625rem",
    marginRight: props.marginRight ? props.marginRight : "0.625rem",
  };

  const labelStyle = {
    width: props.labelWidth,
    height: "2.25rem",
    marginRight: props.labelMarginRight ? props.labelMarginRight : "0.625rem",
    marginLeft: props.labelMarginLeft,
    fontFamily: "AppleSDGothicNeo",
    fontSize: props.fontSize ? props.fontSize : "0.938rem",
    fontWeight: props.fontWeight ? props.fontWeight : "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: props.labelTextColor
      ? props.labelTextColor
      : props.readonly
      ? "#000"
      : props.disabled
      ? "#999"
      : "#000",
    alignItems: "center",
    display: "flex",
  };

  const checkerStyle = {
    width: props.width ? props.width : "2.25rem",
    height: props.height ? props.height : "2.25rem",
    transform: props.transform ? props.transform : "scale(1.3)",
    marginRight: props.marginRight ? props.marginRight : "0.562rem",
    marginLeft: props.ItemMarginLeft,
  };

  const tableCheckerStyle = {
    width: "2rem",
    height: "2rem",
    transform: "scale(1.3)",
  };

  const labelMarginStyle = {
    margin: "0px",
  };

  const onChangeValue = (event) => {
    if (props.onChangeCallback !== undefined) {
      props.onChangeCallback(event);
    }

    setValue(event.target.checked);
  };

  return (
    <Box style={props.isTable ? undefined : hStyle}>
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              sx={{
                color: props.borderColor,
                borderRadius: "50%",
                "&.Mui-checked": {
                  color: props.checkedColor,
                },
              }}
              style={props.isTable ? tableCheckerStyle : checkerStyle}
              onChange={(e) => {
                if (props.onChangeCallback !== undefined) {
                  props.onChangeCallback(e);
                }
              }}
              checked={value}
              disabled={props.disabled || props.readonly}
              icon={props.isCircle && <RadioButtonUncheckedIcon />}
              checkedIcon={props.isCircle && <CheckCircleOutlineIcon />}
            />
          }
          label={props.labelText ? <div style={labelStyle}>{props.labelText}</div> : undefined}
          style={!props.labelText ? labelMarginStyle : {}}
        />
      </FormControl>
    </Box>
  );
};

export default CheckBox2;
