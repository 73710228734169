import CardFixed from "components/Card/CardFixed";
import PageFrame from "components/Panels/PageFrame";
import ContractUpdateView from "./ContractUpdateView";

const ContractView = ({ postRowState, nowService }) => {
  return (
    <PageFrame
      menuActive="태블릿 설정"
      parentMenu={{ index: true }}
      children={
        <div style={{ display: "flex", gap: "1rem" }}>
          <CardFixed
            // topDom={""}
            midDom={<ContractUpdateView postRowState={postRowState} />}
            isLoading={false}
            rootStyle={{
              width: "65%",
              borderRadius: "10px",
              backgroundColor: "#fff",
            }}
          />
        </div>
      }
    />
  );
};

export default ContractView;
