import Label from "components/LabelInput/Label";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import moment from "moment";
import { store } from "store";
import { utils, writeFile } from "xlsx-js-style";
import {
  APPROVAL_TYPE_COOPERATION,
  APPROVAL_TYPE_EXP,
  APPROVAL_TYPE_HOLIDAY,
  APPROVAL_TYPE_IMP,
  APPROVAL_TYPE_INSURANCE,
  APPROVAL_TYPE_ORDER,
  APPROVAL_TYPE_REFUND_TRANS,
  APPROVAL_TYPE_SALARY,
  APPROVAL_TYPE_VAULT_CASH,
  APPROVAL_TYPE_WORK_INFO,
} from "./Consts";

export const contentTypeJson = () => {
  return {
    headers: {
      "content-type": "application/json",
    },
  };
};

export const dataEmpty = (data) => {
  if (data === undefined || data === null) {
    return true;
  } else {
    return false;
  }
};

export const listEmpty = (data) => {
  if (data === undefined || data === null || data.length === 0) {
    return true;
  } else {
    return false;
  }
};

export const storeEmpty = (storeData) => {
  if (storeData.stores === undefined || storeData.stores === null) {
    return true;
  } else {
    if (storeData.stores.data === undefined || storeData.stores === false) {
      return true;
    }
    return false;
  }
};

export const restEmpty = (data) => {
  if (data === undefined || data === null || data === "") {
    return true;
  } else {
    return false;
  }
};

export const storeDataEmpty = (storeData) => {
  if (storeData.store_data === undefined || storeData.store_data === null) {
    return true;
  } else {
    return false;
  }
};

export const setDefault = (initValue) => {
  return {
    value: initValue,
  };
};

export const convertRemToPixels = (rem) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

export const parseEmptyDate = (paramDate) => {
  if (paramDate === "0001-01-01T00:00:00Z") {
    return "";
  } else {
    return paramDate;
  }
};

export const structToBranches = (listData) => {
  let branchNameList = [];

  if (listData.permission_branch_list.length > 0) {
    listData.permission_branch_list.map((data) => branchNameList.push(data.branch_name));
    // listData.selected_branch_name = listData.permission_branch_list[0].branch_name;
    // listData.selected_branch_id = listData.permission_branch_list[0].branch_id;
  }

  return branchNameList;
};

export const structParseBranches = (listData) => {
  let branchNameList = [];
  if (listData.permission_branch_list.length > 0) {
    listData.map((data) => branchNameList.push(data.branch_name));
  }

  return branchNameList;
};

export const ListObjectToBrancheNames = (listData) => {
  let branchNameList = [];
  if (listData.length > 0) {
    listData.map((data) => branchNameList.push(data.branch_name));
  }

  return branchNameList;
};

export const ListObjectToHqNames = (listData) => {
  let branchNameList = [];
  if (listData.length > 0) {
    listData.map((data) => branchNameList.push(data.hq_name));
  }

  return branchNameList;
};

export const ListObjectToPtNames = (listData) => {
  let nameList = [];
  for (let i = 0; i < listData.length; i++) {
    let ptName = listData[i].pt_info.purchase_date + "  =>  " + listData[i].pt_info.join_type;
    nameList.push(ptName);
  }

  return nameList;
};

export const ListParaseToHqNames = (listData) => {
  let hqNameList = [];
  if (listData.length > 0) {
    listData.map((data) => hqNameList.push(data.hq_info.hq_name));
  }

  return hqNameList;
};

export const ListParaseToBranchNames = (listData) => {
  let branchNameList = [];
  if (listData.length > 0) {
    listData.map((data) => branchNameList.push(data.hq_info.hq_name));
  }

  return branchNameList;
};

export const SubLine = (props) => {
  return (
    <div
      style={{
        height: "0.063rem",
        marginTop: props.margin ? props.margin : "1.219rem",
        marginBottom: props.margin ? props.margin : "1.219rem",
        marginLeft: props.marginLeft,
        marginRight: props.marginRight,
        // margin: "1.219rem 0rem",
        backgroundColor: "#dedede",
      }}
    />
  );
};

export const SubLineVertical = (props) => {
  return (
    <div
      style={{
        width: "0.063rem",
        height: "100%",
        marginTop: props.marginTop,
        marginBottom: props.marginBottom,
        marginLeft: props.marginLeft,
        marginRight: props.marginRight,
        // margin: "1.219rem 0rem",
        backgroundColor: "#dedede",
      }}
    />
  );
};

export const structToMemberships = (listData) => {
  let dataList = [];
  if (listData.length > 0) {
    listData.map((data) => dataList.push(data.item_name));
  }
  return dataList;
};

export const getByte = (str) => {
  return str
    .split("")
    .map((s) => s.charCodeAt(0))
    .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0);
};

const getRanHex = (size) => {
  let result = [];
  let hexRef = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "a", "b", "c", "d", "e", "f"];

  for (let n = 0; n < size; n++) {
    result.push(hexRef[Math.floor(Math.random() * 16)]);
  }
  return result.join("");
};

export function generateObjectDummyId() {
  // let oId = String(moment().valueOf()) + getRanHex(11);

  let oId = "000000000000000000000000";
  return oId;
}

export function generateObjectRandomDummyId() {
  let oId = String(moment().valueOf()) + getRanHex(11);

  // let oId = "000000000000000000000000";
  return oId;
}

export function generateObjectZeroId() {
  return "000000000000000000000000";
}

// 본부직원인 경우의 지점 ID
export function getHqBranchId() {
  return "000000000000001039934570";
}

export function parseAllToString(list) {
  if (!list) return [];

  for (let i = 0; i < list.length; i++) {
    list[i] = list[i].toString();
  }
  return list;
}

// "1,234,567" -> 1234567
export function parseCommaNumberToNumber(val) {
  if (typeof val === "string") {
    const res = Number(val.replace(/,/g, ""));
    if (!isNaN(res)) {
      return res;
    }
  }
  console.error("Number parse Error !!!");
  return 0;
}

export function getEmptyBranchInfo() {
  return {
    branch_id: generateObjectZeroId(),
    branch_name: "선택없음",
  };
}
export function getEmptySTaffInfo() {
  return {
    staff_id: generateObjectZeroId(),
    staff_name: "선택없음",
  };
}

export function calMonthFirstAndLast(date, option) {
  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString();
  if (month.length < 2) month = "0" + month;

  if (option === "first") {
    //그달의 1일을 반환
    return [year, month, "01"].join("-");
  } else if (option === "last") {
    //그달의 마지막 일을 반환
    const lastDay = new Date(year, month, 0).toString().split(" ")[2];
    return [year, month, lastDay].join("-");
  }
}

export function parseMoneyFormat(data) {
  // return parseFloat(String(data).replace(/,/g, ""));
  return data.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export function checkIdFormat(data) {
  const expression = /^(?=.*[a-zA-Z])[a-zA-Z0-9]{4,16}$/;
  if (expression.test(data)) {
    return true;
  }
  return false;
}

// 영문 알파벳인지 체크
export function checkOnlyAlphabet(data) {
  const expression = /^[a-zA-Z0-9_]+$/;
  if (expression.test(data)) {
    return true;
  }
  return false;
}

// 숫자인지 체크
export function checkNumber(data) {
  const expression = /^[0-9]+$/;
  if (expression.test(data)) {
    return true;
  }
  return false;
}

// 연락처를 radix에 따라 잘라서 리턴해줌
export function getPhoneSubstr(phoneNum, radix) {
  if (phoneNum.length > 11) {
    switch (radix) {
      case 0:
        return phoneNum.substring(0, 3);
      case 1:
        return phoneNum.length === 12 ? phoneNum.substring(4, 7) : phoneNum.substring(4, 8);
      case 2:
        return phoneNum.length === 12 ? phoneNum.substring(8, 12) : phoneNum.substring(9, 13);
      default:
        break;
    }
  }

  return "";
}

// 이메일 형식 체크
export function checkEmail(val) {
  var reg_email = /^([0-9a-zA-Z_\\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
  if (!reg_email.test(val)) {
    return false;
  } else {
    return true;
  }
}

// 주민번호 형식 체크
export function checkJumin(val) {
  const juminRule = /^(?:[0-9]{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[1,2][0-9]|3[0,1]))-[1-8][0-9]{6}$/;
  if (!juminRule.test(val)) {
    return false;
  } else {
    return true;
  }
}

// 파일 사이즈 체크 (file, MB)
export function checkFileSize(file, maxSize) {
  if (file.size > maxSize * 1024 * 1024) {
    return false;
  }
  return true;
}

// 단일 시트만 가능 (Json Data)
export function excelExport(data, fileName, width, headerStyle) {
  const worksheet = utils.json_to_sheet(data);
  const workbook = utils.book_new();
  const columnsLength = Object.keys(data[0]).length;

  if (width) {
    // 모든 너비를 width 값으로 조정
    worksheet["!cols"] = Array(columnsLength).fill({ wch: width });
  }

  // 컬럼 헤드 스타일 적용
  if (headerStyle) {
    for (let C = 0; C <= columnsLength; ++C) {
      const cellAddress = { c: C, r: 0 };
      // 셀 주소를 문자열 변환 A1, B1 등
      const cellRef = utils.encode_cell(cellAddress);
      // 해당 셀이 없으면 기본 스타일과 값 초기화
      if (!worksheet[cellRef]) worksheet[cellRef] = { s: "", v: "" };
      worksheet[cellRef].s = headerStyle;
    }
  }

  utils.book_append_sheet(workbook, worksheet, "Sheet1");
  writeFile(workbook, fileName + ".xlsx");
}

// 단일 시트만 가능 (Array Data)
export function arrayExcelExport(
  data,
  fileName,
  extendWidth,
  reduceWidth,
  adjustLength,
  allColumnsLength,
) {
  const worksheet = utils.aoa_to_sheet(data);
  const workbook = utils.book_new();
  // 엑셀 너비 조정
  if (extendWidth) {
    // 조정할 컬럼 길이가 없으면 첫번째 Row의 데이터 길이로
    const columnsLength = adjustLength ? adjustLength : Object.keys(data[0]).length;
    const cols = [];

    for (let i = 0; i < allColumnsLength; i++) {
      if (i < columnsLength) {
        cols.push({ wch: extendWidth });
      } else {
        cols.push({ wch: reduceWidth });
      }
    }
    // 너비를 width 값으로 조정
    worksheet["!cols"] = cols;
  }

  // 각 column 길이 체크 후 너비 세팅
  // const cols = [];
  // for (let i = 0; i < data[0].length; i++) {
  //   let max_length = 3;
  //   for (let j = 0; j < data.length; j++) {
  //     if (data[j][i]?.v && typeof data[j][i]?.v === "string" && max_length < data[j][i].v.length) {
  //       max_length = data[j][i].v.length <= 20 ? 20 : data[j][i].v.length;
  //     }
  //   }
  //   cols.push({ wch: max_length });
  // }

  // worksheet["!cols"] = cols;

  utils.book_append_sheet(workbook, worksheet, "Sheet1");
  writeFile(workbook, fileName + ".xlsx");
}

// 여러개의 시트로 구성 가능한 함수 (Json Data)
export function excelExportExtend(dataSet, fileName) {
  const workbook = utils.book_new();

  for (let i = 0; i < dataSet.length; i++) {
    const worksheet = utils.json_to_sheet(dataSet[i].data);

    utils.book_append_sheet(workbook, worksheet, dataSet[i].sheetName);
  }

  writeFile(workbook, fileName + ".xlsx");
}

// 여러개의 시트로 구성 가능한 엑셀 (Array Data)
export function arrayExcelExportExtend(
  dataSet,
  fileName,
  extendWidth,
  reduceWidth,
  adjustLength,
  allColumnsLength,
) {
  const workbook = utils.book_new();

  for (let i = 0; i < dataSet.length; i++) {
    const worksheet = utils.aoa_to_sheet(dataSet[i].data);
    // 엑셀 너비 조정
    if (extendWidth) {
      // 조정할 컬럼 길이가 없으면 첫번째 Row의 데이터 길이로
      const columnsLength = adjustLength ? adjustLength : Object.keys(dataSet[i].data[0]).length;
      const cols = [];
      for (let i = 0; i < allColumnsLength; i++) {
        if (i < columnsLength) {
          cols.push({ wch: extendWidth });
        } else {
          cols.push({ wch: reduceWidth });
        }
      }
      // 너비를 width 값으로 조정
      worksheet["!cols"] = cols;
    }
    utils.book_append_sheet(workbook, worksheet, dataSet[i].sheetName);
  }
  writeFile(workbook, fileName + ".xlsx");
}

export function changeCellStyle(data, fill, align, borderDirection, numFormat, font) {
  const newDataList = [];

  // 데이터가 배열인지 단일인지 체크
  if (Array.isArray(data)) {
    data.forEach((el) => {
      if (el === "") {
        newDataList.push(el);
      } else {
        const cellData = setCellStyle(el, fill, align, borderDirection, numFormat, font);
        newDataList.push(cellData);
      }
    });
    return newDataList;
  } else {
    const cellData = setCellStyle(data, fill, align, borderDirection, numFormat, font);
    return cellData;
  }
}

// 셀 스타일 바꿈
function setCellStyle(data, fill, align, borderDirection, numFormat, font) {
  let newCell = {};
  newCell["v"] = data;
  // 숫자일때 타입 지정
  if (typeof data === "number") {
    newCell["t"] = "n";
  }
  // 배경색 설정
  if (fill) {
    switch (fill) {
      // 연한 주황
      case "lightOrange":
        newCell["s"] = { ...newCell["s"], fill: { fgColor: { theme: 9, tint: 0.6 } } };
        break;
      // 연한 회색
      case "lightGray":
        newCell["s"] = { ...newCell["s"], fill: { fgColor: { theme: 1, tint: 0.8 } } };
        break;
      // 연한 파랑
      case "lightBlue":
        newCell["s"] = { ...newCell["s"], fill: { fgColor: { theme: 4, tint: 0.8 } } };
        break;
      // 파랑
      case "blue":
        newCell["s"] = { ...newCell["s"], fill: { fgColor: { rgb: "87CEEB" } } };
        break;
      // 노랑
      case "yellow":
        newCell["s"] = { ...newCell["s"], fill: { fgColor: { rgb: "FFE033" } } };
        break;
      // 빨강
      case "red":
        newCell["s"] = { ...newCell["s"], fill: { fgColor: { rgb: "FA6464" } } };
        break;
      // 진한 주황
      case "orange":
        newCell["s"] = { ...newCell["s"], fill: { fgColor: { theme: 9, tint: 0.2 } } };
        break;
      default:
        break;
    }
  }

  // align 위치
  if (align) {
    if (align === "allCenter") {
      newCell["s"] = { ...newCell["s"], alignment: { horizontal: "center", vertical: "center" } };
    } else if (align === "verticalCenter") {
      newCell["s"] = { ...newCell["s"], alignment: { vertical: "center" } };
    } else {
      // align 값이 있으면 align 위치로
      newCell["s"] = { ...newCell["s"], alignment: { horizontal: align } };
    }
  } else {
    // 숫자면 right, 아니면 center
    if (typeof data === "number" && fill !== "title") {
      newCell["s"] = { ...newCell["s"], alignment: { horizontal: "right" } };
    } else {
      newCell["s"] = { ...newCell["s"], alignment: { horizontal: "center" } };
    }
  }

  // border 설정 (방향 없으면 4방향)
  if (borderDirection) {
    switch (borderDirection) {
      case "all":
        newCell["s"] = {
          ...newCell["s"],
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };
        break;
      case "left":
        newCell["s"] = {
          ...newCell["s"],
          border: {
            left: { style: "thin" },
          },
        };
        break;
      default:
        break;
    }
  } else {
    newCell["s"] = {
      ...newCell["s"],
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };
  }

  // 숫자 포맷 형식
  if (numFormat) {
    switch (numFormat) {
      case "normal":
        newCell["s"] = { ...newCell["s"], numFmt: "normal" };
        break;
      default:
        break;
    }
  } else {
    newCell["s"] = { ...newCell["s"], numFmt: "#,##0" };
  }

  return newCell;
}

export function isEmptyObj(obj) {
  if (obj.constructor === Object && Object.keys(obj).length === 0) {
    return true;
  }
  return false;
}

///////////////////////////
// DATE & TIME UTILS
///////////////////////////

// Date -> "yyyy-MM-dd HH:mm:ss"
export function dateTimeToStr(val) {
  const ret = _.cloneDeep(val);
  ret.setHours(ret.getHours() + 9);
  return ret.toISOString().replace("T", " ").substring(0, 19);
}

export function strToTime(val) {
  var now = new Date();
  var hour;
  var minute;

  if (val && val.length === 5) {
    hour = val.substring(0, 2);
    minute = val.substring(3, 5);
  } else {
    hour = 0;
    minute = 0;
  }
  now.setHours(hour);
  now.setMinutes(minute);
  return now;
}

// Date -> "HH:mm"
export function timeToStr(val) {
  var hour = val.getHours() < 10 ? "0" + val.getHours().toString() : val.getHours().toString();
  var minute =
    val.getMinutes() < 10 ? "0" + val.getMinutes().toString() : val.getMinutes().toString();
  return hour + ":" + minute;
}

export function compareTimeBigger(a, b) {
  return Number(a.replace(":", "")) > Number(b.replace(":", ""));
}

// "yyyy-MM"
export function getCurrentMonth() {
  const now = new Date();
  const year = now.getFullYear();
  let month = (now.getMonth() + 1).toString();
  if (month.length < 2) month = "0" + month;

  return `${year}-${month}`;
}

// "yyyy-MM-dd"
export function getCurrentDate() {
  var d = new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

// HH:mm or HH:mm:ss
export function getCurrentTime(removeSec) {
  const d = new Date();

  if (removeSec) {
    return (
      d.getHours().toString().padStart(2, "0") + ":" + d.getMinutes().toString().padStart(2, "0")
    );
  } else {
    return (
      d.getHours().toString().padStart(2, "0") +
      ":" +
      d.getMinutes().toString().padStart(2, "0") +
      ":" +
      d.getSeconds().toString().padStart(2, "0")
    );
  }
}

// 파일명을 위한 현재 날짜시간 반환 (yyyyMMdd_HHmmss)
export function getCurrentDateTimeForFileName() {
  const d = new Date();

  return (
    d.getFullYear().toString() +
    (d.getMonth() + 1).toString().padStart(2, "0") +
    d.getDate().toString().padStart(2, "0") +
    "_" +
    d.getHours().toString().padStart(2, "0") +
    d.getMinutes().toString().padStart(2, "0") +
    d.getSeconds().toString().padStart(2, "0")
  );
}

// Date -> "yyyy-MM-dd"
export function dateToStr(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

// Date -> "yyyy-MM"
export function dateToYearMonth(date) {
  if (date instanceof Date && !isNaN(date.valueOf())) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;

    return [year, month].join("-");
  } else {
    return "";
  }
}

//date는 string 타입 => ex) '2022-12-20'
//입력된 날짜의 다음날짜를 반환('yyyy-mm-dd' 형식)
export function makePrevDay(date) {
  const now = new Date(date);
  now.setDate(now.getDate() - 1);
  let year = now.getFullYear();
  let month = ("0" + (now.getMonth() + 1)).slice(-2);
  let day = ("0" + now.getDate()).slice(-2);
  const nextDay = year + "-" + month + "-" + day;
  return nextDay;
}

//date는 string 타입 => ex) '2022-12-20'
//입력된 날짜의 다음날짜를 반환('yyyy-mm-dd' 형식)
export function makeNextDay(date) {
  const now = new Date(date);
  now.setDate(now.getDate() + 1);
  let year = now.getFullYear();
  let month = ("0" + (now.getMonth() + 1)).slice(-2);
  let day = ("0" + now.getDate()).slice(-2);
  const nextDay = year + "-" + month + "-" + day;
  return nextDay;
}

// title : 팝업 타이틀
// msg : 팝업 내 메시지. 여러 줄로 나누고 싶으면 \n을 넣어주면 됨
// reqConfirm : true면 팝업 내 버튼을 무조건 눌러야 함 (거의 true를 안하는 경우가 많음. 귀찮기 때문에. 필수적으로 눌러야 하는 곳만 true 해주고 있음)
// onClickBtnOk : OK 버튼만 있는 곳에는 여기에 inner func 집어넣으면 됨
// onClickBtnYes & No : 예 아니오 버튼을 쓰는 팝업인 경우 여기에 inner func 집어넣으면 됨. 단, onClickBtnOk 파라미터에는 undefined를 넣어줘야 함.
export const simpleAlert = (
  dispatch,
  title,
  msg,
  reqConfirm = false,
  onClickBtnOk,
  onClickBtnYes,
  onClickBtnNo,
) => {
  dispatch(
    globalAlertOn({
      show: true,
      titleText: title,
      reqConfirm: reqConfirm,
      bodyText: <Label labelText={msg} whiteSpace="pre-wrap" textAlign="center" />,
      onBtnOk:
        !onClickBtnYes && !onClickBtnNo
          ? () => {
              onClickBtnOk && onClickBtnOk();
            }
          : undefined,
      onBtnYes: onClickBtnYes ? onClickBtnYes : undefined,
      onBtnNo: onClickBtnYes ? (onClickBtnNo ? onClickBtnNo : () => {}) : undefined,
    }),
  );
};

export const getCardCompList = () => {
  return [
    "- 선택 -",
    "현대",
    "우리",
    "국민",
    "농협",
    "하나",
    "기업",
    "랩스",
    "랩스2",
    "랩스3",
    "랩스4",
    "랩스5",
    "랩스6",
    "랩스7",
    "랩스8",
    "랩스9",
    "랩스10",
  ];
};

export const getDocType = (approvalType) => {
  switch (approvalType) {
    case APPROVAL_TYPE_WORK_INFO:
      return "personnelWorkReq";
    case APPROVAL_TYPE_SALARY:
      return "salaryReport";
    case APPROVAL_TYPE_COOPERATION:
      return "workAssistReq";
    case APPROVAL_TYPE_IMP:
      return "spendProof";
    case APPROVAL_TYPE_EXP:
      return "consult";
    case APPROVAL_TYPE_REFUND_TRANS:
      return "transferReq";
    case APPROVAL_TYPE_INSURANCE:
      return "insuranceReq";
    case APPROVAL_TYPE_HOLIDAY:
      return "dayOffReq";
    case APPROVAL_TYPE_ORDER:
      return "orderReq";
    case APPROVAL_TYPE_VAULT_CASH:
      return "vaultCash";
    default:
      console.log("getDocType Error!!!");
      return "";
  }
};

// end -> kor
export const getDocTypeKor = (approvalType) => {
  switch (approvalType) {
    case "personnelWorkReq":
      return APPROVAL_TYPE_WORK_INFO;
    case "salaryReport":
      return APPROVAL_TYPE_SALARY;
    case "workAssistReq":
      return APPROVAL_TYPE_COOPERATION;
    case "spendProof":
      return APPROVAL_TYPE_IMP;
    case "consult":
      return APPROVAL_TYPE_EXP;
    case "transferReq":
      return APPROVAL_TYPE_REFUND_TRANS;
    case "insuranceReq":
      return APPROVAL_TYPE_INSURANCE;
    case "dayOffReq":
      return APPROVAL_TYPE_HOLIDAY;
    case "orderReq":
      return APPROVAL_TYPE_ORDER;
    case "vaultCash":
      return APPROVAL_TYPE_VAULT_CASH;
    default:
      console.log("getDocType Error!!!");
      return "";
  }
};

// isHqBranch :
// isOnlyBranch : true인 경우 지점만 받음
// includeAll : true인 경우 지점 리스트에 '전체' 포함
// isAddHqOnlyOpt : true인 경우 지점 리스트에 '본부' 포함
export const getPermission = async (
  dispatch,
  key,
  isHqBranch,
  isOnlyBranch,
  includeAll,
  isAddHqOnlyOpt,
) => {
  let result = false;

  await HttpInstance.get(process.env.REACT_APP_AUTH_SERVER + "/web/permission", {
    params: {
      permission_key: key,
      is_read: true,
      is_add_hq_only_opt: isAddHqOnlyOpt,
      include_all: includeAll,
      is_only_branch: isOnlyBranch,
    },
  })
    .then((res) => {
      if (isHqBranch) {
        result = res.data.data;
      } else if (res.data.data.target_list && res.data.data.target_list.length > 0) {
        result = true;
      } else {
        result = false;
      }
    })
    .catch((err) => {
      console.log(err);
      simpleAlert(dispatch, "ERROR", "GET DATA ERROR");
    });

  return result;
};

export const getWritePermission = async (
  dispatch,
  key,
  isHqBranch,
  isOnlyBranch,
  includeAll,
  isAddHqOnlyOpt,
) => {
  let result = false;

  await HttpInstance.get(process.env.REACT_APP_AUTH_SERVER + "/web/permission", {
    params: {
      permission_key: key,
      is_read: false,
      is_add_hq_only_opt: isAddHqOnlyOpt,
      include_all: includeAll,
      is_only_branch: isOnlyBranch,
    },
  })
    .then((res) => {
      if (isHqBranch) {
        result = res.data.data;
      } else if (res.data.data.target_list && res.data.data.target_list.length > 0) {
        result = true;
      } else {
        result = false;
      }
    })
    .catch((err) => {
      console.log(err);
      simpleAlert(dispatch, "ERROR", "GET DATA ERROR");
    });

  return result;
};

export const getCardBuyer = () => {
  return [
    "현대카드",
    "신한카드",
    "비씨카드",
    "우리카드",
    "KB국민카드",
    "하나카드",
    "NH농협카드",
    "삼성카드",
    "롯데카드",
  ];
};

export const getStringBytes = (str) => {
  let character;
  let charBytes = 0;

  for (let i = 0; i < str.length; i += 1) {
    character = str.charAt(i);

    if (escape(character).length > 4) charBytes += 2;
    else charBytes += 1;
  }

  return charBytes;
};

export const isArrayEmpty = (arrayData) => {
  if (arrayData === undefined) {
    return true;
  } else if (arrayData === null) {
    return true;
  } else {
    if (arrayData.length === undefined) {
      return true;
    }
    return false;
  }
};

// 검색 조건 comboBox, field 값 DefaultValue 설정
export const getDefaultValue = (type, dataParam) => {
  switch (type) {
    case "combo":
      return dataParam === "" ? "전체" : dataParam;
    case "field":
      return dataParam;
    default:
      break;
  }
};

// 검색 조건 ComboBox, "전체"값을 빈 값으로 설정
export const changeComboValueToEmpty = (setSearchParams, label, e) => {
  if (e.target.value === "전체") {
    setSearchParams(label, "");
  } else {
    setSearchParams(label, e.target.value);
  }
};

// 시작일과 종료일 체크해서 알림 띄우기
export const checkDateOrder = (startDate, endDate) => {
  if (!restEmpty(startDate) && !restEmpty(endDate)) {
    if (new Date(startDate) > new Date(endDate)) {
      simpleAlert(store.dispatch, "알림", "시작일은 종료일보다 클 수 없습니다.");
      return false;
    }
  }
  return true;
};

// 연락처 or 주민등록번호 입력 key 제한
export const numberKeyValidation = (event) => {
  const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Tab", "Control", "c", "v"];
  if (!allowedKeys.includes(event.key) && !checkNumber(event.key)) {
    event.preventDefault();
  }
};
