import { HttpInstance } from "lib/HttpLib";

export const getViewInfo = (param) => {
  return HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/change/membership_view", {
    params: {
      member_id: param.member_id === null ? "" : param.member_id,
      reg_date: param.reg_date === null ? "" : param.reg_date,
    },
  });
};

const get_api = {
  getViewInfo,
};

export default get_api;
