import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { useGetAuthData } from "hooks/apis/access/useAuth";
import { useDailyReportDetail } from "hooks/apis/salary/useDailyReport";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { report_info_state } from "../Module/reducers/report_info_state";
import DailyReportInfoView from "./DailyReportInfoView";

const DailyReportInfoService = () => {
  const { state } = useLocation();
  const [reportState, setReportState] = useState(_.cloneDeep(report_info_state));
  const [isAdmin, setIsAdmin] = useState(false);

  // [GET] 권한 정보 가져오기
  const { data: authData } = useGetAuthData();
  // [GET] 운영일지 상세 데이터
  const { data: reportData } = useDailyReportDetail(state);

  // 권한 정보 세팅
  useEffect(() => {
    if (authData) setIsAdmin(authData.data.data.is_admin);
    if (reportData) setReportState(reportData.data.data);
  }, [authData, reportData]);

  return (
    <div>
      <PageFrame
        menuActive="락커/셋팅"
        parentMenu={{ 락커: true }}
        children={
          <div>
            <PageTitle titleText="운영일지" />
            <DailyReportInfoView reportState={reportState} isAdmin={isAdmin} />
          </div>
        }
      />
    </div>
  );
};
export default DailyReportInfoService;

//DailyReportInfo
