import styled from "styled-components";

const ContainerWrapper = styled.div`
  // margin-top: 72px;
  display: flex;
  flex-direction: column;
  /* margin-bottom: 100px; */
`;
const FlexBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1301;
  position: fixed;
  left: 0;

  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
`;

const LoadingContainer = (props) => {
  // useEffect(() => {
  //   document.body.style.cssText = `
  //     position: fixed;
  //     // top: -${props.offSetY}px;
  //     // overflow-y: scroll;
  //     width: 100%;`;
  //   return () => {
  //     // const scrollY = document.body.style.top;
  //     document.body.style.cssText = "";
  //     window.scrollTo(0, parseInt(props.offSetY || "0", 10) * -1);
  //   };
  // }, []);

  return (
    <FlexBox>
      <ContainerWrapper>{props.children}</ContainerWrapper>
    </FlexBox>
  );
};

export default LoadingContainer;
