import { ReactComponent as Title } from "assets/login/Head.svg";
import LoginForm from "../../components/LoginForm/LoginForm";
import "./style.css";

const LoginTemplete = ({ onClickLogin }) => {
  return (
    <div className="login_container">
      <div className="login_bg">
        <div className="login_title">
          <Title />
        </div>
        <div className="login_form">
          <LoginForm onClickLogin={onClickLogin} />
        </div>
      </div>
    </div>
  );
};

export default LoginTemplete;
