import { Button } from "@mui/material";
import IconButton from "components/Button/IconButton";
import SubTitle from "components/PageTitle/SubTitle";
import { btnTextStyle } from "./NewVerBtnStyle";

const NormalBtnWithSubtitle = (props) => {
  const whiteStyle = {
    ...btnTextStyle,
    marginTop: props.marginTop,
    marginBottom: props.marginBottom,
    marginLeft: props.marginLeft,
    marginRight: props.marginRight,
    margin: props.margin,
    width: props.width,
    height: props.height ? props.height : "2.25rem",
    border: props.disabled ? "1px solid #777777" : "1px solid #032063",
    backgroundColor: props.disabled ? "#C6C6C6" : "#FFFFFF",
    color: props.disabled ? "#777777" : "#032063",
  };
  const grayStyle = {
    ...btnTextStyle,
    marginTop: props.marginTop,
    marginBottom: props.marginBottom,
    marginLeft: props.marginLeft,
    marginRight: props.marginRight,
    margin: props.margin,
    width: props.width,
    height: props.height ? props.height : "2.25rem",
    border: props.disabled ? "1px solid #777777" : "1px solid #BBBBBB",
    backgroundColor: props.disabled ? "#C6C6C6" : "#FFFFFF",
    color: props.disabled ? "#777777" : " #222222",
  };

  const blueStyle = {
    ...btnTextStyle,
    marginTop: props.marginTop,
    marginBottom: props.marginBottom,
    marginLeft: props.marginLeft,
    marginRight: props.marginRight,
    margin: props.margin,
    width: props.width,
    height: props.height ? props.height : "2.25rem",
    backgroundColor: props.disabled ? "#C6C6C6" : "#032063",
    color: props.disabled ? "#777777" : " #FFFFFF",
  };

  const greenStyle = {
    ...btnTextStyle,
    marginTop: props.marginTop,
    marginBottom: props.marginBottom,
    marginLeft: props.marginLeft,
    marginRight: props.marginRight,
    margin: props.margin,
    width: props.width,
    height: props.height ? props.height : "2.25rem",
    backgroundColor: props.disabled ? "#C6C6C6" : "#217346",
    color: props.disabled ? "#777777" : " #FFFFFF",
  };

  const btnColorTheme = () => {
    if (props.theme === "blue") {
      return blueStyle;
    } else if (props.theme === "gray") {
      return grayStyle;
    } else if (props.theme === "white") {
      return whiteStyle;
    } else if (props.theme === "green") {
      return greenStyle;
    } else {
      return blueStyle;
    }
  };
  return (
    <div style={{ display: "flex", justifyContent: "space-between", margin: "0.2rem 0" }}>
      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText={props.title} />
      </div>
      <Button style={btnColorTheme()} onClick={props.onClick} disabled={props.disabled}>
        {props.name}
      </Button>
    </div>
  );
};

export default NormalBtnWithSubtitle;
