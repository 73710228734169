import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, dateToStr, simpleAlert } from "components/CommonLib/CommonLib";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loadMemberViewStore, loadMembershipHistoryStore } from "../Module/actions";

const ChangeFromDateModal = ({ modalParam }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(0);
  const [convertedToDate, setConvertedToDate] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedDate !== 0) {
      const start = modalParam.from_date ? new Date(modalParam.from_date) : new Date();
      const end = new Date(modalParam.to_date);
      const diff = end.getTime() - start.getTime();
      const result = selectedDate + diff;
      const converted = dateToStr(result);
      setConvertedToDate(converted);
    }
  }, [selectedDate]);

  const getMaxDate = () => {
    const idx = modalParam.membershipDataList.findIndex(
      (x) => x.membership_list.membership_id === modalParam.membership_id,
    );

    const maxDays =
      modalParam.membershipDataList[idx].purchase_item_list.branch_policy.membership_policy
        .max_wait_day;
    if (maxDays === 0) {
      return undefined;
    }

    let lastDate;
    if (idx === 0) {
      lastDate = new Date(modalParam.membershipDataList[idx].membership_list.mbshp.from_date);
    } else {
      lastDate = new Date(modalParam.membershipDataList[idx - 1].membership_list.mbshp.to_date);
    }
    lastDate.setDate(lastDate.getDate() + maxDays);
    return dateToStr(lastDate);
  };

  const changeStartDate = async () => {
    try {
      // const formData = new FormData();
      // formData.append("branch_id", modalParam.branch_id);
      // formData.append("member_id", modalParam.member_id);
      // formData.append("membership_id", modalParam.membership_id);
      // formData.append("change_from_date", dateToStr(selectedDate));
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_USER_SERVER + "/mbshp/from_date/update",
        // formData,
        contentTypeJson,
        {
          params: {
            branch_id: modalParam.branch_id,
            member_id: modalParam.member_id,
            membership_id: modalParam.membership_id,
            change_from_date: dateToStr(selectedDate),
          },
        },
      );
      // console.log(
      //   "보낼",
      //   modalParam.branch_id,
      //   modalParam.member_id,
      //   modalParam.membership_id,
      //   dateToStr(selectedDate),
      // );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label labelText="회원권 시작일이 변경되었습니다." />
              </>
            ),
            onBtnOk: () => {
              storesDispatch(loadMemberViewStore(modalParam.member_id)); // 회원상세 업데이트
              storesDispatch(loadMembershipHistoryStore(modalParam.member_id)); //회원권 테이블 업데이트
            },
          }),
        );
        storesDispatch(globalModalOff());
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const onSaveBtn = () => {
    if (!selectedDate || !convertedToDate) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="시작일을 선택해 주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="시작일을 변경하시겠습니까?" />
            </>
          ),
          onBtnYes: () => {
            changeStartDate();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  return (
    <div>
      {loading && <Loading />}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            fontSize: "0.8rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Label labelText="변경 시작일을 선택해 주세요." />
          <Label labelText="종료일은 유효기간이 자동 계산되어 적용됩니다." />
        </div>
        <div style={{ display: "flex", marginTop: "0.7rem" }}>
          <DatePicker
            minWidth={"9rem"}
            // defaultValue={modalParam.from_date ? modalParam.from_date : dateToStr(new Date())}
            minDate={modalParam.from_date && modalParam.from_date}
            maxDate={getMaxDate()}
            onChangeCallback={(e) => {
              const convertDate = new Date(e).getTime();
              setSelectedDate(convertDate);
            }}
          />
          <TextField
            marginLeft={"0.5rem"}
            labelWidth={"0.625rem"}
            textBoxWidth={"8.75rem"}
            labelText="~"
            defaultValue={convertedToDate ? convertedToDate : ""}
            disabled
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <NormalBtn
          name="변경"
          onClick={() => {
            onSaveBtn();
          }}
        />
      </div>
    </div>
  );
};
export default ChangeFromDateModal;
