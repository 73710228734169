import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import { useEffect, useState } from "react";

const TotalInfoTable = ({ refundState }) => {
  // console.log(props);
  const [totalInfo, setTotalInfo] = useState(refundState.refund_sum_mary);

  useEffect(() => {
    setTotalInfo(refundState.refund_sum_mary);
  }, [refundState.refund_sum_mary]);

  return (
    <div
      style={{
        position: "relative",
        // top: "-1px",
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <CustomRow style={{ width: "100%" }}>
              <CustomTableContents
                style={{ backgroundColor: "#dfebf3" }}
                align="center"
                width="16%"
              >
                합계
              </CustomTableContents>
              <CustomTableContents width="12%">
                {totalInfo.paid_price.toLocaleString()}
              </CustomTableContents>
              <CustomTableContents width="12%">
                {totalInfo.deduct_price.toLocaleString()}
              </CustomTableContents>
              <CustomTableContents width="12%">
                {totalInfo.penalty.toLocaleString()}
              </CustomTableContents>
              <CustomTableContents width="12%">
                {totalInfo.discount_return.toLocaleString()}
              </CustomTableContents>
              <CustomTableContents width="12%">
                {totalInfo.refund_price.toLocaleString()}
              </CustomTableContents>
              <CustomTableContents width="12%">
                {totalInfo.done_refund_price.toLocaleString()}
              </CustomTableContents>
              <CustomTableContents width="12%">
                {totalInfo.repaid_price.toLocaleString()}
              </CustomTableContents>
            </CustomRow>
          </TableHead>
        </Table>
      </TableContainer>
    </div>
  );
};
export default TotalInfoTable;
