import { HttpInstance } from "lib/HttpLib";

export const searchOtManageList = (param) => {
  return HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/class/search_ot_manage_list", {
    params: {
      search_param: param.search_param,
    },
  });
};
export const searchPtManageList = (param) => {
  return HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/class/search_pt_manage_list", {
    params: {
      search_param: param.search_param,
    },
  });
};
export const getPtDetailInfo = (param) => {
  return HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/class/pt_detail_info", {
    params: {
      search_param: param.search_param,
    },
  });
};

export const getClassByStaffInfo = (param) => {
  return HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/class/manage_by_sfaff", {
    params: {
      staff_id: param.staff_id,
      branch_id: param.branch_id,
    },
  });
};

const get_api = {
  searchOtManageList,
  searchPtManageList,
  getPtDetailInfo,
  getClassByStaffInfo,
};

export default get_api;
