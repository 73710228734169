import IconButton from "components/Button/IconButton";
import { simpleAlert } from "components/CommonLib/CommonLib";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { loadMembershipHistoryStore, loadMemberViewStore } from "../../Module/actions";

const MembershipDataListView = ({ no, mbshp, activeInfo, onModalDone }) => {
  const storesDispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const label_width = "4.5rem";
  const box_width = "10rem";

  // const d = new Date();
  // const todayText =
  //   d.getFullYear() +
  //   "-" +
  //   (d.getMonth() + 1) +
  //   "-" +
  //   (d.getDate().toString().length < 2 ? "0" + d.getDate().toString() : d.getDate().toString());
  // const today = new Date(todayText);
  const mbshp_to = new Date(mbshp.membership_list.mbshp.to_date);
  const mbshp_from = new Date(mbshp.membership_list.mbshp.from_date);

  const remainDays = Math.floor(
    Math.abs((mbshp_to.getTime() - mbshp_from.getTime()) / (1000 * 60 * 60 * 24)),
  );

  const mbshpPeriod = {
    from_date: mbshp.membership_list.mbshp.from_date,
    to_date: mbshp.membership_list.mbshp.to_date,
  };

  const onClickChangeBtn = (fromTo) => {
    //TO-DO 유효성 검사 추가해야함

    // console.log("mbshpPeriod", mbshpPeriod);
    // console.log("activeInfo", activeInfo);

    if (mbshpPeriod.from_date === "" || mbshpPeriod.to_date === "") {
      simpleAlert(storesDispatch, "알림", "기간 입력이 잘못되었습니다.");
      return;
    }

    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: <Label labelText="수강 정보를 변경하시겠습니까?" />,
        onBtnYes: () => {
          onChangeData(fromTo);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const onChangeData = async (fromTo) => {
    setLoading(true);

    if (fromTo === "from") {
      await HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/change/force/from_date", {
        params: {
          member_id: activeInfo.member_id,
          membership_id: mbshp.membership_list.membership_id,
          change_from_date: mbshpPeriod.from_date,
        },
      })
        .then((res) => {
          if (!res.data.msg) {
            simpleAlert(storesDispatch, "알림", "시작일이 변경되었습니다.");
            storesDispatch(globalModalOff()); //모달닫기
            storesDispatch(loadMemberViewStore(activeInfo.member_id)); // 회원 정보 가져오기
            storesDispatch(loadMembershipHistoryStore(activeInfo.member_id)); // 회원권 이력 가져오기
          }
        })
        .catch((err) => {
          console.log(err);
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        });
    }

    if (fromTo === "to") {
      await HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/change/force/to_date", {
        params: {
          member_id: activeInfo.member_id,
          membership_id: mbshp.membership_list.membership_id,
          change_to_date: mbshpPeriod.to_date,
        },
      })
        .then((res) => {
          if (!res.data.msg) {
            simpleAlert(storesDispatch, "알림", "종료일이 변경되었습니다.");
            storesDispatch(globalModalOff()); //모달닫기
            storesDispatch(loadMemberViewStore(activeInfo.member_id)); // 회원 정보 가져오기
            storesDispatch(loadMembershipHistoryStore(activeInfo.member_id)); // 회원권 이력 가져오기
          }
        })
        .catch((err) => {
          console.log(err);
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        });
    }

    setLoading(false);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
      {loading && <Loading />}

      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="회원권" />
      </div>
      <TextField
        labelWidth={label_width}
        textBoxWidth={"21.7rem"}
        labelText="상품명"
        disabled
        defaultValue={mbshp.membership_list.item_name}
      />
      {/* 유효기간 -> 시작일 & 종료일로 임시 변경 */}
      {/* <div style={{ display: "flex" }}>
        <DatePicker
          labelWidth={label_width}
          minWidth={box_width}
          labelText="유효기간"
          disabled={mbshp.membership_list.mbshp_type === "구독형"}
          defaultValue={mbshp.membership_list.mbshp.from_date}
          onBlur={(e) => {
            if (mbshpPeriod.to_date && new Date(e) >= new Date(mbshpPeriod.to_date)) {
              simpleAlert(storesDispatch, "알림", "시작일은 종료일보다 클 수 없습니다.", true);
            } else {
              mbshpPeriod.from_date = e;
            }
          }}
        />
        <DatePicker
          labelWidth={"0.5rem"}
          minWidth={box_width}
          labelText="~"
          disabled={mbshp.membership_list.mbshp_type === "구독형"}
          defaultValue={mbshp.membership_list.mbshp.to_date}
          onBlur={(e) => {
            if (mbshpPeriod.from_date && new Date(e) <= new Date(mbshpPeriod.from_date)) {
              simpleAlert(storesDispatch, "알림", "시작일은 종료일보다 클 수 없습니다.", true);
            } else {
              mbshpPeriod.to_date = e;
            }
          }}
        />
      </div> */}
      <div style={{ display: "flex" }}>
        <DatePicker
          labelWidth={label_width}
          minWidth={box_width}
          labelText="시작일"
          disabled={mbshp.membership_list.mbshp_type === "구독형"}
          defaultValue={mbshp.membership_list.mbshp.from_date}
          onBlur={(e) => {
            if (mbshpPeriod.to_date && new Date(e) >= new Date(mbshpPeriod.to_date)) {
              simpleAlert(storesDispatch, "알림", "시작일은 종료일보다 클 수 없습니다.", true);
            } else {
              mbshpPeriod.from_date = e;
            }
          }}
        />
        <NormalBtn
          name="변경"
          onClick={() => {
            onClickChangeBtn("from");
          }}
        />
      </div>

      <div style={{ display: "flex" }}>
        <DatePicker
          labelWidth={label_width}
          minWidth={box_width}
          labelText="종료일"
          disabled={mbshp.membership_list.mbshp_type === "구독형"}
          defaultValue={mbshp.membership_list.mbshp.to_date}
          onBlur={(e) => {
            if (mbshpPeriod.from_date && new Date(e) <= new Date(mbshpPeriod.from_date)) {
              simpleAlert(storesDispatch, "알림", "시작일은 종료일보다 클 수 없습니다.", true);
            } else {
              mbshpPeriod.to_date = e;
            }
          }}
        />
        <NormalBtn
          name="변경"
          onClick={() => {
            onClickChangeBtn("to");
          }}
        />
      </div>

      <TextField
        labelWidth={label_width}
        textBoxWidth={box_width}
        labelText="종류"
        disabled
        defaultValue={mbshp.membership_list.mbshp_type}
      />
      <TextField
        labelWidth={label_width}
        textBoxWidth={box_width}
        labelText="회원권 옵션"
        disabled
        defaultValue={mbshp.membership_list.mbshp_option}
      />

      <TextField
        labelWidth={label_width}
        textBoxWidth={box_width}
        labelText="잔여일"
        endAdornment={"일"}
        disabled
        defaultValue={remainDays}
      />
      {mbshp.membership_list.mbshp_option === "패스권" ? (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              labelWidth={label_width}
              textBoxWidth={box_width}
              endAdornment={"회"}
              labelText="잔여 패스"
              isStringNumber
              defaultValue={activeInfo.active_membership.remain_pass_cnt}
            />
            <Label
              labelText={`*총 패스: ${mbshp.membership_list.pass.total_cnt} `}
              color="red"
              fontSize="0.8rem"
            />
          </div>
        </>
      ) : (
        ""
      )}

      {mbshp.membership_list.mbshp_type === "완납형" ? (
        //
        // 완납형
        //
        <>
          {/* 부가상품 임시 주석처리 */}
          {/* <div>
            <div style={{ display: "flex" }}>
              <IconButton type="border" />
              <div style={{ width: "0.5rem" }}></div>
              <SubTitle titleText="부가상품" />
            </div>
            <div>
              {mbshp.membership_list.locker_list.length > 0 ? (
                <div style={{ display: "flex" }}>
                  <CheckBox
                    labelText={mbshp.membership_list.locker_list.length === 1 ? "락커" : "락커 1"}
                    labelWidth={"3rem"}
                    disabled
                    defaultValue={mbshp.membership_list.locker.is_use}
                  />
                </div>
              ) : (
                ""
              )}
              {mbshp.membership_list.locker_list.length === 2 ? (
                <div style={{ display: "flex" }}>
                  <CheckBox
                    labelText="락커 2"
                    labelWidth={"3rem"}
                    disabled
                    defaultValue={mbshp.membership_list.locker_list[1].is_use}
                  />
                </div>
              ) : (
                ""
              )}
              {mbshp.membership_list.cloth.is_use ? (
                <div style={{ display: "flex" }}>
                  <CheckBox
                    labelText="운동복"
                    labelWidth={"3rem"}
                    disabled
                    defaultValue={mbshp.membership_list.cloth.is_use}
                  />
                </div>
              ) : (
                ""
              )}

              {mbshp.membership_list.gx.is_use ? (
                <div style={{ display: "flex" }}>
                  <CheckBox
                    labelWidth={"3rem"}
                    labelText="GX"
                    disabled
                    defaultValue={mbshp.membership_list.gx.is_use}
                  />
                </div>
              ) : (
                ""
              )}

              {!mbshp.membership_list.locker.is_use &&
              !mbshp.membership_list.cloth.is_use &&
              !mbshp.membership_list.gx.is_use ? (
                <div>
                  <Label padding={"0 0 1rem 0"} labelText="사용중인 부가상품이 없습니다." />
                </div>
              ) : (
                ""
              )}
            </div>
          </div> */}
        </>
      ) : (
        //
        // 구독형
        //

        <>
          {/* <div style={{ display: "flex" }}>
            <TextField
              labelWidth={label_width}
              textBoxWidth={box_width}
              labelText="월 구독료"
              endAdornment={"원"}
              textMarginRight={"1.4rem"}
              disabled
              defaultValue={mbshp.membership_list.mbshp.subs_options.subs_price.toLocaleString()}
            />
            <NumberTextField
              labelWidth={"2rem"}
              endAdornment={"원"}
              textBoxWidth={"7.6rem"}
              labelText="할인"
              onBlur={(e) => {
                newInfo.membership_all_list[no].membership_list.mbshp.subs_options.subs_dis = e;
              }}
            />
          </div>
          <div style={{ display: "flex" }}>
            <DatePicker
              labelWidth={label_width}
              minWidth={box_width}
              labelText="약정기간"
              disabled
              defaultValue={mbshp.membership_list.mbshp.subs_options.contract_from_date}
            />
            <DatePicker
              labelWidth={"0.5rem"}
              minWidth={box_width}
              labelText="~"
              disabled
              defaultValue={mbshp.membership_list.mbshp.subs_options.contract_to_date}
            />
          </div>
          <SubLine />
          <div>
            <div style={{ display: "flex" }}>
              <IconButton type="border" />
              <div style={{ width: "0.5rem" }}></div>
              <SubTitle titleText="부가상품" />
            </div>
            <div>
              {mbshp.membership_list.locker_list.length > 0 ? (
                <div style={{ display: "flex" }}>
                  <CheckBox
                    labelText={mbshp.membership_list.locker_list.length === 1 ? "락커" : "락커 1"}
                    labelWidth={"3rem"}
                    disabled
                    defaultValue={mbshp.membership_list.locker.is_use}
                  />
                  <div style={{ display: "flex", marginTop: "0.7rem" }}>
                    <TextField
                      labelWidth={label_width}
                      textBoxWidth={box_width}
                      labelText="월 구독료"
                      endAdornment={"원"}
                      textMarginRight={"1.4rem"}
                      disabled
                      defaultValue={
                        mbshp.membership_list.locker_list.length !== 0
                          ? mbshp.membership_list.locker_list[0].subs_options.subs_price.toLocaleString()
                          : ""
                      }
                    />
                    <NumberTextField
                      endAdornment={"원"}
                      labelWidth={"2rem"}
                      textBoxWidth={"7.6rem"}
                      labelText="할인"
                      disabled={!mbshp.membership_list.locker.is_use}
                      defaultValue={
                        mbshp.membership_list.locker_list.length !== 0
                          ? mbshp.membership_list.locker_list[0].subs_options.subs_dis.toLocaleString()
                          : ""
                      }
                      onBlur={(e) => {
                        newInfo.membership_all_list[
                          no
                        ].membership_list.locker_list[0].subs_options.subs_dis = e;
                      }}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {mbshp.membership_list.locker_list.length === 2 ? (
                <div style={{ display: "flex" }}>
                  <CheckBox
                    labelText="락커 2"
                    labelWidth={"3rem"}
                    disabled
                    defaultValue={mbshp.membership_list.locker_list[1].is_use}
                  />
                  <div style={{ display: "flex", marginTop: "0.7rem" }}>
                    <TextField
                      labelWidth={label_width}
                      textBoxWidth={box_width}
                      labelText="월 구독료"
                      endAdornment={"원"}
                      textMarginRight={"1.4rem"}
                      disabled
                      defaultValue={mbshp.membership_list.locker_list[1].subs_options.subs_price.toLocaleString()}
                    />
                    <NumberTextField
                      endAdornment={"원"}
                      labelWidth={"2rem"}
                      textBoxWidth={"7.6rem"}
                      labelText="할인"
                      defaultValue={mbshp.membership_list.locker_list[1].subs_options.subs_dis.toLocaleString()}
                      onBlur={(e) => {
                        newInfo.membership_all_list[
                          no
                        ].membership_list.locker_list[1].subs_options.subs_dis = e;
                      }}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {mbshp.membership_list.cloth.is_use ? (
                <div style={{ display: "flex" }}>
                  <CheckBox
                    labelText="운동복"
                    labelWidth={"3rem"}
                    disabled
                    defaultValue={mbshp.membership_list.cloth.is_use}
                  />
                  <div style={{ display: "flex", marginTop: "0.7rem" }}>
                    <TextField
                      labelWidth={label_width}
                      textBoxWidth={box_width}
                      labelText="월 구독료"
                      endAdornment={"원"}
                      textMarginRight={"1.4rem"}
                      disabled
                      defaultValue={mbshp.membership_list.cloth.subs_options.subs_price.toLocaleString()}
                    />
                    <NumberTextField
                      endAdornment={"원"}
                      labelWidth={"2rem"}
                      textBoxWidth={"7.6rem"}
                      labelText="할인"
                      disabled={!mbshp.membership_list.cloth.is_use}
                      defaultValue={mbshp.membership_list.cloth.subs_options.subs_dis.toLocaleString()}
                      onBlur={(e) => {
                        newInfo.membership_all_list[
                          no
                        ].membership_list.cloth.subs_options.subs_dis = e;
                      }}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              {mbshp.membership_list.gx.is_use ? (
                <div style={{ display: "flex" }}>
                  <CheckBox
                    labelWidth={"3rem"}
                    labelText="GX"
                    disabled
                    defaultValue={mbshp.membership_list.gx.is_use}
                  />
                  <div style={{ display: "flex", marginTop: "0.7rem" }}>
                    <TextField
                      labelWidth={label_width}
                      textBoxWidth={box_width}
                      labelText="월 구독료"
                      endAdornment={"원"}
                      textMarginRight={"1.4rem"}
                      disabled
                      defaultValue={mbshp.membership_list.gx.subs_options.subs_price.toLocaleString()}
                    />
                    <NumberTextField
                      endAdornment={"원"}
                      labelWidth={"2rem"}
                      textBoxWidth={"7.6rem"}
                      labelText="할인"
                      defaultValue={mbshp.membership_list.gx.subs_options.subs_dis.toLocaleString()}
                      disabled={!mbshp.membership_list.gx.is_use}
                      onBlur={(e) => {
                        newInfo.membership_all_list[no].membership_list.gx.subs_options.subs_dis =
                          e;
                      }}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              {!mbshp.membership_list.locker.is_use &&
              !mbshp.membership_list.cloth.is_use &&
              !mbshp.membership_list.gx.is_use ? (
                <div>
                  <Label padding={"0 0 1rem 0"} labelText="사용중인 부가상품이 없습니다." />
                </div>
              ) : (
                ""
              )}
            </div>
            <SubLine />
          </div> */}
        </>
      )}

      {/* 변경 방식을 각 항목마다 변경 버튼을 눌러 변경하도록 수정 */}
      {/* <TextField
        labelWidth={"4.5rem"}
        textBoxWidth={"22rem"}
        labelText="변경 사유"
        onBlur={(e) => {
          // newInfo.membership_chage_reason = e.target.value;
        }}
        disabled
      />
      <div style={{ display: "flex", justifyContent: "center", marginTop: "0.7rem" }}>
        <NormalBtn name="변경" onClick={onClickChangeBtn} />
      </div> */}
    </div>
  );
};

export default MembershipDataListView;
