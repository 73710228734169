import { HttpInstance } from "lib/HttpLib";

//급여 메인
export const getSalaryInfoData = (param) => {
  return HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + "/salary/global_summary", {
    params: {
      salary_report_id: param.salary_report_id,
      is_edit: param.is_edit,
    },
  });
};

//급여현황 1번탭
// -> 삭제해도 됨. 사용하지 않음.
export const getSalaryStatusData = (param) => {
  return HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + "/salary/overall_list", {
    params: {
      salary_report_id: param.salary_report_id,
      is_edit: param.is_edit,
    },
  });
};

//기본급 2번탭
export const getStaffStandardSalaryStatusData = (param) => {
  return HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + "/salary/standard_list", {
    params: {
      salary_report_id: param.salary_report_id,
      is_edit: param.is_edit,
    },
  });
};

//인센티브 3번탭
// -> 삭제해도 됨. 사용하지 않음.
export const getMbshpSalaryData = (param) => {
  return HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + "/salary/mbshp_incentive/list", {
    params: {
      salary_report_id: param.salary_report_id,
      is_edit: param.is_edit,
    },
  });
};

//PT 4번탭
export const getPtSalaryData = (param) => {
  return HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + "/salary/pt/list", {
    params: {
      salary_report_id: param.salary_report_id,
      is_edit: param.is_edit,
    },
  });
};

//GX 5번탭
export const getGxSalaryData = (param) => {
  return HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + "/salary/gx/list", {
    params: {
      salary_report_id: param.salary_report_id,
      is_edit: param.is_edit,
    },
  });
};

//기타 6번탭
export const getEtcSalaryData = (param) => {
  return HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + "/salary/etc/list", {
    params: {
      salary_report_id: param.salary_report_id,
      is_edit: param.is_edit,
    },
  });
};

//안쓰는거
export const getClassSessionData = (param) => {
  return HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/salary/class_session", {
    params: {
      class_id: param.class_id,
      staff_id: param.staff_id,
    },
  });
};

const get_api = {
  getSalaryInfoData,
  getSalaryStatusData,
  getStaffStandardSalaryStatusData,
  getMbshpSalaryData,
  getGxSalaryData,
  getPtSalaryData,
  getEtcSalaryData,
  getClassSessionData,
};

export default get_api;
