import CardFixed from "components/Card/CardFixed";
import FileUpload from "components/LabelInput/FileUpload";
import Label from "components/LabelInput/Label";
import { useRegisterStore } from "pages/ERP/MemberRegister/Register/modules/store/registerState";
import { useEffect } from "react";
import { uploadFile } from "./uploadFile";

const DocInfo = (props) => {
  // 전역 상태 관리
  const { showSubmitDocList, resetSubmitDocState } = useRegisterStore();

  // 컴포넌트 unmount시 제출 서류 조건 리셋
  useEffect(() => {
    return () => resetSubmitDocState();
  }, [resetSubmitDocState]);

  //docList : 서버에서 GET 받아온 contract_doc_list
  //uploadDoneCallback: 파일 업로드 이후 실행할 함수
  //onlyOne: 한개파일만 업로드 할때 (UI 분기처리용)
  return (
    <div style={{ marginBottom: "0.5rem" }}>
      {props.onlyOne ? (
        <div>
          {props.docList.map((el, idx) => {
            const docName = el.file_info.doc_name;

            return (
              // 조건에 따른 제출 서류 보이기
              showSubmitDocList[docName] && (
                <FileUpload
                  key={idx}
                  marginTop="0.5rem"
                  labelWidth={"6rem"}
                  textBoxWidth={props.textBoxWidth}
                  textMarginRight={"0rem"}
                  labelText={docName}
                  onUploadFile={(file) => {
                    uploadFile(props.docList[idx], file, props.uploadDoneCallback);
                  }}
                  hideDownload
                />
              )
            );
          })}
        </div>
      ) : (
        <CardFixed
          midDom={
            <div>
              <Label
                labelText="제출 서류"
                justifyContent="left"
                fontSize="1.125rem"
                fontWeight="bold"
              />
              <div style={{ marginTop: "1.25rem", marginBottom: "0.5rem" }}>
                {props.docList.map((el, idx) => {
                  const docName = el.file_info.doc_name;

                  return (
                    showSubmitDocList[docName] && (
                      <FileUpload
                        key={idx}
                        marginTop="0.5rem"
                        labelWidth={"7rem"}
                        textBoxWidth={props.textBoxWidth ? props.textBoxWidth : "7.5rem"}
                        textMarginRight={"0rem"}
                        labelText={docName}
                        onUploadFile={(file) => {
                          uploadFile(props.docList[idx], file, props.uploadDoneCallback);
                        }}
                        hideDownload
                      />
                    )
                  );
                })}
              </div>
            </div>
          }
          isLoading={false}
          rootStyle={{
            marginTop: "0.625rem",
            borderRadius: "5px",
            backgroundColor: "#fff",
            border: "solid 1px #dedede",
          }}
        />
      )}
    </div>
  );
};

export default DocInfo;
