import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import * as lodash from "lodash";
import { useEffect, useState } from "react";
import ERPSetCoupon from "./ERPSetCoupon";
import ERPSetCouponNumbers from "./ERPSetCouponNumbers";
import ERPSetFirewall from "./ERPSetFirewall";
import ERPSetHQ from "./ERPSetHQ";
import ERPSetPackage from "./ERPSetPackage";

const ERPSetIndex = ({ modalSelect, modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();

  const [paramData, setParamData] = useState(modalParam.modalParam);

  // console.log(paramData);
  useEffect(() => {
    const editData = lodash.cloneDeep(modalParam.modalParam);
    setParamData(editData);
    return () => {};
  }, []);

  if (modalSelect === "본부관리/패키지") {
    return <ERPSetPackage modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "본부관리/본부") {
    return <ERPSetHQ modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "접근관리/IP") {
    return <ERPSetFirewall modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "쿠폰관리/쿠폰") {
    return <ERPSetCoupon modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "쿠폰관리/쿠폰번호보기") {
    return <ERPSetCouponNumbers modalParam={paramData} />;
  }

  //  else if (modalSelect === "층추가") {
  //   return <LockerFloorModal modalParam={paramData} onModalDone={onModalDone} />;
  // } else if (modalSelect === "구역추가" || modalSelect === "구역수정") {
  //   return <LockerSetModal modalParam={paramData} onModalDone={onModalDone} />;
  // } else if (modalSelect === "배치설정") {
  //   return <LayOutSetting modalParam={paramData} onModalDone={onModalDone} />;
  // }
  else {
    return <div>current ELSE</div>;
  }
};
export default ERPSetIndex;
