import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty } from "components/CommonLib/CommonLib";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";

export default class PrepayItemTable {
  table_title = "";
  btnStyleClass = btnStyles();

  onClickOpenModal = null;
  branchId = "";
  viewRefresh = null;
  onClickDelete = null;

  constructor(branchId, onClickOpenModal, viewRefresh, onClickDelete) {
    this.branchId = branchId;
    this.onClickOpenModal = onClickOpenModal;
    this.viewRefresh = viewRefresh;
    this.onClickDelete = onClickDelete;
  }

  onEdit = (item_id) => {
    const param = {
      title: "완납형 상품 수정",
      modalParam: {
        mode: "edit",
        branchId: this.branchId,
        itemId: item_id,
        refreshStore: this.viewRefresh,
      },
    };
    this.onClickOpenModal("상품관리/완납형", param);
  };

  getPeriod = (data) => {
    let month = 0;
    let day = 0;
    let hour = 0;
    if (data.mbshp_option === "일일권") {
      hour = data.total_period_hour;
      return hour + "시간";
    } else {
      month = data.period_month + data.service_month;
      day = data.service_day;
      return month + "개월 " + day + "일";
    }
  };

  getCombination = (data) => {
    let res = "";
    if (data.is_combine_cloth) {
      res += "운동복";
    }
    if (data.is_combine_gx) {
      res += res === "" ? "GX" : ", GX";
    }
    if (data.is_combine_locker) {
      res += res === "" ? "락커" : ", 락커";
    }
    if (data.is_combine_pt) {
      res += res === "" ? "PT" : ", PT";
    }

    return res;
  };

  create_table = (data) => {
    return (
      <CustomRow style={{ width: "100%" }}>
        <CustomTableContents width="5%">{data.base_info.order}</CustomTableContents>
        <CustomTableContents width="12%">{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents width="15%">{data.item_name}</CustomTableContents>
        <CustomTableContents width="7%" $isNumber>
          {data.mbshp_price.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents width="5%">{data.mbshp_option}</CustomTableContents>
        <CustomTableContents width="8%">{this.getPeriod(data)}</CustomTableContents>
        <CustomTableContents width="12%">{this.getCombination(data)}</CustomTableContents>
        <CustomTableContents width="6%" $isNumber>
          {data.pt_session + " 회"}
        </CustomTableContents>
        <CustomTableContents width="8%" $isNumber>
          {data.pt_price.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents width="7%">{data.base_info.sales_status}</CustomTableContents>
        <CustomTableContents width="5%">
          {data.base_info.sales_limit === true ? "Y" : "N"}
        </CustomTableContents>
        <CustomTableContents width="5%">
          <div style={{ display: "flex" }}>
            <IconButton type="edit" width="2.2rem" onClick={() => this.onEdit(data.item_id)} />
            <IconButton
              type="delete"
              width="2.2rem"
              onClick={() => this.onClickDelete(data.item_name, data.item_id, "완납형")}
              disabled={data.base_info.sales_status !== "판매대기"}
            />
          </div>
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "게시\n순서", field: "order" },
    { title: "등록일시", field: "reg_date" },
    { title: "상품명", field: "item_name" },
    { title: "판매가", field: "price" },
    { title: "옵션", field: "option" },
    { title: "유효기간", field: "expire_date" },
    { title: "결합 부가상품", field: "extra" },
    { title: "결합\nPT세션", field: "pt_merge" },
    { title: "PT 판매가", field: "pt_price" },
    { title: "판매 상태", field: "status" },
    { title: "판매제한", field: "limit" },
    { title: "", field: "action" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
