import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import Label from "components/LabelInput/Label";
import NumberTextField from "components/LabelInput/NumberTextField";
import TextField from "components/LabelInput/TextField";
import { globalAlertOn } from "modules/actions/Alert";
import { useDispatch } from "react-redux";
import WorkTime from "./CommonItem/WorkTime";

const GxInfoModal = ({ modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();

  const validation = () => {
    let res = "";

    if (!modalParam.class_name) res += "· 수업명\n";
    if (!modalParam.class_fee) res += "· 회당 금액\n";
    if (
      !modalParam.time_list[0].is_use &&
      !modalParam.time_list[1].is_use &&
      !modalParam.time_list[2].is_use &&
      !modalParam.time_list[3].is_use &&
      !modalParam.time_list[4].is_use &&
      !modalParam.time_list[5].is_use &&
      !modalParam.time_list[6].is_use &&
      !modalParam.time_list[7].is_use
    ) {
      res += "· 근무 요일\n";
    }

    // 요일 체크는 되어 있는데 시간 설정이 안된 경우
    for (let i = 0; i < modalParam.time_list.length; i++) {
      if (
        modalParam.time_list[i].is_use &&
        modalParam.time_list[i].from_time === "00:00" &&
        modalParam.time_list[i].end_time === "00:00"
      ) {
        res += "· 근무 시간\n";
        break;
      }
    }

    return res;
  };

  const onButtonEvents = () => {
    const validationRes = validation();
    if (validationRes !== "") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="필수값을 모두 입력해주셔야 합니다." />
              <br />
              <br />
              <Label labelText={validationRes} whiteSpace="pre-wrap" />
            </>
          ),
          // width: "50rem",
          onBtnOk: () => {},
        }),
      );
    } else {
      onModalDone(modalParam);
    }
  };

  return (
    <div style={{ minWidth: "35rem" }}>
      <div style={{ display: "flex", justifyContent: "right" }}>
        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={() => {
            onButtonEvents();
          }}
        >
          저장
        </Button>
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <TextField
          labelWidth={"3.5rem"}
          textBoxWidth={"14rem"}
          // textMarginRight={"1.625rem"}
          labelText="수업명"
          defaultValue={modalParam.class_name}
          onChangeCallback={(e) => {
            modalParam.class_name = e;
          }}
        />

        <NumberTextField
          marginLeft="2rem"
          labelWidth={"4.5rem"}
          textBoxWidth={"9rem"}
          // textMarginRight={"1.625rem"}
          labelText="회당 금액"
          defaultValue={modalParam.class_fee}
          onChangeCallback={(e) => {
            modalParam.class_fee = e;
          }}
        />
      </div>
      <WorkTime workInfoStore={modalParam} isGx={true} />
    </div>
  );
};
export default GxInfoModal;
