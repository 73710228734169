import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import NumberTextField from "components/LabelInput/NumberTextField";
import TextField from "components/LabelInput/TextField";
import Popup from "components/ModalPopup/Popup";
import _ from "lodash";
import { getIsViewLock } from "pages/ApprovalManage/ApprovalCommon/checker";
import { order_detail_state } from "pages/ApprovalManage/Module/reducers/approval_content_state";
import { useCallback, useRef, useState } from "react";
import DaumPostcode from "react-daum-postcode";

const OrderReqForm = ({ mode, contentState, setContentState }) => {
  const btnStyleClass = btnStyles();

  let readOnly = mode === "approver" || mode === "unspent" || mode === "view";

  const [, updateState] = useState(); // 강제 렌더링용
  const forceUpdate = useCallback(() => updateState({}), []);

  const [isAddrPopup, setAddrPopup] = useState(false);
  const curAddrIndex = useRef(0);

  const openAddrPopup = (val) => {
    setAddrPopup(val);
  };

  const handleComplete = (e, data) => {
    openAddrPopup(false);
    let fullAddress = e.address;
    let extraAddress = "";

    if (e.addressType === "R") {
      if (e.bname !== "") {
        extraAddress += e.bname;
      }
      if (e.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${e.buildingName}` : e.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    contentState.item_list[curAddrIndex.current].addr_1 = fullAddress;
    forceUpdate();
  };

  const onClickAddRow = () => {
    contentState.item_list.push(_.cloneDeep(order_detail_state));
    forceUpdate();
  };
  const onClickDelete = (idx) => {
    contentState.item_list.splice(idx, 1);
    forceUpdate();
  };

  const contentList = new contentTable(readOnly, openAddrPopup, onClickDelete, curAddrIndex);

  return (
    <div>
      <Popup
        isOpen={isAddrPopup}
        onClose={() => {
          openAddrPopup(false);
        }}
        title="주소 검색"
        Content={
          <DaumPostcode
            onComplete={(e) => handleComplete(e)}
            autoClose={false}
            style={{ width: "30rem", height: "35rem" }}
          />
        }
      />

      <div style={{ margin: "0.5rem 0.1rem" }}>
        <CustomTable
          columns_head={contentList.columns_head}
          table_title={contentList.table_title}
          rest_call={contentList.get_data_from_rest}
          row_render={contentList.create_table}
          rest_data={contentState.item_list}
          height="35rem"
        />
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.25rem" }}>
        <Button
          className={clsx({
            [btnStyleClass.btnRootWhite]: true,
            [btnStyleClass.buttonType7]: true,
          })}
          onClick={() => {
            onClickAddRow();
          }}
          disabled={getIsViewLock(mode)}
        >
          행 추가
        </Button>
      </div>
    </div>
  );
};
export default OrderReqForm;

//

class contentTable {
  table_title = "";
  onClickDelete = null;
  openAddrPopup = null;
  curAddrIndex = null;
  readOnly = null;

  constructor(readOnly, openAddrPopup, onClickDelete, curAddrIndex) {
    this.readOnly = readOnly;
    this.onClickDelete = onClickDelete;
    this.openAddrPopup = openAddrPopup;
    this.curAddrIndex = curAddrIndex;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow
        style={{}}
        key={idx}
        // onClick={(e) => {
        //   if (e.target.nodeName === "DIV" || e.target.nodeName === "TD") {
        //     this.onClickRow(idx);
        //   }
        // }}
        // hover
      >
        <CustomTableContents>
          <TextField
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            defaultValue={data.order_name}
            onChangeCallback={(e) => {
              data.order_name = e;
            }}
            disabled={this.readOnly}
          />
        </CustomTableContents>
        <CustomTableContents>
          <NumberTextField
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            defaultValue={data.order_cnt}
            onChangeCallback={(e) => {
              data.order_cnt = e;
            }}
            disabled={this.readOnly}
          />
        </CustomTableContents>
        <CustomTableContents>
          <TextField
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            defaultValue={data.email}
            onChangeCallback={(e) => {
              data.email = e;
            }}
            disabled={this.readOnly}
          />
        </CustomTableContents>
        <CustomTableContents>
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", width: "50%" }}>
              {this.readOnly ? (
                <></>
              ) : (
                <IconButton
                  type="search"
                  onClick={() => {
                    this.openAddrPopup(true);
                    this.curAddrIndex.current = idx;
                  }}
                  scale="0.8"
                />
              )}
              <TextField
                labelMarginRight="0rem"
                textMarginRight="0rem"
                fullWidth
                defaultValue={data.addr_1}
                // onChangeCallback={(e) => {}}
                readonly
                disabled
              />
            </div>
            <div style={{ display: "flex", width: "50%" }}>
              <TextField
                labelMarginRight="0rem"
                textMarginRight="0rem"
                fullWidth
                defaultValue={data.addr_2}
                onChangeCallback={(e) => {
                  data.addr_2 = e;
                }}
                disabled={this.readOnly}
              />
            </div>
          </div>
        </CustomTableContents>
        <CustomTableContentsEND>
          {!this.readOnly ? (
            <IconButton type="delete" onClick={() => this.onClickDelete(idx)} />
          ) : (
            <></>
          )}
        </CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "발주 상품명", width: "23%" },
    { title: "수량", width: "7%" },
    { title: "세금계산서 메일", width: "15%" },
    { title: "수령 주소", width: "50%" },
    { title: "", width: "5%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
