export const memberInitPurchaseState = {
  /* 미납금 */
  unpaid: {
    total_unpaid: 0,
    unpaid_list: [],
  },
  /* 구매항목 리스트 */
  purchase_list: [],
  /* 결제수단 리스트 */
  payment_list: [],
};
