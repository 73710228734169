import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";

const RefundListTable = ({ reportState }) => {
  const resultTableInst = new resultTable();

  const getTotalPrice = () => {
    return reportState.refund_record_list.reduce((sum, cur) => {
      const total =
        cur.refund_info.re_paid +
        cur.refund_info.billing_amount +
        cur.refund_info.discounted_refund -
        cur.refund_info.on_site_cancellation_amount;
      return sum + total;

      // return sum + cur.total_price;
    }, 0);
  };

  return (
    <div style={{ marginTop: "2rem" }}>
      <Label
        labelText="환불 내역 (환불 및 약정 해지)"
        fontWeight="bold"
        fontSize="1.2rem"
        justifyContent="flex-start"
        marginBottom="0.25rem"
      />
      <CustomTable
        columns_head={resultTableInst.columns_head}
        table_title={resultTableInst.table_title}
        rest_call={resultTableInst.get_data_from_rest}
        row_render={resultTableInst.create_table}
        rest_data={reportState.refund_record_list}
      />
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem" }}>
        <TextField
          labelText="합계"
          labelWidth="3rem"
          textBoxWidth="10rem"
          textMarginRight="0rem"
          isNumber
          defaultValue={getTotalPrice().toLocaleString()}
          endAdornment={"원"}
          disabled
        />
      </div>
    </div>
  );
};
export default RefundListTable;

class resultTable {
  table_title = "";

  // constructor() {}
  getListMerger = (data) => {
    let resultStr = "";
    for (let i = 0; i < data.product_list.length; i++) {
      resultStr += data.product_list[i].product + ",";
    }
    // resultStr = resultStr.trimEnd(" ,");
    if (resultStr !== "") {
      resultStr = resultStr.slice(0, -1);
    }

    return resultStr;
  };

  get_refund_total = (data) => {
    let totalRefund =
      data.refund_info.re_paid +
      data.refund_info.billing_amount +
      data.refund_info.discounted_refund -
      data.refund_info.on_site_cancellation_amount;
    return totalRefund.toLocaleString();
  };

  create_table = (data, idx) => {
    // console.log(data);
    return (
      <CustomRow style={{}} key={idx}>
        <CustomTableContents>{data.reason}</CustomTableContents>
        <CustomTableContents>{data.reg_info.reg_name}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_name}</CustomTableContents>
        <CustomTableContents>{data.purchase_code}</CustomTableContents>
        <CustomTableContents>{this.getListMerger(data)}</CustomTableContents>
        <CustomTableContents $isNumber>
          {data.refund_info.re_paid.toLocaleString()}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.refund_info.billing_amount.toLocaleString()}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.refund_info.discounted_refund.toLocaleString()}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.refund_info.on_site_cancellation_amount.toLocaleString()}
        </CustomTableContents>
        <CustomTableContents $isNumber fontWeight="bold">
          {this.get_refund_total(data)}
        </CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "구분", width: "8.3%" },
    { title: "담당자", width: "8.3%" },
    { title: "회원번호", width: "8.3%" },
    { title: "이름", width: "8.3%" },
    { title: "구매코드", width: "8.3%" },
    { title: "취소항목", width: "8.3%" },
    { title: "재결제금액", width: "8.3%" },
    { title: "청구금액", width: "8.3%" },
    { title: "할인반환금", width: "8.3%" },
    { title: "취소금액", width: "8.3%" },
    { title: "합계", width: "8.3%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
