import { useMutation, useQueryClient } from "@tanstack/react-query";
import { patchSalesPrice } from "apis/staff/sales_record_api";
import { simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import NumberTextField from "components/LabelInput/NumberTextField";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const SalesPriceChangeModalBody = styled.div`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
const SalesPriceChangeModalFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  gap: 0.5rem;
`;

const SalesPriceChangeModal = ({ modalParam, onModalDone }) => {
  const queryClient = useQueryClient();
  const storesDispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { record_id, member_info, staff_info, incentive } = modalParam;
  // 페이지 구성 변수
  const labelWidth = "5rem";
  const textWidth = "12rem";

  // 페이지 상태 관리
  const [submitData, setSubmitData] = useState({});

  // API Query
  // [PATCH] 실적금액 변경
  const { mutate: mutateSalesPrice } = useMutation({
    mutationFn: patchSalesPrice,
    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
        return;
      } else {
        onModalDone("sales");
      }
      setLoading(false);
    },
    onError: (err) => {
      setLoading(false);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });

  // 창 닫기
  const closeModal = () => {
    storesDispatch(globalModalOff());
  };

  // 제출 데이터 세팅
  const onChangeSubmitData = (name, value) => {
    setSubmitData((prev) => ({
      ...prev,
      record_id: record_id,
      [name]: value,
    }));
  };

  // 제출 데이터 유효성 검사 후 query 실행
  const salesPriceValidation = () => {
    const changePrice = submitData["change_price"];
    let alertMessage;
    let isPositiveNumber = false;
    // 양수, 음수 체크
    if (changePrice > 0) {
      isPositiveNumber = true;
    }
    // 0, 값이 없는지 체크
    if (changePrice === undefined || changePrice === 0) {
      alertMessage = "변경할 금액을 입력해주세요";
    }

    if (alertMessage) {
      return simpleAlert(storesDispatch, "알림", alertMessage);
    }

    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label
              labelText={`실적금액을 ${changePrice}원 ${
                isPositiveNumber ? "추가" : "삭감"
              } 하시겠습니까?`}
            />
            <Label
              color={"red"}
              fontSize={"0.938rem"}
              marginTop={"0.5rem"}
              fontWeight={"bold"}
              labelText={`변경 후 실적 금액 ${(
                incentive.incentive + changePrice
              ).toLocaleString()} 원`}
            />
          </>
        ),
        onBtnOk: () => {
          setLoading(true);
          mutateSalesPrice(submitData);
        },
        onBtnNo: () => {},
      }),
    );
  };

  return (
    <>
      {loading && <Loading />}
      <SalesPriceChangeModalBody>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={textWidth}
          labelText="회원번호"
          defaultValue={member_info.member_no}
          disabled
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={textWidth}
          labelText="회원명"
          defaultValue={member_info.member_name}
          disabled
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={textWidth}
          labelText="실적직원"
          defaultValue={staff_info.staff_name}
          disabled
        />
        <NumberTextField
          labelText="실적금액"
          labelWidth={labelWidth}
          textBoxWidth={textWidth}
          defaultValue={incentive.incentive}
          endAdornment="원"
          isNumber
          disabled
        />
        <NumberTextField
          labelText="변경금액"
          labelWidth={labelWidth}
          textBoxWidth={textWidth}
          defaultValue={0}
          endAdornment="원"
          maxLength={10}
          minValue={-100}
          maxValue={100}
          minusValue={true}
          isNumber
          onChangeCallback={(e) => {
            onChangeSubmitData("change_price", e);
          }}
        />
      </SalesPriceChangeModalBody>
      <SalesPriceChangeModalFooter>
        <NormalBtn
          name={"확인"}
          onClick={() => {
            salesPriceValidation();
          }}
        />
        <NormalBtn
          name={"취소"}
          theme="white"
          onClick={() => {
            closeModal();
          }}
        />
      </SalesPriceChangeModalFooter>
    </>
  );
};

export default SalesPriceChangeModal;
