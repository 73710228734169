import CommonTabComp from "./CommonTabComp";

const MyApprovalDocsTab = ({
  onClickSearch,
  onClickFavorite,
  onClickNewDraft,
  totalPage,
  searchResult,
}) => {
  return (
    <div style={{ minHeight: "40rem" }}>
      <CommonTabComp
        onClickSearch={onClickSearch}
        tabType="my_approval"
        onClickFavorite={onClickFavorite}
        onClickNewDraft={onClickNewDraft}
        totalPage={totalPage}
        searchResult={searchResult}
      />
    </div>
  );
};
export default MyApprovalDocsTab;
