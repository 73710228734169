export const deviceAlloc = () => {
  return process.env.REACT_APP_ACCESS_SERVER + "/access/device_allocate";
};
export const setDeviceReset = () => {
  return process.env.REACT_APP_ACCESS_SERVER + "/access/remove_device";
};
export const setDeviceRefresh = () => {
  return process.env.REACT_APP_ACCESS_SERVER + "/sdk/refresh_device";
};
export const holydayAdd = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/access/add_holy_day";
};

export const holydayRemove = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/access/remove_holy_day";
};

export const presetAdd = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/access/schedule_preset_add";
};
export const presetUpdate = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/access/schedule_preset_update";
};
export const presetRemove = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/access/schedule_preset_remove";
};

export const presetAllocate = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/access/schedule_preset_allocate";
};

const post_api = {
  deviceAlloc,
  setDeviceReset,
  setDeviceRefresh,
  holydayAdd,
  holydayRemove,
  presetAdd,
  presetUpdate,
  presetRemove,
  presetAllocate,
};

export default post_api;
