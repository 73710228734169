import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getSalesSearchParam } from "apis/staff/sales_record_api";
import {
  changeComboValueToEmpty,
  checkDateOrder,
  generateObjectZeroId,
  getDefaultValue,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSalesRecordStore } from "../modules/store/salesRecordState";

const SearchCondition = ({ onClickSearch }) => {
  const storesDispatch = useDispatch();
  const labelWidth = "5.5rem";
  const labelWidthLong = "6.2rem";
  // 전역 상태 관리
  const { hqBranchBaseData, searchParams, setHqBranchInfo, setSearchParams, setHqIdx, hqIdx } =
    useSalesRecordStore().returnStoreByType("mbshp");

  // 페이지 상태 관리
  const [hqBranchListData, setHqBranchListData] = useState(hqBranchBaseData);
  const [branchList, setBranchList] = useState([]);
  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);

  // API Query
  // [GET] 실적 관리 검색 조건 받아오기 및 권한 데이터 세팅
  const { data: firstSearchParamData } = useQuery({
    queryKey: ["salesRecord", "firstSearchParam"],
    queryFn: () => getSalesSearchParam(),
    enabled: isFirstEnterPage,
    retry: false,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });

  // API 받아온 데이터 세팅
  useEffect(() => {
    if (firstSearchParamData) {
      const { combo_selector } = firstSearchParamData.data.data;
      // 첫 검색 조건
      if (searchParams.hq_info.hq_id === generateObjectZeroId()) {
        setSearchParams("hq_info", combo_selector.target_list[0].hq_info);
        setSearchParams("branch_info", combo_selector.target_list[0].branch_list[0]);
        setSearchParams("combo_selector", combo_selector);
      }
      // 본부 및 지점 정보
      setHqBranchListData(combo_selector);
      setBranchList(combo_selector.branch_list[hqIdx]);
      // ComboBox 첫번째 본부 및 지점 정보
      setHqBranchInfo("hq_info", combo_selector.target_list[0].hq_info);
      setHqBranchInfo("branch_info", combo_selector.target_list[0].branch_list[0]);
    }
  }, [firstSearchParamData]);

  // 본부 선택에 따른 지점 리스트 다시 세팅
  useEffect(() => {
    if (hqBranchListData.branch_list) setBranchList(hqBranchListData.branch_list[hqIdx]);
    //  첫 페이지 진입이 아니고 본부 변경 시 지점 초기화 세팅
    if (!isFirstEnterPage) {
      const branchParams = {
        branch_id: hqBranchListData.target_list[hqIdx].branch_list[0].branch_id,
        branch_name: hqBranchListData.target_list[hqIdx].branch_list[0].branch_name,
      };
      setSearchParams("branch_info", branchParams);
    }
  }, [hqIdx]);

  // 첫 페이지 여부
  useEffect(() => {
    setIsFirstEnterPage(false);
  }, []);

  return (
    <Box
      sx={{
        border: "1px solid #dedede",
        borderRadius: "5px",
        backgroundColor: "#f5f5f5",
        height: "8.5rem",
        marginTop: "0.5rem",
      }}
    >
      <div style={{ margin: "0.75rem 0.75rem" }}>
        <Label labelText="검색 조건" fontWeight="bold" justifyContent="flex-start" />
        <div
          style={{ display: "flex", marginTop: "0.5rem" }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClickSearch(1);
            }
          }}
        >
          <div style={{ width: "25%" }}>
            <ComboBox
              labelText="본부"
              labelWidth={labelWidth}
              labelMarginRight="0rem"
              comboItemWidth="15rem"
              data={hqBranchListData && hqBranchListData.hq_list}
              defaultValue={getDefaultValue("combo", searchParams.hq_info.hq_name)}
              onChangeCallback={(e, idx) => {
                setHqIdx(idx);
                const hqParams = hqBranchListData.target_list[idx].hq_info;
                setSearchParams("hq_info", hqParams);
              }}
            />
          </div>
          <div style={{ width: "25%" }}>
            <SearchComboBox
              labelText="지점"
              labelWidth={labelWidthLong}
              comboItemWidth="15rem"
              labelMarginRight="0rem"
              data={branchList}
              defaultValue={getDefaultValue("combo", searchParams.branch_info.branch_name)}
              onChangeCallback={(e, v, idx) => {
                const branchParams = hqBranchListData.target_list[hqIdx].branch_list[idx];
                setSearchParams("branch_info", branchParams);
              }}
            />
          </div>
          <div style={{ width: "25%", display: "flex" }}>
            <DatePicker
              // id="mbshp_date_from"
              labelText="등록 일시"
              labelWidth={labelWidth}
              textBoxWidth="8.5rem"
              labelMarginRight="0rem"
              textMarginRight="0rem"
              defaultValue={getDefaultValue("field", searchParams.reg_date_from)}
              onChangeCallback={(e) => {
                setSearchParams("reg_date_from", e);
              }}
              onBlur={(e) => {
                if (!checkDateOrder(e, searchParams.reg_date_to)) {
                  setSearchParams("reg_date_from", "");
                }
              }}
            />
            <DatePicker
              marginLeft="0.12rem"
              marginRight="4rem"
              labelWidth="0.5rem"
              textBoxWidth="8.5rem"
              textMarginRight="0rem"
              labelMarginRight="0.12rem"
              labelText="~"
              defaultValue={getDefaultValue("field", searchParams.reg_date_to)}
              onChangeCallback={(e) => {
                setSearchParams("reg_date_to", e);
              }}
              onBlur={(e) => {
                if (!checkDateOrder(searchParams.reg_date_from, e)) {
                  setSearchParams("reg_date_to", "");
                }
              }}
            />
          </div>
          <div style={{ width: "25%" }}></div>
        </div>
        <div style={{ display: "flex", marginTop: "0.5rem" }}>
          <div style={{ width: "25%" }}>
            <TextField
              labelText="실적 직원"
              labelWidth={labelWidth}
              labelMarginRight="0rem"
              textMarginRight="0.12rem"
              textBoxWidth="15rem"
              defaultValue={getDefaultValue("field", searchParams.sales_staff_name)}
              onChangeCallback={(e) => setSearchParams("sales_staff_name", e)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onClickSearch(1);
                }
              }}
            />
          </div>
          <div style={{ width: "25%" }}>
            <ComboBox
              labelText="급여 승인여부"
              labelWidth={labelWidthLong}
              comboItemWidth="15rem"
              labelMarginRight="0rem"
              data={["전체", "Y", "N"]}
              defaultValue={getDefaultValue("combo", searchParams.is_salaried_done)}
              onChangeCallback={(e) =>
                changeComboValueToEmpty(setSearchParams, "is_salaried_done", e)
              }
            />
          </div>
          <div style={{ width: "25%" }}></div>
          <div style={{ width: "25%" }}></div>
        </div>
      </div>
    </Box>
  );
};
export default SearchCondition;
