import React, { useState, useEffect, useImperativeHandle } from "react";

import PopupAlert from "./PopupAlert";
import { useDispatch } from "react-redux";
import { globalAlertOff } from "modules/actions/Alert";

// import { Button } from "@mui/material";

const AlertPopupGlobal = ({ params }) => {
  const initialState = {
    show: false,
    titleText: "",
    bodyText: "",
    width: "",
    callback: null,
    onBtnYes: undefined,
    onBtnNo: undefined,
    onBtnOk: undefined,
    onBtnCancel: undefined,
    onBtnClose: undefined,
  };
  const [paramState, setParamState] = useState(initialState);

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(globalAlertOff());
  };

  useEffect(() => {
    if (params !== undefined) {
      setParamState(params);
    }
  }, [params]);

  const onBtnOkCallback = () => {
    paramState.onBtnOk();
    handleClose();
  };
  const onBtnYesCallback = () => {
    paramState.onBtnYes();
    handleClose();
  };
  const onBtnNoCallback = () => {
    paramState.onBtnNo();
    handleClose();
  };
  const onBtnCancelCallback = () => {
    paramState.onBtnCancel();
    handleClose();
  };
  const onBtnCloseCallback = () => {
    paramState.onBtnClose();
    handleClose();
  };

  return (
    <>
      {/* {params.Button} */}
      <PopupAlert
        isOpen={paramState.show}
        onClose={() => {
          handleClose();
        }}
        title={paramState.titleText}
        Content={paramState.bodyText}
        width={paramState.width}
        onBtnOkCallback={paramState.onBtnOk === undefined ? undefined : onBtnOkCallback}
        onBtnYesCallback={paramState.onBtnYes === undefined ? undefined : onBtnYesCallback}
        onBtnNoCallback={paramState.onBtnNo === undefined ? undefined : onBtnNoCallback}
        onBtnCancelCallback={paramState.onBtnCancel === undefined ? undefined : onBtnCancelCallback}
        onBtnCloseCallback={paramState.onBtnClose === undefined ? undefined : onBtnCloseCallback}
        reqConfirm={params && params.reqConfirm}
        {...paramState}
      />
    </>
  );
};

export default AlertPopupGlobal;
