import { generateObjectZeroId } from "components/CommonLib/CommonLib";
import { create } from "zustand";

// 검색 조건 베이스
const baseSearchParams = {
  base_date: "",
  branch_info: {
    branch_id: generateObjectZeroId(),
    branch_name: "",
  },
  team_info: {
    team_id: generateObjectZeroId(),
    team_name: "",
  },
  team_info_list: [
    {
      team_id: generateObjectZeroId(),
      team_name: "",
    },
  ],
  team_name_list: [""],
  week_info_list: [
    {
      selected: true,
      week_ord: 1,
      week_ord_name: "1 주차",
    },
    {
      selected: true,
      week_ord: 2,
      week_ord_name: "2 주차",
    },
    {
      selected: true,
      week_ord: 3,
      week_ord_name: "3 주차",
    },
    {
      selected: true,
      week_ord: 4,
      week_ord_name: "4 주차",
    },
    {
      selected: true,
      week_ord: 5,
      week_ord_name: "5 주차",
    },
  ],
  week_name_list: ["1 주차", "2 주차", "3 주차", "4 주차", "5 주차"],
};

export const useTeamRecordStore = create((set) => ({
  // ========== State ===========
  // 팀 실적 검색 State
  teamRecordSearchParams: {
    ...baseSearchParams,
  },

  teamRecordBranchIdx: 0,

  // ========== Action ==========
  // 팀 실적 검색 조건 변경
  setSearchParams: (key, value) => {
    set((state) => ({
      teamRecordSearchParams: {
        ...state.teamRecordSearchParams,
        [key]: value,
      },
    }));
  },

  // 지점 인덱스 세팅
  setBranchIdx: (value) => {
    set({ teamRecordBranchIdx: value });
  },

  // 검색 조건 리셋
  resetSearchParams: () => {
    set({
      teamRecordSearchParams: {
        ...baseSearchParams,
      },
    });
  },
}));
