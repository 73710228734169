import { put, call } from "redux-saga/effects";
import get_api from "../../lib/CSManage/GetApi";
import { CSAction } from "../actions/CSManage/index";

export function* getCSTask(param) {
  try {
    const { data } = yield call(get_api.getCSTask, param);

    if (data.data === null) {
      yield put({
        type: "LOAD_TASK_INFO_SUCCESS",
        payload: null,
      });
    } else {
      yield put({
        type: "LOAD_TASK_INFO_SUCCESS",
        payload: data,
      });
    }
  } catch (error) {
    console.log("from getCSTask saga  -> ");
    console.log(error);
    yield put(CSAction.Actions.loadTaskInfoFail(error));
  }
}

export function* getCSList(param) {
  try {
    const { data } = yield call(get_api.getCSList, param);

    if (data.data === null) {
      yield put({
        type: "LOAD_CS_LIST_SUCCESS",
        payload: null,
      });
    } else {
      yield put({
        type: "LOAD_CS_LIST_SUCCESS",
        payload: data,
      });
    }
  } catch (error) {
    console.log("from getCSList saga  -> ");
    console.log(error);
    yield put(CSAction.Actions.loadCSListFail(error));
  }
}

export function* getCSInfo(param) {
  try {
    const { data } = yield call(get_api.getCSDetailInfo, param);
    if (data.data === null) {
      yield put({
        type: "LOAD_CS_INFO_SUCCESS",
        payload: null,
      });
    } else {
    yield put({
      type: "LOAD_CS_INFO_SUCCESS",
      payload: data,
    });
  }
  } catch (error) {
    console.log("from getCSInfo saga  -> ");
    console.log(error);
    yield put(CSAction.Actions.loadCSInfoFail(error));
  }
}

export function* getCSFilterInfo() {
  try {
    const { data } = yield call(get_api.getCSFilterSetting);

    if (data === undefined) {
      return;
    }
    if (data.data === null) {
      yield put({
        type: "LOAD_CS_FILTER_INFO_SUCCESS",
        payload: null,
      });
    } else {
      yield put({
        type: "LOAD_CS_FILTER_INFO_SUCCESS",
        payload: data,
      });
    }
  } catch (error) {
    console.log("error from getCSFilterInfo saga  -> ");
    console.log(error);
    yield put(CSAction.Actions.loadCSFilterInfoFail(error));
  }
}


export function* getCSDetailHealthInfo() {
  try {
    const { data } = yield call(get_api.getCSDetailHealthInfo);

    if (data === undefined) {
      return;
    }
    if (data.data === null) {
      yield put({
        type: "LOAD_CS_DETAIL_HEALTH_INFO_SUCCESS",
        payload: null,
      });
    } else {
      yield put({
        type: "LOAD_CS_DETAIL_HEALTH_INFO_SUCCESS",
        payload: data,
      });
    }
  } catch (error) {
    console.log("error from getCSDetailHealthInfo saga  -> ");
    console.log(error);
    yield put(CSAction.Actions.loadCSDetailHealthInfoFail(error));
  }
}
