import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import CardFixed from "components/Card/CardFixed";
import { tabBorder, tabStyle } from "components/CommonComp";
import Loading from "components/Loading/Loading/Loading";
import TabPanel from "components/TabControl/TabPanel";
import { useGetAuthData } from "hooks/apis/access/useAuth";
import { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import ClassRecordView from "./ClassRecord/ClassRecordView";
import MbshpRecordView from "./MbshpRecord/MbshpRecordView";
import PtRecordView from "./PtRecord/PtRecordView";
import { useSalesRecordStore } from "./modules/store/salesRecordState";

const SalesRecordWrapper = () => {
  // 페이지 상태 관리
  const [isAdmin, setIsAdmin] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  // 전역 상태 관리
  const { salesRecordLoading: loading } = useSalesRecordStore();

  // [GET] 권한 정보 가져오기
  const { data: authData } = useGetAuthData();

  const onChangeTabIndex = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  // 권한 정보 세팅
  useEffect(() => {
    if (authData) {
      setIsAdmin(authData.data.data.is_admin);
    }
  }, [authData]);

  return (
    <>
      {loading && <Loading />}
      <CardFixed
        midDom={
          <div>
            <Tabs
              value={tabIndex}
              onChange={onChangeTabIndex}
              textColor="inherit"
              sx={{ minHeight: "40px" }}
              TabIndicatorProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0)" } }}
            >
              <Tab sx={{ minHeight: "40px" }} label="회원권 실적" style={tabStyle(0, tabIndex)} />
              <Tab sx={{ minHeight: "40px" }} label="PT 실적" style={tabStyle(1, tabIndex)} />
              <Tab sx={{ minHeight: "40px" }} label="수업 실적" style={tabStyle(2, tabIndex)} />
            </Tabs>

            <SwipeableViews
              index={tabIndex}
              onChangeIndex={onChangeTabIndex}
              style={tabBorder}
              animateTransitions={false}
            >
              <TabPanel value={tabIndex} index={0}>
                <MbshpRecordView isAdmin={isAdmin} />
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <PtRecordView isAdmin={isAdmin} />
              </TabPanel>
              <TabPanel value={tabIndex} index={2}>
                <ClassRecordView />
              </TabPanel>
            </SwipeableViews>
          </div>
        }
        isLoading={false}
        rootStyle={{
          // width: "20rem",
          // height: "45rem",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />
    </>
  );
};
export default SalesRecordWrapper;
