import CardFixed from "components/Card/CardFixed";
import { generateObjectZeroId, SubLine } from "components/CommonLib/CommonLib";
import _ from "lodash";
import MembershipInfo from "pages/ERP/MemberRegister/Common/CardContents/MembershipInfo";
import PtInfo from "pages/ERP/MemberRegister/Common/CardContents/PtInfo";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import ItemSelector from "./CardContents/ItemSelector";
import ResultWrapper from "./CardContents/ResultWrapper";

const ChangeItemContents = forwardRef((props, ref) => {
  const {
    payInfoListDummy,
    payInfoListAction,
    subsPayInfoListAction,
    resetPaySubInfoListAction,
    baseInfoState,
    onClickOpenModal,
    serviceViewState,
    memberInputState,
    curSettingOption,
    setCurSettingOption,
    setIsSubsNow,
    payMethodList,
    setPayMethodList,
    regDate,
    setCanEnroll,
  } = props;

  const [mbshpPaidList, setMbshpPaidList] = useState([]);
  const [changeItemTotalPrice, setChangeItemTotalPrice] = useState(0);

  const [isPtChange, setIsPtChange] = useState(false);
  const [isPtUpgrade, setIsPtUpgrade] = useState(false);

  const [updateToDate, setUpdateToDate] = useState();
  const [basePurchaseCode, setBasePurchaseCode] = useState("");
  const [defaultFromDate, setDefaultFromDate] = useState("2000-01-01");
  const [noReg, setNoReg] = useState(false);
  const [curPtManager, setCurPtManager] = useState({
    staff_id: "",
    staff_name: "",
    staff_pos: "",
    staff_rank: "",
  });
  const [classDoneCnt, setClassDoneCnt] = useState(0);
  const [baseClassDoneCnt, setBaseClassDoneCnt] = useState(0);
  const [svcClassDoneCnt, setSvcClassDoneCnt] = useState(0);

  const resultRef = useRef();
  const ptInfoItemRef = useRef();
  const membershipInfoRef = useRef();

  useEffect(() => {
    onPtItemUpdate();
  }, [curSettingOption]);

  useImperativeHandle(ref, () => ({
    getBasePurchaseCode: () => {
      return basePurchaseCode;
    },
    getItemList: () => {
      return mbshpPaidList;
    },
    getRefundItemList: () => {
      return resultRef.current.getRefundItemList();
    },
    editItem: (act, pType, detailInfo, ord, mbshpIdx) => {
      resultRef.current.membershipItemEdit(act, pType, detailInfo, ord, mbshpIdx);
    },
    pushItem: (act, pType, detailInfo, ord, mbshpIdx) => {
      if (pType === "PT" && mbshpPaidList.findIndex((x) => x.pType === "PT") !== -1) {
        popItem(pType);
      }
      mbshpPaidList.push({ pType, detailInfo, ord });
      calcChangeTotal(mbshpPaidList);
    },
    popItem: (pType) => {
      popItem(pType);
    },
    disItem: (pType, detailInfo, ord) => {
      discountItem(pType, detailInfo);
    },
    getIsPtChange: () => {
      return isPtChange;
    },
    getIsPtUpgrade: () => {
      return isPtUpgrade;
    },
    getPtDownGradeCheck: () => {
      return ptDownGradeRefundCheck();
    },
  }));

  const popItem = (pType) => {
    const array = _.cloneDeep(mbshpPaidList);
    const index = array.map((item) => item.pType).indexOf(pType);

    if (index !== -1) {
      array.splice(index, 1);
      mbshpPaidList.splice(index, 1);
      // setMbshpPaidList(array);
      calcChangeTotal(array);
    }
  };

  const discountItem = (pType, detailInfo) => {
    const array = _.cloneDeep(mbshpPaidList);
    const index = array.findIndex((x) => x.pType === pType);
    if (index !== -1) {
      array[index].detailInfo.dis = detailInfo.dis;
      setMbshpPaidList(array);
      calcChangeTotal(array);
    }
  };

  const calcChangeTotal = (cItemList) => {
    let total = 0;
    let changeIsPt = false;
    // console.log("-- start cItemList --");

    for (let i = 0; i < cItemList.length; i++) {
      total += Number(cItemList[i].detailInfo.price) - Number(cItemList[i].detailInfo.dis);

      if (cItemList[i].pType === "PT") {
        changeIsPt = true;
      }
    }
    if (changeIsPt) {
      ptUpgradeCheck();
    }

    // console.log("-- end cItemList --");
    // console.log("total :", total);
    // console.log("total :", cItemList);
    setIsPtChange(changeIsPt);
    setChangeItemTotalPrice(total);
  };

  // pt 업그레이드인지 다운그레이드인지 확인
  const ptUpgradeCheck = () => {
    const refundInfoList = serviceViewState.refund_item_info.refund_info_list;

    for (let i = 0; i < refundInfoList.length; i++) {
      if (refundInfoList[i].selected) {
        let oldPtInfo = refundInfoList[i].purchase_list[0].pt_info.pt_info;
        let newPtInfo = ptInfoItemRef.current.getState2();
        //base_session_cnt
        // console.log("-- ptUpgradeCheck --", oldPtInfo.base_session_cnt);
        // console.log("-- ptUpgradeCheck --", newPtInfo.default_session);
        if (oldPtInfo.base_session_cnt > newPtInfo.default_session) {
          setIsPtUpgrade(false);
        } else {
          setIsPtUpgrade(true);
        }
        // console.log(refundInfoList[i].purchase_list[0].pt_info.pt_info.base_session_cnt);

        // console.log(ptInfoItemRef.current.getState2().default_session);
      }
    }
  };

  // pt 다운그레이드일때 취소결제수단 모두 선택 확인
  const ptDownGradeRefundCheck = () => {
    // const refundInfoList = serviceViewState.refund_item_info.refund_info_list;
    const refundList = serviceViewState.refund_item_info.refund_list;
    let checker = false;

    if (!isPtUpgrade) {
      for (let i = 0; i < refundList.length; i++) {
        if (refundList[i].selected) {
          for (let j = 0; j < refundList[i].payment_list.length; j++) {
            if (!refundList[i].payment_list[j].payment_list_selector.selected) {
              checker = true;
              break;
            }
          }
        }
        if (checker) {
          break;
        }
      }
    }

    return checker;
  };

  // 기존 상품 선택 시
  const initAndReset = () => {
    let totalPaid = 0;
    let totalRefund = 0;
    let mbshpItems = [];
    let otherItems = [];
    let basePCode = "";
    let curRefundTargetList = [];

    // 결제 리스트 초기화 1
    setPayMethodList([]);
    serviceViewState.paymentA.payment_info = [];

    const refundInfoList = serviceViewState.refund_item_info.refund_info_list;
    const refundList = serviceViewState.refund_item_info.refund_list;

    // setCurSettingOption({ ...curSettingOption, is_only_pt: false, is_pt_merged: false });
    curSettingOption.is_only_pt = false;
    curSettingOption.is_pt_merged = false;

    for (let i = 0; i < refundList.length; i++) {
      refundList[i].selected = false;
      refundList[i].total_refund_price = 0;
      refundList[i].total_paid_price = 0;
      refundList[i].repaid_price = 0;
      refundList[i].done_refund_price = 0;

      for (let j = 0; j < refundList[i].payment_list.length; j++) {
        refundList[i].payment_list[j].payment_list_selector.selected = false;
      }

      for (let j = 0; j < refundList[i].refund_detail.length; j++) {
        refundList[i].refund_detail[j].is_view = false;
        refundList[i].refund_detail[j].selected = false;
      }
    }

    for (let i = 0; i < refundInfoList.length; i++) {
      //
      if (refundInfoList[i].selected) {
        let baseIndex = refundInfoList[i].purchase_list.findIndex((x) =>
          x.purchase_info.description.includes("회원권"),
        );

        if (baseIndex === -1) {
          const descr = refundInfoList[i].purchase_list[0].purchase_info.description;
          if (descr.includes("PT")) {
            // PT를 선택한 경우
            baseIndex = 0;

            curSettingOption.is_only_pt = true;
            curSettingOption.is_pt_merged = false;

            //////////// begin 아래 회원권 부분과 코드 같음. 기능 검증 후 로직 간소화
            const purchaseList = refundInfoList[i].purchase_list;
            basePCode = refundInfoList[i].purchase_list[baseIndex].purchase_info.purchase_code;
            setBasePurchaseCode(basePCode);

            for (let j = 0; j < purchaseList.length; j++) {
              if (purchaseList[j].purchase_info.purchase_code === basePCode) {
                //구매코드 같을 경우 .. 총액에 포함
                totalPaid += purchaseList[j].purchase_info.paid_price;
                mbshpItems.push(purchaseList[j].purchase_info.paid_price);
              } else {
                //구매코드 다를경우. 이 경우는 없어야 함
                throw new Error("이상한 other 발견");
              }

              // refund???
              const rIndex = refundList.findIndex(
                (x) => x.purchase_code === purchaseList[j].purchase_info.purchase_code,
              );
              if (rIndex !== -1) {
                // console.log("*** 선택하지 않은 refundList가 true되면 안됨 1");
                refundList[rIndex].selected = true;
                refundList[rIndex].total_paid_price += purchaseList[j].purchase_info.paid_price;
              }

              // 현재 수업담당자
              setCurPtManager(purchaseList[j].pt_info.cur_manager.staff_info);
              let _classDoneCnt = 0;
              let _baseClassDoneCnt = 0;
              let _svcClassDoneCnt = 0;
              for (let k = 0; k < purchaseList[j].pt_info.pt_class.length; k++) {
                let curClass = purchaseList[j].pt_info.pt_class[k];

                if (curClass.proceed_class.class_done_date !== "") {
                  if (curClass.session_type === "서비스 세션") {
                    _svcClassDoneCnt++;
                  } else if (curClass.session_type === "기본 세션") {
                    _baseClassDoneCnt++;
                  }
                  // console.log(curClass);
                  // console.log(purchaseList[j].pt_info.pt_class[k]);
                  _classDoneCnt++;
                }
              }
              setClassDoneCnt(_classDoneCnt);
              setBaseClassDoneCnt(_baseClassDoneCnt);
              setSvcClassDoneCnt(_svcClassDoneCnt);
            }
            ////////////// end
          } else {
            curSettingOption.is_only_pt = false;
            curSettingOption.is_pt_merged = false;
          }
          setCurSettingOption({ ...curSettingOption });
        } else {
          // 회원권
          const purchaseList = refundInfoList[i].purchase_list;
          basePCode = refundInfoList[i].purchase_list[baseIndex].purchase_info.purchase_code;
          setBasePurchaseCode(basePCode);

          for (let j = 0; j < purchaseList.length; j++) {
            if (purchaseList[j].purchase_info.purchase_code === basePCode) {
              //회원권과 구매코드 같을 경우 .. 총액에 포함
              totalPaid += purchaseList[j].purchase_info.paid_price;
              mbshpItems.push(purchaseList[j].purchase_info.paid_price);
            } else {
              //회원권과 구매코드 다를경우
              // throw new Error("이상한 other 발견");

              mbshpItems.push(purchaseList[j].purchase_info.paid_price);
              otherItems.push(purchaseList[j].purchase_info.paid_price);

              // const cIdx = refundList.findIndex(
              //   (x) => x.purchase_code === purchaseList[j].purchase_info.purchase_code,
              // );
              // refundList[cIdx].selected = true;
              // refundList[cIdx].total_paid_price += purchaseList[j].purchase_info.paid_price;
              // refundList[cIdx].total_refund_price += purchaseList[j].purchase_info.paid_price;
            }

            // refund???
            const rIndex = refundList.findIndex(
              (x) => x.purchase_code === purchaseList[j].purchase_info.purchase_code,
            );
            if (rIndex !== -1) {
              // console.log("*** 선택하지 않은 refundList가 true되면 안됨 2");
              refundList[rIndex].selected = true;
              refundList[rIndex].total_paid_price += purchaseList[j].purchase_info.paid_price;
              refundList[rIndex].total_refund_price += purchaseList[j].purchase_info.paid_price;
              // console.log(curTarget[j].purchase_info.paid_price);
            }
          }

          // 연결된 부가상품 (purchase_code가 다른..)
          // for (let j = 0; j < refundInfoList[i].child_idx_list.length; j++) {
          //   const childIdx = refundInfoList[i].child_idx_list[j];

          //   // 금액 별도 추가
          //   let accPrice = 0;
          //   for (let k = 0; k < refundInfoList[childIdx].purchase_list.length; k++) {
          //     mbshpItems.push(refundInfoList[childIdx].purchase_list[k].purchase_info.paid_price);
          //     otherItems.push(refundInfoList[childIdx].purchase_list[k].purchase_info.paid_price);
          //     accPrice += refundInfoList[childIdx].purchase_list[k].purchase_info.paid_price;
          //   }

          //   // 강제 선택
          //   refundInfoList[childIdx].selected = true;
          //   // refundInfoList[childIdx].is_lock = true;

          //   // 환불신청내역에도 추가하기 위함
          //   const childPurchaseCode = refundInfoList[childIdx].purchase_code;
          //   const cIdx = refundList.findIndex((x) => x.purchase_code === childPurchaseCode);
          //   refundList[cIdx].selected = true;
          //   refundList[cIdx].total_paid_price = accPrice;
          //   refundList[cIdx].total_refund_price = accPrice;
          // }
        }
      }
    }

    // refund????
    for (let i = 0; i < serviceViewState.refund_item_info.refund_list.length; i++) {
      if (serviceViewState.refund_item_info.refund_list[i].selected) {
        // console.log(serviceViewState.refund_item_info.refund_list[i]);
        curRefundTargetList.push(serviceViewState.refund_item_info.refund_list[i]);
      }
    }

    const totalSummaryParams = {
      mbshpItems: mbshpItems,
      otherItems: otherItems,
      beforeTotal: totalPaid,
      beforeRefund: totalRefund,
      afterRefundTotal: totalRefund,
      diffTotal: 0,
      changeTotal: 0,
    };

    resultRef.current.resetItem(curRefundTargetList, totalSummaryParams);
  };

  const [, updateState] = useState(); // 강제 렌더링용
  const forceUpdate = useCallback(() => updateState({}), []);

  const onSelectBaseItem = (idx, isChecked) => {
    // 체크박스 선택 해제 확인 로직
    const refundInfoList = serviceViewState.refund_item_info.refund_info_list;
    // for (let i = 0; i < refundInfoList[idx].child_idx_list.length; i++) {
    //   refundInfoList[refundInfoList[idx].child_idx_list[i]].selected = isChecked;
    // }

    if (refundInfoList.findIndex((x) => x.selected === true) === -1) {
      // 다른 곳에서 setCurSettingOption 하기 때문에 값만 변경
      curSettingOption.isSelectedItem = false;
      curSettingOption.is_pt_merged = false;
    } else {
      curSettingOption.isSelectedItem = true;
      curSettingOption.is_pt_merged = false;
    }

    // 회원권 시작일 가져오기
    // for (let i = 0; i < refundInfoList[idx].purchase_list.length; i++) {
    // }
    // 만약 index err 발생하면 purchase_list를 루프돌려서 membership_id 있는 놈을 찾아야 함
    const mbshpId = refundInfoList[idx].purchase_list[0].purchase_info.membership_id;
    if (mbshpId !== generateObjectZeroId()) {
      const mIdx = serviceViewState.membership_data_list.findIndex(
        (x) => x.membership_list.membership_id === mbshpId,
      );

      if (mIdx !== -1) {
        setDefaultFromDate(
          serviceViewState.membership_data_list[mIdx].membership_list.mbshp.from_date,
        );
      }
    }

    // 이하 컴포넌트 초기화
    onChangeMbshpSelect();

    // 선택된 놈 제외하고 모든 체크박스 비활성화
    for (let i = 0; i < refundInfoList.length; i++) {
      if (isChecked === false) {
        refundInfoList[i].is_lock = isChecked;
      } else {
        if (idx !== i) {
          refundInfoList[i].is_lock = isChecked;
        }
      }
    }

    if (!isChecked) {
      // 체크 해제 시 base p code 초기화
      setBasePurchaseCode("");
    } else {
      // 선택한 회원권에 등록비가 있는지 검사.
      if (
        refundInfoList[idx].purchase_list.findIndex((x) =>
          x.purchase_info.item_type.includes("등록비"),
        ) === -1
      ) {
        setNoReg(true);
      } else {
        setNoReg(false);
      }
    }

    setCanEnroll(false);
    forceUpdate();
  };

  const onPtItemUpdate = () => {
    if (ptInfoItemRef.current) {
      ptInfoItemRef.current.init();
    }
  };

  // 변경상품의 회원권 선택 시 (초기화)
  const onChangeMbshpSelect = () => {
    initAndReset();
    mbshpPaidList.splice(0, mbshpPaidList.length);
    setChangeItemTotalPrice(0);

    if (memberInputState.membership_all_list[0].purchase_item_list.item_info.is_combine_pt) {
      setCurSettingOption({ ...curSettingOption, is_pt_merged: true });
    } else {
      setCurSettingOption({ ...curSettingOption, is_pt_merged: false });
    }
  };

  // console.log(curMembershipInfo);
  // console.log(curSettingOption);

  useEffect(() => {
    if (membershipInfoRef.current) {
      membershipInfoRef.current.onChangeBranch();
      resetPaySubInfoListAction();
      setIsSubsNow(false);
    }
    onPtItemUpdate();
  }, [baseInfoState, curSettingOption.isSelectedItem]);

  useEffect(() => {
    memberInputState.pt_info.pt_staff = curPtManager;
  }, [curPtManager]);

  return (
    <div>
      {/* 기존 상품 선택 */}
      <ItemSelector serviceViewState={serviceViewState} onSelectBaseItem={onSelectBaseItem} />

      {curSettingOption.isSelectedItem ? (
        <CardFixed
          topDom={<div>변경 상품 선택</div>}
          midDom={
            <div style={{ marginTop: "1.5rem" }}>
              {curSettingOption.is_only_pt ? (
                <div></div>
              ) : (
                <div>
                  <MembershipInfo
                    ref={membershipInfoRef}
                    defaultFromDate={defaultFromDate}
                    payInfoListAction={payInfoListDummy}
                    subsPayInfoListAction={subsPayInfoListAction}
                    baseInfoState={baseInfoState}
                    onClickOpenModal={onClickOpenModal}
                    orderStartIdx={0}
                    membershipIdx={0}
                    memberInputState={memberInputState.membership_all_list[0]}
                    memberInputStateAll={memberInputState}
                    onPtItemUpdate={onPtItemUpdate}
                    isAddPay={false}
                    last_to_date={memberInputState.last_to_date}
                    subs_lock={false}
                    resetPaySubInfoListAction={resetPaySubInfoListAction}
                    setIsSubsNow={setIsSubsNow}
                    from_lock={true}
                    onChangeMbshpSelect={onChangeMbshpSelect}
                    noReg={noReg}
                    changeItem={true}
                  />
                  <SubLine />
                </div>
              )}

              {curSettingOption.is_only_pt || curSettingOption.is_pt_merged ? (
                <PtInfo
                  ref={ptInfoItemRef}
                  baseInfoState={baseInfoState}
                  payInfoListAction={payInfoListDummy}
                  memberPtState={memberInputState.pt_info}
                  memberInputState={memberInputState.membership_all_list[0]}
                  memberInputStateAll={memberInputState}
                  curSettingOption={curSettingOption}
                  curPtManager={curPtManager}
                  // classDoneCnt={classDoneCnt}
                  baseClassDoneCnt={baseClassDoneCnt}
                  svcClassDoneCnt={svcClassDoneCnt}
                  isOnlyPt={curSettingOption.is_only_pt}
                  isPtMerged={curSettingOption.is_pt_merged}
                />
              ) : (
                <div></div>
              )}
            </div>
          }
          isLoading={false}
          rootStyle={{
            width: "76.88rem",
            marginTop: "0.625rem",
            borderRadius: "10px",
            backgroundColor: "#fff",
          }}
        />
      ) : (
        <></>
      )}

      {/* 상품 변경 금액 & 환불 신청 내역 */}
      {/* {curSettingOption.isSelectedItem ? ( */}
      <ResultWrapper
        ref={resultRef}
        basePurchaseCode={basePurchaseCode}
        payInfoListAction={payInfoListAction}
        changeItemTotalPrice={changeItemTotalPrice}
        serviceViewState={serviceViewState}
        payMethodList={payMethodList}
        setPayMethodList={setPayMethodList}
        // isPtChange={isPtChange}
        // isPtUpgrade={isPtUpgrade}
        regDate={regDate}
      />
      {/*
      ) : (
        <></>
      )}
      */}
    </div>
  );
});
export default ChangeItemContents;
