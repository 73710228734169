import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import { HttpInstance } from "lib/HttpLib";
import { globalModalOff } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const CommissionListCard = (props) => {
  const { onClickOpenModal, staffInfoState } = props;
  const storesDispatch = useDispatch();

  let [commSetList, setCommSetList] = useState([]);

  useEffect(() => {
    getCommSetList();
  }, []);

  const getCommSetList = () => {
    HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/staff/work_info_setting/list", {
      params: { staff_id: props.staffInfoState.contents.staff_obj.staff_id },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setCommSetList(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const viewRefresh = () => {
    getCommSetList();
  };

  const onRefresh = (workInfoState) => {
    storesDispatch(globalModalOff());
    viewRefresh();
  };

  const onClickChange = (staffId, branchId) => {
    const param = {
      title: "기본 커미션 설정",
      modalParam: {
        staff_id: staffId,
        branch_id: branchId,
      },
    };

    onClickOpenModal("기본커미션설정", param, onRefresh);
  };

  const itemList = new itemInfoTable(onClickChange);

  return (
    <div style={{}}>
      <Label
        labelText="지점별 커미션 리스트"
        fontWeight="bold"
        justifyContent="left"
        marginBottom="1rem"
      />
      <CustomTable
        columns_head={itemList.columns_head}
        table_title={itemList.table_title}
        rest_call={itemList.get_data_from_rest}
        row_render={itemList.create_table}
        rest_data={commSetList}
      />
    </div>
  );
};
export default CommissionListCard;

class itemInfoTable {
  table_title = "";
  btnStyleClass = btnStyles();
  actionTypeList = [];
  onClickChange = null;

  constructor(onClickChange) {
    this.onClickChange = onClickChange;
  }

  getDefaultPreset = (presetList) => {
    if (!presetList) {
      return "";
    }
    if (presetList.length === 0) {
      return "";
    }

    const idx = presetList.findIndex((x) => x.is_default === true);
    if (idx !== -1) {
      return presetList[idx].preset_name;
    } else {
      return "";
    }
  };

  create_table = (data, key) => {
    return (
      <CustomRow key={key} style={{ width: "100%" }}>
        <CustomTableContents width="20%">{data.base_info.hq_info.hq_name}</CustomTableContents>
        <CustomTableContents width="20%">
          {data.base_info.branch_info.branch_name}
        </CustomTableContents>
        <CustomTableContents width="30%">
          {this.getDefaultPreset(data.pt_pre_set)}
        </CustomTableContents>
        <CustomTableContents width="10%">{data.work_role.is_cs ? "Y" : "N"}</CustomTableContents>
        <CustomTableContents width="10%">{data.work_role.is_pt ? "Y" : "N"}</CustomTableContents>
        <CustomTableContents width="10%">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              className={clsx({
                [this.btnStyleClass.btnRootRaw]: true,
                [this.btnStyleClass.buttonType2]: true,
              })}
              onClick={() =>
                this.onClickChange(data.staff_id, data.base_info.branch_info.branch_id)
              }
            >
              변경
            </Button>
          </div>
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "본부", field: "select" },
    { title: "지점", field: "_status" },
    { title: "기본 커미션명", field: "edit_status" },
    { title: "CS 업무", field: "status" },
    { title: "PT 업무", field: "card_no" },
    { title: "", field: "edit" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
