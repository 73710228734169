import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import ComboBox from "components/LabelInput/ComboBox";
import TextField from "components/LabelInput/TextField";

import { storeEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
const RegisterFriendModal = ({ modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();

  let findMemberList = new findMemberInfoTable();

  const rest_data = [
    {
      branch: "용인점",
      member_id: "1234567890",
      member_name: "김랩스",
      phone: "010-1234-5678",
      member_card_num: "123456",
    },
  ];

  return (
    <div
      style={{
        width: "37.5rem",
      }}
    >
      <div
        style={{
          display: "flex",
        }}
      >
        <div>
          <ComboBox
            labelWidth={"3.25rem"}
            comboItemWidth={"8.75rem"}
            labelText="지점명"
            data={["test", "test2"]}
          />
          <TextField
            labelWidth={"3.25rem"}
            textBoxWidth={"8.75rem"}
            labelText="회원번호"
            textValue=""
          />
          <TextField
            labelWidth={"3.25rem"}
            textBoxWidth={"8.75rem"}
            labelText="연락처"
            textValue=""
          />
        </div>

        <div>
          <div style={{ width: "10rem", height: "3rem" }}></div>
          <TextField
            labelWidth={"3.25rem"}
            textBoxWidth={"8.75rem"}
            labelText="회원번호"
            textValue=""
          />
          <TextField
            labelWidth={"3.25rem"}
            textBoxWidth={"8.75rem"}
            labelText="연락처"
            textValue=""
          />
        </div>

        <div>
          <div style={{ width: "3.375rem", height: "6.5rem" }}></div>
          <Button
            className={clsx({
              [btnStyleClass.btnRootRaw]: true,
              [btnStyleClass.buttonType2]: true,
            })}
          >
            검색
          </Button>
        </div>
      </div>

      <CustomTable
        columns_head={findMemberList.columns_head}
        table_title={findMemberList.table_title}
        rest_call={findMemberList.get_data_from_rest}
        row_render={findMemberList.create_table}
        rest_data={rest_data}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          className={clsx({
            [btnStyleClass.btnRootRaw]: true,
            [btnStyleClass.buttonType2]: true,
          })}
        >
          확인
        </Button>
      </div>
    </div>
  );
};
export default RegisterFriendModal;

class findMemberInfoTable {
  table_title = "";
  btnStyleClass = btnStyles();

  create_table = (data) => {
    return (
      <CustomRow style={{ width: "100%" }}>
        <CustomTableContents width="25%">{data.branch}</CustomTableContents>
        <CustomTableContents width="25%">{data.member_id}</CustomTableContents>
        <CustomTableContents width="25%">{data.member_name}</CustomTableContents>
        <CustomTableContents width="25%">{data.phone}</CustomTableContents>
        <CustomTableContentsEND align="center" width="25%">
          {data.member_card_num}
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "지점명", field: "branch" },
    { title: "회원번호", field: "member_id" },
    { title: "회원명", field: "member_name" },
    { title: "연락처", field: "phone" },
    { title: "카드번호", field: "member_card_num" },
  ];
  get_data_from_rest = (stateData) => {
    console.log(stateData);
    if (storeEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
