import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, dateToStr, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import NumberTextField from "components/LabelInput/NumberTextField";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import { getIsViewLock } from "pages/ApprovalManage/ApprovalCommon/checker";
import SearchEmployee from "pages/CommonModal/Search/SearchEmployee";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const DayOffReqForm = ({ baseState, mode, contentState, setContentState }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();

  const readOnly = mode === "approver" || mode === "unspent" || mode === "view";

  const [hqBranchList, setHqBranchList] = useState();
  const [loading, setLoading] = useState(false);

  const [, updateState] = useState(); // 강제 렌더링용
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    setLoading(true);
    HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/staff/search_modal_view", {
      params: { hq_only_add: true },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          console.log(res.data.data);
          setHqBranchList(res.data.data);
        }
      })
      .catch((err) => {
        console.log("err");
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setContentState({
      ...contentState,
      // staff_list: [],
    });
  }, [baseState.writer.hq_info.hq_name, baseState.writer.branch_info.branch_name]);

  const onModalDone = async (staffInfo) => {
    setLoading(true);
    let usedDays = 0;
    let lastHoliday = "";
    await HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/approval/holiday/history", {
      params: { staff_id: staffInfo.staff_id },
    })
      .then((res) => {
        if (!restEmpty(res.data)) {
          usedDays = res.data.used_days;
          lastHoliday = res.data.last_holiday;
        }
      })
      .catch((err) => {
        console.log("err");
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      })
      .finally(() => {
        setLoading(false);
      });

    const fIdx = contentState.staff_list.findIndex(
      (x) => x.staff_info.staff_id === staffInfo.staff_id,
    );
    if (fIdx !== -1) {
      simpleAlert(storesDispatch, "알림", "이미 추가된 직원입니다.");
    } else {
      const newInfo = {
        branch_info: staffInfo.base_info.branch_info,
        year_use: usedDays,
        before_day_off: lastHoliday,
        join_date: staffInfo.base_info.join_date,
        off_type: "",
        req_day_off: 0,
        req_day_off_from_date: "",
        req_day_off_to_date: "",
        staff_info: {
          staff_id: staffInfo.staff_id,
          staff_name: staffInfo.personal_info.staff_name,
          staff_pos: staffInfo.base_info.position.org_name,
          staff_rank: staffInfo.base_info.rank.org_name,
        },
        work_month: "",
      };
      contentState.staff_list.push(newInfo);
    }
    storesDispatch(globalModalOff());
  };

  const onClickAddRow = () => {
    if (
      !baseState.writer.branch_info.branch_name ||
      !baseState.writer.hq_info.hq_name ||
      baseState.writer.branch_info.branch_name === "전체"
    ) {
      simpleAlert(storesDispatch, "알림", "지점을 선택해주세요.");
    } else {
      const param = {
        branch_list: [[baseState.writer.branch_info.branch_name]],
        hq_list: [baseState.writer.hq_info.hq_name],
        target_list: [
          { hq_info: baseState.writer.hq_info, branch_list: [baseState.writer.branch_info] },
        ],
      };

      storesDispatch(
        globalModalOn({
          show: true,
          title: "직원 검색",
          Content: <SearchEmployee modalParam={param} onModalDone={onModalDone} />,
        }),
      );

      forceUpdate();
    }
  };

  const onClickDelete = (id) => {
    setContentState({
      ...contentState,
      staff_list: contentState.staff_list.filter((x) => x.staff_info.staff_id !== id),
    });
    forceUpdate();
  };

  const contentList = new contentTable(readOnly, onClickDelete, forceUpdate);

  return (
    <div>
      {loading && <Loading />}

      <div style={{ margin: "0.5rem 0.1rem" }}>
        <CustomTable
          columns_head={contentList.columns_head}
          table_title={contentList.table_title}
          rest_call={contentList.get_data_from_rest}
          row_render={contentList.create_table}
          rest_data={contentState && contentState.staff_list}
          height="35rem"
        />
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.25rem" }}>
        <Button
          className={clsx({
            [btnStyleClass.btnRootWhite]: true,
            [btnStyleClass.buttonType7]: true,
          })}
          onClick={() => {
            onClickAddRow();
          }}
          disabled={getIsViewLock(mode)}
        >
          행 추가
        </Button>
      </div>
    </div>
  );
};
export default DayOffReqForm;

//

class contentTable {
  table_title = "";
  onClickDelete = null;
  forceUpdate = null;
  readOnly = null;

  constructor(readOnly, onClickDelete, forceUpdate) {
    this.readOnly = readOnly;
    this.onClickDelete = onClickDelete;
    this.forceUpdate = forceUpdate;
  }

  getWorkPeriod = (val) => {
    const joinDate = new Date(val);
    const today = new Date();

    let months;
    let days;
    months = (today.getFullYear() - joinDate.getFullYear()) * 12;
    months -= joinDate.getMonth() + 1;
    months += today.getMonth() + 1;

    // Calculate the difference in days
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in one day
    const diffDays = Math.round(Math.abs((joinDate - today) / oneDay));
    days = diffDays % 30;

    return months + "개월 " + days + "일";
  };

  create_table = (data, idx) => {
    return (
      <CustomRow style={{}} key={idx}>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.staff_info.staff_name}</CustomTableContents>
        <CustomTableContents>{data.staff_info.staff_pos}</CustomTableContents>
        <CustomTableContents>{this.getWorkPeriod(data.join_date)}</CustomTableContents>
        <CustomTableContents>{data.year_use}</CustomTableContents>
        <CustomTableContents>{data.before_day_off}</CustomTableContents>
        <CustomTableContents>
          <div style={{ display: "flex" }}>
            <NumberTextField
              required
              labelMarginRight="0rem"
              textMarginRight="0rem"
              fullWidth
              endAdornment="일"
              defaultValue={data.req_day_off}
              onChangeCallback={(e) => {
                data.req_day_off = Number(e);

                if (data.req_day_off > 0 && data.req_day_off_from_date) {
                  const addedDate = new Date(data.req_day_off_from_date);
                  addedDate.setDate(addedDate.getDate() + Number(data.req_day_off - 1));
                  data.req_day_off_to_date = dateToStr(addedDate);
                  this.forceUpdate();
                }
              }}
              disabled={this.readOnly}
            />
          </div>
        </CustomTableContents>
        <CustomTableContents>
          <DatePicker
            required
            fullWidth
            labelMarginRight="0rem"
            textMarginRight="0rem"
            defaultValue={data.req_day_off_from_date}
            onChangeCallback={(e) => {
              data.req_day_off_from_date = e;

              if (data.req_day_off > 0) {
                const addedDate = new Date(data.req_day_off_from_date);
                addedDate.setDate(addedDate.getDate() + Number(data.req_day_off - 1));
                data.req_day_off_to_date = dateToStr(addedDate);
                this.forceUpdate();
              }
            }}
            disabled={this.readOnly}
          />
        </CustomTableContents>
        <CustomTableContents>
          <TextField
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            defaultValue={data.req_day_off_to_date}
            disabled
          />
        </CustomTableContents>
        <CustomTableContents>
          <ComboBox
            required
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            data={["유급", "무급", "반차(유급)", "반차(무급)"]}
            defaultValue={data.off_type}
            onChangeCallback={(e) => {
              data.off_type = e.target.value;
            }}
            disabled={this.readOnly}
          />
        </CustomTableContents>
        <CustomTableContentsEND>
          {!this.readOnly ? (
            <IconButton
              type="delete"
              onClick={() => this.onClickDelete(data.staff_info.staff_id)}
            />
          ) : (
            <></>
          )}
        </CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "지점", width: "10%" },
    { title: "직원명", width: "8%" },
    { title: "직책", width: "7%" },
    { title: "근속개월수", width: "10%" },
    { title: "당해 사용", width: "8%" },
    { title: "이전 휴무기간", width: "12%" },
    { title: "휴무기간", width: "8%" },
    { title: "휴무 시작일", width: "10%" },
    { title: "휴무 종료일", width: "10%" },
    { title: "휴무 구분", width: "8%" },
    { title: "", width: "5%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
