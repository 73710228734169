import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";

const ButtonActionGroup = ({ workInfoStore, modalParam, onClickEvent }) => {
  const btnStyleClass = btnStyles();

  if (modalParam.pageMode === "추가") {
    // 임시
    return (
      <div style={{ display: "flex", justifyContent: "right" }}>
        <ActionButton
          buttonText="임시저장"
          actionType="임시저장"
          onClickEvent={onClickEvent}
          permission={workInfoStore.permission.btn_approval} //btn_edit
          btnType={btnStyleClass.buttonType6}
        />
      </div>
    );
  } else if (modalParam.pageMode === "수정") {
    return (
      <div style={{ display: "flex", justifyContent: "right" }}>
        <ActionButton
          buttonText="임시저장"
          actionType="임시저장"
          onClickEvent={onClickEvent}
          permission={workInfoStore.permission.btn_edit}
          btnType={btnStyleClass.buttonType6}
        />
        <ActionButton
          buttonText="변경취소"
          actionType="변경취소"
          onClickEvent={onClickEvent}
          permission={workInfoStore.permission.btn_edit_cancel}
          btnType={btnStyleClass.buttonType6}
        />
      </div>
    );
  } else {
    // 보기 버튼들 임시 추가
    // workInfoStore.permission.btn_approval = true;
    // workInfoStore.permission.btn_draft = true;
    // workInfoStore.permission.btn_re_entry = true;
    // workInfoStore.permission.btn_recall = true;
    // workInfoStore.permission.btn_retirement = true;
    // workInfoStore.permission.btn_trans = true;
    return (
      <div style={{ display: "flex", justifyContent: "right" }}>
        <ActionButton
          buttonText="승인"
          actionType="기안"
          onClickEvent={onClickEvent}
          permission={workInfoStore.permission.btn_approval}
          btnType={btnStyleClass.buttonType4}
        />
        <ActionButton
          buttonText="기안"
          actionType="기안"
          onClickEvent={onClickEvent}
          permission={workInfoStore.permission.btn_draft}
          btnType={btnStyleClass.buttonType4}
        />
        <ActionButton
          buttonText="재입사"
          actionType="재입사"
          onClickEvent={onClickEvent}
          permission={workInfoStore.permission.btn_re_entry}
          btnType={btnStyleClass.buttonType4}
        />
        <ActionButton
          buttonText="회수"
          actionType="회수"
          onClickEvent={onClickEvent}
          permission={workInfoStore.permission.btn_recall}
          btnType={btnStyleClass.buttonType4}
        />
        <ActionButton
          buttonText="퇴직"
          actionType="퇴직"
          onClickEvent={onClickEvent}
          permission={workInfoStore.permission.btn_retirement}
          btnType={btnStyleClass.buttonType4}
        />
        <ActionButton
          buttonText="전환"
          actionType="전환"
          onClickEvent={onClickEvent}
          permission={workInfoStore.permission.btn_trans} // change???
          btnType={btnStyleClass.buttonType4}
        />
      </div>
    );
  }

  // if (modalParam.pageMode === "추가") {
  //   return (
  //     <NewAddButtonGroup
  //       workInfo={workInfoStore.contents.work_info_obj}
  //       modalParam={modalParam}
  //       onButtonEvents={onButtonEvents}
  //     />
  //   );
  // } else {
  //   if (modalParam.editMode) {
  //     // 수정 팝업
  //     return (
  //       <EditButtonGroup
  //         workInfo={workInfoStore.contents.work_info_obj}
  //         modalParam={modalParam}
  //         onButtonEvents={onButtonEvents}
  //       />
  //     );
  //   } else {
  //     return (
  //       <ShowButtonGroup // 보기 팝업
  //         workInfo={workInfoStore.contents.work_info_obj}
  //         modalParam={modalParam}
  //         onButtonEvents={onButtonEvents}
  //       />
  //     );
  //   }
  // }
};

const ActionButton = ({ buttonText, actionType, onClickEvent, permission, btnType }) => {
  const btnStyleClass = btnStyles();

  if (permission) {
    return (
      <div
        style={{
          marginLeft: "0.25rem",
          marginRight: "0.25rem",
        }}
      >
        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnType]: true,
          })}
          onClick={() => {
            onClickEvent(actionType);
          }}
        >
          {buttonText}
        </Button>
      </div>
    );
  } else {
    return <></>;
  }
};

export default ButtonActionGroup;
