import GXIcon from "assets/icon/GX.svg";
import LockerLargeIcon from "assets/icon/LockerLarge.svg";
import OTIcon from "assets/icon/OT.svg";
import TrainingClothesIcon from "assets/icon/TrainingClothes.svg";
import IconButton from "components/Button/IconButton";
import CardFixed from "components/Card/CardFixed";
import { dateToStr, simpleAlert, SubLine } from "components/CommonLib/CommonLib";
import ColorLabel from "components/LabelInput/ColorLabel";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import LabelBtn from "components/NewButton/LabelBtn";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import FamilyInfo from "../CardContents/FamilyInfo/FamilyInfo";
import { loadMemberViewStore } from "../Module/actions";
import { getSubscriptionPurchaseAPi, subscriptionInfo } from "./apiCaller";
import MembershipStatus from "./MembershipStatus/MembershipStatus";

const MembershipInfo = ({
  onClickOpenModal,
  itemList,
  memberId,
  membershipDataList,
  baseInfoState,
  hasSubsPayment,
}) => {
  const storesDispatch = useDispatch();
  //선택된 아이템
  const [selectedItem, setSelectedItem] = useState({
    branch_id: baseInfoState.branch_info.branch_id,
    member_info: {
      from_date: baseInfoState.active_info.active_membership.from_date,
      to_date: baseInfoState.active_info.active_membership.to_date,
      gender: baseInfoState.base_info.personal_info.gender,
      member_id: baseInfoState.member_id,
      member_name: baseInfoState.base_info.personal_info.member_name,
      member_no: baseInfoState.base_info.personal_info.member_no,
      membership_id: baseInfoState.active_info.active_membership.membership_id,
      phone: baseInfoState.base_info.personal_info.phone,
    },
    family_info: "",
    membership_id: "",
    item_name: "",
    from_date: "",
    to_date: "",
    is_use_cloth: false,
    is_use_gx: false,
    ot_remain_session_cnt: 0,
    ot_manager: "",
    locker_list: [],
    can_family: false,
    connectedFamilyName: "",
    mbshp_type: "",
    mbshp_option: "",
    mbshp_status: "",
    full_remain_day: "",
    subs_price: 0,
    subs_contract_from: "2022-01-01",
    subs_contract_to: "2022-12-31",
    postpone_total_cnt: 0,
    postpone_used_cnt: 0,
    pass_total_cnt: 0,
    pass_remain_cnt: 0,
    pass_from_time: "07:00",
    pass_end_time: "09:00",
    postpone_status: "",
    mbshp_postpone: {
      ing: false,
      from_date: "",
      to_date: "",
      final_to_date: "", //연기 최종 회원권 종료일
    },
  });

  const [subsPurchaseInfo, setSubsPurchaseInfo] = useState(subscriptionInfo);

  useEffect(() => {
    getSubscriptionPurchaseAPi(
      storesDispatch,
      selectedItem.member_info.member_id,
      selectedItem.membership_id,
      setSubsPurchaseInfo,
    );
  }, [selectedItem.membership_id]);

  //첫랜더링 이후 => 회원번호가 생기면 기본값 랜더링
  useEffect(() => {
    if (baseInfoState.membership_data_list.length !== 0) {
      setSelectedItem((cur) => {
        const obj = _.cloneDeep(cur);
        const postponeIdx = membershipDataList.findIndex(
          (el) => el.membership_list.mbshp_status === "연기",
        );
        //연기 최종 회원권 종료일 계산
        const startPostpone = new Date(baseInfoState.active_info.last_mbshp_to_date);

        startPostpone.setDate(
          // startPostpone.getDate() + baseInfoState.active_info.active_postpone.postpone_day + 1,
          startPostpone.getDate() + baseInfoState.active_info.active_postpone.postpone_day,
        );

        obj.member_info.from_date =
          baseInfoState.membership_data_list[0].membership_list.mbshp.from_date;
        obj.member_info.to_date =
          baseInfoState.membership_data_list[0].membership_list.mbshp.to_date;
        obj.member_info.gender = baseInfoState.base_info.personal_info.gender;
        obj.member_info.member_name = baseInfoState.base_info.personal_info.member_name;
        obj.member_info.member_no = baseInfoState.base_info.personal_info.member_no;
        obj.member_info.phone = baseInfoState.base_info.personal_info.phone;
        obj.member_info.member_id = baseInfoState.member_id;
        obj.member_info.membership_id =
          baseInfoState.membership_data_list[0].membership_list.membership_id;

        obj.family_info = baseInfoState.membership_data_list[0].membership_list.family_info;
        obj.membership_id = baseInfoState.membership_data_list[0].membership_list.membership_id;
        obj.item_name = "[1] " + baseInfoState.membership_data_list[0].membership_list.item_name;
        obj.branch_id = baseInfoState.branch_info.branch_id;
        obj.from_date = baseInfoState.membership_data_list[0].membership_list.mbshp.from_date;
        obj.to_date = baseInfoState.membership_data_list[0].membership_list.mbshp.to_date;
        obj.is_use_cloth = baseInfoState.membership_data_list[0].membership_list.cloth.is_use;
        obj.is_use_gx = baseInfoState.membership_data_list[0].membership_list.gx.is_use;
        obj.ot_remain_session_cnt =
          baseInfoState.membership_data_list[0].ot_info.ot_info.remain_session_cnt;
        obj.ot_manager =
          baseInfoState.membership_data_list[0].ot_info.cur_manager.staff_info.staff_name;
        obj.locker_list = baseInfoState.membership_data_list[0].membership_list.locker_list;
        obj.can_family =
          baseInfoState.membership_data_list[0].purchase_item_list.item_info.can_family;
        obj.connectedFamilyName =
          baseInfoState.membership_data_list[0].membership_list.family_info.member_info.member_name;
        obj.mbshp_type = baseInfoState.membership_data_list[0].membership_list.mbshp_type;
        obj.mbshp_option = baseInfoState.membership_data_list[0].membership_list.mbshp_option;
        obj.mbshp_status = baseInfoState.membership_data_list[0].membership_list.mbshp_status;
        obj.subs_price = subscriptionInfo.pay_per_month;
        // baseInfoState.membership_data_list[0].membership_list.subs_options.subs_total_price;
        obj.subs_contract_from =
          baseInfoState.membership_data_list[0].membership_list.mbshp.subs_options.contract_from_date;
        obj.subs_contract_to =
          baseInfoState.membership_data_list[0].membership_list.mbshp.subs_options.contract_to_date;
        obj.postpone_total_cnt =
          baseInfoState.membership_data_list[0].membership_list.usage.total_postpone_cnt;
        obj.postpone_used_cnt =
          baseInfoState.membership_data_list[0].membership_list.usage.used_postpone_cnt;
        obj.pass_total_cnt = baseInfoState.membership_data_list[0].membership_list.pass.total_cnt;
        obj.pass_remain_cnt = baseInfoState.active_info.active_membership.remain_pass_cnt;
        obj.pass_from_time = baseInfoState.active_info.active_membership.pass_time.from_time;
        obj.pass_end_time = baseInfoState.active_info.active_membership.pass_time.end_time;
        obj.postpone_status = baseInfoState.active_info.active_mbshp_status;

        obj.mbshp_postpone = {
          ing: baseInfoState.membership_data_list.some(
            (el) => el.membership_list.mbshp_status === "연기",
          ),
          from_date: baseInfoState.active_info.active_postpone.from_date,
          to_date: baseInfoState.active_info.active_postpone.to_date,
          final_to_date: dateToStr(startPostpone),
        };
        return obj;
      });
    }
  }, [baseInfoState]);

  useEffect(() => {
    if (!hasSubsPayment) {
      simpleAlert(storesDispatch, "알림", "정기결제수단을 등록해 주시기 바랍니다.");
    }
  }, [hasSubsPayment]);

  const mbshpTotalDays = baseInfoState.active_info.remain_day;

  const CardTitle = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Label labelText="회원권" fontWeight="bold" fontSize="1rem" />
        <div style={{ marginLeft: "0.5rem", fontSize: "0.7rem", color: "#777777" }}>
          ※ 잔여기간: {mbshpTotalDays}일
        </div>
        {baseInfoState.is_no_entry ? (
          <div style={{ marginLeft: "auto" }}>
            <Label
              labelText="※ 출입이 정지된 회원입니다."
              fontWeight="bold"
              fontSize="1rem"
              color="red"
            />
          </div>
        ) : !hasSubsPayment ? (
          <div style={{ marginLeft: "auto" }}>
            <Label
              labelText="※ 등록된 정기결제수단이 없습니다. 미납 시 회원권이 정지됩니다."
              fontWeight="bold"
              fontSize="1rem"
              color="red"
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  return (
    <CardFixed
      topDom={<CardTitle />}
      midDom={
        <MembershipInfoCardItem
          memberId={memberId}
          baseInfoState={baseInfoState}
          onClickOpenModal={onClickOpenModal}
          itemList={itemList}
          membershipDataList={membershipDataList}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          storesDispatch={storesDispatch}
          subsPurchaseInfo={subsPurchaseInfo}
        />
      }
      isLoading={false}
      rootStyle={{
        width: "60%",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};
export default MembershipInfo;

//

const MembershipOthers = ({
  baseInfoState,
  selectedItem,
  memberId,
  onClickOpenModal,
  storesDispatch,
}) => {
  const navigate = useNavigate();

  const blockStyle = useMemo(() => {
    return {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
      boxSizing: "borderBox",
      width: "11rem",
      height: "12rem",
      margin: "0.3rem 0.7rem",
      padding: "0.5rem",
      borderRadius: "10px",
      border: "1px solid #DEDEDE",
      backgroundColor: "#fff",
    };
  }, []);

  //1-1.락커상세모달 POP
  const popLockerDetailModal = (data) => {
    const param = {
      title: "락커 상세",
      modalParam: {
        isFromMemPage: true,
        memberId,
        branch_id: selectedItem.branch_id,
        floor_id: "",
        zone_id: data.locker_info.zone_id,
        floor_name: "",
        zone_name: data.locker_info.zone_name,
        locker_total_idx: data.locker_info.locker_total_idx,
        locker_no: data.locker_info.locker_no,
        navigate,
      },
    };
    onClickOpenModal("락커상세", param, lockerCloseModal);
  };

  //1. 락커를 배정할수 있는지 여부
  const checkCanAssignLocker = (locker) => {
    // console.log("락커", locker);
    const today = dateToStr(new Date());

    const todayTime = new Date(today);
    const mbshpFrom = new Date(locker.locker_info.from_date);

    const diffMSec = mbshpFrom.getTime() - todayTime.getTime();
    const diffDate = diffMSec / (24 * 60 * 60 * 1000);

    if (diffDate > 0) {
      // 배정여부 체크해보자
      if (diffDate <= 30) {
        // console.log("30이내", diffDate);
      } else {
        // console.log("초과", diffDate); //락커배정 불가
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: <Label labelText="회원권 시작 30일 이내부터 락커배정이 가능합니다." />,
            onBtnOk: () => {},
          }),
        );
      }
    } else {
      //검사할 필요없음 - 통과
      // console.log("통과", diffDate);
    }

    // console.log("베인포", baseInfoState);
    // console.log("셀", selectedItem);
    // console.log("락커", locker);
  };

  //1-2. 락커셀렉션 or 락커배정모달 POP
  const popLockerModal = (data) => {
    let ihaveLocker = false;

    //맨앞꺼인가 뒤에꺼인가 (membershipId가 0번째 membershipId면 젤앞에꺼)
    const firstMbshpLockerList = baseInfoState.membership_data_list[0].membership_list.locker_list;
    for (let i = 0; i < baseInfoState.membership_data_list.length; i++) {
      let curMbshp = baseInfoState.membership_data_list[i].membership_list;
      for (let j = 0; j < curMbshp.locker_list.length; j++) {
        if (curMbshp.locker_list[j].locker_info.zone_id !== "000000000000000000000000") {
          ihaveLocker = true;

          break;
        }
      }
      if (ihaveLocker) {
        break;
      }
    }

    // if (
    //   selectedItem.member_info.membership_id ===
    //   baseInfoState.membership_data_list[0].membership_list.membership_id
    // )
    if (!ihaveLocker) {
      const param = {
        title: "락커 배정",
        modalParam: {
          mode: "add",
          member_info: selectedItem.member_info,
          branch_id: selectedItem.branch_id,
          locker_total_idx: data.locker_info.locker_total_idx,
          mbshp_locker_id: data.locker_info.mbshp_locker_id,
        },
      };
      onClickOpenModal("락커배정", param, lockerAssignModalDone);
    } else {
      const param = {
        title: "락커 배정 옵션",
        modalParam: {
          mode: "add",
          member_info: selectedItem.member_info,
          branch_id: selectedItem.branch_id,
          locker_total_idx: data.locker_info.locker_total_idx,
          mbshp_locker_id: data.locker_info.mbshp_locker_id,
          firstMbshpLockerList,
        },
      };
      onClickOpenModal("락커옵션선택", param, lockerAssignModalDone);
    }
  };

  //1-1.락커모달 DONE
  const lockerAssignModalDone = () => {
    storesDispatch(loadMemberViewStore(memberId)); //회원메인 갱신
    storesDispatch(globalModalOff()); //모달 닫기
  };
  //1-2.락커모달 DONE
  const lockerCloseModal = () => {
    storesDispatch(globalModalOff());
  };

  return (
    <div
      style={{
        marginTop: "1.5rem",
        height: "30rem",
        display: "grid",
        gridTemplateColumns: "repeat(2,1fr)",
      }}
    >
      <div style={blockStyle}>
        <Label
          labelText="락커"
          fontSize="0.9rem"
          fontWeight="bold"
          color="#222222"
          marginTop="0.2rem"
          zIndex="2"
        />
        <img
          alt="locker"
          src={LockerLargeIcon}
          style={{
            width: "7rem",
            height: "7rem",
          }}
        />

        <div style={{ display: "flex" }}>
          {selectedItem.locker_list.length === 0 ? (
            <ColorLabel
              padding="0 0.01rem"
              width="4rem"
              labelText={"미사용"}
              fontSize="0.8rem"
              fontWeight="bold"
              fontColor={"#FF6B17"}
              bgColor={"#FFE1D1"}
            />
          ) : (
            ""
          )}
          {selectedItem.locker_list !== 0
            ? selectedItem.locker_list.map((el, idx) => {
                return (
                  <div key={idx} style={{ marginRight: "0.3rem" }}>
                    {el.locker_info.zone_id !== "000000000000000000000000" ? (
                      <LabelBtn
                        name={`${el.locker_info.locker_no}번`}
                        onClick={() => {
                          popLockerDetailModal(el);
                        }}
                      />
                    ) : (
                      <LabelBtn
                        name="미배정"
                        theme="orange"
                        onClick={() => {
                          // checkCanAssignLocker(el);
                          popLockerModal(el);
                        }}
                      />
                    )}
                  </div>
                );
              })
            : ""}
        </div>
      </div>

      <div style={blockStyle}>
        <Label
          labelText="운동복"
          marginTop="0.2rem"
          zIndex="2"
          fontSize="0.9rem"
          fontWeight="bold"
          color="#222222"
        />
        <img
          alt="cloth"
          src={TrainingClothesIcon}
          style={{
            width: "7rem",
            height: "7rem",
          }}
        />

        <ColorLabel
          padding="0 0.01rem"
          width="4rem"
          labelText={selectedItem.is_use_cloth ? "사용" : "미사용"}
          fontSize="0.8rem"
          fontWeight="bold"
          fontColor={selectedItem.is_use_cloth ? "#578DDE" : "#FF6B17"}
          bgColor={selectedItem.is_use_cloth ? "#E3EBF7" : "#FFE1D1"}
        />
      </div>

      <div style={blockStyle}>
        <Label
          labelText="GX"
          marginTop="0.2rem"
          zIndex="2"
          fontSize="0.9rem"
          fontWeight="bold"
          color="#222222"
        />
        <img
          alt="gx"
          src={GXIcon}
          style={{
            width: "7rem",
            height: "7rem",
          }}
        />

        <ColorLabel
          width="4rem"
          padding="0 0.01rem"
          labelText={selectedItem.is_use_gx ? "사용" : "미사용"}
          fontSize="0.8rem"
          fontWeight="bold"
          fontColor={selectedItem.is_use_gx ? "#578DDE" : "#FF6B17"}
          bgColor={selectedItem.is_use_gx ? "#E3EBF7" : "#FFE1D1"}
        />
      </div>

      <div style={blockStyle}>
        <Label
          labelText="OT"
          marginTop="0.2rem"
          zIndex="2"
          fontSize="0.9rem"
          fontWeight="bold"
          color="#222222"
        />
        <img
          alt="ot"
          src={OTIcon}
          style={{
            width: "7rem",
            height: "7rem",
          }}
        />

        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "0.1rem" }}>
            <Label
              labelText={selectedItem.ot_manager ? selectedItem.ot_manager : "담당자 없음"}
              fontSize="0.9rem"
              fontWeight="500"
              color="#222222"
              // transform="translateY(-0.5rem)"
            />
            <ColorLabel
              width="4rem"
              padding="0 0.01rem"
              labelText={`잔여 ${selectedItem.ot_remain_session_cnt}회`}
              fontSize="0.8rem"
              fontWeight="bold"
              fontColor={"#578DDE"}
              bgColor={"#E3EBF7"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const MembershipInfoCardItem = ({
  membershipItemInfo,
  onClickOpenModal,
  itemList,
  memberId,
  membershipDataList,
  baseInfoState,
  selectedItem,
  setSelectedItem,
  storesDispatch,
  subsPurchaseInfo,
}) => {
  // console.log(selectedItem.mbshp_postpone)
  const popPostponeModal = () => {
    const param = {
      title: "회원권 연기",
      modalParam: {
        memberId: memberId,
        branchId: selectedItem.branch_id,
        membershipId: selectedItem.membership_id,
        mbshp_type: selectedItem.mbshp_type,
        mbshp_status: selectedItem.mbshp_status,
      },
    };
    onClickOpenModal("연기", param);
  };

  const popChangeFromDateModal = () => {
    const param = {
      title: "시작일 변경",
      modalParam: {
        from_date: selectedItem.from_date,
        to_date: selectedItem.to_date,
        member_id: memberId,
        branch_id: baseInfoState.branch_info.branch_id,
        membership_id: selectedItem.membership_id,
        membershipDataList: membershipDataList,
      },
    };
    onClickOpenModal("시작일변경", param);
  };

  const popConnectFamilyModal = () => {
    const param = {
      title: "가족연결",
      modalParam: {
        memberId,
        membershipId: selectedItem.membership_id,
        itemName: selectedItem.item_name.split(" ")[1],
      },
    };
    onClickOpenModal("가족연결", param);
  };

  const onChangeItem = (idx) => {
    setSelectedItem((cur) => {
      const obj = _.cloneDeep(cur);
      //연기 최종 회원권 종료일 계산
      const startPostpone = new Date(baseInfoState.active_info.last_mbshp_to_date);
      // console.log(startPostpone)
      // console.log(baseInfoState.active_info.active_postpone.postpone_day)
      startPostpone.setDate(
        // startPostpone.getDate() + baseInfoState.active_info.active_postpone.postpone_day + 1,
        startPostpone.getDate() + baseInfoState.active_info.active_postpone.postpone_day,
      );

      obj.member_info.from_date = membershipDataList[idx].membership_list.mbshp.from_date;
      obj.member_info.to_date = membershipDataList[idx].membership_list.mbshp.to_date;
      obj.member_info.gender = baseInfoState.base_info.personal_info.gender;
      obj.member_info.member_id = baseInfoState.member_id;
      obj.member_info.member_name = baseInfoState.base_info.personal_info.member_name;
      obj.member_info.member_no = baseInfoState.base_info.personal_info.member_no;
      obj.member_info.phone = baseInfoState.base_info.personal_info.phone;
      obj.member_info.membership_id = membershipDataList[idx].membership_list.membership_id;
      obj.family_info = membershipDataList[idx].membership_list.family_info;
      obj.membership_id = membershipDataList[idx].membership_list.membership_id;
      obj.item_name = `[${idx + 1}] ` + membershipDataList[idx].membership_list.item_name;
      obj.branch_id = baseInfoState.branch_info.branch_id;
      obj.from_date = membershipDataList[idx].membership_list.mbshp.from_date;
      obj.to_date = membershipDataList[idx].membership_list.mbshp.to_date;
      obj.is_use_cloth = membershipDataList[idx].membership_list.cloth.is_use;
      obj.is_use_gx = membershipDataList[idx].membership_list.gx.is_use;
      obj.ot_remain_session_cnt = membershipDataList[idx].ot_info.ot_info.remain_session_cnt;
      obj.ot_manager = membershipDataList[idx].ot_info.cur_manager.staff_info.staff_name;
      obj.locker_list = membershipDataList[idx].membership_list.locker_list;
      obj.can_family = membershipDataList[idx].purchase_item_list.item_info.can_family;
      obj.connectedFamilyName = membershipDataList[idx].membership_list.family_info.member_name;
      obj.mbshp_type = membershipDataList[idx].membership_list.mbshp_type;
      obj.mbshp_option = membershipDataList[idx].membership_list.mbshp_option;
      obj.mbshp_status = membershipDataList[idx].membership_list.mbshp_status;
      obj.subs_price = subscriptionInfo.pay_per_month;
      // membershipDataList[idx].membership_list.subs_options.subs_total_price;
      obj.subs_contract_from =
        membershipDataList[idx].membership_list.mbshp.subs_options.contract_from_date;
      obj.subs_contract_to =
        membershipDataList[idx].membership_list.mbshp.subs_options.contract_to_date;
      obj.postpone_total_cnt = membershipDataList[idx].membership_list.usage.total_postpone_cnt;
      obj.postpone_used_cnt = membershipDataList[idx].membership_list.usage.used_postpone_cnt;
      obj.pass_total_cnt = membershipDataList[idx].membership_list.pass.total_cnt;
      obj.pass_from_time = baseInfoState.active_info.active_membership.pass_time.from_time;
      obj.pass_end_time = baseInfoState.active_info.active_membership.pass_time.end_time;
      obj.postpone_status = baseInfoState.active_info.active_mbshp_status;
      if (selectedItem.mbshp_postpone.ing) {
        //연기 종료일 갖고 있자.
        const postponeIdx = membershipDataList.findIndex(
          (el) => el.membership_list.mbshp_status === "연기",
        );
        obj.mbshp_postpone.from_date = baseInfoState.active_info.active_postpone.from_date;
        obj.mbshp_postpone.to_date = baseInfoState.active_info.active_postpone.to_date;
        obj.mbshp_postpone.final_to_date = dateToStr(startPostpone);
      }
      return obj;
    });
  };

  const checkDisabledStatus = () => {
    if (selectedItem.mbshp_option === "일일권") {
      return true; //비활성
    }
    if (selectedItem.mbshp_status === "수강" && selectedItem.from_date === dateToStr(new Date())) {
      return false; //활성
    } else if (selectedItem.mbshp_status === "대기") {
      return false; //활성
    } else {
      return true; //비활성
    }
  };

  return (
    <div>
      {membershipDataList.length !== 0 ? (
        <div
          style={{
            display: "flex",
            marginBottom: "0.625rem",
            justifyContent: "space-between",

            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              width: "55%",
              boxSizing: "border-box",
            }}
          >
            <div>
              <div style={{ display: "flex" }}>
                <IconButton type="border" />
                <div style={{ width: "0.5rem" }}></div>
                <SubTitle titleText="이용중인 상품" />
              </div>
              <ComboBox
                labelMarginLeft="1rem"
                labelWidth={"3.5rem"}
                comboItemWidth={"27rem"}
                labelText="상품명"
                defaultValue={selectedItem.item_name === "[1] " ? "" : selectedItem.item_name}
                data={itemList}
                onChangeCallback={(e) => {
                  const idx = e.target.value.slice(1, 2) - 1;
                  if (idx !== -1) {
                    onChangeItem(idx);
                  }
                }}
              />
            </div>

            <SubLine />
            {selectedItem.mbshp_postpone.ing ? (
              <div
                style={{
                  // backgroundColor: "#eee",
                  color: "#fff",
                  height: "23rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Label labelText="현재 연기중입니다." fontSize="1.2rem" />
                <Label
                  marginTop="1rem"
                  labelText={`연기기간: ${selectedItem.mbshp_postpone.from_date} ~ ${selectedItem.mbshp_postpone.to_date}`}
                />
                <Label
                  marginTop="0.5rem"
                  marginBottom="0.5rem"
                  labelText={`연기 후 최종 회원권 종료일: ${selectedItem.mbshp_postpone.final_to_date}`}
                />

                <NormalBtn
                  name="연기"
                  theme="gray"
                  // disabled={}
                  onClick={() => popPostponeModal()}
                />
              </div>
            ) : (
              <div>
                <MembershipStatus
                  selectedItem={selectedItem}
                  popPostponeModal={popPostponeModal}
                  popChangeFromDateModal={popChangeFromDateModal}
                  subState={subsPurchaseInfo}
                  isServiceLock={checkDisabledStatus()}
                />
                <SubLine />
                <FamilyInfo
                  memberId={memberId}
                  selectedItem={selectedItem}
                  popConnectFamilyModal={popConnectFamilyModal}
                />
              </div>
            )}
          </div>
          <div style={{ width: "45%", boxSizing: "border-box" }}>
            <MembershipOthers
              baseInfoState={baseInfoState}
              onClickOpenModal={onClickOpenModal}
              membershipItemInfo={membershipItemInfo}
              selectedItem={selectedItem}
              memberId={memberId}
              storesDispatch={storesDispatch}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "3rem",
          }}
        >
          <Label labelText="이용중인 회원권이 없습니다." fontSize="1.1rem" marginTop="10rem" />
        </div>
      )}
    </div>
  );
};
