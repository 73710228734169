import NoticeView from "./NoticeView";

const NoticeWrapper = ({ nowService }) => {
  //본부콤보 GET
  const getHqCombo = async () => {};

  return (
    <div>
      <NoticeView nowService={nowService} />
    </div>
  );
};

export default NoticeWrapper;
