import { useQuery } from "@tanstack/react-query";
import { getOtHistList } from "apis/staff/class_hist_api";
import {
  checkDateOrder,
  dateToStr,
  generateObjectZeroId,
  restEmpty,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useClassHistStore } from "../../modules/store/classHistState";
import { exportToExcel } from "../PtList/MakeExcel";
import OtHistSearchCard from "./OtHistSearchCard";
import OtHistSearchResult from "./OtHistSearchResult";

const OtHistView = ({ branchInfo }) => {
  const storesDispatch = useDispatch();
  // 전역 상태 관리
  const { setLoading, searchParams, setSearchParams, setPage, page, resetSearchParams } =
    useClassHistStore().returnStoreByType("ot");
  // 페이지 상태 관리
  const [searchResult, setSearchResult] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);
  const [isMakeExcel, setIsMakeExcel] = useState(false);

  // API 상태 관리
  const [isGetOtHistList, setIsGetOtHistList] = useState(false);
  const queryData = { page: page, searchParams: searchParams, isExcel: isMakeExcel };

  // API Query
  // [GET] OT 수업 이력 검색
  const { data: otHistData } = useQuery({
    queryKey: ["classHist", "ot", queryData],
    queryFn: () => getOtHistList(queryData),
    enabled: isGetOtHistList,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setIsGetOtHistList(false);
      setLoading(false);
    },
  });

  //검색 POST
  const onClickSearch = async (page, isExcel) => {
    if (isExcel) {
      setIsGetOtHistList(true);
      setIsMakeExcel(true);
      return;
    }
    // 전화번호 중간 자리 미입력 시 alert 띄움
    if (searchParams.member_base.phone === "err") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="전화번호 검색 조건이 잘못되었습니다." />,
          onBtnOk: () => {},
        }),
      );
      return;
    }
    // 날짜 시작일과 종료일 체크
    if (checkDateOrder(searchParams.class_from_date, searchParams.class_to_date)) {
      setPage(page);
      setLoading(true);
      setIsGetOtHistList(true);
    } else {
      setSearchParams("class_from_date", "");
      setSearchParams("class_to_date", "");
    }
  };

  // 검색 조건에 수업일 정보가 없으면 어제와 오늘까지로 세팅
  const calcOneday = () => {
    const today = new Date();
    const oneDayMS = 24 * 60 * 60 * 1000;
    const yesterday = new Date(today.getTime() - oneDayMS);
    if (searchParams.class_from_date === "") {
      setSearchParams("class_from_date", dateToStr(yesterday));
    }
    if (searchParams.class_to_date === "") {
      setSearchParams("class_to_date", dateToStr(today));
    }
  };
  // API 받아온 데이터 세팅
  useEffect(() => {
    // 검색 결과 데이터 세팅
    if (otHistData && !restEmpty(otHistData.data.data)) {
      if (isMakeExcel) {
        exportToExcel(
          otHistData.data.data,
          `${searchParams.branch_info.branch_name}_${searchParams.class_from_date}_${searchParams.class_to_date}\nOT 수업 이력`,
          "OT",
        );
        setIsMakeExcel(false);
      } else {
        setSearchResult(otHistData.data.data);
        setTotalPage(otHistData.data.total_page);
      }
    }
  }, [otHistData]);

  // 페이지 진입 시 리스트 받아오기
  useEffect(() => {
    if (isFirstEnterPage && searchParams.branch_info.brnach_id !== generateObjectZeroId()) {
      onClickSearch(page);
      calcOneday();
      setIsFirstEnterPage(false);
    }
  }, [searchParams.branch_info.brnach_id]);

  //페이지네이션
  useEffect(() => {
    if (page) {
      onClickSearch(page);
    }
  }, [page]);

  return (
    <div>
      <SearchCard
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        resetSearchParams={resetSearchParams}
        onClickSearch={onClickSearch}
        branchInfo={branchInfo}
      />
      <SearchResultCard
        curPage={page}
        setCurPage={setPage}
        totalPage={totalPage}
        searchResult={searchResult}
      />
    </div>
  );
};
export default OtHistView;

const SearchCard = ({
  searchParams,
  setSearchParams,
  resetSearchParams,
  onClickSearch,
  branchInfo,
}) => {
  return (
    <OtHistSearchCard
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      resetSearchParams={resetSearchParams}
      onClickSearch={onClickSearch}
      branchInfo={branchInfo}
    />
  );
};

const SearchResultCard = ({ curPage, setCurPage, totalPage, searchResult }) => {
  return (
    <OtHistSearchResult
      curPage={curPage}
      setCurPage={setCurPage}
      totalPage={totalPage}
      searchResult={searchResult}
    />
  );
};
