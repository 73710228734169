import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import NormalBtn from "components/NewButton/NormalBtn";
import { useRef, useState } from "react";
import AggregatePaymentMethodModal from "../Modals/AggregatePaymentMethodModal";

const PaymentListTable = ({ reportState }) => {
  const resultTableInst = new resultTable();
  const viewModelRef = useRef();
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });

  const getTotalCanceledPrice = () => {
    return reportState.payment_history.reduce((sum, cur) => {
      if (cur.payment_info) {
        return (
          sum +
          cur.payment_info.reduce((sum2, cur2) => {
            if (cur2.price < 0) {
              return sum2 + cur2.price;
            } else {
              return 0;
            }
          }, 0)
        );
      } else {
        return sum;
      }
    }, 0);
  };

  const getTotalPaidPrice = () => {
    return reportState.payment_history.reduce((sum, cur) => {
      if (cur.payment_info) {
        return (
          sum +
          cur.payment_info.reduce((sum2, cur2) => {
            if (cur2.price > 0) {
              return sum2 + cur2.price;
            } else {
              return 0;
            }
          }, 0)
        );
      } else {
        return sum;
      }
    }, 0);
  };

  const onModalDone = () => {
    viewModelRef.current.setClose();
  };

  // 결제기기별 결제수단 집계
  const calcAggregatePayMethod = (isPayment, paymentHist) => {
    const result = [
      {
        // ERP (단말기)
        type: "단말기(ERP)",
        card: 0,
        transfer: 0,
        cash: 0,
        etc: 0,
      },
      {
        // Tablet (리더기)
        type: "리더기",
        card: 0,
        transfer: 0,
        cash: 0,
        etc: 0,
      },
      {
        // 합계
        type: "합계",
        card: 0,
        transfer: 0,
        cash: 0,
        etc: 0,
      },
    ];

    for (let i = 0; i < paymentHist.length; i++) {
      if (paymentHist[i].payment_info) {
        for (let j = 0; j < paymentHist[i].payment_info.length; j++) {
          const payment = paymentHist[i].payment_info[j];
          const { pay_method, paid_by, price } = payment;

          if (isPayment && price < 0) {
            continue;
          } else if (!isPayment && price > 0) {
            continue;
          }

          if (paid_by === "") {
            if (pay_method === "카드") {
              result[0].card += price;
            } else if (pay_method === "현금") {
              result[0].cash += price;
            } else if (pay_method === "이체") {
              result[0].transfer += price;
            } else if (pay_method === "기타") {
              result[0].etc += price;
            }
          } else {
            // tablet
            if (pay_method === "카드") {
              result[1].card += price;
            } else if (pay_method === "현금") {
              result[1].cash += price;
            } else if (pay_method === "이체") {
              result[1].transfer += price;
            } else if (pay_method === "기타") {
              result[1].etc += price;
            }
          }
        }
      }
    }

    // 합계
    for (let i = 0; i < result.length - 1; i++) {
      result[2].card += result[i].card;
      result[2].cash += result[i].cash;
      result[2].transfer += result[i].transfer;
      result[2].etc += result[i].etc;
    }

    return result;
  };
  //

  const onClickAggregatePaymentMethod = () => {
    const param = {
      paymentData: calcAggregatePayMethod(true, reportState.payment_history),
      refundData: calcAggregatePayMethod(false, reportState.payment_history),
    };

    setModalInfo({
      show: true,
      title: "결제기기별 결제수단 집계",
      Content: <AggregatePaymentMethodModal modalParam={param} onModalDone={onModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  return (
    <div style={{ marginTop: "2rem" }}>
      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Label
          labelText="결제 및 결제 취소 내역"
          fontWeight="bold"
          fontSize="1.2rem"
          justifyContent="flex-start"
          marginBottom="0.25rem"
        />
        <NormalBtn
          name="결제기기별 결제수단 집계"
          margin="0 0 0.25rem 0"
          onClick={() => {
            onClickAggregatePaymentMethod();
          }}
        />
      </div>
      <CustomTable
        columns_head={resultTableInst.columns_head}
        table_title={resultTableInst.table_title}
        rest_call={resultTableInst.get_data_from_rest}
        row_render={resultTableInst.create_table}
        rest_data={reportState.payment_history}
      />
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem" }}>
        <TextField
          labelText="취소 금액 합계"
          labelWidth="6rem"
          textBoxWidth="10rem"
          isNumber
          defaultValue={Math.abs(getTotalCanceledPrice()).toLocaleString()}
          endAdornment={"원"}
          disabled
        />
        <TextField
          marginLeft="3rem"
          labelText="결제 금액 합계"
          labelWidth="6rem"
          textBoxWidth="10rem"
          textMarginRight="0rem"
          isNumber
          defaultValue={getTotalPaidPrice().toLocaleString()}
          endAdornment={"원"}
          disabled
        />
      </div>
    </div>
  );
};
export default PaymentListTable;

class resultTable {
  table_title = "";

  // constructor() {}

  getTotalPrice = (pInfoList) => {
    if (pInfoList !== null) {
      return pInfoList.reduce((sum, cur) => {
        return sum + cur.price;
      }, 0);
    }
    return 0;
  };

  getRegDevice = (data) => {
    if (data.payment_info && data.payment_info.length > 0) {
      if (data.payment_info[0].paid_by === "tablet") {
        return "리더기";
      }
    }
    return "단말기(ERP)";
  };

  create_table = (data, idx) => {
    if (data.payment_info) {
      return (
        <>
          <CustomRow style={{}} key={idx}>
            <CustomTableContents rowSpan={data.payment_info.length + 1}>
              {this.getRegDevice(data)}
            </CustomTableContents>
            <CustomTableContents rowSpan={data.payment_info.length + 1}>
              {data.reason}
            </CustomTableContents>
            <CustomTableContents rowSpan={data.payment_info.length + 1}>
              {data.reg_info.reg_name}
            </CustomTableContents>
            <CustomTableContents rowSpan={data.payment_info.length + 1}>
              {data.member_info.member_no}
            </CustomTableContents>
            <CustomTableContents rowSpan={data.payment_info.length + 1}>
              {data.member_info.member_name}
            </CustomTableContents>
            <CustomTableContents rowSpan={data.payment_info.length + 1}>
              {data.payment_info.length === 1
                ? data.payment_info[0].purchase_code
                : data.purchase_code}
            </CustomTableContents>
            <CustomTableContents rowSpan={data.payment_info.length + 1} $isNumber fontWeight="bold">
              {this.getTotalPrice(data.payment_info).toLocaleString()}
            </CustomTableContents>
          </CustomRow>
          {data.payment_info &&
            data.payment_info.map((item, idx2) => {
              return (
                <CustomRow style={{}} key={idx2}>
                  <CustomTableContents>{item.pay_method}</CustomTableContents>
                  <CustomTableContents>{item.buyer}</CustomTableContents>
                  <CustomTableContents>{item.account_name}</CustomTableContents>
                  <CustomTableContents $isNumber color="#b12e2e">
                    {item.price < 0 ? Math.abs(item.price).toLocaleString() : "0"}
                  </CustomTableContents>
                  <CustomTableContents $isNumber color="#2623e0">
                    {item.price < 0 ? "0" : item.price.toLocaleString()}
                  </CustomTableContents>
                </CustomRow>
              );
            })}
        </>
      );
    }
  };

  columns_head = [
    // { title: "등록일", width: "9%" },
    { title: "결제기기", width: "8%" },
    { title: "구분", width: "8%" },
    { title: "담당자", width: "8%" },
    { title: "회원번호", width: "8%" },
    { title: "회원명", width: "8%" },
    { title: "구매코드", width: "9%" },
    { title: "합계", width: "8%" },
    { title: "결제수단", width: "7%" },
    { title: "매입사", width: "8%" },
    { title: "예금주", width: "8%" },
    { title: "취소금액", width: "8%" },
    { title: "결제금액", width: "8%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
