import { useEffect, useState } from "react";

import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { useDispatch } from "react-redux";

import border from "assets/icon/Border.svg";

const PointPayTable = ({ memberId }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const [curPage, setCurPage] = useState();
  const [totalPage, setTotalPage] = useState(0);
  const [list, setList] = useState([]);

  const getData = async (page) => {
    setCurPage(page);
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_USER_SERVER + "/member/point/provision/list",
        {
          params: {
            member_id: memberId,
            page_num: curPage,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setList(res.data.data);
        setTotalPage(res.data.total_page);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  useEffect(() => {
    getData();
  }, [memberId, curPage]);

  const pointPayList = new pointPayTable();
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            alt="border"
            src={border}
            style={{
              width: "1.2rem",
              height: "1.2rem",
            }}
          />

          <SubTitle titleText="포인트 지급내역" />
        </div>
      </div>
      <CustomTable
        columns_head={pointPayList.columns_head}
        table_title={pointPayList.table_title}
        rest_call={pointPayList.get_data_from_rest}
        row_render={pointPayList.create_table}
        rest_data={list}
      />
      <CustomPagination curPage={curPage} doSearch={setCurPage} totalCnt={totalPage} />
    </>
  );
};

export default PointPayTable;

class pointPayTable {
  table_title = "";
  btnStyleClass = btnStyles();

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.point}</CustomTableContents>
        <CustomTableContents>{data.reason}</CustomTableContents>
        <CustomTableContents>{data.purchase_code}</CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "지급일시", width: "25%" },
    { title: "지급 포인트", width: "25%" },
    { title: "사유", width: "25%" },
    { title: "구매코드", width: "25%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
