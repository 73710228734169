import { RadioGroup } from "@material-ui/core";
import { Radio } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckBox2 from "components/LabelInput/CheckBox2";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DrawLockerGrid } from "../Common/LockerGridForEdit";

const LockerSectionList = ({
  onClickOpenModal,
  selectedBranch,
  floorZoneCombo, //섹션2 콤보 state
  lockerAllInfo, //섹션2 구락리 state
  refreshView, //구락리 갱신(branchId,floorId,zoneId)
  getFloorZoneCombo, //섹션2 콤보갱신
}) => {
  const storesDispatch = useDispatch();
  const navigate = useNavigate();

  const LABEL_DATA = useMemo(() => {
    return [
      {
        status: "전체",
        color: "#DADADA",
        selectColor: "#444444",
        count: lockerAllInfo.count_info.total,
      },
      {
        status: "대기",
        color: "#E4F4E1",
        selectColor: "#78C86B",
        count: lockerAllInfo.count_info.ready,
      },
      {
        status: "예약",
        color: "#FBF0DB",
        selectColor: "#E9B44C",
        count: lockerAllInfo.count_info.almost,
      },
      {
        status: "사용",
        color: "#E3EBF7",
        selectColor: "#7199D6",
        count: lockerAllInfo.count_info.used,
      },
      {
        status: "만료",
        color: "#F8D8D8",
        selectColor: "#DE3A3A",
        count: lockerAllInfo.count_info.expired,
      },
      {
        status: "고장",
        color: "#F5E0F7",
        selectColor: "#DE3A3A",
        count: lockerAllInfo.count_info.broken,
      },
    ];
  }, [lockerAllInfo]);

  const [floorIdx, setFloorIdx] = useState(0);
  const [zoneIdx, setZoneIdx] = useState(0);

  const [radioSelect, setRadioSelect] = useState("전체");
  const [isFilterPasswd, setIsFilterPasswd] = useState(false);
  const labelWidth = "2.5rem";
  const textBoxWidth = "12rem";

  //락커상세모달 POP
  const popLockerDetailModal = (data) => {
    // console.log("data", data);
    if (!data.status) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="사용불가 락커입니다." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else {
      const param = {
        title: "락커 상세보기",
        branch_id: selectedBranch.branch_id,
        floor_id: floorZoneCombo.floor_zone_list[floorIdx].floor_id,
        zone_id: lockerAllInfo.zone_id,
        floor_name: floorZoneCombo.floor_zone_list[floorIdx].floor_name,
        zone_name: floorZoneCombo.floor_zone_list[floorIdx].zone_list[zoneIdx].zone_name,
        locker_total_idx: data.locker_total_idx,
        locker_no: data.num,
        navigate,
        isFromMemPage: false,
        refreshView,
      };

      onClickOpenModal("락커상세", param, lockerPopDone);
    }
  };
  const lockerPopDone = () => {
    storesDispatch(globalModalOff());
  };

  //지점바뀌면 섹션2 콤보갱신 + 콤보인덱스 초기화
  useEffect(() => {
    if (selectedBranch.branch_name) {
      getFloorZoneCombo(selectedBranch.branch_id, true);
      setFloorIdx(0);
      setZoneIdx(0);
    }
  }, [selectedBranch.branch_id]);

  return (
    <div>
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            display: "flex",
            marginRight: "2rem",
          }}
        >
          <ComboBox
            comboItemWidth={textBoxWidth}
            labelWidth={labelWidth}
            labelText="층"
            data={floorZoneCombo.floor_name_list}
            defaultValue={floorZoneCombo.floor_name_list[floorIdx]}
            onChangeCallback={(e) => {
              const fIdx = floorZoneCombo.floor_name_list.findIndex((el) => el === e.target.value);
              if (fIdx !== -1) {
                setFloorIdx(fIdx);
                //층이 바뀌면 구락리state 초기화
                setZoneIdx(0);
                refreshView(
                  selectedBranch.branch_id,
                  floorZoneCombo.floor_zone_list[fIdx].floor_id,
                  floorZoneCombo.floor_zone_list[fIdx].zone_list[0].zone_id,
                );
              }
            }}
          />
          <ComboBox
            marginLeft={"1rem"}
            comboItemWidth={textBoxWidth}
            labelWidth={labelWidth}
            labelText={"구역"}
            data={floorZoneCombo.zone_name_list[floorIdx]}
            defaultValue={
              floorZoneCombo.zone_name_list.length !== 0
                ? floorZoneCombo.zone_name_list[floorIdx][0]
                : ""
            }
            onChangeCallback={(e) => {
              const zIdx = floorZoneCombo.zone_name_list[floorIdx].findIndex(
                (el) => el === e.target.value,
              );
              const zId = floorZoneCombo.floor_zone_list[floorIdx].zone_list[zIdx].zone_id;
              if (zIdx !== -1) {
                setZoneIdx(zIdx);
                //구역 바뀌면 구락리 갱신
                refreshView(
                  selectedBranch.branch_id,
                  floorZoneCombo.floor_zone_list[floorIdx].floor_id,
                  zId,
                );
              }
            }}
          />
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <Label labelText="락커 상태" marginRight="2rem" />
          <RadioGroup row>
            {LABEL_DATA.map((el, idx) => {
              return (
                <FormControlLabel
                  key={idx}
                  value={el.status}
                  checked={radioSelect === el.status}
                  control={
                    <Radio
                      onChange={(e) => {
                        setRadioSelect(e.target.value);
                      }}
                    />
                  }
                  label={
                    <Label labelText={el.count ? el.status + " " + el.count : el.status + " 0"} />
                  }
                  style={{
                    backgroundColor: `${el.color}`,
                    borderRadius: "100px",
                    width: "7rem",
                    marginRight: "1.2rem",
                  }}
                />
              );
            })}
          </RadioGroup>

          <CheckBox2
            marginTop="0rem"
            marginRight="0rem"
            labelText={"비밀번호 미설정"}
            labelTextColor="#DE0909"
            fontWeight="400"
            transform={"scale(1.1)"}
            onChangeCallback={(e) => {
              setIsFilterPasswd(e.target.checked);
            }}
          />
        </div>
      </div>

      <div
        style={{
          marginTop: "2rem",
        }}
      >
        <DrawLockerGrid
          currentData={lockerAllInfo}
          gridWidth={"30rem"}
          onItemClick={popLockerDetailModal}
          mode={"view"}
          statusFilter={radioSelect}
          areaHeight={lockerAllInfo.height}
          isFilterPasswd={isFilterPasswd}
        />
      </div>
    </div>
  );
};
export default LockerSectionList;
