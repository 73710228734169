import { takeLatest, takeEvery } from "redux-saga/effects";
import { put, call } from "redux-saga/effects";
// import get_api from "../../../../../lib/LockerSettings/GetApi";
import get_api from "../api/GetApi";
import { hqPermissionAction } from "../actions/permision_act";
import { globalStartLoading, globalFinishLoading } from "modules/actions/Loading/index";
import * as ActionTypes from "../actions/action_type";

export function* getHqStaffListData(param) {
  try {
    // yield put(globalStartLoading());
    const { data } = yield call(get_api.geHqStaffListApi, param);

    if (data.data === null) {
      yield put(hqPermissionAction.loadHqStaffListSuccess(false));
    } else {
      yield put(hqPermissionAction.loadHqStaffListSuccess(data));
    }
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(hqPermissionAction.loadHqStaffListFail(error));
  }
  // yield put(globalFinishLoading());
}

export function* getHqBranchListData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.geHqStaffBranchListApi, param);

    if (data.data === null) {
      yield put(hqPermissionAction.loadBranchListSuccess(false));
    } else {
      yield put(hqPermissionAction.loadBranchListSuccess(data));
    }
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(hqPermissionAction.loadBranchListFail(error));
  }
  yield put(globalFinishLoading());
}
export function* getHqStaffPermissionData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.geHqStaffPermissionApi, param);

    if (data.data === null) {
      yield put(hqPermissionAction.loadStaffPermissionSuccess(false));
    } else {
      yield put(hqPermissionAction.loadStaffPermissionSuccess(data));
    }
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(hqPermissionAction.loadStaffPermissionFail(error));
  }
  yield put(globalFinishLoading());
}

export function* hqStaffPermissionSaga() {
  yield takeLatest(ActionTypes.getHqStaffAction, getHqStaffListData);
  yield takeLatest(ActionTypes.getHqStaffBranchAction, getHqBranchListData);
  yield takeLatest(ActionTypes.getHqStaffPermissionAction, getHqStaffPermissionData);
}
