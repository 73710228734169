import CardFixed from "components/Card/CardFixed";
import WeekCard from "./WeekCard";

const TeamRecordSearchResultCard = ({ weekResult, summaryInfo }) => {
  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2,1fr)",
          gridGap: "2rem",
          marginTop: "1.5rem",
        }}
      >
        {weekResult.map((el, idx) => {
          return (
            <CardFixed
              key={idx}
              midDom={<WeekCard data={el} summaryInfo={summaryInfo} />}
              isLoading={false}
              rootStyle={{
                marginTop: "0.5rem",
                borderRadius: "10px",
                backgroundColor: "#fff",
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default TeamRecordSearchResultCard;
