import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import TextArea from "components/LabelInput/TextArea";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useState } from "react";
import { useDispatch } from "react-redux";

const ApproveActionModal = ({ modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  let comment = "";
  const [loading, setLoading] = useState(false);

  const onClickOk = () => {
    simpleAlert(
      storesDispatch,
      "알림",
      modalParam.action + " 처리 하시겠습니까?",
      false,
      undefined,
      () => {
        doSave();
      },
    );
  };

  const doSave = async () => {
    // post call success 처리 되면 아래 구문 처리 하기

    const postApi =
      modalParam.action === "승인" ? "/approval/execute/approve/" : "/approval/execute/reject/";

    setLoading(true);

    const formData = new FormData();
    formData.append("approval_docs_id", modalParam.approvalDocsId);
    formData.append("comment", comment);
    await HttpInstance.post(process.env.REACT_APP_STAFF_SERVER + postApi, formData, contentTypeJson)
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          storesDispatch(
            globalAlertOn({
              show: true,
              titleText: "알림",
              bodyText: (
                <>
                  <Label labelText={modalParam.action + " 처리가 완료되었습니다."} />
                  <Label labelText="전자결재 목록으로 이동합니다." />
                </>
              ),
              reqConfirm: true,
              onBtnOk: () => {
                onModalDone();
              },
            }),
          );
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ width: "28rem" }}>
      {loading && <Loading />}

      <TextArea
        labelWidth="3.5rem"
        labelText={"의견"}
        fullWidth
        marginTop={"0.5rem"}
        minRows={4}
        maxRows={4}
        onChangeCallback={(e) => {
          comment = e.target.value;
        }}
      />
      <div style={{ display: "flex", justifyContent: "center", marginTop: "1.5rem" }}>
        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={onClickOk}
        >
          {modalParam.action}
        </Button>
      </div>
    </div>
  );
};
export default ApproveActionModal;
