import styled from "styled-components";

export const LogoButton = styled.button`
  padding: 0;
  margin: 0 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
`;

export const TopNavButton = styled.button`
  height: 100%;
  width: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  font-family: AppleSDGothicNeo;
  font-size: 1.125rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  border-radius: 0;
  text-decoration: none;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }
`;

export const TopButtonList = styled(TopNavButton)`
  background-color: #032063;
  z-index: 1;
  width: 8rem;
  height: 3rem;
  font-size: 0.975rem;
  margin: 0;

  &:hover {
    background-color: #4c679d;
  }
`;
