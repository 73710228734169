import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import NormalBtn from "components/NewButton/NormalBtn";

const ChooseEditModeModal = ({ modalParam }) => {
  const btnStyleClass = btnStyles();

  const popEditTeamModal = () => {
    const param = {
      title: "팀원 수정",
      modalParam: {
        data: modalParam.data,
        branch_id: modalParam.branch_id,
      },
    };
    modalParam.onClickOpenModal("팀원수정", param, modalParam.getTeamList);
  };

  const popEditTeamNameModal = () => {
    const param = {
      title: "팀명/팀장 수정",
      modalParam: {
        data: modalParam.data,
        branch_id: modalParam.branch_id,
      },
    };
    modalParam.onClickOpenModal("팀명수정", param, modalParam.getTeamList);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
      <NormalBtn
        name=" 팀명/팀장"
        onClick={() => {
          popEditTeamNameModal();
        }}
        theme="white"
      />
      <NormalBtn
        name=" 팀원"
        onClick={() => {
          popEditTeamModal();
        }}
      />
    </div>
  );
};

export default ChooseEditModeModal;
