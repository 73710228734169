import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import Label from "components/LabelInput/Label";
import NumberTextField from "components/LabelInput/NumberTextField";
import TextField from "components/LabelInput/TextField";

const BoardingSetting = ({ restData }) => {
  const textLabelWidth = "6rem";
  const unloadedTextBoxWidth = "12rem";
  const btnStyleClass = btnStyles();

  return (
    <div>
      <div style={{ display: "flex", marginTop: "1.25rem", marginBottom: "0.625rem" }}>
        <Label labelText={"게시 정보"} />
      </div>
      <div style={{ marginLeft: "2rem" }}>
        <div>
          <NumberTextField
            isNumber
            labelWidth={textLabelWidth}
            textBoxWidth={"6rem"}
            labelText="· 상품 게시 순서"
            endAdornment="번"
            minValue={0}
            maxValue={9999}
            defaultValue={restData.base_info.order}
            onChangeCallback={(e) => {
              restData.base_info.order = e;
            }}
          />

          <div
            style={{
              display: "flex",
              marginTop: "0.5rem",
            }}
          >
            <TextField
              labelWidth={textLabelWidth}
              textMarginRight="0rem"
              textBoxWidth={unloadedTextBoxWidth}
              labelText="· 태블릿 이미지"
              defaultValue={restData.base_info.tablet_image}
              onChangeCallback={(e) => {
                restData.base_info.tablet_image = e;
              }}
            />
            <Button
              textlen={2}
              className={clsx({
                [btnStyleClass.btnRoot]: true,
                [btnStyleClass.buttonType2]: true,
              })}
              // onClick={() => onClickEvent()}
            >
              첨부
            </Button>

            <TextField
              labelMarginLeft="4rem"
              labelWidth={textLabelWidth}
              textMarginRight="0rem"
              textBoxWidth={unloadedTextBoxWidth}
              labelText="· 회원앱 이미지"
              defaultValue={restData.base_info.mem_app_image}
              onChangeCallback={(e) => {
                restData.base_info.mem_app_image = e;
              }}
            />
            <Button
              textlen={2}
              className={clsx({
                [btnStyleClass.btnRoot]: true,
                [btnStyleClass.buttonType2]: true,
              })}
              // onClick={() => onClickEvent()}
            >
              삭제
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BoardingSetting;
