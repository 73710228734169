import CheckBox from "components/LabelInput/CheckBox";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import ModalBtnModeGroup from "components/ModalBtnModeGroup/ModalBtnModeGroup";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const AddHqModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const [hqIdx, setHqIdx] = useState(0);
  const [branchIdx, setBranchIdx] = useState(0);
  const [isHqOnly, setIsHqOnly] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    setIsDisabled(isDisabledHqOnly());
  }, []);

  const onSaveEvent = (e) => {
    const branchInfo = modalParam.hq_branch_list.target_list[hqIdx].branch_list[branchIdx];
    const hqInfo = modalParam.hq_branch_list.target_list[hqIdx].hq_info;

    const hqOnlyBranchInfo = {
      branch_id: "000000000000001039934570",
      branch_name: "본부직원",
    };

    if (!hqInfo || (!isHqOnly && !branchInfo)) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="필수값을 모두 선택해주셔야 합니다." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else {
      onModalDone({
        hqInfo: hqInfo,
        branchInfo: isHqOnly ? hqOnlyBranchInfo : branchInfo,
        isHqOnly: isHqOnly,
      });
    }
  };

  // 본부직원여부 체크박스가 비활성화여야 하는지 확인 (WorkInfoBase.js에 동일하게 선언함)
  const isDisabledHqOnly = () => {
    const hqPermIdx = modalParam.hqPermissions.findIndex(
      (x) => modalParam.hq_branch_list.target_list[hqIdx].hq_info.hq_id === x.hq_info.hq_id,
    );
    if (hqPermIdx === -1) {
      return true;
    }

    const hqPerm = modalParam.hqPermissions[hqPermIdx].permission_list.find(
      (x) => x.auth_code === "HQ_SET_MENU/BRANCH_MANAGE",
    );
    if (!hqPerm) {
      return true;
    }

    if (!hqPerm.is_read) {
      return true;
    }

    return false;
  };

  const canCheckHqOnly = () => {
    const hqName = modalParam.hq_branch_list.target_list[hqIdx].hq_info.hq_name;
    const res = modalParam.affiliation.findIndex((x) => x.hq_info.hq_name === hqName);

    if (res !== -1) return false;

    return true;
  };

  return (
    <div>
      <ModalBtnModeGroup mode={"add"} onClickEvent={onSaveEvent} />

      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <ComboBox
          labelWidth={"3rem"}
          comboItemWidth={"12.75rem"}
          labelText="본부"
          data={modalParam.hq_branch_list.hq_list}
          defaultValue={modalParam.hq_branch_list.hq_list[hqIdx]}
          onChangeCallback={(e, idx) => {
            setHqIdx(idx);
            setBranchIdx(0);
            setIsHqOnly(false);
          }}
        />
      </div>

      <div style={{ marginTop: "0.5rem" }}>
        <SearchComboBox
          labelWidth={"3rem"}
          comboItemWidth={"12.75rem"}
          labelText="지점"
          data={modalParam.hq_branch_list.branch_list[hqIdx]}
          defaultValue={modalParam.hq_branch_list.branch_list[hqIdx][0]}
          onChangeCallback={(e, v, idx) => {
            setBranchIdx(idx);
          }}
          disabled={isHqOnly}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "right", marginTop: "0.5rem" }}>
        <CheckBox
          marginTop="0rem"
          marginRight="0rem"
          labelWidth={"5rem"}
          labelText="본부직원여부"
          defaultValue={isHqOnly}
          disabled={isDisabled}
          onChangeCallback={(e) => {
            if (canCheckHqOnly()) {
              setIsHqOnly(e.checked);
            } else {
              storesDispatch(
                globalAlertOn({
                  show: true,
                  titleText: "알림",
                  bodyText: (
                    <>
                      <Label labelText="해당 본부의 지점이 이미 등록되어 있습니다." />
                    </>
                  ),
                  onBtnOk: () => {},
                }),
              );
              e.checked = false;
            }
          }}
        />
      </div>
    </div>
  );
};
export default AddHqModal;
