import { HttpInstance } from "lib/HttpLib";

export const getBaseInfoApi = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/branch_set/get_branch_info", {
    params: {
      branch_id: param.branch_id === null ? "" : param.branch_id,
      // branch_name: param.branch_name === null ? "" : param.branch_name,
    },
  });
};

export const getPolicyViewApi = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/branch_set/policy_view", {
    params: {
      branch_id: param.branch_id === null ? "" : param.branch_id,
      // branch_name: param.branch_name === null ? "" : param.branch_name,
    },
  });
};

export const getItemsViewApi = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/branch_set/items_view", {
    params: {
      branch_id: param.branch_id === null ? "" : param.branch_id,
      // branch_name: param.branch_name === null ? "" : param.branch_name,
    },
  });
};

export const getPrePaidInfoApi = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/branch_set/prepay_view", {
    params: {
      branch_id: param.branch_id === null ? "" : param.branch_id,
      item_id: param.item_id === null ? "" : param.item_id,
    },
  });
};

export const getSubPaidInfoApi = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/branch_set/subscript_view", {
    params: {
      branch_id: param.branch_id === null ? "" : param.branch_id,
      item_id: param.item_id === null ? "" : param.item_id,
    },
  });
};

export const getETCItemsInfoApi = (param) => {
  return HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/branch_set/etc_view", {
    params: {
      branch_id: param.branch_id === null ? "" : param.branch_id,

      item_id: param.item_id === null ? "" : param.item_id,
    },
  });
};

const get_api = {
  getBaseInfoApi,
  getPolicyViewApi,
  getItemsViewApi,
  getPrePaidInfoApi,
  getSubPaidInfoApi,
  getETCItemsInfoApi,
};

export default get_api;
