import { SubLine, simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import ModalBtnModeGroup from "components/ModalBtnModeGroup/ModalBtnModeGroup";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadPolicyStore } from "../Module/actions";
import { loadPolicyViewReset } from "../Module/actions/policy_act";
import post_api from "../Module/api/PostApi";
import ClassProduct from "./PolicySub/ClassProduct";
import DailyItem from "./PolicySub/DailyItem";
import MembershipPolicy from "./PolicySub/MembershipPolicy";
import PrepayItems from "./PolicySub/PrepayItems";
import SubscribeItem from "./PolicySub/SubscribeItem";

const PolicyManagement = ({ branchId }) => {
  const borderWidth = "65rem";
  const borderWidthExpand = "68rem";
  const storesDispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [contractFiles, setContractFiles] = useState({
    register_prepay: "",
    register_subs: "",
    cancel_subs: "",
    cancel_regular: "",
    change: "",
    transfer: "",
    refund: "",
    service: "",
    register_pt: "",
    assign: "",
    agree_personal: "",
    agree_marketing: "",
  }); // 강제 렌더링용
  const [, updateState] = useState(); // 강제 렌더링용
  const forceUpdate = useCallback(() => updateState({}), []);

  const restData = useSelector((state) => state.branch_set_state.branch_policy_set_state);
  // console.log("policy setting");
  // console.log(restData.stores.data.contents);

  useEffect(() => {
    // console.log("PolicySetting rerender...");
    if (branchId !== "") {
      storesDispatch(loadPolicyStore(branchId));
      forceUpdate();
    }
    return () => {
      storesDispatch(loadPolicyViewReset());
    };
  }, [loadPolicyStore, storesDispatch, branchId]);

  const onUploadFile = (name, file) => {
    setContractFiles({
      ...contractFiles,
      [name]: file,
    });
  };

  const validation = () => {
    const dataSet = restData.stores.data.contents;
    let res = "";

    if (dataSet.membership_policy.use_dflt_from_date && !dataSet.membership_policy.dflt_from_date) {
      res += "· 시작일 기본값\n";
    }
    if (dataSet.membership_policy.use_max_wait_day && !dataSet.membership_policy.max_wait_day) {
      res += "· 시작일 대기일\n";
    }

    return res;
  };

  const onClickSave = () => {
    const validationRes = validation();
    if (validationRes !== "") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="필수값을 입력해 주시기 바랍니다." />
              <br />
              <br />
              <Label labelText={validationRes} whiteSpace="pre-wrap" />
            </>
          ),
          // width: "50rem",
          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="저장하시겠습니까?" />
            </>
          ),
          // width: "50rem",
          onBtnYes: () => {
            onSaveEvent();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const onSaveEvent = async () => {
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    // console.log("try saving~!");
    // console.log(JSON.stringify(restData.stores.data.contents));

    // 행사 설정 값 string -> int 변경
    const monthList = restData.stores.data.contents.membership_policy.event_month_list;
    const dayList = restData.stores.data.contents.membership_policy.event_day_list;
    for (let i = 0; i < monthList.length; i++) {
      monthList[i] = Number(monthList[i]);
    }
    for (let i = 0; i < dayList.length; i++) {
      dayList[i] = Number(dayList[i]);
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("apply_pre_paid_form", contractFiles.register_prepay);
    formData.append("setting_info", JSON.stringify(restData.stores.data.contents));
    await HttpInstance.post(post_api.updatePolicyApi(), formData, config)
      .then((res) => {
        // console.log(res);
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          storesDispatch(
            globalAlertOn({
              show: true,
              titleText: "알림",
              bodyText: (
                <>
                  <Label labelText="저장되었습니다." />
                </>
              ),
              // width: "50rem",
              onBtnOk: () => {},
            }),
          );
          storesDispatch(loadPolicyStore(branchId));
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
        storesDispatch(loadPolicyStore(branchId));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const borderStyle = {
    border: "1px solid #888",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    marginBottom: "1rem",
    width: borderWidth,
  };

  return (
    <div>
      {loading && <Loading />}

      <div style={{ width: borderWidthExpand }}>
        <ModalBtnModeGroup mode={"edit"} onClickEvent={onClickSave} />
      </div>

      <SubTitle titleText={"< 상품금액 정책 >"} fontSize={"1.1rem"} />
      <div style={borderStyle}>
        <PrepayItems restData={restData.stores.data.contents} />
        <SubLine />
        <SubscribeItem restData={restData.stores.data.contents} />
        <SubLine />
        <DailyItem restData={restData.stores.data.contents} />
        <SubLine />
        <ClassProduct restData={restData.stores.data.contents} />
      </div>

      <SubTitle titleText={"< 회원권 정책 >"} fontSize={"1.1rem"} />
      <div style={borderStyle}>
        <MembershipPolicy restData={restData.stores.data.contents} />
        {/* <SubLine /> */}
        {/* <DocumentSetting restData={restData.stores.data.contents} onUploadFile={onUploadFile} /> */}
      </div>
    </div>
  );
};
export default PolicyManagement;
