const commonFontSmall = {
  fontFamily: "AppleSDGothicNeo",
  fontSize: "0.9rem",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#000",
};

const commonFontMid = {
  fontFamily: "AppleSDGothicNeo",
  fontSize: "1rem",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#000",
};

const tabBorder = {
  border: "1px solid #CCCCCC",
  fontFamily: "AppleSDGothicNeo",
  fontSize: "1rem",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  // backgroundColor: "#032063",
};

const tabStyle = (index, curIndex, width) => {
  const retStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: width ? width : "8rem",
    height: "40px",
    backgroundColor: index === curIndex ? "#032063" : "#fff",
    borderRadius: "5px 5px 0px 0px",
    flex: "none",
    order: 0,
    flexGrow: 0,
    border: "1px solid #CCCCCC",

    fontFamily: "AppleSDGothicNeo",
    color: index === curIndex ? "#fff" : "#000",
    fontSize: "1rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    whiteSpace: "pre-wrap",
  };

  if (index === 0) {
    return retStyle;
  } else {
    Object.assign(retStyle, { marginLeft: "0.25rem" });
    return retStyle;
  }
};

const emptyTabStyle = () => {
  const retStyle = {
    minHeight: 0,
    minWidth: 0,
    padding: 0,
    margin: 0,
  };
  return retStyle;
};
const tabStyleWhite = (index, curIndex) => {
  return {
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.875rem",
    lineHeight: "100%",
    letterSpacing: "normal",
    whiteSpace: "pre-wrap",
    marginLeft: index === 0 ? "" : "0.25rem",
    backgroundColor: index === curIndex ? "#FFFFFF" : "",
    borderRadius: index === curIndex ? "5px" : "",
    color: index === curIndex ? "#0C38A8" : "#A3A8B4",
    fontWeight: index === curIndex ? "600" : "500",
    boxShadow: index === curIndex ? "0px 2px 8px rgba(0, 0, 0, 0.12)" : "",
  };
};

const tabBorderWhite = {
  fontFamily: "AppleSDGothicNeo",
  fontSize: "1rem",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
};

export {
  commonFontSmall,
  commonFontMid,
  tabBorder,
  tabStyle,
  emptyTabStyle,
  tabStyleWhite,
  tabBorderWhite,
};
