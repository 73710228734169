import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, getCardCompList } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import SubTitle from "components/PageTitle/SubTitle";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { searchCMSPaidHistAPi } from "./subsPayApi/getApi";

const PayHistoryTab = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const subsPayList = new subsPayTable();

  const [searchResult, setSearchResult] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  // console.log(modalParam);
  const doSearch = async (page) => {
    setCurPage(page);

    searchCMSPaidHistAPi(
      storesDispatch,
      // modalParam.active_info.member_id,
      modalParam,
      setSearchResult,
      setTotalPage,
      curPage,
    );
  };

  useEffect(() => {
    doSearch(1);
    return () => {
      // setMemberId("");
      setCurPage(1);
      setTotalPage(0);
      setSearchResult([]);
    };
  }, [modalParam.params.member_id]);

  return (
    <>
      <div
        style={{
          marginTop: "1rem",
        }}
      >
        <SubTitle titleText={"납부 정보"} />
        <CustomTable
          columns_head={subsPayList.columns_head}
          table_title={subsPayList.table_title}
          rest_call={subsPayList.get_data_from_rest}
          row_render={subsPayList.create_table}
          rest_data={searchResult}
          // rest_data={[{ aa: "aaa" }]}
        />
        <CustomPagination curPage={curPage} doSearch={doSearch} totalCnt={totalPage} />
      </div>
    </>
  );
};
export default PayHistoryTab;

class subsPayTable {
  table_title = "";
  btnStyleClass = btnStyles();
  cardCompList = getCardCompList();

  create_table = (data) => {
    // console.log(data);
    return (
      <CustomRow style={{ width: "100%" }}>
        <CustomTableContents>{data.created_at}</CustomTableContents>
        <CustomTableContents>{data.membership_item_name}</CustomTableContents>
        <CustomTableContents>{data.membership_info}</CustomTableContents>
        <CustomTableContents>{data.paymentCompany}</CustomTableContents>
        <CustomTableContents>{data.payerName}</CustomTableContents>
        <CustomTableContents>{data.paymentNumber}</CustomTableContents>
        <CustomTableContents>{data.callAmount}</CustomTableContents>
        <CustomTableContents>{data.status}</CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "결제 신청일시", width: "18%" },
    { title: "회원권 상품명", width: "20%" },
    { title: "부가상품 이용", width: "15%" },
    { title: "결제사명", width: "8%" },
    { title: "결제자명", width: "8%" },
    { title: "결제번호", width: "10%" },
    { title: "결제금액", width: "8%" },
    { title: "결제여부", width: "8%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
