import { put, call } from "redux-saga/effects";
// import get_api from "../../../../../lib/LockerSettings/GetApi";
import get_api from "../api/GetApi";
import { SalaryInfoAction } from "../actions/index";
import { globalStartLoading, globalFinishLoading } from "modules/actions/Loading/index";

//급여 메인
export function* getSalaryInfoViewData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getSalaryInfoData, param);

    if (data.data === null) {
      yield put(SalaryInfoAction.loadSalaryInfoViewSuccess(false));
    } else {
      yield put(SalaryInfoAction.loadSalaryInfoViewSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(SalaryInfoAction.loadSalaryInfoViewFail(error));
    yield put(globalFinishLoading());
  }
}

//1번탭 (급여현황)
export function* getSalaryStatusData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getSalaryStatusData, param);

    if (data.data === null) {
      yield put(SalaryInfoAction.loadSalaryStatusViewSuccess(false));
    } else {
      yield put(SalaryInfoAction.loadSalaryStatusViewSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(SalaryInfoAction.loadSalaryStatusViewFail(error));
    yield put(globalFinishLoading());
  }
}

//2번탭 (기본급)
export function* getStaffStandardSalaryStatusData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getStaffStandardSalaryStatusData, param);

    if (data.data === null) {
      yield put(SalaryInfoAction.loadStaffStandardSalaryViewSuccess(false));
    } else {
      yield put(SalaryInfoAction.loadStaffStandardSalaryViewSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(SalaryInfoAction.loadStaffStandardSalaryViewFail(error));
    yield put(globalFinishLoading());
  }
}

//3번탭 (인센티브)
export function* getMbshpSalaryData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getMbshpSalaryData, param);

    if (data.data === null) {
      yield put(SalaryInfoAction.loadMbshpSalaryViewSuccess(false));
    } else {
      yield put(SalaryInfoAction.loadMbshpSalaryViewSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(SalaryInfoAction.loadMbshpSalaryViewFail(error));
    yield put(globalFinishLoading());
  }
}

//4번탭(pt)
export function* getPtSalaryData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getPtSalaryData, param);

    if (data.data === null) {
      yield put(SalaryInfoAction.loadPtSalaryViewSuccess(false));
    } else {
      yield put(SalaryInfoAction.loadPtSalaryViewSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(SalaryInfoAction.loadPtSalaryViewFail(error));
    yield put(globalFinishLoading());
  }
}

//5번탭 (gx)
export function* getGxSalaryData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getGxSalaryData, param);

    if (data.data === null) {
      yield put(SalaryInfoAction.loadGxSalaryViewSuccess(false));
    } else {
      yield put(SalaryInfoAction.loadGxSalaryViewSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(SalaryInfoAction.loadGxSalaryViewFail(error));
    yield put(globalFinishLoading());
  }
}

//6번탭(기타)
export function* getEtcSalaryData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getEtcSalaryData, param);

    if (data.data === null) {
      yield put(SalaryInfoAction.loadEtcSalaryViewSuccess(false));
    } else {
      yield put(SalaryInfoAction.loadEtcSalaryViewSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(SalaryInfoAction.loadEtcSalaryViewFail(error));
    yield put(globalFinishLoading());
  }
}

//안쓰는거
export function* getClassSessionData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getClassSessionData, param);

    if (data.data === null) {
      yield put(SalaryInfoAction.loadClassSessionViewSuccess(false));
    } else {
      yield put(SalaryInfoAction.loadClassSessionViewSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(SalaryInfoAction.loadClassSessionViewFail(error));
    yield put(globalFinishLoading());
  }
}
