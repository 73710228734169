import { useEffect, useState } from "react";

import { btnStyles } from "components/ButtonStyle/ButtonStyle";

import * as lodash from "lodash";
import PaymentModalB from "pages/ERP/MemberCommonItems/PaymentModalB/PaymentModalB";
import SubsPayManageModal from "pages/ERP/MemberView/Modal/ChangeRefundModal/SubsPayManageModal";

const ChangeItemModalIndex = ({ modalSelect, modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();

  const [paramData, setParamData] = useState(modalParam.modalParam);

  useEffect(() => {
    const editData = lodash.cloneDeep(modalParam.modalParam);
    setParamData(editData);
    return () => {};
  }, []);

  if (modalSelect === "결제") {
    return <PaymentModalB modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "정기결제수단등록") {
    return <SubsPayManageModal modalParam={paramData} onModalDone={onModalDone} />;
  } else {
    return <div>current ELSE</div>;
  }
};
export default ChangeItemModalIndex;
