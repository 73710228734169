import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, dateToStr, parseMoneyFormat } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import CheckBox from "components/LabelInput/CheckBox";
import Label from "components/LabelInput/Label";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import { globalAlertOn } from "modules/actions/Alert";
import { useDispatch } from "react-redux";

const UnPaidProcessView = ({ viewState, onChangeDueDate, setPayInfo, payInfo }) => {
  const storesDispatch = useDispatch();

  const reqestAutoPay = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="자동 결제를 요청하시겠습니까?" />
          </>
        ),
        onBtnYes: () => {},
        onBtnNo: () => {},
      }),
    );
  };

  //테이블 체크박스 1개만 선택되도록
  const onSelectRowEvt = (isChecked, selectedIdx) => {
    viewState.unpaid_list.forEach((el, idx) => {
      if (selectedIdx === idx) {
        return (el.unpaid_list_selector.selected = true);
      } else {
        return (el.unpaid_list_selector.selected = false);
      }
    });
    if (!isChecked) {
      viewState.unpaid_list[selectedIdx].unpaid_list_selector.selected = false;
    }
  };

  const findMemberList = new findMemberInfoTable(
    onChangeDueDate,
    reqestAutoPay,
    setPayInfo,
    payInfo,
    onSelectRowEvt,
  );

  return (
    <div>
      <div style={{ display: "flex", marginTop: "0.3rem" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="결제할 미납 항목 선택" />
      </div>

      <CustomTable
        columns_head={findMemberList.columns_head}
        table_title={findMemberList.table_title}
        rest_call={findMemberList.get_data_from_rest}
        row_render={findMemberList.create_table}
        rest_data={viewState.unpaid_list}
      />
    </div>
  );
};
export default UnPaidProcessView;

class findMemberInfoTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onChangeDueDate = null;
  reqestAutoPay = null;
  setPayInfo = null;
  payInfo = null;
  onSelectRowEvt = null;

  constructor(onChangeDueDate, reqestAutoPay, setPayInfo, payInfo, onSelectRowEvt) {
    this.onChangeDueDate = onChangeDueDate;
    this.reqestAutoPay = reqestAutoPay;
    this.setPayInfo = setPayInfo;
    this.payInfo = payInfo;
    this.onSelectRowEvt = onSelectRowEvt;
  }

  getSelected = (data) => {
    return (
      this.payInfo.selectedPay &&
      this.payInfo.selectedPay.unpaid_data.purchase_code === data.unpaid_data.purchase_code
    );
  };

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>
          <CheckBox
            isTable
            marginTop="0rem"
            marginRight="0rem"
            color="primary"
            defaultValue={data.unpaid_list_selector.selected}
            onChangeCallback={(e) => {
              this.setPayInfo((cur) => {
                return { ...cur, selectedPay: data, reg_date: dateToStr(new Date()) };
              });
              this.onSelectRowEvt(e.checked, idx);
            }}
          />
        </CustomTableContents>
        <CustomTableContents>{data.unpaid_data.purchase_code}</CustomTableContents>
        <CustomTableContents $isNumber>
          {parseMoneyFormat(data.unpaid_data.total_price) + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {parseMoneyFormat(data.unpaid_data.paid_price) + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {parseMoneyFormat(data.unpaid_data.unpaid_price) + " 원"}
        </CustomTableContents>
        <CustomTableContents>{data.unpaid_data.due_date}</CustomTableContents>
        <CustomTableContents>
          {data.unpaid_data.payment_type !== "구독형" ? (
            <NormalBtn
              name="납부예정일 변경"
              theme="white"
              onClick={() => this.onChangeDueDate(data.unpaid_data.purchase_code)}
            />
          ) : (
            <NormalBtn name="자동결제 요청" theme="white" onClick={() => this.reqestAutoPay()} />
          )}
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "", width: "7%" },
    { title: "구매코드", width: "14%" },
    { title: "상품총액", width: "13%" },
    { title: "납부 완료", width: "13%" },
    { title: "미납금", width: "13%" },
    { title: "납부 예정일", width: "14%" },
    { title: "", width: "17%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
