import PulseLoader from "react-spinners/PulseLoader";
import styled from "styled-components";

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Spinner = () => {
  return (
    <Flex>
      <PulseLoader size={20} color="rgba(255, 255, 255, 1)" speedMultiplier={1} />
    </Flex>
  );
};

export default Spinner;
