import { SubLine } from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import Label from "components/LabelInput/Label";
import NumberTextField from "components/LabelInput/NumberTextField";
import { useEffect, useState } from "react";

const SideItemSetting = ({ branchPolicy, restData, mbshpOption, period, isEditMode }) => {
  const textLabelWidth = "6rem";
  const textLabelWidthShort = "4rem";
  const textBoxWidth = "10rem";
  const labelMarginLeft = "3rem";

  const [ptPrice, setPtPrice] = useState(restData.pt_price);
  const [ptCombine, setPtCombine] = useState(restData.is_combine_pt);

  useEffect(() => {
    resetPrices();
  }, [period]);

  useEffect(() => {
    resetPrices();
  }, [mbshpOption]);

  const resetPrices = () => {
    if (!isEditMode) {
      restData.cloth_price = branchPolicy.base_policy.prepay_cloth * period;
      restData.gx_price = branchPolicy.base_policy.prepay_gx * period;
      restData.locker_price = branchPolicy.base_policy.prepay_locker * period;
      restData.pt_per_price = branchPolicy.base_policy.pt;

      if (mbshpOption === "일일권") {
        restData.cloth_price = branchPolicy.base_policy.oneday_cloth;
        restData.gx_price = branchPolicy.base_policy.oneday_gx;
      }
    }
  };
  const calcPtPrice = () => {
    const pt_total = restData.pt_session * restData.pt_per_price;
    restData.pt_price = pt_total;
    setPtPrice(pt_total);
  };

  return (
    <div style={{}}>
      <div style={{ display: "flex", marginTop: "1.25rem", marginBottom: "0.625rem" }}>
        <Label labelText={"부가상품"} />
      </div>
      <div style={{ marginLeft: "2rem" }}>
        <div
          style={{
            display: "flex",
          }}
        >
          <NumberTextField
            isNumber
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· 운동복 정가"
            defaultValue={
              mbshpOption === "일일권"
                ? branchPolicy.base_policy.oneday_cloth
                : branchPolicy.base_policy.prepay_cloth * period
            }
            endAdornment="원"
            disabled
          />

          <NumberTextField
            isNumber
            labelWidth={textLabelWidthShort}
            textBoxWidth={textBoxWidth}
            labelMarginLeft={labelMarginLeft}
            labelText="· 판매가"
            endAdornment="원"
            minValue={0}
            maxValue={99999999}
            defaultValue={
              isEditMode
                ? restData.cloth_price
                : mbshpOption === "일일권"
                ? branchPolicy.base_policy.oneday_cloth
                : branchPolicy.base_policy.prepay_cloth * period
            }
            onChangeCallback={(e) => {
              restData.cloth_price = e;
            }}
            disabled={isEditMode}
          />
        </div>

        <div
          style={{
            display: "flex",
            marginTop: "0.5rem",
          }}
        >
          <NumberTextField
            isNumber
            labelWidth={textLabelWidth}
            textBoxWidth={textBoxWidth}
            labelText="· GX 정가"
            defaultValue={
              mbshpOption === "일일권"
                ? branchPolicy.base_policy.oneday_gx
                : branchPolicy.base_policy.prepay_gx * period
            }
            endAdornment="원"
            disabled
          />

          <NumberTextField
            isNumber
            labelWidth={textLabelWidthShort}
            textBoxWidth={textBoxWidth}
            labelMarginLeft={labelMarginLeft}
            labelText="· 판매가"
            endAdornment="원"
            minValue={0}
            maxValue={99999999}
            defaultValue={
              isEditMode
                ? restData.gx_price
                : mbshpOption === "일일권"
                ? branchPolicy.base_policy.oneday_gx
                : branchPolicy.base_policy.prepay_gx * period
            }
            onChangeCallback={(e) => {
              restData.gx_price = e;
            }}
            disabled={isEditMode}
          />
        </div>

        {mbshpOption !== "일일권" ? (
          <div
            style={{
              display: "flex",
              marginTop: "0.5rem",
            }}
          >
            <NumberTextField
              isNumber
              labelWidth={textLabelWidth}
              textBoxWidth={textBoxWidth}
              labelText="· 락커 정가"
              defaultValue={branchPolicy.base_policy.prepay_locker * period}
              endAdornment="원"
              disabled
            />

            <NumberTextField
              isNumber
              labelWidth={textLabelWidthShort}
              textBoxWidth={textBoxWidth}
              labelMarginLeft={labelMarginLeft}
              labelText="· 판매가"
              endAdornment="원"
              minValue={0}
              maxValue={99999999}
              defaultValue={
                isEditMode ? restData.locker_price : branchPolicy.base_policy.prepay_locker * period
              }
              onChangeCallback={(e) => {
                restData.locker_price = e;
              }}
              disabled={isEditMode}
            />
          </div>
        ) : (
          <></>
        )}
      </div>

      <SubLine />
      <div style={{ display: "flex", marginTop: "1.25rem" }}>
        <Label labelText={"결합상품 선택"} />
      </div>
      <div style={{ marginLeft: "2rem" }}>
        <div
          style={{
            display: "flex",
          }}
        >
          <CheckBox
            labelText="운동복"
            defaultValue={restData.is_combine_cloth}
            onChangeCallback={(e) => {
              restData.is_combine_cloth = e.checked;
            }}
            disabled={isEditMode}
          />
          <CheckBox
            labelText="GX"
            defaultValue={restData.is_combine_gx}
            onChangeCallback={(e) => {
              restData.is_combine_gx = e.checked;
            }}
            disabled={isEditMode}
          />
          {mbshpOption !== "일일권" ? (
            <CheckBox
              labelText="락커"
              defaultValue={restData.is_combine_locker}
              onChangeCallback={(e) => {
                restData.is_combine_locker = e.checked;
              }}
              disabled={isEditMode}
            />
          ) : (
            <></>
          )}
        </div>
        {mbshpOption !== "일일권" ? (
          <div style={{ display: "flex" }}>
            <CheckBox
              labelText="PT"
              defaultValue={restData.is_combine_pt}
              onChangeCallback={(e) => {
                restData.is_combine_pt = e.checked;
                setPtCombine(e.checked);
              }}
              disabled={isEditMode}
            />
            <div style={{ display: "flex", marginTop: "0.625rem" }}>
              <NumberTextField
                isNumber
                required
                labelWidth={textLabelWidth}
                textBoxWidth={textBoxWidth}
                labelText="· 세션"
                endAdornment="회"
                minValue={0}
                maxValue={200}
                defaultValue={restData.pt_session}
                onChangeCallback={(e) => {
                  restData.pt_session = e;
                  calcPtPrice();
                }}
                disabled={!ptCombine || isEditMode}
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        {/* pt 금액 line */}
        {mbshpOption !== "일일권" ? (
          <div style={{ display: "flex", marginTop: "0.625rem", marginLeft: "4.925rem" }}>
            <Label labelText={"· PT 금액 정가"} marginRight={"0.575rem"} />
            <NumberTextField
              isNumber
              labelWidth={"0rem"}
              textBoxWidth={textBoxWidth}
              labelText=""
              defaultValue={branchPolicy.base_policy.pt}
              endAdornment="원"
              disabled
            />

            <NumberTextField
              isNumber
              labelWidth={"2.5rem"}
              textBoxWidth={textBoxWidth}
              labelMarginLeft={"2rem"}
              labelText="· 회당"
              endAdornment="원"
              minValue={0}
              maxValue={1000000}
              defaultValue={isEditMode ? restData.pt_per_price : branchPolicy.base_policy.pt}
              onChangeCallback={(e) => {
                restData.pt_per_price = e;
                calcPtPrice();
              }}
              disabled={!ptCombine || isEditMode}
            />

            <NumberTextField
              isNumber
              labelWidth={"3.5rem"}
              textBoxWidth={textBoxWidth}
              labelMarginLeft={"2rem"}
              labelText="· 판매가"
              endAdornment="원"
              defaultValue={ptPrice}
              disabled
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <SubLine />
    </div>
  );
};
export default SideItemSetting;
