import CardFixed from "components/Card/CardFixed";
import TextField from "components/LabelInput/TextField";

const RegisterInfoCard = ({ memberInputState }) => {
  const comboItemWidth = "11.5rem";
  const comboItemWidthShort = "6.5rem";
  const lineMarginTop = "0.5rem";
  const labelWidth = "4.25rem";

  return (
    <div style={{ marginTop: "1rem" }}>
      <TextField
        labelWidth={labelWidth}
        textBoxWidth={comboItemWidth}
        labelText="지점"
        defaultValue={memberInputState.branch_info.branch_name}
        disabled
      />
      <TextField
        marginTop={lineMarginTop}
        labelWidth={labelWidth}
        textBoxWidth={comboItemWidth}
        labelText="출입방식"
        defaultValue={memberInputState.card_param.access_method}
        disabled
      />
      <TextField
        marginTop={lineMarginTop}
        labelWidth={labelWidth}
        textBoxWidth={comboItemWidth}
        labelText="카드번호"
        defaultValue={memberInputState.card_param.card_no}
        disabled
      />
    </div>
  );
};

const PersonalInfoCardItem = ({ onClickOpenModal, memberBaseInfoState, isNeedEncode }) => {
  const labelWidth = "3.75rem";
  const textBoxWidth_1 = "10.75rem";

  return (
    <div style={{ marginTop: "1rem" }}>
      <div style={{ display: "flex" }}>
        {isNeedEncode ? (
          <TextField
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth_1}
            labelText="회원명"
            defaultValue={memberBaseInfoState.member_name}
            disabled
          />
        ) : (
          <TextField
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth_1}
            labelText="회원명"
            defaultValue={memberBaseInfoState.member_name}
            disabled
          />
        )}

        <TextField
          marginLeft="2.5rem"
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth_1}
          labelText="회원번호"
          defaultValue={memberBaseInfoState.member_no}
          disabled
        />
        {isNeedEncode ? (
          ""
        ) : (
          <TextField
            marginLeft="2.5rem"
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth_1}
            labelText="생년월일"
            defaultValue={memberBaseInfoState.birth}
            disabled
          />
        )}
      </div>

      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={textBoxWidth_1}
          labelText="성별"
          defaultValue={memberBaseInfoState.gender}
          disabled
        />
        {isNeedEncode ? (
          <TextField
            marginLeft="2.5rem"
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth_1}
            labelText="연락처"
            defaultValue={memberBaseInfoState.phone}
            disabled
          />
        ) : (
          <TextField
            marginLeft="2.5rem"
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth_1}
            labelText="연락처"
            defaultValue={memberBaseInfoState.phone}
            disabled
          />
        )}
      </div>
      {isNeedEncode ? (
        ""
      ) : (
        <div style={{ display: "flex", marginTop: "0.5rem" }}>
          <TextField
            labelWidth={labelWidth}
            textBoxWidth={"26rem"}
            textMarginRight="0rem"
            labelText="주소"
            defaultValue={memberBaseInfoState.address_1}
            disabled
          />
          <TextField
            labelMarginRight="0.25rem"
            textBoxWidth={"21rem"}
            defaultValue={memberBaseInfoState.address_2}
            disabled
          />
        </div>
      )}
    </div>
  );
};

const PersonalInfo = ({
  onClickOpenModal,
  memberBaseInfoState,
  memberInputState,
  isNeedEncode,
}) => {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "80.437rem" }}>
        <div style={{ display: "flex", marginBottom: "0.625rem" }}>
          <CardFixed
            topDom={<div>가입정보</div>}
            midDom={<RegisterInfoCard memberInputState={memberInputState} />}
            isLoading={false}
            rootStyle={{
              width: "20rem",
              height: "12.938rem",
              borderRadius: "10px",
              backgroundColor: "#fff",
            }}
          />
          <CardFixed
            topDom={<div>회원정보</div>}
            midDom={
              <PersonalInfoCardItem
                onClickOpenModal={onClickOpenModal}
                memberBaseInfoState={memberBaseInfoState}
                isNeedEncode={isNeedEncode && isNeedEncode}
              />
            }
            isLoading={false}
            rootStyle={{
              width: "55.63rem",
              height: "12.938rem",
              marginLeft: "1.25rem",
              borderRadius: "10px",
              backgroundColor: "#fff",
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default PersonalInfo;
