import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";

const SendHistResultTable = ({
  openModal,
  searchResult,
  curPage,
  setCurPage,
  totalPage,
  onClickSearch,
}) => {
  const btnStyleClass = btnStyles();

  const onClickShow = (data) => {
    openModal("show", { title: "문자내용 보기", data });
  };

  const onClickDownload = () => {};

  const searchResultList = new resultTable(onClickShow, onClickDownload);

  const doSearch = (page) => {
    onClickSearch(page);
  };

  return (
    <div>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
        // width="160rem"
      />
      <CustomPagination curPage={curPage} totalCnt={totalPage} doSearch={doSearch} />
    </div>
  );
};
export default SendHistResultTable;

class resultTable {
  table_title = "";
  onClickShow = null;
  onClickDownload = null;

  constructor(onClickShow, onClickDownload) {
    this.onClickShow = onClickShow;
    this.onClickDownload = onClickDownload;
  }

  getMethodType = (sendType) => {
    if (sendType === "SMS" || sendType === "LMS" || sendType === "MMS") {
      return "문자";
    }

    return "";
  };

  create_table = (data, idx) => {
    return (
      <CustomRow
        style={{}}
        key={idx}
        // onClick={(e) => {
        //   if (e.target.nodeName === "DIV" || e.target.nodeName === "TD") {
        //     this.onClickRow(idx);
        //   }
        // }}
        // hover
      >
        <CustomTableContents>{data.send_date}</CustomTableContents>
        <CustomTableContents>{data.template_info.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.reg_info.reg_name}</CustomTableContents>
        <CustomTableContents>{this.getMethodType(data.send_type)}</CustomTableContents>
        <CustomTableContents>{data.send_type}</CustomTableContents>
        <CustomTableContents>{data.template_info.sms_type}</CustomTableContents>
        <CustomTableContents>{data.template_info.sms_info.sms_name}</CustomTableContents>
        <CustomTableContents $isNumber>{data.try + " 건"}</CustomTableContents>
        <CustomTableContents $isNumber>{data.success + " 건"}</CustomTableContents>
        <CustomTableContents $isNumber>{data.fail + " 건"}</CustomTableContents>

        <CustomTableContentsEND>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              type="detail"
              onClick={() => this.onClickShow(data)}
              tooltip="문자내용 보기"
            />
            {/* <div style={{ width: "2rem" }}></div>
            <IconButton
              type="download_small"
              onClick={() => this.onClickDownload(data)}
              tooltip="수신자 목록 다운로드"
            /> */}
          </div>
        </CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "전송 일시", width: "11%" },
    { title: "발신 지점", width: "11%" },
    { title: "담당자", width: "11%" },
    { title: "발송 구분", width: "7%" },
    { title: "문자 타입", width: "7%" },
    { title: "발송 정보", width: "7%" },
    { title: "제목", width: "21%" },
    { title: "전송", width: "5%" },
    { title: "성공", width: "5%" },
    { title: "실패", width: "5%" },
    { title: "", width: "6%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
