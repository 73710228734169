import { generateObjectZeroId } from "components/CommonLib/CommonLib";
import { create } from "zustand";

// 검색 조건 베이스
const baseSearchParams = {
  branch_info: { branch_id: generateObjectZeroId(), branch_name: "" },
  created_at_from: "",
  created_at_to: "",
  reg_type: "",
  mbshp_option: "",
};

export const useMemberLocationStore = create((set) => ({
  // ========== State ===========
  // 회원 위치 통계 검색 State
  memberLocationSearchParams: {
    ...baseSearchParams,
  },
  memberLocationPage: 1,

  // ========== Action ==========
  // 회원 위치 통계 검색 조건 변경
  setSearchParams: (key, value) => {
    set((state) => ({
      memberLocationSearchParams: {
        ...state.memberLocationSearchParams,
        [key]: value,
      },
    }));
  },

  // Page 값 변경
  setPage: (value) => {
    set({ memberLocationPage: value });
  },

  // 검색 조건 리셋
  resetSearchParams: () => {
    set({
      memberLocationSearchParams: {
        ...baseSearchParams,
      },
    });
  },
}));
