import { useState } from "react";
import { useDispatch } from "react-redux";

import PageFrame from "components/Panels/PageFrame";
import ERPSetting from "./ERPSetting";

import { dataEmpty } from "components/CommonLib/CommonLib";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import ERPSetIndex from "./Modals/ERPSetIndex";

const SettingWrapper = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const storesDispatch = useDispatch();

  const openModal = (select, param) => {
    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,

        Content: <ERPSetIndex modalSelect={select} modalParam={param} onModalDone={onModalDone} />,
      }),
    );
  };

  const onModalDone = (refreshStore) => {
    storesDispatch(globalModalOff());
    if (!dataEmpty(refreshStore)) {
      refreshStore();
    }
  };

  const onClickOpenModal = (order, param) => {
    if (order !== "") {
      // lockerModelRef.current.setOpen();

      openModal(order, param);
    }
  };

  return (
    <PageFrame
      children={
        <div>
          {/* <PageTitle titleText="ERP 설정" /> */}
          <ERPSetting
            onClickOpenModal={onClickOpenModal}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
          />
        </div>
      }
    />
  );
};
export default SettingWrapper;
