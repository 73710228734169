import { create } from "zustand";

export const useClassManageStore = create((set) => ({
  // ========== State ===========
  // OT, PT, GX Loading State
  classManageLoading: false,

  // ========== Action ==========
  // Loading state 값 변경
  setClassManageLoading: (bool) => {
    set({ classManageLoading: bool });
  },
}));
