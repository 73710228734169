import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, getDefaultValue, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import TextField from "components/LabelInput/TextField";
import _ from "lodash";
import { useState } from "react";
import { searchMemberParamInit } from "./Module/reducers/member_search_param";

import CustomPagination from "components/CustomPagination/CustomPagination";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const SearchMemberInBranch = ({ modalParam, onModalDone }) => {
  //modalParam으로 branch_info 받아오기 (필수!)

  const labelWidth = "4.5rem";
  const compWidth = "10rem";

  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const [searchParam, setSearchParam] = useState(_.cloneDeep(searchMemberParamInit));
  const [searchResult, setSearchResult] = useState([]);
  const [curPage, setCurPage] = useState();
  const [totalPage, setTotalPage] = useState(1);
  const [phone, setPhone] = useState({
    p1: "",
    p2: "",
    p3: "",
  });
  const [loading, setLoading] = useState(false);

  //검색버튼 누르면 searchParam으로 검색요청
  const onClickSearch = async (page) => {
    try {
      // 전화번호 중간 자리 미입력 시 alert 띄움
      if (searchParam.base_info.phone === "err") {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: <Label labelText="전화번호 검색 조건이 잘못되었습니다." />,
            onBtnOk: () => {},
          }),
        );
        return;
      }

      setCurPage(page);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const formData = new FormData();
      formData.append("page_num", page);
      searchParam.branch_info = modalParam.branch_info;
      formData.append("search_param", JSON.stringify(searchParam));

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_USER_SERVER + "/member/search_modal",
        formData,
        config,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        setSearchResult(res.data.data);
        setTotalPage(res.data.total_page);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  // 전화번호 입력 이벤트 (모든 칸)
  const onInputPhone = () => {
    let res = "";

    if (phone.p1 && !phone.p2 && phone.p3) {
      res = "err";
    } else {
      res = phone.p1 + "-" + phone.p2 + "-" + phone.p3;
    }
    res = res.replace("--", "-");

    setSearchParam({
      ...searchParam,
      base_info: {
        phone: res,
      },
    });
  };

  const getMemberData = (data) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        reqConfirm: true,
        bodyText: (
          <>
            <Label labelText={`${data.member_info.member_name} 을 선택하시겠습니까?`} />
          </>
        ),
        // width: "50rem",
        onBtnYes: () => {
          onModalDone(data);
        },
        onBtnNo: () => {},
      }),
    );
  };

  useEffect(() => {
    if (curPage) {
      onClickSearch(curPage);
    }
  }, [curPage]);

  const searchResultList = new searchResultTable(getMemberData);

  return (
    <div>
      {loading && <Loading />}

      <div style={{ display: "flex" }}>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          disabled
          labelText="지점"
          defaultValue={modalParam.branch_info.branch_name}
        />
        <ComboBox
          marginLeft="1.5rem"
          labelWidth={labelWidth}
          comboItemWidth={compWidth}
          labelText="회원구분"
          data={["전체", "회원", "고객"]}
          defaultValue={getDefaultValue("combo", searchParam.member_type)}
          onChangeCallback={(e) => {
            setSearchParam({
              ...searchParam,
              member_type: e.target.value === "전체" ? "" : e.target.value,
            });
          }}
        />
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          marginLeft="1.5rem"
          labelText="카드번호"
          onChangeCallback={(e) => {
            setSearchParam({
              ...searchParam,
              access: {
                card_no: e,
              },
            });
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") onClickSearch(1);
          }}
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.7rem" }}>
        <TextField
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          labelText="이름"
          onChangeCallback={(e) => {
            setSearchParam({
              ...searchParam,
              member_info: {
                member_name: e,
                member_no: searchParam.member_info.member_no,
              },
            });
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") onClickSearch(1);
          }}
        />
        <TextField
          marginLeft="1.5rem"
          labelWidth={labelWidth}
          textBoxWidth={compWidth}
          labelText="회원번호"
          onChangeCallback={(e) => {
            setSearchParam({
              ...searchParam,
              member_info: {
                member_name: searchParam.member_info.member_name,
                member_no: e,
              },
            });
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") onClickSearch(1);
          }}
        />
        <div style={{ display: "flex" }}>
          <TextField
            labelWidth={labelWidth}
            textBoxWidth={"4rem"}
            textMarginRight="0.25rem"
            marginLeft="1.5rem"
            labelText="연락처"
            maxLength={3}
            onChangeCallback={(e) => {
              phone.p1 = e;
              onInputPhone();
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") onClickSearch(1);
            }}
          />
          <TextField
            textMarginRight="0.25rem"
            labelMarginRight="0.25rem"
            labelWidth="0.5rem"
            labelText="-"
            maxLength={4}
            textBoxWidth={"4rem"}
            onChangeCallback={(e) => {
              phone.p2 = e;
              onInputPhone();
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") onClickSearch(1);
            }}
          />
          <TextField
            labelMarginRight="0.25rem"
            labelWidth="0.5rem"
            labelText="-"
            maxLength={4}
            textBoxWidth={"4rem"}
            onChangeCallback={(e) => {
              phone.p3 = e;
              onInputPhone();
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") onClickSearch(1);
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "0.7rem",
          marginRight: "0.625rem",
        }}
      >
        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={() => onClickSearch(1)}
          style={{ marginLeft: "11.5rem" }}
        >
          검색
        </Button>
      </div>
      <div style={{ marginTop: "1rem" }}>
        <CustomTable
          columns_head={searchResultList.columns_head}
          table_title={searchResultList.table_title}
          rest_call={searchResultList.get_data_from_rest}
          row_render={searchResultList.create_table}
          rest_data={searchResult}
        />
        <CustomPagination curPage={curPage} doSearch={setCurPage} totalCnt={totalPage} />
      </div>
    </div>
  );
};

export default SearchMemberInBranch;

class searchResultTable {
  table_title = "";
  getMemberData = null;

  constructor(getMemberData) {
    this.getMemberData = getMemberData;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow
        key={idx}
        onClick={() => {
          this.getMemberData(data);
        }}
        hover
      >
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.member_type}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.access.card_no}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_name}</CustomTableContents>
        <CustomTableContentsEND align="center">{data.base_info.phone}</CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "지점", width: "15%" },
    { title: "구분", width: "15%" },
    { title: "회원번호", width: "15%" },
    { title: "카드번호", width: "15%" },
    { title: "회원명", width: "15%" },
    { title: "연락처", width: "25%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
