import IconButton from "components/Button/IconButton";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";

const RefundListTable = ({ viewData }) => {
  const resultTableInst = new resultTable();

  const getTotalCnt = () => {
    const countedId = [];
    let cnt = 0;
    for (let i = 0; i < viewData.length; i++) {
      if (countedId.findIndex((x) => x === viewData[i].member_info.member_no) === -1) {
        countedId.push(viewData[i].member_info.member_no);
        cnt++;
      }
    }
    return cnt;
  };

  const getTotalPrice = () => {
    let price = 0;
    for (let i = 0; i < viewData.length; i++) {
      for (let j = 0; j < viewData[i].product_list.length; j++) {
        price += viewData[i].product_list[j].refund_price;
      }
    }

    return price;
  };

  return (
    <div style={{ marginTop: "0.5rem" }}>
      <div style={{ display: "flex", marginBottom: "0.5rem" }}>
        <IconButton type="border" />
        <Label labelText="환불 목록" marginLeft="0.5rem" fontWeight="bold" />
      </div>
      <CustomTable
        columns_head={resultTableInst.columns_head}
        table_title={resultTableInst.table_title}
        rest_call={resultTableInst.get_data_from_rest}
        row_render={resultTableInst.create_table}
        rest_data={viewData}
        height="18rem"
      />
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem" }}>
        <TextField
          labelText="총 인원"
          labelWidth="4.5rem"
          textBoxWidth="10rem"
          textMarginRight="0rem"
          isNumber
          defaultValue={getTotalCnt().toLocaleString()}
          endAdornment={"명"}
          disabled
        />
        <TextField
          marginLeft="4rem"
          labelText="총 금액"
          labelWidth="4.5rem"
          textBoxWidth="10rem"
          textMarginRight="0rem"
          isNumber
          defaultValue={getTotalPrice().toLocaleString()}
          endAdornment={"원"}
          disabled
        />
      </div>
    </div>
  );
};
export default RefundListTable;

class resultTable {
  table_title = "";

  // constructor() {}
  get_refund_total = (data) => {
    let totalRefund = 0;
    for (let i = 0; i < data.product_list.length; i++) {
      totalRefund += data.product_list[i].refund_price;
    }
    return totalRefund.toLocaleString();
  };
  create_table = (data, idx) => {
    return (
      <CustomRow style={{}} key={idx}>
        <CustomTableContents>{data.member_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_name}</CustomTableContents>
        <CustomTableContents>{data.reg_date}</CustomTableContents>
        <CustomTableContents $isNumber>{this.get_refund_total(data)}</CustomTableContents>
        <CustomTableContents>{data.reg_info.reg_name}</CustomTableContents>
        <CustomTableContents>{data.reason}</CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "회원번호", width: "16.6%" },
    { title: "이름", width: "16.6%" },
    { title: "환불일", width: "16.6%" },
    { title: "정산금액", width: "16.6%" },
    { title: "담당자", width: "16.6%" },
    { title: "비고", width: "16.6%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
