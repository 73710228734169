import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";

const DraftInfoButtons = ({ onClickRetrieve, baseState, onClickSave }) => {
  const btnStyleClass = btnStyles();

  if (baseState.approval_status === "승인대기") {
    return (
      <>
        <Button
          style={{ marginLeft: "0.5rem" }}
          className={clsx({
            [btnStyleClass.btnRootRaw]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={onClickRetrieve}
        >
          회수
        </Button>
      </>
    );
  } else if (
    baseState.approval_status === "임시저장" ||
    baseState.approval_status === "반려" ||
    baseState.approval_status === "회수"
  ) {
    if (baseState.approval_status === "반려" || baseState.approval_status === "회수") {
      return (
        <>
          <Button
            style={{ marginLeft: "0.5rem" }}
            className={clsx({
              [btnStyleClass.btnRootRaw]: true,
              [btnStyleClass.buttonType4]: true,
            })}
            onClick={() => onClickSave("재기안")}
          >
            재기안
          </Button>
        </>
      );
    } else {
      return <></>;
    }
  }
};
export default DraftInfoButtons;
