import IconButton from "components/Button/IconButton";
import CardFixed from "components/Card/CardFixed";
import { generateObjectZeroId, getDefaultValue } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import SearchCard from "pages/CommonModal/Search/SearchCard";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const AssignDstAccessInfo = ({
  addPayInfoState,
  assigner,
  assignee,
  assignViewState,
  accessMethod,
  setAccessMethod,
  serviceViewState,
}) => {
  const storesDispatch = useDispatch();
  const [accessMethodList, setAccessMethodList] = useState([]);

  // 출입 방식 콤보 리스트
  const initAccessMethodList = () => {
    // 관리자만 신규 카드발급 가능
    // if (isAdmin) {
    //   setAccessMethodList(["신규 카드발급"]);
    // } else {
    //   setAccessMethodList([]);
    // }
    setAccessMethodList(["신규 카드발급"]);

    // 양수자가 신규 생성 유저 X, 카드나, 앱 출입이 가능 할 때
    if (
      assignee.member_id !== generateObjectZeroId() &&
      (assignee.card_param.access_method === "카드" || assignee.card_param.access_method === "앱")
    ) {
      setAccessMethodList((prev) => {
        return [...prev, "양수자 출입방식 유지"];
      });
    }
    // 양도자가 카드가 있는 경우
    if (assigner.card_param.access_method === "카드") {
      setAccessMethodList((prev) => {
        return [...prev, "양도자 카드사용"];
      });
    }

    // 양수자가 신규 생성 유저 또는 카드가 있는 경우
    if (
      assignee.member_id === generateObjectZeroId() ||
      assignee.card_param.access_method === "카드"
    ) {
      setAccessMethodList((prev) => {
        return ["앱 출입", ...prev];
      });
    }
  };

  //지점 카드검색 팝업 (신규회원일때만!)
  const onSearchCardCallback = (data) => {
    assignViewState.dst_option.new_card_param.access_method = "카드";
    assignViewState.dst_option.new_card_param.card_info_id = data.card_info_id;
    assignViewState.dst_option.new_card_param.branch_info = data.branch_info;
    assignViewState.dst_option.new_card_param.card_no = data.card_no;
    assignViewState.dst_option.new_card_param.price = assignViewState.assign_price.new_card_price;

    setAccessMethod((cur) => {
      return { ...cur, cardNum: data.card_no };
    });
    storesDispatch(globalModalOff());
  };

  const onSearchCard = () => {
    const param = {
      title: "카드검색",
      mode: "search",
      branch_id: assignee.branch_info.branch_id,
      searchCallback: onSearchCardCallback,
    };
    storesDispatch(
      globalModalOn({
        show: true,
        title: "회원검색",
        Content: <SearchCard modalParam={param} onModalDone={onSearchCardCallback} />,
      }),
    );
  };

  //콤보 사용방법 바뀔때마다..!
  const onCheckMethod = (method) => {
    if (method === "양도자 카드사용") {
      setAccessMethod({ method, cardNum: "" });
      const filterd = serviceViewState.membership_data_list.filter(
        (el) =>
          el.membership_list.mbshp_type === "완납형" &&
          el.membership_list.mbshp_option === "일반권",
      );
      const isAllCheck = filterd.every((el) => el.selected === true);

      if (isAllCheck) {
        assignViewState.src_member_info.is_assign_card = true;
        assignViewState.dst_member_info.is_use_my_card = false;
        assignViewState.dst_option.is_new_card = false;
        assignViewState.dst_option.is_use_app = false;
        setAccessMethod({ method, cardNum: assigner.card_param.card_no });
      } else {
        //경고팝업 + accessMethod 초기화
        return storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label
                  labelText={`회원권을 모두 양도 받는 경우에만, 양도자 카드 사용이 가능합니다. `}
                />
                <Label labelText={`양도자 카드를 사용하시려면, 회원권을 모두 선택해주세요.`} />
              </>
            ),
            onBtnOk: () => {
              setAccessMethod({ method: "", cardNum: "" });
            },
          }),
        );
      }
    } else if (method === "양수자 출입방식 유지") {
      // 양수자가 카드 출입 유지
      if (assignViewState.dst_member_info.card_param.access_method === "카드") {
        assignViewState.src_member_info.is_assign_card = false;
        assignViewState.dst_member_info.is_use_my_card = true;
        assignViewState.dst_option.is_new_card = false;
        assignViewState.dst_option.is_use_app = false;
        setAccessMethod({ method, cardNum: assignee.card_param.card_no });
      }
      // 양수자가 앱 출입 유지
      if (assignViewState.dst_member_info.card_param.access_method === "앱") {
        assignViewState.src_member_info.is_assign_card = false;
        assignViewState.dst_member_info.is_use_my_card = false;
        assignViewState.dst_option.is_new_card = false;
        assignViewState.dst_option.is_use_app = true;
        setAccessMethod({ method, cardNum: "" });
      }
    } else if (method === "신규 카드발급") {
      assignViewState.src_member_info.is_assign_card = false;
      assignViewState.dst_member_info.is_use_my_card = false;
      assignViewState.dst_option.is_new_card = true;
      assignViewState.dst_option.is_use_app = false;
      setAccessMethod({ method, cardNum: "" });
    } else if (method === "앱 출입") {
      assignViewState.src_member_info.is_assign_card = false;
      assignViewState.dst_member_info.is_use_my_card = false;
      assignViewState.dst_option.is_new_card = false;
      assignViewState.dst_option.is_use_app = true;
      setAccessMethod({ method, cardNum: "" });
    } else {
      console.log("option4");
    }
  };

  //출입방식이 바뀔때마다 실행됨
  useEffect(() => {
    if (accessMethod.method === "신규 카드발급") {
      addPayInfoState(
        "카드재발급",
        assignViewState.assign_price.new_card_price,
        4,
        "카드재발급/없음/없음/없음/없음",
      );
    } else {
      addPayInfoState("카드재발급", 0, 4);
    }
  }, [accessMethod]);

  // 출입 방식 콤보 리스트 세팅
  useEffect(() => {
    if (assignViewState.dst_member_info.member_id) {
      initAccessMethodList();
    }
  }, [assignViewState, assignViewState.dst_member_info.branch_info.branch_id]);

  // 처음 출입방식 앱 출입 or 양수자 출입방식 유지 세팅
  const getDefaultAccessMethod = (accessMethodList) => {
    // 양수자가 "카드" or 신규
    if (accessMethodList.includes("앱 출입")) {
      onCheckMethod("앱 출입");
      return;
    }
    // 양수자가 "앱" O
    if (
      assignViewState.dst_member_info.card_param.access_method === "앱" &&
      accessMethodList.includes("양수자 출입방식 유지")
    ) {
      onCheckMethod("양수자 출입방식 유지");
    }
  };

  // 처음 출입방식 Default값 세팅
  useEffect(() => {
    getDefaultAccessMethod(accessMethodList);
  }, [accessMethodList]);

  return (
    <div>
      <CardFixed
        topDom={<div>양수자 출입방식</div>}
        midDom={
          <div style={{ display: "flex", marginTop: "1rem" }}>
            <ComboBox
              required
              comboItemWidth={"11rem"}
              data={accessMethodList}
              defaultValue={getDefaultValue("combo", accessMethod.method)}
              onChangeCallback={(e) => {
                onCheckMethod(e.target.value);
              }}
            />
            <TextField
              labelWidth={"0rem"}
              textBoxWidth={"8rem"}
              labelText=""
              disabled
              textMarginRight={"0.3rem"}
              defaultValue={accessMethod.cardNum}
            />
            <IconButton
              type="search"
              width="2.2rem"
              cursor="pointer"
              onClick={() => {
                onSearchCard();
              }}
              disabled={accessMethod.method !== "신규 카드발급"}
            />
          </div>
        }
        isLoading={false}
        rootStyle={{
          margin: "1rem",
          borderRadius: "10px",
          backgroundColor: "#fff",
          border: "1px solid #e8ebf2",
          background:
            "transparent linear-gradient(117deg, #e8ebf2 0%, #f2f3f7 100%) 0% 0% no-repeat padding-box",
        }}
      />
    </div>
  );
};
export default AssignDstAccessInfo;
