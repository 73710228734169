import * as ActionTypes from "../actions/action_type";
import { memberInitPurchaseState } from "./member_purchase_state";
const initialDetailStete = {
  stores: {
    data: memberInitPurchaseState,
  },
};
export const member_purchase_detail_state = (state = initialDetailStete, action) => {
  switch (action.type) {
    case ActionTypes.getPurchaseDetailModalSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getPurchaseDetailModalFailAction:
    case ActionTypes.getPurchaseDetailModalResetAction:
      return { ...state, stores: initialDetailStete.stores };
    default:
      return state;
  }
};
