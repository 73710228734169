import IconButton from "components/Button/IconButton";
import TextField from "components/LabelInput/TextField";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import PreMembershipStatus from "./PreMembershipStatus";
import SubsMembershipStatus from "./SubsMembershipStatus";

const MembershipStatus = ({
  selectedItem,
  popPostponeModal,
  popChangeFromDateModal,
  subState,
  isServiceLock,
}) => {
  const labelWidth = "3.5rem";
  const textBoxWidth = "7.7rem";
  // const checkDisabledStatus = () => {
  //   if (selectedItem.mbshp_option === "일일권") {
  //     return true; //비활성
  //   }
  //   if (selectedItem.mbshp_status === "수강" && selectedItem.from_date === dateToStr(new Date())) {
  //     return false; //활성
  //   } else if (selectedItem.mbshp_status === "대기") {
  //     return false; //활성
  //   } else {
  //     return true; //비활성
  //   }
  // };

  return (
    <>
      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="상세 정보" />
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <TextField
          labelMarginLeft="1rem"
          labelWidth={"3.5rem"}
          textMarginRight="0.25rem"
          textBoxWidth={textBoxWidth}
          labelText="유효기간"
          defaultValue={selectedItem.from_date}
          disabled
        />
        <TextField
          labelWidth={"0.5rem"}
          textBoxWidth={textBoxWidth}
          labelMarginRight="0.25rem"
          textMarginRight="0.25rem"
          labelText="~"
          defaultValue={selectedItem.to_date}
          disabled
        />
        <div style={{ display: "flex", gap: "0.25rem" }}>
          <NormalBtn
            width="4.25rem"
            name="연기"
            // disabled={isServiceLock}
            onClick={() => popPostponeModal()}
          />
          <NormalBtn
            width="6.5rem"
            name="시작일 변경"
            disabled={isServiceLock}
            onClick={() => popChangeFromDateModal()}
          />
        </div>
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <div style={{ width: "43%" }}>
          <TextField
            labelMarginLeft="1rem"
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth}
            labelText="종류"
            defaultValue={selectedItem.mbshp_type}
            disabled
          />
          <TextField
            labelMarginLeft="1rem"
            marginTop="0.5rem"
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth}
            labelText="옵션"
            defaultValue={selectedItem.mbshp_option}
            disabled
          />
          <TextField
            labelMarginLeft="1rem"
            marginTop="0.5rem"
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth}
            labelText="상태"
            defaultValue={selectedItem.mbshp_status}
            disabled
          />
        </div>
        <div style={{ width: "57%" }}>
          {selectedItem.mbshp_type === "완납형" ? (
            <PreMembershipStatus
              // membershipItemInfo={membershipItemInfo}
              selectedItem={selectedItem}
            />
          ) : (
            <SubsMembershipStatus
              // membershipItemInfo={membershipItemInfo}
              selectedItem={selectedItem}
              subState={subState}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default MembershipStatus;
