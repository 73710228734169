export const otSearchParamInit = {
  sort_type: "",
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  manager_info: {
    assign_date_use: false,
    assign_from_date: "",
    assign_to_date: "",
    is_resigned: false,
    staff_info: {
      staff_id: "",
      staff_name: "",
      staff_pos: "",
      staff_rank: "",
    },
  },
  member_base: {
    member_name: "",
    member_no: "",
    phone: "",
  },
  membership: {
    mbshp_date_use: false,
    mbshp_from_date: "",
    mbshp_join_type: "",
    mbshp_paid_from_date: "",
    mbshp_paid_to_date: "",
    mbshp_to_date: "",
  },

  ot_info: {
    class_status: "",
    is_assigned: "",
    is_suggest_pt: "",
    remain_cnt_filter: false,
    root_status: ["수강", "연기", "대기", "소진완료", "만료"],
  },
  staff_text_name: "",
  view_info: {
    branch_list: [
      {
        branch_id: "",
        branch_name: "",
      },
    ],
    branch_name_list: [],
  },
};

export const otDetailInit = {
  alloc_date: "",
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  cur_manager: {
    assign_date: "",
    is_assigned: false,
    is_resigned: false,
    staff_info: {
      staff_id: "",
      staff_name: "",
      staff_pos: "",
      staff_rank: "",
    },
  },
  hq_info: {
    hq_id: "",
    hq_name: "",
  },
  is_use: false,
  membership_info: {
    birth: "",
    birth_info: {
      day: 0,
      month: 0,
      year: 0,
    },
    from_date: "",
    gender: "",
    is_new: false,
    item_name: "",
    mbshp_option: "",
    mbshp_type: "",
    member_id: "",
    member_name: "",
    member_no: "",
    membership_id: "",
    phone: "",
    to_date: "",
  },
  ot_class: [
    {
      class_done_date: "",
      class_done_time: "",
      class_status: "",
      comment: "",
      is_suggest_pt: false,
      membership_id: "",
      memo: "",
      no: 0,
      staff_info: {
        staff_id: "",
        staff_name: "",
        staff_pos: "",
        staff_rank: "",
      },
    },
  ],
  ot_detail_id: "",
  ot_info: {
    class_status: "",
    comment: "",
    is_suggest_pt: false,
    latest_contact: "",
    latest_reserv_date: "",
    mbshp_paid_date: "",
    ot_session_cnt: 0,
    proceed_class_lock: false,
    remain_session_cnt: 0,
    root_status: "",
    service_status: "",
    suggest_date: "",
  },
  reg_info: {
    created_at: "",
    reg_id: "",
    reg_name: "",
    upd_id: "",
    upd_name: "",
    updated_at: "",
  },
  remarks: "",
  service_session_hist: [
    {
      add_session: 0,
      comment: "",
      reg_info: {
        created_at: "",
        reg_id: "",
        reg_name: "",
        upd_id: "",
        upd_name: "",
        updated_at: "",
      },
    },
  ],
};
