import { contentTypeJson } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

// [GET] 회원권 실적 내역 리스트 검색
export async function getStaffList(queryData) {
  const { isExcel, page, searchParams } = queryData;
  const formData = new FormData();
  formData.append("is_excel", isExcel);
  formData.append("page_num", page);
  formData.append("search_param", JSON.stringify(searchParams));

  return await HttpInstance.post(
    process.env.REACT_APP_STAFF_SERVER + "/staff/search_list",
    formData,
    contentTypeJson,
  );
}
