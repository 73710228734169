import { contentTypeJson } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

// ==================== GET ====================
// [GET] 출입문 모니터링 검색 조건 받아오기 및 권한 데이터 세팅
export async function getAccessMonitorSearchParam() {
  return await HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/access/search_selector", {
    params: {},
  });
}

// [GET] 출입 이력 리스트 검색
export async function getAccessHistList(queryData) {
  const { page, isExcel, searchParams } = queryData;
  const formData = new FormData();

  formData.append("is_excel", isExcel ? isExcel : false);
  formData.append("page_num", page);
  formData.append("search_param", JSON.stringify(searchParams));

  return await HttpInstance.post(
    process.env.REACT_APP_BRANCH_SERVER + "/access/search_access_history",
    formData,
    contentTypeJson,
  );
}
// [GET] 문열림 기능 사용 이력 리스트 검색
export async function getDoorHistList(queryData) {
  const { page, isExcel, searchParams } = queryData;
  const formData = new FormData();

  formData.append("is_excel", isExcel ? isExcel : false);
  formData.append("page_num", page);
  formData.append("search_param", JSON.stringify(searchParams));

  return await HttpInstance.post(
    process.env.REACT_APP_BRANCH_SERVER + "/access/search_door_history",
    formData,
    contentTypeJson,
  );
}
