import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import ApprovalListView from "./ApprovalListView";

const ApprovalListViewService = () => {
  return (
    <div>
      <PageFrame
        menuActive="락커/셋팅"
        parentMenu={{ 락커: true }}
        children={
          <div>
            <PageTitle titleText="전자결재" />
            <ApprovalListView />
          </div>
        }
      />
    </div>
  );
};
export default ApprovalListViewService;
