import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import {
  dataEmpty,
  generateObjectZeroId,
  isEmptyObj,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import CheckBox from "components/LabelInput/CheckBox";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCouponBenefit } from "./CommonFunc";

const SelectCouponModal = ({ modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const [couponNo, setCouponNo] = useState("");
  const [memberCouponList, setMemberCouponList] = useState([]);
  const [checkState, setCheckState] = useState([]);
  const [selectedMbshp, setSelectedMbshp] = useState();

  useEffect(() => {
    setMemberCouponList(modalParam.coupon_list);
  }, [modalParam.coupon_list]);

  // radio check
  const onChangeCheckState = (idx) => {
    setCheckState(checkState.map((item, i) => i === idx));
  };

  const btnApply = () => {
    const selectedIdx = memberCouponList.findIndex((x) => x.is_selected);
    if (selectedIdx === -1) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="선택된 쿠폰이 없습니다." />,
          onBtnOk: () => {},
        }),
      );
    } else {
      onModalDone("쿠폰", memberCouponList[selectedIdx], selectedMbshp);
    }
  };

  const btnCancel = () => {
    onModalDone();
  };

  const onClickPushCoupon = () => {
    if (!couponNo) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="쿠폰번호를 입력해주세요." />,
          onBtnOk: () => {},
        }),
      );
      return;
    }

    HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member/register/coupon_find", {
      params: {
        ticket_id: couponNo,
        branch_id: modalParam.branch_id,
      },
    })
      .then((res) => {
        // console.log(res);
        AddCoupon(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const AddCoupon = (data) => {
    if (data.coupon_id === generateObjectZeroId()) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="존재하지 않는 쿠폰입니다." />,
          onBtnOk: () => {},
        }),
      );
    } else if (data.base_info.benefit_type === "구매가능") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          width: "25rem",
          bodyText: (
            <>
              <Label labelText="'구매가능' 종류의 쿠폰은 구매 시 사용할 수 없습니다." />
              <br />
              <Label labelText="회원정보 페이지에서 등록해 주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else {
      data.is_selected = false;
      data.ticket_id = couponNo;
      const cpList = [...memberCouponList];
      cpList.splice(memberCouponList.length, 0, data);
      setMemberCouponList(cpList);
      onChangeCheckState(0);

      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="등록되었습니다." />,
          onBtnOk: () => {},
        }),
      );
      setCouponNo("");
    }
  };

  const onRowClick = (row) => {
    // console.log(row);
  };

  const couponTableData = new couponTable(
    onRowClick,
    modalParam.tabNameList,
    setSelectedMbshp,
    getCouponBenefit,
    checkState,
    onChangeCheckState,
  );

  return (
    <div
      style={{
        width: "84rem",
        display: "block",
      }}
    >
      <div>
        <div style={{ marginTop: "1rem", marginBottom: "0.625rem" }}>
          <Label labelText="쿠폰 현황" fontWeight="bold" justifyContent="left" fontSize="1rem" />
          <div style={{ display: "flex", marginTop: "1rem" }}>
            <TextField
              textMarginRight="0.25rem"
              labelWidth="4rem"
              textBoxWidth="12rem"
              labelText="쿠폰번호"
              defaultValue={couponNo}
              onChangeCallback={(e) => {
                setCouponNo(e);
              }}
            />
            <Button
              className={clsx({
                [btnStyleClass.btnRootWhite]: true,
                [btnStyleClass.buttonType5]: true,
              })}
              onClick={() => onClickPushCoupon()}
            >
              등록
            </Button>
          </div>
        </div>
      </div>
      <div>
        <CustomTable
          columns_head={couponTableData.columns_head}
          table_title={couponTableData.table_title}
          rest_call={couponTableData.get_data_from_rest}
          row_render={couponTableData.create_table}
          rest_data={memberCouponList}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1.844rem",
          }}
        >
          <Button
            className={clsx({
              [btnStyleClass.btnRootWhite]: true,
              [btnStyleClass.buttonType6]: true,
            })}
            onClick={btnCancel}
          >
            취소
          </Button>
          <Button
            style={{ marginLeft: "0.5rem" }}
            className={clsx({
              [btnStyleClass.btnRootRaw]: true,
              [btnStyleClass.buttonType6]: true,
            })}
            onClick={btnApply}
          >
            저장
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SelectCouponModal;

class couponTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onRowClick = null;
  mbshpTabList = null;
  setSelectedItem = null;
  getCouponBenefit = null;
  checkState = null;
  onChangeCheckState = null;

  constructor(
    onRowClick,
    mbshpTabList,
    setSelectedItem,
    getCouponBenefit,
    checkState,
    onChangeCheckState,
  ) {
    this.onRowClick = onRowClick;
    this.mbshpTabList = mbshpTabList;
    this.setSelectedItem = setSelectedItem;
    this.getCouponBenefit = getCouponBenefit;
    this.checkState = checkState;
    this.onChangeCheckState = onChangeCheckState;
  }

  create_table = (data, idx) => {
    // radio check
    if (this.checkState[idx] === undefined) {
      this.checkState[idx] = data.is_selected;
    } else {
      data.is_selected = this.checkState[idx];
    }

    if (isEmptyObj(data)) return <></>;
    return (
      <CustomRow style={{}} key={idx} onClick={() => this.onRowClick(data)} hover>
        <CustomTableContents width="12%">
          <CheckBox
            isTable
            defaultValue={this.checkState[idx]}
            onChangeCallback={(e) => {
              if (!e.checked) {
                e.checked = true;
              } else {
                if (this.onChangeCheckState(idx)) {
                  data.is_selected = e.checked;
                } else {
                  e.checked = false;
                }
              }
            }}
          />
        </CustomTableContents>
        <CustomTableContents width="12%">{data.base_info.benefit_type}</CustomTableContents>
        <CustomTableContents width="12%">{data.detail.coupon_name}</CustomTableContents>
        <CustomTableContents width="12%">{this.getCouponBenefit(data)}</CustomTableContents>
        <CustomTableContents width="12%">
          {data.detail.service_benefit.branch_info.branch_name}
        </CustomTableContents>
        <CustomTableContents width="12%">
          {data.base_info.expired_from_date} ~ {data.base_info.expired_to_date}
        </CustomTableContents>
        <CustomTableContentsEND align="center" width="12%">
          <ComboBox
            required
            labelWidth={"2.625rem"}
            comboItemWidth={"10rem"}
            data={this.mbshpTabList || ["신규"]}
            defaultValue={this.mbshpTabList ? "" : "신규"}
            onChangeCallback={(e) => {
              this.setSelectedItem(e.target.value);
            }}
          />
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "선택", field: "pay_method" },
    { title: "쿠폰 종류", field: "card_comp" },
    { title: "쿠폰명", field: "installment_plan" },
    { title: "혜택", field: "pay_receipt" },
    { title: "지점", field: "price" },
    { title: "유효기간", field: "pay_date" },
    { title: "적용 회원권 선택", field: "action" },
  ];
  get_data_from_rest = (data) => {
    if (dataEmpty(data)) {
      return [];
    }
    return data;
  };
}
