export const cardBulkUpdate = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/access/card_bulk_update";
};
export const cardAdd = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/access/add_card";
};
export const cardInfoUpdate = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/access/card_info_update";
};

export const cardManageList = () => {
  return process.env.REACT_APP_BRANCH_SERVER + "/access/card_manage_list";
};

const post_api = {
  cardBulkUpdate,
  cardInfoUpdate,
  cardAdd,
  cardManageList,
};

export default post_api;
