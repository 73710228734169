import { useEffect, useState } from "react";

import { btnStyles } from "components/ButtonStyle/ButtonStyle";

import RegisterPhoneCheckModal from "./RegisterPhoneCheckModal";
// import RegisterFriendModal from "./RegisterFriendModal";
import * as lodash from "lodash";
import SearchCard from "pages/CommonModal/Search/SearchCard";
import SearchMember from "pages/CommonModal/Search/SearchMember";
import PaymentModal from "pages/ERP/MemberCommonItems/PaymentModal/PaymentModal";
import SelectCouponModal from "pages/ERP/MemberCommonItems/PaymentModal/SelectCouponModal";
import SubsPayManageModal from "pages/ERP/MemberView/Modal/ChangeRefundModal/SubsPayManageModal";

const RegisterModalIndex = ({ modalSelect, modalParam, onModalDone, uploadDoneCallback }) => {
  const btnStyleClass = btnStyles();

  const [paramData, setParamData] = useState(modalParam.modalParam);

  useEffect(() => {
    const editData = lodash.cloneDeep(modalParam.modalParam);
    setParamData(editData);
    return () => {};
  }, []);

  if (modalSelect === "연락처중복") {
    return <RegisterPhoneCheckModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "지인소개") {
    return <SearchMember modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "결제") {
    return (
      <PaymentModal
        modalParam={paramData}
        onModalDone={onModalDone}
        uploadDoneCallback={uploadDoneCallback}
      />
    );
  } else if (modalSelect === "쿠폰적용") {
    return <SelectCouponModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "정기결제수단등록") {
    return <SubsPayManageModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "카드검색") {
    return <SearchCard modalParam={paramData} onModalDone={onModalDone} />;
  } else {
    return <div>current ELSE</div>;
  }
};
export default RegisterModalIndex;
