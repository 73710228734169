import * as ActionTypes from "../actions/action_type";

const initialHistoryStete = {
  stores: {
    data: [],
  },
  total_page: 0,
};
export const membership_history_state = (state = initialHistoryStete, action) => {
  switch (action.type) {
    case ActionTypes.getMembershipHistoryViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getMembershipHistoryViewFailAction:
    case ActionTypes.getMembershipHistoryViewResetAction:
      return { ...state, stores: initialHistoryStete.stores };
    default:
      return state;
  }
};

export const class_history_state = (state = initialHistoryStete, action) => {
  switch (action.type) {
    case ActionTypes.getClassHistoryViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getClassHistoryViewFailAction:
    case ActionTypes.getClassHistoryViewResetAction:
      return { ...state, stores: initialHistoryStete.stores };
    default:
      return state;
  }
};

export const purchase_history_state = (state = initialHistoryStete, action) => {
  switch (action.type) {
    case ActionTypes.getPurchaseHistoryViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getPurchaseHistoryViewFailAction:
    case ActionTypes.getPurchaseHistoryViewResetAction:
      return { ...state, stores: initialHistoryStete.stores };
    default:
      return state;
  }
};

export const cs_history_state = (state = initialHistoryStete, action) => {
  switch (action.type) {
    case ActionTypes.getCSHistoryViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getCSHistoryViewFailAction:
    case ActionTypes.getCSHistoryViewResetAction:
      return { ...state, stores: initialHistoryStete.stores };
    default:
      return state;
  }
};
