import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import CardFixed from "components/Card/CardFixed";
import { tabBorder, tabStyle } from "components/CommonComp";
import {
  checkJumin,
  contentTypeJson,
  generateObjectZeroId,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import { uploadFile } from "components/File/uploadFile";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import TabPanel from "components/TabControl/TabPanel";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import BranchWorkInfoCard from "./BranchWork/BranchWorkInfoCard";
import CommissionListCard from "./CommissionCard/CommissionListCard";
import ContentCard from "./ContentCard/ContentCard";
import HqBranchView from "./HqBranchCard/HqBranchView";
import post_api from "./Module/api/PostApi";
import PersonalInfoComp from "./PersonalCard/PersonalInfoComp";

const StaffInfoView = ({ staffInfoState, onClickOpenModal, onRefresh, hqPermissions }) => {
  const storesDispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const [loading, setLoading] = useState(false);
  const [idCardFile, setIdCardFile] = useState(""); // 주민등록등본
  const [accountFile, setAccountFile] = useState(""); // 통장사본
  const isViewMode = staffInfoState.contents.staff_obj.staff_id !== generateObjectZeroId();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // 주민등록등본, 통장사본에서 업로드 한 파일 상태 변경
  const handleFileUpload = (file, label) => {
    if (label === "주민등록등본") {
      setIdCardFile(file);
    } else if (label === "통장사본") {
      setAccountFile(file);
    }
  };

  const validation = () => {
    let res = "";
    if (staffInfoState.contents.staff_obj.affiliation.length === 0) {
      res += "· 소속정보\n";
    }
    if (!staffInfoState.contents.staff_obj.personal_info.staff_name) {
      res += "· 이름\n";
    }
    if (!staffInfoState.contents.staff_obj.personal_info.phone) {
      res += "· 연락처\n";
    }
    if (!staffInfoState.contents.staff_obj.personal_info.gender) {
      res += "· 성별\n";
    }
    if (!staffInfoState.contents.staff_obj.personal_info.birthday) {
      res += "· 생년월일\n";
    }
    if (!staffInfoState.contents.staff_obj.personal_info.address_1) {
      res += "· 주소\n";
    }
    if (!checkJumin(staffInfoState.contents.staff_obj.personal_info.social_number)) {
      res += "· 주민번호\n";
    }
    if (!staffInfoState.contents.staff_obj.personal_info.email) {
      res += "· 이메일\n";
    }
    if (!isViewMode && !staffInfoState.contents.auth_id) {
      res += "· 아이디\n";
    }
    if (!isViewMode && !staffInfoState.contents.auth_pass) {
      res += "· 비밀번호\n";
    }
    if (
      staffInfoState.contents.card_info.access_method === "카드" &&
      staffInfoState.contents.card_info.card_info_id === generateObjectZeroId()
    ) {
      res += "· 출입카드번호\n";
    }

    return res;
  };

  const onClickSave = () => {
    const validationRes = validation();
    if (validationRes !== "") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="필수값을 모두 입력해주셔야 합니다." />
              <br />
              <br />
              <Label labelText={validationRes} whiteSpace="pre-wrap" />
            </>
          ),
          // width: "50rem",
          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="저장하시겠습니까?" />,
          // width: "50rem",
          onBtnYes: () => {
            validFileUpload();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  // 테스트용 export json
  const exportData = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(staffInfoState),
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    let name = staffInfoState.contents.staff_obj.personal_info.staff_name;
    link.download = name + "_data.json";

    link.click();
  };

  // 파일 업로드 유효성 검사
  const validFileUpload = async () => {
    let idCardSuccess = true;
    let accountSuccess = true;
    // 주민등록등본 파일 서버 업로드
    if (idCardFile !== "") {
      idCardSuccess = await uploadFile(staffInfoState.contents.staff_obj.files.id_card, idCardFile);
      if (!idCardSuccess) {
        simpleAlert(storesDispatch, "알림", "주민등록등본 등록을 실패하였습니다.");
        return;
      }
    }
    // 통장사본 파일 서버 업로드
    if (accountFile !== "") {
      accountSuccess = await uploadFile(
        staffInfoState.contents.staff_obj.files.account_copy,
        accountFile,
      );
      if (!accountSuccess) {
        simpleAlert(storesDispatch, "알림", "통장사본 등록을 실패하였습니다.");
        return;
      }
    }

    if (idCardSuccess && accountSuccess) {
      // 주민등록등본 데이터 업데이트
      staffInfoState.contents.staff_obj.files.id_card.file_info.file_name = idCardFile
        ? idCardFile.name
        : staffInfoState.contents.staff_obj.files.id_card.file_info.file_name;
      staffInfoState.contents.staff_obj.files.id_card.file_info.file_type = idCardFile
        ? idCardFile.type
        : staffInfoState.contents.staff_obj.files.id_card.file_info.file_type;
      staffInfoState.contents.staff_obj.files.id_card.file_info.file_group_id =
        staffInfoState.contents.staff_obj.files.temp_group_id;

      // 통장사본 데이터 업데이트
      staffInfoState.contents.staff_obj.files.account_copy.file_info.file_name = accountFile
        ? accountFile.name
        : staffInfoState.contents.staff_obj.files.account_copy.file_info.file_name;
      staffInfoState.contents.staff_obj.files.account_copy.file_info.file_type = accountFile
        ? accountFile.type
        : staffInfoState.contents.staff_obj.files.account_copy.file_info.file_type;

      staffInfoState.contents.staff_obj.files.account_copy.file_info.file_group_id =
        staffInfoState.contents.staff_obj.files.temp_group_id;

      // 최종 정보 저장
      onSaveEvent();
    }
  };

  const onSaveEvent = async () => {
    // 앱 출입 선택 시 기존 카드번호는 제거
    if (staffInfoState.contents.card_info.access_method === "앱") {
      staffInfoState.contents.card_info.card_info_id = generateObjectZeroId();
      staffInfoState.contents.card_info.card_no = 0;
    }

    staffInfoState.contents.staff_obj.personal_info.staff_name =
      staffInfoState.contents.staff_obj.personal_info.staff_name.trim();

    const formData = new FormData();
    formData.append("new_info", JSON.stringify(staffInfoState));
    const postApi = isViewMode ? post_api.updateStaffInfo() : post_api.insertStaffInfo();

    setLoading(true);

    await HttpInstance.post(postApi, formData, contentTypeJson)
      .then((res) => {
        const resId = res.data.data;
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          storesDispatch(
            globalAlertOn({
              show: true,
              titleText: "알림",
              reqConfirm: true,
              bodyText: (
                <>
                  <Label labelText="저장되었습니다." />
                </>
              ),
              onBtnOk: () => {
                navigate("/staff_list");
              },
            }),
          );
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      {loading && <Loading />}

      <PersonalInfoComp
        staffInfoState={staffInfoState}
        onSaveEvent={onClickSave}
        onClickOpenModal={onClickOpenModal}
        handleFileUpload={handleFileUpload}
      />
      <HqBranchView
        isViewMode={isViewMode}
        staffInfoState={staffInfoState}
        onClickOpenModal={onClickOpenModal}
        hqPermissions={hqPermissions}
      />
      <CardFixed
        midDom={
          <div>
            <Box sx={{ bgcolor: "background.paper" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="inherit"
                TabIndicatorProps={{
                  style: { backgroundColor: "rgba(0, 0, 0, 0)" },
                }}
              >
                <Tab label="추가 인적사항" style={tabStyle(0, value, "9rem")} />
                <Tab label="근로정보" style={tabStyle(1, value)} />
                <Tab label="업무 및 커미션" style={tabStyle(2, value, "9rem")} />
              </Tabs>
            </Box>

            <SwipeableViews
              index={value}
              onChangeIndex={handleChangeIndex}
              style={tabBorder}
              animateTransitions={false}
            >
              <TabPanel value={value} index={0}>
                <ContentCard staffInfoState={staffInfoState.contents.staff_obj} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <BranchWorkInfoCard
                  staffInfoState={staffInfoState}
                  onClickOpenModal={onClickOpenModal}
                  onRefresh={onRefresh}
                  hqPermissions={hqPermissions}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <CommissionListCard
                  staffInfoState={staffInfoState}
                  onClickOpenModal={onClickOpenModal}
                />
              </TabPanel>
            </SwipeableViews>
          </div>
        }
        isLoading={false}
        rootStyle={{
          width: "100%",
          marginTop: "0.625rem",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />
    </div>
  );
};
export default StaffInfoView;
