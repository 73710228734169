import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import { generateObjectZeroId } from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import TextField from "components/LabelInput/TextField";
import { globalModalOff } from "modules/actions/Modal";
import PriceDiscountInput from "pages/ERP/MemberRegister/Common/Etc/PriceDiscountInput";
import { useRegisterStore } from "pages/ERP/MemberRegister/Register/modules/store/registerState";
import { useState } from "react";
import { useDispatch } from "react-redux";

const CardReissueInfo = (props) => {
  // const AssignDstAccessInfo = forwardRef((props, ref) => {
  const { payInfoListAction, onClickOpenModal, cardInfo, setCardInfo, baseInfoState } = props;
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const [disableSelectBtn, setDisableSelectBtn] = useState(false);
  // 전역 상태 관리
  const { onChangeSubmitDocState } = useRegisterStore();

  const onSearchCardCallback = (data) => {
    setCardInfo({
      ...cardInfo,
      is_use: true,
      card_no: data.card_no,
      card_info_id: data.card_info_id,
      branch_info: data.branch_info,
    });

    payInfoListAction(
      "push",
      "카드재발급",
      {
        type: "카드재발급",
        price: cardInfo.price,
        dis: 0,
        pay_order: 0,
        tab_idx: 0,
        // item_type: event.id,
        item_type: "카드재발급/없음/금액/없음/없음",
      },
      0,
      0,
    );

    storesDispatch(globalModalOff());
  };

  const onSearchCard = () => {
    const param = {
      title: "카드검색",
      modalParam: {
        mode: "search",
        searchCallback: onSearchCardCallback,
        branch_id: baseInfoState.selected_branch_id,
      },
    };
    onClickOpenModal("카드검색", param);
  };

  return (
    <div>
      <CardFixed
        topDom={<div>카드재발급</div>}
        midDom={
          <div style={{ display: "flex", alignItems: "center", height: "2.75rem" }}>
            <CheckBox
              marginTop="0rem"
              labelText="발급"
              ItemMarginLeft={"0.625rem"}
              defaultValue={cardInfo.is_use}
              onChangeCallback={(e) => {
                onChangeSubmitDocState("서비스 신청서", e.checked);
                setDisableSelectBtn(e.checked);
                if (e.checked === true) {
                  setCardInfo({
                    ...cardInfo,
                    is_use: true,
                  });
                  onSearchCard();
                } else {
                  setCardInfo({
                    ...cardInfo,
                    is_use: false,
                    card_no: "",
                    card_info_id: generateObjectZeroId(),
                  });
                  payInfoListAction("pop", "카드재발급");
                }
              }}
            />
            <TextField
              labelWidth={"4rem"}
              textBoxWidth={"6rem"}
              labelText="카드번호"
              defaultValue={cardInfo.card_no}
              disabled
            />
            <Button
              className={clsx({
                [btnStyleClass.btnRoot]: true,
                [btnStyleClass.buttonType5]: true,
                [btnStyleClass.disabled]: true,
              })}
              onClick={onSearchCard}
              disabled={!disableSelectBtn}
            >
              카드선택
            </Button>

            <PriceDiscountInput
              baseInfo={{
                tabTitle: "",
                tabIdx: 0,
                orderStartIdx: 0,
                type: "카드재발급",
                typeKey: "카드재발급",
                orderIdx: 0,
                payOrder: 6,
                item_type: "카드재발급/없음/금액/없음/없음",
              }}
              standardPrice={cardInfo.price}
              payInfoListAction={payInfoListAction}
              onChangeCallback={(e) => {
                setCardInfo({
                  ...cardInfo,
                  dis: e,
                });
              }}
              paramLock={false}
              paramUse={cardInfo.is_use}
              discountValue={cardInfo.dis}
            />
          </div>
        }
        isLoading={false}
        rootStyle={{
          width: "76.88rem",
          marginTop: "1.25rem",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />
    </div>
  );
};
export default CardReissueInfo;
