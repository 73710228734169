export const CardSearchAction = "ACCESS/CARD/SEARCH_LIST";
export const CardSearchSuccessAction = "ACCESS/CARD/SEARCH_LIST/SUCCESS";
export const CardSearchFailAction = "ACCESS/CARD/SEARCH_LIST/FAIL";
export const CardSearchResetAction = "ACCESS/CARD/SEARCH_LIST/RESET";

export const CardDetailInfoAction = "ACCESS/CARD/DETAIL_INFO";
export const CardDetailInfoSuccessAction = "ACCESS/CARD/DETAIL_INFO/SUCCESS";
export const CardDetailInfoFailAction = "ACCESS/CARD/DETAIL_INFO/FAIL";
export const CardDetailInfoResetAction = "ACCESS/CARD/DETAIL_INFO/RESET";

export const CardListAction = "ACCESS/CARD/LIST";
export const CardListSuccessAction = "ACCESS/CARD/LIST/SUCCESS";
export const CardListFailAction = "ACCESS/CARD/LIST/FAIL";
export const CardListResetAction = "ACCESS/CARD/LIST/RESET";
