import * as ActionTypes from "./action_type";

///FAMILY
export const loadFamilyModal = (member_id) => {
  return {
    type: ActionTypes.getFamilyViewAction,
    member_id: member_id,
  };
};

export const loadFamilyModalSuccess = (data) => {
  return {
    type: ActionTypes.getFamilyViewSuccessAction,
    payload: data,
  };
};

export const loadFamilyModalFail = (data) => {
  return {
    type: ActionTypes.getFamilyViewFailAction,
    payload: [],
  };
};

export const loadFamilyModalReset = (data) => {
  return {
    type: ActionTypes.getFamilyViewResetAction,
    payload: data,
  };
};

///FRIENDS
export const loadFriendModal = (member_id) => {
  return {
    type: ActionTypes.getFamilyViewAction,
    member_id: member_id,
  };
};

export const loadFriendModalSuccess = (data) => {
  return {
    type: ActionTypes.getFamilyViewSuccessAction,
    payload: data,
  };
};

export const loadFriendModalFail = (data) => {
  return {
    type: ActionTypes.getFamilyViewFailAction,
    payload: [],
  };
};

export const loadFriendModalReset = (data) => {
  return {
    type: ActionTypes.getFamilyViewResetAction,
    payload: data,
  };
};
