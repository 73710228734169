import { contentTypeJson } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

// ==================== GET ====================
// [GET] TM 디테일 리스트 검색
export async function getTMListDetail(queryData) {
  const { page, searchParams } = queryData;
  const formData = new FormData();

  formData.append("page_num", page);
  formData.append("search_param", JSON.stringify(searchParams));

  return await HttpInstance.post(
    process.env.REACT_APP_USER_SERVER + "/cs/tm/manage/search_detail",
    formData,
    contentTypeJson,
  );
}
