import IconButton from "components/Button/IconButton";
import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableCellChild,
  CustomTableCellFit,
  CustomTableContents,
} from "components/CustomTable/tableStyle";
import TextField from "components/LabelInput/TextField";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadGxSalaryViewStore } from "../Module/actions";
import { DUMM_GX } from "./Dumm";
import GxStaffBySalaryList from "./GxStaffBySalaryList";

const GxCurrentSalaryList = ({ salaryReportId, is_edit }) => {
  const storesDispatch = useDispatch();
  const [selected, setSelected] = useState({
    staffId: "",
    staffName: "",
    sabun: "",
    pId: "",
    staffSalaryId: "",
  });

  const [gxDetailArr, setGxDetailArr] = useState([]);

  const restData = useSelector((state) => state.staff_service.salary_staff_gx);

  useEffect(() => {
    storesDispatch(loadGxSalaryViewStore(salaryReportId, is_edit));
  }, [salaryReportId]);

  //gx수당 상세byRow GET
  const getGxSalaryDetailByRow = async (selectedData) => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_SALARY_SERVER + "/salary/gx/detail",
        {
          params: {
            staff_salary_id: selectedData.staffSalaryId,
            staff_id: selectedData.staffId,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        if (
          res.data.data.gx_salary.class_list &&
          res.data.data.gx_salary.class_list.length > 0 &&
          res.data.data.gx_salary.class_list[0].class_hist !== null
        ) {
          simpleAlert(
            storesDispatch,
            "알림",
            "GX 데이터 오류가 있습니다.\n급여 문서를 재생성해 주시기 바랍니다.",
          );
          return;
        }

        const result = res.data.data.gx_salary.class_list.filter(
          (el) => el.class_record_list.length > 0,
        );
        setGxDetailArr(result);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  useEffect(() => {
    if (selected.staffId) {
      getGxSalaryDetailByRow(selected);
    }
  }, [selected.staffId]);

  const onClickRow = (staffId, staffName, sabun, staffSalaryId) => {
    setSelected((cur) => {
      return { ...cur, staffId, staffName, sabun, staffSalaryId };
    });
  };

  const dataTableItem = new dataTable(setSelected, DUMM_GX, onClickRow);
  return (
    <div>
      <div>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <SubTitle titleText="GX 수당 현황" />
        </div>
      </div>
      <div style={{ marginTop: "0.7rem" }}>
        <div>
          <CustomTable
            columns_head={dataTableItem.columns_head}
            table_title={dataTableItem.table_title}
            rest_call={dataTableItem.get_data_from_rest}
            row_render={dataTableItem.create_table}
            rest_data={restData.stores.data}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.7rem" }}>
          <TextField
            labelWidth={"7rem"}
            textBoxWidth={"9rem"}
            textMarginRight="0rem"
            labelText="최종 지급액 합계"
            endAdornment={"원"}
            defaultValue={restData.stores.final_total_salary.toLocaleString()}
            isNumber
            disabled
          />
        </div>
      </div>

      {/*위에 테이블 눌러서 row별로 바뀌는 테이블 */}
      {selected.staffId && (
        <GxStaffBySalaryList
          selected={selected}
          salaryReportId={salaryReportId}
          gxDetailArr={gxDetailArr}
          getGxSalaryDetailByRow={getGxSalaryDetailByRow}
          is_edit={is_edit}
        />
      )}
    </div>
  );
};
export default GxCurrentSalaryList;

class dataTable {
  table_title = "";
  setSelected = null;
  DUMM = null;
  onClickRow = null;

  constructor(setSelected, DUMM, onClickRow) {
    this.setSelected = setSelected;
    this.DUMM = DUMM;
    this.onClickRow = onClickRow;
  }

  getChildClass = (data, idx) => {
    return (
      <CustomTableCellFit max_cnt={1}>
        {data.class_list.map((el, idx) => (
          <CustomTableCellChild
            key={idx}
            textAlign="center"
            isLast={data.class_list.length === 1 ? true : false}
          >
            {el.class_name}
          </CustomTableCellChild>
        ))}
      </CustomTableCellFit>
    );
  };

  getChildPrice = (data) => {
    return (
      <CustomTableCellFit $isNumber max_cnt={1}>
        {data.class_list.map((el, idx) => (
          <CustomTableCellChild
            key={idx}
            textAlign="right"
            padding="0 1rem 0 0"
            isLast={data.class_list.length === 1 ? true : false}
          >
            {el.standard_price.toLocaleString() + " 원"}
          </CustomTableCellChild>
        ))}
      </CustomTableCellFit>
    );
  };

  getChildCnt = (data) => {
    return (
      <CustomTableCellFit $isNumber max_cnt={1}>
        {data.class_list.map((el, idx) => (
          <CustomTableCellChild
            key={idx}
            isLast={data.class_list.length === 1 ? true : false}
            textAlign="right"
            padding="0 1rem 0 0"
          >
            {el.class_cnt} 회
          </CustomTableCellChild>
        ))}
      </CustomTableCellFit>
    );
  };

  getChildTotal = (data) => {
    return (
      <CustomTableCellFit $isNumber max_cnt={1}>
        {data.class_list.map((el, idx) => (
          <CustomTableCellChild
            key={idx}
            isLast={data.class_list.length === 1 ? true : false}
            textAlign="right"
            padding="0 1rem 0 0"
          >
            {el.total_salary.toLocaleString() + " 원"}
          </CustomTableCellChild>
        ))}
      </CustomTableCellFit>
    );
  };

  create_table = (data, idx) => {
    return (
      <CustomRow
        key={idx}
        onClick={() => {
          this.onClickRow(
            data.staff_id,
            data.personal_info.staff_name,
            data.personal_info.sabun,
            data.staff_salary_id,
          );
        }}
        hover
      >
        <CustomTableContents>{data.personal_info.sabun}</CustomTableContents>
        <CustomTableContents>{data.personal_info.staff_name}</CustomTableContents>
        <CustomTableContents>{data.staff_info.staff_pos}</CustomTableContents>
        {this.getChildClass(data.gx_salary)}
        {this.getChildPrice(data.gx_salary)}
        {this.getChildCnt(data.gx_salary)}
        {this.getChildTotal(data.gx_salary)}
      </CustomRow>
    );
  };
  columns_head = [
    { title: "사번", width: "10%" },
    { title: "직원명", width: "13%" },
    { title: "직책", width: "14%" },
    { title: "수업명", width: "20%" },
    { title: "1회 수업료", width: "14%" },
    { title: "총 수업완료 및 대강 횟수", width: "14%" },
    { title: "최종 지급액", width: "14%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
