export const board_post_info = {
  no: "",
  writer: {
    staff_name: "",
  },
  title: "",
  contents: "",

  is_important: false,
  is_schedule: false,

  comment_cnt: 10,
  file_cnt: 0,
};

export const board_row = {
  approval_docs_id: "",
  post_id: "",
  hq_info: {
    hq_id: "",
    hq_name: "",
  },
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  can_edit: false,
  board_type: "",
  target_staff_list: [],
  schedule_date_period: {},
  post_info: board_post_info,
  comment_list: [],
  file_list: [],
  reg_info: {
    created_at: "",
    reg_id: "",
    reg_name: "",
    upd_id: "",
    upd_name: "",
    updated_at: "",
  },
};

export const board_search_param = {
  from_date: "",
  to_date: "",
  search_title: "",
};

export const comment_row = {
  comment_id: "",

  can_edit: false,
  comment: "",

  reg_info: {
    created_at: "",
    reg_id: "",
    reg_name: "",
    upd_id: "",
    upd_name: "",
    updated_at: "",
  },
};

export const getNowServiceState = (boardType) => {
  let newBoardType = "";
  if (boardType === "공지사항") {
    newBoardType = "notice";
  } else if (boardType === "자료실") {
    newBoardType = "refer";
  } else if (boardType === "전달사항") {
    newBoardType = "delivery";
  }

  return newBoardType;
};

// ?????????? 양식 가져오는 건가???
export const getPostRowState = (boardType) => {
  let stateData = board_row;
  stateData.board_type = boardType; //getNowServiceState(boardType);

  stateData.post_info.contents = "";

  return stateData;
};

// ????????????????
export const getCommentState = () => {
  let stateData = comment_row;
  // stateData.comment_id = generateObjectZeroId(); //getNowServiceState(boardType);

  return stateData;
};
