import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, parseMoneyFormat } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableCellChild,
  CustomTableCellFit,
  CustomTableContents,
} from "components/CustomTable/tableStyle";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadPurchaseHistoryStore } from "../../Module/actions";

const PaymentHistoryTable = ({ memberId, onClickOpenModal }) => {
  const storesDispatch = useDispatch();
  const historyInfoState = useSelector((state) => state.member_history.purchase_history_state);

  const [curPage, setCurPage] = useState(1);
  // console.log("구매탭", historyInfoState.stores);

  useEffect(() => {
    storesDispatch(loadPurchaseHistoryStore(memberId));
  }, [memberId]);

  //페이지네이션
  useEffect(() => {
    if (curPage) {
      storesDispatch(loadPurchaseHistoryStore(memberId, curPage));
    }
  }, [curPage]);

  const onModalPurchaseDetails = (data) => {
    const param = {
      title: "구매 상세 내역",
      modalParam: {
        memberId: memberId,
        purchase_code: data.purchase_code,
      },
    };
    onClickOpenModal("구매상세내역", param);
  };
  const paymentHistoryTable = new paymentHistoryClass(onModalPurchaseDetails);

  return (
    <div>
      <CustomTable
        columns_head={paymentHistoryTable.columns_head}
        table_title={paymentHistoryTable.table_title}
        rest_call={paymentHistoryTable.get_data_from_rest}
        row_render={paymentHistoryTable.create_table}
        rest_data={historyInfoState.stores.data}
      />
      <CustomPagination
        curPage={curPage}
        doSearch={setCurPage}
        totalCnt={historyInfoState.stores.total_page ? historyInfoState.stores.total_page : 1}
      />
    </div>
  );
};
export default PaymentHistoryTable;

class paymentHistoryClass {
  table_title = "";
  btnStyleClass = btnStyles();
  onParentClickEvent = null;

  constructor(onModalOpen) {
    this.onParentClickEvent = onModalOpen;
  }

  getChildPaidProduct = (data) => {
    return (
      <CustomTableCellFit align="center" max_cnt={2}>
        {data.paid_detail &&
          data.paid_detail.map((paid_info, idx) => (
            <CustomTableCellChild
              key={idx}
              rowRate={1}
              isLast={idx === data.paid_detail.length - 1}
            >
              {paid_info.description}
            </CustomTableCellChild>
          ))}
      </CustomTableCellFit>
    );
  };
  getChildPaidPrice = (data) => {
    return (
      <CustomTableCellFit align="center" max_cnt={2}>
        {data.paid_detail &&
          data.paid_detail.map((paid_info, idx) => (
            <CustomTableCellChild
              textAlign="right"
              key={idx}
              rowRate={1}
              isLast={idx === data.paid_detail.length - 1}
            >
              {parseMoneyFormat(paid_info.paid_price) + " 원"}
            </CustomTableCellChild>
          ))}
      </CustomTableCellFit>
    );
  };
  getChildUnPaidPrice = (data) => {
    return (
      <CustomTableCellFit align="center" max_cnt={2}>
        {data.paid_detail &&
          data.paid_detail.map((paid_info, idx) => (
            <CustomTableCellChild
              textAlign="right"
              key={idx}
              rowRate={1}
              isLast={idx === data.paid_detail.length - 1}
            >
              {paid_info.unpaid_price.toLocaleString() + " 원"}
            </CustomTableCellChild>
          ))}
      </CustomTableCellFit>
    );
  };
  getChildRefundPrice = (data) => {
    return (
      <CustomTableCellFit align="center" max_cnt={2}>
        {data.paid_detail &&
          data.paid_detail.map((paid_info, idx) => (
            <CustomTableCellChild
              textAlign="right"
              key={idx}
              rowRate={1}
              isLast={idx === data.paid_detail.length - 1}
            >
              {paid_info.refund_price.toLocaleString() + " 원"}
            </CustomTableCellChild>
          ))}
      </CustomTableCellFit>
    );
  };
  onClickEvent = (data) => {
    // console.log(data);
    this.onParentClickEvent(data);
  };
  create_table = (data, idx) => {
    return (
      <CustomRow key={idx} onClick={() => this.onClickEvent(data)}>
        <CustomTableContents>
          <span style={{ borderBottom: "1px solid #003CCB", color: "#003CCB", cursor: "pointer" }}>
            {data.purchase_code}
          </span>
        </CustomTableContents>
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.reg_date}</CustomTableContents>
        <CustomTableContents>{data.reg_info.reg_name}</CustomTableContents>
        <CustomTableContents>{data.reason}</CustomTableContents>

        <CustomTableContents $isNumber>
          {data.total_price.toLocaleString() + " 원"}
        </CustomTableContents>
        {this.getChildPaidProduct(data)}
        {this.getChildPaidPrice(data)}
        {this.getChildUnPaidPrice(data)}
        {this.getChildRefundPrice(data)}
      </CustomRow>
    );
  };
  columns_head = [
    { title: "구매코드", width: "8%" },
    { title: "등록일시", width: "10%" },
    { title: "지점", width: "8%" },
    { title: "반영일", width: "7%" },
    { title: "담당자", width: "8%" },
    { title: "사유", width: "8%" },
    { title: "합계 금액", width: "9%" },
    { title: "상품 내역", width: "12%" },
    { title: "결제금액", width: "9%" },
    { title: "미납금", width: "9%" },
    { title: "환불금액", width: "9%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
