import { Box } from "@mui/material";
import { getDefaultValue } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";

const SearchCondition = ({ searchParam, typeList }) => {
  return (
    <Box
      sx={{
        border: "1px solid #dedede",
        borderRadius: "5px",
        backgroundColor: "#f5f5f5",
        height: "5.5rem",
      }}
    >
      <div style={{ margin: "0.75rem 0.75rem" }}>
        <Label labelText="검색 조건" fontWeight="bold" justifyContent="flex-start" />
        <div style={{ display: "flex", marginTop: "0.5rem" }}>
          <div>
            <ComboBox
              labelText="입력 구분"
              labelWidth="4rem"
              labelMarginRight="1rem"
              comboItemWidth="15rem"
              data={["전체", "자동", "수기입력"]}
              defaultValue={getDefaultValue("combo", searchParam.create_type)}
              onChangeCallback={(e) => {
                if (e.target.value === "전체") searchParam.create_type = "";
                else searchParam.create_type = e.target.value;
              }}
            />
          </div>

          <div style={{ marginLeft: "3rem" }}>
            <SearchComboBox
              labelText="계정"
              labelWidth="4rem"
              labelMarginRight="0rem"
              data={["전체", ...typeList]}
              comboItemWidth="15rem"
              defaultValue={getDefaultValue("combo", searchParam.account)}
              onChangeCallback={(e, v) => {
                if (v === "전체") searchParam.account = "";
                else searchParam.account = v;
              }}
            />
          </div>
        </div>
      </div>
    </Box>
  );
};
export default SearchCondition;
