import border from "assets/icon/Border.svg";
import IconButton from "components/Button/IconButton";
import { contentTypeJson, dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import SearchStaffInBranch from "pages/CommonModal/Search/SearchStaffInBranch";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { otDetailInit } from "../Module/reducers/ot_manage_search_param";
import AddOtServiceSessionModal from "./AddOtServiceSessionModal";
import ClassProcessModal from "./ClassProcessModal";
import OtReservationModal from "./OtReservationModal";
import SuggestPtModal from "./SuggestPtModal";

const OtClassDetailModal = ({ modalParam }) => {
  const storesDispatch = useDispatch();

  const labelWidth = "4.5rem";
  const textBoxWidth = "12rem";
  const textBoxWidthShort = "9.5rem";
  const [viewInfo, setViewInfo] = useState(_.cloneDeep(otDetailInit));
  const viewModelRef = useRef();
  const [isModify, setIsModify] = useState(false);
  const [loading, setLoading] = useState(false);

  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });

  const num = useRef(1);

  //화면 상세정보 GET
  useEffect(() => {
    getDetailInfo();
  }, [modalParam]);

  //수업차수
  useEffect(() => {
    const firstOne = viewInfo.ot_class.find((el) => !el.class_done_date);
    if (firstOne) {
      const presentNo = firstOne.no;
      num.current = presentNo;
    }
  }, [viewInfo]);

  const getDetailInfo = async () => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_STAFF_SERVER + "/class/ot_detail_info",
        {
          params: {
            ot_detail_id: modalParam.data.ot_detail_id,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setViewInfo(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const validationCheck = () => {
    if (isModify) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="저장 하시겠습니까?" />
            </>
          ),

          onBtnYes: () => {
            editComment();
          },
          onBtnNo: () => {},
        }),
      );
    } else {
      setIsModify(true);
    }
  };

  const checkIsPossibleManage = () => {
    if (
      !viewInfo.cur_manager.staff_info.staff_name ||
      viewInfo.cur_manager.staff_info.staff_name === "미배정"
    ) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="수업담당자를 먼저 배정해주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
      // } else if (viewInfo.ot_info.root_status !== "수강") {
      //   storesDispatch(
      //     globalAlertOn({
      //       show: true,
      //       titleText: "알림",
      //       bodyText: <Label labelText="회원권의 상태가 수강이 아닌경우, 수업처리가 불가능합니다." />,
      //       onBtnOk: () => {},
      //     }),
      //   );
    } else {
      popOtManageModal(
        "OT 수업 처리",
        { mode: "ot", data: modalParam.data, no: num.current },
        refreshView,
      );
    }
  };

  const editComment = async () => {
    try {
      const formData = new FormData();
      const info = {
        comment: viewInfo.ot_info.comment,
        membership_id: modalParam.data.membership_info.membership_id,
        ot_detail_id: modalParam.data.ot_detail_id,
        staff_info: modalParam.data.cur_manager.staff_info,
      };
      formData.append("new_info", JSON.stringify(info));
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_STAFF_SERVER + "/class/change/ot/comment",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        setIsModify(false);
        getDetailInfo(); //상세화면 다시 GET
        modalParam.refreshTable(); //검결 테이블 다시 갱신
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const changeOtManager = async (url, staffInfo) => {
    try {
      const formData = new FormData();
      const info = {
        comment: "",
        ot_detail_id: modalParam.data.ot_detail_id,
        staff_info: staffInfo,
      };
      formData.append("new_info", JSON.stringify(info));
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_STAFF_SERVER + url,
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: <Label labelText={`${staffInfo.staff_name} 으로 배정되었습니다.`} />,
            onBtnOk: () => {
              getDetailInfo(); //상세화면 다시 GET
              modalParam.refreshTable(); //검결 테이블 다시 갱신
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  //직원검색 모달 DONE
  const searchStaffModalDone = (data) => {
    const staffInfo = {
      staff_name: data.personal_info.staff_name,
      staff_id: data.staff_id,
      staff_pos: data.base_info.position.org_name,
      staff_rank: data.base_info.rank.org_name,
    };
    if (viewInfo.cur_manager.staff_info.staff_name === "미배정") {
      //신규등록 POST
      changeOtManager("/class/assign_ot", staffInfo);
    } else {
      //변경 POST
      changeOtManager("/class/change_ot_manager", staffInfo);
    }
    viewModelRef.current.setClose();
  };

  const refreshView = () => {
    getDetailInfo(); //상세정보 화면 갱신
    modalParam.refreshTable(); //검결 테이블 다시 갱신
    viewModelRef.current.setClose();
  };

  const popSuggestPtModal = (title, param, localModalDone) => {
    if (
      !viewInfo.cur_manager.staff_info.staff_name ||
      viewInfo.cur_manager.staff_info.staff_name === "미배정"
    ) {
      return storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="수업담당자를 먼저 배정해주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else {
      setModalInfo({
        show: true,
        title: title,
        Content: <SuggestPtModal modalParam={param} onModalDone={localModalDone} />,
        callback: null,
        Buttons: undefined,
      });
    }
  };

  const popAddOtServiceSessionModal = (title, param, localModalDone) => {
    if (
      !viewInfo.cur_manager.staff_info.staff_name ||
      viewInfo.cur_manager.staff_info.staff_name === "미배정"
    ) {
      return storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="수업담당자를 먼저 배정해주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else {
      setModalInfo({
        show: true,
        title: title,
        Content: <AddOtServiceSessionModal modalParam={param} onModalDone={localModalDone} />,
        callback: null,
        Buttons: undefined,
      });
    }
  };

  const popSearchStaffModal = (title, param, onModalDone) => {
    setModalInfo({
      show: true,
      title: title,
      Content: <SearchStaffInBranch modalParam={param} onModalDone={onModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const popOtReservationModal = (title, param, localModalDone) => {
    if (
      !viewInfo.cur_manager.staff_info.staff_name ||
      viewInfo.cur_manager.staff_info.staff_name === "미배정"
    ) {
      return storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="수업담당자를 먼저 배정해주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else {
      setModalInfo({
        show: true,
        title: title,
        Content: <OtReservationModal modalParam={param} onModalDone={localModalDone} />,
        callback: null,
        Buttons: undefined,
      });
    }
  };

  const popOtManageModal = (title, param, localModalDone) => {
    setModalInfo({
      show: true,
      title: title,
      Content: <ClassProcessModal modalParam={param} onModalDone={localModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };
  // 수업 담당자 찾기
  const onClickSearchStaffIcon = () => {
    if (viewInfo.ot_info.root_status === "소진완료" || viewInfo.ot_info.root_status === "만료") {
      simpleAlert(storesDispatch, "알림", "만료된 수업은 담당자를 배정할 수 없습니다.");
      return;
    }
    popSearchStaffModal(
      "직원 검색",
      {
        branch_info: modalParam.data.branch_info,
      },
      searchStaffModalDone,
    );
  };

  const sessionList = new sessionListTable();

  return (
    <div style={{ width: "56.5rem" }}>
      {loading && <Loading />}
      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            alt="border"
            src={border}
            style={{
              width: "1.2rem",
              height: "1.2rem",
            }}
          />

          <SubTitle titleText="수업 정보" />
        </div>

        <div>
          <div style={{ display: "flex" }}>
            <TextField
              labelMarginLeft="1rem"
              labelWidth={labelWidth}
              textBoxWidth={textBoxWidth}
              labelText="회원번호"
              defaultValue={viewInfo.membership_info.member_no}
              disabled
            />
            <TextField
              labelWidth={labelWidth}
              labelMarginLeft="1.5rem"
              textBoxWidth={textBoxWidth}
              defaultValue={viewInfo.membership_info.member_name}
              labelText="이름"
              disabled
            />
            <TextField
              labelMarginLeft="1.5rem"
              labelWidth={labelWidth}
              textBoxWidth={textBoxWidth}
              labelText="OT 횟수"
              defaultValue={`${viewInfo.ot_info.remain_session_cnt} / ${viewInfo.ot_info.ot_session_cnt}`}
              disabled
            />
          </div>
          <div style={{ display: "flex", marginTop: "0.5rem" }}>
            <div style={{ display: "flex", marginRight: "0.5rem" }}>
              <TextField
                labelMarginLeft="1rem"
                labelWidth={labelWidth}
                textMarginRight="0.3rem"
                textBoxWidth={textBoxWidthShort}
                labelText="수업 담당자"
                disabled
                defaultValue={viewInfo.cur_manager.staff_info.staff_name}
              />

              <IconButton width={"2.3rem"} type="profile" onClick={onClickSearchStaffIcon} />
            </div>
            <TextField
              labelWidth={labelWidth}
              labelMarginLeft="1.5rem"
              textBoxWidth={textBoxWidth}
              labelText="수업 상태"
              defaultValue={viewInfo.ot_info.class_status}
              disabled
            />
            <TextField
              labelMarginLeft="1.5rem"
              labelWidth={labelWidth}
              textBoxWidth={textBoxWidth}
              labelText="최근 예약일"
              defaultValue={viewInfo.ot_info.latest_reserv_date}
              disabled
            />
          </div>
          <div style={{ display: "flex", marginTop: "0.5rem" }}>
            <TextField
              labelMarginLeft="1rem"
              labelWidth={labelWidth}
              textBoxWidth={textBoxWidth}
              labelText="최근 연락일"
              defaultValue={viewInfo.ot_info.latest_contact}
              disabled
            />
            <div style={{ display: "flex", marginLeft: "1.5rem" }}>
              <TextField
                labelWidth={labelWidth}
                textMarginRight="0.3rem"
                textBoxWidth={
                  viewInfo.ot_info.suggest_date
                    ? //  || viewInfo.ot_info.root_status === "만료"
                      textBoxWidth
                    : textBoxWidthShort
                }
                labelText="PT 권유일"
                defaultValue={viewInfo.ot_info.suggest_date}
                disabled
              />
              {/* {viewInfo.ot_info.suggest_date || viewInfo.ot_info.root_status === "만료" ? ( */}
              {viewInfo.ot_info.suggest_date ? (
                ""
              ) : (
                <IconButton
                  width={"2.3rem"}
                  type="editBorder"
                  onClick={() => {
                    popSuggestPtModal(
                      "PT 권유",
                      {
                        data: modalParam.data,
                      },
                      refreshView,
                    );
                  }}
                />
              )}
            </div>
          </div>
          <div style={{ display: "flex", marginTop: "0.5rem" }}>
            <div style={{ display: "flex" }}>
              <TextField
                labelMarginLeft="1rem"
                labelWidth={labelWidth}
                textMarginRight="0.3rem"
                textBoxWidth={"47.75rem"}
                labelText="특이사항"
                defaultValue={viewInfo.ot_info.comment}
                disabled={!isModify}
                onChangeCallback={(e) => {
                  setViewInfo((cur) => {
                    const obj = { ...cur };
                    obj.ot_info.comment = e;
                    return obj;
                  });
                }}
              />
              {isModify ? (
                <IconButton type="save_border" width={"2.3rem"} onClick={validationCheck} />
              ) : (
                <IconButton width={"2.3rem"} type="editBorder" onClick={validationCheck} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "0.7rem" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            alt="border"
            src={border}
            style={{
              width: "1.2rem",
              height: "1.2rem",
            }}
          />

          <SubTitle titleText="세션 목록" />
        </div>

        <CustomTable
          columns_head={sessionList.columns_head}
          table_title={sessionList.table_title}
          rest_call={sessionList.get_data_from_rest}
          row_render={sessionList.create_table}
          rest_data={viewInfo.ot_class}
        />
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "1.5rem", gap: "0.5rem" }}
      >
        {viewInfo.ot_info.root_status === "만료" || !modalParam.canAddServiceSession ? (
          ""
        ) : (
          <NormalBtn
            name="서비스 세션 추가"
            theme="white"
            onClick={() => {
              popAddOtServiceSessionModal(
                "OT 서비스 세션 추가",
                {
                  mode: "ot",
                  data: modalParam.data,
                },
                refreshView,
              );
            }}
          />
        )}

        <NormalBtn
          name="수업예약"
          theme="white"
          onClick={() => {
            popOtReservationModal(
              "OT 수업 예약",
              { mode: "OT", data: modalParam.data },
              refreshView,
            );
          }}
          disabled={
            viewInfo.ot_info.root_status === "소진완료" || viewInfo.ot_info.root_status === "만료"
          }
        />
        <NormalBtn
          name="수업처리"
          onClick={checkIsPossibleManage}
          disabled={
            viewInfo.ot_info.root_status === "소진완료" || viewInfo.ot_info.root_status === "만료"
          }
        />
      </div>
    </div>
  );
};

export default OtClassDetailModal;

class sessionListTable {
  table_title = "";

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.no}</CustomTableContents>
        <CustomTableContents>{data.class_done_date}</CustomTableContents>
        <CustomTableContents>{data.staff_info.staff_name}</CustomTableContents>
        <CustomTableContentsEND align="center">{data.comment}</CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "수업차수", width: "14%" },
    { title: "수업일", width: "18%" },
    { title: "수업 담당자", width: "18%" },
    { title: "비고", width: "50%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
