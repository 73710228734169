import { contentTypeJson, generateObjectZeroId, simpleAlert } from "components/CommonLib/CommonLib";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LoadConditionModal from "./Modal/LoadConditionModal";
import SaveConditionModal from "./Modal/SaveConditionModal";
import SearchCard from "./SearchCard";
import SearchResultCard from "./SearchResultCard";
import { exportToExcel } from "./excel/exportToExcel";

const SearchMemberContent = ({
  navigate,
  baseSearchState,
  memberSearchState,
  mbshpSearchState,
  ptSearchState,
  sideSearchState,
}) => {
  const storesDispatch = useDispatch();

  const [baseSearchParam, setBaseSearchParam] = useState(_.cloneDeep(baseSearchState));
  const [detailSearchParam, setDetailSearchParam] = useState(_.cloneDeep(memberSearchState));
  const [searchResult, setSearchResult] = useState([]);
  const [curPage, setCurPage] = useState(); // pagination
  const [totalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const [detailOption, setDetailOption] = useState("");

  useEffect(() => {
    setBaseSearchParam(_.cloneDeep(baseSearchState));
    setDetailSearchParam(_.cloneDeep(memberSearchState));
  }, [baseSearchState, memberSearchState, mbshpSearchState, ptSearchState, sideSearchState]);

  const onChangeDetailOption = (option) => {
    if (option === "mbshp") setDetailSearchParam(_.cloneDeep(mbshpSearchState));
    else if (option === "member") setDetailSearchParam(_.cloneDeep(memberSearchState));
    else if (option === "pt") setDetailSearchParam(_.cloneDeep(ptSearchState));
    else if (option === "side" || option === "locker")
      setDetailSearchParam(_.cloneDeep(sideSearchState));
    else setDetailSearchParam(_.cloneDeep(memberSearchState));

    setSearchResult([]);
    setCurPage();
    setTotalPage(1);
  };

  const onClickExcelDown = () => {
    onClickSearch(true);
  };

  const resetSearchParam = () => {
    setDetailOption("");
    setBaseSearchParam(_.cloneDeep(baseSearchState));
    setDetailSearchParam(_.cloneDeep(memberSearchState));
  };

  const onClickSearch = async (isExcel, page) => {
    if (!baseSearchParam.branch_info.branch_name) {
      simpleAlert(storesDispatch, "알림", "지점을 선택해 주세요.");
      return;
    }

    if (!isExcel) {
      setCurPage(page);
    }
    setLoading(true);

    const searchParam = {
      base_search: baseSearchParam,
      sub_search: detailSearchParam,
    };

    const formData = new FormData();

    formData.append("is_excel", isExcel ? isExcel : false);
    formData.append("page_num", page ? page : 1);
    formData.append("search_param", JSON.stringify(searchParam));

    if (detailOption === "member") {
      formData.append("is_member_detail", true);
    }

    const postApi =
      detailOption === "member"
        ? process.env.REACT_APP_USER_SERVER + "/member/search/member"
        : detailOption === "mbshp"
        ? process.env.REACT_APP_USER_SERVER + "/member/search/mbshp"
        : detailOption === "pt"
        ? process.env.REACT_APP_USER_SERVER + "/member/search/pt"
        : detailOption === "side" || detailOption === "locker"
        ? process.env.REACT_APP_USER_SERVER + "/member/search/other"
        : process.env.REACT_APP_USER_SERVER + "/member/search/member";

    await HttpInstance.post(postApi, formData, contentTypeJson)
      .then((res) => {
        // console.log(res);
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          if (isExcel) {
            exportToExcel(detailOption, res.data.data);
          } else {
            setTotalPage(res.data.total_page);
            setSearchResult(res.data.data);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data && err.response.data.msg);
      })
      .finally(() => {
        setLoading(false);
      });

    // if (detailOption === "locker") {
    //   setSearchResult(getTempData());
    // }
  };

  const saveCondition = async (condName, canUseTm) => {
    const paramSet = {
      branch_info: baseSearchParam.branch_info,
      staff_id: generateObjectZeroId(),
      preset_id: "",
      preset_name: condName,
      condition_type: detailOption,
      can_use_tm: canUseTm,

      search_param: {
        base_param: baseSearchParam,
        mbshp_param: mbshpSearchState,
        member_param: memberSearchState,
        other_param: sideSearchState,
        pt_param: ptSearchState,
      },
    };

    if (detailOption === "member") {
      paramSet.search_param.member_param = detailSearchParam;
    } else if (detailOption === "mbshp") {
      paramSet.search_param.mbshp_param = detailSearchParam;
    } else if (detailOption === "pt") {
      paramSet.search_param.pt_param = detailSearchParam;
    } else if (detailOption === "side") {
      paramSet.search_param.other_param = detailSearchParam;
    } else {
      paramSet.search_param.member_param = detailSearchParam;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("new_info", JSON.stringify(paramSet));
    await HttpInstance.post(
      process.env.REACT_APP_USER_SERVER + "/member/search/preset/add",
      formData,
      contentTypeJson,
    )
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          simpleAlert(storesDispatch, "알림", "저장되었습니다.");
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadCondition = (id) => {
    HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member/search/preset/data", {
      params: {
        preset_id: id,
      },
    })
      .then((res) => {
        console.log(res);

        let detailParam = {};
        if (res.data.data.condition_type === "member") {
          detailParam = res.data.data.search_param.member_param;
        } else if (res.data.data.condition_type === "mbshp") {
          detailParam = res.data.data.search_param.mbshp_param;
        } else if (res.data.data.condition_type === "pt") {
          detailParam = res.data.data.search_param.pt_param;
        } else if (res.data.data.condition_type === "side") {
          detailParam = res.data.data.search_param.other_param;
        } else {
          detailParam = res.data.data.search_param.member_param;
        }
        setDetailSearchParam(detailParam);
        setBaseSearchParam(res.data.data.search_param.base_param);
        setDetailOption(res.data.data.condition_type);
        setSearchResult([]);
        setCurPage();
        setTotalPage(1);
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const onModalDone = (type, val, canUseTm) => {
    if (type === "save") {
      saveCondition(val, canUseTm);
    } else if (type === "load") {
      loadCondition(val);
      // setBaseSearchParam(base_~);
      // setDetailSearchParam();
    }
    storesDispatch(globalModalOff());
  };

  const openModal = (order, param) => {
    let Modal;
    if (order === "save") {
      Modal = <SaveConditionModal modalParam={param} onModalDone={onModalDone} />;
    } else if (order === "load") {
      Modal = <LoadConditionModal modalParam={param} onModalDone={onModalDone} />;
    }

    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,
        Content: Modal,
      }),
    );
  };

  return (
    <div style={{ display: "flex" }}>
      {loading && <Loading />}

      <div>
        <SearchCard
          baseSearchParam={baseSearchParam}
          detailSearchParam={detailSearchParam}
          resetSearchParam={resetSearchParam}
          onClickSearch={onClickSearch}
          setCurPage={setCurPage}
          detailOption={detailOption}
          setDetailOption={setDetailOption}
          openModal={openModal}
          onChangeDetailOption={onChangeDetailOption}
        />
      </div>

      <div style={{ marginLeft: "1rem" }}>
        <SearchResultCard
          searchResult={searchResult}
          detailSearchParam={detailSearchParam}
          detailOption={detailOption}
          navigate={navigate}
          onClickExcelDown={onClickExcelDown}
          curPage={curPage}
          setCurPage={setCurPage}
          totalPage={totalPage}
          onClickSearch={onClickSearch}
        />
      </div>
    </div>
  );
};
export default SearchMemberContent;
