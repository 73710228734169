export const registerViewInitState = {
  branch_info: {
    branch_id: "000000000000000000000000",
    branch_name: "",
  },
  contract_doc_list: [],
  card_param: {
    access_method: "",
    branch_info: {
      branch_id: "000000000000000000000000",
      branch_name: "",
    },
    card_info_id: "000000000000000000000000",
    card_no: "",
    dis: 0,
    is_use: false,
    price: 0,
  },
  coupon_lock: true,
  coupon_use_list: [
    // {
    //   coupon_info: {
    //     base_info: {
    //       benefit_product: "string",
    //       benefit_type: "string",
    //       benefit_use: "string",
    //       can_gift: "string",
    //       condition: true,
    //       expired_day: 0,
    //       expired_from_date: "string",
    //       expired_month: 0,
    //       expired_to_date: "string",
    //       img: "string",
    //       issue_date: "string",
    //       status: "string",
    //       type: "string",
    //       use_target: "string",
    //     },
    //     coupon_id: "string",
    //     detail: {
    //       coupon_limit_cnt: 0,
    //       coupon_name: "string",
    //       coupon_used_cnt: 0,
    //       first_come_cnt: 0,
    //       is_coupon_unlimited: true,
    //       is_use_first_come: true,
    //       price_benefit: {
    //         dis_price: 0,
    //         dis_rate: 0,
    //         type: "string",
    //       },
    //       recv_cnt: 0,
    //       service_benefit: {
    //         branch_info: {
    //           branch_id: "string",
    //           branch_name: "string",
    //         },
    //         mbshp: {
    //           add_day: 0,
    //           add_month: 0,
    //           mbshp_option: "string",
    //           mbshp_type: "string",
    //         },
    //         pt: {
    //           add_session: 0,
    //         },
    //       },
    //     },
    //     reg_info: {
    //       created_at: "string",
    //       reg_id: "string",
    //       reg_name: "string",
    //       upd_id: "string",
    //       upd_name: "string",
    //       updated_at: "string",
    //     },
    //     ticket_list: [
    //       {
    //         is_issuance: true,
    //         is_used: true,
    //         member_info: {
    //           member_id: "string",
    //           member_name: "string",
    //           member_no: "string",
    //         },
    //         ticket_id: "string",
    //       },
    //     ],
    //     view_info: {
    //       is_empty: true,
    //       is_new: true,
    //       is_used: true,
    //     },
    //   },
    //   membership_info: {
    //     membership_id: "string",
    //     purchase_item_id: "string",
    //   },
    //   ticket_info: {
    //     is_issuance: true,
    //     is_used: true,
    //     member_info: {
    //       member_id: "string",
    //       member_name: "string",
    //       member_no: "string",
    //     },
    //     ticket_id: "string",
    //   },
    // },
  ],

  member_id: "000000000000000000000000",
  member_type: "",
  personal_info: {
    member_name: "",
    member_no: "",
    phone: "",
    agree_personal: false,
    agree_marketing: false,
    gender: "",
    birth: "",
    email: "",
    zip_code: "",
    address_1: "",
    address_2: "",
    notice: "",
    reg_path: "",
    reg_info: {
      created_at: "",
      updated_at: "",
      reg_name: "",
      reg_id: "000000000000000000000000",
      upd_id: "000000000000000000000000",
      upd_name: "",
    },
  },
  point: {
    remain_point: 0,
    acc_receive_point: 0,
    acc_spend_point: 0,
  },
  membership_all_list: [
    {
      tab_info: {
        title: "",
        idx: 0,
        is_use: true,
        is_new: true,
        is_add_pay: false,
        state_initialized: false,
        selected: true,
      },
      purchase_item_list: {
        erp_policy: {
          link_info_total: 0,
        },
        hq_policy: {
          mbshp_rfnd_day_price: 0,
          prepay_penalty_rate: 0,
          subs_penalty_rate: 0,
        },
        branch_policy: {
          regular_pay_date: 0,
          cms_by_pay_method: {
            cash: 0,
            card: 0,
          },
          membership_policy: {
            use_dflt_from_date: false,
            dflt_from_date: "",
            use_max_wait_day: false,
            max_wait_day: 0,
            recommender_month: 0,
            recommender_day: 0,
            recommendee_month: 0,
            recommendee_day: 0,
            event_month_list: null,
            event_day_list: null,
            pass_mbship_count_rate: 0,
            change_limit: 0,
            pass_change_limit: 0,
            card_remake_fee: 0,
            mbshp_assign_fee: 0,
            pt_assign_fee: 0,
          },
        },
        item_info: {
          purchase_item_id: "000000000000000000000000",
          item_id: "000000000000000000000000",
          item_name: "",
          base_info: {
            incentive: 0,
          },
          mbshp_type: "",
          mbshp_option: "",
          pt_per_price: 0,
          pt_price: 0,
          pt_session: 0,
          is_combine_cloth: false,
          is_combine_gx: false,
          is_combine_locker: false,
          is_combine_pt: false,
          postpone_cnt: 0,
          ot_session: 0,
          can_assign: false,
          can_family: false,
          can_change: false,
          pass_cnt: 0,
          pass_from_time: "",
          pass_end_time: "",
          total_price: 0,
          reg_price: 0,
          total_period_month: 0,
          total_period_day: 0,
          total_period_hour: 0,
          period_month: 0,
          service_month: 0,
          service_day: 0,
          oneday_wait_hour: 0,
          mbshp_price: 0,
          cloth_price: 0,
          gx_price: 0,
          locker_price: 0,
          description: "",
          standard_price: {
            prepay_reg: 0,
            prepay_mbshp: 0,
            prepay_cloth: 0,
            prepay_gx: 0,
            prepay_locker: 0,
            oneday_mbshp: 0,
            oneday_cloth: 0,
            oneday_gx: 0,
            ot: 0,
            pt: 0,
          },
          subs_options: {
            subs_reg: 0,
            subs_period_month: 0,
            pre_month: 0,
            total_subs_price: 0,
            mbshp_subs_price: 0,
            mbshp_pre_price: 0,
            cloth_subs_price: 0,
            cloth_pre_price: 0,
            gx_subs_price: 0,
            gx_pre_price: 0,
            locker_subs_price: 0,
            locker_pre_price: 0,
          },
          reg_info: {
            created_at: "",
            updated_at: "",
            reg_name: "",
            reg_id: "000000000000000000000000",
            upd_id: "000000000000000000000000",
            upd_name: "",
          },
        },
      },
      // purchase_item_addpaid: {
      //   erp_policy: {
      //     link_info_total: 0,
      //   },
      //   hq_policy: {
      //     mbshp_rfnd_day_price: 0,
      //     prepay_penalty_rate: 0,
      //     subs_penalty_rate: 0,
      //   },
      //   branch_policy: {
      //     regular_pay_date: 0,
      //     cms_by_pay_method: {
      //       cash: 0,
      //       card: 0,
      //     },
      //     membership_policy: {
      //       use_dflt_from_date: false,
      //       dflt_from_date: "",
      //       use_max_wait_day: false,
      //       max_wait_day: 0,
      //       recommender_month: 0,
      //       recommender_day: 0,
      //       recommendee_month: 0,
      //       recommendee_day: 0,
      //       event_month_list: null,
      //       event_day_list: null,
      //       pass_mbship_count_rate: 0,
      //       change_limit: 0,
      //       pass_change_limit: 0,
      //       card_remake_fee: 0,
      //       mbshp_assign_fee: 0,
      //       pt_assign_fee: 0,
      //     },
      //   },
      //   item_info: {
      //     purchase_item_id: "000000000000000000000000",
      //     item_id: "000000000000000000000000",
      //     item_name: "",
      //     base_info: {
      //       incentive: 0,
      //     },
      //     mbshp_type: "",
      //     mbshp_option: "",
      //     pt_per_price: 0,
      //     pt_price: 0,
      //     pt_session: 0,
      //     is_combine_cloth: false,
      //     is_combine_gx: false,
      //     is_combine_locker: false,
      //     is_combine_pt: false,
      //     postpone_cnt: 0,
      //     ot_session: 0,
      //     can_assign: false,
      //     can_family: false,
      //     can_change: false,
      //     pass_cnt: 0,
      //     pass_from_time: "",
      //     pass_end_time: "",
      //     total_price: 0,
      //     reg_price: 0,
      //     total_period_month: 0,
      //     total_period_day: 0,
      //     total_period_hour: 0,
      //     period_month: 0,
      //     service_month: 0,
      //     service_day: 0,
      //     oneday_wait_hour: 0,
      //     mbshp_price: 0,
      //     cloth_price: 0,
      //     gx_price: 0,
      //     locker_price: 0,
      //     description: "",
      //     standard_price: {
      //       prepay_reg: 0,
      //       prepay_mbshp: 0,
      //       prepay_cloth: 0,
      //       prepay_gx: 0,
      //       prepay_locker: 0,
      //       oneday_mbshp: 0,
      //       oneday_cloth: 0,
      //       oneday_gx: 0,
      //       ot: 0,
      //       pt: 0,
      //     },
      //     subs_options: {
      //       subs_reg: 0,
      //       subs_period_month: 0,
      //       pre_month: 0,
      //       total_subs_price: 0,
      //       mbshp_subs_price: 0,
      //       mbshp_pre_price: 0,
      //       cloth_subs_price: 0,
      //       cloth_pre_price: 0,
      //       gx_subs_price: 0,
      //       gx_pre_price: 0,
      //       locker_subs_price: 0,
      //       locker_pre_price: 0,
      //     },
      //     reg_info: {
      //       created_at: "",
      //       updated_at: "",
      //       reg_name: "",
      //       reg_id: "000000000000000000000000",
      //       upd_id: "000000000000000000000000",
      //       upd_name: "",
      //     },
      //   },
      // },
      membership_list: {
        item_name: "",
        item_type_idx: 0,
        item_option_idx: 0,
        item_name_idx: 0,
        option_lock: false,
        membership_id: "000000000000000000000000",
        purchase_item_id: "000000000000000000000000",
        mbshp_type: "",
        mbshp_option: "",
        mbshp_status: "",
        is_new: false,
        paid_done_date: "",
        re_reg_path: "",
        subs_options: {
          contract_from_date: "",
          contract_to_date: "",
          is_apply_cancel: false,
        },
        pass: {
          total_cnt: 0,
        },
        pass_time: {
          from_time: "",
          end_time: "",
        },
        daily_time: {
          from_time: "",
          end_time: "",
        },
        reg: {
          is_use: true,
          from_date: "",
          to_date: "",
          price: 0,
          dis: 0,
          is_hide: false,
          is_lock: true,
          subs_options: {
            subs_price: 0,
            pre_price: 0,
            prorate_price: 0,
            subs_dis: 0,
            pre_dis: 0,
            prorate_dis: 0,
          },
        },
        mbshp: {
          event_month: 0,
          event_day: 0,
          event_reason: "",
          is_use: false,
          from_date: "",
          to_date_raw: "",
          to_date: "",
          price: 0,
          dis: 0,
          is_hide: false,
          is_lock: false,
          subs_options: {
            subs_price: 0,
            pre_price: 0,
            prorate_price: 0,
            subs_dis: 0,
            pre_dis: 0,
            prorate_dis: 0,
          },
        },
        cloth: {
          is_use: false,
          from_date: "",
          to_date: "",
          price: 0,
          dis: 0,
          is_hide: false,
          is_lock: false,
          subs_options: {
            subs_price: 0,
            pre_price: 0,
            prorate_price: 0,
            subs_dis: 0,
            pre_dis: 0,
            prorate_dis: 0,
          },
        },
        gx: {
          is_use: false,
          from_date: "",
          to_date: "",
          price: 0,
          dis: 0,
          is_hide: false,
          is_lock: false,
          subs_options: {
            subs_price: 0,
            pre_price: 0,
            prorate_price: 0,
            subs_dis: 0,
            pre_dis: 0,
            prorate_dis: 0,
          },
        },
        locker: {
          is_use: false,
          is_hide: false,
          is_lock: false,
          subs_options: {
            subs_price: 0,
            pre_price: 0,
            prorate_price: 0,
            subs_dis: 0,
            pre_dis: 0,
            prorate_dis: 0,
            contract_from_date: "",
            contract_to_date: "",
          },
        },
        locker_list: [],
        family_info: {
          is_use: false,
          family_info_id: "000000000000000000000000",
          family_type: "",
          member_info: {
            member_id: "000000000000000000000000",
            member_name: "",
            member_no: "",
          },
        },
        family_cloth: {
          is_use: false,
          from_date: "",
          to_date: "",
          price: 0,
          dis: 0,
          is_hide: false,
          is_lock: false,
          subs_options: {
            subs_price: 0,
            pre_price: 0,
            prorate_price: 0,
            subs_dis: 0,
            pre_dis: 0,
            prorate_dis: 0,
          },
        },
        family_gx: {
          is_use: false,
          from_date: "",
          to_date: "",
          price: 0,
          dis: 0,
          is_hide: false,
          is_lock: false,
          subs_options: {
            subs_price: 0,
            pre_price: 0,
            prorate_price: 0,
            subs_dis: 0,
            pre_dis: 0,
            prorate_dis: 0,
          },
        },
        family_locker: {
          is_use: false,
          is_hide: false,
          is_lock: false,
        },
        family_locker_list: [],
        usage: {
          link_info_total: 0,
          total_postpone_cnt: 0,
          used_postpone_cnt: 0,
          acc_postpone_day: 0,
          ot_session: 0,
          family_ot_session: 0,
        },
        mbshp_postpone_list: null,
        ot_detail_info: {
          ot_detail_id: "000000000000000000000000",
        },
        family_ot_detail_info: {
          ot_detail_id: "000000000000000000000000",
        },
        ot_staff: {
          staff_id: "000000000000000000000000",
          staff_name: "",
          staff_pos: "",
          staff_rank: "",
        },
        reg_info: {
          created_at: "",
          updated_at: "",
          reg_name: "",
          reg_id: "000000000000000000000000",
          upd_id: "000000000000000000000000",
          upd_name: "",
        },
      },
    },
  ],
  pt_info: {
    default_session: 0,
    service_session: 0,
    per_price: 0,
    total_price: 0,
    pt_total_cnt: 0,
    price: 0,
    dis: 0,
    input_type: "총액",
    cs_staff: {
      staff_id: "000000000000000000000000",
      staff_name: "",
      staff_pos: "",
      staff_rank: "",
    },
    pt_staff: {
      staff_id: "000000000000000000000000",
      staff_name: "",
      staff_pos: "",
      staff_rank: "",
    },
  },
  paymentA: {
    payment_info: [],
  },
  sub_payment: [
    // {
    //   paid_item_list: [
    //     // {
    //     //   content_obj: {
    //     //     dis: 0,
    //     //     pay_order: 0,
    //     //     price: 0,
    //     //     tab_idx: 0,
    //     //     type: "string",
    //     //   },
    //     //   orderIdx: 0,
    //     //   type_key: "string",
    //     // },
    //   ],
    //   payment_info: {
    //     extra_price: 0,
    //     pay_date: "string",
    //     payment_method: {
    //       card_no: "string",
    //       company: "string",
    //       exp_month: 0,
    //       exp_year: 0,
    //       id_no: true,
    //       is_company: true,
    //       name: "string",
    //       pay_method: "string",
    //       phone: "string",
    //     },
    //     payment_plan_list: [
    //       // {
    //       //   idx: 0,
    //       //   pay_date: "string",
    //       //   price: 0,
    //       // },
    //     ],
    //     price_next_month: 0,
    //     reg_info: {
    //       created_at: "string",
    //       reg_id: "string",
    //       reg_name: "string",
    //       upd_id: "string",
    //       upd_name: "string",
    //       updated_at: "string",
    //     },
    //     relation: "string",
    //     subs_period_month: 0,
    //   },
    //   tab_info: {
    //     MembershipIdx: 0,
    //     idx: 0,
    //     title: "string",
    //   },
    //   view_info: {
    //     is_use: true,
    //     monthly_total: 0,
    //     raw_paid: 0,
    //     total_paid: 0,
    //   },
    // },
  ],
  regular_pay_method: {
    //??
    extra_price: 0,
    subs_period_month: 0,

    pay_date: "1",
    price_next_month: 0,
    relation: "",
    payment_method: {
      card_no: "",
      company: "",
      exp_month: 0,
      exp_year: 0,
      id_no: "",
      is_company: "",
      name: "",
      pay_method: "",
      phone: "",
    },
    payment_plan_list: [
      // {
      //   idx: 0,
      //   pay_date: "",
      //   price: 0,
      // },
    ],
    reg_info: {
      created_at: "",
      reg_id: "",
      reg_name: "",
      upd_id: "",
      upd_name: "",
      updated_at: "",
    },
  },
  cs_staff: {
    staff_id: "000000000000000000000000",
    staff_name: "",
    staff_pos: "",
    staff_rank: "",
  },
  ot_staff: {
    staff_id: "000000000000000000000000",
    staff_name: "",
    staff_pos: "",
    staff_rank: "",
  },
  friend_info: {
    member_id: "000000000000000000000000",
    member_name: "",
    member_no: "",
  },
  reg_date: "",
  join_way: "",
  access_way: "",
  last_to_date: "",
  pass_mbshp_use: false,
  subs_mbshp_user: false,
  time_mbshp_use: false,
  // card_no: "",
  // subs_lock: false,
  // card_remake: {
  //   is_use: false,
  //   card_no: "",
  //   price: 0,
  //   dis: 0,
  // },
};
