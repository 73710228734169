import CardFixed from "components/Card/CardFixed";

import IconButton from "components/Button/IconButton";
import { dataEmpty, getPermission, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const ManagePtTeamView = ({ branchList, onClickOpenModal, selectedBranch, setSelectedBranch }) => {
  const storesDispatch = useDispatch();

  const [teamList, setTeamList] = useState([]);
  const [showTeamAddBtn, setShowTeamAddBtn] = useState(false);

  useEffect(() => {
    // getPermission()
    //  => 세번째 파라미터가 false || undefined인 경우 true/false값 리턴
    //     true인 경우 본부&지점 리스트 리턴
    getPermission(storesDispatch, "CLASS_MENU/CLASS_EXT_MANAGE").then((res) => {
      setShowTeamAddBtn(res);
    });
  }, []);

  //팀리스트 GET
  const getTeamList = async (branchId) => {
    try {
      const res = await HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/pt_team/list", {
        params: {
          branch_id: branchId,
        },
      });
      if (!restEmpty(res.data.data)) {
        setTeamList(res.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };
  //1.팀추가모달 POP
  const popAddTeamModal = () => {
    const param = {
      title: "팀 추가",
      modalParam: {
        branch_info: selectedBranch,
      },
    };
    onClickOpenModal("팀추가", param, refreshView);
  };

  //2.팀수정모달 POP
  const popChooseEditModeModal = (data) => {
    const param = {
      title: "팀 수정",
      modalParam: { data, onClickOpenModal, getTeamList, branch_id: selectedBranch.branch_id },
    };
    onClickOpenModal("팀수정", param, refreshView);
  };

  //1-1.팀추가모달 DONE
  //2-1.팀수정모달 DONE
  const refreshView = () => {
    getTeamList(selectedBranch.branch_id); //팀리스트 갱신
    storesDispatch(globalModalOff()); //모달닫기
  };

  //팀삭제 POST
  const deleteTeam = async (teamId) => {
    try {
      const res = await HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/pt_team/remove", {
        params: {
          team_id: teamId,
        },
      });
      if (!res.data.data) {
        //성공 -> 팀리스트 갱신
        getTeamList(selectedBranch.branch_id);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  //팀삭제 MSG
  const onDeleteBtn = (team) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText={`${team.team_name}을 삭제하시겠습니까?`} />
            <Label labelText="삭제된 정보는 복구할 수 없습니다." />
          </>
        ),
        onBtnYes: () => {
          //삭제POST
          deleteTeam(team.team_id);
        },
        onBtnNo: () => {},
      }),
    );
  };

  useEffect(() => {
    if (selectedBranch.branch_id) {
      getTeamList(selectedBranch.branch_id);
    }
  }, [selectedBranch.branch_id]);

  const teamListData = new teamListTable(popChooseEditModeModal, onDeleteBtn, refreshView);

  return (
    <div>
      <CardFixed
        topDom={<div>팀 관리</div>}
        midDom={
          <div>
            <div style={{ display: "flex", justifyContent: "flex-start", marginTop: "1rem" }}>
              <SearchComboBox
                labelWidth={"2rem"}
                comboItemWidth={"12rem"}
                labelText="지점"
                data={branchList.branch_name_list}
                defaultValue={branchList.branch_name_list[0] || ""}
                onChangeCallback={(e, v, idx) => {
                  const branch = branchList.branch_list[idx];
                  if (idx !== -1) {
                    //지점 바뀌면 다시 팀리스트 GET
                    setSelectedBranch(branch);
                  }
                }}
              />
            </div>

            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "0.7rem" }}>
              <div style={{ display: "flex" }}>
                <IconButton type="border" />
                <div style={{ width: "0.5rem" }}></div>
                <SubTitle titleText="팀 목록" />
              </div>
              {showTeamAddBtn ? (
                <div>
                  <NormalBtn
                    name="팀 추가"
                    onClick={() => {
                      if (!selectedBranch.branch_id) {
                        storesDispatch(
                          globalAlertOn({
                            show: true,
                            titleText: "알림",
                            bodyText: (
                              <>
                                <Label labelText="지점을 설정해주세요." />
                              </>
                            ),

                            onBtnOk: () => {},
                          }),
                        );
                      } else {
                        popAddTeamModal();
                      }
                    }}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div style={{ marginTop: "0.7rem" }}>
              <CustomTable
                columns_head={teamListData.columns_head}
                table_title={teamListData.table_title}
                rest_call={teamListData.get_data_from_rest}
                row_render={teamListData.create_table}
                rest_data={teamList}
              />
            </div>
          </div>
        }
        rootStyle={{
          width: "100%",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />
    </div>
  );
};
export default ManagePtTeamView;

class teamListTable {
  table_title = "";
  popChooseEditModeModal = null;
  onDeleteBtn = null;
  refreshView = null;

  constructor(popChooseEditModeModal, onDeleteBtn, refreshView) {
    this.popChooseEditModeModal = popChooseEditModeModal;
    this.onDeleteBtn = onDeleteBtn;
    this.refreshView = refreshView;
  }

  create_table = (data, key) => {
    return (
      <CustomRow key={key}>
        <CustomTableContents>{data.team_info.team_name}</CustomTableContents>
        <CustomTableContents>{data.team_view_info.boss_name}</CustomTableContents>
        <CustomTableContents>{data.team_view_info.team_member_cnt}</CustomTableContents>
        <CustomTableContents>{data.team_view_info.team_describe}</CustomTableContents>
        <CustomTableContents>
          <IconButton
            type="edit"
            onClick={() => {
              this.popChooseEditModeModal(data);
            }}
          />
        </CustomTableContents>
        <CustomTableContentsEND align="center">
          <IconButton
            type="delete"
            onClick={() => {
              this.onDeleteBtn(data.team_info);
            }}
          />
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "팀명", width: "10%" },
    { title: "팀장", width: "10%" },
    { title: "팀원 수", width: "10%" },
    { title: "팀원", width: "60%" },
    { title: "수정", width: "5%" },
    { title: "삭제", width: "5%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
