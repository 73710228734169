import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import SendHistoryView from "./SendHistoryView";

const SendHistoryService = () => {
  return (
    <div>
      <PageFrame
        children={
          <div>
            <PageTitle titleText="발송 이력" />
            <SendHistoryView />
          </div>
        }
      />
    </div>
  );
};
export default SendHistoryService;
