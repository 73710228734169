export const getAssignViewAction = "MEMEBER_SERVICE/VIEW/ASSIGN";
export const getAssignViewSuccessAction = "MEMEBER_SERVICE/VIEW/ASSIGN/SUCCESS";
export const getAssignViewFailAction = "MEMEBER_SERVICE/VIEW/ASSIGN/FAIL";
export const getAssignViewResetAction = "MEMEBER_SERVICE/VIEW/ASSIGN/RESET";

export const getAssignParamAction = "MEMEBER_SERVICE/PARAM/ASSIGN";
export const setAssignParamSrcId = "MEMEBER_SERVICE/PARAM/ASSIGN/SET_SCR_ID";
export const setAssignParamDstId = "MEMEBER_SERVICE/PARAM/ASSIGN/SET_DST_ID";
export const setAssignParamNewbie = "MEMEBER_SERVICE/PARAM/ASSIGN/SET_NEWBIE";

export const clearAssignParamSrcId = "MEMEBER_SERVICE/PARAM/ASSIGN/CLEAR_SCR_ID";
export const clearAssignParamDstId = "MEMEBER_SERVICE/PARAM/ASSIGN/CLEAR_DST_ID";
export const clearAssignParamNewbie = "MEMEBER_SERVICE/PARAM/ASSIGN/CLEAR_NEWBIE";

export const clearAssignParamAll = "MEMEBER_SERVICE/PARAM/ASSIGN/CLEAR_ALL";
