import {
  changeComboValueToEmpty,
  checkDateOrder,
  getDefaultValue,
} from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import { useEffect, useState } from "react";
import {
  SearchConditionLongBox,
  SearchConditionShortBox,
  SearchContainer,
  SearchRowBox,
} from "../style";

const DetailCondition = ({
  searchParams,
  setSearchParams,
  branchList,
  resetFlag,
  mode,
  onClickSearch,
}) => {
  const labelWidth = "4.5rem";
  const textBoxWidth = "14rem";
  const textBoxWidthShort = "8.5rem";
  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);

  // 초기화 시 지점 첫번째 값 세팅
  useEffect(() => {
    if (!isFirstEnterPage && branchList.branch_list[0]) {
      setSearchParams("branch_info", branchList.branch_list[0]);
    }
  }, [resetFlag]);

  useEffect(() => {
    setIsFirstEnterPage(false);
  }, []);

  return (
    <SearchContainer>
      <Label labelText="검색 조건 : 상세" fontWeight="bold" justifyContent="flex-start" />
      <SearchRowBox
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onClickSearch(false, 1);
          }
        }}
      >
        <SearchConditionShortBox>
          <SearchComboBox
            labelText="지점"
            labelWidth={labelWidth}
            comboItemWidth={textBoxWidth}
            data={branchList.branch_name_list}
            defaultValue={getDefaultValue("combo", searchParams.branch_info.branch_name)}
            onChangeCallback={(e, v, idx) => {
              setSearchParams("branch_info", branchList.branch_list[idx]);
            }}
          />
        </SearchConditionShortBox>
        <SearchConditionShortBox>
          <ComboBox
            labelText="결제 구분"
            labelWidth={labelWidth}
            comboItemWidth={textBoxWidth}
            data={["전체", "일반결제", "정기결제"]}
            defaultValue={getDefaultValue("combo", searchParams.paid_item_type)}
            onChangeCallback={(e) => {
              changeComboValueToEmpty(setSearchParams, "paid_item_type", e);
            }}
          />
        </SearchConditionShortBox>
        <SearchConditionLongBox>
          <DatePicker
            labelText="미납 등록일"
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidthShort}
            textMarginRight="0rem"
            defaultValue={getDefaultValue("field", searchParams.unpaid_reg_date_from)}
            onChangeCallback={(e) => {
              setSearchParams("unpaid_reg_date_from", e);
            }}
            onBlur={(e) => {
              if (!checkDateOrder(e, searchParams.unpaid_reg_date_to)) {
                setSearchParams("unpaid_reg_date_from", "");
              }
            }}
          />
          <DatePicker
            marginLeft="0.1rem"
            labelWidth="0.1rem"
            textBoxWidth={textBoxWidthShort}
            labelText="~"
            defaultValue={getDefaultValue("field", searchParams.unpaid_reg_date_to)}
            onChangeCallback={(e) => {
              setSearchParams("unpaid_reg_date_to", e);
            }}
            onBlur={(e) => {
              if (!checkDateOrder(searchParams.unpaid_reg_date_from, e)) {
                setSearchParams("unpaid_reg_date_to", "");
              }
            }}
          />
        </SearchConditionLongBox>

        {mode === "exempt" ? (
          <SearchConditionLongBox>
            <ComboBox
              labelText="미납금 제외 처리 담당자"
              labelWidth="9.5rem"
              labelMarginRight="0rem"
              comboItemWidth={textBoxWidth}
              data={[]}
              defaultValue={getDefaultValue("combo", searchParams.unpaid_reject_staff.staff_name)}
              onChangeCallback={(e) => {
                setSearchParams("unpaid_reject_staff", e.target.value);
              }}
              includeAllOption
            />
          </SearchConditionLongBox>
        ) : (
          <SearchConditionLongBox>
            <DatePicker
              labelText="납부 예정일"
              labelWidth={labelWidth}
              textMarginRight="0rem"
              textBoxWidth={textBoxWidthShort}
              defaultValue={getDefaultValue("field", searchParams.unpaid_due_date_from)}
              onChangeCallback={(e) => {
                setSearchParams("unpaid_due_date_from", e);
              }}
              onBlur={(e) => {
                if (!checkDateOrder(e, searchParams.unpaid_due_date_to)) {
                  setSearchParams("unpaid_due_date_from", "");
                }
              }}
            />
            <DatePicker
              marginLeft="0.1rem"
              labelWidth="0.1rem"
              textMarginRight="0rem"
              textBoxWidth={textBoxWidthShort}
              labelText="~"
              defaultValue={getDefaultValue("field", searchParams.unpaid_due_date_to)}
              onChangeCallback={(e) => {
                setSearchParams("unpaid_due_date_to", e);
              }}
              onBlur={(e) => {
                if (!checkDateOrder(searchParams.unpaid_due_date_from, e)) {
                  setSearchParams("unpaid_due_date_to", "");
                }
              }}
            />
          </SearchConditionLongBox>
        )}
      </SearchRowBox>
    </SearchContainer>
  );
};
export default DetailCondition;
