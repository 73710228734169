import { contentTypeJson } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

// ==================== GET ====================
// [GET] TM 이력 검색 조건 가져오기
export async function getTMHistSearchParams() {
  return await HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/cs/tm/hist/search_param", {
    params: {},
  });
}

// [GET] TM 이력 검색
export async function getTMHist(queryData) {
  const { page, searchParams } = queryData;
  const formData = new FormData();

  formData.append("page_num", page);
  formData.append("search_param", JSON.stringify(searchParams));

  return await HttpInstance.post(
    process.env.REACT_APP_USER_SERVER + "/cs/tm/hist/search",
    formData,
    contentTypeJson,
  );
}
