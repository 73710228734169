import React, { useState, useEffect } from "react";
import AgesSalesCntTable from "./AgesSalesCntTable";
import GenderSalesCntTable from "./GenderSalesCntTable";
import RegTypeSalesCntTable from "./RegTypeSalesCntTable";
import RegTypeSalesTable from "./RegTypeSalesTable";

const StatPtArea = ({ reportState }) => {
  return (
    <div style={{}}>
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <div style={{ width: "50%", paddingRight: "1rem" }}>
          <RegTypeSalesTable reportState={reportState} />
        </div>
        <div style={{ width: "50%", paddingLeft: "1rem" }}>
          <RegTypeSalesCntTable reportState={reportState} />
        </div>
      </div>

      <div style={{ display: "flex", marginTop: "1rem" }}>
        <div style={{ width: "50%", paddingRight: "1rem" }}>
          <AgesSalesCntTable reportState={reportState} />
        </div>
        <div style={{ width: "50%", paddingLeft: "1rem" }}>
          <GenderSalesCntTable reportState={reportState} />
        </div>
      </div>
    </div>
  );
};
export default StatPtArea;
