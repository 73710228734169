import { Button } from "@material-ui/core";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getUnpaidStatusList, patchExcludeUnpaid } from "apis/member/unpaid_manage_api";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import {
  SubLine,
  generateObjectZeroId,
  getPermission,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import _ from "lodash";
import { globalModalOn } from "modules/actions/Modal";
import { branch_search_init_state } from "pages/CommonModal/Search/Module/reducers/hq_branch_search_init_state";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import UnPaidProcessModal from "../../UnPay/UnPaidProcessModal";
import UnpaidStatusResultTable from "./ResultTable/UnpaidStatusResultTable";
import DetailCondition from "./SearchCondition/DetailCondition";
import MemberInfoCondition from "./SearchCondition/MemberInfoCondition";
import { useUnpaidManageStore } from "./modules/store/unpaidManageState";

const UnpaidStatusTab = () => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();

  // 전역 상태 관리
  const { setLoading, searchParams, setSearchParams, setPage, page, resetSearchParams } =
    useUnpaidManageStore().returnStoreByType("unpaidStatus");

  // 페이지 상태 관리
  const [branchList, setBranchList] = useState(_.cloneDeep(branch_search_init_state));
  const [searchResult, setSearchResult] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [resetFlag, setResetFlag] = useState(false);
  // API 상태 관리
  const [isGetUnpaidStatusList, setIsGetUnpaidStatusList] = useState(false);
  const queryData = { page: page, searchParams: searchParams };

  // 지점 권한 받아오기
  useEffect(() => {
    getPermission(storesDispatch, "SEARCH_MEMBER_MENU/UNPAID_MANAGE", true, true).then((res) => {
      setBranchList(res);
      if (
        res.branch_list.length > 0 &&
        searchParams.branch_info.branch_id === generateObjectZeroId()
      ) {
        setSearchParams("branch_info", res.branch_list[0]);
      }
    });
  }, []);

  // [GET] 미납 현황 리스트 가져오기
  const { isFetching, data: unpaidStatusList } = useQuery({
    queryKey: ["unpaidStatus", queryData],
    queryFn: () => getUnpaidStatusList(queryData),
    enabled: isGetUnpaidStatusList,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setIsGetUnpaidStatusList(false);
      setLoading(false);
    },
  });

  // [PATCH] 미납금 제외 처리
  const { mutate: mutateExcludeUnpaid } = useMutation({
    mutationFn: patchExcludeUnpaid,
    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        simpleAlert(storesDispatch, "알림", "저장되었습니다.");
        onClickSearch(false, page);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });

  /** isExcel: 엑셀 다운로드 여부, page: 페이지네이션 */
  const onClickSearch = async (isExcel, page) => {
    setPage(page);
    // setIsExcel(isExcel);
    // 미납 현황 리스트 받아오기
    setIsGetUnpaidStatusList(true);
  };

  const onClickDetail = (data) => {
    const param = {
      member_id: data.member_info.member_id,
      unpaid: data.purchase.unpaid,
      branch_id: data.branch_info.branch_id,
    };

    storesDispatch(
      globalModalOn({
        show: true,
        title: "미납금 잔액 결제",
        Content: (
          <UnPaidProcessModal
            modalParam={param}
            onModalDone={() => {
              onClickSearch(false, page);
            }}
          />
        ),
      }),
    );
  };

  const onClickExemption = (allRow, unpaidRow) => {
    const alertStr =
      "구매코드 : " +
      unpaidRow.purchase_code +
      "\n미납금 : " +
      unpaidRow.unpaid_price.toLocaleString() +
      "원\n\n" +
      "위 미납금을 제외 처리하시겠습니까?";

    const onClickYes = () => {
      const patchData = {
        member_id: allRow.member_info.member_id,
        purchase_code: unpaidRow.purchase_code,
      };
      // 미납금 제외 처리 api 호출
      mutateExcludeUnpaid(patchData);
    };
    simpleAlert(storesDispatch, "알림", alertStr, false, undefined, onClickYes, "");
  };

  // API 받아온 데이터 세팅
  useEffect(() => {
    // 미납 현황 리스트
    if (unpaidStatusList) {
      setSearchResult(unpaidStatusList.data.data);
      setTotalPage(unpaidStatusList.data.total_page);
    }
  }, [unpaidStatusList]);

  // 로딩 상태
  useEffect(() => {
    if (isFetching) {
      setLoading(isFetching);
    }
  }, [isFetching]);

  // 첫 페이지 진입 시 검색 결과를 가져오기
  useEffect(() => {
    setIsGetUnpaidStatusList(true);
  }, []);

  return (
    <div>
      <div>
        <MemberInfoCondition
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          onClickSearch={onClickSearch}
        />
        <DetailCondition
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          branchList={branchList}
          resetFlag={resetFlag}
          mode="status"
          onClickSearch={onClickSearch}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "0.75rem",
          }}
        >
          <Button
            className={clsx({
              [btnStyleClass.btnRootWhite]: true,
              [btnStyleClass.buttonType6]: true,
            })}
            onClick={() => {
              resetSearchParams();
              setResetFlag(!resetFlag);
            }}
          >
            초기화
          </Button>
          <Button
            className={clsx({
              [btnStyleClass.btnRoot]: true,
              [btnStyleClass.buttonType6]: true,
            })}
            onClick={() => {
              onClickSearch(false, 1);
            }}
            style={{ marginLeft: "0.3rem" }}
          >
            검색
          </Button>
        </div>
      </div>

      <SubLine />

      <div style={{ marginTop: "1rem" }}>
        <UnpaidStatusResultTable
          searchResult={searchResult}
          curPage={page}
          setCurPage={setPage}
          totalPage={totalPage}
          onClickSearch={onClickSearch}
          onClickDetail={onClickDetail}
          onClickExemption={onClickExemption}
        />
      </div>
    </div>
  );
};
export default UnpaidStatusTab;
