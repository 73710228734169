import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import EmployAttendanceCalendar from "./EmployAttendanceCalendar";

const EmployAttendanceModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();
  const [isModify, setIsModify] = useState(false);
  const [attendanceList, setAttendanceList] = useState(modalParam.data.attendance_list);
  const [loading, setLoading] = useState(false);

  const saveAttendance = async () => {
    try {
      const formData = new FormData();
      const info = {
        attendance_list: attendanceList,
        month: (new Date().getMonth() + 1).toString(),
        salary_report_id: modalParam.salaryReportId,
        staff_id: modalParam.data.staff_id,
        // staff_performance_id: modalParam.data.staff_performance_id,
      };

      formData.append("new_info", JSON.stringify(info));
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_SALARY_SERVER + "/salary/staff/attendance/update",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label labelText={"저장 되었습니다."} />
              </>
            ),
            onBtnOk: () => {
              onModalDone(modalParam.data.staff_performance_id); //근태 다시 GET + 기본급탭 + 메인테이블 갱신
              setIsModify(false);
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <Loading />}

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(7,1fr)",
          textAlign: "center",
          marginBottom: "0.7rem",
          fontWeight: "bold",
          color: "#222222",
        }}
      >
        <div style={{ color: "red" }}>일</div>
        <div>월</div>
        <div>화</div>
        <div>수</div>
        <div>목</div>
        <div>금</div>
        <div>토</div>
      </div>

      <EmployAttendanceCalendar
        isModify={isModify}
        setAttendanceList={setAttendanceList}
        attendanceList={attendanceList}
      />

      {modalParam.is_edit ? (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: "1rem", gap: "0.5rem" }}
        >
          <Button
            className={clsx({
              [btnStyleClass.btnRootRaw]: true,
              [btnStyleClass.buttonType5]: true,
            })}
            onClick={() => {
              if (!isModify) {
                setIsModify(true);
              } else {
                storesDispatch(
                  globalAlertOn({
                    show: true,
                    titleText: "알림",
                    bodyText: <Label labelText="저장하시겠습니까?" />,

                    onBtnYes: () => {
                      //저장 POST
                      saveAttendance();
                    },
                    onBtnNo: () => {},
                  }),
                );
              }
            }}
          >
            {isModify ? "저장" : "수정"}
          </Button>
          <Button
            className={clsx({
              [btnStyleClass.btnRootWhite]: true,
              [btnStyleClass.buttonType5]: true,
            })}
            onClick={() => {
              storesDispatch(globalModalOff());
            }}
          >
            취소
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default EmployAttendanceModal;
