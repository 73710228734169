import { contentTypeJson } from "components/CommonLib/CommonLib";
import { HttpInstance } from "lib/HttpLib";

// ==================== GET ====================
// [GET] 지점 선택에 따른 팀 List 가져오기
export async function getPtTeamCombo(queryData) {
  const branchId = queryData;

  return await HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/pt_team/search_param", {
    params: {
      branch_id: branchId,
    },
  });
}

// [GET] 팀 실적 내역 검색
export async function getPtTeamSales(queryData) {
  const { searchParams } = queryData;
  const formData = new FormData();

  formData.append("search_param", JSON.stringify(searchParams));

  return await HttpInstance.post(
    process.env.REACT_APP_STAFF_SERVER + "/pt_team/search/result",
    formData,
    contentTypeJson,
  );
}
