export const salaryStatusInitState = {
  stores: {
    data: [
      {
        performance_info: {
          is_staff_check_up: false,
          staff_performance_id: "",
        },
        personal_info: {
          account_holder: "",
          account_no: "",
          address_1: "",
          address_2: "",
          bank: "",
          birthday: "",
          email: "",
          gender: "",
          phone: "",
          sabun: "",
          social_number: "",
          staff_name: "",
          verify: false,
          zip_code: "",
        },
        salary_data_list: [
          {
            salary_info_data: {
              deduct_reason: "",
              final_salary: 0,
              salary: 0,
              salary_date: "",
              salary_deduct: 0,
            },
            salary_type: "",
          },
        ],
        salary_info_sum: {
          deduct_reason: "",
          final_salary: 0,
          salary: 0,
          salary_date: "",
          salary_deduct: 0,
        },
        staff_id: "",
        staff_info: {
          staff_id: "",
          staff_name: "",
          staff_pos: "",
          staff_rank: "",
        },
      },
    ],
    final_total_salary: 0,
    total_paid_price: 0,
  },
};
