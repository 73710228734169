import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";

const AssignListTable = ({ reportState }) => {
  const resultTableInst = new resultTable();

  const getTotalPrice = () => {
    return reportState.assign_change_history.reduce((sum, cur) => {
      return sum + cur.total_price;
    }, 0);
  };

  return (
    <div style={{ marginTop: "2rem" }}>
      <Label
        labelText="양도 및 변경 내역"
        fontWeight="bold"
        fontSize="1.2rem"
        justifyContent="flex-start"
        marginBottom="0.25rem"
      />
      <CustomTable
        columns_head={resultTableInst.columns_head}
        table_title={resultTableInst.table_title}
        rest_call={resultTableInst.get_data_from_rest}
        row_render={resultTableInst.create_table}
        rest_data={[...reportState.assign_change_history, ...reportState.branch_change_list]}
      />
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem" }}>
        <TextField
          labelText="합계"
          labelWidth="3rem"
          textBoxWidth="10rem"
          textMarginRight="0rem"
          isNumber
          defaultValue={getTotalPrice().toLocaleString()}
          endAdornment={"원"}
          disabled
        />
      </div>
    </div>
  );
};
export default AssignListTable;

class resultTable {
  table_title = "";

  // constructor() {}

  create_table = (data, idx) => {
    // console.log(data);
    return (
      <CustomRow style={{}} key={idx}>
        <CustomTableContents>{data.reason}</CustomTableContents>
        <CustomTableContents>{data.reg_info.reg_name}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_name}</CustomTableContents>
        <CustomTableContents>{data.purchase_code}</CustomTableContents>
        <CustomTableContents $isNumber>
          {data.mbshp_assign_price.toLocaleString()}
        </CustomTableContents>
        <CustomTableContents $isNumber>{data.pt_assign_price.toLocaleString()}</CustomTableContents>
        <CustomTableContents $isNumber>{data.card_new_price.toLocaleString()}</CustomTableContents>
        <CustomTableContents $isNumber>
          {data.branch_transfer_price.toLocaleString()}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.item_change_price.toLocaleString()}
        </CustomTableContents>
        <CustomTableContents $isNumber fontWeight="bold">
          {data.total_price.toLocaleString()}
        </CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "구분", width: "4.09%" },
    { title: "담당자", width: "9.09%" },
    { title: "회원번호", width: "9.09%" },
    { title: "이름", width: "9.09%" },
    { title: "구매코드", width: "9.09%" },
    { title: "회원권 양도비", width: "9.09%" },
    { title: "PT 양도비", width: "9.09%" },
    { title: "양수자 카드발급", width: "9.09%" },
    { title: "지점변경비", width: "9.09%" },
    { title: "상품변경 금액", width: "9.09%" },
    { title: "합계", width: "9.09%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
