import * as ActionTypes from "../actions/action_type";
import { ptDetailInit } from "./init_state";

const initialStete = {
  stores: {
    data: [],
  },
};
export const search_ot_manage_list = (state = initialStete, action) => {
  switch (action.type) {
    case ActionTypes.searchOtManageListSuccess: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.searchOtManageListFail:
    case ActionTypes.searchOtManageListReset:
      return { ...state, stores: initialStete.stores };
    default:
      return state;
  }
};

export const search_pt_manage_list = (state = initialStete, action) => {
  switch (action.type) {
    case ActionTypes.searchPtManageListSuccess: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.searchPtManageListFail:
    case ActionTypes.searchPtManageListReset:
      return { ...state, stores: initialStete.stores };
    default:
      return state;
  }
};

export const pt_manage_detail = (state = ptDetailInit, action) => {
  switch (action.type) {
    case ActionTypes.ptDetailInfoSuccess: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.ptDetailInfoFail:
    case ActionTypes.ptDetailInfoReset:
      return { ...state, stores: ptDetailInit.stores };
    default:
      return state;
  }
};

export const class_manage_by_staff_list = (state = ptDetailInit, action) => {
  switch (action.type) {
    case ActionTypes.classByStaffInfoSuccess: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.classByStaffInfoFail:
    case ActionTypes.classByStaffInfoReset:
      return { ...state, stores: ptDetailInit.stores };
    default:
      return state;
  }
};
