import { useCallback, useState } from "react";
// import Button from "@mui/material/Button";
import { simpleAlert } from "components/CommonLib/CommonLib";
import NormalBtn from "components/NewButton/NormalBtn";
import { GetPublicIpAddr } from "lib/HttpLib";
import { useDispatch } from "react-redux";
import LoginId from "./login_id";
import LoginPassword from "./login_password";

const LoginForm = ({ onClickLogin }) => {
  const storesDispatch = useDispatch();
  const [id, setId] = useState({ text: "", valid: true });
  const [password, setPassword] = useState({ text: "", valid: true });

  const idChangeHandler = useCallback((e) => setId({ ...id, text: e.target.value }), [id]);

  const passwordChangeHandler = useCallback(
    (e) => setPassword({ ...password, text: e.target.value }),
    [password],
  );

  const idValid = useCallback(() => {
    if (!id.text) {
      setId({ ...id, valid: false });
      return false;
    }
    setId({ ...id, valid: true });
    return true;
  }, [id]);

  const passwordValid = useCallback(() => {
    if (!password.text) {
      setPassword({ ...password, valid: false });
      return false;
    }
    setPassword({ ...password, valid: true });
    return true;
  }, [password]);

  const btnStyle = {
    marginTop: "10px",
    fontFamily: "AppleSDGothicNeo",
    height: "36px",
    padding: "10px 16px",
    backgroundColor: "#0066FF",
    color: "#FFFFFF",
    fontWeight: "400",
    fontSize: "14px",
    borderRadius: "5px",
  };

  const onClickRefreshIp = () => {
    simpleAlert(
      storesDispatch,
      "알림",
      "접속 IP 주소를 갱신하시겠습니까?",
      false,
      undefined,
      () => {
        GetPublicIpAddr();
      },
    );
  };

  return (
    <div>
      <LoginId id={id} idChangeHandler={idChangeHandler} idValid={idValid} />
      <LoginPassword
        password={password}
        passwordChangeHandler={passwordChangeHandler}
        passwordValid={passwordValid}
        idValid={idValid}
        id={id}
        onClickLogin={onClickLogin}
      />
      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
        <div>
          <NormalBtn
            theme="white"
            name="IP 갱신"
            onClick={() => {
              onClickRefreshIp();
            }}
          />
        </div>
        <div>
          <NormalBtn
            theme="blue"
            name="로그인"
            onClick={() => {
              if (idValid() && passwordValid()) {
                onClickLogin({ id: id.text, password: password.text });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
