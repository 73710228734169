import IconButton from "components/Button/IconButton";
import { dataEmpty, getCardBuyer, getCardCompList } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import ComboBox from "components/LabelInput/ComboBox";
import NumberTextField from "components/LabelInput/NumberTextField";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import TimePicker from "components/LabelInput/TimePicker";
import TableTitleWithButton from "components/TableTitleWithButton/TableTitleWithButton";

const PaymentMethod = ({
  type,
  payMethodList,
  paymentTableData,
  onClickAddMethod,
  isPtPayment,
}) => {
  return (
    <div style={{ display: "block", marginTop: "1rem", marginBottom: "1rem" }}>
      <TableTitleWithButton
        titleLabel={"결제 수단"}
        buttonText={"추가"}
        onClickEvent={() => onClickAddMethod(type)}
        // disabled={isPtPayment}
      />
      <CustomTable
        columns_head={paymentTableData.columns_head}
        rest_call={paymentTableData.get_data_from_rest}
        row_render={paymentTableData.create_table}
        isMargin={false}
        rest_data={payMethodList}
      />
    </div>
  );
};
export default PaymentMethod;

//
export class paymentMethodTable {
  table_title = "";
  method_item = ["카드", "현금", "이체", "기타"];
  cardCompList = getCardCompList();
  buyer = getCardBuyer();
  methodList = [];
  priceList = [];
  paymentTotalRef = null;
  onChangePrice = null;
  setMethodList = null;
  onClickDeleteMethod = null;
  // uploadDoneCallback = null;
  // onClickOpenPayDocModal = null;
  // isCashApproveNum = false;
  // setIsCashApproveNum = null;
  // isTransApproveNum = false;
  // setIsTransApproveNum = null;
  regDate = "2000-01-01";
  type = "";
  remainPoint = 0;
  unpaidPrice = 0;
  ptPaymentMethod = null;

  constructor(
    methodList,
    onChangePrice,
    setMethodList,
    onClickDeleteMethod,
    // uploadDoneCallback,
    // onClickOpenPayDocModal,
    // isCashApproveNum,
    // setIsCashApproveNum,
    // isTransApproveNum,
    // setIsTransApproveNum,
    regDate,
    unpaidPrice,
    type,
    remainPoint,
    ptPaymentMethod,
  ) {
    this.methodList = methodList;
    this.onChangePrice = onChangePrice;
    this.setMethodList = setMethodList;
    this.onClickDeleteMethod = onClickDeleteMethod;
    // this.uploadDoneCallback = uploadDoneCallback;
    // this.onClickOpenPayDocModal = onClickOpenPayDocModal;
    // this.isCashApproveNum = isCashApproveNum;
    // this.setIsCashApproveNum = setIsCashApproveNum;
    // this.isTransApproveNum = isTransApproveNum;
    // this.setIsTransApproveNum = setIsTransApproveNum;
    this.regDate = regDate;
    this.priceList = [];
    this.unpaidPrice = unpaidPrice;
    this.type = type;
    this.remainPoint = remainPoint;
    for (let i = 0; i < methodList.length; i++) {
      this.priceList.push(Number(this.methodList[i].price));
    }

    this.ptPaymentMethod = ptPaymentMethod;
    if (ptPaymentMethod) {
      this.method_item = [ptPaymentMethod];
    }
  }

  changeMethod = (e, itemId) => {
    var array = [...this.methodList];
    const index = array.map((item, i) => item.id).indexOf(itemId);

    if (index !== -1) {
      array[index].pay_method = e.target.value;
      if (this.methodSetter !== null) {
        this.setMethodList([...array], array[index].id, e.target.value);
      }
    }
  };

  priceChangeMethod = (e) => {
    if (isNaN(e.target.value)) {
      e.target.value = 0;
    }

    var array = [...this.methodList];
    const index = array.map((item, i) => item.id).indexOf(e.target.name);

    if (index !== -1) {
      const price = Number(e.target.value);
      array[index].price = price;
      this.priceList[index] = price;
      this.onChangePrice(this.type, array[index].pay_method, price, array[index].id);
    }
  };

  dateChangeMethod = (e, id) => {
    var array = [...this.methodList];
    const index = array.map((item, i) => item.id).indexOf(id);
    if (index !== -1) {
      array[index].pay_date = this.regDate + " " + e;
    }
  };

  installmentChangeMethod = (e, id) => {
    var array = [...this.methodList];
    const index = array.map((item, i) => item.id).indexOf(id);
    if (index !== -1) {
      array[index].installment = e;
    }
  };

  approveNoChangeMethod = (e, id, payMethod) => {
    // switch (payMethod) {
    //   case "현금":
    //     if (e > 0) {
    //       this.setIsCashApproveNum(true);
    //     } else {
    //       this.setIsCashApproveNum(false);
    //     }
    //     break;
    //   case "이체":
    //     if (e > 0) {
    //       this.setIsTransApproveNum(true);
    //     } else {
    //       this.setIsTransApproveNum(false);
    //     }
    //     break;
    //   default:
    //     break;
    // }

    var array = [...this.methodList];
    const index = array.map((item, i) => item.id).indexOf(id);
    if (index !== -1) {
      array[index].approve_no = e;
    }
  };

  cardNoChangeMethod = (e, id) => {
    var array = [...this.methodList];
    const index = array.map((item, i) => item.id).indexOf(id);
    if (index !== -1) {
      array[index].card_no = e;
    }
  };

  buyerChangeMethod = (e, id) => {
    var array = [...this.methodList];
    const index = array.map((item, i) => item.id).indexOf(id);
    if (index !== -1) {
      array[index].buyer = e;
    }
  };

  accountNameChangeMethod = (e, id) => {
    var array = [...this.methodList];
    const index = array.map((item, i) => item.id).indexOf(id);
    if (index !== -1) {
      array[index].account_name = e;
    }
  };

  getPayDate = (type, data) => {
    if (type === "date") {
      return data.substr(0, 10);
    } else {
      return data.substr(11, 5);
    }
  };

  getMaxPrice = (data) => {
    if (data.pay_method === "포인트") {
      return this.remainPoint < this.unpaidPrice ? this.remainPoint : this.unpaidPrice;
    }
    return this.unpaidPrice;
  };

  // 결제 지불 수단에 따른 업로드 아이콘 생성
  // createUploadIcon = (data) => {
  //   switch (data.pay_method) {
  //     case "카드":
  //       return (
  //         <IconButton
  //           type="upload_small"
  //           width="2.2rem"
  //           onClick={() => this.onClickOpenPayDocModal(data)}
  //           tooltip={"영수증 업로드"}
  //         />
  //       );
  //     case "현금":
  //       return (
  //         this.isCashApproveNum && (
  //           <IconButton
  //             type="upload_small"
  //             width="2.2rem"
  //             onClick={() => this.onClickOpenPayDocModal(data)}
  //             tooltip={"영수증 업로드"}
  //           />
  //         )
  //       );
  //     case "이체":
  //       return (
  //         this.isTransApproveNum && (
  //           <IconButton
  //             type="upload_small"
  //             width="2.2rem"
  //             onClick={() => this.onClickOpenPayDocModal(data)}
  //             tooltip={"영수증 업로드"}
  //           />
  //         )
  //       );
  //     case "기타":
  //       break;
  //     default:
  //       break;
  //   }
  // };

  create_table = (data, idx) => {
    if (this.ptPaymentMethod) {
      data.pay_method = this.ptPaymentMethod;
    }

    return (
      <CustomRow key={idx}>
        <CustomTableContents>
          <ComboBox
            labelMarginRight="0rem"
            marginRight="0rem"
            comboItemWidth={"5rem"}
            data={this.method_item}
            defaultValue={data.pay_method}
            defaultKey={data.id}
            onChangeCallback={(e) => {
              this.priceChangeMethod({ target: { value: 0, name: data.id } });
              this.changeMethod(e, data.id);
              data.price = 0;
            }}
            isMargin={false}
            disabled={this.ptPaymentMethod && this.ptPaymentMethod.length > 0}
          />
        </CustomTableContents>

        <CustomTableContents>
          {data.pay_method === "카드" ? (
            <div style={{ display: "flex" }}>
              <NumberTextField
                isString
                textMarginRight="0.25rem"
                textBoxWidth={"6rem"}
                defaultValue={data.installment_plan || "0"}
                endAdornment="개월"
                maxLength={2}
                onChangeCallback={(e) => this.installmentChangeMethod(e, data.id)}
              />
            </div>
          ) : (
            <></>
          )}
        </CustomTableContents>
        <CustomTableContents>
          {data.pay_method === "이체" || data.pay_method === "기타" ? (
            <TextField
              required
              labelMarginRight={"0rem"}
              textMarginRight={"0rem"}
              textBoxWidth={"7.5rem"}
              isMarginTop={false}
              isMarginRight={false}
              defaultValue={data.account_name}
              onChange={(e) => this.accountNameChangeMethod(e.target.value, data.id)}
            />
          ) : (
            <></>
          )}
        </CustomTableContents>
        <CustomTableContents>
          <NumberTextField
            required
            labelMarginRight={"0rem"}
            textMarginRight={"0rem"}
            name={data.id}
            textBoxWidth={"9rem"}
            defaultValue={data.price}
            minValue={0}
            maxValue={this.getMaxPrice(data)}
            onChange={(e) => {
              // 여기선.. target.name 때문에 onChange를 쓰고 있음....
              this.priceChangeMethod(e);
            }}
            endAdornment={"원"}
          />
        </CustomTableContents>
        <CustomTableContents>
          {data.pay_method !== "포인트" ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <TextField
                labelMarginRight={"0rem"}
                textMarginRight={"0rem"}
                textBoxWidth={"6.75rem"}
                defaultValue={this.getPayDate("date", data.pay_date) || this.regDate}
                disabled
              />
              <TimePicker
                labelMarginRight="0rem"
                textBoxWidth="8.5rem"
                defaultValue={this.getPayDate("time", data.pay_date)}
                onChange={(e) => this.dateChangeMethod(e, data.id)}
              />
            </div>
          ) : (
            <></>
          )}
        </CustomTableContents>
        <CustomTableContents>
          {data.pay_method !== "포인트" && data.pay_method !== "기타" ? (
            <TextField
              required
              labelMarginRight={"0rem"}
              textMarginRight={"0rem"}
              textBoxWidth={"8rem"}
              isMarginTop={false}
              isMarginRight={false}
              defaultValue={data.approve_no}
              maxLength={10}
              isNumber={true}
              onChange={(e) => this.approveNoChangeMethod(e.target.value, data.id, data.pay_method)}
            />
          ) : (
            <></>
          )}
        </CustomTableContents>
        <CustomTableContents>
          {data.pay_method === "카드" ? (
            <TextField
              required
              labelMarginRight={"0rem"}
              textMarginRight={"0rem"}
              textBoxWidth={"7rem"}
              defaultValue={data.card_no}
              isNumber={true}
              maxLength={8}
              onChange={(e) => this.cardNoChangeMethod(e.target.value, data.id)}
            />
          ) : (
            <></>
          )}
        </CustomTableContents>
        <CustomTableContents>
          {data.pay_method === "카드" ? (
            <ComboBox
              required
              labelMarginRight="0rem"
              marginRight="0rem"
              data={this.buyer}
              comboItemWidth={"8.5rem"}
              isMargin={false}
              defaultValue={data.buyer}
              onChangeCallback={(e) => this.buyerChangeMethod(e.target.value, data.id)}
            />
          ) : (
            <></>
          )}
        </CustomTableContents>
        {/* 업로드 아이콘 생성 */}
        {/* <CustomTableContents align="center">{this.createUploadIcon(data)}</CustomTableContents> */}
        {/* 삭제 아이콘 생성 */}
        <CustomTableContents align="center">
          <IconButton
            width="1.5rem"
            height="1.5rem"
            type="delete"
            tooltip={"행 삭제"}
            onClick={() => this.onClickDeleteMethod(this.type, data.id)}
          />
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "결제수단", width: "6.5%" },
    { title: "할부", width: "6%" },
    { title: "예금주", width: "9%" },
    { title: "결제금액", width: "11%" },
    { title: "승인(결제) 일시", width: "15%" },
    { title: "승인번호", width: "10%" },
    { title: "카드번호\n앞 8자리", width: "9%" },
    { title: "매입사", width: "12%" },
    // { title: "", width: "3%" },
    { title: "", width: "3.5%" },
  ];
  get_data_from_rest = (data) => {
    let totalCurrent = 0;
    if (dataEmpty(data)) {
      return [];
    }
    return data;
  };

  payCardInfoItem = (data) => {
    if (data.pay_method === "카드") {
      return (
        <SearchComboBox
          labelMarginRight="0rem"
          marginRight="0rem"
          data={this.cardCompList}
          comboItemWidth="7rem"
          defaultValue={data.card_comp || this.cardCompList[0]}
          onChangeCallback={(e, v) => {
            data.card_comp = v;
          }}
        />
      );
    } else {
      return "";
    }
  };
}
