import { search_result_staff, search_result_member, search_param_member } from "./search_reducer";

import { combineReducers } from "redux";

export const searchModalReducer = combineReducers({
  search_result_member,
  search_result_staff,
  search_param_member,
});

// export const lockerSettingReducer = {
//   locker_setting,
//   locker_setting_show,
//   locker_allocate_show,
//   locker_detail_show,
// };

// export default lockerReducer;
