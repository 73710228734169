import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import {
  dataEmpty,
  generateObjectDummyId,
  getCurrentDate,
  restEmpty,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch } from "react-redux";
import { HqBranchFilter } from "../CommonLib/SelectFilter";

const HqInfoCard = forwardRef((props, ref) => {
  const { staffInfoState, hqPermissions } = props;
  const btnStyleClass = btnStyles();
  const [itemInfoList, setItemInfoList] = useState(staffInfoState.contents.staff_obj.affiliation);
  const [canEditAffiliation, setCanEditAffiliation] = useState(false);
  const storesDispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    getItemList: () => {
      return itemInfoList;
    },
  }));

  const getWorkInfoList = () => {
    HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/staff/work_info_list", {
      params: {
        staff_id: staffInfoState.contents.staff_obj.staff_id,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          if (res.data.data.length === 0) {
            setCanEditAffiliation(true);
          } else {
            setCanEditAffiliation(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  useEffect(() => {
    getWorkInfoList();
    setItemInfoList(staffInfoState.contents.staff_obj.affiliation);
  }, [staffInfoState.contents.staff_obj.affiliation]);

  const onModalAddInfo = () => {
    const filteredHqBranchInfo = HqBranchFilter(
      staffInfoState.selector,
      staffInfoState.contents.staff_obj.affiliation,
      [], // ???
    );

    if (filteredHqBranchInfo.hq_list.length === 0) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="더 이상 추가할 수 있는 본부가 없습니다." />,
          onBtnOk: () => {},
        }),
      );
    } else {
      const param = {
        title: "소속 정보",
        modalParam: {
          btnMode: "임시저장",
          work_info: "",
          hq_branch_list: filteredHqBranchInfo,
          affiliation: staffInfoState.contents.staff_obj.affiliation,
          hqPermissions: hqPermissions,
        },
      };
      props.onClickOpenModal("소속정보", param, btnItemPush);
    }
  };

  const btnItemPush = (data) => {
    const baseItemInfo = {
      view_id: generateObjectDummyId(),
      branch_info: data.branchInfo,
      hq_info: data.hqInfo,
      is_hq_only: data.isHqOnly,
      reg_info: {
        created_at: getCurrentDate(),
        reg_id: "",
        reg_name: "",
        upd_id: "",
        upd_name: "",
        updated_at: "",
      },
    };

    if (itemInfoList.length === 0) {
      setItemInfoList([baseItemInfo]);
    } else {
      setItemInfoList([...itemInfoList, baseItemInfo]);
    }
    staffInfoState.contents.staff_obj.affiliation.push(baseItemInfo);
    storesDispatch(globalModalOff());
  };

  const btnItemPop = (targetId) => {
    const array = [...itemInfoList]; // make a separate copy of the array
    const index = array.map((item) => item.view_id).indexOf(targetId);

    if (index !== -1) {
      array.splice(index, 1);

      setItemInfoList(array);
      staffInfoState.contents.staff_obj.affiliation.splice(index, 1);
    }
  };

  const itemList = new itemInfoTable(props.isViewMode, btnItemPop, canEditAffiliation);

  return (
    <div>
      <div style={{ textAlign: "right" }}>
        {canEditAffiliation ? (
          <Button
            className={clsx({
              [btnStyleClass.btnRoot]: true,
              [btnStyleClass.buttonType2]: true,
            })}
            onClick={() => onModalAddInfo()}
          >
            추가
          </Button>
        ) : (
          <></>
        )}
      </div>
      <div style={{ marginTop: "0.25rem" }}>
        <CustomTable
          columns_head={itemList.columns_head}
          table_title={itemList.table_title}
          rest_call={itemList.get_data_from_rest}
          row_render={itemList.create_table}
          rest_data={itemInfoList}
        />
      </div>
    </div>
  );
});
export default HqInfoCard;

class itemInfoTable {
  table_title = "";
  btnStyleClass = btnStyles();
  btnPayMethodPop = null;
  isViewMode = true;
  canEditAffiliation = false;

  constructor(isViewMode, btnPayMethodPop, canEditAffiliation) {
    this.btnPayMethodPop = btnPayMethodPop;
    this.isViewMode = isViewMode;
    this.canEditAffiliation = canEditAffiliation;
  }

  create_table = (data, key) => {
    return (
      <CustomRow key={key} style={{ width: "100%" }}>
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.reg_info.reg_name}</CustomTableContents>
        <CustomTableContents>
          {data.hq_info === undefined ? "" : data.hq_info.hq_name}
        </CustomTableContents>
        <CustomTableContents>
          {data.branch_info === undefined ? "" : data.branch_info.branch_name}
        </CustomTableContents>
        <CustomTableContents>{data.is_hq_only ? "Y" : "N"}</CustomTableContents>
        <CustomTableContents>
          {this.canEditAffiliation ? (
            <IconButton type="delete" onClick={() => this.btnPayMethodPop(data.view_id)} />
          ) : (
            ""
          )}
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "등록일", width: "18%" },
    { title: "담당자", width: "15%" },
    { title: "본부명", width: "18%" },
    { title: "지점명", width: "18%" },
    { title: "본부직원여부", width: "8%" },
    { title: "", width: "8%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
