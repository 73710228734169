import { dateToStr } from "components/CommonLib/CommonLib";
import TextField from "components/LabelInput/TextField";

const EmployAttendanceCalendar = ({ isModify, attendanceList, setAttendanceList }) => {
  const today_style = {
    backgroundColor: "#003CCB",
    color: "#ffffff",
    width: "1.2rem",
    height: "1.2rem",
    textAlign: "center",
    boxSizing: "border-box",
    paddingTop: "0.1rem",
    fontSize: "0.72rem",
    borderRadius: "50%",
  };

  const getToday = () => {
    const day = dateToStr(new Date()).slice(8);
    if (day[0] === "0") {
      const newDay = day.slice(1);
      return Number(newDay);
    } else {
      return Number(day);
    }
  };

  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(7,1fr)",
          gridAutoRows: "minmax(6rem,auto)",
          gridAutoColumns: "1fr",
          boxSizing: "border-box",
        }}
      >
        {attendanceList.map((week, weekIdx) =>
          week.map((day, idx) => (
            <div
              key={idx}
              style={{
                border: "1px solid #EBEBEB",
                paddingLeft: "0.4rem",
                paddingTop: "0.3rem",
                padding: "0.3rem",
                backgroundColor: `${day.date > 0 ? "" : "#DEDEDE"}`,
                color: `${idx === 0 ? "#DE0909" : ""}`,
              }}
            >
              {getToday() === day.date ? (
                <div style={today_style}>{day.date}</div>
              ) : day.date > 0 ? (
                <div>{day.date}</div>
              ) : (
                ""
              )}

              {day.date > 0 ? (
                <div>
                  <div>
                    <TextField
                      labelWidth={"3.5rem"}
                      textBoxWidth={"5rem"}
                      labelText="출근시간"
                      textAlign="right"
                      defaultValue={day.work_time_info.from_time}
                      disabled
                    />
                  </div>
                  <div style={{ marginTop: "0.2rem" }}>
                    <TextField
                      labelWidth={"3.5rem"}
                      textBoxWidth={"5rem"}
                      isNumber
                      labelText="근무시간"
                      defaultValue={day.work_time_info.work_time}
                      disabled={!isModify}
                      onChangeCallback={(e) => {
                        setAttendanceList((cur) => {
                          const obj = [...cur];
                          obj[weekIdx][idx].work_time_info.work_time = e;
                          return obj;
                        });
                      }}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          )),
        )}
      </div>
    </>
  );
};

export default EmployAttendanceCalendar;
