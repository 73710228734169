import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import CardManageInfoWrapper from "./CardManageInfoWrapper";

import { useLocation } from "react-router";

const CardManageInfoService = () => {
  const { state } = useLocation();

  return (
    <PageFrame
      children={
        <div>
          <PageTitle titleText="카드 상세 정보" />
          <CardManageInfoWrapper getState={state} />
        </div>
      }
    />
  );
};
export default CardManageInfoService;
