import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, dateToYearMonth, simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import MonthPicker from "components/LabelInput/MonthPicker";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { salaryReportPost } from "./CreateReportApi";

const CreateSalaryReport = (props) => {
  // const { modalParam, onModalDone, curPage, searchList, maxPage, onClickSearchEvent } = props;
  const { modalParam, onModalDone } = props;
  const btnStyleClass = btnStyles();
  const labelWidth = "3rem";
  const compWidth = "9rem";
  const [loading, setLoading] = useState(false);
  const [hqIdx, setHqIdx] = useState(0);
  const storesDispatch = useDispatch();
  const [createParam, setCreateParam] = useState(salaryReportPost);

  useEffect(() => {
    getInitData();
    return () => {
      setCreateParam(salaryReportPost);
    };
  }, []);

  useEffect(() => {
    if (createParam.combo_selector.target_list.length > 0) {
      createParam.hq_info = createParam.combo_selector.target_list[hqIdx].hq_info;
      createParam.branch_info = createParam.combo_selector.target_list[hqIdx].branch_list[0];
      createParam.base_date = dateToYearMonth(new Date());

      // // TEMPORARY CODE (테스트 지점 자동 선택)
      // if (createParam.combo_selector.target_list[hqIdx].branch_list.length > 3) {
      //   createParam.branch_info = createParam.combo_selector.target_list[hqIdx].branch_list[1];
      // }
    }
  }, [createParam]);

  const onClickCreateBtn = () => {
    // validation
    if (
      !createParam.hq_info.hq_id ||
      !createParam.branch_info.branch_id ||
      !createParam.base_date
    ) {
      simpleAlert(storesDispatch, "알림", "입력되지 않은 값이 있습니다.");
      return;
    }

    // do create
    const popupText =
      "[" +
      createParam.branch_info.branch_name +
      "] 지점의 " +
      createParam.base_date +
      "월 급여를 생성하시겠습니까?";
    simpleAlert(storesDispatch, "알림", popupText, false, undefined, doCreateReport);
  };

  const doCreateReport = async () => {
    setLoading(true);

    await HttpInstance.post(
      process.env.REACT_APP_SALARY_SERVER + "/salary/manage_list/create",
      createParam,
      contentTypeJson,
    )
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          onModalDone(true);
          simpleAlert(storesDispatch, "알림", "급여 리포트 생성이 완료되었습니다.");
        }
      })
      .catch((err) => {
        console.log(err);
        onModalDone(false);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //초기 검색조건 데이터 POST
  const getInitData = async () => {
    try {
      const formData = new FormData();
      formData.append("", JSON.stringify());
      setLoading(true);

      const res = await HttpInstance.get(
        process.env.REACT_APP_SALARY_SERVER + "/salary/manage_list/create_param",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        setCreateParam(res.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <Loading />}
      <div style={{ display: "flex" }}>
        <div>
          <ComboBox
            labelWidth={labelWidth}
            comboItemWidth={compWidth}
            labelText="본부"
            data={createParam.combo_selector.hq_list}
            defaultValue={
              createParam.combo_selector.hq_list.length > 0 &&
              createParam.combo_selector.hq_list[hqIdx]
            }
            onChangeCallback={(e) => {
              const idx = createParam.combo_selector.hq_list.findIndex(
                (el) => el === e.target.value,
              );
              setHqIdx(idx);
              createParam.hq_info = createParam.combo_selector.target_list[idx].hq_info;

              // 본부 선택 시 해당 본부의 첫번째 지점 선택
              if (createParam.combo_selector.target_list[idx].branch_list.length > 0) {
                createParam.branch_info =
                  createParam.combo_selector.target_list[idx].branch_list[0];
              }
            }}
          />
        </div>
        <div style={{ marginLeft: "2rem" }}>
          <ComboBox
            labelWidth={labelWidth}
            comboItemWidth={compWidth}
            labelText="지점"
            data={createParam.combo_selector.branch_list[hqIdx]}
            defaultValue={
              createParam.combo_selector.branch_list.length > 0 &&
              createParam.combo_selector.branch_list[hqIdx][0]
            }
            onChangeCallback={(e) => {
              const branchIdx = createParam.combo_selector.branch_list[hqIdx].findIndex(
                (el) => el === e.target.value,
              );
              createParam.branch_info =
                createParam.combo_selector.target_list[hqIdx].branch_list[branchIdx];
            }}
          />
        </div>
        <div style={{ display: "flex", marginLeft: "2rem" }}>
          <MonthPicker
            labelWidth={"4.5rem"}
            minWidth={"9rem"}
            labelText="보고서 연월"
            defaultValue={dateToYearMonth(new Date())}
            onChangeCallback={(e) => {
              createParam.base_date = e;
            }}
          />
        </div>
      </div>

      {/* 항목별 체크박스 리스트 */}
      {/* <div style={{ display: "flex", marginTop: "2rem" }}>
        <div style={{ marginLeft: "2rem" }}>
          <CheckBox
            isTable
            labelMarginLeft="0.25rem"
            labelText="기본급"
            defaultValue={createParam.is_salary_basic}
            onChangeCallback={(e) => {
              createParam.is_salary_basic = e.checked;
            }}
          />
        </div>
        <div style={{ marginLeft: "2rem" }}>
          <CheckBox
            isTable
            labelMarginLeft="0.25rem"
            labelText="회원권 판매 인센티브"
            defaultValue={createParam.is_salary_mbshp}
            onChangeCallback={(e) => {
              createParam.is_salary_mbshp = e.checked;
            }}
          />
        </div>
        <div style={{ marginLeft: "2rem" }}>
          <CheckBox
            isTable
            labelMarginLeft="0.25rem"
            labelText="PT 수당"
            defaultValue={createParam.is_salary_pt}
            onChangeCallback={(e) => {
              createParam.is_salary_pt = e.checked;
            }}
          />
        </div>
        <div style={{ marginLeft: "2rem" }}>
          <CheckBox
            isTable
            labelMarginLeft="0.25rem"
            labelText="GX 수당"
            defaultValue={createParam.is_salary_gx}
            onChangeCallback={(e) => {
              createParam.is_salary_gx = e.checked;
            }}
          />
        </div>
        <div style={{ marginLeft: "2rem" }}>
          <CheckBox
            isTable
            labelMarginLeft="0.25rem"
            labelText="기타 수당"
            defaultValue={createParam.is_salary_etc}
            onChangeCallback={(e) => {
              createParam.is_salary_etc = e.checked;
            }}
          />
        </div>
      </div> */}
      <div style={{ display: "flex", marginTop: "2rem", justifyContent: "center" }}>
        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={() => onClickCreateBtn()}
          style={{ marginLeft: "0.5rem" }}
        >
          확인
        </Button>
      </div>
    </div>
  );
};
export default CreateSalaryReport;
