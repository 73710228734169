import { excelExport } from "components/CommonLib/CommonLib";

const getAge = (birth) => {
  const thisYear = new Date().getFullYear();
  const birthYear = new Date(birth).getFullYear();
  let age = thisYear - birthYear + 1;
  return age;
};

export const exportToExcel = (data, fileName) => {
  const newData = [];
  for (let i = 0; i < data.length; i++) {
    const row = {};
    row.회원번호 = data[i].membership_info.member_no;
    row.이름 = data[i].membership_info.member_name;
    row.성별 = data[i].membership_info.gender;
    row.연락처 = data[i].membership_info.phone;
    row.나이 = getAge(data[i].membership_info.birth);
    row.PT_계약_상품명 = data[i].pt_info.item_name;
    row.최종_회원권_종료일 = data[i].active_info.last_mbshp_to_date;
    row.잔여_세션 = data[i].pt_info.remain_session_cnt;
    row.전체_세션 = data[i].pt_info.base_session_cnt + data[i].pt_info.service_session_cnt;
    row.수업_담당자 = data[i].cur_manager.staff_info.staff_name;
    row.최근_수업일 = data[i].pt_info.latest_class_date;
    row.특이사항 = data[i].pt_info.comment;
    row.비고 = data[i].pt_info.remarks;
    row.PT상태 = data[i].pt_info.root_status;
    row.PT_수업상태 = data[i].pt_info.class_status;
    row.총_상품금액 = data[i].pt_price.total_price;

    newData.push(row);
  }

  excelExport(newData, fileName);
};
