import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import {
  contentTypeJson,
  dateToStr,
  makeNextDay,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import FileUpload from "components/LabelInput/FileUpload";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const LongPostponeModal = ({ modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const REASON = ["장기 출장", "출산", "군 입대", "치료", "기타"];
  const REASON_TEXT = [
    "※ 출장확인서 등 회사 확인서류",
    "※ 진료확인서 등 병원 확인서류",
    "※ 입영통지서",
    "※ 진료확인서 등 병원 확인 서류",
    "※ 사유 증빙이 가능한 서류",
  ];

  const [reasonIdx, setReasonIdx] = useState();
  const [docList, setDocList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();

  //초기 문서 세팅
  useEffect(() => {
    if (modalParam) {
      setDocList(modalParam.viewState.contract_doc_list);
    }
  }, [modalParam]);

  const uploadDoneCallback = (result) => {
    const arr = [];
    arr.push(result);
    setDocList(arr);
  };

  //장기연기신청 POST
  const registerLongPostpone = async () => {
    try {
      const formData = new FormData();
      modalParam.viewState.contract_doc_list = docList;
      formData.append("new_info", JSON.stringify(modalParam.viewState));
      formData.append("the_file", file);

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_USER_SERVER + "/change/postpone_long_update",
        formData,
        contentTypeJson,
      );
      if (res.error) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            reqConfirm: true,
            bodyText: (
              <>
                <Label labelText={"장기연기 신청이 완료되었습니다."} />
              </>
            ),

            onBtnOk: () => {
              onModalDone(); //refreshStore + 모달 닫기
            },
          }),
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const onClickEvt = () => {
    //유효성 검사
    if (!modalParam.viewState.contents.active_postpone.from_date) {
      simpleAlert(storesDispatch, "알림", "연기 시작일을 입력해주세요.");
    } else if (!modalParam.viewState.contents.active_postpone.to_date) {
      simpleAlert(storesDispatch, "알림", "연기 종료일을 입력해주세요.");
    } else if (!modalParam.viewState.contents.active_postpone.remarks) {
      simpleAlert(storesDispatch, "알림", "사유를 선택해주세요.");
    } else if (!modalParam.viewState.contents.active_postpone.file_due_date) {
      simpleAlert(storesDispatch, "알림", "서류 제출 기한을 입력해주세요.");
    } else {
      //유효성 검사 통과
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="장기연기를 신청하시겠습니까?" />
            </>
          ),
          onBtnYes: () => {
            registerLongPostpone();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  return (
    <div
      style={{
        marginTop: "1.625rem",
      }}
    >
      {loading && <Loading />}

      <div style={{ display: "flex", marginBottom: "0.4rem" }}>
        <DatePicker
          labelWidth={"6rem"}
          minWidth={"9.9rem"}
          labelText="연기기간"
          defaultValue={modalParam.viewState.contents.active_postpone.from_date}
          minDate={modalParam.viewState.permission.limit_from_date}
          maxDate={modalParam.viewState.permission.limit_to_date}
          onChangeCallback={(e) => {
            if (
              modalParam.viewState.contents.active_postpone.to_date &&
              new Date(e) > new Date(modalParam.viewState.contents.active_postpone.to_date)
            ) {
              storesDispatch(
                globalAlertOn({
                  show: true,
                  titleText: "알림",
                  bodyText: (
                    <>
                      <Label labelText="시작일은 종료일보다 클 수 없습니다." />
                    </>
                  ),
                  onBtnOk: () => {},
                }),
              );
              e = "";
            } else {
              modalParam.viewState.contents.active_postpone.from_date = e;
            }
          }}
        />
        <DatePicker
          labelWidth={"0.4rem"}
          labelText="~"
          minWidth={"9.9rem"}
          minDate={makeNextDay(modalParam.viewState.permission.limit_from_date)}
          onChangeCallback={(e) => {
            if (
              modalParam.viewState.contents.active_postpone.from_date &&
              new Date(modalParam.viewState.contents.active_postpone.from_date) > new Date(e)
            ) {
              storesDispatch(
                globalAlertOn({
                  show: true,
                  titleText: "알림",
                  bodyText: (
                    <>
                      <Label labelText="시작일은 종료일보다 클 수 없습니다." />
                    </>
                  ),
                  onBtnOk: () => {},
                }),
              );
              e = "";
            } else {
              modalParam.viewState.contents.active_postpone.to_date = e;
            }
          }}
        />
      </div>
      <div style={{ marginBottom: "0.4rem" }}>
        <ComboBox
          labelWidth={"6rem"}
          comboItemWidth={"21.5rem"}
          labelText="사유"
          data={REASON}
          onChangeCallback={(e) => {
            modalParam.viewState.contents.active_postpone.remarks = e.target.value;
            const idx = REASON.findIndex((el) => el === e.target.value);
            setReasonIdx(idx);
          }}
        />
      </div>

      <IconPostponeDocInfo textBoxWidth="16.8rem" setFile={setFile} />
      {/* <DocInfo
        docList={docList}
        uploadDoneCallback={uploadDoneCallback}
        onlyOne
        textBoxWidth="16.8rem"
      /> */}

      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          // width: "40%",
          margin: "0.3rem 0 0.3rem 6.7rem",
        }}
      >
        <Label
          labelText={reasonIdx !== undefined ? REASON_TEXT[reasonIdx] : ""}
          color="#DE0909"
          fontSize="0.7rem"
        />
      </div>

      <DatePicker
        labelWidth={"6rem"}
        labelText="서류 제출 기한"
        minWidth={"21.5rem"}
        minDate={dateToStr(new Date())}
        defaultValue={moment(Date.now())}
        onChangeCallback={(e) => {
          modalParam.viewState.contents.active_postpone.file_due_date = e;
        }}
      />
      <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType5]: true,
          })}
          onClick={onClickEvt}
        >
          신청
        </Button>
      </div>
    </div>
  );
};
export default LongPostponeModal;

const IconPostponeDocInfo = (props) => {
  // console.log(props);
  return (
    <div style={{ marginBottom: "0.5rem", display: "flex" }}>
      {/* <Label labelText="제출 서류" justifyContent="left" fontSize="1.125rem" fontWeight="bold" /> */}
      <Label labelText="제출 서류" justifyContent="left" />
      <div style={{ marginTop: "1.25rem", marginBottom: "0.5rem" }}>
        <FileUpload
          // key={idx}
          // marginTop="0.5rem"
          labelWidth={"2.4rem"}
          textBoxWidth={props.textBoxWidth ? props.textBoxWidth : "7.5rem"}
          textMarginRight={"0rem"}
          // labelText={el.file_info.doc_name}
          onUploadFile={(file) => {
            props.setFile(file);
            // console.log("file", props.docList[idx]);
            // uploadFile(props.docList[idx], file, props.uploadDoneCallback);
          }}
          hideDownload
        />
      </div>
    </div>
  );
};
