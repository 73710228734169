import { put, call } from "redux-saga/effects";
// import get_api from "../../../../../lib/LockerSettings/GetApi";
import get_api from "../api/GetApi";
import { unPayViewAction } from "../actions/index";
import { globalStartLoading, globalFinishLoading } from "modules/actions/Loading/index";

export function* getUnPayViewInfoData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getViewInfo, param);

    if (data.data === null) {
      yield put(unPayViewAction.loadUnPayViewSuccess(false));
    } else {
      yield put(unPayViewAction.loadUnPayViewSuccess(data));
    }
    yield put(globalFinishLoading());
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(unPayViewAction.loadUnPayViewFail(error));
    yield put(globalFinishLoading());
  }
}
