export const staffInfoViewAction = "STAFF/INFO_VIEW";
export const staffInfoViewSuccessAction = "STAFF/INFO_VIEW/SUCCESS";
export const staffInfoViewFailAction = "STAFF/INFO_VIEW/FAIL";
export const staffInfoViewResetAction = "STAFF/INFO_VIEW/RESET";

export const staffWorkListViewAction = "STAFF/WROK_INFO_LIST";
export const staffWorkListViewSuccessAction = "STAFF/WROK_INFO_LIST/SUCCESS";
export const staffWorkListViewFailAction = "STAFF/WROK_INFO_LIST/FAIL";
export const staffWorkListViewResetAction = "STAFF/WROK_INFO_LIST/RESET";

export const staffWorkInfoViewAction = "STAFF/WROK_INFO_VIEW";
export const staffWorkInfoViewSuccessAction = "STAFF/WROK_INFO_VIEW/SUCCESS";
export const staffWorkInfoViewFailAction = "STAFF/WROK_INFO_VIEW/FAIL";
export const staffWorkInfoViewResetAction = "STAFF/WROK_INFO_VIEW/RESET";

export const staffGxFeeInfoViewAction = "STAFF/GX_FEE";
export const staffGxFeeInfoViewSuccessAction = "STAFF/GX_FEE/SUCCESS";
export const staffGxFeeInfoViewFailAction = "STAFF/GX_FEE/FAIL";
export const staffGxFeeInfoViewResetAction = "STAFF/GX_FEE/RESET";
