import Box from "@mui/material/Box";
import PropTypes from "prop-types";

export default function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ backgroundColor: props.bgColor ? props.bgColor : props.bgwhite ? "white" : "" }}
    >
      {value === index && (
        <Box sx={props.noPadding ? { p: 0 } : { p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
