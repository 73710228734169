import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { emptyTabStyle, tabBorder, tabStyle } from "components/CommonComp";
import Loading from "components/Loading/Loading/Loading";
import SubTitle from "components/PageTitle/SubTitle";
import A11yProps from "components/TabControl/A11yProps";
import TabPanel from "components/TabControl/TabPanel";
import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import EtcSalaryInfoView from "./EtcSalaryInfo/EtcSalaryInfoView";
import GxSalaryInfoView from "./GxSalaryInfo/GxSalaryInfoView";
import PtSalaryInfoView from "./PtSalaryInfo/PtSalaryInfoView";
import SalaryBasicInfoView from "./SalaryBasicInfo/SalaryBasicInfoView";
import SalaryCurrentInfoView from "./SalaryCurrentInfo/SalaryCurrentInfoView";
import MembershipSalesIncentiveList from "./SalesIncentiveInfo/MembershipSalesIncentiveList";
import { useSalaryInfoStore } from "./modules/store/salaryInfoState";

const SalaryInfoTabView = ({ summaryData, onClickOpenModal, salaryReportId, is_edit }) => {
  // 전역 상태 관리
  const { salaryInfoLoading: loading } = useSalaryInfoStore();
  // 페이지 상태 관리
  const [tabIndex, setTabIndex] = useState(0);

  const onChangeTabIndex = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const getTabStyle = (checkBool, idx, tabIndex) => {
    if (checkBool) {
      return tabStyle(idx, tabIndex);
    } else {
      return emptyTabStyle();
    }
  };

  const getTabStyleStr = (checkBool, tabStr) => {
    if (checkBool) {
      return tabStr;
    } else {
      return "";
    }
  };

  return (
    <>
      {loading && <Loading />}
      <div style={{ marginTop: "2rem" }}>
        <div style={{ marginLeft: "0.5rem" }}>
          <SubTitle titleText="직원별 현황" fontSize="1.2rem" />
        </div>

        <div>
          <Box sx={{ bgcolor: "background.paper" }}>
            <Tabs
              value={tabIndex}
              onChange={onChangeTabIndex}
              textColor="inherit"
              sx={{ minHeight: "40px" }}
              TabIndicatorProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0)" } }}
            >
              <Tab label="급여현황" {...A11yProps(0)} style={tabStyle(0, tabIndex)} />
              {/* <Tab label="기본급" {...A11yProps(1)} style={tabStyle(1, tabIndex)} />
            <Tab label={`회원권 판매\n인센티브`} {...A11yProps(2)} style={tabStyle(2, tabIndex)} /> */}
              {/* <Tab label="PT 수당" {...A11yProps(3)} style={tabStyle(3, tabIndex)} /> */}
              <Tab
                label={getTabStyleStr(summaryData.is_salary_basic, "기본급")}
                {...A11yProps(3)}
                // style={emptyTabStyle()}
                style={getTabStyle(summaryData.is_salary_basic, 1, tabIndex)}
              />
              <Tab
                label={getTabStyleStr(summaryData.is_salary_mbshp, "회원권 판매\n인센티브")}
                {...A11yProps(3)}
                // style={emptyTabStyle()}
                style={getTabStyle(summaryData.is_salary_mbshp, 2, tabIndex)}
              />
              <Tab
                label={getTabStyleStr(summaryData.is_salary_pt, "PT 수당")}
                {...A11yProps(3)}
                // style={emptyTabStyle()}
                style={getTabStyle(summaryData.is_salary_pt, 3, tabIndex)}
              />
              <Tab
                label={getTabStyleStr(summaryData.is_salary_gx, "GX 수당")}
                {...A11yProps(4)}
                // style={tabStyle(4, tabIndex)}
                style={getTabStyle(summaryData.is_salary_gx, 4, tabIndex)}
              />
              <Tab
                label={getTabStyleStr(summaryData.is_salary_etc, "기타 수당")}
                {...A11yProps(5)}
                style={getTabStyle(summaryData.is_salary_etc, 5, tabIndex)}
              />
            </Tabs>
          </Box>
          <SwipeableViews
            index={tabIndex}
            onChangeIndex={onChangeTabIndex}
            style={tabBorder}
            animateTransitions={false}
          >
            <TabPanel value={tabIndex} index={0}>
              <SalaryCurrentInfoView
                onClickOpenModal={onClickOpenModal}
                salaryReportId={salaryReportId}
                is_edit={is_edit}
                branchName={summaryData.branch_info.branch_name}
                baseDate={summaryData.base_date}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <SalaryBasicInfoView
                onClickOpenModal={onClickOpenModal}
                salaryReportId={salaryReportId}
                is_edit={is_edit}
                baseDate={summaryData.base_date}
                branchName={summaryData.branch_info.branch_name}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              <MembershipSalesIncentiveList
                salaryReportId={salaryReportId}
                is_edit={is_edit}
                baseDate={summaryData.base_date}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
              <PtSalaryInfoView
                onClickOpenModal={onClickOpenModal}
                salaryReportId={salaryReportId}
                is_edit={is_edit}
                baseDate={summaryData.base_date}
                branchId={summaryData.branch_info.branch_id}
                branchName={summaryData.branch_info.branch_name}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={4}>
              <GxSalaryInfoView salaryReportId={salaryReportId} is_edit={is_edit} />
            </TabPanel>
            <TabPanel value={tabIndex} index={5}>
              <EtcSalaryInfoView
                baseDate={summaryData.base_date}
                onClickOpenModal={onClickOpenModal}
                salaryReportId={salaryReportId}
                is_edit={is_edit}
              />
            </TabPanel>
          </SwipeableViews>
        </div>
      </div>
    </>
  );
};
export default SalaryInfoTabView;
