import { btnStyles, btnSubmitStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import { generateObjectZeroId, getPermission, listEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import CheckBox from "components/LabelInput/CheckBox";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import SubTitle from "components/PageTitle/SubTitle";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import { payListActions } from "pages/ERP/MemberCommonItems/Module/actions";
import { payment_input_state } from "pages/ERP/MemberCommonItems/PaymentModalB/payment_B_init";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch } from "react-redux";
import SubsRefundList from "./SubsRefundList";

const SubsRefundContents = forwardRef((props, ref) => {
  const {
    refundState,
    payInfoListAction,
    regDate,
    payMethodList,
    setPayMethodList,
    onSelectPayMethod,
  } = props;
  const btnStyleClass = btnSubmitStyles();
  const storesDispatch = useDispatch();
  const [refundListState, setRefundListState] = useState([]);
  const [canEditPenalty, setCanEditPenalty] = useState(false);

  useEffect(() => {
    getPermission(storesDispatch, "MEMBER_VIEW_MENU/EXT_MANAGE").then((res) => {
      setCanEditPenalty(res);
    });
  }, []);

  useImperativeHandle(ref, () => ({
    getSelectedItem: () => {
      return refundListState;
    },
  }));

  const updateRefundList = () => {
    const curRefundTargetList = [];
    const parentPurchaseCodes = [];
    const refundInfoList = refundState.refund_info_list;

    for (let i = 0; i < refundInfoList.length; i++) {
      refundInfoList[i].is_lock = false;
    }
    for (let i = 0; i < refundState.refund_list.length; i++) {
      refundState.refund_list[i].selected = false;
      refundState.refund_list[i].total_paid_price = 0;
      refundState.refund_list[i].total_deduct_price = 0;
      refundState.refund_list[i].total_penalty = 0;
      refundState.refund_list[i].total_refund_price = 0;
      refundState.refund_list[i].discount_return = 0;
    }

    for (let i = 0; i < refundInfoList.length; i++) {
      if (refundInfoList[i].selected) {
        // parent 마킹
        const parentItem = refundInfoList[i].purchase_list.find(
          (x) => x.purchase_info.parent_purchase_code === "",
        );
        if (
          parentItem &&
          !parentPurchaseCodes.some((x) => x === parentItem.purchase_info.purchase_code)
        ) {
          parentPurchaseCodes.push({
            purchase_code: parentItem.purchase_info.purchase_code,
            idx: i,
          });
        }
      }
    }

    // child들 선택 및 lock
    for (let i = 0; i < refundInfoList.length; i++) {
      if (refundInfoList[i].purchase_list.length > 0) {
        const isParent =
          refundInfoList[i].purchase_list.findIndex(
            (x) => x.purchase_info.parent_purchase_code === "",
          ) !== -1
            ? true
            : false;
        const curParentCode = refundInfoList[i].purchase_list[0].purchase_info.parent_purchase_code;

        if (
          isParent === false &&
          parentPurchaseCodes.some((x) => x.purchase_code === curParentCode)
        ) {
          refundInfoList[i].selected = true;
          refundInfoList[i].is_lock = true;
        }

        if (
          refundInfoList[i].purchase_list[0].purchase_info.item_type.includes("PT") &&
          refundInfoList[i].purchase_list[0].purchase_info.membership_id !== generateObjectZeroId()
        ) {
          // 결합 PT는 무조건 회원권의 체크상태 따라가기
          const pObj = parentPurchaseCodes.find((x) => x.purchase_code === curParentCode);
          if (pObj) {
            refundInfoList[i].selected = true;
            refundInfoList[i].is_lock = true;
          } else {
            refundInfoList[i].selected = false;
            refundInfoList[i].is_lock = false;
          }
        }

        if (refundInfoList[i].selected) {
          let totalPaid = 0;
          let totalDeduct = 0;
          let totalPenalty = 0;
          let totalRefund = 0;
          const curPurchaseCode = refundInfoList[i].purchase_code;
          const index = refundState.refund_list
            .map((item, idx) => item.purchase_code)
            .indexOf(curPurchaseCode);
          if (index !== -1) {
            refundState.refund_list[index].selected = true;

            const curTarget = refundInfoList[i];
            for (let j = 0; j < curTarget.purchase_list.length; j++) {
              totalPaid += curTarget.purchase_list[j].purchase_info.paid_price;
              totalDeduct += curTarget.purchase_list[j].deduct_price;
              totalPenalty += curTarget.purchase_list[j].penalty;
              totalRefund += curTarget.purchase_list[j].refund_price;
            }
            refundState.refund_list[index].total_paid_price += totalPaid;
            refundState.refund_list[index].total_deduct_price += totalDeduct;
            refundState.refund_list[index].total_penalty += totalPenalty;
            refundState.refund_list[index].total_refund_price += totalRefund;
            // refundState.refund_list[index].discount_return += totalRefund;
            // refundState.refund_list[index].discount_return += totalRefund < 0 ? Math.abs(totalRefund) : 0;
          }
        }
      }
    }

    // CLEAR
    storesDispatch(payListActions.paymentInfoClear("clear"));
    setPayMethodList([]);

    for (let i = 0; i < refundState.refund_list.length; i++) {
      if (refundState.refund_list[i].selected) {
        curRefundTargetList.push(refundState.refund_list[i]);
      }

      // 할인반환금 & 최종 환불금액 계산
      if (refundState.refund_list[i].total_refund_price < 0) {
        refundState.refund_list[i].discount_return = Math.abs(
          refundState.refund_list[i].total_refund_price,
        );
        refundState.refund_list[i].total_refund_price = 0;

        setDiscountReturn(i);
      } else {
        refundState.refund_list[i].discount_return = 0;
      }
    }

    console.log(curRefundTargetList);
    setRefundListState(curRefundTargetList);
    // setPayMethodList(payMethodList);

    // 환불신청내역 테이블 내 값들 초기화

    for (let i = 0; i < refundListState.length; i++) {
      if (refundListState[i].payment_list) {
        for (let j = 0; j < refundListState[i].payment_list.length; j++) {
          refundListState[i].payment_list[j].payment_list_selector.selected = false;
        }
      }
    }
  };

  // 할인반환금 item 추가
  const setDiscountReturn = (idx) => {
    const purchaseCode = refundState.refund_list[idx].purchase_code;
    const price = refundState.refund_list[idx].discount_return;

    if (price > 0) {
      const contentObj = {
        type: "할인반환금 (" + purchaseCode + ")",
        price: price,
        dis: 0,
        pay_order: 0,
        tab_idx: 0,
        item_type: "할인반환금/없음/없음/없음/없음",
      };

      payInfoListAction("push", "할인반환금 (" + purchaseCode + ")", contentObj, 0);

      const newPayMethod = _.cloneDeep(payment_input_state);
      newPayMethod.purchase_code = purchaseCode;
      newPayMethod.unpaid_info.unpaid_price = price;
      newPayMethod.total_price = price;

      // setPayMethodList([...payMethodList, newPayMethod]); // 렌더링 타이밍 문제
      payMethodList.push(newPayMethod);
    }
  };

  const onChangeRefundState = (idx1, idx2, type, val) => {
    if (type === "deduct") {
      refundState.refund_info_list[idx1].purchase_list[idx2].deduct_price = val;
    } else if (type === "penalty") {
      refundState.refund_info_list[idx1].purchase_list[idx2].penalty = val;
    }

    refundState.refund_info_list[idx1].purchase_list[idx2].refund_price =
      refundState.refund_info_list[idx1].purchase_list[idx2].purchase_info.paid_price -
      refundState.refund_info_list[idx1].purchase_list[idx2].deduct_price -
      refundState.refund_info_list[idx1].purchase_list[idx2].penalty;

    updateRefundList();
  };

  const onSelectCsStaff = (staffInfo) => {
    refundState.cs_staff.staff_id = staffInfo.staff_id;
    refundState.cs_staff.staff_name = staffInfo.staff_name;
    refundState.cs_staff.staff_pos = staffInfo.position.org_name;
    refundState.cs_staff.staff_rank = staffInfo.rank.org_name;
  };

  return (
    <CardFixed
      topDom={
        <div style={{ display: "flex" }}>
          <div>환불 정보</div>
          <div style={{ display: "flex", float: "right", marginLeft: "auto" }}>
            <SelectConsultant
              csInfoList={refundState.cs_team_info_list}
              csNameList={refundState.cs_team_name_list}
              onSelectCsStaff={onSelectCsStaff}
            />
          </div>
        </div>
      }
      midDom={
        <div>
          {/* 환불사항 선택 */}
          <RefundTargetList
            refundState={refundState}
            onChangeRefundState={onChangeRefundState}
            updateRefundList={updateRefundList}
            canEditPenalty={canEditPenalty}
          />

          {/* 환불 신청 내역 */}
          <SubsRefundList
            refundState={refundState}
            refundListState={refundListState}
            payInfoListAction={payInfoListAction}
            regDate={regDate}
            payMethodList={payMethodList}
            setPayMethodList={setPayMethodList}
            onSelectPayMethod={onSelectPayMethod}
          />
        </div>
      }
      isLoading={false}
      rootStyle={{
        width: "76.88rem",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
});
export default SubsRefundContents;

//
const SelectConsultant = ({ csInfoList, csNameList, onSelectCsStaff }) => {
  return (
    <div>
      <ComboBox
        required
        marginRight="0rem"
        labelWidth={"2.625rem"}
        comboItemWidth={"12rem"}
        labelText="상담자"
        data={csNameList || []}
        onChangeCallback={(e) => {
          const idx = csInfoList.findIndex((x) => x.staff_name === e.target.value);
          onSelectCsStaff(csInfoList[idx]);
        }}
      />
    </div>
  );
};

//
const RefundTargetList = ({
  refundState,
  updateRefundList,
  onChangeRefundState,
  canEditPenalty,
}) => {
  const refundTargetList = new refundTargetTable(
    updateRefundList,
    onChangeRefundState,
    refundState.refund_info_list,
    canEditPenalty,
  );

  return (
    <div
      style={{
        marginTop: "1.25rem",
      }}
    >
      <SubTitle titleText={"환불사항 선택"} />
      <div style={{ margin: "0 0.1rem" }}>
        <CustomTable
          columns_head={refundTargetList.columns_head}
          table_title={refundTargetList.table_title}
          rest_call={refundTargetList.get_data_from_rest}
          row_render={refundTargetList.create_table}
          rest_data={refundState.refund_info_list}
        />
      </div>
    </div>
  );
};

//
class refundTargetTable {
  table_title = "";
  btnStyleClass = btnStyles();
  storesDispatch = useDispatch();
  updateRefundList = null;
  setRefundState = null;
  refundInfoList = null;
  canEditPenalty = null;

  constructor(updateRefundList, setRefundState, refundInfoList, canEditPenalty) {
    this.updateRefundList = updateRefundList;
    this.setRefundState = setRefundState;
    this.refundInfoList = refundInfoList;
    this.canEditPenalty = canEditPenalty;
  }

  getMaxValue = (data, idx1, idx2, value) => {
    // refundState.refund_info_list[idx1].purchase_list[idx2].refund_price =
    //   refundState.refund_info_list[idx1].purchase_list[idx2].purchase_info.paid_price -
    //   refundState.refund_info_list[idx1].purchase_list[idx2].deduct_price -
    //   refundState.refund_info_list[idx1].purchase_list[idx2].penalty;
  };

  checkMaxValue = (type, data, idx, value) => {
    if (type === "deduct") {
      if (
        data.purchase_list[idx].purchase_info.paid_price <
        value + data.purchase_list[idx].penalty
      ) {
        return false;
      }
    } else {
      if (
        data.purchase_list[idx].purchase_info.paid_price <
        value + data.purchase_list[idx].deduct_price
      ) {
        return false;
      }
    }
    return true;
  };

  checkMergedPt = (e, data) => {
    if (e.checked && data.is_merged === true) {
      if (
        data.purchase_list.length > 0 &&
        data.purchase_list[0].purchase_info.item_type.includes("PT") &&
        data.purchase_list[0].purchase_info.membership_id !== generateObjectZeroId()
      ) {
        e.checked = false;
        this.storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: <Label labelText="결합상품의 PT는 별도 환불이 불가합니다." />,
            onBtnOk: () => {},
          }),
        );
        return true;
      }
    }
    return false;
  };

  getItemName = (info) => {
    const val = info.item_type.split("/");
    if (val.length > 3 && val[2] === "일할금액") {
      return info.description + " (일할금액)";
    } else if (val.length > 3 && val[2] === "선납금") {
      return info.description + " (선납금)";
    }

    if (info.description === "회원권") {
      return info.description + " (" + info.item_name + ")";
    } else {
      return info.description;
    }
  };

  getContractPrice = (info) => {
    const val = info.item_type.split("/");
    if (
      val.length > 3 &&
      val[2] === "선납금" &&
      (val[0] === "회원권" || val[0] === "운동복" || val[0] === "GX" || val[0] === "락커")
    ) {
      return info.item_price + info.subs_purchase_info.total_unpaid;
    }
    return info.item_price;
  };

  getRowSpan = (data) => {
    if (data.purchase_list.length === 1) {
      const splitStr = data.purchase_list[0].purchase_info.item_type.split("/");
      if (
        splitStr.length === 5 &&
        (splitStr[0] === "운동복" || splitStr[0] === "GX" || splitStr[0] === "락커") &&
        splitStr[1] === "구독형"
      ) {
        if (splitStr[2] === "일할금액") {
          return data.purchase_list.length + 3;
        } else {
          console.log(splitStr);
          return 0; //
        }
      }
    }
    return data.purchase_list.length + 1;
  };

  checkSelectSet = (data, curIdx, isChecked) => {
    if (data.purchase_list.length === 1) {
      const splitStr = data.purchase_list[0].purchase_info.item_type.split("/");
      if (
        splitStr.length === 5 &&
        (splitStr[0] === "운동복" || splitStr[0] === "GX" || splitStr[0] === "락커") &&
        splitStr[1] === "구독형"
      ) {
        if (splitStr[2] === "일할금액") {
          if (this.refundInfoList.length > curIdx + 1) {
            // 선납금이 다음 idx에 있는 경우
            this.refundInfoList[curIdx + 1].selected = isChecked;
          }
        }
      }
    }
  };

  create_table = (data, idx1) => {
    // console.log(data);
    return (
      <>
        <CustomRow style={{ width: "100%" }} key={idx1}>
          {/* {this.getRowSpan(data) > 0 ? (
            // <CustomTableContents width="4%" rowSpan={this.getRowSpan(data)}>
            //   <CheckBox
            //     isTable
            //     defaultValue={data.selected}
            //     onChangeCallback={(e) => {
            //       if (this.checkMergedPt(e, data) === false) {
            //         data.selected = e.checked;
            //         this.checkSelectSet(data, idx1, e.checked);
            //         this.updateRefundList();
            //       }
            //     }}
            //     disabled={data.is_lock}
            //   />
            // </CustomTableContents>
            <></>
          ) : (
            <></>
          )} */}
          <CustomTableContents width="10%" rowSpan={data.purchase_list.length + 1}>
            <CheckBox
              isTable
              defaultValue={data.selected}
              onChangeCallback={(e) => {
                if (this.checkMergedPt(e, data) === false) {
                  data.selected = e.checked;
                  this.checkSelectSet(data, idx1, e.checked);
                  this.updateRefundList();
                }
              }}
              disabled={data.is_lock}
            />
          </CustomTableContents>
          <CustomTableContents width="10%" rowSpan={data.purchase_list.length + 1}>
            {data.purchase_code}
          </CustomTableContents>
          {/* {this.getRowSpan(data) > 0 ? (
            <CustomTableContents width="10%" rowSpan={this.getRowSpan(data)}>
              {data.purchase_code}
            </CustomTableContents>
          ) : (
            <></>
          )} */}
        </CustomRow>
        {data.purchase_list.map((item, idx2) => {
          console.log(item);
          return (
            <CustomRow key={idx2}>
              <CustomTableContents width="20%">
                {this.getItemName(item.purchase_info) + " \n" + item.usage_period}
              </CustomTableContents>
              <CustomTableContents width="8%">
                {/* 약정 총액 */}
                {/* {this.getContractPrice(item.purchase_info).toLocaleString()} */}
                {/* {item.purchase_info.item_price.toLocaleString()} */}
                {item.contract_total_price.toLocaleString()}
              </CustomTableContents>
              <CustomTableContents width="8%">
                {item.purchase_info.paid_price.toLocaleString()}
              </CustomTableContents>
              <CustomTableContents width="8%">{item.usage_details}</CustomTableContents>
              <CustomTableContents width="8%">
                {/* {item.deduct_price} */}
                <TextField
                  justifyContent="center"
                  textBoxWidth="6rem"
                  defaultValue={item.deduct_price}
                  labelMarginRight="0rem"
                  textMarginRight="0rem"
                  isNumber
                  minValue={0}
                  // maxValue={this.getMaxValue()}
                  onChangeCallback={(e) => {
                    // if (this.checkMaxValue("deduct", data, idx2, e) === false) {
                    //   e = 0;
                    //   this.storesDispatch(
                    //     globalAlertOn({
                    //       show: true,
                    //       titleText: "알림",
                    //       bodyText: <Label labelText="환불금액이 결제금액보다 큽니다." />,
                    //       onBtnOk: () => {},
                    //     }),
                    //   );
                    // }
                    this.setRefundState(idx1, idx2, "deduct", e);
                  }}
                  disabled={!this.canEditPenalty}
                />
              </CustomTableContents>
              <CustomTableContents width="8%">
                {/* {item.penalty} */}
                <TextField
                  justifyContent="center"
                  textBoxWidth="6rem"
                  defaultValue={item.penalty}
                  labelMarginRight="0rem"
                  textMarginRight="0rem"
                  isNumber
                  minValue={0}
                  onChangeCallback={(e) => {
                    // if (this.checkMaxValue("penalty", data, idx2, e) === false) {
                    //   e = 0;
                    //   this.storesDispatch(
                    //     globalAlertOn({
                    //       show: true,
                    //       titleText: "알림",
                    //       bodyText: <Label labelText="환불금액이 결제금액보다 큽니다." />,
                    //       onBtnOk: () => {},
                    //     }),
                    //   );
                    // }
                    this.setRefundState(idx1, idx2, "penalty", e);
                  }}
                  disabled={!this.canEditPenalty}
                />
              </CustomTableContents>
              <CustomTableContents width="8%">
                {item.refund_price.toLocaleString()}
              </CustomTableContents>
            </CustomRow>
          );
        })}
      </>
    );
  };

  columns_head = [
    { title: "선택", field: "select" },
    { title: "구매코드", field: "purchase_code" },
    { title: "항목", field: "description" },
    // { title: "기간", field: "period" },
    { title: "약정 총액", field: "item_price" },
    { title: "결제금액", field: "price" },
    { title: "사용내역", field: "usage_details" },
    { title: "사용내역 정가", field: "deduct_price" },
    { title: "위약금", field: "penalty" },
    { title: "환불금액", field: "refund_price" },
  ];

  get_data_from_rest = (stateData) => {
    if (listEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
