import { contentTypeJson, simpleAlert, SubLine } from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import ModalBtnModeGroup from "components/ModalBtnModeGroup/ModalBtnModeGroup";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { branchSettingAction, loadPolicyStore, loadPrePaidItemsStore } from "../../Module/actions";
import post_api from "../../Module/api/PostApi";
import BoardingSetting from "../CommonSub/BoardingSetting";
import ETCSetting from "../CommonSub/ExtraSetting";
import ExpiredSetting from "./ExpiredSetting";
import MembershipSetting from "./MembershipSetting";
import SideItemSetting from "./SideItemSetting";

const ItemInfo = ({ restData, itemOptions, onOptionChange, isEditMode }) => {
  const textLabelWidth = "6rem";
  const textBoxWidthLong = "30rem";
  const comboItemWidth = "12rem";

  const [salesStatus, setSalesStatus] = useState([]);

  useEffect(() => {
    switch (restData.base_info.sales_status) {
      case "판매대기":
        setSalesStatus(["판매대기", "판매중"]);
        break;
      case "판매중":
        setSalesStatus(["판매중", "판매종료"]);
        break;
      case "판매종료":
        setSalesStatus(["판매중", "판매종료"]);
        break;
      default:
        setSalesStatus([]);
        break;
    }
  }, [restData]);

  return (
    <div>
      <div
        style={{
          display: "flex",
        }}
      >
        <ComboBox
          labelWidth={textLabelWidth}
          comboItemWidth={comboItemWidth}
          labelText="판매 상태"
          data={salesStatus}
          defaultValue={restData.base_info.sales_status}
          onChangeCallback={(e) => {
            restData.base_info.sales_status = e.target.value;
          }}
        />
        <CheckBox
          marginTop="0rem"
          labelWidth={textLabelWidth}
          labelText="판매 제한"
          defaultValue={restData.base_info.sales_limit}
          onChangeCallback={(e) => {
            restData.base_info.sales_limit = e.checked;
          }}
        />
      </div>
      <SubLine />
      <TextField
        required
        labelWidth={textLabelWidth}
        textBoxWidth={textBoxWidthLong}
        labelText="상품명"
        defaultValue={restData.item_name}
        onChangeCallback={(e) => {
          restData.item_name = e;
        }}
        disabled={isEditMode}
      />
      <ComboBox
        marginTop="0.5rem"
        labelWidth={textLabelWidth}
        comboItemWidth={comboItemWidth}
        labelText="옵션"
        data={itemOptions}
        defaultValue={restData.mbshp_option}
        onChangeCallback={(e) => {
          restData.mbshp_option = e.target.value;
          onOptionChange(e.target.value);
        }}
        disabled={isEditMode}
      />
    </div>
  );
};

const SettingContents = ({ modalParam, onModalDone }) => {
  const isEditMode = modalParam.mode === "edit";
  const canSelectFamilyOpt = modalParam.canSelectFamilyOpt;
  const canSelectPassOpt = modalParam.canSelectPassOpt;
  const storesDispatch = useDispatch();

  const [itemOptions, setItemOptions] = useState([""]); // ["일반권", "일일권", "시간권", "패스권"];
  const restData = useSelector((state) => state.branch_set_state.branch_pre_paid_info_state);
  const [mbshpOption, setMbshpOption] = useState(restData.mbshp_option);
  const branchPolicy = useSelector((state) => state.branch_set_state.branch_policy_set_state);
  const [period, setPeriod] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (modalParam.branchId !== "") {
      storesDispatch(loadPrePaidItemsStore(modalParam.branchId, modalParam.itemId));
      storesDispatch(loadPolicyStore(modalParam.branchId));
    }

    return () => {
      storesDispatch(branchSettingAction.loadPrePaidItemsReset());
    };
  }, [loadPrePaidItemsStore, storesDispatch]);

  useEffect(() => {
    setPeriod(restData.stores.data.prepay_items[0].period_month);
    setMbshpOption(restData.stores.data.prepay_items[0].mbshp_option);
    updateItemOptions(restData.stores.erp_setting);

    return () => {
      setPeriod(0);
      setMbshpOption(restData.mbshp_option);
    };
  }, [restData]);

  // 권한에 따른 Option 필터
  const filterMbshpOption = (options) => {
    if (canSelectPassOpt) {
      return options;
    } else {
      return options.filter((x) => x !== "시간권" && x !== "패스권");
    }
  };

  const updateItemOptions = (erpSetInfo) => {
    const _itemOptions = ["일반권", "일일권"];
    if (erpSetInfo) {
      // erpSetInfo 값이 정상일 때
      if (erpSetInfo.mbshp_pass_branch_list.find((x) => x.branch_id === modalParam.branchId)) {
        _itemOptions.push("패스권");
      }
      if (erpSetInfo.mbshp_time_branch_list.find((x) => x.branch_id === modalParam.branchId)) {
        _itemOptions.push("시간권");
      }
    }

    setItemOptions(filterMbshpOption(_itemOptions));
  };

  const validation = () => {
    let res = "";
    if (!isEditMode) {
      if (!restData.stores.data.prepay_items[0].item_name) {
        res += "· 상품명\n";
      }

      if (restData.stores.data.prepay_items[0].mbshp_option !== "일일권") {
        if (!restData.stores.data.prepay_items[0].period_month) {
          res += "· 기본기간\n";
        }

        if (
          restData.stores.data.prepay_items[0].is_combine_pt &&
          !restData.stores.data.prepay_items[0].pt_session
        ) {
          res += "· PT 세션\n";
        }
      }

      if (restData.stores.data.prepay_items[0].mbshp_option === "시간권") {
        if (!restData.stores.data.prepay_items[0].pass_from_time) {
          res += "· 출입 가능 시간 - 시작시간\n";
        }
        if (!restData.stores.data.prepay_items[0].pass_end_time) {
          res += "· 출입 가능 시간 - 종료시간\n";
        }
      } else if (restData.stores.data.prepay_items[0].mbshp_option === "패스권") {
        if (!restData.stores.data.prepay_items[0].pass_cnt) {
          res += "· 월 출입 횟수\n";
        }
      } else if (restData.stores.data.prepay_items[0].mbshp_option === "일일권") {
        if (!restData.stores.data.prepay_items[0].total_period_hour) {
          res += "· 유효 기간\n";
        }
        if (!restData.stores.data.prepay_items[0].oneday_wait_hour) {
          res += "· 사용 대기시간\n";
        }
      }
    }
    return res;
  };

  const onClickSave = () => {
    const validationRes = validation();
    if (validationRes !== "") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="필수값을 모두 입력해주셔야 합니다." />
              <br />
              <br />
              <Label labelText={validationRes} whiteSpace="pre-wrap" />
            </>
          ),
          // width: "50rem",
          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="저장하시겠습니까?" />
            </>
          ),
          // width: "50rem",
          onBtnYes: () => {
            onSaveEvent();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const onSaveEvent = async () => {
    restData.stores.data.branch_id = modalParam.branchId;
    // 일일권 등록비 0원 처리
    if (mbshpOption === "일일권") {
      restData.stores.data.prepay_items[0].reg_price = 0;
    }
    setLoading(true);

    const formData = new FormData();
    formData.append("item_info", JSON.stringify(restData.stores.data));
    const postApi = isEditMode ? post_api.updatePrePayApi() : post_api.newPrePayApi();
    await HttpInstance.post(postApi, formData, contentTypeJson)
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          onModalDone(modalParam.refreshStore);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
        // onModalDone(modalParam.refreshStore);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onOptionChange = (e) => {
    // storesDispatch(loadPrePaidItemsStore(modalParam.branchId, modalParam.itemId));
    setMbshpOption(e);
  };

  return (
    <div style={{}}>
      {loading && <Loading />}

      <ModalBtnModeGroup mode={modalParam.mode} onClickEvent={onClickSave} />
      <ItemInfo
        restData={restData.stores.data.prepay_items[0]}
        itemOptions={itemOptions}
        onOptionChange={onOptionChange}
        isEditMode={isEditMode}
      />
      <ExpiredSetting
        branchPolicy={branchPolicy.stores.data.contents}
        restData={restData.stores.data.prepay_items[0]}
        mbshpOption={mbshpOption}
        setPeriod={setPeriod}
        isEditMode={isEditMode}
      />
      <MembershipSetting
        branchPolicy={branchPolicy.stores.data.contents}
        restData={restData.stores.data.prepay_items[0]}
        mbshpOption={mbshpOption}
        period={period}
        isEditMode={isEditMode}
      />
      {/* {mbshpOption !== "일일권" ? ( */}
      <SideItemSetting
        branchPolicy={branchPolicy.stores.data.contents}
        restData={restData.stores.data.prepay_items[0]}
        mbshpOption={mbshpOption}
        period={period}
        isEditMode={isEditMode}
      />
      {/* ) : (
        <></>
      )} */}
      <ETCSetting
        restData={restData.stores.data.prepay_items[0]}
        hqPolicy={restData.stores.data.hq_policy}
        mbshpOption={mbshpOption}
        isEditMode={isEditMode}
        canSelectFamilyOpt={canSelectFamilyOpt}
      />
      <BoardingSetting
        branchPolicy={branchPolicy.stores.data.contents}
        restData={restData.stores.data.prepay_items[0]}
      />
    </div>
  );
};
export default SettingContents;
