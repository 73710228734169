import { dataEmpty } from "components/CommonLib/CommonLib";
import {
  APPROVAL_TYPE_EXP,
  APPROVAL_TYPE_IMP,
  APPROVAL_TYPE_REFUND_TRANS,
} from "components/CommonLib/Consts";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import { useEffect, useState } from "react";

const UnspentDetailDataTable = ({ contentState }) => {
  let [resultTable, setResultTable] = useState(null);

  useEffect(() => {
    if (contentState) {
      const approvalType = contentState.doc_info.doc_name;
      if (approvalType === APPROVAL_TYPE_EXP) {
        setResultTable(new proposalTable());
      } else if (approvalType === APPROVAL_TYPE_IMP) {
        setResultTable(new spentProofTable());
      } else if (approvalType === APPROVAL_TYPE_REFUND_TRANS) {
        setResultTable(new transferRequireTable());
      }
    }
  }, [contentState]);

  if (!resultTable) {
    return <></>;
  }

  return (
    <>
      <CustomTable
        columns_head={resultTable.columns_head}
        table_title={resultTable.table_title}
        rest_call={resultTable.get_data_from_rest}
        row_render={resultTable.create_table}
        rest_data={contentState.item_list}
      />
    </>
  );
};
export default UnspentDetailDataTable;

// 시행품의 테이블
class proposalTable {
  table_title = "";

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.breakdown}</CustomTableContents>
        <CustomTableContents $isNumber>{data.price.toLocaleString() + " 원"}</CustomTableContents>
        <CustomTableContents $isNumber>
          {data.tax_price.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.total_price.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents>{data.bank}</CustomTableContents>
        <CustomTableContents>{data.bank_acc}</CustomTableContents>
        <CustomTableContents>{data.bank_acc_name}</CustomTableContents>
        <CustomTableContents>{data.account}</CustomTableContents>
        <CustomTableContents>{data.tax_invoice}</CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "내역", width: "12%" },
    { title: "공급가액", width: "9%" },
    { title: "부가세액", width: "9%" },
    { title: "총계", width: "9%" },
    { title: "은행", width: "8%" },
    { title: "계좌번호", width: "13%" },
    { title: "입금자명", width: "10%" },
    { title: "계정", width: "8%" },
    { title: "세금계산서", width: "8%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}

// 지출증빙 테이블
class spentProofTable {
  table_title = "";

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.pay_date}</CustomTableContents>
        <CustomTableContents>{data.breakdown}</CustomTableContents>
        <CustomTableContents $isNumber>{data.price.toLocaleString() + " 원"}</CustomTableContents>
        <CustomTableContents $isNumber>
          {data.tax_price.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.total_price.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents>{data.pay_method}</CustomTableContents>
        <CustomTableContents>{data.account}</CustomTableContents>
        <CustomTableContents>{data.tax_invoice}</CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "결제일", width: "12%" },
    { title: "내역", width: "9%" },
    { title: "공급가액", width: "9%" },
    { title: "부가세액", width: "9%" },
    { title: "총계", width: "8%" },
    { title: "결제수단", width: "13%" },
    { title: "계정", width: "10%" },
    { title: "세금계산서", width: "8%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}

// 환불 이체신청 테이블
class transferRequireTable {
  table_title = "";

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.transfer_req_info.bank}</CustomTableContents>
        <CustomTableContents>{data.transfer_req_info.bank_account}</CustomTableContents>
        <CustomTableContents>{data.transfer_req_info.account_name}</CustomTableContents>
        <CustomTableContents $isNumber>
          {data.transfer_req_info.price.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents>{data.account}</CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "은행", width: "9%" },
    { title: "계좌번호", width: "9%" },
    { title: "입금자명", width: "9%" },
    { title: "이체요청 금액", width: "8%" },
    { title: "계정", width: "13%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
