import { Box } from "@mui/material";
import IconButton from "components/Button/IconButton";
import Label from "components/LabelInput/Label";

const DocsStatusCount = ({ resultSummary }) => {
  return (
    <>
      <div style={{ display: "flex" }}>
        <Box
          sx={{
            border: "1px solid #dedede",
            borderRadius: "5px",
            width: "14rem",
            height: "3.75rem",
            display: "flex",
            justifyContent: "flex-start",
            paddingLeft: "0.5rem",
          }}
        >
          <IconButton type="approval_done" scale="0.9" />
          <Label labelText="승인" color="#777" marginLeft="0.5rem" />
          <div style={{ width: "8rem", display: "flex", justifyContent: "flex-end" }}>
            <Label
              labelText={resultSummary.approve.toString()}
              marginLeft="1.5rem"
              fontWeight="bold"
              fontSize="1.75rem"
            />
            <Label labelText="건" />
          </div>
        </Box>
        <Box
          sx={{
            border: "1px solid #dedede",
            borderRadius: "5px",
            width: "14rem",
            height: "3.75rem",
            display: "flex",
            justifyContent: "flex-start",
            paddingLeft: "0.5rem",
            marginLeft: "0.75rem",
          }}
        >
          <IconButton type="approval_wait" scale="0.9" />
          <Label labelText="승인대기" color="#777" marginLeft="0.5rem" />
          <div style={{ width: "6.5rem", display: "flex", justifyContent: "flex-end" }}>
            <Label
              labelText={resultSummary.wait.toString()}
              marginLeft="1.5rem"
              fontWeight="bold"
              fontSize="1.75rem"
            />
            <Label labelText="건" />
          </div>
        </Box>
      </div>

      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <Box
          sx={{
            border: "1px solid #dedede",
            borderRadius: "5px",
            width: "14rem",
            height: "3.75rem",
            display: "flex",
            justifyContent: "flex-start",
            paddingLeft: "0.5rem",
          }}
        >
          <IconButton type="approval_reject" scale="0.9" />
          <Label labelText="반려" color="#777" marginLeft="0.5rem" />
          <div style={{ width: "8rem", display: "flex", justifyContent: "flex-end" }}>
            <Label
              labelText={resultSummary.reject.toString()}
              marginLeft="1.5rem"
              fontWeight="bold"
              fontSize="1.75rem"
            />
            <Label labelText="건" />
          </div>
        </Box>
        <Box
          sx={{
            border: "1px solid #dedede",
            borderRadius: "5px",
            width: "14rem",
            height: "3.75rem",
            display: "flex",
            justifyContent: "flex-start",
            paddingLeft: "0.5rem",
            marginLeft: "0.75rem",
          }}
        >
          <IconButton type="approval_retrieve" scale="0.9" />
          <Label labelText="회수" color="#777" marginLeft="0.5rem" />
          <div style={{ width: "8rem", display: "flex", justifyContent: "flex-end" }}>
            <Label
              labelText={resultSummary.recall.toString()}
              marginLeft="1.5rem"
              fontWeight="bold"
              fontSize="1.75rem"
            />
            <Label labelText="건" />
          </div>
        </Box>
      </div>
    </>
  );
};
export default DocsStatusCount;
