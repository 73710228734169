import React, { useState, useRef, useEffect } from "react";
import PageFrame from "components/Panels/PageFrame";
import PageTitle from "components/PageTitle/PageTitle";
import UnpaidManageContent from "./UnpaidManageContent";

const SearchMember = () => {
  return (
    <div>
      <PageFrame
        menuActive="락커/셋팅"
        parentMenu={{ 락커: true }}
        children={
          <div>
            <PageTitle titleText="미납금 관리" />
            <UnpaidManageContent />
          </div>
        }
      />
    </div>
  );
};
export default SearchMember;
