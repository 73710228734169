export const branchPolicySetStateinitial = {
  stores: {
    data: {
      contents: {
        erp_identify: "",
        remove_lock: false,
        found_date: "",
        branch_no: "0",
        is_active_gx: false,
        manager_list: [],
        transfer_branch_list: [],
        hq_info: {
          hq_id: "",
          hq_name: "",
        },
        branch_info: {
          branch_id: "",
          branch_name: "",
        },
        base_policy: {
          prepay_reg: "0", //"int / 완납형 회원권 등록비",
          prepay_mbshp: "0", //"int / 완납형 회원권 1개월 정가",
          prepay_cloth: "0", //"int / 완납형 운동복 1개월 정가",
          prepay_gx: "0", //"int / 완납형 GX 1개월 정가",
          prepay_locker: "0", //"int / 완납형 락커 1개월 정가",
          subs_reg: "0", //"int / 구독형 회원권 등록 수수료",
          subs_mbshp: "0", //"int / 구독형 회원권 1개월 정가",
          subs_cloth: "0", //"int / 구독형 운동복 1개월 정가",
          subs_gx: "0", //"int / 구독형 GX 1개월 정가",
          subs_locker: "0", //"int / 구독형 락커 1개월 정가",
          oneday_mbshp: "0", //"int / 일일권 정가",
          oneday_cloth: "0", //"int / 운동복 1일",
          oneday_gx: "0", //"int / GX 1일",
          ot: "0", //"int / OT 1회 정가",
          pt: "0", //"int / PT 1회 정가",
        },
        membership_policy: {
          use_dflt_from_date: false, //"bool / 회원권 시작일 기본값 사용여부",
          dflt_from_date: "", //"date / 회원권 시작일 기본값",
          use_max_wait_day: false, //"bool / 시작 대기일 사용여부",
          max_wait_day: "0", //"int / 시작 대기일",
          recommender_month: "0", //"int / 지인소개 소개자 추가 개월",
          recommender_day: "0", //"int / 지인소개 소개자 추가 일",
          recommendee_month: "0", //"int / 지인소개 피소개자 추가 개월",
          recommendee_day: "0", //"int / 지인소개 피소개자 추가 일",
          event_month_list: [],
          event_day_list: [],
          pass_mbship_count_rate: "0",
          change_limit: "0", //"int / 상품변경 제한 일수",
          card_remake_fee: "0", //"int / 카드재발급 비용",
          mbshp_assign_fee: "0", //"int / 회원권 양도비",
          pt_assign_fee: "0", //"int / PT 양도비",
        },
        contract: {
          register_prepay: {
            key_name: "",
            path: "",
          },
          register_subs: {
            key_name: "",
            path: "",
          },
          assign: {
            key_name: "",
            path: "",
          },
          change: {
            key_name: "",
            path: "",
          },
          transfer: {
            key_name: "",
            path: "",
          },
          refund: {
            key_name: "",
            path: "",
          },
          service: {
            key_name: "",
            path: "",
          },
          register_pt: {
            key_name: "",
            path: "",
          },
          cancel_subs: {
            key_name: "",
            path: "",
          },
          cancel_regular: {
            key_name: "",
            path: "",
          },
          agree_personal: {
            key_name: "",
            path: "",
          },
          agree_marketing: {
            key_name: "",
            path: "",
          },
        },
        cms_by_pay_method: {
          cash: "0",
          card: "0",
        },
        default_salary_date: {
          wage: "0",
          incentive: "0",
          pt_fee: "0",
          gx_fee: "0",
          etc_fee: "0",
        },
      },
      permission: {
        item_manage_tab_read: false,
        item_manage_tab_write: false,
        policy_manage_tab_read: false,
        policy_manage_tab_write: false,
        change_log_read: false,
      },
    },
  },
};
