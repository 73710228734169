import { generateObjectZeroId } from "components/CommonLib/CommonLib";
import { create } from "zustand";

// 검색 조건 베이스
const baseSearchParams = {
  branch_info: {
    branch_id: generateObjectZeroId(),
    branch_name: "",
  },
  card_no_from: "",
  card_no_to: "",
  card_serial_no: "",
  card_status: "",
  cur_reg_from_date: "",
  cur_reg_to_date: "",
  cur_user: {
    member_no: "",
    sabun: "",
    user_id: "",
    user_name: "",
  },
  is_empty_user: false,
  user_group: "",
};

export const useAccessCardManageStore = create((set) => ({
  // ========== State ===========
  // 카드관리 검색 State
  accessCardManageSearchParams: {
    ...baseSearchParams,
  },
  accessCardManagePage: 1,

  accessCardManageResetFlag: false,

  // ========== Action ==========
  // 카드관리 검색 조건 변경
  setSearchParams: (key, value) => {
    set((state) => ({
      accessCardManageSearchParams: {
        ...state.accessCardManageSearchParams,
        [key]: value,
      },
    }));
  },

  // Page 값 변경
  setPage: (value) => {
    set({ accessCardManagePage: value });
  },
  // 검색 조건 리셋
  resetSearchParams: () => {
    set({
      accessCardManageSearchParams: {
        ...baseSearchParams,
      },
    });
    set({ accessCardManageHqIdx: 0 });
    set((state) => ({
      accessCardManageResetFlag: {
        ...!state.accessCardManageResetFlag,
      },
    }));
  },
}));
