import { HttpInstance } from "lib/HttpLib";

export const getViewInfo = (param) => {
  return HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/change/pay_method_view", {
    params: {
      member_id: param.member_id === null ? "" : param.member_id,
    },
  });
};

const get_api = {
  getViewInfo,
};

export default get_api;
