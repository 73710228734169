import { generateObjectZeroId } from "components/CommonLib/CommonLib";
import { create } from "zustand";

// 검색 조건 베이스
const baseSearchParams = {
  branch_info: {
    branch_id: generateObjectZeroId(),
    branch_name: "",
  },
  combo_item: {
    branch_info_list: [
      {
        branch_id: generateObjectZeroId(),
        branch_name: "",
      },
    ],
    branch_list: [""],
  },
  reg_from_date: "",
  reg_to_date: "",
  title: "",
};

export const useTMListStore = create((set) => ({
  // ========== State ===========
  // TM List 검색 State
  tmListSearchParams: {
    ...baseSearchParams,
  },
  tmListPage: 1,

  // ========== Action ==========
  // TM List 검색 조건 변경
  setSearchParams: (key, value) => {
    set((state) => ({
      tmListSearchParams: {
        ...state.tmListSearchParams,
        [key]: value,
      },
    }));
  },

  // Page 값 변경
  setPage: (value) => {
    set({ tmListPage: value });
  },

  // 검색 조건 리셋
  resetSearchParams: () => {
    set({
      tmListSearchParams: {
        ...baseSearchParams,
      },
    });
  },
}));
