import { excelExport, excelExportExtend } from "components/CommonLib/CommonLib";

export const exportToExcel = (reportData, fileName) => {
  const dataSet = [
    // {
    //   data: [],
    //   sheetName: "",
    // },
  ];
  const salesRecordData = [];
  const sales = reportData.sales_record_list;
  for (let i = 0; i < sales.length; i++) {
    const row = {};
    row.등록일 = sales[i].reg_date;
    row.구분 = sales[i].reason;
    row.담당자 = sales[i].reg_info.reg_name;
    row.회원번호 = sales[i].member_info.member_no;
    row.이름 = sales[i].member_info.member_name;
    row.구매항목 = getPaidItems(sales[i].product_list);
    row.가입비 = getItemPrice(sales[i].product_list, "등록비");
    row.회원권 = getItemPrice(sales[i].product_list, "회원권");
    row.운동복 = getItemPrice(sales[i].product_list, "운동복");
    row.GX = getItemPrice(sales[i].product_list, "GX");
    row.락커 = getItemPrice(sales[i].product_list, "락커");
    row.PT = getItemPrice(sales[i].product_list, "PT");
    row.카드재발급 = getItemPrice(sales[i].product_list, "카드재발급");
    row.기타상품 = getItemPrice(sales[i].product_list, "기타상품");
    row.총_할인금액 = getTotalDisPrice(sales[i].product_list);
    row.합계 = getTotalPrice(sales[i].product_list);

    salesRecordData.push(row);
  }

  const refundData = [];
  const refunds = reportData.refund_record_list;
  for (let i = 0; i < refunds.length; i++) {
    const row = {};
    row.등록일 = refunds[i].reg_date;
    row.구분 = refunds[i].reason;
    row.담당자 = refunds[i].reg_info.reg_name;
    row.회원번호 = refunds[i].member_info.member_no;
    row.이름 = refunds[i].member_info.member_name;
    row.구매코드 = refunds[i].purchase_code;
    row.취소항목 = getListMerger(refunds[i]);
    row.재결제금액 = refunds[i].refund_info.re_paid.toLocaleString();
    row.청구금액 = refunds[i].refund_info.billing_amount.toLocaleString();
    row.할인반환금 = refunds[i].refund_info.discounted_refund.toLocaleString();
    row.현장_취소금액 = refunds[i].refund_info.on_site_cancellation_amount.toLocaleString();
    row.합계 = get_refund_total(refunds[i]);

    refundData.push(row);
  }

  const assignChangeData = [];
  const assigns = [...reportData.assign_change_history, ...reportData.branch_change_list];
  for (let i = 0; i < assigns.length; i++) {
    const row = {};
    row.등록일 = assigns[i].reg_date;
    row.구분 = assigns[i].reason;
    row.담당자 = assigns[i].reg_info.reg_name;
    row.회원번호 = assigns[i].member_info.member_no;
    row.이름 = assigns[i].member_info.member_name;
    row.구매코드 = assigns[i].purchase_code;
    row.회원권_양도비 = assigns[i].mbshp_assign_price.toLocaleString();
    row.PT_양도비 = assigns[i].pt_assign_price.toLocaleString();
    row.양수자_카드발급 = assigns[i].card_new_price.toLocaleString();
    row.지점변경비 = assigns[i].branch_transfer_price.toLocaleString();
    row.상품변경_금액 = assigns[i].item_change_price.toLocaleString();
    row.합계 = assigns[i].total_price.toLocaleString();

    assignChangeData.push(row);
  }

  const paymentData = [];
  const payments = reportData.payment_history;
  for (let i = 0; i < payments.length; i++) {
    const row = {};
    row.등록일 = payments[i].reg_date;
    row.결제기기 = getRegDevice(payments[i]);
    row.구분 = payments[i].reason;
    row.담당자 = payments[i].reg_info.reg_name;
    row.회원번호 = payments[i].member_info.member_no;
    row.회원명 = payments[i].member_info.member_name;
    row.구매코드 = payments[i].purchase_code;
    row.합계 = getTotalPaymentPrice(payments[i].payment_info).toLocaleString();

    if (payments[i].payment_info && payments[i].payment_info.length > 0) {
      // 결제수단이 있는 경우
      for (let j = 0; j < payments[i].payment_info.length; j++) {
        row.결제수단 = payments[i].payment_info[j].pay_method;
        row.매입사 = payments[i].payment_info[j].buyer;
        row.취소금액 =
          payments[i].payment_info[j].price < 0
            ? Math.abs(payments[i].payment_info[j].price).toLocaleString()
            : "0";
        row.결제금액 =
          payments[i].payment_info[j].price < 0
            ? "0"
            : payments[i].payment_info[j].price.toLocaleString();

        // 결제수단이 여러개인 경우 앞쪽 컬럼들은 반복해서 찍어주지 않는다.
        if (j >= 1) {
          row.결제기기 = "";
          row.구분 = "";
          row.담당자 = "";
          row.회원번호 = "";
          row.회원명 = "";
          row.구매코드 = "";
          row.합계 = "";
        }
        paymentData.push(row);
      }
    } else {
      // 결제수단이 없는 경우 (0원인 경우 해당됨)
      row.결제수단 = "";
      row.매입사 = "";
      row.취소금액 = "";
      row.결제금액 = "";
      paymentData.push(row);
    }
  }

  dataSet.push({ data: salesRecordData, sheetName: "판매 내역" });
  dataSet.push({ data: refundData, sheetName: "환불 내역" });
  dataSet.push({ data: assignChangeData, sheetName: "양도 및 변경 내역" });
  dataSet.push({ data: paymentData, sheetName: "결제 및 결제취소 내역" });

  excelExportExtend(dataSet, fileName);
};

// 운영일지 매출/환불 목록 당월 자료 엑셀
export const exportToSalesReportExcel = (reportData, fileName) => {
  const monthlySalesRecordData = [];
  // 회원권 구매
  const sales = reportData.sales_record_list;
  for (let i = 0; i < sales.length; i++) {
    const row = {};
    row.등록일 = sales[i].reg_date;
    row.구분 = sales[i].reason;
    row.회원번호 = sales[i].member_info.member_no;
    row.이름 = sales[i].member_info.member_name;
    row.가입비 = getNumberItemPrice(sales[i].product_list, "등록비");
    row.회원권 = getNumberItemPrice(sales[i].product_list, "회원권");
    row.운동복 = getNumberItemPrice(sales[i].product_list, "운동복");
    row.GX = getNumberItemPrice(sales[i].product_list, "GX");
    row.락커 = getNumberItemPrice(sales[i].product_list, "락커");
    row.PT = getNumberItemPrice(sales[i].product_list, "PT");
    row.카드재발급 = getNumberItemPrice(sales[i].product_list, "카드재발급");
    row.기타상품 = getNumberItemPrice(sales[i].product_list, "기타상품");
    row.구매코드 = sales[i].purchase_code;
    row.재결제금액 = "";
    row.현장_취소금액 = "";
    row.회원권_양도비 = "";
    row.PT_양도비 = "";
    row.양수자_카드발급 = "";
    row.지점변경비 = "";
    row.결제수단변경 = "";
    row.결제수단 = "";
    row.취소금액 = "";
    row.결제금액 = "";
    monthlySalesRecordData.push(row);
  }
  // 환불
  const refunds = reportData.refund_record_list;
  for (let i = 0; i < refunds.length; i++) {
    const row = {};
    row.등록일 = refunds[i].reg_date;
    row.구분 = refunds[i].reason;
    row.회원번호 = refunds[i].member_info.member_no;
    row.이름 = refunds[i].member_info.member_name;
    row.구매코드 = refunds[i].purchase_code;
    row.재결제금액 = refunds[i].refund_info.re_paid;
    // 현장_취소금액 - 값으로 표기 중
    row.현장_취소금액 = -refunds[i].refund_info.on_site_cancellation_amount;
    row.결제수단 = "";
    row.취소금액 = "";
    row.결제금액 = "";
    monthlySalesRecordData.push(row);
  }
  // 양도 및 양수
  const assigns = [...reportData.assign_change_history, ...reportData.branch_change_list];
  for (let i = 0; i < assigns.length; i++) {
    const row = {};
    row.등록일 = assigns[i].reg_date;
    row.구분 = assigns[i].reason;
    row.회원번호 = assigns[i].member_info.member_no;
    row.이름 = assigns[i].member_info.member_name;
    row.구매코드 = assigns[i].purchase_code;
    row.회원권_양도비 = assigns[i].mbshp_assign_price;
    row.PT_양도비 = assigns[i].pt_assign_price;
    row.양수자_카드발급 = assigns[i].card_new_price;
    row.지점변경비 = assigns[i].branch_transfer_price;
    row.결제수단 = "";
    row.취소금액 = "";
    row.결제금액 = "";

    monthlySalesRecordData.push(row);
  }
  // 카드 결제 내역
  const payments = reportData.payment_history;
  for (let i = 0; i < payments.length; i++) {
    let isExistChangePayment = false;
    const row = {};
    row.등록일 = payments[i].reg_date;
    row.회원번호 = payments[i].member_info.member_no;
    row.이름 = payments[i].member_info.member_name;

    // 결제내역이 "결제수단변경" 일 때
    if (payments[i].payment_info && payments[i].reason.includes("결제수단변경")) {
      const newRow = { ...row };
      newRow.결제수단 =
        payments[i].payment_info[0].pay_method === "카드"
          ? payments[i].payment_info[0].buyer.replace("카드사", "").replace("카드", "")
          : payments[i].payment_info[0].pay_method;
      newRow.취소금액 =
        payments[i].payment_info[0].price < 0 ? payments[i].payment_info[0].price : "";
      newRow.결제금액 =
        payments[i].payment_info[0].price < 0 ? "" : payments[i].payment_info[0].price;
      newRow.결제수단변경 = payments[i].payment_info[0].price;

      for (let k = 0; k < monthlySalesRecordData.length; k++) {
        const regDate = monthlySalesRecordData[k].등록일;
        const reason = monthlySalesRecordData[k].구분;
        const memberNo = monthlySalesRecordData[k].회원번호;
        const purchaseCode = monthlySalesRecordData[k].구매코드;

        // row 정보가 "결제수단변경"이고 같은 구매코드면 내역 끼워넣기
        if (
          reason === "결제수단변경" &&
          regDate === payments[i].reg_date &&
          memberNo === payments[i].member_info.member_no &&
          purchaseCode === payments[i].purchase_code
        ) {
          if (monthlySalesRecordData[k].결제수단 === "") {
            Object.assign(monthlySalesRecordData[k], newRow);
          } else {
            newRow.구분 = payments[i].reason;
            monthlySalesRecordData.splice(k + 1, 0, newRow);
          }
          isExistChangePayment = true;
          break;
        }
      }
      // "결제수단변경" row 정보가 없고 같은 월에 결제한 기록이 없으면
      let isSameMonthRecord = false;
      if (!isExistChangePayment) {
        // 기존 결제 내역이 그 달에 존재하면 끼워넣기
        for (let k = 0; k < monthlySalesRecordData.length; k++) {
          const memberNo = monthlySalesRecordData[k].회원번호;
          const purchaseCode = monthlySalesRecordData[k].구매코드;
          if (
            memberNo === payments[i].member_info.member_no &&
            purchaseCode === payments[i].purchase_code
          ) {
            newRow.구분 = payments[i].reason;
            monthlySalesRecordData.splice(k + 1, 0, newRow);
            isSameMonthRecord = true;
            break;
          }
        }
        // 같은 월에 아무런 기록이 없으면 그냥 넣기
        if (!isSameMonthRecord) {
          newRow.구분 = payments[i].reason;
          monthlySalesRecordData.push(newRow);
        }
      }
    }
    // 결제내역이 "환불("구분")" 일 때
    if (payments[i].payment_info && payments[i].reason.includes("환불(")) {
      const newRow = { ...row };
      newRow.결제수단 =
        payments[i].payment_info[0].pay_method === "카드"
          ? payments[i].payment_info[0].buyer.replace("카드사", "").replace("카드", "")
          : payments[i].payment_info[0].pay_method;
      newRow.취소금액 =
        payments[i].payment_info[0].price < 0 ? payments[i].payment_info[0].price : "";
      newRow.결제금액 =
        payments[i].payment_info[0].price < 0 ? "" : payments[i].payment_info[0].price;

      for (let k = 0; k < monthlySalesRecordData.length; k++) {
        const regDate = monthlySalesRecordData[k].등록일;
        const reason = monthlySalesRecordData[k].구분;
        const memberNo = monthlySalesRecordData[k].회원번호;
        const purchaseCode = monthlySalesRecordData[k].구매코드;
        const comparePurchaseCode =
          payments[i].payment_info.length === 1
            ? payments[i].payment_info[0].purchase_code
            : payments[i].purchase_code;

        // row 정보가 "환불" 일때 기존 카드결제 내역 끼워넣기
        if (
          reason === "환불" &&
          regDate === payments[i].reg_date &&
          memberNo === payments[i].member_info.member_no &&
          purchaseCode === comparePurchaseCode
        ) {
          if (monthlySalesRecordData[k].결제수단 === "") {
            Object.assign(monthlySalesRecordData[k], newRow);
          } else {
            newRow.구분 = payments[i].reason;
            monthlySalesRecordData.splice(k + 1, 0, newRow);
          }
          break;
        }
      }
    }

    // 결제수단이 여러개 있는 경우
    if (payments[i].payment_info && payments[i].payment_info.length > 0) {
      for (let j = 0; j < payments[i].payment_info.length; j++) {
        const newRow = { ...row };
        newRow.결제수단 =
          payments[i].payment_info[j].pay_method === "카드"
            ? payments[i].payment_info[j].buyer.replace("카드사", "").replace("카드", "")
            : payments[i].payment_info[j].pay_method;
        newRow.취소금액 =
          payments[i].payment_info[j].price < 0 ? payments[i].payment_info[j].price : "";
        newRow.결제금액 =
          payments[i].payment_info[j].price < 0 ? "" : payments[i].payment_info[j].price;

        for (let k = 0; k < monthlySalesRecordData.length; k++) {
          const regDate = monthlySalesRecordData[k].등록일;
          const reason = monthlySalesRecordData[k].구분;
          const memberNo = monthlySalesRecordData[k].회원번호;
          const purchaseCode = monthlySalesRecordData[k].구매코드;

          if (
            reason === payments[i].reason &&
            regDate === payments[i].reg_date &&
            memberNo === payments[i].member_info.member_no &&
            purchaseCode === payments[i].purchase_code
          ) {
            if (monthlySalesRecordData[k].결제수단 === "") {
              Object.assign(monthlySalesRecordData[k], newRow);
            } else {
              newRow.구분 = payments[i].reason;
              monthlySalesRecordData.splice(k + 1, 0, newRow);
            }
            continue;
          }
        }
      }
    }
  }

  for (let i = 0; i < monthlySalesRecordData.length; i++) {
    let payPrice = 0;
    let cancelPrice = 0;
    const el = monthlySalesRecordData[i];
    payPrice = el.결제금액;
    cancelPrice = el.취소금액;
    // "구매코드" 필드 삭제
    delete el.구매코드;

    for (const key in el) {
      // KB, NH 글자 제거
      if (key === "결제수단") {
        switch (el[key]) {
          case "KB국민":
            el[key] = "국민";
            break;
          case "NH농협":
            el[key] = "농협";
            break;
          default:
            break;
        }
      }
      // 결제 수단이 여러개 일 때 각 row별 금액의 합이 결제금액을 넘지 않도록 분리
      if (
        typeof el[key] === "number" &&
        key !== "취소금액" &&
        key !== "결제금액" &&
        key !== "결제수단변경" &&
        key !== "현장_취소금액" &&
        key !== "결제수단변경"
      ) {
        if (i + 1 <= monthlySalesRecordData.length && el[key] > payPrice) {
          monthlySalesRecordData[i + 1][key] = el[key] - payPrice;
          el[key] = payPrice;
          payPrice = 0;
        } else {
          payPrice = payPrice - el[key];
        }
      }
      // 취소 수단이 여러개 일 때 각 row별 금액의 합이 취소금액을 넘지 않도록 분리
      if (typeof el[key] === "number" && key === "현장_취소금액") {
        if (i + 1 <= monthlySalesRecordData.length && el[key] < cancelPrice) {
          monthlySalesRecordData[i + 1][key] = el[key] - cancelPrice;
          el[key] = cancelPrice;
          cancelPrice = 0;
        } else {
          cancelPrice = cancelPrice - el[key];
        }
      }
      // 0인 값 제거 및 빈칸 null
      if (el[key] === 0 || el[key] === "") {
        el[key] = null;
      }
    }

    // 취소금액과 결제금액이 모두 빈 문자면 원소 제거
    if (el.취소금액 === null && el.결제금액 === null) {
      monthlySalesRecordData.splice(i, 1);
      // 원소 삭제한 후 인덱스 조정
      i -= 1;
    }
  }
  monthlySalesRecordData.sort((a, b) => new Date(a.등록일) - new Date(b.등록일));
  excelExport(monthlySalesRecordData, fileName);
};

// 현금출납장 수입/지출 내역 엑셀
export const exportToCashBookExcel = (type, data, fileName) => {
  const monthlyData = [];

  data.forEach((el) => {
    const row = {};
    for (let key in el) {
      if (key === "reg_date") {
        // 미리 순서 잡아주기
        row.날짜 = el[key];
        row.입력구분 = "";
        row.사유 = "";
        row.계정 = "";
        row.금액 = "";
        if (type !== "지출상세") {
          row.회원번호 = "";
          row.회원명 = "";
        }
        row.메모 = "";
      }
      if (key === "create_type") {
        row.입력구분 = el[key];
      }
      if (key === "reason") {
        row.사유 = el[key];
      }
      if (key === "account") {
        row.계정 = el[key];
      }
      if (key === "price") {
        switch (type) {
          case "수입집계":
            row.수입금액 = el[key];
            break;
          case "지출집계":
            row.실제지출금액 = el[key];
            break;
          case "수입상세":
            row.금액 = el[key];
            break;
          case "지출상세":
            row.금액 = el[key];
            break;
          default:
            break;
        }
      }
      if (type !== "지출상세" && key === "member_no") {
        row.회원번호 = el[key];
      }
      if (type !== "지출상세" && key === "member_name") {
        row.회원명 = el[key];
      }
      if (key === "memo") {
        row.메모 = el[key];
      }
    }
    monthlyData.push(row);
  });

  excelExport(monthlyData, fileName);
};
const getItemPrice = (itemList, type) => {
  const result = itemList.reduce((sum, cur) => {
    if (cur.product === type) {
      return sum + cur.paid_price;
    }
    return sum;
  }, 0);

  if (result) {
    return result.toLocaleString();
  } else {
    return "";
  }
};
const getNumberItemPrice = (itemList, type) => {
  const result = itemList.reduce((sum, cur) => {
    if (cur.product === type) {
      return sum + cur.paid_price;
    }
    return sum;
  }, 0);

  if (result) {
    return result;
  } else {
    return "";
  }
};

const getTotalDisPrice = (itemList) => {
  const result = itemList.reduce((sum, cur) => {
    return sum + cur.dis;
  }, 0);

  if (result) {
    return result.toLocaleString();
  } else {
    return "";
  }
};

const getTotalPrice = (itemList) => {
  const result = itemList.reduce((sum, cur) => {
    return sum + cur.paid_price;
  }, 0);

  return result.toLocaleString();
};
const getPaidItems = (data) => {
  let result = "";

  for (let i = 0; i < data.length; i++) {
    result += data[i].description;
    if (i < data.length - 1 && data[i].description !== "") {
      result += " ";
    }
  }
  return result;
};

const getListMerger = (data) => {
  let resultStr = "";
  for (let i = 0; i < data.product_list.length; i++) {
    resultStr += data.product_list[i].product + ",";
  }
  // resultStr = resultStr.trimEnd(" ,");
  if (resultStr !== "") {
    resultStr = resultStr.slice(0, -1);
  }

  return resultStr;
};

const get_refund_total = (data) => {
  let totalRefund =
    data.refund_info.re_paid +
    data.refund_info.billing_amount +
    data.refund_info.discounted_refund -
    data.refund_info.on_site_cancellation_amount;
  return totalRefund.toLocaleString();
};

const getTotalPaymentPrice = (pInfoList) => {
  if (pInfoList !== null) {
    return pInfoList.reduce((sum, cur) => {
      return sum + cur.price;
    }, 0);
  }
  return 0;
};

const getRegDevice = (data) => {
  if (data.payment_info && data.payment_info.length > 0) {
    if (data.payment_info[0].paid_by === "tablet") {
      return "리더기";
    }
  }
  return "단말기(ERP)";
};
