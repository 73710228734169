import * as lodash from "lodash";
import { useEffect, useState } from "react";
import ChooseModal from "./ChooseModal";
import DeductSalaryModal from "./DeductSalaryModal";
import EditBasicCommissionModal from "./EditBasicCommissionModal";
import EditSalaryDateModal from "./EditSalaryDateModal";
import EmployAttendanceModal from "./EmployAttendanceModal";
import EtcSalaryAddModal from "./EtcSalaryAddModal";
import GxDetailAddModal from "./GxDetailAddModal";
import LoadCommissionModal from "./LoadCommissionModal";
import SessionEditModal from "./SessionEditModal";
import SetBasicCommissionModal from "./SetBasicCommissionModal";

const SalaryInfoModalIndex = ({ modalSelect, modalParam, onModalDone }) => {
  const [paramData, setParamData] = useState(modalParam.modalParam);

  useEffect(() => {
    const editData = lodash.cloneDeep(modalParam.modalParam);
    setParamData(editData);
    return () => {};
  }, []);

  if (modalSelect === "결재선지정") {
    return <ChooseModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "급여일수정") {
    return <EditSalaryDateModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "급여차감") {
    return <DeductSalaryModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "근태상세보기") {
    return <EmployAttendanceModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "당월커미션설정") {
    return <SetBasicCommissionModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "커미션설정불러오기") {
    return <LoadCommissionModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "당월커미션수정") {
    return <EditBasicCommissionModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "세션가변경") {
    return <SessionEditModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "GX수당상세추가") {
    return <GxDetailAddModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "기타수당내역추가") {
    return <EtcSalaryAddModal modalParam={paramData} onModalDone={onModalDone} />;
  } else {
    return <div>current ELSE</div>;
  }
};
export default SalaryInfoModalIndex;
