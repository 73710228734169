import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { checkFileSize, simpleAlert } from "components/CommonLib/CommonLib";
import { uploadFile } from "components/File/uploadFile";
import FileUpload from "components/LabelInput/FileUpload";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import { globalAlertOff, globalAlertOn } from "modules/actions/Alert";
import { useState } from "react";
import { useDispatch } from "react-redux";

const UploadPopup = ({ params, onModalDone }) => {
  const storesDispatch = useDispatch();
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const btnStyleClass = btnStyles();

  const onUploadedFile = (result) => {
    if (result.error) {
      console.log(result.error);
    } else {
      storesDispatch(globalAlertOff());
      onModalDone();
    }
    setLoading(false);
  };

  const getMakeNewInfo = () => {
    if (params.resource_id === "") {
      let fileInfo = {
        file_info: {
          belong_type: "tablet_ref",
          doc_name: "상담자료",
          file_due_date: "",
          file_name: "",
          reason: "상담자료",
          user_id: params.branch_info.branch_id,
          is_full_doc: false,
        },
      };
      return fileInfo;
    } else {
      return params.file_object;
    }
  };

  const onClickSave = () => {
    if (!file) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="파일을 첨부해 주시기 바랍니다." />,
          onBtnOk: () => {},
        }),
      );
    } else {
      const fileInfo = getMakeNewInfo();
      const canUpload = checkFileSize(file, 20);
      if (!canUpload) {
        simpleAlert(storesDispatch, "알림", "파일 사이즈는 20MB 이하로 등록 가능합니다.");
      } else {
        simpleAlert(storesDispatch, "알림", "파일을 등록 하시겠습니까?", false, undefined, () => {
          uploadFile(fileInfo, file, onUploadedFile);
          setLoading(true);
        });
      }
    }
  };

  return (
    <div style={{ width: "20rem" }}>
      {loading && <Loading />}
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <FileUpload
          textBoxWidth={"14rem"}
          textMarginRight={"0rem"}
          onUploadFile={(file) => {
            setFile(file);
          }}
          hideDownload
        />
      </div>
      <Label
        marginTop="0.5rem"
        labelText="※ 파일명은 숫자 혹은 영어로만 입력해주세요."
        color="red"
        fontSize="0.8rem"
      />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1.5rem",
        }}
      >
        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={onClickSave}
        >
          저장
        </Button>
      </div>
    </div>
  );
};
export default UploadPopup;
