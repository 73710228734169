import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import GxHistSearchCard from "./GxHistSearchCard";
import GxHistSearchResult from "./GxSearchResult";

import { useQuery } from "@tanstack/react-query";
import { getGxHistList } from "apis/staff/class_hist_api";
import { checkDateOrder, generateObjectZeroId, simpleAlert } from "components/CommonLib/CommonLib";
import { useClassHistStore } from "../../modules/store/classHistState";

const SearchCard = ({
  searchParams,
  setSearchParams,
  resetSearchParams,
  onClickSearch,
  branchInfo,
}) => {
  return (
    <GxHistSearchCard
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      resetSearchParams={resetSearchParams}
      onClickSearch={onClickSearch}
      branchInfo={branchInfo}
    />
  );
};

const SearchResultCard = ({ curPage, setCurPage, totalPage, searchResult }) => {
  return (
    <GxHistSearchResult
      curPage={curPage}
      setCurPage={setCurPage}
      totalPage={totalPage}
      searchResult={searchResult}
    />
  );
};

const GxHistView = ({ branchInfo }) => {
  const storesDispatch = useDispatch();
  // 전역 상태 관리
  const { setLoading, searchParams, setSearchParams, setPage, page, resetSearchParams } =
    useClassHistStore().returnStoreByType("gx");
  // 페이지 상태 관리
  const [searchResult, setSearchResult] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);

  // API 상태 관리
  const [isGetGxHistList, setIsGetGxHistList] = useState(false);
  const queryData = { page: page, searchParams: searchParams };

  // API Query
  // [GET] GX 수업 이력 검색
  const { data: gxHistData } = useQuery({
    queryKey: ["classHist", "gx", queryData],
    queryFn: () => getGxHistList(queryData),
    enabled: isGetGxHistList,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setIsGetGxHistList(false);
      setLoading(false);
    },
  });

  //검색 POST
  const onClickSearch = async (page) => {
    // 날짜 시작일과 종료일 체크
    if (checkDateOrder(searchParams.class_from_date, searchParams.class_to_date)) {
      setPage(page);
      setLoading(true);
      setIsGetGxHistList(true);
    } else {
      setSearchParams("class_from_date", "");
      setSearchParams("class_to_date", "");
    }
  };

  // API 받아온 데이터 세팅
  useEffect(() => {
    // 검색 결과 데이터 세팅
    if (gxHistData) {
      setSearchResult(gxHistData.data.data);
      setTotalPage(gxHistData.data.total_page);
    }
  }, [gxHistData]);

  // 페이지 진입 시 리스트 받아오기
  useEffect(() => {
    if (isFirstEnterPage && searchParams.branch_info.brnach_id !== generateObjectZeroId()) {
      onClickSearch(page);
      setIsFirstEnterPage(false);
    }
  }, [searchParams.branch_info.brnach_id]);

  //페이지네이션
  useEffect(() => {
    if (page) {
      onClickSearch(page);
    }
  }, [page]);

  return (
    <div>
      <SearchCard
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        resetSearchParams={resetSearchParams}
        onClickSearch={onClickSearch}
        branchInfo={branchInfo}
      />
      <SearchResultCard
        curPage={page}
        setCurPage={setPage}
        totalPage={totalPage}
        searchResult={searchResult}
      />
    </div>
  );
};
export default GxHistView;
