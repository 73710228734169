///SETTING
export const loadLockerSetting = (branch_id, branch_name) => {
  return {
    type: "LOAD_LOCKER_SETTING",
    branch_id: branch_id,
    branch_name: branch_name,
  };
};

export const loadLockerSettingSuccess = (data) => {
  return {
    type: "LOAD_LOCKER_SETTING_SUCCESS",
    payload: data,
  };
};

export const loadLockerSettingFail = (data) => {
  return {
    type: "LOAD_LOCKER_SETTING_FAIL",
    payload: data,
  };
};

///SHOW
export const loadLockerSettingShow = (branch_id, branch_name) => {
  return {
    type: "LOAD_LOCKER_SETTING_SHOW",
    branch_id: branch_id,
    branch_name: branch_name,
  };
};

export const loadLockerSettingShowSuccess = (data) => {
  return {
    type: "LOAD_LOCKER_SETTING_SHOW_SUCCESS",
    payload: data,
  };
};

export const loadLockerSettingShowFail = (data) => {
  return {
    type: "LOAD_LOCKER_SETTING_SHOW_FAIL",
    payload: data,
  };
};

///ALLOCATE
export const loadLockerAllocateShow = (branch_id, branch_name, floor_id, set_info_id) => {
  return {
    type: "LOAD_LOCKER_ALLOCATE_SHOW",
    branch_id: branch_id,
    branch_name: branch_name,
    floor_id: floor_id,
    set_info_id: set_info_id,
  };
};

export const loadLockerAllocateShowSuccess = (data) => {
  return {
    type: "LOAD_LOCKER_ALLOCATE_SHOW_SUCCESS",
    payload: data,
  };
};

export const loadLockerAllocateShowFail = (data) => {
  return {
    type: "LOAD_LOCKER_ALLOCATE_SHOW_FAIL",
    payload: data,
  };
};
export const loadLockerAllocateShowReset = (data) => {
  return {
    type: "LOAD_LOCKER_ALLOCATE_SHOW_RESET",
    payload: data,
  };
};

///DetailView
export const loadLockerDetailShow = (branch_id, branch_name, floor_id, zone_id, locker_idx) => {
  return {
    type: "LOAD_LOCKER_DETAIL_SHOW",
    branch_id: branch_id,
    branch_name: branch_name,
    floor_id: floor_id,
    zone_id: zone_id,
    locker_idx: locker_idx,
  };
};

export const loadLockerDetailShowSuccess = (data) => {
  return {
    type: "LOAD_LOCKER_DETAIL_SHOW_SUCCESS",
    payload: data,
  };
};

export const loadLockerDetailShowShowFail = (data) => {
  return {
    type: "LOAD_LOCKER_DETAIL_SHOW_FAIL",
    payload: data,
  };
};
export const loadLockerDetailShowReset = (data) => {
  return {
    type: "LOAD_LOCKER_DETAIL_SHOW_RESET",
    payload: data,
  };
};
