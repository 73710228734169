import { useMutation, useQueries, useQuery } from "@tanstack/react-query";
import {
  getDailyReportDetail,
  getDailyReportList,
  getDailyReportSearchParam,
  getMonthlySales,
  getTransferReq,
  getUnpaidExclusion,
  getUnpaidState,
  getVaultCash,
  refreshVaultCash,
} from "apis/salary/daily_report_api";
import { dailyReportKeys } from "apis/salary/query_keys_salary";

import { simpleAlert } from "components/CommonLib/CommonLib";
import { useDispatch } from "react-redux";

// [GET] 운영일지 검색 조건 받아오기 및 권한 데이터 세팅
export const useDailyReportSearchParam = (enabled) => {
  const storesDispatch = useDispatch();
  return useQuery({
    queryKey: dailyReportKeys.firstSearchParam(),
    queryFn: () => getDailyReportSearchParam(),
    enabled: enabled,
    retry: false,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });
};

// [GET] 운영일지 내역 리스트 검색
export const useDailyReportList = (queryData, enabled, setEnabled) => {
  const storesDispatch = useDispatch();

  return useQuery({
    queryKey: dailyReportKeys.list(queryData),
    queryFn: () => getDailyReportList(queryData),
    enabled: enabled,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setEnabled(false);
    },
  });
};

// [GET] 운영일지 상세 데이터
export const useDailyReportDetail = (queryData) => {
  const storesDispatch = useDispatch();
  const { reportInfoId } = queryData;

  return useQuery({
    queryKey: dailyReportKeys.detail(queryData),
    queryFn: () => getDailyReportDetail(queryData),
    enabled: !!reportInfoId,
    retry: false,
    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });
};

// [GET] 운영일지 월간 매출
export const useMonthlySales = (queryData) => {
  const storesDispatch = useDispatch();
  const { branch_id } = queryData.branch_info;

  return useQuery({
    queryKey: dailyReportKeys.monthlySales(queryData),
    queryFn: () => getMonthlySales(queryData),
    enabled: !!branch_id,
    retry: false,
    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });
};

// [GET] 여러 지점의 운영일지 월간 매출 조회
export const useMonthlySalesByBranch = (queryDataList, enabled) => {
  const storesDispatch = useDispatch();

  return useQueries({
    queries: queryDataList
      .filter((queryData) => queryData.branch_info.branch_name !== "테스트")
      .map((queryData) => {
        return {
          queryKey: dailyReportKeys.monthlySales(queryData),
          queryFn: () => getMonthlySales(queryData),
          enabled: enabled,
          retry: false,

          // 반환 데이터에 branch_name 추가 및 총계만 나오도록 가공
          select: (data) => {
            return {
              ...data,
              base_date: queryData.base_date,
              sales_total: data.data.data[2].sales_total,
              branch_name: queryData.branch_info.branch_name,
            };
          },
          onSuccess: (res) => {
            if (res.data.msg) {
              simpleAlert(storesDispatch, "알림", res.data.msg);
            }
          },
          onError: (err) => {
            simpleAlert(
              storesDispatch,
              "ERROR",
              err.response.data ? err.response.data.msg : err.code,
            );
          },
        };
      }),
  });
};

// [GET] 운영일지 미납금 현황
export const useUnpaidState = (queryData) => {
  const storesDispatch = useDispatch();
  const { branch_id } = queryData.branch_info;

  return useQuery({
    queryKey: dailyReportKeys.unpaidStatus(queryData),
    queryFn: () => getUnpaidState(queryData),
    enabled: !!branch_id,
    retry: false,
    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });
};

// [GET] 운영일지 미납금 제외 처리 목록
export const useUnpaidExclusion = (queryData) => {
  const storesDispatch = useDispatch();
  const { branch_id } = queryData.branch_info;

  return useQuery({
    queryKey: dailyReportKeys.unpaidExclusion(queryData),
    queryFn: () => getUnpaidExclusion(queryData),
    enabled: !!branch_id,
    retry: false,
    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });
};

// [GET] 운영일지 환불 요청 목록
export const useTransferReq = (queryData) => {
  const storesDispatch = useDispatch();
  const { branch_id } = queryData.branch_info;

  return useQuery({
    queryKey: dailyReportKeys.transferReq(queryData),
    queryFn: () => getTransferReq(queryData),
    enabled: !!branch_id,
    retry: false,
    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });
};

// [GET] 운영일지 시재금 현황
export const useVaultCash = (queryData) => {
  const storesDispatch = useDispatch();
  const { branch_id } = queryData.branch_info;

  return useQuery({
    queryKey: dailyReportKeys.vaultCash(queryData),
    queryFn: () => getVaultCash(queryData),
    enabled: !!branch_id,
    retry: false,
    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });
};

// [GET] 운영일지 시재금 현황 새로고침 (GET요청이지만 POST처럼 써서 Mutation으로 처리)
export const useRefreshVaultCash = (queryData) => {
  const storesDispatch = useDispatch();

  return useMutation({
    mutationFn: () => refreshVaultCash(queryData),
    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });
};
