import { combineReducers } from "redux";
import * as ActionTypes from "../actions/action_type";

const hqBaseStateinitial = {
  stores: {
    data: {
      hq_info: {
        hq_id: "",
        hq_name: "",
      },
      package_info: {
        package_id: "",
        package_name: "",
      },
      owner_info: {
        owner_name: "",
        owner_gender: "",
        owner_phone: "",
        owner_email: "",
        owner_birthday: "",
      },
      reg_info: {
        created_at: "",
      },
      status: "",
    },
  },
};
const hq_base_state = (state = hqBaseStateinitial, action) => {
  switch (action.type) {
    case ActionTypes.getHqBaseSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getHqBaseFailAction:
    case ActionTypes.getHqBaseResetAction:
      return { ...state, stores: hqBaseStateinitial.stores };
    default:
      return state;
  }
};

const hqSet = {
  // depart: [],
  // pos: [],
  // duty: [],
  employee_list: [],
  dept_list: [],
  position_list: [],
  position_detail_list: [],
  rank_list: [],
};

const policySet = {
  refund_price: 0,
  refund_nor_per: 0,
  refund_sub_per: 0,
  delay_normal_month: 0,

  delay_normal_day: 0,
  delay_sub_month: 0,
  delay_lim: 0,

  assign_option: {
    include_all: false,
    except_service: false,
    except_event: false,
    except_all: false,
  },
  exp_std_mem: 0,
  exp_std_pt: 0,
};

const hqInfoSet = {
  hq_id: "",
  hq_name: "",
}

const hqHqSetStateinitial = {
  stores: {
    data: {
      contents: {
        hq_info: hqInfoSet,
        hq_set: hqSet,
        policy_set: policySet,
      }
    },
  },
};
const hq_hq_set_state = (state = hqHqSetStateinitial, action) => {
  switch (action.type) {
    case ActionTypes.getHqManageViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getHqManageViewFailAction:
    case ActionTypes.getHqManageViewResetAction:
      return { ...state, stores: hqHqSetStateinitial.stores };
    default:
      return state;
  }
};

const hqBranchSetStateinitial = {
  stores: {
    data: [],
  },
};
const hq_branch_set_state = (state = hqBranchSetStateinitial, action) => {
  switch (action.type) {
    case ActionTypes.getBranchManageSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getBranchManageFailAction:
    case ActionTypes.getBranchManageResetAction:
      return { ...state, stores: hqBranchSetStateinitial.stores };
    default:
      return state;
  }
};

const hqPolicySetStateinitial = {
  stores: {
    data: [],
  },
};
const hq_policy_set_state = (state = hqPolicySetStateinitial, action) => {
  switch (action.type) {
    case ActionTypes.getPolicyManageViewSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getPolicyManageViewFailAction:
    case ActionTypes.getPolicyManageViewResetAction:
      return { ...state, stores: hqPolicySetStateinitial.stores };
    default:
      return state;
  }
};

const hqBranchInfoStateinitial = {
  stores: {
    data: {
      branch_id: "",
      branch_name: "",
      num: 0,
      open_data: "",
      manager_list: [],
      global_info: {
        assign_branch_list: [],
        assign_branch_reject_list: [],
      },
    },
  },
};
const hq_branchInfo_state = (state = hqBranchInfoStateinitial, action) => {
  switch (action.type) {
    case ActionTypes.getBranchInfoSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getBranchInfoFailAction:
    case ActionTypes.getBranchInfoResetAction:
      return { ...state, stores: hqBranchInfoStateinitial.stores };
    default:
      return state;
  }
};

export const hqSettingReducer = combineReducers({
  hq_base_state,
  hq_hq_set_state,
  hq_branch_set_state,
  hq_policy_set_state,
  hq_branchInfo_state,
});
