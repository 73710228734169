export const lockerAllInfoInit = {
  allocate_list: [[]],
  branch_id: "",
  branch_name: "",
  count_info: {
    almost: 0,
    broken: 0,
    expired: 0,
    ready: 0,
    total: 0,
    un_passwd: 0,
    used: 0,
  },
  disable_list: [],
  floor_id: "",
  height: 0,
  width: 0,
  id: "",
  zone_id: "",
};

export const lockerDetailInit = {
  active: 0,
  broken_date: "",
  broken_desc: "",
  level: 0,
  locker_no: 0,
  locker_total_idx: 0,
  member_info: {
    birth: "",
    from_date: "",
    gender: "",
    is_new: true,
    item_name: "",
    mbshp_option: "",
    mbshp_type: "",
    member_id: "",
    member_name: "",
    member_no: "",
    membership_id: "",
    phone: "",
    to_date: "",
  },
  num: 0,
  password: "",
  queue_id: "",
  status: "",
};
