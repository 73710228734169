export const parseMenuIndex = (menuActive) => {
  if (menuActive === "신규등록") {
    return {
      assign_active: false,
      register_active: true,
      search_active: false,
      setting_active: false,
    };
  } else if (menuActive === "설정") {
    return {
      assign_active: false,
      register_active: false,
      search_active: false,
      setting_active: true,
    };
  } else {
    return {
      assign_active: false,
      register_active: false,
      search_active: false,
      setting_active: false,
    };
  }
};
