import {
  accountBranchInfo,
  accountBranchInfoSuccess,
  accountBranchInfoFail,
  registerSettingInfo,
  registerSettingInfoSuccess,
  registerSettingInfoFail,
  registerSettingInfoClear,
} from "./register_actions";

import {
  loadRegisterView,
  loadRegisterViewSuccess,
  loadRegisterViewFail,
  loadRegisterViewReset,
} from "./add_pay_act";

export const registerSettingAction = {
  accountBranchInfo,
  accountBranchInfoSuccess,
  accountBranchInfoFail,
  registerSettingInfo,
  registerSettingInfoSuccess,
  registerSettingInfoFail,
  registerSettingInfoClear,
};

export const loadAddPayViewStore = (data) => loadRegisterView(data);

export const registerViewAction = {
  loadRegisterViewSuccess,
  loadRegisterViewFail,
  loadRegisterViewReset,
};
