import { SubLine } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import NumberTextField from "components/LabelInput/NumberTextField";
import { useEffect } from "react";

const MembershipSetting = ({ branchPolicy, restData, mbshpOption, period, isEditMode }) => {
  const textLabelWidth = "6rem";
  const textLabelWidthShort = "4rem";
  const textBoxWidth = "10rem";
  const labelMarginLeft = "3rem";

  const stdMbshpPrice =
    mbshpOption === "일일권"
      ? branchPolicy.base_policy.oneday_mbshp
      : branchPolicy.base_policy.prepay_mbshp;

  useEffect(() => {
    if (!isEditMode) {
      restData.mbshp_price = mbshpOption === "일일권" ? stdMbshpPrice : stdMbshpPrice * period;
      restData.reg_price = branchPolicy.base_policy.prepay_reg;
    }
  }, [period]);

  useEffect(() => {
    // 일일권 선택한 경우 상품금액 입력
    if (!isEditMode && mbshpOption === "일일권") {
      restData.mbshp_price = stdMbshpPrice;
    }
  }, [mbshpOption]);

  return (
    <div style={{}}>
      <div style={{ display: "flex", marginTop: "1.25rem", marginBottom: "0.625rem" }}>
        <Label labelText={"회원권 금액"} />
      </div>
      <div style={{ marginLeft: "2rem" }}>
        <div style={{ display: "flex" }}>
          {mbshpOption === "일일권" ? (
            <NumberTextField
              isNumber
              labelWidth={textLabelWidth}
              textBoxWidth={textBoxWidth}
              labelText="· 정가"
              defaultValue={stdMbshpPrice}
              endAdornment="원"
              disabled
            />
          ) : (
            <NumberTextField
              isNumber
              labelWidth={textLabelWidth}
              textBoxWidth={textBoxWidth}
              labelText="· 정가"
              defaultValue={stdMbshpPrice * period}
              endAdornment="원"
              disabled
            />
          )}

          <NumberTextField
            isNumber
            labelMarginLeft={labelMarginLeft}
            labelWidth={textLabelWidthShort}
            textBoxWidth={textBoxWidth}
            labelText="· 판매가"
            endAdornment="원"
            minValue={0}
            maxValue={99999999}
            defaultValue={
              isEditMode
                ? restData.mbshp_price
                : mbshpOption === "일일권"
                ? stdMbshpPrice
                : stdMbshpPrice * period
            }
            onChangeCallback={(e) => {
              restData.mbshp_price = e;
            }}
            disabled={isEditMode}
          />
        </div>
      </div>
      <SubLine />

      {mbshpOption !== "일일권" ? (
        <div>
          <div>
            <div style={{ display: "flex", marginTop: "1.25rem", marginBottom: "0.625rem" }}>
              <Label labelText={"등록비"} />
            </div>
            <div
              style={{
                display: "flex",
                marginLeft: "2rem",
              }}
            >
              <NumberTextField
                isNumber
                labelWidth={textLabelWidth}
                textBoxWidth={textBoxWidth}
                labelText="· 정가"
                defaultValue={branchPolicy.base_policy.prepay_reg}
                endAdornment="원"
                disabled
              />

              <NumberTextField
                isNumber
                labelMarginLeft={labelMarginLeft}
                labelWidth={textLabelWidthShort}
                textBoxWidth={textBoxWidth}
                labelText="· 판매가"
                endAdornment="원"
                minValue={0}
                maxValue={99999999}
                defaultValue={isEditMode ? restData.reg_price : branchPolicy.base_policy.prepay_reg}
                onChangeCallback={(e) => {
                  restData.reg_price = e;
                }}
                disabled={isEditMode}
              />
            </div>
          </div>

          <SubLine />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default MembershipSetting;
