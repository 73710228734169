import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import { noneSelectType } from "pages/ERP/MemberRegister/Register/Module/reducers/setting_initial_state";
import { useRegisterStore } from "pages/ERP/MemberRegister/Register/modules/store/registerState";
import { forwardRef, useEffect, useImperativeHandle, useState, useTransition } from "react";
import { useDispatch } from "react-redux";
import { structToItemName, structToMembershipSettings } from "../parseSettings";

const SelectOption = ({
  selectorInfo,
  onOptChange,
  memberInputState,
  canSelectDailyOpt,
  canSelectPassOpt,
  canSelectTimeOpt,
}) => {
  const labelWidth = "4.5rem";
  const compWidth = "12rem";

  const getOptionData = () => {
    const res = ["일반권", "패스권", "시간권", "일일권"];

    if (!canSelectPassOpt) {
      const idx = res.findIndex((x) => x === "패스권");
      res.splice(idx, 1);
    }
    if (!canSelectTimeOpt) {
      const idx = res.findIndex((x) => x === "시간권");
      res.splice(idx, 1);
    }

    if (selectorInfo.mbshp_type === "완납형" && canSelectDailyOpt === true) {
      //
    } else {
      ///일일권 이슈 231208
      // const idx = res.findIndex((x) => x === "일일권");
      // res.splice(idx, 1);
    }

    return res;
  };

  const optionData = getOptionData();

  if (memberInputState.tab_info.is_add_pay === true && memberInputState.tab_info.is_new === false) {
    return (
      <TextField
        labelWidth={labelWidth}
        textBoxWidth={compWidth}
        labelText={"옵션"}
        defaultValue={memberInputState.membership_list.mbshp_option}
        disabled
      />
    );
  } else {
    return (
      <ComboBox
        marginTop="0.5rem"
        labelWidth={labelWidth}
        comboItemWidth={compWidth}
        labelText="옵션"
        data={optionData}
        defaultValue={selectorInfo.mbshp_option}
        onChangeCallback={(e) => {
          onOptChange(e.target.value);
        }}
        disabled={memberInputState.membership_list.option_lock}

        // disabled={isPending}
      />
    );
  }
};

const SelectItemCombo = ({ itemNameList, selectorInfo, onItemChange, memberInputState }) => {
  const labelWidth = "4.5rem";
  const marginTop = "0.5rem";
  const passText =
    selectorInfo.mbshp_type === "완납형" ? "* 출입 가능 횟수 : " : "* 월 출입 가능 횟수 : ";

  if (memberInputState.tab_info.is_add_pay === true && memberInputState.tab_info.is_new === false) {
    return (
      <TextField
        labelWidth={labelWidth}
        textBoxWidth={"12rem"}
        labelText={"상품명"}
        defaultValue={memberInputState.purchase_item_list.item_info.item_name}
        disabled
      />
    );
  } else {
    return (
      <div style={{ display: "flex" }}>
        <ComboBox
          required
          marginTop={marginTop}
          labelWidth={labelWidth}
          comboItemWidth={selectorInfo.mbshp_option === "일일권" ? "18rem" : "19rem"}
          labelText="상품명"
          data={itemNameList}
          defaultValue={selectorInfo.item_name === "선택해주세요" ? "" : selectorInfo.item_name}
          onChangeCallback={(e) => {
            const index = itemNameList.map((item) => item).indexOf(e.target.value);
            if (index !== -1) {
              onItemChange(index);
            }
          }}
          disabled={memberInputState.membership_list.option_lock}

          // disabled={isPending}
        />
        {selectorInfo.mbshp_option === "패스권" && selectorInfo.pass_cnt ? (
          <Label
            marginLeft="1rem"
            labelText={passText + selectorInfo.pass_cnt + "회"}
            fontSize="0.8rem"
          />
        ) : selectorInfo.mbshp_option === "시간권" && selectorInfo.pass_from_time ? (
          <Label
            marginLeft="1rem"
            labelText={
              "* 출입 가능 시간 : " + selectorInfo.pass_from_time + "~" + selectorInfo.pass_end_time
            }
            fontSize="0.8rem"
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
};

const MembershipItemSet = forwardRef((props, ref) => {
  const {
    baseInfoState,
    setSelectedItemInfo,
    memberInputStateAll,
    memberInputState,
    rawMemberState,
    onChangeItemSelect,
    resetPaySubInfoListAction,
    onPtItemUpdate,
    selectorInfo,
    setSelectorInfo,
    onChangeMbshpOption,
  } = props;

  const storesDispatch = useDispatch();
  // 전역 상태 관리
  const { onChangeSubmitDocState, setCheckMbshpOption } = useRegisterStore();
  // 페이지 상태 관리
  const [membershipItemList, setMembershipItemList] = useState([]);
  const [itemNameList, setItemNameList] = useState(structToItemName(membershipItemList));
  const [isPending, startTransition] = useTransition();
  const [canSelectTimeOpt, setCanSelectTimeOpt] = useState(false);
  const [canSelectPassOpt, setCanSelectPassOpt] = useState(false);

  useImperativeHandle(ref, () => ({
    resetBranch: () => {
      return onBranchChange();
    },
    resetType: (curType) => {
      return onTypeChange(curType);
    },
    setItemIdx: (idx) => {
      return onItemChange(idx, true);
    },
  }));
  useEffect(() => {
    startTransition(() => {});
  }, [baseInfoState.selected_branch_id]);

  useEffect(() => {
    const curBranchId = baseInfoState.branch_setting.branch_info.branch_id;
    const passOptList = baseInfoState.erp_setting.mbshp_pass_branch_list;
    const timeOptList = baseInfoState.erp_setting.mbshp_time_branch_list;

    if (passOptList) {
      if (passOptList.findIndex((x) => x.branch_id === curBranchId) !== -1) {
        setCanSelectPassOpt(true);
      } else {
        setCanSelectPassOpt(false);
      }
    }
    if (timeOptList) {
      if (timeOptList.findIndex((x) => x.branch_id === curBranchId) !== -1) {
        setCanSelectTimeOpt(true);
      } else {
        setCanSelectTimeOpt(false);
      }
    }
  }, [baseInfoState]);

  const itemLockUnlock = (currentMembership, flag, isPageLoad) => {
    currentMembership.reg.is_lock = flag;
    currentMembership.reg.is_use = !flag;
    currentMembership.mbshp.is_lock = flag;
    currentMembership.mbshp.is_use = !flag;
    currentMembership.cloth.is_lock = flag;
    currentMembership.gx.is_lock = flag;
    currentMembership.locker.is_lock = flag;
    currentMembership.family_cloth.is_lock = flag;
    currentMembership.family_gx.is_lock = flag;
    currentMembership.family_locker.is_lock = flag;

    // 아이템 선택 시 금액 및 배열 초기화
    //
    if (!isPageLoad) {
      // MembershipInfo에 있는 코드와 거의 유사함. 코드 합치기 전까진 같이 업데이트 해야함
      currentMembership.reg.dis = 0;
      currentMembership.gx.dis = 0;
      currentMembership.mbshp.dis = 0;
      currentMembership.cloth.dis = 0;
      currentMembership.locker_list = [];
      currentMembership.family_locker_list = [];

      const getSubsOptions = (option) => {
        return {
          pre_dis: 0,
          pre_price: option.pre_price,
          prorate_dis: 0,
          prorate_price: option.prorate_price,
          subs_dis: 0,
          subs_price: option.subs_price,
        };
      };
      // currentMembership.reg.subs_options = getSubsOptions(currentMembership.reg.subs_options);
      currentMembership.gx.subs_options = getSubsOptions(currentMembership.gx.subs_options);
      currentMembership.mbshp.subs_options = getSubsOptions(currentMembership.mbshp.subs_options);
      currentMembership.cloth.subs_options = getSubsOptions(currentMembership.cloth.subs_options);

      // 가족것도 추가해야됨.
    }

    return currentMembership;
  };

  const switchItemData = (event, itemList) => {
    let targetList = [];

    if (event === "일반권") {
      memberInputState.membership_list.item_option_idx = 0;
      targetList = isEmptyItem(itemList.normal_items);
      setMembershipItemList([...targetList]);
      setCheckMbshpOption(event);
    } else if (event === "패스권") {
      memberInputState.membership_list.item_option_idx = 1;
      targetList = isEmptyItem(itemList.pass_items);
      setMembershipItemList([...targetList]);
    } else if (event === "시간권") {
      memberInputState.membership_list.item_option_idx = 2;
      targetList = isEmptyItem(itemList.time_items);
      setMembershipItemList([...targetList]);
    } else if (event === "일일권") {
      memberInputState.membership_list.item_option_idx = 3;
      targetList = isEmptyItem(itemList.daily_items);
      setMembershipItemList([...targetList]);
      setCheckMbshpOption(event);
    }
    return targetList;
  };

  const isEmptyItem = (listData) => {
    if (listData.length === 0) {
      return [noneSelectType.item_info];
    }

    return listData;
  };

  const onOptionReset = () => {
    startTransition(() => {
      memberInputState.membership_list.item_name = "선택해주세요";
      memberInputState.membership_list.item_name_idx = 0;

      setSelectorInfo({
        mbshp_type: memberInputState.membership_list.mbshp_type,
        item_type_idx: memberInputState.membership_list.item_type_idx,
        mbshp_option: memberInputState.membership_list.mbshp_option,
        item_option_idx: memberInputState.membership_list.item_option_idx,
        item_name: "선택해주세요",
        item_name_idx: 0,
      });
    });
  };

  //상품 초기화
  const onItemReset = (mbshp) => {
    setSelectorInfo({
      mbshp_type: memberInputState.membership_list.mbshp_type,
      item_type_idx: memberInputState.membership_list.item_type_idx,
      mbshp_option: memberInputState.membership_list.mbshp_option,
      item_option_idx: memberInputState.membership_list.item_option_idx,
      item_name: memberInputState.membership_list.item_name,
      item_name_idx: memberInputState.membership_list.item_name_idx,
      pass_cnt:
        memberInputState.membership_list.mbshp_option === "패스권" ? mbshp.pass_cnt : undefined,
      pass_from_time:
        memberInputState.membership_list.mbshp_option === "시간권" ? mbshp.pass_from_time : "",
      pass_end_time:
        memberInputState.membership_list.mbshp_option === "시간권" ? mbshp.pass_end_time : "",
    });
  };

  const onBranchChange = () => {
    // console.log(baseInfoState);
    let curTypeItemList = structToMembershipSettings("완납형", baseInfoState);
    let curItemDataList = switchItemData("일반권", curTypeItemList);

    setItemNameList(structToItemName(curItemDataList));

    memberInputState.membership_list = itemLockUnlock(rawMemberState.membership_list, true);
    memberInputState.purchase_item_list.item_info = noneSelectType.item_info;
    setSelectedItemInfo(noneSelectType);

    startTransition(() => {
      // console.log("onBranchChange");
      setItemNameList(structToItemName(curItemDataList));
      resetPaySubInfoListAction(memberInputState.tab_info.idx);
      onChangeItemSelect(0);
      onPtItemUpdate();
    });
  };

  const onTypeChange = (curType) => {
    let curTypeItemList = structToMembershipSettings(curType, baseInfoState);
    let curItemDataList = switchItemData("일반권", curTypeItemList);

    setItemNameList(structToItemName(curItemDataList));

    memberInputState.membership_list = itemLockUnlock(rawMemberState.membership_list, true);
    memberInputState.purchase_item_list.item_info = noneSelectType.item_info;
    setSelectedItemInfo(noneSelectType);

    startTransition(() => {
      // console.log("onTypeChange");
      setItemNameList(structToItemName(curItemDataList));
      resetPaySubInfoListAction(memberInputState.tab_info.idx);
      onChangeItemSelect(0);
      onPtItemUpdate();
    });
  };

  const onOptChange = (val) => {
    let curTypeItemList = structToMembershipSettings(selectorInfo.mbshp_type, baseInfoState);
    let curItemDataList = switchItemData(val, curTypeItemList);
    memberInputState.membership_list.item_option_idx = 0;
    memberInputState.membership_list.mbshp_option = val;

    memberInputState.membership_list = itemLockUnlock(rawMemberState.membership_list, true);
    memberInputState.purchase_item_list.item_info = noneSelectType.item_info;
    setSelectedItemInfo(noneSelectType);

    onOptionReset();

    startTransition(() => {
      // console.log("onOptChange");
      setItemNameList(structToItemName(curItemDataList));
      resetPaySubInfoListAction(memberInputState.tab_info.idx);
      onChangeItemSelect(0);
      onPtItemUpdate();
    });

    if (onChangeMbshpOption) {
      onChangeMbshpOption();
    }
  };

  // 여기서 아이템 설정. isPageLoad : 화면 초기 렌더링의 경우 true
  const onItemChange = (idx, isPageLoad) => {
    const curTypeItemList = structToMembershipSettings(selectorInfo.mbshp_type, baseInfoState);
    const curItemDataList = switchItemData(selectorInfo.mbshp_option, curTypeItemList);

    if (curItemDataList.length - 1 < idx) {
      return;
    }
    memberInputState.membership_list.item_name =
      curItemDataList.length > 0 && curItemDataList[idx].item_name;
    memberInputState.membership_list.item_name_idx = idx;

    // idx === 0 이면 상품명이 "선택해주세요"
    if (idx === 0) {
      memberInputState.membership_list = itemLockUnlock(
        rawMemberState.membership_list,
        true,
        isPageLoad,
      );
      memberInputState.purchase_item_list.item_info = noneSelectType.item_info;
      setSelectedItemInfo(noneSelectType);
      onChangeSubmitDocState("가입 신청서", false);
    } else {
      memberInputState.membership_list = itemLockUnlock(
        rawMemberState.membership_list,
        false,
        isPageLoad,
      );
      memberInputState.purchase_item_list.item_info = curItemDataList[idx];
      setSelectedItemInfo(curItemDataList[idx]);
      onChangeSubmitDocState("가입 신청서", true);
    }

    onChangeSubmitDocState("PT가입 신청서", false);
    onItemReset(curItemDataList[idx]);

    startTransition(() => {
      setItemNameList(structToItemName(curItemDataList));
      if (!isPageLoad) {
        // 여기부터는 아이템 선택했을 때만 초기화
        resetPaySubInfoListAction(memberInputState.tab_info.idx);
        onChangeItemSelect(idx);
        onPtItemUpdate();
      }
    });
  };

  const canSelectDailyOpt = () => {
    if (memberInputStateAll.membership_all_list.length > 1) {
      return false;
    }
    return true;
  };

  return (
    <div>
      <SelectOption
        selectorInfo={selectorInfo}
        onOptChange={onOptChange}
        memberInputState={memberInputState}
        canSelectDailyOpt={canSelectDailyOpt()}
        canSelectPassOpt={canSelectPassOpt}
        canSelectTimeOpt={canSelectTimeOpt}
      />
      <SelectItemCombo
        itemNameList={itemNameList}
        selectorInfo={selectorInfo}
        memberInputState={memberInputState}
        onItemChange={onItemChange}
      />
    </div>
  );
});
export default MembershipItemSet;
