import { assign_view_info } from "pages/ERP/MemberService/AssignChangeBranch/Assign/Module/reducers/view_reducer";
import { change_branch_view_info } from "pages/ERP/MemberService/AssignChangeBranch/ChangeBranch/Module/reducers/view_reducer";
import { change_item_view_info } from "pages/ERP/MemberService/ChangeItem/Module/reducers/view_reducer";
import { change_new_item_state } from "pages/ERP/MemberService/ChangeItem/Module/reducers/newItem_reducer";

import { change_paymethod_view_info } from "pages/ERP/MemberService/ChangePayMethod/Module/reducers/view_reducer";
import { postpone_view_info } from "pages/ERP/MemberService/Postpone/Module/reducers/view_reducer";
import { unpay_view_info } from "pages/ERP/MemberService/UnPay/Module/reducers/view_reducer";

import { refund_view_info } from "pages/ERP/MemberService/Refund/Module/reducers/view_reducer";

import { member_show_state } from "pages/ERP/MemberView/Module/reducers/show_reducer";
import { member_purchase_detail_state } from "pages/ERP/MemberView/Module/reducers/purchase_detail_reducer";

import {
  register_settings,
  register_view_state,
} from "pages/ERP/MemberRegister/Register/Module/reducers/register_reducer";

import { combineReducers } from "redux";

export const memberServiceReducer = combineReducers({
  unpay_view_info,
  register_settings,
  register_view_state,

  member_show_state,
  member_purchase_detail_state,

  assign_view_info,
  change_branch_view_info,

  change_paymethod_view_info,
  postpone_view_info,
  refund_view_info,
  change_item_view_info,
  change_new_item_state,
});
