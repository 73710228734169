import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";

const TableTitleWithButton = (props) => {
  const btnStyleClass = btnStyles();

  const titleStyle = {
    // width: "4.938rem",
    height: "100%",
    // margin: "0.063rem 57.063rem 0.625rem 0",
    fontFamily: "NotoSansCJKkr",
    fontSize: "1rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "2.5",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#222",
    alignItems: "center",
    justifyContent: "center",
    // lineHeight: "100%",
  };

  const btnStyle = {
    marginLeft: "auto",
    marginBottom: "0.5rem",
  };

  return (
    <div style={{ display: "flex", marginBottom: props.marginBottom }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText={props.titleLabel} />
      </div>

      <div style={btnStyle}>
        <NormalBtn
          theme={props.theme ? props.theme : "white"}
          width={`${props.btnWidth ? props.btnWidth : "4.25rem"}`}
          name={props.buttonText}
          onClick={() => {
            props.onClickEvent();
          }}
          disabled={props.disabled}
        />
      </div>
    </div>
  );
};
export default TableTitleWithButton;
