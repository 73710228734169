import { generateObjectZeroId } from "components/CommonLib/CommonLib";
import { create } from "zustand";

// 검색 조건 베이스
const baseSearchParams = {
  branch_info: {
    branch_id: generateObjectZeroId(),
    branch_name: "",
  },
  combo_item: {
    branch_info_list: [
      {
        branch_id: generateObjectZeroId(),
        branch_name: "",
      },
    ],
    branch_list: [""],
  },
  send_from_date: "",
  send_method: "",
  send_to_date: "",
  send_type: "",
  sms_type: "",
};

export const useSendMessageHistStore = create((set) => ({
  // ========== State ===========
  // 발송 이력 리스트 검색 State
  sendMessageHistSearchParams: {
    ...baseSearchParams,
  },
  sendMessageHistPage: 1,

  // ========== Action ==========
  // 발송 이력 리스트 검색 조건 변경
  setSearchParams: (key, value) => {
    set((state) => ({
      sendMessageHistSearchParams: {
        ...state.sendMessageHistSearchParams,
        [key]: value,
      },
    }));
  },

  // Page 값 변경
  setPage: (value) => {
    set({ sendMessageHistPage: value });
  },

  // 검색 조건 리셋
  resetSearchParams: () => {
    set({
      sendMessageHistSearchParams: {
        ...baseSearchParams,
      },
    });
  },
}));
