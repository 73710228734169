import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import PayHistoryTab from "pages/ERP/MemberView/Modal/ChangeRefundModal/PayHistoryTab";
import PayPlanState from "pages/ERP/MemberView/Modal/ChangeRefundModal/PayPlanState";
import { useDispatch } from "react-redux";

const SubsManageResultTable = ({
  searchResult,
  navigate,
  curPage,
  setCurPage,
  totalPage,
  onClickSearch,
}) => {
  const btnStyleClass = btnStyles();

  const storesDispatch = useDispatch();

  const onModalDone = () => {
    storesDispatch(globalModalOff());
  };

  const doSearch = (page) => {
    onClickSearch(false, page);
  };

  const popUpDetailModal = (data, isHist) => {
    const param = {
      // data,
      // mode: "detail",
      params: {
        member_id: data.membership_info.member_id,
        membership_id: data.membership_info.membership_id,
      },
    };
    if (isHist) {
      storesDispatch(
        globalModalOn({
          show: true,
          title: "결제 신청 이력",
          Content: (
            // <CardManageModalIndex modalSelect={select} modalParam={param} onModalDone={onModalDone} />
            // <SubsPayManageModal modalParam={param} onModalDone={onModalDone} />
            <PayHistoryTab modalParam={param} onModalDone={onModalDone} />
          ),
        }),
      );
    } else {
      storesDispatch(
        globalModalOn({
          show: true,
          title: "정기결제 현황",
          Content: (
            // <CardManageModalIndex modalSelect={select} modalParam={param} onModalDone={onModalDone} />
            // <SubsPayManageModal modalParam={param} onModalDone={onModalDone} />
            <PayPlanState modalParam={param} onModalDone={onModalDone} />
          ),
        }),
      );
    }
  };

  const searchResultList = new resultTable(navigate, popUpDetailModal);

  return (
    <div>
      <div
        style={{
          display: "flex",
          float: "right",
          marginLeft: "auto",
          marginBottom: "0.625rem",
        }}
      >
        <Button
          className={clsx({
            [btnStyleClass.btnRootWhite]: true,
            [btnStyleClass.buttonType6]: true,
          })}
          onClick={() => {}}
          style={{ marginLeft: "0.5rem" }}
        >
          목록 저장
        </Button>
      </div>

      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
        // width="160rem"
      />
      <CustomPagination
        curPage={curPage}
        // pageSelection={setCurPage}
        totalCnt={totalPage}
        doSearch={doSearch}
      />
    </div>
  );
};
export default SubsManageResultTable;

class resultTable {
  table_title = "";
  onNavigate = null;
  onDetailModal = null;

  constructor(navigate, onDetailModal) {
    this.onNavigate = navigate;
    this.onDetailModal = onDetailModal;
  }

  onClickRow = (data) => {
    // this.onNavigate("/staff_info/", {
    //   state: {
    //     staff_id: data.staff_id,
    //     is_view: true,
    //   },
    // });
  };

  onClickDetail = (data) => {};

  getContractDate = (data) => {
    if (data.contract_from_date) {
      return data.contract_from_date + " ~ " + data.contract_to_date;
    }
    return "";
  };

  create_table = (data, idx) => {
    // console.log(data);
    return (
      <CustomRow
        style={{}}
        key={idx}
        onClick={(e) => {
          if (e.target.nodeName === "DIV" || e.target.nodeName === "TD") {
            this.onClickRow(idx);
          }
        }}
        hover
      >
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.membership_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.membership_info.member_name}</CustomTableContents>
        <CustomTableContents>{data.membership_info.phone}</CustomTableContents>
        <CustomTableContents>{""}</CustomTableContents>
        <CustomTableContents>{""}</CustomTableContents>
        {/* <CustomTableContents>{this.getContractDate(data)}</CustomTableContents> */}
        {/* <CustomTableContents>{data.next_pay_date}</CustomTableContents> */}
        <CustomTableContents>{0}</CustomTableContents>
        <CustomTableContents>{data.subs_paid_num}</CustomTableContents>

        <CustomTableContents>{data.pay_try_date}</CustomTableContents>
        <CustomTableContents>{""}</CustomTableContents>
        <CustomTableContents>{data.remark}</CustomTableContents>
        <CustomTableContents>
          <IconButton type="detail" onClick={() => this.onDetailModal(data, true)} />
        </CustomTableContents>
        <CustomTableContentsEND>
          <IconButton type="detail" onClick={() => this.onDetailModal(data, false)} />
        </CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "지점", width: "5%" },
    { title: "회원번호", width: "8%" },
    { title: "이름", width: "5%" },
    { title: "연락처", width: "9%" },
    { title: "정기결제 상태", width: "4%" },
    { title: "결제처", width: "5%" },

    // { title: "약정 기간", width: "9%" },
    { title: "정기결제 금액", width: "9%" },
    { title: "최근 결제 회차", width: "4%" },
    { title: "최근 결제 신청일시", width: "9%" },

    { title: "최근 결제 상태", width: "9%" },
    { title: "비고", width: "9%" },
    { title: "결제이력", width: "4%" },
    { title: "결제현황", width: "4%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
