// 초기화
const initialState = {
  stores: null,
};

// 리듀서 작성
// function global_loading(state = initialStete, action) {
export const test_state = (state = initialState, action) => {
  switch (action.type) {
    case "loading/TEST/OK": {
      return { ...state, stores: action.payload };
    }
    case "loading/TEST/FAIL":
      return { stores: null };
    default:
      return state;
  }
};
