import ReferenceView from "./ReferenceView";
const ReferenceWrapper = ({ nowService }) => {
  //본부콤보 GET
  const getHqCombo = async () => {};

  return (
    <div>
      <ReferenceView nowService={nowService} />
    </div>
  );
};

export default ReferenceWrapper;
