// 회원 검색
export const searchMemberKeys = {
  all: ["searchMember"],

  // ---- 회원 조회 리스트 ----
  // [GET] 회원 조회 베이스 검색 조건 받아오기
  baseSearchParam: () => [...searchMemberKeys.all, "baseSearchParam"],
  // [GET] 회원 조회 검색 조건 받아오기
  memberSearchParam: () => [...searchMemberKeys.all, "memberSearchParam"],
  // [GET] 회원 조회 검색 조건 받아오기
  mbshpSearchParam: () => [...searchMemberKeys.all, "mbshpSearchParam"],
  // [GET] 회원 조회 검색 조건 받아오기
  sideSearchParam: () => [...searchMemberKeys.all, "sideSearchParam"],
  // [GET] 회원 조회 검색 조건 받아오기
  ptSearchParam: () => [...searchMemberKeys.all, "ptSearchParam"],
};
