// import * as React from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { CustomRow } from "components/CustomTable/tableStyle";
import React, { forwardRef, useImperativeHandle } from "react";

// export default function EdiTable(props) {
const EditTable = forwardRef((props, ref) => {
  const [selected, setSelected] = React.useState([]);
  const [selectedIdx, setSelectedIdx] = React.useState([]);

  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const rows = props.rest_call(props.rest_data);

  useImperativeHandle(ref, () => ({
    getSelectedStat: () => {
      setSelected([]);
      setSelectedIdx([]);
      return selectedIdx;
    },
  }));

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = rows.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const handleClick = (event, name, idx) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    let newSelectedIdx = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
      newSelectedIdx = newSelectedIdx.concat(selectedIdx, idx);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedIdx = newSelectedIdx.concat(selectedIdx.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedIdx = newSelectedIdx.concat(selectedIdx.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );

      newSelectedIdx = newSelectedIdx.concat(
        selectedIdx.slice(0, selectedIndex),
        selectedIdx.slice(selectedIndex + 1),
      );
    }
    // console.log(selectedIndex);
    // console.log(idx);

    setSelectedIdx(newSelectedIdx);
    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const maxHeight = "20rem";

  return (
    <Box
      sx={{ width: "100%", minHeight: maxHeight, maxHeight: maxHeight, border: "1px solid #ccc" }}
    >
      <Paper sx={{ width: "100%", mb: 2 }}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer sx={{ maxHeight: maxHeight }}>
          <Table stickyHeader aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            <TableHead>
              <CustomRow>
                <TableCell
                  colSpan={2}
                  style={{
                    // border: "1px solid #b9b9b9",
                    backgroundColor: "#dfebf3",
                    fontWeight: "bold",
                    textAlign: "center",
                    padding: "0.25rem",
                  }}
                >
                  {props.headTitle}
                </TableCell>
              </CustomRow>
            </TableHead>
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {rows
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.branch_name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <CustomRow
                      hover
                      onClick={(event) => handleClick(event, row.branch_name, index)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.branch_name}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox" style={{ padding: "0px", width: "0px" }}>
                        <Checkbox
                          style={{
                            maxWidth: 0, // percentage also works
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      {/* <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.name}
                      
                      </TableCell> */}
                      <TableCell align="center">{row.branch_name}</TableCell>
                      {/* <TableCell align="center">{row.calories}</TableCell>
                      <TableCell align="center">{row.fat}</TableCell> */}

                      {/* <TableCell align="center">
                        <IconButton color="primary" aria-label="upload picture" component="span">
                          <DeleteOutlineIcon />
                        </IconButton>
                      </TableCell> */}
                    </CustomRow>
                  );
                })}
              {emptyRows > 0 && (
                <CustomRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </CustomRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
});
export default EditTable;
