import * as ActionTypes from "./action_type";

///PERMISSION
export const loadPermission = (package_id) => {
  return {
    type: ActionTypes.getPermissionAction,
    package_id: package_id,
    // branch_name: branch_name,
  };
};

export const loadPermissionSuccess = (data) => {
  return {
    type: ActionTypes.getPermissionSuccessAction,
    payload: data,
  };
};

export const loadPermissionFail = (data) => {
  return {
    type: ActionTypes.getPermissionFailAction,
    payload: [],
  };
};

export const loadPermissionReset = (data) => {
  return {
    type: ActionTypes.getPermissionResetAction,
    payload: data,
  };
};

///PERMISSION
export const loadHqViewList = () => {
  return {
    type: ActionTypes.getHqViewAction,
    // branch_id: branch_id,
    // branch_name: branch_name,
  };
};

export const loadHqViewListSuccess = (data) => {
  return {
    type: ActionTypes.getHqViewSuccessAction,
    payload: data,
  };
};

export const loadHqViewListFail = (data) => {
  return {
    type: ActionTypes.getHqViewFailAction,
    payload: [],
  };
};

export const loadHqViewListReset = (data) => {
  return {
    type: ActionTypes.getHqViewResetAction,
    payload: data,
  };
};

///PERMISSION
export const loadIpAllowList = () => {
  return {
    type: ActionTypes.getIpAllowViewAction,
    // branch_id: branch_id,
    // branch_name: branch_name,
  };
};

export const loadIpAllowListSuccess = (data) => {
  return {
    type: ActionTypes.getIpAllowViewSuccessAction,
    payload: data,
  };
};

export const loadIpAllowListFail = (data) => {
  return {
    type: ActionTypes.getIpAllowViewFailAction,
    payload: [],
  };
};

export const loadIpAllowListReset = (data) => {
  return {
    type: ActionTypes.getIpAllowViewResetAction,
    payload: data,
  };
};

///POLICY
export const loadPolicyView = () => {
  return {
    type: ActionTypes.getPolicyViewAction,
    // branch_id: branch_id,
    // branch_name: branch_name,
  };
};

export const loadPolicyViewSuccess = (data) => {
  return {
    type: ActionTypes.getPolicyViewSuccessAction,
    payload: data,
  };
};

export const loadPolicyViewFail = (data) => {
  return {
    type: ActionTypes.getPolicyViewFailAction,
    payload: [],
  };
};

export const loadPolicyViewListReset = (data) => {
  return {
    type: ActionTypes.getPolicyViewResetAction,
    payload: data,
  };
};

export const erpSettingAction = {
  loadPermissionSuccess,
  loadPermissionFail,
  loadPermissionReset,
  loadHqViewListSuccess,
  loadHqViewListFail,
  loadHqViewListReset,
  loadIpAllowListSuccess,
  loadIpAllowListFail,
  loadIpAllowListReset,
  loadPolicyViewSuccess,
  loadPolicyViewFail,
  loadPolicyViewListReset,
};
