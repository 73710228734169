import PageFrame from "components/Panels/PageFrame";
import { useState } from "react";
import { useLocation } from "react-router-dom";

// useNavigate를 통해 html 본문을 받으면 그대로 화면에 출력하는 페이지
const RenderHTML = () => {
  const location = useLocation();
  const [htmlContent, setHtmlContent] = useState(location.state?.html);

  return (
    <PageFrame noPadding>
      <iframe
        style={{ padding: "0px", width: "100%", height: "94vh", border: "none" }}
        title="Server Rendered HTML"
        srcDoc={htmlContent}
        sandbox="allow-scripts"
      />
    </PageFrame>
  );
};

export default RenderHTML;
