import { globalFinishLoading, globalStartLoading } from "modules/actions/Loading/index";
import { call, put, takeLatest } from "redux-saga/effects";
import * as ActionTypes from "../actions/action_type";
import { branchSettingAction } from "../actions/index";
import get_api from "../api/GetApi";

export function* getBranchBaseData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getBaseInfoApi, param);

    if (data.data === null) {
      yield put(branchSettingAction.loadBranchBaseSuccess(false));
    } else {
      yield put(branchSettingAction.loadBranchBaseSuccess(data));
    }
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(branchSettingAction.loadBranchBaseFail(error));
  }
  yield put(globalFinishLoading());
}

export function* getPolicyViewData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getPolicyViewApi, param);

    if (data.data === null) {
      yield put(branchSettingAction.loadPolicyViewSuccess(false));
    } else {
      yield put(branchSettingAction.loadPolicyViewSuccess(data));
    }
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(branchSettingAction.loadPolicyViewFail(error));
  }
  yield put(globalFinishLoading());
}

export function* getItemsViewData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getItemsViewApi, param);

    if (data.data === null) {
      yield put(branchSettingAction.loadItemsViewSuccess(false));
    } else {
      yield put(branchSettingAction.loadItemsViewSuccess(data));
    }
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(branchSettingAction.loadItemsViewFail(error));
  }
  yield put(globalFinishLoading());
}

export function* getPrePaidInfoData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getPrePaidInfoApi, param);

    if (data.data === null) {
      yield put(branchSettingAction.loadPrePaidItemsSuccess(false));
    } else {
      yield put(branchSettingAction.loadPrePaidItemsSuccess(data));
    }
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(branchSettingAction.loadPrePaidItemsFail(error));
  }
  yield put(globalFinishLoading());
}

export function* getSubPaidInfoData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getSubPaidInfoApi, param);

    if (data.data === null) {
      yield put(branchSettingAction.loadSubScriptionItemsSuccess(false));
    } else {
      yield put(branchSettingAction.loadSubScriptionItemsSuccess(data));
    }
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(branchSettingAction.loadSubScriptionItemsFail(error));
  }
  yield put(globalFinishLoading());
}

export function* getETCItemsInfoData(param) {
  try {
    yield put(globalStartLoading());
    const { data } = yield call(get_api.getETCItemsInfoApi, param);

    if (data.data === null) {
      yield put(branchSettingAction.loadETCItemsSuccess(false));
    } else {
      yield put(branchSettingAction.loadETCItemsSuccess(data));
    }
  } catch (error) {
    console.log("from saga  -> ");
    console.log(error);
    yield put(branchSettingAction.loadETCViewFail(error));
  }
  yield put(globalFinishLoading());
}

export function* branchSettingSaga() {
  yield takeLatest(ActionTypes.getBranchBaseAction, getBranchBaseData);
  yield takeLatest(ActionTypes.getPolicyManageViewAction, getPolicyViewData);
  yield takeLatest(ActionTypes.getBranchItemsViewAction, getItemsViewData);
  yield takeLatest(ActionTypes.getPrePaidInfoAction, getPrePaidInfoData);
  yield takeLatest(ActionTypes.getSubPaidInfoAction, getSubPaidInfoData);
  yield takeLatest(ActionTypes.getEtcInfoAction, getETCItemsInfoData);
}
