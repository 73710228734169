import { simpleAlert } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";

export const updateContractAPi = async (
  navigate,
  selectedBranchInfo,
  storesDispatch,
  contractId,
  stateData,
) => {
  try {
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    if (contractId === "") {
      return;
    }

    const res = await HttpInstance.post(
      process.env.REACT_APP_BRANCH_SERVER + "/branch/contract/" + contractId + "/add",
      stateData,
      config,
    );
    if (res.data.msg) {
      simpleAlert(storesDispatch, "ERROR", res.data.msg);
    } else {
      //성공
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          reqConfirm: true,
          bodyText: (
            <>
              <Label labelText="저장되었습니다." />
            </>
          ),
          onBtnOk: () => {
            navigate("/tablet_setting", { state: selectedBranchInfo });
          },
        }),
      );
    }
  } catch (err) {
    console.log(err);
    simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
  }
};
