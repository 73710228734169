import { useEffect, useState } from "react";

import SearchMember from "pages/CommonModal/Search/SearchMember";
import UnPaidHistModal from "pages/ERP/MemberService/UnPay/UnPaidHistModal";
import UnPaidProcessModal from "pages/ERP/MemberService/UnPay/UnPaidProcessModal";
import ChangeFromDateModal from "./ChangeFromDateModal";
import ContractDocumentModal from "./ContractDocumentModal";
import CouponPointModal from "./CouponPointModal/CouponPointModal";
import FamilyListModal from "./FamilyListModal";
import LinkBranchHistModal from "./LinkBranchHistModal";
import PurchaseDetailModal from "./PurchaseDetail/PurchaseDetailModal";

///결제/환불
import SubsPayCancelModal from "./ChangeRefundModal/SubsPayCancelModal";
import SubsPayManageModal from "./ChangeRefundModal/SubsPayManageModal";

import PostponeService from "pages/ERP/MemberService/Postpone/PostponeService";

import * as lodash from "lodash";
import LockerDetailModal from "pages/ERP/LockerService/Modal/LockerDetailModal";
import LockerSelectModal from "pages/ERP/LockerService/Modal/LockerSelectModal";
import ChangeRefundModal from "./ChangeRefundModal";
import ExtendMembershipModal from "./ChangeRefundModal/ExtendMembershipModal";
import ConverToMemberModal from "./ConverToMemberModal";
import FamilyConnectModal from "./FamilyConnectModal";
import LockerSelectOptionModal from "./LockerSelectOptionModal";
import MasterChangeView from "./MasterChangeModal/MasterChangeView";

const MemberViewModalIndex = ({ modalSelect, modalParam, onModalDone }) => {
  const [paramData, setParamData] = useState(modalParam.modalParam);

  useEffect(() => {
    const editData = lodash.cloneDeep(modalParam.modalParam);
    setParamData(editData);
    return () => {};
  }, []);

  if (modalSelect === "회원전환") {
    return <ConverToMemberModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "연기") {
    return <PostponeService modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "시작일변경") {
    return <ChangeFromDateModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "락커배정") {
    return <LockerSelectModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "락커상세") {
    return <LockerDetailModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "락커옵션선택") {
    return <LockerSelectOptionModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "미납금이력") {
    return <UnPaidHistModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "구매상세내역") {
    return <PurchaseDetailModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "미납금처리") {
    return <UnPaidProcessModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "당월타지점출입이력") {
    return <LinkBranchHistModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "가족연결") {
    return <FamilyConnectModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "가족목록") {
    return <FamilyListModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "회원검색") {
    return <SearchMember modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "변경/환불") {
    return <ChangeRefundModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "쿠폰포인트") {
    return <CouponPointModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "회원권기간추가") {
    return <ExtendMembershipModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "계약서관리") {
    return <ContractDocumentModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "정기결제수단관리") {
    return <SubsPayManageModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "정기결제해지예약") {
    return <SubsPayCancelModal modalParam={paramData} onModalDone={onModalDone} />;
  } else if (modalSelect === "수강정보변경") {
    return <MasterChangeView modalParam={paramData} onModalDone={onModalDone} />;
  } else {
    return <div>current ELSE</div>;
  }
};
export default MemberViewModalIndex;
