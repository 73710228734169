import {
  locker_allocate_show,
  locker_detail_show,
  locker_setting,
  locker_setting_show,
} from "./locker_setting_reducer";

import { combineReducers } from "redux";

export const lockerSettingReducer = combineReducers({
  locker_setting,
  locker_setting_show,
  locker_allocate_show,
  locker_detail_show,
});
