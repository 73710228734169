import failIcon from "assets/icon/NO.svg";
import successIcon from "assets/icon/success.svg";
import IconButton from "components/Button/IconButton";
import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import { uploadFile } from "components/File/uploadFile";
import FileUploadIcon from "components/LabelInput/FileUploadIcon";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const PaymethodDetailTable = ({ memberId, pCode }) => {
  const storesDispatch = useDispatch();
  const [curPage, setCurPage] = useState();
  const [totalPage, setTotalPage] = useState(0);
  const [paymentList, setPaymentList] = useState([]);
  const [loading, setLoading] = useState(false);

  //결제수단 GET
  const getPurchaseMethodDetail = async (page) => {
    setCurPage(page);
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_USER_SERVER + "/purchase/detail/payment",
        {
          params: {
            member_id: memberId,
            purchase_code: pCode,
            page_num: curPage,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setPaymentList(res.data.data);
        setTotalPage(res.data.total_page);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  useEffect(() => {
    getPurchaseMethodDetail(1);
  }, []);

  //파일 다운로드 POST
  const downloadFile = async (data, payAct) => {
    try {
      setLoading(true);

      const url = "/member/document/" + data.payment_id + "/payment/receipt"; // /" + data.approve_no;

      const res = await HttpInstance.get(process.env.REACT_APP_USER_SERVER + url, {
        params: {
          pay_act: payAct,
        },
      });
      if (res.data) {
        const downloadLink = res.data.data;
        const a = document.createElement("a");
        a.href = downloadLink;
        a.download = data.file_name;
        document.body.appendChild(a);
        a.click();
        setTimeout((_) => {
          window.URL.revokeObjectURL(downloadLink);
        }, 60000);
        a.remove();

        // simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const onUploadDone = (result) => {
    if (result.error) {
      simpleAlert(storesDispatch, "ERROR", "파일 업로드 실패");
    } else {
      simpleAlert(storesDispatch, "알림", "파일 업로드 완료");
      getPurchaseMethodDetail(curPage);
    }

    setLoading(false);
  };

  // 파일 업로드
  const onUploadFile = (data, file) => {
    const fileInfo = {
      file_info: {
        doc_name: data.doc_name, // 결제 영수증 or 취소 영수증
        reason: data.reason, // 결제 or 취소
        belong_type: "member_payment_receipt",
      },
      is_full_doc: true,
      payment_id: data.payment_id,
      purchase_code: pCode,
    };

    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <Label labelText={`[${fileInfo.file_info.doc_name}] 을(를) 업로드 하시겠습니까?`} />
        ),
        onBtnYes: () => {
          setLoading(true);
          uploadFile(fileInfo, file, onUploadDone);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const paymentListItem = new paymentListTable(downloadFile, onUploadFile);

  return (
    <div style={{ marginTop: "1rem" }}>
      {loading ? <Loading /> : <></>}

      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="결제 수단 목록" />
      </div>

      <CustomTable
        columns_head={paymentListItem.columns_head}
        table_title={paymentListItem.table_title}
        rest_call={paymentListItem.get_data_from_rest}
        row_render={paymentListItem.create_table}
        rest_data={paymentList}
      />
      <CustomPagination curPage={curPage} doSearch={getPurchaseMethodDetail} totalCnt={totalPage} />
    </div>
  );
};

export default PaymethodDetailTable;

class paymentListTable {
  table_title = "";
  downloadFile = null;
  onUploadFile = null;

  constructor(downloadFile, onUploadFile) {
    this.downloadFile = downloadFile;
    this.onUploadFile = onUploadFile;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.pay_date}</CustomTableContents>
        <CustomTableContents>{data.pay_method}</CustomTableContents>
        <CustomTableContents>{data.approve_no}</CustomTableContents>
        <CustomTableContents>{data.cancel_date}</CustomTableContents>
        <CustomTableContents>{data.cancel_approve_no}</CustomTableContents>
        <CustomTableContents>
          <div style={{ fontWeight: data.paid_by === "tablet" ? "bold" : "" }}>
            {data.price.toLocaleString() + "원"}
          </div>
        </CustomTableContents>
        <CustomTableContents>
          {data.is_valid ? (
            <img
              alt="O"
              src={successIcon}
              style={{
                width: "1.3rem",
                height: "1.3rem",
                verticalAlign: "bottom",
              }}
            />
          ) : (
            <img
              alt="X"
              src={failIcon}
              style={{
                width: "1.3rem",
                height: "1.3rem",
                verticalAlign: "bottom",
              }}
            />
          )}
        </CustomTableContents>
        <CustomTableContents>{data.remarks}</CustomTableContents>
        <CustomTableContents>
          {data.is_uploaded_paid_recipt ? (
            <IconButton
              type="download_small"
              onClick={() => {
                this.downloadFile(data, "결제");
              }}
            />
          ) : (
            <FileUploadIcon
              idx={idx}
              onlyIcon
              small
              onUploadFile={(file) => {
                this.onUploadFile(
                  {
                    payment_id: data.payment_id,
                    doc_name: "결제 영수증",
                    reason: "결제",
                  },
                  file,
                );
              }}
              hideDownload
            />
          )}
        </CustomTableContents>
        <CustomTableContentsEND align="center">
          {data.is_uploaded_cancel_recipt ? (
            <IconButton
              type="download_small"
              onClick={() => {
                this.downloadFile(data, "취소");
              }}
            />
          ) : (
            !data.is_valid && (
              <FileUploadIcon
                idx={idx}
                onlyIcon
                small
                onUploadFile={(file) => {
                  this.onUploadFile(
                    {
                      payment_id: data.payment_id,
                      doc_name: "취소 영수증",
                      reason: "취소",
                    },
                    file,
                  );
                }}
                hideDownload
              />
            )
          )}
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "승인(결제)일시", width: "13%" },
    { title: "결제수단", width: "10%" },
    { title: "승인번호", width: "11%" },
    { title: "취소일시", width: "13%" },
    { title: "취소승인번호", width: "11%" },
    { title: "결제 금액", width: "10%", tooltip: "Tablet에서 결제한 경우 Bold체로 표시됩니다" },
    { title: "유효결제\n여부", width: "6%" },
    { title: "비고", width: "11%" },
    { title: "결제\n영수증", width: "6%" },
    { title: "취소\n영수증", width: "6%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
