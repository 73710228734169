import { create } from "zustand";

export const useRegisterStore = create((set, get) => ({
  // ========== State ===========
  // 제출 서류 보이는 조건 State
  showSubmitDocList: {
    "가입 신청서": false,
    "PT가입 신청서": false,
    "결제 영수증": false,
    "서비스 신청서": false,
  },
  // 서비스 신청 개수 state
  checkServiceCount: 0,

  // 결제 영수증 첨부한 파일 이름
  submitPayDocFileName: "",

  // 회원권 일반권, 일일권 옵션 체크
  checkMbshpOption: "",

  // ========== Action ==========
  // 제출 서류 조건 변경
  onChangeSubmitDocState: (docName, docState) => {
    const serviceDocCount = get().checkServiceCount;

    switch (docName) {
      case "서비스 신청서":
        // 서비스 신청 +1
        if (docState) {
          set((state) => ({
            checkServiceCount: serviceDocCount + 1,
            showSubmitDocList: { ...state.showSubmitDocList, [docName]: true },
          }));
          // 서비스 신청 -1 && 서비스 신청한 상품들이 0일때
        } else {
          if (serviceDocCount - 1 <= 0) {
            set((state) => ({
              checkServiceCount: 0,
              showSubmitDocList: { ...state.showSubmitDocList, [docName]: false },
            }));
            // 서비스 신청 -1 && 서비스 신청한 상품들이 남았을때
          } else if (serviceDocCount - 1 > 0) {
            set({ checkServiceCount: serviceDocCount - 1 });
          }
        }
        break;
      // 서비스 신청이 아닌 경우 (가입, PT)
      default:
        set((state) => ({
          showSubmitDocList: { ...state.showSubmitDocList, [docName]: docState },
        }));
        break;
    }
  },
  // 회원권 옵션 체크 변경
  setCheckMbshpOption: (option) => {
    set({ checkMbshpOption: option });
  },

  // 제출 서류 조건 reset
  resetSubmitDocState: () =>
    set({
      showSubmitDocList: {
        "가입 신청서": false,
        "PT가입 신청서": false,
        "결제 영수증": false,
        "서비스 신청서": false,
      },
      checkServiceCount: 0,
    }),

  // 결제 영수증 파일 이름 변경
  setPayDocFileName: (fileName) => set({ submitPayDocFileName: fileName }),
}));
