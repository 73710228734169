import { generateObjectZeroId } from "components/CommonLib/CommonLib";

export const staffInitState = {
  contents: {
    auth_id: "",
    auth_pass: "",
    card_info: {
      access_method: "",
      card_info_id: generateObjectZeroId(),
      card_no: "",
      branch_info: {
        branch_id: generateObjectZeroId(),
        branch_name: "",
      },
    },
    staff_obj: {
      accessible: [],
      affiliation: [],
      award: [],
      can_login: "",
      career: [],
      education: [],
      family: [],
      files: {
        account_copy: "",
        id_card: "",
        is_submit_account_copy: "",
        is_submit_id_card: "",
      },
      join_status: "",
      military: {
        category: "",
        exception_reason: "",
        idx: 0,
        is_serviced: false,
        rank: "",
        service_end_date: "",
        service_from_date: "",
        specialty: "",
      },
      personal_info: {
        account_holder: "",
        account_no: "",
        address_1: "",
        address_2: "",
        bank: "",
        birthday: "",
        email: "",
        gender: "",
        phone: "",
        sabun: "",
        social_number: "",
        staff_name: "",
        verify: false,
        zip_code: "",
      },
      prev_db_oid: "",
      qualifications: [],
      reg_info: {
        created_at: "",
        reg_id: "",
        reg_name: "",
        upd_id: "",
        upd_name: "",
        updated_at: "",
      },
      staff_id: "",
      temp_branch_name: "",
    },
  },
  permission: {
    login_reject: {
      key: "",
      value: false,
    },
    stff_info_edit: {
      key: "",
      value: true,
    },
    stff_info_encryption: {
      key: "",
      value: false,
    },
    work_info_tab_add: {
      key: "",
      value: false,
    },
    work_info_tab_show: {
      key: "",
      value: false,
    },
  },
  selector: {
    branch_list: [],
    hq_list: [],
    target_list: [],
  },
};
