import ComboBox from "components/LabelInput/ComboBox";
import NormalBtn from "components/NewButton/NormalBtn";

const SelectStaffModal = ({ modalParam, onModalDone }) => {
  const staffNameList = modalParam.data.staffList.map((el) => {
    return el.personal_info.staff_name + " (사번: " + el.personal_info.sabun + ")";
  });

  return (
    <div>
      <ComboBox
        labelWidth={"3rem"}
        labelText="담당자"
        comboItemWidth={"15rem"}
        data={staffNameList}
        onChangeCallback={(e) => {
          const targetName = e.target.value.split(" ")[0];

          const selected = modalParam.data.staffList.find(
            (el) => el.personal_info.staff_name === targetName,
          );
          modalParam.data.setChangeInfo((cur) => {
            return { ...cur, staff: selected };
          });
        }}
      />

      <div style={{ marginTop: "1.5rem", textAlign: "center" }}>
        <NormalBtn
          name="선택"
          onClick={() => {
            onModalDone();
          }}
        />
      </div>
    </div>
  );
};
export default SelectStaffModal;
