import { Tooltip } from "@mui/material";
import IconButtonComp from "@mui/material/IconButton";
import { ReactComponent as ApprovalDoneIcon } from "assets/icon/approval_done.svg";
import { ReactComponent as ApprovalRejectIcon } from "assets/icon/approval_reject.svg";
import { ReactComponent as ApprovalRetrieveIcon } from "assets/icon/approval_retrieve.svg";
import { ReactComponent as ApprovalWaitIcon } from "assets/icon/approval_wait.svg";
import { ReactComponent as ArrowDownSmallIcon } from "assets/icon/arrowDownSmall.svg";
import { ReactComponent as AttendanceIcon } from "assets/icon/Attendance.svg";
import { ReactComponent as BorderIcon } from "assets/icon/Border.svg";
import { ReactComponent as CancelIcon } from "assets/icon/cancel.svg";
import { ReactComponent as ChangeIcon } from "assets/icon/Change.svg";
import { ReactComponent as CommissionIcon } from "assets/icon/Commission.svg";
import { ReactComponent as ConnectIcon } from "assets/icon/Connect.svg";
import { ReactComponent as DeleteIcon } from "assets/icon/Delete.svg";
import { ReactComponent as DeleteNoBorderIcon } from "assets/icon/DeleteNoBorder.svg";
import { ReactComponent as DetailIcon } from "assets/icon/Detail.svg";
import { ReactComponent as DocFormIcon } from "assets/icon/DocForm.svg";
import { ReactComponent as DownloadIcon } from "assets/icon/Download.svg";
import { ReactComponent as DownloadIconSmall } from "assets/icon/Download_small.svg";
import { ReactComponent as EditIcon } from "assets/icon/Edit.svg";
import { ReactComponent as EditBoderIcon } from "assets/icon/EditWithBorder.svg";
import { ReactComponent as GXIcon } from "assets/icon/GX.svg";
import { ReactComponent as GxOffIcon } from "assets/icon/gx_toggle_off.svg";
import { ReactComponent as GxOnIcon } from "assets/icon/gx_toggle_on.svg";
import { ReactComponent as LockerIcon } from "assets/icon/Locker.svg";
import { ReactComponent as LockerLargeIcon } from "assets/icon/LockerLarge.svg";
import { ReactComponent as LogoutIcon } from "assets/icon/Logout.svg";
import { ReactComponent as MinusIcon } from "assets/icon/Minus.svg";
import { ReactComponent as NextIcon } from "assets/icon/next.svg";
import { ReactComponent as OTIcon } from "assets/icon/OT.svg";
import { ReactComponent as PlusIcon } from "assets/icon/Plus.svg";
import { ReactComponent as PreIcon } from "assets/icon/pre.svg";
import { ReactComponent as PrintIcon } from "assets/icon/Print.svg";
import { ReactComponent as ProfileIcon } from "assets/icon/Profile.svg";
import { ReactComponent as ReceiptIcon } from "assets/icon/Receipt.svg";
import { ReactComponent as RegisterDoneIcon } from "assets/icon/registerDone.svg";
import { ReactComponent as SaveBorderIcon } from "assets/icon/saveBorder.svg";
import { ReactComponent as SearchIcon } from "assets/icon/Search.svg";
import { ReactComponent as SearchBlueIcon } from "assets/icon/Search_Blue.svg";
import { ReactComponent as SearchIconDisabled } from "assets/icon/Search_disabled.svg";
import { ReactComponent as SessionIcon } from "assets/icon/Session.svg";
import { ReactComponent as SettingIcon } from "assets/icon/setting.svg";
import { ReactComponent as TmPhone } from "assets/icon/tm_phone.svg";
import { ReactComponent as TodayIcon } from "assets/icon/Today.svg";
import { ReactComponent as ToolTipIcon } from "assets/icon/tooltip.svg";
import { ReactComponent as TrainingClothesIcon } from "assets/icon/TrainingClothes.svg";
import { ReactComponent as UpdateIcon } from "assets/icon/Update.svg";
import { ReactComponent as UploadIcon } from "assets/icon/Upload.svg";
import { ReactComponent as UploadSmallIcon } from "assets/icon/upload_small.svg";

const IconButton = (props) => {
  // 각 icon에 width, height값 부여할 수 있음
  const Icon = () => {
    switch (props.type) {
      case "search":
        return !props.disabled ? <SearchIcon /> : <SearchIconDisabled />;
      case "search_blue":
        return !props.disabled ? <SearchBlueIcon /> : <SearchIconDisabled />;
      case "logout":
        return <LogoutIcon />;
      case "setting":
        return <SettingIcon />;
      case "arrow_down_small":
        return <ArrowDownSmallIcon />;
      case "register_done":
        return <RegisterDoneIcon />;
      case "delete":
        return <DeleteNoBorderIcon />;
      case "deleteBox":
        return <DeleteIcon />;
      case "cancel":
        return <CancelIcon />;
      case "locker":
        return <LockerIcon />;
      case "change":
        return <ChangeIcon />;
      case "minus":
        return <MinusIcon />;
      case "plus":
        return <PlusIcon />;
      case "border":
        return <BorderIcon />;
      case "lockerLage":
        return <LockerLargeIcon />;
      case "trainingClothes":
        return <TrainingClothesIcon />;
      case "gx":
        return <GXIcon />;
      case "ot":
        return <OTIcon />;
      case "receipt":
        return <ReceiptIcon />;
      case "detail":
        return <DetailIcon />;
      case "profile":
        return <ProfileIcon />;
      case "connect":
        return <ConnectIcon />;
      case "print":
        return <PrintIcon />;
      case "download":
        return <DownloadIcon />;
      case "download_small":
        return <DownloadIconSmall />;
      case "editBorder":
        return <EditBoderIcon />;
      case "edit":
        return <EditIcon />;
      case "save_border":
        return <SaveBorderIcon />;
      case "upload":
        return <UploadIcon />;
      case "upload_small":
        return <UploadSmallIcon />;
      case "attendance":
        return <AttendanceIcon />;
      case "session":
        return <SessionIcon />;
      case "commission":
        return <CommissionIcon />;
      case "preBtn":
        return <PreIcon />;
      case "nextBtn":
        return <NextIcon />;
      case "gx_toggle_on":
        return <GxOnIcon />;
      case "gx_toggle_off":
        return <GxOffIcon />;
      case "today":
        return <TodayIcon />;
      case "approval_done":
        return <ApprovalDoneIcon />;
      case "approval_reject":
        return <ApprovalRejectIcon />;
      case "approval_wait":
        return <ApprovalWaitIcon />;
      case "approval_retrieve":
        return <ApprovalRetrieveIcon />;
      case "doc_form":
        return <DocFormIcon />;
      case "update":
        return <UpdateIcon />;
      case "tm_phone":
        return <TmPhone />;
      case "tooltip":
        return <ToolTipIcon />;
      default:
        return "";
    }
  };

  const buttonStyle = {
    padding: 0,
    width: props.width,
    height: props.height,
    scale: props.scale,
    marginLeft: props.marginLeft ? props.marginLeft : "0rem",
    marginRight: props.marginRight ? props.marginRight : "0rem",
    marginTop: props.marginTop ? props.marginTop : "0rem",
    marginBottom: props.marginBottom ? props.marginBottom : "0rem",
    zIndex: props.zIndex,
    cursor: props.type === "border" ? "default" : props.cursor ? props.cursor : "cursor",
  };

  const disabledStyle = {
    padding: 0,
    width: props.width,
    height: props.height,
    scale: props.scale,
    marginLeft: props.marginLeft ? props.marginLeft : "0rem",
    marginRight: props.marginRight ? props.marginRight : "0rem",
    marginTop: props.marginTop ? props.marginTop : "0rem",
    marginBottom: props.marginBottom ? props.marginBottom : "0rem",
    zIndex: props.zIndex,
    visibility: "hidden",
    // display: "none",
    // backgroundColor:''
  };

  if (props.tooltip) {
    return (
      <Tooltip
        title={props.tooltip}
        placement={props.placement ? props.placement : "top"}
        enterDelay={500}
      >
        <IconButtonComp
          id={props.id}
          onClick={props.onClick}
          style={props.disabled ? disabledStyle : buttonStyle}
          disabled={props.disabled}
          component={props.component}
        >
          <Icon />
        </IconButtonComp>
      </Tooltip>
    );
  } else {
    return (
      <IconButtonComp
        id={props.id}
        onClick={props.onClick}
        style={props.disabled ? disabledStyle : buttonStyle}
        disabled={props.disabled}
        component={props.component}
      >
        <Icon />
      </IconButtonComp>
    );
  }
};
export default IconButton;
