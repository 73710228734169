import {
  loadDeviceList,
  loadDeviceListSuccess,
  loadDeviceListFail,
  loadDeviceListReset,
  loadDeviceScheduleList,
  loadDeviceScheduleListSuccess,
  loadDeviceScheduleListFail,
  loadDeviceScheduleListReset,
  loadSchedulePreSetInfoAction,
  loadSchedulePreSetInfoSuccessAction,
  loadSchedulePreSetInfoFailAction,
  loadSchedulePreSetInfoResetAction,
  loadHolyDayInfoAction,
  loadHolyDayInfoSuccessAction,
  loadHolyDayInfoFailAction,
  loadHolyDayInfoResetAction,
} from "./view_act";

export const loadDeviceListStore = (branch_id) => loadDeviceList(branch_id);
export const loadDeviceScheduleListStore = (branch_id) => loadDeviceScheduleList(branch_id);
export const loadSchedulePreSetInfoActionStore = (branch_id) =>
  loadSchedulePreSetInfoAction(branch_id);

export const loadHolyDayInfoActionStore = (date_year) => loadHolyDayInfoAction(date_year);

//
export const AccessDeviceAction = {
  loadDeviceList,
  loadDeviceListSuccess,
  loadDeviceListFail,
  loadDeviceListReset,

  loadDeviceScheduleList,
  loadDeviceScheduleListSuccess,
  loadDeviceScheduleListFail,
  loadDeviceScheduleListReset,

  loadSchedulePreSetInfoAction,
  loadSchedulePreSetInfoSuccessAction,
  loadSchedulePreSetInfoFailAction,
  loadSchedulePreSetInfoResetAction,

  loadHolyDayInfoSuccessAction,
  loadHolyDayInfoFailAction,
  loadHolyDayInfoResetAction,
};
