import IconButton from "components/Button/IconButton";
import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import ManageGxClassModal from "pages/Staff/SalaryInfo/Modal/ManageGxClassModal";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

const GxClassDetailModal = ({ modalParam }) => {
  const storesDispatch = useDispatch();
  const labelWidth = "4.5rem";
  const textBoxWidth = "16rem";
  const viewModelRef = useRef();
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });

  const [classHistList, setClassHistList] = useState([]);

  const popGxManageModal = (title, param) => {
    setModalInfo({
      show: true,
      title: title,
      Content: <ManageGxClassModal modalParam={param} onModalDone={refreshView} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const manageGx = (data) => {
    if (!data.class_manager_info.staff_name) {
      simpleAlert(storesDispatch, "알림", "담당자 배정 후 수업처리가 가능합니다.");
    } else {
      popGxManageModal("GX 수업 처리", { data });
    }
  };

  const getGxDetail = async () => {
    try {
      const res = await HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/class/gx/detail", {
        params: {
          gx_id: modalParam.data.gx_info.gx_id,
        },
      });
      if (!restEmpty(res.data.data)) {
        setClassHistList(res.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  //화면 상세정보 GET
  useEffect(() => {
    getGxDetail();
  }, [modalParam]);

  const refreshView = () => {
    getGxDetail(); //gx모달 갱신
    modalParam.refreshTable(); //검결 테이블 갱신
    viewModelRef.current.setClose();
  };

  // const popAddGxModal = (title, param, localModalDone) => {
  //   setModalInfo({
  //     show: true,
  //     title: title,
  //     Content: <AddGxClassModal modalParam={param} onModalDone={localModalDone} />,
  //     callback: null,
  //     Buttons: undefined,
  //   });
  // };

  const classList = new latelyClassListTable(manageGx);
  return (
    <div style={{ width: "52rem" }}>
      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
      <div>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <SubTitle titleText="수업 정보" />
        </div>
        <div style={{ display: "flex" }}>
          <TextField
            labelMarginLeft="1.2rem"
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth}
            labelText="수업 코드"
            disabled
            defaultValue={modalParam.data.gx_info.gx_code}
          />
          <TextField
            labelMarginLeft="2.4rem"
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth}
            labelText="수업명"
            disabled
            defaultValue={modalParam.data.class_name}
          />
        </div>
      </div>
      <div style={{ marginTop: "0.5rem" }}>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <div
            style={{ marginLeft: "0.5rem", display: "flex", alignItems: "center", gap: "0.7rem" }}
          >
            <SubTitle titleText="최근 수업 내역" />
            {/* <span style={{ fontSize: "0.725rem", color: "#960000" }}>
              ※ 최근 2개월까지만 표시됩니다.
            </span> */}
            <Label labelText="※ 최근 2개월까지만 표시됩니다." fontSize="0.725rem" color="red" />
          </div>
        </div>
        <CustomTable
          columns_head={classList.columns_head}
          table_title={classList.table_title}
          rest_call={classList.get_data_from_rest}
          row_render={classList.create_table}
          rest_data={classHistList}
        />
      </div>
      {/* <div style={{ display: "flex", justifyContent: "center", marginTop: "1.2rem" }}>
        <Button
          className={clsx({
            [btnStyleClass.btnRootRaw]: true,
            [btnStyleClass.buttonType5]: true,
          })}
          onClick={() => {
            popAddGxModal("GX 수업 추가", {});
          }}
        >
          수업추가
        </Button>
      </div> */}
    </div>
  );
};
export default GxClassDetailModal;

class latelyClassListTable {
  table_title = "";
  manageGx = null;

  constructor(manageGx) {
    this.manageGx = manageGx;
  }

  convertToDayText = (dayIdx) => {
    if (dayIdx === 0) {
      return "일";
    } else if (dayIdx === 1) {
      return "월";
    } else if (dayIdx === 2) {
      return "화";
    } else if (dayIdx === 3) {
      return "수";
    } else if (dayIdx === 4) {
      return "목";
    } else if (dayIdx === 5) {
      return "금";
    } else if (dayIdx === 6) {
      return "토";
    }
  };

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.class_date}</CustomTableContents>
        <CustomTableContents>{this.convertToDayText(data.schedule_week_idx)}</CustomTableContents>
        <CustomTableContents>
          {data.class_from_time} ~ {data.class_to_time}
        </CustomTableContents>
        <CustomTableContents>{data.class_manager_info.staff_name}</CustomTableContents>
        <CustomTableContents>{data.last_class_info.class_status}</CustomTableContents>
        <CustomTableContents>{data.last_class_info.comment}</CustomTableContents>

        <CustomTableContentsEND align="center">
          {/* 완료구분 있을때만 아이콘 보여주기 */}
          {!data.last_class_info.class_status && (
            <IconButton
              type="attendance"
              onClick={() => {
                this.manageGx(data);
              }}
            />
          )}
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "수업일", width: "15%" },
    { title: "수업요일", width: "15%" },
    { title: "수업시간", width: "15%" },
    { title: "수업 담당자", width: "15%" },
    { title: "완료구분", width: "15%" },
    { title: "메모", width: "15%" },
    { title: "", width: "10%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
