export const ptDetailInit = {
  // attendance_list: [
  //   {
  //     date: 0,
  //     is_holiday: false,
  //     is_late: false,
  //     is_no_work: false,
  //     remarks: "",
  //     work_from_time: "",
  //     work_time: 0,
  //     work_to_time: "",
  //   },
  // ],
  // branch_info: {
  //   branch_id: "",
  //   branch_name: "",
  // },
  // class_setting: {
  //   base_info: {
  //     branch_info: {
  //       branch_id: "",
  //       branch_name: "",
  //     },
  //     hq_info: {
  //       hq_id: "",
  //       hq_name: "",
  //     },
  //   },
  //   pt_pre_set: {
  //     is_default: false,
  //     preset_id: "",
  //     preset_name: "",
  //     pt_commission: [
  //       {
  //         from_section: 0,
  //         idx: 0,
  //         rate: 0,
  //         to_section: 0,
  //       },
  //     ],
  //     pt_fee: [
  //       {
  //         from_section: 0,
  //         idx: 0,
  //         rate: 0,
  //         to_section: 0,
  //       },
  //     ],
  //     reg_info: {
  //       created_at: "",
  //       reg_id: "",
  //       reg_name: "",
  //       upd_id: "",
  //       upd_name: "",
  //       updated_at: "",
  //     },
  //   },
  //   staff_id: "",
  // },
  // gx_class_list: [
  //   {
  //     class_fee: 0,
  //     class_name: "",
  //     gx_fee_id: "",
  //     time_list: [
  //       {
  //         day_of_week: "",
  //         end_time: "",
  //         from_time: "",
  //         idx: 0,
  //         is_use: false,
  //       },
  //     ],
  //   },
  // ],
  // is_salary_done: false,
  // is_staff_check_up: false,
  // mbshp_sales_list: [
  //   {
  //     date: 0,
  //     item_info: {
  //       base_info: {
  //         incentive: 0,
  //       },
  //       item_id: "",
  //       item_name: "",
  //       mbshp_option: "",
  //       mbshp_type: "",
  //       pass_cnt: 0,
  //       pass_end_time: "",
  //       pass_from_time: "",
  //       subs_options: {
  //         subs_period_month: 0,
  //       },
  //       total_period_day: 0,
  //       total_period_hour: 0,
  //       total_period_month: 0,
  //       total_price: 0,
  //     },
  //     member_info: {
  //       member_id: "",
  //       member_name: "",
  //       member_no: "",
  //     },
  //     note: "",
  //     performance: {
  //       incentive: 0,
  //     },
  //     sales_info: {
  //       enroll_reg_date: "",
  //       paid_done_date: "",
  //       product_info: {
  //         price: 0,
  //         subs_options: {
  //           contract_from_date: "",
  //           contract_to_date: "",
  //           pre_dis: 0,
  //           pre_price: 0,
  //           prorate_dis: 0,
  //           prorate_price: 0,
  //           subs_dis: 0,
  //           subs_price: 0,
  //         },
  //       },
  //       product_type: "",
  //     },
  //     week_info: {
  //       selected: false,
  //       week_ord: 0,
  //       week_ord_name: "",
  //     },
  //   },
  // ],
  // month: "",

  personal_info: {
    account_holder: "",
    account_no: "",
    address_1: "",
    address_2: "",
    bank: "",
    birthday: "",
    email: "",
    gender: "",
    phone: "",
    sabun: "",
    social_number: "",
    staff_name: "",
    verify: false,
    zip_code: "",
  },
  // pt_class_list: [
  //   {
  //     class_info: {
  //       class_id: "",
  //       fix_payout_rate: false,
  //       memo: "",
  //       no: 0,
  //       payout_price: 0,
  //       proceed_class: {
  //         class_done_date: "",
  //         class_done_time: "",
  //         class_status: "",
  //         comment: "",
  //       },
  //       session_price: 0,
  //       session_type: "",
  //       staff_info: {
  //         staff_id: "",
  //         staff_name: "",
  //         staff_pos: "",
  //         staff_rank: "",
  //       },
  //       view_info: {
  //         is_fix_payout_rate_lock: false,
  //       },
  //     },
  //     member_info: {
  //       member_id: "",
  //       member_name: "",
  //       member_no: "",
  //     },
  //     pt_info: {
  //       pt_info: {
  //         base_session_cnt: 0,
  //         item_name: "",
  //         pt_detail_id: "",
  //         service_session_cnt: 0,
  //       },
  //       week_info: {
  //         selected: false,
  //         week_ord: 0,
  //         week_ord_name: "",
  //       },
  //     },
  //   },
  // ],
  // pt_sales_list: [
  //   {
  //     date: 0,
  //     member_info: {
  //       member_id: "",
  //       member_name: "",
  //       member_no: "",
  //     },
  //     performance: {
  //       incentive: 0,
  //     },
  //     sales_info: {
  //       enroll_reg_date: "",
  //       paid_done_date: "",
  //       pay_type: "",
  //       pt_info: {
  //         base_session_cnt: 0,
  //         item_name: "",
  //         pt_detail_id: "",
  //         service_session_cnt: 0,
  //       },
  //       pt_price: {
  //         paid_price: 0,
  //         paid_price_tax: 0,
  //         per_price: 0,
  //         per_price_tax: 0,
  //         refund_per_price: 0,
  //         total_price: 0,
  //         total_price_tax: 0,
  //       },
  //     },
  //     setting_info: {
  //       cms_by_pay_method: {
  //         card: 0,
  //         cash: 0,
  //       },
  //     },
  //     week_info: {
  //       selected: false,
  //       week_ord: 0,
  //       week_ord_name: "",
  //     },
  //   },
  // ],
  // pt_sales_summary: {
  //   date: 0,
  //   item_info: {
  //     base_info: {
  //       incentive: 0,
  //     },
  //     item_id: "",
  //     item_name: "",
  //     mbshp_option: "",
  //     mbshp_type: "",
  //     pass_cnt: 0,
  //     pass_end_time: "",
  //     pass_from_time: "",
  //     subs_options: {
  //       subs_period_month: 0,
  //     },
  //     total_period_day: 0,
  //     total_period_hour: 0,
  //     total_period_month: 0,
  //     total_price: 0,
  //   },
  //   member_info: {
  //     member_id: "",
  //     member_name: "",
  //     member_no: "",
  //   },
  //   performance: {
  //     incentive: 0,
  //   },
  //   sales_info: {
  //     enroll_reg_date: "",
  //     paid_done_date: "",
  //     product_info: {
  //       price: 0,
  //       subs_options: {
  //         contract_from_date: "",
  //         contract_to_date: "",
  //         pre_dis: 0,
  //         pre_price: 0,
  //         prorate_dis: 0,
  //         prorate_price: 0,
  //         subs_dis: 0,
  //         subs_price: 0,
  //       },
  //     },
  //     product_type: "",
  //   },
  // },
  // sales_plan: [
  //   {
  //     class_info: {
  //       class_freq: "",
  //       class_id: "",
  //       class_name: "",
  //       no: 0,
  //       remain_session_cnt: 0,
  //       session_type: "",
  //     },
  //     membership_info: {
  //       birth: "",
  //       birth_info: {
  //         day: 0,
  //         month: 0,
  //         year: 0,
  //       },
  //       from_date: "",
  //       gender: "",
  //       is_new: false,
  //       item_name: "",
  //       mbshp_option: "",
  //       mbshp_type: "",
  //       member_id: "",
  //       member_name: "",
  //       member_no: "",
  //       membership_id: "",
  //       phone: "",
  //       to_date: "",
  //     },
  //     task_objective_info: {
  //       current_sales: 0,
  //       goal_rate: 0,
  //       goal_sales: 0,
  //     },
  //     task_record_info: {
  //       remark: 0,
  //       status: "",
  //     },
  //     team_info: {
  //       team_id: "",
  //       team_name: "",
  //     },
  //     week_info: {
  //       selected: false,
  //       week_ord: 0,
  //       week_ord_name: "",
  //     },
  //   },
  // ],
  staff_info: {
    staff_id: "",
    staff_name: "",
    staff_pos: "",
    staff_rank: "",
  },
  // staff_performance_id: "",
  // staff_work_info: {
  //   time_list: [
  //     {
  //       day_of_week: "",
  //       end_time: "",
  //       from_time: "",
  //       idx: 0,
  //       is_use: false,
  //     },
  //   ],
  //   wage: 0,
  //   wage_type: "",
  //   work_time_use: false,
  // },
  weekly_summary: [
    {
      pt_summary_info: {
        pt_after_ot_cnt: 0,
        pt_after_ot_sales: 0,
        pt_re_reg_cnt: 0,
        pt_re_reg_sales: 0,
        pt_total_cnt: 0,
        pt_total_sales: 0,
        pt_work_in_cnt: 0,
        pt_work_in_sales: 0,
        pt_total_refund: 0,
        pt_refund_cnt: 0,
      },
      task_activity_info: {
        ot_class: 0,
        ot_reserv: 0,
        pt_class: 0,
        pt_reserv: 0,
      },
      task_objective_info: {
        current_sales: 0,
        goal_rate: 0,
        goal_sales: 0,
      },
      week_info: {
        selected: false,
        week_ord: 0,
        week_ord_name: "",
      },
    },
  ],
  sales_info: {
    sales_record_list: [],
  },
  class_info: {
    class_record_list: [],
  },
};
// class_info.class_record_list
// sales_info.sales_record_list
export const ptMonthSummary = {
  pt_summary_info: {
    pt_after_ot_cnt: 0,
    pt_after_ot_sales: 0,
    pt_re_reg_cnt: 0,
    pt_re_reg_sales: 0,
    pt_total_cnt: 0,
    pt_total_sales: 0,
    pt_work_in_cnt: 0,
    pt_work_in_sales: 0,
    pt_total_refund: 0,
    pt_refund_cnt: 0,
  },
  task_activity_info: {
    ot_class: 0,
    ot_reserv: 0,
    pt_class: 0,
    pt_reserv: 0,
  },
  task_objective_info: {
    current_sales: 0,
    goal_rate: 0,
    goal_sales: 0,
  },
  week_info: {
    selected: false,
    week_ord: 0,
    week_ord_name: "",
  },
};

export const ptTabDumm = [
  {
    etc_salary: {
      salary_info: {
        deduct_reason: "",
        final_salary: 0,
        salary: 0,
        salary_date: "",
        salary_deduct: 0,
      },
    },
    gx_salary: {
      class_list: [
        {
          class_cnt: 0,
          class_hist: [
            {
              from_date: "",
              from_time: "",
              gx_class_hist_id: "",
            },
          ],
          class_name: "",
          standard_price: 0,
          total_salary: 0,
        },
      ],
      salary_info: {
        deduct_reason: "",
        final_salary: 0,
        salary: 0,
        salary_date: "",
        salary_deduct: 0,
      },
    },
    mbshp_salary: {
      salary_info: {
        deduct_reason: "",
        final_salary: 0,
        salary: 0,
        salary_date: "",
        salary_deduct: 0,
      },
      sales_cnt: 0,
      sales_incentive: 0,
      sales_total: 0,
    },
    performance_info: {
      is_staff_check_up: false,
      staff_performance_id: "",
    },
    personal_info: {
      account_holder: "",
      account_no: "",
      address_1: "",
      address_2: "",
      bank: "",
      birthday: "",
      email: "",
      gender: "",
      phone: "",
      sabun: "",
      social_number: "",
      staff_name: "",
      verify: false,
      zip_code: "",
    },
    pt_salary: {
      class_info: {
        class_allowance: 0,
        class_cnt: 0,
        class_commission: 0,
        class_session_total: 0,
      },
      salary_info: {
        deduct_reason: "",
        final_salary: 0,
        salary: 0,
        salary_date: "",
        salary_deduct: 0,
      },
      sales_info: {
        sales_allowance: 0,
        sales_commission: 0,
        sales_total: 0,
      },
    },
    salary_info_total: {
      deduct_reason: "",
      final_salary: 0,
      salary: 0,
      salary_date: "",
      salary_deduct: 0,
    },
    salary_summary_all_type: {
      base_salary_total: 0,
      etc_total_salary: 0,
      gx_total_class: 0,
      mbshp_total_salary: 0,
      pt_total_class: 0,
      pt_total_commission: 0,
      salary_total: 0,
    },
    staff_id: "",
    staff_info: {
      staff_id: "",
      staff_name: "",
      staff_pos: "",
      staff_rank: "",
    },
    standard_salary: {
      salary_info: {
        deduct_reason: "",
        final_salary: 0,
        salary: 0,
        salary_date: "",
        salary_deduct: 0,
      },
      salary_month: 0,
      salary_time: 0,
      total_work_time: 0,
      work_info: {
        wage: 0,
        wage_type: "",
      },
    },
    summary_info: {
      gx_use_cnt: 0,
      mbshp_incentive_cnt: 0,
      mbshp_total_sales: 0,
      pt_total_sales: 0,
      sales_total: 0,
    },
  },
];

export const studentDetailInfoInit = {
  alloc_date: "",
  branch_info: {
    branch_id: "",
    branch_name: "",
  },
  coupon_info: {
    base_info: {
      benefit_product: "",
      benefit_type: "",
    },
    coupon_id: "",
    detail: {
      coupon_name: "",
      price_benefit: {
        dis_price: 0,
        dis_rate: 0,
        type: "",
      },
      service_benefit: {
        pt: {
          add_session: 0,
        },
      },
    },
    final_dis: 0,
    ticket_id: "",
  },
  cs_staff: {
    staff_id: "",
    staff_name: "",
    staff_pos: "",
    staff_rank: "",
  },
  cur_manager: {
    assign_date: "",
    is_assigned: false,
    is_resigned: false,
    staff_info: {
      staff_id: "",
      staff_name: "",
      staff_pos: "",
      staff_rank: "",
    },
  },
  membership_info: {
    birth: "",
    from_date: "",
    gender: "",
    is_new: false,
    item_name: "",
    mbshp_option: "",
    mbshp_type: "",
    member_id: "",
    member_name: "",
    member_no: "",
    membership_id: "",
    phone: "",
    to_date: "",
  },
  pt_class: [
    {
      class_id: "",
      fix_payout_rate: false,
      no: 0,
      payout_price: 0,
      proceed_class: {
        class_done_date: "",
        comment: "",
        done_type: "",
      },
      session_price: 0,
      session_type: "",
      staff_info: {
        staff_id: "",
        staff_name: "",
        staff_pos: "",
        staff_rank: "",
      },
      view_info: {
        is_fix_payout_rate_lock: false,
      },
    },
  ],
  pt_detail_id: "",
  pt_info: {
    base_session_cnt: 0,
    class_status: "",
    item_name: "",
    join_type: "",
    latest_class_date: "",
    mbshp_status: "",
    notice: "",
    proceed_class_lock: false,
    pt_cancel_cnt: 0,
    pt_id: "",
    pt_postpone_cnt: 0,
    pt_used_cnt: 0,
    purchase_date: "",
    remain_session_cnt: 0,
    remarks: "",
    service_session_cnt: 0,
  },
  pt_price: {
    paid_price: 0,
    paid_price_tax: 0,
    per_price: 0,
    per_price_tax: 0,
    refund_per_price: 0,
    total_price: 0,
    total_price_tax: 0,
  },
  raw_price: 0,
  reg_info: {
    created_at: "",
    reg_id: "",
    reg_name: "",
    upd_id: "",
    upd_name: "",
    updated_at: "",
  },
  service_session_hist: [
    {
      add_session: 0,
      reg_info: {
        created_at: "",
        reg_id: "",
        reg_name: "",
        upd_id: "",
        upd_name: "",
        updated_at: "",
      },
    },
  ],
  view_info: {
    selected: false,
  },
};
