import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import _ from "lodash";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import LockerModalIndex from "pages/ERP/LockerService/Modal/LockerModalIndex";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { locker_info_state } from "../Module/reducers/locker_info";
import LockerListItem from "./LockerListItem";

const FamilyLockerListInfo = ({
  memberInputState,
  payInfoListAction,
  orderStartIdx,
  subsPayInfoListAction,
  isSubs,
  hqBranchPolicy,
  selected_locker_list = [],
  personalInfo,
}) => {
  const [lockerListLen, setLockerListLen] = useState(0);

  const btnStyleClass = btnStyles();
  // LockerListInfo

  const [popupInfo, setPopupInfo] = useState({
    title: "",
    select: "",
    modalParam: {
      branch_id: hqBranchPolicy.selected_branch_id,
      branch_name: hqBranchPolicy.selected_branch_name,
    },
  });
  const storesDispatch = useDispatch();

  useEffect(() => {
    // console.log("LockerListInfo  Re-rendered!!!!!!", lockerList);
  });

  const onClickOpenLockerModal = (order, titleText, view_id) => {
    const modalInfo = {
      title: titleText,
      select: order,
      modalParam: {
        branch_id: hqBranchPolicy.selected_branch_id,
        branch_name: hqBranchPolicy.selected_branch_name,
        view_id: view_id,
        selected_locker_list: selected_locker_list,
      },
    };
    setPopupInfo(modalInfo);
    // lockerModelRef.current.setOpen();

    const openModal = (select, param) => {
      storesDispatch(
        globalModalOn({
          show: true,
          title: select,
          Content: (
            <LockerModalIndex
              modalSelect={select}
              modalParam={param}
              onModalDone={onLockerSelectDone}
            />
          ),
        }),
      );
    };
    openModal(order, modalInfo.modalParam);
  };

  const onLockerSelectDone = (paramResRes, selectInfo, view_id) => {
    var array = [...selected_locker_list];
    const index = array.map((item) => item.view_id).indexOf(view_id);

    if (index !== -1) {
      selected_locker_list.splice(index, 1);
    }

    if (selectInfo.locker_area_info.status === "대기") {
      let describe = selectInfo.locker_sector + " - " + selectInfo.locker_no;

      memberInputState.membership_list.locker_list[view_id].describe = describe;
      memberInputState.membership_list.locker_list[view_id].sector_info = paramResRes.sectorInfo;
      memberInputState.membership_list.locker_list[view_id].locker_item = paramResRes.lockerItem;

      selected_locker_list.push(selectInfo);

      storesDispatch(globalModalOff());
    }
  };

  const getOptionData = (is_lock) => {
    if (
      memberInputState.tab_info.is_add_pay === true &&
      memberInputState.tab_info.is_new === false
    ) {
      // addpaid 뺌
      // if (is_lock) {
      return memberInputState.purchase_item_list.item_info.mbshp_option;
      // } else {
      //   return memberInputState.purchase_item_addpaid.item_info.mbshp_option;
      // }
    } else {
      return memberInputState.purchase_item_list.item_info.mbshp_option;
    }
  };

  const btnLockerPush = () => {
    // getLockerTotalPrice();

    const lockerId = uuidv4();
    const currentItem = memberInputState.purchase_item_list.item_info;
    const baseLocker = _.cloneDeep(locker_info_state);

    // if (lockerList === []) {
    //   setLockerList([baseLocker]);
    // } else {
    //   setLockerList([...lockerList, baseLocker]);
    // }
    // setLockerListLen(lockerList.length);

    // let eventId = "locker-" + lockerId;

    memberInputState.membership_list.family_locker_list.push(baseLocker);
    setLockerListLen(memberInputState.membership_list.family_locker_list.length);
    // let eventId = "locker-" + lockerId;

    const idx = memberInputState.membership_list.family_locker_list.length - 1;
    const eventId = memberInputState.tab_info.title;

    if (!dataEmpty(subsPayInfoListAction)) {
      payInfoListAction(
        "push",
        lockerId + "(일할)(가족회원)",
        {
          type: eventId + "락커(일할)(가족회원) (" + (idx + 1) + ")",
          price: baseLocker.item_info.price,
          dis: 0,
          pay_order: idx + 5,
          tab_idx: memberInputState.tab_info.tabIdx,

          item_type: "락커/구독형/일할금액/" + getOptionData(false) + "/가족",
        },
        orderStartIdx + idx,
      );
      payInfoListAction(
        "push",
        lockerId + "(선납)(가족회원)",
        {
          type: eventId + "락커(선납)(가족회원) (" + (idx + 1) + ")",
          price: baseLocker.item_info.price,
          dis: 0,
          pay_order: idx + 5,
          tab_idx: memberInputState.tab_info.tabIdx,

          item_type: "락커/구독형/선납금/" + getOptionData(false) + "/가족",
        },
        orderStartIdx + idx,
      );

      subsPayInfoListAction(
        "push",
        lockerId,
        {
          type: eventId + "락커(가족회원) (" + (idx + 1) + ")",
          price: baseLocker.item_info.price,
          dis: 0,
          pay_order: idx + 5,
          tab_idx: memberInputState.tab_info.tabIdx,
          // item_type: "락커(가족)",
          item_type: "락커/구독형/월구독료/" + getOptionData(false) + "/가족",
        },
        orderStartIdx + idx,
        memberInputState.tab_info.idx,
      );
    } else {
      payInfoListAction(
        "push",
        lockerId,
        {
          type: eventId + "락커(가족회원) (" + (idx + 1) + ")",
          price: baseLocker.item_info.price,
          dis: 0,
          pay_order: idx + 5,
          tab_idx: memberInputState.tab_info.tabIdx,

          item_type: "락커/완납형/금액/" + getOptionData(false) + "/가족",
        },
        orderStartIdx + idx,
      );
    }
  };

  const btnLockerPop = (e) => {
    var array = [...memberInputState.membership_list.family_locker_list];
    const index = array.map((item) => item.view_id).indexOf(e.currentTarget.id);

    if (index !== -1) {
      let curItem = array[index];
      memberInputState.membership_list.family_locker_list.splice(index, 1);
      setLockerListLen(memberInputState.membership_list.family_locker_list.length);
      payInfoListAction("pop", curItem.view_id, {}, 4);
      setLockerListLen(memberInputState.membership_list.family_locker_list.length);
    }

    var array2 = [...selected_locker_list];
    const index2 = array2.map((item) => item.view_id).indexOf(e.currentTarget.id);

    if (index2 !== -1) {
      // let curItem = array2[index];

      selected_locker_list.splice(index2, 1);
    }
  };
  const checkerLock = () => {
    if (
      memberInputState.membership_list.family_locker_list.length > 1 ||
      memberInputState.membership_list.family_locker.is_lock
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div>
      <div style={{ display: "flex" }}>
        <CheckBox
          marginTop="0rem"
          //   inputRef={refStatus.pt_required}
          labelText="락커"
          defaultValue={
            memberInputState.membership_list.family_locker_list.length > 0 ? true : false
          }
          disabled={checkerLock()}
        />

        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType2]: true,
            [btnStyleClass.disabled]: checkerLock(),
          })}
          onClick={btnLockerPush}
          disabled={checkerLock()}
        >
          추가
        </Button>
      </div>
      {memberInputState.membership_list.family_locker_list.map((item, idx) => (
        <LockerListItem
          key={idx}
          ord={idx}
          lockerItemInfo={item}
          // itemInfo={item.item_info}
          // lockerInfo={item.locker_info}
          // onDisPriceChange={onDisPriceChange}
          btnLockerPop={btnLockerPop}
          onClickOpenLockerModal={onClickOpenLockerModal}
          payInfoListAction={payInfoListAction}
          tabInfoParam={memberInputState.tab_info}
          subsPayInfoListAction={subsPayInfoListAction}
          isSubs={isSubs}
          familyKey={"가족"}
          selected_locker_list={selected_locker_list}
        />
      ))}
    </div>
  );
};

export default FamilyLockerListInfo;
