import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import CurrentWorkInfoView from "pages/ApprovalManage/ApprovalForm/PersonnelWorkReq/CurrentWorkInfoView";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

const WorkInfoChangeHist = ({ modalParam }) => {
  const storesDispatch = useDispatch();
  const [historyList, setHistoryList] = useState([]);

  useEffect(() => {
    HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/staff/work_info/change_log", {
      params: {
        staff_id: modalParam.staff_info_state.staff_id,
        branch_id: modalParam.branch_id,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setHistoryList(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  }, []);

  const onModalDone = () => {
    console.log("done");
  };

  // console.log(modalParam);
  const onClickDetail = (logType, workObj) => {
    // const detailParams = _.cloneDeep(modalParam);
    // detailParams.pageMode = "이력";
    // detailParams.log_id = logId;
    // console.log(detailParams);
    setModalInfo({
      show: true,
      title: "기존 근로정보 내역",
      Content: <CurrentWorkInfoView workInfoObj={workObj} logType={logType} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const itemList = new itemInfoTable(onClickDetail);

  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });
  const viewModalRef = useRef();

  return (
    <div style={{ width: "85rem" }}>
      <ModalPopupLocal ref={viewModalRef} params={modalInfo} />
      <SubTitle titleText={"근로정보 변경 이력"} />

      <CustomTable
        columns_head={itemList.columns_head}
        table_title={itemList.table_title}
        rest_call={itemList.get_data_from_rest}
        row_render={itemList.create_table}
        rest_data={historyList}
      />
    </div>
  );
};
export default WorkInfoChangeHist;

class itemInfoTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onClickDetail = null;

  constructor(onClickDetail) {
    this.onClickDetail = onClickDetail;
  }
  getDateInfo = (data) => {
    if (data.log_type === "입사") {
      return data.work_info_data.base_info.join_date;
    } else if (data.log_type === "퇴사") {
      return data.work_info_data.base_info.resign_date;
    } else if (data.log_type === "전환") {
      return data.work_info_data.base_info.work_trans_from_date;
    } else {
      return "";
    }
  };
  create_table = (data, key) => {
    // console.log(data);
    return (
      <CustomRow key={key} style={{ width: "100%" }}>
        <CustomTableContents width="12%">{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents width="8%">{data.reg_info.reg_name}</CustomTableContents>
        <CustomTableContents width="7%">{data.log_type}</CustomTableContents>
        {/* <CustomTableContents width="8%">
          {data.work_info_data.base_info.work_trans_from_date}
        </CustomTableContents>
        <CustomTableContents width="8%">
          {data.work_info_data.base_info.work_trans_to_date}
        </CustomTableContents> */}
        <CustomTableContents width="8%">
          {this.getDateInfo(data)}
          {/* ^^ */}
        </CustomTableContents>
        <CustomTableContents width="8%">
          {data.work_info_data.base_info.position.org_name}
        </CustomTableContents>
        <CustomTableContents width="7%">{data.work_info_data.wage_type}</CustomTableContents>
        <CustomTableContents width="8%" $isNumber>
          {data.work_info_data.wage.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents width="7%">
          {data.work_info_data.is_incentive ? "Y" : "N"}
        </CustomTableContents>
        <CustomTableContents width="6%">
          {data.work_info_data.is_pt_extra ? "Y" : "N"}
        </CustomTableContents>
        <CustomTableContents width="6%">
          {data.work_info_data.is_gx_extra ? "Y" : "N"}
        </CustomTableContents>
        <CustomTableContents width="5%">
          <Button
            className={clsx({
              [this.btnStyleClass.btnRootRaw]: true,
              [this.btnStyleClass.buttonType4]: true,
            })}
            onClick={() => this.onClickDetail(data.log_type, data.work_info_data)}
          >
            내역 보기
          </Button>
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "등록일시", field: "select" },
    { title: "등록자", field: "pay_method" },
    { title: "등록구분", field: "pay_method" },
    { title: "인사변경일", field: "pay_method" },
    // { title: "근무(전환)\n시작일", field: "pay_method" },
    // { title: "근무(전환)\n종료일", field: "pay_method" },
    { title: "직책", field: "pay_method" },
    { title: "기본급 종류", field: "pay_method" },
    { title: "기본급 금액", field: "pay_method" },
    { title: "회원권 판매\n 인센티브", field: "pay_method" },
    { title: "PT수당", field: "pay_method" },
    { title: "GX수당", field: "pay_method" },
    { title: "", field: "" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
