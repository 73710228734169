import { getPostRowState } from "pages/NoticeBoard/model/board_row";
import { getBoardRowAPi } from "pages/NoticeBoard/restApi/getApi";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import StaffDMDetailView from "./StaffDMDetailView";

const StaffDMDetailWrapper = ({ nowService, postId }) => {
  const storesDispatch = useDispatch();
  const [postRowState, setPostRowState] = useState(getPostRowState(nowService));
  useEffect(() => {
    getBoardRowAPi(storesDispatch, nowService, postId, setPostRowState);
  }, []);
  //게시글 상세 GET
  const getNoticeDetail = async () => {};
  return <StaffDMDetailView postRowState={postRowState} nowService={nowService} />;
};

export default StaffDMDetailWrapper;
