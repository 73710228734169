import CardFixed from "components/Card/CardFixed";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import NormalBtn from "components/NewButton/NormalBtn";
import { useNavigate } from "react-router-dom";

const LockerOverallStatus = ({
  onClickOpenModal,
  branchList,
  selectedBranch,
  setSelectedBranch,
  lockerStatus,
  refreshView,
}) => {
  const navigate = useNavigate();

  const currentTotalTable = new lockerTotalTable();

  return (
    <div>
      <CardFixed
        topDom={<div>전체 현황</div>}
        midDom={
          <div>
            <div
              style={{
                display: "flex",
              }}
            >
              <SearchComboBox
                labelWidth={"2.5rem"}
                comboItemWidth={"12rem"}
                labelText="지점명"
                data={branchList.branch_name_list}
                defaultValue={branchList.branch_name_list[0] || ""}
                onChangeCallback={(e, v) => {
                  const branch = branchList.branch_list.find((el) => el.branch_name === v);
                  if (branch) {
                    setSelectedBranch(branch);
                  }
                }}
              />

              <div style={{ display: "flex", gap: "0.3rem" }}>
                <NormalBtn
                  name=" 고장 락커 사용 회원"
                  theme="white"
                  onClick={() => {
                    const param = {
                      title: "고장 락커 사용 회원",
                      selectedBranch,
                    };
                    //refreshView  파라미터 3개 필요(branchId, floorId,zoneId)
                    onClickOpenModal("고장락커사용회원", param, refreshView);
                  }}
                />
                <NormalBtn
                  name="미배정 회원목록"
                  theme="white"
                  onClick={() => {
                    const param = {
                      title: "미배정 회원목록",
                      selectedBranch,
                    };
                    onClickOpenModal("미배정회원목록", param, refreshView);
                  }}
                />

                <NormalBtn
                  name="만료 락커 검색"
                  onClick={() => {
                    const param = {
                      title: "만료락커 검색",
                      selectedBranch,
                      refreshView,
                      navigate,
                    };
                    onClickOpenModal("만료락커검색", param);
                  }}
                />
              </div>
            </div>

            <div style={{ marginTop: "0.5rem" }}>
              <CustomTable
                columns_head={currentTotalTable.columns_head}
                table_title={currentTotalTable.table_title}
                rest_call={currentTotalTable.get_data_from_rest}
                row_render={currentTotalTable.create_table}
                rest_data={lockerStatus}
              />
            </div>
          </div>
        }
        isLoading={false}
        rootStyle={{
          width: "105rem",
          borderRadius: "0.625rem",
          backgroundColor: "#fff",
        }}
        titleHeight={"3rem"}
      />
    </div>
  );
};
export default LockerOverallStatus;

class lockerTotalTable {
  table_title = "";

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.total}</CustomTableContents>
        <CustomTableContents>{data.used}</CustomTableContents>
        <CustomTableContents>{data.ready}</CustomTableContents>
        <CustomTableContents>{data.expired}</CustomTableContents>
        <CustomTableContents>{data.broken}</CustomTableContents>
        <CustomTableContentsEND align="center">{data.un_passwd}</CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "전체", width: "16.5%" },
    { title: "사용", width: "16.5%" },
    { title: "대기", width: "16.5%" },
    { title: "만료", width: "16.5%" },
    { title: "고장", width: "16.5%" },
    { title: "비밀번호 미설정", width: "16.5%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return [stateData];
  };
}
