import { restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import { useGetAuthData } from "hooks/apis/access/useAuth";
import { HttpInstance } from "lib/HttpLib";
import {
  loadMemberViewStore,
  loadMembershipHistoryStore,
} from "pages/ERP/MemberView/Module/actions";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import ChangePaperDueModal from "./Modal/ChangePaperDueModal";
import LongPostponeModal from "./Modal/LongPostponeModal";
import { loadPostponeViewStore } from "./Module/actions";
import PostponeView from "./PostponeView";

const PostponeWrapper = ({ viewState, memberId, branchId, membershipId, mbshpType }) => {
  // console.log(viewState);
  const storesDispatch = useDispatch();
  const viewModelRef = useRef();
  const [isAdmin, setIsAdmin] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });

  const { data: authData } = useGetAuthData();

  const [curPage, setCurPage] = useState();
  const [totalPage, setTotalPage] = useState(0);

  const [postponeHist, setPostponeHist] = useState([]);
  const paperDueRef = useRef([]);

  //연기이력 GET
  const getPostponeHist = async (page) => {
    setCurPage(page);
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_USER_SERVER + "/change/postpone_hist",
        {
          params: {
            member_id: memberId,
            page_num: curPage,
            membership_id: membershipId,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        const result = res.data.data;

        if (membershipId === viewState.contents.active_postpone.membership_id) {
          //현재 연기중
          const copyResult = [...result];
          copyResult.unshift(viewState.contents.active_postpone);

          setPostponeHist(copyResult);
          setTotalPage(res.data.total_page);
        } else {
          //연기중 아님

          setPostponeHist(result);
          setTotalPage(res.data.total_page);
        }
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  //서류이력 GET
  const getPaperDueHist = async () => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_USER_SERVER + "/change/due_date/postpone_hist",
        {
          params: {
            member_id: memberId,
            page_num: 1,
            membership_id: membershipId,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        paperDueRef.current = res.data.data;
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  const refreshStore = () => {
    storesDispatch(loadPostponeViewStore(memberId, branchId, membershipId)); //연기화면 업데이트
    storesDispatch(loadMemberViewStore(memberId)); //회원정보 업데이트
    storesDispatch(loadMembershipHistoryStore(memberId)); //회원정보 테이블 업데이트
  };

  useEffect(() => {
    if (authData) {
      setIsAdmin(authData.data.data.is_admin);
    }
  }, [authData]);

  useEffect(() => {
    if (viewState.contents.active_postpone.membership_id) {
      getPostponeHist(1);
      getPaperDueHist();
    }
  }, [viewState.contents.active_postpone]);

  //연기이력 페이지네이션
  useEffect(() => {
    if (curPage) {
      getPostponeHist(curPage);
    }
  }, [curPage]);

  const localModalDone = () => {
    refreshStore(); //연기 화면 업데이트 + 회원정보 업데이트+ 회원테이블 업데이트
    viewModelRef.current.setClose();
  };

  //장기연기 신청 모달
  const onRequestLongPostponeModal = (title, param, localModalDone) => {
    setModalInfo({
      show: true,
      title: title,
      Content: <LongPostponeModal modalParam={param} onModalDone={localModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  //장기연기 신청 서류기한 변경 모달
  const onChangePaperDueModal = (title, param, localModalDone) => {
    setModalInfo({
      show: true,
      title: title,
      Content: <ChangePaperDueModal modalParam={param} onModalDone={localModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const popLongPostponeModal = (fromDate) => {
    onRequestLongPostponeModal(
      "장기연기 신청",
      {
        from_date: fromDate,
        viewState,
      },
      localModalDone,
    );
  };

  const popChangePaperDueModal = (fileDueDate) => {
    onChangePaperDueModal(
      "서류 제출 기한 변경",
      {
        file_due_date: fileDueDate,
        member_id: memberId,
        membership_id: membershipId,
        postpone_id: viewState.contents.active_postpone.postpone_id,
        paperDueList: paperDueRef.current,
        getPaperDueHist,
      },
      localModalDone,
    );
  };

  return (
    <div>
      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
      <PostponeView
        viewState={viewState}
        popLongPostponeModal={popLongPostponeModal}
        popChangePaperDueModal={popChangePaperDueModal}
        refreshStore={refreshStore}
        branchId={branchId}
        mbshpType={mbshpType}
        postponeHist={postponeHist}
        curPage={curPage}
        setCurPage={setCurPage}
        totalPage={totalPage}
        getPostponeHist={getPostponeHist}
        isAdmin={isAdmin}
      />
    </div>
  );
};
export default PostponeWrapper;
