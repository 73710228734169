import IconButton from "components/Button/IconButton";
import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import TextField from "components/LabelInput/TextField";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalModalOff } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { exportToPtSalaryExcel, exportToPtSalaryExcelExtend } from "../MakeExcel";
import { loadPtSalaryViewStore, loadSalaryInfoViewStore } from "../Module/actions";
import { useSalaryInfoStore } from "../modules/store/salaryInfoState";
import StaffPtSalesDetail from "./StaffPtSalesDetail";
import { ptDetailInit, ptMonthSummary } from "./dumm";

// PT 수당 현황 테이블
const PtSalaryInfoView = ({
  baseDate,
  onClickOpenModal,
  salaryReportId,
  branchId,
  is_edit,
  branchName,
}) => {
  const storesDispatch = useDispatch();
  // 전역 상태 관리
  const { setSalaryInfoLoading: setLoading } = useSalaryInfoStore();

  const [selectedStaffInfo, setSelectedStaffInfo] = useState({
    staffId: "",
    salaryReportId: salaryReportId,
    staffName: "",
    staffPos: "",
    staffSalaryId: "",
  });

  const restData = useSelector((state) => state.staff_service.salary_staff_pt);

  const [lastWorkInfo, setLastWorkInfo] = useState({ work_info_id: "" });
  const [ptDetail, setPtDetail] = useState(ptDetailInit);
  const [ptSummary, setPtSummary] = useState(ptMonthSummary);

  //row클릭 PT수당 상세 GET
  const getPtSalaryDetail = async (selectedInfo) => {
    try {
      setLoading(true);

      if (selectedInfo === undefined) {
        return;
      }
      const res = await HttpInstance.get(
        process.env.REACT_APP_SALARY_SERVER + "/salary/pt/detail",
        {
          params: {
            base_month: baseDate,
            staff_salary_id: selectedInfo.staffSalaryId,
            staff_id: selectedInfo.staffId,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setPtDetail(res.data.data);
        setPtSummary(res.data.summary);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  // 해당 월의 마지막 work info를 가져옴
  const getLastWorkInfo = async (staffId) => {
    try {
      setLoading(true);

      const res = await HttpInstance.get(
        process.env.REACT_APP_STAFF_SERVER + "/staff/work_info/in_month",
        {
          params: {
            base_month: baseDate,
            staff_id: staffId,
            branch_id: branchId,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        return res.data.data;
      } else {
        return null;
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };
  ///////////////////////////////

  useEffect(() => {
    if (salaryReportId) {
      storesDispatch(loadPtSalaryViewStore(salaryReportId, is_edit));
    }
  }, [salaryReportId]);

  useEffect(() => {
    if (selectedStaffInfo.staffId) {
      getPtSalaryDetail(selectedStaffInfo);
    }
  }, [selectedStaffInfo.staffId]);

  const refreshAllView = async (selectedInfo) => {
    storesDispatch(loadSalaryInfoViewStore(salaryReportId, is_edit)); //메인 갱신
    storesDispatch(loadPtSalaryViewStore(salaryReportId, is_edit)); //PT탭 갱신
    await getPtSalaryDetail(selectedInfo); //상세 하단 테이블 갱신
    storesDispatch(globalModalOff()); //모달닫기
  };

  const popChangeSessionPriceModal = (ptDetailId) => {
    const param = {
      title: "세션가 변경",
      modalParam: {
        staffId: selectedStaffInfo.staffId,
        salaryReportId,
        staffSalaryId: selectedStaffInfo.staffSalaryId,
        base_date: baseDate,
        is_edit,
        ptDetailId,
      },
    };
    onClickOpenModal("세션가변경", param, () => {
      refreshAllView(selectedStaffInfo);
    });
  };

  const popSettingBasicCommissionModal = async (data, pt_commission) => {
    const lastWorkInfo = await getLastWorkInfo(data.staff_id);
    const param = {
      title: "당월 커미션 설정",
      modalParam: {
        mode: "view",
        onClickOpenModal,
        is_edit,
        branchId,
        salaryReportId,
        staffSalaryId: data.staff_salary_id,
        data,
        pt_preset: pt_commission,
        pt_commission: pt_commission.pt_commission,
        pt_fee: pt_commission.pt_fee,
        lastWorkInfoId: lastWorkInfo.work_info_id,
      },
    };
    onClickOpenModal("당월커미션설정", param);
  };

  const onClickRow = (data) => {
    setSelectedStaffInfo((cur) => {
      return {
        ...cur,
        staffId: data.staff_info.staff_id,
        staffName: data.personal_info.staff_name,
        staffPos: data.staff_info.staff_pos,
        staffSalaryId: data.staff_salary_id,
      };
    });
  };

  // Get Excel Data
  const getExcelData = async (staffId, staffName) => {
    try {
      setLoading(true);

      let api = "";
      const params = {
        base_month: baseDate,
        salary_report_id: salaryReportId,
      };
      if (staffId) {
        // 개별 직원 PT 수당 엑셀 다운로드
        api = "/salary/pt/list/excel";
        params.staff_id = staffId;
      } else {
        api = "/salary/pt/list/excel/all";
      }

      const res = await HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + api, {
        params,
      });
      if (!restEmpty(res.data.data)) {
        if (staffId) {
          exportToPtSalaryExcel(res.data.data, `${staffName}_${baseDate}_PT_수당현황`, baseDate);
        } else {
          exportToPtSalaryExcelExtend(
            res.data.data,
            `${branchName}_${baseDate}_PT_수당현황`,
            baseDate,
          );
        }
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  // 엑셀 다운로드 여부 체크 모달
  const onClickOpenExcelDownloadModal = (staffId, staffName) => {
    simpleAlert(
      storesDispatch,
      "알림",
      staffId
        ? `"${staffName}_${baseDate}_PT_수당현황"\n 엑셀 파일을 다운로드 하시겠습니까?`
        : `"${branchName}_${baseDate}_PT_수당현황"\n 엑셀 파일을 다운로드 하시겠습니까?`,
      false,
      undefined,
      () => {
        staffId ? getExcelData(staffId, staffName) : getExcelData();
      },
    );
  };

  const ptSalaryTableItem = new ptSalaryTable(
    popSettingBasicCommissionModal,
    setSelectedStaffInfo,
    onClickRow,
    onClickOpenExcelDownloadModal,
  );

  return (
    <>
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex" }}>
            <IconButton type="border" />
            <div style={{ width: "0.5rem" }}></div>
            <SubTitle titleText="PT 수당 현황" />
          </div>

          <div>
            <NormalBtn
              name="엑셀 다운로드"
              theme="green"
              onClick={() => {
                onClickOpenExcelDownloadModal();
              }}
            />
          </div>
        </div>
        <div style={{ marginTop: "0.5rem" }}>
          <CustomTable
            columns_head={ptSalaryTableItem.columns_head}
            table_title={ptSalaryTableItem.table_title}
            rest_call={ptSalaryTableItem.get_data_from_rest}
            row_render={ptSalaryTableItem.create_table}
            rest_data={restData.stores.data}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem" }}>
          {/* <TextField
            labelWidth={"7rem"}
            textBoxWidth={"9rem"}
            labelText="수당 합계"
            endAdornment={"원"}
            disabled
            defaultValue={restData.stores.total_class_salary.toLocaleString()}
          /> */}
          <TextField
            labelWidth={"7rem"}
            textBoxWidth={"9rem"}
            textMarginRight="0rem"
            labelText="최종 지급액 합계"
            endAdornment={"원"}
            defaultValue={restData.stores.final_total_salary.toLocaleString()}
            isNumber
            disabled
          />
        </div>
      </div>
      {selectedStaffInfo.staffId && (
        <StaffPtSalesDetail
          selected={selectedStaffInfo}
          ptDetail={ptDetail}
          ptSummary={ptSummary}
          popChangeSessionPriceModal={popChangeSessionPriceModal}
        />
      )}
    </>
  );
};
export default PtSalaryInfoView;

//
//
//
class ptSalaryTable {
  table_title = "";
  popSettingBasicCommissionModal = null;
  setSelected = null;
  onClickRow = null;
  onClickOpenExcelDownloadModal = null;

  constructor(
    popSettingBasicCommissionModal,
    setSelected,
    onClickRow,
    onClickOpenExcelDownloadModal,
  ) {
    this.popSettingBasicCommissionModal = popSettingBasicCommissionModal;
    this.setSelected = setSelected;
    this.onClickRow = onClickRow;
    this.onClickOpenExcelDownloadModal = onClickOpenExcelDownloadModal;
  }
  create_table = (data, idx) => {
    return (
      <CustomRow
        key={idx}
        onClick={(e) => {
          // e.target.tagName : svg = IconButton, TD = table cell
          if (!e.target.type && e.target.tagName === "TD") {
            this.onClickRow(data);
          }
        }}
        hover
      >
        <CustomTableContents>{data.personal_info.sabun}</CustomTableContents>
        <CustomTableContents>{data.personal_info.staff_name}</CustomTableContents>
        <CustomTableContents>{data.staff_info.staff_pos}</CustomTableContents>
        <CustomTableContents $isNumber>
          {data.pt_salary.sales_info.sales_incentive.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.pt_salary.sales_info.sales_commission.toLocaleString() + " %"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.pt_salary.sales_info.sales_allowance.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.pt_salary.class_info.class_cnt + " 회"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.pt_salary.class_info.class_commission.toLocaleString() + " %"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.pt_salary.class_info.class_allowance.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents $isNumber>
          {data.pt_salary.salary_info.final_salary.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents>
          <div
            onClick={() => {
              this.popSettingBasicCommissionModal(data, data.pt_commission);
            }}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <IconButton type="commission" width="3rem" />
          </div>
        </CustomTableContents>
        <CustomTableContentsEND>
          <div
            onClick={() => {
              this.onClickOpenExcelDownloadModal(data.staff_id, data.personal_info.staff_name);
            }}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <IconButton type="download_small" />
          </div>
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "사번", width: "7%" },
    { title: "직원명", width: "7%" },
    { title: "직책", width: "9%" },
    { title: "총 매출", width: "9%", bgColor: "#E4F4E1" },
    { title: "PT 매출 커미션율", width: "8%", bgColor: "#E4F4E1" },
    { title: "PT 매출 커미션", width: "10%", bgColor: "#E4F4E1" },
    { title: "총 수업 진행횟수", width: "8%", bgColor: "#FFF4D7" },
    { title: "수업 커미션율", width: "8%", bgColor: "#FFF4D7" },
    { title: "수업 커미션", width: "9%", bgColor: "#FFF4D7" },
    { title: "최종 지급액", width: "10%" },
    { title: "커미션 설정", width: "7%" },
    { title: "엑셀 다운로드", width: "7%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
