const { default: styled } = require("styled-components");

export const SearchRowBox = styled.div`
  display: flex;
  margin-top: ${(props) => props.marginTop || "0.5rem"};
`;

export const SearchConditionBox = styled.div`
  width: ${(props) => props.width || "20%"};
  display: flex;
`;
