import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { listEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import SubTitle from "components/PageTitle/SubTitle";
import _ from "lodash";
import { payment_input_state } from "pages/ERP/MemberCommonItems/PaymentModalB/payment_B_init";
import { useEffect, useRef, useState } from "react";
import RefundListItem from "./RefundListItem";
import TotalInfoTable from "./TotalInfoTable";

const RefundList = ({
  refundState,
  refundListState,
  payInfoListAction,
  regDate,
  payMethodList,
  setPayMethodList,
  onSelectPayMethod,
}) => {
  const [openListState, setOpenListState] = useState([]);
  const refundListItemRef = useRef();

  const initOpenState = () => {
    const openList = [];
    for (let i = 0; i < refundListState.length; i++) {
      openList.push(false);
    }
    return openList;
  };

  useEffect(() => {
    setOpenListState(initOpenState());
    if (refundListItemRef.current) {
      refundListItemRef.current.clearList();
    }
  }, [refundListState]);

  const selectOpen = (idx, val) => {
    const openList = initOpenState();
    openList[idx] = val;
    for (let i = 0; i < openList.length; i++) {
      if (idx !== i) {
        openList[i] = false;
      }
    }
    setOpenListState(openList);
  };

  const selectPayMethod = (totalPaid, idx) => {
    refundListState[idx].done_refund_price = totalPaid;
    refundListState[idx].repaid_price = totalPaid - refundListState[idx].total_refund_price;

    const openList = initOpenState();
    openList[idx] = true;
    setOpenListState(openList);

    const purchaseCode = refundListState[idx].purchase_code;
    const action = refundListState[idx].repaid_price <= 0 ? "pop" : "push";
    addPayInfoState(
      purchaseCode,
      action,
      "재결제 (" + purchaseCode + ")",
      refundListState[idx].repaid_price,
      0,
    );

    if (action === "push") {
      if (payMethodList.findIndex((x) => x.purchase_code === purchaseCode) === -1) {
        const newPayMethod = _.cloneDeep(payment_input_state);
        newPayMethod.purchase_code = purchaseCode;
        newPayMethod.unpaid_info.unpaid_price = refundListState[idx].repaid_price;
        newPayMethod.total_price = refundListState[idx].repaid_price;
        setPayMethodList([...payMethodList, newPayMethod]);
      }
    } else {
      const deleteIdx = payMethodList.findIndex((x) => x.purchase_code === purchaseCode);
      if (deleteIdx !== -1) {
        const arrCopy = _.cloneDeep(payMethodList);
        arrCopy.splice(deleteIdx, 1);
        setPayMethodList(arrCopy);
      }
    }

    onSelectPayMethod();
  };

  // 추가
  const addPayInfoState = (purchaseCode, action, key, price, idx) => {
    const contentObj = {
      type: key,
      price: price,
      dis: 0,
      pay_order: idx,
      tab_idx: 0,
      item_type: "재결제/없음/없음/없음/없음",
    };

    payInfoListAction(action, key, contentObj, idx);
  };

  const updateTotalSummary = () => {
    let paid_price = 0;
    let deduct_price = 0;
    let penalty = 0;
    let refund_price = 0;
    let done_refund_price = 0;
    let repaid_price = 0;

    for (let i = 0; i < refundListState.length; i++) {
      paid_price += refundListState[i].total_paid_price;
      deduct_price += refundListState[i].total_deduct_price;
      penalty += refundListState[i].total_penalty;
      refund_price += refundListState[i].total_refund_price;
      done_refund_price += refundListState[i].done_refund_price;
      repaid_price += refundListState[i].repaid_price;
    }

    refundState.refund_sum_mary.paid_price = paid_price;
    refundState.refund_sum_mary.deduct_price = deduct_price;
    refundState.refund_sum_mary.penalty = penalty;
    refundState.refund_sum_mary.refund_price = refund_price;
    refundState.refund_sum_mary.done_refund_price = done_refund_price;
    refundState.refund_sum_mary.repaid_price = repaid_price;
  };

  const refundList = new refundTable(
    openListState,
    selectOpen,
    selectPayMethod,
    updateTotalSummary,
    regDate,
    refundListItemRef,
  );

  return (
    <div
      style={{
        marginTop: "3rem",
      }}
    >
      <SubTitle titleText={"환불 신청 내역"} />
      <div style={{ margin: "0 0.1rem" }}>
        <CustomTable
          columns_head={refundList.columns_head}
          table_title={refundList.table_title}
          rest_call={refundList.get_data_from_rest}
          row_render={refundList.create_table}
          rest_data={refundListState}
        />

        <TotalInfoTable refundState={refundState} />
      </div>
    </div>
  );
};

export default RefundList;

class refundTable {
  table_title = "";
  btnStyleClass = btnStyles();
  openListState = [];
  selectOpen = null;
  selectPayMethod = null;
  updateTotalSummary = null;
  regDate = null;
  refundListItemRef = null;

  constructor(
    openListState,
    selectOpen,
    selectPayMethod,
    updateTotalSummary,
    regDate,
    refundListItemRef,
  ) {
    this.openListState = openListState;
    this.selectOpen = selectOpen;
    this.selectPayMethod = selectPayMethod;
    this.updateTotalSummary = updateTotalSummary;
    this.regDate = regDate;
    this.refundListItemRef = refundListItemRef;
  }

  create_table = (data, idx) => {
    return (
      <>
        <CustomRow
          style={{ width: "100%", cursor: "pointer" }}
          onClick={() => {
            this.selectOpen(idx, !this.openListState[idx]);
          }}
        >
          <CustomTableContents width="16%">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ width: "2rem" }}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    this.selectOpen(idx, !this.openListState[idx]);
                  }}
                >
                  {this.openListState[idx] ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                </IconButton>
              </div>
              <div style={{ width: "70%" }}>{data.purchase_code}</div>
            </div>
          </CustomTableContents>
          <CustomTableContents width="14%">
            {data.total_paid_price.toLocaleString()}
          </CustomTableContents>
          <CustomTableContents width="14%">
            {(data.total_penalty + data.total_deduct_price).toLocaleString()}
          </CustomTableContents>
          {/* <CustomTableContents width="14%">
            {data.total_penalty.toLocaleString()}
          </CustomTableContents> */}
          <CustomTableContents width="14%">
            {data.total_refund_price.toLocaleString()}
          </CustomTableContents>
          <CustomTableContents width="14%">
            {data.done_refund_price.toLocaleString()}
          </CustomTableContents>
          <CustomTableContents width="14%">
            {data.repaid_price.toLocaleString()}
          </CustomTableContents>
        </CustomRow>
        <CustomRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in={this.openListState[idx]} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <RefundListItem
                  ref={this.refundListItemRef}
                  refundItem={data}
                  index={idx}
                  selectPayMethod={this.selectPayMethod}
                  regDate={this.regDate}
                />
              </Box>
            </Collapse>
          </TableCell>
        </CustomRow>
      </>
    );
  };

  columns_head = [
    { title: "구매코드", width: "16%" },
    { title: "결제금액 합계", width: "14%" },
    { title: "차감금액 및 위약금", width: "14%" },
    // { title: "위약금 합계", width: "14%" },
    { title: "최종 환불금액", width: "14%" },
    { title: "환불 완료 금액", width: "14%" },
    { title: "재결제 금액", width: "14%" },
  ];

  get_data_from_rest = (stateData) => {
    if (listEmpty(stateData)) {
      return [];
    }
    this.updateTotalSummary();
    return stateData;
  };
}
