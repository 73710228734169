import IconButton from "components/Button/IconButton";
import {
  contentTypeJson,
  dateToStr,
  generateObjectZeroId,
  getCurrentDate,
  restEmpty,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import Popup from "components/ModalPopup/Popup";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import { globalModalOff } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import DaumPostcode from "react-daum-postcode";
import { useDispatch } from "react-redux";
import { loadMemberViewStore } from "../Module/actions";

const ConverToMemberModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const labelWidth = "4rem";
  const compWidth = "10rem";
  const [isAddrPopup, setAddrPopup] = useState(false);
  const [hqIdx, setHqIdx] = useState(0);
  const [loading, setLoading] = useState(false);

  const [hqBranchData, setHqBranchData] = useState({
    branch_list: [[]],
    hq_list: [],
    target_list: [],
  });

  const [memberInfo, setMemberInfo] = useState({
    access_way: "",
    join_way: "",
    member_type: "회원",
    branch_info: {
      branch_id: "",
      branch_name: "",
    },
    hq_info: {
      hq_id: "",
      hq_name: "",
    },
    member_id: modalParam.member_id,
    personal_info: {
      address_1: "",
      address_2: "",
      agree_marketing: modalParam.personal_info.agree_marketing,
      agree_personal: modalParam.personal_info.agree_personal,
      birth: "",
      gender: "남",
      member_name: modalParam.personal_info.member_name,
      member_no: modalParam.personal_info.member_no,
      phone: modalParam.personal_info.phone,
      notice: "",
      email: "",
      phone_backup: "",
      reg_info: {
        created_at: "",
        reg_id: "",
        reg_name: "",
        upd_id: "",
        upd_name: "",
        updated_at: "",
      },
      reg_path: "",
      zip_code: "",
    },
  });
  const openAddrPopup = (val) => {
    setAddrPopup(val);
  };

  const validationCheck = () => {
    if (!memberInfo.branch_info.branch_id || memberInfo.branch_info.branch_name === "전체") {
      simpleAlert(storesDispatch, "알림", "지점을 선택해주세요.");
    }
    // else if (!memberInfo.join_way) {
    //   simpleAlert(storesDispatch, "알림", "가입경로를 선택해주세요.");
    // }
    else if (!memberInfo.personal_info.birth) {
      simpleAlert(storesDispatch, "알림", "생년월일을 입력해주세요.");
    } else if (!memberInfo.personal_info.gender) {
      simpleAlert(storesDispatch, "알림", "성별을 선택해주세요.");
    } else if (!memberInfo.personal_info.address_1) {
      simpleAlert(storesDispatch, "알림", "주소를 입력해 주세요.");
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="회원전환 하시겠습니까?" />
            </>
          ),
          onBtnYes: () => {
            covertToMemeber();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const covertToMemeber = async () => {
    try {
      const formData = new FormData();
      formData.append("new_info", JSON.stringify(memberInfo));

      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_USER_SERVER + "/member_type/edit",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        if (modalParam.isAssign) {
          onModalDone();
        } else {
          let regDate = dateToStr(new Date());
          storesDispatch(
            globalAlertOn({
              show: true,
              titleText: "알림",
              reqConfirm: true,
              bodyText: (
                <>
                  <Label labelText="회원 전환이 완료되었습니다." />
                  <Label labelText="추가 결제 페이지로 이동하시겠습니까?" />
                  <div style={{ display: "flex", justifyContent: "center", marginTop: "0.7rem" }}>
                    <DatePicker
                      labelWidth="4rem"
                      minWidth="12rem"
                      labelText="등록일"
                      minDate={"1900-01-01"}
                      // maxDate={getCurrentDate()} 임시 주석
                      defaultValue={dateToStr(new Date())}
                      onChangeCallback={(e) => {
                        regDate = e;
                      }}
                    />
                  </div>
                </>
              ),
              onBtnYes: () => {
                //추가결제 페이지로 이동
                storesDispatch(globalModalOff());
                modalParam.navigate("/add_pay", {
                  state: {
                    member_id: modalParam.member_id,
                    branch_id: memberInfo.branch_info.branch_id,
                    branch_name: memberInfo.branch_info.branch_name,
                    memberBaseInfoState: modalParam.baseInfoState,
                    reg_date: regDate,
                  },
                });
              },
              onBtnNo: () => {
                //회원정보 페이지 갱신하고 거기로 이동
                storesDispatch(globalModalOff());
                storesDispatch(loadMemberViewStore(modalParam.member_id));
              },
            }),
          );
        }
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const getComboHqBranch = async () => {
    try {
      setLoading(true);
      const res = await HttpInstance.get(
        process.env.REACT_APP_USER_SERVER + "/member_type/edit/combo",
        {
          params: {},
        },
      );
      if (!restEmpty(res.data.data)) {
        setHqBranchData(res.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  //초기랜더링
  useEffect(() => {
    getComboHqBranch();
  }, [modalParam]);

  //주소입력 ModalDone
  const handleComplete = (data) => {
    openAddrPopup(false);
    setMemberInfo((cur) => {
      const obj = { ...cur };
      obj.personal_info.address_1 = data.address;
      return obj;
    });
  };

  return (
    <div style={{ width: "50rem" }}>
      {loading && <Loading />}

      <Popup
        isOpen={isAddrPopup}
        onClose={() => {
          openAddrPopup(false);
        }}
        title="주소 검색"
        Content={
          <DaumPostcode
            onComplete={handleComplete}
            autoClose={false}
            style={{ width: "30rem", height: "35rem" }}
          />
        }
      />

      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="가입 정보" />
      </div>
      <div style={{ display: "flex" }}>
        <ComboBox
          required
          marginLeft="1.5rem"
          labelWidth={labelWidth}
          comboItemWidth={compWidth}
          labelText="본부"
          data={hqBranchData.hq_list}
          onChangeCallback={(e) => {
            const index = hqBranchData.hq_list.findIndex((el) => el === e.target.value);
            if (index !== -1) {
              setHqIdx(index);
              let tBranchName = hqBranchData.target_list[index].branch_list[0].branch_name;
              let tBranchId = hqBranchData.target_list[index].branch_list[0].branch_id;
              if (tBranchName === "전체") {
                tBranchId = generateObjectZeroId();
              }

              setMemberInfo({
                ...memberInfo,
                hq_info: hqBranchData.target_list[index].hq_info,
                branch_info: { branch_id: generateObjectZeroId(), branch_name: "" },
              });
            }
          }}
        />
        <ComboBox
          required
          marginLeft="1.5rem"
          labelWidth={labelWidth}
          comboItemWidth={compWidth}
          labelText="지점"
          data={hqBranchData.branch_list[hqIdx]}
          onChangeCallback={(e) => {
            const index = hqBranchData.branch_list[hqIdx].findIndex((el) => el === e.target.value);
            if (index !== -1) {
              let tBranchId = hqBranchData.target_list[hqIdx].branch_list[index].branch_id;
              if (e === "전체") {
                tBranchId = generateObjectZeroId();
              }
            }
            setMemberInfo((cur) => {
              const obj = { ...cur };
              obj.branch_info = hqBranchData.target_list[hqIdx].branch_list[index];
              return obj;
            });
          }}
        />
        {/* <ComboBox
          marginLeft="1.5rem"
          labelWidth={labelWidth}
          comboItemWidth={compWidth}
          labelText="가입 경로"
          data={["기타", "소개", "간판", "블로그", "인스타", "페이스북", "네이버", "홍보", "열정"]}
          onChangeCallback={(e) => {
            setMemberInfo((cur) => {
              return { ...cur, join_way: e.target.value };
            });
          }}
        /> */}
      </div>

      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="회원 정보" />
      </div>
      <div>
        <div style={{ display: "flex" }}>
          <TextField
            marginLeft="1.5rem"
            labelWidth={labelWidth}
            textBoxWidth={compWidth}
            labelText="이름"
            disabled
            defaultValue={modalParam.member_name}
            onChangeCallback={(e) => {
              setMemberInfo((cur) => {
                const obj = _.cloneDeep(memberInfo);
                obj.member_info.member_name = e;
                return obj;
              });
            }}
          />

          <DatePicker
            required
            labelMarginLeft="1.5rem"
            labelText={"생년월일"}
            labelWidth={labelWidth}
            minWidth={compWidth}
            maxDate={getCurrentDate()}
            onBlur={(e) => {
              setMemberInfo((cur) => {
                const obj = { ...cur };
                obj.personal_info.birth = e;
                return obj;
              });
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "0.7rem",

            // justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ComboBox
            required
            marginLeft="1.5rem"
            labelWidth={labelWidth}
            comboItemWidth={compWidth}
            labelText="성별"
            defaultValue={"남"}
            data={["남", "여"]}
            onBlur={(e) => {
              setMemberInfo((cur) => {
                const obj = { ...cur };
                obj.personal_info.gender = e.target.value;
                return obj;
              });
            }}
          />
          <TextField
            marginLeft="1.5rem"
            textMarginRight="0rem"
            labelWidth={labelWidth}
            textBoxWidth={compWidth}
            labelText="연락처"
            disabled
            defaultValue={memberInfo.personal_info.phone}
          />
        </div>
        <div style={{ display: "flex", marginTop: "0.7rem" }}>
          <TextField
            marginLeft="1.5rem"
            textMarginRight="0rem"
            labelWidth={labelWidth}
            textBoxWidth={"20rem"}
            labelText="주소"
            defaultValue={memberInfo.personal_info.address_1}
            disabled
          />
          <TextField
            marginLeft="0rem"
            textBoxWidth={"20rem"}
            textMarginRight="0.3rem"
            onBlur={(e) => {
              setMemberInfo((cur) => {
                const obj = { ...cur };
                obj.personal_info.address_2 = e.target.value;
                return obj;
              });
            }}
          />
          <IconButton
            type="search"
            width="2.1rem"
            onClick={() => {
              openAddrPopup(true);
            }}
          />
        </div>
        <div style={{ display: "flex", marginTop: "0.7rem" }}>
          <TextField
            marginLeft="1.5rem"
            labelWidth={labelWidth}
            textBoxWidth={"43.2rem"}
            labelText="특이사항"
            onBlur={(e) => {
              setMemberInfo((cur) => {
                const obj = { ...cur };
                obj.personal_info.notice = e.target.value;
                return obj;
              });
            }}
          />
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
        <NormalBtn
          name="등록"
          onClick={() => {
            validationCheck();
          }}
        />
      </div>
    </div>
  );
};

export default ConverToMemberModal;
