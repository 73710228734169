import { create } from "zustand";

export const useSideBarStore = create((set, get) => ({
  // ========== State ===========
  // 사이드 멀티 메뉴 오픈 상태
  sideMultiMenuOpen: {
    게시판: false,
    수업운영: false,
    조회: false,
    지점관리: false,
    CS관리: false,
    출입관리: false,
  },
  // 사이드 메뉴 더블 뎁스 메뉴 오픈 상태
  sideMenuDoubleDepthOpen: {
    "PT팀 관리": false,
    현금출납장: false,
  },

  // 사이드 멀티메뉴 액티브 상태
  sideMultiMenuActive: {
    게시판: false,
    수업운영: false,
    조회: false,
    지점관리: false,
    CS관리: false,
    출입관리: false,
  },
  // 사이드 메뉴 전체 state 상태
  sideMenuStatus: {
    스케줄: false,
    락커관리: false,
    전자결재: false,
  },

  // ========== Action ==========
  // url에 따른 사이드 메뉴 상태 변경
  setSideMenuStatus: (menuUrl) => {
    const { resetSideMultiMenu, resetSideMenuStatus, resetSideDoubleDepthMenu } = get();

    // 최상단 부모 타이틀, 중간 타이틀(PT팀 관리, 현금출납장), 각 최종 페이지 연결 메뉴 타이틀
    let parentTitle = "";
    let middleTitle = "";
    let menuTitle = "";
    // 사이드 메뉴들 모음
    const menuObject = {
      "/schedule": { menuTitle: "스케줄" },
      "/locker_service": { menuTitle: "락커관리" },
      "/approval_list": { menuTitle: "전자결재" },
      "/notice": { parentTitle: "게시판", menuTitle: "공지사항" },
      "/reference": { parentTitle: "게시판", menuTitle: "자료실" },
      "/staff_dm": { parentTitle: "게시판", menuTitle: "전달사항" },
      "/class_manage": { parentTitle: "수업운영", menuTitle: "수업 관리" },
      "/class_hist": { parentTitle: "수업운영", menuTitle: "수업 이력" },
      "/manage_pt_team": {
        parentTitle: "수업운영",
        middleTitle: "PT팀 관리",
        menuTitle: "팀 관리",
      },
      "/pt_team_performance": {
        parentTitle: "수업운영",
        middleTitle: "PT팀 관리",
        menuTitle: "팀 실적",
      },
      "/search_member": { parentTitle: "조회", menuTitle: "회원 조회" },
      "/long_postpone_list": { parentTitle: "조회", menuTitle: "장기연기 리스트" },
      "/unpaid_manage": { parentTitle: "조회", menuTitle: "미납금 관리" },
      "/subs_manage": { parentTitle: "조회", menuTitle: "정기결제 관리" },
      "/extend_hist": { parentTitle: "조회", menuTitle: "기간 추가 현황" },
      "/staff_list": { parentTitle: "지점관리", menuTitle: "직원관리" },
      "/salary_list": { parentTitle: "지점관리", menuTitle: "급여 관리" },
      "/daily_list": { parentTitle: "지점관리", menuTitle: "운영일지" },
      "/sales_record": { parentTitle: "지점관리", menuTitle: "실적관리" },
      "/member_location_stats": { parentTitle: "지점관리", menuTitle: "회원 위치 통계" },
      "/cash_book": {
        parentTitle: "지점관리",
        middleTitle: "현금출납장",
        menuTitle: "수입/지출내역",
      },
      "/unspent": { parentTitle: "지점관리", middleTitle: "현금출납장", menuTitle: "미지출 내역" },
      "/send_message": { parentTitle: "CS관리", menuTitle: "문자 발송" },
      "/send_message_hist": { parentTitle: "CS관리", menuTitle: "발송 이력" },
      "/unsubs_list": { parentTitle: "CS관리", menuTitle: "수신거부 목록" },
      "/guest_book": { parentTitle: "CS관리", menuTitle: "방명록" },
      "/tm_list": { parentTitle: "CS관리", menuTitle: "TM 리스트" },
      "/tm_hist": { parentTitle: "CS관리", menuTitle: "TM 이력" },
      "/access_device_manage": { parentTitle: "출입관리", menuTitle: "단말기 관리" },
      "/access_card_manage": { parentTitle: "출입관리", menuTitle: "카드관리" },
      "/access_monitor": { parentTitle: "출입관리", menuTitle: "출입문 모니터링" },
    };

    if (menuObject[menuUrl]) {
      ({ parentTitle, middleTitle, menuTitle } = menuObject[menuUrl]);
    }

    const newMenuState = {};
    // 메뉴 타이틀 O (url이 있는 페이지)
    if (menuTitle) {
      set((state) => {
        for (let key in state.sideMenuStatus) {
          newMenuState[key] = false;
        }
        newMenuState[menuTitle] = true;
        return {
          sideMenuStatus: newMenuState,
        };
      });
      resetSideDoubleDepthMenu();
      resetSideMultiMenu();
      set((state) => {
        const newState = {
          ...state.sideMenuStatus,
          [parentTitle]: true,
        };
        // 중간 메뉴 O
        if (middleTitle) {
          newState[middleTitle] = true;
          return {
            sideMenuDoubleDepthOpen: { ...state.sideMenuDoubleDepthOpen, [middleTitle]: true },
            sideMenuStatus: newState,
            sideMultiMenuOpen: { ...state.sideMultiMenuOpen, [parentTitle]: true },
            sideMultiMenuActive: {
              ...state.sideMultiMenuActive,
              [parentTitle]: true,
              [middleTitle]: true,
            },
          };
        }
        // 중간 메뉴 X
        return {
          sideMenuStatus: newState,
          sideMultiMenuOpen: { ...state.sideMultiMenuOpen, [parentTitle]: true },
          sideMultiMenuActive: { ...state.sideMultiMenuActive, [parentTitle]: true },
        };
      });
      // }
    } else {
      // 메뉴 타이틀 X (url이 없는 페이지)
      resetSideMultiMenu();
      resetSideDoubleDepthMenu();
      resetSideMenuStatus();
    }
  },

  // 사이드 멀티 메뉴 상태 변경
  setSideMultiMenuOpen: (menuTitle) => {
    const newState = {};
    set((state) => {
      for (let key in state.sideMultiMenuOpen) {
        if (state.sideMultiMenuActive[key]) {
          newState[key] = true;
        } else {
          newState[key] = false;
        }
      }
      newState[menuTitle] = !state.sideMultiMenuOpen[menuTitle];

      return {
        sideMultiMenuOpen: newState,
        sideMenuStatus: { ...state.sideMenuStatus, [menuTitle]: false },
      };
    });
  },

  // 사이드 메뉴의 더블 뎁스 상태 변경 (PT팀 관리, 현금출납장)
  setSideMenuDoubleDepthOpen: (menuTitle) => {
    const newState = {};
    set((state) => {
      for (let key in state.sideMenuDoubleDepthOpen) {
        if (state.sideMultiMenuActive[key]) {
          newState[key] = true;
        } else {
          newState[key] = false;
        }
      }
      newState[menuTitle] = !state.sideMenuDoubleDepthOpen[menuTitle];

      return {
        sideMenuDoubleDepthOpen: newState,
      };
    });
  },
  // 멀티 메뉴 상태 리셋
  resetSideMultiMenu: () => {
    const newState = {};
    set((state) => {
      for (let key in state.sideMultiMenuOpen) {
        newState[key] = false;
      }
      return {
        sideMultiMenuOpen: newState,
        sideMultiMenuActive: newState,
      };
    });
  },
  // 더블 뎁스 메뉴 상태 리셋
  resetSideDoubleDepthMenu: () => {
    const newState = {};
    set((state) => {
      for (let key in state.sideMenuDoubleDepthOpen) {
        newState[key] = false;
      }
      return { sideMenuDoubleDepthOpen: newState };
    });
  },
  // 사이드 메뉴 상태 리셋
  resetSideMenuStatus: () => {
    const newMenuStatus = {};
    set((state) => {
      for (let key in state.sideMenuStatus) {
        newMenuStatus[key] = false;
      }
      return { sideMenuStatus: newMenuStatus };
    });
  },
}));
