import { combineReducers } from "redux";
import * as ActionTypes from "../actions/action_type";
import { navMenuItems } from "components/NavMenu/navBarItems";
import { sideMenuItems } from "components/Sidebar/sideBarItems";

const listStateinitial = {
  stores: {
    data: {
      nav_menu: navMenuItems,
      side_menu: sideMenuItems,
      reg_info: {
        created_at: "",
        reg_id: "",
        reg_name: "",
        upd_id: "",
        upd_name: "",
        updated_at: "",
      },
    },
  },
};
const auth_permission_state = (state = listStateinitial, action) => {
  switch (action.type) {
    case ActionTypes.getPermissionSuccessAction: {
      return { ...state, stores: action.payload };
    }
    case ActionTypes.getPermissionFailAction:
      return { ...state, stores: listStateinitial.stores };
    default:
      return state;
  }
};

export const authPermissiongReducer = combineReducers({
  auth_permission_state,
});
