import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import Loading from "components/Loading/Loading/Loading";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalModalOff } from "modules/actions/Modal";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import DeviceAllocateModal from "./DeviceAllocateModal";

const ReadyDeviceModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const viewModelRef = useRef();
  const [loading, setLoading] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });

  //지점의 켜져있는 장치리스트 GET
  const getDeviceList = async () => {
    setLoading(true);
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_ACCESS_SERVER + "/sdk/pending_device_list",
        {
          params: {},
        },
      );
      if (!restEmpty(res.data.data)) {
        setDeviceList(res.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  //초기 랜더링
  useEffect(() => {
    getDeviceList();
  }, []);

  //장치할당 모달POP
  const onAllocateDivce = (dev_id) => {
    const params = {
      device_id: dev_id,
      hqBranchList: modalParam.hqBranchList,
    };
    const allocModalDone = () => {
      storesDispatch(globalModalOff()); //모달 다 닫기
      onModalDone(); //메인 출입장치 리스트 갱신
    };

    setModalInfo({
      show: true,
      title: "장치 할당",
      Content: <DeviceAllocateModal modalParam={params} onModalDone={allocModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const readyDeviceInfoTableItem = new readyDeviceInfoTable(onAllocateDivce);

  return (
    <div
      style={{
        width: "20rem",
      }}
    >
      {loading && <Loading />}
      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
      <div style={{ display: "flex", justifyContent: "right", marginBottom: "0.5rem" }}>
        <NormalBtn name="새로고침" onClick={getDeviceList} />
      </div>
      <CustomTable
        columns_head={readyDeviceInfoTableItem.columns_head}
        table_title={readyDeviceInfoTableItem.table_title}
        rest_call={readyDeviceInfoTableItem.get_data_from_rest}
        row_render={readyDeviceInfoTableItem.create_table}
        rest_data={deviceList}
      />
    </div>
  );
};
export default ReadyDeviceModal;

class readyDeviceInfoTable {
  table_title = "";
  btnStyleClass = btnStyles();
  onAllocateDivce = null;

  constructor(onAllocateDivce) {
    this.onAllocateDivce = onAllocateDivce;
  }
  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data}</CustomTableContents>
        <CustomTableContentsEND align="center">
          <Button
            className={clsx({
              [this.btnStyleClass.btnRoot]: true,
              [this.btnStyleClass.buttonType3]: true,
            })}
            onClick={() => this.onAllocateDivce(data)}
          >
            할당
          </Button>
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "장치 ID", width: "65%" },
    { title: "", width: "35%" },
  ];
  get_data_from_rest = (stateData) => {
    // console.log(stateData);
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
