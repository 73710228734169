import { restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { report_info_state } from "../Module/reducers/report_info_state";
import DailyReportInfoView from "./DailyReportInfoView";

const DailyReportInfoService = () => {
  const storesDispatch = useDispatch();
  const { state } = useLocation();
  const [reportState, setReportState] = useState(_.cloneDeep(report_info_state));
  const [isAdmin, setIsAdmin] = useState(false);

  const getAuthData = async () => {
    await HttpInstance.get(process.env.REACT_APP_AUTH_SERVER + "/global/perm_auth", {})
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setIsAdmin(res.data.data.is_admin);
        }
      })
      .catch((err) => {
        console.log("err");
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const getReportData = async () => {
    HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + "/daily_report/detail", {
      params: {
        report_info_id: state.reportInfoId,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setReportState(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  useEffect(() => {
    getReportData();
    getAuthData();
  }, []);

  return (
    <div>
      <PageFrame
        menuActive="락커/셋팅"
        parentMenu={{ 락커: true }}
        children={
          <div>
            <PageTitle titleText="운영일지" />
            <DailyReportInfoView reportState={reportState} isAdmin={isAdmin} />
          </div>
        }
      />
    </div>
  );
};
export default DailyReportInfoService;

//DailyReportInfo
