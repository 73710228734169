import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { useParams } from "react-router";

import MemberViewWrapper from "./MemberViewWrapper";

const RegisterMember = () => {
  const params = useParams();

  return (
    <PageFrame
      isMemberInfo={true}
      children={
        <div>
          <PageTitle titleText="회원정보" />
          <MemberViewWrapper memberId={params.targetId} />
        </div>
      }
    />
  );
};
export default RegisterMember;
