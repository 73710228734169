import { simpleAlert, SubLine } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import NumberTextField from "components/LabelInput/NumberTextField";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import ModalBtnModeGroup from "components/ModalBtnModeGroup/ModalBtnModeGroup";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { branchSettingAction, loadETCItemsStore } from "../Module/actions";
import post_api from "../Module/api/PostApi";
import BoardingSetting from "./CommonSub/BoardingSetting";

const ETCSettingContents = ({ modalParam, onModalDone }) => {
  const isEditMode = modalParam.mode === "edit";
  const storesDispatch = useDispatch();
  const restData = useSelector((state) => state.branch_set_state.branch_etc_product_state);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (modalParam.branchId !== "") {
      storesDispatch(loadETCItemsStore(modalParam.branchId, modalParam.itemId));
    }
    return () => {
      storesDispatch(branchSettingAction.loadETCItemsReset());
    };
  }, [loadETCItemsStore, storesDispatch]);

  const validation = () => {
    let res = "";
    if (!restData.stores.data.etc_items[0].base_info.sales_status) {
      res += "· 판매상태\n";
    }
    if (!restData.stores.data.etc_items[0].item_name) {
      res += "· 상품명\n";
    }
    if (!restData.stores.data.etc_items[0].price) {
      res += "· 판매가\n";
    }
    return res;
  };

  const onClickSave = () => {
    const validationRes = validation();
    if (validationRes !== "") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="필수값을 모두 입력해주셔야 합니다." />
              <br />
              <br />
              <Label labelText={validationRes} whiteSpace="pre-wrap" />
            </>
          ),
          // width: "50rem",
          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="저장하시겠습니까?" />
            </>
          ),
          // width: "50rem",
          onBtnYes: () => {
            onSaveEvent();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  const onSaveEvent = async () => {
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    restData.stores.data.branch_id = modalParam.branchId;

    setLoading(true);

    const formData = new FormData();
    formData.append("item_info", JSON.stringify(restData.stores.data));
    const postApi = isEditMode ? post_api.updateETCApi() : post_api.newETCApi();
    await HttpInstance.post(postApi, formData, config)
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          onModalDone(modalParam.refreshStore);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
        // onModalDone(modalParam.refreshStore);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        width: "52rem",
      }}
    >
      {loading && <Loading />}

      <ModalBtnModeGroup mode={modalParam.mode} onClickEvent={onClickSave} />
      <ETCItem restData={restData.stores.data.etc_items[0]} isEditMode={isEditMode} />
      <BoardingSetting restData={restData.stores.data.etc_items[0]} />
    </div>
  );
};

export default ETCSettingContents;

const ETCItem = ({ restData, isEditMode }) => {
  const textLabelWidth = "6rem";
  const textBoxWidth = "12rem";
  const textBoxWidthLong = "25rem";
  const comboItemWidth = "12rem";
  // console.log(restData);

  return (
    <div>
      <ComboBox
        labelWidth={textLabelWidth}
        comboItemWidth={comboItemWidth}
        labelText="판매상태"
        data={["판매중", "판매종료"]}
        defaultValue={restData.base_info.sales_status}
        onChangeCallback={(e) => {
          restData.base_info.sales_status = e.target.value;
        }}
      />

      <TextField
        required
        marginTop="0.5rem"
        labelWidth={textLabelWidth}
        textBoxWidth={textBoxWidthLong}
        labelText="상품명"
        defaultValue={restData.item_name}
        onChangeCallback={(e) => {
          restData.item_name = e;
        }}
        disabled={isEditMode}
      />

      <TextField
        marginTop="0.5rem"
        labelWidth={textLabelWidth}
        textBoxWidth={textBoxWidthLong}
        labelText="상품 설명"
        defaultValue={restData.item_detail}
        onChangeCallback={(e) => {
          restData.item_detail = e;
        }}
      />
      <SubLine />

      <div style={{ display: "flex" }}>
        <NumberTextField
          isNumber
          required
          labelWidth={textLabelWidth}
          textBoxWidth={textBoxWidth}
          labelText="판매가"
          endAdornment="원"
          minValue={0}
          maxValue={99999999}
          defaultValue={restData.price}
          onChangeCallback={(e) => {
            restData.price = e;
          }}
          disabled={isEditMode}
        />
      </div>

      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <NumberTextField
          isNumber
          labelWidth={textLabelWidth}
          textBoxWidth={textBoxWidth}
          labelText="판매 인센티브"
          endAdornment="원"
          minValue={0}
          maxValue={99999999}
          defaultValue={restData.base_info.incentive}
          onChangeCallback={(e) => {
            restData.base_info.incentive = e;
          }}
          disabled={isEditMode}
        />
      </div>
      <SubLine />
    </div>
  );
};
