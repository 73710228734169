import { navMenuItems } from "components/NavMenu/navBarItems";
import { sideMenuItems } from "components/Sidebar/sideBarItems";

export const permissionInitData = {
  nav_menu: navMenuItems,
  side_menu: sideMenuItems,
  reg_info: {
    created_at: "",
    reg_id: "",
    reg_name: "",
    upd_id: "",
    upd_name: "",
    updated_at: "",
  },
};
